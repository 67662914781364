import React from "react";
import BlankPrizeCardComponent from "./blankPrizeCardV2.component";
import { useSelector } from "react-redux";

function BlankPrizeCardContainer({
  isEditing,
  prizeDetail,
  formik,
  updateDetail,
  disabled,
  enableWinRate,
  remainingWinRate,
}) {
  const lang = useSelector((state) => state.constant.languages);

  return (
    <BlankPrizeCardComponent
      prizeDetail={prizeDetail}
      isEditing={isEditing}
      formik={formik}
      lang={lang}
      updateDetail={updateDetail}
      disabled={disabled}
      enableWinRate={enableWinRate}
      remainingWinRate={remainingWinRate}
    />
  );
}

export default BlankPrizeCardContainer;
