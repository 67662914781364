import React from "react";
import RejectApproveRequestDialogComponent from "./rejectApproveRequestDialog.component";
import { useSelector } from "react-redux";
import { getLang } from "app/feature/constants";

function RejectApproveRequestDialogContainer({
  isLoading,
  isOpen,
  handleClose,
  handleProceed,
}) {
  const lang = useSelector((state) => state.constant.languages);

  const reasonType = [
    getLang(lang, "label.OPERATIONAL_DISRUPTION"),
    getLang(lang, "label.OUT_OF_STOCK"),
    getLang(lang, "label.OTHERS"),
  ];

  const handleSubmit = (values) => {
    let reason = "";
    if (values.selection <= 2) {
      reason = reasonType[values.selection];
    } else {
      reason = values.reason;
    }

    handleProceed(reason);
  };

  return (
    <RejectApproveRequestDialogComponent
      isLoading={isLoading}
      isOpen={isOpen}
      handleClose={handleClose}
      handleProceed={handleSubmit}
      lang={lang}
    />
  );
}

export default RejectApproveRequestDialogContainer;
