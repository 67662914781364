import React, { useState, useEffect } from "react";
import BulkCreateAttributePanelComponent from './bulkCreateAttributeSelectPanel.component';
import { useDispatch, useSelector } from "react-redux";
import { selectLoading } from "modules/notification";
import { unwrapResult } from "@reduxjs/toolkit";
import CreateAttributesDialog from "../../dialog/createAttributesDialog";
import { addAttribute, getAttributeDropdown } from "modules/product-mgmt/redux/action/attribute.action";

function BulkCreateAttributeSelectContainer({
  handleChange,
  value,
  disabled,
  styles,
  searchPlaceholder,
  searchable,
  anchorOrigin,
  transformOrigin,
  PaperProps,
}) {
  const lang = useSelector((state) => state.constant.languages);
  const attributeDropdown = useSelector(state => state.products.attributeList.attributeDropdown)
  const isAttributeDropdownLoading = useSelector(state => selectLoading(state, getAttributeDropdown.typePrefix))
  const isAddingAttribute = useSelector(state => selectLoading(state, addAttribute.typePrefix))
  
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectAll, setSelectAll] = useState(false);
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [attributeDropdownSearch, setAttributeDropdownSearch] = useState("")

  useEffect(() => {
    dispatch(getAttributeDropdown({ search: attributeDropdownSearch }))
  }, [dispatch, attributeDropdownSearch])

  const handleClose = () => {
    setAnchorEl(null)
    setAttributeDropdownSearch("")
  };

  const handleAttributeSearch = (value) => {
    setAttributeDropdownSearch(value)
  }

  const handleAddAttribute = (value) => {
    setIsCreateDialogOpen(value)
  }

  const handleSubmitAttribute = (value) => {
    dispatch(addAttribute({
      name: value.attribute,
      options: value.options
    }))
      .then(unwrapResult)
      .then(() => {
        dispatch(getAttributeDropdown({ search: attributeDropdownSearch }))
      })
      .finally(() => {
        setIsCreateDialogOpen(false)
      })
  }

  const handleSelectAll = () => {
    if (!disabled) {
      if (selectAll) {
        handleChange([])
      } else {
        let temp = [];
        attributeDropdown.forEach(attribute => {
          let optionTemp = []
          attribute.options.forEach((option) => {
            optionTemp.push({
              id: option.id,
              value: option.value
            })
          });
  
          temp.push({
            id: attribute.id,
            name: attribute.name,
            options: optionTemp,
          });
        });
  
        handleChange(temp)
      }
      setSelectAll(!selectAll)
    }
  }

  const handleSelectAttribute = (field, checked) => {
    if (!disabled) {
      let temp = [...value];
      let index = temp.findIndex((v) => v.id === field.id)
      if (checked) {
        let optionTemp = []
        field.options.map((option) => {
          optionTemp.push({
            id: option.id,
            value: option.value
          })
          return null;
        });
        if(index !== -1){
          temp[index].options = optionTemp;
        }
        else {
          temp.push({
            id: field.id,
            name: field.name,
            options: optionTemp,
          });
        }
      } else {
        temp.splice(index, 1)
        setSelectAll(false)
      }

      handleChange(temp)
    }
  }

  const handleSelectOption = (field, optionsfield, checked) => {
    if (!disabled) {
      let temp = [...value];
      let optionsTemp = [];
      let valAttrIndex = temp.findIndex((a) => a.id === field.id);
      if (temp.length > 0 && valAttrIndex !== -1) {
        optionsTemp = temp[valAttrIndex].options;
        if(checked) {
          optionsTemp.push({
            id: optionsfield.id,
            value: optionsfield.value,
          });
          temp[valAttrIndex].options = optionsTemp;
        }
        else {
          let index = optionsTemp.findIndex((o) => o.id === optionsfield.id);
          optionsTemp.splice(index, 1);
          if (optionsTemp.length === 0){
            temp.splice(valAttrIndex, 1);
          }
          else {
            temp[valAttrIndex].options = optionsTemp;
          }
          setSelectAll(false)
        }
      }
      else {
        if (checked) {
          optionsTemp.push({
            id: optionsfield.id,
            value: optionsfield.value,
          });
          temp.push({
            id: field.id,
            name: field.name,
            options: optionsTemp,
          });
        } else {
          setSelectAll(false)
        }
      }
      handleChange(temp)
    }
  }

  return (
    <>
      <BulkCreateAttributePanelComponent
        anchorEl={anchorEl}
        selectAll={selectAll}
        style={styles}
        value={value}
        handleClose={handleClose}
        isFetching={isAttributeDropdownLoading}
        dropdownItem={attributeDropdown}
        handleSelectAll={handleSelectAll}
        placeholder={searchPlaceholder}
        searchable={searchable}
        handleAttributeSearch={handleAttributeSearch}
        handleSelectAttribute={handleSelectAttribute}
        handleSelectOptions={handleSelectOption}
        handleAddAttribute={handleAddAttribute}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        PaperProps={PaperProps}
        lang={lang}
      />
      <CreateAttributesDialog
        isOpen={isCreateDialogOpen}
        handleClose={() => {
          setIsCreateDialogOpen(false)
        }}
        handleSubmit={handleSubmitAttribute}
        isSubmittingAddModel={isAddingAttribute}
      />
    </>
  )
}

export default BulkCreateAttributeSelectContainer

