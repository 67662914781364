import React, {useRef, useState} from "react";
import {connect, useSelector} from "react-redux";
import {getLang} from "../../../../../app/feature/constants";
import {setImgFormatAndSize} from "../../../../../lib/FileHelper";
import Modal from "../../../../account/components/modal";
import {ImageEditorPanel} from "../../../../../components/panel";
import BranchLogoPanelComponent from "./branchLogoPanel.component";
import {addAlert} from "../../../../notification";

function BranchLogoPanelContainer({
  logo,
  handleChange,
  handleRemove,
}) {
  const inputImgRef = useRef();
  const [bufferImg, setBufferImg] = useState();
  const [isCropping, setCropping] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const allowedImageExtension = [".jpeg", ".jpg", ".png", ".webp"];
  const lang = useSelector(state => state.constant.languages)

  const handleImgChange = (e) => {
    const rawFile = e.target.files[0];

    validateImg(rawFile);
  };

  const handleDrop = (dropped) => {
    setDragging(false);
    const rawFile = dropped[0];

    validateImg(rawFile);
  };

  const validateImg = ( rawFile ) => {
    if (!ValidImageFormat(rawFile.name)) {
      addAlert({ severity: "error", message: getLang(lang, 'message.error.FORM_VALIDATE_IMAGE_TYPE') });
      return;
    }

    if ((rawFile.size / 1024 / 1024) > 6) {
      addAlert({ severity: "error", message: getLang(lang, "message.error.FORM_VALIDATE_FILE_SIZE", { value: 6 }) })
      return;
    }

    const image = URL.createObjectURL(rawFile);

    setBufferImg(image);
    setIsModalOpen(true);
    setCropping(true);
  }

  const ValidImageFormat = (file) => {
    for (let index = 0; index < allowedImageExtension.length; index++) {
      if (file.indexOf(allowedImageExtension[index]) !== -1) {
        return true;
      }
    }
    return false;
  };

  const handleClose = () => setIsModalOpen(false);

  const handleResult = async (base64Image) => {
    setCropping(false);
    setIsModalOpen(false);

    base64Image = await setImgFormatAndSize(base64Image, 2160, 2160, 'image/webp')

    handleChange(base64Image);
  };

  const handleDragEnter = () => {
    setDragging(true);
  };
  const handleDragLeave = () => {
    setDragging(false);
  };

  return (
    <>
      <BranchLogoPanelComponent
        logo={logo}
        inputImgRef={inputImgRef}
        handleImgChange={handleImgChange}
        handleDragEnter={handleDragEnter}
        handleDragLeave={handleDragLeave}
        handleDrop={handleDrop}
        handleRemove={handleRemove}
        dragging={dragging}
        lang={lang}
      />
      <Modal isOpen={isModalOpen} handleClose={handleClose}>
        {isCropping && (
          <ImageEditorPanel
            img={bufferImg}
            handleCancel={handleClose}
            handleResult={handleResult}
          />
        )}
      </Modal>
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  addAlert: (payload) => dispatch(addAlert(payload)),
});

export default connect(
  mapDispatchToProps
)(BranchLogoPanelContainer);
