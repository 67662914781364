import React, { useEffect, useState } from "react";
import EditPagePanelComponent from "./editPagePanel.component";
import { connect, useDispatch, useSelector } from "react-redux";
import { publishNewProduct } from "../../../redux/action/products.action";
import SavingDialog from "../../dialog/savingDialog";
import { setEditorMode } from "modules/product-mgmt/redux/slice/productPage.slice";
import { resetAvailableSurveys } from "modules/survey/redux/slice";

function EditPagePanelContainer({
  children,
  enableEditorMode,
  publishNewProduct,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const lang = useSelector((state) => state.constant.languages);

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetAvailableSurveys());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    enableEditorMode();
  }, [enableEditorMode]);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handlePublish = () => {
    publishNewProduct();
    setIsOpen(true);
  };

  return (
    <>
      <EditPagePanelComponent
        children={children}
        handlePublish={handlePublish}
        lang={lang}
      />
      <SavingDialog isOpen={isOpen} handleClose={handleClose} />
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  enableEditorMode: () => dispatch(setEditorMode(true)),
  publishNewProduct: () => dispatch(publishNewProduct()),
});

export default connect(null, mapDispatchToProps)(EditPagePanelContainer);
