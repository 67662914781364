import React from "react";
import { Box, Typography, Button, Backdrop, Dialog, makeStyles, IconButton } from "@material-ui/core";
import SuccessIllustration from "assets/img/assign-success-illustration.png";
import CloseIcon from "@material-ui/icons/CloseRounded";
import SuccessCloud from "assets/img/successCloud.png"
import { getLang } from "app/feature/constants";

const useStyle = makeStyles((theme) => ({
  header: {
    padding: "4px 0px",
    paddingLeft: theme.spacing(3),
    display: "flex",
    placeContent: "flex-end",
    alignItems: "center",
  },
  text: {
    position: "absolute",
    bottom: "85px",
    left: "85px",
    fontWeight: "bold",
  },
  alrightButton: {
    color: theme.palette.common.white,
    borderRadius: "100px",
    padding: theme.spacing(1, 3),
    fontWeight: "bold",
  },
  button: {
    position: "absolute",
    bottom: "30px",
    left: "50%",
    marginLeft: "-57px"
  },
  image: {
    height: "250px",
    "& img": {
      height: "100%",
      flexShrink: 0,
      minWidth: "100%",
      minHeight: "100%",
      objectFit: "cover",
    },
  },
  imageBottom: {
    height: "236px",
    "& img": {
      height: "100%",
      flexShrink: 0,
      minWidth: "100%",
      minHeight: "100%",
      objectFit: "cover",
    },
  },
  dialog: {
    overflowY: "hidden"
  }
}));

export default function DisplayMessageDialogComponent({
  handleClose,
  isOpen,
  lang,
  statusResult
}) {
  const classes = useStyle();

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      disableEnforceFocus={true}
      disableAutoFocus={true}
      disableEscapeKeyDown={true}
      disableBackdropClick={true}
      closeAfterTransition
      BackdropComponent={Backdrop}
      maxWidth="sm"
      classes={{
        paperWidthSm: classes.dialog,
      }}
    >
      <Box style={{ backgroundColor: "#5ABEF7" }}>
        <Box style={{textAlign: "center"}}>
          <Box className={classes.header}>
            <IconButton color="primary" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
          <Box className={classes.image}>
            <img src={SuccessIllustration} alt="success" />
          </Box>
        </Box>
        <Box className={classes.imageBottom}>
          <img src={SuccessCloud} alt="bottomCloud" />
        </Box>
        <Box>
          <Typography variant="body1" className={classes.text}>
            {statusResult === "pending" && "The redemption has updated"}
            {statusResult === "approved" && "The redemption has approved"}
            {statusResult === "rejected" && "The redemption has rejected"}
          </Typography>
        </Box>
        <Box className={classes.button}>
          <Button
            color="secondary"
            variant="contained"
            classes={{ root: classes.alrightButton }}
            disableElevation
            type="button"
            onClick={handleClose}
          >
            {getLang(lang,"label.ALRIGHT")}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}
