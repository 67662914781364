import React, { useState, useRef, useEffect } from "react"
import StockoutRequestorSelectComponent from "./stockoutRequestorSelect.component"
import { selectLoading } from "modules/notification"
import StockoutRequestorSelectPanelComponent from "./stockoutRequestorSelectPanel.component"
import { getStockoutRequestorDropdown } from "modules/stockflow/redux"
import { useSelector, useDispatch } from "react-redux"
import { cloneDeep } from "lodash"

function StockoutRequestorSelectContainer({
  handleChange,
  disabled,
  value,
  styles, 
  textStyles,
  placeholder,
  type,
  dealerUuid,
}) {
  const isFetching = useSelector(state => selectLoading(state, getStockoutRequestorDropdown.typePrefix))
  const stockoutRequestorDropdown = useSelector((state) => state.stockflowStockout.stockoutRequestorDropdown)
  const lang = useSelector((state) => state.constant.languages)
  const [open, setOpen] = useState(false)
  const [isOpenList] = useState(false)
  const anchorRef = useRef(null)
  const prevOpen = useRef(open)
  const prevOpenList = useRef(isOpenList)
  const [selectAll, setSelectAll] = useState(false)
  const [search, setSearch] = useState("")
  const dispatch = useDispatch()

  useEffect(() => {
    let param = { search, type }
    if (dealerUuid) {
      param.stockout_to_dealer = dealerUuid
    }
    dispatch(getStockoutRequestorDropdown(param))
  }, [search, type, dispatch, dealerUuid])

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])

  useEffect(() => {
    if (prevOpenList.current === true && isOpenList === false) {
      anchorRef.current.focus()
    }

    prevOpenList.current = isOpenList
  }, [isOpenList])

  useEffect(() => {
    setSelectAll(value.length === stockoutRequestorDropdown.length)
  }, [value, stockoutRequestorDropdown])

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
    setSearch("")
  }

  const handleSelectAll = async () => {
    if(!selectAll){
      
      let selectedRequestor = stockoutRequestorDropdown.map(p => {
        return {
          id: p.id,
          type: p.type
        }
      })
      handleChange(selectedRequestor)
    }else{
      handleChange([])
    }
  }

  const handleSelectRequestor = (requestor) => {
    let temp = cloneDeep(value)

    if(temp.findIndex(t => t.id === requestor.id && t.type === requestor.type) >= 0){
      temp = temp.filter( t => !(t.id === requestor.id && t.type === requestor.type))
    } else {
      temp.push({
        id: requestor.id,
        type: requestor.type
      })
    }
    handleChange(temp)
  }

  const handleSearch = (value) => {
    setSearch(value)
  }

  return (
    <>
      <StockoutRequestorSelectComponent
        anchorRef={anchorRef}
        disabled={disabled}
        value={value}
        handleClick={handleOpen}
        styles={styles}
        textStyles={textStyles}
        placeholder={placeholder}
        lang={lang}
      />
      <StockoutRequestorSelectPanelComponent
        selectAll={selectAll}
        value={value}
        anchorRef={anchorRef}
        open={open}
        isOpenList={isOpenList}
        handleClose={handleClose}
        isFetching={isFetching}
        stockoutRequestorDropdown={stockoutRequestorDropdown}
        handleSelectAll={handleSelectAll}
        handleSelectRequestor={handleSelectRequestor}
        handleSearch={handleSearch}
        lang={lang}
      />
    </>
  )
}

export default StockoutRequestorSelectContainer