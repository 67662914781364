import React from "react";
import {
  makeStyles,
  ButtonBase,
  Typography,
  Box
} from "@material-ui/core";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {getLang} from "../../../../../app/feature/constants";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "5px 12px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#eceff0",
    borderRadius: 4,
    width: "100%",
    //height: 43,
    overflow: "hidden"
  },
  inputText: {
    fontSize: "0.875rem",
    color: "#a4a6a8",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontWeight: "500"
  },
  filled: {
    color: "rgba(0, 0, 0, 0.87)",
  },
  clearButton: {
    minWidth: 0
  }
}))

function LanguageSelectComponent({
  handleClick,
  value,
  disabled,
  style,
  anchorRef,
  isEmpty,
  open,
  textStyle,
  lang,
}) {
  const classes = useStyles();

  return (
    <ButtonBase
      className={classes.root}
      disableRipple
      ref={anchorRef}
      onClick={handleClick}
      style={{ padding: 12, backgroundColor: 'white', ...style }}
    >
      <Typography
        variant='body2'
        className={clsx(classes.inputText, {
          [classes.filled]: !isEmpty
        })}
        style={textStyle}
      >
        {getLang(lang, 'label.LANGUAGE_UPPERCASE')}: {getLang(lang, `constant.language.${value}`)}
      </Typography>
      <Box
        style={{
          display: "flex",
          alignItems: "center"
        }}
      >
        {open ? (
          <ArrowDropUpIcon fontSize='small' />
        ) : (
          <ArrowDropDownIcon fontSize='small' />
        )}
      </Box>
    </ButtonBase>
  )
}

LanguageSelectComponent.propTypes = {
  handleClick: PropTypes.func,
  value: PropTypes.any,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  isEmpty: PropTypes.bool
}

export default LanguageSelectComponent
