import moment from 'moment'
import React, { useState, useEffect } from 'react'
import DashboardFilterPanelComponent from "./dashboardFilterPanel.component"
import { useSelector } from 'react-redux';

function DashboardFilterPanelContainer({
  filter,
  updatePeriod,
  updateDateRange,
  periodMenuItem
}) {
  const lang = useSelector((state) => state.constant.languages);
  const [anchorEl, setAnchorEl] = useState(null)

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);

    if(filter.period)
      query.set("period", filter.period)
    
    // date range sql format in moment
    if(filter.dateRange)
      query.set("start", moment(filter.dateRange[0]).format('YYYY-MM-DD'))

    if(filter.dateRange)
      query.set("end", moment(filter.dateRange[1]).format('YYYY-MM-DD'))

    if(filter){
      window.history.replaceState(null, null, `?${query}` )
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter])
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const addDateRange = (date, selectionSubDateRange) => {
    let result = moment(date).toDate()
    switch (filter.period) {
      case "daily":
        return result
      case "weekly":
        let week = Math.floor(
          moment(result).diff(selectionSubDateRange, "days") / 7
        )

        if (selectionSubDateRange) {
          if (result >= selectionSubDateRange) {
            result = moment(selectionSubDateRange).add(6, "days").toDate()
          } else {
            result = moment(selectionSubDateRange).add(-6, "days").toDate()
          }

          if (week >= 1) {
            result = moment(result)
              .add(week * 7, "days")
              .toDate()
          } else if (week < -1) {
            let negativeWeek = week + 1
            result = moment(result)
              .add(negativeWeek * 7, "days")
              .toDate()
          }
        } else {
          result = moment(result)
            .add(week * 7, "days")
            .toDate()
        }

        if (result.getTime() > Date.now()) {
          result = moment().toDate()
        }
        return result
      case "monthly":
        result = moment(result).endOf("month").toDate()
        if (result.getTime() > Date.now()) {
          result = moment().toDate()
        }

        return result
      default:
        return result
    }
  }

  const subDateRange = (date) => {
    // new date with moment
    let result = moment(date).toDate()

    switch (filter.period) {
      case "daily":
        return result
      case "weekly":
        return result
      case "monthly":
        // set first day of the month
        return (result = moment(result).startOf("month").toDate())
      default:
        return result
    }
  }

  return (
    <DashboardFilterPanelComponent
      filter={filter}
      anchorEl={anchorEl}
      handleClick={handleClick}
      handleClose={handleClose}
      addDateRange={addDateRange}
      subDateRange={subDateRange}
      updatePeriod={updatePeriod}
      updateDateRange={updateDateRange}
      periodMenuItem={periodMenuItem}
      lang={lang}
    />
  )
}

export default DashboardFilterPanelContainer