import ProfilePageComponent from "./profile.page";
import React from "react";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { connect, useSelector } from "react-redux";
import { getLang } from "app/feature/constants";

function ProfileContainer({ profileData, subscriptionPlan }) {
  const lang = useSelector(state => state.constant.languages)
  const [activeIndex, setActiveIndex] = React.useState(0);
  const tabLabels = [getLang(lang,"label.INFO_SETTING"), getLang(lang,"label.CHANGE_PASSWORD")];

  const theme = useTheme();
  const xsWidth = useMediaQuery(theme.breakpoints.down("xs"));

  const handleChange = (event, newActiveIndex) => {
    setActiveIndex(newActiveIndex);
  };

  const compProps = {
    handleChange,
    activeIndex,
  };

  return (
    <ProfilePageComponent
      {...compProps}
      profileData={profileData}
      tabLabels={tabLabels}
      xsWidth={xsWidth}
      subscriptionPlan={subscriptionPlan}
      lang={lang}
    />
  );
}

const mapStateToProps = state => ({
  profileData: state.profile,
  subscriptionPlan: state.billing.subscriptionPlan,
  
});

export default connect(
  mapStateToProps,
  null
)(ProfileContainer);

