import MuiAccordion from "@material-ui/core/Accordion";
import { withStyles } from "@material-ui/core";

export default withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, 0.1)",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
    "&:not(:last-child)": {
      borderBottom: 0
    },
    "&:before": {
      display: "none"
    },
    "&$expanded": {
      margin: "auto"
    }
  },
  expanded: {},
})(MuiAccordion);
