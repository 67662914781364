import React from 'react';
import NoResultImage from 'assets/img/no-result.png';
import { Box, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { getLang } from 'app/feature/constants';

export default function EmptyComponent() {
    const lang = useSelector(state => state.constant.languages)

    return (
        <Box style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column"
        }}>
            <img src={NoResultImage} alt="no-result" />
            <Typography color="primary" style={{
                marginTop: 10,
                fontSize: 15,
                fontWeight: 500,
                color: "#BDBDBD"
            }}>
                {getLang(lang, "label.NO_RESULT_FOUND")}
            </Typography>
        </Box>
    )
}