import { createAsyncThunk } from "@reduxjs/toolkit";
import { ASSIGN_BATCH } from "../../constants";
import serialNumberApi from "app/api/serialnumber";

export const getAssignListing = createAsyncThunk(
  `${ASSIGN_BATCH}/getAssignListing`,
  async (payload, thunkApi) => {
    return serialNumberApi
      .getAssignListing(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const getAssignProgress = createAsyncThunk(
  `${ASSIGN_BATCH}/getAssignProgress`,
  async (payload, thunkApi) => {
    return serialNumberApi
      .getAssignProgress(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const getAssignFailJob = createAsyncThunk(
  `${ASSIGN_BATCH}/getAssignFailJob`,
  async (payload, thunkApi) => {
    return serialNumberApi
      .getAssignFailJob(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const retryAssignFailJob = createAsyncThunk(
  `${ASSIGN_BATCH}/retryAssignFailJob`,
  async (payload, thunkApi) => {
    return serialNumberApi
      .retryAssignFailJob(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);
