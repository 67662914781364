/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CreditAlertPanelComponent from "./creditAlertPanel.component";
import { selectLoading } from "modules/notification";
import {
  getCreditAlertInfo,
  resetCreditAlert,
  updateCreditAlertInfo,
} from "modules/account/redux";

export default function CreditAlertPanelContainer({ disbursementInfo }) {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.constant.languages);
  const isFetching = useSelector((state) =>
    selectLoading(state, getCreditAlertInfo.typePrefix)
  );
  const alertInfo = useSelector((state) => state.disbursement.alertInfo);
  const isSubmitting = useSelector((state) =>
    selectLoading(state, updateCreditAlertInfo.typePrefix)
  );

  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    handleDataLoad();
    return () => {
      dispatch(resetCreditAlert());
    };
  }, []);

  const handleDataLoad = () => {
    dispatch(getCreditAlertInfo());
  };

  const handleSubmit = (values) => {
    dispatch(
      updateCreditAlertInfo({
        has_alert: values.hasAlert,
        alert_email: values.recipients,
        alert_threshold: values.alertThreshold,
        transaction_threshold: values.transactionThreshold,
      })
    ).then(() => {
      handleDataLoad();
      setIsEdit(false);
    });
  };

  return (
    <CreditAlertPanelComponent
      lang={lang}
      alertInfo={alertInfo}
      isFetching={isFetching}
      isError={alertInfo.isError}
      handleReload={handleDataLoad}
      isEdit={isEdit}
      setIsEdit={setIsEdit}
      handleSubmit={handleSubmit}
      isSubmitting={isSubmitting}
      disbursementInfo={disbursementInfo}
    />
  );
}
