/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CreditBalanceOverviewPanelComponent from "./creditBalanceOverviewPanel.component";
import { selectLoading } from "modules/notification";
import { getCreditOverview, resetCreditOverview } from "modules/account/redux";

export default function CreditBalanceOverviewPanelContainer({
  disbursementInfo,
}) {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.constant.languages);
  const isFetching = useSelector((state) =>
    selectLoading(state, getCreditOverview.typePrefix)
  );
  const overview = useSelector((state) => state.disbursement.overview.data);
  const isError = useSelector((state) => state.disbursement.overview.list);

  useEffect(() => {
    handleDataLoad();
    return () => {
      dispatch(resetCreditOverview());
    };
  }, []);

  const handleDataLoad = () => {
    dispatch(getCreditOverview());
  };

  return (
    <CreditBalanceOverviewPanelComponent
      lang={lang}
      isFetching={isFetching}
      isError={isError}
      handleReload={handleDataLoad}
      overview={overview}
      disbursementInfo={disbursementInfo}
    />
  );
}
