import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { SIGNUP } from "../../constants";
import accountApi from "app/api/account";
import paymentApi from "app/api/payment";
import { LABEL_COMPANY } from "modules/admin/constants";
import authApi from "app/api/authentication";

export const submitSignupProfile = createAction(
	`${SIGNUP}/submitSignupProfile`
);
export const addPaymentMethod = createAction(`${SIGNUP}/addPaymentMethod`);

export const submitSignupCompany = createAction(
	`${SIGNUP}/submitSignupCompany`
);
export const fetchSignupData = createAction(`${SIGNUP}/fetchSignupData`);

export const isValidReseller = createAsyncThunk(
	`${SIGNUP}/isValidReseller`,
	async (payload, { rejectWithValue }) => {
		const { code } = payload;
		return accountApi
			.getCollaborationGroupByTypeAndCode({
				type: 'reseller',
				code: code,
			})
			.then(response => response.data)
			.catch(error => rejectWithValue(error.data))
	}
);
export const isValidPartner = createAsyncThunk(
	`${SIGNUP}/isValidPartner`,
	async (payload, { rejectWithValue }) => {
		const { code } = payload;
		return accountApi
			.getCollaborationGroupByTypeAndCode({
				type: 'partner',
				code: code,
			})
			.then(response => response.data)
			.catch(error => rejectWithValue(error.data))
	}
);

export const signup = createAsyncThunk(
	`${SIGNUP}/signup`,
	async (payload, { rejectWithValue }) => {
		const { username, email, password, confirmPassword, metadata } = payload;

		// return new Auth0Client()
		//   .signup(email, password, username)
		//   .then(newUser => newUser)
		//   .catch(error => rejectWithValue(error));

		return authApi.signUp({
			username,
			email,
			password,
			confirmPassword,
			metadata
		})
			.then(response => response.data.data)
			.catch(error => rejectWithValue(error.response.data))
	}
);

export const checkResendVerificationEmailResendTimeLeft = createAsyncThunk(
	`${SIGNUP}/checkResendVerificationEmailResendTimeLeft`,
	async (payload, { getState, rejectWithValue }) => {
		const { sub } = getState().session.idTokenPayload;
		return accountApi.checkResendVerificationEmailResendTimeLeft({ userId: sub })
			.then(response => response)
			.catch(error => rejectWithValue(error))
	}
);

export const resendEmailVerification = createAsyncThunk(
	`${SIGNUP}/resendEmailVerification`,
	async (payload, { getState, rejectWithValue }) => {
		const { email } = getState().session.idTokenPayload;

		return authApi.resendVerificationEmail({ email })
			.then(response => response.data.data)
			.catch(error => rejectWithValue(error.response.data))
	}
);

export const setUserProfile = createAsyncThunk(
	`${SIGNUP}/setUserProfile`,
	async (payload, { rejectWithValue, dispatch }) => {
		return accountApi.profile
			.put({
				first_name: payload.firstName,
				last_name: payload.lastName,
				phone_country: payload.country,
				phone_number: payload.phone,
				phone_code: payload.number,
				newsletter: payload.newsletter,
				image_policy_url: payload.privacyPolicySignatureUrl,
				image_agreement_url: payload.endUserAgreementSignatureUrl,
				locale: payload.language,
				timezone: payload.timezone
			})
			.then(response => response)
			.catch(error => rejectWithValue(error));
	}
);

export const submitCompanyInfo = createAsyncThunk(
	`${SIGNUP}/submitCompanyInfo`,
	async (payload, { rejectWithValue, getState }) => {
		const labels = getState().session.labels;
		const hasSubmitted = getState().signup.hasSubmittedCompany;
		const companyExist =
			labels.some(label => label.key === LABEL_COMPANY) || hasSubmitted;

		if (companyExist) {
			return accountApi.company
				.put(payload)
				.then(response => response)
				.catch(error => rejectWithValue(error));
		} else {
			return accountApi.company
				.post(payload)
				.then(response => response)
				.catch(error => rejectWithValue(error));
		}
	}
);

export const setSignupPaymentMethod = createAsyncThunk(
	`${SIGNUP}/setSignupPaymentMethod`,
	async (payload, { rejectWithValue }) => {
		const addPresubscription = await paymentApi.presubscription
			.post({
				plan: payload.plan
			})
			.then(response => response)
			.catch(error => ({ error }));

		if (addPresubscription.error) {
			return rejectWithValue(addPresubscription.error);
		}

		const addPayment = await paymentApi.paymentMethod
			.post({
				payment_method: payload.pmTokenID,
				isDefault: true
			})
			.then(response => response)
			.catch(error => ({ error }));

		if (addPayment.error) {
			return rejectWithValue(addPayment.error);
		}

		return { addPresubscription, addPayment };
	}
);

export const verifyEmployee = createAsyncThunk(
	`${SIGNUP}/verifyEmployee`,
	async (payload, { rejectWithValue }) => {
		return accountApi.verifyEmployee({ id: payload.id, token: payload.token })
			.then(response => response)
			.catch(error => rejectWithValue(error))
	}
)

export const signupEmployee = createAsyncThunk(
	`${SIGNUP}/signupEmployee`,
	async (payload, { rejectWithValue }) => {
		return accountApi.signupEmployee({
			id: payload.id,
			token: payload.token,
			username: payload.username,
			password: payload.password,
			confirm_password: payload.confirmPassword,
			timezone: payload.timezone
		})
			.then(response => response)
			.catch(error => rejectWithValue(error));
	}
);

export const verifyEmail = createAsyncThunk(
	`${SIGNUP}/verifyEmail`,
	async (payload, { rejectWithValue }) => {
		return authApi.verifyEmail({
			email: payload.email,
			token: payload.token
		})
			.then(response => response.data.data)
			.catch(error => rejectWithValue(error.response.data));
	}
)

export const validateResetPasswordToken = createAsyncThunk(
	`${SIGNUP}/validateResetPasswordToken`,
	async (payload, { rejectWithValue }) => {
		return authApi.validateResetPasswordToken({
			email: payload.email,
			token: payload.token
		})
			.then(response => response.data.data)
			.catch(error => rejectWithValue(error.response.data));
	}
)

export const resetPassword = createAsyncThunk(
	`${SIGNUP}/resetPassword`,
	async (payload, { rejectWithValue }) => {
		return authApi.resetPassword({
			email: payload.email,
			token: payload.token,
			password: payload.password,
			confirmPassword: payload.confirmPassword
		})
			.then(response => response.data.data)
			.catch(error => rejectWithValue(error.response.data))
	}
)