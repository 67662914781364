import {
  Box,
  Button,
  Grid,
  makeStyles,
  Menu,
  MenuItem,
  withStyles,
} from "@material-ui/core"
import moment from "moment"
import React from "react"
import { DateRangePicker } from "rsuite"
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown"
import "rsuite/dist/rsuite.min.css"
import "modules/reporting/assets/css/customDateRangePicker.css"
import { layoutStyle } from "modules/reporting/styles"
import CustomMenu from "modules/reporting/components/menu"
import { getLang } from "app/feature/constants"

const useStyles = makeStyles((theme) => ({
  filterContainer: {
    background: "#FFFFFF",
    padding: "0px 32px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
}))

const CustomButton = withStyles((theme) => ({
  root: {
    minWidth: "36px",
  },
  label: {
    fontSize: "12px",
    fontFamily: "Roboto",
    fontWeight: 400,
    color: "#344054",
    lineHeight: "14.4px",
    textTransform: "none",
  },
}))(Button)

export default function DashboardFilterPanel({ 
  filter,
  anchorEl,
  handleClick,
  handleClose,
  subDateRange,
  addDateRange,
  updatePeriod,
  updateDateRange,
  periodMenuItem,
  lang
}) {
  const classes = useStyles()
  const layoutClass = layoutStyle()

  // range selection
  let selectionSubDateRange = null

  return (
    <Box className={classes.filterContainer}>
      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
        {/* right side */}
        <Box className={layoutClass.layout__filter_right_sides}>
          <CustomMenu
            menuItems={periodMenuItem}
            selected={filter.period}
            action={updatePeriod}
            tag="Period"
          />
          <Box display="flex" alignContent="center" flexWrap="wrap">
            <DateRangePicker
              // config
              character=" - " // separator between two dates
              cleanable={false}
              placeholder={getLang(lang, "placeholder.SELECT_DATE_RANGE")}
              format="dd MMMM yyyy"
              placement="auto"
              disabledDate={(date) => date.getTime() > Date.now()}
              editable={false}
              hoverRange={(date, test) => [
                subDateRange(date, test),
                addDateRange(date, selectionSubDateRange, test),
              ]}
              ranges={[]}
              // get date range start and end
              onChange={(value) => {
                // value[0] = start date
                // value[1] = end date
                updateDateRange(value[0], value[1], null)
              }}
              onSelect={(value) => {
                if (selectionSubDateRange) {
                  selectionSubDateRange = null
                } else {
                  selectionSubDateRange = value
                }
              }}
              value={filter.dateRange}
            />
            <Box
              style={{
                padding: 0,
              }}
            >
              <CustomButton
                onClick={handleClick}
                variant="outlined"
                endIcon={<ArrowDropDownIcon />}
                style={{
                  borderTopLeftRadius: "0px",
                  borderBottomLeftRadius: "0px",
                  padding: "7px 0px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                }}
              ></CustomButton>
              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem
                  selected={null}
                  onClick={() => {
                    updateDateRange(
                      moment().subtract(7, "days").toDate(),
                      moment().toDate(),
                      "daily"
                    )
                    handleClose()
                  }}
                >
                  {getLang(lang, "label.LAST_COUNT_DAYS", { day: 7 })}
                </MenuItem>
                <MenuItem
                  selected={null}
                  onClick={() => {
                    updateDateRange(
                      moment().subtract(14, "days").toDate(),
                      moment().toDate(),
                      "daily"
                    )
                    handleClose()
                  }}
                >
                  {getLang(lang, "label.LAST_COUNT_DAYS", { day: 14 })}
                </MenuItem>
                <MenuItem
                  selected={null}
                  onClick={() => {
                    updateDateRange(
                      moment().subtract(28, "days").toDate(),
                      moment().toDate(),
                      "daily"
                    )
                    handleClose()
                  }}
                >
                  {getLang(lang, "label.LAST_COUNT_DAYS", { day: 28 })}
                </MenuItem>
                <MenuItem
                  selected={null}
                  onClick={() => {
                    updateDateRange(
                      moment().startOf("month").toDate(),
                      moment().toDate(),
                      "daily"
                    )
                    handleClose()
                  }}
                >
                  {getLang(lang, "label.THIS_MONTH")}
                </MenuItem>
                <MenuItem
                  selected={null}
                  onClick={() => {
                    updateDateRange(
                      moment().subtract(1, "month").startOf("month").toDate(),
                      moment().subtract(1, "month").endOf("month").toDate(),
                      "daily"
                    )
                    handleClose()
                  }}
                >
                  {getLang(lang, "label.LAST_MONTH")}
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Box>
  )
}
