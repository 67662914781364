import React from "react";
import {
  makeStyles,
  Typography,
  Box,
  Button,
  Grid,
  InputAdornment,
  Checkbox,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@material-ui/core";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import CustomTextField from "components/textField"
import { getLang } from "app/feature/constants";


const useStyle = makeStyles((theme) => ({
  root: {
    width: "70%",
    [theme.breakpoints.down("sm")]: {
      width: "80%"
    },
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  button: {
    textTransform: "capitalize",
    [theme.breakpoints.down("sm")]: {
      fontSize: "small",
      padding: theme.spacing(1, 2, 1, 2)
    }
  },
  title: {
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      marginBottom: theme.spacing(1)
    }
  },
  label: {
    fontSize: "smaller"
  },
  errorMessage: {
    marginTop: theme.spacing(0),
    lineHeight: 1.0,
  }
}));

export default function SignUpFormComponent({
  handleSubmit,
  email,
  isLoading,
  lang,
  handleClickShowPassword,
  handleMouseDownPassword,
  isPasswordShown,
  timezones,
  timezone
}) {
  const classes = useStyle();

  const textFieldProps = {
    fullWidth: true,
    variant: "outlined",
    size: "small",
    margin: "dense"
  };

  return (
    <Box className={classes.root}>
      <Box>
        <Typography variant="body1" className={classes.title}>
          {getLang(lang, "paragraph.SIGN_UP_KOOD_TODAY")}
        </Typography>
        <Formik
          initialValues={{
            email: email || "",
            username: "",
            password: "",
            confirmPassword: "",
            timezone: timezone
          }}
          validationSchema={Yup.object({
            username: Yup.string()
              .trim()
              .required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED"))
              .min(8, getLang(lang, "message.error.FORM_VALIDATE_MIN", { value: getLang(lang, "message.error.CHARACTERS_8") }))
              .max(50, getLang(lang, "message.error.FORM_VALIDATE_MAX", { value: 50 }))
              .matches(
                /^[a-zA-Z0-9-_@.]+$/,
                getLang(lang, "message.error.FORM_VALIDATE_ALPANUMERIC_DASH_UNDERSCORE_AT_SIGN_DOT")
              ).matches(
                /^\S*$/,
                getLang(lang,"message.error.FORM_VALIDATE_STRING_NO_SPACES")
              ),
            password: Yup.string()
              .required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED"))
              .min(8, getLang(lang, "message.error.FORM_VALIDATE_MIN", { value: getLang(lang, "message.error.CHARACTERS_8") }))
              .matches(
                /^(?=.*[a-z])/,
                getLang(lang, "message.error.FORM_VALIDATE_MATCH", { value: getLang(lang, "message.error.LOWERCASE_CHARACTER_1") })
              )
              .matches(
                /^(?=.*[A-Z])/,
                getLang(lang, "message.error.FORM_VALIDATE_MATCH", { value: getLang(lang, "message.error.UPPERCASE_CHARACTER_1") })
              )
              .matches(/[!@#$%^&~`*()_+\-={}[\]:";'<>,.?|]/, getLang(lang, "message.error.FORM_VALIDATE_MATCH", { value: getLang(lang, "message.error.SPECIAL_CHARACTER_1") }))
              .matches(/^(?=.*[0-9])/, getLang(lang, "message.error.FORM_VALIDATE_MATCH", { value: getLang(lang, "message.error.NUMBER_1") }))
              .matches(
                /^\S*$/,
                getLang(lang, "message.error.FORM_VALIDATE_STRING_NO_SPACES")
              ),
            confirmPassword: Yup.string()
              .oneOf([Yup.ref("password"), null], getLang(lang, "message.error.FORM_VALIDATE_PASSWORD"))
              .required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
            email: Yup.string()
              .email(getLang(lang, "message.error.FORM_VALIDATE_EMAIL"))
              .required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
            timezone: Yup.string(),
          })}
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              {[
                {
                  key: "email",
                  label: getLang(lang, "label.EMAIL"),
                  placeholder: getLang(lang, "placeholder.EMAIL"),
                  editable: email ? true : false
                },
                {
                  key: "username",
                  label: getLang(lang, "label.USERNAME"),
                  placeholder: getLang(lang, "placeholder.USERNAME"),
                },
                {
                  key: "password",
                  label: getLang(lang, "label.PASSWORD"),
                  placeholder: getLang(lang, "placeholder.CREATE_NEW_PASSWORD"),
                  type: "password",
                },
                {
                  key: "confirmPassword",
                  label: getLang(lang, "label.CONFIRM_NEW_PASSWORD"),
                  placeholder: getLang(lang, "placeholder.CONFIRM_NEW_PASSWORD"),
                  type: "password",
                },
                {
                  key: "timezone",
                  label: "Timezone ",
                  placeholder: "Timezone",
                  showTimezone: email ? true : false,
                  value: formik.values.timezone
                },
              ].map((field, index) => (
                <React.Fragment key={index}>
                  {field.editable ? (
                    <Box py={2}>
                      <Typography>
                        {getLang(lang, "label.LOGIN_ID")}: <span className={classes.title}>{email}</span>
                      </Typography>
                    </Box>
                  ) :
                    field.showTimezone ? (

                      <FormControl
                        fullWidth
                        variant="outlined"
                        size="small"
                        margin="dense"
                      >
                        <InputLabel className={classes.label}>
                          {field.label}<span style={{ color: "#DE350B", display: 'inline-block' }}>*</span>
                        </InputLabel>
                        <Select
                          name={field.key}
                          {...textFieldProps}
                          disabled={formik.isSubmitting || isLoading}
                          {...formik.getFieldProps(field.key)}
                          label={field.label}
                          value={field.value}
                          error={
                            formik.touched[field.key] && formik.errors[field.key]
                              ? true
                              : false
                          }
                          // helperText={ErrorMessage({ name: field.key })}
                          helperText={<div className={classes.errorMessage}><ErrorMessage name={field.key} /></div>}
                        >
                          {timezones.map((select, index) => {

                            return (
                              <MenuItem key={index} value={select.id}>
                                {select.text}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>

                    ) : field.key === "timezone" ? (
                      null
                    ) :
                      field.type === "password" ? (
                        <CustomTextField
                          key={index}
                          {...textFieldProps}
                          {...formik.getFieldProps(field.key)}
                          type={isPasswordShown ? "text" : field.type}
                          label={<>{field.label} <span style={{ color: "#DE350B", display: 'inline-block' }}>*</span></>}
                          placeholder={field.placeholder}
                          error={
                            formik.touched[field.key] && formik.errors[field.key]
                              ? true
                              : false
                          }
                          // helperText={ErrorMessage({ name: field.key })}
                          helperText={<div className={classes.errorMessage}><ErrorMessage name={field.key} /></div>}
                          disabled={formik.isSubmitting || isLoading}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position='end'>

                              </InputAdornment>
                            )
                          }}
                        />
                      ) : (
                        <CustomTextField
                          key={index}
                          {...textFieldProps}
                          {...formik.getFieldProps(field.key)}
                          type={field.type || "text"}
                          label={<>{field.label}<span style={{ color: "#DE350B", display: 'inline-block' }}>*</span></>}
                          placeholder={field.placeholder}
                          error={
                            formik.touched[field.key] && formik.errors[field.key]
                              ? true
                              : false
                          }
                          // helperText={ErrorMessage({ name: field.key })}
                          helperText={<div className={classes.errorMessage}><ErrorMessage name={field.key} /></div>}
                          disabled={formik.isSubmitting || isLoading}
                          onKeyDown={field.key === "username" ? (e) => {
                            if (e.keyCode === 32) {
                              e.preventDefault();
                              return false;
                            }
                          } : null}

                        />
                      )}
                </React.Fragment>
              ))}
              <FormControlLabel
                control={
                  <Checkbox
                    color="secondary"
                    disabled={formik.isSubmitting || isLoading}
                    onChange={(state) => {
                      handleClickShowPassword(state.isPasswordShown)
                    }}
                  />
                }
                label={<Typography variant="body2" className={classes.signed}>
                  {getLang(lang, "label.SHOW_PASSWORD")}
                </Typography>}
              />

              <Box mt={1.5}>
                <Grid container>
                  <Grid
                    item
                    xs={5}
                    sm={4}
                    md={4}
                    style={{ width: "100%", alignSelf: "center" }}
                  >
                    <Typography variant="caption" color="textSecondary">
                      <Typography style={{ color: "#DE350B", display: 'inline-block' }}>*</Typography>  {getLang(lang, "label.REQUIRED_FIELD")}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={7}
                    sm={8}
                    md={8}
                    style={{ width: "100%", textAlign: "end" }}
                  >
                    <Button
                      variant="contained"
                      type="submit"
                      color="secondary"
                      size="large"
                      className={classes.button}
                      disabled={formik.isSubmitting || isLoading}
                    >
                      {formik.isSubmitting || isLoading ? getLang(lang, "label.LOADING") : getLang(lang, "paragraph.LETS_STARTED")}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Box>
  );
}
