import React from 'react';
import { Backdrop, Dialog, makeStyles } from '@material-ui/core';
import { AddPointForm } from '../../form';

const useStyles = makeStyles(() => ({
  dialog: {
    maxWidth: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  dialogContent: {
    flex: 1,
    overflowY: 'auto',
  },
}));

export default function AddPointDialogComponent({
  headerType,
  isOpen,
  handleClose,
}) {
  const classes = useStyles();

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      disableEnforceFocus
      disableAutoFocus
      disableEscapeKeyDown
      disableBackdropClick
      closeAfterTransition
      BackdropComponent={Backdrop}
      maxWidth="sm"
      classes={{
        paperWidthSm: classes.dialog,
      }}
    >
      <div className={classes.dialogContent}>
        <AddPointForm handleClose={handleClose} headerType={headerType} />
      </div>
    </Dialog>
  );
}
