import React from "react";
import {
  makeStyles,
  Box,
  Typography,
  Button,
  Tabs,
  Divider,
  Switch
} from "@material-ui/core";
import { TYPES } from "./authForm.container";
import { ModalFooter, ContentWrapper } from "../../modal";
import LanguageTab from "../../tab/languageTab";
import clsx from "clsx";
import { purple } from "@material-ui/core/colors";
import EllipseSvg from "assets/svg/Ellipse.svg";
import LANGUAGES from "lib/constants/languages";
import Input from "components/input/inputTextField";
import DefaultImg from "assets/img/img-default.png";
import langHelper from "lib/langHelper";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles(theme => ({
  root: {
    maxHeight: 500,
    overflow: "hidden",
    overflowY: "scroll",
    paddingRight: theme.spacing(1)
  },
  tabsRoot: {
    textTransform: "none"
  },
  indicator: {
    border: "2px solid",
    borderColor: theme.palette.primary.main,
    width: "50px !important",
    borderRadius: theme.spacing(1, 1, 0, 0)
  },
  indicatorLang: {
    transform: "translateX(12px)"
  },
  scroll: {
    width: "min-content"
  },
  authContentWrapper: {
    maxWidth: 345,
    margin: "auto"
  },
  authCard: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)",
    borderRadius: theme.spacing(0.5)
  },
  btnSecondary: {
    color: theme.palette.common.white
  },
  authContent: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    backgroundImage: `url(${EllipseSvg})`,
    backgroundSize: "100% 53%",
    backgroundRepeat: "no-repeat",
    position: "relative",
    paddingBottom: theme.spacing(2)
  },
  productName: {
    fontWeight: theme.typography.fontWeightBold,
    color: purple[400],
    marginTop: theme.spacing(1)
  },
  productImageContainer: {
    height: 90,
    width: 90,
    border: "2px dashed #1FD026",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  productImage: {
    borderRadius: "50%",
    height: 80,
    width: 80,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    "& img": {
      height: "100%",
      minHeight: "100%",
      minWidth: "100%",
      objectFit: "cover",
      flexShrink: 0
    }
  },
  genuineLabel: {
    fontWeight: theme.typography.fontWeightBold,
    color: "#1FD026"
  },
  helpIcon: {
    cursor: "pointer",
    color: theme.palette.primary.main,
    fontSize: 16
  },
  fade50: {
    opacity: 0.5
  },
  genuineBackground: {
    width: 200,
    position: "absolute",
    bottom: 0,
    right: 0
  },
  authCompSwitchContainer: {
    display: "flex"
  }
}));

export default function AuthFormComponent({
  type,
  handleSubmit,
  handleDelete,
  activeLang,
  handleChangeTabLang,
  lang,
  authentication,
  isEditing,
  handleEnableEdit,
  activeMessage,
  handleChangeActiveMessage,
  handleSave,
  productInfo,
  handleClose,
  languages,
  isSnHidden,
  handleChangeIsSnHidden,
  userLanguage
}) {
  const classes = useStyle();
  const message = authentication.messages[activeLang].value || getLang(languages, "label.YOUR_MESSAGE_HERE");

  return (
    <Box mb={type === TYPES[2] ? 2 : 0}>
      <ContentWrapper disablePadding={true} resetPaper={true}>
        <Box className={classes.authContentWrapper}>
          <Tabs
            value={activeLang}
            onChange={handleChangeTabLang}
            aria-label="scrollable auto tabs example"
            textColor="primary"
            variant="scrollable"
            indicatorColor="primary"
            classes={{
              root: classes.tabsRoot,
              indicator: clsx(classes.indicator, classes.indicatorLang),
              scrollButtons: classes.scroll
            }}
          >
            {lang.map((label, index) => {
              const LABEL = LANGUAGES.some(langObj => langObj.code === label);
              return (
                <LanguageTab
                  key={index}
                  label={LABEL ? label : label.split("-")[1]}
                />
              );
            })}
          </Tabs>
          <Box className={classes.authCard}>
            <Box className={classes.authContent}>
              <Box display="flex" style={{ paddingTop: "24px", paddingBottom: "0.5em" }}>
                <Box className={classes.productImageContainer}>
                  <Box className={classes.productImage}>
                    <img src={productInfo.picture || DefaultImg} alt="" />
                  </Box>
                </Box>
                <Box style={{ marginLeft: 16 }}>
                  <Typography
                    variant="body1"
                    className={clsx(
                      classes.productName,
                      isEditing && classes.fade50
                    )}
                  >
                    {productInfo.name}
                  </Typography>
                  {isEditing ? (
                    <Input
                      required
                      hiddenLabel
                      variant="filled"
                      size="small"
                      margin="none"
                      placeholder={getLang(languages, "placeholder.TYPE_MESSAGE_HERE")}
                      fullWidth
                      multiline={true}
                      rowsMax={4}
                      value={activeMessage}
                      onChange={handleChangeActiveMessage}
                    />
                  ) : (
                    <Typography variant="body2">{message}</Typography>
                  )}
                </Box>
              </Box>
              {!isSnHidden && (
                <Box style={{ marginLeft: "0.5em" }}>
                  <Typography variant="caption" color="textSecondary">
                    {getLang(languages, "label.SERIAL_NUMBER")}
                  </Typography>
                  <Box display="flex" alignItems="center">
                    <Typography variant="body2">A783k929443283CEF</Typography>
                  </Box>
                </Box>
              )}
              <Box style={{ marginTop: "8px", marginLeft: "0.5em" }}>
                <Typography variant="caption" color="textSecondary">
                  {getLang(languages, "label.SCAN_COUNT")}
                </Typography>
                <Box display="flex" alignItems="center">
                  <Typography variant="body2">5</Typography>
                </Box>
              </Box>
              <img className={classes.genuineBackground} src={langHelper.getGenuineBackground(userLanguage)} alt="genuine" />
            </Box>
            <Divider />
            {isEditing && (
              <>
                <Box
                  px={2}
                  py={1}
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <Typography variant="subtitle1">{getLang(languages, "label.HIDE_SERIAL_NUMBER")}</Typography>
                  <Switch
                    color="primary"
                    checked={isSnHidden}
                    onChange={handleChangeIsSnHidden}
                  />
                </Box>
                <Divider />
              </>
            )}
            <Box
              px={2}
              py={1}
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
            >
              <Button
                variant="contained"
                color="secondary"
                size="small"
                classes={{ root: classes.btnSecondary }}
                disableElevation
                onClick={!isEditing ? handleEnableEdit : handleSave}
              >
                {isEditing ? getLang(languages, "label.SAVE") : getLang(languages, "label.EDIT")}
              </Button>
            </Box>
          </Box>
        </Box>
      </ContentWrapper>
      <ModalFooter
        handleDelete={handleDelete}
        showDelete={type !== TYPES[0]}
        handleClick={handleSubmit}
        handleClose={handleClose}
      />
    </Box>
  );
}
