import React from "react";
import { makeStyles, ButtonBase, Typography, Box } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import clsx from "clsx";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles((theme) => ({
  root: {
    padding: "5px 12px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#ECEFF0",
    borderRadius: "4px",
    width: "100%",
  },
  inputText: {
    color: "#a4a6a8",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontWeight: 500,
  },
  filled: {
    minWidth: "auto",
    fontWeight: 500,
    color: theme.palette.primary.main,
    display: "inline-block",
    flexWrap: "wrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "100%",
    alignSelf: "center",
  },
  filledWithNum: {
    minWidth: "auto",
    fontWeight: 500,
    color: theme.palette.primary.main,
    display: "inline-block",
    flexWrap: "wrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "70%",
    alignSelf: "center",
  },
  textNum: {
    fontWeight: 500,
    color: theme.palette.primary.main,
    display: "inline-block",
  },
}));

export default function BranchSelectComponent({
  anchorRef,
  handleClick,
  value,
  styles,
  disabled,
  textStyles,
  lang,
  placeholder,
  open,
}) {
  const classes = useStyle();

  const getDisplay = () => {
    let display = placeholder || getLang(lang, "placeholder.SELECT_A_BRANCH");
    if (value?.length > 0) {
      display = value[0].name;
    }
    return display;
  };
  const displayValue = () => {
    return (
      <Box
        style={{
          display: "flex",
          flexWrap: "wrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          flex: "2",
        }}
      >
        <Typography
          variant="body2"
          noWrap
          display="inline"
          className={clsx(
            classes.inputText,
            value.length > 1 && classes.filledWithNum,
            getDisplay() !== getLang(lang, "placeholder.SELECT_A_BRANCH") &&
              getDisplay() !== placeholder &&
              classes.filled
          )}
        >
          {getDisplay()}
        </Typography>
        {value.length > 1 ? (
          <Typography
            className={classes.textNum}
            variant="body2"
            display="inline"
          >
            {" "}
            &nbsp; + {value.length - 1}
          </Typography>
        ) : null}
      </Box>
    );
  };

  return (
    <ButtonBase
      className={clsx(classes.root, {
        [classes.disabledField]: disabled,
      })}
      disableRipple
      ref={anchorRef}
      style={styles}
      onClick={handleClick}
      disabled={disabled}
    >
      {displayValue()}
      {open ? (
        <ArrowDropUpIcon fontSize="small" />
      ) : (
        <ArrowDropDownIcon fontSize="small" />
      )}
    </ButtonBase>
  );
}
