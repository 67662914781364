import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Typography,
  Tabs,
  makeStyles,
  Divider,
  Grid
} from "@material-ui/core";
import { ProfilePanel } from "../../components/panel";
import { ChangePasswordPanel } from "../../components/panel";
import PhotoProfilePanel from "../../components/panel/photoProfilePanel";
import CustomTab from "components/tab";
import { ArrowImg } from "../../utils/static";
import FadeText from "modules/profile/components/animationFade";
import { LITE, BUSINESS, PROFESSIONAL } from "lib/constants/subscriptionPlan";
import { getLang } from "app/feature/constants";

const style = theme => ({
  wrapper: {
    background: theme.palette.background.paper
  },
  root: {
    [theme.breakpoints.up("lg")]: {
      display: "grid",
    },
    gridTemplateColumns: "7fr 3fr",
    gridTemplayeRows: "auto auto",
    columnGap: theme.spacing(2),
    gridTemplateAreas: "'topbar .''panel photo'",
    [theme.breakpoints.down("md")]: {
      gridTemplateAreas: "'topbar topbar''panel photo'"
    },
    [theme.breakpoints.down("sm")]: {
      gridTemplateAreas: "'topbar topbar''panel panel''photo photo'"
    }
  },
  indicator: {
    border: "0.5px solid",
    borderColor: theme.palette.primary.main
  },
  scroll: {
    width: "min-content"
  },
  planText: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "small"
    }
  },
  listIcon: {
    display: "list-item",
    [theme.breakpoints.down("xs")]: {
      listStyleType: "disc",
      fontSize: "small"
    }
  },
  title: {
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(2)
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: theme.spacing(1)
    }
  }
});

const useStyles = makeStyles(style);

export default function ProfilePageComponent({
  handleChange,
  activeIndex,
  tabLabels,
  xsWidth,
  profileData,
  subscriptionPlan,
  lang
}) {
  const classes = useStyles();

  const getPlanName = (type) => {
    switch (type) {
      case LITE:
        return "Lite Plan";

      case BUSINESS:
        return "Biz Plan";

      case PROFESSIONAL:
        return "Pro Plan";

      default:
        break;
    }
  };

  const getsubscriptionInterval = (type) => {
    switch (type) {
      case "month":
        return getLang(lang,"label.MONTHLY_SUBSCRIPTION");

      case "year":
        return getLang(lang,"label.YEARLY_SUBSCRIPTION");

      default:
        break;
    }
  };

  function a11yProps(index, label = "label") {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
      label: <Typography variant="button" children={label} />
    };
  }

  function TabPanel({ children, value, index, ...other }) {
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {activeIndex === index && <Box>{children}</Box>}
      </Typography>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
  };
  
  return (
    <Box className={classes.root}>
      <Box gridArea="topbar">
        <Grid container>
          <Grid item xs={8} sm={5} md={5} style={{ width: "100%" }}>
            <Box mb={1} display="flex" alignItems="center">
              <Typography
                variant="h6"
                color="primary"
                className={classes.account}
              >
                {getLang(lang,"label.PROFILE")}
              </Typography>

              <Box className={classes.title}>
                <ArrowImg />
              </Box>
              <Box>
                <Typography
                  variant="h6"
                  color="primary"
                  className={classes.title}
                >
                  {tabLabels[activeIndex]}
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid
            item
            xs={4}
            sm={7}
            md={7}
            style={{ width: "100%", alignSelf: "center" }}
          >
            <Box display="flex" justifyContent="flex-end">
              {xsWidth ? (
                <FadeText
                  companyName={profileData.companyName}
                  planName={profileData.registrationType === 1 && getPlanName(subscriptionPlan.productName)}
                  intervalPlan={profileData.registrationType === 1 && getsubscriptionInterval(
                    subscriptionPlan.interval
                  )}
                />
              ) : (
                <>
                  <Typography
                    variant="body2"
                    color="primary"
                    className={classes.planText}
                  >
                    {profileData.companyName}
                  </Typography>
                  {profileData.role === "owner" && profileData.registrationType === 1 &&
                    <>
                      <Box ml={3} className={classes.listIcon}>
                        <Typography
                          variant="body2"
                          color="primary"
                          className={classes.planText}
                        >
                          {getPlanName(subscriptionPlan.productName)}
                        </Typography>
                      </Box>
                      <Box ml={3} className={classes.listIcon}>
                        <Typography
                          variant="body2"
                          color="primary"
                          className={classes.planText}
                        >
                          {getsubscriptionInterval(subscriptionPlan.interval)}
                        </Typography>
                      </Box>
                    </>
                  }
                </>
              )}
            </Box>
          </Grid>
        </Grid>
        <Divider />
      </Box>
      <Box gridArea="panel">
        <Box>
          <Box>
            <Tabs
              value={activeIndex}
              onChange={handleChange}
              aria-label="scrollable auto tabs example"
              textColor="primary"
              variant="scrollable"
              indicatorColor="primary"
              classes={{
                indicator: classes.indicator,
                scrollButtons: classes.scroll,
              }}
            >
              <CustomTab {...a11yProps(0, tabLabels[0])} />
              <CustomTab {...a11yProps(1, tabLabels[1])} />
            </Tabs>
          </Box>

          <Box>
            <TabPanel value={activeIndex} index={0}>
              <ProfilePanel />
            </TabPanel>
            <TabPanel value={activeIndex} index={1}>
              <ChangePasswordPanel />
            </TabPanel>
          </Box>
        </Box>
      </Box>
      <Box gridArea="photo" mt={{ xs: 3, sm: 3, md: 6 }}>
        <PhotoProfilePanel />
      </Box>
    </Box>
  );
}
