import React from "react";
import InstantLuckyDrawRegFormComponent from "./instantLuckyDrawRegForm.component";
import { useDispatch, useSelector } from "react-redux";
import {
  reorderRegisterFormField,
} from "modules/instant-lucky-draw/redux";

export default function InstantLuckyDrawRegFormContainer({ children, type, formRef }) {
  const dispatch = useDispatch();
  const instantLuckyDrawRegisterForm = useSelector(
    (state) => state.instantLuckyDraw.InstantLuckyDrawDialogInfo.registrationForm
  );

  const lang = useSelector(state => state.constant.languages)

  const handleDragEnd = (destination, source) => {
    if (!destination) return;

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    )
      return;

    dispatch(
      reorderRegisterFormField({
        sourceIndex: source.index,
        destinationIndex: destination.index,
        latestform:formRef.current.values.form
      })
    );
  };


  return (
    <InstantLuckyDrawRegFormComponent
      children={children}
      instantLuckyDrawRegisterForm={instantLuckyDrawRegisterForm}
      type={type}
      formRef={formRef}
      handleDragEnd={handleDragEnd}
      lang={lang}
    />
  );
}
