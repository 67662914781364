import React from "react";
import { useSelector } from "react-redux";
import ComparePlanDialogComponent from "./comparePlanDialog.component";

export default function ComparePlanDialogContainer({
  registrationType,
  userSubscriptionPlan,
  isCompareDialogOpen,
  subscriptionPlans,
  compareType,
  onCompareCloseClick,
  onCompareTypeClick,
}) {
  
  const lang = useSelector(state => state.constant.languages);
  return (
    <ComparePlanDialogComponent
      registrationType={registrationType}
      userSubscriptionPlan={userSubscriptionPlan}
      isCompareDialogOpen={isCompareDialogOpen}
      subscriptionPlans={subscriptionPlans}
      compareType={compareType}
      onCompareCloseClick={onCompareCloseClick}
      lang={lang}
      onCompareTypeClick={onCompareTypeClick}
    />
  );
}