import axios from "axios";

const getScanDetail = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/consumer/admin/api/v1/scan/${id}/dashboard/detail`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data));
  });
};

export default getScanDetail;
