import React from "react"
import {
  Box,
  makeStyles,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Link
} from "@material-ui/core";
import {
  StyledTableCell,
  StyledTableRow
} from "components/table";
import { SkeletonTableRow } from "components/skeleton/skeleton.component"
import { ActionButton } from "components/button";
import { TablePaginationActions } from 'components/tablePagination';
import clsx from "clsx";
import { getLang } from "app/feature/constants";
import moment from "moment";
import "moment/min/locales.min";

const useStyle = makeStyles((theme) => ({
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    borderCollapse: "separate",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
    },
    "& th,td": {
      fontSize: ".75rem",
      borderRight: "1px solid rgba(58, 77, 84, 0.1)",
      padding: theme.spacing(1, 2, 1, 2),
    },
    "& th": {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightBold,
    },
    "& th:last-child, td:last-child": {
      width: 37,
      borderRight: "0px",
    },
    "& th th": {
      borderBottom: "none !important",
    },
    "& td td": {
      borderBottom: "none !important",
    },
  },
  tableContainer: {
    maxHeight: 500,
    overflowY: "auto",
  },
  emptyContainer: {
    backgroundColor: theme.palette.background.paper,
    textAlign: "center",
  },
  button: {
    display: "flex",
    alignItems: "center",
    padding: 0,
    minWidth: 0,
    marginLeft: theme.spacing(1),
  },
  actionContainer: {
    justifyContent: "center",
  },
  show: {
    display: "flex",
  },
  inProgress: {
    color: "#FDB022"
  },
  completed: {
    color: "#32D583"
  },
  rejected: {
    color: "#F97066"
  },
  ellipsisText: {
    maxWidth: "18em",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: "0.75rem"
  },
}));

export default function RedemptionTableComponent({
  rows,
  rowsPerPage,
  paginationOptions,
  page,
  language,
  totalFiltered,
  isFetching,
  isError,
  isSearchResult,
  handleViewDetail,
  handleChangePage,
  handleChangeRowPerPage,
  lang
}) {
  const classes = useStyle();
  
  if(language==="ZH")
  {
    moment.locale('zh-cn')
  }else if(language==="TL")
  {
    moment.locale('tl-ph')
  }else
  {
    moment.locale(language)
  }


  return (
    <>
      {isFetching || rows.length > 0 ? (
        <Box>
          <TableContainer>
            <Table
              className={classes.table}
              aria-label="redemption table"
              size="small"
              stickyHeader
            >
              <TableHead style={{ height: "45px" }}>
                <TableRow>
                  <StyledTableCell padding='checkbox'>{getLang(lang, 'label.NUM')}</StyledTableCell>
                  <StyledTableCell >{getLang(lang, 'label.ORDER_ID')}</StyledTableCell>
                  <StyledTableCell >{getLang(lang, 'label.DEALER_NAME')}</StyledTableCell>
                  <StyledTableCell >{getLang(lang, 'label.REDEMPTION_DATE')}</StyledTableCell>
                  <StyledTableCell >{getLang(lang, 'label.STATUS')}</StyledTableCell>
                  <StyledTableCell style={{ width: "67px", textAlign: "center" }} >{getLang(lang, 'label.ACTION')}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isFetching ? [...new Array(5)].map((v, index) => (
                  <SkeletonTableRow key={index} columnCount={6} />
                )) : rows.map((row, index) => {
                  const actionItems = [];

                  actionItems.push({
                    label: getLang(lang, 'label.VIEW_DETAILS'),
                    action: () => handleViewDetail(row.uuid)
                  })

                  return (
                    <StyledTableRow
                      key={row.uuid + index}
                      hover
                      role='checkbox'
                      tabIndex={-1}
                    >
                      <StyledTableCell>{index + 1}</StyledTableCell>
                      <StyledTableCell>
                        <Link
                          color="secondary"
                          component="button"
                          onClick={() => handleViewDetail(row.uuid)}
                        >
                          <Typography className={classes.ellipsisText}>{row.orderId}</Typography>
                        </Link>
                      </StyledTableCell>
                      <StyledTableCell>{row.name}</StyledTableCell>
                      <StyledTableCell>{moment(row.orderDate).format('ll')}</StyledTableCell>
                      <StyledTableCell
                        className={clsx({
                          [classes.inProgress]: row.status === "In Progress",
                          [classes.rejected]: row.status === "Rejected",
                          [classes.completed]: row.status === "Completed"
                        })}
                      >
                        {row.status === "Rejected"
                          ? getLang(lang, "label.REJECTED")
                          : row.status === "Completed"
                            ? getLang(lang, "label.COMPLETED")
                            : getLang(lang, "label.IN_PROGRESS")}
                        {/* QUICK FIX FOR LANGUAGES TRANSLATION */}
                      </StyledTableCell>
                      <StyledTableCell style={{ width: "67px" }}>
                        <div className={clsx(classes.actionContainer, classes.show)}>
                          <ActionButton
                            actionItems={actionItems}
                            popperStyle={{ zIndex: 999 }}
                          />
                        </div>
                      </StyledTableCell>
                    </StyledTableRow>
                  )
                })}
              </TableBody>

            </Table>
          </TableContainer>
          <TablePagination
            style={{
              backgroundColor: "#fff",
              boxShadow: "0px -1px 0px rgba(58, 77, 84, 0.15)"
            }}
            ActionsComponent={TablePaginationActions}
            rowsPerPageOptions={paginationOptions}
            component="div"
            count={totalFiltered}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowPerPage}
            labelRowsPerPage={getLang(lang, 'label.ROWS_PER_PAGE')}
            labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${getLang(lang, 'label.OF')} ${count}`}
          />
        </Box>
      ) : null}
      {!isFetching && !isError && rows.length === 0 && (
        <Box>
          <TableContainer>
            <Table
              className={classes.table}
              aria-label="redemption table"
              size="small"
              stickyHeader
            >
              <TableHead style={{ height: "45px" }}>
                <TableRow>
                  <StyledTableCell padding='checkbox'>{getLang(lang, 'label.NUM')}</StyledTableCell>
                  <StyledTableCell >{getLang(lang, 'label.ORDER_ID')}</StyledTableCell>
                  <StyledTableCell >{getLang(lang, 'label.DEALER_NAME')}</StyledTableCell>
                  <StyledTableCell >{getLang(lang, 'label.REDEMPTION_DATE')}</StyledTableCell>
                  <StyledTableCell >{getLang(lang, 'label.STATUS')}</StyledTableCell>
                  <StyledTableCell style={{ width: "67px", textAlign: "center" }} >{getLang(lang, 'label.ACTION')}</StyledTableCell>
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>
          <Box py={9} className={classes.emptyContainer}>
            {isSearchResult ? getLang(lang, 'message.info.NO_REDEMPTION_EXIST') : getLang(lang, 'message.info.REWARD_DOESNT_HAVE_REDEMPTION')}
          </Box>
        </Box>
      )}
    </>
  )
}
