import React from "react";
import {
  makeStyles,
  Box,
  Dialog,
  Backdrop,
  IconButton,
  Typography,
  Button,
  CircularProgress,
  Divider,
} from "@material-ui/core";
import "react-image-lightbox/style.css";
import {
  CloseRounded as CloseRoundedIcon,
  KeyboardArrowRightRounded as KeyboardArrowRightRoundedIcon,
  KeyboardArrowLeftRounded as KeyboardArrowLeftRoundedIcon,
} from "@material-ui/icons";
import { getLang } from "app/feature/constants";
import clsx from "clsx";
import { Skeleton } from "@material-ui/lab";
import RefreshTable from "components/table/refreshTable";
import DoubleArrowLeft from "assets/img/doubleArrowLeft.png";
import DoubleArrowRight from "assets/img/doubleArrowRight.png";

const useStyle = makeStyles((theme) => ({
  header: {
    padding: "12px 16px",
  },
  container: {
    display: "grid",
    gridTemplateColumns: "7fr 0.7fr 7fr",
    columnGap: theme.spacing(2),
  },
  listContainer: {
    height: 300,
    maxHeight: "70vh",
    border: "1px solid #D0D5DD",
    overflowY: "auto",
  },
  summaryContainer: {
    border: "1px solid #D0D5DD",
  },
  branchRow: {
    "&:hover": {
      backgroundColor: "#F2F4F7",
    },
  },
  selected: {
    backgroundColor: "#E4E7EC",
    "&:hover": {
      backgroundColor: "#E4E7EC",
    },
  },
  smallButton: {
    height: 28,
    minWidth: 28,
    padding: 0,
  },
}));

export default function GroupBranchEditDialogComponent({
  isOpen,
  handleClose,
  lang,
  addBranch,
  removeBranch,
  availableBranch,
  selectedBranch,
  selectBranchToAdd,
  selectBranchToRemove,
  branchToAdd,
  branchToRemove,
  submitUpdate,
  isSubmitting,
  isFetching,
  handleReload,
  isError,
  addAllAvailableBranch,
  removeAllAvailableBranch,
  resetList,
}) {
  const classes = useStyle();

  return (
    <Dialog
      maxWidth="md"
      open={isOpen}
      onClose={() => {}}
      aria-labelledby="modal-group-detail"
      aria-describedby="modal-group-detail"
      BackdropComponent={Backdrop}
      fullWidth
      PaperProps={{
        style: {
          overflowY: "hidden",
        },
      }}
    >
      <Box
        className={classes.header}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={1.5}
      >
        <Typography variant="h6" style={{ marginLeft: 8 }}>
          <b>{getLang(lang, "label.EDIT_GROUP_BRANCH")}</b>
        </Typography>
        <IconButton
          onClick={handleClose}
          edge="end"
          size="small"
          aria-label="close dialog"
          disabled={isSubmitting}
        >
          <CloseRoundedIcon color="primary" />
        </IconButton>
      </Box>
      {isError ? (
        <RefreshTable handleReload={handleReload} />
      ) : (
        <Box pt={0} px={3} pb={1}>
          <Box className={classes.container} mb={2}>
            <Box>
              <Box mb={1.5}>
                <Typography variant="body1">
                  <b>{getLang(lang, "label.AVAILABLE_BRANCHES")}</b>
                </Typography>
              </Box>
              <Box className={classes.listContainer}>
                {isFetching ? (
                  <Box p={1}>
                    <Skeleton variant="text" animation="wave" height={35} />
                    <Skeleton variant="text" animation="wave" height={35} />
                    <Skeleton variant="text" animation="wave" height={35} />
                    <Skeleton variant="text" animation="wave" height={35} />
                  </Box>
                ) : (
                  availableBranch.map((b) => {
                    let isSelected = branchToAdd.some(
                      (branch) => branch.id === b.id
                    );

                    return (
                      <Box
                        key={b.id}
                        py={1}
                        px={2}
                        onClick={() => selectBranchToAdd(b)}
                        className={clsx(classes.branchRow, {
                          [classes.selected]: !!isSelected,
                        })}
                      >
                        <Typography variant="body1">{b.name}</Typography>
                      </Box>
                    );
                  })
                )}
              </Box>
              <Box className={classes.summaryContainer} px={2} py={1}>
                <Typography variant="body2">
                  <b>
                    {getLang(
                      lang,
                      "paragraph.TOTAL_SELECTED_FROM_TOTAL_BRANCHES",
                      {
                        selected: branchToAdd.length,
                        total: availableBranch.length,
                      }
                    )}
                  </b>
                </Typography>
              </Box>
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <Box mb={2}>
                <Button
                  color="primary"
                  variant="outlined"
                  className={classes.smallButton}
                  onClick={() => addAllAvailableBranch()}
                  disabled={!availableBranch.length}
                >
                  <img
                    src={DoubleArrowRight}
                    alt="left"
                    style={{
                      height: 10,
                      opacity: !availableBranch.length ? 0.2 : 1,
                    }}
                  />
                </Button>
              </Box>
              <Box mb={2}>
                <Button
                  color="primary"
                  variant="outlined"
                  className={classes.smallButton}
                  onClick={() => addBranch()}
                  disabled={!availableBranch.length || !branchToAdd.length}
                >
                  <KeyboardArrowRightRoundedIcon />
                </Button>
              </Box>
              <Box mb={2}>
                <Button
                  color="primary"
                  variant="outlined"
                  className={classes.smallButton}
                  onClick={() => removeBranch()}
                  disabled={!selectedBranch.length || !branchToRemove.length}
                >
                  <KeyboardArrowLeftRoundedIcon />
                </Button>
              </Box>
              <Box>
                <Button
                  color="primary"
                  variant="outlined"
                  className={classes.smallButton}
                  onClick={() => removeAllAvailableBranch()}
                  disabled={!selectedBranch.length}
                >
                  <img
                    src={DoubleArrowLeft}
                    alt="left"
                    style={{
                      height: 10,
                      opacity: !selectedBranch.length ? 0.2 : 1,
                    }}
                  />
                </Button>
              </Box>
            </Box>
            <Box>
              <Box mb={1.5}>
                <Typography variant="body1">
                  <b>{getLang(lang, "label.ASSIGNED_BRANCHES")}</b>
                </Typography>
              </Box>
              <Box className={classes.listContainer}>
                {isFetching ? (
                  <Box p={1}>
                    <Skeleton variant="text" animation="wave" height={35} />
                    <Skeleton variant="text" animation="wave" height={35} />
                    <Skeleton variant="text" animation="wave" height={35} />
                    <Skeleton variant="text" animation="wave" height={35} />
                  </Box>
                ) : (
                  selectedBranch.map((b) => {
                    let isSelected = branchToRemove.some(
                      (branch) => branch.id === b.id
                    );

                    return (
                      <Box
                        key={b.id}
                        py={1}
                        px={2}
                        onClick={() => selectBranchToRemove(b)}
                        className={clsx(classes.branchRow, {
                          [classes.selected]: !!isSelected,
                        })}
                      >
                        <Typography variant="body1">{b.name}</Typography>
                      </Box>
                    );
                  })
                )}
              </Box>
              <Box className={classes.summaryContainer} px={2} py={1}>
                <Typography variant="body2">
                  <b>
                    {selectedBranch.length} {getLang(lang, "label.BRANCHES")}
                  </b>
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      <Divider />
      <Box display="flex" justifyContent="end" p={2}>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => resetList()}
          disabled={isSubmitting || isError}
          style={{ marginRight: 16 }}
        >
          <Typography>{getLang(lang, "label.RESET")}</Typography>
        </Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={() => submitUpdate()}
          disabled={isSubmitting || isError}
        >
          {isSubmitting ? (
            <CircularProgress size={20} style={{ color: "white" }} />
          ) : (
            <Typography>{getLang(lang, "label.SAVE")}</Typography>
          )}
        </Button>
      </Box>
    </Dialog>
  );
}
