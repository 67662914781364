import React from "react";
import { makeStyles, Box, Typography } from "@material-ui/core";
import { TYPES } from "./additionalInfoForm.container";
import { ModalFooter } from "../../modal";
import DraggableAdditionalInfoCard from "../../card/draggableAdditionalInfoCard";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Formik } from "formik";
import RefreshTable from "components/table/refreshTable";
import { getLang } from "app/feature/constants";
import InfiniteScroll from "react-infinite-scroll-component";
import LoadingComponent from "components/loading";

const useStyle = makeStyles((theme) => ({
  select: {
    padding: "5px 12px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    borderRadius: 8,
    width: "100%",
    border: "1px solid #D0D5DD",
  },
  inputText: {
    fontSize: "1rem",
    color: "#A4A6A8",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  filled: {
    color: "rgba(0, 0, 0, 0.87)",
  },
  container: {
    maxHeight: "75vh",
    overflowY: "auto",
    backgroundColor: "#f0f3f5",
    padding: 16,
  },
}));

export default function AdditionalInfoFormComponent({
  type,
  handleSubmit,
  lang,
  handleClose,
  handleDelete,
  isSubmitting,
  adtList,
  isError,
  handleReload,
  isLoading,
  isUpdating,
  userLang,
  handleFetchMoreItems,
  totalFilter,
}) {
  const classes = useStyle();

  return (
    <Box mb={type === TYPES[2] ? 2 : 0}>
      <Formik
        initialValues={{
          additionalInfo: adtList,
          isUpdated: false,
        }}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Box>
            <Box>
              {isError ? (
                <Box className={classes.container}>
                  <RefreshTable handleReload={handleReload} />
                </Box>
              ) : (
                <InfiniteScroll
                  height="100%"
                  className={classes.container}
                  dataLength={adtList.length}
                  next={() => {
                    handleFetchMoreItems(formik.values.additionalInfo);
                  }}
                  hasMore={totalFilter > adtList.length}
                  loader={<LoadingComponent height={50} />}
                  endMessage={
                    isLoading ? (
                      <LoadingComponent height={100} />
                    ) : (
                      !adtList.length && (
                        <Box py={2} dispaly="flex" justifyContent="center">
                          <Typography variant="body1" align="center">
                            {getLang(lang, "paragraph.NO_ADDITIONAL_INFO_YET")}
                          </Typography>
                        </Box>
                      )
                    )
                  }
                >
                  <DndProvider backend={HTML5Backend}>
                    {formik.values.additionalInfo.map((info, index) => (
                      <DraggableAdditionalInfoCard
                        key={info.id}
                        userLang={userLang}
                        index={index}
                        lang={lang}
                        updateInfo={(val) => {
                          formik.setFieldValue("additionalInfo", val);

                          if (!formik.values.isUpdated) {
                            formik.setFieldValue("isUpdated", true);
                          }
                        }}
                        info={info}
                        infoList={formik.values.additionalInfo}
                        isSubmitting={isSubmitting}
                      />
                    ))}
                  </DndProvider>
                </InfiniteScroll>
              )}
            </Box>
            <ModalFooter
              handleDelete={handleDelete}
              showDelete={type !== TYPES[0]}
              handleClick={() => handleSubmit(formik.values)}
              handleClose={handleClose}
              isLoading={isUpdating}
            />
          </Box>
        )}
      </Formik>
    </Box>
  );
}
