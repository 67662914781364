import { createAsyncThunk } from "@reduxjs/toolkit";
import RedemptionCenterApi from "app/api/redemptionCenter";
import { timezoneOffSetFormatTime } from "lib/helper";

const REDEMPTIONCENTER = "redemptionCenter";

export const fetchGiftListing = createAsyncThunk(
  `${REDEMPTIONCENTER}/fetchGiftListing`,
  async (payload, { rejectWithValue }) => {
    return RedemptionCenterApi.getAllGift(payload)
      .then((response) => response)
      .catch((error) => rejectWithValue(error));
  }
);

export const createGift = createAsyncThunk(
  `${REDEMPTIONCENTER}/createGift`,
  async (payload, { rejectWithValue, getState }) => {
    const timezone = getState().profile.timezone;
    return RedemptionCenterApi.createGift({
      name: payload.name,
      description: payload.description,
      imgUrl: payload.imgUrl.split("?")[0],
      point: payload.point,
      quantity: payload.quantity,
      itemPerCustomer: payload.itemPerCustomer,
      status: "active",
      startAt: timezoneOffSetFormatTime(payload.startAt, timezone),
      endAt: timezoneOffSetFormatTime(payload.endAt, timezone),
    })
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const getGift = createAsyncThunk(
  `${REDEMPTIONCENTER}/getGift`,
  async (payload, { rejectWithValue }) => {
    return RedemptionCenterApi.getGift({
      id: payload.id,
    }).catch((error) => rejectWithValue(error));
  }
);

export const updateGift = createAsyncThunk(
  `${REDEMPTIONCENTER}/updateGift`,
  async (payload, { rejectWithValue, getState }) => {
    const timezone = getState().profile.timezone;
    return RedemptionCenterApi.updateGift({
      id: payload.id,
      name: payload.name,
      description: payload.description,
      imgUrl: payload.imgUrl.split("?")[0],
      point: payload.point,
      quantity: payload.quantity,
      itemPerCustomer: payload.itemPerCustomer,
      status: payload.status,
      startAt: timezoneOffSetFormatTime(payload.startAt, timezone),
      endAt: timezoneOffSetFormatTime(payload.endAt, timezone),
    }).catch((error) => rejectWithValue(error));
  }
);

export const changeStatusGift = createAsyncThunk(
  `${REDEMPTIONCENTER}/changeStatusGift`,
  async (payload, { rejectWithValue }) => {
    return RedemptionCenterApi.updateGift({
      id: payload.id,
      status: payload.status,
    }).catch((error) => rejectWithValue(error));
  }
);

export const deleteGift = createAsyncThunk(
  `${REDEMPTIONCENTER}/deleteGift`,
  async (payload, { rejectWithValue }) => {
    return RedemptionCenterApi.deleteGift({
      id: payload.id,
    }).catch((error) => rejectWithValue(error));
  }
);

export const fetchOrderListing = createAsyncThunk(
  `${REDEMPTIONCENTER}/fetchOrderListing`,
  async (payload, { rejectWithValue }) => {
    return RedemptionCenterApi.getAllOrder(payload)
      .then((response) => response)
      .catch((error) => rejectWithValue(error));
  }
);

export const getOrder = createAsyncThunk(
  `${REDEMPTIONCENTER}/getOrder`,
  async (payload, { rejectWithValue }) => {
    return RedemptionCenterApi.getOrder({
      id: payload.id,
    }).catch((error) => rejectWithValue(error));
  }
);

export const updateOrder = createAsyncThunk(
  `${REDEMPTIONCENTER}/updateOrder`,
  async (payload, { rejectWithValue }) => {
    return RedemptionCenterApi.updateOrder({
      id: payload.id,
      adminRemark: payload.adminRemark,
      status: payload.status,
    }).catch((error) => rejectWithValue(error));
  }
);

export const fetchPointCutListing = createAsyncThunk(
  `${REDEMPTIONCENTER}/fetchPointCutListing`,
  async (payload, { rejectWithValue }) => {
    return RedemptionCenterApi.getAllPointCut(payload)
      .then((response) => response)
      .catch((error) => rejectWithValue(error));
  }
);

export const createPointCut = createAsyncThunk(
  `${REDEMPTIONCENTER}/createPointCut`,
  async (payload, { rejectWithValue, getState }) => {
    const timezone = getState().profile.timezone;
    return RedemptionCenterApi.createPointCut({
      parentId: payload.parentId,
      itemId: payload.itemId,
      amount: payload.point,
      status: "active",
      startAt: timezoneOffSetFormatTime(payload.startAt, timezone),
      endAt: timezoneOffSetFormatTime(payload.endAt, timezone),
    })
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const getPointCut = createAsyncThunk(
  `${REDEMPTIONCENTER}/getPointCut`,
  async (payload, { rejectWithValue }) => {
    return RedemptionCenterApi.getPointCut({
      id: payload.id,
    }).catch((error) => rejectWithValue(error));
  }
);

export const updatePointCut = createAsyncThunk(
  `${REDEMPTIONCENTER}/updatePointCut`,
  async (payload, { rejectWithValue, getState }) => {
    const timezone = getState().profile.timezone;
    return RedemptionCenterApi.updatePointCut({
      id: payload.id,
      parentId: payload.parentId,
      itemId: payload.itemId,
      amount: payload.point,
      status: payload.status,
      startAt: timezoneOffSetFormatTime(payload.startAt, timezone),
      endAt: timezoneOffSetFormatTime(payload.endAt, timezone),
    }).catch((error) => rejectWithValue(error));
  }
);

export const changeStatusPointCut = createAsyncThunk(
  `${REDEMPTIONCENTER}/changeStatusPointCut`,
  async (payload, { rejectWithValue }) => {
    return RedemptionCenterApi.updatePointCutStatus({
      id: payload.id,
      status: payload.status,
    }).catch((error) => rejectWithValue(error));
  }
);

export const getGiftDropdown = createAsyncThunk(
  `${REDEMPTIONCENTER}/getGiftDropdown`,
  async (payload, { rejectWithValue }) => {
    return RedemptionCenterApi.getGiftDropdown(payload)
      .then((response) => response)
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const getGiftOrderSetting = createAsyncThunk(
  `${REDEMPTIONCENTER}/getGiftOrderSetting`,
  async (_, { rejectWithValue }) => {
    return RedemptionCenterApi.getGiftOrderSetting()
      .then((response) => response)
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const updateGiftOrderSetting = createAsyncThunk(
  `${REDEMPTIONCENTER}/updateGiftOrderSetting`,
  async (payload, { rejectWithValue }) => {
    return RedemptionCenterApi.updateGiftOrderSetting(payload)
      .then((response) => response)
      .catch((error) => rejectWithValue(error.response.data));
  }
);
