import React from "react";
import {
  makeStyles,
  Box,
  Paper,
  IconButton, Divider
} from "@material-ui/core";
import CompaniesReactSortableTree from "../../companiesReactSortableTree";
import LoadingComponent from "components/loading";
import {useDispatch} from "react-redux";
import {collapseAll, expandAll} from "../../../redux";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const style = (theme) => ({
  root: {
    width: '100%',
  }
});

const useStyles = makeStyles(style);

export default function StructureOverviewPanelComponent({
  isOrganisationStructureLoading,
  organisationStructure,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <Box className={classes.root}>
      <Paper>
        {!isOrganisationStructureLoading ? (
          <Box>
            <Box display="flex" justifyContent="flex-end" pt={1} pr={1} pb={1}>
              <IconButton
                onClick={() => dispatch(collapseAll())}
                aria-label="Collapse All"
              >
                <ExpandLessIcon />
              </IconButton>
              <IconButton
                onClick={() => dispatch(expandAll())}
                aria-label="Expand All"
              >
                <ExpandMoreIcon />
              </IconButton>
            </Box>
            <Divider />
          </Box>
        ) : null}
        <Box style={{ paddingBottom: 25, paddingTop: 25, overflowX: "auto" }}>
          {isOrganisationStructureLoading ? (
            <LoadingComponent />
          ) : (
            <CompaniesReactSortableTree
              organisationStructure={organisationStructure}
            />
          )}
        </Box>
      </Paper>
    </Box>
  );
}
