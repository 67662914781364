/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import EditPrizeCardComponent from "./editPrizeCardV2.component";
import { useSelector } from "react-redux";
import GeneralDialog from "components/dialog/generalDialog";
import DeleteSvgIcon from "assets/svg/delete.svg";
import { getLang } from "app/feature/constants";

function EditPrizeCardContainer({
  handleDelete,
  prizeDetail,
  enableWinRate,
  formik,
  index,
  updateDetail,
  disabled,
  hasIldPrizeDeleteAccessRight,
  isPublished,
  disbursementInfos,
  instantLuckyDraw,
}) {
  const lang = useSelector((state) => state.constant.languages);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [transactionUse, setTransactionUse] = useState(0);
  const [prizeTypes, setPrizeTypes] = useState([]);

  useEffect(() => {
    let tempTypes = [
      {
        id: 0,
        name: getLang(lang, "label.ITEM"),
      },
    ];
    if (disbursementInfos.length) {
      let tempTypes = [
        {
          id: 0,
          name: getLang(lang, "label.ITEM"),
        },
      ];

      disbursementInfos.forEach((info) => {
        tempTypes.push({
          id: info.id,
          name: info.disbursement_providers?.name,
        });
      });
    }
    setPrizeTypes(tempTypes);
  }, [disbursementInfos]);

  useEffect(() => {
    if(prizeDetail.balance) {
      setTransactionUse(parseInt(prizeDetail.winQuantity) + parseInt(prizeDetail.balance))
    }else{
      setTransactionUse(prizeDetail.quantity)
    }
  }, [prizeDetail.quantity, prizeDetail.balance]);

  return (
    <>
      <EditPrizeCardComponent
        instantLuckyDraw={instantLuckyDraw}
        handleDelete={() => setShowDeleteDialog(true)}
        prizeDetail={prizeDetail}
        lang={lang}
        formik={formik}
        updateDetail={updateDetail}
        index={index}
        enableWinRate={enableWinRate}
        disabled={disabled}
        hasIldPrizeDeleteAccessRight={hasIldPrizeDeleteAccessRight}
        isPublished={isPublished}
        transactionUse={transactionUse}
        prizeTypes={prizeTypes}
      />
      <GeneralDialog
        isOpen={showDeleteDialog}
        handleClose={() => {
          setShowDeleteDialog(false);
        }}
        handleProceed={() => {
          handleDelete();
          setShowDeleteDialog(false);
        }}
        title={getLang(lang, "label.DELETE_PRIZE")}
        description={getLang(lang, "paragraph.DELETE_PRIZE_QUESTION")}
        type="danger"
        proceedLabel={getLang(lang, "label.DELETE")}
        icon={<img src={DeleteSvgIcon} alt="" />}
      />
    </>
  );
}

export default EditPrizeCardContainer;
