import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import NotFoundComponent from "./notFound.page";

export default function NotFoundContainer() {
  useEffect(() => {
    document.title = "Page not found - CheckNow Customer Portal";
  }, []);
  const lang = useSelector(state => state.constant.languages)
  return <NotFoundComponent lang={lang}/>;
}
