import React from "react"
import {
  Box,
  makeStyles,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Paper,
  TableSortLabel,
  Link,
} from "@material-ui/core"
import {
  StyledTableCell,
  StyledTableRow,
} from "components/table"
import { SkeletonTableRowStickyLeftRight } from "components/skeleton/skeleton.component"
import { TablePaginationActions } from "components/tablePagination"
import RefreshTable from "components/table/refreshTable"
import moment from "moment"
import { KeyboardArrowDown } from "@material-ui/icons"
import { getLang } from "app/feature/constants"

const useStyle = makeStyles((theme) => ({
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    borderCollapse: "separate",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
    },
    "& th,td": {
      fontSize: ".75rem",
      borderRight: "1px solid rgba(58, 77, 84, 0.1)",
      padding: theme.spacing(1, 2, 1, 2),
    },
    "& th": {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightBold,
    },
    "& th:last-child, td:last-child": {
      width: 37,
      borderRight: "0px",
    },
    "& th th": {
      borderBottom: "none !important",
    },
    "& td td": {
      borderBottom: "none !important",
    },
  },
  emptyContainer: {
    backgroundColor: theme.palette.background.paper,
    textAlign: "center",
  },
}))

export default function PointHistoryListTableComponent({
  hasStockflowRewardCenterViewAccessRight,
  rows,
  isFetching,
  isError,
  handleReload,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowPerPage,
  language,
  totalFiltered,
  paginationOptions,
  tableFilter,
  changeOrder,
  lang,
  handleViewDetail
}) {
  const classes = useStyle()

  if(language==="ZH")
  {
    moment.locale('zh-cn')
  }else if(language==="TL")
  {
    moment.locale('tl-ph')
  }else
  {
    moment.locale(language)
  }
  
  const statusColor = {
    increment: "#32D583",
    decrement: "#F97066"
  }

  const getTransactionType = (history) => {
    let transaction = getLang(lang, history.type)

    if (history.reference_code) {
        transaction += " - " + history.reference_code
    }

    return transaction
  }

  return (
    <>
      {!isFetching && isError && <RefreshTable handleReload={handleReload} />}
      {isFetching || rows.length > 0 ? (
        <Paper id="test"style={{overflowX: 'auto', width: "100%"}}>
          <TableContainer>
            <Table
              className={classes.table}
              aria-label="point table"
              size="small"
              stickyHeader
            >
              <TableHead style={{ height: "45px" }}>
                <TableRow>
                  <StyledTableCell style={{ minWidth: "10rem" }}>{getLang(lang, "label.TRANSACTION")}</StyledTableCell>
                  <StyledTableCell>{getLang(lang, "label.BALANCE_BEFORE")}</StyledTableCell>
                  <StyledTableCell>{getLang(lang, "label.AMOUNT")}</StyledTableCell>
                  <StyledTableCell>{getLang(lang, "label.BALANCE_AFTER")}</StyledTableCell>
                  <StyledTableCell>
                    <TableSortLabel
                      active={true}
                      direction={tableFilter.order}
                      onClick={() => {
                        const order = tableFilter.order === 'asc' ? "desc" : "asc"
                        changeOrder(order)
                      }}
                      IconComponent={KeyboardArrowDown}
                    >
                      {getLang(lang, "label.DATE")}
                    </TableSortLabel>
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isFetching
                  ? [...new Array(5)].map((v, index) => (
                    <SkeletonTableRowStickyLeftRight 
                      key={index} 
                      columnCount={4} 
                    />
                  ))
                  : rows.map((row, index) => {
                    return (
                      <StyledTableRow
                        key={row.id}
                        hover
                        role="checkbox"
                        tabIndex={-1}
                      >
                        <StyledTableCell>
                          {row.reference_code && hasStockflowRewardCenterViewAccessRight ? (
                            <Link
                              color="secondary"
                              component="button"
                              onClick={() => {
                                handleViewDetail(row)
                              }}
                            >
                              <Typography variant="caption">{getTransactionType(row)}</Typography>
                            </Link>
                          ) : (
                            <Typography variant="caption">{getTransactionType(row)}</Typography>
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          <Typography variant="caption">
                            {row.before_balance.toLocaleString("en-US")}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          <Typography variant="caption" style={{ color: statusColor[row.before_balance > row.after_balance ? 'decrement' : 'increment'] }}>
                            {row.before_balance > row.after_balance ? "-" : row.before_balance < row.after_balance ? "+" : ""}
                            {row.amount.toLocaleString("en-US")}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          <Typography variant="caption">
                            {row.after_balance.toLocaleString("en-US")}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Typography variant="caption">
                            {moment.utc(row.date).local().format("lll")}
                          </Typography>
                        </StyledTableCell>
                      </StyledTableRow>
                    )
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            style={{
              backgroundColor: "#fff",
              boxShadow: "0px -1px 0px rgba(58, 77, 84, 0.15)",
            }}
            ActionsComponent={TablePaginationActions}
            rowsPerPageOptions={paginationOptions}
            component="div"
            count={totalFiltered}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowPerPage}
            labelRowsPerPage={getLang(lang, 'label.ROWS_PER_PAGE')}
            labelDisplayedRows={({ from, to, count }) => `${from.toLocaleString("en-US")}-${to.toLocaleString("en-US")} ${getLang(lang, 'label.OF')} ${count.toLocaleString("en-US")}`}
          />
        </Paper>
      ) : null}
      {!isFetching && !isError && rows.length === 0 && (
        <Box>
          <Table
            className={classes.table}
            aria-label="point table"
            size="small"
            stickyHeader
          >
            <TableHead style={{ height: "45px" }}>
              <TableRow>
                <StyledTableCell style={{ minWidth: "10rem" }}>{getLang(lang, "label.TRANSACTION")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.BALANCE_BEFORE")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.AMOUNT")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.BALANCE_AFTER")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.DATE")}</StyledTableCell>
              </TableRow>
            </TableHead>
          </Table>
          <Box py={9} className={classes.emptyContainer}>
            <Typography variant="body1" style={{ paddingBottom: "16px", fontWeight: "bold" }}>
              {getLang(lang, "paragraph.NO_POINT_HISTORY")}
            </Typography>
          </Box>
        </Box>
      )}
    </>
  )
}
