import React from "react";
import MaintenanceAlertComponent from "./maintenanceAlert.component";
import { useSelector } from "react-redux";

function MaintenanceAlertContainer() {
  const showMaintenanceAlert = useSelector(
    (state) => state.session.showMaintenanceAlert
  );
  const lang = useSelector((state) => state.constant.languages);

  return (
    <MaintenanceAlertComponent
      showMaintenanceAlert={showMaintenanceAlert}
      lang={lang}
    />
  );
}

export default MaintenanceAlertContainer;
