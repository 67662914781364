import PropTypes from "prop-types";
import koodServerInstance from "./instance";

const uploadJsonFile = async (fileName, data) => {
  const body = { fileName, data };

  const response = await koodServerInstance.post("/uploadJsonFile", body);

  return response;
};

uploadJsonFile.propTypes = {
  fileName: PropTypes.string.isRequired
};

export default uploadJsonFile;
