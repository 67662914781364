import React from "react";
import EditEmployeeDialogComponent from "./editEmployeeDialog.component";
import { useDispatch, useSelector } from "react-redux";
import { updateEmployeeDetail } from "modules/employee/redux";
import { hideBackdrop, showBackdrop } from "modules/backdrop";
import { unwrapResult } from "@reduxjs/toolkit";
import { addAlert } from "modules/notification";
import { getLang } from "app/feature/constants";

export default function EditEmployeeDialogContainer({ isOpen, handleClose, handleReload }) {
  const dispatch = useDispatch();
  const employeeDetail = useSelector((state) => state.employee.employeeDetail);
  const lang = useSelector(state => state.constant.languages);

  const handleSubmit = async (values) => {
    const updateInfo = {
      email: employeeDetail,
      userGroupId: values.role.userGroupId,
      nodeIds: [],
      id: employeeDetail.id
    };
    for (const employeeBranch of values.branch) {
      updateInfo.nodeIds.push(employeeBranch.node_id);
    }
    if (updateInfo.nodeIds.length === 0)
      return dispatch(addAlert({ severity: "error", message: getLang(lang, "message.error.SELECT_AT_LEAST_ONE_BRANCH") }));

    dispatch(showBackdrop());
    await dispatch(updateEmployeeDetail(updateInfo))
      .then(unwrapResult)
      .finally((error) => {
        dispatch(hideBackdrop());
        handleReload();
        handleClose();
      });
  };

  return (
    <EditEmployeeDialogComponent
      isOpen={isOpen}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      employeeDetail={employeeDetail}
      lang={lang}
    />
  );
}
