import React from "react"
import {
  Box,
  // Button,
  Link,
  makeStyles,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from "@material-ui/core"
import {
  StyledTableCell,
  StyledTableRow,
  StickyRightTableCell,
  StickyLeftTableCell,
} from "components/table"
import { SkeletonTableRowStickyLeftRight } from "components/skeleton/skeleton.component"
import { ActionButton } from "components/button"
import { TablePaginationActions } from "components/tablePagination"
import RefreshTable from "components/table/refreshTable"
import clsx from "clsx"
import { KeyboardArrowDown } from "@material-ui/icons"
import { ROOT } from "modules/stockflow/config/stockflow.route"
import { getLang } from "app/feature/constants"

const useStyle = makeStyles((theme) => ({
  tableContainer: {
    maxWidth: "calc(100vw - 80px)",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "calc(100vw - 32px)",
    },
  },
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    borderCollapse: "separate",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
    },
    "& th,td": {
      fontSize: ".75rem",
      borderRight: "1px solid rgba(58, 77, 84, 0.1)",
      padding: theme.spacing(1, 2, 1, 2),
    },
    "& th": {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightBold,
    },
    "& th:last-child, td:last-child": {
      width: 37,
      borderRight: "0px",
    },
    "& th th": {
      borderBottom: "none !important",
    },
    "& td td": {
      borderBottom: "none !important",
    },
  },
  emptyContainer: {
    backgroundColor: theme.palette.background.paper,
    textAlign: "center",
  },
  activeText: {
    color: "#32D583",
  },
  suspendText: {
    color: "#F97066",
  },
}))

export default function ProductStockListTableComponent({
  rows,
  isFetching,
  isError,
  handleReload,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowPerPage,
  isSearchResult,
  totalFiltered,
  viewActivityLog,
  tableFilter,
  changeSequenceOrder,
  lang,
  paginationOptions,
  handleViewDetail
}) {
  const classes = useStyle()

  return (
    <>
      {!isFetching && isError && <RefreshTable handleReload={handleReload} />}
      {isFetching || rows.length > 0 ? (
        <>
          <TableContainer className={classes.tableContainer}>
            <Table
              className={classes.table}
              aria-label="stock table"
              size="small"
              stickyHeader
            >
              <TableHead style={{ height: "45px" }}>
                <TableRow>
                  <StickyLeftTableCell>
                    <StyledTableCell>
                      <TableSortLabel
                        active={true}
                        direction={tableFilter.order}
                        onClick={() => {
                          if (tableFilter.order === "asc") {
                            changeSequenceOrder("desc")
                          } else {
                            changeSequenceOrder("asc")
                          }
                        }}
                        IconComponent={KeyboardArrowDown}
                      >
                        {getLang(lang, "label.SEQUENCE_NUMBER")}
                      </TableSortLabel>
                    </StyledTableCell>
                  </StickyLeftTableCell>
                  
                  <StyledTableCell>{getLang(lang, "label.SERIAL_NUMBER")}</StyledTableCell>
                  <StyledTableCell>{getLang(lang, "label.PACKAGE")}</StyledTableCell>
                  <StyledTableCell>{getLang(lang, "label.BOX")}</StyledTableCell>
                  <StyledTableCell>{getLang(lang, "label.STATUS")}</StyledTableCell>
                  <StyledTableCell>{getLang(lang, "label.BRANCH_ASSIGNED")}</StyledTableCell>
                  <StickyRightTableCell>
                    <StyledTableCell
                      style={{ width: "67px", textAlign: "center" }}
                    >
                      {getLang(lang, "label.ACTION")}
                    </StyledTableCell>
                  </StickyRightTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isFetching
                  ? [...new Array(5)].map((v, index) => (
                    <SkeletonTableRowStickyLeftRight key={index} columnCount={7} />
                  ))
                  : rows.map((row, index) => {
                    const actionItems = []

                    actionItems.push({
                      label: getLang(lang, "label.VIEW_DETAILS"),
                      action: () => {
                        viewActivityLog(row)
                      },
                    })

                    return (
                      <StyledTableRow
                        key={row.id}
                        hover
                        tabIndex={-1}
                      >
                        <StickyLeftTableCell>
                          <StyledTableCell align="right">
                            {row.sequence_no || "-"}
                          </StyledTableCell>
                        </StickyLeftTableCell>
                        <StyledTableCell>
                          {row.serial_number ? (
                            <Link
                              color="secondary"
                              component="button"
                              variant="caption"
                              onClick={() => viewActivityLog(row)}
                            >
                              {row.serial_number}
                            </Link>
                          ) : "-"}
                        </StyledTableCell>
                        <StyledTableCell>
                          {row.pack ? (
                            <Link
                              href={`${ROOT}/inventory/package/${row.uuid}`}
                              color="secondary"
                              variant="caption"
                              onClick={(e) => {
                                handleViewDetail(`${ROOT}/inventory/package/${row.uuid}`)
                                e.preventDefault();
                                return false;
                              }}
                            >
                              {getLang(lang, "label.PACKED")}
                            </Link>
                          ) : "-"}
                        </StyledTableCell>
                        <StyledTableCell>
                          {row.box ? (
                            <Link
                              href={`${ROOT}/inventory/box/${row.box.id}`}
                              color="secondary"
                              variant="caption"
                              onClick={(e) => {
                                handleViewDetail(`${ROOT}/inventory/box/${row.box.id}`)
                                e.preventDefault();
                                return false;
                              }}
                            >
                              {row.box.name}
                            </Link>
                          ) : "-"}
                        </StyledTableCell>
                        <StyledTableCell className={clsx({
                          [classes.activeText]: row.status === "active",
                          [classes.suspendText]: row.status === "suspend",
                        })}> 
                          {row.status === 'active' ? getLang(lang, "label.ACTIVE") : getLang(lang, "label.SUSPENDED")}
                        </StyledTableCell>
                        <StyledTableCell>
                          {row.node ? row.node.name : "-"}
                        </StyledTableCell>
                        <StickyRightTableCell>
                          <StyledTableCell style={{ width: "67px" }}>
                            <ActionButton
                              actionItems={actionItems}
                              popperStyle={{ zIndex: 999 }}
                            />
                          </StyledTableCell>
                        </StickyRightTableCell>
                      </StyledTableRow>
                    )
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            style={{
              backgroundColor: "#fff",
              boxShadow: "0px -1px 0px rgba(58, 77, 84, 0.15)",
            }}
            ActionsComponent={TablePaginationActions}
            rowsPerPageOptions={paginationOptions}
            component="div"
            count={totalFiltered}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowPerPage}
            labelRowsPerPage={getLang(lang, 'label.ROWS_PER_PAGE')}
            labelDisplayedRows={({ from, to, count }) => `${from.toLocaleString("en-US")}-${to.toLocaleString("en-US")} ${getLang(lang, 'label.OF')} ${count.toLocaleString("en-US")}`}
          />
        </>
      ) : null}
      {!isFetching && !isError && rows.length === 0 && (
        <Box>
          <Table
            className={classes.table}
            aria-label="stock table"
            size="small"
            stickyHeader
          >
            <TableHead style={{ height: "45px" }}>
              <TableRow>
                <StyledTableCell>{getLang(lang, "label.SEQUENCE_NUMBER")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.SERIAL_NUMBER")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.PACKAGE")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.BOX")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.STATUS")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.BRANCH_ASSIGNED")}</StyledTableCell>
                <StickyRightTableCell>
                  <StyledTableCell>{getLang(lang, "label.ACTION")}</StyledTableCell>
                </StickyRightTableCell>
              </TableRow>
            </TableHead>
          </Table>
          <Box py={9} className={classes.emptyContainer}>
            <Typography variant="body1" style={{ paddingBottom: "16px", fontWeight: "bold" }}>
              {isSearchResult
                ? getLang(lang, "paragraph.NO_SERIAL_NUMBER_EXISTS")
                : getLang(lang, "paragraph.INVENTORY_EMPTY")}
            </Typography>
            {!isSearchResult && (
              <>
                <Typography variant="body2" style={{ paddingBottom: "16px" }}>
                  {getLang(lang, "paragraph.INVENTORY_INSTRUCTION")}
                </Typography>
                {/* <Button
                  onClick={() => {
                    // TODO: add link
                  }}
                  variant="contained"
                  disableElevation
                  color="primary"
                >
                  {getLang(lang, "label.CREATE_QR_CODE")}
                </Button> */}
              </>
            )}
          </Box>
        </Box>
      )}
    </>
  )
}
