import React from "react";
import GeneratePage from "./pages/generate-page";
import DetailsPage from "./pages/details-page";
import TagPage from "./pages/tag-page";
import ListingPage from "./pages/listing-page";
import BatchPage from "./pages/batch-page";
import AddSnAdtInfo from "./pages/add-sn-adt-page";

import MainPage from "./pages/main-page";
import { Route, Switch, Redirect } from "react-router-dom";
import {
  ROOT,
  GENERATE_SN,
  TAG_SN,
  LISTING_SN,
  BATCH_SN,
  DETAILS_SN,
  ADT_ADD_SN,
} from "./config/serial-number.route";
import { serialNumberReducer, setListingSerialNumber } from "./redux";
import { serialNumberBatchReducer, setGenerateBatch } from "./redux";
import { assignBatchReducer } from "./redux";
import {
  serialNumberTagReducer,
  setTagSerialNumber,
  getUnassignedSerialNumber,
  assignSerialNumber,
} from "./redux";
import {
  SERIAL_NUMBER_TYPE_NUMBER,
  SERIAL_NUMBER_TYPE_ALPHANUMERIC,
} from "./constants";

export default function Index() {
  return (
    <Switch>
      <Route exact path={ROOT} component={MainPage} />
      <Route exact path={GENERATE_SN} component={GeneratePage} />
      <Route exact path={DETAILS_SN} component={DetailsPage} />
      <Route exact path={TAG_SN}>
        <TagPage />
      </Route>
      <Route exact path={ADT_ADD_SN}>
        <AddSnAdtInfo />
      </Route>
      <Route exact path={LISTING_SN}>
        <ListingPage />
      </Route>
      <Route exact path={BATCH_SN}>
        <BatchPage />
      </Route>
      <Route path="*">
        <Redirect to="/admin/invalid" />
      </Route>
    </Switch>
  );
}

export {
  ROOT,
  GENERATE_SN,
  TAG_SN,
  LISTING_SN,
  BATCH_SN,
  DETAILS_SN,
  DetailsPage,
  serialNumberReducer,
  setListingSerialNumber,
  serialNumberBatchReducer,
  assignBatchReducer,
  setGenerateBatch,
  serialNumberTagReducer,
  setTagSerialNumber,
  getUnassignedSerialNumber,
  SERIAL_NUMBER_TYPE_NUMBER,
  SERIAL_NUMBER_TYPE_ALPHANUMERIC,
  assignSerialNumber,
};
