import axios from "axios";

const url = "/distributor/admin/api/v1/programs/get-product-dropdown";

export function getNewProgramProductDropdown(){
  return new Promise((resolve, reject) => {
    axios.get(url)
      .then(response => {
        return resolve(response.data)
      })
      .catch(error => {
        return reject(error.response.data)
      })
  })
}