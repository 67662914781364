import React from "react";
import RoleNameInputComponent from "./roleNameInput.component";
import PropTypes from "prop-types";

function RoleNameInputContainer({
    label,
    ...props
}) {
    return (
        <RoleNameInputComponent
            label={label}
            {...props}
        />
    )
}

RoleNameInputContainer.defaultProps = {
    label: "Label",
};

RoleNameInputContainer.propTypes = {
    label: PropTypes.string,
};

export default RoleNameInputContainer;
