
import React from 'react'
import GenerateProductLoadingModalComponent from './generateProductLoadingModal.component'

function GenerateProductLoadingModalContainer({
  open,
}) {
  return (
    <GenerateProductLoadingModalComponent open={open} />
  )
}

export default GenerateProductLoadingModalContainer