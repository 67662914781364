import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectLoading } from "modules/notification";
import { generatePaginationOptions, updateUrlQueryParam } from "lib/helper";
import {
  changeBulkSelectedAvailableRecall,
  changeBulkSelectedRecall,
  changeSelectedRecall,
  getRecallList,
  resetRecallListing,
} from "modules/stockflow/redux";
import moment from "moment";
import RecallListingComponent from "./recallListing.component";
import { useHistory } from "react-router-dom";
import {
  STOCKFLOW_RECALL_VIEW,
  STOCKFLOW_DEALER_VIEW,
} from "lib/constants/accessRights";

function RecallListingContainer() {
  const history = useHistory();
  const dispatch = useDispatch();

  const hasStockflowRecallViewAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(STOCKFLOW_RECALL_VIEW)
  );
  const hasStockflowDealerViewAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(STOCKFLOW_DEALER_VIEW)
  );
  const lang = useSelector((state) => state.constant.languages);
  const language =useSelector(state => state.profile.language)
  const isFetching = useSelector((state) =>
    selectLoading(state, getRecallList.typePrefix)
  );
  const totalFiltered = useSelector(
    (state) => state.stockflowRecall.totalFiltered
  );
  const totalRecords = useSelector(
    (state) => state.stockflowRecall.totalRecords
  );
  const recallList = useSelector((state) => state.stockflowRecall.recallList);
  const selectedIds = useSelector((state) => state.stockflowRecall.selectedIds);
  const isSelectAll = useSelector((state) => state.stockflowRecall.isSelectAll);
  const isError = useSelector(
    (state) => state.stockflowRecall.isLoadingRecallListError
  );
  const isSearchResult = recallList.length < totalRecords;
  const [paginationOptions, setPaginationOptions] = useState([]);
  // const [isExporting, setIsExporting] = useState(false)
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isFilterUpdated, setIsFilterUpdated] = useState(false);
  const [tableFilter, setTableFilter] = useState({
    page: 0,
    rowsPerPage: 25,
    order: "desc",
    orderBy: "created_at",
    search: "",
    nodeIds: [],
    branch: [],
    fromDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
    toDate: moment().format("YYYY-MM-DD"),
    recallBy: [],
    recallFrom: [],
  });

  const query = new URLSearchParams(window.location.search);
  const tableFilterInitialRef = useRef(true);

  useEffect(() => {
    return () => {
      dispatch(resetRecallListing());
    };
  }, [dispatch]);

  useEffect(() => {
    const isFilter = query.has("is_filter");
    let filter = sessionStorage.getItem("recall_filter");

    let temp = { ...tableFilter };
    if (isFilter && filter) {
      temp = JSON.parse(filter);
      setIsFilterUpdated(true);
      setIsFilterOpen(true);
    }

    if (isFilter) {
      updateUrlQueryParam(null, ["is_filter"]);
    }
    sessionStorage.removeItem("recall_filter");

    setTableFilter(temp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!tableFilterInitialRef.current) {
      getData();
    }
    tableFilterInitialRef.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableFilter]);

  useEffect(() => {
    setPaginationOptions(generatePaginationOptions(totalFiltered));
  }, [totalFiltered]);

  const getData = () => {
    dispatch(
      getRecallList({
        length: tableFilter.rowsPerPage,
        start: tableFilter.rowsPerPage * tableFilter.page,
        order: tableFilter.order,
        order_by: tableFilter.orderBy,
        filter: {
          search: tableFilter.search,
          nodes: tableFilter.nodeIds,
          from_date: tableFilter.fromDate,
          to_date: tableFilter.toDate,
          recall_by: tableFilter.recallBy,
          recall_from: tableFilter.recallFrom,
          respond_date: tableFilter.respondDate,
        },
      })
    );
  };

  const handleChangePage = (event, newPage) => {
    let newData = {
      ...tableFilter,
      page: newPage,
    };
    setIsFilterUpdated(true);
    setTableFilter(newData);
  };

  const handleChangeRowsPerPage = (event) => {
    let newData = {
      ...tableFilter,
      page: 0,
      rowsPerPage: event.target.value,
    };
    setIsFilterUpdated(true);
    setTableFilter(newData);
  };

  const handleSearch = (filter) => {
    let nodeIds =
      filter.branch.length > 0 ? filter.branch.map((br) => br.node_id) : [];

    let newData = {
      ...tableFilter,
      page: 0,
      search: filter.search,
      fromDate: filter.dateRange?.from
        ? moment(filter.dateRange.from).format("YYYY-MM-DD")
        : "",
      toDate: filter.dateRange?.to
        ? moment(filter.dateRange.to).format("YYYY-MM-DD")
        : "",
      nodeIds: nodeIds,
      branch: filter.branch,
      recallFrom: filter.recallFrom,
      recallBy: filter.recallBy,
    };
    setIsFilterUpdated(true);
    setTableFilter(newData);
  };

  const clearSearch = () => {
    let newData = {
      page: 0,
      search: "",
      fromDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
      toDate: moment().format("YYYY-MM-DD"),
      nodeIds: [],
      branch: [],
      recallFrom: [],
      recallBy: [],
    };
    setIsFilterUpdated(false);
    setTableFilter(newData);
  };

  const handleSelectRecall = (index, isSelected) => {
    dispatch(changeSelectedRecall({ index, isSelected }));
  };

  const handleSelectAvailable = () => {
    dispatch(changeBulkSelectedAvailableRecall({ value: true }));
  };

  const handleSelectAll = () => {
    dispatch(changeBulkSelectedRecall({ value: true }));
  };

  const handleDeselectAll = () => {
    dispatch(changeBulkSelectedRecall({ value: false }));
  };

  const changeOrder = (orderBy, order) => {
    let newData = {
      ...tableFilter,
      order,
      orderBy,
    };
    setIsFilterUpdated(true);
    setTableFilter(newData);
  };

  const resetFilter = () => {
    let newData = {
      ...tableFilter,
      page: 0,
      nodeIds: [],
      branch: [],
      recallFrom: [],
      recallBy: [],
    };
    setIsFilterUpdated(true);
    setTableFilter(newData);
  };

  const handleViewDetail = (url) => {
    if (isFilterUpdated) {
      updateUrlQueryParam({ is_filter: true });
      sessionStorage.setItem("recall_filter", JSON.stringify(tableFilter));
    }
    history.push(url);
  };

  return (
    <>
      <RecallListingComponent
        hasStockflowRecallViewAccessRight={hasStockflowRecallViewAccessRight}
        hasStockflowDealerViewAccessRight={hasStockflowDealerViewAccessRight}
        recalls={recallList}
        isFetching={isFetching}
        language={language}
        selectedIds={selectedIds}
        isSelectAll={isSelectAll}
        selectedCount={
          !isSelectAll ? selectedIds.length : totalFiltered - selectedIds.length
        }
        isError={isError}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        page={tableFilter.page}
        rowsPerPage={tableFilter.rowsPerPage}
        totalFiltered={totalFiltered}
        handleSearch={handleSearch}
        clearSearch={clearSearch}
        handleReload={getData}
        isSearchResult={isSearchResult}
        handleSelectRecall={handleSelectRecall}
        handleSelectAvailable={handleSelectAvailable}
        handleSelectAll={handleSelectAll}
        handleDeselectAll={handleDeselectAll}
        paginationOptions={paginationOptions}
        isFilterOpen={isFilterOpen}
        setIsFilterOpen={setIsFilterOpen}
        tableFilter={tableFilter}
        changeOrder={changeOrder}
        lang={lang}
        resetFilter={resetFilter}
        handleViewDetail={handleViewDetail}
      />
    </>
  );
}

export default RecallListingContainer;
