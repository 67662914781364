import React from "react";
import {
  makeStyles,
  Box,
  Typography,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from "@material-ui/core";
import { StyledTableCell, StyledTableRow } from "components/table";
import { TablePaginationActions } from "components/tablePagination";
import { ActionButton } from 'components/button';
import { SkeletonTableRow } from 'components/skeleton/skeleton.component';
import clsx from 'clsx'
import Alert from "@material-ui/lab/Alert";
import RefreshTable from "components/table/refreshTable";
import { getLang } from "app/feature/constants";
import StickyLeftTableCell from "components/table/stickyLeftTableCell";
import StickyRightTableCell from "components/table/stickyRightTableCell";
import moment from "moment";
import "moment/min/locales.min";

const useStyle = makeStyles(theme => ({
  emptyContainer: {
    backgroundColor: theme.palette.background.paper,
    textAlign: "center"
  },
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    borderCollapse: "separate",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
    },
    "& th,td": {
      fontSize: ".75rem",
      borderRight: "1px solid rgba(58, 77, 84, 0.1)",
      padding: theme.spacing(1, 2, 1, 2),
    },
    "& th": {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightBold,
    },
    "& th:last-child, td:last-child": {
      width: 37,
      borderRight: "0px",
    },
    "& th th": {
      borderBottom: "none !important",
    },
    "& td td": {
      borderBottom: "none !important",
    },
  },
  unprocressed: {
    color: "#FD646F"
  },
  processed: {
    color: "#1FD026"
  },
  pending: {
    color: "#F69F00"
  }
}));

export default function RedemptionsTableComponent({
  hasGiftRedemptionEditAccessRight,
  data,
  isFetching,
  page,
  search,
  rowsPerPage,
  totalRecords,
  handleChangePage,
  language,
  handleChangeRowsPerPage,
  handleView,
  isLoadingRedemptionError,
  handleReload,
  paginationOptions,
  lang
}) {
  const classes = useStyle();
  
  if(language==="ZH")
  {
    moment.locale('zh-cn')
  }else if(language==="TL")
  {
    moment.locale('tl-ph')
  }else
  {
    moment.locale(language)
  }

  return (
    <>
      {isFetching || data.length > 0 ? (
        <TableContainer>
          <Table className={classes.table} aria-label="product table" stickyHeader>
            <TableHead>
              <TableRow>
                <StickyLeftTableCell>
                  <StyledTableCell>{getLang(lang, "label.NUM")}</StyledTableCell>
                </StickyLeftTableCell>
                <StyledTableCell>{getLang(lang, "label.REDEEMER_NAME")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.REQUESTED_DATE")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.STATUS")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.ITEM")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.PROCESS_DATE")}</StyledTableCell>
                <StickyRightTableCell>
                  <StyledTableCell>{getLang(lang, "label.ACTION")}</StyledTableCell>
                </StickyRightTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isFetching
                ? [...new Array(5)].map((i, index) => (
                  <SkeletonTableRow key={index} columnCount={7} />
                ))
                : data.map((row, index) => {
                  const actionItems = [
                    {
                      label: hasGiftRedemptionEditAccessRight ? getLang(lang, "label.VIEW_UPDATE") : getLang(lang, "label.VIEW"),
                      action: () => handleView({ id: row.orderId })
                    }
                  ]
                  return (
                    <StyledTableRow key={index} hover role="checkbox" tabIndex={-1}>
                      <StickyLeftTableCell>
                        <StyledTableCell component="th" scope="row" style={{ borderBottom: "0px" }}>
                          {index + 1}
                        </StyledTableCell>
                      </StickyLeftTableCell>
                      <StyledTableCell>{row.name}</StyledTableCell>
                      <StyledTableCell>{moment(row.dateAdded).format('ll')}</StyledTableCell>
                      <StyledTableCell style={{ fontWeight: 700 }}
                        className={clsx({
                          [classes.unprocressed]: row.status === "rejected",
                          [classes.processed]: row.status === "approved",
                          [classes.pending]: row.status === "pending"
                        })}>  {row.status === 'approved' ? getLang(lang, 'label.APPROVED')
                          : row.status === 'rejected' ? getLang(lang, 'label.REJECTED')
                            : getLang(lang, 'label.PENDING')}
                        {/* QUICK FIX FOR LANGUAGES TRANSLATION */}
                      </StyledTableCell>
                      <StyledTableCell>{row.item.toLocaleString("en-US")}</StyledTableCell>
                      <StyledTableCell >
                        {moment(row.dateProceed).format('lll')}
                      </StyledTableCell>
                      <StickyRightTableCell>
                        <StyledTableCell>
                          <div style={{ display: "flex", justifyContent: "center" }}>
                            <ActionButton actionItems={actionItems} popperStyle={{ zIndex: 999 }} />
                          </div>
                        </StyledTableCell>
                      </StickyRightTableCell>
                    </StyledTableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : !search && !isLoadingRedemptionError && (
        <Box>
          <Table className={classes.table} aria-label="product table" stickyHeader>
            <TableHead>
              <TableRow>
                <StyledTableCell>{getLang(lang, "label.NUM")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.REDEEMER_NAME")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.REQUESTED_DATE")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.STATUS")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.ITEM")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.PROCESS_DATE")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.ACTION")}</StyledTableCell>
              </TableRow>
            </TableHead>
          </Table>
          <Box py={9} className={classes.emptyContainer}>
            <Typography variant="body1">
              {getLang(lang, "paragraph.NO_ORDER")}
            </Typography>
            <Box mt={2}>
            </Box>
          </Box>
        </Box>

      )}

      {!isFetching && data.length === 0 && search && (
        <Alert severity="info">{getLang(lang, "message.info.NO_GIFT_REDEMPTION")}</Alert>
      )}

      {!isFetching && isLoadingRedemptionError && (
        <RefreshTable handleReload={handleReload} />
      )}

      {!isFetching && data.length > 0 && (
        <TablePagination
          style={{
            backgroundColor: "#fff",
            boxShadow: "0px -1px 0px rgba(58, 77, 84, 0.15)",
          }}
          ActionsComponent={TablePaginationActions}
          rowsPerPageOptions={paginationOptions}
          component="div"
          count={totalRecords}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage={getLang(lang, 'label.ROWS_PER_PAGE')}
          labelDisplayedRows={({ from, to, count }) => `${from.toLocaleString("en-US")}-${to.toLocaleString("en-US")} ${getLang(lang, 'label.OF')} ${count.toLocaleString("en-US")}`}
        />
      )}
    </>
  )
}
