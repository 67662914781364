import React from "react";
import {
  Box,
  Typography,
  Grid,
  Button,
  FormControlLabel,
  TextField,
  Checkbox,
  Divider,
  makeStyles,
} from "@material-ui/core";
import Dialog from "components/dialog/customDialog";
import {getLang} from "../../../../../app/feature/constants";
import {Formik} from "formik";
import DateTimePicker from "../../../../../components/input/dateTimePicker";

const style = (theme) => ({
  dateContainer: {
    height: 43,
    backgroundColor: "#ECEFF0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    borderRadius: "4px 0px 0px 4px",
  },
  dateView: {
    paddingRight: theme.spacing(1),
  },
  divider: {
    height: "100%",
    display: "flex",
    alignSelf: "center",
  },
  dividerContainer: {
    backgroundColor: "#ECEFF0",
    display: "flex",
  }
});

const useStyles = makeStyles(style);

export default function ReinstateWarrantyDialogComponent({
  isOpen,
  handleClose,
  handleSubmit,
  consumerWarrantyDetail,
  lang,
}) {
  const classes = useStyles();

  return (
    <Dialog
      isOpen={isOpen}
      handleClose={handleClose}
      disableEnforceFocus={true}
      disableAutoFocus={true}
      disableEscapeKeyDown={true}
      disableBackdropClick={true}
      PaperProps={{ style: { borderRadius: "10px" } }}
    >
      <Box p={4} py={3}>
        <Typography>
          <Box fontWeight="fontWeightBold" textAlign="center">
            {getLang(lang, "paragraph.REINSTATE_DIALOG_TITLE")}
          </Box>
          <Box fontcolor="text.secondary" textAlign="center" fontSize="fontSize" pt={1} pb={3}>
            {getLang(lang, "paragraph.REINSTATE_DIALOG_CONTENT")}
          </Box>
          <Formik
            onSubmit={handleSubmit}
            initialValues={{
              toExtend: false,
              extendedMonth: 0,
            }}
          >
            {(formik) => (
              <form onSubmit={formik.handleSubmit}>
                <Box pb={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formik.values.toExtend}
                        onChange={formik.handleChange}
                        name="toExtend"
                        color="secondary"
                      />
                    }
                    label={getLang(lang, "label.EXTEND_WARRANTY")}
                  />
                </Box>

                {formik.values.toExtend && (
                  <Box pb={3}>
                    <Grid container>
                      <Grid item xs={5} style={{ width: "100%" }}>
                        <Box display="flex">
                          <Box className={classes.dateContainer}>
                            <Typography
                              variant="subtitle2"
                              style={{
                                fontWeight: "bold",
                                color: " rgba(58, 77, 84, 0.33)",
                              }}
                            >
                              {getLang(lang, "label.VALIDITY_DATE")}
                            </Typography>
                          </Box>
                          <Box className={classes.dividerContainer}>
                            <Divider
                              orientation="vertical"
                              className={classes.divider}
                            />
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={7} style={{ width: "100%" }}>
                        <DateTimePicker
                          required
                          label=""
                          disabled={true}
                          value={consumerWarrantyDetail.endAt}
                          format="DD MMM YYYY"
                          InputPropStyle={{
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0,
                          }}
                        />
                      </Grid>
                    </Grid>

                    <Box mt={2}>
                      <Grid container>
                        <Grid item xs={5} style={{ width: "100%" }}>
                          <Box display="flex">
                            <Box className={classes.dateContainer} style={{ height: "56px" }}>
                              <Typography
                                variant="subtitle2"
                                style={{
                                  fontWeight: "bold",
                                  color: " rgba(58, 77, 84, 0.33)",
                                }}
                              >
                                {getLang(lang, "label.PERIOD")}
                              </Typography>
                            </Box>
                            <Box className={classes.dividerContainer}>
                              <Divider
                                orientation="vertical"
                                className={classes.divider}
                              />
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={7} style={{ width: "100%" }}>
                          <TextField
                            type="number"
                            name="extendedMonth"
                            variant="outlined"
                            value={formik.values.extendedMonth}
                            onChange={formik.handleChange}
                            style={{
                              backgroundColor: "#ECEFF0",
                              borderRadius: "0px 4px 4px 0px",
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Box>

                    <Box mt={2}>
                      <Grid container>
                        <Grid item xs={5} style={{ width: "100%" }}>
                          <Box display="flex">
                            <Box className={classes.dateContainer}>
                              <Typography
                                variant="subtitle2"
                                style={{
                                  fontWeight: "bold",
                                  color: " rgba(58, 77, 84, 0.33)",
                                }}
                              >
                                {getLang(lang, "label.EXTENDED_DATE")}
                              </Typography>
                            </Box>
                            <Box className={classes.dividerContainer}>
                              <Divider
                                orientation="vertical"
                                className={classes.divider}
                              />
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={7} style={{ width: "100%" }}>
                          <DateTimePicker
                            required
                            label=""
                            disabled={true}
                            format="DD MMM YYYY"
                            value={new Date(consumerWarrantyDetail.endAt).setMonth(new Date(consumerWarrantyDetail.endAt).getMonth() + formik.values.extendedMonth)}
                            InputPropStyle={{
                              borderTopLeftRadius: 0,
                              borderBottomLeftRadius: 0,
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                )}

                <Grid container>
                  <Grid item xs={6}>
                    <Box display="flex" justifyContent="center" pr={1}>
                      <Button
                        size="large"
                        variant="outlined"
                        fullWidth={true}
                        onClick={handleClose}
                      >
                        {getLang(lang, "label.CANCEL")}
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box display="flex" justifyContent="center" pl={1}>
                      <Button
                        size="large"
                        color="secondary"
                        variant="contained"
                        fullWidth={true}
                        type="submit"
                      >
                        {getLang(lang, "label.REINSTATE")}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </Typography>
      </Box>
    </Dialog>
  );
}
