import axios from "axios";

const endpoint_url = "/consumer/admin/api/v1/counterfeit-report";

const get = (parameters) => {
  return new Promise((resolve, reject) => {
    axios
      .get(endpoint_url
        ,{
        params:{
          length: parameters.length,
          start:parameters.start,
          search:parameters.search,
          order:parameters.order,
          column:parameters.column,
          from_date:parameters.from_date,
          to_date:parameters.to_date,
          draw:parameters.draw,
          enc:parameters.enc
        }}
      )
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};
const counterfeitReport = {
  get
};

export default counterfeitReport;
