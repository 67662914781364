import { createSlice } from "@reduxjs/toolkit";
import { TRACKCO } from "modules/trackco/constants";
import {
  validateInvitationCode,
  validateResetPasswordToken,
} from "../action"

const initialState = {
  inviter: {
    distributor: {
      uuid: "",
      picture: "",
      name: "",
      firstName: "",
      lastName: "",
      email: "",
    },
    branch: {
      uuid: "",
      picture: "",
      name: "",
    },
    isInvitationValid: false,
  },
  profile: {
    type: "",
    invitationCode: "",
    email: "",
    password: "",
    confirmPassword: "",
    firstName: "",
    lastName: "",
    gender: "",
    countryCode: "",
    phoneNumber: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    country: "",
    locale: "",
    picture: ""
  },

}

const trackcoSlice = createSlice({
  name: TRACKCO,
  initialState,
  reducers: {
    setInvitationCode(state, action) {
      const { payload } = action;

      state.profile.invitationCode = payload.invitationCode;
    },
    setVerificationEmail(state, action) {
      const { payload } = action;

      state.profile.email = payload.email;

    }

  },
  extraReducers: {
    [validateInvitationCode.fulfilled]: (state, action) => {
      const { payload } = action;

      state.inviter.isInvitationValid = payload.data.is_valid;
      if (payload.data.is_valid){
        state.inviter.branch.uuid = payload.data.branch.uuid;
        state.inviter.branch.name = payload.data.branch.name;
        state.inviter.branch.picture = payload.data.branch.picture ? payload.data.branch.picture.url : "";
        if (payload.data.distributor !== null) {
          state.inviter.distributor.uuid = payload.data.distributor.uuid;
          state.inviter.distributor.picture = payload.data.distributor.picture;
          state.inviter.distributor.name = payload.data.distributor.name;
          state.inviter.distributor.firstName = payload.data.distributor.first_name;
          state.inviter.distributor.lastName = payload.data.distributor.last_name;
          state.inviter.distributor.email = payload.data.distributor.email;
        }
      }
    },

    [validateResetPasswordToken.fulfilled]: (state, action) => {
      const { payload } = action;

      if (payload.data.is_valid) {
        state.profile.email = payload.data.user.email;
        state.profile.picture = payload.data.user.picture;
      }
    }
  }
})

export const {
  setInvitationCode,
  setVerificationEmail,
} = trackcoSlice.actions

export const trackcoReducer = trackcoSlice.reducer;