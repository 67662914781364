import React, { useState, useRef } from "react";
import BatchTable from "../../components/table/batchTable";
import { Formik } from "formik";
import { getLang } from "app/feature/constants";
import {
  Box,
  Button,
  Divider,
  MenuItem,
  TextField,
  makeStyles,
} from "@material-ui/core";
import BranchSearch from "modules/stockflow/components/select/branchSearch";
import clsx from "clsx";

const useStyle = makeStyles((theme) => ({
  searchContainer: {
    backgroundColor: theme.palette.background.paper,
    display: "grid",
    gridTemplateColumns: "7fr 3.5fr 2fr 2fr",
    columnGap: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1),
    },
  },
  onFocusBorder: {
    borderRadius: 4,
    outlineWidth: "1px",
    outlineStyle: "solid",
    outlineColor: theme.palette.secondary.main,
  },
  dropdownSearchBox: {
    backgroundColor: "#E9EDEF",
    borderRadius: 4,
    width: "100%",
    alignItems: "center",
  },
  select: {
    minWidth: "170px",
    [theme.breakpoints.down("xs")]: {
      minWidth: "50%",
    },
  },
  inputRoot: {
    fontWeight: 500,
    paddingTop: "8px !important",
    paddingBottom: "7px !important",
    paddingLeft: "10px !important",
  },
  divider: {
    height: "70%",
    placeSelf: "center",
  },
  textFieldInput: {
    padding: "7px",
    paddingTop: "8px",
    fontWeight: 500,
    color: theme.palette.primary.main,
    [theme.breakpoints.down("xs")]: {
      minWidth: "50%",
    },
  },
  menuItemText: {
    padding: 10,
    fontWeight: 500,
  },
}));

export default function BatchComponent({
  hasSerialNumberGenerateAccessRight,
  isFetching,
  generateBatchData,
  handleChangePage,
  handleChangeRowsPerPage,
  isLoading,
  length,
  language,
  handleSearch,
  handleReload,
  paginationOptions,
  lang,
  handleAppAuthorizeStatusChange,
  handleScanVerifyStatusChange,
  setSelectedBatch,
  tableFilter,
  clearSearch,
  isSearchResult,
  setQuickEdit,
  hasBatchVerificationEditRight,
  hasBatchAuthenticatorEditRight
}) {
  const classes = useStyle();
  const textRef = useRef();

  const [onFocus, setOnFocus] = useState(false);
  const columnDropdown = [
    { name: getLang(lang, "label.BATCH_NUMBER"), value: "batch_number" },
  ];

  return (
    <>
      <Formik
        initialValues={{
          searchColumn: tableFilter.searchColumn || columnDropdown[0].value,
          search: tableFilter.search,
          branch: tableFilter.branch,
        }}
        enableReinitialize
        onSubmit={handleSearch}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <Box p={2} mb={2} className={classes.searchContainer}>
              <Box
                display="flex"
                className={clsx(classes.dropdownSearchBox, {
                  [classes.onFocusBorder]: onFocus,
                })}
              >
                <TextField
                  className={classes.select}
                  select
                  SelectProps={{
                    MenuProps: {
                      MenuListProps: {
                        style: { padding: 0 },
                      },
                      style: { marginTop: 5 },
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    },
                  }}
                  value={formik.values.searchColumn}
                  size="small"
                  margin="none"
                  InputProps={{
                    disableUnderline: true,
                    classes: { input: classes.inputRoot },
                  }}
                >
                  {columnDropdown.map((column, index) => (
                    <MenuItem
                      className={classes.menuItemText}
                      value={column.value}
                      key={index}
                      onClick={() => {
                        formik.setFieldValue("searchColumn", column.value);
                      }}
                    >
                      {column.name}
                    </MenuItem>
                  ))}
                </TextField>
                <Divider orientation="vertical" className={classes.divider} />
                <TextField
                  fullWidth={true}
                  onFocus={() => setOnFocus(true)}
                  onBlur={() => setOnFocus(false)}
                  size="small"
                  margin="none"
                  placeholder={getLang(lang, "placeholder.SEARCH")}
                  inputRef={textRef}
                  InputProps={{
                    disableUnderline: true,
                    margin: "none",
                  }}
                  inputProps={{
                    className: classes.textFieldInput,
                  }}
                  value={formik.values.search}
                  onChange={(event) => {
                    formik.setFieldValue("search", event.target.value);
                  }}
                />
              </Box>
              <BranchSearch
                placeholder={getLang(lang, "placeholder.BRANCH")}
                value={formik.values.branch}
                handleChange={(value) => formik.setFieldValue("branch", value)}
                isEmpty={formik.values.branch?.length === 0}
                styles={{
                  padding: "0.25em 0.75em",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderRadius: 4,
                  width: "100%",
                  overflow: "hidden",
                }}
                handleChipDelete={(branch) => {
                  let temp = [...formik.values.branch];
                  if (temp.length > 0) {
                    const filteredBranchs = temp.filter(
                      (item) => item.node_id !== branch.node_id
                    );
                    temp = [...filteredBranchs];
                  }
                  formik.setFieldValue("branch", temp);
                }}
              />
              <Button
                variant="text"
                disableElevation
                color="primary"
                size="small"
                onClick={() => {
                  clearSearch();
                }}
              >
                {getLang(lang, "label.CLEAR_SEARCH")}
              </Button>
              <Button
                variant="contained"
                disableElevation
                color="primary"
                size="small"
                type="submit"
              >
                {getLang(lang, "label.SEARCH")}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
      <BatchTable
        hasSerialNumberGenerateAccessRight={hasSerialNumberGenerateAccessRight}
        isFetching={isFetching}
        generateBatchData={generateBatchData}
        rowsPerPage={tableFilter.rowsPerPage}
        page={tableFilter.page}
        isSearchResult={isSearchResult}
        handleChangePage={handleChangePage}
        language={language}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        isLoading={isLoading}
        length={length}
        handleReload={handleReload}
        paginationOptions={paginationOptions}
        handleAppAuthorizeStatusChange={handleAppAuthorizeStatusChange}
        handleScanVerifyStatusChange={handleScanVerifyStatusChange}
        lang={lang}
        setSelectedBatch={setSelectedBatch}
        setQuickEdit={setQuickEdit}
        hasBatchVerificationEditRight={hasBatchVerificationEditRight}
        hasBatchAuthenticatorEditRight={hasBatchAuthenticatorEditRight}
      />
    </>
  );
}
