import { Box, Typography } from "@material-ui/core";
import { fontTextStyle } from "modules/reporting/styles";
import React from "react";
import { ContentCard } from "../../layout";
import { responseHandler } from "modules/reporting/utils/helper";
import { BoxLoading } from "../../skeleton/loading";
import { BoxError } from "../../skeleton/error";

export default function ModelTitleCardComponent({
	status,
	data,
	translate
}) {
	const fontClasses = fontTextStyle();

	const renderData = (data) => {

		if (Object.keys(data).length === 0 || !data.isSuccess) {
			return null;
		}

		const { model_summary } = data;
		return (
			<Box display="flex" flexDirection="column" style={{ gap: "12px" }} justifyContent={"center"} alignContent="center">
				<Typography className={fontClasses.text__muted}>
					Drilldown by model
				</Typography>
				<Box
					display="flex"
					style={{
						gap: "12px",
					}}
					alignItems="center"
				>
					<Typography className={fontClasses.text__card_title}>
						{model_summary.name}
					</Typography>
				</Box>
			</Box>
		)
	}

	return (
		<ContentCard>
			<Box
				padding="15px 20px"
			>
				{
                    responseHandler(
                        data.productModelDetailData,
                        status.isFetchingProductModelDetailData,
                        (
                            renderData(data.productModelDetailData)
                        ),
                        (
                            <BoxLoading />
                        ),
                        (
                            <>empty</>
                        ),
                        (
                            <>no result</>
                        ),
                        (
                            <BoxError 
                                title={translate("label.LOADING_FAILED")}
                                value={translate("paragraph.LOADING_FAILED_DESCRIPTION")}
                            />
                        )
                    )
                }
			</Box>
		</ContentCard>
	);
}