import React from "react";
import {
  Paper,
  Box,
  Grid,
  Divider,
  IconButton,
  Typography,
  makeStyles,
  FormControl,
  MenuItem,
  Select,
  withStyles,
  InputBase,
  TablePagination
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import InfoIcon from "@material-ui/icons/Info";
import { TablePaginationActions } from "components/tablePagination";
import { Skeleton } from "@material-ui/lab";
import { LITE, BUSINESS, PROFESSIONAL } from "lib/constants/subscriptionPlan";
import ActionButton from "components/button/actionDetailButton";
import { getLang } from "app/feature/constants";

const style = (theme) => ({
  root: {
    width: 650,
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    }
  },
  text: {
    color: "rgba(58, 77, 84, 0.33)",
    fontWeight: "bold"
  },
  header: {
    padding: "4px 0px",
    paddingLeft: theme.spacing(3),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  searchContainer: {
    padding: theme.spacing(1, 3, 1, 3),
    [theme.breakpoints.up("sm")]: {
      display: "flex"
    }
  },
  selectContainer: {
    paddingLeft: theme.spacing(2)
  },
  detailSearchContainer: {
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      alignItems: "center"
    },
    [theme.breakpoints.down("xs")]: {
      display: "grid",
      gridTemplateColumns: "2fr 6fr",
      paddingBottom: "4px",
      alignItems: "center"
    }
  },
  detailSearchTypeContainer: {
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(2),
      display: "flex",
      alignItems: "center"
    },
    [theme.breakpoints.down("xs")]: {
      display: "grid",
      gridTemplateColumns: "2fr 6fr",
      paddingBottom: "4px",
      alignItems: "center"
    }
  },
  textQty: {
    color: "rgba(58, 77, 84, 0.33)",
    fontSize: "10px",
    paddingLeft: "3px",
    alignSelf: "center"
  },
  gridQty: {
    [theme.breakpoints.down("xs")]: {
      placeContent: "flex-end"
    },
    width: "100%",
    display: "flex",
    placeContent: "flex-end"
  },
  infoContainer: {
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.spacing(3)
    },
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(1)
    }
  },
  footer: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2, 2, 3, 2),
    justifyContent: "center"
  },
  footerText: {
    paddingLeft: "4px",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: theme.spacing(1)
    }
  },
  toolbar: {
    minHeight: 0
  },
  displayMessage: {
    padding: theme.spacing(3, 2, 3, 2),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100px"
  }
});

const useStyles = makeStyles(style);

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(1)
    }
  },
  input: {
    borderRadius: 4,
    position: "relative",
    border: "1px solid",
    borderColor: "rgba(0, 0, 0, 0.2)",
    fontSize: 12,
    padding: "5px 10px 5px 10px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)"
    },
    paddingRight: "60px !important"
  }
}))(InputBase);

export default function TransactionHistoryTableComponent({
  handleClose,
  yearlist,
  monthlist,
  type,
  selectedYear,
  selectedMonth,
  selectedType,
  handleYear,
  handleMonth,
  handleType,
  transactionHistory,
  isFetching,
  page,
  handlePageChange,
  handleRowPerPageChange,
  length,
  rowsPerPage,
  paginationOptions,
  lang
}) {
  const classes = useStyles();

  const getPlanName = (type) => {
    switch (type) {
      case LITE:
        return (
          <Typography variant="body2" color="primary">
            {getLang(lang, "label.SUBSCRIPTION_PLAN")} ({getLang(lang, "label.LITE")})
          </Typography>
        );

      case BUSINESS:
        return (
          <Typography variant="body2" color="primary">
            {getLang(lang, "label.SUBSCRIPTION_PLAN")} ({getLang(lang, "label.BIZ")})
          </Typography>
        );

      case PROFESSIONAL:
        return (
          <Typography variant="body2" color="primary">
            {getLang(lang, "label.SUBSCRIPTION_PLAN")} ({getLang(lang, "label.PRO")})
          </Typography>
        );

      default:
        break;
    }
  };

  return (
    <Paper elevation={0} className={classes.root}>
      <div className={classes.header}>
        <Typography
          variant="subtitle2"
          color="primary"
          style={{ fontWeight: "bold" }}
        >
          {getLang(lang, "label.TRANSACTION_HISTORY")}
        </Typography>
        <IconButton color="primary" onClick={handleClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </div>
      <Divider />
      <Box className={classes.searchContainer}>
        <Box className={classes.detailSearchContainer}>
          <Typography color="primary" variant="body2">
            {getLang(lang, "label.BY_YEAR")}
          </Typography>

          <FormControl variant="outlined" className={classes.selectContainer}>
            <Select
              value={selectedYear}
              onChange={handleYear}
              label="Year"
              input={<BootstrapInput />}
              disabled={isFetching}
            >
              <MenuItem value="All">{getLang(lang, "label.ALL")}</MenuItem>
              {yearlist.map((value, index) => (
                <MenuItem value={value} key={index}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Box className={classes.detailSearchTypeContainer}>
          <Typography color="primary" variant="body2">
            {getLang(lang, "label.BY_MONTH")}
          </Typography>

          <FormControl variant="outlined" className={classes.selectContainer}>
            <Select
              value={selectedMonth}
              onChange={handleMonth}
              label="Month"
              input={<BootstrapInput />}
              disabled={isFetching}
            >
              <MenuItem value="All">{getLang(lang, "label.ALL")}</MenuItem>
              {monthlist.map((m, index) => (
                <MenuItem value={m.value} key={index}>
                  {m.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Box className={classes.detailSearchTypeContainer}>
          <Typography color="primary" variant="body2">
            {getLang(lang, "label.TYPE")}
          </Typography>

          <FormControl variant="outlined" className={classes.selectContainer}>
            <Select
              value={selectedType}
              onChange={handleType}
              label="Type"
              input={<BootstrapInput />}
              disabled={isFetching}
            >
              <MenuItem value="All">{getLang(lang, "label.ALL")}</MenuItem>
              {type.map((t, index) => (
                <MenuItem value={t.value} key={index}>
                  {t.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Divider />
      {isFetching ? (
        <Box p={2}>
          <Skeleton width={130} height={30} />
          <Skeleton width={"100%"} height={30} />
          <Skeleton width={"100%"} height={30} />
          <Box pt={2} pb={2}>
            <Divider />
          </Box>

          <Skeleton width={130} height={30} />
          <Skeleton width={"100%"} height={30} />
          <Skeleton width={"100%"} height={30} />
          <Box pt={2} pb={2}>
            <Divider />
          </Box>

          <Skeleton width={130} height={30} />
          <Skeleton width={"100%"} height={30} />
          <Skeleton width={"100%"} height={30} />
        </Box>
      ) : (
        <Box>
          {!isFetching && transactionHistory.data.length > 0 ? (
            <Box>
              {transactionHistory.data.map((field, index) => (
                <Box key={index}>
                  <Box p={3} pt={2}>
                    <Typography
                      variant="caption"
                      style={{ color: "rgba(58, 77, 84, 0.33)" }}
                    >
                      {field.dateDisplay}
                    </Typography>

                    {field.transactions.map((transaction, index) => {
                      const actionItems = [
                        {
                          label: getLang(lang, "label.VIEW_INVOICE"),
                          action: () => window.open(`${transaction.receipt_url}`, "_blank"),
                        }
                      ];

                      return (
                        <Box key={index} className={classes.infoContainer}>
                          <Grid container style={{ alignItems: "center" }}>
                            <Grid
                              item
                              xs={8}
                              sm={4}
                              md={4}
                              style={{ width: "100%" }}
                            >
                              {transaction.type === "plan" ? (
                                getPlanName(transaction.name)
                              ) : (
                                <Typography variant="body2" color="primary">
                                  {getLang(lang, "label.PURCHASE")} {transaction.name === "QR Credit" ? getLang(lang, 'label.QR_CREDIT') : transaction.name}
                                </Typography>
                              )}
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              sm={2}
                              md={2}
                              style={{ width: "100%" }}
                            >
                              <Box className={classes.gridQty}>
                                {transaction.type === "plan" ? (
                                  transaction.unit === "month" ? (
                                    <Typography variant="body2" color="primary">
                                      {getLang(lang, "label.MONTHLY")}
                                    </Typography>
                                  ) : transaction.unit === "year" ? (
                                    <Typography variant="body2" color="primary">
                                      {getLang(lang, "label.YEARLY")}
                                    </Typography>
                                  ) : null
                                ) : (
                                  <>
                                    <Typography variant="body2" color="primary">
                                      {transaction.unit}
                                    </Typography>

                                    <Typography className={classes.textQty}>
                                      ({getLang(lang, "label.QTY")})
                                  </Typography>
                                  </>
                                )}
                              </Box>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={3}
                              md={3}
                              style={{ width: "100%", textAlign: "end" }}
                            >
                              <Typography variant="body2" color="textSecondary">
                                {`**** **** **** ${transaction.card}`}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={2}
                              md={2}
                              style={{ width: "100%", textAlign: "end" }}
                            >
                              <Typography variant="body2" color="primary">
                                {parseFloat(transaction.amount).toFixed(2)} USD
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={1}
                              md={1}
                              style={{ width: "100%", textAlign: "center" }}
                            >
                                <ActionButton popperStyle={{ zIndex: 1400 }} actionItems={actionItems} />
                            </Grid>
                          </Grid>
                        </Box>
                      );
                      })}
                  </Box>

                  <Divider />
                </Box>
              ))}
            </Box>
          ) : (
            <Box className={classes.displayMessage}>
              <Typography variant="body1" color="primary">
                {getLang(lang, "paragraph.NO_TRANSACTION_HISTORY")}
              </Typography>
            </Box>
          )}
        </Box>
      )}

      {!isFetching && transactionHistory.data.length > 0 && (
        <TablePagination
          style={{
            backgroundColor: "#fff",
            boxShadow: "0px -1px 0px rgba(58, 77, 84, 0.15)"
          }}
          classes={{
            toolbar: classes.toolbar
          }}
          ActionsComponent={TablePaginationActions}
          rowsPerPageOptions={paginationOptions}
          component="div"
          count={length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={(event) =>
            handleRowPerPageChange(event.target.value)
          }
          labelRowsPerPage={getLang(lang, 'label.ROWS_PER_PAGE')}
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${getLang(lang, 'label.OF')} ${count}`}
        />
      )}
      <Divider />
      <Box className={classes.footer}>
        <InfoIcon fontSize="small" color="primary" />
        <Typography
          variant="caption"
          color="primary"
          className={classes.footerText}
        >
          {getLang(lang, "paragraph.CHARGE_ADDITIONAL_FEE")}
        </Typography>
      </Box>
    </Paper>
  );
}
