import axios from "axios";

const getSerialNumberFirstScanList = payload => {
  return new Promise((resolve, reject) => {
    axios.post("/analytic/api/v1/dashboard/get-sn-first-scan-list", {
      start_date: payload.startDate,
      end_date: payload.endDate,
      page: payload.page,
      length: 25,
    })
      .then(response => resolve({...response.data, page: payload.page}))
      .catch(error => reject(error.response.data));
  });
};

export default getSerialNumberFirstScanList;
