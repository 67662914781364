import React, { useState } from "react";
import RegSettingFormComponent from "./regSettingForm.component";
import Dialog from "components/dialog/customDialog";
import PreviewRegForm from "../previewRegForm";
import {
  reorderRegisterFormField,
} from "modules/warranty/redux";
import { useDispatch, useSelector } from "react-redux";

function RegSettingFormContainer({
  children,
  formRef,
  type,
}) {
  const [isModalOpen, setModalOpen] = useState(false);
  const dispatch = useDispatch();
  const warrantyRegisterForm = useSelector(
    (state) => state.warranty.warrantyRegisterForm
  );

  const deliveryOrder = useSelector(
    (state) => state.warranty.warrantySetting.warrantyRequiredValidation
  );

  const lang = useSelector(state => state.constant.languages);

  const handlePreviewForm = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleDragEnd = (destination, source) => {

    if (!destination) return;

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    )
      return;
    dispatch(
      reorderRegisterFormField({
        sourceIndex: source.index,
        destinationIndex: destination.index,
        latestform: formRef.current.values.form
      })
    );
  };

  return (
    <>
      <RegSettingFormComponent
        children={children}
        warrantyRegisterForm={warrantyRegisterForm}
        deliveryOrder={deliveryOrder}
        formRef={formRef}
        type={type}
        handlePreviewForm={handlePreviewForm}
        handleDragEnd={handleDragEnd}
        lang={lang}

      />
      <Dialog
        isOpen={isModalOpen}
        handleClose={closeModal}
        disableEnforceFocus={true}
        disableAutoFocus={true}
        PaperProps={{
          style: {
            maxWidth: 700,
            overflowY: "hidden"
          }
        }}
      >
        <PreviewRegForm
          handleClose={closeModal}
          warrantyRegisterForm={warrantyRegisterForm}
        />
      </Dialog>
    </>
  );
}



export default RegSettingFormContainer;
