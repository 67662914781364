import React from "react";
import { Provider } from "react-redux";
import store from "../config/store";

const withStore = Component => props => {
  return (
    <Provider store={store}>
      <Component {...props} />
    </Provider>
  );
};

export default withStore;
