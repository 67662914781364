import koodServerInstance from "../s3/instance";
import PropTypes from "prop-types";

const apiLinkPreview = async url => {
  const data = { url };

  const response = await koodServerInstance.post("/url", data, {
    timeout: 10000
  });

  return response;
};

apiLinkPreview.propTypes = {
  url: PropTypes.string.isRequired
};

export default apiLinkPreview;
