import {
  fetchBatchRangeList,
  getGenerateBatch,
  updateBatchAppAuthorizeStatus,
  getExportFileDropdown,
  updateBatchScanVerifyStatus,
  updateBatchRedirectTo,
} from "../action";
import { setLoading, addAlert } from "modules/notification";
import { setGenerateBatch } from "modules/serial-number";
import { getApiLang } from "app/feature/constants";

export const getGenerateBatchMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    switch (type) {
      case getGenerateBatch.pending.type:
        // dispatch set loading to notification reducer
        dispatch(setLoading({ id: getGenerateBatch.typePrefix, state: true }));
        break;

      case getGenerateBatch.fulfilled.type:
        // dispatch remove loading from notification reducer
        next(setGenerateBatch(action.payload));
        dispatch(setLoading({ id: getGenerateBatch.typePrefix, state: false }));
        break;

      case getGenerateBatch.rejected.type:
        const lang = getState().constant.languages;
        dispatch(setLoading({ id: getGenerateBatch.typePrefix, state: false }));
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

export const fetchBatchRangeListMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case fetchBatchRangeList.pending.type:
        dispatch(
          setLoading({ id: fetchBatchRangeList.typePrefix, state: true })
        );
        break;

      case fetchBatchRangeList.fulfilled.type:
        dispatch(
          setLoading({ id: fetchBatchRangeList.typePrefix, state: false })
        );
        break;

      case fetchBatchRangeList.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({ id: fetchBatchRangeList.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };
export const getExportFileDropdownMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getExportFileDropdown.pending.type:
        dispatch(
          setLoading({ id: getExportFileDropdown.typePrefix, state: true })
        );
        break;

      case getExportFileDropdown.fulfilled.type:
        dispatch(
          setLoading({ id: getExportFileDropdown.typePrefix, state: false })
        );
        break;

      case getExportFileDropdown.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({ id: getExportFileDropdown.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };
export const updateBatchAppAuthorizeStatusMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case updateBatchAppAuthorizeStatus.pending.type:
        dispatch(
          setLoading({
            id: updateBatchAppAuthorizeStatus.typePrefix,
            state: true,
          })
        );
        break;

      case updateBatchAppAuthorizeStatus.fulfilled.type:
        dispatch(
          setLoading({
            id: updateBatchAppAuthorizeStatus.typePrefix,
            state: false,
          })
        );
        break;

      case updateBatchAppAuthorizeStatus.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: updateBatchAppAuthorizeStatus.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

export const updateBatchScanVerifyStatusMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case updateBatchScanVerifyStatus.pending.type:
        dispatch(
          setLoading({
            id: updateBatchScanVerifyStatus.typePrefix,
            state: true,
          })
        );
        break;

      case updateBatchScanVerifyStatus.fulfilled.type:
        dispatch(
          setLoading({
            id: updateBatchScanVerifyStatus.typePrefix,
            state: false,
          })
        );
        break;

      case updateBatchScanVerifyStatus.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: updateBatchScanVerifyStatus.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

export const updateBatchRedirectToMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case updateBatchRedirectTo.pending.type:
        dispatch(
          setLoading({
            id: updateBatchRedirectTo.typePrefix,
            state: true,
          })
        );
        break;

      case updateBatchRedirectTo.fulfilled.type:
        dispatch(
          setLoading({
            id: updateBatchRedirectTo.typePrefix,
            state: false,
          })
        );
        break;

      case updateBatchRedirectTo.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: updateBatchRedirectTo.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

export default [
  getGenerateBatchMiddleware,
  fetchBatchRangeListMiddleware,
  updateBatchAppAuthorizeStatusMiddleware,
  updateBatchScanVerifyStatusMiddleware,
  updateBatchRedirectToMiddleware,
];
