import axios from "axios";

const get = ({ id }) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/campaign/api/admin/instant-lucky-draw/prizes/${id}`)
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

const put = ({
  instantLuckyDrawId,
  title,
  imgUrl,
  quantity,
  description,
  orders,
  status,
  isGiftCode,
  fileKey,
  id
}) => {
  const body = {
    instantLuckyDrawId: instantLuckyDrawId,
    title: title,
    imgUrl: imgUrl,
    quantity: quantity,
    description: description,
    orders: orders,
    status: status,
    isGiftCode: isGiftCode,
    fileKey: fileKey
  };

  return new Promise((resolve, reject) => {
    axios
      .put(`/campaign/api/admin/instant-lucky-draw/prizes/${id}`, body)
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

const post = ({
  instantLuckyDrawId,
  title,
  imgUrl,
  quantity,
  description,
  orders,
  status,
  fileKey
}) => {
  const body = {
    instantLuckyDrawId: instantLuckyDrawId,
    title: title,
    imgUrl: imgUrl,
    quantity: quantity,
    description: description,
    orders: orders,
    status: status,
    fileKey: fileKey
  };

  return new Promise((resolve, reject) => {
    axios
      .post("/campaign/api/admin/instant-lucky-draw/prizes", body)
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

const prize = {
  put,
  get,
  post
};

export default prize;
