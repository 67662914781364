import React from "react";
import {
  makeStyles,
  Box,
  Typography,
  Grid,
  Button,
  // FormControlLabel,
  Divider,
  FormHelperText,
  // Link
} from "@material-ui/core";
import {
  AddImage,
  ErrorSVG,
  // UploadImageIcon
} from "../../../utils/static";
import InputTextField from "components/input/inputTextField";
import { ErrorMessage, Formik } from "formik";
import * as Yup from "yup";
// import clsx from "clsx";
// import AntSwitch from "components/switch/switch";
import { getLang } from "app/feature/constants";
import { isFileExceedLimit, isImage } from "lib/helper";

const style = (theme) => ({
  addPrizeContainer: {
    border: "1px solid #3A4D54",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
    margin: theme.spacing(2, 3)
  },
  photoContainer: {
    background: "#EBEDEE",
    border: "1px solid rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
    width: "120px",
    height: "120px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden"
  },
  button: {
    width: "100%",
    height: "100%"
  },
  toggleControl: {
    margin: "7px 35px 16px 0px"
  },
  input: {
    display: "none"
  },
  buttonExcel: {
    backgroundColor: "#ECEFF0",
    marginBottom: "4px",
    boxShadow: "none",
    fontWeight: "500",
    color: "rgba(32, 32, 32, 0.33)",
    fontSize: "14px",
    fontFamily: "roboto",
    width: "100%",
    borderRadius: 4,
    display: "flex",
    placeContent: "start",
    padding: "9px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#ECEFF0",
      boxShadow: "none"
    }
  },
  buttonLabel: {
    display: "flex",
    justifyContent: "space-between"
  },
  buttonLabelImage: {
    color: "black"
  },
  endIcon: {
    marginRight: 4
  }
});

const useStyles = makeStyles(style);

export default function CreatePrizeCardComponent({
  handleDelete,
  handleSubmit,
  inputImgRef,
  handleImgChange,
  prizeImage,
  handleDownloadTemplate,
  lang
}) {
  const classes = useStyles();

  return (
    <Formik
      initialValues={{
        description: "",
        prizeName: "",
        quantity: 0,
        prizeImage: "",
        giftCode: "",
        isGiftCode: false
      }}
      validationSchema={Yup.object({
        prizeName: Yup.string().trim().required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
        quantity: Yup.number().moreThan(0, getLang(lang, "message.error.FORM_VALIDATE_MORETHAN", {value: 0})),
        prizeImage: Yup.lazy(
          (value) => {
            if (value instanceof File) {
              return Yup.mixed().test(
                'fileSize',
                'The file is too large',
                (v) => {
                  return !isFileExceedLimit(v.size, 10, 'MB')
                }
              ).test(
                'fileType',
                'The file is not a image',
                (v) => {
                  return isImage(v)
                }
              )
            } else {
              return Yup.string().required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED"))
            }
          }
        )
      })}
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <form onSubmit={formik.handleSubmit}>
          <Box className={classes.addPrizeContainer}>
            <Box p={3} pb={2}>
              <Grid container spacing={1}>
                <Grid item xs={3} sm={3} md={3}>
                  <Typography variant="body2" color="primary">
                    {getLang(lang, "label.PRIZE_IMAGE")} <Typography style={{ color: "#DE350B", display: 'inline-block' }}>*</Typography>
                  </Typography>
                  <Box className={classes.photoContainer}>
                    <input
                      accept="image/*"
                      id="contained-button-file"
                      type="file"
                      ref={inputImgRef}
                      style={{ display: "none" }}
                      onChange={(e) => {
                        handleImgChange(e)
                        if (e.currentTarget.files.length > 0) {
                          formik.setFieldValue("prizeImage", e.currentTarget.files[0])
                        }
                      }}
                    />
                    <label
                      htmlFor="contained-button-file"
                      style={{ height: "100%", width: "100%" }}
                    >
                      <Button
                        size="small"
                        className={classes.button}
                        disableElevation
                        component="span"
                        type="button"
                      >
                        {prizeImage ? (
                          <img
                            src={prizeImage}
                            alt="prize"
                            height="100%"
                            width="100%"
                          />
                        ) : (
                          <AddImage />
                        )}
                      </Button>
                    </label>
                  </Box>
                  <FormHelperText
                    error={formik.errors.prizeImage ? true : false}
                  >
                    {ErrorMessage({ name: "prizeImage" })}
                  </FormHelperText>
                </Grid>
                <Grid item xs={9} sm={9} md={9}>
                  <Box>
                    <Typography variant="body2" color="primary">
                      {getLang(lang, "label.PRIZE_NAME")} <Typography style={{ color: "#DE350B", display: 'inline-block' }}>*</Typography>
                    </Typography>
                    <InputTextField
                      required
                      variant="filled"
                      size="small"
                      fullWidth
                      placeholder={getLang(lang, "placeholder.ENTER_NAME")}
                      {...formik.getFieldProps("prizeName")}
                    />

                    <Box pt={2}>
                      <Typography variant="body2" color="primary">
                        {getLang(lang, "label.QUANTITY")} <Typography style={{ color: "#DE350B", display: 'inline-block' }}>*</Typography>
                      </Typography>
                      <InputTextField
                        required
                        variant="filled"
                        size="small"
                        fullWidth
                        type="number"
                        InputProps={{
                          min: "1"
                        }}
                        placeholder={getLang(lang, "placeholder.ENTER_QUANTITY")}
                        {...formik.getFieldProps("quantity")}
                        error={
                          formik.touched.quantity && formik.errors.quantity
                            ? true
                            : false
                        }
                        helperText={ErrorMessage({ name: "quantity" })}
                      />
                    </Box>

                    <Box pt={2} pb={2}>
                      <Typography variant="body2" color="primary">
                        {getLang(lang, "label.DESCRIPTION")} <Typography style={{ color: "#DE350B", display: 'inline-block' }}>*</Typography>
                      </Typography>
                      <InputTextField
                        required
                        multiline
                        rows={3}
                        variant="filled"
                        size="small"
                        fullWidth
                        placeholder={getLang(lang, "placeholder.ENTER_DESCRIPTION")}
                        {...formik.getFieldProps("description")}
                      />
                    </Box>

                    {/* <FormControlLabel
                      classes={{
                        root: classes.toggleControl
                      }}
                      control={
                        <AntSwitch
                          checked={formik.getFieldProps("isGiftCode").value}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "isGiftCode",
                              !formik.getFieldProps("isGiftCode").value
                            );
                          }}
                          disabled={formik.isSubmitting}
                        />
                      }
                      label={
                        <Typography variant="body2" color="primary">
                          &ensp;Upload Gift Code
                        </Typography>
                      }
                    />

                    {formik.values.isGiftCode
                      ? formik.getFieldProps("isGiftCode").value && (
                          <>
                            <Box>
                              <input
                                accept=".xlsx, .xls, .csv"
                                className={classes.input}
                                id={"contained-button-file-excel"}
                                type="file"
                                onChange={(e) => {
                                  e.persist();
                                  formik.setFieldValue(
                                    "excel",
                                    e.currentTarget.files[0]
                                  );
                                }}
                              />
                              <label htmlFor={"contained-button-file-excel"}>
                                <Button
                                  variant="contained"
                                  component="span"
                                  type="button"
                                  endIcon={<UploadImageIcon />}
                                  classes={{
                                    root: classes.buttonExcel,

                                    label: clsx(classes.buttonLabel, {
                                      [classes.buttonLabelImage]:
                                        formik.getFieldProps("excel").value
                                    }),
                                    endIcon: classes.endIcon
                                  }}
                                >
                                  {formik.getFieldProps("excel").value
                                    ? formik.getFieldProps("excel").value.name
                                    : "Upload Excel File"}
                                </Button>
                              </label>
                            </Box>
                            <Link
                              variant="caption"
                              color="primary"
                              style={{ cursor: "pointer" }}
                              onClick={handleDownloadTemplate}
                            >
                              DOWNLOAD TEMPLATE
                            </Link>
                          </>
                        )
                      : null} */}
                  </Box>
                </Grid>
              </Grid>
            </Box>

            <>
              <Divider />
              <Box px={3} pt={1} pb={1} display="flex" alignItems="center">
                <ErrorSVG />
                <Typography
                  variant="caption"
                  style={{
                    paddingLeft: "4px",
                    color: "rgba(58, 77, 84, 0.33)",
                  }}
                >
                  {getLang(lang, "paragraph.RECOMMENDED_IMAGE_SIZE_1")}
                </Typography>
              </Box>
              <Divider />

              <Box
                style={{
                  display: "flex",
                  padding: "10px",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  disableElevation
                  type="button"
                  style={{ marginRight: "4px" }}
                  onClick={(e) => {
                    formik.resetForm()
                    handleDelete(e)
                  }}
                >
                  {getLang(lang, "label.CANCEL")}
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  disableElevation
                  type="submit"
                >
                  {getLang(lang, "label.ADD")}
                </Button>
              </Box>
            </>
          </Box>
        </form>
      )}
    </Formik>
  );
}
