import React from "react";
import SignUpVerificationPage from "./signup-verification.page";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { connect } from 'react-redux';
import { selectNewAccountEmail } from "../../redux/selector";

function SignUpVerificationContainer({ email }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const type = "SignUp";

  return <SignUpVerificationPage isMobile={isMobile} type={type} />;
}

const mapStateToProps = state => ({
  email: selectNewAccountEmail(state)
});

export default connect(mapStateToProps, null)(SignUpVerificationContainer);