import axios from "axios";

export default function updateGroupRules(parameters) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/account/api/v1/branch-group/add-or-update-rule`, {
        branch_group_uuid: parameters.branch_group_uuid,
        rules: parameters.rules,
        custom_rules: parameters.custom_rules,
        deleted_custom_rules_from: parameters.deleted_custom_rules_from,
        deleted_custom_rules_to: parameters.deleted_custom_rules_to,
      })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data));
  });
}
