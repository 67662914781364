import React, { useEffect, useState } from "react";
import { makeStyles, Box, Paper, Grid, Divider } from "@material-ui/core";

import SignUpLayout from "../../components/layout/signUp";
import SignUp from "../../components/form/signUpForm";
import Header from "../../components/header";
import Footer from "../../components/footer";
import {
  RESELLER_PLAN_A_SIGN_UP,
  RESELLER_PLAN_B_SIGN_UP,
  PARTNER_PLAN_A_SIGN_UP,
  PARTNER_PLAN_B_SIGN_UP,
} from "../../config/public.route";

import BackgroundSignUp from "../../utils/image/background-signup.png";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { isValidPartner, isValidReseller } from "modules/public/redux/action";
import LoadingComponent from "components/loading";

const useStyle = makeStyles(theme => ({
  root: {
    maxWidth: 850,
    maxHeight: 600,
    boxShadow: "0px 10px 15px rgba(0, 0, 0, 0.1)",
    [theme.breakpoints.down("sm")]: {
      height: "100%",
      alignSelf: "center",
      width: "70%"
    },
    [theme.breakpoints.down("xs")]: {
      height: "100%",
      alignSelf: "center",
      width: "90%"
    }
  },
  paper: {
    [theme.breakpoints.down("sm")]: {
      position: "relative"
    }
  },
  form: {
    display: "flex",
    placeContent: "center"
  },
  background: {
    height: "100%"
  }
}));

export default function SignUpComponent({ isMobile, type }) {
  const classes = useStyle();

  const dispatch = useDispatch();
  const route = useRouteMatch();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);

  const isValidRegistrationUrl = useSelector(state => state.signup.isValidRegistrationUrl)
  useEffect(() => {
    if (!isValidRegistrationUrl) {
      history.push('/invalid')
    }
    const code = route.params.code
    const path = route.path
    if (code) {
      switch (path) {
        case RESELLER_PLAN_A_SIGN_UP:
        case RESELLER_PLAN_B_SIGN_UP:
          dispatch(isValidReseller({ code }))
            .then(() => setIsLoading(false));
          break;
        case PARTNER_PLAN_A_SIGN_UP:
        case PARTNER_PLAN_B_SIGN_UP:
          dispatch(isValidPartner({ code }))
            .then(() => setIsLoading(false));
          break;
        default:
          break;
      }
    } else {
      setIsLoading(false)
    }
  }, [dispatch, route, history, isValidRegistrationUrl])

  const content = (
    <>
      <Header />
      <Divider />
      <Paper className={classes.form} elevation={0}>
        {
          isLoading
            ? 
              <Box width={'466px'} height={'436px'}>
                <LoadingComponent />
              </Box>
            : <SignUp />

        }
      </Paper>
      <Divider />
      <Box className={classes.footer}>
        <Footer activeStep={0} type={type} />
      </Box>
    </>
  );

  return (
    <SignUpLayout isMobile={isMobile}>
      <Box className={classes.root}>
        <Paper className={classes.paper} elevation={0}>
          {isMobile ? (
            <Box>{content}</Box>
          ) : (
            <Grid container>
              <Grid item sm={7} md={7} style={{ width: "100%" }}>
                {content}
              </Grid>
              <Grid item sm={5} md={5} style={{ width: "100%" }}>
                <Box className={classes.background}>
                  <img
                    src={BackgroundSignUp}
                    alt="sign up background"
                    height="100%"
                    width="100%"
                  />
                </Box>
              </Grid>
            </Grid>
          )}
        </Paper>
      </Box>
    </SignUpLayout>
  );
}
