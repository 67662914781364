import React from "react";
import {
  makeStyles,
  Box,
  Typography,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
} from "@material-ui/core";
import { StyledTableCell, StyledTableRow } from "components/table";
import { getLang } from "app/feature/constants";
import StickyLeftTableCell from "components/table/stickyLeftTableCell";
import RefreshTable from "components/table/refreshTable";
import { SkeletonTableRow } from "components/skeleton/skeleton.component";
import { Delete as DeleteIcon } from "@material-ui/icons";
import {
  CheckRounded as CheckRoundedIcon,
  CloseRounded as CloseRoundedIcon,
} from "@material-ui/icons";

const useStyle = makeStyles((theme) => ({
  table: {
    tableLayout: "auto",
    // whiteSpace: "nowrap",
    borderCollapse: "separate",
    width: "100%",
    border: "1px solid #D0D5DD",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
    },
    "& th": {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightBold,
      backgroundColor: "#F2F4F7",
      padding: theme.spacing(1, 2, 1, 2),
      borderRight: "none",
    },
    "& td": {
      backgroundColor: "#FFFFFF",
      borderRight: "none",
      padding: theme.spacing(1, 2, 1, 2),
    },
    "& th th": {
      borderBottom: "none !important",
    },
    "& td td": {
      borderBottom: "none !important",
    },
  },
  tableContainer: {
    maxHeight: 500,
    overflowY: "auto",
  },
  emptyContainer: {
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #D0D5DD",
    textAlign: "center",
  },
  fixedCellContent: {
    minWidth: 130,
    whiteSpace: "normal",
  },
  fixedCellNameContent: {
    minWidth: 200,
  },
  checkbox: {
    padding: "0px !important",
  },
  deleteIcon: {
    color: "#F04438",
    cursor: "pointer",
  },
  customCheckbox: {
    height: 18,
    width: 18,
    borderRadius: 4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& svg": {
      color: "white",
      fontSize: 18,
    },
  },
}));

export default function GroupBranchRuleTableComponent({
  rows,
  isEditable,
  lang,
  updateRules,
  isFetching,
  isError,
  handleReload,
  type,
  removeRule,
}) {
  const classes = useStyle();

  return (
    <>
      {!isFetching && isError && <RefreshTable handleReload={handleReload} />}
      {isFetching || rows.length > 0 ? (
        <>
          <TableContainer>
            <Table
              className={classes.table}
              aria-label="product table"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  <StickyLeftTableCell>
                    <StyledTableCell className={classes.fixedCellNameContent}>
                      {getLang(
                        lang,
                        type === "group"
                          ? "label.GROUP_NAME"
                          : "label.BRANCH_NAME"
                      )}
                    </StyledTableCell>
                  </StickyLeftTableCell>
                  <StyledTableCell
                    align="center"
                    className={classes.fixedCellContent}
                  >
                    {getLang(lang, "label.TRANSFER_TO")}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    className={classes.fixedCellContent}
                  >
                    {getLang(lang, "label.TRANSFER_REQUIRES_APPROVAL")}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    className={classes.fixedCellContent}
                  >
                    {getLang(lang, "label.SEND_REQUEST_TO")}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    className={classes.fixedCellContent}
                  >
                    {getLang(lang, "label.REQUEST_REQUIRES_APPROVAL")}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    className={classes.fixedCellContent}
                  >
                    {getLang(lang, "label.REQUETS_ON_BEHALF_OF")}
                  </StyledTableCell>
                  {isEditable && (
                    <StyledTableCell
                      align="center"
                      width={40}
                    ></StyledTableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {isFetching
                  ? [...new Array(5)].map((v, index) => (
                      <SkeletonTableRow
                        key={index}
                        columnCount={isEditable ? 7 : 6}
                      />
                    ))
                  : rows.map((row, index) => {
                      return (
                        <StyledTableRow
                          key={index}
                          hover
                          role="checkbox"
                          tabIndex={-1}
                        >
                          <StickyLeftTableCell>
                            <StyledTableCell
                              className={classes.fixedCellNameContent}
                            >
                              <span>{row.name}</span>
                            </StyledTableCell>
                          </StickyLeftTableCell>
                          {[
                            "is_transfer_to",
                            "is_transfer_requires_approval",
                            "is_send_request_to",
                            "is_request_requires_approval",
                            "is_request_on_behalf",
                          ].map((ruleName, rIndex) => (
                            <StyledTableCell
                              key={rIndex}
                              align="center"
                              className={classes.fixedCellContent}
                            >
                              {isEditable ? (
                                <Checkbox
                                  style={{ width: "20px", padding: 0 }}
                                  checked={!!row[ruleName]}
                                  onChange={(e) => {
                                    if (isEditable) {
                                      updateRules(
                                        row,
                                        ruleName,
                                        e.target.checked
                                      );
                                    }
                                  }}
                                />
                              ) : (
                                <Box display="flex" justifyContent="center">
                                  <Box
                                    className={classes.customCheckbox}
                                    style={{
                                      backgroundColor: row[ruleName]
                                        ? "#4AA94A"
                                        : "#F97066",
                                    }}
                                  >
                                    {row[ruleName] ? (
                                      <CheckRoundedIcon />
                                    ) : (
                                      <CloseRoundedIcon />
                                    )}
                                  </Box>
                                </Box>
                              )}
                            </StyledTableCell>
                          ))}
                          {isEditable && (
                            <StyledTableCell align="center" width={40}>
                              {type === "branch" && (
                                <Box
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                >
                                  <DeleteIcon
                                    size={18}
                                    className={classes.deleteIcon}
                                    onClick={() => {
                                      removeRule(row);
                                    }}
                                  />
                                </Box>
                              )}
                            </StyledTableCell>
                          )}
                        </StyledTableRow>
                      );
                    })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : null}
      {!isFetching && !isError && rows.length === 0 && (
        <>
          <TableContainer>
            <Table
              className={classes.table}
              aria-label="product table"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  <StickyLeftTableCell>
                    <StyledTableCell className={classes.fixedCellNameContent}>
                      {getLang(lang, "label.GROUP_NAME")}
                    </StyledTableCell>
                  </StickyLeftTableCell>
                  <StyledTableCell
                    align="center"
                    className={classes.fixedCellContent}
                  >
                    {getLang(lang, "label.TRANSFER_TO")}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    className={classes.fixedCellContent}
                  >
                    {getLang(lang, "label.TRANSFER_REQUIRES_APPROVAL")}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    className={classes.fixedCellContent}
                  >
                    {getLang(lang, "label.SEND_REQUEST_TO")}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    className={classes.fixedCellContent}
                  >
                    {getLang(lang, "label.REQUEST_REQUIRES_APPROVAL")}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    className={classes.fixedCellContent}
                  >
                    {getLang(lang, "label.REQUETS_ON_BEHALF_OF")}
                  </StyledTableCell>
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>
          <Box py={4} className={classes.emptyContainer}>
            <Typography variant="body1">
              {getLang(
                lang,
                type === "branch"
                  ? "paragraph.NO_BRANCH_CUSTOM_TRANSFER_RULE_SET"
                  : "paragraph.NO_GROUP_TRANSFER_RULE_SET"
              )}
            </Typography>
          </Box>
        </>
      )}
    </>
  );
}
