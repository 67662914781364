import React from "react";
import { Box, makeStyles, Typography, ButtonBase } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import AddIcon from "@material-ui/icons/Add";
import PropTypes from "prop-types";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles(theme => ({
  root: {
    width: "100%",
    height: 340,
    padding: theme.spacing(11, 4, 4, 4),
    boxSizing: "border-box",
    backgroundColor: "#F4F6F8",
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(11, 5, 5)
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(11, 2, 2)
    },
    [theme.breakpoints.down("xs")]: {
      height: 300
    }
  },
  card: {
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    border: "dashed 2px #9CA6A9",
    width: "100%",
    height: 140,
    minWidth: 190,
    maxWidth: 210,
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    boxSizing: "border-box",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.15)",
    backgroundColor: theme.palette.common.white,
    position: "relative"
  },
  addButton: {
    zIndex: 1,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.main,
    position: "absolute",
    height: 49,
    width: 49,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "50%"
  }
}));

function AddCardButtonComponent({ handleClick, lang }) {
  const classes = useStyle();

  return (
    <Box className={classes.root}>
      <Box className={classes.card}>
        <ButtonBase className={classes.addButton} onClick={handleClick}>
          <AddIcon fontSize="large" />
        </ButtonBase>
        <Skeleton animation={false} variant="text" width={54} />
        <Skeleton animation={false} variant="text" width={147} />
        <Skeleton animation={false} variant="text" width={54} />
        <Skeleton animation={false} variant="text" width={37} />
      </Box>
      <Box p={1.5} display="flex" justifyContent="center" alignItems="center">
        <Typography variant="body1" color="textSecondary">
          {getLang(lang, 'label.ADD_NEW_CARD')}
        </Typography>
      </Box>
    </Box>
  );
}

AddCardButtonComponent.propTypes = {
  handleClick: PropTypes.func.isRequired
};

export default AddCardButtonComponent;
