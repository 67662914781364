import { Avatar, Box, Grid, TableCell, TableRow, Typography } from "@material-ui/core";
import { UnknownUserIcon } from "modules/reporting/assets/svg";
import { fontTextStyle, iconStyle, tableStyle } from "modules/reporting/styles";
import { drilldownPageSelection, responseHandler } from "modules/reporting/utils/helper";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { TrendingNegativeCard } from "../../card";
import { ContentCard } from "../../layout";
import { TrendingCardLoading } from "../../skeleton/loading";
import { DownloadableTable } from "../../table";
import "moment/min/locales.min";

export default function SuspendedProductPanel({
    status,
    data,
    sort,
    pagination,
    columns,
    currentFilter,
    pageType,
    translate,
    handleExportExcel
}) {
    const tableClasses = tableStyle();
    const fontClasses = fontTextStyle();
    const iconClasses = iconStyle();
    const renderData = (data) => {

        let rows = [];
        if(Object.keys(data).length === 0 || !data.isSuccess){
            return rows;
        }

        let imageProps = JSON.stringify(data);
        imageProps = JSON.parse(imageProps);

        let no_iteration = pagination.rowsPerPage * pagination.page + 1;
        imageProps.rows.forEach((item, index) => {
            let row = (
                <TableRow>
                    <TableCell className={tableClasses.table__cell} style={{width:"20px"}}>
                        <Typography className={fontClasses.text__table_cell}>
                            {no_iteration++}
                        </Typography>
                    </TableCell>
                    <TableCell className={tableClasses.table__cell} style={{minWidth:"185px", width:"185px"}}>
                        <Link 
                            target="_blank" rel="noopener noreferrer"
                            className={fontClasses.text__hyperlink}
                            to={() => {

                                let filterProperties = {};

                                if(item.enc)
                                    filterProperties.enc = item.enc;

                                let url = drilldownPageSelection(filterProperties, pageType, true);
                                return url;
                            }}
                        >
                            {item.serial_number ?? translate("label.UNKNOWN")}
                        </Link>
                    </TableCell>
                    <TableCell
                            className={tableClasses.table__cell}
                            align="left"
                            style={{
                                width: "300px"
                            }}
                        >
                            <Box className={tableClasses.cell__product}>
                                {
                                    item.product_image ?
                                    <Avatar variant="square" alt={item.name} src={item.product_image} className={iconClasses.svg__icon}/>
                                    :
                                    <Avatar variant="square" alt={item.name} src={UnknownUserIcon} className={iconClasses.svg__icon}/>
                                }
                                
                                <Link
                                    target="_blank" rel="noopener noreferrer" 
                                    className={fontClasses.text__hyperlink}
                                    to={() => {

                                        let filterProperties = {};

                                        if(currentFilter.countryCode)
                                            filterProperties.countryCode = currentFilter.countryCode;
                                        
                                        if(currentFilter.cityUuid)
                                            filterProperties.city = currentFilter.cityUuid;
                                        
                                        if(currentFilter.branchUuid)
                                            filterProperties.branchUuid = currentFilter.branchUuid;

                                        if(currentFilter.period)
                                            filterProperties.period = currentFilter.period;

                                        if(currentFilter.dateRange){
                                            filterProperties.start = moment(currentFilter.dateRange[0]).format('YYYY-MM-DD');
                                            filterProperties.end = moment(currentFilter.dateRange[1]).format('YYYY-MM-DD');
                                        }

                                        if(item.product_uuid)
                                            filterProperties.productUuid = item.product_uuid;

                                        let url = drilldownPageSelection(filterProperties, pageType, true);
                                        return url;
                                    }}
                                >
                                    {item.product_name}
                                </Link>
                            </Box>
                        </TableCell>
                    <TableCell className={tableClasses.table__cell} align="left" style={{width:"170px"}}>
                        <Typography className={fontClasses.text__table_cell}>
                            {item.scan_count && item.scan_limit ? `${item.scan_count}/${item.scan_limit === "label.NO_LIMIT" ? translate("label.NO_LIMIT") : item.scan_limit}` : "-"}
                        </Typography>
                    </TableCell>
                    <TableCell className={tableClasses.table__cell} align="right" style={{width:"140px"}}>
                        <Typography className={fontClasses.text__table_cell}>
                            {item.report_received}
                        </Typography>
                    </TableCell>
                    <TableCell className={tableClasses.table__cell} style={{width:"130px"}}>
                        <Typography className={fontClasses.text__table_cell}>
                            {moment(item.last_scan_date).format('lll')}
                        </Typography>
                    </TableCell>
                </TableRow>
            )

            
            item.render = row;
        })
        
        return imageProps;
    }

    return (
        <ContentCard>
            {/* trending card --- START */}
            <Grid container spacing={3} alignItems="center">
                <Grid item xs={12} sm={12} md={6} >
                    {
                        responseHandler(
                            data.totalSuspendedTrendingData,
                            status.isFetchingTotalSuspendedTrending,
                            <TrendingNegativeCard translate={translate} title={translate("label.SUSPENDED_QR")} data={data.totalSuspendedTrendingData} />,
                            <TrendingCardLoading/>,
                            null,
                            null,
                            null
                        )
                    }
                </Grid>
                <Grid item xs={12} sm={12} md={6} >
                    {
                        responseHandler(
                            data.totalReportedTrendingData,
                            status.isFetchingTotalReportedTrending,
                            <TrendingNegativeCard translate={translate} title={translate("label.REPORT_RECEIVED")} data={data.totalReportedTrendingData} />,
                            <TrendingCardLoading/>,
                            null,
                            null,
                            null
                        )
                    }
                </Grid>
            </Grid>
            {/* trending card --- END */}

            {/* chart suspended scan --- START */}
            {/* <Box
                display="flex"
                flexDirection="column"
                marginTop="24px"
                marginBottom="24px"
            >
                {
                    responseHandler(
                        data.suspendedScanQRData,
                        status.isFetchingSuspendedScanQR,
                        (
                            <DownloadChartCard 
                                chartColors={['#F97066']} 
                                chartHeight="300px" 
                                type="line" 
                                title="Suspended Scan" 
                                payload={data.suspendedScanQRData} 
                                canSwitchChartType={true} 
                            />
                        ),
                        (
                            <ChartCardLoading />
                        ),
                        (
                            <EmptyChart
                                data={['Suspended']}
                                title="Customer Data Not Found"
                                value="You don't have any customer data at the moment."
                                labels= {['10 July', '11 July', '12 July', '13 July']}
                            />
                        ),
                        (
                            <EmptyChart
                                data={['65+', '55-64', '45-54', '35-44', '25-34', '18-24', '17-', 'Unknown']}
                                title="Customer Data Not Found"
                                value="You don't have any customer data in this period."
                                labels= {['10 July', '11 July', '12 July', '13 July']}
                            />
                        ),
                        (
                            <EmptyChart
                                data={['65+', '55-64', '45-54', '35-44', '25-34', '18-24', '17-', 'Unknown']}
                                title="Loading Failed"
                                value="We're sorry but we can't load the data at the moment."
                                labels= {['10 July', '11 July', '12 July', '13 July']}
                            />
                        )
                    )
                }
            </Box> */}
            {/* chart suspended scan --- END */}

            {/* downloadable table listing suspended --- START */}
            <DownloadableTable 
                title={translate("label.SUSPENDED_LIST")}
                sort={sort}
                pagination={pagination}
                columns={columns}
                status={status.isFetchingSuspendedList}
                data={renderData(data.suspendedListData)}
                translate={translate}
                handleExportExcel={handleExportExcel}
                exportExcel={true}
            />
            {/* downloadable table listing suspended --- END */}
        </ContentCard>
    )
}