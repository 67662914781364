import React from "react";
import {
  Box,
  // Button,
  makeStyles,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Checkbox,
  TableSortLabel,
  Link,
} from "@material-ui/core";
import {
  StyledTableCell,
  StyledTableRow,
  StickyRightTableCell,
  StickyLeftTableCell,
} from "components/table";
import { SkeletonTableRowStickyLeftRight } from "components/skeleton/skeleton.component";
import { ActionButton } from "components/button";
import { TablePaginationActions } from "components/tablePagination";
import RefreshTable from "components/table/refreshTable";
import SelectAllCheckBox from "components/selectAllCheckbox";
import clsx from "clsx";
import DefaultImg from "assets/img/img-default.png";
import DefaultProfile from "assets/img/defaultPhoto.png";
import { ROOT } from "modules/stockflow/config/stockflow.route";
import moment from "moment";
import "moment/min/locales.min";
import { KeyboardArrowDown } from "@material-ui/icons";
import { TIER_ICON_PROPS } from "modules/stockflow/constants";
import { getLang } from "app/feature/constants";

// const API_HOST = process.env.REACT_APP_API_HOST_URL

const useStyle = makeStyles((theme) => ({
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    borderCollapse: "separate",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
    },
    "& th,td": {
      fontSize: ".75rem",
      borderRight: "1px solid rgba(58, 77, 84, 0.1)",
      padding: theme.spacing(1, 2, 1, 2),
    },
    "& th": {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightBold,
    },
    "& th:last-child, td:last-child": {
      width: 37,
      borderRight: "0px",
    },
    "& th th": {
      borderBottom: "none !important",
    },
    "& td td": {
      borderBottom: "none !important",
    },
  },
  tableContainer: {
    maxHeight: 500,
    overflowY: "auto",
  },
  emptyContainer: {
    backgroundColor: theme.palette.background.paper,
    textAlign: "center",
  },
  button: {
    display: "flex",
    alignItems: "center",
    padding: 0,
    minWidth: 0,
    marginLeft: theme.spacing(1),
  },
  productPhoto: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #E4E7EC",
    width: "30px",
    height: "30px",
    marginRight: "10px",
    "& img": {
      height: "30px",
      width: "30px",
    },
  },
  actionContainer: {
    justifyContent: "center",
  },
  paperRoot: {
    width: "300px",
  },
  selectAll: {
    "&.Mui-checked": {
      color: "#FDB022",
    },
  },
  selectOne: {
    "&.Mui-checked": {
      color: "#6AAF68",
    },
  },
  activeText: {
    color: "#32D583",
  },
  suspendText: {
    color: "#F97066",
  },
  missingText: {
    color: "#FDB022",
  },
  disabledText: {
    color: "#98A2B3",
  },
  profile: {
    height: 26,
    width: 26,
    minWidth: 26,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& img": {
      height: "100%",
      minHeight: "100%",
      minWidth: "100%",
      objectFit: "cover",
      borderRadius: "50%",
      border: "1px solid #D0D5DD",
    },
  },
  tierIcon: {
    borderRadius: "3px",
    paddingLeft: "0.15em",
    paddingRight: "0.15em",
    display: "flex",
    alignItems: "center",
    position: "absolute",
    top: "-3px",
    left: "-8px",
  },
  tierLabel: {
    color: "#FFFFFF",
    fontSize: "0.6rem",
  },
  attributeText: {
    color: "#b2b2b2",
    fontSize: 12,
    whiteSpace: "break-spaces",
  },
}));

export default function PackageListTableComponent({
  hasStockflowInventoryViewAccessRight,
  hasStockflowDealerViewAccessRight,
  rows,
  isFetching,
  isSelectAll,
  selectedIds,
  language,
  isError,
  handleReload,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowPerPage,
  isSearchResult,
  totalFiltered,
  handleSelectAvailable,
  handleSelectAll,
  handleDeselectAll,
  handleSelectPackage,
  token,
  paginationOptions,
  tableFilter,
  changeOrder,
  lang,
  isDealerPage,
  dealerId,
  handleViewDetail,
}) {
  const classes = useStyle();

  if (language === "ZH") {
    moment.locale("zh-cn");
  } else if (language === "TL") {
    moment.locale("tl-ph");
  } else {
    moment.locale(language);
  }

  const selectAllItems = [
    {
      label: getLang(lang, "paragraph.SELECT_AVAILABLE_ITEM", {
        count: rows.length > rowsPerPage ? rowsPerPage : rows.length,
      }),
      action: () => handleSelectAvailable(),
    },
    {
      label: getLang(lang, "paragraph.SELECT_ALL_PACKAGES"),
      action: () => handleSelectAll(),
    },
    {
      label: getLang(lang, "paragraph.DESELECT_ALL_PACKAGES"),
      action: () => handleDeselectAll(),
      divider: true,
    },
  ];

  return (
    <>
      {!isFetching && isError && <RefreshTable handleReload={handleReload} />}
      {isFetching || rows.length > 0 ? (
        <Box>
          <TableContainer>
            <Table
              className={classes.table}
              aria-label="package table"
              size="small"
              stickyHeader
            >
              <TableHead style={{ height: "45px" }}>
                <TableRow>
                  {!isDealerPage && (
                    <StickyLeftTableCell>
                      <StyledTableCell padding="checkbox">
                        <SelectAllCheckBox
                          actionItems={selectAllItems}
                          disabled={isFetching}
                          isSelectAll={isSelectAll}
                          selectedIdLength={selectedIds.length}
                          totalFiltered={totalFiltered}
                        />
                      </StyledTableCell>
                    </StickyLeftTableCell>
                  )}
                  <StyledTableCell>
                    <TableSortLabel
                      active={tableFilter.orderBy === "sequence_number"}
                      direction={tableFilter.order}
                      onClick={() => {
                        const differentTab =
                          tableFilter.orderBy !== "sequence_number";
                        const order = differentTab
                          ? tableFilter.order
                          : tableFilter.order === "asc"
                          ? "desc"
                          : "asc";
                        changeOrder("sequence_number", order);
                      }}
                      IconComponent={KeyboardArrowDown}
                    >
                      {getLang(lang, "label.SEQUENCE_NUMBER")}
                    </TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.SERIAL_NUMBER")}
                  </StyledTableCell>
                  <StyledTableCell style={{ minWidth: "20rem" }}>
                    {getLang(lang, "label.PRODUCT_NAME")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.LOOSE_ITEMS")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.BRANCH_ASSIGNED")}
                  </StyledTableCell>
                  {!isDealerPage && (
                    <StyledTableCell>
                      {getLang(lang, "label.LOCATED_AT")}
                    </StyledTableCell>
                  )}
                  <StyledTableCell>
                    {getLang(lang, "label.STATUS")}
                  </StyledTableCell>
                  <StyledTableCell>
                    <TableSortLabel
                      active={tableFilter.orderBy === "created_at"}
                      direction={tableFilter.order}
                      onClick={() => {
                        const differentTab =
                          tableFilter.orderBy !== "created_at";
                        const order = differentTab
                          ? tableFilter.order
                          : tableFilter.order === "asc"
                          ? "desc"
                          : "asc";
                        changeOrder("created_at", order);
                      }}
                      IconComponent={KeyboardArrowDown}
                    >
                      {getLang(
                        lang,
                        isDealerPage
                          ? "label.RECEIVED_DATE"
                          : "label.CREATED_DATE"
                      )}
                    </TableSortLabel>
                  </StyledTableCell>
                  {!isDealerPage && (
                    <>
                      <StyledTableCell>
                        <TableSortLabel
                          active={tableFilter.orderBy === "updated_at"}
                          direction={tableFilter.order}
                          onClick={() => {
                            const differentTab =
                              tableFilter.orderBy !== "updated_at";
                            const order = differentTab
                              ? tableFilter.order
                              : tableFilter.order === "asc"
                              ? "desc"
                              : "asc";
                            changeOrder("updated_at", order);
                          }}
                          IconComponent={KeyboardArrowDown}
                        >
                          {getLang(lang, "label.LAST_UPDATED_DATE")}
                        </TableSortLabel>
                      </StyledTableCell>
                      <StickyRightTableCell>
                        <StyledTableCell
                          style={{ width: "67px", textAlign: "center" }}
                        >
                          {getLang(lang, "label.ACTION")}
                        </StyledTableCell>
                      </StickyRightTableCell>
                    </>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {isFetching
                  ? [...new Array(5)].map((v, index) => (
                      <SkeletonTableRowStickyLeftRight
                        key={index}
                        columnCount={isDealerPage ? 7 : 11}
                      />
                    ))
                  : rows.map((row, index) => {
                      const actionItems = [];

                      actionItems.push({
                        label: getLang(lang, "label.VIEW_DETAIL"),
                        action: () => {
                          let url = `${ROOT}/inventory/package/${row.id}`;

                          if (isDealerPage && dealerId) {
                            url += `?dealer=${dealerId}`;
                          }
                          handleViewDetail(url);
                        },
                      });
                      // actionItems.push({
                      //   label: "Export XLS",
                      //   action: () => {
                      //     window.open(`${API_HOST}/distributor/admin/export/v1/inventory/product?isSelectAll=${false}&selectedIds=${row.id}&token=${token}`, "_blank")
                      //   },
                      // })

                      return (
                        <StyledTableRow
                          key={row.id}
                          hover
                          role="checkbox"
                          tabIndex={-1}
                        >
                          {!isDealerPage && (
                            <StickyLeftTableCell>
                              <StyledTableCell padding="checkbox">
                                <Checkbox
                                  className={clsx({
                                    [classes.selectAll]: isSelectAll,
                                    [classes.selectOne]:
                                      row.isSelected && isSelectAll === false,
                                  })}
                                  checked={row.isSelected}
                                  onChange={(event) =>
                                    handleSelectPackage(
                                      index,
                                      event.target.checked
                                    )
                                  }
                                />
                              </StyledTableCell>
                            </StickyLeftTableCell>
                          )}
                          <StyledTableCell align="right">
                            <span>{row.sequence_no || "-"}</span>
                          </StyledTableCell>
                          <StyledTableCell>
                            {row.serial_number ? (
                              hasStockflowInventoryViewAccessRight ? (
                                <Link
                                  href={
                                    `${ROOT}/inventory/package/${row.id}` +
                                    (isDealerPage && dealerId ? `?dealer=${dealerId}` : '')
                                  }
                                  color="secondary"
                                  variant="caption"
                                  onClick={(e) => {
                                    let url = `${ROOT}/inventory/package/${row.id}`;
                                    if (isDealerPage && dealerId) {
                                      url += `?dealer=${dealerId}`;
                                    }
                                    handleViewDetail(url);
                                    e.preventDefault();
                                    return false;
                                  }}
                                >
                                  {row.serial_number}
                                </Link>
                              ) : (
                                row.serial_number
                              )
                            ) : (
                              "-"
                            )}
                          </StyledTableCell>
                          <StyledTableCell>
                            <Box display="flex" alignItems="center">
                              <Box className={classes.productPhoto}>
                                {row.picture === "" ? (
                                  <img src={DefaultImg} alt="emptyProduct" />
                                ) : (
                                  <img src={row.picture} alt={row.name} />
                                )}
                              </Box>
                              <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                alignItems="flex-start"
                              >
                                <Typography
                                  variant="body2"
                                  style={{ fontSize: 12 }}
                                >
                                  {row.name}
                                </Typography>
                                {!!row.attributes &&
                                  !!row.attributes.length && (
                                    <Typography
                                      className={classes.attributeText}
                                      variant="body2"
                                    >
                                      {row.attributes.join(", ")}
                                    </Typography>
                                  )}
                              </Box>
                            </Box>
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            <span>
                              {(row.quantity || 0).toLocaleString("en-US")}
                            </span>
                          </StyledTableCell>
                          <StyledTableCell>
                            <span>{row.nodeName}</span>
                          </StyledTableCell>
                          {!isDealerPage && (
                            <StyledTableCell>
                              {row.located_at ? (
                                <Box display="flex" alignItems="center">
                                  <Box
                                    className={classes.profile}
                                    mr={1}
                                    ml={0.5}
                                    position="relative"
                                  >
                                    {row.located_at.picture === "" ? (
                                      <img
                                        src={
                                          row.located_at.type === "branch"
                                            ? DefaultImg
                                            : DefaultProfile
                                        }
                                        alt="default profile"
                                      />
                                    ) : (
                                      <img
                                        src={row.located_at.picture}
                                        alt={row.name}
                                      />
                                    )}
                                    {!!row.located_at.tier && (
                                      <Box
                                        className={classes.tierIcon}
                                        style={{
                                          backgroundColor: TIER_ICON_PROPS.find(
                                            ({ tier }) =>
                                              tier === row.located_at.tier
                                          ).color,
                                        }}
                                      >
                                        <Typography
                                          className={classes.tierLabel}
                                        >
                                          T{row.located_at.tier}
                                        </Typography>
                                      </Box>
                                    )}
                                  </Box>
                                  {row.located_at.type === "branch" ||
                                  !hasStockflowDealerViewAccessRight ? (
                                    <Typography variant="caption">
                                      {row.located_at.name}
                                    </Typography>
                                  ) : (
                                    <Link
                                      href={row.located_at.type === "dealer" ? `${ROOT}/my-dealer/${row.located_at.uuid}` : '#'}
                                      color="secondary"
                                      onClick={(e) => {
                                        if (row.located_at.type === "dealer") {
                                          handleViewDetail(
                                            `${ROOT}/my-dealer/${row.located_at.uuid}`
                                          );
                                        }
                                        e.preventDefault();
                                        return false;
                                      }}
                                    >
                                      <Typography variant="caption">
                                        {row.located_at.name}
                                      </Typography>
                                    </Link>
                                  )}
                                </Box>
                              ) : (
                                <span>-</span>
                              )}
                            </StyledTableCell>
                          )}
                          <StyledTableCell
                            className={clsx({
                              [classes.activeText]: row.status === "active",
                              [classes.suspendText]: row.status === "suspend",
                              [classes.missingText]:
                                row.status === "missing" ||
                                row.status === "faulty",
                              [classes.disabledText]:
                                row.status === "disabled" ||
                                row.status === "checked out" ||
                                row.status === "replaced",
                            })}
                          >
                            <span>
                              {row.status === "suspend"
                                ? getLang(lang, "label.SUSPENDED")
                                : row.status === "active"
                                ? getLang(lang, "label.ACTIVE")
                                : row.status === "disabled"
                                ? getLang(lang, "label.DISABLED")
                                : row.status === "replaced"
                                ? getLang(lang, "label.REPLACED")
                                : row.status === "missing"
                                ? getLang(lang, "label.MISSING")
                                : row.status === "faulty"
                                ? getLang(lang, "label.FAULTY")
                                : row.status === "checked out"
                                ? getLang(lang, "label.CHECKED_OUT")
                                : row.status === "pending receive" ||
                                  row.status === "pending approval" ||
                                  row.status === "in transit"
                                ? getLang(lang, "label.IN_TRANSIT")
                                : row.status}
                            </span>
                          </StyledTableCell>
                          <StyledTableCell>
                            <span>
                              {moment.utc(row.created_at).local().format("lll")}
                            </span>
                          </StyledTableCell>
                          {!isDealerPage && (
                            <>
                              <StyledTableCell>
                                <span>
                                  {moment
                                    .utc(row.updated_at)
                                    .local()
                                    .format("lll")}
                                </span>
                              </StyledTableCell>
                              <StickyRightTableCell>
                                <StyledTableCell style={{ width: "67px" }}>
                                  <ActionButton
                                    actionItems={actionItems}
                                    popperStyle={{ zIndex: 999 }}
                                  />
                                </StyledTableCell>
                              </StickyRightTableCell>
                            </>
                          )}
                        </StyledTableRow>
                      );
                    })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            style={{
              backgroundColor: "#fff",
              boxShadow: "0px -1px 0px rgba(58, 77, 84, 0.15)",
            }}
            ActionsComponent={TablePaginationActions}
            rowsPerPageOptions={paginationOptions}
            component="div"
            count={totalFiltered}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowPerPage}
            labelRowsPerPage={getLang(lang, 'label.ROWS_PER_PAGE')}
            labelDisplayedRows={({ from, to, count }) => `${from.toLocaleString("en-US")}-${to.toLocaleString("en-US")} ${getLang(lang, 'label.OF')} ${count.toLocaleString("en-US")}`}
          />
        </Box>
      ) : null}
      {!isFetching && !isError && rows.length === 0 && (
        <Box>
          <TableContainer>
            <Table
              className={classes.table}
              aria-label="package table"
              size="small"
              stickyHeader
            >
              <TableHead style={{ height: "45px" }}>
                <TableRow>
                  {!isDealerPage && (
                    <StickyLeftTableCell style={{ textAlign: "center" }}>
                      <StyledTableCell padding="checkbox">
                        <SelectAllCheckBox disabled={true} />
                      </StyledTableCell>
                    </StickyLeftTableCell>
                  )}
                  <StyledTableCell>
                    {getLang(lang, "label.SEQUENCE_NUMBER")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.SERIAL_NUMBER")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.PRODUCT_NAME")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.LOOSE_ITEMS")}
                  </StyledTableCell>
                  {!isDealerPage && (
                    <StyledTableCell>
                      {getLang(lang, "label.LOCATED_AT")}
                    </StyledTableCell>
                  )}
                  <StyledTableCell>
                    {getLang(lang, "label.STATUS")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.CREATED_DATE")}
                  </StyledTableCell>
                  {!isDealerPage && (
                    <>
                      <StyledTableCell>
                        {getLang(lang, "label.LAST_UPDATED_DATE")}
                      </StyledTableCell>
                      <StickyRightTableCell>
                        <StyledTableCell>
                          {getLang(lang, "label.ACTION")}
                        </StyledTableCell>
                      </StickyRightTableCell>
                    </>
                  )}
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>
          <Box py={9} className={classes.emptyContainer}>
            <Typography
              variant="body1"
              style={{ paddingBottom: "16px", fontWeight: "bold" }}
            >
              {isSearchResult || isDealerPage
                ? getLang(lang, "paragraph.NO_PACKAGE_EXISTS")
                : getLang(lang, "paragraph.NO_PACKAGE_CREATED")}
            </Typography>
            {!isSearchResult && !isDealerPage && (
              <>
                <Typography
                  variant="subtitle2"
                  style={{ paddingBottom: "16px" }}
                >
                  {getLang(lang, "paragraph.INVENTORY_PACKAGE_INSTRUCTION")}
                </Typography>
                {/* <Button
                  onClick={() => {
                    // TODO: add link
                  }}
                  variant="contained"
                  disableElevation
                  color="primary"
                >
                  {getLang(lang, "label.LEARN_HOW")}
                </Button> */}
              </>
            )}
          </Box>
        </Box>
      )}
    </>
  );
}
