import React, { useRef } from "react";
import {
  makeStyles,
  Typography,
  Popover,
  Box,
  Divider,
  InputAdornment,
  Button,
  MenuItem,
  MenuList,
} from "@material-ui/core";
import { 
  Search as SearchIcon,
} from "@material-ui/icons";
import { Skeleton } from '@material-ui/lab';
import { AddIcon } from "components/icon";
import InputTextField from 'components/input/inputTextField';
import InfiniteScroll from "react-infinite-scroll-component";
import LoadingComponent from 'components/loading/loading.component';
import { getLang } from "app/feature/constants";


const useStyle = makeStyles((theme) => ({
  button: {
    padding: 0,
    minWidth: 0
  },
  perfectScrollBar: {
    maxHeight: 200,
    overflow: "auto"
  },
  list: {
    padding: 0,
  }
}));

function BulkCreateCategorySelectPanelComponent({
  open,
  anchorEl,
  handleClose,
  id,
  style,
  searchable,
  handleSearch,
  placeholder,
  items,
  isFetching,
  emptyMessage,
  handleListKeyDown,
  anchorOrigin,
  transformOrigin,
  PaperProps,
  type,
  handleFetchMoreItems,
  totalFilter,
  selected,
  handleAddCategory,
  lang
}) {
  const classes = useStyle();
  const textRef = useRef();

  const displayMenuItem = () => {
    return (
      <>
        {isFetching ? (
          <>
            <Skeleton variant='text' animation="wave" height={25} />
            <Skeleton variant='text' animation="wave" height={25} />
            <Skeleton variant='text' animation="wave" height={25} />
            <Skeleton variant='text' animation="wave" height={25} />
          </>
        ) : items.length > 0 ? items.map((item) => (
          <Box key={item?.name ? item.name : item}>
            <MenuList
              id='menu-list-grow'
              className={classes.list}
            >
              <MenuItem
                selected={selected(item)}
                onClick={() => handleListKeyDown(item)}
              >
                <Typography variant='body1' noWrap style={{ margin: "4px 0px" }}>
                  {item?.name ? item.name : item || '-'}
                </Typography>
              </MenuItem>
            </MenuList>
          </Box>
        )) : (
          <Box py={9} style={{ textAlign: "center" }}>
            <Typography variant='body1'>{emptyMessage}</Typography>
          </Box>
        )}
      </>
    )
  }

  return (
    <Popover
      id={id}
      open={open}
      style={style}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      elevation={2}
      PaperProps={PaperProps}
    >
      {(!!isFetching || (!isFetching && !!items.length)) && (
        <>
          {searchable && (
            <Box>
              <InputTextField
                variant="filled"
                size="small"
                fullWidth
                inputRef={textRef}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end' style={{ paddingRight: 6 }}>
                      <Button
                        onClick={() => handleSearch(textRef.current.value)}
                        className={classes.button}
                      >
                        <SearchIcon />
                      </Button>
                    </InputAdornment>
                  ),
                  style: {
                    backgroundColor: "#fff",
                    paddingRight: 0
                  }
                }}
                inputProps={{
                  onKeyDown: (e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      handleSearch(textRef.current.value)
                      return false
                    }
                  },
                  style: {
                    padding: 8
                  }
                }}
                placeholder={placeholder || getLang(lang, "placeholder.SEARCH")}
              />
            </Box>
          )}
          <Divider />
          {type === "InfiniteScroll" ? (
            <>
              <InfiniteScroll
                height={400}
                dataLength={items.length}
                next={handleFetchMoreItems}
                hasMore={totalFilter > items.length}
                loader={<LoadingComponent height={50} />}
              >
                {displayMenuItem()}
              </InfiniteScroll>
            </>
          ) : (
            <>
              <Box className={classes.perfectScrollBar}>
                {displayMenuItem()}
              </Box>
            </>
          )}
          <Divider />
        </>
      )}
      <Box className={classes.createLink} style={{ padding: '5px 10px' }}>
        <Button
          variant="text"
          color="primary"
          startIcon={
            <AddIcon color="secondary" style={{ fontSize: "13px" }} />
          }
          onClick={handleAddCategory}
          style={{
            marginRight: "8px"
          }}
        >
          {getLang(lang, "label.ADD_NEW")}
        </Button>
      </Box>
    </Popover>
  )
}

export default BulkCreateCategorySelectPanelComponent