import React from "react";
import {
  makeStyles,
  Box,
  Typography,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { getLang } from "app/feature/constants";
import { Formik } from "formik";
import Input from "components/input/inputTextField";
import CourierCompanySelect from "../../select/courierCompanySelect";
import CountrySelect from "components/select/countrySelect";

const useStyle = makeStyles((theme) => ({
  container: {
    boxSizing: "border-box",
    maxHeight: "85vh",
    backgroundColor: "#FFFFFF",
    padding: "4px",
  },
  field: {
    display: "grid",
    gridTemplateColumns: "3fr 7fr",
    columnGap: theme.spacing(2),
  },
  activeText: {
    color: "#32D583",
  },
  activeChipStatus: {
    backgroundColor: "#ECFDF3",
    width: "fit-content",
    textTransform: "capitalize",
    color: "#027A48",
    fontWeight: "bold",
  },
  suspendChipStatus: {
    backgroundColor: "#fdecec",
    width: "fit-content",
    textTransform: "capitalize",
    color: "red",
    fontWeight: "bold",
  },

  item: {
    position: "relative",
    display: "flex",
    listStyle: "none",
  },
  seperator: {
    minWidth: 52,
    display: "flex",
    flexDirection: "column",
    flex: 0,
    alignItems: "center",
    padding: "0px 6px",
  },
  dot: {
    minWidth: 12,
    minHeight: 12,
    boxShadow: "none",
    backgroundColor: "#A749F0",
    color: "#ffffff",
    padding: 2,
    borderRadius: "50%",
    textAlign: "center",
    margin: "5px 0px",
  },
  connector: {
    width: 1,
    backgroundColor: "transparent",
    flexGrow: 1,
    minHeight: "25px",
    borderLeft: "2px dotted #D0D5DD",
  },
  flow: {
    paddingInlineStart: "0px",
  },
}));

export default function DeliveryDetailInputPanelComponent({
  lang,
  updateDelivery,
  isUpdating,
  deliveryInfo,
  handleCancel,
  hasDeliveryEditAccessRight,
}) {
  const classes = useStyle();

  return (
    <Formik
      initialValues={{
        initalTracking: deliveryInfo ? deliveryInfo.tracking : "",
        tracking: deliveryInfo ? deliveryInfo.tracking : "",
        courier:
          deliveryInfo && deliveryInfo.courier_name
            ? {
                logo: deliveryInfo.courier_image,
                name: deliveryInfo.courier_name,
                code: deliveryInfo.courier_code,
              }
            : null,
        postalCode: "",
        country: "",
      }}
      onSubmit={updateDelivery}
    >
      {(formik) => (
        <form onSubmit={formik.handleSubmit}>
          {!deliveryInfo && (
            <>
              <Box>
                <Typography>
                  <b>{getLang(lang, "label.DELIVERY_INFO")}</b>
                </Typography>
              </Box>
              <Box>
                <Typography variant="caption" color="textSecondary">
                  {getLang(lang, "paragraph.DELIVERY_INSTRUCTION")}
                </Typography>
              </Box>
            </>
          )}

          <Box mt={2}>
            <Typography>{getLang(lang, "label.TRACKING_NO")}</Typography>
            <Input
              hiddenLabel
              variant="filled"
              size="small"
              placeholder={getLang(
                lang,
                "placeholder.PLEASE_ENTER_TRACKING_NO"
              )}
              margin="none"
              style={{ width: "100%", marginTop: 4 }}
              InputProps={{
                disableUnderline: true,
                margin: "none",
                style: {
                  borderRadius: "8px",
                  backgroundColor: "white",
                  border: "1px solid #D0D5DD",
                  minWidth: "50px",
                  height: "100%",
                },
                onBlur: () => {
                  if (formik.values.tracking !== formik.values.initalTracking) {
                    formik.setFieldValue(
                      "initalTracking",
                      formik.values.tracking
                    );
                    formik.setFieldValue("courier", null);
                  }
                },
                classes: { input: classes.inputRootID },
              }}
              disabled={!hasDeliveryEditAccessRight || isUpdating}
              {...formik.getFieldProps("tracking")}
            />
          </Box>
          <Box mt={2}>
            <Typography>{getLang(lang, "label.COURIER_COMPANY")}</Typography>
            <Box mt={0.5}>
              <CourierCompanySelect
                disabled={
                  !formik.values.initalTracking || !hasDeliveryEditAccessRight
                }
                handleChange={(courier) => {
                  formik.setFieldValue("courier", courier);
                }}
                value={formik.values.courier}
                placeholder={getLang(
                  lang,
                  "placeholder.PLEASE_SELECT_DELIVERY_COMPANY"
                )}
                tracking={formik.values.initalTracking}
              />
            </Box>
          </Box>
          {formik.values.courier?.is_postal_code_required && (
            <Box mt={2}>
              <Typography>{getLang(lang, "label.POSTAL_CODE")}</Typography>
              <Input
                hiddenLabel
                variant="filled"
                size="small"
                placeholder={getLang(lang, "placeholder.POSTAL_CODE")}
                margin="none"
                style={{ width: "100%", marginTop: 4 }}
                InputProps={{
                  disableUnderline: true,
                  margin: "none",
                  style: {
                    borderRadius: "8px",
                    backgroundColor: "white",
                    border: "1px solid #D0D5DD",
                    minWidth: "50px",
                    height: "100%",
                  },
                  classes: { input: classes.inputRootID },
                }}
                disabled={isUpdating || !hasDeliveryEditAccessRight}
                {...formik.getFieldProps("postalCode")}
              />
            </Box>
          )}
          {formik.values.courier?.is_country_required && (
            <Box mt={2}>
              <Typography>{getLang(lang, "label.COUNTRY")}</Typography>
              <CountrySelect
                textFieldProps={{
                  fullWidth: true,
                  variant: "filled",
                  size: "small",
                  margin: "dense",
                  inputPropStyle: {
                    backgroundColor: "white",
                    border: "1px solid #D0D5DD",
                    borderRadius: 8,
                  },
                }}
                formProps={{
                  ...formik.getFieldProps("country"),
                  value: formik.values.country,
                  onChange: (e, newValue) => {
                    formik.setFieldValue("country", newValue);
                  },
                  disabled: isUpdating || !hasDeliveryEditAccessRight,
                  disableClearable: true,
                }}
                type="countryOnly"
              />
            </Box>
          )}
          <Box mt={2} display="flex" justifyContent="end">
            {!!deliveryInfo && (
              <Box mr={2}>
                <Button
                  disabled={isUpdating}
                  variant="outlined"
                  disableElevation
                  color="primary"
                  size="small"
                  onClick={handleCancel}
                >
                  {getLang(lang, "label.CANCEL")}
                </Button>
              </Box>
            )}
            <Button
              disabled={isUpdating || !hasDeliveryEditAccessRight}
              variant="contained"
              disableElevation
              color="secondary"
              size="small"
              type="submit"
            >
              {isUpdating ? (
                <CircularProgress size={20} style={{ color: "white" }} />
              ) : (
                getLang(lang, "label.UPDATE")
              )}
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
}
