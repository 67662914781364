import React from "react";
import EmailVerifiedComponent from "./email-verified.page";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";


function EmailVerifiedContainer({ email }) {
    const history = useHistory();
    const lang = useSelector(state => state.constant.languages)

    const handleContinue = () => {
        history.push("/");
    };

    return (
        <EmailVerifiedComponent
            handleContinue={handleContinue}
            lang={lang}
        />
    );
}

const mapStateToProps = state => ({
    email: state.session.idTokenPayload.email,
});


export default connect(
    mapStateToProps,
    null
)(EmailVerifiedContainer);
