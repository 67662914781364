import React from "react";

import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return (
    <MuiAlert elevation={6} variant="filled" {...props}>
      {props.children}
    </MuiAlert>
  );
}

export default function SnackBarComponent({
  isOpen,
  handleClose,
  notification
}) {
  return (
    <Snackbar open={isOpen} onClose={handleClose} autoHideDuration={notification.severity === "success" ? 3500 : null}>
      <Alert onClose={handleClose} severity={notification.severity}>
        {notification.message}
      </Alert>
    </Snackbar>
  );
}
