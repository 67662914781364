import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import {
    DownArrowIcon,
    UpArrowIcon
} from "../../../assets/svg";

import {
    TrendingChart
} from "modules/reporting/components/charts";
import { numberFormatteer } from "modules/reporting/utils/helper";
import moment from "moment";
import "moment/min/locales.min";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
    trendingCard: {
        padding: "12px",
        backgroundColor: "#FFFFFF",
        borderRadius: "12px",
        border: "1px solid #D0D5DD"
    },
    trendingCard__totalCount: {
        fontSize: "20px",
        color: "#000000",
        fontWeight: "700",
        lineHeight: "30px",
        fontFamily: "Roboto",
    },
    trendingCard__trendingCountIncrement: {
        color: "#32D583",
        fontSize: "12px",
        fontWeight: "700",
        lineHeight: "30px",
        fontFamily: "Roboto",
    },
    trendingCard__trendingCountDecrement: {
        color: "#F97066",
        fontSize: "12px",
        fontWeight: "700",
        lineHeight: "30px",
        fontFamily: "Roboto",
    },
    trendingCard__trendingIndicatorIncrement: {
        color: "#FFFFFF",
        backgroundColor: "#32D583",
        display: "flex",
        alignItems: "center",
        gap: "4px",
        borderRadius: "8px",
        padding: "4px 8px",
    },
    trendingCard__trendingIndicatorDecrement: {
        color: "#FFFFFF",
        backgroundColor: "#F97066",
        display: "flex",
        alignItems: "center",
        gap: "4px",
        borderRadius: "8px",
        padding: "4px 8px",
    },
    trendingCard__trendingIndicatorEqual: {
        backgroundColor: "#98A2B3",
        color: "#FFFFFF",
        display: "flex",
        alignItems: "center",
        borderRadius: "8px",
        padding: "4px 8px",
    },
    trendingCard__title: {
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "20px",
        color: "#101828",
    },
    trendingCard_info: {
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "20px",
        color: "#7C8DB5"
    },
    global__flexGap3: {
        gap: "3px"
    }
}))

export default function TrendingPositiveCardComponent({
    data,
    title,
    translate
}){

    const language = useSelector((state)=> state.profile.language)
    const classes = useStyles();
    
  if(language==="ZH")
  {
    moment.locale('zh-cn')
  }else if(language==="TL")
  {
    moment.locale('tl-ph')
  }else
  {
    moment.locale(language)
  }


    let formattedStartDate = ''; // Declare variables outside the if block
    let formattedEndDate = '';


    if (data) {
        const [startDateStr, endDateStr] = data.trendingComparedDate.split(' - ');
        formattedStartDate = moment(startDateStr).format('ll');
        formattedEndDate = moment(endDateStr).format('ll');
    }

    return (
        <Box
            className={classes.trendingCard}
        >
            {/* 1st ROW: Total Count & Trending % */}
            <Box display="flex" justifyContent="space-between" alignItems="center" fontFamily="Roboto">
                <Box display="flex" className={classes.global__flexGap3}>
                    <Typography className={classes.trendingCard__totalCount}>{numberFormatteer(data.totalCount)}</Typography>
                    <Typography className={( data.realTrendingPercentage > 0 ? classes.trendingCard__trendingCountIncrement : classes.trendingCard__trendingCountDecrement)}>{data.realTrendingCount ? "(" + data.displayTrendingCount + ")" : null}</Typography>
                </Box>
                <Box className={ ( data.realTrendingPercentage > 0 ? classes.trendingCard__trendingIndicatorIncrement :  data.realTrendingPercentage < 0 ? classes.trendingCard__trendingIndicatorDecrement : classes.trendingCard__trendingIndicatorEqual ) }>
                    { data.displayTrendingCount > 0 ? <UpArrowIcon /> : data.displayTrendingCount < 0 ? <DownArrowIcon /> :  null}
                    <Typography>{data.displayTrendingPercentage || 0 + '%'}</Typography>
                </Box>
            </Box>
            
            {/* 2nd ROW: Trending Title  & Compare*/}
            <Box display="flex" justifyContent="space-between" alignItems="center" fontFamily="Roboto" marginTop="6px">
                <Box display="flex" flexDirection="column">
                    <Typography className={classes.trendingCard__title}>{title}</Typography>
                    <Typography className={classes.trendingCard_info} >{translate("label.COMPARE_WITH")}</Typography>
                    <Typography className={classes.trendingCard_info} >{formattedStartDate +" - " + formattedEndDate}</Typography>
                </Box>
                {/* { data.trendingPercentage > 0 ? <TrendingIncreaseIcon /> : data.trendingPercentage === 0 ?  <TrendingEqualIcon /> : <TrendingDecreaseIcon /> } */}
                <TrendingChart chart={data.chart} />
            </Box>
        </Box>
    )
}