import React from "react";
import {
  Box,
  Button,
  Divider,
  Tabs,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { getLang } from "app/feature/constants";
import InfiniteScroll from "react-infinite-scroll-component";
import LoadingComponent from "components/loading";
import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";
import CustomTab from "components/tab";
import { Formik } from "formik";
import Input from "components/input/inputTextField";

const useStyles = makeStyles((theme) => ({
  productRow: {
    border: "1px solid #D0D5DD",
    "& > div:first-child": {
      borderBottom: "1px solid #D0D5DD",
    },
  },
  list: {
    maxHeight: "70vh",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "5px",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "16px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#E4E7EC",
      borderRadius: "16px",
    },
  },
  panel: {
    backgroundColor: "white",
    padding: 16,
  },
  searchContainer: {
    backgroundColor: theme.palette.background.paper,
    display: "grid",
    gridTemplateColumns: "5fr 1fr 1fr",
    columnGap: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1),
    },
  },
  inputRootProduct: {
    paddingTop: "8px !important",
    paddingBottom: "7px !important",
    paddingLeft: "12px !important",
    paddingRight: "12px !important",
  },
}));

export default function ReceiveItemsBoxListPanelComponent({
  lang,
  isFetching,
  list,
  totalFiltered,
  isError,
  handleFetchMoreItems,
  hasNextPage,
  handleReload,
  viewBox,
  changeStatusTab,
  tab,
  status,
  handleSearch,
  filter,
}) {
  const classes = useStyles();

  const PARTIAL_RECEIVE = "Partial Receive";

  let tabLabels = [
    getLang(lang, "label.RECEIVED"),
    status === PARTIAL_RECEIVE
      ? getLang(lang, "label.PENDING")
      : getLang(lang, "label.MISSING"),
  ];

  function a11yProps(index, label = "label") {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tab-${index}`,
      label: <Typography variant="button" children={label} />,
    };
  }

  return (
    <Box className={classes.panel}>
      <Box mb={1}>
        <Typography variant="h6">{getLang(lang, "label.BOX")}</Typography>
      </Box>
      <Box mb={2}>
        <Tabs
          value={tab}
          onChange={changeStatusTab}
          aria-label="scrollable auto tabs example"
          textColor="primary"
          variant="scrollable"
          indicatorColor="primary"
          classes={{
            indicator: classes.indicator,
            scrollButtons: classes.scroll,
          }}
        >
          {tabLabels.map((item, index) => {
            return <CustomTab key={index} {...a11yProps(index, item)} />;
          })}
        </Tabs>
        <Divider />
      </Box>
      <Box mt={2}>
        <Formik
          initialValues={{
            keyword: filter.search,
          }}
          onSubmit={handleSearch}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <Box className={classes.searchContainer}>
                <Input
                  hiddenLabel
                  variant="filled"
                  size="small"
                  placeholder={getLang(lang, "placeholder.SEARCH_BOX_CODE")}
                  margin="none"
                  InputProps={{
                    disableUnderline: true,
                    margin: "none",
                    style: {
                      borderRadius: "4px",
                      backgroundColor: "#eceff0",
                    },
                    classes: { input: classes.inputRootProduct },
                  }}
                  {...formik.getFieldProps("keyword")}
                />
                <Button
                  variant="text"
                  disableElevation
                  color="primary"
                  size="small"
                  onClick={() => handleSearch()}
                >
                  {getLang(lang, "label.CLEAR")}
                </Button>
                <Button
                  variant="contained"
                  disableElevation
                  color="primary"
                  size="small"
                  type="submit"
                >
                  {getLang(lang, "label.SEARCH")}
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
      <Box mt={2}>
        {isError ? (
          <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
            pt={2}
            pb={3}
          >
            <Typography
              variant="body2"
              color="primary"
              style={{ fontWeight: "bold", paddingBottom: "8px" }}
            >
              {getLang(lang, "label.NO_DATA_RECEIVED")}
            </Typography>
            <Typography
              variant="body2"
              style={{ color: "#9CA6A9", paddingBottom: "16px" }}
            >
              {getLang(lang, "label.SOMETHING_WENT_WRONG")}
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleReload}
              disableElevation
            >
              {getLang(lang, "label.REFRESH")}
            </Button>
          </Box>
        ) : (
          <InfiniteScroll
            height="100%"
            className={classes.list}
            dataLength={list.length}
            next={() => {
              if (hasNextPage) handleFetchMoreItems();
            }}
            hasMore={totalFiltered > list.length}
            loader={<LoadingComponent height={100} />}
            endMessage={
              isFetching ? (
                <LoadingComponent height={100} />
              ) : !list.length ? (
                <Box px={1.5} py={4}>
                  <Typography variant="body2" style={{ textAlign: "center" }}>
                    <b>{getLang(lang, "label.NO_BOX")}</b>
                  </Typography>
                </Box>
              ) : null
            }
          >
            {[...list].map((item, i) => (
              <Box key={i} className={classes.productRow} mt={i === 0 ? 0 : 2}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  p={1}
                >
                  <Box>
                    <Typography variant="body1">
                      <b>{item.name}</b>
                    </Typography>
                  </Box>
                  <ChevronRightRoundedIcon
                    color="primary"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      viewBox(item);
                    }}
                  />
                </Box>
                <Box p={1}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="body2">
                      {getLang(lang, "label.SERIAL_NUMBER_QTY")}
                    </Typography>
                    <Typography variant="body1">
                      <b>{item.quantity.toLocaleString("en-US")}</b>
                    </Typography>
                  </Box>
                </Box>
              </Box>
            ))}
          </InfiniteScroll>
        )}
      </Box>
    </Box>
  );
}
