import axios from "axios";

const post = ({ instantLuckyDrawId, winnerField }) => {
  const body = {
    instantLuckyDrawId: instantLuckyDrawId,
    winnerField: winnerField
  };

  return new Promise((resolve, reject) => {
    axios
      .post("/campaign/api/admin/instant-lucky-draw/prizes/winners", body)
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

const winners = {
  post
};

export default winners;
