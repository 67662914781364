import axios from "axios"

export function getStockoutByProduct (parameters) {
  return new Promise((resolve, reject) => {
    axios.post(`/analytic/api/v1/dealership/get-stockout-by-product`, {
      period: parameters.period,
      start_date: parameters.start_date,
      end_date: parameters.end_date,
      dealer_uuid: parameters.dealer_uuid,
      orderBy: parameters.orderBy,
      orderDirection: parameters.orderDirection,
    })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data))
  });
};