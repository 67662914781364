
import axios from "axios"

export function getStockProductDropdown ({ search, type, dealer, code }) {
  return new Promise((resolve, reject) => {
    axios.get(`/distributor/admin/api/v1/inventory/get-stock-product-dropdown`, {
      params: {
          search: search,
          type: type,
          dealer: dealer,
          code: code
      }
  })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data))
  });
};