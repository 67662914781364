import axios from "axios";

export function getBrandDropdownWithNoBrand() {
  const url = "/serialnumber/api/v1/product/brand-models/get-brand-dropdown-with-no-brand";

  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data));
  });
}
