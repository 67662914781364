import { createAsyncThunk } from "@reduxjs/toolkit";
import stockflowApi from "app/api/stockflow";

export const STOCKFLOWCHECKOUT = "stockflowCheckout";

export const getCheckoutList = createAsyncThunk(
  `${STOCKFLOWCHECKOUT}/getCheckoutList`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi
      .getCheckoutList(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const getCheckoutDetail = createAsyncThunk(
  `${STOCKFLOWCHECKOUT}/getCheckoutDetail`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi
      .getCheckoutDetail(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const getCheckoutByDropdown = createAsyncThunk(
  `${STOCKFLOWCHECKOUT}/getCheckoutByDropdown`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi
      .getCheckoutByDropdown(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const getCheckoutBoxList = createAsyncThunk(
  `${STOCKFLOWCHECKOUT}/getCheckoutBoxList`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi
      .getCheckoutBoxList(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const getCheckoutProductList = createAsyncThunk(
  `${STOCKFLOWCHECKOUT}/getCheckoutProductList`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi
      .getCheckoutProductList(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const getCheckoutProductDetail = createAsyncThunk(
  `${STOCKFLOWCHECKOUT}/getCheckoutProductDetail`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi
      .getCheckoutProductDetail(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const updateCheckoutDeliveryInfo = createAsyncThunk(
  `${STOCKFLOWCHECKOUT}/updateCheckoutDeliveryInfo`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi
      .updateCheckoutDeliveryInfo(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const getCheckoutDeliveryInfo = createAsyncThunk(
  `${STOCKFLOWCHECKOUT}/getCheckoutDeliveryInfo`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi
      .getCheckoutDeliveryInfo(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const updateCheckoutDetail = createAsyncThunk(
  `${STOCKFLOWCHECKOUT}/updateCheckoutDetail`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi
      .updateCheckoutDetail(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);
