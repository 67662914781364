import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchWarrantyReimbursementTable, fetchConsumerClaimDetail, clearConsumerClaimDetail, exportWarrantyReimbursementTable } from "modules/warranty/redux";
import { getAllProducts } from "modules/product-mgmt/redux/action/products.action";

import { selectLoading } from "modules/notification";
import BranchClaimPanelComponent from "./branchClaimPanel.component";
import { unwrapResult } from "@reduxjs/toolkit";

export default function BranchClaimPanelContainer({ lang }) {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.session.accessToken);

  const isFetching = useSelector(state => selectLoading(state, fetchWarrantyReimbursementTable.typePrefix));
  const warrantyReimbursementTable = useSelector(state => state.warranty.warrantyReimbursementTable);
  const companyUuid = useSelector(state => state.account.id);
  const productDropdown = useSelector(state => state.products.allProducts);
  const language = useSelector(state => state.profile.language);

  const rowPerPageOption = [10, 25, 50, 100];
  const [page, setPage] = useState(0);
  const [rowPerPage, setRowPerPage] = useState(rowPerPageOption[1]);


  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [pageSetting, setPageSetting] = useState({
    length: 25,
    start: 0,
    search: "",
    searchColumn: "warrantee_claim_reference_code",
    branch: [],
    product: [],
    status: "",
  });

  const fetchTable = (payload) => {
    dispatch(fetchWarrantyReimbursementTable(payload));
  }

  useEffect(() => {
    fetchTable({ ...pageSetting, companyUuid });
    dispatch(getAllProducts());

    // eslint-disable-next-line
  }, [dispatch, companyUuid]);


  //  SEARCH & FILTER  //
  const handleSearch = async (value) => {
    const temp = {
      ...pageSetting,
      start: 0,
      search: value.search,
      searchColumn: value.searchColumn,
      branch: value.branch,
      product: value.product,
      status: value.status,
    };
    await setPageSetting(temp);
    fetchTable({ ...temp, companyUuid });
  };

  //  TABLE LIST  //
  const handleReload = async () => {
    const temp = {
      length: 25,
      start: 0,
      search: "",
      searchColumn: "warrantee_claim_reference_code",
    };

    await setPageSetting(temp);
    fetchTable({ ...temp, companyUuid })
  };

  //  PAGINATION  //
  const handlePageChange = async (_, value) => {
    await setPage(value);

    const temp = {
      ...pageSetting,
      start: value * rowPerPage,
    };

    await setPageSetting(temp)
    fetchTable({ ...temp, companyUuid })
  };

  const handleRowPerPageChange = async (value) => {
    await setRowPerPage(value.target.value);
    const temp = {
      ...pageSetting,
      start: 0,
      length: value.target.value,
    };

    await setPageSetting(temp);
    fetchTable({ ...temp, companyUuid })
  };

  //  REIMBURSEMENT DETAIL MODEL  //
  const handleViewModel = async (item) => {
    const info = {
      claimId: item.claimUuid,
      reimbursementId: item.uuid,
    };
    await (dispatch(fetchConsumerClaimDetail(info)));
  };

  const handleCloseModel = () => {
    dispatch(clearConsumerClaimDetail());
  }

  // EXPORT//
  const handleExport = (values) => {
    const info = {
      token: token,
      format: values.format,
      ...pageSetting
    };
    dispatch(exportWarrantyReimbursementTable(info))
      .then(unwrapResult)
      .finally(() => {

      });
  };

  return (
    <BranchClaimPanelComponent
      lang={lang}
      language={language}

      //  SEARCH & FILTER  //
      pageSetting={pageSetting}
      handleSearch={handleSearch}
      isFilterOpen={isFilterOpen}
      setIsFilterOpen={setIsFilterOpen}
      productDropdown={productDropdown}

      //  TABLE LIST  //
      isFetching={isFetching}
      rows={warrantyReimbursementTable.data}
      handleReload={handleReload}

      //EXPORT//
      handleExport={handleExport}

      //  DETAIL MODEL  //
      handleViewModel={handleViewModel}
      handleCloseModel={handleCloseModel}

      //  PAGINATION  //
      page={page}
      length={
        pageSetting.search ?
          warrantyReimbursementTable.filteredTotalRecords :
          warrantyReimbursementTable.totalRecords
      }
      rowPerPage={rowPerPage}
      rowPerPageOption={rowPerPageOption}
      handlePageChange={handlePageChange}
      handleRowPerPageChange={handleRowPerPageChange}
    />
  );
}
