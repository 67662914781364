import { createSlice } from "@reduxjs/toolkit";
import { getIndustries, getCountry, getLanguages, getApiLanguages } from "app/feature/constants";
import langUiFallback from "../../../../assets/lang/ui/EN.json";
import langApiFallback from "../../../../assets/lang/api/EN.json";

const CONSTANT = "constant";

const initialState = {
  industries: {
    isLoaded: false,
    isLoading: false,
    data: []
  },
  country: {
    isLoaded: false,
    isLoading: false,
    data: []
  },
  timezones: [],
  languages: {
    isLoaded: false,
    isLoading: false,
    data: [],
    apiData: [],
    fallbackData: [],
    fallbackApiData: []
  },
};

const constantSlice = createSlice({
  name: CONSTANT,
  initialState,
  reducers: {
    setTimezone(state, action) {
      state.timezones = action.payload;
    },
    setFallbackLanguages(state) {
      state.languages.fallbackData = langUiFallback;
      state.languages.fallbackApiData = langApiFallback;
    }
  },
  extraReducers: {
    [getIndustries.fulfilled]: (state, action) => {
      state.industries.data = action.payload;
    },
    [getCountry.fulfilled]: (state, action) => {
      state.country.data = action.payload;
    },
    [getLanguages.fulfilled]: (state, action) => {
      state.languages.data = action.payload;
    },
    [getLanguages.rejected]: (state) => {
      state.languages.data = initialState.languages.data;
    },
    [getApiLanguages.fulfilled]: (state, action) => {
      state.languages.apiData = action.payload;
    },
    [getApiLanguages.rejected]: (state) => {
      state.languages.apiData = initialState.languages.data;
    }
  }
});

export const { 
  setTimezone,
  setFallbackLanguages
} = constantSlice.actions;
export default constantSlice.reducer;
