import React, { useEffect } from "react";
import SignUpComponent from "./signup.page";
import qs from "query-string";
import { connect } from "react-redux";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { initiateWithPreselectPlan } from "../../redux/slice/signup.slice";

function SignUpContainer({ location: { search }, initiateWithPreselectPlan }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const type = "SignUp";

  useEffect(() => {
    
    // localStorage.removeItem('session_id')
    // localStorage.removeItem('session_expiration')

    if (!search) return;
    const { plan, type } = qs.parse(search);
    initiateWithPreselectPlan({ plan, type });
  }, [initiateWithPreselectPlan, search]);

  return <SignUpComponent isMobile={isMobile} type={type} />;
}

const mapDispatchToProps = dispatch => ({
  initiateWithPreselectPlan: value => dispatch(initiateWithPreselectPlan(value))
});

export default connect(null, mapDispatchToProps)(SignUpContainer);
