import { setLoading, addAlert } from "modules/notification";
import {
  assignSerialNumber,
  checkSerialNumberAvailability,
  fetchTableLength,
  getCampaignAssigned,
  getScanTrackDetail,
  getSerialNumberActivityLog,
  getSerialNumberScanListing,
  getSnSummaryCount,
  getUnassignedSerialNumberByBranch,
} from "../action";
import { getApiLang } from "app/feature/constants";
import { setListingLength } from "../slice";

const assignSerialNumberMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case assignSerialNumber.pending.type:
        dispatch(
          setLoading({ id: assignSerialNumber.typePrefix, state: true })
        );
        break;

      case assignSerialNumber.fulfilled.type:
        dispatch(
          setLoading({ id: assignSerialNumber.typePrefix, state: false })
        );
        break;

      case assignSerialNumber.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({ id: assignSerialNumber.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity:
              payload.code === "error.ERROR_FEATURE_DISABLED"
                ? "warning"
                : "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

const getUnassignedSNByBranchMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getUnassignedSerialNumberByBranch.pending.type:
        dispatch(
          setLoading({
            id: getUnassignedSerialNumberByBranch.typePrefix,
            state: true,
          })
        );
        break;

      case getUnassignedSerialNumberByBranch.fulfilled.type:
        dispatch(
          setLoading({
            id: getUnassignedSerialNumberByBranch.typePrefix,
            state: false,
          })
        );
        break;

      case getUnassignedSerialNumberByBranch.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: getUnassignedSerialNumberByBranch.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_GET_PATTERN_LIST"
            ),
          })
        );
        break;

      default:
        break;
    }
  };

const getSerialNumberScanListingMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getSerialNumberScanListing.pending.type:
        dispatch(
          setLoading({ id: getSerialNumberScanListing.typePrefix, state: true })
        );
        break;

      case getSerialNumberScanListing.fulfilled.type:
        dispatch(
          setLoading({
            id: getSerialNumberScanListing.typePrefix,
            state: false,
          })
        );
        break;

      case getSerialNumberScanListing.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: getSerialNumberScanListing.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

const getCampaignAssignedMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getCampaignAssigned.pending.type:
        dispatch(
          setLoading({ id: getCampaignAssigned.typePrefix, state: true })
        );
        break;

      case getCampaignAssigned.fulfilled.type:
        dispatch(
          setLoading({ id: getCampaignAssigned.typePrefix, state: false })
        );
        break;

      case getCampaignAssigned.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({ id: getCampaignAssigned.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

const getSerialNumberActivityLogMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getSerialNumberActivityLog.pending.type:
        dispatch(
          setLoading({ id: getSerialNumberActivityLog.typePrefix, state: true })
        );
        break;

      case getSerialNumberActivityLog.fulfilled.type:
        dispatch(
          setLoading({
            id: getSerialNumberActivityLog.typePrefix,
            state: false,
          })
        );
        break;

      case getSerialNumberActivityLog.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: getSerialNumberActivityLog.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

const getScanTrackDetailMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getScanTrackDetail.pending.type:
        dispatch(
          setLoading({ id: getScanTrackDetail.typePrefix, state: true })
        );
        break;

      case getScanTrackDetail.fulfilled.type:
        dispatch(
          setLoading({ id: getScanTrackDetail.typePrefix, state: false })
        );
        break;

      case getScanTrackDetail.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({ id: getScanTrackDetail.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

const checkSerialNumberAvailabilityMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case checkSerialNumberAvailability.pending.type:
        dispatch(
          setLoading({
            id: checkSerialNumberAvailability.typePrefix,
            state: true,
          })
        );
        break;

      case checkSerialNumberAvailability.fulfilled.type:
        dispatch(
          setLoading({
            id: checkSerialNumberAvailability.typePrefix,
            state: false,
          })
        );
        break;

      case checkSerialNumberAvailability.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: checkSerialNumberAvailability.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_GET_PATTERN_LIST"
            ),
          })
        );
        break;

      default:
        break;
    }
  };

const fetchTableLengthMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case fetchTableLength.pending.type:
        dispatch(setLoading({ id: fetchTableLength.typePrefix, state: true }));
        break;

      case fetchTableLength.fulfilled.type:
        dispatch(setListingLength(payload));
        dispatch(setLoading({ id: fetchTableLength.typePrefix, state: false }));
        break;

      case fetchTableLength.rejected.type:
        const lang = getState().constant.languages;
        dispatch(setLoading({ id: fetchTableLength.typePrefix, state: false }));
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_GET_PATTERN_LIST"
            ),
          })
        );
        break;

      default:
        break;
    }
  };

const getSnSummaryCountMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getSnSummaryCount.pending.type:
        dispatch(setLoading({ id: getSnSummaryCount.typePrefix, state: true }));
        break;

      case getSnSummaryCount.fulfilled.type:
        dispatch(
          setLoading({ id: getSnSummaryCount.typePrefix, state: false })
        );
        break;

      case fetchTableLength.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({ id: getSnSummaryCount.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

export default [
  assignSerialNumberMiddleware,
  getUnassignedSNByBranchMiddleware,
  getSerialNumberScanListingMiddleware,
  getCampaignAssignedMiddleware,
  getSerialNumberActivityLogMiddleware,
  getScanTrackDetailMiddleware,
  checkSerialNumberAvailabilityMiddleware,
  fetchTableLengthMiddleware,
  getSnSummaryCountMiddleware,
];
