import React from "react";
import {
  makeStyles,
  Typography,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  Box,
  Divider,
  Button,
  MenuItem,
  MenuList
} from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import PerfectScrollbar from "react-perfect-scrollbar";
import Skeleton from "@material-ui/lab/Skeleton";
import AntSwitch from "components/switch/switch";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles((theme) => ({
  paper: {
    width: 425
  },
  button: {
    padding: 0,
    minWidth: 0
  },
  buttonAddBranch: {
    display: "flex",
    padding: 0,
    minWidth: 0
  },
  createLink: {
    padding: theme.spacing(2)
  }
}));

export default function BranchPanelComponent({
  hasBranchAddAccessRight,
  open,
  anchorRef,
  handleClose,
  isOpenList,
  branchDropdown,
  isFetching,
  value,
  handleAddBranch,
  handleSelectAll,
  selectAll,
  handleSelectBranch,
  lang
}) {
  const classes = useStyle();

  const generateLayeredSwitch = (nodes, layer, parent_ids) => {
    if (!nodes || nodes.length === 0) {
      return null;
    }

    return nodes.map((node, index) => {
      return layer === -1 ? (
        <React.Fragment key={index}>
          <MenuItem
            onClick={() => handleSelectBranch(node)}
            disabled={!!value.find((v) => v.node_id === node.parent_node_id)}
          >
            <AntSwitch
              checked={value.map(v => v.node_id).some(id => [node.node_id, node.parent_node_id].includes(id))}
              disabled={!!value.find((v) => v.node_id === node.parent_node_id)}
            />
            <Typography
              variant="inherit"
              noWrap
              style={{ paddingLeft: "8px" }}
            >
              {node.name}
            </Typography>
          </MenuItem>

          {generateLayeredSwitch(
            node.child,
            0,
            [node.node_id]
          )}
        </React.Fragment>
      ) : (
        <React.Fragment key={index}>
          <MenuList
            autoFocusItem={isOpenList}
            id="menu-list-grow"
            style={{ padding: 0 }}
          >
            <MenuItem
              style={{ paddingLeft: 40 + layer * 16 + "px" }}
              onClick={() => handleSelectBranch(node)}
              disabled={value.map(v => v.node_id).some(id => parent_ids.includes(id))}
            >
              <AntSwitch
                checked={value.map(v => v.node_id).some(id => [...parent_ids, node.node_id].includes(id))}
                disabled={value.map(v => v.node_id).some(id => parent_ids.includes(id))}
              />

              <Typography
                variant="inherit"
                noWrap
                style={{ paddingLeft: "8px" }}
              >
                {node.name}
              </Typography>
            </MenuItem>
          </MenuList>

          {generateLayeredSwitch(
            node.child,
            layer + 1,
            [...parent_ids, node.node_id]
          )}
        </React.Fragment>
      );
    });
  }

  return (
    <Popper
      open={open}
      style={{ zIndex: 99 }}
      anchorEl={anchorRef.current}
      role={undefined}
      transition
      disablePortal
      placement="bottom-start"
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === "bottom-start" ? "center top" : "center bottom"
          }}
        >
          <Paper className={classes.paper}>
            <ClickAwayListener onClickAway={handleClose}>
              <Box>
                <Typography
                  variant="body2"
                  color="primary"
                  style={{ padding: "8px 16px", fontWeight: "bold" }}
                >
                  {getLang(lang, "label.BRANCH")}
                </Typography>
                <Divider />

                <PerfectScrollbar>
                  {isFetching ? (
                    <>
                      <Skeleton variant="text" animation="wave" />
                      <Skeleton variant="text" animation="wave" />
                      <Skeleton variant="text" animation="wave" />
                      <Skeleton variant="text" animation="wave" />
                    </>
                  ) : (
                    <div>
                      <MenuList
                        autoFocusItem={isOpenList}
                        id="menu-list-grow"
                        onClick={() => {
                          handleSelectAll();
                        }}
                        style={{ padding: 0 }}
                      >
                        <MenuItem>
                          <AntSwitch checked={selectAll} />
                          <Typography
                            variant="inherit"
                            noWrap
                            style={{ paddingLeft: "8px" }}
                          >
                            {getLang(lang, "label.ALL")}
                          </Typography>
                        </MenuItem>
                      </MenuList>
                      <Divider />

                      <Box height="25vh" overflow="auto">
                        {generateLayeredSwitch(branchDropdown, -1)}
                      </Box>
                    </div>
                  )}
                  {
                    hasBranchAddAccessRight
                      ?
                      <>
                        <Divider />
                        <Box className={classes.createLink}>
                          <Button
                            color="primary"
                            className={classes.buttonAddBranch}
                            onClick={handleAddBranch}
                          >
                            <Typography
                              variant="body2"
                              color="primary"
                              style={{ fontWeight: "bold", paddingRight: "4px" }}
                            >
                              {getLang(lang, "label.ADD_NEW_BRANCH")}
                            </Typography>
                            <ArrowForwardIcon fontSize="small" />
                          </Button>
                        </Box>
                      </>
                      : null
                  }
                </PerfectScrollbar>
              </Box>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}
