import React from "react";

export default function HorizontalBarChartIcon(props) {
    return (
        <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.5879 0.969727C12.8775 0.969727 13.1123 1.20449 13.1123 1.49409L13.1123 3.06719C13.1123 3.35679 12.8775 3.59155 12.5879 3.59155L4.72246 3.59155L4.72246 0.969726L12.5879 0.969727Z" fill="#344054"/>
            <path d="M2.10071 0.969727C2.8247 0.969727 3.41162 1.55664 3.41162 2.28064C3.41162 3.00464 2.8247 3.59155 2.10071 3.59155C1.37671 3.59155 0.789794 3.00464 0.789794 2.28064C0.789794 1.55664 1.37671 0.969726 2.10071 0.969727Z" fill="#344054"/>
            <path d="M10.4908 5.68896C10.7804 5.68896 11.0151 5.92373 11.0151 6.21333L11.0151 7.78643C11.0151 8.07603 10.7804 8.31079 10.4908 8.31079L4.72275 8.31079L4.72275 5.68896L10.4908 5.68896Z" fill="#344054"/>
            <path d="M2.10071 5.68896C2.8247 5.68896 3.41162 6.27588 3.41162 6.99988C3.41162 7.72388 2.8247 8.31079 2.10071 8.31079C1.37671 8.31079 0.789794 7.72388 0.789794 6.99988C0.789794 6.27588 1.37671 5.68896 2.10071 5.68896Z" fill="#344054"/>
            <path d="M14.6856 10.4087C14.9752 10.4087 15.21 10.6435 15.21 10.9331L15.21 12.5062C15.21 12.7958 14.9752 13.0305 14.6856 13.0305L4.72265 13.0305L4.72265 10.4087L14.6856 10.4087Z" fill="#344054"/>
            <path d="M2.10071 10.4087C2.8247 10.4087 3.41162 10.9956 3.41162 11.7196C3.41162 12.4436 2.8247 13.0305 2.10071 13.0305C1.37671 13.0305 0.789794 12.4436 0.789794 11.7196C0.789794 10.9956 1.37671 10.4087 2.10071 10.4087Z" fill="#344054"/>
        </svg>
    )
}