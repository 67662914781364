import axios from "axios";

const url = "/distributor/admin/api/v1/stockout/list";
export function getStockoutList(parameters) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, {
        length: parameters.length,
        start: parameters.start,
        order: parameters.order,
        order_by: parameters.order_by,
        status: parameters.status,
        filter: {
          nodes: parameters.filter.nodes,
          search: parameters.filter.search,
          from_date: parameters.filter.from_date,
          to_date: parameters.filter.to_date,
          stockout_from: parameters.filter.stockout_from,
          stockout_to: parameters.filter.stockout_to,
          respond_date: parameters.filter.respond_date,
        },
        custom_fields: parameters.custom_fields,
      })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data));
  });
}
