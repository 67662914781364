import axios from "axios";

export function updateStockModuleSetting(parameters) {
  return new Promise((resolve, reject) => {
    let payload = {
      max_tier: parameters.maxTier,
      request_stock: parameters.requestStock,
      auto_receive_stock: parameters.autoReceiveStock,
      auto_receive_stock_days: parameters.autoReceiveStockDays,
      reward_point: parameters.rewardPoint,
      reward_point_min: parameters.rewardPointMin,
      reward_point_expiration: parameters.rewardPointExpiration,
      reward_point_expiration_months: parameters.rewardPointExpirationMonths,
      reward_point_recall_deduction: parameters.rewardPointRecallDeduction,
      custom_email_option: parameters.customEmailOption,
      custom_email_recipients: parameters.customEmailRecipients,
    };

    if (parameters.hasCompanyStockEditTransferAccessRight) {
      payload.auto_receive_transfer = parameters.autoReceiveTransfer;
      payload.auto_receive_transfer_days = parameters.autoReceiveTransferDays;
      payload.enable_transfer_rules = parameters.enableTransferRules;
      payload.enable_custom_reasons = parameters.enableCustomReasons;
      payload.custom_reasons = parameters.customReasons;
    }

    if (parameters.hasUpdateReceiveEmailAccessRight) {
      payload.custom_email_option = parameters.customEmailOption;
      payload.custom_email_recipients = parameters.customEmailRecipients;
    }

    axios
      .post("/distributor/admin/api/v1/updateStockModuleSetting", payload)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data));
  });
}
