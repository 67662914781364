import PropTypes from "prop-types";
import koodServerInstance from "./instance";

const createPresignedUrl = async fileName => {
  const data = { fileName };

  const response = await koodServerInstance.post(
    '/createPresignedUrl', data
  );

  return response.data;
};

createPresignedUrl.propTypes = {
  fileName: PropTypes.string.isRequired
};

export default createPresignedUrl;
