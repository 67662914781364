import React from "react";
import {
  makeStyles,
  Box,
  Typography,
  Chip,
  Divider,
  MenuItem,
  Button
} from "@material-ui/core";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import LANGUAGES from "lib/constants/languages";
import { Formik } from "formik";
import Input from "components/input/inputTextField";
import { getLang } from "app/feature/constants";
import LanguageSelect from "../../select/languageSelect";

const useStyle = makeStyles(theme => ({
  chip: {
    margin: theme.spacing(1, 1, 0, 0)
  },
  btnSecondary: {
    color: theme.palette.common.white,
    marginLeft: theme.spacing(1)
  }
}));

export default function ProductPageSettingTabComponent({
  handleClick,
  handleDelete,
  lang,
  showLangSelect,
  handleAddLanguage,
  languages,
  isEditorMode,
}) {
  const classes = useStyle();
  const availableLanguages = LANGUAGES.filter(lg => !lang.includes(lg.code));
  const ableToAddLanguage = availableLanguages.length > 0;
  return (
    <Box>
      <LanguagePanel
        title={getLang(languages,"label.LANGUAGE_UPPERCASE")}
        types={lang.map(code => ({
          label: LANGUAGES.find(lg => lg.code === code).label,
          key: code
        }))}
        handleClick={handleClick}
        handleDelete={handleDelete}
        handleAddLanguage={handleAddLanguage}
        lang={lang}
        languages={languages}
        isEditorMode={isEditorMode}
      />
      {showLangSelect && ableToAddLanguage ? (
        <Formik
          initialValues={{ newLang: availableLanguages[0].code }}
          onSubmit={handleAddLanguage}
        >
          {formik => (
            <form onSubmit={formik.handleSubmit}>
              <Box p={2} pt={0} display="flex">
                <Input
                  required
                  hiddenLabel
                  variant="filled"
                  size="small"
                  margin="none"
                  fullWidth
                  select={true}
                  {...formik.getFieldProps("newLang")}
                >
                  {availableLanguages.map(option => (
                    <MenuItem key={option.code} value={option.code}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Input>
                <Button
                  disableElevation
                  variant="contained"
                  color="secondary"
                  classes={{ root: classes.btnSecondary }}
                  type="submit"
                >
                  {getLang(languages,"label.CONFIRM")}
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      ) : null}
      <Divider />
    </Box>
  );
}

function LanguagePanel({
  title,
  types = [],
  handleDelete,
  renderAddLanguageComponent,
  handleAddLanguage,
  lang,
  languages,
  isEditorMode,
}) {
  const classes = useStyle();
  const hasOnly1Lang = types.length === 1;
  return (
    <Box p={2}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="body2" style={{ fontWeight: "bold" }}>
          {title}
        </Typography>

        <LanguageSelect
          disabled={!isEditorMode}
          handleAddLang={(code) => {
            handleAddLanguage(code)
          }}
          handleDeleteLang={(code) => {
            handleDelete(code);
          }}
          selectedValue={lang}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        />
      </Box>

      <Box display="flex" flexWrap="wrap">
        {types.map((type, index) => {
          return (
            <Chip
              key={index}
              color="primary"
              label={getLang(languages, `constant.language.${type.key}`)}
              className={classes.chip}
              clickable
              onDelete={!hasOnly1Lang && type.key !== "EN" ? () => handleDelete(type.key) : null}
              deleteIcon={<CancelRoundedIcon />}
            />
          );
        })}
        {renderAddLanguageComponent}
      </Box>
    </Box>
  );
}
