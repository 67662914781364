import React from 'react'
import AddPointDialogComponent from './addPointDialog.component'
import { useSelector } from "react-redux";

export default function AddPointDialogContainer({ isOpen, handleClose }) {
  const headerType = useSelector((state) => state.loyaltyProgram.headerType);

  return (
    <AddPointDialogComponent
      isOpen={isOpen}
      handleClose={handleClose}
      headerType={headerType}
    />
  )
}