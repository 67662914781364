import React from "react";
import { useSelector } from "react-redux";
import TimezoneSelectComponent from "./timezoneSelect.component";
import PropTypes from "prop-types";

function TimezoneSelectContainer({ textFieldProps, formProps }) {
  const timezones = useSelector(state => state.constant.timezones)
  const lang = useSelector(state => state.constant.languages)
  return (
    <TimezoneSelectComponent
      textFieldProps={textFieldProps}
      formProps={formProps}
      timezones={timezones}
      lang={lang}
    />
  );
}

TimezoneSelectContainer.propTypes = {
  textFieldProps: PropTypes.object,
  formProps: PropTypes.object
};

export default TimezoneSelectContainer;
