import { createSelector } from "reselect";
import { selectLoading } from "modules/notification";
import { checkSession } from "../action";

export const isUserVerified = createSelector(
  state => state.session,
  session => {
    return session.idTokenPayload.emailVerified || false;
  }
);

export const isHashParsed = createSelector(
  state => state.session,
  session => session.isHashParsed
);

export const isInitializing = createSelector(
  state => state.session.initialize,
  ({ loading, error }) => (error ? false : loading)
);

export const isAccessGranted = createSelector(
  state => state.session.isAccessGranted,
  accessGranted => accessGranted
);

export const isSessionSet = createSelector(
  state => state.session,
  session => {
    return Object.keys(session.idTokenPayload).length > 0;
  }
);

export const isSessionChecked = createSelector(
  state => state.session,
  state => selectLoading(state, checkSession.typePrefix),
  (session, isLoading) => session.isSessionChecked && !isLoading
);
