import React from "react";
import { Box, IconButton, makeStyles, Typography } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import clsx from "clsx";
import { getLang } from "app/feature/constants";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";
import { useHistory } from "react-router";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import moment from "moment";
import "moment/min/locales.min";

const useStyles = makeStyles((_) => ({
  card: {
    borderRadius: 12,
    background: "white",
    border: "1px solid #D0D5DD",
    padding: 10,
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  },
  icon: {
    height: 40,
    width: 40,
    objectFit: "contain",
    marginRight: 5,
  },
  infoLabel: {
    color: "#32D583",
  },
  warningLabel: {
    color: "#F97066",
  },
}));

export default function TrendingSummaryCardComponent({
  title,
  icon,
  data,
  language,
  loading,
  positive = true,
  lang,
  url,
  allowPopup,
  popup,
}) {
  const classes = useStyles();
  const history = useHistory();

  if(language==="ZH")
  {
    moment.locale('zh-cn')
  }else if(language==="TL")
  {
    moment.locale('tl-ph')
  }else
  {
    moment.locale(language)
  }


  let formattedStartDate = ''; // Declare variables outside the if block
  let formattedEndDate = '';
  if (data) {
    const [startDateStr, endDateStr] = data.trendingComparedDate.split(' - ');
    formattedStartDate = moment(startDateStr).format("ll");
    formattedEndDate = endDateStr ? moment(endDateStr).format("ll") : "";
  }

  return (
    <Box className={classes.card}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center" mr={1}>
          <img src={icon} alt="icon" className={classes.icon} />
          <Typography variant="body1">{title}</Typography>
        </Box>
        {!!url && (
          <IconButton
            style={{ padding: 8 }}
            onClick={() => {
              history.push(url);
            }}
          >
            <ArrowForwardIosRoundedIcon style={{ fontSize: 20 }} />
          </IconButton>
        )}
        {(!!popup && allowPopup) && (
          <IconButton
            style={{ padding: 8 }}
            onClick={popup}
          >
            <InfoIcon />
          </IconButton>
        )}
      </Box>
      <Box mt={0.5} mb={1} ml={0.5} display="flex" alignItems="flex-end">
        {loading ? (
          <Skeleton variant="text" animation="wave" width="100%" height={30} />
        ) : (
          <>
            <Typography variant="h5" style={{ marginRight: 4 }}>
              <b>{data?.totalCount?.toLocaleString("en-US") || 0}</b>
            </Typography>
            {!!data?.realTrendingPercentage && (
              <Typography
                variant="caption"
                className={clsx({
                  [classes.infoLabel]:
                    (data.realTrendingPercentage > 0 && positive) ||
                    (data.realTrendingPercentage < 0 && !positive),
                  [classes.warningLabel]:
                    (data.realTrendingPercentage > 0 && !positive) ||
                    (data.realTrendingPercentage < 0 && positive),
                })}
                style={{ marginBottom: 2 }}
              >
                <b>({data.displayTrendingPercentage || 0})</b>
              </Typography>
            )}
          </>
        )}
      </Box>
      <Box ml={0.5}>
        <Typography style={{ color: "#7C8DB5" }} variant="body2">
          {getLang(lang, "label.COMPARE_WITH")}{" "}
          {formattedStartDate +
            (formattedEndDate ? ` - ${formattedEndDate}` : "")}
        </Typography>
      </Box>
    </Box>
  );
}
