import React, { useEffect, useState } from "react";
import WarrantyExtensionPanelComponent from "./warrantyExtensionPanel.component";
import {
  setWarrantyExtension,
  setWarrantyExtensionFinishEdit,
  setWarrantyExtensionDelete,
  fetchWarrantyExtensionList,
  fetchMoreWarrantyExtension,
  setIsWarrantyExtensionBatchTableDirty,
  setSelectedWarrantyExtension,
} from "../../../redux";
import { selectLoading } from "modules/notification";
import { connect, useDispatch, useSelector } from "react-redux";
import ConfirmationDialogComponent from "components/dialog/confirmation";
import { WARRANTY_LIST_EDIT } from "lib/constants/accessRights";
import { getLang } from "app/feature/constants";

function WarrantyExtensionPanelContainer({
  handleClose,
  setWarrantyExtension,
  setWarrantyExtensionFinishEdit,
  setWarrantyExtensionDelete,
  type,
  changeBackView,
  changeToUpdate,
  warrantyExtensionList,
  isFetching,
  fetchMoreWarrantyExtension,
  currentShowing,
  hasMoretoLoad
}) {
  const dispatch = useDispatch();

  const hasWarrantyEditAccessRight = useSelector(state => state.profile.accessRights.includes(WARRANTY_LIST_EDIT))
  const [currentAddExtension, setcurrentAddExtension] = useState(null);
  const [confirmationDialogModal, setConfirmationDialogModal] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const lang = useSelector(state => state.constant.languages);
  const language = useSelector(state => state.constant.language);
  const currentSelectedExtension = useSelector(state => state.warranty.selectedWarrantyExtension);

  useEffect(() => {
    dispatch(setSelectedWarrantyExtension(0));
  }, [dispatch]);


  const fetchMoreData = () => {
    if (hasMoretoLoad) {
      fetchMoreWarrantyExtension(currentShowing + 10);
    }
  };

  const closeConfirmationDialog = () => {
    setConfirmationDialogModal(false);
    setDeleteIndex(null);
  };

  const handleOpenConfirmationDeleteDialog = () => {
    setConfirmationDialogModal(true);
  };

  const handleProceed = async () => {
    setIsProcessing(true);
    setConfirmationDialogModal(false);
    if (deleteIndex !== null) {
      await setWarrantyExtensionDelete(deleteIndex);
      setDeleteIndex(null);
      setIsProcessing(false);
    } else {
      handleDelete();
      setIsProcessing(false);
      changeBackView();
    }
  };

  const addExtension = () => {
    changeToUpdate('add');
    setcurrentAddExtension(warrantyExtensionList.length - 1);
  };

  const handleSave = async (type, values, response) => {
    switch (type) {
      case 'create':
        doneAddExtension(values, response);
        break;

      case 'edit':
        doneUpdateExtension(values, response);
        break;

      case 'generate':
        doneAddExtensionBatch(values, response);
        break;

      default:
        break;
    }
  }

  const doneAddExtension = async (values, response) => {
    const temp = [];
    const info = {
      ...values,
      id: response.payload.data.warranty_extension_tag_id,
      batch_number: []
    };

    info.batch_number.push(response.payload.data.batch_number);
    temp.push(info);

    await setWarrantyExtension(warrantyExtensionList.concat(temp));
    setcurrentAddExtension(null);
  };

  const doneUpdateExtension = async (values, response) => {
    const temp = warrantyExtensionList.map((item) => (item.id === values.id) ? values : item);

    await setWarrantyExtension(temp);
    setcurrentAddExtension(null);
  }

  const doneAddExtensionBatch = async (values, response) => {
    const info = {
      ...values,
      id: response.payload.data.warranty_extension_tag_id,
      batch_number: []
    };

    info.batch_number.push(response.payload.data.batch_number);
    const tempItem = warrantyExtensionList.map((item) => (item.id === info.id) ? info : item);

    await setWarrantyExtension(tempItem);
    dispatch(setIsWarrantyExtensionBatchTableDirty(true));
    setcurrentAddExtension(null);
  }

  const handleDelete = () => {
    setcurrentAddExtension(null);
  };

  const handleOpenConfirmationDeleteExistingDialog = (index) => {
    setDeleteIndex(index);
    setConfirmationDialogModal(true);
  };

  const handleDoneEdit = async (values, response) => {
    const info = {
      ...values,
      batch_number: response ? response.payload.data.batch_number : null,
    };
    await setWarrantyExtensionFinishEdit(info);
  };

  const onExtensionItemClick = (value) => {
    dispatch(setSelectedWarrantyExtension(value));
  };

  return (
    <>
      <WarrantyExtensionPanelComponent
        hasWarrantyEditAccessRight={hasWarrantyEditAccessRight}
        currentAddExtension={currentAddExtension}
        language={language}
        warrantyExtensionList={warrantyExtensionList}
        handleClose={handleClose}
        isProcessing={isProcessing}
        addExtension={addExtension}
        handleSave={handleSave}
        handleExistingDelete={handleOpenConfirmationDeleteExistingDialog}
        handleDelete={handleOpenConfirmationDeleteDialog}
        handleDoneEdit={handleDoneEdit}
        type={type}
        changeToUpdate={changeToUpdate}
        currentSelectedExtension={currentSelectedExtension}
        isFetching={isFetching}
        fetchMoreData={fetchMoreData}
        currentShowing={currentShowing}
        lang={lang}
        onExtensionItemClick={onExtensionItemClick}
      />
      <ConfirmationDialogComponent
        isOpen={confirmationDialogModal}
        handleClose={closeConfirmationDialog}
        handleProceed={handleProceed}
        type={"danger"}
        description={getLang(lang, 'paragraph.CANCEL_CREATE_WARRANTY_EXTENSION')}
        title={getLang(lang, 'label.CANCEL_CREATE_WARRANTY_EXTENSION')}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  warrantyExtensionList: state.warranty.warrantyExtensionListTable.data,
  currentShowing: state.warranty.warrantyExtensionListTable.currentShowing,
  hasMoretoLoad: state.warranty.warrantyExtensionListTable.hasMoretoLoad,
  id: state.warranty.warrantySetting.id,
  isFetching: selectLoading(state, fetchWarrantyExtensionList.typePrefix)
});

const mapDispatchToProps = (dispatch) => ({
  setWarrantyExtension: (values) => dispatch(setWarrantyExtension(values)),
  setWarrantyExtensionFinishEdit: (values) =>
    dispatch(setWarrantyExtensionFinishEdit(values)),
  setWarrantyExtensionDelete: (values) =>
    dispatch(setWarrantyExtensionDelete(values)),
  fetchWarrantyExtensionList: (values) =>
    dispatch(fetchWarrantyExtensionList(values)),
  fetchMoreWarrantyExtension: (values) =>
    dispatch(fetchMoreWarrantyExtension(values))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WarrantyExtensionPanelContainer);
