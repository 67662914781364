import React, { useState } from "react";
import GalleryFormComponent from "./galleryForm.component";
import PropTypes from "prop-types";
import {
  toBase64,
  createGalleryContentModel
} from "../../../utils/productPage.util";
import { connect, useSelector } from "react-redux";
import { addAlert } from "modules/notification";
import GalleryDetailForm from "../galleryDetailForm";
import { getLang } from "app/feature/constants";

const TYPES = ["new", "edit", "readonly"];
const INPUT_METHODS = ["browse_file", "image_url"];
const MAX_IMAGE_COUNT = 10;
const MAX_IMAGE_SIZE = 1024 * 1024 * 12;

function getInitialOrder(order) {
  const length = order.length;

  if (!length || length < MAX_IMAGE_COUNT) return [...order];
  else return order;
}

function GalleryFormContainer({
  type,
  handleResult,
  handleDelete,
  initialValues,
  showAlert,
  handleClose
}) {
  const [order, setOrder] = useState(getInitialOrder(initialValues.order));
  const [images, setImages] = useState(initialValues.images);
  const [isLoading, setLoading] = useState(false);
  const [imageName, setImageName] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [imageLink, setImageLink] = useState("");
  const [activeIndex, setActiveIndex] = useState(0);
  const [isEditingLink, setIsEditingLink] = useState(false);
  const [isEnableFullscreenBtn, setIsEnableFullscreenBtn] = useState(initialValues.isEnableFullscreenBtn ?? false);
  const lang = useSelector(state => state.constant.languages)

  const handleChangeTab = index => {
    setActiveIndex(index);
  };

  const handleChangeEnableFullscreenBtn = () => {
    setIsEnableFullscreenBtn(prev => prev !== true);
  }

  const handleFileUpload = async files => {
    if (!files[0]) {
      return;
    }

    if (files[0].size > MAX_IMAGE_SIZE) {
      showAlert({
        severity: "error",
        message: getLang(lang,"message.error.IMAGE_EXCEED_MAXIMUM_FILE_SIZE_12")
      });
      return;
    }

    if (order.length >= MAX_IMAGE_COUNT) {
      showAlert({
        severity: "error",
        message: getLang(lang,"message.error.MAXIMUM_IMAGE_ALLOWED")
      });
      return;
    }

    setLoading(true);

    const filename = files[0].name.replace(/\s/g, "");
    const base64Image = await toBase64(files[0]);
    const newImage = {
      id: filename,
      img: base64Image,
      type: files[0].type,
      destinationURL: ""
    };

    setLoading(false);

    if (order.includes(filename)) {
      showAlert({
        severity: "error",
        message: getLang(lang,"message.error.IMAGE_EXISTS")
      });
      return;
    }

    let newImages = { ...images };
    const newOrder = Array.from(order);

    newImages = {
      ...newImages,
      [filename]: {
        ...newImage,
        source: INPUT_METHODS[0],
        destinationURL: imageLink
      }
    };
    newOrder.push(filename);

    setImages(newImages);
    setOrder(newOrder);
    handleResetInput();
    setImageLink("");
  };

  const handleDeleteImage = id => {
    const newOrder = order.filter(c => c !== id);

    const newImages = { ...images };
    delete newImages[id];

    setOrder(newOrder);
    setImages(newImages);
  };

  const handleSubmit = () => {
    handleResult({
      images,
      order,
      isEnableFullscreenBtn
    });
  };

  const handleResetInput = () => {
    setImageName("");
    setImageUrl("");
  };

  const handleChangeLink = ({ name, value }) => {
    setIsEditingLink(false)

    const oldImage = images[name];

    let newImages = { ...images };
    delete newImages[name];

    newImages = {
      ...newImages,
      [imageName]: {
        ...oldImage,
        destinationURL: value
      }
    };

    setImages(newImages);
  };

  const handleRejected = errorMessage => {
    showAlert({
      severity: "error",
      message: errorMessage
    })
  }

  const handleInsertLink = id => {
    const selectedImage = images[id];

    setImageName(selectedImage.id);
    setImageUrl(selectedImage.img);
    setImageLink(selectedImage.destinationURL);
    setIsEditingLink(true);
  }

  const handleBack = () => {
    setIsEditingLink(false);
  }

  return (
    <>
      {!isEditingLink ?
        <GalleryFormComponent
          type={type}
          handleSubmit={handleSubmit}
          order={type === TYPES[2] ? initialValues.order : order}
          images={type === TYPES[2] ? initialValues.images : images}
          handleDeleteImage={handleDeleteImage}
          handleDelete={handleDelete}
          activeIndex={activeIndex}
          handleChangeTab={handleChangeTab}
          MAX_IMAGE_COUNT={MAX_IMAGE_COUNT}
          handleRejected={handleRejected}
          isLoading={isLoading}
          handleFileUpload={handleFileUpload}
          handleInsertLink={handleInsertLink}
          handleClose={handleClose}
          isEnableFullscreenBtn={isEnableFullscreenBtn}
          handleChangeEnableFullscreenBtn={handleChangeEnableFullscreenBtn}
          lang={lang}
        />
        :
        <GalleryDetailForm
          imageName={imageName}
          imageUrl={imageUrl}
          imageLink={imageLink}
          handleChangeLink={handleChangeLink}
          handleBack={handleBack}
        />
      }
    </>
  );
}

GalleryFormContainer.defaultProps = {
  type: TYPES[0],
  initialValues: createGalleryContentModel()
};

GalleryFormContainer.propTypes = {
  type: PropTypes.oneOf(TYPES).isRequired,
  handleResult: PropTypes.func,
  handleDelete: PropTypes.func,
  initialValues: PropTypes.object
};

const mapDispatchToProps = dispatch => ({
  showAlert: payload => dispatch(addAlert(payload))
});

export { TYPES };

export default connect(null, mapDispatchToProps)(GalleryFormContainer);
