import React from "react";
import {
  makeStyles,
  Box,
  Typography,

  Grid,
  Divider,
  Button,
} from "@material-ui/core";
import InputTextField from "components/input/inputTextField";
import { Formik } from "formik";
import * as Yup from "yup";
import DateTimePicker from "components/input/dateTimePicker";
import moment from "moment";
import ExtensionListTableComponent from "../../table/extensionListTable";
import clsx from "clsx";
import InputHelper from 'lib/InputHelper'
import { getLang } from "app/feature/constants";

const style = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "Column",
  },
  addExtensionContainer: {
    margin: "16px !important",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
    border: "1px solid rgba(0, 0, 0, 0.1)",
  },
  gridFieldPeriod: {
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  divider: {
    height: "100%",
    display: "flex",
    alignSelf: "center",
  },
  dividerEdit: {
    height: "70%",
  },
  monthContainer: {
    height: 43,
    backgroundColor: "#ECEFF0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 70,
    borderRadius: "0px 4px 4px 0px",
  },
  dateContainer: {
    height: 43,
    backgroundColor: "#ECEFF0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 60,
    borderRadius: "4px 0px 0px 4px",
  },
  dividerContainer: {
    backgroundColor: "#ECEFF0",
    display: "flex",
  },
  helperText: {
    marginTop: 0,
  },
  publishButton: {
    color: theme.palette.common.white,
  },
  gridContainer: {
    alignItems: "center",
    paddingBottom: theme.spacing(1),
  },
  gridGenerateCode: {
    display: "flex",
    placeContent: "flex-end",
  },
  monthView: {
    paddingLeft: theme.spacing(1),
  },
  dateView: {
    paddingRight: theme.spacing(1),
  },
});

const useStyles = makeStyles(style);

export default function WarrantyExtensionCardComponent({
  hasWarrantyEditAccessRight,
  handleDelete,
  handleSubmit,
  handleCancel,
  onAmountFieldChange,
  amount,
  info,
  index,
  handleGenerate,
  type,
  lang
}) {
  const classes = useStyles();
  const viewType = type === "view" ? true : false;

  return (
    <Box className={classes.root}>
      <Formik
        initialValues={{
          ...info,
          extensionPeriod: info ? info.extensionPeriod : "",
          effectiveDateStart: info
            ? moment(info.effectiveDateStart).format("YYYY-MM-DD HH:mm")
            : new moment().format("YYYY-MM-DD HH:mm"),
          effectiveDateEnd: info
            ? moment(info.effectiveDateEnd).format("YYYY-MM-DD HH:mm")
            : new moment().add(7, "day").format("YYYY-MM-DD HH:mm"),
          extensionCode: info ? info.extensionCode : "",
        }}
        validationSchema={Yup.object({
          extensionPeriod: Yup.number()
            .min(1, getLang(lang, "message.error.FORM_VALIDATE_MIN_NUMBER", { value: 1 }))
            .required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
          effectiveDateStart: Yup.date().required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
          effectiveDateEnd: Yup.date().required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
          extensionCode:
            type === "add" ? Yup.number().min(1, getLang(lang, "message.error.FORM_VALIDATE_MIN_NUMBER", { value: "1" })).required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")) : null,
        })}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            {/*Header*/}
            <Box p={2} px={3} pb={0}>
              <Typography color="primary" style={{ fontWeight: "bold" }}>
                {getLang(lang, 'label.EXTENSION')} #{index + 1} {type === "add" ? `(${getLang(lang, 'label.NEW')})` : null}
              </Typography>
            </Box>

            {/*Form Content*/}
            <Box flexGrow={1}>
              <Box p={2} px={3}>
                {/*Extension Period Field*/}
                <Grid container spacing={1} className={classes.gridContainer}>
                  <Grid item xs={5} sm={2} md={2} style={{ width: "100%" }}>
                    <Typography variant="body2" color="primary" sx={{ display: 'inline-block' }}>
                      {`${getLang(lang, 'label.EXTENSION_PERIOD')} ${type !== "view" ? "*" : ''}`}
                    </Typography>
                  </Grid>

                  <Grid item xs={7} sm={5} md={5} className={classes.gridFieldPeriod}>
                    <InputTextField
                      required
                      variant="filled"
                      size="small"
                      type="number"
                      onPaste={(e) => InputHelper.preventCopyEDecimalAndNegetiveInNumberInput(e)}
                      onKeyDown={(e) => InputHelper.preventTypeEDecimalAndNegetiveInNumberInput(e)}
                      InputProps={{
                        inputProps: {
                          min: 1,
                          style: {
                            padding: "8px",
                            backgroundColor: "#ECEFF0",
                            borderRadius: "4px 0px 0px 4px",
                          },
                        },
                        style: {
                          padding: "4px",
                          backgroundColor: "#ECEFF0",
                          borderRadius: "4px 0px 0px 4px",
                        },
                      }}
                      FormHelperTextProps={{
                        className: classes.helperText,
                      }}
                      disabled={type === "view" || formik.isSubmitting}
                      placeholder={getLang(lang, 'placeholder.ENTER')}
                      {...formik.getFieldProps("extensionPeriod")}
                      error={
                        formik.touched.extensionPeriod &&
                          formik.errors.extensionPeriod
                          ? true
                          : false
                      }
                    />

                    <Box display="flex">
                      <Box className={classes.dividerContainer}>
                        <Divider
                          orientation="vertical"
                          className={clsx(classes.divider, {
                            [classes.dividerEdit]: type !== "view"
                          })}
                        />
                      </Box>

                      <Box
                        className={clsx({
                          [classes.monthView]: viewType,
                          [classes.monthContainer]: !viewType,
                        })}
                      >
                        <Typography
                          variant="subtitle2"
                          style={{
                            fontWeight: "bold",
                            color: " rgba(58, 77, 84, 0.33)",
                          }}
                        >
                          {getLang(lang, 'label.MONTH')}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>

                {/*Effective Period Field*/}
                <Grid container spacing={1} className={classes.gridContainer}>
                  <Grid item xs={12} sm={2} md={2} style={{ width: "100%" }}>
                    <Typography variant="body2" color="primary" sx={{ display: 'inline-block' }}>
                      {`${getLang(lang, 'label.EFFECTIVE_PERIOD')} ${type !== "view" ? "*" : ''}`}
                    </Typography>
                  </Grid>

                  {[
                    {
                      label: getLang(lang, 'label.START'),
                      date: "effectiveDateStart",
                    },
                    {
                      label: getLang(lang, 'label.END'),
                      date: "effectiveDateEnd",
                    },
                  ].map((field, index) => (
                    <Grid
                      item
                      xs={6}
                      sm={5}
                      md={5}
                      key={index}
                      className={classes.gridFieldPeriod}
                    >
                      <Box display="flex">
                        <Box
                          className={clsx({
                            [classes.dateView]: viewType,
                            [classes.dateContainer]: !viewType,
                          })}
                        >
                          <Typography
                            variant="subtitle2"
                            style={{
                              fontWeight: "bold",
                              color: " rgba(58, 77, 84, 0.33)",
                            }}
                          >
                            {field.label}
                          </Typography>
                        </Box>
                        <Box className={classes.dividerContainer}>
                          <Divider
                            orientation="vertical"
                            className={clsx(classes.divider, {
                              [classes.dividerEdit]: type !== "view"
                            })}
                          />
                        </Box>
                      </Box>
                      <DateTimePicker
                        required
                        label=""
                        disabled={type === "view" || formik.isSubmitting || (field.label === getLang(lang, 'label.START') && info && moment(info.effectiveDateStart).isBefore(moment()))}
                        minDate={
                          field.label === "End"
                            ? new Date(formik.getFieldProps("effectiveDateStart").value).setDate(new Date(formik.getFieldProps("effectiveDateStart").value).getDate())
                            : new Date()
                        }
                        id={
                          info
                            ? field.label + info.id + "-picker-dialog"
                            : field.label + "-picker-dialog"
                        }
                        value={formik.getFieldProps(field.date).value}
                        handleChange={(v) => {
                          formik.setFieldValue(field.date, v.format("YYYY-MM-DD HH:mm"))
                          if (
                            field.date === "effectiveDateStart" && moment(v).isSameOrAfter(moment(formik.values.effectiveDateEnd))
                          ) {
                            formik.setFieldValue("effectiveDateEnd", moment(v).add(1, 'minutes').format("YYYY-MM-DD HH:mm"))
                          }

                          if (
                            field.date === "effectiveDateEnd" && moment(v).isSameOrBefore(moment(formik.values.effectiveDateStart))
                          ) {
                            formik.setFieldValue("effectiveDateEnd", moment(formik.values.effectiveDateStart).add(1, 'minutes').format("YYYY-MM-DD HH:mm"))
                          }
                        }}
                        InputPropStyle={{
                          borderTopLeftRadius: 0,
                          borderBottomLeftRadius: 0,
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>

                {/*Generate Code*/}
                <Grid container spacing={1} className={classes.gridContainer}>
                  {(type === "add") ? (
                    <>
                      <Grid
                        item
                        xs={5}
                        sm={2}
                        md={2}
                        style={{ width: "100%" }}
                      >
                        <Typography variant="body2" color="primary" align="center">
                          {getLang(lang, 'label.QUANTITY')} *
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={7}
                        sm={5}
                        md={5}
                        className={classes.gridFieldPeriod}
                      >
                        <InputTextField
                          required
                          variant="filled"
                          size="small"
                          type="number"
                          onPaste={(e) => InputHelper.preventCopyEDecimalAndNegetiveInNumberInput(e)}
                          onKeyDown={(e) => InputHelper.preventTypeEDecimalAndNegetiveInNumberInput(e)}
                          InputProps={{
                            inputProps: {
                              min: 1,
                              style: {
                                padding: "8px",
                                backgroundColor: "#ECEFF0",
                                borderRadius: "4px 0px 0px 4px",
                              },
                            },
                            style: {
                              padding: "4px",
                              backgroundColor: "#ECEFF0",
                              borderRadius: "4px 0px 0px 4px",
                            },
                          }}
                          FormHelperTextProps={{
                            className: classes.helperText,
                          }}
                          placeholder={getLang(lang, 'label.ENTER_AMOUNT')}
                          {...formik.getFieldProps("extensionCode")}
                          error={
                            formik.touched.extensionCode &&
                              formik.errors.extensionCode
                              ? true
                              : false
                          }
                        />
                      </Grid>
                    </>
                  ) : null}
                </Grid>
              </Box>

              {/*Extension Code Table*/}
              {type === "view" && info ? (
                <>
                  <Divider />
                  <Box p={2} px={3}>
                    <Grid container >
                      <Grid
                        item
                        xs={5}
                        sm={2}
                        md={2}
                        style={{ width: "100%" }}
                      >
                        <Typography variant="body2" color="primary" align="center">
                          {getLang(lang, 'label.QUANTITY')} *
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={5}
                        sm={5}
                        md={5}
                        className={classes.gridFieldPeriod}
                      >
                        <InputTextField
                          variant="filled"
                          size="small"
                          type="number"
                          onPaste={(e) => InputHelper.preventCopyEDecimalAndNegetiveInNumberInput(e)}
                          onKeyDown={(e) => InputHelper.preventTypeEDecimalAndNegetiveInNumberInput(e)}
                          InputProps={{
                            inputProps: {
                              min: 1,
                              style: {
                                padding: "8px",
                                backgroundColor: "#ECEFF0",
                                borderRadius: "4px 0px 0px 4px",
                              },
                            },
                            style: {
                              padding: "4px",
                              backgroundColor: "#ECEFF0",
                              borderRadius: "4px 0px 0px 4px",
                            },
                          }}
                          FormHelperTextProps={{
                            className: classes.helperText,
                          }}
                          onChange={(e) => { onAmountFieldChange(e.target.value) }}
                          placeholder={getLang(lang, 'label.ENTER_AMOUNT')}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        sm={5}
                        md={5}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          classes={{ root: classes.publishButton }}
                          disableElevation
                          type="button"
                          disabled={formik.isSubmitting || type === "edit" || !hasWarrantyEditAccessRight}
                          onClick={() => { handleGenerate({ amount: Number(amount) }) }}
                        >
                          {getLang(lang, 'label.GENERATE_CODE')}
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box p={2} px={3}>
                    <ExtensionListTableComponent
                      tagId={info.id}
                      index={index}
                      lang={lang}
                    />
                  </Box>
                </>
              ) : null}
            </Box>

            {/*Footer*/}
            <Box mt="auto">
              <Divider />
              <Box display="flex" justifyContent="space-between" px={2} pt={1} pb={1}>
                {/* {type === "add" ? (
                      <IconButton style={{ padding: 0 }} onClick={handleDelete}>
                        <DustBinSVG />
                      </IconButton>
                    ) : (
                      <span></span>
                    )} */}
                <span></span>

                <Box display="flex">
                  {
                    (type === "edit" || type === "add") && (
                      <>
                        <Button
                          disableElevation
                          type="reset"
                          onClick={() => {
                            if (type === "add") {
                              handleDelete();
                            }
                            else {
                              formik.resetForm();
                              handleCancel();
                            }
                          }}
                          style={{ marginRight: "4px" }}
                        >
                          {getLang(lang, 'label.CANCEL')}
                        </Button>
                      </>
                    )
                  }
                  {hasWarrantyEditAccessRight &&
                    <Button
                      variant="contained"
                      color="secondary"
                      classes={{ root: classes.publishButton }}
                      disableElevation
                      type="submit"
                      disabled={formik.isSubmitting}
                    >
                      {type === "add"
                        ? getLang(lang, 'label.CREATE')
                        : type === "view"
                          ? getLang(lang, 'label.EDIT')
                          : getLang(lang, 'label.DONE')}
                    </Button>
                  }
                </Box>
              </Box>
            </Box>
          </form>
        )}
      </Formik>

    </Box>
  );
}
