import { createSlice } from "@reduxjs/toolkit";
import { STOCKFLOWCOURIER, detectCourier } from "../action";

const initialState = {
  courierList: {
    list: [],
    isError: false,
  },
};

const stockflowCourierSlice = createSlice({
  name: STOCKFLOWCOURIER,
  initialState,
  reducers: {
    resetCourierList(state) {
      state.courierList = initialState.courierList;
    },
  },
  extraReducers: {
    [detectCourier.pending]: (state) => {
      state.courierList = initialState.courierList;
    },
    [detectCourier.fulfilled]: (state, action) => {
      const { payload } = action;
      state.courierList.list = payload;
    },
    [detectCourier.rejected]: (state) => {
      state.courierList.isError = true;
    },
  },
});

export const { resetCourierList } = stockflowCourierSlice.actions;

export const stockflowCourierReducer = stockflowCourierSlice.reducer;
