import React, { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import WarrantyListPanelComponent from "./warrantyListPanel.component";
import { connect, useDispatch, useSelector } from "react-redux";
import { fetchWarrantyListTable, resetRegisterForm, resetWarrantySetting, setType, resetRegisterFormType, updateWarrantyPageSetting } from "../../../redux";
import { selectLoading } from "modules/notification";
import WarrantyFormPanel from "modules/warranty/components/panel/warrantyPanel";
import { WARRANTY_LIST_ADD, WARRANTY_LIST_EDIT, WARRANTEE_LIST_VIEW } from "lib/constants/accessRights";
import { generatePaginationOptions } from "lib/helper";
import {getAllProducts} from "../../../../product-mgmt/redux/action/products.action";

const WarrantyListPanelContainer = forwardRef((_, ref) => {
  const hasWarrantyAddAccessRight = useSelector(state => state.profile.accessRights.includes(WARRANTY_LIST_ADD))
  const hasWarrantyEditAccessRight = useSelector(state => state.profile.accessRights.includes(WARRANTY_LIST_EDIT))
  const hasWarranteeViewAccessRight = useSelector(state => state.profile.accessRights.includes(WARRANTEE_LIST_VIEW))
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [search, setSearch] = useState({ searchString: "", product: [] })
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false)
  const [paginationOptions, setPaginationOptions] = useState([]);

  const dispatch = useDispatch();
  const warrantyListData = useSelector((state) => state.warranty.warrantyListTable.data);
  const length = useSelector(
    (state) => state.warranty.warrantyListTable.totalRecords,
  );
  const filteredlength = useSelector(
    (state) => state.warranty.warrantyListTable.filteredTotalRecords
  );
  const isFetching = useSelector((state) =>
    selectLoading(state, fetchWarrantyListTable.typePrefix)
  );

  const productDropdown = useSelector(state => state.products.allProducts);
  const isFetchingProduct = useSelector(state => selectLoading(state, getAllProducts.typePrefix));

  useEffect(() => {
    dispatch(getAllProducts());
  }, [dispatch]);

  useImperativeHandle(ref, () => ({
    openCreateWarrantyModal: () => {
      dispatch(setType({ index: "null", type: "create" }));
      setIsCreateDialogOpen(true);
    },
  }));

  useEffect(() => {
    dispatch(fetchWarrantyListTable({
      length: rowsPerPage,
      start: rowsPerPage * page,
      search: search.searchString,
      product: search.product.map(item => item.id),
    }));
  }, [search, rowsPerPage, page, dispatch]);

  useEffect(() => {
    setPaginationOptions(generatePaginationOptions(length))
  }, [length])

  const handleReload = () => {
    dispatch(
      fetchWarrantyListTable({
        length: rowsPerPage,
        start: rowsPerPage * page,
        search: search.searchString,
        product: search.product.map(item => item.id),
      })
    );
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage)

    dispatch(updateWarrantyPageSetting({
      start: rowsPerPage * newPage
    }))
  }

  const handleRowPerPageChange = event => {
    setPage(0);
    setRowsPerPage(event.target.value)

    dispatch(updateWarrantyPageSetting({
      start: 0,
      length: event.target.value
    }))
  }

  const handleSearch = async (values) => {
    setPage(0)
    setSearch(values)

    dispatch(
      updateWarrantyPageSetting({
        start: 0,
        search: values.searchString ?? "",
        product: values.product.map((item) => item.id),
      })
    );
  }

  const handleCreate = () => {
    if (hasWarrantyAddAccessRight) {
      dispatch(setType({ index: "null", type: "create" }));
      setIsCreateDialogOpen(true);
    }
  };

  const handleCloseCreateDialog = () => {
    dispatch(resetRegisterFormType())
    dispatch(resetWarrantySetting())
    dispatch(resetRegisterForm())
    setIsCreateDialogOpen(false)
  }

  return (
    <>
      <WarrantyListPanelComponent
        hasWarrantyAddAccessRight={hasWarrantyAddAccessRight}
        hasWarrantyEditAccessRight={hasWarrantyEditAccessRight}
        hasWarranteeViewAccessRight={hasWarranteeViewAccessRight}
        warrantyListData={warrantyListData}
        isFetching={isFetching}
        handlePageChange={handlePageChange}
        handleRowPerPageChange={handleRowPerPageChange}
        page={page}
        search={search}
        totalRecords={search ? filteredlength : length}
        rowsPerPage={rowsPerPage}
        handleSearch={handleSearch}
        handleCreate={handleCreate}
        handleReload={handleReload}
        paginationOptions={paginationOptions}
        productDropdown={productDropdown}
        isFetchingProduct={isFetchingProduct}
      />
      <WarrantyFormPanel
        isOpen={isCreateDialogOpen}
        handleClose={handleCloseCreateDialog}
      />
    </>
  );
});

export default connect(null, null, null, { forwardRef: true })(
  WarrantyListPanelContainer
);
