import React from 'react'
import { ErrorMessage, Formik } from 'formik'
import * as Yup from 'yup'
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Typography,
  InputAdornment,
  FormHelperText
} from '@material-ui/core';
import InputTextField from 'components/input/inputTextField';
import {
  Close as CloseIcon
} from '@material-ui/icons'
//import { Skeleton } from '@material-ui/lab';
import ModuleTable from "../../table/moduleTable";
import ModuleSelect from "../../select/moduleSelect";
import clsx from "clsx";
import DateTimePicker from "components/input/dateTimePicker";
import moment from "moment";
import "moment/min/locales.min";
import { convertToBigNum } from "lib/generalUtility";
import { getLang } from 'app/feature/constants';

const useStyle = makeStyles(theme => ({
  root: {
    boxShadow: theme.shadows[5],
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "space-between",
    width: 700,
    borderRadius: "4px",
  },
  detailsWrapper: {
    padding: "25px 20px",
    height: 350,
    overflowY: "auto",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  discardButton: {
    color: theme.palette.error.main
  },
  dateView: {
    paddingRight: theme.spacing(1)
  },
  dateContainer: {
    height: 43,
    backgroundColor: "#ECEFF0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 60,
    borderRadius: "4px 0px 0px 4px"
  },
  gridFieldPeriod: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    paddingBottom: "7px !important"
  },
  divider: {
    margin: "6px 0px"
  },
  dividerEdit: {
    height: "70%"
  },
  dividerView: {
    margin: "0px"
  },
  dividerContainer: {
    backgroundColor: "#ECEFF0",
    display: "flex"
  },
  gridContainer: {
    minHeight: "50px",
    paddingTop: theme.spacing(1)
  },
  gridContainereView: {
    paddingBottom: theme.spacing(2)
  },
  gridEditField: {
    paddingTop: "16px !important"
  },
  gridViewField: {
    paddingTop: "10px !important"
  },
  title: {
    columnGap: "5px"
  },
  iconButton: {
    padding: "unset"
  },
  headerText: {
    fontWeight: "bold"
  },
  bonanzaPointText: {
    fontWeight: "bold",
    marginTop: "5px"
  }
}));

export default function BonanzaPointFormComponent({
  hasBonanzaAddAccessRight,
  hasBonanzaEditAccessRight,
  bonanzaPoint,
  handleClose,
  handleSubmit,
  bonanzaPointType,
  language,
  changeToUpdate,
  headerType,
  bonanza,
  lang,
  changeBackView
}) {
  const classes = useStyle()
  
  if(language==="ZH")
  {
    moment.locale('zh-cn')
  }else if(language==="TL")
  {
    moment.locale('tl-ph')
  }else
  {
    moment.locale(language)
  }
;
  const viewType = bonanzaPointType === "view" ? true : false;

  const renderInputField = (item, index, formik) => {
    switch (item.type) {
      case "number":
        return (
          <>
            <Grid item xs={true} sm={true} md={true}>
              {viewType ? (
                <Typography
                  variant="body2"
                  color="primary"
                  className={classes.bonanzaPointText}
                >
                  {convertToBigNum(formik.getFieldProps(item.name).value)}
                </Typography>
              ) : (
                <InputTextField
                  disabled={formik.isSubmitting}
                  required
                  variant="filled"
                  size="small"
                  fullWidth
                  type={item.type}
                  inputProps={{
                    min: 1,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" style={{ padding: 8 }}>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                        >
                          %
                        </Typography>
                      </InputAdornment>
                    )
                  }}
                  placeholder={item.placeholder}
                  {...formik.getFieldProps(item.name)}
                  error={
                    formik.touched[item.name] && formik.errors[item.name]
                      ? true
                      : false
                  }
                  helperText={ErrorMessage({
                    name: item.name,
                  })}
                />
              )}
            </Grid>
            <Grid item xs={true} sm={true} md={true} />
          </>
        );
      case "select":
        return (
          <Grid item xs={8} sm={9} md={9}>
            {!viewType &&
              <ModuleSelect
                disabled={formik.isSubmitting || viewType}
                value={formik.values.modules}
                required
                handleChange={(value) => formik.setFieldValue("modules", value)}
                bonanza={bonanza}
              />}
            <FormHelperText
              style={{ margin: "4px 14px 0px" }}
              error={formik.errors[item.name] ? true : false}
            >
              {ErrorMessage({ name: item.name })}
            </FormHelperText>
            <ModuleTable tableData={formik.values.modules} bonanza={bonanza} percent={formik.getFieldProps("point").value} />
          </Grid>
        );
      case "date":
        return [
          {
            label: getLang(lang, "label.FROM"),
            date: "startAt"
          },
          {
            label: getLang(lang, "label.TO"),
            date: "endAt"
          },
        ].map((field, index) => (
          <Grid item xs={8} sm={true} md={true} key={index} className={classes.gridFieldPeriod}>
            <Box display="flex">
              <Box
                className={clsx({
                  [classes.dateView]: viewType,
                  [classes.dateContainer]: !viewType
                })}
              >
                <Typography
                  variant="subtitle2"
                  style={{
                    fontWeight: "bold",
                    color: " rgba(58, 77, 84, 0.33)"
                  }}
                >
                  {field.label}
                </Typography>
              </Box>
              <Box className={classes.dividerContainer}>
                <Divider
                  orientation="vertical"
                  className={clsx(classes.divider, {
                    [classes.dividerEdit]: !viewType,
                    [classes.dividerView]: viewType
                  })}
                />
              </Box>
            </Box>
            {viewType ? (
              <Typography
                variant="body2"
                color="primary"
                style={{
                  fontWeight: "bold",
                  paddingLeft: "16px"
                }}
              >
                {moment(formik.getFieldProps(field.date).value).format('lll')}
              </Typography>
            ) : (
              <DateTimePicker
                minDate={
                  field.label === getLang(lang, "label.TO")
                    ? moment(formik.values.eventPeriodStart)
                    : new Date()
                }
                required
                label=""
                disabled={formik.isSubmitting || (field.label === getLang(lang, "label.FROM") && moment(bonanzaPoint.startAt).isBefore(moment()))}
                disableFuture={false}
                id={field.label + "-picker-dialog"}
                value={formik.getFieldProps(field.date).value}
                handleChange={(v) => {
                  formik.setFieldValue(field.date, v.format("YYYYY-MM-DD HH:mm"))
                  if (
                    field.date === "startAt" && moment(v).isSameOrAfter(moment(formik.values.endAt))
                  ) {
                    formik.setFieldValue("endAt", moment(v).add(1, 'minutes').format("YYYY-MM-DD HH:mm"))
                  }

                  if (
                    field.date === "endAt" && moment(v).isSameOrBefore(moment(formik.values.startAt))
                  ) {
                    formik.setFieldValue("endAt", moment(formik.values.startAt).add(1, 'minutes').format("YYYY-MM-DD HH:mm"))
                  }
                }}
                InputPropStyle={{
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0
                }}
              />
            )}
          </Grid>
        ));
      default:
        return null;
    }

  };
  return (
    <Paper elevation={0} className={classes.root}>
      <Box p={2} className={classes.header}>
        <Typography variant="subtitle2" color="primary" className={classes.headerText}>
          {headerType === "create" ? getLang(lang, "label.ADD_LOWERCASE") :
            headerType === "edit" ? getLang(lang, "label.EDIT") :
              getLang(lang, "label.VIEW")} {getLang(lang, "label.BONANZA_LOWERCASE")}
        </Typography>
        <IconButton color="primary" onClick={handleClose} className={classes.iconButton}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>
      <Divider />
      <Formik
        enableReinitialize
        initialValues={{
          ...bonanzaPoint,
          point: bonanzaPoint.point || "",
          modules: bonanzaPoint.modules,
          startAt: bonanzaPoint.startAt
            ? bonanzaPoint.startAt
            : new moment().format("YYYY-MM-DD HH:mm"),
          endAt: bonanzaPoint.endAt
            ? bonanzaPoint.endAt
            : new moment().add(7, "day").format("YYYY-MM-DD HH:mm")
        }}
        validationSchema={Yup.object({
          point: Yup.string().required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
          modules: Yup.string().required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
          startAt: Yup.date().required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
          endAt: Yup.date().required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED"))
        })}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <Box className={classes.detailsWrapper}>
              {[
                {
                  title: getLang(lang, "label.BONANZA"),
                  requiredText: "*",
                  name: "point",
                  placeholder: getLang(lang, "placeholder.ENTER_POINT_HERE"),
                  type: "number",
                },
                {
                  title: getLang(lang, "label.PERIOD"),
                  requiredText: "*",
                  type: "date",
                },
                {
                  title: getLang(lang, "label.ASSIGN_MODULE"),
                  requiredText: "*",
                  name: "modules",
                  placeholder: getLang(lang, "placeholder.SELECT_MODULE"),
                  type: "select",
                }
              ].map((item, index) => (
                <Grid container key={index} spacing={1}
                  className={clsx(classes.gridContainer, {
                    [classes.gridContainerView]: viewType,
                  })}
                >
                  <Grid item xs={3} sm={3} md={3}
                    className={clsx({
                      [classes.gridEditField]: !viewType,
                      [classes.gridViewField]: viewType,
                    })}>
                    <Box display="flex" className={classes.title}>
                      <Typography variant="body2" color="primary">
                        {item.title}
                      </Typography>
                      <Typography variant="body2" color="error">
                        {item.requiredText}
                      </Typography>
                    </Box>
                  </Grid>
                  {renderInputField(item, index, formik)}
                </Grid>
              ))}
            </Box>
            {
              (headerType === "create" && hasBonanzaAddAccessRight) ||
                (headerType === "view" && hasBonanzaEditAccessRight) ||
                (headerType === "edit" && hasBonanzaEditAccessRight)
                ?
                <>
                  <Divider />
                  <Box p={2} flexGrow={1} display="flex" alignItems="center" justifyContent="flex-end">
                    {!viewType ? (
                      <Button
                        disabled={formik.isSubmitting}
                        onClick={(e) => {
                          formik.resetForm()
                          headerType === "edit" ?
                            changeBackView(e) :
                            handleClose(e)
                        }}
                        type="button"
                        style={{ marginRight: "4px" }}
                      >
                        {getLang(lang, "label.CANCEL")}
                      </Button>
                    ) : (
                      ""
                    )}
                    <Button
                      variant="contained"
                      color="secondary"
                      disableElevation
                      disabled={formik.isSubmitting}
                      type={viewType ? "button" : "submit"}
                      onClick={viewType ? changeToUpdate : null}
                    >
                      {bonanzaPointType === "create"
                        ? (formik.isSubmitting ? getLang(lang, "label.ADDING") : getLang(lang, "label.ADD"))
                        : bonanzaPointType === "edit"
                          ? getLang(lang, "label.SAVE")
                          : getLang(lang, "label.EDIT")}
                    </Button>
                  </Box>
                </>
                : null
            }
          </form>
        )}
      </Formik>
    </Paper >
  )
}