import axios from "axios";

const HqEditBranchDropdown = async ({
  uuid
}) => {

    const response = await axios.get(
        `account/api/v1/nodes/hq-edit-branch-dropdown/${uuid}`,
       
    );

    return response.data;
};

export default HqEditBranchDropdown;