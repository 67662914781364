import heic2any from 'heic-convert';
import { isFile } from './helper';
import { allowedFileType } from './constants/aws_s3';

export const toBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  })
}

export const convertHeicToJPG = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsArrayBuffer(file);
    reader.onload = async () => {
      const arrayBuffer = new Uint8Array(reader.result);
      const jpegData = await heic2any({ buffer: arrayBuffer, format: 'JPEG' });
      const jpegBlob = new Blob([jpegData], { type: 'image/jpeg' });
      let base64Image = await toBase64(jpegBlob);

      resolve(base64Image)
    }
    reader.onerror = (err) => reject(err);
  })
}

export function isValidFile(file) {
  if (!isFile(file)) {
    return false;
  }

  if (!allowedFileType.includes(file.type)) {
    return false;
  }

  return true;
}

export async function setImgFormatAndSize(file, width = null, height = null, format = null) {
  const img = new Image();
  img.src = file;

  const imageLoadPromise = new Promise((resolve, reject) => {
    img.onload = () => resolve();
    img.onerror = (_) => reject(_);
  });

  await imageLoadPromise;

  const canvas = document.createElement("canvas");
  canvas.width = width ?? img.width;
  canvas.height = height ?? img.height;

  const ctx = canvas.getContext('2d');
  ctx.drawImage(img, 0, 0, width ?? img.width, height ?? img.height);

  return canvas.toDataURL(format ?? "image/webp");
}

export async function getResizeDimension(file, maxWidth, maxHeight) {
  const img = new Image();
  img.src = file;

  const imageLoadPromise = new Promise((resolve, reject) => {
    img.onload = () => resolve();
    img.onerror = (_) => reject(_);
  });

  await imageLoadPromise;

  const aspectRatio = img.width / img.height;
  let scaledWidth, scaledHeight;

  if (img.width > maxWidth || img.height > maxHeight) {
    if (img.width / maxWidth > img.height / maxHeight) {
      scaledWidth = maxWidth;
      scaledHeight = maxWidth / aspectRatio;
    }
    else {
      scaledHeight = maxHeight;
      scaledWidth = maxHeight * aspectRatio;
    }
  }
  else {
    scaledWidth = img.width;
    scaledHeight = img.height;
  }

  return [scaledWidth, scaledHeight];
}
