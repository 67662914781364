import axios from "axios"

const updateBulkProductStatus = ({
filter,
update
}) => {
  return new Promise((resolve, reject) => {
    axios.post("/serialnumber/api/v1/product/bulk-update-status", {
      filter: filter,
      update: update
    })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data))
  });
};

export default updateBulkProductStatus;