import { getApiLang } from "app/feature/constants";
import { setLoading, addAlert } from "modules/notification";
import { detectCourier } from "../action";

const detectCourierMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case detectCourier.pending.type:
        dispatch(setLoading({ id: detectCourier.typePrefix, state: true }));
        break;
      case detectCourier.fulfilled.type:
        dispatch(setLoading({ id: detectCourier.typePrefix, state: false }));
        break;
      case detectCourier.rejected.type:
        const lang = getState().constant.languages;
        dispatch(setLoading({ id: detectCourier.typePrefix, state: false }));
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_GET_COURIER_LIST"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

export default [detectCourierMiddleware];
