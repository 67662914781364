import axios from "axios";

const post = (parameters) => {
  const body = {
    prizes: parameters.prizes,
  };
  return new Promise((resolve, reject) => {
    axios
      .post(
        `/campaign/api/admin/lucky-draws/${parameters.id}/set-winners`,
        body
      )
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

const saveWinner = {
  post
};

export default saveWinner;
