import React from "react";
import BarChartComponent from "./barChart.component";
import { useSelector } from "react-redux";

export default function BarChartContainer({ data, labels }) {
  const lang = useSelector(state => state.constant.languages);

  return (
    <BarChartComponent data={data} labels={labels} lang={lang}/>
  )
}