import React from "react";
import {
  makeStyles,
  Box,
  Typography,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TablePagination,
  Paper,
} from "@material-ui/core";
import { StyledTableCell, StyledTableRow } from "components/table";
import { SkeletonTableRow } from "components/skeleton/skeleton.component";
import RefreshTable from "components/table/refreshTable";
import { KeyboardArrowDown } from "@material-ui/icons";
import { getLang } from "app/feature/constants";
import moment from "moment";
import { TablePaginationActions } from "components/tablePagination";
import ImportAdtInfoStatusProgress from "../../progress/importAdtInfoStatusProgress";

const useStyle = makeStyles((theme) => ({
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    borderCollapse: "separate",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
    },
    "& th,td": {
      fontSize: ".75rem",
      borderRight: "1px solid rgba(58, 77, 84, 0.1)",
      padding: theme.spacing(1, 2, 1, 2),
    },
    "& th": {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightBold,
    },
    "& th:last-child, td:last-child": {
      width: 37,
      right: "-2px",
    },
    "& th th": {
      borderBottom: "none !important",
    },
    "& td td": {
      borderBottom: "none !important",
    },
  },
  emptyContainer: {
    backgroundColor: theme.palette.background.paper,
    textAlign: "center",
  },
}));

export default function AdtInfoImportLogTableComponent({
  rows,
  isFetching,
  isError,
  handleReload,
  handleChangePage,
  handleChangeRowsPerPage,
  totalFiltered,
  paginationOptions,
  tableFilter,
  changeOrder,
  lang,
}) {
  const classes = useStyle();

  return (
    <>
      {!isFetching && isError ? (
        <RefreshTable handleReload={handleReload} />
      ) : rows.length > 0 || isFetching ? (
        <Paper id="test" style={{ overflowX: "auto", width: "100%" }}>
          <TableContainer>
            <Table
              className={classes.table}
              aria-label="import table"
              size="small"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell>
                    <TableSortLabel
                      active={true}
                      direction={tableFilter.order}
                      onClick={() => {
                        const order =
                          tableFilter.order === "asc" ? "desc" : "asc";
                        changeOrder(order);
                      }}
                      IconComponent={KeyboardArrowDown}
                    >
                      {getLang(lang, "label.CREATED_DATE")}
                    </TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.TOTAL_UPDATED_SERIAL_NUMBER")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.STATUS")}
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isFetching
                  ? [...new Array(5)].map((v, index) => (
                      <SkeletonTableRow key={index} columnCount={3} />
                    ))
                  : rows.map((row, index) => {
                      return (
                        <StyledTableRow
                          key={index}
                          hover
                          role="checkbox"
                          tabIndex={-1}
                        >
                          <StyledTableCell>
                            {moment(row.datetime).format("DD MMM YYYY, HH:mm")}
                          </StyledTableCell>
                          <StyledTableCell>{row.total_import}</StyledTableCell>
                          <StyledTableCell>
                            <ImportAdtInfoStatusProgress
                              importUuid={row.id}
                              initialProgress={row.progress}
                              initialStatus={row.status}
                            />
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            style={{
              backgroundColor: "#fff",
              boxShadow: "0px -1px 0px rgba(58, 77, 84, 0.15)",
            }}
            ActionsComponent={TablePaginationActions}
            rowsPerPageOptions={paginationOptions}
            component="div"
            count={totalFiltered}
            rowsPerPage={tableFilter.rowsPerPage}
            page={tableFilter.page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage={getLang(lang, "label.ROWS_PER_PAGE")}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} ${getLang(lang, "label.OF")} ${count}`
            }
          />
        </Paper>
      ) : !isFetching && rows.length === 0 ? (
        <Paper id="test" style={{ overflowX: "auto", width: "100%" }}>
          <TableContainer>
            <Table
              className={classes.table}
              aria-label="import table"
              size="small"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell>
                    {getLang(lang, "label.CREATED_DATE")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.TOTAL_UPDATED_SERIAL_NUMBER")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.STATUS")}
                  </StyledTableCell>
                </TableRow>
              </TableHead>
            </Table>
            <Box py={5} className={classes.emptyContainer}>
              <Typography variant="body1">
                {getLang(lang, "message.info.NO_IMPORT_LOG")}
              </Typography>
            </Box>
          </TableContainer>
        </Paper>
      ) : null}
    </>
  );
}
