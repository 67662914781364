import React from "react";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { makeStyles } from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
  underline: {
    "&:before": {
      borderBottom: "2px solid",
      borderBottomColor: theme.palette.primary.main
    }
  },
  button: {
    padding: 0,
    color: theme.palette.primary.main,
    size: "small"
  }
}));

export default function DatePickerComponent({
  disabled,
  id,
  value,
  handleChange,
  format,
  okLabel,
  cancelLabel,
  disableFuture,
  error,
  helperText,
  label,
  InputPropStyle,
  inputStyle,
  minDate,
  maxDate,
  ...props
}) {
  const classes = useStyle();

  return (
    <MuiPickersUtilsProvider utils={MomentUtils} >
      <KeyboardDatePicker
        margin="none"
        fullWidth
        label={label}
        disabled={disabled}
        disableFuture={disableFuture}
        id={id}
        value={value === "" ? null : value}
        onChange={handleChange}
        format={format}
        InputProps={{
          disableUnderline: true,
          style: {
            padding: "6px",
            backgroundColor: "#ECEFF0",
            borderRadius: "4px",
            height: "43px",
            ...InputPropStyle
          },
          readOnly: true
        }}
        KeyboardButtonProps={{
          classes: {
            root: classes.button
          }
        }}
        inputProps={{
          style: {
            fontSize: "14px",
            fontWeight: "bold",
            ...inputStyle
          }
        }}
        okLabel={okLabel}
        cancelLabel={cancelLabel}
        error={error}
        helperText={helperText}
        minDate={minDate}
        maxDate={maxDate}
        {...props}
      />
    </MuiPickersUtilsProvider>
  );
}
