import axios from "axios";

const getAdtInfoImportLog = (parameters) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/serialnumber/api/v1/additional-info/import-log`, {
        params: {
          start: parameters.start,
          length: parameters.length,
          order: parameters.order,
        },
      })
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

export default getAdtInfoImportLog;
