import React, { useEffect, useState } from "react";
import {
  ThemeProvider,
  createMuiTheme,
  responsiveFontSizes
} from "@material-ui/core/styles";
import { useThemes } from "../../assets/jss/theme";
import { useSelector } from "react-redux";

const withTheme = Component => props => {
  const [didMount, setDidMount] = useState(false);
  const [theme, toggleThemes] = useThemes();
  const currentTheme = useSelector(state => state.setting.theme);
  const customColor = useSelector(state => state.setting.customColor);

  useEffect(() => {
    if (didMount) {
      toggleThemes(currentTheme, customColor);
    }
    // eslint-disable-next-line
  }, [currentTheme, customColor]);

  useEffect(() => {
    setDidMount(true);
  }, []);

  const appTheme = responsiveFontSizes(createMuiTheme(theme));

  return (
    <ThemeProvider theme={appTheme}>
      <Component {...props} />
    </ThemeProvider>
  );
};

export default withTheme;
