import axios from "axios";

const url = "/serialnumber/api/v1/product/brand-models/create";

export function addBrandModel({
  name,
  models,
}) {
  return new Promise((resolve, reject) => {
    axios.post(url, {
      name,
      models,
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data))
  })
}
