import React from "react";
import {
  makeStyles,
  Box,
  Grid,
  Button,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
} from "@material-ui/core";

import Dialog from "components/dialog/customDialog";

import { getLang } from "app/feature/constants";
import { Formik } from "formik";
import * as Yup from "yup";

const style = (theme) => ({
  infoContainer: {
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    }
  },
  header: {
    paddingTop: "4px",
    paddingLeft: theme.spacing(3),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  indicator: {
    border: "0.5px solid",
    borderColor: theme.palette.primary.main
  },
  scroll: {
    width: "min-content"
  },
  rejectButton: {
    color: "white",
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
});

const useStyles = makeStyles(style);

export default function RejectReimbursementDialogComponent({
  lang,
  isOpen,
  reasons,
  handleSubmit,
  handleClose,
}) {
  const classes = useStyles();

  return (
    <Dialog
      isOpen={isOpen}
      handleClose={handleClose}
      disableEnforceFocus={true}
      disableAutoFocus={true}
      disableEscapeKeyDown={true}
      disableBackdropClick={true}
      PaperProps={{ style: { borderRadius: "10px" } }}
    >
      <Box p={4} py={3}>
        <Typography>
          <Box fontWeight="fontWeightBold" textAlign="left">
            {getLang(lang, "paragraph.REJECT_REIMBURSEMENT_DIALOG_TITLE")}
          </Box>
          <Box fontcolor="text.secondary" textAlign="left" fontSize="fontSize" pt={1}>
            {getLang(lang, "paragraph.REJECT_REIMBURSEMENT_DIALOG_CONTENT")}
          </Box>

          <Formik
            initialValues={{
              selected: null,
              otherReason: "",
            }}
            validationSchema={Yup.object().shape({
              selected: Yup.string().nullable().required(getLang(lang, "message.error.ERROR_REJECT_REASON_NOT_SELECT")),
            })}
            onSubmit={handleSubmit}
          >
            {(formik) => {
              return (
                <form onSubmit={formik.handleSubmit}>
                  <Box pt={2} pb={3}>
                    {formik.errors.selected && <Box color={"error.main"}>{formik.errors.selected}</Box>}

                    <RadioGroup
                      name="selected"
                      value={formik.values.selected}
                      onChange={formik.handleChange}
                    >
                      {reasons.map((reason) => (
                        <FormControlLabel
                          value={reason}
                          control={<Radio />}
                          label={reason}
                          onChange={() => formik.setValues({ otherReason: "" })}
                        />
                      ))}
                      <FormControlLabel
                        value="others"
                        control={<Radio />}
                        label={getLang(lang, "label.OTHERS")}
                        onChange={() => formik.setValues({ otherReason: "" })}
                      />
                    </RadioGroup>
                    <Box pt={1} />
                    <TextField
                      disabled={formik.values.selected !== "others"}
                      multiline
                      fullWidth
                      rows={4}
                      maxRows={8}
                      name="otherReason"
                      value={formik.values.otherReason}
                      placeholder={getLang(lang, "placeholder.REASON")}
                      variant="outlined"
                      onChange={formik.handleChange}
                    />
                    <Box pt={1} />

                    <Grid container>
                      <Grid item xs={6}>
                        <Box display="flex" justifyContent="center" pr={1}>
                          <Button
                            size="large"
                            variant="outlined"
                            fullWidth={true}
                            onClick={handleClose}
                          >
                            {getLang(lang, "label.CANCEL")}
                          </Button>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box display="flex" justifyContent="center" pl={1}>
                          <Button
                            size="large"
                            classes={{ root: classes.rejectButton }}
                            variant="contained"
                            fullWidth={true}
                            type="submit"
                          >
                            {getLang(lang, "label.REJECT")}
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </form>
              );
            }}
          </Formik>
        </Typography>
      </Box>
    </Dialog>
  );
}