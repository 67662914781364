import React from "react";
import { Box, Typography, Divider, Button } from "@material-ui/core";
import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";
import { AddIcon } from "components/icon";
import { getLang } from "app/feature/constants";
import { useHistory } from "react-router";
import StockTakeListing from "../stock-take-listing";
import { STOCK_TAKE_CREATE_PAGE } from "modules/stockflow/config/stockflow.route";

export default function MainPageComponent({
  lang,
  hasStockflowEditAccessRight,
}) {
  const history = useHistory();
  return (
    <Box>
      <Box gridArea="topbar">
        <Box
          mb={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="flex" alignItems="flex-end" fontWeight="bold">
            <Typography variant="h6" color="primary">
              Trackco
            </Typography>
            <Box mx={0.5}>
              <ChevronRightRoundedIcon color="disabled" />
            </Box>
            <Box>
              <Typography variant="h6" color="primary">
                {getLang(lang, "label.STOCK_TAKE")}
              </Typography>
            </Box>
          </Box>
          {hasStockflowEditAccessRight ? (
            <Button
              variant="text"
              color="primary"
              startIcon={
                <AddIcon color="secondary" style={{ fontSize: "13px" }} />
              }
              disableElevation
              onClick={() => {
                history.push(STOCK_TAKE_CREATE_PAGE);
              }}
            >
              {getLang(lang, "label.STOCK_TAKE")}
            </Button>
          ) : null}
        </Box>
        <Divider />
      </Box>

      <Box gridArea="panel" mt={2}>
        <StockTakeListing />
      </Box>
    </Box>
  );
}
