import { createSlice } from "@reduxjs/toolkit";

export const MARKETPLACE = "marketplace";

const initialState = {
  qrCode: {
    quantity: 0,
    couponCode: "",
    calculation: {}
  },
  discountCalculation: null,
  selectedPaymentCardId: "",
  subsciptionPlans: [],
};

const marketplaceSlice = createSlice({
  name: MARKETPLACE,
  initialState,
  reducers: {
    setQrCouponCode(state, action) {
      state.qrCode.couponCode = action.payload.couponCode;
      state.discountCalculation = action.payload.calculation;
    },
    confirmQrPurchaseQty(state, action) {
      state.qrCode.quantity = action.payload.quantity;
      state.qrCode.calculation = action.payload.calculation;
    },
    setSelectedPaymentCardId(state, action) {
      state.selectedPaymentCardId = action.payload;
    },
    resetCreditPurchase(state, action) {
      state.qrCode.couponCode = "";
      state.discountCalculation = null;
      state.qrCode.quantity = 0;
      state.qrCode.calculation = {};
      state.selectedPaymentCardId = "";
    },
    setQrCodeQty(state, action) {
      state.qrCode.quantity = action.payload;
    },
    setSubscriptionPlans(state, action) {
      state.subsciptionPlans = action.payload;
    }
  }
});

export const marketplaceReducer = marketplaceSlice.reducer;
export const {
  setQrCouponCode,
  confirmQrPurchaseQty,
  setSelectedPaymentCardId,
  resetCreditPurchase,
  setQrCodeQty,
  setSubscriptionPlans
} = marketplaceSlice.actions;
