import React from "react";
import { useSelector } from "react-redux";
import LoginSuccessPage from "./login-success.page";

function LoginSuccessContainer() {
  const branch = useSelector(state => state.trackco.inviter.branch);

  return (
    <LoginSuccessPage branchDetail={branch} />
  )
}

export default LoginSuccessContainer;