import React, { useState } from "react";
import QRCodeCreditComponent from "./QRCodeCredit.component";
import {
  EnterQtyIconSvg,
  PaymentSummaryIconSvg,
  CheckoutIconSvg
} from "../../../utils/static";
import CardForm from "../../forms/cardForm";
import SummaryForm from "../../forms/summaryForm";
import QrTab from "../../tab";
import { connect, useSelector } from "react-redux";
import { resetCreditPurchase } from "../../../redux";
import { getLang } from "app/feature/constants";

function getSteps(lang) {
  const EnterQtyIconSvgWhite = () => (
    <EnterQtyIconSvg color="rgba(58, 77, 84, 0.33)" />
  );

  const PaymentSummaryIconSvgWhite = () => (
    <PaymentSummaryIconSvg color="rgba(58, 77, 84, 0.33)" />
  );

  const CheckoutIconSvgWhite = () => (
    <CheckoutIconSvg color="rgba(58, 77, 84, 0.33)" />
  );

  let steps = [
    {
      form: QrTab,
      labelImage: EnterQtyIconSvgWhite,
      activeImage: EnterQtyIconSvg,
      label: getLang(lang, 'label.ENTER_QUANTITY')
    },
    {
      form: SummaryForm,
      labelImage: PaymentSummaryIconSvgWhite,
      activeImage: PaymentSummaryIconSvg,
      label: getLang(lang, 'label.CONFIRM_AND_CHECKOUT')
    },
    {
      form: CardForm,
      labelImage: CheckoutIconSvgWhite,
      activeImage: CheckoutIconSvg,
      label: getLang(lang, 'label.RESULT')
    }
  ];
  return steps;
}

function QRCodeCreditContainer({ handleCancel, resetCreditPurchase }) {
  const lang = useSelector(state => state.constant.languages);
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps(lang);

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleCancelPurchase = () => {
    resetCreditPurchase();
    handleCancel();
  };

  return (
    <QRCodeCreditComponent
      steps={steps}
      activeStep={activeStep}
      handleNext={handleNext}
      handleBack={handleBack}
      handleCancel={handleCancelPurchase}
      handleReset={handleReset}
      lang={lang}
    />
  );
}

const mapDispatchToProps = dispatch => ({
  resetCreditPurchase: () => dispatch(resetCreditPurchase())
});

export default connect(null, mapDispatchToProps)(QRCodeCreditContainer);
