import React from "react";
import {
  Box,
  Button,
  makeStyles,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Checkbox,
  Chip
} from "@material-ui/core";
import { StyledTableCell, StyledTableRow } from "components/table";
import AntSwitch from 'components/switch/switch';
import clsx from 'clsx';
import { SkeletonTableRow } from "components/skeleton/skeleton.component"
import { ActionButton } from "components/button";
import { TablePaginationActions } from "components/tablePagination";
import RefreshTable from 'components/table/refreshTable';
import SelectAllCheckBox from 'components/selectAllCheckbox';
import { getLang } from "app/feature/constants";
import StickyLeftTableCell from "components/table/stickyLeftTableCell";
import StickyRightTableCell from "components/table/stickyRightTableCell";
import BrandImage from "../../icon/brandImage";


const useStyle = makeStyles(theme => ({
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    borderCollapse: "separate",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
    },
    "& th,td": {
      fontSize: ".75rem",
      borderRight: "1px solid rgba(58, 77, 84, 0.1)",
      padding: theme.spacing(1, 2, 1, 2),
    },
    "& th": {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightBold,
    },
    "& th:last-child, td:last-child": {
      width: 37,
      borderRight: "0px",
    },
    "& th:first-child, td:first-child": {
      width: 37,
    },
    "& th th": {
      borderBottom: "none !important",
    },
    "& td td": {
      borderBottom: "none !important",
    },
  },
  tableContainer: {
    maxHeight: 500,
    overflowY: "auto"
  },
  emptyContainer: {
    backgroundColor: theme.palette.background.paper,
    textAlign: "center"
  },
  button: {
    display: "flex",
    alignItems: "center",
    padding: 0,
    minWidth: 0,
    marginLeft: theme.spacing(1)
  },
  iconBtn: {
    borderRadius: theme.spacing(0.5),
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shortest,
    }),
    padding: "3px 5px"
  },
  pendingColor: {
    color: "#FFBF00"
  },
  activeColor: {
    color: "#4BCB0F"
  },
  deactiveColor: {
    color: "#9CA6A9"
  },
  selectAll: {
    '&.Mui-checked': {
      color: "#FDB022",
    },
  },
  selectOne: {
    '&.Mui-checked': {
      color: "#6AAF68",
    },
  }
}));

export default function BrandModelListTableComponent({
  hasProductBrandModelEditAccessRight,
  hasProductBrandModelDeleteAccessRight,
  data,
  isFetching,
  isSelectAll,
  selectedIds,
  paginationOptions,
  isLoadingBrandModelError,
  search,
  handleReload,
  page,
  rowsPerPage,
  totalFiltered,
  handleChangePage,
  handleChangeRowsPerPage,
  handleChangeStatus,
  handleEditBrandModel,
  handleCreateBrandModel,
  handleDeleteBrandModel,
  handleSelectBrandModel,
  handleSelectAvailable,
  handleSelectAll,
  handleDeselectAll,
  lang
}) {
  const classes = useStyle()

  const selectAllItems = [
    {
      label: getLang(lang, "paragraph.SELECT_AVAILABLE_ITEM", { count: data.filter((brand) => brand.id !== "-1").length }),
      action: () => handleSelectAvailable()
    },
    {
      label: getLang(lang, "paragraph.SELECT_ALL_BRANDS"),
      action: () => handleSelectAll()
    },
    {
      label: getLang(lang, "paragraph.DESELECT_ALL_BRANDS"),
      action: () => handleDeselectAll(),
      divider: true
    },
  ]

  return (
    <>
      {data.length > 0 || isFetching ? (
        <TableContainer>
          <Table className={classes.table} size="small" stickyHeader>
            <TableHead style={{ height: "45px" }}>
              <TableRow>
                <StickyLeftTableCell>
                  <StyledTableCell padding='checkbox'>
                    <SelectAllCheckBox
                      actionItems={selectAllItems}
                      disabled={isFetching}
                      isSelectAll={isSelectAll}
                      selectedIdLength={selectedIds.length}
                      totalFiltered={totalFiltered}
                    />
                  </StyledTableCell>
                </StickyLeftTableCell>
                <StyledTableCell>{getLang(lang, "label.BRAND")}</StyledTableCell>
                <StyledTableCell style={{ width: '60%' }}>{getLang(lang, "label.MODEL")}</StyledTableCell>
                <StyledTableCell style={{ width: "0px" }}>{getLang(lang, "label.ASSIGNED_PRODUCT")}</StyledTableCell>
                <StyledTableCell style={{ width: "0px" }}>{getLang(lang, "label.STATUS")}</StyledTableCell>
                <StickyRightTableCell>
                  <StyledTableCell style={{ width: "67px", textAlign: "center" }}>{getLang(lang, "label.ACTION")}</StyledTableCell>
                </StickyRightTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isFetching ? (
                [...new Array(5)].map((i, index) => (
                  <SkeletonTableRow key={index} columnCount={6} />
                ))
              ) : data.map((item, index) => {
                const actionItems = [];

                actionItems.push(
                  {
                    label: getLang(lang, "label.EDIT"),
                    disabled: !hasProductBrandModelEditAccessRight,
                    action: () => {
                      handleEditBrandModel(true, item)
                    }
                  },
                  {
                    label: getLang(lang, "label.DELETE"),
                    divider: true,
                    errorText: true,
                    action: () => handleDeleteBrandModel(item.id),
                    disabled: !hasProductBrandModelDeleteAccessRight || item.productCount > 0 || item.id === "-1",
                    tooltip: item.id === "-1" ? getLang(lang, "tooltips.CANNOT_DELETE_BRAND_DEFAULT") 
                      : item.productCount > 0 ? getLang(lang, "tooltips.CANNOT_DELETE_BRAND", { count: item.productCount }) : null
                  }
                )
                return (
                  <StyledTableRow key={index} hover tabIndex={-1}>
                    <StickyLeftTableCell>
                      <StyledTableCell padding='checkbox'>
                        <Checkbox
                          className={clsx({
                            [classes.selectAll]: isSelectAll,
                            [classes.selectOne]: item.isSelected && isSelectAll === false
                          })}
                          disabled={item.id === "-1"}
                          checked={item.isSelected}
                          onChange={(event) => {
                            handleSelectBrandModel(index, event.target.checked)
                          }}
                        />
                      </StyledTableCell>
                    </StickyLeftTableCell>
                    <StyledTableCell>
                      <Box display="flex" alignItems="center">
                        <Box pr={1}>
                          <BrandImage url={item.imageUrl} imageSize="40px" iconSize={22}/>
                        </Box>
                        <Box>
                          {item.name}
                        </Box>
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell  >
                      <Box style={{ display: "flex", flexWrap: 'wrap' }} >
                        {item.models.length > 0 ?
                          item.models.map((model) => (
                            <>{model.status === 1 ?
                              <Box style={{ marginRight: "8px", marginBottom: '4px' }}>
                                <Chip
                                  label={model.name}
                                  size="small"
                                  style={{
                                    backgroundColor: "#E4E7EC",
                                  }}
                                />
                              </Box> :
                              <Box style={{ marginRight: "8px", marginBottom: '4px' }}>
                                <Chip
                                  label={model.name}
                                  size="small"
                                  style={{
                                    backgroundColor: "#F2F4F7",
                                    color: "#98A2B3"
                                  }}
                                />
                              </Box>
                            }

                            </>
                          ))
                          : "-"}
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell style={{ textAlign: "center" }}>{item.productCount.toLocaleString("en-US")}</StyledTableCell>
                    <StyledTableCell>
                      <AntSwitch
                        disabled={!hasProductBrandModelEditAccessRight || item.id === "-1"}
                        checked={item.status === 1 ? true : false}
                        onChange={(event) => handleChangeStatus(item.id, event.target.checked)}
                      />
                    </StyledTableCell>
                    <StickyRightTableCell>
                      <StyledTableCell>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                          <ActionButton actionItems={actionItems} popperStyle={{ zIndex: 999 }}/>
                        </div>
                      </StyledTableCell>
                    </StickyRightTableCell>
                  </StyledTableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : !isFetching && !isLoadingBrandModelError ? (
        <Box>
          <Table className={classes.table} size="small" stickyHeader>
            <TableHead style={{ height: "45px" }}>
              <TableRow>
                <StyledTableCell padding='checkbox'>
                  <SelectAllCheckBox disabled={true} />
                </StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.BRAND")}</StyledTableCell>
                <StyledTableCell style={{ width: '60%' }}>{getLang(lang, "label.MODEL")}</StyledTableCell>
                <StyledTableCell style={{ width: "0px" }}>{getLang(lang, "label.ASSIGNED_PRODUCT")}</StyledTableCell>
                <StyledTableCell style={{ width: "0px" }}>{getLang(lang, "label.STATUS")}</StyledTableCell>
                <StyledTableCell style={{ width: "67px", textAlign: "center" }}>{getLang(lang, "label.ACTION")}</StyledTableCell>
              </TableRow>
            </TableHead>
          </Table>
          <Box py={9} className={classes.emptyContainer}>
            <Typography variant='body1'>
              {search ? getLang(lang, "paragraph.BRAND_NOT_EXIST") : getLang(lang, "paragraph.NO_BRAND_CREATED")}
            </Typography>
            {!search ? (
              <Box mt={2}>
                <Button
                  variant="contained"
                  disableElevation
                  color='primary'
                  style={{ textTransform: "uppercase" }}
                  onClick={() => handleCreateBrandModel(true)}
                >
                  {getLang(lang, "label.ADD_BRAND_MODEL")}
                </Button>
              </Box>
            ) : null}
          </Box>
        </Box>

      ) : null}

      {!isFetching && isLoadingBrandModelError && (
        <RefreshTable handleReload={handleReload} />
      )}

      {!isFetching && data.length > 0 && (
        <TablePagination
          style={{
            backgroundColor: "#fff",
            boxShadow: "0px -1px 0px rgba(58, 77, 84, 0.15)"
          }}
          ActionsComponent={TablePaginationActions}
          rowsPerPageOptions={paginationOptions}
          component="div"
          count={totalFiltered}
          rowsPerPage={rowsPerPage}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          page={page}
          labelRowsPerPage={getLang(lang, 'label.ROWS_PER_PAGE')}
          labelDisplayedRows={({ from, to, count }) => `${from.toLocaleString("en-US")}-${to.toLocaleString("en-US")} ${getLang(lang, 'label.OF')} ${count.toLocaleString("en-US")}`}
        />
      )}
    </>
  )
}
