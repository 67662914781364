import React from "react";
import PropTypes from "prop-types";

import { getLang } from "app/feature/constants";

import RejectReimbursementDialogComponent from "./rejectReimbursementDialog.component";

function RejectReimbursementDialogContainer({
  lang,
  isOpen,
  handleSubmit,
  handleClose,
}) {
  const reasons = [
    getLang(lang, "paragraph.REJECT_CLAIMS_REASON1"),
    getLang(lang, "paragraph.REJECT_CLAIMS_REASON2"),
    getLang(lang, "paragraph.REJECT_CLAIMS_REASON3"),
    getLang(lang, "paragraph.REJECT_REIMBURSEMENT_REASON1"),
  ]

  return (
    <RejectReimbursementDialogComponent
      lang={lang}
      isOpen={isOpen}
      reasons={reasons}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
    />
  );
}

RejectReimbursementDialogContainer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default RejectReimbursementDialogContainer;
