import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useRouteMatch } from "react-router-dom"
import { selectLoading } from 'modules/notification'
import LoadingComponent from "components/loading"
import { 
  getInventoryBoxDetail,
} from "modules/stockflow/redux"
import { STOCKFLOW_INVENTORY_VIEW, STOCKFLOW_DEALER_VIEW } from "lib/constants/accessRights"
import { ROOT } from "modules/dashboard"
import BoxDetailComponent from "./boxDetail.component"
import { getLang } from "app/feature/constants"

function BoxDetailsContainer() {
  const history = useHistory()
  const dispatch = useDispatch()
  const match = useRouteMatch()

  const id = match.params.uuid
  const hasStockflowViewAccessRight = useSelector(state => state.profile.accessRights.includes(STOCKFLOW_INVENTORY_VIEW))
  const hasStockflowDealerViewAccessRight = useSelector(state => state.profile.accessRights.includes(STOCKFLOW_DEALER_VIEW))
  const lang = useSelector(state => state.constant.languages)
  const language = useSelector(state => state.profile.language)
  const token = useSelector(state => state.session.accessToken)
  const box = useSelector(state => state.stockflowInventory.box)
  const isLoading = useSelector(state => selectLoading(state, getInventoryBoxDetail.typePrefix))

  useEffect(() => {
    if (!hasStockflowViewAccessRight) {
      history.push('/admin/dashboard')
    }
  }, [history, hasStockflowViewAccessRight])

  useEffect(() => {
    if (id) {
      dispatch(getInventoryBoxDetail(id))
    }
    else {
      history.push(ROOT)
    }
  }, [history, id, dispatch])

  if (!hasStockflowViewAccessRight) return <LoadingComponent />

  let tabLabels = []
  if (hasStockflowViewAccessRight) {
    tabLabels.push(getLang(lang, "label.INVENTORY"))
  }

  return (
    <BoxDetailComponent
      hasStockflowDealerViewAccessRight={hasStockflowDealerViewAccessRight}
      box={box}
      isLoading={isLoading}
      token={token}
      language={language}
      lang={lang}
    />
  )
}

export default BoxDetailsContainer
