import React from "react";
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
} from "@material-ui/core";
import DefaultImg from "assets/img/img-default.png";
import { getLang } from "app/feature/constants";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "components/accordion";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { StyledTableCell, StyledTableRow } from "components/table";
import RefreshTable from "components/table/refreshTable";
import { SkeletonTableRow } from "components/skeleton/skeleton.component";

const useStyles = makeStyles((theme) => ({
  content: {
    border: "2px solid #ECEEEE",
    borderRadius: 4,
    margin: "10px 0px !important",
    flex: 1,
    overflow: "hidden",
    boxShadow: "none",
  },
  productContainer: {
    padding: "8px 16px",
    borderRadius: "8px !important",
  },
  productImg: {
    height: 45,
    width: 45,
    minWidth: 45,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #D0D5DD",
    borderRadius: 4,
    "& img": {
      height: "100%",
      minHeight: "100%",
      minWidth: "100%",
      objectFit: "cover",
      flexShrink: 0,
      borderRadius: 4,
    },
  },
  productName: {
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    borderCollapse: "separate",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
    },
    "& th,td": {
      fontSize: ".75rem",
      borderRight: "1px solid rgba(58, 77, 84, 0.1)",
      padding: theme.spacing(1, 2, 1, 2),
    },
    "& th": {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightBold,
    },
    "& th:last-child, td:last-child": {
      width: 37,
      borderRight: "0px",
    },
    "& th th": {
      borderBottom: "none !important",
    },
    "& td td": {
      borderBottom: "none !important",
    },
  },
  tableContainer: {
    maxHeight: 400,
    overflowY: "auto",
  },
  emptyContainer: {
    display: "flex",
    justifyContent: "center",
    padding: 16,
  },
  attributeText: {
    color: "#b2b2b2",
    fontSize: 12,
    whiteSpace: "break-spaces",
  },
}));

export default function SingleCheckoutProductAccordionComponent({
  product,
  lang,
  handleExpand,
  selectedProduct,
  isFetching,
  isError,
  snList,
  handleReload,
  tableRef,
  boxUuid,
}) {
  const classes = useStyles();

  return (
    <Box>
      <Accordion
        defaultExpanded={false}
        expanded={
          selectedProduct &&
          selectedProduct.uuid === product.uuid &&
          (boxUuid ? selectedProduct.box_uuid === boxUuid : true)
        }
        className={classes.content}
        onChange={(event, expanded) => {
          handleExpand(expanded ? product : null, "product", boxUuid);
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={classes.productContainer}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            flex={1}
          >
            <Box display="flex" alignItems="center">
              <Box mr={1} className={classes.productImg}>
                {!product.picture ? (
                  <img src={DefaultImg} alt="default profile" />
                ) : (
                  <img src={product.picture} alt={product.name} />
                )}
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="flex-start"
              >
                <Typography className={classes.productName}>
                  <b>{product.name}</b>
                </Typography>
                {!!product.attributes && !!product.attributes.length && (
                  <Typography className={classes.attributeText} variant="body2">
                    {product.attributes.join(", ")}
                  </Typography>
                )}
              </Box>
            </Box>
            <Box ml={1.5}>
              <Typography>
                <b>{product.quantity.toLocaleString("en-US")}</b>
              </Typography>
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box mt={0.25}>
            {!isFetching && isError ? (
              <RefreshTable handleReload={handleReload} />
            ) : (
              <TableContainer className={classes.tableContainer} ref={tableRef}>
                <Table
                  className={classes.table}
                  aria-label="transfer table"
                  size="small"
                  stickyHeader
                >
                  <TableHead style={{ height: "45px" }}>
                    <TableRow>
                      <StyledTableCell>
                        {getLang(lang, "label.SEQUENCE_NUMBER")}
                      </StyledTableCell>
                      <StyledTableCell>
                        {getLang(lang, "label.SERIAL_NUMBER")}
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!isFetching && !isError && snList.length === 0 ? (
                      <StyledTableRow>
                        <StyledTableCell colSpan={2}>
                          <Box py={9} className={classes.emptyContainer}>
                            <Typography
                              variant="body1"
                              style={{
                                paddingBottom: "16px",
                                fontWeight: "bold",
                              }}
                            >
                              {getLang(lang, "label.NO_DATA_AVAILABLE")}
                            </Typography>
                          </Box>
                        </StyledTableCell>
                      </StyledTableRow>
                    ) : isFetching ? (
                      [...new Array(5)].map((v, index) => (
                        <SkeletonTableRow key={index} columnCount={2} />
                      ))
                    ) : (
                      [...snList].map((row, index) => {
                        return (
                          <StyledTableRow
                            key={index}
                            hover
                            role="checkbox"
                            tabIndex={-1}
                          >
                            <StyledTableCell>
                              <span>{row.sequence_number}</span>
                            </StyledTableCell>
                            <StyledTableCell>
                              <span>{row.serial_number}</span>
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
