import React, { useCallback } from "react";
import DraggableReasonCardComponent from "./draggableReasonCard.component";
import { cloneDeep } from "lodash";

export default function DraggableReasonCardContainer({
  index,
  lang,
  reason,
  updateReason,
  reasons,
  usedLang,
  languageTabIndex,
  isSubmitting,
}) {
  const moveCard = useCallback((dragIndex, hoverIndex, reasonList) => {
    let temp = cloneDeep(reasonList);

    let itemToMove = temp[dragIndex];
    temp.splice(dragIndex, 1);
    temp.splice(hoverIndex, 0, itemToMove);

    temp = temp.map((t, index) => ({
      ...t,
      sequence: index + 1,
      is_edited: true,
    }));
    updateReason(temp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DraggableReasonCardComponent
      index={index}
      moveCard={moveCard}
      lang={lang}
      reason={reason}
      updateReason={updateReason}
      reasons={reasons}
      usedLang={usedLang}
      languageTabIndex={languageTabIndex}
      isSubmitting={isSubmitting}
    />
  );
}
