import {Box, makeStyles} from "@material-ui/core";
import BusinessIcon from "@material-ui/icons/Business";
import React from "react";

const useStyle = makeStyles(theme => ({
  profileImage: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "32px",
    height: "32px",
    borderRadius: "100%",
    "& img": {
      height: "32px",
      width: "32px",
      borderRadius: "100%",
    },
  },
}));

export default function BranchIcon({url, imageSize, iconSize}) {
  const classes = useStyle();

  return (
    <Box>
      {url ? (
        <Box className={classes.profileImage} width={imageSize ?? "32px"} height={imageSize ?? "32px"}>
          <img src={url} alt="branch-icon"/>
        </Box>
      ) : (
        <Box className={classes.profileImage} width={imageSize ?? "32px"} height={imageSize ?? "32px"}>
          <Box
            style={{
              height: "100%",
              minWidth: "100%",
              minHeight: "100%",
              borderRadius: '100%',
              border: '2px solid #6AAF68',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: '0px 0px 4px 0px rgb(0 0 0 / 25%)',
            }}
          >
            <BusinessIcon style={{ fontSize: iconSize ?? 18, color: '#6AAF68' }} />
          </Box>
        </Box>
      )}
    </Box>
  );
}
