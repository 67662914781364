import { createSlice } from "@reduxjs/toolkit";
import { ADDITIONAL_INFO } from "../../constants";
import {
  checkHasPendingProcess,
  deleteAdtInfoTitle,
  editAdtInfoTitle,
  getAdditionalInfoDropdown,
  getAdditionalInfoSequence,
  getAdtInfoImportLog,
  getAdtInfoListing,
  getAdtInfoTitles,
  getAdtProductListing,
  getAdtSnListing,
} from "../action";

const initialState = {
  adtInfoList: {
    list: [],
    totalRecords: 0,
    totalFiltered: 0,
    isError: false,
    hasNextPage: true,
  },
  isOpenAddDialog: false,
  titles: {
    //for export dialog
    list: [],
    supportedLang: ["EN"],
    totalRecords: 0,
    totalFiltered: 0,
    isError: false,
  },
  pendingProcess: null,
  additionalFieldDropdown: {
    list: [],
    isError: false,
    totalRecords: 0,
    totalFiltered: 0,
    hasNextPage: true,
  },
  serialNumbers: {
    additionalFields: [],
    list: [],
    totalRecords: 0,
    totalFiltered: 0,
    isError: false,
    afterKey: {},
  },
  snPageSetting: {
    length: 25,
    start: 0,
    search: "",
    searchColumn: "serial_number",
    node_uuids: "",
    product_uuids: "",
    products: [],
    productNotAssigned: false,
    branch: [],
    additionalInfo: [],
    isNextPage: true,
  },
  products: {
    additionalFields: [],
    list: [],
    isError: false,
    afterKey: {},
  },
  productPageSetting: {
    length: 25,
    start: 0,
    node_uuids: "",
    product_uuids: "",
    products: [],
    productNotAssigned: false,
    branch: [],
    additionalInfo: [],
    isNextPage: true,
    previousKey: null,
  },
  selectedAdtInfos: [],
  additionalInfoSequenceList: {
    list: [],
    newlyFetchList: [],
    isError: false,
    totalRecords: 0,
    totalFiltered: 0,
    hasNextPage: true,
  },
  importLog: {
    list: [],
    isError: false,
    totalRecords: 0,
    totalFiltered: 0,
  },
  displayLang: "EN",
};

const adtInfoSlice = createSlice({
  name: ADDITIONAL_INFO,
  initialState,
  reducers: {
    resetAdtInfoListing(state) {
      state.adtInfoList = initialState.adtInfoList;
    },
    updateImportProgress(state, action) {
      const { payload } = action;
      state.tag = {
        ...state.tag,
        hasPendingImport: payload,
        mode: 2,
      };
    },
    openImportDialog(state) {
      state.isOpenAddDialog = true;
    },
    closeImportDialog(state) {
      state.isOpenAddDialog = false;
    },
    resetAdtinfoExportTitles(state) {
      state.titles = initialState.titles;
    },
    resetAdditionalFieldDropdown(state) {
      state.additionalFieldDropdown = initialState.additionalFieldDropdown;
    },
    updateSelectedAdtInfoTitles(state, action) {
      let updatedTitle = action.payload;

      let updated = false;
      let newTitles = state.selectedAdtInfos.map((t) => {
        let data = { ...t };
        if (t.id === updatedTitle.id) {
          data.title = updatedTitle.title;
          updated = true;
        }
        return data;
      });

      if (updated) {
        state.selectedAdtInfos = newTitles;
      }
    },
    updateSelectedAdtInfos(state, action) {
      state.selectedAdtInfos = action.payload;
      state.products.afterKey = {};
      state.serialNumbers.afterKey = {};
    },
    resetSelectedAdtInfos(state) {
      state.selectedAdtInfos = initialState.selectedAdtInfos;
      state.products = initialState.products;
      state.serialNumbers = initialState.serialNumbers;
    },
    resetAdditionalInfoSequenceList(state) {
      state.additionalInfoSequenceList =
        initialState.additionalInfoSequenceList;
    },
    resetSnAfterKey(state) {
      state.serialNumbers.afterKey = initialState.serialNumbers.afterKey;
    },
    resetPrdAfterKey(state) {
      state.products.afterKey = initialState.products.afterKey;
    },
    updateDisplayLang(state, action) {
      state.displayLang = action.payload;
    },
    updateAdtSnPageSetting(state, action) {
      const { payload } = action;
      state.snPageSetting = {
        ...state.snPageSetting,
        ...payload,
      };
    },
    resetAdtSnPageSetting(state) {
      state.snPageSetting = initialState.snPageSetting;
    },
    saveAdtSnPageSettingSession(state, action) {
      sessionStorage.setItem(
        "adt_info_sn_filter",
        JSON.stringify({
          ...state.snPageSetting,
          selectedAdtInfos: state.selectedAdtInfos,
        })
      );
    },
    updateAdtProductPageSetting(state, action) {
      const { payload } = action;
      state.productPageSetting = {
        ...state.productPageSetting,
        ...payload,
      };
    },
    resetAdtProductPageSetting(state) {
      state.productPageSetting = initialState.productPageSetting;
    },
    saveAdtPrdPageSettingSession(state, action) {
      sessionStorage.setItem(
        "adt_info_prd_filter",
        JSON.stringify({
          ...state.productPageSetting,
          selectedAdtInfos: state.selectedAdtInfos,
        })
      );
    },
  },
  extraReducers: {
    [getAdtInfoListing.fulfilled]: (state, action) => {
      const { payload } = action;
      if (state.adtInfoList.hasNextPage) {
        state.adtInfoList.list = [...state.adtInfoList.list, ...payload.data];
      }
      state.adtInfoList.totalRecords = payload.recordsTotal;
      state.adtInfoList.totalFiltered = payload.recordsFiltered;
      state.adtInfoList.hasNextPage =
        state.adtInfoList.list.length < state.adtInfoList.totalFiltered;
    },
    [getAdtInfoListing.rejected]: (state) => {
      state.adtInfoList.isError = true;
    },
    [editAdtInfoTitle.fulfilled]: (state, action) => {
      const { payload } = action;
      let temp = [...state.adtInfoList.list];
      let idx = temp.findIndex((a) => a.id === payload.uuid);
      if (idx >= 0) {
        temp[idx] = {
          ...temp[idx],
          title: payload.title,
        };
      }
      state.adtInfoList.list = temp;
    },
    [getAdtInfoTitles.pending]: (state) => {
      state.titles = initialState.titles;
    },
    [getAdtInfoTitles.fulfilled]: (state, action) => {
      const { payload } = action;
      state.titles.list = payload.data;
      state.titles.totalRecords = payload.recordsTotal;
      state.titles.totalFiltered = payload.recordsFiltered;

      let langList = [];
      payload.data.forEach((d) => {
        let mergedSet = new Set([...langList, ...Object.keys(d.title)]);
        langList = [...mergedSet];
      });
      state.titles.supportedLang = langList;
    },
    [getAdtInfoTitles.rejected]: (state) => {
      state.titles.isError = true;
    },
    [checkHasPendingProcess.fulfilled]: (state, action) => {
      state.pendingProcess = action.payload;
    },
    [getAdditionalInfoDropdown.fulfilled]: (state, action) => {
      const { payload } = action;

      if (state.additionalFieldDropdown.hasNextPage) {
        state.additionalFieldDropdown.list = [
          ...state.additionalFieldDropdown.list,
          ...payload.data,
        ];
      }
      state.additionalFieldDropdown.totalRecords = payload.recordsTotal;
      state.additionalFieldDropdown.totalFiltered = payload.recordsFiltered;
      state.additionalFieldDropdown.hasNextPage =
        state.additionalFieldDropdown.list.length < payload.recordsFiltered;
    },
    [getAdditionalInfoDropdown.rejected]: (state) => {
      state.additionalFieldDropdown.isError = true;
    },
    [getAdtSnListing.pending]: (state) => {
      state.serialNumbers.list = initialState.serialNumbers.list;
      state.serialNumbers.isError = initialState.serialNumbers.isError;
      state.serialNumbers.additionalFields =
        initialState.serialNumbers.additionalFields;
      state.serialNumbers.totalRecords =
        initialState.serialNumbers.totalRecords;
      state.serialNumbers.totalFiltered =
        initialState.serialNumbers.totalFiltered;
    },
    [getAdtSnListing.fulfilled]: (state, action) => {
      const {
        payload,
        meta: { arg },
      } = action;
      let currentPage = arg.start / arg.length;

      const tempData = [];

      payload.data.forEach((element) => {
        const sn = {
          id: element.id || "",
          productName: element.product_name || "",
          urlPrefix: element.url_prefix || "",
          seqNumber: element.seq_number || 0,
          serialNumber: element.serial_number || "",
          status: element.status || "",
          subStatus: element.sub_status || "",
          branchName: element.branch_name || "",
          encode: element.enc || "",
          additionalFields: element.additional_fields || [],
          attributes: element.attributes || [],
        };

        tempData.push(sn);
      });

      state.serialNumbers.totalRecords = payload.recordsTotal;
      state.serialNumbers.totalFiltered = payload.recordsFiltered;
      state.serialNumbers.list = tempData;
      state.serialNumbers.additionalFields = payload.additionalFields || [];

      if (tempData.length) {
        state.serialNumbers.afterKey = {
          ...state.serialNumbers.afterKey,
          [currentPage + 1]: tempData[tempData.length - 1].seqNumber,
        };
      }
    },
    [getAdtSnListing.rejected]: (state) => {
      state.serialNumbers.isError = true;
    },
    [getAdtProductListing.pending]: (state) => {
      state.products.list = initialState.products.list;
      state.products.isError = initialState.products.isError;
      state.products.additionalFields = initialState.products.additionalFields;
    },
    [getAdtProductListing.fulfilled]: (state, action) => {
      const {
        payload,
        meta: { arg },
      } = action;
      let currentPage = arg.start / arg.length;

      const tempData = [];

      payload.data.forEach((element) => {
        const sn = {
          id: element.id || "",
          product: element.product,
          quantity: element.quantity || 0,
          additionalFields: element.additional_infos || [],
          attributes: element.attributes || [],
        };

        tempData.push(sn);
      });

      state.products.list = tempData;
      state.products.additionalFields = payload.additionalFields || [];
      state.products.afterKey = {
        ...state.products.afterKey,
        [currentPage + 1]: payload.after_key,
      };
    },
    [getAdtProductListing.rejected]: (state) => {
      state.products.isError = true;
    },
    [getAdditionalInfoSequence.fulfilled]: (state, action) => {
      const { payload } = action;

      if (state.additionalInfoSequenceList.hasNextPage) {
        state.additionalInfoSequenceList.list = [
          ...state.additionalInfoSequenceList.list,
          ...payload.data,
        ];
        state.additionalInfoSequenceList.newlyFetchList = payload.data;
      }
      state.additionalInfoSequenceList.totalRecords = payload.recordsTotal;
      state.additionalInfoSequenceList.totalFiltered = payload.recordsFiltered;
      state.additionalInfoSequenceList.hasNextPage =
        state.additionalInfoSequenceList.list.length < payload.recordsFiltered;
    },
    [getAdditionalInfoSequence.rejected]: (state) => {
      state.additionalInfoSequenceList.isError = true;
    },
    [getAdtInfoImportLog.pending]: (state) => {
      state.importLog = initialState.importLog;
    },
    [getAdtInfoImportLog.fulfilled]: (state, action) => {
      const { payload } = action;

      state.importLog.totalRecords = payload.recordsTotal;
      state.importLog.totalFiltered = payload.recordsFiltered;
      state.importLog.list = payload.data;
    },
    [getAdtInfoImportLog.rejected]: (state) => {
      state.importLog.isError = true;
    },
    [deleteAdtInfoTitle.fulfilled]: (state, action) => {
      let deletedId = action.meta.arg;

      state.adtInfoList.list = state.adtInfoList.list.filter(
        (t) => t.id !== deletedId
      );
      state.adtInfoList.totalRecords -= 1;
      state.adtInfoList.totalFiltered -= 1;

      let idx = state.selectedAdtInfos.findIndex((a) => a.id === deletedId);
      if (idx >= 0) {
        state.selectedAdtInfos = state.selectedAdtInfos.filter(
          (a) => a.id !== deletedId
        );
      }
    },
  },
});

export const {
  resetAdtInfoListing,
  openImportDialog,
  closeImportDialog,
  resetAdtinfoExportTitles,
  additionalFieldDropdown,
  updateSelectedAdtInfos,
  resetAdditionalInfoSequenceList,
  resetAdditionalFieldDropdown,
  resetSelectedAdtInfos,
  resetSnAfterKey,
  resetPrdAfterKey,
  updateSelectedAdtInfoTitles,
  updateDisplayLang,
  updateAdtProductPageSetting,
  saveAdtPrdPageSettingSession,
  updateAdtSnPageSetting,
  saveAdtSnPageSettingSession,
  resetAdtProductPageSetting,
  resetAdtSnPageSetting,
  updateImportProgress,
} = adtInfoSlice.actions;
export const adtInfoReducer = adtInfoSlice.reducer;
