import React from "react";
import {
  Box,
  Grid,
  Button,
} from "@material-ui/core";

import { getLang } from "app/feature/constants";

import Dialog from "components/dialog/customDialog";

export default function ConfirmReimbursementSubmitDialogComponent({
  lang,
  isOpen,
  handleClose,
  handleConfirm,
}) {
  return (
    <Dialog
      isOpen={isOpen}
      handleClose={handleClose}
      disableEnforceFocus={true}
      disableAutoFocus={true}
      disableEscapeKeyDown={true}
      disableBackdropClick={true}
      PaperProps={{ style: { borderRadius: "10px" } }}
    >
      <Box p={4} py={3}>
        <Box fontWeight="fontWeightBold" textAlign="center">
          {getLang(lang, "paragraph.COMFIRM_SUBMIT_REIMBURSEMENT_FORM_TITLE")}
        </Box>
        <Box fontcolor="text.secondary" textAlign="center" fontSize="fontSize" pt={1} pb={3}>
          {getLang(lang, "paragraph.COMFIRM_SUBMIT_REIMBURSEMENT_FORM_CONTENT")}
        </Box>

        <Grid container>
          <Grid item xs={6}>
            <Box display="flex" justifyContent="center" pr={1}>
              <Button
                size="large"
                variant="outlined"
                fullWidth={true}
                onClick={handleClose}
              >
                {getLang(lang, "label.CANCEL")}
              </Button>
            </Box>
          </Grid>

          <Grid item xs={6}>
            <Box display="flex" justifyContent="center" pl={1}>
              <Button
                size="large"
                color="secondary"
                variant="contained"
                fullWidth={true}
                onClick={handleConfirm}
              >
                {getLang(lang, "label.CONFIRM")}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
}