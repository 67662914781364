import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import LoginPage from "./loginPage.page";

function LoginContainer() {
  const type = "Login";

  const lang = useSelector(state => state.constant.languages)
  useEffect(() => {
    document.body.setAttribute("id", "login");
    document.title = "Login - SmartKood Customer Portal";

    return () => {
      document.body.removeAttribute("id");
    };
  }, []);

  return <LoginPage type={type} lang={lang}/>;
}

export default LoginContainer;
