import { createAsyncThunk } from "@reduxjs/toolkit";
import warrantyApi from "app/api/warranty";

export const REPLACEMENTHANDOFF = "replacementHandoff";

export const getWarrantyHandoffList = createAsyncThunk(
  `${REPLACEMENTHANDOFF}/getWarrantyHandoffList`,
  async (payload, { rejectWithValue }) => {
    return warrantyApi
      .getWarrantyHandoffList(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const getWarrantyHandoffDetail = createAsyncThunk(
  `${REPLACEMENTHANDOFF}/getWarrantyHandoffDetail`,
  async (payload, { rejectWithValue }) => {
    return warrantyApi
      .getWarrantyHandoffDetail(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const approveHandoff = createAsyncThunk(
  `${REPLACEMENTHANDOFF}/approveHandoff`,
  async (payload, { rejectWithValue }) => {
    return warrantyApi
      .approveHandoff(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const rejectHandoff = createAsyncThunk(
  `${REPLACEMENTHANDOFF}/rejectHandoff`,
  async (payload, { rejectWithValue }) => {
    return warrantyApi
      .rejectHandoff(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);
