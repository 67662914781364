import axios from "axios";

const get = ({ id }) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/serialnumber/api/v1/warranty-setting/${id}`)
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

const put = ({
  warranty_name,
  duration_month,
  warrantyApproval,
  warrantyPreregisterEnabled,
  warrantyRequiredValidation,
  metadata,
  productId,
  id,
  tnc_url,
  claimDisable,
  claimEnable,
  claimAdd,
  claimEdit,
  allowMultipleClaimReason,
  warrantyRegisterNotificationType,
  claimApplicationNotificationType,
  customRecipient,
  itemReplacementProcessEnabled,
  allowDifferentProductReplacement,
}) => {
  const body = {
    warranty_name: warranty_name,
    duration_month: duration_month,
    isApproval: warrantyApproval,
    is_preregister_enabled: warrantyPreregisterEnabled,
    required_validation: warrantyRequiredValidation,
    metadata: metadata,
    product_id: productId,
    tnc_url: tnc_url,
    claim_reason_add: claimAdd,
    claim_reason_disable: claimDisable,
    claim_reason_enable: claimEnable,
    claim_reason_edit: claimEdit,
    allow_multiple_claim_reason: allowMultipleClaimReason,
    warranty_register_notification_type: warrantyRegisterNotificationType,
    claim_application_notification_type: claimApplicationNotificationType,
    custom_recipient: customRecipient ? {
      warranty_registration: customRecipient.warrantyRegister.length === 0 ? [] : customRecipient.warrantyRegister,
      claim_application: customRecipient.claimApplication.length === 0  ? [] : customRecipient.claimApplication
    } : null,
    item_replacement_enabled: itemReplacementProcessEnabled,
    allow_different_product_replacement: allowDifferentProductReplacement,
  };

  return new Promise((resolve, reject) => {
    axios
      .put(`/serialnumber/api/v1/warranty-setting/${id}`, body)
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

const warrantySettingForm = {
  put,
  get,
};

export default warrantySettingForm;
