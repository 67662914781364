import { Avatar, Box, Typography } from "@material-ui/core";
import { fontTextStyle, iconStyle } from "modules/reporting/styles";
import React from "react";
import { ContentCard } from "../../layout";
import { responseHandler } from "modules/reporting/utils/helper";
import { BoxError } from "../../skeleton/error";

export default function CampaignPrizeComponent({
    status,
    data,
    translate
}){
    const fontClass = fontTextStyle();
    const iconClass = iconStyle();

    const renderData = (ref) => {
        
        if(!ref) return null;
        if (Object.keys(ref).length === 0 || !ref.isSuccess){
            return null;
        }
        const prize_detail = ref;
        return (
            <Box display="flex" style={{gap:"12px"}}>
                <Avatar variant="square" alt={prize_detail.name} src={prize_detail.image} className={iconClass.small_image__icon} />
                <Box display="flex" flexDirection="column" style={{gap:"12px"}}>
                    <Typography className={fontClass.text__header_title}>
                        {prize_detail.name}
                    </Typography>
                    <Typography className={fontClass.text__header_subtitle} >
                        {`${translate('label.QUANTITY')}: ${prize_detail.quantity}`}
                    </Typography>
                </Box>
            </Box>
        )

    }
    return (
        <ContentCard>
            <Box padding="12px 10px">
                {
                    responseHandler(
                        data.campaignPrizeSummaryData,
                        status.isFetchingCampaignPrizeSummaryData,
                        (
                            renderData(data.campaignPrizeSummaryData)
                        ),
                        (
                            <>empty</>
                        ),
                        (
                            <>no result</>
                        ),
                        (
                            <BoxError 
                                title={translate("label.LOADING_FAILED")}
                                value={translate("paragraph.LOADING_FAILED_DESCRIPTION")}
                            />
                        )
                    )
                }
            </Box>
        </ContentCard>
    )
}