import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { addAlert, selectLoading } from 'modules/notification'
import EditSurveyFormComponent from './editSurveyForm.component'
import { getProductDropdown } from 'modules/product-mgmt/redux/action/products.action'
import { surveyTemplate } from 'modules/survey/constants/template';

export default function EditSurveyFormContainer({
  handleSubmit,
  handleClose,
  isEditMode,
  survey,
  isLoading,
  templateType
}) {
  const dispatch = useDispatch()
  const productDropdown = useSelector(state => state.products.productsDropdown)
  const isProductDropdownLoading = useSelector(state => selectLoading(state, getProductDropdown.typePrefix))
  const lang = useSelector(state => state.constant.languages)
  const [template, setTemplate] = useState()
  const [isAddQuestionOpen, setIsAddQuestionOpen] = useState(false)
  const [surveyData, setSurveyData] = useState({
    id: "",
    name: "",
    description: "",
    productIds: [],
    startDate: moment().format("YYYY-MM-DD HH:mm"),
    endDate: moment().add(7, "day").format("YYYY-MM-DD HH:mm"),
    questions: [],
    disableStartDate: false
  })
  const [isError, setIsError] = useState("")

  const alertWarningMessage = message => {
    dispatch(addAlert({ severity: "warning", message }))
  }

  useEffect(() => {
    if (templateType) {
      setTemplate(surveyTemplate(lang).find(x => x.type === templateType))
    }
  }, [templateType,lang])

  useEffect(() => {
    if (template) {
      setSurveyData((prev) => ({
        ...prev,
        name: template.name,
        description: template.description,
        questions: [...template.questions]
      }))
    }

    if (isEditMode) {
      setSurveyData((prev) => ({
        ...prev,
        id: survey.id,
        name: survey.name,
        description: survey.description,
        productIds: [...survey.productIds],
        startDate: survey.startDate,
        endDate: survey.endDate,
        questions: [...survey.questions],
        disableStartDate: moment(survey.startDate).isBefore(moment())
      }))
    }
  }, [template, isEditMode, survey])

  const handleDeleteQuestion = (index) => {

    let questionsTemp = [...surveyData.questions]

    questionsTemp.splice(index, 1)

    setSurveyData((prev) => ({
      ...prev,
      questions: questionsTemp
    }))

  }

  const handleIsAddQuestionOpen = (value) => {
    setIsAddQuestionOpen(value)
  }

  return (
    <EditSurveyFormComponent
      alertWarningMessage={alertWarningMessage}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
      isEditMode={isEditMode}
      isLoading={isLoading}
      productDropdown={productDropdown}
      isProductDropdownLoading={isProductDropdownLoading}
      lang={lang}
      handleDeleteQuestion={handleDeleteQuestion}
      isAddQuestionOpen={isAddQuestionOpen}
      handleIsAddQuestionOpen={handleIsAddQuestionOpen}
      surveyData={surveyData}
      setSurveyData={setSurveyData}
      isError={isError}
      setIsError={setIsError}
   
    />
  )
}
