import { makeStyles } from "@material-ui/core";

export const tableStyle = makeStyles((theme) => ({
    table__cell: {
        borderRight: "1px solid #d5d9de",
        padding: "8px !important"
    },
    table__total_row: {
        backgroundColor: "#6184FF0D",
    },
    table__total_cell: {
        fontFamily: "Roboto",
        color: "#344054",
        fontWeight: "500",
        fontSize: "14px",
    },
    cell__product: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: "12px",
        width: "100%",
        // padding: "10px"
    },
    cell__product_no_padding: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: "12px",
        width: "100%",
        padding: "0px"
    },
    table__cell_icon: {
        fontFamily: "Roboto",
        border: "1px solid #d5d9de",
        textAlign: "center !IMPORTANT"
    },
    table__cell_center : {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "24px",
        width: "100%",
        padding: "10px"
    }
}))