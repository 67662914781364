import React from "react";
import { useSelector } from "react-redux";
import EditAttributeDialogComponent from './editAttributeDialog.component';

export default function EditAttributeDialogContainer({
  isOpen,
  handleClose,
  handleSubmit,
  data,
  isSaving,
}) {
  const lang = useSelector(state => state.constant.languages)
  return (
    <EditAttributeDialogComponent
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      isOpen={isOpen}
      data={data}
      lang={lang}
      isSaving={isSaving}
    />
  )
}