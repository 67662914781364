import React from 'react'
import { Backdrop, Dialog, makeStyles } from '@material-ui/core'
import { AddBonanzaPointForm } from '../../form'

const useStyle = makeStyles(() => ({
  dialog: {
    maxWidth: "none"
  }
}))

export default function AddPointDialogComponent({
  headerType,
  isOpen,
  handleClose
}) {
  const classes = useStyle()

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      disableEnforceFocus={true}
      disableAutoFocus={true}
      disableEscapeKeyDown={true}
      disableBackdropClick={true}
      closeAfterTransition
      BackdropComponent={Backdrop}
      maxWidth="700px"
      classes={{
        paperWidthSm: classes.dialog
      }}
    >
      <AddBonanzaPointForm
        handleClose={handleClose}
        headerType={headerType}
      />
    </Dialog>
  )
}
