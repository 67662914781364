import React, { useState } from "react";
import PointCutFormComponent from './pointCutForm.component'
import { useDispatch, useSelector } from "react-redux";
import { createPointCut, updatePointCut } from "modules/loyalty-program/redux";
import { setType } from "modules/loyalty-program/redux/slice/redemptionCenter.slice"
import { unwrapResult } from "@reduxjs/toolkit";
import ConfirmationDialogComponent from "components/dialog/confirmation";
import { hideBackdrop, showBackdrop } from "modules/backdrop";
import { redemptionCenterType } from "modules/loyalty-program/constants";
import { POINT_CUT_ADD, POINT_CUT_EDIT } from "lib/constants/accessRights";
import { getLang } from "app/feature/constants";

export default function AddPointFormContainer({ headerType, handleClose }) {
  const dispatch = useDispatch();
  const hasPointCutAddAccessRight = useSelector(state => state.profile.accessRights.includes(POINT_CUT_ADD));
  const hasPointCutEditAccessRight = useSelector(state => state.profile.accessRights.includes(POINT_CUT_EDIT));

  const [confirmationDialogModal, setConfirmationDialogModal] = useState(false);
  const pointCutType = useSelector(
    (state) => state.redemptionCenter.pointCutType
  );
  const pointCut = useSelector(
    (state) => state.redemptionCenter.pointCut
  );
  const lang = useSelector(state => state.constant.languages)
  const language = useSelector(state => state.profile.language)
  
  const changeToUpdate = (e) => {
    if (hasPointCutEditAccessRight) {
      e.preventDefault();
      const updateInfo = {
        index: redemptionCenterType.pointCut,
        type: "edit",
      };
      dispatch(setType(updateInfo));
    }
  };

  const changeBackView = () => {
    const updateInfo = {
      index: redemptionCenterType.pointCut,
      type: "view",
    };
    dispatch(setType(updateInfo));
  };


  const handleSubmit = async (values) => {
    if (pointCutType === "create") {
      if (hasPointCutAddAccessRight) {
        const giftId = [];
        for (const gifts of values.gift) {
          giftId.push(gifts.id);
        }
        const info = {
          point: values.point,
          itemId: giftId,
          startAt: values.startAt,
          endAt: values.endAt,
        };
        await dispatch(createPointCut(info))
          .then(unwrapResult)
          .then((r) => {
            dispatch(hideBackdrop());
            setConfirmationDialogModal(true);
          })
          .catch((error) => {
            dispatch(hideBackdrop());
          });
      }
    }
    else {
      if (hasPointCutEditAccessRight) {
        dispatch(showBackdrop());
        const giftId = [];
        for (const gifts of values.gift) {
          giftId.push(gifts.id);
        }
        const info = {
          id: values.id,
          point: values.point,
          itemId: giftId,
          startAt: values.startAt,
          endAt: values.endAt,
        };
        await dispatch(updatePointCut(info))
          .then(unwrapResult)
          .then((r) => {
            dispatch(hideBackdrop());
            changeBackView();
          })
          .catch((error) => {
            dispatch(hideBackdrop());
          });
      }
    }
  };
  return (
    <>
      <PointCutFormComponent
        hasPointCutAddAccessRight={hasPointCutAddAccessRight}
        hasPointCutEditAccessRight={hasPointCutEditAccessRight}
        headerType={headerType}
        pointCut={pointCut}
        language={language}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        pointCutType={pointCutType}
        changeToUpdate={changeToUpdate}
        lang={lang}
        changeBackView={changeBackView}
      />
      <ConfirmationDialogComponent
        isOpen={confirmationDialogModal}
        handleProceed={handleClose}
        type={"success"}
        title={getLang(lang,"label.POINT_CUT_ADDED_SUCCESSFULLY")}
        rejectOption={false}
        proceedLabel={getLang(lang,"label.ALRIGHT")}
      />
    </>
  )
}
