import React, { useEffect, useState } from "react";
import { makeStyles, Box, Typography, Divider, Tabs } from "@material-ui/core";
import ScanMapCard from "../../components/card/scanMapCard";
import { getLang } from "app/feature/constants";
import CustomTab from "components/tab";
import { DateRangePicker } from "rsuite";
import TrendingSummaryPanel from "modules/dashboard/components/panel/trendingSummaryPanel";
import ScanAgainstSuspendPanel from "modules/dashboard/components/panel/scanAgainstSuspendPanel";
import TopScanCitiesPanel from "modules/dashboard/components/panel/topScanCitiesPanel";
import TopScanProductsPanel from "modules/dashboard/components/panel/topScanProductsPanel";
import {
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon,
} from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import SerialNumberFirstScanPopUp from "../../components/dialog/serialNumberFirstScanPopup";
import UniqueConsumerPopUp from "../../components/dialog/uniqueConsumerPopup";
import NewConsumerPopUp from "../../components/dialog/newConsumerPopup";
import moment from "moment/moment";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 16,
    fontWeight: "bold",
  },
  panel: {
    background: "white",
    border: "1px solid #D0D5DD",
    borderRadius: 12,
    padding: 20,
  },
  tabButton: {
    textTransform: "none",
  },
  twoColumn: {
    overflow: "hidden",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridColumnGap: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      rowGap: theme.spacing(2),
    },
  },
  collapseButton: {
    backgroundColor: "#F9FAFB",
    border: "1px solid #D0D5DD",
    padding: "8px 20px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 4,
    cursor: "pointer",
  },
  datePicker: {
    "& .rs-picker-default .rs-picker-toggle.rs-btn": {
      borderRadius: 4,
    },
  },
}));

export default function MainPageComponent({
  currentFilter,
  updateFilterDate,
  lang,
  activeIndex,
  setActiveIndex,
  showMaintenanceAlert,
  showPopup,
  closePopup,
  popupIsShow,
}) {
  const classes = useStyles();
  const [expand, setExpand] = useState(false);
  const [isCollapsible, setIsCollapsible] = useState(false);

  const options = [
    getLang(lang, "label.LAST_COUNT_DAYS", { day: 14 }),
    getLang(lang, "label.TODAY"),
    getLang(lang, "label.THIS_MONTH"),
    getLang(lang, "label.LAST_MONTH"),
    getLang(lang, "label.CUSTOM_DATE"),
  ];

  function a11yProps(index, item) {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tab-${index}`,
      label: (
        <Typography
          variant="button"
          classes={{ button: classes.tabButton }}
          children={item}
        />
      ),
    };
  }

  useEffect(() => {
    const resizeObserver = new ResizeObserver(onResize);
    resizeObserver.observe(document.getElementById("container"));
  }, []);

  const onResize = (entries) => {
    const div = entries[0];
    if (div.contentRect.height > 372) {
      setIsCollapsible(true);
    }
  };

  return (
    <Box>
      {showMaintenanceAlert && (
        <Box mb={2}>
          <Alert severity="warning">
            {getLang(lang, "paragraph.MAINTENANCE_ALERT")}
          </Alert>
        </Box>
      )}
      <Box mb={2}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box mr={2}>
            <Typography color="primary" className={classes.title}>
              <b>{getLang(lang, "label.DASHBOARD")}</b>
            </Typography>
          </Box>
          <Tabs
            value={activeIndex}
            onChange={(e, index) => setActiveIndex(index)}
            aria-label="scrollable auto tabs example"
            textColor="primary"
            variant="scrollable"
            indicatorColor="primary"
            classes={{
              root: classes.tab,
              indicator: classes.indicator,
              scrollButtons: classes.scroll,
            }}
          >
            {options.map((item, index) => (
              <CustomTab
                className={classes.tab}
                key={index}
                {...a11yProps(index, item)}
              />
            ))}
          </Tabs>
        </Box>
        <Divider />
        <Box
          display="flex"
          alignContent="center"
          flexWrap="wrap"
          justifyContent="flex-end"
          mb={1}
          mt={1.5}
          className={classes.datePicker}
        >
          <DateRangePicker
            character=" - "
            cleanable={false}
            placeholder={getLang(lang, "placeholder.SELECT_DATE_RANGE")}
            format="dd MMM yyyy"
            placement="auto"
            disabledDate={(date) => date.getTime() > Date.now()}
            editable={false}
            onChange={(value) => {
              updateFilterDate(value[0], value[1]);
            }}
            value={currentFilter.dateRange}
            ranges={[]}
          />
        </Box>
      </Box>
      <TrendingSummaryPanel
        dateRange={currentFilter.dateRange}
        isToday={activeIndex === 1}
        isMonth={activeIndex === 2 || activeIndex === 3}
        showPopup={(name) => showPopup(name)}
      />
      <SerialNumberFirstScanPopUp
        isOpen={popupIsShow['serialNumberFirstScan']}
        handleClose={() => closePopup('serialNumberFirstScan')}
        startDate={moment(currentFilter.dateRange[0]).format("YYYY-MM-DD")}
        endDate={moment(currentFilter.dateRange[1]).format("YYYY-MM-DD")}
      />
      <UniqueConsumerPopUp
        isOpen={popupIsShow['uniqueConsumer']}
        handleClose={() => closePopup('uniqueConsumer')}
        startDate={moment(currentFilter.dateRange[0]).format("YYYY-MM-DD")}
        endDate={moment(currentFilter.dateRange[1]).format("YYYY-MM-DD")}
      />
      <NewConsumerPopUp
        isOpen={popupIsShow['newConsumer']}
        handleClose={() => closePopup('newConsumer')}
        startDate={moment(currentFilter.dateRange[0]).format("YYYY-MM-DD")}
        endDate={moment(currentFilter.dateRange[1]).format("YYYY-MM-DD")}
      />
      <Box className={classes.panel}>
        <Box mb={2}>
          <Typography variant="body1">
            <b>{getLang(lang, "label.CONSUMER_SCAN_GEOLOCATION")}</b>
          </Typography>
        </Box>
        <ScanMapCard
          lang={lang}
          startDate={currentFilter.dateRange[0]}
          endDate={currentFilter.dateRange[1]}
        />
        <Box
          className={classes.twoColumn}
          mt={2}
          id="container"
          style={{ maxHeight: isCollapsible && !expand ? 372 : "fit-content" }}
        >
          <TopScanCitiesPanel dateRange={currentFilter.dateRange} />
          <TopScanProductsPanel dateRange={currentFilter.dateRange} />
        </Box>
        {!!isCollapsible && (
          <Box
            className={classes.collapseButton}
            onClick={() => {
              setExpand((prevVal) => !prevVal);
            }}
            mt={2}
          >
            {expand ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            <Typography variant="body2">
              <b>{expand ? "Collapse" : "Expand"}</b>
            </Typography>
          </Box>
        )}
      </Box>
      <ScanAgainstSuspendPanel dateRange={currentFilter.dateRange} />
    </Box>
  );
}
