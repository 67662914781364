import React from "react";
import {
  makeStyles,
  Typography,
  Popover,
  Box,
  MenuItem,
  MenuList
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import clsx from "clsx";

const useStyle = makeStyles((theme) =>({
  button: {
    padding: 0,
    minWidth: 0,
  },
  list: {
    paddingTop: 6,
    paddingBottom: 6,
    alignItems: "right"
  },
  actionItemNormalText: {
    color: "#344054"
  },
  actionItemRedText: {
    color: "#f97066"
  }
}));

function ActionSelectPanelComponent({
  open,
  anchorEl,
  handleClose,
  id,
  style,
  items,
  isFetching,
  emptyMessage,
  handleListKeyDown,
  anchorOrigin,
  transformOrigin,
  PaperProps
}) {
  const classes = useStyle();

  return (
    <Popover
      id={id}
      open={open}
      style={style}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      PaperProps={PaperProps}
      elevation={2}
    >
      <Box>
        {isFetching ? (
          <>
            <Skeleton variant='text' animation="wave" height={25}/>
            <Skeleton variant='text' animation="wave" height={25}/>
            <Skeleton variant='text' animation="wave" height={25}/>
            <Skeleton variant='text' animation="wave" height={25}/>
          </>
        ) : items.length > 0 ? items.map((item) => (
          <Box key={item.name ? item.name : item}>
            <MenuList
              id="menu-list-grow"
              className={classes.list}
              onClick={() => handleListKeyDown(item)}
            >
              <MenuItem style={{display: "flex", justifyContent: "flex-end"}}>
                <Typography 
                  variant="body1" 
                  noWrap 
                  className={clsx(
                    classes.actionItemNormalText,
                    (item.name ? item.name : item) !== "Enable Selected" && classes.actionItemRedText
                  )}
                  style={{ paddingLeft: 6, paddingRight: 6 }}>
                  {item.name ? item.name : item}
                </Typography>
              </MenuItem>
            </MenuList>
          </Box>
        )) : (
          <Box py={9} style={{ textAlign: "center" }}>
            <Typography variant='body1'>{emptyMessage}</Typography>
          </Box>
        )}
      </Box>
    </Popover>
  )
}

export default ActionSelectPanelComponent