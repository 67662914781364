import React from "react"
import BoxListTableComponent from "./boxListTable.component"

export default function BoxListTableContainer({
  hasStockflowInventoryViewAccessRight,
  hasStockflowDealerViewAccessRight,
  rows,
  isFetching,
  selectedIds,
  isSelectAll,
  isError,
  handleReload,
  page,
  rowsPerPage,
  language,
  handleChangePage,
  handleChangeRowsPerPage,
  isSearchResult,
  totalFiltered,
  handleSelectBox,
  handleSelectAvailable,
  handleSelectAll,
  handleDeselectAll,
  token,
  paginationOptions,
  tableFilter,
  changeOrder,
  lang,
  isDealerPage = false,
  dealerId,
  handleViewDetail
}) {
  return (
    <BoxListTableComponent
      hasStockflowInventoryViewAccessRight={hasStockflowInventoryViewAccessRight}
      hasStockflowDealerViewAccessRight={hasStockflowDealerViewAccessRight}
      rows={rows}
      isFetching={isFetching}
      isSelectAll={isSelectAll} 
      selectedIds={selectedIds}
      isError={isError}
      handleReload={handleReload}
      language={language}
      page={page}
      rowsPerPage={rowsPerPage}
      handleChangePage={handleChangePage}
      handleChangeRowPerPage={handleChangeRowsPerPage}
      isSearchResult={isSearchResult}
      totalFiltered={totalFiltered}
      handleSelectBox={handleSelectBox}
      handleSelectAvailable={handleSelectAvailable}
      handleSelectAll={handleSelectAll}
      handleDeselectAll={handleDeselectAll}
      token={token}
      paginationOptions={paginationOptions}
      tableFilter={tableFilter}
      changeOrder={changeOrder}
      lang={lang}
      isDealerPage={isDealerPage}
      dealerId={dealerId}
      handleViewDetail={handleViewDetail}
    />
  )
}