import moment from "moment";

const API_HOST = process.env.REACT_APP_API_HOST_URL;

const saveFile = async (blob) => {
  const a = document.createElement("a");
  const date = moment().format("YYYYMMDD");
  a.download = `Gift_Code_Template${date}.xlsx`;
  a.href = URL.createObjectURL(blob);
  a.addEventListener("click", (e) => {
    setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
  });
  a.click();
};

const get = async ({ id, token }) => {
  let queryString = [`id=${id}`].join("&");
  let url;

  if (id === undefined)
    url = `${API_HOST}/campaign/api/admin/instant-lucky-draw/prizes/giftcode-excel`;
  else
    url = `${API_HOST}/campaign/api/admin/instant-lucky-draw/prizes/giftcode-excel?${queryString}`;

  let response = await fetch(url, {
    method: "Get",
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  let blob = await response.blob();

  saveFile(blob);
};

const giftCodeExcel = {
  get
};

export default giftCodeExcel;
