import React from "react"
import {
  Box,
  makeStyles,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Checkbox,
  Paper,
  TableSortLabel,
  Link,
} from "@material-ui/core"
import {
  StyledTableCell,
  StyledTableRow,
  StickyRightTableCell,
  StickyLeftTableCell,
} from "components/table"
import { SkeletonTableRowStickyLeftRight } from "components/skeleton/skeleton.component"
import { ActionButton } from "components/button"
import { TablePaginationActions } from "components/tablePagination"
import RefreshTable from "components/table/refreshTable"
import SelectAllCheckBox from 'components/selectAllCheckbox'
import clsx from "clsx"
import { ROOT } from "modules/stockflow/config/stockflow.route"
import moment from "moment"
import { KeyboardArrowDown } from "@material-ui/icons"
import DefaultImage from "assets/img/defaultPhoto.png"
import DefaultImg from "assets/img/img-default.png"
import { TIER_ICON_PROPS } from "modules/stockflow/constants"
import { getLang } from "app/feature/constants"
import "moment/min/locales.min";
import BranchIcon from "../../../../../components/icon/branchIcon";

const useStyle = makeStyles((theme) => ({
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    borderCollapse: "separate",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
    },
    "& th,td": {
      fontSize: ".75rem",
      borderRight: "1px solid rgba(58, 77, 84, 0.1)",
      padding: theme.spacing(1, 2, 1, 2),
    },
    "& th": {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightBold,
    },
    "& th:last-child, td:last-child": {
      width: 37,
      borderRight: "0px",
    },
    "& th th": {
      borderBottom: "none !important",
    },
    "& td td": {
      borderBottom: "none !important",
    },
  },
  emptyContainer: {
    textAlign: "center",
  },
  actionContainer: {
    justifyContent: "center",
  },
  paperRoot: {
    width: "300px",
  },
  selectAll: {
    '&.Mui-checked': {
      color: "#FDB022",
    },
  },
  selectOne: {
    '&.Mui-checked': {
      color: "#6AAF68",
    },
  },
  profile: {
    height: 26,
    width: 26,
    minWidth: 26,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& img": {
      height: "100%",
      minHeight: "100%",
      minWidth: "100%",
      objectFit: "cover",
      borderRadius: "50%",
      border: "1px solid #D0D5DD",
    }
  },
  tierIcon: {
    borderRadius: "3px",
    paddingLeft: "0.15em",
    paddingRight: "0.15em",
    display: "flex",
    alignItems: "center",
    position: "absolute",
    top: "-3px",
    left: "-8px"
  },
  tierLabel: {
    color: "#FFFFFF",
    fontSize: "0.6rem"
  },
  ellipsisText: {
    maxWidth: "18em",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: "0.75rem"
  },
}))

export default function StockoutListTableComponent({
  hasStockflowStockoutViewAccessRight,
  hasStockflowDealerViewAccessRight,
  type,
  rows,
  isFetching,
  isSelectAll,
  selectedIds,
  isError,
  handleReload,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowPerPage,
  isSearchResult,
  totalFiltered,
  language,
  handleSelectAvailable,
  handleSelectAll,
  handleDeselectAll,
  handleSelectStockout,
  paginationOptions,
  tableFilter,
  changeOrder,
  lang,
  isDealerPage,
  displayReceiveOnly,
  handleViewDetail
}) {
  const classes = useStyle()
  
  if(language==="ZH")
  {
    moment.locale('zh-cn')
  }else if(language==="TL")
  {
    moment.locale('tl-ph')
  }else
  {
    moment.locale(language)
  }

  const selectAllItems = [
    {
      label: getLang(lang,"paragraph.SELECT_AVAILABLE_ITEM", {count: rows.length > rowsPerPage ? rowsPerPage : rows.length}),
      action: () => handleSelectAvailable(),
    },
    {
      label: getLang(lang, "paragraph.SELECT_ALL_RECORDS"),
      action: () => handleSelectAll(),
    },
    {
      label: getLang(lang, "paragraph.DESELECT_ALL_RECORDS"),
      action: () => handleDeselectAll(),
      divider: true,
    },
  ]

  const statusColor = {
    "new": "#FDB022",
    "accepted": "#32D583",
    "rejected": "#F97066",
    "cancelled": "#98A2B3"
  }

  return (
    <>
      {!isFetching && isError && <RefreshTable handleReload={handleReload} />}
      {isFetching || rows.length > 0 ? (
        <Paper id="test"style={{overflowX: 'auto', width: "100%"}}>
          <TableContainer>
            <Table
              className={classes.table}
              aria-label="stockout table"
              size="small"
              stickyHeader
            >
              <TableHead style={{ height: "45px" }}>
                <TableRow>
                  {!isDealerPage && (
                    <StickyLeftTableCell >
                      <StyledTableCell padding="checkbox">
                        <SelectAllCheckBox
                          actionItems={selectAllItems}
                          disabled={isFetching}
                          isSelectAll={isSelectAll} 
                          selectedIdLength={selectedIds.length}
                          totalFiltered={totalFiltered}
                        />
                      </StyledTableCell>
                    </StickyLeftTableCell>
                  )}
                  <StyledTableCell>{getLang(lang, "label.STOCKOUT_ID")}</StyledTableCell>
                  {!isDealerPage && (
                    <StyledTableCell>{getLang(lang, "label.BRANCH")}</StyledTableCell>
                  )}
                  {(!isDealerPage || displayReceiveOnly) && (
                    <StyledTableCell>
                      <TableSortLabel
                        active={tableFilter.orderBy === 'stockout_from'}
                        direction={tableFilter.order}
                        onClick={() => {
                          const differentTab = tableFilter.orderBy !== "stockout_from"
                          const order = differentTab ? tableFilter.order : tableFilter.order === 'asc' ? "desc" : "asc"
                          changeOrder("stockout_from", order)

                        }}
                        IconComponent={KeyboardArrowDown}
                      >
                        {getLang(lang, "label.STOCKOUT_FROM")}
                      </TableSortLabel>
                    </StyledTableCell>
                  )}
                  {(!isDealerPage || !displayReceiveOnly) && (
                    <StyledTableCell>
                      <TableSortLabel
                        active={tableFilter.orderBy === 'stockout_to'}
                        direction={tableFilter.order}
                        onClick={() => {
                          const differentTab = tableFilter.orderBy !== "stockout_to"
                          const order = differentTab ? tableFilter.order : tableFilter.order === 'asc' ? "desc" : "asc"
                          changeOrder("stockout_to", order)

                        }}
                        IconComponent={KeyboardArrowDown}
                      >
                        {getLang(lang, "label.STOCKOUT_TO")}
                      </TableSortLabel>
                    </StyledTableCell>
                  )} 
                  {(!isDealerPage || displayReceiveOnly) && (
                    <StyledTableCell>{getLang(lang, "label.DEALER_INCENTIVE_POINT")}</StyledTableCell>
                  )}
                  <StyledTableCell>{getLang(lang, "label.STATUS")}</StyledTableCell>
                  <StyledTableCell>
                    <TableSortLabel
                      active={tableFilter.orderBy === 'created_at'}
                      direction={tableFilter.order}
                      onClick={() => {
                        const differentTab = tableFilter.orderBy !== "created_at"
                        const order = differentTab ? tableFilter.order : tableFilter.order === 'asc' ? "desc" : "asc"
                        changeOrder("created_at", order)
                      }}
                      IconComponent={KeyboardArrowDown}
                    >
                      {getLang(lang, "label.STOCKOUT_DATE")}
                    </TableSortLabel>
                  </StyledTableCell>
                  {type !== "new" && (
                    <StyledTableCell>
                      <TableSortLabel
                        active={tableFilter.orderBy === 'updated_at'}
                        direction={tableFilter.order}
                        onClick={() => {
                          const differentTab = tableFilter.orderBy !== "updated_at"
                          const order = differentTab ? tableFilter.order : tableFilter.order === 'asc' ? "desc" : "asc"
                          changeOrder("updated_at", order)

                        }}
                        IconComponent={KeyboardArrowDown}
                      >
                        {getLang(lang, "label.RESPOND_DATE")}
                      </TableSortLabel>
                    </StyledTableCell>
                  )}
                  {!isDealerPage && (
                    <StickyRightTableCell>
                      <StyledTableCell
                        style={{ width: "67px", textAlign: "center" }}
                      >
                        {getLang(lang, "label.ACTION")}
                      </StyledTableCell>
                    </StickyRightTableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {isFetching
                  ? [...new Array(5)].map((v, index) => (
                    <SkeletonTableRowStickyLeftRight 
                      key={index} 
                      columnCount={
                        isDealerPage 
                          ? displayReceiveOnly 
                            ? type === "new" ? 5 : 6 
                            : type === "new" ? 4 : 5
                          : type === "new" 
                            ? 9 : 10
                      } 
                    />
                  ))
                  : rows.map((row, index) => {
                    const actionItems = []

                    actionItems.push({
                      label: getLang(lang, "label.VIEW_DETAILS"),
                      action: () => {
                        handleViewDetail(`${ROOT}/stockout/${row.uuid}`)
                      },
                    })
                    // actionItems.push({
                    //   label: "Export XLS",
                    //   action: () => {
                    //     // TODO: add export function
                    //   },
                    // })

                    return (
                      <StyledTableRow
                        key={row.id}
                        hover
                        role="checkbox"
                        tabIndex={-1}
                      >
                        {!isDealerPage && (
                          <StickyLeftTableCell>
                            <StyledTableCell padding="checkbox">
                              <Checkbox
                                className={clsx({
                                  [classes.selectAll]: isSelectAll,
                                  [classes.selectOne]: row.isSelected && isSelectAll === false
                                })}
                                checked={row.isSelected}
                                onChange={(event) =>
                                  handleSelectStockout(
                                    index,
                                    event.target.checked
                                  )
                                }
                              />
                            </StyledTableCell>
                          </StickyLeftTableCell>
                        )}
                        <StyledTableCell>
                          {hasStockflowStockoutViewAccessRight ? (
                            <Link
                              href={`${ROOT}/stockout/${row.uuid}`}
                              color="secondary"
                              onClick={(e) => {
                                handleViewDetail(`${ROOT}/stockout/${row.uuid}`)
                                e.preventDefault();
                                return false;
                              }}
                            >
                              <Typography className={classes.ellipsisText}>{row.reference_code}</Typography>
                            </Link>
                          ) : (
                            <Typography className={classes.ellipsisText}>{row.reference_code}</Typography>
                          )}
                        </StyledTableCell>
                        {!isDealerPage && (
                            <StyledTableCell>
                              <Box display="flex" alignItems="center">
                                <Box pr={1}>
                                  <BranchIcon url={row.node_image_url} imageSize="40px" iconSize={22}/>
                                </Box>
                                <Box>
                                  {row.node || '-'}
                                </Box>
                              </Box>
                            </StyledTableCell>
                        )}
                        {(!isDealerPage || displayReceiveOnly) && (
                          <StyledTableCell>
                            {row.stockout_from ? (
                              <Box display="flex" alignItems="center">
                                <Box className={classes.profile} mr={1} position="relative" >
                                  {row.stockout_from.picture === "" ? (
                                    <img src={row.stockout_from.type === "Headquarter" ? DefaultImg : DefaultImage} alt="default profile" />
                                  ) : (
                                    <img src={row.stockout_from.picture} alt={row.name} />
                                  )}
                                  {!!row.stockout_from.tier && (
                                    <Box
                                      className={classes.tierIcon}
                                      style={{
                                        backgroundColor: TIER_ICON_PROPS.find(({ tier }) => tier === row.stockout_from.tier).color
                                      }}
                                    >
                                      <Typography className={classes.tierLabel}>
                                        T{row.stockout_from.tier}
                                      </Typography>
                                    </Box>
                                  )}
                                </Box>
                                {row.stockout_from.type === "Headquarter" || !hasStockflowDealerViewAccessRight ? (
                                  <Typography className={classes.ellipsisText}>{row.stockout_from.name}</Typography>
                                ) : (
                                  <Link
                                    href={row.stockout_from.type !== 'Headquarter' ? `${ROOT}/my-dealer/${row.stockout_from.uuid}` : '#'}
                                    color="secondary"
                                    onClick={(e) => {
                                      if (row.stockout_from.type !== 'Headquarter') {
                                        handleViewDetail(`${ROOT}/my-dealer/${row.stockout_from.uuid}`)
                                      }
                                      e.preventDefault();
                                      return false;
                                    }}
                                  >
                                    <Typography className={classes.ellipsisText}>{row.stockout_from.name}</Typography>
                                  </Link>
                                )}
                              </Box>
                            ) : (
                              <span>-</span>
                            )}
                          </StyledTableCell>
                        )}
                        {(!isDealerPage || !displayReceiveOnly) && (
                          <StyledTableCell>
                            {row.stockout_to ? (
                              <Box display="flex">
                                <Box className={classes.profile} mr={1} position="relative">
                                  {!!row.stockout_to.picture ? (
                                    <img src={row.stockout_to.picture} alt={row.name} />
                                  ) : (
                                    <img src={DefaultImage} alt="default profile" />
                                  )}
                                  {!!row.stockout_to.tier && (
                                    <Box
                                      className={classes.tierIcon}
                                      style={{
                                        backgroundColor: TIER_ICON_PROPS.find(({ tier }) => tier === row.stockout_to.tier).color
                                      }}
                                    >
                                      <Typography className={classes.tierLabel}>
                                        T{row.stockout_to.tier}
                                      </Typography>
                                    </Box>
                                  )}
                                </Box>
                                {hasStockflowDealerViewAccessRight ? (
                                  <Link
                                    href={`${ROOT}/my-dealer/${row.stockout_to.uuid}`}
                                    color="secondary"
                                    onClick={(e) => {
                                      handleViewDetail(`${ROOT}/my-dealer/${row.stockout_to.uuid}`)
                                      e.preventDefault();
                                      return false;
                                    }}
                                  >
                                    <Typography className={classes.ellipsisText}>{row.stockout_to.name}</Typography>
                                  </Link>
                                ) : (
                                  <Typography className={classes.ellipsisText}>{row.stockout_to.name}</Typography>
                                )}
                              </Box>
                            ) : (
                              <span>-</span>
                            )}
                          </StyledTableCell>
                        )}
                        {(!isDealerPage || displayReceiveOnly) && (
                          <StyledTableCell align="right">
                            <span>
                              {row.point ? row.point.toLocaleString("en-US") : '0'}
                            </span>
                          </StyledTableCell>
                        )}
                        <StyledTableCell>
                          <span style={{ color: statusColor[type] }}>
                            {row.status ? getLang(lang, `db_label.${row.status}`) : '-'}
                          </span>
                        </StyledTableCell>
                        <StyledTableCell>
                          <span>
                            {moment.utc(row.stockout_date).local().format('lll')}
                          </span>
                        </StyledTableCell>
                        {type !== "new" && (
                          <StyledTableCell>
                            <span>
                              {moment.utc(row.respond_date).local().format('lll')}
                            </span>
                          </StyledTableCell>
                        )}
                        {!isDealerPage && (
                          <StickyRightTableCell>
                            <StyledTableCell style={{ width: "67px" }}>
                              <ActionButton
                                actionItems={actionItems}
                                popperStyle={{ zIndex: 999 }}
                              />
                            </StyledTableCell>
                          </StickyRightTableCell>
                        )}
                      </StyledTableRow>
                    )
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            style={{
              backgroundColor: "#fff",
              boxShadow: "0px -1px 0px rgba(58, 77, 84, 0.15)",
            }}
            ActionsComponent={TablePaginationActions}
            rowsPerPageOptions={paginationOptions}
            component="div"
            count={totalFiltered}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowPerPage}
            labelRowsPerPage={getLang(lang, 'label.ROWS_PER_PAGE')}
            labelDisplayedRows={({ from, to, count }) => `${from.toLocaleString("en-US")}-${to.toLocaleString("en-US")} ${getLang(lang, 'label.OF')} ${count.toLocaleString("en-US")}`}
          />
        </Paper>
      ) : null}
      {!isFetching && !isError && rows.length === 0 && (
        <Box style={{overflowX: 'auto', backgroundColor: '#fff'}}>
          <TableContainer>
            <Table
              className={classes.table}
              aria-label="stockout table"
              size="small"
              stickyHeader
            >
              <TableHead style={{ height: "45px" }}>
                <TableRow>
                  {!isDealerPage && (
                    <StickyLeftTableCell style={{ textAlign: "center" }}>
                      <SelectAllCheckBox disabled={true} />
                    </StickyLeftTableCell>
                  )}
                  <StyledTableCell>{getLang(lang, "label.STOCKOUT_ID")}</StyledTableCell>
                  {!isDealerPage && (
                    <StyledTableCell>{getLang(lang, "label.BRANCH")}</StyledTableCell>
                  )}
                  {(!isDealerPage || displayReceiveOnly) && (
                    <StyledTableCell>{getLang(lang, "label.STOCKOUT_FROM")}</StyledTableCell>
                  )}
                  {(!isDealerPage || !displayReceiveOnly) && (
                    <StyledTableCell>{getLang(lang, "label.STOCKOUT_TO")}</StyledTableCell>
                  )}
                  {(!isDealerPage || displayReceiveOnly) && (
                    <StyledTableCell>{getLang(lang, "label.DEALER_INCENTIVE_POINT")}</StyledTableCell>
                  )}
                  <StyledTableCell>{getLang(lang, "label.STATUS")}</StyledTableCell>
                  <StyledTableCell>{getLang(lang, "label.STOCKOUT_DATE")}</StyledTableCell>
                  {type !== "new" && (
                    <StyledTableCell>{getLang(lang, "label.RESPOND_DATE")}</StyledTableCell>
                  )}
                  {!isDealerPage && (
                    <StickyRightTableCell>
                      <StyledTableCell>{getLang(lang, "label.ACTION")}</StyledTableCell>
                    </StickyRightTableCell>
                  )}
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>
          <Box py={9} className={classes.emptyContainer}>
            <Typography variant="body1" style={{ paddingBottom: "16px", fontWeight: "bold" }}>
              {isSearchResult || isDealerPage
                ? getLang(lang, "paragraph.NO_STOCKOUT_EXISTS")
                : getLang(lang, "paragraph.NO_STOCKOUT_CREATED")}
            </Typography>
            {!isSearchResult && !isDealerPage && (
              <Typography variant="body2" style={{ paddingBottom: "16px" }}>
                {getLang(lang, "paragraph.STOCKOUT_INSTRUCTION")}
              </Typography>
            )}
          </Box>
        </Box>
      )}
    </>
  )
}
