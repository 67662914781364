import React from "react";
import {
  Box,
  Paper,
  Typography,
  makeStyles,
  Divider,
  Button,
} from "@material-ui/core";
import InfiniteScroll from "react-infinite-scroll-component";
import SurveyAnswerSearch from "../../search/surveryAnswerSearch";
import DefaultImg from "assets/img/img-default.png";
import SearchIcon from "assets/svg/search.svg";
import { Skeleton } from "@material-ui/lab";
import LoadingComponent from "components/loading/loading.component";
import { getLang } from "app/feature/constants";
import moment from "moment/moment";
import "moment/min/locales.min";

const useStyle = makeStyles((theme) => ({
  container: {
    boxShadow:
      "0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);",
    borderRadius: 6,
    padding: "12px 16px",
  },
  perfectScrollbar: {
    height: 400,
    padding: "12px 12px 0px 12px",
  },
  answerContainer: {
    backgroundColor: "#F9FAFB",
    borderRadius: "6px",
    padding: 12,
    marginBottom: "16px",
  },
  profileImg: {
    height: 50,
    width: 50,
    borderRadius: "50%",
  },
  search: {
    width: 80,
    height: 80,
  },
  emptyRespondents: {
    paddingTop: 20,
    paddingBottom: 20,
    backgroundColor: "#F9FAFB",
  },
  button: {
    width: 352,
    height: 40,
    borderRadius: 35,
    textTransform: "capitalize",
  },
}));

export default function SurveyAnswerCardComponent({
  answerList,
  isShowAnswer,
  handleSearch,
  isAnswerFetching,
  isAnswerFilter,
  handleFilter,
  handleFetchMoreAnswerListing,
  totalFilter,
  language,
  copyLink,
  lang,
}) {
  const classes = useStyle();

  if (language === "ZH") {
    moment.locale("zh-cn");
  } else if (language === "TL") {
    moment.locale("tl-ph");
  } else {
    moment.locale(language);
  }

  return (
    <Paper variant="outlined" className={classes.container}>
      <Box style={{ marginBottom: "12px" }}>
        <Typography
          variant="subtitle1"
          color="textPrimary"
          style={{ fontWeight: "400" }}
        >
          {getLang(lang, "label.ANSWER")}
        </Typography>
      </Box>
      <Divider />
      <Box>
        <SurveyAnswerSearch
          handleFilter={(value) => {
            handleSearch(value);

            const isAnswerFilter =
              value.search === "" && value.productSelect === "";
            handleFilter(isAnswerFilter);
          }}
        />
      </Box>
      {isAnswerFetching && answerList.length === 0 ? (
        <>
          <Skeleton
            variant="rect"
            height={100}
            style={{ marginBottom: "16px" }}
          />
          <Skeleton variant="rect" height={100} />
        </>
      ) : answerList.length > 0 ? (
        <InfiniteScroll
          height={400}
          dataLength={answerList.length}
          next={handleFetchMoreAnswerListing}
          hasMore={totalFilter > answerList.length}
          loader={<LoadingComponent height={50} />}
        >
          {answerList.map((row, index) => (
            <Box key={row + index} className={classes.answerContainer}>
              <Box>
                {Array.isArray(row.selectedAnswer) ? (
                  <ul
                    style={{
                      margin: "0",
                      padding: "0",
                      paddingLeft: "16px",
                      listStyle: "square",
                    }}
                  >
                    {row.selectedAnswer.map((response) => {
                      return (
                        <li>
                          {" "}
                          <Typography
                            variant="body1"
                            color="textPrimary"
                            style={{ fontWeight: "500" }}
                          >
                            {" "}
                            {response}{" "}
                          </Typography>
                        </li>
                      );
                    })}
                  </ul>
                ) : (
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    style={{ fontWeight: "500" }}
                  >
                    {row.selectedAnswer === "skip" ? (
                      <span style={{ color: "#98A2B3" }}>Skipped</span>
                    ) : (
                      row.selectedAnswer
                    )}
                  </Typography>
                )}
              </Box>
              <Box display="flex" justifyContent="flex-end" mt={1} mb={1}>
                <Typography variant="caption" color="textSecondary">
                  {moment(row.createdAt).format("lll")}
                </Typography>
              </Box>
              {!isShowAnswer && (
                <Box style={{ backgroundColor: "#fff", padding: "8px" }}>
                  <Box display="flex">
                    <img
                      src={row.image !== "anonymous" ? row.image : DefaultImg}
                      className={classes.profileImg}
                      alt="respondent"
                    />
                    <Box ml={1}>
                      <Typography variant="subtitle2" color="textPrimary">
                        {row.name}
                      </Typography>
                      <Typography variant="subtitle2" color="textSecondary">
                        {row.email}
                      </Typography>
                    </Box>
                  </Box>
                  <Box pt={2}>
                    <Typography variant="subtitle2">
                      <span style={{ color: "#667085" }}>
                        {row.productName}
                      </span>
                      <span style={{ color: "#344054" }}>
                        {row.serialNumber}
                      </span>
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
          ))}
        </InfiniteScroll>
      ) : (
        <Box className={classes.emptyRespondents}>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box>
              <img src={SearchIcon} className={classes.search} alt="" />
            </Box>
            <Box
              mb={3}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography variant="h6">
                {getLang(lang, "message.info.SURVEY_NO_RESPONDENTS")}
              </Typography>
              <Typography varaint="body2">
                {getLang(
                  lang,
                  "message.info.COPY_URL_SEND_CUSTOMER_GET_SURVEY_RESULT"
                )}
              </Typography>
            </Box>
            <Box>
              <Button
                variant="contained"
                color="secondary"
                onClick={copyLink}
                className={classes.button}
              >
                {getLang(lang, "label.COPY_URL")}
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </Paper>
  );
}
