import React from "react"
import { 
  makeStyles, 
  Box, 
  Typography, 
} from '@material-ui/core'
import { Skeleton } from "@material-ui/lab"
import ActivityLogTimeline from "../../timeline/activityLogTimeline"
import { getLang } from "app/feature/constants"

const useStyle = makeStyles((theme) => ({
  container: {
    boxSizing: "border-box",
    maxHeight: "85vh",
    backgroundColor: "#FFFFFF",
    padding: "4px"
  },
  field: {
    display: "grid",
    gridTemplateColumns: "3fr 7fr",
    columnGap: theme.spacing(2)
  },
  activeText: {
    color: "#32D583",
  },
  activeChipStatus: {
    backgroundColor: "#ECFDF3",
    width: "fit-content",
    textTransform: "capitalize",
    color: "#027A48",
    fontWeight: "bold"
  },
  suspendChipStatus: {
    backgroundColor: "#fdecec",
    width: "fit-content",
    textTransform: "capitalize",
    color: "red",
    fontWeight: "bold"
  },
  activityLogContainer: {
    padding: theme.spacing(2, 2),
    flex: 1,
    height: "100%",
    overflowY: "auto",
    overflowX: "hidden",
    '&::-webkit-scrollbar': {
      width: "10px"
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: "16px"
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: "#E4E7EC",
      borderRadius: "16px"
    },
  }
}))

export default function SerialNumberActivityLogPanelComponent({
  log,
  isFetching,
  language,
  isError,
  loading,
  lang
}) {
  const classes = useStyle()

  return (
    <Box className={classes.container} display="flex" flexDirection="column">
      {!isFetching && !loading && !isError ? (
        <Box className={classes.activityLogContainer}>
          <Box>
            <Typography variant="body1">
              <b>{getLang(lang, "label.ACTIVITY_LOG")}</b>
            </Typography>
            <Box mt={2}>
              <ActivityLogTimeline activityLog={log} lang={lang} language={language} />
            </Box>
          </Box>
        </Box>
      ) : (
        <Box className={classes.activityLogContainer}>
          <Skeleton variant='text' height={30} style={{ marginTop: 20 }} />
          <Skeleton variant='text' height={30} />
          <Skeleton variant='text' height={70} />
          <Skeleton variant='text' height={70} />
          <Skeleton variant='text' height={70} />
        </Box>
      )}
    </Box>
  )
}