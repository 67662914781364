import { createSelector } from "@reduxjs/toolkit";
import { getObjectByString } from "lib/helper";

export const selectLang = createSelector(
  (_, key) => key,
  (langState) => langState.data,
  (langState) => langState.fallbackData,
  (key, fetchLang, fallbackLang) => {
    return getObjectByString(fetchLang, key) ?? getObjectByString(fallbackLang, key) ?? key
  }
)

export const selectLangValues = createSelector(
  (_, keyValues) => keyValues,
  (langState) => langState.data,
  (langState) => langState.fallbackData,
  (keyValues, fetchLang, fallbackLang) => {
    let key = keyValues.key;
    let values = keyValues.values;
    let lang = getObjectByString(fetchLang, key) ?? getObjectByString(fallbackLang, key) ?? key;
    if (lang !== key && Object.keys(values).length > 0) {
      Object.keys(values).forEach((key) => {
        lang = lang.replace(`{${key}}`, values[key])
      });
    }
    return lang;
  }
)

export const getLang = (langState, key, values = null) => {
  if (values === null) {
    return selectLang(langState, key)
  }
  else {
    return selectLangValues(langState, {
      key: key,
      values: values
    });
  }
}
export const selectApiLang = createSelector(
  (_, key) => key,
  (langState) => langState.apiData,
  (langState) => langState.fallbackApiData,
  (key, fetchLang, fallbackLang) => {
    return getObjectByString(fetchLang, key) ?? getObjectByString(fallbackLang, key) ?? key
  }
)

export const selectApiLangValues = createSelector(
  (_, keyValues) => keyValues,
  (langState) => langState.apiData,
  (langState) => langState.fallbackApiData,
  (keyValues, fetchLang, fallbackLang) => {
    let key = keyValues.key;
    let values = keyValues.values;
    let lang = getObjectByString(fetchLang, key) ?? getObjectByString(fallbackLang, key) ?? key;
    if (lang !== key && Object.keys(values).length > 0) {
      Object.keys(values).forEach((key) => {
        lang = lang.replace(`{${key}}`, values[key])
      });
    }
    return lang;
  }
)

export const getApiLang = (langState, key, values = null) => {
  if (values === null) {
    return selectApiLang(langState, key)
  }
  else {
    return selectApiLangValues(langState, {
      key: key,
      values: values
    });
  }
}