import React, { useState } from "react"
import DealerDashboardPanelComponent from "./dealerDashboardPanel.component"
import { datetimeValidation, periodValidation } from 'modules/reporting/utils/helper'
import { getLang } from "app/feature/constants"
import { useDispatch, useSelector } from "react-redux"
import moment from "moment"
import { useEffect } from "react"
import { 
  getDealerInvitedSummary, 
  getDealerInvitedTrending, 
  getTotalRecallTrending, 
  getTotalStockoutTrending 
} from "modules/stockflow/redux"
import { useRouteMatch } from "react-router-dom"
import { selectLoading } from "modules/notification"

function DealerDashboardPanelContainer() {
  const query = new URLSearchParams(window.location.search)
  const period = query.get('period') ? query.get('period') : "daily"
  const date_start = query.get('start') ? datetimeValidation(query.get('start') , query.get('end'))[0] : new Date(Date.now() - 13 * 24 * 60 * 60 * 1000)
  const date_end = query.get('end') ?  datetimeValidation(query.get('end') , query.get('end'))[1] : new Date()

  const lang = useSelector(state => state.constant.languages)
  const dispatch = useDispatch();
  const match = useRouteMatch()
  const id = match.params.dealerId


  const isFetchingTotalStockoutTrending = useSelector(state => selectLoading(state, getTotalStockoutTrending.typePrefix));
  const totalStockoutData = useSelector(state => state.stockflowDealer.dashboard.totalStockoutTrend);

  const isFetchingTotalRecallTrending = useSelector(state => selectLoading(state, getTotalRecallTrending.typePrefix));
  const totalRecallData = useSelector(state => state.stockflowDealer.dashboard.totalRecallTrend);

  const isFetchingDealerInvitedTrending = useSelector(state => selectLoading(state, getDealerInvitedTrending.typePrefix));
  const dealerInvitedData = useSelector(state => state.stockflowDealer.dashboard.totalDealerInvitedTrend);

  const [currentFilter, setCurrentFilter] = useState({
    period: periodValidation(period) ? period : "daily",
    dateRange: [ date_start, date_end ],
  })

  const periodMenuItem = [
    {
      name: getLang(lang, "label.DAILY"),
      value: "daily",
    },
    {
      name: getLang(lang, "label.WEEKLY"),
      value: "weekly",
    },
    {
      name: getLang(lang, "label.MONTHLY"),
      value: "monthly",
    },
  ]

  useEffect(() => {
    dispatch(getDealerInvitedTrending({
      dealer_uuid: id,
      period: currentFilter.period,
      start_date: moment(currentFilter.dateRange[0]).format('YYYY-MM-DD'),
      end_date: moment(currentFilter.dateRange[1]).format('YYYY-MM-DD'),
    }))
    dispatch(getDealerInvitedSummary({
      dealer_uuid: id,
      period: currentFilter.period,
      start_date: moment(currentFilter.dateRange[0]).format('YYYY-MM-DD'),
      end_date: moment(currentFilter.dateRange[1]).format('YYYY-MM-DD'),
    }))
    dispatch(getTotalStockoutTrending({
      dealer_uuid: id,
      period: currentFilter.period,
      start_date: moment(currentFilter.dateRange[0]).format('YYYY-MM-DD'),
      end_date: moment(currentFilter.dateRange[1]).format('YYYY-MM-DD'),
    }))
    dispatch(getTotalRecallTrending({
      dealer_uuid: id,
      period: currentFilter.period,
      start_date: moment(currentFilter.dateRange[0]).format('YYYY-MM-DD'),
      end_date: moment(currentFilter.dateRange[1]).format('YYYY-MM-DD'),
    }))
    dispatch(getDealerInvitedTrending({
      dealer_uuid: id,
      period: currentFilter.period,
      start_date: moment(currentFilter.dateRange[0]).format('YYYY-MM-DD'),
      end_date: moment(currentFilter.dateRange[1]).format('YYYY-MM-DD'),
    }))
  }, [currentFilter, dispatch, id])

  const updatePeriod = (value) => {
    var start,
      end = null

    switch (value) {
      case "daily":
        setCurrentFilter((prev) => {
          return { ...prev, period: value }
        })
        break
      case "weekly":
        start = moment(currentFilter.dateRange[0]).toDate()
        end = moment(currentFilter.dateRange[1]).toDate()
        let week = Math.floor(moment(end).diff(start, "days") / 7)

        end = moment(start).add(6, "days").toDate()

        if (week >= 1) {
          let days = week * 7
          end = moment(end).add(days, "days").toDate()
        }

        if (end.getTime() > Date.now()) {
          end = moment().toDate()
        }

        setCurrentFilter((prev) => {
          return { ...prev, period: value, dateRange: [start, end] }
        })
        break
      case "monthly":
        start = moment(currentFilter.dateRange[0])
          .startOf("month")
          .toDate()
        end = moment(currentFilter.dateRange[1])
          .endOf("month")
          .toDate()

        if (end > Date.now()) {
          end = moment().toDate()
        }
        setCurrentFilter((prev) => {
          return { ...prev, period: value, dateRange: [start, end] }
        })
        break
      default:
        break
    }
  }

  const updateDateRange = (start, end, period = null) => {
    if (period) {
      setCurrentFilter((prev) => {
        return { ...prev, dateRange: [start, end], period: period }
      })
    } else {
      setCurrentFilter((prev) => {
        return { ...prev, dateRange: [start, end] }
      })
    }
  }
  
  const translate = (text) => {
    return getLang(lang, text);
  }

  return (
    <DealerDashboardPanelComponent
      status={{
        isFetchingTotalStockoutTrending,
        isFetchingTotalRecallTrending,
        isFetchingDealerInvitedTrending
      }}
      trendingData={{
        totalStockoutData,
        totalRecallData,
        dealerInvitedData
      }}
      id={id}
      filter={currentFilter}
      updatePeriod={updatePeriod}
      updateDateRange={updateDateRange}
      periodMenuItem={periodMenuItem}
      translate={translate}
    />
  )
}

export default DealerDashboardPanelContainer
