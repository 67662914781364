import React from "react";
import { Typography, Box } from "@material-ui/core";
import { getLang } from "app/feature/constants";
import {
  IMPORT_ADT_STATUS_FAIL,
  IMPORT_ADT_STATUS_SUCCESS,
} from "modules/serial-number/constants";

export default function ImportAdtInfoStatusProgressComponent({
  lang,
  progress,
  status,
}) {
  return (
    <Box>
      <Typography
        variant="caption"
        style={{
          color:
            status === IMPORT_ADT_STATUS_FAIL
              ? "#FDB022"
              : status === IMPORT_ADT_STATUS_SUCCESS
              ? "#32D583"
              : "#989898",
          textTransform: "capitalize",
        }}
      >
        {status === IMPORT_ADT_STATUS_FAIL
          ? getLang(lang, "label.WARNING")
          : status === IMPORT_ADT_STATUS_SUCCESS
          ? getLang(lang, "label.SUCCESS")
          : `${progress}%`}
      </Typography>
    </Box>
  );
}
