import React, { useEffect, useState } from "react";
import ExtensionListTableComponent from "./extensionListTable.component";
import { selectLoading } from "modules/notification";
import {connect, useDispatch, useSelector} from "react-redux";
import {fetchWarrantyExtensionBatch, setIsWarrantyExtensionBatchTableDirty} from "../../../redux";

function ExtensionListTableContainer({
  isFetching,
  tagId,
  warrantyExtensionBatchData,
  fetchWarrantyExtensionBatch,
  token,
  index,
  lang
}) {
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [pageSetting, setPageSetting] = useState({
    length: 5,
    start: 0,
    column: "created_at",
    order: "desc",
    search: ""
  });
  const isWarrantyExtensionBatchTableDirty = useSelector(state => state.warranty.isWarrantyExtensionBatchTableDirty);
  const language = useSelector(state => state.profile.language);

  useEffect(() => {
    if (isWarrantyExtensionBatchTableDirty === true) {
      dispatch(setIsWarrantyExtensionBatchTableDirty(false));

      const generateInfo = {
        id: tagId,
        index: index,
        ...pageSetting
      };
      fetchWarrantyExtensionBatch(generateInfo);

      return;
    }

    const generateInfo = {
      id: tagId,
      index: index,
      ...pageSetting
    };
    fetchWarrantyExtensionBatch(generateInfo);
  }, [pageSetting, fetchWarrantyExtensionBatch, tagId, index, dispatch, isWarrantyExtensionBatchTableDirty]);

  function handlePageChange(event, page) {
    setPage(page);
    setPageSetting({
      length: pageSetting.length,
      start: pageSetting.length * page,
      column: pageSetting.column,
      order: pageSetting.order,
      search: pageSetting.search
    });
  }

  function handleRowPerPageChange(value) {
    setPage(0);
    setPageSetting({
      length: value,
      start: value * 0,
      column: pageSetting.column,
      order: pageSetting.order,
      search: pageSetting.search
    });
  }

  return (
    <ExtensionListTableComponent
      rows={warrantyExtensionBatchData[index].data}
      page={page}
      rowsPerPage={pageSetting.length}
      handlePageChange={handlePageChange}
      handleRowPerPageChange={handleRowPerPageChange}
      isFetching={isFetching}
      language={language}
      length={
        pageSetting.search
          ? warrantyExtensionBatchData[index].filteredTotalRecords
          : warrantyExtensionBatchData[index].totalRecords
      }
      token={token}
      lang={lang}
    />
  );
}

const mapDispatchToProps = (dispatch) => ({
  fetchWarrantyExtensionBatch: (value) =>
    dispatch(fetchWarrantyExtensionBatch(value))
});

const mapStateToProps = (state) => ({
  warrantyExtensionBatchData: state.warranty.warrantyExtensionBatchTable,
  isFetching: selectLoading(state, fetchWarrantyExtensionBatch.typePrefix),
  token: state.session.accessToken
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExtensionListTableContainer);
