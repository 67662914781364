import React from "react";
import { LoginLayout, CreditKoodFooter } from "../../components/layout";
import { makeStyles, Box, Paper, Divider } from "@material-ui/core";
import Header from "../../components/header";
import Footer from "../../components/footer";
import ResetPasswordInput from "../../components/form/reset-password-input";

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: "900px",
    position: "absolute",

    boxShadow: "0px 10px 15px rgba(0, 0, 0, 0.1)",
    [theme.breakpoints.down("md")]: {
      alignSelf: "center",
      width: "45%"
    },
    [theme.breakpoints.down("sm")]: {
      alignSelf: "center",
      width: "60%"
    },
    [theme.breakpoints.down("xs")]: {
      alignSelf: "center",
      width: "80%"
    }
  }
}));

export default function ResetPassword() {
  const classes = useStyles();

  return (
    <LoginLayout>
      <Box className={classes.root}>
        <Paper className={classes.paper} elevation={0}>
          <Header />
          <Divider />
          <Paper className={classes.form} elevation={0}>
            <ResetPasswordInput />
          </Paper>
          <Divider />
          <Box>
            <Footer type={"Login"} />
          </Box>
        </Paper>
        <CreditKoodFooter />
      </Box>
    </LoginLayout>
  );
}
