import React, { useRef } from "react";
import {
  makeStyles,
  Typography,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  InputAdornment,
  Box,
  Button,
  Divider,
  MenuItem,
  MenuList,
  Checkbox,
} from "@material-ui/core";
import PerfectScrollbar from "react-perfect-scrollbar";
import DefaultImg from "assets/img/img-default.png";
import SearchIcon from "@material-ui/icons/Search";
import Skeleton from "@material-ui/lab/Skeleton";
import InputTextField from "components/input/inputTextField";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles((theme) => ({
  paper: {
    width: 425,
  },
  button: {
    padding: 0,
    minWidth: 0,
  },
  productPhoto: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #E4E7EC",
    width: "30px",
    height: "30px",
    marginRight: "10px",
    "& img": {
      height: "30px",
      width: "30px",
    },
  },
  name: {
    whiteSpace: "normal",
    overflow: "hidden",
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "line-clamp": 2,
    "-webkit-box-orient": "vertical",
  },
  attributeText: {
    color: "#b2b2b2",
    fontSize: 12,
    whiteSpace: "break-spaces",
  },
}));

export default function StockProductSelectPanelComponent({
  selectAll,
  value,
  anchorRef,
  open,
  isOpenList,
  handleClose,
  isFetching,
  stockProductDropdown,
  handleSelectAll,
  handleSelectProduct,
  handleSearch,
  lang,
}) {
  const classes = useStyle();
  const textRef = useRef();

  return (
    <Popper
      open={open}
      style={{ zIndex: 99 }}
      anchorEl={anchorRef.current}
      role={undefined}
      transition
      disablePortal
      placement="bottom-start"
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === "bottom-start" ? "center top" : "center bottom",
          }}
        >
          <Paper className={classes.paper}>
            <ClickAwayListener onClickAway={handleClose}>
              <Box>
                <Box py={0.5}>
                  <InputTextField
                    variant="filled"
                    size="small"
                    fullWidth
                    inputRef={textRef}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          style={{ paddingRight: 6 }}
                        >
                          <Button
                            onClick={() => handleSearch(textRef.current.value)}
                            className={classes.button}
                          >
                            <SearchIcon />
                          </Button>
                        </InputAdornment>
                      ),
                      style: {
                        backgroundColor: "#fff",
                        paddingRight: 0,
                      },
                    }}
                    inputProps={{
                      onKeyDown: (e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                          handleSearch(textRef.current.value);
                          return false;
                        }
                      },
                      style: {
                        padding: 8,
                      },
                    }}
                    placeholder={getLang(lang, "placeholder.SEARCH")}
                  />
                </Box>
                <Divider />
                <PerfectScrollbar>
                  {isFetching ? (
                    <Box p={1}>
                      <Skeleton variant="text" animation="wave" />
                      <Skeleton variant="text" animation="wave" />
                      <Skeleton variant="text" animation="wave" />
                      <Skeleton variant="text" animation="wave" />
                    </Box>
                  ) : stockProductDropdown.length > 0 ? (
                    <div>
                      <MenuList
                        autoFocusItem={isOpenList}
                        id="menu-list-grow"
                        onClick={() => {
                          handleSelectAll();
                        }}
                        style={{ padding: 0 }}
                      >
                        <MenuItem style={{ padding: 0 }}>
                          <Checkbox checked={selectAll} />
                          <Typography
                            variant="inherit"
                            noWrap
                            style={{ paddingLeft: "8px" }}
                          >
                            {getLang(lang, "label.ALL")}
                          </Typography>
                        </MenuItem>
                      </MenuList>
                      <Divider />

                      <Box height="25vh" overflow="auto">
                        {stockProductDropdown.map((product, index) => {
                          return (
                            <React.Fragment key={index}>
                              <MenuItem
                                style={{ padding: "4px 0px" }}
                                onClick={() => handleSelectProduct(product)}
                              >
                                <Box display="flex">
                                  <Checkbox
                                    checked={
                                      !!value.find((v) => v === product.id)
                                    }
                                  />
                                  <Box display="flex" alignItems="center">
                                    <Box className={classes.productPhoto}>
                                      {product.picture === "" ? (
                                        <img
                                          src={DefaultImg}
                                          alt="emptyProduct"
                                        />
                                      ) : (
                                        <img
                                          src={product.picture}
                                          alt={product.name}
                                        />
                                      )}
                                    </Box>
                                    <Box
                                      display="flex"
                                      flexDirection="column"
                                      justifyContent="center"
                                      alignItems="flex-start"
                                    >
                                      <Typography
                                        className={classes.name}
                                        variant="body2"
                                      >
                                        {product.name}
                                      </Typography>
                                      {!!product.attributes &&
                                        !!product.attributes.length && (
                                          <Typography
                                            className={classes.attributeText}
                                            variant="body2"
                                          >
                                            {product.attributes.join(", ")}
                                          </Typography>
                                        )}
                                    </Box>
                                  </Box>
                                </Box>
                              </MenuItem>
                            </React.Fragment>
                          );
                        })}
                      </Box>
                    </div>
                  ) : (
                    <Box p={2} style={{ textAlign: "center" }}>
                      <Typography variant="body1">
                        {getLang(lang, "paragraph.NO_PRODUCT_EXIST")}
                      </Typography>
                    </Box>
                  )}
                </PerfectScrollbar>
              </Box>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}
