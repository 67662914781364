import { TableCell, withStyles } from "@material-ui/core";

export default withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.white,
        right: 0,
        position: "sticky",
        zIndex: 90,
        padding: "0px 0px 0px 0px !important",
        borderLeft: "1px solid rgba(58, 77, 84, 0.1)"
      },
      body: {
        backgroundColor: theme.palette.common.white,
        right: 0,
        position: "sticky",
        zIndex: 90,
        padding: "0px 0px 0px 0px !important",
        borderLeft: "1px solid rgba(58, 77, 84, 0.1)"
      }
}))(TableCell);