import React, { useRef } from "react";
import {
  makeStyles,
  Typography,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  Box,
  Divider,
  InputAdornment,
  Button,
  MenuItem,
  MenuList
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import InputTextField from "components/input/inputTextField";
import Skeleton from "@material-ui/lab/Skeleton";
import clsx from "clsx";
import InfiniteScroll from "react-infinite-scroll-component";
import LoadingComponent from "components/loading";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles((theme) => ({
  paper: {
    width: 425
  },
  button: {
    padding: theme.spacing(1),
    minWidth: 0
  },
  buttonAddWarranty: {
    display: "flex",
    padding: 0,
    minWidth: 0
  },
  createLink: {
    padding: theme.spacing(2)
  },
  menuListOdd: {
    backgroundColor: "#EBEDEE"
  },
  scroll: {
    overflowY: "auto",
    maxHeight: "250px"
  },
  emptyContainer: {
    backgroundColor: theme.palette.common.white,
    textAlign: "center"
  },
  menuItemContainer: {
    display: "flex",
    justifyContent: "space-between",
    placeItems: "flex-start"
  },
  menuList: {
    padding: "4px 0px"
  }
}));

export default function SetWinnerPanelComponent({
  open,
  anchorRef,
  handleClose,
  handleListKeyDown,
  isOpenList,
  isFetching,
  value,
  luckyDrawParticipantData,
  excludeList,
  fetchMoreData,
  hasMoretoLoad,
  handleSearch,
  lang
}) {
  const classes = useStyle();
  const textRef = useRef();
  
  return (
    <Popper
      open={open}
      style={{ zIndex: 99 }}
      anchorEl={anchorRef.current}
      role={undefined}
      transition
      disablePortal
      placement="bottom-start"
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === "bottom-start" ? "center top" : "center bottom"
          }}
        >
          <Paper className={classes.paper}>
            <ClickAwayListener onClickAway={handleClose}>
              <Box>
                <Box>
                  <InputTextField
                    inputRef={textRef}
                    variant="filled"
                    size="small"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          style={{ paddingRight: "6px" }}
                        >
                          <Button type="button" className={classes.button} onClick={()=> handleSearch(textRef.current.value)} >
                            <SearchIcon fontSize="small" />
                          </Button>
                        </InputAdornment>
                      ),
                      style: {
                        backgroundColor: "#fff"
                      },
                    }}
                    inputProps={{
                      style: {
                        padding: "14px"
                      }
                    }}
                    placeholder={getLang(lang,"placeholder.SEARCH_CONSUMER")}
                  />
                </Box>

                <Divider />

                {isFetching && luckyDrawParticipantData.length === 0 ? (
                  <>
                    <Skeleton variant="text" animation="wave" />
                    <Skeleton variant="text" animation="wave" />
                    <Skeleton variant="text" animation="wave" />
                    <Skeleton variant="text" animation="wave" />
                  </>
                ) : luckyDrawParticipantData.length === 0 ? (
                  <Box py={4} className={classes.emptyContainer}>
                    <Typography variant="body1">
                      {getLang(lang,"paragraph.NO_CONSUMER")}
                    </Typography>
                  </Box>
              ) :
                luckyDrawParticipantData.length > 0 ? (
                
                  <InfiniteScroll
                    dataLength={luckyDrawParticipantData.length}
                    next={fetchMoreData}
                    hasMore={hasMoretoLoad}
                    loader={<LoadingComponent height={50} />}
                    height={200}
                    style={{ position: "relative" }}
                  >
                    <>

                      {luckyDrawParticipantData
                        // .slice(0, currentShowing)
                        .map((field, index) => {
                          if (excludeList.length >= 1) {
                            if (
                              !excludeList.find((e) => e === field.serialNumber)
                            ) {
                              return (
                                <React.Fragment key={index}>
                                  <MenuList
                                    autoFocusItem={isOpenList}
                                    id="menu-list-grow"
                                    onClick={() => {
                                      handleListKeyDown(
                                        field.serialNumber,
                                        field.id
                                      );
                                    }}
                                    className={clsx(classes.menuList, {
                                      [classes.menuListOdd]: index % 2 === 0,
                                    })}
                                  >
                                    <MenuItem className={classes.menuItemContainer}>
                                      <Typography
                                        variant="body2"
                                        color="primary"
                                        style={{ paddingLeft: "8px", fontWeight: "bold" }}
                                      >
                                        {field.serialNumber}
                                      </Typography>
                                      <Typography
                                        variant="caption"
                                        style={{ paddingLeft: "8px", color:"rgba(58, 77, 84, 0.33)" }}
                                      >
                                        {field.name}
                                        {/* &nbsp;.&nbsp;{field.contactNumber} */}
                                      </Typography>
                                    </MenuItem>
                                  </MenuList>
                                </React.Fragment>
                              );
                            }
                            return null;
                          } else {
                            return (
                              <React.Fragment key={index}>
                                <MenuList
                                  autoFocusItem={isOpenList}
                                  id="menu-list-grow"
                                  onClick={() => {
                                    handleListKeyDown(field.serialNumber, field.id);
                                  }}
                                  className={clsx(classes.menuList, {
                                    [classes.menuListOdd]: index % 2 === 0,
                                  })}
                                >
                                  <MenuItem className={classes.menuItemContainer}>
                                    <Typography
                                      variant="body2"
                                      color="primary"
                                      style={{
                                        paddingLeft: "8px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {field.serialNumber}
                                    </Typography>
                                    <Typography
                                      variant="caption"
                                      style={{
                                        paddingLeft: "8px",
                                        color: "rgba(58, 77, 84, 0.33)"
                                      }}
                                    >
                                      {field.name}
                                      {/* &nbsp;.&nbsp;{field.contactNumber} */}
                                    </Typography>
                                  </MenuItem>
                                </MenuList>
                              </React.Fragment>
                            );
                          }
                        })}
                    </>
                    <Divider />
                    {excludeList.length=== luckyDrawParticipantData.length &&
                  <Box py={10} className={classes.emptyContainer}>
                  <Typography variant="body1">
                    {getLang(lang,"paragraph.NO_WINNER_FOUND")}
                  </Typography>
                </Box>}
                  </InfiniteScroll>
                ) : 
                null}
              </Box>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}
