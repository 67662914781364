import CampaignConversionPanel from './campaignConversionPanel.component';
import React, { useEffect } from 'react';

import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from 'react-redux';
import { selectLoading } from 'modules/notification';
import { getCampaignConversionData } from 'modules/reporting/redux';
import moment from 'moment';
import { useState } from 'react';

export default function CampaignConversionPanelContainer({
    currentFilter,
    url,
    pageType,
    translate
}) {
    const dispatch = useDispatch();
    const isFetchingCampaignConversionData = useSelector(state => selectLoading(state, getCampaignConversionData.typePrefix));
    const campaignConversionData = useSelector(state => state.reporting.campaign.campaignConversionData);
    const language = useSelector((state)=> state.profile.language)
    // table configuration & function
    const [tableFilter, setTableFilter] = useState({
        page: 0,
        rowsPerPage: 25,
        search: "",
        searchBy: "name",
        orderBy: "total_engagement",
        orderDirection: "desc",
    })

    const columns = [
        {
            title: translate("label.NUM"),
            field: null,
            render: (row) => {
                return (
                    <>{row.title}</>
                )
            },
            align: "left",
            size: "small"
        },
        {
            title: translate("label.CAMPAIGN"),
            field: null,
            render: (row) => {
                return (
                    <>{row.title}</>
                )
            },
            align: "left",
            size: "medium"
        },
        {
            title: translate("label.TOTAL_ENGAGEMENT"),
            field: "total_engagement",
            render: (row) => {
                return (
                    <>{row.title}</>
                )
            },
            size: "small",
            align: "left"
        }
    ];

    useEffect(() => {
        dispatch(getCampaignConversionData({
            orderBy: tableFilter.orderBy,
            orderDirection: tableFilter.orderDirection,
            countryCode: currentFilter.countryCode,
            city_uuid: currentFilter.cityUuid,
            branch_uuid: currentFilter.branchUuid,
            brand_uuid: currentFilter.brandUuid,
            category_uuid: currentFilter.categoryUuid,
            model_uuid: currentFilter.modelUuid,
            product_uuid: currentFilter.productUuid,
            period: currentFilter.period,
            start_date: moment(currentFilter.dateRange[0]).format('YYYY-MM-DD'),
            end_date: moment(currentFilter.dateRange[1]).format('YYYY-MM-DD'),
            url: url
        })).then(unwrapResult);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, currentFilter, tableFilter]);

    const handleRequestSort = (event, property) => {
        const isAsc = tableFilter.orderBy === property && tableFilter.orderDirection === 'asc';
        setTableFilter((prev) => ({
            ...prev,
            orderDirection: isAsc ? 'desc' : 'asc',
            orderBy: property,
        }))
    }

    const handleChangePage = (e, newPage) => {
        setTableFilter((prev) => ({
          ...prev,
          page: newPage,
        }))
    }

    const handleChangeRowPerPage = (event) => {
        setTableFilter((prev) => ({
          ...prev,
          page: 0,
          rowsPerPage: event.target.value
        }))
    }


    return (
        <CampaignConversionPanel 
            status={{
                isFetchingCampaignConversionData,
            }}

            data={{
                campaignConversionData,
            }}

            language={language}
            
            currentFilter={currentFilter}

            pageType={pageType}

            columns={columns}

            sort={{
                valueToOrderBy: tableFilter.orderBy,
                orderDirection: tableFilter.orderDirection,
                handleRequestSort: handleRequestSort,
            }}

            pagination={{
                handleChangePage: handleChangePage,
                handleChangeRowPerPage: handleChangeRowPerPage,
                page: tableFilter.page,
                rowsPerPage: tableFilter.rowsPerPage,
                rowsPerPageOptions: [5, 10, 25],
            }}

            translate={translate}
        />
    )
}