import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { signup, signupEmployee } from "../../../redux/action";
import { SIGN_UP_VERIFICATION } from "../../../config/public.route";
import SignUpFormComponent from "./signUpForm.component";
import { unwrapResult } from "@reduxjs/toolkit";
import { login } from "../../../redux/action/login.action"
import { selectLoading } from "modules/notification";
import {
  SIGN_UP,
  PARTIAL_FREE_SIGN_UP,
  FULL_FREE_SIGN_UP,
  RESELLER_PLAN_A_SIGN_UP,
  RESELLER_PLAN_B_SIGN_UP,
  PARTNER_PLAN_A_SIGN_UP,
  PARTNER_PLAN_B_SIGN_UP,
} from "../../../config/public.route";
// import { setIsSignUpFormLoading } from "modules/public/redux/slice/signup.slice";

function SignUpFormContainer({
  employeeInfo,
  signupEmployee,
  signup,
  login,
  isLoading
}) {
  const route = useRouteMatch();
  // const location = useLocation();

  const lang = useSelector(state => state.constant.languages)
  const timezones = useSelector(state => state.constant.timezones)
  const history = useHistory();

  const [isPasswordShown, setIsPasswordShown] = useState(false);

  const handleClickShowPassword = () => {
    setIsPasswordShown(prev => !prev)
  }

  const handleMouseDownPassword = event => {
    event.preventDefault()
  }

  const handleSubmit = async (values, formikApi) => {
    if (employeeInfo.id) {
      const info = {
        ...values,
        token: employeeInfo.token,
        id: employeeInfo.id
      }
      await signupEmployee(info)
        .then(unwrapResult)
        .then(() => {
          login({ username: values.username, password: values.password })
        })
        .catch(() => {
          formikApi.setSubmitting(false)
        })
    } else {
      const formValues = {
        ...values
      }
      const code = route.params.code
      const path = route.path
      switch(path) {
        case SIGN_UP:
          formValues.metadata = JSON.stringify({ registration_type: 1 });
          break;
        case PARTIAL_FREE_SIGN_UP:
          formValues.metadata = JSON.stringify({ registration_type: 2 });
          break;
        case FULL_FREE_SIGN_UP:
          formValues.metadata = JSON.stringify({ registration_type: 3 });
          break;
        case RESELLER_PLAN_A_SIGN_UP:
          formValues.metadata = JSON.stringify({ registration_type: 4, collaboration_group_code: code });
          break;
        case RESELLER_PLAN_B_SIGN_UP:
          formValues.metadata = JSON.stringify({ registration_type: 5, collaboration_group_code: code });
          break;
        case PARTNER_PLAN_A_SIGN_UP:
          formValues.metadata = JSON.stringify({ registration_type: 6, collaboration_group_code: code });
          break;
        case PARTNER_PLAN_B_SIGN_UP:
          formValues.metadata = JSON.stringify({ registration_type: 7, collaboration_group_code: code });
          break;
        default:
          formValues.metadata = JSON.stringify({ registration_type: 1 });
          break;
      }
      await signup(formValues)
        .then(unwrapResult)
        .then(() => history.push(SIGN_UP_VERIFICATION))
        .catch(() => {
          formikApi.setSubmitting(false)
        })
    }
  };

  return (
    <SignUpFormComponent
      isLoading={isLoading}
      handleSubmit={handleSubmit}
      email={employeeInfo.email}
      lang={lang}
      handleClickShowPassword={handleClickShowPassword}
      handleMouseDownPassword={handleMouseDownPassword}
      isPasswordShown={isPasswordShown}
      timezones={timezones}
      timezone={employeeInfo.timezone}
    />
  )
}

const mapStateToProps = state => ({
  employeeInfo: state.signup.employee,
  isLoading: selectLoading(state, login.typePrefix)
});

const mapDispatchToProps = dispatch => ({
  signup: values => dispatch(signup(values)),
  signupEmployee: values => dispatch(signupEmployee(values)),
  login: values => dispatch(login(values)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignUpFormContainer);
