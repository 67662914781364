import { makeStyles } from "@material-ui/core";

export const fontTextStyle = makeStyles((theme) => ({

    [theme.breakpoints.up("lg")]: {
        text__subtitle_muted : {
            fontFamily: "Roboto",
            fontWeight: "400",
            fontSize: "12px",
            lineHeight: "20px",
            color: '#98A2B3',
            display: "block"
        },
    },
    [theme.breakpoints.down("md")]: {
        text__subtitle_muted : {
            display: "none"
        },
    },
    text__muted : {
        fontFamily: "Roboto",
        fontWeight: "400",
        fontSize: "12px",
        lineHeight: "20px",
        color: '#98A2B3'
    },
    text__color_muted : {
        color: '#98A2B3 !important'
    },
    text__hyperlink: {
        fontFamily: "Roboto",
        fontWeight: "400",
        fontSize: "12px",
        textDecoration: "none",
        color: '#6AAF68',
        '&:hover': {
            cursor: 'pointer',
            textDecoration: 'underline',
            color: '#6AAF68',
        }
    },
    text__table_cell: {
        fontFamily: "Roboto",
        color: "#344054",
        fontWeight: "500",
        fontSize: "12px",
    },
    text__card_title: {
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "20px",
        color: "#101828",
    },
    text__unknown: {
        color: "#98A2B3 !important",
        fontWeight: "500",
        fontSize: "12px",
        lineHeight: "14.4px",
        fontFamily: "Roboto",
    },
    text__detail_title : {
        fontFamily: "Roboto",
        fontWeight: "500",
        fontSize: "20px",
        lineHeight: "30px",
        color: "#344054"
    },
    text__success : {
        color: "#32D583",
    },
    text__danger: {
        color: "#F97066 !important"
    },
    text__secondary: {
        color: "#98A2B3 !important"
    },
    text__detail_subtitle : {
        fontFamily: "Roboto",
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "24px",
        color: "#98A2B3"
    },
    text__detail_subtitle_value : {
        fontFamily: "Roboto",
        fontWeight: "500",
        fontSize: "16px",
        lineHeight: "24px",
        color: "#344054",
    },
    text__header_table_cell : {
        fontFamily: "Roboto",
        fontWeight: "600",
        fontSize: "13px",
        lineHeight: "20px",
        color: "#101828",
    },
    text__header_title : {
        fontFamily: "Roboto",
        fontWeight: "700",
        fontSize: "20px",
        lineHeight: "20px",
    },
    text__header_subtitle : {
        fontFamily: "Roboto",
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "16px",
    },
    text__tier_label : {
        fontFamily: "Roboto",
        color: '#FFFFFF',
        fontSize: "0.6rem"
    }
}))