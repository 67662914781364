import React from "react";
import {
  makeStyles,
  Box,
  Typography,
  Grid,
  Button,
  Divider
} from "@material-ui/core";
import { EditSVG } from "../../../utils/static";
import { Skeleton } from "@material-ui/lab";
import { getLang } from "app/feature/constants";

const style = (theme) => ({
  root: {
    zIndex: "999",
    paddingBottom: theme.spacing(5)
  },
  gridContainer: {
    width: "100%",
    display: "flex",
    placeContent: "flex-end"
  },
  buttonEdit: {
    padding: 0,
    minWidth: 0
  },
  text: {
    color: " rgba(58, 77, 84, 0.33)"
  },
  detail: {
    zIndex: "999",
    display: "grid",
    paddingTop: theme.spacing(3)
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
});

const useStyles = makeStyles(style);

export default function BillingDetailCardComponent({
  handleEditDetail,
  billingDetail,
  isFetching,
  lang
}) {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Grid container>
        <Grid
          item
          xs={6}
          sm={6}
          md={6}
          style={{ width: "100%", alignItems: "center" }}
        >
          <Typography
            variant="body2"
            color="primary"
            style={{ fontWeight: "bold" }}
          >
            {getLang(lang, "label.BILLING_DETAILS")}
          </Typography>
        </Grid>

        <Grid item xs={6} sm={6} md={6} className={classes.gridContainer}>
          {isFetching ? null : (
            <Button className={classes.buttonEdit} onClick={handleEditDetail}>
              <EditSVG />
            </Button>
          )}
        </Grid>
      </Grid>

      <Box className={classes.detail}>
        <Typography variant="caption" className={classes.text}>
          {getLang(lang, "label.CONTACT_PERSON_NAME")}
        </Typography>

        {isFetching ? (
          <Skeleton variant="text" width={130} />
        ) : (
          <Typography
            variant="caption"
            color="primary"
            style={{ fontWeight: "bold" }}
          >
            {billingDetail.name}
          </Typography>
        )}

        <Divider className={classes.divider} />

        <Typography variant="caption" className={classes.text}>
          {getLang(lang, "label.CONTACT_NUMBER")}
        </Typography>

        {isFetching ? (
          <Skeleton variant="text" width={130} />
        ) : (
          <Typography variant="caption" color="primary">
            {billingDetail.phoneCode} {billingDetail.phone}
          </Typography>
        )}

        <Divider className={classes.divider} />

        <Typography variant="caption" className={classes.text}>
          {getLang(lang, "label.ADDRESS")}
        </Typography>

        {isFetching ? (
          <Skeleton variant="text" width={130} />
        ) : (
          <Typography variant="caption" color="primary">
            {billingDetail.line1 === ""
              ? "-"
              : billingDetail.line1 +
                ", " +
                billingDetail.line2 +
                ", " +
                billingDetail.city +
                ", " +
                billingDetail.postalCode +
                " " +
                billingDetail.state +
                ", " +
                billingDetail.country}
          </Typography>
        )}

        <Divider className={classes.divider} />

        <Typography variant="caption" className={classes.text}>
          {getLang(lang, "paragraph.SEND_COPY_TO")}
        </Typography>

        {isFetching ? (
          <Skeleton variant="text" width={130} />
        ) : (
          <Typography
            variant="caption"
            color="primary"
            style={{ fontWeight: "bold" }}
          >
            {billingDetail.email}
          </Typography>
        )}
      </Box>
    </Box>
  );
}
