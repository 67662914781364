import React from "react"
import { makeStyles, Box, Button } from "@material-ui/core"
import { Formik } from "formik"
import { getLang } from "app/feature/constants"
import LooseItemListTable from "../../table/looseItemListTable"
import Input from "components/input/inputTextField"

const useStyles = makeStyles((theme) => ({
  container: {
    background: "white",
    borderRadius: 4,
    padding: "24px 20px"
  },
  searchContainer: {
    backgroundColor: theme.palette.background.paper,
    display: "grid",
    gridTemplateColumns: "10fr 1fr 2fr",
    columnGap: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1)
    }
  },
  inputRootID: {
    paddingTop: "8px !important",
    paddingBottom: "7px !important",
    paddingLeft: "12px !important",
    paddingRight: "12px !important",
  },
}))

export default function DealerLooseItemListPanelComponent({
  hasStockflowInventoryViewAccessRight,
  id,
  looseItems,
  isFetching,
  isError,
  handleChangePage,
  handleChangeRowsPerPage,
  totalFiltered,
  page,
  rowsPerPage,
  handleSearch,
  clearSearch,
  handleReload,
  isSearchResult,
  paginationOptions,
  tableFilter,
  lang,
  handleViewDetail
}) {
  const classes = useStyles()

  return (
    <>
      <Box className={classes.container}>
        <Formik
          initialValues={{
            search: tableFilter.search,
          }}
          enableReinitialize
          onSubmit={(values, actions) => {
            handleSearch(values)
            actions.setSubmitting(false)
          }}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <Box mb={2} className={classes.searchContainer}>
                <Input
                  hiddenLabel
                  variant="filled"
                  size="small"
                  placeholder={getLang(lang, "placeholder.SEARCH_LOOSE_ITEM")}
                  margin="none"
                  InputProps={{
                    disableUnderline: true,
                    margin: "none",
                    style: {
                      borderRadius: "4px",
                      backgroundColor: "#eceff0",
                      minWidth: "50px",
                      height: "100%"
                    },
                    classes: { input: classes.inputRootID }
                  }}
                  {...formik.getFieldProps("search")}
                />
                <Button
                  variant='text'
                  disableElevation
                  color="secondary"
                  size='small'
                  onClick={() => {
                    clearSearch()
                  }}
                >
                  {getLang(lang, "label.CLEAR")}
                </Button>
                <Button
                  disabled={isFetching}
                  variant="contained"
                  disableElevation
                  color='primary'
                  size='small'
                  type='submit'
                >
                  {getLang(lang, "label.SEARCH")}
                </Button>
              </Box>
            </form>
          )}
        </Formik>
        <Box mt={3}>
          <LooseItemListTable
            hasStockflowInventoryViewAccessRight={hasStockflowInventoryViewAccessRight}
            isDealerPage={true}
            rows={looseItems}
            isFetching={isFetching}
            isError={isError}
            handleReload={handleReload}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            isSearchResult={isSearchResult}
            totalFiltered={totalFiltered}
            paginationOptions={paginationOptions}
            tableFilter={tableFilter}
            lang={lang}
            dealerId={id}
            handleViewDetail={handleViewDetail}
          />
        </Box>
      </Box>
    </>
  )
}
