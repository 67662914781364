import React from "react";
import {
  makeStyles,
  Box,
  Typography
} from "@material-ui/core";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { getLang } from "app/feature/constants";

const style = theme => ({
  mainContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
  container: {
    padding: "40px 30px 40px 30px",
    backgroundColor: "#ffffff",
    borderRadius: "4px",
    marginBottom: "40px"
  },
  title: {
    fontWeight: 700,
    fontSize: "24px",
    fontFamily: "Montserrat"
  },
  description: {
    fontFamily: "Montserrat",
    fontWeight: 400,
    fontSize: "18px"
  },
  button: {
    width: "100%",
    borderRadius: "42px",
    textTransform: "capitalize",
    fontSize: "16px",
  }
});

const useStyle = makeStyles(style);

export default function NewPasswordSuccessPage( { lang } ) {
  const classes = useStyle();

  return (
    <Box className={classes.mainContainer}>
      <Box style={{ width: "502px" }}>
        <Box style={{ marginBottom: "60px" }}>
          <Header justifyContent="center" />
        </Box>
        <Box className={classes.container}>
          <Box paddingBottom="20px">
            <Typography variant='h5' className={classes.title}>{getLang(lang, 'paragraph.PASSWORD_UPDATED')}</Typography>
          </Box>
          <Box paddingBottom="69px">
            <Typography variant='body1' className={classes.description}>
              {getLang(lang, 'paragraph.YOUR_PASSWORD_UPDATED')} <br />{getLang(lang, 'paragraph.CLOSE_THIS_WINDOW')}
            </Typography>
          </Box>
        </Box>
        <Box>
          <Footer type="tnc" />
        </Box>
      </Box>
    </Box>
  )
}