import { makeStyles, TableCell, TableRow, Typography } from "@material-ui/core";
import React from "react";
import { ContentCard } from "../../layout";
import { DownloadableTable } from "../../table";

const useStyles = makeStyles((theme) => ({
    text__muted : {
        fontFamily: "Roboto",
        fontWeight: "400",
        fontSize: "12px",
        lineHeight: "24px",
        color: '#98A2B3'
    },
    text__hyperlink: {
        fontFamily: "Roboto",
        fontWeight: "400",
        fontSize: "12px",
        // lineHeight: "24px",
        color: '#6AAF68',
        '&:hover': {
            cursor: 'pointer',
            textDecoration: 'underline'
        }
    },
    table__cell: {
        fontFamily: "Roboto",
        border: "1px solid #F2F4F7",
    },
    table__total_row: {
        backgroundColor: "#6184FF0D",
    },
    table__total_cell: {
        fontFamily: "Roboto",
        color: "#344054",
        fontWeight: "500",
        fontSize: "14px",
    },
    table__text_cell: {
        fontFamily: "Roboto",
        color: "#344054",
        fontWeight: "400",
        fontSize: "14px",
    },
    gap3: {
        gap: "6px"
    },
    alignEnd: {
        alignItems: "end"
    },
        text__success: {
        color: "#32D583 !important"
    },
    text__danger: {
        color: "#F97066 !important"
    },
    text__secondary: {
        color: "#98A2B3 !important"
    }
})) 


export default function CampaignRespodentPanelComponent({
    status,
    data,
    sort,
    pagination,
    columns,
    translate
}) {
    const classes = useStyles();
    const renderData = (data) => {

        let rows = [];
        if(Object.keys(data).length === 0 || !data.isSuccess){
            return rows;
        }

        let imageProps = JSON.stringify(data);
        imageProps = JSON.parse(imageProps);
        imageProps.rows.forEach((item, index) => {
            let row = (
                <TableRow>
                    <TableCell className={classes.table__cell}>
                        <Typography className={classes.text__hyperlink}>
                            {item.name}
                        </Typography>
                    </TableCell>
                    <TableCell className={classes.table__cell}>
                        <Typography className={classes.table__text_cell}>
                            {item.survey.name}
                        </Typography>
                    </TableCell>
                    <TableCell className={classes.table__cell}>
                        <Typography className={classes.table__text_cell}>
                            {item.submitted_date}
                        </Typography>
                    </TableCell>
                </TableRow>
            )

            
            item.render = row;
        })
        
        return imageProps;
    }

    return (
        <ContentCard>
            <DownloadableTable
                title="Respondents"
                sort={sort}
                pagination={pagination}
                columns={columns}
                status={status.isFetchingAllCampaignRespodents}
                data={renderData(data.allCampaignRespodents)}
                translate={translate}
            />
        </ContentCard>
    );
}