import { getApiLang } from "app/feature/constants";
import { setLoading, addAlert } from "modules/notification";
import {
  approveTransfer,
  getTransferDeliveryInfo,
  getTransferDetail,
  getTransferItemBoxList,
  getTransferItemBoxProductList,
  getTransferItemProductList,
  getTransferItemProductSNList,
  getTransferList,
  getTransferReceiverDropdown,
  getTransferSenderDropdown,
  rejectApproveTransfer,
  updateTransferDeliveryInfo,
} from "../action";

const getTransferListMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getTransferList.pending.type:
        dispatch(setLoading({ id: getTransferList.typePrefix, state: true }));
        break;
      case getTransferList.fulfilled.type:
        dispatch(setLoading({ id: getTransferList.typePrefix, state: false }));
        break;
      case getTransferList.rejected.type:
        const lang = getState().constant.languages;
        dispatch(setLoading({ id: getTransferList.typePrefix, state: false }));
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_GET_TRANSFER_LIST"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const getTransferDetailMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getTransferDetail.pending.type:
        dispatch(setLoading({ id: getTransferDetail.typePrefix, state: true }));
        break;
      case getTransferDetail.fulfilled.type:
        dispatch(
          setLoading({ id: getTransferDetail.typePrefix, state: false })
        );
        break;
      case getTransferDetail.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({ id: getTransferDetail.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_GET_TRANSFER_DETAIL"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const getTransferSenderDropdownMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getTransferSenderDropdown.pending.type:
        dispatch(
          setLoading({
            id: getTransferSenderDropdown.typePrefix,
            state: true,
          })
        );
        break;
      case getTransferSenderDropdown.fulfilled.type:
        dispatch(
          setLoading({
            id: getTransferSenderDropdown.typePrefix,
            state: false,
          })
        );
        break;
      case getTransferSenderDropdown.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: getTransferSenderDropdown.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

const getTransferReceiverDropdownMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getTransferReceiverDropdown.pending.type:
        dispatch(
          setLoading({
            id: getTransferReceiverDropdown.typePrefix,
            state: true,
          })
        );
        break;
      case getTransferReceiverDropdown.fulfilled.type:
        dispatch(
          setLoading({
            id: getTransferReceiverDropdown.typePrefix,
            state: false,
          })
        );
        break;
      case getTransferReceiverDropdown.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: getTransferReceiverDropdown.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

const getTransferItemBoxListMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getTransferItemBoxList.pending.type:
        dispatch(
          setLoading({ id: getTransferItemBoxList.typePrefix, state: true })
        );
        break;
      case getTransferItemBoxList.fulfilled.type:
        dispatch(
          setLoading({ id: getTransferItemBoxList.typePrefix, state: false })
        );
        break;
      case getTransferItemBoxList.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({ id: getTransferItemBoxList.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_GET_TRANSFER_BOX_ITEMS"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const getTransferItemBoxProductListMiddleWare =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getTransferItemBoxProductList.pending.type:
        dispatch(
          setLoading({
            id: getTransferItemBoxProductList.typePrefix,
            state: true,
          })
        );
        break;
      case getTransferItemBoxProductList.fulfilled.type:
        dispatch(
          setLoading({
            id: getTransferItemBoxProductList.typePrefix,
            state: false,
          })
        );
        break;
      case getTransferItemBoxProductList.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: getTransferItemBoxProductList.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_INVALID_BOX_ID"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const getTransferItemProductListMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getTransferItemProductList.pending.type:
        dispatch(
          setLoading({ id: getTransferItemProductList.typePrefix, state: true })
        );
        break;
      case getTransferItemProductList.fulfilled.type:
        dispatch(
          setLoading({
            id: getTransferItemProductList.typePrefix,
            state: false,
          })
        );
        break;
      case getTransferItemProductList.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: getTransferItemProductList.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_GET_TRANSFER_PRODUCTS"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const getTransferItemProductSNListMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getTransferItemProductSNList.pending.type:
        dispatch(
          setLoading({
            id: getTransferItemProductSNList.typePrefix,
            state: true,
          })
        );
        break;
      case getTransferItemProductSNList.fulfilled.type:
        dispatch(
          setLoading({
            id: getTransferItemProductSNList.typePrefix,
            state: false,
          })
        );
        break;
      case getTransferItemProductSNList.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: getTransferItemProductSNList.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_GET_TRANSFER_PRODUCT_SN_LIST"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const approveTransferMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case approveTransfer.pending.type:
        dispatch(
          setLoading({
            id: approveTransfer.typePrefix,
            state: true,
          })
        );
        break;
      case approveTransfer.fulfilled.type:
        dispatch(
          setLoading({
            id: approveTransfer.typePrefix,
            state: false,
          })
        );
        break;
      case approveTransfer.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: approveTransfer.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_APPROVE_TRANSFER"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const rejectApproveTransferMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case rejectApproveTransfer.pending.type:
        dispatch(
          setLoading({
            id: rejectApproveTransfer.typePrefix,
            state: true,
          })
        );
        break;
      case rejectApproveTransfer.fulfilled.type:
        dispatch(
          setLoading({
            id: rejectApproveTransfer.typePrefix,
            state: false,
          })
        );
        break;
      case rejectApproveTransfer.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: rejectApproveTransfer.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_REJECT_TRANSFER"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const updateTransferDeliveryInfoMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case updateTransferDeliveryInfo.pending.type:
        dispatch(
          setLoading({ id: updateTransferDeliveryInfo.typePrefix, state: true })
        );
        break;
      case updateTransferDeliveryInfo.fulfilled.type:
        dispatch(
          setLoading({
            id: updateTransferDeliveryInfo.typePrefix,
            state: false,
          })
        );
        break;
      case updateTransferDeliveryInfo.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: updateTransferDeliveryInfo.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

const getTransferDeliveryInfoMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getTransferDeliveryInfo.pending.type:
        dispatch(
          setLoading({ id: getTransferDeliveryInfo.typePrefix, state: true })
        );
        break;
      case getTransferDeliveryInfo.fulfilled.type:
        dispatch(
          setLoading({
            id: getTransferDeliveryInfo.typePrefix,
            state: false,
          })
        );
        break;
      case getTransferDeliveryInfo.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: getTransferDeliveryInfo.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

export default [
  getTransferListMiddleware,
  getTransferDetailMiddleware,
  getTransferReceiverDropdownMiddleware,
  getTransferSenderDropdownMiddleware,
  getTransferItemBoxListMiddleware,
  getTransferItemProductListMiddleware,
  getTransferItemProductSNListMiddleware,
  getTransferItemBoxProductListMiddleWare,
  approveTransferMiddleware,
  rejectApproveTransferMiddleware,
  updateTransferDeliveryInfoMiddleware,
  getTransferDeliveryInfoMiddleware,
];
