import React from "react";
import { IconButton } from "@material-ui/core";
import { KeyboardBackspace as BackIcon } from "@material-ui/icons";
import { useHistory } from "react-router";

export default function BackButton() {
    const history = useHistory();
    // const currentPath = history.location.pathname;
    // const rootPath = `/${currentPath.split('/').slice(1,3).join('/')}`;

    if (history.length < 2) {
        return null;
    }

    return (
        <IconButton style={{ padding: 5 }} onClick={() => {
            history.goBack();
            // if (history.length > 1) {
            //    history.goBack();
            // } else {
            //     if (currentPath.includes(rootPath)) {
            //         history.push(rootPath);
            //     } else {
            //         return null;
            // }
        }}>
            <BackIcon color="primary" />
        </IconButton>
    );
}