import React from "react";
import { makeStyles, Box, Typography, Link } from "@material-ui/core";
import { getLang } from "app/feature/constants";
import { useSelector } from "react-redux";

const useStyles = makeStyles(theme => ({
  root: {
    position: "absolute",
    background: "transparent",
    width: "100%",
    textAlign: "center",
    marginTop: theme.spacing(3)
  },
  font: {
    fontWeight: 500
  }
}));

function CreditComponent() {
  const classes = useStyles();
  const lang = useSelector(state => state.constant.languages)

  return (
    <Box className={classes.root}>
      <Typography variant="caption">
        {getLang(lang, "label.KOOD_ALL_RIGHT_RESERVED")}
        <br />
        <Typography variant="caption" color="textSecondary">
          <Link
            className={classes.font}
            color="secondary"
            href={process.env.REACT_APP_KOOD_ASIA_PRIVACY_POLICY_URL}
            target="_blank"
          >
            {getLang(lang, "label.PRIVACY_POLICY")}
          </Link>
          &nbsp;{getLang(lang, "label.AND")}&nbsp;
          <Link
            className={classes.font}
            color="secondary"
            href={process.env.REACT_APP_KOOD_ASIA_USER_AGREEMENT_URL}
            target="_blank"
          >
            {getLang(lang, "label.USER_AGREEMENT")}
          </Link>
          .
        </Typography>
      </Typography>
    </Box>
  );
}

export default CreditComponent;
