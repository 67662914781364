import React from "react"
import BulkEditTableComponent from './bulkEditTable.component'

export default function BulkEditTableContainer({
  data,
  isFetching,
  handleChangePage,
  handleChangeRowsPerPage,
  page,
  totalRecords,
  rowsPerPage,
  formik,
  categoryDropdownData,
  handleCategorySearch,
  currencyDropdownData,
  handleCurrencyCodeSearch,
  brandModelDropdownData,
  handleBrandModelSearch,
  attributeDropdownData,
  handleAttributeSearch,
  isBrandModelDropdownLoading,
  handleBulkEditFieldUpdate,
  paginationOptions,
  lang
}) {
  return (
    <BulkEditTableComponent
      data={data}
      formik={formik}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      page={page}
      totalRecords={totalRecords}
      rowsPerPage={rowsPerPage}
      isFetching={isFetching}
      categoryDropdownData={categoryDropdownData}
      handleCategorySearch={handleCategorySearch}
      currencyDropdownData={currencyDropdownData}
      handleCurrencyCodeSearch={handleCurrencyCodeSearch}
      isBrandModelDropdownLoading={isBrandModelDropdownLoading}
      brandModelDropdownData={brandModelDropdownData}
      handleBrandModelSearch={handleBrandModelSearch}
      attributeDropdownData={attributeDropdownData}
      handleAttributeSearch={handleAttributeSearch}
      handleBulkEditFieldUpdate={handleBulkEditFieldUpdate}
      paginationOptions={paginationOptions}
      lang={lang}
    />
  )
}