import axios from "axios";

export default async function getAllConsumer({
  length,
  start,
  column,
  order,
  search
}) {
  const response = await axios.get(
    '/consumer/admin/api/v1/user-listing',
    {
      params: {
        length,
        start,
        column,
        order,
        search
      }
    }
  );

  return response;
}