import React, { Fragment, useRef, useState } from "react"
import {
  makeStyles,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  Box,
  Divider,
  // MenuItem,
  // MenuList,
  Checkbox,
  InputAdornment,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Collapse,
  ListItemIcon,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import InputTextField from 'components/input/inputTextField';
import {
  Search as SearchIcon,
  // ExpandLess as ExpandLessIcon,
  // ExpandMore as ExpandMoreIcon,
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon
} from "@material-ui/icons"

const useStyle = makeStyles((theme) => ({
  paper: {
    width: 425
  },
  button: {
    padding: 0,
    minWidth: 0
  },
  buttonAddBranch: {
    display: "flex",
    padding: 0,
    minWidth: 0
  },
  createLink: {
    padding: theme.spacing(2)
  },
  dropdownItem: {
    padding: 0
  }
}))

export default function AttributeMultiSelectPanel({
  open,
  anchorRef,
  handleClose,
  // isOpenList,
  dropdownItem,
  isFetching,
  value,
  handleSelectAttribute,
  handleSelectOptions,
  placeholder,
  searchable,
  handleAttributeSearch,
  lang
  // handleCheckedOptions
}) {
  const classes = useStyle();
  const textRef = useRef();
  const [openDropdown, setOpenDropdown] = useState("")


  return (
    <Popper
      open={open}
      style={{ zIndex: 99 }}
      anchorEl={anchorRef.current}
      role={undefined}
      transition
      disablePortal
      placement="bottom-end"
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin: placement === "bottom-end" ? "right top" : "right bottom"
          }}
        >
          <Paper className={classes.paper}>
            <ClickAwayListener onClickAway={handleClose}>
              <Box>
                {searchable && (
                  <Fragment>
                    <InputTextField
                      variant="filled"
                      size="small"
                      fullWidth
                      inputRef={textRef}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end' style={{ paddingRight: 6 }}>
                            <IconButton onClick={() => handleAttributeSearch(textRef.current.value)}>
                              <SearchIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                        style: {
                          backgroundColor: "#fff",
                          paddingRight: 0
                        }
                      }}
                      inputProps={{
                        onKeyDown: (e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            handleAttributeSearch(textRef.current.value)
                          }
                        },
                        style: {
                          padding: "8px"
                        }
                      }}
                      placeholder={placeholder}
                    />
                  </Fragment>
                )}
                <Divider />
                <Box style={{ overflow: "auto" }}>
                  {isFetching ? (
                    <>
                      <Skeleton variant='text' animation="wave" />
                      <Skeleton variant='text' animation="wave" />
                      <Skeleton variant='text' animation="wave" />
                      <Skeleton variant='text' animation="wave" />
                    </>
                  ) : (
                    <div>
                      <Box maxHeight="25vh" overflow="auto">
                        <List>
                          {dropdownItem.map((field, index) => {
                            return (
                              <Fragment key={index}>
                                <ListItem>
                                  <ListItemIcon style={{ paddingLeft: 0, minWidth: "40px" }}>
                                    <Checkbox
                                      checked={
                                        value.find((v) => v.id === field.id && v.options.length === field.options.length) ? true : false
                                      }
                                      indeterminate={
                                        value.find((v) => v.id === field.id && v.options.length !== field.options.length) ? true : false
                                      }
                                      disabled={false}
                                      style={{
                                        padding: 0
                                      }}
                                      onChange={(event) => {
                                        handleSelectAttribute(field, event.target.checked)
                                      }}
                                    />
                                  </ListItemIcon>
                                  <ListItemText primary={field.name} />
                                  {openDropdown !== "" && field.id === openDropdown ? (
                                    <ArrowDropUpIcon onClick={() => {
                                      setOpenDropdown("")
                                    }} />
                                  ) : (
                                    <ArrowDropDownIcon onClick={() => {
                                      if (field.options.length > 0) {
                                        setOpenDropdown(field.id)
                                      }
                                    }} />
                                  )}
                                </ListItem>
                                <Collapse in={field.id === openDropdown}>
                                  <List component="div" disablePadding>
                                    {field.options.length > 0 ? field.options.map((option) => {
                                      return (
                                        <ListItem key={option.id}>
                                          <ListItemIcon style={{ paddingLeft: "20px", minWidth: "40px" }}>
                                            <Checkbox
                                              checked={
                                                value.find((v) => v.options.find((v2) => v2.id === option.id)) ? true : false
                                              }
                                              disabled={false}
                                              disableRipple
                                              style={{
                                                padding: 0
                                              }}
                                              onClick={(event) => {
                                                handleSelectOptions(field, option, event.target.checked)
                                              }}
                                            />
                                          </ListItemIcon>
                                          <ListItemText primary={option.value} />
                                        </ListItem>
                                      )
                                    }) : (
                                      <></>
                                    )}
                                  </List>
                                </Collapse>
                              </Fragment>
                            )
                          })}
                        </List>
                      </Box>
                    </div>
                  )}
                </Box>
              </Box>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  )

}