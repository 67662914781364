import getAllAccessRight from "./getAllAccessRight.api";
import getAllRoleList from "./getAllRoleList.api";
import roleAccessRight from "./roleAccessRight.api";

const roleAccessRightApi = {
  getAllAccessRight,
  getAllRoleList,
  roleAccessRight
};

export default roleAccessRightApi;
