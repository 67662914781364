import axios from "axios"

const url = "/distributor/admin/api/v1/inventory/packages"
export function getAllPackagesListing (parameters) {
  return new Promise((resolve, reject) => {
    axios.post(url, {
      length: parameters.length,
      start: parameters.start,
      order: parameters.order,
      order_by: parameters.order_by,
      filter: {
        nodes: parameters.filter.nodes,
        status: parameters.filter.status,
        search: parameters.filter.search,
        search_by: parameters.filter.search_by,
        pattern: parameters.filter.pattern,
        created_at: parameters.filter.created_at,
        updated_at: parameters.filter.updated_at,
        located_at: parameters.filter.located_at
      }
    })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data))
  });
};