import React from "react";
import {
  makeStyles,
  Box,
  Typography,
  Divider,
  Button,
  Tabs
} from "@material-ui/core";
import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";
import CustomTab from "components/tab";
import { TabPanel, allyProps } from "components/tabPanel";
import { AddIcon } from "components/icon";
import IncentiveProgramPanel from 'modules/stockflow/components/panel/incentiveProgramPanel';
import { getLang } from "app/feature/constants";

const useStyle = makeStyles(theme => ({
  indicator: {
    border: "0.5px solid",
    borderColor: theme.palette.primary.main
  },
  scroll: {
    width: "min-content"
  }
}))

export default function DealerIncentiveProgramListingCompnent({
  hasStockflowAddAccessRight,
  handleChange,
  activeIndex,
  tabLabels,
  handleAddIncentiveProgram,
  lang
}) {
  const classes = useStyle();

  return (
    <Box>
      <Box mb={1} display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" alignItems="flex-end" fontWeight="bold">
          <Typography variant="h6" color="primary" style={{ fontSize: "1.2rem" }}>
            {getLang(lang, 'label.DEALER_INCENTIVE_PROGRAM')}
          </Typography>
          <Box mx={0.5}>
            <ChevronRightRoundedIcon color="disabled" />
          </Box>
          <Box>
            <Typography variant="h6" color="primary" style={{ fontSize: "1.2rem" }}>
              {tabLabels[activeIndex]}
            </Typography>
          </Box>
        </Box>
        <Box>
          {hasStockflowAddAccessRight && (
            <Button
              variant='text'
              color='primary'
              style={{ padding: "6px" }}
              startIcon={
                <AddIcon color="secondary" style={{ fontSize: "13px" }} />
              }
              onClick={handleAddIncentiveProgram}
            >
              {getLang(lang, 'label.DEALER_INCENTIVE_PROGRAM')}
            </Button>
          )}
        </Box>
      </Box>
      <Divider />
      <Box>
        <Box>
          <Tabs
            value={activeIndex}
            onChange={handleChange}
            aria-label='scrollable auto tabs example'
            textColor="primary"
            variant='scrollable'
            indicatorColor='primary'
            classes={{
              indicator: classes.indicator,
              scrollButtons: classes.scroll
            }}
          >
            {tabLabels.map((item, index) => {
              return <CustomTab key={index} {...allyProps(index, item)} />
            })}
          </Tabs>
        </Box>
        <Box>
          <TabPanel value={activeIndex} index={0}>
            <IncentiveProgramPanel />
          </TabPanel>
          <TabPanel value={activeIndex} index={1}>
            <Box>
              <Typography>Good Morning</Typography>
            </Box>
          </TabPanel>
        </Box>
      </Box>
    </Box>
  )
}


