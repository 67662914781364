import React, { useRef, useState } from "react";
import EditPrizeCardComponent from "./editPrizeCard.component";
import { useDispatch, useSelector } from "react-redux";
import { getUploadS3FilePath, isExcel, isImage } from "lib/helper";
import { addAlert } from "modules/notification";
import { v4 as uuidv4 } from "uuid";
import { FolderType, UploadType } from "lib/constants/aws_s3";
import { toBase64 } from "modules/product-mgmt/utils/productPage.util";
import { unwrapResult } from "@reduxjs/toolkit";
import { uploadImageToS3, uploadFileToS3 } from "app/feature";
import { hideBackdrop, showBackdrop } from "modules/backdrop";
import { openAdjustDialog, setCancelEdit } from "modules/instant-lucky-draw/redux";
import { QuantityAdjusterDialog } from "../../dialog";
import giftCodeExcel from "app/api/instantLuckyDraw/legacy/giftCodeExcel.api";
import * as XLSX from "xlsx";
import { getLang } from "app/feature/constants";

function EditPrizeCardContainer({ handleSave, handleDelete, prizeDetail, handleNewDelete, handleIsEditing }) {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.session.idTokenPayload.sub);
  const id = useSelector(
    (state) => state.instantLuckyDraw.InstantLuckyDrawDialogInfo.info.id
  );
  const accessToken = useSelector((state) => state.session.accessToken);
  const lang = useSelector(state => state.constant.languages);
  const editing = useSelector(state => state.instantLuckyDraw.prizeDialogInfo.prizeList);

  const inputImgRef = useRef();

  const [prizeImage, setPrizeImage] = useState(null);
  const [prizeImageFile, setPrizeImageFile] = useState(null);

  const handleImgChange = (e) => {
    const file = inputImgRef.current.files[0];

    if (file) {
      setPrizeImageFile(e.target.files[0]);
      setPrizeImage(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleDisableEdit = async(values) => {
    const index = editing.findIndex((w) => w.editing);
    const info = {
      index,
    };
    dispatch(setCancelEdit(info));
    handleNewDelete();
    handleIsEditing();
  }

  const handleAdjustQuantity = async () => {
    dispatch(openAdjustDialog());
  };
  const getWorkbookFromFile = (excelFile) => {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();

      reader.onload = (e) => {
        var data = e.target.result;
        let readedData = XLSX.read(data, { type: "binary" });
        const wsname = readedData.SheetNames[0];
        const ws = readedData.Sheets[wsname];

        /* Convert array to json*/
        const dataParse = XLSX.utils.sheet_to_json(ws, { header: 2, blankrows: false });
        resolve(dataParse);
      };
      reader.readAsBinaryString(excelFile);
    });
  };
  const handleSubmit = async (values) => {
    const updateInfo = {
      ...values,
      quantity: parseFloat(values.quantity),
      instantLuckyDrawId: id,
      order: prizeDetail.order,
      id: prizeDetail.id
    };

    dispatch(showBackdrop())
    if ((!updateInfo.hasOwnProperty('excel') || updateInfo.excel === "") && updateInfo.isGiftCode) {
      dispatch(addAlert({ severity: "error", message: getLang(lang, "message.error.ERROR_REQUIRE_GIFT_CODE")}))
      dispatch(hideBackdrop());
      return;
    }

    if (!isExcel(updateInfo.excel) && updateInfo.isGiftCode) {
      dispatch(addAlert({ severity: "error", message: getLang(lang, "message.error.FORM_VALIDATE_EXCEL")}))
      dispatch(hideBackdrop());
      return;
    }

    // if (isNull(prizeImageFile) && !isImage(prizeImageFile)) {
    //   dispatch(addAlert({ severity: "error", message: displayFormMessage(lang.FORM_VALIDATE_IMAGE) }))
    //   dispatch(hideBackdrop());
    //   console.log(prizeImageFile);
    //   return;
    // }

    if (updateInfo.isGiftCode) {
      let excelQuantity = await getWorkbookFromFile(updateInfo.excel);

      if (excelQuantity.length !== prizeDetail.balance) {
        dispatch(
          addAlert({
            severity: "error",
            message: getLang(lang, "message.error.ERROR_GIFTCODE_LENGTH_MISMATCH")
          })
        );
        dispatch(hideBackdrop());
        return;
      }

      const excelFileName = `${Date.now()}${values.excel.name}`;
      const uploadExcelInfo = {
        uploadType: UploadType.giftCodeExcel,
        file: values.excel,
        id: userId,
        fileName: excelFileName
      };

      await dispatch(uploadFileToS3(uploadExcelInfo))
        .then(unwrapResult)
        .then(() => {
          const filePath = getUploadS3FilePath({
            type: uploadExcelInfo.uploadType,
            id: userId,
            fileName: uploadExcelInfo.fileName
          });
          updateInfo.fileKey = filePath;
        })
        .catch((error) => {
          dispatch(addAlert({ severity: "error", message: error.message || getLang(lang, "message.error.ERROR_UPLOAD_FILE")}));
          dispatch(hideBackdrop());
          return;
        });
    }

    if (isImage(prizeImageFile)) {
      const fileName = `${uuidv4()}.png`;
      const base64Image = await toBase64(prizeImageFile);
      const uploadLuckyDrawImageData = {
        uploadType: FolderType.instantprizeImage,
        base64Image: base64Image,
        id: userId,
        fileName: fileName
      };

      await dispatch(uploadImageToS3(uploadLuckyDrawImageData))
        .then(unwrapResult)
        .then((imageUrl) => {
          updateInfo.prizeImage = imageUrl.split("?")[0];
        })
        .catch(() => {
          dispatch(addAlert({ severity: "error", message: getLang(lang, "message.error.ERROR_UPLOAD_IMAGES")}));
          dispatch(hideBackdrop());
          return;
        });
    }
    handleNewDelete();
    handleIsEditing();
    dispatch(hideBackdrop());
    await handleSave(updateInfo);
  };

  const handleDownloadTemplate = () => {
    dispatch(showBackdrop());
    giftCodeExcel
      .get({ id: prizeDetail.id, token: accessToken })
      .finally(() => {
        dispatch(hideBackdrop());
        return;
      });
  };

  return (
    <>
      <EditPrizeCardComponent
        handleSubmit={handleSubmit}
        handleDelete={handleDelete}
        handleImgChange={handleImgChange}
        inputImgRef={inputImgRef}
        prizeDetail={prizeDetail}
        prizeImage={prizeImage}
        handleAdjustQuantity={handleAdjustQuantity}
        handleDownloadTemplate={handleDownloadTemplate}
        lang={lang}
        handleDisableEdit={handleDisableEdit}
      />
      <QuantityAdjusterDialog />
    </>
  );
}

export default EditPrizeCardContainer;
