import React, { useState, useEffect, useCallback } from "react";
import QrTabComponent from "./qrTab.component";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { confirmQrPurchaseQty, fetchFormula } from "../../redux";
import { calculateQRCreditCost } from "../../utils/calculation";
import { unwrapResult } from "@reduxjs/toolkit";
import { ErrorPanel } from "../panel/error/errorPanel.component";

function QrTabContainer({
  children,
  handleNext,
  confirmQrPurchaseQty,
  initialQty,
  fetchFormula,
  discountCalculation
}) {
  const lang = useSelector(state => state.constant.languages)
  const [isLoading, setIsLoading] = useState(true);
  const [calculation, setCalculation] = useState(null);
  const [quantity, setQuantity] = useState(initialQty);
  const [formula, setFormula] = useState(null);
  const [error, setError] = useState(false);

  const [basePrice, setBasePrice] = useState(0)

  const handleSubmit = values => {
    confirmQrPurchaseQty({
      quantity: values.quantity,
      calculation
    });
    handleNext();
  };

  const getFormula = useCallback(() => {
    setIsLoading(true);
    fetchFormula()
      .then(unwrapResult)
      .then(response => {
        const formula = response.data;
        setFormula(formula);
        setBasePrice(Number(response.data.credit_plan.base_price))
        setIsLoading(false);
        setError(false);
      })
      .catch(error => {
        setIsLoading(false);
        setError(true);
      });
  }, [fetchFormula]);

  useEffect(() => {
    getFormula();
  }, [getFormula]);

  useEffect(() => {
    if (!formula) return;
    const newCalculation = calculateQRCreditCost(
      quantity,
      formula,
      discountCalculation
    );

    setCalculation(newCalculation);
  }, [discountCalculation, formula, quantity]);

  const handleCalculateCost = e => {
    const qty = e.target.value;

    if (!qty || !formula) {
      setQuantity(0);
    }

    setQuantity(qty);
  };

  if (!isLoading && error) return <ErrorPanel handleClick={getFormula} />;

  return (
    <QrTabComponent
      children={children}
      handleSubmit={handleSubmit}
      handleCalculateCost={handleCalculateCost}
      initialQty={quantity}
      isLoading={isLoading}
      basePrice={basePrice}
      calculation={calculation}
      lang={lang}
    />
  );
}

const mapStateToProps = state => ({
  initialQty: state.marketplace.qrCode.quantity,
  discountCalculation: state.marketplace.discountCalculation
});

const mapDispatchToProps = dispatch => ({
  confirmQrPurchaseQty: payload => dispatch(confirmQrPurchaseQty(payload)),
  fetchFormula: () => dispatch(fetchFormula("serial_number"))
});

QrTabContainer.propTypes = {
  handleNext: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(QrTabContainer);
