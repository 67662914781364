import { createSlice } from "@reduxjs/toolkit";
import { REDEMPTIONCENTER } from "../../constants";
import {
    fetchGiftListing, getGift, fetchOrderListing, getOrder, getGiftDropdown, updateGift, fetchPointCutListing, getPointCut, getGiftOrderSetting, updateGiftOrderSetting
} from "../action";

const initialState = {
    giftListTable: {
        data: [],
        totalRecords: 0,
        filteredTotalRecords: 0
    },
    gift: {
        id: "",
        name: "",
        description: "",
        startAt: "",
        endAt: "",
        imgUrl: "",
        remainQuantity: "",
        itemPerCustomer: "",
        point: ""
    },
    giftOrderSetting: null,
    giftType: "view",
    orderListTable: {
        data: [],
        totalRecords: 0,
        filteredTotalRecords: 0
    },
    order: {
        id: "",
        name: "",
        address: "",
        requestDate: "",
        contact: "",
        items: {
            img: "",
            name: "",
            quantity: ""
        },
        status: "",
        adminRemark: "",
    },
    orderType: "view",
    pointCutListTable: {
        data: [],
        totalRecords: 0,
        filteredTotalRecords: 0
    },
    pointCut: {
        id: "",
        point: "",
        gift: [],
        status: "",
        parentId: "",
        startAt: "",
        endAt: ""
    },
    giftDropdown: [],
    pointCutType: "view",
    headerType: "view",
    isLoadingGiftListError: false,
    isLoadingRedemptionError: false,
    isLoadingPointCutError: false,
    pageSetting: {
        length: 25,
        start: 0,
        search: "",
    },
}

const redemptionCenterSlice = createSlice({
    name: REDEMPTIONCENTER,
    initialState,
    reducers: {

        resetGiftDetails(state, action) {
            state.gift = initialState.gift
        },
        resetOrderDetails(state, action) {
            state.order = initialState.order
        },
        resetPointCutDetails(state, action) {
            state.pointCut = initialState.pointCut
        },
        resetImage(state, action) {
            state.gift.imgUrl = initialState.gift.imgUrl
        }
        ,
        setType(state, action) {
            const { payload } = action;
            state.headerType = payload.type;
            switch (payload.index) {
                case 0:
                    state.giftType = payload.type;
                    break;
                case 1:
                    state.orderType = payload.type;
                    break;
                case 2:
                    state.pointCutType = payload.type;
                    break;
                default:
                    state.giftType = payload.type;
                    state.orderType = payload.type;
                    state.pointCutType = payload.type;
                    break;
            }
        },
        updateRedemptionPageSetting(state, action) {
            const { payload } = action;
            state.pageSetting = {
                ...state.pageSetting,
                ...payload,
            };
        },
    },
    extraReducers: {
        [fetchGiftListing.fulfilled]: (state, action) => {
            const { payload } = action
            const tempArray = []

            state.giftListTable.totalRecords = payload.recordsFiltered

            payload.data.forEach(item => {
                tempArray.push({
                    giftId: item.id,
                    name: item.name,
                    point: item.point,
                    dateAdded: item.createdAt,
                    status: item.status,
                    stock: item.remainQuantity,
                    startAt: item.startAt,
                    endAt: item.endAt
                })
            })

            state.giftListTable.data = tempArray;
            state.isLoadingGiftListError = false;
        },
        [fetchGiftListing.rejected]: (state, action) => {
            state.isLoadingGiftListError = true;
          },
        [getGift.fulfilled]: (state, action) => {
            const { payload } = action
            const { gift } = state

            gift.id = payload.id
            gift.name = payload.name
            gift.description = payload.description
            gift.startAt = payload.startAt
            gift.endAt = payload.endAt
            gift.imgUrl = payload.imgUrl
            gift.remainQuantity = payload.remainQuantity
            gift.itemPerCustomer = payload.itemPerCustomer
            gift.point = payload.point
        },
        [updateGift.fulfilled]: (state, action) => {
            const { payload } = action
            const { gift } = state

            gift.imgUrl = payload.imgUrl
        },
        [fetchOrderListing.fulfilled]: (state, action) => {
            const { payload } = action
            const tempArray = []

            state.orderListTable.totalRecords = payload.recordsTotal
            state.orderListTable.filteredTotalRecords = payload.recordsFiltered

            payload.data.forEach(item => {
                tempArray.push({
                    orderId: item.id,
                    name: item.name,
                    dateAdded: item.createdAt,
                    status: item.status,
                    item: item.items.length,
                    dateProceed: item.updatedAt
                })
            })

            state.orderListTable.data = tempArray;
            state.isLoadingRedemptionError = false;
        },
        [fetchOrderListing.rejected]: (state, action) => {
            state.isLoadingRedemptionError = true;
          },

        [getOrder.fulfilled]: (state, action) => {
            const { payload } = action
            const { order } = state
            const address = JSON.parse(payload.address)

            order.id = payload.id
            order.name = payload.name
            order.address = address
            order.requestDate = payload.updatedAt
            order.contact = payload.phoneNumber

            const items = []
            payload.items.forEach((item) => {
                items.push({
                    img: item.itemImgUrl,
                    name: item.itemName,
                    quantity: item.quantity
                })
            })
            order.items = items
            order.status = payload.status
            order.adminRemark = payload.adminRemark
        },
        [getGiftDropdown.fulfilled]: (state, action) => {
            const { payload } = action
            const tempArray = []

            payload.data.forEach(item => {
                tempArray.push({
                    id: item.id,
                    picture:item.imgUrl,
                    name: item.name,
                    point: item.point,
                    quantity: item.remainQuantity
                })
            })

            state.giftDropdown = tempArray
        },
        [fetchPointCutListing.fulfilled]: (state, action) => {
            const { payload } = action
            const tempArray = []
            state.pointCutListTable.totalRecords = payload.recordsTotal
            state.pointCutListTable.filteredTotalRecords = payload.recordsFiltered

            payload.data.forEach(item => {
                tempArray.push({
                    pointCutId: item.id,
                    percent: item.amount,
                    dateAdded: item.createdAt,
                    startAt: item.startAt,
                    endAt: item.endAt,
                    gift: item.items,
                    status: item.status,
                })
            })

            state.pointCutListTable.data = tempArray;
            state.isLoadingPointCutError = false;
        },
        [fetchPointCutListing.rejected]: (state, action) => {
            state.isLoadingPointCutError = true;
        },
        [getPointCut.fulfilled]: (state, action) => {
            const { payload } = action
            const { pointCut } = state
            pointCut.id = payload.id
            pointCut.point = payload.amount
            pointCut.status = payload.status
            pointCut.startAt = payload.startAt
            pointCut.endAt = payload.endAt

            const gifts = [];
            payload.items.forEach((gift) => {
                const info = {
                    id: gift.itemId,
                    name: gift.itemInfo.name,
                    point: gift.itemInfo.point,
                    quantity: gift.itemInfo.remainQuantity
                };
                gifts.push(info);
            });

            pointCut.gift = gifts
        },
        [getGiftOrderSetting.pending]: (state, action) => {
            state.giftOrderSetting = initialState.giftOrderSetting
        },
        [getGiftOrderSetting.fulfilled]: (state, action) => {
            const { payload } = action
            state.giftOrderSetting = payload.value
        },
        [updateGiftOrderSetting.fulfilled]: (state, action) => {
            const { meta } = action
            if (state.giftOrderSetting) {
                state.giftOrderSetting = meta.arg.value
            }
        },
    },

})

export const redemptionCenterReducer = redemptionCenterSlice.reducer;
export const {
    resetGiftDetails,
    setType,
    resetImage,
    resetOrderDetails,
    resetPointCutDetails,
    updateRedemptionPageSetting
} = redemptionCenterSlice.actions