import { Box, IconButton, makeStyles, Typography } from "@material-ui/core";
import { DownloadIcon } from "modules/reporting/assets/svg";
import React from "react";
import downloadjs from 'downloadjs';
import html2canvas from 'html2canvas';
import DynamicTable from "../dynamic-table";


const useStyles = makeStyles((theme) => ({
    table__title: {
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "20px",
        color: "#101828",
    }
}));

export default function DownloadableTable({
    title,
    columns = [],
    sort,
    status,
    handleExportExcel,
    pagination,
    data,
    translate
}) {
    const classes = useStyles();

    const buttonGroupId = title + '_buttonGroup';
    const handleDownloadCard = (exportExcel) => {


        if (exportExcel) {
            handleExportExcel();
        }
        else {
            let element = document.getElementById(title);

            // hide element have id = remove-this inside target
            let removeThis = element.querySelector(buttonGroupId);
            removeThis.style.display = "none";

            html2canvas(element).then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                downloadjs(imgData, 'chart_' + title + '.png');
            });

            // show element have id = remove-this inside target
            removeThis.style.display = "flex";

        }
    }



    return (
        // <></>
        <Box
            display="flex"
            flexDirection="column"
            id={title}
        >
            {/* title and download icon */}
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                marginBottom="12px"
            >
                <Typography className={classes.table__title}>
                    {title}
                </Typography>
                <Box
                    id={buttonGroupId}
                >
                    <IconButton title="Download Charts" aria-label="download" onClick={handleDownloadCard} disableRipple>
                        <DownloadIcon />
                    </IconButton>
                </Box>
            </Box>

            {/* table */}
            <DynamicTable
                columns={columns}
                sort={sort}
                status={status}
                pagination={pagination}
                data={data}
                translate={translate}
            />

        </Box>
    )
}