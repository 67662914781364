import React from "react";
import {
  makeStyles,
  Box,
  IconButton,
  Dialog,
  Typography,
  Backdrop,
  Tabs,
  Divider,
} from "@material-ui/core";
import { CloseRounded as CloseRoundedIcon } from "@material-ui/icons";
import { getLang } from "app/feature/constants";
import "react-image-lightbox/style.css";
import DefaultImg from "assets/img/img-default.png";
import ProductSNListTable from "../../table/productSNListTable";
import CustomTab from "components/tab/customTab";

const useStyle = makeStyles((theme) => ({
  header: {
    padding: "12px 16px",
    borderBottom: "1px solid #D0D5DD",
  },
  transferProductRow: {
    backgroundColor: "#FFFFFF",
    border: "1px solid #D0D5DD",
    "& > div:first-child": {
      borderBottom: "1px solid #D0D5DD",
    },
  },
  receiveProductRow: {
    backgroundColor: "#FFFFFF",
    "& > div:first-child": {
      borderBottom: "1px solid #D0D5DD",
    },
    "& > div:last-child": {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      "& > div:first-child": {
        borderRight: "1px solid #D0D5DD",
      },
    },
  },
  container: {
    backgroundColor: "#EBEDEE",
    overflowY: "auto",
    padding: "16px 16px 34px 16px",
  },
  productPhoto: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #0000001a",
    width: "45px",
    minWidth: "45px",
    height: "45px",
    borderRadius: "4px",
    "& img": {
      height: "100%",
      width: "100%",
    },
  },
  attributeText: {
    color: "#b2b2b2",
    fontSize: 12,
    whiteSpace: "break-spaces",
  },
}));

export default function TransferProductSnListDialogComponent({
  isOpen,
  handleClose,
  lang,
  productInfo,
  list,
  isFetching,
  isError,
  totalFiltered,
  paginationOptions,
  changeOrder,
  handleChangePage,
  handleChangeRowsPerPage,
  tableFilter,
  handleReload,
  tab,
  boxUuid,
  handleChangeTab,
}) {
  const classes = useStyle();

  const PARTIAL_RECEIVE = "Partial Receive";
  const RECEIVED = "Received";

  let isReceive = productInfo
    ? [PARTIAL_RECEIVE, RECEIVED].includes(productInfo.status)
    : false;

  function a11yProps(index, label = "label") {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tab-${index}`,
      label: <Typography variant="button" children={label} />,
    };
  }

  return (
    <Dialog
      maxWidth="md"
      open={isOpen}
      onClose={() => {}}
      aria-labelledby="modal-scan-detail"
      aria-describedby="modal-scan-detail"
      BackdropComponent={Backdrop}
      fullWidth
      PaperProps={{
        style: {
          overflowY: "hidden",
        },
      }}
    >
      <Box
        className={classes.header}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="body1">
          <b>
            {getLang(
              lang,
              isReceive ? "label.ITEMS_RECEIVED" : "label.ITEMS_TO_RECEIVE"
            )}
          </b>
        </Typography>
        <IconButton
          onClick={handleClose}
          edge="end"
          size="small"
          aria-label="close dialog"
        >
          <CloseRoundedIcon color="primary" />
        </IconButton>
      </Box>
      <Box className={classes.container}>
        <Box mb={2}>
          <Typography variant="body1">
            <b>{getLang(lang, "label.PRODUCT_DETAILS")}</b>
          </Typography>
        </Box>
        {!!productInfo && (
          <>
            <Box
              className={
                isReceive
                  ? classes.receiveProductRow
                  : classes.transferProductRow
              }
            >
              <Box display="flex" alignItems="center" p={1}>
                <Box className={classes.productPhoto} mr={2}>
                  {!productInfo?.picture ? (
                    <img src={DefaultImg} alt="emptyProduct" />
                  ) : (
                    <img src={productInfo.picture} alt={productInfo.name} />
                  )}
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="flex-start"
                >
                  <Typography variant="body1">
                    <b>{productInfo.name}</b>
                  </Typography>
                  {!!productInfo.attributes &&
                    !!productInfo.attributes.length && (
                      <Typography
                        className={classes.attributeText}
                        variant="body2"
                      >
                        {productInfo.attributes.join(", ")}
                      </Typography>
                    )}
                </Box>
              </Box>
              {isReceive ? (
                <Box>
                  <Box p={1}>
                    <Box>
                      <Typography variant="body1">
                        <b>{productInfo.total_receive}</b>
                      </Typography>
                      <Typography variant="body2">
                        {getLang(lang, "label.RECEIVED")}
                      </Typography>
                    </Box>
                  </Box>
                  <Box p={1}>
                    <Box>
                      <Typography
                        variant="body1"
                        style={{
                          color:
                            productInfo.total_transfer -
                              productInfo.total_receive !==
                            0
                              ? "#F97066"
                              : "#101828",
                        }}
                      >
                        <b>
                          {productInfo.total_transfer -
                            productInfo.total_receive}
                        </b>
                      </Typography>
                      <Typography variant="body2">
                        {productInfo.status === PARTIAL_RECEIVE
                          ? getLang(lang, "label.PENDING")
                          : getLang(lang, "label.MISSING")}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              ) : (
                <Box p={1}>
                  <Typography variant="body1">
                    <b>{productInfo.total_transfer}</b>
                  </Typography>
                  <Typography variant="body2">
                    {getLang(lang, "label.TRANSFERRED")}
                  </Typography>
                </Box>
              )}
            </Box>
            <Box p={2} style={{ background: "white" }} mt={2}>
              {isReceive && (
                <Box mb={2}>
                  <Tabs
                    value={tab}
                    onChange={handleChangeTab}
                    aria-label="scrollable auto tabs example"
                    textColor="primary"
                    variant="scrollable"
                    indicatorColor="primary"
                    classes={{
                      indicator: classes.indicator,
                      scrollButtons: classes.scroll,
                    }}
                  >
                    {[
                      getLang(lang, "label.SCANNED_TAB"),
                      productInfo.status === PARTIAL_RECEIVE
                        ? getLang(lang, "label.PENDING")
                        : getLang(lang, "label.MISSING"),
                    ].map((item, index) => {
                      return (
                        <CustomTab key={index} {...a11yProps(index, item)} />
                      );
                    })}
                  </Tabs>
                  <Divider />
                </Box>
              )}
              <ProductSNListTable
                rows={list}
                isFetching={isFetching}
                isError={isError}
                handleReload={handleReload}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                totalFiltered={totalFiltered}
                paginationOptions={paginationOptions}
                tableFilter={tableFilter}
                changeOrder={changeOrder}
                lang={lang}
                boxUuid={boxUuid}
              />
            </Box>
          </>
        )}
      </Box>
    </Dialog>
  );
}
