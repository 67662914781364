import axios from "axios";

const resendVerificationEmail = async ({
    userId
}) => {
    const response = await axios.post("/account/public/api/v1/resend-email", {
        user_id: userId
    })

    return response
};

export default resendVerificationEmail;
