/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import CustomFieldSelectComponent from "./customFieldSelect.component";
import CustomFieldSelectPopoverComponent from "./customFieldSelectPopover.component";
import { useDispatch, useSelector } from "react-redux";
import { selectLoading } from "modules/notification";
import {
  getStockoutCustomFieldDropdown,
  resetStockoutCustomFieldDropdown,
} from "modules/stockflow/redux";

function CustomFieldSelectContainer({
  value,
  placeholder,
  handleChange,
  disabled,
  style,
  textStyle,
  userLang,
  selectedIds,
  setFilter,
  customFieldsDropdown,
  isError,
  filter,
}) {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.constant.languages);
  const isFetching = useSelector((state) =>
    selectLoading(state, getStockoutCustomFieldDropdown.typePrefix)
  );

  const [dropdown, setDropdown] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    setDropdown(
      customFieldsDropdown.filter((c) => !selectedIds.includes(c.id))
    );
  }, [customFieldsDropdown, selectedIds]);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    if (filter.search) {
      setFilter({
        search: "",
      });
    }
  };

  const handleSearch = (value) => {
    dispatch(resetStockoutCustomFieldDropdown());
    setFilter({
      search: value,
    });
  };

  const handleReload = () => {
    dispatch(resetStockoutCustomFieldDropdown());
    setFilter({
      search: "",
    });
  };

  const handleSelectField = (field) => {
    handleChange(field);
    handleClose();
  };

  return (
    <>
      <CustomFieldSelectComponent
        handleClick={handleOpen}
        value={value}
        style={style}
        disabled={disabled}
        placeholder={placeholder}
        open={open}
        textStyle={textStyle}
        userLang={userLang}
      />
      <CustomFieldSelectPopoverComponent
        customFields={customFieldsDropdown}
        value={value}
        anchorEl={anchorEl}
        open={open}
        handleClose={handleClose}
        isFetching={isFetching}
        dropdown={dropdown}
        handleSelectField={handleSelectField}
        lang={lang}
        isError={isError}
        handleReload={handleReload}
        handleSearch={handleSearch}
        userLang={userLang}
      />
    </>
  );
}

export default CustomFieldSelectContainer;
