/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import BranchSelectComponent from "./branchSelect.component";
import { selectLoading } from "modules/notification";
import BranchSelectPanelComponent from "./branchSelectPanel.component";
import { useSelector, useDispatch } from "react-redux";
import { cloneDeep } from "lodash";
import {
  getAllAvailableBranchesForRules,
  resetAllBranches,
} from "modules/group/redux";
import { Box } from "@material-ui/core";

function BranchSelectContainer({
  handleChange,
  disabled,
  value,
  styles,
  textStyles,
  placeholder,
  nodeUuid,
}) {
  const branchDropdown = useSelector((state) => state.branchGroup.allBranches);
  const isFetching = useSelector((state) =>
    selectLoading(state, getAllAvailableBranchesForRules.typePrefix)
  );

  const lang = useSelector((state) => state.constant.languages);
  const [selectAll, setSelectAll] = useState(false);
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    dispatch(getAllAvailableBranchesForRules({ search, nodeUuid }));

    return () => {
      dispatch(resetAllBranches());
    };
  }, [search, dispatch, nodeUuid]);

  useEffect(() => {
    setSelectAll(value.length === branchDropdown.length);
  }, [value, branchDropdown]);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setSearch("");
    setAnchorEl(null);
  };

  const handleSelectAll = async () => {
    if (!selectAll) {
      handleChange(branchDropdown);
    } else {
      handleChange([]);
    }
  };

  const handleSelectBranch = (branch) => {
    let temp = cloneDeep(value);

    if (temp.findIndex((t) => t.uuid === branch.uuid) >= 0) {
      temp = temp.filter((t) => t.uuid !== branch.uuid);
    } else {
      temp.push(branch);
    }
    handleChange(temp);
  };

  const handleSearch = (value) => {
    setSearch(value);
  };

  return (
    <Box position="relative" width="100%">
      <BranchSelectComponent
        disabled={disabled}
        value={value}
        handleClick={handleOpen}
        styles={styles}
        textStyles={textStyles}
        placeholder={placeholder}
        lang={lang}
      />
      <BranchSelectPanelComponent
        selectAll={selectAll}
        value={value}
        handleClose={handleClose}
        isFetching={isFetching}
        branchDropdown={branchDropdown}
        handleSelectAll={handleSelectAll}
        handleSelectBranch={handleSelectBranch}
        handleSearch={handleSearch}
        lang={lang}
        anchorEl={anchorEl}
        open={open}
      />
    </Box>
  );
}

export default BranchSelectContainer;
