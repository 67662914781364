import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from '@reduxjs/toolkit'
import { selectLoading } from 'modules/notification';
import SurveyReportPanelComponent from "./surveyReportPanel.component";
import {
  getSurvey,
  editSurvey,
  fetchTotalResponse,
  fetchTotalVisit,
  fetchSurveyResponseCount,
  fetchSurveyByAge,
  fetchSurveyByProduct,
  fetchSurveyByGender,
  fetchSurveyByDevice,
  fetchSurveyGeoCount
} from 'modules/survey/redux/action';
import { EditSurveyDialog } from "../../dialog"
import { getLang } from "app/feature/constants";

const SurveyReportPanelContainer = ({
  uuid
}) => {
  const lang = useSelector(state => state.constant.languages);
  const language = useSelector(state => state.profile.language);
  const dispatch = useDispatch();
  const [lastPeriod, setLastPeriod] = useState(getLang(lang, "label.TODAY"));
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const surveyDetails = useSelector((state) => state.survey.survey);
  const totalResponse = useSelector((state) => state.survey.surveyAnalytic.totalResponse);
  const totalVisit = useSelector((state) => state.survey.surveyAnalytic.totalVisit)
  const responseCount = useSelector((state) => state.survey.surveyAnalytic.response)
  const surveyCountByAge = useSelector((state) => state.survey.surveyAnalytic.ageCount);
  const surveyCountByProduct = useSelector((state) => state.survey.surveyAnalytic.product);
  const surveyCountByGender = useSelector((state) => state.survey.surveyAnalytic.gender);
  const surveyCountByDevice = useSelector((state) => state.survey.surveyAnalytic.device);
  const totalDevice = useSelector((state) => state.survey.surveyAnalytic.totalDevice);
  const surveyCountByGeo = useSelector((state) => state.survey.surveyAnalytic.geo);
  const isScanLocationLoading = useSelector((state) => selectLoading(state, fetchSurveyGeoCount.typePrefix))
  const isDetailsLoading = useSelector((state) => selectLoading(state, getSurvey.typePrefix))
  
  const handleFetchAllReport = useCallback((lastPeriod) => {
    let period = "";
    
    if (lastPeriod === getLang(lang, 'label.TODAY')) {
      period = "1 day"
    } else if (lastPeriod === getLang(lang, 'label.ONE_WEEK')) {
      period = "1 week"
    } else if (lastPeriod === getLang(lang, 'label.ONE_MONTH')) {
      period = "1 month"
    } else if (lastPeriod === getLang(lang, 'label.ONE_YEAR')) {
      period = "1 year"
    }

    dispatch(getSurvey({ id: uuid }))
    dispatch(fetchTotalResponse({ survey_uuid: uuid, last_period: period }))
    dispatch(fetchTotalVisit({ survey_uuid: uuid, last_period: period }))
    dispatch(fetchSurveyResponseCount({ survey_uuid: uuid, last_period: period }))
    dispatch(fetchSurveyByAge({ survey_uuid: uuid, last_period: period }))
    dispatch(fetchSurveyByProduct({ survey_uuid: uuid, last_period: period }))
    dispatch(fetchSurveyByGender({ survey_uuid: uuid, last_period: period }))
    dispatch(fetchSurveyByDevice({ survey_uuid: uuid, last_period: period }))
    dispatch(fetchSurveyGeoCount({ survey_uuid: uuid, last_period: period }))
  }, [dispatch, uuid,
    lang,
    ])
  
  useEffect(() => {
    handleFetchAllReport(lastPeriod)
  }, [handleFetchAllReport, lastPeriod])
  
  const closeEditSurveyModal = () => {
    setIsEditDialogOpen(false)
  }

  const openEditSurveyModal = () => {
    setIsEditDialogOpen(true)
  }

  const handleEditSurvey = values => {
    dispatch(editSurvey(values))
      .then(unwrapResult)
      .then(() => {
        handleFetchAllReport(lastPeriod)
      })
      .finally(() => closeEditSurveyModal())
  }

  return (
    <>
      <SurveyReportPanelComponent
        surveyDetails={surveyDetails}
        totalResponse={totalResponse}
        totalVisit={totalVisit}
        responseCount={responseCount}
        surveyCountByAge={surveyCountByAge}
        surveyCountByProduct={surveyCountByProduct}
        language={language}
        surveyCountByGender={surveyCountByGender}
        surveyCountByDevice={surveyCountByDevice}
        totalDevice={totalDevice}
        surveyCountByGeo={surveyCountByGeo}
        isDetailsLoading={isDetailsLoading}
        isScanLocationLoading={isScanLocationLoading}
        handleLastPeriod={(value) => setLastPeriod(value)}
        lastPeriod={lastPeriod}
        openEditSurveyModal={openEditSurveyModal}
        lang={lang}
      />
      <EditSurveyDialog
        isOpen={isEditDialogOpen}
        handleClose={closeEditSurveyModal}
        handleSubmit={handleEditSurvey}
      />
    </>
  )
}

export default SurveyReportPanelContainer