import React, { useEffect, useRef } from "react";
import AdtInfoImportProgressComponent from "./adtInfoImportProgress.component";
import { useDispatch, useSelector } from "react-redux";
import { checkHasPendingProcess } from "modules/serial-number/redux";

function AdtInfoImportProgressContainer() {
  const lang = useSelector((state) => state.constant.languages);
  const pendingProcess = useSelector(
    (state) => state.additionalInfo.pendingProcess
  );
  const dispatch = useDispatch();

  const intervalRef = useRef(null);

  useEffect(() => {
    if (pendingProcess) {
      intervalRef.current = setInterval(() => {
        dispatch(checkHasPendingProcess());
      }, 7000);
    } else {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingProcess]);

  return (
    <AdtInfoImportProgressComponent
      pendingProcess={pendingProcess}
      lang={lang}
    />
  );
}

export default AdtInfoImportProgressContainer;
