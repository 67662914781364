import React, { useState, useEffect, useRef } from "react";
import { WarrantySettingSVG, WarrantyRegSettingSVG } from "../../utils/static";
import WarrantyForm from "../form/warrantySettingForm";
import RegSettingForm from "../form/regSettingForm";
import StepperComponent from "./stepper.component";
import ConfirmationDialogComponent from "components/dialog/confirmation";
import { useDispatch, useSelector } from "react-redux";
import Dialog from "components/dialog/customDialog";
import WarrantyExtensionPanel from "modules/warranty/components/panel/warrantyExtensionPanel";
import { UploadType } from "lib/constants/aws_s3";
import { uploadJSONToS3, uploadFileToS3 } from "app/feature";
import { addAlert } from "modules/notification";
import { hideBackdrop, showBackdrop } from "modules/backdrop";
import {
  createWarrantySetting,
  resetWarrantySetting,
  resetRegisterForm,
  setWarrantyRegisterForm,
  resetRegisterFormType,
} from "../../redux";
import { WARRANTY_EDIT } from "lib/constants/accessRights";
import { getLang } from "app/feature/constants";
import { getProduct } from "modules/product-mgmt/redux/action/products.action";
import { useRouteMatch } from "react-router-dom";

function getSteps(lang) {
  const WarrantySettingSVGWhite = () => (
    <WarrantySettingSVG color="rgba(58, 77, 84, 0.33)" />
  );

  const WarrantyRegSettingSVGWhite = () => (
    <WarrantyRegSettingSVG color="rgba(58, 77, 84, 0.33)" />
  );

  let steps = [
    {
      form: WarrantyForm,
      labelImage: WarrantySettingSVGWhite,
      activeImage: WarrantySettingSVG,
      label: getLang(lang, "label.WARRANTY_SETTINGS"),
    },
    {
      form: RegSettingForm,
      labelImage: WarrantyRegSettingSVGWhite,
      activeImage: WarrantyRegSettingSVG,
      label: getLang(lang, "label.WARRANTY_REG_SETTINGS"),
    },
  ];
  return steps;
}

function StepperContainer({ earlyAccess, handleClose }) {
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const productPageId = match.params.productId;
  const hasWarrantyEditAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(WARRANTY_EDIT)
  );
  const isFormEditing = useSelector(
    (state) => state.warranty.settingFormEditing
  );
  const lang = useSelector((state) => state.constant.languages);
  const id = useSelector((state) => state.session.idTokenPayload.sub);
  const productPage = useSelector((state) => state.productPage);
  const warrantySettingForm = useSelector(
    (state) => state.warranty.warrantySetting
  );

  const steps = getSteps(lang);
  const [confirmationDialogModal, setConfirmationDialogModal] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [isModalOpen, setModalOpen] = useState(false);
  const [file, setFile] = useState(null);
  const formRef = useRef();

  useEffect(() => {
    return () => {
      resetWarrantySetting();
      resetRegisterFormType();
      resetRegisterForm();
    };
  }, [dispatch]);

  const handleNext = async (warrantyRequiredValidation) => {
    if (activeStep === 1) {
      formRef.current.handleSubmit();

      const participantregformdata = formRef.current.values.form;

      const uniqueVariantNames = new Set();

      for (const property of participantregformdata) {
        if (
          property.variantName === undefined ||
          property.variantName.trim() === ""
        ) {
          await dispatch(
            addAlert({
              severity: "error",
              message: getLang(lang, "message.warning.FILL_IN_REQUIRED_FIELD"),
            })
          );
          dispatch(hideBackdrop());
          return;
        }

        if (uniqueVariantNames.has(property.variantName)) {
          await dispatch(
            addAlert({
              severity: "error",
              message: getLang(lang, "message.warning.DUPLICATE_VARIANT_NAME"),
            })
          );
          dispatch(hideBackdrop());
          return;
        }

        uniqueVariantNames.add(property.variantName);

        if (
          property.type === "multipleChoice" ||
          property.type === "dropdownlist" ||
          property.type === "multipleChoiceMultiSelect"
        ) {
          for (const choices of property.choice) {
            if (choices.trim() === "" || choices === undefined) {
              await dispatch(
                addAlert({
                  severity: "error",
                  message: getLang(
                    lang,
                    "message.warning.CHOICES_CANNOT_BE_EMPTY"
                  ),
                })
              );
              dispatch(hideBackdrop());
              return;
            }
          }

          const uniqueChoices = new Set(property.choice);
          if (uniqueChoices.size !== property.choice.length) {
            await dispatch(
              addAlert({
                severity: "error",
                message: getLang(
                  lang,
                  "message.warning.CHOICES_MUST_BE_UNIQUE"
                ),
              })
            );
            dispatch(hideBackdrop());
            return;
          }

          if (property.choice.length < 2) {
            await dispatch(
              addAlert({
                severity: "error",
                message: getLang(
                  lang,
                  "message.warning.MUST_HAVE_AT_LEAST_TWO_CHOICES"
                ),
              })
            );
            dispatch(hideBackdrop());
            return;
          }
        }
      }

      const formdata = formRef.current.values.form;

      showBackdrop();
      if (warrantySettingForm.warrantyTermsConditionText) {
        const termAndConditionFileName = "JsonFile";
        const response = await dispatch(
          uploadJSONToS3({
            uploadType: UploadType.warrantyTermAndCondition,
            data: warrantySettingForm.termsConditionDetail,
            id: id,
            fileName: `${termAndConditionFileName}_${Date.now()}${termAndConditionFileName}`,
          })
        );
        if (response.type === "s3/uploadJSON/fulfilled") {
          const productId = [];
          for (const product of warrantySettingForm.productIds) {
            productId.push(product.id);
          }
          const info = {
            warrantySetting: {
              ...warrantySettingForm,
              claimAdd: warrantySettingForm.claimReasons
                .filter((item) => item.command[0] === "add")
                .map((item) => item.name),
              fileUrl: response.payload,
            },
            productId: productId,
            warrantyRegister: formdata,
          };
          const createResponse = await dispatch(createWarrantySetting(info));
          if (createResponse.payload.success) {
            if (hasWarrantyEditAccessRight) {
              setConfirmationDialogModal(true);
            } else {
              closeModal();
            }

            if (productPage.isInProductPage) {
              dispatch(getProduct(productPageId));
            }
          }
          hideBackdrop();
        } else {
          hideBackdrop();
          addAlert({
            severity: "error",
            message: response.message || "Upload files failed!",
          });
        }
      } else {
        const termAndConditionFileName = file.name;
        const response = await dispatch(
          uploadFileToS3({
            uploadType: UploadType.warrantyTermAndCondition,
            file: file,
            id: id,
            fileName: `${termAndConditionFileName}_${Date.now()}${termAndConditionFileName}`,
          })
        );
        if (response.type === "s3/uploadFile/fulfilled") {
          const productId = [];
          for (const product of warrantySettingForm.productIds) {
            productId.push(product.id);
          }
          const info = {
            warrantySetting: {
              ...warrantySettingForm,
              claimAdd: warrantySettingForm.claimReasons
                .filter((item) => item.command[0] === "add")
                .map((item) => item.name),
              fileUrl: response.payload,
            },
            productId: productId,
            warrantyRegister: formdata,
          };
          const createResponse = await dispatch(createWarrantySetting(info));

          if (createResponse.payload.success) {
            if (hasWarrantyEditAccessRight) {
              setConfirmationDialogModal(true);
            } else {
              closeModal();
            }

            if (productPage.isInProductPage) {
              dispatch(getProduct(productPageId));
            }
          }
          hideBackdrop();
        } else {
          hideBackdrop();
          addAlert({
            severity: "error",
            message: response.message || "Upload files failed!",
          });
        }
      }
    } else {
      let newWarrantyRegisterForm = [
        {
          fieldName: "name",
          labelType: getLang(lang, "label.SHORT_TEXT"),
          type: "textField",
          mandatory: true,
          visible: true,
          enableDelete: false,
          editing: false,
          fieldType: "standard",
          variantName: getLang(lang, "label.NAME"),
          choice: [],
        },
        {
          fieldName: "ic",
          labelType: getLang(lang, "label.IDENTIFICATION_TEXT_FIELD"),
          type: "textField",
          mandatory: true,
          visible: true,
          enableDelete: false,
          editing: false,
          fieldType: "standard",
          variantName: getLang(lang, "label.IC_NUMBER"),
          choice: [],
        },
        {
          fieldName: "email",
          labelType: getLang(lang, "label.EMAIL"),
          type: "textField",
          mandatory: true,
          visible: true,
          enableDelete: false,
          editing: false,
          fieldType: "standard",
          variantName: getLang(lang, "label.EMAIL"),
          choice: [],
        },
        {
          fieldName: "phone",
          labelType: getLang(lang, "label.CONTACT_NUMBER"),
          type: "contactNumber",
          mandatory: true,
          visible: true,
          enableDelete: false,
          editing: false,
          fieldType: "standard",
          variantName: getLang(lang, "label.CONTACT_NUMBER"),
          choice: [],
        },
        {
          fieldName: "purchased_at",
          labelType: getLang(lang, "label.DATE_PICKER"),
          type: "datePicker",
          mandatory: true,
          visible: true,
          enableDelete: false,
          editing: false,
          fieldType: "standard",
          variantName: getLang(lang, "label.DATE_OF_PURCHASE"),
          choice: [],
        },
        {
          fieldName: "place_of_purchase",
          labelType: getLang(lang, "label.SHORT_TEXT"),
          type: "textField",
          mandatory: true,
          visible: true,
          enableDelete: false,
          editing: false,
          fieldType: "standard",
          variantName: getLang(lang, "label.PLACE_OF_PURCHASE"),
          choice: [],
        },
        {
          fieldName: "proof_of_purchase",
          labelType: getLang(lang, "label.IMAGE_UPLOAD"),
          type: "uploadImage",
          mandatory: true,
          visible: true,
          enableDelete: false,
          editing: false,
          fieldType: "standard",
          variantName: getLang(lang, "label.PROOF_OF_PURCHASE"),
          choice: [],
        },
      ];
      if (earlyAccess) {
        newWarrantyRegisterForm.push({
          fieldName: "do_number",
          labelType: getLang(lang, "label.SHORT_TEXT"),
          type: "textField",
          mandatory: warrantyRequiredValidation ? true : false,
          visible: warrantyRequiredValidation ? true : false,
          enableDelete: false,
          editing: false,
          fieldType: "standard",
          variantName: getLang(lang, "label.DO_NUMBER"),
          choice: [],
        });
      }
      dispatch(
        setWarrantyRegisterForm({
          warrantyRegisterForm: newWarrantyRegisterForm,
        })
      );
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const closeConfirmationDialog = () => {
    setConfirmationDialogModal(false);
    handleClose();
  };

  const handleProceed = () => {
    setConfirmationDialogModal(false);
    handleAddWarrantyExtension();
  };

  const handleAddWarrantyExtension = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    resetWarrantySetting();
    resetRegisterForm();
    handleClose();
  };

  const handleDiscard = () => {
    resetWarrantySetting();
    resetRegisterForm();
    handleClose();
  };

  return (
    <>
      <StepperComponent
        earlyAccess={earlyAccess}
        steps={steps}
        activeStep={activeStep}
        handleBack={handleBack}
        formRef={formRef}
        handleNext={handleNext}
        handleDiscard={handleDiscard}
        file={file}
        setFile={setFile}
        isFormEditing={isFormEditing}
        lang={lang}
      />
      <Dialog
        isOpen={isModalOpen}
        handleClose={closeModal}
        disableEnforceFocus={true}
        disableAutoFocus={true}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
      >
        <WarrantyExtensionPanel handleClose={closeModal} type="create" />
      </Dialog>
      <ConfirmationDialogComponent
        isOpen={confirmationDialogModal}
        handleClose={closeConfirmationDialog}
        handleProceed={handleProceed}
        type={"success"}
        description={getLang(lang, "paragraph.ADD_EXTENSION_TO_WARRANTY")}
        title={getLang(lang, "label.WARRANTY_CREATED")}
        remarks={getLang(lang, "paragraph.ADD_EXTENSION_LATER")}
      />
    </>
  );
}

export default StepperContainer;
