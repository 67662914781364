import { createAsyncThunk } from "@reduxjs/toolkit";
import reportingApi from "app/api/reporting";
import moment from "moment";

// (1) country sales data -- start
export const getCountrySalesData = createAsyncThunk(
    "reporting/getCountrySalesData",
    async (payload, thunkApi) => {
        return reportingApi.getCountrySalesData({
            length: payload.length,
            start: payload.start,
            search: payload.search,
            orderBy: payload.orderBy,
            orderDirection: payload.orderDirection,
            search_by: payload.searchBy,
            country : payload.countryCode,
            city_id: payload.city_uuid,
            branch_uuid: payload.branch_uuid,
            category_uuid: payload.category_uuid,
            model_uuid: payload.model_uuid,
            brand_uuid: payload.brand_uuid,
            product_uuid: payload.product_uuid,
            period: payload.period,
            start_date: moment(payload.start_date).format('YYYY-MM-DD'),
            end_date: moment(payload.end_date).format('YYYY-MM-DD'),
            url : payload.url,
            campaign_type: payload.campaign_type,
            campaign_uuid: payload.campaign_uuid
        })
            .then((response) => response)
            .catch((error) => thunkApi.rejectWithValue(error));
    }
);
// (1) country sales data -- end

// (2) city sales data -- start
export const getCitySalesData = createAsyncThunk(
    "reporting/getCitySalesData",
    async (payload, thunkApi) => {
        return reportingApi.getCitySalesData({
            length: payload.length,
            start: payload.start,
            search: payload.search,
            orderBy: payload.orderBy,
            orderDirection: payload.orderDirection,
            search_by: payload.searchBy,
            country : payload.countryCode,
            city_id: payload.city_uuid,
            branch_uuid: payload.branch_uuid,
            category_uuid: payload.category_uuid,
            brand_uuid: payload.brand_uuid,
            model_uuid: payload.model_uuid,
            product_uuid: payload.product_uuid,
            period: payload.period,
            start_date: moment(payload.start_date).format('YYYY-MM-DD'),
            end_date: moment(payload.end_date).format('YYYY-MM-DD'),
            url : payload.url,
            campaign_type: payload.campaign_type,
            campaign_uuid: payload.campaign_uuid
        })
            .then((response) => response)
            .catch((error) => thunkApi.rejectWithValue(error));
    }
);
// (2) city sales data -- end

// (3) age data -- start
export const getDemographicAgeData = createAsyncThunk(
    "reporting/getDemographicAgeData",
    async (payload, thunkApi) => {
        return reportingApi.getDemographicAgeData({
            country : payload.countryCode,
            city_id: payload.city_uuid,
            branch_uuid: payload.branch_uuid,
            category_uuid: payload.category_uuid,
            model_uuid: payload.model_uuid,
            brand_uuid: payload.brand_uuid,
            product_uuid: payload.product_uuid,
            period: payload.period,
            start_date: moment(payload.start_date).format('YYYY-MM-DD'),
            end_date: moment(payload.end_date).format('YYYY-MM-DD'),
            url: payload.url,
            campaign_type: payload.campaign_type,
            campaign_uuid: payload.campaign_uuid
        })
            .then((response) => response)
            .catch((error) => thunkApi.rejectWithValue(error));
    }
);
// (3) age data -- end

// (4) gender data -- start
export const getDemographicGenderData = createAsyncThunk(
    "reporting/getDemographicGenderData",
    async (payload, thunkApi) => {
        return reportingApi.getDemographicGenderData({
            country : payload.countryCode,
            city_id: payload.city_uuid,
            branch_uuid: payload.branch_uuid,
            category_uuid: payload.category_uuid,
            model_uuid: payload.model_uuid,
            brand_uuid: payload.brand_uuid,
            product_uuid: payload.product_uuid,
            period: payload.period,
            start_date: moment(payload.start_date).format('YYYY-MM-DD'),
            end_date: moment(payload.end_date).format('YYYY-MM-DD'),
            url: payload.url,
            campaign_type: payload.campaign_type,
            campaign_uuid: payload.campaign_uuid
        })
            .then((response) => response)
            .catch((error) => thunkApi.rejectWithValue(error));
    }
);
// (4) gender data -- end

// (5) age-gender data -- start
export const getDemographicAgeGenderData = createAsyncThunk(
    "reporting/getDemographicAgeGenderData",
    async (payload, thunkApi) => {
        return reportingApi.getDemographicAgeGenderData({
            country : payload.countryCode,
            city_id: payload.city_uuid,
            branch_uuid: payload.branch_uuid,
            category_uuid: payload.category_uuid,
            model_uuid: payload.model_uuid,
            brand_uuid: payload.brand_uuid,
            product_uuid: payload.product_uuid,
            period: payload.period,
            start_date: moment(payload.start_date).format('YYYY-MM-DD'),
            end_date: moment(payload.end_date).format('YYYY-MM-DD'),
            url: payload.url,
            campaign_type: payload.campaign_type,
            campaign_uuid: payload.campaign_uuid
        })
            .then((response) => response)
            .catch((error) => thunkApi.rejectWithValue(error));
    }
);
// (5) age-gender data -- end
