import React from "react";
import {
  makeStyles,
  Box,
  IconButton,
  Dialog,
  Typography,
  Backdrop,
  Button,
  Paper,
} from "@material-ui/core";
import {
  CloseRounded as CloseRoundedIcon,
  Check as CheckIcon,
  Edit as EditIcon,
} from "@material-ui/icons";
import { getLang } from "app/feature/constants";
import "react-image-lightbox/style.css";
import InfiniteScroll from "react-infinite-scroll-component";
import LoadingComponent from "components/loading";
import RefreshTable from "components/table/refreshTable";
import { AddIcon } from "components/icon";

const useStyle = makeStyles((theme) => ({
  header: {
    padding: "12px 16px",
    borderBottom: "1px solid #D0D5DD",
  },
  container: {
    backgroundColor: "#ebedeec2",
    overflowY: "auto",
    padding: "16px",
  },
  list: {
    maxHeight: "70vh",
    overflowY: "auto",
  },
}));

export default function RequestTransferAddressDialogComponent({
  isOpen,
  handleClose,
  lang,
  list,
  isFetching,
  isError,
  handleReload,
  handleFetchMoreItems,
  selectAddress,
  totalFiltered,
  updateReceiver,
  selectedAddress,
  setShowAddDialog,
  setSelectedEditAddr,
}) {
  const classes = useStyle();

  return (
    <Dialog
      maxWidth="sm"
      open={isOpen}
      onClose={() => {}}
      aria-labelledby="modal-scan-detail"
      aria-describedby="modal-scan-detail"
      BackdropComponent={Backdrop}
      fullWidth
      PaperProps={{
        style: {
          overflowY: "hidden",
        },
      }}
    >
      <Box
        className={classes.header}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="body1">
          <b>{getLang(lang, "label.CHANGE_ADDRESS")}</b>
        </Typography>
        <IconButton
          onClick={handleClose}
          edge="end"
          size="small"
          aria-label="close dialog"
          // disabled={isLoading}
        >
          <CloseRoundedIcon color="primary" />
        </IconButton>
      </Box>
      <Box className={classes.container}>
        {isError ? (
          <RefreshTable handleReload={handleReload} />
        ) : (
          <InfiniteScroll
            height="100%"
            dataLength={list.length}
            className={classes.list}
            next={handleFetchMoreItems}
            hasMore={totalFiltered > list.length}
            loader={<LoadingComponent height={100} />}
            endMessage={
              !list.length ? (
                isFetching ? (
                  <LoadingComponent height={100} />
                ) : (
                  <Box px={1.5} py={4}>
                    <Typography variant="body2" style={{ textAlign: "center" }}>
                      <b>{getLang(lang, "paragraph.NO_ADDRESS_AVAILABLE")}</b>
                    </Typography>
                  </Box>
                )
              ) : null
            }
          >
            {!!list.length && (
              <Box>
                {list.map((addr, index) => {
                  return (
                    <Box display="flex" alignItems="center">
                      <Paper
                        key={index}
                        onClick={() => selectAddress(addr)}
                        style={{ marginBottom: 16, flex: 1 }}
                      >
                        <Box
                          p={1}
                          display="flex"
                          justifyContent="space-between"
                        >
                          <Box>
                            {!!addr.is_default && (
                              <Typography
                                variant="caption"
                                style={{ color: "#4AA94A" }}
                              >
                                {getLang(lang, "label.DEFAULT")}
                              </Typography>
                            )}
                            <Box>
                              <Typography
                                variant="body2"
                                display="inline"
                                style={{ marginRight: 8 }}
                              >
                                <b>{addr.branch_name}</b>
                              </Typography>
                              <Typography
                                variant="caption"
                                display="inline"
                                color="textSecondary"
                              >
                                {addr.phone_code} {addr.phone_number}
                              </Typography>
                            </Box>
                            <Typography variant="body2">
                              {addr.full_address}
                            </Typography>
                          </Box>
                          {!!selectedAddress &&
                            selectedAddress.uuid === addr.uuid && (
                              <Box pl={1}>
                                <CheckIcon style={{ color: "#4AA94A" }} />
                              </Box>
                            )}
                        </Box>
                      </Paper>
                      <Box pl={1}>
                        <EditIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setSelectedEditAddr(addr);
                          }}
                        />
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            )}
          </InfiniteScroll>
        )}
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={2}
      >
        <Box>
          <Button
            variant="text"
            color="primary"
            startIcon={
              <AddIcon color="secondary" style={{ fontSize: "13px" }} />
            }
            disableElevation
            onClick={() => {
              setShowAddDialog(true);
            }}
          >
            {getLang(lang, "label.ADD_ADDRESS")}
          </Button>
        </Box>
        <Box display="flex" alignItems="center">
          <Button
            variant="outlined"
            color="primary"
            style={{
              marginRight: 10,
            }}
            onClick={() => handleClose()}
          >
            {getLang(lang, "label.CANCEL")}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              updateReceiver(selectedAddress);
              handleClose();
            }}
          >
            {getLang(lang, "label.CHANGE")}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}
