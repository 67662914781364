import React from "react";
import {
  Box,
  makeStyles,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Paper,
  TableSortLabel,
  Link,
} from "@material-ui/core";
import {
  StyledTableCell,
  StyledTableRow,
  StickyRightTableCell,
} from "components/table";
import { SkeletonTableRowStickyLeftRight } from "components/skeleton/skeleton.component";
import { ActionButton } from "components/button";
import { TablePaginationActions } from "components/tablePagination";
import RefreshTable from "components/table/refreshTable";
import moment from "moment";
import { KeyboardArrowDown } from "@material-ui/icons";
import DefaultImg from "assets/img/img-default.png";
import { getLang } from "app/feature/constants";
import { TIER_ICON_PROPS } from "modules/stockflow/constants";
import "moment/min/locales.min";
import BranchIcon from "../../../../../components/icon/branchIcon";

const useStyle = makeStyles((theme) => ({
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    borderCollapse: "separate",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
    },
    "& th,td": {
      fontSize: ".75rem",
      borderRight: "1px solid rgba(58, 77, 84, 0.1)",
      padding: theme.spacing(1, 2, 1, 2),
    },
    "& th": {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightBold,
    },
    "& th:last-child, td:last-child": {
      width: 37,
      borderRight: "0px",
    },
    "& th th": {
      borderBottom: "none !important",
    },
    "& td td": {
      borderBottom: "none !important",
    },
  },
  emptyContainer: {
    backgroundColor: theme.palette.background.paper,
    textAlign: "center",
  },
  profile: {
    height: 26,
    width: 26,
    minWidth: 26,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& img": {
      height: "100%",
      minHeight: "100%",
      minWidth: "100%",
      objectFit: "cover",
      borderRadius: "50%",
      border: "1px solid #D0D5DD",
    },
  },
  tierIcon: {
    borderRadius: "3px",
    paddingLeft: "0.15em",
    paddingRight: "0.15em",
    display: "flex",
    alignItems: "center",
    position: "absolute",
    top: "-3px",
    left: "-8px",
  },
  tierLabel: {
    color: "#FFFFFF",
    fontSize: "0.6rem",
  },
  ellipsisText: {
    maxWidth: "18em",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: "0.75rem",
  },
  productPhoto: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #E4E7EC",
    width: "30px",
    height: "30px",
    marginRight: "10px",
    "& img": {
      height: "30px",
      width: "30px",
    },
  },
  attributeText: {
    color: "#b2b2b2",
    fontSize: 12,
    whiteSpace: "break-spaces",
  },
  name: {
    whiteSpace: "normal",
    fontSize: 12,
  },
}));

export default function CheckoutListTableComponentComponent({
  rows,
  isFetching,
  isError,
  handleReload,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowPerPage,
  isSearchResult,
  totalFiltered,
  paginationOptions,
  tableFilter,
  changeOrder,
  lang,
  handleViewDetail,
  url,
  language,
}) {
  const classes = useStyle();

  if(language==="ZH")
  {
    moment.locale('zh-cn')
  }else if(language==="TL")
  {
    moment.locale('tl-ph')
  }else
  {
    moment.locale(language)
  }
  
  return (
    <>
      {!isFetching && isError && <RefreshTable handleReload={handleReload} />}
      {isFetching || rows.length > 0 ? (
        <Paper id="test" style={{ overflowX: "auto", width: "100%" }}>
          <TableContainer>
            <Table
              className={classes.table}
              aria-label="checkout table"
              size="small"
              stickyHeader
            >
              <TableHead style={{ height: "45px" }}>
                <TableRow>
                  <StyledTableCell>
                    {getLang(lang, "label.REFERENCE_CODE")}
                  </StyledTableCell>
                  <StyledTableCell>
                    <TableSortLabel
                      active={tableFilter.orderBy === "created_at"}
                      direction={tableFilter.order}
                      onClick={() => {
                        const differentTab =
                          tableFilter.orderBy !== "created_at";
                        const order = differentTab
                          ? tableFilter.order
                          : tableFilter.order === "asc"
                          ? "desc"
                          : "asc";
                        changeOrder("created_at", order);
                      }}
                      IconComponent={KeyboardArrowDown}
                    >
                      {getLang(lang, "label.DATE")}
                    </TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.BRANCH")}
                  </StyledTableCell>
                  <StyledTableCell>
                    <TableSortLabel
                      active={tableFilter.orderBy === "checkout_by"}
                      direction={tableFilter.order}
                      onClick={() => {
                        const differentTab =
                          tableFilter.orderBy !== "created_at";
                        const order = differentTab
                          ? tableFilter.order
                          : tableFilter.order === "asc"
                          ? "desc"
                          : "asc";
                        changeOrder("created_at", order);
                      }}
                      IconComponent={KeyboardArrowDown}
                    >
                      {getLang(lang, "label.CHECKOUT_BY")}
                    </TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.PRODUCTS")}
                  </StyledTableCell>
                  <StickyRightTableCell
                    style={{ width: "67px", textAlign: "center" }}
                  >
                    {getLang(lang, "label.ACTION")}
                  </StickyRightTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isFetching
                  ? [...new Array(5)].map((v, index) => (
                      <SkeletonTableRowStickyLeftRight
                        key={index}
                        columnCount={6}
                      />
                    ))
                  : rows.map((row, index) => {
                      const actionItems = [];

                      actionItems.push({
                        label: getLang(lang, "label.VIEW_DETAILS"),
                        action: () => {
                          handleViewDetail(`${url}/${row.uuid}`);
                        },
                      });

                      return (
                        <StyledTableRow
                          key={row.id}
                          hover
                          role="checkbox"
                          tabIndex={-1}
                        >
                          <StyledTableCell>
                            <Link
                              href={`${url}/${row.uuid}`}
                              color="secondary"
                              onClick={(e) => {
                                handleViewDetail(`${url}/${row.uuid}`);
                                e.preventDefault();
                                return false;
                              }}
                            >
                              {row.reference_code}
                            </Link>
                          </StyledTableCell>
                          <StyledTableCell>
                            <span>
                              {moment
                                .utc(row.created_at)
                                .local()
                                .format("lll")}
                            </span>
                          </StyledTableCell>
                          <StyledTableCell>
                            <Box display="flex" alignItems="center">
                              <Box pr={1}>
                                <BranchIcon url={row.node_image_url} imageSize="40px" iconSize={22}/>
                              </Box>
                              <Box>{row.branch}</Box>
                            </Box>

                          </StyledTableCell>
                          <StyledTableCell>
                            {row.checkout_by ? (
                              <Box display="flex" alignItems="center">
                                <Box
                                  className={classes.profile}
                                  mr={1}
                                  position="relative"
                                >
                                  {row.checkout_by.picture === "" ||
                                  row.checkout_by.picture === null ? (
                                    <img
                                      src={DefaultImg}
                                      alt="default profile"
                                    />
                                  ) : (
                                    <img
                                      src={row.checkout_by.picture}
                                      alt={row.checkout_by.name}
                                    />
                                  )}
                                  {!row.checkout_by.is_branch &&
                                    !!row.checkout_by.tier && (
                                      <Box
                                        className={classes.tierIcon}
                                        style={{
                                          backgroundColor: TIER_ICON_PROPS.find(
                                            ({ tier }) =>
                                              tier === row.checkout_by.tier
                                          ).color,
                                        }}
                                      >
                                        <Typography
                                          className={classes.tierLabel}
                                        >
                                          T{row.checkout_by.tier}
                                        </Typography>
                                      </Box>
                                    )}
                                </Box>
                                <Typography className={classes.ellipsisText}>
                                  {row.checkout_by.name}
                                </Typography>
                              </Box>
                            ) : (
                              <span>-</span>
                            )}
                          </StyledTableCell>
                          <StyledTableCell>
                            {row.products.map((product, idx) => (
                              <Box key={product.uuid} mt={idx ? 1 : 0}>
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="space-between"
                                >
                                  <Box display="flex" alignItems="center">
                                    <Box className={classes.productPhoto}>
                                      {product.picture === "" ? (
                                        <img
                                          src={DefaultImg}
                                          alt="emptyProduct"
                                        />
                                      ) : (
                                        <img
                                          src={product.picture}
                                          alt={product.name}
                                        />
                                      )}
                                    </Box>
                                    <Box
                                      display="flex"
                                      flexDirection="column"
                                      justifyContent="center"
                                      alignItems="flex-start"
                                      ml={1}
                                      flex={1}
                                    >
                                      <Typography
                                        className={classes.name}
                                        variant="body2"
                                      >
                                        {product.name}
                                      </Typography>
                                      {!!product.attributes &&
                                        !!product.attributes.length && (
                                          <Typography
                                            className={classes.attributeText}
                                            variant="body2"
                                          >
                                            {product.attributes.join(", ")}
                                          </Typography>
                                        )}
                                    </Box>
                                  </Box>
                                  <Box ml={1}>
                                    <Typography
                                      className={classes.name}
                                      variant="body2"
                                    >
                                      {product.quantity.toLocaleString("en-US")}
                                    </Typography>
                                  </Box>
                                </Box>
                                {!!product.serial_numbers?.length && (
                                  <Box
                                    mt={1}
                                    p={1}
                                    display="flex"
                                    flexDirection="column"
                                    style={{ backgroundColor: "#00000008" }}
                                  >
                                    {product.serial_numbers.map((sn, snIdx) => (
                                      <Typography key={snIdx} variant="caption">
                                        {sn}
                                      </Typography>
                                    ))}
                                  </Box>
                                )}
                              </Box>
                            ))}
                          </StyledTableCell>
                          <StickyRightTableCell style={{ width: "67px" }}>
                            <Box display="flex" justifyContent="center">
                              <ActionButton
                                actionItems={actionItems}
                                popperStyle={{ zIndex: 999 }}
                              />
                            </Box>
                          </StickyRightTableCell>
                        </StyledTableRow>
                      );
                    })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            style={{
              backgroundColor: "#fff",
              boxShadow: "0px -1px 0px rgba(58, 77, 84, 0.15)",
            }}
            ActionsComponent={TablePaginationActions}
            rowsPerPageOptions={paginationOptions}
            component="div"
            count={totalFiltered}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowPerPage}
            labelRowsPerPage={getLang(lang, "label.ROWS_PER_PAGE")}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} ${getLang(lang, "label.OF")} ${count}`
            }
          />
        </Paper>
      ) : null}
      {!isFetching && !isError && rows.length === 0 && (
        <Box>
          <TableContainer>
            <Table
              className={classes.table}
              aria-label="checkout table"
              size="small"
              stickyHeader
            >
              <TableHead style={{ height: "45px" }}>
                <TableRow>
                  <StyledTableCell>
                    {getLang(lang, "label.REFERENCE_CODE")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.DATE")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.BRANCH")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.CHECKOUT_BY")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.PRODUCTS")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.ACTION")}
                  </StyledTableCell>
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>
          <Box py={9} className={classes.emptyContainer}>
            <Typography
              variant="body1"
              style={{ paddingBottom: "16px", fontWeight: "bold" }}
            >
              {isSearchResult
                ? getLang(lang, "paragraph.NO_CHECKOUT_EXISTS")
                : getLang(lang, "paragraph.YOU_DONT_HAVE_CHECKOUT")}
            </Typography>
            {!isSearchResult && (
              <Typography variant="body2" style={{ paddingBottom: "16px" }}>
                {getLang(lang, "paragraph.CHECKOUT_INSTRUCTION")}
              </Typography>
            )}
          </Box>
        </Box>
      )}
    </>
  );
}
