import React, { useRef, useState, useEffect } from "react";
import SearchableSelectComponent from "./searchableSelect.component";
import SearchableSelectPanelComponent from "./searchableSelectPanel.component";

function SearchableSelectContainer({
  placeholder,
  handleChange,
  value,
  disabled,
  dropdownItem,
  isLoading,
  emptyMessage,
  searchable,
  style,
  handleItemSearch,
  showImg
}) {
  const [open, setOpen] = useState(false);
  const [isOpenList] = useState(false);
  const anchorRef = useRef();
  const prevOpen = useRef(open);
  const prevOpenList = useRef(isOpenList);

  const handleClose = () => {
    setOpen(false);
    if (searchable) {
      handleItemSearch("");
    }
  }

  const handleClick = () => {
    setOpen(true);
  }

  useEffect(() => {
    if (prevOpenList.current === true && isOpenList === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = isOpenList;
  }, [isOpenList])

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open])

  function handleListKeyDown(field) {
    handleChange(field)
    handleClose()
  }

  return (
    <>
      <SearchableSelectComponent
        anchorRef={anchorRef}
        handleClick={handleClick}
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        style={style}
      />
      <SearchableSelectPanelComponent
        anchorRef={anchorRef}
        open={open}
        isOpenList={isOpenList}
        handleClose={handleClose}
        isFetching={isLoading}
        handleListKeyDown={handleListKeyDown}
        items={dropdownItem}
        handleSearch={handleItemSearch}
        emptyMessage={emptyMessage}
        searchable={searchable}
        showImg={showImg}
      />
    </>
  );
}

export default SearchableSelectContainer