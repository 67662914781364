import React from "react"
import SelectedQuestionComponent from './selectedQuestionCard.component'
import { useSelector } from "react-redux";

export default function SelectedQuestionCardContainer({
  selectedQuestion,
  isShowAnswer,
  handleShowAnswer,
  isQuestionFetching,
  totalResponse,
  totalAnswer,
  totalSkip,
  isAnswerFetching
}) {
  const lang = useSelector(state => state.constant.languages);
  
  return (
    <SelectedQuestionComponent
      selectedQuestion={selectedQuestion} 
      isShowAnswer={isShowAnswer}
      handleShowAnswer={handleShowAnswer}
      isQuestionFetching={isQuestionFetching}
      totalResponse = {totalResponse}
      totalAnswer = {totalAnswer}
      totalSkip = {totalSkip}
      isAnswerFetching={isAnswerFetching}
      lang={lang}

    />
  )
}