import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import BulkCreatePanelComponent from './bulkCreatePanel.component'
import { fetchMeAndBranchDropdown } from "modules/product-mgmt/redux/action/products.action";

function BulkEditListPanelContainer({
  handleAddProducts,
}) {
  const lang = useSelector(state => state.constant.languages)
  const currencyDropdown = useSelector(state => state.productPage.currencyDropdown)
  const languages = useSelector(state => state.constant.languages.data)
  const [totalGenerated, setTotalGenerated] = useState(0);
  const generatedProductsRef = useRef([])
  const dispatch = useDispatch()
  const [branchSearch, setBranchSearch] = useState("")
  
  useEffect(() => {
    dispatch(fetchMeAndBranchDropdown({ search: branchSearch }));
  }, [dispatch, branchSearch]);

  const handleBranchSearch = (value) => {
    setBranchSearch(value)
  }

  const calculateCombination = (values) => {
    let arg = []

    let bm = []
    values.brandModel.forEach((brand) => {
      if (brand.models.length) {
        brand.models.forEach((model) => {
          bm.push({
            name: "brandModel",
            brand: { id: brand.id, name: brand.name },
            model: model
          })
        })
      } else {
        bm.push({
          name: "brandModel",
          brand: { id: brand.id, name: brand.name },
          model: null
        })
      }
    })
    if (bm.length) {
      arg.push(bm)
    }

    let attrCombination = []
    let attrs = values.attributes.map(attribute => {
      return attribute.options.map((op) => ({
          id: attribute.id,
          name: attribute.name,
          option: op,
      }))
    })
    let maxLength = attrs.length-1
    helper([], 0, attrCombination, attrs, maxLength);
    if (attrCombination.length) {
      arg.push(attrCombination.map((c) => ({ name: "attributes", value: c })))
    }

    if (values.category) {
      arg.push([{
        name: "category",
        value: values.category
      }])
    }
    
    if (values.currencyCode.length) {
      arg.push(values.currencyCode.map(c => ({ name: "currencyCode", value: c })))
    } else {
      if (currencyDropdown.length) {
        const currency = currencyDropdown.find(c => !!c.isDefault)
        if (currency) {
          arg.push([{ name: "currencyCode", value: currency }])
        } else {
          arg.push([{ name: "currencyCode", value: currencyDropdown[0] }])
        }
      }
    }

    if (values.branch.length) {
      arg.push([{
        name: "branch",
        value: values.branch
      }])
    }

    if (values.incentivePoint !== "") {
      arg.push([{
        name: "incentivePoint",
        value: values.incentivePoint
      }])
    }

    if (values.scanLimit !== "") {
      arg.push([{
        name: "scanLimit",
        value: values.scanLimit
      }])
    }
  
    let r = []
    let max = arg.length-1
    helper([], 0, r, arg, max);

    generatedProductsRef.current = r
    if (totalGenerated !== r.length) {
      setTotalGenerated(r.length)
    }
  }

  const helper = (arr, i, r, arg, max) => {
    for (var j = 0; j < (arg[i] || []).length; j++) {
        var a = arr.slice(0); 
        a.push(arg[i][j])
        if (i === max) {
            r.push(a);
        } else
            helper(a, i+1, r, arg, max);
    }
  }

  const addProducts = (values, setSubmitting) => {
    handleAddProducts(generatedProductsRef.current, values, setSubmitting)
  }

  return (
    <>
      <BulkCreatePanelComponent
        totalRecords={totalGenerated}
        languages={languages}
        handleAddProducts={addProducts}
        calculateCombination={calculateCombination}
        handleBranchSearch={handleBranchSearch}
        lang={lang}
      />
    </>
  )
}

export default BulkEditListPanelContainer