import { unwrapResult } from "@reduxjs/toolkit";
import { getLang } from "app/feature/constants";
import { addAlert, selectLoading } from "modules/notification";
import { getAdminBranchDropdown, getBranchDropdownSpecial, getDealerDropdown, resetBranchDropdownData, resetDealerListDropdownData } from "modules/stockflow/redux";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InviteDealerDialogComponent from "./inviteDealerDialog.component";

export default function InviteDealerDialogContainer({
  isOpen,
  handleClose
}) {
  const dispatch = useDispatch();

  const lang = useSelector(state => state.constant.languages);
  const branchDropdown = useSelector(state => state.stockflowDealer.dropdowns.branchDropdownData);
  const isBranchFetching = useSelector((state) => selectLoading(state, getBranchDropdownSpecial.typePrefix));
  const isAdminBranchFetching = useSelector((state) => selectLoading(state, getAdminBranchDropdown.typePrefix));

  const dealerDropdown = useSelector(state => state.stockflowDealer.dropdowns.dealerListDropdownData);
  const isDealerDropdownFetching = useSelector((state) => selectLoading(state, getDealerDropdown.typePrefix));

  const isHqAdmin = useSelector(state => state.profile.isHqAdmin)

  const [selectedBranch, setSelectedBranch] = useState(null);
  const [selectedTierType, setSelectedTierType] = useState("noupline");
  const [selectedDealer, setSelectedDealer] = useState(null);
  const [inviteUrl, setInviteUrl] = useState("");


  useEffect(() => {
    if (isHqAdmin) {
      dispatch(getAdminBranchDropdown())
        .then(unwrapResult)
    }
    else {
      dispatch(getBranchDropdownSpecial())
        .then(unwrapResult)
    }
    return () => {
      dispatch(resetBranchDropdownData())
      dispatch(resetDealerListDropdownData())
    }
  }, [isHqAdmin, dispatch])

  const handleSelectBranch = (value) => {
    setSelectedBranch(value)
    dispatch(getDealerDropdown(value.nodeId))
    if (selectedTierType === "noupline") {
      setInviteUrl(`${window.location.origin}/trackco/${value.invitationCode}/signup`)
    }
    else {
      setInviteUrl("")
      setSelectedDealer(null)
    }
  }

  const handleChangeTierType = (event) => {
    setSelectedTierType(event.target.value)
    setSelectedDealer(null)
    if(event.target.value === "noupline"){
      if (selectedBranch !== null) {
        setInviteUrl(`${window.location.origin}/trackco/${selectedBranch.invitationCode}/signup`)
      }
    }
    else {
      setInviteUrl("")
    }
  }

  const handleSelectDealer = (value) => {
    setSelectedDealer(value)
    if (selectedTierType !== "noupline") {
      setInviteUrl(`${window.location.origin}/trackco/${value.invitationCode}/signup`)
    }
  }

  const copyToClipboard = () => {
    navigator.clipboard.writeText(inviteUrl)
    dispatch(addAlert({ severity: "success", message: getLang(lang, 'message.success.SUCCESS_COPY_LINK_TO_CLIPBOARD') }))
  }

  const handleDialogClose = () => {
    setSelectedDealer(null)
    setSelectedTierType("noupline")
    setSelectedBranch(null)
    setInviteUrl("")
    dispatch(resetDealerListDropdownData())
    handleClose()
  }


  return (
    <InviteDealerDialogComponent
      isOpen={isOpen}
      handleClose={handleDialogClose}
      isBranchFetching={isBranchFetching || isAdminBranchFetching}
      branchDropdown={branchDropdown}
      handleSelectBranch={handleSelectBranch}
      selectedBranch={selectedBranch}
      selectedTierType={selectedTierType}
      handleChangeTierType={handleChangeTierType}
      isDealerDropdownFetching={isDealerDropdownFetching}
      dealerDropdown={dealerDropdown}
      handleSelectDealer={handleSelectDealer}
      selectedDealer={selectedDealer}
      inviteUrl={inviteUrl}
      copyToClipboard={copyToClipboard}
      lang={lang}
    />
  )
}