import React from "react";
import { CircularProgress } from "@material-ui/core";
import { CenteredLayout } from "../../components/layout";

export default function VerifyInvitationComponent() {
  return (
    <CenteredLayout>
      <CircularProgress color="inherit" />
    </CenteredLayout>
  );
}
