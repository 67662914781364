import React, { useEffect, useState } from "react";
import LastTransactionPanelComponent from "./lastTransactionPanel.component";
import Dialog from "components/dialog/customDialog";
import TransactionHistoryTable from "../../table/transactionHistoryTable";
import { connect, useSelector } from "react-redux";
import { selectLoading } from "modules/notification";
import { fetchTransactionLatest } from "../../../redux";

function LastTransactionPanelContainer({
  fetchTransactionLatest,
  transactionLatest,
  isFetching
}) {
  const [isModalOpen, setModalOpen] = useState(false);

  const lang = useSelector(state => state.constant.languages)

  useEffect(() => {
    fetchTransactionLatest();
  }, [fetchTransactionLatest]);

  const handleViewAll = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      <LastTransactionPanelComponent
        handleViewAll={handleViewAll}
        transactionLatest={transactionLatest}
        isFetching={isFetching}
        lang={lang}
      />
      <Dialog
        isOpen={isModalOpen}
        handleClose={closeModal}
        disableEnforceFocus={true}
        disableAutoFocus={true}
      >
        <TransactionHistoryTable handleClose={closeModal} />
      </Dialog>
    </>
  );
}

const mapStateToProps = (state) => ({
  transactionLatest: state.billing.transactionLatest,
  isFetching: selectLoading(state, fetchTransactionLatest.typePrefix)
});

const mapDispatchToProps = (dispatch) => ({
  fetchTransactionLatest: () => dispatch(fetchTransactionLatest())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LastTransactionPanelContainer);
