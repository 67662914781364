import React from "react";
import CreateAdtInfoTitleDialogComponent from "./createAdtInfoTitleDialog.component";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { addAlert, selectLoading } from "modules/notification";
import { addAdtInfoTitle } from "modules/serial-number/redux";
import { unwrapResult } from "@reduxjs/toolkit";

function CreateAdtInfoTitleDialogContainer({
  isOpen,
  handleClose,
  handleReload,
}) {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.constant.languages);
  const isSubmitting = useSelector((state) =>
    selectLoading(state, addAdtInfoTitle.typePrefix)
  );

  const handleCreate = async (values) => {
    dispatch(addAdtInfoTitle({ titles: values.titles }))
      .then(unwrapResult)
      .then(() => {
        handleReload();
        handleClose();
      });
  };

  const showAlert = (message) => {
    dispatch(
      addAlert({
        severity: "error",
        message: message,
      })
    );
  };

  return (
    <CreateAdtInfoTitleDialogComponent
      handleCreate={handleCreate}
      isOpen={isOpen}
      handleClose={handleClose}
      lang={lang}
      isSubmitting={isSubmitting}
      addAlert={showAlert}
    />
  );
}

CreateAdtInfoTitleDialogContainer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default CreateAdtInfoTitleDialogContainer;
