import React from "react";
import {
    makeStyles,
    Typography,
    Popper,
    Grow,
    Paper,
    ClickAwayListener,
    Box,
    Divider,
    Button,
    MenuItem,
    MenuList,
    InputAdornment
} from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import PerfectScrollbar from "react-perfect-scrollbar";
import Skeleton from "@material-ui/lab/Skeleton";
import { getLang } from "app/feature/constants";
import InputTextField from 'components/input/inputTextField';
import SearchIcon from "@material-ui/icons/Search";
import { useRef } from "react";

const useStyle = makeStyles((theme) => ({
    paper: {
        width: 435
    },
    button: {
        padding: 0,
        minWidth: 0
    },
    buttonAddCategory: {
        display: "flex",
        padding: 0,
        minWidth: 0
    },
    createLink: {
        padding: theme.spacing(2)
    }
}));

export default function CategoryPanelComponent({
    open,
    anchorRef,
    handleClose,
    handleListKeyDown,
    isOpenList,
    handleAddCategory,
    categoryDropdown,
    isFetching,
    handleSearch,
    searchable,
    lang
}) {
    const classes = useStyle();
    const textRef = useRef();

    return (
        <Popper
            open={open}
            style={{ zIndex: 99 }}
            anchorEl={anchorRef.current}
            category={undefined}
            transition
            disablePortal
            placement="bottom-start"
        >
            {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{
                        transformOrigin:
                            placement === "bottom-start" ? "center top" : "center bottom",
                    }}
                >
                    <Paper className={classes.paper}>
                        <ClickAwayListener onClickAway={handleClose}>
                            <PerfectScrollbar>
                                <Box>
                                    {categoryDropdown.length > 0
                                        ? (
                                            <Box>
                                                {isFetching ? (
                                                    <>
                                                        <Skeleton variant="text" animation="wave" height={"20%"} />
                                                        <Skeleton variant="text" animation="wave" height={"20%"} />
                                                        <Skeleton variant="text" animation="wave" height={"20%"} />
                                                        <Skeleton variant="text" animation="wave" height={"20%"} />
                                                        <Skeleton variant="text" animation="wave" height={"20%"} />
                                                    </>
                                                )
                                                    : <>
                                                        {searchable && (
                                                            <Box>
                                                                <InputTextField
                                                                    variant="filled"
                                                                    size="small"
                                                                    fullWidth
                                                                    inputRef={textRef}
                                                                    InputProps={{
                                                                    endAdornment: (
                                                                        <InputAdornment position='end' style={{ paddingRight: 6 }}>
                                                                        <Button
                                                                            onClick={() => handleSearch(textRef.current.value)}
                                                                            className={classes.button}
                                                                        >
                                                                            <SearchIcon />
                                                                        </Button>
                                                                        </InputAdornment>
                                                                    ),
                                                                    style: {
                                                                        backgroundColor: "#fff",
                                                                        paddingRight: 0
                                                                    }
                                                                    }}
                                                                    inputProps={{
                                                                    onKeyDown: (e) => {
                                                                        if (e.key === "Enter") {
                                                                            e.preventDefault();
                                                                            handleSearch(textRef.current.value)
                                                                            return false
                                                                        }
                                                                    },
                                                                    style: {
                                                                        padding: 8
                                                                    }
                                                                    }}
                                                                    placeholder={getLang(lang, "placeholder.SEARCH")}
                                                                />
                                                            </Box>
                                                        )}
                                                        <Divider />
                                                        <Box height="15vh" overflow="auto">
                                                            {categoryDropdown.map((field, index) => {
                                                                return (
                                                                    <Box key={index}>
                                                                        <MenuList
                                                                            autoFocusItem={isOpenList}
                                                                            id="menu-list-grow"
                                                                            onClick={() => handleListKeyDown(field)}
                                                                        >
                                                                            <MenuItem>
                                                                                <Typography variant="inherit" noWrap>
                                                                                    {field.name}
                                                                                </Typography>
                                                                            </MenuItem>
                                                                        </MenuList>
                                                                    </Box>
                                                                );
                                                            })}
                                                        </Box>
                                                    </>
                                                }
                                            </Box>
                                        ) : (
                                            <Box py={6} style={{ textAlign: "center" }}>
                                                <Typography variant='body1'>{getLang(lang,"paragraph.CATEGORY_NOT_FOUND")} </Typography>
                                            </Box>
                                        )}
                                </Box>

                                <>
                                    <Divider />
                                    <Box className={classes.createLink}>
                                        <Button
                                            color="primary"
                                            className={classes.buttonAddCategory}
                                            onClick={handleAddCategory}
                                        >
                                            <Typography
                                                variant="body2"
                                                color="primary"
                                                style={{ fontWeight: "bold", paddingRight: "4px" }}
                                            >
                                                {getLang(lang,"label.ADD_NEW_CATEGORY")}
                                            </Typography>
                                            <ArrowForwardIcon fontSize="small" />
                                        </Button>
                                    </Box>
                                </>
                            </PerfectScrollbar>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    );
}
