import React from "react";
import PropTypes from "prop-types";
import {
  makeStyles,
  Box,
  Typography,
  Divider,
  Button,
  Tabs
} from "@material-ui/core";
import ProductListPanel from '../../components/panel/productListPanel';
import CategoryListPanel from "../../components/panel/categoryListPanel"
import BrandModelListPanel from "../../components/panel/brandModelListPanel"
import AttributesListPanel from "../../components/panel/attributesListPanel"
import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";
import CustomTab from "components/tab";
import { AddIcon } from "components/icon";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(5, 0),
    width: "90%",
    maxWidth: 1000,
    display: "inherit",
    justifyContent: "inherit"
  },
  content: {
    width: "95%",
    maxWidth: 850
  },
  indicator: {
    border: "0.5px solid",
    borderColor: theme.palette.primary.main
  },
  scroll: {
    width: "min-content"
  },
  actionSection: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column"
    }
  },
  activeColor: {
    color: "#6AAF68"
  },
  deactiveColor: {
    color: "#00000042"
  }
}));

export default function ListingComponent({
  hasProductAddAccessRight,
  handleCreate,
  handleGenerateProduct,
  isCategoryEdit,
  handleAdd,
  hasProductCategoryAddAccessRight,
  hasProductAttributeAddAccessRight,
  hasProductBrandModelAddAccessRight,
  handleChange,
  activeIndex,
  tabLabels,
  lang,
  disableCategoryEdit
}) {
  const classes = useStyle();

  function a11yProps(index, label = "label") {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tab-${index}`,
      label: <Typography variant="button" children={label} />,
    };
  }

  function TabPanel({ children, value, index, ...other }) {
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </Typography>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
  };

  return (
    <Box>
      <Box gridArea="top">
        <Box>
          <Box
            mb={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" alignItems="flex-end" fontWeight="bold">
              <Typography variant="h6" color="primary">
                {getLang(lang,"label.PRODUCT")}
              </Typography>
              <Box mx={0.5}>
                <ChevronRightRoundedIcon color="disabled" />
              </Box>
              <Typography variant="h6" color="primary">
                {/* {getLang(lang,"label.PRODUCT_LIST")} */}
                {tabLabels[activeIndex]}
              </Typography>
            </Box>
            <Box className={classes.actionSection}>

              {
                (tabLabels[activeIndex] === getLang(lang, "label.PRODUCT_LIST") && hasProductAddAccessRight)
                  ?
                  <>
                    <Button
                      variant="text"
                      color="primary"
                      startIcon={
                        <AddIcon color="secondary" style={{ fontSize: "13px" }} />
                      }
                      onClick={handleGenerateProduct}
                      style={{
                        marginLeft: "8px"
                      }}
                    >
                      {getLang(lang, "label.BULK_CREATE")}
                    </Button>
                    <Button
                      variant="text"
                      color="primary"
                      startIcon={
                        <AddIcon color="secondary" style={{ fontSize: "13px" }} />
                      }
                      onClick={handleCreate}
                      style={{
                        marginLeft: "8px"
                      }}
                    >
                      {getLang(lang,"label.CREATE")}
                    </Button>
                  </>
                  
                : (tabLabels[activeIndex] === getLang(lang, "label.CATEGORY_LIST") && hasProductCategoryAddAccessRight)
                  ?
                    <Button
                      variant='text'
                      color='primary'
                      startIcon={
                        <AddIcon color="secondary" style={{ fontSize: "13px" }} />
                      }
                      style={{ textTransform: "uppercase" }}
                      onClick={() => {
                        disableCategoryEdit();
                        handleAdd(true);
                      }}
                    >
                      {getLang(lang, "label.CATEGORY")}
                    </Button>
                  : (tabLabels[activeIndex] === getLang(lang, "label.BRAND_MODEL_LIST") && hasProductBrandModelAddAccessRight)
                    ?
                      <Button
                        variant='text'
                        color='primary'
                        startIcon={
                          <AddIcon color="secondary" style={{ fontSize: "13px" }} />
                        }
                        style={{ textTransform: "uppercase" }}
                        onClick={() => handleAdd(true)}
                      >
                        {getLang(lang, "label.BRAND_MODEL")}
                      </Button>
                    : (tabLabels[activeIndex] === getLang(lang, "label.ATTRIBUTE_LIST") && hasProductAttributeAddAccessRight)
                      ?
                        <Button
                          variant='text'
                          color='primary'
                          startIcon={
                            <AddIcon color="secondary" style={{ fontSize: "13px" }} />
                          }
                          style={{ textTransform: "uppercase" }}
                          onClick={() => handleAdd(true)}
                        >
                          {getLang(lang, "label.ATTRIBUTE")}
                        </Button>
                    : null
              }
            </Box>
          </Box>
          <Divider />
        </Box>
      </Box>

      <Box gridArea="main">
        <Tabs
          value={activeIndex}
          onChange={handleChange}
          aria-label="scrollable auto tabs example"
          textColor="primary"
          variant="scrollable"
          indicatorColor="primary"
          classes={{
            indicator: classes.indicator,
            scrollButtons: classes.scroll
          }}
        >
          {tabLabels.map((item, index) => {
            return <CustomTab key={index} {...a11yProps(index, item)} />;
          })}
        </Tabs>
      </Box>

      <Box>
        {activeIndex !== null && tabLabels.map((item, index) => {
          if (item === getLang(lang, "label.PRODUCT_LIST")) {
            return (
              <TabPanel value={activeIndex} index={index} key={index}>
                <Box className={classes.panel}>
                  <ProductListPanel />
                </Box>
              </TabPanel>
            );
          }
          if (item === getLang(lang, "label.CATEGORY_LIST")) {
            return (
              <TabPanel value={activeIndex} index={index} key={index}>
                <Box className={classes.panel}>
                  <CategoryListPanel handleCreateCategory={handleAdd} isCategoryEdit={isCategoryEdit} />
                </Box>
              </TabPanel>
            );
          }
          if (item === getLang(lang, "label.BRAND_MODEL_LIST")) {
            return (
              <TabPanel value={activeIndex} index={index} key={index}>
                <Box className={classes.panel}>
                  <BrandModelListPanel handleCreateBrandModel={handleAdd} />
                </Box>
              </TabPanel>
            );
          }
          if (item === getLang(lang, "label.ATTRIBUTE_LIST")) {
            return (
              <TabPanel value={activeIndex} index={index} key={index}>
                <Box className={classes.panel}>
                  <AttributesListPanel handleCreateAttribute={handleAdd}/>
                </Box>
              </TabPanel>
            );
          }
          return null;
        })}
      </Box>
    </Box>
  );
}
