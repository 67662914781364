import { selectLoading } from "modules/notification";
import { getProductModelDetailData } from "modules/reporting/redux";
import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProductModelDetail from './productModelDetailPanel.component';

export default function ProductModelDetailContainer({
    urlProductModelDetail,
    currentFilter,
    translate
}){
    const dispatch = useDispatch();


    // (1) get product category model data -- start
    const isFetchingProductModelDetailData = useSelector(state => selectLoading(state, getProductModelDetailData.typePrefix));
    const productModelDetailData = useSelector(state => state.reporting.product.productModelDetailData);
    // (1) get product category model data -- end
    
    useEffect(() => {
        dispatch(getProductModelDetailData({
            branch_uuid: currentFilter.branchUuid,
            model_uuid: currentFilter.modelUuid,
            period: currentFilter.period,
            start_date: moment(currentFilter.dateRange[0]).format('YYYY-MM-DD'),
            end_date: moment(currentFilter.dateRange[1]).format('YYYY-MM-DD'),
            url: urlProductModelDetail
        }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentFilter, dispatch])

    return (
        <ProductModelDetail 
            status={{
                isFetchingProductModelDetailData
            }}

            data={{
                productModelDetailData
            }}

            currentFilter={currentFilter}
            translate={translate}
        />
    )
}