import React from "react";
import {
  makeStyles,
  Box,
  Typography,
  IconButton,
  Divider,
  Dialog,
  DialogTitle,
  Button,
  Tooltip,
  CircularProgress,
} from "@material-ui/core";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import HelpIcon from "@material-ui/icons/Help";
import clsx from "clsx";
import { KeyboardBackspace as BackIcon } from "@material-ui/icons";
import { getLang } from "app/feature/constants";
import { useSelector } from "react-redux";

const useStyle = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  header: {
    padding: theme.spacing(1, 3, 0, 4),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  contentPadding: {
    padding: theme.spacing(1.2),
    backgroundColor: theme.palette.background.default,
  },
  contentWrapper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)",
    borderRadius: theme.spacing(0.5),
  },
  modalFooter: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1.5, 3),
    backgroundColor: theme.palette.background.paper,
    borderTop: "1px solid",
    borderTopColor: theme.palette.divider,
  },
  deleteButton: {
    color: theme.palette.error.main,
  },
  helpIcon: {
    cursor: "pointer",
    color: theme.palette.primary.main,
    fontSize: 16,
  },
}));

export default function ModalComponent({
  title,
  isOpen,
  handleClose,
  children,
  helperText,
  maxWidth,
}) {
  const classes = useStyle();

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      //disableBackdropClick
      aria-labelledby="form-dialog-title"
      //fullScreen={isMobile}
      maxWidth={maxWidth}
      fullWidth={!!maxWidth}
    >
      <DialogTitle disableTypography id="form-dialog-title">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center">
            <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
              {title}&nbsp;
            </Typography>
            {helperText && (
              <Tooltip title={helperText} arrow>
                <HelpIcon className={classes.helpIcon} />
              </Tooltip>
            )}
          </Box>
          <IconButton
            onClick={handleClose}
            edge="end"
            size="small"
            aria-label="close dialog"
          >
            <CloseRoundedIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider />
      <Box width={!maxWidth ? 500 : "auto"}>{children}</Box>
    </Dialog>
  );
}

export function ContentWrapper({
  children,
  disablePadding = false,
  resetPaper = false,
}) {
  const classes = useStyle();
  return (
    <Box className={classes.contentPadding}>
      <Box
        className={clsx({
          [classes.contentWrapper]: !resetPaper,
        })}
        p={disablePadding ? 0 : 2}
      >
        {children}
      </Box>
    </Box>
  );
}

export function ModalFooter({
  showDelete,
  showBack,
  handleBack,
  handleClick,
  handleDelete,
  handleClose,
  handleDisable,
  type,
  disableProceed = false,
  submitLabel = "SAVE",
  isLoading,
}) {
  const lang = useSelector((state) => state.constant.languages);
  const classes = useStyle();
  return (
    <Box
      className={classes.modalFooter}
      justifyContent={showDelete || showBack ? "space-between" : "flex-end"}
    >
      {showDelete && (
        <Button
          variant="text"
          classes={{ root: classes.deleteButton }}
          onClick={handleDelete}
          disabled={isLoading}
        >
          {getLang(lang, "label.DELETE")}
        </Button>
      )}
      <Box justifyContent={"flex-end"}>
        <Button
          disableElevation
          type="button"
          style={{ marginRight: "4px" }}
          onClick={handleClose}
          disabled={isLoading}
        >
          {getLang(lang, "label.CANCEL")}
        </Button>
        {showBack && (
          <Button
            variant="outlined"
            onClick={handleBack}
            startIcon={<BackIcon />}
            disabled={isLoading}
          >
            {getLang(lang, "label.BACK")}
          </Button>
        )}
        <Button
          variant="contained"
          color="secondary"
          disableElevation
          type={type}
          disabled={handleDisable || isLoading}
          onClick={handleClick}
        >
          {isLoading ? (
            <CircularProgress size={20} style={{ color: "white" }} />
          ) : (
            getLang(lang, "label.SAVE")
          )}
        </Button>
      </Box>
    </Box>
  );
}
