import { createAsyncThunk } from "@reduxjs/toolkit";
import constantsApi from "app/api/constants";

export const getIndustries = createAsyncThunk(
    "constants/getIndustries",
    async () => {
        const response = await constantsApi.getIndustries()

        return response
    }
)

export const getCountry = createAsyncThunk(
    "constants/getCountry",
    async () => {
        const response = await constantsApi.getCountry()

        return response
    }
)

export const getLanguages = createAsyncThunk(
    "constants/getLanguages",
    async (payload) => {
        const response = await constantsApi.getLanguages(payload)

        return response
    }
)

export const getApiLanguages = createAsyncThunk(
    "constants/getApiLanguages",
    async (payload) => {
        const response = await constantsApi.getApiLanguages(payload)

        return response
    }
)

export const getFallbackLanguage = createAsyncThunk(
    "constants/getFallbackLanguage",
    async () => {
        const response = await constantsApi.getFallbackLanguage()

        return response
    }
)

export const getFallbackApiLanguage = createAsyncThunk(
    "constants/getFallbackApiLanguage",
    async () => {
        const response = await constantsApi.getFallbackApiLanguage()

        return response
    }
)