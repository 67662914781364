import React from "react"
import { useSelector } from "react-redux"
import ScanHistoryTableComponent from "./scanHistoryTable.component"

export default function ScanHistoryTableContainer({
  rows,
  isFetching,
  isError,
  page,
  tableFilter,
  rowsPerPage,
  totalFiltered,
  handleChangePage,
  handleChangeRowsPerPage,
  handleReload,
  paginationOptions,
  changeOrder,
  handleViewDetail
}) {
  const lang = useSelector(state => state.constant.languages)
  const language = useSelector(state => state.profile.language)

  return (
    <ScanHistoryTableComponent
      rows={rows}
      isFetching={isFetching}
      isError={isError}
      handleReload={handleReload}
      page={page}
      rowsPerPage={rowsPerPage}
      language={language}
      totalFiltered={totalFiltered}
      paginationOptions={paginationOptions}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      tableFilter={tableFilter}
      changeOrder={changeOrder}
      lang={lang}
      handleViewDetail={handleViewDetail}
    />
  )
}
