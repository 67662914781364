import React from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

const withRecaptcha = Component => props => {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITEKEY}
      language="en"
    >
      <Component {...props} />
    </GoogleReCaptchaProvider>
  );
};

export default withRecaptcha;
