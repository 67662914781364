import React, { useState, useEffect } from "react";
import ProductSelectFieldComponent from "./productSelectField.component";
import ProductSelectPopoverComponent from './productSelectPopover.component';
import { useSelector } from "react-redux";

function ProductSelectFieldContainer({
  searchable,
  value,
  placeholder,
  searchPlaceholder,
  handleChange,
  disabled,
  isFetching,
  panelStyle,
  dropdownItem,
  anchorOrigin,
  transformOrigin,
  PaperProps,
  style,
  textStyle,
  isEmpty,
  isClearable,
  handleClear,
  emptyMessage
}) {
  
  const lang = useSelector(state => state.constant.languages);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [productList, setProductList] = useState(dropdownItem);

  useEffect(() => {
    setProductList(dropdownItem);
  }, [dropdownItem]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
    handleSearch("");
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSearch = (targetProduct) => {
    if (targetProduct.trim().length === 0) {
      setProductList(dropdownItem);
      return;
    }
    let products = [];
    dropdownItem.forEach(product => {
      let productName = product.name ?? "";
      if (productName.toLowerCase().includes(targetProduct.toLowerCase())) {
        products.push(product);
      }
    });

    setProductList(products);
  }

  const selectedValue = (selectedValue, index) => {
    if (isEmpty) {
      return false
    }
    
    return false 
  }

  return (
    <>
      <ProductSelectFieldComponent
        handleClick={handleClick}
        handleClear={handleClear}
        value={value}
        isEmpty={isEmpty}
        style={style}
        disabled={disabled}
        placeholder={placeholder}
        open={open}
        textStyle={textStyle}
        isClearable={isClearable}
        lang={lang}
      />
      <ProductSelectPopoverComponent
        anchorEl={anchorEl}
        open={open}
        handleClose={handleClose}
        style={panelStyle}
        items={productList}
        handleSearch={handleSearch}
        isFetching={isFetching}
        searchable={searchable}
        handleChange={handleChange}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        PaperProps={PaperProps}
        value={value}
        selectedValue={selectedValue}
        emptyMessage={emptyMessage}
        placeholder={searchPlaceholder}
      />
    </>
  )
}

export default ProductSelectFieldContainer