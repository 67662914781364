import axios from "axios";

export function checkFeatureAvailability(parameters) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/account/api/v1/access-right/check-feature`, {
        access_right: parameters.access_right,
      })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data));
  });
}
