import React, { useCallback } from "react";
import DraggableAdditionalInfoCardComponent from "./draggableAdditionalInfoCard.component";
import { cloneDeep } from "lodash";
import { useState } from "react";

export default function DraggableAdditionalInfoCardContainer({
  index,
  lang,
  infoList,
  info,
  updateInfo,
  isSubmitting,
  userLang,
}) {
  const [showMore, setShowMore] = useState(false);

  const moveCard = useCallback((dragIndex, hoverIndex, infoList) => {
    let temp = cloneDeep(infoList);

    let itemToMove = temp[dragIndex];
    temp.splice(dragIndex, 1);
    temp.splice(hoverIndex, 0, itemToMove);

    updateInfo(temp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleReadMore = () => setShowMore((show) => !show);

  const changeVisibility = (info) => {
    let temp = cloneDeep(infoList);

    temp[index] = {
      ...temp[index],
      is_visible: temp[index].is_visible === 1 ? 0 : 1,
      is_edited: true,
    };

    updateInfo(temp);
  };

  return (
    <DraggableAdditionalInfoCardComponent
      index={index}
      moveCard={moveCard}
      lang={lang}
      info={info}
      infoList={infoList}
      updateInfo={updateInfo}
      isSubmitting={isSubmitting}
      showMore={showMore}
      toggleReadMore={toggleReadMore}
      changeVisibility={changeVisibility}
      userLang={userLang}
    />
  );
}
