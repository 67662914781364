import { createAsyncThunk } from '@reduxjs/toolkit';
import trackcoApi from 'app/api/trackco';
import { TRACKCO } from "modules/trackco/constants";

export const newJoin = createAsyncThunk(
  `${TRACKCO}/newJoin`,
  async (payload, { rejectWithValue }) => {
    return trackcoApi.join({
      type: "new",
      invitationCode: payload.invitationCode,
      email: payload.email,
      password: payload.password,
      confirmPassword: payload.confirmPassword,
      firstName: payload.firstName,
      lastName: payload.lastName,
      gender: payload.gender,
      countryCode: payload.countryCode,
      phoneNumber: payload.phoneNumber,
      addressLine1: payload.addressLine1,
      addressLine2: payload.addressLine2,
      city: payload.city,
      state: payload.state,
      country: payload.country,
      locale: payload.locale,
      postalCode: payload.postalCode,
      dateOfBirth: payload.dateOfBirth,
    })
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const additionalJoin = createAsyncThunk(
  `${TRACKCO}/additionalJoin`,
  async (payload, { rejectWithValue }) => {
    return trackcoApi.join({
      type: "exist",
      invitationCode: payload.invitationCode,
      email: payload.email,
      password: payload.password,
    })
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const verifyUsedEmail = createAsyncThunk(
  `${TRACKCO}/verifyUsedEmail`,
  async (payload, { rejectWithValue }) => {
    return trackcoApi.validateEmail({
      email: payload.email,
    })
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const validateInvitationCode = createAsyncThunk(
  `${TRACKCO}/validateInvitationCode`,
  async (payload, { rejectWithValue }) => {
    return trackcoApi.validateInvitationCode({
      invitationCode: payload
    })
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const validateEmailVertificationToken = createAsyncThunk(
  `${TRACKCO}/validateEmailVertificationToken`,
  async (payload, { rejectWithValue }) => {
    return trackcoApi.validateEmailVerificationToken({
      email: payload.email,
      emailVerificationToken: payload.emailVerificationToken
    })
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)
export const verifyPhoneNumber = createAsyncThunk(
  `${TRACKCO}/verifyPhoneNumber`,
  async (payload, { rejectWithValue }) => {
    return trackcoApi.verifyPhoneNumber({
      phoneCode: payload.phoneCode,
      phoneNumber: payload.phoneNumber,
      invitationCode: payload.invitationCode,
    })
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const validateOTP = createAsyncThunk(
  `${TRACKCO}/validateOTP`,
  async (payload, { rejectWithValue }) => {
    return trackcoApi.validateOTP({
      phoneCode: payload.phoneCode,
      phoneNumber: payload.phoneNumber,
      otp: payload.otp,
    })
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const sendVerificationEmail = createAsyncThunk(
  `${TRACKCO}/sendVerificationEmail`,
  async (payload, { rejectWithValue }) => {
    return trackcoApi.sendVerificationEmail({
      email: payload.email,
    })
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const sendResetPasswordLink = createAsyncThunk(
  `${TRACKCO}/sendResetPassword`,
  async (payload, { rejectWithValue }) => {
    return trackcoApi.sendResetPasswordLink({
      email: payload.email,
    })
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const validateResetPasswordToken = createAsyncThunk(
  `${TRACKCO}/validateResetPasswordToken`,
  async (payload, { rejectWithValue }) => {
    return trackcoApi.validateResetPasswordToken({
      email: payload.email,
      resetPasswordToken: payload.resetPasswordToken
    })
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const resetPassword = createAsyncThunk(
  `${TRACKCO}/resetPassword`,
  async (payload, { rejectWithValue }) => {
    return trackcoApi.resetPassword({
      email: payload.email,
      resetPasswordToken: payload.resetPasswordToken,
      password: payload.password,
      confirmPassword: payload.confirmPassword,
    })
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)


