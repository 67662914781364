import React from "react"
import LooseItemListPanelComponent from "./looseItemListPanel.component"

export default function LooseItemListPanelContainer({
  packageDetail,
  isFetching,
  lang
}) {
  return (
    <LooseItemListPanelComponent
      packageDetail={packageDetail} 
      isFetching={isFetching}
      lang={lang}
    />
  )
}