import React from "react";
import {
  Box,
  Paper,
  Typography,
  makeStyles,
  Divider,
  Button
} from "@material-ui/core";
import DefaultImg from "assets/img/img-default.png";
import clsx from "clsx";
import { Skeleton } from '@material-ui/lab';
import { getLang } from "app/feature/constants";
import moment from "moment/moment";
import 'moment/min/locales.min';

const useStyles = makeStyles((theme) => ({
  container: {
    boxShadow: "0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);",
    borderRadius: 6,
    padding: theme.spacing(3, 2),
  },
  statusContainer: {
    borderRadius: "15px",
    width: 100,
    height: 26,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  success: {
    backgroundColor: '#32D583',
  },
  error: {
    backgroundColor: '#F04438',
  },
  statusWhiteTxt: {
    color: "#fff"
  },
  infoContainer: {
    backgroundColor: "#F9FAFB",
    padding: 12,
    marginTop: theme.spacing(2)
  },
  profileImg: {
    height: 24,
    width: 24,
    borderRadius: "50%"
  }
}));

export default function SurveyInfoCardComponent({
  surveyDetails,
  language,
  isDetailsLoading,
  openEditSurveyModal,
  lang
}) {
  const classes = useStyles();
  return (
    <Paper variant="outlined" className={classes.container}>
      <Box display="flex" justifyContent="flex-end">
        <Button variant="text" onClick={() => openEditSurveyModal()}>
          <Typography variant='button' style={{ textTransform: "capitalize" }} color="secondary">{getLang(lang, 'label.EDIT')}</Typography>
        </Button>
      </Box>
      <Box mb={2}>
        <Typography variant="subtitle1" color='textPrimary' style={{ fontWeight: "500", lineHeight: "20px", textTransform: "capitalize" }}>
          {isDetailsLoading ? (
            <Skeleton variant='text' />
          ): surveyDetails.name}
        </Typography>
      </Box>
      {isDetailsLoading ? (
        <Skeleton variant="rect" height={26}/>
      ): (
        <Box className={clsx(classes.statusContainer, {
          [classes.success]: surveyDetails.status === "published",
          [classes.error]: surveyDetails.status === "unpublished" 
        })} mb={2}>
          <Typography variant="caption" className={classes.statusWhiteTxt} style={{ textTransform: "capitalize" }}>
            {surveyDetails.status}
          </Typography>
        </Box>
      )}
      <Divider />
      <Box className={classes.infoContainer}>
        <Box mb={3}>
          <Box mb={1}>
            <Typography variant="body2" color='textSecondary' style={{ color: "#98A2B3" }}>
              {getLang(lang, 'label.PRODUCT')}
            </Typography>
          </Box>
          {isDetailsLoading ? (
            <Box display="flex" alignItems="center" mb={1.8}>
              <Skeleton variant='circle' width={24} height={24} style = {{ backgroundColor: "#00000026" }}/>
              <Skeleton variant='text' width={100} style = {{ marginLeft: 8, backgroundColor: "#00000026" }}/>
            </Box>
          ) : surveyDetails.productIds.map((product) => (
            <Box display="flex" alignItems="center" mb={1.8} key={product.id}>
              <img src={product.picture ? product.picture : DefaultImg} className={classes.profileImg} alt="respondent"/>
              <Box ml={1}>
                <Typography variant="body2" color='textPrimary' style={{ fontWeight: 400 }}>{product.name}</Typography>
              </Box>
            </Box>
          ))}
        </Box>
        <Box mb={3}>
          <Box mb={1}>
            <Typography variant='body2' color='textSecondary' style={{ color: "#98A2B3" }}>{getLang(lang, 'label.DESCRIPTION')}</Typography>
          </Box>
          <Box>
            <Typography variant='body2' color='textPrimary' style={{ fontWeight: 400, textAlign: "justify" }}>
              {isDetailsLoading ? (
                <Skeleton variant='text' width={100} style = {{ backgroundColor: "#00000026" }}/>
              ): surveyDetails.description } 
            </Typography>
          </Box>
        </Box>
        <Box mb={3}>
          <Box mb={1}>
            <Typography variant='body2' color='textSecondary' style={{ color: "#98A2B3" }}>{getLang(lang, 'label.PERIOD')}</Typography>
          </Box>
          <Box>
            <Typography variant='body2' color='textPrimary' style={{ fontWeight: 400 }}>
              {isDetailsLoading ? (
                <Skeleton variant='text' width={150} style = {{ backgroundColor: "#00000026" }}/>
              ):(
                <span>{moment(surveyDetails.startDate).format('lll')} - {moment(surveyDetails.endDate).format('lll')}</span>
              )}
            </Typography>
          </Box>
        </Box>
        <Box>
          <Box mb={1}>
            <Typography variant='body2' color='textSecondary' style={{ color: "#98A2B3" }}>
              {getLang(lang, 'label.CREATED_DATE')}
            </Typography>
          </Box>
          <Box>
            <Typography variant='body2' color='textPrimary' style={{ fontWeight: 400 }}>
              {isDetailsLoading ? (
                <Skeleton variant='text' width={150} style = {{ backgroundColor: "#00000026" }}/>
              ):(
                <span>{moment(surveyDetails.createdAt).format('lll')}</span>
              )}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Paper>
  )
}