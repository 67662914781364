import React from "react";
import { useSelector } from "react-redux";
import AssignProductTableComponent from "./assignProductTable.component";

export default function AssignProductTableContainer({ tableData }) {
  const productDropdown = useSelector(
    (state) => state.products.productsDropdown
  );
  const lang = useSelector(state => state.constant.languages)

  return (
    <AssignProductTableComponent
      tableData={tableData}
      productDropdown={productDropdown}
      lang={lang}
    />
  );
}
