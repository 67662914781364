import React from "react";
import { useSelector } from "react-redux";
import HeaderComponent from "./header.component";


export default function HeaderContainer()
{
    const lang = useSelector(state => state.constant.languages)
    return <HeaderComponent lang={lang}/>;

}