import React from "react";
import Webcam from "react-webcam";
import { Paper, Button, Box, ButtonGroup, makeStyles } from "@material-ui/core";
import { getLang } from "app/feature/constants";

const style = theme => ({
  paper: {
    display: "grid",
    gridTemplateRows: "1fr 50px",
    columnGap: theme.spacing(1),
    padding: theme.spacing(2),
    borderRadius: 10,
    outline: "none", 
    backgroundColor: theme.palette.primary.main
  },
  loading: {
    height: 640,
    width: 640,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute"
  }
});

const useStyle = makeStyles(style);

export default function WebcamPanelComponent({
  img,
  handleCapture,
  granted,
  captured,
  webcamRef,
  handleReset,
  handleConfirm,
  handleLoading,
  lang
}) {
  const classes = useStyle();

  return (
    <Paper elevation={0} className={classes.paper}>
      {captured ? (
        <React.Fragment>
          <img src={img} alt="" />
          <Box mt={1} m={"auto"}>
            <ButtonGroup
              variant="contained"
              aria-label="outlined primary button group"
            >
              <Button
                color="primary"
                disableElevation
                onClick={handleConfirm}
                mr={3}
              >
                {getLang(lang,"label.CONFIRM")}
              </Button>
              <Button disableElevation onClick={handleReset}>
                {getLang(lang,"label.RESET")}
              </Button>
            </ButtonGroup>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {!granted && (
            <Box className={classes.loading}>
              <small>{getLang(lang,"paragraph.WAITING_FOR_PERMISSION")}</small>
            </Box>
          )}
          <Webcam
            audio={false}
            height={640}
            ref={webcamRef}
            onUserMedia={() => handleLoading(true)}
            screenshotFormat="image/jpeg"
            width={640}
            mirrored={true}
            videoConstraints={{
              width: 720,
              height: 720,
              facingMode: "user"
            }}
          />
          <Box mt={1} m={"auto"}>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              disabled={!granted}
              onClick={handleCapture}
            >
              {getLang(lang,"label.CAPTURE_PHOTO")}
            </Button>
          </Box>
        </React.Fragment>
      )}
    </Paper>
  );
}
