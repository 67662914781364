import {
  fetchOrganisationStructureDetails,
  fetchNewEmployee,
  fetchMoreNewEmployee,
} from "./organisationStructure.action";
import { setLoading, addAlert } from "modules/notification";
import { getApiLang } from "app/feature/constants";

const fetchOrganisationStructureDetailsMiddleware = ({ getState }) => next => action => {
  next(action);
  const { type, payload } = action;
  switch (type) {
    case fetchOrganisationStructureDetails.pending.type:
      next(setLoading({ id: fetchOrganisationStructureDetails.typePrefix, state: true }));
      break;
    case fetchOrganisationStructureDetails.fulfilled.type:
      next(setLoading({ id: fetchOrganisationStructureDetails.typePrefix, state: false }));
      break;
    case fetchOrganisationStructureDetails.rejected.type:
      const lang = getState().constant.languages
      next(setLoading({ id: fetchOrganisationStructureDetails.typePrefix, state: false }));
      next(
        addAlert({ severity: "error", message: getApiLang(lang, payload.code)})
      );
      break;
    default:
      break;
  }
};


const fetchNewEmployeeMiddleware = ({ dispatch, getState }) => (next) => (action) => {
  next(action);

  const { type, payload } = action;
  switch (type) {
    case fetchNewEmployee.pending.type:
      dispatch(
        setLoading({ id: fetchNewEmployee.typePrefix, state: true })
      );
      break;

    case fetchNewEmployee.fulfilled.type:
      dispatch(
        setLoading({ id: fetchNewEmployee.typePrefix, state: false })
      );
      break;

    case fetchNewEmployee.rejected.type:
      const lang = getState().constant.languages
      dispatch(
        setLoading({ id: fetchNewEmployee.typePrefix, state: false })
      );
      dispatch(
        addAlert({
          severity: "error",
          message: getApiLang(lang, payload.code),
        })
      );
      break;

    default:
      break;
  }
};

const fetchMoreNewEmployeeMiddleware = ({ dispatch, getState }) => (next) => (action) => {
  next(action);

  const { type } = action;
  if (type === fetchMoreNewEmployee.type) {
    const w = getState().employee.newEmployeeList;
    const start = w.data.length;
    dispatch(fetchNewEmployee({ start: start }));
  }
};

export const organisationStructureMiddleware = [
  fetchOrganisationStructureDetailsMiddleware,
  fetchNewEmployeeMiddleware,
  fetchMoreNewEmployeeMiddleware,
];
