import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePaginationOptions, updateUrlQueryParam } from "lib/helper";
import {
  getStockTakeList,
  resetStockTakeListing,
} from "modules/stockflow/redux";
import moment from "moment";
import StockTakeListingComponent from "./stockTakeListing.component";
import { useHistory } from "react-router-dom";
import { STOCKFLOW_STOCKTAKE_EDIT } from "lib/constants/accessRights";
import { getLang } from "app/feature/constants";
import GeneralDialog from "components/dialog/generalDialog";
import { selectLoading } from "modules/notification";

function StockTakeListingContainer() {
  const history = useHistory();
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.constant.languages);
  const language = useSelector((state) => state.profile.language);
  const hasStockflowEditAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(STOCKFLOW_STOCKTAKE_EDIT)
  );
  const isFetching = useSelector((state) =>
    selectLoading(state, getStockTakeList.typePrefix)
  );
  const isCancelling = false;

  const totalFiltered = useSelector(
    (state) => state.stockflowStockTake.stockTakeList.totalFiltered
  );
  const totalRecords = useSelector(
    (state) => state.stockflowStockTake.stockTakeList.totalRecords
  );
  const stockTakeList = useSelector(
    (state) => state.stockflowStockTake.stockTakeList.list
  );
  const isError = useSelector(
    (state) => state.stockflowStockTake.stockTakeList.isError
  );
  const isSearchResult = stockTakeList.length < totalRecords;
  const [paginationOptions, setPaginationOptions] = useState([]);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isFilterUpdated, setIsFilterUpdated] = useState(false);
  const [tableFilter, setTableFilter] = useState({
    page: 0,
    rowsPerPage: 25,
    order: "desc",
    orderBy: "created_at",
    fromDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
    toDate: moment().format("YYYY-MM-DD"),
    nodeIds: [],
    branch: [],
    search: "",
    status: "",
  });
  const [selectedRecord, setSelectedRecord] = useState(null);

  const query = new URLSearchParams(window.location.search);
  const tableFilterInitialRef = useRef(true);

  useEffect(() => {
    const isFilter = query.has("is_filter");
    let filter = sessionStorage.getItem("stocktake_filter");

    let temp = { ...tableFilter };
    if (isFilter && filter) {
      temp = JSON.parse(filter);
      setIsFilterUpdated(true);
      setIsFilterOpen(true);
    }

    if (isFilter) {
      updateUrlQueryParam(null, ["is_filter"]);
    }
    sessionStorage.removeItem("stocktake_filter");

    setTableFilter(temp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!tableFilterInitialRef.current) {
      dispatch(resetStockTakeListing());
      handleDataLoad();
    }
    tableFilterInitialRef.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableFilter]);

  useEffect(() => {
    dispatch(resetStockTakeListing());

    return () => {
      dispatch(resetStockTakeListing());
    };
  }, [dispatch]);

  useEffect(() => {
    setPaginationOptions(generatePaginationOptions(totalFiltered));
  }, [totalFiltered]);

  const handleDataLoad = () => {
    let payload = {
      length: tableFilter.rowsPerPage,
      start: tableFilter.rowsPerPage * tableFilter.page,
      order: tableFilter.order,
      order_by: tableFilter.orderBy,
      filter: {
        status: tableFilter.status,
        search: tableFilter.search,
        from_date: tableFilter.fromDate,
        to_date: tableFilter.toDate,
        nodes: tableFilter.nodeIds,
      },
    };
    dispatch(getStockTakeList(payload));
  };

  const handleChangePage = (event, newPage) => {
    let newData = {
      ...tableFilter,
      page: newPage,
    };
    setIsFilterUpdated(true);
    setTableFilter(newData);
  };

  const handleChangeRowsPerPage = (event) => {
    let newData = {
      ...tableFilter,
      page: 0,
      rowsPerPage: event.target.value,
    };
    setIsFilterUpdated(true);
    setTableFilter(newData);
  };

  const handleSearch = (filter) => {
    const { dateRange, branch, search, status } = filter;

    let newData = {
      ...tableFilter,
      page: 0,
      nodeIds: branch.length > 0 ? branch.map((br) => br.node_id) : [],
      branch: branch,
      fromDate: dateRange?.from
        ? moment(dateRange.from).format("YYYY-MM-DD")
        : "",
      toDate: dateRange?.to ? moment(dateRange.to).format("YYYY-MM-DD") : "",
      search: search,
      status: status ? status.value : "",
    };
    setIsFilterUpdated(true);
    setTableFilter(newData);
  };

  const clearSearch = () => {
    let newData = {
      ...tableFilter,
      page: 0,
      fromDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
      toDate: moment().format("YYYY-MM-DD"),
      branch: [],
      nodeIds: [],
      search: "",
      status: "",
    };
    setIsFilterUpdated(false);
    setTableFilter(newData);
  };

  const changeOrder = (orderBy, order) => {
    let newData = {
      ...tableFilter,
      order,
      orderBy,
    };
    setIsFilterUpdated(true);
    setTableFilter(newData);
  };

  const resetFilter = () => {
    let newData = {
      ...tableFilter,
      page: 0,
      search: "",
      status: "",
    };
    setIsFilterUpdated(true);
    setTableFilter(newData);
  };

  const handleViewDetail = (url) => {
    if (isFilterUpdated) {
      updateUrlQueryParam({ is_filter: true });
      sessionStorage.setItem("stocktake_filter", JSON.stringify(tableFilter));
    }
    history.push(url);
  };

  const handleCancel = (stocktake) => {
    setSelectedRecord(stocktake);
  };

  const cancelStocktake = () => {};

  return (
    <>
      <StockTakeListingComponent
        stocktakes={stockTakeList}
        isFetching={isFetching}
        language={language}
        isError={isError}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        page={tableFilter.page}
        rowsPerPage={tableFilter.rowsPerPage}
        totalFiltered={totalFiltered}
        handleSearch={handleSearch}
        clearSearch={clearSearch}
        handleReload={handleDataLoad}
        isSearchResult={isSearchResult}
        paginationOptions={paginationOptions}
        isFilterOpen={isFilterOpen}
        setIsFilterOpen={setIsFilterOpen}
        tableFilter={tableFilter}
        changeOrder={changeOrder}
        lang={lang}
        resetFilter={resetFilter}
        handleViewDetail={handleViewDetail}
        handleCancel={handleCancel}
        hasStockflowEditAccessRight={hasStockflowEditAccessRight}
      />
      <GeneralDialog
        isLoading={isCancelling}
        isOpen={!!selectedRecord}
        handleClose={() => {
          setSelectedRecord(null);
        }}
        handleProceed={cancelStocktake}
        title={getLang(lang, "label.CANCEL_STOCK_TAKE")}
        description={getLang(lang, "paragraph.CANCEL_STOCK_TAKE_QUESTION")}
        type="danger"
        proceedLabel={getLang(lang, "label.CONFIRM")}
      />
    </>
  );
}

export default StockTakeListingContainer;
