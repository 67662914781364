import React from "react";
import { Typography, Box, LinearProgress, makeStyles } from "@material-ui/core";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles((theme) => ({
  progressBar: {
    backgroundColor: "#6AAF68",
  },
  container: {
    backgroundColor: "white",
    borderRadius: 4,
    padding: 12,
    marginBottom: 8,
  },
}));

export default function AdtInfoImportProgressComponent({
  lang,
  pendingProcess,
}) {
  const classes = useStyle();
  const formatNumber = (number) => {
    if (number % 1 !== 0 && number.toString().split(".")[1].length > 2) {
      return parseFloat(number.toFixed(2)); // Fixed to 2 decimal places if it has more than 2 decimals
    }
    return number;
  };

  return (
    pendingProcess && (
      <Box className={classes.container}>
        <Box mb={0.5}>
          <Typography variant="body2">
            {getLang(lang, "message.info.IMPORT_UPADTE_ADT_IN_PROGRESS")}
          </Typography>
        </Box>
        <LinearProgress
          color="secondary"
          variant="determinate"
          value={formatNumber(
            (pendingProcess.total_process / pendingProcess.total_import) * 100
          )}
        />
      </Box>
    )
  );
}
