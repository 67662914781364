import React from "react";
import {
  Backdrop,
  Box,
  Paper,
  Divider,
  makeStyles,
  Typography,
  Grid,
  Button,
  IconButton
} from "@material-ui/core";
import Dialog from "components/dialog/customDialog";
import CloseIcon from "@material-ui/icons/Close";
import { Formik, ErrorMessage } from "formik";
import InputTextField from "components/input/inputTextField";
import { RoleSelect, BranchSelect } from "../../select";
import AddEmployeeTable from "../../table/addEmployeeTable";
import * as Yup from "yup";
import { getLang } from "app/feature/constants";

const style = (theme) => ({
  infoContainer: {
    width: "650px",
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    }
  },
  root: {
    height: "80%"
  },
  wrapper: {
    display: "flex",
    placeContent: "center",
    padding: theme.spacing(1),
    backgroundColor: "#E5E5E5"
  },
  detailsWrapper: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(2, 3, 2, 3),
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
    [theme.breakpoints.up("md")]: {
      width: "90%",
      maxHeight: "calc(100vh - 260px)", // Adjust the value as needed
      overflowY: "auto",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      maxHeight: "calc(100vh - 260px)", // Adjust the value as needed
      overflowY: "auto",
    },
  },
  header: {
    paddingLeft: theme.spacing(3),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  buttonAdd: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "flex-end"
  },
  employeeDetail: {
    border: "solid 1px #DCE0E7",
    padding: theme.spacing(3),
    marginTop: "18px",
    marginBottom: theme.spacing(1),
    display: "flex",
    justifyContent: "center",
    textAlign: "center"
  },
  footer: {
    background: theme.palette.common.white,
    boxShadow: "0px -1px 0px rgba(58, 77, 84, 0.15)",
    borderRadius: "0px 0px 4px 4px",
    padding: theme.spacing(2, 4, 2, 3),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  }
});

const useStyles = makeStyles(style);

export default function CreateEmployeeDialogComponent({
  isOpen,
  handleClose,
  handleSubmit,
  alertError,
  handleCheckEmail,
  lang
}) {
  const classes = useStyles();

  return (
    <Dialog
      isOpen={isOpen}
      handleClose={handleClose}
      disableEnforceFocus={true}
      disableAutoFocus={true}
      disableEscapeKeyDown={true}
      disableBackdropClick={true}
      closeAfterTransition
      BackdropComponent={Backdrop}
    >
      <Paper elevation={0} className={classes.root}>
        <div className={classes.infoContainer}>
          <div className={classes.header}>
            <Typography variant="subtitle2" style={{ fontWeight: "bold" }}>
              {getLang(lang, "label.ADD_EMPLOYEE")}
            </Typography>
            <IconButton color="primary" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </div>
          <Divider />

          <Formik
            initialValues={{
              email: "",
              role: {},
              branch: [],
              employees: []
            }}
            validationSchema={Yup.object({
              email: Yup.string()
                .email(getLang(lang, "message.error.FORM_VALIDATE_EMAIL"))
            })}
            onSubmit={handleSubmit}
          >
            {(formik) => (
              <form onSubmit={formik.handleSubmit}>
                <Box className={classes.wrapper}>
                <div className={classes.detailsWrapper}>
                    <Grid container style={{ alignItems: "center" }}>
                      <Grid item xs={3} sm={3} md={3} style={{ width: "100%" }}>
                        <Typography variant="body2" color="textSecondary">
                        {getLang(lang, "label.EMAIL")} <Typography style={{ color: "#DE350B", display: 'inline-block' }}>*</Typography>
                        </Typography>
                      </Grid>
                      <Grid item xs={9} sm={9} md={9} style={{ width: "100%" }}>
                        <InputTextField
                          name="email"
                          hiddenLabel
                          variant="filled"
                          size="small"
                          fullWidth
                          margin="dense"
                          disabled={formik.isSubmitting}
                          {...formik.getFieldProps("email")}
                          error={
                            formik.touched.email && formik.errors.email
                              ? true
                              : false
                          }
                          helperText={ErrorMessage({ name: "email" })}
                        />
                      </Grid>
                    </Grid>

                    <Grid container style={{ alignItems: "center" }}>
                      <Grid item xs={3} sm={3} md={3} style={{ width: "100%" }}>
                        <Typography variant="body2" color="textSecondary">
                          {getLang(lang, "label.ROLE")} <Typography style={{ color: "#DE350B", display: 'inline-block' }}>*</Typography>
                        </Typography>
                      </Grid>
                      <Grid item xs={9} sm={9} md={9} style={{ width: "100%" }}>
                        <RoleSelect
                          value={formik.values.role}
                          handleChange={(value) =>
                            formik.setFieldValue("role", value)
                          }
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      style={{ alignItems: "center", paddingTop: "8px" }}
                    >
                      <Grid item xs={3} sm={3} md={3} style={{ width: "100%" }}>
                        <Typography variant="body2" color="textSecondary">
                          {getLang(lang, "label.BRANCH")} <Typography style={{ color: "#DE350B", display: 'inline-block' }}>*</Typography>
                        </Typography>
                      </Grid>
                      <Grid item xs={9} sm={9} md={9} style={{ width: "100%" }}>
                        <BranchSelect
                          value={formik.values.branch}
                          handleChange={(value) =>
                            formik.setFieldValue("branch", value)
                          }
                        />
                      </Grid>
                    </Grid>

                    <Box className={classes.buttonAdd}>
                      <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                        type="button"
                        onClick={async () => {
                          const newEmployee = {
                            email: formik.values.email,
                            role: formik.values.role,
                            branch: formik.values.branch
                          };
                          const temp = formik.values.employees.slice();
                          if (!newEmployee.email) {
                            alertError(getLang(lang, "message.error.NO_EMAIL"));
                            return;
                          }
                          else if (formik.touched.email && formik.errors.email ? true : false) {
                            return;
                          }
                          else if (temp.find((e) => e.email === newEmployee.email)) {
                            alertError(getLang(lang, "message.error.SAME_EMAIL_ADDED"));
                            return;
                          }
                          else if (!newEmployee.role.name) {
                            alertError(getLang(lang, "message.error.NO_ROLE_SELECTED"));
                            return;
                          }
                          else if (newEmployee.branch.length === 0) {
                            alertError(getLang(lang, "message.error.NO_BRANCH_SELECTED"));
                            return;
                          }
                          await handleCheckEmail(newEmployee.email);
                          temp.push(newEmployee);
                          formik.setFieldValue("employees", temp);
                          formik.setFieldValue("email", "");
                          formik.setFieldValue("branch", []);
                          formik.setFieldValue("role", {});
                        }}
                      >
                        {getLang(lang, "label.ADD")}
                      </Button>
                    </Box>

                    <AddEmployeeTable
                      tableData={formik.values.employees}
                      formik={formik}
                    />
                  </div>
                </Box>

                <div className={classes.footer}>
                  <Typography variant="caption" style={{ color: "#9CA6A9" }}>
                    <Typography style={{ color: "#DE350B", display: 'inline-block' }}>*</Typography> {getLang(lang, "label.REQUIRED_FIELD")}
                  </Typography>

                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    type="submit"
                    disabled={formik.values.employees.length === 0}
                  >
                    {formik.isSubmitting ? getLang(lang, "label.ADDING") : getLang(lang, "label.CONFIRM")}
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </Paper>
    </Dialog>
  );
}
