import React from "react";
import InputTextField from "components/input/inputTextField";
import LockTextField from "components/input/lockTextField";
import ProfileHeader from "../../profileHeader";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import languageType from "lib/constants/languages";
import { LockViewImg } from "../../../utils/static";
import InputAdornment from "@material-ui/core/InputAdornment";
import {
  Box,
  Paper,
  Button,
  makeStyles,
  Typography,
  Divider,
  Grid,
  MenuItem,
  TextField
} from "@material-ui/core";
import CountrySelect from "components/select/countrySelect";
import countries from "lib/constants/countries";
import TimezoneSelect from "components/select/timezoneSelect";
import { getLang } from "app/feature/constants";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexFlow: "column nowrap",
    boxShadow: theme.shadows[0],
    height: "max-content",
    alignItems: "center"
  },
  field: {
    borderBottom: "1px solid",
    "& h6:last-child": {
      fontWeight: "normal"
    }
  },
  label: {
    textTransform: "none"
  },
  button: {
    display: "flex",
    justifyContent: "flex-end"
  }
}));

export default function ProfilePanelComponent({
  handleSubmit,
  profileData = {},
  isChanging,
  editMode,
  timezones,
  lang,
  disableEdit
}) {
  const { isEditing, enableEdit } = editMode;
  const classes = useStyles();

  return (
    <Box>
      <Paper elevation={0}>
        <ProfileHeader />

        <Paper elevation={0} className={classes.paper}>
          <Box mt={1} style={{ width: "100%" }}>
            <Formik
              enableReinitialize
              initialValues={{
                loginId: profileData.username,
                firstName: profileData.firstName,
                lastName: profileData.lastName,
                email: profileData.email,
                contactNumber: profileData.contactNumber,
                phoneCode: countries.find(({ phone }) => phone === profileData.phoneCode) || "",
                phoneCountry: profileData.phoneCountry,
                timezone: timezones.find(({ id }) => id === profileData.timezone),
                language: languageType.findIndex(
                  (lg) => lg.code === profileData.language
                ),
                timezoneDisplay:
                  timezones.find((tz) => tz.id === profileData.timezone)
                    ?.text || "",
                languageDisplay: languageType.find(
                  (l) => l.code === profileData.language
                )?.label
              }}
              validationSchema={Yup.object({
                loginId: Yup.string().required(getLang(lang,"message.error.FORM_VALIDATE_REQUIRED")),
                firstName: Yup.string().trim()
                  .max(100, getLang(lang,"message.error.FORM_VALIDATE_MAX", {value: 100}))
                  .required(getLang(lang,"message.error.FORM_VALIDATE_REQUIRED")),
                lastName: Yup.string()
                  .trim()
                  .max(100, getLang(lang, "message.error.FORM_VALIDATE_MAX" ,{value: 100}))
                  .required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
                email: Yup.string()
                  .email(getLang(lang,"message.error.FORM_VALIDATE_EMAIL"))
                  .required(getLang(lang,"message.error.FORM_VALIDATE_REQUIRED")),
                contactNumber: Yup.string()
                  .trim()
                  .min(6, getLang(lang,"message.error.FORM_VALIDATE_MIN", {value: getLang(lang,"message.error.message.error.NUMBERS_6")}))
                  .max(20, getLang(lang,"message.error.FORM_VALIDATE_MAX", {value: getLang(lang,"message.error.message.error.NUMBERS_20")}))
                  .matches(/^[1-9]{1}[0-9]{4,19}$/, getLang(lang,"message.error.FORM_VALIDATE_PHONE"))
                  .required(getLang(lang,"message.error.FORM_VALIDATE_REQUIRED")),
                timezone: Yup.string().required(getLang(lang,"message.error.FORM_VALIDATE_REQUIRED")),
                language: Yup.number().required(getLang(lang,"message.error.FORM_VALIDATE_REQUIRED"))
              })}
              onSubmit={handleSubmit}
            >
              {(formik) => (
                <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
                  <Box
                    p={3}
                    style={{
                      display: "flex",
                      placeContent: "center",
                      backgroundColor: "#F4F6F8"
                    }}
                  >
                    <Box
                      p={1}
                      px={4}
                      style={{
                        width: "90%",
                        background: "#fff"
                      }}
                    >
                      {[
                        { label: getLang(lang,"label.LOGIN_ID"), name: "loginId", lock: true },
                        {
                          label: getLang(lang,"label.FIRST_NAME"),
                          name: "firstName",
                          lock: false
                        },
                        {
                          label: getLang(lang,"label.LAST_NAME"),
                          name: "lastName",
                          lock: false
                        },
                        { label: getLang(lang,"label.EMAIL"), name: "email", lock: true },
                        {
                          label: getLang(lang,"label.CONTACT"),
                          name: "contactNumber",
                          lock: false
                        },
                        {
                          label: getLang(lang,"label.TIME_ZONE"),
                          name: "timezone",
                          selects: timezones
                        },
                        {
                          label: getLang(lang,"label.LANGUAGE"),
                          name: "language",
                          selects: languageType
                        },
                      ].map((field, index) => {
                        return (
                          <Box key={index}>
                            {field.selects ? (
                              <>
                                {field.name === "timezone" ? (
                                  <Box mt={1} mb={1}>
                                    <Divider />{" "}
                                  </Box>
                                ) : (
                                  ""
                                )}

                                <Box style={{ display: "flex" }}>
                                  <Grid
                                    container
                                    style={{
                                      alignItems: "center"
                                    }}
                                  >
                                    <Grid item md={3} style={{ width: "100%" }}>
                                      <Typography
                                        variant="body2"
                                        color="textSecondary"
                                      >
                                        {field.label}
                                      </Typography>
                                    </Grid>
                                    <Grid item md={9} style={{ width: "100%" }}>
                                      {(field.name !== "timezone") ? (<InputTextField
                                        select={isEditing}
                                        name={field.name}
                                        hiddenLabel
                                        variant={
                                          isEditing ? "filled" : "standard"
                                        }
                                        size="small"
                                        fullWidth
                                        margin="dense"
                                        InputProps={
                                          isEditing
                                            ? {
                                              disableUnderline: true,
                                              style: {
                                                padding: "0",
                                                backgroundColor: "#ECEFF0",
                                                borderRadius: "4px"
                                              },
                                            }
                                            : {
                                              disableUnderline: true,
                                              style: {
                                                paddingLeft: "0",
                                                color: "#3A4D54",
                                                fontWeight: "700",
                                                fontSize: "14px"
                                              }
                                            }
                                        }
                                        inputProps={
                                          isEditing
                                            ? {
                                              style: {
                                                padding: "7px"
                                              },
                                            }
                                            : {
                                              style: {
                                                paddingLeft: "0",
                                                paddingTop: "7px",
                                                paddingBottom: "7px"
                                              }
                                            }
                                        }
                                        {...(isEditing
                                          ? formik.getFieldProps(field.name)
                                          : field.name === "timezone"
                                            ? formik.getFieldProps(
                                              "timezoneDisplay"
                                            )
                                            : field.name === "language"
                                              ? formik.getFieldProps(
                                                "languageDisplay"
                                              )
                                              : null)}
                                        disabled={
                                          isEditing ? formik.isSubmitting : true
                                        }
                                        error={
                                          formik.touched[field.name] &&
                                            formik.errors[field.name]
                                            ? true
                                            : false
                                        }
                                        helperText={ErrorMessage({
                                          name: field.name,
                                        })}
                                      >
                                        {field.selects.map((select, index) => (
                                          <MenuItem value={index} key={index}>
                                            {field.name === "timezone" &&
                                              `${select.text}`}
                                            {field.name === "language" &&
                                              `${select.label}`}
                                          </MenuItem>
                                        ))}
                                      </InputTextField>) : (
                                        !isEditing ? (
                                          <InputTextField
                                            name={field.name}
                                            hiddenLabel
                                            variant={"standard"}
                                            size="small"
                                            fullWidth
                                            margin="dense"
                                            InputProps={{
                                              disableUnderline: true,
                                              style: {
                                                paddingLeft: "0",
                                                color: "#3A4D54",
                                                fontWeight: "700",
                                                fontSize: "14px"
                                              }
                                            }}
                                            inputProps={{
                                              style: {
                                                paddingLeft: "0",
                                                paddingTop: "7px",
                                                paddingBottom: "7px"
                                              }
                                            }}
                                            {...formik.getFieldProps(
                                              "timezoneDisplay"
                                            )}
                                            disabled={true}
                                          >
                                          </InputTextField>
                                        ) : (
                                          <TimezoneSelect
                                            textFieldProps={{
                                              required: true,
                                              variant: "filled",
                                              size: "small",
                                              margin: "dense",
                                              error: formik.errors.timezone && formik.touched.timezone ? true : false,
                                              helperText: ErrorMessage({ name: "timezone" })
                                            }}
                                            formProps={{
                                              ...formik.getFieldProps("timezone"),
                                              value: formik.values.timezone,
                                              onChange: (e, newValue) => {
                                                formik.setFieldValue("timezone", newValue);
                                              },
                                              disabled: formik.isSubmitting,
                                              disableClearable: true
                                            }}
                                          />
                                        )
                                      )}
                                    </Grid>
                                  </Grid>
                                </Box>
                              </>
                            ) : (
                              <>
                                <Box style={{ display: "flex" }}>
                                  <Grid
                                    container
                                    style={{
                                      alignItems: "center",
                                    }}
                                  >
                                    <Grid item md={3} style={{ width: "100%" }}>
                                      <Typography
                                        variant="body2"
                                        color="textSecondary"
                                      >
                                        {field.label}
                                      </Typography>
                                    </Grid>
                                    <Grid item md={9} style={{ width: "100%" }}>
                                      {field.lock ? (
                                        isEditing ? (
                                          <LockTextField
                                            name={field.name}
                                            hiddenLabel
                                            disabled
                                            variant="filled"
                                            size="small"
                                            fullWidth
                                            margin="dense"
                                            color="primary"
                                            {...formik.getFieldProps(
                                              field.name
                                            )}
                                            // disabled={formik.isSubmitting}
                                            error={
                                              formik.touched[field.name] &&
                                                formik.errors[field.name]
                                                ? true
                                                : false
                                            }
                                            helperText={ErrorMessage({
                                              name: field.name,
                                            })}
                                          />
                                        ) : (
                                          <TextField
                                            name={field.name}
                                            hiddenLabel
                                            disabled
                                            fullWidth
                                            margin="normal"
                                            {...formik.getFieldProps(
                                              field.name
                                            )}
                                            InputProps={{
                                              disableUnderline: true,
                                              endAdornment: (
                                                <InputAdornment
                                                  position="end"
                                                  style={{
                                                    paddingRight: "6px"
                                                  }}
                                                >
                                                  <LockViewImg />
                                                </InputAdornment>
                                              ),
                                              style: {
                                                color: "#3A4D54",
                                                fontWeight: "700",
                                                fontSize: "14px"
                                              }
                                            }}
                                            error={
                                              formik.touched[field.name] &&
                                                formik.errors[field.name]
                                                ? true
                                                : false
                                            }
                                            helperText={ErrorMessage({
                                              name: field.name,
                                            })}
                                          />
                                        )
                                      ) : isEditing ? (
                                        <Box display="flex" gridColumnGap={12}>
                                          {field.name === "contactNumber" &&
                                            <CountrySelect
                                              type="phone"
                                              textFieldProps={{
                                                required: true,
                                                style: { width: 200 },
                                                variant: "filled",
                                                size: "small",
                                                margin: "dense",
                                                error: formik.errors.phoneCode && formik.touched.phoneCode ? true : false,
                                                helperText: ErrorMessage({ name: "phoneCode" }),
                                              }}
                                              formProps={{
                                                ...formik.getFieldProps("phoneCode"),
                                                value: formik.values.phoneCode,
                                                onChange: (e, newValue) => {
                                                  formik.setFieldValue("phoneCode", newValue);
                                                },
                                                disabled: formik.isSubmitting
                                              }}
                                            />
                                          }{field.name === "contactNumber" ?
                                            (<InputTextField
                                              onKeyPress={(e) => {
                                                if (!/[0-9]/.test(e.key)) {
                                                  e.preventDefault();
                                                }
                                              }}
                                              name={field.name}
                                              hiddenLabel
                                              variant="filled"
                                              size="small"
                                              fullWidth
                                              margin="dense"
                                              {...formik.getFieldProps(field.name)}
                                              error={
                                                formik.touched[field.name] &&
                                                  formik.errors[field.name]
                                                  ? true
                                                  : false
                                              }
                                              helperText={ErrorMessage({
                                                name: field.name
                                              })}
                                            />) :
                                            <InputTextField
                                              name={field.name}
                                              hiddenLabel
                                              variant="filled"
                                              size="small"
                                              fullWidth
                                              margin="dense"
                                              {...formik.getFieldProps(field.name)}
                                              error={
                                                formik.touched[field.name] &&
                                                  formik.errors[field.name]
                                                  ? true
                                                  : false
                                              }
                                              helperText={ErrorMessage({
                                                name: field.name
                                              })}
                                            />
                                          }

                                        </Box>
                                      ) : (
                                        <TextField
                                          name={field.name}
                                          hiddenLabel
                                          disabled
                                          fullWidth
                                          margin="normal"
                                          {...formik.getFieldProps(field.name)}
                                          InputProps={{
                                            disableUnderline: true,
                                            style: {
                                              color: "#3A4D54",
                                              fontWeight: "700",
                                              fontSize: "14px"
                                            }
                                          }}
                                          value={field.name === "contactNumber" ? `${formik.values.phoneCode.phone || ""} ${formik.values.contactNumber}` : formik.getFieldProps(field.name).value}
                                          error={
                                            formik.touched[field.name] &&
                                              formik.errors[field.name]
                                              ? true
                                              : false
                                          }
                                          helperText={ErrorMessage({
                                            name: field.name
                                          })}
                                        />
                                      )}
                                    </Grid>
                                  </Grid>
                                </Box>
                              </>
                            )}
                          </Box>
                        );
                      })}
                    </Box>
                  </Box>
                  {isEditing && (
                    <Box mb={3}>
                      <Divider />
                      <Box mt={3} pr={3} className={classes.button}>
                        <Button
                          variant="oulined"
                          disableElevation
                          type="button"
                          style={{ marginRight: "4px" }}
                          onClick={(e) => {
                            formik.resetForm()
                            disableEdit()
                          }}
                        >
                          {getLang(lang,"label.CANCEL")}
                        </Button>
                        <Button
                          color="primary"
                          variant="contained"
                          disableElevation
                          type="submit"
                          disabled={formik.isSubmitting || isChanging}
                        >
                          {getLang(lang,"label.UPDATE")}
                        </Button>
                      </Box>
                    </Box>
                  )}
                </form>
              )}
            </Formik>

            {!isEditing && (
              <Box mb={3}>
                <Divider />
                <Box mt={3} pr={3} className={classes.button}>
                  <Button
                    color="primary"
                    onClick={enableEdit}
                    variant="contained"
                    disableElevation
                  >
                    {getLang(lang,"label.EDIT")}
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        </Paper>
      </Paper>
    </Box>
  );
}
