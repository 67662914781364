import React from "react";
import {
  Box,
  makeStyles,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Paper,
  TableSortLabel,
  Link,
} from "@material-ui/core";
import {
  StyledTableCell,
  StyledTableRow,
  StickyRightTableCell,
} from "components/table";
import { SkeletonTableRowStickyLeftRight } from "components/skeleton/skeleton.component";
import { ActionButton } from "components/button";
import { TablePaginationActions } from "components/tablePagination";
import RefreshTable from "components/table/refreshTable";
import moment from "moment";
import { KeyboardArrowDown } from "@material-ui/icons";
import { getLang } from "app/feature/constants";
import "moment/min/locales.min";
import DOMPurify from "dompurify";

const useStyle = makeStyles((theme) => ({
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    borderCollapse: "separate",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
    },
    "& th,td": {
      fontSize: ".75rem",
      borderRight: "1px solid rgba(58, 77, 84, 0.1)",
      padding: theme.spacing(1, 2, 1, 2),
    },
    "& th": {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightBold,
    },
    "& th:last-child, td:last-child": {
      width: 37,
      borderRight: "0px",
    },
    "& th th": {
      borderBottom: "none !important",
    },
    "& td td": {
      borderBottom: "none !important",
    },
  },
  emptyContainer: {
    backgroundColor: theme.palette.background.paper,
    textAlign: "center",
  },
}));

export default function HandoffListTableComponent({
  rows,
  isFetching,
  isError,
  handleReload,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowPerPage,
  totalFiltered,
  paginationOptions,
  tableFilter,
  language,
  changeOrder,
  lang,
  handleViewDetail,
}) {
  const classes = useStyle();

  if(language==="ZH")
  {
    moment.locale('zh-cn')
  }else if(language==="TL")
  {
    moment.locale('tl-ph')
  }else
  {
    moment.locale(language)
  }

  const statusColor = {
    pending: "#FDB022",
    approved: "#6AAF68",
    rejected: "#F04438",
  };

  const generateLink = (remark) => {
    let urlRegex = /(https?:\/\/[^\s]+)/g;
    let textWithLink = remark.replace(urlRegex, (url) => {
      return '<a href="' + url + '" target="_blank">' + url + "</a>";
    });

    const sanitizedHtmlContent = DOMPurify.sanitize(textWithLink);

    return (
      <Box
        dangerouslySetInnerHTML={{
          __html: sanitizedHtmlContent,
        }}
      />
    );
  };

  return (
    <>
      {!isFetching && isError && <RefreshTable handleReload={handleReload} />}
      {isFetching || rows.length > 0 ? (
        <Paper id="test" style={{ overflowX: "auto", width: "100%" }}>
          <TableContainer>
            <Table
              className={classes.table}
              aria-label="handoff table"
              size="small"
              stickyHeader
            >
              <TableHead style={{ height: "45px" }}>
                <TableRow>
                  <StyledTableCell>
                    {getLang(lang, "label.REFERENCE_CODE")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.BRANCH")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.STATUS")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.REMARK")}
                  </StyledTableCell>
                  <StyledTableCell>
                    <TableSortLabel
                      active={tableFilter.orderBy === "date"}
                      direction={tableFilter.order}
                      onClick={() => {
                        const differentTab = tableFilter.orderBy !== "date";
                        const order = differentTab
                          ? tableFilter.order
                          : tableFilter.order === "asc"
                          ? "desc"
                          : "asc";
                        changeOrder("created_at", order);
                      }}
                      IconComponent={KeyboardArrowDown}
                    >
                      {getLang(lang, "label.DATE")}
                    </TableSortLabel>
                  </StyledTableCell>
                  <StickyRightTableCell
                    style={{ width: "67px", textAlign: "center" }}
                  >
                    {getLang(lang, "label.ACTION")}
                  </StickyRightTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isFetching
                  ? [...new Array(5)].map((v, index) => (
                      <SkeletonTableRowStickyLeftRight
                        key={index}
                        columnCount={6}
                      />
                    ))
                  : rows.map((row, index) => {
                      const actionItems = [];

                      actionItems.push({
                        label: getLang(lang, "label.VIEW_DETAILS"),
                        action: () => {
                          handleViewDetail(row);
                        },
                      });

                      return (
                        <StyledTableRow
                          key={row.uuid}
                          hover
                          role="checkbox"
                          tabIndex={-1}
                        >
                          <StyledTableCell>
                            <Link
                              color="secondary"
                              component="button"
                              onClick={() => {
                                handleViewDetail(row);
                              }}
                            >
                              {row.reference_code}
                            </Link>
                          </StyledTableCell>
                          <StyledTableCell>
                            <span>{row.branch}</span>
                          </StyledTableCell>
                          <StyledTableCell>
                            <span style={{ color: statusColor[row.status] }}>
                              {getLang(lang, `db_label.${row.status}`)}
                            </span>
                          </StyledTableCell>
                          <StyledTableCell>
                            <span>
                              {row.remark ? generateLink(row.remark) : "-"}
                            </span>
                          </StyledTableCell>
                          <StyledTableCell>
                            <span>
                              {row.date
                                ? moment(
                                    row.date,
                                    "DD MMM YYYY, HH:mm:ss"
                                  ).format("lll")
                                : "-"}
                            </span>
                          </StyledTableCell>
                          <StickyRightTableCell style={{ width: "67px" }}>
                            <Box display="flex" justifyContent="center">
                              <ActionButton
                                actionItems={actionItems}
                                popperStyle={{ zIndex: 999 }}
                              />
                            </Box>
                          </StickyRightTableCell>
                        </StyledTableRow>
                      );
                    })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            style={{
              backgroundColor: "#fff",
              boxShadow: "0px -1px 0px rgba(58, 77, 84, 0.15)",
            }}
            ActionsComponent={TablePaginationActions}
            rowsPerPageOptions={paginationOptions}
            component="div"
            count={totalFiltered}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowPerPage}
            labelRowsPerPage={getLang(lang, "label.ROWS_PER_PAGE")}
            labelDisplayedRows={({ from, to, count }) =>
              `${from.toLocaleString("en-US")}-${to.toLocaleString("en-US")} ${getLang(lang, "label.OF")} ${count.toLocaleString("en-US")}`
            }
          />
        </Paper>
      ) : null}
      {!isFetching && !isError && rows.length === 0 && (
        <Box>
          <Table
            className={classes.table}
            aria-label="checkout table"
            size="small"
            stickyHeader
          >
            <TableHead style={{ height: "45px" }}>
              <TableRow>
                <StyledTableCell>
                  {getLang(lang, "label.REFERENCE_CODE")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.BRANCH")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.STATUS")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.REMARK")}
                </StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.DATE")}</StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.ACTION")}
                </StyledTableCell>
              </TableRow>
            </TableHead>
          </Table>
          <Box py={9} className={classes.emptyContainer}>
            <Typography
              variant="body1"
              style={{ paddingBottom: "16px", fontWeight: "bold" }}
            >
              {getLang(lang, "paragraph.NO_HANDOFF_RECORD_EXIST")}
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
}
