import React from "react";
import {
  makeStyles,
  Typography,
  Box,
  CircularProgress,
  Paper,
  Divider,
  Link,
} from "@material-ui/core";
import { ChevronRightRounded as ChevronRightRoundedIcon } from "@material-ui/icons";
import { BackButton } from "components/button";
import RefreshTable from "components/table/refreshTable";
import DefaultImg from "assets/img/img-default.png";
import DefaultImage from "assets/img/defaultPhoto.png";
import moment from "moment";
import { ROOT } from "modules/stockflow/config/stockflow.route";
import { useHistory } from "react-router-dom";
import { TIER_ICON_PROPS } from "modules/stockflow/constants";
import RecallItemListPanel from "modules/stockflow/components/panel/recallItemListPanel";
import { getLang } from "app/feature/constants";
import "moment/min/locales.min";
import DOMPurify from "dompurify";

const useStyle = makeStyles((theme) => ({
  panelContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridGap: "1em",
    gridTemplateAreas: "'left-panel right-panel'",
    [theme.breakpoints.down("sm")]: {
      gridTemplateAreas: "'left-panel left-panel''right-panel right-panel'",
    },
  },
  productContainer: {
    marginBottom: "16px",
    padding: "20px 16px",
    borderRadius: "8px",
  },
  list: {
    maxHeight: "380px",
    overflowY: "auto",
    padding: "4px 6px 8px",
    "&::-webkit-scrollbar": {
      width: "10px",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "16px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#E4E7EC",
      borderRadius: "16px",
    },
  },
  field: {
    display: "grid",
    gridTemplateColumns: "2fr 3fr",
    columnGap: theme.spacing(2),
  },
  panel: {
    backgroundColor: "#FFFFFF",
    borderRadius: 4,
    padding: "20px 16px",
    marginBottom: 16,
  },
  largeProfile: {
    height: 80,
    width: 80,
    minWidth: 80,
    padding: "2px",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& img": {
      height: "100%",
      minHeight: "100%",
      minWidth: "100%",
      objectFit: "cover",
      borderRadius: "50%",
      border: "1px solid #D0D5DD",
    },
  },
  respondProfile: {
    height: 32,
    width: 32,
    minWidth: 32,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& img": {
      height: "100%",
      minHeight: "100%",
      minWidth: "100%",
      objectFit: "cover",
      border: "1px solid #D0D5DD",
      borderRadius: "50%",
    },
  },
  branchProfile: {
    height: 40,
    width: 40,
    minWidth: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& img": {
      height: "100%",
      minHeight: "100%",
      minWidth: "100%",
      objectFit: "cover",
      borderRadius: "50%",
    },
  },
  productImage: {
    height: 50,
    width: 50,
    minWidth: 50,
    borderRadius: "4px",
    border: "1px solid #0000001A",
    padding: "2px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& img": {
      height: "100%",
      minHeight: "100%",
      minWidth: "100%",
      objectFit: "cover",
    },
  },
  tierIcon: {
    borderRadius: "4px",
    paddingLeft: "0.15em",
    paddingRight: "0.15em",
    display: "flex",
    alignItems: "center",
    position: "absolute",
    top: "2px",
    left: "-1px",
  },
  tierLabel: {
    color: "#FFFFFF",
  },
  summaryBox: {
    border: "1px solid #D0D5DD",
    padding: "8px 10px",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridGap: "1em",
  },
  attributeText: {
    color: "#b2b2b2",
    fontSize: 12,
    whiteSpace: "break-spaces",
  },
}));

export default function RecallDetailComponent({
  hasStockflowDealerViewAccessRight,
  recall,
  isFetching,
  isError,
  handleReload,
  language,
  lang,
}) {
  const classes = useStyle();
  const history = useHistory();

  if (language === "ZH") {
    moment.locale("zh-cn");
  } else if (language === "TL") {
    moment.locale("tl-ph");
  } else {
    moment.locale(language);
  }

  const generateLink = (remark) => {
    let urlRegex = /(https?:\/\/[^\s]+)/g;
    let textWithLink = remark.replace(urlRegex, (url) => {
      return '<a href="' + url + '" target="_blank">' + url + "</a>";
    });

    const sanitizedHtmlContent = DOMPurify.sanitize(textWithLink);

    return (
      <Box
        dangerouslySetInnerHTML={{
          __html: sanitizedHtmlContent,
        }}
      />
    );
  };

  return (
    <Box className={classes.root}>
      <Box gridArea="topbar" mb={3}>
        <Box
          mb={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="flex" alignItems="flex-end" fontWeight="bold">
            <BackButton />
            <Box display="flex" ml={1} alignItems="flex-end">
              <Typography variant="h6" color="primary">
                {getLang(lang, "label.RECALL")}
              </Typography>
              <Box mx={0.5}>
                <ChevronRightRoundedIcon color="disabled" />
              </Box>
              <Box>
                <Typography variant="h6" color="primary">
                  {getLang(lang, "label.RECALL_DETAILS")}
                </Typography>
              </Box>
            </Box>
          </Box>
          {/* <Box>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              style={{ padding: "5px 12px", background: "white", borderRadius: "8px" }}
              disableElevation
              onClick={() => {}}
              startIcon={<DownloadSVG />}
              // disabled={isLoading}
            >
              EXPORT XLS
            </Button>
          </Box> */}
        </Box>
      </Box>
      <Box gridArea="panel">
        {!isFetching && !isError && recall && (
          <>
            <Box px={1} py={1} mb={2}>
              <Typography variant="h5">
                <b>
                  {getLang(lang, "label.RECALL_ID")}: {recall.reference_code}
                </b>
              </Typography>
            </Box>
            <Box className={classes.panelContainer}>
              <Box gridArea="left-panel">
                <Paper className={classes.panel} elevation={3}>
                  <Box mb={2} display="flex" alignItems="center">
                    <Box className={classes.branchProfile} mr={1}>
                      {recall.node?.picture ? (
                        <img src={recall.node.picture} alt={recall.node.name} />
                      ) : (
                        <img src={DefaultImg} alt="default branch" />
                      )}
                    </Box>
                    <Typography variant="body1">{recall.node.name}</Typography>
                  </Box>
                  <Divider />
                  <Box mt={1}>
                    <Box display="flex" alignItems="center" mb={1}>
                      <Typography variant="h6">
                        {getLang(lang, "label.RECALL_BY")}
                      </Typography>
                    </Box>
                    <Box mb={1} display="flex" alignItems="center">
                      <Box className={classes.largeProfile} mr={1.5}>
                        {recall.recall_by?.picture ? (
                          <img
                            src={recall.recall_by.picture}
                            alt={recall.recall_by.name}
                          />
                        ) : (
                          <img
                            src={
                              recall.recall_by.type === "Headquarter"
                                ? DefaultImg
                                : DefaultImage
                            }
                            alt="default profile"
                          />
                        )}
                        {!!recall.recall_by.tier && (
                          <Box
                            className={classes.tierIcon}
                            style={{
                              backgroundColor: TIER_ICON_PROPS.find(
                                ({ tier }) => tier === recall.recall_by.tier
                              ).color,
                            }}
                          >
                            <Typography
                              className={classes.tierLabel}
                              variant="body1"
                            >
                              T{recall.recall_by.tier}
                            </Typography>
                          </Box>
                        )}
                        {!!recall.recall_by.employee && (
                          <Box
                            className={classes.respondProfile}
                            position="absolute"
                            style={{
                              bottom: "0px",
                              right: "0px",
                            }}
                          >
                            {recall.recall_by.employee.picture ? (
                              <img
                                src={recall.recall_by.employee.picture}
                                alt={recall.recall_by.employee.name}
                              />
                            ) : (
                              <img src={DefaultImage} alt="default profile" />
                            )}
                          </Box>
                        )}
                      </Box>
                      <Box>
                        <Box mb={0.5}>
                          {recall.recall_by.type === "Headquarter" ||
                          !hasStockflowDealerViewAccessRight ? (
                            <>
                              <Typography
                                variant="body1"
                                style={{ textAlign: "left" }}
                              >
                                {recall.recall_by.name}
                              </Typography>
                            </>
                          ) : (
                            <Link
                              href={`${ROOT}/my-dealer/${recall.recall_by.uuid}`}
                              color="secondary"
                              onClick={(e) => {
                                history.push(
                                  `${ROOT}/my-dealer/${recall.recall_by.uuid}`
                                );
                                e.preventDefault();
                                return false;
                              }}
                            >
                              <Typography
                                variant="body1"
                                style={{ textAlign: "left" }}
                              >
                                {recall.recall_by.name}
                              </Typography>
                            </Link>
                          )}
                        </Box>
                        {!!recall.recall_by.employee && (
                          <Link
                            href={`/admin/employee/View/${recall.recall_by.employee.uuid}`}
                            color="secondary"
                            onClick={(e) => {
                              history.push(
                                `/admin/employee/View/${recall.recall_by.employee.uuid}`
                              );
                              e.preventDefault();
                              return false;
                            }}
                          >
                            <Typography
                              variant="body1"
                              style={{ textAlign: "left" }}
                            >
                              {recall.recall_by.employee.name}
                            </Typography>
                          </Link>
                        )}
                        <Box>
                          <Typography variant="body1" color="textSecondary">
                            <span>{recall.recall_by.phone_code} </span>
                            <span>{recall.recall_by.phone_number}</span>
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="body1" color="textSecondary">
                            {recall.recall_by.email}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Paper>
                <Paper className={classes.panel} elevation={3}>
                  <Box mb={2}>
                    <Typography variant="body1">
                      <b>{getLang(lang, "label.RECALL_INFORMATION")}</b>
                    </Typography>
                  </Box>
                  <Divider />
                  <Box mt={2}>
                    <Box className={classes.field}>
                      <Typography variant="body1">
                        {getLang(lang, "label.RECALL_DATE")}
                      </Typography>
                      <Typography variant="body1" color="textSecondary">
                        <span>: </span>
                        <span>
                          {moment.utc(recall.recall_date).local().format("lll")}
                        </span>
                      </Typography>
                    </Box>
                    <Box className={classes.field} mt={2}>
                      <Typography variant="body1">
                        {getLang(lang, "label.REMARK")}
                      </Typography>
                      <Box display="flex">
                        <Typography
                          variant="body1"
                          color="textSecondary"
                          style={{ marginRight: 3 }}
                        >
                          :{" "}
                        </Typography>
                        <Typography variant="body1" color="textSecondary">
                          {recall.remarks ? generateLink(recall.remarks) : "-"}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Paper>
                <Paper className={classes.panel} elevation={3}>
                  <Box mb={2}>
                    <Typography variant="body1">
                      <b>{getLang(lang, "label.RECALL_SUMMARY")}</b>
                    </Typography>
                  </Box>
                  <Divider />
                  <Box display="flex" justifyContent="space-between" my={2}>
                    <Typography variant="body1">
                      {getLang(lang, "label.PRODUCT_NAME")}
                    </Typography>
                    <Typography variant="body1">
                      {getLang(lang, "label.QUANTITY")}
                    </Typography>
                  </Box>
                  <Box className={classes.list}>
                    {recall.products.map((item, index) => (
                      <Paper
                        key={item.id}
                        elevation={3}
                        className={classes.productContainer}
                      >
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Box display="flex" alignItems="center" mr={1}>
                            <Typography variant="body1">
                              <b>{index + 1}.</b>
                            </Typography>
                            <Box mx={2} className={classes.productImage}>
                              {item.product_image ? (
                                <img
                                  src={item.product_image}
                                  alt={item.product_name}
                                />
                              ) : (
                                <img src={DefaultImg} alt="default product" />
                              )}
                            </Box>
                            <Box
                              display="flex"
                              flexDirection="column"
                              justifyContent="center"
                              alignItems="flex-start"
                            >
                              <Typography variant="body1">
                                <b>{item.product_name}</b>
                              </Typography>
                              {!!item.attributes &&
                                !!item.attributes.length && (
                                  <Typography
                                    className={classes.attributeText}
                                    variant="body2"
                                  >
                                    {item.attributes.join(", ")}
                                  </Typography>
                                )}
                            </Box>
                          </Box>
                          <Typography variant="body1">
                            {item.product_quantity.toLocaleString("en-US")}
                          </Typography>
                        </Box>
                      </Paper>
                    ))}
                  </Box>
                </Paper>
              </Box>
              <Box gridArea="right-panel">
                <RecallItemListPanel />
              </Box>
            </Box>
          </>
        )}
        {!isFetching && isError && (
          <Box className={classes.tabContainer}>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <RefreshTable handleReload={handleReload} />
            </Box>
          </Box>
        )}
        {isFetching && (
          <Box gridArea="main" mt={6} style={{ textAlign: "center" }}>
            <CircularProgress color="primary" />
          </Box>
        )}
      </Box>
    </Box>
  );
}
