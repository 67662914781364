import React, { useRef } from "react";
import {
  makeStyles,
  Typography,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  Box,
  Divider,
  InputAdornment,
  Button,
  MenuItem,
  MenuList,
  Tooltip,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import PerfectScrollbar from "react-perfect-scrollbar";
import InputTextField from "components/input/inputTextField";
import Skeleton from "@material-ui/lab/Skeleton";
import AntSwitch from "components/switch/switch";
import DefaultImg from "assets/img/img-default.png";

const useStyle = makeStyles((theme) => ({
  paper: {
    width: 385,
  },
  button: {
    padding: 0,
    minWidth: 0,
  },
  buttonAddWarranty: {
    display: "flex",
    padding: 0,
    minWidth: 0,
  },
  createLink: {
    padding: theme.spacing(2),
  },
  perfectScrollbar: {
    height: 200,
  },
  attributeText: {
    color: "#b2b2b2",
    fontSize: 12,
    whiteSpace: "break-spaces",
  },
}));

export default function WarrantyFormAssignProductPanelComponent({
  open,
  anchorRef,
  handleClose,
  handleListKeyDown,
  isOpenList,
  productDropdown,
  isFetching,
  value,
  handleSearch,
  warrantySettingId,
}) {
  const classes = useStyle();
  const textRef = useRef();
  return (
    <Popper
      open={open}
      style={{ zIndex: 1301 }}
      anchorEl={anchorRef.current}
      role={undefined}
      transition
      disablePortal={false}
      placement="bottom-start"
      modifiers={{
        preventOverflow: {
          enabled: false,
          boundariesElement: "scrollParent",
        },
      }}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === "bottom-start" ? "center top" : "center bottom",
          }}
        >
          <Paper className={classes.paper}>
            <ClickAwayListener onClickAway={handleClose}>
              <Box>
                <Box>
                  <InputTextField
                    variant="filled"
                    size="small"
                    fullWidth
                    inputRef={textRef}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          style={{ paddingRight: "6px" }}
                        >
                          <Button
                            onClick={() => handleSearch(textRef.current.value)}
                            className={classes.button}
                          >
                            <SearchIcon fontSize="small" />
                          </Button>
                        </InputAdornment>
                      ),
                      style: {
                        backgroundColor: "#FFFFFF",
                      },
                    }}
                    inputProps={{
                      onKeyDown: (e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                          handleSearch(textRef.current.value);
                          return false;
                        }
                      },
                      style: {
                        padding: "14px",
                      },
                    }}
                    placeholder="Search Product"
                  />
                </Box>

                <Divider />

                <PerfectScrollbar className={classes.perfectScrollbar}>
                  {isFetching ? (
                    <>
                      <Skeleton variant="text" animation="wave" />
                      <Skeleton variant="text" animation="wave" />
                      <Skeleton variant="text" animation="wave" />
                      <Skeleton variant="text" animation="wave" />
                    </>
                  ) : productDropdown.length > 0 ? (
                    productDropdown.map((field, index) => {
                      return (
                        <Box key={index}>
                          <MenuList
                            autoFocusItem={isOpenList}
                            id="menu-list-grow"
                            onClick={() => {
                              handleListKeyDown(field);
                            }}
                          >
                            {field.warranty_setting_id &&
                            warrantySettingId !== field.warranty_setting_id ? (
                              <Tooltip title="This product has assigned with another warranty">
                                <span>
                                  <MenuItem
                                    disabled={
                                      value.find(
                                        (v) =>
                                          v.id === field.id || v === field.id
                                      )
                                        ? false
                                        : true
                                    }
                                  >
                                    <AntSwitch
                                      disabled={
                                        value.find(
                                          (v) =>
                                            v.id === field.id || v === field.id
                                        )
                                          ? false
                                          : true
                                      }
                                      checked={
                                        value.find(
                                          (v) =>
                                            v.id === field.id || v === field.id
                                        )
                                          ? true
                                          : false
                                      }
                                    />
                                    <Box className={classes.listImage}>
                                      <img
                                        src={
                                          field.picture
                                            ? field.picture
                                            : DefaultImg
                                        }
                                        style={{
                                          width: "30px",
                                          height: "30px",
                                          marginRight: "0.5em",
                                          marginLeft: "0.5em",
                                        }}
                                        alt={field.id}
                                      />
                                    </Box>
                                    <Typography
                                      variant="inherit"
                                      noWrap
                                      style={{ paddigLeft: "8px" }}
                                    >
                                      {field.name}
                                    </Typography>
                                  </MenuItem>
                                </span>
                              </Tooltip>
                            ) : (
                              <MenuItem>
                                <AntSwitch
                                  checked={
                                    value.find(
                                      (v) => v.id === field.id || v === field.id
                                    )
                                      ? true
                                      : false
                                  }
                                />
                                <Box className={classes.listImage}>
                                  <img
                                    src={
                                      field.picture ? field.picture : DefaultImg
                                    }
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                      marginLeft: "0.5em",
                                    }}
                                    alt={field.id}
                                  />
                                </Box>
                                <Box
                                  display="flex"
                                  flexDirection="column"
                                  justifyContent="center"
                                  alignItems="flex-start"
                                  ml={1}
                                >
                                  <Typography
                                    className={classes.name}
                                    variant="body2"
                                  >
                                    {field.name}
                                  </Typography>
                                  {!!field.attributes &&
                                    !!field.attributes.length && (
                                      <Typography
                                        className={classes.attributeText}
                                        variant="body2"
                                      >
                                        {field.attributes.join(", ")}
                                      </Typography>
                                    )}
                                </Box>
                              </MenuItem>
                            )}
                          </MenuList>
                        </Box>
                      );
                    })
                  ) : (
                    <Box py={9} style={{ textAlign: "center" }}>
                      <Typography variant="body1">No Product</Typography>
                    </Box>
                  )}
                  <Divider />
                </PerfectScrollbar>
              </Box>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}
