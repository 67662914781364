import { getApiLang } from "app/feature/constants";
import { setLoading, addAlert } from "modules/notification";
import {
  getRecallDealerDropdown,
  getRecallDetail,
  getRecallItemList,
  getRecallList,
  getRecallRequestorDropdown,
} from "../action/stockflowRecall.action";

const getRecallListMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getRecallList.pending.type:
        dispatch(setLoading({ id: getRecallList.typePrefix, state: true }));
        break;
      case getRecallList.fulfilled.type:
        dispatch(setLoading({ id: getRecallList.typePrefix, state: false }));
        break;
      case getRecallList.rejected.type:
        const lang = getState().constant.languages;
        dispatch(setLoading({ id: getRecallList.typePrefix, state: false }));
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_GET_RECALL_LIST"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const getRecallDetailMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getRecallDetail.pending.type:
        dispatch(setLoading({ id: getRecallDetail.typePrefix, state: true }));
        break;
      case getRecallDetail.fulfilled.type:
        dispatch(setLoading({ id: getRecallDetail.typePrefix, state: false }));
        break;
      case getRecallDetail.rejected.type:
        const lang = getState().constant.languages;
        dispatch(setLoading({ id: getRecallDetail.typePrefix, state: false }));
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_GET_RECALL_DETAIL"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const getRecalltRequestorDropdownMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getRecallRequestorDropdown.pending.type:
        dispatch(
          setLoading({ id: getRecallRequestorDropdown.typePrefix, state: true })
        );
        break;
      case getRecallRequestorDropdown.fulfilled.type:
        dispatch(
          setLoading({
            id: getRecallRequestorDropdown.typePrefix,
            state: false,
          })
        );
        break;
      case getRecallRequestorDropdown.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: getRecallRequestorDropdown.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

const getRecallDealerDropdownMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getRecallDealerDropdown.pending.type:
        dispatch(
          setLoading({ id: getRecallDealerDropdown.typePrefix, state: true })
        );
        break;
      case getRecallDealerDropdown.fulfilled.type:
        dispatch(
          setLoading({ id: getRecallDealerDropdown.typePrefix, state: false })
        );
        break;
      case getRecallDealerDropdown.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({ id: getRecallDealerDropdown.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

const getRecallItemListMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getRecallItemList.pending.type:
        dispatch(setLoading({ id: getRecallItemList.typePrefix, state: true }));
        break;
      case getRecallItemList.fulfilled.type:
        dispatch(
          setLoading({ id: getRecallItemList.typePrefix, state: false })
        );
        break;
      case getRecallItemList.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({ id: getRecallItemList.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_GET_RECALL_ITEMS"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

export default [
  getRecallListMiddleware,
  getRecallDetailMiddleware,
  getRecalltRequestorDropdownMiddleware,
  getRecallDealerDropdownMiddleware,
  getRecallItemListMiddleware,
];
