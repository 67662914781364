import React from "react";
import {
  makeStyles,
  Box,
  Tabs,
} from "@material-ui/core";
import { Editor } from "react-draft-wysiwyg";
import { TYPES, transformHTMLToRaw } from "./textForm.container";
import { ModalFooter, ContentWrapper } from "../../modal";
import LANGUAGES from "lib/constants/languages";
import LanguageTab from "../../tab/languageTab";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles(theme => ({
  editor: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(0, 1),
    maxHeight: 300,
    minHeight: 200
  },
  toolbar: {
    border: "none",
    marginBottom: 0,
    borderBottom: "1px solid",
    borderBottomColor: theme.palette.divider
  },
  btnSecondary: {
    color: theme.palette.common.white
  },
  tabsRoot: {
    textTransform: "none"
  },
  indicator: {
    border: "2px solid",
    borderColor: theme.palette.primary.main,
    width: "50px !important",
    transform: "translateX(12px)",
    borderRadius: theme.spacing(1, 1, 0, 0)
  },
  scroll: {
    width: "min-content"
  },
  editorPaper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)",
    borderRadius: theme.spacing(0.5)
  },
  dropdownToolbar: {
    border: "none"
  }
}));

export default function TextFormComponent({
  type,
  handleSubmit,
  handleDelete,
  handleChange,
  editorState,
  initialValues,
  activeIndex,
  handleChangeTab,
  lang,
  handleClose,
  languages
}) {
  const classes = useStyle();

  return (
    <Box mb={type === TYPES[2] ? 2 : 0}>
      <ContentWrapper disablePadding={true} resetPaper={true}>
          <Box>
            <Box display={(lang.length === 1) ? "none" : "block"}>
              <Tabs
                value={activeIndex}
                onChange={handleChangeTab}
                aria-label="scrollable auto tabs example"
                textColor="primary"
                variant="scrollable"
                indicatorColor="primary"
                classes={{
                  root: classes.tabsRoot,
                  indicator: classes.indicator,
                  scrollButtons: classes.scroll
                }}
              >
                {lang.map((label, index) => {
                  const LABEL = LANGUAGES.some(langObj => langObj.code === label);
                  return (
                    <LanguageTab
                      key={index}
                      disabled={activeIndex === index ? true : false}
                      label={LABEL ? label : label.split("-")[1]}
                    />
                  );
                })}
              </Tabs>
            </Box>
            <Box className={classes.editorPaper}>
              <Editor
                placeholder={getLang(languages,"placeholder.ENTER_WRITE_UP_HERE")}
                readOnly={type === TYPES[2]}
                toolbarHidden={type === TYPES[2]}
                editorState={
                  type === TYPES[2]
                    ? transformHTMLToRaw(initialValues.html)
                    : editorState
                }
                onEditorStateChange={handleChange}
                toolbar={{
                  options: [
                    "inline",
                    "list",
                    "emoji",
                    "link",
                    "textAlign",
                    "blockType",
                    "fontSize"
                  ],
                  inline: {
                    inDropdown: true,
                    options: ["bold", "italic", "underline", "strikethrough"],
                    className: classes.dropdownToolbar
                  },
                  list: {
                    inDropdown: true,
                    className: classes.dropdownToolbar
                  },
                  textAlign: {
                    inDropdown: true,
                    className: classes.dropdownToolbar
                  },
                  blockType: {
                    inDropdown: true,
                    className: classes.dropdownToolbar
                  },
                  link: {
                    inDropdown: true,
                    className: classes.dropdownToolbar
                  },
                  fontSize: {
                    className: classes.dropdownToolbar
                  },
                  emoji: {
                    className: classes.dropdownToolbar
                  }
                }}
                editorClassName={classes.editor}
                toolbarClassName={classes.toolbar}
              />
            </Box>
          </Box>
      </ContentWrapper>
      <ModalFooter
        handleDelete={handleDelete}
        showDelete={type !== TYPES[0]}
        handleClick={handleSubmit}
        handleClose={handleClose}
        //handleDisable={editorState.has === "<p></p>"}
      />
    </Box>
  );
}
