import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut, Pie } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend)

export default function CircularChartComponent({
  labels,
  value,
  type
}) {

  const data = {
    labels: [...labels],
    datasets: [
      {
        label: "",
        data: [...value],
        backgroundColor: [
          "#FF5858",
          "#816DFF",
          "#FFAE33",
          "#7ED0FF",
          "#15E380",
          "#FFEA2A",
          "#BBFF65",
          "#FF5B8C",
          "#54BF54",
          "#39FBC0",
          "#814600",
          "#6AAF68",
          "#3A4D54",
          "#E4E7EC",
        ],
      }
    ],
  }

  return (
    <>
      {type === 'pie' ? (
        <Pie 
          data={data} 
          width={250} 
          height={250}
          options={{
            maintainAspectRatio: false,
            plugins: {
              legend: {
                position: "bottom"
              }
            }
          }} 
        />
      ): type === 'doughnut' ? (
        <Doughnut 
          data={data} 
          width={250} 
          height={250}
          options={{
            maintainAspectRatio: false,
            plugins: {
              legend: {
                position: "bottom"
              }
            }
          }} 
        />
      ) : null }
    </>
  )
}