import { createAsyncThunk } from "@reduxjs/toolkit";
import stockSummaryApi from "app/api/stockSummary";

const STOCK_SUMMARY = "stockSummary";

export const getMonthlyStockSummary = createAsyncThunk(
  `${STOCK_SUMMARY}/getMonthlyStockSummary`,
  async (payload, thunkApi) => {
    return stockSummaryApi
      .getStockSummary(payload)
      .then((response) => {
        return response;
      })
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const getBranchStockSummary = createAsyncThunk(
  `${STOCK_SUMMARY}/getBranchStockSummary`,
  async (payload, thunkApi) => {
    return stockSummaryApi
      .getStockSummary(payload)
      .then((response) => response)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const getProductStockSummary = createAsyncThunk(
  `${STOCK_SUMMARY}/getProductStockSummary`,
  async (payload, thunkApi) => {
    return stockSummaryApi
      .getStockSummary(payload)
      .then((response) => response)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const getCountryDropdown = createAsyncThunk(
  `${STOCK_SUMMARY}/getCountryDropdown`,
  async (payload, thunkApi) => {
    return stockSummaryApi
      .getCountryDropdown()
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const getBranchDropdown = createAsyncThunk(
  `${STOCK_SUMMARY}/getBranchDropdown`,
  async (payload, thunkApi) => {
    return stockSummaryApi
      .getBranchDropdown(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const getProductDropdown = createAsyncThunk(
  `${STOCK_SUMMARY}/getProductDropdown`,
  async (payload, thunkApi) => {
    return stockSummaryApi
      .getProductDropdown(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const exportStockSummary = createAsyncThunk(
  `${STOCK_SUMMARY}/exportStockSummary`,
  async (payload, thunkApi) => {
    return stockSummaryApi
      .exportStockSummary(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);
