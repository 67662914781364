import {Button, CircularProgress} from "@material-ui/core";
import React from "react";
import {getLang} from "app/feature/constants";
import {DownloadSVG} from "modules/lucky-draw/utils/static";

export default function ExportToExcelSelectComponent({
  isLoading,
  handleClick,
  disabled,
  anchorRef,
  lang,
}) {
  return (
    <Button
      ref={anchorRef}
      variant="outlined"
      color="primary"
      size="small"
      style={{ margin: "4px", background: "white" }}
      onClick={handleClick}
      startIcon={
        isLoading ? (
          <CircularProgress style={{ width: 10, height: 10 }} />
        ) : (
          <DownloadSVG />
        )
      }
      disabled={disabled}
    >
      {getLang(lang, "label.EXPORT")}
    </Button>
  )
}
