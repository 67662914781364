export const SERIAL_NUMBER = "serialNumber";
export const SERIAL_NUMBER_BATCH = "serialNumberBatch";
export const SERIAL_NUMBER_TAG = "serialNumberTag";
export const ASSIGN_BATCH = "assignBatch";
export const ADDITIONAL_INFO = "additionalInfo";

export const SERIAL_NUMBER_TYPE_NUMBER = "number";
export const SERIAL_NUMBER_TYPE_ALPHANUMERIC = "alphanumeric";

export const GENERATE_RANGE_TYPE_CUSTOM = "custom";
export const GENERATE_RANGE_TYPE_LAST_NUMBER = "last";

export const SERIAL_NUMBER_TAB_BATCH = "serial number batch";

export const SERIAL_NUMBER_BATCH_STATUS_GENERATED = "generated";
export const SERIAL_NUMBER_BATCH_STATUS_PENDING = "pending";
export const SERIAL_NUMBER_BATCH_STATUS_FAILED = "failed";

export const ASSIGN_BATCH_STATUS_PENDING = "pending";
export const ASSIGN_BATCH_STATUS_SUCCESS = "success";
export const ASSIGN_BATCH_STATUS_FAIL = "failed";

export const IMPORT_ADT_STATUS_PENDING = "pending";
export const IMPORT_ADT_STATUS_SUCCESS = "success";
export const IMPORT_ADT_STATUS_FAIL = "fail";
