import React from "react";
import {
  makeStyles,
  Box,
  Typography,
  Divider,
  Button,
  Tabs
} from "@material-ui/core";
import CustomTab from "components/tab";
import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";
import RewardListPanel from "modules/stockflow/components/panel/rewardListPanel";
import { TabPanel, allyProps } from "components/tabPanel";
import { AddIcon } from "components/icon";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(5, 0),
    width: "90%",
    maxWidth: 1000,
    display: "inherit",
    justifyContent: "inherit"
  },
  content: {
    width: "95%",
    maxWidth: 850
  },
  indicator: {
    border: "0.5px solid",
    borderColor: theme.palette.primary.main
  },
  scroll: {
    width: "min-content"
  }
}));

export default function RewardCenterPage({
  hasStockflowAddAccessRight,
  handleNewReward,
  handleChange,
  activeIndex,
  lang
}) {
  const classes = useStyle();

  return (
    <Box>
      <Box gridArea="top">
        <Box>
          <Box
            mb={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" alignItems="flex-end" fontWeight="bold">
              <Typography variant="h6" color="primary">
                {getLang(lang, 'label.STOCKFLOW')}
              </Typography>
              <Box mx={0.5}>
                <ChevronRightRoundedIcon color="disabled" />
              </Box>
              <Typography variant="h6" color="primary">
                {getLang(lang, 'label.REWARD_CENTER')}
              </Typography>
            </Box>
            <Box>
              {
                hasStockflowAddAccessRight
                  ?
                  <Button
                    variant="text"
                    color="primary"
                    startIcon={
                      <AddIcon color="secondary" style={{ fontSize: "13px" }} />
                    }
                    onClick={() => handleNewReward()}
                  >
                    {getLang(lang, 'label.ADD_REWARD')}
                  </Button>
                  : null
              }
            </Box>
          </Box>
          <Divider />
        </Box>
      </Box>

      <Box gridArea="main">
        <Tabs
          value={activeIndex}
          onChange={handleChange}
          aria-label="scrollable auto tabs example"
          textColor="primary"
          variant="scrollable"
          indicatorColor="primary"
          classes={{
            indicator: classes.indicator,
            scrollButtons: classes.scroll
          }}
        >
          <CustomTab {...allyProps(0, getLang(lang, 'label.REWARD'))} />
        </Tabs>
        <TabPanel value={activeIndex} index={0}>
          <RewardListPanel 
            handleAddReward={handleNewReward}
          />
        </TabPanel>
      </Box>
    </Box>
  );
}