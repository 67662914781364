import React from "react";
import TagTableComponent from "./tagTable.component";
import { useSelector } from "react-redux";

function TagTableContainer({
  isFetching,
  tagSerialNumberData,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
  length,
  search,
  handleAddSerialNumberPattern,
  handleReload,
  paginationOptions,
  lang,
  hasAdtInfoViewAccessRight,
  handleViewDetail
}) {
  const isLoadingSerialNumberTagListError = useSelector(
    (state) => state.serialNumberTag.isLoadingSerialNumberTagListError
  );

  return (
    <TagTableComponent
      rows={tagSerialNumberData}
      page={page}
      rowsPerPage={rowsPerPage}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      isFetching={isFetching}
      length={length}
      search={search}
      handleAddSerialNumberPattern={handleAddSerialNumberPattern}
      handleReload={handleReload}
      isLoadingSerialNumberTagListError={isLoadingSerialNumberTagListError}
      paginationOptions={paginationOptions}
      lang={lang}
      hasAdtInfoViewAccessRight={hasAdtInfoViewAccessRight}
      handleViewDetail={handleViewDetail}
    />
  );
}

export default TagTableContainer;
