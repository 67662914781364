import React, { useState } from "react";
import WebsiteFormComponent from "./websiteForm.component";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";

const TYPES = ["new", "edit", "readonly"];

function WebsiteFormContainer({
  type,
  handleResult,
  handleDelete,
  initialValues,
  lang,
  handleClose
}) {
  const languages = useSelector(state => state.constant.languages)
  const [descriptions, setDescriptions] = useState(
    type === TYPES[0]
      ? lang.map(code => ({ lang: code, value: "" }))
      : initialValues.descriptions
  );
  
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeDesc, setActiveDesc] = useState(descriptions[activeIndex].value);
  const URL = initialValues.URL;

  const handleChangeActiveDesc = e => {
    setActiveDesc(e.target.value);
  };

  const handleChangeActiveIndex = (e, index) => {
    const newDescription = { ...descriptions[activeIndex], value: activeDesc };
    const newDescriptions = Array.from(descriptions);
    newDescriptions.splice(activeIndex, 1, newDescription);
    setDescriptions(newDescriptions);
    setActiveDesc(descriptions[index].value);
    setActiveIndex(index);
    
  };

  const handleSubmit = values => {
    const newDescription = { ...descriptions[activeIndex], value: activeDesc };
    const newDescriptions = Array.from(descriptions);
    newDescriptions.splice(activeIndex, 1, newDescription);
    setDescriptions(newDescriptions);
    const URL = values.URL
    const content = {
      URL,
      descriptions: newDescriptions
    };
    handleResult(content);
  };

  return (
    <WebsiteFormComponent
      type={type}
      handleSubmit={handleSubmit}
      activeIndex={activeIndex}
      lang={lang}
      URL={URL}
      activeDesc={activeDesc}
      handleDelete={handleDelete}
      handleChangeActiveDesc={handleChangeActiveDesc}
      handleChangeActiveIndex={handleChangeActiveIndex}
      languages={languages}
      handleClose={handleClose}
    />
  );
}

WebsiteFormContainer.defaultProps = {
  type: TYPES[0],
  initialValues: { URL: "", descriptions: [] }
};

WebsiteFormContainer.propTypes = {
  type: PropTypes.oneOf(TYPES).isRequired,
  handleResult: PropTypes.func,
  handleDelete: PropTypes.func,
  initialValues: PropTypes.object
};

const mapStateToProps = state => ({
  lang: state.productPage.lang
});

export { TYPES };

export default connect(mapStateToProps)(WebsiteFormContainer);
