import { createAsyncThunk } from "@reduxjs/toolkit";
import { COUNTERFEIT_REPORT } from "../../constants";
import counterfeitReportApi from "app/api/counterfeitReport";

export const getCounterfeitReportList = createAsyncThunk(
  `${COUNTERFEIT_REPORT}/getCounterfeitReportList`,
  async (payload, thunkApi) => {
    return counterfeitReportApi.counterfeitReport.get(payload)
      .then(response => response)
      .catch(error => thunkApi.rejectWithValue(error))
  }
);

export const getCounterfeitReportDetail = createAsyncThunk(
  `${COUNTERFEIT_REPORT}/getCounterfeitReportDetail`,
  async (payload, thunkApi) => {

    return counterfeitReportApi.counterfeitReportDetail.get({
      refCode: payload
    }).then(response => response.data)
      .catch(error => thunkApi.rejectWithValue(error.response.data))

  }
);

export const updateCounterfeitReportDetail = createAsyncThunk(
  `${COUNTERFEIT_REPORT}/updateCounterfeitReportDetail`,
  async (payload, thunkApi) => {
    return counterfeitReportApi.counterfeitReportDetail.put({
      refCode: payload.refCode,
      status: payload.status,
      adminRemarks: payload.adminRemarks
    }).then(response => response.data)
      .catch(error => thunkApi.rejectWithValue(error.response.data))
  }
);
