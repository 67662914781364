import React from "react";
import GroupListTableComponent from "./groupListTable.component";

function GroupListTableContainer({
  rows,
  isFetching,
  isError,
  handleReload,
  hasGroupEditAccessRight,
  hasGroupAddAccessRight,
  rowsPerPage,
  page,
  isSearchResult,
  totalFiltered,
  handlePageChange,
  handleRowPerPageChange,
  paginationOptions,
  handleViewDetail,
  lang,
  openCreateDialog,
  handleDeleteGroup,
}) {
  return (
    <GroupListTableComponent
      rows={rows}
      isFetching={isFetching}
      isError={isError}
      handleReload={handleReload}
      hasGroupEditAccessRight={hasGroupEditAccessRight}
      hasGroupAddAccessRight={hasGroupAddAccessRight}
      rowsPerPage={rowsPerPage}
      page={page}
      isSearchResult={isSearchResult}
      totalFiltered={totalFiltered}
      handlePageChange={handlePageChange}
      handleRowPerPageChange={handleRowPerPageChange}
      paginationOptions={paginationOptions}
      handleViewDetail={handleViewDetail}
      lang={lang}
      openCreateDialog={openCreateDialog}
      handleDeleteGroup={handleDeleteGroup}
    />
  );
}

export default GroupListTableContainer;
