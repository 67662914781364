import getAllWarrantySetting from "./getAllWarrantySetting.api";
import createWarrantySetting from "./createWarrantySetting.api";
import getWarrantyExtension from "./getWarrantyExtension.api";
import warrantySettingForm from "./warrantySettingForm.api";
import createWarrantyExtension from "./createWarrantyExtension.api";
import warrantyNameDropdown from "./warrantyNameDropdown.api";
import getWarrantyExtensionBatch from "./getWarrantyExtensionBatch.api";
import assignWarranty from "./assignWarranty.api";
import getWarranteeList from "./getWarranteeList.api";
import getConsumerWarrantyDetail from "./getConsumerWarrantyDetail.api";
import verifyWarranty from "./verifyWarranty.api";
import warrantyLog from "./warrantyLog.api";
import warranteeList from "./getAllWarranteeList.api";
import warrantyClaims from "./warrantyClaims.api";
import warrantyReimbursement from "./warrantyReimbursement.api";
import { getWarrantyHandoffList } from "./getWarrantyHandoffList.api";
import { getWarrantyHandoffDetail } from "./getWarrantyHandoffDetail.api";
import { approveHandoff } from "./approveHandoff.api";
import { rejectHandoff } from "./rejectHandoff.api";
import exportWarrantyReimbursementTable from "./exportWarrantyReimbursement.api.js";
import warrantyItemReplacement from "./itemReplacement.api";
import { updateClaimDetail } from "./updateClaimDetail.api";

const warrantyApi = {
  getAllWarrantySetting,
  createWarrantySetting,
  getWarrantyExtension,
  warrantySettingForm,
  createWarrantyExtension,
  warrantyNameDropdown,
  getWarrantyExtensionBatch,
  assignWarranty,
  getWarranteeList,
  getConsumerWarrantyDetail,
  verifyWarranty,
  warrantyLog,
  warranteeList,
  warrantyClaims,
  warrantyReimbursement,
  warrantyItemReplacement,
  exportWarrantyReimbursementTable,
  getWarrantyHandoffList,
  getWarrantyHandoffDetail,
  approveHandoff,
  rejectHandoff,
  updateClaimDetail,
};

export default warrantyApi;
