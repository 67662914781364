import { withStyles } from "@material-ui/core/styles";
import { Switch } from "@material-ui/core";

const AntSwitch = withStyles(theme => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    overflow: "unset"
  },
  switchBase: {
    padding: 2,
    color: theme.palette.common.white,
    // backgroundColor: "#cacaca",
    "&$disabled": {
      "& + $track": {
        opacity: 0.4,
        cursor: "not-allowed"
      }
    },
    "&$checked": {
      transform: "translateX(12px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: "#6AAF68 !important"
      }
    }
  },
  // colorSecondary: {
  //   "&$disabled": {
  //     "& + $track": {
  //       backgroundColor: "#cacaca"
  //     }
  //   }
  // },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: "none"
  },
  track: {
    borderRadius: "13px",
    opacity: 1,
    backgroundColor: "#D4D7DB !important"
  },
  checked: {},
  disabled: {
    opacity: "0.4 !important"
  }
}))(Switch);

export default AntSwitch;
