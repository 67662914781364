/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import AdtInfoAddInstructionDialogComponent from "./adtInfoAddInstructionDialog.component";
import { useDispatch, useSelector } from "react-redux";
import {
  checkHasPendingProcess,
  closeImportDialog,
} from "modules/serial-number/redux";
import { useHistory } from "react-router";
import { ADT_ADD_SN } from "modules/serial-number/config/serial-number.route";
import ImportFileDialog from "../importFileDialog";
import ImportLogDialog from "../importLogDialog";

function AdtInfoAddInstructionDialogContainer() {
  const history = useHistory();
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.constant.languages);
  const pendingProcess = useSelector(
    (state) => state.additionalInfo.pendingProcess
  );
  const isOpenAddDialog = useSelector(
    (state) => state.additionalInfo.isOpenAddDialog
  );
  const [showImportDialog, setShowImportDialog] = useState(false);
  const [showImportLog, setShowImportLog] = useState(false);

  useEffect(() => {
    if (isOpenAddDialog) {
      dispatch(checkHasPendingProcess());
    }
  }, [isOpenAddDialog]);

  const closeDialog = () => {
    dispatch(closeImportDialog());
  };

  const handleView = (mode) => {
    if (mode === "import") {
      closeDialog();
      setShowImportDialog(true);
    }
    if (mode === "manual") {
      closeDialog();
      history.push(`${ADT_ADD_SN}`);
    }
  };

  return (
    <>
      <AdtInfoAddInstructionDialogComponent
        lang={lang}
        isOpen={isOpenAddDialog}
        handleClose={closeDialog}
        handleView={handleView}
        pendingProcess={pendingProcess}
        setShowImportLog={setShowImportLog}
      />
      <ImportFileDialog
        isOpen={showImportDialog}
        handleClose={() => setShowImportDialog(false)}
        handleReload={() => {
          // setMode(2);
        }}
      />
      <ImportLogDialog
        isOpen={showImportLog}
        handleClose={() => setShowImportLog(false)}
      />
    </>
  );
}

export default AdtInfoAddInstructionDialogContainer;
