import React from "react"
import SurveyInfoCardComponent from "./surveyInfoCard.component";
import { useSelector } from "react-redux";

export default function SurveyInfoCardContainer({
  surveyDetails,
  isDetailLoading,
  language,
  openEditSurveyModal
}) {
  const lang = useSelector(state => state.constant.languages);
  return (
    <SurveyInfoCardComponent 
      surveyDetails={surveyDetails} 
      language={language}
      isDetailsLoading={isDetailLoading}
      openEditSurveyModal={openEditSurveyModal}
      lang={lang}

    />
  )
}