import axios from "axios";

var qs = require("qs");

const get = (parameters) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `/campaign/api/admin/lucky-draws/${parameters.id}/random-participants`,
        {
          params: {
            count: parameters.count,
            exclude: parameters.exclude
          },
          paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: "repeat" });
          }
        }
      )
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

const getAllRandomParticipant = {
  get
};

export default getAllRandomParticipant;
