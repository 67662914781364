import React from "react";
import InfoDialogComponent from "./infoDialog.component";
import { useSelector } from "react-redux";

function InfoDialogContainer({
  isOpen,
  handleClose,
  title,
  description,
  buttonColor,
  buttonText
}) {
  const lang = useSelector((state) => state.constant.languages);

  return (
    <InfoDialogComponent
      isOpen={isOpen}
      handleClose={handleClose}
      lang={lang}
      title={title}
      description={description}
      buttonColor={buttonColor}
      buttonText={buttonText}
    />
  );
}

export default InfoDialogContainer;
