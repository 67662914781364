import React from "react"
import {
  Box,
  Paper,
  Typography,
  makeStyles,
  withStyles,
  LinearProgress
} from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  container: {
    boxShadow: "0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);",
    borderRadius: 6,
    padding: theme.spacing(3, 2),
  },
  progressContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 9fr 2fr",
    columnGap: 16
  }
}));

const CustomLinearProgress = withStyles((theme) => ({
  root: {
    height: 24,
    borderRadius: 5
  },
  colorPrimary: {
    backgroundColor: "#F2F4F7"
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#32D583"
  }
}))(LinearProgress)

const useLinearProgressStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  }
}))

function CustomizedProgressBar({
  value
}){
  const classes = useLinearProgressStyles();

  return (
    <Box className={classes.root}>
      <CustomLinearProgress variant='determinate' value={value}/>
    </Box>
  )
}

export default function SurveyAgeCardComponent({
  title,
  data
}) {
  const classes = useStyles()

  return (
    <Paper className={classes.container}>
      <Box mb={2}>
        <Typography variant="h6" style={{ fontSize: 16 }}>{title}</Typography>
      </Box>
      {data.map((row, index) => (
        <Box className={classes.progressContainer} style={{ marginBottom: 12 }} key={index}>
          <Box>
            <Typography variant='body2' color='textPrimary'>{row.label}</Typography>
          </Box>
          <Box>
            <CustomizedProgressBar value={row.value} />
          </Box>
          <Box>
            <Typography variant='body2' color='textPrimary'>{row.value}</Typography>
          </Box>
        </Box>
      ))}
    </Paper>
  )
}