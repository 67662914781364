import React, { useState, useEffect } from "react";
import NewTagDialogComponent from "./newTagDialog.component";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import PropTypes from "prop-types";
import { useTheme } from "@material-ui/core/styles";
import { connect, useSelector } from "react-redux";
import { createTagSerialNumber } from "../../../redux";
import { fetchTagsDropdown } from "modules/serial-number/redux";
import { addAlert } from 'modules/notification';
import { unwrapResult } from "@reduxjs/toolkit"
function NewTagDialogContainer({
  isOpen,
  handleClose,
  refreshTable,
  createTagSerialNumber,
  tags,
  fetchTagsDropdown,
  addAlert
}) {

  useEffect(() => {
    fetchTagsDropdown();
  }, [fetchTagsDropdown])

  const lang = useSelector(state => state.constant.languages)

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [isSummary, setSummary] = useState(false);

  const handleSubmit = async (values, formikApi) => {
    if (!isSummary) {
      setSummary(true);
    } else {
      const newTag = {
        prefix: values.prefix,
        snDigit: values.snDigit,
        postfix: values.postfix,
        serialType: values.type
      };
      createTagSerialNumber(newTag)
        .then(unwrapResult)
        .then(() => {
          setSummary(false)
          handleClose();
          refreshTable();
        }).catch(() => { });

    }
  };

  const handleReset = () => {
    handleClose();
    setSummary(false);
  };

  const handleBack = () => {
    setSummary(false);
  };

  const handleClosePopUp = () => {
    handleClose();
    setSummary(false);
  }

  return (
    <NewTagDialogComponent
      isSummary={isSummary}
      handleSubmit={handleSubmit}
      fullScreen={fullScreen}
      open={isOpen}
      handleClose={handleClosePopUp}
      handleReset={handleReset}
      handleBack={handleBack}
      lang={lang}
      tags={tags}
      addAlert={addAlert}
    />
  );
}

NewTagDialogContainer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
};

const mapDispatchToProps = (dispatch) => ({
  createTagSerialNumber: (values) => dispatch(createTagSerialNumber(values)),
  fetchTagsDropdown: () => dispatch(fetchTagsDropdown()),
  addAlert: value => dispatch(addAlert({ severity: "warning", message: value }))
});

const mapStateToProps = state => ({
  tags: state.serialNumberTag.tagsDropdown
});

export default connect(mapStateToProps, mapDispatchToProps)(NewTagDialogContainer);
