import axios from "axios";

const get = (parameters) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/serialnumber/api/v1/serialnumbers/campaign/${parameters.id}`, {
        params: {
          length: parameters.length,
          start: parameters.start,
          search: parameters.search,
          order: parameters.order,
          column: parameters.column,
          draw: parameters.draw,
          status: parameters.status,
          enc: parameters.exclude
        },
      })
      .then((response) => resolve(response))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

const getAllSerialNumber = {
  get
};

export default getAllSerialNumber;
