import { createSlice } from "@reduxjs/toolkit";
import {
  fetchOrganisationStructureDetails,
  fetchNewEmployee,
} from './organisationStructure.action'

const ORGANISATION_STRUCTURE = "organisationStructure";
const initialState = {
  isOrganisationStructureLoading: false,
  organisationStructure: [],
  newEmployeeList: {
    data: [],
    totalRecords: 0,
    filteredTotalRecords: 0,
    hasMoretoLoad: true
  },
  isLoadingNewError: false,
};
const organisationStructureSlice = createSlice({
  name: ORGANISATION_STRUCTURE,
  initialState,
  reducers: {
    setOrganisationStructure(state, action) {
      state.organisationStructure = action.payload.organisationStructure;
    },
    expandAll(state, _) {
      const recursiveSetExpand = (node) => {
        return {
          ...node,
          expanded: true,
          showEmployees: true,
          children: node.children.map(child => recursiveSetExpand(child))
        }
      }

      state.organisationStructure = state.organisationStructure.map(node => recursiveSetExpand(node));
    },
    collapseAll(state, _) {
      const recursiveSetCollapse = (node, layer = 0) => {
        return {
          ...node,
          expanded: layer === 0,
          showEmployees: layer === 0,
          children: node.children.map(child => recursiveSetCollapse(child, layer + 1))
        }
      }

      state.organisationStructure = state.organisationStructure.map(node => recursiveSetCollapse(node));
    }
  },
  extraReducers: {
    [fetchOrganisationStructureDetails.pending]: (state, action) => {
      if (action.meta.arg.uuid) {
        let updatedOrganisationStructure = JSON.parse(JSON.stringify(state.organisationStructure))
        function updatePropertyById(id, data, property, value) {
          if (data.uuid === id) {
            data[property] = value;
          }
          if (data.children !== undefined && data.children.length > 0) {
            for (let i = 0; i < data.children.length; i++) {
              data.children[i] = updatePropertyById(id, data.children[i], property, value);
            }
          }
          return data;
        }
        updatePropertyById(action.meta.arg.uuid, { children: updatedOrganisationStructure }, "isChildrenLoaded", true);
        updatePropertyById(action.meta.arg.uuid, { children: updatedOrganisationStructure }, "expanded", false);
        updatePropertyById(action.meta.arg.uuid, { children: updatedOrganisationStructure }, "isLoading", true);
        updatePropertyById(action.meta.arg.uuid, { children: updatedOrganisationStructure }, 'employees', [])
        updatePropertyById(action.meta.arg.uuid, { children: updatedOrganisationStructure }, 'children', [{}])
        state.organisationStructure = updatedOrganisationStructure
      }else{
        state.isOrganisationStructureLoading = true
      }
    },
    [fetchOrganisationStructureDetails.fulfilled]: (state, action) => {
      let organisationStructure = []

      const recursiveGetOrganisation = (node, layer = 0) => {
        return {
          uuid: node.node_id,
          imageUrl: node.remarks === 'company' ? node.image_logo_url ? node.image_logo_url.url : null : node.image_logo_url,
          name: node.name,
          city: node.city,
          country: node.country,
          remarks: node.remarks,
          plan: node.remarks === 'company' ? node.plan : null,
          status: node.status,
          isChildrenLoaded: true,
          expanded: layer === 0,
          isLoading: false,
          showEmployees: layer === 0,
          employees: node.employee.map(
            (user) => {
              return {
                uuid: user.id,
                firstName: user.first_name,
                lastName: user.last_name,
                fullName: user.full_name,
                imageProfileUrl: user.image_profile_url ? user.image_profile_url.url : null,
                roleName: user.role_name,
                state: user.state,
              }
            }
          ),
          children: node.child.map(
            (child) => {
              return recursiveGetOrganisation(child, layer + 1)
            }
          ),
        }
      }

      for (const node of action.payload.data.nodes) {
        organisationStructure.push(recursiveGetOrganisation(node))
      }

      state.organisationStructure = organisationStructure
      state.isOrganisationStructureLoading = false
    },
    [fetchNewEmployee.fulfilled]: (state, action) => {
      const { payload } = action;
      const tempArray = [];
      state.newEmployeeList.totalRecords = payload.recordsFiltered;
      payload.data.forEach((item) => {
        tempArray.push({
          employeeId: item.id,
          employeeName: item.name,
          imageProfileUrl: item.image_profile_url,
          userGroup: item.user_group,
          nodeName: item.nodes_name,
          joinedAt: item.joined_at,
          state: item.state
        });
      });
      state.newEmployeeList.data = tempArray;
      state.newEmployeeList.hasMoretoLoad =
        state.newEmployeeList.totalRecords !==
        state.newEmployeeList.data.length;
      
      state.isLoadingNewError = false;
    },
    [fetchNewEmployee.rejected]: (state, action) => {
      state.isLoadingNewError = true;
    },
  }
});
export const organisationStructureReducer = organisationStructureSlice.reducer;
export const {
  setOrganisationStructure,
  expandAll,
  collapseAll
} = organisationStructureSlice.actions;
