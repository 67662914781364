import React, { useState, useEffect } from "react";
import DealerIncentiveProgramListingCompnent from "./listing.page";
import LoadingComponent from "components/loading";
import { useHistory } from "react-router-dom"
// import LoadingComponent from 'components/loading';
import { NEW_INCENTIVE_PROGRAM_PAGE } from "../../../config/stockflow.route"
import { useSelector } from "react-redux";
import { getLang } from "app/feature/constants";
import { STOCKFLOW_DEALER_INCENTIVE_PROGRAM_VIEW, STOCKFLOW_DEALER_INCENTIVE_PROGRAM_ADD } from "lib/constants/accessRights";

function DealerIncentiveProgramListingContainer() {
  const history = useHistory()

  const [ activeIndex, setActiveIndex ] = useState(0);
  const hasStockflowViewAccessRight = useSelector(state => state.profile.accessRights.includes(STOCKFLOW_DEALER_INCENTIVE_PROGRAM_VIEW));
  const hasStockflowAddAccessRight = useSelector(state => state.profile.accessRights.includes(STOCKFLOW_DEALER_INCENTIVE_PROGRAM_ADD));
  const lang = useSelector(state => state.constant.languages);
  const tabLabels = [getLang(lang, 'label.DEALER_INCENTIVE_POINTS')];

  useEffect(() => {
    if (!hasStockflowViewAccessRight) {
      history.push('/admin/dashboard')
    }
  }, [history, hasStockflowViewAccessRight])

  if (!hasStockflowViewAccessRight) return <LoadingComponent />;

  const handleChange = (event, index) => {
    setActiveIndex(index)
  }

  const handleAddIncentiveProgram = () => {
    history.push( NEW_INCENTIVE_PROGRAM_PAGE )
  }

  return (
    <>
      <DealerIncentiveProgramListingCompnent
        hasStockflowAddAccessRight={hasStockflowAddAccessRight}
        handleChange={handleChange}
        activeIndex={activeIndex}
        tabLabels={tabLabels}
        handleAddIncentiveProgram={handleAddIncentiveProgram}
        lang={lang}
      />
    </>
  )
}

export default DealerIncentiveProgramListingContainer