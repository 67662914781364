import {Avatar, Box, Grid, IconButton, TableCell, TableRow, Typography} from "@material-ui/core";
import {DownloadIcon, UnknownUserIcon} from "modules/reporting/assets/svg";
import { fontTextStyle, iconStyle, tableStyle } from "modules/reporting/styles";
import { drilldownPageSelection, responseHandler } from "modules/reporting/utils/helper";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { TrendingNegativeCard, TrendingPositiveCard } from "../../card";
import { ContentCard } from "../../layout";
import { ChartCardLoading, TrendingCardLoading } from "../../skeleton/loading";
import { DynamicTable } from "../../table";
import { MixedChart } from "../../charts";
import EmptyChart from "../../skeleton/global/empty-chart";

export default function ProductListingPanel({
    status,
    data,
    sort,
    pagination,
    columns,
    pageType,
    currentFilter,
    translate,
    onProductScanListExportClick
}) {

    const tableClasses = tableStyle();
    const fontClasses = fontTextStyle();
    const iconClasses = iconStyle();

    const renderData = (data) => {

        let rows = [];
        if(Object.keys(data).length === 0 || !data.isSuccess){
            return rows;
        }

        let imageProps = JSON.stringify(data);
        imageProps = JSON.parse(imageProps);

        let no_iteration = pagination.rowsPerPage * pagination.page + 1;
        imageProps.rows.forEach((item, index) => {
            let row = (
                <TableRow key={index}>
                    {/* no */}
                    <TableCell
                        className={tableClasses.table__cell}
                        align="left"
                        style={{width:"20px"}}
                    >
                        <Typography className={fontClasses.text__table_cell}>
                            {no_iteration++}
                        </Typography>
                    </TableCell>
                    {/* products */}
                    <TableCell
                        className={tableClasses.table__cell}
                        align="left"
                        size="medium"
                        style={{width:"700px"}}
                    >
                        <Box className={tableClasses.cell__product}>
                            {
                                item.image ?
                                <Avatar variant="square" alt={item.name} src={item.image} className={iconClasses.svg__icon}/>
                                :
                                <Avatar variant="square" alt={item.name} src={UnknownUserIcon} className={iconClasses.svg__icon}/>
                            }
                            <Link 
                                target="_blank" rel="noopener noreferrer"
                                className={fontClasses.text__hyperlink}
                                to={() => {

                                    let filterProperties = {};

                                    if(currentFilter.countryCode)
                                        filterProperties.countryCode = currentFilter.countryCode;
                                    
                                    if(currentFilter.cityUuid)
                                        filterProperties.city = currentFilter.cityUuid;
                                    
                                    if(currentFilter.branchUuid)
                                        filterProperties.branchUuid = currentFilter.branchUuid;

                                    if(currentFilter.period)
                                        filterProperties.period = currentFilter.period;

                                    if(currentFilter.dateRange){
                                        filterProperties.start = moment(currentFilter.dateRange[0]).format('YYYY-MM-DD');
                                        filterProperties.end = moment(currentFilter.dateRange[1]).format('YYYY-MM-DD');
                                    }

                                    if(item.uuid)
                                        filterProperties.productUuid = item.uuid;

                                    let url = drilldownPageSelection(filterProperties, pageType, true);
                                    return url;
                                }}
                            >
                                {item.name}
                            </Link>
                        </Box>

                    </TableCell>
                    {/* total count */}
                    <TableCell
                        className={tableClasses.table__cell}
                        align="center"
                        style={{width:"120px"}}
                    >
                        {/* <Box className={tableClasses.table__cell_center}>
                            <Typography className={fontClasses.text__table_cell}>
                                {item.total_count}
                            </Typography>
                            <Typography className={[fontClasses.text__table_cell, fontClasses.text__color_muted]}>
                                {item.total_percentage}
                            </Typography>
                        </Box> */}
                        <Typography className={fontClasses.text__table_cell}>
                            {item.total_count}
                        </Typography>
                    </TableCell>
                    {/* scan weightage */}
                    <TableCell
                        className={tableClasses.table__cell}
                        align="right"
                        style={{
                            minWidth: "130px",
                            width: "130px"
                        }}
                    >
                        <Typography className={fontClasses.text__table_cell}>
                            {item.total_percentage}
                        </Typography>
                    </TableCell>
                    {/* total suspended */}
                    <TableCell
                        className={tableClasses.table__cell}
                        align="right"
                        style={{width:"110px"}}
                    >
                        <Typography className={fontClasses.text__table_cell}>
                            {item.total_suspended}
                        </Typography>
                    </TableCell>
                </TableRow>
            )

            
            item.render = row;
        })
        
        return imageProps;
    }
    
    // const renderTrendingDistributionData = (data) => {
    //     if (Object.keys(data).length === 0 || !data.isSuccess){
    //         return null;
    //     }

    //     return (
    //         data.trendingSales.map((item, index) => {
    //             return (
    //                 <TrendingDistributionPerformanceCard translate={translate} index={index} title={translate("label.DISTRIBUTION_PERFORMANCE")} data={item} />
    //             )
    //         })
    //     )
    // }
    return (
        <>
            <Box margin="20px">
                <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} sm={12} md={6} >
                        {
                            responseHandler(
                                data.totalScanTrendingData, 
                                status.isFetchingTotalScanTrending, 
                                <TrendingPositiveCard translate={translate} title={translate("label.TOTAL_VALID_SCAN_QR")} data={data.totalScanTrendingData} />, 
                                <TrendingCardLoading/>, 
                                null, 
                                null, 
                                null
                            )
                        }
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} >
                        {
                            responseHandler(
                                data.totalSuspendedTrendingData,
                                status.isFetchingTotalSuspendedTrending,
                                <TrendingNegativeCard translate={translate} title={translate("label.SUSPENDED_QR")} data={data.totalSuspendedTrendingData} />,
                                <TrendingCardLoading/>,
                                null,
                                null,
                                null
                            )
                        }
                    </Grid>
                    {/* <Grid item xs={12} sm={12} md={4} >
                        <Carousel        
                            autoPlay={true}
                            interval={5000}
                            infiniteLoop={true}
                        >
                            {
                                responseHandler(
                                    data.totalDistributionPerformanceData, 
                                    status.isFetchingTotalDistributionPerformance, 
                                    renderTrendingDistributionData(data.totalDistributionPerformanceData), 
                                    <TrendingCardLoading/>, 
                                    null, 
                                    null, 
                                    null
                                )
                            }
                        </Carousel>

                    </Grid> */}
                </Grid>
            </Box>

            <Box
                marginTop="20px"
                marginBottom="20px"
            >
                <ContentCard>
                    <Box marginBottom="24px" display="flex" justifyContent="space-between" alignContent="center">
                        <Typography className={fontClasses.text__card_title}>
                            {translate("label.TOTAL_SCAN_COUNT_AGAINST_SUSPENDED_QR")}
                        </Typography>
                    </Box>
                    {/* chart */}
                    {
                        responseHandler(
                            data.scanAgainstSuspendedData,
                            status.isFetchingScanAgainstSuspendedData,
                            (
                                <MixedChart 
                                    payload={data.scanAgainstSuspendedData.chart}
                                    chartHeight="340px"
                                    chartColors={[]}
                                    dontAddUnknown={true}
                                    translate={translate}
                                    multipleYAxis={[
                                        {
                                            name: translate("label.TOTAL_SCAN_QR"),
                                            color: "#6184FF"
                                        },
                                        {
                                            name: translate("label.SUSPENDED_QR"),
                                            color: "#F97066",
                                            opposite: true
                                        }
                                    ]}
                                />
                            ),
                            (
                                <ChartCardLoading />
                            ),
                            (
                                <EmptyChart
                                    data={[translate("label.TOTAL_SCAN"), translate("label.SUSPENDED")]}
                                    title={translate("label.CUSTOMER_NOT_FOUND")}
                                    value={translate("paragraph.NO_DATA_ON_SELECTED_FILTER")}
                                    labels= {['10 July', '11 July', '12 July', '13 July']}
                                />
                            ),
                            (
                                <EmptyChart
                                    data={[translate("label.TOTAL_SCAN"), translate("label.SUSPENDED")]}
                                    title={translate("label.CUSTOMER_NOT_FOUND")}
                                    value={translate("paragraph.NO_DATA_ON_SELECTED_FILTER")}
                                    labels= {['10 July', '11 July', '12 July', '13 July']}
                                />
                            ),
                            <EmptyChart
                                data={[translate("label.TOTAL_SCAN"), translate("label.SUSPENDED")]}
                                title={translate("label.LOADING_FAILED")}
                                value={translate("paragraph.LOADING_FAILED_DESCRIPTION")}
                                labels= {['10 July', '11 July', '12 July', '13 July']}
                            />
                        )
                    }
                </ContentCard>
            </Box>

            <Box
                marginTop="20px"
                marginBottom="20px"
            >
                <ContentCard>
                    <Box marginBottom="24px" display="flex" justifyContent="space-between" alignContent="center">
                      <Typography className={fontClasses.text__card_title}>
                          {translate("label.PRODUCT_SCAN")}
                      </Typography>
                      {!status.isFetchingProductListing && data.productListingData?.rows && data.productListingData.rows.length > 0 && (
                        <Box>
                          <IconButton title="Export" aria-label="export" onClick={onProductScanListExportClick} disableRipple>
                            <DownloadIcon />
                          </IconButton>
                        </Box>
                      )}
                    </Box>

                    <DynamicTable 
                        sort={sort}
                        pagination={pagination}
                        columns={columns}
                        data={renderData(data.productListingData)}
                        status={status.isFetchingProductListing}
                        translate={translate}
                    />
                </ContentCard>

            </Box>
        </>
    )
}
