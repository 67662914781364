import axios from "axios";

export function getStockoutCustomFieldDropdown(parameters) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/distributor/admin/api/v1/stockout/custom-fields`, {
        params: {
          search: parameters.search,
        },
      })
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
}
