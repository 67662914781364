import { Box, Grid, IconButton, Typography } from "@material-ui/core";
import { DownloadIcon } from "modules/reporting/assets/svg";
import { flexGapStyle, fontTextStyle } from "modules/reporting/styles";
import { responseHandler } from "modules/reporting/utils/helper";
import React from "react";
import { TrendingSingleCampaignCard } from "../../card";
import { ContentCard } from "../../layout";
import { ChartCardLoading, TrendingCardLoading } from "../../skeleton/loading";
import downloadjs from 'downloadjs';
import html2canvas from "html2canvas";
import {  MixedChart } from "../../charts";
import EmptyChart from "../../skeleton/global/empty-chart";
import { CAMPAIGN_TYPE_INSTANTLUCKYDRAW, CAMPAIGN_TYPE_LUCKYDRAW, CAMPAIGN_TYPE_SURVEY, CAMPAIGN_TYPE_WARRANTY } from "modules/reporting/constants";

export default function CampaignTrendingPanelComponent({
    data,
    status,
    translate,
    title="campaign-performance",
    currentFilter
}){
    const fontClasses = fontTextStyle();
    const flexGapClasses = flexGapStyle();

    let buttonGroupId = title + '_buttonGroup';
    // download miscellaneous chart
    const handleDownloadCard = () => {
        let element = document.getElementById(title);

        // hide element have id = remove-this inside target
        let removeThis = element.querySelector(buttonGroupId);
        removeThis.style.display = "none";

        html2canvas(element).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            downloadjs(imgData, 'chart_'+ title +'.png');
        });

        // show element have id = remove-this inside target
        removeThis.style.display = "flex";
    }

    const getCampaignTrendingTitleCard = (campaignType) => {
        if(campaignType === CAMPAIGN_TYPE_SURVEY)
            return translate("label.TOTAL_SURVEY_RECEIVED");

        if(campaignType === CAMPAIGN_TYPE_WARRANTY)
            return translate("label.WARRANTY_REGISTRATION"); 

        return translate("label.TOTAL_PARTICIPANTS")
    }

    return (
        (
            <ContentCard>
                {/* trending --- start */}
                <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} sm={12} md={currentFilter.campaignType === CAMPAIGN_TYPE_INSTANTLUCKYDRAW || currentFilter.campaignType === CAMPAIGN_TYPE_LUCKYDRAW ? 4 : 6} >
                        {
                            responseHandler(
                                data.campaignTypeData,
                                status.isFetchingCampaignTypeData,
                                <TrendingSingleCampaignCard title={translate("label.TOTAL_VALID_SCAN_QR")} data={data.campaignTypeData} value={data.campaignTypeData.scanCount}/>,
                                <TrendingCardLoading/>,
                                null,
                                null,
                                null
                            )
                        }
                    </Grid>
                    <Grid item xs={12} sm={12} md={currentFilter.campaignType === CAMPAIGN_TYPE_INSTANTLUCKYDRAW || currentFilter.campaignType === CAMPAIGN_TYPE_LUCKYDRAW ? 4 : 6} >
                        {
                            responseHandler(
                                data.campaignTypeData,
                                status.isFetchingCampaignTypeData,
                                <TrendingSingleCampaignCard title={getCampaignTrendingTitleCard(currentFilter.campaignType)} data={data.campaignTypeData} value={data.campaignTypeData.campaignCount} />,
                                <TrendingCardLoading/>,
                                null,
                                null,
                                null
                            )
                        }
                    </Grid>
                    {
                        currentFilter.campaignType === CAMPAIGN_TYPE_INSTANTLUCKYDRAW || currentFilter.campaignType === CAMPAIGN_TYPE_LUCKYDRAW ? 
                        (
                            <Grid item xs={12} sm={12} md={4} >
                                {
                                    responseHandler(
                                        data.campaignTypeData,
                                        status.isFetchingCampaignTypeData,
                                        <TrendingSingleCampaignCard title={translate("label.PRIZE_WON")} data={data.campaignTypeData} value={data.campaignTypeData.prizeWon} />,
                                        <TrendingCardLoading/>,
                                        null,
                                        null,
                                        null
                                    )
                                }
                            </Grid>
                        ):
                        null
                    }

                </Grid>
                {/* trending --- end */}
    
                {/* campaign performance --- start */}
                <Box
                    display="flex"
                    flexDirection="column"
                >
                    {/* title and download icon/toggle icon */}
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        margin="24px 0"
                    >
                        <Typography className={fontClasses.text__card_title}>
                            {translate("label.CAMPAIGN_PERFORMANCE")}
                        </Typography>
                        <Box display="flex" alignContent="center" className={flexGapClasses.global__flexGap20} id={buttonGroupId}>
                            <IconButton title="Download Charts" aria-label="download" onClick={handleDownloadCard} disableRipple>
                                <DownloadIcon />
                            </IconButton>
                        </Box>
                    </Box>
    
                    {/* chart */}
                    {
                        responseHandler(
                            data.campaignTypeData,
                            status.isFetchingCampaignTypeData,
                            (
                                <MixedChart 
                                    payload={data.campaignTypeData.chart}
                                    chartHeight="340px"
                                    chartColors={[]}
                                    dontAddUnknown={true}
                                    translate={translate}
                                    multipleYAxis={[
                                        {
                                            name: translate("label.TOTAL_SCAN_QR"),
                                            color: "#6184FF"
                                        },
                                        {
                                            name: translate("label.CAMPAIGN_ENGAGED"),
                                            color: "#35CA91",
                                            opposite: true
                                        }
                                    ]}
                                    dashLine={true}
                                />
                            ),
                            (
                                <ChartCardLoading />
                            ),
                            (
                                <EmptyChart
                                    data={[translate("label.TOTAL_SCAN", translate("label.WARRANTY"), translate("label.SURVEY"), translate("label.LUCKY_DRAW", translate("label.INSTANT_LUCKY_DRAW"), translate("label.LOYALTY_PROGRAM")))]}
                                    title={translate("label.CUSTOMER_NOT_FOUND")}
                                    value={translate("paragraph.NO_DATA_ON_SELECTED_FILTER")}
                                    labels= {['10 July', '11 July', '12 July', '13 July']}
                                />
                            ),
                            (
                                <EmptyChart
                                    data={[translate("label.TOTAL_SCAN", translate("label.WARRANTY"), translate("label.SURVEY"), translate("label.LUCKY_DRAW", translate("label.INSTANT_LUCKY_DRAW"), translate("label.LOYALTY_PROGRAM")))]}
                                    title={translate("label.CUSTOMER_NOT_FOUND")}
                                    value={translate("paragraph.NO_DATA_ON_SELECTED_FILTER")}
                                    labels= {['10 July', '11 July', '12 July', '13 July']}
                                />
                            ),
                            <EmptyChart
                                data={[translate("label.TOTAL_SCAN", translate("label.WARRANTY"), translate("label.SURVEY"), translate("label.LUCKY_DRAW", translate("label.INSTANT_LUCKY_DRAW"), translate("label.LOYALTY_PROGRAM")))]}
                                title={translate("label.LOADING_FAILED")}
                                value={translate("paragraph.LOADING_FAILED_DESCRIPTION")}
                                labels= {['10 July', '11 July', '12 July', '13 July']}
                            />
                        )
                    }
                </Box>
            </ContentCard>
        )
    )
}