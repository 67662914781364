import axios from "axios";

// update survey details 
export function updateSurvey({
  id,
  name,
  description,
  startDate,
  endDate,
  productIds,
  questions
}) {
  return new Promise((resolve, reject) => {
    axios.put(`/consumer/admin/api/v1/survey/${id}`, {
      survey_name: name,
      survey_description: description,
      start_date: startDate,
      end_date: endDate,
      survey_questions: questions,
      product_id: productIds
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data))
  })
}