import axios from "axios";

export function getTransferItemBoxProductList(parameters) {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `/distributor/admin/api/v1/transfer/${parameters.id}/get-transfer-box-products`,
        {
          params: {
            box_uuid: parameters.box_uuid,
            type: parameters.type,
          },
        }
      )
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data));
  });
}
