import axios from "axios"

const url = "/account/api/v1/company/currency/list";

function getCurrencyConfigListing({
  length,
  start,
  column,
  order
}) {
  return new Promise((resolve, reject) => {
    axios.get(url, {
      params: {
        length,
        start,
        column,
        order
      }
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data))
  })
}

export default getCurrencyConfigListing;