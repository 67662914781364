import React from "react";
import {
  makeStyles,
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination, Link,
} from "@material-ui/core";
import { SkeletonTableRow } from 'components/skeleton/skeleton.component';
import { StyledTableCell, StyledTableRow } from "components/table";
import { TablePaginationActions } from "components/tablePagination";
import Alert from "@material-ui/lab/Alert";
import ActionButton from "components/button/actionDetailButton";
import RefreshTable from "components/table/refreshTable";
import { getLang } from "app/feature/constants";
import StickyLeftTableCell from "components/table/stickyLeftTableCell";
import StickyRightTableCell from "components/table/stickyRightTableCell";
import ProductCountDetailPopover from "../../../../../components/popover/productCountDetailPopover";
import moment from 'moment';
import 'moment/min/locales.min';

const useStyle = makeStyles((theme) => ({
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    borderCollapse: "separate",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
    },
    "& th,td": {
      fontSize: ".75rem",
      borderRight: "1px solid rgba(58, 77, 84, 0.1)",
      padding: theme.spacing(1, 2, 1, 2),
    },
    "& th": {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightBold,
    },
    "& th:last-child, td:last-child": {
      width: 37,
      borderRight: "0px",
    },
    "& th th": {
      borderBottom: "none !important",
    },
    "& td td": {
      borderBottom: "none !important",
    },
  },
  emptyContainer: {
    textAlign: "center"
  },
  attributeText: {
    color: "#b2b2b2",
    fontSize: 12,
    whiteSpace: "break-spaces",
  },
}));

export default function WarrantyListTableComponent({
  hasWarrantyAddAccessRight,
  hasWarrantyEditAccessRight,
  hasWarranteeViewAccessRight,
  rows,
  isFetching,
  page,
  rowsPerPage,
  language,
  handlePageChange,
  handleRowPerPageChange,
  length,
  search,
  handleView,
  handleViewWarrantee,
  handleCreate,
  handleReload,
  isLoadingWarrantyError,
  paginationOptions,
  lang
}) {
  const classes = useStyle();
  
  if(language==="ZH")
  {
    moment.locale('zh-cn')
  }else if(language==="TL")
  {
    moment.locale('tl-ph')
  }else
  {
    moment.locale(language)
  }


  return (
    <>
      {rows.length > 0 || isFetching ? (
        <TableContainer>
          <Table
            className={classes.table}
            size="small"
            aria-label="product table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <StickyLeftTableCell>
                  <StyledTableCell>{getLang(lang, 'label.NUM')}</StyledTableCell>
                </StickyLeftTableCell>
                <StyledTableCell>{getLang(lang, 'label.WARRANTY_NAME')}</StyledTableCell>
                <StyledTableCell>{getLang(lang, 'label.WARRANTY_PERIOD')}</StyledTableCell>
                <StyledTableCell>{getLang(lang, 'label.PRODUCTS')}</StyledTableCell>
                <StyledTableCell>{getLang(lang, 'label.CONSUMER')}</StyledTableCell>
                <StyledTableCell>{getLang(lang, 'label.DATE_CREATED')}</StyledTableCell>
                <StyledTableCell>{getLang(lang, 'label.STATUS')}</StyledTableCell>
                <StickyRightTableCell>
                  <StyledTableCell>{getLang(lang, 'label.ACTION')}</StyledTableCell>
                </StickyRightTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isFetching
                ? [...new Array(5)].map((i, index) => (
                  <SkeletonTableRow key={index} columnCount={8} />
                ))
                : rows.map((row, index) => {
                  const actionItems = []

                  actionItems.push({
                      label: hasWarrantyEditAccessRight ? getLang(lang, 'label.VIEW_EDIT') : getLang(lang, 'label.VIEW'),
                      action: () => handleView(row.id)
                    },
                    {
                      label: getLang(lang, 'label.VIEW_WARRANTEE'),
                      disabled: !hasWarranteeViewAccessRight || row.totalCount === 0,
                      action: () => handleViewWarrantee(row.id)
                    })

                  return (
                    <StyledTableRow
                      key={index}
                      hover
                      role="checkbox"
                      tabIndex={-1}
                    >
                      <StickyLeftTableCell>
                        <StyledTableCell component="th" scope="row" style={{ borderBottom: "0px" }}>
                          {index + 1 + rowsPerPage * page}
                        </StyledTableCell>
                      </StickyLeftTableCell>
                      <StyledTableCell>{row.warrantyName}</StyledTableCell>
                      <StyledTableCell>
                        {row.durationMonth} {getLang(lang, 'label.MONTH')}
                      </StyledTableCell>
                      <ProductCountDetailPopover
                        products={row.products}
                        itemTranslator={product => ({
                          id: product.id,
                          name: product.name,
                          imageUrl: product.image_url,
                          attributes: product.attributes,
                        })}
                      />
                      <StyledTableCell>
                        {row.totalCount > 0 ? (
                          <Link
                            color="secondary"
                            href="#"
                            onClick={(event) => {
                              event.preventDefault();
                              handleViewWarrantee(row.id);
                            }}
                          >
                            {row.totalCount.toLocaleString("en-US")}
                          </Link>
                        ) : (
                          <Box>{row.totalCount.toLocaleString("en-US")}</Box>
                        )}
                      </StyledTableCell>
                      <StyledTableCell>
                        {moment(row.createdAt).format('ll')}
                      </StyledTableCell>
                      <StyledTableCell style={{color: row.status === "active" ? "green" : "grey"}}>
                        {row.status === "active" ? getLang(lang, 'label.ACTIVE') : getLang(lang, 'label.DEACTIVE')}
                        {/* QUICK FIX FOR LANGUAGES TRANSLATION */}
                      </StyledTableCell>
                      <StickyRightTableCell>
                        <StyledTableCell>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center"
                            }}
                          >
                            <ActionButton actionItems={actionItems} popperStyle={{ zIndex: 999 }} />
                          </div>
                        </StyledTableCell>
                      </StickyRightTableCell>
                    </StyledTableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : !search && !isLoadingWarrantyError ? (
        <Box style={{overflowX: 'auto', backgroundColor: '#fff'}}>
          <Table
            className={classes.table}
            size="small"
            aria-label="product table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <StickyLeftTableCell>
                  <StyledTableCell>{getLang(lang, 'label.NUM')}</StyledTableCell>
                </StickyLeftTableCell>
                <StyledTableCell>{getLang(lang, 'label.WARRANTY_NAME')}</StyledTableCell>
                <StyledTableCell>{getLang(lang, 'label.WARRANTY_PERIOD')}</StyledTableCell>
                <StyledTableCell>{getLang(lang, 'label.PRODUCTS')}</StyledTableCell>
                <StyledTableCell>{getLang(lang, 'label.CONSUMER')}</StyledTableCell>
                <StyledTableCell>{getLang(lang, 'label.DATE_CREATED')}</StyledTableCell>
                <StyledTableCell>{getLang(lang, 'label.STATUS')}</StyledTableCell>
                <StickyRightTableCell>
                  <StyledTableCell>{getLang(lang, 'label.ACTION')}</StyledTableCell>
                </StickyRightTableCell>
              </TableRow>
            </TableHead>
          </Table>
          <Box py={9} className={classes.emptyContainer}>
            <Typography variant="body1">
              {getLang(lang, 'paragraph.YOU_DONT_HAVE_WARRANTY')}
            </Typography>
            {
              hasWarrantyAddAccessRight
                ?
                <Box mt={2}>
                  <Button
                    onClick={handleCreate}
                    variant="contained"
                    disableElevation
                    color="primary"
                  >
                    {getLang(lang, 'label.ADD_WARRANTY')}
                  </Button>
                </Box>
                : null
            }
          </Box>
        </Box>

      ) : null}

      {!isFetching && rows.length === 0 && search && (
        <Alert severity="info">{getLang(lang, 'label.NO_WARRANTY_LIST')}</Alert>
      )}

      {!isFetching && isLoadingWarrantyError && (
        <RefreshTable handleReload={handleReload} />
      )}

      {!isFetching && rows.length > 0 && (
        <TablePagination
          style={{
            backgroundColor: "#fff",
            boxShadow: "0px -1px 0px rgba(58, 77, 84, 0.15)"
          }}
          ActionsComponent={TablePaginationActions}
          rowsPerPageOptions={paginationOptions}
          component="div"
          count={length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowPerPageChange}
          labelRowsPerPage={getLang(lang, 'label.ROWS_PER_PAGE')}
          labelDisplayedRows={({ from, to, count }) => `${from.toLocaleString("en-US")}-${to.toLocaleString("en-US")} ${getLang(lang, 'label.OF')} ${count.toLocaleString("en-US")}`}
        />
      )}
    </>
  );
}
