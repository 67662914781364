import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Paper,
  Typography,
  Divider,
  makeStyles,
  Tabs,
  FormControl,
  MenuItem,
  Select,
  Grid,
  withStyles,
  InputBase
} from "@material-ui/core";
import InfoHeader from "../../infoHeader";
import CustomTab from "components/tab";
import OverallTable from "../../table/overallTable";

const style = (theme) => ({
  paper: {
    backgroundColor: "#F4F6F8"
  },
  content: {
    width: "100%",
    padding: theme.spacing(0, 6)
  },
  table: {
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(5, 0, 5, 0)
    }
  },
  monthSelected: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      paddingRight: theme.spacing(2)
    }
  },
  scroll: {
    width: "min-content"
  }
});

const useStyles = makeStyles(style);

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(1)
    }
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "#F4F6F8",
    border: "1px solid #3A4D54",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)"
    }
  }
}))(InputBase);

export default function OrderHistoryPanelComponent({
  handleChange,
  activeIndex,
  tabLabels,
  yearlist,
  language,
  selectedYear,
  handleControl,
  lang
}) {
  const classes = useStyles();

  function a11yProps(index, label = "label") {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
      label: <Typography variant="button" children={label} />,
    };
  }

  function TabPanel({ children, value, index, ...other }) {
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {activeIndex === index && <Box>{children}</Box>}
      </Typography>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
  };

  return (
    <Box>
      <InfoHeader />
      <Paper elevation={0} className={classes.paper}>
        <Divider />
        <Box style={{ display: "flex", justifyContent: "center" }}>
          <Box className={classes.content}>
            <Grid container>
              <Grid item xs={8} sm={7} md={7} style={{ width: "100%" }}>
                <Tabs
                  value={activeIndex}
                  onChange={handleChange}
                  aria-label="scrollable auto tabs example"
                  textColor="primary"
                  variant="scrollable"
                  indicatorColor="primary"
                  classes={{
                    indicator: classes.indicator,
                    scrollButtons: classes.scroll
                  }}
                >
                  <CustomTab {...a11yProps(0, tabLabels[0])} />
                  <CustomTab {...a11yProps(1, tabLabels[1])} />
                  <CustomTab {...a11yProps(2, tabLabels[2])} />
                </Tabs>
              </Grid>

              <Grid item xs={4} sm={5} md={5} className={classes.monthSelected}>
                <FormControl variant="outlined" style={{ marginTop: "0.2rem" }}>
                  <Select
                    value={selectedYear}
                    onChange={handleControl}
                    label="Year"
                    input={<BootstrapInput />}
                  >
                    {yearlist.map((value, index) => (
                      <MenuItem value={value} key={index}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Divider />

        <div style={{ display: "flex", justifyContent: "center" }}>
          <Box className={classes.content}>
            <TabPanel value={activeIndex} index={0}>
              <div className={classes.table}>
                <OverallTable selectedYear={selectedYear} module="" lang={lang}/>
              </div>
            </TabPanel>
            <TabPanel value={activeIndex} index={1}>
              <div className={classes.table}>
                <OverallTable
                  selectedYear={selectedYear}
                  module="serial_number"
                  lang={lang}
                />
              </div>
            </TabPanel>
            <TabPanel value={activeIndex} index={2}>
              <div className={classes.table}>
                <OverallTable selectedYear={selectedYear} module="sms" lang={lang} language={language}/>
              </div>
            </TabPanel>
          </Box>
        </div>
      </Paper>
    </Box>
  );
}
