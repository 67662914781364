import React from "react";

import {
  Box,
  Typography,
  Grid,
  Card,
  Divider,
  Link,
  Button,
  makeStyles,
  Chip,
  CircularProgress,
} from "@material-ui/core";
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

import { getLang } from "app/feature/constants";
import { getDate } from "lib/generalUtility";
import moment from "moment/moment";
import 'moment/min/locales.min';

import { ROOT } from "modules/serial-number/config/serial-number.route";
import { useHistory } from "react-router-dom";
import { Edit as EditIcon } from "@material-ui/icons";
import DateTimePicker from "components/input/dateTimePicker";
import { Formik } from "formik";
import InputTextField from "components/input/inputTextField";
import DatePickerScrollDialog from "modules/serial-number/components/dialog/datePickerScrollDialog";
import CountrySelect from "components/select/countrySelect";
import BoxIcon from "assets/img/box_dark.png";
import country from "lib/constants/countries";
import DOMPurify from "dompurify";

const style = (theme) => ({
  root: {
    width: "50rem",
    maxWidth: "100%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  dateContainer: {
    height: 43,
    backgroundColor: "#ECEFF0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    borderRadius: "4px 0px 0px 4px",
  },
  divider: {
    height: "100%",
    display: "flex",
    alignSelf: "center",
  },
  dividerContainer: {
    backgroundColor: "#ECEFF0",
    display: "flex",
  },
  footer: {
    position: "sticky",
    bottom: 0,
    width: "100%",
    backgroundColor: "white",
  },
  rejectButton: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.light,
    "&:hover": {
      borderColor: theme.palette.error.dark,
    },
  },
  attributeText: {
    color: "#b2b2b2",
    fontSize: 12,
    whiteSpace: "break-spaces",
  },
  prodImage: {
    // borderRadius: "50%",
    border: "1px solid",
    borderColor: theme.palette.divider,
    height: 54,
    width: 54,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    "& img": {
      height: "100%",
      minHeight: "100%",
      minWidth: "100%",
      objectFit: "cover",
      flexShrink: 0
    }
  },
});

const useStyles = makeStyles(style);

export default function ConsumerClaimDetailPanelComponent({
  lang,
  profileLang,
  claim,
  language,
  handleView,
  claimReasonOptions,
  handleMediaClick,
  onRejectClick,
  onApproveClick,
  hasClaimListEditAccessRight,
  setIsEdit,
  isEdit,
  handleUpdate,
  isUpdating,
  showAlert,
}) {
  const history = useHistory();
  const classes = useStyles();
  // const warranteeMetadataMap = {
  //   "name": getLang(lang, "label.NAME"),
  //   "ic": getLang(lang, "label.IC_NUMBER"),
  //   "email": getLang(lang, "label.EMAIL"),
  //   "phone": getLang(lang, "label.PHONE_NUMBER"),
  //   "purchased_at": getLang(lang, "label.DATE_OF_PURCHASE"),
  //   "place_of_purchase": getLang(lang, "label.PLACE_OF_PURCHASE"),
  //   "proof_of_purchase": getLang(lang, "label.PROOF_OF_PURCHASE"),
  // }

  if (language === "ZH") {
    moment.locale('zh-cn')
  } else if (language === "TL") {
    moment.locale('tl-ph')
  } else {
    moment.locale(language)
  }

  const editableMetadataFields = [
    "phone",
    "purchased_at",
    "place_of_purchase",
    "proof_of_purchase",
  ];

  const editableMetadataName = ["Proof of Purchase (DO NUMBER)"];

  const generateLink = (remark) => {
    let urlRegex = /(https?:\/\/[^\s]+)/g;
    let textWithLink = remark.replace(urlRegex, (url) => {
      return '<a href="' + url + '" target="_blank">' + url + "</a>";
    });

    const sanitizedHtmlContent = DOMPurify.sanitize(textWithLink);

    return (
      <Box
        dangerouslySetInnerHTML={{
          __html: sanitizedHtmlContent,
        }}
      />
    );
  };

  return (
    <>
      <Box
        className={classes.root}
        display="flex"
        flexDirection="column"
        height="100%"
      >
        <Formik
          initialValues={{
            remark: claim.remark,
            metadata: claim.warranteeMetadata,
            warrantyDuration: claim.warrantyDuration,
            warrantyEnd: claim.warrantyEnd,
            errorField: {},
          }}
        >
          {(formik) => (
            <>
              <Box maxHeight="75vh" overflow="auto">
                <Box p={3}>
                  <Box pb={2}>
                    <Typography
                      variant="body2"
                      color="primary"
                      style={{ fontWeight: "bold" }}
                    >
                      {getLang(lang, "label.DETAILS")}
                    </Typography>
                  </Box>

                  <Box>
                    <Grid container>
                      <Grid item xs={4}>
                        {getLang(lang, "label.STATUS")}
                      </Grid>

                      <Grid item xs={8}>
                        {claim.status === 1 && (
                          <Box
                            fontWeight="fontWeightBold"
                            bgcolor="warning.main"
                            color="#FFFFFF"
                            p={1}
                            py={0.5}
                            borderRadius={4}
                            height="min-content"
                            width="min-content"
                          >
                            {getLang(lang, "label.PENDING")}
                          </Box>
                        )}
                        {claim.status === 2 && (
                          <Box
                            fontWeight="fontWeightBold"
                            bgcolor="secondary.main"
                            color="#FFFFFF"
                            p={1}
                            py={0.5}
                            borderRadius={4}
                            height="min-content"
                            width="min-content"
                          >
                            {getLang(lang, "label.APPROVED")}
                          </Box>
                        )}
                        {claim.status === 3 && (
                          <Box
                            fontWeight="fontWeightBold"
                            bgcolor="error.main"
                            color="#FFFFFF"
                            p={1}
                            py={0.5}
                            borderRadius={4}
                            height="min-content"
                            width="min-content"
                          >
                            {getLang(lang, "label.REJECTED")}
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                  </Box>

                  {claim.referenceCode.length > 0 && (
                    <Box mt={2}>
                      <Grid container mt={2}>
                        <Grid item xs={4}>
                          {getLang(lang, "label.CHECKOUT_BRANCH")}
                        </Grid>
                        <Grid item xs={8}>
                          <Box fontWeight="fontWeightBold">
                            {claim.checkoutBranch ?? "-"}
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  )}

                  <Box mt={2}>
                    <Grid container mt={2}>
                      <Grid item xs={4}>
                        {getLang(lang, "label.REFERENCE_CODE")}
                      </Grid>
                      <Grid item xs={8}>
                        <Box fontWeight="fontWeightBold">
                          {claim.referenceCode}
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box mt={2}>
                    <Grid container mt={2}>
                      <Grid item xs={4}>
                        {getLang(lang, "label.SUBMISSION_DATE")}
                      </Grid>
                      <Grid item xs={8}>
                        <Box fontWeight="fontWeightBold">
                          {moment(claim.createdAt).format("lll")}
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>

                  {claim.medias &&
                    claim.medias.videoFile &&
                    claim.medias.imageFile && (
                      <Box mt={2}>
                        <Grid container>
                          <Grid item xs={4}>
                            {getLang(lang, "label.UPLOADED_FILE")}
                          </Grid>
                          <Grid item xs={8}>
                            <Box
                              py={1}
                              mb={1}
                              display="flex"
                              flexWrap="no-wrap"
                              style={{ overflowX: "auto" }}
                            >
                              {claim.medias.videoFile.length > 0 &&
                                claim.medias.videoFile.map((file) => (
                                  <Box mr={1}>
                                    <Card>
                                      <Box height="70px" width="70px">
                                        <video
                                          width="70"
                                          height="70"
                                          preload="metadata"
                                          muted
                                          onClick={() =>
                                            handleMediaClick(file, "video")
                                          }
                                        >
                                          <source src={file} />
                                        </video>
                                      </Box>
                                    </Card>
                                  </Box>
                                ))}
                              {claim.medias.imageFile.length > 0 &&
                                claim.medias.imageFile.map((file, i) => (
                                  <Box mr={1} key={i}>
                                    <Card>
                                      <Box height="70px" width="70px">
                                        <img
                                          height="70px"
                                          width="70px"
                                          style={{ objectFit: "contain" }}
                                          src={file}
                                          alt="img"
                                          onClick={() =>
                                            handleMediaClick(file, "image")
                                          }
                                        />
                                      </Box>
                                    </Card>
                                  </Box>
                                ))}
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    )}
                  {claim.claimReasonOptions &&
                    claim.claimReasonOptions.length > 0 &&
                    Object.keys(claim.claimReasonOptions).length > 0 && (
                      <Box mt={2}>
                        <Grid container>
                          <Grid item xs={4}>
                            {getLang(lang, "label.CLAIM_REASONS")}
                          </Grid>
                          <Grid item xs={8}>
                            <Box fontWeight="fontWeightBold">
                              {claim.claimReasonOptions.map((item) => (
                                <Box>
                                  {item.id === claim.claimReasonId ? (
                                    <span>
                                      {item.reasonName[profileLang] ||
                                        item.reasonName["EN"]}
                                    </span>
                                  ) : null}
                                </Box>
                              ))}
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    )}

                  <Box mt={2}>
                    <Grid container mt={2}>
                      <Grid item xs={4}>
                        {getLang(lang, "label.CLAIM_ADDRESS")}
                      </Grid>
                      <Grid item xs={8}>
                        <Box fontWeight="fontWeightBold">
                          {claim.claimAddress}
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box mt={2}>
                    <Grid container>
                      <Grid item xs={4}>
                        {getLang(lang, "label.REMARK")}
                      </Grid>
                      <Grid item xs={8}>
                        {isEdit ? (
                          <InputTextField
                            required
                            multiline
                            fullWidth
                            rows={6}
                            disabled={isUpdating}
                            name="remark"
                            placeholder={getLang(
                              lang,
                              "placeholder.ENTER_REMARKS_HERE"
                            )}
                            value={formik.values.remark}
                            onChange={(e) => {
                              formik.setFieldValue("remark", e.target.value);

                              if (
                                e.target.value &&
                                Object.hasOwn(
                                  formik.values.errorField,
                                  "remark"
                                )
                              ) {
                                let error = formik.values.errorField;
                                delete error["remark"];
                                formik.setFieldValue("errorField", error);
                              }
                            }}
                            inputProps={{
                              style: {
                                fontSize: "16px",
                                padding: "7px",
                              },
                            }}
                            InputStyle={{
                              backgroundColor: "white",
                              border: "1px solid #d0d5dd",
                            }}
                          />
                        ) : (
                          <Box
                            fontWeight="fontWeightBold"
                            style={{ whiteSpace: "break-spaces" }}
                          >
                            {claim.remark ? generateLink(claim.remark) : "-"}
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                </Box>

                {claim.rejectedReason && claim.rejectedReason.length > 0 && (
                  <>
                    <Divider />
                    <Box p={3}>
                      <Box>
                        <Box mt={2}>
                          <Grid container>
                            <Grid item xs={4}>
                              {getLang(lang, "label.CLAIM_REJECTED_REASON")}
                            </Grid>
                            <Grid item xs={8}>
                              <Box fontWeight="fontWeightBold">
                                {claim.rejectedReason}
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                        <Box mt={2}>
                          <Grid container>
                            <Grid item xs={4}>
                              {getLang(lang, "label.RESPONSE_DATE")}
                            </Grid>
                            <Grid item xs={8}>
                              <Box fontWeight="fontWeightBold">
                                {moment(claim.updatedAt).format("lll")}
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                    </Box>
                  </>
                )}

                <Divider />
                <Box p={3}>
                  <Box>
                    <Typography
                      variant="body2"
                      color="primary"
                      style={{ fontWeight: "bold" }}
                    >
                      {getLang(lang, "label.WARRANTEE_DETAILS")}
                    </Typography>
                  </Box>

                  {claim.warranteeMetadata.map((field, index) => {
                    return (
                      <Box key={index} pt={2}>
                        <Grid container>
                          <Grid item xs={4} style={{ width: "100%" }}>
                            <Typography variant="body2" color="primary">
                              {field.variantName}
                            </Typography>
                          </Grid>
                          {field.type === "uploadImage" ? (
                            <Grid item xs={8}>
                              <Box
                                py={1}
                                mb={1}
                                display="flex"
                                flexWrap="no-wrap"
                                style={{ overflowX: "auto" }}
                              >
                                {field.value && (
                                  <Box mr={1}>
                                    <Card>
                                      <Box height="70px" width="70px">
                                        <img
                                          height="70px"
                                          width="70px"
                                          style={{ objectFit: "contain" }}
                                          src={field.value}
                                          alt="img"
                                          onClick={() =>
                                            handleMediaClick(
                                              field.value,
                                              "image"
                                            )
                                          }
                                        />
                                      </Box>
                                    </Card>
                                  </Box>
                                )}
                              </Box>
                            </Grid>
                          ) : (
                            <Grid
                              item
                              xs={8}
                              style={{ width: "100%", display: "flex" }}
                            >
                              {isEdit &&
                                (editableMetadataFields.includes(field.key) ||
                                  (field.key?.startsWith("custom_") &&
                                    field.type === "textField")) ? (
                                field.key === "phone" ? (
                                  <Box display="flex">
                                    <CountrySelect
                                      type="phone"
                                      textFieldProps={{
                                        required: true,
                                        style: { width: 200 },
                                        inputPropStyle: {
                                          backgroundColor: "white",
                                          border: "1px solid #d0d5dd",
                                        },
                                        disabled: isUpdating,
                                        variant: "filled",
                                        size: "small",
                                        error:
                                          (typeof formik.values.metadata[index]
                                            .value === "object"
                                            ? !formik.values.metadata[index]
                                              .value.contactCode
                                            : true) &&
                                          Object.hasOwn(
                                            formik.values.errorField,
                                            field.key
                                          ),
                                        helperText:
                                          (typeof formik.values.metadata[index]
                                            .value === "object"
                                            ? !formik.values.metadata[index]
                                              .value.contactCode
                                            : true) &&
                                            Object.hasOwn(
                                              formik.values.errorField,
                                              field.key
                                            )
                                            ? getLang(
                                              lang,
                                              "label.REQUIRED_FIELD"
                                            )
                                            : "",
                                      }}
                                      formProps={{
                                        ...formik.getFieldProps("contactCode"),
                                        value:
                                          typeof formik.values.metadata[index]
                                            .value === "object"
                                            ? country.find(
                                              ({ phone }) =>
                                                phone ===
                                                formik.values.metadata[index]
                                                  .value.contactCode
                                            )
                                            : null,
                                        onChange: (e, newValue) => {
                                          let temp = [
                                            ...formik.values.metadata,
                                          ];
                                          temp[index] = {
                                            ...temp[index],
                                            value:
                                              typeof formik.values.metadata[
                                                index
                                              ].value === "object"
                                                ? {
                                                  ...temp[index].value,
                                                  contactCode: newValue.phone,
                                                }
                                                : {
                                                  contactNumber:
                                                    formik.values.metadata[
                                                      index
                                                    ].value,
                                                  contactCode: newValue.phone,
                                                },
                                            is_edited: true,
                                          };
                                          formik.setFieldValue(
                                            "metadata",
                                            temp
                                          );

                                          if (
                                            newValue &&
                                            formik.values.metadata[index].value
                                              .contactNumber &&
                                            Object.hasOwn(
                                              formik.values.errorField,
                                              field.key
                                            )
                                          ) {
                                            let error =
                                              formik.values.errorField;
                                            delete error[field.key];
                                            formik.setFieldValue(
                                              "errorField",
                                              error
                                            );
                                          }
                                        },
                                        disabled: isUpdating,
                                      }}
                                    />
                                    <InputTextField
                                      required
                                      onKeyPress={(e) => {
                                        if (!/[0-9]/.test(e.key)) {
                                          e.preventDefault();
                                        }
                                      }}
                                      InputStyle={{
                                        backgroundColor: "white",
                                        border: "1px solid #d0d5dd",
                                      }}
                                      disabled={isUpdating}
                                      name={field.key}
                                      hiddenLabel
                                      variant="filled"
                                      size="small"
                                      fullWidth
                                      value={
                                        typeof formik.values.metadata[index]
                                          .value === "object"
                                          ? formik.values.metadata[index].value
                                            .contactNumber
                                          : formik.values.metadata[index].value
                                      }
                                      onChange={(e) => {
                                        let temp = [...formik.values.metadata];
                                        temp[index] = {
                                          ...temp[index],
                                          value:
                                            typeof formik.values.metadata[index]
                                              .value === "object"
                                              ? {
                                                ...temp[index].value,
                                                contactNumber: e.target.value,
                                              }
                                              : {
                                                contactNumber: e.target.value,
                                                contactCode: "",
                                              },
                                          is_edited: true,
                                        };
                                        formik.setFieldValue("metadata", temp);

                                        if (
                                          e.target.value &&
                                          formik.values.metadata[index].value
                                            .contactCode &&
                                          Object.hasOwn(
                                            formik.values.errorField,
                                            field.key
                                          )
                                        ) {
                                          let error = formik.values.errorField;
                                          delete error[field.key];
                                          formik.setFieldValue(
                                            "errorField",
                                            error
                                          );
                                        }
                                      }}
                                      error={
                                        (typeof formik.values.metadata[index]
                                          .value === "object"
                                          ? !formik.values.metadata[index].value
                                            .contactNumber
                                          : !formik.values.metadata[index]
                                            .value) &&
                                        Object.hasOwn(
                                          formik.values.errorField,
                                          field.key
                                        )
                                      }
                                      helperText={
                                        (typeof formik.values.metadata[index]
                                          .value === "object"
                                          ? !formik.values.metadata[index].value
                                            .contactNumber
                                          : !formik.values.metadata[index]
                                            .value) &&
                                          Object.hasOwn(
                                            formik.values.errorField,
                                            field.key
                                          )
                                          ? getLang(
                                            lang,
                                            "label.REQUIRED_FIELD"
                                          )
                                          : ""
                                      }
                                    />
                                  </Box>
                                ) : field.type === "datePicker" ? (
                                  <DatePickerScrollDialog
                                    label=""
                                    handleChange={(value) => {
                                      let temp = [...formik.values.metadata];
                                      temp[index] = {
                                        ...temp[index],
                                        value: value.format("DD MMMM YYYY"),
                                        is_edited: true,
                                      };
                                      formik.setFieldValue("metadata", temp);
                                    }}
                                    InputProps={{
                                      style: {
                                        backgroundColor: "white",
                                        border: "1px solid #d0d5dd",
                                        height: 43,
                                      },
                                    }}
                                    value={formik.values.metadata[index].value}
                                    displayFormat="DD MMMM YYYY"
                                    disabled={isUpdating}
                                  />
                                ) : field.type === "textField" ? (
                                  <InputTextField
                                    fullWidth
                                    required
                                    name={field.key}
                                    placeholder={getLang(
                                      lang,
                                      "placeholder.ENTER_VALUE_HERE"
                                    )}
                                    value={formik.values.metadata[index].value}
                                    onChange={(e) => {
                                      let temp = [...formik.values.metadata];
                                      temp[index] = {
                                        ...temp[index],
                                        value: e.target.value,
                                        is_edited: true,
                                      };
                                      formik.setFieldValue("metadata", temp);

                                      if (
                                        e.target.value &&
                                        Object.hasOwn(
                                          formik.values.errorField,
                                          field.key
                                        )
                                      ) {
                                        let error = formik.values.errorField;
                                        delete error[field.key];
                                        formik.setFieldValue(
                                          "errorField",
                                          error
                                        );
                                      }
                                    }}
                                    inputProps={{
                                      style: {
                                        fontSize: "16px",
                                        padding: "7px",
                                      },
                                    }}
                                    InputStyle={{
                                      backgroundColor: "white",
                                      border: "1px solid #d0d5dd",
                                    }}
                                    disabled={isUpdating}
                                    error={
                                      !formik.values.metadata[index].value &&
                                      Object.hasOwn(
                                        formik.values.errorField,
                                        field.key
                                      )
                                    }
                                    helperText={
                                      !formik.values.metadata[index].value &&
                                        Object.hasOwn(
                                          formik.values.errorField,
                                          field.key
                                        )
                                        ? getLang(lang, "label.REQUIRED_FIELD")
                                        : ""
                                    }
                                  />
                                ) : (
                                  <Typography>
                                    <b>{field.value}</b>
                                  </Typography>
                                )
                              ) : (
                                <>
                                  {field.type === "contactNumber" ? (
                                    <Typography>
                                      <Box fontWeight="fontWeightBold">
                                        {field.value?.contactCode}{" "}
                                        {field.value?.contactNumber}
                                      </Box>
                                    </Typography>
                                  ) : typeof field.value === "string" &&
                                    field.value.includes("https://") ? (
                                    <Link
                                      variant="body2"
                                      href="#"
                                      color="primary"
                                      onClick={() => handleView(field.value)}
                                      underline="always"
                                      style={{
                                        color: "royalblue",
                                      }}
                                    >
                                      {getLang(lang, "label.VIEW")}
                                    </Link>
                                  ) : Array.isArray(field.value) ? (
                                    <ul
                                      style={{
                                        margin: "0",
                                        padding: "0",
                                        paddingLeft: "16px",
                                        listStyle: "square",
                                      }}
                                    >
                                      {field.value.map((item) => {
                                        return (
                                          <li>
                                            {" "}
                                            <Typography
                                              variant="body2"
                                              color="primary"
                                            >
                                              {item}
                                            </Typography>
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  ) : (
                                    <Typography>
                                      <b>{field.value}</b>
                                    </Typography>
                                  )}
                                </>
                              )}
                            </Grid>
                          )}
                        </Grid>
                      </Box>
                    );
                  })}
                </Box>

                <Divider />

                <Box p={3}>
                  <Box>
                    {claim.replacement ? (
                      <>
                        <Typography
                          variant="body2"
                          color="primary"
                          style={{ fontWeight: "bold" }}
                        >
                          {getLang(lang, "label.REPLACEMENT_DETAILS")}
                        </Typography>
                        <Grid
                          container
                          spacing={2}
                          style={{ marginTop: "1rem" }}
                        >
                          <Box display="flex" flexDirection="column" py={1} px={2}>
                            <Box display="flex" flexDirection="row" alignItems="center" flexWrap="wrap" marginBottom={1}>
                              <img src={BoxIcon} alt="Box" style={{ marginRight: "8px", width: "20px" }} />
                              <Typography
                                variant="body2"
                                color="primary"
                                style={{ fontWeight: "bold" }}
                              >
                                {getLang(lang, "label.FROM")}
                              </Typography>
                            </Box>
                            <Box display="flex" flexDirection="row" alignItems="center" flexWrap="wrap">
                              <Box className={classes.prodImage}>
                                <img src={claim.productImage} alt="product" />
                              </Box>
                              <Box ml={2} overflow="hidden" maxWidth={190}>
                                <Typography variant="body2" style={{ wordWrap: "break-word" }}>
                                  <b>{claim.productName}</b>
                                  <span style={{ color: "grey" }}>
                                    {!!claim.productNameOld &&
                                      ` (${claim.productNameOld})`}
                                  </span>
                                </Typography>
                                {!!claim.attributes &&
                                  !!claim.attributes.length && (
                                    <Typography
                                      className={classes.attributeText}
                                      variant="body2"
                                    >
                                      {claim.attributes.join(", ")}
                                    </Typography>
                                  )}
                                <Link
                                  href={`${ROOT}/details/${claim.replacement.from_enc}`}
                                  color="secondary"
                                  onClick={(e) => {
                                    history.push(
                                      `${ROOT}/details/${claim.replacement.from_enc}`
                                    );
                                    e.preventDefault();
                                    return false;
                                  }}
                                >
                                  <Typography
                                    variant="body2"
                                    fontWeight="fontWeightBold"
                                  >
                                    {claim.replacement.from_serial_number}
                                  </Typography>
                                </Link>
                              </Box>
                            </Box>
                          </Box>

                          <Box display="flex" alignItems="center" justifyContent="center">
                            <Box display="flex" flexDirection="column" alignItems="center" marginTop={3}>
                              <ArrowRightAltIcon style={{ fontSize: "48px" }} />
                            </Box>
                          </Box>

                          <Box display="flex" flexDirection="column" py={1} px={2}>
                            <Box display="flex" flexDirection="row" alignItems="center" flexWrap="wrap" marginBottom={1}>
                              <img src={BoxIcon} alt="Box" style={{ marginRight: "8px", width: "20px" }} />
                              <Typography
                                variant="body2"
                                color="primary"
                                style={{ fontWeight: "bold" }}
                              >
                                {getLang(lang, "label.REPLACE_TO")}
                              </Typography>
                            </Box>
                            <Box display="flex" flexDirection="row" alignItems="center" flexWrap="wrap">
                              <Box className={classes.prodImage}>
                                <img src={claim.replacementProductImage} alt="replacement_product_image" />
                              </Box>
                              <Box ml={2} overflow="hidden" maxWidth={190}>
                                <Typography variant="body2" style={{ wordWrap: "break-word" }}>
                                  <b>{claim.replacementProductName}</b>
                                  <span style={{ color: "grey" }}>
                                    {!!claim.replacementProductNameOld &&
                                      ` (${claim.replacementProductNameOld})`}
                                  </span>
                                </Typography>
                                {!!claim.attributes &&
                                  !!claim.attributes.length && (
                                    <Typography
                                      className={classes.attributeText}
                                      variant="body2"
                                    >
                                      {claim.attributes.join(", ")}
                                    </Typography>
                                  )}
                                <Link
                                  href={`${ROOT}/details/${claim.replacement.to_enc}`}
                                  color="secondary"
                                  onClick={(e) => {
                                    history.push(
                                      `${ROOT}/details/${claim.replacement.to_enc}`
                                    );
                                    e.preventDefault();
                                    return false;
                                  }}
                                >
                                  <Typography
                                    variant="body2"
                                    fontWeight="fontWeightBold"
                                  >
                                    {claim.replacement.to_serial_number}
                                  </Typography>
                                </Link>
                              </Box>
                            </Box>
                          </Box>
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Typography
                          variant="body2"
                          color="primary"
                          style={{ fontWeight: "bold" }}
                        >
                          {getLang(lang, "label.PRODUCT_DETAILS")}
                        </Typography>
                        <Box mt={2}>
                          <Grid container>
                            <Grid item xs={4}>
                              {getLang(lang, "label.PRODUCT_NAME")}
                            </Grid>
                            <Grid item xs={8}>
                              <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                alignItems="flex-start"
                              >
                                <Typography variant="body1">
                                  <b>{claim.productName}</b>
                                  <span style={{ color: "grey" }}>
                                    {!!claim.productNameOld &&
                                      ` (${claim.productNameOld})`}
                                  </span>
                                </Typography>
                                {!!claim.attributes &&
                                  !!claim.attributes.length && (
                                    <Typography
                                      className={classes.attributeText}
                                      variant="body2"
                                    >
                                      {claim.attributes.join(", ")}
                                    </Typography>
                                  )}
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                        <Box mt={2}>
                          <Grid container>
                            <Grid item xs={4}>
                              {getLang(lang, "label.SERIAL_NUMBER")}
                            </Grid>
                            <Grid item xs={8}>
                              <Box fontWeight="fontWeightBold">
                                <Link
                                  href={`${ROOT}/details/${claim.enc}`}
                                  color="secondary"
                                  onClick={(e) => {
                                    history.push(`${ROOT}/details/${claim.enc}`);
                                    e.preventDefault();
                                    return false;
                                  }}
                                >
                                  <Typography variant="body1">
                                    {claim.serialNumber}
                                  </Typography>
                                </Link>
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      </>
                    )}
                  </Box>
                </Box>

                <Divider />

                <Box p={3}>
                  <Box>
                    <Typography
                      variant="body2"
                      color="primary"
                      style={{ fontWeight: "bold" }}
                    >
                      {getLang(lang, "label.WARRANTY_DETAILS")}
                    </Typography>

                    <Box mt={2}>
                      <Grid container>
                        <Grid item xs={4}>
                          {getLang(lang, "label.WARRANTY_NAME")}
                        </Grid>
                        <Grid item xs={8}>
                          <Box fontWeight="fontWeightBold">
                            {claim.warrantyName}
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>

                    <Box mt={2}>
                      <Grid container>
                        <Grid item xs={4}>
                          {getLang(lang, "label.WARRANTY_STATUS")}
                        </Grid>
                        <Grid item xs={8}>
                          <Box fontWeight="fontWeightBold">
                            {new Date().getTime() <
                              new Date(claim.warrantyEnd).getTime() ? (
                              <Chip
                                label={getLang(lang, "label.COVER")}
                                style={{
                                  color: "#6AAF68",
                                  backgroundColor: "#ddffdc",
                                }}
                              />
                            ) : (
                              <Chip
                                label={getLang(lang, "label.NOT_COVER")}
                                style={{
                                  color: "#ffa400",
                                  backgroundColor: "#fff1d7",
                                }}
                              />
                            )}
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>

                    <Box mt={2}>
                      <Grid container>
                        <Grid item xs={4}>
                          {getLang(lang, "label.COVERAGE_PERIOD")}
                        </Grid>
                        <Grid item xs={8}>
                          {isEdit ? (
                            <Box display="flex" alignItems="center">
                              <InputTextField
                                required
                                variant="filled"
                                size="small"
                                fullWidth
                                disabled={isUpdating}
                                type="number"
                                inputProps={{
                                  min: "1",
                                }}
                                placeholder={getLang(
                                  lang,
                                  "placeholder.ENTER_VALUE_HERE"
                                )}
                                InputStyle={{
                                  backgroundColor: "white",
                                  border: "1px solid #d0d5dd",
                                }}
                                value={formik.values.warrantyDuration}
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    "warrantyDuration",
                                    e.target.value
                                  );
                                  formik.setFieldValue(
                                    "warrantyEnd",
                                    moment(claim.warrantyStart)
                                      .add(e.target.value, "months")
                                      .format("YYYY-MM-DD HH:mm:ss")
                                  );
                                }}
                                style={{
                                  width: 120,
                                }}
                              />
                              <Box fontWeight="fontWeightBold" ml={1}>
                                {(!!claim.extensionPeriod
                                  ? " + " + claim.extensionPeriod
                                  : "") + ` ${getLang(lang, "label.MONTH_S")}`}
                              </Box>
                            </Box>
                          ) : (
                            <Box fontWeight="fontWeightBold">
                              {claim.warrantyDuration +
                                (claim.extensionPeriod &&
                                  " + " + claim.extensionPeriod) +
                                ` ${getLang(lang, "label.MONTH_S")}`}
                            </Box>
                          )}
                        </Grid>
                      </Grid>
                    </Box>

                    <Box mt={2}>
                      <Grid container>
                        <Grid item xs={4}>
                          {getLang(lang, "label.VALIDITY_DATE")}
                        </Grid>
                        <Grid item xs={8}>
                          <Box fontWeight="fontWeightBold">
                            {claim.warrantyEnd && claim.warrantyStart ? (
                              <Box>
                                <Grid container>
                                  <Grid item xs={3} style={{ width: "100%" }}>
                                    <Box display="flex">
                                      <Box className={classes.dateContainer}>
                                        <Typography
                                          variant="subtitle2"
                                          style={{
                                            fontWeight: "bold",
                                            color: " rgba(58, 77, 84, 0.33)",
                                          }}
                                        >
                                          {getLang(lang, "label.START")}
                                        </Typography>
                                      </Box>
                                      <Box className={classes.dividerContainer}>
                                        <Divider
                                          orientation="vertical"
                                          className={classes.divider}
                                        />
                                      </Box>
                                    </Box>
                                  </Grid>
                                  <Grid item xs={9} style={{ width: "100%" }}>
                                    <DateTimePicker
                                      required
                                      label=""
                                      disabled={true}
                                      value={claim.warrantyStart}
                                      format="DD MMM YYYY"
                                      InputPropStyle={{
                                        borderTopLeftRadius: 0,
                                        borderBottomLeftRadius: 0,
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                                <Box mt={2}>
                                  <Grid container>
                                    <Grid item xs={3} style={{ width: "100%" }}>
                                      <Box display="flex">
                                        <Box className={classes.dateContainer}>
                                          <Typography
                                            variant="subtitle2"
                                            style={{
                                              fontWeight: "bold",
                                              color: " rgba(58, 77, 84, 0.33)",
                                            }}
                                          >
                                            {getLang(lang, "label.END")}
                                          </Typography>
                                        </Box>
                                        <Box
                                          className={classes.dividerContainer}
                                        >
                                          <Divider
                                            orientation="vertical"
                                            className={classes.divider}
                                          />
                                        </Box>
                                      </Box>
                                    </Grid>
                                    <Grid item xs={9} style={{ width: "100%" }}>
                                      <DateTimePicker
                                        required
                                        label=""
                                        disabled={true}
                                        format="DD MMM YYYY"
                                        value={formik.values.warrantyEnd}
                                        InputPropStyle={{
                                          borderTopLeftRadius: 0,
                                          borderBottomLeftRadius: 0,
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                </Box>
                              </Box>
                            ) : null}
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Box>

                <Divider />

                <Box p={3}>
                  <Typography
                    variant="body2"
                    color="primary"
                    style={{ fontWeight: "bold" }}
                  >
                    {getLang(lang, "label.EXTEND_WARRANTY")}
                  </Typography>

                  {[
                    ...(claim.extensionCode
                      ? [
                        {
                          date: new Date(claim.extensionDate),
                          code: claim.extensionCode,
                          extendedMonth: claim.extensionPeriod,
                        },
                      ]
                      : []),
                  ].map((field, index) => {
                    return (
                      <Box
                        key={index}
                        mt={2}
                        py={2}
                        px={1}
                        border={1}
                        borderRadius="4px"
                      >
                        <Grid container>
                          <Grid item xs={3}>
                            <Typography variant="body2" color="primary">
                              <b>{getDate(field.date, true)}</b>
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="body2" color="primary">
                              <Box>
                                {getLang(lang, "label.EXTENSION_CODE")} -{" "}
                                {field.code}
                              </Box>
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography
                              variant="body2"
                              color="primary"
                              style={{ fontWeight: "bold", textAlign: "right" }}
                            >
                              +{field.extendedMonth}{" "}
                              {getLang(lang, "label.MONTH_S")}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    );
                  })}

                  {!claim.extensionCode && (
                    <Box mt={2} py={2} px={1} border={1} borderRadius="4px">
                      <Typography variant="body2" color="primary">
                        <b>{getLang(lang, "label.EMPTY_WARRANTY_EXTENSION")}</b>
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
              {isEdit ? (
                <Box className={classes.footer}>
                  <Divider />
                  <Box
                    p={3}
                    py={2}
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-end"
                  >
                    <Button
                      variant="text"
                      disabled={isUpdating}
                      disableElevation
                      onClick={() => {
                        setIsEdit(false);
                        formik.resetForm();
                      }}
                      style={{ marginRight: "4px" }}
                    >
                      {getLang(lang, "label.CANCEL")}
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      disableElevation
                      type="button"
                      onClick={() => {
                        if (!isUpdating) {
                          let error = {};
                          formik.values.metadata.forEach((metadata, index) => {
                            if (
                              editableMetadataFields.includes(metadata.key) ||
                              editableMetadataName.includes(
                                metadata.variantName
                              )
                            ) {
                              if (metadata.key === "phone") {
                                if (
                                  typeof metadata.value === "string" ||
                                  !metadata.value.contactCode ||
                                  !metadata.value.contactNumber
                                ) {
                                  error[metadata.key] = true;
                                }
                              } else if (!metadata.value) {
                                error[metadata.key] = true;
                              }
                            }
                          });
                          formik.setFieldValue("errorField", error);
                          if (!Object.keys(error).length) {
                            handleUpdate(formik.values);
                          } else {
                            showAlert(getLang(lang, "label.REQUIRED_FIELD"));
                          }
                        }
                      }}
                    >
                      {isUpdating ? (
                        <CircularProgress
                          size={20}
                          style={{ color: "white" }}
                        />
                      ) : (
                        <Typography>{getLang(lang, "label.SAVE")}</Typography>
                      )}
                    </Button>
                  </Box>
                </Box>
              ) : claim.status === 1 &&
                hasClaimListEditAccessRight &&
                claim.warranteeStatus !== "voided" ? (
                <Box className={classes.footer}>
                  <Divider />
                  <Box
                    p={3}
                    py={2}
                    display="flex"
                    justifyContent="space-between"
                  >
                    <Button
                      variant="text"
                      disableElevation
                      onClick={() => setIsEdit(true)}
                      style={{ marginRight: "4px" }}
                      startIcon={<EditIcon fontSize="small" />}
                    >
                      {getLang(lang, "label.EDIT")}
                    </Button>
                    <Box display="flex" justifyContent="flex-end">
                      <Button
                        variant="outlined"
                        classes={{ root: classes.rejectButton }}
                        onClick={onRejectClick}
                      >
                        {getLang(lang, "label.REJECT")}
                      </Button>
                      <Box mx={1} />
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={onApproveClick}
                      >
                        {getLang(lang, "label.APPROVE")}
                      </Button>
                    </Box>
                  </Box>
                </Box>
              ) : (
                <Box className={classes.footer}>
                  <Divider />
                  <Box p={3} py={2} display="flex" flexDirection="row">
                    <Button
                      variant="text"
                      disableElevation
                      onClick={() => setIsEdit(true)}
                      style={{ marginRight: "4px" }}
                      startIcon={<EditIcon fontSize="small" />}
                    >
                      {getLang(lang, "label.EDIT")}
                    </Button>
                  </Box>
                </Box>
              )}
            </>
          )}
        </Formik>
      </Box>
    </>
  );
}
