import React, { useState, useEffect, useRef } from "react";
import ActionDetailButtonComponent from "./actionDetailButton.component";

function ActionDetailButtonContainer({
  variant,
  actionItems,
  popperStyle,
  disablePortal
}) {
  const [isOpen, setIsOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggleOpen = () => {
    setIsOpen((prev) => !prev);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setIsOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setIsOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(isOpen);
  useEffect(() => {
    if (prevOpen.current === true && isOpen === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = isOpen;
  }, [isOpen]);

  if( !variant ){
    variant = "horizontal" 
  }

  return (
    <ActionDetailButtonComponent
      variant={variant}
      isOpen={isOpen}
      handleToggleOpen={handleToggleOpen}
      anchorRef={anchorRef}
      handleListKeyDown={handleListKeyDown}
      handleClose={handleClose}
      actionItems={actionItems}
      popperStyle={popperStyle}
      disablePortal={disablePortal}
    />
  );
}

export default ActionDetailButtonContainer;
