import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Box, ButtonBase, Typography } from "@material-ui/core";
import { MarketplaceSvg, AddMarketplaceSvg } from "../../../utils/staticSvg";
import CloseIcon from "@material-ui/icons/Close";

// We can inject some CSS into the DOM.
const useStyle = makeStyles(theme => ({
  root: {
    padding: 0,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minWidth: 0,
    width: 90
  },
  imageTab: {
    cursor: "pointer",
    position: "relative",
    width: 64,
    height: 64,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#EBEDEE",
    opacity: 0.33,
    borderRadius: "50%",
    margin: theme.spacing(2, 0, 0.5),
    transition: theme.transitions.create("background-color", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
  },
  borderDashed: {
    border: "2px dashed darkgray"
  },
  indicator: {
    position: "absolute",
    width: 50,
    height: 5,
    backgroundColor: theme.palette.primary.main,
    borderTopLeftRadius: theme.spacing(0.5),
    borderTopRightRadius: theme.spacing(0.5),
    bottom: -42,
    left: "50%",
    transform: "translateX(-50%)"
  },

  imageTabActive: {
    //backgroundColor: theme.palette.primary.main,
    opacity: 1
  },
  removeButton: {
    width: 24,
    height: 24,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
    borderRadius: "50%",
    position: "absolute",
    zIndex: 1,
    bottom: 0,
    right: -6
  },
  isEditing: {
    borderColor: theme.palette.primary.main,
    opacity: 1
  },
  opacity1: {
    opacity: 1
  },
  imageSelected: {
    boxShadow: theme.shadows[3]
  },
  label: {
    textAlign: "center",
    marginBottom: theme.spacing(2)
  },
  btnMarketplace: {
    width: 64,
    height: 64,
    display: "flex",
    overflow: "hidden",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    "& img": {
      height: "100%",
      minWidth: "100%",
      minHeight: "100%",
      objectFit: "cover",
      flexShrink: 0
    }
  }
}));

function MarketplaceTabComponent({
  className,
  selected,
  imageSrc = "",
  isEditing = false,
  handleDelete,
  label,
  ...other
}) {
  const classes = useStyle();

  return (
    <Box className={clsx(classes.root, className)} {...other}>
      <Box
        className={clsx(classes.imageTab, {
          [classes.opacity1]: imageSrc !== "",
          [classes.borderDashed]: imageSrc === "",
          [classes.imageTabActive]: selected && !imageSrc,
          [classes.isEditing]: isEditing && !imageSrc && selected,
          [classes.imageSelected]: !isEditing && imageSrc !== "" && selected
        })}
      >
        {imageSrc ? null : selected ? (
          isEditing ? (
            <MarketplaceSvg color="#3A4D54" />
          ) : (
            <AddMarketplaceSvg />
          )
        ) : (
          <MarketplaceSvg />
        )}
        {imageSrc && (
          <>
            <Box className={classes.btnMarketplace}>
              <img src={imageSrc} alt={`marketplace-${label}`} />
            </Box>
            <ButtonBase className={classes.removeButton} onClick={handleDelete}>
              <CloseIcon fontSize="small" />
            </ButtonBase>
          </>
        )}
        {isEditing && selected ? <Box className={classes.indicator} /> : null}
      </Box>
      <Typography
        className={classes.label}
        variant="body2"
        color="textSecondary"
      >
        {label}
      </Typography>
    </Box>
  );
}

MarketplaceTabComponent.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default MarketplaceTabComponent;
