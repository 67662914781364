import React, { useRef, useState } from "react";
import BannerPanelComponent from "./bannerPanel.component";
import Modal from "../../modal";
import PropTypes from "prop-types";
import { Paper, Box } from "@material-ui/core";
import  ImageEditorPanel  from "components/panel/landingPageImageEditorPanel";
import { isFileExceedLimit, isImage } from "lib/helper";
import { useDispatch, useSelector } from "react-redux";
import { getLang } from "app/feature/constants";
import RemoveBannerDialog from "../../dialog/RemoveBannerDialog";
import { addAlert } from "modules/notification";

function BannerPanelContainer({ image, handleChange }) {
  const dispatch = useDispatch();
  const inputImgRef = useRef();
  const [bufferImg, setBufferImg] = useState();
  const [isCroping, setCroping] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRemoveBannerDialogOpen, setIsRemoveBannerDialogOpen] = useState(false);
  const lang = useSelector(state => state.constant.languages)

  const handleImgChange = e => {
    const file = inputImgRef.current.files[0];

    if (file) {
      const rawFile = e.target.files[0];

      if (!isImage(rawFile)) {
        dispatch(addAlert({ severity: "error", message: getLang(lang, 'message.error.INVALID_FILE_TYPE') }));
        return;
      }

      if (isFileExceedLimit(rawFile.size, 12, 'MB')) {
        dispatch(addAlert({ severity: "error", message: getLang(lang, "message.error.FILE_SIZE_EXCEED_LIMIT", { value: 12 }) }));
        return;
      }

      const reader = new FileReader();
      reader.onload = (event) => {
        setBufferImg(reader.result);
        handleChange(reader.result);
      };
      reader.readAsDataURL(file);
    }
    setBufferImg(image);
    setIsModalOpen(true);
    setCroping(true);
  };

  const handleRemoveClicked = () => {
    setIsRemoveBannerDialogOpen(true);
  }

  const handleRemoveBanner = () => {
    setIsRemoveBannerDialogOpen(false);
    setBufferImg(null);
    handleChange("");
  }

  const handleClose = () => {
    setBufferImg(image);
    handleChange(image);
    setIsModalOpen(false)
  };

  const handleResult = async image => {
    setIsUploading(true);
    setCroping(false);
    setIsModalOpen(false);
    setIsUploading(false);
    handleChange(image);
  };

  return (
    <>
      <BannerPanelComponent
        image={image}
        inputImgRef={inputImgRef}
        handleImgChange={handleImgChange}
        handleCancel={handleRemoveClicked}
        lang={lang}
      />
      <Modal isOpen={isModalOpen} handleClose={handleClose}>
        {isCroping && (
          <ImageEditorPanel
            isCircleRadius={false}
            height={375}
            width={375}
            aspectRatio={[29, 10]}
            img={bufferImg}
            handleCancel={handleClose}
            handleResult={handleResult}
          />
        )}
        {isUploading && (
          <Paper elevation={0}>
            <Box p={2}>
              <h3>{getLang(lang, "label.UPLOADING")}</h3>
            </Box>
          </Paper>
        )}
      </Modal>
      <RemoveBannerDialog
        isOpen={isRemoveBannerDialogOpen}
        handleClose={() => setIsRemoveBannerDialogOpen(false)}
        handleConfirm={handleRemoveBanner}
      />
    </>
  );
}

BannerPanelContainer.propTypes = {
  handleChange: PropTypes.func.isRequired,
  image: PropTypes.string.isRequired
};

export default BannerPanelContainer;
