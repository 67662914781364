import React from "react";
import { 
  makeStyles, 
  Box, 
  Typography, 
  Button,
  Checkbox,
  FormControlLabel 
} from "@material-ui/core";
import CustomTextField from "components/textField";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles(theme => ({
  title: {
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      fontSize: "smaller",
      marginBottom: theme.spacing(1)
    }
  }
}));

export default function NewPasswordFormComponent({
  email,
  handleSubmit,
  isLoading,
  lang,
  handleClickShowPassword,
  handleMouseDownPassword,
  isShowPassword
}) {
  const classes = useStyle();
  return (
    <Box p={4}>
      <Typography variant="body1" className={classes.title} gutterBottom>
        {getLang(lang, "paragraph.CREATE_NEW_PASSWORD")}
      </Typography>
      <Typography variant="body2" color="textPrimary" gutterBottom>
        {getLang(lang, "paragraph.PLEASE_CREATE_NEW_PASSWORD")} {email}
      </Typography>
      <Formik
        initialValues={{
          password: "",
          confirmPassword: ""
        }}
        validationSchema={Yup.object({
          password: Yup.string()
            .required(getLang(lang,"message.error.FORM_VALIDATE_REQUIRED"))
            .min(8, getLang(lang,"message.error.FORM_VALIDATE_MIN", {value: getLang(lang, "message.error.CHARACTERS_8")}))
            .max(20, getLang(lang,"message.error.FORM_VALIDATE_MAX", {value: 20}))
            .matches(/^(?=.*[a-z])/, getLang(lang,"message.error.FORM_VALIDATE_MATCH", {value: getLang(lang, "message.error.LOWERCASE_CHARACTER_1")}))
            .matches(/^(?=.*[A-Z])/, getLang(lang,"message.error.FORM_VALIDATE_MATCH", {value: getLang(lang, "message.error.UPPERCASE_CHARACTER_1")}))
            .matches(/[!@#$%^&~`*()_+\-={}[\]:";'<>,.?|]/, getLang(lang,"message.error.FORM_VALIDATE_MATCH", {value: getLang(lang, "message.error.SPECIAL_CHARACTER_1")}))
            .matches(/^(?=.*[0-9])/, getLang(lang,"message.error.FORM_VALIDATE_MATCH", {value: getLang(lang, "message.error.NUMBER_1")})),
          confirmPassword: Yup.string()
            .oneOf([Yup.ref("password"), null], getLang(lang,"message.error.FORM_VALIDATE_PASSWORD"))
            .required(getLang(lang,"message.error.FORM_VALIDATE_REQUIRED"))
        })}
        onSubmit={handleSubmit}
      >
        {formik => (
          <Box mt={3}>
            <form onSubmit={formik.handleSubmit}>
              <CustomTextField
                style={{
                  marginBottom: 10
                }}
                label={<><span>{getLang(lang, "label.NEW_PASSWORD")} </span><span style={{ color: "#DE350B", display: 'inline-block' }}>*</span></>}
                variant="outlined"
                size="small"
                type={isShowPassword ? "text" : "password"}
                fullWidth={true}
                error={
                  formik.touched.password && formik.errors.password ? true : false
                }
                helperText={ErrorMessage({ name: "password" })}
                {...formik.getFieldProps("password")}
              />
              <CustomTextField
                label={<><span>{getLang(lang, "label.CONFIRM_NEW_PASSWORD")} </span><span style={{ color: "#DE350B", display: 'inline-block' }}>*</span></>}
                variant="outlined"
                size="small"
                type={isShowPassword ? "text" : "password"}
                fullWidth={true}
                error={
                  formik.touched.confirmPassword && formik.errors.confirmPassword ? true : false
                }
                helperText={ErrorMessage({ name: "confirmPassword" })}
                {...formik.getFieldProps("confirmPassword")}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="secondary"
                    onChange={(state) => {
                      handleClickShowPassword(state.isShowPassword)
                    }}
                  />
                }
                label={<Typography variant="body2" className={classes.signed}>
                  {getLang(lang, "label.SHOW_PASSWORD")}
                </Typography>}
              />
              <Box mt={3} display="flex" justifyContent="end">
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  disableElevation
                  disabled={isLoading}
                >
                  {getLang(lang, "label.RESET_PASSWORD")}
                </Button>
              </Box>
            </form>
          </Box>
        )}
      </Formik>
    </Box>
  );
}
