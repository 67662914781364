import React from "react"
import { makeStyles, Typography, Box, Button } from "@material-ui/core"
import { Formik } from "formik"
import DatePicker from 'components/input/datePicker'
import moment from "moment"
import GiftRedemptionTable from "../../table/giftRedemptionTable"
import SearchableSelect from "components/select/searchableSelect"
import GiftDropdownSelect from "../../select/giftDropdownSelect"
import { getLang } from "app/feature/constants"

const useStyles = makeStyles((theme) => ({
  container: {
    background: "white",
    borderRadius: 4,
    padding: "16px 20px"
  },
  filterFieldContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 0.3fr 0.3fr",
    columnGap: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1)
    }
  },
}))

export default function GiftRedemptionListPanelComponent({ 
  id,
  list,
  isFetching,
  isError,
  handleChangePage,
  handleChangeRowsPerPage,
  page,
  rowsPerPage,
  totalFiltered,
  handleReload,
  paginationOptions,
  language,
  tableFilter,
  changeOrder,
  handleFilter,
  lang
}) {
  const classes = useStyles()

  return (
    <>
      <Box className={classes.container}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="body1"><b>{getLang(lang, "label.GIFT_REDEMPTION_LIST")}</b></Typography>
        </Box>
        <Box className={classes.filterMainContainer}>
          <Formik
            initialValues={{
              date: '',
              gift: [],
              status: '',
            }}
            onSubmit={(values, actions) => {
              handleFilter(values)
              actions.setSubmitting(false)
            }}
            onReset={handleFilter}
          >
            {(formik) => (
              <form onSubmit={formik.handleSubmit}>
                <Box>
                  <Box className={classes.filterFieldContainer} style={{ marginBottom: "12px" }}>
                    <DatePicker
                      label=""
                      disabled={formik.isSubmitting || isFetching}
                      disableFuture
                      id="date-picker"
                      value={formik.values.date}
                      handleChange={(value) => {
                        formik.setFieldValue("date", value.format("YYYY-MM-DD"))
                      }}
                      format="DD MMM YYYY"
                      InputPropStyle={{
                        margin: 0,
                        height: 34
                      }}
                      inputStyle={{
                        fontWeight: "normal",
                        color: formik.values.date ? "black" : "#98A2B3",
                        fontSize: "16px",
                        paddingLeft: 5
                      }}
                      emptyLabel={getLang(lang, "placeholder.DATE")}
                      maxDate={moment().format("YYYY-MM-DD")}
                    />
                    <GiftDropdownSelect
                      value={formik.values.gift}
                      handleChange={(value) => formik.setFieldValue("gift", value)}
                      placeholder={getLang(lang, "placeholder.GIFT")}
                      disabled={isFetching}
                      id={id}
                    />
                    <SearchableSelect
                      value={formik.values.status}
                      handleChange={(value) => {
                        formik.setFieldValue("status", value === "-" ? "" : value)
                      }}
                      placeholder={getLang(lang, "placeholder.STATUS")}
                      disabled={formik.isSubmitting || isFetching}
                      searchable={false}
                      style={{ height: 34 }}
                      panelStyle={{ width: "200px" }}
                      PaperProps={{
                        classes: { root: classes.paperRoot }
                      }}
                      dropdownItem={['-', getLang(lang, "label.PENDING"), getLang(lang, "label.APPROVED"), getLang(lang, "label.REJECTED")]}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left"
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left"
                      }}
                      isEmpty={!formik.values.status}
                      returnType="object"
                    />
                    <Button
                      variant='text'
                      disableElevation
                      color='secondary'
                      size='small'
                      onClick={formik.handleReset}
                      style={{
                        marginRight: "8px"
                      }}
                    >
                      {getLang(lang, "label.CLEAR")}
                    </Button>
                    <Button
                      disabled={isFetching}
                      variant="contained"
                      disableElevation
                      color="primary"
                      size='small'
                      type='submit'
                    >
                      {getLang(lang, "label.SEARCH")}
                    </Button>
                  </Box>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
        <Box mt={3}>
          <GiftRedemptionTable 
            rows={list}
            isFetching={isFetching}
            isError={isError}
            handleReload={handleReload}
            language={language}
            page={page}
            rowsPerPage={rowsPerPage}
            totalFiltered={totalFiltered}
            paginationOptions={paginationOptions}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            tableFilter={tableFilter}
            changeOrder={changeOrder}
            lang={lang}
          />
        </Box>
      </Box>
    </>
  )
}
