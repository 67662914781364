import React from "react";
import { Box } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { getLang } from "app/feature/constants";

export default function MaintenanceAlertComponent({
  showMaintenanceAlert,
  lang,
}) {
  return (
    showMaintenanceAlert && (
      <Box pb={2}>
        <Alert severity="warning">
          {getLang(lang, "paragraph.MAINTENANCE_ALERT")}
        </Alert>
      </Box>
    )
  );
}
