import React from "react"
import {
  Backdrop,
  Dialog,
  makeStyles,
  Typography,
  Paper,
  Box,
  IconButton,
  Divider,
  Chip,
  Button
} from "@material-ui/core";
import { Close as CloseIcon } from '@material-ui/icons'
import { Formik } from 'formik';
import Input from "components/input/inputTextField";
import { getLang } from "app/feature/constants";


const useStyle = makeStyles((theme) => ({
  dialog: {
    maxWidth: "none"
  },
  root: {
    boxShadow: theme.shadows[5],
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "space-between",
    width: 500,
    borderRadius: "4px",
    fontSize: 14
  },
  header: {
    paddingLeft: theme.spacing(3),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  content: {
    padding: "16px"
  },
  buttonContainer: {
    padding: "10px 16px",
    display: "flex",
    justifyContent: "flex-end"
  },
  selectRoot: {
    padding: "8px 14px"
  },
  inputRoot: {
    paddingTop: "8px !important",
    paddingBottom: "7px !important",
    paddingLeft: "12px !important",
    paddingRight: "12px !important"
  },
}))

export default function CreateCategoryDialogComponent({
  isOpen,
  handleClose,
  handleSubmit,
  isSubmittingAddCategory,
  lang
}) {
  const classes = useStyle()

  return (
    <Dialog
      maxWidth="sm"
      open={isOpen}
      onClose={handleClose}
      disableBackdropClick
      closeAfterTransition
      BackdropComponent={Backdrop}
      classes={{
        paperWidthSm: classes.dialog
      }}
    >
      <Paper elevation={0} className={classes.root}>
        <Box className={classes.header}>
          <Typography
            variant="subtitle2"
            color="primary"
            style={{ fontWeight: "bold" }}
          >
            {getLang(lang,"label.ADD_CATEGORY")}
          </Typography>
          <IconButton color="primary" onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
        <Divider />
        <Formik
          initialValues={{
            name: "",
            categories: []
          }}
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <form onSubmit={e => { e.preventDefault(); }}>
              <Box>
                <Box className={classes.content}>
                  <Typography
                    variant='body2'
                    color='primary'
                    style={{ marginBottom: "10px" }}
                  >
                    {getLang(lang,"label.CATEGORY_NAME")}
                  </Typography>
                  <Box style={{ display: "flex", justifyContent: "space-between" }}>
                    <Input
                      hiddenLabel
                      variant="filled"
                      placeholder={getLang(lang,"placeholder.PLEASE_FILL_CATEGORY")}
                      margin="none"
                      InputProps={{
                        disableUnderline: true,
                        margin: "none",
                        style: {
                          borderRadius: "4px",
                          backgroundColor: "#eceff0",
                          height: "36.5px"
                        },
                        classes: { input: classes.inputRoot }
                      }}
                      style={{
                        width: "80%",
                      }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter" && formik.values.name !== "") {
                          let errorMsg = "";
                          let isError = false;
                          let array = formik.values.name.trim().split(',');
                          let arrayToInsert = [];
                          if (array.length !== 0) {
                            array.forEach((input) => {
                              let val = input.trim();
                              if (val !== "") {
                                if (formik.values.categories.filter((category) => category === val).length === 0 && arrayToInsert.filter((value) => value === val).length === 0) {
                                  arrayToInsert = [...arrayToInsert, val]
                                }
                                else {
                                  isError = true;
                                  errorMsg = getLang(lang,"message.error.DUPLICATE_CATEGORY_NAME");
                                }
                              }
                            })
                          }
                          else {
                            errorMsg = getLang(lang,"message.error.INVALID_ENTRY");
                            isError = true;
                          }
                          if (arrayToInsert.length !== 0 && !isError) {
                            formik.setFieldValue("categories", [...formik.values.categories, ...arrayToInsert]);
                            formik.setFieldValue("name", "");
                          }
                          formik.setFieldError("name", errorMsg);
                        }
                      }}
                      error={formik.errors.name ? true : false}
                      helperText={formik.errors.name}
                      {...formik.getFieldProps("name")}
                    />
                    <Button
                      variant='contained'
                      color="primary"
                      disableElevation
                      onClick={() => {
                        if (formik.values.name !== "") {
                          let errorMsg = "";
                          let isError = false;
                          let array = formik.values.name.trim().split(',');
                          let arrayToInsert = [];
                          if (array.length !== 0) {
                            array.forEach((input) => {
                              let val = input.trim();
                              if (val !== "") {
                                if (formik.values.categories.filter((category) => category === val).length === 0 && arrayToInsert.filter((value) => value === val).length === 0) {
                                  arrayToInsert = [...arrayToInsert, val]
                                }
                                else {
                                  isError = true;
                                  errorMsg = getLang(lang,"message.error.DUPLICATE_CATEGORY_NAME");
                                }
                              }
                            })
                          }
                          else {
                            errorMsg = getLang(lang,"message.error.INVALID_ENTRY");
                            isError = true;
                          }
                          if (arrayToInsert.length !== 0 && !isError) {
                            formik.setFieldValue("categories", [...formik.values.categories, ...arrayToInsert]);
                            formik.setFieldValue("name", "");
                          }
                          formik.setFieldError("name", errorMsg);
                        }
                      }}
                    >
                      {getLang(lang,"label.INSERT")}
                    </Button>
                  </Box>
                  <Typography
                    variant="caption"
                    style={{ color: "rgba(58, 77, 84, 0.7)" }}
                  >
                    {getLang(lang,"paragraph.ENTER_TO_ADD_CATEGORY")}
                  </Typography>
                  <Box style={{ display: "flex", marginTop: "10px", flexWrap: "wrap" }}>
                    {
                      formik.values.categories.length > 0 ? formik.values.categories.map((category) => (
                        <Box key={category} style={{ marginRight: "8px", marginBottom: "8px" }}>
                          <Chip
                            label={category}
                            onDelete={() => {
                              let temp = formik.values.categories.filter((cat) => cat !== category)
                              formik.setFieldValue("categories", temp)
                            }}
                          />
                        </Box>
                      )) : null
                    }
                  </Box>
                </Box>
                <Divider />
                <Box className={classes.buttonContainer}>
                  <Button
                    type="button"
                    variant="contained"
                    disableElevation
                    color='secondary'
                    disabled={formik.values.categories.length === 0 || isSubmittingAddCategory}
                    // style={{
                    //   backgroundColor: (formik.values.categories.length > 0 && !isSubmittingAddCategory) ? "#6AAF68" : "#E5F1E4",
                    //   color: "#FFFFFF"
                    // }}
                    onClick={formik.handleSubmit}
                  >
                    {getLang(lang,"label.ADD")}
                  </Button>
                </Box>
              </Box>
            </form>
          )}
        </Formik>
      </Paper>
    </Dialog>
  )
}