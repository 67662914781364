import React from "react";
import {
  makeStyles,
  Box,
  Typography,
  Divider,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { useFormik } from "formik";
import LoadingComponent from "components/loading";
import { getLang } from "app/feature/constants";
import StockModuleSettingCard from "../../card/stockModuleSettingCard";
import AutoCompleteStockTransferSettingCard from "../../card/autoCompleteStockTransferSettingCard";
import CustomReasonSettingCard from "../../card/customReasonSettingCard";
import EmailSettingCard from "../../card/emailSettingCard";

const useStyle = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 3, 2, 3),
    borderRadius: "4px",
  },
  button: {
    "&:not(:last-child)": {
      marginLeft: "10px",
    },
  },
}));

export default function StockModuleSettingPanelComponent({
  isEditable,
  hasEditTransferAccessRight,
  isLoadingStockModuleSetting,
  rules,
  handleSubmit,
  lang,
  reasonActiveIndex,
  changeTab,
  tabLabels,
  hasEmptyReasonField,
  isSubmitting,
  hasUpdateReceiveEmailAccessRight,
  setInvalidEmails,
  invalidEmails,
  showAlert,
}) {
  const classes = useStyle();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      maxTier: rules.maxTier,
      requestStock: rules.requestStock,
      autoReceiveStock: rules.autoReceiveStock,
      autoReceiveStockDays: rules.autoReceiveStockDays,
      rewardPoint: rules.rewardPoint,
      rewardPointMin: rules.rewardPointMin,
      rewardPointExpiration: rules.rewardPointExpiration,
      rewardPointExpirationMonths: rules.rewardPointExpirationMonths,
      rewardPointRecallDeduction: rules.rewardPointRecallDeduction,
      enableAutoCompleteTransfer: rules.autoReceiveTransfer >= 1 ? 1 : 0,
      actionAfterAutoCompleteTransfer:
        rules.autoReceiveTransfer > 0 ? rules.autoReceiveTransfer : 1,
      autoReceiveTransferDays: rules.autoReceiveTransferDays ?? 7,
      enableTransferRules: rules.enableTransferRules ?? 0,
      enableCustomReasons: rules.enableCustomReasons ?? 0,
      emailSettings: rules.customEmailOption ?? 1,
      emailRecipients: rules.customEmailRecipients ?? [],
      reasons: rules.reasons || {},
    },
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  if (isLoadingStockModuleSetting) {
    return (
      <Box
        className={classes.container}
        style={{ paddingTop: 40, paddingBottom: 40 }}
      >
        <LoadingComponent />
      </Box>
    );
  }

  const dealerStockflowAttributes = [
    {
      label: getLang(lang, "label.STOCK_REQUEST_OPTION"),
      subLabel: getLang(lang, "paragraph.CHOOSE_DEALERS_TO_REQUEST_STOCK"),
      type: "radio",
      fieldName: "requestStock",
      valuesType: "number",
      values: [
        {
          label: getLang(lang, "label.REQUEST_FROM_HQ_ONLY"),
          value: 1,
        },
        {
          label: getLang(lang, "label.REQUEST_FROM_UPLINE_ONLY"),
          value: 2,
        },
        {
          label: getLang(lang, "label.REQUEST_FROM_HQ_OR_UPLINE"),
          value: 3,
        },
      ],
    },
    {
      label: getLang(lang, "label.AUTO_RECEIVE_STOCK"),
      subLabel: getLang(
        lang,
        "paragraph.SET_AUTOMATICALLY_CONFIRM_RECEIVE_GOODS"
      ),
      type: "radio",
      fieldName: "autoReceiveStock",
      valuesType: "number",
      values: [
        {
          label: getLang(lang, "label.AUTO_RECEIVE_STOCK_AFTER"),
          value: 1,
          enableCustomField: true,
          customFieldType: "number",
          customFieldFieldName: "autoReceiveStockDays",
          customFieldEndAdornment: getLang(lang, "label.DAY_S"),
        },
        {
          label: getLang(lang, "label.NEVER_AUTO_RECEIVE"),
          value: 2,
        },
      ],
    },
    {
      label: getLang(lang, "label.RECALL_SETTING"),
      subLabel: getLang(
        lang,
        "paragraph.DEDUCT_INCENTIVE_POINTS_FROM_RECEIVER"
      ),
      type: "radio",
      fieldName: "rewardPointRecallDeduction",
      valuesType: "number",
      values: [
        {
          label: getLang(lang, "label.NO_DEDUCTION"),
          value: 1,
        },
        {
          label: getLang(lang, "label.DEDUCT_RECEIVER_ONLY"),
          value: 3,
        },
        {
          label: getLang(lang, "label.DEDUCT_UPLINE_AND_RECEIVER"),
          value: 2,
        },
      ],
    },
  ];

  const dealerIncentiveAttributes = [
    {
      label: getLang(lang, "label.MINIMUM_INCENTIVE_POINT"),
      subLabel: getLang(lang, "paragraph.SET_MINIMUM_ICENTIVE_POINT"),
      type: "radio",
      fieldName: "rewardPointMin",
      valuesType: "number",
      values: [
        {
          label: getLang(lang, "label.ZERO_POINT"),
          value: 1,
        },
        {
          label: getLang(lang, "label.NO_LIMIT"),
          value: 2,
        },
      ],
    },
    {
      label: getLang(lang, "label.INCENTIVE_POINTS_VALIDITY"),
      subLabel: getLang(lang, "paragraph.SET_EXPIRY_PERIOD"),
      type: "radio",
      fieldName: "rewardPointExpiration",
      valuesType: "number",
      values: [
        {
          label: getLang(lang, "label.NO_EXPIRATION"),
          value: 2,
        },
        {
          label: getLang(lang, "label.EXPIRY_PERIOD"),
          value: 1,
          enableCustomField: true,
          customFieldType: "number",
          customFieldFieldName: "rewardPointExpirationMonths",
          customFieldEndAdornment: getLang(lang, "label.MONTH_S"),
        },
      ],
    },
  ];

  return (
    <form>
      <Box className={classes.container}>
        {hasUpdateReceiveEmailAccessRight && (
          <Box>
            <Typography
              variant="h6"
              color="primary"
              style={{ marginBottom: 8 }}
            >
              {getLang(lang, "label.EMAIL_SETTINGS")}
            </Typography>
            <Divider />
            <Box mt={2} px={1}>
              <EmailSettingCard
                attribute={{
                  label: getLang(lang, "label.RECEIVE_EMAIL_OPTION"),
                  subLabel: getLang(
                    lang,
                    "paragraph.CHOOSE_OPTION_RECEIVE_EMAIL"
                  ),
                  fieldName: "emailSettings",
                  values: [
                    {
                      label: getLang(lang, "label.DEFAULT"),
                      value: 1,
                    },
                    {
                      label: getLang(lang, "label.NO"),
                      value: 2,
                    },
                    {
                      label: getLang(lang, "label.CUSTOM"),
                      value: 3,
                    },
                  ],
                }}
                isEditable={isEditable}
                formik={formik}
                attributeValue={formik.values.emailSettings}
                isSubmitting={isSubmitting}
                invalidEmails={invalidEmails}
                setInvalidEmails={setInvalidEmails}
              />
            </Box>
          </Box>
        )}
        <Box mt={hasUpdateReceiveEmailAccessRight ? 3 : 0}>
          <Typography variant="h6" color="primary" style={{ marginBottom: 8 }}>
            {getLang(lang, "label.DEALER_STOCKFLOW")}
          </Typography>
          <Divider />
          <Box mt={2} px={1}>
            {dealerStockflowAttributes.map((attribute, i) => {
              return (
                <StockModuleSettingCard
                  key={i}
                  attribute={attribute}
                  isEditable={isEditable}
                  formik={formik}
                  updateFormikValue={(key, value) => {
                    formik.setFieldValue(key, value);
                  }}
                  attributeValue={formik.values[attribute.fieldName]}
                  isSubmitting={isSubmitting}
                />
              );
            })}
          </Box>
        </Box>
        <Box mt={3}>
          <Typography variant="h6" color="primary" style={{ marginBottom: 8 }}>
            {getLang(lang, "label.DEALER_INCENTIVE_POINTS")}
          </Typography>
          <Divider />
          <Box mt={2} px={1}>
            {dealerIncentiveAttributes.map((attribute, i) => {
              return (
                <StockModuleSettingCard
                  key={i}
                  attribute={attribute}
                  isEditable={isEditable}
                  formik={formik}
                  updateFormikValue={(key, value) => {
                    formik.setFieldValue(key, value);
                  }}
                  attributeValue={formik.values[attribute.fieldName]}
                  isSubmitting={isSubmitting}
                />
              );
            })}
          </Box>
        </Box>
        {hasEditTransferAccessRight && (
          <Box mt={3}>
            <Typography
              variant="h6"
              color="primary"
              style={{ marginBottom: 8 }}
            >
              {getLang(lang, "label.BRANCH_STOCKFLOW")}
            </Typography>
            <Divider />
            <Box mt={2} px={1}>
              <AutoCompleteStockTransferSettingCard
                updateFormikValue={(key, value) => {
                  if (key === "enableAutoCompleteTransfer") {
                    if (value === 0) {
                      formik.setFieldValue(
                        "actionAfterAutoCompleteTransfer",
                        1
                      );
                    }
                  }

                  formik.setFieldValue(key, value);
                }}
                lang={lang}
                enableAutoCompleteTransfer={
                  formik.values.enableAutoCompleteTransfer ?? 0
                }
                actionAfterAutoCompleteTransfer={
                  formik.values.actionAfterAutoCompleteTransfer ?? 1
                }
                autoReceiveTransferDays={
                  formik.values.autoReceiveTransferDays ?? 0
                }
              />
              <StockModuleSettingCard
                attribute={{
                  label: getLang(lang, "label.ENABLE_TRANSFER_RULES"),
                  subLabel: getLang(
                    lang,
                    "paragraph.SET_ENABLE_TRANSFER_RULES"
                  ),
                  type: "radio",
                  fieldName: "enableTransferRules",
                  valuesType: "number",
                  values: [
                    {
                      label: getLang(lang, "label.ENABLE"),
                      value: 1,
                    },
                    {
                      label: getLang(lang, "label.DISABLE"),
                      value: 0,
                    },
                  ],
                }}
                isEditable={isEditable}
                formik={formik}
                updateFormikValue={(key, value) => {
                  formik.setFieldValue(key, value);
                }}
                attributeValue={formik.values.enableTransferRules}
                isSubmitting={isSubmitting}
              />
              <CustomReasonSettingCard
                attribute={{
                  label: getLang(lang, "label.CUSTOM_REASONS"),
                  subLabel: getLang(
                    lang,
                    "paragraph.SET_ENABLE_CUSTOM_REASONS"
                  ),
                  type: "radio",
                  fieldName: "enableCustomReasons",
                  valuesType: "number",
                  values: [
                    {
                      label: getLang(lang, "label.ENABLE"),
                      value: 1,
                    },
                    {
                      label: getLang(lang, "label.DISABLE"),
                      value: 0,
                    },
                  ],
                }}
                isEditable={isEditable}
                formik={formik}
                attributeValue={formik.values.enableCustomReasons}
                activeIndex={reasonActiveIndex}
                changeTab={changeTab}
                tabLabels={tabLabels}
                hasEmptyReasonField={hasEmptyReasonField}
                isSubmitting={isSubmitting}
              />
            </Box>
          </Box>
        )}
      </Box>
      {isEditable && (
        <>
          <Divider />
          <Box
            className={classes.container}
            style={{ display: "flex", flexDirection: "row-reverse" }}
          >
            <Button
              variant="contained"
              disableElevation
              color="primary"
              onClick={() => {
                let invalid = [];
                formik.values.emailRecipients.forEach((element, index) => {
                  if (!!element.trim()) {
                    if (
                      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(element)
                    ) {
                      invalid.push(index);
                    }
                  }
                });
                setInvalidEmails(invalid);

                if (!invalid.length) {
                  formik.handleSubmit();
                } else {
                  showAlert(
                    getLang(lang, "message.error.INVALID_EMAIL_FORMAT")
                  );
                }
              }}
              className={classes.button}
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <CircularProgress size={20} style={{ color: "white" }} />
              ) : (
                <Typography>{getLang(lang, "label.SUBMIT")}</Typography>
              )}
            </Button>
            <Button
              variant="contained"
              disableElevation
              onClick={() => formik.resetForm()}
              className={classes.button}
              disabled={isSubmitting}
            >
              {getLang(lang, "label.RESET")}
            </Button>
          </Box>
        </>
      )}
    </form>
  );
}
