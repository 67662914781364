import { checkoutPurchase, checkCoupon, getSubscriptionPlans, swapSubscriptionPlan, swapSubscriptionPlanPreview } from "./marketplace.action";
import { setLoading, addAlert } from "modules/notification";
import { setCredit } from "modules/billing/redux";
import { setSubscriptionPlans } from "./marketplace.slice";
import { getApiLang } from "app/feature/constants";

const checkoutPurchaseMiddleware = ({ getState }) => next => action => {
  next(action);

  const { type, payload } = action;

  switch (type) {
    case checkoutPurchase.pending.type:
      next(setLoading({ id: checkoutPurchase.typePrefix, state: true }));
      break;
    case checkoutPurchase.fulfilled.type:
      next(setCredit(payload.data.credits));
      next(setLoading({ id: checkoutPurchase.typePrefix, state: false }));
      break;
    case checkoutPurchase.rejected.type:
      const lang = getState().constant.languages
      next(setLoading({ id: checkoutPurchase.typePrefix, state: false }));
      next(
        addAlert({ severity: "error", message: getApiLang(lang, payload.code) })
      );
      break;

    default:
      break;
  }
};

const checkCouponMiddleware = ({ getState }) => next => action => {
  next(action);
  const { type, payload } = action;

  if (type === checkCoupon.rejected.type) {
    const lang = getState().constant.languages
    next(addAlert({ severity: "error", message: getApiLang(lang, payload.code)}));
  }
};

const getSubscriptionPlansMiddleware = ({ getState }) => next => action => {
  next(action);
  const { type, payload } = action;
  const lang = getState().constant.languages
  switch (type) {
    case getSubscriptionPlans.pending.type:
      next(setLoading({ id: getSubscriptionPlans.typePrefix, state: true }));
      break;
    case getSubscriptionPlans.fulfilled.type:
      let plans = payload;
      next(setSubscriptionPlans(plans));
      next(setLoading({ id: getSubscriptionPlans.typePrefix, state: false }));
      break;
    case getSubscriptionPlans.rejected.type:
      next(setLoading({ id: getSubscriptionPlans.typePrefix, state: false }));
      next(
        addAlert({ severity: "error", message: getApiLang(lang, payload.code)})
      );
      break;
    default:
      break;
  }
};

const swapSubscriptionPlanMiddleware = ({ getState }) => next => action => {
  next(action);
  
  const { type, payload } = action;
  const lang = getState().constant.languages

  switch (type) {
    case swapSubscriptionPlan.pending.type:
      next(setLoading({ id: swapSubscriptionPlan.typePrefix, state: true }));
      break;
    case swapSubscriptionPlan.fulfilled.type:
      next(setLoading({ id: swapSubscriptionPlan.typePrefix, state: false }));
      break;
    case swapSubscriptionPlan.rejected.type:
      next(setLoading({ id: swapSubscriptionPlan.typePrefix, state: false }));
      next(
        addAlert({ severity: "error", message: getApiLang(lang, payload.code) })
      );
      break;

    default:
      break;
  }
};

const swapSubscriptionPlanPreviewMiddleware = ({ getState }) => next => action => {
  next(action);
  const { type, payload } = action;
  const lang = getState().constant.languages

  switch (type) {
    case swapSubscriptionPlanPreview.pending.type:
      next(setLoading({ id: swapSubscriptionPlanPreview.typePrefix, state: true }));
      break;
    case swapSubscriptionPlanPreview.fulfilled.type:
      next(setLoading({ id: swapSubscriptionPlanPreview.typePrefix, state: false }));
      break;
    case swapSubscriptionPlanPreview.rejected.type:
      next(setLoading({ id: swapSubscriptionPlanPreview.typePrefix, state: false }));
      next(
        addAlert({ severity: "error", message: getApiLang(lang, payload.code) })
      );
      break;

    default:
      break;
  }
};


export const marketplaceMiddleware = [
  checkoutPurchaseMiddleware,
  checkCouponMiddleware,
  getSubscriptionPlansMiddleware,
  swapSubscriptionPlanMiddleware,
  swapSubscriptionPlanPreviewMiddleware
];
