import React from "react";
import {
  makeStyles,
  Paper,
  Box,
  Typography,
  IconButton,
  Divider,
} from "@material-ui/core";
import Dialog from "components/dialog/customDialog";
import CloseIcon from "@material-ui/icons/Close";
import { getLang } from "app/feature/constants";
import moment from "moment";
import RefreshTable from "components/table/refreshTable";
import { Skeleton } from "@material-ui/lab";

const useStyle = makeStyles((theme) => ({
  root: {
    boxShadow: theme.shadows[5],
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "space-between",
    width: 600,
    borderRadius: "4px",
    fontSize: 14,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  header: {
    paddingLeft: theme.spacing(3),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  greyText: {
    color: "#98A2B3",
  },
  formatSection: {
    display: "grid",
    gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
    columnGap: theme.spacing(2),
    wordBreak: "break-all",
  },
}));

export default function TagDetailDialogComponent({
  open,
  handleReload,
  handleClose,
  lang,
  tag,
  isLoading,
  isError,
}) {
  const classes = useStyle();

  let format = tag ? tag.format.split(",") : [];

  return (
    <Dialog
      isOpen={open}
      handleClose={handleClose}
      disableEnforceFocus={true}
      disableAutoFocus={true}
      disableEscapeKeyDown={true}
    >
      <Paper elevation={0} className={classes.root}>
        <div className={classes.header}>
          <Typography variant="subtitle2" style={{ fontWeight: "bold" }}>
            {getLang(lang, "label.SAVED_PATTERN_DETAILS")}
          </Typography>
          <IconButton color="primary" onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </div>
        <Divider />
        <Box p={2} mb={2} maxHeight="80dvh" overflow="auto">
          {isLoading ? (
            <>
              <Skeleton variant="text" animation="wave" height={30} />
              <Skeleton variant="text" animation="wave" height={30} />
              <Skeleton variant="text" animation="wave" height={30} />
              <Skeleton variant="text" animation="wave" height={30} />
            </>
          ) : isError ? (
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <RefreshTable handleReload={handleReload} />
            </Box>
          ) : tag ? (
            <>
              <Box className={classes.formatSection} px={2.5} pb={2}>
                <Box>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    className={classes.greyText}
                  >
                    {getLang(lang, "label.PREFIX")}
                  </Typography>
                  <Typography variant="h6">
                    <b>
                      {format.length
                        ? format[0] !== ""
                          ? format[0]
                          : "-"
                        : "-"}
                    </b>
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    className={classes.greyText}
                  >
                    {getLang(lang, "label.LENGTH")}
                  </Typography>
                  <Typography variant="h6">
                    <b>{format.length ? format[1] : "-"}</b>
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    className={classes.greyText}
                  >
                    {getLang(lang, "label.POSTFIX")}
                  </Typography>
                  <Typography variant="h6">
                    <b>
                      {format.length
                        ? format[2] !== ""
                          ? format[2]
                          : "-"
                        : "-"}
                    </b>
                  </Typography>
                </Box>
              </Box>
              <Box style={{ background: "#f7f7f7" }} p={2}>
                <Box>
                  <Box mb={0.5}>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      className={classes.greyText}
                    >
                      {getLang(lang, "label.TYPE")}
                    </Typography>
                  </Box>
                  <Typography variant="body2">
                    <b>
                      {format.length > 0
                        ? format[3] === "number"
                          ? getLang(lang, "label.RUNNING_NUMBER")
                          : getLang(lang, "label.RANDOM_ALPHANUMERIC")
                        : "-"}
                    </b>
                  </Typography>
                </Box>
                <Box mt={2}>
                  <Box mb={0.5}>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      className={classes.greyText}
                    >
                      {getLang(lang, "label.SERIAL_NUMBER_RANGE")}
                    </Typography>
                  </Box>
                  {tag.serial_number_range.length ? (
                    tag.serial_number_range.map((s, i) => (
                      <Typography variant="body2" key={i}>
                        <b>
                          {s.from} - {s.to}
                        </b>
                      </Typography>
                    ))
                  ) : (
                    <Typography variant="body2">
                      <b>-</b>
                    </Typography>
                  )}
                </Box>
                <Box mt={2}>
                  <Box mb={0.5}>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      className={classes.greyText}
                      style={{ textTransform: "capitalize" }}
                    >
                      {getLang(lang, "label.SEQUENCE_NUMBER_RANGE")}
                    </Typography>
                  </Box>
                  {tag.seq_number_range.length ? (
                    tag.seq_number_range.map((s, i) => (
                      <Typography variant="body2" key={i}>
                        <b>
                          {s.from} - {s.to}
                        </b>
                      </Typography>
                    ))
                  ) : (
                    <Typography variant="body2">
                      <b>-</b>
                    </Typography>
                  )}
                </Box>
                <Box mt={2}>
                  <Box mb={0.5}>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      className={classes.greyText}
                      style={{ textTransform: "capitalize" }}
                    >
                      {getLang(lang, "label.BRANCH")}
                    </Typography>
                  </Box>
                  {tag.branches.length ? (
                    tag.branches.map((b, i) => (
                      <Typography variant="body2" key={i}>
                        <b>{b}</b>
                      </Typography>
                    ))
                  ) : (
                    <Typography variant="body2">
                      <b>-</b>
                    </Typography>
                  )}
                </Box>
                <Box mt={2}>
                  <Box mb={0.5}>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      className={classes.greyText}
                      style={{ textTransform: "capitalize" }}
                    >
                      {getLang(lang, "label.DATE_CREATED")}
                    </Typography>
                  </Box>
                  <Typography variant="body2">
                    <b>
                      {tag.created_at
                        ? moment
                            .utc(tag.created_at)
                            .local()
                            .format("D MMM YYYY, HH:mm")
                        : "-"}
                    </b>
                  </Typography>
                </Box>
                <Box mt={2}>
                  <Box mb={0.5}>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      className={classes.greyText}
                      style={{ textTransform: "capitalize" }}
                    >
                      {getLang(lang, "label.LAST_UPDATED_DATE")}
                    </Typography>
                  </Box>
                  <Typography variant="body2">
                    <b>
                      {tag.last_updated_at
                        ? moment
                            .utc(tag.last_updated_at)
                            .local()
                            .format("D MMM YYYY, HH:mm")
                        : "-"}
                    </b>
                  </Typography>
                </Box>
              </Box>
            </>
          ) : null}
        </Box>
      </Paper>
    </Dialog>
  );
}
