import React from "react";
import {
  makeStyles,
  Box,
  Typography,
  Grid,
  Button,
  Divider,
  FormHelperText,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { AddImage, ErrorSVG } from "../../../utils/static";
import InputTextField from "components/input/inputTextField";
import { ErrorMessage } from "formik";
import { getLang } from "app/feature/constants";
import { isFile } from "lib/helper";
import PrizeTypeSelect from "../../select/prizeTypeSelect";

const style = (theme) => ({
  addPrizeContainer: {
    border: "1px solid #3A4D54",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
    margin: theme.spacing(2, 3),
  },
  photoContainer: {
    background: "#EBEDEE",
    border: "1px solid rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
    width: "120px",
    height: "120px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
  },
  button: {
    width: "100%",
    height: "100%",
  },
  toggleControl: {
    margin: "7px 35px 16px 0px",
  },
  editPrize: {
    border: "1px solid rgba(0, 0, 0, 0.1)",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
  },
  divider: {
    height: "70%",
    display: "flex",
    alignSelf: "center",
  },
  dividerContainer: {
    backgroundColor: "#fff",
    display: "flex",
  },
  input: {
    display: "none",
  },
  deleteIcon: {
    color: "#F04438",
    cursor: "pointer",
  },
});

const useStyles = makeStyles(style);

export default function EditPrizeCardComponent({
  handleDelete,
  prizeDetail,
  lang,
  formik,
  updateDetail,
  index,
  enableWinRate,
  disabled,
  hasIldPrizeDeleteAccessRight,
  isPublished,
  transactionUse,
  instantLuckyDraw,
}) {
  const classes = useStyles();
  return (
    <Box className={classes.addPrizeContainer}>
      <Box className={classes.editPrize}>
        <Box p={2}>
          <Grid container spacing={1}>
            <Grid item xs={3} sm={3} md={3}>
              <Box>
                <Typography variant="body2" color="primary">
                  {getLang(lang, "label.PRIZE_IMAGE")}{" "}
                  <span style={{ color: "#DE350B", display: "inline-block" }}>
                    *
                  </span>
                </Typography>
                <Box className={classes.photoContainer}>
                  <input
                    accept="image/*"
                    id={`prize-file-${index}`}
                    type="file"
                    style={{ display: "none" }}
                    onChange={(e) => {
                      formik.setFieldTouched(
                        `prizeList.[${index}].prizeImage`,
                        true
                      );

                      if (e.target.files.length && e.target.files[0]) {
                        updateDetail("prizeImage", e.target.files[0]);
                      }
                    }}
                    disabled={disabled}
                  />
                  <label
                    htmlFor={`prize-file-${index}`}
                    style={{ height: "100%", width: "100%" }}
                  >
                    <Button
                      size="small"
                      className={classes.button}
                      disableElevation
                      component="span"
                      type="button"
                    >
                      {prizeDetail?.prizeImage ? (
                        <img
                          src={
                            isFile(prizeDetail.prizeImage)
                              ? URL.createObjectURL(prizeDetail.prizeImage)
                              : prizeDetail.prizeImage
                          }
                          alt="prize"
                          height="100%"
                          width="100%"
                        />
                      ) : (
                        <AddImage />
                      )}
                    </Button>
                  </label>
                </Box>
                <FormHelperText
                  error={
                    formik.errors.prizeList?.[index]?.prizeImage ? true : false
                  }
                >
                  {ErrorMessage({ name: `prizeList.[${index}].prizeImage` })}
                </FormHelperText>
              </Box>
            </Grid>
            <Grid item xs={9} sm={9} md={9}>
              <Box>
                <Typography variant="body2" color="primary">
                  {getLang(lang, "label.PRIZE_NAME")}{" "}
                  <span style={{ color: "#DE350B", display: "inline-block" }}>
                    *
                  </span>
                </Typography>
                <InputTextField
                  {...formik.getFieldProps(`prizeList.[${index}].prizeName`)}
                  value={prizeDetail.prizeName}
                  required
                  variant="filled"
                  size="small"
                  fullWidth
                  placeholder={getLang(lang, "placeholder.ENTER_NAME")}
                  onChange={(e) => {
                    updateDetail("prizeName", e.target.value);
                  }}
                  disabled={disabled}
                  error={
                    formik.touched.prizeList?.[index]?.prizeName &&
                    formik.errors.prizeList?.[index]?.prizeName
                      ? true
                      : false
                  }
                  helperText={ErrorMessage({
                    name: `prizeList.[${index}].prizeName`,
                  })}
                />

                <Box pt={2}>
                  <Typography variant="body2" color="primary">
                    {getLang(lang, "label.DESCRIPTION")}{" "}
                    <span
                      style={{
                        color: "#DE350B",
                        display: "inline-block",
                      }}
                    >
                      *
                    </span>
                  </Typography>
                  <InputTextField
                    {...formik.getFieldProps(
                      `prizeList.[${index}].description`
                    )}
                    disabled={disabled}
                    value={prizeDetail.description}
                    required
                    multiline
                    rows={3}
                    variant="filled"
                    size="small"
                    fullWidth
                    placeholder={getLang(lang, "placeholder.DESCRIPTION")}
                  />
                </Box>
                <Box pt={2}>
                  <Typography variant="body2" color="primary">
                    {getLang(lang, "label.TYPE")}{" "}
                    <span
                      style={{
                        color: "#DE350B",
                        display: "inline-block",
                      }}
                    >
                      *
                    </span>
                  </Typography>
                  <Box>
                    <PrizeTypeSelect
                      handleChange={(type) => {
                        updateDetail("type", type.id);
                        updateDetail("provider", type);
                      }}
                      value={prizeDetail.type}
                      disabled={disabled || isPublished}
                    />
                  </Box>
                  <FormHelperText
                    error={
                      formik.errors.prizeList?.[index]?.type ? true : false
                    }
                  >
                    {ErrorMessage({ name: `prizeList.[${index}].type` })}
                  </FormHelperText>
                  {prizeDetail.id &&
                  instantLuckyDraw.progress === "published" ? (
                    <Box pt={2}>
                      <Box
                        display="grid"
                        gridTemplateColumns="7fr 4fr"
                        gridColumnGap="8px"
                      >
                        <Typography
                          variant="body2"
                          color="primary"
                          style={{ textTransform: "capitalize" }}
                        >
                          {getLang(lang, "label.AVAILABLE_QUANTITY")}{" "}
                          <span
                            style={{
                              color: "#DE350B",
                              display: "inline-block",
                            }}
                          >
                            *
                          </span>
                        </Typography>
                        <Typography variant="body2" color="primary">
                          {getLang(lang, "label.WON_QUANTITY")}{" "}
                          <span
                            style={{
                              color: "#DE350B",
                              display: "inline-block",
                            }}
                          >
                            *
                          </span>
                        </Typography>
                      </Box>
                      <Box
                        display="grid"
                        gridTemplateColumns="6fr 1fr 4fr"
                        justifyItems="center"
                        gridColumnGap="8px"
                      >
                        <InputTextField
                          {...formik.getFieldProps(
                            `prizeList.[${index}].balance`
                          )}
                          value={prizeDetail.balance}
                          required
                          variant="filled"
                          size="small"
                          fullWidth
                          type="number"
                          InputProps={{
                            min: "1",
                          }}
                          placeholder={getLang(
                            lang,
                            "placeholder.ENTER_QUANTITY"
                          )}
                          disabled={disabled}
                          error={
                            formik.touched.prizeList?.[index]?.balance &&
                            formik.errors.prizeList?.[index]?.balance
                              ? true
                              : false
                          }
                          helperText={ErrorMessage({
                            name: `prizeList.[${index}].balance`,
                          })}
                          onKeyDown={(event) => {
                            const disabledKey = ["e", "E", ".", "+", "-"];
                            if (event.key.includes(disabledKey)) {
                              event.preventDefault();
                            }
                          }}
                          onChange={(e) => {
                            if (e.target.value) {
                              const regex = /^\d+$/;
                              const isValid = regex.test(e.target.value);
                              if (isValid) {
                                updateDetail("balance", e.target.value);
                              }
                            } else {
                              updateDetail("balance", e.target.value);
                            }
                          }}
                        />
                        <Box className={classes.dividerContainer}>
                          <Divider
                            orientation="vertical"
                            className={classes.divider}
                          />
                        </Box>
                        <Box display="flex" alignItems="center" width="100%">
                          <Typography variant="body1">
                            {prizeDetail.winQuantity.toLocaleString("en-US")}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  ) : (
                    <Box pt={2}>
                      <Typography variant="body2" color="primary">
                        {getLang(lang, "label.QUANTITY")}{" "}
                        <span
                          style={{ color: "#DE350B", display: "inline-block" }}
                        >
                          *
                        </span>
                      </Typography>
                      <InputTextField
                        {...formik.getFieldProps(
                          `prizeList.[${index}].quantity`
                        )}
                        value={prizeDetail.quantity}
                        required
                        variant="filled"
                        size="small"
                        fullWidth
                        type="number"
                        InputProps={{
                          min: "1",
                        }}
                        disabled={disabled}
                        placeholder={getLang(
                          lang,
                          "placeholder.ENTER_QUANTITY"
                        )}
                        error={
                          formik.touched.prizeList?.[index]?.quantity &&
                          formik.errors.prizeList?.[index]?.quantity
                            ? true
                            : false
                        }
                        helperText={ErrorMessage({
                          name: `prizeList.[${index}].quantity`,
                        })}
                        onKeyDown={(event) => {
                          const disabledKey = ["e", "E", ".", "+", "-"];
                          if (event.key.includes(disabledKey)) {
                            event.preventDefault();
                          }
                        }}
                        onChange={(e) => {
                          if (e.target.value) {
                            const regex = /^\d+$/;
                            const isValid = regex.test(e.target.value);
                            if (isValid) {
                              updateDetail("quantity", e.target.value);
                            }
                          } else {
                            updateDetail("quantity", e.target.value);
                          }
                        }}
                      />
                    </Box>
                  )}
                  {prizeDetail.type > 0 && (
                    <>
                      <Box pt={2}>
                        <Typography variant="body2" color="primary">
                          {getLang(lang, "label.CREDIT_AMOUNT_PER_WINNER")}{" "}
                          <span
                            style={{
                              color: "#DE350B",
                              display: "inline-block",
                            }}
                          >
                            *
                          </span>
                        </Typography>
                        <InputTextField
                          {...formik.getFieldProps(
                            `prizeList.[${index}].credit`
                          )}
                          value={prizeDetail.credit}
                          required
                          type="number"
                          variant="filled"
                          size="small"
                          fullWidth
                          placeholder={getLang(
                            lang,
                            "placeholder.CREDIT_AMOUNT_PER_WINNER"
                          )}
                          disabled={disabled}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment>
                                {prizeDetail.provider
                                  ? prizeDetail.provider.currency
                                  : null}
                              </InputAdornment>
                            ),
                          }}
                          onKeyDown={(event) => {
                            const disabledKey = ["e", "E", "+", "-"];
                            if (event.key.includes(disabledKey)) {
                              event.preventDefault();
                            }
                          }}
                          onChange={(e) => {
                            if (e.target.value) {
                              const regex = /^[0-9]+(\.[0-9]{1,2})?$/;
                              const isValid = regex.test(e.target.value);
                              if (isValid) {
                                updateDetail("credit", e.target.value);
                              }
                            } else {
                              updateDetail("credit", e.target.value);
                            }
                          }}
                          error={
                            formik.touched.prizeList?.[index]?.credit &&
                            formik.errors.prizeList?.[index]?.credit
                              ? true
                              : false
                          }
                          helperText={ErrorMessage({
                            name: `prizeList.[${index}].credit`,
                          })}
                        />
                      </Box>
                      {!isNaN(prizeDetail.quantity) &&
                        !isNaN(prizeDetail.credit) && (
                          <Box pt={2}>
                            <Typography variant="body1" color="primary">
                              {getLang(lang, "label.TOTAL_CREDIT_USE")}:{" "}
                              <b>
                                {prizeDetail.provider
                                  ? `${prizeDetail.provider.currency} `
                                  : null}
                                {prizeDetail.id &&
                                instantLuckyDraw.progress === "published"
                                  ? (
                                      (Number(prizeDetail.balance) +
                                        Number(prizeDetail.winQuantity)) *
                                      Number(prizeDetail.credit)
                                    ).toFixed(2)
                                  : (
                                      Number(prizeDetail.quantity) *
                                      Number(prizeDetail.credit)
                                    ).toFixed(2)}
                              </b>
                            </Typography>
                            <Typography variant="body1" color="primary">
                            {getLang(lang, "label.TOTAL_TRANSACTION_USE")}:{" "}
                              <b>
                                {transactionUse}
                              </b>
                              </Typography>
                          </Box>
                        )}
                    </>
                  )}
                </Box>
                {!!enableWinRate && (
                  <Box pt={2}>
                    <Typography variant="body2" color="primary">
                      {getLang(lang, "label.WINNING_RATE")}{" "}
                      <span
                        style={{ color: "#DE350B", display: "inline-block" }}
                      >
                        *
                      </span>
                    </Typography>
                    <InputTextField
                      {...formik.getFieldProps(`prizeList.[${index}].winRate`)}
                      value={prizeDetail.winRate}
                      required
                      variant="filled"
                      size="small"
                      fullWidth
                      type="number"
                      disabled={disabled}
                      placeholder={getLang(lang, "placeholder.ENTER_WIN_RATE")}
                      error={
                        formik.touched.prizeList?.[index]?.winRate &&
                        formik.errors.prizeList?.[index]?.winRate
                          ? true
                          : false
                      }
                      helperText={ErrorMessage({
                        name: `prizeList.[${index}].winRate`,
                      })}
                      onKeyDown={(event) => {
                        const disabledKey = ["e", "E", "+", "-"];
                        if (event.key.includes(disabledKey)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        if (e.target.value) {
                          const regex = /^[0-9]+(\.[0-9]{1,3})?$/;
                          const isValid = regex.test(e.target.value);
                          if (isValid) {
                            updateDetail("winRate", e.target.value);
                          }
                        } else {
                          updateDetail("winRate", e.target.value);
                        }
                      }}
                      InputProps={{
                        endAdornment: <InputAdornment>%</InputAdornment>,
                      }}
                    />
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <Box
          px={3}
          pt={1}
          pb={1}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box display="flex" alignItems="center">
            <ErrorSVG />
            <Typography
              variant="caption"
              style={{
                paddingLeft: "4px",
                color: "rgba(58, 77, 84, 0.33)",
              }}
            >
              {getLang(lang, "paragraph.RECOMMENDED_IMAGE_SIZE_1")}
            </Typography>
          </Box>
          {(!prizeDetail.id || !!hasIldPrizeDeleteAccessRight) &&
            !isPublished && (
              <Box ml={2}>
                <IconButton
                  onClick={() => {
                    handleDelete();
                  }}
                  disabled={disabled}
                >
                  <DeleteIcon className={classes.deleteIcon} />
                </IconButton>
              </Box>
            )}
        </Box>
      </Box>
    </Box>
  );
}
