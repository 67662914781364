import React from "react";
import { makeStyles, TextField } from "@material-ui/core";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import countries from "lib/constants/countries";
import clsx from "clsx";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles(theme => ({
  inputRoot: {
    paddingTop: "8px !important",
    paddingBottom: "7px !important"
  },
  option: {
    "& > span": {
      flex: 1
    }
  },
  label: {
    fontSize: "smaller"
  },
  paper: {
    width: "200%"
  }
}));

const filterOptions = () => createFilterOptions({
  stringify: option => option.label + option.code + option.phone,
});

export default function CountryCodeSelectComponent({ 
  textFieldProps, 
  formProps, 
  textFieldStyle, 
  placeholder,
  lang
}) {
  const classes = useStyle();
  return (
    <Autocomplete
      {...formProps}
      fullWidth={textFieldProps.fullWidth || false}
      options={countries}
      noOptionsText={getLang(lang, "label.NO_RESULT_FOUND")}
      classes={{
        option: classes.option,
        paper: classes.paper,
        inputRoot: clsx({
          [classes.inputRoot]: textFieldProps.variant === "filled"
        })
      }}
      autoHighlight
      filterOptions={filterOptions()}
      getOptionLabel={option => `${option.code} ${option.phone}`}
      renderOption={option => (
        <React.Fragment>
          <span>{option.label} ({option.code})</span> {option.phone}
        </React.Fragment>
      )}
      renderInput={params => {
        return (
          <TextField
            name="phone code"
            {...params}
            {...textFieldProps}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              style: {
                ...textFieldStyle,
                padding: "5px",
                borderRadius: "4px",
              }
            }}
            placeholder={placeholder}
            inputProps={{
              ...params.inputProps,
              style: {
                paddingLeft: "8px"
              },
              autoComplete: "phone" // disable autocomplete and autofill
            }}
          />
        );
      }}
    />
  );
}