import axios from "axios";

const updateBatchRedirectTo = ({ uuid, redirect_to }) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`/serialnumber/api/v1/generate/status/redirect-update/${uuid}`, {
        redirect_to,
      })
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

export default updateBatchRedirectTo;
