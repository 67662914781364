import React, { useRef } from "react";
import { makeStyles, Button, Box } from "@material-ui/core";
import InputTextField from "components/input/inputTextField";
import { getLang } from "app/feature/constants";
import ProductSelect from "../../select/productSelect";

const useStyle = makeStyles((theme) => ({
  searchContainer: {
    backgroundColor: theme.palette.background.paper,
    display: "grid",
    gridTemplateColumns: "6fr 6fr 3fr 3fr",
    columnGap: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1)
    }
  },
  button: {
    [theme.breakpoints.up("sm")]: {
      width: "fit-content",
      justifySelf: "flex-end"
    }
  },
  inputRoot: {
    paddingTop: "8px !important",
    paddingBottom: "7px !important",
    fontSize: "0.875rem",
    fontWeight: "500"
  }
}));

export default function WithProductGeneralSearchComponent({
  handleSearch,
  lang,
  productDropdown,
  isFetchingProduct,
  productsSelected,
  setProductsSelected,
  handleClear,
}) {
  const classes = useStyle();
  const textRef = useRef();

  return (
    <Box p={2} mb={2} className={classes.searchContainer}>
      <InputTextField
        variant="filled"
        inputRef={textRef}
        size="small"
        placeholder={getLang(lang, "placeholder.SEARCH")}
        margin="none"
        InputProps={{
          disableUnderline: true,
          margin: "none",
          style: {
            borderRadius: "4px",
            backgroundColor: "#ECEFF0",
          },
          classes: { input: classes.inputRoot },
        }}
        onKeyPress={(e) => {
          if (e.key === 'Enter')
          handleSearch(textRef.current.value)
        }}
      />
      <ProductSelect
        searchable={true}
        value={productsSelected}
        placeholder={getLang(lang, 'placeholder.SELECT_A_PRODUCT')}
        searchPlaceholder={getLang(lang, 'placeholder.SEARCH_A_PRODUCT_NAME')}
        isClearable={true}
        emptyMessage={getLang(lang, 'message.info.NO_PRODUCT_FOUND')}
        dropdownItem={productDropdown}
        isFetching={isFetchingProduct}
        handleChange={(value) => {
          let temp = [...productsSelected];
          if (temp.length === 0) {
            temp.push(value);
          }
          else {
            const isProductExist = temp.find((product) => product.id === value.id);

            if (isProductExist === undefined) {
              temp.push(value);
            }
            else {
              let filter = temp.filter(product => product.id !== value.id);
              temp = [...filter];
            }
          }

          setProductsSelected(temp);
        }}
        handleDeleteChip={(value) => {
          let temp = [...productsSelected];

          const isProductExist = temp.find(product => product.id === value.id);
          if (isProductExist) {
            let filter = temp.filter(product => product.id !== value.id);
            temp = [...filter];

            setProductsSelected(temp);
          }
        }}
        handleClear={() => {
          setProductsSelected([]);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        isEmpty={productDropdown.length === 0}
        disabled={false}
      />
      <Button
        color="primary"
        variant="text"
        size="small"
        disableElevation
        onClick={() => {
          textRef.current.value = ""
          handleClear();
        }}
      >
        {getLang(lang, "label.CLEAR")}
      </Button>
      <Button
        variant="contained"
        color="primary"
        disableElevation
        type="submit"
        size="small"
        onClick={() => handleSearch(textRef.current.value)}
      >
        {getLang(lang, "label.SEARCH")}
      </Button>
    </Box>
  );
}
