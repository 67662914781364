import React, { useState } from "react";
import ProductInfoPanelComponent from "./productInfoPanel.component";
import { connect, useSelector } from "react-redux";
import { updateProductInfo } from "modules/product-mgmt/redux/action/products.action";
import { selectLoading } from "modules/notification";
import { unwrapResult } from "@reduxjs/toolkit";
import { PRODUCT_PRODUCT_LIST_EDIT } from "lib/constants/accessRights";

function ProductInfoPanelContainer({
  isSaving,
  updateProductInfo,
  createdAt,
  updatedAt
}) {
  const hasProductEditAccessRight = useSelector(state => state.profile.accessRights.includes(PRODUCT_PRODUCT_LIST_EDIT))
  const [isEditing, setIsEditing] = useState(false);
  const lang = useSelector(state => state.constant.languages);
  const language = useSelector(state => state.profile.language);

  const handleEdit = value => {
    setIsEditing(value);
  };

  const handleCancel = formik => {
    formik.resetForm();
    setIsEditing(false)
  }

  const handleSaveChanges = () => {
    updateProductInfo()
      .then(unwrapResult)
      .then(() => {
        setIsEditing(false);
      });
  };

  return (
    <ProductInfoPanelComponent
      hasProductEditAccessRight={hasProductEditAccessRight}
      isEditing={isEditing}
      handleEdit={handleEdit}
      handleCancel={handleCancel}
      handleSaveChanges={handleSaveChanges}
      isSaving={isSaving}
      createdAt={createdAt}
      updatedAt={updatedAt}
      language={language}
      lang={lang}
    />
  );
}

const mapDispatchToProps = dispatch => ({
  updateProductInfo: () => dispatch(updateProductInfo())
});

const mapStateToProps = state => ({
  isSaving: selectLoading(state, updateProductInfo.typePrefix),
  createdAt: state.productPage.createdAt,
  updatedAt: state.productPage.updatedAt
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductInfoPanelContainer);
