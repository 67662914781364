import React from "react";
import { makeStyles, Box, Button, Typography } from "@material-ui/core";
import {
  AddCircleOutline as AddCircleOutlineIcon,
  RemoveCircleOutline as RemoveCircleOutlineIcon,
} from "@material-ui/icons";
import { ErrorMessage, Field, FieldArray } from "formik";
import InputTextField from "components/input/inputTextField";
import { getLang } from "app/feature/constants";
// import AntSwitch from "components/switch/switch";
// import {  Help as HelpIcon, } from "@material-ui/icons";

const useStyle = makeStyles((theme) => ({
  input: {
    border: "2px solid #D0D5DD",
    borderRadius: "8px !important",
    backgroundColor: "white !important",
  },
  availableRangeCard: {
    boxShadow: "0px 0px 4px 0px #adadad",
    marginTop: 15,
    marginBottom: 10,
    position: "relative",
    "&::after": {
      content: `''`,
      position: "absolute",
      width: 11,
      height: 11,
      transform: "rotate(45deg)",
      top: -5,
      left: 95,
      backgroundColor: "white",
      boxShadow: "-2px -2px 2px -1px #dadada",
    },
  },
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    "& th:last-child, td:last-child": {
      width: 65,
      paddingRight: theme.spacing(3),
    },
    "& th,td": {
      fontSize: "small",
      borderBottom: "0",
      padding: theme.spacing(1, 2, 1, 2),
      color: theme.palette.primary.main,
    },
    "& th": {
      borderBottom: "2px solid rgba(224, 224, 224, 1)",
      fontWeight: "bold",
    },
  },
  notAvailableRange: {
    color: "#b1b1b1 !important",
  },
  stripedRow: {
    backgroundColor: "#ececec",
  },
  balanceLabel: {
    fontSize: 13,
    display: "inline",
    marginRight: 10,
  },
  balanceValue: {
    fontSize: 13,
    fontWeight: "bold",
    display: "inline",
  },
  deleteGrid: {
    display: "flex",
    justifyContent: "center",
  },
  deleteButton: {
    border: "2px solid #E4E7EC",
    borderRadius: 4,
    color: "#98A2B3",
    height: 47,
    minWidth: 47,
    padding: 0,
  },
  addButton: {
    border: "2px solid #E4E7EC",
    borderRadius: 4,
    color: "#6AAF68",
    height: 47,
    minWidth: 47,
    padding: 0,
  },
  quantityLabel: {
    display: "inline",
    marginRight: 10,
  },
  quantityValue: {
    fontWeight: "bold",
    display: "inline",
  },
  addButtonLabel: {
    fontSize: 12,
  },
}));

function RenderFormikField({ name, formik, onChange, disabled, placeholder }) {
  const classes = useStyle();
  return (
    <>
      <Field name={name}>
        {({ field, form, meta }) => (
          <InputTextField
            {...field}
            hiddenLabel={true}
            variant="filled"
            fullWidth
            type="number"
            size="small"
            disabled={disabled}
            placeholder={placeholder}
            onKeyDown={(e) => {
              const invalidChars = ["-", "+", "e", "."];
              if (invalidChars.includes(e.key)) {
                e.preventDefault();
              }
            }}
            inputProps={{
              min: 0,
            }}
            error={meta.error && meta.touched ? true : false}
            helperText={ErrorMessage({ name: field.name })}
            onChange={onChange}
            InputProps={{
              className: classes.input,
            }}
          />
        )}
      </Field>
    </>
  );
}

export default function CustomRangePanelComponent({
  formik,
  addAlert,
  onCustomRangeChange,
  isSameRange,
  lang,
  isExceedLimit,
  isInputDisable,
  availableQuantity,
  submit,
}) {
  const classes = useStyle();

  return (
    <Box mt="10px">
      <FieldArray name="generateRanges">
        {(arrayHelper) => {
          return (
            <>
              {formik.values.generateRanges.map((range, i) => {
                return (
                  <Box display="flex" mt={i === 0 ? 0 : 1} key={i}>
                    <Box display="flex" flex={1} mr={1}>
                      <RenderFormikField
                        placeholder={getLang(
                          lang,
                          "placeholder.ENTER_A_DIGIT_RANGE"
                        )}
                        name={`generateRanges.${i}.start`}
                        formik={formik}
                        onChange={(e) =>
                          onCustomRangeChange(
                            e,
                            formik,
                            `generateRanges.${i}.start`,
                            i
                          )
                        }
                        isSameRange={isSameRange}
                        disabled={isInputDisable}
                      />
                      <Box mx={1.5} mt={1.25}>
                        <Typography>
                          <b>-</b>
                        </Typography>
                      </Box>
                      <RenderFormikField
                        placeholder={getLang(
                          lang,
                          "placeholder.ENTER_A_DIGIT_RANGE"
                        )}
                        name={`generateRanges.${i}.end`}
                        formik={formik}
                        onChange={(e) =>
                          onCustomRangeChange(
                            e,
                            formik,
                            `generateRanges.${i}.end`,
                            i
                          )
                        }
                        isSameRange={isSameRange}
                        disabled={isInputDisable}
                      />
                    </Box>
                    <Box display="flex">
                      <Button
                        style={{
                          marginRight: 8,
                          opacity:
                            formik.values.generateRanges.length > 1 ? 1 : 0.5,
                        }}
                        classes={{ root: classes.deleteButton }}
                        onClick={() => {
                          if (formik.values.generateRanges.length > 1) {
                            arrayHelper.remove(i);
                          } else {
                            addAlert(
                              getLang(
                                lang,
                                "message.warning.AT_LEAST_ONE_RANGE"
                              )
                            );
                          }
                        }}
                      >
                        <RemoveCircleOutlineIcon />
                      </Button>
                      <Button
                        classes={{ root: classes.addButton }}
                        onClick={() => {
                          if (!isInputDisable) {
                            if (formik.values.generateRanges.length < 10) {
                              arrayHelper.push({
                                start: "",
                                end: "",
                              });
                            } else {
                              addAlert(
                                getLang(
                                  lang,
                                  "message.warning.MAXIMUM_TEN_RANGES"
                                )
                              );
                            }
                          }
                        }}
                      >
                        <AddCircleOutlineIcon />
                      </Button>
                    </Box>
                  </Box>
                );
              })}
              <Box>
                {isSameRange && (
                  <span
                    style={{
                      color: "#f44336",
                      fontSize: "0.75rem",
                      margin: "0px",
                    }}
                  >
                    {getLang(lang, "label.SIMILAR_RANGE_DETECTED")}
                  </span>
                )}
              </Box>
              <Box>
                {isExceedLimit && (
                  <span
                    style={{
                      color: "#f44336",
                      fontSize: "0.75rem",
                      margin: "0px",
                    }}
                  >
                    {getLang(lang, "label.MAX_ALLOWED_VALUE_COUNT", {
                      value: "9".repeat(formik.values.digitLength),
                    })}
                  </span>
                )}
              </Box>
            </>
          );
        }}
      </FieldArray>
      <Box display="flex" justifyContent="flex-end" mt={1}>
        {/* <Box display="flex" alignItems="center" mr={1.5}>
          <AntSwitch
            checked={formik.values.forceReassign}
            onChange={(e) => {
              formik.setFieldValue("forceReassign", e.target.checked);
            }}
          />
          <Box mx={0.5}>
            <Typography variant="body2">
              {getLang(lang, "label.FORCE_REASSIGN_SERIAL_NUMBER")}
            </Typography>
          </Box>
          <HelpIcon fontSize="small" />
        </Box> */}
        <Button
          variant="contained"
          color="secondary"
          classes={{ root: classes.btnSecondary }}
          onClick={() => submit(formik)}
        >
          {getLang(lang, "label.CHECK_AVAILABILITY")}
        </Button>
      </Box>
    </Box>
  );
}
