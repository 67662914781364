import React from "react";
import {
  Backdrop,
  Fade,
  Dialog,
  useTheme,
  useMediaQuery
} from "@material-ui/core";

export default function CustomDialogComponent({
  isOpen,
  handleClose,
  children,
  maxWidth,
  ...props
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      fullScreen={isMobile}
      PaperProps={{
        style: {
          maxWidth: maxWidth,
        }
      }}
      {...props}
    >
      <Fade in={isOpen}>
        <div>{children}</div>
      </Fade>
    </Dialog>
  );
}

CustomDialogComponent.defaultProps = {
  maxWidth: 700
}
