import axios from "axios";

const updateSerialNumberStatus = ({
  enc,
  status
}) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`/serialnumber/api/v1/serialnumber/status/update/${enc}`, {
        status,
      })
      .then(response => resolve({...response.data, enc, status}))
      .catch(error => {
        return reject(error.response.data)
      });
  });
};

export default updateSerialNumberStatus;
