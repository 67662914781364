import React from "react";
import {
  makeStyles,
  Box,
  Typography,
  Divider,
  Grid,
  Button,
  withStyles
} from "@material-ui/core";
import { UndoSVG } from "../../../utils/static";
import { Formik } from "formik";
import * as Yup from "yup";
import SetWinnerSelect from "../../select/setWinnerSelect";
import ViewWinnerTable from "../../table/viewWinnerTable";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { getLang } from "app/feature/constants";

const style = (theme) => ({
  viewPrizeContainer: {
    border: "1px solid rgba(0, 0, 0, 0.1)",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
    margin: theme.spacing(3)
  },
  viewPrizePhoto: {
    width: 100,
    height: 100,
    display: "flex",
    alignItems: "center",
    padding: "4px",
    placeContent: "center"
  },
  button: {
    minWidth: 0,
    padding: 13
  },
  publishButton: {
    color: theme.palette.common.white,
    marginLeft: theme.spacing(1)
  },
  discardButton: {
    color: theme.palette.error.main
  }
});

const Accordion = withStyles((theme) => ({
  root: {
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto"
    }
  },
  expanded: {}
}))(MuiAccordion);

const AccordionSummary = withStyles((theme) => ({
  root: {
    padding: "0 16px",
    borderBottom: "1px solid rgba(34, 45, 48, 0.15)",
    borderRadius: "0px 0px 10px 10px",
    marginBottom: -1,
    minHeight: 49,
    "&$expanded": {
      minHeight: 49,
      borderRadius: "0px"
    }
  },
  content: {
    justifyContent: "space-between",
    minHeight: 49,
    display: "flex",
    alignItems: "center",
    margin: 0,
    "&$expanded": {
      margin: "0"
    }
  },
  expandIcon: {
    color: theme.palette.primary.main,
    background: "rgba(58, 77, 84, 0.1)",
    borderRadius: "6px",
    padding: "4px",
    margin: "0px"
  },
  expanded: {}
}))(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(0),
    display: "block"
  }
}))(MuiAccordionDetails);

const useStyles = makeStyles(style);

export default function SetWinnerCardComponent({
  prizeDetail,
  handleSubmit,
  handleRandomPickWinnerForOnePrize,
  handleSetPrize,
  winnerType,
  isRandomParticipantLoading,
  lang
}) {
  const classes = useStyles();
  const viewType = winnerType === "view" ? true : false;

  return (
    <Box>
      <Formik
        enableReinitialize
        initialValues={{
          setWinner: prizeDetail.winners,
        }}
        validationSchema={Yup.object({})}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <Box className={classes.viewPrizeContainer}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography
                    variant="body2"
                    color="primary"
                    style={{ fontWeight: "bold" }}
                  >
                    {prizeDetail.prizeLabel}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box display="grid" gridTemplateColumns="1.5fr 5fr 1fr">
                    <Box className={classes.viewPrizePhoto}>
                      <img
                        src={prizeDetail.prizeImage}
                        alt="example"
                        height="100%"
                        width="100%"
                        style={{ objectFit: "contain" }}
                      />
                    </Box>

                    <Box p={2} width="100%">
                      <Box display="flex" pt={1} pb={1}>
                        <Typography
                          variant="body2"
                          style={{ color: " rgba(58, 77, 84, 0.33)" }}
                        >
                          {getLang(lang, "label.PRIZE_NAME")}:&nbsp;
                        </Typography>
                        <Typography
                          variant="body2"
                          color="primary"
                          style={{ fontWeight: "bold" }}
                        >
                          {prizeDetail.prizeName}
                        </Typography>
                      </Box>

                      <Box display="grid" gridTemplateColumns="2fr 6fr">
                        <div style={{ display: "flex" }}>
                          <Typography
                            variant="body2"
                            style={{ color: " rgba(58, 77, 84, 0.33)" }}
                          >
                            {getLang(lang, "label.QUANTITY")}:&nbsp;
                          </Typography>
                          <Typography
                            variant="body2"
                            color="primary"
                            style={{ fontWeight: "bold" }}
                          >
                            {prizeDetail.quantity.toLocaleString("en-US")}
                          </Typography>
                        </div>
                      </Box>

                      <Box display="flex" pt={1} pb={1}>
                        <div style={{ display: "flex" }}>
                          <Typography
                            variant="body2"
                            style={{ color: " rgba(58, 77, 84, 0.33)" }}
                          >
                            {getLang(lang, "label.DRAW_PRIZE_METHOD")}:&nbsp;
                          </Typography>
                          <Typography
                            variant="body2"
                            color="primary"
                            style={{ fontWeight: "bold" }}
                          >
                            {prizeDetail.drawMethod === 'One by One' ? getLang(lang, 'label.ONE_BY_ONE')
                              : prizeDetail.drawMethod === 'All at Once' ? getLang(lang, 'label.ALL_AT_ONCE')
                                : prizeDetail.drawMethod}
                            {/* QUICK FIX FOR LANGUAGES TRANSLATION */}

                          </Typography>
                        </div>
                      </Box>
                    </Box>
                  </Box>

                  <Divider />

                  {viewType ? (
                    <ViewWinnerTable data={prizeDetail.winners} lang={lang} />
                  ) : (
                    <Box p={2}>
                      <Grid
                        container
                        spacing={1}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        {prizeDetail.winners.map((v, index) => (
                          <React.Fragment key={index}>
                            <Grid item xs={2} sm={2} md={2}>
                              <Typography variant="body2" color="primary">
                                {getLang(lang, "label.WINNER")} #{index + 1} <span style={{ color: "#DE350B", display: 'inline-block' }}>*</span>
                              </Typography>
                            </Grid>
                            <Grid item xs={9} sm={9} md={9}>
                              <SetWinnerSelect
                                value={formik.values.setWinner[index]}
                                handleChange={(value, id) =>
                                  handleSetPrize(value, index, id)
                                }
                              />
                            </Grid>

                            <Grid item xs={1} sm={1} md={1}>
                              {!viewType ? (
                                <Box display="flex" justifyContent="center">
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    disabled={isRandomParticipantLoading}
                                    onClick={() =>
                                      handleRandomPickWinnerForOnePrize(index)
                                    }
                                  >
                                    <UndoSVG />
                                  </Button>
                                </Box>
                              ) : null}
                            </Grid>
                          </React.Fragment>
                        ))}
                      </Grid>
                    </Box>
                  )}
                </AccordionDetails>
              </Accordion>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
}
