import React, { useState, useRef, useEffect } from "react";
import WarrantyFormAssignProductSelectComponent from "./warrantyFormAssignProductSelect.component";
import WarrantyFormAssignProductPanelComponent from "./warrantyFormAssignProductPanel.component";
import PropTypes from "prop-types";
import { selectLoading } from "modules/notification";
import { useSelector, useDispatch } from "react-redux";
import { getProductDropdown } from "modules/product-mgmt/redux/action/products.action";

function WarrantyFormAssignProductSelectContainer({
  placeholder,
  handleChange,
  value,
  disabled,
  isEdit
}) {

  const [open, setOpen] = useState(false);
  const [isOpenList] = useState(false);
  const anchorRef = useRef(null);
  const prevOpen = useRef(open);
  const prevOpenList = useRef(isOpenList);
  const [search, setSearch] = useState("")
  const dispatch = useDispatch()

  const productDropdown = useSelector(
    (state) => state.products.productsDropdown
  );
  const warrantySettingId = useSelector((state) => state.warranty.warrantySetting.id);
  const isProductDropdownLoading = useSelector((state) =>
    selectLoading(state, getProductDropdown.typePrefix)
  );

  useEffect(() => {
    dispatch(getProductDropdown({
      search: search
    }));
  }, [search, dispatch]);

  const handleClose = () => {
    setOpen(false);
    setSearch("");
  };

  const handleClick = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    if (prevOpenList.current === true && isOpenList === false) {
      anchorRef.current.focus();
    }

    prevOpenList.current = isOpenList;
  }, [isOpenList]);

  function handleListKeyDown(v) {

    if (v.warranty_setting_id && !isEdit) {
      return;
    }
    if ((v.warranty_setting_id !== null)) {
      if (v.warranty_setting_id !== warrantySettingId) {
        return;
      }
    }
    const temp = value.slice();

    const index = temp.findIndex(
      (t) => t.id === v.id
    );

    if (index === -1) {
      temp.push(v);
    } else {
      temp.splice(index, 1);
    }
    handleChange(temp);
  }

  const handleSearch = async (values) => {
    await setSearch(values)
  }

  return (
    <>
      <WarrantyFormAssignProductSelectComponent
        anchorRef={anchorRef}
        handleClick={handleClick}
        value={value}
        placeholder={placeholder}
        disabled={disabled}
      />
      <WarrantyFormAssignProductPanelComponent
        value={value}
        anchorRef={anchorRef}
        open={open}
        isOpenList={isOpenList}
        handleClose={handleClose}
        isFetching={isProductDropdownLoading}
        handleListKeyDown={handleListKeyDown}
        productDropdown={productDropdown}
        handleSearch={handleSearch}
        warrantySettingId={warrantySettingId}
      />
    </>
  );
}
WarrantyFormAssignProductSelectContainer.defaultProps = {
  placeholder: "Select product(s)"
};

WarrantyFormAssignProductSelectContainer.propTypes = {
  placeholder: PropTypes.string
};


export default (WarrantyFormAssignProductSelectContainer);
