/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import RequestTransferBranchSelectComponent from "./requestTransferBranchSelect.component";
import RequestTransferBranchSelectPanelComponent from "./requestTransferBranchSelectPanel.component";
import PropTypes from "prop-types";
import { selectLoading } from "modules/notification";
import { useDispatch, useSelector } from "react-redux";
import {
  getRequestTransferRequestBranches,
  getRequestTransferTargetBranches,
  resetRequestTransferRequestBranchDropdown,
  resetRequestTransferTargetBranchDropdown,
} from "modules/stockflow/redux";

function RequestTransferBranchSelectContainer({
  placeholder,
  handleChange,
  value,
  disabled,
  styles,
  textStyles,
  type,
  fromBranch,
  toBranch,
}) {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.constant.languages);
  const branches = useSelector(
    (state) =>
      state.stockflowRequestTransfer[
        type === "to" ? "targetBranches" : "requestorBranches"
      ].list
  );
  const totalFiltered = useSelector(
    (state) =>
      state.stockflowRequestTransfer[
        type === "to" ? "targetBranches" : "requestorBranches"
      ].totalFiltered
  );
  const isError = useSelector(
    (state) =>
      state.stockflowRequestTransfer[
        type === "to" ? "targetBranches" : "requestorBranches"
      ].isError
  );
  const isFetching = useSelector((state) =>
    selectLoading(
      state,
      type === "to"
        ? getRequestTransferTargetBranches.typePrefix
        : getRequestTransferRequestBranches.typePrefix
    )
  );

  const isFromFetching = useSelector(state => selectLoading(state, getRequestTransferRequestBranches.typePrefix));

  const isToFetching = useSelector(state => selectLoading(state, getRequestTransferTargetBranches.typePrefix));

  const [branchList, setBranchList] = useState([]);
  const [open, setOpen] = useState(false);
  const [isOpenList] = useState(false);
  const anchorRef = useRef(null);
  const prevOpen = useRef(open);
  const prevOpenList = useRef(isOpenList);

  const [filter, setFilter] = useState({
    length: 25,
    page: 0,
    search: "",
  });

  const fromBranchRef = useRef(fromBranch);

  useEffect(() => {
    if (type === "from") {
      setBranchList(branches);
    } else {
      if (fromBranch)
        setBranchList(branches.filter((b) => b.uuid !== fromBranch.uuid));
      else setBranchList(branches);
    }
  }, [branches]);

  useEffect(() => {
    if (type === "from") {
      handleDataLoad();
    }
  }, [dispatch, filter]);

  useEffect(() => {
    if (type !== "from" && fromBranch) {
      if (
        fromBranchRef.current &&
        fromBranchRef.current.uuid !== fromBranch.uuid
      ) {
        handleReload();
      } else {
        handleDataLoad();
      }
    }
    fromBranchRef.current = fromBranch;
  }, [dispatch, fromBranch, filter]);

  useEffect(() => {
    if (!value && type === "from" && branches.length) {
      handleChange(branches[0]);
    }
  }, [branches, value]);

  const handleDataLoad = () => {
    if (type === "from") {
      dispatch(
        getRequestTransferRequestBranches({
          search: "",
          length: filter.length,
          start: filter.page * filter.length,
        })
      );
    } else {
      dispatch(
        getRequestTransferTargetBranches({
          search: filter.page * filter.length,
          length: filter.length,
          start: 0,
          from_node_uuid: fromBranch.uuid,
        })
      );
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    if (prevOpenList.current === true && isOpenList === false) {
      anchorRef.current.focus();
    }

    prevOpenList.current = isOpenList;
  }, [isOpenList]);

  const handleSelectBranch = (branch) => {
    if (!value || value.uuid !== branch.uuid) {
      handleChange(branch);
      if (type === "from") {
        dispatch(resetRequestTransferTargetBranchDropdown());
      }
    }
    handleClose();
  };

  const handleFetchMoreItems = () => {
    setFilter({
      ...filter,
      page: filter.page + 1,
    });
  };

  const handleReload = () => {
    if (type === "from") {
      dispatch(resetRequestTransferRequestBranchDropdown());
    } else {
      dispatch(resetRequestTransferTargetBranchDropdown());
    }
    setFilter({
      length: 25,
      page: 0,
      search: "",
    });
  };
  return (
    <>
      <RequestTransferBranchSelectComponent
        anchorRef={anchorRef}
        handleClick={handleClick}
        value={value}
        styles={styles}
        placeholder={placeholder}
        disabled={disabled}
        branchDropdown={branches}
        textStyles={textStyles}
        lang={lang}
        isFromFetching={isFromFetching}
        isToFetching={isToFetching}
        type={type}
      />
      <RequestTransferBranchSelectPanelComponent
        value={value}
        anchorRef={anchorRef}
        open={open}
        isOpenList={isOpenList}
        handleClose={handleClose}
        isFetching={isFetching}
        totalRecords={branches.length}
        branchDropdown={branchList}
        handleSelectBranch={handleSelectBranch}
        lang={lang}
        handleFetchMoreItems={handleFetchMoreItems}
        totalFiltered={totalFiltered}
        isError={isError}
        handleReload={handleReload}
      />
    </>
  );
}
RequestTransferBranchSelectContainer.defaultProps = {
  placeholder: "Select Branch(s)",
};

RequestTransferBranchSelectContainer.propTypes = {
  placeholder: PropTypes.string,
};

export default RequestTransferBranchSelectContainer;
