import { selectLoading } from "modules/notification"
import { getScanTrackDetail } from "modules/serial-number/redux"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import ScanTrackDetailTimelineComponent from './scanTrackDetailTimeline.component'

function ScanTrackDetailTimelineContainer({
  scan_id,
  expanded,
  lang,
  language
}) {
  const dispatch = useDispatch();
  const detailList = useSelector(state => state.serialNumber.scanTrackDetail[scan_id] ?? []);
  const isLoading = useSelector(state => selectLoading(state, getScanTrackDetail.typePrefix) && state.serialNumber.scanTrackDetailFetchingIds.includes(scan_id));

  useEffect(() => {
    if (scan_id && expanded && detailList.length === 0) {
      dispatch(getScanTrackDetail(scan_id))
    }
  }, [scan_id, expanded, dispatch, detailList.length])

  return (
    <ScanTrackDetailTimelineComponent 
      detailList={detailList} 
      isLoading={isLoading}
      lang={lang}
      language={language}
    />
  )
}

export default ScanTrackDetailTimelineContainer
