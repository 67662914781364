import React from "react";
import { useSelector } from "react-redux";
import GeneralSearchComponent from "./generalSearch.component";

export default function GeneralSearchContainer({ handleFilter }) {
  const handleSearch = async (values) => {
    await handleFilter(values);
  };
  const lang = useSelector(state => state.constant.languages)

  return <GeneralSearchComponent handleSearch={handleSearch} lang={lang}/>;
}
