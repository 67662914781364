import React, { useEffect, useCallback } from "react";
import NewEmployeePanelComponent from "./newEmployeePanel.component";
import { ROOT as EMPLOYEE_ROOT } from "modules/employee";
import { useHistory } from "react-router-dom";
import { selectLoading } from "modules/notification";
import {
  fetchNewEmployee,
  fetchMoreNewEmployee,
} from "modules/organisation-structure/redux";
import { useDispatch, useSelector } from "react-redux";

export default function NewEmployeePanelContainer() {
  const dispatch = useDispatch();
  const history = useHistory();
  const isFetching = useSelector((state) =>
    selectLoading(state, fetchNewEmployee.typePrefix)
  );
  const hasMoretoLoad = useSelector(
    (state) => state.organisationStructure.newEmployeeList.hasMoretoLoad
  );
  const newEmployeeData = useSelector(
    (state) => state.organisationStructure.newEmployeeList.data
  );
  const isLoadingNewError = useSelector(
    (state) => state.organisationStructure.isLoadingNewError
  );
  const lang = useSelector(state => state.constant.languages)

  useEffect(() => {
    dispatch(fetchNewEmployee());
  }, [dispatch]);

  const fetchMoreData = useCallback(() => {
    if (!isFetching && hasMoretoLoad) {
      dispatch(fetchMoreNewEmployee());
    }
  }, [isFetching, hasMoretoLoad, dispatch]);

  const handleReload = () => {
    dispatch(fetchNewEmployee());
  };

  async function handleView(id) {
    history.push(`${EMPLOYEE_ROOT}/View/${id}`);
  }

  return (
    <NewEmployeePanelComponent
      handleView={handleView}
      fetchMoreData={fetchMoreData}
      newEmployeeData={newEmployeeData}
      isFetching={isFetching}
      isLoadingNewError={isLoadingNewError}
      handleReload={handleReload}
      lang={lang}
    />
  );
}
