import axios from "axios";

const index = ({ companyUuid, length, start, column, search, status, branch, product }) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`serialnumber/api/v1/warranty-reimbursement`, {
        uuid: companyUuid,
        length: length,
        start: start,
        column: column,
        search: search === "" ? null : search,
        status: status,
        branch: branch,
        product: product
      })
      .then((response) => resolve(response.data))
      .catch(error => reject(error.response.data));
  });
};

const show = (id) => {
  return new Promise((resolve, reject) => {
    axios.get(`serialnumber/api/v1/warranty-reimbursement/show/${id}`)
      .then((response) => resolve(response.data))
      .catch(error => reject(error.response.data));
  });
};

const create = (info) => {
  return new Promise((resolve, reject) => {
    axios.post(`serialnumber/api/v1/warranty-reimbursement/create`, info)
      .then((response) => resolve(response.data))
      .catch(error => reject(error.response.data));
  });
}

const process = (info) => {
  return new Promise((resolve, reject) => {
    axios.post(`serialnumber/api/v1/warranty-reimbursement/process`, info)
      .then((response) => resolve(response.data))
      .catch(error => reject(error.response.data));
  });
}

const update = (info) => {
  return new Promise((resolve, reject) => {
    axios.post(`serialnumber/api/v1/warranty-reimbursement/update`, info)
      .then((response) => resolve(response.data))
      .catch(error => reject(error.response.data));
  });
}

const warrantyReimbursement = {
  index,
  show,
  create,
  process,
  update
};

export default warrantyReimbursement;