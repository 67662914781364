import React from "react";
import {
  makeStyles,
  Box,
  Typography,
  IconButton,
} from "@material-ui/core";
import Header from "../../components/header"
import Footer from 'modules/trackco/components/footer';
import googlePlay from "../../utils/image/googlePlay.png";
import appStore from "../../utils/image/appStore.png";
import phone from "../../utils/image/phone.png";

const style = theme => ({
  mainContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
  container: {
    padding: "40px 30px 40px 30px",
    backgroundColor: "#ffffff",
    borderRadius: "4px",
    marginBottom: "60px"
  },
  title: {
    fontWeight: 700,
    fontSize: "24px",
    fontFamily: "Montserrat",
    color: "#344054"
  },
  description: {
    fontFamily: "Montserrat",
    fontWeight: 400,
    fontSize: "18px",
    marginBottom: "70px"
  },
  downloadContainer: {
    background: "linear-gradient(to bottom,#4444DD, #141457)",
    borderRadius: "19px",
    padding: "26px 30px"
  },
  downloadTitle: {
    fontFamily: "Montserrat",
    fontWeight: 700,
    color: "#ffffff",
    fontSize: "18px"
  },
  linkContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "40px",
  },
  link: {
    fontFamily: "Montserrat",
    fontWeight: 700,
    fontSize: "16px"
  },
  phone: {
    position: "absolute",
    top: "46%",
    right: "28%",
    [theme.breakpoints.up('xs')]: {
      display: "none",
    },
    '@media(min-width: 700px)': {
      display: "block",
      top: "37%",
      right: "2%",
    },
    '@media(min-width: 800px)': {
      top: "37%",
      right: "8%",
    },
    [theme.breakpoints.up('md')]: {
      top: "37%",
      right: "15%",
    },
    [theme.breakpoints.up('lg')]: {
      top: "37%",
      right: "24%",
    },
    '@media(min-width: 1400px)': {
      top: "37%",
      right: "26%",
    },
    '@media(min-width: 1536px)': {
      top: "39%",
      right: "28%",
    },
    '@media(min-width: 1600px)': {
      top: "39%",
      right: "29%",
    },
    '@media(min-width: 1700px)': {
      top: "39%",
      right: "30%",
    },
    '@media(min-width: 1800px)': {
      top: "39%",
      right: "31%",
    },
    [theme.breakpoints.up('xl')]: {
      top: "47%",
      right: "32%",
    }

  }
});

const useStyle = makeStyles(style);

export default function LoginSuccessPage({
  branchDetail,
}) {
  const classes = useStyle();

  return (
    <Box className={classes.mainContainer}>
      <Box style={{ width: "502px" }}>
        <Box style={{ marginBottom: "60px" }}>
          <Header justifyContent="center" />
        </Box>
        <Box className={classes.container}>
          <Box paddingBottom="20px">
            <Typography variant='h5' className={classes.title}>Successfully Joined the Company!</Typography>
          </Box>
          <Box className={classes.description}>
            <Typography variant='body1' className={classes.description}>
              You can now access <b>{branchDetail.name}</b> through the Trackco app. Login and explore your new workspace today!
            </Typography>
          </Box>
          <Box className={classes.downloadContainer}>
            <Box marginBottom="16px">
              <Typography variant='body1' className={classes.downloadTitle}>Download Tracko</Typography>
            </Box>
            <Box>
              <IconButton style={{ padding: "0px 16px 0px 0px" }} >
                <img src={googlePlay} alt="goggle play button" />
              </IconButton>
              <IconButton style={{ padding: "0px 0px 0px 0px" }}>
                <img src={appStore} alt="app store button" />
              </IconButton>
              <Box className={classes.phone}>
                <img src={phone} alt="phone" />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box>
          <Footer type="tnc" />
        </Box>
      </Box>
    </Box>
  )
}