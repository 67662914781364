import React, { useState } from "react";
import OrderListTableComponent from "./orderListTable.component";
import { useDispatch, useSelector } from "react-redux";
import { getOrder, resetOrderDetails } from "modules/loyalty-program/redux";
import { setType } from "modules/loyalty-program/redux/slice/redemptionCenter.slice"
import { hideBackdrop, showBackdrop } from "modules/backdrop";
import { OrderDetailsDialog } from "../../dialog";
import { unwrapResult } from '@reduxjs/toolkit'
import ConfirmationDialog from 'components/dialog/confirmation/confirmationDialog.component'
import { redemptionCenterType } from "modules/loyalty-program/constants"
import { getLang } from "app/feature/constants";

export default function OrderListTableContainer({
  hasGiftRedemptionEditAccessRight,
  data,
  isFetching,
  page,
  search,
  rowsPerPage,
  totalRecords,
  handleChangePage,
  handleChangeRowsPerPage,
  handleReload,
  paginationOptions
}) {
  const dispatch = useDispatch()
  const [isModalOpen, setModalOpen] = useState(false);
  const [confirmationDialogModal] = useState(false);
  const isLoadingRedemptionError = useSelector(
    (state) => state.redemptionCenter.isLoadingRedemptionError
  );
  const lang = useSelector(state => state.constant.languages)
  const language = useSelector(state => state.profile.language)

  const handleView = (id) => {
    dispatch(showBackdrop());
    dispatch(setType({ index: redemptionCenterType.order, type: "view" }));
    dispatch(getOrder(id))
      .then(unwrapResult)
      .then(() => {
        dispatch(hideBackdrop())
        setModalOpen(true)
      })
  }

  const handleClose = () => {
    dispatch(resetOrderDetails())
    setModalOpen(false);
  }

  return (
    <>
      <OrderListTableComponent
        hasGiftRedemptionEditAccessRight={hasGiftRedemptionEditAccessRight}
        data={data}
        isFetching={isFetching}
        page={page}
        search={search}
        rowsPerPage={rowsPerPage}
        totalRecords={totalRecords}
        handleChangePage={handleChangePage}
        language={language}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleView={handleView}
        handleReload={handleReload}
        isLoadingRedemptionError={isLoadingRedemptionError}
        paginationOptions={paginationOptions}
        lang={lang}
      />
      <OrderDetailsDialog
        isOpen={isModalOpen}
        handleClose={handleClose}
      />
      <ConfirmationDialog
        isOpen={confirmationDialogModal}
        handleProceed={handleClose}
        type="success"
        title={getLang(lang,"label.GIFT_ADDED_SUCCESSFULLY")}
        rejectOption={false}
        proceedLabel={getLang(lang,"label.ALRIGHT")}
      />
    </>
  );
}
