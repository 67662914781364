import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import warrantyApi from "app/api/warranty";

const WARRANTY = "warranty";

export const uploadTermAndCondition = createAction(
  `${WARRANTY}/uploadTermAndCondition`
);

export const createWarrantySetting = createAsyncThunk(
  `${WARRANTY}/createWarrantySetting`,
  async (payload, thunkApi) => {
    return warrantyApi.createWarrantySetting
      .post({
        warrantyName: payload.warrantySetting.warrantyName,
        durationMonth: payload.warrantySetting.warrantyPeriod,
        warrantyApproval: payload.warrantySetting.warrantyApproval,
        requiredValidation: payload.warrantySetting.warrantyRequiredValidation,
        tncUrl: payload.warrantySetting.fileUrl.split("?")[0],
        metadata: payload.warrantyRegister,
        productId: payload.productId,
        claimAdd: payload.warrantySetting.claimAdd,
        claimDelete: payload.warrantySetting.claimDelete,
        allowMultipleClaimReason:
          payload.warrantySetting.allowMultipleClaimReason,
        warrantyPreregisterEnabled:
          payload.warrantySetting.warrantyPreregisterEnabled,
        itemReplacementProcessEnabled:
          payload.warrantySetting.itemReplacementProcessEnabled,
        warrantyRegisterNotificationType:
          payload.warrantySetting.warrantyRegisterNotificationType,
        claimApplicationNotificationType:
          payload.warrantySetting.claimApplicationNotificationType,
        customRecipient: payload.warrantySetting.customRecipient,
      })
      .then((response) => response)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const fetchWarrantyListTable = createAsyncThunk(
  `${WARRANTY}/fetchWarrantyListTable`,
  async (payload, thunkApi) => {
    return warrantyApi.getAllWarrantySetting
      .get(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const fetchWarrantySettingDetail = createAsyncThunk(
  `${WARRANTY}/fetchWarrantySettingDetail`,
  async (payload, thunkApi) => {
    return warrantyApi.warrantySettingForm
      .get({
        id: payload,
      })
      .then((response) => response)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const updateWarrantySettingDetail = createAsyncThunk(
  `${WARRANTY}/updateWarrantySettingDetail`,
  async (payload, thunkApi) => {
    const info = {
      ...payload,
      tnc_url: payload.fileUrl,
      warranty_name: payload.warrantyName,
      duration_month: payload.warrantyPeriod,
      warrantyApproval: payload.warrantyApproval,
      warrantyRequiredValidation: payload.warrantyRequiredValidation,
      metadata: payload.metadata,
      productId: payload.productId,
      id: payload.id,
      warrantyPreregisterEnabled: payload.warrantyPreregisterEnabled,
      warrantyRegisterNotificationType:
        payload.warrantyRegisterNotificationType,
      claimApplicationNotificationType:
        payload.claimApplicationNotificationType,
      customRecipient: payload.customRecipient,
      itemReplacementProcessEnabled: payload.itemReplacementProcessEnabled,
      allowDifferentProductReplacement: payload.allowDifferentProductReplacement,
    };
    return warrantyApi.warrantySettingForm
      .put(info)
      .then(() => info)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const createWarrantyExtension = createAsyncThunk(
  `${WARRANTY}/createWarrantyExtension`,
  async (payload, thunkApi) => {
    return warrantyApi.createWarrantyExtension
      .post({
        warrantySettingId: payload.warrantySettingId,
        extMonth: payload.extensionPeriod,
        quantity: payload.extensionCode,
        extStartAt: payload.effectiveDateStart,
        extEndAt: payload.effectiveDateEnd,
        type: payload.type,
        tagId: payload.id,
      })
      .then((response) => response)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const updateWarrantyExtension = createAsyncThunk(
  `${WARRANTY}/updateWarrantyExtension`,
  async (payload, thunkApi) => {
    return warrantyApi.createWarrantyExtension
      .put({
        id: payload.id,
        extMonth: payload.extensionPeriod,
        extStartAt: payload.effectiveDateStart,
        extEndAt: payload.effectiveDateEnd,
      })
      .then((response) => response)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const fetchWarrantyExtensionList = createAsyncThunk(
  `${WARRANTY}/fetchWarrantyExtensionList`,
  async (payload, thunkApi) => {
    return warrantyApi.getWarrantyExtension
      .get({
        ...payload,
      })
      .then((response) => response)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const fetchWarrantyExtensionBatch = createAsyncThunk(
  `${WARRANTY}/fetchWarrantyExtensionBatch`,
  async (payload, thunkApi) => {
    return warrantyApi.getWarrantyExtensionBatch
      .get({
        ...payload,
      })
      .then((response) => response)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const fetchJSONURL = createAsyncThunk(
  `${WARRANTY}/fetchJSONURL`,
  async (payload, thunkApi) => {
    return fetch(payload)
      .then((res) => res.json())
      .then((out) => {
        return out;
      })
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const fetchWarrantyNameDropdown = createAsyncThunk(
  `${WARRANTY}/fetchWarrantyNameDropdown`,
  async (payload, thunkApi) => {
    return warrantyApi.warrantyNameDropdown
      .get(payload)
      .then((response) => response)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const assignWarranty = createAsyncThunk(
  `${WARRANTY}/assignWarranty`,
  async (payload, { rejectWithValue }) => {
    return warrantyApi.assignWarranty
      .post({
        warrantySettingId: payload.warrantySettingId,
        productId: payload.productId,
      })
      .then((res) => res.data)
      .catch((error) => {
        return rejectWithValue(error);
      });
  }
);

export const fetchWarranteeListTable = createAsyncThunk(
  `${WARRANTY}/fetchWarranteeListTable`,
  async (payload, thunkApi) => {
    return warrantyApi.getWarranteeList
      .get({
        ...payload,
      })
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);
export const fetchAllWarranteeListTable = createAsyncThunk(
  `${WARRANTY}/fetchWarranteeListTable`,
  async (payload, thunkApi) => {
    return warrantyApi.warranteeList
      .getAll({
        ...payload,
      })
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const exportWarrantee = createAsyncThunk(
  `${WARRANTY}/exportWarrantee`,
  async (payload, thunkApi) => {
    return warrantyApi.warranteeList
      .exports({
        ...payload,
      })
      .then((response) => response)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const exportReimbursement = createAsyncThunk(
  `${WARRANTY}/exportReimbursement`,
  async (payload, thunkApi) => {
    return warrantyApi.warrantyReimbursement
      .export({
        ...payload,
      })
      .then((response) => response)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const fetchConsumerWarrantyDetail = createAsyncThunk(
  `${WARRANTY}/fetchConsumerWarrantyDetail`,
  async (payload, thunkApi) => {
    return warrantyApi.getConsumerWarrantyDetail
      .get({
        id: payload,
      })
      .then((response) => response)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const exportClaim = createAsyncThunk(
  `${WARRANTY}/exportClaim`,
  async (payload, thunkApi) => {
    return warrantyApi.warrantyClaims
      .exportExcel(payload)
      .then((response) => response)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const exportHandoff = createAsyncThunk(
  `${WARRANTY}/exportClaim`,
  async (payload, thunkApi) => {
    return warrantyApi.warrantyItemReplacement
      .exportHandoff(payload)
      .then((response) => response)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const verifyWarranty = createAsyncThunk(
  `${WARRANTY}/verifyWarranty`,
  async (payload, thunkApi) => {
    const info = {
      ...payload,
      status: payload.status,
      reason: payload.reason,
      id: payload.id,
    };

    return warrantyApi.verifyWarranty
      .put(info)
      .then(() => info)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const fetchWarrantyLogTable = createAsyncThunk(
  `${WARRANTY}/fetchWarrantyLogTable`,
  async (payload, thunkApi) => {
    return warrantyApi.warrantyLog
      .get(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const updateWarrantyLog = createAsyncThunk(
  `${WARRANTY}/updateWarrantyLog`,
  async (payload, thunkApi) => {
    const info = {
      ...payload,
      enc: payload.enc,
      action: payload.action,
      remarks: payload.remarks,
      extendMonth: payload.extended,
    };

    return warrantyApi.warrantyLog
      .post(info)
      .then(() => info)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const fetchWarranteeClaims = createAsyncThunk(
  `${WARRANTY}/fetchWarranteeClaims`,
  async (payload, thunkApi) => {
    return warrantyApi.warrantyClaims
      .get({ id: payload.id })
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const processWarranteeClaims = createAsyncThunk(
  `${WARRANTY}/processWarranteeClaims`,
  async (payload, thunkApi) => {
    const info = {
      id: payload.id,
      status: payload.status,
      reason: payload.reason,
      extendPeriod: payload.extendPeriod,
    };

    return warrantyApi.warrantyClaims
      .post(info)
      .then((response) => {
        return { ...response.data, index: payload.index };
      })
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const fetchConsumerClaimTable = createAsyncThunk(
  `${WARRANTY}/fetchConsumerClaimTable`,
  async (payload, thunkApi) => {
    const info = {
      length: payload.length,
      start: payload.start,
      search: payload.search,
      column: payload.searchColumn,
      status: payload.status,
      from: payload.fromDate,
      to: payload.toDate,
    };
    return warrantyApi.warrantyClaims
      .all(info)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const fetchConsumerClaimDetail = createAsyncThunk(
  `${WARRANTY}/fetchConsumerClaimDetail`,
  async (payload, thunkApi) => {
    return warrantyApi.warrantyClaims
      .show(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const fetchWarrantyReimbursementTable = createAsyncThunk(
  `${WARRANTY}/fetchWarrantyReimbursementTable`,
  async (payload, thunkApi) => {
    const queryInfo = {
      companyUuid: payload.companyUuid,
      length: payload.length,
      start: payload.start,
      search: payload.search,
      column: payload.searchColumn,
      status: payload.status,
      branch: payload.branch,
      product: payload.product,
    };

    return warrantyApi.warrantyReimbursement
      .index(queryInfo)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const exportWarrantyReimbursementTable = createAsyncThunk(
  `${WARRANTY}/exportWarrantyReimbursementTable`,
  async (payload, thunkApi) => {
    return warrantyApi
      .exportWarrantyReimbursementTable({
        ...payload,
      })
      .then((response) => response)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const fetchWarrantyReimbursementDetail = createAsyncThunk(
  `${WARRANTY}/fetchWarrantyReimbursementDetail`,
  async (payload, thunkApi) => {
    return warrantyApi.warrantyReimbursement
      .show(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const createReimbursement = createAsyncThunk(
  `${WARRANTY}/createReimbursement`,
  async (payload, thunkApi) => {
    const info = {
      company_uuid: payload.companyUuid,
      node_uuid: payload.nodeUuid,
      employee_uuid: payload.employeeUuid,
      employee_email: payload.employeeEmail,
      claim_uuid: payload.claimUuid,
      claim_reference_code: payload.claimReferenceCode,
      consumer_email: payload.consumerEmail,
      currency_code: payload.currencyCode,
      currency_exp: payload.currencyExp,
      currency_name: payload.currencyName,
      amount: payload.amount,
      upload_metadata: JSON.stringify({
        image_file: payload.uploadedFileUrl.imageFiles,
        video_file: payload.uploadedFileUrl.videoFiles,
      }),
      remark: payload.remark,
    };

    return warrantyApi.warrantyReimbursement
      .create(info)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const processWarrantyReimbursement = createAsyncThunk(
  `${WARRANTY}/processWarrantyReimbursement`,
  async (payload, thunkApi) => {
    return warrantyApi.warrantyReimbursement
      .process(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const updateReimbursement = createAsyncThunk(
  `${WARRANTY}/updateReimbursement`,
  async (payload, thunkApi) => {
    const info = {
      company_uuid: payload.companyUuid,
      node_uuid: payload.nodeUuid,
      employee_uuid: payload.employeeUuid,
      employee_email: payload.employeeEmail,
      claim_uuid: payload.claimUuid,
      claim_reference_code: payload.claimReferenceCode,
      consumer_email: payload.consumerEmail,
      reimbursement_uuid: payload.reimbursementID,
      currency_code: payload.currencyCode,
      currency_exp: payload.currencyExp,
      currency_name: payload.currencyName,
      amount: payload.amount,
      upload_metadata: JSON.stringify({
        image_file: payload.uploadedFileUrl.imageFiles,
        video_file: payload.uploadedFileUrl.videoFiles,
      }),
      remark: payload.remark,
    };

    return warrantyApi.warrantyReimbursement
      .update(info)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const fetchItemReplacementTable = createAsyncThunk(
  `${WARRANTY}/fetchItemReplacementTable`,
  async (payload, thunkApi) => {
    const queryInfo = {
      companyUuid: payload.companyUuid,
      length: payload.length,
      start: payload.start,
      search: payload.search,
      column: payload.searchColumn,
      status: payload.status,
      branch: payload.branch,
      product: payload.product,
    };

    return warrantyApi.warrantyItemReplacement
      .index(queryInfo)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const fetchItemReplacementDetail = createAsyncThunk(
  `${WARRANTY}/fetchItemReplacementDetail`,
  async (payload, thunkApi) => {
    const queryInfo = {
      companyUuid: payload.companyUuid,
      length: payload.length,
      start: payload.start,
      search: payload.search,
      column: payload.searchColumn,
      status: payload.status,
      branch: payload.branch,
      product: payload.product,
    };

    return warrantyApi.warrantyItemReplacement
      .show(queryInfo)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const updateClaimDetail = createAsyncThunk(
  `${WARRANTY}/updateClaimDetail`,
  async (payload, thunkApi) => {
    return warrantyApi
      .updateClaimDetail(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);
