import React from "react";
import RedemptionTableComponent from './redemptionTable.component';
import { useSelector } from "react-redux";

export default function RedemptionTableContainer({
  handleChangePage,
  handleChangeRowPerPage,
  handleViewDetail,
  isError,
  isFetching,
  language,
  isSearchResult,
  page,
  paginationOptions,
  rows,
  rowsPerPage,
  totalFiltered
}) {
  const lang = useSelector(state => state.constant.languages);
  return (
    <RedemptionTableComponent
      handleChangePage={handleChangePage}
      handleChangeRowPerPage={handleChangeRowPerPage}
      handleViewDetail={handleViewDetail}
      isError={isError}
      isFetching={isFetching}
      language={language}
      isSearchResult={isSearchResult}
      page={page}
      paginationOptions={paginationOptions}
      rows={rows}
      rowsPerPage={rowsPerPage}
      totalFiltered={totalFiltered}
      lang={lang}
    />
  )
}