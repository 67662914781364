export const REPORTING_TITLE__QR_DATA = " - QR Data - SmartKood";
export const REPORTING_TITLE__PRODUCT_CATEGORY = " - Product Category - SmartKood";
export const REPORTING_TITLE__PRODUCT_BRAND = " - Product Brand - SmartKood";
export const REPORTING_TITLE__PRODUCT_MODEL = " - Product Model - SmartKood";
export const REPORTING_TITLE__PRODUCT_LIST = " - Product List - SmartKood";
export const REPORTING_TITLE__SUSPENDED_PRODUCT = " - Suspended Product - SmartKood";
export const REPORTING_TITLE__CONSUMER = " - Consumer - SmartKood";
export const REPORTING_TITLE__PRODUCT_DETAIL = " - Product Detail - SmartKood";

export const REPORTING_TITLE__CAMPAIGN_SUMMARY = " - Campaign Summary - SmartKood";
export const REPORTING_TITLE__CAMPAIGN_SURVEY = " - Campaign Survey - SmartKood";
export const REPORTING_TITLE__CAMPAIGN_LUCKYDRAW = " - Campaign Lucky Draw - SmartKood";
export const REPORTING_TITLE__CAMPAIGN_INSTANTLUCKYDRAW = " - Campaign Instant Lucky Draw - SmartKood";
export const REPORTING_TITLE__CAMPAIGN_LOYALTYPROGRAM = " - Campaign Loyalty Program - SmartKood";
export const REPORTING_TITLE__CAMPAIGN_WARRANTY = " - Campaign Warranty - SmartKood";
export const REPORTING_TITLE__CAMPAIGN_PRIZE = " - Campaign Prize - SmartKood";

export const REPORTING_CAMPAIGN_TITLE= [
    REPORTING_TITLE__CAMPAIGN_SUMMARY,
    REPORTING_TITLE__CAMPAIGN_SURVEY,
    REPORTING_TITLE__CAMPAIGN_LUCKYDRAW,
    REPORTING_TITLE__CAMPAIGN_INSTANTLUCKYDRAW,
    REPORTING_TITLE__CAMPAIGN_LOYALTYPROGRAM,
    REPORTING_TITLE__CAMPAIGN_WARRANTY
];

export const REPORTING_TITLE__DEALERSHIP = " - Dealership - SmartKood";