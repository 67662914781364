/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import StocktakeBoxDetailDialogComponent from "./stocktakeBoxDetailDialog.component";
import { useDispatch, useSelector } from "react-redux";
import { selectLoading } from "modules/notification";
import {
  getStockTakeItemBoxProductList,
  getStockTakeItemProductSNList,
  resetStockTakeBoxProductListing,
  resetStockTakeSnListing,
} from "modules/stockflow/redux";
import { generatePaginationOptions } from "lib/helper";

function StocktakeBoxDetailDialogContainer({
  isOpen,
  handleClose,
  id,
  boxInfo,
}) {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.constant.languages);

  // box
  const isFetching = useSelector((state) =>
    selectLoading(state, getStockTakeItemBoxProductList.typePrefix)
  );
  const list = useSelector(
    (state) => state.stockflowStockTake.stockTakeBoxProductList.list
  );
  const isError = useSelector(
    (state) => state.stockflowStockTake.stockTakeBoxProductList.isError
  );

  const isPrdFetching = useSelector((state) =>
    selectLoading(state, getStockTakeItemProductSNList.typePrefix)
  );
  const prdTotalFiltered = useSelector(
    (state) => state.stockflowStockTake.stockTakeSnList.totalFiltered
  );
  const prdList = useSelector(
    (state) => state.stockflowStockTake.stockTakeSnList.list
  );
  const isPrdError = useSelector(
    (state) => state.stockflowStockTake.stockTakeSnList.isError
  );

  const [search, setSearch] = useState("");
  const [selectedProduct, setSelectedProduct] = useState(null);

  const [paginationOptions, setPaginationOptions] = useState([]);
  const [tableFilter, setTableFilter] = useState({
    page: 0,
    rowsPerPage: 25,
    order: "asc",
    orderBy: "sequence_number", // sequence_number|serial_number
    search: "",
  });

  useEffect(() => {
    return () => {
      dispatch(resetStockTakeSnListing());
      dispatch(resetStockTakeBoxProductListing());
    };
  }, [dispatch]);

  useEffect(() => {
    if (boxInfo) {
      handleDataLoad();
    }
  }, [boxInfo, search]);

  useEffect(() => {
    if (selectedProduct && isOpen) {
      handlePrdDataLoad();
    } else {
      dispatch(resetStockTakeSnListing());
      setPaginationOptions([]);
    }
  }, [selectedProduct, tableFilter]);

  useEffect(() => {
    setPaginationOptions(generatePaginationOptions(prdTotalFiltered));
  }, [prdTotalFiltered]);

  useEffect(() => {
    if (!isOpen) {
      setSearch("");
      setTableFilter({
        page: 0,
        rowsPerPage: 25,
        order: "asc",
        orderBy: "sequence_number",
        search: "",
      });
      setSelectedProduct(null);
    }
  }, [isOpen]);

  const handleDataLoad = () => {
    let payload = {
      id: id,
      box_uuid: boxInfo.uuid,
      search: search,
      status: boxInfo.type,
    };
    dispatch(getStockTakeItemBoxProductList(payload));
  };

  const handlePrdDataLoad = () => {
    let payload = {
      id: id,
      product_uuid: selectedProduct.uuid,
      length: tableFilter.rowsPerPage,
      start: tableFilter.rowsPerPage * tableFilter.page,
      order: tableFilter.order,
      order_by: tableFilter.orderBy,
      search: tableFilter.search,
      status: boxInfo.type, // matched|missing|extra
      box_uuid: boxInfo.uuid,
    };
    dispatch(getStockTakeItemProductSNList(payload));
  };

  const handleChangePage = (event, newPage) => {
    let newData = {
      ...tableFilter,
      page: newPage,
    };
    setTableFilter(newData);
  };

  const handleChangeRowsPerPage = (event) => {
    let newData = {
      ...tableFilter,
      page: 0,
      rowsPerPage: event.target.value,
    };
    setTableFilter(newData);
  };

  const changeOrder = (orderBy, order) => {
    let newData = {
      ...tableFilter,
      order,
      orderBy,
    };
    setTableFilter(newData);
  };

  const handlePrdReload = () => {
    let newData = {
      ...tableFilter,
      page: 0,
    };
    setTableFilter(newData);
  };

  const handleSearch = (values) => {
    if (selectedProduct) {
      setTableFilter({
        ...tableFilter,
        page: 0,
        search: values.search,
      });
    } else {
      setSearch(values.search);
    }
  };

  const viewProduct = (prd) => {
    setTableFilter({
      ...tableFilter,
      page: 0,
      search: "",
    });
    setSelectedProduct(prd);
  };

  return (
    <StocktakeBoxDetailDialogComponent
      isOpen={isOpen}
      handleClose={handleClose}
      lang={lang}
      boxInfo={boxInfo}
      list={list}
      isFetching={isFetching}
      isError={isError}
      handleReload={handleDataLoad}
      viewProduct={viewProduct}
      selectedProduct={selectedProduct}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      changeOrder={changeOrder}
      handlePrdReload={handlePrdReload}
      paginationOptions={paginationOptions}
      prdList={prdList}
      isPrdFetching={isPrdFetching}
      isPrdError={isPrdError}
      prdTotalFiltered={prdTotalFiltered}
      tableFilter={tableFilter}
      handleSearch={handleSearch}
      boxSearch={search}
    />
  );
}

export default StocktakeBoxDetailDialogContainer;
