import { makeStyles } from "@material-ui/core";

export const borderStyle = makeStyles((theme) => ({

    border__middle_detail : {
        [theme.breakpoints.down('sm')]: {
            borderLeft : "none",
            borderRight : "none",
        },
        [theme.breakpoints.up('md')]: {
            borderLeft : "1px solid #E5E5E5",
            borderRight : "1px solid #E5E5E5",
        },
    },
    border_left : {
        [theme.breakpoints.down('sm')]: {
            borderLeft : "none",
        },
        [theme.breakpoints.up('md')]: {
            borderLeft : "1px solid #E5E5E5",
        },
    }
}))