import React from "react";
import ResetInputComponent from "./resetInput.component";
import { connect, useSelector } from "react-redux";
import { requestResetPassword } from "../../../redux/action";
import { selectLoading } from "modules/notification";
import { useHistory } from "react-router-dom";

function ResetInputContainer({
  requestResetPassword,
  isLoading,
}) {
  const history = useHistory();
  const lang = useSelector(state => state.constant.languages)

  const handleSubmit = async values => {
    let resendEmail = values.email ? values.email : values
    requestResetPassword(resendEmail)
      .then((response) => {
        const requestResponse = response.payload
        if( requestResponse.success || requestResponse.code === "ERROR_RESEND_SAME_EMAIL_WITHIN_60_SECONDS" ){
          history.push('/sent-reset-password?email=' + resendEmail);
        }
      })
  };
  return (
    <ResetInputComponent handleSubmit={handleSubmit} isLoading={isLoading} enableResend={true} lang={lang}/>
  );
}

const mapStateToProps = state => ({
  isLoading: selectLoading(state, requestResetPassword.typePrefix),
});

const mapDispatchToProps = dispatch => ({
  requestResetPassword: email => dispatch(requestResetPassword(email)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetInputContainer);
