import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { ROOT } from "./config/loyalty-program.route";
import { LoyaltyProgramPage } from "./pages";

export default function LoyaltyProgramModule() {
  return (
    <Switch>
      <Route exact path={ROOT} component={LoyaltyProgramPage} />
      <Route path="*">
        <Redirect to="/admin/invalid" />
      </Route>
    </Switch>
  );
}

export { ROOT };
