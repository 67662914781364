import { createAsyncThunk } from "@reduxjs/toolkit";
import reportingApi from "app/api/reporting";
import moment from "moment";

// (1) product data -- start
export const getProductData = createAsyncThunk(
    "reporting/getProductData",
    async (payload, thunkApi) => {
        return reportingApi.getProductData({
            length: payload.length,
            start: payload.start,
            search: payload.search,
            orderBy: payload.orderBy,
            orderDirection: payload.orderDirection,
            search_by: payload.searchBy,
            country : payload.countryCode,
            city_id: payload.city_uuid,
            branch_uuid: payload.branch_uuid,
            category_uuid: payload.category_uuid,
            model_uuid: payload.model_uuid,
            brand_uuid: payload.brand_uuid,
            period: payload.period,
            start_date: moment(payload.start_date).format('YYYY-MM-DD'),
            end_date: moment(payload.end_date).format('YYYY-MM-DD'),
            url : payload.url,
        })
            .then((response) => response)
            .catch((error) => thunkApi.rejectWithValue(error));
    }
);
// (1) product data -- end

// (2) product detail data -- start
export const getProductDetailData = createAsyncThunk(
    "reporting/getProductDetailData",
    async (payload, thunkApi) => {
        return reportingApi.getProductDetailData({
            product_uuid: payload.product_uuid,
            branch_uuid: payload.branch_uuid,
            country : payload.countryCode,
            city_id: payload.city_uuid,
            period: payload.period,
            start_date: moment(payload.start_date).format('YYYY-MM-DD'),
            end_date: moment(payload.end_date).format('YYYY-MM-DD'),
            url : payload.url,
        })
            .then((response) => response)
            .catch((error) => thunkApi.rejectWithValue(error));
    }
);
// (2) product detail data -- end

// (3) product category data -- start
export const getProductCategoryData = createAsyncThunk(
    "reporting/getProductCategoryData",
    async (payload, thunkApi) => {
        
        return reportingApi.getProductCategoryData({
            length: payload.length,
            start: payload.start,
            search: payload.search,
            orderBy: payload.orderBy,
            orderDirection: payload.orderDirection,
            search_by: payload.searchBy,
            branch_uuid: payload.branch_uuid,
            country : payload.countryCode,
            city_id: payload.city_uuid,
            category_uuid: payload.category_uuid,
            period: payload.period,
            start_date: moment(payload.start_date).format('YYYY-MM-DD'),
            end_date: moment(payload.end_date).format('YYYY-MM-DD'),
            url : payload.url,
        })
            .then((response) => response)
            .catch((error) => thunkApi.rejectWithValue(error));
    }
);
// (3) product category data -- end

// (4) product brand data -- start
export const getProductBrandData = createAsyncThunk(
    "reporting/getProductBrandData",
    async (payload, thunkApi) => {
        return reportingApi.getProductBrandData({
            length: payload.length,
            start: payload.start,
            search: payload.search,
            orderBy: payload.orderBy,
            orderDirection: payload.orderDirection,
            search_by: payload.searchBy,
            country: payload.countryCode,
            city_id: payload.city_uuid,
            branch_uuid: payload.branch_uuid,
            period: payload.period,
            start_date: moment(payload.start_date).format('YYYY-MM-DD'),
            end_date: moment(payload.end_date).format('YYYY-MM-DD'),
            url : payload.url,
        })
            .then((response) => response)
            .catch((error) => thunkApi.rejectWithValue(error));
    }
);
// (4) product brand data -- end

// (5) product model data -- start
export const getProductModelData = createAsyncThunk(
    "reporting/getProductModelData",
    async (payload, thunkApi) => {
        return reportingApi.getProductModelData({
            length: payload.length,
            start: payload.start,
            search: payload.search,
            orderBy: payload.orderBy,
            orderDirection: payload.orderDirection,
            search_by: payload.searchBy,
            country: payload.countryCode,
            city_id: payload.city_uuid,
            model_uuid: payload.model_uuid,
            branch_uuid: payload.branch_uuid,
            period: payload.period,
            start_date: moment(payload.start_date).format('YYYY-MM-DD'),
            end_date: moment(payload.end_date).format('YYYY-MM-DD'),
            url : payload.url,
        })
            .then((response) => response)
            .catch((error) => thunkApi.rejectWithValue(error));
    }
);
// (5) product model data -- end

// (6) product listing data -- start
export const getProductListingData = createAsyncThunk(
    "reporting/getProductListingData",
    async (payload, thunkApi) => {
        return reportingApi.getProductListingData({
            length: payload.length,
            start: payload.start,
            search: payload.search,
            orderBy: payload.orderBy,
            orderDirection: payload.orderDirection,
            search_by: payload.searchBy,
            country: payload.countryCode,
            city_id: payload.city_uuid,
            branch_uuid: payload.branch_uuid,
            period: payload.period,
            start_date: moment(payload.start_date).format('YYYY-MM-DD'),
            end_date: moment(payload.end_date).format('YYYY-MM-DD'),
            url : payload.url,
        })
            .then((response) => response)
            .catch((error) => thunkApi.rejectWithValue(error));
    }
);
// (6) product listing data -- end

// (8) suspended list -- start
export const getSuspendedListData = createAsyncThunk(
    "reporting/getSuspendedListData",
    async (payload, thunkApi) => {
        return reportingApi.getSuspendedListData({
            length: payload.length,
            start: payload.start,
            search: payload.search,
            orderBy: payload.orderBy,
            orderDirection: payload.orderDirection,
            search_by: payload.searchBy,
            country: payload.countryCode,
            city_id: payload.city_uuid,
            branch_uuid: payload.branch_uuid,
            period: payload.period,
            start_date: moment(payload.start_date).format('YYYY-MM-DD'),
            end_date: moment(payload.end_date).format('YYYY-MM-DD'),
            url : payload.url,
        })
            .then((response) => response)
            .catch((error) => thunkApi.rejectWithValue(error));
    }
);
// (8) suspended list -- end

// (9) get product category detail data -- start
export const getProductCategoryDetailData = createAsyncThunk(
    "reporting/getProductCategoryDetailData",
    async (payload, thunkApi) => {
        return reportingApi.getProductCategoryDetailData({
            category_uuid: payload.category_uuid,
            country : payload.countryCode,
            city_id: payload.city_uuid,
            branch_uuid: payload.branch_uuid,
            period: payload.period,
            start_date: moment(payload.start_date).format('YYYY-MM-DD'),
            end_date: moment(payload.end_date).format('YYYY-MM-DD'),
            url : payload.url,
        })
            .then((response) => response)
            .catch((error) => thunkApi.rejectWithValue(error));
    }
);
// (9) get product category detail data -- end

// (10) get product brand detail data -- start
export const getProductBrandDetailData = createAsyncThunk(
    "reporting/getProductBrandDetailData",
    async (payload, thunkApi) => {
        return reportingApi.getProductBrandDetailData({
            country : payload.countryCode,
            city_id: payload.city_uuid,
            branch_uuid: payload.branch_uuid,
            brand_uuid: payload.brand_uuid,
            period: payload.period,
            start_date: moment(payload.start_date).format('YYYY-MM-DD'),
            end_date: moment(payload.end_date).format('YYYY-MM-DD'),
            url : payload.url,
        })
            .then((response) => response)
            .catch((error) => thunkApi.rejectWithValue(error));
    }
);
// (10) get product brand detail data -- end

// (11) get product model detail data -- start
export const getProductModelDetailData = createAsyncThunk(
    "reporting/getProductModelDetailData",
    async (payload, thunkApi) => {
        return reportingApi.getProductModelDetailData({
            branch_uuid: payload.branch_uuid,
            model_uuid: payload.model_uuid,
            country : payload.countryCode,
            city_id: payload.city_uuid,
            period: payload.period,
            start_date: moment(payload.start_date).format('YYYY-MM-DD'),
            end_date: moment(payload.end_date).format('YYYY-MM-DD'),
            url : payload.url,
        })
            .then((response) => response)
            .catch((error) => thunkApi.rejectWithValue(error));
    }
);
// (11) get product model detail data -- end

// (12) export excel suspended listing
export const exportSuspendedListing = createAsyncThunk(
    "reporting/exportSuspendedListing",
    async (payload, thunkApi) => {

        return reportingApi.exportSuspendedListing({
            length: payload.length,
            token: payload.token,
            start: payload.start,
            search: payload.search,
            orderBy: payload.orderBy,
            orderDirection: payload.orderDirection,
            search_by: payload.searchBy,
            country: payload.countryCode,
            city_id: payload.city_uuid,
            branch_uuid: payload.branch_uuid,
            product_uuid: payload.product_uuid,
            period: payload.period,
            start_date: moment(payload.start_date).format('YYYY-MM-DD'),
            end_date: moment(payload.end_date).format('YYYY-MM-DD'),
            url: payload.url,
        })
            .then((response) => response)
            .catch((error) => thunkApi.rejectWithValue(error));
    }
);