import React from "react";
import MarketplaceComponent from "./marketplace.component";
import { useSelector } from "react-redux";

export default function MarketplaceContainer({ id }) {
  const { content, isVisible } = useSelector(
    state => state.productPage.components[id]
  );
  const lang = useSelector(state => state.constant.languages);

  return (
    isVisible && <MarketplaceComponent content={content} lang={lang}/>
  );
}
