import { addAlert, setLoading } from "modules/notification";
import { getBranchSalesData } from "../action";

// (1) branch sales data -- start
export const getBranchSalesDataMiddleware = ({ dispatch, getState}) => next => action => {
    next(action);
    if (action.type === getBranchSalesData.pending.type) {
        dispatch(setLoading({ id: getBranchSalesData.typePrefix, state: true }));
    }
    if (action.type === getBranchSalesData.fulfilled.type) {
        dispatch(setLoading({ id: getBranchSalesData.typePrefix, state: false }));
    }
    if (action.type === getBranchSalesData.rejected.type) {
        dispatch(setLoading({ id: getBranchSalesData.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: action.payload.message}));
    }
}
// (1) branch sales data -- end

export default [
    getBranchSalesDataMiddleware,
]