import axios from "axios";

export function updateCategoryStatus({
  id,
  status,
}) {
  const url =  `/serialnumber/api/v1/product/category/update-status/${id}`;

  return new Promise((resolve, reject) => {
    axios.post(url, {
      status: status,
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data))
  })
}