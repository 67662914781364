import axios from "axios";

const url = "/serialnumber/api/v1/product/list";

const post = (parameters) => {
  return new Promise((resolve, reject) => {
    axios
      .post(url, {
        length: parameters.length,
        start: parameters.start,
        search: parameters.search,
        status: parameters.status,
        attribute: parameters.attribute,
        attributeoption: parameters.options,
        brand: parameters.brand,
        model: parameters.model,
        category: parameters.category,
        nodes: parameters.nodes
      }, { timeout: 15000 })
      .then(response => resolve(response.data))
      .catch(error => {
        return reject(error.response.data);
      });
  });
};

const products = {
  post
};

export default products;
