import { Box } from "@material-ui/core";
import { selectLoading } from "modules/notification";
import { getCitySalesData, getCountrySalesData, getDemographicAgeData, getDemographicAgeGenderData, getDemographicGenderData } from "modules/reporting/redux";
import { flexGapStyle, fontTextStyle } from "modules/reporting/styles";
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DemographicCampaignPanel from './demographicCampaignPanel.component';
import { unwrapResult } from "@reduxjs/toolkit";
import moment from "moment";
import { useState } from "react";

export default function DemographicCampaignPanelContainer(props){
    const { currentFilter, pageType, urlAge, urlGender, urlGenderAge, urlCity, urlCountry, translate } = props;

    const fontClasses = fontTextStyle();
    const flexGapClasses = flexGapStyle();

    // demographic charts configuration -- start
    const dispatchAgeGender = useDispatch();

    const isFetchingDemographicAgeData = useSelector(state => selectLoading(state, getDemographicAgeData.typePrefix))
    const demographicAgeData = useSelector(state => state.reporting.demographic.ageData);

    const isFetchingDemographicGenderData = useSelector(state => selectLoading(state, getDemographicGenderData.typePrefix))
    const demographicGenderData = useSelector(state => state.reporting.demographic.genderData);

    const isFetchingDemographicAgeGenderData = useSelector(state => selectLoading(state, getDemographicAgeGenderData.typePrefix))
    const demographicAgeGenderData = useSelector(state => state.reporting.demographic.ageGenderData);

    useEffect(() => {
        dispatchAgeGender(getDemographicAgeData({
            countryCode: currentFilter.countryCode,
            city_uuid: currentFilter.cityUuid,
            branch_uuid: currentFilter.branchUuid,
            category_uuid: currentFilter.categoryUuid,
            model_uuid: currentFilter.modelUuid,
            brand_uuid: currentFilter.brandUuid,
            product_uuid: currentFilter.productUuid,
            period: currentFilter.period,
            start_date: moment(currentFilter.dateRange[0]).format('YYYY-MM-DD'),
            end_date: moment(currentFilter.dateRange[1]).format('YYYY-MM-DD'),
            url: urlAge,

            campaign_type: currentFilter.campaignType,
            campaign_uuid: currentFilter.campaignUuid
        })).then(unwrapResult);
        dispatchAgeGender(getDemographicGenderData({
            countryCode: currentFilter.countryCode,
            city_uuid: currentFilter.cityUuid,
            branch_uuid: currentFilter.branchUuid,
            category_uuid: currentFilter.categoryUuid,
            model_uuid: currentFilter.modelUuid,
            brand_uuid: currentFilter.brandUuid,
            product_uuid: currentFilter.productUuid,
            period: currentFilter.period,
            start_date: moment(currentFilter.dateRange[0]).format('YYYY-MM-DD'),
            end_date: moment(currentFilter.dateRange[1]).format('YYYY-MM-DD'),
            url: urlGender,

            campaign_type: currentFilter.campaignType,
            campaign_uuid: currentFilter.campaignUuid
        })).then(unwrapResult);
        dispatchAgeGender(getDemographicAgeGenderData({
            countryCode: currentFilter.countryCode,
            city_uuid: currentFilter.cityUuid,
            branch_uuid: currentFilter.branchUuid,
            category_uuid: currentFilter.categoryUuid,
            model_uuid: currentFilter.modelUuid,
            brand_uuid: currentFilter.brandUuid,
            product_uuid: currentFilter.productUuid,
            period: currentFilter.period,
            start_date: moment(currentFilter.dateRange[0]).format('YYYY-MM-DD'),
            end_date: moment(currentFilter.dateRange[1]).format('YYYY-MM-DD'),
            url: urlGenderAge,

            campaign_type: currentFilter.campaignType,
            campaign_uuid: currentFilter.campaignUuid
        })).then(unwrapResult);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatchAgeGender, currentFilter]);
    // demographic charts configuration -- end

    // city configuration -- start
    const dispatchCity = useDispatch();
    const isFetchingCitySalesData = useSelector(state => selectLoading(state, getCitySalesData.typePrefix));
    const citySalesData = useSelector(state => state.reporting.demographic.cityDatalist);
    
    const [tableCityFilter, setTableCityFilter] = useState({
        page: 0,
        rowsPerPage: 10,
        search: "",
        searchBy: "name",
        orderBy: "engagement",
        orderDirection: "desc",
    })

    const columnsCity = [
        {
            title: translate("label.NUM"),
            field: null,
            render: (row) => {
                return (
                    <>{row.title}</>
                )
            },
            align: "left"
        },
        {
            title: translate("label.CITY"),
            subtitle: `(${translate("paragraph.CITY_DRILLDOWN_DESCRIPTION")})`,
            field: null,
            render: (row) => {
                return (
                    <Box
                        display="flex"
                        className={flexGapClasses.global__flexGap3}
                    >
                        <span>{row.title}</span>
                        <span className={fontClasses.text__subtitle_muted}>{row.subtitle}</span>
                    </Box>
                )
            },
            align: null
        },
        {
            title: translate("label.TOTAL_SCAN"),
            field: "count",
            render : (row) => {
                return (
                    <>{row.title}</>
                )
            },
            align: "left"
        },
        {
            title: translate("label.TOTAL_ENGAGEMENT"),
            field: "engagement",
            render : (row) => {
                return (
                    <>{row.title}</>
                )
            },
            align: "left"
        }
    ]

    useEffect(() => {
        dispatchCity(getCitySalesData({
            countryCode: currentFilter.countryCode,
            city_uuid: currentFilter.cityUuid,
            branch_uuid: currentFilter.branchUuid,
            category_uuid: currentFilter.categoryUuid,
            model_uuid: currentFilter.modelUuid,
            brand_uuid: currentFilter.brandUuid,
            product_uuid: currentFilter.productUuid,
            period: currentFilter.period,
            start_date: moment(currentFilter.dateRange[0]).format('YYYY-MM-DD'),
            end_date: moment(currentFilter.dateRange[1]).format('YYYY-MM-DD'),
            url: urlCity,

            campaign_type: currentFilter.campaignType,
            campaign_uuid: currentFilter.campaignUuid
        })).then(unwrapResult);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatchCity, currentFilter]);

    const handleCityChangePage = (e, newPage) => {
        setTableCityFilter((prev) => ({
          ...prev,
          page: newPage,
        }))
    }

    const handleCityChangeRowPerPage = (event) => {
        setTableCityFilter((prev) => ({
          ...prev,
          page: 0,
          rowsPerPage: event.target.value
        }))
    }

    const handleCityRequestSort = (event, property) => {
        const isAsc = tableCityFilter.orderBy === property && tableCityFilter.orderDirection === 'asc';
        setTableCityFilter((prev) => ({
            ...prev,
            orderDirection: isAsc ? 'desc' : 'asc',
            orderBy: property,
        }))
    }
    // city configuration -- end


    // country configuration - start
    const dispatchCountry = useDispatch();
    const isFetchingCountrySalesData = useSelector(state => selectLoading(state, getCountrySalesData.typePrefix));
    const countrySalesData = useSelector(state => state.reporting.demographic.countryDatalist);

    const [tableCountryFilter, setTableCountryFilter] = useState({
        page: 0,
        rowsPerPage: 10,
        search: "",
        searchBy: "name",
        orderBy: "engagement",
        orderDirection: "desc",
    })

    const columnsCountry = [
        {
            title: translate("label.NUM"),
            field: null,
            render : (row) => {
                return (
                    <>{row.title}</>
                )
            },
            align: "left"
        },
        {
            title: translate("label.COUNTRY"),
            subtitle: `(${translate("paragraph.COUNTRY_DRILLDOWN_DESCRIPTION")})`,
            field: null,
            render: (row) => {
                return (
                    <Box
                        display="flex"
                        className={flexGapClasses.global__flexGap3}
                    >
                        <span>{row.title}</span>
                        <span className={fontClasses.text__subtitle_muted}>{row.subtitle}</span>
                    </Box>
                )
            },
            align: null
        },
        {
            title: translate("label.TOTAL_SCAN"),
            field: "count",
            render : (row) => {
                return (
                    <>{row.title}</>
                )
            },
            align: "left"
        },
        {
            title: translate("label.TOTAL_ENGAGEMENT"),
            field: "engagement",
            render : (row) => {
                return (
                    <>{row.title}</>
                )
            },
            align: "left"
        }
    ]

    useEffect(() => {
        dispatchCountry(getCountrySalesData({
            countryCode: currentFilter.countryCode,
            city_uuid: currentFilter.cityUuid,
            branch_uuid: currentFilter.branchUuid,
            category_uuid: currentFilter.categoryUuid,
            model_uuid: currentFilter.modelUuid,
            brand_uuid: currentFilter.brandUuid,
            product_uuid: currentFilter.productUuid,
            period: currentFilter.period,
            start_date: moment(currentFilter.dateRange[0]).format('YYYY-MM-DD'),
            end_date: moment(currentFilter.dateRange[1]).format('YYYY-MM-DD'),
            url: urlCountry,

            campaign_type: currentFilter.campaignType,
            campaign_uuid: currentFilter.campaignUuid
        })).then(unwrapResult);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatchCountry, currentFilter]);

    const handleCountryChangePage = (e, newPage) => {
        setTableCountryFilter((prev) => ({
          ...prev,
          page: newPage,
        }))
    }

    const handleCountryChangeRowPerPage = (event) => {
        setTableCountryFilter((prev) => ({
          ...prev,
          page: 0,
          rowsPerPage: event.target.value
        }))
    }

    const handleCountryRequestSort = (event, property) => {
        const isAsc = tableCountryFilter.orderBy === property && tableCountryFilter.orderDirection === 'asc';
        setTableCountryFilter((prev) => ({
            ...prev,
            orderDirection: isAsc ? 'desc' : 'asc',
            orderBy: property,
        }))
    }
    // country configuration - end
    return (
        <DemographicCampaignPanel 
            status={{
                isFetchingDemographicAgeData,
                isFetchingDemographicGenderData,
                isFetchingDemographicAgeGenderData,
                isFetchingCitySalesData,
                isFetchingCountrySalesData
            }}

            data={{
                demographicAgeData,
                demographicGenderData,
                demographicAgeGenderData,
                citySalesData,
                countrySalesData
            }}

            currentFilter={currentFilter}

            sortCity={{
                valueToOrderBy: tableCityFilter.orderBy,
                orderDirection: tableCityFilter.orderDirection,
                handleRequestSort: handleCityRequestSort,
            }}

            paginationCity={{
                handleChangePage: handleCityChangePage,
                handleChangeRowPerPage: handleCityChangeRowPerPage,
                page: tableCityFilter.page,
                rowsPerPage: tableCityFilter.rowsPerPage,
                rowsPerPageOptions: [5, 10, 25],
            }}

            columnsCity={columnsCity}

            sortCountry={{
                valueToOrderBy: tableCountryFilter.orderBy,
                orderDirection: tableCountryFilter.orderDirection,
                handleRequestSort: handleCountryRequestSort,
            }}

            paginationCountry={{
                handleChangePage: handleCountryChangePage,
                handleChangeRowPerPage: handleCountryChangeRowPerPage,
                page: tableCountryFilter.page,
                rowsPerPage: tableCountryFilter.rowsPerPage,
                rowsPerPageOptions: [5, 10, 25],
            }}

            columnsCountry={columnsCountry}

            pageType={pageType}
            translate={translate}
        />
    )
}