import React from 'react'
import AddBonanzaPointDialogComponent from './addBonanzaPointDialog.component'
import { useSelector } from "react-redux";

export default function AddBonanzaPointDialogContainer({ isOpen, handleClose }) {
  const headerType = useSelector((state) => state.loyaltyProgram.headerType);

  return (
    <AddBonanzaPointDialogComponent
      isOpen={isOpen}
      handleClose={handleClose}
      headerType={headerType}
    />
  )
}