import React, { useEffect, useState } from "react";
import DateRangePickerComponent from "./dateRangePicker.component";
import moment from "moment";

function DateRangePickerContainer({
  disabled,
  value,
  placeholder,
  handleChange,
  format,
  disableFuture,
  minDate,
  maxDate,
}) {
  const [dateRange, setDateRange] = useState([]);

  useEffect(() => {
    if (value) {
      setDateRange([moment(value.from).toDate(), moment(value.to).toDate()]);
    } else {
      setDateRange([]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleDateChange = (value) => {
    if (value.length > 0) {
      setDateRange(value);
      handleChange({
        from: moment(value[0]).format("YYYY-MM-DD"),
        to: moment(value[1]).format("YYYY-MM-DD"),
      });
    }
  };

  return (
    <>
      <DateRangePickerComponent
        handleChange={handleDateChange}
        value={dateRange}
        disabled={disabled}
        placeholder={placeholder}
        minDate={minDate}
        maxDate={maxDate}
        disableFuture={disableFuture}
        format={format}
      />
    </>
  );
}

export default DateRangePickerContainer;
