import axios from "axios";

export default function getAllGift({
    length,
    start,
    column,
    order,
    search
}) {
    return new Promise((resolve, reject) => {
        axios.get("/campaign/api/admin/items",
            {
                params: {
                    limit: length,
                    skip: start,
                    sortBy: column,
                    order: order,
                    search: search
                }
            })
            .then(response => resolve(response.data))
            .catch(error => reject(error.response.data))
    })
}