import React, { useEffect } from "react";
import { useSelector } from 'react-redux'
import { useHistory } from "react-router-dom";
import { STOCKFLOW_RECALL_VIEW } from "lib/constants/accessRights";
import LoadingComponent from "components/loading";
import MainPageComponent from "./main.component";

function MainPageContainer() {
  const hasStockflowViewAccessRight = useSelector(state => state.profile.accessRights.includes(STOCKFLOW_RECALL_VIEW));
  const lang = useSelector(state => state.constant.languages)
  const history = useHistory()

  useEffect(() => {
    if(!hasStockflowViewAccessRight){
      history.push('/admin/dashboard')
    }
  }, [history, hasStockflowViewAccessRight])

  if(!hasStockflowViewAccessRight) return <LoadingComponent />;

  return (
    <>
      <MainPageComponent lang={lang}/>
    </>
  );
}

export default MainPageContainer;