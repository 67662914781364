import React, { useState } from "react";
import { useSelector } from "react-redux";
import AttributesComponent from './attributes.component';
import { PRODUCT_ATTRIBUTE_ADD } from "lib/constants/accessRights"

function AttributesContainer(){
  const [ isCreateDialogOpen, setIsCreateDialogOpen ] = useState(false)

  const handleCreateAtrribute = (value) => {
    setIsCreateDialogOpen(value)
  }
  const lang = useSelector(state => state.constant.languages);
  const hasProductAttributeAddAccessRight = useSelector(state => state.profile.accessRights.includes(PRODUCT_ATTRIBUTE_ADD));

  return (
    <>
      <AttributesComponent
        hasProductAttributeAddAccessRight={hasProductAttributeAddAccessRight}
        handleCreateAttribute={handleCreateAtrribute}
        isCreateDialogOpen={isCreateDialogOpen}
        lang={lang}
      /> 
    </>
  )
}

export default AttributesContainer