import React from "react"
import {
  makeStyles,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Box,
  Chip
} from "@material-ui/core"
import { StyledTableCell, StyledTableRow } from "components/table"
import AntSwitch from 'components/switch/switch'
import { SkeletonTableRow } from 'components/skeleton/skeleton.component'
import { TablePaginationActions } from 'components/tablePagination'
import Input from "components/input/inputTextField"
import DefaultPhoto from "assets/img/img-default.png"
import SearchableSelect from 'components/select/searchableSelect'
import BrandModelSingleSelect from '../../select/brandModelSingleSelect'
import AttributeSelect from '../../select/attributeSelect'
import { isEmpty } from "lib/helper";
import BranchSelect from "../../select/branchSelect"
import { getLang } from "app/feature/constants"

const useStyle = makeStyles(theme => ({
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    borderCollapse: "separate",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0
    },
    "& th,td": {
      fontSize: ".75rem",
      borderRight: "1px solid rgba(58, 77, 84, 0.1)",
      padding: theme.spacing(1, 2, 1, 2)
    },
    "& th": {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightBold
    },
    "& th:last-child, td:last-child": {
      width: 55
    }
  },
  tableContainer: {
    maxHeight: 500,
    overflowY: "auto"
  },
  emptyContainer: {
    backgroundColor: theme.palette.background.paper,
    textAlign: "center"
  },
  button: {
    display: "flex",
    alignItems: "center",
    padding: 0,
    minWidth: 0,
    marginLeft: theme.spacing(1)
  },
  iconBtn: {
    borderRadius: theme.spacing(0.5),
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shortest,
    }),
    padding: "3px 5px"
  },
  pendingColor: {
    color: "#FFBF00"
  },
  activeColor: {
    color: "#4BCB0F"
  },
  deactiveColor: {
    color: "#9CA6A9"
  },
  inputRoot: {
    paddingTop: "8px !important",
    paddingBottom: "7px !important",
    paddingLeft: "12px !important",
    paddingRight: "12px !important"
  },
  paperRoot: {
    width: "200px",
  },
  selectAll: {
    '&.Mui-checked': {
      color: "#FDB022",
    },
  },
  selectOne: {
    '&.Mui-checked': {
      color: "#6AAF68",
    },
  }
}))

export default function BulkEditTableComponent({
  data,
  isFetching,
  page,
  rowsPerPage,
  totalRecords,
  handleChangePage,
  handleChangeRowsPerPage,
  formik,
  categoryDropdownData,
  handleCategorySearch,
  currencyDropdownData,
  handleCurrencyCodeSearch,
  brandModelDropdownData,
  handleBrandModelSearch,
  attributeDropdownData,
  handleAttributeSearch,
  isBrandModelDropdownLoading,
  handleBulkEditFieldUpdate,
  paginationOptions,
  lang
}) {
  const classes = useStyle()
  const numberReg = /^-?\d*(\.\d*)?$/

  const displayBrandModel = (brand, model) => {
    if (isEmpty(brand) && isEmpty(model)) {
      return "-";
    }

    if (brand.id === "-1" && model.id === "-1") {
      return "-";
    }

    if (model.id === "-1") {
      return brand.name;
    }

    if (brand.id === "-1") {
      return model.name;
    }

    return brand.name + ", " + model.name;
  };


  return (
    <>
      {data.length > 0 || isFetching ? (
        <TableContainer>
          <Table className={classes.table} size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <StyledTableCell>{getLang(lang, "label.IMAGE")}</StyledTableCell>
                <StyledTableCell style={{ minWidth: "280px" }}>{getLang(lang, "label.PRODUCT_NAME")}</StyledTableCell>
                <StyledTableCell style={{ minWidth: "200px" }}>{getLang(lang, "label.CATEGORY")}</StyledTableCell>
                <StyledTableCell style={{ minWidth: "200px" }}>{getLang(lang, "label.BRAND_MODEL")}</StyledTableCell>
                <StyledTableCell style={{ minWidth: "200px" }}>{getLang(lang, "label.ATTRIBUTE")}</StyledTableCell>
                <StyledTableCell style={{ minWidth: "200px" }}>{getLang(lang, "label.BRANCH")}</StyledTableCell>
                <StyledTableCell style={{ minWidth: "200px" }}>{getLang(lang, "label.SKU")}</StyledTableCell>
                <StyledTableCell style={{ minWidth: "200px" }}>{getLang(lang, "label.PRICE")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.SCAN_LIMIT")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.INCENTIVE_POINT")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.STATUS")}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <StyledTableRow style={{ backgroundColor: "#E5F1E4" }}>
                <StyledTableCell colSpan={2} style={{}}>
                  <Typography style={{ fontSize: "0.75rem", marginRight: "16px" }}>
                    {getLang(lang, "paragraph.BULK_EDIT_INSTRUCTION")}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <SearchableSelect
                    value={formik.values.category}
                    handleChange={(value) => {
                      formik.setFieldValue("category", value)
                      handleBulkEditFieldUpdate({ category: value })
                    }}
                    placeholder={getLang(lang, "placeholder.CATEGORY")}
                    disabled={formik.isSubmitting}
                    isEmpty={formik.values.category === ""}
                    searchable={true}
                    handleItemSearch={handleCategorySearch}
                    style={{
                      height: "34px",
                      backgroundColor: "#fff",
                      border: "thin solid #eceff0",
                    }}
                    textStyle={{ fontSize: "0.75rem" }}
                    PaperProps={{
                      classes: { root: classes.paperRoot }
                    }}
                    dropdownItem={categoryDropdownData}
                    emptyMessage="Category not found"
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    returnType="object"
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <BrandModelSingleSelect
                    brandValue={formik.values.brand}
                    modelValue={formik.values.model}
                    onChange={(brand, model) => {
                      formik.setFieldValue("brand", brand)
                      formik.setFieldValue("model", model !== -1 ? model : null)
                      handleBulkEditFieldUpdate({
                        brand, 
                        model: model !== -1 ? model : null
                      })
                    }}
                    placeholder={getLang(lang, "placeholder.BRAND_MODEL")}
                    disabled={formik.isSubmitting}
                    style={{
                      height: "34px",
                      backgroundColor: "#fff",
                      border: "thin solid #eceff0",
                    }}
                    textStyle={{ fontSize: "0.75rem" }}
                    PaperProps={{
                      classes: { root: classes.paperRoot }
                    }}
                    dropdownItem={brandModelDropdownData}
                    isLoading={isBrandModelDropdownLoading}
                    emptyMessage="Brand & Model not found"
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    searchable={true}
                    handleSearch={handleBrandModelSearch}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <AttributeSelect
                    value={formik.values.attribute}
                    placeholder={getLang(lang, "placeholder.ATTRIBUTE")}
                    disabled={formik.isSubmitting}
                    isEmpty={formik.values.attribute?.length === 0}
                    style={{
                      minheight: '34px',
                      backgroundColor: "#fff",
                      border: "thin solid #eceff0",
                    }}
                    textStyle={{ fontSize: "0.75rem" }}
                    PaperProps={{
                      classes: { root: classes.paperRoot }
                    }}
                    dropdownItem={attributeDropdownData}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    searchable
                    onChange={(attribute) => {
                      let temp = [...formik.values.attribute]

                      if (temp.length > 0) {
                        const index = temp.findIndex((item) => item.id === attribute.id)

                        if (index === -1) {
                          temp.push(attribute)
                        } else {
                          temp[index].value = attribute.value
                        }
                      } else {
                        temp.push(attribute)
                      }

                      formik.setFieldValue("attribute", temp)
                      handleBulkEditFieldUpdate({ attribute: temp })
                    }}
                    handleItemSearch={handleAttributeSearch}
                    handleChipDelete={(attribute) => {
                      let temp = [...formik.values.attribute]
                      if (temp.length > 0) {
                        const filteredAttributes = temp.filter(
                          (item) => item.id !== attribute.id
                        );
                        temp = [...filteredAttributes];
                      }
                      formik.setFieldValue("attribute", temp)
                      handleBulkEditFieldUpdate({ attribute: temp })
                    }}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <BranchSelect
                    value={formik.values.nodes}
                    styles={{
                      backgroundColor: "#fff",
                      border: "thin solid #eceff0",
                    }}
                    textStyles={{ fontSize: "0.75rem" }}
                    PaperProps={{
                      classes: { root: classes.paperRoot },
                    }}
                    handleChange={(value) => {
                      formik.setFieldValue("nodes", value)
                      handleBulkEditFieldUpdate({ nodes: value })
                    }}
                  />
              </StyledTableCell>
                <StyledTableCell>
                  <Input
                    hiddenLabel
                    variant="filled"
                    size="small"
                    placeholder={getLang(lang, "placeholder.SKU")}
                    margin="none"
                    InputProps={{
                      disableUnderline: true,
                      margin: "none",
                      style: {
                        borderRadius: "4px",
                        backgroundColor: "#fff",
                        border: "thin solid #eceff0",
                        fontSize: "0.75rem",
                        height: "34px"
                      }
                    }}
                    style={{ width: "100%" }}
                    value={formik.values.sku}
                    onChange={(event) => {
                      formik.setFieldValue("sku", event.target.value)
                      handleBulkEditFieldUpdate({ sku: event.target.value })
                    }}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <SearchableSelect
                      value={formik.values.currencyCode}
                      handleChange={(value) => {
                        formik.setFieldValue("currencyCode", value)
                        handleBulkEditFieldUpdate({ currencyCode: value })
                      }}
                      placeholder={getLang(lang, "placeholder.CURRENCY_CODE")}
                      disabled={formik.isSubmitting}
                      isEmpty={formik.values.currencyCode === ""}
                      searchable={true}
                      handleItemSearch={handleCurrencyCodeSearch}
                      style={{
                        height: "34px",
                        backgroundColor: "#fff",
                        border: "thin solid #eceff0",
                        fontSize: "0.75rem",
                        width: "45%",
                        marginRight: "8px"
                      }}
                      textStyle={{ fontSize: "0.75rem" }}
                      PaperProps={{
                        classes: { root: classes.paperRoot }
                      }}
                      dropdownItem={currencyDropdownData}
                      emptyMessage={getLang(lang, "paragraph.CURRENCY_CODE_NOT_FOUND")}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    />
                    <Input
                      hiddenLabel
                      variant="filled"
                      size="small"
                      placeholder={getLang(lang, "placeholder.PRICE")}
                      margin="none"
                      InputProps={{
                        disableUnderline: true,
                        margin: "none",
                        style: {
                          borderRadius: "4px",
                          backgroundColor: "#fff",
                          border: "thin solid #eceff0",
                          fontSize: "0.75rem",
                          height: "34px"
                        }
                      }}
                      style={{ width: "55%" }}
                      value={formik.values.price}
                      onChange={(event) => {
                        if (!isNaN(event.target.value) && numberReg.test(event.target.value)) {
                          formik.setFieldValue("price", event.target.value)
                          handleBulkEditFieldUpdate({ unitPrice: event.target.value })
                        }
                      }}
                    />
                  </Box>
                </StyledTableCell>
                <StyledTableCell>
                  <Input
                    hiddenLabel
                    variant="filled"
                    size="small"
                    placeholder={getLang(lang, "placeholder.SCAN_LIMIT")}
                    margin="none"
                    inputProps={{style: { textAlign: 'right' }}}
                    InputProps={{
                      disableUnderline: true,
                      margin: "none",
                      style: {
                        borderRadius: "4px",
                        backgroundColor: "#fff",
                        border: "thin solid #eceff0",
                        fontSize: "0.75rem",
                        height: "34px"
                      }
                    }}
                    style={{ width: "100%" }}
                    value={formik.values.scanLimit}
                    onChange={(event) => {
                      if (!isNaN(event.target.value) && numberReg.test(event.target.value)) {
                        formik.setFieldValue("scanLimit", event.target.value)
                        handleBulkEditFieldUpdate({ scanLimit: event.target.value })
                      }
                    }}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <Input
                    hiddenLabel
                    variant="filled"
                    size="small"
                    placeholder={getLang(lang, "placeholder.INCENTIVE_POINTS")}
                    margin="none"
                    inputProps={{style: { textAlign: 'right' }}}
                    InputProps={{
                      disableUnderline: true,
                      margin: "none",
                      style: {
                        borderRadius: "4px",
                        backgroundColor: "#fff",
                        border: "thin solid #eceff0",
                        fontSize: "0.75rem",
                        height: "34px"
                      }
                    }}
                    style={{ width: "100%" }}
                    value={formik.values.incentivePoint}
                    onChange={(event) => {
                      if (!isNaN(event.target.value) && numberReg.test(event.target.value)) {
                        formik.setFieldValue("incentivePoint", event.target.value)
                        handleBulkEditFieldUpdate({ incentivePoint: event.target.value })
                      }
                    }}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <AntSwitch
                    checked={formik.values.status === "published" ? true : false}
                    onChange={(event) => {
                      let status = 'unpublished'
                      if (event.target.checked) {
                        status = 'published'
                      } 
                      formik.setFieldValue("status", status)
                      handleBulkEditFieldUpdate({ status })
                    }}
                  />
                </StyledTableCell>
              </StyledTableRow>
              {isFetching ? (
                [...new Array(5)].map((i, index) => (
                  <SkeletonTableRow key={index} columnCount={11} />
                ))
              ) : data.map((item, index) => {
                return (
                  <StyledTableRow key={index} hover tabIndex={-1}>
                    <StyledTableCell>
                      <img src={item.picture === "" ? DefaultPhoto : item.picture} alt="items" style={{ width: "46px", height: "46px" }} />
                    </StyledTableCell>
                    <StyledTableCell>
                      <span>{item.name}</span>
                    </StyledTableCell>
                    <StyledTableCell>
                      <span>
                        {item.category === null
                          ? "-"
                          : item.category?.name}
                      </span>
                    </StyledTableCell>
                    <StyledTableCell>
                      <span>
                        {displayBrandModel(item.brand, item.model)}
                      </span>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Box
                        style={{ display: "flex", flexWrap: "wrap" }}
                      >
                        {item.attribute.length !== 0 ? (
                          item.attribute.map((attribute, index) => (
                            <>
                              <Box
                                key={index}
                                style={{
                                  marginRight: "4px",
                                  marginBottom: "4px",
                                }}
                              >
                                <Chip
                                  key={index}
                                  label={
                                    attribute.name +
                                    ": " +
                                    attribute.options
                                  }
                                  size="small"
                                  style={{
                                    backgroundColor: "#E4E7EC",
                                    fontSize: "0.75rem",
                                  }}
                                />
                              </Box>
                            </>
                          ))
                        ) : (
                          <span>-</span>
                        )}
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Box
                        style={{ display: "flex", flexWrap: "wrap" }}
                      >
                        {item.nodes.map((node, index) => (
                          <>
                            <Box
                              style={{
                                marginRight: "4px",
                                marginBottom: "4px",
                              }}
                            >
                              <Chip
                                key={index}
                                label={node.name}
                                size="small"
                                style={{
                                  backgroundColor: "#E4E7EC",
                                  fontSize: "0.75rem",
                                }}
                              />
                            </Box>
                          </>
                        ))}
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell>
                      <span>{item.sku === null ? "-" : item.sku}</span>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Box style={{ textAlign: "right" }}>
                        <span>
                          {!item.currencyCode
                            ? "-"
                            : typeof item.currencyCode === 'string' 
                              ? item.currencyCode + " " + item.unitPriceFormatted
                              : typeof item.currencyCode === 'object' 
                              ? item.currencyCode.alpha3 + " " + item.unitPriceFormatted
                              : '-'
                          }
                        </span>
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Box style={{ textAlign: "right" }}>
                        {item.scanLimit}
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Box style={{ textAlign: "right" }}>
                        {item.incentivePoint}
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell>
                      <AntSwitch
                        checked={item.status === "published" ? true : false}
                        disabled
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : !isFetching && (
        <Box className={classes.emptyContainer}>
          <Typography variant="body1">
            {getLang(lang, "paragraph.NO_PRODUCT_SELECTED")}
          </Typography>
        </Box>
      )}
      {!isFetching && data.length > 0 && (
        <TablePagination
          style={{
            backgroundColor: "#fff",
            boxShadow: "0px -1px 0px rgba(58, 77, 84, 0.15)"
          }}
          ActionsComponent={TablePaginationActions}
          rowsPerPageOptions={paginationOptions}
          component="div"
          count={totalRecords}
          rowsPerPage={rowsPerPage}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          page={page}
          labelRowsPerPage={getLang(lang, 'label.ROWS_PER_PAGE')}
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${getLang(lang, 'label.OF')} ${count}`}
        />
      )}
    </>
  )
}