import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { 
	STOCKFLOW_DEALER_EDIT, 
	STOCKFLOW_DEALER_VIEW, 
	STOCKFLOW_STOCKOUT_VIEW, 
	STOCKFLOW_ORDER_VIEW, 
	STOCKFLOW_RECALL_VIEW, 
	STOCKFLOW_INVENTORY_VIEW, 
	STOCKFLOW_REWARD_CENTER_VIEW 
} from 'lib/constants/accessRights';
import DealerDetailsComponent from './dealer-details.page';
import { getDealerDetails, resetDealerDetails } from 'modules/stockflow/redux';
import { useState } from 'react';
import { datetimeValidation, periodValidation } from 'modules/reporting/utils/helper';
import { selectLoading } from 'modules/notification';
import { unwrapResult } from '@reduxjs/toolkit';
import { getLang } from 'app/feature/constants';

export default function DealerDetailsContainer() {
	const dispatch = useDispatch();
	const history = useHistory();
	const match = useRouteMatch();
	const query = new URLSearchParams(window.location.search);
	
	const hasStockflowDealerEditAccessRight = useSelector((state) => state.profile.accessRights.includes(STOCKFLOW_DEALER_EDIT));
	const hasStockflowDealerViewAccessRight = useSelector((state) => state.profile.accessRights.includes(STOCKFLOW_DEALER_VIEW));
	const hasStockflowStockoutViewAccessRight = useSelector((state) => state.profile.accessRights.includes(STOCKFLOW_STOCKOUT_VIEW));
	const hasStockflowOrderViewAccessRight = useSelector((state) => state.profile.accessRights.includes(STOCKFLOW_ORDER_VIEW));
	const hasStockflowRecallViewAccessRight = useSelector((state) => state.profile.accessRights.includes(STOCKFLOW_RECALL_VIEW));
	const hasStockflowInventoryViewAccessRight = useSelector((state) => state.profile.accessRights.includes(STOCKFLOW_INVENTORY_VIEW));
	const hasStockflowRewardCenterViewAccessRight = useSelector((state) => state.profile.accessRights.includes(STOCKFLOW_REWARD_CENTER_VIEW));
	const dealerDetails = useSelector((state) => state.stockflowDealer.dealerDetails);
	const isLoading = useSelector((state) => selectLoading(state, getDealerDetails.typePrefix));
	const isError = useSelector((state) => state.stockflowDealer.dealerDetails.isError);
	const lang = useSelector((state) => state.constant.languages);
	const language = useSelector((state) => state.profile.language);
	const id = match.params.dealerId;
	const period = query.get('period') ? query.get('period') : 'daily';
	const date_start = query.get('start') ? new Date(query.get('start')) : new Date(Date.now() - 13 * 24 * 60 * 60 * 1000);
	const date_end = query.get('end') ? new Date(query.get('end')) : new Date();
	const isDev = query.get('dev') ? parseInt(query.get('dev')) : null;

	const [activeIndex, setActiveIndex] = useState(null);
	const page = query.get('tab') ? query.get('tab') : 'dashboard';
	const [currentFilter, setCurrentFilter] = useState({
		period: periodValidation(period) ? period : 'daily',
		dateRange: [
			datetimeValidation(date_start)
				? date_start
				: new Date(Date.now() - 13 * 24 * 60 * 60 * 1000),
			datetimeValidation(date_end) ? date_end : new Date(),
		],
	});

	let tabLabels = [];
	if (hasStockflowDealerViewAccessRight) {
		if (isDev) {
			tabLabels.push(getLang(lang, 'label.DASHBOARD'));
		}
		if (hasStockflowStockoutViewAccessRight) {
			tabLabels.push(getLang(lang, 'label.STOCKOUT'));
			tabLabels.push(getLang(lang, 'label.RECEIVE'));
		}
		if (hasStockflowOrderViewAccessRight) {
			tabLabels.push(getLang(lang, 'label.REQUEST'));
			tabLabels.push(getLang(lang, 'label.ORDERS'));
		}
		if (hasStockflowRecallViewAccessRight) {
			tabLabels.push(getLang(lang, 'label.RECALL'));
		}
		if (hasStockflowInventoryViewAccessRight) {
			tabLabels.push(getLang(lang, 'label.INVENTORY'));
		}
		if (hasStockflowRewardCenterViewAccessRight) {
			tabLabels.push(getLang(lang, 'label.REWARD_POINTS'));
		}
		tabLabels.push(getLang(lang, 'label.DOWNLINE'));
		
		tabLabels = tabLabels.map((label, index) => ({ index, label }));
	}

	useEffect(() => {
		if (id && hasStockflowDealerViewAccessRight) {
			getData();
		} else {
			history.push('/admin/dashboard');
		}

		return () => {
			dispatch(resetDealerDetails());
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, id, hasStockflowDealerViewAccessRight]);

	useEffect(() => {
		let tempList = ['reward', 'point-history', 'expiring-point'];

		if (tempList.includes(page)) {
			const index = tabLabels.findIndex(tab => tab.label === 'Rewards & Points');
			setActiveIndex(index >0 ? index : 0);
			return;
		}
		
		let tempIndex = tabLabels.findIndex(tab => tab.label === page.charAt(0).toUpperCase() + page.slice(1));
		setActiveIndex(tempIndex >= 0 ? tempIndex : 0);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page]);

	const getData = () => {
		dispatch(getDealerDetails(id)).then(unwrapResult);
	};

	const handleChange = (event, index) => {
		if (index !== activeIndex) {
			setActiveIndex(index);

			const newQuery = new URLSearchParams();

			if (tabLabels[index]['label']  === 'Rewards & Points') {
				newQuery.set('tab', tabLabels[index]['label']);
				window.history.replaceState({}, '', `?tab=reward`);
				return;
			}
			
			newQuery.set('tab', tabLabels[index]['label']);
			window.history.replaceState({}, '', `?tab=${tabLabels[index]['label']}`);
		}
	};

	return (
		<DealerDetailsComponent
			dealer={dealerDetails}
			lang={lang}
			language={language}
			isLoading={isLoading}
			isError={isError}
			handleChange={handleChange}
			handleReload={getData}
			activeIndex={activeIndex}
			filter={{
				currentFilter,
				setCurrentFilter,
			}}
			tabLabels={tabLabels}
			hasStockflowDealerEditAccessRight={hasStockflowDealerEditAccessRight}
		/>
	);
}
