import axios from "axios"

export function getDealerInvitedTrending (parameters) {
  return new Promise((resolve, reject) => {
    axios.post(`/distributor/admin/api/v1/dealership/get-dealer-invited-trending`, {
      period: parameters.period,
      start_date: parameters.start_date,
      end_date: parameters.end_date,
      dealer_uuid: parameters.dealer_uuid,
    })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data))
  });
};