/* eslint-disable no-loop-func */
import React, { useState } from "react";
import InstantPrizeDialogComponent from "./instantPrizeDialogV2.component";
import { useDispatch, useSelector } from "react-redux";
import { toBase64 } from "modules/product-mgmt/utils/productPage.util";
import { v4 as uuidv4 } from "uuid";
import { FolderType } from "lib/constants/aws_s3";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  closePrizeDialog,
  updateInstantLuckyDrawPrizes,
  fetchAllInstantPrize,
  fetchInstantLuckyDrawListing,
} from "modules/instant-lucky-draw/redux";
import { hideBackdrop, showBackdrop } from "modules/backdrop";
import {
  INSTANT_LUCKY_DRAW_EDIT,
  INSTANT_LUCKY_DRAW_PRIZE_DELETE,
} from "lib/constants/accessRights";
import { isImage } from "lib/helper";
import { uploadImageToS3 } from "app/feature";
import { getLang } from "app/feature/constants";
import { addAlert, selectLoading } from "modules/notification";

function InstantPrizeDialogContainer() {
  const dispatch = useDispatch();
  const hasIldEditAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(INSTANT_LUCKY_DRAW_EDIT)
  );
  const hasIldPrizeDeleteAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(INSTANT_LUCKY_DRAW_PRIZE_DELETE)
  );
  const isOpen = useSelector(
    (state) => state.instantLuckyDraw.prizeDialogSettingV2.isOpen
  );
  const type = useSelector(
    (state) => state.instantLuckyDraw.prizeDialogSettingV2.type
  );
  const selectedInstantLuckyDraw = useSelector(
    (state) =>
      state.instantLuckyDraw.prizeDialogSettingV2.selectedInstantLuckyDraw
  );
  const defaultPrizeData = useSelector(
    (state) => state.instantLuckyDraw.prizeDialogInfo.defaultPrizeDetail
  );
  const hasWinRate = useSelector(
    (state) => state.instantLuckyDraw.prizeDialogInfo.hasWinRate
  );
  const prizeList = useSelector(
    (state) => state.instantLuckyDraw.prizeDialogInfo.prizeList
  );
  const disbursementInfos = useSelector(
    (state) => state.account.disbursementInfos
  );
  const pageSetting = useSelector(
    (state) => state.instantLuckyDraw.pageSetting
  );
  const isSubmitting = useSelector((state) =>
    selectLoading(state, updateInstantLuckyDrawPrizes.typePrefix)
  );
  const isFetching = useSelector((state) =>
    selectLoading(state, fetchAllInstantPrize.typePrefix)
  );
  const userId = useSelector((state) => state.session.idTokenPayload.sub);

  const lang = useSelector((state) => state.constant.languages);
  const [isEditing, setIsEditing] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const handleClose = () => {
    setIsEditing(false);
    dispatch(closePrizeDialog());
  };

  const handleSubmit = async (values) => {
    dispatch(showBackdrop());
    let prizes = [];

    let hasError = false;
    let defaultprizeImage = values.defaultPrizeData.prizeImage;
    if (isImage(defaultprizeImage)) {
      const fileName = `${uuidv4()}.png`;
      const base64Image = await toBase64(defaultprizeImage);
      const uploadLuckyDrawImageData = {
        uploadType: FolderType.instantprizeImage,
        base64Image: base64Image,
        id: userId,
        fileName: fileName,
      };

      await dispatch(uploadImageToS3(uploadLuckyDrawImageData))
        .then(unwrapResult)
        .then((imageUrl) => {
          defaultprizeImage = imageUrl.split("?")[0];
        })
        .catch(() => {
          hasError = true;
        });
    }

    if (hasError) {
      dispatch(
        addAlert({
          severity: "error",
          message: getLang(lang, "message.error.ERROR_UPLOAD_IMAGES"),
        })
      );
      dispatch(hideBackdrop());
      return;
    }

    // add default prize
    prizes.push({
      id: values.defaultPrizeData.uuid,
      title: values.defaultPrizeData.prizeName,
      imgUrl: defaultprizeImage,
      quantity: values.defaultPrizeData.quantity,
      description: values.defaultPrizeData.description,
      winRate: values.remainingWinRate,
      isDefault: 1,
      status: "active",
      orders: 1,
      prizeType: null,
      credit: 0,
      disbursementCompanyId: null,
    });

    for (let i = 0; i < values.prizeList.length; i++) {
      let prize = values.prizeList[i];
      if (isImage(prize.prizeImage)) {
        const fileName = `${uuidv4()}.png`;
        const base64Image = await toBase64(prize.prizeImage);
        const uploadLuckyDrawImageData = {
          uploadType: FolderType.instantprizeImage,
          base64Image: base64Image,
          id: userId,
          fileName: fileName,
        };

        await dispatch(uploadImageToS3(uploadLuckyDrawImageData))
          .then(unwrapResult)
          .then((imageUrl) => {
            prize.prizeImage = imageUrl.split("?")[0];
          })
          .catch(() => {
            hasError = true;
          });
      }

      if (hasError) {
        dispatch(
          addAlert({
            severity: "error",
            message: getLang(lang, "message.error.ERROR_UPLOAD_IMAGES"),
          })
        );
        dispatch(hideBackdrop());
        return;
      }

      prizes.push({
        id: prize.uuid || null,
        title: prize.prizeName,
        imgUrl: prize.prizeImage,
        quantity:
          prize.uuid && selectedInstantLuckyDraw.progress === "published"
            ? parseInt(prize.balance) + parseInt(prize.winQuantity)
            : prize.quantity,
        description: prize.description,
        winRate: prize.winRate || 0,
        isDefault: prize.is_default || 0,
        status: "active",
        orders: 1,
        prizeType: prize.type > 0 ? 2 : 1,
        credit: prize.credit,
        disbursementCompanyId: prize.type > 0 ? prize.type : null,
      });
    }

    let params = {
      prizes: prizes,
      deletedPrizeUuids: values.deletedUuids || [],
      instantLuckyDrawId: selectedInstantLuckyDraw.id,
      hasWinRate: !!values.enableWinRate,
    };

    dispatch(updateInstantLuckyDrawPrizes(params))
      .then(unwrapResult)
      .then(() => {
        const fetchPrizeTableInfo = {
          type: "dialog",
          instantLuckyDrawId: selectedInstantLuckyDraw.id,
          isWinnerShow: 1,
        };
        dispatch(fetchAllInstantPrize(fetchPrizeTableInfo));
        dispatch(fetchInstantLuckyDrawListing(pageSetting));
        setShowConfirmDialog(false);
        setIsEditing(false);
        dispatch(hideBackdrop());
      })
      .finally(() => {
        dispatch(hideBackdrop());
      });
  };

  return (
    <>
      <InstantPrizeDialogComponent
        hasIldEditAccessRight={hasIldEditAccessRight}
        hasIldPrizeDeleteAccessRight={hasIldPrizeDeleteAccessRight}
        type={type}
        prizeList={prizeList}
        handleClose={handleClose}
        isOpen={isOpen}
        defaultPrizeData={defaultPrizeData}
        isEditing={isEditing}
        lang={lang}
        handleSubmit={handleSubmit}
        setIsEditing={setIsEditing}
        isSubmitting={isSubmitting}
        hasWinRate={hasWinRate}
        selectedInstantLuckyDraw={selectedInstantLuckyDraw}
        showConfirmDialog={showConfirmDialog}
        setShowConfirmDialog={setShowConfirmDialog}
        disbursementInfos={disbursementInfos}
        isFetching={isFetching}
      />
    </>
  );
}

export default InstantPrizeDialogContainer;
