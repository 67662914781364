import UniqueConsumerPopUpComponent from "./uniqueConsumerPopup.component";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {getUniqueConsumerScannedList} from "../../../redux/action/dashboard.action";
import {ROOT as CONSUMER_ROOT} from "../../../../consumer";

export default function UniqueConsumerPopUpContainer({
  isOpen,
  handleClose,
  startDate,
  endDate,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const lang = useSelector((state) => state.constant.languages);
  const uniqueConsumerScannedList = useSelector(state => state.dashboard.uniqueConsumerScannedList);

  const [page, setPage] = useState(0);

  useEffect(() => {
    dispatch(getUniqueConsumerScannedList({startDate, endDate, page}))
  }, [dispatch, startDate, endDate, page]);

  const handleLoadingMore = () => {
    if (uniqueConsumerScannedList.records.length === uniqueConsumerScannedList.totalRecords) return;

    setPage(page + 1);
  }

  const handleConsumerNameClick = (id) => {
    history.push(CONSUMER_ROOT + '/' + id);
  }

  return <UniqueConsumerPopUpComponent
    isOpen={isOpen}
    handleClose={handleClose}
    lang={lang}
    rows={uniqueConsumerScannedList.records}
    haveNext={uniqueConsumerScannedList.hasNextPage}
    handleLoadingMore={handleLoadingMore}
    handleConsumerNameClick={handleConsumerNameClick}
  />
}
