import {
  getIncentiveProgramListing,
  createIncentiveProgram,
  getStockModuleSetting,
  getProgramDetails,
  updateProgramDetails,
  updateProgramStatus,
  deleteIncentiveProgram,
  getNewProgramProductDropdown,
  getEditProgramProductDropdown,
  bulkUpdateProgramStatus
} from "../action/stockflowIncentiveProgram.action";
import { setLoading, addAlert } from "modules/notification"
import { getApiLang } from "app/feature/constants";

const getIncentiveProgramListingMiddleware = ({ dispatch, getState }) => next => action => {
  next(action)

  const { type } = action;
  const lang = getState().constant.languages;
  switch (type) {
    case getIncentiveProgramListing.pending.type:
      dispatch(setLoading({ id: getIncentiveProgramListing.typePrefix, state: true }));
      break;
    case getIncentiveProgramListing.fulfilled.type:
      dispatch(setLoading({ id: getIncentiveProgramListing.typePrefix, state: false }));
      break;
    case getIncentiveProgramListing.rejected.type:
      dispatch(setLoading({ id: getIncentiveProgramListing.typePrefix, state: false }));
      dispatch(addAlert({ severity: "error", message: getApiLang(lang, 'error.ERROR_GET_DEALER_INCENTIVE_PROGREAM_LIST')}));
      break;
    default:
      break;
  }
}

const getNewProgramProductDropdownMiddleware = ({ dispatch, getState }) => next => action => {
  next(action)

  const { type } = action;
  const lang = getState().constant.languages;
  switch (type) {
    case getNewProgramProductDropdown.pending.type:
      dispatch(setLoading({ id: getNewProgramProductDropdown.typePrefix, state: true }));
      break;
    case getNewProgramProductDropdown.fulfilled.type:
      dispatch(setLoading({ id: getNewProgramProductDropdown.typePrefix, state: false }));
      break;
    case getNewProgramProductDropdown.rejected.type:
      dispatch(setLoading({ id: getNewProgramProductDropdown.typePrefix, state: false }));
      dispatch(addAlert({ severity: "error", message: getApiLang(lang, 'error.ERROR_GET_PRODUCT_DROPDOWN') }));
      break;
    default:
      break;
  }
}

const getEditProgramProductDropdownMiddleware = ({ dispatch, getState}) => next => action => {
  next(action)

  const { type } = action;
  const lang = getState().constant.languages;
  switch (type) {
    case getEditProgramProductDropdown.pending.type:
      dispatch(setLoading({ id: getEditProgramProductDropdown.typePrefix, state: true }));
      break;
    case getEditProgramProductDropdown.fulfilled.type:
      dispatch(setLoading({ id: getEditProgramProductDropdown.typePrefix, state: false }));
      break;
    case getEditProgramProductDropdown.rejected.type:
      dispatch(setLoading({ id: getEditProgramProductDropdown.typePrefix, state: false }));
      dispatch(addAlert({ severity: "error", message:  getApiLang(lang, 'error.ERROR_GET_PRODUCT_DROPDOWN') }));
      break;
    default:
      break;
  }
}

const createIncentiveProgramMiddleware = ({ dispatch, getState }) => next => action => {
  next(action)

  const { type } = action;
  const lang = getState().constant.languages;
  switch (type) {
    case createIncentiveProgram.pending.type:
      dispatch(setLoading({ id: createIncentiveProgram.typePrefix, state: true }));
      break;
    case createIncentiveProgram.fulfilled.type:
      dispatch(setLoading({ id: createIncentiveProgram.typePrefix, state: false }));
      dispatch(addAlert({ severity: "success", message: getApiLang(lang, 'success.SUCCESS_CREATE_INCENTIVE_PROGRAM') }));
      break;
    case createIncentiveProgram.rejected.type:
      dispatch(setLoading({ id: createIncentiveProgram.typePrefix, state: false }));
      dispatch(addAlert({ severity: "error", message: getApiLang(lang, 'error.ERROR_CREATE_INCENTIVE_PROGRAM') }));
      break;
    default:
      break;
  }
}

const getStockModuleSettingMiddleware = ({ dispatch, getState }) => next => action => {
  next(action)

  const { type } = action;
  const lang = getState().constant.languages;
  switch (type) {
    case getStockModuleSetting.pending.type:
      dispatch(setLoading({ id: getStockModuleSetting.typePrefix, state: true }));
      break;
    case getStockModuleSetting.fulfilled.type:
      dispatch(setLoading({ id: getStockModuleSetting.typePrefix, state: false }));
      break;
    case getStockModuleSetting.rejected.type:
      dispatch(setLoading({ id: getStockModuleSetting.typePrefix, state: false }));
      dispatch(addAlert({ severity: "error", message: getApiLang(lang, 'error.ERROR_GET_STOCK_MODULE_SETTINGS') }));
      break;
    default:
      break;
  }
}

const getProgramDetailsMiddleware = ({ dispatch, getState }) => next => action => {
  next(action)

  const { type } = action;
  const lang = getState().constant.languages;
  switch (type) {
    case getProgramDetails.pending.type:
      dispatch(setLoading({ id: getProgramDetails.typePrefix, state: true }));
      break;
    case getProgramDetails.fulfilled.type:
      dispatch(setLoading({ id: getProgramDetails.typePrefix, state: false }));
      break;
    case getProgramDetails.rejected.type:
      dispatch(setLoading({ id: getProgramDetails.typePrefix, state: false }));
      dispatch(addAlert({ severity: "error", message:  getApiLang(lang,'error.ERROR_GET_DEALER_INCENTIVE_PROGRAM')}));
      break;
    default:
      break;
  }
}

const updateProgramDetailsMiddleware = ({ dispatch, getState }) => next => action => {
  next(action)

  const { type, payload } = action;
  const lang = getState().constant.languages;
  switch (type) {
    case updateProgramDetails.pending.type:
      dispatch(setLoading({ id: updateProgramDetails.typePrefix, state: true }));
      break;
    case updateProgramDetails.fulfilled.type:
      dispatch(setLoading({ id: updateProgramDetails.typePrefix, state: false }));
      dispatch(addAlert({ severity: "success", message: getApiLang(lang, 'success.SUCCESS_UPDATE_INCENTIVE_PROGRAM') }));
      break;
    case updateProgramDetails.rejected.type:
      dispatch(setLoading({ id: updateProgramDetails.typePrefix, state: false }));
      dispatch(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
      break;
    default:
      break;
  }
}

const updateProgramStatusMiddleware = ({ dispatch, getState }) => next => action => {
  next(action)

  const { type } = action;
  const lang = getState().constant.languages;
  switch (type) {
    case updateProgramStatus.pending.type:
      dispatch(setLoading({ id: updateProgramStatus.typePrefix, state: true }));
      break;
    case updateProgramStatus.fulfilled.type:
      dispatch(setLoading({ id: updateProgramStatus.typePrefix, state: false }));
      dispatch(addAlert({ severity: "success", message: getApiLang(lang, 'success.SUCCESS_UPDATE_DEALER_INCENTIVE_PROGRAM_STATUS') }));
      break;
    case updateProgramStatus.rejected.type:
      dispatch(setLoading({ id: updateProgramStatus.typePrefix, state: false }));
      dispatch(addAlert({ severity: "error", message: getApiLang(lang, 'error.ERROR_UPDATE_DEALER_INCENTIVE_PROGRAM_STATUS') }));
      break;
    default:
      break;
  }
}

const deleteIncentiveProgramMiddleware = ({ dispatch, getState }) => next => action => {
  next(action)

  const { type, payload } = action;
  const lang = getState().constant.languages;
  switch (type) {
    case deleteIncentiveProgram.pending.type:
      dispatch(setLoading({ id: deleteIncentiveProgram.typePrefix, state: true }));
      break;
    case deleteIncentiveProgram.fulfilled.type:
      dispatch(setLoading({ id: deleteIncentiveProgram.typePrefix, state: false }));
      dispatch(addAlert({ severity: "success", message: getApiLang(lang, 'success.SUCCESS_DELETE_DEALER_INCENTIVE_PROGRAM') }));
      break;
    case deleteIncentiveProgram.rejected.type:
      dispatch(setLoading({ id: deleteIncentiveProgram.typePrefix, state: false }));
      dispatch(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
      break;
    default:
      break;
  }
}

const bulkUpdateProgramStatusMiddleware = ({ dispatch, getState }) => next => action => {
  next(action)

  const { type } = action;
  const lang = getState().constant.languages;
  switch (type) {
    case bulkUpdateProgramStatus.pending.type:
      dispatch(setLoading({ id: bulkUpdateProgramStatus.typePrefix, state: true }));
      break;
    case bulkUpdateProgramStatus.fulfilled.type:
      dispatch(setLoading({ id: bulkUpdateProgramStatus.typePrefix, state: false }));
      dispatch(addAlert({ severity: "success", message: getApiLang(lang, 'success.SUCCESS_UPDATE_DEALER_INCENTIVE_PROGRAM_STATUS') }));
      break;
    case bulkUpdateProgramStatus.rejected.type:
      dispatch(setLoading({ id: bulkUpdateProgramStatus.typePrefix, state: false }));
      dispatch(addAlert({ severity: "error", message: getApiLang(lang, 'error.ERROR_UPDATE_DEALER_INCENTIVE_PROGRAM_STATUS') }));
      break;
    default:
      break;
  }
}

export default [
  getIncentiveProgramListingMiddleware,
  getNewProgramProductDropdownMiddleware,
  getEditProgramProductDropdownMiddleware,
  createIncentiveProgramMiddleware,
  getStockModuleSettingMiddleware,
  getProgramDetailsMiddleware,
  updateProgramDetailsMiddleware,
  updateProgramStatusMiddleware,
  deleteIncentiveProgramMiddleware,
  bulkUpdateProgramStatusMiddleware
]