import React from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";
import { getLang } from "app/feature/constants";
import GroupBranchRuleTable from "../../table/groupBranchRuleTable";
import {
  CloseRounded as CloseRoundedIcon,
  CheckRounded as CheckRoundedIcon,
} from "@material-ui/icons";

const useStyle = makeStyles((theme) => ({
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    borderCollapse: "separate",
    width: "100%",
    border: "1px solid #D0D5DD",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
    },
    "& td": {
      backgroundColor: "#FFFFFF",
      borderRight: "none",
      padding: "8px 16px",
    },
    "& td td": {
      borderBottom: "none !important",
    },
  },
  fixedCellContent: {
    width: 158,
    minWidth: 130,
    whiteSpace: "normal",
  },
  emptyContainer: {
    border: "1px solid #D0D5DD",
    minHeight: 120,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
}));

export default function GroupTransferRulePanelComponent({ lang, group }) {
  const classes = useStyle();

  return (
    <>
      <Box display="flex" alignItems="center" mt={2}>
        <Box display="flex" alignItems="center" mr={3}>
          {group.is_transfer_rule_enable ? (
            <CheckRoundedIcon
              style={{
                color: "#1FD026",
              }}
            />
          ) : (
            <CloseRoundedIcon
              style={{
                color: "#F04438",
              }}
            />
          )}
          <Typography variant="body2" style={{ marginLeft: 10 }}>
            {getLang(lang, "label.ENABLE_TRANSFER_RULES")}
          </Typography>
        </Box>
      </Box>
      <Box mt={4}>
        <Box mt={5} mb={2}>
          <Typography variant="body1">
            <b>{getLang(lang, "label.GROUP_TRANSFER_RULES")}</b>
          </Typography>
        </Box>
        {group.rules.length ? (
          <GroupBranchRuleTable
            lang={lang}
            isEditable={false}
            rows={group.rules}
            isFetching={false}
            isError={false}
            type="group"
          />
        ) : (
          <Box className={classes.emptyContainer}>
            <Typography>
              {getLang(lang, "paragraph.NO_GROUP_TRANSFER_RULE_SET")}
            </Typography>
          </Box>
        )}
      </Box>
      {!group.is_default && (
        <>
          <Box mt={5} mb={2}>
            <Typography variant="body1">
              <b>{getLang(lang, "label.BRANCH_CUSTOM_TRANSFER_RULES")}</b>
            </Typography>
          </Box>
          {group.custom_rules.length ? (
            <Box>
              {group.custom_rules.map((rule, index) => (
                <Box mb={2} key={index}>
                  <Box mb={1}>
                    <Typography variant="body2">
                      <b>{rule.from_node}</b>
                    </Typography>
                  </Box>
                  <Box>
                    <GroupBranchRuleTable
                      lang={lang}
                      isEditable={false}
                      rows={rule.to_node}
                      isFetching={false}
                      isError={false}
                      type="branch"
                    />
                  </Box>
                </Box>
              ))}
            </Box>
          ) : (
            <Box className={classes.emptyContainer}>
              <Typography>
                {getLang(lang, "paragraph.NO_BRANCH_CUSTOM_TRANSFER_RULE_SET")}
              </Typography>
            </Box>
          )}
        </>
      )}
    </>
  );
}
