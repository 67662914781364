import axios from "axios"

export function getDealerRequestList (parameters) {
  return new Promise((resolve, reject) => {
    axios.post(`/distributor/admin/api/v1/dealers/${parameters.id}/request`, {
      length: parameters.length,
      start: parameters.start,
      order: parameters.order,
      order_by: parameters.order_by,
      type: parameters.type,
      filter: {
        search: parameters.filter.search,
        from_date: parameters.filter.from_date,
        to_date: parameters.filter.to_date,
        request_to: parameters.filter.request_to,
        respond_date: parameters.filter.respond_date,
      }
    })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data))
  });
};