import { getLang } from "app/feature/constants";

export const luckyDrawCreated = ({
  hasLuckyDrawEditAccessRight,
  id,
  disableViewParticipant,
  progress,
  handleView,
  handleViewParticipants,
  handleViewPrize,
  lang
}) => {
  let actionItems = []
  actionItems.push({
    label: hasLuckyDrawEditAccessRight ? getLang(lang,"label.VIEW_EDIT_DETAIL") : getLang(lang,"label.VIEW_DETAIL"),
    action: () => handleView(id)
  })
  if(hasLuckyDrawEditAccessRight){
    actionItems.push({
      label: getLang(lang,"label.SET_PRIZE"),
      action: () => handleViewPrize(id, progress)
    })
  }
  actionItems.push({
    label: getLang(lang,"label.VIEW_PARTICIPANTS"),
    action: () => handleViewParticipants(id),
    disabled: disableViewParticipant,
  })
  if(hasLuckyDrawEditAccessRight){
    actionItems.push({
      label: getLang(lang,"label.SET_WINNER"),
      disabled: true,
      required: true,
      requiredText: getLang(lang,"label.REQUIRED_SET_PRIZE")
    })
  }
  actionItems.push({
    label: getLang(lang,"label.DOWNLOAD_WINNER_LIST"),
    disabled: true,
    required: true,
    requiredText: getLang(lang,"label.REQUIRED_CONFIRM_WINNER")
  })
  actionItems.push({
    label: getLang(lang,"label.LIVE_DRAW"),
    disabled: true,
    required: true,
    requiredText: getLang(lang,"label.REQUIRED_CONFIRM_WINNER")
  })
  return actionItems
};

export const prizeCreated = ({
  hasLuckyDrawEditAccessRight,
  id,
  disableViewParticipant,
  progress,
  handleView,
  handleViewParticipants,
  handleViewPrize,
  handleViewWinner,
  lang
}) => {
  let actionItems = []
  actionItems.push({
    label: hasLuckyDrawEditAccessRight ? getLang(lang,"label.VIEW_EDIT_DETAIL") : getLang(lang,"label.VIEW_DETAIL"),
    action: () => handleView(id)
  })
  actionItems.push({
    label: hasLuckyDrawEditAccessRight ? getLang(lang,"label.VIEW_EDIT_PRIZE") : getLang(lang,"label.VIEW_PRIZE"),
    action: () => handleViewPrize(id, progress)
  })
  if(hasLuckyDrawEditAccessRight){
    actionItems.push({
      label: getLang(lang,"label.SET_WINNER"),
      action: () => handleViewWinner(id, progress)
    })
  }
  actionItems.push({
    label: getLang(lang,"label.VIEW_PARTICIPANTS"),
    action: () => handleViewParticipants(id),
    disabled: disableViewParticipant,
  },)
  actionItems.push({
    label: getLang(lang,"label.DOWNLOAD_WINNER_LIST"),
    disabled: true,
    required: true,
    requiredText: getLang(lang,"label.REQUIRED_CONFIRM_WINNER")
  })
  actionItems.push({
    label: getLang(lang,"label.LIVE_DRAW"),
    disabled: true,
    required: true,
    requiredText: getLang(lang,"label.REQUIRED_CONFIRM_WINNER")
  })
  return actionItems
};

export const winnerCreated = ({
  hasLuckyDrawEditAccessRight,
  id,
  disableViewParticipant,
  progress,
  handleView,
  handleViewParticipants,
  handleViewPrize,
  handleViewWinner,
  lang
}) => {
  let actionItems = []
  actionItems.push({
    label: hasLuckyDrawEditAccessRight ? getLang(lang,"label.VIEW_EDIT_DETAIL") : getLang(lang,"label.VIEW_DETAIL"),
    action: () => handleView(id)
  })
  actionItems.push({
    label: hasLuckyDrawEditAccessRight ? getLang(lang,"label.VIEW_EDIT_PRIZE") : getLang(lang,"label.VIEW_PRIZE"),
    action: () => handleViewPrize(id, progress)
  })
  actionItems.push({
    label: hasLuckyDrawEditAccessRight ? getLang(lang,"label.VIEW_EDIT_WINNER") : getLang(lang,"label.VIEW_WINNER"),
    action: () => handleViewWinner(id, progress)
  })
  actionItems.push({
    label: getLang(lang,"label.VIEW_PARTICIPANTS"),
    action: () => handleViewParticipants(id),
    disabled: disableViewParticipant,
  },)
  actionItems.push({
    label: getLang(lang,"label.DOWNLOAD_WINNER_LIST"),
    disabled: true,
    required: true,
    requiredText: getLang(lang,"label.REQUIRED_CONFIRM_WINNER")
  })
  actionItems.push({
    label: getLang(lang,"label.LIVE_DRAW"),
    disabled: true,
    required: true,
    requiredText: getLang(lang,"label.REQUIRED_CONFIRM_WINNER")
  })
  return actionItems
};

export const winnerConfirm = ({
  hasLuckyDrawEditAccessRight,
  id,
  disableViewParticipant,
  progress,
  luckyDrawTitle,
  handleView,
  handleViewParticipants,
  handleViewPrize,
  handleViewWinner,
  handleLiveDraw,
  handleDownloadWinner,
  lang
}) => {
  return [
    {
      label: hasLuckyDrawEditAccessRight ? getLang(lang,"label.VIEW_EDIT_DETAIL") : getLang(lang,"label.VIEW_DETAIL"),
      action: () => handleView(id)
    },
    {
      label: hasLuckyDrawEditAccessRight ? getLang(lang,"label.VIEW_EDIT_PRIZE") : getLang(lang,"label.VIEW_PRIZE"),
      action: () => handleViewPrize(id, progress)
    },
    {
      label: hasLuckyDrawEditAccessRight ? getLang(lang,"label.VIEW_EDIT_WINNER") : getLang(lang,"label.VIEW_WINNER"),
      action: () => handleViewWinner(id, progress)
    },
    {
      label: getLang(lang,"label.VIEW_PARTICIPANTS"),
      action: () => handleViewParticipants(id),
      disabled: disableViewParticipant,
    },
    {
      label: getLang(lang,"label.DOWNLOAD_WINNER_LIST"),
      action: () => handleDownloadWinner(id)
    },
    {
      label: getLang(lang,"label.LIVE_DRAW"),
      action: () => handleLiveDraw(id, progress, luckyDrawTitle)
    }
  ];
};

export const live = ({
  hasLuckyDrawEditAccessRight,
  id,
  disableViewParticipant,
  progress,
  luckyDrawTitle,
  handleView,
  handleViewParticipants,
  handleViewPrize,
  handleViewWinner,
  handleLiveDraw,
  handleDownloadWinner,
  lang
}) => {
  return [
    {
      label: getLang(lang,"label.VIEW_DETAIL"),
      action: () => handleView(id)
    },
    {
      label: getLang(lang,"label.VIEW_PRIZE"),
      action: () => handleViewPrize(id, progress)
    },
    {
      label: getLang(lang,"label.VIEW_WINNER"),
      action: () => handleViewWinner(id, progress)
    },
    {
      label: getLang(lang,"label.DOWNLOAD_WINNER_LIST"),
      action: () => handleDownloadWinner(id)
    },
    {
      label: getLang(lang,"label.LIVE_DRAW"),
      action: () => handleLiveDraw(id, progress, luckyDrawTitle)
    },
    {
      label: getLang(lang,"label.VIEW_PARTICIPANTS"),
      action: () => handleViewParticipants(id),
      disabled: disableViewParticipant,
    }
  ];
};
