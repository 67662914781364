export function isEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function isEightChar(string) {
  const re = /^(?=.{8,})/;
  if (string !== "" && re.test(string)) {
    return true;
  } else {
    return false;
  }
}

export function isLowercaseExist(string) {
  const re = /^(?=.*[a-z])/;
  if (string !== "" && re.test(string)) {
    return true;
  } else {
    return false;
  }
}

export function isUppercaseExist(string) {
  const re = /^(?=.*[A-Z])/;
  if (string !== "" && re.test(string)) {
    return true;
  } else {
    return false;
  }
}

export function isNumExist(string) {
  const re = /^(?=.*[0-9])/;
  if (string !== "" && re.test(string)) {
    return true;
  } else {
    return false;
  }
}

export function isSpecialCharExist(string) {
  const re = /[!@#$%^&~`*()_+\-={}[\]:";'<>,.?|]/;
  if (string !== "" && re.test(string)) {
    return true;
  } else {
    return false;
  }
}

export function isPasswordValid(string) {
  const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&~`*()_+\-={}[\]:";'<>,.?|])(?=.{8,})/;
  if (string !== "" && re.test(string)) {
    return true;
  } else {
    return false;
  }
}

export function getDate(date=new Date(), shortMonth=false) {

  const MonthList = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];

  const day = String(date.getDate()).padStart(2, "0");
  const month = shortMonth
    ? MonthList[date.getMonth()].slice(0, 3)
    : MonthList[date.getMonth()]; //Be careful! January is 0 not 1
  const year = date.getFullYear();

  return `${day} ${month} ${year}`;
}

export function getDay() {
  const today = new Date();
  const dayList = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday"
  ];
  const day = dayList[today.getDay() - 1];

  return day;
}

export function longStringHelper(str) {
  const sliceBoundary = str => str.substr(0, str.lastIndexOf(" "));
  const truncate = (n, useWordBoundary) =>
    str.length <= n
      ? str
      : `${
          useWordBoundary
            ? sliceBoundary(str.slice(0, n - 1))
            : str.substr(0, n - 1)
        } ...`;
  return { full: str, truncate };
}

export function convertToBigNum(bigNum) {
  return bigNum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

export function getBase64String(imageBase64) {
  const splitString = imageBase64.split("data:image/png;base64,");
  if (splitString.length !== 2) return imageBase64;
  return splitString[1];
}

export function roundTo2Decimals(num) {
  return Math.round((num + Number.EPSILON) * 100) / 100;
}

export function convertToNumberString(amount) {
  const roundedAmount = roundTo2Decimals(amount);

  const [wholeAmount, pennyAmount] = roundedAmount.toString().split(".");
  const dollarString = wholeAmount.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");

  return `${dollarString}${pennyAmount ? "." + pennyAmount : ""}`;
}

export function convertFirstLetterUppercase(string) {
  const re = /(\b[a-z](?!\s))/g;
  return string.replace(re, x => x.toUpperCase());
}

export function isDataUrl(url) {
  const re = /^\s*data:([a-z]+\/[a-z]+(;[a-z-]+=[a-z-]+)?)?(;base64)?,[a-z0-9!$&',()*+,;=\-._~:@/?%\s]*\s*$/i;

  return re.test(url);
}

export function isUrl(url) {
  const re = /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!]))?/;

  return re.test(url);
}

export function getYearList() {
  const yearlist = [];
  const year = new Date().getFullYear();
  yearlist.push(year - 2);
  yearlist.push(year - 1);
  yearlist.push(year);

  return yearlist;
}

export function isNumberInvalid(string) {
  const invalidChars = ["-","+","e","."];
  
  if(invalidChars.includes(string)) {
    return true 
  } else {
    return false
  }
}
