import { ROOT as DASHBOARD_ROOT } from "modules/dashboard";
import { ROOT as SERIAL_NUMBER_ROOT } from "modules/serial-number";
import { ROOT as BILLING_ROOT } from "modules/billing";
import { ROOT as STORE_ROOT } from "modules/store";
import { ROOT as PRODUCT_MGMT_ROOT } from "modules/product-mgmt";
import { ROOT as PROFILE_ROOT } from "modules/profile";
import { ROOT as ACCOUNT_ROOT } from "modules/account";
import { ROOT as COUNTERFEIT_ROOT } from "modules/counterfeit-report";
import { ROOT as CONSUMER_ROOT } from "modules/consumer";
import { ROOT as WARRANTY_ROOT } from "modules/warranty";
import { ROOT as SURVEY_ROOT } from "modules/survey";
import { ROOT as LOYALTY_PROGRAM_ROOT } from "modules/loyalty-program";
import { ROOT as LUCKY_DRAW_ROOT } from "modules/lucky-draw";
import { ROOT as ORGANISATION_STRUCTURE_ROOT } from "modules/organisation-structure";
import { ROOT as BRANCH_ROOT } from "modules/branch";
import { ROOT as GROUP_ROOT } from "modules/group";
import { ROOT as EMPLOYEE_ROOT } from "modules/employee";
import { ROOT as ROLE_ACCESS_RIGHT_ROOT } from "modules/role-access-right";
import { ROOT as INSTANT_LUCKY_DRAW } from "modules/instant-lucky-draw";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { ADMIN, APP } from "modules/admin/constants";
import accountApi from "app/api/account";
import companyApi from "app/api/company";
import authApi from "app/api/authentication";
import {
  DEALEAR_PROGRAM_LIST_PAGE,
  MY_DEALER_LIST_PAGE,
  REWARD_CENTER_PAGE,
} from "modules/stockflow";

/**
 * * action with redux-toolkit
 */

export const setCurrentModule = createAction(
  `${APP}/setCurrentModule`,
  (pathname) => {
    const rootCurrentModule = pathname.split("/", 3).join("/");

    const pages = [
      { path: DASHBOARD_ROOT, label: "Dashboard" },
      { path: BILLING_ROOT, label: "Billing" },
      { path: STORE_ROOT, label: "Market Place" },
      { path: PRODUCT_MGMT_ROOT, label: "Product" },
      { path: SERIAL_NUMBER_ROOT, label: "Serial Number" },
      { path: PROFILE_ROOT, label: "Profile" },
      { path: ACCOUNT_ROOT, label: "Account" },
      { path: CONSUMER_ROOT, label: "Consumer" },
      { path: COUNTERFEIT_ROOT, label: "Counterfeit Report" },
      { path: WARRANTY_ROOT, label: "Warranty" },
      { path: SURVEY_ROOT, label: "Survey" },
      { path: LOYALTY_PROGRAM_ROOT, label: "Loyalty Program" },
      { path: LUCKY_DRAW_ROOT, label: "Lucky Draw" },
      { path: ORGANISATION_STRUCTURE_ROOT, label: "Organisation Structure" },
      { path: BRANCH_ROOT, label: "Branch" },
      { path: GROUP_ROOT, label: "Group" },
      { path: EMPLOYEE_ROOT, label: "Employee" },
      { path: ROLE_ACCESS_RIGHT_ROOT, label: "Role & Access Control" },
      { path: INSTANT_LUCKY_DRAW, label: "Instant Lucky Draw" },
      { path: MY_DEALER_LIST_PAGE, label: "My Dealer" },
      { path: REWARD_CENTER_PAGE, label: "Reward Center" },
      { path: DEALEAR_PROGRAM_LIST_PAGE, label: "Dealer Incentive Program" },
    ];

    const index = pages.findIndex((c) => c.path === rootCurrentModule);

    return {
      payload: index !== -1 ? pages[index].label : "SmartKood",
    };
  }
);

export const initializeApp = createAction(`${ADMIN}/initializeApp`);

export const logout = createAsyncThunk(
  `${ADMIN}/logout`,
  async (payload, { rejectWithValue }) => {
    authApi
      .signOut({
        sessionId: payload.sessionId,
        sessionExpiration: payload.sessionExpiration,
      })
      .then((response) => response.data.data)
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const fetchMasterInfo = createAsyncThunk(
  `${ADMIN}/fetchMasterInfo`,
  async (payload, { rejectWithValue }) => {
    return accountApi
      .bootstrap()
      .then((response) => response)
      .catch((error) => rejectWithValue(error));
  }
);

export const fetchAppSetting = createAsyncThunk(
  `${ADMIN}/fetchAppSetting`,
  async (payload, { rejectWithValue }) => {
    return accountApi.company
      .get()
      .then((response) => response)
      .catch((error) => rejectWithValue(error));
  }
);

export const fetchLabel = createAsyncThunk(`${ADMIN}/fetchLabel`, async () => {
  const response = await accountApi.fetchLabel();

  return response.data;
});

export const fetchCompanyInfo = createAsyncThunk(
  `${ADMIN}/fetchCompanyInfo`,
  async () => {
    const response = await companyApi.fetchCompanyInfo();

    return response.data;
  }
);

export const checkSession = createAsyncThunk(
  `${ADMIN}/checkSession`,
  async (payload, { rejectWithValue }) => {
    return authApi
      .checkSession({
        sessionId: payload.sessionId,
        sessionExpiration: payload.sessionExpiration,
      })
      .then((response) => response.data.data)
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const checkMaintenanceAlert = createAsyncThunk(
  `${ADMIN}/checkMaintenanceAlert`,
  async (payload, { rejectWithValue }) => {
    return accountApi
      .checkMaintenanceAlert()
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response.data));
  }
);
