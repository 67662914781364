import React from 'react'
import { Box, Button, Checkbox, Divider, FormControlLabel, FormGroup, makeStyles, Typography } from '@material-ui/core'
import { Formik } from "formik"
import * as Yup from "yup";
import Input from "components/input/inputTextField";
import BulkCreateCategorySelect from '../../select/bulkCreateCategorySelect';
import BulkCreateBrandModelSelect from '../../select/bulkCreateBrandModelSelect';
import CurrencySelect from '../../select/currencySelect';
import BulkCreateBranchSelect from '../../select/bulkCreateBranchSelect';
import BulkCreateAttributeSelect from '../../select/bulkCreateAttributeSelect';
import { getLang } from 'app/feature/constants';

const useStyle = makeStyles(theme => ({
  generateContainer: {
    backgroundColor: "#ffffff",
    marginBottom: theme.spacing(5),
    marginTop: theme.spacing(3),
  },
  generateFormContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    columnGap: theme.spacing(3),
    marginTop: "24px",
    marginBottom: "32px",
    marginLeft: "32px",
    marginRight: "32px",
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(2),
      margin: 24
    }
  },
  itemFormContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 2fr",
    columnGap: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1)
    }
  },
  paperRoot: {
    width: "359px",
  },
  inputRoot: {
    paddingTop: "8px !important",
    paddingBottom: "7px !important",
    paddingLeft: "12px !important",
    paddingRight: "12px !important"
  },
  checkboxContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    columnGap: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1)
    }
  },
  warningText: {
    color: "#F04438"
  }
}))

export default function BulkEditListPanelComponent({
  totalRecords,
  languages,
  handleAddProducts,
  calculateCombination,
  handleBranchSearch,
  lang
}) {
  const classes = useStyle();
  const numberReg = /^-?\d*(\.\d*)?$/

  return (
    <>
      <Box className={classes.generateContainer}>
        <Box>
          <Box style={{ paddingTop: "32px", paddingLeft: "32px", paddingRight: "32px" }}>
            <Typography
              variant='body1'
              color='textPrimary'
              style={{ fontWeight: "bold" }}
            >
              {getLang(lang, "label.GENERATE_PRODUCT")}
            </Typography>
          </Box>
          <Formik
            initialValues={{
              category: "",
              brandModel: [],
              attributes: [],
              branch: [],
              currencyCode: [],
              scanLimit: "",
              incentivePoint: "",
              isCategory: true,
              isModel: true,
              isBrand: true,
              isAttribute: true,
            }}
            validationSchema={Yup.object({
              brandModel: Yup.array().required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
              branch: Yup.array().required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
              scanLimit: Yup.number().required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED"))
            })}
            enableReinitialize
            validateOnChange={false}
            onSubmit={(values, { setSubmitting }) => {
              handleAddProducts(values, setSubmitting);
            }}
          >
            {(formik) => (
              <form onSubmit={formik.handleSubmit}>
                <Box className={classes.generateFormContainer}>
                  <Box>
                    <Box className={classes.itemFormContainer} style={{ marginBottom: "20px" }}>
                      <Box display="flex" mt={0.75}>
                        <Typography variant='body1' color='primary'>{getLang(lang, "label.CATEGORY")}</Typography>
                      </Box>
                      <BulkCreateCategorySelect
                        value={formik.values.category}
                        handleChange={async (value) => {
                          formik.setFieldValue("category", value)
                          calculateCombination({
                            ...formik.values,
                            category: value
                          })
                        }}
                        placeholder={getLang(lang, "placeholder.CATEGORY")}
                        disabled={formik.isSubmitting}
                        isEmpty={!formik.values.category}
                        searchable={true}
                        style={{ height: "34px" }}
                        PaperProps={{
                          classes: { root: classes.paperRoot }
                        }}
                        emptyMessage={getLang(lang, "paragraph.CATEGORY_NOT_FOUND")}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left"
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left"
                        }}
                        returnType="object"
                      />
                    </Box>
                    <Box className={classes.itemFormContainer} style={{ marginBottom: "20px" }}>
                      <Box display="flex" mt={0.75}>
                        <Typography variant='body1' color='primary'>{getLang(lang, "label.BRAND_MODEL")} *</Typography>
                      </Box>
                      <Box display="flex" flexDirection="column" overflow="hidden">
                        <BulkCreateBrandModelSelect
                          placeholder={getLang(lang, "placeholder.BRAND_MODEL")}
                          disabled={formik.isSubmitting}
                          handleChange={(value) => {
                            formik.setFieldValue("brandModel", value)
                            calculateCombination({
                              ...formik.values,
                              brandModel: value
                            })
                          }}
                          value={formik.values.brandModel}
                          styles={{
                            padding: "6px 10px"
                          }}
                          searchPlaceholder={getLang(lang, "placeholder.SEARCH")}
                          searchable={true}
                          PaperProps={{
                            classes: { root: classes.paperRoot }
                          }}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left"
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "left"
                          }}
                        />
                        {!!formik.errors?.brandModel && (
                          <Typography variant='caption' className={classes.warningText}>{formik.errors?.brandModel}</Typography>
                        )}
                      </Box>
                    </Box>
                    <Box className={classes.itemFormContainer} style={{ marginBottom: "20px" }}>
                      <Box display="flex" mt={0.75}>
                        <Typography variant='body1' color='primary'>{getLang(lang, "label.CURRENCY")}</Typography>
                      </Box>
                      <CurrencySelect
                        placeholder={getLang(lang, "placeholder.CURRENCY")}
                        value={formik.values.currencyCode}
                        handleChange={(value) => {
                          formik.setFieldValue("currencyCode", value)
                          calculateCombination({
                            ...formik.values,
                            currencyCode: value
                          })
                        }}
                        styles={{
                          padding: "6px 10px"
                        }}
                        searchPlaceholder={getLang(lang, "placeholder.SEARCH")}
                        searchable={true}
                      />  
                    </Box>
                    <Box className={classes.itemFormContainer} style={{ marginBottom: "20px" }}>
                      <Box display="flex" mt={0.75}>
                        <Typography variant='body1' color='primary'>Branch *</Typography>
                      </Box>
                      <Box display="flex" flexDirection="column">
                        <BulkCreateBranchSelect
                          placeholder={getLang(lang, "placeholder.BRANCH")}
                          value={formik.values.branch}
                          handleChange={(value) => {
                            formik.setFieldValue("branch", value)
                            calculateCombination({
                              ...formik.values,
                              branch: value
                            })
                          }}
                          styles={{
                            padding: "6px 10px"
                          }}
                          searchable={true}
                          handleBranchSearch={handleBranchSearch}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                        />
                        {!!formik.errors?.branch && (
                          <Typography variant='caption' className={classes.warningText}>{formik.errors?.branch}</Typography>
                        )}
                      </Box>
                    </Box>
                    <Box className={classes.itemFormContainer} style={{ marginBottom: "20px" }}>
                      <Box display="flex" mt={0.75}>
                        <Typography variant='body1' color='primary'>{getLang(lang, "placeholder.SCAN_LIMIT")} *</Typography>
                      </Box>
                      <Box display="flex" flexDirection="column" overflow="hidden">
                        <Input
                          hiddenLabel
                          variant="filled"
                          size="small"
                          placeholder={getLang(lang, "placeholder.SCAN_LIMIT")}
                          margin="none"
                          InputProps={{
                            disableUnderline: true,
                            margin: "none",
                            style: {
                              borderRadius: "4px",
                              backgroundColor: "#eceff0"
                            },
                            classes: { input: classes.inputRoot }
                          }}
                          onChange={({ target: { value }}) => {
                            if (!isNaN(value) && numberReg.test(value)) {
                              formik.setFieldValue("scanLimit", value)
                              calculateCombination({
                                ...formik.values,
                                scanLimit: value
                              })
                            }
                          }}
                          value={formik.values.scanLimit}
                        />
                        {!!formik.errors?.scanLimit && (
                          <Typography variant='caption' className={classes.warningText}>{formik.errors?.scanLimit}</Typography>
                        )}
                      </Box>
                    </Box>
                    <Box className={classes.itemFormContainer} style={{ marginBottom: "20px" }}>
                      <Box display="flex" mt={0.75}>
                        <Typography variant='body1' color='primary'>{getLang(lang, "placeholder.INCENTIVE_POINTS")}</Typography>
                      </Box>
                      <Input
                        hiddenLabel
                        variant="filled"
                        size="small"
                        placeholder={getLang(lang, "placeholder.INCENTIVE_POINTS")}
                        margin="none"
                        InputProps={{
                          disableUnderline: true,
                          margin: "none",
                          style: {
                            borderRadius: "4px",
                            backgroundColor: "#eceff0"
                          },
                          classes: { input: classes.inputRoot }
                        }}
                        onChange={({ target: { value }}) => {
                          if (!isNaN(value) && numberReg.test(value)) {
                            formik.setFieldValue("incentivePoint", value)
                            calculateCombination({
                              ...formik.values,
                              incentivePoint: value
                            })
                          }
                        }}
                        value={formik.values.incentivePoint}
                      />
                    </Box>
                    <Box className={classes.checkboxContainer}>
                      <Box py={1}>
                        <Typography variant="body1">{getLang(lang, "label.AUTO_GENERATE_PRODUCT_NAME")}:</Typography>
                      </Box>
                      <Box display="flex">
                        <FormGroup>
                          <FormControlLabel
                            control={<Checkbox checked={formik.values.isCategory} />}
                            label={getLang(lang, "label.CATEGORY")}
                            onChange={(event) => formik.setFieldValue("isCategory", event.target.checked)}
                            color="primary"
                          />
                          <FormControlLabel
                            control={<Checkbox checked={formik.values.isModel} />}
                            label={getLang(lang, "label.MODEL")}
                            onChange={(event) => formik.setFieldValue("isModel", event.target.checked)}
                            color="primary"
                          />
                        </FormGroup>
                        <FormGroup>
                          <FormControlLabel
                            control={<Checkbox checked={formik.values.isBrand} />}
                            label={getLang(lang, "label.BRAND")}
                            onChange={(event) => formik.setFieldValue("isBrand", event.target.checked)}
                            color="primary"
                          />
                          <FormControlLabel
                            control={<Checkbox checked={formik.values.isAttribute} />}
                            label={getLang(lang, "label.ATTRIBUTES")}
                            onChange={(event) => formik.setFieldValue("isAttribute", event.target.checked)}
                            color="primary"
                          />
                        </FormGroup>
                      </Box>
                    </Box>
                  </Box>
                  <Box style={{ display: "flex", flexDirection: "column", height: 'fit-content' }}>
                    <Typography variant='body1' color='primary' style={{ marginBottom: "10px" }}>
                      {getLang(lang, "label.ATTRIBUTES")}
                    </Typography>
                    <BulkCreateAttributeSelect 
                      disabled={formik.isSubmitting}
                      handleChange={(value) => {
                        formik.setFieldValue("attributes", value)
                        calculateCombination({
                          ...formik.values,
                          attributes: value
                        })
                      }}
                      value={formik.values.attributes}
                      styles={{
                        padding: "6px 10px"
                      }}
                      searchPlaceholder={getLang(lang, "placeholder.SEARCH")}
                      searchable={true}
                      PaperProps={{
                        classes: { root: classes.paperRoot }
                      }}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left"
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left"
                      }}
                    />
                  </Box>
                </Box>
                <Divider />
                <Box style={{ display: "flex", justifyContent: "space-between", padding: "12px 32px" }}>
                  <Box style={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      variant='body2'
                      style={{ color: '#98A2B3' }}
                    >
                      <span style={{ color: "#6AAF68", fontWeight: "bold" }}>{getLang(lang, "paragraph.TOTAL_PRODUCTS_COUNT", { count: totalRecords })}</span> {getLang(lang, "paragraph.WILL_BE_GENERATED")}
                    </Typography>
                  </Box>
                  <Box>
                    <Button
                      variant="contained"
                      disableElevation
                      color='primary'
                      type='submit'
                      disabled={formik.isSubmitting}
                    >
                      {formik.isSubmitting ?  getLang(lang, "label.LOADING") : getLang(lang, "label.ADD")}
                    </Button>
                  </Box>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </Box>
    </>
  )
}
