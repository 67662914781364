import { Avatar, Box, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Typography } from "@material-ui/core";
import { generatePaginationOptions } from "lib/helper";
import { cardChartStyle, flexGapStyle, fontTextStyle, iconStyle, tableStyle } from "modules/reporting/styles";
import React from "react";
import downloadjs from 'downloadjs';
import html2canvas from 'html2canvas';
import { useEffect } from "react";
import { useState } from "react";
import { setChartColors } from "modules/reporting/utils/chartConfig";
import Chart from 'react-apexcharts';
import { Link } from "react-router-dom";
import { DownloadIcon, UnknownUserIcon } from "modules/reporting/assets/svg";
import { drilldownPageSelection, numberFormatteer, responseHandler } from "modules/reporting/utils/helper";
import { TablePaginationActions } from "components/tablePagination";
import { ContentCard } from "../../layout";
import { BoxLoading } from "../../skeleton/loading";
import { CampaignConversionsNoData } from "../../skeleton/no-data";
import { CampaignConversionsNoResult } from "../../skeleton/no-result";

export default function StockoutEmployeePanelComponent({
    status,
    data,
    sort,
    pagination,
    currentFilter,
    translate,
    pageType,
}){

    const fontClasses = fontTextStyle();
    const flexGapClasses = flexGapStyle();
    const iconClasses = iconStyle();
    const tableClasses = tableStyle();
    const chartClasses = cardChartStyle();

    const [paginationOptions, setPaginationOptions] = useState([]);
    useEffect(() => {
        setPaginationOptions(generatePaginationOptions(data.dealerStockoutByEmployeeData ? data.dealerStockoutByEmployeeData.total_record : 0, [10, 25, 50, 100]))
    }, [data]);

    const columns = [
        {
            title: translate("label.NUM"),
            field: null,
            render: (row) => {
                return (
                    <>{row.title}</>
                )
            },
            align: "left",
            size: "medium"
        },
        {
            title: translate("label.FROM"),
            field: null,
            render: (row) => {
                return (
                    <>{row.title}</>
                )
            },
            align: "left",
            size: "medium"
        },
        {
            title: translate("label.STOCKOUT_TO"),
            field: null,
            render: (row) => {
                return (
                    <>{row.title}</>
                )
            },
            size: "small",
            align: "left"
        },
        {
            title: translate("label.TOTAL_STOCKOUT"),
            field: "stockout_count",
            render: (row) => {
                return (
                    <>{row.title}</>
                )
            },
            size: "small",
            align: "left"
        }
    ]

    const handleDownloadCard = () => {
        let element = document.getElementById("stockout-by-employee");

        html2canvas(element).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            downloadjs(imgData, 'stockout-by-employee.png');
        });

    }


    // apexchart configuration & function
    const chartID = "stockout-employee-chart";

    const createSortHandler = (property) => (event) => {
        sort.handleRequestSort(event, property);
    };

    // render table data
    const displayData = (ref) => {
        let rows = [];
        if(Object.keys(ref).length === 0 || !ref.isSuccess){
            return rows;
        }

        const colorSet = setChartColors(ref.chart.datasets.length, true);

        const options = {
            chart: {
                id: chartID
            },
            tooltip:{
                followCursor: true,
                y: {
                    formatter: function (val) {
                        return parseInt(val)
                    }
                }
            },
            xaxis: {
                categories: ref.chart.labels,
                labels: {
                    rotateAlways: true,
                    rotate: -45,
                    hideOverlappingLabels: true,
                },
                tickPlacement: 'between',
            },
            legend: {
                position: 'bottom',
                showForSingleSeries: true,
                onItemClick: {
                    toggleDataSeries: false
                }
            },
            grid: {
                padding: {
                    left: 12,
                    right: 12,
                }
            },
            stroke: {
                width: 2,
            },
            colors: colorSet
    
        }

        let no_iteration = pagination.rowsPerPage * pagination.page + 1;

        return (
            <Box
                className={chartClasses.card}    
            >
                <Chart
                    options={options}
                    series={ref.chart.datasets}
                    type="line"
                    height="430px"
                ></Chart>
                <TableContainer component={ Paper }>
                    <Box style={{
                        overflowX: "auto",
                        width: "100%"
                    }}>
                        <Table>
                            <TableHead>
                                <TableRow
                                    hover
                                >
                                    {columns.map((column, index) => (
                                        <TableCell className={[tableClasses.table__cell, fontClasses.text__header_table_cell]} key={column.title} size={column.size}>
                                            {
                                                column.field ? 
                                                (
                                                    <TableSortLabel
                                                        active={sort.valueToOrderBy === column.field ? true : false}
                                                        direction={sort.valueToOrderBy === column.field ? sort.orderDirection : 'desc'}
                                                        onClick={!status.isFetchingDealerStockoutEmployee && column.field ? createSortHandler(column.field) : null}
                                                    >
                                                        {column.render(column)}
                                                    </TableSortLabel>
                                                ) : 
                                                column.render(column)
                                            }
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {ref.rows.map((item, index) => {

                                    return (
                                        <TableRow
                                            hideOverlappingLabels
                                            key={item.name}
                                        >
                                            <TableCell className={tableClasses.table__cell} style={{width: "20px"}}>
                                                <Typography className={fontClasses.text__table_cell}>{no_iteration++}</Typography>
                                            </TableCell>
                                            <TableCell className={tableClasses.table__cell} style={{width: "200px", minWidth: "200px"}}>
                                                <Typography className={fontClasses.text__table_cell}>
                                                    { item.name ?? '-' }
                                                </Typography>
                                            </TableCell>
                                            <TableCell className={tableClasses.table__cell} style={{width:"400px", minWidth:"400px"}}>
                                                {
                                                    item.dealer_uuid ? (
                                                        <Box className={tableClasses.cell__product}>
                                                            {
                                                                item.image ?
                                                                <Avatar variant="square" alt={item.dealer_name} src={item.dealer_image} className={iconClasses.svg__icon}/>
                                                                :
                                                                <Avatar variant="square" alt={item.dealer_name} src={UnknownUserIcon} className={iconClasses.svg__icon}/>
                                                            }
                                                        
                                                            <Link
                                                                target="_blank" rel="noopener noreferrer" 
                                                                className={fontClasses.text__hyperlink}
                                                                to={() => {
                                                                    let filterProperties = {};

                                                                    if (item.dealer_uuid) {
                                                                        filterProperties.dealershipUuid = item.dealer_uuid;
                                                                    }
                                                                    let url = drilldownPageSelection(filterProperties, pageType, true);
                                                                    return url;
                                                                }}
                                                            
                                                            >
                                                                {translate(item.name)}
                                                            </Link>
                                                        </Box>
                                                    ) : 
                                                    (
                                                        <Typography className={fontClasses.text__unknown}>-</Typography>
                                                    )
                                                }

                                            </TableCell>
                                            <TableCell className={tableClasses.table__cell} align="right" style={{width: "120px", minWidth: "120px"}}>
                                                <Typography className={fontClasses.text__table_cell}>
                                                    {numberFormatteer(item.total_stockout)}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </Box>
                    <TablePagination
                        ActionsComponent={TablePaginationActions}
                        component="div"

                        // rowsPerPageOptions={pagination.rowsPerPageOptions}
                        rowsPerPageOptions={paginationOptions}
                        rowsPerPage={pagination.rowsPerPage}
                        page={pagination.page}
                        count={ref.total_record ?? 0}
                        onChangePage={pagination.handleChangePage}
                        onChangeRowsPerPage={pagination.handleChangeRowPerPage}
                    />
                </TableContainer>
            </Box>
        )
    }

    return (
        <ContentCard>
            <Box
                display="flex"
                flexDirection="column"
                id="stockout-by-employee"
            >
                {/* title and download icon/toggle icon */}
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    margin="24px 0"
                >
                    <Typography className={fontClasses.text__card_title}>
                        {translate("label.STOCKOUT_BY_EMPLOYEE")}
                    </Typography>
                    <Box display="flex" alignContent="center" className={flexGapClasses.global__flexGap20}>
                        <IconButton title="Download Charts" aria-label="download" onClick={handleDownloadCard} disableRipple>
                            <DownloadIcon />
                        </IconButton>
                    </Box>
                </Box>

                {
                    responseHandler(
                        data.dealerStockoutByEmployeeData,
                        status.isFetchingDealerStockoutEmployee,
                        displayData(data.dealerStockoutByEmployeeData),
                        <BoxLoading />,
                        <CampaignConversionsNoData 
                            title={translate("label.DEALERSHIP_NOT_FOUND")}
                            value={translate("paragraph.NO_DATA_ON_SELECTED_FILTER")}
                            columnTitle={[translate("label.NAME"), translate("label.DEALER"), translate("label.TOTAL_STOCKOUT")]}
                            translate={translate}
                        />,
                        <CampaignConversionsNoResult 
                            title={translate("label.DEALERSHIP_NOT_FOUND")}
                            value={translate("paragraph.NO_DATA_ON_SELECTED_FILTER")}
                            columnTitle={[translate("label.NAME"), translate("label.DEALER"), translate("label.TOTAL_STOCKOUT")]}
                            translate={translate}
                        />,
                        <CampaignConversionsNoResult 
                            title={translate("label.LOADING_FAILED")}
                            value={translate("paragraph.LOADING_FAILED_DESCRIPTION")}
                            columnTitle={[translate("label.NAME"), translate("label.DEALER"), translate("label.TOTAL_STOCKOUT")]}
                            translate={translate}
                        />
                    )
                }

            </Box>
        </ContentCard>
    )
}