import React from "react";
import {useSelector} from "react-redux";
import {useRef, useState} from "react";
import LanguageSelectComponent from "./languageSelect.component";
import LanguageSelectPanelComponent from "./langaugeSelectPanel.component";

export default function LanguageSelectContainer({
  disabled,
  handleAddLang,
  handleDeleteLang,
  selectedValue,
  panelStyle,
  anchorOrigin,
  transformOrigin,
  PaperProps,
}) {
  const lang = useSelector(state => state.constant.languages)
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const anchorRef = useRef();

  const languages = [
    'EN', 'VI', 'TH', 'ZH', 'ZH-TW', 'MS', 'ID', 'TL'
  ];

  const handleClick = (event) => {
    setAnchorEl(open ? null : event.currentTarget);
  }

  const handleClose = () => {
    setAnchorEl(null);
  }

  const handleSelectLanguage  = (code) => {
    handleAddLang(code);
  }

  const handleDeleteLanguage = (code) => {
    handleDeleteLang(code);
  }

  return (
    <>
      <LanguageSelectComponent
        disabled={disabled}
        handleClick={handleClick}
        anchorRef={anchorRef}
        isEditing={open}
        lang={lang}
      />
      <LanguageSelectPanelComponent
        anchorEl={anchorEl}
        open={open}
        handleClose={handleClose}
        style={panelStyle}
        selectedLanguages={selectedValue}
        availableLanguages={languages.filter(language => !selectedValue.includes(language))}
        handleSelectLanguage={handleSelectLanguage}
        handleDeleteLanguage={handleDeleteLanguage}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        PaperProps={PaperProps}
        lang={lang}
      />
    </>
  )
}
