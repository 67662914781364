import React from "react";
import { Box, makeStyles } from "@material-ui/core";
import AdtInfoTitleListPanel from "modules/serial-number/components/panel/adtInfoTitleListPanel";
import AdtInfoDataPanel from "modules/serial-number/components/panel/adtInfoDataPanel";

const useStyle = makeStyles((theme) => ({
  container: {
    display: "grid",
    gridTemplateColumns: "2.5fr 7.5fr",
    columnGap: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "3fr 6fr",
    },
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1),
    },
  },
}));

export default function AdtInfoListingComponent() {
  const classes = useStyle();

  return (
    <Box className={classes.container}>
      <Box height="fit-content">
        <AdtInfoTitleListPanel />
      </Box>
      <Box width="100%" overflow="hidden">
        <AdtInfoDataPanel />
      </Box>
    </Box>
  );
}
