import React, { useState } from "react";
import AddBonanzaFormComponent from './addBonanzaPointForm.component'
import { setType } from "modules/loyalty-program/redux";
import { useDispatch, useSelector } from "react-redux";
import {
  addBonanzaPoint,
  editBonanzaPoint,
} from "modules/loyalty-program/redux";
import { unwrapResult } from "@reduxjs/toolkit";
import ConfirmationDialogComponent from "components/dialog/confirmation";
import { hideBackdrop, showBackdrop } from "modules/backdrop";
import { loyaltyType } from 'modules/loyalty-program/constants'
import { BONANZA_ADD, BONANZA_EDIT } from "lib/constants/accessRights";
import { getLang } from "app/feature/constants";

export default function AddBonanzaPointFormContainer({ headerType, handleClose }) {
  const dispatch = useDispatch();
  const hasBonanzaAddAccessRight = useSelector(state => state.profile.accessRights.includes(BONANZA_ADD));
  const hasBonanzaEditAccessRight = useSelector(state => state.profile.accessRights.includes(BONANZA_EDIT));
  const [confirmationDialogModal, setConfirmationDialogModal] = useState(false);
  const bonanzaPointType = useSelector(
    (state) => state.loyaltyProgram.bonanzaPointType
  );
  const bonanzaPoint = useSelector(
    (state) => state.loyaltyProgram.bonanzaPoint
  );
  const lang = useSelector(state => state.constant.languages)
  const language = useSelector(state => state.profile.language)

  const changeToUpdate = (e) => {
    if (hasBonanzaEditAccessRight) {
      e.preventDefault();
      const updateInfo = {
        index: loyaltyType.bonanza,
        type: "edit",
      };
      dispatch(setType(updateInfo));
    }
  };

  const changeBackView = () => {
    const updateInfo = {
      index: loyaltyType.bonanza,
      type: "view",
    };
    dispatch(setType(updateInfo));
  };


  const handleSubmit = async (values) => {
    if (bonanzaPointType === "create") {
      if (hasBonanzaAddAccessRight) {
        const modulesId = [];
        for (const modules of values.modules) {
          modulesId.push(modules.id);
        }
        const info = {
          modules: modulesId,
          point: values.point,
          startAt: values.startAt,
          endAt: values.endAt,
          status: "active",
        };
        await dispatch(addBonanzaPoint(info))
          .then(unwrapResult)
          .then((r) => {
            dispatch(hideBackdrop());
            setConfirmationDialogModal(true);
          })
          .catch((error) => {
            dispatch(hideBackdrop());
          });
      }
    } else {
      if (hasBonanzaEditAccessRight) {
        dispatch(showBackdrop());
        const modulesId = [];
        for (const modules of values.modules) {
          modulesId.push(modules.id);
        }
        const info = {
          modules: modulesId,
          point: values.point,
          id: values.id,
          startAt: values.startAt,
          endAt: values.endAt,
          status: "active",
        };
        await dispatch(editBonanzaPoint(info))
          .then(unwrapResult)
          .then((r) => {
            dispatch(hideBackdrop());
            changeBackView();
          })
          .catch((error) => {
            dispatch(hideBackdrop());
          });
      }
    }
  };
  return (
    <>
      <AddBonanzaFormComponent
        hasBonanzaAddAccessRight={hasBonanzaAddAccessRight}
        hasBonanzaEditAccessRight={hasBonanzaEditAccessRight}
        headerType={headerType}
        bonanzaPoint={bonanzaPoint}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        bonanzaPointType={bonanzaPointType}
        changeToUpdate={changeToUpdate}
        bonanza={true}
        lang={lang}
        language={language}
        changeBackView={changeBackView}
      />
      <ConfirmationDialogComponent
        isOpen={confirmationDialogModal}
        handleProceed={handleClose}
        type="success"
        title={getLang(lang,"label.BONANZA_ADDED_SUCCESSFULLY")}
        rejectOption={false}
        proceedLabel={getLang(lang,"label.ALRIGHT")}
      />
    </>
  )
}
