import React, { useState } from "react";
import { useSelector } from "react-redux";
import WebcamPanelComponent from "./webcamPanel.component";

export default function WebcamPanelContainer({ handleResult }) {
  const [img, setImg] = useState();
  const [captured, setCaptured] = useState(false);
  const [granted, setGranted] = useState(false);

  const lang = useSelector(state => state.constant.languages)

  const handleLoading = isGranted => {
    if (isGranted) {
      setGranted(true);
    }
  };

  const handleReset = () => {
    setGranted(false);
    setCaptured(false);
  };

  const handleConfirm = () => {
    handleResult(img);
  };

  const webcamRef = React.useRef(null);

  const handleCapture = React.useCallback(() => {
    const imageBase64 = webcamRef.current.getScreenshot();
    setImg(imageBase64);
    setCaptured(true);
  }, [webcamRef]);

  return (
    <WebcamPanelComponent
      img={img}
      handleCapture={handleCapture}
      granted={granted}
      captured={captured}
      webcamRef={webcamRef}
      handleReset={handleReset}
      handleConfirm={handleConfirm}
      handleLoading={handleLoading}
      lang={lang}
    />
  );
}
