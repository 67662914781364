import getTimezone from "./getTimezone.api";
import getAllCurrencyConfig from "./getAllCurrencyConfig.api";
import checkMaintenanceStatus from "./checkMaintenanceStatus.api";

const publicApi = {
  getTimezone,
  getAllCurrencyConfig,
  checkMaintenanceStatus,
};

export default publicApi;
