import React from "react";
import {
  //makeStyles,
  Box,
  CircularProgress,
  Typography
} from "@material-ui/core";
import { BackButton } from "components/button";
import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";
import DealerIncentiveProgramForm from 'modules/stockflow/components/form/dealerIncentiveProgramForm';
import { getLang } from "app/feature/constants";

// const useStyle = makeStyles(theme => ({

// }));

export default function EditIncentiveProgramComponent({
  productDropdown,
  maxTier,
  handleSubmit,
  programDetails,
  isFetching,
  lang,
  isSubmitting
}) {
  // const classes = useStyle();

  return (
    <>
      <Box>
        <Box mb={4} display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="flex-end" fontWeight="bold">
            <BackButton />
            <Typography variant='h6' color='primary'>
              {getLang(lang, 'label.DEALER_INCENTIVE_PROGRAM')}
            </Typography>
            <Box mx={0.5}>
              <ChevronRightRoundedIcon color="disabled" />
            </Box>
            <Box>
              <Typography variant="h6" color="primary">
                {getLang(lang, 'label.EDIT_DEALER_INCENTIVE_PROGRAM')}
              </Typography>
            </Box>
          </Box>
        </Box>
        {!isFetching && (
          <DealerIncentiveProgramForm
            type="edit"
            productDropdown={productDropdown}
            maxTier={maxTier}
            handleSubmit={handleSubmit}
            programDetails={programDetails}
            lang={lang}
            isSubmitting={isSubmitting}
          />
        )}
      </Box>
      {isFetching && (
        <>
          <Box
            gridArea="main" mt={6}
            style={{
              textAlign:"center"
            }}
          >
            <CircularProgress color="primary" />
          </Box>
        </>
      )}
    </>
  )
}