import axios from "axios";
import PropTypes from "prop-types";

const post = ({ plan }) => {
  return new Promise((resolve, reject) => {
    const body = { plan };
    axios
      .post("/payment/api/v1/subscription/swap", body, {
        timeout: 10000
      })
      .then(response => resolve(response.data))
      .catch(error => {
        return reject(error.response.data);
      });
  });
};

post.propTypes = {
  plan: PropTypes.string.isRequired
};

const swapSubscription = {
  post
};

export default swapSubscription;
