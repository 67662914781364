import { Avatar, Box, Paper, TableCell, TableRow, Typography } from "@material-ui/core";
import { UnknownUserIcon } from "modules/reporting/assets/svg";
import { fontTextStyle, iconStyle, tableStyle } from "modules/reporting/styles";
import { drilldownPageSelection } from "modules/reporting/utils/helper";
import React from "react";
import { Link } from "react-router-dom";
import { ContentCard } from "../../layout";
import { DynamicTable } from "../../table";

export default function CampaignPrizeComponent({
    status,
    data, 
    pagination, 
    sort, 
    currentFilter, 
    pageType, 
    translate
}) {
    const tableClass = tableStyle();
    const fontClass = fontTextStyle();
    const iconClass = iconStyle();

    const columns = [
        {
            title: translate("label.NUM"),
            field: null,
            render : (row) => {
                return (
                    <span>{row.title}</span>
                )
            },
            align: "left"
        },
        {
            title: translate("label.PRIZES"),
            field: "name", // total_scan
            render : (row) => {
                return (
                    <>{row.title}</>
                )
            },
            align: "left"
        },
        {
            title: translate("label.TOTAL_QUANTITY"),
            field: "quantity",
            render : (row) => {
                return (
                    <>{row.title}</>
                )
            },

        }
    ];

    const renderData = (ref) => {
        let rows = [];
        if(Object.keys(ref).length === 0 || !ref.isSuccess){
            return rows;
        }

        let imageProps = JSON.stringify(ref);
        imageProps = JSON.parse(imageProps);
        let no_iteration = pagination.rowsPerPage * pagination.page + 1;

        imageProps.rows.forEach((item, index) => {
            let row = item ? (
                <TableRow key={index}>
                    <TableCell align="left" className={tableClass.table__cell} style={{width: "20px"}}>
                        {no_iteration++}
                    </TableCell>
                    <TableCell
                        align={"left"}
                        className={tableClass.table__cell}
                        size="medium"
                        style={{width:"700px"}}
                    >
                        <Box className={tableClass.cell__product}>
                            {
                                item.image ? (
                                    <Avatar variant="square" alt={item.name} src={item.image} className={iconClass.svg__icon} />
                                ) : (
                                    <Avatar variant="square" alt={item.name} src={UnknownUserIcon} className={iconClass.svg__icon} />
                                )
                            }
                            <Link
                                target="_blank" rel="noopener noreferrer"
                                className={fontClass.text__hyperlink}
                                to={() => {
                                    if(!item.uuid)
                                        return;
                                    let filterProperties = {};

                                    if(currentFilter.campaignType){
                                        filterProperties.campaignType = currentFilter.campaignType;
                                    }

                                    if(currentFilter.campaignUuid){
                                        filterProperties.campaignUuid = currentFilter.campaignUuid;
                                    }

                                    filterProperties.prizeUuid = item.uuid;

                                    let url = drilldownPageSelection(filterProperties, pageType, translate);
                                    return url;
                                }}
                            >
                                <span>{item.name}</span>
                            </Link>
                        </Box>

                    </TableCell>
                    <TableCell align={item.align || "right"} className={tableClass.table__cell} style={{width:"190px"}}>
                        <Typography className={fontClass.text__table_cell}>{item.quantity}</Typography>
                    </TableCell>
                </TableRow>
            ) : null;

            item.render = row;
        })

        return imageProps;
    }

    return (
        <ContentCard>
            <Box margin="24px" display="flex" justifyContent="space-between" alignContent="center">
                <Typography className={fontClass.text__card_title}>
                    {translate("label.PRIZES")}
                </Typography>
            </Box>
            <DynamicTable 
                component={ Paper }
                sort={sort}
                pagination={pagination}
                columns={columns}
                data={renderData(data.campaignPrizeDatalist)}
                status={status.isFetchingCampaignPrizeDatalist}
                translate={translate}
            />
        </ContentCard>
    )
}