/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import {
  BRANCH_GROUP_ADD,
  BRANCH_GROUP_EDIT,
} from "lib/constants/accessRights";
import { generatePaginationOptions, updateUrlQueryParam } from "lib/helper";
import { useRef } from "react";
import { selectLoading } from "modules/notification";
import {
  deleteBranchGroup,
  fetchGroupList,
  resetBranchGroupListing,
} from "modules/group/redux";
import GroupListPanelComponent from "./groupListPanel.component";
import ConfirmationDialog from "../../dialog/confirmationDialog";
import { getLang } from "app/feature/constants";

function GroupListPanelContainer({ openCreateDialog }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const hasGroupAddAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(BRANCH_GROUP_ADD)
  );
  const hasGroupEditAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(BRANCH_GROUP_EDIT)
  );
  const lang = useSelector((state) => state.constant.languages);

  const isFetching = useSelector((state) =>
    selectLoading(state, fetchGroupList.typePrefix)
  );
  const totalFiltered = useSelector(
    (state) => state.branchGroup.groupList.totalFiltered
  );
  const totalRecords = useSelector(
    (state) => state.branchGroup.groupList.totalRecords
  );

  const isDeleting = useSelector((state) =>
    selectLoading(state, deleteBranchGroup.typePrefix)
  );

  const groupList = useSelector((state) => state.branchGroup.groupList.list);
  const isError = useSelector((state) => state.branchGroup.groupList.isError);

  const isSearchResult = totalFiltered < totalRecords;
  const [paginationOptions, setPaginationOptions] = useState([]);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isFilterUpdated, setIsFilterUpdated] = useState(false);
  const [tableFilter, setTableFilter] = useState({
    page: 0,
    rowsPerPage: 25,
    search: "",
    nodes: [],
    branch: [],
  });

  const [selectedDeleteGroup, setSelectedDeleteGroup] = useState(null);

  const query = new URLSearchParams(window.location.search);
  const tableFilterInitialRef = useRef(true);

  useEffect(() => {
    const isFilter = query.has("is_filter");
    let filter = sessionStorage.getItem("branch_group_filter");

    let temp = { ...tableFilter };
    if (isFilter && filter) {
      temp = JSON.parse(filter);
      setIsFilterUpdated(true);
      setIsFilterOpen(true);
    }

    if (isFilter) {
      updateUrlQueryParam(null, ["is_filter"]);
    }
    sessionStorage.removeItem("branch_group_filter");

    setTableFilter(temp);

    return () => {
      dispatch(resetBranchGroupListing());
    };
  }, []);

  useEffect(() => {
    if (!tableFilterInitialRef.current) {
      getData();
    }
    tableFilterInitialRef.current = false;
  }, [tableFilter]);

  useEffect(() => {
    setPaginationOptions(generatePaginationOptions(totalRecords));
  }, [totalRecords]);

  const getData = () => {
    dispatch(
      fetchGroupList({
        length: tableFilter.rowsPerPage,
        start: tableFilter.rowsPerPage * tableFilter.page,
        search: tableFilter.search,
        nodes: tableFilter.nodes,
        order: "asc",
        order_by: "name",
      })
    );
  };

  const handleSearch = (filter) => {
    let newData = {
      ...tableFilter,
      page: 0,
      search: filter.search,
      nodes:
        filter.branch.length > 0 ? filter.branch.map((br) => br.node_id) : [],
      branch: filter.branch,
    };
    setIsFilterUpdated(true);
    setTableFilter(newData);
  };

  const clearSearch = () => {
    let newData = {
      ...tableFilter,
      page: 0,
      search: "",
      nodes: [],
      branch: [],
    };
    setIsFilterUpdated(false);
    setTableFilter(newData);
  };

  const handleChangePage = (event, newPage) => {
    let newData = {
      ...tableFilter,
      page: newPage,
    };
    setIsFilterUpdated(true);
    setTableFilter(newData);
  };

  const resetFilter = () => {
    let newData = {
      ...tableFilter,
      page: 0,
      nodes: [],
      branch: [],
    };
    setIsFilterUpdated(true);
    setTableFilter(newData);
  };

  const handleChangeRowsPerPage = (event) => {
    let newData = {
      ...tableFilter,
      page: 0,
      rowsPerPage: event.target.value,
    };
    setIsFilterUpdated(true);
    setTableFilter(newData);
  };

  const handleViewDetail = (url) => {
    if (isFilterUpdated) {
      updateUrlQueryParam({ is_filter: true });
      sessionStorage.setItem(
        "branch_group_filter",
        JSON.stringify(tableFilter)
      );
    }
    history.push(url);
  };

  const handleDeleteGroup = () => {
    dispatch(deleteBranchGroup(selectedDeleteGroup.uuid)).then(() => {
      setSelectedDeleteGroup(null);
      let newData = {
        ...tableFilter,
        page: 0,
      };
      setTableFilter(newData);
    });
  };

  return (
    <>
      <GroupListPanelComponent
        hasGroupAddAccessRight={hasGroupAddAccessRight}
        hasGroupEditAccessRight={hasGroupEditAccessRight}
        groupList={groupList}
        isFetching={isFetching}
        isError={isError}
        totalFiltered={totalFiltered}
        tableFilter={tableFilter}
        handlePageChange={handleChangePage}
        handleRowPerPageChange={handleChangeRowsPerPage}
        handleSearch={handleSearch}
        clearSearch={clearSearch}
        resetFilter={resetFilter}
        handleReload={getData}
        paginationOptions={paginationOptions}
        isSearchResult={isSearchResult}
        isFilterOpen={isFilterOpen}
        setIsFilterOpen={setIsFilterOpen}
        lang={lang}
        openCreateDialog={openCreateDialog}
        handleViewDetail={handleViewDetail}
        handleDeleteGroup={(group) => {
          setSelectedDeleteGroup(group);
        }}
      />
      <ConfirmationDialog
        isOpen={!!selectedDeleteGroup}
        handleClose={() => setSelectedDeleteGroup(null)}
        handleProceed={() => handleDeleteGroup()}
        title={getLang(lang, "label.DELETE_GROUP")}
        description={getLang(lang, "paragraph.DELETE_GROUP_QUESTION")}
        type="danger"
        isLoading={isDeleting}
      />
    </>
  );
}

export default GroupListPanelContainer;
