import { createAsyncThunk } from "@reduxjs/toolkit";
import loyaltyProgramApi from "app/api/loyaltyProgram"
import { timezoneOffSetFormatTime } from "lib/helper";

const LOYALTYPROGRAM = "loyaltyProgram";

export const fetchLoyaltyPointListing = createAsyncThunk(
    `${LOYALTYPROGRAM}/fetchLoyaltyPointListing`,
    async (payload, thunkApi) => {
        return loyaltyProgramApi.getAllLoyaltyPoint(payload)
        .then(response => response)
        .catch(error => thunkApi.rejectWithValue(error))
    }
);

export const addLoyaltyPoint = createAsyncThunk(
    `${LOYALTYPROGRAM}/addLoyaltyPoint`,
    async (payload, { rejectWithValue }) => {
        return loyaltyProgramApi.addLoyaltyPoint({
            modules: payload.modules,
            point: payload.point,
            status: payload.status,
        })
            .then(response => response.data)
            .catch(error => rejectWithValue(error))
    }
)

export const getLoyaltyPoint = createAsyncThunk(
    `${LOYALTYPROGRAM}/getLoyaltyPoint`,
    async (payload, { rejectWithValue }) => {
        return loyaltyProgramApi.getLoyaltyPoint({
            id: payload.id
        }).catch(error => rejectWithValue(error))
    },
)

export const editLoyaltyPoint = createAsyncThunk(
    `${LOYALTYPROGRAM}/updateLoyaltyPoint`,
    async (payload, { rejectWithValue }) => {
        return loyaltyProgramApi.updateLoyaltyPoint({
            id: payload.id,
            point: payload.point,
            modules: payload.modules,
            status: payload.status
        }).catch(error => rejectWithValue(error))
    },
)

export const changeStatusLoyaltyPoint = createAsyncThunk(
    `${LOYALTYPROGRAM}/changeStatusLoyaltyPoint`,
    async (payload, { rejectWithValue }) => {
        return loyaltyProgramApi.updateLoyaltyPointStatus({
            id: payload.id,
            status: payload.status
        }).catch(error => rejectWithValue(error))
    },
)

export const getModuleDropdown = createAsyncThunk(
    `${LOYALTYPROGRAM}/getModuleDropdown`,
    async (payload, thunkApi) => {
        return loyaltyProgramApi.getModuleDropdown(payload)
            .then(response => response)
            .catch((error) => thunkApi.rejectWithValue(error.response.data));
    }
);

export const fetchBonanzaPointListing = createAsyncThunk(
    `${LOYALTYPROGRAM}/fetchBonanzaPointListing`,
    async (payload, thunkApi) => {
        return loyaltyProgramApi.getAllBonanzaPoint(payload)
        .then(response => response)
        .catch(error => thunkApi.rejectWithValue(error));
    }
);

export const addBonanzaPoint = createAsyncThunk(
    `${LOYALTYPROGRAM}/addBonanzaPoint`,
    async (payload, { rejectWithValue, getState }) => {
        const timezone = getState().profile.timezone;
        return loyaltyProgramApi.addBonanzaPoint({
            point: payload.point,
            modules: payload.modules,
            status: payload.status,
            parentId: payload.parentId,
            startAt: timezoneOffSetFormatTime(payload.startAt, timezone),
            endAt:  timezoneOffSetFormatTime(payload.endAt, timezone),
        })
            .then(response => response.data)
            .catch(error => rejectWithValue(error))
    }
)

export const getBonanzaPoint = createAsyncThunk(
    `${LOYALTYPROGRAM}/getBonanzaPoint`,
    async (payload, { rejectWithValue }) => {
        return loyaltyProgramApi.getBonanzaPoint({
            id: payload.id
        }).catch(error => rejectWithValue(error))
    },
)

export const editBonanzaPoint = createAsyncThunk(
    `${LOYALTYPROGRAM}/updateBonanzaPoint`,
    async (payload, { rejectWithValue, getState }) => {
        const timezone = getState().profile.timezone;
        return loyaltyProgramApi.updateBonanzaPoint({
            id: payload.id,
            point: payload.point,
            modules: payload.modules,
            status: payload.status,
            parentId: payload.parentId,
            startAt: timezoneOffSetFormatTime(payload.startAt, timezone),
            endAt:  timezoneOffSetFormatTime(payload.endAt, timezone),
        }).catch(error => rejectWithValue(error))
    },
)

export const changeStatusBonanzaPoint = createAsyncThunk(
    `${LOYALTYPROGRAM}/changeStatusBonanzaPoint`,
    async (payload, { rejectWithValue }) => {
        return loyaltyProgramApi.updateBonanzaPointStatus({
            id: payload.id,
            status: payload.status,
        }).catch(error => rejectWithValue(error))
    },
)