import React, { useEffect, useRef, useState } from "react";
import ImageEditorPanelComponent from "./imageEditorPanel.component";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

function ImageEditorPanelContainer({
  img,
  handleCancel,
  handleResult,
  isCircleRadius,
  aspectRatio,
  width,
  height,
}) {
  const refEditor = useRef();
  const imgRef = useRef(null); // Rep
  const previewCanvasRef = useRef(null)

  const [zoom, setZoom] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [flipped, setFlipped] = useState(false);
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();

  const lang = useSelector(state => state.constant.languages)

  const useDebounceEffect = (fn,
    waitTime,
    deps,) => {
    useEffect(() => {
      const t = setTimeout(() => {
        fn.apply(undefined, deps)
      }, waitTime)
      return () => {
        clearTimeout(t)
      }
    }, [deps, fn, waitTime])
  }
  const handleZoom = (event, newValue) => {
    setZoom(newValue);
  };
  const handleCrop = (newValue) => {
    setCrop(newValue);
  };
  const handleCompletedCrop = (newValue) => {
    setCompletedCrop(newValue);
  };
  const handleRotate = (event) => {
    let degree = rotate + 90;
    if (!flipped && degree === 360)
      degree = 0;
    if (flipped && degree < 0)
      degree = 270;
    setRotate(degree)
  }

  const handleFlip = (event) => {
    setFlipped(!flipped);
  }

  const handleFitout = (event) => {
    setZoom(3);
  }
  const handleFit = (event) => {
    setZoom(1);
  }

  const handleSave = () => {
    if (previewCanvasRef) {
      const canvasScaled = previewCanvasRef.current;
      handleResult(canvasScaled.toDataURL("image/png"));
    }
  };

  return (
    <ImageEditorPanelComponent
      img={img}
      zoom={zoom}
      isCircleRadius={isCircleRadius}
      refEditor={refEditor}
      handleSave={handleSave}
      handleZoom={handleZoom}
      handleRotate={handleRotate}
      handleFlip={handleFlip}
      handleFitout={handleFitout}
      handleFit={handleFit}
      handleCancel={handleCancel}
      aspectRatio={aspectRatio}
      width={width}
      height={height}
      rotate={rotate}
      flipped={flipped}
      lang={lang}
      imgRef={imgRef}
      previewCanvasRef={previewCanvasRef}
      crop={crop}
      setCrop={handleCrop}
      completedCrop={completedCrop}
      setCompletedCrop={handleCompletedCrop}
      useDebounceEffect={useDebounceEffect}
    />
  );
}

ImageEditorPanelContainer.defaultProps = {
  isCircleRadius: true,
  width: 350,
  height: 350
};

ImageEditorPanelContainer.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  handleResult: PropTypes.func.isRequired,
  radius: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number
};

export default ImageEditorPanelContainer;
