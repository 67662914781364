import React from "react";
import {
  Backdrop,
  Dialog,
  makeStyles,
  Typography,
  Paper,
  Box,
  IconButton,
  Divider,
  Chip,
  Button
} from "@material-ui/core";
import { Close as CloseIcon } from '@material-ui/icons';
import { Formik } from "formik"
import Input from "components/input/inputTextField";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles((theme) => ({
  dialog: {
    maxWidth: "none"
  },
  root: {
    boxShadow: theme.shadows[5],
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "space-between",
    width: 500,
    borderRadius: "4px",
    fontSize: 14
  },
  header: {
    paddingLeft: theme.spacing(3),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  content: {
    padding: "16px",
    maxHeight: "calc(100vh - 260px)", // Adjust the value as needed
    overflowY: "auto",
  },
  buttonContainer: {
    padding: "10px 16px",
    display: "flex",
    justifyContent: "flex-end"
  },
  selectRoot: {
    padding: "8px 14px"
  },
  inputRoot: {
    paddingTop: "8px !important",
    paddingBottom: "7px !important",
    paddingLeft: "12px !important",
    paddingRight: "12px !important"
  },
}))

export default function CreateAttributesDialogComponent({
  isOpen,
  handleClose,
  handleSubmit,
  isSubmittingAddOption,
  lang
}) {
  const classes = useStyle()

  return (
    <Dialog
      maxWidth="sm"
      open={isOpen}
      onClose={handleClose}
      disableBackdropClick
      closeAfterTransition
      BackdropComponent={Backdrop}
      classes={{
        paperWidthSm: classes.dialog
      }}
    >
      <Paper elevation={0} className={classes.root}>
        <Box className={classes.header}>
          <Typography
            variant='subtitle2'
            color='primary'
            style={{ fontWeight: "bold" }}
          >
            {getLang(lang,"label.ADD_ATTRIBUTE")}
          </Typography>
          <IconButton color='primary' onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
        <Divider />
        <Formik
          initialValues={{
            attribute: "",
            name: "",
            options: []
          }}
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <form onSubmit={e => { e.preventDefault() }}>
              <Box>
                <Box className={classes.content}>
                  <Box style={{ marginBottom: "20px" }}>
                    <Typography
                      variant="body2"
                      color="primary"
                      style={{ marginBottom: "10px" }}
                    >
                      {getLang(lang,"label.ATTRIBUTE_NAME")} <Typography style={{ color: "#DE350B", display: 'inline-block' }}>*</Typography>
                    </Typography>
                    <Input
                      hiddenLabel
                      varaint="filled"
                      placeholder={getLang(lang,"placeholder.ENTER_ATTRIBUTE_NAME")}
                      margin="none"
                      InputProps={{
                        disableUnderline: true,
                        margin: "none",
                        style: {
                          borderRadius: "4px",
                          backgroundColor: "#eceff0",
                          height: "36.5px"
                        },
                        classes: { input: classes.inputRoot }
                      }}
                      style={{
                        width: "100%"
                      }}
                      {...formik.getFieldProps("attribute")}
                    />
                  </Box>
                  <Box>
                    <Typography
                      variant="body2"
                      color="primary"
                      style={{ marginBottom: "10px" }}
                    >
                      {getLang(lang,"label.OPTIONS")} <Typography style={{ color: "#DE350B", display: 'inline-block' }}>*</Typography>
                    </Typography>
                    <Box style={{ display: "flex", justifyContent: "space-between" }}>
                      <Input
                        hiddenLabel
                        varaint="filled"
                        placeholder={getLang(lang,"placeholder.PLEASE_FILL_OPTION")}
                        margin="none"
                        InputProps={{
                          disableUnderline: true,
                          margin: "none",
                          style: {
                            borderRadius: "4px",
                            backgroundColor: "#eceff0",
                            height: "36.5px"
                          },
                          classes: { input: classes.inputRoot }
                        }}
                        style={{
                          width: "80%"
                        }}
                        onKeyPress={(e) => {
                          if (e.key === "Enter" && formik.values.name !== "") {
                            let errorMsg = "";
                            let isError = false;
                            let array = formik.values.name.trim().split(',');
                            let arrayToInsert = [];
                            if (array.length !== 0) {
                              array.forEach((input) => {
                                let val = input.trim();
                                if (val !== "") {
                                  if (formik.values.options.filter((option) => option === val).length === 0 && arrayToInsert.filter((value) => value === val).length === 0) {
                                    arrayToInsert = [...arrayToInsert, val]
                                  }
                                  else {
                                    isError = true;
                                    errorMsg = getLang(lang,"message.error.DUPLICATE_ATTRIBUTE_NAME");
                                  }
                                }
                              })
                            }
                            else {
                              errorMsg = getLang(lang,"message.error.INVALID_ENTRYINVALID_ENTRY");
                              isError = true;
                            }
                            if (arrayToInsert.length !== 0 && !isError) {
                              formik.setFieldValue("options", [...formik.values.options, ...arrayToInsert]);
                              formik.setFieldValue("name", "");
                            }
                            formik.setFieldError("name", errorMsg);
                          }
                        }}
                        error={formik.errors.name ? true : false}
                        helperText={formik.errors.name}
                        {...formik.getFieldProps("name")}
                      />
                      <Box>
                        <Button
                          variant='contained'
                          color="primary"
                          disableElevation
                          onClick={() => {
                            if (formik.values.name !== "") {
                              let errorMsg = "";
                              let isError = false;
                              let array = formik.values.name.trim().split(',');
                              let arrayToInsert = [];
                              if (array.length !== 0) {
                                array.forEach((input) => {
                                  let val = input.trim();
                                  if (val !== "") {
                                    if (formik.values.options.filter((option) => option === val).length === 0 && arrayToInsert.filter((value) => value === val).length === 0) {
                                      arrayToInsert = [...arrayToInsert, val]
                                    }
                                    else {
                                      isError = true;
                                      errorMsg = getLang(lang,"message.error.DUPLICATE_ATTRIBUTE_NAME");
                                    }
                                  }
                                })
                              }
                              else {
                                errorMsg = getLang(lang,"message.error.INVALID_ENTRYINVALID_ENTRY");
                                isError = true;
                              }
                              if (arrayToInsert.length !== 0 && !isError) {
                                formik.setFieldValue("options", [...formik.values.options, ...arrayToInsert]);
                                formik.setFieldValue("name", "");
                              }
                              formik.setFieldError("name", errorMsg);
                            }
                          }}
                        >
                          {getLang(lang,"label.INSERT")}
                        </Button>
                      </Box>
                    </Box>
                    <Typography
                      variant="caption"
                      style={{ color: "rgba(58, 77, 84, 0.7)" }}
                    >
                     {getLang(lang,"paragraph.ENTER_TO_ADD_ATTRIBUTE")}
                    </Typography>
                  </Box>
                  <Box style={{ display: "flex", marginTop: "10px", flexWrap: "wrap" }}>
                    {
                      formik.values.options.length > 0 ? formik.values.options.map((option) => (
                        <Box key={option} style={{ marginRight: "8px", marginBottom: "8px" }}>
                          <Chip
                            label={option}
                            onDelete={() => {
                              let temp = formik.values.options.filter((value) => value !== option)
                              formik.setFieldValue("options", temp)
                            }}
                          />
                        </Box>
                      )) : null
                    }
                  </Box>
                </Box>
                <Divider />
                <Box className={classes.buttonContainer}>
                  <Button
                    variant='contained'
                    disableElevation
                    color="secondary"
                    disabled={(formik.values.options.length === 0 || formik.values.attribute === "") || isSubmittingAddOption}
                    onClick={formik.handleSubmit}
                  >
                    {getLang(lang,"label.ADD")}
                  </Button>
                </Box>
              </Box>
            </form>
          )}
        </Formik>
      </Paper>
    </Dialog>
  )
}