import React from "react";
import {
  Box,
  // Button,
  Link,
  makeStyles,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from "@material-ui/core";
import {
  StyledTableCell,
  StyledTableRow,
  StickyRightTableCell,
} from "components/table";
import { SkeletonTableRowStickyLeftRight } from "components/skeleton/skeleton.component";
import { TablePaginationActions } from "components/tablePagination";
import RefreshTable from "components/table/refreshTable";
import { KeyboardArrowDown } from "@material-ui/icons";
import { ROOT } from "modules/stockflow/config/stockflow.route";
import { getLang } from "app/feature/constants";
import DefaultProfile from "assets/img/defaultPhoto.png";
import DefaultImg from "assets/img/img-default.png";
import { TIER_ICON_PROPS } from "modules/stockflow/constants";

const useStyle = makeStyles((theme) => ({
  tableContainer: {
    maxWidth: "calc(100vw - 80px)",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "calc(100vw - 32px)",
    },
  },
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    borderCollapse: "separate",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
    },
    "& th,td": {
      fontSize: ".75rem",
      borderRight: "1px solid rgba(58, 77, 84, 0.1)",
      padding: theme.spacing(1, 2, 1, 2),
    },
    "& th": {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightBold,
    },
    "& th:last-child, td:last-child": {
      width: 37,
      borderRight: "0px",
    },
    "& th th": {
      borderBottom: "none !important",
    },
    "& td td": {
      borderBottom: "none !important",
    },
  },
  emptyContainer: {
    backgroundColor: theme.palette.background.paper,
    textAlign: "center",
  },
  activeText: {
    color: "#32D583",
  },
  suspendText: {
    color: "#F97066",
  },
  profile: {
    height: 26,
    width: 26,
    minWidth: 26,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& img": {
      height: "100%",
      minHeight: "100%",
      minWidth: "100%",
      objectFit: "cover",
      borderRadius: "50%",
      border: "1px solid #D0D5DD",
    },
  },
  tierIcon: {
    borderRadius: "3px",
    paddingLeft: "0.15em",
    paddingRight: "0.15em",
    display: "flex",
    alignItems: "center",
    position: "absolute",
    top: "-3px",
    left: "-8px",
  },
  tierLabel: {
    color: "#FFFFFF",
    fontSize: "0.6rem",
  },
  ellipsisText: {
    maxWidth: "18em",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: "0.75rem",
  },
  productPhoto: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #E4E7EC",
    width: "30px",
    height: "30px",
    marginRight: "10px",
    "& img": {
      height: "30px",
      width: "30px",
    },
  },
  attributeText: {
    color: "#b2b2b2",
    fontSize: 12,
    whiteSpace: "break-spaces",
  },
}));

export default function LooseItemStockListTableComponent({
  hasStockflowDealerViewAccessRight,
  rows,
  isFetching,
  isError,
  handleReload,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowPerPage,
  isSearchResult,
  totalFiltered,
  tableFilter,
  changeSequenceOrder,
  lang,
  paginationOptions,
  isDealerPage,
  handleViewDetail,
}) {
  const classes = useStyle();

  return (
    <>
      {!isFetching && isError && <RefreshTable handleReload={handleReload} />}
      {isFetching || rows.length > 0 ? (
        <>
          <TableContainer className={classes.tableContainer}>
            <Table
              className={classes.table}
              aria-label="stock table"
              size="small"
              stickyHeader
            >
              <TableHead style={{ height: "45px" }}>
                <TableRow>
                  <StyledTableCell>
                    <TableSortLabel
                      active={true}
                      direction={tableFilter.order}
                      onClick={() => {
                        if (tableFilter.order === "asc") {
                          changeSequenceOrder("desc");
                        } else {
                          changeSequenceOrder("asc");
                        }
                      }}
                      IconComponent={KeyboardArrowDown}
                    >
                      {getLang(lang, "label.SEQUENCE_NUMBER")}
                    </TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.PACKAGE")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.BOX")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.PRODUCT_NAME")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.LOOSE_ITEM_QTY")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.BRANCH_ASSIGNED")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.OWNER")}
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isFetching
                  ? [...new Array(5)].map((v, index) => (
                      <SkeletonTableRowStickyLeftRight
                        key={index}
                        columnCount={7}
                      />
                    ))
                  : rows.map((row, index) => {
                      return (
                        <StyledTableRow key={row.id} hover tabIndex={-1}>
                          <StyledTableCell align="right">
                            {row.sequence_number || "-"}
                          </StyledTableCell>
                          <StyledTableCell>
                            {row.package ? (
                              <Link
                                href={`${ROOT}/inventory/package/${row.package.uuid}`}
                                color="secondary"
                                variant="caption"
                                onClick={(e) => {
                                  handleViewDetail(
                                    `${ROOT}/inventory/package/${row.package.uuid}`
                                  );
                                  e.preventDefault();
                                  return false;
                                }}
                              >
                                {row.package.code}
                              </Link>
                            ) : (
                              "-"
                            )}
                          </StyledTableCell>
                          <StyledTableCell>
                            {row.box ? (
                              <Link
                                href={`${ROOT}/inventory/box/${row.box.uuid}`}
                                color="secondary"
                                variant="caption"
                                onClick={(e) => {
                                  handleViewDetail(
                                    `${ROOT}/inventory/box/${row.box.uuid}`
                                  );
                                  e.preventDefault();
                                  return false;
                                }}
                              >
                                {row.box.code}
                              </Link>
                            ) : (
                              "-"
                            )}
                          </StyledTableCell>
                          <StyledTableCell>
                            {row.product ? (
                              <Box display="flex" alignItems="center">
                                <Box className={classes.productPhoto}>
                                  {row.product.picture === "" ? (
                                    <img src={DefaultImg} alt="emptyProduct" />
                                  ) : (
                                    <img
                                      src={row.product.picture}
                                      alt={row.product.name}
                                    />
                                  )}
                                </Box>
                                <Box
                                  display="flex"
                                  flexDirection="column"
                                  justifyContent="center"
                                  alignItems="flex-start"
                                >
                                  <Link
                                    href={`${ROOT}/inventory/product/${row.product.uuid}`}
                                    color="secondary"
                                    onClick={(e) => {
                                      handleViewDetail(
                                        `${ROOT}/inventory/product/${row.product.uuid}`
                                      );
                                      e.preventDefault();
                                      return false;
                                    }}
                                  >
                                    {row.product.name}
                                  </Link>
                                  {!!row.product.attributes &&
                                    !!row.product.attributes.length && (
                                      <Typography
                                        className={classes.attributeText}
                                        variant="body2"
                                      >
                                        {row.product.attributes.join(", ")}
                                      </Typography>
                                    )}
                                </Box>
                              </Box>
                            ) : (
                              "-"
                            )}
                          </StyledTableCell>
                          <StyledTableCell>
                            {row.quantity ? row.quantity.toLocaleString("en-US") : "-"}
                          </StyledTableCell>
                          <StyledTableCell>{row.node || "-"}</StyledTableCell>
                          <StyledTableCell>
                            {row.located_at ? (
                              <Box display="flex" alignItems="center">
                                <Box
                                  className={classes.profile}
                                  mr={1}
                                  position="relative"
                                >
                                  {row.located_at.picture === "" ? (
                                    <img
                                      src={
                                        row.located_at.type === "branch"
                                          ? DefaultImg
                                          : DefaultProfile
                                      }
                                      alt="default profile"
                                    />
                                  ) : (
                                    <img
                                      src={row.located_at.picture}
                                      alt={row.located_at.name}
                                    />
                                  )}
                                  {!!row.located_at.tier && (
                                    <Box
                                      className={classes.tierIcon}
                                      style={{
                                        backgroundColor: TIER_ICON_PROPS.find(
                                          ({ tier }) =>
                                            tier === row.located_at.tier
                                        ).color,
                                      }}
                                    >
                                      <Typography className={classes.tierLabel}>
                                        T{row.located_at.tier}
                                      </Typography>
                                    </Box>
                                  )}
                                </Box>
                                {row.located_at.type === "branch" ||
                                !hasStockflowDealerViewAccessRight ? (
                                  <Typography className={classes.ellipsisText}>
                                    {row.located_at.name}
                                  </Typography>
                                ) : (
                                  <Link
                                    href={`${ROOT}/my-dealer/${row.located_at.uuid}`}
                                    color="secondary"
                                    onClick={(e) => {
                                      if (row.located_at.type !== "branch") {
                                        handleViewDetail(
                                          `${ROOT}/my-dealer/${row.located_at.uuid}`
                                        );
                                      }
                                      e.preventDefault();
                                      return false;
                                    }}
                                  >
                                    <Typography
                                      className={classes.ellipsisText}
                                    >
                                      {row.located_at.name}
                                    </Typography>
                                  </Link>
                                )}
                              </Box>
                            ) : (
                              <span>-</span>
                            )}
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            style={{
              backgroundColor: "#fff",
              boxShadow: "0px -1px 0px rgba(58, 77, 84, 0.15)",
            }}
            ActionsComponent={TablePaginationActions}
            rowsPerPageOptions={paginationOptions}
            component="div"
            count={totalFiltered}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowPerPage}
            labelRowsPerPage={getLang(lang, "label.ROWS_PER_PAGE")}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} ${getLang(lang, "label.OF")} ${count}`
            }
          />
        </>
      ) : null}
      {!isFetching && !isError && rows.length === 0 && (
        <Box>
          <Table
            className={classes.table}
            aria-label="stock table"
            size="small"
            stickyHeader
          >
            <TableHead style={{ height: "45px" }}>
              <TableRow>
                <StyledTableCell>
                  {getLang(lang, "label.SEQUENCE_NUMBER")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.PACKAGE")}
                </StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.BOX")}</StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.PRODUCT_NAME")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.LOOSE_ITEM_QTY")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.BRANCH_ASSIGNED")}
                </StyledTableCell>
                <StickyRightTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.LOCATED_AT")}
                  </StyledTableCell>
                </StickyRightTableCell>
              </TableRow>
            </TableHead>
          </Table>
          <Box py={9} className={classes.emptyContainer}>
            <Typography
              variant="body1"
              style={{ paddingBottom: "16px", fontWeight: "bold" }}
            >
              {isSearchResult
                ? getLang(lang, "paragraph.NO_SERIAL_NUMBER_EXISTS")
                : getLang(lang, "paragraph.INVENTORY_EMPTY")}
            </Typography>
            {!isSearchResult && (
              <>
                <Typography variant="body2" style={{ paddingBottom: "16px" }}>
                  {getLang(lang, "paragraph.INVENTORY_INSTRUCTION")}
                </Typography>
                {/* <Button
                  onClick={() => {
                    // TODO: add link
                  }}
                  variant="contained"
                  disableElevation
                  color="primary"
                >
                  {getLang(lang, "label.CREATE_QR_CODE")}
                </Button> */}
              </>
            )}
          </Box>
        </Box>
      )}
    </>
  );
}
