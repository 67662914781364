import React from "react"
import LooseItemInfoCardComponent from "./looseItemInfoCard.component";

export default function LooseItemInfoCardContainer({
  looseItem,
  isFetching,
  lang
}) {
  return (
    <LooseItemInfoCardComponent
      looseItem={looseItem} 
      isFetching={isFetching}
      lang={lang}
    />
  )
}