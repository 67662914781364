/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import AdtInfoDisplayLangSelectComponent from "./adtInfoDisplayLangSelect.component";
import { useDispatch } from "react-redux";
import { updateDisplayLang } from "modules/serial-number/redux";

function AdtInfoDisplayLangSelectContainer() {
  const dispatch = useDispatch();

  const [currentLang, setCurrentLang] = useState("EN");

  const handleChangeLang = (event) => {
    setCurrentLang(event.target.value);
    dispatch(updateDisplayLang(event.target.value));
  };

  return (
    <>
      <AdtInfoDisplayLangSelectComponent
        handleChangeLang={handleChangeLang}
        value={currentLang}
      />
    </>
  );
}

export default AdtInfoDisplayLangSelectContainer;
