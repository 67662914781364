import listingMdl from "./listing.mdl";
import batchMdl from "./batch.mdl";
import tagMdl from "./tag.mdl";
import serialnumberMdl from "./serialnumber.mdl";
import detailsMdl from "./details.mdl";
import assignBatchMdl from "./assignBatch.mdl";
import adtInfoMdl from "./adtInfo.mdl";

export default [
  ...listingMdl,
  ...batchMdl,
  ...tagMdl,
  ...serialnumberMdl,
  ...detailsMdl,
  ...assignBatchMdl,
  ...adtInfoMdl,
];
