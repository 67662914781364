import React from "react";
import EmailSettingCardComponent from "./emailSettingCard.component";
import { useSelector } from "react-redux";
import { memo } from "react";

function EmailSettingCardContainer({
  attribute,
  isEditable,
  formik,
  attributeValue,
  isSubmitting,
  invalidEmails,
  setInvalidEmails
}) {
  const lang = useSelector((state) => state.constant.languages);

  return (
    <EmailSettingCardComponent
      attribute={attribute}
      isEditable={isEditable}
      formik={formik}
      attributeValue={attributeValue}
      lang={lang}
      isSubmitting={isSubmitting}
      invalidEmails={invalidEmails}
      setInvalidEmails={setInvalidEmails}
    />
  );
}

export default memo(EmailSettingCardContainer);
