import { createSlice } from "@reduxjs/toolkit";
import {
  STOCKFLOWRECEIVETRANSFER,
  getReceiveTransferDeliveryInfo,
  getReceiveTransferDetail,
  getReceiveTransferItemBoxList,
  getReceiveTransferItemBoxProductList,
  getReceiveTransferItemProductList,
  getReceiveTransferItemProductSNList,
  getReceiveTransferList,
  getReceiveTransferReceiverDropdown,
  getReceiveTransferSenderDropdown,
} from "../action/stockflowReceiveTransfer.action";

const initialState = {
  receiveTransferList: {
    list: [],
    totalFiltered: 0,
    totalRecords: 0,
  },
  isLoadingReceiveTransferListError: false,
  receiveTransfer: null,
  isLoadingReceiveTransferError: false,
  receiveTransferSenderDropdown: [],
  receiveTransferReceiverDropdown: [],
  transferBoxList: {
    list: [],
    totalFiltered: 0,
    totalRecords: 0,
    isError: false,
    hasNextPage: true,
  },
  transferBoxProductList: {
    list: [],
    isError: false,
  },
  transferSnList: {
    list: [],
    totalFiltered: 0,
    totalRecords: 0,
    isError: false,
  },
  transferProductList: {
    list: [],
    totalFiltered: 0,
    totalRecords: 0,
    isError: false,
    hasNextPage: true,
  },
  deliveryInfo: {
    list: [],
    isLoading: false,
    isError: false,
  },
};

const stockflowReceiveTransferSlice = createSlice({
  name: STOCKFLOWRECEIVETRANSFER,
  initialState,
  reducers: {
    resetReceiveTransferListing(state) {
      state.receiveTransferList = initialState.receiveTransferList;
    },
    resetReceiveTransfer(state) {
      state.receiveTransfer = initialState.receiveTransfer;
      state.transferBoxList = initialState.transferBoxList;
      state.transferBoxProductList = initialState.transferBoxProductList;
      state.transferSnList = initialState.transferSnList;
      state.transferProductList = initialState.transferProductList;
      state.deliveryInfo = initialState.deliveryInfo;
    },
    resetSnListingReceiver(state) {
      state.transferSnList = initialState.transferSnList;
    },
    resetReceiveTransferBoxListing(state) {
      state.transferBoxList = initialState.transferBoxList;
    },
    resetBoxProductListingReceiver(state) {
      state.transferBoxProductList = initialState.transferBoxProductList;
    },
  },
  extraReducers: {
    [getReceiveTransferList.pending]: (state) => {
      state.receiveTransferList = initialState.receiveTransferList;
      state.isLoadingReceiveTransferListError = false;
    },
    [getReceiveTransferList.fulfilled]: (state, action) => {
      const { payload } = action;
      state.receiveTransferList.list = payload.data;
      state.receiveTransferList.totalRecords = payload.recordsTotal;
      state.receiveTransferList.totalFiltered = payload.recordsFiltered;
    },
    [getReceiveTransferList.rejected]: (state) => {
      state.isLoadingReceiveTransferListError = true;
    },
    [getReceiveTransferDetail.pending]: (state) => {
      state.receiveTransfer = initialState.receiveTransfer;
      state.isLoadingReceiveTransferError = false;
    },
    [getReceiveTransferDetail.fulfilled]: (state, action) => {
      state.receiveTransfer = action.payload;
    },
    [getReceiveTransferDetail.rejected]: (state) => {
      state.isLoadingReceiveTransferError = true;
    },
    [getReceiveTransferSenderDropdown.pending]: (state) => {
      state.receiveTransferSenderDropdown =
        initialState.receiveTransferSenderDropdown;
    },
    [getReceiveTransferSenderDropdown.fulfilled]: (state, action) => {
      state.receiveTransferSenderDropdown = action.payload;
    },
    [getReceiveTransferReceiverDropdown.pending]: (state) => {
      state.receiveTransferReceiverDropdown =
        initialState.receiveTransferReceiverDropdown;
    },
    [getReceiveTransferReceiverDropdown.fulfilled]: (state, action) => {
      state.receiveTransferReceiverDropdown = action.payload;
    },
    [getReceiveTransferItemBoxList.pending]: (state) => {
      state.transferBoxList = initialState.transferBoxList;
    },
    [getReceiveTransferItemBoxList.fulfilled]: (state, action) => {
      const { payload } = action;
      if (state.transferBoxList.hasNextPage) {
        state.transferBoxList.list = [
          ...state.transferBoxList.list,
          ...payload.data,
        ];
      }
      state.transferBoxList.totalRecords = payload.recordsTotal;
      state.transferBoxList.totalFiltered = payload.recordsFiltered;
      state.transferBoxList.hasNextPage =
        state.transferBoxList.list.length < state.transferBoxList.totalFiltered;
    },
    [getReceiveTransferItemBoxList.rejected]: (state) => {
      state.transferBoxList.isError = true;
    },
    [getReceiveTransferItemBoxProductList.pending]: (state) => {
      state.transferBoxProductList = initialState.transferBoxProductList;
    },
    [getReceiveTransferItemBoxProductList.fulfilled]: (state, action) => {
      const { payload } = action;
      state.transferBoxProductList.list = payload;
    },
    [getReceiveTransferItemBoxProductList.rejected]: (state) => {
      state.transferBoxProductList.isError = true;
    },
    [getReceiveTransferItemProductList.pending]: (state) => {
      state.transferProductList = initialState.transferProductList;
    },
    [getReceiveTransferItemProductList.fulfilled]: (state, action) => {
      const { payload } = action;
      if (state.transferProductList.hasNextPage) {
        state.transferProductList.list = [
          ...state.transferProductList.list,
          ...payload.data,
        ];
      }
      state.transferProductList.totalRecords = payload.recordsTotal;
      state.transferProductList.totalFiltered = payload.recordsFiltered;
      state.transferProductList.hasNextPage =
        state.transferProductList.list.length <
        state.transferProductList.totalFiltered;
    },
    [getReceiveTransferItemProductList.rejected]: (state) => {
      state.transferProductList.isError = true;
    },
    [getReceiveTransferItemProductSNList.pending]: (state) => {
      state.transferSnList = initialState.transferSnList;
    },
    [getReceiveTransferItemProductSNList.fulfilled]: (state, action) => {
      const { payload } = action;
      state.transferSnList.list = payload.data;
      state.transferSnList.totalRecords = payload.recordsTotal;
      state.transferSnList.totalFiltered = payload.recordsFiltered;
    },
    [getReceiveTransferItemProductSNList.rejected]: (state) => {
      state.transferSnList.isError = true;
    },
    [getReceiveTransferDeliveryInfo.pending]: (state) => {
      state.deliveryInfo.isLoading = true;
      state.deliveryInfo.list = [];
      state.deliveryInfo.isError = false;
    },
    [getReceiveTransferDeliveryInfo.fulfilled]: (state, action) => {
      state.deliveryInfo.isLoading = false;
      state.deliveryInfo.list = action.payload;
    },
    [getReceiveTransferDeliveryInfo.rejected]: (state) => {
      state.deliveryInfo.isLoading = false;
      state.deliveryInfo.isError = true;
    },
  },
});

export const {
  resetReceiveTransferListing,
  resetReceiveTransfer,
  resetSnListingReceiver,
  resetBoxProductListingReceiver,
  resetReceiveTransferBoxListing,
} = stockflowReceiveTransferSlice.actions;

export const stockflowReceiveTransferReducer =
  stockflowReceiveTransferSlice.reducer;
