/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import DatePickerScrollDialogComponent from "./datePickerScrollDialog.component";
import { useSelector } from "react-redux";
import moment from "moment";

function DatePickerScrollDialogContainer({
  label,
  handleChange,
  displayFormat,
  value,
  error,
  disabled,
  ...props
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [startYear] = useState(
    parseInt(new moment().subtract(100, "years").format("YYYY"))
  );
  const [months] = useState(moment.months());
  const [pickerValue, setPickerValue] = useState({
    year: (value ? moment(value) : moment()).format("YYYY"),
    month: (value ? moment(value) : moment()).format("MMMM") ?? "",
    day: (value ? moment(value) : moment()).format("DD") ?? "",
  });
  const [date, setDate] = useState(
    value ? moment(value).format(displayFormat) : ""
  );
  const [defaultDate] = useState(
    (value ? moment(value) : moment()).format("DD MMMM YYYY")
  );
  const lang = useSelector((state) => state.constant.languages);

  useEffect(() => {
    setDate(value ? moment(value).format(displayFormat) : "");
  }, [value]);

  const getDayArray = (year, month) => {
    const dayCount = new moment(
      year + "/" + month + "/1",
      "YYYY/MMMM/DD"
    ).daysInMonth();
    return Array.from({ length: dayCount }, (x, i) =>
      moment().startOf("month").add(i, "days").format("DD")
    );
  };

  const handlePickerChange = useCallback((newValue, key) => {
    if (key === "day") {
      setPickerValue(newValue);
      return;
    }
    const { year, month } = newValue;
    const newDayArray = getDayArray(Number(year), month);
    const newDay = newDayArray.includes(newValue.day)
      ? newValue.day
      : newDayArray[newDayArray.length - 1];
    setPickerValue({ ...newValue, day: newDay });
  }, []);

  const selectDate = () => {
    setIsOpen(false);
    const day = pickerValue.day;
    const month = pickerValue.month;
    const year = pickerValue.year;
    setDate(
      moment(day + " " + month + " " + year, "DD MMMM YYYY").format(
        displayFormat
      )
    ); // for text field display
    handleChange(moment(day + " " + month + " " + year, "DD MMMM YYYY")); // for form request
  };

  return (
    <DatePickerScrollDialogComponent
      label={label}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      defaultDate={defaultDate}
      lang={lang}
      date={date}
      months={months}
      startYear={startYear}
      pickerValue={pickerValue}
      setPickerValue={setPickerValue}
      handlePickerChange={handlePickerChange}
      getDayArray={getDayArray}
      selectDate={selectDate}
      error={error}
      disabled={disabled}
      {...props}
    />
  );
}

DatePickerScrollDialogContainer.defaultProps = {
  displayFormat: "DD MMM YYYY",
};

DatePickerScrollDialogContainer.propTypes = {
  label: PropTypes.string.isRequired,
  displayFormat: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default DatePickerScrollDialogContainer;
