import { createAsyncThunk } from "@reduxjs/toolkit";
import accountApi from "app/api/account";

export const BRANCHGROUP = "branchGroup";

export const fetchGroupList = createAsyncThunk(
  `${BRANCHGROUP}/fetchGroupList`,
  async (payload, thunkApi) => {
    return accountApi
      .getAllBranchGroups(payload)
      .then((response) => response)
      .catch((error) => thunkApi.rejectWithValue(error.response.data));
  }
);

export const createBranchGroup = createAsyncThunk(
  `${BRANCHGROUP}/createGroup`,
  async (payload, thunkApi) => {
    return accountApi
      .createBranchGroup(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error.response.data));
  }
);

export const getAvailableBranches = createAsyncThunk(
  `${BRANCHGROUP}/getAvailableBranches`,
  async (payload, thunkApi) => {
    return accountApi
      .getAvailableBranches(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error.response.data));
  }
);

export const updateGroupBranches = createAsyncThunk(
  `${BRANCHGROUP}/updateGroupBranches`,
  async (payload, thunkApi) => {
    return accountApi
      .updateGroupBranches(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error.response.data));
  }
);

export const getGroupSummary = createAsyncThunk(
  `${BRANCHGROUP}/getGroupSummary`,
  async (payload, { rejectWithValue }) => {
    return accountApi
      .getBranchGroupSummary(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const deleteBranchGroup = createAsyncThunk(
  `${BRANCHGROUP}/deleteBranchGroup`,
  async (payload, thunkApi) => {
    return accountApi
      .deleteBranchGroup(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error.response.data));
  }
);

export const editBranchGroup = createAsyncThunk(
  `${BRANCHGROUP}/editBranchGroup`,
  async (payload, thunkApi) => {
    return accountApi
      .editBranchGroup(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error.response.data));
  }
);

export const getGroupRules = createAsyncThunk(
  `${BRANCHGROUP}/getGroupRules`,
  async (payload, thunkApi) => {
    return accountApi
      .getGroupRules(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error.response.data));
  }
);

export const getAllAvailableBranchesForRules = createAsyncThunk(
  `${BRANCHGROUP}/getAllAvailableBranchesForRules`,
  async (payload, thunkApi) => {
    return accountApi
      .getAllAvailableBranchesForRules(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error.response.data));
  }
);

export const getBranchesInGroup = createAsyncThunk(
  `${BRANCHGROUP}/getBranchesInGroup`,
  async (payload, thunkApi) => {
    return accountApi
      .getBranchesInGroup(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error.response.data));
  }
);

export const updateGroupRules = createAsyncThunk(
  `${BRANCHGROUP}/updateGroupRules`,
  async (payload, thunkApi) => {
    return accountApi
      .updateGroupRules(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error.response.data));
  }
);
