import React from "react";
import {
  makeStyles,
  Typography,
  Box,
  Paper,
  Divider,
  Button,
  CircularProgress,
} from "@material-ui/core";
import Input from "components/input/inputTextField";
import { ChevronRightRounded as ChevronRightRoundedIcon } from "@material-ui/icons";
import { BackButton } from "components/button";
import { getLang } from "app/feature/constants";
import { Formik } from "formik";
import StockTakeBranchSelect from "modules/stockflow/components/select/stockTakeBranchSelect";

const useStyle = makeStyles((theme) => ({
  panel: {
    backgroundColor: "#FFFFFF",
    borderRadius: 4,
    marginBottom: 16,
  },
  inputSection: {
    display: "grid",
    gridTemplateColumns: "2fr 6fr",
    columnGap: theme.spacing(2),
    alignItems: "center",
  },
  inputRootID: {
    paddingTop: "8px !important",
    paddingBottom: "7px !important",
    paddingLeft: "12px !important",
    paddingRight: "12px !important",
    backgroundColor: "#FFFFFF",
    border: "1px solid #D0D5DD",
    borderRadius: 4,
  },
}));

export default function StockTakeCreateComponent({
  handleSubmit,
  lang,
  isSubmitting,
}) {
  const classes = useStyle();

  return (
    <Box className={classes.root}>
      <Box gridArea="topbar" mb={3}>
        <Box
          mb={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="flex" alignItems="flex-end" fontWeight="bold">
            <BackButton />
            <Box display="flex" ml={1} alignItems="flex-end">
              <Typography variant="h6" color="primary">
                {getLang(lang, "label.STOCK_TAKE")}
              </Typography>
              <Box mx={0.5}>
                <ChevronRightRoundedIcon color="disabled" />
              </Box>
              <Box>
                <Typography variant="h6" color="primary">
                  {getLang(lang, "label.ADD_STOCK_TAKE")}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box gridArea="panel">
        <Formik
          initialValues={{
            branch: null,
            name: "",
          }}
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <Paper className={classes.panel} elevation={3}>
                <Box mb={2} p={3}>
                  <Box mb={2}>
                    <Typography variant="body1">
                      <b>{getLang(lang, "label.ADD_STOCK_TAKE")}</b>
                    </Typography>
                  </Box>
                  <Divider />
                  <Box mt={2}>
                    <Typography variant="body1">
                      <b>{getLang(lang, "label.STOCK_TAKE_SETUP")}</b>
                    </Typography>
                  </Box>
                  <Box className={classes.inputSection} mt={2}>
                    <Box>
                      <Typography>
                        {getLang(lang, "label.BRANCH")}{" "}
                        <span style={{ color: "#F97066" }}>*</span>
                      </Typography>
                    </Box>
                    <StockTakeBranchSelect
                      placeholder={getLang(lang, "placeholder.BRANCH")}
                      value={formik.values.branch}
                      handleChange={(value) =>
                        formik.setFieldValue("branch", value)
                      }
                      styles={{
                        padding: "6px 10px",
                      }}
                      disabled={isSubmitting}
                    />
                  </Box>
                  <Box className={classes.inputSection} mt={2}>
                    <Box>
                      <Typography>
                        {getLang(lang, "label.STOCK_TAKE_PROGRAM_NAME")}
                      </Typography>
                    </Box>
                    <Input
                      hiddenLabel
                      variant="filled"
                      size="small"
                      placeholder={getLang(
                        lang,
                        "placeholder.STOCK_TAKE_PROGRAM_NAME"
                      )}
                      margin="none"
                      InputProps={{
                        disableUnderline: true,
                        margin: "none",
                        style: {
                          borderRadius: "4px",
                          minWidth: "50px",
                          height: "100%",
                        },
                        classes: { input: classes.inputRootID },
                      }}
                      disabled={isSubmitting}
                      {...formik.getFieldProps("name")}
                    />
                  </Box>
                </Box>
                <Divider />
                <Box display="flex" justifyContent="end" p={2}>
                  <Button
                    variant="contained"
                    disableElevation
                    color="secondary"
                    size="small"
                    type="submit"
                    loadin
                  >
                    {isSubmitting ? (
                      <CircularProgress size={20} style={{ color: "white" }} />
                    ) : (
                      <Typography>{getLang(lang, "label.SAVE")}</Typography>
                    )}
                  </Button>
                </Box>
              </Paper>
            </form>
          )}
        </Formik>
      </Box>
    </Box>
  );
}
