import { createAsyncThunk } from '@reduxjs/toolkit';
import accountApi from 'app/api/account';

export const PRODUCTS = "products";

export const getCurrencyDropdown = createAsyncThunk(
  `${PRODUCTS}/getCurrencyDropdown`,
  async (payload, { rejectWithValue }) => {
    return accountApi.getConfiguredCurrencyDropdownList({
      search: payload?.search || ''
    })
      .then(response => response.data)
      .catch(error => rejectWithValue(error))
  }
)