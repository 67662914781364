import React from 'react';
import { Box } from '@material-ui/core';
import ScanLocationStateListItem from "./scanLocationStateListItem.component"

export default function ScanLocationStatePanelComponent({ scanData, targetCountry }) {
  return (
    <Box>
      {scanData.map((item, index) => (
        <ScanLocationStateListItem
          targetCountry={targetCountry}
          key={index}
          scanData={scanData}
          item={item}
          index={index}
        />
      ))}
    </Box>
  )
}