import React from "react";
import StockoutDealerPanel from './stockoutDealerPanel.component';
import { useDispatch, useSelector } from "react-redux";
import { selectLoading } from "modules/notification";
import { getDealerStockoutData } from "modules/reporting/redux";
import { useState } from "react";
import { useEffect } from "react";
import moment from "moment";

export default function StockoutDealerPanelContainer({
    currentFilter,
    urlDealerStockout,
    pageType,
    translate
}){
    const dispatch = useDispatch();
    
    // (1) stockout model data -- start
    const isFetchingDealerStockout = useSelector(state => selectLoading(state, getDealerStockoutData.typePrefix));
    const dealerStockoutData = useSelector(state => state.reporting.stockout.dealer.stockoutByDealerData);
    // (1) stockout model data -- end

    const [tableFilter, setTableFilter] = useState({
        page: 0,
        rowsPerPage: 10,
        search: "",
        searchBy: "name",
        orderBy: "tier",
        orderDirection: "asc",
    })

    useEffect(() => {
        dispatch(getDealerStockoutData({
            length: tableFilter.rowsPerPage,
            start: tableFilter.page * tableFilter.rowsPerPage,
            orderBy: tableFilter.orderBy,
            orderDirection: tableFilter.orderDirection,
            search: tableFilter.search,
            searchBy: tableFilter.searchBy,
            branch_uuid: currentFilter.branchUuid,
            category_uuid: currentFilter.categoryUuid,
            brand_uuid: currentFilter.brandUuid,
            model_uuid: currentFilter.modelUuid,
            product_uuid: currentFilter.productUuid,
            period: currentFilter.period,
            start_date: moment(currentFilter.dateRange[0]).format('YYYY-MM-DD'),
            end_date: moment(currentFilter.dateRange[1]).format('YYYY-MM-DD'),
            url: urlDealerStockout
        }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, tableFilter, currentFilter]);

    const handleChangePage = (e, newPage) => {
        setTableFilter((prev) => ({
          ...prev,
          page: newPage,
        }))
    }

    const handleChangeRowPerPage = (event) => {
        setTableFilter((prev) => ({
          ...prev,
          page: 0,
          rowsPerPage: event.target.value
        }))
    }

    const handleRequestSort = (event, property) => {
        const isAsc = tableFilter.orderBy === property && tableFilter.orderDirection === 'asc';
        setTableFilter((prev) => ({
            ...prev,
            orderDirection: isAsc ? 'desc' : 'asc',
            orderBy: property,
        }))
    }


    return (
        <StockoutDealerPanel 
            status={{
                isFetchingDealerStockout
            }}

            data={{
                dealerStockoutData
            }}

            sort={{
                valueToOrderBy: tableFilter.orderBy,
                orderDirection: tableFilter.orderDirection,
                handleRequestSort: handleRequestSort,
            }}

            pagination={{
                handleChangePage: handleChangePage,
                handleChangeRowPerPage: handleChangeRowPerPage,
                page: tableFilter.page,
                rowsPerPage: tableFilter.rowsPerPage,
                rowsPerPageOptions: [5, 10, 25],
            }}

            currentFilter={currentFilter}
            translate={translate}
            pageType={pageType}
        />
    )
}