import { getApiLang } from "app/feature/constants";
import { setLoading, addAlert } from "modules/notification";
import {
  getCheckoutBoxList,
  getCheckoutByDropdown,
  getCheckoutDeliveryInfo,
  getCheckoutDetail,
  getCheckoutList,
  getCheckoutProductDetail,
  getCheckoutProductList,
  updateCheckoutDeliveryInfo,
  updateCheckoutDetail,
} from "../action";

const getCheckoutListMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getCheckoutList.pending.type:
        dispatch(setLoading({ id: getCheckoutList.typePrefix, state: true }));
        break;
      case getCheckoutList.fulfilled.type:
        dispatch(setLoading({ id: getCheckoutList.typePrefix, state: false }));
        break;
      case getCheckoutList.rejected.type:
        const lang = getState().constant.languages;
        dispatch(setLoading({ id: getCheckoutList.typePrefix, state: false }));
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_GET_CHECKOUT_LIST"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const getCheckoutDetailMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getCheckoutDetail.pending.type:
        dispatch(setLoading({ id: getCheckoutDetail.typePrefix, state: true }));
        break;
      case getCheckoutDetail.fulfilled.type:
        dispatch(
          setLoading({ id: getCheckoutDetail.typePrefix, state: false })
        );
        break;
      case getCheckoutDetail.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({ id: getCheckoutDetail.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_GET_CHECKOUT_DETAIL"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const getCheckoutByDropdownMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getCheckoutByDropdown.pending.type:
        dispatch(
          setLoading({
            id: getCheckoutByDropdown.typePrefix,
            state: true,
          })
        );
        break;
      case getCheckoutByDropdown.fulfilled.type:
        dispatch(
          setLoading({
            id: getCheckoutByDropdown.typePrefix,
            state: false,
          })
        );
        break;
      case getCheckoutByDropdown.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: getCheckoutByDropdown.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

const getCheckoutProductDetailMiddleWare =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);

    const { type } = action;

    switch (type) {
      case getCheckoutProductDetail.pending.type:
        dispatch(
          setLoading({
            id: getCheckoutProductDetail.typePrefix,
            state: true,
          })
        );
        break;
      case getCheckoutProductDetail.fulfilled.type:
        dispatch(
          setLoading({
            id: getCheckoutProductDetail.typePrefix,
            state: false,
          })
        );
        break;
      case getCheckoutProductDetail.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: getCheckoutProductDetail.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              "error.ERROR_GET_CHECKOUT_PRODUCT_SN_LIST"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const getCheckoutProductListMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getCheckoutProductList.pending.type:
        dispatch(
          setLoading({ id: getCheckoutProductList.typePrefix, state: true })
        );
        break;
      case getCheckoutProductList.fulfilled.type:
        dispatch(
          setLoading({
            id: getCheckoutProductList.typePrefix,
            state: false,
          })
        );
        break;
      case getCheckoutProductList.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: getCheckoutProductList.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_GET_CHECKOUT_PRODUCTS"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const getCheckoutBoxListMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getCheckoutBoxList.pending.type:
        dispatch(
          setLoading({ id: getCheckoutBoxList.typePrefix, state: true })
        );
        break;
      case getCheckoutBoxList.fulfilled.type:
        dispatch(
          setLoading({
            id: getCheckoutBoxList.typePrefix,
            state: false,
          })
        );
        break;
      case getCheckoutBoxList.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: getCheckoutBoxList.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_GET_CHECKOUT_BOX_ITEMS"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const updateCheckoutDeliveryInfoMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case updateCheckoutDeliveryInfo.pending.type:
        dispatch(
          setLoading({ id: updateCheckoutDeliveryInfo.typePrefix, state: true })
        );
        break;
      case updateCheckoutDeliveryInfo.fulfilled.type:
        dispatch(
          setLoading({
            id: updateCheckoutDeliveryInfo.typePrefix,
            state: false,
          })
        );
        break;
      case updateCheckoutDeliveryInfo.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: updateCheckoutDeliveryInfo.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

const getCheckoutDeliveryInfoMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getCheckoutDeliveryInfo.pending.type:
        dispatch(
          setLoading({ id: getCheckoutDeliveryInfo.typePrefix, state: true })
        );
        break;
      case getCheckoutDeliveryInfo.fulfilled.type:
        dispatch(
          setLoading({
            id: getCheckoutDeliveryInfo.typePrefix,
            state: false,
          })
        );
        break;
      case getCheckoutDeliveryInfo.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: getCheckoutDeliveryInfo.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

const updateCheckoutDetailMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case updateCheckoutDetail.pending.type:
        dispatch(
          setLoading({ id: updateCheckoutDetail.typePrefix, state: true })
        );
        break;
      case updateCheckoutDetail.fulfilled.type:
        dispatch(
          setLoading({
            id: updateCheckoutDetail.typePrefix,
            state: false,
          })
        );
        break;
      case updateCheckoutDetail.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: updateCheckoutDetail.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

export default [
  getCheckoutListMiddleware,
  getCheckoutDetailMiddleware,
  getCheckoutByDropdownMiddleware,
  getCheckoutProductDetailMiddleWare,
  getCheckoutProductListMiddleware,
  getCheckoutBoxListMiddleware,
  updateCheckoutDeliveryInfoMiddleware,
  getCheckoutDeliveryInfoMiddleware,
  updateCheckoutDetailMiddleware,
];
