import React from "react";
import { Box, Paper, Typography, Divider, makeStyles } from "@material-ui/core";
import DefaultProfileImg from "assets/img/default-profile-picture.png";
import { Skeleton } from "@material-ui/lab";
import { getLang } from "app/feature/constants";
import moment from "moment";
import "moment/min/locales.min";

const style = (theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(2, 3, 2, 3)
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2)
    }
  },
  box: {
    width: "100%",
    padding: theme.spacing(2, 0, 2, 0)
  },
  roleDisplay: {
    display: "flex",
    width: "100%",
    padding: theme.spacing(0, 6),
    [theme.breakpoints.down("sm")]: {
      width: "80%",
      alignItems: "center"
    }
  },
  text: {
    color: theme.palette.primary.main,
    fontWeight: "bold"
  },
  memberDate: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      fontSize: "small"
    }
  },
  roleIcon: {
    borderBottom: "solid",
    color: theme.palette.primary.main
  },
  listIcon: {
    display: "list-item",
    listStyleType: "disc",
    color: "#9CA6A9",
    fontSize: "small"
  },
  textRole: {
    color: "#9CA6A9",
    fontWeight: "300"
  },
  avatar: {
    height: 56,
    width: 56,
    borderRadius: "50%",
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    "& img": {
      height: "100%",
      minWidth: "100%",
      minHeight: "100%",
      objectFit: "cover",
      flexShrink: 0
    }
  },
  multibranch: {
    backgroundColor: "#0095E1",
    color: "white",
    padding: "4px 8px",
    borderRadius: "16px",
    fontSize: "10px",
    marginLeft: theme.spacing(1)
  }
});

const useStyles = makeStyles(style);

export default function InfoHeaderComponent({ employeeDetail, isFetching, lang,language }) {
  const classes = useStyles();
  
  if(language==="ZH")
  {
    moment.locale('zh-cn')
  }else if(language==="TL")
  {
    moment.locale('tl-ph')
  }else
  {
    moment.locale(language)
  }


  return (
    <Paper elevation={0}>
      <Box className={classes.root}>
        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.memberDate}
        >
          {getLang(lang, "label.LAST_LOGIN")}&nbsp;
          {isFetching && !employeeDetail.loggedinAt ? (
            <Skeleton variant="text" width={80} height={30} />
          ) : moment(employeeDetail.loggedinAt).format('lll') ? (
            <span className={classes.text}>
              {moment(employeeDetail.loggedinAt).format('lll') + " (" + moment(employeeDetail.loggedinAt).startOf('day').fromNow() + ")"}
            </span>
          ) : (
            "-"
          )}
        </Typography>
        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.memberDate}
        >
          {getLang(lang, "label.DATE_JOINED")}&nbsp;
          {isFetching ? (
            <Skeleton variant="text" width={80} height={30} />
          ) : (
            <span className={classes.text}>
              {moment(employeeDetail.joinedDate).format('ll')}
            </span>
          )}
        </Typography>
      </Box>
      <Divider />

      <Box className={classes.box}>
        <Box style={{ display: "flex", placeContent: "center" }}>
          <Box className={classes.roleDisplay}>
            <Box className={classes.roleIcon}>
              <Box mb={2} className={classes.avatar}>
                {isFetching ? (
                  <Skeleton variant="circle" width={56} height={56} />
                ) : (
                  <img
                    src={
                      employeeDetail.imageProfileUrl
                        ? employeeDetail.imageProfileUrl.url
                        : DefaultProfileImg
                    }
                    alt=""
                    className={classes.logo}
                  />
                )}
              </Box>
            </Box>

            <Box ml={4} mt={1} style={{ width: "100%" }}>
              {isFetching ? (
                <Skeleton variant="text" height={40} />
              ) : (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    variant="h5"
                    color="primary"
                    style={{ fontWeight: "500" }}
                  >
                    {employeeDetail.firstName}&nbsp;
                    {employeeDetail.lastName ? employeeDetail.lastName : ""}
                  </Typography>
                  {employeeDetail.nodes.length > 1 ? (
                    <Typography
                      variant="caption"
                      className={classes.multibranch}
                    >
                      {getLang(lang, "label.MULTIBRANCH")}
                    </Typography>
                  ) : null}
                </div>
              )}
              <Box display="flex">
                {isFetching ? (
                  <Skeleton variant="text" width={100} height={30} />
                ) : (
                  <Typography variant="body1" className={classes.textRole}>
                    {employeeDetail.roleName}
                  </Typography>
                )}
                <Box className={classes.listIcon} ml={3}>
                  {isFetching || employeeDetail.nodes === 0 ? (
                    <Skeleton variant="text" width={100} height={30} />
                  ) : (
                    <Typography variant="body1" className={classes.textRole}>
                      {employeeDetail.nodes
                        .map((field) => field.name)
                        .join(", ")}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
}
