import React, { useState } from "react";
import ApprovalWarrantyPanelComponent from "./approvalWarrantyPanal.component";
import { useDispatch, useSelector } from "react-redux";
import {updateWarrantyLog, verifyWarranty} from "modules/warranty/redux";
import { hideBackdrop, showBackdrop } from "modules/backdrop";
import { useHistory } from "react-router";
import { WARRANTEE_LIST_EDIT } from "lib/constants/accessRights";
import ApproveRegistrationDialog from "../../dialog/approveRegistrationDialog";
import RejectRegistrationDialog from "../../dialog/rejectRegistrationDialog";
import VoidWarrantyDialog from "../../dialog/voidWarrantyDialog";
import ReinstateWarrantyDialog from "../../dialog/reinstateWarrantyDialog";

function ApprovalWarrantyPanelContainer({ pageSetting,language }) {
  const dispatch = useDispatch();
  const hasWarrantyEditAccessRight = useSelector(state => state.profile.accessRights.includes(WARRANTEE_LIST_EDIT))
  const consumerWarrantyDetail = useSelector(
    (state) => state.warranty.consumerWarrantyDetail
  );

  const warrantySetting = useSelector(
    (state) => state.warranty.warrantySetting
  );

  const warrantyRegisterForm = useSelector(
    (state) => state.warranty.warrantyRegisterForm
  );
  const lang = useSelector(state => state.constant.languages);

  const [currentActionDialog, setCurrentActionDialog] = useState(null);
  const history = useHistory();

  const handleView = async (value) => {
    window.open(value, "_blank");
  };

  const onCloseActionDialogClick = () => {
    setCurrentActionDialog(null)
  }

  const onApproveConfirmClick = async () => {
    const info = {
      id: consumerWarrantyDetail.id,
      status: "approved",
      reason: "",
      pageSetting: {
        ...pageSetting,
        id: history.location.pathname.substring(
          history.location.pathname.lastIndexOf("/") + 1)
      }
    };
    await dispatch(showBackdrop());
    await dispatch(verifyWarranty(info));
    await dispatch(hideBackdrop());
    setCurrentActionDialog(null);
  }

  const onRejectSubmitClick = async (value) => {
    const info = {
      id: consumerWarrantyDetail.id,
      status: "rejected",
      reason: (value.otherReason.length === 0) ? value.selected : value.otherReason,
      pageSetting: {
        ...pageSetting,
        id: history.location.pathname.substring(
          history.location.pathname.lastIndexOf("/") + 1)
      }
    };
    await dispatch(showBackdrop());
    await dispatch(verifyWarranty(info));
    await dispatch(hideBackdrop());
    setCurrentActionDialog(null);
  }

  const onVoidSubmitClick = async (value) => {
    if (hasWarrantyEditAccessRight) {
      const info = {
        pageSetting: pageSetting,
        enc: consumerWarrantyDetail.enc,
        action: 'void',
        remarks: (value.otherReason.length === 0) ? value.selected : value.otherReason,
      };

      await dispatch(showBackdrop());
      await dispatch(updateWarrantyLog(info));
      await dispatch(hideBackdrop());
      setCurrentActionDialog(null);
    }
  }

  const onReinstateSubmitClick = async (value) => {
    const extendedDate = new Date(consumerWarrantyDetail.endAt)
      .setMonth(new Date(consumerWarrantyDetail.endAt).getMonth() + value.extendedMonth);

    if (hasWarrantyEditAccessRight) {
      const info = {
        pageSetting: pageSetting,
        enc: consumerWarrantyDetail.enc,
        action: 'reinstate',
        remarks: value.toExtend ? `Extend to ${extendedDate}` : "",
        extended: value.toExtend ? value.extendedMonth : 0,
        extendedTo: extendedDate,
        status: consumerWarrantyDetail.beforeVoidedStatus,
        reason: consumerWarrantyDetail.beforeVoidedReason,
      };

      await dispatch(showBackdrop());
      await dispatch(updateWarrantyLog(info));
      await dispatch(hideBackdrop());
      setCurrentActionDialog(null);
    }
  }

  return (
    <>
      <ApprovalWarrantyPanelComponent
        hasWarrantyEditAccessRight={hasWarrantyEditAccessRight}
        consumerWarrantyDetail={consumerWarrantyDetail}
        language={language}
        warrantySetting={warrantySetting}
        warrantyRegisterForm={warrantyRegisterForm}
        onApproveClick={() => setCurrentActionDialog('approve')}
        onRejectClick={() => setCurrentActionDialog('reject')}
        onVoidClick={() => setCurrentActionDialog('void')}
        onReinstateClick={() => setCurrentActionDialog('reinstate')}
        handleView={handleView}
        lang={lang}
      />
      {currentActionDialog === 'approve' && (
        <ApproveRegistrationDialog
          isOpen={currentActionDialog === 'approve'}
          handleClose={onCloseActionDialogClick}
          handleConfirm={onApproveConfirmClick}
        />
      )}
      {currentActionDialog === 'reject' && (
        <RejectRegistrationDialog
          isOpen={currentActionDialog === 'reject'}
          handleClose={onCloseActionDialogClick}
          handleSubmit={onRejectSubmitClick}
        />
      )}
      {currentActionDialog === 'void' && (
        <VoidWarrantyDialog
          isOpen={currentActionDialog === 'void'}
          handleClose={onCloseActionDialogClick}
          handleSubmit={onVoidSubmitClick}
        />
      )}
      {currentActionDialog === 'reinstate' && (
        <ReinstateWarrantyDialog
          isOpen={currentActionDialog === 'reinstate'}
          handleClose={onCloseActionDialogClick}
          handleSubmit={onReinstateSubmitClick}
          consumerWarrantyDetail={consumerWarrantyDetail}
        />
      )}
    </>
  );
}

export default ApprovalWarrantyPanelContainer;
