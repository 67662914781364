import React, { useRef } from "react";
import {
  makeStyles,
  Typography,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  InputAdornment,
  Box,
  Button,
  Divider,
  MenuItem,
  MenuList,
  Checkbox,
} from "@material-ui/core";
import PerfectScrollbar from "react-perfect-scrollbar";
import SearchIcon from "@material-ui/icons/Search";
import Skeleton from "@material-ui/lab/Skeleton";
import InputTextField from "components/input/inputTextField";
import { getLang } from "app/feature/constants";
import DefaultImg from "assets/img/img-default.png";

const useStyle = makeStyles((theme) => ({
  paper: {
    width: 425,
  },
  button: {
    padding: 0,
    minWidth: 0,
  },
  buttonAddBranch: {
    display: "flex",
    padding: 0,
    minWidth: 0,
  },
  greenLabel: {
    color: theme.palette.secondary.main,
  },
  profile: {
    height: 26,
    width: 26,
    minWidth: 26,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& img": {
      height: "100%",
      minHeight: "100%",
      minWidth: "100%",
      objectFit: "cover",
      borderRadius: "50%",
      border: "1px solid #D0D5DD",
    },
  },
  tierIcon: {
    borderRadius: "3px",
    paddingLeft: "0.15em",
    paddingRight: "0.15em",
    display: "flex",
    alignItems: "center",
    position: "absolute",
    top: "-3px",
    left: "-8px",
  },
  tierLabel: {
    color: "#FFFFFF",
    fontSize: "0.6rem",
  },
}));

export default function TransferReceiverSelectPanelComponent({
  selectAll,
  value,
  anchorRef,
  open,
  isOpenList,
  handleClose,
  isFetching,
  transferReceiverDropdown,
  handleSelectAll,
  handleSelectReceiver,
  handleSearch,
  lang,
}) {
  const classes = useStyle();
  const textRef = useRef();

  return (
    <Popper
      open={open}
      style={{ zIndex: 99 }}
      anchorEl={anchorRef.current}
      role={undefined}
      transition
      disablePortal
      placement="bottom-start"
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === "bottom-start" ? "center top" : "center bottom",
          }}
        >
          <Paper className={classes.paper}>
            <ClickAwayListener onClickAway={handleClose}>
              <Box>
                <Box py={0.5}>
                  <InputTextField
                    variant="filled"
                    size="small"
                    fullWidth
                    inputRef={textRef}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          style={{ paddingRight: 6 }}
                        >
                          <Button
                            onClick={() => handleSearch(textRef.current.value)}
                            className={classes.button}
                          >
                            <SearchIcon />
                          </Button>
                        </InputAdornment>
                      ),
                      style: {
                        backgroundColor: "#fff",
                        paddingRight: 0,
                      },
                    }}
                    inputProps={{
                      onKeyDown: (e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                          handleSearch(textRef.current.value);
                          return false;
                        }
                      },
                      style: {
                        padding: 8,
                      },
                    }}
                    placeholder={getLang(lang, "placeholder.SEARCH")}
                  />
                </Box>
                <Divider />
                <PerfectScrollbar>
                  {isFetching ? (
                    <Box p={1}>
                      <Skeleton variant="text" animation="wave" />
                      <Skeleton variant="text" animation="wave" />
                      <Skeleton variant="text" animation="wave" />
                      <Skeleton variant="text" animation="wave" />
                    </Box>
                  ) : transferReceiverDropdown.length > 0 ? (
                    <div>
                      <MenuList
                        autoFocusItem={isOpenList}
                        id="menu-list-grow"
                        onClick={() => {
                          handleSelectAll();
                        }}
                        style={{ padding: 0 }}
                      >
                        <MenuItem style={{ padding: 0 }}>
                          <Checkbox checked={selectAll} />
                          <Typography
                            variant="inherit"
                            noWrap
                            style={{ paddingLeft: "8px" }}
                          >
                            {getLang(lang, "label.ALL")}
                          </Typography>
                        </MenuItem>
                      </MenuList>
                      <Divider />

                      <Box height="25vh" overflow="auto">
                        {transferReceiverDropdown.map((sender, index) => {
                          return (
                            <React.Fragment key={index}>
                              <MenuItem
                                style={{ padding: 0 }}
                                onClick={() => handleSelectReceiver(sender)}
                              >
                                <Checkbox
                                  checked={
                                    !!value.find((v) => v === sender.uuid)
                                  }
                                />
                                <Box display="flex" alignItems="center">
                                  <Box
                                    className={classes.profile}
                                    mx={1}
                                    position="relative"
                                  >
                                    {!!sender.picture ? (
                                      <img
                                        src={sender.picture}
                                        alt={sender.name}
                                      />
                                    ) : (
                                      <img
                                        src={DefaultImg}
                                        alt="default profile"
                                      />
                                    )}
                                  </Box>
                                  <Typography
                                    variant="inherit"
                                    noWrap
                                    style={{ paddingLeft: "8px" }}
                                  >
                                    {sender.name}
                                  </Typography>
                                </Box>
                              </MenuItem>
                            </React.Fragment>
                          );
                        })}
                      </Box>
                    </div>
                  ) : (
                    <Box p={2} style={{ textAlign: "center" }}>
                      <Typography variant="body1">
                        {getLang(lang, "paragraph.NO_RECEIVER")}
                      </Typography>
                    </Box>
                  )}
                </PerfectScrollbar>
              </Box>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}
