import { createSlice } from "@reduxjs/toolkit";
import { REPORTING } from "modules/reporting/constants";

import {
    getCountrySalesData,
    getCitySalesData,
    getDemographicAgeData,
    getDemographicGenderData,
    getDemographicAgeGenderData,
} from "../action";

const initialState = {
    countryDatalist: {},
    cityDatalist: {},
    ageData: {},
    genderData: {},
    ageGenderData: {}
}

const reportingDemographicSlice = createSlice({
    name: REPORTING,
    initialState,
    reducers: {
        initReportingDemographic: (state) => {
            state.countryDatalist = initialState.countryDatalist;
            state.cityDatalist = initialState.cityDatalist;
            state.ageData = initialState.ageData;
            state.genderData = initialState.genderData;
            state.ageGenderData = initialState.ageGenderData;
        }
    },
    extraReducers: {
        // DEMOGRAPHIC -- START
        [getCountrySalesData.fulfilled]: (state, action) => {

            let data = action.payload.data;
            data.isSuccess = action.payload.success;
            data.noResult = (data.rows.length === 0);

            state.countryDatalist = data;
        },
        [getCountrySalesData.rejected]: (state, action) => {
            state.countryDatalist = {
                isSuccess: false,
                noResult: true,
                rows: [],
                total: 0,
            }
        },


        [getCitySalesData.fulfilled]: (state, action) => {
            let data = action.payload.data;
            data.isSuccess = action.payload.success;
            data.noResult = (data.rows.length === 0);


            state.cityDatalist = data;
        },
        [getCitySalesData.rejected]: (state, action) => {
            state.cityDatalist = {
                isSuccess: false,
                noResult: true,
                rows: [],
                total: 0,
            }
        },


        [getDemographicAgeData.fulfilled]: (state, action) => {
            let data = action.payload.data;
            data.isSuccess = action.payload.success;
            data.noResult = (data.datasets.length === 0);
            // data.noResult = false;

            state.ageData = data;
        },
        [getDemographicAgeData.rejected]: (state, action) => {
            state.ageData = {
                isSuccess: false,
                noResult: true,
                labels: [],
                datasets: [],
            }
        },


        [getDemographicGenderData.fulfilled]: (state, action) => {
            let data = action.payload.data;
            data.isSuccess = action.payload.success;
            data.noResult = (data.datasets.length === 0);
            // data.noResult = false;

            state.genderData = data;
        },
        [getDemographicGenderData.rejected]: (state, action) => {
            state.genderData = {
                isSuccess: false,
                noResult: true,
                labels: [],
                datasets: [],
            }
        },


        [getDemographicAgeGenderData.fulfilled]: (state, action) => {
            let data = action.payload.data;
            data.isSuccess = action.payload.success;
            let dataCount = 0;
            data.datasets.forEach((item) => {
                // male , female
                let dataItem = item.data;
                dataItem.forEach((item2) => {
                    dataCount += Math.abs(parseInt(item2));
                });
            });
            data.dataCount = dataCount;
            data.noResult = (dataCount > 0 ? false : true);

            state.ageGenderData = data;
        },
        [getDemographicAgeGenderData.rejected]: (state, action) => {
            state.ageGenderData = {
                isSuccess: false,
                noResult: true,
                labels: [],
                datasets: [],
            }
        },
        // DEMOGRAPHIC -- END
    }
})

export const {
    initReportingDemographic
} = reportingDemographicSlice.actions;

export const reportingDemographicReducer = reportingDemographicSlice.reducer;