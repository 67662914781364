import axios from "axios"

const url = "/distributor/admin/api/v1/inventory/products"

export function getBranchInventory (parameters) {
  return new Promise((resolve, reject) => {
    axios.post(url, {
      length: parameters.length,
      start: parameters.start,
      order: parameters.order,
      order_by: parameters.order_by,
      branch_uuid: parameters.branch,
      dealer_uuid: "",
      filter: {
        search: parameters.filter.search,
        search_by: parameters.filter.search_by,
      },
      to: "branch",
    }, {
      cancelToken: parameters.cancelToken
    })
      .then((response) => resolve(response.data)) 
      .catch((error) =>  reject(error?.response?.data))
  });
};