import axios from "axios";

const getBatchRangeList = async ({ prefix, sn_digit, postfix, serial_type }) => {
    const response = await axios.post(
        '/serialnumber/api/v1/generate/range-list',
        {
            prefix,
            sn_digit,
            postfix,
            serial_type
        });

    return response.data;
};

export default getBatchRangeList;