export const LOGIN = "/trackco/:invitationCode/login";
export const LOGIN_SUCCESS = "/trackco/login/success";
export const SIGN_UP = "/trackco/:invitationCode/signup";
export const INVITATION_CODE = "/trackco/:invitationCode";
export const SIGN_UP_VERIFICATION = "/trackco/signup-verification";
export const  VERIFY_EMAIL ="/trackco/verify-email";
export const FORGOT_PASSWORD = "/trackco/forgot-password";
export const NEW_PASSWORD = "/trackco/new-password";
export const NEW_PASSWORD_SUCCESS = "/trackco/new-password/success";
export const PUBLIC_NOT_FOUND = "/404";
