import React from 'react'
import ViewSurveyFormComponent from './viewSurveyForm.component'
import { useSelector } from "react-redux";

export default function ViewSurveyFormContainer({
  hasSurveyEditAccessRight,
  survey,
  handleClose,
  isLoading,
  handleEdit
}) {
  const lang = useSelector(state => state.constant.languages);

  return (
    <ViewSurveyFormComponent
      hasSurveyEditAccessRight={hasSurveyEditAccessRight}
      survey={survey}
      handleClose={handleClose}
      isLoading={isLoading}
      handleEdit={handleEdit}
      lang={lang}
    />
  )
}
