import React from "react";
import {
  makeStyles,
  Link,
  Box,
  Typography,
  Grid,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Checkbox,
  Button,
  InputAdornment,
  Icon
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { LoginLayout } from '../../components/layout/login';
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import CountrySelect from 'components/select/countrySelect';
import Header from "../../components/header";
import InputTextField from 'components/input/inputTextField';
import SplashScreen from "modules/admin/components/screen/splash";
import { getLang } from "app/feature/constants";
import DatepickerDialog from "../../../../components/dialog/datepicker/datepickerDialog.container";
import moment from "moment/moment";

const style = theme => ({
  header: {
    [theme.breakpoints.up('xs')]: {
      width: "64px",
    },
    '@media(min-width: 400px)': {
      width: "75px",
    },
    '@media(min-width: 500px)': {
      width: "90px",
    },
    [theme.breakpoints.up('sm')]: {
      width: "100px",
    },
    '@media(min-width: 700px)': {
      width: "107px",
    },
    '@media(min-width: 1000px)': {
      width: "85px",
    },
    '@media(min-width: 1100px)': {
      width: "95px",
    },
    '@media(min-width: 1200px)': {
      width: "100px",
    },
    [theme.breakpoints.up('lg')]: {
      width: "107px",
    },
  },
  title: {
    fontWeight: "700",
    fontFamily: "Montserrat",
    [theme.breakpoints.up('xs')]: {
      fontSize: "18px",
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: "24px",
    },

  },
  label: {
    fontFamily: "Open Sans",
  },
  root: {
    width: "100%",
  },
  inputRoot: {
    borderRadius: "4px",
    backgroundColor: "#ffffff",
    border: "1px solid #e6e6e6",
    boxShadow: "0px 0px 10px 2px rgba(118, 156, 214, 0.1)",
  },
  input: {
    paddingTop: "8px !important",
    paddingBottom: "7px !important",
  },
  genderLabel: {
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: 500
  },
  button: {
    width: "100%",
    borderRadius: "42px",
    textTransform: "capitalize",
    fontSize: "16px"
  },
  inputFirstName: {
    paddingRight: "8px",
    marginBottom: "20px",
    [theme.breakpoints.up('xs')]: {
      paddingRight: "0px",
    },
    [theme.breakpoints.up('sm')]: {
      paddingRight: "8px",
    },
  },
  inputLastName: {
    paddingLeft: "8px",
    marginBottom: "20px",
    [theme.breakpoints.up('xs')]: {
      paddingLeft: "0px",
    },
    [theme.breakpoints.up('sm')]: {
      paddingLeft: "8px",
    },
  },
  profile: {
    position: "relative",
    flexDirection: "column",

    [theme.breakpoints.up('xs')]: {
      top: "0.8%",
    },
    '@media(min-width: 400px)': {
      top: "1%",
    },
    '@media(min-width: 500px)': {
      top: "1.6%",
    },
    [theme.breakpoints.up('sm')]: {
      top: "2.6%",
    },
    '@media(min-width: 700px)': {
      top: "3.6%",
    },
    '@media(min-width: 800px)': {
      top: "4.6%",
    },
    '@media(min-width: 900px)': {
      top: "5.6%",
    },
    [theme.breakpoints.up('md')]: {
      top: "6.3%",
    },
    '@media(min-width: 1000px)': {
      top: "2%",
    },
    '@media(min-width: 1200px)': {
      top: "2.6%",
    },
    [theme.breakpoints.up('lg')]: {
      top: "3.6%",
    },
    '@media(min-width: 1400px)': {
      top: "4%",
    },
    '@media(min-width: 1500px)': {
      top: "4.6%",
    },
    '@media(min-width: 1600px)': {
      top: "5.2%",
    },
    '@media(min-width: 1700px)': {
      top: "5.8%",
    },
    '@media(min-width: 1800px)': {
      top: "6.4%",
    },
    '@media(min-width: 1900px)': {
      top: "7%",
    },
  },
  profilePhoto: {
    width: "100%",
    [theme.breakpoints.up('xs')]: {
      width: "50px",
    },
    '@media(min-width: 400px)': {
      width: "65px",
    },
    '@media(min-width: 500px)': {
      width: "70px",
    },
    [theme.breakpoints.up('sm')]: {
      width: "75px",
    },
    '@media(min-width: 700px)': {
      width: "80px",
    },
    '@media(min-width: 1000px)': {
      width: "65px",
    },
    '@media(min-width: 1100px)': {
      width: "75px",
    },
    '@media(min-width: 1200px)': {
      width: "80px",
    },
  },
});

const useStyle = makeStyles(style);

export default function SignUpPage({
  isPasswordShown,
  isConfirmPasswordShow,
  handleClickShowPassword,
  handleMouseDownPassword,
  invitationCode,
  onChangeGender,
  handleSubmit,
  branchDetail,
  distributorDetail,
  isValidateInvitationLoading,
  isInvitationCodeValidated,
  languages,
  isDatePickerOpen,
  setIsDatePickerOpen,
  lang,
  enableResent,
  counter,
  getOTP,
  displayOTPVerification,
  showOTP,
  disableInput,
  verifyOTP,
  OTP,
  setOTP,
  verifyPhone
}) {
  const classes = useStyle();
  const USER_AGREEMENT_URL = process.env.REACT_APP_KOOD_ASIA_USER_AGREEMENT_URL;
  // const isLoading = true;

  return (
    (!isValidateInvitationLoading && isInvitationCodeValidated) ? (
      <LoginLayout
        header={<Header justifyContent="start" imgClass={classes.header} />}
        profileStyle={classes.profile}
        profilePhotoStyle={classes.profilePhoto}
        isLoading={isValidateInvitationLoading}
        branch={branchDetail}
        distributor={distributorDetail}
        lang={lang}
      >
        <Box>

          <Formik
            initialValues={{
              email: "",
              firstName: "",
              lastName: "",
              phoneNo: "",
              countryCode: {},
              gender: "",
              dateOfBirth: "",
              address1: "",
              address2: "",
              city: "",
              postalCode: "",
              state: "",
              country: "",
              password: "",
              confirmPassword: "",
              tnc: false,
              showPassword: false
            }}

            validationSchema={Yup.object({
              email: Yup.string().email(getLang(lang, "message.error.FORM_VALIDATE_EMAIL")).required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
              firstName: Yup.string().trim().max(100, getLang(lang, "message.error.FORM_VALIDATE_MAX", { value: 100 })).required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
              lastName: Yup.string().trim().max(100, getLang(lang, "message.error.FORM_VALIDATE_MAX", { value: 100 })).required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
              countryCode: Yup.object().required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")).nullable(),
              phoneNo: Yup.string().trim()
                .min(6, getLang(lang, "message.error.FORM_VALIDATE_MIN_NUMBER", { value: 6 }))
                .max(20, getLang(lang, "message.error.FORM_VALIDATE_MAX", { value: getLang(lang, "message.error.NUMBERS_20") }))
                .matches(/^[1-9]{1}[0-9]{5,19}$/, getLang(lang, "message.error.FORM_VALIDATE_PHONE"))
                .required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
              gender: Yup.string().required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
              dateOfBirth: Yup.string().required(" "),
              address1: Yup.string().trim().required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
              city: Yup.string().trim().required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
              postalCode: Yup.string()
                .trim()
                .max(10, getLang(lang, "message.error.FORM_VALIDATE_MAX", { value: 10 }))
                .required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED"))
                .matches(/^[0-9a-zA-Z-_ ]*$/, getLang(lang, "message.error.INVALID_POSTCODE")),
              state: Yup.string().trim().required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
              country: Yup.object().required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")).nullable(),
              password: Yup.string().required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED"))
                .min(8, getLang(lang, "message.error.FORM_VALIDATE_MIN_NUMBER", { value: 8 }))
                .matches(/^(?=.*[a-z])/, getLang(lang, "message.error.FORM_VALIDATE_MATCH", { value: getLang(lang, "message.error.LOWERCASE_CHARACTER_1") }))
                .matches(/^(?=.*[A-Z])/, getLang(lang, "message.error.FORM_VALIDATE_MATCH", { value: getLang(lang, "message.error.UPPERCASE_CHARACTER_1") }))
                .matches(/[!@#$%^&~`*()_+\-={}[\]:";'<>,.?|]/, getLang(lang, "FORM_VALIDATE_MATCH", { value: getLang(lang, "message.error.SPECIAL_CHARACTER_1") }))
                .matches(/^(?=.*[0-9])/, getLang(lang, "message.error.FORM_VALIDATE_MATCH", { value: getLang(lang, "message.error.NUMBER_1") })),
              confirmPassword: Yup.string()
                .oneOf([Yup.ref("password"), null], getLang(lang, "message.error.FORM_VALIDATE_PASSWORD"))
                .required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
              tnc: Yup.boolean().required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")).oneOf([true], getLang(lang, "message.error.MUST_AGREE_WITH_TNC")),
            })}

            onSubmit={handleSubmit}
          >
            {(formik) => (
              <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
                {/* <Box style={{ marginTop: "40px", marginBottom: "40px" }}>
                  <Typography variant='h5' className={classes.title}>User verification </Typography>
                </Box>
                <Box style={{
                  display: "flex",
                  paddingBottom: "12px"
                }}>
                  <CountrySelect
                    required
                    type='phone'
                    textFieldProps={{
                      style: { width: "175px", marginRight: "16px" },
                      variant: "filled",
                      size: "small",
                      margin: "none",
                      error: formik.errors.countryCode && formik.touched.countryCode ? true : false,
                      helperText: ErrorMessage({ name: "countryCode" })
                    }}
                    formProps={{
                      ...formik.getFieldProps("countryCode"),
                      value: formik.values.phoneCode,
                      onChange: (e, newValue) => {
                        formik.setFieldValue("countryCode", newValue)
                      },
                      disabled: formik.isSubmitting || disableInput,
                    }}
                  />
                  <InputTextField
                    onKeyPress={(e) => {
                      if (!/[0-9+-]/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    className={classes.root}
                    variant="filled"
                    size="small"
                    margin="none"
                    InputProps={{
                      padding: "none",
                      disableUnderline: true,
                      margin: "none",
                      classes: { input: classes.input, root: classes.inputRoot },
                      disabled: formik.isSubmitting || disableInput,
                      endAdornment: disableInput && (
                        <InputAdornment position="center">

                          <Icon style={{ color: 'green', padding: 'none' }}>done</Icon>

                        </InputAdornment>
                      ),
                    }}
                    placeholder={getLang(lang, 'placeholder.MOBILE_NUMBER')}
                    {...formik.getFieldProps("phoneNo")}
                    error={formik.touched.phoneNo && formik.errors.phoneNo ? true : false}
                    helperText={ErrorMessage({
                      name: "phoneNo"
                    })}
                  />

                </Box>
                {!disableInput && <Box
                  style={showOTP ? {
                    display: "grid",
                    gridTemplateColumns: "1.7fr 1fr 1.8fr",
                    alignItems: "start",
                  }
                    : {
                      display: "flex",
                      justifyContent: "flex-end"
                    }}>

                  {showOTP && (<> <InputTextField
                    onKeyPress={(e) => {
                      if (!/[0-9+-]/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    className={classes.root}
                    variant="filled"
                    size="small"
                    margin="none"
                    InputProps={{
                      disableUnderline: true,
                      margin: "none",
                      classes: { input: classes.input, root: classes.inputRoot },

                    }}
                    onChange={(value) => setOTP(value.target.value)}
                    placeholder="Enter OTP"


                  />
                    <Button
                      variant='contained'
                      size='large'
                      color='primary'
                      className={classes.button}
                      disabled={formik.isSubmitting || isValidateInvitationLoading}
                      style={{
                        width: "auto",
                        marginLeft: "20px",
                        marginRight: "20px"
                      }}
                      onClick={() => verifyOTP(formik.values.countryCode.phone, formik.values.phoneNo)}
                    >
                      Verify
                    </Button>
                  </>)}
                  <Button
                    variant='contained'
                    size='large'
                    color='primary'
                    onClick={() => {
                      verifyPhone(formik.values.countryCode.phone, formik.values.phoneNo);
                    }}
                    className={classes.button}
                    disabled={formik.isSubmitting || isValidateInvitationLoading || !enableResent}
                    style={{ width: "auto" }}
                  >
                    {!disableInput && !enableResent ? (`Resend OTP in ${counter} secs.`) : 'Get OTP'}
                  </Button>
                </Box>} */}

                <>
                  <Box style={{ marginTop: "40px", marginBottom: "40px" }}>
                    <Typography variant='h5' className={classes.title}>{getLang(lang, 'label.CREATE_YOUR_ACCOUNT')}</Typography>
                  </Box>
                  <Box style={{ marginBottom: "20px" }} >
                    <Typography variant='body2' className={classes.label}>{getLang(lang, 'label.EMAIL')} <Typography style={{ color: "#DE350B", display: 'inline-block' }}>*</Typography></Typography>
                    <InputTextField
                      className={classes.root}
                      variant="filled"
                      size="small"
                      margin="none"
                      InputProps={{
                        disableUnderline: true,
                        margin: "none",
                        classes: { input: classes.input, root: classes.inputRoot },
                        disabled: formik.isSubmitting,
                      }}
                      placeholder={getLang(lang, 'placeholder.EMAIL')}
                      {...formik.getFieldProps("email")}
                      error={formik.touched.email && formik.errors.email ? true : false}
                      helperText={ErrorMessage({
                        name: "email"
                      })}
                    />
                  </Box>
                  <Grid container>
                    <Grid item xs={12} sm={6} md={6} className={classes.inputFirstName}>
                      <Typography variant='body2' className={classes.label}>{getLang(lang, 'label.FIRST_NAME')} <Typography style={{ color: "#DE350B", display: 'inline-block' }}>*</Typography></Typography>
                      <InputTextField
                        className={classes.root}
                        variant="filled"
                        size="small"
                        margin="none"
                        InputProps={{
                          disableUnderline: true,
                          margin: "none",
                          classes: { input: classes.input, root: classes.inputRoot },
                          disabled: formik.isSubmitting,
                        }}
                        placeholder={getLang(lang, 'placeholder.FIRST_NAME')}
                        {...formik.getFieldProps("firstName")}
                        error={formik.touched.firstName && formik.errors.firstName ? true : false}
                        helperText={ErrorMessage({
                          name: "firstName"
                        })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} className={classes.inputLastName}>
                      <Typography variant='body2' className={classes.label}>{getLang(lang, 'label.LAST_NAME')} <Typography style={{ color: "#DE350B", display: 'inline-block' }}>*</Typography></Typography>
                      <InputTextField
                        className={classes.root}
                        variant="filled"
                        size="small"
                        margin="none"
                        InputProps={{
                          disableUnderline: true,
                          margin: "none",
                          classes: { input: classes.input, root: classes.inputRoot },
                          disabled: formik.isSubmitting,
                        }}
                        placeholder={getLang(lang, 'placeholder.LAST_NAME')}
                        {...formik.getFieldProps("lastName")}
                        error={formik.touched.lastName && formik.errors.lastName ? true : false}
                        helperText={ErrorMessage({
                          name: "lastName"
                        })}
                      />
                    </Grid>
                  </Grid>
                  <Box style={{
                    display: "flex",
                    paddingBottom: "12px"
                  }}>
                    <CountrySelect
                      required
                      type='phone'
                      textFieldProps={{
                        style: { width: "175px", marginRight: "16px" },
                        variant: "filled",
                        size: "small",
                        margin: "none",
                        error: formik.errors.countryCode && formik.touched.countryCode ? true : false,
                        helperText: ErrorMessage({ name: "countryCode" })
                      }}
                      formProps={{
                        ...formik.getFieldProps("countryCode"),
                        value: formik.values.phoneCode,
                        onChange: (e, newValue) => {
                          formik.setFieldValue("countryCode", newValue)
                        },
                        disabled: formik.isSubmitting,
                      }}
                    />
                    <InputTextField
                      onKeyPress={(e) => {
                        if (!/[0-9+-]/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      className={classes.root}
                      variant="filled"
                      size="small"
                      margin="none"
                      InputProps={{
                        padding: "none",
                        disableUnderline: true,
                        margin: "none",
                        classes: { input: classes.input, root: classes.inputRoot },
                        disabled: formik.isSubmitting,
                        endAdornment: disableInput && (
                          <InputAdornment position="center">

                            <Icon style={{ color: 'green', padding: 'none' }}>done</Icon>

                          </InputAdornment>
                        ),
                      }}
                      placeholder={getLang(lang, 'placeholder.MOBILE_NUMBER')}
                      {...formik.getFieldProps("phoneNo")}
                      error={formik.touched.phoneNo && formik.errors.phoneNo ? true : false}
                      helperText={ErrorMessage({
                        name: "phoneNo"
                      })}
                    />

                  </Box>
                  <Box style={{ marginBottom: "20px" }}>
                    <Typography variant='body2' className={classes.label}>{getLang(lang, 'label.GENDER')} <Typography style={{ color: "#DE350B", display: 'inline-block' }}>*</Typography></Typography>
                    <FormControl error={formik.touched.gender && formik.errors.gender ? true : false}>
                      <RadioGroup row={true} name='gender' value={formik.values.gender} onChange={(event) => onChangeGender(event, formik)}>
                        <FormControlLabel
                          value="male"
                          classes={{ label: classes.genderLabel }}
                          control={<Radio size='small' disabled={formik.isSubmitting} />}
                          label={getLang(lang, 'label.MALE')}
                        />
                        <FormControlLabel
                          value="female"
                          classes={{ label: classes.genderLabel }}
                          control={<Radio size='small' disabled={formik.isSubmitting} />}
                          label={getLang(lang, 'label.FEMALE')}
                        />
                        <FormControlLabel
                          value="others"
                          classes={{ label: classes.genderLabel }}
                          control={<Radio size='small' disabled={formik.isSubmitting} />}
                          label="Others"
                        />
                      </RadioGroup>
                    </FormControl>
                    <ErrorMessage name='gender' render={errorMsg => (
                      <Typography style={{ color: "#f44336", fontSize: "0.75rem", fontWeight: 400 }}>{errorMsg}</Typography>
                    )} />
                  </Box>
                  <Box style={{ marginBottom: "20px" }} >
                    <Typography variant='body2' className={classes.label}>{getLang(lang, 'label.DATE_OF_BIRTH')} <Typography style={{ color: "#DE350B", display: 'inline-block' }}>*</Typography></Typography>
                    <DatepickerDialog
                      handleChange={(value) => {
                        formik.setFieldValue("dateOfBirth", value.format("DD-MM-YYYY"))
                      }}
                      value={moment().subtract(18, 'years')}
                      label={getLang(lang, 'placeholder.BIRTHDAY')}
                      disabled={formik.isSubmitting}
                      disableFuture={true}
                      displayFormat="DD MMMM YYYY"
                      style={{
                        height: 43
                      }}
                      inputProps={{
                        style: {
                          paddingTop: 14,
                        }
                      }}
                    />

                    {(!!(formik.touched.dateOfBirth && formik.errors.dateOfBirth)) && (
                      <Box>
                        <Typography style={{ color: "#f44336", fontSize: "0.75rem", fontWeight: 400 }}>{getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")}</Typography>
                      </Box>
                    )}
                  </Box>
                  <Box style={{ marginBottom: "20px" }} >
                    <Typography variant='body2' className={classes.label}>{getLang(lang, 'label.ADDRESS_LINE_1')}<Typography style={{ color: "#DE350B", display: 'inline-block' }}>*</Typography></Typography>
                    <InputTextField
                      className={classes.root}
                      variant="filled"
                      size="small"
                      margin="none"
                      InputProps={{
                        disableUnderline: true,
                        margin: "none",
                        classes: { input: classes.input, root: classes.inputRoot },
                        disabled: formik.isSubmitting,
                      }}
                      placeholder={getLang(lang, 'placeholder.ADDRESS_LINE_1')}
                      {...formik.getFieldProps("address1")}
                      error={formik.touched.address1 && formik.errors.address1 ? true : false}
                      helperText={ErrorMessage({
                        name: "address1"
                      })}
                    />
                  </Box>
                  <Box style={{ marginBottom: "20px" }} >
                    <Typography variant='body2' className={classes.label}>{getLang(lang, 'label.ADDRESS_LINE_2')}</Typography>
                    <InputTextField
                      className={classes.root}
                      variant="filled"
                      size="small"
                      margin="none"
                      InputProps={{
                        disableUnderline: true,
                        margin: "none",
                        classes: { input: classes.input, root: classes.inputRoot },
                        disabled: formik.isSubmitting,
                      }}
                      placeholder={getLang(lang, 'placeholder.ADDRESS_LINE_2')}
                      {...formik.getFieldProps("address2")}
                      error={formik.touched.address2 && formik.errors.address2 ? true : false}
                      helperText={ErrorMessage({
                        name: "address2"
                      })}
                    />
                  </Box>
                  <Grid container>
                    <Grid item xs={12} sm={6} md={6} className={classes.inputFirstName}>
                      <Typography variant='body2' className={classes.label}>{getLang(lang, 'label.CITY')} <Typography style={{ color: "#DE350B", display: 'inline-block' }}>*</Typography></Typography>
                      <InputTextField
                        className={classes.root}
                        variant="filled"
                        size="small"
                        margin="none"
                        InputProps={{
                          disableUnderline: true,
                          margin: "none",
                          classes: { input: classes.input, root: classes.inputRoot },
                          disabled: formik.isSubmitting,
                        }}
                        placeholder={getLang(lang, 'placeholder.CITY')}
                        {...formik.getFieldProps("city")}
                        error={formik.touched.city && formik.errors.city ? true : false}
                        helperText={ErrorMessage({
                          name: "city"
                        })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} className={classes.inputLastName}>
                      <Typography variant='body2' className={classes.label}>{getLang(lang, 'label.POSTAL_CODE')} <Typography style={{ color: "#DE350B", display: 'inline-block' }}>*</Typography></Typography>
                      <InputTextField
                        className={classes.root}
                        variant="filled"
                        size="small"
                        margin="none"
                        InputProps={{
                          disableUnderline: true,
                          margin: "none",
                          classes: { input: classes.input, root: classes.inputRoot },
                          disabled: formik.isSubmitting,
                        }}
                        placeholder={getLang(lang, 'placeholder.POSTAL_CODE')}
                        {...formik.getFieldProps("postalCode")}
                        error={formik.touched.postalCode && formik.errors.postalCode ? true : false}
                        helperText={ErrorMessage({
                          name: "postalCode"
                        })}
                      />
                    </Grid>
                  </Grid>
                  <Box style={{ marginBottom: "20px" }} >
                    <Typography variant='body2' className={classes.label}>{getLang(lang, 'label.STATE')} <Typography style={{ color: "#DE350B", display: 'inline-block' }}>*</Typography></Typography>
                    <InputTextField
                      className={classes.root}
                      variant="filled"
                      size="small"
                      margin="none"
                      InputProps={{
                        disableUnderline: true,
                        margin: "none",
                        classes: { input: classes.input, root: classes.inputRoot },
                        disabled: formik.isSubmitting,
                      }}
                      placeholder={getLang(lang, 'placeholder.STATE')}
                      {...formik.getFieldProps("state")}
                      error={formik.touched.state && formik.errors.state ? true : false}
                      helperText={ErrorMessage({
                        name: "state"
                      })}
                    />
                  </Box>
                  <Box style={{ marginBottom: "20px" }} >
                    <Typography variant='body2' className={classes.label}>{getLang(lang, 'label.COUNTRY')} <Typography style={{ color: "#DE350B", display: 'inline-block' }}>*</Typography></Typography>
                    <CountrySelect
                      required
                      type='countryOnly'
                      textFieldProps={{
                        fullWidth: true,
                        variant: "filled",
                        size: "small",
                        margin: "dense",
                        error: formik.errors.country && formik.touched.country ? true : false,
                        helperText: ErrorMessage({ name: "country" })
                      }}
                      formProps={{
                        ...formik.getFieldProps("country"),
                        value: formik.values.country,
                        onChange: (e, newValue) => {
                          formik.setFieldValue("country", newValue);
                        },
                        disabled: formik.isSubmitting,
                        disableClearable: true
                      }}
                    />
                  </Box>
                  <Box style={{ marginBottom: "20px" }}>
                    <Typography variant='body2' className={classes.label}>{getLang(lang, 'label.PASSWORD')} <Typography style={{ color: "#DE350B", display: 'inline-block' }}>*</Typography></Typography>
                    <InputTextField
                      className={classes.root}
                      variant="filled"
                      size="small"
                      margin="none"
                      type={isPasswordShown ? "text" : "password"}
                      InputProps={{
                        disableUnderline: true,
                        margin: "none",
                        classes: { input: classes.input, root: classes.inputRoot },
                        disabled: formik.isSubmitting,
                      }}
                      placeholder={getLang(lang, 'placeholder.PASSWORD')}
                      {...formik.getFieldProps("password")}
                      error={formik.touched.password && formik.errors.password ? true : false}
                      helperText={ErrorMessage({
                        name: "password"
                      })}
                    />
                  </Box>
                  <Box style={{ marginBwottom: "40px" }}>
                    <Typography variant='body2' className={classes.label}>{getLang(lang, 'label.CONFIRM_PASSWORD')} <Typography style={{ color: "#DE350B", display: 'inline-block' }}>*</Typography></Typography>
                    <InputTextField
                      className={classes.root}
                      variant="filled"
                      size="small"
                      margin="none"
                      type={isConfirmPasswordShow ? "text" : "password"}
                      InputProps={{
                        disableUnderline: true,
                        margin: "none",
                        classes: { input: classes.input, root: classes.inputRoot },
                        disabled: formik.isSubmitting,
                      }}
                      placeholder={getLang(lang, 'placeholder.CONFIRM_PASSWORD')}
                      {...formik.getFieldProps("confirmPassword")}
                      error={formik.touched.confirmPassword && formik.errors.confirmPassword ? true : false}
                      helperText={ErrorMessage({
                        name: "confirmPassword"
                      })}
                    />
                  </Box>
                  <Box style={{ marginTop: "12px", marginBottom: "12px", display: "flex", alignItems: "center" }}>
                    <Checkbox
                      checked={formik.values.showPassword}
                      onChange={(event) => {
                        formik.setFieldValue("showPassword", event.target.checked)
                        handleClickShowPassword("password");
                        handleClickShowPassword("confirmPassword")
                      }}
                    />
                    <Typography variant='subtitle2' style={{ fontFamily: "Open Sans" }}> {getLang(lang, 'label.SHOW_PASSWORD')}</Typography>
                  </Box>
                  <Box style={{ marginBottom: "24px" }}>
                    <Box style={{ display: "flex", alignItems: "center" }}>
                      <Checkbox
                        onChange={(event) => formik.setFieldValue("tnc", event.target.checked)}
                      />
                      <Typography variant='subtitle2' style={{ fontFamily: "Open Sans" }}>{getLang(lang, 'paragraph.I_AGREE_WITH')}&nbsp;</Typography>
                      <Typography
                        variant='subtitle2'
                        style={{ fontFamily: "Open Sans" }}
                      >
                        <Link
                          color="secondary"
                          href={USER_AGREEMENT_URL}
                          target="_blank"
                        >
                          {getLang(lang, 'paragraph.TERMS_AND_CONDITIONS')}
                        </Link>
                      </Typography>
                    </Box>
                    <ErrorMessage name='tnc' render={errorMsg => (
                      <Typography style={{ color: "#f44336", fontSize: "0.75rem", fontWeight: 400 }}>{errorMsg}</Typography>
                    )} />
                  </Box>
                  <Box>
                    <Button
                      variant='contained'
                      type='submit'
                      size='large'
                      color='primary'
                      className={classes.button}
                      disabled={formik.isSubmitting || isValidateInvitationLoading}
                    >
                      {getLang(lang, 'label.REGISTER')}
                    </Button>
                  </Box>
                  <Box style={{ marginTop: '20px', marginBottom: '40px' }}>
                    <Typography variant='subtitle2'>
                      {getLang(lang, 'paragraph.ALREADY_HAVE_ACCOUNT')}
                      <Link
                        component={RouterLink}
                        variant="subtitle2"
                        color='secondary'
                        to={`/trackco/${invitationCode}/login`}
                        style={{ marginLeft: "8px" }}
                      >
                        {getLang(lang, 'paragraph.JOIN_WITH_EXISTING_ACCOUNT')}
                      </Link>
                    </Typography>
                  </Box>
                </>

              </form>
            )}
          </Formik>
        </Box>
      </LoginLayout >
    ) : (
      <SplashScreen />
    )
  );
}
