import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectLoading } from "modules/notification";
import { getProductListingByDealer } from "modules/stockflow/redux/action/stockflowInventory.action";
import SummaryByDealerComponent from "./summary-by-dealer.component";
import {
  changeBulkSelectedAvailableProduct,
  changeBulkSelectedProduct,
  changeSelectedProduct,
  resetProductList,
} from "modules/stockflow/redux";
import { generatePaginationOptions, updateUrlQueryParam } from "lib/helper";
import { exportProductSummaryByDealer } from "modules/stockflow/redux";
import { useHistory, useLocation } from "react-router-dom";
import { STOCKFLOW_INVENTORY_VIEW } from "lib/constants/accessRights";
import { getAllProducts } from "modules/product-mgmt/redux/action/products.action";
import axios from "axios";
import qs from "query-string";

function SummaryByDealerContainer() {
  const history = useHistory();
  const dispatch = useDispatch();

  const hasStockflowInventoryViewAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(STOCKFLOW_INVENTORY_VIEW)
  );
  const lang = useSelector((state) => state.constant.languages);
  const token = useSelector((state) => state.session.accessToken);
   const productDropdown = useSelector(state => state.products.allProducts);
  const isFetching = useSelector((state) =>
    selectLoading(state, getProductListingByDealer.typePrefix)
  );
  const totalFiltered = useSelector(
    (state) => state.stockflowInventory.totalFiltered
  );
  const totalRecords = useSelector(
    (state) => state.stockflowInventory.totalRecords
  );
  const products = useSelector((state) => state.stockflowInventory.products) ?? [];
  const selectedIds = useSelector(
    (state) => state.stockflowInventory.selectedId
  );
  const isSelectAll = useSelector(
    (state) => state.stockflowInventory.isSelectAll
  );
  const isError = useSelector(
    (state) => state.stockflowInventory.isLoadingProductListError
  );
  const isSearchResult = products.length < totalRecords;
  const [paginationOptions, setPaginationOptions] = useState([]);
  const [isExporting, setIsExporting] = useState(false);
  const [isFilterUpdated, setIsFilterUpdated] = useState(false);
  const [tableFilter, setTableFilter] = useState({
    page: 0,
    rowsPerPage: 25,
    search: "",
    searchColumn: "",
    nodeIds: [],
    branch: [],
    products: [],
    dealer: [],
    order: "desc",
    order_by: "current_stock"
  });
  const [isReloaded, setIsReloaded] = useState(false);

  const query = new URLSearchParams(window.location.search);
  const tableFilterInitialRef = useRef(true);

  const location = useLocation();
  const qsParams = qs.parse(location.search);
  const targetDealer = qsParams.dealer || "";

  useEffect(() => {
    dispatch(getAllProducts());
    return () => {
      dispatch(resetProductList());
    };
  }, [dispatch]);

  useEffect(() => {
    const isFilter = query.has("is_filter");
    let filter = sessionStorage.getItem("inventory_product_filter");

    let temp = { ...tableFilter };
    if (isFilter && filter) {
      temp = JSON.parse(filter);
      setIsFilterUpdated(true);
    }

    if (isFilter) {
      updateUrlQueryParam(null, ["is_filter"]);
    }
    sessionStorage.removeItem("inventory_product_filter");

    setTableFilter(temp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    if (!tableFilterInitialRef.current) {
      let productUuid = "";
      let branchUuid = "";
      let dealerUuid = (tableFilter.products.length === 0) ? targetDealer : "";

      if (tableFilter.products.length > 0){
        tableFilter.products.forEach((product) => {
          productUuid += (product.id + ",");
        });
      }
      productUuid = productUuid.replace(/,*$/, '');

      if (tableFilter.branch.length > 0){
        tableFilter.branch.forEach((branch) => {
          branchUuid += (branch.node_id + ",");
        });
      }
      branchUuid = branchUuid.replace(/,*$/, '');

      if (tableFilter.dealer.length > 0){
        tableFilter.dealer.forEach((dealer) => {
          dealerUuid += (dealer.uuid + ",");
        });
      }
      dealerUuid = dealerUuid.replace(/,*$/, '');

      dispatch(
        getProductListingByDealer({
          length: tableFilter.rowsPerPage,
          start: tableFilter.rowsPerPage * tableFilter.page,
          dealer: dealerUuid,
          branch: branchUuid,
          order: tableFilter.order,
          order_by: tableFilter.order_by,
          cancelToken: cancelToken.token,
          filter: {
            search: productUuid,
            search_by: tableFilter.searchColumn
          },
        })
      );
    }
    tableFilterInitialRef.current = false;

    return () => {
      // Abort pending request if change page
      cancelToken.cancel('Page Changed');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableFilter, isReloaded]);

  const handleReload = () => {
    setIsReloaded(!isReloaded);
  }

  useEffect(() => {
    setPaginationOptions(generatePaginationOptions(totalFiltered));
  }, [totalFiltered]);

  const handleChangePage = (event, newPage) => {
    let newData = {
      ...tableFilter,
      page: newPage,
    };
    updateUrlQueryParam({
      page: newData.page + 1,
      length: newData.rowsPerPage,
    });
    setIsFilterUpdated(true);
    setTableFilter(newData);
  };

  const handleChangeRowsPerPage = (event) => {
    let newData = {
      ...tableFilter,
      page: 0,
      rowsPerPage: event.target.value,
    };
    updateUrlQueryParam({
      page: newData.page + 1,
      length: newData.rowsPerPage,
    });
    setIsFilterUpdated(true);
    setTableFilter(newData);
  };

  const handleSearch = (filter) => {
    if (query.has('dealer')) {
      query.delete('dealer');
      history.replace({
        search: query.toString(),
      })
    }

    let newData = {
      ...tableFilter,
      page: 0,
      search: filter.search,
      searchColumn: filter.searchColumn,
      nodeIds:
        filter.dealer.length > 0 ? filter.dealer.map((br) => br.node_id) : [],
      dealer: filter.dealer,
      branch: filter.branch,
      products: filter.products,
    };
    setIsFilterUpdated(true);
    setTableFilter(newData);
  };

  const clearSearch = () => {
    if (query.has('dealer')) {
      query.delete('dealer');
      history.replace({
        search: query.toString(),
      })
    }

    let newData = {
      ...tableFilter,
      page: 0,
      search: "",
      searchColumn: "",
      branch: [],
      nodeIds: [],
      dealer: "",
      products: [],
    };

    setIsFilterUpdated(false);
    setTableFilter(newData);
  };

  const handleSelectProduct = (index, isSelected) => {
    dispatch(changeSelectedProduct({ index, isSelected }));
  };

  const handleSelectAvailable = () => {
    dispatch(changeBulkSelectedAvailableProduct({ value: true }));
  };

  const handleSelectAll = () => {
    dispatch(changeBulkSelectedProduct({ value: true }));
  };

  const handleDeselectAll = () => {
    dispatch(changeBulkSelectedProduct({ value: false }));
  };

  const handleExport = (type) => {
    setIsExporting(false);
    
    let productUuid = "";
    let branchUuid = "";
    let dealerUuid = "";

    if (tableFilter.products.length > 0){
      tableFilter.products.forEach((product) => {
        productUuid += (product.id + ",");
      });
    }
    productUuid = productUuid.replace(/,*$/, '');

    if (tableFilter.branch.length > 0){
      tableFilter.branch.forEach((branch) => {
        branchUuid += (branch.node_id + ",");
      });
    }
    branchUuid = branchUuid.replace(/,*$/, '');

    if (tableFilter.dealer.length > 0){
      tableFilter.dealer.forEach((dealer) => {
        dealerUuid += (dealer.uuid + ",");
      });
    }
    dealerUuid = dealerUuid.replace(/,*$/, '');

    const info = {
      token: token,
      data: {
        product: productUuid,
        node: branchUuid,
        dealer: dealerUuid,
        fileType: type
      }
    };
    dispatch(exportProductSummaryByDealer(info));

    setIsExporting(false);
  };

  const handleViewDetail = (url) => {
    if (isFilterUpdated) {
      updateUrlQueryParam({ is_filter: true });
      sessionStorage.setItem(
        "inventory_product_filter",
        JSON.stringify(tableFilter)
      );
    }
    history.push(url);
  };

  const changeSequenceOrder = (order) => {
    let newData = {
      ...tableFilter,
      order,
    }
    setIsFilterUpdated(true)
    setTableFilter(newData)
  }
  

  return (
    <>
      <SummaryByDealerComponent
        hasStockflowInventoryViewAccessRight={
          hasStockflowInventoryViewAccessRight
        }
        products={products}
        isFetching={isFetching}
        selectedIds={selectedIds}
        isSelectAll={isSelectAll}
        selectedCount={
          !isSelectAll ? selectedIds.length : totalFiltered - selectedIds.length
        }
        isError={isError}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        page={tableFilter.page}
        rowsPerPage={tableFilter.rowsPerPage}
        totalFiltered={totalFiltered}
        handleSearch={handleSearch}
        clearSearch={clearSearch}
        handleReload={handleReload}
        isSearchResult={isSearchResult}
        handleSelectProduct={handleSelectProduct}
        handleSelectAvailable={handleSelectAvailable}
        handleSelectAll={handleSelectAll}
        handleDeselectAll={handleDeselectAll}
        token={token}
        paginationOptions={paginationOptions}
        handleExport={handleExport}
        isExporting={isExporting}
        lang={lang}
        tableFilter={tableFilter}
        handleViewDetail={handleViewDetail}
        productDropdown={productDropdown}
        changeSequenceOrder={changeSequenceOrder}
      />
    </>
  );
}

export default SummaryByDealerContainer;
