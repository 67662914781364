import { createSlice } from "@reduxjs/toolkit";
import {
  STOCKFLOWREQUESTTRANSFER,
  getRequestDetail,
  getRequestReceiveList,
  getRequestReceiverDropdown,
  getRequestSendList,
  getRequestSenderDropdown,
  getRequestStockList,
  getRequestTransferAddressList,
  getRequestTransferRequestBranches,
  getRequestTransferTargetBranches,
} from "../action/stockflowRequestTransfer.action";

const initialState = {
  requestSendList: {
    list: [],
    totalFiltered: 0,
    totalRecords: 0,
    isError: false,
  },
  requestReceiveList: {
    list: [],
    totalFiltered: 0,
    totalRecords: 0,
    isError: false,
  },
  selectedIds: [],
  isSelectAll: false,
  request: null,
  isLoadingRequestError: false,
  requestSenderDropdown: [],
  requestReceiverDropdown: [],
  addressList: {
    list: [],
    totalFiltered: 1,
    totalRecords: 1,
    isError: false,
  },
  stockList: {
    list: [],
    totalFiltered: 0,
    totalRecords: 0,
    isError: false,
  },
  targetBranches: {
    list: [],
    totalFiltered: 0,
    totalRecords: 0,
    isError: false,
  },
  requestorBranches: {
    list: [],
    totalFiltered: 0,
    totalRecords: 0,
    isError: false,
  },
};

const stockflowRequestTransferSlice = createSlice({
  name: STOCKFLOWREQUESTTRANSFER,
  initialState,
  reducers: {
    changeSelectedOrder(state, action) {
      const { payload } = action;

      state[`request${payload.type}List`].list[payload.index].isSelected =
        payload.isSelected;
      let orderId = state[`request${payload.type}List`].list[payload.index];
      if (
        (!state.isSelectAll && payload.isSelected) ||
        (state.isSelectAll && !payload.isSelected)
      ) {
        state.selectedIds = [...state.selectedIds, orderId];
      } else {
        state.selectedIds = state.selectedIds.filter((id) => id !== orderId);
      }
    },
    changeBulkSelectedOrder(state, action) {
      const { payload } = action;

      state[`request${payload.type}List`].list.forEach((order) => {
        order.isSelected = payload.value;
      });
      state.isSelectAll = payload.value;
      state.selectedIds = [];
    },
    changeBulkSelectedAvailableOrder(state, action) {
      const { payload } = action;
      state[`request${payload.type}List`].list.forEach((order) => {
        order.isSelected = payload.value;
        if (
          (!state.isSelectAll && payload.value) ||
          (state.isSelectAll && !payload.value)
        ) {
          if (state.selectedIds.findIndex((id) => id === order.id) === -1) {
            state.selectedIds = [...state.selectedIds, order.id];
          }
        } else {
          state.selectedIds = state.selectedIds.filter((id) => id !== order.id);
        }
      });
    },
    resetRequestListing(state) {
      state.requestSendList = initialState.requestSendList;
      state.requestReceiveList = initialState.requestReceiveList;
    },
    resetRequest(state) {
      state.request = initialState.request;
    },
    resetRequestTransferRequestBranchDropdown(state) {
      state.requestorBranches = initialState.requestorBranches;
    },
    resetRequestTransferTargetBranchDropdown(state) {
      state.targetBranches = initialState.targetBranches;
    },
    resetAddressList(state) {
      state.addressList = initialState.addressList;
    },
    resetRequestStockList(state) {
      state.stockList = initialState.stockList;
    },
    updateAddress(state, action) {
      const { payload } = action;
      let idx = state.addressList.list.findIndex(
        (a) => a.uuid === payload.uuid
      );
      if (idx >= 0) {
        state.addressList.list[idx] = {
          ...state.addressList.list[idx],
          phone_code: payload.phone_code,
          phone_number: payload.phone_number,
          address_line_1: payload.address_line_1,
          address_line_2: payload.address_line_2,
          postal_code: payload.postal_code,
          city: payload.city,
          state: payload.state,
          country: payload.country,
          full_address: payload.full_address,
        };
      }
    },
  },
  extraReducers: {
    [getRequestSendList.pending]: (state) => {
      state.requestSendList = initialState.requestSendList;
    },
    [getRequestSendList.fulfilled]: (state, action) => {
      const { payload } = action;
      const temp = [];
      payload.data.forEach((item) => {
        let selectedState = !state.isSelectAll
          ? state.selectedIds.findIndex((id) => id === item.id) !== -1
          : state.selectedIds.findIndex((id) => id === item.id) === -1;
        temp.push({
          ...item,
          isSelected: selectedState,
        });
        return null;
      });
      state.requestSendList.list = temp;
      state.requestSendList.totalRecords = payload.recordsTotal;
      state.requestSendList.totalFiltered = payload.recordsFiltered;
    },
    [getRequestSendList.rejected]: (state) => {
      state.requestSendList.isError = true;
    },
    [getRequestReceiveList.pending]: (state) => {
      state.requestReceiveList = initialState.requestReceiveList;
    },
    [getRequestReceiveList.fulfilled]: (state, action) => {
      const { payload } = action;
      const temp = [];
      payload.data.forEach((item) => {
        let selectedState = !state.isSelectAll
          ? state.selectedIds.findIndex((id) => id === item.id) !== -1
          : state.selectedIds.findIndex((id) => id === item.id) === -1;
        temp.push({
          ...item,
          isSelected: selectedState,
        });
        return null;
      });
      state.requestReceiveList.list = temp;
      state.requestReceiveList.totalRecords = payload.recordsTotal;
      state.requestReceiveList.totalFiltered = payload.recordsFiltered;
    },
    [getRequestReceiveList.rejected]: (state) => {
      state.requestReceiveList.isError = true;
    },
    [getRequestDetail.pending]: (state) => {
      state.request = initialState.request;
      state.isLoadingRequestError = false;
    },
    [getRequestDetail.fulfilled]: (state, action) => {
      state.request = action.payload;
    },
    [getRequestDetail.rejected]: (state) => {
      state.isLoadingRequestError = true;
    },
    [getRequestSenderDropdown.pending]: (state) => {
      state.requestSenderDropdown = initialState.requestSenderDropdown;
    },
    [getRequestSenderDropdown.fulfilled]: (state, action) => {
      state.requestSenderDropdown = action.payload;
    },
    [getRequestReceiverDropdown.pending]: (state) => {
      state.requestReceiverDropdown = initialState.requestReceiverDropdown;
    },
    [getRequestReceiverDropdown.fulfilled]: (state, action) => {
      state.requestReceiverDropdown = action.payload;
    },
    [getRequestStockList.pending]: (state) => {
      state.stockList.isError = false;
    },
    [getRequestStockList.fulfilled]: (state, action) => {
      let { payload } = action;
      if (
        state.stockList.list.length + payload.data.length <=
        payload.recordsFiltered
      ) {
        state.stockList.list = state.stockList.list.concat(
          payload.data.map((d) => ({
            ...d,
            quantity: 0,
          }))
        );
      }
      state.stockList.totalRecords = payload.recordsTotal;
      state.stockList.totalFiltered = payload.recordsFiltered;
    },
    [getRequestStockList.rejected]: (state, action) => {
      state.stockList.isError = true;
    },
    [getRequestTransferRequestBranches.pending]: (state) => {
      state.requestorBranches.isError = false;
    },
    [getRequestTransferRequestBranches.fulfilled]: (state, action) => {
      let { payload } = action;
      if (
        state.requestorBranches.list.length + payload.data.length <=
        payload.recordsFiltered
      ) {
        state.requestorBranches.list = state.requestorBranches.list.concat(
          payload.data
        );
      }
      state.requestorBranches.totalRecords = payload.recordsTotal;
      state.requestorBranches.totalFiltered = payload.recordsFiltered;
    },
    [getRequestTransferRequestBranches.rejected]: (state, action) => {
      state.requestorBranches.isError = true;
    },
    [getRequestTransferTargetBranches.pending]: (state) => {
      state.targetBranches.isError = false;
    },
    [getRequestTransferTargetBranches.fulfilled]: (state, action) => {
      let { payload } = action;
      if (
        state.targetBranches.list.length + payload.data.length <=
        payload.recordsFiltered
      ) {
        state.targetBranches.list = state.targetBranches.list.concat(
          payload.data
        );
      }
      state.targetBranches.totalRecords = payload.recordsTotal;
      state.targetBranches.totalFiltered = payload.recordsFiltered;
    },
    [getRequestTransferTargetBranches.rejected]: (state, action) => {
      state.targetBranches.isError = true;
    },
    [getRequestTransferAddressList.pending]: (state) => {
      state.addressList.isError = false;
    },
    [getRequestTransferAddressList.fulfilled]: (state, action) => {
      let { payload } = action;
      if (
        state.addressList.list.length + payload.data.length <=
        payload.recordsFiltered
      ) {
        state.addressList.list = state.addressList.list.concat(payload.data);
      }
      state.addressList.totalRecords = payload.recordsTotal;
      state.addressList.totalFiltered = payload.recordsFiltered;
    },
    [getRequestTransferAddressList.rejected]: (state, action) => {
      state.addressList.isError = true;
    },
  },
});

export const {
  changeSelectedOrder,
  changeBulkSelectedOrder,
  changeBulkSelectedAvailableOrder,
  resetRequestListing,
  resetRequest,
  resetRequestTransferRequestBranchDropdown,
  resetRequestTransferTargetBranchDropdown,
  resetAddressList,
  updateAddress,
  resetRequestStockList,
} = stockflowRequestTransferSlice.actions;

export const stockflowRequestTransferReducer =
  stockflowRequestTransferSlice.reducer;
