import React from "react";
import WarrantyPanelComponent from "./warrantyPanel.component";
import { connect, useSelector } from "react-redux";

function WarrantyPanelContainer({ isOpen, handleClose, headerType }) {
  const companyId = useSelector((state) => state.profile.companyID);

  const earlyAccessCompany = [12, 57, 85];
  const earlyAccess = process.env.REACT_APP_ENV === "DEVELOPMENT" ||
    earlyAccessCompany.includes(companyId);

  return (
    <WarrantyPanelComponent
      headerType={headerType}
      isOpen={isOpen}
      handleClose={handleClose}
      earlyAccess={earlyAccess}
    />
  );
}

const mapStateToProps = (state) => ({
  headerType: state.warranty.headerType
});

export default connect(mapStateToProps, null)(WarrantyPanelContainer);
