import axios from 'axios';

export function deleteSurvey({
  id
}) {
  const url =  `/consumer/admin/api/v1/survey/delete/${id}`;

  return new Promise((resolve, reject) => {
    axios.delete(url)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data))
  })
}