import React, { useState } from 'react';
import { connect } from 'react-redux';
import { addAlert, selectLoading } from 'modules/notification';
import { fetchBatchRangeList } from 'modules/serial-number/redux';
import CustomRangePanelComponent from './customRangePanel.component';

function CustomRangePanelContainer({
    formik,
    isFetching,
    batchRangeInfo,
    addAlert,
    totalQuantity,
    isSameRange,
    setIsSameRange,
    lang,
    isExceedLimit,
    setIsExceedLimit,
    isInputDisable
}) {
    const [isOpen, setIsOpen] = useState(false);

    const onCustomRangeChange = (event, formik, name, index) => {
        const digitLength = formik.getFieldProps("digitLength").value

        let range = formik.getFieldProps("generateRanges").value
        let sameRange = false
        let exceedLimit = false

        if (Number(event.target.value).toString().length <= digitLength) {
            formik.setFieldValue(name, event.target.value);
        } else {
            exceedLimit = true
        }

        let currentRange = range[index];
        range.forEach((number, i) => {
            if (i !== index) {
                const from = Number(number.from);
                const to = Number(number.to);
                if (
                    (event.target.value >= from && event.target.value <= to) 
                    || (currentRange.from <= from && currentRange.to >= to)
                ) {
                    sameRange = true
                }
            }
        })

        setIsSameRange(sameRange)
        setIsExceedLimit(exceedLimit)
    }

    return (
        <CustomRangePanelComponent
            formik={formik}
            isFetching={isFetching}
            batchRangeInfo={batchRangeInfo}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            addAlert={addAlert}
            totalQuantity={totalQuantity}
            onCustomRangeChange={onCustomRangeChange}
            isSameRange={isSameRange}
            lang={lang}
            isExceedLimit={isExceedLimit}
            isInputDisable={isInputDisable}
        />
    );
}

const mapStateToProps = state => ({
    isFetching: selectLoading(state, fetchBatchRangeList.typePrefix),
    batchRangeInfo: state.serialNumber.generateSerialNumber.batchRange
});

const mapDispatchToProps = dispatch => ({
    addAlert: value => dispatch(addAlert({ severity: "warning", message: value }))
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomRangePanelContainer);