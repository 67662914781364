import React from "react"
import ProductTraceabilityTimelineComponent from './productTraceabilityTimeline.component'
import { useSelector } from "react-redux"

function ProductTraceabilityTimelineContainer({
  traceabilityList,
  language,
  updateRecordFocus,
  recordFocus,
  lang
}) {
  const productColorList = useSelector(state => state.consumer.traceabilityList.productColor)
  
  return (
    <ProductTraceabilityTimelineComponent 
      traceabilityList={traceabilityList} 
      productColorList={productColorList}
      language={language}
      updateRecordFocus={updateRecordFocus}
      recordFocus={recordFocus}
      lang={lang}
    />
  )
}

export default ProductTraceabilityTimelineContainer