import {
  fetchLuckyDrawListTable,
  createLuckyDraw,
  fetchLuckyDrawDetail,
  updateLuckyDrawDetail,
  fetchJSONURL,
  fetchAllLuckyDrawPrize,
  createPrize,
  updatePrize,
  fetchLuckyDrawParticipantTable,
  fetchParticipantDetail,
  changeLuckyDrawStatus,
  randomParticipant,
  saveWinner,
  changeLuckyDrawProgress,
  fetchMoreLuckyDrawParticipant,
  fetchLuckyDrawParticipant,
} from "./luckyDraw.action";
import { setLoading, addAlert } from "modules/notification";
import {
  setLuckyDrawList,
  resetLuckyDrawDetail,
  resetRegisterForm,
  setLuckyDrawID,
  setLuckyDrawDetailFromApi,
  setLuckyDrawDetailUpdate,
  setLuckyDrawTNC,
  setPrizeListTable,
  setParticipantDetail,
  setLuckyDrawParticipantTable,
} from "./luckyDraw.slice";
import { getApiLang, getLang } from "app/feature/constants";

const fetchLuckyDrawListTableMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    switch (type) {
      case fetchLuckyDrawListTable.pending.type:
        dispatch(
          setLoading({ id: fetchLuckyDrawListTable.typePrefix, state: true })
        );
        break;

      case fetchLuckyDrawListTable.fulfilled.type:
        next(setLuckyDrawList(action.payload));
        dispatch(
          setLoading({ id: fetchLuckyDrawListTable.typePrefix, state: false })
        );
        break;

      case fetchLuckyDrawListTable.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({ id: fetchLuckyDrawListTable.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

const createLuckyDrawMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);
    const { type, payload } = action;
    const lang = getState().constant.languages;
    const pageSetting = getState().luckyDraw.pageSetting;

    switch (type) {
      case createLuckyDraw.pending.type:
        dispatch(setLoading({ id: createLuckyDraw.typePrefix, state: true }));
        break;

      case createLuckyDraw.fulfilled.type:
        next(resetLuckyDrawDetail());
        next(resetRegisterForm());
        next(setLuckyDrawID(action.payload));
        dispatch(fetchLuckyDrawListTable(pageSetting));
        dispatch(setLoading({ id: createLuckyDraw.typePrefix, state: false }));
        dispatch(
          addAlert({
            severity: "success",
            message: getApiLang(lang, "success.SUCCESS_CREATE_LUCKY_DRAW"),
          })
        );
        break;

      case createLuckyDraw.rejected.type:
        dispatch(setLoading({ id: createLuckyDraw.typePrefix, state: false }));
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

const fetchLuckyDrawDetailMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    switch (type) {
      case fetchLuckyDrawDetail.pending.type:
        dispatch(
          setLoading({ id: fetchLuckyDrawDetail.typePrefix, state: true })
        );
        break;

      case fetchLuckyDrawDetail.fulfilled.type:
        next(setLuckyDrawDetailFromApi(action.payload));
        dispatch(
          setLoading({
            id: fetchLuckyDrawDetail.typePrefix,
            state: false,
          })
        );
        break;

      case fetchLuckyDrawDetail.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: fetchLuckyDrawDetail.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

const updateLuckyDrawDetailMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages;

    switch (type) {
      case updateLuckyDrawDetail.pending.type:
        dispatch(
          setLoading({
            id: updateLuckyDrawDetail.typePrefix,
            state: true,
          })
        );
        break;

      case updateLuckyDrawDetail.fulfilled.type:
        dispatch(setLuckyDrawDetailUpdate(action.payload));

        dispatch(
          setLoading({
            id: updateLuckyDrawDetail.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "success",
            message: getApiLang(lang, "success.SUCCESS_UPDATE_LUCKY_DRAW"),
          })
        );
        break;

      case updateLuckyDrawDetail.rejected.type:
        dispatch(
          setLoading({
            id: updateLuckyDrawDetail.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

const fetchJSONURLMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    switch (type) {
      case fetchJSONURL.pending.type:
        dispatch(setLoading({ id: fetchJSONURL.typePrefix, state: true }));
        break;

      case fetchJSONURL.fulfilled.type:
        next(setLuckyDrawTNC(action.payload));
        dispatch(setLoading({ id: fetchJSONURL.typePrefix, state: false }));
        break;

      case fetchJSONURL.rejected.type:
        const lang = getState().constant.languages;
        dispatch(setLoading({ id: fetchJSONURL.typePrefix, state: false }));
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

const fetchAllLuckyDrawPrizeMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    switch (type) {
      case fetchAllLuckyDrawPrize.pending.type:
        dispatch(
          setLoading({ id: fetchAllLuckyDrawPrize.typePrefix, state: true })
        );
        break;

      case fetchAllLuckyDrawPrize.fulfilled.type:
        next(setPrizeListTable(action.payload));
        dispatch(
          setLoading({
            id: fetchAllLuckyDrawPrize.typePrefix,
            state: false,
          })
        );
        break;

      case fetchAllLuckyDrawPrize.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: fetchAllLuckyDrawPrize.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

const createPrizeMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);
    const { type, payload } = action;
    const lang = getState().constant.languages;
    const pageSetting = getState().luckyDraw.pageSetting;

    switch (type) {
      case createPrize.pending.type:
        dispatch(setLoading({ id: createPrize.typePrefix, state: true }));
        break;

      case createPrize.fulfilled.type:
        dispatch(fetchLuckyDrawListTable(pageSetting));
        dispatch(setLoading({ id: createPrize.typePrefix, state: false }));
        dispatch(
          addAlert({
            severity: "success",
            message: getApiLang(lang, "success.SUCCESS_CREATE_PRIZE"),
          })
        );
        break;

      case createPrize.rejected.type:
        dispatch(setLoading({ id: createPrize.typePrefix, state: false }));
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

const updatePrizeMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages;

    switch (type) {
      case updatePrize.pending.type:
        dispatch(
          setLoading({
            id: updatePrize.typePrefix,
            state: true,
          })
        );
        break;

      case updatePrize.fulfilled.type:
        dispatch(
          setLoading({
            id: updatePrize.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "success",
            message: getApiLang(lang, "success.SUCCESS_UPDATE_PRIZE"),
          })
        );
        break;

      case updatePrize.rejected.type:
        dispatch(
          setLoading({
            id: updatePrize.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

const fetchMoreLuckyDrawParticipantMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    const { type } = action;
    if (type === fetchMoreLuckyDrawParticipant.type) {
      const p = getState().luckyDraw.luckyDrawParticipantTable;
      const luckyDrawId = getState().luckyDraw.LuckyDrawDetail.id;
      const start = p.data.length;
      const search = getState().luckyDraw.searchForParticipants;
      const fetchParticipantInfo = {
        luckyDrawId,
        length: "10",
        start: start,
        column: "createdAt",
        order: "desc",
        search: search,
      };

      dispatch(fetchLuckyDrawParticipant(fetchParticipantInfo));
    }
  };

const fetchLuckyDrawParticipantMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    switch (type) {
      case fetchLuckyDrawParticipant.pending.type:
        dispatch(
          setLoading({
            id: fetchLuckyDrawParticipant.typePrefix,
            state: true,
          })
        );
        break;

      case fetchLuckyDrawParticipant.fulfilled.type:
        dispatch(
          setLoading({
            id: fetchLuckyDrawParticipant.typePrefix,
            state: false,
          })
        );
        break;

      case fetchLuckyDrawParticipant.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: fetchLuckyDrawParticipant.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

const fetchLuckyDrawParticipantTableMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    switch (type) {
      case fetchLuckyDrawParticipantTable.pending.type:
        dispatch(
          setLoading({
            id: fetchLuckyDrawParticipantTable.typePrefix,
            state: true,
          })
        );
        break;

      case fetchLuckyDrawParticipantTable.fulfilled.type:
        next(setLuckyDrawParticipantTable(action.payload));
        dispatch(
          setLoading({
            id: fetchLuckyDrawParticipantTable.typePrefix,
            state: false,
          })
        );
        break;

      case fetchLuckyDrawParticipantTable.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: fetchLuckyDrawParticipantTable.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

const fetchParticipantDetailMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    switch (type) {
      case fetchParticipantDetail.pending.type:
        dispatch(
          setLoading({
            id: fetchParticipantDetail.typePrefix,
            state: true,
          })
        );
        break;

      case fetchParticipantDetail.fulfilled.type:
        next(setParticipantDetail(action.payload));
        dispatch(
          setLoading({
            id: fetchParticipantDetail.typePrefix,
            state: false,
          })
        );
        break;

      case fetchParticipantDetail.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: fetchParticipantDetail.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

const changeLuckyDrawStatusMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages;

    switch (type) {
      case changeLuckyDrawStatus.pending.type:
        dispatch(
          setLoading({ id: changeLuckyDrawStatus.typePrefix, state: true })
        );
        break;
      case changeLuckyDrawStatus.fulfilled.type:
        dispatch(
          setLoading({ id: changeLuckyDrawStatus.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "success",
            message: getApiLang(
              lang,
              "success.SUCCESS_CHANGE_LUCKY_DRAW_STATUS"
            ),
          })
        );
        break;
      case changeLuckyDrawStatus.rejected.type:
        dispatch(
          setLoading({ id: changeLuckyDrawStatus.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

const randomParticipantMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    switch (type) {
      case randomParticipant.pending.type:
        dispatch(setLoading({ id: randomParticipant.typePrefix, state: true }));
        break;

      case randomParticipant.fulfilled.type:
        dispatch(
          setLoading({
            id: randomParticipant.typePrefix,
            state: false,
          })
        );
        break;

      case randomParticipant.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: randomParticipant.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

const saveWinnerMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages;
    const pageSetting = getState().luckyDraw.pageSetting;

    switch (type) {
      case saveWinner.pending.type:
        dispatch(
          setLoading({
            id: saveWinner.typePrefix,
            state: true,
          })
        );
        break;

      case saveWinner.fulfilled.type:
        dispatch(fetchLuckyDrawListTable(pageSetting));
        const luckyDrawId = getState().luckyDraw.LuckyDrawDetail.id;
        const fetchPrizeTableInfo = {
          luckyDrawId: luckyDrawId,
          column: "order",
          order: "asc",
        };
        await dispatch(fetchAllLuckyDrawPrize(fetchPrizeTableInfo));
        dispatch(
          setLoading({
            id: saveWinner.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "success",
            message: getApiLang(lang, "success.SUCCESS_SAVE_WINNER"),
          })
        );
        break;

      case saveWinner.rejected.type:
        dispatch(
          setLoading({
            id: saveWinner.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message:
              payload.code === "error.ERROR_INVALID_DATA"
                ? getLang(lang, "message.error.FAILED_SET_WINNER")
                : getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

const changeLuckyDrawProgressMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages;
    const pageSetting = getState().luckyDraw.pageSetting;

    switch (type) {
      case changeLuckyDrawProgress.pending.type:
        dispatch(
          setLoading({ id: changeLuckyDrawProgress.typePrefix, state: true })
        );
        break;
      case changeLuckyDrawProgress.fulfilled.type:
        dispatch(fetchLuckyDrawListTable(pageSetting));
        dispatch(
          setLoading({ id: changeLuckyDrawProgress.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "success",
            message: getApiLang(
              lang,
              "success.SUCCESS_CHANGE_LUCKY_DRAW_PROGRESS"
            ),
          })
        );
        break;
      case changeLuckyDrawProgress.rejected.type:
        dispatch(
          setLoading({ id: changeLuckyDrawProgress.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

export const luckyDrawMiddleware = [
  fetchLuckyDrawListTableMiddleware,
  createLuckyDrawMiddleware,
  fetchLuckyDrawDetailMiddleware,
  updateLuckyDrawDetailMiddleware,
  fetchJSONURLMiddleware,
  fetchAllLuckyDrawPrizeMiddleware,
  createPrizeMiddleware,
  updatePrizeMiddleware,
  fetchLuckyDrawParticipantTableMiddleware,
  fetchParticipantDetailMiddleware,
  changeLuckyDrawStatusMiddleware,
  randomParticipantMiddleware,
  saveWinnerMiddleware,
  changeLuckyDrawProgressMiddleware,
  fetchLuckyDrawParticipantMiddleware,
  fetchMoreLuckyDrawParticipantMiddleware,
];
