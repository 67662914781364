import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { GoogleMap } from "@react-google-maps/api";
import BothClusterIcon from "assets/img/bothClusterIcon.png";
import ReportWarningIcon from "assets/img/reportWarning.png";
import { getLang } from "app/feature/constants";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    "& [class*='-marker-view']:hover": {
      zIndex: 1,
    },
    "& .custom-marker-label": {
      color: "#ffffff",
      marginBottom: "5px",
    },
    "& .suspendPin": {
      backgroundColor: "#F97066",
      borderRadius: "50%",
      height: 38,
      width: 38,
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "white",
      animation: `$suspendPulse 2s infinite ${theme.transitions.easing.easeInOut}`,
      cursor: "pointer",
      pointerEvents: "auto",
    },
    "& .suspendPin::after": {
      content: '""',
      position: "absolute",
      left: 0,
      right: 0,
      margin: "0 auto",
      width: 0,
      height: 0,
      borderTop: "9px solid #F97066",
      borderLeft: "6px solid transparent",
      borderRight: "6px solid transparent",
      bottom: -6,
    },
    "& .activePin": {
      backgroundColor: "#35CA91",
      borderRadius: "50%",
      height: 38,
      width: 38,
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "white",
      cursor: "pointer",
      pointerEvents: "auto",
    },
    "& .activePin::after": {
      content: '""',
      position: "absolute",
      left: 0,
      right: 0,
      margin: "0 auto",
      width: 0,
      height: 0,
      borderTop: "9px solid #35CA91",
      borderLeft: "6px solid transparent",
      borderRight: "6px solid transparent",
      bottom: -6,
    },
    "& .activeCluster": {
      borderRadius: "50%",
      height: 58,
      width: 58,
      border: "4px solid white",
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "white",
      fontSize: 18,
    },
    "& .suspendCluster": {
      borderRadius: "50%",
      height: 58,
      width: 58,
      border: "4px solid white",
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "white",
      fontSize: 18,
      animation: `$suspendPulse 1.5s infinite ${theme.transitions.easing.easeInOut}`,
    },
    "& .bothCluster": {
      borderRadius: "50%",
      height: 58,
      width: 58,
      position: "relative",
      display: "flex",
      border: "4px solid white",
      justifyContent: "center",
      alignItems: "center",
      color: "white",
      fontSize: 18,
      backgroundImage: `url(${BothClusterIcon})`,
      backgroundSize: "contain",
      animation: `$suspendPulse 1.5s infinite ${theme.transitions.easing.easeInOut}`,
    },
    "& .report": {
      top: -9,
      right: -9,
      height: 25,
      width: 25,
      position: "absolute",
      backgroundImage: `url(${ReportWarningIcon})`,
      backgroundSize: "contain",
    },
    "& .reportCluster": {
      top: -3,
      right: -3,
    },
    "& .productPic": {
      height: 32,
      width: 32,
      borderRadius: "50%",
      objectFit: "contain",
      border: "1px solid #E4E7EC",
    },
    "& .clusterSection": {
      position: "relative",
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      pointerEvents: "auto",
    },
    "& .imgSection": {
      padding: "5px 10px 5px 23px",
      borderRadius: "0px 57px 57px 0px",
      backgroundColor: "#F2F4F7",
      alignItems: "center",
      position: "absolute",
      top: 10,
      left: 40,
      height: 43,
      display: "none",
      animation: `$slideRight 0.5s ${theme.transitions.easing.easeInOut}`,
    },
    "& .show": {
      display: "flex !important",
    },
    "& .clusterProduct": {
      marginLeft: -12,
    },
    "& .moreProduct": {
      height: 33,
      width: 33,
      borderRadius: "50%",
      border: "1px solid #E4E7EC",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#F2F4F7",
      marginLeft: -12,
      fontWeight: "bold",
    },
    "& .zoomSection": {
      padding: "5px 23px 5px 10px",
      borderRadius: "57px 0px 0px 57px",
      backgroundColor: "#F2F4F7",
      alignItems: "center",
      position: "absolute",
      right: 40,
      height: 43,
      top: 10,
      display: "none",
      animation: `$slideLeft 0.5s ${theme.transitions.easing.easeInOut}`,
    },
    "& .zoomPic": {
      height: 24,
      width: 24,
      objectFit: "contain",
      color: "#98A2B3",
    },
    "& .clusterSection:hover": {
      "& .imgSection": {
        display: "flex",
      },
      "& .zoomSection": {
        display: "flex",
      },
    },
  },
  "@keyframes suspendPulse": {
    "0%": {
      boxShadow: "0 0 0 0 rgba(249, 112, 102, 0.8)",
    },
    "100%": {
      boxShadow: "0 0 0 12px rgba(249, 112, 102, 0)",
    },
  },
  "@keyframes slideRight": {
    "0%": {
      opacity: 0,
      left: 0,
    },
    "100%": {
      opacity: 1,
      left: 40,
    },
  },
  "@keyframes slideLeft": {
    "0%": {
      opacity: 0,
      right: 0,
    },
    "100%": {
      opacity: 1,
      right: 40,
    },
  },
  revealSection: {
    position: "absolute",
    bottom: 13,
    left: 10,
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
    padding: "8px 16px",
    borderRadius: 30,
    boxShadow: "1px 1px 3px 2px #00000021",
    [theme.breakpoints.down("xs")]: {
      bottom: 40,
    },
  },
  activeDot: {
    height: 8,
    width: 8,
    borderRadius: "50%",
    backgroundColor: "#32D583",
    marginRight: 8,
  },
  suspendDot: {
    height: 8,
    width: 8,
    borderRadius: "50%",
    backgroundColor: "#F97066",
    marginRight: 8,
  },
  instruction: {
    padding: "0px 11px",
    backgroundColor: "white",
    position: "absolute",
    bottom: 13,
    right: "10%",
    display: "flex",
    alignItems: "center",
    borderRadius: 4,
    boxShadow: "1px 1px 3px 1px #00000021",
  },
}));

export default function DashboardScanCountMapComponent({
  isLoaded,
  center,
  zoomLevel,
  updateZoomLevel,
  onLoad,
  onUnmount,
  lang,
}) {
  const classes = useStyles();

  return (
    window.google !== undefined &&
    isLoaded && (
      <Box className={classes.container} id="map">
        <GoogleMap
          mapContainerStyle={{
            width: "100%",
            height: "560px",
          }}
          onLoad={onLoad}
          onUnmount={onUnmount}
          onZoomChanged={updateZoomLevel}
          center={center}
          zoom={zoomLevel}
          options={{
            mapTypeControl: true,
            streetViewControl: true,
            rotateControl: true,
            // fullscreenControl: true,
            // gestureHandling: "none",
            clickableIcons: false,
            keyboardShortcuts: false,
          }}
        ></GoogleMap>
        <Box className={classes.instruction}>
          <Box display="flex" alignItems="center" mr={2}>
            <Box className={classes.activeDot} />
            <Typography variant="caption">
              {getLang(lang, "label.VALID_SCAN")}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <Box className={classes.suspendDot} />
            <Typography variant="caption">
              {getLang(lang, "label.SUSPENDED_SCAN")}
            </Typography>
          </Box>
        </Box>
      </Box>
    )
  );
}
