import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import Alert from "@material-ui/lab/Alert";
import {
  Paper,
  makeStyles,
  Grow,
  ClickAwayListener,
  Popper,
  Box,
  Button,
  Typography,
  MenuItem,
  MenuList
} from "@material-ui/core";
import {
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon
} from '@material-ui/icons';
import { getLang } from "app/feature/constants";

const useStyle = makeStyles(theme => ({
  tablePaper: {
    height: 400
  },
  adornmentIcon: {
    color: "#707070"
  },
  input: {
    paddingRight: theme.spacing(1),
    cursor: "pointer !important"
  },
  paperTable: {
    padding: theme.spacing(1, 0),
    border: "1px solid #3A4D54",
    maxHeight: 150,
    overflowY: "auto"
  },
  tableContainer: {
    maxHeight: 300
  },
  smallCase: {
    textTransform: "none"
  },
  greenLabel: {
    color: theme.palette.secondary.main
  }
}));

export default function TagSelectComponent({
  open,
  isFetching,
  handleOpen,
  handleClose,
  anchorRef,
  options,
  handleChange,
  lang
}) {
  const classes = useStyle();

  return (
    <Box style={{
      display: 'flex',
      justifyContent: 'flex-end'
    }}>
      <Button
        variant="outlined"
        color="primary"
        ref={anchorRef}
        aria-controls={open ? 'favTagsList' : undefined}
        size="small"
        onClick={handleOpen}
        classes={{
          label: classes.smallCase
        }}
        endIcon={open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      >
        {getLang(lang, "label.USE_SAVE_PATTERN")}
      </Button>
      <Popper
        open={open}
        style={{ zIndex: 99 }}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom"
            }}
          >
            <Paper className={classes.paperTable} elevation={0}>
              <ClickAwayListener onClickAway={handleClose}>
                <div>

                  {isFetching && (
                    <Box>
                      <Skeleton variant="text" width={150} animation="wave" />
                      <Skeleton variant="text" width={150} animation="wave" />
                      <Skeleton variant="text" width={150} animation="wave" />
                    </Box>
                  )}

                  {!isFetching && options.length > 0 && (
                    options.map((tag, index) => {
                      const tagFormatInfoArray = tag.format.split(',');
                      const prefix = tagFormatInfoArray[0];
                      const digitLength = tagFormatInfoArray[1];
                      const postfix = tagFormatInfoArray[2];

                      return (
                        <MenuList
                          id="favTagsList"
                          key={index}
                          open={true}
                          disablePadding
                        >
                          <MenuItem onClick={() => handleChange({ prefix, digitLength, postfix })}>
                            <Typography
                              display="inline"
                              variant="body2"
                              classes={{
                                root: classes.greenLabel
                              }}
                            >
                              {prefix}&nbsp;
                          </Typography>
                            <Typography
                              display="inline"
                              variant="body2"
                            >
                              [{digitLength} {getLang(lang, "label.DIGITS")}]
                          </Typography>
                            <Typography
                              display="inline"
                              variant="body2"
                              classes={{
                                root: classes.greenLabel
                              }}
                            >
                              &nbsp;{postfix}
                            </Typography>
                          </MenuItem>
                        </MenuList>
                      );
                    })
                  )}

                  {!isFetching && options.length === 0 && (
                    <Alert severity="info">{getLang(lang, "message.info.NO_RECORD_EXIST")}</Alert>
                  )}
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
}