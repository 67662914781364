import React from "react";
import ReceiveItemsProductListPanelComponent from "./receiveItemsProductListPanel.component";

export default function ReceiveItemsProductListPanelContainer({
  isFetching,
  list,
  totalFiltered,
  isError,
  handleFetchMoreItems,
  viewProduct,
  lang,
  handleReload,
  hasNextPage,
  status,
}) {
  return (
    <>
      <ReceiveItemsProductListPanelComponent
        lang={lang}
        isFetching={isFetching}
        list={list}
        totalFiltered={totalFiltered}
        isError={isError}
        handleFetchMoreItems={handleFetchMoreItems}
        viewProduct={viewProduct}
        handleReload={handleReload}
        hasNextPage={hasNextPage}
        status={status}
      />
    </>
  );
}
