import axios from "axios"

const url = "/distributor/admin/api/v1/rewards"

const get = (uuid) => {
  return new Promise((resolve, reject) => {
    axios.get(`${url}/${uuid}`)
      .then(response => resolve(response))
      .catch(error => reject(error.response));
  })
}

const postCreate = ({
  coverImageUrl, 
  imageUrl,
  nodeUuid,
  name,
  description,
  tncUrl,
  tncType,
  displayStartDateTime,
  displayEndDateTime,
  validityStartDateTime,
  validityEndDateTime,
  point,
  redemptionLimit,
  quantity
}) => {
  const body = {
    cover_image_url: coverImageUrl, 
    image_url: imageUrl,
    node_uuid: nodeUuid,
    name,
    description,
    tnc_url: tncUrl,
    tnc_type: tncType,
    display_start_datetime: displayStartDateTime,
    display_end_datetime: displayEndDateTime,
    validity_start_datetime: validityStartDateTime,
    validity_end_datetime: validityEndDateTime,
    point,
    redemption_limit: redemptionLimit,
    quantity
  }
  return new Promise((resolve, reject) => {
    axios
      .post(`${url}/create`, body)
      .then((response) => resolve(response))
      .catch((error) => {
        return reject(error.response);
      });
  });
}

const postUpdate = ({
  uuid,
  coverImageUrl, 
  imageURL,
  nodeUuid,
  name,
  description,
  tncUrl,
  tncType,
  displayStartDateTime,
  displayEndDateTime,
  validityStartDateTime,
  validityEndDateTime,
  point,
  redemptionLimit,
  quantity
}) => {
  const body = {
    cover_image_url: coverImageUrl, 
    image_url: imageURL,
    node_uuid: nodeUuid,
    name,
    description,
    tnc_url: tncUrl,
    tnc_type: tncType,
    display_start_datetime: displayStartDateTime,
    display_end_datetime: displayEndDateTime,
    validity_start_datetime: validityStartDateTime,
    validity_end_datetime: validityEndDateTime,
    point,
    redemption_limit: redemptionLimit,
    quantity
  }
  return new Promise((resolve, reject) => {
    axios
      .post(`${url}/${uuid}/update`, body)
      .then((response) => resolve(response))
      .catch((error) => {
        return reject(error.response);
      });
  });
}

const rewardDetails = {
  get,
  postCreate,
  postUpdate
}

export default rewardDetails;