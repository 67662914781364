import React, { useEffect, useState } from "react";
import VerificationFormComponent from "./verificationForm.component";
import { useDispatch, useSelector } from "react-redux";
import { checkResendVerificationEmailResendTimeLeft, resendEmailVerification } from "../../../redux/action";
import { LOGOUT } from "../../../config/public.route";
import { useHistory } from "react-router-dom";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { selectLoading } from "modules/notification";
import { isUserVerified } from "modules/admin";
import { ROOT as HOME } from "modules/dashboard/config/dashboard.route";

export default function VerificationFormContainer() {
  const dispatch = useDispatch()
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const [counter, setCounter] = useState(60);
  const resendEmailResendTimeLeft = useSelector(state => state.signup.resendEmailResendTimeLeft)
  const isLoading = useSelector((state) => selectLoading(state, resendEmailVerification.typePrefix));
  const session = useSelector(state => state.session)
  const isEmailVerified = useSelector(state => isUserVerified(state))
  const lang = useSelector(state => state.constant.languages)

  useEffect(() => {
    dispatch(checkResendVerificationEmailResendTimeLeft())
  }, [dispatch])

  useEffect(() => {
    if (isEmailVerified) {
      history.push(HOME)
    }
  }, [isEmailVerified, history])

  useEffect(() => {
    setCounter(resendEmailResendTimeLeft)
  }, [resendEmailResendTimeLeft]);


  useEffect(() => {
    if (counter > 0) {
      const timer = setInterval(() => setCounter(counter - 1), 1000);
      return () => clearInterval(timer);
    }
  }, [counter]);

  const handleResendEmailVerification = async () => {
    await dispatch(resendEmailVerification())
    setCounter(60)
  };

  const handleLogout = () => {
    history.push(LOGOUT)
  }

  return (
    <VerificationFormComponent
      isLoading={isLoading}
      email={session.idTokenPayload.email}
      handleResendEmailVerification={handleResendEmailVerification}
      handleLogout={handleLogout}
      isMobile={isMobile}
      counter={counter}
      lang={lang}
    />
  );
}
