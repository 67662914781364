import React from "react";
import InfoHeader from "../../infoHeader";
import {
  Box,
  Paper,
  Button,
  makeStyles,
  Divider,
  Grid,
  Typography
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import languages from "lib/constants/languages";
import { getLang } from "app/feature/constants";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexFlow: "column nowrap",
    boxShadow: theme.shadows[0],
    height: "max-content",
    alignItems: "center"
  },
  field: {
    borderBottom: "1px solid",
    "& h6:last-child": {
      fontWeight: "normal"
    }
  },
  label: {
    textTransform: "none"
  },
  button: {
    display: "flex",
    justifyContent: "flex-end"
  }
}));

export default function InfoSettingProfilePanelComponent({
  currentUserUuid,
  hasEmployeeEditAccessRight,
  employeeDetail,
  isFetching,
  handleEdit,
  timezone,
  lang
}) {
  const classes = useStyles();
  return (
    <Box>
      <Paper elevation={0}>
        <InfoHeader isFetching={isFetching} employeeDetail={employeeDetail} />

        <Paper elevation={0} className={classes.paper}>
          <Box style={{ width: "100%" }}>
            <Box
              p={3}
              style={{
                display: "flex",
                placeContent: "center",
                backgroundColor: "#F4F6F8"
              }}
            >
              <Box p={1} px={4} style={{ width: "100%", background: "#fff" }}>
                {[
                  { label: getLang(lang, "label.FIRST_NAME"), name: employeeDetail.firstName },
                  { label: getLang(lang, "label.LAST_NAME"), name: employeeDetail.lastName },
                  { label: getLang(lang, "label.EMAIL"), name: employeeDetail.email },
                  {
                    label: getLang(lang, "label.CONTACT"),
                    name: employeeDetail.phoneNumber
                  },
                  {
                    label: getLang(lang, "label.BRANCH"),
                    name: employeeDetail.nodes
                  },
                  {
                    label: getLang(lang, "label.ROLE"),
                    name: employeeDetail.roleName === "owner" ? getLang(lang, 'label.OWNER') : employeeDetail.roleName 
                  },
                  {
                    label: getLang(lang, "label.TIME_ZONE"),
                    name:
                      timezone.find((tz) => tz.id === employeeDetail.timeZone)
                        ?.text || ""
                  },
                  {
                    label: getLang(lang, "label.LANGUAGE"),
                    name: employeeDetail.locale,
                  },
                ].map((info, index) => (
                  <React.Fragment key={index}>
                    {info.label === getLang(lang, "label.BRANCH") || info.label === getLang(lang, "label.TIME_ZONE")? (
                      <Divider />
                    ) : null}
                    <Grid
                      container
                      style={{
                        alignItems: "center",
                        padding: "16px 0px"
                      }}
                    >
                      <Grid item xs={3} sm={3} md={3} style={{ width: "100%" }}>
                        <Typography variant="body2" color="textSecondary">
                          {info.label}
                        </Typography>
                      </Grid>
                      <Grid item xs={9} sm={9} md={9} style={{ width: "100%" }}>
                        {isFetching && !employeeDetail.firstName ? (
                          <Skeleton variant="text" width={80} height={30} />
                        ) : (
                          <Typography
                            variant="body2"
                            color="primary"
                            style={{ fontWeight: "bold" }}
                          >
                            {info.name
                              ? info.label === getLang(lang, "label.LANGUAGE")
                                ? languages.find((lg) => lg.code === info.name)
                                    ?.label
                                : info.label === getLang(lang, "label.CONTACT")
                                  ? `${employeeDetail.phoneCode} ${info.name}`
                                  : info.label === getLang(lang, "label.BRANCH")
                                    ? info.name.map((field) => field.name).join(",")
                                    : info.name
                              : "-"}
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  </React.Fragment>
                ))}
              </Box>
            </Box>

            {hasEmployeeEditAccessRight &&
              currentUserUuid !== employeeDetail.id ? (
              <Box>
                <Divider />
                <Box p={2} px={3} className={classes.button}>
                  <Button
                    color="primary"
                    variant="contained"
                    disableElevation
                    onClick={handleEdit}
                  >
                    {getLang(lang, "label.EDIT")}
                  </Button>
                </Box>
              </Box>
            ) : null}
          </Box>
        </Paper>
      </Paper>
    </Box>
  );
}
