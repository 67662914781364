import React, { useState, useEffect, useRef } from "react"
import { useHistory, useRouteMatch } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import DealerDownlinePanelComponent from "./dealerDownlinePanel.component"
import { selectLoading } from "modules/notification"
import { generatePaginationOptions, updateUrlQueryParam } from "lib/helper"
import { getAllDealerListing, resetDealerDownlineListing } from "modules/stockflow/redux"
import { MY_DEALER_LIST_PAGE } from "modules/stockflow/config/stockflow.route";
import { memo } from "react"
import { STOCKFLOW_DEALER_VIEW } from "lib/constants/accessRights";

function DealerDownlinePanelContainer() {
  const dispatch = useDispatch()
  const match = useRouteMatch()
  const history = useHistory()

  const id = match.params.dealerId
  const query = new URLSearchParams(window.location.search)
  const hasStockflowDealerViewAccessRight = useSelector(state => state.profile.accessRights.includes(STOCKFLOW_DEALER_VIEW));

  const lang = useSelector(state => state.constant.languages)
  const language = useSelector(state => state.profile.language);
  const isFetching = useSelector(state => selectLoading(state, getAllDealerListing.typePrefix))
  const totalFiltered = useSelector(state => state.stockflowDealer.dealerList.totalFiltered)
  const totalRecords = useSelector(state => state.stockflowDealer.dealerList.totalRecords)
  const downlineList = useSelector(state => state.stockflowDealer.dealerList.data)
  const isError = useSelector(state => state.stockflowDealer.dealerList.isError)

  const isSearchResult = downlineList.length < totalRecords
  const [paginationOptions, setPaginationOptions] = useState([])
  const [isFilterUpdated, setIsFilterUpdated] = useState(false)
  const [tableFilter, setTableFilter] = useState({
    page: 0,
    rowsPerPage: 25,
    order: "asc",
    search: "",
  })

  const tableFilterInitialRef = useRef(true)

  useEffect(() => {
    return () => {
      dispatch(resetDealerDownlineListing())
    }
  }, [dispatch])

  useEffect(() => {
    const isFilter = query.has("is_filter")
    let filter = sessionStorage.getItem(`dealer_downline_filter_${id}`);

    let temp = {...tableFilter}
    if (filter) {
      temp = JSON.parse(filter)
      setIsFilterUpdated(true)
    }

    if (isFilter) {
      updateUrlQueryParam(null, ["is_filter"])
    }

    setTableFilter(temp)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!tableFilterInitialRef.current) {
      getData()
    } 
    tableFilterInitialRef.current = false
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, tableFilter, id])

  useEffect(() => {
    setPaginationOptions(generatePaginationOptions(totalFiltered))
  }, [totalFiltered])

  const getData = () => {
    dispatch(getAllDealerListing({
      length: tableFilter.rowsPerPage,
      start: tableFilter.rowsPerPage * tableFilter.page,
      order: tableFilter.order,
      search: tableFilter.search,
      dealer: id
    }))
  }

  const handleChangePage = (event, newPage) => {
    let newData = {
      ...tableFilter,
      page: newPage,
    }
    setIsFilterUpdated(true)
    setTableFilter(newData)
  }

  const handleChangeRowsPerPage = event => {
    let newData = {
      ...tableFilter,
      page: 0,
      rowsPerPage: event.target.value,
    }
    setIsFilterUpdated(true)
    setTableFilter(newData)
  }

  const handleSearch = (filter) => {
    const { search } = filter

    let newData = {
      ...tableFilter,
      page: 0,
      search: search,
    }
    setIsFilterUpdated(true)
    setTableFilter(newData)
  }

  const clearSearch = () => {
    let newData = {
      page: 0,
      search: "",
    }
    setIsFilterUpdated(false)
    setTableFilter(newData)
    sessionStorage.removeItem(`dealer_downline_filter_${id}`)
  }

  const handleViewDetail = (id) => {
    if (isFilterUpdated) {
      updateUrlQueryParam({ is_filter: true })
      sessionStorage.setItem(`dealer_downline_filter_${id}`, JSON.stringify(tableFilter));
    }
    history.push(`${MY_DEALER_LIST_PAGE}/${id}`)
  }

  return (
    <DealerDownlinePanelComponent
      hasStockflowDealerViewAccessRight={hasStockflowDealerViewAccessRight}
      id={id}
      downlines={downlineList}
      isFetching={isFetching}
      isError={isError}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      page={tableFilter.page}
      language={language}
      rowsPerPage={tableFilter.rowsPerPage}
      totalFiltered={totalFiltered}
      handleSearch={handleSearch}
      clearSearch={clearSearch}
      handleReload={getData}
      isSearchResult={isSearchResult}
      paginationOptions={paginationOptions}
      lang={lang}
      tableFilter={tableFilter}
      handleViewDetail={handleViewDetail}
    />
  )
}

export default memo(DealerDownlinePanelContainer)
