import React from "react";
import {
  makeStyles,
  Paper,
  Typography,
  Box
} from "@material-ui/core";
import GenerateSerialNumberForm from "modules/serial-number/components/form/generate-serial-number";
import {
  ChevronRightRounded as ChevronRightRoundedIcon
} from "@material-ui/icons";
import { BackButton } from "components/button";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles(theme => ({
  title: {
    fontWeight: "bold"
  },
  indicator: {
    border: "0.5px solid",
    borderColor: theme.palette.primary.main
  },
  scroll: {
    width: "min-content"
  }
}));

export default function GenerateComponent({lang}) {
  const classes = useStyle();
  return (
    <Box className={classes.root}>
      <Box gridArea="topbar">
        <Box
          mb={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="flex" alignItems="flex-end" fontWeight="bold">
            <BackButton/>
            <Typography
              variant="h6"
              color="primary"
              className={classes.title}
            >
              {getLang(lang, "label.QR_CODE")}
            </Typography>
            <Box mx={0.5}>
              <ChevronRightRoundedIcon color="disabled" />
            </Box>
            <Box>
              <Typography variant="h6" color="primary">
                {getLang(lang, "label.GENERATE_SERIAL_NUMBER")}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box gridArea="panel">
        <Paper
          elevation={3}
        >
          <GenerateSerialNumberForm />
        </Paper>
      </Box>

      <Box gridArea="photo" mt={{ xs: 3, sm: 3, md: 6 }}>
      </Box>
    </Box>
  );
}
