import React from "react"
import PackageInfoCardComponent from "./packageInfoCard.component";

export default function PackageInfoCardContainer({
  hasStockflowDealerViewAccessRight,
  packageDetail,
  isFetching,
  language,
  lang
}) {
  return (
    <PackageInfoCardComponent
      hasStockflowDealerViewAccessRight={hasStockflowDealerViewAccessRight}
      packageDetail={packageDetail} 
      isFetching={isFetching}
      language={language}
      lang={lang}
    />
  )
}