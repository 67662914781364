import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { ROOT, SURVEY_DETAIL } from "./config/survey.route";
import { SurveyListingPage, SurveyDetail } from "./pages";
import { surveyReducer } from "./redux/slice";

export default function ModuleSurvey() {
    return (
        <Switch>
            <Route exact path={ROOT} component={SurveyListingPage} />
            <Route exact path={SURVEY_DETAIL} component={SurveyDetail} />
            <Route path="*">
                <Redirect to="/admin/invalid" />
            </Route>
        </Switch>
    );
}

export {
    ROOT,
    surveyReducer
};
