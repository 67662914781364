import {Box, Button, makeStyles, Paper, Typography} from "@material-ui/core";
import {getLang} from "../../../../../app/feature/constants";
import Dropzone from "react-dropzone";
import classnames from "classnames";
import {UploadImg} from "../../../../account/utils/static";
import React from "react";
import BusinessIcon from "@material-ui/icons/Business";

const style = theme => ({
  paper: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(3)
  },
  imgWrapper: {
    height: 172,
    width: 172,
    borderRadius: "50%",
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden"
  },
  logo: {
    height: "100%",
    minWidth: "100%",
    minHeight: "100%",
    objectFit: "cover",
    flexShrink: 0
  },
  label: {
    textTransform: "none",
    display: "flex",
    flexFlow: "column"
  },
  input: {
    display: "none"
  },
  mr1: {
    backgroundColor: "#4D626A",
    padding: theme.spacing(2),
    "&:hover": {
      backgroundColor: "#415D69"
    },
    color: "#ffffff",
    display: "grid",
    textAlign: "-webkit-center"
  },
  text: {
    color: theme.palette.secondary.main
  },
  dragging: {
    border: "dashed 2px",
    backgroundColor: "#415D69"
  },
  dropzoneContainer: {
    backgroundColor: "#4D626A",
    padding: theme.spacing(2)
  }
});

const useStyles = makeStyles(style);
export default function BranchLogoPanelComponent({
  logo,
  inputImgRef,
  handleImgChange,
  handleDrop,
  dragging,
  handleDragEnter,
  handleDragLeave,
  handleRemove,
  lang
}) {
  const classes = useStyles();

  return (
    <Box>
      <Paper elevation={0} className={classes.paper}>
        <Box style={{ display: "flex" }}>
          <Typography variant="body1" style={{ color: "#ffffff" }}>
            {getLang(lang, "label.BRANCH_LOGO")}
          </Typography>
        </Box>

        <Box style={{ display: "flex", placeContent: "center" }}>
          <Box mt={2} mb={2} className={classes.imgWrapper}>
            {logo ? (
              <img
                src={logo}
                alt="logo"
                className={classes.logo}
              />
            ) : (
              <Box
                style={{
                  height: "100%",
                  minWidth: "100%",
                  minHeight: "100%",
                  borderRadius: '100%',
                  border: '8px solid #6AAF68',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  boxShadow: '0px 0px 4px 0px rgb(0 0 0 / 25%)',
                }}
              >
                <BusinessIcon style={{ fontSize: 64, color: '#6AAF68' }} />
              </Box>
            )}
          </Box>
        </Box>
        <Box>
          <Box mb={1}>
            <Typography
              variant="body1"
              style={{ color: "#ffffff", textAlign: "center" }}
            >
              {getLang(lang, "label.UPLOAD_BRANCH_LOGO")}
            </Typography>
          </Box>

          <Box className={classes.dropzoneContainer}>
            <Dropzone
              onDrop={handleDrop}
              noClick
              noKeyboard
              onDragEnter={handleDragEnter}
              onDragLeave={handleDragLeave}
            >
              {({ getRootProps }) => (
                <div {...getRootProps()}>
                  <input
                    accept="image/*"
                    className={classes.input}
                    id="contained-button-file"
                    type="file"
                    ref={inputImgRef}
                    onChange={handleImgChange}
                    onClick={(e) => {
                      e.target.value = null
                    }}
                  />
                  <label htmlFor="contained-button-file">
                    <Button
                      variant="contained"
                      size="small"
                      component="span"
                      className={classnames(classes.mr1, {
                        [classes.dragging]: dragging
                      })}
                      classes={{ label: classes.label }}
                      disableElevation
                    >
                      <UploadImg />
                      {dragging ? (
                        <Box mt={1}>{getLang(lang, "label.DROP_HERE")}</Box>
                      ) : (
                        <Box
                          mt={1}
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            justifyContent: "center"
                          }}
                        >
                          {getLang(lang, "paragraph.DROP_FILES")}
                          <span className={classes.text}>&nbsp;{getLang(lang, "paragraph.BROWSE")}</span>
                        </Box>
                      )}
                    </Button>
                  </label>
                </div>
              )}
            </Dropzone>
          </Box>

          <Box
            mt={2}
            style={{
              display: "flex",
              placeContent: "center",
              textAlign: "center"
            }}
          >
            <Typography variant="caption" style={{ color: "#ffffff" }}>
              {getLang(lang, "paragraph.RECOMMENDED_FILE_SIZE_LOGO_6")}
            </Typography>
          </Box>

          {logo && handleRemove ? (
            <Box
              mt={2}
              style={{
                display: "flex",
                justifyContent: 'end'
              }}
            >
              <Button
                onClick={handleRemove}
                variant="contained"
                color="error"
              >
                {getLang(lang, "label.REMOVE")}
              </Button>
            </Box>
          ) : null}
        </Box>
      </Paper>
    </Box>
  );
}
