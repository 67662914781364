import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ProductSummaryComponent from "./product-summary.component";
import { useHistory, useLocation } from "react-router-dom";
import { STOCKFLOW_INVENTORY_VIEW } from "lib/constants/accessRights";
import LoadingComponent from "components/loading";
import qs from "query-string";
import { getLang } from "app/feature/constants";
import { INVENTORY_LIST_PAGE } from "modules/stockflow/config/stockflow.route";

function ProductSummaryContainer( {activeSummaryIndex} ) {
  const hasStockflowViewAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(STOCKFLOW_INVENTORY_VIEW)
  );
  const lang = useSelector(state => state.constant.languages);
  const history = useHistory();
  const location = useLocation();
  const [activeIndex, setActiveIndex] = useState(activeSummaryIndex);

  const qsParams = qs.parse(location.search);
  const currentSearch = qsParams.summary || "";

  useEffect(() => {
    if (!hasStockflowViewAccessRight) {
      history.push("/admin/dashboard");
    }
  }, [history, hasStockflowViewAccessRight]);

  useEffect(() => {
    let tempIndex = 0;
    switch (currentSearch) {
      case "to-branch":
        tempIndex = 0;
        break;
      case "to-dealer":
        tempIndex = 1;
        break;
      case "by-branch":
        tempIndex = 2;
        break;
      case "by-dealer":
        tempIndex = 3;
        break;
      default:
        tempIndex = 0;
    }
    setActiveIndex(tempIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!hasStockflowViewAccessRight) return <LoadingComponent />;

  let tabLabels = [];
  if (hasStockflowViewAccessRight) {
    tabLabels.push(getLang(lang, "label.BRANCH_INVENTORY"));
    tabLabels.push(getLang(lang, "label.DEALER_INVENTORY"));
    tabLabels.push(getLang(lang, "label.SUM_BY_BRANCH"));
    tabLabels.push(getLang(lang, "label.SUM_BY_DEALER"));
  }

  const handleChange = (event, index) => {
    setActiveIndex(index);
    history.replace({
      pathname: INVENTORY_LIST_PAGE,
      search: `?type=product&summary=${
        index === 0
          ? "to-branch"
          : index === 1
          ? "to-dealer"
          : index === 2
          ? "by-branch"
          : "by-dealer"
      }`,
    });

  };

  return (
    <>
      <ProductSummaryComponent
        handleChange={handleChange}
        activeIndex={activeIndex}
        tabLabels={tabLabels}
        lang={lang}
      />
    </>
  );
}

export default ProductSummaryContainer;
