import axios from "axios";

export function getTransferSenderDropdown(params) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/distributor/admin/api/v1/transfer/get-sender-dropdown`, {
        params,
      })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data));
  });
}
