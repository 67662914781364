import React, { useEffect, useState } from "react";
import EditProductComponent from "./edit-product.page";
import { useRouteMatch, useHistory } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { ROOT } from "../../config/product-mgmt.route";
import { getProduct } from "../../redux/action/products.action";
import { selectLoading } from "modules/notification";
import { unwrapResult } from "@reduxjs/toolkit";
import { PRODUCT_PRODUCT_LIST_EDIT, PRODUCT_PRODUCT_LIST_VIEW } from "lib/constants/accessRights";
import { setEditorMode, setIsInProductPage } from "modules/product-mgmt/redux/slice/productPage.slice";
import LoadingComponent from "components/loading";

function EditProductContainer({ resetPageEditor, getProduct, isLoading, setIsInProductPage }) {
  const history = useHistory();
  const match = useRouteMatch();
  const id = match.params.productId;
  const hasProductViewAccessRight = useSelector(state => state.profile.accessRights.includes(PRODUCT_PRODUCT_LIST_VIEW));
  const hasProductEditAccessRight = useSelector(state => state.profile.accessRights.includes(PRODUCT_PRODUCT_LIST_EDIT));
  const lang = useSelector(state => state.constant.languages);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    if (!hasProductViewAccessRight) {
      history.push('/admin/dashboard')
    }
  }, [history, hasProductViewAccessRight])

  const handleChange = (event, index) => {
    setActiveIndex(index);
  };

  useEffect(() => {
    if (id) {
      getProduct(id)
        .then(unwrapResult)
        .catch(() => history.push(ROOT));
    } else {
      history.push(ROOT);
    }

    setIsInProductPage(true);
    return () => {
      setIsInProductPage(false);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    resetPageEditor();
  }, [resetPageEditor]);

  if (!hasProductViewAccessRight) return <LoadingComponent />;

  return (
    <EditProductComponent
      hasProductEditAccessRight={hasProductEditAccessRight}
      isLoading={isLoading}
      activeIndex={activeIndex}
      handleChange={handleChange}
      lang={lang}
    />
  );
}

const mapStateToProps = state => ({
  isLoading: selectLoading(state, getProduct.typePrefix)
});

const mapDispatchToProps = dispatch => ({
  resetPageEditor: () => {
    dispatch(setEditorMode(false));
  },
  getProduct: id => dispatch(getProduct(id)),
  setIsInProductPage: (value) => dispatch(setIsInProductPage(value))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditProductContainer);
