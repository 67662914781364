import { FolderType, FolderOwner, UploadType } from "lib/constants/aws_s3";
import moment_timezone from "moment-timezone"
import moment from "moment"

export function isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}

export function displayMessage(language, code) {
  if (code) {
    return language[code] || code
  } else {
    return language.ERROR_SERVER_DOWN
  }
}

export function displayFormMessage(language, value) {
  return language ? language.replace("{value}", value) : language
}

export function getTimestamp() {
  const today = new Date();
  const date = today.getDate();
  const month = today.getMonth(); //Be careful! January is 0 not 1
  const year = today.getFullYear();
  const hour = today.getHours();
  const minute = today.getMinutes();
  const second = today.getSeconds();

  function parseMonth() {
    let nMount = month + 1;
    if (nMount < 10) {
      nMount = "0" + nMount;
    }
    return nMount;
  }

  function parseDate() {
    if (date < 10) {
      return "0" + date;
    }
    return date;
  }

  const timestamp = `${year}-${parseMonth()}-${parseDate()}T${hour}:${minute}:${second}.10Z`;

  return timestamp;
}

export function getUploadS3FilePath({ type, id, fileName }) {
  switch (type) {
    case UploadType.userProfilePrivacyPolicySignature:
      return `${FolderOwner.account}/${id}/${FolderType.privacyPolicy}/${fileName}`;

    case FolderType.warrantyPicture:
      return `${FolderOwner.warrantyConsumer}/${id}/${FolderType.warrantyPicture}/${fileName}`;

    case UploadType.userProfileEndUserAgreementSignature:
      return `${FolderOwner.account}/${id}/${FolderType.endUserAgreement}/${fileName}`;

    case UploadType.companyArticleAssociation:
      return `${FolderOwner.company}/${id}/${FolderType.articleAssociation}/${fileName}`;

    case UploadType.companyDirectorIdentification:
      return `${FolderOwner.company}/${id}/${FolderType.directorIdentification}/${fileName}`;

    case FolderType.profilePicture:
      return `${FolderOwner.account}/${id}/${FolderType.profilePicture}/${fileName}`;

    case FolderType.logo:
      return `${FolderOwner.company}/${id}/${FolderType.logo}/${fileName}`;

    case FolderType.backgroundImageSetting:
      return `${FolderOwner.company}/${id}/${FolderType.backgroundImageSetting}/${fileName}`;

    case FolderType.consumerBanner:
      return `${FolderOwner.company}/${id}/${FolderType.consumerBanner}/${fileName}`

    case UploadType.productPageUi:
      return `${FolderOwner.product}/${fileName}`;

    case FolderType.brandImage:
      return `${FolderOwner.product}/${FolderType.brandImage}/${fileName}`;

    case UploadType.productImage:
      return `${FolderOwner.productImage}/${fileName}`;

    case UploadType.productPageComponent:
      return `${FolderOwner.productPageComponent}/${fileName}`;

    case UploadType.warrantyTermAndCondition:
      return `${FolderOwner.warranty}/${id}/${FolderType.termAndCondition}/${fileName}`;

    case UploadType.luckyDrawTermAndCondition:
      return `${FolderOwner.luckyDraw}/${id}/${FolderType.termAndCondition}/${fileName}`;

    case FolderType.luckyDrawImage:
      return `${FolderOwner.luckyDraw}/${id}/${FolderType.luckyDrawImage}/${fileName}`;

    case FolderType.luckyDrawprizeImage:
      return `${FolderOwner.luckyDraw}/${id}/${FolderType.luckyDrawprizeImage}/${fileName}`;

    case UploadType.instantLuckyDrawTermAndCondition:
      return `${FolderOwner.instantLuckyDraw}/${id}/${FolderType.termAndCondition}/${fileName}`;

    case FolderType.instantLuckyDrawImage:
      return `${FolderOwner.instantLuckyDraw}/${id}/${FolderType.instantLuckyDrawImage}/${fileName}`;

    case FolderType.instantprizeImage:
      return `${FolderOwner.instantLuckyDraw}/${id}/${FolderType.instantprizeImage}/${fileName}`;

    case UploadType.giftCodeExcel:
      return `${FolderOwner.instantLuckyDraw}/${id}/${UploadType.giftCodeExcel}/${fileName}`;

    case FolderType.giftImage:
      return `${FolderOwner.redemptionCenter}/${id}/${FolderType.giftImage}/${fileName}`;

    case FolderType.stockflowRewardImage:
      return `${FolderOwner.stockflowReward}/${id}/${FolderType.stockflowRewardImage}/${fileName}`;

    case UploadType.stockflowRewardTermAndCondition:
      return `${FolderOwner.stockflowReward}/${id}/${FolderType.termAndCondition}/${fileName}`;

    case UploadType.privacyPolicyTermAndCondition:
      return `${FolderOwner.company}/${id}/${FolderType.privacyPolicy}/${fileName}`;

    case FolderType.warrantyReimbursement:
      return `${FolderOwner.warrantyReimbursement}/${id}/${FolderType.warrantyReimbursement}/${fileName}`;

    case FolderType.branchProfile:
      return `${FolderOwner.branch}/${id}/${FolderType.branchProfile}/${fileName}`;

    default:
      break;
  }
}

export function isFile(input) {
  if ('File' in window && input instanceof File)
    return true;
  else return false;
}

export function isPDF(file) {
  if (!isFile(file)) {
    return false
  }

  if (file.type.slice(file.type.indexOf("/") + 1, file.type.length) !== "pdf") {
    return false
  }

  return true
}

export function isImage(file) {
  if (!isFile(file)) {
    return false
  }

  if (file.type.slice(0, file.type.indexOf("/")) !== "image") {
    return false
  }

  return true
}

export function isFileExceedLimit(size, limit, type) {
  let maxSize = 0

  switch (type) {
    case 'KB':
      maxSize = limit * 1024
      break;
    case 'MB':
      maxSize = limit * 1024 * 1024
      break;
    case 'GB':
      maxSize = limit * 1024 * 1024 * 1024
      break;
    default:
      break;
  }

  if (size > maxSize) {
    return true
  }

  return false
}

export function isExcel(file) {
  if (!isFile(file)) {
    return false;
  }

  if (file.type.slice(file.type.indexOf("/") + 1, file.type.length) !== "vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
    return false
  }

  return true
}

export function timezoneOffSetFormatTime(time, timezone) {
  const now = new Date().getTime()
  const utcOffset = moment_timezone.tz.zone(timezone).utcOffset(now);

  return moment(time).add(utcOffset, 'minute').format('YYYY-MM-DD HH:mm');
}

export function isObject(obj) {
  return obj.constructor === Object
}

export function isArray(array) {
  return array.constructor === Array
}

export function generatePaginationOptions(totalRecords, options = [25, 50, 100, 200, 400]) {
  //Change paginationOptions if want to update default available options
  let paginationOptions = options;
  let indexPlusOne = paginationOptions.filter((option) => option < totalRecords).length;
  if (indexPlusOne < (paginationOptions.length - 1)) {
    paginationOptions.splice(indexPlusOne - (paginationOptions.length - 1));
  }
  // If want to increase the options indefinitely, uncomment the code below
  // else {
  //   for (let nextNum = Math.max(...paginationOptions); nextNum < totalRecords; ){
  //     nextNum = nextNum * 2;
  //     paginationOptions.push(nextNum);
  //   }
  // }
  return paginationOptions;
}

export function displayValueMessage(language, valueObject) {
  let message = language

  if (!message) {
    return ""
  }

  if (valueObject === undefined || valueObject === null || valueObject === {}) {
    return ""
  }
  else {
    Object.keys(valueObject).forEach((key) => {
      message = message.replace(`{${key}}`, valueObject[key])
    })
  }
  return message
}

export function getObjectByString(o, s) {
  try {
    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, '');           // strip a leading dot
    var a = s.split('.');
    for (var i = 0, n = a.length; i < n; ++i) {
      var k = a[i];
      if (k in o) {
        o = o[k];
      } else {
        return;
      }
    }
    return o;
  }
  catch {
    return null;
  }
}

export function updateUrlQueryParam(paramToAdd = {}, keyToRemove = []) {
  const query = new URLSearchParams(window.location.search);

  keyToRemove.forEach((key) => {
    query.delete(key);
  })

  if (paramToAdd) {
    Object.keys(paramToAdd).forEach((key) => {
      query.set(key, paramToAdd[key]);
    })
  }

  let queryString = window.location.origin + window.location.pathname
  if (Array.from(query).length || (paramToAdd && Object.keys(paramToAdd).length)) {
    queryString = `?${query}`
  }

  window.history.replaceState({}, '', queryString);
}

export function capitalizeEveryWord(string) {
  const words = string.split(' ');

  const capitalizedWords = words.map(word => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });

  return capitalizedWords.join(' ');
}
