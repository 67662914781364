import React from "react";
import GalleryComponent from "./gallery.component";
import { useSelector } from "react-redux";

export default function GalleryContainer({ id }) {
  const {
    isVisible,
    content: { images, order }
  } = useSelector(state => state.productPage.components[id]);
  const lang = useSelector(state => state.constant.languages);
  const isEnableFullscreenBtn = useSelector(state => state.productPage.components[id].content.isEnableFullscreenBtn ?? false);

  return (
    isVisible && <GalleryComponent order={order} images={images} lang={lang} isEnableFullscreenBtn={isEnableFullscreenBtn}/>
  );
}
