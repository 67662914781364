import React from "react"
import {
  Box,
  Paper,
  Typography,
  makeStyles
} from "@material-ui/core";
import EmptyComponent from 'components/empty';
import clsx from 'clsx';
import { getLang } from "app/feature/constants";

const useStyles = makeStyles((theme) => ({
  container: {
    boxShadow: "0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);",
    borderRadius: 6,
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: 'column'
  },
  emptyContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  }
}));

export default function SurveyDeviceInfoCardComponent({
  data,
  total,
  lang
}) {
  const classes = useStyles()

  return (
    <Paper className={classes.container}>
      <Box >
        <Typography variant="subtitle1" color='textPrimary' style={{ fontWeight: "500" }}>{getLang(lang, 'label.DEVICE')}</Typography>
      </Box>
      <Box className={clsx({
        [classes.emptyContainer] : data.length === 0
      })}>
        {data.length > 0 ? data.map((row, index) => (
          <Box mb={3} display="flex" justifyContent="space-between" key={index}>
            <Typography variant="subtitle1" color='textSecondary' style={{ textTransform: 'capitalize' }}>{row.device}</Typography>
            <Box display="flex">
              <Typography variant="h5" style={{ fontWeight: 500, marginRight: 4 }}>{row.survey_count}</Typography>
              <Typography variant="subtitle1" color='textSecondary'>( {row.survey_count / total * 100}% )</Typography>
            </Box>
          </Box>
        )) : (
          <Box>
            <EmptyComponent />
          </Box>
        )}

      </Box>
    </Paper>
  )
}