import LoadingPanel from "./loading-panel";
import BranchSalesPanel from "./branch-sales-panel";
import DemographicPanel from "./demographic-panel";
import CampaignConversionPanel from "./campaign-conversion-panel";
import MiscellaneousPanel from './miscellaneous-panel';
import FilterPanelV3 from './filter-panel';
import ProductPanel from "./product-panel";

import ProductCategoryPanel from "./product-category-panel";
import ProductBrandPanel from "./product-brand-panel";
import ProductModelPanel from "./product-model-panel";
import ProductListingPanel from "./product-listing-panel";
import SuspendedProductPanel from "./suspended-product-panel";
import AgeGenderPanel from "./age-gender-panel";

import ConsumerScanPanel from './consumer-scan-panel';
import CampaignPerformancePanel from "./campaign-performance-panel";
import CampaignRespodentPanel from './campaign-respondent-panel';
import SurveyListPanel from "./survey-list-panel";
import LuckyDrawListPanel from "./lucky-draw-list-panel";
import InstantLuckyDrawListPanel from './instant-lucky-draw-list-panel';
import LoyaltyProgramListPanel from './loyalty-program-list-panel';
import WarrantyListPanel from './warranty-list-panel';
import ConsumerPanel from './consumer-panel';

import DealershipPanel from './dealership-panel';
import StockoutCategoryPanel from './stockout-category-panel';
import StockoutBrandPanel from './stockout-brand-panel'; 
import StockoutModelPanel from './stockout-model-panel';

import ProductDetailPanel from './product-detail-panel';
import ProductCategoryDetailPanel from './product-category-detail-panel';
import ProductBrandDetailPanel from './product-brand-detail-panel';
import ProductModelDetail from './product-model-detail-panel'

import DemographicCampaignPanel from './demographic-campaign-panel';
import BranchSalesCampaignPanel from './branch-sales-campaign-panel';

import CampaignInstantLuckyDrawPanel from './campaign-instant-lucky-draw-panel';
import CampaignLoyaltyProgramPanel from './campaign-loyalty-program-panel';
import CampaignLuckyDrawPanel from './campaign-lucky-draw-panel';
import CampaignSurveyPanel from './campaign-survey-panel';
import CampaignWarrantyPanel from './campaign-warranty-panel';

import RespondentsConsumerAllSurveyPanel from './respondents-consumer-all-survey-panel';
import RespondentsConsumerAllLuckyDrawPanel from './respondents-consumer-all-lucky-draw-panel';
import RespondentsConsumerAllInstantLuckyDrawPanel from './respondents-consumer-all-instant-lucky-draw-panel';
import RespondentsConsumerAllLoyaltyProgramPanel from './respondents-consumer-all-loyalty-program-panel';
import RespondentsConsumerAllWarrantyPanel from './respondents-consumer-all-warranty-panel';

import RespondentSingleCampaignPanel from './respondent-single-campaign-panel';
import CampaignSummaryPanel from './campaign-summary-panel';
import CampaignTrendingPanel from './campaign-trending-panel';
import CampaignProductPanel from './campaign-product-panel';

import ScanCountAgainstSuspendedPanel from './scan-count-against-suspended-panel';
import CampaignPrizePanel from './campaign-prize-panel'

import WinnerParticipantPanel from './winner-participant-panel';
import StockoutProductPanel from './stockout-product-panel';
import StockoutDealerPanel from './stockout-dealer-panel';
import StockoutEmployeePanel from './stockout-employee-panel';

export {
    AgeGenderPanel,
    LoadingPanel,
    BranchSalesPanel,
    DemographicPanel,
    CampaignConversionPanel,
    MiscellaneousPanel,
    FilterPanelV3,
    ProductPanel,
  
    ProductCategoryPanel,
    ProductBrandPanel,
    ProductModelPanel,
    ProductListingPanel,
    SuspendedProductPanel,
    ConsumerScanPanel,

    CampaignRespodentPanel,
    CampaignPerformancePanel,
    SurveyListPanel,
    LuckyDrawListPanel,
    InstantLuckyDrawListPanel,
    LoyaltyProgramListPanel,
    WarrantyListPanel,

    ConsumerPanel,

    DealershipPanel,
    StockoutCategoryPanel,
    StockoutBrandPanel,
    StockoutModelPanel,

    ProductDetailPanel,
    ProductCategoryDetailPanel,
    ProductBrandDetailPanel,
    ProductModelDetail,

    DemographicCampaignPanel,
    BranchSalesCampaignPanel,

    CampaignInstantLuckyDrawPanel,
    CampaignLoyaltyProgramPanel,
    CampaignLuckyDrawPanel,
    CampaignSurveyPanel,
    CampaignWarrantyPanel,

    RespondentsConsumerAllSurveyPanel,
    RespondentsConsumerAllLuckyDrawPanel,
    RespondentsConsumerAllInstantLuckyDrawPanel,
    RespondentsConsumerAllLoyaltyProgramPanel,
    RespondentsConsumerAllWarrantyPanel,
    RespondentSingleCampaignPanel,

    CampaignSummaryPanel,
    CampaignTrendingPanel,
    CampaignProductPanel,
    
    ScanCountAgainstSuspendedPanel,
    CampaignPrizePanel,

    WinnerParticipantPanel,
    StockoutProductPanel,
    StockoutDealerPanel,
    StockoutEmployeePanel
}