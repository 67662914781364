import React from "react";
import {
  Box,
  Divider,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import RefreshTable from "components/table/refreshTable";
import InfiniteScroll from "react-infinite-scroll-component";
import LoadingComponent from "components/loading";
import DefaultImg from "assets/img/img-default.png";
import { getLang } from "app/feature/constants";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles((theme) => ({
  panel: {
    backgroundColor: "white",
    border: "1px solid #d0d5dd",
    borderRadius: 4,
  },
  productPhoto: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #E4E7EC",
    width: "30px",
    height: "30px",
    marginRight: "10px",
    "& img": {
      height: "30px",
      width: "30px",
    },
  },
  name: {
    whiteSpace: "normal",
    overflow: "hidden",
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "line-clamp": 2,
    "-webkit-box-orient": "vertical",
  },
  attributeText: {
    color: "#b2b2b2",
    fontSize: 12,
    whiteSpace: "break-spaces",
  },
  actionButton: {
    border: "1px solid #E4E7EC",
    height: 30,
    width: 30,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  inputRootID: {
    textAlign: "center",
  },
  list: {
    maxHeight: "400px",
    overflowY: "auto",
  },
}));

export default function RequestTransferProductListPanelComponent({
  lang,
  list,
  isError,
  totalFiltered,
  handleFetchMoreItems,
  handleReload,
  isFetching,
  updateQuantity,
  products,
}) {
  const classes = useStyles();
  const numberReg = /^-?\d*(\.\d*)?$/;

  return (
    <Box className={classes.panel}>
      {isError ? (
        <Box style={{ textAlign: "center" }}>
          <RefreshTable handleReload={handleReload} />
        </Box>
      ) : (
        <Box>
          <Box display="flex" justifyContent="space-between" p={1}>
            <Typography variant="body1" style={{ marginRight: 8 }}>
              {getLang(lang, "label.TOTAL_REQUEST_ITEMS")}:
            </Typography>
            <Typography variant="body1">
              <b>
                {Object.values(products).reduce((accumulator, value) => {
                  return accumulator + value;
                }, 0)}
              </b>
            </Typography>
          </Box>
          <Divider />
          <Box p={0.5}>
            <InfiniteScroll
              height="100%"
              className={classes.list}
              dataLength={list.length}
              next={handleFetchMoreItems}
              hasMore={totalFiltered > list.length}
              loader={<LoadingComponent height={100} />}
              endMessage={
                !list.length ? (
                  isFetching ? (
                    <LoadingComponent height={100} />
                  ) : (
                    <Box px={1.5} py={4}>
                      <Typography
                        variant="body2"
                        style={{ textAlign: "center" }}
                      >
                        <b>{getLang(lang, "label.NO_PRODUCT_AVAILABLE")}</b>
                      </Typography>
                    </Box>
                  )
                ) : null
              }
            >
              <Box>
                {list.map((product, index) => {
                  return (
                    <Box key={index} style={{ padding: 8 }}>
                      <Box display="flex" alignItems="center" width="100%">
                        <Box className={classes.productPhoto}>
                          {product.picture === "" ? (
                            <img src={DefaultImg} alt="emptyProduct" />
                          ) : (
                            <img src={product.picture} alt={product.name} />
                          )}
                        </Box>
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          alignItems="flex-start"
                          ml={1}
                          flex={1}
                        >
                          <Typography className={classes.name} variant="body2">
                            {product.name}
                          </Typography>
                          {!!product.attributes &&
                            !!product.attributes.length && (
                              <Typography
                                className={classes.attributeText}
                                variant="body2"
                              >
                                {product.attributes.join(", ")}
                              </Typography>
                            )}
                        </Box>
                        <Box display="flex" alignItems="center">
                          <Box className={classes.actionButton}>
                            <RemoveIcon
                              onClick={() => {
                                if (Object.hasOwn(products, product.uuid)) {
                                  if (products[product.uuid] > 0) {
                                    updateQuantity(
                                      product,
                                      products[product.uuid] - 1
                                    );
                                  }
                                }
                              }}
                            />
                          </Box>
                          <TextField
                            hiddenLabel
                            size="small"
                            margin="none"
                            InputProps={{
                              disableUnderline: true,
                              margin: "none",
                              style: {
                                padding: 0,
                                backgroundColor: "white",
                                border: "1px solid #E4E7EC",
                                width: 50,
                                height: 30,
                              },
                              classes: { input: classes.inputRootID },
                            }}
                            onChange={(e) => {
                              let val = e.target.value;
                              if (!isNaN(val) && numberReg.test(val)) {
                                updateQuantity(product, e.target.value);
                              }
                            }}
                            value={
                              Object.hasOwn(products, product.uuid)
                                ? products[product.uuid]
                                : 0
                            }
                          />
                          <Box
                            className={classes.actionButton}
                            onClick={() => {
                              if (Object.hasOwn(products, product.uuid)) {
                                updateQuantity(
                                  product,
                                  products[product.uuid] + 1
                                );
                              } else {
                                updateQuantity(product, 1);
                              }
                            }}
                          >
                            <AddIcon />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </InfiniteScroll>
          </Box>
        </Box>
      )}
    </Box>
  );
}
