import React from "react"
import {
  makeStyles,
  Typography,
  Box,
  // Button,
} from "@material-ui/core"
import {
  ChevronRightRounded as ChevronRightRoundedIcon
} from "@material-ui/icons"
import { BackButton } from "components/button"
// import { DownloadSVG } from "modules/stockflow/utils/static"
import PackageInfoCard from "modules/stockflow/components/card/packageInfoCard"
import PackageActivityLogPanel from "modules/stockflow/components/panel/activityLogPanel"
import LooseItemListPanel from "modules/stockflow/components/panel/looseItemListPanel"
import { getLang } from "app/feature/constants"

// const API_HOST = process.env.REACT_APP_API_HOST_URL

const useStyle = makeStyles(theme => ({
  panelContainer: {
    display: "grid",
    gridTemplateColumns: "6fr 4fr",
    gridGap: "1em",
    gridTemplateAreas: "'left-panel right-panel'",
    [theme.breakpoints.down("sm")]: {
      gridTemplateAreas: "'left-panel left-panel''right-panel right-panel'"
    }
  }
}))

export default function PackageDetailComponent({
  hasStockflowDealerViewAccessRight,
  packageDetail,
  language,
  isLoading,
  lang
}) {
  const classes = useStyle()

  return (
    <Box className={classes.root}>
      <Box gridArea="topbar" mb={3}>
        <Box
          mb={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="flex" alignItems="flex-end" fontWeight="bold">
            <BackButton />
            <Box display="flex" ml={1} alignItems="flex-end">
              <Typography
                variant="h6"
                color="primary"
              >
                {getLang(lang, "label.PACKAGE_TAB")}
              </Typography>
              <Box mx={0.5}>
                <ChevronRightRoundedIcon color="disabled" />
              </Box>
              <Box>
                <Typography variant="h6" color="primary">
                  {getLang(lang, "label.VIEW_DETAILS")}
                </Typography>
              </Box>
            </Box>
          </Box>
          {/* <Box>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              style={{ padding: "5px 12px", background: "white", borderRadius: "8px" }}
              disableElevation
              onClick={() => {}}
              startIcon={<DownloadSVG />}
              disabled={isLoading}
            >
              EXPORT XLS
            </Button>
          </Box> */}
        </Box>
      </Box>
      <Box gridArea="panel">
        <Box className={classes.panelContainer}>
          <Box gridArea="left-panel">
            <Box mb={2}>
              <PackageInfoCard
                hasStockflowDealerViewAccessRight={hasStockflowDealerViewAccessRight}
                packageDetail={packageDetail}
                language={language}
                isFetching={isLoading}
                lang={lang}
              />
            </Box>
            <LooseItemListPanel 
              packageDetail={packageDetail}
              isFetching={isLoading}
              lang={lang}
            />
          </Box>
          <Box gridArea="right-panel">
            <PackageActivityLogPanel type="package" loading={isLoading} language={language} />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
