import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Tab from "@material-ui/core/Tab";
import { withStyles } from "@material-ui/core/styles";

// We can inject some CSS into the DOM.
const styles = theme => ({
  root: {
    padding: 0,
    marginRight: 20,
    // borderBottom: "#3A4D54 2px solid",
    alignItems: "center",
    minWidth: 0,
    textTransform: "none",
    overflow: "visible"
  },
  wrapper: {
    alignItems: "flex-start"
  }
});

function CustomTab(props) {
  const { classes, className, disabled, ...other } = props;

  return (
    <Tab
      classes={{ wrapper: classes.wrapper }}
      className={clsx(classes.root, className)}
      disabled={disabled}
      {...other}
    />
  );
}

CustomTab.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string
};

export default withStyles(styles)(CustomTab);
