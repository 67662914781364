import React, { useRef, useState } from "react";
import BlankPrizeCardComponent from "./blankPrizeCard.component";
import { useDispatch, useSelector } from "react-redux";
import { isFile } from "lib/helper";
import { v4 as uuidv4 } from "uuid";
import { toBase64 } from "modules/product-mgmt/utils/productPage.util";
import { FolderType } from "lib/constants/aws_s3";
import { uploadImageToS3 } from "app/feature";
import { unwrapResult } from "@reduxjs/toolkit";
import { addAlert } from "modules/notification";
import { hideBackdrop, showBackdrop } from "modules/backdrop";
import { updatePrize, setPrizeImage } from "modules/instant-lucky-draw/redux";
import { INSTANT_LUCKY_DRAW_EDIT } from "lib/constants/accessRights";
import { getLang } from "app/feature/constants";

function BlankPrizeCardContainer({
  AddingPrizeFlag,
  AddPrize,
  handleIsEditing,
  handleBlankPrizeEdit,
  handleNewDelete
}) {
  const [type, setType] = useState("view");
  const hasInstantLuckyDrawEditAccessRight = useSelector(state => state.profile.accessRights.includes(INSTANT_LUCKY_DRAW_EDIT))
  const userId = useSelector((state) => state.session.idTokenPayload.sub);
  const defaultPrize = useSelector(
    (state) => state.instantLuckyDraw.prizeDialogInfo.defaultPrizeDetail
  );
  const lang = useSelector(state => state.constant.languages);
  const dispatch = useDispatch();

  const inputImgRef = useRef();

  const handleImgChange = (formik, e) => {
    const file = inputImgRef.current.files[0];
    if (file) {
      formik.setFieldValue("prizeImage", e.currentTarget.files[0]);
    }
  };

  const changeToEdit = () => {
    setType("edit");
    handleBlankPrizeEdit()
    AddPrize();
  };

  const handleDisableEdit = () => {
    setType("view")
    handleIsEditing();
    handleNewDelete()
  }

  const handleSubmit = async (values) => {
    const updateInfo = {
      ...values,
      id: defaultPrize.id,
      instantLuckyDrawId: defaultPrize.instantLuckyDrawId
    };

    dispatch(showBackdrop());
    if (isFile(values.prizeImage)) {
      const fileName = `${uuidv4()}.png`;
      const base64Image = await toBase64(values.prizeImage);
      const uploadDefaultPrizeImage = {
        uploadType: FolderType.instantLuckyDrawImage,
        base64Image: base64Image,
        id: userId,
        fileName: fileName,
      };

      await dispatch(uploadImageToS3(uploadDefaultPrizeImage))
        .then(unwrapResult)
        .then(async (imageUrl) => {
          updateInfo.prizeImage = imageUrl.split("?")[0];
          await dispatch(updatePrize(updateInfo))
            .then(unwrapResult)
            .then(() => {
              setType("view");
              handleIsEditing();
              handleNewDelete()
            })
            .finally(() => {
              dispatch(setPrizeImage(updateInfo.prizeImage))
              dispatch(hideBackdrop());
            });
        })
        .catch((error) => {
          dispatch(
            addAlert({
              severity: "error",
              message: getLang(lang, "message.error.FAILED_UPLOAD_IMAGE")
            })
          );
          dispatch(hideBackdrop());
          return;
        });
    } else {
      await dispatch(updatePrize(updateInfo))
        .then(unwrapResult)
        .then(() => {
          setType("view");
          handleIsEditing();
          handleNewDelete()
        })
        .finally(() => {
          dispatch(hideBackdrop());
        });
    }
  };

  return (
    <BlankPrizeCardComponent
      hasInstantLuckyDrawEditAccessRight={hasInstantLuckyDrawEditAccessRight}
      type={type}
      prizeDetail={defaultPrize}
      handleImgChange={handleImgChange}
      inputImgRef={inputImgRef}
      handleSubmit={handleSubmit}
      changeToEdit={changeToEdit}
      handleDisableEdit={handleDisableEdit}
      AddingPrizeFlag={AddingPrizeFlag}
      lang={lang}
    />
  );
}

export default BlankPrizeCardContainer;
