import React, {useState, useCallback} from "react";
import PropTypes from "prop-types";
import DatePickerDialogComponent from "./datepickerDialog.component";
import { useSelector } from "react-redux";
import moment from 'moment';

function DatePickerDialogContainer({
  label,
  displayFormat,
  forceDisplay,
  handleChange,
  value,
  enableTime,
  disablePast,
  disableFuture,
  inputProps,
  InputProps,
  InputLabelProps,
  style,
}) {
  value = value === undefined || value === "" ? null : moment(value);

  const [isOpen, setIsOpen] = useState(false);
  const [startYear] = useState(parseInt(new moment().subtract(150, 'years').format('YYYY')));
  const [months] = useState(moment.months());
  const [monthsNoFuture] = useState(moment.months().slice(0, moment().month() + 1));
  const [monthsNoPast] = useState(moment.months().slice(moment().month()));
  const [pickerValue, setPickerValue] = useState({
    year: value ? value.format("YYYY") : moment().format("YYYY"),
    month: value ? value.format("MMM") : moment().format("MMM"),
    day:  value ? value.format("DD") : moment().format("DD"),
    hour: value ? value.format("HH") : moment().format("HH"),
    minute: value ? value.format("HH") : moment().format("HH"),
  })
  const [date, setDate] = useState();
  const [defaultDate] = useState(
    value
      ? enableTime
        ? value.format("DD MMM YYYY HH:mm")
        : value.format("DD MMM YYYY")
      : null
  )
  const lang = useSelector(state => state.constant.languages);

  function getDayArray(year , month) {
    const dayCount = new moment(year+"/"+month+"/1",'YYYY/MMMM/DD').daysInMonth()
    return Array.from({length: dayCount}, (x, i) => moment(year+"/"+month+"/1",'YYYY/MMMM/DD').startOf('month').add(i, 'days').format('DD'))
  }

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setIsOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setIsOpen(false);
    setAnchorEl(null);
  }

  const handlePickerChange = useCallback((newValue, key) => {
    if (key === 'day' ) {
      setPickerValue(newValue)
      return
    }
    const { year, month } = newValue
    const newDayArray = getDayArray(Number(year), month)
    const newDay = newDayArray.includes(newValue.day) ? newValue.day : newDayArray[newDayArray.length - 1]
    setPickerValue({ ...newValue, day: newDay })
  }, [])

  function selectDate(){
    setIsOpen(false);
    const day = pickerValue.day;
    const month = pickerValue.month; 
    const year = pickerValue.year;
    const hour = pickerValue.hour;
    const minute = pickerValue.minute;

    setDate(new moment([day, month, year,  hour + ':' + minute].join(" "))); // for text field display
    handleChange(new moment([day, month, year, hour + ':' + minute].join(" "),enableTime ? "DD MMM YYYY HH:mm" : "DD MMMM YYYY")) // for form request
  }

  return (
    <DatePickerDialogComponent
      label={label}
      isOpen={isOpen}
      handleClick={handleClick}
      handleClose={handleClose}
      defaultDate={defaultDate}
      lang={lang}
      date={date}
      disablePast={disablePast}
      disableFuture={disableFuture}
      months={months}
      monthsNoPast={monthsNoPast}
      monthsNoFuture={monthsNoFuture}
      startYear={startYear}
      pickerValue={pickerValue}
      setPickerValue={setPickerValue}
      handlePickerChange={handlePickerChange}
      getDayArray={getDayArray}
      selectDate={selectDate}
      anchorEl={anchorEl}
      displayFormat={displayFormat}
      enableTime={enableTime}
      inputProps={inputProps}
      InputLabelProps={InputLabelProps}
      InputProps={InputProps}
      style={style}
      forceDisplay={forceDisplay}
    />
  );
}

DatePickerDialogContainer.propTypes = {
  handleChange: PropTypes.func.isRequired
};

export default DatePickerDialogContainer;
