import axios from "axios";

const url = "/serialnumber/api/v1/product/brand-models/add-models-to-existing-brand";

export function addModelsToExistingBrand({
  brand_uuid,
  models,
}) {

  return new Promise((resolve, reject) => {
    axios
      .post(url, {
        brand_uuid,
        models,
      })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data));
  });
}
