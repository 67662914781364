import React from "react";
import { Box, CircularProgress, Typography } from "@material-ui/core";
import { getLang } from "app/feature/constants";
import { useSelector } from "react-redux";

function CheckoutLoading() {
  const lang = useSelector(state => state.constant.languages)
  return (
    <Box py={6} display="flex" flexDirection="column" alignItems="center">
      <CircularProgress color="primary" />
      <Box mt={2}>
        <Typography variant="body2" color="textSecondary">
          {getLang(lang, 'label.MAKING_PAYMENT')}
        </Typography>
      </Box>
    </Box>
  );
}

export default CheckoutLoading;
