import { addAlert, setLoading } from "modules/notification";
import { getProductBrandData, getProductBrandDetailData, getProductCategoryData, getProductCategoryDetailData, getProductData, getProductDetailData, getProductListingData, getProductModelData, getProductModelDetailData, getSuspendedListData } from "../action";

// (1) product data -- start
export const getProductDataMiddleware = ({ dispatch, getState}) => next => action => {
    next(action);
    if (action.type === getProductData.pending.type) {
        dispatch(setLoading({ id: getProductData.typePrefix, state: true }));
    }
    if (action.type === getProductData.fulfilled.type) {
        dispatch(setLoading({ id: getProductData.typePrefix, state: false }));
    }
    if (action.type === getProductData.rejected.type) {
        dispatch(setLoading({ id: getProductData.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: action.payload.message}));
    }
}
// (1) product data -- end

// (2) product detail data -- start
export const getProductDetailDataMiddleware = ({ dispatch, getState}) => next => action => {
    next(action);
    if (action.type === getProductDetailData.pending.type) {
        dispatch(setLoading({ id: getProductDetailData.typePrefix, state: true }));
    }
    if (action.type === getProductDetailData.fulfilled.type) {
        dispatch(setLoading({ id: getProductDetailData.typePrefix, state: false }));
    }
    if (action.type === getProductDetailData.rejected.type) {
        dispatch(setLoading({ id: getProductDetailData.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: action.payload.message}));
    }
}
// (2) product detail data -- end

// (3) product category data -- start
export const getProductCategoryDataMiddleware = ({ dispatch, getState}) => next => action => {
    next(action);
    if (action.type === getProductCategoryData.pending.type) {
        dispatch(setLoading({ id: getProductCategoryData.typePrefix, state: true }));
    }
    if (action.type === getProductCategoryData.fulfilled.type) {
        dispatch(setLoading({ id: getProductCategoryData.typePrefix, state: false }));
    }
    if (action.type === getProductCategoryData.rejected.type) {
        dispatch(setLoading({ id: getProductCategoryData.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: action.payload.message}));
    }
}
// (3) product category data -- end

// (4) product brand data -- start
export const getProductBrandDataMiddleware = ({ dispatch, getState}) => next => action => {
    next(action);
    if (action.type === getProductBrandData.pending.type) {
        dispatch(setLoading({ id: getProductBrandData.typePrefix, state: true }));
    }
    if (action.type === getProductBrandData.fulfilled.type) {
        dispatch(setLoading({ id: getProductBrandData.typePrefix, state: false }));
    }
    if (action.type === getProductBrandData.rejected.type) {
        dispatch(setLoading({ id: getProductBrandData.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: action.payload.message}));
    }
}
// (4) product brand data -- end

// (5) product model data -- start
export const getProductModelDataMiddleware = ({ dispatch, getState}) => next => action => {
    next(action);
    if (action.type === getProductModelData.pending.type) {
        dispatch(setLoading({ id: getProductModelData.typePrefix, state: true }));
    }
    if (action.type === getProductModelData.fulfilled.type) {
        dispatch(setLoading({ id: getProductModelData.typePrefix, state: false }));
    }
    if (action.type === getProductModelData.rejected.type) {
        dispatch(setLoading({ id: getProductModelData.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: action.payload.message}));
    }
}
// (5) product model data -- end

// (6) product listing data -- start
export const getProductListingDataMiddleware = ({ dispatch, getState}) => next => action => {
    next(action);
    if (action.type === getProductListingData.pending.type) {
        dispatch(setLoading({ id: getProductListingData.typePrefix, state: true }));
    }
    if (action.type === getProductListingData.fulfilled.type) {
        dispatch(setLoading({ id: getProductListingData.typePrefix, state: false }));
    }
    if (action.type === getProductListingData.rejected.type) {
        dispatch(setLoading({ id: getProductListingData.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: action.payload.message}));
    }
}
// (6) product listing data -- end


// (8) suspended list -- start
export const getSuspendedListDataMiddleware = ({ dispatch, getState}) => next => action => {
    next(action);
    if (action.type === getSuspendedListData.pending.type) {
        dispatch(setLoading({ id: getSuspendedListData.typePrefix, state: true }));
    }
    if (action.type === getSuspendedListData.fulfilled.type) {
        dispatch(setLoading({ id: getSuspendedListData.typePrefix, state: false }));
    }
    if (action.type === getSuspendedListData.rejected.type) {
        dispatch(setLoading({ id: getSuspendedListData.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: action.payload.message}));
    }
}
// (8) suspended list -- end

// (9) get product category detail data -- start
export const getProductCategoryDetailDataMiddleware = ({ dispatch, getState}) => next => action => {
    next(action);
    if (action.type === getProductCategoryDetailData.pending.type) {
        dispatch(setLoading({ id: getProductCategoryDetailData.typePrefix, state: true }));
    }
    if (action.type === getProductCategoryDetailData.fulfilled.type) {
        dispatch(setLoading({ id: getProductCategoryDetailData.typePrefix, state: false }));
    }
    if (action.type === getProductCategoryDetailData.rejected.type) {
        dispatch(setLoading({ id: getProductCategoryDetailData.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: action.payload.message}));
    }
}
// (9) get product category detail data -- end

// (10) get product brand detail data -- start
export const getProductBrandDetailDataMiddleware = ({ dispatch, getState}) => next => action => {
    next(action);
    if (action.type === getProductBrandDetailData.pending.type) {
        dispatch(setLoading({ id: getProductBrandDetailData.typePrefix, state: true }));
    }
    if (action.type === getProductBrandDetailData.fulfilled.type) {
        dispatch(setLoading({ id: getProductBrandDetailData.typePrefix, state: false }));
    }
    if (action.type === getProductBrandDetailData.rejected.type) {
        dispatch(setLoading({ id: getProductBrandDetailData.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: action.payload.message}));
    }
}
// (10) get product brand detail data -- end

// (11) get product model detail data -- start
export const getProductModelDetailDataMiddleware = ({ dispatch, getState}) => next => action => {
    next(action);
    if (action.type === getProductModelDetailData.pending.type) {
        dispatch(setLoading({ id: getProductModelDetailData.typePrefix, state: true }));
    }
    if (action.type === getProductModelDetailData.fulfilled.type) {
        dispatch(setLoading({ id: getProductModelDetailData.typePrefix, state: false }));
    }
    if (action.type === getProductModelDetailData.rejected.type) {
        dispatch(setLoading({ id: getProductModelDetailData.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: action.payload.message}));
    }
}
// (11) get product model detail data -- end

export default [
    getProductDataMiddleware,
    getProductDetailDataMiddleware,
    getProductCategoryDataMiddleware,
    getProductBrandDataMiddleware,
    getProductModelDataMiddleware,
    getProductListingDataMiddleware,
    getSuspendedListDataMiddleware,
    getProductCategoryDetailDataMiddleware,
    getProductBrandDetailDataMiddleware,
    getProductModelDetailDataMiddleware,
]