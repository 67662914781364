
import React from "react";
import { makeStyles, Typography, Box, Button } from "@material-ui/core";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles((theme) => ({
    body: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: theme.spacing(1, 3),
    },
    labelText: {
        color: "#9CA6A9"
    }
}));

export default function RoleNameInputComponent({
    label,
    handleSelectAll,
    handleDeselectAll,
    lang,
    ...props
}) {
    const classes = useStyle();

    return (
        <Box className={classes.body}>
            <Box flexGrow={1}>
                <Typography variant="body2" className={classes.labelText}>
                    {label}
                </Typography>
            </Box>
            <Box>
                <Button color="secondary" variant="text" onClick={handleSelectAll} style={{ textTransform: "none" }}>
                    {getLang(lang, "label.SELECT_ALL")}
                </Button>
            </Box>
            <Box>
                <Button color="secondary" variant="text" onClick={handleDeselectAll} style={{ textTransform: "none" }}>
                    {getLang(lang, "label.DESELECT_ALL")}
                </Button>
            </Box>
        </Box>
    );
}