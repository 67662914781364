import axios from "axios";

const addAdtInfoTitle = (parameters) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `/serialnumber/api/v1/additional-info-title/create`,
        {
          titles: parameters.titles,
        }
      )
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

export default addAdtInfoTitle;
