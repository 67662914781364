import { createAsyncThunk } from '@reduxjs/toolkit'
import stockflowApi from 'app/api/stockflow'

export const STOCKFLOWINVENTORY = "stockflowInventory"

export const getInventoryProductDetail = createAsyncThunk(
  `${STOCKFLOWINVENTORY}/getInventoryProductDetail`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi.getProductDetail(payload)
    .then(response => response.data)
    .catch(error => rejectWithValue(error))
  }  
)

export const getInventoryProductStockList = createAsyncThunk(
  `${STOCKFLOWINVENTORY}/getInventoryProductStockList`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi.getProductStockListing(payload)
    .then(response => response.data)
    .catch(error => rejectWithValue(error))
  }  
)

export const getSerialNumberActivityLog = createAsyncThunk(
  `${STOCKFLOWINVENTORY}/getSerialNumberActivityLog`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi.getSerialNumberActivityLog(payload)
    .then(response => response.data)
    .catch(error => rejectWithValue(error))
  }  
)

export const getInventoryPackageListing = createAsyncThunk(
  `${STOCKFLOWINVENTORY}/getInventoryPackageListing`,
  async (payload, { rejectWithValue, getState }) => {
    return stockflowApi.getAllPackagesListing(payload)
    .then(response => response.data)
    .catch(error => rejectWithValue(error))
  }  
)

export const getInventoryPackageDetail = createAsyncThunk(
  `${STOCKFLOWINVENTORY}/getInventoryPackageDetail`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi.getPackageDetail(payload)
    .then(response => response.data)
    .catch(error => rejectWithValue(error))
  }  
)

export const getPackageActivityLog = createAsyncThunk(
  `${STOCKFLOWINVENTORY}/getPackageActivityLog`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi.getPackageActivityLog(payload)
    .then(response => response.data)
    .catch(error => rejectWithValue(error))
  }  
)

export const getInventoryBoxListing = createAsyncThunk(
  `${STOCKFLOWINVENTORY}/getInventoryBoxListing`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi.getAllBoxesListing(payload)
    .then(response => response.data)
    .catch(error => rejectWithValue(error))
  }  
)

export const getInventoryBoxDetail = createAsyncThunk(
  `${STOCKFLOWINVENTORY}/getBoxPackageDetail`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi.getBoxDetail(payload)
    .then(response => response.data)
    .catch(error => rejectWithValue(error))
  }  
)

export const getBoxActivityLog = createAsyncThunk(
  `${STOCKFLOWINVENTORY}/getBoxActivityLog`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi.getBoxActivityLog(payload)
    .then(response => response.data)
    .catch(error => rejectWithValue(error))
  }  
)


export const getInventoryLooseItemListing = createAsyncThunk(
  `${STOCKFLOWINVENTORY}/getInventoryLooseItemListing`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi.getAllLooseItemListing(payload)
    .then(response => response.data)
    .catch(error => rejectWithValue(error))
  }  
)

export const getInventoryLooseItemDetail = createAsyncThunk(
  `${STOCKFLOWINVENTORY}/getInventoryLooseItemDetail`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi.getLooseItemDetail(payload)
    .then(response => response.data)
    .catch(error => rejectWithValue(error))
  }  
)

export const getInventoryLooseItemStockList = createAsyncThunk(
  `${STOCKFLOWINVENTORY}/getInventoryLooseItemStockList`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi.getLooseItemStockListing(payload)
    .then(response => response.data)
    .catch(error => rejectWithValue(error))
  }  
)

export const getStockProductDropdown = createAsyncThunk(
  `${STOCKFLOWINVENTORY}/getStockProductDropdown`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi.getStockProductDropdown(payload)
    .then(response => response.data)
    .catch(error => rejectWithValue(error))
  }  
)

export const getStockHolderDropdown = createAsyncThunk(
  `${STOCKFLOWINVENTORY}/getStockHolderDropdown`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi.getStockHolderDropdown(payload)
      .then(response => response.data)
      .catch(error => rejectWithValue(error))
  }
)

export const getProductListingByBranch = createAsyncThunk(
  `${STOCKFLOWINVENTORY}/getProductListingByBranch`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi.getProductListingByBranch(payload)
      .then(response => response.data)
      .catch(error => rejectWithValue(error))
  }
)

export const getProductListingByDealer = createAsyncThunk(
  `${STOCKFLOWINVENTORY}/getProductListingByDealer`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi.getProductListingByDealer(payload)
      .then(response => response.data)
      .catch(error => rejectWithValue(error))
  }
)

export const getBranchInventory = createAsyncThunk(
  `${STOCKFLOWINVENTORY}/getBranchInventory`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi.getBranchInventory(payload)
      .then(response => response.data)
      .catch(error => rejectWithValue(error))
  }
)

export const getDealerInventory = createAsyncThunk(
  `${STOCKFLOWINVENTORY}/getDealerInventory`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi.getDealerInventory(payload)
      .then(response => response.data)
      .catch(error => rejectWithValue(error))
  }
)

export const exportBranchInventory = createAsyncThunk(
  `${STOCKFLOWINVENTORY}/exportBranchInventory`,
  async (payload, thunkApi) => {
    return stockflowApi.exportBranchInventory(payload)
      .then(response => response)
      .catch(error => thunkApi.rejectWithValue(error))
  }
);

export const exportDealerInventory = createAsyncThunk(
  `${STOCKFLOWINVENTORY}/exportDealerInventory`,
  async (payload, thunkApi) => {
    return stockflowApi.exportDealerInventory(payload)
      .then(response => response)
      .catch(error => thunkApi.rejectWithValue(error))
  }
);

export const exportProductSummaryByBranch = createAsyncThunk(
  `${STOCKFLOWINVENTORY}/exportProductSummaryByBranch`,
  async (payload, thunkApi) => {
    return stockflowApi.exportProductSummaryByBranch(payload)
      .then(response => response)
      .catch(error => thunkApi.rejectWithValue(error))
  }
);

export const exportProductSummaryByDealer = createAsyncThunk(
  `${STOCKFLOWINVENTORY}/exportProductSummaryByDealer`,
  async (payload, thunkApi) => {
    return stockflowApi.exportProductSummaryByDealer(payload)
      .then(response => response)
      .catch(error => thunkApi.rejectWithValue(error))
  }
);