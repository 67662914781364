import React, { useState, useEffect } from "react"
import { getConsumerLoyaltyPointHistory } from "modules/consumer/redux/action/consumer.action"
import { selectLoading } from "modules/notification"
import { useDispatch, useSelector } from "react-redux"
import { useRouteMatch } from "react-router-dom"
import LoyaltyPointTableComponent from "./loyaltyPointHistoryTable.component"
import { generatePaginationOptions } from "lib/helper"

export default function LoyaltyPointTableContainer() {
  const dispatch = useDispatch()
  const match = useRouteMatch()
  const id = match.params.consumerId

  const list = useSelector(state => state.consumer.loyaltyPointHistoryList.data)
  const totalFiltered = useSelector(state => state.consumer.loyaltyPointHistoryList.totalFiltered)
  const isFetching = useSelector(state => selectLoading(state, getConsumerLoyaltyPointHistory.typePrefix))
  const isError = useSelector(state => state.consumer.isLoadingLoyaltyPointListError)
  const lang = useSelector(state => state.constant.languages)
  const language = useSelector(state => state.profile.language)
  const [paginationOptions, setPaginationOptions] = useState([])
  const [tableFilter, setTableFilter] = useState({
    page: 0,
    rowsPerPage: 25,
    order: "desc",
  })

  useEffect(() => {
    dispatch(getConsumerLoyaltyPointHistory({
      id,
      length: tableFilter.rowsPerPage,
      start: tableFilter.rowsPerPage * tableFilter.page,
      order: tableFilter.order,
    }))
  }, [dispatch, tableFilter, id])

  useEffect(() => {
    setPaginationOptions(generatePaginationOptions(totalFiltered))
  }, [totalFiltered])

  const handleReload = () => {
    dispatch(getConsumerLoyaltyPointHistory({
      id,
      length: tableFilter.rowsPerPage,
      start: tableFilter.rowsPerPage * tableFilter.page,
      order: tableFilter.order,
    }))
  }

  const handleChangePage = (event, newPage) => {
    setTableFilter({
      ...tableFilter,
      page: newPage,
    })
  }

  const handleChangeRowsPerPage = event => {
    setTableFilter({
      ...tableFilter,
      page: 0,
      rowsPerPage: event.target.value,
    })
  }

  const changeOrder = (order) => {
    setTableFilter({
      ...tableFilter,
      order,
    })
  }

  return (
    <LoyaltyPointTableComponent
      rows={list}
      isFetching={isFetching}
      language={language}
      isError={isError}
      handleReload={handleReload}
      page={tableFilter.page}
      rowsPerPage={tableFilter.rowsPerPage}
      totalFiltered={totalFiltered}
      paginationOptions={paginationOptions}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      tableFilter={tableFilter}
      changeOrder={changeOrder}
      lang={lang}
    />
  )
}
