import React, { useState, useEffect } from "react";
import SnackBarComponent from "./snackBar.component";
import { connect } from "react-redux";
import { closeSingleAlert, resetAlert, selectAlerts } from "../../redux";

function SnackBarContainer({ alerts, closeSingleAlert }) {
  const [message, setMessage] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = (event, reason) => {
    // if (reason === 'clickaway') {
    //   return;
    // }
    setIsOpen(false);
    closeSingleAlert();
  };

  useEffect(() => {
    if (alerts.length !== 0) {
      setMessage(alerts[0].message);
      setIsOpen(true);
    }
  }, [alerts]);

  if (alerts.length !== 0) {
    return (
      <SnackBarComponent
        notification={alerts[0]}
        isOpen={isOpen}
        handleClose={handleClose}
        message={message}
      />
    );
  }

  return null;
}

const mapStateToProps = (state) => ({
  alerts: selectAlerts(state),
});

const mapDispatchToProps = (dispatch) => ({
  resetAlert: () => dispatch(resetAlert()),
  closeSingleAlert: () => dispatch(closeSingleAlert()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SnackBarContainer);
