import React, { useRef, useState, useEffect} from "react"
import {
    makeStyles,
    Typography,
    ButtonBase,
    Box,
    Chip,
    Tooltip
} from "@material-ui/core"
import {
    ArrowDropDown as ArrowDropDownIcon,
} from '@material-ui/icons'
import clsx from "clsx"
import { getLang } from "app/feature/constants"
import { withStyles } from "@material-ui/core"

const useStyle = makeStyles(theme => ({
    root: {
        padding: "5px 12px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "#ECEFF0",
        borderRadius: "4px",
        width: "100%",
    },
    inputText: {
        fontSize: "0.875rem",
        color: "#A4A6A8",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        fontWeight: 500
    },
    filled: {
        color: "rgba(0, 0, 0, 0.87)"
    },
    tablePaper: {
        height: 400
    },
    adornmentIcon: {
        color: "#707070"
    },
    input: {
        paddingRight: theme.spacing(1),
        cursor: "pointer !important"
    },
    paper: {
        width: 425,
    },
    tableContainer: {
        maxHeight: 300
    },
    smallCase: {
        textTransform: "none"
    },
    greenLabel: {
        color: theme.palette.secondary.main
    },
    list: {
        padding: "8px 16px",
    },
    filledWithNum: {
        minWidth: "auto",
        fontWeight: 500,
        color: theme.palette.primary.main,
        display: "inline-block",
        flexWrap: "wrap",
        overflow: "hidden",
        maxWidth: "90%",
        alignSelf: "center"
    },
    chip: {
        backgroundColor: "#FFF",
        marginRight: "0.25em",
        marginBottom: "0.25em",
        border: "none",
        boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.1), 0px 2px 2px 0px rgba(0,0,0,0.04), 0px 1px 5px 0px rgba(0,0,0,0.02)"
    },
    tooltip: {
        maxHeight: "150px",
        overflow: "auto",
        display: "grid",
        '&::-webkit-scrollbar': {
            width: '0.3em'
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: 'rgba(255,255,255,1)',
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: '2px',
            backgroundColor: '#ccd1d3',
        }
    },
    tooltipChip: {
        backgroundColor: "#FFF",
        margin: "0.5em 0.3em 0.5em 0.3em",
        display: "flex",
        justifyContent: "space-between",
        boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.1), 0px 2px 2px 0px rgba(0,0,0,0.04), 0px 1px 5px 0px rgba(0,0,0,0.02)"
    }
}));

const CustomizeTooltip = withStyles({
    tooltip: {
        backgroundColor: "#f7f7f7",
        boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)"
    },
    arrow: {
        scale: 2,
        color: "#f7f7f7",
        top: "-4px !important",
        "&::before": {
            boxShadow: "0px 5px 0px -2px rgba(0,0,0,0.2), 0px 0px 2px -1px rgba(0,0,0,0.44), 0px 0px 5px -2px rgba(0,0,0,0.42)"
        }
    }
})(Tooltip);

export default function StockoutReceiverSelectComponent({
    handleClick,
    anchorRef,
    disabled,
    value,
    textStyles,
    styles,
    placeholder,
    lang,
    drawerOpen,
    handleChange,
    handleChipDelete,
    extraChip,
    adjustField,
    isEmpty
}) {
    const classes = useStyle()
    const ref = useRef(null);
    const [dimension, setDimension] = useState({
        width: 0,
        height: 0,
    });

    useEffect(() => {
        // Add event when user zoom in/out or resize screen size
        const handleResize = () => {
            setDimension({
                width: window.innerHeight,
                height: window.innerWidth,
            });
        }
        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        adjustField(ref);
        // eslint-disable-next-line
    }, [handleChange, dimension])

    useEffect(() => {
        setTimeout(() => {
            adjustField(ref);
            window.dispatchEvent(new Event('resize'));
        }, 200);
        // eslint-disable-next-line
    }, [drawerOpen]);

    const getDisplay = () => {
        let display = placeholder;
        //let stockHolderName = stockHolderDropdown.map(holder => { return holder.name })
        if (value.length > 0) {
            display = value[0].name
        }
        return display;
    };

    const extraList = () => {
        return <div className={classes.tooltip}>
            {
                extraChip.overflowChild.map((item) => {
                    return (
                        <Chip
                            label={item.name}
                            size="small"
                            className={classes.tooltipChip}
                            onDelete={() => handleChipDelete(item)}
                        />
                    )
                })
            }
        </div>
    }

    return (
        <ButtonBase
            className={clsx(classes.root, {
                [classes.disabledField]: disabled
            })}
            disableRipple
            ref={anchorRef}
            onClick={handleClick}
            disabled={disabled}
            style={{ padding: 12, ...styles }}
        >
            <Box ref={ref} style={{ display: "flex", flexWrap: "wrap", overflow: "hidden", textOverflow: "ellipsis", flex: "2" }}>
                <Typography
                    variant="body2"
                    style={!extraChip.allOverflow ? { textStyles, textOverflow: "ellipsis" } : { textStyles, textOverflow: "clip" }}
                    className={clsx(
                        classes.inputText,
                        value.length > 1 && classes.filledWithNum,
                        (getDisplay() !== getLang(lang, "placeholder.LOCATED_AT") && getDisplay() !== placeholder) && classes.filled
                    )}
                >

                    {!isEmpty ? value.map((item) => (
                        <Chip
                            variant="outlined"
                            label={item.name}
                            size="small"
                            className={classes.chip}
                            onDelete={() => handleChipDelete(item)}
                            style={extraChip.allOverflow ? { visibility: "hidden" } : {}}
                        />
                    ))
                        : (placeholder)
                    }

                </Typography>
                {(extraChip.count >= 1 && !isEmpty)
                    ?
                    <CustomizeTooltip title={extraList()} arrow interactive>
                        <Chip
                            variant="outlined"
                            label={"+ " + extraChip.count}
                            size="small"
                            className={classes.chip}
                            style={{ left: extraChip.offset, position: "absolute", marginLeft: 2.5 }}
                        />
                    </CustomizeTooltip>
                    : null}
            </Box>
            <ArrowDropDownIcon fontSize="small" />
        </ButtonBase>
    )
}