const { makeStyles } = require("@material-ui/core");

export const chipStyle = makeStyles((theme) => ({
    chip__valid: {
        backgroundColor: "#32D583",
        color: "#FFFFFF",
        fontSize: "12px",
        fontWeight: "400",
        lineHeight: "14.4px",
        fontFamily: "Roboto",
        height: "24px"
    },
    chip__branch: {
        backgroundColor: "#3A4D54",
        color: "#FFFFFF",
        fontWeight: "400",
        fontSize: "12px",
        lineHeight: "20px",
        fontFamily: "Roboto",
        width: "84px",
        height: "24px"
    },
    chip__suspended: {
        backgroundColor: "#F97066",
        color: "#FFFFFF",
        fontSize: "12px",
        fontWeight: "400",
        lineHeight: "14.4px",
        fontFamily: "Roboto",
        height: "24px"
    },
    chip__brand: {
        backgroundColor: "#A749F0",
        color: "#FFFFFF",
        fontWeight: "400",
        fontSize: "12px",
        lineHeight: "20px",
        fontFamily: "Roboto",
        width: "84px",
        height: "24px"
    },
    chip__category: {
        backgroundColor: "#FDB022",
        color: "#FFFFFF",
        fontWeight: "400",
        fontSize: "12px",
        lineHeight: "20px",
        fontFamily: "Roboto",
        width: "84px",
        height: "24px"
    },
    chip__model: {
        backgroundColor: "#A749F0",
        color: "#FFFFFF",
        fontWeight: "400",
        fontSize: "12px",
        lineHeight: "20px",
        fontFamily: "Roboto",
        width: "84px",
        height: "24px"
    },
    chip__publish_success : {
        background: "linear-gradient(270deg, rgba(210, 255, 232, 0.5) 4.61%, rgba(241, 255, 248, 0.5) 100%)",
        color: "#32D583",
        width: "120px",
        height: "24px"
    },
    chip__product : {
        background: '#6184FF',
        color: '#FFFFFF',
        fontFamily: "Roboto",
        fontWeight: "400",
        fontSize: "12px",
        lineHeight: "20px",
        maxWidth: "100px",
        height: "24px"
    },
    chip__product_big : {
        background: '#6184FF',
        color: '#FFFFFF',
        fontFamily: "Roboto",
        fontWeight: "400",
        fontSize: "12px",
        lineHeight: "20px",
        maxWidth: "180px",
        height: "24px"
    },
    chip__incentive_points: {
        background: "linear-gradient(270deg, rgba(255, 192, 137, 0.5) 4.61%, rgba(255, 240, 227, 0.5) 100%)",
        padding: "4px 8px",
        color: "#DC6803",
        fontFamily: "Roboto",
        fontWeight: "400",
        fontSize: "12px",
        lineHeight: "20px",
        height: "24px"
    },
    chip__publish_failed : {
        color: "#F97066",
        background: "linear-gradient(270deg, rgba(254, 228, 226, 0.5) 4.61%, rgba(254, 243, 242, 0.5) 100%)",
        width: "120px",
        height: "24px"
    },
    chip__attributes : {
        background: "rgba(186, 218, 255, 0.2)",
        color: "#344054",
        fontFamily: "Roboto",
        fontWeight: "500",
        fontSize: "16px",
        width: "fit-content",
        height: "24px"
    },
})) 