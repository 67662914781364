/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import AdditionalFieldSelectComponent from "./additionalFieldSelect.component";
import AdditionalFieldSelectPopoverComponent from "./additionalFieldSelectPopover.component";
import { useSelector } from "react-redux";

function AdditionalFieldSelectContainer({
  value,
  placeholder,
  handleChange,
  disabled,
  style,
  textStyle,
  userLang,
  selectedIds,
  additionalFields,
}) {
  const lang = useSelector((state) => state.constant.languages);
  const [dropdown, setDropdown] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    setDropdown(additionalFields.filter((a) => !selectedIds.includes(a.id)));
  }, [additionalFields, selectedIds]);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectField = (field) => {
    handleChange(field);
    handleClose();
  };

  return (
    <>
      <AdditionalFieldSelectComponent
        handleClick={handleOpen}
        value={value}
        style={style}
        disabled={disabled}
        placeholder={placeholder}
        open={open}
        textStyle={textStyle}
        userLang={userLang}
      />
      <AdditionalFieldSelectPopoverComponent
        additionalFields={additionalFields}
        value={value}
        anchorEl={anchorEl}
        open={open}
        handleClose={handleClose}
        dropdown={dropdown}
        handleSelectField={handleSelectField}
        lang={lang}
        userLang={userLang}
      />
    </>
  );
}

export default AdditionalFieldSelectContainer;
