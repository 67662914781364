import { Content } from "modules/reporting/components/layout";
import { BranchSalesCampaignPanel, CampaignPerformancePanel, DemographicCampaignPanel, FilterPanelV3, MiscellaneousPanel } from "modules/reporting/components/panel";
import React from "react";

export default function SummaryPage({
    filter,
    tagUrl,
    currentFilter,
    pageMode,
    translate,
    campaignType
}) {

    const pageType="campaign-summary"
    return (
        <>
            <FilterPanelV3
                filter={filter}
                currentFilter={currentFilter}
                pageMode={pageMode}
                translate={translate}
            />

            <Content>
                {/* trending card --- START */}
                <CampaignPerformancePanel 
                    currentFilter={currentFilter}
                    urlTotalScan={tagUrl.qrTagUrl + "get-total-scan-qr"}
                    urlCampaignEngaged={tagUrl.campaignTagUrl + "get-campaign-engaged"}
                    urlCampaignPerformance={tagUrl.campaignTagUrl + "get-campaigns-conversion"}
                    pageType={pageType}
                    translate={translate}
                    campaignType={campaignType}
                />
                {/* trending card --- END */}

                {/* demographic -- START */}
                <DemographicCampaignPanel 
                    currentFilter={currentFilter}
                    pageType={pageType}
                    urlAge = {tagUrl.campaignTagUrl + "get-total-age"}
                    urlGender = {tagUrl.campaignTagUrl + "get-total-gender" }
                    urlGenderAge = {tagUrl.campaignTagUrl + "get-total-gender-age"}
                    urlCity= { tagUrl.campaignTagUrl + "get-city" }
                    urlCountry = { tagUrl.campaignTagUrl + "get-country" }

                    translate={translate}
                    campaignType={campaignType}
                />
                {/* demographic -- END */}

                {/* branch sales detail row --- START*/}
                {
                    !currentFilter.branchUuid ? 
                    (
                        <BranchSalesCampaignPanel
                            url={tagUrl.campaignTagUrl + "get-branch"}
                            currentFilter={currentFilter}
        
                            pageType={pageType}
                            translate={translate}
                        /> 
                    ): null
                }
                {/* branch sales detail row --- END*/}  

                {/* miscellaneous card row --- START */}
                <MiscellaneousPanel
                    currentFilter={currentFilter}
                    urlPlatform={tagUrl.campaignTagUrl + "get-total-platform"}
                    urlDevice={tagUrl.campaignTagUrl + "get-total-device"}
                    urlBrowser={tagUrl.campaignTagUrl + "get-total-browser"}
                    urlLanguage={tagUrl.campaignTagUrl + "get-total-language"}

                    translate={translate}
                    campaignType={campaignType}
                />
                {/* miscellaneous card row --- END */}
            </Content>
        </>
    )
}