import axios from "axios";

const url = "/distributor/admin/api/v1/getStockModuleSetting";

export function getStockModuleSetting() {
  return new Promise((resolve, reject) => {
    axios.get(url)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data))
  })
}