import React from "react";
import LuckyDrawDetailFormComponent from "./luckyDrawDetailForm.component";
import { addAlert } from "modules/notification";
import { useDispatch, useSelector } from "react-redux";
import { isPDF, isImage, isFileExceedLimit, isFile } from "lib/helper";
import { unwrapResult } from "@reduxjs/toolkit";
import { toBase64 } from "modules/product-mgmt/utils/productPage.util";
import { v4 as uuidv4 } from "uuid";
import { uploadImageToS3, uploadFileToS3, uploadJSONToS3 } from "app/feature";
import { FolderType, UploadType } from "lib/constants/aws_s3";
import { hideBackdrop, showBackdrop } from "modules/backdrop";
import {
  setLuckyDrawDetail,
  updateLuckyDrawDetail,
  fetchJSONURL
} from "modules/lucky-draw/redux";
import moment from "moment";
import { isNull } from 'lodash';
import { getLang } from "app/feature/constants";
import { useState } from "react";
import {setImgFormatAndSize} from "../../../../../lib/FileHelper";
import { isUrl } from "../../../../../lib/generalUtility";

function LuckyDrawDetailFormContainer({
  type,
  handleNext,
  changeBackView,
  termConditionFile,
  setTermConditionFile,
  luckyDrawImageFile,
  setLuckyDrawImageFile,
  children
}) {
  const dispatch = useDispatch();
  const id = useSelector((state) => state.session.idTokenPayload.sub);
  const LuckyDrawDetail = useSelector(
    (state) => state.luckyDraw.LuckyDrawDetail
  );
  const lang = useSelector(state => state.constant.languages)

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [isCropping, setIsCropping] = useState(false);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }

  const handleTncPdfChangePage = (isAddPage) => {
    setPageNumber(pageNumber + (isAddPage ? 1 : -1))
  }

  const handleSubmitForm = async (values) => {
    let tncUrl = ""
    let urlTemp = ""
    if (type === "edit") {
      dispatch(showBackdrop());

      if (isImage(values.imageFile)) {
        urlTemp = await imageUploadToS3(values.imageFile)
        if (urlTemp.length > 0) {
          values.luckyDrawImageUrl = urlTemp.split("?")[0];
        }
      }
      if ((values.luckyDrawTermsConditionDocument && isFile(values.luckyDrawTermsConditionDetail)) || values.luckyDrawTermsConditionText) {
        if (values.luckyDrawTermsConditionDocument && isFile(values.luckyDrawTermsConditionDetail) && !isPDF(values.luckyDrawTermsConditionDetail)) {
          dispatch(
            addAlert({
              severity: "error",
              message: getLang(lang, "message.error.UPLOAD_PDF_ONLY")
            })
          )
        }
        else {
          tncUrl = await tncUploadToS3(values)
          if (tncUrl.length > 0) {
            values.luckyDrawTermsConditionDetail = tncUrl.split("?")[0];
          }
        }
      }

      const productId = [];
      for (const product of values.assignProduct) {
        productId.push(product.id);
      }

      const info = {
        LuckyDrawDetail: {
          ...values,
          id: LuckyDrawDetail.id,
          imageFile: null,
          luckyDrawTermsConditionDetail: (isUrl(values.luckyDrawTermsConditionDetail) || values.luckyDrawTermsConditionText)
            ? values.luckyDrawTermsConditionDetail
            : values.luckyDrawTermsConditionUrl
        },
        productId: productId
      };

      await dispatch(updateLuckyDrawDetail(info))
        .then(unwrapResult)
        .then(() => {
          let tempTncUrl = values.luckyDrawTermsConditionDetail;
          if (values.luckyDrawTermsConditionText) {
            dispatch(fetchJSONURL(tempTncUrl))
              .then(unwrapResult)
          }
          dispatch(hideBackdrop());
          changeBackView();
        })
        .catch((error) => {
          dispatch(hideBackdrop());
          dispatch(
            addAlert({
              severity: "error",
              message: error.message || getLang(lang, "message.error.FAILED_UPLOAD_LD_IMAGE")
            })
          )
        });
    }

    if (type === "create") {
      if (moment(values.startAt).isBefore(moment().format("YYYY-MM-DD")) || moment(values.endAt).isBefore(values.startAt)) {
        dispatch(
          addAlert({
            severity: "error",
            message: getLang(lang, "message.error.START_DATE_END_DATE_CANT_CROSS")
          })
        );
        return;
      }

      if (isNull(values.imageFile)) {
        dispatch(
          addAlert({
            severity: "error",
            message: getLang(lang, "message.error.PLEASE_UPLOAD_IMAGE")
          })
        );
        return;
      }

      if (!isImage(values.imageFile)) {
        dispatch(
          addAlert({
            severity: "error",
            message: getLang(lang, "message.error.PLEASE_UPLOAD_VALID_IMAGE")
          })
        );
        return;
      } else {
        setLuckyDrawImageFile(values.imageFile)
      }


      if (isFileExceedLimit(values.imageFile.size, 2, 'MB')) {
        dispatch(
          addAlert({
            severity: "error",
            message: getLang(lang, "message.error.FORM_VALIDATE_FILE_SIZE", { value: 2 })
          })
        );
        return;
      }

      const updateInfo = {
        ...values,
        imageFile: null,
      };

      if (values.luckyDrawTermsConditionDocument && ((termConditionFile === null || termConditionFile.name !== values.luckyDrawTermsConditionDetail) && !isPDF(values.luckyDrawTermsConditionDetail))) {
        dispatch(addAlert({
          severity: "error",
          message: getLang(lang, "message.error.PLEASE_UPLOAD_TNC_FILE")
        }));
        return;
      }

      if (values.luckyDrawTermsConditionDocument && isPDF(values.luckyDrawTermsConditionDetail) && isFileExceedLimit(values.termsConditionDetail, 2, "MB")) {
        dispatch(
          addAlert({
            severity: "error",
            message: getLang(lang, "message.error.FORM_VALIDATE_FILE_SIZE", { value: 2 })
          })
        );
        return;
      }

      if (values.luckyDrawTermsConditionText && (values.luckyDrawTermsConditionDetail.value === "<p></p>\n" || (termConditionFile !== null && values.luckyDrawTermsConditionDetail === termConditionFile.name))) {
        dispatch(addAlert({
          severity: "error",
          message: getLang(lang, "message.error.EMPTY_TNC")
        }));
        return;
      }

      if (values.luckyDrawTermsConditionDocument && isFile(values.luckyDrawTermsConditionDetail)) {
        updateInfo.luckyDrawTermsConditionDetail = updateInfo.luckyDrawTermsConditionDetail.name;
      }

      if (isFile(values.luckyDrawTermsConditionDetail))
        await setTermConditionFile(values.luckyDrawTermsConditionDetail);
      await dispatch(setLuckyDrawDetail(updateInfo));
      handleNext();
    }
  };

  const imageUploadToS3 = async (image) => {
    let url = ""
    const fileName = `${uuidv4()}.webp`;
    let base64Image = await toBase64(image);
    base64Image = await setImgFormatAndSize(base64Image, 2160, 2160, "image/webp");
    const uploadLuckyDrawImageData = {
      uploadType: FolderType.luckyDrawImage,
      base64Image: base64Image,
      id: id,
      fileName: fileName
    };

    url = await dispatch(uploadImageToS3(uploadLuckyDrawImageData))
      .then(unwrapResult)
      .catch((error) => {
        dispatch(hideBackdrop());
        dispatch(
          addAlert({
            severity: "error",
            message: error.message || "Upload lucky draw image failed!"
          })
        );
      });
    return url
  }

  const tncUploadToS3 = async (values) => {
    let url = "";
    if (values.luckyDrawTermsConditionText) {
      const fileName = "JsonFile";

      const uploadJSONdata = {
        uploadType: UploadType.luckyDrawTermAndCondition,
        data: values.luckyDrawTermsConditionDetail,
        id: id,
        fileName: `${Date.now()}${fileName}`
      }
      await dispatch(uploadJSONToS3(uploadJSONdata))
        .then(unwrapResult)
        .then((jsonUrl) => {
          url = jsonUrl
        })
        .catch(error => {
          dispatch(hideBackdrop());
          dispatch(
            addAlert({
              severity: "error",
              message: error.message || "Upload Terms & Conditions Failed"
            })
          )
        })
      return url
    }
    else {
      const fileName = values.luckyDrawTermsConditionDetail.name;
      const fileType = values.luckyDrawTermsConditionDetail.type;

      if (fileType !== "application/pdf") {
        dispatch(
          addAlert({
            severity: "error",
            message: "Upload Terms & Conditions Failed"
          })
        )
        return url;
      }

      const uploadPDFdata = {
        uploadType: UploadType.luckyDrawTermAndCondition,
        file: values.luckyDrawTermsConditionDetail,
        id: id,
        fileName: `${fileName}_${Date.now()}${fileName}`
      }
      await dispatch(uploadFileToS3(uploadPDFdata))
        .then(unwrapResult)
        .then((termUrl) => {
          url = termUrl
        })
        .catch(error => {
          dispatch(hideBackdrop());
          dispatch(
            addAlert({
              severity: "error",
              message: error.message || "Upload Terms & Conditions Failed"
            })
          )
        })
      return url
    }
  }

  const handleCloseCropping = () => setIsCropping(false);

  const handleOpenCropping = () => setIsCropping(true);

  return (
    <LuckyDrawDetailFormComponent
      children={children}
      handleSubmit={handleSubmitForm}
      LuckyDrawDetail={LuckyDrawDetail}
      type={type}
      termConditionFile={termConditionFile}
      setTermConditionFile={setTermConditionFile}
      setLuckyDrawImageFile={setLuckyDrawImageFile}
      luckyDrawImageFile={luckyDrawImageFile}
      lang={lang}
      onDocumentLoadSuccess={onDocumentLoadSuccess}
      pageNumber={pageNumber}
      numPages={numPages}
      handleTncPdfChangePage={handleTncPdfChangePage}
      handleCloseCropping={handleCloseCropping}
      handleOpenCropping={handleOpenCropping}
      isCropping={isCropping}
    />
  );
}

export default LuckyDrawDetailFormContainer;
