import React from "react";
import ViewPrizeCardComponent from "./viewPrizeCardV2.component";
import { fetchAllInstantPrizeLegacy } from "modules/instant-lucky-draw/redux";
import { useSelector } from "react-redux";
import { selectLoading } from "modules/notification";

function ViewPrizeCardContainer({
  prizeDetail,
  enableWinRate,
  disbursementInfo,
}) {
  const isFetching = useSelector((state) =>
    selectLoading(state, fetchAllInstantPrizeLegacy.typePrefix)
  );
  const lang = useSelector((state) => state.constant.languages);

  return (
    <>
      <ViewPrizeCardComponent
        prizeDetail={prizeDetail}
        isFetching={isFetching}
        lang={lang}
        enableWinRate={enableWinRate}
        disbursementInfo={disbursementInfo}
      />
    </>
  );
}

export default ViewPrizeCardContainer;
