import { createSelector } from "@reduxjs/toolkit";
import { COLOR_PRIMARY, COLOR_SECONDARY } from "../../constants";

export const isCurrentThemeDefault = createSelector(
  state => state.setting.customColor,
  ({ primary, secondary }) =>
    primary === COLOR_PRIMARY && secondary === COLOR_SECONDARY
);

export const selectSetting = createSelector(
  state => state.setting,
  setting => setting
);
