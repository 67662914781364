import { Content } from "modules/reporting/components/layout";
import { FilterPanelV3, SurveyListPanel } from "modules/reporting/components/panel";
import React from "react";

export default function SurveyPage({
    filter,
    tagUrl,
    currentFilter,
    pageMode,
    campaignType,
    translate
}) {
    const pageType="campaign-list"
    
    return (
        <>
            <FilterPanelV3
                filter={filter}
                currentFilter={currentFilter}
                pageMode={pageMode}
                translate={translate}
            />

            <Content>
                {/* survey list --- START */}
                <SurveyListPanel 
                    currentFilter={currentFilter}
                    urlSurveyList={tagUrl + "get-survey-info-list"}
                    // urlSurveyList={"http://localhost:9080/surveyList"}
                    pageType={pageType}
                    campaignType={campaignType}

                    translate={translate}
                />
            </Content>
        </>
    )
}