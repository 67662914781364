import React from "react";
import { useSelector } from "react-redux";
import GiftTableComponent from "./giftTable.component";

export default function GiftTableContainer({
  tableData,
  pointCut
}) {
  const giftDropdown = useSelector(
    (state) => state.redemptionCenter.giftDropdown
  );
  const lang = useSelector(state => state.constant.languages)

  return (
    <GiftTableComponent
      tableData={tableData}
      giftDropdown={giftDropdown}
      pointCut={pointCut}
      lang={lang}
    />
  );
}