import React, { useRef, useState } from "react";
import LuckyDrawPrizeCardComponent from "./luckyDrawPrizeCard.component";
import { useDispatch, useSelector } from "react-redux";
import { isFile, isFileExceedLimit } from "lib/helper";
import { addAlert } from "modules/notification";
import { v4 as uuidv4 } from "uuid";
import { FolderType } from "lib/constants/aws_s3";
import { toBase64 } from "modules/product-mgmt/utils/productPage.util";
import { unwrapResult } from "@reduxjs/toolkit";
import { uploadImageToS3 } from "app/feature";
import { hideBackdrop, showBackdrop } from "modules/backdrop";
import ConfirmationDialogComponent from "components/dialog/confirmation";
import { LUCKY_DRAW_EDIT } from "lib/constants/accessRights";
import { setCancelEdit } from "modules/lucky-draw/redux";
import { getLang } from "app/feature/constants";
 
function LuckyDrawPrizeCardContainer({
  type,
  handleSave,
  handleDelete,
  prizeDetail,
  handleEnableEdit,
  handleNewDelete,
  handleNotEditing,
  AddingPrizeFlag
}) {
  const dispatch = useDispatch();
  const hasLuckyDrawEditAccessRight = useSelector(state => state.profile.accessRights.includes(LUCKY_DRAW_EDIT))
  const userId = useSelector((state) => state.session.idTokenPayload.sub);
  const id = useSelector((state) => state.luckyDraw.LuckyDrawDetail.id);
  const [confirmationDialogModal, setConfirmationDialogModal] = useState(false);
  const [updatePrizeInfo, setUpdatePrizeInfo] = useState(null);
  const lang = useSelector(state => state.constant.languages)
  const editing = useSelector(state => state.luckyDraw.prizeListTable.data)

  const inputImgRef = useRef();

  const [prizeImage, setPrizeImage] = useState(null);
  const [prizeImageFile, setPrizeImageFile] = useState(null);

  const handleImgChange = (e) => {
    const file = inputImgRef.current.files[0];

    if (file) {
      if (isFileExceedLimit(file.size, 12, 'MB')) {
        dispatch(addAlert({
          severity: "error",
          message: getLang(lang, "message.error.FORM_VALIDATE_FILE_SIZE", { value: 12 })
        }));
        return;
      }
      setPrizeImageFile(e.target.files[0]);
      setPrizeImage(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleDisableEdit = async(values) => {
    const index = editing.findIndex((w) => w.editing);
    const info = {
      index,
    };
    dispatch(setCancelEdit(info));
    handleNewDelete();
    handleNotEditing();
  }

  const closeConfirmationDialog = () => {
    setConfirmationDialogModal(false);
  };

  const handleProceed = async () => {
    dispatch(showBackdrop());
    await handleSave(updatePrizeInfo);
    dispatch(hideBackdrop());
    setConfirmationDialogModal(false);
  };


  const handleSubmit = async (values) => {
    const updateInfo = {
      ...values,
      luckyDrawId: id,
      order: 0,
      id: null
    };

    dispatch(showBackdrop());
    if (!isFile(prizeImageFile) && type === "create") {
      dispatch(
        addAlert({ severity: "error", message: getLang(lang,"message.error.PLEASE_UPLOAD_IMAGE") })
      );
      dispatch(hideBackdrop());
      return;

    } else if (isFile(prizeImageFile)) {
      const fileName = `${uuidv4()}.png`;
      const base64Image = await toBase64(prizeImageFile);
      const uploadLuckyDrawImageData = {
        uploadType: FolderType.luckyDrawprizeImage,
        base64Image: base64Image,
        id: userId,
        fileName: fileName,
      };

      await dispatch(uploadImageToS3(uploadLuckyDrawImageData))
        .then(unwrapResult)
        .then(async (imageUrl) => {
          updateInfo.prizeImage = imageUrl.split("?")[0];
        })
        .catch((error) => {
          dispatch(
            addAlert({
              severity: "error",
              message: getLang(lang,"message.error.FAILED_UPLOAD_IMAGE")
            })
          );
          dispatch(hideBackdrop());
          return;
        });
    }

    if (type === "edit") {
      updateInfo.order = prizeDetail.order;
      updateInfo.id = prizeDetail.id;
    }

    if(type === 'edit' && prizeDetail.quantity !== updateInfo.quantity){
      dispatch(hideBackdrop());
      setUpdatePrizeInfo(updateInfo)
      setConfirmationDialogModal(true);
      return;
    }
    
    await handleSave(updateInfo);
    dispatch(hideBackdrop());
  };

  return (
    <>
     
     <LuckyDrawPrizeCardComponent
      hasLuckyDrawEditAccessRight={hasLuckyDrawEditAccessRight}
      handleSubmit={handleSubmit}
      handleDelete={handleDelete}
      type={type}
      handleImgChange={handleImgChange}
      inputImgRef={inputImgRef}
      prizeDetail={prizeDetail}
      handleEnableEdit={handleEnableEdit}
      handleDisableEdit={handleDisableEdit}
      prizeImage={prizeImage}
      lang={lang}
      AddingPrizeFlag={AddingPrizeFlag}
    />

      <ConfirmationDialogComponent
        isOpen={confirmationDialogModal}
        handleClose={closeConfirmationDialog}
        handleProceed={handleProceed}
        type={"success"}
        description={getLang(lang,"paragraph.ADJUST_QUANTITY_AFFECT_WINNER_LIST")}
        title={getLang(lang,"label.CONFIRMATION")}
        />
    </>

   
  );
}

export default LuckyDrawPrizeCardContainer;
