import axios from "axios"

export default function addLoyaltyPoint({
    modules,
    point,
    status,
}) {
    return new Promise((resolve, reject) => {
        axios.post("/campaign/api/admin/campaigns",
            {
                productId: modules,
                amount: point,
                status: status,
            })
            .then(response => resolve(response.data))
            .catch(error => reject(error.response.data))
    })
}