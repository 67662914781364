/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import CustomReasonsPanelComponent from "./customReasonsPanel.component";
import { useSelector } from "react-redux";
import languages from "../../../../../lib/constants/languages";

function CustomReasonsPanelContainer({
  type,
  reasons,
  usedLang,
  updateReason,
  isSubmitting,
}) {
  const lang = useSelector((state) => state.constant.languages);

  const [languageTabIndex, setLanguageTabIndex] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleAddLang = (lang) => {
    const temp = [...usedLang];
    temp.push(lang);
    setLanguageTabIndex(temp.length - 1);

    updateReason(type, temp, reasons);
  };

  const handleRemoveLang = () => {
    const temp = [...usedLang].filter(
      (item) => item.code !== usedLang[languageTabIndex].code
    );

    let tempReason = reasons.map((r) => {
      let name = r.name;
      delete name[usedLang[languageTabIndex].code];

      return {
        ...r,
        name,
        is_edited: true,
      };
    });

    setLanguageTabIndex(0);

    updateReason(type, temp, tempReason);
  };

  return (
    <CustomReasonsPanelComponent
      lang={lang}
      type={type}
      languageTabIndex={languageTabIndex}
      setLanguageTabIndex={setLanguageTabIndex}
      usedLang={usedLang}
      addableLangs={languages.filter(
        (language) => !usedLang.some((used) => used.code === language.code)
      )}
      handleCloseAddLang={() => setAnchorEl(null)}
      handleAddLangClick={(event) => setAnchorEl(event.currentTarget)}
      handleRemoveLang={handleRemoveLang}
      anchorEl={anchorEl}
      handleAddLang={handleAddLang}
      reasons={reasons}
      updateReason={updateReason}
      isSubmitting={isSubmitting}
    />
  );
}

export default CustomReasonsPanelContainer;
