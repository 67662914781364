import React from 'react';
import ReactSortableTree from 'react-sortable-tree';
import '../customReactSortableTreeThemeV2/custom-react-sortable-tree.css'
import { setOrganisationStructure } from 'modules/organisation-structure/redux';
import { useDispatch } from 'react-redux';
import CustomReactSortableTreeThemeV2 from "../customReactSortableTreeThemeV2/customReactSortableTreeTheme";

export default function CompaniesReactSortableTreeComponent({
  treeData,
}) {
  const dispatch = useDispatch()
  return (
    <ReactSortableTree
      isVirtualized={false}
      canDrag={false}
      theme={CustomReactSortableTreeThemeV2}
      treeData={treeData}
      onChange={(value) => dispatch(setOrganisationStructure({ organisationStructure: value }))}
      scaffoldBlockPxWidth={88}
      rowHeight={(props) => {
        const { node } = props
        if (!node.employees || node.employees.length <= 0) return 76;

        if (node.showEmployees) {
          return ((node.employees.length) * 60) + 10
        }

        if (!node.isChildrenLoaded || node.isLoading) return 76;

        if (!node.children || node.children.length <= 0) return 60;

        return 90;
      }}
    />
  )
}
