import React from "react";
import RewardListTableComponent from "./rewardListTable.component";
import { useSelector } from "react-redux";

export default function RewardListTableContainer({
  hasStockflowEditAccessRight,
  hasStockflowAddAccessRight,
  rows,
  rowsPerPage,
  paginationOptions,
  page,
  totalFiltered,
  isFetching,
  isError,
  isSelectAll,
  selectedUUIDs,
  handleReload,
  language,
  handleViewDetail,
  handleChangePage,
  handleChangeRowPerPage,
  handleUpdateRewardStatus,
  handleDeleteReward,
  handleCreate,
  handleSelectReward,
  handleSelectAvailable,
  handleDeselectAll,
  handleSelectAll
}) {
  const lang = useSelector(state => state.constant.languages);
  return (
    <RewardListTableComponent
      hasStockflowEditAccessRight={hasStockflowEditAccessRight}
      hasStockflowAddAccessRight={hasStockflowAddAccessRight}
      rows={rows}
      language={language}
      rowsPerPage={rowsPerPage}
      paginationOptions={paginationOptions}
      page={page}
      totalFiltered={totalFiltered}
      isFetching={isFetching}
      isError={isError}
      isSelectAll={isSelectAll}
      selectedUUIDs={selectedUUIDs}
      handleReload={handleReload}
      handleViewDetail={handleViewDetail}
      handleChangePage={handleChangePage}
      handleChangeRowPerPage={handleChangeRowPerPage}
      handleUpdateRewardStatus={handleUpdateRewardStatus}
      handleDeleteReward={handleDeleteReward}
      handleCreate={handleCreate}
      handleSelectReward={handleSelectReward}
      handleSelectAvailable={handleSelectAvailable}
      handleDeselectAll={handleDeselectAll}
      handleSelectAll={handleSelectAll}
      lang={lang}

    />
  )
}