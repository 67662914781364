import React from "react";
import {
  Backdrop,
  Dialog,
  makeStyles,
  Typography,
  Paper,
  Box,
  IconButton,
  Divider,
  Button,
} from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { Formik } from "formik";
import Input from "components/input/inputTextField";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles((theme) => ({
  dialog: {
    maxWidth: "none",
  },
  root: {
    boxShadow: theme.shadows[5],
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "space-between",
    width: 500,
    borderRadius: "4px",
    fontSize: 14,
  },
  header: {
    paddingLeft: theme.spacing(3),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  content: {
    padding: "16px",
  },
  buttonContainer: {
    padding: "10px 16px",
    display: "flex",
    justifyContent: "flex-end",
  },
  selectRoot: {
    padding: "8px 14px",
  },
  inputRoot: {
    paddingTop: "8px !important",
    paddingBottom: "7px !important",
    paddingLeft: "12px !important",
    paddingRight: "12px !important",
  },
}));

export default function CreateBranchGroupDialogComponent({
  isOpen,
  handleClose,
  handleSubmit,
  isSubmittingAddGroup,
  lang,
}) {
  const classes = useStyle();

  return (
    <Dialog
      maxWidth="sm"
      open={isOpen}
      onClose={handleClose}
      disableBackdropClick
      closeAfterTransition
      BackdropComponent={Backdrop}
      classes={{
        paperWidthSm: classes.dialog,
      }}
    >
      <Paper elevation={0} className={classes.root}>
        <Box className={classes.header}>
          <Typography
            variant="subtitle2"
            color="primary"
            style={{ fontWeight: "bold" }}
          >
            {getLang(lang, "label.ADD_BRANCH_GROUP")}
          </Typography>
          <IconButton color="primary" onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
        <Divider />
        <Formik
          initialValues={{
            name: "",
          }}
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <Box>
                <Box className={classes.content}>
                  <Typography
                    variant="body2"
                    color="primary"
                    style={{ marginBottom: "10px" }}
                  >
                    {getLang(lang, "label.GROUP_NAME")}
                  </Typography>
                  <Box display="flex" alignItems="center">
                    <Input
                      hiddenLabel
                      // variant="filled"
                      placeholder={getLang(
                        lang,
                        "placeholder.ENTER_GROUP_NAME"
                      )}
                      margin="none"
                      InputProps={{
                        disableUnderline: true,
                        margin: "none",
                        style: {
                          borderRadius: "4px",
                          backgroundColor: "#eceff0",
                          height: "36.5px",
                        },
                        classes: { input: classes.inputRoot },
                      }}
                      style={{ flex: 1 }}
                      error={formik.errors.name ? true : false}
                      helperText={formik.errors.name}
                      inputProps={{ maxLength: 50 }}
                      {...formik.getFieldProps("name")}
                    />
                    <Box ml={1}>{formik.values.name.length}/50</Box>
                  </Box>
                </Box>
                <Divider />
                <Box className={classes.buttonContainer}>
                  <Button
                    type="button"
                    variant="contained"
                    disableElevation
                    color="secondary"
                    disabled={
                      !formik.values.name.trim() || isSubmittingAddGroup
                    }
                    onClick={formik.handleSubmit}
                  >
                    {getLang(lang, "label.ADD")}
                  </Button>
                </Box>
              </Box>
            </form>
          )}
        </Formik>
      </Paper>
    </Dialog>
  );
}
