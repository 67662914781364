import React from "react";
import { makeStyles, Modal, Backdrop, Fade } from "@material-ui/core";

const style = theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
});

const useStyle = makeStyles(style);
export default function ModalComponent({ isOpen, handleClose, children }) {
  const classes = useStyle();
  return (
    <Modal
      className={classes.root}
      open={isOpen}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
    >
      <Fade in={isOpen}>
        <div>{children}</div>
      </Fade>
    </Modal>
  );
}
