export const ROOT = "/admin/stockflow";
export const MY_DEALER_LIST_PAGE = `${ROOT}/my-dealer`;
export const DEALER_DETAILS_PAGE = `${ROOT}/my-dealer/:dealerId`;
export const REWARD_CENTER_PAGE = `${ROOT}/reward-center`;
export const REWARD_CENTER_REWARD_TAB = `${ROOT}/reward-center/reward`;
export const REWARD_DETAILS_PAGE = `${ROOT}/reward-center/reward/:rewardId`;
export const REWARD_CREATE_PAGE = `${ROOT}/reward-center/create-reward`;
export const DEALEAR_PROGRAM_LIST_PAGE = `${ROOT}/dealer-incentive-program`;
export const NEW_INCENTIVE_PROGRAM_PAGE = `${ROOT}/dealer-incentive-program/new`;
export const EDIT_DEALER_INCENTIVE_PROGRAM_PAGE = `${ROOT}/dealer-incentive-program/:programId`;
export const STOCKOUT_LIST_PAGE = `${ROOT}/stockout`;
export const STOCKOUT_DETAILS_PAGE = `${ROOT}/stockout/:uuid`;
export const RECALL_LIST_PAGE = `${ROOT}/recall`;
export const RECALL_DETAILS_PAGE = `${ROOT}/recall/:uuid`;
export const TRANSFER_LIST_PAGE = `${ROOT}/transfer`;
export const TRANSFER_DETAILS_PAGE = `${ROOT}/transfer/:uuid`;
export const RECEIVE_TRANSFER_LIST_PAGE = `${ROOT}/receive-transfer`;
export const RECEIVE_TRANSFER_DETAILS_PAGE = `${ROOT}/receive-transfer/:uuid`;
export const REQUEST_TRANSFER_LIST_PAGE = `${ROOT}/request-transfer`;
export const REQUEST_TRANSFER_CREATE_PAGE = `${ROOT}/request-transfer/create`;
export const MY_REQUEST_TRANSFER_DETAILS_PAGE = `${ROOT}/request-transfer/my/:uuid`;
export const BRANCH_REQUEST_TRANSFER_DETAILS_PAGE = `${ROOT}/request-transfer/branch/:uuid`;
export const INVENTORY_LIST_PAGE = `${ROOT}/inventory`;
export const INVENTORY_PRODUCT_DETAIL_PAGE = `${ROOT}/inventory/product/:productId`;
export const INVENTORY_PACKAGE_DETAIL_PAGE = `${ROOT}/inventory/package/:uuid`;
export const INVENTORY_BOX_DETAIL_PAGE = `${ROOT}/inventory/box/:uuid`;
export const INVENTORY_LOOSE_ITEM_DETAIL_PAGE = `${ROOT}/inventory/loose-item/detail`;
export const ORDER_LIST_PAGE = `${ROOT}/order`;
export const ORDER_DETAILS_PAGE = `${ROOT}/order/:uuid`;
export const CHECKOUT_LIST_PAGE = `${ROOT}/checkout`;
export const CHECKOUT_DETAILS_PAGE = `${ROOT}/checkout/:uuid`;
export const STOCK_TAKE_LIST_PAGE = `${ROOT}/stock-take`;
export const STOCK_TAKE_CREATE_PAGE = `${ROOT}/stock-take/create`;
export const STOCK_TAKE_DETAILS_PAGE = `${ROOT}/stock-take/:uuid`;
