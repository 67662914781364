import React from "react"
import { 
  makeStyles, 
  Box, 
  Divider, 
  Typography, 
  Chip, 
  IconButton,
  Link
} from '@material-ui/core'
import clsx from "clsx"
import { Skeleton } from "@material-ui/lab"
import CloseIcon from "@material-ui/icons/Close"
import ActivityLogTimeline from "../../timeline/activityLogTimeline"
import { ROOT } from "modules/stockflow/config/stockflow.route"
import { useHistory } from "react-router-dom"
import { getLang } from "app/feature/constants"

const useStyle = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2, 2),
    boxSizing: "border-box",
    maxHeight: "100%",
    backgroundColor: "#FFFFFF"
  },
  field: {
    display: "grid",
    gridTemplateColumns: "3fr 7fr",
    columnGap: theme.spacing(2)
  },
  activeChipStatus: {
    backgroundColor: "#ECFDF3",
    width: "fit-content",
    textTransform: "capitalize",
    color: "#027A48",
    fontWeight: "bold"
  },
  suspendChipStatus: {
    backgroundColor: "#fdecec",
    width: "fit-content",
    textTransform: "capitalize",
    color: "red",
    fontWeight: "bold"
  },
  activityLogContainer: {
    flex: 1,
    overflowY: "auto",
    overflowX: "hidden",
    '&::-webkit-scrollbar': {
      width: "10px"
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: "16px"
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: "#E4E7EC",
      borderRadius: "16px"
    },
  },
  highlightBox: {
    padding: "2px 10px",
    backgroundColor: "#98A2B3",
    color: "#FFFFFF",
    borderRadius: "11px",
    marginRight: "8px"
  }
}))

export default function SerialNumberActivityLogPanelComponent({
  log,
  serialNumber,
  isFetching,
  isError,
  handleClose,
  lang
}) {
  const classes = useStyle()
  const history = useHistory()

  return (
    <>
      {!isFetching && !isError && serialNumber ? (
        <Box className={classes.container} display="flex" flexDirection="column">
          <Box mb={2} display="flex" alignItems="center" justifyContent="space-between">
            <Box display="flex" alignItems="center">
              <Box className={classes.highlightBox}>
                <Typography variant="h5">
                  {serialNumber.sequence_no}
                </Typography>
              </Box>
              <Typography variant="h6">
                <b>{serialNumber.serial_number}</b>
              </Typography>
              <Box ml={1}>
                <Chip 
                  label={`• ${getLang(lang, `label.${serialNumber.status.toUpperCase()}`)}`}
                  size="small"
                  className={clsx({
                    [classes.activeChipStatus]: serialNumber.status === 'active',
                    [classes.suspendChipStatus]: serialNumber.status === 'suspend',
                  })}
                />
              </Box>
            </Box>
            <IconButton color="primary" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
          <Divider />
          <Box className={classes.activityLogContainer} mt={2}>
            <Box mb={2} >
              <Box display="flex" className={classes.field} mb={2}>
                <Typography variant="body1">
                  <b>{getLang(lang, "label.PACKAGE_STATUS")}</b>
                </Typography>
                {serialNumber.pack ? (
                  <Box display="flex">
                    <Typography variant="body1" color="textSecondary" style={{ marginRight: 4 }}>
                      <span>: </span>
                    </Typography>
                    <Link
                      href={`${ROOT}/inventory/package/${serialNumber.uuid}`}
                      color="secondary"
                      variant="body1"
                      onClick={(e) => {
                        history.push(`${ROOT}/inventory/package/${serialNumber.uuid}`)
                        e.preventDefault();
                        return false;
                      }}
                    >
                      {getLang(lang, "label.PACKED")}
                    </Link>
                  </Box>
                ) : (
                  <Typography variant="body1" color="textSecondary">
                    <span>: {getLang(lang, "label.NONE")}</span>
                  </Typography>
                )}
              </Box>
              <Box display="flex" className={classes.field}>
                <Typography variant="body1">
                  <b>{getLang(lang, "label.BOX_STATUS")}</b>
                </Typography>
                {serialNumber.box ? (
                  <Box display="flex">
                    <Typography variant="body1" color="textSecondary" style={{ marginRight: 4 }}>
                      <span>: </span>
                    </Typography>
                    <Link
                      href={`${ROOT}/inventory/box/${serialNumber.box.id}`}
                      color="secondary"
                      variant="body1"
                      onClick={(e) => {
                        history.push(`${ROOT}/inventory/box/${serialNumber.box.id}`)
                        e.preventDefault();
                        return false;
                      }}
                    >
                      {serialNumber.box.name}
                    </Link>
                  </Box>
                ) : (
                  <Typography variant="body1" color="textSecondary">
                    <span>: {getLang(lang, "label.NONE")}</span>
                  </Typography>
                )}
              </Box>
            </Box>
            <Divider />
            <Box mt={2}>
              <Typography variant="body1">
                <b>{getLang(lang, "label.ACTIVITY_LOG")}</b>
              </Typography>
              <Box mt={2}>
                <ActivityLogTimeline activityLog={log} lang={lang}/>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box className={classes.container}>
          <Skeleton variant="text" height={40}/>
          <Divider />
          <Skeleton variant='text' height={30} style={{ marginTop: 20 }} />
          <Skeleton variant='text' height={30} style={{ marginBottom: 20 }} />
          <Divider />
          <Skeleton variant='text' height={30} style={{ marginTop: 20 }} />
          <Skeleton variant='text' height={30} />
          <Skeleton variant='text' height={70} />
          <Skeleton variant='text' height={70} />
          <Skeleton variant='text' height={70} />
        </Box>
      )}
    </>
  )
}