/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import TransferBoxDetailDialogComponent from "./transferBoxDetailDialog.component";
import { useDispatch, useSelector } from "react-redux";
import { selectLoading } from "modules/notification";
import {
  getReceiveTransferItemBoxProductList,
  getReceiveTransferItemProductSNList,
  getTransferItemBoxProductList,
  getTransferItemProductSNList,
  resetBoxProductListing,
  resetBoxProductListingReceiver,
  resetSnListing,
  resetSnListingReceiver,
} from "modules/stockflow/redux";
import { generatePaginationOptions } from "lib/helper";

function TransferBoxDetailDialogContainer({
  isOpen,
  handleClose,
  id,
  boxInfo,
  isReceiver,
}) {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.constant.languages);

  // box
  const isFetching = useSelector((state) =>
    selectLoading(
      state,
      isReceiver
        ? getReceiveTransferItemBoxProductList.typePrefix
        : getTransferItemBoxProductList.typePrefix
    )
  );
  const list = useSelector(
    (state) =>
      state[isReceiver ? "stockflowReceiveTransfer" : "stockflowTransfer"]
        .transferBoxProductList.list
  );
  const isError = useSelector(
    (state) =>
      state[isReceiver ? "stockflowReceiveTransfer" : "stockflowTransfer"]
        .transferBoxProductList.isError
  );

  const isPrdFetching = useSelector((state) =>
    selectLoading(
      state,
      isReceiver
        ? getReceiveTransferItemProductSNList.typePrefix
        : getTransferItemProductSNList.typePrefix
    )
  );
  const prdTotalFiltered = useSelector(
    (state) =>
      state[isReceiver ? "stockflowReceiveTransfer" : "stockflowTransfer"]
        .transferSnList.totalFiltered
  );
  const prdList = useSelector(
    (state) =>
      state[isReceiver ? "stockflowReceiveTransfer" : "stockflowTransfer"]
        .transferSnList.list
  );
  const isPrdError = useSelector(
    (state) =>
      state[isReceiver ? "stockflowReceiveTransfer" : "stockflowTransfer"]
        .transferSnList.isError
  );

  const [selectedProduct, setSelectedProduct] = useState(null);

  const [paginationOptions, setPaginationOptions] = useState([]);
  const [tableFilter, setTableFilter] = useState({
    page: 0,
    rowsPerPage: 25,
    order: "asc",
    orderBy: "sequence_number", // sequence_number|code
  });

  useEffect(() => {
    return () => {
      dispatch(isReceiver ? resetSnListingReceiver() : resetSnListing());
      dispatch(
        isReceiver ? resetBoxProductListingReceiver() : resetBoxProductListing()
      );
    };
  }, [dispatch]);

  useEffect(() => {
    if (boxInfo) {
      handleDataLoad();
    }
  }, [boxInfo]);

  useEffect(() => {
    if (selectedProduct) {
      handlePrdDataLoad();
    } else {
      dispatch(isReceiver ? resetSnListingReceiver() : resetSnListing());
      setPaginationOptions([]);
    }
  }, [selectedProduct, tableFilter]);

  useEffect(() => {
    setPaginationOptions(generatePaginationOptions(prdTotalFiltered));
  }, [prdTotalFiltered]);

  const handleDataLoad = () => {
    let payload = {
      id: id,
      box_uuid: boxInfo.uuid,
      type: boxInfo.type, // receive|missing|pending
    };
    dispatch(
      isReceiver
        ? getReceiveTransferItemBoxProductList(payload)
        : getTransferItemBoxProductList(payload)
    );
  };

  const handlePrdDataLoad = () => {
    let payload = {
      id: id,
      product_uuid: selectedProduct.uuid,
      length: tableFilter.rowsPerPage,
      start: tableFilter.rowsPerPage * tableFilter.page,
      order: tableFilter.order,
      order_by: tableFilter.orderBy,
      type: boxInfo.type, // receive|missing|pending
      box_uuid: boxInfo.uuid,
    };
    dispatch(
      isReceiver
        ? getReceiveTransferItemProductSNList(payload)
        : getTransferItemProductSNList(payload)
    );
  };

  const handleChangePage = (event, newPage) => {
    let newData = {
      ...tableFilter,
      page: newPage,
    };
    setTableFilter(newData);
  };

  const handleChangeRowsPerPage = (event) => {
    let newData = {
      ...tableFilter,
      page: 0,
      rowsPerPage: event.target.value,
    };
    setTableFilter(newData);
  };

  const changeOrder = (orderBy, order) => {
    let newData = {
      ...tableFilter,
      order,
      orderBy,
    };
    setTableFilter(newData);
  };

  const handlePrdReload = () => {
    let newData = {
      ...tableFilter,
      page: 0,
    };
    setTableFilter(newData);
  };

  return (
    <TransferBoxDetailDialogComponent
      isOpen={isOpen}
      handleClose={handleClose}
      lang={lang}
      boxInfo={boxInfo}
      list={list}
      isFetching={isFetching}
      isError={isError}
      handleReload={handleDataLoad}
      viewProduct={(prd) => setSelectedProduct(prd)}
      selectedProduct={selectedProduct}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      changeOrder={changeOrder}
      handlePrdReload={handlePrdReload}
      paginationOptions={paginationOptions}
      prdList={prdList}
      isPrdFetching={isPrdFetching}
      isPrdError={isPrdError}
      prdTotalFiltered={prdTotalFiltered}
      tableFilter={tableFilter}
    />
  );
}

export default TransferBoxDetailDialogContainer;
