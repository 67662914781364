import auth0 from "auth0-js";

const domain = process.env.REACT_APP_AUTH0_DOMAIN
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID
const audience = process.env.REACT_APP_AUTH0_AUDIENCE
const db_connection = process.env.REACT_APP_AUTH0_DATABASE_CONNECTION

class Auth0Client {
  constructor() {
    this.auth = new auth0.WebAuth({
      domain: domain,
      clientID: clientId,
      redirectUri: `${window.location.origin}/auth`,
      audience: audience,
    });
  }

  checkSession() {
    return new Promise((resolve, reject) => {
      this.auth.checkSession(
        {
          responseType: "code token id_token",
          scope: "openid profile email",
        },
        (error, authResult) => {
          if (error) reject(error);
          else resolve(authResult);
        }
      );
    });
  }

  login(username, password) {
    return new Promise((resolve, reject) => {
      this.auth.login(
        {
          username,
          password,
          responseType: "code token id_token",
          realm: db_connection,
        },
        (error, authResult) => {
          if (error) reject(error);
          else resolve(authResult);
        }
      );
    });
  }

  logout() {
    this.auth.logout({
      // responseType: "code token id_token",
      clientID: clientId,
      returnTo: `${window.location.origin}/login`,
    });
  }

  signup(email, password, username) {
    return new Promise((resolve, reject) => {
      this.auth.signup(
        {
          connection: db_connection,
          email,
          password,
          username,
        },
        (error, user) => {
          if (error) reject(error);
          else resolve(user);
        }
      );
    });
  }

  parseHash(hash) {
    return new Promise((resolve, reject) => {
      this.auth.parseHash(
        {
          hash,
          responseType: "code token id_token",
          realm: db_connection,
        },
        (error, user) => {
          if (error) reject(error);
          else resolve(user);
        }
      );
    });
  }
}

export { Auth0Client };
