import axios from "axios";

// validate invitation code
export function validateEmailVerificationToken({
  email,
  emailVerificationToken
}) {
  return new Promise((resolve, reject) => {
    axios.post("distributor/public/api/v1/auth/validate-email-verification-token-and-verify-email", {
      email: email,
      email_verification_token: emailVerificationToken
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data))
  })
}