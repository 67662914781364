import React from 'react'
import {
    Box,
    Button,
    makeStyles,
    Typography
} from '@material-ui/core'
import InteractiveEditor from '../../interactiveEditor'
import { PlayIconSvg } from '../../../utils/staticSvg'
import { getLang } from 'app/feature/constants'
import LanguageSelect from "../../../../../components/select/languageSelect";

const useStyle = makeStyles(() => ({
    previewButton: {
        textTransform: "none",
        fontSize: 13,
        borderRadius: 8
    }
}))

export default function PageWorkspacePanelComponent({ handlePreview, lang, productLanguages, currentLanguage, onSetLanguage }) {
    const classes = useStyle()

    return (
        <Box style={{
            borderRadius: "6px 6px 0px 0px",
            overflow: "hidden"
        }}>
            <Box style={{
                backgroundColor: "white",
                padding: "12px 16px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between"
            }}>
                <Typography
                    variant="body2"
                    color="primary"
                    style={{ fontWeight: "bold", fontSize: 15 }}
                >
                    {getLang(lang,"label.PAGE_WORKSPACE")}
                </Typography>
                <Box display="flex">
                    <Box px={1}>
                        <LanguageSelect
                          value={currentLanguage}
                          options={productLanguages || ["EN"]}
                          disabled={!productLanguages}
                          onSelect={(language) => {
                              onSetLanguage(language);
                          }}
                          anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                          }}
                          transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                          }}
                        />
                    </Box>
                    <Button
                      className={classes.previewButton}
                      variant="contained"
                      color="primary"
                      disableElevation
                      onClick={handlePreview}
                      startIcon={<PlayIconSvg color="white" />}
                    >
                        {getLang(lang,"label.PREVIEW_LAYOUT")}
                    </Button>
                </Box>
            </Box>
            <Box style={{
                paddingTop: 13,
                display: "flex",
                justifyContent: "center",
                backgroundColor: "#E4E7EC",
                paddingBottom: 13,
                height: "100%"
            }}>
                <InteractiveEditor />
            </Box>
        </Box>
    )
}
