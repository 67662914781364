import React from "react";
import {
  makeStyles,
  Box,
  IconButton,
  Typography,
  fade,
  ButtonBase,
  FormControlLabel,
  Switch,
  Popper,
  Grow,
  Paper,
  List,
  ListItem,
  ListItemText,
  ClickAwayListener,
  Tooltip
} from "@material-ui/core";
import MoreHorizRoundedIcon from "@material-ui/icons/MoreHorizRounded";
import clsx from "clsx";
import MastercardLogo from "assets/svg/mastercard-logo.svg";
import VisaLogo from "assets/svg/visa-logo.svg";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles(theme => ({
  root: {
    // fontWeight: theme.typography.fontWeightBold,
    width: "100%",
    height: 340,
    boxSizing: "border-box",
    backgroundColor: "#F4F6F8",
    padding: theme.spacing(2, 4, 4),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2, 5, 5)
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2)
    },
    [theme.breakpoints.down("xs")]: {
      height: 300
    }
  },
  rootSelected: {
    backgroundColor: "#E4EAEC"
  },
  mockCard: {
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    width: "100%",
    height: 140,
    minWidth: 190,
    maxWidth: 210,
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    boxSizing: "border-box",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.15)"
  },
  bgMastercard: {
    color: "#313943",
    background: "linear-gradient(70.41deg, #60BCFF 0.09%, #76FFAD 100%)"
  },
  bgVisa: {
    color: theme.palette.common.white,
    background: "linear-gradient(70.47deg, #3A4D54 0%, #625D81 100%)"
  },
  logo: {
    height: 26,
    display: "flex",
    alignItems: "flex-end"
  },
  cardNumber: {
    fontSize: "large",
    margin: theme.spacing(1, 0, 0, 1)
  },
  visaExpDate: {
    color: fade(theme.palette.common.white, 0.33)
  },
  mastercardExpDate: {
    color: "rgba(49, 57, 67, 0.5)"
  },
  primarySwitch: {
    marginLeft: 0
  },
  popper: {
    zIndex: 1
  },
  paperSubmenu: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  },
  boxSubmenu: {
    paddingTop: theme.spacing(1),
    "&::before": {
      content: '""',
      position: "absolute",
      width: "0",
      height: "0",
      top: "-7px",
      right: "15px",
      border: "8px solid",
      borderColor: "transparent",
      borderBottomColor: theme.palette.primary.main
    }
  },
  deleteBtn: {
    color: theme.palette.error.main,
    fontWeight: theme.typography.fontWeightBold
  },
  cardLabelContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center"
    }
  },
  bgDeleting: {
    color: theme.palette.common.white,
    background: "lightgray"
  }
}));

export default function PaymentCardCardComponent({
  isSelected,
  last4number,
  type,
  expMonth,
  expYear,
  isPrimary,
  anchorOptionMenu,
  handleClickOption,
  showMenu,
  handleCloseMenu,
  handleSelect,
  handleDelete,
  isPrimarySwitchChecked,
  handleChangePrimarySwitch,
  isDeleting,
  lang
}) {
  const classes = useStyle();

  const isVisa = type === "visa";

  return (
    <Box className={clsx(classes.root, { [classes.rootSelected]: isSelected })}>
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="flex-start"
        height={72}
      >
        <IconButton
          aria-label="card option"
          color="primary"
          edge="end"
          onClick={handleClickOption}
          disabled={isDeleting}
        >
          <MoreHorizRoundedIcon />
        </IconButton>
        <Popper
          id="submenu-popper"
          className={classes.popper}
          open={showMenu}
          anchorEl={anchorOptionMenu}
          placement="bottom-end"
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps} in={showMenu}>
              <Box className={classes.boxSubmenu}>
                <ClickAwayListener onClickAway={handleCloseMenu}>
                  <Paper className={classes.paperSubmenu}>
                    <List component="div" disablePadding>
                      <ListItem button onClick={handleDelete}>
                        <ListItemText
                          primary="DELETE"
                          classes={{ primary: classes.deleteBtn }}
                        />
                      </ListItem>
                    </List>
                  </Paper>
                </ClickAwayListener>
              </Box>
            </Grow>
          )}
        </Popper>
      </Box>
      <Tooltip title={isDeleting ? getLang(lang, 'label.DELETE_CARD') : getLang(lang, 'label.SELECT_CARD')}>
        <ButtonBase
          className={clsx(classes.mockCard, {
            [classes.bgMastercard]: !isVisa,
            [classes.bgVisa]: isVisa,
            [classes.bgDeleting]: isDeleting
          })}
          onClick={handleSelect}
        >
          <Box className={classes.logo}>
            <img alt="card type" src={isVisa ? VisaLogo : MastercardLogo} />
          </Box>
          <Typography variant="subtitle1" className={classes.cardNumber}>
            {`**** **** **** ${last4number}`}
          </Typography>
          <Box>
            <Typography
              variant="caption"
              className={clsx({
                [classes.visaExpDate]: isVisa,
                [classes.mastercardExpDate]: !isVisa
              })}
            >
              {getLang(lang, 'label.EXP_DATE')}
            </Typography>
            <Typography variant="body2">{`${
              expMonth <= 9 ? "0" : ""
            }${expMonth}/${expYear}`}</Typography>
          </Box>
        </ButtonBase>
      </Tooltip>
      <Box className={classes.cardLabelContainer} py={isPrimary ? 1.5 : 0.625}>
        {isPrimary ? (
          <Typography variant="body1" color="primary">
            {getLang(lang, 'label.PRIMARY')}
          </Typography>
        ) : (
          <FormControlLabel
            className={classes.primarySwitch}
            value="primary"
            control={
              <Switch
                color="primary"
                disabled={isPrimarySwitchChecked || isDeleting}
                checked={isPrimarySwitchChecked}
                onChange={handleChangePrimarySwitch}
              />
            }
            label={getLang(lang, 'label.SET_PRIMARY')}
            labelPlacement="start"
          />
        )}
      </Box>
    </Box>
  );
}
