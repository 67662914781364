import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getLang } from "app/feature/constants";

import {
  clearWarrantyReimbursementDetail,
  fetchWarrantyReimbursementDetail,
} from "modules/warranty/redux";

import ClaimDetailDialogComponent from "./claimDetailDialog.component";
import { REIMBURSEMENT_LIST_VIEW } from "lib/constants/accessRights";

function ClaimDetailDialogContainer({
  handleClose,
  fromPanel,
  handleTableReload,

  //  CLAIM  //
  handleClaimProcessed,

  //  REIMBURSEMENT  //
}) {
  const dispatch = useDispatch();

  const lang = useSelector((state) => state.constant.languages);
  const isLoading = useSelector((state) => state.backdrop.show);
  const claimItem = useSelector((state) => state.warranty.consumerClaimDetail);
  const reimbursementItem = useSelector(
    (state) => state.warranty.warrantyReimbursementDetail
  );
  const hasReimbursementViewAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(REIMBURSEMENT_LIST_VIEW)
  );

  const [activeIndex, setActiveIndex] = useState(0);
  const tabLabels = [getLang(lang, "label.CLAIM")];
  if (claimItem.status === 2 && hasReimbursementViewAccessRight) {
    tabLabels.push(getLang(lang, "label.REIMBURSEMENT"));
  }

  const handleChange = (event, index) => {
    setActiveIndex(index);
  };

  useEffect(() => {
    if (claimItem.status === 2 && claimItem.reimbursementId) {
      dispatch(fetchWarrantyReimbursementDetail(claimItem.reimbursementId));
    }

    if (fromPanel === 1) {
      setActiveIndex(1);
    }

    return () => {
      setActiveIndex(0);
      dispatch(clearWarrantyReimbursementDetail());
    };
    // eslint-disable-next-line
  }, [dispatch, claimItem.uuid]);

  return (
    claimItem.uuid &&
    !isLoading && (
      <ClaimDetailDialogComponent
        isOpen={!!claimItem.uuid}
        handleClose={() => {
          handleClose();
        }}
        handleTableReload={handleTableReload}
        //  TABS  //
        handleChange={handleChange}
        activeIndex={activeIndex}
        tabLabels={tabLabels}
        //  CLAIM TAB  //
        claimItem={claimItem}
        handleClaimProcessed={handleClaimProcessed}
        //  REIMBURSEMENT TAB  //
        reimbursementItem={reimbursementItem}
      />
    )
  );
}

export default ClaimDetailDialogContainer;
