import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { ROOT } from "./config/dashboard.route";
import { MainPage } from "./pages";
import { dashboardReducer } from './redux/slice/dashboard.slice';

export default function Moduledashboard() {
  return (
    <Switch>
      <Route exact path={ROOT} component={MainPage} />
      <Route path="*">
        <Redirect to="/admin/invalid" />
      </Route>
    </Switch>
  );
}

export {
  ROOT,
  dashboardReducer
};
