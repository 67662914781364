import { addAlert, setLoading } from "modules/notification";
import { getCitySalesData, getCountrySalesData, getDemographicAgeData, getDemographicAgeGenderData, getDemographicGenderData } from "../action";

// (1) country sales data -- start
export const getCountrySalesDataMiddleware = ({ dispatch, getState}) => next => action => {
    next(action);
    if (action.type === getCountrySalesData.pending.type) {
        dispatch(setLoading({ id: getCountrySalesData.typePrefix, state: true }));
    }
    if (action.type === getCountrySalesData.fulfilled.type) {
        dispatch(setLoading({ id: getCountrySalesData.typePrefix, state: false }));
    }
    if (action.type === getCountrySalesData.rejected.type) {
        dispatch(setLoading({ id: getCountrySalesData.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: action.payload.message}));
    }
}
// (1) country sales data -- end

// (2) city sales data -- start
export const getCitySalesDataMiddleware = ({ dispatch, getState}) => next => action => {
    next(action);
    if (action.type === getCitySalesData.pending.type) {
        dispatch(setLoading({ id: getCitySalesData.typePrefix, state: true }));
    }
    if (action.type === getCitySalesData.fulfilled.type) {
        dispatch(setLoading({ id: getCitySalesData.typePrefix, state: false }));
    }
    if (action.type === getCitySalesData.rejected.type) {
        dispatch(setLoading({ id: getCitySalesData.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: action.payload.message}));
    }
}
// (2) city sales data -- end

// (3) age data -- start
export const getDemographicAgeDataMiddleware = ({ dispatch, getState}) => next => action => {
    next(action);
    if (action.type === getDemographicAgeData.pending.type) {
        dispatch(setLoading({ id: getDemographicAgeData.typePrefix, state: true }));
    }
    if (action.type === getDemographicAgeData.fulfilled.type) {
        dispatch(setLoading({ id: getDemographicAgeData.typePrefix, state: false }));
    }
    if (action.type === getDemographicAgeData.rejected.type) {
        dispatch(setLoading({ id: getDemographicAgeData.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: action.payload.message}));
    }
}
// (3) age data -- end

// (4) gender data -- start
export const getDemographicGenderDataMiddleware = ({ dispatch, getState}) => next => action => {
    next(action);
    if (action.type === getDemographicGenderData.pending.type) {
        dispatch(setLoading({ id: getDemographicGenderData.typePrefix, state: true }));
    }
    if (action.type === getDemographicGenderData.fulfilled.type) {
        dispatch(setLoading({ id: getDemographicGenderData.typePrefix, state: false }));
    }
    if (action.type === getDemographicGenderData.rejected.type) {
        dispatch(setLoading({ id: getDemographicGenderData.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: action.payload.message}));
    }
}
// (4) gender data -- end

// (5) age gender data -- start
export const getDemographicAgeGenderDataMiddleware = ({ dispatch, getState}) => next => action => {
    next(action);
    if (action.type === getDemographicAgeGenderData.pending.type) {
        dispatch(setLoading({ id: getDemographicAgeGenderData.typePrefix, state: true }));
    }
    if (action.type === getDemographicAgeGenderData.fulfilled.type) {
        dispatch(setLoading({ id: getDemographicAgeGenderData.typePrefix, state: false }));
    }
    if (action.type === getDemographicAgeGenderData.rejected.type) {
        dispatch(setLoading({ id: getDemographicAgeGenderData.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: action.payload.message}));
    }
}
// (5 age gender data -- end)

export default [
    getCountrySalesDataMiddleware,
    getCitySalesDataMiddleware,
    getDemographicAgeDataMiddleware,
    getDemographicGenderDataMiddleware,
    getDemographicAgeGenderDataMiddleware,
]