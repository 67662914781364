import axios from "axios";

const endpoint_url = "/serialnumber/api/v1/generates";

const post = (parameters) => {
  return new Promise((resolve, reject) => {
    axios
      .post(endpoint_url, {
        length: parameters.length,
        start: parameters.start,
        search: parameters.search,
        search_column: parameters.search_column,
        order: parameters.order,
        column: parameters.column,
        node_uuids: parameters.node_uuids,
        draw: parameters.draw,
      })
      .then((response) => resolve(response))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

const generatesBatch = {
  post,
};

export default generatesBatch;
