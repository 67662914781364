import React, { useState, useRef, useEffect } from "react";
import ConsumerSelectComponent from "./consumerSelect.component";
import { selectLoading } from "modules/notification";
import ConsumerSelectPanelComponent from "./consumerSelectPanel.component";
import { useSelector, useDispatch } from "react-redux";
// import { cloneDeep } from "lodash";
import { fetchClusterScanConsumerDropdown } from "modules/dashboard/redux/action/dashboard.action";
import { resetConsumerDropdown, changeSelectedConsumer, changeBulkSelectedConsumer } from "modules/dashboard/redux/slice/dashboard.slice";

function ConsumerSelectContainer({
  handleChange,
  disabled,
  value,
  styles,
  textStyles,
  placeholder,
  startDate,
  endDate,
  clusterBound,
}) {
  const isFetching = useSelector((state) =>
    selectLoading(state, fetchClusterScanConsumerDropdown.typePrefix)
  );
  const consumers = useSelector(
    (state) => state.dashboard.consumerDropdown.list
  );
  const totalFiltered = useSelector(
    (state) => state.dashboard.consumerDropdown.totalFiltered
  );
  const hasNextPage = useSelector(
    (state) => state.dashboard.consumerDropdown.hasNextPage
  );
  const selectedIds = useSelector((state) => state.dashboard.selectedId);
  const isSelectAll = useSelector((state) => state.dashboard.isSelectAll);
  const lang = useSelector((state) => state.constant.languages);
  const [open, setOpen] = useState(false);
  const [isOpenList] = useState(false);
  const anchorRef = useRef(null);
  const prevOpen = useRef(open);
  const prevOpenList = useRef(isOpenList);
  const [selectAll, setSelectAll] = useState(false);
  const dispatch = useDispatch();

  const [filter, setFilter] = useState({
    length: 20,
    page: 0,
    search: "",
  });

  useEffect(() => {
    dispatch(
      fetchClusterScanConsumerDropdown({
        length: filter.length,
        start: filter.page * filter.length,
        search: filter.search,
        start_date: startDate,
        end_date: endDate,
        longitude: [clusterBound.west, clusterBound.east],
        latitude: [clusterBound.south, clusterBound.north],
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, filter]);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    if (prevOpenList.current === true && isOpenList === false) {
      anchorRef.current.focus();
    }

    prevOpenList.current = isOpenList;
  }, [isOpenList]);

  useEffect(() => {
    setSelectAll(value.length === totalFiltered);
  }, [value, totalFiltered]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);

    if (filter.search) {
      dispatch(resetConsumerDropdown());
      setFilter({
        length: 20,
        page: 0,
        search: "",
      });
    }
  };

  // const handleSelectAll = async () => {
  //   if (!selectAll) {
  //     let selectedHolder = consumers.map((p) => {
  //       return p.uuid;
  //     });
  //     handleChange(selectedHolder);
  //   } else {
  //     handleChange([]);
  //   }
  // };

  const handleSelectAll = () => {
    setSelectAll(prevSelectAll => !prevSelectAll);
    if (!selectAll) {
      dispatch(changeBulkSelectedConsumer({ value: true }))
    } else {
      dispatch(changeBulkSelectedConsumer({ value: false }))
    }
  };

  const handleSelectedConsumer = (index, isSelected) => {
    dispatch(changeSelectedConsumer({ index, isSelected }));
  };

  // const handleSelectConsumer = (consumer) => {
  //   let temp = cloneDeep(value);

  //   if (temp.findIndex((t) => t === consumer.uuid) >= 0) {
  //     temp = temp.filter((t) => t !== consumer.uuid);
  //   } else {
  //     temp.push(consumer.uuid);
  //   }
  //   handleChange(temp);
  // };

  const handleSearch = (value) => {
    dispatch(resetConsumerDropdown());
    setFilter({
      ...filter,
      page: 0,
      search: value,
    });
  };

  const handleFetchMoreItems = () => {
    if (hasNextPage) {
      setFilter({
        ...filter,
        page: filter.page + 1,
      });
    }
  };

  return (
    <>
      <ConsumerSelectComponent
        anchorRef={anchorRef}
        disabled={disabled}
        value={selectedIds}
        handleClick={handleOpen}
        styles={styles}
        textStyles={textStyles}
        placeholder={placeholder}
        open={open}
        lang={lang}
        selectAll={isSelectAll}
      />
      <ConsumerSelectPanelComponent
        selectAll={selectAll}
        // value={value}
        anchorRef={anchorRef}
        open={open}
        isOpenList={isOpenList}
        handleClose={handleClose}
        isFetching={isFetching}
        consumerDropdown={consumers}
        handleSelectAll={handleSelectAll}
        // handleSelectConsumer={handleSelectConsumer}
        handleSelectedConsumer={handleSelectedConsumer}
        handleSearch={handleSearch}
        lang={lang}
        handleFetchMoreItems={handleFetchMoreItems}
        totalFiltered={totalFiltered}
      />
    </>
  );
}

export default ConsumerSelectContainer;
