import React from "react";
import {
  makeStyles,
  Typography,
  Box,
  Paper,
  IconButton,
  Button,
  Divider,
  Grid,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import InputTextField from "components/input/inputTextField";
import InfoIcon from "@material-ui/icons/Info";
import CountrySelect from "components/select/countrySelect";
import countries from "lib/constants/countries";
import { getLang } from "app/feature/constants";

const style = (theme) => ({
  root: {
    boxShadow: theme.shadows[5],
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "space-between",
    borderRadius: "4px",
    fontSize: 14,
    width: 500,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  detailsWrapper: {
    width: "100%",
    padding: theme.spacing(3, 3, 0, 3),
  },
  switch: {
    paddingLeft: theme.spacing(2),
  },
  header: {
    padding: "4px 0px",
    paddingLeft: theme.spacing(3),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  text: {
    paddingLeft: "4px",
    color: "rgba(58, 77, 84, 0.33)",
  },
});

const useStyles = makeStyles(style);

export default function BillingDetailFormComponent({
  handleClose,
  handleSubmit,
  billingDetail,
  lang,
  country,
  isCountryDropdownLoading
}) {
  const classes = useStyles();

  return (
    <Paper elevation={0} className={classes.root}>
      <div className={classes.header}>
        <Typography
          variant="subtitle2"
          color="primary"
          style={{ fontWeight: "bold" }}
        >
          {getLang(lang, "label.BILLING_DETAILS")}
        </Typography>
        <IconButton color="primary" onClick={handleClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </div>
      <Divider />

      <Formik
        initialValues={{
          name: billingDetail.name,
          phone: billingDetail.phone,
          phoneCode: countries.find(({ phone }) => phone === billingDetail.phoneCode),
          line1: billingDetail.line1,
          line2: billingDetail.line2,
          city: billingDetail.city,
          postal_code: billingDetail.postalCode,
          state: billingDetail.state,
          country: countries.find(({ label }) => label === billingDetail.country),
          email: billingDetail.email
        }}
        validationSchema={Yup.object({
          name: Yup.string().trim().required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
          email: Yup.string().email(getLang(lang, "message.error.FORM_VALIDATE_EMAIL")).required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
          phone: Yup.string().trim()
            .max(20, getLang(lang, "message.error.FORM_VALIDATE_MAX", { value: getLang(lang, "message.error.NUMBERS_20") }))
            .matches(/^[1-9]{1}[0-9]{4,19}$/, getLang(lang, "message.error.FORM_VALIDATE_PHONE"))
            .required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
          line1: Yup.string().trim().required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
          city: Yup.string().trim().required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
          postal_code: Yup.string().trim().max(10, getLang(lang, "message.error.FORM_VALIDATE_MAX", { value: 10 })).matches(/^[0-9a-zA-Z-_ ]*$/, getLang(lang, "message.error.INVALID_POSTCODE")).required(getLang(lang, "Fmessage.error.ORM_VALIDATE_REQUIRED")),
          state: Yup.string().trim().required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
          country: Yup.string().trim().required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED"))
        })}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <Box style={{ display: "flex", placeContent: "center" }}>
              <Box className={classes.detailsWrapper}>
                <Grid container spacing={2}>
                  {[
                    { title: getLang(lang, "label.CONTACT_PERSON"), name: "name" },
                    { title: getLang(lang, "label.CONTACT_NUMBER"), name: "phone" },
                    { title: getLang(lang, "label.ADDRESS_LINE_1"), name: "line1" },
                    { title: getLang(lang, "label.ADDRESS_LINE_2"), name: "line2" },
                    { title: getLang(lang, "label.CITY"), name: "city", type: "grid" },
                    { title: getLang(lang, "label.POSTAL_CODE"), name: "postal_code", type: "grid" },
                    { title: getLang(lang, "label.STATE"), name: "state", type: "grid" },
                    { title: getLang(lang, "label.COUNTRY"), name: "country", type: "grid" },
                    {
                      title: getLang(lang, "paragraph.SEND_COPY_TO"),
                      name: "email",
                      type: "emails"
                    }
                  ].map((field, index) => {
                    return (
                      <Grid
                        item
                        xs={12}
                        sm={field.type === "grid" ? 6 : 12}
                        md={field.type === "grid" ? 6 : 12}
                        style={{ width: "100%" }}
                        key={index}
                      >
                        <Box pb={1}>
                          <Typography variant="body2" color="primary">
                            {(field.title === getLang(lang, "label.CONTACT_PERSON") || field.title === getLang(lang, "paragraph.SEND_COPY_TO"))
                              ? <>{field.title}&nbsp;<Typography style={{ color: "#DE350B", display: 'inline-block' }}>*</Typography> </>
                              : field.title}
                          </Typography>

                          {field.name === "country" ? (
                            <CountrySelect
                              textFieldProps={{
                                required: true,
                                fullWidth: true,
                                variant: "filled",
                                size: "small",
                                margin: "dense",
                                error: formik.errors.country && formik.touched.country ? true : false,
                                helperText: ErrorMessage({ name: "country" })
                              }}
                              formProps={{
                                ...formik.getFieldProps("country"),
                                value: formik.values.country,
                                onChange: (e, newValue) => {
                                  formik.setFieldValue("country", newValue);
                                },
                                disabled: formik.isSubmitting,
                                disableClearable: true,
                              }}
                              type="countryOnly"
                            />
                          ) : (
                            <Box display="flex" gridColumnGap={12}>
                              {field.name === "phone" &&
                                <CountrySelect
                                  type="phone"
                                  textFieldProps={{
                                    required: true,
                                    style: { width: 175 },
                                    variant: "filled",
                                    size: "small",
                                    margin: "dense",
                                    error: formik.errors.phoneCode && formik.touched.phoneCode ? true : false,
                                    helperText: ErrorMessage({ name: "phoneCode" }),
                                  }}
                                  formProps={{
                                    ...formik.getFieldProps("phoneCode"),
                                    value: formik.values.phoneCode,
                                    onChange: (e, newValue) => {
                                      formik.setFieldValue("phoneCode", newValue);
                                    },
                                    disabled: formik.isSubmitting
                                  }}
                                />
                              }
                              {field.name === "phone" ?
                                <InputTextField
                                  onKeyPress={(e) => {
                                    if (!/[0-9]/.test(e.key)) {
                                      e.preventDefault();
                                    }
                                  }}
                                  hiddenLabel
                                  name={field.name}
                                  variant="filled"
                                  fullWidth
                                  margin="dense"
                                  {...formik.getFieldProps(field.name)}
                                  disabled={formik.isSubmitting}
                                  error={
                                    formik.touched[field.name] &&
                                      formik.errors[field.name]
                                      ? true
                                      : false
                                  }
                                  helperText={ErrorMessage({
                                    name: field.name
                                  })}
                                /> :
                                <InputTextField
                                  hiddenLabel
                                  name={field.name}
                                  variant="filled"
                                  fullWidth
                                  margin="dense"
                                  {...formik.getFieldProps(field.name)}
                                  disabled={formik.isSubmitting}
                                  error={
                                    formik.touched[field.name] &&
                                      formik.errors[field.name]
                                      ? true
                                      : false
                                  }
                                  helperText={ErrorMessage({
                                    name: field.name
                                  })}
                                />
                              }

                            </Box>
                          )}
                          {field.type === "emails" ? (
                            <Box
                              pb={1}
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <InfoIcon fontSize="small" color="primary" />
                              <Typography
                                variant="caption"
                                className={classes.text}
                              >
                                {getLang(lang, "paragraph.ONLY_E_BILLING_STATEMENT")}
                              </Typography>
                            </Box>
                          ) : null}
                        </Box>
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            </Box>
            <Divider />
            <Box m={3} display="flex" justifyContent="space">
              <Box flexGrow={1} display="flex" alignItems="center">
                <Typography variant="caption" color="primary">
                  <Typography style={{ color: "#DE350B", display: 'inline-block' }}>*</Typography>  {getLang(lang, "label.REQUIRED_FIELD")}
                </Typography>
              </Box>
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  type="submit"
                  disabled={formik.isSubmitting}
                >
                  {getLang(lang, "label.UPDATE")}
                </Button>
              </Box>
            </Box>
          </form>
        )}
      </Formik>
    </Paper>
  );
}
