import React from "react";

import {
  makeStyles,
  Box,
  Typography,
  IconButton,
  Divider,
  Button,
  Dialog,
  Grid,
  Link,
  Backdrop,
  Card,
} from "@material-ui/core";
import { CloseRounded as CloseRoundedIcon } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import { getLang } from "app/feature/constants";
import { Skeleton } from "@material-ui/lab";
import moment from "moment";
import { Formik } from "formik";
import SingleBranchSelect from "../../select/singleBranchSelect";
import "moment/min/locales.min";
import DOMPurify from "dompurify";

const style = (theme) => ({
  container: {
    backgroundColor: "#EBEDEE",
    overflowY: "auto",
    padding: "16px 16px 34px 16px",
  },
});

const useStyles = makeStyles(style);

export default function HAndoffDetailDialogComponent({
  isOpen,
  handleClose,
  handoff,
  isError,
  handleReload,
  isLoading,
  lang,
  language,
  handleMediaClick,
  handleApprove,
  handleReject,
  isApproving,
  profileLang,
}) {
  const classes = useStyles();
  const history = useHistory();

  if (language === "ZH") {
    moment.locale("zh-cn");
  } else if (language === "TL") {
    moment.locale("tl-ph");
  } else {
    moment.locale(language);
  }

  const generateLink = (remark) => {
    let urlRegex = /(https?:\/\/[^\s]+)/g;
    let textWithLink = remark.replace(urlRegex, (url) => {
      return '<a href="' + url + '" target="_blank">' + url + "</a>";
    });

    const sanitizedHtmlContent = DOMPurify.sanitize(textWithLink);

    return (
      <Box
        dangerouslySetInnerHTML={{
          __html: sanitizedHtmlContent,
        }}
      />
    );
  };

  return (
    <Dialog
      maxWidth="md"
      open={isOpen}
      onClose={() => {}}
      aria-labelledby="modal-scan-detail"
      aria-describedby="modal-scan-detail"
      BackdropComponent={Backdrop}
      fullWidth
      PaperProps={{
        style: {
          overflowY: "hidden",
        },
      }}
    >
      <Formik
        initialValues={{
          branch: null,
        }}
        onSubmit={handleApprove}
      >
        {(formik) => (
          <>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              px={2}
              py={1}
            >
              <Typography variant="body1">
                <b>{getLang(lang, "label.ITEM_REPLACEMENT_HANDOFF")}</b>
              </Typography>
              <IconButton
                onClick={handleClose}
                edge="end"
                size="small"
                aria-label="close dialog"
                // disabled={isLoading}
              >
                <CloseRoundedIcon color="primary" />
              </IconButton>
            </Box>
            <Divider />
            <Box className={classes.container}>
              {isLoading ? (
                <>
                  <Skeleton variant="text" animation="wave" height={25} />
                  <Skeleton variant="text" animation="wave" height={25} />
                  <Skeleton variant="text" animation="wave" height={25} />
                  <Skeleton variant="text" animation="wave" height={25} />
                </>
              ) : isError ? (
                <Box
                  display="flex"
                  justifyContent="center"
                  flexDirection="column"
                  alignItems="center"
                  pt={2}
                  pb={3}
                >
                  <Typography
                    variant="body2"
                    color="primary"
                    style={{ fontWeight: "bold", paddingBottom: "8px" }}
                  >
                    {getLang(lang, "label.NO_DATA_RECEIVED")}
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{ color: "#9CA6A9", paddingBottom: "16px" }}
                  >
                    {getLang(lang, "label.SOMETHING_WENT_WRONG")}
                  </Typography>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleReload}
                    disableElevation
                  >
                    {getLang(lang, "label.REFRESH")}
                  </Button>
                </Box>
              ) : handoff ? (
                <Box>
                  {handoff?.claim && (
                    <>
                      <Box>
                        <Typography variant="body2">
                          <b>
                            {getLang(lang, "label.WARRANTY_CLAIM_PANEL_TITLE")}
                          </b>
                        </Typography>
                      </Box>
                      <Box mt={2}>
                        <Grid container>
                          <Grid item xs={4}>
                            {getLang(lang, "label.WARRANTY_CLAIM_ID")}
                          </Grid>
                          <Grid item xs={8}>
                            {handoff.claim.reference_code ?? "-"}
                          </Grid>
                        </Grid>
                      </Box>
                      <Box mt={2}>
                        <Grid container>
                          <Grid item xs={4}>
                            {getLang(lang, "label.APPROVED_BY")}
                          </Grid>
                          <Grid item xs={8}>
                            {handoff.claim.approved_by ? (
                              <Link
                                href={`/admin/employee/View/${handoff.claim.approved_by?.uuid}`}
                                color="secondary"
                                onClick={(e) => {
                                  history.push(
                                    `/admin/employee/View/${handoff.claim.approved_by?.uuid}`
                                  );
                                  e.preventDefault();
                                  return false;
                                }}
                              >
                                <Typography
                                  variant="body1"
                                  style={{ textAlign: "left" }}
                                >
                                  {handoff.claim.approved_by?.name ?? "-"}
                                </Typography>
                              </Link>
                            ) : (
                              "-"
                            )}
                          </Grid>
                        </Grid>
                      </Box>
                      <Box mt={2}>
                        <Grid container>
                          <Grid item xs={4}>
                            {getLang(
                              lang,
                              "label.WARRANTY_CLAIM_APPROVED_DATE"
                            )}
                          </Grid>
                          <Grid item xs={8}>
                            {handoff.claim.approved_at
                              ? moment
                                  .utc(handoff.claim.approved_at)
                                  .local()
                                  .format("lll")
                              : "-"}
                          </Grid>
                        </Grid>
                      </Box>
                      <Box mt={2}>
                        <Grid container>
                          <Grid item xs={4}>
                            {getLang(lang, "label.SUBMISSION_DATE")}
                          </Grid>
                          <Grid item xs={8}>
                            {moment
                              .utc(handoff.claim.created_at)
                              .local()
                              .format("lll")}
                          </Grid>
                        </Grid>
                      </Box>
                      <Box mt={2}>
                        <Grid container>
                          <Grid item xs={4}>
                            {getLang(lang, "label.CLAIM_ADDRESS")}
                          </Grid>
                          <Grid item xs={8}>
                            {handoff.claim.claim_address ?? "-"}
                          </Grid>
                        </Grid>
                      </Box>
                      {handoff.claim.metadata && (
                        <Box mt={2}>
                          <Grid container>
                            <Grid item xs={4}>
                              {getLang(lang, "label.UPLOADED_FILE")}
                            </Grid>
                            <Grid item xs={8}>
                              <Box
                                py={1}
                                mb={1}
                                display="flex"
                                flexWrap="no-wrap"
                                overflowX="auto"
                              >
                                {handoff.claim.metadata.video_file?.length >
                                  0 &&
                                  handoff.claim.metadata.video_file.map(
                                    (file) => (
                                      <Box mr={1}>
                                        <Card>
                                          <Box height="70px" width="70px">
                                            <video
                                              width="70"
                                              height="70"
                                              preload="metadata"
                                              muted
                                              onClick={() =>
                                                handleMediaClick(file, "video")
                                              }
                                            >
                                              <source src={file} />
                                            </video>
                                          </Box>
                                        </Card>
                                      </Box>
                                    )
                                  )}
                                {handoff.claim.metadata.image_file.length > 0 &&
                                  handoff.claim.metadata.image_file.map(
                                    (file) => (
                                      <Box mr={1}>
                                        <Card>
                                          <Box height="70px" width="70px">
                                            <img
                                              height="70px"
                                              width="70px"
                                              style={{ objectFit: "contain" }}
                                              src={file}
                                              alt="img"
                                              onClick={() =>
                                                handleMediaClick(file, "image")
                                              }
                                            />
                                          </Box>
                                        </Card>
                                      </Box>
                                    )
                                  )}
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      )}
                      {handoff.claim.claim_reason_options && (
                        <Box mt={2}>
                          <Grid container>
                            <Grid item xs={4}>
                              {getLang(lang, "label.CLAIM_REASONS")}
                            </Grid>
                            <Grid item xs={8}>
                              {handoff.claim.claim_reason_options.map(
                                (item, index) => (
                                  <Box key={index}>
                                    {index + 1}.{" "}
                                    {item.reason_name[profileLang] ||
                                      item.reason_name["EN"]}
                                  </Box>
                                )
                              )}
                            </Grid>
                          </Grid>
                        </Box>
                      )}
                      <Box mt={2}>
                        <Grid container>
                          <Grid item xs={4}>
                            {getLang(lang, "label.REMARK")}
                          </Grid>
                          <Grid item xs={8}>
                            <Box>
                              {handoff.claim.remark
                                ? generateLink(handoff.claim.remark)
                                : "-"}
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </>
                  )}
                  <Box my={2}>
                    <Divider />
                  </Box>
                  <Box>
                    <Box>
                      <Typography variant="body2">
                        <b>{getLang(lang, "label.HANDOFF")}</b>
                      </Typography>
                    </Box>
                    <Box mt={2}>
                      <Grid container>
                        <Grid item xs={4}>
                          {getLang(lang, "label.STATUS")}
                        </Grid>
                        <Grid item xs={8}>
                          <Box
                            fontWeight="fontWeightBold"
                            bgcolor={
                              handoff.status === "rejected"
                                ? "error.main"
                                : handoff.status === "approved"
                                ? "secondary.main"
                                : "warning.main"
                            }
                            color="#FFFFFF"
                            px={1.5}
                            py={0.5}
                            borderRadius={4}
                            height="min-content"
                            width="fit-content"
                          >
                            {getLang(lang, `db_label.${handoff.status}`)}
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box mt={2}>
                      <Grid container>
                        <Grid item xs={4}>
                          {getLang(lang, "label.SUBMITTED_DATE")}
                        </Grid>
                        <Grid item xs={8}>
                          <b>
                            {moment
                              .utc(handoff.created_at)
                              .local()
                              .format("lll")}
                          </b>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box mt={2}>
                      <Grid container>
                        <Grid item xs={4}>
                          {getLang(lang, "label.SUBMITTED_BY")}
                        </Grid>
                        <Grid item xs={8}>
                          {handoff.handoff_from.name ?? "-"}
                        </Grid>
                      </Grid>
                    </Box>
                    <Box mt={2}>
                      <Grid container>
                        <Grid item xs={4}>
                          {getLang(lang, "label.REMARK")}
                        </Grid>
                        <Grid item xs={8}>
                          {handoff.handoff_remark ?? "-"}
                        </Grid>
                      </Grid>
                    </Box>
                    {handoff.status !== "pending" && (
                      <Box mt={2}>
                        <Grid container>
                          <Grid item xs={4}>
                            {getLang(lang, "label.RESPOND_DATE")}
                          </Grid>
                          <Grid item xs={8}>
                            <b>
                              {moment
                                .utc(handoff.respond_at)
                                .local()
                                .format("lll")}
                            </b>
                          </Grid>
                        </Grid>
                      </Box>
                    )}
                    <Box mt={2}>
                      <Grid container alignItems="center">
                        <Grid item xs={4}>
                          {getLang(lang, "label.HANDOFF_TO")}
                        </Grid>
                        <Grid item xs={8}>
                          {handoff.status === "pending" ? (
                            <>
                              <SingleBranchSelect
                                placeholder={getLang(
                                  lang,
                                  "placeholder.BRANCH"
                                )}
                                value={formik.values.branch}
                                handleChange={(value) =>
                                  formik.setFieldValue("branch", value)
                                }
                                styles={{
                                  padding: "6px 10px",
                                }}
                                disabled={isApproving}
                              />
                            </>
                          ) : (
                            handoff.handoff_to?.name ?? "-"
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Box>
              ) : null}
            </Box>
            <Divider />
            {handoff?.status === "pending" && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p={2}
              >
                <Typography>
                  <b>{getLang(lang, "label.HANDOFF_APPROVAL")}</b>
                </Typography>
                <Box display="flex" alignItems="center">
                  <Button
                    variant="outlined"
                    color="secondary"
                    style={{
                      borderColor: "#F04438",
                      color: "#F04438",
                      marginRight: 10,
                    }}
                    onClick={handleReject}
                  >
                    {getLang(lang, "label.REJECT")}
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={formik.handleSubmit}
                    disabled={!formik.values.branch}
                  >
                    {getLang(lang, "label.APPROVE")}
                  </Button>
                </Box>
              </Box>
            )}
          </>
        )}
      </Formik>
    </Dialog>
  );
}
