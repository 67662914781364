import {
  Backdrop,
  Box,
  Dialog,
  IconButton,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import { getLang } from "../../../../../app/feature/constants";
import { CloseRounded as CloseRoundedIcon } from "@material-ui/icons";
import React from "react";
import DefaultProfile from "../../../../../assets/img/defaultPhoto.png";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
import LoadingComponent from "../../../../../components/loading";

const useStyle = makeStyles((theme) => ({
  header: {
    padding: "12px 16px",
    borderBottom: "1px solid #D0D5DD",
  },
  container: {
    backgroundColor: "#F9FAFB",
    overflowY: "auto",
    padding: 16,
    paddingBottom: 8,
  },
  profilePhoto: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #E4E7EC",
    width: "45px",
    minWidth: "25px",
    height: "45px",
    marginRight: 8,
    borderRadius: "50%",
    "& img": {
      height: "45px",
      width: "45px",
      borderRadius: "50%",
    },
  },
}));

export default function NewConsumerPopUpComponent({
  isOpen,
  handleClose,
  lang,
  rows,
  haveNext,
  handleLoadingMore,
  handleConsumerNameClick,
}) {
  const classes = useStyle();

  const ListTile = ({ user, userId, userProfileUrl, userName, joinedDate }) => (
    <Box style={{ cursor: "pointer" }} pb="16px">
      <Paper>
        <Box
          p={2}
          style={{ display: "flex", alignItems: "center" }}
          onClick={() => handleConsumerNameClick(userId)}
        >
          <Box className={classes.profilePhoto}>
            {!userProfileUrl ? (
              <img src={DefaultProfile} alt="default-profile" />
            ) : (
              <img src={userProfileUrl} alt={userName} />
            )}
          </Box>
          <Box style={{ display: "flex", flexDirection: "column" }}>
            <Box>
              <Typography variant="body1" color="secondary">
                {userName}
              </Typography>
            </Box>
            <Box>
              <Typography variant="caption">
                {getLang(lang, "label.JOINED_AT")}{" "}
                {moment(joinedDate).format("DD MMM YYYY, HH:mm")}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Box>
  );

  const Loading = () => (
    <Box py={3} style={{ display: "flex", justifyContent: "center" }}>
      <LoadingComponent />
    </Box>
  );

  return (
    <Dialog
      maxWidth="sm"
      open={isOpen}
      onClose={() => {}}
      aria-labelledby="modal-scan-detail"
      aria-describedby="modal-scan-detail"
      BackdropComponent={Backdrop}
      fullWidth
      PaperProps={{
        style: {
          overflowY: "hidden",
        },
      }}
    >
      <Box
        className={classes.header}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="body1">
          <b>{getLang(lang, "label.NEW_CONSUMER_REGISTERED")}</b>
        </Typography>
        <IconButton
          onClick={handleClose}
          edge="end"
          size="small"
          aria-label="close dialog"
        >
          <CloseRoundedIcon color="primary" />
        </IconButton>
      </Box>
      <Box id="scrollContainer" style={{ height: "700px", overflow: "hidden" }}>
        <InfiniteScroll
          height="700px"
          style={{ height: "700px", padding: "16px" }}
          dataLength={rows.length}
          next={handleLoadingMore}
          hasMore={haveNext}
          loader={<Loading />}
        >
          <Box className={classes.container}>
            {rows.map((row, i) => (
              <ListTile
                key={i}
                user={row.user}
                userId={row.user_id}
                userProfileUrl={row.image_url}
                userName={row.name}
                joinedDate={row.created_at}
              />
            ))}
          </Box>
        </InfiniteScroll>
      </Box>
    </Dialog>
  );
}
