import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';

const useStyle = makeStyles(theme => ({
  badgeStyle: {
    marginLeft: "0.25em",
    padding: "0 0.5em",
    borderRadius: "1em",
    color: "white",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center"
  }
}));

export default function CustomizedBadges({ badgeContent = 0, bgColor = "#6AAF68", max = 99, props }) {
  const classes = useStyle();

  const displayValue = () => {
    if (badgeContent > max) {
      return max + "+";
    }
    return badgeContent
  }

  return (
    <>
      {badgeContent > 0 && (
        <Box className={classes.badgeStyle} style={{backgroundColor: bgColor}}>
          <Typography variant="caption">{displayValue()}</Typography>
        </Box>
      )}
    </>
  )
  
}