import React from "react";

import MaintenancePage from "./maintenance.page";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { hideBackdrop, showBackdrop } from "modules/backdrop";
import { unwrapResult } from "@reduxjs/toolkit";
import { checkMaintenanceStatus } from "modules/public/redux/action";

function MaintenanceContainer() {
  const dispatch = useDispatch();
  const isMaintenance = useSelector((state) => state.session.isMaintenance);
  const lang = useSelector((state) => state.constant.languages);

  useEffect(() => {
    checkStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMaintenance]);

  const checkStatus = () => {
    dispatch(showBackdrop());
    dispatch(checkMaintenanceStatus())
      .then(unwrapResult)
      .then((result) => {
        dispatch(hideBackdrop());

        if (!result) {
          window.location.replace("/");
        }
      })
      .catch(() => {
        dispatch(hideBackdrop());
      });
  };

  const refresh = () => {
    checkStatus();
  };

  return <MaintenancePage lang={lang} refresh={refresh} />;
}

export default MaintenanceContainer;
