import React, {useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import ExportToExcelSelectPanelComponent from "./exportToExcelSelectPanel.component";
import ExportToExcelSelectComponent from "./exportToExcelSelect.component";

export default function ExportToExcelSelectContainer({
  disabled,
  isLoading,
  dropdownItems,
  handleSelect,
}) {
  const [open, setOpen] = useState(false);
  const [isOpenList] = useState(false);
  const anchorRef = useRef(null);
  const prevOpen = useRef(open);
  const prevOpenList = useRef(isOpenList);
  const lang = useSelector(state => state.constant.languages);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    if (prevOpenList.current === true && isOpenList === false) {
      anchorRef.current.focus();
    }

    prevOpenList.current = isOpenList;
  }, [isOpenList]);

  return (
    <>
      <ExportToExcelSelectComponent
        anchorRef={anchorRef}
        isLoading={isLoading}
        handleClick={handleClick}
        disabled={disabled}
        lang={lang}
      />
      <ExportToExcelSelectPanelComponent
        anchorRef={anchorRef}
        open={open}
        handleClose={handleClose}
        dropdownItems={dropdownItems}
        isOpenList={isOpenList}
        handleSelection={(type) => {
          handleSelect(type);
          handleClose();
        }}
      />
    </>
  );
}
