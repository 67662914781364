import { createAsyncThunk } from "@reduxjs/toolkit";
import roleAccessRight from "app/api/roleAccessRight";
import { ROLE_ACCESS_RIGHT } from "modules/role-access-right/constant";

export const fetchAccessRightList = createAsyncThunk(
  `${ROLE_ACCESS_RIGHT}/fetchAccessRightList`,
  async (payload, thunkApi) => {
    return roleAccessRight.getAllAccessRight.get(payload)
    .then((response) => response.data)
    .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const fetchRoleList = createAsyncThunk(
  `${ROLE_ACCESS_RIGHT}/fetchRoleList`,
  async (payload, thunkApi) => {
    return roleAccessRight.getAllRoleList.get(payload)
    .then((response) => response.data)
    .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const fetchRoleDetail = createAsyncThunk(
  `${ROLE_ACCESS_RIGHT}/fetchRoleDetail`,
  async (payload, thunkApi) => {
    return roleAccessRight.roleAccessRight.get(payload)
    .then((response) => response.data)
    .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const createRole = createAsyncThunk(
  `${ROLE_ACCESS_RIGHT}/createRole`,
  async (payload, thunkApi) => {
    return roleAccessRight.roleAccessRight.post(payload)
    .then((response) => response)
    .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const updateRole = createAsyncThunk(
  `${ROLE_ACCESS_RIGHT}/updateRole`,
  async (payload, thunkApi) => {
    return roleAccessRight.roleAccessRight.put(payload)
    .then((response) => response)
    .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const deleteRole = createAsyncThunk(
  `${ROLE_ACCESS_RIGHT}/deleteRole`,
  async (payload, thunkApi) => {
    return roleAccessRight.roleAccessRight.remove(payload)
    .then((response) => response)
    .catch((error) => thunkApi.rejectWithValue(error));
  }
);

