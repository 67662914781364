import React, { useState } from "react";
import { useSelector } from "react-redux";
import { PRODUCT_BRAND_MODEL_ADD } from "lib/constants/accessRights"
import BrandModelComponent from './brandModel.component';

function BrandModelContainer(){
  const [ isCreateDialogOpen, setIsCreateDialogOpen] = useState(false)

  const handleCreateBrandModel = (value) => {
    setIsCreateDialogOpen(value)
  }
  const lang = useSelector(state => state.constant.languages);
  const hasProductBrandModelAddAccessRight = useSelector(state => state.profile.accessRights.includes(PRODUCT_BRAND_MODEL_ADD));

  return (
    <>
      <BrandModelComponent
        hasProductBrandModelAddAccessRight={hasProductBrandModelAddAccessRight}
        handleCreateBrandModel={handleCreateBrandModel} 
        isCreateDialogOpen={isCreateDialogOpen}
        lang={lang}
      />
    </>
  )
}

export default BrandModelContainer;