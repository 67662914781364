import React, { useRef, useState, useEffect } from "react";
import {
  Button,
  makeStyles,
  Dialog,
  Box,
  TextField,
  Divider,
  Typography,
  ButtonBase,
  withStyles,
  Tooltip,
  Chip,
  IconButton,
} from "@material-ui/core";
import { getLang } from "app/feature/constants";
import {
  RemoveCircleOutlineOutlined as RemoveCircleIcon,
  AddCircleOutlineOutlined as AddCircleIcon,
  ArrowDropUp as ArrowDropUpIcon,
  ArrowDropDown as ArrowDropDownIcon,
  Close as CloseIcon,
} from "@material-ui/icons";
import DatePickerScrollDialog from "modules/serial-number/components/dialog/datePickerScrollDialog";
import { Formik } from "formik";
import clsx from "clsx";
import CustomFieldSelect from "../../select/customFieldSelect";
import moment from "moment";

const useStyle = makeStyles((theme) => ({
  root: {
    padding: "5px 12px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#ECEFF0",
    borderRadius: "4px",
    width: "100%",
  },
  dialogAction: {
    display: "flex",
    justifyContent: "flex-end",
  },
  dropdownSearchBox: {
    backgroundColor: "#E9EDEF",
    borderRadius: 4,
    width: "100%",
    alignItems: "center",
    display: "grid",
    gridTemplateColumns: "4fr 6fr",
  },
  textFieldInput: {
    padding: "7px",
    paddingTop: "8px",
    width: "100%",
    color: theme.palette.primary.main,
    [theme.breakpoints.down("xs")]: {
      minWidth: "50%",
    },
  },
  inputText: {
    color: "#a4a6a8",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontWeight: 500,
  },
  filled: {
    minWidth: "auto",
    fontWeight: 500,
    color: theme.palette.primary.main,
    display: "inline-block",
    flexWrap: "wrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "100%",
    alignSelf: "center",
  },
  filledWithNum: {
    minWidth: "auto",
    fontWeight: 500,
    color: theme.palette.primary.main,
    display: "inline-block",
    flexWrap: "wrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "90%",
    alignSelf: "center",
  },
  tooltipChip: {
    backgroundColor: "#FFF",
    margin: "0.5em 0.3em 0.5em 0.3em",
    display: "flex",
    justifyContent: "space-between",
    boxShadow:
      "0px 3px 1px -2px rgba(0,0,0,0.1), 0px 2px 2px 0px rgba(0,0,0,0.04), 0px 1px 5px 0px rgba(0,0,0,0.02)",
  },
  tooltip: {
    maxHeight: "150px",
    overflow: "auto",
    display: "grid",
    "&::-webkit-scrollbar": {
      width: "0.3em",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "rgba(255,255,255,1)",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "2px",
      backgroundColor: "#ccd1d3",
    },
  },
  chip: {
    backgroundColor: "#FFF",
    marginRight: "0.25em",
    marginBottom: "0.15em",
    marginTop: "0.15em",
    border: "none",
    boxShadow:
      "0px 3px 1px -2px rgba(0,0,0,0.1), 0px 2px 2px 0px rgba(0,0,0,0.04), 0px 1px 5px 0px rgba(0,0,0,0.02)",
  },
}));

const CustomizeTooltip = withStyles({
  tooltip: {
    backgroundColor: "#f7f7f7",
    boxShadow:
      "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
  },
  arrow: {
    scale: 2,
    color: "#f7f7f7",
    top: "-4px !important",
    "&::before": {
      boxShadow:
        "0px 5px 0px -2px rgba(0,0,0,0.2), 0px 0px 2px -1px rgba(0,0,0,0.44), 0px 0px 5px -2px rgba(0,0,0,0.42)",
    },
  },
})(Tooltip);

export default function StockoutCustomFieldSearchDialogComponent({
  customFields,
  disabled,
  isOpen,
  setIsOpen,
  lang,
  handleSearch,
  userLang,
  adjustField,
  extraChip,
  placeholder,
  drawerOpen,
  setFilter,
  customFieldsDropdown,
  isError,
  filter,
  handleClear,
}) {
  const classes = useStyle();
  const ref = useRef(null);
  const [dimension, setDimension] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    // Add event when user zoom in/out or resize screen size
    const handleResize = () => {
      setDimension({
        width: window.innerHeight,
        height: window.innerWidth,
      });
      adjustField(ref);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    adjustField(ref);
    // eslint-disable-next-line
  }, [handleSearch, dimension]);

  useEffect(() => {
    setTimeout(() => {
      adjustField(ref);
      window.dispatchEvent(new Event("resize"));
    }, 200);
    // eslint-disable-next-line
  }, [drawerOpen]);

  const getDisplay = () => {
    let display = placeholder;
    if (customFields.length > 0) {
      let info = customFields[0].info;
      display =
        info && info.field_name
          ? info.field_name[userLang] ??
            info.field_name["EN"] ??
            Object.values(info.field_name)[0]
          : "";
    }
    return display;
  };

  let isEmpty = !customFields.length;

  const displayValue = () => {
    return (
      <Box
        ref={ref}
        style={{
          display: "flex",
          flexWrap: "wrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          flex: "2",
        }}
      >
        <Typography
          variant="body2"
          noWrap
          style={extraChip.allOverflow ? { textOverflow: "clip" } : null}
          className={clsx(
            classes.inputText,
            customFields.length > 1 && classes.filledWithNum,
            getDisplay() !== placeholder && classes.filled
          )}
        >
          {isEmpty ? (
            <Typography
              variant="body2"
              className={clsx(classes.inputText, {
                [classes.filled]: !isEmpty,
              })}
            >
              {placeholder}
            </Typography>
          ) : (
            customFields.map((item, i) => (
              <Chip
                key={i}
                variant="outlined"
                label={
                  item.info
                    ? `${
                        item.info?.field_name
                          ? item.info?.field_name[userLang] ??
                            item.info?.field_name["EN"] ??
                            Object.values(item.info?.field_name)[0]
                          : ""
                      }: ${
                        item.info?.field_type === 2
                          ? moment(item.search).format("DD MMM YYYY")
                          : item.search
                      }`
                    : item.search
                }
                size="small"
                className={classes.chip}
                style={extraChip.allOverflow ? { visibility: "hidden" } : {}}
              />
            ))
          )}
        </Typography>
      </Box>
    );
  };

  const extraList = () => {
    return (
      <div className={classes.tooltip}>
        {extraChip.overflowChild.map((item, i) => (
          <Chip
            label={
              item.info && item.info.field_name
                ? item.info.field_name[userLang] ??
                  item.info.field_name["EN"] ??
                  Object.values(item.info?.field_name)[0]
                : ""
            }
            size="small"
            key={i}
            className={classes.tooltipChip}
          />
        ))}
      </div>
    );
  };

  return (
    <>
      <ButtonBase
        className={clsx(classes.root, {
          [classes.disabledField]: disabled,
        })}
        disableRipple
        onClick={() => setIsOpen(true)}
        disabled={disabled}
        style={{ padding: "4px 12px", height: 38 }}
      >
        {displayValue()}
        <Box
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {extraChip.count >= 1 && !isEmpty ? (
            <CustomizeTooltip title={extraList()} arrow interactive>
              <Chip
                label={"+ " + extraChip.count}
                size="small"
                className={classes.chip}
                style={{
                  left: extraChip.offset,
                  position: "absolute",
                  marginLeft: 3,
                }}
              />
            </CustomizeTooltip>
          ) : null}

          {isOpen ? (
            <ArrowDropUpIcon fontSize="small" />
          ) : (
            <ArrowDropDownIcon fontSize="small" />
          )}
        </Box>
      </ButtonBase>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        disableEscapeKeyDown
        aria-labelledby="picker-dialog"
        fullWidth
        maxWidth="sm"
      >
        <Formik
          initialValues={{
            customFields: customFields.length
              ? customFields
              : [
                  {
                    info: null,
                    search: "",
                  },
                ],
            selectedIds: customFields.length
              ? customFields.map((c) => c.info.id)
              : [],
          }}
          enableReinitialize
          onSubmit={handleSearch}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <Box p={2}>
                <Box display="flex" justifyContent="space-between">
                  <Typography
                    variant="body2"
                    style={{
                      textWrap: "nowrap",
                      marginBottom: 16,
                    }}
                  >
                    <b>{getLang(lang, "label.CUSTOM_FIELDS")}</b>
                  </Typography>
                  <IconButton
                    size="medium"
                    style={{ marginTop: -20, marginRight: -14 }}
                    color="primary"
                    onClick={() => {
                      formik.resetForm();
                      setIsOpen(false);
                    }}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Box>
                {formik.values.customFields.map((a, i) => (
                  <Box display="flex" alignItems="center" my={1} key={i}>
                    <Box
                      flex={1}
                      display="flex"
                      className={classes.dropdownSearchBox}
                      height={38}
                    >
                      <Box display="flex" height="100%">
                        <CustomFieldSelect
                          value={a.info}
                          handleChange={(field) => {
                            let temp = [...formik.values.customFields];

                            let idToRemove = null;
                            if (temp[i].info) {
                              idToRemove = temp[i].info.id;
                            }

                            temp[i] = {
                              info: field,
                              search:
                                temp[i].info?.format !== field.format
                                  ? ""
                                  : temp[i].search,
                            };
                            formik.setFieldValue("customFields", temp);

                            let ids = [...formik.values.selectedIds];
                            if (idToRemove >= 0) {
                              ids = ids.filter((i) => i !== idToRemove);
                            }
                            ids.push(field.id);
                            formik.setFieldValue("selectedIds", [
                              ...new Set(ids),
                            ]);
                          }}
                          placeholder={getLang(lang, "label.TITLE")}
                          disabled={disabled}
                          style={{ height: 38, flex: 1 }}
                          userLang={userLang}
                          selectedIds={formik.values.selectedIds}
                          setFilter={setFilter}
                          customFieldsDropdown={customFieldsDropdown}
                          isError={isError}
                          filter={filter}
                        />
                        <Divider orientation="vertical" />
                      </Box>

                      {a.info?.field_type === 2 ? (
                        <DatePickerScrollDialog
                          label=""
                          handleChange={(value) => {
                            let temp = [...formik.values.customFields];
                            temp[i] = {
                              ...temp[i],
                              search: value.format("YYYY-MM-DD"),
                            };
                            formik.setFieldValue("customFields", temp);
                          }}
                          InputProps={{
                            style: {
                              backgroundColor: "#eceff0",
                              border: "none",
                            },
                          }}
                          value={a.search ?? moment.now()}
                          displayFormat="DD MMM YYYY"
                        />
                      ) : (
                        <TextField
                          size="small"
                          margin="none"
                          placeholder={getLang(lang, "placeholder.SEARCH")}
                          InputProps={{
                            disableUnderline: true,
                            margin: "none",
                          }}
                          inputProps={{
                            className: classes.textFieldInput,
                          }}
                          style={{ flex: 1 }}
                          value={a.search}
                          onChange={(event) => {
                            let temp = [...formik.values.customFields];
                            temp[i] = {
                              ...temp[i],
                              search: event.target.value,
                            };
                            formik.setFieldValue("customFields", temp);
                          }}
                        />
                      )}
                    </Box>

                    <Box
                      onClick={() => {
                        let temp = [...formik.values.customFields];
                        if (temp.length === 1) {
                          temp = [
                            {
                              info: null,
                              search: "",
                            },
                          ];
                        } else {
                          temp.splice(i, 1);
                        }
                        formik.setFieldValue("customFields", temp);

                        let ids = [...formik.values.selectedIds].filter(
                          (i) => i !== a.info?.id
                        );
                        formik.setFieldValue("selectedIds", [...new Set(ids)]);
                      }}
                      style={{ marginLeft: 10 }}
                    >
                      <RemoveCircleIcon style={{ color: "#F97066" }} />
                    </Box>
                    <Box
                      onClick={() => {
                        let temp = [...formik.values.customFields];
                        temp.push({
                          info: null,
                          search: "",
                        });
                        formik.setFieldValue("customFields", temp);
                      }}
                      style={{ marginLeft: 10 }}
                    >
                      <AddCircleIcon style={{ color: "#6AAF68" }} />
                    </Box>
                  </Box>
                ))}

                <Box className={classes.dialogAction} mt={2}>
                  <Button
                    color="primary"
                    variant="text"
                    type="button"
                    onClick={() => {
                      formik.resetForm();
                      handleClear();
                    }}
                    style={{ marginRight: 8 }}
                  >
                    {getLang(lang, "label.CLEAR")}
                  </Button>
                  <Button color="secondary" variant="contained" type="submit">
                    {getLang(lang, "label.CONFIRM")}
                  </Button>
                </Box>
              </Box>
            </form>
          )}
        </Formik>
      </Dialog>
    </>
  );
}
