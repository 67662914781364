import React from "react";
import {
  makeStyles,
  Box,
  ButtonBase,
  Typography,
  Paper,
  Grid
} from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import clsx from "clsx";
import { Carousel } from "react-responsive-carousel";
import { TimeImg, BatteryImg } from "../../../utils/static";
import "./customCarousel.css";
import DefaultImg from "assets/img/img-default.png";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles(theme => ({
  header: {
    overflow: "hidden",
    position: "relative",
    height: "250px",
    "& img": {
      width: "100%"
    }
  },
  content: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.background.paper
  },
  floatButton: {
    width: 50,
    height: 50,
    borderRadius: "50%",
    marginTop: theme.spacing(2),
    alignSelf: "flex-end"
  },
  floatIcon: {
    color: theme.palette.common.white
  },
  logo: {
    borderRadius: "50%",
    backgroundColor: theme.palette.common.white,
    display: "flex",
    overflow: "hidden",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    "& img": {
      height: "100%",
      minWidth: "100%",
      minHeight: "100%",
      objectFit: "cover",
      flexShrink: 0
    }
  },
  logoAppBar: {
    height: 50,
    width: 50
  },
  logoHeader: {
    height: 80,
    width: 80,
    top: "-120px",
    left: "50%",
    transform: "translateX(-50%)"
  },
  backButton: {
    width: 30,
    height: 30,
    backgroundColor: theme.palette.background.paper,
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: 20,
    left: 15
  },
  contentLanding: {
    height: 20,
    backgroundColor: theme.palette.background.paper,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  appBar: {
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(2, 2, 1, 2),
    alignItems: "center"
  },
  withImageBg: {
    display: "grid",
    gridTemplateRows: "180px 20px 30px 1fr",
    gridTemplateColumns: "1fr"
  },
  dividerLogo: {
    backgroundColor: theme.palette.background.paper,
    gridRow: "2/4",
    gridColumn: "1/2",
    borderTopRightRadius: "4px",
    borderTopLeftRadius: "4px",
    position: "relative",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  contentUI: {
    justifyContent: "center",
    backgroundColor: "#9CA6A9"
  },
  mobileUI: {
    backgroundColor: "#ffffff",
    boxShadow: "0px 25px 9px rgba(58, 77, 84, 0.15)",
    borderRadius: "20px 20px 0px 0px"
  },
  indicatorStyles: {
    background: "#D4D7DB",
    width: 30,
    height: 8,
    display: "inline-block",
    borderRadius: "6px",
    margin: "0 8px"
  },
  text: {
    textAlign: "left",
    paddingLeft: "24px",
    paddingTop: "10px",
    color: "white"
  },
  hidden: {
    display: "none"
  }
}));

export default function PreviewConsumerPagePanelComponent({
  colorPrimary,
  colorSecondary,
  bgType,
  bgImage,
  bgColor,
  logo,
  mdWidth,
  lang,
  bannerImage,
}) {
  const classes = useStyle();

  const contentPhoneHeader = (
    <Box pt={3} pl={3} pr={3}>
      <Grid container className={classes.mobileUI}>
        <Grid
          item
          xs={6}
          sm={6}
          md={6}
          style={{ width: "100%", textAlignLast: "start", paddingLeft: "8px" }}
        >
          <TimeImg />
        </Grid>
        <Grid
          item
          xs={6}
          sm={6}
          md={6}
          style={{ width: "100%", textAlignLast: "end", paddingRight: "8px" }}
        >
          <BatteryImg />
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <Box>
      <div>
        <Carousel
          showArrows={true}
          showThumbs={false}
          renderIndicator={(onClickHandler, isSelected, index, label) => {
            if (isSelected) {
              return (
                <li
                  className={classes.indicatorStyles}
                  style={{ background: "#3A4D54" }}
                />
              );
            }
            return (
              <li
                className={classes.indicatorStyles}
                onClick={onClickHandler}
                onKeyDown={onClickHandler}
                value={index}
                key={index}
                role="button"
                tabIndex={0}
              />
            );
          }}
        >
          {/* header component preview */}
          <Paper elevation={0} p={3} className={classes.contentUI} style={{ overflowY: "hidden" }}>
            {mdWidth ? (
              <Typography variant="body2" className={classes.text}>
                {getLang(lang, "label.LOOK_AND_FEEL")}
              </Typography>
            ) : null}
            {contentPhoneHeader}

            <Box
              className={clsx(classes.container, classes.withImageBg)}
              style={(bgType === "Image") ? {
                backgroundImage: `linear-gradient(to bottom, ${bgColor + "00"} 80%, ${bgColor + "FF"} 95%), url(${bgImage})`,
                marginLeft: "24px",
                marginRight: "24px",
              } : {
                backgroundColor: bgColor,
                marginLeft: "24px",
                marginRight: "24px",
              }}
            >
              <Box className={classes.header}>
                <ButtonBase className={classes.backButton}>
                  <ArrowBackIosRoundedIcon
                    fontSize="small"
                    style={{ color: colorSecondary }}
                  />
                </ButtonBase>
                <Box
                  p={3}
                  pt={0}
                  position="absolute"
                  top="70px"
                  left="0px"
                  width="100%"
                  borderRadius="20px"
                >
                  {(bannerImage === "") ? (
                    <img
                      src={bannerImage}
                      alt="banner background"
                      style={{ display: "none" }}
                    />
                  ) : (
                    <img
                      src={bannerImage}
                      alt="banner background"
                      style={{ borderRadius: "20px" }}
                    />
                  )}
                </Box>
              </Box>

              <Box className={classes.dividerLogo} mt={4}>
                <Box
                  className={clsx({
                    [classes.logo]: true,
                    [classes.logoHeader]: true,
                    [classes.hidden]: bannerImage !== "",
                  })}
                >
                  <img src={logo || DefaultImg} alt="company logo" />
                </Box>
              </Box>

              {/*<Box className={classes.contentLanding}></Box>*/}
            </Box>
          </Paper>

          {/* appbar component preview */}
          <Paper elevation={0} p={3} className={classes.contentUI}>
            {contentPhoneHeader}
            <Box className={classes.container}>
              <Box
                className={classes.appBar}
                style={{
                  backgroundColor: colorSecondary,
                  marginLeft: "24px",
                  marginRight: "24px"
                }}
              >
                <PersonOutlineOutlinedIcon
                  fontSize="large"
                  className={classes.floatIcon}
                />
                <Box className={clsx(classes.logo, classes.logoAppBar)}>
                  <img src={logo || DefaultImg} alt="company logo" />
                </Box>
              </Box>
              <Box ml={3} mr={3}>
                <Box className={classes.content}>
                  <Typography
                    variant="h5"
                    gutterBottom
                    style={{ color: colorPrimary }}
                  >
                    {getLang(lang, "label.PRIMARY_TEXT")}
                  </Typography>
                  <Typography
                    variant="caption"
                    gutterBottom
                    color="textSecondary"
                  >
                    {getLang(lang, "paragraph.LOREN_IPSUM")}
                  </Typography>
                  <ButtonBase
                    className={classes.floatButton}
                    style={{ backgroundColor: colorPrimary }}
                  >
                    <MoreHorizIcon
                      fontSize="large"
                      className={classes.floatIcon}
                    />
                  </ButtonBase>
                </Box>
              </Box>
            </Box>
          </Paper>
        </Carousel>
      </div>
    </Box>
  );
}
