import axios from 'axios';

export function updateAttributeStatus({
  id,
  status
}) {
  const url =  `/serialnumber/api/v1/product/attribute/update-status/${id}`;

  return new Promise((resolve, reject) => {
    axios.post(url, {
      status: status
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data))
  })
}