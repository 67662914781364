import React from "react";
import {
  Backdrop,
  Dialog,
  makeStyles,
  Typography,
  Paper,
  Box,
  IconButton,
  Divider,
  Chip,
  Button,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { Formik } from "formik"
import Input from 'components/input/inputTextField';
import { getLang } from "app/feature/constants";
import SearchableSelect from "components/select/searchableSelect";

const useStyle = makeStyles((theme) => ({
  dialog: {
    maxWidth: "none"
  },
  root: {
    boxShadow: theme.shadows[5],
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "space-between",
    width: 700,
    borderRadius: "4px",
    fontSize: 14
  },
  header: {
    paddingLeft: theme.spacing(3),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  content: {
    padding: "16px",
    maxHeight: "calc(100vh - 260px)", // Adjust the value as needed
    overflowY: "auto",
  },
  buttonContainer: {
    padding: "10px 16px",
    display: "flex",
    justifyContent: "flex-end"
  },
  modelFieldContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "10px 16px",
    maxHeight: "calc(100vh - 260px)", // Adjust the value as needed
    overflowY: "auto",
  },
  selectRoot: {
    padding: "8px 14px"
  },
  inputRoot: {
    paddingTop: "8px !important",
    paddingBottom: "7px !important",
    paddingLeft: "12px !important",
    paddingRight: "12px !important"
  },
}))

export default function CreateBrandModelDialogComponent({
  isOpen,
  handleClose,
  handleSubmit,
  lang,
  brandDropdownWithNoBrand,
  handleSearch
}) {
  const classes = useStyle();

  return (
    <Dialog
      maxWidth="sm"
      open={isOpen}
      onClose={handleClose}
      disableBackdropClick
      closeAfterTransition
      BackdropComponent={Backdrop}
      classes={{
        paperWidthSm: classes.dialog
      }}
    >
      <Paper elevation={0} className={classes.root}>
        <Box className={classes.header}>
          <Typography
            variant='subtitle2'
            color="primary"
            style={{ fontWeight: "bold" }}
          >
            {getLang(lang, "label.ADD_BRAND_MODEL")}
          </Typography>
          <IconButton color='primary' onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
        <Divider />
        <Formik
          initialValues={{
            brand: "",
            name: "",
            models: [],
            brandOption: 'add_new',
            brand_uuid: ''
          }}
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <Box>
                <Box>
                  <Box className={classes.content}>
                    <Box style={{ marginBottom: "z0px" }}>
                      <FormControl component="fieldset">
                        <RadioGroup
                          row
                          value={formik.values.brandOption}
                          {...formik.getFieldProps('brandOption')}
                        >
                          <FormControlLabel
                            value="add_new"
                            control={<Radio />}
                            label="Add New Brand"
                          />
                          <FormControlLabel
                            value="add_to_existing"
                            control={<Radio />}
                            label="Add Model to Existing Brand"
                          />
                        </RadioGroup>
                      </FormControl>
                      <Typography
                        variant="body2"
                        color="primary"
                        style={{ marginBottom: "10px" }}
                      >
                        {formik.values.brandOption === "add_to_existing"
                          ? "Brand"
                          : "Brand (Optional)"}

                      </Typography>

                    </Box>
                    {formik.values.brandOption === "add_to_existing" ? (
                      <SearchableSelect
                        value={formik.values.brand_uuid}
                        handleChange={(value) => {
                          formik.setFieldValue("brand_uuid", value)
                        }}
                        handleItemSearch={handleSearch}
                        placeholder={getLang(lang, "placeholder.SELECT_BRAND")}
                        disabled={formik.isSubmitting}
                        searchable={true}
                        style={{ height: 34 }}
                        panelBoxStyle={{ width: "24em" }}
                        PaperProps={{
                          classes: { root: classes.paperRoot }
                        }}
                        emptyMessage={getLang(lang, "message.info.SEARCH_NO_BRAND_FOUND")}

                        dropdownItem={brandDropdownWithNoBrand.map((brand) => {
                          return {
                            name: brand.name,
                            value: brand.uuid
                          }
                        })}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left"
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left"
                        }}
                        isEmpty={!formik.values.brand_uuid}
                        returnType="object"
                      />

                    ) : (
                      <Box>
                        <Input
                          hiddenLabel
                          varaint="filled"
                          placeholder={getLang(lang, "placeholder.PLEASE_FILL_BRAND")}
                          margin="none"
                          InputProps={{
                            disableUnderline: true,
                            margin: "none",
                            style: {
                              borderRadius: "4px",
                              backgroundColor: "#eceff0",
                              height: "36.5px"
                            },
                            classes: { input: classes.inputRoot }
                          }}
                          style={{
                            width: "100%"
                          }}
                          onChange={(event) => {
                            formik.setFieldValue("brand", event.target.value)
                          }}
                          {...formik.getFieldProps("brand")}
                        />
                      </Box>
                    )}
                  </Box>
                  <Box className={classes.modelFieldContainer}>
                    <Box>
                      <Typography
                        variant="body2"
                        color="primary"
                        style={{ marginBottom: "10px" }}
                      >
                        {formik.values.brandOption === "add_to_existing"
                          ? "Model"
                          : "Model (Optional)"}
                      </Typography>
                      <Box style={{ display: "flex", justifyContent: "space-between" }}>
                        <Input
                          hiddenLabel
                          varaint="filled"
                          placeholder={getLang(lang, "placeholder.PLEASE_FILL_MODEL")}
                          margin="none"
                          InputProps={{
                            disableUnderline: true,
                            margin: "none",
                            style: {
                              borderRadius: "4px",
                              backgroundColor: "#eceff0",
                              height: "36.5px"
                            },
                            classes: { input: classes.inputRoot }
                          }}
                          style={{
                            width: "80%"
                          }}
                          onKeyPress={(e) => {
                            if (e.key === "Enter" && formik.values.name !== "") {
                              let errorMsg = "";
                              let isError = false;
                              let array = formik.values.name.trim().split(',');
                              let arrayToInsert = [];
                              if (array.length !== 0) {
                                array.forEach((input) => {
                                  let val = input.trim();
                                  if (val !== "") {
                                    if (formik.values.models.filter((model) => model === val).length === 0 && arrayToInsert.filter((value) => value === val).length === 0) {
                                      arrayToInsert = [...arrayToInsert, val]
                                    }
                                    else {
                                      isError = true;
                                      errorMsg = getLang(lang, "message.error.DUPLICATE_MODEL_NAME");
                                    }
                                  }
                                })
                              }
                              else {
                                errorMsg = getLang(lang, "message.error.INVALID_ENTRY");
                                isError = true;
                              }
                              if (arrayToInsert.length !== 0 && !isError) {
                                formik.setFieldValue("models", [...formik.values.models, ...arrayToInsert]);
                                formik.setFieldValue("name", "");
                              }
                              formik.setFieldError("name", errorMsg);
                            }
                          }}
                          error={formik.errors.name ? true : false}
                          helperText={formik.errors.name}
                          {...formik.getFieldProps("name")}
                        />
                        <Box>
                          <Button
                            variant='contained'
                            color="primary"
                            disableElevation
                            onClick={() => {
                              if (formik.values.name !== "") {
                                let errorMsg = "";
                                let isError = false;
                                let array = formik.values.name.trim().split(',');
                                let arrayToInsert = [];
                                if (array.length !== 0) {
                                  array.forEach((input) => {
                                    let val = input.trim();
                                    if (val !== "") {
                                      if (formik.values.models.filter((model) => model === val).length === 0 && arrayToInsert.filter((value) => value === val).length === 0) {
                                        arrayToInsert = [...arrayToInsert, val]
                                      }
                                      else {
                                        isError = true;
                                        errorMsg = getLang(lang, "message.error.DUPLICATE_MODEL_NAME");
                                      }
                                    }
                                  })
                                }
                                else {
                                  errorMsg = getLang(lang, "message.error.INVALID_ENTRY");
                                  isError = true;
                                }
                                if (arrayToInsert.length !== 0 && !isError) {
                                  formik.setFieldValue("models", [...formik.values.models, ...arrayToInsert]);
                                  formik.setFieldValue("name", "");
                                }
                                formik.setFieldError("name", errorMsg);
                              }
                            }}
                          >
                            {getLang(lang, "label.INSERT")}
                          </Button>
                        </Box>
                      </Box>
                      <Typography
                        variant="caption"
                        style={{ color: "rgba(58, 77, 84, 0.7)" }}
                      >
                        {getLang(lang, "paragraph.ENTER_TO_ADD_MODEL")}
                      </Typography>
                    </Box>
                    <Box style={{ display: "flex", marginTop: "10px", flexWrap: "wrap" }}>
                      {
                        formik.values.models.length > 0 ? formik.values.models.map((model) => (
                          <Box key={model} style={{ marginRight: "8px", marginBottom: "8px" }}>
                            <Chip
                              label={model}
                              onDelete={() => {
                                let temp = formik.values.models.filter((mod) => mod !== model)
                                formik.setFieldValue("models", temp)
                              }}
                            />
                          </Box>
                        )) : null
                      }
                    </Box>
                  </Box>
                </Box>
                <Divider />
                <Box className={classes.buttonContainer}>
                  <Button
                    variant="contained"
                    disableElevation
                    color='secondary'
                    disabled={formik.values.brandOption === "add_to_existing"
                      ? (formik.values.models.length === 0 || formik.values.brand_uuid === "") 
                      : (formik.values.models.length === 0 && formik.values.brand === "")
                    }
                    onClick={formik.handleSubmit}

                  >
                    {getLang(lang, "label.ADD")}
                  </Button>
                </Box>
              </Box>
            </form>
          )}
        </Formik>
      </Paper>
    </Dialog >
  )
}
