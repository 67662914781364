import React, { useState, useEffect, useRef } from "react";
import GenerateStatusProgressComponent from "./generateStatusProgress.component";
import { useDispatch, useSelector } from "react-redux";
import { getGenerateProgress } from "modules/serial-number/redux";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  SERIAL_NUMBER_BATCH_STATUS_FAILED,
  SERIAL_NUMBER_BATCH_STATUS_GENERATED,
} from "modules/serial-number/constants";

function GenerateStatusProgressContainer({
  generateUuid,
  initialProgress = 0,
  initialStatus,
}) {
  const lang = useSelector((state) => state.constant.languages);
  const [status, setStatus] = useState(initialStatus);
  const [progress, setProgress] = useState(initialProgress);
  const dispatch = useDispatch();

  const intervalRef = useRef(null);

  useEffect(() => {
    if (
      initialStatus !== SERIAL_NUMBER_BATCH_STATUS_GENERATED &&
      initialStatus !== SERIAL_NUMBER_BATCH_STATUS_FAILED
    ) {
      intervalRef.current = setInterval(() => {
        getData();
      }, 5000);
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, generateUuid]);

  const getData = () => {
    dispatch(getGenerateProgress(generateUuid))
      .then(unwrapResult)
      .then((data) => {
        setStatus(data.status);
        setProgress(data.progress);

        if (
          data.status === SERIAL_NUMBER_BATCH_STATUS_GENERATED ||
          data.status === SERIAL_NUMBER_BATCH_STATUS_FAILED
        ) {
          clearInterval(intervalRef.current);
        }
      })
      .catch(() => {
        setStatus("failed");
        setProgress(0);
        clearInterval(intervalRef.current);
      });
  };

  return (
    <GenerateStatusProgressComponent
      progress={progress}
      status={status}
      lang={lang}
    />
  );
}

export default GenerateStatusProgressContainer;
