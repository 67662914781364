import axios from "axios";

const get = async ({ refCode }) => {
  const response = await axios.get(
    `/consumer/admin/api/v1/counterfeit-report/${refCode}`
  );

  return response.data;
};

const put = async ({ refCode, status, adminRemarks }) => {
  const response = await axios.put(
    `/consumer/admin/api/v1/counterfeit-report/${refCode}`,
    {
      status: status,
      admin_remarks: adminRemarks,
    }
  );

  return response;
};

const counterfeitReportDetail = {
  get,
  put
};

export default counterfeitReportDetail;
