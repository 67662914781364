import countries from '../../../../src/lib/constants/countries';
import { 
    REPORTING_DRILLDOWN_PRODUCT_CATEGORY_TO_CATEGORY,
    REPORTING_DRILLDOWN_PRODUCT_CATEGORY_TO_CCB,
    REPORTING_DRILLDOWN_QR_TO_COUNTRY_CITY_BRANCH, 
    REPORTING_DRILLDOWN_TO_COUNTRY_CITY_BRANCH_PRODUCT ,
    REPORTING_DRILLDOWN_PRODUCT_BRAND_TO_BRAND,
    REPORTING_DRILLDOWN_PRODUCT_BRAND_TO_CCB,
    REPORTING_DRILLDOWN_PRODUCT_MODEL_TO_MODEL,
    REPORTING_DRILLDOWN_PRODUCT_MODEL_TO_CCB,
    REPORTING_DRILLDOWN_PRODUCT_LIST_TO_CCB,
    REPORTING_DRILLDOWN_PRODUCT_SUSPENDED_TO_CCB,
    REPORTING_DRILLDOWN_CONSUMER_TO_CCB,
    REPORTING_DRILLDOWN_CONSUMER_TO_CONSUMER_PAGE,
    REPORTING__DRILLDOWN_TO_SERIAL_NUMBER_PAGE,
    REPORTING_DRILLDOWN_CAMPAIGN_SUMMARY_TO_CAMPAIGN_TYPE,
    REPORTING_DRILLDOWN_CAMPAIGN_SUMMARY_TO_CCB,
    REPORTING_DRILLDOWN_CAMPAIGN_TO_SPECIFIC,
    REPORTING_DRILLDOWN_CAMPAIGN_SPECIFIC_TO_CAMPAIGN_PRIZE,
    REPORTING_DRILLDOWN_DEALERSHIP_BY_TYPE,
    REPORTING__DRILLDOWN_TO_DEALERSHIP_DETAIL
} from '../config/reporting.route';

export function getCountryName(countryCode){
    let country = countries.find(key => key.code === countryCode);

    let countryName = country ? country.label : countryCode;
    return countryName;
}

export function numberFormatteer (number) {
    number = parseInt(number);
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function responseHandler(
    data, 
    fetchingStatus, 
    displayComponent, 
    loadingComponent, 
    emptyComponent, 
    noResultComponent, 
    errorComponent,
    debug = false,
){
    let isLoaded = (!fetchingStatus && Object.keys(data).length !== 0);

    if(debug){
        console.log(isLoaded, data, "data")
    }
    
    if(!isLoaded){
        if(debug)
            console.log("loading")
        return loadingComponent;
    }

    if(debug){
        console.log("finish loading")
    }


    if(!data.isSuccess){
        if(debug){
            console.log("error")
        }
        return errorComponent;
    }

    if(data.isEmpty){
        if(debug){
            console.log("empty")
        }
        return emptyComponent;
    }


    if(data.noResult){
        if(debug)
            console.log("noResult")
        return noResultComponent;
    }
        

    if(debug){
        console.log("display")
    }
    return displayComponent;    
}

export function snakeCase(string) {
    return string.replace(/\W+/g, " ")
      .split(/ |\B(?=[A-Z])/)
      .map(word => word.toLowerCase())
      .join('_');
};

export function sortByNameAscending(arr) {
    arr.sort(function(a, b) {
        var nameA = a.name.toUpperCase();
        var nameB = b.name.toUpperCase();
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    });
    return arr;
}

export function drilldownPageSelection(filterProperties, pageType = null, debug = false){
    let url = '#';

    // if(debug){
    //     console.log(filterProperties, pageType, "filterProperties")
    // }

    if(pageType === 'qr'){
        url = getSearchPath(REPORTING_DRILLDOWN_QR_TO_COUNTRY_CITY_BRANCH, filterProperties);
        if(filterProperties.hasOwnProperty('productUuid')){
            url = getSearchPath(REPORTING_DRILLDOWN_TO_COUNTRY_CITY_BRANCH_PRODUCT, filterProperties);
        }

        if(filterProperties.hasOwnProperty('consumerUuid')){
            url = REPORTING_DRILLDOWN_CONSUMER_TO_CONSUMER_PAGE + '/' + filterProperties.consumerUuid;
        }

        if(filterProperties.hasOwnProperty('enc')){
            url = REPORTING__DRILLDOWN_TO_SERIAL_NUMBER_PAGE + '/details/' + filterProperties.enc;
        }

        if(filterProperties.hasOwnProperty('campaignType')){
            url = getSearchPath(REPORTING_DRILLDOWN_CAMPAIGN_SUMMARY_TO_CAMPAIGN_TYPE, filterProperties);
        }
    }

    if(pageType === 'product-category'){
        url = getSearchPath(REPORTING_DRILLDOWN_PRODUCT_CATEGORY_TO_CCB, filterProperties);
        if(filterProperties.hasOwnProperty('categoryUuid')){
            url = getSearchPath(REPORTING_DRILLDOWN_PRODUCT_CATEGORY_TO_CATEGORY, filterProperties);
        }

        if(filterProperties.hasOwnProperty('productUuid')){
            url = getSearchPath(REPORTING_DRILLDOWN_TO_COUNTRY_CITY_BRANCH_PRODUCT, filterProperties);
        }
        
        if(filterProperties.hasOwnProperty('campaignType')){
            url = getSearchPath(REPORTING_DRILLDOWN_CAMPAIGN_SUMMARY_TO_CAMPAIGN_TYPE, filterProperties);
        }
    }

    if(pageType === 'product-brand'){
        url = getSearchPath(REPORTING_DRILLDOWN_PRODUCT_BRAND_TO_CCB, filterProperties);
        if(filterProperties.hasOwnProperty('brandUuid')){
            url = getSearchPath(REPORTING_DRILLDOWN_PRODUCT_BRAND_TO_BRAND, filterProperties);
        }

        if(filterProperties.hasOwnProperty('productUuid')){
            url = getSearchPath(REPORTING_DRILLDOWN_TO_COUNTRY_CITY_BRANCH_PRODUCT, filterProperties);
        }

        if(filterProperties.hasOwnProperty('campaignType')){
            url = getSearchPath(REPORTING_DRILLDOWN_CAMPAIGN_SUMMARY_TO_CAMPAIGN_TYPE, filterProperties);
        }
    }

    if(pageType === 'product-model'){
        url = getSearchPath(REPORTING_DRILLDOWN_PRODUCT_MODEL_TO_CCB, filterProperties);
        if(filterProperties.hasOwnProperty('modelUuid')){
            url = getSearchPath(REPORTING_DRILLDOWN_PRODUCT_MODEL_TO_MODEL, filterProperties);
        }

        if(filterProperties.hasOwnProperty('brandUuid')){
            url = getSearchPath(REPORTING_DRILLDOWN_PRODUCT_BRAND_TO_BRAND, filterProperties);
        }

        if(filterProperties.hasOwnProperty('productUuid')){
            url = getSearchPath(REPORTING_DRILLDOWN_TO_COUNTRY_CITY_BRANCH_PRODUCT, filterProperties);
        }

        if(filterProperties.hasOwnProperty('campaignType')){
            url = getSearchPath(REPORTING_DRILLDOWN_CAMPAIGN_SUMMARY_TO_CAMPAIGN_TYPE, filterProperties);
        }
    }

    if(pageType === 'product-list'){
        url = getSearchPath(REPORTING_DRILLDOWN_PRODUCT_LIST_TO_CCB, filterProperties);
        if(filterProperties.hasOwnProperty('productUuid')){
            url = getSearchPath(REPORTING_DRILLDOWN_TO_COUNTRY_CITY_BRANCH_PRODUCT, filterProperties);
        }

        if(filterProperties.hasOwnProperty('campaignType')){
            url = getSearchPath(REPORTING_DRILLDOWN_CAMPAIGN_SUMMARY_TO_CAMPAIGN_TYPE, filterProperties);
        }
        
    }

    if(pageType === 'product-suspended'){
        url = getSearchPath(REPORTING_DRILLDOWN_PRODUCT_SUSPENDED_TO_CCB, filterProperties);
        if(filterProperties.hasOwnProperty('productUuid')){
            url = getSearchPath(REPORTING_DRILLDOWN_TO_COUNTRY_CITY_BRANCH_PRODUCT, filterProperties);
        }

        if(filterProperties.hasOwnProperty('enc')){
            url = REPORTING__DRILLDOWN_TO_SERIAL_NUMBER_PAGE + '/details/' + filterProperties.enc;
            // getSearchPath(REPORTING_DRILLDOWN_TO_COUNTRY_CITY_BRANCH_PRODUCT, filterProperties);
        }
    }

    if(pageType === 'consumer'){
        url = getSearchPath(REPORTING_DRILLDOWN_CONSUMER_TO_CCB, filterProperties);
    
        if(filterProperties.hasOwnProperty('consumerUuid')){
            url = REPORTING_DRILLDOWN_CONSUMER_TO_CONSUMER_PAGE + '/' + filterProperties.consumerUuid;
            // getSearchPath(REPORTING_DRILLDOWN_TO_COUNTRY_CITY_BRANCH_PRODUCT, filterProperties);
        }

        if(filterProperties.hasOwnProperty('enc')){
            url = REPORTING__DRILLDOWN_TO_SERIAL_NUMBER_PAGE + '/details/' + filterProperties.enc;
            // getSearchPath(REPORTING_DRILLDOWN_TO_COUNTRY_CITY_BRANCH_PRODUCT, filterProperties);
        }

        if(filterProperties.hasOwnProperty('campaignType')){
            url = getSearchPath(REPORTING_DRILLDOWN_CAMPAIGN_SUMMARY_TO_CAMPAIGN_TYPE, filterProperties);
        }
    }

    if(pageType === 'campaign-summary'){
        url = getSearchPath(REPORTING_DRILLDOWN_CAMPAIGN_SUMMARY_TO_CCB, filterProperties);
        if(filterProperties.hasOwnProperty('campaignType')){
            url = getSearchPath(REPORTING_DRILLDOWN_CAMPAIGN_SUMMARY_TO_CAMPAIGN_TYPE, filterProperties);
        }
    }

    if(pageType === 'campaign-type'){
        url = getSearchPath(REPORTING_DRILLDOWN_CAMPAIGN_SUMMARY_TO_CAMPAIGN_TYPE, filterProperties);
        if(filterProperties.hasOwnProperty('campaignUuid') && filterProperties.hasOwnProperty('campaignType')){
            url = getSearchPath(REPORTING_DRILLDOWN_CAMPAIGN_TO_SPECIFIC, filterProperties);
        }

        if(filterProperties.hasOwnProperty('consumerUuid'))
            url = REPORTING_DRILLDOWN_CONSUMER_TO_CONSUMER_PAGE + '/' + filterProperties.consumerUuid;
    }

    if(pageType === "campaign-list"){
        url = getSearchPath(REPORTING_DRILLDOWN_CAMPAIGN_TO_SPECIFIC, filterProperties);
    }

    if(pageType === "campaign-specific"){
        url = getSearchPath(REPORTING_DRILLDOWN_CAMPAIGN_TO_SPECIFIC, filterProperties);

        if(filterProperties.hasOwnProperty('consumerUuid'))
            url = REPORTING_DRILLDOWN_CONSUMER_TO_CONSUMER_PAGE + '/' + filterProperties.consumerUuid;

        if(filterProperties.hasOwnProperty('productUuid'))
            url = getSearchPath(REPORTING_DRILLDOWN_TO_COUNTRY_CITY_BRANCH_PRODUCT, filterProperties);

        if(filterProperties.hasOwnProperty('prizeUuid'))
            url = getSearchPath(REPORTING_DRILLDOWN_CAMPAIGN_SPECIFIC_TO_CAMPAIGN_PRIZE, filterProperties);
    }

    if(pageType === "dealership"){
        // if(filterProperties.hasOwnProperty('branchUuid'))
        url = getSearchPath(REPORTING_DRILLDOWN_DEALERSHIP_BY_TYPE, filterProperties);

        if(filterProperties.hasOwnProperty('dealershipUuid'))
            url = REPORTING__DRILLDOWN_TO_DEALERSHIP_DETAIL + '/' + filterProperties.dealershipUuid;
    }
    return url;
}

export function getSearchPath(path, filterProperties){
    
    let searchParams = new URLSearchParams();

    Object.keys(filterProperties).forEach(key => {
        searchParams.append(key, filterProperties[key]);
    });

    return path + '?' + searchParams.toString();
}

export function datetimeValidation(date_start, date_end){
    let valid = true;
    let dateStart = new Date(date_start);
    let dateEnd = new Date(date_end);

    if(dateStart > dateEnd)
        valid = false;
    
    if(isNaN(dateStart.getTime()) || isNaN(dateEnd.getTime()))
        valid = false;
    
    if(dateStart > new Date(Date.now()) || dateEnd > new Date(Date.now()))
        valid = false;

    if(!valid){
        dateStart = new Date(Date.now() - 13 * 24 * 60 * 60 * 1000);
        dateEnd = new Date(Date.now());
    }

    return [dateStart, dateEnd];
}

// export function datetimeValidation(date){
//     // const regex = /^\d{4}-\d{2}-\d{2}$/;
//     // console.log(date);
//     // if(!regex.test(date)){
        
//     //     return false;
//     // } else {
//     const dateCheck = new Date(date);
//     if(isNaN(dateCheck.getTime())){
//         console.log('false', dateCheck)
//         return false;
//     }
//     console.log('true', dateCheck)
//     // }
//     return true;
// }

export function periodValidation(period){
    if(period === 'today' || period === 'week' || period === 'month'){
        return true;
    } else {
        return false;
    }
}
  
