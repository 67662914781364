import { createAsyncThunk } from '@reduxjs/toolkit';
import stockflowApi from 'app/api/stockflow';

export const STOCKFLOWINCENTIVEPROGRAM = "stockflowIncentiveProgram"

export const getIncentiveProgramListing = createAsyncThunk(
  `${STOCKFLOWINCENTIVEPROGRAM}/getIncentiveProgramListing`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi.getIncentiveProgramList({
      length: payload.length,
      start: payload.start,
      search: payload.search,
      status: payload.status,
      product: payload.product,
    })
      .then(response => response.data)
      .catch(error => rejectWithValue(error))
  }
)

export const getNewProgramProductDropdown = createAsyncThunk(
  `${STOCKFLOWINCENTIVEPROGRAM}/getNewProgramProductDropdown`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi.getNewProgramProductDropdown()
      .then(response => response.data)
      .catch(error => rejectWithValue(error))
  }
)

export const getEditProgramProductDropdown = createAsyncThunk(
  `${STOCKFLOWINCENTIVEPROGRAM}/getEditProgramProductDropdown`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi.getEditProgramProductDropdown(payload)
      .then(response => response.data)
      .catch(error => rejectWithValue(error))
  }
)

export const createIncentiveProgram = createAsyncThunk(
  `${STOCKFLOWINCENTIVEPROGRAM}/createIncentiveProgram`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi.createIncentiveProgram({
      name: payload.name,
      percentages: payload.percentages,
      is_select_all: payload.isSelectAll,
      products: payload.products
    })
      .then(response => response.data)
      .catch(error => rejectWithValue(error))
  }
)

export const getStockModuleSetting = createAsyncThunk(
  `${STOCKFLOWINCENTIVEPROGRAM}/getStockModuleSetting`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi.getStockModuleSetting()
      .then(response => response.data)
      .catch(error => rejectWithValue(error))
  }
)

export const getProgramDetails = createAsyncThunk(
  `${STOCKFLOWINCENTIVEPROGRAM}/getProgramDetails`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi.GetProgramDetails({
      id: payload
    })
      .then(response => response.data)
      .catch(error => rejectWithValue(error))
  }
);

export const updateProgramDetails = createAsyncThunk(
  `${STOCKFLOWINCENTIVEPROGRAM}/updateProgramDetails`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi.UpdateProgramDetails({
      id: payload.id,
      name: payload.name,
      percentages: payload.percentages,
      is_select_all: payload.isSelectAll,
      products: payload.products
    })
      .then(response => response.data)
      .catch(error => rejectWithValue(error))
  }
);

export const updateProgramStatus = createAsyncThunk(
  `${STOCKFLOWINCENTIVEPROGRAM}/updateProgramStatus`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi.UpdateIncentiveProgramStatus({
      id: payload.id,
      status: payload.status
    })
      .then(response => response.data)
      .catch(error => rejectWithValue(error))
  }
)

export const deleteIncentiveProgram = createAsyncThunk(
  `${STOCKFLOWINCENTIVEPROGRAM}/deleteProgramDetails`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi.deleteIncentiveProgram({
      id: payload.id
    })
      .then(response => response.data)
      .catch(error => rejectWithValue(error))
  }
)

export const bulkUpdateProgramStatus = createAsyncThunk(
  `${STOCKFLOWINCENTIVEPROGRAM}/bulkUpdateProgramStatus`,
  async (payload, {rejectWithValue}) => {
    return stockflowApi.BulkUpdateIncentiveProgramStatus(payload)
      .then(response => response.data)
      .catch(error => rejectWithValue(error))
  }
)
