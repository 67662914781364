import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Tab from "@material-ui/core/Tab";
import { lighten, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    marginRight: theme.spacing(2),
    minWidth: 0,
    minHeight: 0,
    padding: theme.spacing(0.5, 1),
    borderRadius: theme.spacing(1)
  },
  wrapper: {
    flexDirection: "row"
  },
  light: {
    color: lighten(theme.palette.primary.main, 0.2),
    backgroundColor: theme.palette.grey[300]
  },
  dark: {
    color: theme.palette.common.white,
    backgroundColor: lighten(theme.palette.primary.main, 0.2)
  },
  selectedDark: {
    backgroundColor: theme.palette.primary.main
  },
  selected: {
    backgroundColor: lighten(theme.palette.primary.light, 0.7)
  }
}));

function CustomTab({ classes, className, variant, ...other }) {
  const dark = variant === "dark";

  const cls = useStyles();
  return (
    <Tab
      classes={{
        selected: dark ? cls.selectedDark : cls.selected,
        wrapper: other.icon ? cls.wrapper : null,
        ...classes
      }}
      className={clsx(cls.root, className, {
        [cls.dark]: dark,
        [cls.light]: !dark
      })}
      {...other}
    />
  );
}

CustomTab.defaultProps = {
  variant: "light"
};

CustomTab.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  variant: PropTypes.oneOf(["light", "dark"])
};

export default CustomTab;
