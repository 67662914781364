import React from "react";
import {
  Box,
  makeStyles,
  Button,
  Tabs,
  Divider,
  Typography,
  Grid
} from "@material-ui/core";
import PropTypes from "prop-types";
import CustomTab from "components/tab";
import ParticipantTable from "../../components/table/participant-table";
import { DownloadSVG } from "../../utils/static";
import { GeneralSearch } from "components/search";
import { BackButton } from "components/button";
import { getLang } from "app/feature/constants";

const style = (theme) => ({
  indicator: {
    border: "0.5px solid",
    borderColor: theme.palette.primary.main
  },
  scroll: {
    width: "min-content"
  },
  content: {
    width: "100%"
  },
  download: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.down("xs")]: {
      paddingRight: theme.spacing(2)
    }
  }
});

const useStyles = makeStyles(style);

export default function ParticipantPageComponent({
  handleChange,
  isFetching,
  instantParticipantData,
  page,
  search,
  rowsPerPage,
  language,
  totalRecords,
  handleChangePage,
  handleChangeRowsPerPage,
  handleSearch,
  activeIndex,
  tabLabels,
  handleExport,
  handleReload,
  paginationOptions,
  lang
}) {
  const classes = useStyles();

  function a11yProps(index, label = "label") {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tab-${index}`,
      label: <Typography variant="button" children={label} />
    };
  }

  function TabPanel({ children, value, index, ...other }) {
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </Typography>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  return (
    <Box>
      <Box mb={1} display="flex" alignItems="center">
        <BackButton />
        <Typography
          variant="h6"
          color="primary"
          style={{ fontWeight: "bold", marginLeft: 5 }}
        >
          {getLang(lang, 'label.INSTANT_LUCKY_DRAW_PARTICIPANT')}
        </Typography>
      </Box>

      <Divider />

      <Box display="flex" justifyContent="space-between">
        <Box className={classes.content}>
          <Grid container>
            <Grid item xs={8} sm={7} md={7} style={{ width: "100%" }}>
              <Tabs
                value={activeIndex}
                onChange={handleChange}
                aria-label="scrollable auto tabs example"
                textColor="primary"
                variant="scrollable"
                indicatorColor="primary"
                classes={{
                  indicator: classes.indicator,
                  scrollButtons: classes.scroll,
                }}
              >
                {tabLabels.map((item, index) => {
                  return <CustomTab key={index} {...a11yProps(index, item)} />;
                })}
              </Tabs>
            </Grid>

            <Grid item xs={4} sm={5} md={5} className={classes.download}>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                style={{ margin: "4px", background: "white" }}
                onClick={handleExport}
              >
                {getLang(lang, 'label.DOWNLOAD_REPORT')}
                <DownloadSVG />
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box>
        <TabPanel value={activeIndex} index={0}></TabPanel>
      </Box>

      <Box>
        <GeneralSearch
          handleFilter={(value) => {
            handleSearch(value);
          }}
        />
        <ParticipantTable
          isFetching={isFetching}
          rows={instantParticipantData}
          page={page}
          language={language}
          search={search}
          rowsPerPage={rowsPerPage}
          totalRecords={totalRecords}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleReload={handleReload}
          paginationOptions={paginationOptions}
          lang={lang}
        />
      </Box>
    </Box>
  );
}
