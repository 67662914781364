import { useEffect } from 'react'
import { LOGIN } from 'modules/public/config/public.route';
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import { logout } from 'modules/admin/redux';

export default function Logout() {
    const history = useHistory()
    const dispatch = useDispatch()

    useEffect(() => {
        const sessionId = localStorage.getItem('session_id')
        const sessionExpiration = localStorage.getItem('session_expiration')

        if (sessionId && sessionExpiration) {
            dispatch(logout({
                sessionId,
                sessionExpiration
            }))
                .then(() => localStorage.clear())
                .finally(() => {
                    window.location.href = LOGIN
                })
        } else {
            window.location.href = LOGIN
        }
    }, [history, dispatch])

    return null
}
