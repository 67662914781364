import React, { useEffect } from "react";
import ClusterProductListingPanelComponent from "./clusterProductListingPanel.component";
import { useDispatch, useSelector } from "react-redux";
import { fetchClusterScanProductListing } from "modules/dashboard/redux/action/dashboard.action";
import { selectLoading } from "modules/notification";
import { useState } from "react";
import {
  resetClusterScanProducts,
  updateClsuterProductFilter,
} from "modules/dashboard/redux/slice/dashboard.slice";

function ClusterProductListingPanelContainer({
  clusterBound,
  startDate,
  endDate,
  updateRevealProductStatus,
  revealProduct,
  isSelectAll,
  selectedIds,
  handleSelectProduct,
  handleSelectAll,
}) {
  const dispatch = useDispatch();

  const lang = useSelector((state) => state.constant.languages);
  const products = useSelector(
    (state) => state.dashboard.clusterScanProducts.list
  );
  const hasNextPage = useSelector(
    (state) => state.dashboard.clusterScanProducts.hasNextPage
  );
  const totalFiltered = useSelector(
    (state) => state.dashboard.clusterScanProducts.totalFiltered
  );
  const totalRecords = useSelector(
    (state) => state.dashboard.clusterScanProducts.totalRecords
  );
  const isError = useSelector(
    (state) => state.dashboard.clusterScanProducts.isError
  );
  const isFetching = useSelector((state) =>
    selectLoading(state, fetchClusterScanProductListing.typePrefix)
  );
  const [filter, setFilter] = useState({
    length: 25,
    page: 0,
    search: "",
  });

  const [showProductList, setShowProductList] = useState(true);

  useEffect(() => {
    if (clusterBound) {
      dispatch(resetClusterScanProducts());
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, clusterBound]);

  const getData = () => {
    dispatch(
      fetchClusterScanProductListing({
        length: filter.length,
        start: filter.page * filter.length,
        search: filter.search,
        latitude: [clusterBound.south, clusterBound.north],
        longitude: [clusterBound.west, clusterBound.east],
        start_date: startDate,
        end_date: endDate,
      })
    );
  };

  const handleSearch = (values) => {
    dispatch(resetClusterScanProducts());

    const newFilter = {
      ...filter,
      search: values.search,
    };
    setFilter(newFilter);
    dispatch(updateClsuterProductFilter(newFilter));
  };

  const handleFetchMoreItems = () => {
    if (hasNextPage) {
      const newFilter = {
        ...filter,
        page: filter.page + 1,
      };
      setFilter(newFilter);
      dispatch(updateClsuterProductFilter(newFilter));
    }
  };

  const handleReload = () => {
    dispatch(resetClusterScanProducts());
    const newFilter = {
      ...filter,
      page: 0,
    };
    setFilter(newFilter);
    dispatch(updateClsuterProductFilter(newFilter));
  };

  return (
    <ClusterProductListingPanelComponent
      showProductList={showProductList}
      toggleProductList={(show) => setShowProductList(show)}
      clusterBound={clusterBound}
      startDate={startDate}
      endDate={endDate}
      lang={lang}
      handleReload={handleReload}
      totalRecords={totalRecords}
      products={products}
      isError={isError}
      isFetching={isFetching}
      filter={filter}
      handleSearch={handleSearch}
      updateRevealProductStatus={updateRevealProductStatus}
      revealProduct={revealProduct}
      isSelectAll={isSelectAll}
      handleSelectAll={handleSelectAll}
      handleFetchMoreItems={handleFetchMoreItems}
      handleSelectProduct={handleSelectProduct}
      selectedIds={selectedIds}
      totalFiltered={totalFiltered}
    />
  );
}

export default ClusterProductListingPanelContainer;
