import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import qs from "query-string";
import { validateResetPasswordToken } from "modules/public/redux/action";
import NewPasswordPage from "./newPassword.page";
import { useHistory } from "react-router-dom";
import { INVALID_LINK } from "modules/public/config/public.route";
import { unwrapResult } from "@reduxjs/toolkit";
import LoadingComponent from "components/loading";

export default function NewPasswordContainer() {
    const history = useHistory();
    const params = qs.parse(history.location.search);
    const dispatch = useDispatch();
    const [isValid, setIsValid] = useState(false)

    useEffect(() => {
        dispatch(validateResetPasswordToken({
            email: params.email,
            token: params.reset_password_token
        }))
            .then(unwrapResult)
            .then(() => {
                setIsValid(true)
            })
            .catch(() => {
                history.push(INVALID_LINK)
            })
    }, [dispatch, history, params])

    return !isValid ?
        <LoadingComponent />
        :
        <NewPasswordPage email={params.email} token={params.reset_password_token} />;
}