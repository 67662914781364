/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import AdditionalInfoFormComponent from "./additionalInfoForm.component";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  getAdditionalInfoSequence,
  resetAdditionalInfoSequenceList,
  updateAdditionalInfoSequence,
} from "modules/serial-number/redux";
import { selectLoading } from "modules/notification";
import { unwrapResult } from "@reduxjs/toolkit";

const TYPES = ["new", "edit", "readonly"];

function AdditionalInfoFormContainer({
  type,
  handleResult,
  handleClose,
  handleDelete,
  open,
}) {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.constant.languages);
  const userLang = useSelector((state) => state.profile.language);
  const newlyFetchList = useSelector(
    (state) => state.additionalInfo.additionalInfoSequenceList.newlyFetchList
  );
  const totalFilter = useSelector(
    (state) => state.additionalInfo.additionalInfoSequenceList.totalFiltered
  );
  const hasNextPage = useSelector(
    (state) => state.additionalInfo.additionalInfoSequenceList.hasNextPage
  );
  const isError = useSelector(
    (state) => state.additionalInfo.additionalInfoSequenceList.isError
  );
  const isLoading = useSelector((state) =>
    selectLoading(state, getAdditionalInfoSequence.typePrefix)
  );
  const isUpdating = useSelector((state) =>
    selectLoading(state, updateAdditionalInfoSequence.typePrefix)
  );

  const [infoList, setInfoList] = useState([]);
  const [filter, setFilter] = useState({
    length: 30,
    page: 0,
  });

  const handleSubmit = (values) => {
    if (values.isUpdated) {
      let updatedAdtInfo = [];
      values.additionalInfo.forEach((a, i) => {
        if (a.sequence !== i + 1 || a.is_edited) {
          // only update if sequence/visibility change
          updatedAdtInfo.push({
            id: a.id,
            sequence: i + 1,
            is_visible: a.is_visible,
          });
        }
      });

      dispatch(updateAdditionalInfoSequence(updatedAdtInfo))
        .then(unwrapResult)
        .then(() => {
          handleResult(null);
        });
    } else {
      handleResult(null);
    }
  };

  useEffect(() => {
    getData();
  }, [filter]);

  useEffect(() => {
    setInfoList([...infoList, ...newlyFetchList]);
  }, [newlyFetchList]);

  useEffect(() => {
    if (!open) {
      dispatch(resetAdditionalInfoSequenceList());
      setInfoList([]);
    }
  }, [open]);

  const getData = () => {
    dispatch(
      getAdditionalInfoSequence({
        length: filter.length,
        start: filter.page * filter.length,
      })
    );
  };

  const handleFetchMoreItems = (tempAdtInfoList) => {
    if (hasNextPage) {
      setInfoList(tempAdtInfoList);
      const newFilter = {
        ...filter,
        page: filter.page + 1,
      };
      setFilter(newFilter);
    }
  };

  return (
    <AdditionalInfoFormComponent
      type={type}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
      lang={lang}
      handleDelete={handleDelete}
      isSubmitting={false}
      adtList={infoList}
      isError={isError}
      handleReload={getData}
      isLoading={isLoading}
      isUpdating={isUpdating}
      userLang={userLang}
      handleFetchMoreItems={handleFetchMoreItems}
      totalFilter={totalFilter}
    />
  );
}

AdditionalInfoFormContainer.defaultProps = {
  type: TYPES[0],
};

AdditionalInfoFormContainer.propTypes = {
  type: PropTypes.oneOf(TYPES).isRequired,
  handleResult: PropTypes.func,
  handleDelete: PropTypes.func,
};

export { TYPES };

export default AdditionalInfoFormContainer;
