import React from "react";
import {
  makeStyles,
  Box,
  IconButton,
  Dialog,
  Typography,
  Backdrop,
  Button,
} from "@material-ui/core";
import {
  CloseRounded as CloseRoundedIcon,
  ChevronRightRounded as ChevronRightRoundedIcon,
  KeyboardBackspace as BackIcon,
} from "@material-ui/icons";
import { getLang } from "app/feature/constants";
import "react-image-lightbox/style.css";
import { Skeleton } from "@material-ui/lab";
import DefaultImg from "assets/img/img-default.png";
import ProductSNListTable from "../../table/productSNListTable";

const useStyle = makeStyles((theme) => ({
  header: {
    padding: "12px 16px",
    borderBottom: "1px solid #D0D5DD",
  },
  container: {
    backgroundColor: "#EBEDEE",
    overflowY: "auto",
    padding: "16px 16px 34px 16px",
  },
  productRow: {
    backgroundColor: "#FFFFFF",
    border: "1px solid #D0D5DD",
    "& > div:first-child": {
      borderBottom: "1px solid #D0D5DD",
    },
  },
  productList: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    columnGap: theme.spacing(2),
    rowGap: theme.spacing(2),
  },
  productPhoto: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #0000001a",
    width: "45px",
    minWidth: "45px",
    height: "45px",
    borderRadius: "4px",
    "& img": {
      height: "100%",
      width: "100%",
    },
  },
  attributeText: {
    color: "#b2b2b2",
    fontSize: 12,
    whiteSpace: "break-spaces",
  },
}));

export default function TransferBoxDetailDialogComponent({
  isOpen,
  handleClose,
  lang,
  boxInfo,
  list,
  isFetching,
  isError,
  handleReload,
  viewProduct,
  selectedProduct,
  handleChangePage,
  handleChangeRowsPerPage,
  changeOrder,
  handlePrdReload,
  paginationOptions,
  prdList,
  isPrdFetching,
  isPrdError,
  prdTotalFiltered,
  tableFilter,
}) {
  const classes = useStyle();

  const getLabelText = () => {
    return boxInfo.type === "missing"
      ? getLang(lang, "label.MISSING")
      : boxInfo.type === "pending"
      ? getLang(lang, "label.PENDING")
      : boxInfo.type === "receive"
      ? getLang(lang, "label.RECEIVED")
      : getLang(lang, "label.TRANSFERRED");
  };

  return (
    <Dialog
      maxWidth="md"
      open={isOpen}
      onClose={() => {}}
      aria-labelledby="modal-scan-detail"
      aria-describedby="modal-scan-detail"
      BackdropComponent={Backdrop}
      fullWidth
      PaperProps={{
        style: {
          overflowY: "hidden",
        },
      }}
    >
      <Box
        className={classes.header}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="body1">
          <b>
            {getLang(
              lang,
              boxInfo
                ? boxInfo.type === "missing"
                  ? "label.ITEMS_MISSING"
                  : boxInfo.type === "receive"
                  ? "label.ITEMS_RECEIVED"
                  : "label.ITEMS_TO_RECEIVE"
                : "label.ITEMS_TO_RECEIVE"
            )}
          </b>
        </Typography>
        <IconButton
          onClick={handleClose}
          edge="end"
          size="small"
          aria-label="close dialog"
          // disabled={isLoading}
        >
          <CloseRoundedIcon color="primary" />
        </IconButton>
      </Box>
      <Box className={classes.container}>
        {!!boxInfo && (
          <>
            <Box display="flex" alignItems="center" mb={2} fontWeight="bold">
              {!!selectedProduct && (
                <Box mr={1.2}>
                  <BackIcon
                    color="primary"
                    style={{ cursor: "pointer" }}
                    onClick={() => viewProduct(null)}
                  />
                </Box>
              )}
              <Typography variant="body1">
                <b>{boxInfo.name}</b>
              </Typography>
              {!!selectedProduct && (
                <>
                  <Box mx={0.5}>
                    <ChevronRightRoundedIcon color="disabled" />
                  </Box>
                  <Box>
                    <Typography variant="body1" color="primary">
                      <b>{getLang(lang, "label.PRODUCT_DETAILS")}</b>
                    </Typography>
                  </Box>
                </>
              )}
            </Box>
            <Box className={classes.productRow}>
              {!!selectedProduct ? (
                <>
                  <Box display="flex" alignItems="center" p={1}>
                    <Box className={classes.productPhoto} mr={2}>
                      {!selectedProduct?.picture ? (
                        <img src={DefaultImg} alt="emptyProduct" />
                      ) : (
                        <img
                          src={selectedProduct.picture}
                          alt={selectedProduct.name}
                        />
                      )}
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="flex-start"
                    >
                      <Typography variant="body1">
                        <b>{selectedProduct.name}</b>
                      </Typography>
                      {!!selectedProduct.attributes &&
                        !!selectedProduct.attributes.length && (
                          <Typography
                            className={classes.attributeText}
                            variant="body2"
                          >
                            {selectedProduct.attributes.join(", ")}
                          </Typography>
                        )}
                    </Box>
                  </Box>
                  <Box p={1}>
                    <Typography variant="body1">
                      <b>{selectedProduct.quantity}</b>
                    </Typography>
                    <Typography variant="body2">{getLabelText()}</Typography>
                  </Box>
                </>
              ) : (
                <>
                  <Box display="flex" alignItems="center" p={1}>
                    <Typography variant="body1">
                      <b>{boxInfo.name}</b>
                    </Typography>
                  </Box>
                  <Box p={1}>
                    <Typography variant="body1">
                      <b>{boxInfo.quantity}</b>
                    </Typography>
                    <Typography variant="body2">{getLabelText()}</Typography>
                  </Box>
                </>
              )}
            </Box>
            <Box p={2} mt={2} style={{ background: "white" }}>
              {!!selectedProduct ? (
                <>
                  <ProductSNListTable
                    rows={prdList}
                    isFetching={isPrdFetching}
                    isError={isPrdError}
                    handleReload={handlePrdReload}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    totalFiltered={prdTotalFiltered}
                    paginationOptions={paginationOptions}
                    tableFilter={tableFilter}
                    changeOrder={changeOrder}
                    lang={lang}
                    boxUuid={boxInfo.uuid}
                  />
                </>
              ) : isFetching ? (
                <>
                  <Skeleton variant="text" animation="wave" height={25} />
                  <Skeleton variant="text" animation="wave" height={25} />
                  <Skeleton variant="text" animation="wave" height={25} />
                  <Skeleton variant="text" animation="wave" height={25} />
                </>
              ) : isError ? (
                <Box
                  display="flex"
                  justifyContent="center"
                  flexDirection="column"
                  alignItems="center"
                  pt={2}
                  pb={3}
                >
                  <Typography
                    variant="body2"
                    color="primary"
                    style={{ fontWeight: "bold", paddingBottom: "8px" }}
                  >
                    {getLang(lang, "label.NO_DATA_RECEIVED")}
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{ color: "#9CA6A9", paddingBottom: "16px" }}
                  >
                    {getLang(lang, "label.SOMETHING_WENT_WRONG")}
                  </Typography>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleReload}
                    disableElevation
                  >
                    {getLang(lang, "label.REFRESH")}
                  </Button>
                </Box>
              ) : (
                <Box className={classes.productList}>
                  {list.map((item, i) => (
                    <Box key={i}>
                      <Box className={classes.productRow}>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          p={1}
                        >
                          <Box display="flex" alignItems="center">
                            <Box className={classes.productPhoto} mr={2}>
                              {!item?.picture ? (
                                <img src={DefaultImg} alt="emptyProduct" />
                              ) : (
                                <img src={item.picture} alt={item.name} />
                              )}
                            </Box>
                            <Box
                              display="flex"
                              flexDirection="column"
                              justifyContent="center"
                              alignItems="flex-start"
                            >
                              <Typography variant="body1">
                                <b>{item.name}</b>
                              </Typography>
                              {!!item.attributes &&
                                !!item.attributes.length && (
                                  <Typography
                                    className={classes.attributeText}
                                    variant="body2"
                                  >
                                    {item.attributes.join(", ")}
                                  </Typography>
                                )}
                            </Box>
                          </Box>
                          <ChevronRightRoundedIcon
                            color="primary"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              viewProduct(item);
                            }}
                          />
                        </Box>
                        <Box p={1}>
                          <Typography variant="body1">
                            <b>{item.quantity}</b>
                          </Typography>
                          <Typography variant="body2">
                            {getLang(lang, "label.PRODUCT")}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  ))}
                </Box>
              )}
            </Box>
          </>
        )}
      </Box>
    </Dialog>
  );
}
