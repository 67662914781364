import * as Yup from "yup";
import { getLang } from "../app/feature/constants/constants.selector";

const handleRegistrationForm = ({ lang }) =>
    Yup.array().of(
        Yup.object().shape({
            variantName: Yup.mixed().when("type", {
                is: "textField",
                then: Yup.string()
                    .required(
                        getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")
                    ),
            }),
        })
    );


export const RegistrationFormSchema = ({ lang }) => Yup.object({
    form: handleRegistrationForm({ lang })
})

export const checkIfFieldAreError = (touchedField,errorField, index) => {
    let error = false;

    if (Array.isArray(errorField) && typeof errorField[index] === 'object' && errorField[index].variantName &&
    Array.isArray(touchedField) && typeof touchedField[index] === 'object' && touchedField[index].variantName) {
        error = true;
        return error;
    }

    return error
}
