import React from "react";
import { makeStyles, Box, Typography, Tabs, Divider } from "@material-ui/core";
import CustomTab from "components/tab";
import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";
import { getLang } from "app/feature/constants";
import { TabPanel, allyProps } from "components/tabPanel";
import ReceiveTransferListing from "../receive-transfer-listing";

const style = (theme) => ({
  indicator: {
    border: "0.5px solid",
    borderColor: theme.palette.primary.main,
  },
  scroll: {
    width: "min-content",
  },
});

const useStyles = makeStyles(style);

export default function MainPageComponent({ lang }) {
  const classes = useStyles();

  return (
    <Box>
      <Box gridArea="topbar">
        <Box
          mb={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="flex" alignItems="flex-end" fontWeight="bold">
            <Typography variant="h6" color="primary">
              Trackco
            </Typography>
            <Box mx={0.5}>
              <ChevronRightRoundedIcon color="disabled" />
            </Box>
            <Box>
              <Typography variant="h6" color="primary">
                {getLang(lang, "label.RECEIVE_TRANSFER")}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
      </Box>

      <Box gridArea="panel">
        <Box>
          <Box>
            <Tabs
              value={0}
              aria-label="scrollable auto tabs example"
              textColor="primary"
              variant="scrollable"
              indicatorColor="primary"
              classes={{
                indicator: classes.indicator,
                scrollButtons: classes.scroll,
              }}
            >
              <CustomTab
                {...allyProps(
                  0,
                  getLang(lang, "label.RECEIVE_TRANSFER_LISTING")
                )}
              />
            </Tabs>
          </Box>

          <Box>
            <TabPanel value={0} index={0}>
              <ReceiveTransferListing />
            </TabPanel>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
