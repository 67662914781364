import { createSlice } from "@reduxjs/toolkit";
import { fetchBonanzaPointListing, fetchLoyaltyPointListing, getLoyaltyPoint, getModuleDropdown, getBonanzaPoint } from "modules/loyalty-program/redux/action/loyaltyProgram.action"
import { LOYALTYPROGRAM, loyaltyType } from "../../constants"

const initialState = {
    pointListTable: {
        data: [],
        totalRecords: 0,
        filteredTotalRecords: 0
    },
    loyaltyPoint: {
        id: "",
        point: "",
        modules: [],
        status: ""
    },
    modulesDropdown: [],
    bonanzaListTable: {
        data: [],
        totalRecords: 0,
        filteredTotalRecords: 0
    },
    bonanzaPoint: {
        id: "",
        point: "",
        modules: [],
        status: "",
        parentId: "",
        startAt: "",
        endAt: ""
    },
    loyaltyPointType: "view",
    bonanzaPointType: "view",
    headerType: "view",
    isLoadingPointError: false,
    isLoadingBonanzaError: false,
    pageSetting: {
        length: 25,
        start: 0,
        search: "",
        product: [],
    },
}

const loyaltyProgramSlice = createSlice({
    name: LOYALTYPROGRAM,
    initialState,
    reducers: {
        setType(state, action) {
            const { payload } = action;
            state.headerType = payload.type;
            switch (payload.index) {
                case loyaltyType.point:
                    state.loyaltyPointType = payload.type;
                    break;
                case loyaltyType.bonanza:
                    state.bonanzaPointType = payload.type;
                    break;
                default:
                    state.loyaltyPointType = payload.type;
                    state.bonanzaPointType = payload.type;
                    break;
            }
        },

        resetLoyaltyPoint(state, action) {
            state.loyaltyPoint = initialState.loyaltyPoint
            state.view = initialState.view
        },

        resetBonanzaPoint(state, action) {
            state.bonanzaPoint = initialState.bonanzaPoint
            state.view = initialState.view
        },

        updateLoyaltyProgramPageSetting(state, action) {
            const { payload } = action;
            state.pageSetting = {
                ...state.pageSetting,
                ...payload,
            };
        },
    },
    extraReducers: {
        [fetchLoyaltyPointListing.fulfilled]: (state, action) => {
            const { payload } = action
            const tempArray = []

            state.pointListTable.totalRecords = payload.recordsFiltered

            payload.data.forEach(item => {
                tempArray.push({
                    loyaltyId: item.id,
                    point: item.amount,
                    dateAdded: item.createdAt,
                    module: item.products,
                    status: item.status,
                })
            })

            state.pointListTable.data = tempArray;
            state.isLoadingPointError = false;
        },
        [fetchLoyaltyPointListing.rejected]: (state, action) => {
            state.isLoadingPointError = true;
          },
        [getLoyaltyPoint.fulfilled]: (state, action) => {
            const { payload } = action
            const { loyaltyPoint } = state

            loyaltyPoint.id = payload.id
            loyaltyPoint.point = payload.amount
            loyaltyPoint.status = payload.status

            const products = [];
            payload.products.forEach((product) => {
                const info = {
                    id: product.productId,
                    name: product.productName
                };
                products.push(info);
            });
            
            loyaltyPoint.modules = products
        },
        [getModuleDropdown.fulfilled]: (state, action) => {
            const { payload } = action
            const modules = [];
            payload.forEach((item) => {
                const module = {
                    id: item.productId,
                    name: item.productName,
                    picture: item.picture,
                    point: item.amount
                };
                modules.push(module);
            });
            state.modulesDropdown = modules
        },
        [fetchBonanzaPointListing.fulfilled]: (state, action) => {
            const { payload } = action
            const tempArray = []

            state.bonanzaListTable.totalRecords = payload.recordsTotal
            state.bonanzaListTable.filteredTotalRecords = payload.recordsFiltered

            payload.data.forEach(item => {
                tempArray.push({
                    bonanzaId: item.id,
                    percent: item.amount,
                    dateAdded: item.createdAt,
                    startAt: item.startAt,
                    endAt: item.endAt,
                    module: item.products,
                    status: item.status,
                })
            })

            state.bonanzaListTable.data = tempArray;
            state.isLoadingBonanzaError = false;
        },
        [fetchBonanzaPointListing.rejected]: (state, action) => {
            state.isLoadingBonanzaError = true;
        },
        [getBonanzaPoint.fulfilled]: (state, action) => {
            const { payload } = action
            const { bonanzaPoint } = state

            bonanzaPoint.id = payload.id
            bonanzaPoint.point = payload.amount
            bonanzaPoint.status = payload.status
            bonanzaPoint.startAt = payload.startAt
            bonanzaPoint.endAt = payload.endAt

            const products = [];
            payload.products.forEach((product) => {
                const info = {
                    id: product.productId,
                    name: product.productName,
                    point: product.campaign.campaignInfo.amount
                };
                products.push(info);
            });
            
            bonanzaPoint.modules = products
        },
    },
})

export const loyaltyProgramReducer = loyaltyProgramSlice.reducer;
export const {
    resetLoyaltyPoint,
    resetBonanzaPoint,
    setType,
    updateLoyaltyProgramPageSetting
} = loyaltyProgramSlice.actions