import React from "react";
import SurveyDeviceInfoCardComponent from "./surveyDeviceInfoCard.component";
import { useSelector } from "react-redux";

export default function SurveyDeviceInfoCardContainer({
  data,
  total
}) {
  const lang = useSelector(state => state.constant.languages);

  return (
    <SurveyDeviceInfoCardComponent data={data} total={total} lang={lang}
/>
  )
}