import { setChartColors } from "modules/reporting/utils/chartConfig";
import React from "react";
import Chart from "react-apexcharts";

export default function NoDataChartComponent({
  labels,
  datasets,
  type = "bar",
}) {
  const colorSet = setChartColors(datasets.length, true);
  const options = {
    plotOptions: {
      bar: {
        dataLabels: {
          style: {
            fontSize: 16,
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: "bold",
            colors: ["#f00", "#fff"],
          },
          enabled: false,
          total: {
            enabled: false,
            offsetX: 10,
            offsetY: 0,
            style: {
              color: "#373d3f",
              fontSize: "12px",
              fontFamily: undefined,
              fontWeight: 600,
            },
          },
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    xaxis: {
      categories: labels,
    },
    fill: {
      opacity: 1,
    },
    legend: {
      position: "bottom",
      itemMargin: {
        horizontal: 20,
        vertical: 16,
      },
      onItemClick: {
        toggleDataSeries: false,
      },
    },
    colors: colorSet,
  };

  return (
    <Chart
      options={options}
      series={datasets}
      type={type}
      height="430px"
    ></Chart>
  );
}
