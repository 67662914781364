import React from "react"
import { makeStyles, Typography, Box, Tabs } from "@material-ui/core"
import CustomTab from "components/tab"
import { getLang } from "app/feature/constants"
import DealerProductListPanel from "../dealerProductListPanel"
import DealerPackageListPanel from "../dealerPackageListPanel"
import DealerBoxListPanel from "../dealerBoxListPanel"
import DealerLooseItemListPanel from "../dealerLooseItemListPanel"

const useStyles = makeStyles((theme) => ({
  indicator: {
    border: "0.5px solid",
    borderColor: theme.palette.primary.main,
  },
  scroll: {
    width: "min-content",
  },
  container: {
    background: "white",
    borderRadius: 12,
    marginTop: 16,
    border: "1px solid #D0D5DD",
    padding: "4px 0px 8px"
  },
}))

export default function DealerInventoryPanelComponent({ 
  tabLabels,
  activeIndex,
  handleChange,
  lang
}) {
  const classes = useStyles()

  function a11yProps(index, label = "label") {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tab-${index}`,
      label: <Typography variant="button" style={{ textTransform: "none" }} children={label} />,
    }
  }

  function TabPanel({ children, value, index, ...other }) {
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </Typography>
    )
  }

  return (
    <Box className={classes.container}>
      <Box display="flex" justifyContent="space-between" px={3}>
        <Tabs
          value={activeIndex}
          onChange={handleChange}
          aria-label="scrollable auto tabs example"
          textColor="primary"
          variant="scrollable"
          indicatorColor="primary"
          classes={{
            indicator: classes.indicator,
            scrollButtons: classes.scroll,
          }}
        >
          {tabLabels.map((item, index) => {
            return <CustomTab key={index} {...a11yProps(index, item)} />
          })}
        </Tabs>
      </Box>
      <Box
        style={{
          position: "relative",
        }}
      >
        {tabLabels.map((item, index) => {
          if (item === getLang(lang, "label.PRODUCT_TAB")) {
            return (
              <TabPanel value={activeIndex} index={index} key={index}>
                <Box className={classes.panel}>
                  <DealerProductListPanel />
                </Box>
              </TabPanel>
            );
          }
          if (item === getLang(lang, "label.PACKAGE_TAB")) {
            return (
              <TabPanel value={activeIndex} index={index} key={index}>
                <Box className={classes.panel}>
                  <DealerPackageListPanel />
                </Box>
              </TabPanel>
            );
          }
          if (item === getLang(lang, "label.BOX_TAB")) {
            return (
              <TabPanel value={activeIndex} index={index} key={index}>
                <Box className={classes.panel}>
                  <DealerBoxListPanel />
                </Box>
              </TabPanel>
            );
          }
          if (item === getLang(lang, "label.LOOSE_ITEM_TAB")) {
            return (
              <TabPanel value={activeIndex} index={index} key={index}>
                <Box className={classes.panel}>
                  <DealerLooseItemListPanel />
                </Box>
              </TabPanel>
            );
          }
          return null;

        })}
      </Box>
    </Box>
  )
}
