import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import { fontTextStyle, tableStyle } from "modules/reporting/styles";
import { drilldownPageSelection, responseHandler } from "modules/reporting/utils/helper";
import React from "react";
import { TableCellLoading } from "modules/reporting/components/skeleton/loading";
import { TableCellNoData } from "modules/reporting/components/skeleton/no-data";
import { TableCellNoResult } from "modules/reporting/components/skeleton/no-result";
import { ContentCard } from "modules/reporting/components/layout";
import moment from "moment";
import { Link } from "react-router-dom";

export default function SuspendedProductTableComponent(props) {
    const { suspendedProductColumns, status, data, currentFilter, pageType, translate } = props;

    const tableClasses = tableStyle();
    const fontClasses = fontTextStyle();

    const tableDisplay = (data) => {

        if (Object.keys(data).length === 0 || !data.isSuccess) {
            return null
        }

        let no_iteration = 1;
        return (
            data.map((row, index) => {
                return (
                    <TableRow key={index}>
                        <TableCell
                            className={tableClasses.table__cell}
                            align="left"
                            style={{
                                width: "20px"
                            }}
                        >
                            <Typography className={fontClasses.text__table_cell}>
                                {no_iteration++}
                            </Typography>
                        </TableCell>
                        <TableCell
                            className={tableClasses.table__cell}
                            align="right"
                            style={{
                                minWidth: "130px"
                            }}
                        >
                            <Typography className={fontClasses.text__table_cell}>
                                <Link
                                    target="_blank" rel="noopener noreferrer"
                                    className={fontClasses.text__hyperlink}
                                    to={() => {

                                        let filterProperties = {};

                                        if (currentFilter.countryCode)
                                            filterProperties.countryCode = currentFilter.countryCode;

                                        if (currentFilter.cityUuid)
                                            filterProperties.city = currentFilter.cityUuid;

                                        if (currentFilter.branchUuid)
                                            filterProperties.branchUuid = currentFilter.branchUuid;

                                        if (currentFilter.period)
                                            filterProperties.period = currentFilter.period;

                                        if (currentFilter.dateRange) {
                                            filterProperties.start = moment(currentFilter.dateRange[0]).format('YYYY-MM-DD');
                                            filterProperties.end = moment(currentFilter.dateRange[1]).format('YYYY-MM-DD');
                                        }

                                        if (row.product_uuid)
                                            filterProperties.productUuid = row.product_uuid;

                                        let url = drilldownPageSelection(filterProperties, pageType, true);
                                        return url;
                                    }}
                                >
                                    <span>{row.product}</span>
                                </Link>
                            </Typography>
                        </TableCell>
                        <TableCell
                            className={tableClasses.table__cell}
                            align="right"
                            style={{
                                minWidth: "130px"
                            }}
                        >
                            <Typography className={fontClasses.text__table_cell}>
                                {row.suspend_count}
                            </Typography>
                        </TableCell>
                        <TableCell
                            className={tableClasses.table__cell}
                            align="right"
                            style={{
                                minWidth: "130px"
                            }}
                        >
                            <Typography className={fontClasses.text__table_cell}>
                                {row.valid_scan_count}
                            </Typography>
                        </TableCell>
                    </TableRow>
                )
            })
        )
    }

    return (
        <ContentCard style={{ margin: '0' }}>
            <Box marginBottom="24px" display="flex" justifyContent="space-between" alignContent="center">
                <Typography className={fontClasses.text__card_title}>
                    <b>{translate("label.TOP_SUSPENDED_PRODUCTS")} ({data.length || 0})</b>
                </Typography>
            </Box>
            <TableContainer component={Paper}>
                <Box style={{
                    overflowX: 'auto',
                    width: '100%'
                }}>
                    <Table>
                        {/* TABLE HEAD --- START */}
                        <TableHead>
                            <TableRow style={{ backgroundColor: "#fff !important" }}>
                                {suspendedProductColumns.map((column, index) => {
                                    return (
                                        <TableCell className={[tableClasses.table__cell, fontClasses.text__header_table_cell]} key={suspendedProductColumns.title} size={suspendedProductColumns.size}>
                                            {column.render(column)}
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        </TableHead>
                        {/* TABLE HEAD --- END */}

                        {/* TABLE BODY --- START */}
                        <TableBody>
                            {
                                responseHandler(
                                    data,
                                    status,
                                    tableDisplay(data),
                                    (
                                        <TableRow>
                                            <TableCellLoading />
                                        </TableRow>
                                    ),
                                    (
                                        <TableRow>
                                            <TableCellNoData
                                                title={translate("label.QR_CODE_DATA_NOT_FOUND")}
                                                value={translate("paragraph.EMPTY_QR_GENERATE_NOW")}
                                                buttonText={translate("paragraph.GENERATE_QR_CODE").toUpperCase()}
                                                link="/admin/serial-number/generate"
                                            />
                                        </TableRow>
                                    ),
                                    (
                                        <TableRow>
                                            <TableCellNoResult
                                                title={translate("label.QR_CODE_DATA_NOT_FOUND")}
                                                value={translate("paragraph.NO_DATA_ON_SELECTED_FILTER")}
                                            />
                                        </TableRow>
                                    ),
                                    (
                                        <TableRow>
                                            <TableCellNoResult
                                                title={translate("label.LOADING_FAILED")}
                                                value={translate("paragraph.LOADING_FAILED_DESCRIPTION")}
                                            />
                                        </TableRow>
                                    )

                                )
                            }
                        </TableBody>
                        {/* TABLE BODY --- END */}
                    </Table>
                </Box>
            </TableContainer>
        </ContentCard>
    )
}
