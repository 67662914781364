import React from "react";
import { Box, Typography, makeStyles, Button } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import LoadingComponent from "components/loading";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles(theme => ({
  title: {
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      fontSize: "smaller",
      marginBottom: theme.spacing(1)
    }
  },
  resendBtn: {
    textTransform: "none",
    padding: "7px 0px",
    textAlign: "left",
    lineHeight: 1.4
  }
}));

function SentResetPasswordPanelComponent({ isLoading, handleClick, email, handleSubmit, enableResend, counter, lang }) {
  const classes = useStyle();

  return (
    <Box p={4}>
      {
        isLoading
          ?
          <LoadingComponent />
          :
          <>
          <Typography variant="body1" className={classes.title} gutterBottom>
        {getLang(lang, "paragraph.CHECK_EMAIL")}
      </Typography>
      <Typography variant="body2" color="textPrimary">
        {getLang(lang, "paragraph.SENT_LINK_RESET_PASSWORD")}:
      </Typography>
      <Typography className={classes.title}>{email}</Typography>
      <br />
      <Typography variant="subtitle2" color="textPrimary">
        {getLang(lang, "paragraph.NOT_RECEIVING_EMAIL_QUESTION")}
      </Typography>
      <Button
        color="secondary"
        classes={{ root: classes.resendBtn, label: classes.resendBtnLabel }}
        onClick={() => handleSubmit(email)}
        endIcon={<ArrowForwardIcon />}
        disabled={!enableResend}
      >
        {enableResend ? getLang(lang, "label.RESEND_CONFIRMATION_EMAIL") : getLang(lang, "paragraph.TRY_RESEND_EMAIL", {count: counter})}
      </Button>
      <Box mt={4} display="flex" justifyContent="flex-end">
        <Button
          variant="contained"
          color="secondary"
          disableElevation
          onClick={handleClick}
        >
          {getLang(lang, "label.OK")}
        </Button>
      </Box>
          </>
      }
    </Box>
  );
}

export default SentResetPasswordPanelComponent;
