import React, { useState } from "react";
import WarrantyListTableComponent from "./warrantyListTable.component";
import WarrantyFormPanel from "../../panel/warrantyPanel";
import {
  setType,
  resetWarrantySetting,
  resetRegisterForm,
  resetWarrantyExtension,
  resetRegisterFormType
} from "modules/warranty/redux";
import { connect, useSelector } from "react-redux";
import {
  fetchWarrantySettingDetail,
  fetchWarrantyExtensionList,
  fetchJSONURL,
  assignWarranty
} from "../../../redux";
import { hideBackdrop, showBackdrop } from "modules/backdrop";
import { ROOT } from "../../../config/warranty.route";
import { useHistory } from "react-router-dom";

function WarrantyListTableContainer({
  hasWarrantyAddAccessRight,
  hasWarrantyEditAccessRight,
  hasWarranteeViewAccessRight,
  warrantyListData,
  page,
  rowsPerPage,
  handlePageChange,
  handleRowPerPageChange,
  length,
  search,
  setType,
  fetchWarrantySettingDetail,
  fetchJSONURL,
  isFetching,
  hideBackdrop,
  showBackdrop,
  fetchWarrantyExtensionList,
  resetWarrantySetting,
  resetRegisterForm,
  resetRegisterFormType,
  resetWarrantyExtension,
  handleCreate,
  handleReload,
  paginationOptions
}) {
  const history = useHistory();
  const [isModalOpen, setModalOpen] = useState(false);
  const isLoadingWarrantyError = useSelector(state => state.warranty.isLoadingWarrantyError)
  const lang = useSelector(state => state.constant.languages);
  const language = useSelector(state => state.profile.language);

  const handleView = async (id) => {
    setType({ index: -1, type: "view" });
    showBackdrop();
    const response = await fetchWarrantySettingDetail(id);
    const fetchWarrantyInfo = {
      id: id,
      length: "1000",
      start: 0,
      column: "created_at",
      order: "asc",
      search: ""
    };
    if (response.payload.success) {
      if (response.payload.data.tnc_url.substr(response.payload.data.tnc_url.length - 3) !== "pdf") {
      await fetchJSONURL(response.payload.data.tnc_url);
      }
      await fetchWarrantyExtensionList(fetchWarrantyInfo);
      hideBackdrop();
      setModalOpen(true);
    }
    else {
      hideBackdrop();
      setModalOpen(false);
    }
  
  };

  const closeModal = () => {
    resetWarrantySetting();
    resetRegisterFormType();
    resetWarrantyExtension();
    resetRegisterForm();
    setModalOpen(false);
  };

  async function handleViewWarrantee(id) {
    history.push(`${ROOT}/View/${id}`);
  }

  return (
    <>
      <WarrantyListTableComponent
        hasWarrantyAddAccessRight={hasWarrantyAddAccessRight}
        hasWarrantyEditAccessRight={hasWarrantyEditAccessRight}
        hasWarranteeViewAccessRight={hasWarranteeViewAccessRight}
        rows={warrantyListData}
        page={page}
        rowsPerPage={rowsPerPage}
        handlePageChange={handlePageChange}
        handleRowPerPageChange={handleRowPerPageChange}
        isFetching={isFetching}
        length={length}
        search={search}
        handleView={handleView}
        handleViewWarrantee={handleViewWarrantee}
        handleCreate={handleCreate}
        handleReload={handleReload}
        isLoadingWarrantyError={isLoadingWarrantyError}
        paginationOptions={paginationOptions}
        lang={lang}
        language={language}
      />
      <WarrantyFormPanel isOpen={isModalOpen} handleClose={closeModal} />
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  setType: (values) => dispatch(setType(values)),
  fetchJSONURL: (values) => dispatch(fetchJSONURL(values)),
  fetchWarrantySettingDetail: (values) =>
    dispatch(fetchWarrantySettingDetail(values)),
  hideBackdrop: () => dispatch(hideBackdrop()),
  showBackdrop: () => dispatch(showBackdrop()),
  fetchWarrantyExtensionList: (values) =>
    dispatch(fetchWarrantyExtensionList(values)),
  assignWarranty: (values) => dispatch(assignWarranty(values)),
  resetRegisterForm: () => dispatch(resetRegisterForm()),
  resetWarrantyExtension: () => dispatch(resetWarrantyExtension()),
  resetWarrantySetting: () => dispatch(resetWarrantySetting()),
  resetRegisterFormType: () => dispatch(resetRegisterFormType())
});

export default connect(null, mapDispatchToProps)(WarrantyListTableContainer);
