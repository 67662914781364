import React, { useState } from "react";
import ChangePasswordPanelComponent from "./changePasswordPanel.component";
import { connect, useSelector } from "react-redux";
import { selectLoading } from "modules/notification";
import { changePassword } from "../../../redux";
import {
  isEightChar,
  isLowercaseExist,
  isNumExist,
  isSpecialCharExist,
  isUppercaseExist,
} from "lib/generalUtility";
import { getLang } from "app/feature/constants";

function ChangePasswordPanelContainer({ isChanging, changePassword }) {

  const lang = useSelector(state => state.constant.languages)

  const validators = (password) => [
    {
      name: getLang(lang,"label.ONE_LOWERCASE_CHAR"),
      isValidated: isLowercaseExist(password),
    },
    {
      name: getLang(lang,"label.ONE_SPECIAL_CHAR"),
      isValidated: isSpecialCharExist(password),
    },
    {
      name: getLang(lang,"label.ONE_UPPERCASE_CHAR"),
      isValidated: isUppercaseExist(password),
    },
    { name: getLang(lang,"label.EIGHT_CHAR_MIN"), isValidated: isEightChar(password) },
    { name: getLang(lang,"label.ONE_NUMBER"), isValidated: isNumExist(password) },
  ];

  const [showPassword, setShowPassword] = useState(false);

  const IsShowPassword = (type) => {
    setShowPassword(prev => !prev)
  };
  const handleSubmit = async (values) => {

    const updateInfo = {
      currentPassowrd: values.currentPw,
      password: values.newPw,
      confirmPassword: values.confirmPw
    }
    await changePassword(updateInfo);
  };

  return (
    <ChangePasswordPanelComponent
      validators={validators}
      handleSubmit={handleSubmit}
      isChanging={isChanging}
      IsShowPassword={IsShowPassword}
      showPassword={showPassword}
      lang={lang}
    />
  );
}

const mapStateToProps = (state) => ({
  isChanging: selectLoading(state, changePassword.typePrefix)
});

const mapDispatchToProps = (dispatch) => ({
  changePassword: (passwordData) => dispatch(changePassword(passwordData))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePasswordPanelContainer);
