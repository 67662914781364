import axios from "axios";

export function getDealerStockoutByProductData({
  length,
  start,
  search,
  orderBy,
  orderDirection,
  search_by,
  branch_uuid,
  category_uuid,
  brand_uuid,
  model_uuid,
  product_uuid,
  period,
  start_date,
  end_date,
  url
}) {

  return new Promise((resolve, reject) => {
    axios
      .post(url, {
        length,
        start,
        search,
        orderBy,
        orderDirection,
        search_by,
        branch_uuid,
        category_uuid,
        brand_uuid,
        model_uuid,
        product_uuid,
        period,
        start_date,
        end_date
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        if (error?.response?.data) {
          reject(error.response.data);
        } else {
          reject(error);
        }
      });
  });
}

export default getDealerStockoutByProductData;
