import { getSurvey } from 'modules/survey/redux/action'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from "react-router-dom"
import { ROOT } from "../../../config/survey.route";
import { ViewSurveyDialog } from '../../dialog'
import SurveyListingTableComponent from './surveyListingTable.component'
import { addAlert } from "modules/notification";
import { getLang } from 'app/feature/constants';


export default function SurveyListingTableContainer({
  hasSurveyAddAccessRight,
  hasSurveyEditAccessRight,
  hasSurveyActivateOrDeactivateAccessRight,
  data,
  isFetching,
  page,
  search,
  rowsPerPage,
  totalRecords,
  handleChangePage,
  handleChangeRowsPerPage,
  handleCreate,
  handleStatusChange,
  openEditSurveyModal,
  handleDeleteSurvey,
  handleReload,
  paginationOptions
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isViewDialogOpen, setIsViewDialogOpen] = useState(false)
  const isLoadingSurveyError = useSelector((state) => state.survey.isLoadingSurveyError);

  const lang = useSelector(state => state.constant.languages);
  const language = useSelector(state => state.profile.language);
  const CONSUMER_HOST = process.env.REACT_APP_CONSUMER_PORTAL_URL

  const handleViewReport = ({ id }) => {
    history.push(`${ROOT}/view/${id}`)
  }

  const handleView = ({ id }) => {
    dispatch(getSurvey({ id }))
    setIsViewDialogOpen(true)
  }

  const addSuccessAlert = (value) => {
    dispatch(addAlert({ severity: "success", message: value }))
  }

  function copyLink(uuid) {
    navigator.clipboard.writeText(`${CONSUMER_HOST}/survey/${uuid}?anonymous=true`)
    addSuccessAlert(getLang(lang, 'message.success.SUCCESS_COPY_LINK_TO_CLIPBOARD'))
  }

  return (
    <>
      <SurveyListingTableComponent
        hasSurveyAddAccessRight={hasSurveyAddAccessRight}
        hasSurveyEditAccessRight={hasSurveyEditAccessRight}
        hasSurveyActivateOrDeactivateAccessRight={hasSurveyActivateOrDeactivateAccessRight}
        data={data}
        isFetching={isFetching}
        page={page}
        search={search}
        language={language}
        rowsPerPage={rowsPerPage}
        totalRecords={totalRecords}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleCreate={handleCreate}
        handleView={handleView}
        handleStatusChange={handleStatusChange}
        handleReload={handleReload}
        handleDeleteSurvey={handleDeleteSurvey}
        isLoadingSurveyError={isLoadingSurveyError}
        handleViewReport={handleViewReport}
        copyLink={copyLink}
        paginationOptions={paginationOptions}
        lang={lang}
      />
      <ViewSurveyDialog
        hasSurveyEditAccessRight={hasSurveyEditAccessRight}
        isOpen={isViewDialogOpen}
        handleClose={() => setIsViewDialogOpen(false)}
        handleEdit={() => {
          openEditSurveyModal()
          setIsViewDialogOpen(false)
        }}
      />
    </>
  )
}
