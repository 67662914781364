import React from "react";
import PropTypes from "prop-types";
import GeneralDialogComponent from "./generalDialog.component";
import { useSelector } from "react-redux";
import { getLang } from "app/feature/constants";

function GeneralDialogContainer({
  isLoading,
  isOpen,
  handleClose,
  handleProceed,
  title,
  description,
  rejectOption,
  icon,
  iconStyle,
  titleStyle,
  descriptionStyle,
  type,
  proceedLabel,
  rejectLabel,
}) {
  const lang = useSelector((state) => state.constant.languages);

  return (
    <GeneralDialogComponent
      isLoading={isLoading}
      isOpen={isOpen}
      handleClose={handleClose}
      handleProceed={handleProceed}
      title={title}
      description={description}
      proceedLabel={proceedLabel ?? getLang(lang, "label.CONFIRM")}
      rejectLabel={rejectLabel ?? getLang(lang, "label.CANCEL")}
      rejectOption={rejectOption}
      icon={icon}
      iconStyle={iconStyle}
      titleStyle={titleStyle}
      descriptionStyle={descriptionStyle}
      type={type}
    />
  );
}

GeneralDialogContainer.defaultProps = {
  rejectOption: true,
};

GeneralDialogContainer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleProceed: PropTypes.func.isRequired,
};

export default GeneralDialogContainer;
