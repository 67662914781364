import React from "react";
import { Box, CircularProgress, Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";
import { getLang } from "app/feature/constants";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SavingDialogComponent({
  handleClose,
  isOpen,
  isPublishing,
  handleSeeListing,
  lang
}) {

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={isOpen}
      TransitionComponent={Transition}
      disableBackdropClick
    >
      <Box
        width={"500px"}
        height={"400px"}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        {isPublishing ? (
          <>
            <CircularProgress color="primary" />
            <Box mt={4} textAlign="center">
              <Typography variant="body2" color="textSecondary">
                {getLang(lang,"label.SAVING")}
              </Typography>
            </Box>
          </>
        ) : (
          <>
            <Box mb={4} textAlign="center">
              <Typography variant="h5" color="textSecondary">
                {getLang(lang,"label.PRODUCT_HAS_BEEN_CREATED")}
              </Typography>
            </Box>
            <Button
              variant="contained"
              disableElevation
              color="primary"
              endIcon={<ArrowForwardIosRoundedIcon />}
              onClick={handleSeeListing}
            >
              {getLang(lang,"label.SEE_PRODUCT_LISTING")}
            </Button>
          </>
        )}
      </Box>
    </Dialog>
  );
}
