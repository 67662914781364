import { Box, CircularProgress, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles({
    chartCard: {
        backgroundColor: "#FFFFFF",
        borderRadius: "12px",
        border: "1px solid #D0D5DD",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "458px"
    }
});

export default function ChartCardLoadingComponent(){
    const classes = useStyles();
    return (
        <Box
            className={classes.chartCard}
        >
            <CircularProgress />
        </Box>

    )
}