import React from "react";
import OrderDetailsDialogComponent from "./orderDetailsDialog.component";

export default function OrderDetailsDialogContainer({ isOpen, handleClose }) {

  return (
    <OrderDetailsDialogComponent
      isOpen={isOpen}
      handleClose={handleClose}
    />
  );
}
