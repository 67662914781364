import React from "react";
import {
  makeStyles,
  Box,
  Typography,
  Button,
  FormHelperText,
  Grid,
  Divider, Tooltip
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import Input from "components/input/inputTextField";
import { isEmpty } from "lib/helper"
import AttributeSelect from '../../select/attributeSelect';
import BrandModelSingleSelect from '../../select/brandModelSingleSelect';
import BranchSelect from "../../select/branchSelect";
import CategorySelect from "../../select/categorySelect";
import CurrencySelect from "modules/account/components/select/currencySelect";
import WarrantySelect from "../../select/warantySelect";
import LoadingComponent from "components/loading";
import { getLang } from "app/feature/constants";
import HelpOutlinedIcon from "@material-ui/icons/HelpOutlined";
import Switch from 'components/switch';
import { addAlert } from 'modules/notification';

const useStyle = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    flexGrow: 1,
    backgroundColor: "white"
  },
  loading: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    flexGrow: 1,
    backgroundColor: "white"
  },
  field: {
    display: "grid",
    gridTemplateColumns: "2.5fr 7.5fr",
    columnGap: theme.spacing(2)
  },
  btnSecondary: {
    color: theme.palette.common.white
  },
  toggleControl: {
    margin: "7px 35px 7px 0px"
  },
  paperRoot: {
    width: "432px",
  },
  chip: {
    display: "flex",
    width: "fit-content",
    padding: "2px 10px",
    borderRadius: "50px",
    backgroundColor: "#dbdbdb",
    fontSize: "0.875rem",
    marginRight: "8px",
    marginBottom: "4px"
  },
  chipBox: {
    display: "flex",
    flexWrap: "wrap"
  }
}));

export default function ProductInfoFormComponent({
  children,
  handleSubmit,
  handleCancel,
  initialValues,
  enableEdit,
  allNodes,
  formType,
  lang,
  categoryDropdown,
  currencyDropdown,
  attributeDropdown,
  brandModelDropdown,
  isFetching,
  isScanLimitDisabled,
  setIsScanLimitDisabled
}) {
  const dispatch = useDispatch();
  const classes = useStyle();

  const section = [
    {
      num: "01",
      title: getLang(lang, 'label.PRODUCT_INFO'),
    },
    {
      num: "02",
      title: formType === 'create' ?
        `${getLang(lang, 'label.WARRANTY_INFO')} (${getLang(lang, 'label.OPTIONAL')})`
        : getLang(lang, 'label.WARRANTY_INFO'),
    }
  ];

  const displayBrandModel = (brand, model) => {
    let brandName = ""
    let modelName = ""

    if (brand.id !== "-1") {
      brandName = brand.name
    }

    if (model.id !== "-1") {
      modelName = model.name
    }

    if (brandName !== "" && modelName !== "") {
      return <Typography variant='body2'>{brandName}, {modelName}</Typography>
    }

    if (brandName === "" && modelName !== "") {
      return <Typography variant='body2'>{modelName}</Typography>
    }

    if (brandName !== "" && modelName === "") {
      return <Typography variant='body2'>{brandName}</Typography>
    }

    return <Typography variant='body2'>-</Typography>
  }
  let currencyCode = "";
  for (let i = 0; i < currencyDropdown.length; i++) {
    if (currencyDropdown[i].isDefault)
      currencyCode = currencyDropdown[i].alpha3;
  }

  const sectionLength = section.length;
  return (
    isFetching
      ? <Box className={classes.loading} >
        <LoadingComponent />
      </Box>
      : <Formik
        enableReinitialize
        initialValues={{
          productName: initialValues.productName,
          category: initialValues.category,
          brand: initialValues.brand,
          model: initialValues.model,
          attributes: initialValues.attributes,
          sku: initialValues.sku,
          currencyCode: formType === "edit" ? initialValues.currencyCode : currencyDropdown.find(({ alpha3 }) => alpha3 === currencyCode),
          currencyExp: initialValues.currencyExp,
          price: Number(initialValues.price).toFixed(initialValues.currencyExp),
          desc: initialValues.desc,
          nodes: initialValues.nodes,
          scanLimit: initialValues.scanLimit,
          incentivePoint: initialValues.incentivePoint,
          warrantyInfo: initialValues.warrantyInfo
        }}
        validationSchema={Yup.object({
          productName: Yup.string().trim().required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
          desc: Yup.string(),
          nodes: Yup.array().of(Yup.string().required()).required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
          scanLimit: Yup.number(),
          incentivePoint: Yup.number().integer().moreThan(-1).required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED"))
        })}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <form className={classes.root} onSubmit={formik.handleSubmit}>
            <Box p={3} pt={2} overflow="auto">
              {section.map((section, index) => (
                <Box key={index}>
                  <Grid container>
                    <Grid item xs={1} sm={1} md={1} style={{ width: "100%" }}>
                      <Typography
                        variant="body2"
                        color="primary"
                        style={{ fontWeight: "bold" }}
                      >
                        {section.num}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={11}
                      sm={11}
                      md={11}
                      style={{ width: "100%" }}
                    >
                      <Typography
                        variant="body2"
                        color="primary"
                        style={{ fontWeight: "bold" }}
                      >
                        {section.title}
                      </Typography>

                      {section.title === getLang(lang, 'label.PRODUCT_INFO') && (
                        <Box mt={4} mb={4} px={3} style={{ alignSelf: "center" }}>
                          <Box mb={2} className={classes.field}>
                            <Box display="flex" pt={enableEdit ? 1.5 : 0}>
                              <Typography variant="body2" color="textSecondary" display="inline">
                                {getLang(lang, "label.BRANCH")}&nbsp;
                              </Typography>
                              <Typography variant="body2" display="inline" style={{ color: "#DE350B" }}>*</Typography>
                            </Box>
                            {enableEdit ? (
                              <>
                                <Box width="100%">
                                  <BranchSelect
                                    value={formik.values.nodes}
                                    initialValue={formType === "create" ? [] : initialValues.nodes}
                                    handleChange={(value) => {
                                      formik.setFieldValue("nodes", value)
                                    }}
                                    styles={{ height: 43 }}
                                  />
                                  <FormHelperText
                                    error={
                                      formik.touched.nodes && formik.errors.nodes
                                        ? true
                                        : false
                                    }
                                    style={{ paddingLeft: 8 }}
                                  >
                                    {ErrorMessage({ name: "nodes" })}
                                  </FormHelperText>

                                </Box>
                              </>
                            ) : (
                              <>
                                <Box width="100%" className={classes.chipBox}>
                                  {formik.values.nodes.length > 0 ? (
                                    <>
                                      {formik.values.nodes.map((node, index) => (
                                        <Box key={index} className={classes.chip}>
                                          <span>{node.name}</span>
                                        </Box>
                                      ))}
                                    </>
                                  ) : (
                                    <Typography variant="body2">-</Typography>
                                  )}
                                </Box>
                              </>
                            )}
                          </Box>
                          <Box mb={2} className={classes.field}>
                            <Box display="flex" alignItems="center">
                              <Typography variant="body2" color="textSecondary" display="inline">
                                {getLang(lang, "label.PRODUCT_NAME")}&nbsp;
                              </Typography>
                              <Typography variant="body2" display="inline" style={{ color: "#DE350B" }}>*</Typography>
                            </Box>
                            {enableEdit ? (
                              <Input
                                required
                                hiddenLabel
                                variant="filled"
                                size="small"
                                margin="none"
                                placeholder={getLang(lang, "placeholder.ENTER_PRODUCT_NAME")}
                                fullWidth
                                {...formik.getFieldProps("productName")}
                                error={
                                  formik.touched.productName && formik.errors.productName
                                    ? true
                                    : false
                                }
                                helperText={ErrorMessage({ name: "productName" })}
                              />
                            ) : (
                              <Typography variant="body2">
                                {formik.values.productName}
                              </Typography>
                            )}
                          </Box>
                          <Box mb={2} className={classes.field}>
                            <Box display="flex" alignItems="center">
                              <Typography variant="body2" color="textSecondary">
                                {getLang(lang, "label.CATEGORY")}
                              </Typography>
                            </Box>
                            {enableEdit ? (
                              <CategorySelect
                                value={formik.values.category}
                                placeholder={categoryDropdown.length === 0 ? getLang(lang, "placeholder.NO_CATEGORY_CREATED") : getLang(lang, "placeholder.SELECT_CATEGORY")}
                                handleChange={(value) =>
                                  formik.setFieldValue("category", value)
                                }
                                handleClear={() => {
                                  formik.setFieldValue("category", "")
                                }}
                              />
                            ) : (
                              <Typography variant="body2">
                                {!isEmpty(formik.values.category) ? formik.values.category.name : "-"}
                              </Typography>
                            )}
                          </Box>
                          <Box mb={2} className={classes.field}>
                            <Box display="flex" alignItems="center">
                              <Typography variant="body2" color="textSecondary">
                                {getLang(lang, "label.BRAND_MODEL")}
                              </Typography>
                            </Box>
                            {enableEdit ? (
                              <BrandModelSingleSelect
                                brandValue={formik.values.brand}
                                modelValue={formik.values.model}
                                isClearable={true}
                                onChange={(brand, model) => {
                                  if (brand) {
                                    formik.setFieldValue("brand", brand)
                                  }
                                  if (model) {
                                    formik.setFieldValue("model", model)
                                  }
                                }}
                                handleClear={() => {
                                  formik.setFieldValue("brand", {
                                    id: "-1",
                                    name: ""
                                  })
                                  formik.setFieldValue("model", {
                                    id: "-1",
                                    name: ""
                                  })
                                }}
                                placeholder={brandModelDropdown.length === 0 ? getLang(lang, "placeholder.NO_BRAND_MODEL_CREATED") : getLang(lang, "placeholder.SELECT_BRAND_MODEL")}
                                disabled={formik.isSubmitting}
                                style={{
                                  backgroundColor: "#eceff0",
                                  minHeight: 43,
                                }}
                                textStyle={{ fontSize: "1rem" }}
                                PaperProps={{
                                  classes: { root: classes.paperRoot }
                                }}
                                dropdownItem={brandModelDropdown}
                                emptyMessage={getLang(lang, "paragraph.BRAND_MODEL_NOT_FOUND")}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "left",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "left",
                                }}
                                searchable={false}
                                isEmpty={formik.values.brand.id === "-1" && formik.values.model.id === "-1"}
                              />
                            ) : displayBrandModel(formik.values.brand, formik.values.model)}
                          </Box>
                          <Box mb={2} className={classes.field}>
                            <Box display="flex" alignItems="center">
                              <Typography variant="body2" color="textSecondary">
                                {getLang(lang, "label.ATTRIBUTES")}
                              </Typography>
                            </Box>
                            {enableEdit ? (
                              <>
                                <AttributeSelect
                                  value={formik.values.attributes}
                                  placeholder={attributeDropdown.length === 0 ? getLang(lang, "placeholder.NO_ATTRIBUTE_CREATED") : getLang(lang, "placeholder.SELECT_ATTRIBUTES")}
                                  disabled={formik.isSubmitting}
                                  isClearable={true}
                                  style={{
                                    backgroundColor: "#eceff0",
                                    minHeight: 43,
                                  }}
                                  textStyle={{ fontSize: "1rem" }}
                                  PaperProps={{
                                    classes: { root: classes.paperRoot }
                                  }}
                                  dropdownItem={attributeDropdown}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                  }}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                  }}
                                  isEmpty={formik.values.attributes.length === 0}
                                  emptyMessage={getLang(lang, "paragraph.ATTRIBUTE_NOT_FOUND")}
                                  onChange={(attribute) => {
                                    let temp = [...formik.values.attributes]

                                    if (temp.length > 0) {
                                      const index = temp.findIndex((item) => item.id === attribute.id)
                                      let attr = { ...temp[index] }
                                      if (attribute.delete) {
                                        const filteredAttributes = temp.filter((item) => item.id !== attribute.id)
                                        temp = [...filteredAttributes]
                                      }
                                      else if (index === -1) {
                                        temp.push(attribute)
                                      }
                                      else {
                                        attr.options = attribute.options
                                        attr.optionsId = attribute.optionsId
                                        temp[index] = attr
                                      }
                                    } else {
                                      temp.push(attribute)
                                    }

                                    formik.setFieldValue("attributes", temp)
                                  }}
                                  handleClear={() => {
                                    formik.setFieldValue("attributes", [])
                                  }}
                                  handleChipDelete={(attribute) => {
                                    let temp = [...formik.values.attributes]
                                    if (temp.length > 0) {
                                      const filteredAttributes = temp.filter((item) => item.id !== attribute.id)
                                      temp = [...filteredAttributes]
                                    }
                                    formik.setFieldValue("attributes", temp)
                                  }}
                                />
                              </>
                            ) : (
                              <>
                                <Box style={{ display: "flex", flexWrap: "wrap" }}>
                                  {formik.values.attributes.length > 0 ? (
                                    <>
                                      {formik.values.attributes.map((attribute, index) => (
                                        <Box key={index} className={classes.chip}>
                                          <span>{attribute.name}: {attribute.options}</span>
                                        </Box>
                                      ))}
                                    </>
                                  ) : (
                                    <Typography variant="body2">-</Typography>
                                  )}
                                </Box>
                              </>
                            )}
                          </Box>
                          <Box mb={2} className={classes.field}>
                            <Box display="flex" alignItems="center">
                              <Typography variant="body2" color="textSecondary">
                                {getLang(lang, "label.DESCRIPTION")}
                              </Typography>
                            </Box>
                            {enableEdit ? (
                              <Input
                                hiddenLabel
                                multiline
                                rows={4}
                                variant="filled"
                                size="small"
                                margin="none"
                                placeholder={getLang(lang, "placeholder.ENTER_DESCRIPTION")}
                                fullWidth
                                {...formik.getFieldProps("desc")}
                                error={
                                  formik.touched.desc && formik.errors.desc ? true : false
                                }
                                helperText={ErrorMessage({ name: "desc" })}
                              />
                            ) : (
                              <Typography variant="body2">{formik.values.desc}</Typography>
                            )}
                          </Box>
                          <Box mb={2} className={classes.field}>
                            <Box display="flex" alignItems="center">
                              <Typography variant="body2" color="textSecondary">
                                {getLang(lang, "label.SKU")}
                              </Typography>
                            </Box>
                            {enableEdit ? (
                              <Input
                                hiddenLabel
                                variant="filled"
                                size="small"
                                margin="none"
                                placeholder={getLang(lang, "placeholder.ENTER_PRODUCT_SKU")}
                                fullWidth
                                {...formik.getFieldProps("sku")}
                                error={
                                  formik.touched.sku && formik.errors.sku ? true : false
                                }
                                helperText={ErrorMessage({ name: "sku" })}
                              />
                            ) : (
                              <Typography variant="body2">
                                {formik.values.sku}
                              </Typography>
                            )}
                          </Box>
                          <Box mb={2} className={classes.field}>
                            <Box display="flex" alignItems="center">
                              <Typography variant="body2" color="textSecondary">
                                {getLang(lang, "label.PRICE")}
                              </Typography>
                            </Box>
                            {enableEdit ? (
                              <Box style={{ display: "flex" }}>
                                <CurrencySelect
                                  currencyDropdownData={currencyDropdown}
                                  textFieldProps={{
                                    style: {
                                      width: "120px",
                                      marginRight: "10px",
                                      borderRadius: "4px",
                                      backgroundColor: "#ECEFF0"
                                    },
                                    error: formik.errors.currencyCode && formik.touched.currencyCode ? true : false,
                                    helperText: ErrorMessage({ name: "currencyCode" })
                                  }}
                                  defaultValue={{ name: formik.values.currencyCode, alpha3: formik.values.currencyCode ? formik.values.currencyCode : "" }}
                                  formProps={{
                                    ...formik.getFieldProps("currencyCode"),
                                    value: formik.values.currencyCode,
                                    onChange: (e, value) => {
                                      formik.setFieldValue("currencyCode", value)
                                      formik.setFieldValue("currencyExp", value.exp)
                                      formik.setFieldValue("price", Number(formik.values.price).toFixed(value.exp))
                                    },
                                    disabled: formik.isSubmitting
                                  }}
                                  type={"currencyCode"}
                                />
                                <Input
                                  hiddenLabel
                                  variant="filled"
                                  size="small"
                                  margin="none"
                                  placeholder={getLang(lang, "placeholder.ENTER_PRODUCT_PRICE")}
                                  fullWidth
                                  value={formik.values.price}
                                  onChange={(e) => {
                                    let regex = new RegExp('([0-9]*[.|,]{0,1}[0-9]{0,' + formik.values.currencyExp + '})', 's');
                                    let value = e.target.value.match(regex)[0];
                                    formik.setFieldValue("price", value);
                                  }}
                                  error={
                                    formik.touched.price && formik.errors.price ? true : false
                                  }
                                  helperText={ErrorMessage({ name: "price" })}
                                />
                              </Box>
                            ) : (
                              <Typography variant="body2">
                                {formik.values.currencyCode} {Number(formik.values.price).toLocaleString(undefined, { minimumFractionDigits: formik.values.currencyExp, maximumFractionDigits: formik.values.currencyExp })}
                              </Typography>
                            )}
                          </Box>

                          <Box mb={2} className={classes.field}>
                            <Box display="flex" alignItems="center" flexDirection="row">
                              <Box>
                                <Typography variant="body2" color="textSecondary">
                                  {getLang(lang, "label.SCAN_LIMIT")}
                                </Typography>
                              </Box>
                              <Box pl={1}>
                                <Tooltip
                                  title={getLang(
                                    lang,
                                    "paragraph.MAX_SCAN_LIMIT"
                                  )}
                                  arrow
                                >
                                  <HelpOutlinedIcon color="primary" fontSize="small" />
                                </Tooltip>
                              </Box>
                            </Box>
                            {/*<Box display="flex" alignItems="center">*/}
                            {/*  <Box>*/}
                            {/*    <Typography variant="body2" color="textSecondary">*/}
                            {/*      {getLang(lang, "label.SCAN_LIMIT")}*/}
                            {/*    </Typography>*/}
                            {/*    {enableEdit && (*/}
                            {/*      <Typography variant="body2" color="textSecondary" style={{ fontSize: "0.75rem" }}>*/}
                            {/*        {getLang(lang, "paragraph.SCAN_LIMIT_ZERO")}*/}
                            {/*      </Typography>*/}
                            {/*    )}*/}
                            {/*  </Box>*/}
                            {/*</Box>*/}
                            {enableEdit ? (
                              <Box display="flex" alignItems="center" flexDirection="row">
                                <Input
                                  hiddenLabel
                                  variant="filled"
                                  size="small"
                                  margin="none"
                                  type="number"
                                  disabled={isScanLimitDisabled}
                                  InputProps={{
                                    inputProps: { min: 0, max: 1_000_000 },
                                    defaultValue: 0
                                  }}
                                  placeholder={getLang(lang, "placeholder.ENTER_SCAN_LIMIT_COUNT")}
                                  fullWidth
                                  {...formik.getFieldProps("scanLimit")}
                                  error={
                                    formik.touched.scanLimit && formik.errors.scanLimit
                                      ? true
                                      : false
                                  }
                                  helperText={ErrorMessage({ name: "scanLimit" })}
                                  onKeyDown={(e) => {
                                    const invalidChars = [
                                      "-",
                                      "+",
                                      "e",
                                      ".",
                                    ];
                                    if (invalidChars.includes(e.key)) {
                                      e.preventDefault();
                                    }
                                  }}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    if (value.startsWith("0")) {
                                      e.preventDefault();
                                      setIsScanLimitDisabled(e.target.checked);
                                    }
                                    if (value > 1_000_000) {
                                      e.preventDefault();
                                      dispatch(
                                        addAlert({
                                          severity: "warning",
                                          message: getLang(lang, "message.warning.SCAN_LIMIT_EXCEEDED"),
                                        })
                                      );
                                      formik.setFieldValue('scanLimit', 1_000_000);
                                      return;
                                    }
                                    formik.setFieldValue('scanLimit', e.target.value);
                                  }}
                                />
                                <Box display="flex" justifyContent="center" alignItems="center" flexDirection="row" px={2} style={{ width: "100%" }}>
                                  <Switch
                                    defaultChecked={isScanLimitDisabled}
                                    onChange={(e) => {
                                      setIsScanLimitDisabled(e.target.checked);
                                      if (e.target.checked) {
                                        formik.setFieldValue('scanLimit', 0);
                                      }
                                    }}
                                  />
                                  <Box display="flex" alignItems="center" flexDirection="row" pl={2}>
                                    <Box>
                                      <Typography variant="body2" style={{ fontWeight: "bold", fontSize: "0.75rem" }}>
                                        {getLang(lang, "label.DISABLE_SCAN_LIMIT")}
                                      </Typography>
                                    </Box>
                                    <Box pl={1}>
                                      <Tooltip
                                        title={getLang(
                                          lang,
                                          "paragraph.DISABLE_SCAN_LIMIT_TIP"
                                        )}
                                        arrow
                                      >
                                        <HelpOutlinedIcon color="primary" fontSize="small" />
                                      </Tooltip>
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            ) : (
                              <Typography variant="body2">
                                {formik.values.scanLimit.toLocaleString("en-US")}
                              </Typography>
                            )}
                          </Box>

                          <Box mb={2} className={classes.field}>
                            <Box display="flex" alignItems="center">
                              <Box>
                                <Typography variant="body2" color="textSecondary">
                                  {getLang(lang, "label.DEALER_INCENTIVE_POINT")}
                                </Typography>
                                {enableEdit && (
                                  <Typography variant="body2" color="textSecondary" style={{ fontSize: "0.75rem" }}>
                                    {getLang(lang, "paragraph.MORE_SETTING_AT")}
                                  </Typography>
                                )}
                              </Box>
                            </Box>
                            {enableEdit ? (
                              <Input
                                hiddenLabel
                                variant="filled"
                                size="small"
                                margin="none"
                                type="number"
                                InputProps={{
                                  inputProps: { min: 0, max: 9999999 },
                                  defaultValue: 0
                                }}
                                placeholder={getLang(lang, "placeholder.ENTER_INCENTIVE_POINT")}
                                fullWidth
                                {...formik.getFieldProps("incentivePoint")}
                                error={
                                  formik.touched.incentivePoint && formik.errors.incentivePoint
                                    ? true
                                    : false
                                }
                                helperText={ErrorMessage({ name: "incentivePoint" })}
                                onKeyDown={(e) => {
                                  const invalidChars = [
                                    "-",
                                    "+",
                                    "e",
                                    ".",
                                  ];
                                  if (invalidChars.includes(e.key)) {
                                    e.preventDefault();
                                  }
                                }}
                                // onChange={(e) => {
                                //   if (e.target.value > 9999999) {
                                //     e.preventDefault();
                                //   }
                                //   else {
                                //     formik.setFieldValue('incentivePoint', e.target.value).toLocaleString("en-US");
                                    
                                //   }
                                // }}
                              />
                            ) : (
                              <Typography variant="body2">
                                {formik.values.incentivePoint.toLocaleString("en-US")}
                              </Typography>
                            )}
                          </Box>
                        </Box>
                      )}

                      {(section.title === getLang(lang, 'label.WARRANTY_INFO') ||
                        section.title === `${getLang(lang, 'label.WARRANTY_INFO')} (${getLang(lang, 'label.OPTIONAL')})`) && (
                          <Box mt={4} mb={4} px={5} style={{ alignSelf: "center" }}>

                            {formik.values.warrantyInfo.warrantyName || enableEdit ? (
                              <>
                                <Box mb={2} className={classes.field}>
                                  <Box display="flex" alignItems="center">
                                    <Typography variant="body2" color="textSecondary" >
                                      {getLang(lang, "label.WARRANTY_NAME")}
                                    </Typography>
                                  </Box>
                                  {enableEdit ? (
                                    <WarrantySelect
                                      value={formik.values.warrantyInfo}
                                      popperPlacemenet={"top"}
                                      handleChange={(value) => {
                                        formik.setFieldValue("warrantyInfo", value)
                                      }}
                                      isClearable={true}
                                      handleClear={() => {
                                        formik.setFieldValue("warrantyInfo", "")
                                      }}
                                    />
                                  ) : (
                                    <Typography variant="body2">
                                      {formik.values.warrantyInfo.warrantyName ? formik.values.warrantyInfo.warrantyName : '-'}
                                    </Typography>
                                  )}
                                </Box>

                                <Box mb={2} className={classes.field}>
                                  <Box display="flex" alignItems="center">
                                    <Typography variant="body2" color="textSecondary">
                                      {getLang(lang, "label.WARRANTY_PERIOD")}
                                    </Typography>
                                  </Box>
                                  <Typography variant="body2">
                                    {`${formik.values.warrantyInfo.warrantyDuration ? formik.values.warrantyInfo.warrantyDuration : 0} Month(s)`}
                                  </Typography>
                                </Box>
                              </>
                            ) : (
                              <Typography variant="subtitle1">
                                {getLang(lang, "label.WARRANTY_NOT_ASSIGNED")}
                              </Typography>
                            )}
                          </Box>
                        )}

                    </Grid>
                  </Grid>

                  {sectionLength > index + 1 ? (
                    <Box pt={3} pb={2}>
                      <Divider />
                    </Box>
                  ) : null}
                </Box>
              ))}
            </Box>

            {children({
              handleCancel: () => {
                handleCancel(formik)
              },
              renderSubmitButton: (
                <Button
                  id="btn-submit-product"
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disableElevation
                  classes={{ root: classes.btnSecondary }}
                >
                  {getLang(lang, "label.NEXT")}
                </Button>
              )
            })}
          </form>
        )
        }
      </Formik >
  );
}
