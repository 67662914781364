import React, { useEffect, useState } from "react";
import AssignListingComponent from "./assignListing.page";
import { useDispatch, useSelector } from "react-redux";
import { getAssignListing } from "../../redux";
import { selectLoading } from "modules/notification";
import { generatePaginationOptions } from "lib/helper";
import { getAllProducts } from "modules/product-mgmt/redux/action/products.action";
import ExportAssignExcelDialog from "modules/serial-number/components/dialog/exportAssignExcelDialog";
import ConfirmationDialog from "components/dialog/confirmation";
import { getLang } from "app/feature/constants";
import { unwrapResult } from "@reduxjs/toolkit";
import { retryAssignFailJob } from "modules/serial-number/redux/action/assignBatch.action";

const API_HOST = process.env.REACT_APP_API_HOST_URL;

function AssignListingContainer() {
  const dispatch = useDispatch();

  const lang = useSelector((state) => state.constant.languages);
  const language = useSelector((state) => state.profile.language);
  const productDropdown = useSelector((state) => state.products.allProducts);
  const assignListData = useSelector(
    (state) => state.assignBatch.assignBatchTable.data
  );
  const totalRecords = useSelector(
    (state) => state.assignBatch.assignBatchTable.totalRecords
  );
  const totalFiltered = useSelector(
    (state) => state.assignBatch.assignBatchTable.totalFiltered
  );
  const isError = useSelector(
    (state) => state.assignBatch.assignBatchTable.isError
  );
  const isFetching = useSelector((state) =>
    selectLoading(state, getAssignListing.typePrefix)
  );
  const token = useSelector((state) => state.session.accessToken);

  const isSearchResult = totalFiltered < totalRecords;
  const [paginationOptions, setPaginationOptions] = useState([]);
  const [selectedBatch, setSelectedBatch] = useState(null);
  const [selectedRetryBatch, setSelectedRetryBatch] = useState(null);
  const [showExportDialog, setShowExportDialog] = useState(false);
  const [showRetryConfirmDialog, setShowRetryConfirmDialog] = useState(false);
  const [retryLoading, setRetryLoading] = useState(false);
  const [tableFilter, setTableFilter] = useState({
    page: 0,
    rowsPerPage: 25,
    order: "desc",
    orderBy: "created_at",
    search: "",
    products: [],
    product_uuids: [],
  });

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, tableFilter]);

  useEffect(() => {
    dispatch(getAllProducts());
  }, [dispatch]);

  useEffect(() => {
    setPaginationOptions(generatePaginationOptions(totalFiltered));
  }, [totalFiltered]);

  const getData = () => {
    dispatch(
      getAssignListing({
        length: tableFilter.rowsPerPage,
        start: tableFilter.rowsPerPage * tableFilter.page,
        order: tableFilter.order,
        column: tableFilter.orderBy,
        search: tableFilter.search,
        products: tableFilter.product_uuids,
      })
    );
  };

  const handleChangePage = (event, newPage) => {
    let newData = {
      ...tableFilter,
      page: newPage,
    };
    setTableFilter(newData);
  };

  const handleChangeRowsPerPage = (event) => {
    let newData = {
      ...tableFilter,
      page: 0,
      rowsPerPage: event.target.value,
    };
    setTableFilter(newData);
  };

  const handleSearch = (values) => {
    let tempItem = values.products.map((item) => item.id);

    let newData = {
      ...tableFilter,
      page: 0,
      search: values.search,
      products: values.products,
      product_uuids: tempItem,
    };
    setTableFilter(newData);
  };

  const clearSearch = () => {
    let newData = {
      ...tableFilter,
      page: 0,
      search: "",
      products: [],
      product_uuids: [],
    };
    setTableFilter(newData);
  };

  const changeOrder = (order) => {
    let newData = {
      ...tableFilter,
      order,
    };
    setTableFilter(newData);
  };

  const selectBatch = (batch) => {
    setSelectedBatch(batch);
    setShowExportDialog(true);
  };

  const exportFile = (format, fields) => {
    window.open(
      `${API_HOST}/serialnumber/export/v1/assign/${selectedBatch.batchNumber}?format=${format}&fields=${fields}&token=${token}`,
      "_blank"
    );
    setShowExportDialog(false);
    setSelectedBatch(null);
  };

  const selectBatchForRetry = (batch) => {
    setSelectedRetryBatch(batch);
    setShowRetryConfirmDialog(true);
  };

  const retryAssign = () => {
    setRetryLoading(true);
    dispatch(retryAssignFailJob(selectedRetryBatch.uuid))
      .then(unwrapResult)
      .then(() => {
        getData();
      })
      .finally(() => {
        setRetryLoading(false);
        setSelectedRetryBatch(null);
        setShowRetryConfirmDialog(false);
      });
  };

  return (
    <>
      <AssignListingComponent
        assignList={assignListData}
        isFetching={isFetching}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        tableFilter={tableFilter}
        totalFiltered={totalFiltered}
        isError={isError}
        handleSearch={handleSearch}
        handleReload={getData}
        paginationOptions={paginationOptions}
        lang={lang}
        setSelectedBatch={selectBatch}
        isSearchResult={isSearchResult}
        clearSearch={clearSearch}
        changeOrder={changeOrder}
        language={language}
        productDropdown={productDropdown}
        retryAssign={selectBatchForRetry}
      />
      <ExportAssignExcelDialog
        isOpen={showExportDialog}
        handleClose={() => {
          setShowExportDialog(false);
          setSelectedBatch(null);
        }}
        exportFile={exportFile}
        batch={selectedBatch}
      />
      <ConfirmationDialog
        isLoading={retryLoading}
        isOpen={showRetryConfirmDialog}
        handleClose={() => setShowRetryConfirmDialog(false)}
        handleProceed={retryAssign}
        title={getLang(lang, "label.RETRY_SERIAL_NUMBER_ASSIGN")}
        description={getLang(lang, "paragraph.ASSIGN_SN_RETRY_CONFIRMATION")}
        type="default"
        proceedLabel={getLang(lang, "label.RETRY")}
      />
    </>
  );
}

export default AssignListingContainer;
