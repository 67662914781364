import React from "react";
import {
  makeStyles,
  Box,
} from "@material-ui/core";
import AccountInfoTab from "../../tab/accountInfoTab";
import CompanyLogoPanel from "../companyLogoPanel";

const style = theme => ({
  root: {
    display: "grid",
    gridTemplateColumns: "7fr 3fr",
    gridTemplateRows: "auto auto",
    columnGap: theme.spacing(2),
    gridTemplateAreas: "'panel photo'",
    [theme.breakpoints.down("md")]: {
      gridTemplateAreas: "'panel photo'"
    },
    [theme.breakpoints.down("sm")]: {
      gridTemplateAreas: "'panel panel''photo photo'"
    }
  },
  indicator: {
    border: "0.5px solid",
    borderColor: theme.palette.primary.main
  },
  scroll: {
    width: "min-content"
  }
})

const useStyles = makeStyles(style);

export default function CompanyInfoPanelComponent() {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box gridArea="panel">
        <AccountInfoTab />
      </Box>
      <Box gridArea="photo">
        <CompanyLogoPanel />
      </Box>
    </Box>
  )
}