import {
  createBranchGroup,
  deleteBranchGroup,
  editBranchGroup,
  fetchGroupList,
  getAllAvailableBranchesForRules,
  getAvailableBranches,
  getBranchesInGroup,
  getGroupRules,
  getGroupSummary,
  updateGroupBranches,
  updateGroupRules,
} from "./group.action";
import { addAlert, setLoading } from "modules/notification";
import { getApiLang } from "app/feature/constants";

const fetchGroupListMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case fetchGroupList.pending.type:
        dispatch(setLoading({ id: fetchGroupList.typePrefix, state: true }));
        break;
      case fetchGroupList.fulfilled.type:
        dispatch(setLoading({ id: fetchGroupList.typePrefix, state: false }));
        break;
      case fetchGroupList.rejected.type:
        const lang = getState().constant.languages;
        dispatch(setLoading({ id: fetchGroupList.typePrefix, state: false }));
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

const createGroupMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages;

    switch (type) {
      case createBranchGroup.pending.type:
        dispatch(setLoading({ id: createBranchGroup.typePrefix, state: true }));
        break;
      case createBranchGroup.fulfilled.type:
        dispatch(
          setLoading({ id: createBranchGroup.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "success",
            message: getApiLang(lang, "success.SUCCESS_CREATE_BRANCH_GROUP"),
          })
        );
        break;
      case createBranchGroup.rejected.type:
        dispatch(
          setLoading({ id: createBranchGroup.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

const getGroupSummaryMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getGroupSummary.pending.type:
        dispatch(setLoading({ id: getGroupSummary.typePrefix, state: true }));
        break;
      case getGroupSummary.fulfilled.type:
        dispatch(setLoading({ id: getGroupSummary.typePrefix, state: false }));
        break;
      case getGroupSummary.rejected.type:
        const lang = getState().constant.languages;
        dispatch(setLoading({ id: getGroupSummary.typePrefix, state: false }));
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_GET_BRANCH_GROUP_DETAIL"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const updateGroupBranchesMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages;

    switch (type) {
      case updateGroupBranches.pending.type:
        dispatch(
          setLoading({ id: updateGroupBranches.typePrefix, state: true })
        );
        break;
      case updateGroupBranches.fulfilled.type:
        dispatch(
          setLoading({ id: updateGroupBranches.typePrefix, state: false })
        );
        break;
      case updateGroupBranches.rejected.type:
        dispatch(
          setLoading({ id: updateGroupBranches.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

const getAvailableBranchesMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages;

    switch (type) {
      case getAvailableBranches.pending.type:
        dispatch(
          setLoading({ id: getAvailableBranches.typePrefix, state: true })
        );
        break;
      case getAvailableBranches.fulfilled.type:
        dispatch(
          setLoading({ id: getAvailableBranches.typePrefix, state: false })
        );
        break;
      case getAvailableBranches.rejected.type:
        dispatch(
          setLoading({ id: getAvailableBranches.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

const deleteBranchGroupMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages;

    switch (type) {
      case deleteBranchGroup.pending.type:
        dispatch(setLoading({ id: deleteBranchGroup.typePrefix, state: true }));
        break;
      case deleteBranchGroup.fulfilled.type:
        dispatch(
          setLoading({ id: deleteBranchGroup.typePrefix, state: false })
        );
        break;
      case deleteBranchGroup.rejected.type:
        dispatch(
          setLoading({ id: deleteBranchGroup.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

const editBranchGroupMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages;

    switch (type) {
      case editBranchGroup.pending.type:
        dispatch(setLoading({ id: editBranchGroup.typePrefix, state: true }));
        break;
      case editBranchGroup.fulfilled.type:
        dispatch(setLoading({ id: editBranchGroup.typePrefix, state: false }));
        break;
      case editBranchGroup.rejected.type:
        dispatch(setLoading({ id: editBranchGroup.typePrefix, state: false }));
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

const getAllAvailableBranchesForRulesMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages;

    switch (type) {
      case getAllAvailableBranchesForRules.pending.type:
        dispatch(
          setLoading({
            id: getAllAvailableBranchesForRules.typePrefix,
            state: true,
          })
        );
        break;
      case getAllAvailableBranchesForRules.fulfilled.type:
        dispatch(
          setLoading({
            id: getAllAvailableBranchesForRules.typePrefix,
            state: false,
          })
        );
        break;
      case getAllAvailableBranchesForRules.rejected.type:
        dispatch(
          setLoading({
            id: getAllAvailableBranchesForRules.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

const getBranchesInGroupMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages;

    switch (type) {
      case getBranchesInGroup.pending.type:
        dispatch(
          setLoading({
            id: getBranchesInGroup.typePrefix,
            state: true,
          })
        );
        break;
      case getBranchesInGroup.fulfilled.type:
        dispatch(
          setLoading({
            id: getBranchesInGroup.typePrefix,
            state: false,
          })
        );
        break;
      case getBranchesInGroup.rejected.type:
        dispatch(
          setLoading({
            id: getBranchesInGroup.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

const getGroupRulesMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages;

    switch (type) {
      case getGroupRules.pending.type:
        dispatch(setLoading({ id: getGroupRules.typePrefix, state: true }));
        break;
      case getGroupRules.fulfilled.type:
        dispatch(setLoading({ id: getGroupRules.typePrefix, state: false }));
        break;
      case getGroupRules.rejected.type:
        dispatch(setLoading({ id: getGroupRules.typePrefix, state: false }));
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

const updateGroupRulesMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages;

    switch (type) {
      case updateGroupRules.pending.type:
        dispatch(setLoading({ id: updateGroupRules.typePrefix, state: true }));
        break;
      case updateGroupRules.fulfilled.type:
        dispatch(setLoading({ id: updateGroupRules.typePrefix, state: false }));
        break;
      case updateGroupRules.rejected.type:
        dispatch(setLoading({ id: updateGroupRules.typePrefix, state: false }));
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_UPDATE_GROUP_RULES"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

export const groupMiddleware = [
  fetchGroupListMiddleware,
  createGroupMiddleware,
  getGroupSummaryMiddleware,
  updateGroupBranchesMiddleware,
  getAvailableBranchesMiddleware,
  deleteBranchGroupMiddleware,
  getGroupRulesMiddleware,
  getAllAvailableBranchesForRulesMiddleware,
  getBranchesInGroupMiddleware,
  updateGroupRulesMiddleware,
  editBranchGroupMiddleware,
];
