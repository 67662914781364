import axios from "axios";

const getRangeListByBranch = (branchUuid) => {
  return new Promise((resolve, reject) => {
    axios
      .get("/serialnumber/api/v1/generate/range-list-by-branch/" + branchUuid)
      .then(response => resolve(response.data))
      .catch(error => {
        return reject(error.response.data);
      });
  });
};

export default getRangeListByBranch;
