import axios from "axios";

const endpoint_url = "/serialnumber/api/v1/serialnumber/assign/list";

const getAssignListing = (parameters) => {
  return new Promise((resolve, reject) => {
    axios
      .post(endpoint_url, {
        length: parameters.length,
        start: parameters.start,
        search: parameters.search,
        order: parameters.order,
        column: parameters.column,
        products: parameters.products,
      })
      .then((response) => resolve(response))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

export default getAssignListing;
