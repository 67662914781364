import React from "react";
import {
  makeStyles,
  Typography,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  Box,
  Divider,
  InputAdornment,
  Button,
  MenuItem,
  MenuList
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import PerfectScrollbar from "react-perfect-scrollbar";
import InputTextField from "components/input/inputTextField";
import Skeleton from "@material-ui/lab/Skeleton";
import AntSwitch from "components/switch/switch";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles((theme) => ({
  paper: {
    width: 385
  },
  button: {
    padding: 0,
    minWidth: 0
  },
  buttonAddWarranty: {
    display: "flex",
    padding: 0,
    minWidth: 0
  },
  createLink: {
    padding: theme.spacing(2)
  }
}));

export default function PrizePanelComponent({
  open,
  anchorRef,
  handleClose,
  handleListKeyDown,
  isOpenList,
  productDropdown,
  isFetching,
  value,
  lang
}) {
  const classes = useStyle();
  return (
    <Popper
      open={open}
      style={{ zIndex: 99 }}
      anchorEl={anchorRef.current}
      role={undefined}
      transition
      disablePortal
      placement="bottom-start"
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === "bottom-start" ? "center top" : "center bottom",
          }}
        >
          <Paper className={classes.paper}>
            <ClickAwayListener onClickAway={handleClose}>
              <Box>
                <Box>
                  <InputTextField
                    variant="filled"
                    size="small"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          style={{ paddingRight: "6px" }}
                        >
                          <Button className={classes.button}>
                            <SearchIcon fontSize="small" />
                          </Button>
                        </InputAdornment>
                      ),
                      style: {
                        backgroundColor: "#ECEFF0",
                      },
                    }}
                    inputProps={{
                      style: {
                        padding: "14px",
                      },
                    }}
                    placeholder={getLang(lang,"placeholder.SELECT_PRODUCT")}
                    // {...formik.getFieldProps("search")}
                  />
                </Box>

                <Divider />

                <PerfectScrollbar>
                  {isFetching ? (
                    <>
                      <Skeleton variant="text" animation="wave" />
                      <Skeleton variant="text" animation="wave" />
                      <Skeleton variant="text" animation="wave" />
                      <Skeleton variant="text" animation="wave" />
                    </>
                  ) : (
                    productDropdown.map((field, index) => {
                      return (
                        <Box key={index}>
                          <MenuList
                            autoFocusItem={isOpenList}
                            id="menu-list-grow"
                            onClick={() => {
                              handleListKeyDown(field);
                            }}
                          >
                            <MenuItem>
                              <AntSwitch
                                checked={
                                  value.find((v) => v.id === field.id)
                                    ? true
                                    : false
                                }
                              />
                              <Typography
                                variant="inherit"
                                noWrap
                                style={{ paddingLeft: "8px" }}
                              >
                                {field.name}
                              </Typography>
                            </MenuItem>
                          </MenuList>
                        </Box>
                      );
                    })
                  )}
                  <Divider />
                </PerfectScrollbar>
              </Box>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}
