import React from "react";
import { useSelector } from "react-redux"
import { selectLoading } from "modules/notification";
import { fetchRespondentDetails } from "modules/survey/redux/action";
import ViewRespondentDialogComponent from "./viewRespondentDialog.component";

export default function ViewRespondentDialogContainer({
  isOpen,
  handleClose
}) {
  const isLoading = useSelector(state => selectLoading(state, fetchRespondentDetails.typePrefix))
  const respondent = useSelector(state => state.survey.respondent)
  const lang = useSelector(state => state.constant.languages);
  const language = useSelector(state => state.profile.language);

  return (
    <ViewRespondentDialogComponent 
      isOpen={isOpen}
      handleClose={handleClose}
      respondent={respondent}
      isLoading={isLoading}
      lang={lang}
      language={language}
    />
  )
}