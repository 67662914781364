import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';

export default function LoadingComponent({ hide = false, height = "100%", color = "primary", style }) {
    return (
        <Box style={{
            display: hide ? "none" : "flex",
            alignItems: "center",
            justifyContent: "center",
            height
        }}>
            <CircularProgress color={color} style={style} />
        </Box>
    );
}