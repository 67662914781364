import { setLoading, addAlert } from "modules/notification";
import { getDetailsSerialNumber, resetSNScanCount } from "../action";
import { getApiLang } from "app/feature/constants";

export const getDetailsSerialNumberMiddleware = ({ dispatch, getState }) => (next) => (
  action
) => {
  next(action);

  const { type, payload } = action;
  switch (type) {
    case getDetailsSerialNumber.pending.type:
      dispatch(
        setLoading({ id: getDetailsSerialNumber.typePrefix, state: true })
      );
      break;

    case getDetailsSerialNumber.fulfilled.type:
      dispatch(
        setLoading({ id: getDetailsSerialNumber.typePrefix, state: false })
      );
      break;

    case getDetailsSerialNumber.rejected.type:
      const lang = getState().constant.languages
      dispatch(
        setLoading({ id: getDetailsSerialNumber.typePrefix, state: false })
      );
      dispatch(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
      break;

    default:
      break;
  }
};

export const resetScanCountMiddleware = ({ dispatch, getState }) => (next) => (
  action
) => {
  next(action);

  const { type, payload } = action;
  switch (type) {
    case resetSNScanCount.pending.type:
      dispatch(
        setLoading({ id: resetSNScanCount.typePrefix, state: true })
      );
      break;

    case resetSNScanCount.fulfilled.type:
      dispatch(
        setLoading({ id: resetSNScanCount.typePrefix, state: false })
      );
      break;

    case resetSNScanCount.rejected.type:
      const lang = getState().constant.languages
      dispatch(
        setLoading({ id: resetSNScanCount.typePrefix, state: false })
      );
      dispatch(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
      break;

    default:
      break;
  }
};

export default [
  resetScanCountMiddleware,
  getDetailsSerialNumberMiddleware,
];
