import axios from "axios"

const updateBulkDealerStatus = ({
  selectedUUIDs,
  status,
  isSelectAll,
  filter
}) => {
  return new Promise((resolve, reject) => {
    axios.post("/distributor/admin/api/v1/dealers/bulk-update-status", {
      dealer_uuids: selectedUUIDs,
      status: status,
      is_select_all: isSelectAll,
      filter: filter
    })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data))
  });
};

export default updateBulkDealerStatus;