import React from "react";
import {
  makeStyles,
  Typography,
  Paper,
  ClickAwayListener,
  Box,
  Divider,
  Popover,
  // InputAdornment,
  Button,
  MenuItem,
  MenuList
} from "@material-ui/core";
// import SearchIcon from "@material-ui/icons/Search";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import PerfectScrollbar from "react-perfect-scrollbar";
// import InputTextField from "components/input/inputTextField";
import Skeleton from "@material-ui/lab/Skeleton";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles((theme) => ({
  paper: {
    width: 400
  },
  button: {
    padding: 0,
    minWidth: 0
  },
  buttonAddWarranty: {
    display: "flex",
    padding: 0,
    minWidth: 0
  },
  createLink: {
    padding: theme.spacing(2),
  }
}));

export default function WarrantyPanelComponent({
  hasWarrantyAddAccessRight,
  open,
  anchorRef,
  handleClose,
  handleListKeyDown,
  isOpenList,
  handleAddWarranty,
  warrantyNameDropdown,
  isFetching,
  lang,
  popperPlacemenet
}) {
  const classes = useStyle();

  return (
    <Popover
      open={open}
      anchorEl={anchorRef.current}
      role={undefined}
      transition
      disablePortal
      anchorOrigin={{
        vertical: popperPlacemenet === 'top' ? 'top' : 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: popperPlacemenet === 'top' ? 'bottom' : 'top',
        horizontal: 'left',
      }}
    >
      <Paper className={classes.paper}>
        <ClickAwayListener onClickAway={handleClose}>
          <Box>
            {/* <Box>
                  <InputTextField
                    variant="filled"
                    size="small"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          style={{ paddingRight: "6px" }}
                        >
                          <Button className={classes.button}>
                            <SearchIcon fontSize="small" />
                          </Button>
                        </InputAdornment>
                      ),
                      style: {
                        backgroundColor: "#ECEFF0"
                      }
                    }}
                    inputProps={{
                      style: {
                        padding: "14px"
                      }
                    }}
                    placeholder="Search Warranty"
                    // {...formik.getFieldProps("search")}
                  />
                </Box> */}

            <Divider />

            <PerfectScrollbar>
              {isFetching ? (
                <>
                  <Skeleton variant="text" animation="wave" />
                  <Skeleton variant="text" animation="wave" />
                  <Skeleton variant="text" animation="wave" />
                  <Skeleton variant="text" animation="wave" />
                </>
              ) : (
                warrantyNameDropdown.map((field, index) => {
                  return (
                    <Box key={index}>
                      <MenuList
                        autoFocusItem={isOpenList}
                        id="menu-list-grow"
                        onClick={() => handleListKeyDown(field)}
                      >
                        <MenuItem>
                          <Typography variant="inherit" noWrap>
                            {field.warrantyName}
                          </Typography>
                        </MenuItem>
                      </MenuList>
                    </Box>
                  );
                })
              )}
              {
                hasWarrantyAddAccessRight
                  ?
                  <>
                    <Divider />
                    <Box className={classes.createLink}>
                      <Button
                        color="primary"
                        className={classes.buttonAddWarranty}
                        onClick={handleAddWarranty}
                      >
                        <Typography
                          variant="body2"
                          color="primary"
                          style={{ fontWeight: "bold", paddingRight: "4px" }}
                        >
                          {getLang(lang, "label.ADD_NEW_WARRANTY")}
                        </Typography>
                        <ArrowForwardIcon fontSize="small" />
                      </Button>
                    </Box>
                  </>
                  : null
              }
            </PerfectScrollbar>
          </Box>
        </ClickAwayListener>
      </Paper>
    </Popover >
  );
}
