import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  makeStyles,
  Typography
} from "@material-ui/core";
import SuccessImage from "assets/img/generate-success-illustration.png";
import { useSelector } from "react-redux";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles(theme => ({
  root: {
    textAlign: "center",
    padding: "35px 30px"
  },
  customDialogActionRoot: {
    justifyContent: "flex-end",
    padding: "15px"
  },
  customDialogTitle: {
    fontWeight: "bold"
  },
  customDialogPaperWithXs: {
    maxWidth: 450
  },
  dialogRemarksCaption: {
    fontWeight: 200,
    marginLeft: 5
  },
  dialogRemarks: {
    color: "#FD646F"
  },
  dangerDialog: {
    backgroundColor: "#FD646F",
    color: "white !important"
  },
  dangerCancelButton: {
    color: "white",
    borderColor: "white !important"
  },
  dangerText: {
    color: "white"
  },
  infoTitle: {
    color: "#3a4d5454",
    fontSize: 12
  },
  infoValue: {
    fontWeight: 600
  },
  buttonLabel: {
    color: "white"
  },
  image: {
    width: 175
  }
}));

export default function GenerateSuccessDialogComponent({
  registrationType,
  isOpen,
  handleProceed,
  batchID,
  creditUsed,
  remainingCredit
}) {
  const classes = useStyle();
  const lang = useSelector(state => state.constant.languages)
  
  return (
    <Dialog
      classes={{
        paperWidthXs: classes.customDialogPaperWithXs
      }}
      open={isOpen}
      aria-labelledby="confirmation-dialog"
      maxWidth="xs"
    >
      <Box
        style={{
          display: "flex",
          alignItems: "flex-start"
        }}
      >
        <Box>
          <DialogTitle>
            <Typography
              color="primary"
              variant="body1"
              classes={{
                root: classes.customDialogTitle
              }}
            >
              {getLang(lang, 'label.THANK_YOU')}
            </Typography>
          </DialogTitle>
          <DialogContent style={{ marginBottom: 5 }}>
            <Typography color="primary" variant="body2" component={"div"}>
              {getLang(lang, 'paragraph.GENERATING_SERIAL_NUMBER')}
              <br />
              <br />
              {getLang(lang, 'paragraph.NOTIFY_YOU_VIA_EMAIL')}
            </Typography>
          </DialogContent>
        </Box>
        <img src={SuccessImage} alt="success" className={classes.image} />
      </Box>
      <Divider />
      <Grid
        container
        style={{
          padding: "15px 24px"
        }}
      >
        <Grid item xs={5}>
          <Typography classes={{ root: classes.infoTitle }}>
            {getLang(lang, 'label.BATCH_ID')}
          </Typography>
          <Typography classes={{ root: classes.infoValue }} color="primary">
            {batchID}
          </Typography>
        </Grid>
        {
          registrationType !== 3
            ?
            (
              <>
                <Grid item xs={3}>
                  <Typography classes={{ root: classes.infoTitle }}>
                    {getLang(lang, 'label.CREDIT_USED')}
                  </Typography>
                  <Typography classes={{ root: classes.infoValue }} color="primary">
                    {creditUsed}
                  </Typography>
                </Grid>
                <Divider orientation="vertical" flexItem />
                <Grid item xs style={{ paddingLeft: 20 }}>
                  <Typography classes={{ root: classes.infoTitle }}>
                    {getLang(lang, 'label.REMAINING_CREDIT')}
                  </Typography>
                  <Typography classes={{ root: classes.infoValue }} color="secondary">
                    {remainingCredit}
                  </Typography>
                </Grid>
              </>
            )
            : null
        }

      </Grid>
      <Divider />
      <DialogActions classes={{ root: classes.customDialogActionRoot }}>
        <Button
          onClick={handleProceed}
          color="secondary"
          variant="contained"
          autoFocus
          classes={{
            label: classes.buttonLabel
          }}
        >
          {getLang(lang, 'label.OK')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
