import React from "react";
import { Box, Button, makeStyles, Typography } from "@material-ui/core";
//import ProductSelect from "modules/serial-number/components/select/productSelect";
import ProductSearch from "modules/stockflow/components/select/productSearch";
import { getLang } from "app/feature/constants";
import { Formik } from "formik";
import AdditionalInfoSearchDialog from "../../dialog/additionalInfoSearchDialog";
import AdtProductListTable from "../../table/adtProductListTable";

const useStyle = makeStyles((theme) => ({
  searchContainer: {
    backgroundColor: theme.palette.background.paper,
    display: "grid",
    gridTemplateColumns: "7.5fr 2.5fr 3.5fr 1fr 1fr",
    columnGap: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1),
    },
  },
  inputRoot: {
    fontWeight: 500,
    paddingTop: "8px !important",
    paddingBottom: "7px !important",
    paddingLeft: "10px !important",
  },
  onFocusBorder: {
    borderRadius: 4,
    outlineWidth: "1px",
    outlineStyle: "solid",
    outlineColor: theme.palette.secondary.main,
  },
  colorIsSelected: {
    background: "#EBEDEE",
  },
  icon: {
    borderRight: "1px solid #3A4D5430",
  },
  dropdownSearchBox: {
    backgroundColor: "#E9EDEF",
    borderRadius: 4,
    width: "100%",
    alignItems: "center",
  },
  select: {
    [theme.breakpoints.down("xs")]: {
      minWidth: "50%",
    },
  },
  divider: {
    height: "70%",
    placeSelf: "center",
  },
  textFieldInput: {
    padding: "7px",
    paddingTop: "8px",
    fontWeight: 500,
    color: theme.palette.primary.main,
    [theme.breakpoints.down("xs")]: {
      minWidth: "50%",
    },
  },
  menuItemText: {
    padding: 10,
    fontWeight: 500,
  },
  filterContainer: {
    backgroundColor: theme.palette.background.paper,
    padding: "0px 16px 12px",
    display: "grid",
    gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
    columnGap: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1),
    },
  },
  infoControlSection: {
    border: "1px solid #c4c4c4",
    borderRadius: 4,
    padding: 4,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  emptyContainer: {
    backgroundColor: "white",
    borderRadius: 4,
    padding: 16,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "calc(100dvh - 365px)",
  },
}));

export default function AdtProductListingPanelComponent({
  productList,
  handleChangePage,
  handleChangeRowsPerPage,
  isFetching,
  language,
  handleSearch,
  clearSearch,
  handleReload,
  productDropdown,
  lang,
  tableFilter,
  handleViewDetail,
  additionalFields,
  isError,
  hasNextPage,
}) {
  const classes = useStyle();

  return additionalFields.length ? (
    <>
      <Formik
        initialValues={{
          products: tableFilter.products,
          productNotAssigned: tableFilter.productNotAssigned,
          additionalInfo: tableFilter.additionalInfo,
        }}
        enableReinitialize
        onSubmit={handleSearch}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <Box
              p={2}
              className={classes.searchContainer}
              style={{
                gridTemplateColumns: "7.5fr 2.5fr 1fr 1fr",
              }}
            >
              <ProductSearch
                searchable={true}
                value={formik.values.products}
                placeholder={getLang(lang, "placeholder.SEARCH_BY_PRODUCT")}
                searchPlaceholder={getLang(
                  lang,
                  "placeholder.SEARCH_A_PRODUCT_NAME"
                )}
                isClearable={false}
                emptyMessage={getLang(lang, "message.info.NO_PRODUCT_FOUND")}
                dropdownItem={productDropdown}
                isEmpty={formik.values.products?.length === 0}
                selectedProduct={formik.values.products}
                style={{
                  padding: "0.25em 0.75em",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderRadius: 4,
                  width: "100%",
                  overflow: "hidden",
                  height: 38,
                }}
                handleSelectAll={async (selectAll) => {
                  if (selectAll) {
                    await formik.setFieldValue("products", productDropdown);
                  } else {
                    await formik.setFieldValue("products", []);
                  }
                }}
                handleSelectNotAssigned={(selectNotAssign) => {
                  formik.setFieldValue("products", []);
                  formik.setFieldValue("productNotAssigned", selectNotAssign);
                }}
                handleChange={(value) => {
                  formik.setFieldValue("productNotAssigned", false);

                  let temp = [...formik.values.products];

                  if (temp.length === 0) {
                    temp.push(value);
                  } else {
                    const isProductExist = temp.find(
                      (product) => product.id === value.id
                    );

                    if (isProductExist === undefined) {
                      temp.push(value);
                    } else {
                      let filter = temp.filter(
                        (product) => product.id !== value.id
                      );

                      temp = [...filter];
                    }
                  }
                  formik.setFieldValue("products", [...temp]);
                }}
                handleChipDelete={(product) => {
                  let temp = [...formik.values.products];
                  if (temp.length > 0) {
                    const filteredProducts = temp.filter(
                      (item) => item.id !== product.id
                    );
                    temp = [...filteredProducts];
                  }
                  formik.setFieldValue("products", temp);
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                disabled={isFetching}
              />
              <AdditionalInfoSearchDialog
                additionalFields={additionalFields}
                values={formik.values.additionalInfo}
                disabled={isFetching}
                handleChange={(values) => {
                  formik.setFieldValue(
                    "additionalInfo",
                    values.additionalValues
                  );
                }}
                handleClear={() => {
                  formik.setFieldValue("additionalInfo", []);
                }}
              />
              <Button
                variant="text"
                disableElevation
                color="primary"
                size="small"
                onClick={() => {
                  clearSearch();
                }}
              >
                {getLang(lang, "label.CLEAR")}
              </Button>
              <Button
                variant="contained"
                disableElevation
                color="primary"
                size="small"
                type="submit"
              >
                {getLang(lang, "label.SEARCH")}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
      <Box mt={2}>
        <AdtProductListTable
          isFetching={isFetching}
          productList={productList}
          rowsPerPage={tableFilter.length}
          page={tableFilter.start}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          language={language}
          handleReload={handleReload}
          lang={lang}
          handleViewDetail={handleViewDetail}
          additionalFields={additionalFields}
          isError={isError}
          hasNextPage={hasNextPage}
        />
      </Box>
    </>
  ) : (
    <Box className={classes.emptyContainer}>
      <Typography variant="body1">
        {getLang(lang, "paragraph.PLEASE_SELECT_ADT_INFO_VIEW_RESULT")}
      </Typography>
    </Box>
  );
}
