import { Box, makeStyles } from "@material-ui/core";
import { getLang } from "app/feature/constants";
import EmptyChart from "modules/reporting/components/skeleton/global/empty-chart";
import React from "react";
import { useSelector } from "react-redux";


const useStyles = makeStyles({
    checkboxChartCard: {
        padding: "20px",
        backgroundColor: "#FFFFFF",
        borderRadius: "12px",
        border: "1px solid #D0D5DD"
    },
    item: {
        position: "relative",
        borderRadius: "12px",
        border: "1px solid #D0D5DD"
    },
    item__behind: {
        zIndex: 0,
        opacity: 0.2,
    },
    item__front: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    item__title: {
        fontFamily: "Roboto",
        fontWeight: "700",
        fontSize: "16px",
        lineHeight: "24px",
        color: '#101828',
        textAlign: "center"
    },
    item__value: {
        fontFamily: "Roboto",
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "24px",
        color: '#98A2B3',
        textAlign: "center"
    },
    table__cell: {
        fontFamily: "Roboto",
        border: "1px solid #F2F4F7",
    },
})

export default function DealerInvitedNoData({
    title, value
}) {
    const classes = useStyles();
    const lang = useSelector(state => state.constant.languages)

    return (
        <Box className={classes.checkboxChartCard}>
            <EmptyChart
                data={[getLang(lang, "lanel.DEALER_INVITED")]}
                title={title}
                value={value}
                labels= {['10 July', '11 July', '12 July', '13 July']}
            />
        </Box>
    );
}