import React, { useState, useEffect } from "react"
import AddCurrencyConfigDialogComponent from "./addCurrencyConfigDialog.component"
import { useDispatch, useSelector } from 'react-redux';
import { getUnusedCurrencyConfigData } from "../../../redux";

function AddCurrencyConfigDialogContainer({
  isOpen,
  handleClose,
  handleSubmit,
  handleCurrencyCodeSearch,
  isLoadingUnusedCurrencyError,
  isNewCurrencySelected,
  setIsNewCurrencySelected
}) {
  const dispatch = useDispatch();
  const currencyDropdownData = useSelector(state => state.account.currencyConfig.unusedCurrency);
  const [search, setSearch] = useState("");
  const lang = useSelector(state => state.constant.languages)

  useEffect(() => {
    if(isOpen) {
      setSearch("");
      dispatch(getUnusedCurrencyConfigData({
        start: 0,
        length: 10,
        search: ""
      }))
    }
  }, [dispatch, isOpen]);

  const handleFetchMoreCurrencyConfigData = () => {
    dispatch(getUnusedCurrencyConfigData({
      search: search
    }))
  }

  const handleSearch = (value) => {
    setSearch(value);
    dispatch(getUnusedCurrencyConfigData());
  }
    
  return (
    <AddCurrencyConfigDialogComponent
      isOpen={isOpen}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
      handleCurrencyCodeSearch={handleCurrencyCodeSearch}
      currencyDropdownData={currencyDropdownData.data}
      handleFetchMoreItems={handleFetchMoreCurrencyConfigData}
      handleSearch={handleSearch}
      totalFilter={currencyDropdownData.filteredRecords}
      totalRecords={currencyDropdownData.totalRecords}
      isNewCurrencySelected={isNewCurrencySelected}
      handleSelectAddCurrency={setIsNewCurrencySelected}
      lang={lang}
    />
  )
}

export default AddCurrencyConfigDialogContainer;