import { createAsyncThunk } from "@reduxjs/toolkit";
import { SERIAL_NUMBER_TAG } from "../../constants";
import serialNumberApi from "app/api/serialnumber";

export const getTagSerialNumber = createAsyncThunk(
  `${SERIAL_NUMBER_TAG}/getTagSerialNumber`,
  async (payload, thunkApi) => {
    return serialNumberApi.tagSerialNumber
      .get(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const createTagSerialNumber = createAsyncThunk(
  `${SERIAL_NUMBER_TAG}/createTagSerialNumber`,
  async (payload, thunkApi) => {
    return serialNumberApi.tagSerialNumber
      .post({
        prefix: payload.prefix,
        snDigit: payload.snDigit,
        postfix: payload.postfix,
        serialType: payload.serialType,
      })
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const fetchTagsDropdown = createAsyncThunk(
  `${SERIAL_NUMBER_TAG}/fetchTagsDropdown`,
  async (payload, thunkApi) => {
    return serialNumberApi
      .getTagsDropdown()
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error.response.data));
  }
);

export const getTagDetail = createAsyncThunk(
  `${SERIAL_NUMBER_TAG}/getTagDetail`,
  async (payload, thunkApi) => {
    return serialNumberApi
      .getTagDetail(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);
