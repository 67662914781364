import React from "react";
import PropTypes from "prop-types";
import ConfirmationDialogComponent from "./remarksDialog.component";
import { useState } from "react";
import { useSelector } from "react-redux";
import { getLang } from "app/feature/constants";

function ConfirmationDialogContainer({
  isLoading,
  isOpen,
  handleClose,
  handleProceed,
  title,
  description,
  employeeName
}) {
  const lang = useSelector(state => state.constant.languages);
  const [rejectRemarks, setRejectRemarks] = useState([
    {
      name: getLang(lang, "paragraph.EMPLOYEE_NO_WITH_COMPANY"),
      checked: false
    }, {
      name: getLang(lang, "paragraph.EMPLOYEE_NO_NEED_ACCESS"),
      checked: false
    }, {
      name: getLang(lang, "label.OTHERS"),
      checked: false
    },
  ]);

  return (
    <ConfirmationDialogComponent
      isLoading={isLoading}
      isOpen={isOpen}
      handleClose={handleClose}
      handleProceed={handleProceed}
      title={title}
      description={description}
      employeeName={employeeName}
      rejectRemarks={rejectRemarks}
      setRejectRemarks={setRejectRemarks}
      lang={lang}
    />
  );
}

ConfirmationDialogContainer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleProceed: PropTypes.func.isRequired
};

export default ConfirmationDialogContainer;