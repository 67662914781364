import React, { useEffect, useState, useRef } from "react";
import ListingComponent from "./listing.page";
import { connect, useDispatch, useSelector } from "react-redux";
import { fetchTableLength, getListingSerialNumber } from "../../redux";
import { selectLoading } from "modules/notification";
import {
  SERIAL_NUMBER_GENERATE,
  SERIAL_NUMBER_EDIT,
} from "lib/constants/accessRights";
import { getSnSummaryCount, updateSNStatus } from "../../redux/action";
import { unwrapResult } from "@reduxjs/toolkit";
import ConfirmationDialog from "components/dialog/confirmation";
import { generatePaginationOptions, updateUrlQueryParam } from "lib/helper";
import { getAllProducts } from "modules/product-mgmt/redux/action/products.action";
import { getLang } from "app/feature/constants";
import { useHistory } from "react-router-dom";

function ListingContainer({
  fetchTableLength,
  listingSerialNumberData,
  length,
  filteredlength,
  getListingSerialNumber,
  updateSNStatus,
  isUpdatingSNStatus,
  isSnLoading,
  summaryCount,
  isSnCountLoading,
  isError,
}) {
  const dispatch = useDispatch();
  const language = useSelector((state) => state.profile.language);
  const history = useHistory();
  const hasSerialNumberGenerateAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(SERIAL_NUMBER_GENERATE)
  );
  const hasSerialNumberEditAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(SERIAL_NUMBER_EDIT)
  );
  const productDropdown = useSelector((state) => state.products.allProducts);
  const lang = useSelector((state) => state.constant.languages);
  const isSearchResult = filteredlength < length;
  const [isDeactivateDialogOpen, setIsDeactivateDialogOpen] = useState(false);
  const [isActivateDialogOpen, setIsActivateDialogOpen] = useState(false);
  const [changeStatusTargetEnc, setChangeStatusTargetEnc] = useState("");
  const [paginationOptions, setPaginationOptions] = useState([]);
  const [isFilterUpdated, setIsFilterUpdated] = useState(false);
  const [snTableFilter, setSnTableFilter] = useState({
    length: 25,
    start: 0,
    search: "",
    status: "",
    searchColumn: "serial_number",
    node_uuids: "",
    product_uuids: "",
    products: [],
    productNotAssigned: false,
    branch: [],
  });

  const query = new URLSearchParams(window.location.search);
  const tableFilterInitialRef = useRef(true);
  const [urlLoaded, setUrlLoaded] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [lengthFilter, setLengthFilter] = useState({
    search: snTableFilter.search,
    searchColumn: snTableFilter.searchColumn,
    nodes: snTableFilter.node_uuids,
    products: snTableFilter.product_uuids,
    status: snTableFilter.status,
    productNotAssigned: snTableFilter.productNotAssigned,
  });

  useEffect(() => {
    const isFilter = query.has("is_filter");
    let filter = sessionStorage.getItem("qr_filter");

    let temp = { ...snTableFilter };
    if (isFilter && filter) {
      temp = JSON.parse(filter);
      setIsFilterUpdated(true);
    }

    if (isFilter) {
      updateUrlQueryParam(null, ["is_filter"]);
    }
    sessionStorage.removeItem("qr_filter");

    setSnTableFilter(temp);

    setUrlLoaded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getAllProducts());
    dispatch(getSnSummaryCount());
  }, [dispatch]);

  useEffect(() => {
    if (!tableFilterInitialRef.current && urlLoaded) {
      fetchData();
    }
    tableFilterInitialRef.current = false;

    if (
      lengthFilter.search !== snTableFilter.search ||
      lengthFilter.searchColumn !== snTableFilter.searchColumn ||
      JSON.stringify(lengthFilter.nodes) !==
        JSON.stringify(snTableFilter.node_uuids) ||
      JSON.stringify(lengthFilter.products) !==
        JSON.stringify(snTableFilter.product_uuids) ||
      lengthFilter.status !== snTableFilter.status ||
      lengthFilter.productNotAssigned !== snTableFilter.productNotAssigned
    ) {
      setLengthFilter({
        search: snTableFilter.search,
        searchColumn: snTableFilter.searchColumn,
        nodes: snTableFilter.node_uuids,
        products: snTableFilter.product_uuids,
        status: snTableFilter.status,
        productNotAssigned: snTableFilter.productNotAssigned,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [snTableFilter]);

  useEffect(() => {
    setPaginationOptions(generatePaginationOptions(length));
  }, [length]);
  useEffect(() => {
    fetchTableLength({
      ...lengthFilter,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lengthFilter]);

  const fetchData = () => {
    getListingSerialNumber({
      length: snTableFilter.length,
      start: snTableFilter.length * snTableFilter.start,
      search: snTableFilter.search,
      searchColumn: snTableFilter.searchColumn,
      node_uuids: snTableFilter.node_uuids,
      product_uuids: snTableFilter.product_uuids,
      status: snTableFilter.status,
      productNotAssigned: snTableFilter.productNotAssigned,
    });
  };

  const handleChangePage = (event, newPage) => {
    let newData = {
      ...snTableFilter,
      start: newPage,
    };
    setIsFilterUpdated(true);
    setSnTableFilter(newData);
  };

  const handleChangeRowsPerPage = (event) => {
    let newData = {
      ...snTableFilter,
      start: 0,
      length: event.target.value,
    };
    setIsFilterUpdated(true);
    setSnTableFilter(newData);
  };

  const handleSearch = (values) => {
    let tempItem = values.products.map((item) => item.id);
    let tempBranch = values.branch.map((branch) => branch.node_id);

    let newData = {
      ...snTableFilter,
      start: 0,
      search: values.search,
      searchColumn: values.searchColumn,
      node_uuids: tempBranch.join(","),
      product_uuids: tempItem.join(","),
      products: values.products,
      branch: values.branch,
      status: values.status ? values.status.value : "",
      productNotAssigned: values.productNotAssigned,
    };
    setIsFilterUpdated(true);
    setSnTableFilter(newData);
  };

  const clearSearch = () => {
    let newData = {
      ...snTableFilter,
      start: 0,
      search: "",
      searchColumn: "serial_number",
      status: "",
      node_uuids: [],
      product_uuids: [],
      products: [],
      branch: [],
      productNotAssigned: false,
    };

    setIsFilterUpdated(false);
    setSnTableFilter(newData);
  };

  const handleStatusChange = ({ currentStatus, enc }) => {
    //add access right
    setChangeStatusTargetEnc(enc);
    if (currentStatus === "active") {
      setIsDeactivateDialogOpen(true);
    } else {
      setIsActivateDialogOpen(true);
    }
  };

  const handleStatusSubmit = (status) => {
    //add access right
    updateSNStatus({
      enc: changeStatusTargetEnc,
      status: status ? "active" : "suspend",
    })
      .then(unwrapResult)
      .then(() => {
        setIsDeactivateDialogOpen(false);
        setIsActivateDialogOpen(false);
        dispatch(getSnSummaryCount());
      });
  };

  const handleViewDetail = (url) => {
    if (isFilterUpdated) {
      updateUrlQueryParam({ is_filter: true });
      sessionStorage.setItem("qr_filter", JSON.stringify(snTableFilter));
    }
    history.push(url);
  };

  return (
    <>
      <ListingComponent
        hasSerialNumberEditAccessRight={hasSerialNumberEditAccessRight}
        hasSerialNumberGenerateAccessRight={hasSerialNumberGenerateAccessRight}
        listingSerialNumberData={listingSerialNumberData}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        page={snTableFilter.start}
        search={snTableFilter.search}
        rowsPerPage={snTableFilter.length}
        isFetching={isSnLoading}
        length={filteredlength}
        handleSearch={handleSearch}
        clearSearch={clearSearch}
        searchColumn={snTableFilter.searchColumn}
        // setSearchColumn={setSearchColumn}
        language={language}
        handleReload={fetchData}
        handleStatusChange={handleStatusChange}
        paginationOptions={paginationOptions}
        productDropdown={productDropdown}
        lang={lang}
        tableFilter={snTableFilter}
        handleViewDetail={handleViewDetail}
        showFilter={showFilter}
        setShowFilter={setShowFilter}
        isSearchResult={isSearchResult}
        summaryCount={summaryCount}
        isSnCountLoading={isSnCountLoading}
        isError={isError}
      />
      <ConfirmationDialog
        isOpen={isActivateDialogOpen}
        handleClose={() => setIsActivateDialogOpen(false)}
        handleProceed={() => handleStatusSubmit(true)}
        isLoading={isUpdatingSNStatus}
        type={"success"}
        description={getLang(lang, "paragraph.ACTIVATE_SN_QUESTION")}
        title={getLang(lang, "label.REACTIVATE_SERIAL_NUMBER")}
      />
      <ConfirmationDialog
        isOpen={isDeactivateDialogOpen}
        handleClose={() => setIsDeactivateDialogOpen(false)}
        handleProceed={() => handleStatusSubmit(false)}
        isLoading={isUpdatingSNStatus}
        type={"danger"}
        description={getLang(lang, "paragraph.SUSPEND_SN_QUESTION")}
        title={getLang(lang, "label.SUSPEND_SERIAL_NUMBER")}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  listingSerialNumberData: state.serialNumber.SerialNumberTable.data,
  length: state.serialNumber.SerialNumberTable.totalRecords,
  filteredlength: state.serialNumber.SerialNumberTable.filteredTotalRecords,
  isUpdatingSNStatus: selectLoading(state, updateSNStatus.typePrefix),
  isSnLoading: selectLoading(state, getListingSerialNumber.typePrefix),
  summaryCount: state.serialNumber.summaryCount,
  isSnCountLoading: selectLoading(state, getSnSummaryCount.typePrefix),
  isError: state.serialNumber.isLoadingSerialNumberError,
});

const mapDispatchToProps = (dispatch) => ({
  getListingSerialNumber: (pageSetting) =>
    dispatch(getListingSerialNumber(pageSetting)),
  updateSNStatus: (values) => dispatch(updateSNStatus(values)),
  fetchTableLength: (values) => dispatch(fetchTableLength(values)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ListingContainer);
