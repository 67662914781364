import { fontTextStyle } from "modules/reporting/styles";
import { responseHandler } from "modules/reporting/utils/helper";
import React from "react";
import { BoxError } from "../../skeleton/error";
import { BoxLoading } from "../../skeleton/loading";
import { Box, Typography } from "@material-ui/core";
import { ContentCard } from "../../layout";

export default function BrandTitleCardComponent({
    status,
    data,
    translate
}){
    const fontClasses = fontTextStyle();

    const renderData = (data) => {

        if (Object.keys(data).length === 0 || !data.isSuccess){
            return null;
        }

        const { brand_summary } = data;
        return (
            <Box display="flex" flexDirection="column" style={{gap:"12px"}} justifyContent={"center"} alignContent="center">
                <Typography className={fontClasses.text__muted}>
                    Drilldown by brand
                </Typography>
                <Box
                    display="flex"
                    style={{
                        gap: "12px",
                    }}
                    alignItems="center"
                >
                    <Typography className={fontClasses.text__card_title}>
                        {brand_summary.name}
                    </Typography>
                </Box>
            </Box>
        )
    }

    return (
		<ContentCard>
			<Box
				padding="15px 20px"
			>
				{
                    responseHandler(
                        data.productBrandDetailData,
                        status.isFetchingProductBrandDetailData,
                        (
                            renderData(data.productBrandDetailData)
                        ),
                        (
                            <BoxLoading />
                        ),
                        (
                            <>empty</>
                        ),
                        (
                            <>no result</>
                        ),
                        (
                            <BoxError 
                                title={translate("label.LOADING_FAILED")}
                                value={translate("paragraph.LOADING_FAILED_DESCRIPTION")}
                            />
                        )
                    )
                }
			</Box>
		</ContentCard>
    )
}