import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useRouteMatch } from "react-router-dom"
import { selectLoading } from 'modules/notification'
import LoadingComponent from "components/loading"
import { STOCKFLOW_RECALL_VIEW, STOCKFLOW_DEALER_VIEW } from "lib/constants/accessRights"
import { ROOT } from "modules/dashboard"
import RecallDetailComponent from "./recallDetails.component"
import { getRecallDetail, getRecallItemList } from "modules/stockflow/redux"

function RecallDetailsContainer() {
  const history = useHistory()
  const dispatch = useDispatch()
  const match = useRouteMatch()
  const id = match.params.uuid
  const hasStockflowViewAccessRight = useSelector(state => state.profile.accessRights.includes(STOCKFLOW_RECALL_VIEW))
  const hasStockflowDealerViewAccessRight = useSelector(state => state.profile.accessRights.includes(STOCKFLOW_DEALER_VIEW))
  const lang = useSelector(state => state.constant.languages)
  const language = useSelector(state => state.profile.language)
  const recall = useSelector(state => state.stockflowRecall.recall)
  const isError = useSelector(state => state.stockflowRecall.isLoadingRecallError)
  const isFetching = useSelector(state => selectLoading(state, getRecallDetail.typePrefix))

  useEffect(() => {
    if (!hasStockflowViewAccessRight) {
      history.push('/admin/dashboard')
    }
  }, [history, hasStockflowViewAccessRight])

  useEffect(() => {
    if (id) {
      dispatch(getRecallDetail(id))
      dispatch(getRecallItemList(id))
    }
    else {
      history.push(ROOT)
    }
  }, [history, id, dispatch])

  if (!hasStockflowViewAccessRight) return <LoadingComponent />

  const handleReload = () => {
    dispatch(getRecallDetail(id))
    dispatch(getRecallItemList(id))
  }

  return (
    <RecallDetailComponent
      hasStockflowDealerViewAccessRight={hasStockflowDealerViewAccessRight}
      recall={recall}
      isFetching={isFetching}
      isError={isError}
      language={language}
      handleReload={handleReload}
      lang={lang}
    />
  )
}

export default RecallDetailsContainer
