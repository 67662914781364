import React, { useState, useEffect, useRef } from "react";
import AssignStatusProgressComponent from "./assignStatusProgress.component";
import { useDispatch, useSelector } from "react-redux";
import { getAssignProgress } from "modules/serial-number/redux";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  ASSIGN_BATCH_STATUS_FAIL,
  ASSIGN_BATCH_STATUS_SUCCESS,
} from "modules/serial-number/constants";

function AssignStatusProgressContainer({
  assignUuid,
  initialProgress = 0,
  initialStatus,
}) {
  const lang = useSelector((state) => state.constant.languages);
  const [status, setStatus] = useState(initialStatus);
  const [progress, setProgress] = useState(initialProgress);
  const dispatch = useDispatch();

  const intervalRef = useRef(null);

  useEffect(() => {
    if (
      initialStatus !== ASSIGN_BATCH_STATUS_SUCCESS &&
      initialStatus !== ASSIGN_BATCH_STATUS_FAIL
    ) {
      intervalRef.current = setInterval(() => {
        getData();
      }, 5000);
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, assignUuid]);

  const getData = () => {
    dispatch(getAssignProgress(assignUuid))
      .then(unwrapResult)
      .then((data) => {
        setStatus(data.status);
        setProgress(data.progress);

        if (
          data.status === ASSIGN_BATCH_STATUS_SUCCESS ||
          data.status === ASSIGN_BATCH_STATUS_FAIL
        ) {
          clearInterval(intervalRef.current);
        }
      })
      .catch(() => {
        setStatus("failed");
        setProgress(0);
        clearInterval(intervalRef.current);
      });
  };

  return (
    <AssignStatusProgressComponent
      progress={progress}
      status={status}
      lang={lang}
    />
  );
}

export default AssignStatusProgressContainer;
