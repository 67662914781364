import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { PUBLIC } from "../../constants";
import publicApi from "app/api/public";
import { setMaintenanceStatus } from "modules/admin/redux";

export const getTimezone = createAction(`${PUBLIC}/getTimezone`);

export const fetchTimezone = createAsyncThunk(
  `${PUBLIC}/fetchTimezone`,
  async (payload, { rejectWithValue }) => {
    return publicApi
      .getTimezone()
      .then((response) => response)
      .catch((error) => rejectWithValue(error));
  }
);

export const fetchAllCurrencyConfig = createAsyncThunk(
  `${PUBLIC}/fetchAllCurrencyConfig`,
  async (payload, { rejectWithValue }) => {
    return publicApi
      .getAllCurrencyConfig()
      .then((response) => response)
      .catch((error) => rejectWithValue(error));
  }
);

export const checkMaintenanceStatus = createAsyncThunk(
  `${PUBLIC}/checkMaintenanceStatus`,
  async (payload, { dispatch, rejectWithValue, getState }) => {
    try {
      const response = await publicApi.checkMaintenanceStatus();
      const isMaintenance = process.env.REACT_APP_MAINTENANCE_MODE === "true";

      const maintenance = isMaintenance || response ? response.data : false;
      dispatch(setMaintenanceStatus(maintenance));

      return maintenance;
    } catch (e) {
      rejectWithValue(e);
    }
  }
);
