import React, { useState } from "react";
import AddCardFormComponent from "./addCardForm.component";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import AddCardButtonComponent from "./addCardButton.component";
import { addPaymentMethod } from "modules/billing/redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { selectLoading } from "modules/notification";

function AddCardFormContainer({
  handleResult,
  initialValues,
  addPaymentMethod,
  isAddingPaymentMethod,
  setIsPrimary
}) {
  const [error, setError] = useState(null);
  const stripe = useStripe();
  const elements = useElements();
  const [isFormOpen, setIsFormOpen] = useState(false);
  const countries = useSelector(state => state.constant.country.data)
  const lang = useSelector(state => state.constant.languages)

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);

    if (!stripe || !elements) {
      setSubmitting(false);
      return;
    }

    values.postCode = values.postCode.trim();

    const cardElement = elements.getElement(CardElement);

    const billing_details = {
      name: values.name,
      email: values.email,
      address: {
        city: values.city,
        country: values.country,
        line1: values.address,
        state: values.region,
        postal_code: values.postCode
      }
    };

    const createPaymentMethod = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details
    });

    if (createPaymentMethod.error) {
      setError(createPaymentMethod.error.code);
      setSubmitting(false);
      return;
    }

    addPaymentMethod({
      isDefault: setIsPrimary,
      payment_method: createPaymentMethod.paymentMethod.id
    })
      .then(unwrapResult)
      .then(response => {
        setSubmitting(false);
        handleResult();
      })
      .catch(error => {
        setSubmitting(false);
      });
  };

  const handleCard = event => {
    if (event.error) {
      setError(event.error.code);
    } else {
      setError(null);
    }
  };

  const handleOpenForm = () => {
    setIsFormOpen(true);
  };

  const handleCloseForm = () => {
    setIsFormOpen(false);
  };

  return (
    <>
      <AddCardButtonComponent handleClick={handleOpenForm} lang={lang} />
      <AddCardFormComponent
        initialValues={initialValues}
        isAddingPaymentMethod={isAddingPaymentMethod}
        handleSubmit={handleSubmit}
        handleCard={handleCard}
        error={error}
        isFormOpen={isFormOpen}
        handleClose={handleCloseForm}
        countries={countries}
        lang={lang}
      />
    </>
  );
}

AddCardFormContainer.propTypes = {
  handleResult: PropTypes.func
};

const mapStateToProps = (state) => ({
  initialValues: {
    name: `${state.profile.firstName} ${state.profile.lastName}`,
    email: state.profile.email,
    address: state.account.address,
    city: state.account.city,
    state: state.account.region,
    country: state.constant.country.data.find(
      (country) =>
        country.code === state.account.countryCode || country.code === "MY"
    ).code,
    postCode: state.account.postCode,
  },
  isAddingPaymentMethod: selectLoading(state, addPaymentMethod.typePrefix),
});

const mapDispatchToProps = dispatch => ({
  addPaymentMethod: payload => dispatch(addPaymentMethod(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddCardFormContainer);
