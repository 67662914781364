import React from "react";
import {
  makeStyles,
  Typography,
  Box,
  CircularProgress,
  Paper,
  Divider,
  Link,
} from "@material-ui/core";
import { ChevronRightRounded as ChevronRightRoundedIcon } from "@material-ui/icons";
import { BackButton } from "components/button";
import RefreshTable from "components/table/refreshTable";
import DefaultImg from "assets/img/img-default.png";
import DefaultImage from "assets/img/defaultPhoto.png";
import moment from "moment";
import { ROOT } from "modules/stockflow/config/stockflow.route";
import { useHistory } from "react-router-dom";
import { TIER_ICON_PROPS } from "modules/stockflow/constants";
import StockoutItemListPanel from "modules/stockflow/components/panel/stockoutItemListPanel";
import { getLang } from "app/feature/constants";
import "moment/min/locales.min";
import DOMPurify from "dompurify";

const useStyle = makeStyles((theme) => ({
  panelContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridGap: "1em",
    gridTemplateAreas: "'left-panel right-panel'",
    [theme.breakpoints.down("sm")]: {
      gridTemplateAreas: "'left-panel left-panel''right-panel right-panel'",
    },
  },
  productContainer: {
    marginBottom: "16px",
    padding: "20px 16px",
    borderRadius: "8px",
  },
  list: {
    maxHeight: "380px",
    overflowY: "auto",
    padding: "4px 16px 16px",
    "&::-webkit-scrollbar": {
      width: "10px",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "16px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#E4E7EC",
      borderRadius: "16px",
    },
  },
  field: {
    display: "grid",
    gridTemplateColumns: "2fr 3fr",
    columnGap: theme.spacing(2),
  },
  panel: {
    backgroundColor: "#FFFFFF",
    borderRadius: 4,
    padding: "20px 16px",
    marginBottom: 16,
  },
  largeProfile: {
    height: 80,
    width: 80,
    minWidth: 80,
    padding: "2px",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& img": {
      height: "100%",
      minHeight: "100%",
      minWidth: "100%",
      objectFit: "cover",
      borderRadius: "50%",
      border: "1px solid #D0D5DD",
    },
  },
  respondProfile: {
    height: 32,
    width: 32,
    minWidth: 32,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& img": {
      height: "100%",
      minHeight: "100%",
      minWidth: "100%",
      objectFit: "cover",
      border: "1px solid #D0D5DD",
      borderRadius: "50%",
    },
  },
  branchProfile: {
    height: 40,
    width: 40,
    minWidth: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& img": {
      height: "100%",
      minHeight: "100%",
      minWidth: "100%",
      objectFit: "cover",
      borderRadius: "50%",
    },
  },
  productImage: {
    height: 50,
    width: 50,
    minWidth: 50,
    borderRadius: "4px",
    border: "1px solid #0000001A",
    padding: "2px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& img": {
      height: "100%",
      minHeight: "100%",
      minWidth: "100%",
      objectFit: "cover",
    },
  },
  item: {
    position: "relative",
    display: "flex",
    listStyle: "none",
  },
  seperator: {
    display: "flex",
    flexDirection: "column",
    flex: 0,
    alignItems: "center",
  },
  dot: {
    width: 12,
    height: 12,
    boxShadow: "none",
    backgroundColor: "#A749F0",
    color: "#ffffff",
    padding: 4,
    borderRadius: "50%",
    textAlign: "center",
    margin: "5px 0px",
  },
  connector: {
    width: 1,
    backgroundColor: "transparent",
    flexGrow: 1,
    minHeight: "25px",
    borderLeft: "2px dotted #D0D5DD",
  },
  flow: {
    paddingInlineStart: "0px",
  },
  tierIcon: {
    borderRadius: "4px",
    paddingLeft: "0.15em",
    paddingRight: "0.15em",
    display: "flex",
    alignItems: "center",
    position: "absolute",
    top: "2px",
    left: "-1px",
  },
  tierLabel: {
    color: "#FFFFFF",
  },
  statusChip: {
    color: "#FFFFFF",
    padding: "7px 18px",
    marginLeft: "10px",
    borderRadius: "4px",
  },
  summaryBox: {
    border: "1px solid #D0D5DD",
    padding: "8px 10px",
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gridGap: "1em",
  },
  attributeText: {
    color: "#b2b2b2",
    fontSize: 12,
    whiteSpace: "break-spaces",
  },
}));

export default function StockoutDetailComponent({
  hasStockflowDealerViewAccessRight,
  stockout,
  language,
  isFetching,
  isError,
  handleReload,
  lang,
}) {
  const classes = useStyle();
  const history = useHistory();

  const PENDING_TO_RECEIVE = "Pending Approval";
  const ACCEPTED = "Accepted";
  const AUTO_ACCEPTED = "Auto Accepted";
  const REJECTED = "Rejected";
  const CANCELLED = "Cancelled";

  const statusColor = {
    [PENDING_TO_RECEIVE]: "#FDB022",
    [ACCEPTED]: "#32D583",
    [AUTO_ACCEPTED]: "#32D583",
    [REJECTED]: "#F97066",
    [CANCELLED]: "#98A2B3",
  };

  const statusPanelColor = {
    [PENDING_TO_RECEIVE]: "#FFFCF5",
    [ACCEPTED]: "#F6FEF9",
    [AUTO_ACCEPTED]: "#F6FEF9",
    [REJECTED]: "#FFFBFA",
    [CANCELLED]: "#FCFCFD",
  };

  const generateLink = (remark) => {
    let urlRegex = /(https?:\/\/[^\s]+)/g;
    let textWithLink = remark.replace(urlRegex, (url) => {
      return '<a href="' + url + '" target="_blank">' + url + "</a>";
    });

    const sanitizedHtmlContent = DOMPurify.sanitize(textWithLink);

    return (
      <Box
        dangerouslySetInnerHTML={{
          __html: sanitizedHtmlContent,
        }}
      />
    );
  };

  return (
    <Box className={classes.root}>
      <Box gridArea="topbar" mb={3}>
        <Box
          mb={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="flex" alignItems="flex-end" fontWeight="bold">
            <BackButton />
            <Box display="flex" ml={1} alignItems="flex-end">
              <Typography variant="h6" color="primary">
                {getLang(lang, "label.STOCK_OUT")}
              </Typography>
              <Box mx={0.5}>
                <ChevronRightRoundedIcon color="disabled" />
              </Box>
              <Box>
                <Typography variant="h6" color="primary">
                  {getLang(lang, "label.STOCKOUT_DETAILS")}
                </Typography>
              </Box>
            </Box>
          </Box>
          {/* <Box>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              style={{ padding: "5px 12px", background: "white", borderRadius: "8px" }}
              disableElevation
              onClick={() => {}}
              startIcon={<DownloadSVG />}
              // disabled={isLoading}
            >
              EXPORT XLS
            </Button>
          </Box> */}
        </Box>
      </Box>
      <Box gridArea="panel">
        {!isFetching && !isError && stockout && (
          <>
            <Box
              display="flex"
              alignItems="center"
              px={2}
              py={2.5}
              mb={2}
              style={{ backgroundColor: statusPanelColor[stockout.status] }}
            >
              <Typography variant="h5">
                <b>
                  {getLang(lang, "label.STOCKOUT_ID")}:{" "}
                  {stockout.reference_code}
                </b>
              </Typography>
              <Box
                className={classes.statusChip}
                style={{ backgroundColor: statusColor[stockout.status] }}
              >
                <Typography variant="h5">
                  <b>{getLang(lang, `db_label.${stockout.status}`)}</b>
                </Typography>
              </Box>
            </Box>
            <Box className={classes.panelContainer}>
              <Box gridArea="left-panel">
                <Paper className={classes.panel} elevation={3}>
                  <Box mb={2} display="flex" alignItems="center">
                    <Box className={classes.branchProfile} mr={1}>
                      {stockout.node?.picture ? (
                        <img
                          src={stockout.node.picture}
                          alt={stockout.node.name}
                        />
                      ) : (
                        <img src={DefaultImg} alt="default branch" />
                      )}
                    </Box>
                    <Typography variant="body1">
                      {stockout.node.name}
                    </Typography>
                  </Box>
                  <Divider />
                  <Box mt={2}>
                    <ul className={classes.flow}>
                      <li className={classes.item}>
                        <Box className={classes.seperator}>
                          <Box
                            className={classes.dot}
                            style={{ backgroundColor: "#344054" }}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                          />
                          <Box className={classes.connector} />
                        </Box>
                        <Box ml={2} mb={3} mt={-0.5}>
                          <Box display="flex" alignItems="center" mb={0.5}>
                            <Typography variant="h6">
                              {getLang(lang, "label.STOCKOUT_FROM")}
                            </Typography>
                          </Box>
                          <Box mb={1} display="flex" alignItems="center">
                            <Box className={classes.largeProfile} mr={1.5}>
                              {stockout.stockout_from?.picture ? (
                                <img
                                  src={stockout.stockout_from.picture}
                                  alt={stockout.stockout_from.name}
                                />
                              ) : (
                                <img
                                  src={
                                    stockout.stockout_from.type ===
                                    "Headquarter"
                                      ? DefaultImg
                                      : DefaultImage
                                  }
                                  alt="default profile"
                                />
                              )}
                              {!!stockout.stockout_from.tier && (
                                <Box
                                  className={classes.tierIcon}
                                  style={{
                                    backgroundColor: TIER_ICON_PROPS.find(
                                      ({ tier }) =>
                                        tier === stockout.stockout_from.tier
                                    ).color,
                                  }}
                                >
                                  <Typography
                                    className={classes.tierLabel}
                                    variant="body1"
                                  >
                                    T{stockout.stockout_from.tier}
                                  </Typography>
                                </Box>
                              )}
                              {!!stockout.stockout_by && (
                                <Box
                                  className={classes.respondProfile}
                                  position="absolute"
                                  style={{
                                    bottom: "0px",
                                    right: "0px",
                                  }}
                                >
                                  {stockout.stockout_by?.picture ? (
                                    <img
                                      src={stockout.stockout_by.picture}
                                      alt={stockout.stockout_by.name}
                                    />
                                  ) : (
                                    <img
                                      src={DefaultImage}
                                      alt="default profile"
                                    />
                                  )}
                                </Box>
                              )}
                            </Box>
                            <Box>
                              <Box mb={0.5}>
                                {stockout.stockout_from.type ===
                                  "Headquarter" ||
                                !hasStockflowDealerViewAccessRight ? (
                                  <>
                                    <Typography
                                      variant="body1"
                                      style={{ textAlign: "left" }}
                                    >
                                      {stockout.stockout_from.name}
                                    </Typography>
                                  </>
                                ) : (
                                  <Link
                                    href={`${ROOT}/my-dealer/${stockout.stockout_from.uuid}`}
                                    color="secondary"
                                    onClick={(e) => {
                                      history.push(
                                        `${ROOT}/my-dealer/${stockout.stockout_from.uuid}`
                                      );
                                      e.preventDefault();
                                      return false;
                                    }}
                                  >
                                    <Typography
                                      variant="body1"
                                      style={{ textAlign: "left" }}
                                    >
                                      {stockout.stockout_from.name}
                                    </Typography>
                                  </Link>
                                )}
                              </Box>
                              {!!stockout.stockout_by && (
                                <Link
                                  href={`${ROOT}/employee/${stockout.stockout_by.uuid}`}
                                  color="secondary"
                                  onClick={(e) => {
                                    history.push(
                                      `/admin/employee/View/${stockout.stockout_by.uuid}`
                                    );
                                    e.preventDefault();
                                    return false;
                                  }}
                                >
                                  <Typography
                                    variant="body1"
                                    style={{ textAlign: "left" }}
                                  >
                                    {stockout.stockout_by.name}
                                  </Typography>
                                </Link>
                              )}
                              <Box>
                                <Typography
                                  variant="body1"
                                  color="textSecondary"
                                >
                                  <span>
                                    {stockout.stockout_from.phone_code}{" "}
                                  </span>
                                  <span>
                                    {stockout.stockout_from.phone_number}
                                  </span>
                                </Typography>
                              </Box>
                              <Box>
                                <Typography
                                  variant="body1"
                                  color="textSecondary"
                                >
                                  {stockout.stockout_from.email}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </li>
                      <li className={classes.item}>
                        <Box className={classes.seperator}>
                          <Box
                            className={classes.dot}
                            style={{
                              backgroundColor: [
                                PENDING_TO_RECEIVE,
                                CANCELLED,
                              ].includes(stockout.status)
                                ? "#98A2B3"
                                : "#344054",
                            }}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                          />
                        </Box>
                        <Box ml={2} mt={-0.5}>
                          <Box display="flex" alignItems="center" mb={0.5}>
                            <Typography
                              variant="h6"
                              color={
                                [PENDING_TO_RECEIVE, CANCELLED].includes(
                                  stockout.status
                                )
                                  ? "textSecondary"
                                  : "textPrimary"
                              }
                            >
                              {getLang(lang, "label.STOCKOUT_TO")}
                            </Typography>
                          </Box>
                          <Box mb={1} display="flex" alignItems="center">
                            <Box className={classes.largeProfile} mr={1.5}>
                              {stockout.stockout_to?.picture ? (
                                <img
                                  src={stockout.stockout_to.picture}
                                  alt={stockout.stockout_to.name}
                                />
                              ) : (
                                <img src={DefaultImage} alt="default profile" />
                              )}
                              {!!stockout.stockout_to.tier && (
                                <Box
                                  className={classes.tierIcon}
                                  style={{
                                    backgroundColor: TIER_ICON_PROPS.find(
                                      ({ tier }) =>
                                        tier === stockout.stockout_to.tier
                                    ).color,
                                  }}
                                >
                                  <Typography
                                    className={classes.tierLabel}
                                    variant="body1"
                                  >
                                    T{stockout.stockout_to.tier}
                                  </Typography>
                                </Box>
                              )}
                            </Box>
                            <Box>
                              {hasStockflowDealerViewAccessRight ? (
                                <Link
                                  href={`${ROOT}/my-dealer/${stockout.stockout_to.uuid}`}
                                  color="secondary"
                                  onClick={(e) => {
                                    history.push(
                                      `${ROOT}/my-dealer/${stockout.stockout_to.uuid}`
                                    );
                                    e.preventDefault();
                                    return false;
                                  }}
                                >
                                  <Typography
                                    variant="body1"
                                    style={{ textAlign: "left" }}
                                  >
                                    {stockout.stockout_to.name}
                                  </Typography>
                                </Link>
                              ) : (
                                <Typography
                                  variant="body1"
                                  style={{ textAlign: "left" }}
                                >
                                  {stockout.stockout_to.name}
                                </Typography>
                              )}
                              <Box mt={0.5}>
                                <Typography
                                  variant="body1"
                                  color="textSecondary"
                                >
                                  <span>
                                    {stockout.stockout_to.phone_code}{" "}
                                  </span>
                                  <span>
                                    {stockout.stockout_to.phone_number}
                                  </span>
                                </Typography>
                              </Box>
                              <Box>
                                <Typography
                                  variant="body1"
                                  color="textSecondary"
                                >
                                  {stockout.stockout_to.email}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </li>
                    </ul>
                  </Box>
                </Paper>
                <Paper className={classes.panel} elevation={3}>
                  <Box mb={2}>
                    <Typography variant="body1">
                      <b>{getLang(lang, "label.ORDER_INFORMATION")}</b>
                    </Typography>
                  </Box>
                  <Divider />
                  {stockout.status !== PENDING_TO_RECEIVE && (
                    <>
                      <Box my={2}>
                        <Box className={classes.field}>
                          <Typography variant="body1">
                            {[ACCEPTED, AUTO_ACCEPTED].includes(stockout.status)
                              ? "Received"
                              : stockout.status === "Rejected"
                              ? "Rejected"
                              : "Cancelled"}{" "}
                            Date
                          </Typography>
                          <Typography variant="body1" color="textSecondary">
                            <span>: </span>
                            <span>
                              {moment
                                .utc(stockout.respond_date)
                                .local()
                                .format("lll")}
                            </span>
                            {stockout.status === AUTO_ACCEPTED && (
                              <span style={{ color: "#FDB022" }}>
                                {" "}
                                ({getLang(lang, "label.AUTO_RECEIVED")})
                              </span>
                            )}
                          </Typography>
                        </Box>
                        {!!stockout.stockout_by &&
                          stockout.status === CANCELLED && (
                            <Box className={classes.field} mt={2}>
                              <Typography variant="body1">
                                {getLang(lang, "label.CANCELLED_BY")}
                              </Typography>
                              <Box display="flex">
                                <Typography
                                  variant="body1"
                                  color="textSecondary"
                                >
                                  :{" "}
                                </Typography>
                                <Box display="flex" ml={0.5}>
                                  <Box
                                    className={classes.respondProfile}
                                    mr={1}
                                  >
                                    {stockout.stockout_by?.picture ? (
                                      <img
                                        src={stockout.stockout_by.picture}
                                        alt={stockout.stockout_by.name}
                                      />
                                    ) : (
                                      <img
                                        src={DefaultImage}
                                        alt="default profile"
                                      />
                                    )}
                                  </Box>
                                  <Link
                                    href={`${ROOT}/employee/${stockout.stockout_by.uuid}`}
                                    color="secondary"
                                    onClick={(e) => {
                                      history.push(
                                        `/admin/employee/View/${stockout.stockout_by.uuid}`
                                      );
                                      e.preventDefault();
                                      return false;
                                    }}
                                  >
                                    <Typography
                                      variant="body1"
                                      style={{ textAlign: "left" }}
                                    >
                                      {stockout.stockout_by.name}
                                    </Typography>
                                  </Link>
                                </Box>
                              </Box>
                            </Box>
                          )}
                        {[REJECTED, CANCELLED].includes(stockout.status) && (
                          <Box className={classes.field} mt={2}>
                            <Typography variant="body1">
                              {stockout.status === REJECTED
                                ? getLang(lang, "label.REJECT_REASON")
                                : getLang(lang, "label.CANCEL_REASON")}
                            </Typography>
                            <Box display="flex">
                              <Typography
                                variant="body1"
                                color="textSecondary"
                                style={{ marginRight: 3 }}
                              >
                                :{" "}
                              </Typography>
                              <Typography variant="body1" color="textSecondary">
                                <span>{stockout.reasons || "-"}</span>
                              </Typography>
                            </Box>
                          </Box>
                        )}
                      </Box>
                      <Divider />
                    </>
                  )}
                  <Box mt={2}>
                    <Box className={classes.field}>
                      <Typography variant="body1">
                        {getLang(lang, "label.STOCKOUT_DATE")}
                      </Typography>
                      <Typography variant="body1" color="textSecondary">
                        <span>: </span>
                        <span>
                          {moment
                            .utc(stockout.stockout_date)
                            .local()
                            .format("lll")}
                        </span>
                      </Typography>
                    </Box>
                    {!!stockout.stockout_by &&
                      stockout.status !== CANCELLED && (
                        <Box className={classes.field} mt={2}>
                          <Typography variant="body1">
                            {getLang(lang, "label.STOCKOUT_BY")}
                          </Typography>
                          <Box display="flex">
                            <Typography variant="body1" color="textSecondary">
                              :{" "}
                            </Typography>
                            <Box display="flex" ml={0.5}>
                              <Box className={classes.respondProfile} mr={1}>
                                {stockout.stockout_by?.picture ? (
                                  <img
                                    src={stockout.stockout_by.picture}
                                    alt={stockout.stockout_by.name}
                                  />
                                ) : (
                                  <img
                                    src={DefaultImage}
                                    alt="default profile"
                                  />
                                )}
                              </Box>
                              <Link
                                href={`${ROOT}/employee/${stockout.stockout_by.uuid}`}
                                color="secondary"
                                onClick={(e) => {
                                  history.push(
                                    `/admin/employee/View/${stockout.stockout_by.uuid}`
                                  );
                                  e.preventDefault();
                                  return false;
                                }}
                              >
                                <Typography
                                  variant="body1"
                                  style={{ textAlign: "left" }}
                                >
                                  {stockout.stockout_by.name}
                                </Typography>
                              </Link>
                            </Box>
                          </Box>
                        </Box>
                      )}
                    <Box className={classes.field} mt={2}>
                      <Typography variant="body1">
                        {getLang(lang, "label.REMARK")}
                      </Typography>
                      <Box display="flex">
                        <Typography
                          variant="body1"
                          color="textSecondary"
                          style={{ marginRight: 3 }}
                        >
                          :{" "}
                        </Typography>
                        <Typography variant="body1" color="textSecondary">
                          {stockout.remarks
                            ? generateLink(stockout.remarks)
                            : "-"}
                        </Typography>
                      </Box>
                    </Box>
                    <Box className={classes.field} mt={2}>
                      <Typography variant="body1">
                        {getLang(lang, "label.DEALER_INCENTIVE_POINT")}
                      </Typography>
                      <Box display="flex">
                        <Typography
                          variant="body1"
                          color="textSecondary"
                          style={{ marginRight: 3 }}
                        >
                          :{" "}
                        </Typography>
                        <Typography variant="body1" color="textSecondary">
                          <span>
                            {stockout.point
                              ? stockout.point.toLocaleString("en-US")
                              : "0"}
                          </span>
                          {!!stockout.point &&
                            (stockout.status === REJECTED ||
                            stockout.status === CANCELLED ? (
                              <span style={{ color: "#F97066" }}>
                                {" "}
                                ({getLang(lang, "label.UNCLAIMED")})
                              </span>
                            ) : stockout.status !== PENDING_TO_RECEIVE ? (
                              <span style={{ color: "#32D583" }}>
                                {" "}
                                ({getLang(lang, "label.CLAIMED")})
                              </span>
                            ) : null)}
                        </Typography>
                      </Box>
                    </Box>
                    {stockout.custom_fields.map((field, i) => (
                      <Box className={classes.field} mt={2} key={i}>
                        <Typography variant="body1">
                          {field.field_name[language] ??
                            field.field_name["EN"] ??
                            Object.values(field.field_name)[0]}
                        </Typography>
                        <Box display="flex">
                          <Typography
                            variant="body1"
                            color="textSecondary"
                            style={{ marginRight: 3 }}
                          >
                            :{" "}
                          </Typography>
                          <Typography variant="body1" color="textSecondary">
                            <span>
                              {field.field_type === 2
                                ? moment(field.value_date).format("DD MMM YYYY")
                                : field.value_text ?? "-"}
                            </span>
                          </Typography>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </Paper>
                <Paper className={classes.panel} elevation={3}>
                  <Box mb={2}>
                    <Typography variant="body1">
                      <b>{getLang(lang, "label.SHIPPING_DETAILS")}</b>
                    </Typography>
                  </Box>
                  <Divider />
                  <Box mt={2}>
                    <Box className={classes.field}>
                      <Typography variant="body1">
                        {getLang(lang, "label.NAME")}
                      </Typography>
                      <Typography variant="body1" color="textSecondary">
                        <span>: </span>
                        <span>
                          {stockout.shipping_detail
                            ? stockout.shipping_detail.name
                            : getLang(lang, "label.NONE")}
                        </span>
                      </Typography>
                    </Box>
                    <Box className={classes.field} mt={2}>
                      <Typography variant="body1">
                        {getLang(lang, "label.CONTACT")}
                      </Typography>
                      <Typography variant="body1" color="textSecondary">
                        <span>: </span>
                        <span>
                          {stockout.shipping_detail
                            ? `${stockout.shipping_detail.phone_code} ${stockout.shipping_detail.phone_number}`
                            : getLang(lang, "label.NONE")}
                        </span>
                      </Typography>
                    </Box>
                    <Box className={classes.field} mt={2}>
                      <Typography variant="body1">
                        {getLang(lang, "label.ADDRESS")}
                      </Typography>
                      <Box display="flex">
                        <Typography
                          variant="body1"
                          color="textSecondary"
                          style={{ marginRight: 3 }}
                        >
                          :{" "}
                        </Typography>
                        <Typography variant="body1" color="textSecondary">
                          <span>
                            {stockout.shipping_detail
                              ? stockout.shipping_detail.address
                              : getLang(lang, "label.NONE")}
                          </span>
                        </Typography>
                      </Box>
                    </Box>
                    {!stockout.shipping_detail && (
                      <Box mt={2}>
                        <Typography variant="body1" color="textSecondary">
                          {getLang(
                            lang,
                            "paragraph.NO_SHIPPING_DETAILS_PROVIDED"
                          )}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Paper>
              </Box>
              <Box gridArea="right-panel">
                <Paper
                  className={classes.panel}
                  style={{ padding: "0 0 16px 0" }}
                >
                  <Box px={2} pt={2}>
                    <Typography variant="body1">
                      <b>{getLang(lang, "label.STOCKED_OUT_SUMMARY")}</b>
                    </Typography>
                  </Box>
                  <Box p={2}>
                    <Divider />
                  </Box>
                  <Box px={2}>
                    <Box className={classes.summaryBox}>
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        style={{ borderRight: "1px solid #D0D5DD" }}
                      >
                        <Typography variant="body1">
                          {getLang(lang, "label.TOTAL_BOX")}
                        </Typography>
                        <Typography variant="h5" color="textSecondary">
                          <b>{stockout.total_box}</b>
                        </Typography>
                      </Box>
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        style={{ borderRight: "1px solid #D0D5DD" }}
                      >
                        <Typography variant="body1">
                          {getLang(lang, "label.TOTAL_PACKAGE")}
                        </Typography>
                        <Typography variant="h5" color="textSecondary">
                          <b>{stockout.total_package}</b>
                        </Typography>
                      </Box>
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                      >
                        <Typography variant="body1">
                          {getLang(lang, "label.TOTAL_LOOSE_ITEM")}
                        </Typography>
                        <Typography variant="h5" color="textSecondary">
                          <b>{stockout.total_loose_items}</b>
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box p={2}>
                    <Divider />
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    mb={2}
                    px={2}
                  >
                    <Typography variant="body1">
                      {getLang(lang, "label.PRODUCT_NAME")}
                    </Typography>
                    <Typography variant="body1">
                      {getLang(lang, "label.QUANTITY")}
                    </Typography>
                  </Box>
                  <Box className={classes.list}>
                    {stockout.products.map((item, index) => (
                      <Paper
                        key={item.id}
                        elevation={3}
                        className={classes.productContainer}
                      >
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Box display="flex" alignItems="center" mr={1}>
                            <Typography variant="body1">
                              <b>{index + 1}.</b>
                            </Typography>
                            <Box mx={2} className={classes.productImage}>
                              {item.product_image ? (
                                <img
                                  src={item.product_image}
                                  alt={item.product_name}
                                />
                              ) : (
                                <img src={DefaultImg} alt="default product" />
                              )}
                            </Box>
                            <Box
                              display="flex"
                              flexDirection="column"
                              justifyContent="center"
                              alignItems="flex-start"
                              pl={1}
                            >
                              <Typography variant="body1">
                                <b>{item.product_name}</b>
                              </Typography>
                              {!!item.attributes &&
                                !!item.attributes.length && (
                                  <Typography
                                    className={classes.attributeText}
                                    variant="body2"
                                  >
                                    {item.attributes.join(", ")}
                                  </Typography>
                                )}
                            </Box>
                          </Box>
                          <Typography variant="body1">
                            {item.product_quantity.toLocaleString("en-US")}
                          </Typography>
                        </Box>
                      </Paper>
                    ))}
                  </Box>
                </Paper>
                <StockoutItemListPanel />
              </Box>
            </Box>
          </>
        )}
        {!isFetching && isError && (
          <Box className={classes.tabContainer}>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <RefreshTable handleReload={handleReload} />
            </Box>
          </Box>
        )}
        {isFetching && (
          <Box gridArea="main" mt={6} style={{ textAlign: "center" }}>
            <CircularProgress color="primary" />
          </Box>
        )}
      </Box>
    </Box>
  );
}
