import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import ReinstateWarrantyDialogComponent from "./reinstateWarrantyDialog.component";

function ReinstateWarrantyDialogContainer({
  isOpen,
  handleClose,
  handleSubmit,
  consumerWarrantyDetail
}) {
  const lang = useSelector(state => state.constant.languages);

  return (
    <ReinstateWarrantyDialogComponent
      isOpen={isOpen}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      lang={lang}
      consumerWarrantyDetail={consumerWarrantyDetail}
    />
  );
}

ReinstateWarrantyDialogContainer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default ReinstateWarrantyDialogContainer;
