import React from "react";
import BrandModelListTableComponent from './brandModelListTable.component';

export default function BrandModelListTableContainer({
  hasProductBrandModelEditAccessRight,
  hasProductBrandModelDeleteAccessRight,
  data,
  isFetching,
  isSelectAll,
  selectedIds,
  paginationOptions,
  isLoadingBrandModelListError,
  handleChangePage,
  handleChangeRowsPerPage,
  handleReload,
  page,
  search,
  totalFiltered,
  rowsPerPage,
  handleChangeStatus,
  handleEditBrandModel,
  handleCreateBrandModel,
  handleDeleteBrandModel,
  handleSelectBrandModel,
  handleSelectAvailable,
  handleSelectAll,
  handleDeselectAll,
  lang
}) {
  return (
    <BrandModelListTableComponent
      hasProductBrandModelEditAccessRight={hasProductBrandModelEditAccessRight}
      hasProductBrandModelDeleteAccessRight={hasProductBrandModelDeleteAccessRight}
      data={data}
      isSelectAll={isSelectAll}
      selectedIds={selectedIds}
      paginationOptions={paginationOptions}
      isFetching={isFetching}
      isLoadingBrandModelListError={isLoadingBrandModelListError}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      handleReload={handleReload}
      page={page}
      rowsPerPage={rowsPerPage}
      search={search}
      totalFiltered={totalFiltered}
      handleChangeStatus={handleChangeStatus}
      handleEditBrandModel={handleEditBrandModel}
      handleCreateBrandModel={handleCreateBrandModel}
      handleDeleteBrandModel={handleDeleteBrandModel}
      handleSelectBrandModel={handleSelectBrandModel}
      handleSelectAvailable={handleSelectAvailable}
      handleSelectAll={handleSelectAll}
      handleDeselectAll={handleDeselectAll}
      lang={lang}
    />
  )
}