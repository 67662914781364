import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import {
  getEditProgramProductDropdown,
  resetProductDropdown,
  getStockModuleSetting,
  getProgramDetails,
  updateProgramDetails
} from "modules/stockflow/redux";
import EditIncentiveProgramComponent from './edit-incentive-program.page';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { DEALEAR_PROGRAM_LIST_PAGE } from "modules/stockflow/config/stockflow.route"
import { unwrapResult } from '@reduxjs/toolkit';
import { selectLoading } from "modules/notification";

export default function EditIncentiveProgramContainer() {
  const dispatch = useDispatch();
  const history = useHistory()
  const match = useRouteMatch();
  const uuid = match.params.programId;
  const productDropdown = useSelector(state => state.stockflowIncentiveProgram.productDropdown);
  const maxTier = useSelector(state => state.stockflowIncentiveProgram.maxTier)
  const programDetails = useSelector(state => state.stockflowIncentiveProgram.details)
  const isFetching = useSelector(state => selectLoading(state, getProgramDetails.typePrefix));
  const lang = useSelector(state => state.constant.languages);
  const isSubmitting = useSelector(state => selectLoading(state, updateProgramDetails.typePrefix));

  useEffect(() => {
    dispatch(getEditProgramProductDropdown(uuid))
    dispatch(getStockModuleSetting())
    dispatch(getProgramDetails(uuid))

    return () => {
      dispatch(resetProductDropdown())
    }
  }, [dispatch, uuid])

  const handleSubmit = (values) => {

    dispatch(updateProgramDetails({
      id: uuid,
      name: values.name.trim(),
      products: values.assignProduct,
      percentages: values.tier,
      isSelectAll: false
    }))
      .then(unwrapResult)
      .then((response) => {
        history.push(DEALEAR_PROGRAM_LIST_PAGE)
      })
  }

  return (
    <EditIncentiveProgramComponent
      isFetching={isFetching}
      productDropdown={productDropdown}
      maxTier={maxTier}
      handleSubmit={handleSubmit}
      programDetails={programDetails}
      lang={lang}
      isSubmitting={isSubmitting}
    />
  )
}