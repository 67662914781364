import React from "react";
import { 
    BranchSalesPanel,
    CampaignConversionPanel,
    DemographicPanel,
    FilterPanelV3,
    MiscellaneousPanel,
    ProductPanel,
} from "modules/reporting/components/panel";
import { Content } from "modules/reporting/components/layout";
// import { QrTrendingPanel } from "./components";

export default function QRDataPage({
    filter,
    tagUrl,
    currentFilter,
    pageMode,
    translate
}) {
    
    const pageType = "qr";
    return (
        <>
            <FilterPanelV3
                filter={filter}
                currentFilter={currentFilter}
                pageMode={pageMode}
                translate={translate}
            /> 
            
            <Content>
                {/* trending card row --- START */}
                <ProductPanel
                    currentFilter={currentFilter} 
                    urlTotalScan={tagUrl + "get-total-scan-qr"} 
                    urlTotalSuspended={tagUrl + "get-total-suspended-qr"} 
                    urlTotalDistributionPerformance={tagUrl + "get-distribution-performance"}
                    urlProduct = {tagUrl + "qr-code-scan-count"}
                    urlSuspendedProduct = {tagUrl + "get-top-suspended-product"}
                    urlSuspendedQr = {tagUrl + "get-top-suspended-qr"}
                    // urlProduct={"http://localhost:9080/parallelCoordinateData"}
                    urlScanAgainstSuspended = {tagUrl + "scan-vs-suspended"}

                    pageType={pageType}
                    translate={translate}
                />
                {/* trending card row --- END */}

                {/* campaigns conversion --- START */}
                <CampaignConversionPanel 
                    currentFilter={currentFilter} 
                    url={tagUrl + "get-campaigns-conversion"}
                    // url={"http://localhost:9080/campaignConversionDrilldown"} 

                    pageType={pageType}
                    translate={translate}
                />
                {/* campaigns conversion --- END */}

                {/* demographic card row --- START*/}
                <DemographicPanel 
                    currentFilter={currentFilter} 
                    urlAge={tagUrl + "get-total-age"} 
                    urlGender={tagUrl + "get-total-gender" } 
                    urlGenderAge={tagUrl + "get-total-gender-age"}
                    urlCountry={tagUrl + "get-country"}
                    urlCity={tagUrl + "get-city"}

                    pageType={pageType}
                    translate={translate}
                />
                {/* demographic card row --- END */}

                {/* branch sales detail row --- START*/}
                {
                    !currentFilter.branchUuid ? 
                    (
                        <BranchSalesPanel 
                            url={tagUrl + "get-branch"}
                            currentFilter={currentFilter}
        
                            pageType={pageType}
                            translate={translate}
                        /> 
                    ): null
                }
                {/* branch sales detail row --- END*/}  

                {/* miscellaneous card row --- START */}
                <MiscellaneousPanel 
                    currentFilter={currentFilter}
                    urlPlatform={tagUrl + "get-total-platform"}
                    urlDevice={tagUrl + "get-total-device"}
                    urlBrowser={tagUrl + "get-total-browser"}
                    urlLanguage={tagUrl + "get-total-language"}

                    translate={translate}
                />
                {/* miscellaneous card row --- END */}
            </Content>
            
        </>
    )
}