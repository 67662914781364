import React from "react";
import {
  Box,
  makeStyles,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from "@material-ui/core";
import { StyledTableCell, StyledTableRow } from "components/table";
import { SkeletonTableRow } from "components/skeleton/skeleton.component";
import { ActionButton } from "components/button";
import { TablePaginationActions } from "components/tablePagination";
import RefreshTable from "components/table/refreshTable";
import { getLang } from "app/feature/constants";
import moment from "moment/moment";
import 'moment/min/locales.min';

const useStyle = makeStyles(theme => ({
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    borderCollapse: "separate",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0
    },
    "& th,td": {
      fontSize: ".75rem",
      borderRight: "1px solid rgba(58, 77, 84, 0.1)",
      padding: theme.spacing(1, 2, 1, 2)
    },
    "& th": {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightBold
    },
    "& th:last-child, td:last-child": {
      width: 55
    }
  },
  tableContainer: {
    maxHeight: 500,
    overflowY: "auto"
  },
  emptyContainer: {
    backgroundColor: theme.palette.background.paper,
    textAlign: "center"
  },
  button: {
    display: "flex",
    alignItems: "center",
    padding: 0,
    minWidth: 0,
    marginLeft: theme.spacing(1)
  },
  iconBtn: {
    borderRadius: theme.spacing(0.5),
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shortest,
    }),
    padding: "3px 5px"
  },
  pendingColor: {
    color: "#FFBF00"
  },
  activeColor: {
    color: "#4BCB0F"
  },
  deactiveColor: {
    color: "#9CA6A9"
  },
}));

export default function RespondentListingTableComponent({
  data,
  isFetching,
  page,
  rowsPerPage,
  totalRecords,
  handleChangePage,
  handleChangeRowsPerPage,
  handleView,
  isLoadingRespondentError,
  handleReload,
  paginationOptions,
  lang,
  language
}) {
  const classes = useStyle()
  
  if(language==="ZH")
  {
    moment.locale('zh-cn')
  }else if(language==="TL")
  {
    moment.locale('tl-ph')
  }else
  {
    moment.locale(language)
  }

  return (
    <>
      {data.length > 0 || isFetching ? (
        <TableContainer>
          <Table
            className={classes.table}
            size="small"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <StyledTableCell>{getLang(lang, 'label.NUM')}</StyledTableCell>
                <StyledTableCell>{getLang(lang, 'label.SURVEY')}</StyledTableCell>
                <StyledTableCell>{getLang(lang, 'label.EMAIL')}</StyledTableCell>
                <StyledTableCell>{getLang(lang, 'label.PRODUCT')}</StyledTableCell>
                <StyledTableCell>{getLang(lang, 'label.SERIAL_NUMBER')}</StyledTableCell>
                <StyledTableCell>{getLang(lang, 'label.SUBMITTED_DATE')}</StyledTableCell>
                <StyledTableCell>{getLang(lang, 'label.ACTION')}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isFetching ? (
                [...new Array(5)].map((i, index) => (
                  <SkeletonTableRow key={index} columnCount={7} />
                ))
              ) : (
                data.map((item, index) => {
                  const actionItems = [
                    {
                      label: "View Details",
                      action: () => handleView({ id: item.id })
                    }
                  ];

                  return (
                    <StyledTableRow key={index} hover tabIndex={-1}>
                      <StyledTableCell>{index + 1 + rowsPerPage * page}</StyledTableCell>
                      <StyledTableCell>{item.name}</StyledTableCell>
                      <StyledTableCell>{item.email}</StyledTableCell>
                      <StyledTableCell>{item.product_name}</StyledTableCell>
                      <StyledTableCell>{item.serial_number}</StyledTableCell>
                      <StyledTableCell>{moment(item.created_at).format('lll')}</StyledTableCell>
                      <StyledTableCell>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                          <ActionButton actionItems={actionItems} />
                        </div>
                      </StyledTableCell>
                    </StyledTableRow>
                  )
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
      ) : !isLoadingRespondentError && (
        <Box>
          <Table
            className={classes.table}
            size="small"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <StyledTableCell>{getLang(lang, 'label.NUM')}</StyledTableCell>
                <StyledTableCell>{getLang(lang, 'label.SURVEY')}</StyledTableCell>
                <StyledTableCell>{getLang(lang, 'label.EMAIL')}</StyledTableCell>
                <StyledTableCell>{getLang(lang, 'label.PRODUCT')}</StyledTableCell>
                <StyledTableCell>{getLang(lang, 'label.SERIAL_NUMBER')}</StyledTableCell>
                <StyledTableCell>{getLang(lang, 'label.SUBMITTED_DATE')}</StyledTableCell>
                <StyledTableCell>{getLang(lang, 'label.ACTION')}</StyledTableCell>
              </TableRow>
            </TableHead>
          </Table>
          <Box py={9} className={classes.emptyContainer}>
            <Typography variant='body1'>{getLang(lang, 'message.info.YOU_DONT_HAVE_RESPONDENT')}</Typography>
          </Box>
        </Box>

      )}

      {!isFetching && isLoadingRespondentError && (
        <RefreshTable handleReload={handleReload} />
      )}

      {!isFetching && data.length > 0 && (
        <TablePagination
          style={{
            backgroundColor: "#fff",
            boxShadow: "0px -1px 0px rgba(58, 77, 84, 0.15)",
          }}
          ActionsComponent={TablePaginationActions}
          rowsPerPageOptions={paginationOptions}
          component="div"
          count={totalRecords}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage={getLang(lang, 'label.ROWS_PER_PAGE')}
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${getLang(lang, 'label.OF')} ${count}`}
        />
      )}
    </>
  )
}