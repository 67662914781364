import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { selectLoading } from 'modules/notification'
import {
  changeBulkEditFieldUpdate,
  resetBulkEditList,
  resetProductListing,
  resetProductListingCountings
} from "modules/product-mgmt/redux/slice/products.slice"
import {
  getBulkEditProductListing, updateBulkProduct,
} from "modules/product-mgmt/redux/action/products.action"
import { getCurrencyDropdown } from "modules/product-mgmt/redux/action/productPage.action"
import { getBrandModelDropdown } from "modules/product-mgmt/redux/action/brandModels.action"
import { getProductCategoryDropdown } from "modules/product-mgmt/redux/action/productCategory.action"
import { getAttributeDropdown } from "modules/product-mgmt/redux/action/attribute.action"
import BulkEditListPanelComponent from './bulkEditListPanel.component'
import { generatePaginationOptions } from "lib/helper"
import { ROOT } from 'modules/product-mgmt/config/product-mgmt.route'
import GeneralDialog from "components/dialog/generalDialog"
import AlertIcon from 'assets/svg/alert.svg'
import { PRODUCT_PRODUCT_LIST_EDIT } from "lib/constants/accessRights"
import { getLang } from "app/feature/constants"

function BulkEditListPanelContainer() {
  const hasProductEditAccessRight = useSelector(state => state.profile.accessRights.includes(PRODUCT_PRODUCT_LIST_EDIT))
  const products = useSelector(state => state.products.products)
  const bulkEditFieldUpdateDetails = useSelector(state => state.products.bulkEditFieldUpdateDetails)
  const bulkEditListFilter = useSelector(state => state.products.bulkEditListFilter)
  const isSelectAll = useSelector(state => state.products.isSelectAll)
  const selectedIds = useSelector(state => state.products.selectedId)
  const totalRecords = useSelector(state => state.products.totalRecords)
  const totalFiltered = useSelector(state => state.products.totalFiltered)
  const isFetching = useSelector(state => selectLoading(state, getBulkEditProductListing.typePrefix))
  const isError = useSelector(state => state.products.isLoadingProductsError)
  const categoryDropdown = useSelector(state => state.products.categoryList.categoryDropdown)
  const isQuickEdit = useSelector(state => state.products.isQuickEdit)
  const brandModelDropdown = useSelector(state => state.products.brandModelList.brandModelDropdown)
  const isBrandModelDropdownLoading = useSelector(state => selectLoading(state, getBrandModelDropdown.typePrefix))
  const attributeDropdown = useSelector(state => state.products.attributeList.attributeDropdown)
  const isAttributeDropdownLoading = useSelector(state => selectLoading(state, getAttributeDropdown.typePrefix))
  const currencyDropdown = useSelector(state => state.productPage.currencyDropdown)
  const isUpdateBulkProductLoading = useSelector(state => selectLoading(state, updateBulkProduct.typePrefix))
  const lang = useSelector(state => state.constant.languages)

  const history = useHistory()
  const dispatch = useDispatch()

  const [categoryDropdownSearch, setCategoryDropdownSearch] = useState("")
  const [brandModelDropdownSearch, setBrandModelDropdownSearch] = useState("")
  const [attributeDropdownSearch, setAttributeDropdownSearch] = useState("")
  const [currencyCodeDropdownSearch, setCurrencyCodeDropdownSearch] = useState("")
  const [paginationOptions, setPaginationOptions] = useState([])
  const [tableFilter, setTableFilter] = useState({
    page: 0,
    rowsPerPage: 25,
  })
  const [confirmationDialogModal, setConfirmationDialogModal] = useState(false)

  useEffect(() => {
    dispatch(resetProductListingCountings())

    return () => {
      dispatch(resetBulkEditList())
      dispatch(resetProductListing())
    }
  }, [dispatch])

  useEffect(() => {
    if (!hasProductEditAccessRight || (!bulkEditListFilter.isselectedall && !bulkEditListFilter.uuids.length)) {
      history.push(ROOT)
    } else {
      dispatch(getBulkEditProductListing({
        filter: bulkEditListFilter,
        length: tableFilter.rowsPerPage,
        start: tableFilter.rowsPerPage * tableFilter.page,
      }))
    }
  }, [
    dispatch, 
    bulkEditListFilter, 
    tableFilter, 
    hasProductEditAccessRight, 
    history
  ])

  useEffect(() => {
    setPaginationOptions(generatePaginationOptions(totalFiltered))
  }, [totalFiltered])

  useEffect(() => {
    dispatch(getProductCategoryDropdown({ search: categoryDropdownSearch }))
  }, [dispatch, categoryDropdownSearch])

  useEffect(() => {
    dispatch(getBrandModelDropdown({ search: brandModelDropdownSearch }))
  }, [dispatch, brandModelDropdownSearch])

  useEffect(() => {
    dispatch(getAttributeDropdown({ search: attributeDropdownSearch }))
  }, [dispatch, attributeDropdownSearch])

  useEffect(() => {
    dispatch(getCurrencyDropdown({ search: currencyCodeDropdownSearch }))
  }, [dispatch, currencyCodeDropdownSearch])

  const handleBrandModelSearch = (value) => {
    setBrandModelDropdownSearch(value)
  }

  const handleAttributeSearch = (value) => {
    setAttributeDropdownSearch(value)
  }
  
  const handleChangePage = (event, newPage) => {
    setTableFilter({
      page: newPage,
      rowsPerPage: tableFilter.rowsPerPage,
    })
  }

  const handleChangeRowsPerPage = event => {
    setTableFilter({
      page: 0,
      rowsPerPage: event.target.value,
    })
  }

  const handleCategorySearch = (value) => {
    setCategoryDropdownSearch(value)
  }

  const handleCurrencyCodeSearch = (value) => {
    setCurrencyCodeDropdownSearch(value)
  }

  const handleProceed = () => {
    setConfirmationDialogModal(true)
  }

  const handleUpdateProducts = () => {
    let generalUpdateDetail = {
      category: !bulkEditFieldUpdateDetails.category ? "" : bulkEditFieldUpdateDetails.category.uuid || bulkEditFieldUpdateDetails.category.id || "",
      brand: bulkEditFieldUpdateDetails.brand.id === undefined ? "" : bulkEditFieldUpdateDetails.brand.id === "-1" ? "" : bulkEditFieldUpdateDetails.brand.id,
      model: bulkEditFieldUpdateDetails.model.id === undefined ? "" : bulkEditFieldUpdateDetails.model.id === "-1" ? "" : bulkEditFieldUpdateDetails.model.id,
      attributeoptions: bulkEditFieldUpdateDetails.attribute ? bulkEditFieldUpdateDetails.attribute.map(a => a.optionsId) : null,
      nodes: bulkEditFieldUpdateDetails.nodes ? bulkEditFieldUpdateDetails.nodes.map(a => a.node_id) : null,
      scanlimit: bulkEditFieldUpdateDetails.scanLimit,
      sku: bulkEditFieldUpdateDetails.sku,
      currency_code: bulkEditFieldUpdateDetails.currencyCode?.alpha3 || '',
      unit_price: bulkEditFieldUpdateDetails.unitPrice,
      incentive_point: bulkEditFieldUpdateDetails.incentivePoint,
      status: bulkEditFieldUpdateDetails.status
    }

    dispatch(updateBulkProduct({
      filter: bulkEditListFilter,
      updateDetails: generalUpdateDetail
    }))
      .then(() => {
        history.push(ROOT)
      })
  }

  const handleBulkEditFieldUpdate = (field) => {
    dispatch(changeBulkEditFieldUpdate(field))
  }

  const handleReset = () => {
    dispatch(resetBulkEditList())
    dispatch(getBulkEditProductListing({
      filter: bulkEditListFilter,
      length: tableFilter.rowsPerPage,
      start: tableFilter.rowsPerPage * tableFilter.page,
    }))
  }

  return (
    <>
      <BulkEditListPanelComponent
        rows={products}
        page={tableFilter.page}
        rowsPerPage={tableFilter.rowsPerPage}
        paginationOptions={paginationOptions}
        selectedCount={!isSelectAll ? selectedIds.length : totalFiltered - selectedIds.length}
        isSelectAll={isSelectAll}
        selectedIds={selectedIds}
        isFetching={isFetching}
        isError={isError}
        totalFiltered={totalFiltered}
        categoryDropdown={categoryDropdown}
        handleCategorySearch={handleCategorySearch}
        isQuickEdit={isQuickEdit}
        brandModelDropdown={brandModelDropdown}
        isBrandModelDropdownLoading={isBrandModelDropdownLoading}
        handleBrandModelSearch={handleBrandModelSearch}
        attributeDropdown={attributeDropdown}
        handleAttributeSearch={handleAttributeSearch}
        isAttributeLoading={isAttributeDropdownLoading}
        currencyDropdown={currencyDropdown}
        handleCurrencyCodeSearch={handleCurrencyCodeSearch}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        totalRecords={totalRecords}
        handleProceed={handleProceed}
        handleBulkEditFieldUpdate={handleBulkEditFieldUpdate}
        handleReset={handleReset}
        lang={lang}
      />
      <GeneralDialog
        isOpen={confirmationDialogModal}
        handleClose={() => setConfirmationDialogModal(false)}
        handleProceed={handleUpdateProducts}
        title={getLang(lang, "label.BULK_EDIT")}
        description={getLang(lang, "paragraph.UPDATE_PRODUCT_QUESTION")}
        type="danger"
        icon={<img src={AlertIcon} alt="alert" />}
        isLoading={isUpdateBulkProductLoading}
      />
    </>
  )
}

export default BulkEditListPanelContainer