import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles, Box, Typography, Button } from "@material-ui/core";
import CustomTextField from "components/textField";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import ArrowBackRoundedIcon from "@material-ui/icons/ArrowBackRounded";
import { LOGIN } from "../../../config/public.route";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles(theme => ({
  title: {
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      fontSize: "smaller",
      marginBottom: theme.spacing(1)
    }
  }
}));

export default function ResetInputcomponent({ handleSubmit, isLoading, lang }) {
  const classes = useStyle();
  return (
    <Box p={4}>
      <Typography variant="body1" className={classes.title} gutterBottom>
        {getLang(lang, "paragraph.FORGOT_PASSWORD_QUESTION")}
      </Typography>
      <Typography variant="body2" color="textPrimary" gutterBottom>
        {getLang(lang, "paragraph.ENTER_EMAIL_ADDRESS")}
      </Typography>
      <Formik
        initialValues={{ email: "" }}
        validationSchema={Yup.object({
          email: Yup.string()
            .email(getLang(lang,"message.error.FORM_VALIDATE_EMAIL"))
            .required(getLang(lang,"message.error.FORM_VALIDATE_REQUIRED"))
        })}
        onSubmit={handleSubmit}
      >
        {formik => (
          <Box mt={3}>
            <form onSubmit={formik.handleSubmit}>
              <CustomTextField
                label={<><span>{getLang(lang, "label.EMAIL")} </span><span style={{ color: "#DE350B", display: 'inline-block' }}>*</span></>}
                variant="outlined"
                size="small"
                fullWidth={true}
                error={
                  formik.touched.email && formik.errors.email ? true : false
                }
                helperText={ErrorMessage({ name: "email" })}
                {...formik.getFieldProps("email")}
              />
              <Box mt={3} display="flex" justifyContent="space-between">
                <Button
                  component={RouterLink}
                  to={LOGIN}
                  startIcon={<ArrowBackRoundedIcon />}
                >
                  {getLang(lang, "label.BACK")}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  disableElevation
                  disabled={isLoading}
                >
                  {getLang(lang, "label.SEND")}
                </Button>
              </Box>
            </form>
          </Box>
        )}
      </Formik>
    </Box>
  );
}
