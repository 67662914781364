import React from "react";
import { makeStyles, Box } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyle = makeStyles(theme => ({
  root: {
    width: "100%"
  }
}));

export default function LoadingPanelComponent() {
  const classes = useStyle();

  return (
    <Box className={classes.root}>
      <Skeleton variant="text" width={350} height={40} />
      <Skeleton variant="text" width={650} height={40} />
      <Skeleton variant="text" width={650} height={40} />
    </Box>
  );
}
