import React from 'react'
import {
  Box,
  makeStyles,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TableCell
} from '@material-ui/core';
//import { StyledTableCell, StyledTableRow } from 'components/table';
import { SkeletonTableRow } from 'components/skeleton/skeleton.component';
import ErrorIcon from '@material-ui/icons/Error';
import { getLang } from 'app/feature/constants';

const useStyle = makeStyles(theme => ({
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    borderCollapse: "separate",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0
    },
    "& th,td": {
      fontSize: ".75rem",
      borderBottom: "1px solid rgba(58, 77, 84, 0.1)",
      padding: theme.spacing(1, 2, 1, 2)
    },
    "& th": {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightBold
    },
  },
  tableContainer: {
    maxHeight: 175,
    overflowY: "auto"
  },
  emptyContainer: {
    backgroundColor: theme.palette.background.paper,
    justifyContent: "center",
    border: "1px solid rgba(0, 0, 0, 0.1);",
    borderRadius: 4,
    display: "flex"
  },
  button: {
    display: "flex",
    alignItems: "center",
    padding: 0,
    minWidth: 0,
    marginLeft: theme.spacing(1)
  },
  iconBtn: {
    borderRadius: theme.spacing(0.5),
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shortest,
    }),
    padding: "3px 5px"
  },
}));

export default function ItemTableComponent({ data, isFetching, lang }) {
  const classes = useStyle()
  return (
    <>
      {data.length > 0 ? (
        <TableContainer style={{
          overflowX: "hidden",
          borderRadius: 3,
          border: "1px solid rgba(58, 77, 84, 0.1)",
          maxHeight: 200
        }}>
          <Table
            className={classes.table}
            size="small"
          >
            <TableHead>
              <TableRow>
                <TableCell>{getLang(lang,"label.GIFT")}</TableCell>
                <TableCell />
                <TableCell style={{ textAlign: "center" }}>{getLang(lang,"label.QUANTITY")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isFetching ? (
                [...new Array(5)].map(i => (
                  <SkeletonTableRow columnCount={6} />
                ))
              ) : (
                data.map((item, index) => {
                  return (
                    <TableRow
                      key={index}
                      hover
                      tabIndex={-1}
                    >
                      <TableCell>
                        <img
                          style={{
                            width: "50px",
                            height: "50px",
                            objectFit: "contain"
                          }}
                          src={item.img}
                          alt="profile"
                        />
                      </TableCell>
                      <TableCell>{item.name}</TableCell>
                      <TableCell style={{ textAlign: "center" }}>{item.quantity}</TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box>
           <Table
            className={classes.table}
            size="small"
          >
            <TableHead>
              <TableRow>
                <TableCell>{getLang(lang,"label.GIFT")}</TableCell>
                <TableCell />
                <TableCell style={{ textAlign: "center" }}>{getLang(lang,"label.QUANTITY")}</TableCell>
              </TableRow>
            </TableHead>
            </Table>
            <Box py={9} className={classes.emptyContainer}>
          <ErrorIcon style={{ paddingRight: 5 }}></ErrorIcon>
          <Typography variant="body1">
            {getLang(lang,"label.NO_MODULE_ADDED")}
          </Typography>
        </Box>
        </Box>
       
      )}
    </>
  )
}