import { getApiLang } from 'app/feature/constants';
import { addAlert, setLoading } from 'modules/notification';
import {
  additionalJoin,
  newJoin,
  validateInvitationCode,
  verifyUsedEmail,
  validateEmailVertificationToken,
  sendVerificationEmail,
  sendResetPasswordLink,
  validateResetPasswordToken,
  resetPassword,
  verifyPhoneNumber,
  validateOTP
} from "../action"

const newJoinMiddleware = ({ dispatch, getState }) => next => action => {
  next(action);

  const { type, payload } = action;
  const lang = getState().constant.languages;

  switch (type) {
    case newJoin.pending.type:
      dispatch(setLoading({ id: newJoin.typePrefix, state: true }));
      break;
    case newJoin.fulfilled.type:
      dispatch(setLoading({ id: newJoin.typePrefix, state: false }));
      break;
    case newJoin.rejected.type:
      dispatch(setLoading({ id: newJoin.typePrefix, state: false }));
      dispatch(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
      break;
    default:
      break;
  }
};

const additionalJoinMiddleware = ({ dispatch, getState }) => next => action => {
  next(action);

  const { type, payload } = action;
  const lang = getState().constant.languages;

  switch (type) {
    case additionalJoin.pending.type:
      dispatch(setLoading({ id: additionalJoin.typePrefix, state: true }));
      break;
    case additionalJoin.fulfilled.type:
      dispatch(setLoading({ id: additionalJoin.typePrefix, state: false }));
      break;
    case additionalJoin.rejected.type:
      dispatch(setLoading({ id: additionalJoin.typePrefix, state: false }));
      dispatch(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
      break;
    default:
      break;
  }
}

const validateInvitationCodeMiddleware = ({ dispatch }) => next => action => {
  next(action);

  const { type } = action;

  switch (type) {
    case validateInvitationCode.pending.type:
      dispatch(setLoading({ id: validateInvitationCode.typePrefix, state: true }));
      break;
    case validateInvitationCode.fulfilled.type:
      dispatch(setLoading({ id: validateInvitationCode.typePrefix, state: false }));
      break;
    case validateInvitationCode.rejected.type:
      dispatch(setLoading({ id: validateInvitationCode.typePrefix, state: false }));
      break;
    default:
      break;
  }
}

const verifyUsedEmailMiddleware = ({ dispatch }) => next => action => {
  next(action);

  const { type } = action;

  switch (type) {
    case verifyUsedEmail.pending.type:
      dispatch(setLoading({ id: verifyUsedEmail.typePrefix, state: true }));
      break;
    case verifyUsedEmail.fulfilled.type:
      dispatch(setLoading({ id: verifyUsedEmail.typePrefix, state: false }));
      break;
    case verifyUsedEmail.rejected.type:
      dispatch(setLoading({ id: verifyUsedEmail.typePrefix, state: false }));
      break;
    default:
      break;
  }
}

const validateEmailVertificationTokenMiddleware = ({ dispatch }) => next => action => {
  next(action);

  const { type } = action;

  switch (type) {
    case validateEmailVertificationToken.pending.type:
      dispatch(setLoading({ id: validateEmailVertificationToken.typePrefix, state: true }));
      break;
    case validateEmailVertificationToken.fulfilled.type:
      dispatch(setLoading({ id: validateEmailVertificationToken.typePrefix, state: false }));
      break;
    case validateEmailVertificationToken.rejected.type:
      dispatch(setLoading({ id: validateEmailVertificationToken.typePrefix, state: false }));
      break;
    default:
      break;
  }
}

const sendVerificationEmailMiddleware = ({ dispatch }) => next => action => {
  next(action);

  const { type } = action;

  switch (type) {
    case sendVerificationEmail.pending.type:
      dispatch(setLoading({ id: sendVerificationEmail.typePrefix, state: true }));
      break;
    case sendVerificationEmail.fulfilled.type:
      dispatch(setLoading({ id: sendVerificationEmail.typePrefix, state: false }));
      break;
    case sendVerificationEmail.rejected.type:
      dispatch(setLoading({ id: sendVerificationEmail.typePrefix, state: false }));
      break;
    default:
      break;
  }
}

const sendResetPasswordLinkMiddleware = ({ dispatch }) => next => action => {
  next(action);

  const { type } = action;

  switch (type) {
    case sendResetPasswordLink.pending.type:
      dispatch(setLoading({ id: sendResetPasswordLink.typePrefix, state: true }));
      break;
    case sendResetPasswordLink.fulfilled.type:
      dispatch(setLoading({ id: sendResetPasswordLink.typePrefix, state: false }));
      break;
    case sendResetPasswordLink.rejected.type:
      dispatch(setLoading({ id: sendResetPasswordLink.typePrefix, state: false }));
      break;
    default:
      break;
  }
}
const verifyPhoneNumberMiddleware = ({ dispatch, getState }) => next => action => {
  next(action);

  const { type, payload } = action;
  const lang = getState().constant.languages;

  switch (type) {
    case verifyPhoneNumber.pending.type:
      dispatch(setLoading({ id: sendResetPasswordLink.typePrefix, state: true }));
      break;
    case verifyPhoneNumber.fulfilled.type:
      dispatch(setLoading({ id: sendResetPasswordLink.typePrefix, state: false }));
      dispatch(addAlert({ severity: "success", message: getApiLang(lang, payload.code) }));
      break;
    case verifyPhoneNumber.rejected.type:
      dispatch(setLoading({ id: sendResetPasswordLink.typePrefix, state: false }));
      dispatch(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
      break;
    default:
      break;
  }
}
const validateOTPMiddleware = ({ dispatch, getState }) => next => action => {
  next(action);

  const { type, payload } = action;
  const lang = getState().constant.languages;

  switch (type) {
    case validateOTP.pending.type:
      dispatch(setLoading({ id: sendResetPasswordLink.typePrefix, state: true }));
      break;
    case validateOTP.fulfilled.type:
      dispatch(setLoading({ id: sendResetPasswordLink.typePrefix, state: false }));
      dispatch(addAlert({ severity: "success", message: getApiLang(lang, payload.code) }));
      break;
    case validateOTP.rejected.type:
      dispatch(setLoading({ id: sendResetPasswordLink.typePrefix, state: false }));
      dispatch(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
      break;
    default:
      break;
  }
}


const validateResetPasswordTokenMiddleware = ({ dispatch }) => next => action => {
  next(action);

  const { type } = action;

  switch (type) {
    case validateResetPasswordToken.pending.type:
      dispatch(setLoading({ id: validateResetPasswordToken.typePrefix, state: true }));
      break;
    case validateResetPasswordToken.fulfilled.type:
      dispatch(setLoading({ id: validateResetPasswordToken.typePrefix, state: false }));
      break;
    case validateResetPasswordToken.rejected.type:
      dispatch(setLoading({ id: validateResetPasswordToken.typePrefix, state: false }));
      break;
    default:
      break;
  }
}

const resetPasswordMiddleware = ({ dispatch }) => next => action => {
  next(action);

  const { type } = action;

  switch (type) {
    case resetPassword.pending.type:
      dispatch(setLoading({ id: resetPassword.typePrefix, state: true }));
      break;
    case resetPassword.fulfilled.type:
      dispatch(setLoading({ id: resetPassword.typePrefix, state: false }));
      break;
    case resetPassword.rejected.type:
      dispatch(setLoading({ id: resetPassword.typePrefix, state: false }));
      break;
    default:
      break;
  }
}

export default [
  newJoinMiddleware,
  additionalJoinMiddleware,
  validateInvitationCodeMiddleware,
  verifyUsedEmailMiddleware,
  validateEmailVertificationTokenMiddleware,
  sendVerificationEmailMiddleware,
  sendResetPasswordLinkMiddleware,
  validateResetPasswordTokenMiddleware,
  resetPasswordMiddleware,
  validateOTPMiddleware,
  verifyPhoneNumberMiddleware
]