/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { selectLoading } from "modules/notification";
import LoadingComponent from "components/loading";
import { STOCKFLOW_STOCKTAKE_VIEW } from "lib/constants/accessRights";
import { ROOT } from "modules/dashboard";
import StocktakeDetailComponent from "./stockTakeDetails.component";
import { getLang } from "app/feature/constants";
import GeneralDialog from "components/dialog/generalDialog";
import {
  cancelStockTake,
  getStockTakeDetail,
  updateStockTake,
  updateStockTakeInfo,
  endStockTake,
  resetStockTake,
} from "modules/stockflow/redux";

function StocktakeDetailsContainer() {
  const history = useHistory();
  const dispatch = useDispatch();
  const match = useRouteMatch();

  const id = match.params.uuid;
  const lang = useSelector((state) => state.constant.languages);
  const language = useSelector((state) => state.profile.language);
  const hasStockflowViewAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(STOCKFLOW_STOCKTAKE_VIEW)
  );
  const stockTake = useSelector((state) => state.stockflowStockTake.stocktake);
  const isError = useSelector(
    (state) => state.stockflowStockTake.isLoadingStockTakeError
  );
  const isFetching = useSelector((state) =>
    selectLoading(state, getStockTakeDetail.typePrefix)
  );

  const isUpdating = useSelector((state) =>
    selectLoading(state, updateStockTake.typePrefix)
  );
  const isCancelling = useSelector((state) =>
    selectLoading(state, cancelStockTake.typePrefix)
  );
  const isEnding = useSelector((state) =>
    selectLoading(state, endStockTake.typePrefix)
  );

  const [activeIndex, setActiveIndex] = useState(0);
  const [status, setStatus] = useState(0);

  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [showEndDialog, setShowEndDialog] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (!hasStockflowViewAccessRight) {
      history.push("/admin/dashboard");
    }
  }, [history, hasStockflowViewAccessRight]);

  useEffect(() => {
    return () => {
      dispatch(resetStockTake());
    };
  }, []);

  useEffect(() => {
    if (id) {
      fetchData();
    } else {
      history.push(ROOT);
    }
  }, [history, id, dispatch]);

  if (!hasStockflowViewAccessRight) return <LoadingComponent />;

  const fetchData = () => {
    dispatch(getStockTakeDetail(id));
  };

  const handleChangeType = (event, index) => {
    if (activeIndex !== index) {
      setActiveIndex(index);
    }
  };

  const cancelStocktake = () => {
    dispatch(cancelStockTake(id)).then(() => {
      fetchData();
      setShowCancelDialog(false);
    });
  };

  const endStocktake = () => {
    dispatch(endStockTake(id)).then(() => {
      fetchData();
      setShowEndDialog(false);
    });
  };

  const handleUpdate = (values) => {
    dispatch(
      updateStockTake({
        id: id,
        name: values.name,
      })
    ).then(() => {
      dispatch(
        updateStockTakeInfo({
          name: "name",
          value: values.name,
        })
      );
      setIsEditing(false);
    });
  };

  return (
    <>
      <StocktakeDetailComponent
        stockTake={stockTake}
        isFetching={isFetching}
        isError={isError}
        language={language}
        handleReload={fetchData}
        lang={lang}
        handleChangeType={handleChangeType}
        activeIndex={activeIndex}
        id={id}
        handleCancel={() => setShowCancelDialog(true)}
        handleEnd={() => setShowEndDialog(true)}
        handleUpdate={handleUpdate}
        boxStatusIndex={status}
        changeBoxStatus={(event, index) => {
          setStatus(index);
        }}
        isUpdating={isUpdating}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
      />
      <GeneralDialog
        isLoading={isCancelling}
        isOpen={!!showCancelDialog}
        handleClose={() => {
          setShowCancelDialog(false);
        }}
        handleProceed={cancelStocktake}
        title={getLang(lang, "label.CANCEL_STOCK_TAKE")}
        description={getLang(lang, "paragraph.CANCEL_STOCK_TAKE_QUESTION")}
        type="danger"
        proceedLabel={getLang(lang, "label.CONFIRM")}
      />
      <GeneralDialog
        isLoading={isEnding}
        isOpen={!!showEndDialog}
        handleClose={() => {
          setShowEndDialog(false);
        }}
        handleProceed={endStocktake}
        title={getLang(lang, "label.CONFIRM_END_STOCK_TAKE")}
        description={
          stockTake?.name
            ? getLang(lang, "paragraph.END_STOCK_TAKE_QUESTION", {
                name: stockTake.name,
              })
            : getLang(lang, "paragraph.END_STOCK_TAKE_QUESTION")
        }
        type="success"
        proceedLabel={getLang(lang, "label.CONFIRM")}
      />
    </>
  );
}

export default StocktakeDetailsContainer;
