import axios from "axios"

const url = "/distributor/admin/api/v1/dealers/get-dealers-dropdown";

export function GetDealerListDropdown(branchUuid = 'get-all-dealers') {
  return new Promise((resolve, reject) => {
    axios.get(`${url}/${branchUuid}`)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data))
  })
}