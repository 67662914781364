import ConfirmationDialog from "components/dialog/confirmation";
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { fetchLoyaltyPointListing, setType, changeStatusLoyaltyPoint, updateLoyaltyProgramPageSetting } from 'modules/loyalty-program/redux'
import { connect, useDispatch, useSelector } from 'react-redux'
import { AddPointDialog } from 'modules/loyalty-program/components/dialog'
import PointListingPanel from './pointListPanel.component'
import { selectLoading } from 'modules/notification'
import { unwrapResult } from '@reduxjs/toolkit'
import { LOYALTY_PROGRAM_ACTIVATE_OR_DEACTIVATE, LOYALTY_PROGRAM_ADD, LOYALTY_PROGRAM_EDIT } from "lib/constants/accessRights";
import { generatePaginationOptions } from "lib/helper";
import { getLang } from "app/feature/constants";
import { getAllProducts } from "../../../../product-mgmt/redux/action/products.action";

const PointListingPanelContainer = forwardRef((_, ref) => {
  const hasLoyaltyProgramAddAccessRight = useSelector(state => state.profile.accessRights.includes(LOYALTY_PROGRAM_ADD));
  const hasLoyaltyProgramEditAccessRight = useSelector(state => state.profile.accessRights.includes(LOYALTY_PROGRAM_EDIT));
  const hasLoyaltyProgramActivateOrDeactivateAccessRight = useSelector(state => state.profile.accessRights.includes(LOYALTY_PROGRAM_ACTIVATE_OR_DEACTIVATE));
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [search, setSearch] = useState({ search: "", product: [] })
  const [isDeactivateDialogOpen, setIsDeactivateDialogOpen] = useState(false);
  const [isActivateDialogOpen, setIsActivateDialogOpen] = useState(false);
  const [changeStatusTargetId, setChangeStatusTargetId] = useState("");
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [paginationOptions, setPaginationOptions] = useState([]);

  const dispatch = useDispatch()
  const data = useSelector((state) => state.loyaltyProgram.pointListTable.data);
  const totalRecords = useSelector(state => state.loyaltyProgram.pointListTable.totalRecords)
  const filteredTotalRecords = useSelector(state => state.loyaltyProgram.pointListTable.filteredTotalRecords)
  const isStatusUpdating = useSelector((state) => selectLoading(state, changeStatusLoyaltyPoint.typePrefix));
  const isFetching = useSelector(state => selectLoading(state, fetchLoyaltyPointListing.typePrefix))
  const productDropdown = useSelector(state => state.products.allProducts);
  const isFetchingProduct = useSelector(state => selectLoading(state, getAllProducts.typePrefix));
  const lang = useSelector(state => state.constant.languages)

  useImperativeHandle(ref, () => ({
    openCreateLoyaltyProgramModal: () => {
      dispatch(setType({ index: "null", type: "create" }));
      setIsCreateDialogOpen(true);
    },
  }));

  useEffect(() => {
    dispatch(getAllProducts());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchLoyaltyPointListing({
      length: rowsPerPage,
      start: rowsPerPage * page,
      search: search.searchString,
      product: search.product.map(item => item.id),
    }));
  }, [search, rowsPerPage, page, dispatch])

  useEffect(() => {
    setPaginationOptions(generatePaginationOptions(totalRecords))
  }, [totalRecords])

  const handleFetchLoyaltyPointList = () => {
    dispatch(fetchLoyaltyPointListing({
      length: rowsPerPage,
      start: rowsPerPage * page,
      search: search.searchString,
      product: search.product.map(item => item.id),
    }));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage)

    dispatch(
      updateLoyaltyProgramPageSetting({
        start: rowsPerPage * newPage,
      })
    );
  }

  const handleChangeRowsPerPage = event => {
    setPage(0)
    setRowsPerPage(event.target.value)

    dispatch(
      updateLoyaltyProgramPageSetting({
        length: event.target.value,
        start: 0,
      })
    );
  }

  const handleSearch = value => {
    setPage(0)
    setSearch(value)

    dispatch(
      updateLoyaltyProgramPageSetting({
        start: 0,
        search: value.searchString ?? "",
        product: value.product.map((item) => item.id),
      })
    );
  }

  const handleStatusChange = ({ currentStatus, id }) => {
    if (hasLoyaltyProgramActivateOrDeactivateAccessRight) {
      setChangeStatusTargetId(id);
      if (currentStatus) {
        setIsDeactivateDialogOpen(true);
      } else {
        setIsActivateDialogOpen(true);
      }
    }
  };

  const handleStatusSubmit = (status) => {
    if (hasLoyaltyProgramActivateOrDeactivateAccessRight) {
      dispatch(changeStatusLoyaltyPoint({
        id: changeStatusTargetId,
        status: status ? "active" : "inactive",
      })
      )
        .then(unwrapResult)
        .then(() => handleFetchLoyaltyPointList())
        .finally(() => {
          setIsDeactivateDialogOpen(false);
          setIsActivateDialogOpen(false);
        });
    }
  };

  const handleCreateLoyaltyPoint = (values) => {
    if (hasLoyaltyProgramAddAccessRight) {
      setIsCreateDialogOpen(false);
    }
  };

  const handleCreate = () => {
    if (hasLoyaltyProgramAddAccessRight) {
      dispatch(setType({ index: "null", type: "create" }));
      setIsCreateDialogOpen(true);
    }
  };

  return (
    <>
      <PointListingPanel
        hasLoyaltyProgramAddAccessRight={hasLoyaltyProgramAddAccessRight}
        hasLoyaltyProgramEditAccessRight={hasLoyaltyProgramEditAccessRight}
        hasLoyaltyProgramActivateOrDeactivateAccessRight={hasLoyaltyProgramActivateOrDeactivateAccessRight}
        data={data}
        isFetching={isFetching}
        page={page}
        search={search}
        rowsPerPage={rowsPerPage}
        totalRecords={search ? filteredTotalRecords : totalRecords}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleSearch={handleSearch}
        handleCreate={handleCreate}
        handleStatusChange={handleStatusChange}
        handleReload={handleFetchLoyaltyPointList}
        paginationOptions={paginationOptions}
        productDropdown={productDropdown}
        isFetchingProduct={isFetchingProduct}
      />
      <AddPointDialog
        isOpen={isCreateDialogOpen}
        handleClose={() => setIsCreateDialogOpen(false)}
        handleSubmit={handleCreateLoyaltyPoint}
      />
      <ConfirmationDialog
        isOpen={isActivateDialogOpen}
        handleClose={() => setIsActivateDialogOpen(false)}
        handleProceed={() => handleStatusSubmit(true)}
        isLoading={isStatusUpdating}
        type={"success"}
        description={getLang(lang,"paragraph.ACTIVATE_LOYALTY_POINT_QUESTION")}
        title={getLang(lang,"label.CONFIRMATION_NEEDED")}
      />
      <ConfirmationDialog
        isOpen={isDeactivateDialogOpen}
        handleClose={() => setIsDeactivateDialogOpen(false)}
        handleProceed={() => handleStatusSubmit(false)}
        isLoading={isStatusUpdating}
        type={"danger"}
        description={getLang(lang,"paragraph.DEACTIVATE_LOYALTY_POINT_QUESTION")}
        title={getLang(lang,"label.CONFIRMATION_NEEDED")}
      />
    </>
  )
})

export default connect(null, null, null, { forwardRef: true })(PointListingPanelContainer)
