/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useSelector } from "react-redux";
import DeliveryInfoPanelComponent from "./deliveryInfoPanel.component";
import { useEffect } from "react";
import { useState } from "react";

function DeliveryInfoPanelContainer({ fetchData, storeName }) {
  const lang = useSelector((state) => state.constant.languages);
  const language = useSelector((state) => state.profile.language);

  const list = useSelector((state) => state[storeName].deliveryInfo.list);
  const isFetching = useSelector(
    (state) => state[storeName].deliveryInfo.isLoading
  );
  const isError = useSelector((state) => state[storeName].deliveryInfo.isError);

  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    fetch();
  }, []);

  const fetch = () => {
    fetchData();
  };

  return (
    <DeliveryInfoPanelComponent
      isFetching={isFetching}
      list={list}
      lang={lang}
      isError={isError}
      handleReload={fetch}
      showMore={showMore}
      setShowMore={setShowMore}
      language={language}
    />
  );
}

export default DeliveryInfoPanelContainer;
