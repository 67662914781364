import React from "react";
import { makeStyles, Box, Button } from "@material-ui/core";
import { Formik } from "formik";
import Input from "components/input/inputTextField";
import DateRangePicker from "components/input/dateRangePicker";
import { getLang } from "app/feature/constants";
import clsx from "clsx";
import RecallDealerSelect from "../../select/recallDealerSelect";
import RecallListTable from "../../table/recallListTable";

const useStyles = makeStyles((theme) => ({
  indicator: {
    border: "0.5px solid",
    borderColor: theme.palette.primary.main,
  },
  scroll: {
    width: "min-content",
  },
  container: {
    background: "white",
    borderRadius: 12,
    marginTop: 16,
    border: "1px solid #D0D5DD",
    padding: "20px",
  },
  searchContainer: {
    backgroundColor: theme.palette.background.paper,
    display: "grid",
    gridTemplateColumns: "5fr 4fr 4fr 1fr 2fr",
    columnGap: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1),
    },
  },
  inputRootID: {
    paddingTop: "8px !important",
    paddingBottom: "7px !important",
    paddingLeft: "12px !important",
    paddingRight: "12px !important",
  },
  filterMainContainer: {
    backgroundColor: theme.palette.background.paper,
    paddingTop: "12px",
    paddingBottom: "12px",
    paddingRight: "20px",
    paddingLeft: "20px",
    marginBottom: "12px",
  },
  filterFieldContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
    columnGap: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1),
    },
  },
  paperRoot: {
    width: "217px",
  },
  emptyField: {
    "& .MuiInputBase-root": {
      color: "#A4A6A8",
    },
  },
}));

export default function DealerRecallPanelComponent({
  hasStockflowRecallViewAccessRight,
  hasStockflowDealerViewAccessRight,
  id,
  recalls,
  isFetching,
  isError,
  handleChangePage,
  handleChangeRowsPerPage,
  totalFiltered,
  page,
  rowsPerPage,
  handleSearch,
  clearSearch,
  handleReload,
  isSearchResult,
  paginationOptions,
  tableFilter,
  changeOrder,
  lang,
  handleViewDetail,
}) {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Formik
        initialValues={{
          search: tableFilter.search,
          dateRange: {
            from: tableFilter.fromDate,
            to: tableFilter.toDate,
          },
          recallFrom: tableFilter.recallFrom,
        }}
        enableReinitialize
        onSubmit={(values, actions) => {
          handleSearch(values);
          actions.setSubmitting(false);
        }}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <Box mb={2} className={classes.searchContainer}>
              <Input
                hiddenLabel
                variant="filled"
                size="small"
                placeholder={getLang(lang, "placeholder.SEARCH_RECALL_ID")}
                margin="none"
                InputProps={{
                  disableUnderline: true,
                  margin: "none",
                  style: {
                    borderRadius: "4px",
                    backgroundColor: "#eceff0",
                    minWidth: "50px",
                    height: "100%",
                  },
                  classes: { input: classes.inputRootID },
                }}
                {...formik.getFieldProps("search")}
              />
              <DateRangePicker
                label=""
                placeholder={getLang(lang, "placeholder.RECALL_DATE")}
                disabled={isFetching}
                disableFuture={false}
                className={clsx({
                  [classes.emptyField]:
                    !formik.getFieldProps("dateRange").value,
                })}
                id={"created-picker-dialog"}
                value={formik.getFieldProps("dateRange").value}
                handleChange={(v) => {
                  formik.setFieldValue("dateRange", v);
                }}
                InputPropStyle={{
                  color: !formik.getFieldProps("dateRange").value
                    ? "#A4A6A8"
                    : "#000000",
                  fontWeight: "normal",
                }}
                inputProps={{
                  fontWeight: "400",
                  fontSize: "16px",
                }}
                title={getLang(lang, "placeholder.RECALL_DATE")}
              />
              <RecallDealerSelect
                value={formik.values.recallFrom}
                handleChange={(value) =>
                  formik.setFieldValue("recallFrom", value)
                }
                placeholder={getLang(lang, "placeholder.DEALER")}
                disabled={isFetching}
                dealerUuid={id}
              />
              <Button
                variant="text"
                disableElevation
                color="secondary"
                size="small"
                onClick={() => {
                  clearSearch();
                }}
              >
                {getLang(lang, "label.CLEAR")}
              </Button>
              <Button
                disabled={isFetching}
                variant="contained"
                disableElevation
                color="primary"
                size="small"
                type="submit"
              >
                {getLang(lang, "label.SEARCH")}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
      <Box mt={3}>
        <RecallListTable
          hasStockflowRecallViewAccessRight={hasStockflowRecallViewAccessRight}
          hasStockflowDealerViewAccessRight={hasStockflowDealerViewAccessRight}
          isDealerPage={true}
          rows={recalls}
          isFetching={isFetching}
          isError={isError}
          handleReload={handleReload}
          page={page}
          rowsPerPage={rowsPerPage}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          isSearchResult={isSearchResult}
          totalFiltered={totalFiltered}
          paginationOptions={paginationOptions}
          tableFilter={tableFilter}
          changeOrder={changeOrder}
          lang={lang}
          handleViewDetail={handleViewDetail}
        />
      </Box>
    </Box>
  );
}
