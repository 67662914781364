import axios from "axios";

export function UpdateProgramDetails({ 
  id,
  name,
  percentages,
  is_select_all,
  products
}) {
  const url = `/distributor/admin/api/v1/programs/${id}/update`;

  return new Promise((resolve, reject) => {
    axios.post(url, {
      name,
      percentages,
      is_select_all,
      products
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data))
  })
}