import { createAsyncThunk } from "@reduxjs/toolkit";
import reportingApi from "app/api/reporting";
import moment from "moment";

// (1) campaign conversion -- start
export const getCampaignConversionData = createAsyncThunk(
    "reporting/getCampaignConversionData",
    async (payload, thunkApi) => {
        return reportingApi.getCampaignConversionData({
            orderBy: payload.orderBy,
            orderDirection: payload.orderDirection,
            country : payload.countryCode,
            city_id: payload.city_uuid,
            branch_uuid: payload.branch_uuid,
            category_uuid: payload.category_uuid,
            model_uuid: payload.model_uuid,
            brand_uuid: payload.brand_uuid,
            product_uuid: payload.product_uuid,
            period: payload.period,
            start_date: moment(payload.start_date).format('YYYY-MM-DD'),
            end_date: moment(payload.end_date).format('YYYY-MM-DD'),
            url: payload.url,
        })
            .then((response) => response)
            .catch((error) => thunkApi.rejectWithValue(error));
    }
);
// (1) campaign conversion -- end

// (2) campaign performance -- start
export const getCampaignPerformanceData = createAsyncThunk(
    "reporting/getCampaignPerformanceData",
    async (payload, thunkApi) => {
        return reportingApi.getCampaignPerformanceData({
            country: payload.countryCode,
            city_id: payload.city_uuid,
            branch_uuid: payload.branch_uuid,
            period: payload.period,
            start_date: moment(payload.start_date).format('YYYY-MM-DD'),
            end_date: moment(payload.end_date).format('YYYY-MM-DD'),
            url : payload.url,
        })
            .then((response) => response)
            .catch((error) => thunkApi.rejectWithValue(error));
    }
);
// (2) campaign performance -- end

// (3) campaign survey list -- start
export const getCampaignSurveyListData = createAsyncThunk( 
    "reporting/getCampaignSurveyListData",
    async (payload, thunkApi) => {
        return reportingApi.getCampaignSurveyListData({
            length: payload.length,
            start: payload.start,
            search: payload.search,
            orderBy: payload.orderBy,
            orderDirection: payload.orderDirection,
            search_by: payload.searchBy,
            country: payload.countryCode,
            city_id: payload.city_uuid,
            branch_uuid: payload.branch_uuid,
            period: payload.period,
            start_date: moment(payload.start_date).format('YYYY-MM-DD'),
            end_date: moment(payload.end_date).format('YYYY-MM-DD'),
            url : payload.url,
            campaign_type: payload.campaign_type,
        })
            .then((response) => response)
            .catch((error) => thunkApi.rejectWithValue(error));
    }
);
// (3) campaign survey list -- end

// (4) campaign lucky draw list -- start
export const getCampaignLuckyDrawListData = createAsyncThunk(
    "reporting/getCampaignLuckyDrawListData",
    async (payload, thunkApi) => {
        return reportingApi.getCampaignLuckyDrawListData({
            length: payload.length,
            start: payload.start,
            search: payload.search,
            orderBy: payload.orderBy,
            orderDirection: payload.orderDirection,
            search_by: payload.searchBy,
            country: payload.countryCode,
            city_id: payload.city_uuid,
            branch_uuid: payload.branch_uuid,
            period: payload.period,
            start_date: moment(payload.start_date).format('YYYY-MM-DD'),
            end_date: moment(payload.end_date).format('YYYY-MM-DD'),
            url : payload.url,
            campaign_type: payload.campaign_type,
        })
            .then((response) => response)
            .catch((error) => thunkApi.rejectWithValue(error));
    }
);
// (4) campaign lucky draw list -- end

// (5) campaign instant lucky draw list -- start
export const getCampaignInstantLuckyDrawListData = createAsyncThunk(
    "reporting/getCampaignInstantLuckyDrawListData",
    async (payload, thunkApi) => {
        return reportingApi.getCampaignInstantLuckyDrawListData({
            length: payload.length,
            start: payload.start,
            search: payload.search,
            orderBy: payload.orderBy,
            orderDirection: payload.orderDirection,
            search_by: payload.searchBy,
            country: payload.countryCode,
            city_id: payload.city_uuid,
            branch_uuid: payload.branch_uuid,
            period: payload.period,
            start_date: moment(payload.start_date).format('YYYY-MM-DD'),
            end_date: moment(payload.end_date).format('YYYY-MM-DD'),
            url : payload.url,
            campaign_type: payload.campaign_type,
        })
            .then((response) => response)
            .catch((error) => thunkApi.rejectWithValue(error));
    }
);
// (5) campaign instant lucky draw list -- end

// (6) campaign loyalty program list -- start
export const getCampaignLoyaltyProgramListData = createAsyncThunk(
    "reporting/getCampaignLoyaltyProgramListData",
    async (payload, thunkApi) => {
        return reportingApi.getCampaignLoyaltyProgramListData({
            length: payload.length,
            start: payload.start,
            search: payload.search,
            orderBy: payload.orderBy,
            orderDirection: payload.orderDirection,
            search_by: payload.searchBy,
            country: payload.countryCode,
            city_id: payload.city_uuid,
            branch_uuid: payload.branch_uuid,
            period: payload.period,
            start_date: moment(payload.start_date).format('YYYY-MM-DD'),
            end_date: moment(payload.end_date).format('YYYY-MM-DD'),
            url : payload.url,
            campaign_type: payload.campaign_type,
        })
            .then((response) => response)
            .catch((error) => thunkApi.rejectWithValue(error));
    }
);
// (6) campaign loyalty program list -- end

// (7) campaign warranty list -- start
export const getCampaignWarrantyListData = createAsyncThunk(
    "reporting/getCampaignWarrantyListData",
    async (payload, thunkApi) => {
        return reportingApi.getCampaignWarrantyListData({
            length: payload.length,
            start: payload.start,
            search: payload.search,
            orderBy: payload.orderBy,
            orderDirection: payload.orderDirection,
            search_by: payload.searchBy,
            country: payload.countryCode,
            city_id: payload.city_uuid,
            branch_uuid: payload.branch_uuid,
            period: payload.period,
            start_date: moment(payload.start_date).format('YYYY-MM-DD'),
            end_date: moment(payload.end_date).format('YYYY-MM-DD'),
            url : payload.url,
            campaign_type: payload.campaign_type,
        })
            .then((response) => response)
            .catch((error) => thunkApi.rejectWithValue(error));
    }
);
// (7) campaign warranty list -- end

// (8) campaign type survey list -- start
export const getCampaignTypeSurveyDatalist = createAsyncThunk(
    "reporting/getCampaignTypeSurveyDatalist",
    async (payload, thunkApi) => {
        return reportingApi.getCampaignTypeSurveyDatalist({
            length: payload.length,
            start: payload.start,
            search: payload.search,
            orderBy: payload.orderBy,
            orderDirection: payload.orderDirection,
            search_by: payload.searchBy,
            country: payload.countryCode,
            city_id: payload.city_uuid,
            branch_uuid: payload.branch_uuid,
            period: payload.period,
            start_date: moment(payload.start_date).format('YYYY-MM-DD'),
            end_date: moment(payload.end_date).format('YYYY-MM-DD'),
            url : payload.url,
            campaign_type: payload.campaign_type,
        })
            .then((response) => response)
            .catch((error) => thunkApi.rejectWithValue(error));
    }
);
// (8) campaign type survey list -- end

// (9) respondents consumer all survey datalist -- start
export const getRespondentsConsumerAllSurveyDatalist = createAsyncThunk(
    "reporting/getRespondentsConsumerAllSurveyDatalist",
    async (payload, thunkApi) => {
        return reportingApi.getRespondentsConsumerAllSurveyDatalist({
            length: payload.length,
            start: payload.start,
            search: payload.search,
            orderBy: payload.orderBy,
            orderDirection: payload.orderDirection,
            search_by: payload.searchBy,
            country: payload.countryCode,
            city_id: payload.city_uuid,
            branch_uuid: payload.branch_uuid,
            period: payload.period,
            start_date: moment(payload.start_date).format('YYYY-MM-DD'),
            end_date: moment(payload.end_date).format('YYYY-MM-DD'),
            url : payload.url,
            campaign_type: payload.campaign_type,
        })
            .then((response) => response)
            .catch((error) => thunkApi.rejectWithValue(error));
    }
);
// (9) respondents consumer all survey datalist -- end

// (10) campaign type lucky draw datalist -- start
export const getCampaignTypeLuckyDrawDatalist = createAsyncThunk(
    "reporting/getCampaignTypeLuckyDrawDatalist",
    async (payload, thunkApi) => {
        return reportingApi.getCampaignTypeLuckyDrawDatalist({
            length: payload.length,
            start: payload.start,
            search: payload.search,
            orderBy: payload.orderBy,
            orderDirection: payload.orderDirection,
            search_by: payload.searchBy,
            country: payload.countryCode,
            city_id: payload.city_uuid,
            branch_uuid: payload.branch_uuid,
            period: payload.period,
            start_date: moment(payload.start_date).format('YYYY-MM-DD'),
            end_date: moment(payload.end_date).format('YYYY-MM-DD'),
            url : payload.url,
            campaign_type: payload.campaign_type,
        })
            .then((response) => response)
            .catch((error) => thunkApi.rejectWithValue(error));
    }
);
// (10) campaign type lucky draw datalist -- end

// (11) respondents consumer all lucky draw datalist -- start
export const getRespondentsConsumerAllLuckyDrawDatalist = createAsyncThunk(
    "reporting/getRespondentsConsumerAllLuckyDrawDatalist",
    async (payload, thunkApi) => {
        return reportingApi.getRespondentsConsumerAllLuckyDrawDatalist({
            length: payload.length,
            start: payload.start,
            search: payload.search,
            orderBy: payload.orderBy,
            orderDirection: payload.orderDirection,
            search_by: payload.searchBy,
            country: payload.countryCode,
            city_id: payload.city_uuid,
            branch_uuid: payload.branch_uuid,
            period: payload.period,
            start_date: moment(payload.start_date).format('YYYY-MM-DD'),
            end_date: moment(payload.end_date).format('YYYY-MM-DD'),
            url : payload.url,
            campaign_type: payload.campaign_type,
        })
            .then((response) => response)
            .catch((error) => thunkApi.rejectWithValue(error));
    }
);
// (11) respondents consumer all lucky draw datalist -- end

// (12) campaign type instant lucky draw datalist -- start
export const getCampaignTypeInstantLuckyDrawDatalist = createAsyncThunk(
    "reporting/getCampaignTypeInstantLuckyDrawDatalist",
    async (payload, thunkApi) => {
        return reportingApi.getCampaignTypeInstantLuckyDrawDatalist({
            length: payload.length,
            start: payload.start,
            search: payload.search,
            orderBy: payload.orderBy,
            orderDirection: payload.orderDirection,
            search_by: payload.searchBy,
            country: payload.countryCode,
            city_id: payload.city_uuid,
            branch_uuid: payload.branch_uuid,
            period: payload.period,
            start_date: moment(payload.start_date).format('YYYY-MM-DD'),
            end_date: moment(payload.end_date).format('YYYY-MM-DD'),
            url : payload.url,
            campaign_type: payload.campaign_type,
        })
            .then((response) => response)
            .catch((error) => thunkApi.rejectWithValue(error));
    }
);
// (12) campaign type instant lucky draw datalist -- end

// (13) respondents consumer all instant lucky draw datalist -- start
export const getRespondentsConsumerAllInstantLuckyDrawDatalist = createAsyncThunk(
    "reporting/getRespondentsConsumerAllInstantLuckyDrawDatalist",
    async (payload, thunkApi) => {
        return reportingApi.getRespondentsConsumerAllInstantLuckyDrawDatalist({
            length: payload.length,
            start: payload.start,
            search: payload.search,
            orderBy: payload.orderBy,
            orderDirection: payload.orderDirection,
            search_by: payload.searchBy,
            country: payload.countryCode,
            city_id: payload.city_uuid,
            branch_uuid: payload.branch_uuid,
            period: payload.period,
            start_date: moment(payload.start_date).format('YYYY-MM-DD'),
            end_date: moment(payload.end_date).format('YYYY-MM-DD'),
            url : payload.url,
            campaign_type: payload.campaign_type,
        })
            .then((response) => response)
            .catch((error) => thunkApi.rejectWithValue(error));
    }
);
// (13) respondents consumer all instant lucky draw datalist -- end
        
// (14) campaign type loyalty program datalist -- start
export const getCampaignTypeLoyaltyProgramDatalist = createAsyncThunk(
    "reporting/getCampaignTypeLoyaltyProgramDatalist",
    async (payload, thunkApi) => {
        return reportingApi.getCampaignTypeLoyaltyProgramDatalist({
            length: payload.length,
            start: payload.start,
            search: payload.search,
            orderBy: payload.orderBy,
            orderDirection: payload.orderDirection,
            search_by: payload.searchBy,
            country: payload.countryCode,
            city_id: payload.city_uuid,
            branch_uuid: payload.branch_uuid,
            period: payload.period,
            start_date: moment(payload.start_date).format('YYYY-MM-DD'),
            end_date: moment(payload.end_date).format('YYYY-MM-DD'),
            url : payload.url,
            campaign_type: payload.campaign_type,
        })
            .then((response) => response)
            .catch((error) => thunkApi.rejectWithValue(error));
    }
);
// (14) campaign type loyalty program datalist -- end

// (15) respondents consumer all loyalty program datalist -- start
export const getRespondentsConsumerAllLoyaltyProgramDatalist = createAsyncThunk(
    "reporting/getRespondentsConsumerAllLoyaltyProgramDatalist",
    async (payload, thunkApi) => {
        return reportingApi.getRespondentsConsumerAllLoyaltyProgramDatalist({
            length: payload.length,
            start: payload.start,
            search: payload.search,
            orderBy: payload.orderBy,
            orderDirection: payload.orderDirection,
            search_by: payload.searchBy,
            country: payload.countryCode,
            city_id: payload.city_uuid,
            branch_uuid: payload.branch_uuid,
            period: payload.period,
            start_date: moment(payload.start_date).format('YYYY-MM-DD'),
            end_date: moment(payload.end_date).format('YYYY-MM-DD'),
            url : payload.url,
            campaign_type: payload.campaign_type,
        })
            .then((response) => response)
            .catch((error) => thunkApi.rejectWithValue(error));
    }
);
// (15) respondents consumer all loyalty program datalist -- end

// (16) campaign type warranty datalist -- start
export const getCampaignTypeWarrantyDatalist = createAsyncThunk(
    "reporting/getCampaignTypeWarrantyDatalist",
    async (payload, thunkApi) => {
        return reportingApi.getCampaignTypeWarrantyDatalist({
            length: payload.length,
            start: payload.start,
            search: payload.search,
            orderBy: payload.orderBy,
            orderDirection: payload.orderDirection,
            search_by: payload.searchBy,
            country: payload.countryCode,
            city_id: payload.city_uuid,
            branch_uuid: payload.branch_uuid,
            period: payload.period,
            start_date: moment(payload.start_date).format('YYYY-MM-DD'),
            end_date: moment(payload.end_date).format('YYYY-MM-DD'),
            url : payload.url,
            campaign_type: payload.campaign_type,
        })
            .then((response) => response)
            .catch((error) => thunkApi.rejectWithValue(error));
    }
);
// (16) campaign type warranty datalist -- end

// (17) respondents consumer all warranty datalist -- start
export const getRespondentsConsumerAllWarrantyDatalist = createAsyncThunk(
    "reporting/getRespondentsConsumerAllWarrantyDatalist",
    async (payload, thunkApi) => {
        return reportingApi.getRespondentsConsumerAllWarrantyDatalist({
            length: payload.length,
            start: payload.start,
            search: payload.search,
            orderBy: payload.orderBy,
            orderDirection: payload.orderDirection,
            search_by: payload.searchBy,
            country: payload.countryCode,
            city_id: payload.city_uuid,
            branch_uuid: payload.branch_uuid,
            period: payload.period,
            start_date: moment(payload.start_date).format('YYYY-MM-DD'),
            end_date: moment(payload.end_date).format('YYYY-MM-DD'),
            url : payload.url,
            campaign_type: payload.campaign_type,
        })
            .then((response) => response)
            .catch((error) => thunkApi.rejectWithValue(error));
    }
);
// (17) respondents consumer all warranty datalist -- end

// (18) campaign summary -- start
export const getCampaignSummaryData = createAsyncThunk(
    "reporting/getCampaignSummaryData",
    async (payload, thunkApi) => {
        return reportingApi.getCampaignSummaryData({
            country: payload.countryCode,
            city_id: payload.city_uuid,
            branch_uuid: payload.branch_uuid,
            period: payload.period,
            start_date: moment(payload.start_date).format('YYYY-MM-DD'),
            end_date: moment(payload.end_date).format('YYYY-MM-DD'),
            url : payload.url,


            campaign_uuid: payload.campaign_uuid,
            campaign_type: payload.campaign_type,
        })
            .then((response) => response)
            .catch((error) => thunkApi.rejectWithValue(error));
    }
)
// (18) campaign summary -- end

// (19) campaign type data -- start
export const getCampaignTypeData = createAsyncThunk(
    "reporting/getCampaignTypeData",
    async (payload, thunkApi) => {
        return reportingApi.getCampaignTypeData({
            country: payload.countryCode,
            city_id: payload.city_uuid,
            branch_uuid: payload.branch_uuid,
            period: payload.period,
            start_date: moment(payload.start_date).format('YYYY-MM-DD'),
            end_date: moment(payload.end_date).format('YYYY-MM-DD'),
            url : payload.url,


            campaign_uuid: payload.campaign_uuid,
            campaign_type: payload.campaign_type,
        })
            .then((response) => response)
            .catch((error) => thunkApi.rejectWithValue(error));
    }
)
// (19) campaign type data -- end

// (20) campaign product data -- start
export const getCampaignProductData = createAsyncThunk(
    "reporting/getCampaignProductData",
    async (payload, thunkApi) => {
        return reportingApi.getCampaignProductData({
            length: payload.length,
            start: payload.start,
            search: payload.search,
            orderBy: payload.orderBy,
            orderDirection: payload.orderDirection,
            search_by: payload.searchBy,
            country: payload.countryCode,
            city_id: payload.city_uuid,
            branch_uuid: payload.branch_uuid,
            period: payload.period,
            start_date: moment(payload.start_date).format('YYYY-MM-DD'),
            end_date: moment(payload.end_date).format('YYYY-MM-DD'),
            url : payload.url,


            campaign_uuid: payload.campaign_uuid,
            campaign_type: payload.campaign_type,
        })
            .then((response) => response)
            .catch((error) => thunkApi.rejectWithValue(error));
    }
)
// (20) campaign product data -- end

// (21) respondents single campaign -- start
export const getRespondentsSingleCampaignDatalist = createAsyncThunk(
    "reporting/getRespondentsSingleCampaignDatalist",
    async (payload, thunkApi) => {
        return reportingApi.getRespondentsSingleCampaignDatalist({
            length: payload.length,
            start: payload.start,
            search: payload.search,
            orderBy: payload.orderBy,
            orderDirection: payload.orderDirection,
            search_by: payload.searchBy,
            country: payload.countryCode,
            city_id: payload.city_uuid,
            branch_uuid: payload.branch_uuid,
            period: payload.period,
            start_date: moment(payload.start_date).format('YYYY-MM-DD'),
            end_date: moment(payload.end_date).format('YYYY-MM-DD'),
            url : payload.url,
            campaign_uuid: payload.campaign_uuid,
            campaign_type: payload.campaign_type,
        })
            .then((response) => response)
            .catch((error) => thunkApi.rejectWithValue(error));
    }
)
// (21) respondents single campaign -- end

// (22) campaign prize datalist -- start
export const getCampaignPrizeDatalist = createAsyncThunk(
    "reporting/getCampaignPrizeDatalist",
    async (payload, thunkApi) => {
        return reportingApi.getCampaignPrizeDatalist({
            length: payload.length,
            start: payload.start,
            search: payload.search,
            orderBy: payload.orderBy,
            orderDirection: payload.orderDirection,
            search_by: payload.searchBy,
            country: payload.countryCode,
            city_id: payload.city_uuid,
            branch_uuid: payload.branch_uuid,
            period: payload.period,
            start_date: moment(payload.start_date).format('YYYY-MM-DD'),
            end_date: moment(payload.end_date).format('YYYY-MM-DD'),
            url : payload.url,
            campaign_uuid: payload.campaign_uuid,
            campaign_type: payload.campaign_type,
        })
            .then((response) => response)
            .catch((error) => thunkApi.rejectWithValue(error));
    }
)
// (22) campaign prize datalist -- end

// (23) campaign prize summary data -- start
export const getCampaignPrizeSummaryData = createAsyncThunk(
    "reporting/getCampaignPrizeSummaryData",
    async (payload, thunkApi) => {
        return reportingApi.getCampaignPrizeSummaryData({
            country: payload.countryCode,
            city_id: payload.city_uuid,
            branch_uuid: payload.branch_uuid,
            period: payload.period,
            start_date: moment(payload.start_date).format('YYYY-MM-DD'),
            end_date: moment(payload.end_date).format('YYYY-MM-DD'),
            url : payload.url,
            campaign_uuid: payload.campaign_uuid,
            campaign_type: payload.campaign_type,
            prize_uuid: payload.prize_uuid,
        })
            .then((response) => response)
            .catch((error) => thunkApi.rejectWithValue(error));
    }
)
// (23) campaign prize summary data -- end


// (24) winner participant datalist -- start
export const getWinnerParticipantDatalist = createAsyncThunk(
    "reporting/getWinnerParticipantDatalist",
    async (payload, thunkApi) => {
        return reportingApi.getWinnerParticipantDatalist({
            length: payload.length,
            start: payload.start,
            search: payload.search,
            orderBy: payload.orderBy,
            orderDirection: payload.orderDirection,
            search_by: payload.searchBy,
            country: payload.countryCode,
            city_id: payload.city_uuid,
            branch_uuid: payload.branch_uuid,
            period: payload.period,
            start_date: moment(payload.start_date).format('YYYY-MM-DD'),
            end_date: moment(payload.end_date).format('YYYY-MM-DD'),
            url : payload.url,
            campaign_uuid: payload.campaign_uuid,
            campaign_type: payload.campaign_type,
            prize_uuid: payload.prize_uuid,
        })
            .then((response) => response)
            .catch((error) => thunkApi.rejectWithValue(error));
    }
)
// (24) winner participant datalist -- end