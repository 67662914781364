import React from 'react'
import CreateSurveyDialogComponent from './createSurveyDialog.component'

export default function CreateSurveyDialogContainer({
    isOpen,
    handleClose,
    handleSubmit,
    type
}) {

    return (
        <CreateSurveyDialogComponent
            isOpen={isOpen}
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            templateType={type}
        />
    )
}
