import React, { useState, useRef, useEffect } from "react";
import BulkCreateBranchSelectComponent from "./bulkCreateBranchSelect.component";
import BulkCreateBranchSelectPanelComponent from "./bulkCreateBranchSelectPanel.component";
import { selectLoading } from "modules/notification";
import { useSelector } from "react-redux";
import { fetchMeAndBranchDropdown } from "modules/product-mgmt/redux/action/products.action";
import cloneDeep from "lodash/cloneDeep";
import { Box } from "@material-ui/core";

function BulkCreateBranchSelectContainer({ 
  placeholder, 
  handleChange, 
  value, 
  disabled, 
  styles, 
  textStyles,
  searchable,
  searchPlaceholder,
  handleBranchSearch,
  anchorOrigin,
  transformOrigin,
  showAllButton = true
}) {
  const lang = useSelector((state) => state.constant.languages);
  const branchDropdown = useSelector((state) => state.products.branchDropdown);
  const isBranchDropdownLoading = useSelector((state) => selectLoading(state, fetchMeAndBranchDropdown.typePrefix));
  const anchorRef = useRef(null);
  const [selectAll, setSelectAll] = useState(false);
  let flattenBranch = []
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    for (const masterBranch of branchDropdown) {
      flattenBranch.push({
        name: masterBranch.name,
        node_id: masterBranch.node_id,
        parent_node_id: masterBranch.parent_node_id,
        remarks: masterBranch.remarks,
      })
      if (masterBranch.child !== undefined && masterBranch.child.length > 0) {
        for (const subBranch of masterBranch.child) {
          flattenBranch.push({
            name: subBranch.name,
            node_id: subBranch.node_id,
            parent_node_id: subBranch.parent_node_id,
            remarks: subBranch.remarks,
          })
        }
      }
    }
  }, [branchDropdown, flattenBranch]);

  useEffect(() => {
    let temp = cloneDeep(value)
    let isSelectAll = true
    for (const masterBranch of flattenBranch) {
      if (temp && !(temp.filter(t => t.node_id === masterBranch.node_id).length === 1)
      ) {
        isSelectAll = false
      }
    }
    setSelectAll(isSelectAll)
  }, [value, flattenBranch]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSelectAll = async () => {
    let temp = cloneDeep(value)
    if (selectAll) {
      for (const masterBranch of branchDropdown) {
        temp = temp.filter(t => t.node_id !== masterBranch.node_id)
        if (temp.filter(t => t.node_id === masterBranch.parent_node_id).length === 0) {
          if (masterBranch.child.length > 0) {
            for (const branch of masterBranch.child) {
              temp = temp.filter(t => t.node_id !== branch.node_id)
            }
          }
        }
      }
    } else {
      for (const masterBranch of branchDropdown) {
        temp = temp.filter(t => t.node_id !== masterBranch.node_id)
        if (masterBranch.child.length > 0) {
          for (const branch of masterBranch.child) {
            temp = temp.filter(t => t.node_id !== branch.node_id)
          }
        }
        if (temp.filter(t => t.node_id === masterBranch.parent_node_id).length === 0) {
          temp.push({
            name: masterBranch.name,
            node_id: masterBranch.node_id,
            parent_node_id: masterBranch.parent_node_id,
            remarks: masterBranch.remarks
          });

          if (masterBranch.child.length > 0) {
            for (const branch of masterBranch.child) {
              temp.push({
                name: branch.name,
                node_id: branch.node_id,
                parent_node_id: branch.parent_node_id,
                remarks: branch.remarks
              });
            }
          }
        }
      }
    }
    setSelectAll(!selectAll)
    handleChange(temp)
  };

  const handleSelectBranch = (branch) => {
    let temp = cloneDeep(value)
    if (temp.filter(t => t.node_id === branch.node_id).length > 0) {
      temp = temp.filter(t => t.node_id !== branch.node_id)
    } else {
      const parentBranch = branchDropdown.find(b => b.node_id === branch.parent_node_id);
      if (!branch.child && !temp.find(t => t.node_id === parentBranch.node_id)) {
        temp.push({
          name: parentBranch.name,
          node_id: parentBranch.node_id,
          parent_node_id: parentBranch.parent_node_id,
          remarks: parentBranch.remarks
        })
      }
      temp.push({
        name: branch.name,
        node_id: branch.node_id,
        parent_node_id: branch.parent_node_id,
        remarks: branch.remarks
      })
    }
    handleChange(temp)
  };

  return (
    <Box position="relative">
      <BulkCreateBranchSelectComponent
        anchorRef={anchorRef}
        handleClick={handleClick}
        value={value}
        styles={styles}
        placeholder={placeholder}
        disabled={disabled}
        branchDropDown={branchDropdown}
        textStyles={textStyles}
        lang={lang}
      />
      <BulkCreateBranchSelectPanelComponent
        selectAll={selectAll}
        value={value}
        anchorEl={anchorEl}
        open={open}
        handleClose={handleClose}
        isFetching={isBranchDropdownLoading}
        branchDropdown={branchDropdown}
        handleSelectAll={handleSelectAll}
        handleSelectBranch={handleSelectBranch}
        handleBranchSearch={handleBranchSearch}
        placeholder={searchPlaceholder}
        searchable={searchable}
        transformOrigin={transformOrigin}
        anchorOrigin={anchorOrigin}
        lang={lang}
        showAllButton={showAllButton}
      />
    </Box>
  );
}
export default BulkCreateBranchSelectContainer;
