import React from "react";
import {
  makeStyles,
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from "@material-ui/core";
import { TablePaginationActions } from "components/tablePagination";
import Alert from "@material-ui/lab/Alert";
import {
  StickyRightTableCell,
  StyledTableCell,
  StyledTableRow,
} from "components/table";
import { ActionButton } from "components/button";
import { SkeletonTableRowStickyLeftRight } from "components/skeleton/skeleton.component";
import RefreshTable from "components/table/refreshTable";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles((theme) => ({
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    borderCollapse: "separate",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
    },
    "& th,td": {
      fontSize: ".75rem",
      borderRight: "1px solid rgba(58, 77, 84, 0.1)",
      padding: "10px 16px 10px 16px",
    },
    "& th": {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightBold,
    },
  },
  emptyContainer: {
    backgroundColor: theme.palette.background.paper,
    textAlign: "center",
  },
}));

export default function TagTableComponent({
  rows,
  isFetching,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
  length,
  search,
  handleAddSerialNumberPattern,
  isLoadingSerialNumberTagListError,
  handleReload,
  paginationOptions,
  lang,
  hasAdtInfoViewAccessRight,
  handleViewDetail,
}) {
  const classes = useStyle();

  return (
    <>
      {rows.length > 0 || isFetching ? (
        <TableContainer>
          <Table
            className={classes.table}
            size="small"
            aria-label="product table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <StyledTableCell>{getLang(lang, "label.NUM")}</StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.PREFIX")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.LENGTH")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.POSTFIX")}
                </StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.TYPE")}</StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.STATUS")}
                </StyledTableCell>
                {!!hasAdtInfoViewAccessRight && (
                  <StickyRightTableCell
                    style={{ width: "67px", textAlign: "center" }}
                  >
                    {getLang(lang, "label.ACTION")}
                  </StickyRightTableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {isFetching
                ? [...new Array(5)].map((v, index) => (
                    <SkeletonTableRowStickyLeftRight
                      key={index}
                      columnCount={hasAdtInfoViewAccessRight ? 7 : 6}
                    />
                  ))
                : rows.map((row, index) => {
                    const actionItems = [];

                    actionItems.push({
                      label: getLang(lang, "label.VIEW_EDIT_DETAIL"),
                      action: () => {
                        // history.push(`${TAG_SN}/${row.id}`);
                        handleViewDetail(row.id);
                      },
                    });

                    return (
                      <StyledTableRow
                        key={index}
                        hover
                        role="checkbox"
                        tabIndex={-1}
                      >
                        <StyledTableCell component="th" scope="row">
                          {index + 1 + rowsPerPage * page}
                        </StyledTableCell>
                        <StyledTableCell>{row.prefix}</StyledTableCell>
                        <StyledTableCell>{row.length}</StyledTableCell>
                        <StyledTableCell>{row.postfix}</StyledTableCell>
                        <StyledTableCell className={classes.borderRight}>
                          {row.pinLength === "number"
                            ? getLang(lang, "label.NUMBER")
                            : row.pinLength === "alphanumeric"
                            ? getLang(lang, "label.ALPANUMERIC")
                            : row.pinLength}
                          {/* QUICK FIX FOR LANGUAGES TRANSLATION */}
                        </StyledTableCell>
                        <StyledTableCell>
                          {row.status === "ready"
                            ? getLang(lang, "label.READY")
                            : row.status}
                          {/* QUICK FIX FOR LANGUAGES TRANSLATION */}
                        </StyledTableCell>
                        {!!hasAdtInfoViewAccessRight && (
                          <StickyRightTableCell style={{ width: "67px" }}>
                            <Box display="flex" justifyContent="center">
                              <ActionButton
                                actionItems={actionItems}
                                popperStyle={{ zIndex: 999 }}
                              />
                            </Box>
                          </StickyRightTableCell>
                        )}
                      </StyledTableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : !search && !isLoadingSerialNumberTagListError ? (
        <Box>
          <Table
            className={classes.table}
            size="small"
            aria-label="product table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <StyledTableCell>{getLang(lang, "label.NUM")}</StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.PREFIX")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.LENGTH")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.POSTFIX")}
                </StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.TYPE")}</StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.STATUS")}
                </StyledTableCell>
                {!!hasAdtInfoViewAccessRight && (
                  <StickyRightTableCell>
                    <StyledTableCell>
                      {getLang(lang, "label.ACTION")}
                    </StyledTableCell>
                  </StickyRightTableCell>
                )}
              </TableRow>
            </TableHead>
          </Table>
          <Box py={9} className={classes.emptyContainer}>
            <Typography variant="body1">
              {getLang(lang, "paragraph.NO_SERIAL_NUMBER_PATTERN")}
            </Typography>
            <Box mt={2}>
              <Button
                onClick={handleAddSerialNumberPattern}
                variant="contained"
                disableElevation
                color="primary"
              >
                {getLang(lang, "label.ADD_SERIAL_NUMBER_PATTERN")}
              </Button>
            </Box>
          </Box>
        </Box>
      ) : null}

      {!isFetching && rows.length === 0 && search && (
        <Alert severity="info">
          {getLang(lang, "message.info.NO_SERIAL_NUMBER_PATTERN")}
        </Alert>
      )}

      {!isFetching && isLoadingSerialNumberTagListError && (
        <RefreshTable handleReload={handleReload} />
      )}

      {!isFetching && rows.length > 0 && (
        <TablePagination
          style={{
            backgroundColor: "#fff",
            boxShadow: "0px -1px 0px rgba(58, 77, 84, 0.15)",
          }}
          ActionsComponent={TablePaginationActions}
          rowsPerPageOptions={paginationOptions}
          component="div"
          count={length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          SelectProps={{
            classes: classes.toolbar,
          }}
          labelRowsPerPage={getLang(lang, "label.ROWS_PER_PAGE")}
          labelDisplayedRows={({ from, to, count }) =>
            `${from.toLocaleString("en-US")}-${to.toLocaleString(
              "en-US"
            )} ${getLang(lang, "label.OF")} ${count.toLocaleString("en-US")}`
          }
        />
      )}
    </>
  );
}
