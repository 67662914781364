import axios from "axios"

export function updateBrandModelStatus({
  id,
  status
}) {
  const url =  `/serialnumber/api/v1/product/brand-models/update-status/${id}`;

  return new Promise((resolve, reject) => {
    axios.post(url, {
      status
    })
      .then(response => resolve(response.data))
      .catch(error =>  reject(error.response.data))
  })
}