import BackgroundImage from './image/background.png'
import ButtonImage from './image/button.png'

export default {
    backgroundImage: BackgroundImage,
    blocks: [
        { padding: '15px', background: '#ffc27a', borderRadius: 28 },
        { padding: '4px', background: '#ff4a4c', borderRadius: 23 },
        { padding: '4px', background: '#ff625b', borderRadius: 20 },
    ],
    prize: {
        imgWidth: '45px',
        imgHeight: '45px',
        imgTop: '12%',
        fontSize: 12,
        fontTop: '65%'
    },
    spinButtons: [
        {
            x: 1,
            y: 1,
            background: 'rgba(0, 0, 0, 0)',
            imgs: [
                { src: ButtonImage, width: '100%' }
            ]
        }
    ],
    defaultConfig: {
        gutter: 5,
    },
    defaultStyle: {
        borderRadius: 15,
        fontColor: '#DF424B',
        fontSize: '14px',
        textAlign: 'center',
        background: '#fff',
        shadow: '0 5 1 #ebf1f4'
    },
    activeStyle: {
        background: 'linear-gradient(270deg, #FFDCB8, #FDC689)',
        shadow: ''
    },
    width: "350px",
    height: "350px"
}