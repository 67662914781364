import ConfirmationDialog from "components/dialog/confirmation";
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { fetchPointCutListing, changeStatusPointCut } from "modules/loyalty-program/redux";
import { setType } from "modules/loyalty-program/redux/slice/redemptionCenter.slice"
import { connect, useDispatch, useSelector } from 'react-redux'
import { PointCutDialog } from "../../dialog";
import PointCutListPanelComponent from "./pointCutPanel.component";
import { selectLoading } from 'modules/notification'
import { unwrapResult } from '@reduxjs/toolkit'
import { POINT_CUT_ADD, POINT_CUT_EDIT, POINT_CUT_ACTIVATE_OR_DEACTIVATE } from "lib/constants/accessRights";
import { generatePaginationOptions } from "lib/helper";
import { getLang } from "app/feature/constants";

const GiftListPanelContainer = forwardRef((_, ref) => {
  const hasPointCutAddAccessRight = useSelector(state => state.profile.accessRights.includes(POINT_CUT_ADD));
  const hasPointCutEditAccessRight = useSelector(state => state.profile.accessRights.includes(POINT_CUT_EDIT));
  const hasPointCutActivateOrDeactivateAccessRight = useSelector(state => state.profile.accessRights.includes(POINT_CUT_ACTIVATE_OR_DEACTIVATE));
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [search, setSearch] = useState("")
  const [isDeactivateDialogOpen, setIsDeactivateDialogOpen] = useState(false);
  const [isActivateDialogOpen, setIsActivateDialogOpen] = useState(false);
  const [changeStatusTargetId, setChangeStatusTargetId] = useState("");
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false)
  const [paginationOptions, setPaginationOptions] = useState([]);

  const dispatch = useDispatch()
  const data = useSelector((state) => state.redemptionCenter.pointCutListTable.data);
  const totalRecords = useSelector(state => state.redemptionCenter.pointCutListTable.totalRecords)
  const filteredTotalRecords = useSelector(state => state.redemptionCenter.pointCutListTable.filteredTotalRecords)
  const isStatusUpdating = useSelector((state) => selectLoading(state, changeStatusPointCut.typePrefix));
  const isFetching = useSelector(state => selectLoading(state, fetchPointCutListing.typePrefix))
  const lang = useSelector(state => state.constant.languages)

  useImperativeHandle(ref, () => ({
    openCreateLoyaltyProgramModal: () => {
      dispatch(setType({ index: "null", type: "create" }));
      setIsCreateDialogOpen(true);
    },
  }));

  useEffect(() => {
    dispatch(fetchPointCutListing({
      length: rowsPerPage,
      start: rowsPerPage * page,
      search: search
    }))
  }, [search, rowsPerPage, page, dispatch])

  useEffect(() => {
    setPaginationOptions(generatePaginationOptions(totalRecords))
  }, [totalRecords])

  const handleFetchPointCutListing = () => {
    dispatch(fetchPointCutListing({
      length: rowsPerPage,
      start: rowsPerPage * page,
      search: search
    })
    );
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setPage(0)
    setRowsPerPage(event.target.value)
  }

  const handleSearch = value => {
    setPage(0)
    setSearch(value)
  }

  const handleStatusChange = ({ currentStatus, id }) => {
    if(hasPointCutActivateOrDeactivateAccessRight){
      setChangeStatusTargetId(id);
      if (currentStatus) {
        setIsDeactivateDialogOpen(true);
      } else {
        setIsActivateDialogOpen(true);
      }
    }
  };

  const handleStatusSubmit = (status) => {
    if(hasPointCutActivateOrDeactivateAccessRight){
      dispatch(changeStatusPointCut({
        id: changeStatusTargetId,
        status: status ? "active" : "inactive",
      })
      )
        .then(unwrapResult)
        .then(() => handleFetchPointCutListing())
        .finally(() => {
          setIsDeactivateDialogOpen(false);
          setIsActivateDialogOpen(false);
        });
    }
  };

  const handleCreatePointCut = (values) => {
    if(hasPointCutAddAccessRight){
      setIsCreateDialogOpen(false);
    }
  };

  const handleCreate = () => {
    if(hasPointCutAddAccessRight){
      dispatch(setType({ index: "null", type: "create" }));
      setIsCreateDialogOpen(true);
    }
  };

  return (
    <>
      <PointCutListPanelComponent
        hasPointCutAddAccessRight={hasPointCutAddAccessRight}
        hasPointCutEditAccessRight={hasPointCutEditAccessRight}
        hasPointCutActivateOrDeactivateAccessRight={hasPointCutActivateOrDeactivateAccessRight}
        data={data}
        isFetching={isFetching}
        page={page}
        search={search}
        rowsPerPage={rowsPerPage}
        totalRecords={search ? filteredTotalRecords : totalRecords}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleSearch={handleSearch}
        handleCreate={handleCreate}
        handleStatusChange={handleStatusChange}
        handleReload={handleFetchPointCutListing}
        paginationOptions={paginationOptions}
      />
      <PointCutDialog
        isOpen={isCreateDialogOpen}
        handleClose={() => setIsCreateDialogOpen(false)}
        handleSubmit={handleCreatePointCut}
      />
      <ConfirmationDialog
        isOpen={isActivateDialogOpen}
        handleClose={() => setIsActivateDialogOpen(false)}
        handleProceed={() => handleStatusSubmit(true)}
        isLoading={isStatusUpdating}
        type={"success"}
        description={getLang(lang,"paragraph.ACTIVATE_POINT_CUT_QUESTION")}
        title={getLang(lang,"label.CONFIRMATION_NEEDED")}
      />
      <ConfirmationDialog
        isOpen={isDeactivateDialogOpen}
        handleClose={() => setIsDeactivateDialogOpen(false)}
        handleProceed={() => handleStatusSubmit(false)}
        isLoading={isStatusUpdating}
        type={"danger"}
        description={getLang(lang,"paragraph.DEACTIVATE_POINT_CUT_QUESTION")}
        title={getLang(lang,"label.CONFIRMATION_NEEDED")}
      />
    </>
  );
})

export default connect(null, null, null, { forwardRef: true })(GiftListPanelContainer)