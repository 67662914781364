import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel } from "@material-ui/core";
import { TablePaginationActions } from "components/tablePagination";
import { generatePaginationOptions } from "lib/helper";
import { fontTextStyle, tableStyle } from "modules/reporting/styles";
import { responseHandler } from "modules/reporting/utils/helper";
import React, { useEffect, useState } from "react";
import { TableCellLoading } from "../../skeleton/loading";
import { TableCellNoData } from "../../skeleton/no-data";
import { TableCellNoResult } from "../../skeleton/no-result";

export default function DynamicTableComponent({
    columns = [],
    sort,
    status,
    pagination,
    data,
    translate,
}){
    const fontClasses = fontTextStyle();
    const tableClasses = tableStyle();

    const createSortHandler = (property) => (event) => {
        sort.handleRequestSort(event, property);
    };

    const displayData = (data) => {

        if(data.length === 0) {
            return null;
        }
        return data.rows.map((row, index) => {
            return row.render;
        })
    }
    const [paginationOptions, setPaginationOptions] = useState([]);
    useEffect(() => {
        setPaginationOptions(generatePaginationOptions(data ? data.total_record : 0, [25,50,100]))
    }, [data]);
    
    return (
        <TableContainer component={Paper}>
            <Box style={{
                overflowX: "auto",
                width: "100%"
            }}>
                <Table>
                    {/* TABLE HEAD --- START */}
                    <TableHead>
                        <TableRow>
                            {columns.map((column, index) => {
                                return (
                                    <TableCell className={[tableClasses.table__cell, fontClasses.text__header_table_cell]} key={column.title} size={column.size}>
                                        {
                                            column.field ? 
                                            (
                                                <TableSortLabel
                                                    active={sort.valueToOrderBy === column.field ? true : false}
                                                    direction={sort.valueToOrderBy === column.field ? sort.orderDirection : 'desc'}
                                                    onClick={!status && column.field ? createSortHandler(column.field) : null}
                                                >
                                                    {column.render(column)}
                                                </TableSortLabel>
                                            ) : 
                                            column.render(column)
                                        }
                                    </TableCell>
                                )
                            })}
                        </TableRow>
                    </TableHead>
                    {/* TABLE HEAD --- END */}
                    
                    {/* TABLE BODY --- START */}
                    <TableBody>
                        {
                            responseHandler(
                                data,
                                status,
                                (
                                    displayData(data)
                                ),
                                (
                                    <TableRow>
                                        <TableCellLoading />
                                    </TableRow>
                                ),
                                (
                                    <TableRow>
                                        <TableCellNoData
                                            title={translate("label.CUSTOMER_NOT_FOUND")}
                                            value={translate("paragraph.NO_DATA_ON_SELECTED_FILTER")}
                                        />
                                    </TableRow>
                                ),
                                (
                                    <TableRow>
                                        <TableCellNoResult
                                            title={translate("label.CUSTOMER_NOT_FOUND")}
                                            value={translate("paragraph.NO_DATA_ON_SELECTED_FILTER")}
                                        />
                                    </TableRow>
                                ),
                                (
                                    <TableRow>
                                        <TableCellNoResult
                                        title={translate("label.LOADING_FAILED")}
                                        value={translate("paragraph.LOADING_FAILED_DESCRIPTION")}
                                        />
                                    </TableRow>
                                )
                            )
                        }
                    </TableBody>
                </Table>
            </Box>
            <TablePagination
                ActionsComponent={TablePaginationActions}
                component="div"

                // rowsPerPageOptions={pagination.rowsPerPageOptions}
                rowsPerPageOptions={paginationOptions}
                rowsPerPage={pagination.rowsPerPage}
                page={pagination.page}
                count={data.total_record ?? 0}
                onChangePage={pagination.handleChangePage}
                onChangeRowsPerPage={pagination.handleChangeRowPerPage}
            />
        </TableContainer>
    )
}