import { createSlice } from "@reduxjs/toolkit";
import {
  getRecallDealerDropdown,
  getRecallDetail,
  getRecallItemList,
  getRecallList,
  getRecallRequestorDropdown,
  STOCKFLOWRECALL,
} from "../action/stockflowRecall.action";

const initialState = {
  recallList: [],
  isLoadingRecallListError: false,
  totalFiltered: 0,
  totalRecords: 0,
  selectedIds: [],
  isSelectAll: false,
  recall: null,
  isLoadingRecallError: false,
  recallItems: [],
  isLoadingRecallItemsError: false,
  recallRequestorDropdown: [],
  recallDealerDropdown: [],
};

const stockflowRecallSlice = createSlice({
  name: STOCKFLOWRECALL,
  initialState,
  reducers: {
    changeSelectedRecall(state, action) {
      const { payload } = action;

      state.recallList[payload.index].isSelected = payload.isSelected;
      if (
        (!state.isSelectAll && payload.isSelected) ||
        (state.isSelectAll && !payload.isSelected)
      ) {
        state.selectedIds = [
          ...state.selectedIds,
          state.recallList[payload.index].id,
        ];
      } else {
        state.selectedIds = state.selectedIds.filter(
          (id) => id !== state.recallList[payload.index].id
        );
      }
    },
    changeBulkSelectedRecall(state, action) {
      const { payload } = action;

      state.recallList.forEach((s) => {
        s.isSelected = payload.value;
      });
      state.isSelectAll = payload.value;
      state.selectedIds = [];
    },
    changeBulkSelectedAvailableRecall(state, action) {
      const { payload } = action;
      state.recallList.forEach((s) => {
        s.isSelected = payload.value;
        if (
          (!state.isSelectAll && payload.value) ||
          (state.isSelectAll && !payload.value)
        ) {
          if (state.selectedIds.findIndex((id) => id === s.id) === -1) {
            state.selectedIds = [...state.selectedIds, s.id];
          }
        } else {
          state.selectedIds = state.selectedIds.filter((id) => id !== s.id);
        }
      });
    },
    resetRecallListing(state) {
      state.recallList = initialState.recallList;
      state.selectedIds = initialState.selectedIds;
      state.isSelectAll = initialState.isSelectAll;
    },
    resetRecall(state) {
      state.recall = initialState.recall;
    },
  },
  extraReducers: {
    [getRecallList.pending]: (state) => {
      state.recallList = initialState.recallList;
      state.isLoadingRecallListError = false;
    },
    [getRecallList.fulfilled]: (state, action) => {
      const { payload } = action;
      const temp = [];
      payload.data.forEach((item) => {
        let selectedState = !state.isSelectAll
          ? state.selectedIds.findIndex((id) => id === item.id) !== -1
          : state.selectedIds.findIndex((id) => id === item.id) === -1;
        temp.push({
          ...item,
          isSelected: selectedState,
        });
        return null;
      });
      state.recallList = temp;
      state.totalRecords = payload.recordsTotal;
      state.totalFiltered = payload.recordsFiltered;
    },
    [getRecallList.rejected]: (state) => {
      state.isLoadingRecallListError = true;
      state.totalRecords = 0;
      state.totalFiltered = 0;
    },
    [getRecallDetail.pending]: (state) => {
      state.recall = initialState.recall;
      state.isLoadingRecallError = false;
    },
    [getRecallDetail.fulfilled]: (state, action) => {
      state.recall = action.payload;
    },
    [getRecallDetail.rejected]: (state) => {
      state.isLoadingRecallError = true;
    },
    [getRecallRequestorDropdown.pending]: (state) => {
      state.recallRequestorDropdown = initialState.recallRequestorDropdown;
    },
    [getRecallRequestorDropdown.fulfilled]: (state, action) => {
      state.recallRequestorDropdown = action.payload;
    },
    [getRecallDealerDropdown.pending]: (state) => {
      state.recallDealerDropdown = initialState.recallDealerDropdown;
    },
    [getRecallDealerDropdown.fulfilled]: (state, action) => {
      state.recallDealerDropdown = action.payload;
    },
    [getRecallItemList.pending]: (state) => {
      state.recallItems = initialState.recallItems;
      state.isLoadingRecallItemsError = false;
    },
    [getRecallItemList.fulfilled]: (state, action) => {
      state.recallItems = action.payload;
    },
    [getRecallItemList.rejected]: (state) => {
      state.isLoadingRecallItemsError = true;
    },
  },
});

export const {
  changeSelectedRecall,
  changeBulkSelectedRecall,
  changeBulkSelectedAvailableRecall,
  resetRecallListing,
  resetRecall,
} = stockflowRecallSlice.actions;

export const stockflowRecallReducer = stockflowRecallSlice.reducer;
