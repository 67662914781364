import axios from "axios";

const url = "/serialnumber/api/v1/products/get-all-dropdown";

const getAllProducts = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {}, { timeout: 15000 })
      .then(response => resolve(response.data))
      .catch(error => {
        return reject(error.response.data);
      });
  });
};


export default getAllProducts;
