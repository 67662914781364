import axios from "axios"

export function respondRedemption({ 
  id,
  status,
  remarks,
}) {
  
  const url = `/distributor/admin/api/v1/rewards/redemptions/${id}/respond`;
  
  return new Promise((resolve, reject) => {
    axios.post(url, {
      status: status,
      remarks: remarks
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data))
  })
}