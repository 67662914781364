import { addAlert, setLoading } from "modules/notification";
import {
    changeSurveyStatus,
    createSurvey,
    editSurvey,
    fetchSurveyListing,
    getSurvey,
    fetchRespondentListing,
    fetchRespondentDetails,
    fetchRespondentQuestionListing,
    fetchRespondentAnswerListing,
    fetchTotalVisit,
    fetchTotalResponse,
    fetchSurveyResponseCount,
    fetchSurveyByAge,
    fetchSurveyByDevice,
    fetchSurveyByGender,
    fetchSurveyByProduct,
    fetchSurveyGeoCount,
    fetchResponseByChoiceCount,
    deleteSurvey,
    getAvailableSurveys
} from "../action";

import { getApiLang } from "app/feature/constants";

const fetchSurveyListingMiddleware = ({ dispatch, getState }) => next => action => {
    next(action);

    const { payload, type } = action;

    switch (type) {
        case fetchSurveyListing.pending.type:
            dispatch(setLoading({ id: fetchSurveyListing.typePrefix, state: true }));
            break;
        case fetchSurveyListing.fulfilled.type:
            dispatch(setLoading({ id: fetchSurveyListing.typePrefix, state: false }));
            break;
        case fetchSurveyListing.rejected.type:
            const lang = getState().constant.languages
            dispatch(setLoading({ id: fetchSurveyListing.typePrefix, state: false }));
            dispatch(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
            break;
        default:
            break;
    }
};

const changeSurveyStatusMiddleware = ({ dispatch, getState }) => next => action => {
    next(action);

    const { payload, type } = action;
    const lang = getState().constant.languages

    switch (type) {
        case changeSurveyStatus.pending.type:
            dispatch(setLoading({ id: changeSurveyStatus.typePrefix, state: true }));
            break;
        case changeSurveyStatus.fulfilled.type:
            dispatch(setLoading({ id: changeSurveyStatus.typePrefix, state: false }));
            dispatch(addAlert({ severity: "success", message: getApiLang(lang, "success.SUCCESS_CHANGE_SURVEY_STATUS") }));
            break;
        case changeSurveyStatus.rejected.type:
            dispatch(setLoading({ id: changeSurveyStatus.typePrefix, state: false }));
            dispatch(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
            break;
        default:
            break;
    }
};

const createSurveyMiddleware = ({ dispatch, getState }) => next => action => {
    next(action);

    const { payload, type } = action;
    const lang = getState().constant.languages

    switch (type) {
        case createSurvey.pending.type:
            dispatch(setLoading({ id: createSurvey.typePrefix, state: true }));
            break;
        case createSurvey.fulfilled.type:
            dispatch(setLoading({ id: createSurvey.typePrefix, state: false }));
            dispatch(addAlert({ severity: "success", message: getApiLang(lang, "success.SUCCESS_CREATE_SURVEY") }));
            break;
        case createSurvey.rejected.type:
            dispatch(setLoading({ id: createSurvey.typePrefix, state: false }));
            dispatch(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
            break;
        default:
            break;
    }
};

const getSurveyMiddleware = ({ dispatch, getState }) => next => action => {
    next(action);

    const { payload, type } = action;
    const lang = getState().constant.languages

    switch (type) {
        case getSurvey.pending.type:
            dispatch(setLoading({ id: getSurvey.typePrefix, state: true }));
            break;
        case getSurvey.fulfilled.type:
            dispatch(setLoading({ id: getSurvey.typePrefix, state: false }));
            break;
        case getSurvey.rejected.type:
            dispatch(setLoading({ id: getSurvey.typePrefix, state: false }));
            dispatch(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
            break;
        default:
            break;
    }
};

const editSurveyMiddleware = ({ dispatch, getState }) => next => action => {
    next(action);

    const { payload, type } = action;
    const lang = getState().constant.languages

    switch (type) {
        case editSurvey.pending.type:
            dispatch(setLoading({ id: editSurvey.typePrefix, state: true }));
            break;
        case editSurvey.fulfilled.type:
            dispatch(setLoading({ id: editSurvey.typePrefix, state: false }));
            dispatch(addAlert({ severity: "success", message: getApiLang(lang, "success.SUCCESS_EDIT_SURVEY") }));
            break;
        case editSurvey.rejected.type:
            dispatch(setLoading({ id: editSurvey.typePrefix, state: false }));
            dispatch(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
            break;
        default:
            break;
    }
};

const fetchRespondentListingMiddleware = ({ dispatch, getState }) => next => action => {
    next(action)

    const { payload, type } = action;
    const lang = getState().constant.languages

    switch (type) {
        case fetchRespondentListing.pending.type:
            dispatch(setLoading({ id: fetchRespondentListing.typePrefix, state: true }));
            break;
        case fetchRespondentListing.fulfilled.type:
            dispatch(setLoading({ id: fetchRespondentListing.typePrefix, state: false }));
            break;
        case fetchRespondentListing.rejected.type:
            dispatch(setLoading({ id: fetchRespondentListing.typePrefix, state: false }));
            dispatch(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
            break;
        default:
            break;
    }
}

const fetchRespondentDetailsMiddleware = ({ dispatch, getState }) => next => action => {
    next(action)

    const { type, payload } = action;
    const lang = getState().constant.languages

    switch (type) {
        case fetchRespondentDetails.pending.type:
            dispatch(setLoading({ id: fetchRespondentDetails.typePrefix, state: true }));
            break;
        case fetchRespondentDetails.fulfilled.type:
            dispatch(setLoading({ id: fetchRespondentDetails.typePrefix, state: false }));
            break;
        case fetchRespondentDetails.rejected.type:
            dispatch(setLoading({ id: fetchRespondentDetails.typePrefix, state: false }));
            dispatch(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
            break;
        default:
            break;
    }
}

const fetchRespondentQuestionListingMiddleware = ({ dispatch }) => next => action => {
    next(action)

    const { type } = action;

    switch (type) {
        case fetchRespondentQuestionListing.pending.type:
            dispatch(setLoading({ id: fetchRespondentQuestionListing.typePrefix, state: true }));
            break;
        case fetchRespondentQuestionListing.fulfilled.type:
            dispatch(setLoading({ id: fetchRespondentQuestionListing.typePrefix, state: false }));
            break;
        case fetchRespondentQuestionListing.rejected.type:
            dispatch(setLoading({ id: fetchRespondentQuestionListing.typePrefix, state: false }));
            break;
        default:
            break;
    }
}

const fetchRespondentAnswerListingMiddleware = ({ dispatch }) => next => action => {
    next(action)

    const { type } = action;

    switch (type) {
        case fetchRespondentAnswerListing.pending.type:
            dispatch(setLoading({ id: fetchRespondentAnswerListing.typePrefix, state: true }));
            break;
        case fetchRespondentAnswerListing.fulfilled.type:
            dispatch(setLoading({ id: fetchRespondentAnswerListing.typePrefix, state: false }));
            break;
        case fetchRespondentAnswerListing.rejected.type:
            dispatch(setLoading({ id: fetchRespondentAnswerListing.typePrefix, state: false }));
            break;
        default:
            break;
    }
}

const fetchTotalResponseMiddleware = ({ dispatch }) => next => action => {
    next(action)

    const { type } = action;

    switch (type) {
        case fetchTotalResponse.pending.type:
            dispatch(setLoading({ id: fetchTotalResponse.typePrefix, state: true }));
            break;
        case fetchTotalResponse.fulfilled.type:
            dispatch(setLoading({ id: fetchTotalResponse.typePrefix, state: false }));
            break;
        case fetchTotalResponse.rejected.type:
            dispatch(setLoading({ id: fetchTotalResponse.typePrefix, state: false }));
            break;
        default:
            break;
    }
}

const fetchTotalVisitMiddleware = ({ dispatch }) => next => action => {
    next(action)

    const { type } = action;

    switch (type) {
        case fetchTotalVisit.pending.type:
            dispatch(setLoading({ id: fetchTotalVisit.typePrefix, state: true }));
            break;
        case fetchTotalVisit.fulfilled.type:
            dispatch(setLoading({ id: fetchTotalVisit.typePrefix, state: false }));
            break;
        case fetchTotalVisit.rejected.type:
            dispatch(setLoading({ id: fetchTotalVisit.typePrefix, state: false }));
            break;
        default:
            break;
    }
}

const fetchSurveyResponseCountMiddleware = ({ dispatch }) => next => action => {
    next(action)

    const { type } = action;

    switch (type) {
        case fetchSurveyResponseCount.pending.type:
            dispatch(setLoading({ id: fetchSurveyResponseCount.typePrefix, state: true }));
            break;
        case fetchSurveyResponseCount.fulfilled.type:
            dispatch(setLoading({ id: fetchSurveyResponseCount.typePrefix, state: false }));
            break;
        case fetchSurveyResponseCount.rejected.type:
            dispatch(setLoading({ id: fetchSurveyResponseCount.typePrefix, state: false }));
            break;
        default:
            break;
    }
}

const fetchSurveyByProductMiddleware = ({ dispatch }) => next => action => {
    next(action)

    const { type } = action;

    switch (type) {
        case fetchSurveyByProduct.pending.type:
            dispatch(setLoading({ id: fetchSurveyByProduct.typePrefix, state: true }));
            break;
        case fetchSurveyByProduct.fulfilled.type:
            dispatch(setLoading({ id: fetchSurveyByProduct.typePrefix, state: false }));
            break;
        case fetchSurveyByProduct.rejected.type:
            dispatch(setLoading({ id: fetchSurveyByProduct.typePrefix, state: false }));
            break;
        default:
            break;
    }
}

const fetchSurveyByGenderMiddleware = ({ dispatch }) => next => action => {
    next(action)

    const { type } = action;

    switch (type) {
        case fetchSurveyByGender.pending.type:
            dispatch(setLoading({ id: fetchSurveyByGender.typePrefix, state: true }));
            break;
        case fetchSurveyByGender.fulfilled.type:
            dispatch(setLoading({ id: fetchSurveyByGender.typePrefix, state: false }));
            break;
        case fetchSurveyByGender.rejected.type:
            dispatch(setLoading({ id: fetchSurveyByGender.typePrefix, state: false }));
            break;
        default:
            break;
    }
}

const fetchSurveyByDeviceMiddleware = ({ dispatch }) => next => action => {
    next(action)

    const { type } = action;

    switch (type) {
        case fetchSurveyByDevice.pending.type:
            dispatch(setLoading({ id: fetchSurveyByDevice.typePrefix, state: true }));
            break;
        case fetchSurveyByDevice.fulfilled.type:
            dispatch(setLoading({ id: fetchSurveyByDevice.typePrefix, state: false }));
            break;
        case fetchSurveyByDevice.rejected.type:
            dispatch(setLoading({ id: fetchSurveyByDevice.typePrefix, state: false }));
            break;
        default:
            break;
    }
}

const fetchSurveyGeoCountMiddleware = ({ dispatch }) => next => action => {
    next(action)

    const { type } = action;

    switch (type) {
        case fetchSurveyGeoCount.pending.type:
            dispatch(setLoading({ id: fetchSurveyGeoCount.typePrefix, state: true }));
            break;
        case fetchSurveyGeoCount.fulfilled.type:
            dispatch(setLoading({ id: fetchSurveyGeoCount.typePrefix, state: false }));
            break;
        case fetchSurveyGeoCount.rejected.type:
            dispatch(setLoading({ id: fetchSurveyGeoCount.typePrefix, state: false }));
            break;
        default:
            break;
    }
}

const fetchSurveyByAgeMiddleware = ({ dispatch }) => next => action => {
    next(action)

    const { type } = action;

    switch (type) {
        case fetchSurveyByAge.pending.type:
            dispatch(setLoading({ id: fetchSurveyByAge.typePrefix, state: true }));
            break;
        case fetchSurveyByAge.fulfilled.type:
            dispatch(setLoading({ id: fetchSurveyByAge.typePrefix, state: false }));
            break;
        case fetchSurveyByAge.rejected.type:
            dispatch(setLoading({ id: fetchSurveyByAge.typePrefix, state: false }));
            break;
        default:
            break;
    }
}

const fetchResponseByChoiceCountMiddleware = ({ dispatch }) => next => action => {
    next(action)

    const { type } = action;

    switch (type) {
        case fetchResponseByChoiceCount.pending.type:
            dispatch(setLoading({ id: fetchResponseByChoiceCount.typePrefix, state: true }));
            break;
        case fetchResponseByChoiceCount.fulfilled.type:
            dispatch(setLoading({ id: fetchResponseByChoiceCount.typePrefix, state: false }));
            break;
        case fetchResponseByChoiceCount.rejected.type:
            dispatch(setLoading({ id: fetchResponseByChoiceCount.typePrefix, state: false }));
            break;
        default:
            break;
    }
}

const deleteSurveyMiddleware = ({ dispatch, getState }) => next => action => {
    next(action);

    const { payload, type } = action;

    switch (type) {
        case deleteSurvey.pending.type:
            dispatch(setLoading({ id: deleteSurvey.typePrefix, state: true }));
            break;
        case deleteSurvey.fulfilled.type:
            dispatch(setLoading({ id: deleteSurvey.typePrefix, state: false }));
            break;
        case deleteSurvey.rejected.type:
            const lang = getState().constant.languages
            dispatch(setLoading({ id: deleteSurvey.typePrefix, state: false }));
            dispatch(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
            break;
        default:
            break;
    }
};

const getAvailableSurveysMiddleware = ({ dispatch, getState }) => next => action => {
    next(action);

    const { payload, type } = action;

    switch (type) {
        case getAvailableSurveys.pending.type:
            dispatch(setLoading({ id: getAvailableSurveys.typePrefix, state: true }));
            break;
        case getAvailableSurveys.fulfilled.type:
            dispatch(setLoading({ id: getAvailableSurveys.typePrefix, state: false }));
            break;
        case getAvailableSurveys.rejected.type:
            const lang = getState().constant.languages
            dispatch(setLoading({ id: getAvailableSurveys.typePrefix, state: false }));
            dispatch(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
            break;
        default:
            break;
    }
};

export default [
    fetchSurveyListingMiddleware,
    changeSurveyStatusMiddleware,
    createSurveyMiddleware,
    getSurveyMiddleware,
    editSurveyMiddleware,
    fetchRespondentListingMiddleware,
    fetchRespondentDetailsMiddleware,
    fetchRespondentQuestionListingMiddleware,
    fetchRespondentAnswerListingMiddleware,
    fetchTotalResponseMiddleware,
    fetchTotalVisitMiddleware,
    fetchSurveyResponseCountMiddleware,
    fetchSurveyByProductMiddleware,
    fetchSurveyByGenderMiddleware,
    fetchSurveyByDeviceMiddleware,
    fetchSurveyGeoCountMiddleware,
    fetchSurveyByAgeMiddleware,
    fetchResponseByChoiceCountMiddleware,
    deleteSurveyMiddleware,
    getAvailableSurveysMiddleware
]