import ConfirmationDialog from 'components/dialog/confirmation'
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { fetchBonanzaPointListing, setType, changeStatusBonanzaPoint } from 'modules/loyalty-program/redux'
import { connect, useDispatch, useSelector } from 'react-redux'
import { AddBonanzaPointDialog } from 'modules/loyalty-program/components/dialog'
import BonanzaPointListingPanel from './bonanzaListPanel.component'
import { selectLoading } from 'modules/notification'
import { unwrapResult } from '@reduxjs/toolkit'
import { BONANZA_ADD, BONANZA_EDIT, BONANZA_ACTIVATE_OR_DEACTIVATE } from "lib/constants/accessRights"
import { generatePaginationOptions } from "lib/helper";
import { getLang } from 'app/feature/constants'
import {getAllProducts} from "../../../../product-mgmt/redux/action/products.action";

const BonanzaPointListingPanelContainer = forwardRef((_, ref) => {
  const hasBonanzaAddAccessRight = useSelector(state => state.profile.accessRights.includes(BONANZA_ADD));
  const hasBonanzaEditAccessRight = useSelector(state => state.profile.accessRights.includes(BONANZA_EDIT));
  const hasBonanzaActivateOrDeactivateAccessRight = useSelector(state => state.profile.accessRights.includes(BONANZA_ACTIVATE_OR_DEACTIVATE));
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [search, setSearch] = useState({search: "", product: []});
  const [isDeactivateDialogOpen, setIsDeactivateDialogOpen] = useState(false)
  const [isActivateDialogOpen, setIsActivateDialogOpen] = useState(false)
  const [changeStatusTargetId, setChangeStatusTargetId] = useState("");
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false)
  const [paginationOptions, setPaginationOptions] = useState([]);

  const dispatch = useDispatch()
  const data = useSelector(state => state.loyaltyProgram.bonanzaListTable.data)
  const totalRecords = useSelector(state => state.loyaltyProgram.bonanzaListTable.totalRecords)
  const filteredTotalRecords = useSelector(state => state.loyaltyProgram.bonanzaListTable.filteredTotalRecords)
  const isStatusUpdating = useSelector(state => selectLoading(state, changeStatusBonanzaPoint.typePrefix))
  const isFetching = useSelector(state => selectLoading(state, fetchBonanzaPointListing.typePrefix))
  const productDropdown = useSelector(state => state.products.allProducts);
  const isFetchingProduct = useSelector(state => selectLoading(state, getAllProducts.typePrefix));
  const lang = useSelector(state => state.constant.languages)

  useEffect(() => {
    dispatch(getAllProducts());
  }, [dispatch]);

  useImperativeHandle(ref, () => ({
    openCreateLoyaltyProgramModal: () => {
      dispatch(setType({ index: "null", type: "create" }));
      setIsCreateDialogOpen(true)
    },
  }));

  useEffect(() => {
    dispatch(fetchBonanzaPointListing({
      length: rowsPerPage,
      start: rowsPerPage * page,
      search: search.searchString,
      product: search.product.map(item => item.id),
    }))
  }, [search, rowsPerPage, page, dispatch])

  useEffect(() => {
    setPaginationOptions(generatePaginationOptions(totalRecords))
  }, [totalRecords])

  const handleFetchBonanzaPointListing = () => {
    dispatch(fetchBonanzaPointListing({
      length: rowsPerPage,
      start: rowsPerPage * page,
      search: search.searchString,
      product: search.product.map(item => item.id),
    }))
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = value => {
    setPage(0)
    setRowsPerPage(value)
  }

  const handleSearch = value => {
    setPage(0)
    setSearch(value)
  }

  const handleStatusChange = ({ currentStatus, id }) => {
    if (hasBonanzaActivateOrDeactivateAccessRight) {
      setChangeStatusTargetId(id)
      if (currentStatus) {
        setIsDeactivateDialogOpen(true)
      }
      else {
        setIsActivateDialogOpen(true)
      }
    }
  }
  
  const handleStatusSubmit = status => {
    if(hasBonanzaActivateOrDeactivateAccessRight){
      dispatch(changeStatusBonanzaPoint({
        id: changeStatusTargetId,
        status: status ? "active" : "inactive",
      }))
        .then(unwrapResult)
        .then(() => handleFetchBonanzaPointListing())
        .finally(() => {
          setIsDeactivateDialogOpen(false);
          setIsActivateDialogOpen(false);
        });
    }
  }

  const handleCreateBonanzaPoint = (values) => {
    if (hasBonanzaAddAccessRight) {
      setIsCreateDialogOpen(false);
    }
  }

  const handleCreate = () => {
    if (hasBonanzaAddAccessRight) {
      dispatch(setType({ index: "null", type: "create" }));
      setIsCreateDialogOpen(true)
    }
  }

  return (
    <>
      <BonanzaPointListingPanel
        hasBonanzaAddAccessRight={hasBonanzaAddAccessRight}
        hasBonanzaEditAccessRight={hasBonanzaEditAccessRight}
        hasBonanzaActivateOrDeactivateAccessRight={hasBonanzaActivateOrDeactivateAccessRight}
        data={data}
        isFetching={isFetching}
        page={page}
        search={search}
        rowsPerPage={rowsPerPage}
        totalRecords={search ? filteredTotalRecords : totalRecords}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleSearch={handleSearch}
        handleCreate={handleCreate}
        handleStatusChange={handleStatusChange}
        handleReload={handleFetchBonanzaPointListing}
        paginationOptions={paginationOptions}
        productDropdown={productDropdown}
        isFetchingProduct={isFetchingProduct}
      />
      <AddBonanzaPointDialog
        isOpen={isCreateDialogOpen}
        handleClose={() => setIsCreateDialogOpen(false)}
        handleSubmit={handleCreateBonanzaPoint}
      />
      <ConfirmationDialog
        isOpen={isActivateDialogOpen}
        handleClose={() => setIsActivateDialogOpen(false)}
        handleProceed={() => handleStatusSubmit(true)}
        isLoading={isStatusUpdating}
        type={"success"}
        description={getLang(lang,"paragraph.ACTIVATE_BONANZA_POINT_QUESTION")}
        title={getLang(lang,"label.CONFIRMATION_NEEDED")}
      />
      <ConfirmationDialog
        isOpen={isDeactivateDialogOpen}
        handleClose={() => setIsDeactivateDialogOpen(false)}
        handleProceed={() => handleStatusSubmit(false)}
        isLoading={isStatusUpdating}
        type={"danger"}
        description={getLang(lang,"paragraph.DEACTIVATE_BONANZA_POINT_QUESTION")}
        title={getLang(lang,"label.CONFIRMATION_NEEDED")}
      />
    </>
  )
})

export default connect(null, null, null, { forwardRef: true })(BonanzaPointListingPanelContainer)
