import React from "react";
import {
  makeStyles,
  Paper,
  Typography,
  Grid,
  Box,
  Divider,
  Button,
  FormHelperText,
  Backdrop,
  Modal,
} from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import InputTextField from "components/input/inputTextField";
import AntSwitch from "components/switch";
import EditorComponent from "components/editor/editor.container";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import UploadDocumentComponent from "components/uploadDocument/uploadDocument.container";
import { Formik, ErrorMessage } from "formik";
import DateTimePicker from "components/input/dateTimePicker";
import moment from "moment";
import 'moment/min/locales.min';
import * as Yup from "yup";
import clsx from "clsx";
import PerfectScrollbar from "react-perfect-scrollbar";
import { AddImageSvg } from "modules/product-mgmt/utils/staticSvg";
import AssignProductSelect from "components/select/assignProductSelect";
import AssignProductTable from "components/table/assignProductTable";
import DefaultPhoto from "../../../utils/defaultLuckyDrawPhoto.png";
import { isFile, isFileExceedLimit } from "lib/helper";
import { addAlert } from "modules/notification";
import { useDispatch } from "react-redux";
import { getLang } from "app/feature/constants";
import { Document, Page, pdfjs } from "react-pdf";
import ImageEditorPanel from "../../../../../components/panel/landingPageImageEditorPanel";
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const style = (theme) => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  gridFieldPeriod: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    paddingBottom: "7px !important",
  },
  gridEditField: {
    paddingTop: "10px !important",
  },
  input: {
    display: "none",
  },
  dividerContainer: {
    backgroundColor: "#ECEFF0",
    display: "flex",
  },
  switchContainer: {
    display: "grid",
    paddingTop: theme.spacing(3),
    gridTemplateColumns: "3fr 1fr 10fr",
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "3fr 1fr 4fr",
    },
  },
  switch: {
    display: "flex",
    alignItems: "center",
  },
  helperText: {
    marginTop: 0,
  },
  textDisable: {
    fontWeight: "bold",
    paddingLeft: "8px",
    color: "rgba(58, 77, 84, 0.33)",
  },
  textActive: {
    fontWeight: "bold",
    paddingLeft: "8px",
    color: theme.palette.primary.main,
  },
  viewTC: {
    background: "#EBEDEE",
    borderRadius: "0px 0px 4px 4px",
    padding: theme.spacing(1, 4, 1, 3),
    wordBreak: "break-all",
    height: "100%",
  },
  viewUrl: {
    wordBreak: "break-all",
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(2),
  },
  scroll: {
    overflowY: "auto",
    height: "230px",
  },
  dateView: {
    paddingRight: theme.spacing(1),
  },
  dateContainer: {
    height: 43,
    backgroundColor: "#ECEFF0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 60,
    borderRadius: "4px 0px 0px 4px",
  },
  dividerEdit: {
    height: "70%",
  },
  divider: {
    height: "100%",
    display: "flex",
    alignSelf: "center",
  },
  button: {
    backgroundColor: "#ECEFF0",
    marginBottom: "4px",
    boxShadow: "none",
    fontSize: "14px",
    fontFamily: "roboto",
    color: "rgba(32, 32, 32, 0.33)",
    width: "100%",
    height: 43,
    borderRadius: 4,
    display: "flex",
    placeContent: "start",
    padding: "5px 10px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#ECEFF0",
      boxShadow: "none",
    },
  },
  buttonLabel: {
    display: "flex",
    justifyContent: "space-between",
  },
  buttonLabelImage: {
    color: "rgba(0, 0, 0, 0.87)",
  },
  endIcon: {
    marginRight: 0,
  },
  photoContainer: {
    width: "150px",
    height: "150px",
    "& img": {
      height: "inherit",
    },
  },
  gridContainerField: {
    paddingTop: theme.spacing(1),
  },
  gridContainereView: {
    paddingBottom: theme.spacing(2),
  },
  productTableView: {
    marginTop: "-16px",
  },
  pageControl: {
    background: "white",
    borderRadius: "4px",
  },
  pdfScrollContainer: {
    width: "40%",
    display: "flex",
    height: "21em",
    flexDirection: "column",
    alignItems: "center",
    border: "1px solid #00001A",
    borderRadius: "4px",
  },
  imageInputBox: {
    width: 130,
    height: 130,
    backgroundColor: theme.palette.primary.main,
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: theme.spacing(0.5),
    margin: theme.spacing(2, 0),
    transition: theme.transitions.create("background-color", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
});

const useStyles = makeStyles(style);

export default function LuckyDrawDetailFormComponent({
  children,
  handleSubmit,
  type,
  LuckyDrawDetail,
  setLuckyDrawImageFile,
  luckyDrawImageFile,
  lang,
  onDocumentLoadSuccess,
  pageNumber,
  numPages,
  handleTncPdfChangePage,
  handleCloseCropping,
  handleOpenCropping,
  isCropping,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const viewType = type === "view" ? true : false;

  const options = {
    cMapUrl: "cmaps/",
    cMapPacked: true,
    standardFontDataUrl: "standard_fonts/",
  };

  const renderFormField = (field, index, formik) => {
    switch (field.type) {
      case "date":
        return [
          {
            label: getLang(lang, "label.FROM"),
            date: "startAt",
          },
          {
            label: getLang(lang, "label.TO"),
            date: "endAt",
          },
        ].map((field, index) => (
          <Grid
            item
            xs={7}
            sm={4}
            md={4}
            key={index}
            className={classes.gridFieldPeriod}
          >
            <Box display="flex">
              <Box
                className={clsx({
                  [classes.dateView]: viewType,
                  [classes.dateContainer]: !viewType,
                })}
              >
                <Typography
                  variant="subtitle2"
                  style={{
                    fontWeight: "bold",
                    color: " rgba(58, 77, 84, 0.33)",
                  }}
                >
                  {field.label}
                </Typography>
              </Box>
              <Box className={classes.dividerContainer}>
                <Divider
                  orientation="vertical"
                  className={clsx(classes.divider, {
                    [classes.dividerEdit]: !viewType,
                  })}
                />
              </Box>
            </Box>

            {viewType ? (
              <Typography
                variant="body2"
                color="primary"
                style={{
                  fontWeight: "bold",
                  paddingLeft: "16px",
                }}
              >
                {moment(formik.getFieldProps(field.date).value).format('lll')}
              </Typography>
            ) : (
              <DateTimePicker
                minDate={
                  field.label === getLang(lang, "label.TO")
                    ? moment(formik.values.startAt)
                    : new Date()
                }
                required
                label=""
                disabled={
                  formik.isSubmitting ||
                  (field.label === getLang(lang, "label.FROM") &&
                    moment(LuckyDrawDetail.startAtStatic).isBefore(moment()))
                }
                disableFuture={false}
                id={field.label + "-picker-dialog"}
                value={formik.getFieldProps(field.date).value}
                handleChange={(v) => {
                  formik.setFieldValue(
                    field.date,
                    v.format("lll")
                  );
                  if (
                    field.date === "startAt" &&
                    moment(v).isSameOrAfter(moment(formik.values.endAt))
                  ) {
                    formik.setFieldValue(
                      "endAt",
                      moment(v).add(1, "minutes").format("lll")
                    );
                  }

                  if (
                    field.date === "endAt" &&
                    moment(v).isSameOrBefore(moment(formik.values.startAt))
                  ) {
                    formik.setFieldValue(
                      "endAt",
                      moment(formik.values.startAt)
                        .add(1, "minutes")
                        .format("lll")
                    );
                  }
                }}
                InputPropStyle={{
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                }}
              />
            )}
          </Grid>
        ));
      case "text":
      case "paragraph":
        return (
          <Grid item xs={7} sm={8} md={8}>
            {viewType ? (
              <Typography
                variant="body2"
                color="primary"
                style={{ fontWeight: "bold" }}
              >
                {formik.getFieldProps(field.name).value}
              </Typography>
            ) : (
              <InputTextField
                required
                variant="filled"
                size="small"
                fullWidth
                multiline={field.type === "paragraph"}
                rows={field.type === "paragraph" ? 3 : null}
                placeholder={field.label}
                {...formik.getFieldProps(field.name)}
                error={
                  formik.touched[field.name] && formik.errors[field.name]
                    ? true
                    : false
                }
                helperText={ErrorMessage({
                  name: field.name,
                })}
              />
            )}
          </Grid>
        );
      case "image":
        return (
          <>
            <Grid item xs={7} sm={8} md={8}>
              {viewType ? (
                <Box className={classes.photoContainer}>
                  <img
                    src={
                      LuckyDrawDetail
                        ? LuckyDrawDetail.luckyDrawImageUrl
                        : DefaultPhoto
                    }
                    alt="defaultLuckyDraw"
                  />
                </Box>
              ) : (
                <>
                  <input
                    accept="image/*"
                    className={classes.input}
                    id={"contained-button-file" + index}
                    type="file"
                    onChange={(e) => {
                      e.persist();
                      if (e.currentTarget.files.length > 0) {
                        if (isFileExceedLimit(e.currentTarget.files[0].size, 12, 'MB')) {
                          dispatch(addAlert({
                            severity: "error",
                            message: getLang(lang, "message.error.FORM_VALIDATE_FILE_SIZE", { value: 12 })
                          }));
                          return;
                        }
                        formik.setFieldValue('cropping', e.currentTarget.files[0]);
                        handleOpenCropping();
                      }
                      e.target.value = null;
                    }}
                  />
                  <Box disabled={formik.isSubmitting}>
                    <Box>
                      <label
                        htmlFor={"contained-button-file" + index}
                        style={{ cursor: "pointer" }}
                      >
                        {formik.values.imageFile ||
                        formik.getFieldProps(field.name).value !== "" ? (
                          <Box
                            display="flex"
                            alignItems="center"
                            className={classes.photoContainer}
                          >
                            {!!formik.values.imageFile ? (
                              <img
                                src={
                                  formik.values.imageFile && isFile(formik.values.imageFile)
                                    ? URL.createObjectURL(formik.values.imageFile)
                                    : formik.values.imageFile
                                }
                                alt="poster"
                              />
                            ) : (
                              <img
                                src={formik.values.luckyDrawImageUrl}
                                alt="poster"
                              />
                            )}
                            <Typography
                              color="textSecondary"
                              variant="body2"
                              style={{marginLeft: 12}}
                            >
                              {getLang(lang, "label.CHOOSE_IMAGE")}
                            </Typography>
                          </Box>
                        ) : (
                          <Box className={classes.imageInputBox}>
                            <AddImageSvg />
                          </Box>
                        )}
                      </label>
                      <FormHelperText
                        error={formik.errors[field.name] ? true : false}
                      >
                        {ErrorMessage({ name: field.name })}
                      </FormHelperText>
                    </Box>
                    <Box display="flex" alignItems="center">
                      <ReportProblemOutlinedIcon fontSize="small" color="error" />
                      &ensp;
                      <Typography
                        variant="caption"
                        style={{ color: "rgba(58, 77, 84, 0.33)" }}
                      >
                        {getLang(lang, "paragraph.RECOMMENDED_IMAGE_SIZE_CUSTOM", { resolution: '1024 X 445(px)', size: 12, type: 'JPG & PNG' })}
                      </Typography>
                    </Box>
                  </Box>
                </>
              )}
            </Grid>

            <Modal
              style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
              open={isCropping}
              onClose={handleCloseCropping}
              closeAfterTransition
              BackdropComponent={Backdrop}
            >
              {isCropping && (
                <Box>
                  <ImageEditorPanel
                    isCircleRadius={false}
                    height={375}
                    width={375}
                    aspectRatio={[1, 1]}
                    img={isFile(formik.values.cropping) ? URL.createObjectURL(formik.values.cropping) : formik.values.cropping}
                    handleCancel={handleCloseCropping}
                    handleResult={async (value) => {
                      const newFileName = formik.values.cropping.name.split('.');
                      newFileName.pop();
                      newFileName.push('webp');

                      const file = await new File([await (await fetch(value)).blob()], newFileName.join('.'), {type: 'image/webp'});

                      formik.setFieldValue('imageFile', file);
                      handleCloseCropping();
                    }}
                  />
                </Box>
              )}
            </Modal>
          </>
        );
      case "select":
        return (
          <Grid item xs={7} sm={8} md={8}>
            {!viewType && (
              <>
                <AssignProductSelect
                  value={formik.values.assignProduct}
                  handleChange={(value) =>
                    formik.setFieldValue("assignProduct", value)
                  }
                  disabled={type === "view"}
                />
                <ErrorMessage
                  name="assignProduct"
                  render={(errorMsg) => (
                    <span style={{ color: "#f44336", fontSize: "0.75rem" }}>
                      {errorMsg}
                    </span>
                  )}
                />
              </>
            )}
            <div
              className={clsx({
                [classes.productTableView]: viewType,
              })}
            >
              <AssignProductTable tableData={formik.values.assignProduct} />
            </div>
            {!viewType && (
              <Typography
                variant="caption"
                style={{ color: "rgba(58, 77, 84, 0.33)" }}
              >
                {getLang(lang, "paragraph.PRODUCT_TIED_WITH_OTHER_LD")}
              </Typography>
            )}
          </Grid>
        );
      default:
        return null;
    }
  };

  return (
    <Paper elevation={0} className={classes.root}>
      <Formik
        initialValues={{
          ...LuckyDrawDetail,
          luckyDrawTitle: LuckyDrawDetail.luckyDrawTitle || "",
          startAt: LuckyDrawDetail.startAt
            ? LuckyDrawDetail.startAt
            : new moment().format("YYYY-MM-DD HH:mm"),
          endAt: LuckyDrawDetail.endAt
            ? LuckyDrawDetail.endAt
            : new moment().add(7, "day").format("YYYY-MM-DD HH:mm"),
          luckyDrawDescription: LuckyDrawDetail.luckyDrawDescription || "",
          luckyDrawImageUrl: LuckyDrawDetail.luckyDrawImageUrl,
          assignProduct: LuckyDrawDetail.assignProduct,
          luckyDrawTermsConditionText:
            LuckyDrawDetail.luckyDrawTermsConditionText,
          luckyDrawTermsConditionDocument:
            LuckyDrawDetail.luckyDrawTermsConditionDocument,
          luckyDrawTermsConditionDetail:
            LuckyDrawDetail.luckyDrawTermsConditionDetail || "",
          imageFile: luckyDrawImageFile || null
        }}
        validationSchema={Yup.object({
          luckyDrawTitle: Yup.string()
            .trim()
            .required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
          startAt: Yup.date().required(
            getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")
          ),
          endAt: Yup.date().required(
            getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")
          ),
          luckyDrawDescription: Yup.string()
            .trim()
            .required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
          assignProduct: Yup.array().required(
            getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")
          ),
        })}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <Box paddingTop={"16px"} paddingRight={"13px"} paddingBottom={"24px"} paddingLeft={"13px"} height="55vh" overflow="auto">
              <Box>
                {[
                  {
                    title: getLang(lang, "label.LUCKY_DRAW_NAME"),
                    label: getLang(lang, "placeholder.ENTER_LUCKY_DRAW_NAME"),
                    name: "luckyDrawTitle",
                    type: "text",
                  },
                  {
                    title: getLang(lang, "label.EVENT_PERIOD"),
                    type: "date",
                  },
                  {
                    title: getLang(lang, "label.DESCRIPTION"),
                    name: "luckyDrawDescription",
                    label: getLang(lang, "placeholder.ENTER_DESCRIPTION"),
                    type: "paragraph",
                  },
                  {
                    title: getLang(lang, "label.LUCKY_DRAW_IMAGE"),
                    label: getLang(lang, "placeholder.UPLOAD_IMAGE"),
                    name: "luckyDrawImageUrl",
                    type: "image",
                  },
                  {
                    title: getLang(lang, "label.ASSIGN_PRODUCT"),
                    label: getLang(lang, "placeholder.SELECT_PRODUCT"),
                    name: "assignProduct",
                    type: "select",
                  },
                ].map((field, index) => (
                  <Grid
                    container
                    key={index}
                    spacing={1}
                    className={clsx(classes.gridContainerField, {
                      [classes.gridContainereView]: viewType,
                    })}
                  >
                    <Grid
                      item
                      xs={5}
                      sm={4}
                      md={4}
                      className={clsx({
                        [classes.gridEditField]: !viewType,
                      })}
                    >
                      <Typography variant="body2" color="primary">
                        {field.title}{" "}
                        <Typography
                          style={{ color: "#DE350B", display: "inline-block" }}
                        >
                          *
                        </Typography>
                      </Typography>
                    </Grid>
                    {renderFormField(field, index, formik)}
                  </Grid>
                ))}

                <Box pt={2}>
                  {type === "view" ? (
                    <Box pt={2} pb={2}>
                      <PerfectScrollbar
                        className={classes.scroll}
                        options={{
                          suppressScrollX: true,
                        }}
                      >
                        <Box
                          className={clsx({
                            [classes.viewTC]: formik.getFieldProps(
                              "luckyDrawTermsConditionText"
                            ).value,
                            [classes.viewUrl]: formik.getFieldProps(
                              "luckyDrawTermsConditionDocument"
                            ).value,
                          })}
                        >
                          {LuckyDrawDetail.luckyDrawTermsConditionDocument ? (
                            <>
                              <Box>
                                <Document
                                  file={
                                    formik.getFieldProps(
                                      "luckyDrawTermsConditionUrl"
                                    ).value
                                  }
                                  onLoadSuccess={onDocumentLoadSuccess}
                                  options={options}
                                >
                                  <Page pageNumber={pageNumber} />
                                </Document>
                              </Box>
                            </>
                          ) : (
                            <>
                              <Box
                                dangerouslySetInnerHTML={{
                                  __html: formik.getFieldProps(
                                    "luckyDrawTermsConditionDetail"
                                  ).value.value,
                                }}
                              />
                            </>
                          )}
                        </Box>
                      </PerfectScrollbar>
                      {formik.getFieldProps("luckyDrawTermsConditionDocument")
                        .value &&
                        numPages !== null &&
                        numPages > 0 && (
                          <Box className={classes.pageControl}>
                            <Button
                              onClick={() => handleTncPdfChangePage(false)}
                              disabled={pageNumber === 1}
                            >
                              <ChevronLeftIcon />
                            </Button>
                            <span>
                              Page {pageNumber} of {numPages}
                            </span>
                            <Button
                              onClick={() => handleTncPdfChangePage(true)}
                              disabled={pageNumber === numPages}
                            >
                              <ChevronRightIcon />
                            </Button>
                          </Box>
                        )}
                    </Box>
                  ) : (
                    <>
                      <Typography variant="body2" color="primary">
                        {getLang(lang, "label.TNC")}{" "}
                        <Typography
                          style={{ color: "#DE350B", display: "inline-block" }}
                        >
                          *
                        </Typography>
                      </Typography>
                      <Box className={classes.switchContainer}>
                        <Box className={classes.switch}>
                          <AntSwitch
                            checked={
                              formik.getFieldProps(
                                "luckyDrawTermsConditionText"
                              ).value
                            }
                            onClick={(e) => {
                              formik.setFieldValue(
                                "luckyDrawTermsConditionDocument",
                                false
                              );

                              formik.setFieldValue(
                                "luckyDrawTermsConditionText",
                                true
                              );
                            }}
                            disabled={formik.isSubmitting}
                          />
                          <Typography
                            variant="body2"
                            className={clsx(classes.textDisable, {
                              [classes.textActive]: formik.getFieldProps(
                                "luckyDrawTermsConditionText"
                              ).value,
                            })}
                          >
                            {getLang(lang, "label.TEXT_BOX")}
                          </Typography>
                        </Box>

                        <Typography
                          variant="body2"
                          style={{ fontWeight: "bold" }}
                        >
                          {getLang(lang, "label.OR")}
                        </Typography>

                        <Box className={classes.switch}>
                          <AntSwitch
                            checked={
                              formik.getFieldProps(
                                "luckyDrawTermsConditionDocument"
                              ).value
                            }
                            onClick={(e) => {
                              formik.setFieldValue(
                                "luckyDrawTermsConditionText",
                                false
                              );

                              formik.setFieldValue(
                                "luckyDrawTermsConditionDocument",
                                true
                              );
                            }}
                            disabled={formik.isSubmitting}
                          />
                          <Typography
                            variant="body2"
                            className={clsx(classes.textDisable, {
                              [classes.textActive]: formik.getFieldProps(
                                "luckyDrawTermsConditionDocument"
                              ).value,
                            })}
                          >
                            {getLang(lang, "label.UPLOAD_DOCUMENT")}
                          </Typography>
                        </Box>
                      </Box>
                      <Box>
                        {formik.getFieldProps("luckyDrawTermsConditionDocument")
                          .value && (
                            <UploadDocumentComponent
                              value={
                                formik.getFieldProps(
                                  "luckyDrawTermsConditionDetail"
                                ).value
                              }
                              handleUpload={(file) => {
                                formik.setFieldValue(
                                  "luckyDrawTermsConditionDetail",
                                  file
                                );
                              }}
                              reuploadFlag={
                                type !== "create" &&
                                LuckyDrawDetail.luckyDrawTermsConditionUrl.substr(
                                  LuckyDrawDetail.luckyDrawTermsConditionUrl
                                    .length - 3
                                ) === "pdf"
                              }
                              maxUploadSize={{ size: 15, unit: 'MB' }}
                            />
                          )}
                        {formik.getFieldProps("luckyDrawTermsConditionText")
                          .value && (
                            <EditorComponent
                              type={type === "create" ? "new" : "edit"}
                              initialValues={
                                formik.getFieldProps(
                                  "luckyDrawTermsConditionDetail"
                                ).value
                              }
                              handleChange={(value) => {
                                formik.setFieldValue(
                                  "luckyDrawTermsConditionDetail",
                                  value
                                );
                              }}
                            />
                          )}
                      </Box>
                    </>
                  )}
                </Box>
              </Box>
            </Box>
            {children ? children() : null}
          </form>
        )}
      </Formik>
    </Paper>
  );
}
