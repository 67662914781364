import React from "react";
import { makeStyles, Box, Button, Typography } from "@material-ui/core";
import { Formik } from "formik";
import Input from "components/input/inputTextField";
//import BranchSelect from '../../select/branchSelect';
import ActionSelect from "../../select/actionSelect";
import ProductListTable from "../../table/productListTable";
import FilterSvg from "assets/svg/filter.svg";
import CategorySearch from "../../select/categorySearch";
//import CategoryMultiSelect from "components/select/categoryMultiSelect";
import SearchableSelect from "components/select/searchableSelect";
import BrandModelSelect from "../../select/brandModelSelect";
import AttributeMultiSelect from "../../select/attributeMultiSelect";
import { getLang } from "app/feature/constants";
import BranchSearch from "modules/stockflow/components/select/branchSearch";

const useStyle = makeStyles((theme) => ({
  searchContainer: {
    backgroundColor: theme.palette.background.paper,
    display: "grid",
    gridTemplateColumns: "5fr 1fr 1fr 1fr",
    columnGap: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1),
    },
  },
  filterMainContainer: {
    backgroundColor: theme.palette.background.paper,
    paddingTop: "12px",
    paddingBottom: "12px",
    paddingRight: "20px",
    paddingLeft: "20px",
    marginBottom: "12px",
  },
  filterFieldContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
    columnGap: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1),
    },
  },
  button: {
    display: "flex",
    alignItems: "center",
    padding: 0,
    minWidth: 0,
    marginLeft: theme.spacing(1),
  },
  inputRootProduct: {
    paddingTop: "10px !important",
    paddingBottom: "8px !important",
    paddingLeft: "12px !important",
    paddingRight: "12px !important",
    fontWeight: 550,
    fontSize: "0.875rem",
  },
  paperRoot: {
    width: "217px",
  },
}));

export default function ProductListPanelComponent({
  hasProductEditAccessRight,
  hasProductAssignAccessRight,
  hasProductAddAccessRight,
  hasProductDeleteAccessRight,
  rows,
  isSelected,
  isSelectAll,
  selectedIds,
  selectedCount,
  isFetching,
  isError,
  handleReload,
  page,
  rowsPerPage,
  paginationOptions,
  handleChangePage,
  handleChangeRowsPerPage,
  handleSearch,
  clearSearch,
  isSearchResult,
  handleCreate,
  totalFiltered,
  handleEdit,
  handleAssignSerialNumber,
  handleStatusChange,
  handleDeleteClick,
  isFilterOpen,
  setIsFilterOpen,
  handleSelectProduct,
  handleSelectAvailable,
  handleSelectAll,
  handleDeselectAll,
  categoryDropdown,
  handleBulkUpdate,
  isQuickEdit,
  handleQuickEdit,
  isBrandModelDropdownLoading,
  brandModelDropdown,
  handleBrandModelSearch,
  attributeDropdown,
  handleAttributeSearch,
  isAttributeLoading,
  currencyDropdown,
  handleSubmitQuickEdit,
  lang,
  tableFilter,
  resetFilter,
}) {
  const classes = useStyle();

  return (
    <>
      <Formik
        initialValues={{
          search: tableFilter.search,
          status: tableFilter.status,
          branch: tableFilter.branch,
          brandModel: tableFilter.brandModelList,
          attributes: tableFilter.attributeList,
          category: tableFilter.categoryList,
        }}
        enableReinitialize
        onSubmit={handleSearch}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <Box p={2} mb={2} className={classes.searchContainer}>
              <Input
                hiddenLabel
                variant="filled"
                size="small"
                placeholder={getLang(lang, "placeholder.ENTER_PRODUCT_NAME")}
                margin="none"
                InputProps={{
                  disableUnderline: true,
                  margin: "none",
                  style: {
                    borderRadius: "4px",
                    backgroundColor: "#eceff0",
                  },
                  classes: { input: classes.inputRootProduct },
                }}
                {...formik.getFieldProps("search")}
              />
              <Button
                variant="outlined"
                size="small"
                disableElevation
                onClick={() => setIsFilterOpen(!isFilterOpen)}
                startIcon={<img src={FilterSvg} alt="filter svg" />}
              >
                {getLang(lang, "label.FILTER")}
              </Button>
              <Button
                variant="text"
                disableElevation
                color="primary"
                size="small"
                onClick={clearSearch}
              >
                {getLang(lang, "label.CLEAR")}
              </Button>
              <Button
                disabled={isFetching}
                variant="contained"
                disableElevation
                color="primary"
                size="small"
                type="submit"
              >
                {getLang(lang, "label.SEARCH")}
              </Button>
            </Box>
            {isFilterOpen && (
              <Box className={classes.filterMainContainer}>
                <Box
                  className={classes.filterFieldContainer}
                  style={{ marginBottom: "12px" }}
                >
                  <CategorySearch
                    searchable={true}
                    value={formik.values.category}
                    placeholder={getLang(lang, "placeholder.CATEGORY")}
                    searchPlaceholder={getLang(
                      lang,
                      "placeholder.SEARCH_CATEGORY"
                    )}
                    isClearable={false}
                    emptyMessage={getLang(
                      lang,
                      "message.info.NO_CATEGORY_FOUND"
                    )}
                    dropdownItem={categoryDropdown}
                    isFetching={false}
                    isEmpty={formik.values.category?.length === 0}
                    selectedCategory={formik.values.category}
                    style={{
                      padding: "0.25em 0.75em",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderRadius: 4,
                      width: "100%",
                      overflow: "hidden",
                    }}
                    handleSelectAll={(selectAll) => {
                      if (selectAll) {
                        formik.setFieldValue("category", categoryDropdown);
                      } else {
                        formik.setFieldValue("category", []);
                      }
                    }}
                    handleChange={(value) => {
                      let temp = [...formik.values.category];

                      if (temp.length === 0) {
                        temp.push(value);
                      } else {
                        const isCategoryExist = temp.find(
                          (category) => category.uuid === value.uuid
                        );

                        if (isCategoryExist === undefined) {
                          temp.push(value);
                        } else {
                          let filter = temp.filter(
                            (category) => category.uuid !== value.uuid
                          );

                          temp = [...filter];
                        }
                      }
                      formik.setFieldValue("category", [...temp]);
                    }}
                    handleChipDelete={(category) => {
                      let temp = [...formik.values.category];
                      if (temp.length > 0) {
                        const filteredCategory = temp.filter(
                          (item) => item.uuid !== category.uuid
                        );
                        temp = [...filteredCategory];
                      }
                      formik.setFieldValue("category", temp);
                    }}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    disabled={false}
                  />
                  <SearchableSelect
                    value={formik.values.status}
                    handleChange={(value) => {
                      formik.setFieldValue("status", value);
                    }}
                    placeholder={getLang(lang, "placeholder.STATUS")}
                    disabled={formik.isSubmitting}
                    searchable={false}
                    style={{ height: "34px" }}
                    panelStyle={{ width: "249px" }}
                    PaperProps={{
                      classes: { root: classes.paperRoot },
                    }}
                    dropdownItem={[
                      getLang(lang, "label.PUBLISHED"),
                      getLang(lang, "label.UNPUBLISHED"),
                    ]}
                    emptyMessage={getLang(lang, "paragraph.STATUS_NOT_FOUND")}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    isEmpty={formik.values.status === ""}
                  />
                  {/* <BranchSelect
                      placeholder={getLang(lang, "placeholder.BRANCH")}
                      value={formik.values.branch}
                      handleChange={(value) => formik.setFieldValue("branch", value)}
                      styles={{
                        padding: "6px 10px"
                      }}
                    /> */}
                  <BranchSearch
                    placeholder={getLang(lang, "placeholder.BRANCH")}
                    value={formik.values.branch}
                    handleChange={(value) =>
                      formik.setFieldValue("branch", value)
                    }
                    isEmpty={formik.values.branch?.length === 0}
                    styles={{
                      padding: "0.25em 0.75em",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderRadius: 4,
                      width: "100%",
                      overflow: "hidden",
                    }}
                    handleChipDelete={(branch) => {
                      let temp = [...formik.values.branch];
                      if (temp.length > 0) {
                        const filteredBranchs = temp.filter(
                          (item) => item.node_id !== branch.node_id
                        );
                        temp = [...filteredBranchs];
                      }
                      formik.setFieldValue("branch", temp);
                    }}
                  />
                  <BrandModelSelect
                    placeholder={getLang(lang, "placeholder.BRAND_MODEL")}
                    disabled={formik.isSubmitting}
                    handleChange={(value) => {
                      formik.setFieldValue("brandModel", value);
                    }}
                    value={formik.values.brandModel}
                    styles={{
                      padding: "6px 10px",
                    }}
                    dropdownItem={brandModelDropdown}
                    isLoading={isBrandModelDropdownLoading}
                    panelLabel={getLang(lang, "label.BRAND_MODEL")}
                    searchPlaceholder={getLang(
                      lang,
                      "placeholder.SEARCH_BRAND_MODEL"
                    )}
                    searchable={true}
                    handleSearch={handleBrandModelSearch}
                  />
                  <AttributeMultiSelect
                    placeholder={getLang(lang, "placeholder.ATTRIBUTE")}
                    disabled={formik.isSubmitting}
                    handleChange={(value) => {
                      formik.setFieldValue("attributes", value);
                    }}
                    value={formik.values.attributes}
                    styles={{
                      padding: "6px 10px",
                    }}
                    dropdownItem={attributeDropdown}
                    isLoading={isAttributeLoading}
                    panelLabel={getLang(lang, "label.ATTRIBUTES")}
                    searchPlaceholder={getLang(
                      lang,
                      "placeholder.SEARCH_ATTRIBUTE"
                    )}
                    searchable={true}
                    handleSearch={handleAttributeSearch}
                  />
                </Box>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    variant="text"
                    disableElevation
                    color="secondary"
                    size="small"
                    onClick={resetFilter}
                    style={{
                      marginRight: "8px",
                    }}
                  >
                    {getLang(lang, "label.CLEAR")}
                  </Button>
                  <Button
                    disabled={isFetching}
                    variant="contained"
                    disableElevation
                    color="secondary"
                    size="small"
                    type="submit"
                  >
                    {getLang(lang, "label.APPLY_FILTER")}
                  </Button>
                </Box>
              </Box>
            )}
          </form>
        )}
      </Formik>

      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingBottom: "12px",
        }}
      >
        <Box style={{ display: "flex" }}>
          <ActionSelect
            label="Show Column"
            dropdownItem={[
              getLang(lang, "label.IMAGE"),
              getLang(lang, "label.CATEGORY"),
              getLang(lang, "label.BRAND_MODEL"),
              getLang(lang, "label.ATTRIBUTES"),
              getLang(lang, "label.BRANCH"),
              getLang(lang, "label.SCAN_LIMIT"),
            ]}
            disabled={false}
            isFetching={false}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            style={{
              width: "150px",
              borderRadius: "8px",
              backgroundColor: "#ffffff",
              border: "1px solid #D0D5DD",
              marginRight: "16px",
              display: "none",
            }}
          />
          <Box style={{ display: "flex", alignItems: "center" }}>
            {selectedCount !== 0 ? (
              isSelectAll && selectedCount === totalFiltered ? (
                <Typography variant="body2" style={{ color: "#FDB022" }}>
                  {getLang(lang, "paragraph.SELECTED_ALL_PRODUCTS", {
                    count: selectedCount,
                  })}
                </Typography>
              ) : (
                <Typography variant="body2" color="primary">
                  {selectedCount > 1
                    ? getLang(lang, "paragraph.SELECTED_PRODUCTS_COUNT", {
                        count: selectedCount,
                      })
                    : getLang(lang, "paragraph.SELECTED_PRODUCT_COUNT", {
                        count: selectedCount,
                      })}
                </Typography>
              )
            ) : (
              ""
            )}
          </Box>
        </Box>
        <ActionSelect
          label={getLang(lang, "label.ACTION")}
          dropdownItem={[
            getLang(lang, "label.PUBLISH_SELECTED"),
            getLang(lang, "label.UNPUBLISH_SELECTED"),
            getLang(lang, "label.BULK_EDIT"),
          ]} //"Bulk Edit"
          handleChange={(value) => handleBulkUpdate(value)}
          disabled={isFetching || !isSelected}
          isFetching={false}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          style={{
            width: "100px",
            borderRadius: "8px",
            backgroundColor: "#ffffff",
            border: "1px solid #D0D5DD",
          }}
        />
      </Box>
      <Box>
        <ProductListTable
          hasProductEditAccessRight={hasProductEditAccessRight}
          hasProductAssignAccessRight={hasProductAssignAccessRight}
          hasProductAddAccessRight={hasProductAddAccessRight}
          hasProductDeleteAccessRight={hasProductDeleteAccessRight}
          rows={rows}
          isFetching={isFetching}
          isSelectAll={isSelectAll}
          selectedIds={selectedIds}
          isError={isError}
          handleReload={handleReload}
          page={page}
          rowsPerPage={rowsPerPage}
          paginationOptions={paginationOptions}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          isSearchResult={isSearchResult}
          handleCreate={handleCreate}
          totalFiltered={totalFiltered}
          handleEdit={handleEdit}
          handleAssignSerialNumber={handleAssignSerialNumber}
          handleStatusChange={handleStatusChange}
          handleDeleteClick={handleDeleteClick}
          handleSelectProduct={handleSelectProduct}
          handleSelectAvailable={handleSelectAvailable}
          handleSelectAll={handleSelectAll}
          handleDeselectAll={handleDeselectAll}
          handleQuickEdit={handleQuickEdit}
          isQuickEdit={isQuickEdit}
          categoryDropdown={categoryDropdown}
          brandModelDropdown={brandModelDropdown}
          attributeDropdown={attributeDropdown}
          currencyDropdown={currencyDropdown}
          handleSubmitQuickEdit={handleSubmitQuickEdit}
          lang={lang}
          handleBrandModelSearch={handleBrandModelSearch}
          handleAttributeSearch={handleAttributeSearch}
        />
      </Box>
    </>
  );
}
