import { createAsyncThunk } from "@reduxjs/toolkit";
import reportingApi from "app/api/reporting";

// (1) branch -- start
export const getFilterBranchesData = createAsyncThunk(
    "reporting/getBranchesData",
    async (payload, thunkApi) => {
        return reportingApi.getFilterBranchesData(payload)
            .then((response) => response)
            .catch((error) => thunkApi.rejectWithValue(error));
    }
);
// (1) branch -- end

// (2) country city -- start
export const getFilterCountryCityData = createAsyncThunk(
    "reporting/getFilterCountryCityData",
    async (payload, thunkApi) => {
        return reportingApi.getFilterCountryCityData(payload)
            .then((response) => response)
            .catch((error) => thunkApi.rejectWithValue(error));
    }
);
// (2) country city -- end