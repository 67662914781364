import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useRouteMatch } from "react-router-dom"
import { selectLoading } from 'modules/notification'
import LoadingComponent from "components/loading"
import { getOrderDetail } from "modules/stockflow/redux"
import { STOCKFLOW_ORDER_VIEW, STOCKFLOW_DEALER_VIEW } from "lib/constants/accessRights"
import { ROOT } from "modules/dashboard"
import OrderDetailComponent from "./orderDetails.component"
import { getLang } from "app/feature/constants"

function OrderDetailsContainer() {
  const history = useHistory()
  const dispatch = useDispatch()
  const match = useRouteMatch()

  const id = match.params.uuid
  const hasStockflowViewAccessRight = useSelector(state => state.profile.accessRights.includes(STOCKFLOW_ORDER_VIEW))
  const hasStockflowDealerViewAccessRight = useSelector(state => state.profile.accessRights.includes(STOCKFLOW_DEALER_VIEW))
  const lang = useSelector(state => state.constant.languages)
  const language = useSelector(state => state.profile.language)
  const order = useSelector(state => state.stockflowOrder.order)
  const isError = useSelector(state => state.stockflowOrder.isLoadingOrderError)
  const isFetching = useSelector(state => selectLoading(state, getOrderDetail.typePrefix))

  useEffect(() => {
    if (!hasStockflowViewAccessRight) {
      history.push('/admin/dashboard')
    }
  }, [history, hasStockflowViewAccessRight])

  useEffect(() => {
    if (id) {
      dispatch(getOrderDetail(id))
    }
    else {
      history.push(ROOT)
    }
  }, [history, id, dispatch])

  if (!hasStockflowViewAccessRight) return <LoadingComponent />

  let tabLabels = []
  if (hasStockflowViewAccessRight) {
    tabLabels.push(getLang(lang, "label.INVENTORY"))
  }

  const handleReload = () => {
    dispatch(getOrderDetail(id))
  }

  return (
    <OrderDetailComponent
      hasStockflowDealerViewAccessRight={hasStockflowDealerViewAccessRight}
      order={order}
      language={language}
      isFetching={isFetching}
      isError={isError}
      handleReload={handleReload}
      lang={lang}
    />
  )
}

export default OrderDetailsContainer
