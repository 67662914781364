import { createAsyncThunk } from "@reduxjs/toolkit";
import dashboardApi from "app/api/dashboard";
import { DASHBOARD } from "modules/dashboard/constants";

export const fetchScanMap = createAsyncThunk(
  `${DASHBOARD}/fetchScanMap`,
  async (payload, thunkApi) => {
    return dashboardApi
      .getScanMap(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const fetchClusterScanMap = createAsyncThunk(
  `${DASHBOARD}/fetchClusterScanMap`,
  async (payload, thunkApi) => {
    return dashboardApi
      .getClusterScanMap(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const fetchClusterScanProductListing = createAsyncThunk(
  `${DASHBOARD}/fetchClusterScanProductListing`,
  async (payload, thunkApi) => {
    return dashboardApi
      .getClusterScanProductListing(payload)
      .then((response) => response)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const fetchClusterScanRecordListing = createAsyncThunk(
  `${DASHBOARD}/fetchClusterScanRecordListing`,
  async (payload, thunkApi) => {
    return dashboardApi
      .getClusterScanRecordListing(payload)
      .then((response) => response)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const fetchClusterScanConsumerDropdown = createAsyncThunk(
  `${DASHBOARD}/fetchClusterScanConsumerDropdown`,
  async (payload, thunkApi) => {
    return dashboardApi
      .getClusterScanConsumerDropdown(payload)
      .then((response) => response)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const fetchClusterConsumerScannedSummary = createAsyncThunk(
  `${DASHBOARD}/fetchClusterConsumerScannedSummary`,
  async (payload, thunkApi) => {
    return dashboardApi
      .getClusterConsumerScannedSummary(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const fetchClusterUniqueConsumerScannedSummary = createAsyncThunk(
  `${DASHBOARD}/fetchClusterUniqueConsumerScannedSummary`,
  async (payload, thunkApi) => {
    return dashboardApi
      .getClusterUniqueConsumerScannedSummary(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const fetchClusterFirstScanSummary = createAsyncThunk(
  `${DASHBOARD}/fetchClusterFirstScanSummary`,
  async (payload, thunkApi) => {
    return dashboardApi
      .getClusterFirstScanSummary(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const fetchClusterSuspendSnSummary = createAsyncThunk(
  `${DASHBOARD}/fetchClusterSuspendSnSummary`,
  async (payload, thunkApi) => {
    return dashboardApi
      .getClusterSuspendSnSummary(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const fetchClusterCounterfeitReportSummary = createAsyncThunk(
  `${DASHBOARD}/fetchClusterCounterfeitReportSummary`,
  async (payload, thunkApi) => {
    return dashboardApi
      .getClusterCounterfeitReportSummary(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const fetchScanDetail = createAsyncThunk(
  `${DASHBOARD}/fetchScanDetail`,
  async (payload, thunkApi) => {
    return dashboardApi
      .getScanDetail(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const getConsumerScannedSummary = createAsyncThunk(
  `${DASHBOARD}/getConsumerScannedSummary`,
  async (payload, thunkApi) => {
    return dashboardApi
      .getConsumerScannedSummary(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const getSuspendSnSummary = createAsyncThunk(
  `${DASHBOARD}/getSuspendSnSummary`,
  async (payload, thunkApi) => {
    return dashboardApi
      .getSuspendSnSummary(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const getCounterfeitReportSummary = createAsyncThunk(
  `${DASHBOARD}/getCounterfeitReportSummary`,
  async (payload, thunkApi) => {
    return dashboardApi
      .getCounterfeitReportSummary(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const getFirstScanSummary = createAsyncThunk(
  `${DASHBOARD}/getFirstScanSummary`,
  async (payload, thunkApi) => {
    return dashboardApi
      .getFirstScanSummary(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const getUniqueConsumerScannedSummary = createAsyncThunk(
  `${DASHBOARD}/getUniqueConsumerScannedSummary`,
  async (payload, thunkApi) => {
    return dashboardApi
      .getUniqueConsumerScannedSummary(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const getConsumerRegisteredSummary = createAsyncThunk(
  `${DASHBOARD}/getConsumerRegisteredSummary`,
  async (payload, thunkApi) => {
    return dashboardApi
      .getConsumerRegisteredSummary(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const getScanAgainstSuspendedData = createAsyncThunk(
  `${DASHBOARD}/getScanAgainstSuspendedData`,
  async (payload, thunkApi) => {
    return dashboardApi
      .getScanAgainstSuspendedData(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const getTopScanCities = createAsyncThunk(
  `${DASHBOARD}/getTopScanCities`,
  async (payload, thunkApi) => {
    return dashboardApi
      .getTopScanCities(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const getTopScanProduct = createAsyncThunk(
  `${DASHBOARD}/getTopScanProduct`,
  async (payload, thunkApi) => {
    return dashboardApi
      .getTopScanProduct(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const getSerialNumberFirstScanList = createAsyncThunk(
  `${DASHBOARD}/getSerialNumberFirstScanList`,
  async (payload, thunkApi) => {
    return dashboardApi
      .getSerialNumberFirstScanList(payload)
      .then(response => response.data)
      .catch(error => thunkApi.rejectWithValue(error));
  }
)

export const getNewConsumerRegisteredList = createAsyncThunk(
  `${DASHBOARD}/getNewConsumerRegisteredList`,
  async (payload, thunkApi) => {
    return dashboardApi
      .getNewConsumerRegisteredList(payload)
      .then(response => response.data)
      .catch(error => thunkApi.rejectWithValue(error));
  }
)

export const getUniqueConsumerScannedList = createAsyncThunk(
  `${DASHBOARD}/getUniqueConsumerScannedList`,
  async (payload, thunkApi) => {
    return dashboardApi
      .getUniqueConsumerScannedList(payload)
      .then(response => response.data)
      .catch(error => thunkApi.rejectWithValue(error));
  }
)

