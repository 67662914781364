import React from "react";
import { 
  makeStyles, 
  Box, 
  Button,
  Typography,
} from '@material-ui/core';
import { Formik } from "formik";
import Input from "components/input/inputTextField";
import IncentiveProgramTable from '../../table/incentiveProgramTable';
import ActionSelect from "components/select/actionSelect";
import { getLang } from "app/feature/constants";
import ProductSelect from "../../../../../components/select/productSelect";

const useStyle = makeStyles(theme => ({
  searchContainer: {
    backgroundColor: theme.palette.background.paper,
    display: "grid",
    gridTemplateColumns: "3fr 3fr 1fr 1fr",
    columnGap: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1)
    }
  },
  inputRoot: {
    paddingTop: "8px !important",
    paddingBottom: "7px !important",
    paddingLeft: "12px !important",
    paddingRight: "12px !important",
    fontSize: "0.875rem",
    fontWeight: 500
  },
  paperRoot: {
    width: "217px"
  }
}));

export default function IncentiveProgramPanelComponent({
  hasStockflowAddAccessRight,
  hasStockflowEditAccessRight,
  handleSearch,
  isFetching,
  handleSelectAllProgram,
  isError,
  isSelected,
  isSelectAll,
  selectedUUIDs,
  selectedCount,
  rows,
  tierLimit,
  isSearchResult,
  rowsPerPage,
  paginationOptions,
  page,
  totalFiltered,
  handleChangePage,
  handleChangeRowPerPage,
  handleEdit,
  handleChangeStatus,
  handleDelete,
  handleAddIncentiveProgram,
  handleSelectProgram,
  handleSelectAll,
  handleDeselectAll,
  handleSelectAvailable,
  handleBulkUpdateProgramStatus,
  productDropdown,
  isFetchingProduct,
  lang
}) {
  const classes = useStyle();

  return (
    <>
      <Formik
        initialValues={{
          programName: "",
          product: [],
        }}
        onSubmit={handleSearch}
        onReset={handleSearch}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <Box p={2} mb={2} className={classes.searchContainer}>
              <Input
                hiddenLabel
                variant="filled"
                size="small"
                placeholder={getLang(lang, 'placeholder.PROGRAM_NAME')}
                margin="none"
                InputProps={{
                  disableUnderline: true,
                  margin: "none",
                  style: {
                    borderRadius: "4px",
                    backgroundColor: "#eceff0"
                  },
                  classes: { input: classes.inputRoot }
                }}
                {...formik.getFieldProps("programName")}
              />
              <ProductSelect
                searchable={true}
                value={formik.values.product}
                placeholder={getLang(lang, 'placeholder.SELECT_A_PRODUCT')}
                searchPlaceholder={getLang(lang, 'placeholder.SEARCH_A_PRODUCT_NAME')}
                isClearable={true}
                emptyMessage={getLang(lang, 'message.info.NO_PRODUCT_FOUND')}
                dropdownItem={productDropdown}
                isFetching={isFetchingProduct}
                handleChange={(value) => {
                  let temp = [...formik.values.product];
                  if (temp.length === 0) {
                    temp.push(value);
                  }
                  else {
                    const isProductExist = temp.find((product) => product.id === value.id);

                    if (isProductExist === undefined) {
                      temp.push(value);
                    }
                    else {
                      let filter = temp.filter(product => product.id !== value.id);
                      temp = [...filter];
                    }
                  }

                  formik.setFieldValue('product', temp);
                }}
                handleClear={() => {
                  formik.setFieldValue('product', []);
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                isEmpty={productDropdown.length === 0}
                disabled={false}
              />
              <Button 
                variant='text'
                disableElevation
                color="primary"
                size='small'
                onClick={formik.handleReset}
              >
                {getLang(lang, 'label.CLEAR')}
              </Button>
              <Button
                disabled={isFetching}
                variant="contained"
                disableElevation
                color="primary"
                size="small"
                type="submit"
              >
                {getLang(lang, 'label.SEARCH')}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
      <Box style={{ display: "flex", justifyContent: "space-between", paddingBottom: "12px" }}>
        <Box style={{ display: "flex", alignItems: "center" }}>
          {selectedCount !== 0 ? (
            (isSelectAll && selectedCount === totalFiltered) ? (
              <Typography variant='body2' style={{color:"#FDB022"}}>{getLang(lang, 'label.SELECTED')} {selectedCount} {selectedCount > 1 ? getLang(lang, 'label.INCENTIVE_PROGRAMS') : getLang(lang, 'label.INCENTIVE_PROGRAM')} [{getLang(lang, 'label.ALL')}]</Typography>
            ) : (
              <Typography variant='body2' color='primary'>{getLang(lang, 'label.SELECTED')} {selectedCount} {selectedCount > 1 ? getLang(lang, 'label.INCENTIVE_PROGRAMS'): getLang(lang, 'label.INCENTIVE_PROGRAM')}</Typography>
            )
          ) : (
            ""
          )}
        </Box>
        <Box style={{ display: "flex" }}>
          <ActionSelect
            label={getLang(lang, 'label.ACTION')}
            dropdownItem={[getLang(lang, 'label.ACTIVATE_SELECTED'), getLang(lang, 'label.DEACTIVATE_SELECTED')]}
            handleChange={(value) => handleBulkUpdateProgramStatus(value)}
            disabled={isFetching || !isSelected}
            isFetching={isFetching}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            style={{
              width: "100px",
              borderRadius: "8px",
              backgroundColor: "#ffffff",
              border: "1px solid #D0D5DD"
            }}
          />
        </Box>
      </Box>
      <Box>
        <IncentiveProgramTable
          hasStockflowAddAccessRight={hasStockflowAddAccessRight}
          hasStockflowEditAccessRight={hasStockflowEditAccessRight}
          handleSelectAllProgram={handleSelectAllProgram}
          isError={isError}
          isFetching={isFetching}
          isSelectAll={isSelectAll}
          selectedUUIDs={selectedUUIDs}
          rows={rows}
          tierLimit={tierLimit}
          isSearchResult={isSearchResult}
          rowsPerPage={rowsPerPage}
          paginationOptions={paginationOptions}
          page={page}
          totalFiltered={totalFiltered}
          handleChangePage={handleChangePage}
          handleChangeRowPerPage={handleChangeRowPerPage}
          handleEdit={handleEdit}
          handleChangeStatus={handleChangeStatus}
          handleDelete={handleDelete}
          handleAddIncentiveProgram={handleAddIncentiveProgram}
          handleSelectProgram={handleSelectProgram}
          handleSelectAll={handleSelectAll}
          handleDeselectAll={handleDeselectAll}
          handleSelectAvailable={handleSelectAvailable}
        />
      </Box>
    </>
  )
}
