import React from "react";
import ProductSNListTableComponent from "./productSNListTable.component";

// used in stocktake, transfer and receive transfer
export default function ProductSNListTableContainer({
  rows,
  isFetching,
  isError,
  handleReload,
  handleChangePage,
  handleChangeRowsPerPage,
  totalFiltered,
  paginationOptions,
  tableFilter,
  changeOrder,
  lang,
  boxUuid,
}) {
  return (
    <ProductSNListTableComponent
      rows={rows}
      isFetching={isFetching}
      isError={isError}
      handleReload={handleReload}
      handleChangePage={handleChangePage}
      handleChangeRowPerPage={handleChangeRowsPerPage}
      totalFiltered={totalFiltered}
      paginationOptions={paginationOptions}
      tableFilter={tableFilter}
      changeOrder={changeOrder}
      lang={lang}
      boxUuid={boxUuid}
    />
  );
}
