import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  STOCKFLOW_DEALER_VIEW,
  STOCKFLOW_DEALER_ADD,
  STOCKFLOW_DEALER_EDIT
} from "lib/constants/accessRights"
import { selectLoading } from 'modules/notification';
import MyDealerListPanelComponent from './myDealerListPanel.component';
import {
  getAllDealerListing,
  updateDealerStatus,
  resetDealerList,
  getDealerIncentivePoint,
} from "modules/stockflow/redux";
import { MY_DEALER_LIST_PAGE } from "modules/stockflow/config/stockflow.route";
import { generatePaginationOptions } from "lib/helper";
import { SEARCH_NAME_COLUMN, SEARCH_UPLINE_NAME_COLUMN } from "modules/stockflow/constants";
import InviteDealerDialogContainer from "../../dialog/inviteDealerDialog";
import EditIncentivePointDialogContainer from "../../dialog/editIncentivePointDialog/editIncentivePointDialog.container";
import { bulkUpdateDealerStatus, resendVerificationEmail } from "modules/stockflow/redux/action/stockflowDealer.action";
import { changeBulkSelectedAvailableDealer, changeBulkSelectedAllDealer, changeSelectedDealer} from "modules/stockflow/redux/slice/stockflowDealer.slice";
import { hideBackdrop, showBackdrop } from "modules/backdrop";
import { unwrapResult } from "@reduxjs/toolkit";
import { getLang } from "app/feature/constants";

function MyDealerListPanelContainer({
  isInviteDialogOpen,
  handleVisibleInviteDialog,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const lang = useSelector(state => state.constant.languages);
  const hasStockflowDealerViewAccessRight = useSelector(state => state.profile.accessRights.includes(STOCKFLOW_DEALER_VIEW));
  const hasStockflowDealerAddAccessRight = useSelector(state => state.profile.accessRights.includes(STOCKFLOW_DEALER_ADD));
  const hasStockflowDealerEditAccessRight = useSelector(state => state.profile.accessRights.includes(STOCKFLOW_DEALER_EDIT));
  const [tableFilter, setTableFilter] = useState({
    page: 0,
    rowsPerPage: 25,
    search: "",
    searchBy: "name",
    order: "asc",
  })
  const [isSearchResult, setIsSearchResult] = useState(false);
  const [paginationOptions, setPaginationOptions] = useState([]);
  const isFetching = useSelector(state => selectLoading(state, getAllDealerListing.typePrefix));
  const isError = useSelector(state => state.stockflowDealer.dealerList.isError);
  const data = useSelector(state => state.stockflowDealer.dealerList.data);
  const totalFiltered = useSelector(state => state.stockflowDealer.dealerList.totalFiltered);
  
  const [isEditIncentivePointDialogOpen, setIsEditIncentivePointDialogOpen] = useState(false);
  const [editIncentivePointId, setEditIncentivePointId] = useState("");
  const selectedUUIDs = useSelector(state => state.stockflowDealer.dealerList.selectedUUIDs);
  const [isSelectAll, setIsSelectAll] = useState(false);
  
  useEffect(() => {
    for (let key in sessionStorage) {
      if (key.startsWith("dealer")) {
        sessionStorage.removeItem(key);
      }
    }
  }, [])

  useEffect(() => {
    dispatch(getAllDealerListing({
      length: tableFilter.rowsPerPage,
      start: tableFilter.rowsPerPage * tableFilter.page,
      order: tableFilter.order,
      search: tableFilter.search,
      searchBy: tableFilter.searchBy,
      dealer: ""
    }));
    return () => {
      dispatch(resetDealerList());
    };
  }, [
    tableFilter,
    dispatch
  ]);

  useEffect(() => {
    setPaginationOptions(generatePaginationOptions(totalFiltered))
  }, [totalFiltered]);

  const handleViewDetail = id => history.push(`${MY_DEALER_LIST_PAGE}/${id}`);

  const handleReload = () => {
    dispatch(getAllDealerListing({
      length: tableFilter.rowsPerPage,
      start: tableFilter.rowsPerPage * tableFilter.page,
      order: tableFilter.order,
      search: tableFilter.search,
      searchBy: tableFilter.searchBy,
      dealer: ""
    }))
  }

  const handleChangePage = (event, newPage) => {
    setTableFilter((prev) => ({
      ...prev,
      page: newPage,
    }))
  }

  const handleChangeRowPerPage = (event) => {
    setTableFilter((prev) => ({
      ...prev,
      page: 0,
      rowsPerPage: event.target.value
    }))
  }

  const handleSearch = (values) => {
    setIsSearchResult(values.search !== "")
    setTableFilter({
      search: values.search,
      searchBy: !values.searchByFlag ? SEARCH_NAME_COLUMN : SEARCH_UPLINE_NAME_COLUMN,
      page: 0,
      rowsPerPage: 25
    })
  }

  const handleUpdateDealerStatus = (id, status) => {
    dispatch(updateDealerStatus({ id, status: status ? "ACTIVE" : "INACTIVE" }));
  }
  
  const handleVisibleEditIncentivePointDialog = (value) => {
    setIsEditIncentivePointDialogOpen(value);
  }

  const handleLoadIncentivePointDialog = (value) => {
    dispatch(getDealerIncentivePoint(value))
    setEditIncentivePointId(value)
    handleVisibleEditIncentivePointDialog(true)
  }
  const handleBulkUpdateDealerStatus = (value) => {

    const request = {
      status: value === getLang(lang, 'label.ACTIVATE_SELECTED') ? 1 : 2,
      isSelectAll: isSelectAll,
      selectedUUIDs: selectedUUIDs,
      filter: {
        search: tableFilter.search,
        search_by: tableFilter.searchBy
      }
    }
    dispatch(bulkUpdateDealerStatus(request))
      .then(() => {
        handleReload()
        handleDeselectAll()
      })
  }
  const handleSelectAvailable = () => {
    setIsSelectAll(false)
    dispatch(changeBulkSelectedAvailableDealer({ value: true }))
  }
  const handleDeselectAll = () => {
    setIsSelectAll(false)
    dispatch(changeBulkSelectedAllDealer({ value: false }))
  }
  const handleSelectAllDealer = () => {
    setIsSelectAll(true)
    dispatch(changeBulkSelectedAllDealer({ value: true }))
  }
  const handleSelectDealer = (index, isSelected) => {
    dispatch(changeSelectedDealer({ index: index, isSelected: isSelected }))
  }

  const handleResendEmail = async (email) => {
    dispatch(showBackdrop());
    await dispatch(resendVerificationEmail(email))
      .then(unwrapResult)
      .finally((response) => {
        dispatch(hideBackdrop());
      });
  }

  return (
    <>
      <MyDealerListPanelComponent
        hasStockflowDealerViewAccessRight={hasStockflowDealerViewAccessRight}
        hasStockflowDealerEditAccessRight={hasStockflowDealerEditAccessRight}
        hasStockflowDealerAddAccessRight={hasStockflowDealerAddAccessRight}
        rows={data}
        page={tableFilter.page}
        rowsPerPage={tableFilter.rowsPerPage}
        paginationOptions={paginationOptions}
        totalFiltered={totalFiltered}
        isFetching={isFetching}
        isError={isError}
        isSearchResult={isSearchResult}
        handleReload={handleReload}
        handleSearch={handleSearch}
        handleUpdateDealerStatus={handleUpdateDealerStatus}
        handleViewDetail={handleViewDetail}
        handleChangePage={handleChangePage}
        handleChangeRowPerPage={handleChangeRowPerPage}
        handleVisibleInviteDialog={handleVisibleInviteDialog}
        handleLoadIncentivePointDialog={handleLoadIncentivePointDialog}
        handleBulkUpdateDealerStatus={handleBulkUpdateDealerStatus}
        handleSelectAllDealer={handleSelectAllDealer}
        handleSelectAvailable={handleSelectAvailable}
        handleDeselectAll={handleDeselectAll}
        handleSelectDealer={handleSelectDealer}
        isSelected={(!isSelectAll && selectedUUIDs.length > 0) || (isSelectAll && selectedUUIDs.length < totalFiltered)}
        isSelectAll={isSelectAll}
        selectedCount={!isSelectAll ? selectedUUIDs.length : totalFiltered - selectedUUIDs.length}
        selectedUUIDs={selectedUUIDs}
        handleResendEmail={handleResendEmail}
        lang={lang}

      />
      <React.Fragment>
        <InviteDealerDialogContainer
          isOpen={isInviteDialogOpen}
          handleClose={() => handleVisibleInviteDialog(false)}
        />
      </React.Fragment>
      <React.Fragment>
        <EditIncentivePointDialogContainer 
          isOpen={isEditIncentivePointDialogOpen}
          handleClose={() => handleVisibleEditIncentivePointDialog(false)}
          id={editIncentivePointId}
        />
      </React.Fragment>
    </>
  )
}

export default MyDealerListPanelContainer