import s3Api from "app/api/s3";

export function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

async function upload(filePath, base64Image) {
  const file = dataURLtoFile(base64Image, filePath);

  const presigned = await s3Api.createPresignedUrl(filePath);
  const upload = await s3Api.upload(presigned.data, file);

  if (upload.status !== 204) {
    return { success: false };
  }

  const { url } = await s3Api.getUrl(filePath);

  return { success: true, url };
}

export default upload;
