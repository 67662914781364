/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router";
import { getLang } from "app/feature/constants";
import {
  CLAIM_LIST_VIEW,
  REIMBURSEMENT_LIST_VIEW,
  ITEM_REPLACEMENT_HANDOFF,
} from "lib/constants/accessRights";
import { ROOT } from "modules/claim";
import LoadingComponent from "components/loading";
import ClaimListPageComponent from "./claimList.page";
import qs from "query-string";

export default function ClaimListContainer() {
  const history = useHistory();
  const location = useLocation();
  const lang = useSelector((state) => state.constant.languages);
  const hasClaimViewAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(CLAIM_LIST_VIEW)
  );
  const hasReimbursementViewAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(REIMBURSEMENT_LIST_VIEW)
  );
  const hasHandoffViewAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(ITEM_REPLACEMENT_HANDOFF)
  );
  const notificationCount = useSelector(
    (state) => state.notification.notificationCount
  );

  const [activeTab, setActiveTab] = useState(0);

  const qsParams = qs.parse(location.search);
  const currentSearch = qsParams.tab || "";

  useEffect(() => {
    if (!hasClaimViewAccessRight && !hasReimbursementViewAccessRight) {
      history.push("/admin/dashboard");
    }
  }, [history, hasClaimViewAccessRight, hasReimbursementViewAccessRight]);

  useEffect(() => {
    let tempIndex = 0;
    switch (currentSearch) {
      case "claim":
        tempIndex = 0;
        break;
      case "replacement":
        tempIndex = 1;
        break;
      case "reimbursement":
        tempIndex = 2;
        break;
      default:
        tempIndex = 0;
    }
    setActiveTab(tempIndex);
  }, [currentSearch]);

  if (!hasClaimViewAccessRight && !hasReimbursementViewAccessRight)
    return <LoadingComponent />;

  const tabLabels = [];
  if (hasClaimViewAccessRight)
    tabLabels.push({
      title: getLang(lang, "label.CONSUMER_CLAIM"),
      identifier: "claim",
    });
  if (hasHandoffViewAccessRight)
    tabLabels.push({
      title: getLang(lang, "label.ITEM_REPLACEMENT_HANDOFF"),
      identifier: "handoff",
    });
  if (hasReimbursementViewAccessRight)
    tabLabels.push({
      title: getLang(lang, "label.WARRANTY_REIMBURSEMENT"),
      identifier: "reimbursement",
    });

  const handleChange = (event, index) => {
    setActiveTab(index);
    history.replace({
      pathname: ROOT,
      search: `?tab=${
        index === 0 ? "claim" : index === 1 ? "replacement" : "reimbursement"
      }`,
    });
  };

  return (
    <>
      <ClaimListPageComponent
        lang={lang}
        tabLabels={tabLabels}
        activeTab={activeTab}
        handleChange={handleChange}
        setActiveTab={(_, value) => setActiveTab(value)}
        notificationCount={notificationCount}
      />
    </>
  );
}
