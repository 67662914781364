import React, { useState, useRef, useEffect } from "react"
import StockHolderSelectComponent from "./stockHolderSelect.component"
import { selectLoading } from "modules/notification"
import StockHolderSelectPanelComponent from "./stockHolderSelectPanel.component"
import { getStockHolderDropdown } from "modules/stockflow/redux"
import { useSelector, useDispatch } from "react-redux"
import { cloneDeep } from "lodash"

function StockHolderSelectContainer({
  handleChange,
  disabled,
  value,
  styles, 
  textStyles,
  placeholder,
  type
}) {
  const isFetching = useSelector(state => selectLoading(state, getStockHolderDropdown.typePrefix))
  const stockHolderDropdown = useSelector((state) => state.stockflowInventory.stockHolderDropdown)
  const lang = useSelector((state) => state.constant.languages)
  const [open, setOpen] = useState(false)
  const [isOpenList] = useState(false)
  const anchorRef = useRef(null)
  const prevOpen = useRef(open)
  const prevOpenList = useRef(isOpenList)
  const [selectAll, setSelectAll] = useState(false)
  const [search, setSearch] = useState("")
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getStockHolderDropdown({
      search,
      type
    }))
  }, [search, type, dispatch])

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])

  useEffect(() => {
    if (prevOpenList.current === true && isOpenList === false) {
      anchorRef.current.focus()
    }

    prevOpenList.current = isOpenList
  }, [isOpenList])

  useEffect(() => {
    setSelectAll(value.length === stockHolderDropdown.length)
  }, [value, stockHolderDropdown])

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
    setSearch("")
  }

  const handleSelectAll = async () => {
    if(!selectAll){
      let selectedHolder = stockHolderDropdown.map(p => {
        return {
          id: p.id,
          type: p.type
        }
      })
      handleChange(selectedHolder)
    }else{
      handleChange([])
    }
  }

  const handleSelectHolder = (holder) => {
    let temp = cloneDeep(value)

    if(temp.findIndex(t => t.id === holder.id && t.type === holder.type) >= 0){
      temp = temp.filter( t => !(t.id === holder.id && t.type === holder.type))
    } else {
      temp.push({
        id: holder.id,
        type: holder.type
      })
    }
    handleChange(temp)
  }


  const handleSearch = (value) => {
    setSearch(value)
  }

  return (
    <>
      <StockHolderSelectComponent
        anchorRef={anchorRef}
        disabled={disabled}
        value={value}
        handleClick={handleOpen}
        styles={styles}
        textStyles={textStyles}
        placeholder={placeholder}
        lang={lang}
      />
      <StockHolderSelectPanelComponent
        selectAll={selectAll}
        value={value}
        anchorRef={anchorRef}
        open={open}
        isOpenList={isOpenList}
        handleClose={handleClose}
        isFetching={isFetching}
        stockHolderDropdown={stockHolderDropdown}
        handleSelectAll={handleSelectAll}
        handleSelectHolder={handleSelectHolder}
        handleSearch={handleSearch}
        lang={lang}
      />
    </>
  )
}

export default StockHolderSelectContainer