import { addAlert, setLoading } from "modules/notification";
import {
    fetchGiftListing,
    createGift,
    getGift,
    updateGift,
    changeStatusGift,
    fetchOrderListing,
    getOrder,
    updateOrder,
    fetchPointCutListing,
    createPointCut,
    getPointCut,
    updatePointCut,
    getGiftDropdown,
    changeStatusPointCut,
    getGiftOrderSetting,
    updateGiftOrderSetting
} from "../action";
import { getApiLang } from "app/feature/constants";

const fetchGiftListingMiddleware = ({ dispatch, getState }) => next => action => {
    next(action);

    const { type, payload } = action;

    switch (type) {
        case fetchGiftListing.pending.type:
            dispatch(setLoading({ id: fetchGiftListing.typePrefix, state: true }));
            break;
        case fetchGiftListing.fulfilled.type:
            dispatch(setLoading({ id: fetchGiftListing.typePrefix, state: false }));
            break;
        case fetchGiftListing.rejected.type:
            const lang = getState().constant.languages
            dispatch(setLoading({ id: fetchGiftListing.typePrefix, state: false }));
            dispatch(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
            break;
        default:
            break;
    }
};

const createGiftMiddleware = ({ dispatch, getState }) => next => action => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages
    const pageSetting = getState().redemptionCenter.languages

    switch (type) {
        case createGift.pending.type:
            dispatch(setLoading({ id: createGift.typePrefix, state: true }));
            break;

        case createGift.fulfilled.type:
            dispatch(fetchGiftListing(pageSetting))
            dispatch(setLoading({ id: createGift.typePrefix, state: false }));
            dispatch(addAlert({ severity: "success", message: getApiLang(lang, "success.SUCCESS_CREATE_GIFT") }));
            break;

        case createGift.rejected.type:
            dispatch(setLoading({ id: createGift.typePrefix, state: false }));
            dispatch(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
            break;

        default:
            break;
    }
};

const getGiftMiddleware = ({ dispatch, getState }) => next => action => {
    next(action);

    const { type, payload } = action;

    switch (type) {
        case getGift.pending.type:
            dispatch(setLoading({ id: getGift.typePrefix, state: true }));
            break;
        case getGift.fulfilled.type:
            dispatch(setLoading({ id: getGift.typePrefix, state: false }));
            break;
        case getGift.rejected.type:
            const lang = getState().constant.languages
            dispatch(setLoading({ id: getGift.typePrefix, state: false }));
            dispatch(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
            break;
        default:
            break;
    }
};

const updateGiftMiddleware = ({ dispatch, getState }) => next => action => {
    next(action);

    const { type, payload } = action;
    const pageSetting = getState().redemptionCenter.languages

    switch (type) {
        case updateGift.pending.type:
            dispatch(setLoading({ id: updateGift.typePrefix, state: true }));
            break;
        case updateGift.fulfilled.type:
            dispatch(fetchGiftListing(pageSetting))
            dispatch(setLoading({ id: updateGift.typePrefix, state: false }));
            break;
        case updateGift.rejected.type:
            const lang = getState().constant.languages
            dispatch(setLoading({ id: updateGift.typePrefix, state: false }));
            dispatch(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
            break;
        default:
            break;
    }
};

const changeStatusGiftMiddleware = ({ dispatch, getState }) => next => action => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages

    switch (type) {
        case changeStatusGift.pending.type:
            dispatch(setLoading({ id: changeStatusGift.typePrefix, state: true }));
            break;
        case changeStatusGift.fulfilled.type:
            dispatch(setLoading({ id: changeStatusGift.typePrefix, state: false }));
            dispatch(addAlert({ severity: "success", message: getApiLang(lang, "success.SUCCESS_CHANGE_STATUS_GIFT") }));
            break;
        case changeStatusGift.rejected.type:
            dispatch(setLoading({ id: changeStatusGift.typePrefix, state: false }));
            dispatch(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
            break;
        default:
            break;
    }
};

const fetchOrderListingMiddleware = ({ dispatch, getState }) => next => action => {
    next(action);

    const { type, payload } = action;

    switch (type) {
        case fetchOrderListing.pending.type:
            dispatch(setLoading({ id: fetchOrderListing.typePrefix, state: true }));
            break;
        case fetchOrderListing.fulfilled.type:
            dispatch(setLoading({ id: fetchOrderListing.typePrefix, state: false }));
            break;
        case fetchOrderListing.rejected.type:
            const lang = getState().constant.languages
            dispatch(setLoading({ id: fetchOrderListing.typePrefix, state: false }));
            dispatch(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
            break;
        default:
            break;
    }
};

const getOrderMiddleware = ({ dispatch, getState }) => next => action => {
    next(action);

    const { type, payload } = action;

    switch (type) {
        case getOrder.pending.type:
            dispatch(setLoading({ id: getOrder.typePrefix, state: true }));
            break;
        case getOrder.fulfilled.type:
            dispatch(setLoading({ id: getOrder.typePrefix, state: false }));
            break;
        case getOrder.rejected.type:
            const lang = getState().constant.languages
            dispatch(setLoading({ id: getOrder.typePrefix, state: false }));
            dispatch(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
            break;
        default:
            break;
    }
};

const updateOrderMiddleware = ({ dispatch, getState }) => next => action => {
    next(action);

    const { type, payload } = action;
    const pageSetting = getState().redemptionCenter.languages

    switch (type) {
        case updateOrder.pending.type:
            dispatch(setLoading({ id: updateOrder.typePrefix, state: true }));
            break;
        case updateOrder.fulfilled.type:
            dispatch(fetchOrderListing(pageSetting))
            dispatch(setLoading({ id: updateOrder.typePrefix, state: false }));
            break;
        case updateOrder.rejected.type:
            const lang = getState().constant.languages
            dispatch(setLoading({ id: updateOrder.typePrefix, state: false }));
            dispatch(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
            break;
        default:
            break;
    }
};

const fetchPointCutListingMiddleware = ({ dispatch, getState }) => next => action => {
    next(action);

    const { type, payload } = action;

    switch (type) {
        case fetchPointCutListing.pending.type:
            dispatch(setLoading({ id: fetchPointCutListing.typePrefix, state: true }));
            break;
        case fetchPointCutListing.fulfilled.type:
            dispatch(setLoading({ id: fetchPointCutListing.typePrefix, state: false }));
            break;
        case fetchPointCutListing.rejected.type:
            const lang = getState().constant.languages
            dispatch(setLoading({ id: fetchPointCutListing.typePrefix, state: false }));
            dispatch(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
            break;
        default:
            break;
    }
};

const createPointCutMiddleware = ({ dispatch, getState }) => next => action => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages
    const pageSetting = getState().redemptionCenter.languages

    switch (type) {
        case createPointCut.pending.type:
            dispatch(setLoading({ id: createPointCut.typePrefix, state: true }));
            break;

        case createPointCut.fulfilled.type:
            dispatch(fetchPointCutListing(pageSetting))
            dispatch(setLoading({ id: createPointCut.typePrefix, state: false }));
            dispatch(addAlert({ severity: "success", message: getApiLang(lang, "success.SUCCESS_CREATE_POINT_CUT") }));
            break;

        case createPointCut.rejected.type:
            dispatch(setLoading({ id: createPointCut.typePrefix, state: false }));
            dispatch(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
            break;

        default:
            break;
    }
};

const getPointCutMiddleware = ({ dispatch, getState }) => next => action => {
    next(action);

    const { type, payload } = action;

    switch (type) {
        case getPointCut.pending.type:
            dispatch(setLoading({ id: getPointCut.typePrefix, state: true }));
            break;
        case getPointCut.fulfilled.type:
            dispatch(setLoading({ id: getPointCut.typePrefix, state: false }));
            break;
        case getPointCut.rejected.type:
            const lang = getState().constant.languages
            dispatch(setLoading({ id: getPointCut.typePrefix, state: false }));
            dispatch(addAlert({ severity: "error", message:  getApiLang(lang, payload.code) }));
            break;
        default:
            break;
    }
};

const updatePointCutMiddleware = ({ dispatch, getState }) => next => action => {
    next(action);

    const { type, payload } = action;
    const pageSetting = getState().redemptionCenter.languages

    switch (type) {
        case updatePointCut.pending.type:
            dispatch(setLoading({ id: updatePointCut.typePrefix, state: true }));
            break;
        case updatePointCut.fulfilled.type:
            dispatch(fetchPointCutListing(pageSetting))
            dispatch(setLoading({ id: updatePointCut.typePrefix, state: false }));
            break;
        case updatePointCut.rejected.type:
            const lang = getState().constant.languages
            dispatch(setLoading({ id: updatePointCut.typePrefix, state: false }));
            dispatch(addAlert({ severity: "error", message:  getApiLang(lang, payload.code) }));
            break;
        default:
            break;
    }
};

const getGiftDropdownMiddleware = ({ dispatch, getState }) => next => action => {
    next(action);

    const { payload, type } = action;

    switch (type) {
        case getGiftDropdown.pending.type:
            dispatch(setLoading({ id: getGiftDropdown.typePrefix, state: true }));
            break;
        case getGiftDropdown.fulfilled.type:
            dispatch(setLoading({ id: getGiftDropdown.typePrefix, state: false }));
            break;
        case getGiftDropdown.rejected.type:
            const lang = getState().constant.languages
            dispatch(setLoading({ id: getGiftDropdown.typePrefix, state: false }));
            dispatch(addAlert({ severity: "error", message:  getApiLang(lang, payload.code) }));
            break;
        default:
            break;
    }
};

const changeStatusPointCutMiddleware = ({ dispatch, getState }) => next => action => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages

    switch (type) {
        case changeStatusPointCut.pending.type:
            dispatch(setLoading({ id: changeStatusPointCut.typePrefix, state: true }));
            break;
        case changeStatusPointCut.fulfilled.type:
            dispatch(setLoading({ id: changeStatusPointCut.typePrefix, state: false }));
            dispatch(addAlert({ severity: "success", message:  getApiLang(lang, "success.SUCCESS_CHANGE_STATUS_POINT_CUT") }));
            break;
        case changeStatusPointCut.rejected.type:
            dispatch(setLoading({ id: changeStatusPointCut.typePrefix, state: false }));
            dispatch(addAlert({ severity: "error", message:  getApiLang(lang, payload.code) }));
            break;
        default:
            break;
    }
};

const getGiftOrderSettingMiddleware = ({ dispatch, getState }) => next => action => {
    next(action);

    const { payload, type } = action;

    switch (type) {
        case getGiftOrderSetting.pending.type:
            dispatch(setLoading({ id: getGiftOrderSetting.typePrefix, state: true }));
            break;
        case getGiftOrderSetting.fulfilled.type:
            dispatch(setLoading({ id: getGiftOrderSetting.typePrefix, state: false }));
            break;
        case getGiftOrderSetting.rejected.type:
            const lang = getState().constant.languages
            dispatch(setLoading({ id: getGiftOrderSetting.typePrefix, state: false }));
            dispatch(addAlert({ severity: "error", message:  getApiLang(lang, payload.code) }));
            break;
        default:
            break;
    }
};


const updateGiftOrderSettingMiddleware = ({ dispatch, getState }) => next => action => {
    next(action);

    const { payload, type } = action;

    switch (type) {
        case updateGiftOrderSetting.pending.type:
            dispatch(setLoading({ id: updateGiftOrderSetting.typePrefix, state: true }));
            break;
        case updateGiftOrderSetting.fulfilled.type:
            dispatch(setLoading({ id: updateGiftOrderSetting.typePrefix, state: false }));
            break;
        case updateGiftOrderSetting.rejected.type:
            const lang = getState().constant.languages
            dispatch(setLoading({ id: updateGiftOrderSetting.typePrefix, state: false }));
            dispatch(addAlert({ severity: "error", message:  getApiLang(lang, payload.code) }));
            break;
        default:
            break;
    }
};

export default [
    fetchGiftListingMiddleware,
    createGiftMiddleware,
    getGiftMiddleware,
    updateGiftMiddleware,
    changeStatusGiftMiddleware,
    getGiftOrderSettingMiddleware,
    updateGiftOrderSettingMiddleware,

    getOrderMiddleware,
    updateOrderMiddleware,
    fetchOrderListingMiddleware,

    fetchPointCutListingMiddleware,
    createPointCutMiddleware,
    getPointCutMiddleware,
    updatePointCutMiddleware,
    getGiftDropdownMiddleware,
    changeStatusPointCutMiddleware
]