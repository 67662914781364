import React, { useState } from "react";
import AdditionalInfoSearchDialogComponent from "./additionalInfoSearchDialog.component";
import { useSelector } from "react-redux";
import { getLang } from "app/feature/constants";

function AdditionalInfoSearchDialogContainer({
  additionalFields,
  values,
  handleChange,
  disabled,
  handleClear,
}) {
  const lang = useSelector((state) => state.constant.languages);
  const userLang = useSelector((state) => state.profile.language);
  const drawerOpen = useSelector((state) => state.app.drawerOpen);

  const [isOpen, setIsOpen] = useState(false);
  const [extraChip, setExtraChip] = useState({
    count: 0,
    offset: 0,
    allOverflow: false,
    overflowChild: [],
  });

  const adjustField = (ref) => {
    let children = ref.current?.children[0]?.children;
    let p = ref.current?.children[0];
    if (!children) return;

    if (children.length > 0) {
      // Need to exclude placeholder
      if (children[0].role == null) return;

      children = [...children];

      // max width is 90%. Use 0.9 to calc field width
      let panelWidth = ref.current?.getBoundingClientRect().width * 0.9;
      let childWidth = 0;

      // Check for no overflow child count
      let inRangeCount = children.filter((child, index) => {
        let panelContentWidth = p.getBoundingClientRect().width;
        // get exact width + padding with decimal
        childWidth +=
          parseInt(window.getComputedStyle(child).marginRight) +
          child.getBoundingClientRect().width;

        // Check if overflow
        if (panelWidth - panelContentWidth < 1) {
          // need to add 0.5 as the ellipsis padding (0.5 = ellipsis padding)
          // if the overflow happen before last child, need inlude another 10 for the ellipsis (10 = ellipsis width)
          return index !== children.length - 1
            ? childWidth + 10.5 < panelContentWidth
            : childWidth + 0.5 < panelContentWidth;
        }

        return panelWidth > panelContentWidth;
      }).length;

      if (inRangeCount > 0) {
        let lastRangeChild = children[inRangeCount - 1];

        let outRangeChild = values.slice(inRangeCount);

        // Check for overflow child count
        let extraCount = values.length - inRangeCount;
        setExtraChip({
          count: extraCount,
          offset: lastRangeChild.offsetLeft + lastRangeChild.offsetWidth,
          allOverflow: false,
          overflowChild: outRangeChild,
        });
      } else {
        setExtraChip({
          count: values.length,
          offset: 0,
          allOverflow: true,
          overflowChild: values,
        });
      }
    } else {
      setExtraChip({
        count: 0,
        offset: 0,
        allOverflow: false,
        overflowChild: [],
      });
    }
  };

  return (
    <AdditionalInfoSearchDialogComponent
      additionalFields={additionalFields}
      values={values}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      lang={lang}
      userLang={userLang}
      placeholder={getLang(lang, "placeholder.SEARCH_BY_ADDITIONAL_INFOS")}
      extraChip={extraChip}
      adjustField={adjustField}
      drawerOpen={drawerOpen}
      handleClear={handleClear}
      handleSearch={(values) => {
        setIsOpen(false);
        handleChange(values);
      }}
    />
  );
}

export default AdditionalInfoSearchDialogContainer;
