import axios from "axios";

/**
 * create axios instance for s3 api calling,
 * reset base url from axios default header
 */

const HOST = process.env.REACT_APP_API_HOST_URL;

const koodServerInstance = axios.create({
  baseURL: `${HOST}/kood_s3_service/api/s3`,
  headers: { "Content-Type": "application/json" },
  timeout: 3000
});

export default koodServerInstance;
