/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getStockModuleSetting,
  updateStockModuleSetting,
} from "modules/account/redux";
import StockModuleSettingPanelComponent from "./stockModuleSettingPanel.component";
import { useHistory } from "react-router-dom";
import {
  COMPANY_STOCK_VIEW,
  COMPANY_STOCK_EDIT,
  COMPANY_STOCK_EDIT_TRANSFER,
  NOTIFICATION_CUSTOM_RECEIVE_EMAIL,
} from "lib/constants/accessRights";
import { DASHBOARD } from "modules/dashboard/constants";
import { getLang } from "app/feature/constants";
import { addAlert, selectLoading } from "modules/notification";

export default function StockModuleSettingPanelContainer() {
  const dispatch = useDispatch();
  const history = useHistory;

  const lang = useSelector((state) => state.constant.languages);
  const hasCompanyStockViewAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(COMPANY_STOCK_VIEW)
  );
  const hasCompanyStockEditAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(COMPANY_STOCK_EDIT)
  );
  const hasCompanyStockEditTransferAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(COMPANY_STOCK_EDIT_TRANSFER)
  );
  const hasUpdateReceiveEmailAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(NOTIFICATION_CUSTOM_RECEIVE_EMAIL)
  );

  const isLoadingStockModuleSetting = useSelector((state) =>
    selectLoading(state, getStockModuleSetting.typePrefix)
  );
  const isSubmitting = useSelector((state) =>
    selectLoading(state, updateStockModuleSetting.typePrefix)
  );

  const rules = useSelector((state) => state.stockModuleSetting.rules);

  const [reasonActiveIndex, setReasonActiveIndex] = useState(0);
  const [hasEmptyReasonField, setHasEmptyReasonField] = useState([]);
  const [invalidEmails, setInvalidEmails] = useState([]);

  const tabLabels = {
    0: {
      value: "Receive Transfer",
      label: getLang(lang, "label.RECEIVE_TRANSFER"),
    },
  };

  useEffect(() => {
    if (hasCompanyStockViewAccessRight) {
      dispatch(getStockModuleSetting());
    } else {
      history.push(DASHBOARD);
    }
  }, [history, dispatch, hasCompanyStockViewAccessRight]);

  const handleSubmit = (values) => {
    let params = {
      maxTier: values.maxTier,
      requestStock: values.requestStock,
      autoReceiveStock: values.autoReceiveStock,
      autoReceiveStockDays: values.autoReceiveStockDays,
      rewardPoint: values.rewardPoint,
      rewardPointMin: values.rewardPointMin,
      rewardPointExpiration: values.rewardPointExpiration,
      rewardPointExpirationMonths: values.rewardPointExpirationMonths,
      rewardPointRecallDeduction: values.rewardPointRecallDeduction,
    };
    if (hasCompanyStockEditTransferAccessRight) {
      let autoReceiveSelection = values.enableAutoCompleteTransfer;
      if (autoReceiveSelection > 0) {
        autoReceiveSelection = values.actionAfterAutoCompleteTransfer;
      }
      params.autoReceiveTransfer = autoReceiveSelection;
      params.autoReceiveTransferDays = values.autoReceiveTransferDays;
      params.enableTransferRules = values.enableTransferRules;
      params.enableCustomReasons = values.enableCustomReasons;

      if (values.reasons && !!values.enableCustomReasons) {
        let data = [];
        let valid = true;

        Object.keys(values.reasons).forEach((rKey) => {
          let reasonData = values.reasons[rKey];

          const reasonByLang = {};

          for (const reason of reasonData.reasons) {
            for (const used of reasonData.usedLang) {
              reasonByLang[used.code] = reasonByLang[used.code]
                ? [...reasonByLang[used.code], reason.name[used.code]]
                : [reason.name[used.code]];
            }
          }

          let tempHasEmptyReasonField = [];
          for (const key in reasonByLang) {
            if (
              reasonByLang[key].filter((r) => !!r).length !==
              reasonData.reasons.length
            ) {
              valid = false;
              tempHasEmptyReasonField.push(rKey);
            }
          }
          setHasEmptyReasonField(tempHasEmptyReasonField);

          data.push({
            type: rKey,
            data: values.reasons[rKey].reasons,
          });
        });

        if (!valid) {
          return;
        }
        params.customReasons = data;
      } else {
        params.customReasons = [];
      }
    }

    if (hasUpdateReceiveEmailAccessRight) {
      params.customEmailOption = values.emailSettings;
      params.customEmailRecipients = values.emailRecipients.filter(
        (r) => !!r.trim()
      );
    }

    params.hasCompanyStockEditTransferAccessRight =
      hasCompanyStockEditTransferAccessRight;
    params.hasUpdateReceiveEmailAccessRight = hasUpdateReceiveEmailAccessRight;

    dispatch(updateStockModuleSetting(params));
  };

  const showAlert = (message) => {
    dispatch(
      addAlert({
        severity: "error",
        message: message,
      })
    );
  };

  return (
    <StockModuleSettingPanelComponent
      hasUpdateReceiveEmailAccessRight={hasUpdateReceiveEmailAccessRight}
      isEditable={hasCompanyStockEditAccessRight}
      hasEditTransferAccessRight={hasCompanyStockEditTransferAccessRight}
      isLoadingStockModuleSetting={isLoadingStockModuleSetting}
      rules={rules}
      handleSubmit={handleSubmit}
      lang={lang}
      tabLabels={tabLabels}
      reasonActiveIndex={reasonActiveIndex}
      changeTab={(e, val) => setReasonActiveIndex(val)}
      hasEmptyReasonField={hasEmptyReasonField}
      isSubmitting={isSubmitting}
      setInvalidEmails={setInvalidEmails}
      invalidEmails={invalidEmails}
      showAlert={showAlert}
    />
  );
}
