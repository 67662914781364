import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectLoading } from "modules/notification";
import { getInventoryPackageListing } from "modules/stockflow/redux/action/stockflowInventory.action";
import PackageListingComponent from "./packageListing.component";
import {
  changeBulkSelectedAvailablePackage,
  changeBulkSelectedPackage,
  changeSelectedPackage,
  resetPackageList,
} from "modules/stockflow/redux";
import { generatePaginationOptions, updateUrlQueryParam } from "lib/helper";
import { fetchTagsDropdown } from "modules/serial-number/redux";
import { exportPackageListing } from "app/api/stockflow/inventory/exportPackageListing.api";
import { useHistory } from "react-router-dom";
import {
  STOCKFLOW_INVENTORY_VIEW,
  STOCKFLOW_DEALER_VIEW,
} from "lib/constants/accessRights";

function PackageListingContainer() {
  const dispatch = useDispatch();
  const history = useHistory();

  const hasStockflowInventoryViewAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(STOCKFLOW_INVENTORY_VIEW)
  );
  const hasStockflowDealerViewAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(STOCKFLOW_DEALER_VIEW)
  );
  const language = useSelector(state => state.profile.language);
  const lang = useSelector((state) => state.constant.languages);
  const token = useSelector((state) => state.session.accessToken);
  const isFetching = useSelector((state) =>
    selectLoading(state, getInventoryPackageListing.typePrefix)
  );
  const totalFiltered = useSelector(
    (state) => state.stockflowInventory.totalFiltered
  );
  const totalRecords = useSelector(
    (state) => state.stockflowInventory.totalRecords
  );
  const packages = useSelector((state) => state.stockflowInventory.packages);
  const tags = useSelector((state) => state.serialNumberTag.tagsDropdown);
  const selectedIds = useSelector(
    (state) => state.stockflowInventory.selectedId
  );
  const isSelectAll = useSelector(
    (state) => state.stockflowInventory.isSelectAll
  );
  const isError = useSelector(
    (state) => state.stockflowInventory.isLoadingPackageListError
  );
  const isSearchResult = packages.length < totalRecords;
  const [paginationOptions, setPaginationOptions] = useState([]);
  const [isExporting, setIsExporting] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isFilterUpdated, setIsFilterUpdated] = useState(false);
  const [tableFilter, setTableFilter] = useState({
    page: 0,
    rowsPerPage: 25,
    search: "",
    searchColumn: "",
    order: "desc",
    orderBy: "updated_at",
    nodeIds: [],
    branch: [],
    pattern: [],
    createdAt: null,
    updatedAt: "",
    locatedAt: [],
  });

  const query = new URLSearchParams(window.location.search);
  const tableFilterInitialRef = useRef(true);

  useEffect(() => {
    return () => {
      dispatch(resetPackageList());
    };
  }, [dispatch]);

  useEffect(() => {
    const isFilter = query.has("is_filter");
    let filter = sessionStorage.getItem("inventory_package_filter");

    let temp = { ...tableFilter };
    if (isFilter && filter) {
      temp = JSON.parse(filter);
      setIsFilterUpdated(true);
      setIsFilterOpen(true);
    }

    if (isFilter) {
      updateUrlQueryParam(null, ["is_filter"]);
    }
    sessionStorage.removeItem("inventory_package_filter");

    setTableFilter(temp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!tableFilterInitialRef.current) {
      getData();
    }
    tableFilterInitialRef.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableFilter]);

  useEffect(() => {
    dispatch(fetchTagsDropdown());
  }, [dispatch]);

  useEffect(() => {
    setPaginationOptions(generatePaginationOptions(totalFiltered));
  }, [totalFiltered]);

  const getData = () => {
    dispatch(
      getInventoryPackageListing({
        length: tableFilter.rowsPerPage,
        start: tableFilter.rowsPerPage * tableFilter.page,
        order: tableFilter.order,
        order_by: tableFilter.orderBy,
        filter: {
          search: tableFilter.search,
          search_by: tableFilter.searchColumn,
          status: tableFilter.status,
          nodes: tableFilter.nodeIds,
          pattern: tableFilter.pattern,
          created_at: tableFilter.createdAt,
          updated_at: tableFilter.updatedAt,
          located_at: tableFilter.locatedAt,
        },
      })
    );
  };

  const handleChangePage = (event, newPage) => {
    let newData = {
      ...tableFilter,
      page: newPage,
    };
    setIsFilterUpdated(true);
    setTableFilter(newData);
  };

  const handleChangeRowsPerPage = (event) => {
    let newData = {
      ...tableFilter,
      page: 0,
      rowsPerPage: event.target.value,
    };
    updateUrlQueryParam({
      page: newData.page + 1,
      length: newData.rowsPerPage,
    });
    setIsFilterUpdated(true);
    setTableFilter(newData);
  };

  const handleSearch = (filter) => {
    let nodeIds =
      filter.branch.length > 0 ? filter.branch.map((br) => br.node_id) : [];

    let newData = {
      ...tableFilter,
      page: 0,
      search: filter.search,
      searchColumn: filter.searchColumn,
      nodeIds: nodeIds,
      branch: filter.branch,
      pattern: filter.pattern,
      status: filter.status ? filter.status.value : "",
      createdAt: filter.createdAt,
      updatedAt: filter.updatedAt,
      locatedAt: filter.locatedAt,
    };
    setIsFilterUpdated(true);
    setTableFilter(newData);
  };

  const clearSearch = () => {
    let newData = {
      ...tableFilter,
      page: 0,
      search: "",
      searchColumn: "",
      status: "",
      nodeIds: [],
      pattern: [],
      branch: [],
      createdAt: "",
      updatedAt: "",
      locatedAt: [],
    };
    setIsFilterUpdated(false);
    setTableFilter(newData);
  };

  const handleSelectPackage = (index, isSelected) => {
    dispatch(changeSelectedPackage({ index, isSelected }));
  };

  const handleSelectAvailable = () => {
    dispatch(changeBulkSelectedAvailablePackage({ value: true }));
  };

  const handleSelectAll = () => {
    dispatch(changeBulkSelectedPackage({ value: true }));
  };

  const handleDeselectAll = () => {
    dispatch(changeBulkSelectedPackage({ value: false }));
  };

  const changeOrder = (orderBy, order) => {
    let newData = {
      ...tableFilter,
      order,
      orderBy,
    };
    setIsFilterUpdated(true);
    setTableFilter(newData);
  };

  const resetFilter = () => {
    let newData = {
      ...tableFilter,
      page: 0,
      status: "",
      nodeIds: [],
      pattern: [],
      branch: [],
      createdAt: "",
      updatedAt: "",
      locatedAt: [],
    };
    setIsFilterUpdated(true);
    setTableFilter(newData);
  };

  const exportListing = () => {
    setIsExporting(true);
    exportPackageListing
      .post({
        token,
        isSelectAll,
        selectedIds,
        filter: {
          search: tableFilter.search,
          search_by: tableFilter.searchColumn,
          status: tableFilter.status,
          nodes: tableFilter.nodeIds,
          pattern: tableFilter.pattern,
          created_at: tableFilter.createdAt,
          updated_at: tableFilter.updatedAt,
          located_at: tableFilter.locatedAt,
        },
      })
      .then((blob) => {
        saveFile(blob);
        setIsExporting(false);
      })
      .catch(() => {
        setIsExporting(false);
      });
  };

  const saveFile = async (blob) => {
    const a = document.createElement("a");
    a.download = `inventory_packages.xlsx`;
    a.href = URL.createObjectURL(blob);
    a.addEventListener("click", (e) => {
      setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
    });
    a.click();
  };

  const handleViewDetail = (url) => {
    if (isFilterUpdated) {
      updateUrlQueryParam({ is_filter: true });
      sessionStorage.setItem(
        "inventory_package_filter",
        JSON.stringify(tableFilter)
      );
    }
    history.push(url);
  };

  return (
    <>
      <PackageListingComponent
        hasStockflowInventoryViewAccessRight={
          hasStockflowInventoryViewAccessRight
        }
        hasStockflowDealerViewAccessRight={hasStockflowDealerViewAccessRight}
        packages={packages}
        isFetching={isFetching}
        selectedIds={selectedIds}
        isSelectAll={isSelectAll}
        selectedCount={
          !isSelectAll ? selectedIds.length : totalFiltered - selectedIds.length
        }
        isError={isError}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        page={tableFilter.page}
        rowsPerPage={tableFilter.rowsPerPage}
        totalFiltered={totalFiltered}
        handleSearch={handleSearch}
        clearSearch={clearSearch}
        handleReload={getData}
        language={language}
        isSearchResult={isSearchResult}
        handleSelectPackage={handleSelectPackage}
        handleSelectAvailable={handleSelectAvailable}
        handleSelectAll={handleSelectAll}
        handleDeselectAll={handleDeselectAll}
        token={token}
        paginationOptions={paginationOptions}
        tableFilter={tableFilter}
        changeOrder={changeOrder}
        isFilterOpen={isFilterOpen}
        setIsFilterOpen={setIsFilterOpen}
        tags={tags}
        exportListing={exportListing}
        isExporting={isExporting}
        lang={lang}
        resetFilter={resetFilter}
        handleViewDetail={handleViewDetail}
      />
    </>
  );
}

export default PackageListingContainer;
