import React from "react";
import { Formik } from "formik";
import { Box, Button, makeStyles } from "@material-ui/core";
import ProductSearch from "modules/stockflow/components/select/productSearch";
import { getLang } from "app/feature/constants";
import Input from "components/input/inputTextField";
import AssignTable from "modules/serial-number/components/table/assignTable";

const useStyle = makeStyles((theme) => ({
  searchContainer: {
    backgroundColor: theme.palette.background.paper,
    display: "grid",
    gridTemplateColumns: "8fr 4fr 2fr 2fr",
    columnGap: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1),
    },
  },
  inputRootProduct: {
    paddingTop: "8px !important",
    paddingBottom: "7px !important",
    paddingLeft: "12px !important",
    paddingRight: "12px !important",
  },
}));

export default function AssignListingComponent({
  isFetching,
  assignList,
  tableFilter,
  handleChangePage,
  handleChangeRowsPerPage,
  language,
  totalFiltered,
  handleSearch,
  handleReload,
  paginationOptions,
  lang,
  setSelectedBatch,
  changeOrder,
  clearSearch,
  productDropdown,
  isError,
  retryAssign
}) {
  const classes = useStyle();

  return (
    <>
      <Formik
        initialValues={{
          search: tableFilter.search,
          products: tableFilter.products,
        }}
        enableReinitialize
        onSubmit={handleSearch}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <Box p={2} mb={2} className={classes.searchContainer}>
              <Input
                hiddenLabel
                variant="filled"
                size="small"
                placeholder={getLang(
                  lang,
                  "placeholder.SEARCH_ASSIGN_BATCH_NUMBER"
                )}
                margin="none"
                InputProps={{
                  disableUnderline: true,
                  margin: "none",
                  style: {
                    borderRadius: "4px",
                    backgroundColor: "#eceff0",
                    fontWeight: "600",
                    fontSize: 14,
                    height: "100%",
                  },
                  classes: { input: classes.inputRootProduct },
                }}
                {...formik.getFieldProps("search")}
              />
              <ProductSearch
                searchable={true}
                value={formik.values.products}
                placeholder={getLang(lang, 'placeholder.SEARCH_BY_PRODUCT')}
                searchPlaceholder={getLang(lang, 'placeholder.SEARCH_A_PRODUCT_NAME')}
                isClearable={false}
                emptyMessage={getLang(lang, 'message.info.NO_PRODUCT_FOUND')}
                dropdownItem={productDropdown}
                isFetching={false}
                isEmpty={formik.values.products?.length === 0}
                selectedProduct={formik.values.products}
                style={{
                  padding: "0.25em 0.75em",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderRadius: 4,
                  width: "100%",
                  overflow: "hidden",
                }}
                handleSelectAll={(selectAll) => {
                  if (selectAll) {
                    formik.setFieldValue("products", productDropdown)
                  } else {
                    formik.setFieldValue("products", [])
                  }
                }}
                handleChange={(value) => {
                  let temp = [...formik.values.products]

                  if (temp.length === 0) {
                    temp.push(value)
                  } else {
                    const isProductExist = temp.find((product) => product.id === value.id)

                    if (isProductExist === undefined) {
                      temp.push(value)
                    } else {
                      let filter = temp.filter((product) => product.id !== value.id)

                      temp = [...filter]
                    }
                  }
                  formik.setFieldValue("products", [...temp])
                }}
                handleChipDelete={(product) => {
                  let temp = [...formik.values.products];
                  if (temp.length > 0) {
                    const filteredProducts = temp.filter((item) => item.id !== product.id);
                    temp = [...filteredProducts];
                  }
                  formik.setFieldValue("products", temp);
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left"
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                disabled={false}
              />
              <Button
                variant="text"
                disableElevation
                color="primary"
                size="small"
                onClick={() => {
                  clearSearch();
                }}
              >
                {getLang(lang, "label.CLEAR")}
              </Button>
              <Button
                variant="contained"
                disableElevation
                color="primary"
                size="small"
                type="submit"
              >
                {getLang(lang, "label.SEARCH")}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
      <AssignTable
        rows={assignList}
        isFetching={isFetching}
        isError={isError}
        language={language}
        handleReload={handleReload}
        page={tableFilter.page}
        rowsPerPage={tableFilter.rowsPerPage}
        handleChangePage={handleChangePage}
        handleChangeRowPerPage={handleChangeRowsPerPage}
        totalFiltered={totalFiltered}
        paginationOptions={paginationOptions}
        tableFilter={tableFilter}
        changeOrder={changeOrder}
        lang={lang}
        setSelectedBatch={setSelectedBatch}
        retryAssign={retryAssign}
      />
    </>
  );
}
