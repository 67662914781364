import React from "react";
import {
  makeStyles,
  Box,
  Paper,
  Typography,
  IconButton
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { getLang } from "app/feature/constants";

const style = (theme) => ({
  header: {
    padding: "4px 0px",
    paddingLeft: theme.spacing(3),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  }
});

const useStyles = makeStyles(style);

export default function HeaderComponent({ handleClose, headerType, title, lang }) {
  const classes = useStyles();

  return (
    <Paper elevation={0}>
      <Box className={classes.header}>
        <Typography
          variant="subtitle2"
          color="primary"
          style={{ fontWeight: "bold" }}
        >
          {getLang(lang, 'db_label.' + headerType)} {getLang(lang, 'label.WARRANTY')} {title ? ("(" + title + ")") : null}
        </Typography>
        <IconButton color="primary" onClick={handleClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>
    </Paper>
  );
}
