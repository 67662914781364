import React from "react";
import {
  makeStyles,
  Box,
  Divider,
  Button,
  StepConnector,
  StepLabel,
  Stepper,
  Step
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import clsx from "clsx";
import { getLang } from "app/feature/constants";

const Connector = withStyles((theme) => ({
  alternativeLabel: {
    padding: "0 80px",
    top: "20px",
    [theme.breakpoints.down("xs")]: {
      padding: "0 20px"
    }
  },
  active: {
    "& $line": {
      borderColor: theme.palette.primary.main
    }
  },
  completed: {
    "& $line": {
      borderColor: theme.palette.primary.main
    }
  },
  line: {
    borderColor: "rgba(58, 77, 84, 0.33)",
    borderTopWidth: 4,
    borderRadius: 5
  }
}))(StepConnector);

const useStyle = makeStyles((theme) => ({
  content: {
    width: "95%",
    maxWidth: 850
  },
  publishButton: {
    color: theme.palette.common.white
  },
  backButton: {
    marginRight: theme.spacing(1)
  },
  discardButton: {
    color: theme.palette.error.main
  },
  iconContainer: {
    minHeight: 38
  },
  stepperRoot: {
    paddingBottom: 0,
    padding: 0
  },
  stepLabelRoot: {
    height: 100,
    position: "relative"
  },
  stepLabelActive: {
    "&::after": {
      content: "''",
      backgroundColor: theme.palette.primary.main,
      width: "20%",
      height: 3,
      position: "absolute",
      bottom: 0,
      borderRadius: theme.spacing(1, 1, 0, 0)
    }
  }
}));

function StepperComponent({
  earlyAccess,
  activeStep,
  steps,
  handleNext,
  handleBack,
  handleDiscard,
  file,
  setFile,
  formRef,
  isFormEditing,
  lang
}) {
  const classes = useStyle();
  const SelectedStep = steps[activeStep];

  const showBackBtn = activeStep !== 0;
  const isLastStep = activeStep === steps.length - 1;

  return (
    <Box>
      <Box p={2} pb={0}>
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          connector={<Connector />}
          classes={{ root: classes.stepperRoot }}
        >
          {steps.map((step, index) => (
            <Step key={index}>
              <StepLabel
                classes={{
                  root: clsx(
                    classes.stepLabelRoot,
                    activeStep === index && classes.stepLabelActive
                  ),
                  iconContainer: classes.iconContainer
                }}
                StepIconComponent={
                  activeStep >= index ? step.activeImage : step.labelImage
                }
              >
                {step.label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      <Divider />
      <SelectedStep.form
        type={'create'}
        handleNext={handleNext}
        file={file}
        setFile={setFile}
        isFormEditing={isFormEditing} 
        formRef={formRef}
        earlyAccess={earlyAccess}
      >
        {() => (
          <Box mt="auto">
            <Divider />
            <Box
              display="flex"
              justifyContent="space-between"
              px={2}
              pt={1}
              pb={1}
            >
              {activeStep === 0 ? (
                <Button
                  variant="text"
                  classes={{ root: classes.discardButton }}
                  onClick={handleDiscard}
                >
                  {getLang(lang, 'label.DISCARD')}
                </Button>
              ) : null}
              {showBackBtn ? (
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  onClick={handleBack}
                  type="button"
                  disabled={isFormEditing}
                  classes={{ root: classes.backButton }}
                >
                  {getLang(lang, 'label.BACK')}
                </Button>
              ) : null}

              <Button
                variant="contained"
                color="secondary"
                classes={{ root: classes.publishButton }}
                disableElevation
                disabled={isFormEditing}
                type="submit"
                onClick={isLastStep ? handleNext : null}
              >
                {isLastStep ? getLang(lang, 'label.DONE') : getLang(lang, 'label.NEXT')}
              </Button>
            </Box>
          </Box>
        )}
      </SelectedStep.form>
    </Box>
  );
}

export default StepperComponent;
