import axios from "axios";

const get = ({ ...parameters }) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/serialnumber/api/v1/warranty-extension/${parameters.id}/generates`, {
        params: {
          draw: parameters.draw,
          length: parameters.length,
          start: parameters.start,
          column: parameters.column,
          order: parameters.order,
          search: parameters.search,
          index: parameters.index
        }
      })
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

const getWarrantyExtensionBatch = {
  get
};

export default getWarrantyExtensionBatch;
