import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { selectLoading } from "modules/notification";
import {
  getInventoryProductStockList,
  resetProductStockListing,
} from "modules/stockflow/redux";
import ProductStockListPanelComponent from "./productStockListPanel.component";
import { generatePaginationOptions, updateUrlQueryParam } from "lib/helper";

function ProductStockListPanelContainer({ viewActivityLog }) {
  const lang = useSelector((state) => state.constant.languages);
  const productStockList = useSelector(
    (state) => state.stockflowInventory.productStockList
  );
  const totalFiltered = useSelector(
    (state) => state.stockflowInventory.totalFiltered
  );
  const totalRecords = useSelector(
    (state) => state.stockflowInventory.totalRecords
  );
  const isFetching = useSelector((state) =>
    selectLoading(state, getInventoryProductStockList.typePrefix)
  );
  const isError = useSelector(
    (state) => state.stockflowInventory.isLoadingProductStockListError
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch();
  const id = match.params.productId;
  const isSearchResult = totalFiltered < totalRecords;
  const [paginationOptions, setPaginationOptions] = useState([]);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isFilterUpdated, setIsFilterUpdated] = useState(false);
  const [tableFilter, setTableFilter] = useState({
    page: 0,
    rowsPerPage: 25,
    search: "",
    searchColumn: "serial_number", // serial_number | sequence_no
    nodeIds: [],
    branch: [],
    status: "", // active | suspend
    box: false,
    packed: false,
    order: "desc",
    orderBy: "sequence_number",
  });

  const query = new URLSearchParams(window.location.search);
  const tableFilterInitialRef = useRef(true);

  useEffect(() => {
    return () => {
      dispatch(resetProductStockListing());
    };
  }, [dispatch]);

  useEffect(() => {
    const isFilter = query.has("is_filter");
    let filter = sessionStorage.getItem("inventory_product_stock_filter");

    let temp = { ...tableFilter };
    if (isFilter && filter) {
      temp = JSON.parse(filter);
      setIsFilterUpdated(true);
      setIsFilterOpen(true);
    }

    if (isFilter) {
      updateUrlQueryParam(null, ["is_filter"]);
    }
    sessionStorage.removeItem("inventory_product_stock_filter");

    setTableFilter(temp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!tableFilterInitialRef.current) {
      getData();
    }
    tableFilterInitialRef.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableFilter]);

  useEffect(() => {
    setPaginationOptions(generatePaginationOptions(totalFiltered));
  }, [totalFiltered]);

  const getData = () => {
    const dealer = query.get("dealer");

    dispatch(
      getInventoryProductStockList({
        id,
        length: tableFilter.rowsPerPage,
        start: tableFilter.rowsPerPage * tableFilter.page,
        order: tableFilter.order,
        order_by: tableFilter.orderBy,
        dealer: dealer || "",
        filter: {
          search: tableFilter.search,
          search_by: tableFilter.searchColumn,
          nodes: tableFilter.nodeIds,
          status: tableFilter.status,
          box: tableFilter.box,
          packed: tableFilter.packed,
        },
      })
    );
  };

  const handleSearch = async (values) => {
    let nodeIds =
      values.branch.length > 0 ? values.branch.map((br) => br.node_id) : [];

    let newData = {
      ...tableFilter,
      page: 0,
      search: values.search,
      searchColumn: values.searchColumn,
      nodeIds: nodeIds,
      branch: values.branch,
      status: values.status.value,
      box: values.box.value || false,
      packed: values.packed.value || false,
    };
    setIsFilterUpdated(true);
    setTableFilter(newData);
  };

  const handleChangePage = (event, newPage) => {
    let newData = {
      ...tableFilter,
      page: newPage,
    };
    setIsFilterUpdated(true);
    setTableFilter(newData);
  };

  const handleChangeRowsPerPage = (event) => {
    let newData = {
      ...tableFilter,
      page: 0,
      rowsPerPage: event.target.value,
    };
    setIsFilterUpdated(true);
    setTableFilter(newData);
  };

  const clearSearch = () => {
    let newData = {
      ...tableFilter,
      page: 0,
      search: "",
      searchColumn: "serial_number",
      nodeIds: [],
      branch: [],
      status: "",
      box: false,
      packed: false,
    };
    setIsFilterUpdated(false);
    setTableFilter(newData);
  };

  const changeSequenceOrder = (order) => {
    let newData = {
      ...tableFilter,
      order,
    };
    setIsFilterUpdated(true);
    setTableFilter(newData);
  };

  const resetFilter = () => {
    let newData = {
      ...tableFilter,
      page: 0,
      nodeIds: [],
      branch: [],
      status: "",
      box: false,
      packed: false,
    };
    setIsFilterUpdated(true);
    setTableFilter(newData);
  };

  const handleViewDetail = (url) => {
    if (isFilterUpdated) {
      updateUrlQueryParam({ is_filter: true });
      sessionStorage.setItem(
        "inventory_product_stock_filter",
        JSON.stringify(tableFilter)
      );
    }
    history.push(url);
  };

  return (
    <>
      <ProductStockListPanelComponent
        rows={productStockList}
        isFetching={isFetching}
        isError={isError}
        handleReload={getData}
        page={tableFilter.page}
        rowsPerPage={tableFilter.rowsPerPage}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleSearch={handleSearch}
        isSearchResult={isSearchResult}
        totalFiltered={totalFiltered}
        isFilterOpen={isFilterOpen}
        setIsFilterOpen={setIsFilterOpen}
        viewActivityLog={viewActivityLog}
        tableFilter={tableFilter}
        changeSequenceOrder={changeSequenceOrder}
        lang={lang}
        resetFilter={resetFilter}
        paginationOption={paginationOptions}
        clearSearch={clearSearch}
        handleViewDetail={handleViewDetail}
      />
    </>
  );
}

export default ProductStockListPanelContainer;
