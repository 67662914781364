import axios from 'axios';

export function getAttributeDropdown({
  search
}) {
  const url =  `/serialnumber/api/v1/product/attribute/get-attribute-options-dropdown-tree`;

  return new Promise((resolve, reject) => {
    axios.get(url, {
      params: {
        search: search
      }
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data))
  })
}