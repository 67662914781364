import { createSelector } from "@reduxjs/toolkit";
import {
  COMPANY_STATUS_APPROVED,
  COMPANY_STATUS_DEACTIVATED,
  COMPANY_STATUS_SUSPEND,
  LABEL_COMPANY
} from "modules/admin/constants";

const selectSignupStepsState = createSelector(
  state => state.signupsteps,
  result => result
);

const selectPlan = createSelector(
  state => state.stripePlan.plans,
  result => result
);

export const isCompanyApproved = createSelector(
  state => state.session,
  session =>
    session.labels.some(label => label.key === LABEL_COMPANY) &&
    session.company.status === COMPANY_STATUS_APPROVED
);
export const selectCurrentRegistrationStep = createSelector(
  selectSignupStepsState,
  signupState => signupState.currentRegistrationStep
);

export const selectUserProfileData = createSelector(
  selectSignupStepsState,
  signupState => {
    const { userInfo } = signupState;
    return {
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      phoneCountry: userInfo.phoneCountry,
      contactNumber: userInfo.contactNumber,
      contactCode: userInfo.contactCode,
      language: userInfo.language,
      timezone: userInfo.timezone,
      newsletter: userInfo.newsletter,
      privacyPolicySignature: userInfo.privacyPolicySignature,
      endUserAgreementSignature: userInfo.endUserAgreementSignature
    };
  }
);

export const isPlanSelected = createSelector(
  selectSignupStepsState,
  signupState => signupState.selectedPlanGroup !== "" && signupState.selectedPlanGroupInterval !== ""
);

export const isPlanLoaded = createSelector(
  selectPlan,
  plans => !plans.isLoading && plans.isLoaded && !plans.error
);

export const selectPlans = createSelector(
  selectSignupStepsState,
  selectPlan,
  (signupState, plans) => {
    const paymentInterval = signupState.selectedInterval;
    const planNames = plans.data.planNames;
    const allPlans = plans.data.plans;

    if (!planNames) return null;

    const price = planNames.map(name => {
      const currentTypePlans = allPlans.filter(p => p.product.name === name);

      const currentPlan = currentTypePlans.find(
        p => p.interval === paymentInterval
      );

      const featuresMetadata = JSON.parse(
        currentPlan.product.metadata.properties
      );

      const featureNames = Object.keys(featuresMetadata);

      const savedAmountOnAnnualBilling = Math.abs(
        currentTypePlans.find(({ interval }) => interval === "year").amount -
          currentTypePlans.find(({ interval }) => interval === "month").amount *
            12
      );

      const features = featureNames
        .map(name => ({
          title: name,
          isIncluded: featuresMetadata[name]
        }))
      return {
        name,
        currency: currentPlan.currency,
        price: currentPlan.amount,
        id: currentPlan.id,
        features,
        savedAmountOnAnnualBilling
      };
    });

    return price;
  }
);

export const isCompanySuspended = createSelector(
  state => state.session,
  session =>
    session.labels.some(label => label.key === LABEL_COMPANY) &&
    session.company.status === COMPANY_STATUS_SUSPEND
);

export const isAccountSuspended = createSelector(
  state => state.profile,
  profile =>
    profile.state === "suspend" ? true : false
);

export const isCompanyDisabled = createSelector(
  state => state.session,
  session =>
    session.labels.some(label => label.key === LABEL_COMPANY) &&
    session.company.status === COMPANY_STATUS_DEACTIVATED
);
