import React from "react";
import BatchTableComponent from "./batchTable.component";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { GENERATE_SN } from "../../../config/serial-number.route";

function BatchTableContainer({
  hasSerialNumberGenerateAccessRight,
  isFetching,
  generateBatchData,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
  length,
  isSearchResult,
  isLoadingSerialNumberBatchError,
  handleReload,
  language,
  paginationOptions,
  lang,
  handleAppAuthorizeStatusChange,
  handleScanVerifyStatusChange,
  setSelectedBatch,
  setQuickEdit,
  hasBatchVerificationEditRight,
  hasBatchAuthenticatorEditRight
}) {
  const history = useHistory();

  const handleAddSerialNumber = () => {
    history.push(GENERATE_SN);
  };

  return (
    <BatchTableComponent
      hasSerialNumberGenerateAccessRight={hasSerialNumberGenerateAccessRight}
      rows={generateBatchData}
      page={page}
      rowsPerPage={rowsPerPage}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      isFetching={isFetching}
      length={length}
      isSearchResult={isSearchResult}
      language={language}
      handleAddSerialNumber={handleAddSerialNumber}
      isLoadingSerialNumberBatchError={isLoadingSerialNumberBatchError}
      handleReload={handleReload}
      paginationOptions={paginationOptions}
      lang={lang}
      handleAppAuthorizeStatusChange={handleAppAuthorizeStatusChange}
      handleScanVerifyStatusChange={handleScanVerifyStatusChange}
      setSelectedBatch={setSelectedBatch}
      setQuickEdit={setQuickEdit}
      hasBatchVerificationEditRight={hasBatchVerificationEditRight}
      hasBatchAuthenticatorEditRight={hasBatchAuthenticatorEditRight}
    />
  );
}

const mapStateToProps = (state) => ({
  isLoadingSerialNumberBatchError:
    state.serialNumberBatch.isLoadingSerialNumberBatchError,
});

export default connect(mapStateToProps)(BatchTableContainer);
