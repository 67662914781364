import axios from "axios"

// sign up
export function join({
  type,
  invitationCode,
  email,
  password,
  confirmPassword,
  firstName,
  lastName,
  gender,
  countryCode,
  phoneNumber,
  addressLine1,
  addressLine2,
  city,
  state,
  country,
  locale,
  postalCode,
  dateOfBirth,
}) {

  return new Promise((resolve, reject) => {
    axios.post("distributor/public/api/v1/auth/join", {
      type: type,
      invitation_code: invitationCode,
      email: email,
      password: password,
      confirm_password: confirmPassword,
      first_name: firstName,
      last_name: lastName,
      gender: gender,
      phone_code: countryCode,
      phone_number: phoneNumber,
      address_line_1: addressLine1,
      address_line_2: addressLine2,
      city: city,
      state: state,
      country: country,
      locale: locale,
      postal_code: postalCode,
      date_of_birth: dateOfBirth, 
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data))
  })
}