import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import RejectRegistrationDialogComponent from "./rejectRegistrationDialog.component";
import {getLang} from "../../../../../app/feature/constants";

function RejectRegistrationDialogContainer({ isOpen, handleClose, handleSubmit }) {

  const lang = useSelector(state => state.constant.languages);
  const reasons = [
    getLang(lang, "paragraph.REJECT_REASON1"),
    getLang(lang, "paragraph.REJECT_REASON2"),
    getLang(lang, "paragraph.REJECT_REASON3"),
  ]

  return (
    <RejectRegistrationDialogComponent
      isOpen={isOpen}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      reasons={reasons}
      lang={lang}
    />
  );
}

RejectRegistrationDialogContainer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default RejectRegistrationDialogContainer;
