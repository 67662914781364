import React from "react";
import {
  makeStyles,
  Typography,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  Box,
  Divider,
  MenuItem,
  MenuList,
  Checkbox,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import {
  Search as SearchIcon,
} from "@material-ui/icons"
import PerfectScrollbar from "react-perfect-scrollbar";
import Skeleton from "@material-ui/lab/Skeleton";
import { Fragment } from "react";
import InputTextField from "components/input/inputTextField";
import { useRef } from "react";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles((theme) => ({
  paper: {
    width: 425
  },
  defaultCurrencyText: {
    backgroundColor: "#6AAF68",
    color: "#FFFFFF",
    borderRadius: "10px",
    paddingLeft: "12px",
    paddingRight: "12px",
    marginLeft: "10px"
  }
}));

export default function CurrencyPanelComponent({
  open,
  anchorRef,
  handleClose,
  isOpenList,
  currencyDropdown,
  isFetching,
  value,
  handleSelectAll,
  selectAll,
  handleSelectCurrency,
  searchable,
  handleSearch,
  placeholder,
  lang
}) {
  const classes = useStyle();
  const textRef = useRef();

  return (
    <Popper
      open={open}
      style={{ zIndex: 99 }}
      anchorEl={anchorRef.current}
      role={undefined}
      transition
      disablePortal
      placement="bottom-start"
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === "bottom-start" ? "center top" : "center bottom"
          }}
        >
          <Paper className={classes.paper}>
            <ClickAwayListener onClickAway={handleClose}>
              <Box>
                <PerfectScrollbar>
                  {isFetching ? (
                    <>
                      <Skeleton variant="text" animation="wave" />
                      <Skeleton variant="text" animation="wave" />
                      <Skeleton variant="text" animation="wave" />
                      <Skeleton variant="text" animation="wave" />
                    </>
                  ) : (
                    <div>
                      {searchable && (
                        <Fragment>
                          <InputTextField
                            variant="filled"
                            size="small"
                            fullWidth
                            inputRef={textRef}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position='end' style={{ paddingRight: 6 }}>
                                  <IconButton onClick={() => handleSearch(textRef.current.value)}>
                                    <SearchIcon />
                                  </IconButton>
                                </InputAdornment>
                              ),
                              style: {
                                backgroundColor: "#fff",
                                paddingRight: 0
                              }
                            }}
                            inputProps={{
                              onKeyDown: (e) => {
                                if (e.key === "Enter") {
                                  e.preventDefault();
                                  handleSearch(textRef.current.value)
                                }
                              },
                              style: {
                                padding: "8px"
                              }
                            }}
                            placeholder={placeholder || getLang(lang, "placeholder.SEARCH")}
                          />
                        </Fragment>
                      )}
                      <Divider />
                      <Box>
                        <MenuList
                          autoFocusItem={isOpenList}
                          id="menu-list-grow"
                          onClick={() => {
                            handleSelectAll();
                          }}
                          style={{ padding: 0 }}
                        >
                          <MenuItem style={{ padding: 0 }}>
                            <Checkbox checked={selectAll} />
                            <Typography
                              variant="inherit"
                              noWrap
                              style={{ paddingLeft: "8px" }}
                            >
                              {getLang(lang, "label.SELECT_ALL_CURRENCY")}
                            </Typography>
                          </MenuItem>
                        </MenuList>
                        <Divider />

                        <Box height="25vh" overflow="auto">
                          {currencyDropdown.map((currency, index) => {
                            return (
                              <React.Fragment key={index}>
                                <MenuItem
                                  style={{ padding: 0 }}
                                  onClick={() => handleSelectCurrency(currency)}
                                >
                                  <Checkbox
                                    checked={
                                      value.find(
                                        (v) => v.alpha3 === currency.alpha3
                                      )
                                        ? true
                                        : false 
                                    }
                                  />
                                  <Typography
                                    variant="inherit"
                                    noWrap
                                    style={{ paddingLeft: "8px" }}
                                  >
                                    <span>{currency.alpha3}
                                      {currency.isDefault ?
                                        (
                                          <span className={classes.defaultCurrencyText}>{getLang(lang, "label.DEFAULT")}</span>
                                        )
                                        : null
                                      }
                                    </span>
                                  </Typography>
                                </MenuItem>
                              </React.Fragment>
                            );
                          })}
                        </Box>
                      </Box>
                    </div>
                  )}
                </PerfectScrollbar>
              </Box>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}
