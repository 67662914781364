import React from "react";
import {
  Typography,
  Box,
  FormGroup,
  FormLabel,
  FormControlLabel,
  Checkbox,
  Divider
} from "@material-ui/core";
import * as Yup from "yup";
import { Formik, ErrorMessage } from "formik";
import { TYPES } from "./facebookForm.container";
import { ModalFooter, ContentWrapper } from "../../modal";
import Input from "components/input/inputTextField";
import { getLang } from "app/feature/constants";

export default function FacebookFormComponent({
  type,
  initialValues,
  handleSubmit,
  handleDelete,
  lang,
  handleClose
}) {

  return (
    <Formik
      initialValues={{
        pageURL: initialValues.pageURL,
        useSmallHeader: initialValues.useSmallHeader,
        hideCoverPhoto: initialValues.hideCoverPhoto,
        showFriendsFaces: initialValues.showFriendsFaces,
        tabsTimeline: initialValues.tabs.timeline,
        tabsEvents: initialValues.tabs.events,
        tabsMessages: initialValues.tabs.messages
      }}
      validationSchema={Yup.object({
        pageURL: Yup.string().required(getLang(lang,"message.error.FORM_VALIDATE_REQUIRED")),
        useSmallHeader: Yup.bool(),
        hideCoverPhoto: Yup.bool(),
        showFriendsFaces: Yup.bool(),
        tabsTimeline: Yup.bool(),
        tabsEvents: Yup.bool(),
        tabsMessages: Yup.bool()
      })}
      onSubmit={handleSubmit}
    >
      {formik => (
        <form onSubmit={formik.handleSubmit}>
          <ContentWrapper>
            <Typography variant="body2" gutterBottom>
              {getLang(lang,"label.INSERT_FACEBOOK_URL")} <Typography style={{ color: "#DE350B", display: 'inline-block' }}>*</Typography>
            </Typography>
            <Input
              required
              hiddenLabel
              variant="filled"
              size="small"
              margin="normal"
              placeholder={getLang(lang,"placeholder.ENTER_URL_HERE")}
              fullWidth
              {...formik.getFieldProps("pageURL")}
              value={
                type === TYPES[2]
                  ? initialValues.pageURL
                  : formik.values.pageURL
              }
              error={
                formik.touched.pageURL && formik.errors.pageURL ? true : false
              }
              helperText={ErrorMessage({ name: "pageURL" })}
              inputProps={{ readOnly: type === TYPES[2] }}
            />
            <Typography variant="body2">
              <span style={{ color: "gray" }}>Eg.&nbsp;</span>
              https://www.facebook.com/packtica/
            </Typography>
            <Box my={2}>
              <Divider />
            </Box>
            <Box display="flex">
              <FormGroup>
                <FormLabel component="legend">{getLang(lang,"label.CONFIGURATION")}</FormLabel>
                <FormControlLabel
                  disabled={type === TYPES[2]}
                  control={
                    <Checkbox
                      name="useSmallHeader"
                      size="small"
                      color="primary"
                      {...formik.getFieldProps("useSmallHeader")}
                      checked={
                        type === TYPES[2]
                          ? initialValues.useSmallHeader
                          : formik.values.useSmallHeader
                      }
                    />
                  }
                  label={getLang(lang,"label.USE_SMALL_HEADER")}
                />
                <FormControlLabel
                  disabled={type === TYPES[2]}
                  control={
                    <Checkbox
                      name="hideCoverPhoto"
                      size="small"
                      color="primary"
                      {...formik.getFieldProps("hideCoverPhoto")}
                      checked={
                        type === TYPES[2]
                          ? initialValues.hideCoverPhoto
                          : formik.values.hideCoverPhoto
                      }
                    />
                  }
                  label={getLang(lang,"label.HIDE_COVER_PHOTO")}
                />
                <FormControlLabel
                  disabled={type === TYPES[2]}
                  control={
                    <Checkbox
                      name="showFriendsFaces"
                      size="small"
                      color="primary"
                      {...formik.getFieldProps("showFriendsFaces")}
                      checked={
                        type === TYPES[2]
                          ? initialValues.showFriendsFaces
                          : formik.values.showFriendsFaces
                      }
                    />
                  }
                  label={getLang(lang,"label.SHOW_FRIEND_FACES")}
                />
              </FormGroup>
              <FormGroup>
                <FormLabel component="legend">{getLang(lang,"label.TABS")}</FormLabel>
                <FormControlLabel
                  disabled={type === TYPES[2]}
                  control={
                    <Checkbox
                      name="tabTimeline"
                      size="small"
                      color="primary"
                      {...formik.getFieldProps("tabsTimeline")}
                      checked={
                        type === TYPES[2]
                          ? initialValues.tabs.timeline
                          : formik.values.tabsTimeline
                      }
                    />
                  }
                  label={getLang(lang,"label.TIMELINE")}
                />
                <FormControlLabel
                  disabled={type === TYPES[2]}
                  control={
                    <Checkbox
                      name="tabEvents"
                      size="small"
                      color="primary"
                      {...formik.getFieldProps("tabsEvents")}
                      checked={
                        type === TYPES[2]
                          ? initialValues.tabs.events
                          : formik.values.tabsEvents
                      }
                    />
                  }
                  label={getLang(lang,"label.EVENTS")}
                />
                <FormControlLabel
                  disabled={type === TYPES[2]}
                  control={
                    <Checkbox
                      name="tabMessages"
                      size="small"
                      color="primary"
                      {...formik.getFieldProps("tabsMessages")}
                      checked={
                        type === TYPES[2]
                          ? initialValues.tabs.messages
                          : formik.values.tabsMessages
                      }
                    />
                  }
                  label={getLang(lang,"label.MESSAGES")}
                />
              </FormGroup>
            </Box>

            <Box mt={3}>
              <Typography variant="caption" color="textSecondary">
              <Typography style={{ color: "#DE350B", display: 'inline-block' }}>*</Typography> {getLang(lang,"label.REQUIRED_FIELD")}
              </Typography>
            </Box>
          </ContentWrapper>

          <ModalFooter
            handleDelete={handleDelete}
            showDelete={type !== TYPES[0]}
            handleClose={handleClose}
          />
        </form>
      )}
    </Formik>
  );
}
