import axios from "axios"

export default function createPointCut({
    amount,
    parentId,
    status,
    itemId,
    startAt,
    endAt
}) {
    return new Promise((resolve, reject) => {
        axios.post("/campaign/api/admin/items-setting",
            {
                amount,
                parentId,
                status,
                itemId,
                startAt,
                endAt
            })
            .then(response => resolve(response.data))
            .catch(error => reject(error.response.data))
    })
}