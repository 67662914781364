import React from "react";
import {
  Paper,
  Box,
  Button,
  makeStyles,
  Typography,
  Divider,
  useTheme,
  IconButton,
  useMediaQuery,
  darken
} from "@material-ui/core";
import QRCodeCredit from "../../component/panel/QRCodeCredit";
import QrCodeSvg from "assets/svg/qr-code.svg";
import BlobQrSvg from "assets/svg/blob-qr.svg";
import SmsCreditSvg from "assets/svg/sms-credit.svg";
import BlobSMSSvg from "assets/svg/blob-sms.svg";
import SubscriptionPlansSvg from "assets/svg/subscription-plans.svg";
import clsx from "clsx"
import { KeyboardBackspace as BackIcon } from '@material-ui/icons';
import { getLang } from "app/feature/constants";
import { useSelector } from "react-redux";

const useStyle = makeStyles(theme => ({
  root: {
    display: "grid",
    gridTemplateColumns: "1fr 330px",
    columnGap: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "1fr",
      gridTemplateRows: "1fr auto",
      gridRowGap: theme.spacing(2),
      rowGap: theme.spacing(2)
    }
  },
  sideBannerContainer: {
    [theme.breakpoints.down("md")]: {
      display: "grid",
      gridTemplateColumns: "repeat(2, 330px) auto",
      columnGap: theme.spacing(2)
    },
    [theme.breakpoints.down("xs")]: {
      display: "grid",
      gridTemplateColumns: "1fr",
      gridTemplateRows: "auto auto",
      gridRowGap: theme.spacing(2),
      rowGap: theme.spacing(2)
    }
  },
  indicator: {
    border: "0.5px solid",
    borderColor: theme.palette.primary.main
  },
  purchaseTypePanel: {
    display: "flex",
    justifyContent: "space-around",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column"
    }
  },
  purchaseTypeButton: {
    backgroundColor: "#6AAF68",
    "&:hover": {
      backgroundColor: darken("#6AAF68", 0.2)
    }
  },
  purchaseTypeWrapper: {
    textAlign: "center",
    width: "100%",
    padding: theme.spacing(4, 0)
  },
  purchaseTypeWrapperOpacity: {
    textAlign: "center",
    width: "100%",
    padding: theme.spacing(4, 0),
    opacity: 0.5
  },
  purchaseTypeIcon: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "1fr",
    height: 160,
    "& img": {
      margin: "auto",
      gridRow: "1/2",
      gridColumn: "1/2"
    }
  },
  adsBanner: {
    background: "linear-gradient(267.68deg, #351E76 -4.64%, #A242CF 103.04%)",
    color: "white",
    borderRadius: theme.spacing(0.5)
  }
}));

function PurchaseTypeCard({
  title,
  icon,
  handleClick = () => { },
  isComingSoon = false,
  isDisabled = false,
}) {
  const classes = useStyle();
  const lang = useSelector(state => state.constant.languages)
  return (
    <Box className={clsx(classes.purchaseTypeWrapper, {
      [classes.purchaseTypeWrapperOpacity]: isDisabled
    })}>
      <Typography variant="h6" style={{ fontWeight: "bold" }} color="primary">
        {title}
      </Typography>
      <Box my={3} className={classes.purchaseTypeIcon}>
        {icon}
      </Box>
      <Box px={6}>
        {
          isComingSoon
            ?
            (
              <Box pt={1}>
                <Typography variant="body2" color="textSecondary">
                  {getLang(lang, 'label.COMING_SOON')}
                </Typography>
              </Box>
            )
            :
            (
              <Button
                variant="contained"
                disableElevation
                size="large"
                color="primary"
                classes={{ root: classes.purchaseTypeButton }}
                fullWidth
                onClick={handleClick}
                disabled={isDisabled}
              >
                {getLang(lang, 'label.SELECT')}
              </Button>
            )
        }
      </Box>
    </Box>
  );
}

export default function MainPageComponent({
  hasQrCodeCreditPurchaseAccessRight,
  hasSmsCreditPurchaseAccessRight,
  handleCancel,
  handleSelect,
  types,
  activeType,
  isOwner = true,
  registrationType,
  lang
}) {
  const classes = useStyle();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const isSelected = activeType !== null;
  return (
    <Box>
      <Box mb={1} display="flex" alignItems="center" fontWeight="bold">
        {isSelected && activeType === types[0] && (
          <IconButton style={{ padding: 5 }} onClick={handleCancel}>
            <BackIcon color="primary" />
          </IconButton>)}
        <Typography variant="h6" color="primary">
          {getLang(lang, 'label.MARKET_PLACE')}
        </Typography>
      </Box>
      <Box className={classes.root}>
        <Box>
          {!isSelected && (
            <Paper elevation={0} className={classes.purchaseTypePanel}>
              <PurchaseTypeCard
                title={getLang(lang, 'label.QR_CODE_CREDIT')}
                handleClick={() => handleSelect(types[0])}
                isDisabled={!hasQrCodeCreditPurchaseAccessRight}
                icon={
                  <>
                    <img src={QrCodeSvg} alt="purchase_qr_code_credit" />
                    <img src={BlobQrSvg} alt="blob_purchase_qr_code_credit" />
                  </>
                }

              />
              <Divider
                orientation={isMobile ? "horizontal" : "vertical"}
                flexItem={!isMobile}
              />
              <PurchaseTypeCard
                title={getLang(lang, 'label.SMS_CREDIT')}
                isDisabled={!hasSmsCreditPurchaseAccessRight}
                isComingSoon={true}
                icon={
                  <>
                    <img src={SmsCreditSvg} alt="purchase_sms_credit" />
                    <img src={BlobSMSSvg} alt="blob_purchase_sms_credit" />
                  </>
                }

              />
              <Divider
                orientation={isMobile ? "horizontal" : "vertical"}
                flexItem={!isMobile}
              />
              <PurchaseTypeCard
                title={getLang(lang, 'label.SUBSCRIPTION_PLANS')}
                handleClick={() => handleSelect(types[2])}
                isDisabled={isOwner ? !(registrationType === 1 || registrationType === 4 || registrationType === 5) : true}
                icon={
                  <>
                    <img src={SubscriptionPlansSvg} alt="purchase_subs_plans" />
                  </>
                }

              />
            </Paper>
          )}
          {isSelected && activeType === types[0] && (
            <QRCodeCredit handleCancel={handleCancel} />
          )}
        </Box>

        {/* Kood side banner */}
        <Box className={classes.sideBannerContainer}>
          <Box p={2} mb={2} className={classes.adsBanner}>
            <Typography
              gutterBottom
              variant="subtitle1"
              style={{ fontWeight: "bold" }}
            >
              {getLang(lang, 'label.PROMOTION')}
            </Typography>
            <Typography variant="body2">
              {getLang(lang, 'paragraph.FREE_1000_SMS_CREDIT_WITH_YEARLY_PRO_PLAN')}
            </Typography>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="flex-end"
            >
              <Box mt={2} display="flex" flexDirection="column">
                <Typography variant="caption" color="textSecondary">
                  {getLang(lang, 'label.PROMOTION_PERIOD')}
                </Typography>
                <Typography variant="caption">
                  21 Dec 2020 to 21 Feb 2021
                </Typography>
              </Box>
              <Button
                variant="outlined"
                size="small"
                disableElevation
                color="inherit"
              >
                {getLang(lang, 'label.GET_IT_NOW')}
              </Button>
            </Box>
          </Box>
          <Paper elevation={0}>
            <Box p={6} textAlign="center">
              <Typography variant="h4" color="textSecondary">
                {getLang(lang, 'label.KOOD_ADS')}
              </Typography>
            </Box>
          </Paper>
        </Box>
      </Box>
    </Box>
  );
}
