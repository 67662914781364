import React, { useEffect, useState } from "react"
import { cloneDeep } from 'lodash'
import { useSelector } from "react-redux"
import { selectLoading } from 'modules/notification'
import RecallItemListPanelComponent from "./recallItemListPanel.component"
import { getRecallItemList } from "modules/stockflow/redux"
import { STOCKFLOW_DEALER_VIEW } from "lib/constants/accessRights"

export default function StockItemListPanelContainer() {
  const hasStockflowDealerViewAccessRight = useSelector(state => state.profile.accessRights.includes(STOCKFLOW_DEALER_VIEW))
  const lang = useSelector(state => state.constant.languages)
  const recallItems = useSelector(state => state.stockflowRecall.recallItems)
  const isFetching = useSelector(state => selectLoading(state, getRecallItemList.typePrefix))

  const [itemList, setItemList] = useState([])
  
  useEffect(() => {
    setItemList(cloneDeep(recallItems))
  }, [recallItems])

  const handleSearch = (values) => {
    if (values.keyword) {
      let keyword = values.keyword.toLowerCase()
      let filteredDealerList = []
    
      filteredDealerList = recallItems.reduce((filteredDealer, dealer) => {
        let tempDealer = cloneDeep(dealer)
        let found = false

        // filter by serial number in boxes
        let boxList = dealer.boxes.reduce((filtered, box) => {
          let list = box.products.reduce((list, product) => {
            let index = product.packages.findIndex(p => p.name.toLowerCase().includes(keyword))
            if (index >= 0) {
              list.push({
                ...product,
                packages: product.packages.filter(p => p.name.toLowerCase().includes(keyword))
              })
            }
            return list
          }, [])

          if (list.length) {
            filtered.push({
              ...box,
              products: list
            })
          } else {
            // search by box code if serial number not found
            if(box.name.toLowerCase().includes(keyword)) {
              filtered.push(box)
            }
          }
          return filtered
        }, [])

        if (boxList.length) {
          tempDealer.boxes = boxList
          found = true
        }

        // filter by serial number in packages
        let packageList = dealer.packages.reduce((filtered, product) => {
          let index = product.packages.findIndex(p => p.name.toLowerCase().includes(keyword))
          if (index >= 0) {
            filtered.push({
              ...product,
              packages: product.packages.filter(p => p.name.toLowerCase().includes(keyword))
            })
          }
          return filtered
        }, [])
        
        if (packageList.length) {
          tempDealer.packages = packageList
          found = true
        }

        if (found) {
          filteredDealer.push(tempDealer)
        }
        return filteredDealer
      }, [])

      setItemList(filteredDealerList)
    } else {
      setItemList(recallItems)
    }
  }
  
  return (
    <RecallItemListPanelComponent
    hasStockflowDealerViewAccessRight={hasStockflowDealerViewAccessRight}
      recallItems={itemList}
      isFetching={isFetching}
      handleSearch={handleSearch}
      lang={lang}
    />
  )
}