import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectLoading } from "modules/notification";
import ScanTableListPanelComponent from "./scanTableListPanel.component";
import { fetchClusterScanRecordListing } from "modules/dashboard/redux/action/dashboard.action";
import { generatePaginationOptions } from "lib/helper";
import { resetScanRecords } from "modules/dashboard/redux/slice/dashboard.slice";
import { exportClusterScanListing } from "app/api/dashboard/exportClusterScanListing.api";
import moment from "moment";

export default function ScanTableListPanelContainer({
  clusterBound,
  startDate,
  endDate,
  handleMarkerClick,
}) {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.constant.languages);
  const language = useSelector((state) => state.profile.language);
  const token = useSelector((state) => state.session.accessToken);
  const list = useSelector((state) => state.dashboard.scanRecords.list);
  const totalFiltered = useSelector(
    (state) => state.dashboard.scanRecords.totalFiltered
  );
  const isError = useSelector((state) => state.dashboard.scanRecords.isError);
  const isFetching = useSelector((state) =>
    selectLoading(state, fetchClusterScanRecordListing.typePrefix)
  );

  const selectedIds = useSelector((state) => state.dashboard.selectedId);
  const isSelectAll = useSelector((state) => state.dashboard.isSelectAll);
  const [paginationOptions, setPaginationOptions] = useState([]);
  const [tableFilter, setTableFilter] = useState({
    page: 0,
    rowsPerPage: 25,
    order: "desc",
    column: "created_at", // created_at|location
    status: "all",
    consumers: [],
  });

  const [isExporting, setIsExporting] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(resetScanRecords());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableFilter, clusterBound]);

  useEffect(() => {
    setPaginationOptions(generatePaginationOptions(totalFiltered));
  }, [totalFiltered]);

  const getData = () => {
    dispatch(
      fetchClusterScanRecordListing({
        length: tableFilter.rowsPerPage,
        start: tableFilter.page * tableFilter.rowsPerPage,
        order: tableFilter.order,
        order_by: tableFilter.column,
        consumers: tableFilter.consumers,
        status: tableFilter.status,
        latitude: [clusterBound.south, clusterBound.north],
        longitude: [clusterBound.west, clusterBound.east],
        start_date: startDate,
        end_date: endDate,
        uuids: selectedIds,
        isselectedall: isSelectAll,
      })
    );
  };

  const handleChangePage = (event, newPage) => {
    let newData = {
      ...tableFilter,
      page: newPage,
    };
    setTableFilter(newData);
  };

  const handleChangeRowsPerPage = (event) => {
    let newData = {
      ...tableFilter,
      page: 0,
      rowsPerPage: event.target.value,
    };
    setTableFilter(newData);
  };

  const handleFilter = (values) => {
    let newData = {
      ...tableFilter,
      page: 0,
      rowsPerPage: tableFilter.rowsPerPage,
      status: values.status,
      consumers: values.consumers,
    };

    setTableFilter(newData);
  };

  const resetFilter = () => {
    let newData = {
      ...tableFilter,
      page: 0,
      status: "all",
      consumers: [],
    };
    setTableFilter(newData);
  };

  const changeOrder = (orderBy, order) => {
    let newData = {
      ...tableFilter,
      order,
      column: orderBy,
    };
    setTableFilter(newData);
  };

  const exportListing = () => {
    setIsExporting(true);
    exportClusterScanListing
      .post({
        token,
        order: tableFilter.order,
        order_by: tableFilter.column,
        start_date: startDate,
        end_date: endDate,
        latitude: [clusterBound.south, clusterBound.north],
        longitude: [clusterBound.west, clusterBound.east],
        consumers: tableFilter.consumers,
        status: tableFilter.status,
      })
      .then((blob) => {
        saveFile(blob);
        setIsExporting(false);
      })
      .catch(() => {
        setIsExporting(false);
      });
  };

  const saveFile = async (blob) => {
    const a = document.createElement("a");
    let start = moment(startDate).format("YYMMDD");
    let end = moment(endDate).format("YYMMDD");
    a.download = `${start}_${end}_consumer_scan.csv`;
    a.href = URL.createObjectURL(blob);
    a.addEventListener("click", (e) => {
      setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
    });
    a.click();
  };

  return (
    <ScanTableListPanelComponent
      list={list}
      isFetching={isFetching}
      isError={isError}
      handleChangePage={handleChangePage}
      language={language}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      page={tableFilter.page}
      rowsPerPage={tableFilter.rowsPerPage}
      totalFiltered={totalFiltered}
      handleReload={getData}
      paginationOptions={paginationOptions}
      tableFilter={tableFilter}
      changeOrder={changeOrder}
      handleFilter={handleFilter}
      lang={lang}
      resetFilter={resetFilter}
      handleMarkerClick={handleMarkerClick}
      startDate={startDate}
      endDate={endDate}
      clusterBound={clusterBound}
      exportListing={exportListing}
      isExporting={isExporting}
    />
  );
}
