import React, { useState } from 'react'
import {
    Box,
    Divider,
    makeStyles,
    Tabs,
    Typography
} from '@material-ui/core'
import DefaultImage from "assets/img/img-default.png";
import CustomTab from 'components/tab';
import PropTypes from "prop-types";
import ProductPageSettingTab from '../../tab/productPageSettingTab';
import ComponentTab from '../../tab/componentTab';
import { getLang } from 'app/feature/constants';

const useStyle = makeStyles(theme => ({
    prodImage: {
        borderRadius: "50%",
        border: "1px solid",
        borderColor: theme.palette.divider,
        height: 54,
        width: 54,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
        "& img": {
            height: "100%",
            minHeight: "100%",
            minWidth: "100%",
            objectFit: "cover",
            flexShrink: 0
        }
    },
    prodName: {
        fontWeight: "bold",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden"
    },
    branch: {
        overflow: "hidden",
        fontWeight: "bold",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        display: "block"
    },
    tabRoot: {
        textTransform: "none"
    },
    indicator: {
        border: "0.5px solid",
        borderColor: theme.palette.primary.main,
        borderRadius: "8px 8px 0px 0px"
    },
    scroll: {
        width: "min-content"
    }
}))

function TabPanel({ children, value, index, ...other }) {
    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

export default function ComponentMenuPanelComponent({
    productInfo,
    branch,
    lang
}) {
    const classes = useStyle()
    const [activeIndex, setActiveIndex] = useState(0);

    const handleChangeTab = (event, index) => {
        setActiveIndex(index);
    };

    return (
        <Box style={{ backgroundColor: "white" }}>
            <Box display="flex" py={1} px={2} alignItems="center" flexWrap="wrap">
                <Box className={classes.prodImage}>
                    <img src={productInfo.picture || DefaultImage} alt="product" />
                </Box>
                <Box ml={2} overflow="hidden" maxWidth={190}>
                    <Typography variant="body2" className={classes.prodName}>
                        {productInfo.productName}
                    </Typography>
                    <Typography
                        variant="caption"
                        color="textSecondary"
                        className={classes.branch}
                    >
                        {branch}
                    </Typography>
                </Box>
            </Box>
            <Divider />
            <Box px={2}>
                <Tabs
                    value={activeIndex}
                    onChange={handleChangeTab}
                    aria-label="scrollable auto tabs example"
                    textColor="primary"
                    variant="scrollable"
                    indicatorColor="primary"
                    classes={{
                        root: classes.tabRoot,
                        indicator: classes.indicator,
                        scrollButtons: classes.scroll
                    }}
                >
                    <CustomTab
                        id={`simple-tab-0`}
                        {...{ "aria-controls": `simple-tabpanel-0` }}
                        label={
                            <Typography variant="body2" style={{ textTransform: "none" }}>
                                {getLang(lang,"label.COMPONENT")}
                            </Typography>
                        }
                    />
                    <CustomTab
                        id={`simple-tab-1`}
                        {...{ "aria-controls": `simple-tabpanel-1` }}
                        label={
                            <Typography variant="body2" style={{ textTransform: "none" }}>
                                {getLang(lang,"label.SETTING")}
                            </Typography>
                        }
                    />
                </Tabs>
            </Box>
            <Divider />
            <Box>
                <TabPanel value={activeIndex} index={0}>
                    <ComponentTab />
                </TabPanel>
                <TabPanel value={activeIndex} index={1}>
                    <ProductPageSettingTab />
                </TabPanel>
            </Box>
        </Box>
    )
}
