import React from "react";
import ModelTitleCard from './modelTitleCard.component';
import { useDispatch, useSelector } from "react-redux";
import { selectLoading } from "modules/notification";
import { getProductModelDetailData } from "modules/reporting/redux";
import moment from "moment";
import { useEffect } from "react";

export default function ModelTitleCardContainer({
    currentFilter,
    urlModelDetail,
    translate
}){

    const dispatch = useDispatch();


    // (1) get product category model data -- start
    const isFetchingProductModelDetailData = useSelector(state => selectLoading(state, getProductModelDetailData.typePrefix));
    const productModelDetailData = useSelector(state => state.reporting.product.productModelDetailData);
    // (1) get product category model data -- end
    
    useEffect(() => {
        dispatch(getProductModelDetailData({
            branch_uuid: currentFilter.branchUuid,
            model_uuid: currentFilter.modelUuid,
            period: currentFilter.period,
            start_date: moment(currentFilter.dateRange[0]).format('YYYY-MM-DD'),
            end_date: moment(currentFilter.dateRange[1]).format('YYYY-MM-DD'),
            url: urlModelDetail
        }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentFilter, dispatch])

    return (
        <ModelTitleCard 
            status={{
                isFetchingProductModelDetailData
            }}

            data={{
                productModelDetailData
            }}

            currentFilter={currentFilter}
            translate={translate}
        />
    )
}