import React from "react";
import {
  makeStyles,
  Box,
  Typography,
  InputAdornment,
  IconButton,
  Button,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons"
import { Formik } from 'formik';
import * as Yup from "yup";
import DefaultPhoto from "assets/img/defaultPhoto.png"
import InputTextField from 'components/input/inputTextField';
import Header from "../../components/header";
import Footer from "../../components/footer";
import { Skeleton } from '@material-ui/lab';
import SplashScreen from "modules/admin/components/screen/splash";
import { getLang } from "app/feature/constants";

const style = theme => ({
  mainContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh"
  },
  container: {
    padding: "40px 30px 40px 30px",
    backgroundColor: "#ffffff",
    borderRadius: "4px",
    marginBottom: "40px"
  },
  title: {
    fontWeight: "700",
    fontSize: "24px",
    fontFamily: "Montserrat"
  },
  defaultPhoto: {
    width: "48px",
    height: "48px"
  },
  email: {
    fontFamily: "Montserrat",
    fontWeight: 400,
    fontSize: "18px"
  },
  label: {
    fontFamily: "Open Sans",
  },
  root: {
    width: "100%",
  },
  inputRoot: {
    paddingTop: "8px!important",
    paddingBottom: "7px !important"
  },
  button: {
    width: "100%",
    borderRadius: "42px",
    textTransform: "capitalize",
    fontSize: "16px"
  },
  linkContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "40px"
  },
  link: {
    fontFamily: "Montserrat",
    fontWeight: 700,
    fontSize: "16px"
  }
});

const useStyle = makeStyles(style);

export default function NewPasswordPage({
  handleSubmit,
  isConfirmPasswordShow,
  isPasswordShown,
  handleClickShowPassword,
  handleMouseDownPassword,
  email,
  picture,
  isLoading,
  isValidate,
  lang
}) {
  const classes = useStyle();

  return (
    (!isLoading && isValidate) ? (
      <Box className={classes.mainContainer}>
        <Box style={{ width: "502px" }}>
          <Box style={{ marginBottom: "60px" }}>
            <Header justifyContent="center" />
          </Box>
          <Box className={classes.container}>
            <Box paddingBottom="30px">
              <Typography variant='h5' className={classes.title}>{getLang(lang, 'label.SET_NEW_PASSWORD')}</Typography>
            </Box>
            <Box style={{ display: "flex", alignItems: "center", marginBottom: "20px" }}>
              <Box style={{ marginRight: "8px" }}>
                <img src={picture === null || picture === "" ? DefaultPhoto : picture} alt="defaultPhoto" className={classes.defaultPhoto} />
              </Box>
              <Box>
                {isLoading ? (
                  <Skeleton variant='text' style={{ width: "300px" }} />
                ) : (
                  <Typography variant='body1' className={classes.email}>{email}</Typography>
                )}
              </Box>
            </Box>
            <Formik
              initialValues={{
                password: "",
                confirmPassword: ""
              }}

              validationSchema={Yup.object({
                password: Yup.string(),
                confirmPassword: Yup.string(),
              })}

              onSubmit={handleSubmit}
            >
              {(formik) => (
                <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
                  <Box style={{ paddingBottom: "20px " }}>
                    <Typography variant='body2' className={classes.label}>{getLang(lang, 'label.PASSWORD')}</Typography>
                    <InputTextField
                      className={classes.root}
                      variant="filled"
                      size="small"
                      margin="none"
                      type={isPasswordShown ? "text" : "password"}
                      InputProps={{
                        disableUnderline: true,
                        margin: "none",
                        style: {
                          borderRadius: "4px",
                          backgroundColor: "#ffffff",
                          border: "1px solid #e6e6e6",
                          boxShadow: "0px 0px 10px 2px rgba(118, 156, 214, 0.1)",
                        },
                        classes: { input: classes.inputRoot },
                        endAdornment: (
                          <InputAdornment position='end'>
                            <IconButton
                              aria-label='toggle password visibility'
                              onClick={() => handleClickShowPassword("password")}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {isPasswordShown ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                      placeholder={getLang(lang, 'placeholder.PASSWORD')}
                      {...formik.getFieldProps("password")}
                      error={formik.touched.password && formik.errors.password}
                    />
                  </Box>
                  <Box style={{ paddingBottom: "40px " }}>
                    <Typography variant='body2' className={classes.label}>{getLang(lang, 'label.CONFIRM_PASSWORD')}</Typography>
                    <InputTextField
                      className={classes.root}
                      variant="filled"
                      size="small"
                      margin="none"
                      type={isConfirmPasswordShow ? "text" : "password"}
                      InputProps={{
                        disableUnderline: true,
                        margin: "none",
                        style: {
                          borderRadius: "4px",
                          backgroundColor: "#ffffff",
                          border: "1px solid #e6e6e6",
                          boxShadow: "0px 0px 10px 2px rgba(118, 156, 214, 0.1)",
                        },
                        classes: { input: classes.inputRoot },
                        endAdornment: (
                          <InputAdornment position='end'>
                            <IconButton
                              aria-label='toggle password visibility'
                              onClick={() => handleClickShowPassword("confirmPassword")}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {isPasswordShown ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                      placeholder={getLang(lang, 'placeholder.PASSWORD')}
                      {...formik.getFieldProps("confirmPassword")}
                      error={formik.touched.confirmPassword && formik.errors.confirmPassword}
                    />
                  </Box>
                  <Box>
                    <Button
                      variant='contained'
                      type='submit'
                      size='large'
                      color='primary'
                      className={classes.button}
                    >
                      {getLang(lang, 'label.UPDATE_PASSWORD')}
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
          <Box>
            <Footer type="tnc" />
          </Box>
        </Box>
      </Box>
    ) : (
      <SplashScreen />
    )
  );
}