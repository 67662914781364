import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectLoading } from "modules/notification";
import ScanAgainstSuspendPanelComponent from "./scanAgainstSuspendPanel.component";
import { getScanAgainstSuspendedData } from "modules/dashboard/redux/action/dashboard.action";
import moment from "moment";

export default function ScanAgainstSuspendPanelContainer({ dateRange }) {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.constant.languages);
  const scanAgainstSuspendedData = useSelector(
    (state) => state.dashboard.scanAgainstSuspendedData
  );
  const isFetching = useSelector((state) =>
    selectLoading(state, getScanAgainstSuspendedData.typePrefix)
  );

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange]);

  const getData = () => {
    let param = {
      start_date: moment(dateRange[0]).format("YYYY-MM-DD"),
      end_date: moment(dateRange[1]).format("YYYY-MM-DD"),
    };
    dispatch(getScanAgainstSuspendedData(param));
  };

  return (
    <ScanAgainstSuspendPanelComponent
      handleReload={getData}
      lang={lang}
      startDate={dateRange[0]}
      endDate={dateRange[1]}
      data={scanAgainstSuspendedData}
      loading={isFetching}
    />
  );
}
