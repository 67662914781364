import { Box, Paper, Tab, TableCell, TableRow, Tabs, Typography, useTheme, withStyles } from "@material-ui/core";
import React, { useState } from "react";
import SwipeableViews from "react-swipeable-views";
import { ContentCard } from "../../layout";
import { DynamicTable } from "../../table";
import { hasFlag } from "country-flag-icons";
import { UnknownFlagIcon } from "modules/reporting/assets/svg";
import * as FlagIcons from'country-flag-icons/react/3x2';
import moment from "moment";
import { drilldownPageSelection, getCountryName } from "modules/reporting/utils/helper";
import AgeGenderPanel from "../age-gender-panel";
import { flexGapStyle, fontTextStyle, iconStyle, tableStyle } from "modules/reporting/styles";
import { Link } from "react-router-dom";

const CustomizeTabs = withStyles({
    indicator: {
        display: "flex",
        justifyContent: "center",
        backgroundColor: "transparent",
        // position: "relative",
        "& > span": {
            // position: "absolute",
            // left: "40%",
            width: "60%",
            backgroundColor: "#344054",
        }
    }
})((props) => <Tabs {...props} TabIndicatorProps={{children: <span />}} />);


const CustomizeTab = withStyles((theme) => ({
    root: {
        padding: "0px",
        textTransform: 'none',
        color: '#344054',
        fontWeight: 500,
        fontSize: "14px",
        '&:focus': {
            opacity: 1,
        },
        minWidth: "80px",
        "& > span": {
            alignItems: "center"
        }
    },
}))((props) => <Tab disableRipple {...props} />);

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
            <Box>
                {children}
            </Box>
            )}
        </div>
    );
}

export default function DemographicPanelComponent({
    status,
    data,
    currentFilter,
    sortCity,
    paginationCity,
    columnsCity,
    sortCountry,
    paginationCountry,
    columnsCountry,
    pageType,
    translate
}) {
    const theme = useTheme();
    const iconClasses = iconStyle();
    const tableClasses = tableStyle();
    const fontClasses = fontTextStyle();
    const flexGapClasses = flexGapStyle();

    // function handle switch tabs
    const [tabValue, setTabValue] = useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleTabChangeIndex = (index) => {
        setTabValue(index);
    };

    function getFlagIcon(countryCode) {

        if (!hasFlag(countryCode)) {
            return (
            <Box className={iconClasses.flag__icon}>
                <UnknownFlagIcon/>
            </Box>)
        }

        const FlagIcon = FlagIcons[countryCode];
        return (
            <Box className={iconClasses.flag__icon}>
                <FlagIcon  title={countryCode} />
            </Box>
        )
    }
    
    const renderCityData = (data) => {

        let rows = [];
        if(Object.keys(data).length === 0 || !data.isSuccess){
            return rows;
        }
        
        let imageProps = JSON.stringify(data);
        imageProps = JSON.parse(imageProps);

        let no_iteration = paginationCity.rowsPerPage * paginationCity.page + 1;
        imageProps.rows.forEach((item, index) => {
            let row = item.id ? (
                <TableRow key={index}>
                    <TableCell className={tableClasses.table__cell} style={{width: "20px"}}>
                        <Typography className={fontClasses.text__table_cell}>{no_iteration++}</Typography>
                    </TableCell>
                    <TableCell
                        align={item.align || "left"}
                        className={tableClasses.table__cell}
                        style={{
                            width:"580px"
                        }}
                    >
                        <Link 
                            target="_blank" rel="noopener noreferrer"
                            className={fontClasses.text__hyperlink}
                            to={() => {

                                let filterProperties = {};

                                if(currentFilter.countryCode)
                                    filterProperties.countryCode = currentFilter.countryCode;
                                
                                if(currentFilter.cityUuid)
                                    filterProperties.cityUuid = currentFilter.cityUuid;
                                
                                if(currentFilter.branchUuid)
                                    filterProperties.branchUuid = currentFilter.branchUuid;

                                if(currentFilter.period)
                                    filterProperties.period = currentFilter.period;

                                if(currentFilter.dateRange){
                                    filterProperties.start = moment(currentFilter.dateRange[0]).format('YYYY-MM-DD');
                                    filterProperties.end = moment(currentFilter.dateRange[1]).format('YYYY-MM-DD');
                                }

                                if(currentFilter.productUuid)
                                    filterProperties.productUuid = currentFilter.productUuid;

                                if(currentFilter.categoryUuid)
                                    filterProperties.categoryUuid = currentFilter.categoryUuid;

                                if(currentFilter.modelUuid)
                                    filterProperties.modelUuid = currentFilter.modelUuid;
                                
                                if(currentFilter.brandUuid)
                                    filterProperties.brandUuid = currentFilter.brandUuid;
                                
                                if(item.country_code)
                                    filterProperties.countryCode = item.country_code.toLowerCase();

                                if(item.id)
                                    filterProperties.cityUuid = item.id;

                                let url = drilldownPageSelection(filterProperties, pageType, true);
                                return url;
                            }}
                        >
                            <span>{item.name}</span>
                        </Link>
                    </TableCell>
                    <TableCell align={item.align || "right"} className={tableClasses.table__cell} style={{minWidth:"130px"}}>
                        <Typography className={fontClasses.text__table_cell}>{item.scan}</Typography>
                    </TableCell>
                    <TableCell align={item.align || "right"} className={tableClasses.table__cell} style={{minWidth:"130px"}}>
                        <Typography className={fontClasses.text__table_cell}>{item.suspended}</Typography>
                    </TableCell>
                    {/* <TableCell align={item.align || "left"} className={tableClasses.table__cell} style={{minWidth:"170px"}}>
                        <Box display="flex" flexDirection="column" className={flexGapClasses.global__flexGap3}>
                            {
                                item.sales ?
                                item.sales.map((currentSale, u) => {
                                    return (
                                        <Box key={u} display="flex" className={flexGapClasses.global__flexGap3} justifyContent="space-between">
                                            <Typography className={fontClasses.text__muted}>{currentSale.currency}</Typography>
                                            <Typography className={fontClasses.text__table_cell}>{currentSale.total_sales}</Typography>
                                        </Box>
                                    )
                                })
                                : null
                            }
                        </Box>
                    </TableCell> */}
                </TableRow>
            ) : (
                <TableRow className={tableClasses.table__total_row}>
                    <TableCell colSpan={2} component="th" scope="row" className={[tableClasses.table__total_cell,tableClasses.table__cell]} style={{width:"580px"}}>
                        {translate("label.TOTAL")}
                    </TableCell>
                    <TableCell align="right" className={[tableClasses.table__total_cell,tableClasses.table__cell]} style={{width:"110px"}}>{item.scan}</TableCell>
                    <TableCell align="right" className={[tableClasses.table__total_cell,tableClasses.table__cell]} style={{width:"110px"}}>{item.suspended}</TableCell>
                    {/* <TableCell className={[tableClasses.table__total_cell,tableClasses.table__cell]} style={{width:"170px"}}>
                        <Box
                            display="flex"
                            flexDirection="column"
                            className={flexGapClasses.global__flexGap3}
                        >
                            {
                                item.sales ?
                                item.sales.map((currentSale, u) => {
                                    return (
                                        <Box
                                            display="flex"
                                            className={flexGapClasses.global__flexGap3}
                                            justifyContent="space-between"
                                            key={u}
                                        >
                                            <Typography className={fontClasses.text__muted}>{currentSale.currency}</Typography>
                                            <Typography className={fontClasses.text__table_cell}>{currentSale.total_sales}</Typography>
                                        </Box>
                                    )
                                }) :
                                null
                            }
                        </Box>
                    </TableCell> */}
                </TableRow>
            )

            
            item.render = row;
        })
        
        return imageProps;
    }

    const renderCountryData = (data) => {

        let rows = [];
        if(Object.keys(data).length === 0 || !data.isSuccess){
            return rows;
        }

        let imageProps = JSON.stringify(data);
        imageProps = JSON.parse(imageProps);

        let no_iteration = paginationCountry.rowsPerPage * paginationCountry.page + 1;
        imageProps.rows.forEach((item, index) => {
            let row = item.country_code ? (
                <TableRow key={index}>
                    <TableCell className={tableClasses.table__cell} style={{width: "20px"}}>
                        <Typography className={fontClasses.text__table_cell}>{no_iteration++}</Typography>
                    </TableCell>
                    <TableCell
                        align={item.align || "left"}
                        className={tableClasses.table__cell}
                        style={{
                            width: "580px"
                        }}
                    >
                        <Box
                            display="flex"
                            justifyContent="flex-start"
                            alignContent="center"
                            className={flexGapClasses.global__flexGap3}
                        >
                            {getFlagIcon(item.country_code)}
                            <Link 
                                target="_blank" rel="noopener noreferrer"
                                className={fontClasses.text__hyperlink}
                                to={() => {

                                    let filterProperties = {};

                                    if(currentFilter.countryCode)
                                        filterProperties.countryCode = currentFilter.countryCode;
                                    
                                    if(currentFilter.cityUuid)
                                        filterProperties.cityUuid = currentFilter.cityUuid;
                                    
                                    if(currentFilter.branchUuid)
                                        filterProperties.branchUuid = currentFilter.branchUuid;

                                    if(currentFilter.period)
                                        filterProperties.period = currentFilter.period;

                                    if(currentFilter.dateRange){
                                        filterProperties.start = moment(currentFilter.dateRange[0]).format('YYYY-MM-DD');
                                        filterProperties.end = moment(currentFilter.dateRange[1]).format('YYYY-MM-DD');
                                    }

                                    if(currentFilter.productUuid)
                                        filterProperties.productUuid = currentFilter.productUuid;

                                    if(currentFilter.categoryUuid)
                                        filterProperties.categoryUuid = currentFilter.categoryUuid;
    
                                    if(currentFilter.modelUuid)
                                        filterProperties.modelUuid = currentFilter.modelUuid;
                                    
                                    if(currentFilter.brandUuid)
                                        filterProperties.brandUuid = currentFilter.brandUuid;

                                    if(item.country_code)
                                        filterProperties.countryCode = item.country_code.toLowerCase();

                                    let url = drilldownPageSelection(filterProperties, pageType, true);
                                    return url;
                                }}
                            >
                                {getCountryName(item.country_code)}
                            </Link>
                        </Box>
                    </TableCell>
                    <TableCell align={item.align || "right"} className={tableClasses.table__cell} style={{minWidth:"130px"}}>
                        <Typography className={fontClasses.text__table_cell}>{item.scan}</Typography>
                    </TableCell>
                    <TableCell align={item.align || "right"} className={tableClasses.table__cell} style={{minWidth:"130px"}}>
                        <Typography className={fontClasses.text__table_cell}>{item.suspended}</Typography>
                    </TableCell>
                    {/* <TableCell align={item.align || "left"} className={tableClasses.table__cell} style={{minWidth:"170px"}}>
                        <Box display="flex" flexDirection="column" className={flexGapClasses.global__flexGap3}>
                            {
                                item.sales ?
                                item.sales.map((currentSale, u) => {
                                    return (
                                        <Box key={u} display="flex" className={flexGapClasses.global__flexGap3} justifyContent="space-between">
                                            <Typography className={fontClasses.text__muted}>{currentSale.currency}</Typography>
                                            <Typography className={fontClasses.text__table_cell}>{currentSale.total_sales}</Typography>
                                        </Box>
                                    )
                                })
                                : null
                            }
                        </Box>
                    </TableCell> */}
                </TableRow>
            ) : (
                <TableRow className={tableClasses.table__total_row} >
                    <TableCell colSpan={2} component="th" scope="row" className={[tableClasses.table__total_cell,tableClasses.table__cell]} style={{width:"580px"}}>
                        {translate("label.TOTAL")}
                    </TableCell>
                    <TableCell align="right" className={[tableClasses.table__total_cell,tableClasses.table__cell]} style={{width:"110px"}}>{item.scan}</TableCell>
                    <TableCell align="right" className={[tableClasses.table__total_cell,tableClasses.table__cell]} style={{width:"110px"}}>{item.suspended}</TableCell>
                    {/* <TableCell className={[tableClasses.table__total_cell,tableClasses.table__cell]} style={{width:"170px"}}>
                        <Box
                            display="flex"
                            flexDirection="column"
                            className={flexGapClasses.global__flexGap3}
                        >
                            {
                                item.sales ? 
                                item.sales.map((currentSale, u) => {
                                    return (
                                        <Box
                                            display="flex"
                                            className={flexGapClasses.global__flexGap3}
                                            justifyContent="space-between"
                                            key={u}
                                        >
                                            <Typography className={fontClasses.text__muted}>{currentSale.currency}</Typography>
                                            <Typography className={fontClasses.text__table_cell}>{currentSale.total_sales}</Typography>
                                        </Box>
                                    )
                                })

                                : null
                            }
                        </Box>
                    </TableCell> */}

                </TableRow>
            )

            
            item.render = row;
        })
        
        return imageProps;
    }

    return (
        <ContentCard>
            <Box marginBottom="24px" display="flex" justifyContent="space-between" alignContent="center">
                <Typography className={fontClasses.text__card_title}>
                    {translate("label.DEMOGRAPHIC")}
                </Typography>
            </Box>
            {/* tabs for switching between country and city/town */}

            {
                
                currentFilter.hasOwnProperty('cityUuid') && currentFilter.hasOwnProperty('countryCode') ?
                    (
                        currentFilter.countryCode ? 
                            (!currentFilter.cityUuid ?
                                <>
                                    <CustomizeTabs
                                        value={tabValue}
                                        onChange={handleTabChange}
                                    >
                                        <CustomizeTab label={translate("label.TOWN_CITY")} />
                                    </CustomizeTabs>
        
                                    <SwipeableViews
                                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                        index={tabValue}
                                        onChangeIndex={handleTabChangeIndex}
                                    >
                                        <TabPanel value={tabValue} index={0} dir={theme.direction}>
                                            <Box padding="8px 1px">
                                                <DynamicTable 
                                                    sort={sortCity}
                                                    pagination={paginationCity}
                                                    columns={columnsCity}
                                                    data={renderCityData(data.citySalesData)}
                                                    status={status.isFetchingCitySalesData}
                                                    component={Paper}
                                                    translate={translate}
                                                />
                                            </Box>
                                        </TabPanel>  
                                    </SwipeableViews>
                                </>
                                : null
                            )
                        :
                        <>
                            <CustomizeTabs
                                value={tabValue}
                                onChange={handleTabChange}
                            >
                                <CustomizeTab label={translate("label.COUNTRY")} />
                                <CustomizeTab label={translate("label.TOWN_CITY")} /> 
                            </CustomizeTabs>

                            <SwipeableViews
                                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                index={tabValue}
                                onChangeIndex={handleTabChangeIndex}
                            >
                                <TabPanel value={tabValue} index={0} dir={theme.direction}>
                                    <Box padding="8px 1px">
                                        <DynamicTable 
                                            sort={sortCountry}
                                            pagination={paginationCountry}
                                            columns={columnsCountry}
                                            data={renderCountryData(data.countrySalesData)}
                                            status={status.isFetchingCountrySalesData}
                                            component={Paper}
                                            translate={translate}
                                        />
                                    </Box>
                                </TabPanel> 
                                <TabPanel value={tabValue} index={1} dir={theme.direction}>
                                    <Box padding="8px 1px">
                                        <DynamicTable 
                                            sort={sortCity}
                                            pagination={paginationCity}
                                            columns={columnsCity}
                                            data={renderCityData(data.citySalesData)}
                                            status={status.isFetchingCitySalesData}
                                            component={Paper}
                                            translate={translate}
                                        />
                                    </Box>
                                </TabPanel>
                            </SwipeableViews>
                        </>

                    )
                :
                <>
                    <CustomizeTabs
                        value={tabValue}
                        onChange={handleTabChange}
                    >
                        <CustomizeTab label={translate("label.COUNTRY")} />
                        <CustomizeTab label={translate("label.TOWN_CITY")} /> 
                    </CustomizeTabs>

                    <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={tabValue}
                        onChangeIndex={handleTabChangeIndex}
                    >
                        <TabPanel value={tabValue} index={0} dir={theme.direction}>
                            <Box padding="8px 1px">
                                <DynamicTable 
                                    sort={sortCountry}
                                    pagination={paginationCountry}
                                    columns={columnsCountry}
                                    data={renderCountryData(data.countrySalesData)}
                                    status={status.isFetchingCountrySalesData}
                                    translate={translate}
                                />
                            </Box>
                        </TabPanel> 

                        <TabPanel value={tabValue} index={1} dir={theme.direction}>
                            <Box padding="8px 1px">
                                <DynamicTable 
                                    sort={sortCity}
                                    pagination={paginationCity}
                                    columns={columnsCity}
                                    data={renderCityData(data.citySalesData)}
                                    status={status.isFetchingCitySalesData}
                                    translate={translate}
                                />
                            </Box>
                        </TabPanel>
                    </SwipeableViews>
                </>
                
            }

            <AgeGenderPanel 
                status={status}
                data={data}

                translate={translate}
            />
        </ContentCard>
    )
}