import axios from "axios";

export function getStockSummary(parameters) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/analytic/api/v1/stock/get-stock-summary`, {
        view_by: parameters.view_by,
        length: parameters.length,
        start: parameters.start,
        month: parameters.month,
        year: parameters.year,
        country: parameters.country,
        branch_uuid: parameters.branch_id,
        product_uuid: parameters.product_id,
        order: parameters.order,
        order_by: parameters.order_by,
      })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data));
  });
}
