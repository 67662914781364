import { getApiLang } from "app/feature/constants";
import { setLoading, addAlert } from "modules/notification";
import {
  getRequestSendList,
  getRequestDetail,
  getRequestReceiveList,
  getRequestReceiverDropdown,
  getRequestSenderDropdown,
  approveRequestTransfer,
  rejectApproveRequestTransfer,
  getRequestStockList,
  getRequestTransferRequestBranches,
  getRequestTransferTargetBranches,
  getRequestTransferAddressList,
  addRequestTransferAddress,
  updateRequestTransferAddress,
  createRequestTransfer,
} from "../action/stockflowRequestTransfer.action";

const getRequestSendListMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getRequestSendList.pending.type:
        dispatch(
          setLoading({ id: getRequestSendList.typePrefix, state: true })
        );
        break;
      case getRequestSendList.fulfilled.type:
        dispatch(
          setLoading({ id: getRequestSendList.typePrefix, state: false })
        );
        break;
      case getRequestSendList.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({ id: getRequestSendList.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_GET_REQUEST_TRANSFER_LIST"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const getRequestReceiveListMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getRequestReceiveList.pending.type:
        dispatch(
          setLoading({ id: getRequestReceiveList.typePrefix, state: true })
        );
        break;
      case getRequestReceiveList.fulfilled.type:
        dispatch(
          setLoading({ id: getRequestReceiveList.typePrefix, state: false })
        );
        break;
      case getRequestReceiveList.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({ id: getRequestReceiveList.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_GET_REQUEST_TRANSFER_LIST"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const getRequestDetailMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getRequestDetail.pending.type:
        dispatch(setLoading({ id: getRequestDetail.typePrefix, state: true }));
        break;
      case getRequestDetail.fulfilled.type:
        dispatch(setLoading({ id: getRequestDetail.typePrefix, state: false }));
        break;
      case getRequestDetail.rejected.type:
        const lang = getState().constant.languages;
        dispatch(setLoading({ id: getRequestDetail.typePrefix, state: false }));
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_GET_REQUEST_TRANSFER_DETAIL"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const getRequestSenderDropdownMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getRequestSenderDropdown.pending.type:
        dispatch(
          setLoading({
            id: getRequestSenderDropdown.typePrefix,
            state: true,
          })
        );
        break;
      case getRequestSenderDropdown.fulfilled.type:
        dispatch(
          setLoading({
            id: getRequestSenderDropdown.typePrefix,
            state: false,
          })
        );
        break;
      case getRequestSenderDropdown.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: getRequestSenderDropdown.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

const getRequestReceiverDropdownMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getRequestReceiverDropdown.pending.type:
        dispatch(
          setLoading({
            id: getRequestReceiverDropdown.typePrefix,
            state: true,
          })
        );
        break;
      case getRequestReceiverDropdown.fulfilled.type:
        dispatch(
          setLoading({
            id: getRequestReceiverDropdown.typePrefix,
            state: false,
          })
        );
        break;
      case getRequestReceiverDropdown.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: getRequestReceiverDropdown.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

const approveRequestTransferMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case approveRequestTransfer.pending.type:
        dispatch(
          setLoading({
            id: approveRequestTransfer.typePrefix,
            state: true,
          })
        );
        break;
      case approveRequestTransfer.fulfilled.type:
        dispatch(
          setLoading({
            id: approveRequestTransfer.typePrefix,
            state: false,
          })
        );
        break;
      case approveRequestTransfer.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: approveRequestTransfer.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_APPROVE_TRANSFER"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const rejectApproveRequestTransferMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case rejectApproveRequestTransfer.pending.type:
        dispatch(
          setLoading({
            id: rejectApproveRequestTransfer.typePrefix,
            state: true,
          })
        );
        break;
      case rejectApproveRequestTransfer.fulfilled.type:
        dispatch(
          setLoading({
            id: rejectApproveRequestTransfer.typePrefix,
            state: false,
          })
        );
        break;
      case rejectApproveRequestTransfer.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: rejectApproveRequestTransfer.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_REJECT_TRANSFER"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const getRequestStockListMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getRequestStockList.pending.type:
        dispatch(
          setLoading({
            id: getRequestStockList.typePrefix,
            state: true,
          })
        );
        break;
      case getRequestStockList.fulfilled.type:
        dispatch(
          setLoading({
            id: getRequestStockList.typePrefix,
            state: false,
          })
        );
        break;
      case getRequestStockList.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: getRequestStockList.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

const getRequestTransferRequestBranchesMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getRequestTransferRequestBranches.pending.type:
        dispatch(
          setLoading({
            id: getRequestTransferRequestBranches.typePrefix,
            state: true,
          })
        );
        break;
      case getRequestTransferRequestBranches.fulfilled.type:
        dispatch(
          setLoading({
            id: getRequestTransferRequestBranches.typePrefix,
            state: false,
          })
        );
        break;
      case getRequestTransferRequestBranches.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: getRequestTransferRequestBranches.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

const getRequestTransferTargetBranchesMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getRequestTransferTargetBranches.pending.type:
        dispatch(
          setLoading({
            id: getRequestTransferTargetBranches.typePrefix,
            state: true,
          })
        );
        break;
      case getRequestTransferTargetBranches.fulfilled.type:
        dispatch(
          setLoading({
            id: getRequestTransferTargetBranches.typePrefix,
            state: false,
          })
        );
        break;
      case getRequestTransferTargetBranches.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: getRequestTransferTargetBranches.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

const getRequestTransferAddressListMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getRequestTransferAddressList.pending.type:
        dispatch(
          setLoading({
            id: getRequestTransferAddressList.typePrefix,
            state: true,
          })
        );
        break;
      case getRequestTransferAddressList.fulfilled.type:
        dispatch(
          setLoading({
            id: getRequestTransferAddressList.typePrefix,
            state: false,
          })
        );
        break;
      case getRequestTransferAddressList.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: getRequestTransferAddressList.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

const addRequestTransferAddressMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case addRequestTransferAddress.pending.type:
        dispatch(
          setLoading({
            id: addRequestTransferAddress.typePrefix,
            state: true,
          })
        );
        break;
      case addRequestTransferAddress.fulfilled.type:
        dispatch(
          setLoading({
            id: addRequestTransferAddress.typePrefix,
            state: false,
          })
        );
        break;
      case addRequestTransferAddress.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: addRequestTransferAddress.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

const updateRequestTransferAddressMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case updateRequestTransferAddress.pending.type:
        dispatch(
          setLoading({
            id: updateRequestTransferAddress.typePrefix,
            state: true,
          })
        );
        break;
      case updateRequestTransferAddress.fulfilled.type:
        dispatch(
          setLoading({
            id: updateRequestTransferAddress.typePrefix,
            state: false,
          })
        );
        break;
      case updateRequestTransferAddress.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: updateRequestTransferAddress.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

const createRequestTransferMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case createRequestTransfer.pending.type:
        dispatch(
          setLoading({
            id: createRequestTransfer.typePrefix,
            state: true,
          })
        );
        break;
      case createRequestTransfer.fulfilled.type:
        dispatch(
          setLoading({
            id: createRequestTransfer.typePrefix,
            state: false,
          })
        );
        break;
      case createRequestTransfer.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: createRequestTransfer.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

export default [
  getRequestSendListMiddleware,
  getRequestReceiveListMiddleware,
  getRequestDetailMiddleware,
  getRequestSenderDropdownMiddleware,
  getRequestReceiverDropdownMiddleware,
  approveRequestTransferMiddleware,
  rejectApproveRequestTransferMiddleware,
  getRequestStockListMiddleware,
  getRequestTransferRequestBranchesMiddleware,
  getRequestTransferTargetBranchesMiddleware,
  getRequestTransferAddressListMiddleware,
  addRequestTransferAddressMiddleware,
  updateRequestTransferAddressMiddleware,
  createRequestTransferMiddleware,
];
