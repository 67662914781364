import { addAlert, setLoading } from "../../../notification";
import { getApiLang } from "../../../../app/feature/constants";
import {
  getBranchDropdown,
  getBranchStockSummary,
  getCountryDropdown,
  getMonthlyStockSummary,
  getProductDropdown,
  getProductStockSummary,
} from "../action";

const getMonthlyStockSummaryMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getMonthlyStockSummary.pending.type:
        dispatch(
          setLoading({ id: getMonthlyStockSummary.typePrefix, state: true })
        );
        break;
      case getMonthlyStockSummary.fulfilled.type:
        dispatch(
          setLoading({ id: getMonthlyStockSummary.typePrefix, state: false })
        );
        break;
      case getMonthlyStockSummary.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({ id: getMonthlyStockSummary.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

const getBranchStockSummaryMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getBranchStockSummary.pending.type:
        dispatch(
          setLoading({ id: getBranchStockSummary.typePrefix, state: true })
        );
        break;
      case getBranchStockSummary.fulfilled.type:
        dispatch(
          setLoading({ id: getBranchStockSummary.typePrefix, state: false })
        );
        break;
      case getBranchStockSummary.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({ id: getBranchStockSummary.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

const getProductStockSummaryMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getProductStockSummary.pending.type:
        dispatch(
          setLoading({ id: getProductStockSummary.typePrefix, state: true })
        );
        break;
      case getProductStockSummary.fulfilled.type:
        dispatch(
          setLoading({ id: getProductStockSummary.typePrefix, state: false })
        );
        break;
      case getProductStockSummary.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({ id: getProductStockSummary.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

const getCountryDropdownMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getCountryDropdown.pending.type:
        dispatch(
          setLoading({
            id: getCountryDropdown.typePrefix,
            state: true,
          })
        );
        break;

      case getCountryDropdown.fulfilled.type:
        dispatch(
          setLoading({
            id: getCountryDropdown.typePrefix,
            state: false,
          })
        );
        break;

      case getCountryDropdown.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: getCountryDropdown.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

const getBranchDropdownMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getBranchDropdown.pending.type:
        dispatch(
          setLoading({
            id: getBranchDropdown.typePrefix,
            state: true,
          })
        );
        break;

      case getBranchDropdown.fulfilled.type:
        dispatch(
          setLoading({
            id: getBranchDropdown.typePrefix,
            state: false,
          })
        );
        break;

      case getBranchDropdown.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: getBranchDropdown.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

const getProductDropdownMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getProductDropdown.pending.type:
        dispatch(
          setLoading({
            id: getProductDropdown.typePrefix,
            state: true,
          })
        );
        break;

      case getProductDropdown.fulfilled.type:
        dispatch(
          setLoading({
            id: getProductDropdown.typePrefix,
            state: false,
          })
        );
        break;

      case getProductDropdown.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: getProductDropdown.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

export default [
  getBranchDropdownMiddleware,
  getCountryDropdownMiddleware,
  getProductDropdownMiddleware,
  getMonthlyStockSummaryMiddleware,
  getBranchStockSummaryMiddleware,
  getProductStockSummaryMiddleware,
];
