import {
  getCampaignTngDisbursementList,
  getCreditAlertInfo,
  getCreditBalanceReloadLog,
  getCreditOverview,
  updateCreditAlertInfo,
} from "../action";
import { setLoading, addAlert } from "modules/notification";
import { getApiLang } from "app/feature/constants";

export const getCampaignTngDisbursementListMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages;

    switch (type) {
      case getCampaignTngDisbursementList.pending.type:
        dispatch(
          setLoading({
            id: getCampaignTngDisbursementList.typePrefix,
            state: true,
          })
        );
        break;

      case getCampaignTngDisbursementList.fulfilled.type:
        dispatch(
          setLoading({
            id: getCampaignTngDisbursementList.typePrefix,
            state: false,
          })
        );
        break;

      case getCampaignTngDisbursementList.rejected.type:
        dispatch(
          setLoading({
            id: getCampaignTngDisbursementList.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

export const getCreditBalanceReloadLogMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages;

    switch (type) {
      case getCreditBalanceReloadLog.pending.type:
        dispatch(
          setLoading({
            id: getCreditBalanceReloadLog.typePrefix,
            state: true,
          })
        );
        break;

      case getCreditBalanceReloadLog.fulfilled.type:
        dispatch(
          setLoading({
            id: getCreditBalanceReloadLog.typePrefix,
            state: false,
          })
        );
        break;

      case getCreditBalanceReloadLog.rejected.type:
        dispatch(
          setLoading({
            id: getCreditBalanceReloadLog.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

export const getCreditAlertInfoMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages;

    switch (type) {
      case getCreditAlertInfo.pending.type:
        dispatch(
          setLoading({
            id: getCreditAlertInfo.typePrefix,
            state: true,
          })
        );
        break;

      case getCreditAlertInfo.fulfilled.type:
        dispatch(
          setLoading({
            id: getCreditAlertInfo.typePrefix,
            state: false,
          })
        );
        break;

      case getCreditAlertInfo.rejected.type:
        dispatch(
          setLoading({
            id: getCreditAlertInfo.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

export const updateCreditAlertInfoMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages;

    switch (type) {
      case updateCreditAlertInfo.pending.type:
        dispatch(
          setLoading({
            id: updateCreditAlertInfo.typePrefix,
            state: true,
          })
        );
        break;

      case updateCreditAlertInfo.fulfilled.type:
        dispatch(
          setLoading({
            id: updateCreditAlertInfo.typePrefix,
            state: false,
          })
        );
        break;

      case updateCreditAlertInfo.rejected.type:
        dispatch(
          setLoading({
            id: updateCreditAlertInfo.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

export const getCreditOverviewMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages;

    switch (type) {
      case getCreditOverview.pending.type:
        dispatch(
          setLoading({
            id: getCreditOverview.typePrefix,
            state: true,
          })
        );
        break;

      case getCreditOverview.fulfilled.type:
        dispatch(
          setLoading({
            id: getCreditOverview.typePrefix,
            state: false,
          })
        );
        break;

      case getCreditOverview.rejected.type:
        dispatch(
          setLoading({
            id: getCreditOverview.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

export default [
  getCampaignTngDisbursementListMiddleware,
  getCreditBalanceReloadLogMiddleware,
  getCreditAlertInfoMiddleware,
  updateCreditAlertInfoMiddleware,
  getCreditOverviewMiddleware,
];
