import axios from "axios";

const getAllBranches = async ({ length, start, column, order, search }) => {
    const body = {
        length,
        start,
        column,
        order,
        search
    }

    const response = await axios.get(
        "/account/api/v1/nodes", { params: body }
    );
    
    return response.data;
};

export default getAllBranches;