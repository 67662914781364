import React, { useState } from 'react'
import BonanzaListingTableComponent from './bonanzaListingTable.component'
import { useDispatch, useSelector } from 'react-redux'
import { getBonanzaPoint, resetBonanzaPoint, setType } from 'modules/loyalty-program/redux'
import { hideBackdrop, showBackdrop } from "modules/backdrop";
import { AddBonanzaPointDialog } from '../../dialog'
import { unwrapResult } from "@reduxjs/toolkit";
import ConfirmationDialog from 'components/dialog/confirmation/confirmationDialog.component'
import { loyaltyType } from 'modules/loyalty-program/constants'
import { getLang } from 'app/feature/constants';

export default function BonanzaListingTableContainer({
  hasBonanzaAddAccessRight,
  hasBonanzaEditAccessRight,
  hasBonanzaActivateOrDeactivateAccessRight,
  data,
  isFetching,
  page,
  search,
  rowsPerPage,
  totalRecords,
  handleChangePage,
  handleChangeRowsPerPage,
  handleCreate,
  handleStatusChange,
  handleReload,
  paginationOptions
}) {
  const dispatch = useDispatch()
  const [isModalOpen, setModalOpen] = useState(false);
  const [confirmationDialogModal] = useState(false);
  const isLoadingBonanzaError = useSelector(state => state.loyaltyProgram.isLoadingBonanzaError)
  const lang = useSelector(state => state.constant.languages)
  const language = useSelector(state => state.profile.language)

  const handleView = id => {
    dispatch(setType({ index: loyaltyType.bonanza, type: "view" }));
    dispatch(showBackdrop());

    dispatch(getBonanzaPoint(id))
      .then(unwrapResult)
      .then(() => {
        dispatch(hideBackdrop())
        setModalOpen(true)
      })
  }

  const handleClose = () => {
    dispatch(resetBonanzaPoint())
    setModalOpen(false);
  }

  return (
    <>
      <BonanzaListingTableComponent
        hasBonanzaAddAccessRight={hasBonanzaAddAccessRight}
        hasBonanzaEditAccessRight={hasBonanzaEditAccessRight}
        hasBonanzaActivateOrDeactivateAccessRight={hasBonanzaActivateOrDeactivateAccessRight}
        data={data}
        isFetching={isFetching}
        page={page}
        search={search}
        rowsPerPage={rowsPerPage}
        totalRecords={totalRecords}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleCreate={handleCreate}
        language={language}
        handleView={handleView}
        handleStatusChange={handleStatusChange}
        handleReload={handleReload}
        isLoadingBonanzaError={isLoadingBonanzaError}
        paginationOptions={paginationOptions}
        lang={lang}
      />
      <AddBonanzaPointDialog
        isOpen={isModalOpen}
        handleClose={handleClose}
      />
      <ConfirmationDialog
        isOpen={confirmationDialogModal}
        handleProceed={handleClose}
        type="success"
        title={getLang(lang,"label.BONANZA_ADDED_SUCCESSFULLY")}
        rejectOption={false}
        proceedLabel={getLang(lang,"label.ALRIGHT")}
      />
    </>
  )
}