import axios from "axios";

const updateBatchScanVerifyStatus = ({ uuid, status }) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`/serialnumber/api/v1/generate/status/verify-update/${uuid}`, {
        status,
      })
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

export default updateBatchScanVerifyStatus;
