import { getDefaultMiddleware } from "@reduxjs/toolkit";
import modPublicMdl from "modules/public/redux/middleware";
import modPrivateMdl from "modules/private/redux/middleware";
import modAccountMdl from "modules/account/redux/middleware";
import modAdminMdl from "modules/admin/redux/middleware";
import modProfileMdl from "modules/profile/redux/middleware";
import modSerialNumberMdl from "modules/serial-number/redux/middleware";
import modProductMgmtMdl from "modules/product-mgmt/redux/middleware";
import modCounterfeitReportMdl from "modules/counterfeit-report/redux/middleware";
import { billingMiddleware as modBillingMdl } from "modules/billing/redux";
import { marketplaceMiddleware as modMarketplaceMdl } from "modules/store/redux";
import modDashboardMdl from "modules/dashboard/redux/middleware";

import modReportingMdl from "modules/reporting/redux/middleware"; // am
import modConsumerMdl from "modules/consumer/redux/middleware";
import modSurveyMdl from "modules/survey/redux/middleware";
import modWarrantyMdl from "modules/warranty/redux/middleware";
import { branchMiddleware as modBranchMdl } from "modules/branch/redux";
import { groupMiddleware as modGroupMdl } from "modules/group/redux";
import { employeeMiddleware as modEmployeeMdl } from "modules/employee/redux";
import { roleAccessRightMiddleware as modRoleAccessRightMdl } from "modules/role-access-right/redux";
import { luckyDrawMiddleware as modLuckyDrawMdl } from "modules/lucky-draw/redux";
import { instantLuckyDrawMiddleware as modInstantLuckyDrawMdl } from "modules/instant-lucky-draw/redux";
// import { loyaltyProgramMiddleware as modLoyaltyProgramMdl} from "modules/loyalty-program/redux"
// import { redemptionCenterMiddleware as modRedemptionCenterMdl } from "modules/redemption-center/redux";
import { organisationStructureMiddleware } from "modules/organisation-structure/redux/organisationStructure.mdl";
import modTrackcoMdl from "modules/trackco/redux/middleware";
import modLoyaltyProgramMdl from "modules/loyalty-program/redux/middleware";
// import { organisationStructureMiddleware } from "modules/organisation-structure/redux/organisationStructure.mdl";
// core feature middleware
import { s3Middleware } from "../feature";
import modStockflowDealerMdl from "modules/stockflow/redux/middleware";
import modStockSummaryMdl from "modules/stock-summary/redux/middleware";

const middleware = getDefaultMiddleware().concat([
  ...s3Middleware,
  ...modPublicMdl,
  ...modPrivateMdl,
  ...modAccountMdl,
  ...modAdminMdl,
  ...modProfileMdl,
  ...modMarketplaceMdl,
  ...modBillingMdl,
  ...modProductMgmtMdl,
  ...modSerialNumberMdl,
  ...modDashboardMdl,
  ...modCounterfeitReportMdl,
  ...modConsumerMdl,
  ...modSurveyMdl,
  ...organisationStructureMiddleware,
  ...modWarrantyMdl,
  ...modBranchMdl,
  ...modGroupMdl,
  ...modEmployeeMdl,
  ...modRoleAccessRightMdl,
  ...modLuckyDrawMdl,
  ...modInstantLuckyDrawMdl,
  ...modLoyaltyProgramMdl,
  // ...modRedemptionCenterMdl,
  ...modTrackcoMdl,
  ...modStockflowDealerMdl,
  ...modReportingMdl, // am
  ...modStockSummaryMdl,
]);

export default middleware;
