const API_HOST = process.env.REACT_APP_API_HOST_URL;

const saveFile = async (blob, filename) => {
  const a = document.createElement("a");

  a.download = filename;
  a.href = URL.createObjectURL(blob);
  a.addEventListener("click", (e) => {
    setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
  });
  a.click();
};

const exportCreditReport = async ({ id, token }) => {
  let response = await fetch(
    `${API_HOST}/campaignV2/customer/company/creditReportExport?id=${id}`,
    {
      method: "Get",
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Expose-Headers": "Content-Disposition",
      },
    }
  );

  const disposition = response.headers.get("content-disposition");
  const filename = disposition
    ? disposition.split("filename=")[1].replace(/["]/g, "")
    : "campaign_disbursement.csv";

  let blob = await response.blob();

  saveFile(blob, filename);
};

export default exportCreditReport;
