import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { ROOT, COUNTERFEIT_DETAIL } from "./config/counterfeit-report.route";
import { MainPage, CounterfeitReportDetail } from "./pages";
import {
  counterfeitReportReducer,
  setCounterfeitReportList,
  setCounterfeitDetail,
  setCounterfeitDetailRefCode,
  resetCounterfeitDetail
} from "./redux";

export default function Modulestore() {
  return (
    <Switch>
      <Route exact path={ROOT} component={MainPage} />
      <Route
        exact
        path={COUNTERFEIT_DETAIL}
        component={CounterfeitReportDetail}
      />
      <Route path="*">
        <Redirect to="/admin/invalid" />
      </Route>
    </Switch>
  );
}

export {
  ROOT,
  counterfeitReportReducer,
  setCounterfeitReportList,
  setCounterfeitDetail,
  setCounterfeitDetailRefCode,
  resetCounterfeitDetail
};
