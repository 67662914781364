import React from "react";
import {
  Box,
  Button,
  makeStyles,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Checkbox,
  Chip,
  Link
} from "@material-ui/core";
import {
  StyledTableCell,
  StyledTableRow,
  StickyRightTableCell,
  StickyLeftTableCell,
} from "components/table";
import { SkeletonTableRowStickyLeftRight } from "components/skeleton/skeleton.component";
import { ActionButton } from "components/button";
import { TablePaginationActions } from "components/tablePagination";
import RefreshTable from "components/table/refreshTable";
import clsx from "clsx";
import { REWARD_STATUS } from "modules/stockflow/constants";
import moment from "moment";
import SelectAllCheckBox from 'components/selectAllCheckbox';
import { convertToBigNum } from "lib/generalUtility";
import { getLang } from "app/feature/constants";
import "moment/min/locales.min";

const ROOT = "/admin/stockflow";

const useStyle = makeStyles((theme) => ({
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    borderCollapse: "separate",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
    },
    "& th,td": {
      fontSize: ".75rem",
      borderRight: "1px solid rgba(58, 77, 84, 0.1)",
      padding: theme.spacing(1, 2, 1, 2),
    },
    "& th": {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightBold,
    },
    "& th:last-child, td:last-child": {
      width: 37,
      borderRight: "0px",
    },
    "& th th": {
      borderBottom: "none !important",
    },
    "& td td": {
      borderBottom: "none !important",
    },
  },
  tableContainer: {
    maxHeight: 500,
    overflowY: "auto",
  },
  emptyContainer: {
    backgroundColor: theme.palette.background.paper,
    textAlign: "center",
  },
  button: {
    display: "flex",
    alignItems: "center",
    padding: 0,
    minWidth: 0,
    marginLeft: theme.spacing(1),
  },
  rewardPhoto: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #E4E7EC",
    width: "24px",
    height: "24px",
    marginRight: "0.75em",
    "& img": {
      height: "24px",
      width: "24px"
    },
  },
  hidden: {
    display: "none",
  },
  show: {
    display: "flex",
  },
  actionContainer: {
    justifyContent: "center",
  },
  paperRoot: {
    width: "300px",
  },
  rewardTableRow: {
    display: "flex",
    alignItems: "center"
  },
  ellipsisText: {
    maxWidth: "18em",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: "0.75rem"
  },
  tierIcon: {
    borderRadius: "3px",
    marginRight: "0.75em",
    paddingLeft: "0.15em",
    paddingRight: "0.15em",
    display: "flex",
    alignItems: "center"
  },
  tierLabel: {
    color: "#FFFFFF",
    fontSize: "0.6rem"
  },
  selectAll: {
    '&.Mui-checked': {
      color: "#FDB022",
    },
  },
  selectOne: {
    '&.Mui-checked': {
      color: "#6AAF68",
    },
  }
}));

export default function RewardListTableComponent({
  hasStockflowEditAccessRight,
  hasStockflowAddAccessRight,
  rows,
  rowsPerPage,
  paginationOptions,
  language,
  page,
  totalFiltered,
  isFetching,
  isError,
  isSelectAll,
  selectedUUIDs,
  isSearchResult,
  handleReload,
  handleViewDetail,
  handleChangePage,
  handleChangeRowPerPage,
  handleUpdateRewardStatus,
  handleDeleteReward,
  handleCreate,
  handleSelectReward,
  handleSelectAvailable,
  handleDeselectAll,
  handleSelectAll,
  lang
}) {
  const classes = useStyle();

  const selectAllItems = [
    {
      label: `${getLang(lang, 'label.SELECT_AVAILABLE_REWARDS', { available: rows.length > rowsPerPage ? rowsPerPage : rows.length })}`,
      action: () => handleSelectAvailable(),
    },
    {
      label: getLang(lang, 'label.SELECT_ALL_REWARDS'),
      action: () => handleSelectAll(),
    },
    {
      label: getLang(lang, 'label.DESELECT_ALL_REWARDS'),
      action: () => handleDeselectAll(),
      divider: true,
    },
  ];

  return (
    <>
      {!isFetching && isError && <RefreshTable handleReload={handleReload} />}
      <Box>
        <TableContainer>
          <Table
            className={classes.table}
            aria-label="reward table"
            size="small"
            stickyHeader
          >
            <TableHead style={{ height: "45px" }}>
              <TableRow>
                <StickyLeftTableCell>
                  <StyledTableCell padding="checkbox">
                    <SelectAllCheckBox
                      actionItems={selectAllItems}
                      disabled={isFetching}
                      isSelectAll={isSelectAll}
                      selectedIdLength={selectedUUIDs.length}
                      totalFiltered={totalFiltered}
                    />
                  </StyledTableCell>
                </StickyLeftTableCell>
                <StyledTableCell style={{ minWidth: "24em" }}>
                  {getLang(lang, 'label.REWARD_NAME')}
                </StyledTableCell>
                <StyledTableCell style={{ minWidth: "4em" }}>
                  {getLang(lang, 'label.POINTS')}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, 'label.AVAILABLE')}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, 'label.TOTAL_REDEMPTION')}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, 'label.PENDING_REDEMPTION')}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, 'label.VALIDITY_DATE')}
                </StyledTableCell>
                <StyledTableCell style={{ minWidth: "18em" }}>
                  {getLang(lang, 'label.BRANCH')}
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    width: "14rem",
                    borderRight: "",
                  }}
                >
                  {getLang(lang, 'label.STATUS')}
                </StyledTableCell>
                <StickyRightTableCell>
                  <StyledTableCell
                    style={{ width: "67px", textAlign: "center" }}
                  >
                    {getLang(lang, 'label.ACTION')}
                  </StyledTableCell>
                </StickyRightTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isFetching
                ? [...new Array(5)].map((v, index) => (
                  <SkeletonTableRowStickyLeftRight key={index} columnCount={10} />
                ))
                : rows.map((row, index) => {
                  const actionItems = [];

                  actionItems.push({
                    label: hasStockflowEditAccessRight ? getLang(lang, 'label.VIEW_EDIT') : getLang(lang, 'label.VIEW'),
                    action: () => handleViewDetail(row.uuid)
                  });

                  if (hasStockflowEditAccessRight) {
                    // actionItems.push({
                    //   label: "Edit Incentive Point",
                    //   action: () => handleEditPointDialog(row.uuid)
                    // });
                    if (row.status.toLowerCase() === "active") {
                      actionItems.push({
                        label: getLang(lang, 'label.DEACTIVATE'),
                        action: () => handleUpdateRewardStatus(row.uuid, false),
                      });
                    }
                    if (row.status.toLowerCase() === "inactive") {
                      actionItems.push({
                        label: getLang(lang, 'label.ACTIVATE'),
                        action: () => handleUpdateRewardStatus(row.uuid, true),
                      });
                    }
                    actionItems.push({
                      label: getLang(lang, 'label.DELETE'),
                      action: () => handleDeleteReward(row.uuid),
                      divider: true,
                      errorText: true,
                      disabled: moment(row.validityStartDateTime).isBefore(moment()),
                      tooltip: moment(row.validityStartDateTime).isBefore(moment()) ? getLang(lang, 'tooltips.PUBLISHED_REWARD_CANNOT_DELETE') : null,
                    });
                  }

                  return (
                    <StyledTableRow
                      key={row.uuid}
                      hover
                      role="checkbox"
                      tabIndex={-1}
                    >
                      <StickyLeftTableCell>
                        <StyledTableCell padding="checkbox" style={{ paddingLeft: "13px" }}>
                          <Checkbox
                            className={clsx({
                              [classes.selectAll]: isSelectAll,
                              [classes.selectOne]: row.isSelected && isSelectAll === false
                            })}
                            checked={row.isSelected}
                            onChange={(event) =>
                              handleSelectReward(index, event.target.checked)
                            }
                          />
                        </StyledTableCell>
                      </StickyLeftTableCell>
                      <StyledTableCell>
                        <Box className={classes.rewardTableRow}>
                          <Box className={classes.rewardPhoto}>
                            <img src={row.coverImageUrl} alt={row.name} />
                          </Box>
                          <Link 
                            href={`${ROOT}/reward-center/reward/${row.uuid}`}
                            color="secondary"
                            onClick={(e) => {
                              handleViewDetail(row.uuid)
                              e.preventDefault();
                              return false;
                            }}
                          >
                            <Typography className={classes.ellipsisText}>{row.name}</Typography>
                          </Link>
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell>
                        <span>{convertToBigNum(row.point)}</span>
                      </StyledTableCell>
                      <StyledTableCell>
                        <span>{row.available.toLocaleString("en-US")}</span>
                      </StyledTableCell>
                      <StyledTableCell>
                        <span>{row.totalRedemptionCount.toLocaleString("en-US")}</span>
                      </StyledTableCell>
                      <StyledTableCell>
                        <span>{row.pendingRedemptionCount.toLocaleString("en-US")}</span>
                      </StyledTableCell>
                      <StyledTableCell>
                        <span>{moment(row.validityStartDateTime).format("lll")} - {moment(row.validityEndDateTime).format("lll")}</span>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Box style={{ display: "flex", flexWrap: "wrap" }}>
                          {row.isAllBranch ? (
                            <span>{getLang(lang, 'label.ALL')}</span>
                          ) : (row.branch.map((branchName) => (
                            <>
                              <Box
                                style={{
                                  marginRight: "4px",
                                  marginBottom: "4px",
                                }}
                              >
                                <Chip
                                  key={index}
                                  label={branchName}
                                  size="small"
                                  style={{
                                    backgroundColor: "#E4E7EC",
                                    fontSize: "0.75rem",
                                  }}
                                />
                              </Box>
                            </>
                          )))}
                        </Box>
                        <Typography className={classes.ellipsisText}>{row.branchName}</Typography>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Typography
                          style={{
                            color: REWARD_STATUS.find(({ code }) => code === row.status.toLowerCase()).color,
                            fontSize: "0.75rem"
                          }}
                        >
                          {/* {REWARD_STATUS.find(({ code }) => code === row.status.toLowerCase()).text} */}
                          {row.status === "active" ? getLang(lang, 'label.ACTIVATED') : getLang(lang, 'label.DEACTIVATED')}
                          {/* QUICK FIX FOR LANGUAGES TRANSLATION */}
                        </Typography>
                      </StyledTableCell>
                      <StickyRightTableCell>
                        <StyledTableCell style={{ width: "67px" }}>
                          <div
                            className={clsx(classes.actionContainer, classes.show)}
                          >
                            <ActionButton
                              actionItems={actionItems}
                              popperStyle={{ zIndex: 999 }}
                            />
                          </div>
                        </StyledTableCell>
                      </StickyRightTableCell>
                    </StyledTableRow>
                  )
                })
              }
            </TableBody>
          </Table>
        </TableContainer>
        {!isFetching && !isError && rows.length !== 0 &&
          <TablePagination
            style={{
              backgroundColor: "#fff",
              boxShadow: "0px -1px 0px rgba(58, 77, 84, 0.15)",
            }}
            ActionsComponent={TablePaginationActions}
            rowsPerPageOptions={paginationOptions}
            component="div"
            count={totalFiltered}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowPerPage}
            labelRowsPerPage={getLang(lang, 'label.ROWS_PER_PAGE')}
            labelDisplayedRows={({ from, to, count }) => `${from.toLocaleString("en-US")}-${to.toLocaleString("en-US")} ${getLang(lang, 'label.OF')} ${count.toLocaleString("en-US")}`}
          />

        }
      </Box>
      {!isFetching && !isError && rows.length === 0 && (
        <Box>
          <Box py={9} className={classes.emptyContainer}>
            <Typography variant="body1" style={{ paddingBottom: "16px" }}>
              {isSearchResult
                ? getLang(lang, 'message.info.NO_REWARD_FOUND')
                : getLang(lang, 'message.info.YOU_DONT_HAVE_REWARD')}
            </Typography>
            {hasStockflowAddAccessRight && (
              <Button
                onClick={handleCreate}
                variant="contained"
                disableElevation
                color="primary"
              >
                {getLang(lang, 'label.ADD_REWARD')}
              </Button>
            )}
          </Box>
        </Box>
      )}
    </>
  )
}