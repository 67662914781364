import { createSlice } from "@reduxjs/toolkit";
import DefaultImg from "assets/img/img-default.png";
import DefaultProfile from "assets/img/defaultPhoto.png";
import {
  STOCKFLOWREWARDCENTER,
  getAllRewardListing,
  updateRewardStatus,
  getRewardDetails,
  getRewardBranchDropdown,
  getAdminRewardBranchDropdown,
  getRedemptionList,
  getRedemptionDetails,
} from "../action/stockflowRewardCenter.action";


const initialState = {
  rewardList: {
    totalRecords: 0,
    totalFiltered: 0,
    totalLoaded: 0,
    data: [],
    isSelectAll: false,
    selectedUUIDs: [],
    isError: false
  },
  rewardDetails: {
    coverImageURL: "",
    imageURL: [],
    branch: [],
    uuid: "",
    name: "",
    description: "",
    tncUrl: "",
    tncText: {
      value: "<p></p>",
      data: []
    },
    tncType: "",
    displayStartDateTime: "",
    displayEndDateTime: "",
    validityStartDateTime: "",
    validityEndDateTime: "",
    point: 0,
    redemptionLimit: 0,
    totalRedemptionCount: 0,
    pendingRedemptionCount: 0,
    quantity: 0,
    available: 0,
    status: "",
    isError: false
  },
  dropdowns: {
    branchDropdownData: [],
  },
  formType: "view",
  redemptionList: {
    totalRecords: 0,
    totalFiltered: 0,
    totalLoaded: 0,
    data: [],
    isError: false
  },
  redemptionDetail: {
    uuid: "",
    orderId: "",
    orderDatetime: "",
    status: "",
    balanceBefore: 0,
    balanceAfter: 0,
    pointDeducted: 0,
    distributor: {
      uuid: "",
      name: "",
      phone: "",
      picture: null,
      email: ""
    },
    respondDatetime: null,
    respondBy: null,
    remarks: null
  }
};

const stockflowRewardCenterSlice = createSlice({
  name: STOCKFLOWREWARDCENTER,
  initialState,
  reducers: {
    setFormType(state, action) {
      const { payload } = action;
      state.formType = payload;
    },
    setRewardTnc(state, action) {
      const { payload } = action;
      state.rewardDetails.tncText = payload;
    },
    resetRewardList(state) {
      state.rewardList = initialState.rewardList
    },
    resetRewardBranchDropdownData(state) {
      state.dropdowns.branchDropdownData = initialState.dropdowns.branchDropdownData;
    },
    resetRewardDetails(state) {
      state.rewardDetails = initialState.rewardDetails;
    },
    changeSelectedReward(state, action) {
      const { payload } = action;
      state.rewardList.data[payload.index].isSelected = payload.isSelected;
      if((!state.rewardList.isSelectAll && payload.isSelected) || (state.rewardList.isSelectAll && !payload.isSelected)) {
        state.rewardList.selectedUUIDs = [...state.rewardList.selectedUUIDs, state.rewardList.data[payload.index].uuid];
      }
      else {
        state.rewardList.selectedUUIDs = state.rewardList.selectedUUIDs.filter((uuid) => uuid !== state.rewardList.data[payload.index].uuid);
      }
    },
    changeBulkSelectedAllReward(state, action) {
      const { payload } = action;

      state.rewardList.data.forEach((program) => {
        program.isSelected = payload.value
      });
      state.rewardList.isSelectAll = payload.value;
      state.rewardList.selectedUUIDs = [];
    },
    changeBulkSelectedAvailableReward(state, action) {
      const { payload } = action;

      state.rewardList.data.forEach((program) => {
        program.isSelected = payload.value
        if (((!state.rewardList.isSelectAll && payload.value) || (state.rewardList.isSelectAll && !payload.value))) {
          if (state.rewardList.selectedUUIDs.findIndex((uuid) => uuid === program.uuid) === -1) {
            state.rewardList.selectedUUIDs = [...state.rewardList.selectedUUIDs, program.uuid];
          }
        }
        else {
          state.rewardList.selectedUUIDs = state.rewardList.selectedUUIDs.filter((uuid) => uuid !== program.uuid);
        }
      })
    }
  },
  extraReducers: {
    [getAllRewardListing.pending]: (state) => {
      state.rewardList = initialState.rewardList
    },
    [getAllRewardListing.fulfilled]: (state, action) => {
      const { payload } = action;
      const temp = [];
      payload.data.forEach((item) => {
        let selectedState = !state.rewardList.isSelectAll ? 
          state.rewardList.selectedUUIDs.findIndex((uuid) => uuid === item.uuid) !== -1 : 
          state.rewardList.selectedUUIDs.findIndex((uuid) => uuid === item.uuid) === -1;
        temp.push({
          uuid: item.uuid,
          name: item.name,
          point: item.point,
          available: item.available,
          totalRedemptionCount: item.total_redemption_count,
          pendingRedemptionCount: item.pending_redemption_count,
          validityStartDateTime: item.validity_start_datetime,
          validityEndDateTime: item.validity_end_datetime,
          branch: item.branch,
          isAllBranch: item.is_all_branch,
          status: item.status.toLowerCase(),
          createdDateTime: item.created_datetime,
          coverImageUrl: item.cover_image_url ?? DefaultImg,
          isSelected: selectedState
        });
        return null;
      });
      state.rewardList.data = temp;
      state.rewardList.totalRecords = payload.recordsTotal;
      state.rewardList.totalFiltered = payload.recordsFiltered;
      state.rewardList.totalLoaded = payload.recordsLoaded;
      state.rewardList.isError = false;
    },
    [getAllRewardListing.rejected]: (state) => {
      state.rewardList.data = [];
      state.rewardList.totalRecords = 0;
      state.rewardList.totalFiltered = 0;
      state.rewardList.totalLoaded = 0;
      state.rewardList.isError = true;
    },
    [updateRewardStatus.fulfilled]: (state, action) => {
      const payload = action.payload.data;
      const index = state.rewardList.data.findIndex(({ uuid }) => uuid === payload.uuid);
      if (index > -1) {
        state.rewardList.data[index].status = (payload.status.toLowerCase());
      }
    },
    [getRewardDetails.pending]: (state) => {
      state.rewardDetails = initialState.rewardDetails
    },
    [getRewardDetails.rejected]: (state) => {
      state.rewardDetails = {
        ...initialState.rewardDetails,
        isError: true
      }
    },
    [getRewardDetails.fulfilled]: (state, action) => {
      const payload = action.payload.data.data;
      state.rewardDetails.uuid = payload.uuid;
      state.rewardDetails.name = payload.name;
      state.rewardDetails.branch = payload.branch;
      state.rewardDetails.coverImageUrl = payload.cover_image_url;
      state.rewardDetails.imageUrl = payload.image_url;
      state.rewardDetails.description = payload.description;
      state.rewardDetails.tncType = payload.tnc_type;
      state.rewardDetails.tncUrl = payload.tnc_url;
      state.rewardDetails.displayStartDateTime = payload.display_start_datetime;
      state.rewardDetails.displayEndDateTime = payload.display_end_datetime;
      state.rewardDetails.validityStartDateTime = payload.validity_start_datetime;
      state.rewardDetails.validityEndDateTime = payload.validity_end_datetime;
      state.rewardDetails.point = payload.point;
      state.rewardDetails.redemptionLimit = payload.redemption_limit;
      state.rewardDetails.totalRedemptionCount = payload.total_redemption_count;
      state.rewardDetails.pendingRedemptionCount = payload.pending_redemption_count;
      state.rewardDetails.quantity = payload.quantity;
      state.rewardDetails.available = payload.available;
      state.rewardDetails.status = payload.status;
      state.isError = false;
    },
    [getRewardBranchDropdown.fulfilled]: (state, action) => {
      const payload = action.payload.data;

      let temp = [];
      payload.map((item, index) => {
        temp.push({
          nodeId: item.node_id,
          name: item.name,
          searchName: item.name.toLowerCase(),
          index: index
        });
        return null;
      })
      state.dropdowns.branchDropdownData = temp;
    },
    [getAdminRewardBranchDropdown.fulfilled]: (state, action) => {
      const payload = action.payload.data;

      let temp = [];
      payload.map((item, index) => {
        temp.push({
          nodeId: item.node_id,
          name: item.name,
          searchName: item.name.toLowerCase(),
          index: index
        });
        return null;
      })
      state.dropdowns.branchDropdownData = temp;
    },
    [getRedemptionList.fulfilled]: (state, action) => {
      const { payload } = action;
      const temp = []

      payload.data.map((item, index) => {
        temp.push({
          name: item.distribution_target.name,
          orderDate: item.order_datetime,
          orderId: item.order_id,
          status: item.status,
          uuid: item.uuid
        })
        return null;
      })

      state.redemptionList.data = temp;
      state.redemptionList.totalRecords = payload.recordsTotal;
      state.redemptionList.totalFiltered = payload.recordsFiltered;
      state.redemptionList.totalLoaded = payload.recordsLoaded;
      state.redemptionList.isError = false
    },
    [getRedemptionList.rejected]: (state) => {
      state.redemptionList.data = [];
      state.redemptionList.totalRecords = 0;
      state.redemptionList.totalFiltered = 0;
      state.redemptionList.totalLoaded = 0;
      state.redemptionList.isError = true;
    },
    [getRedemptionDetails.fulfilled]: (state, action) => {
      const { payload } = action;

      state.redemptionDetail.uuid = payload.data.uuid;
      state.redemptionDetail.orderId = payload.data.order_id;
      state.redemptionDetail.orderDatetime = payload.data.order_datetime;
      state.redemptionDetail.status = payload.data.status;
      state.redemptionDetail.balanceBefore = payload.data.balance_before;
      state.redemptionDetail.balanceAfter = payload.data.balance_after;
      state.redemptionDetail.pointDeducted = payload.data.point_deducted;
      state.redemptionDetail.distributor.uuid = payload.data.distribution_target.uuid;
      state.redemptionDetail.distributor.name = payload.data.distribution_target.name;
      state.redemptionDetail.distributor.email = payload.data.distribution_target.email;
      state.redemptionDetail.distributor.phone = payload.data.distribution_target.phone;
      state.redemptionDetail.distributor.picture = payload.data.distribution_target.picture ?? DefaultProfile;
      state.redemptionDetail.respondDatetime = payload.data.respond_datetime;
      state.redemptionDetail.respondBy = payload.data.respond_by;
      state.redemptionDetail.remarks = payload.data.remarks;
    }
  }
});

export const {
  setFormType,
  setRewardTnc,
  resetRewardList,
  resetRewardBranchDropdownData,
  resetRewardDetails,
  resetRewardDetailsForm,
  changeSelectedReward,
  changeBulkSelectedAllReward,
  changeBulkSelectedAvailableReward
} = stockflowRewardCenterSlice.actions;

export const stockflowRewardCenterReducer = stockflowRewardCenterSlice.reducer