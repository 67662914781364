import axios from "axios"

const url = "/distributor/admin/api/v1/inventory/dealer/products"
export function getAllDealerProductsListing (parameters) {
  return new Promise((resolve, reject) => {
    axios.post(url, {
      length: parameters.length,
      start: parameters.start,
      order: parameters.order,
      dealer: parameters.dealer,
      filter: {
        search: parameters.filter.search,
        search_by: parameters.filter.search_by,
      }
    })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data))
  });
};