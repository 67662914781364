/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useCallback, useLayoutEffect } from "react";
import SingleCheckoutProductAccordionComponent from "./singleCheckoutProductAccordion.component";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  getCheckoutProductDetail,
  resetProductSnList,
} from "modules/stockflow/redux";
import { selectLoading } from "modules/notification";

export default function SingleCheckoutProductAccordionContainer({
  product,
  lang,
  selectedProduct,
  selectViewItem,
  boxUuid,
  id,
}) {
  const dispatch = useDispatch();
  const isFetching = useSelector((state) =>
    selectLoading(state, getCheckoutProductDetail.typePrefix)
  );
  const totalFiltered = useSelector(
    (state) => state.stockflowCheckout.productSnList.totalFiltered
  );
  const snList = useSelector(
    (state) => state.stockflowCheckout.productSnList.list
  );
  const isError = useSelector(
    (state) => state.stockflowCheckout.productSnList.isError
  );
  const hasMoreToLoad = useSelector(
    (state) => state.stockflowCheckout.productSnList.hasMoreToLoad
  );

  const [isExpanded, setIsExpanded] = useState(false);
  const [distanceBottom, setDistanceBottom] = useState(0);

  const tableRef = useRef();
  const filterRef = useRef({
    page: 0,
    rowsPerPage: 25,
    boxUuid: boxUuid ?? null,
  });

  useEffect(() => {
    setIsExpanded(selectedProduct && selectedProduct.uuid === product.uuid);
  }, [selectedProduct, product]);

  useEffect(() => {
    if (!isExpanded) {
      dispatch(resetProductSnList());

      let filter = {
        ...filterRef.current,
        page: 0,
        rowsPerPage: 25,
      };
      filterRef.current = filter;
    } else {
      handleDataLoad();
    }
  }, [isExpanded]);

  const handleDataLoad = () => {
    if (selectedProduct) {
      let payload = {
        length: filterRef.current.rowsPerPage,
        start: filterRef.current.rowsPerPage * filterRef.current.page,
        box_uuid: filterRef.current.boxUuid,
        id,
        product_uuid: selectedProduct.uuid,
      };
      dispatch(getCheckoutProductDetail(payload));
    }
  };

  const handleFetchMoreItems = () => {
    if (hasMoreToLoad) {
      const newFilter = {
        ...filterRef.current,
        page: filterRef.current.page + 1,
      };
      filterRef.current = newFilter;
      handleDataLoad();
    }
  };

  const handleReload = () => {
    filterRef.current = {
      ...filterRef.current,
      page: 0,
    };
    handleDataLoad();
  };

  const scrollListener = useCallback(() => {
    let bottom = tableRef.current.scrollHeight - tableRef.current.clientHeight;
    // if you want to change distanceBottom every time new data is loaded
    // don't use the if statement
    if (!distanceBottom) {
      // calculate distanceBottom that works for you
      setDistanceBottom(Math.round(bottom * 0.2));
    }
    if (
      tableRef.current.scrollTop > bottom - distanceBottom &&
      hasMoreToLoad &&
      !isFetching
    ) {
      handleFetchMoreItems();
    }
  }, []);

  useLayoutEffect(() => {
    const tableEl = tableRef.current;
    tableEl.addEventListener("scroll", scrollListener);
    return () => {
      tableEl.removeEventListener("scroll", scrollListener);
    };
  }, [scrollListener]);

  return (
    <SingleCheckoutProductAccordionComponent
      product={product}
      lang={lang}
      handleExpand={selectViewItem}
      selectedProduct={selectedProduct}
      handleFetchMoreItems={handleFetchMoreItems}
      isFetching={isFetching}
      isError={isError}
      snList={snList}
      totalRecords={totalFiltered}
      handleReload={handleReload}
      hasMoreToLoad={hasMoreToLoad}
      tableRef={tableRef}
      boxUuid={boxUuid}
    />
  );
}
