import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useRouteMatch } from "react-router-dom"
import { selectLoading } from 'modules/notification'
import LoadingComponent from "components/loading"
import { getStockoutDetail, getStockoutItemList } from "modules/stockflow/redux"
import { STOCKFLOW_STOCKOUT_VIEW, STOCKFLOW_DEALER_VIEW } from "lib/constants/accessRights"
import { ROOT } from "modules/dashboard"
import StockoutDetailComponent from "./stockoutDetails.component"

function StockoutDetailsContainer() {
  const history = useHistory()
  const dispatch = useDispatch()
  const match = useRouteMatch()

  const id = match.params.uuid
  const hasStockflowViewAccessRight = useSelector(state => state.profile.accessRights.includes(STOCKFLOW_STOCKOUT_VIEW))
  const hasStockflowDealerViewAccessRight = useSelector(state => state.profile.accessRights.includes(STOCKFLOW_DEALER_VIEW))
  const stockout = useSelector(state => state.stockflowStockout.stockout)
  const isError = useSelector(state => state.stockflowStockout.isLoadingStockoutError)
  const isFetching = useSelector(state => selectLoading(state, getStockoutDetail.typePrefix))
  const lang = useSelector(state => state.constant.languages)
  const language = useSelector(state => state.profile.language)

  useEffect(() => {
    if (!hasStockflowViewAccessRight) {
      history.push('/admin/dashboard')
    }
  }, [history, hasStockflowViewAccessRight])

  useEffect(() => {
    if (id) {
      dispatch(getStockoutDetail(id))
      dispatch(getStockoutItemList(id))
    }
    else {
      history.push(ROOT)
    }
  }, [history, id, dispatch])

  if (!hasStockflowViewAccessRight) return <LoadingComponent />

  const handleReload = () => {
    dispatch(getStockoutDetail(id))
    dispatch(getStockoutItemList(id))
  }

  return (
    <StockoutDetailComponent
      hasStockflowDealerViewAccessRight={hasStockflowDealerViewAccessRight}
      stockout={stockout}
      isFetching={isFetching}
      isError={isError}
      handleReload={handleReload}
      lang={lang}
      language={language}
    />
  )
}

export default StockoutDetailsContainer
