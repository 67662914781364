import React from "react";
import ScanLocationStatePanelComponent from "./scanLocationStatePanel.component";

export default function ScanLocationStatePanelContainer({
  scanData,
  targetCountry,
}) {
  return (
    <ScanLocationStatePanelComponent scanData={scanData} targetCountry={targetCountry} />
  )
}