import React, { useState, useRef, useEffect } from "react";
import CategorySelectComponent from "./categorySelect.component";
import CategoryPanelComponent from "./categoryPanel.component";
import PropTypes from "prop-types";
import { selectLoading } from "modules/notification";
import { useDispatch, useSelector } from "react-redux";
import { getProductCategoryDropdown, addProductCategories } from "../../../redux/action/productCategory.action";
import CreateCategoryDialog from 'modules/product-mgmt/components/dialog/createCategoryDialog';
import { unwrapResult } from '@reduxjs/toolkit';
import { getLang } from "app/feature/constants";

function CategorySelectContainer({
    searchable,
    onCategoryChange,
    handleChange,
    value,
    disabled,
    style,
    placeholder = null,
    panelStyle,
    dropdownItem,
    emptyMessage,
    anchorOrigin,
    transformOrigin,
    PaperProps,
    textStyle,
    isEmpty,
    isClearable,
    handleClear,
}) {
    const [open, setOpen] = useState(false);
    const [isOpenList] = useState(false);
    const anchorRef = useRef(null);
    const prevOpen = useRef(open);
    const prevOpenList = useRef(isOpenList);
    const dispatch = useDispatch();
    const categoryDropdown = useSelector((state) => state.products.categoryList.categoryDropdown);
    const isFetching = useSelector((state) => selectLoading(state, getProductCategoryDropdown.typePrefix));
    const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
    const lang = useSelector(state => state.constant.languages);
    const [searchKeyword, setSearchKeyword] = useState("")

    const handleCreateCategory = (value) => {
        setIsCreateDialogOpen(value)
    }

    const handleSubmitCategories = (value) => {
        dispatch(addProductCategories({
            categories: value.categories
        })).then(unwrapResult).finally(() => {
            dispatch(getProductCategoryDropdown({ search: "" }));
        })
        setIsCreateDialogOpen(false)
    }

    useEffect(() => {
        dispatch(getProductCategoryDropdown({
            search: searchKeyword
        }));
    }, [dispatch, searchKeyword]);

    const handleClose = () => {
        setOpen(false);
        setSearchKeyword("")
    };

    const handleClick = () => {
        setOpen(true);
    };

    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    useEffect(() => {
        if (prevOpenList.current === true && isOpenList === false) {
            anchorRef.current.focus();
        }

        prevOpenList.current = isOpenList;
    }, [isOpenList]);

    function handleListKeyDown(value) {
        handleChange ? handleChange(value) : onCategoryChange(value);
        setOpen(false);
    }

    const handleItemSearch = (keyword) => {
        setSearchKeyword(keyword)
    }

    return (
        <>
            <CategorySelectComponent
                anchorRef={anchorRef}
                handleClick={handleClick}
                value={value}
                placeholder={placeholder ?? getLang(lang, "placeholder.SELECT_CATEGORY")}
                handleClear={handleClear}
                isOpenList={open}
                style={style}
                disabled={disabled}
                textStyle={textStyle}
                isClearable={isClearable}
            />
            <CategoryPanelComponent
                anchorRef={anchorRef}
                open={open}
                isOpenList={isOpenList}
                handleClose={handleClose}
                isFetching={isFetching}
                handleListKeyDown={handleListKeyDown}
                handleAddCategory={() => setIsCreateDialogOpen(true)}
                categoryDropdown={categoryDropdown}
                lang={lang}
                searchable={searchable}
                handleSearch={handleItemSearch}
            />
            <CreateCategoryDialog
                isOpen={isCreateDialogOpen}
                handleClose={() => handleCreateCategory(false)}
                handleSubmit={handleSubmitCategories}
                isSubmittingAddCategory={isFetching}
            />
        </>
    );
}

CategorySelectContainer.propTypes = {
    placeholder: PropTypes.string,
};

export default CategorySelectContainer;
