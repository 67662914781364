import React from "react";
import {
  makeStyles,
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  IconButton,
} from "@material-ui/core";
import { StyledTableCell, StyledTableRow } from "components/table";
import { SkeletonTableRow } from "components/skeleton/skeleton.component";
import AntSwitch from "components/switch/switch";
// import HelpRoundedIcon from "@material-ui/icons/HelpRounded";
// import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ActionButton from "components/button/actionDetailButton";
import { TablePaginationActions } from "components/tablePagination";
import Alert from "@material-ui/lab/Alert";
import { SERIAL_NUMBER_BATCH_STATUS_GENERATED } from "modules/serial-number/constants";
import RefreshTable from "components/table/refreshTable";
import { getLang } from "app/feature/constants";
import StickyLeftTableCell from "components/table/stickyLeftTableCell";
import StickyRightTableCell from "components/table/stickyRightTableCell";
import GenerateStatusProgress from "../../progress/generateStatusProgress";
import { Edit as EditIcon } from "@material-ui/icons";
import RedirectUrlTextField from "../../textField/redirectUrlTextField";
import moment from "moment";
import "moment/min/locales.min";

const useStyle = makeStyles((theme) => ({
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    borderCollapse: "separate",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
    },
    "& th,td": {
      fontSize: ".75rem",
      borderRight: "1px solid rgba(58, 77, 84, 0.1)",
      padding: theme.spacing(1, 2, 1, 2),
    },
    "& th": {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightBold,
    },
    "& th:last-child, td:last-child": {
      width: 37,
      borderRight: "0px",
    },
    "& th th": {
      borderBottom: "none !important",
    },
    "& td td": {
      borderBottom: "none !important",
    },
  },
  emptyContainer: {
    textAlign: "center",
  },
  button: {
    alignItems: "center",
    padding: 0,
    minWidth: 0,
    marginLeft: theme.spacing(1),
  },
}));

export default function BatchTableComponent({
  hasSerialNumberGenerateAccessRight,
  rows,
  isFetching,
  page,
  rowsPerPage,
  handleChangePage,
  language,
  handleChangeRowsPerPage,
  length,
  handleAddSerialNumber,
  handleReload,
  isLoadingSerialNumberBatchError,
  paginationOptions,
  lang,
  handleAppAuthorizeStatusChange,
  handleScanVerifyStatusChange,
  setSelectedBatch,
  isSearchResult,
  setQuickEdit,
  hasBatchVerificationEditRight,
  hasBatchAuthenticatorEditRight,
}) {
  const classes = useStyle();

  if (language === "ZH") {
    moment.locale("zh-cn");
  } else if (language === "TL") {
    moment.locale("tl-ph");
  } else {
    moment.locale(language);
  }

  return (
    <>
      {rows.length > 0 || isFetching ? (
        <TableContainer>
          <Table
            className={classes.table}
            size="small"
            aria-label="product table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <StickyLeftTableCell rowSpan={2}>
                  <StyledTableCell rowSpan={2}>
                    {getLang(lang, "label.NUM")}
                  </StyledTableCell>
                </StickyLeftTableCell>
                <StyledTableCell rowSpan={2}>
                  {getLang(lang, "label.TYPE")}
                </StyledTableCell>
                <StyledTableCell
                  colSpan={3}
                  align="center"
                  className={classes.borderRight}
                >
                  {getLang(lang, "label.FORMAT")}
                </StyledTableCell>
                <StyledTableCell rowSpan={2}>
                  {getLang(lang, "label.RANGE")}
                </StyledTableCell>
                <StyledTableCell rowSpan={2}>
                  {getLang(lang, "label.QUANTITY")}
                </StyledTableCell>
                <StyledTableCell rowSpan={2}>
                  {getLang(lang, "label.BATCH_NUMBER")}
                  {/* /**
                   * Disabled help button.
                   * Uncomment these code blocks whenever ready to implement help button
                   */}
                  {/* <Button rowSpan={2} className={classes.button}>
                    <HelpRoundedIcon style={{ fontSize: "medium" }} />
                  </Button> */}
                </StyledTableCell>
                <StyledTableCell rowSpan={2}>
                  {getLang(lang, "label.BRANCH_NAME")}
                </StyledTableCell>
                <StyledTableCell rowSpan={2}>
                  {getLang(lang, "label.STATUS")}
                </StyledTableCell>
                {!!hasBatchAuthenticatorEditRight && (
                  <StyledTableCell rowSpan={2}>
                    {getLang(lang, "label.APP_AUTHENTICATOR")}
                  </StyledTableCell>
                )}
                {!!hasBatchVerificationEditRight && (
                  <StyledTableCell rowSpan={2}>
                    {getLang(lang, "label.PRODUCT_PAGE_PREVIEW")}
                  </StyledTableCell>
                )}
                <StyledTableCell rowSpan={2}>
                  {getLang(lang, "label.DATE_CREATED")}
                  {/* /**
                   * Disabled sort button.
                   * Uncomment these code blocks whenever ready to implement sort button
                   */}
                  {/* <Button className={classes.button}>
                    <ArrowUpwardIcon style={{ fontSize: "small" }} />
                  </Button> */}
                </StyledTableCell>
                <StickyRightTableCell rowSpan={2}>
                  <StyledTableCell rowSpan={2}>
                    {getLang(lang, "label.ACTION")}
                  </StyledTableCell>
                </StickyRightTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell>
                  {getLang(lang, "label.PREFIX")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.LENGTH")}
                </StyledTableCell>
                <StyledTableCell
                  style={{ borderRight: "1px solid rgba(58, 77, 84, 0.1)" }}
                >
                  {getLang(lang, "label.POSTFIX")}
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isFetching
                ? [...new Array(5)].map((v, index) => (
                    <SkeletonTableRow
                      key={index}
                      columnCount={
                        hasBatchAuthenticatorEditRight &&
                        hasBatchVerificationEditRight
                          ? 14
                          : hasBatchAuthenticatorEditRight ||
                            hasBatchVerificationEditRight
                          ? 13
                          : 12
                      }
                    />
                  ))
                : rows.map((row, index) => {
                    const actionItems = [
                      {
                        label: getLang(lang, "label.EXPORT_AS"),
                        action: () => {
                          setSelectedBatch(row);
                        },
                        disabled:
                          row.status !== SERIAL_NUMBER_BATCH_STATUS_GENERATED,
                      },
                    ];

                    return (
                      <StyledTableRow
                        key={index}
                        hover
                        role="checkbox"
                        tabIndex={-1}
                      >
                        <StickyLeftTableCell>
                          <StyledTableCell
                            component="th"
                            scope="row"
                            style={{ borderBottom: "0px" }}
                          >
                            {index + 1 + rowsPerPage * page}
                          </StyledTableCell>
                        </StickyLeftTableCell>
                        <StyledTableCell>
                          {row.genType === "running"
                            ? getLang(lang, "label.RUNNING_NUMBER")
                            : row.genType === "random"
                            ? getLang(lang, "label.RANDOM_ALPHANUMERIC")
                            : row.genType}
                          {/* QUICK FIX FOR LANGUAGES TRANSLATION */}
                        </StyledTableCell>
                        <StyledTableCell>{row.prefix}</StyledTableCell>
                        <StyledTableCell>{row.length}</StyledTableCell>
                        <StyledTableCell>{row.postfix}</StyledTableCell>
                        <StyledTableCell>
                          {row.range.map((value, index) => {
                            return (
                              <span key={index}>
                                {value.from_sn} - {value.to_sn}
                                <br />
                              </span>
                            );
                          })}
                        </StyledTableCell>
                        <StyledTableCell>
                          {row.quantity.toLocaleString("en-US")}
                        </StyledTableCell>
                        <StyledTableCell>{row.batchNumber}</StyledTableCell>
                        <StyledTableCell>{row.branchName}</StyledTableCell>
                        <StyledTableCell>
                          <GenerateStatusProgress
                            generateUuid={row.uuid}
                            initialProgress={row.progress}
                            initialStatus={row.status}
                          />
                        </StyledTableCell>
                        {!!hasBatchAuthenticatorEditRight && (
                          <StyledTableCell>
                            <AntSwitch
                              checked={!!row.appAuthorizeRequire}
                              disabled={
                                row.status !==
                                SERIAL_NUMBER_BATCH_STATUS_GENERATED
                              }
                              onChange={() => {
                                handleAppAuthorizeStatusChange({
                                  currentStatus: row.appAuthorizeRequire,
                                  uuid: row.uuid,
                                });
                              }}
                            />
                          </StyledTableCell>
                        )}
                        {!!hasBatchVerificationEditRight && (
                          <StyledTableCell>
                            <Box
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Box
                                display="flex"
                                alignItems="center"
                                width="100%"
                              >
                                <AntSwitch
                                  checked={!!row.isVerifyRequired}
                                  disabled={
                                    row.status !==
                                    SERIAL_NUMBER_BATCH_STATUS_GENERATED
                                  }
                                  onChange={() => {
                                    handleScanVerifyStatusChange({
                                      currentStatus: row.isVerifyRequired,
                                      uuid: row.uuid,
                                    });
                                  }}
                                />
                                {row.isEdit ? (
                                  <Box display="flex" pl={1}>
                                    <RedirectUrlTextField
                                      batch={row}
                                      lang={lang}
                                      value={row.redirectTo}
                                      handleCancel={() => {
                                        setQuickEdit(index, false);
                                      }}
                                    />
                                  </Box>
                                ) : (
                                  <>
                                    <Box pl={1} pr={1.5} flex={1}>
                                      <Typography variant="caption">
                                        {row.redirectTo
                                          ? row.redirectTo
                                          : getLang(lang, "label.DEFAULT")}
                                      </Typography>
                                    </Box>
                                    <Box>
                                      <IconButton
                                        size="small"
                                        disabled={
                                          row.status !==
                                          SERIAL_NUMBER_BATCH_STATUS_GENERATED
                                        }
                                        onClick={() =>
                                          setQuickEdit(index, true)
                                        }
                                      >
                                        <EditIcon />
                                      </IconButton>
                                    </Box>
                                  </>
                                )}
                              </Box>
                            </Box>
                          </StyledTableCell>
                        )}
                        <StyledTableCell>
                          {moment(row.datetime).format("ll")}
                        </StyledTableCell>
                        <StickyRightTableCell>
                          <StyledTableCell>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <ActionButton
                                actionItems={actionItems}
                                popperStyle={{ zIndex: 999 }}
                              />
                            </div>
                          </StyledTableCell>
                        </StickyRightTableCell>
                      </StyledTableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : !isSearchResult && !isLoadingSerialNumberBatchError ? (
        <Box style={{ overflowX: "auto", backgroundColor: "#fff" }}>
          <Table
            className={classes.table}
            size="small"
            aria-label="product table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <StickyLeftTableCell rowSpan={2}>
                  <StyledTableCell rowSpan={2}>
                    {getLang(lang, "label.NUM")}
                  </StyledTableCell>
                </StickyLeftTableCell>
                <StyledTableCell rowSpan={2}>
                  {getLang(lang, "label.TYPE")}
                </StyledTableCell>
                <StyledTableCell
                  colSpan={3}
                  align="center"
                  className={classes.borderRight}
                >
                  {getLang(lang, "label.FORMAT")}
                </StyledTableCell>
                <StyledTableCell rowSpan={2}>
                  {getLang(lang, "label.RANGE")}
                </StyledTableCell>
                <StyledTableCell rowSpan={2}>
                  {getLang(lang, "label.QUANTITY")}
                </StyledTableCell>
                <StyledTableCell rowSpan={2}>
                  {getLang(lang, "label.BATCH_NUMBER")}
                  {/* /**
                   * Disabled help button.
                   * Uncomment these code blocks whenever ready to implement help button
                   */}
                  {/* <Button rowSpan={2} className={classes.button}>
                    <HelpRoundedIcon style={{ fontSize: "medium" }} />
                  </Button> */}
                </StyledTableCell>
                <StyledTableCell rowSpan={2}>
                  {getLang(lang, "label.BRANCH_NAME")}
                </StyledTableCell>
                <StyledTableCell rowSpan={2}>
                  {getLang(lang, "label.STATUS")}
                </StyledTableCell>
                {!!hasBatchAuthenticatorEditRight && (
                  <StyledTableCell rowSpan={2}>
                    {getLang(lang, "label.APP_AUTHENTICATOR")}
                  </StyledTableCell>
                )}
                <StyledTableCell rowSpan={2}>
                  {getLang(lang, "label.PRODUCT_PAGE_PREVIEW")}
                </StyledTableCell>
                <StyledTableCell rowSpan={2}>
                  {getLang(lang, "label.DATE_CREATED")}
                  {/* /**
                   * Disabled sort button.
                   * Uncomment these code blocks whenever ready to implement sort button
                   */}
                  {/* <Button className={classes.button}>
                    <ArrowUpwardIcon style={{ fontSize: "small" }} />
                  </Button> */}
                </StyledTableCell>
                <StickyRightTableCell rowSpan={2}>
                  <StyledTableCell rowSpan={2}>
                    {getLang(lang, "label.ACTION")}
                  </StyledTableCell>
                </StickyRightTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell>
                  {getLang(lang, "label.PREFIX")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.LENGTH")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.POSTFIX")}
                </StyledTableCell>
              </TableRow>
            </TableHead>
          </Table>
          <Box py={9} className={classes.emptyContainer}>
            <Typography variant="body1">
              {getLang(lang, "paragraph.NO_SERIAL_NUMBER")}
            </Typography>
            {hasSerialNumberGenerateAccessRight ? (
              <Box mt={2}>
                <Button
                  onClick={handleAddSerialNumber}
                  variant="contained"
                  disableElevation
                  color="primary"
                >
                  {getLang(lang, "label.ADD_SERIAL_NUMBER")}
                </Button>
              </Box>
            ) : null}
          </Box>
        </Box>
      ) : null}

      {!isFetching && rows.length === 0 && isSearchResult && (
        <Alert severity="info">
          {getLang(lang, "message.info.NO_SERIAL_NUMBER_BATCH")}
        </Alert>
      )}

      {!isFetching && isLoadingSerialNumberBatchError && (
        <RefreshTable handleReload={handleReload} />
      )}

      {!isFetching && rows.length > 0 && (
        <TablePagination
          style={{
            backgroundColor: "#fff",
            boxShadow: "0px -1px 0px rgba(58, 77, 84, 0.15)",
          }}
          ActionsComponent={TablePaginationActions}
          rowsPerPageOptions={paginationOptions}
          component="div"
          count={length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          SelectProps={{
            classes: classes.toolbar,
          }}
          labelRowsPerPage={getLang(lang, "label.ROWS_PER_PAGE")}
          labelDisplayedRows={({ from, to, count }) =>
            `${from.toLocaleString("en-US")}-${to.toLocaleString(
              "en-US"
            )} ${getLang(lang, "label.OF")} ${count.toLocaleString("en-US")}`
          }
        />
      )}
    </>
  );
}
