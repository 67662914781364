import { Box, Paper, TableCell, TableRow, Typography } from "@material-ui/core";
import { flexGapStyle, fontTextStyle, tableStyle } from "modules/reporting/styles";
import { drilldownPageSelection } from "modules/reporting/utils/helper";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { ContentCard } from "../../layout";
import { DynamicTable } from "../../table";

export default function BranchSalesCampaignPanelComponent({
    status,
    data,
    pagination,
    sort,
    currentFilter,
    pageType,
    translate
}){
    const tableClass = tableStyle();
    const globalFlexGapClass = flexGapStyle();
    const fontClass = fontTextStyle();

    const columns = [
        {
            title: translate("label.NUM"),
            field: null,
            render : (row) => {
                return (
                    <span>{row.title}</span>
                )
            },
            align: "left"
        },
        {
            title: translate("label.BRANCH"),
            field: null,
            subtitle: `(${translate("paragraph.BRANCH_DRILLDOWN_DESCRIPTION")})`,
            render : (row) => {
                return (
                    <Box
                        display="flex"
                        className={globalFlexGapClass.global__flexGap3}
                    >
                        <span>{row.title}</span>
                        <span className={fontClass.text__subtitle_muted}>{row.subtitle}</span>
                    </Box>
                )
            },
            align: null
        },
        {
            title: translate("label.TOTAL_SCAN"),
            field: "count", // total_scan
            render : (row) => {
                return (
                    <>{row.title}</>
                )
            },
            align: "left"
        },
        {
            title: translate("label.TOTAL_ENGAGEMENT"),
            field: "engagement",
            render : (row) => {
                return (
                    <>{row.title}</>
                )
            },
            align: "left"
        }
    ];

    const renderData = (data) => {

        let rows = [];
        if(Object.keys(data).length === 0 || !data.isSuccess){
            return rows;
        }

        let imageProps = JSON.stringify(data);
        imageProps = JSON.parse(imageProps);

        let no_iteration = pagination.rowsPerPage * pagination.page + 1;
        imageProps.rows.forEach((item, index) => {
            let row = item.uuid ? (
                <TableRow key={index}>
                    <TableCell align="left" className={tableClass.table__cell} style={{width:"20px"}}>
                        <Typography className={fontClass.text__table_cell}>
                            {no_iteration++}
                        </Typography>
                    </TableCell>
                    <TableCell
                        align={item.align || "left"}
                        className={tableClass.table__cell}
                        style={{width:"580px"}}
                    >
                        <Link 
                            target="_blank" rel="noopener noreferrer"
                            className={fontClass.text__hyperlink}
                            to={() => {

                                let filterProperties = {};

                                if(currentFilter.countryCode)
                                    filterProperties.countryCode = currentFilter.countryCode;
                                
                                if(currentFilter.cityUuid)
                                    filterProperties.cityUuid = currentFilter.cityUuid;
                                
                                if(currentFilter.branchUuid)
                                    filterProperties.branchUuid = currentFilter.branchUuid;

                                if(currentFilter.period)
                                    filterProperties.period = currentFilter.period;

                                if(currentFilter.dateRange){
                                    filterProperties.start = moment(currentFilter.dateRange[0]).format('YYYY-MM-DD');
                                    filterProperties.end = moment(currentFilter.dateRange[1]).format('YYYY-MM-DD');
                                }

                                if(currentFilter.campaignUuid)
                                    filterProperties.campaignUuid = currentFilter.campaignUuid;
                            
                                if(currentFilter.campaignType)
                                    filterProperties.campaignType = currentFilter.campaignType;

                                // if(currentFilter.productUuid)
                                //     filterProperties.productUuid = currentFilter.productUuid;
                            
                                // if(currentFilter.categoryUuid)
                                //     filterProperties.categoryUuid = currentFilter.categoryUuid;

                                // if(currentFilter.modelUuid)
                                //     filterProperties.modelUuid = currentFilter.modelUuid;
                                
                                // if(currentFilter.brandUuid)
                                //     filterProperties.brandUuid = currentFilter.brandUuid;

                                if(item.uuid)
                                    filterProperties.branchUuid = item.uuid;

                                let url = drilldownPageSelection(filterProperties, pageType, true);
                                return url;
                            }}
                        >
                            <span>{item.name}</span>
                        </Link>
                    </TableCell>
                    <TableCell align={item.align || "right"} className={tableClass.table__cell} style={{minWidth:"130px"}}>
                        <Typography className={fontClass.text__table_cell}>{item.scan}</Typography>
                    </TableCell>
                    <TableCell align={item.align || "right"} className={tableClass.table__cell} style={{minWidth:"130px"}}>
                        <Typography className={fontClass.text__table_cell}>{item.engagement}</Typography>
                    </TableCell>
                </TableRow>
            ) : (

                item.uuid !== 0 ? 
                (
                    <TableRow className={tableClass.table__total_row} key={index}>
                        <TableCell colSpan={2} component="th" scope="row" className={[tableClass.table__total_cell,tableClass.table__cell]} style={{width:"580px"}}>
                            {translate("label.TOTAL")}
                        </TableCell>
                        <TableCell align="right" className={[tableClass.table__total_cell,tableClass.table__cell]} style={{width:"110px"}}>{item.scan}</TableCell>
                        <TableCell align="right" className={[tableClass.table__total_cell,tableClass.table__cell]} style={{width:"110px"}}>{item.engagement}</TableCell>
                    </TableRow>
                ) : 
                (
                    <TableRow key={index}>
                        <TableCell align="left" className={tableClass.table__cell} style={{width:"20px"}}>
                            <Typography className={fontClass.text__table_cell}>
                                {no_iteration++}
                            </Typography>
                        </TableCell>
                        <TableCell
                            align={item.align || "left"}
                            className={tableClass.table__cell}
                            style={{width:"580px"}}
                        >
                            <Typography className={fontClass.text__unknown}>({translate("label.EXTERNAL_LINK")})</Typography>
                        </TableCell>
                        <TableCell align={item.align || "right"} className={tableClass.table__cell} style={{minWidth:"130px"}}>
                            <Typography className={fontClass.text__table_cell}>{item.scan}</Typography>
                        </TableCell>
                        <TableCell align={item.align || "right"} className={tableClass.table__cell} style={{minWidth:"130px"}}>
                            <Typography className={fontClass.text__table_cell}>{item.engagement}</Typography>
                        </TableCell>
                    </TableRow>
                ) 

            )

            
            item.render = row;
        })
        
        return imageProps;
    }

    return (
        <ContentCard>
            <Box marginBottom="24px" display="flex" justifyContent="space-between" alignContent="center">
                <Typography className={fontClass.text__card_title}>
                    {translate("label.BRANCH_CAMPAIGN_ENGAGEMENT")}
                </Typography>
            </Box>
            <DynamicTable 
                component={ Paper }
                sort={sort}
                pagination={pagination}
                columns={columns}
                data={renderData(data.branchSalesData)}
                status={status.isFetchingBranchSalesData}
                translate={translate}
            />
        </ContentCard>
    )
}