import React from "react";
import {
  Box,
  Button, Link,
  makeStyles,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from "@material-ui/core";
import { StyledTableCell, StyledTableRow } from "components/table";
import { SkeletonTableRow } from "components/skeleton/skeleton.component";
import { ActionButton } from "components/button";
import { TablePaginationActions } from "components/tablePagination";
import AntSwitch from "components/switch/switch";
import Alert from "@material-ui/lab/Alert";
import { progressStatus } from "lib/constants/luckyDrawProgress";
import {
  luckyDrawCreated,
  prizeCreated,
  winnerCreated,
  winnerConfirm,
  live
} from "./luckyDrawProgress";
import RefreshTable from "components/table/refreshTable";
import { getLang } from "app/feature/constants";
import StickyLeftTableCell from "components/table/stickyLeftTableCell";
import StickyRightTableCell from "components/table/stickyRightTableCell";
import ProductCountDetailPopover from "../../../../../components/popover/productCountDetailPopover";
import moment from "moment";
import 'moment/min/locales.min';

const useStyle = makeStyles((theme) => ({
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    borderCollapse: "separate",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
    },
    "& th,td": {
      fontSize: ".75rem",
      borderRight: "1px solid rgba(58, 77, 84, 0.1)",
      padding: theme.spacing(1, 2, 1, 2),
    },
    "& th": {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightBold,
    },
    "& th:last-child, td:last-child": {
      width: 37,
      borderRight: "0px",
    },
    "& th th": {
      borderBottom: "none !important",
    },
    "& td td": {
      borderBottom: "none !important",
    },
  },
  tableContainer: {
    maxHeight: 500,
    overflowY: "auto"
  },
  emptyContainer: {
    textAlign: "center"
  },
  button: {
    display: "flex",
    alignItems: "center",
    padding: 0,
    minWidth: 0,
    marginLeft: theme.spacing(1)
  },
  iconBtn: {
    borderRadius: theme.spacing(0.5),
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shortest
    }),
    padding: "3px 5px"
  },
  attributeText: {
    color: "#b2b2b2",
    fontSize: 12,
    whiteSpace: "break-spaces",
  },
}));

export default function LuckyDrawListingTableComponent({
  hasLuckyDrawAddAccessRight,
  hasLuckyDrawEditAccessRight,
  hasLuckyDrawActivateOrDeactivateAccessRight,
  data,
  isFetching,
  page,
  search,
  rowsPerPage,
  totalRecords,
  handleChangePage,
  handleChangeRowsPerPage,
  handleCreate,
  handleViewParticipants,
  handleView,
  handleStatusChange,
  handleViewPrize,
  language,
  handleViewWinner,
  handleLiveDraw,
  handleDownloadWinner,
  handleFetchLuckyDrawList,
  isLoadingLuckyDrawError,
  paginationOptions,
  lang
}) {
  const classes = useStyle();
  
  if(language==="ZH")
  {
    moment.locale('zh-cn')
  }else if(language==="TL")
  {
    moment.locale('tl-ph')
  }else
  {
    moment.locale(language)
  }

  return (
    <>
      {data.length > 0 || isFetching ? (
        <TableContainer>
          <Table className={classes.table} size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <StickyLeftTableCell>
                  <StyledTableCell>{getLang(lang, "label.NUM")}</StyledTableCell>
                </StickyLeftTableCell>
                <StyledTableCell>{getLang(lang, "label.LUCKY_DRAW_NAME")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.IMAGE")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.DATE_CREATED")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.PRODUCTS")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.PARTICIPANTS")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.WINNER_RESULT")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.EVENT_PERIOD")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.STATUS")}</StyledTableCell>
                <StickyRightTableCell>
                  <StyledTableCell>{getLang(lang, "label.ACTION")}</StyledTableCell>
                </StickyRightTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isFetching
                ? [...new Array(5)].map((v, index) => (
                  <SkeletonTableRow key={index} columnCount={10} />
                ))
                : data.map((item, index) => {
                  var actionItems;

                  switch (item.progress) {
                    case progressStatus.new:
                      actionItems = luckyDrawCreated({
                        hasLuckyDrawEditAccessRight,
                        id: item.id,
                        disableViewParticipant: item.participantsCount === 0,
                        progress: item.progress,
                        handleView,
                        handleViewParticipants,
                        handleViewPrize,
                        lang
                      });
                      break;
                    case progressStatus.setPrize:
                      actionItems = prizeCreated({
                        hasLuckyDrawEditAccessRight,
                        id: item.id,
                        disableViewParticipant: item.participantsCount === 0,
                        progress: item.progress,
                        handleView,
                        handleViewParticipants,
                        handleViewPrize,
                        handleViewWinner,
                        lang
                      });
                      break;
                    case progressStatus.setWinner:
                      actionItems = winnerCreated({
                        hasLuckyDrawEditAccessRight,
                        id: item.id,
                        disableViewParticipant: item.participantsCount === 0,
                        progress: item.progress,
                        handleView,
                        handleViewParticipants,
                        handleViewPrize,
                        handleViewWinner,
                        lang
                      });
                      break;
                    case progressStatus.confirmWinner:
                      actionItems = winnerConfirm({
                        hasLuckyDrawEditAccessRight,
                        id: item.id,
                        disableViewParticipant: item.participantsCount === 0,
                        progress: item.progress,
                        luckyDrawTitle: item.luckyDrawTitle,
                        handleView,
                        handleViewParticipants,
                        handleViewPrize,
                        handleViewWinner,
                        handleLiveDraw,
                        handleDownloadWinner,
                        lang
                      });
                      break;
                    case progressStatus.published:
                      actionItems = live({
                        hasLuckyDrawEditAccessRight,
                        id: item.id,
                        disableViewParticipant: item.participantsCount === 0,
                        progress: item.progress,
                        luckyDrawTitle: item.luckyDrawTitle,
                        handleView,
                        handleViewParticipants,
                        handleViewPrize,
                        handleViewWinner,
                        handleLiveDraw,
                        handleDownloadWinner,
                        lang
                      });
                      break;
                    default:
                      break;
                  }
                  return (
                    <StyledTableRow key={index} hover tabIndex={-1}>
                      <StickyLeftTableCell>
                        <StyledTableCell>
                          {index + 1 + rowsPerPage * page}
                        </StyledTableCell>
                      </StickyLeftTableCell>
                      <StyledTableCell>{item.luckyDrawTitle}</StyledTableCell>
                      <StyledTableCell><img src={item.luckyDrawImageUrl} style={{ width: "30px", height: "30px" }}alt="poster" /></StyledTableCell>
                      <StyledTableCell>
                        {moment(item.createdAt).format('ll')}
                      </StyledTableCell>
                      <ProductCountDetailPopover
                        products={item.assignProduct}
                        itemTranslator={product => ({
                          id: product.productId,
                          name: product.name,
                          imageUrl: product.imageUrl,
                          attributes: product.attributes,
                        })}
                      />
                      <StyledTableCell>
                        {item.participantsCount > 0 ? (
                          <Link
                            color="secondary"
                            href="#"
                            onClick={(event) => {
                              event.preventDefault();
                              handleViewParticipants(item.id)
                            }}
                          >
                            {item.participantsCount.toLocaleString("en-US")}
                          </Link>
                        ) : (
                          <Box>{item.participantsCount.toLocaleString("en-US")}</Box>
                        )}
                      </StyledTableCell>
                      <StyledTableCell>
                        {item.progress === progressStatus.published ? (
                          <Typography
                            variant="caption"
                            style={{ color: "#4BCB0F" }}
                          >
                            {getLang(lang, "label.PUBLISHED")}
                          </Typography>
                        ) : (
                          <Typography
                            variant="caption"
                            style={{ color: "#FF1D1D" }}
                          >
                            {getLang(lang, "label.NOT_AVAILABLE")}
                          </Typography>
                        )}
                      </StyledTableCell>
                      <StyledTableCell>{moment(item.startAt).format('lll')}&ensp;-&ensp;{moment(item.endAt).format('lll')}</StyledTableCell>
                      <StyledTableCell>
                        <AntSwitch
                          checked={item.status}
                          disabled={!hasLuckyDrawActivateOrDeactivateAccessRight}
                          onChange={() =>
                            handleStatusChange({
                              currentStatus: item.status,
                              id: item.id
                            })
                          }
                        />
                      </StyledTableCell>
                      <StickyRightTableCell>
                        <StyledTableCell>
                          <div style={{ display: "flex", justifyContent: "center" }}>
                            <ActionButton actionItems={actionItems} popperStyle={{ zIndex: 999 }} />
                          </div>
                        </StyledTableCell>
                      </StickyRightTableCell>
                    </StyledTableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : !search && !isLoadingLuckyDrawError && (
        <Box style={{overflowX: 'auto', backgroundColor: '#fff'}}>
          <Table className={classes.table} size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <StickyLeftTableCell>
                  <StyledTableCell>{getLang(lang, "label.NUM")}</StyledTableCell>
                </StickyLeftTableCell>
                <StyledTableCell>{getLang(lang, "label.IMAGE")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.DATE_CREATED")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.PRODUCTS")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.PARTICIPANTS")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.WINNER_RESULT")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.EVENT_PERIOD")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.STATUS")}</StyledTableCell>
                <StickyRightTableCell>
                  <StyledTableCell>{getLang(lang, "label.ACTION")}</StyledTableCell>
                </StickyRightTableCell>
              </TableRow>
            </TableHead>
          </Table>
          <Box py={9} className={classes.emptyContainer}>
            <Typography variant="body1">
              {getLang(lang, "paragraph.NO_LD")}
            </Typography>
            {
              hasLuckyDrawAddAccessRight
                ?
                <Box mt={2}>
                  <Button
                    onClick={handleCreate}
                    variant="contained"
                    disableElevation
                    color="primary"
                  >
                    {getLang(lang, "label.ADD_LUCKY_DRAW")}
                  </Button>
                </Box>
                : null
            }
          </Box>
        </Box>

      )}

      {!isFetching && data.length === 0 && search && (
        <Alert severity="info">{getLang(lang, "message.info.NO_LD_LIST")}</Alert>
      )}

      {!isFetching && isLoadingLuckyDrawError && (
        <RefreshTable handleReload={handleFetchLuckyDrawList} />
      )}

      {!isFetching && data.length > 0 && (
        <TablePagination
          style={{
            backgroundColor: "#fff",
            boxShadow: "0px -1px 0px rgba(58, 77, 84, 0.15)",
          }}
          ActionsComponent={TablePaginationActions}
          rowsPerPageOptions={paginationOptions}
          component="div"
          count={totalRecords}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage={getLang(lang, 'label.ROWS_PER_PAGE')}
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${getLang(lang, 'label.OF')} ${count}`}
        />
      )}
    </>
  );
}
