import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./app/App";
import * as serviceWorker from "./serviceWorker";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import AxiosDefaultSetting from "app/axiosDefaultSetting";

const API_KEY = process.env.REACT_APP_BUGSNAG_API_KEY;

Bugsnag.start({
  apiKey: API_KEY,
  plugins: [new BugsnagPluginReact()],
});
const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

ReactDOM.render(
  <ErrorBoundary>
    {/* <React.StrictMode> */}
    <AxiosDefaultSetting>
      <App />
    </AxiosDefaultSetting>
    {/* </React.StrictMode> */}
  </ErrorBoundary>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
