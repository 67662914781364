import React from "react";
import {
  Box,
  Typography,
  Paper,
  Button,
  CircularProgress,
  makeStyles,
  Chip,
  InputAdornment,
} from "@material-ui/core";
import { getLang } from "app/feature/constants";
import {
  CloseRounded as CloseRoundedIcon,
  CheckRounded as CheckRoundedIcon,
} from "@material-ui/icons";
import AntSwitch from "components/switch";
import { Formik } from "formik";
import { Skeleton } from "@material-ui/lab";
import InputTextField from "components/input/inputTextField";
import RefreshTable from "components/table/refreshTable";

const useStyle = makeStyles((theme) => ({
  inputRoot: {
    paddingTop: "8px !important",
    paddingBottom: "7px !important",
  },
}));

export default function CreditReloadLogPanelComponent({
  lang,
  alertInfo,
  isFetching,
  isError,
  handleReload,
  isEdit,
  setIsEdit,
  handleSubmit,
  isSubmitting,
  disbursementInfo,
}) {
  const classes = useStyle();

  return (
    <Box style={{
      display: "flex",
      flexDirection: "column",
    }}>
      <Box mb={1.5}>
        <Typography variant="body1">
          <b>{getLang(lang, "label.CREDIT_ALERT")}</b>
        </Typography>
      </Box>
      <Paper elevation={2}style={{
        height: "100%",
      }}>
        {isError ? (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <RefreshTable handleReload={handleReload} />
          </Box>
        ) : (
          <Formik
            initialValues={{
              alertThreshold: alertInfo.alertThreshold,
              hasAlert: alertInfo.hasAlert,
              newRecipient: "",
              recipients: alertInfo.recipients,
              transactionThreshold: alertInfo.transactionThreshold,
            }}
            enableReinitialize
            onSubmit={handleSubmit}
          >
            {(formik) => (
              <form onSubmit={formik.handleSubmit} style={{height:"inherit"}}>
                <Box p={2} style={{
                      display: "flex",
                      flexDirection: "column",
                      height: "inherit",
                      justifyContent: "space-between",
                }}>
                  <Box>
                  <Box>
                    <Typography variant="body2">
                      <b>
                        {isEdit
                          ? `${getLang(lang, "label.ALERT_RECIPIENTS")} (
                                ${getLang(lang, "label.MAX_COUNT_RECIPIENTS", {
                                  count: 5,
                                })}
                              )`
                          : getLang(lang, "label.ALERT_RECIPIENTS")}
                      </b>
                      {isEdit && (
                        <span style={{ color: "#FF3333" }}>&nbsp;*</span>
                      )}
                    </Typography>
                    {isFetching ? (
                      <>
                        <Skeleton variant="text" animation="wave" height={25} />
                        <Skeleton variant="text" animation="wave" height={25} />
                        <Skeleton variant="text" animation="wave" height={25} />
                      </>
                    ) : isEdit ? (
                      <Box mt={1}>
                        <Box display="flex" flexWrap="wrap">
                          {formik.values.recipients.length > 0
                            ? formik.values.recipients.map((email) => (
                                <Box
                                  key={email}
                                  style={{
                                    marginRight: 8,
                                    marginBottom: 8,
                                  }}
                                >
                                  <Chip
                                    label={email}
                                    onDelete={() => {
                                      let temp =
                                        formik.values.recipients.filter(
                                          (war) => war !== email
                                        );
                                      formik.setFieldValue("recipients", temp);
                                    }}
                                    disabled={isSubmitting}
                                  />
                                </Box>
                              ))
                            : null}
                        </Box>
                        <InputTextField
                          variant="filled"
                          size="small"
                          fullWidth
                          placeholder={getLang(lang, "placeholder.ENTER_EMAIL")}
                          InputProps={{
                            style: {
                              padding: "4px",
                              backgroundColor: "#ECEFF0",
                              borderRadius: "4px 0px 0px 4px",
                            },
                            classes: { input: classes.inputRoot },
                          }}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "newRecipient",
                              e.target.value
                            );
                          }}
                          onKeyPress={(e) => {
                            if (
                              (e.key === "Enter" || e.key === ";") &&
                              formik.values.newRecipient !== ""
                            ) {
                              e.preventDefault();
                              let errorMsg = "";
                              let isError = false;
                              let array = [formik.values.newRecipient.trim()];
                              let arrayToInsert = [];

                              if (array.length !== 0) {
                                array.forEach((input) => {
                                  let val = input.trim();
                                  if (!formik.errors.newRecipient) {
                                    if (formik.values.recipients) {
                                      const isValidEmail =
                                        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\s*;\s*[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})*$/.test(
                                          val
                                        );

                                      if (isValidEmail) {
                                        if (
                                          formik.values.recipients.filter(
                                            (email) => email === val
                                          ).length === 0 &&
                                          arrayToInsert.filter(
                                            (value) => value === val
                                          ).length === 0
                                        ) {
                                          arrayToInsert = [
                                            ...arrayToInsert,
                                            val,
                                          ];
                                        } else {
                                          isError = true;
                                          errorMsg = getLang(
                                            lang,
                                            "message.error.ERROR_DUPLICATE_EMAIL"
                                          );
                                        }
                                      } else {
                                        isError = true;
                                        errorMsg = getLang(
                                          lang,
                                          "message.error.FORM_VALIDATE_EMAIL"
                                        );
                                      }
                                    } else {
                                      isError = true;
                                      errorMsg = getLang(
                                        lang,
                                        "message.error.FORM_VALIDATE_EMAIL"
                                      );
                                    }
                                  }
                                });
                              }
                              if (arrayToInsert.length !== 0 && !isError) {
                                formik.setFieldValue("recipients", [
                                  ...formik.values.recipients,
                                  ...arrayToInsert,
                                ]);
                                formik.setFieldValue("newRecipient", "");
                              }
                              formik.setFieldError("newRecipient", errorMsg);
                            }
                          }}
                          error={formik.errors.newRecipient ? true : false}
                          helperText={formik.errors.newRecipient}
                          {...formik.getFieldProps("newRecipient")}
                          disabled={
                            isSubmitting || formik.values.recipients.length >= 5
                          }
                        />
                        <Box>
                          <Typography
                            variant="caption"
                            style={{ color: "#98A2B3" }}
                          >
                            {getLang(
                              lang,
                              "paragraph.ENTER_MULTIPLE_EMAIL_WITH_SEPARATOR",
                              {
                                separatorName: getLang(
                                  lang,
                                  "label.SEMICOLUN"
                                ).toLowerCase(),
                                separator: ";",
                              }
                            )}
                          </Typography>
                        </Box>
                      </Box>
                    ) : (
                      <Box
                        style={{ backgroundColor: "#F4F7F8" }}
                        p={formik.values.recipients.length ? 1 : 3}
                        mt={1}
                      >
                        {formik.values.recipients.length ? (
                          formik.values.recipients.map((r, index) => (
                            <Box key={index} mt={index ? 1 : 0}>
                              <Typography variant="body2">{r}</Typography>
                            </Box>
                          ))
                        ) : (
                          <Box display="flex" justifyContent="center">
                            <Typography variant="body2">
                              {getLang(lang, "paragraph.NO_RECIPIENT_SET")}
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    )}
                  </Box>
                  <Box mt={3}>
                    <Box display="flex" alignItems="center">
                      {!isFetching && (
                        <Box mr={1} display="flex">
                          {isEdit ? (
                            <AntSwitch
                              checked={formik.values.hasAlert}
                              onChange={(e) => {
                                let newVal = e.target.checked;
                                formik.setFieldValue("hasAlert", newVal);

                                if (!newVal) {
                                  formik.setFieldValue(
                                    "alertThreshold",
                                    alertInfo.alertThreshold
                                  );
                                }
                              }}
                              disabled={isSubmitting}
                            />
                          ) : formik.values.hasAlert ? (
                            <CheckRoundedIcon
                              style={{ color: "#36B37E", fontSize: 20 }}
                            />
                          ) : (
                            <CloseRoundedIcon
                              style={{ color: "#FF3333", fontSize: 20 }}
                            />
                          )}
                        </Box>
                      )}

                      <Typography variant="body2">
                        <b>{getLang(lang, "label.LOW_CREDIT_BALANCE_ALERT")}</b>
                      </Typography>
                    </Box>
                    {isFetching ? (
                      <>
                        <Skeleton variant="text" animation="wave" height={25} />
                        <Skeleton variant="text" animation="wave" height={25} />
                        <Skeleton variant="text" animation="wave" height={25} />
                      </>
                    ) : (
                      formik.values.hasAlert && (
                        <>
                        <Box mt={1}>
                          <Box mb={0.5}>
                            <Typography
                              variant="body2"
                              style={{ color: "#596780" }}
                            >
                              <b>{getLang(lang, "label.LOW_BALANCE_AMOUNT")}</b>
                            </Typography>
                          </Box>
                          <InputTextField
                            variant="filled"
                            size="small"
                            type="number"
                            fullWidth
                            placeholder={getLang(lang, "placeholder.AMOUNT")}
                            InputProps={{
                              style: {
                                padding: "4px",
                                backgroundColor: "#ECEFF0",
                                borderRadius: "4px 0px 0px 4px",
                              },
                              classes: { input: classes.inputRoot },
                              startAdornment: (
                                <InputAdornment>
                                  {disbursementInfo
                                    ? disbursementInfo.currency
                                    : null}
                                </InputAdornment>
                              ),
                            }}
                            onChange={(e) => {
                              formik.setFieldValue(
                                "alertThreshold",
                                e.target.value
                              );
                            }}
                            onKeyPress={(e) => {
                              const disabledKey = ["e", "E", ".", "+", "-"];
                              if (e.key.includes(disabledKey)) {
                                e.preventDefault();
                              }
                            }}
                            error={formik.errors.alertThreshold ? true : false}
                            helperText={formik.errors.alertThreshold}
                            {...formik.getFieldProps("alertThreshold")}
                            disabled={isSubmitting || !isEdit}
                          />
                        </Box>
                        <Box mt={1}>
                          <Box mb={0.5}>
                            <Typography
                              variant="body2"
                              style={{ color: "#596780" }}
                            >
                              <b>{getLang(lang, "label.LOW_TRANSACTION_AMOUNT")}</b>
                            </Typography>
                          </Box>
                          <InputTextField
                            variant="filled"
                            size="small"
                            type="number"
                            fullWidth
                            placeholder={getLang(lang, "placeholder.AMOUNT")}
                            InputProps={{
                              style: {
                                padding: "4px",
                                backgroundColor: "#ECEFF0",
                                borderRadius: "4px 0px 0px 4px",
                              },
                              classes: { input: classes.inputRoot }
                            }}
                            onChange={(e) => {
                              formik.setFieldValue(
                                "transactionThreshold",
                                e.target.value
                              );
                            }}
                            onKeyPress={(e) => {
                              const disabledKey = ["e", "E", ".", "+", "-"];
                              if (e.key.includes(disabledKey)) {
                                e.preventDefault();
                              }
                            }}
                            error={formik.errors.transactionThreshold ? true : false}
                            helperText={formik.errors.transactionThreshold}
                            {...formik.getFieldProps("transactionThreshold")}
                            disabled={isSubmitting || !isEdit}
                          />
                        </Box>
                        </>
                      )
                    )}

                  </Box>
                  </Box>
                  <Box display="flex" justifyContent="flex-end" mt={3}>
                        {isEdit ? (
                          <>
                            <Button
                              color="primary"
                              variant="text"
                              disableElevation
                              onClick={() => {
                                setIsEdit(false);
                                formik.resetForm();
                              }}
                              style={{ marginRight: "4px" }}
                            >
                              {getLang(lang, "label.CANCEL")}
                            </Button>
                            <Button
                              color="primary"
                              variant="contained"
                              disableElevation
                              type="submit"
                              disabled={isSubmitting}
                            >
                              {isSubmitting ? (
                                <CircularProgress
                                  size={20}
                                  style={{ color: "white" }}
                                />
                              ) : (
                                <Typography>
                                  {getLang(lang, "label.UPDATE")}
                                </Typography>
                              )}
                            </Button>
                          </>
                        ) : (
                          <Button
                            color="primary"
                            variant="contained"
                            disableElevation
                            onClick={() => setIsEdit(true)}
                            disabled={isFetching}
                          >
                            {getLang(lang, "label.EDIT")}
                          </Button>
                        )}
                      </Box>
                </Box>
              </form>
            )}
          </Formik>
        )}
      </Paper>
    </Box>
  );
}
