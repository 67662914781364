import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { selectLoading } from "modules/notification"
import ProductTraceabilityPanelComponent from "./productTraceabilityPanel.component"
import { fetchAllScanTrack } from "modules/consumer/redux/action/consumer.action"
import { useRouteMatch } from "react-router-dom"

function ProductTraceabilityPanelContainer() {
  const dispatch = useDispatch()
  const match = useRouteMatch()
  const id = match.params.consumerId

  const traceabilityList = useSelector(state => state.consumer.traceabilityList.data)
  const isFetching = useSelector(state => selectLoading(state, fetchAllScanTrack.typePrefix))
  const isError = useSelector(state => state.consumer.isLoadingScanError)
  const lang = useSelector(state => state.constant.languages)
  const language = useSelector(state => state.profile.language)

  const [recordFocus, setRecordFocus] = useState(null)

  const handleReload = () => {
    dispatch(fetchAllScanTrack({
      id,
      length: 0,
      order: "desc",
      column: "created_at"
    }))
  }

  const updateRecordFocus = (record) => {
    setRecordFocus(record)

    if (record) {
      const el = document.getElementById(`track_record_${record.id}`)
      if (el) {
        el.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
      }
    }
    
  } 

  return (
    <>
      <ProductTraceabilityPanelComponent 
        traceabilityList={traceabilityList}
        isFetching={isFetching}
        language={language}
        isError={isError}
        handleReload={handleReload}
        recordFocus={recordFocus}
        updateRecordFocus={updateRecordFocus}
        lang={lang}
      />
    </>
  )
}

export default ProductTraceabilityPanelContainer
