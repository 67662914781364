import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { generatePaginationOptions } from "lib/helper";
import IncentiveProgramPanelComponent from './incentiveProgramPanel.component';
import {
  getIncentiveProgramListing,
  resetIncentiveProgramList,
  getStockModuleSetting,
  updateProgramStatus,
  deleteIncentiveProgram
} from "modules/stockflow/redux"
import { selectLoading } from 'modules/notification';
import { ROOT } from "../../../config/stockflow.route";
import { useHistory } from "react-router-dom";
import GeneralDialog from 'components/dialog/generalDialog';
import AlertIcon from 'assets/svg/alert.svg';
import SuccessIcon from 'assets/svg/success.svg';
import { NEW_INCENTIVE_PROGRAM_PAGE } from "../../../config/stockflow.route"
import { changeBulkSelectedAllProgram, changeSelectedProgram, changeBulkSelectedAvailableProgram } from "modules/stockflow/redux/slice/stockflowIncentiveProgram.slice";
import { bulkUpdateProgramStatus } from "modules/stockflow/redux/action/stockflowIncentiveProgram.action";
import { STOCKFLOW_DEALER_INCENTIVE_PROGRAM_ADD, STOCKFLOW_DEALER_INCENTIVE_PROGRAM_EDIT } from "lib/constants/accessRights";
import { getLang } from "app/feature/constants";
import {getAllProducts} from "../../../../product-mgmt/redux/action/products.action";

function IncentiveProgramPanelContainer() {
  const dispatch = useDispatch();
  const history = useHistory();

  const hasStockflowAddAccessRight = useSelector(state => state.profile.accessRights.includes(STOCKFLOW_DEALER_INCENTIVE_PROGRAM_ADD));
  const hasStockflowEditAccessRight = useSelector(state => state.profile.accessRights.includes(STOCKFLOW_DEALER_INCENTIVE_PROGRAM_EDIT));
  const lang = useSelector(state => state.constant.languages);
  const [tableFilter, setTableFilter] = useState({
    page: 0,
    rowsPerPage: 25,
    search: "",
    status: null,
    product: [],
  });
  const [paginationOptions, setPaginationOptions] = useState([]);
  const isFetching = useSelector(state => selectLoading(state, getIncentiveProgramListing.typePrefix));
  const isError = useSelector(state => state.stockflowIncentiveProgram.incentiveProgramList.isError);
  const data = useSelector(state => state.stockflowIncentiveProgram.incentiveProgramList.data);
  const selectedUUIDs = useSelector(state => state.stockflowIncentiveProgram.incentiveProgramList.selectedUUIDs);
  const totalFiltered = useSelector(state => state.stockflowIncentiveProgram.incentiveProgramList.totalFiltered);
  const tierLimit = useSelector(state => state.stockflowIncentiveProgram.maxTier);
  const isStatusUpdating = useSelector(state => selectLoading(state, updateProgramStatus.typePrefix))
  const isDeleting = useSelector(state => selectLoading(state, deleteIncentiveProgram.typePrefix))
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [isSearchResult, setIsSearchResult] = useState(false);
  const [isDeactivateDialogOpen, setIsDeactivateDialogOpen] = useState(false)
  const [isActivateDialogOpen, setIsActivateDialogOpen] = useState(false)
  const [targetId, setTargetId] = useState("")
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const productDropdown = useSelector(state => state.products.allProducts);
  const isFetchingProduct = useSelector(state => selectLoading(state, getAllProducts.typePrefix));

  useEffect(() => {
    dispatch(getIncentiveProgramListing({
      length: tableFilter.rowsPerPage,
      start: tableFilter.rowsPerPage * tableFilter.page,
      search: tableFilter.search,
      product: tableFilter.product,
      status: tableFilter.status,
    }));
    return () => {
      dispatch(resetIncentiveProgramList());
    };
  }, [
    tableFilter,
    dispatch
  ])

  useEffect(() => {
    setPaginationOptions(generatePaginationOptions(totalFiltered))
  }, [totalFiltered])

  useEffect(() => {
    dispatch(getStockModuleSetting())
    dispatch(getAllProducts());
  }, [dispatch])

  const handleFetchProgramList = () => {
    dispatch(getIncentiveProgramListing({
      length: tableFilter.rowsPerPage,
      start: tableFilter.rowsPerPage * tableFilter.page,
      search: tableFilter.search,
      product: tableFilter.product.map(item => item.id),
      status: tableFilter.status,
    }));
  }


  const handleSearch = async (value, isSearch) => {
    setTableFilter({
      search: value.search,
      product: value.product.map(item => item.id),
      page: 0,
      rowsPerPage: 25
    })
    handleDeselectAll()
    setIsSearchResult(isSearch)
  }

  const handleSelectAllProgram = (value) => {
    setIsSelectAll(value)
  }

  const handleChangePage = (event, newPage) => {
    setTableFilter((prev) => ({
      ...prev,
      page: newPage
    }))
  }

  const handleChangeRowPerPage = (event) => {
    setTableFilter((prev) => ({
      ...prev,
      page: 0,
      rowsPerPage: event.target.value
    }))
  }

  const handleEdit = ({ id }) => {
    history.push(`${ROOT}/dealer-incentive-program/${id}`)
  }

  const handleChangeStatus = (id, status) => {
    setTargetId(id)
    if (status === "active") {
      setIsDeactivateDialogOpen(true)
    }

    if (status === "inactive") {
      setIsActivateDialogOpen(true)
    }
  }

  const handleStatusSubmit = status => {
    dispatch(updateProgramStatus({
      id: targetId,
      status,
    }))
      .then(() => handleFetchProgramList())
      .finally(() => {
        setIsDeactivateDialogOpen(false);
        setIsActivateDialogOpen(false);
      })
  }

  const handleDelete = (id) => {
    setIsDeleteDialogOpen(true)
    setTargetId(id)
  }

  const handleDeleteSubmit = () => {
    dispatch(deleteIncentiveProgram({
      id: targetId
    }))
      .then(() => handleFetchProgramList())
      .finally(() => {
        setIsDeleteDialogOpen(false)
      })
  }

  const handleAddIncentiveProgram = () => {
    history.push( NEW_INCENTIVE_PROGRAM_PAGE )
  }

  const handleSelectProgram = (index, isSelected) => {
    dispatch(changeSelectedProgram({ index: index, isSelected: isSelected }))
  }

  const handleSelectAvailable = () => {
    setIsSelectAll(false)
    dispatch(changeBulkSelectedAvailableProgram({ value: true }))
  }

  const handleDeselectAll = () => {
    setIsSelectAll(false)
    dispatch(changeBulkSelectedAllProgram({ value: false }))
  }

  const handleSelectAll = () => {
    setIsSelectAll(true)
    dispatch(changeBulkSelectedAllProgram({ value: true }))
  }

  const handleBulkUpdateProgramStatus = (value) => {

    const request = {
      status: value === getLang(lang,'label.ACTIVATE_SELECTED') ? 1 : 2,
      isSelectAll: isSelectAll,
      programUUIDs: selectedUUIDs,
      filter: {
        search: tableFilter.search,
        product: tableFilter.product.map(item => item.id),
      }
    }

    dispatch(bulkUpdateProgramStatus(request))
      .then(() => {
        handleFetchProgramList()
        handleDeselectAll()
      })
  }

  return (
    <>
      <IncentiveProgramPanelComponent
        hasStockflowAddAccessRight={hasStockflowAddAccessRight}
        hasStockflowEditAccessRight={hasStockflowEditAccessRight}
        handleSearch={handleSearch}
        isFetching={isFetching}
        handleSelectAllProgram={handleSelectAllProgram}
        isError={isError}
        isSelected={(!isSelectAll && selectedUUIDs.length > 0) || (isSelectAll && selectedUUIDs.length < totalFiltered)}
        isSelectAll={isSelectAll}
        selectedCount={!isSelectAll ? selectedUUIDs.length : totalFiltered - selectedUUIDs.length}
        selectedUUIDs={selectedUUIDs}
        rows={data}
        tierLimit={tierLimit}
        isSearchResult={isSearchResult}
        page={tableFilter.page}
        rowsPerPage={tableFilter.rowsPerPage}
        totalFiltered={totalFiltered}
        paginationOptions={paginationOptions}
        handleChangePage={handleChangePage}
        handleChangeRowPerPage={handleChangeRowPerPage}
        handleEdit={handleEdit}
        handleChangeStatus={handleChangeStatus}
        handleDelete={handleDelete}
        handleAddIncentiveProgram={handleAddIncentiveProgram}
        handleSelectProgram={handleSelectProgram}
        handleSelectAll={handleSelectAll}
        handleDeselectAll={handleDeselectAll}
        handleSelectAvailable={handleSelectAvailable}
        handleBulkUpdateProgramStatus={handleBulkUpdateProgramStatus}
        productDropdown={productDropdown}
        isFetchingProduct={isFetchingProduct}
        lang={lang}
      />
      <GeneralDialog
        isOpen={isDeactivateDialogOpen}
        isLoading={isStatusUpdating}
        handleClose={() => setIsDeactivateDialogOpen(false)}
        handleProceed={() => handleStatusSubmit(2)}
        title={getLang(lang, 'label.DEACTIVE_PROGRAM')}
        description={getLang(lang, 'paragraph.DO_YOU_WANT_TO_DEACTIVE_PROGRAM')}
        type="danger"
        icon={<img src={AlertIcon} alt="" />}
      />
      <GeneralDialog
        isOpen={isActivateDialogOpen}
        isLoading={isStatusUpdating}
        handleClose={() => setIsActivateDialogOpen(false)}
        handleProceed={() => handleStatusSubmit(1)}
        title={getLang(lang, 'label.ACTIVATE_PROGRAM')}
        description={getLang(lang, 'paragraph.DO_YOU_WANT_TO_ACTIVE_PROGRAM')}
        type="success"
        icon={<img src={SuccessIcon} alt="" />}
      />
      <GeneralDialog
        isOpen={isDeleteDialogOpen}
        isLoading={isDeleting}
        handleClose={() => setIsDeleteDialogOpen(false)}
        handleProceed={() => handleDeleteSubmit()}
        title={getLang(lang, 'label.DELETE_PROGRAM')}
        description={getLang(lang, 'paragraph.DO_YOU_WANT_TO_DELETE_PROGRAM')}
        type="danger"
        icon={<img src={AlertIcon} alt="" />}
      />
    </>
  );
}

export default IncentiveProgramPanelContainer;
