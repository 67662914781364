import { selectLoading } from 'modules/notification'
import { getSurvey } from 'modules/survey/redux/action'
import React from 'react'
import { useSelector } from 'react-redux'
import EditSurveyDialogComponent from './editSurveyDialog.component'

export default function EditSurveyDialogContainer({
    isOpen,
    handleClose,
    handleSubmit
}) {
    const isLoading = useSelector(state => selectLoading(state, getSurvey.typePrefix))
    const survey = useSelector(state => state.survey.survey)

    return (
        <EditSurveyDialogComponent
            isOpen={isOpen}
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            survey={survey}
            isLoading={isLoading}
        />
    )
}
