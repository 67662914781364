import React from "react";

import { getLang } from "app/feature/constants";

import InputTextField from "components/input/inputTextField";
import CurrencySelect from "modules/account/components/select/currencySelect";
import LoadingComponent from "components/loading";
import { NumericFormat } from 'react-number-format';


import {
  Box,
  Typography,
  IconButton,
  Button,
  Card,
  Input,
  makeStyles
} from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";

const style = (theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    flexGrow: 1,
    backgroundColor: "white",
  },
  loading: {
    width: "50rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    flexGrow: 1,
    backgroundColor: "white",
  },
  field: {
    display: "grid",
    gridTemplateColumns: "150px 400px",
    columnGap: theme.spacing(2)
  },
});

const useStyles = makeStyles(style);


export default function ReimbursementFormComponent({
  lang,
  submitType,
  currencyDropdown,
  isCurrencyFetching,
  filePreviews,
  handleFileUpload,
  handleFileRemove,
  handleMediaClick,
  isFileUploading,
  initialValues,

  //  SUBMIT BUTTON  //
  children,
  handleReimbursementSubmit,
}) {
  const classes = useStyles();

  let currencyCode = "";
  for (let i = 0; i < currencyDropdown.length; i++) {
    if (currencyDropdown[i].isDefault)
      currencyCode = currencyDropdown[i].alpha3;
  }

  return (
    isCurrencyFetching
      ? (
        <Box className={classes.loading} p={5} >
          <LoadingComponent />
        </Box>
      ) :
      <>
        <Formik
          enableReinitialize
          initialValues={{
            currencyCode: initialValues.currencyCode ?
              currencyDropdown.find(({ alpha3 }) => alpha3 === initialValues.currencyCode) :
              currencyDropdown.find(({ alpha3 }) => alpha3 === currencyCode),
            currencyExp: initialValues.currencyExp,
            amount: Number(initialValues.amount).toFixed(initialValues.currencyExp),
            receiptProof: submitType === 'create' ? initialValues.receiptProof : filePreviews,
            remark: initialValues.remark
          }}
          validationSchema={Yup.object({
            amount: Yup.number().moreThan(0).required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
            receiptProof: Yup.lazy((value) => {
              return Yup.array().min(1, getLang(lang, "message.error.FORM_VALIDATE_REQUIRED"));
            }),
            remark: Yup.string().trim().required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
          })}
          onSubmit={handleReimbursementSubmit}
        >
          {(formik) => (
            <form className={classes.root} onSubmit={formik.handleSubmit}>
              <Box style={{ overflowY: "auto" }} display="flex" height="100%" flexDirection="column" flexGrow={1}>
                <Box pt={3} px={3} className={classes.field}>
                  <Box display="flex" pt={true ? 1.5 : 0}>
                    {getLang(lang, "label.SHIPPING_FEE")} <Typography style={{ color: "#DE350B", display: 'inline-block' }}>*</Typography>
                  </Box>

                  <Box style={{ display: "flex" }}>
                    <CurrencySelect
                      currencyDropdownData={currencyDropdown}
                      textFieldProps={{
                        style: {
                          width: "150px",
                          marginRight: "10px",
                          borderRadius: "4px",
                          backgroundColor: "#ECEFF0"
                        },
                        error: formik.errors.currencyCode && formik.touched.currencyCode ? true : false,
                        helperText: ErrorMessage({ name: "currencyCode" })
                      }}
                      defaultValue={{ name: formik.values.currencyCode, alpha3: formik.values.currencyCode ? formik.values.currencyCode : "" }}
                      formProps={{
                        ...formik.getFieldProps("currencyCode"),
                        value: formik.values.currencyCode,
                        onChange: (e, value) => {
                          formik.setFieldValue("currencyCode", value)
                          formik.setFieldValue("currencyExp", value.exp)
                          formik.setFieldValue("amount", Number(formik.values.amount).toFixed(value.exp))
                        },
                        disabled: formik.isSubmitting
                      }}
                      type={"currencyCode"}
                    />
                    <NumericFormat
                      customInput={InputTextField}
                      thousandSeparator=","
                      decimalScale={2}
                      placeholder={getLang(lang, "placeholder.ENTER_SHIPPING_FEE")}
                      value={formik.values.amount.replace(/,/g, '')}
                      onChange={(e) => {
                        formik.setFieldValue("amount",e.target.value.replace(/,/g, ''));
                      }}
                      error={formik.touched.amount && formik.errors.amount ? true : false}
                      helperText={ErrorMessage({ name: 'amount' })}
                    />

                    {/* <InputTextField
                      hiddenLabel
                      variant="filled"
                      size="small"
                      margin="none"
                      placeholder={getLang(lang, "placeholder.ENTER_SHIPPING_FEE")}
                      fullWidth
                      value={formik.values.amount ? parseFloat(formik.values.amount).toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      }) : ''}
                      onChange={(e) => {
                        let regex = new RegExp(`([0-9]*[.,]?[0-9]{0,${formik.values.currencyExp}})`);
                        let value = e.target.value.match(regex)[0];
                        value = value.replace(',', '');
                        formik.setFieldValue("amount", value);
                      }}
                      error={formik.touched.amount && formik.errors.amount ? true : false}
                      helperText={ErrorMessage({ name: 'amount' })}
                    /> */}
                  </Box>
                </Box>

                <Box pt={3} px={3} className={classes.field}>
                  <Box display="flex" pt={true ? 1.5 : 0}>
                    {getLang(lang, "label.RECEIPT_PROOF")} <Typography style={{ color: "#DE350B", display: 'inline-block' }}>*</Typography>
                  </Box>

                  <Box mt={1} py={1} display="flex" flexWrap="no-wrap" style={{ overflowX: "auto" }}>
                    <Box mr={1} border={1} borderColor="#E5E5E5">
                      <Card>
                        <Button
                          style={{ height: "70px", width: "70px", padding: "0" }}
                          disabled={isFileUploading || formik.isSubmitting}
                        >
                          <AddIcon color="primary" style={{ position: "absolute", left: "24px", right: "0px", top: "23px" }} />
                          <Input
                            fullWidth
                            type="file"
                            name="receiptProof"
                            inputProps={{ style: { 'opacity': 0, height: "70px" }, multiple: true, accept: "image/*,video/*" }}
                            style={{ cursor: "pointer" }}
                            onChange={(e) => {
                              handleFileUpload(e.currentTarget.files).then((value) => {
                                formik.setFieldValue('receiptProof', value);
                              });
                              e.currentTarget.value = null;
                            }}
                            title=""
                          />
                        </Button>
                      </Card>
                    </Box>

                    {filePreviews.length > 0 && filePreviews.map((file, index) => (
                      <Box mr={1} border={1} borderColor="#E5E5E5">
                        <Card>
                          <Box style={{ height: "70px", width: "70px" }}>
                            {(file.file.type.includes('video') && file.src !== null) &&
                              <video
                                width="70"
                                height="70"
                                style={{ verticalAlign: "middle" }}
                                preload="metadata"
                                muted
                                onClick={() => handleMediaClick(file.src, 'video')}>
                                <source src={file.src} />
                              </video>
                            }
                            {(file.file.type.includes('image') && file.src !== null) && (
                              <img
                                height="70px"
                                width="70px"
                                style={{ objectFit: "contain" }}
                                src={file.src}
                                alt="img"
                                onClick={() => handleMediaClick(file.src, 'image')}
                              />
                            )}
                            {file.src === null && (
                              <Box
                                height="100%"
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                alignItems="center"
                              >
                                <Box fontSize={12} color="text.disabled">
                                  {getLang(lang, "label.UPLOADING")}
                                </Box>
                              </Box>
                            )}

                            <Box position="relative" top="-70px" right="-45px">
                              <IconButton
                                onClick={() => {
                                  const temp = [...filePreviews];
                                  temp.splice(index, 1);

                                  const files = temp.map((file => {
                                    return {
                                      name: file.file.name,
                                      size: file.file.size,
                                      type: file.file.type,
                                    }
                                  }));

                                  formik.setFieldValue('receiptProof', files);
                                  handleFileRemove(index)
                                }}
                                style={{ padding: 0, color: 'red' }}
                              >
                                <CancelIcon />
                              </IconButton>
                            </Box>
                          </Box>
                        </Card>
                      </Box>
                    ))}
                  </Box>
                  {formik.errors.receiptProof && (
                    <>
                      <Box />
                      <Box color="#f44336" fontSize={12} mb={1}>
                        {formik.errors.receiptProof}
                      </Box>
                    </>
                  )}
                  <Box />
                  <Box color="text.disabled" fontSize={12}>
                    {getLang(lang, "paragraph.MAX_FILE_UPLOAD_SIZE", { imageSize: '30MB', fileSize: '100MB' })}
                  </Box>
                </Box>

                <Box p={3} className={classes.field}>
                  <Box display="flex" alignItems="center">
                    {getLang(lang, "label.REMARK")} <Typography style={{ color: "#DE350B", display: 'inline-block' }}>*</Typography>
                  </Box>
                  <InputTextField
                    hiddenLabel
                    multiline
                    rows={4}
                    variant="filled"
                    size="small"
                    margin="none"
                    placeholder={getLang(lang, "placeholder.ENTER_REMARK")}
                    fullWidth
                    {...formik.getFieldProps("remark")}
                    error={formik.touched.remark && formik.errors.remark ? true : false}
                    helperText={ErrorMessage({ name: "remark" })}
                  />
                </Box>
              </Box>

              {children ? children(formik.isSubmitting) : null}
            </form>
          )}
        </Formik >
      </>
  );
}