import React from "react";

export default function VerticalBarChartIcon(){
    return (
        <svg width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.56152 17.2588L20.4387 17.2588" stroke="#344054" stroke-width="1.5731" stroke-linecap="round"/>
            <path d="M2.60986 2.83882C2.60986 2.54922 2.84463 2.31445 3.13423 2.31445H4.70732C4.99692 2.31445 5.23169 2.54922 5.23169 2.83882V14.8992H2.60986V2.83882Z" fill="#344054"/>
            <path d="M7.3291 1.26558C7.3291 0.975977 7.56387 0.741211 7.85347 0.741211H9.42656C9.71616 0.741211 9.95093 0.975977 9.95093 1.26558V14.8991H7.3291V1.26558Z" fill="#344054"/>
            <path d="M12.0488 5.98481C12.0488 5.69522 12.2836 5.46045 12.5732 5.46045H14.1463C14.4359 5.46045 14.6707 5.69522 14.6707 5.98481V14.899H12.0488V5.98481Z" fill="#344054"/>
            <path d="M16.7681 4.93647C16.7681 4.64688 17.0028 4.41211 17.2924 4.41211H18.8655C19.1551 4.41211 19.3899 4.64688 19.3899 4.93647V14.8994H16.7681V4.93647Z" fill="#344054"/>
        </svg>
    )
}