import axios from "axios"

export default function updateLoyaltyPoint({
    id,
    point,
    modules,
    status }) {
    return new Promise((resolve, reject) => {
        axios.put(`/campaign/api/admin/campaigns/${id}`,
            {
                amount: point,
                productId: modules,
                status
            })
            .then(response => resolve(response.data))
            .catch(error => reject(error.response.data))
    })
}