import React, { memo } from "react";
import { GoogleMap, OverlayView } from "@react-google-maps/api";
import { Box, makeStyles } from "@material-ui/core";
import ReportWarningIcon from "assets/img/reportWarning.png";

const containerStyle = {
  width: "100%",
  height: "350px",
};

const mapOptions = {
  mapTypeControl: false,
  streetViewControl: false,
  rotateControl: false,
  fullscreenControl: false,
  clickableIcons: false,
  keyboardShortcuts: false,
};

const useStyles = makeStyles((theme) => ({
  activePin: {
    backgroundColor: "#35CA91",
    borderRadius: "50%",
    height: 70,
    width: 70,
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    cursor: "pointer",
    pointerEvents: "auto",
    "&::after": {
      content: '""',
      position: "absolute",
      left: 0,
      right: 0,
      margin: "0 auto",
      width: 0,
      height: 0,
      borderTop: "9px solid #35CA91",
      borderLeft: "6px solid transparent",
      borderRight: "6px solid transparent",
      bottom: -6,
    },
  },
  suspendPin: {
    backgroundColor: "#F97066",
    borderRadius: "50%",
    height: 70,
    width: 70,
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    animation: `$suspendPulse 2s infinite ${theme.transitions.easing.easeInOut}`,
    cursor: "pointer",
    pointerEvents: "auto",
    "&::after": {
      content: '""',
      position: "absolute",
      left: 0,
      right: 0,
      margin: "0 auto",
      width: 0,
      height: 0,
      borderTop: "9px solid #F97066",
      borderLeft: "6px solid transparent",
      borderRight: "6px solid transparent",
      bottom: -6,
    },
  },
  "@keyframes suspendPulse": {
    "0%": {
      boxShadow: "0 0 0 0 rgba(249, 112, 102, 0.8)",
    },
    "100%": {
      boxShadow: "0 0 0 12px rgba(249, 112, 102, 0)",
    },
  },
  report: {
    top: -9,
    right: -9,
    height: 25,
    width: 25,
    position: "absolute",
    backgroundImage: `url(${ReportWarningIcon})`,
    backgroundSize: "contain",
  },
  productPic: {
    height: 62,
    width: 62,
    borderRadius: "50%",
    objectFit: "contain",
    border: "1px solid #E4E7EC",
  },
}));

function DashboardSingleScanMapComponent({ center, isLoaded, detail }) {
  const classes = useStyles();
  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={{
        lat: center.lat,
        lng: center.lng,
      }}
      zoom={13}
      options={mapOptions}
    >
      <OverlayView
        position={center}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        getPixelPositionOffset={(offsetWidth, offsetHeight) => ({
          x: -(offsetWidth / 2),
          y: -(offsetHeight / 2) - 27,
        })}
      >
        <Box className={classes[`${detail.status}Pin`]}>
          <img
            src={detail.product_image}
            className={classes.productPic}
            alt="product"
          />
          {!!detail.counterfeit_report && <Box className={classes.report} />}
        </Box>
      </OverlayView>
    </GoogleMap>
  ) : (
    <></>
  );
}

export default memo(DashboardSingleScanMapComponent);
