import axios from "axios";

const endpoint_url = "/account/api/v1/access-rights";

const get = (parameters) => {
  return new Promise((resolve, reject) => {
    axios
      .get(endpoint_url, {
        params: {
          ...parameters
        }
      })
      .then((response) => resolve(response))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

const getAllAccessRight = {
  get
};

export default getAllAccessRight;
