import React, { useEffect, useRef } from "react";
import {
  Box,
  Divider,
  Grid,
  makeStyles,
  Typography
} from "@material-ui/core";
import PercentageBar from "../../percentageBar";

const useStyles = makeStyles(() => ({
  gridItem: {
    paddingTop: "8px !important",
    paddingBottom: "8px !important"
  }
}));

export default function ScanLocationStateItemListComponent({ scanData, index, item, targetCountry }) {
  const classes = useStyles();
  const ref = useRef();

  useEffect(() => {
    if (ref && ref.current && targetCountry === item.country) {
      ref.current.scrollIntoView()
    }
  }, [ref, targetCountry, item.country])

  return (
    <Box ref={ref} style={{ paddingTop: 20 }}>
      <Typography color='primary' variant='subtitle2' gutterBottom >{item.country}</Typography>
      <Grid container spacing={3} style={{ alignItems: "flex-end" }}>
        {item.state.map((state, index) => (
          <Grid
            key={index}
            item
            xs={6}
            classes={{ item: classes.gridItem }}
          >
            <PercentageBar
              label={state.name}
              percentage={parseFloat(((state.scanCount / item.scanCount) * 100).toFixed(2))}
              count={state.scanCount}
            />
          </Grid>
        ))}
      </Grid>
      {scanData.length - 1 > index && (
        <Divider style={{ marginTop: 20 }} />
      )}
    </Box>
  )
}