import React from "react";
import {
  makeStyles,
  Typography,
  Box,
  Grid,
  Link,
  Divider,
  Button
} from "@material-ui/core";

import Confirmation from "../../../utils/image/confirmation.png";
import ArrowLogout from "../../../utils/image/logout-arrow.png";
import Click from "../../../utils/image/click.png";
import Exclaimation from "../../../utils/image/exclamation-button.png";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import LoadingComponent from "components/loading";
import { displayValueMessage } from "lib/helper";
import { getLang } from "app/feature/constants";

const style = theme => ({
  root: {
    width: "100%"
  },
  verification: {
    padding: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      paddingRight: "4%",
      paddingLeft: "4%"
    }
  },
  title: {
    width: "100%",
    marginTop: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      marginLeft: theme.spacing(2)
    }
  },
  arrow: {
    display: "inline-block",
    marginLeft: theme.spacing(1)
  },
  image: {
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      width: "100%"
    },
    [theme.breakpoints.down("xs")]: {
      marginRight: theme.spacing(1)
    }
  },
  grid: {
    flexWrap: "nowrap",
    paddingRight: theme.spacing(1)
  },
  gridConfirm: {
    flexWrap: "nowrap",
    borderBottomStyle: "groove",
    borderColor: "#E5E5E5",
    borderBottom: "solid 1px",
    paddingBottom: theme.spacing(3),
    paddingRight: theme.spacing(1)
  },
  logout: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      paddingLeft: "17%"
    }
  },
  logoutBox: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "center"
    }
  },
  resendBtn: {
    textTransform: "none",
    padding: "10px 0px",
    textAlign: "left",
    lineHeight: 1.4
  }
});

const useStyle = makeStyles(style);

export default function VerificationFormComponent({
  isLoading,
  email,
  handleLogout,
  handleResendEmailVerification,
  isMobile,
  counter,
  lang
}) {
  const classes = useStyle();
  return (
    <Box className={classes.root}>
      <Box className={classes.verification}>
        <Grid container className={classes.gridConfirm}>
          <Grid item xs={4} md={4} className={classes.image}>
            <img src={Confirmation} alt="Confirmation" />
          </Grid>
          <Grid item xs={8} md={8} className={classes.title}>
            <Typography variant="body1">
              {getLang(lang,"paragraph.CONFIRMATION_EMAIL_SENT")}:&nbsp;
              <Typography variant="body1"  style={{ wordWrap: "break-word" }}>
              {isMobile ? (
                <>
                  <br />
                  <b>{email}</b>
                </>
              ) : (
                <b>{email}</b>
              )}
              </Typography>            
            </Typography>
            <br />
            <Typography variant="subtitle2" color="textSecondary">
              {getLang(lang,"paragraph.NOT_RECEIVING_EMAIL_QUESTION")}
            </Typography>
            {
              isLoading
                ?
                <Button
                  color="secondary"
                  classes={{ root: classes.resendBtn, label: classes.resendBtnLabel }}
                  disabled={true}
                >
                  <LoadingComponent />
                </Button>
                :
                <Button
                  color="secondary"
                  classes={{ root: classes.resendBtn, label: classes.resendBtnLabel }}
                  onClick={handleResendEmailVerification}
                  endIcon={<ArrowForwardIcon />}
                  disabled={counter > 0}
                >
                  {counter === 0 ? getLang(lang,"label.RESEND_CONFIRMATION_EMAIL") : displayValueMessage(getLang(lang,"paragraph.TRY_RESEND_EMAIL"), {count: counter})}
                </Button>
            }
          </Grid>
        </Grid>

        <Box pt={2} mb={3}>
          <Grid container className={classes.grid}>
            <Grid item xs={4} md={4} className={classes.image}>
              <img src={Click} alt="CLick" />
            </Grid>
            <Grid item xs={8} md={8} className={classes.title}>
              <Typography variant="body1">
                {getLang(lang,"paragraph.LINK_ACTIVATE_ACCOUNT")}
              </Typography>
              <br />
              <Typography
                variant="subtitle2"
                color="textSecondary"
                style={{ alignItems: "center", display: "flex" }}
              >
                <img src={Exclaimation} alt="exclaimation" />
                &ensp;{getLang(lang,"paragraph.CHECK_JUNK_FOLDER")}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box className={classes.logoutBox}>
        <Divider mt={3} p={1} />
        <Link
          component="button"
          variant="subtitle2"
          color="primary"
          className={classes.logout}
          onClick={handleLogout}
        >
          {getLang(lang,"label.LOGOUT_UPPERCASE")}
        </Link>
        <Box className={classes.arrow}>
          <img src={ArrowLogout} alt="Arrow Logout" />
        </Box>
      </Box>
    </Box>
  );
}
