import axios from "axios"

export default function updateGift({
    id,
    name,
    description,
    imgUrl,
    point,
    quantity,
    itemPerCustomer,
    status,
    startAt,
    endAt
}) {
    return new Promise((resolve, reject) => {
        axios.put(`/campaign/api/admin/items/${id}`,
            {
                name,
                description,
                imgUrl,
                point,
                quantity,
                itemPerCustomer,
                status,
                startAt,
                endAt
            })
            .then(response => resolve(response.data))
            .catch(error => reject(error.response.data))
    })
}