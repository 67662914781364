import axios from 'axios';

export function editAttribute({
  id,
  name,
  status,
  options
}) {
  const url =  `/serialnumber/api/v1/product/attribute/update/${id}`;

  return new Promise((resolve, reject) => {
    axios.post(url, {
      name: name,
      status: status,
      options: options,
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data))
  })
}