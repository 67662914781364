import React from "react";
import { Box, Link, Paper, Typography, makeStyles } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { getLang } from "app/feature/constants";
import { useHistory } from "react-router-dom";
import { ROOT } from "modules/stockflow/config/stockflow.route";

const useStyles = makeStyles((theme) => ({
  list: {
    maxHeight: "45vh",
    overflowY: "auto",
    padding: "16px",
    "&::-webkit-scrollbar": {
      width: "10px",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "16px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#E4E7EC",
      borderRadius: "16px",
    },
  },
  looseItemContainer: {
    marginBottom: "16px",
    padding: "20px 16px",
    borderRadius: "8px",
  },
}));

export default function LooseItemListPanelsComponent({
  packageDetail,
  isFetching,
  lang,
}) {
  const history = useHistory();
  const classes = useStyles();

  return (
    <Paper elevation={0}>
      {!isFetching && packageDetail ? (
        <Box p={0.5}>
          <Box px={2} pt={2}>
            <Typography variant="body1">
              <b>
                {packageDetail.total_loose_item_count.toLocaleString("en-US")}{" "}
                {getLang(lang, "label.LOOSE_ITEMS")}
              </b>
            </Typography>
          </Box>
          <Box className={classes.list}>
            {packageDetail.loose_items.map((item, index) => (
              <Paper
                key={item.id}
                elevation={3}
                className={classes.looseItemContainer}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box display="flex" alignItems="center">
                    <Typography variant="body2" style={{ marginRight: 10 }}>
                      <b>{index + 1}.</b>
                    </Typography>
                    <Link
                      href={
                        item.type === "Pack"
                          ? `${ROOT}/inventory/package/${item.uuid}`
                          : `${ROOT}/inventory/loose-item/detail?code=${item.name}`
                      }
                      color="secondary"
                      onClick={(e) => {
                        if (item.type === "Pack") {
                          history.push(
                            `${ROOT}/inventory/package/${item.uuid}`
                          );
                        } else {
                          history.push(
                            `${ROOT}/inventory/loose-item/detail?code=${item.name}`
                          );
                        }
                        e.preventDefault();
                        return false;
                      }}
                    >
                      <Typography variant="body2" style={{ marginRight: 10 }}>
                        <b>{item.name}</b>
                      </Typography>
                    </Link>
                  </Box>
                  <Typography variant="body2">
                    {item.quantity.toLocaleString("en-US")}
                  </Typography>
                </Box>
              </Paper>
            ))}
          </Box>
        </Box>
      ) : (
        <Box p={2}>
          <Skeleton variant="text" height={40} mt={2} />
          <Skeleton variant="text" height={60} mt={2} />
          <Skeleton variant="text" height={60} mt={2} />
          <Skeleton variant="text" height={60} mt={2} />
        </Box>
      )}
    </Paper>
  );
}
