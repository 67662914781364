import { createSlice } from "@reduxjs/toolkit";
import DefaultImg from "assets/img/defaultPhoto.png";
import {
  STOCKFLOWINVENTORY,
  getInventoryProductDetail,
  getInventoryProductStockList,
  getSerialNumberActivityLog,
  getInventoryPackageListing,
  getInventoryPackageDetail,
  getPackageActivityLog,
  getInventoryBoxListing,
  getInventoryBoxDetail,
  getBoxActivityLog,
  getStockProductDropdown,
  getStockHolderDropdown,
  getInventoryLooseItemListing,
  getInventoryLooseItemDetail,
  getInventoryLooseItemStockList,
  getProductListingByBranch,
  getProductListingByDealer,
  getBranchInventory,
  getDealerInventory,
} from "../action/stockflowInventory.action";

const initialState = {
  products: [],
  product: null,
  productStockList: [],
  selectedSerialNumber: null,
  activityLog: [],
  isLoadingProductListError: false,
  isLoadingProductError: false,
  isLoadingProductStockListError: false,
  isLoadingActivityLogError: false,
  packages: [],
  package: null,
  isLoadingPackageListError: false,
  isLoadingPackageError: false,
  boxes: [],
  box: null,
  isLoadingBoxListError: false,
  isLoadingBoxError: false,
  looseItems: [],
  looseItem: null,
  looseItemStockList: [],
  isLoadingLooseItemListError: false,
  isLoadingLooseItemError: false,
  isLoadingLooseItemStockListError: false,
  isSelectAll: false,
  selectedId: [],
  totalRecords: 0,
  totalFiltered: 0,
  stockProductDropdown: [],
  stockHolderDropdown: [],
};

const stockflowInventorySlice = createSlice({
  name: STOCKFLOWINVENTORY,
  initialState,
  reducers: {
    changeSelectedProduct(state, action) {
      const { payload } = action;

      state.products[payload.index].isSelected = payload.isSelected;
      if (
        (!state.isSelectAll && payload.isSelected) ||
        (state.isSelectAll && !payload.isSelected)
      ) {
        state.selectedId = [
          ...state.selectedId,
          state.products[payload.index].id,
        ];
      } else {
        state.selectedId = state.selectedId.filter(
          (id) => id !== state.products[payload.index].id
        );
      }
    },
    changeBulkSelectedProduct(state, action) {
      const { payload } = action;

      state.products.forEach((product) => {
        product.isSelected = payload.value;
      });
      state.isSelectAll = payload.value;
      state.selectedId = [];
    },
    changeBulkSelectedAvailableProduct(state, action) {
      const { payload } = action;
      state.products.forEach((product) => {
        product.isSelected = payload.value;
        if (
          (!state.isSelectAll && payload.value) ||
          (state.isSelectAll && !payload.value)
        ) {
          if (state.selectedId.findIndex((id) => id === product.id) === -1) {
            state.selectedId = [...state.selectedId, product.id];
          }
        } else {
          state.selectedId = state.selectedId.filter((id) => id !== product.id);
        }
      });
    },
    changeSelectedSerialNumber(state, action) {
      const { payload } = action;
      state.selectedSerialNumber = payload;
    },
    changeSelectedPackage(state, action) {
      const { payload } = action;

      state.packages[payload.index].isSelected = payload.isSelected;
      if (
        (!state.isSelectAll && payload.isSelected) ||
        (state.isSelectAll && !payload.isSelected)
      ) {
        state.selectedId = [
          ...state.selectedId,
          state.packages[payload.index].id,
        ];
      } else {
        state.selectedId = state.selectedId.filter(
          (id) => id !== state.packages[payload.index].id
        );
      }
    },
    changeBulkSelectedPackage(state, action) {
      const { payload } = action;

      state.packages.forEach((pkg) => {
        pkg.isSelected = payload.value;
      });
      state.isSelectAll = payload.value;
      state.selectedId = [];
    },
    changeBulkSelectedAvailablePackage(state, action) {
      const { payload } = action;
      state.packages.forEach((pkg) => {
        pkg.isSelected = payload.value;
        if (
          (!state.isSelectAll && payload.value) ||
          (state.isSelectAll && !payload.value)
        ) {
          if (state.selectedId.findIndex((id) => id === pkg.id) === -1) {
            state.selectedId = [...state.selectedId, pkg.id];
          }
        } else {
          state.selectedId = state.selectedId.filter((id) => id !== pkg.id);
        }
      });
    },
    changeSelectedBox(state, action) {
      const { payload } = action;

      state.boxes[payload.index].isSelected = payload.isSelected;
      if (
        (!state.isSelectAll && payload.isSelected) ||
        (state.isSelectAll && !payload.isSelected)
      ) {
        state.selectedId = [...state.selectedId, state.boxes[payload.index].id];
      } else {
        state.selectedId = state.selectedId.filter(
          (id) => id !== state.boxes[payload.index].id
        );
      }
    },
    changeBulkSelectedBox(state, action) {
      const { payload } = action;

      state.boxes.forEach((box) => {
        box.isSelected = payload.value;
      });
      state.isSelectAll = payload.value;
      state.selectedId = [];
    },
    changeBulkSelectedAvailableBox(state, action) {
      const { payload } = action;
      state.boxes.forEach((box) => {
        box.isSelected = payload.value;
        if (
          (!state.isSelectAll && payload.value) ||
          (state.isSelectAll && !payload.value)
        ) {
          if (state.selectedId.findIndex((id) => id === box.id) === -1) {
            state.selectedId = [...state.selectedId, box.id];
          }
        } else {
          state.selectedId = state.selectedId.filter((id) => id !== box.id);
        }
      });
    },
    resetProductList(state) {
      state.products = initialState.products;
      state.totalFiltered = initialState.totalFiltered;
      state.totalRecords = initialState.totalRecords;
      state.isSelectAll = initialState.isSelectAll;
      state.selectedId = initialState.selectedId;
    },
    resetProductStockListing(state) {
      state.productStockList = initialState.productStockList;
      state.totalFiltered = initialState.totalFiltered;
      state.totalRecords = initialState.totalRecords;
      state.isSelectAll = initialState.isSelectAll;
      state.selectedId = initialState.selectedId;
    },
    resetActivityLog(state) {
      state.activityLog = initialState.activityLog;
      state.selectedSerialNumber = initialState.selectedSerialNumber;
    },
    resetPackageList(state) {
      state.packages = initialState.packages;
      state.totalFiltered = initialState.totalFiltered;
      state.totalRecords = initialState.totalRecords;
      state.isSelectAll = initialState.isSelectAll;
      state.selectedId = initialState.selectedId;
    },
    resetBoxList(state) {
      state.boxes = initialState.boxes;
      state.totalFiltered = initialState.totalFiltered;
      state.totalRecords = initialState.totalRecords;
      state.isSelectAll = initialState.isSelectAll;
      state.selectedId = initialState.selectedId;
    },
    resetLooseItemList(state) {
      state.looseItems = initialState.looseItems;
      state.totalFiltered = initialState.totalFiltered;
      state.totalRecords = initialState.totalRecords;
    },
    resetLooseItemStockListing(state) {
      state.looseItemStockList = initialState.looseItemStockList;
      state.totalFiltered = initialState.totalFiltered;
      state.totalRecords = initialState.totalRecords;
    },
  },
  extraReducers: {
    [getProductListingByBranch.pending]: (state) => {
      state.products = initialState.products;
      state.totalFiltered = initialState.totalFiltered;
      state.totalRecords = initialState.totalRecords;
      state.isLoadingProductListError = false;
    },
    [getProductListingByBranch.fulfilled]: (state, action) => {
      const { payload } = action;
      const temp = [];
      payload.data.forEach((item) => {
        let selectedState = !state.isSelectAll
          ? state.selectedId.findIndex((id) => id === item.id) !== -1
          : state.selectedId.findIndex((id) => id === item.id) === -1;

        temp.push({
          id: item.id,
          uuid: item.uuid,
          picture: item.picture ?? DefaultImg,
          attributes: item.attributes,
          name: item.name,
          currentStock: item.currentStock
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          quantity: item.quantity,
          isSelected: selectedState,
        });
      });

      state.products = temp;
      state.totalRecords = payload.recordsTotal;
      state.totalFiltered = payload.recordsFiltered;
    },
    [getProductListingByBranch.rejected]: (state, action) => {
      const { meta } = action;
      if (!meta.arg.cancelToken?.reason?.message) {
        state.isLoadingProductListError = true;
      }
      state.totalRecords = 0;
      state.totalFiltered = 0;
    },
    [getProductListingByDealer.pending]: (state) => {
      state.products = initialState.products;
      state.totalFiltered = initialState.totalFiltered;
      state.totalRecords = initialState.totalRecords;
      state.isLoadingProductListError = false;
    },
    [getProductListingByDealer.fulfilled]: (state, action) => {
      const { payload } = action;
      const temp = [];
      payload.data.forEach((item) => {
        let selectedState = !state.isSelectAll
          ? state.selectedId.findIndex((id) => id === item.id) !== -1
          : state.selectedId.findIndex((id) => id === item.id) === -1;

        temp.push({
          id: item.id,
          uuid: item.uuid,
          picture: item.picture ?? DefaultImg,
          attributes: item.attributes,
          name: item.name,
          currentStock: item.currentStock
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          quantity: item.quantity,
          isSelected: selectedState,
        });
      });

      state.products = temp;
      state.totalRecords = payload.recordsTotal;
      state.totalFiltered = payload.recordsFiltered;
    },
    [getProductListingByDealer.rejected]: (state, action) => {
      const { meta } = action;
      if (!meta.arg.cancelToken?.reason?.message) {
        state.isLoadingProductListError = true;
      }
      state.totalRecords = 0;
      state.totalFiltered = 0;
    },
    [getDealerInventory.pending]: (state) => {
      state.products = initialState.product;
      state.totalFiltered = initialState.totalFiltered;
      state.totalRecords = initialState.totalRecords;
      state.isLoadingProductListError = false;
    },
    [getDealerInventory.fulfilled]: (state, action) => {
      const { payload } = action;
      const temp = [];
      payload.data.forEach((item) => {
        let selectedState = !state.isSelectAll
          ? state.selectedId.findIndex((id) => id === item.id) !== -1
          : state.selectedId.findIndex((id) => id === item.id) === -1;

        temp.push({
          id: item.id,
          uuid: item.uuid,
          picture: item.picture ?? DefaultImg,
          name: item.name,
          currentStock: item.currentStock
            ?.toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          quantity: item.quantity,
          isSelected: selectedState,
        });
      });

      state.products = temp;
      state.totalRecords = payload.recordsTotal;
      state.totalFiltered = payload.recordsFiltered;
    },
    [getDealerInventory.rejected]: (state, action) => {
      const { meta } = action;
      if (!meta.arg.cancelToken?.reason?.message) {
        state.isLoadingProductListError = true;
      }
      state.totalRecords = 0;
      state.totalFiltered = 0;
    },
    [getBranchInventory.pending]: (state) => {
      state.products = initialState.product;
      state.totalFiltered = initialState.totalFiltered;
      state.totalRecords = initialState.totalRecords;
      state.isLoadingProductListError = false;
    },
    [getBranchInventory.fulfilled]: (state, action) => {
      const { payload } = action;
      const temp = [];
      payload.data.forEach((item) => {
        let selectedState = !state.isSelectAll
          ? state.selectedId.findIndex((id) => id === item.id) !== -1
          : state.selectedId.findIndex((id) => id === item.id) === -1;

        temp.push({
          id: item.id,
          uuid: item.uuid,
          picture: item.picture ?? DefaultImg,
          name: item.name,
          currentStock: item.currentStock
            ?.toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          quantity: item.quantity,
          isSelected: selectedState,
          nodeImageUrl: item.node_image_url,
        });
      });

      state.products = temp;
      state.totalRecords = payload.recordsTotal;
      state.totalFiltered = payload.recordsFiltered;
    },
    [getBranchInventory.rejected]: (state, action) => {
      const { meta } = action;
      if (!meta.arg.cancelToken?.reason?.message) {
        state.isLoadingProductListError = true;
      }
      state.totalRecords = 0;
      state.totalFiltered = 0;
    },
    [getInventoryProductDetail.pending]: (state) => {
      state.product = initialState.product;
      state.isLoadingProductError = false;
    },
    [getInventoryProductDetail.fulfilled]: (state, action) => {
      const { payload } = action;
      state.product = payload;
    },
    [getInventoryProductDetail.rejected]: (state) => {
      state.isLoadingProductError = true;
    },
    [getInventoryProductStockList.pending]: (state) => {
      state.productStockList = initialState.productStockList;
      state.totalFiltered = initialState.totalFiltered;
      state.totalRecords = initialState.totalRecords;
      state.isLoadingProductStockListError = false;
    },
    [getInventoryProductStockList.fulfilled]: (state, action) => {
      const { payload } = action;
      state.productStockList = payload.data;
      state.totalRecords = payload.recordsTotal;
      state.totalFiltered = payload.recordsFiltered;
    },
    [getInventoryProductStockList.rejected]: (state) => {
      state.isLoadingProductStockListError = true;
    },
    [getSerialNumberActivityLog.pending]: (state) => {
      state.activityLog = initialState.activityLog;
      state.isLoadingActivityLogError = false;
    },
    [getSerialNumberActivityLog.fulfilled]: (state, action) => {
      const { payload } = action;
      state.activityLog = payload.data;
    },
    [getSerialNumberActivityLog.rejected]: (state) => {
      state.isLoadingActivityLogError = true;
    },
    [getInventoryPackageListing.pending]: (state) => {
      state.packages = initialState.packages;
      state.totalFiltered = initialState.totalFiltered;
      state.totalRecords = initialState.totalRecords;
      state.isLoadingPackageListError = false;
    },
    [getInventoryPackageListing.fulfilled]: (state, action) => {
      const { payload } = action;
      const temp = [];
      payload.data.forEach((item) => {
        let selectedState = !state.isSelectAll
          ? state.selectedId.findIndex((id) => id === item.id) !== -1
          : state.selectedId.findIndex((id) => id === item.id) === -1;

        temp.push({
          id: item.uuid,
          sequence_no: item.sequence_no,
          serial_number: item.serial_number,
          picture: item.product?.picture ?? DefaultImg,
          name: item.product?.name || "",
          attributes: item.product?.attributes || [],
          quantity: item.total_loose_item_count,
          status: item.status,
          located_at: item.located_at,
          created_at: item.created_at,
          updated_at: item.updated_at,
          isSelected: selectedState,
          nodeName: item.node?.name || "",
        });
      });

      state.packages = temp;
      state.totalRecords = payload.recordsTotal;
      state.totalFiltered = payload.recordsFiltered;
    },
    [getInventoryPackageListing.rejected]: (state) => {
      state.isLoadingPackageListError = true;
      state.totalRecords = 0;
      state.totalFiltered = 0;
    },
    [getInventoryPackageDetail.pending]: (state) => {
      state.package = initialState.package;
      state.isLoadingPackageError = false;
    },
    [getInventoryPackageDetail.fulfilled]: (state, action) => {
      const { payload } = action;
      state.package = payload;
    },
    [getInventoryPackageDetail.rejected]: (state) => {
      state.isLoadingPackageError = true;
    },
    [getPackageActivityLog.pending]: (state) => {
      state.activityLog = initialState.activityLog;
      state.isLoadingActivityLogError = false;
    },
    [getPackageActivityLog.fulfilled]: (state, action) => {
      const { payload } = action;
      state.activityLog = payload.data;
    },
    [getPackageActivityLog.rejected]: (state) => {
      state.isLoadingActivityLogError = true;
    },
    [getInventoryBoxListing.pending]: (state) => {
      state.boxes = initialState.boxes;
      state.totalFiltered = initialState.totalFiltered;
      state.totalRecords = initialState.totalRecords;
      state.isLoadingBoxListError = false;
    },
    [getInventoryBoxListing.fulfilled]: (state, action) => {
      const { payload } = action;
      const temp = [];
      payload.data.forEach((item) => {
        let selectedState = !state.isSelectAll
          ? state.selectedId.findIndex((id) => id === item.id) !== -1
          : state.selectedId.findIndex((id) => id === item.id) === -1;

        temp.push({
          ...item,
          id: item.uuid,
          isSelected: selectedState,
        });
      });

      state.boxes = temp;
      state.totalRecords = payload.recordsTotal;
      state.totalFiltered = payload.recordsFiltered;
    },
    [getInventoryBoxListing.rejected]: (state) => {
      state.isLoadingBoxListError = true;
      state.totalRecords = 0;
      state.totalFiltered = 0;
    },
    [getInventoryBoxDetail.pending]: (state) => {
      state.box = initialState.box;
      state.isLoadingBoxError = false;
    },
    [getInventoryBoxDetail.fulfilled]: (state, action) => {
      const { payload } = action;
      state.box = payload;
    },
    [getInventoryBoxDetail.rejected]: (state) => {
      state.isLoadingBoxError = true;
    },
    [getBoxActivityLog.pending]: (state) => {
      state.activityLog = initialState.activityLog;
      state.isLoadingActivityLogError = false;
    },
    [getBoxActivityLog.fulfilled]: (state, action) => {
      const { payload } = action;
      state.activityLog = payload.data;
    },
    [getBoxActivityLog.rejected]: (state) => {
      state.isLoadingActivityLogError = true;
    },
    [getInventoryLooseItemListing.pending]: (state) => {
      state.looseItems = initialState.looseItems;
      state.totalFiltered = initialState.totalFiltered;
      state.totalRecords = initialState.totalRecords;
      state.isLoadingLooseItemListError = false;
    },
    [getInventoryLooseItemListing.fulfilled]: (state, action) => {
      const { payload } = action;
      state.looseItems = payload.data;
      state.totalRecords = payload.recordsTotal;
      state.totalFiltered = payload.recordsFiltered;
    },
    [getInventoryLooseItemListing.rejected]: (state) => {
      state.isLoadingLooseItemListError = true;
      state.totalRecords = 0;
      state.totalFiltered = 0;
    },
    [getInventoryLooseItemDetail.pending]: (state) => {
      state.looseItem = initialState.looseItem;
      state.isLoadingLooseItemError = false;
    },
    [getInventoryLooseItemDetail.fulfilled]: (state, action) => {
      const { payload } = action;
      state.looseItem = payload;
    },
    [getInventoryLooseItemDetail.rejected]: (state) => {
      state.isLoadingLooseItemError = true;
    },
    [getInventoryLooseItemStockList.pending]: (state) => {
      state.looseItemStockList = initialState.looseItemStockList;
      state.totalFiltered = initialState.totalFiltered;
      state.totalRecords = initialState.totalRecords;
      state.isLoadingLooseItemStockListError = false;
    },
    [getInventoryLooseItemStockList.fulfilled]: (state, action) => {
      const { payload } = action;
      state.looseItemStockList = payload.data;
      state.totalRecords = payload.recordsTotal;
      state.totalFiltered = payload.recordsFiltered;
    },
    [getInventoryLooseItemStockList.rejected]: (state) => {
      state.isLoadingLooseItemStockListError = true;
    },
    [getStockProductDropdown.fulfilled]: (state, action) => {
      state.stockProductDropdown = action.payload;
    },
    [getStockHolderDropdown.pending]: (state) => {
      state.stockHolderDropdown = initialState.stockHolderDropdown;
    },
    [getStockHolderDropdown.fulfilled]: (state, action) => {
      state.stockHolderDropdown = action.payload;
    },
  },
});

export const {
  changeSelectedProduct,
  changeBulkSelectedProduct,
  changeBulkSelectedAvailableProduct,
  changeSelectedSerialNumber,
  changeSelectedPackage,
  changeBulkSelectedPackage,
  changeBulkSelectedAvailablePackage,
  changeSelectedBox,
  changeBulkSelectedBox,
  changeBulkSelectedAvailableBox,
  resetProductList,
  resetProductStockListing,
  resetActivityLog,
  resetPackageList,
  resetBoxList,
  resetLooseItemList,
  resetLooseItemStockListing,
} = stockflowInventorySlice.actions;

export const stockflowInventoryReducer = stockflowInventorySlice.reducer;
