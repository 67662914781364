import axios from "axios";

export default function setBranchProfile({ id, url }) {
  return new Promise((resolve, reject) => {
    axios.post("/account/api/v1/node/set-profile", {
      id, url
    })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data));
  });
}
