import React from "react";
import { makeStyles, Box, Typography, Button } from "@material-ui/core";
import ImageRoundedIcon from "@material-ui/icons/ImageRounded";
import clsx from "clsx";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles(theme => ({
  root: {
    fontWeight: theme.typography.fontWeightBold,
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2),
    backgroundColor: "#ECEFF0",
    borderRadius: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3)
  },
  image: {
    width: 76,
    height: 100,
    border: "1px solid",
    borderColor: theme.palette.grey[500],
    display: "flex",
    overflow: "hidden",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    "& img": {
      height: "100%",
      minWidth: "100%",
      maxWidth: "100%",
      minHeight: "100%",
      objectFit: "cover",
      flexShrink: 0
    }
  },
  hideBorder: {
    border: "none"
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    flexWrap: "nowrap",
    marginLeft: theme.spacing(3)
  },
  input: {
    display: "none"
  }
}));

export default function BgImagePanelComponent({
  image,
  inputImgRef,
  handleImgChange,
  lang
}) {
  const classes = useStyle();

  return (
    <Box className={classes.root}>
      <Box
        className={clsx(classes.image, { [classes.hideBorder]: image !== "" })}
      >
        {image ? (
          <img src={image} alt="background company" />
        ) : (
          <>
            <ImageRoundedIcon color="disabled" fontSize="large" />
            <Typography variant="caption" color="textSecondary">
              {getLang(lang, "label.NO_IMAGE")}
            </Typography>
          </>
        )}
      </Box>
      <Box className={classes.wrapper}>
        <Typography variant="body1">{getLang(lang, "label.UPLOAD_BACKGROUND")}</Typography>
        <Typography variant="body2" color="textSecondary" gutterBottom>
          {getLang(lang, "paragraph.RECOMMENDED_FILE_SIZE_LANDING_PAGE")}
        </Typography>

        <Box style={{ placeSelf: "flex-end" }}>
          <input
            accept="image/*"
            className={classes.input}
            id="background-image"
            type="file"
            ref={inputImgRef}
            onChange={handleImgChange}
            onClick={(e) => {
              e.target.value = null
            }}
          />
          <label htmlFor="background-image">
            <Button
              variant="contained"
              disableElevation
              size="small"
              component="span"
              color="primary"
            >
              {getLang(lang, "label.UPLOAD")}
            </Button>
          </label>
        </Box>
      </Box>
    </Box>
  );
}
