import { createSlice } from "@reduxjs/toolkit";
import {
  getAcceptedStockoutList,
  getCancelledStockoutList,
  getNewStockoutList,
  getRejectedStockoutList,
  getStockoutCustomFieldDropdown,
  getStockoutDetail,
  getStockoutItemList,
  getStockoutReceiverDropdown,
  getStockoutRequestorDropdown,
  STOCKFLOWSTOCKOUT,
} from "../action/stockflowStockout.action";

const initialState = {
  newStockoutList: {
    list: [],
    totalFiltered: 0,
    totalRecords: 0,
    hasCustomFields: false,
  },
  acceptedStockoutList: {
    list: [],
    totalFiltered: 0,
    totalRecords: 0,
    hasCustomFields: false,
  },
  rejectedStockoutList: {
    list: [],
    totalFiltered: 0,
    totalRecords: 0,
    hasCustomFields: false,
  },
  cancelledStockoutList: {
    list: [],
    totalFiltered: 0,
    totalRecords: 0,
    hasCustomFields: false,
  },
  isLoadingStockoutListError: false,
  selectedIds: [],
  isSelectAll: false,
  stockout: null,
  isLoadingStockoutError: false,
  stockoutItems: {
    boxes: [],
    packages: [],
  },
  isLoadingStockoutItemsError: false,
  stockoutRequestorDropdown: [],
  stockoutReceiverDropdown: [],
  customFieldDropdown: {
    list: [],
    isError: false,
    totalRecords: 0,
    totalFiltered: 0,
  },
};

const stockflowStockoutSlice = createSlice({
  name: STOCKFLOWSTOCKOUT,
  initialState,
  reducers: {
    changeSelectedStockout(state, action) {
      const { payload } = action;

      state[`${payload.type}StockoutList`].list[payload.index].isSelected =
        payload.isSelected;
      let stockoutId =
        state[`${payload.type}StockoutList`].list[payload.index].id;
      if (
        (!state.isSelectAll && payload.isSelected) ||
        (state.isSelectAll && !payload.isSelected)
      ) {
        state.selectedIds = [...state.selectedIds, stockoutId];
      } else {
        state.selectedIds = state.selectedIds.filter((id) => id !== stockoutId);
      }
    },
    changeBulkSelectedStockout(state, action) {
      const { payload } = action;

      state[`${payload.type}StockoutList`].list.forEach((s) => {
        s.isSelected = payload.value;
      });
      state.isSelectAll = payload.value;
      state.selectedIds = [];
    },
    changeBulkSelectedAvailableStockout(state, action) {
      const { payload } = action;
      state[`${payload.type}StockoutList`].list.forEach((s) => {
        s.isSelected = payload.value;
        if (
          (!state.isSelectAll && payload.value) ||
          (state.isSelectAll && !payload.value)
        ) {
          if (state.selectedIds.findIndex((id) => id === s.id) === -1) {
            state.selectedIds = [...state.selectedIds, s.id];
          }
        } else {
          state.selectedIds = state.selectedIds.filter((id) => id !== s.id);
        }
      });
    },
    resetStockoutListing(state) {
      state.newStockoutList = initialState.newStockoutList;
      state.acceptedStockoutList = initialState.acceptedStockoutList;
      state.rejectedStockoutList = initialState.rejectedStockoutList;
      state.cancelledStockoutList = initialState.cancelledStockoutList;
      state.selectedIds = initialState.selectedIds;
      state.isSelectAll = initialState.isSelectAll;
    },
    resetStockout(state) {
      state.stockout = initialState.stockout;
    },
    resetStockoutCustomFieldDropdown(state) {
      state.customFieldDropdown = initialState.customFieldDropdown;
    },
  },
  extraReducers: {
    [getNewStockoutList.pending]: (state) => {
      state.newStockoutList = initialState.newStockoutList;
      state.isLoadingStockoutListError = false;
    },
    [getNewStockoutList.fulfilled]: (state, action) => {
      const { payload } = action;
      const temp = [];
      payload.data.forEach((item) => {
        let selectedState = !state.isSelectAll
          ? state.selectedIds.findIndex((id) => id === item.id) !== -1
          : state.selectedIds.findIndex((id) => id === item.id) === -1;
        temp.push({
          ...item,
          isSelected: selectedState,
        });
        return null;
      });
      state.newStockoutList.list = temp;
      state.newStockoutList.totalRecords = payload.recordsTotal;
      state.newStockoutList.totalFiltered = payload.recordsFiltered;
      state.newStockoutList.hasCustomFields = payload.hasCustomFields;
    },
    [getNewStockoutList.rejected]: (state) => {
      state.isLoadingStockoutListError = true;
    },
    [getAcceptedStockoutList.pending]: (state) => {
      state.acceptedStockoutList = initialState.acceptedStockoutList;
      state.isLoadingStockoutListError = false;
    },
    [getAcceptedStockoutList.fulfilled]: (state, action) => {
      const { payload } = action;
      const temp = [];
      payload.data.forEach((item) => {
        let selectedState = !state.isSelectAll
          ? state.selectedIds.findIndex((id) => id === item.id) !== -1
          : state.selectedIds.findIndex((id) => id === item.id) === -1;
        temp.push({
          ...item,
          isSelected: selectedState,
        });
        return null;
      });
      state.acceptedStockoutList.list = temp;
      state.acceptedStockoutList.totalRecords = payload.recordsTotal;
      state.acceptedStockoutList.totalFiltered = payload.recordsFiltered;
      state.acceptedStockoutList.hasCustomFields = payload.hasCustomFields;
    },
    [getAcceptedStockoutList.rejected]: (state) => {
      state.isLoadingStockoutListError = true;
    },
    [getRejectedStockoutList.pending]: (state) => {
      state.rejectedStockoutList = initialState.rejectedStockoutList;
      state.isLoadingStockoutListError = false;
    },
    [getRejectedStockoutList.fulfilled]: (state, action) => {
      const { payload } = action;
      const temp = [];
      payload.data.forEach((item) => {
        let selectedState = !state.isSelectAll
          ? state.selectedIds.findIndex((id) => id === item.id) !== -1
          : state.selectedIds.findIndex((id) => id === item.id) === -1;
        temp.push({
          ...item,
          isSelected: selectedState,
        });
        return null;
      });
      state.rejectedStockoutList.list = temp;
      state.rejectedStockoutList.totalRecords = payload.recordsTotal;
      state.rejectedStockoutList.totalFiltered = payload.recordsFiltered;
      state.rejectedStockoutList.hasCustomFields = payload.hasCustomFields;
    },
    [getRejectedStockoutList.rejected]: (state) => {
      state.isLoadingStockoutListError = true;
    },
    [getCancelledStockoutList.pending]: (state) => {
      state.cancelledStockoutList = initialState.cancelledStockoutList;
      state.isLoadingStockoutListError = false;
    },
    [getCancelledStockoutList.fulfilled]: (state, action) => {
      const { payload } = action;
      const temp = [];
      payload.data.forEach((item) => {
        let selectedState = !state.isSelectAll
          ? state.selectedIds.findIndex((id) => id === item.id) !== -1
          : state.selectedIds.findIndex((id) => id === item.id) === -1;
        temp.push({
          ...item,
          isSelected: selectedState,
        });
        return null;
      });
      state.cancelledStockoutList.list = temp;
      state.cancelledStockoutList.totalRecords = payload.recordsTotal;
      state.cancelledStockoutList.totalFiltered = payload.recordsFiltered;
      state.cancelledStockoutList.hasCustomFields = payload.hasCustomFields;
    },
    [getCancelledStockoutList.rejected]: (state) => {
      state.isLoadingStockoutListError = true;
    },
    [getStockoutDetail.pending]: (state) => {
      state.stockout = initialState.stockout;
      state.isLoadingStockoutError = false;
    },
    [getStockoutDetail.fulfilled]: (state, action) => {
      state.stockout = action.payload;
    },
    [getStockoutDetail.rejected]: (state) => {
      state.isLoadingStockoutError = true;
    },
    [getStockoutRequestorDropdown.pending]: (state) => {
      state.stockoutRequestorDropdown = initialState.stockoutRequestorDropdown;
    },
    [getStockoutRequestorDropdown.fulfilled]: (state, action) => {
      state.stockoutRequestorDropdown = action.payload;
    },
    [getStockoutReceiverDropdown.pending]: (state) => {
      state.stockoutReceiverDropdown = initialState.stockoutReceiverDropdown;
    },
    [getStockoutReceiverDropdown.fulfilled]: (state, action) => {
      state.stockoutReceiverDropdown = action.payload;
    },
    [getStockoutItemList.pending]: (state) => {
      state.stockoutItems = initialState.stockoutItems;
      state.isLoadingStockoutItemsError = false;
    },
    [getStockoutItemList.fulfilled]: (state, action) => {
      state.stockoutItems = action.payload;
    },
    [getStockoutItemList.rejected]: (state) => {
      state.isLoadingStockoutItemsError = true;
    },
    [getStockoutCustomFieldDropdown.pending]: (state) => {
      state.customFieldDropdown = initialState.customFieldDropdown;
    },
    [getStockoutCustomFieldDropdown.fulfilled]: (state, action) => {
      const { payload } = action;

      state.customFieldDropdown.list = payload.data;
      state.customFieldDropdown.totalRecords = payload.recordsTotal;
      state.customFieldDropdown.totalFiltered = payload.recordsFiltered;
    },
    [getStockoutCustomFieldDropdown.rejected]: (state) => {
      state.customFieldDropdown.isError = true;
    },
  },
});

export const {
  changeSelectedStockout,
  changeBulkSelectedStockout,
  changeBulkSelectedAvailableStockout,
  resetStockoutListing,
  resetStockout,
  resetStockoutCustomFieldDropdown,
} = stockflowStockoutSlice.actions;

export const stockflowStockoutReducer = stockflowStockoutSlice.reducer;
