import React, { useRef } from "react";
import {
  makeStyles,
  Typography,
  Popover,
  Box,
  Divider,
  InputAdornment,
  Button,
  MenuItem,
  MenuList,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { Skeleton } from '@material-ui/lab';
import InputTextField from 'components/input/inputTextField';
import InfiniteScroll from "react-infinite-scroll-component";
import LoadingComponent from 'components/loading/loading.component';
import ClearIcon from '@material-ui/icons/Clear';

const useStyle = makeStyles((theme) => ({
  button: {
    padding: 0,
    minWidth: 0
  },
  perfectScrollBar: {
    maxHeight: 200,
    overflow: "auto"
  },
  list: {
    paddingTop: 6,
    paddingBottom: 6
  }
}));

function SearchableSelectPanelComponent({
  open,
  anchorEl,
  handleClose,
  id,
  style,
  popoverBoxStyle,
  searchable,
  handleSearch,
  placeholder,
  items,
  viewOnly,
  isFetching,
  width,
  emptyMessage,
  handleListKeyDown,
  anchorOrigin,
  transformOrigin,
  PaperProps,
  type,
  handleFetchMoreItems,
  totalFilter,
  selected,
}) {
  const classes = useStyle();
  const textRef = useRef();

  const displayMenuItem = () => {
    return (
      <>
        {isFetching ? (
          <>
            <Skeleton variant='text' animation="wave" height={25} />
            <Skeleton variant='text' animation="wave" height={25} />
            <Skeleton variant='text' animation="wave" height={25} />
            <Skeleton variant='text' animation="wave" height={25} />
          </>
        ) : items.length > 0 ? items.map((item) => (
          <Box key={item.name ? item.name : item}>
            <MenuList
              id='menu-list-grow'
              className={classes.list}
            >
              <MenuItem
                selected={selected(item)}
                disabled={viewOnly ? true : false}
                onClick={() => handleListKeyDown(item)}
              >
                <Typography variant='body1' noWrap style={{ paddingLeft: 8, paddingRight: 8 }}>
                  {item.name ? item.name : item}
                </Typography>
              </MenuItem>
            </MenuList>
          </Box>
        )) : (
          <Box py={9} style={{ textAlign: "center" }}>
            <Typography variant='body1'>{emptyMessage}</Typography>
          </Box>
        )}
      </>
    )
  }

  return (
    <Popover
      id={id}
      open={open}
      style={style}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      elevation={2}
      PaperProps={PaperProps}
    >
      {searchable && (
        <Box
          style={{width:width,...popoverBoxStyle}}
        >
          <InputTextField
            variant="filled"
            size="small"
            disabled={viewOnly ? true : false}
            fullWidth
            inputRef={textRef}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end' style={{ paddingRight: 6 }}>
                  <Button
                    onClick={() => handleSearch(textRef.current.value)}
                    className={classes.button}
                    disabled={viewOnly ? true : false}
                  >
                    <SearchIcon />
                  </Button>
                  {viewOnly ?
                    <Button
                      className={classes.button}
                      disabled
                    >
                      <ClearIcon/>
                    </Button> :
                    null}
                </InputAdornment>
              ),
              style: {
                backgroundColor: "#fff",
                paddingRight: 0
              }
            }}
            inputProps={{
              onKeyDown: (e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  handleSearch(textRef.current.value)
                  return false
                }
              },
              style: {
                padding: 8
              }
            }}
            placeholder={placeholder}
          />
        </Box>
      )}
      <Divider />
      {type === "InfiniteScroll" ? (
        <>
          <InfiniteScroll
            height={400}
            dataLength={items.length}
            next={handleFetchMoreItems}
            hasMore={totalFilter > items.length}
            loader={<LoadingComponent height={50} />}
          >
            {displayMenuItem()}
          </InfiniteScroll>
        </>
      ) : (
        <>
          <Box className={classes.perfectScrollBar}>
            {displayMenuItem()}
          </Box>
        </>
      )}
    </Popover>
  )
}

export default SearchableSelectPanelComponent