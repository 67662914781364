import React, { useState, useEffect, useCallback } from "react";
import PaymentCardCardComponent from "./paymentCardCard.component";
import { setSelectedPaymentCardId } from "../../../redux";
import {
  deletePaymentMethod,
  updateDefaultPaymentMethod
} from "modules/billing/redux";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { unwrapResult } from "@reduxjs/toolkit";
import { addAlert } from "modules/notification";
import { getLang } from "app/feature/constants";

function PaymentCardCardContainer({
  id,
  last4number,
  type,
  expMonth,
  expYear,
  isPrimary,
  isSelected,
  setSelectedPaymentCardId,
  deletePaymentMethod,
  handleReloadPaymentMethod,
  updateDefaultPaymentMethod,
  addAlert,
  cardLength
}) {
  const lang = useSelector(state => state.constant.languages);
  const [anchorOptionMenu, setAnchorOptionMenu] = useState(null);
  const [showMenu, setShowMenu] = useState(false);
  const [isPrimarySwitchChecked, setIsPrimarySwitchChecked] = useState(
    isPrimary
  );
  const [isDeleting, setIsDeleting] = useState(false);

  const handleSelect = useCallback(() => {
    setSelectedPaymentCardId(id);
  }, [id, setSelectedPaymentCardId]);

  useEffect(() => {
    if (isPrimary) {
      handleSelect();
    }
  }, [isPrimary, handleSelect]);

  const handleChangePrimarySwitch = e => {
    setIsPrimarySwitchChecked(true);

    updateDefaultPaymentMethod(id)
      .then(unwrapResult)
      .then(response => {
        handleReloadPaymentMethod();
      })
      .catch(error => {
        setIsPrimarySwitchChecked(false);
      });
  };

  const handleClickOption = event => {
    setAnchorOptionMenu(event.currentTarget);
    setShowMenu(prev => !prev);
  };

  const handleCloseMenu = () => {
    setShowMenu(false);
  };

  const handleDelete = () => {
    if (cardLength === 1) {
      addAlert({
        severity: "warning",
        message: getLang(lang, 'message.warning.WARNING_MUST_ADD_NEW_CARD_BEFORE_REMOVE_THIS_CARD,')
      });
      return;
    }
    setShowMenu(false);
    setIsDeleting(true);
    deletePaymentMethod(id)
      .then(unwrapResult)
      .then(response => {
        handleReloadPaymentMethod();
      })
      .catch(error => {
        setIsDeleting(false);
      });
  };

  return (
    <PaymentCardCardComponent
      last4number={last4number}
      type={type}
      expMonth={expMonth}
      expYear={expYear}
      isSelected={isSelected}
      isPrimary={isPrimary}
      anchorOptionMenu={anchorOptionMenu}
      handleClickOption={handleClickOption}
      showMenu={showMenu}
      handleCloseMenu={handleCloseMenu}
      handleSelect={handleSelect}
      handleDelete={handleDelete}
      isPrimarySwitchChecked={isPrimarySwitchChecked}
      handleChangePrimarySwitch={handleChangePrimarySwitch}
      isDeleting={isDeleting}
      lang={lang}
    />
  );
}

PaymentCardCardContainer.propTypes = {
  id: PropTypes.string.isRequired,
  last4number: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  expMonth: PropTypes.number.isRequired,
  expYear: PropTypes.number.isRequired,
  isPrimary: PropTypes.bool.isRequired
};

const mapStateToProps = (state, { id }) => ({
  isSelected: state.marketplace.selectedPaymentCardId === id
});

const mapDispatchToProps = dispatch => ({
  setSelectedPaymentCardId: id => dispatch(setSelectedPaymentCardId(id)),
  deletePaymentMethod: id => dispatch(deletePaymentMethod(id)),
  updateDefaultPaymentMethod: id => dispatch(updateDefaultPaymentMethod(id)),
  addAlert: (payload) => dispatch(addAlert(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentCardCardContainer);
