import { createAsyncThunk } from "@reduxjs/toolkit";
import { STOCK_MODULE_SETTING } from "../../constants";
import stockModuleSettingApi from "app/api/stockModuleSetting";

export const getStockModuleSetting = createAsyncThunk(
  `${STOCK_MODULE_SETTING}/getStockModuleSetting`,
  async (payload, thunkApi) => {
    return stockModuleSettingApi
      .getStockModuleSetting()
      .then((response) => response)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const updateStockModuleSetting = createAsyncThunk(
  `${STOCK_MODULE_SETTING}/updateStockModuleSetting`,
  async (payload, thunkApi) => {
    return stockModuleSettingApi
      .updateStockModuleSetting(payload)
      .then((response) => response)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);
