import React from "react";
import {
  Box,
  Paper,
  makeStyles,
  Divider,
  Typography,
  useTheme,
  useMediaQuery,
  CircularProgress
} from "@material-ui/core";
import PaymentCardCard from "../../card/paymentCardCard";
import AddCardForm from "../addCardForm";
import { getLang } from "app/feature/constants";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  paper: {
    background: "rgba(0, 0, 0, 0.04)",
    padding: theme.spacing(3)
  },
  content: {
    width: "100%",
    textAlignLast: "start"
  },
  ending: {
    marginLeft: theme.spacing(2)
  },
  expired: {
    marginLeft: theme.spacing(10)
  },
  bgCardsPanel: {
    backgroundColor: theme.palette.divider,
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    columnGap: "1px",
    rowGap: "1px",
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr"
    }
  }
}));

const MAX_CARD_COUNT = 3;

export default function CardFormComponent({
  children,
  isFetching,
  paymentMethods,
  handlePurchase,
  hasSelectedCard,
  handleAddNewCardSuccess,
  handleReloadPaymentMethod,
  lang
}) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const fillGridLayout = (totalItem, totalItemInARow) => {
    const totalItemIncludeAddCardBtn = totalItem + 1;
    const totalEmptyCell =
      totalItemInARow - (totalItemIncludeAddCardBtn % totalItemInARow);

    if (totalEmptyCell === totalItemInARow) return;

    const ids = [];
    for (let n = 0; n < totalEmptyCell; n++) {
      ids.push(n);
    }
    return ids.map(id => (
      <Box
        key={id}
        width="100%"
        height={isMobile ? 295 : 340}
        style={{ backgroundColor: "#F4F6F8" }}
      />
    ));
  };

  if (isFetching)
    return (
      <Box py={6} textAlign="center">
        <CircularProgress color="primary" />
      </Box>
    );

  return (
    <Paper elevation={0} className={classes.root}>
      <Box className={classes.content}>
        <Box
          py={2}
          px={3}
          display="flex"
          alignItems={isMobile ? "flex-start" : "center"}
          justifyContent="space-between"
          flexDirection={isMobile ? "column" : "row"}
        >
          <Box mb={isMobile ? 2 : 0} display="flex" alignItems="center">
            <Typography variant="body2" color="textSecondary">
              {getLang(lang, 'label.PAYMENT_METHOD')}&nbsp;
            </Typography>
            <Typography variant="body2" style={{ fontWeight: "bold" }}>
              {getLang(lang, 'label.CREDIT_CARD')}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box className={classes.bgCardsPanel}>
          {paymentMethods.map((pm, index) => (
            <PaymentCardCard
              key={index}
              id={pm.id}
              type={pm.card.brand}
              last4number={pm.card.last4}
              expMonth={pm.card.exp_month}
              expYear={pm.card.exp_year}
              isPrimary={pm.default}
              handleReloadPaymentMethod={handleReloadPaymentMethod}
              cardLength={paymentMethods.length}
            />
          ))}
          {paymentMethods.length < MAX_CARD_COUNT && (
            <>
              <AddCardForm handleResult={handleAddNewCardSuccess} setIsPrimary={paymentMethods.length === 0 ? true : false} />
              {!isMobile && fillGridLayout(paymentMethods.length, 3)}
            </>
          )}
        </Box>

        {/* step footer */}
        <Divider />
        <Box p={2}>
          <form onSubmit={handlePurchase}>
            {children({ disabled: !hasSelectedCard })}
          </form>
        </Box>
      </Box>
    </Paper>
  );
}
