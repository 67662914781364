import { createAsyncThunk } from "@reduxjs/toolkit";
import stockflowApi from "app/api/stockflow";

export const STOCKFLOWSTOCKTAKE = "stockflowStockTake";

export const getStockTakeList = createAsyncThunk(
  `${STOCKFLOWSTOCKTAKE}/getStockTakeList`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi
      .getStockTakeList({
        ...payload,
      })
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const getStockTakeDetail = createAsyncThunk(
  `${STOCKFLOWSTOCKTAKE}/getStockTakeDetail`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi
      .getStockTakeDetail(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const createStockTake = createAsyncThunk(
  `${STOCKFLOWSTOCKTAKE}/createStockTake`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi
      .createStockTake(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const updateStockTake = createAsyncThunk(
  `${STOCKFLOWSTOCKTAKE}/updateStockTake`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi
      .updateStockTake(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const cancelStockTake = createAsyncThunk(
  `${STOCKFLOWSTOCKTAKE}/cancelStockTake`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi
      .cancelStockTake(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const endStockTake = createAsyncThunk(
  `${STOCKFLOWSTOCKTAKE}/endStockTake`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi
      .endStockTake(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const getStockTakeItemBoxList = createAsyncThunk(
  `${STOCKFLOWSTOCKTAKE}/getStockTakeItemBoxList`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi
      .getStockTakeItemBoxList(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const getStockTakeItemBoxProductList = createAsyncThunk(
  `${STOCKFLOWSTOCKTAKE}/getStockTakeItemBoxProductList`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi
      .getStockTakeItemBoxProductList(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const getStockTakeItemProductList = createAsyncThunk(
  `${STOCKFLOWSTOCKTAKE}/getStockTakeItemProductList`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi
      .getStockTakeItemProductList(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const getStockTakeItemProductSNList = createAsyncThunk(
  `${STOCKFLOWSTOCKTAKE}/getStockTakeItemProductSNList`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi
      .getStockTakeItemProductSNList(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);
