import React from "react"
import { makeStyles, Box, Typography, Paper, TableSortLabel, Link } from "@material-ui/core"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TablePagination from "@material-ui/core/TablePagination"
import { TablePaginationActions } from "components/tablePagination"
import DefaultImg from "assets/img/img-default.png"
import RefreshTable from "components/table/refreshTable"
import {
  StyledTableCell,
  StyledTableRow,
} from "components/table"
import { SkeletonTableRowStickyLeftRight } from "components/skeleton/skeleton.component"
import { KeyboardArrowDown } from "@material-ui/icons"
import moment from "moment"
import { ROOT as SERIAL_NUMBER_ROOT } from "modules/serial-number"
import { getLang } from "app/feature/constants"
import "moment/min/locales.min";

const useStyle = makeStyles((theme) => ({
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    borderCollapse: "separate",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
    },
    "& th,td": {
      fontSize: ".75rem",
      borderRight: "1px solid rgba(58, 77, 84, 0.1)",
      padding: theme.spacing(1, 2, 1, 2),
    },
    "& th": {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightBold,
    },
    "& th:last-child, td:last-child": {
      width: 37,
      borderRight: "0px",
    },
    "& th th": {
      borderBottom: "none !important",
    },
    "& td td": {
      borderBottom: "none !important",
    },
  },
  emptyContainer: {
    backgroundColor: theme.palette.background.paper,
    textAlign: "center",
  },
  productPhoto: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #E4E7EC",
    width: "30px",
    height: "30px",
    marginRight: "10px", 
    "& img": {
      height: "30px",
      width: "30px",
    },
  },
}))

export default function CampaignListTableComponent({
  rows,
  isFetching,
  isError,
  page,
  tableFilter,
  language,
  rowsPerPage,
  totalFiltered,
  handleChangePage,
  handleChangeRowsPerPage,
  handleReload,
  paginationOptions,
  changeOrder,
  lang,
  handleViewDetail
}) {
  const classes = useStyle()
  
  if(language==="ZH")
  {
    moment.locale('zh-cn')
  }else if(language==="TL")
  {
    moment.locale('tl-ph')
  }else
  {
    moment.locale(language)
  }

  return (
    <>
      {!isFetching && isError && <RefreshTable handleReload={handleReload} />}
      {isFetching || rows.length > 0 ? (
        <Paper id="test"style={{overflowX: 'auto', width: "100%"}}>
          <TableContainer>
            <Table
              className={classes.table}
              aria-label="gift table"
              size="small"
              stickyHeader
            >
              <TableHead style={{ height: "45px" }}>
                <TableRow>
                  <StyledTableCell>
                    <TableSortLabel
                      active={true}
                      direction={tableFilter.order}
                      onClick={() => {
                        const order = tableFilter.order === 'asc' ? "desc" : "asc"
                        changeOrder(order)
                      }}
                      IconComponent={KeyboardArrowDown}
                    >
                      {getLang(lang, "label.DATE")}
                    </TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell>{getLang(lang, "label.CAMPAIGN_TYPE")}</StyledTableCell>
                  <StyledTableCell style={{ minWidth: "10rem" }}>{getLang(lang, "label.NAME")}</StyledTableCell>
                  <StyledTableCell>{getLang(lang, "label.PRODUCTS")}</StyledTableCell>
                  <StyledTableCell>{getLang(lang, "label.SERIAL_NUMBER")}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isFetching
                  ? [...new Array(5)].map((v, index) => (
                    <SkeletonTableRowStickyLeftRight key={index} columnCount={5} />
                  ))
                  : rows.map((row, index) => (
                    <StyledTableRow
                      key={index}
                      hover
                      tabIndex={-1}
                    >
                      <StyledTableCell>
                        <Typography variant="caption">
                          {moment(row.created_at).format('lll')}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Typography variant="caption">
                          {getLang(lang, row.type)}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell style={{ maxWidth: "40vw", whiteSpace: "normal" }}>
                        <Typography variant="caption">
                          {row.name}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell style={{ maxWidth: "40vw", whiteSpace: "normal" }}>
                        <Box display="flex" alignItems="center">
                          <Box className={classes.productPhoto}>
                            {row.product_picture ? (
                              <img src={row.product_picture} alt={row.product_name} />
                            ) : (
                              <img src={DefaultImg} alt="emptyProduct" />
                            )}
                          </Box>
                          <Typography variant="caption">{row.product_name}</Typography>
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Link
                          href={`${SERIAL_NUMBER_ROOT}/details/${row.serial_number_enc}`}
                          color="secondary"
                          onClick={(e) => {
                            handleViewDetail(`${SERIAL_NUMBER_ROOT}/details/${row.serial_number_enc}`)
                            e.preventDefault();
                            return false;
                          }}
                        >
                          <Typography variant="caption">
                            {row.serial_number}
                          </Typography>
                        </Link>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            style={{
              backgroundColor: "#fff",
              boxShadow: "0px -1px 0px rgba(58, 77, 84, 0.15)",
            }}
            ActionsComponent={TablePaginationActions}
            rowsPerPageOptions={paginationOptions}
            component="div"
            count={totalFiltered}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage={getLang(lang, 'label.ROWS_PER_PAGE')}
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${getLang(lang, 'label.OF')} ${count}`}
          />
        </Paper>
      ) : null}
      {!isFetching && !isError && rows.length === 0 && (
        <Box>
          <Table
            className={classes.table}
            aria-label="product table"
            size="small"
            stickyHeader
          >
            <TableHead style={{ height: "45px" }}>
              <TableRow>
                <StyledTableCell>Date</StyledTableCell>
                <StyledTableCell>Campaign Type</StyledTableCell>
                <StyledTableCell style={{ minWidth: "10rem" }}>Name</StyledTableCell>
                <StyledTableCell>Product(s)</StyledTableCell>
                <StyledTableCell>Serial Number</StyledTableCell>
              </TableRow>
            </TableHead>
          </Table>
          <Box py={9} className={classes.emptyContainer}>
            <Typography variant="caption" style={{ paddingBottom: "16px", fontWeight: "bold" }}>
              No redemption history
            </Typography>
          </Box>
        </Box>
      )}
    </>
  )
}
