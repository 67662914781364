import React, { useState, useRef, useEffect } from "react";
import RoleSelectComponent from "./roleSelect.component";
import RolePanelComponent from "./rolePanel.component";
import PropTypes from "prop-types";
import { NewRoleDialog } from "modules/role-access-right/components/dialog";
import { selectLoading } from "modules/notification";
import { useDispatch, useSelector } from "react-redux";
import { fetchRoleDropdown } from "modules/employee/redux";
import { openCreationDialog } from "modules/role-access-right/redux";
import { ROLE_ADD } from "lib/constants/accessRights";
import { getLang } from "app/feature/constants";

function RoleSelectContainer({ placeholder, handleChange, value }) {
  const [open, setOpen] = useState(false);
  const [isOpenList] = useState(false);
  const anchorRef = useRef(null);
  const prevOpen = useRef(open);
  const prevOpenList = useRef(isOpenList);
  const dispatch = useDispatch();
  const roleDropdown = useSelector((state) => state.employee.roleDropdown);
  const hasRoleAddAccessRight = useSelector(state => state.profile.accessRights.includes(ROLE_ADD));
  const isFetching = useSelector((state) =>
    selectLoading(state, fetchRoleDropdown.typePrefix)
  );
  const isCloseAddNewRole = useSelector((state) => state.roleAccessRight.roleSetting.isOpen);
  const lang = useSelector(state => state.constant.languages);

  useEffect(() => {
    if(!isCloseAddNewRole){
      dispatch(fetchRoleDropdown());
    }
  }, [dispatch, isCloseAddNewRole]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    if (prevOpenList.current === true && isOpenList === false) {
      anchorRef.current.focus();
    }

    prevOpenList.current = isOpenList;
  }, [isOpenList]);

  function handleListKeyDown(value) {
    handleChange(value);
    setOpen(false);
  }

  const handleAddRole = () => {
    // setType({ index: "null", type: "create" });
    if(hasRoleAddAccessRight){
      dispatch(openCreationDialog());
    }
  };

  return (
    <>
      <RoleSelectComponent
        anchorRef={anchorRef}
        handleClick={handleClick}
        value={value}
        placeholder={getLang(lang, 'placeholder.SELECT_ROLE')}
      />
      <RolePanelComponent
        hasRoleAddAccessRight={hasRoleAddAccessRight}
        anchorRef={anchorRef}
        open={open}
        isOpenList={isOpenList}
        handleClose={handleClose}
        isFetching={isFetching}
        handleListKeyDown={handleListKeyDown}
        handleAddRole={handleAddRole}
        roleDropdown={roleDropdown}
        lang={lang}
      />
      <NewRoleDialog />
    </>
  );
}
RoleSelectContainer.defaultProps = {
  placeholder: "Select Role",
};

RoleSelectContainer.propTypes = {
  placeholder: PropTypes.string,
};

export default RoleSelectContainer;
