import axios from "axios";

const endpoint_url = "/serialnumber/api/v1/warranty-extension";

const post = ({
  warrantySettingId,
  extMonth,
  quantity,
  extStartAt,
  extEndAt,
  type,
  tagId
}) => {
  const body = {
    warranty_setting_id: warrantySettingId,
    ext_month: extMonth,
    quantity: quantity,
    ext_start_at: extStartAt,
    ext_end_at: extEndAt,
    type: type,
    tag_id: tagId
  };

  return new Promise((resolve, reject) => {
    axios
      .post(endpoint_url, body)
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

const put = ({ extMonth, extStartAt, extEndAt, id }) => {
  const body = {
    ext_month: extMonth,
    ext_start_at: extStartAt,
    ext_end_at: extEndAt
  };

  return new Promise((resolve, reject) => {
    axios
      .put(`/serialnumber/api/v1/warranty-extension/tag/${id}`, body)
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

const createWarrantyExtension = {
  post,
  put
};

export default createWarrantyExtension;
