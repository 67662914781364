import React from "react";
import {
  makeStyles,
  Box,
  Typography
} from "@material-ui/core";
import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";
import { BackButton } from "components/button";
import clsx from 'clsx';
import RewardDetailsForm from "modules/stockflow/components/form/rewardDetailsForm.component";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles(theme => ({
  root: {
    gridTemplateAreas: "'top .' 'main side'",
    gridTemplateColumns: "1fr",
    [theme.breakpoints.up("lg")]: {
      display: "grid"
    },
    columnGap: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      gridTemplateAreas: "'top' 'main' 'side'",
      gridTemplateColumns: "1fr",
      gridTemplateRows: "1fr auto",
      rowGap: theme.spacing(2)
    }
  },
  fullWidthGrid: {
    gridTemplateAreas: "'top' 'main'",
    gridTemplateColumns: "1fr"
  },
  paper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(5, 0),
    width: "90%",
    maxWidth: 1000,
    display: "inherit",
    justifyContent: "inherit"
  },
  content: {
    width: "95%",
    maxWidth: 850
  },

  sideBanner: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridRowGap: theme.spacing(2),
    rowGap: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(3),
      gridTemplateColumns: "1fr 1fr",
      columnGap: theme.spacing(2)
    },
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr"
    }
  },

  publishButton: {
    color: theme.palette.common.white
  },
  backButton: {
    marginRight: theme.spacing(1)
  },
  discardButton: {
    color: theme.palette.error.main
  },
  iconContainer: {
    minHeight: 38
  },
  stepperRoot: {
    paddingBottom: 0
  },
  stepLabelRoot: {
    height: 100,
    position: "relative"
  },
  stepLabelActive: {
    "&::after": {
      content: "''",
      backgroundColor: theme.palette.primary.main,
      width: "20%",
      height: 3,
      position: "absolute",
      bottom: 0,
      borderRadius: theme.spacing(1, 1, 0, 0)
    }
  },
  indicator: {
    border: "0.5px solid",
    borderColor: theme.palette.primary.main,
    borderRadius: "4px 4px 0px 0px"
  },
  scroll: {
    width: "min-content"
  },
  tabContainer: {
    backgroundColor: "white",
    borderRadius: "0.5em"
  }
}));

export default function AddRewardPageComponent({
  isSubmitting,
  lang
}) {
  const classes = useStyle();

  return (
    <>
      <Box className={clsx(classes.root)}>
        <Box gridArea="top">
          <Box
            mb={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" alignItems="flex-end" fontWeight="bold">
              <BackButton />
              <Typography variant="h6" color="primary">
                {getLang(lang,'label.REWARD_CENTER')}
              </Typography>
              <Box mx={0.5}>
                <ChevronRightRoundedIcon color="disabled" />
              </Box>
              <Typography variant="h6" color="primary">
                {getLang(lang, 'label.ADD_REWARD')}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box gridArea="main">
          <Box className={classes.tabContainer}>
            <RewardDetailsForm 
              formType={"create"}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
}
