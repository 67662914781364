import React from "react";
import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import Input from "components/input/inputTextField";
import FilterSvg from "assets/svg/filter.svg";
import { Formik } from "formik";
import BranchSearch from "modules/stockflow/components/select/branchSearch";
import clsx from "clsx";
import DateRangePicker from "components/input/dateRangePicker";
import OrderListTable from "modules/stockflow/components/table/orderListTable";
import OrderRequestorSearch from "modules/stockflow/components/select/orderRequestorSearch";
import OrderReceiverSearch from "modules/stockflow/components/select/orderReceiverSearch";
import { getLang } from "app/feature/constants";
import ExportExcelSelect from "modules/warrantee/components/select/exportExcelSelect";

const useStyle = makeStyles((theme) => ({
  searchContainer: {
    backgroundColor: theme.palette.background.paper,
    display: "grid",
    gridTemplateColumns: "5fr 4fr 2fr 1fr 2fr",
    columnGap: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1),
    },
  },
  exportButton: {
    backgroundColor: "#ffffff",
    height: 43,
    borderRadius: 4,
    padding: "5px 12px",
    borderColor: "#D0D5DD",
  },
  inputRootOrder: {
    paddingTop: "8px !important",
    paddingBottom: "7px !important",
    paddingLeft: "12px !important",
    paddingRight: "12px !important",
    fontSize: "0.875rem",
    fontWeight: 550,
  },
  filterMainContainer: {
    backgroundColor: theme.palette.background.paper,
    paddingTop: "12px",
    paddingBottom: "12px",
    paddingRight: "20px",
    paddingLeft: "20px",
    marginBottom: "12px",
  },
  filterFieldContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(4, minmax(0, 1fr))",
    columnGap: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1),
    },
  },
  paperRoot: {
    width: "217px",
  },
  emptyField: {
    "& .MuiInputBase-root": {
      color: "#A4A6A8",
    },
  },
}));

export default function OrderListingComponent({
  hasStockflowOrderViewAccessRight,
  hasStockflowDealerViewAccessRight,
  type,
  orders,
  isFetching,
  selectedIds,
  isSelectAll,
  selectedCount,
  isError,
  handleChangePage,
  handleChangeRowsPerPage,
  totalFiltered,
  page,
  rowsPerPage,
  handleSearch,
  clearSearch,
  handleReload,
  isSearchResult,
  handleSelectOrder,
  handleSelectAvailable,
  handleSelectAll,
  handleDeselectAll,
  paginationOptions,
  isExporting,
  isFilterOpen,
  setIsFilterOpen,
  handleFilter,
  tableFilter,
  changeOrder,
  lang,
  resetFilter,
  handleViewDetail,
  handleExport,
}) {
  const classes = useStyle();
  const exportDropdown = [
    { name: "CSV", value: "csv" },
    { name: "Excel", value: "xlsx" },
  ];

  return (
    <>
      <Box style={{ display: "flex", justifyContent: "flex-end" }}>
        <ExportExcelSelect
          disabled={isExporting || isFetching || orders.length < 1}
          dropdownItem={exportDropdown}
          isLoading={isExporting}
          handleSelection={(type) => handleExport(type)}
        />
      </Box>

      <Formik
        initialValues={{
          search: tableFilter.search,
          dateRange: {
            from: tableFilter.fromDate,
            to: tableFilter.toDate,
          },
          branch: tableFilter.branch,
          respondDate: tableFilter.respondDate,
          requestFrom: tableFilter.requestFrom,
          requestTo: tableFilter.requestTo,
        }}
        enableReinitialize
        onSubmit={(values, actions) => {
          handleSearch(values);
          actions.setSubmitting(false);
        }}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <Box p={2} mb={2} className={classes.searchContainer}>
              <Input
                hiddenLabel
                variant="filled"
                size="small"
                placeholder={getLang(lang, "placeholder.SEARCH_ORDER_ID")}
                margin="none"
                InputProps={{
                  disableUnderline: true,
                  margin: "none",
                  style: {
                    borderRadius: "4px",
                    backgroundColor: "#eceff0",
                    minWidth: "50px",
                    height: "100%",
                  },
                  classes: { input: classes.inputRootOrder },
                }}
                {...formik.getFieldProps("search")}
              />
              <DateRangePicker
                label=""
                placeholder={getLang(lang, "placeholder.REQUESTED_DATE")}
                disabled={isFetching}
                disableFuture={true}
                className={clsx({
                  [classes.emptyField]:
                    !formik.getFieldProps("dateRange").value,
                })}
                id={"created-picker-dialog"}
                value={formik.getFieldProps("dateRange").value}
                handleChange={(v) => {
                  formik.setFieldValue("dateRange", v);
                }}
                InputPropStyle={{
                  color: !formik.getFieldProps("dateRange").value
                    ? "#A4A6A8"
                    : "#000000",
                  fontWeight: "normal",
                }}
                inputProps={{
                  fontWeight: "400",
                  fontSize: "16px",
                }}
                title={getLang(lang, "placeholder.REQUESTED_DATE")}
              />
              <Button
                style={{ padding: "3px 40px" }}
                variant="outlined"
                size="small"
                disableElevation
                onClick={() => setIsFilterOpen(!isFilterOpen)}
                startIcon={<img src={FilterSvg} alt="filter svg" />}
              >
                {getLang(lang, "label.ADD_FILTER")}
              </Button>
              <Button
                variant="text"
                disableElevation
                color="secondary"
                size="small"
                onClick={() => {
                  clearSearch();
                }}
              >
                {getLang(lang, "label.CLEAR")}
              </Button>
              <Button
                disabled={isFetching}
                variant="contained"
                disableElevation
                color="primary"
                size="small"
                type="submit"
              >
                {getLang(lang, "label.SEARCH")}
              </Button>
            </Box>
            {isFilterOpen && (
              <Box className={classes.filterMainContainer}>
                <Box
                  className={classes.filterFieldContainer}
                  style={{ marginBottom: "12px" }}
                >
                  <BranchSearch
                    placeholder={getLang(lang, "placeholder.BRANCH")}
                    value={formik.values.branch}
                    handleChange={(value) =>
                      formik.setFieldValue("branch", value)
                    }
                    isEmpty={formik.values.branch?.length === 0}
                    styles={{
                      padding: "0.25em 0.75em",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderRadius: 4,
                      width: "100%",
                      overflow: "hidden",
                    }}
                    handleChipDelete={(branch) => {
                      let temp = [...formik.values.branch];
                      if (temp.length > 0) {
                        const filteredBranchs = temp.filter(
                          (item) => item.node_id !== branch.node_id
                        );
                        temp = [...filteredBranchs];
                      }
                      formik.setFieldValue("branch", temp);
                    }}
                  />
                  <OrderRequestorSearch
                    value={formik.values.requestFrom}
                    handleChange={(value) =>
                      formik.setFieldValue("requestFrom", value)
                    }
                    placeholder={getLang(lang, "placeholder.REQUEST_FROM")}
                    type={type}
                    isEmpty={formik.values.requestFrom?.length === 0}
                    styles={{
                      padding: "0.25em 0.75em",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderRadius: 4,
                      width: "100%",
                      overflow: "hidden",
                    }}
                    handleChipDelete={(requestor) => {
                      let temp = [...formik.values.requestFrom];
                      if (temp.length > 0) {
                        const filteredRequestFrom = temp.filter(
                          (item) => item !== requestor
                        );
                        temp = [...filteredRequestFrom];
                      }
                      formik.setFieldValue("requestFrom", temp);
                    }}
                  />
                  <OrderReceiverSearch
                    value={formik.values.requestTo}
                    handleChange={(value) =>
                      formik.setFieldValue("requestTo", value)
                    }
                    placeholder={getLang(lang, "placeholder.REQUEST_TO")}
                    type={type}
                    isEmpty={formik.values.requestTo?.length === 0}
                    styles={{
                      padding: "0.25em 0.75em",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderRadius: 4,
                      width: "100%",
                      overflow: "hidden",
                    }}
                    handleChipDelete={(receiver) => {
                      let temp = [...formik.values.requestTo];
                      if (temp.length > 0) {
                        const filteredRequestFrom = temp.filter(
                          (item) => item !== receiver
                        );
                        temp = [...filteredRequestFrom];
                      }
                      formik.setFieldValue("requestTo", temp);
                    }}
                  />
                  {type !== "new" && (
                    <DateRangePicker
                      label=""
                      disabled={formik.isSubmitting || isFetching}
                      placeholder={getLang(lang, "placeholder.RESPOND_DATE")}
                      disableFuture={false}
                      id="date-picker"
                      className={clsx({
                        [classes.emptyField]:
                          !formik.getFieldProps("respondDate").value,
                      })}
                      value={formik.values.respondDate}
                      handleChange={(value) => {
                        formik.setFieldValue("respondDate", value);
                      }}
                      InputPropStyle={{
                        color: !formik.getFieldProps("respondDate").value
                          ? "#A4A6A8"
                          : "#000000",
                        fontWeight: "normal",
                      }}
                      inputProps={{
                        fontWeight: "400",
                        fontSize: "16px",
                      }}
                    />
                  )}
                </Box>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    variant="text"
                    disableElevation
                    color="secondary"
                    size="small"
                    onClick={resetFilter}
                    style={{
                      marginRight: "8px",
                    }}
                  >
                    {getLang(lang, "label.CLEAR")}
                  </Button>
                  <Button
                    disabled={isFetching}
                    variant="contained"
                    disableElevation
                    color="secondary"
                    size="small"
                    type="submit"
                  >
                    {getLang(lang, "label.APPLY_FILTER")}
                  </Button>
                </Box>
              </Box>
            )}
          </form>
        )}
      </Formik>

      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingBottom: "12px",
        }}
      >
        <Box style={{ display: "flex" }}>
          <Box style={{ display: "flex", alignItems: "center" }}>
            {selectedCount !== 0 ? (
              isSelectAll && selectedCount === totalFiltered ? (
                <Typography variant="body2" style={{ color: "#FDB022" }}>
                  {getLang(lang, "paragraph.SELECTED_ALL_ORDERS", {
                    count: selectedCount,
                  })}
                </Typography>
              ) : (
                <Typography variant="body2" color="primary">
                  {selectedCount > 1
                    ? getLang(lang, "paragraph.SELECTED_ORDERS_COUNT", {
                        count: selectedCount,
                      })
                    : getLang(lang, "paragraph.SELECTED_ORDER_COUNT", {
                        count: selectedCount,
                      })}
                </Typography>
              )
            ) : (
              ""
            )}
          </Box>
        </Box>
      </Box>
      <OrderListTable
        hasStockflowOrderViewAccessRight={hasStockflowOrderViewAccessRight}
        hasStockflowDealerViewAccessRight={hasStockflowDealerViewAccessRight}
        type={type}
        rows={orders}
        isFetching={isFetching}
        selectedIds={selectedIds}
        isSelectAll={isSelectAll}
        isError={isError}
        handleReload={handleReload}
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        isSearchResult={isSearchResult}
        totalFiltered={totalFiltered}
        handleSelectOrder={handleSelectOrder}
        handleSelectAvailable={handleSelectAvailable}
        handleSelectAll={handleSelectAll}
        handleDeselectAll={handleDeselectAll}
        paginationOptions={paginationOptions}
        tableFilter={tableFilter}
        changeOrder={changeOrder}
        lang={lang}
        handleViewDetail={handleViewDetail}
      />
    </>
  );
}
