import React, { useState } from 'react'
import PointListingTableComponent from './pointListingTable.component'
import { useDispatch, useSelector } from 'react-redux'
import { getLoyaltyPoint, resetLoyaltyPoint, setType } from 'modules/loyalty-program/redux'
import { hideBackdrop, showBackdrop } from "modules/backdrop";
import { AddPointDialog } from '../../dialog'
import { unwrapResult } from "@reduxjs/toolkit";
import ConfirmationDialog from 'components/dialog/confirmation/confirmationDialog.component'
import { loyaltyType } from "modules/loyalty-program/constants"
import { getLang } from 'app/feature/constants';

export default function PointListingTableContainer({
  hasLoyaltyProgramAddAccessRight,
  hasLoyaltyProgramEditAccessRight,
  hasLoyaltyProgramActivateOrDeactivateAccessRight,
  data,
  isFetching,
  page,
  search,
  rowsPerPage,
  totalRecords,
  handleChangePage,
  handleChangeRowsPerPage,
  handleCreate,
  handleStatusChange,
  handleReload,
  paginationOptions
}) {
  const dispatch = useDispatch()
  const [isModalOpen, setModalOpen] = useState(false);
  const [confirmationDialogModal] = useState(false);
  const isLoadingPointError = useSelector(state => state.loyaltyProgram.isLoadingPointError)
  const lang = useSelector(state => state.constant.languages)
  const language = useSelector(state => state.profile.language)

  const handleView = id => {
    dispatch(setType({ index: loyaltyType.point, type: "view" }));
    dispatch(showBackdrop());
    dispatch(getLoyaltyPoint(id))
      .then(unwrapResult)
      .then(() => {
        dispatch(hideBackdrop())
        setModalOpen(true)
      })
  }

  const handleClose = () => {
    dispatch(resetLoyaltyPoint())
    setModalOpen(false);
  }

  return (
    <>
      <PointListingTableComponent
        hasLoyaltyProgramAddAccessRight={hasLoyaltyProgramAddAccessRight}
        hasLoyaltyProgramEditAccessRight={hasLoyaltyProgramEditAccessRight}
        hasLoyaltyProgramActivateOrDeactivateAccessRight={hasLoyaltyProgramActivateOrDeactivateAccessRight}
        data={data}
        isFetching={isFetching}
        page={page}
        search={search}
        rowsPerPage={rowsPerPage}
        totalRecords={totalRecords}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        language={language}
        handleCreate={handleCreate}
        handleView={handleView}
        handleStatusChange={handleStatusChange}
        handleReload={handleReload}
        isLoadingPointError={isLoadingPointError}
        paginationOptions={paginationOptions}
        lang={lang}
      />
      <AddPointDialog
        isOpen={isModalOpen}
        handleClose={handleClose}
      />
      <ConfirmationDialog
        isOpen={confirmationDialogModal}
        handleProceed={handleClose}
        type="success"
        title={getLang(lang,"label.POINT_ADDED_SUCCESSFULLY")}
        rejectOption={false}
        proceedLabel={getLang(lang,"label.ALRIGHT")}
      />
    </>
  )
}