import React, { useEffect, useState } from "react";
import ReceiveItemsListPanelComponent from "./receiveItemsListPanel.component";
import { useDispatch, useSelector } from "react-redux";
import { selectLoading } from "modules/notification";
import {
  getReceiveTransferItemBoxList,
  getReceiveTransferItemProductList,
  getTransferItemBoxList,
  getTransferItemProductList,
  resetReceiveTransferBoxListing,
  resetTransferBoxListing,
} from "modules/stockflow/redux";
import TransferProductSnListDialog from "../../dialog/transferProductSnListDialog";
import TransferBoxDetailDialog from "../../dialog/transferBoxDetailDialog";

export default function ReceiveItemsListPanelContainer({
  totalBox,
  totalReceiveBox,
  totalPackage,
  totalReceivePackage,
  status,
  id,
  isReceiver,
}) {
  const dispatch = useDispatch();

  const lang = useSelector((state) => state.constant.languages);
  const PARTIAL_RECEIVE = "Partial Receive";

  // box
  const isBoxFetching = useSelector((state) =>
    selectLoading(
      state,
      isReceiver
        ? getReceiveTransferItemBoxList.typePrefix
        : getTransferItemBoxList.typePrefix
    )
  );
  const boxTotalFiltered = useSelector(
    (state) =>
      state[isReceiver ? "stockflowReceiveTransfer" : "stockflowTransfer"]
        .transferBoxList.totalFiltered
  );
  const boxList = useSelector(
    (state) =>
      state[isReceiver ? "stockflowReceiveTransfer" : "stockflowTransfer"]
        .transferBoxList.list
  );
  const isBoxError = useSelector(
    (state) =>
      state[isReceiver ? "stockflowReceiveTransfer" : "stockflowTransfer"]
        .transferBoxList.isError
  );
  const boxHasNextPage = useSelector(
    (state) =>
      state[isReceiver ? "stockflowReceiveTransfer" : "stockflowTransfer"]
        .transferBoxList.hasNextPage
  );

  // product
  const isProductFetching = useSelector((state) =>
    selectLoading(
      state,
      isReceiver
        ? getReceiveTransferItemProductList.typePrefix
        : getTransferItemProductList.typePrefix
    )
  );
  const prdTotalFiltered = useSelector(
    (state) =>
      state[isReceiver ? "stockflowReceiveTransfer" : "stockflowTransfer"]
        .transferProductList.totalFiltered
  );
  const prdList = useSelector(
    (state) =>
      state[isReceiver ? "stockflowReceiveTransfer" : "stockflowTransfer"]
        .transferProductList.list
  );
  const isPrdError = useSelector(
    (state) =>
      state[isReceiver ? "stockflowReceiveTransfer" : "stockflowTransfer"]
        .transferProductList.isError
  );
  const prdHasNextPage = useSelector(
    (state) =>
      state[isReceiver ? "stockflowReceiveTransfer" : "stockflowTransfer"]
        .transferProductList.hasNextPage
  );

  const [filter, setFilter] = useState({
    rowsPerPage: 25,
    prdPage: 0,
    search: "",
    boxPage: 0,
  });

  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedBox, setSelectedBox] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [statusTab, setStatusTab] = useState(0);
  const hasNextPage = activeIndex === 0 ? boxHasNextPage : prdHasNextPage;

  useEffect(() => {
    const isFetching = activeIndex === 0 ? isBoxFetching : isProductFetching;
    if (hasNextPage && !isFetching) {
      fetchData(filter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, activeIndex]);

  const fetchData = (filterInfo) => {
    if (activeIndex === 0) {
      let param = {
        id,
        length: filterInfo.rowsPerPage,
        start: filterInfo.rowsPerPage * filterInfo.boxPage,
        type:
          statusTab === 0
            ? "receive"
            : status === PARTIAL_RECEIVE
            ? "pending"
            : "missing",
        search: filterInfo.search,
      };
      dispatch(
        isReceiver
          ? getReceiveTransferItemBoxList(param)
          : getTransferItemBoxList(param)
      );
    } else {
      let param = {
        id,
        length: filterInfo.rowsPerPage,
        start: filterInfo.rowsPerPage * filterInfo.prdPage,
      };
      dispatch(
        isReceiver
          ? getReceiveTransferItemProductList(param)
          : getTransferItemProductList(param)
      );
    }
  };

  const handleReload = () => {
    const newFilter = {
      ...filter,
      prdPage: 0,
      boxPage: 0,
    };
    setFilter(newFilter);
  };

  const handleChangeType = (event, index) => {
    if (activeIndex !== index) {
      setActiveIndex(index);
      // setStatusTab(0);
      // const newFilter = {
      //   ...filter,
      //   page: 0,
      // };
      // setFilter(newFilter);
    }
  };

  const changeStatusTab = (event, index) => {
    if (statusTab !== index) {
      resetListing();

      setStatusTab(index);
      const newFilter = {
        ...filter,
        boxPage: 0,
      };
      setFilter(newFilter);
    }
  };

  const handleFetchMoreItems = () => {
    if (hasNextPage) {
      const newFilter = { ...filter };
      if (activeIndex === 0) {
        newFilter.boxPage += 1;
      } else {
        newFilter.prdPage += 1;
      }
      setFilter(newFilter);
    }
  };

  const handleSearch = (values) => {
    resetListing();
    if (values && values.keyword) {
      const newFilter = {
        ...filter,
        boxPage: 0,
        search: values.keyword,
      };
      setFilter(newFilter);
    } else {
      const newFilter = {
        ...filter,
        boxPage: 0,
        search: "",
      };
      setFilter(newFilter);
    }
  };

  const resetListing = () => {
    dispatch(
      isReceiver ? resetReceiveTransferBoxListing() : resetTransferBoxListing()
    );
  };

  return (
    <>
      <ReceiveItemsListPanelComponent
        totalBox={totalBox}
        totalReceiveBox={totalReceiveBox}
        totalPackage={totalPackage}
        totalReceivePackage={totalReceivePackage}
        status={status}
        lang={lang}
        statusTab={statusTab}
        activeIndex={activeIndex}
        handleChangeType={handleChangeType}
        isFetching={activeIndex === 0 ? isBoxFetching : isProductFetching}
        list={activeIndex === 0 ? boxList : prdList}
        totalFiltered={activeIndex === 0 ? boxTotalFiltered : prdTotalFiltered}
        isError={activeIndex === 0 ? isBoxError : isPrdError}
        handleFetchMoreItems={handleFetchMoreItems}
        hasNextPage={hasNextPage}
        viewBox={(box) => setSelectedBox(box)}
        viewProduct={(prd) => setSelectedProduct(prd)}
        changeStatusTab={changeStatusTab}
        handleReload={handleReload}
        handleSearch={handleSearch}
        filter={filter}
      />
      <TransferProductSnListDialog
        isOpen={!!selectedProduct}
        handleClose={() => setSelectedProduct(null)}
        id={id}
        productInfo={
          selectedProduct
            ? {
                ...selectedProduct,
                status: status,
              }
            : null
        }
        isReceiver={isReceiver}
      />
      <TransferBoxDetailDialog
        isOpen={!!selectedBox}
        handleClose={() => setSelectedBox(null)}
        id={id}
        boxInfo={
          selectedBox
            ? {
                ...selectedBox,
                type:
                  statusTab === 0
                    ? "receive"
                    : status === PARTIAL_RECEIVE
                    ? "pending"
                    : "missing",
              }
            : null
        }
        isReceiver={isReceiver}
      />
    </>
  );
}
