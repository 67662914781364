import React from "react"
import {
  Typography,
  Box,
  CircularProgress,
} from "@material-ui/core"
import {
  ChevronRightRounded as ChevronRightRoundedIcon,
} from "@material-ui/icons"
import { BackButton } from "components/button"
import SearchCloudIcon from "../../utils/img/searchCloud.svg"
import BulkCreatePanel from "modules/product-mgmt/components/panel/bulkCreatePanel"
import GeneratedProductListPanel from "modules/product-mgmt/components/panel/generatedProductListPanel"
import { getLang } from "app/feature/constants"

export default function GenerateProductComponent({
  handleAddProducts,
  isAddProduct,
  paginationOptions,
  isGeneratingProduct,
  generatedProducts,
  clearProducts,
  deleteProducts,
  handleSubmit,
  fieldRequireError,
  lang
}) {
  return (
    <Box>
      <Box display="flex" alignItems="flex-end" fontWeight="bold">
        <BackButton />
        <Typography variant='h6' color='primary'>
          {getLang(lang, "label.PRODUCT")}
        </Typography>
        <Box mx={0.5}>
          <ChevronRightRoundedIcon color='disabled' />
        </Box>
        <Typography variant='h6' color='primary'>
          {getLang(lang, "label.GENERATE_PRODUCT")}
        </Typography>
      </Box>
      <BulkCreatePanel 
        handleAddProducts={handleAddProducts}
      />
      {isGeneratingProduct ? (
        <Box 
          display="flex" 
          flexDirection="column" 
          justifyContent="center" 
          alignItems="center" 
          p={4} 
          style={{ height: 150, backgroundColor: "#fff" }}
        >
          <CircularProgress style={{ width: 20, height: 20 }}/>
          <Typography variant="body1" style={{ marginTop: 8 }}>
            {getLang(lang, "label.GENERATING_PRODUCT")}...
          </Typography>
        </Box>
      ) : isAddProduct ? (
        <GeneratedProductListPanel 
          isFetching={false}
          isLoading={false}
          paginationOptions={paginationOptions}
          generatedProducts={generatedProducts}
          clearProducts={clearProducts}
          deleteProducts={deleteProducts}
          handleSubmit={handleSubmit}
          fieldRequireError={fieldRequireError}
          lang={lang}
        />
      ) : (
        <Box style={{ backgroundColor: "#fff", height: "276px", display:"flex", justifyContent:"center", alignItems: "center", flexDirection:"column" }}>
          <img src={SearchCloudIcon} alt="searchloud" width="152px" style={{ marginBottom: "16px" }}/>
          <Typography variant='body2' style={{ fontWeight: 500, textAlign: "center" }}>{getLang(lang, "paragraph.GENERATE_PRODUCTS_INSTRUCTION")}</Typography>
        </Box>
      )}
    </Box>
  )
}
