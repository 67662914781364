import React, {useState} from "react";
import {Box, Button, CircularProgress, Typography,} from '@material-ui/core'
import {useDispatch, useSelector} from "react-redux";
import {fetchOrganisationStructureDetails, setOrganisationStructure} from '../../redux'
import ActionDetailButton from "components/button/actionDetailButton";
import {ROOT as EMPLOYEE_ROOT} from "modules/employee";
import {useHistory} from "react-router-dom";
import {changeEmployeeStatus, removeEmployee,} from "modules/employee/redux";
import {MeSvg} from "modules/organisation-structure/utils/static";
import ProfileDefaultImage from "assets/img/defaultPhoto.png";
import RemarksDialog from "modules/employee/components/dialog/remarksDialog";
import {selectLoading} from "modules/notification";
import {getLang} from "app/feature/constants";
import BusinessIcon from "@material-ui/icons/Business";

function classnames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function NodeLabel({ node, lang }) {
  return (
    <Box>
      <Box
        display="flex"
        height={60}
        alignItems="center"
      >
        <NodeImage node={node} />
        <Box
          paddingLeft={1}
          paddingRight={1}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          style={{
            opacity: node.status === 'active' ? 1 : 0.5
          }}
        >
          <Typography
            style={{
              fontSize: node.remarks === 'company' ? 16 : 12,
              color: '#3A4D54',
              fontWeight: "bold",
            }}
          >
            {node.name}
          </Typography>
          <Typography
            style={{
              fontSize: node.remarks === 'company' ? 14 : 11,
              color: '#899498',
            }}
          >
            {node.remarks === 'company' ?
              node.plan === "Pro" ? getLang(lang,"label.PROFESSIONAL") : node.plan === "Biz" ? getLang(lang,"label.BUSINESS") : getLang(lang,"label.LITE_LOWERCASE")
              : `${node.city}, ${node.country}`}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

function NodeImage({ node }) {
  if (node.remarks === 'company') {
    return (
      <img
        src={node.imageUrl ? node.imageUrl : BusinessIcon}
        style={{
          width: 50,
          height: 50,
          borderRadius: 50,
          opacity: node.status === 'active' ? 1 : 0.5,
          boxShadow: '0px 0px 10px rgb(0 0 0 / 25%)',
        }}
        alt={node.name}
      />
    )
  } else {
    if (node.imageUrl) {
      return (
        <img
          src={node.imageUrl}
          style={{
            width: 46,
            height: 46,
            borderRadius: 50,
            opacity: node.status === 'active' ? 1 : 0.5,
            boxShadow: '0px 0px 10px rgb(0 0 0 / 25%)',
          }}
          alt={node.name}
        />
      )
    }
    return (
      <Box
        style={{
          width: 46,
          height: 46,
          borderRadius: '100%',
          border: '2px solid #6AAF68',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          opacity: node.status === 'active' ? 1 : 0.5,
          boxShadow: '0px 0px 4px 0px rgb(0 0 0 / 25%)',
        }}
      >
        <BusinessIcon style={{ fontSize: 28, color: '#6AAF68' }} />
      </Box>
    )
  }
}

function EmployeesTree({ node, lang }) {
  const dispatch = useDispatch();
  const organisationStructure = useSelector(state => state.organisationStructure.organisationStructure);

  const setShowEmployees = (show) => {
    let updatedOrganisationStructure = JSON.parse(JSON.stringify(organisationStructure))
    function updatePropertyById(id, data, property, value) {
      if (data.uuid === id) {
        data[property] = value;
      }
      if (data.children !== undefined && data.children.length > 0) {
        for (let i = 0; i < data.children.length; i++) {
          data.children[i] = updatePropertyById(id, data.children[i], property, value);
        }
      }
      return data;
    }
    updatePropertyById(node.uuid, { children: updatedOrganisationStructure }, "showEmployees", show);
    dispatch(setOrganisationStructure({ organisationStructure: updatedOrganisationStructure }))
  }

  if (!node.employees || node.employees.length <= 0) return null;

  if (node.showEmployees) return (
    <>
      <Box className="rst__node">
        <Box className="rst__nodeContent" style={{ display: "flex", flexDirection: "row" }}>
          <Box style={{ height: 60, width: "fit-content", display: "flex", flexDirection: "row", alignItems: "center" }}>
            <Button
              type="button"
              style={{ minWidth: 16 }}
              aria-label="Collapse"
              className="rst__collapseButton"
              onClick={() => setShowEmployees(false)}
            />
          </Box>
          <Box className={"employee_node"} style={{ height: 60 * node.employees.length }}>
            {
              node.employees.map((employee, index) => (
                <Box key={index} className="rst__node" style={{ height: 60 }}>
                  <Box
                    className={
                      node.employees.length - 1 === 0
                        ? "rst__lineBlock rst__lineFullHorizontal"
                        : index === 0
                          ? "rst__lineBlock rst__lineFullHorizontal rst__lineHalfVerticalBottom"
                          : index === node.employees.length - 1
                            ? "rst__lineBlock rst__lineHalfHorizontalRight rst__lineHalfVerticalTop"
                            : "rst__lineBlock rst__lineHalfHorizontalRight rst__lineFullVertical"
                    }
                    style={{ width: 50, zIndex: 1, height: 60 }}
                  />
                  <Box className="rst__nodeContent" style={{ height: 60, left: 50 }}>
                    <Box style={{ height: "100%" }}>
                      <Box className="rst__rowWrapper">
                        <Box className="rst__row" style={{ opacity: 1 }}>
                          <Box className="rst__rowContents rst__rowContentsDragDisabled">
                            <Box className="rst__rowLabel">
                            <span className="rst__rowTitle">
                              <EmployeeLabel node={node} employee={employee} lang={lang}/>
                            </span>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              ))
            }
          </Box>
        </Box>
      </Box>
    </>
  );

  return (
    <Box className="rst__node">
      <Box className="rst__lineBlock" style={{ width: 50 }}></Box>
      <Box className="rst__nodeContent">
        <Box style={{ height: 60, display: "flex", flexDirection: "row", alignItems: "center" }}>
            <Button
              type="button"
              style={{ minWidth: 16 }}
              aria-label="Expand"
              className="rst__expandButton"
              onClick={() => setShowEmployees(true)}
            />
        </Box>
      </Box>
    </Box>
  )
}

function EmployeeLabel({ node, employee, lang }) {
  return (
    <Box
      display="flex"
      height={60}
      alignItems="center"
    >
      <EmployeeImage node={node} employee={employee} />
      <Box
        paddingLeft={1}
        paddingRight={1}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        style={{
          opacity: node.status === 'active' && employee.state === 'active' ? 1 : 0.5
        }}
      >
        <Typography
          style={{
            fontSize: 12,
            color: '#3A4D54',
            fontWeight: "bold",
          }}
        >
          {employee.fullName}
        </Typography>
        <Typography
          style={{
            fontSize: 11,
            color: '#899498',
          }}
        >
          {/* {employee.roleName.charAt(0).toUpperCase() + employee.roleName.slice(1)} */}
          {employee.roleName === "owner" ? getLang(lang, 'label.OWNER') : (employee.roleName.charAt(0).toUpperCase() + employee.roleName.slice(1) )}
          {/* QUICK FIX FOR LANGUAGES TRANSLATION */}
        </Typography>
        {
          employee.state !== 'active'
            ?
            employee.state === 'pending'
              ?
              <Typography
                style={{
                  fontSize: 11,
                  color: '#FFDC73',
                }}
              >
                {getLang(lang,"label.PENDING")}
              </Typography>
              :
              <Typography
                style={{
                  fontSize: 11,
                  color: '#FD646F',
                }}
              >
                {getLang(lang,"label.DEACTIVATED")}
              </Typography>
            : null
        }
      </Box>
      <EmployeeActions node={node} employee={employee} lang={lang}/>
    </Box>
  )
}

function EmployeeImage({ node, employee }) {
  return (
    <img
      src={employee.imageProfileUrl ? employee.imageProfileUrl : ProfileDefaultImage}
      style={{
        width: 32,
        height: 32,
        borderRadius: 50,
        opacity: node.status === 'active' && employee.state === 'active' ? 1 : 0.5,
        boxShadow: '0px 0px 10px rgb(0 0 0 / 25%)',
      }}
      alt={employee.fullName}
    />
  )
}

function EmployeeActions({ node, employee, lang}) {
  const dispatch = useDispatch()
  const history = useHistory();
  const currentUserUuid = useSelector(state => state.profile.uuid);
  const [open, setOpen] = useState(false);
  const isStatusUpdating = useSelector((state) => selectLoading(state, changeEmployeeStatus.typePrefix));

  if (currentUserUuid === employee.uuid) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <MeSvg />
      </Box>
    )
  } else {
    const actionItems = [];
    actionItems.push({
      label: getLang(lang,"label.VIEW_PROFILE"),
      action: () => history.push(`${EMPLOYEE_ROOT}/View/${employee.uuid}`)
    });
    if (employee.roleName !== "owner") {
      if (node.status === 'active') {
        if (employee.state === 'active') {
          actionItems.push({
            label: getLang(lang,"label.DEACTIVATE"),
            action: () => setOpen(true),
            errorText: true,
          })
        } else if (employee.state === 'pending') {
          actionItems.push({
            label: getLang(lang,"label.CANCEL_INVITATION"),
            action: () => dispatch(removeEmployee(employee.uuid)),
            errorText: true,
          })
        } else if (employee.state === 'suspend') {
          actionItems.push({
            label: getLang(lang,"label.REACTIVATE"),
            action: () => dispatch(changeEmployeeStatus({
              id: employee.uuid,
              state: getLang(lang,"label.ACTIVE"),
            })).then(() => dispatch(fetchOrganisationStructureDetails({ uuid: node.uuid })))
          })
        }
      }
    }
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <ActionDetailButton
          variant="vertical"
          actionItems={actionItems}
        />
        <RemarksDialog
          isOpen={open}
          handleClose={() => setOpen(false)}
          handleProceed={(reasons) => dispatch(changeEmployeeStatus({
            id: employee.uuid,
            state: 'suspend',
            remarks: reasons
          }))
            .then(() => dispatch(fetchOrganisationStructureDetails({ uuid: node.uuid })))}
          isLoading={isStatusUpdating}
          description={getLang(lang,"paragraph.DEACTIVATE_EMPLOYEE_QUESTION")}
          title={getLang(lang,"label.CONFIRMATION_NEEDED")}
          employeeName={employee.fullName}
        />
      </Box>
    )
  }
}

export default function CompanyNodeContentRendererV2(props) {
  const dispatch = useDispatch()
  const lang = useSelector(state => state.constant.languages)

  const {
    scaffoldBlockPxWidth,
    toggleChildrenVisibility,
    connectDragPreview,
    connectDragSource,
    isDragging,
    canDrop,
    canDrag,
    node,
    title, // Ignore
    subtitle, // Ignore
    draggedNode,
    path,
    treeIndex,
    isSearchMatch,
    isSearchFocus,
    className,
    style,
    didDrop,
    treeId,
    isOver, // Not needed, but preserved for other renderers
    parentNode, // Needed for dndManager
    rowDirection, // Ignore
    ...otherProps
  } = props;

  return (
    <div style={{ height: '100%' }} {...otherProps}>
      {/* Branch Card */}
      <div className={'rst__rowWrapper'} style={{ display: "flex", flexDirection: "column", height: '100%' }}>
        {connectDragPreview(
          <div
            className={classnames(
              'rst__row',
              isSearchMatch && 'rst__rowSearchMatch',
              isSearchFocus && 'rst__rowSearchFocus',
              className
            )}
            style={{ ...style, height: "fit-content" }}
          >
            <div className={classnames('rst__rowContents', 'rst__rowContentsDragDisabled')}>
              <Box display="flex" flexDirection="row">
                <Box>
                  <div className={'rst__rowLabel'}>
                    <span className={classnames('rst__rowTitle', node.subtitle && 'rst__rowTitleWithSubtitle')}>
                      <NodeLabel node={node} lang={lang}/>
                    </span>
                  </div>
                  {/* Collapse Button*/}
                  {toggleChildrenVisibility &&
                    node.children &&
                    (node.children.length > 0 || typeof node.children === 'function') && (
                      <Box>
                        <Box style={{ width: (node.remarks === 'company') ? 50 : 46, display: "flex", justifyContent: "center" }}>
                          <button
                            type="button"
                            aria-label={node.expanded ? 'Collapse' : 'Expand'}
                            className={node.expanded ? 'rst__collapseButton' : 'rst__expandButton'}
                            style={{ left: scaffoldBlockPxWidth, zIndex: 1, position: "static" }}
                            onClick={() => {
                              toggleChildrenVisibility({
                                node,
                                path,
                                treeIndex,
                              })
                              if (node.isChildrenLoaded === false) {
                                dispatch(fetchOrganisationStructureDetails({ uuid: node.uuid }))
                              }
                            }}
                          />
                        </Box>
                        {/* Expand Button Loading */}
                        <Box
                          style={{
                            position: 'absolute',
                            zIndex: 1,
                            left: 30,
                            top: 62,
                            height: 10,
                            width: scaffoldBlockPxWidth,
                            textAlign: 'center',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          {node.isLoading ? (
                            <>
                              <CircularProgress size={10} />
                              &nbsp;
                              <Typography
                                style={{
                                  fontSize: 10,
                                }}
                              >
                                {getLang(lang,"label.LOADING")}
                              </Typography>
                            </>
                          ) : null}
                        </Box>
                        {node.expanded && !isDragging && (
                          <div
                            style={{ width: scaffoldBlockPxWidth }}
                            className={'rst__lineChildren'}
                          />
                        )}
                      </Box>
                    )
                  }
                </Box>
                <Box flexGrow={1}>
                  <EmployeesTree node={node} lang={lang}/>
                </Box>
              </Box>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
