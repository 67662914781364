import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import TransactionHistoryTableComponent from "./transactionHistoryTable.component";
import { connect } from "react-redux";
import { selectLoading } from "modules/notification";
import { fetchTransactionHistory } from "../../../redux";
import { getYearList } from "lib/generalUtility";
import monthlist from "lib/constants/monthlist";
import { generatePaginationOptions } from "lib/helper";
import { getLang } from "app/feature/constants";

function TransactionHistoryTableContainer({
  handleClose,
  fetchTransactionHistory,
  transactionHistory,
  isFetching,
  filteredlength
}) {
  const totalRecords = useSelector(state => state.billing.transactionHistory.totalRecords);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [pageSetting, setPageSetting] = useState({
    length: 25,
    start: 0,
    column: "created_at",
    order: "desc",
    search: "",
    year: "",
    month: "",
    type: ""
  });

  const [selectedYear, setselectedYear] = useState(
    new Date().getFullYear()
  );
  const [selectedMonth, setselectedMonth] = useState("All");
  const [selectedType, setselectedType] = useState("All");
  const [paginationOptions, setPaginationOptions] = useState([]);

  const lang = useSelector(state => state.constant.languages)

  useEffect(() => {
    fetchTransactionHistory(pageSetting).then((res) => setIsLoading(false));
  }, [pageSetting, fetchTransactionHistory]);

  useEffect(() => {
    setPaginationOptions(generatePaginationOptions(totalRecords))
  }, [totalRecords])

  const yearlist = getYearList();

  const type = [
    {
      label: getLang(lang, "label.QR_CREDIT"),
      value: "serial_number"
    },
    {
      label: getLang(lang, "label.PLAN"),
      value: "plan"
    }
  ];

  const handleYear = (event) => {
    setselectedYear(event.target.value);
    setPageSetting(settings => ({
      ...settings,
      start: 0,
      year: event.target.value === "All" ? "" : event.target.value
    }));
  };

  const handleMonth = (event) => {
    setselectedMonth(event.target.value);
    setPageSetting(settings => ({
      ...settings,
      start: 0,
      month: event.target.value === "All" ? "" : event.target.value
    }));
  };

  const handleType = (event) => {
    setselectedType(event.target.value);
    setPageSetting(settings => ({
      ...settings,
      start: 0,
      type: event.target.value === "All" ? "" : event.target.value
    }));
  };

  function handlePageChange(event, page) {
    setPage(page);
    setPageSetting(settings => ({
      ...settings,
      start: pageSetting.length * page
    }));
  }

  function handleRowPerPageChange(value) {
    setPage(0);
    setPageSetting(settings => ({
      ...settings,
      length: value,
      start: value * 0
    }));
  }

  return (
    <TransactionHistoryTableComponent
      handleClose={handleClose}
      yearlist={yearlist}
      selectedYear={selectedYear}
      selectedMonth={selectedMonth}
      selectedType={selectedType}
      handleYear={handleYear}
      handleMonth={handleMonth}
      handleType={handleType}
      transactionHistory={transactionHistory}
      isFetching={isFetching}
      handlePageChange={handlePageChange}
      handleRowPerPageChange={handleRowPerPageChange}
      page={page}
      isLoading={isLoading}
      length={filteredlength}
      rowsPerPage={pageSetting.length}
      monthlist={monthlist}
      type={type}
      paginationOptions={paginationOptions}
      lang={lang}
    />
  );
}

const mapStateToProps = (state) => ({
  transactionHistory: state.billing.transactionHistory,
  length: state.billing.transactionHistory.totalRecords,
  filteredlength: state.billing.transactionHistory.filteredTotalRecords,
  isFetching: selectLoading(state, fetchTransactionHistory.typePrefix)
});

const mapDispatchToProps = (dispatch) => ({
  fetchTransactionHistory: (pageSetting) =>
    dispatch(fetchTransactionHistory(pageSetting))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransactionHistoryTableContainer);
