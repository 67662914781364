import React from "react";
import PropTypes from "prop-types"
import { 
  Chart as ChartJS, 
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement 
} from 'chart.js';
import { Bar as BarChart } from 'react-chartjs-2';
import { getLang } from "app/feature/constants";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement
);

const BarChartComponent = ({ 
  labels,
  data,
  lang
}) => {
  return (
    <BarChart
      data={{
        labels: [...labels],
        datasets: [{
          label: getLang(lang, 'label.NO_OF_RESPONDENT'),
          data: [...data],
          backgroundColor: [
            "#32D583",
            "#47D675",
            "#5CD867",
            "#70D95A",
            "#85DA4C",
            "#9ADC3E",
            "#AFDD30",
            "#C3DE23",
            "#D8E015",
            "#EDE107"
          ],
          borderRadius: 4,
          barThickness: 50
        }]
      }}
      style={{ maxWidth: '100%' }}
      width={600}
      height={400}
      options={{
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false
          }
        }
      }}
    />
  )
}

BarChartComponent.propTypes = {
  data: PropTypes.array.isRequired,
  labels: PropTypes.array.isRequired,
}

BarChartComponent.defaultProps = {
  data: [0, 0, 0, 0],
  labels: ['Option 1', 'Option 2', "Option 3", "Skipped"]
}

export default BarChartComponent