import axios from "axios";

const get = ({ search = "" }) => {
  return new Promise((resolve, reject) => {
    axios
      .get("/account/api/v1/nodes/group-me-bottom-dropdown-special", {
        params: {
          search: search
        }
      })
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

const getMeAndBranchDropdownSpecial = {
  get
};

export default getMeAndBranchDropdownSpecial;
