import React, { useState, useRef } from "react";
import TabComponent from "./tab.component";
import {
  setType,
  setRegisterForm,
  updateWarrantySettingDetail,
} from "modules/warranty/redux";
import { useSelector, useDispatch } from "react-redux";
import { WARRANTY_LIST_EDIT } from "lib/constants/accessRights";
import { getLang } from "app/feature/constants";
import { addAlert } from "modules/notification";
import { unwrapResult } from "@reduxjs/toolkit";
import { hideBackdrop } from "modules/backdrop";

function TabContainer({ earlyAccess }) {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.constant.languages);
  const hasWarrantyEditAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(WARRANTY_LIST_EDIT)
  );
  const [activeIndex, setActiveIndex] = useState(0);
  const viewWarrantyOnly = useSelector(
    (state) => state.serialNumber.serialNumber.viewWarranty
  );
  const isFormEditing = useSelector(
    (state) => state.warranty.settingFormEditing
  );
  const formRef = useRef();
  const [tempRegForm, setTempRegForm] = useState([]);
  const settingType = useSelector((state) => state.warranty.settingType);
  const registerType = useSelector((state) => state.warranty.registerType);
  const extensionType = useSelector((state) => state.warranty.extensionType);

  const warrantyRegisterForm = useSelector(
    (state) => state.warranty.warrantyRegisterForm
  );

  const warrantySettingForm = useSelector(
    (state) => state.warranty.warrantySetting
  );

  const tabLabels = [
    getLang(lang, "label.WARRANTY_SETTINGS"),
    getLang(lang, "label.WARRANTY_REGISTRATION_FROM_SETTINGS"),
    getLang(lang, "label.WARRANTY_EXTENSION"),
  ];

  const changeToUpdate = (type) => {
    setTempRegForm(warrantyRegisterForm);
    const updateInfo = {
      index: activeIndex,
      type: type,
    };
    dispatch(setType(updateInfo));
  };

  const changeBackView = () => {
    const updateInfo = {
      index: activeIndex,
      type: "view",
    };
    dispatch(setRegisterForm(tempRegForm));
    dispatch(setType(updateInfo));
  };

  const handleChange = (event, index) => {
    setActiveIndex(index);
  };

  const handleUpdate = async () => {
    if (hasWarrantyEditAccessRight) {
      const participantregformdata = formRef.current.values.form;
      const uniqueVariantNames = new Set();

      for (const property of participantregformdata) {
        if (
          property.variantName === undefined ||
          property.variantName.trim() === ""
        ) {
          await dispatch(
            addAlert({
              severity: "error",
              message: getLang(lang, "message.warning.FILL_IN_REQUIRED_FIELD"),
            })
          );
          dispatch(hideBackdrop());
          return;
        }

        if (uniqueVariantNames.has(property.variantName)) {
          await dispatch(
            addAlert({
              severity: "error",
              message: getLang(lang, "message.warning.DUPLICATE_VARIANT_NAME"),
            })
          );
          dispatch(hideBackdrop());
          return;
        }

        uniqueVariantNames.add(property.variantName);

        if (
          property.type === "multipleChoice" ||
          property.type === "dropdownlist" ||
          property.type === "multipleChoiceMultiSelect"
        ) {
          for (const choices of property.choice) {
            if (choices.trim() === "" || choices === undefined) {
              await dispatch(
                addAlert({
                  severity: "error",
                  message: getLang(
                    lang,
                    "message.warning.CHOICES_CANNOT_BE_EMPTY"
                  ),
                })
              );
              dispatch(hideBackdrop());
              return;
            }
          }

          const uniqueChoices = new Set(property.choice);
          if (uniqueChoices.size !== property.choice.length) {
            await dispatch(
              addAlert({
                severity: "error",
                message: getLang(
                  lang,
                  "message.warning.CHOICES_MUST_BE_UNIQUE"
                ),
              })
            );
            dispatch(hideBackdrop());
            return;
          }

          if (property.choice.length < 2) {
            await dispatch(
              addAlert({
                severity: "error",
                message: getLang(
                  lang,
                  "message.warning.MUST_HAVE_AT_LEAST_TWO_CHOICES"
                ),
              })
            );
            dispatch(hideBackdrop());
            return;
          }
        }
      }

      const update = {
        id: warrantySettingForm.id,
        metadata: participantregformdata,
      };
      dispatch(updateWarrantySettingDetail(update))
        .then(unwrapResult)
        .then((r) => {
          const updateInfo = {
            index: activeIndex,
            type: "view",
          };
          dispatch(setType(updateInfo));
        });
    }
  };
  return (
    <TabComponent
      earlyAccess={earlyAccess}
      hasWarrantyEditAccessRight={hasWarrantyEditAccessRight}
      handleChange={handleChange}
      activeIndex={activeIndex}
      tabLabels={tabLabels}
      settingType={settingType}
      registerType={registerType}
      formRef={formRef}
      extensionType={extensionType}
      changeBackView={changeBackView}
      changeToUpdate={changeToUpdate}
      handleUpdate={handleUpdate}
      viewWarrantyOnly={viewWarrantyOnly}
      isFormEditing={isFormEditing}
      lang={lang}
    />
  );
}

export default TabContainer;
