import React from "react";
import DistributeIncentivePointTable from "./distributeIncentivePointTable.component";
import { useSelector } from "react-redux";

export default function DistributeIncentivePointTableContainer({
  rows,
  maxTier,
  formik,
  defaultValue
}) {
  const lang = useSelector(state => state.constant.languages);
  return (
    <DistributeIncentivePointTable
      rows={rows}
      maxTier={maxTier}
      formik={formik}
      defaultValue={defaultValue}
      lang={lang}
    />
  )
}