import React, { useState } from "react";
import ProfilePanelComponent from "./profilePanel.component";
import { connect, useSelector } from "react-redux";
import { updateProfile } from "../../../redux";
import { selectLoading } from "modules/notification";
import { SkeletonProfileComponent } from "../../skeleton/skeleton.component";
import languageType from "lib/constants/languages";

function ProfilePanelContainer({
  profileData,
  fetchProfile,
  updateProfile,
  isFetching,
  isChanging,
  timezones,
}) {
  const [isEditing, setEditing] = useState(false);

  const lang = useSelector(state => state.constant.languages)

  const enableEdit = () => setEditing(true);

  const disableEdit = () => setEditing(false);

  const handleSubmit = async values => {
    values.contactNumber= values.contactNumber.replace(/^0/,'');
    const updateinfo = {
      ...values,
      phoneCode: values.phoneCode.phone,
      locale: languageType[values.language].code,
      language: languageType[values.language].label,
      timezone: values.timezone.id,
    }

    updateProfile(updateinfo);
    setEditing(false);
  };

  if (isFetching) return <SkeletonProfileComponent />;
  return (
    <ProfilePanelComponent
      profileData={profileData}
      handleSubmit={handleSubmit}
      isChanging={isChanging}
      editMode={{ isEditing, enableEdit }}
      timezones={timezones}
      lang={lang}
      disableEdit={disableEdit}
    />
  );
}

const mapStateToProps = state => ({
  profileData: state.profile,
  // isFetching: selectLoading(state, fetchProfile.typePrefix),
  isChanging: selectLoading(state, updateProfile.typePrefiuserNamex),
  timezones: state.constant.timezones
});

const mapDispatchToProps = dispatch => ({
  // fetchProfile: () => dispatch(fetchProfile()),
  updateProfile: profileData => dispatch(updateProfile(profileData))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfilePanelContainer);
