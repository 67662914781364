import axios from "axios";


export function UpdateIncentiveProgramStatus({ id, status }) {
  
  const url = `/distributor/admin/api/v1/programs/${id}/update-status`;

  return new Promise((resolve, reject) => {
    axios.post(url, {
      status
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data))
  })
}