import React, {useState} from "react"
import { useDispatch, useSelector } from "react-redux"
import { selectLoading } from "modules/notification"
import ProductTraceabilityPanelComponent from "./productTraceabilityPanel.component"
import { useRouteMatch } from "react-router-dom"
import { getSerialNumberActivityLog } from "modules/serial-number/redux"

function ProductTraceabilityPanelContainer() {
  const dispatch = useDispatch()
  const match = useRouteMatch()
  const enc = match.params.enc

  const lang = useSelector(state => state.constant.languages);
  const language = useSelector(state => state.constant.language);
  const traceabilityList = useSelector(state => state.serialNumber.serialNumber.activityLog)
  const serialNumber = useSelector(state => state.serialNumber.serialNumber.info)
  const isFetching = useSelector(state => selectLoading(state, getSerialNumberActivityLog.typePrefix))
  const isError = useSelector(state => state.serialNumber.isLoadingActivityLogError)

  const [recordFocus, setRecordFocus] = useState([])

  const handleReload = () => {
    dispatch(getSerialNumberActivityLog(enc))
  }

  const updateRecordFocus = (record, isAdd) => {
    if (isAdd) {
      setRecordFocus([...recordFocus, record]);

      const el = document.getElementById(`track_record_${record.index}`);
      if (el) {
        el.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
      }
    }
    else {
      setRecordFocus(recordFocus.filter(e => e.index !== record.index));
    }
  } 

  return (
    <>
      <ProductTraceabilityPanelComponent 
        traceabilityList={traceabilityList}
        serialNumber={serialNumber}
        isFetching={isFetching}
        isError={isError}
        handleReload={handleReload}
        language={language}
        recordFocus={recordFocus}
        updateRecordFocus={updateRecordFocus}
        lang={lang}
      />
    </>
  )
}

export default ProductTraceabilityPanelContainer
