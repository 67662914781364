import axios from "axios";


export function BulkUpdateRewardStatus({
  rewardUUIDs,
  isSelectAll,
  status,
  filter
}) {

  const url = `/distributor/admin/api/v1/rewards/bulk-update-status`;

  return new Promise((resolve, reject) => {
    axios.post(url, {
      reward_uuids: rewardUUIDs,
      is_select_all: isSelectAll,
      status,
      filter
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data))
  })
}