import React from "react";
import {
  makeStyles,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Box,
  Button,
  Typography
} from "@material-ui/core";
import { StyledTableCell, StyledTableRow } from "components/table";
import { SkeletonTableRow } from "components/skeleton/skeleton.component";
import { ActionButton } from "components/button";
import { TablePaginationActions } from "components/tablePagination";
import Alert from "@material-ui/lab/Alert";
import RefreshTable from "components/table/refreshTable";
import { getLang } from "app/feature/constants";
import StickyRightTableCell from "components/table/stickyRightTableCell";
import StickyLeftTableCell from "components/table/stickyLeftTableCell";

const style = (theme) => ({
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    borderCollapse: "separate",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
    },
    "& th,td": {
      fontSize: ".75rem",
      borderRight: "1px solid rgba(58, 77, 84, 0.1)",
      padding: theme.spacing(1, 2, 1, 2),
    },
    "& th": {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightBold,
    },
    "& th:last-child, td:last-child": {
      width: 37,
      borderRight: "0px",
    },
    "& th th": {
      borderBottom: "none !important",
    },
    "& td td": {
      borderBottom: "none !important",
    },
  },
  tableContainer: {
    maxHeight: 500,
    overflowY: "auto"
  },
  emptyContainer: {
    backgroundColor: theme.palette.background.paper,
    textAlign: "center"
  },
  button: {
    display: "flex",
    alignItems: "center",
    padding: 0,
    minWidth: 0,
    marginLeft: theme.spacing(1)
  }
});

const useStyles = makeStyles(style);

export default function RoleTableComponent({
  currentUserRoleId,
  hasRoleAddAccessRight,
  hasRoleEditAccessRight,
  hasRoleDeleteAccessRight,
  rows,
  isFetching,
  page,
  search,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
  totalRecords,
  handleCreate,
  handleViewEdit,
  handleDelete,
  isLoadingRoleError,
  handleReload,
  paginationOptions,
  lang
}) {
  const classes = useStyles();

  return (
    <>
      {rows.length > 0 || isFetching ? (
        <TableContainer>
          <Table
            className={classes.table}
            aria-label="product table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <StickyLeftTableCell>
                  <StyledTableCell>{getLang(lang, "label.NUM")}</StyledTableCell>
                </StickyLeftTableCell>
                <StyledTableCell>{getLang(lang, "label.ROLE")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.NO_OF_EMPLOYEE")}</StyledTableCell>
                <StickyRightTableCell>
                  <StyledTableCell>{getLang(lang, "label.ACTION")}</StyledTableCell>
                </StickyRightTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isFetching
                ? [...new Array(5)].map((v, index) => (
                  <SkeletonTableRow key={index} columnCount={4} />
                ))
                : rows.map((row, index) => {
                  const actionItems = [
                    {
                      label:
                        hasRoleEditAccessRight && currentUserRoleId !== row.id && row.default === 0
                          ? getLang(lang, "label.VIEW_EDIT_DETAIL")
                          : getLang(lang, "label.VIEW_DETAIL"),
                      action: () => handleViewEdit(row)
                    }
                  ];
                  if (
                    hasRoleDeleteAccessRight &&
                    currentUserRoleId !== row.id &&
                    row.default === 0
                  ) {
                    actionItems.push({
                      label: getLang(lang, "label.DELETE_ROLE"),
                      action: () => handleDelete(row),
                      errorText: true,
                    });
                  }

                  return (
                    <StyledTableRow key={index} hover tabIndex={-1}>
                      <StickyLeftTableCell>
                        <StyledTableCell component="th" scope="row" style={{ borderBottom: "0px" }}>
                          {index + 1 + rowsPerPage * page}
                        </StyledTableCell>
                      </StickyLeftTableCell>
                      <StyledTableCell>{row.name}</StyledTableCell>
                      <StyledTableCell>{row.users.toLocaleString("en-US")}</StyledTableCell>
                      <StickyRightTableCell>
                        <StyledTableCell>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center"
                            }}
                          >
                            <ActionButton actionItems={actionItems} popperStyle={{ zIndex: 999 }} />
                          </div>
                        </StyledTableCell>
                      </StickyRightTableCell>
                    </StyledTableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        !search && !isLoadingRoleError && (
          <Box>
            <Table
              className={classes.table}
              aria-label="product table"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell>{getLang(lang, "label.NUM")}</StyledTableCell>
                  <StyledTableCell>{getLang(lang, "label.ROLE")}</StyledTableCell>
                  <StyledTableCell>{getLang(lang, "label.NO_OF_EMPLOYEE")}</StyledTableCell>
                  <StyledTableCell>{getLang(lang, "label.ACTION")}</StyledTableCell>
                </TableRow>
              </TableHead>
            </Table>
            <Box py={9} className={classes.emptyContainer}>
              <Typography variant="body1">{getLang(lang, "paragraph.NO_ROLE_CREATED")}</Typography>
              {hasRoleAddAccessRight ? (
                <Box mt={2}>
                  <Button
                    onClick={handleCreate}
                    variant="contained"
                    disableElevation
                    color="primary"
                  >
                    {getLang(lang, "label.ADD_ROLE")}
                  </Button>
                </Box>
              ) : null}
            </Box>
          </Box>

        )
      )}

      {!isFetching && rows.length === 0 && search && (
        <Alert severity="info">{getLang(lang, "message.info.NO_ROLE")}</Alert>
      )}

      {!isFetching && isLoadingRoleError && (
        <RefreshTable handleReload={handleReload} />
      )}

      {!isFetching && rows.length > 0 && (
        <TablePagination
          style={{
            backgroundColor: "#fff",
            boxShadow: "0px -1px 0px rgba(58, 77, 84, 0.15)"
          }}
          ActionsComponent={TablePaginationActions}
          rowsPerPageOptions={paginationOptions}
          component="div"
          count={totalRecords}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage={getLang(lang, 'label.ROWS_PER_PAGE')}
          labelDisplayedRows={({ from, to, count }) => `${from.toLocaleString("en-US")}-${to.toLocaleString("en-US")} ${getLang(lang, 'label.OF')} ${count.toLocaleString("en-US")}`}
        />
      )}
    </>
  );
}
