import axios from "axios";

export const getFallbackLanguage = () => {
    return new Promise((resolve, reject) => {
        axios.get(`${window.location.origin}/language-EN.json`)
            .then(response => resolve(response.data))
            .catch(error => reject(error))
    });
};

export const getFallbackApiLanguage = () => {
    return new Promise((resolve, reject) => {
        axios.get(`${window.location.origin}/api-language-EN.json`)
            .then(response => resolve(response.data))
            .catch(error => reject(error))
    });
};