import { createAsyncThunk } from "@reduxjs/toolkit";
import accountApi from "app/api/account";
import { BRANCH } from "../constants";

export const fetchBranchList = createAsyncThunk(
    `${BRANCH}/fetchBranchList`,
    async (payload, thunkApi) => {
        return accountApi.getAllBranches(payload)
            .then(response => response)
            .catch(error => thunkApi.rejectWithValue(error.response.data));
    }
);


export const getBranchGroupDropdown = createAsyncThunk(
    `${BRANCH}/getBranchGroupDropdown`,
    async (payload, thunkApi) => {
        return accountApi.getBranchGroupDropdown()
            .then(response => response)
            .catch(error => thunkApi.rejectWithValue(error.response.data));
    }
);

export const createBranch = createAsyncThunk(
    `${BRANCH}/createBranch`,
    async (payload, thunkApi) => {
        return accountApi.createBranch({
            name: payload.branchName,
            short_name: payload.branchShortName,
            description: payload.desc,
            line1: payload.addressLine1,
            line2: payload.addressLine2,
            city: payload.city,
            country: payload.country.label,
            postal_code: payload.postalCode,
            state: payload.state,
            contact: payload.contact,
            contact_code: payload.contactCode.phone,
            email: payload.email,
            parent_id: payload.parentBranch,
            timezone: payload.timezone.id,
            branch_group_uuid: payload.branchGroupUuid ?? null,
            social_media_urls: payload.socialMediaUrls,
            is_show_contact: payload.isShowContact,
            is_show_email: payload.isShowEmail,
            landing_page_language: payload.landingPageLanguage,
        })
            .then(response => response.data)
            .catch(error => thunkApi.rejectWithValue(error.response.data));
    }
);

export const editBranch = createAsyncThunk(
    `${BRANCH}/editBranch`,
    async (payload, thunkApi) => {
        return accountApi.editBranch({
            id: payload.id,
            name: payload.branchName,
            description: payload.desc,
            line1: payload.addressLine1,
            line2: payload.addressLine2,
            city: payload.city,
            country: payload.country.label,
            postal_code: payload.postalCode,
            state: payload.state,
            contact: payload.contact,
            contact_code: payload.contactCode.phone,
            email: payload.email,
            parent_id: payload.parentBranch,
            timezone: payload.timezone.id,
            branch_group_uuid: payload.branchGroupUuid ?? null,
            profile_image_url: payload.profileImage ?? null,
            social_media_urls: payload.socialMediaUrls,
            is_show_contact: payload.isShowContact,
            is_show_email: payload.isShowEmail,
            landing_page_language: payload.landingPageLanguage,
        })
            .then(response => response.data)
            .catch(error => thunkApi.rejectWithValue(error.response.data));
    }
);

export const editBranchStatus = createAsyncThunk(
    `${BRANCH}/editBranchStatus`,
    async (payload, thunkApi) => {
        return accountApi.editBranchStatus({
            id: payload.id,
            status: payload.status
        })
            .then(response => response.data)
            .catch(error => thunkApi.rejectWithValue(error.response.data));
    }
);

export const fetchBranchDropdown = createAsyncThunk(
    `${BRANCH}/fetchBranchDropdown`,
    async (payload, { rejectWithValue }) => {
        return accountApi.branchDropdown()
            .then(response => response)
            .catch(error => rejectWithValue(error));
    }
);

export const fetchBranchDropdownSpecial = createAsyncThunk(
    `${BRANCH}/fetchBranchDropdown`,
    async (payload, { rejectWithValue }) => {
        return accountApi.BranchDropdownSpecial()
            .then(response => response)
            .catch(error => rejectWithValue(error));
    }
);

export const fetchEditBranchDropdown = createAsyncThunk(
    `${BRANCH}/fetchEditBranchDropdown`,
    async (payload, { rejectWithValue }) => {
        return accountApi.hqEditBranchDropdown({ uuid: payload.uuid,})
            .then(response => response)
            .catch(error => rejectWithValue(error));
    }
);

export const setBranchProfile = createAsyncThunk(
  `${BRANCH}/setBranchProfile`,
  async (payload, { rejectWithValue }) => {
    return accountApi.setBranchProfile({ id: payload.id, url: payload.url })
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)
