import React, { useRef } from "react";
import {
  makeStyles,
  Typography,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  Box,
  Divider,
  InputAdornment,
  Button,
  MenuItem,
  MenuList,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import PerfectScrollBar from "react-perfect-scrollbar";
import { Skeleton } from "@material-ui/lab";
import PropTypes from "prop-types";
import InputTextField from "components/input/inputTextField";
import DefaultImg from "assets/img/img-default.png";
import { useSelector } from "react-redux";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles((theme) => ({
  paper: {
    width: 385,
  },
  button: {
    padding: 0,
    minWidth: 0,
  },
  button2: {
    display: "flex",
    padding: 0,
    minWidth: 0,
  },
  createLink: {
    padding: theme.spacing(2),
  },
  prefectScrollbar: {
    height: 200,
  },
  list: {
    paddingTop: 6,
    paddingBottom: 6,
  },
  attributeText: {
    color: "#b2b2b2",
    fontSize: 12,
    whiteSpace: "break-spaces",
  },
}));

function SearchableSelectPanelComponent({
  open,
  anchorRef,
  handleClose,
  handleListKeyDown,
  isOpenList,
  items,
  isFetching,
  handleSearch,
  emptyMessage,
  searchable,
  showImg,
}) {
  const classes = useStyle();
  const textRef = useRef();
  const lang = useSelector((state) => state.constant.languages);
  return (
    <Popper
      open={open}
      style={{ zIndex: 99 }}
      anchorEl={anchorRef.current}
      role={undefined}
      transition
      disablePortal
      placement="bottom-start"
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === "bottom-start" ? "center top" : "center bottom",
          }}
        >
          <Paper className={classes.paper}>
            <ClickAwayListener onClickAway={handleClose}>
              <Box>
                {searchable && (
                  <Box>
                    <InputTextField
                      variant="filled"
                      size="small"
                      fullWidth
                      inputRef={textRef}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            style={{ paddingRight: "6px" }}
                          >
                            <Button
                              onClick={() =>
                                handleSearch(textRef.current.value)
                              }
                              className={classes.button}
                            >
                              <SearchIcon fontSize="small" />
                            </Button>
                          </InputAdornment>
                        ),
                        style: {
                          backgroundColor: "#f7f7f7",
                          paddingRight: 0,
                        },
                      }}
                      inputProps={{
                        onKeyDown: (e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            handleSearch(textRef.current.value);
                            return false;
                          }
                        },
                        style: {
                          padding: 14,
                        },
                      }}
                      placeholder={getLang(lang, "placeholder.SEARCH_PRODUCT")}
                    />
                  </Box>
                )}
                <Divider />
                <PerfectScrollBar className={classes.prefectScrollbar}>
                  {isFetching ? (
                    <>
                      <Skeleton variant="text" animation="wave" height={40} />
                      <Skeleton variant="text" animation="wave" height={40} />
                      <Skeleton variant="text" animation="wave" height={40} />
                      <Skeleton variant="text" animation="wave" height={40} />
                    </>
                  ) : items.length > 0 ? (
                    items.map((item) => (
                      <Box key={item.name ? item.name : item}>
                        <MenuList
                          autoFocusItem={isOpenList}
                          id="menu-list-grow"
                          onClick={() => {
                            handleListKeyDown(item);
                          }}
                          className={classes.list}
                        >
                          <MenuItem>
                            {showImg === true && (
                              <Box className={classes.listImage}>
                                <img
                                  src={item.picture ? item.picture : DefaultImg}
                                  style={{ width: "30px", height: "30px" }}
                                  alt={item.id}
                                />
                              </Box>
                            )}
                            <Box
                              display="flex"
                              flexDirection="column"
                              justifyContent="center"
                              alignItems="flex-start"
                            >
                              <Typography
                                className={classes.name}
                                variant="body2"
                              >
                                {item.name ? item.name : item}
                              </Typography>
                              {!!item.attributes &&
                                !!item.attributes.length && (
                                  <Typography
                                    className={classes.attributeText}
                                    variant="body2"
                                  >
                                    {item.attributes.join(", ")}
                                  </Typography>
                                )}
                            </Box>
                          </MenuItem>
                        </MenuList>
                      </Box>
                    ))
                  ) : (
                    <Box py={9} style={{ textAlign: "center" }}>
                      <Typography variant="body1">{emptyMessage}</Typography>
                    </Box>
                  )}
                </PerfectScrollBar>
              </Box>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}

SearchableSelectPanelComponent.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleListKeyDown: PropTypes.func,
  isOpenList: PropTypes.bool,
  items: PropTypes.array.isRequired,
  isFetching: PropTypes.bool,
  handleSearch: PropTypes.func,
  placeholder: PropTypes.string,
  emptyMessage: PropTypes.string,
};

export default SearchableSelectPanelComponent;
