import React from 'react'
import {
    makeStyles,
    Step,
    StepConnector,
    StepLabel,
    Stepper,
    withStyles
} from '@material-ui/core'
import clsx from 'clsx'

const useStyle = makeStyles(theme => ({
    stepperRoot: {
        padding: 0
    },
    stepLabelRoot: {
        height: 100,
        position: "relative"
    },
    stepLabel: {
        color: theme.palette.primary.main + " !important"
    },
    stepLabelActive: {
        "&::after": {
            content: "''",
            backgroundColor: theme.palette.primary.main,
            width: "20%",
            height: 3,
            position: "absolute",
            bottom: 0,
            borderRadius: theme.spacing(1, 1, 0, 0)
        }
    },
    iconContainer: {
        minHeight: 38
    }
}))

const Connector = withStyles((theme) => ({
    alternativeLabel: {
        padding: "0 60px",
        top: "20px",
        [theme.breakpoints.down("xs")]: {
            padding: "0 20px"
        }
    },
    active: {
        "& $line": {
            borderColor: theme.palette.primary.main
        }
    },
    completed: {
        "& $line": {
            borderColor: theme.palette.primary.main
        }
    },
    line: {
        borderColor: "rgba(58, 77, 84, 0.33)",
        borderTopWidth: 4,
        borderRadius: 5
    }
}))(StepConnector)

export default function StepperComponent({
    steps,
    activeStep
}) {
    const classes = useStyle()

    return (
        <Stepper
            activeStep={activeStep}
            alternativeLabel
            connector={<Connector />}
            classes={{ root: classes.stepperRoot }}
        >
            {steps.map((step, index) => (
                <Step key={index}>
                    <StepLabel
                        classes={{
                            root: clsx(classes.stepLabelRoot, {
                                [classes.stepLabelActive]: activeStep >= index
                            }),
                            label: clsx({
                                [classes.stepLabel]: activeStep >= index
                            }),
                            iconContainer: classes.iconContainer
                        }}
                        StepIconComponent={() => activeStep >= index ? step.image() : step.image("rgba(58, 77, 84, 0.33)")}
                    >
                        {step.label}
                    </StepLabel>
                </Step>
            ))}
        </Stepper >
    )
}
