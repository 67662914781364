import React from "react";
import AddEmployeeTableComponent from "./addEmployeeTable.component";
import { useSelector } from "react-redux";

export default function AddEmployeeTableContainer({ tableData, formik }) {
  const branchDropdown = useSelector((state) => state.employee.branchDropdown);
  const lang = useSelector(state => state.constant.languages);

  const handleDelete = (index) => {
    const temp = formik.values.employees.slice();
    temp.splice(index, 1);
    formik.setFieldValue("employees", temp);
  };
  return (
    <AddEmployeeTableComponent
      tableData={tableData}
      branchDropdown={branchDropdown}
      handleDelete={handleDelete}
      lang={lang}
    />
  );
}
