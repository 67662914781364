import { addAlert, setLoading } from "modules/notification";
import { getFilterBranchesData, getFilterCountryCityData } from "../action";

// (1) branch -- start
export const getBranchesDataMiddleware = ({ dispatch, getState}) => next => action => {
    next(action);
    if (action.type === getFilterBranchesData.pending.type) {
        dispatch(setLoading({ id: getFilterBranchesData.typePrefix, state: true }));
    }
    if (action.type === getFilterBranchesData.fulfilled.type) {
        dispatch(setLoading({ id: getFilterBranchesData.typePrefix, state: false }));
    }
    if (action.type === getFilterBranchesData.rejected.type) {
        dispatch(setLoading({ id: getFilterBranchesData.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: action.payload.message}));
    }
}
// (1) branch -- end

// (2) country city -- start
export const getCountryCityDataMiddleware = ({ dispatch, getState}) => next => action => {
    next(action);
    if (action.type === getFilterCountryCityData.pending.type) {
        dispatch(setLoading({ id: getFilterCountryCityData.typePrefix, state: true }));
    }
    if (action.type === getFilterCountryCityData.fulfilled.type) {
        dispatch(setLoading({ id: getFilterCountryCityData.typePrefix, state: false }));
    }
    if (action.type === getFilterCountryCityData.rejected.type) {
        dispatch(setLoading({ id: getFilterCountryCityData.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: action.payload.message}));
    }
}
// (2) country city -- end

export default [
    getBranchesDataMiddleware,
    getCountryCityDataMiddleware,
]