import React from "react";
import Chart from 'react-apexcharts';
import { setChartColors } from "modules/reporting/utils/chartConfig";
import moment from "moment";
import "moment/min/locales.min";
import { useSelector } from "react-redux";

export default function MixedChart({
    payload, chartHeight, chartColors = [], translate, dontAddUnknown = true, multipleYAxis = [], dashLine = false
}) {
    const language = useSelector((state) => state.profile.language)
    
  if(language==="ZH")
  {
    moment.locale('zh-cn')
  }else if(language==="TL")
  {
    moment.locale('tl-ph')
  }else
  {
    moment.locale(language)
  }
;
    
    const chartSeries = () => {
        let series = JSON.stringify(payload);
        series = JSON.parse(series);

        series.datasets.forEach(e => {
            e.name = translate(e.name);
        });

        return series;
    }

    let yAxis = [
        {
            axisTicks: {
                show: true
            },
            axisBorder: {
                show: true,
                color: "#008FFB"
            },
            title: {
                text: "Total Scan QR",
                color: "#008FFB"
            }
        },
        {
            opposite: true,
            seriesName: "Survey Received",
            axisTicks: {
                show: true
            },
            axisBorder: {
                show: true,
                color: "#35CA91"
            },
            title: {
                text: "Campaign Engaged",
                color: "#35CA91"
            }
        }
    ];

    if (multipleYAxis.length > 0) {
        yAxis = [];
        multipleYAxis.forEach((e, i) => {
            let newYaxis = {
                axisTicks: {
                    show: true
                },
                axisBorder: {
                    show: true,
                    color: e.color ?? "#008FFB"
                },
                title: {
                    text: e.name ?? "?",
                    color: e.color ?? "#008FFB"
                },
                // opposite: i % 2 === 0 ? false : true
                opposite: e.opposite ?? false
            }
            yAxis.push(newYaxis)
        });
    }
    const options = {
        markers: {
            size: 4
        },
        colors: chartColors.length > 0 ? chartColors : setChartColors(payload.datasets.length, dontAddUnknown),
        xaxis: {
            categories: payload.labels.map(date => moment(date).format('ll')),
            tickPlacement: 'between',
            labels: {
                hideOverlappingLabels: true,
                rotateAlways: true,
                rotate: -45
            }
        },
        fill: {
            opacity: 1
        },
        legend: {
            position: 'bottom',
            itemMargin: {
                horizontal: 20,
                vertical: 16
            },
            // horizontalAlign: 'left',
            offsetX: 40
        },
        yaxis: yAxis,
        stroke: {
            width: [2, 2],
            // dashArray: dashLine ? [8, 0] : [0, 0]
            dashArray: [0, 0]
        },
    }
    return (
        <Chart
            series={
                chartSeries().datasets
            }

            options={
                options
            }

            type="line"
            height={chartHeight ?? 'auto'}
        >

        </Chart>
    )
}