import React from "react";
import {
  makeStyles,
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Tooltip,
  withStyles,
} from "@material-ui/core";
import { convertToNumberString } from "lib/generalUtility";
//import PerfectScrollbar from "react-perfect-scrollbar"
import { getLang } from "app/feature/constants";
import { SkeletonTableRow } from "components/skeleton/skeleton.component";
import RefreshTable from "components/table/refreshTable";
import { SERIAL_NUMBER_TYPE_NUMBER } from "modules/serial-number";
import HelpIcon from "@material-ui/icons/Help";

const useStyle = makeStyles((theme) => ({
  container: {
    padding: 16,
    background: "white",
    borderRadius: 4,
  },
  tableContainer: {
    maxHeight: 350,
    overflowY: "auto",
  },
  table: {
    border: "1px solid #D0D5DD",
    tableLayout: "auto",
    whiteSpace: "nowrap",
    maxHeight: 500,
    overflowY: "auto",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
    },
    "& th": {
      backgroundColor: "white",
      color: "#98A2B3",
    },
  },
  greenLabel: {
    color: "#6AAF68",
  },
  emptyContainer: {
    backgroundColor: theme.palette.background.paper,
    alignItems: "center",
    display: "flex",
    placeContent: "center",
    border: "1px solid rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
  },
  filterSection: {
    display: "grid",
    gridTemplateColumns: "repeat(3,1fr)",
    columnGap: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
    },
  },
  filterBox: {
    height: 34,
    backgroundColor: "white",
    border: "2px solid #D0D5DD",
  },
  paperRoot: {
    width: "217px",
  },
}));

const StyledTooltip = withStyles({
  tooltip: {
    backgroundColor: "#3A4D54",
    color: "white",
    padding: 16,
  },
})(Tooltip);

export default function SnAvailabilityListTableComponent({
  isLoading,
  error,
  list,
  handleReload,
  lang,
  format,
  activeType,
}) {
  const classes = useStyle();

  return (
    <Paper elevation={0} className={classes.container} id="preview-table">
      {!isLoading && error && <RefreshTable handleReload={handleReload} />}
      {(isLoading || !!list.length) && (
        <Box>
          <TableContainer className={classes.tableContainer}>
            <Table
              className={classes.table}
              aria-label="sn table"
              size="small"
              stickyHeader
            >
              <TableHead style={{ height: "45px", background: "white" }}>
                <TableRow>
                  <TableCell>
                    <Typography variant="body2">
                      {getLang(lang, "label.NUM")}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Box>
                      <Typography variant="body2">
                        <b>{getLang(lang, "label.SERIAL_NO")}</b>
                      </Typography>
                      <Typography variant="body2">
                        {getLang(lang, "label.FROM")}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell style={{ verticalAlign: "bottom" }}>
                    <Typography variant="body2">
                      {getLang(lang, "label.TO")}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Box>
                      <Typography variant="body2">
                        <b>{getLang(lang, "label.SEQUENCE_NO")}</b>
                      </Typography>
                      <Typography variant="body2">
                        {getLang(lang, "label.FROM")}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell style={{ verticalAlign: "bottom" }}>
                    <Typography variant="body2">
                      {getLang(lang, "label.TO")}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    {getLang(lang, "label.QUANTITY")}
                  </TableCell>
                  <TableCell>
                    {getLang(lang, "label.BATCH_NUMBER")}
                  </TableCell>
                  <TableCell align="right">
                    {getLang(lang, "label.STATUS")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading
                  ? [...new Array(5)].map((v, index) => (
                      <SkeletonTableRow key={index} columnCount={8} />
                    ))
                  : list.map((sn, index) => {
                      const pattern = format.split(",");

                      let fromSnDigit = sn.from_sn;
                      let toSnDigit = sn.to_sn;
                      if (pattern[0]) {
                        if (sn.from_sn.startsWith(pattern[0])) {
                          fromSnDigit = fromSnDigit
                            .slice(pattern[0].length)
                            .trim();
                        }
                        if (sn.to_sn.startsWith(pattern[0])) {
                          toSnDigit = toSnDigit.slice(pattern[0].length).trim();
                        }
                      }

                      if (pattern[2]) {
                        if (sn.from_sn.endsWith(pattern[2])) {
                          fromSnDigit = fromSnDigit
                            .slice(0, fromSnDigit.length - pattern[2].length)
                            .trim();
                        }
                        if (sn.to_sn.endsWith(pattern[2])) {
                          toSnDigit = toSnDigit
                            .slice(0, toSnDigit.length - pattern[2].length)
                            .trim();
                        }
                      }

                      return (
                        <TableRow
                          key={index}
                          hover
                          role="checkbox"
                          tabIndex={-1}
                        >
                          <TableCell>
                            <Typography color="textSecondary">
                              <b>{index + 1}</b>
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Box
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Typography variant="body2" style={{ marginRight: 8 }}>
                                <span className={classes.greenLabel}>
                                  <b>{pattern[0]}</b>
                                </span>
                                <span>
                                  <b>{fromSnDigit}</b>
                                </span>
                                <span className={classes.greenLabel}>
                                  <b>{pattern[2]}</b>
                                </span>
                              </Typography>
                              <Typography>
                                <b>-</b>
                              </Typography>
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2">
                              <span className={classes.greenLabel}>
                                <b>{pattern[0]}</b>
                              </span>
                              <span>
                                <b>{toSnDigit}</b>
                              </span>
                              <span className={classes.greenLabel}>
                                <b>{pattern[2]}</b>
                              </span>
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Box
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Typography variant="body2" style={{ marginRight: 8 }}>
                                <b>{sn.from_seq_num}</b>
                              </Typography>
                              <Typography>
                                <b>-</b>
                              </Typography>
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2">
                              <b>{sn.to_seq_num}</b>
                            </Typography>
                          </TableCell>
                          <TableCell align="right">
                            <Typography
                              color={
                                sn.quantity <= 0
                                  ? "textSecondary"
                                  : "textPrimary"
                              }
                              variant="body2"
                            >
                              <b>{convertToNumberString(sn.quantity)}</b>
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2">
                              {sn.batch_num}
                            </Typography>
                          </TableCell>
                          <TableCell align="right">
                            {sn.is_available ? (
                              <Typography
                                style={{ color: "#32D583" }}
                                variant="body2"
                              >
                                {getLang(lang, "label.AVAILABLE")}
                              </Typography>
                            ) : (
                              <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="flex-end"
                              >
                                <Box mr={0.5}>
                                  <Typography
                                    style={{ color: "#98A2B3" }}
                                    variant="body2"
                                  >
                                    {getLang(lang, "label.SKIPPED")}
                                  </Typography>
                                </Box>
                                <StyledTooltip
                                  placement="top"
                                  title={
                                    <>
                                      <Box mb={0.5}>
                                        <Typography
                                          color="inherit"
                                          variant="body2"
                                        >
                                          {getLang(lang, "label.SKIPPED")}
                                        </Typography>
                                      </Box>
                                      <Typography
                                        color="inherit"
                                        variant="body2"
                                      >
                                        {/* {getLang(
                                          lang,
                                          "paragraph.SN_SKIPPED_TOOLTIP"
                                        )} */}
                                        {getLang(
                                          lang,
                                          "paragraph.SN_SKIPPED_ASSIGN_TOOLTIP",
                                          { name: sn.product_name || "" }
                                        )}
                                      </Typography>
                                    </>
                                  }
                                >
                                  <HelpIcon fontSize="small" />
                                </StyledTooltip>
                              </Box>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
      {!isLoading && !error && !list.length && (
        <Box>
          <Table
            className={classes.table}
            aria-label="sn table"
            size="small"
            stickyHeader
          >
            <TableHead style={{ height: "45px" }}>
              <TableRow>
                <TableCell>{getLang(lang, "label.NUM")}</TableCell>
                {activeType === SERIAL_NUMBER_TYPE_NUMBER && (
                  <>
                    <TableCell>
                      <Box>
                        <Typography variant="body2">
                          <b>{getLang(lang, "label.SERIAL_NO")}</b>
                        </Typography>
                        <Typography variant="body2">
                          {getLang(lang, "label.FROM")}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell style={{ verticalAlign: "bottom" }}>
                      <Typography variant="body2">
                        {getLang(lang, "label.TO")}
                      </Typography>
                    </TableCell>
                  </>
                )}
                <TableCell>
                  <Box>
                    <Typography variant="body2">
                      <b>{getLang(lang, "label.SEQUENCE_NO")}</b>
                    </Typography>
                    <Typography variant="body2">
                      {getLang(lang, "label.FROM")}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell style={{ verticalAlign: "bottom" }}>
                  <Typography variant="body2">
                    {getLang(lang, "label.TO")}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  {getLang(lang, "label.QUANTITY")}
                </TableCell>
                <TableCell align="right">
                  {getLang(lang, "label.BATCH_NUMBER")}
                </TableCell>
                <TableCell align="right">
                  {getLang(lang, "label.STATUS")}
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
          <Box py={9} className={classes.emptyContainer}>
            <Typography
              variant="body1"
              style={{ paddingBottom: "16px", fontWeight: "bold" }}
            >
              {getLang(lang, "paragraph.SN_AVAILABILITY_NO_RESULT")}
            </Typography>
          </Box>
        </Box>
      )}
    </Paper>
  );
}
