import React from "react";

export const SortIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 12 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.80458 2.5287L3.47125 0.195372C3.21125 -0.0649612 2.78858 -0.0649612 2.52858 0.195372L0.19525 2.5287C-0.0650833 2.78904 -0.0650833 3.21104 0.19525 3.47137C0.45525 3.7317 0.877916 3.7317 1.13792 3.47137L2.33325 2.27604V15.3334C2.33325 15.7014 2.63192 16 2.99991 16C3.36791 16 3.66658 15.7014 3.66658 15.3334V2.27604L4.86191 3.47137C4.99191 3.6017 5.16258 3.6667 5.33325 3.6667C5.50391 3.6667 5.67458 3.6017 5.80458 3.47137C6.06491 3.21104 6.06491 2.78904 5.80458 2.5287Z"
      fill="#929292"
    />
    <path
      d="M11.8046 12.5287C11.5443 12.2683 11.1223 12.2683 10.8619 12.5287L9.66659 13.724V0.666666C9.66659 0.298667 9.36792 0 8.99992 0C8.63192 0 8.33325 0.298667 8.33325 0.666666V13.724L7.13792 12.5287C6.87759 12.2683 6.45525 12.2683 6.19525 12.5287C5.93492 12.789 5.93492 13.211 6.19525 13.4713L8.52859 15.8047C8.65892 15.935 8.82925 16 8.99992 16C9.17059 16 9.34092 15.935 9.47126 15.8047L11.8046 13.4713C12.0649 13.211 12.0649 12.789 11.8046 12.5287Z"
      fill="#929292"
    />
  </svg>
);

export const ShowIcon = () => (
  <svg
    width="15"
    height="10"
    viewBox="0 0 15 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.7046 4.70758C14.5742 4.52385 11.4676 0.208811 7.49993 0.208811C3.53226 0.208811 0.42554 4.52385 0.295299 4.7074C0.171815 4.88171 0.171815 5.11812 0.295299 5.29242C0.42554 5.47615 3.53226 9.79119 7.49993 9.79119C11.4676 9.79119 14.5742 5.47613 14.7046 5.29257C14.8282 5.11829 14.8282 4.88171 14.7046 4.70758ZM7.49993 8.79991C4.57732 8.79991 2.04603 5.93594 1.29672 4.99966C2.04506 4.06256 4.57105 1.20009 7.49993 1.20009C10.4224 1.20009 12.9535 4.06356 13.7031 5.00034C12.9548 5.93741 10.4288 8.79991 7.49993 8.79991Z"
      fill="#3A4D54"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.32914 2.22858C3.63247 1.11456 5.44652 0 7.49993 0C9.55334 0 11.3674 1.11456 12.6707 2.2286C13.9776 3.34573 14.7981 4.48558 14.8682 4.58435C15.044 4.83192 15.0439 5.16817 14.8682 5.41579C14.7982 5.51438 13.9776 6.65428 12.6707 7.77141C11.3674 8.88543 9.55334 10 7.49993 10C5.44652 10 3.63247 8.88544 2.32914 7.7714C1.02221 6.65428 0.201678 5.51443 0.131596 5.41557C-0.0438426 5.16792 -0.0438767 4.83195 0.131562 4.58431C0.201588 4.48562 1.0222 3.34568 2.32914 2.22858ZM0.459003 5.16928C0.387507 5.06832 0.387518 4.93145 0.459037 4.83049L0.459003 5.16928ZM0.459037 4.83049C0.519942 4.74467 1.31774 3.63574 2.58802 2.54997C3.86253 1.46058 5.58567 0.417623 7.49993 0.417623C9.41418 0.417623 11.1373 1.46058 12.4118 2.54999C13.6827 3.63631 14.4806 4.74584 14.5409 4.83081C14.6124 4.93146 14.6125 5.06835 14.541 5.16926C14.4806 5.25423 13.6827 6.36372 12.4118 7.45002C11.1373 8.53942 9.41418 9.58238 7.49993 9.58238C5.58567 9.58238 3.86253 8.53942 2.58802 7.45001C1.31735 6.36389 0.519428 5.25452 0.459003 5.16928M1.5609 4.99952C1.96689 5.49227 2.72518 6.33629 3.70398 7.08309C4.78021 7.90423 6.10115 8.5911 7.49993 8.5911C8.90192 8.5911 10.2231 7.90451 11.2986 7.08374C12.2767 6.33725 13.0336 5.49345 13.439 5.00047C13.0329 4.50755 12.2745 3.66349 11.2957 2.91672C10.2195 2.09565 8.89864 1.4089 7.49993 1.4089C6.09793 1.4089 4.77672 2.09548 3.70129 2.91624C2.72317 3.66274 1.96627 4.50653 1.5609 4.99952ZM3.45995 2.58068C4.56682 1.73593 5.97304 0.991275 7.49993 0.991275C9.02368 0.991275 10.4296 1.73626 11.537 2.58112C12.6471 3.4281 13.4792 4.39226 13.8596 4.86752L13.9657 5.0002L13.8597 5.133C13.48 5.60843 12.6495 6.57249 11.5399 7.41931C10.433 8.26406 9.02681 9.00873 7.49993 9.00873C5.9761 9.00873 4.57009 8.26361 3.46269 7.41869C2.35252 6.57165 1.52046 5.60749 1.14031 5.13249L1.03412 4.99981L1.14017 4.867C1.51986 4.39156 2.35038 3.4275 3.45995 2.58068Z"
      fill="#3A4D54"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.41036 4.99996C4.41036 3.24488 5.79619 1.81729 7.49993 1.81729C9.20366 1.81729 10.5895 3.24488 10.5895 4.99996C10.5895 6.75504 9.20366 8.18263 7.49993 8.18263C5.79619 8.18263 4.41036 6.75504 4.41036 4.99996Z"
      fill="#3A4D54"
    />
  </svg>
);


