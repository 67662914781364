import React, { useState } from "react";
import { Box, IconButton, makeStyles, Typography } from "@material-ui/core";
import { DownloadIcon, LineChartIcon, VerticalBarChartIcon } from "modules/reporting/assets/svg";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";

import downloadjs from 'downloadjs';
import html2canvas from 'html2canvas';
import { LineChart, ComparisonChart, VerticalBarChart } from "../../charts";

const useStyles = makeStyles({
    chartCard: {
        padding: "20px",
        backgroundColor: "#FFFFFF",
        borderRadius: "12px",
        border: "1px solid #D0D5DD",
        height: "100%",
        maxHeight: "530px",
        overflowY: "auto",
        overflowX: "hidden",
    },
    chartCard__title : {
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "20px",
        color: "#101828",
    },
    global__m20: {
        marginBottom: "20px"
    }
});

export default function DownloadChartCardComponent({
    title,
    type="line",
    payload,
    canSwitchChartType,
    chartColors=[],
    chartHeight,
    translate
}){
    const classes = useStyles();

    // button highlight selection

    let chartType = type;
    if(type === "line"){
        if(payload.labels.length === 1){
            chartType = "bar"
        } else {
            chartType = "line"
        }
    }

    const [chartTypeSelection, setChartTypeSelection] = useState(chartType);
    const handleChartTypeSelection = (event, newChartTypeSelection) => {
        if (newChartTypeSelection === chartTypeSelection || newChartTypeSelection === null) {
            return;
        }
        setChartTypeSelection(newChartTypeSelection);
    };

    let buttonGroupId = title + '_buttonGroup';
    // download miscellaneous chart
    const handleDownloadCard = () => {
        let element = document.getElementById(title);

        // hide element have id = remove-this inside target
        // let removeThis = element.querySelector(buttonGroupId);
        // console.log(element, removeThis, title, buttonGroupId);
        // removeThis.style.display = "none";

        html2canvas(element).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            downloadjs(imgData, 'chart_'+ title +'.png');
        });

        // // show element have id = remove-this inside target
        // removeThis.style.display = "flex";
    }

    return (
        <Box className={classes.chartCard} id={title}>
            <Box
                display="flex"
                justifyContent="space-between"
                className={classes.global__m20}
            >
                <Typography className={classes.chartCard__title}>
                    {title}
                </Typography>
                <Box display="flex" alignContent="center" className={classes.global__flexGap20} id={buttonGroupId}>
                    <IconButton title="Download Charts" aria-label="download" onClick={handleDownloadCard} disableRipple>
                        <DownloadIcon />
                    </IconButton>
                    {canSwitchChartType &&
                    <ToggleButtonGroup
                        value={chartTypeSelection}
                        exclusive
                        onChange={handleChartTypeSelection}
                    >
                        <ToggleButton value="line">
                            <LineChartIcon />
                        </ToggleButton>
                        <ToggleButton value="bar">
                            {/* <HorizontalBarChartIcon /> */}
                            <VerticalBarChartIcon />
                        </ToggleButton>
                    </ToggleButtonGroup>
                    }
                </Box>
            </Box>

            {chartTypeSelection === "line" && 
            <LineChart translate={translate} chartColors={chartColors} payload={payload} chartHeight={chartHeight}/>}
            {/* {chartTypeSelection === "bar" && 
            <HorizontalBarChart chartColors={chartColors} payload={payload} chartHeight={chartHeight}/>} */}
            {chartTypeSelection === "bar" && 
            <VerticalBarChart translate={translate} chartColors={chartColors} payload={payload} chartHeight={chartHeight} stacked={true}/>}
            {chartTypeSelection === "comparison" &&
            <ComparisonChart translate={translate} payload={payload} />}
        </Box>
    )
}