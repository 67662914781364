import validationError from '../../constants/react-dropzone-error'

const getErrorMessageByCode = ({ errorCode, defaultErrorMessage }) => {
    let errorMessage = ""

    switch (errorCode) {
        case validationError.invalidFileType:
            errorMessage = "Invalid file format"
            break;
        case validationError.multipleFile:
            errorMessage = "Only allowed to upload single file"
            break;
        case validationError.fileTooLarge:
            errorMessage = "Uploaded file is too large"
            break;
        default:
            errorMessage = defaultErrorMessage
            break;
    }

    return errorMessage
}

export default getErrorMessageByCode