export const LOYALTYPROGRAM = "loyaltyProgram"

export const REDEMPTIONCENTER = "redemptionCenter"

export const loyaltyType = {
    point: 0,
    bonanza: 1,
}

export const redemptionCenterType = {
    gift: 0,
    order: 1,
    pointCut: 2
}

