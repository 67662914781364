import FadeTextComponent from "./fadeText.component";
import React, { useEffect } from "react";

export default function FadeTextContainer({ companyName, planName, intervalPlan }) {
  const [fadeText, setfadeText] = React.useState("");

  useEffect(() => {
    var count = 0;
    const fadeTextlist = [companyName, planName, intervalPlan];
    const setText = () => {
      setfadeText(fadeTextlist[count]);
      count = count + 1;
      if (count > 2) {
        count = 0;
      }
    };
    const interval = setInterval(setText, 2500);
    return () => clearInterval(interval);
  }, [companyName, planName, intervalPlan]);

  return <FadeTextComponent fadeText={fadeText} />;
}
