import React from "react";
import { Box, Grid } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import {
  StyledTableCell,
  StyledTableRow,
  StickyRightTableCell,
  StickyLeftTableCell,
} from "components/table";

export const SkeletonCounterfeitReportDetailComponent = () => (
  <Box>
    <Skeleton variant="text" animation="wave" width={100} height={40} />
    <Skeleton variant="rect" animation="wave" width={250} height={250} />

    <div style={{ paddingTop: "16px" }}>
      <Skeleton variant="text" animation="wave" width={200} height={40} />
      <Skeleton variant="text" animation="wave" width={200} height={40} />
    </div>

    <Grid container spacing={2}>
      {[1, 2, 3].map((i, index) => {
        return (
          <React.Fragment key={index}>
            <Grid item xs={3} sm={3} md={3} style={{ width: "100%" }}>
              <Skeleton variant="text" animation="wave" height={40} />
              <Skeleton variant="text" animation="wave" height={40} />
            </Grid>
          </React.Fragment>
        );
      })}
    </Grid>

    <Grid container spacing={2} style={{ paddingTop: "16px" }}>
      {[1, 2].map((i, index) => {
        return (
          <React.Fragment key={index}>
            <Grid item xs={3} sm={3} md={3} style={{ width: "100%" }}>
              <Skeleton variant="text" animation="wave" height={40} />
              <Skeleton variant="text" animation="wave" height={40} />
            </Grid>
          </React.Fragment>
        );
      })}
    </Grid>

    <div style={{ paddingTop: "16px" }}>
      <Skeleton variant="text" animation="wave" height={40} />
      <Skeleton variant="rect" animation="wave" height={250} />
    </div>
  </Box>
);

export const SkeletonSubscriptionComponent = () => (
  <Skeleton variant="rect" height={300} />
);

export const SkeletonPaymentMethodComponent = () => (
  <Grid container spacing={4} style={{ display: "flex", alignItems: "center" }}>
    <Grid item xs={12} sm={6} md={6} style={{ width: "100%" }}>
      <Skeleton variant="rect" width={"100%"} height={260} />
    </Grid>
    <Grid item xs={12} sm={6} md={6}>
      <Skeleton variant="rect" height={40} style={{ marginBottom: "6px" }} />
      <Skeleton variant="rect" height={40} style={{ marginBottom: "6px" }} />
      <Skeleton variant="rect" height={40} />
    </Grid>
  </Grid>
);

export const SkeletonTableRow = ({ columnCount }) => (
  <StyledTableRow>
    {[...new Array(columnCount)].map((v, index) => (
      <StyledTableCell key={index}>
        <Skeleton variant="text" animation="wave" />
      </StyledTableCell>
    ))}
  </StyledTableRow>
);

export const SkeletonTableRowStickyLeftRight = ({ columnCount }) => (
  <StyledTableRow>
    {[...new Array(columnCount)].map((v, index) =>
      index === 0 ? (
        <StickyLeftTableCell key={index}>
          <Box px={2}>
            <Skeleton variant="text" animation="wave" />
          </Box>
        </StickyLeftTableCell>
      ) : index === columnCount - 1 ? (
        <StickyRightTableCell key={index}>
          <Box px={2}>
            <Skeleton variant="text" animation="wave" />
          </Box>
        </StickyRightTableCell>
      ) : (
        <StyledTableCell key={index}>
          <Skeleton variant="text" animation="wave" />
        </StyledTableCell>
      )
    )}
  </StyledTableRow>
);

export const SkeletonWarrantyFormComponent = () => (
  <Box style={{ display: "flex", justifyContent: "center" }} width={"100%"}>
    <Box pt={5} pb={5} width={"80%"}>
      <Skeleton width={"100%"} height={50} />
      <Skeleton width={"100%"} height={50} />
      <Skeleton width={"100%"} height={50} />
      <Skeleton width={"100%"} height={50} />
      <Skeleton width={"100%"} height={50} />
      <Skeleton width={"100%"} height={50} />
    </Box>
  </Box>
);

export const SkeletonOrganisationChartComponent = () => (
  <Box px={2}>
    <Grid container spacing={2}>
      <Grid
        item
        xs={2}
        sm={2}
        md={2}
        style={{ display: "flex", placeContent: "end" }}
      >
        <Skeleton variant="circle" animation="wave" width={60} height={60} />
      </Grid>
      <Grid item xs={10} sm={10} md={10} style={{ width: "100%" }}>
        <Skeleton variant="text" animation="wave" width={300} height={50} />
      </Grid>
    </Grid>

    <div style={{ paddingLeft: "52px", paddingTop: "24px" }}>
      <Grid container spacing={2}>
        {[1, 2, 3].map((i, index) => {
          return (
            <React.Fragment key={index}>
              <Grid
                item
                xs={2}
                sm={2}
                md={2}
                style={{ display: "flex", placeContent: "end" }}
              >
                <Skeleton
                  variant="circle"
                  animation="wave"
                  width={40}
                  height={40}
                />
              </Grid>
              <Grid item xs={10} sm={10} md={10} style={{ width: "100%" }}>
                <Skeleton
                  variant="text"
                  animation="wave"
                  width={300}
                  height={40}
                />
              </Grid>
            </React.Fragment>
          );
        })}
      </Grid>
    </div>
  </Box>
);
