import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { selectLoading } from 'modules/notification'
import LoadingComponent from "components/loading";
import { changeSelectedSerialNumber, getInventoryLooseItemDetail } from "modules/stockflow/redux";
import { STOCKFLOW_INVENTORY_VIEW } from "lib/constants/accessRights";
import { ROOT } from "modules/dashboard";
import LooseItemDetailsComponent from "./looseItemDetails.component";
import { getLang } from "app/feature/constants";

function LooseItemDetailsContainer() {
  const history = useHistory()
  const dispatch = useDispatch()
  
  const query = new URLSearchParams(window.location.search)
  const hasStockflowViewAccessRight = useSelector(state => state.profile.accessRights.includes(STOCKFLOW_INVENTORY_VIEW));
  const lang = useSelector(state => state.constant.languages);
  const token = useSelector(state => state.session.accessToken)
  const looseItem = useSelector(state => state.stockflowInventory.looseItem);
  const isLoading = useSelector(state => selectLoading(state, getInventoryLooseItemDetail.typePrefix))
  const [activeIndex, setActiveIndex] = useState(0);
  const [showLog, setShowLog] = useState(false)

  let code = query.get("code") || ""

  useEffect(() => {
    if (!hasStockflowViewAccessRight) {
      history.push('/admin/dashboard')
    }
  }, [history, hasStockflowViewAccessRight])

  useEffect(() => {
    if (code) {
      dispatch(getInventoryLooseItemDetail(code))
    } else {
      history.push(ROOT)
    }
  }, [history, code, dispatch])

  if (!hasStockflowViewAccessRight) return <LoadingComponent />;

  let tabLabels = []
  if (hasStockflowViewAccessRight) {
    tabLabels.push(getLang(lang, "label.LOOSE_ITEM_DETAILS"))
  }

  const handleChange = (event, index) => {
    setActiveIndex(index);
  };

  const viewActivityLog = (sn) => {
    if (sn) {
      dispatch(changeSelectedSerialNumber(sn))
    }
    setShowLog(!!sn)
  }
  
  return (
    <LooseItemDetailsComponent
      tabLabels={tabLabels}
      activeIndex={activeIndex}
      handleChange={handleChange}
      looseItem={looseItem}
      isLoading={isLoading}
      token={token}
      showLog={showLog}
      viewActivityLog={viewActivityLog}
      lang={lang}
      code={code}
    />
  );
}

export default LooseItemDetailsContainer;
