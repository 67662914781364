export const DASHBOARD = "dashboard";

export const mapInfoStep = {
    productListStep: 0,
    scanListStep: 1,
    scanDetailStep: 2,
}

export const scanLoginType = {
    all: 0,
    registered: 1,
    nonRegistered: 2
}

export const dateType = {
    LAST_14_DAYS: "last_14_days",
    TODAY: "today",
    THIS_MONTH: "this_month",
    LAST_MONTH: "last_month",
    CUSTOM: "custom"
}