import React from "react";
import EditAdtInfoTitleDialogComponent from "./editAdtInfoTitleDialog.component";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { addAlert, selectLoading } from "modules/notification";
import {
  editAdtInfoTitle,
  updateSelectedAdtInfoTitles,
} from "modules/serial-number/redux";
import { unwrapResult } from "@reduxjs/toolkit";

function EditAdtInfoTitleDialogContainer({
  isOpen,
  handleClose,
  selectedTitle,
}) {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.constant.languages);
  const isSubmitting = useSelector((state) =>
    selectLoading(state, editAdtInfoTitle.typePrefix)
  );

  const handleEdit = async (values) => {
    dispatch(
      editAdtInfoTitle({
        id: values.title.id,
        format: values.title.format,
        title: values.title.title,
      })
    )
      .then(unwrapResult)
      .then(() => {
        dispatch(updateSelectedAdtInfoTitles(values.title));
        handleClose(values.title);
      });
  };

  const showAlert = (message) => {
    dispatch(
      addAlert({
        severity: "error",
        message: message,
      })
    );
  };

  return (
    <EditAdtInfoTitleDialogComponent
      selectedTitle={selectedTitle}
      handleEdit={handleEdit}
      isOpen={isOpen}
      handleClose={handleClose}
      lang={lang}
      isSubmitting={isSubmitting}
      addAlert={showAlert}
    />
  );
}

EditAdtInfoTitleDialogContainer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default EditAdtInfoTitleDialogContainer;
