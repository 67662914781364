import { createSlice } from "@reduxjs/toolkit"
import { CONSUMER } from "../../constants"
import { fetchAllScanTrack, fetchConsumerListing, fetchConsumerProfile, fetchConsumerScanHistoryListing, getCampaignProductDropdown, getConsumerCampaignListing, getConsumerGiftRedemptionListing, getConsumerLoyaltyPointHistory, getGiftDropdown } from "../action/consumer.action"

const initialState = {
    consumerList: {
        totalRecords: 0,
        data: []
    },
    consumer: null,
    scanHistory: {
        totalRecords: 0,
        data: []
    },
    traceabilityList: {
        totalRecords: 0,
        totalProductScan: 0,
        totalSuspend: 0,
        data: [],
        productColor: {}
    },
    campaignList: {
        totalRecords: 0,
        totalFiltered: 0,
        data: []
    },
    giftRedemptionList: {
        totalRecords: 0,
        totalFiltered: 0,
        data: []
    },
    loyaltyPointHistoryList: {
        totalRecords: 0,
        totalFiltered: 0,
        data: []
    },
    isLoadingConsumerError: false,
    isLoadingConsumerProfileError: false,
    isLoadingScanError: false,
    isLoadingCampaignListError: false,
    isLoadingRedemptionListError: false,
    isLoadingLoyaltyPointListError: false,
    isLoadingGiftDropdownError: false,
    isLoadingProductDropdownError: false,
    giftDropdown: [],
    campaignProductDropdown: [],
}

const consumerSlice = createSlice({
    name: CONSUMER,
    initialState,
    reducers: {
        resetConsumerData(state) {
            state.consumer = initialState.consumer
            state.scanHistory = initialState.scanHistory
            state.traceabilityList = initialState.traceabilityList
            state.giftRedemptionList = initialState.giftRedemptionList
            state.giftDropdown = initialState.giftDropdown
            state.campaignList = initialState.campaignList
            state.campaignProductDropdown = initialState.campaignProductDropdown
            state.loyaltyPointHistoryList = initialState.loyaltyPointHistoryList
        }
    },
    extraReducers: {
        [fetchConsumerListing.fulfilled]: (state, action) => {
            const { payload } = action
            const tempArray = []

            payload.data.forEach(item => {
                tempArray.push({
                    id: item.uuid,
                    firstName: item.first_name,
                    lastName: item.last_name,
                    phoneCode: item.phone_code,
                    phoneCountry: item.phone_country,
                    phoneNumber: item.phone_number,
                    email: item.email,
                    profilePicture: item.profiles_image_url,
                    gender: item.gender,
                    dateOfBirth: item.dateofbirth,
                    point: item.point,
                    googleId: item.google_id,
                    facebookId: item.facebook_id,
                    lineId: item.line_id,
                    appleId: item.apple_id,
                    joinedDate: item.created_at
                })
            })

            state.consumerList.totalRecords = payload.recordsFiltered
            state.consumerList.data = tempArray
            state.isLoadingConsumerError = false
        },
        [fetchConsumerListing.rejected]: (state) => {
            state.isLoadingConsumerError = true
        },
        [fetchConsumerProfile.pending]: (state) => {
            state.consumer = null
            state.isLoadingConsumerProfileError = false
        },
        [fetchConsumerProfile.fulfilled]: (state, action) => {
            const { payload } = action
            state.consumer = payload
        },
        [fetchConsumerProfile.rejected]: (state) => {
            state.isLoadingConsumerProfileError = true
        },
        [fetchConsumerScanHistoryListing.fulfilled]: (state, action) => {
            const { payload } = action
            state.scanHistory.totalRecords = payload.recordsTotal
            state.scanHistory.data = payload.data
            state.isLoadingScanError = false
        },
        [fetchConsumerScanHistoryListing.rejected]: (state, action) => {
            state.isLoadingScanError = true
        },
        [fetchAllScanTrack.fulfilled]: (state, action) => {
            const { payload } = action
            payload.data.forEach((track) => {
                if (!state.traceabilityList.productColor.hasOwnProperty(track.product_uuid)) {
                    state.traceabilityList.productColor[track.product_uuid] =
                        "#" + Math.floor(Math.random()*16777215).toString(16).padStart(6, '0').toUpperCase() 
                }
            })
            state.traceabilityList.totalRecords = payload.recordsFiltered
            state.traceabilityList.totalProductScan = payload.totalProduct
            state.traceabilityList.totalSuspend = payload.totalSuspend
            state.traceabilityList.data = payload.data
            state.isLoadingScanError = false
        },
        [fetchAllScanTrack.rejected]: (state, action) => {
            state.isLoadingScanError = true
        },
        [getConsumerGiftRedemptionListing.pending]: (state) => {
            state.giftRedemptionList = initialState.giftRedemptionList
            state.isLoadingRedemptionListError = false
        },
        [getConsumerGiftRedemptionListing.fulfilled]: (state, action) => {
            const { payload } = action
            state.giftRedemptionList.totalRecords = payload.recordsTotal
            state.giftRedemptionList.totalFiltered = payload.recordsFiltered
            state.giftRedemptionList.data = payload.data
        },
        [getConsumerGiftRedemptionListing.rejected]: (state, action) => {
            state.isLoadingRedemptionListError = true
        },
        [getGiftDropdown.pending]: (state) => {
            state.giftDropdown = initialState.giftDropdown
            state.isLoadingGiftDropdownError = false
        },
        [getGiftDropdown.fulfilled]: (state, action) => {
            const { payload } = action
            state.giftDropdown = payload
        },
        [getGiftDropdown.rejected]: (state, action) => {
            state.isLoadingGiftDropdownError = true
        },
        [getConsumerLoyaltyPointHistory.pending]: (state) => {
            state.loyaltyPointHistoryList = initialState.loyaltyPointHistoryList
            state.isLoadingLoyaltyPointListError = false
        },
        [getConsumerLoyaltyPointHistory.fulfilled]: (state, action) => {
            const { payload } = action
            state.loyaltyPointHistoryList.totalRecords = payload.recordsTotal
            state.loyaltyPointHistoryList.totalFiltered = payload.recordsFiltered
            state.loyaltyPointHistoryList.data = payload.data
        },
        [getConsumerLoyaltyPointHistory.rejected]: (state, action) => {
            state.isLoadingLoyaltyPointListError = true
        },
        [getConsumerCampaignListing.pending]: (state) => {
            state.campaignList = initialState.campaignList
            state.isLoadingCampaignListError = false
        },
        [getConsumerCampaignListing.fulfilled]: (state, action) => {
            const { payload } = action
            state.campaignList.totalRecords = payload.recordsTotal
            state.campaignList.totalFiltered = payload.recordsFiltered
            state.campaignList.data = payload.data
        },
        [getConsumerCampaignListing.rejected]: (state, action) => {
            state.isLoadingCampaignListError = true
        },
        [getCampaignProductDropdown.pending]: (state) => {
            state.campaignProductDropdown = initialState.campaignProductDropdown
            state.isLoadingProductDropdownError = false
        },
        [getCampaignProductDropdown.fulfilled]: (state, action) => {
            const { payload } = action
            state.campaignProductDropdown = payload.data
        },
        [getCampaignProductDropdown.rejected]: (state, action) => {
            state.isLoadingProductDropdownError = true
        },
    }
})

export const { 
    resetConsumerData,
} = consumerSlice.actions
export const consumerReducer = consumerSlice.reducer
