import React from "react";
import ResetPasswordPage from "./resetPassword.page";
import { connect } from "react-redux";

function ResetPasswordContainer() {
  return <ResetPasswordPage />;
}

const mapStateToProps = state => ({
});

export default connect(mapStateToProps)(ResetPasswordContainer);
