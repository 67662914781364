import React from 'react'
import {
  Box,
  Button, Link,
  makeStyles,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@material-ui/core';
import { StyledTableCell, StyledTableRow, StickyRightTableCell } from 'components/table';
import { SkeletonTableRow } from 'components/skeleton/skeleton.component';
import clsx from 'clsx';
import { ActionButton } from 'components/button';
import { TablePaginationActions } from "components/tablePagination";
import RefreshTable from "components/table/refreshTable";
import { Alert } from '@material-ui/lab';
import { getLang } from 'app/feature/constants';
import StickyLeftTableCell from 'components/table/stickyLeftTableCell';
import ProductCountDetailPopover from "../../../../../components/popover/productCountDetailPopover";
import moment from "moment/moment";
import 'moment/min/locales.min';

const useStyle = makeStyles(theme => ({
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    borderCollapse: "separate",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
    },
    "& th,td": {
      fontSize: ".75rem",
      borderRight: "1px solid rgba(58, 77, 84, 0.1)",
      padding: theme.spacing(1, 2, 1, 2),
    },
    "& th": {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightBold,
    },
    "& th:last-child, td:last-child": {
      width: 37,
      borderRight: "0px",
    },
    "& th th": {
      borderBottom: "none !important",
    },
    "& td td": {
      borderBottom: "none !important",
    },
  },
  emptyContainer: {
    backgroundColor: theme.palette.background.paper,
    textAlign: "center"
  },
  button: {
    display: "flex",
    alignItems: "center",
    padding: 0,
    minWidth: 0,
    marginLeft: theme.spacing(1)
  },
  iconBtn: {
    borderRadius: theme.spacing(0.5),
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shortest,
    }),
    padding: "3px 5px"
  },
  pendingColor: {
    color: "#FFBF00"
  },
  activeColor: {
    color: "#4BCB0F"
  },
  deactiveColor: {
    color: "#9CA6A9"
  },
  attributeText: {
    color: "#b2b2b2",
    fontSize: 12,
    whiteSpace: "break-spaces",
  },
}));

export default function SurveyListingTableComponent({
  hasSurveyAddAccessRight,
  hasSurveyEditAccessRight,
  hasSurveyActivateOrDeactivateAccessRight,
  data,
  isFetching,
  language,
  page,
  search,
  filterStatus,
  dateCreated,
  productSelect,
  rowsPerPage,
  totalRecords,
  handleChangePage,
  handleChangeRowsPerPage,
  handleCreate,
  handleView,
  handleStatusChange,
  handleDeleteSurvey,
  isLoadingSurveyError,
  handleReload,
  handleViewReport,
  copyLink,
  paginationOptions,
  lang
}) {
  const classes = useStyle()
  
  if(language==="ZH")
  {
    moment.locale('zh-cn')
  }else if(language==="TL")
  {
    moment.locale('tl-ph')
  }else
  {
    moment.locale(language)
  }


  return (
    <>
      {data.length > 0 || isFetching ? (
        <TableContainer>
          <Table
            className={classes.table}
            size="small"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <StickyLeftTableCell>
                  <StyledTableCell>{getLang(lang, 'label.NUM')}</StyledTableCell>
                </StickyLeftTableCell>
                <StyledTableCell>{getLang(lang, 'label.SURVEY')}</StyledTableCell>
                <StyledTableCell>{getLang(lang, 'label.PRODUCT')} </StyledTableCell>
                <StyledTableCell>{getLang(lang, 'label.RESPOND')}</StyledTableCell>
                <StyledTableCell>{getLang(lang, 'label.PERIOD')}</StyledTableCell>
                <StyledTableCell>{getLang(lang, 'label.DATE_CREATED')}</StyledTableCell>
                <StyledTableCell>{getLang(lang, 'label.STATUS')}</StyledTableCell>
                <StickyRightTableCell>
                  <StyledTableCell>{getLang(lang, 'label.ACTION')}</StyledTableCell>
                </StickyRightTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isFetching ? (
                [...new Array(5)].map((i, index) => (
                  <SkeletonTableRow key={index} columnCount={8} />
                ))
              ) : (
                data.map((item, index) => {
                  const actionItems = [];

                  actionItems.push(
                    {
                      label: hasSurveyEditAccessRight ? getLang(lang, 'label.VIEW_OR_EDIT_SURVEY') : getLang(lang, 'label.VIEW_SURVEY'),
                      action: () => handleView({ id: item.surveyId })
                    },
                    {
                      label: getLang(lang, 'label.VIEW_REPORT'),
                      action: () => handleViewReport({ id: item.surveyId })
                    },
                    {
                      label: getLang(lang, 'label.COPY_URL'),
                      action: () => copyLink(item.surveyId),
                    },
                  )

                  if (item.status === 'published') {
                    actionItems.push({
                      label: getLang(lang, 'label.UNPUBLISH'),
                      errorText: true,
                      action: () => handleStatusChange(item.status, item.surveyId)
                    })
                  }

                  if (item.status === 'unpublished') {
                    actionItems.push({
                      label: getLang(lang, 'label.PUBLISH'),
                      action: () => handleStatusChange(item.status, item.surveyId)
                    })
                  }

                  if (item.status === 'unpublished' && item.respondentCount === 0) {
                    actionItems.push({
                      label: getLang(lang, 'label.DELETE'),
                      divider: true,
                      errorText: true,
                      action: () => handleDeleteSurvey(item.surveyId)
                    })
                  }

                  return (
                    <StyledTableRow
                      key={index}
                      hover
                      tabIndex={-1}
                    >
                      <StickyLeftTableCell>
                        <StyledTableCell>{index + 1 + rowsPerPage * page}</StyledTableCell>
                      </StickyLeftTableCell>
                      <StyledTableCell>{item.surveyName}</StyledTableCell>
                      <ProductCountDetailPopover
                        products={item.products}
                        itemTranslator={product => ({
                          id: product.id,
                          name: product.name,
                          imageUrl: product.image_url,
                          attributes: product.attributes,
                        })}
                      />
                      <StyledTableCell>
                        {item.respondentCount > 0 ? (
                          <Link
                            color="secondary"
                            href="#"
                            onClick={(event) => {
                              event.preventDefault();
                              handleViewReport({ id: item.surveyId })
                            }}
                          >
                            {item.respondentCount.toLocaleString("en-US")}
                          </Link>
                        ) : (
                          <Box>{item.respondentCount.toLocaleString("en-US")}</Box>
                        )}
                      </StyledTableCell>
                      <StyledTableCell>
                        {moment(item.startDate).format('lll')} - {moment(item.endDate).format('lll')}
                      </StyledTableCell>
                      <StyledTableCell>
                        {moment(item.createdAt).format('lll')}
                      </StyledTableCell>
                      <StyledTableCell>
                        <Typography
                          variant='caption'
                          className={clsx({
                            [classes.activeColor]: item.status === 'Published' || item.status === 'published',
                            [classes.pendingColor]: item.status === 'Unpublished' || item.status === 'unpublished',
                            [classes.deactiveColor]: item.status === 'Expired' || item.status === 'expired',
                          })}
                          style={{ textTransform: "capitalize" }}
                        >
                          {item.status === 'published' ? getLang(lang, 'label.PUBLISHED')
                            : item.status === 'unpublished' ? getLang(lang, 'label.UNPUBLISHED') : getLang(lang, 'label.EXPIRED')}
                        </Typography>
                      </StyledTableCell>
                      <StickyRightTableCell>
                        <StyledTableCell>
                          <div style={{ display: "flex", justifyContent: "center" }}>
                            <ActionButton actionItems={actionItems}
                              popperStyle={{ zIndex: 999 }} />
                          </div>
                        </StyledTableCell>
                      </StickyRightTableCell>
                    </StyledTableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
      ) : ((!isLoadingSurveyError || !isFetching) && !search) ? (
        <Box>
          <Table
            className={classes.table}
            size="small"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <StyledTableCell>{getLang(lang, 'label.NUM')}</StyledTableCell>
                <StyledTableCell>{getLang(lang, 'label.SURVEY')}</StyledTableCell>
                <StyledTableCell>{getLang(lang, 'label.PRODUCT')} </StyledTableCell>
                <StyledTableCell>{getLang(lang, 'label.RESPOND')}</StyledTableCell>
                <StyledTableCell>{getLang(lang, 'label.PERIOD')}</StyledTableCell>
                <StyledTableCell>{getLang(lang, 'label.DATE_CREATED')}</StyledTableCell>
                <StyledTableCell>{getLang(lang, 'label.STATUS')}</StyledTableCell>
                <StickyRightTableCell>
                  <StyledTableCell>{getLang(lang, 'label.ACTION')}</StyledTableCell>
                </StickyRightTableCell>
              </TableRow>
            </TableHead>
          </Table>
          <Box py={9} className={classes.emptyContainer}>
            <Typography variant="body1">
              {(search === "" || filterStatus === "" || dateCreated === "" || productSelect === "") ? getLang(lang, 'message.info.SURVEY_DOES_NOT_EXIST') : getLang(lang, 'message.info.YOU_DONT_HAVE_SURVEY')}
            </Typography>
            {
              hasSurveyAddAccessRight && !search
                ?
                <Box mt={2}>
                  <Button
                    onClick={handleCreate}
                    variant="contained"
                    disableElevation
                    color="primary"
                  >
                    {getLang(lang, 'label.ADD_SURVEY')}
                  </Button>
                </Box>
                : null
            }
          </Box>
        </Box>

      ) : null}

      {!isFetching && isLoadingSurveyError && (
        <RefreshTable handleReload={handleReload} />
      )}
      {!isFetching && data.length === 0 && search && (
        <Alert severity="info">{getLang(lang, 'message.info.NO_SURVEY_EXISTS')}</Alert>
      )}

      {!isFetching && data.length > 0 && (
        <TablePagination
          style={{
            backgroundColor: "#fff",
            boxShadow: "0px -1px 0px rgba(58, 77, 84, 0.15)",
          }}
          ActionsComponent={TablePaginationActions}
          rowsPerPageOptions={paginationOptions}
          component="div"
          count={totalRecords}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage={getLang(lang, 'label.ROWS_PER_PAGE')}
          labelDisplayedRows={({ from, to, count }) => `${from.toLocaleString("en-US")}-${to.toLocaleString("en-US")} ${getLang(lang, 'label.OF')} ${count.toLocaleString("en-US")}`}
        />
      )}
    </>
  )
}
