import React, { useState, useRef, useEffect } from "react";
import DealerSearchComponent from "./dealerSearch.component";
import DealerPanelComponent from "./dealerPanel.component";
import { selectLoading } from "modules/notification";
import { useDispatch, useSelector } from "react-redux";
import { getDealerDropdown } from "modules/stockflow/redux";
import cloneDeep from "lodash/cloneDeep";
import { useLocation } from "react-router-dom";
import qs from "query-string";

function DealerSearchContainer({ 
  placeholder, 
  handleChange, 
  value, 
  disabled, 
  styles, 
  textStyles,
  handleChipDelete,
  isEmpty,
  branchDropdown
}) {
  const dispatch = useDispatch();
  const lang = useSelector(state => state.constant.languages);
  const dealerDropdown = useSelector(state => state.stockflowDealer.dropdowns.dealerListDropdownData);
  const isDealerDropdownFetching = useSelector((state) => selectLoading(state, getDealerDropdown.typePrefix));

  const [open, setOpen] = useState(false);
  const [isOpenList] = useState(false);
  const anchorRef = useRef(null);
  const prevOpen = useRef(open);
  const prevOpenList = useRef(isOpenList);
  const [dealerList, setDealerList] = useState([]);
  const [targetDealer, setTargetDealer] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const location = useLocation();
  const qsParams = qs.parse(location.search);
  const searchDealer = qsParams.dealer || "";
  const drawerOpen = useSelector((state)=>state.app.drawerOpen);
  const [extraChip, setExtraChip] = useState({
    count : 0,
    offset : 0,
    allOverflow : false
  });

  const adjustField = (ref) => {
    let children = ref.current?.children[0]?.children;
    let p = ref.current?.children[0];
    if (!children) return;

    if (children.length > 0) {
      // Need to exclude placeholder
      if (children[0].role == null) return;

      children = [...children];
      
      // max width is 90%. Use 0.9 to calc field width
      let panelWidth = ref.current?.scrollWidth * 0.9;
      let childWidth = 0;

      // Check for no overflow child count
      let inRangeCount = children.filter((child, index)=>{
  
        let panelContentWidth = p.getBoundingClientRect().width
        // get exact width + padding with decimal
        childWidth += (parseInt(window.getComputedStyle(child).marginRight) + child.getBoundingClientRect().width);
        
        // Check if overflow
        if(panelWidth - panelContentWidth < 1) {
          // need to add 0.5 as the ellipsis padding (0.5 = ellipsis padding)
          // if the overflow happen before last child, need inlude another 10 for the ellipsis (10 = ellipsis width)
          return (index !== (children.length - 1)) ? (childWidth + 10.5) < panelContentWidth : childWidth + 0.5 < panelContentWidth;
        }

        return (panelWidth > panelContentWidth);
      }).length;

      if (inRangeCount > 0) {

        let lastRangeChild = children[inRangeCount - 1];

        let outRangeChild = value.slice(inRangeCount);

        // Check for overflow child count
        let extraCount = value.length - inRangeCount;
        setExtraChip({
          count : extraCount,
          offset : lastRangeChild.offsetLeft + lastRangeChild.offsetWidth,
          allOverflow : false,
          overflowChild : outRangeChild
        });
      } else {
        setExtraChip({
          count : children.length,
          offset : 0,
          allOverflow : true,
          overflowChild : value
        });
      }
    } else {
      setExtraChip({
          count : 0,
          offset : 0,
          allOverflow : false,
          overflowChild : []
        });
    }
  }

  useEffect(() => {
    dispatch(getDealerDropdown());
  }, [dispatch]);


  useEffect(() => {
    if (dealerDropdown.length > 0 && targetDealer.length === 0) {
      if (searchDealer) {
          dealerDropdown.forEach((dealer) => {
          if (dealer.uuid === searchDealer) {
            let temp = [{
              name: dealer.name,
              node_id: dealer.uuid,
              picture: dealer.picture
            }];
            setTargetDealer(temp);
            handleChange(temp);
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealerDropdown]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = () => {
    setOpen(true);
    setDealerList(filterDealerWithBranch);
  };

  function filterDealerWithBranch() {
    if (branchDropdown.length === 0) {
      return dealerDropdown;
    } else {
      let dealers = [];
      branchDropdown.forEach(branch => {
        let branchUuid = branch.node_id;
        dealerDropdown.forEach(dealer => {
          if (dealer.nodeUuid === branchUuid) {
            dealers.push(dealer);
          }
        });
      });
      return dealers
    }
  }

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    if (prevOpenList.current === true && isOpenList === false) {
      anchorRef.current.focus();
    }

    prevOpenList.current = isOpenList;
  }, [isOpenList]);

  const handleSelectDealer = (dealer) => {
    let temp = cloneDeep(value);

    if (temp.filter(t => t.uuid === dealer.uuid).length > 0) {
      temp = temp.filter(t => t.uuid !== dealer.uuid)
    } else {
      temp.push({
      name: dealer.name,
      uuid: dealer.uuid,
      picture: dealer.picture,
      tier: dealer.tier
    });
    }
    
    handleChange(temp);
  };

  const handleSelectAll = () => {
    let temp = cloneDeep(value)
    if (selectAll) {
      temp = [];
    } else {
      temp = dealerList;
    }
    setSelectAll(!selectAll)
    handleChange(temp);
  };

  const handleDealerSearch = (targetDealer) => {
    
    if (targetDealer.trim().length === 0) {
      setDealerList(filterDealerWithBranch());
      return;
    }
    let dealers = [];
    filterDealerWithBranch().forEach(dealer => {
      let dealerName = dealer.name ?? "";
      if (dealerName.toLowerCase().includes(targetDealer.toLowerCase())) {
        dealers.push(dealer);
      }
    });

    setDealerList(dealers);
  };

  return (
    <>
      <DealerSearchComponent
        anchorRef={anchorRef}
        handleClick={handleClick}
        value={value}
        styles={styles}
        placeholder={placeholder}
        disabled={disabled}
        targetDealer={targetDealer}
        dealerDropdown={dealerDropdown}
        textStyles={textStyles}
        lang={lang}
        handleChange={handleChange}
        handleChipDelete={handleChipDelete}
        drawerOpen={drawerOpen}
        extraChip={extraChip}
        adjustField={adjustField}
        isEmpty={isEmpty}
      />
      <DealerPanelComponent
        value={value}
        anchorRef={anchorRef}
        open={open}
        selectAll={selectAll}
        isOpenList={isOpenList}
        handleSelectAll={handleSelectAll}
        handleDealerSearch={handleDealerSearch}
        handleClose={handleClose}
        isFetching={isDealerDropdownFetching}
        dealerDropdown={dealerList}
        handleSelectDealer={handleSelectDealer}
        lang={lang}
      />
    </>
  );
}

export default DealerSearchContainer;
