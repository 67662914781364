import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { sendVerificationEmail } from "modules/trackco/redux";
import SignUpVerificationPage from "./signup-verification.page";

export default function SignUpVerificationContainer() {
  const dispatch = useDispatch();
  const email = useSelector(state => state.trackco.profile.email)
  const localEmail = localStorage.getItem('email');
  const [counter, setCounter] = useState(60);
  const lang = useSelector(state => state.constant.languages);

  useEffect(() => {
    if(counter > 0) {
      const timer = setInterval(() => setCounter(counter - 1), 1000);
      return () => clearInterval(timer)
    }
  }, [counter])

  const handleResendEmailVerification = () => {
    dispatch(sendVerificationEmail({ email: email === "" ? localEmail : email }))
      .then(() => {
        setCounter(60)
      })
  }

  return (
    <SignUpVerificationPage 
      counter={counter}
      enableResent={counter === 0}
      email={email === "" ? localEmail : email}
      handleResendEmailVerification={handleResendEmailVerification}
      lang={lang}
    />
  )
}