import React from "react";
import {
  Box,
  Paper,
  Typography,
  makeStyles,
  Chip,
  Divider,
  Link,
} from "@material-ui/core";
import DefaultImg from "assets/img/img-default.png";
import DefaultProfile from "assets/img/defaultPhoto.png";
import { Skeleton } from "@material-ui/lab";
import clsx from "clsx";
import moment from "moment";
import { ROOT } from "modules/stockflow/config/stockflow.route";
import { useHistory } from "react-router-dom";
import { TIER_ICON_PROPS } from "modules/stockflow/constants";
import { getLang } from "app/feature/constants";
import "moment/min/locales.min";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2, 2),
  },
  productPhoto: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #0000001a",
    width: "77px",
    height: "77px",
    borderRadius: "4px",
    "& img": {
      height: "100%",
      width: "100%",
    },
  },
  greyText: {
    color: "#98A2B3",
  },
  activeChipStatus: {
    backgroundColor: "#ECFDF3",
    width: "fit-content",
    textTransform: "capitalize",
    color: "#027A48",
    fontWeight: "bold",
  },
  suspendChipStatus: {
    backgroundColor: "#fdecec",
    width: "fit-content",
    textTransform: "capitalize",
    color: "red",
    fontWeight: "bold",
  },
  missingChipStatus: {
    backgroundColor: "#fff4e0",
    width: "fit-content",
    textTransform: "capitalize",
    color: "#FDB022",
    fontWeight: "bold",
  },
  disabledChipStatus: {
    backgroundColor: "#F2F4F7",
    width: "fit-content",
    textTransform: "capitalize",
    color: "#475467",
    fontWeight: "bold",
  },
  field: {
    display: "grid",
    gridTemplateColumns: "3fr 7fr",
    columnGap: theme.spacing(2),
  },
  activeText: {
    color: "#6AAF68",
    cursor: "pointer",
  },
  highlightBox: {
    padding: "2px 10px",
    backgroundColor: "#98A2B3",
    color: "#FFFFFF",
    borderRadius: "11px",
    marginRight: "8px",
  },
  profile: {
    height: 26,
    width: 26,
    minWidth: 26,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& img": {
      height: "100%",
      minHeight: "100%",
      minWidth: "100%",
      objectFit: "cover",
      borderRadius: "50%",
      border: "1px solid #D0D5DD",
    },
  },
  tierIcon: {
    borderRadius: "3px",
    paddingLeft: "0.15em",
    paddingRight: "0.15em",
    display: "flex",
    alignItems: "center",
    position: "absolute",
    top: "-3px",
    left: "-8px",
  },
  tierLabel: {
    color: "#FFFFFF",
    fontSize: "0.6rem",
  },
  attributeText: {
    color: "#b2b2b2",
    fontSize: 12,
    whiteSpace: "break-spaces",
  },
}));

export default function PackageInfoCardComponent({
  hasStockflowDealerViewAccessRight,
  packageDetail,
  isFetching,
  language,
  lang,
}) {
  const classes = useStyles();
  const history = useHistory();

  if (language === "ZH") {
    moment.locale("zh-cn");
  } else if (language === "TL") {
    moment.locale("tl-ph");
  } else {
    moment.locale(language);
  }

  return (
    <Paper elevation={0} className={classes.container}>
      {!isFetching && packageDetail ? (
        <Box>
          <Box display="flex" mb={2} alignItems="center">
            <Box
              className={classes.productPhoto}
              alignItems="center"
              justifyContent="center"
              mr={2}
            >
              {packageDetail?.picture === "" ? (
                <img src={DefaultImg} alt="emptyProduct" />
              ) : (
                <img src={packageDetail.picture} alt={packageDetail.name} />
              )}
            </Box>
            <Box>
              <Box display="flex" alignItems="center" mb={1}>
                <Box className={classes.highlightBox}>
                  <Typography variant="h5">
                    {packageDetail.sequence_no}
                  </Typography>
                </Box>
                <Typography variant="h5" style={{ fontWeight: "700" }}>
                  {packageDetail.serial_number}
                </Typography>
                <Box ml={1}>
                  <Chip
                    label={`• ${
                      packageDetail.status === "suspend"
                        ? getLang(lang, "label.SUSPEND")
                        : packageDetail.status === "active"
                        ? getLang(lang, "label.ACTIVE")
                        : packageDetail.status === "disabled"
                        ? getLang(lang, "label.DISABLED")
                        : packageDetail.status === "missing"
                        ? getLang(lang, "label.MISSING")
                        : packageDetail.status === "faulty"
                        ? getLang(lang, "label.FAULTY")
                        : packageDetail.status === "checked out"
                        ? getLang(lang, "label.CHECKED_OUT")
                        : packageDetail.status === "replaced"
                        ? getLang(lang, "label.REPLACED")
                        : packageDetail.status === "pending receive" ||
                          packageDetail.status === "pending approval" ||
                          packageDetail.status === "in transit"
                        ? getLang(lang, "label.IN_TRANSIT")
                        : packageDetail.status
                    }`}
                    size="small"
                    className={clsx({
                      [classes.activeChipStatus]:
                        packageDetail.status === "active",
                      [classes.suspendChipStatus]:
                        packageDetail.status === "suspend",
                      [classes.missingChipStatus]:
                        packageDetail.status === "missing" ||
                        packageDetail.status === "faulty",
                      [classes.disabledChipStatus]:
                        packageDetail.status === "disabled" ||
                        packageDetail.status === "checked out" ||
                        packageDetail.status === "replaced",
                    })}
                  />
                </Box>
              </Box>
              <Typography variant="body1">{packageDetail.name}</Typography>
              {!!packageDetail.attributes &&
                !!packageDetail.attributes.length && (
                  <Typography className={classes.attributeText} variant="body2">
                    {packageDetail.attributes.join(", ")}
                  </Typography>
                )}
            </Box>
          </Box>
          <Divider />
          <Box mt={2}>
            <Box className={classes.field}>
              <Typography variant="body1">
                {getLang(lang, "label.BRANCH_ASSIGNED")}
              </Typography>
              <Typography variant="body1" color="textSecondary">
                <span>: </span>
                <span>{packageDetail.node?.name || "-"}</span>
              </Typography>
            </Box>
            <Box className={classes.field} mt={2}>
              <Typography variant="body1">
                {getLang(lang, "label.LOCATED_AT")}
              </Typography>
              <Box display="flex">
                <Typography color="textSecondary" style={{ marginRight: 5 }}>
                  :
                </Typography>
                <span>
                  {!packageDetail.located_at ? (
                    <span>-</span>
                  ) : (
                    <Box display="flex" alignItems="center">
                      <Box
                        className={classes.profile}
                        mr={1}
                        ml={0.5}
                        position="relative"
                      >
                        {packageDetail.located_at.picture === "" ? (
                          <img
                            src={
                              packageDetail.located_at.type === "branch"
                                ? DefaultImg
                                : DefaultProfile
                            }
                            alt="default profile"
                          />
                        ) : (
                          <img
                            src={packageDetail.located_at.picture}
                            alt={packageDetail.name}
                          />
                        )}
                        {!!packageDetail.located_at.tier && (
                          <Box
                            className={classes.tierIcon}
                            style={{
                              backgroundColor: TIER_ICON_PROPS.find(
                                ({ tier }) =>
                                  tier === packageDetail.located_at.tier
                              ).color,
                            }}
                          >
                            <Typography className={classes.tierLabel}>
                              T{packageDetail.located_at.tier}
                            </Typography>
                          </Box>
                        )}
                      </Box>
                      {packageDetail.located_at.type === "branch" ||
                      !hasStockflowDealerViewAccessRight ? (
                        <Typography variant="body1">
                          {packageDetail.located_at.name}
                        </Typography>
                      ) : (
                        <Link
                          href={
                            packageDetail.located_at.type === "dealer"
                              ? `${ROOT}/my-dealer/${packageDetail.located_at.uuid}`
                              : "#"
                          }
                          color="secondary"
                          onClick={(e) => {
                            if (packageDetail.located_at.type === "dealer") {
                              history.push(
                                `${ROOT}/my-dealer/${packageDetail.located_at.uuid}`
                              );
                            }
                            e.preventDefault();
                            return false;
                          }}
                        >
                          <Typography variant="body1">
                            {packageDetail.located_at.name}
                          </Typography>
                        </Link>
                      )}
                    </Box>
                  )}
                </span>
              </Box>
            </Box>
            <Box className={classes.field} mt={2}>
              <Typography variant="body1">
                {getLang(lang, "label.BOX_STATUS")}
              </Typography>
              <Typography variant="body1" color="textSecondary">
                <span>: </span>
                <span
                  className={clsx({
                    [classes.activeText]: !!packageDetail.box,
                  })}
                  onClick={() => {
                    if (packageDetail.box) {
                      history.push(
                        `${ROOT}/inventory/box/${packageDetail.box.id}`
                      );
                    }
                  }}
                >
                  {packageDetail.box
                    ? packageDetail.box.name
                    : getLang(lang, "label.NONE")}
                </span>
              </Typography>
            </Box>
            <Box className={classes.field} mt={2}>
              <Typography variant="body1">
                {getLang(lang, "label.CREATED_DATE")}
              </Typography>
              <Typography variant="body1" color="textSecondary">
                <span>: </span>
                <span>
                  {moment.utc(packageDetail.created_at).local().format("lll")}
                </span>
              </Typography>
            </Box>
            <Box className={classes.field} mt={2}>
              <Typography variant="body1">
                {getLang(lang, "label.LAST_UPDATE")}
              </Typography>
              <Typography variant="body1" color="textSecondary">
                <span>: </span>
                <span>
                  {moment.utc(packageDetail.updated_at).local().format("lll")}
                </span>
              </Typography>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box>
          <Box display="flex" flex={1} mb={2}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              mr={1}
            >
              <Skeleton variant="rect" width={77} height={77} />
            </Box>
            <Box>
              <Skeleton variant="text" height={40} width={100} />
              <Skeleton variant="text" height={40} width={100} />
            </Box>
          </Box>
          <Divider />
          <Skeleton variant="text" height={40} mt={2} />
          <Skeleton variant="text" height={40} mt={2} />
          <Skeleton variant="text" height={40} mt={2} />
          <Skeleton variant="text" height={40} mt={2} />
        </Box>
      )}
    </Paper>
  );
}
