import { Avatar, Box, TableCell, TableRow, Typography } from '@material-ui/core';
import { UnknownUserIcon } from 'modules/reporting/assets/svg';
import { flexGapStyle, fontTextStyle, iconStyle, tableStyle } from 'modules/reporting/styles';
import { drilldownPageSelection } from 'modules/reporting/utils/helper';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { ContentCard } from '../../layout';
import { DownloadableTable } from '../../table';

export default function WarrantyListPanel({
    status,
    data,
    pagination,
    sort,
    columns,
    currentFilter,
    pageType,
    translate
}) {

    const fontClasses = fontTextStyle();
    const tableClasses = tableStyle();
    const iconClasses = iconStyle();
    const flexGapClasses = flexGapStyle();

    const renderData = (data) => {

        let rows = [];
        if(Object.keys(data).length === 0 || !data.isSuccess){
            return rows;
        }

        let no_iteration = pagination.rowsPerPage * pagination.page + 1;
        let imageProps = JSON.stringify(data);
        imageProps = JSON.parse(imageProps);
        imageProps.rows.forEach((item, index) => {
            let row = (
                <TableRow>
                    <TableCell className={tableClasses.table__cell} style={{minWidth: "20px", verticalAlign: "top"}}>
                        <Typography className={fontClasses.text__table_cell}>{no_iteration++}</Typography>
                    </TableCell>
                    <TableCell  className={tableClasses.table__cell} style={{
                        verticalAlign: "top",
                        minWidth: "120px"
                    }}>
                        <Link 
                            target="_blank" rel="noopener noreferrer"
                            className={fontClasses.text__hyperlink}
                            to={() => {
                                let filterProperties = {};
                                if(currentFilter.dateRange){
                                    filterProperties.start = moment(currentFilter.dateRange[0]).format('YYYY-MM-DD');
                                    filterProperties.end = moment(currentFilter.dateRange[1]).format('YYYY-MM-DD');
                                }

                                if(item.campaign_type)
                                    filterProperties.campaignType = item.campaign_type;

                                if(item.uuid)
                                    filterProperties.campaignUuid = item.uuid;

                                let url = drilldownPageSelection(filterProperties, pageType);
                                return url;
                            }}
                        >
                            {item.name}
                        </Link>
                    </TableCell>
                    <TableCell className={tableClasses.table__cell} style={{
                        minWidth: "395px"
                    }}>
                        <Box
                            display="flex"
                            flexDirection="column"
                            style={{
                                "gap": "12px"
                            }}
                        >
                            {
                                item.branches.length !== 0 ? 
                                item.branches.map((branch, index) => {
                                    return (
                                        <Box display="flex" flexDirection="column" style={{gap: "12px"}}>
                                            <Typography className={fontClasses.text__table_cell}>
                                                {branch.name}
                                            </Typography>
                                            <Box
                                                display="flex"
                                                flexDirection="column"
                                                className={flexGapClasses.gap3}
                                                style={{
                                                    "gap": "8px"
                                                }}
                                            >
                                                {branch.products.map((product, index) => {
                                                    return (
                                                        <Box display="flex" justifyContent="space-between" style={{gap: "12px"}}>
                                                            <Box className={tableClasses.cell__product_no_padding}>
                                                                {
                                                                    product.image ?
                                                                    <Avatar variant="square" alt={product.name} src={product.image} className={iconClasses.svg__icon}/>
                                                                    :
                                                                    <Avatar variant="square" alt={product.name} src={UnknownUserIcon} className={iconClasses.svg__icon}/>
                                                                }
                                                                {
                                                                    product.name ? (
                                                                        <Typography 
                                                                            target="_blank" rel="noopener noreferrer"
                                                                            className={fontClasses.text__table_cell}
                                                                        >
                                                                            {product.name ?? "Unknown"}
                                                                        </Typography>
                                                                    ) : (
                                                                        <Typography className={fontClasses.text__unknown}>
                                                                            {translate("label.UNKNOWN")}
                                                                        </Typography>
                                                                    )
                                                                }
                                                            </Box>
                                                            <Typography className={fontClasses.text__table_cell} style={{margin:"auto"}}>
                                                                {product.participant}
                                                            </Typography>
                                                        </Box>
                                                    )
                                                })}
                                            </Box>
                                        </Box>
                                    )
                                }) : '-'
                            }
                        </Box>
                    </TableCell>
                    <TableCell className={tableClasses.table__cell} align="right" style={{
                        minWidth: "170px",
                        verticalAlign: "top",
                    }}>
                        <Typography className={fontClasses.text__table_cell}>
                            {item.total_participant}
                        </Typography>
                    </TableCell>
                    <TableCell className={tableClasses.table__cell} style={{
                        minWidth: "170px",
                        verticalAlign: "top",
                    }}>
                        <Typography className={fontClasses.text__table_cell}>
                            {item.date_created}
                        </Typography>
                    </TableCell>
                    <TableCell className={tableClasses.table__cell} style={{
                        minWidth: "180px",
                        verticalAlign: "top",
                    }}>
                        <Typography className={fontClasses.text__table_cell}>
                            {item.warranty_period}
                        </Typography>
                    </TableCell>
                    <TableCell className={tableClasses.table__cell}
                    style={{
                        minWidth: "100px",
                        verticalAlign: "top",
                    }}>
                        <Typography className={fontClasses.text__table_cell}>
                            {renderStatus(item.status)}
                        </Typography>
                    </TableCell>
                </TableRow>
            )

            
            item.render = row;
        })
        
        return imageProps;
    }

    const renderStatus = (status) => {
        if(status === 1){
            return (
                <Typography className={[fontClasses.text__success, fontClasses.text__table_cell]}>
                    {translate("label.ACTIVE")}
                </Typography>
            )
        }else if(status === 0){
            return (
                <Typography className={[fontClasses.text_danger, fontClasses.text__table_cell]}>
                    {translate("label.INACTIVE")}
                </Typography>
            )
        }else if(status === 2){
            return (
                <Typography className={[fontClasses.text_secondary, fontClasses.text__table_cell]}>
                    {translate("label.UNKNOWN")}
                </Typography>
            )
        }
    }
    
    return (
        <ContentCard>
            {/* downloadable table listing suspended --- START */}
            <DownloadableTable
                title={translate("label.WARRANTY_LIST")}
                sort={sort}
                pagination={pagination}
                columns={columns}
                status={status.isFetchingCampaignWarrantyList}
                data={renderData(data.campaignWarrantyListData)}
                translate={translate}
            />
            {/* downloadable table listing suspended --- END */}
        </ContentCard>
    )
}