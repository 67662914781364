import React from 'react'
import {
  Backdrop,
  Dialog,
  makeStyles
} from '@material-ui/core'
import { ViewSurveyForm } from '../../form'

const useStyle = makeStyles(() => ({
  dialog: {
    maxWidth: "none"
  }
}))

export default function ViewSurveyDialogComponent({
  hasSurveyEditAccessRight,
  isOpen,
  handleClose,
  handleEdit,
  survey,
  isLoading
}) {
  const classes = useStyle()

  return (
    <Dialog
      maxWidth="sm"
      open={isOpen}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      classes={{
        paperWidthSm: classes.dialog
      }}
    >
      <ViewSurveyForm
        hasSurveyEditAccessRight={hasSurveyEditAccessRight}
        survey={survey}
        isLoading={isLoading}
        handleEdit={handleEdit}
        handleClose={handleClose}
      />
    </Dialog>
  )
}
