import axios from "axios"

export default async function getConsumerLoyaltyPointHistory({
    id,
    length,
    start,
    column,
    order,
}) {
    return new Promise((resolve, reject) => {
        axios
        .get(`/campaign/api/admin/point-log/${id}`, {
            params: {
                limit: length,
                skip: start,
                sortBy: column,
                orderBy: order,
            }
        })
        .then((response) => resolve(response.data))
        .catch((error) => {
            return reject(error.response.data)
        })
    })
}
