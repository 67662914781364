import { Avatar, Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Typography } from "@material-ui/core";
import { TablePaginationActions } from "components/tablePagination";
import { UnknownUserIcon } from "modules/reporting/assets/svg";
import { fontTextStyle, iconStyle, tableStyle } from "modules/reporting/styles";
import { drilldownPageSelection, responseHandler } from "modules/reporting/utils/helper";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { TableCellLoading } from "../../skeleton/loading";
import { TableCellNoData } from "../../skeleton/no-data";
import { TableCellNoResult } from "../../skeleton/no-result";
import { generatePaginationOptions } from "lib/helper";

export default function ProductTableComponent(props){
    const { columns, sort, status, data, pagination, currentFilter,pageType, translate } = props; 

    const tableClasses = tableStyle();
    const fontClasses = fontTextStyle();
    const iconClasses = iconStyle();
    const [paginationOptions, setPaginationOptions] = useState([]);

    useEffect(() => {
        setPaginationOptions(generatePaginationOptions(data ? data.total_record : 0, [25,50,100]))
    }, [data]);

    const createSortHandler = (property) => (event) => {
        sort.handleRequestSort(event, property);
    };

    const tableDisplay = (data) => {

        if(Object.keys(data).length === 0 || !data.isSuccess){
            return null
        }

        let no_iteration = pagination.rowsPerPage * pagination.page + 1; 
        return (
            data.rows.map((row, index) => {
                return (
                    <TableRow key={index}>
                        <TableCell
                            className={tableClasses.table__cell}
                            align="left"
                            style={{
                                width: "20px"
                            }}
                        >
                            <Typography className={fontClasses.text__table_cell}>
                                {no_iteration++}
                            </Typography>
                        </TableCell>
                        {/* image & name */}
                        <TableCell
                            className={tableClasses.table__cell}
                            align="left"
                            style={{
                                minWidth: "300px"
                            }}
                        >
                            <Box className={tableClasses.cell__product}>
                                {
                                    row.image ?
                                    <Avatar variant="square" alt={row.name} src={row.image} className={iconClasses.svg__icon}/>
                                    :
                                    <Avatar variant="square" alt={row.name} src={UnknownUserIcon} className={iconClasses.svg__icon}/>
                                }
                                
                                <Link
                                    target="_blank" rel="noopener noreferrer" 
                                    className={fontClasses.text__hyperlink}
                                    to={() => {

                                        let filterProperties = {};

                                        if(currentFilter.countryCode)
                                            filterProperties.countryCode = currentFilter.countryCode;
                                        
                                        if(currentFilter.cityUuid)
                                            filterProperties.city = currentFilter.cityUuid;
                                        
                                        if(currentFilter.branchUuid)
                                            filterProperties.branchUuid = currentFilter.branchUuid;

                                        if(currentFilter.period)
                                            filterProperties.period = currentFilter.period;

                                        if(currentFilter.dateRange){
                                            filterProperties.start = moment(currentFilter.dateRange[0]).format('YYYY-MM-DD');
                                            filterProperties.end = moment(currentFilter.dateRange[1]).format('YYYY-MM-DD');
                                        }

                                        if(row.uuid)
                                            filterProperties.productUuid = row.uuid;

                                        let url = drilldownPageSelection(filterProperties, pageType, true);
                                        return url;
                                    }}
                                >
                                    <span>{row.name}</span>
                                </Link>
                            </Box>
                        </TableCell>
                        {/* total count */}
                        <TableCell
                            className={tableClasses.table__cell}
                            align="right"
                            style={{
                                minWidth: "130px"
                            }}
                        >
                            {/* <Box className={tableClasses.table__cell_center}>
                                <Typography className={fontClasses.text__table_cell}>
                                    {row.total_count}
                                </Typography>
                                <Typography className={[fontClasses.text__table_cell, fontClasses.text__color_muted]}>
                                    {row.total_percentage ? row.total_percentage : '-'} 
                                </Typography>
                            </Box> */}
                            <Typography className={fontClasses.text__table_cell}>
                                {row.total_count}
                            </Typography>
                        </TableCell>
                        {/* scan weightage */}
                        <TableCell
                            className={tableClasses.table__cell}
                            align="right"
                            style={{
                                minWidth: "130px",
                                width: "130px"
                            }}
                        >
                            <Typography className={fontClasses.text__table_cell}>
                                {row.total_percentage}
                            </Typography>
                        </TableCell>
                        {/* total suspended */}
                        <TableCell
                            className={tableClasses.table__cell}
                            align="right"
                            style={{
                                minWidth: "110px"
                            }}
                        >
                            <Typography className={fontClasses.text__table_cell}>
                                {row.total_suspended}
                            </Typography>
                        </TableCell>
                        {/* total warranty */}
                        <TableCell
                            className={tableClasses.table__cell}
                            align="right"
                            style={{
                                minWidth: "100px"
                            }}
                        >
                            <Typography className={fontClasses.text__table_cell}>
                                {row.total_warranty}
                            </Typography>
                        </TableCell>
                        {/* total survey */}
                        <TableCell
                            className={tableClasses.table__cell}
                            align="right"
                            style={{
                                minWidth: "90px"
                            }}
                        >
                            <Typography className={fontClasses.text__table_cell}>
                                {row.total_survey}
                            </Typography>
                        </TableCell>
                        {/* total lucky draw */}
                        <TableCell
                            className={tableClasses.table__cell}
                            align="right"
                            style={{
                                minWidth: "130px"
                            }}
                        >
                            <Typography className={fontClasses.text__table_cell}>
                                {row.total_luckydraw}
                            </Typography>
                        </TableCell>
                        {/* total instant lucky draw */}
                        <TableCell
                            className={tableClasses.table__cell}
                            align="right"
                            style={{
                                minWidth: "190px"
                            }}
                        >
                            <Typography className={fontClasses.text__table_cell}>
                                {row.total_instantluckydraw}
                            </Typography>
                        </TableCell>
                        {/* total loyalty points */}
                        <TableCell
                            className={tableClasses.table__cell}
                            align="right"
                            style={{
                                minWidth: "150px"
                            }}
                        >
                            <Typography className={fontClasses.text__table_cell}>
                                {row.total_loyalty}
                            </Typography>
                        </TableCell>
                    </TableRow>
                )
            })
        )
    }

    return (
        <TableContainer component={Paper}>
            <Box style={{
                overflowX: 'auto',
                width: '100%'
            }}>
                <Table>
                    {/* TABLE HEAD --- START */}
                    <TableHead>
                        <TableRow style={{backgroundColor: "#fff !important"}}>
                            {columns.map((column, index) => {
                                return (
                                    <TableCell className={[tableClasses.table__cell, fontClasses.text__header_table_cell]} key={column.title} size={column.size}>
                                        {
                                            column.field ? 
                                            (
                                                <TableSortLabel
                                                    active={sort.valueToOrderBy === column.field ? true : false}
                                                    direction={sort.valueToOrderBy === column.field ? sort.orderDirection : 'desc'}
                                                    onClick={!status && column.field ? createSortHandler(column.field) : null}
                                                >
                                                    {column.render(column)}
                                                </TableSortLabel>
                                            ) : 
                                            column.render(column)
                                        }
                                    </TableCell>
                                )
                            })}
                        </TableRow>
                    </TableHead>
                    {/* TABLE HEAD --- END */}

                    {/* TABLE BODY --- START */}
                    <TableBody>
                        {
                            responseHandler(
                                data, 
                                status, 
                                tableDisplay(data),
                                (
                                    <TableRow>
                                        <TableCellLoading />
                                    </TableRow>
                                ),
                                (   
                                    <TableRow>
                                        <TableCellNoData 
                                            title={translate("label.QR_CODE_DATA_NOT_FOUND")} 
                                            value={translate("paragraph.EMPTY_QR_GENERATE_NOW")}
                                            buttonText={translate("paragraph.GENERATE_QR_CODE").toUpperCase()}
                                            link="/admin/serial-number/generate"
                                        />
                                    </TableRow>
                                ),
                                (
                                    <TableRow>
                                        <TableCellNoResult 
                                            title={translate("label.QR_CODE_DATA_NOT_FOUND")} 
                                            value={translate("paragraph.NO_DATA_ON_SELECTED_FILTER")}
                                        />
                                    </TableRow>
                                ),
                                (
                                    <TableRow>
                                        <TableCellNoResult 
                                            title={translate("label.LOADING_FAILED")}
                                            value={translate("paragraph.LOADING_FAILED_DESCRIPTION")}
                                        />
                                    </TableRow>
                                )

                            )
                        }
                    </TableBody>
                    {/* TABLE BODY --- END */}
                </Table>
            </Box>
            <TablePagination
                ActionsComponent={TablePaginationActions}
                component="div"

                rowsPerPageOptions={paginationOptions}
                rowsPerPage={pagination.rowsPerPage}
                page={pagination.page}
                count={data.total_record ?? 0}
                onChangePage={pagination.handleChangePage}
                onChangeRowsPerPage={pagination.handleChangeRowPerPage}
            />
        </TableContainer>
    )
}