import React from "react"
import {
  Backdrop,
  Dialog,
  makeStyles,
  Typography,
  Paper,
  Box,
  IconButton,
  Divider,
  Button,
  Checkbox
} from "@material-ui/core";
import { Close as CloseIcon } from '@material-ui/icons'
import { Formik } from 'formik';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CurrencySelect from "../../select/currencySelect";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles((theme) => ({
  dialog: {
    maxWidth: "none"
  },
  root: {
    boxShadow: theme.shadows[5],
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "space-between",
    width: 500,
    borderRadius: "4px",
    fontSize: 14
  },
  header: {
    paddingLeft: theme.spacing(3),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  content: {
    padding: "16px"
  },
  buttonContainer: {
    padding: "10px 16px",
    display: "flex",
    justifyContent: "flex-end",
  },
  selectRoot: {
    padding: "8px 14px"
  },
  inputRoot: {
    paddingTop: "8px !important",
    paddingBottom: "7px !important",
    paddingLeft: "12px !important",
    paddingRight: "12px !important"
  }
}))

export default function AddCurrencyConfigDialogComponent({
  isOpen,
  handleClose,
  handleSubmit,
  handleSearch,
  currencyDropdownData,
  handleFetchMoreItems,
  totalFilter,
  isNewCurrencySelected,
  handleSelectAddCurrency,
  totalRecords,
  lang
}) {
  const classes = useStyle()

  return (
    <Dialog
      maxWidth="sm"
      open={isOpen}
      onClose={handleClose}
      disableBackdropClick
      closeAfterTransition
      BackdropComponent={Backdrop}
      classes={{
        paperWidthSm: classes.dialog
      }}
    >
      <Paper elevation={0} className={classes.root}>
        <Box className={classes.header}>
          <Typography
            variant="subtitle2"
            color="primary"
            style={{ fontWeight: "bold" }}
          >
            {getLang(lang, "label.ADD_CURRENCY")}
          </Typography>
          <IconButton color="primary" onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
        <Divider />
        <Formik
          initialValues={{
            currencyCode: "",
            name: "",
            isDefault: false
          }}
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <Box>
                <Box className={classes.content}>
                  <Typography
                    variant='body2'
                    color='primary'
                    style={{ marginBottom: "10px" }}
                  >
                    {getLang(lang, "label.CURRENCY")}
                  </Typography>
                  <Box style={{ display: "flex", justifyContent: "space-between" }}>
                    <CurrencySelect
                      currencyDropdownData={currencyDropdownData}
                      textFieldProps={{
                        style: { width: 400 },
                      }}
                      formProps={{
                        value: formik.values.currency,
                        onChange: (e, value) => {
                          formik.setFieldValue("currencyCode", value.alpha3);
                          formik.setFieldValue("name", value.name);
                          handleSelectAddCurrency(true);
                        },
                        disabled: formik.isSubmitting
                      }}
                    />
                  </Box>
                  <FormControlLabel
                    control={<Checkbox
                      control={<Checkbox checked={formik.values.isDefault} />}
                      label="Set as default"
                      onChange={(event) => formik.setFieldValue("isDefault", event.target.checked)}
                      color="primary"
                      style={{
                        color: "#6AAF68"
                      }}
                    />}
                    label={getLang(lang, "label.SET_AS_DEFAULT")}
                  />
                </Box>
                <Divider />
                <Box className={classes.buttonContainer}>
                  <Button
                    variant="contained"
                    disableElevation
                    color='primary'
                    type='submit'
                    style={{
                      backgroundColor: isNewCurrencySelected ? "#6AAF68" : "#E5F1E4",
                      color: "#FFFFFF"
                    }}
                    disabled={!isNewCurrencySelected}
                  >
                    {getLang(lang, "label.ADD")}
                  </Button>
                </Box>
              </Box>
            </form>
          )}
        </Formik>
      </Paper>
    </Dialog>
  )
}