const API_HOST = process.env.REACT_APP_API_HOST_URL;

const exportWarrantyReimbursementTable = async ({ ...parameters }) => {
    
    const productString = JSON.stringify(parameters.product);
    const branchString = JSON.stringify(parameters.branch);

    openWindowWithPost(`${API_HOST}/serialnumber/export/v1/exportWarrantyReimbursement?&token=${parameters.token}&product=${productString}&branch=${branchString}&status=${parameters.status}&searchColumn=${parameters.searchColumn}&search=${parameters.search}`
    );

};

function openWindowWithPost(url, data) {
    var form = document.createElement("form");
    form.target = "_blank";
    form.method = "POST";
    form.action = url;
    form.style.display = "none";


    var input = document.createElement("input");
    input.type = "hidden";
    input.name = 'data';
    input.value = data;
    form.appendChild(input);

    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);
}

export default exportWarrantyReimbursementTable;
