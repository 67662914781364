import DownArrowIcon from "./DownArrowIcon";
import UpArrowIcon from "./UpArrowIcon";
import TrendingIncreaseIcon from "./TrendingIncreaseIcon";
import TrendingDecreaseIcon from "./TrendingDecreaseIcon";
import TrendingEqualIcon from "./TrendingEqualIcon";
import DownloadIcon from "./DownloadIcon";
import LineChartIcon from "./LineChartIcon";
import PieChartIcon from "./PieChartIcon";
import HorizontalBarChartIcon from "./HorizontalBarChartIcon";
import UnknownFlagIcon from "./UnknownFlag";
import LoyaltyPointIcon from "./LoyaltyPointIcon";
import OSWindowIcon from "./OSWindowIcon";
import AppleMacIcon from "./AppleMacIcon";
import AndroidIcon from "./AndroidIcon";
import MSIEIcon from "./MSIEIcon";
import MozillaFirefoxIcon from "./MozillaFirefoxIcon";
import OperaIcon from "./OperaIcon";
import ChromeIcon from "./ChromeIcon";
import SafariIcon from "./SafariIcon";
import NetscapeIcon from "./NetscapeIcon";
import EdgeIcon from "./EdgeIcon";
import UnknownUserIcon from "./UnknownUserIcon";
import StackedBarIcon from "./StackedBarIcon";
import VerticalBarChartIcon from "./VerticalBarChartIcon";

export  {
    DownArrowIcon,
    UpArrowIcon,
    TrendingIncreaseIcon,
    TrendingDecreaseIcon,
    TrendingEqualIcon,
    DownloadIcon,
    LineChartIcon,
    PieChartIcon,
    StackedBarIcon,
    HorizontalBarChartIcon,
    UnknownFlagIcon,
    LoyaltyPointIcon,
    OSWindowIcon,
    AppleMacIcon,
    AndroidIcon,
    MSIEIcon,
    MozillaFirefoxIcon,
    OperaIcon,
    ChromeIcon,
    SafariIcon,
    NetscapeIcon,
    EdgeIcon,
    UnknownUserIcon,
    VerticalBarChartIcon
};
