import React from "react";
import {
  makeStyles,
  Box,
  Dialog,
  Typography,
  Button,
  Backdrop,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormControl,
  FormLabel,
  Checkbox,
} from "@material-ui/core";
import { Formik } from "formik";
import { getLang } from "app/feature/constants";
import InputTextField from "components/input/inputTextField";
import { Info } from "@material-ui/icons";

const useStyle = makeStyles((theme) => ({
  greyBox: {
    backgroundColor: "#F9FAFB",
    padding: "8px 12px",
  },
  warningBox: {
    backgroundColor: "#FFFAEB",
    padding: "8px 12px",
  },
  warningText: {
    color: "#FDB022",
  },
  warningTitle: {
    color: "#7A2E0E",
  },
}));

export default function ExportExcelDialogComponent({
  isOpen,
  handleClose,
  handleSubmit,
  lang,
  formats,
  fields,
  batch,
}) {
  const classes = useStyle();

  return (
    <Dialog
      maxWidth={"xs"}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-assign-serial-number-to-product"
      aria-describedby="modal-assign-serial-number-to-product"
      BackdropComponent={Backdrop}
      fullWidth
    // PaperProps={{
    //   style: {
    //     overflowY: "hidden",
    //   },
    // }}
    >
      <Formik
        initialValues={{
          format: "",
          fields: [],
          fromSeqNum: "",
          toSeqNum: ""
        }}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <Box p={3}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                mb={3}
              >
                <Box mb={0.5}>
                  <Typography variant="h6" align="center">
                    <b>{getLang(lang, "label.EXPORT_SERIAL_NUMBER")}</b>
                  </Typography>
                </Box>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  align="center"
                >
                  {getLang(lang, "label.PLEASE_SELECT_EXPORT_INFO")}
                </Typography>
              </Box>
              <Box className={classes.greyBox} mb={2}>
                <Box mb={0.5}>
                  <Typography variant="body1" color="textSecondary">
                    {getLang(lang, "label.BATCH_NUMBER")}
                  </Typography>
                </Box>
                <Typography variant="body1">
                  <b>{batch?.batchNumber || "-"}</b>
                </Typography>
              </Box>
              <Box p={1.5} mb={0.5}>
                {batch?.quantity > 1000000 && (
                  <Box mb={2}>
                    <Box mb={2}>
                      <FormLabel>
                        <Typography variant="body1" style={{ color: "black" }}>
                          <b>{getLang(lang, "label.EXPORT_SEQUENCE_NUMBER_RANGE")}</b>
                        </Typography>
                      </FormLabel>
                    </Box>
                    <Box style={{ display: "flex" }}>
                      <InputTextField
                        {...formik.getFieldProps("fromSeqNum")}
                        hiddenLabel={true}
                        variant="filled"
                        placeholder={getLang(lang, "placeholder.ENTER_START_RANGE")}
                        type="number"
                        size="small"
                        onKeyDown={(e) => {
                          const invalidChars = [
                            "-",
                            "+",
                            "e",
                            ".",
                          ];
                          if (invalidChars.includes(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        onChange={e => {
                          const value = e.target.value;

                          formik.setFieldValue("fromSeqNum", value);
                        }}
                        inputProps={{
                          min: Number(batch?.fromSeqNum),
                          max: Number(batch?.toSeqNum)
                        }}
                        fullWidth
                        InputStyle={{
                          width: "95%"
                        }}
                      />
                      <InputTextField
                        {...formik.getFieldProps("toSeqNum")}
                        hiddenLabel={true}
                        variant="filled"
                        placeholder={getLang(lang, "placeholder.ENTER_END_RANGE")}
                        type="number"
                        size="small"
                        onKeyDown={(e) => {
                          const invalidChars = [
                            "-",
                            "+",
                            "e",
                            ".",
                          ];
                          if (invalidChars.includes(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        onChange={e => {
                          const value = e.target.value;

                          formik.setFieldValue("toSeqNum", value);
                        }}
                        inputProps={{
                          min: Number(batch?.fromSeqNum),
                          max: Number(batch?.toSeqNum)
                        }}
                        fullWidth
                        InputStyle={{
                          width: "95%"
                        }}
                      />
                    </Box>
                    {(Number(formik.values.fromSeqNum) > Number(formik.values.toSeqNum) || (Number(formik.values.toSeqNum) - Number(formik.values.fromSeqNum)) >= 1000000) && <Typography variant="body2" color="error">
                      {getLang(lang, "label.INVALID_RANGE")}
                    </Typography>
                    }
                    <Box
                      style={{
                        display: "flex",
                        margin: "15px 0px",
                        alignItems: "center"
                      }}
                    >
                      <Typography
                        variant="body2"
                        display="block"
                        color="primary"
                        style={{
                          marginLeft: 5
                        }}
                      >
                        {getLang(lang, "label.SEQUENCE_NUMBER_RANGE")}: {batch?.fromSeqNum} - {batch.toSeqNum}
                      </Typography>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        margin: "15px 0px",
                        alignItems: "center"
                      }}
                    >

                      <Info color="primary" />
                      <Typography
                        variant="body2"
                        display="block"
                        color="primary"
                        style={{
                          marginLeft: 5
                        }}
                      >
                        {getLang(lang, "label.MAX_ALLOWED_RANGE")} 1000000
                      </Typography>
                    </Box>
                  </Box>
                )}

                <Box mb={2}>
                  <FormLabel>
                    <Typography variant="body1" style={{ color: "black" }}>
                      <b>{getLang(lang, "label.FILE_FORMAT")}</b>
                    </Typography>
                  </FormLabel>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={formik.values.format}
                      onChange={({ target: { value } }) => {
                        formik.setFieldValue("format", value);
                      }}
                    >
                      {formats.map((f, i) => (
                        <FormControlLabel
                          key={i}
                          value={f}
                          control={<Radio />}
                          label={
                            <Typography
                              variant="body2"
                              style={{ marginRight: 4 }}
                            >
                              <b>.{f}</b>
                            </Typography>
                          }
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </Box>
                <Box>
                  <FormLabel>
                    <Typography variant="body1" style={{ color: "black" }}>
                      <b>{getLang(lang, "label.INFORMATION")}</b>
                    </Typography>
                  </FormLabel>
                  <FormControl>
                    <Box display="flex" flexWrap="wrap">
                      {fields.map((f, i) => (
                        <FormControlLabel
                          key={i}
                          disabled={
                            formik.values.fields.length > 0 &&
                            formik.values.fields[0] === "all" &&
                            f.value !== "all"
                          }
                          control={
                            <Checkbox
                              checked={formik.values.fields.includes(f.value)}
                              onChange={({ target: { checked } }) => {
                                let temp = [...formik.values.fields];
                                if (checked) {
                                  if (f.value === "all") {
                                    temp = [f.value];
                                  } else if (!temp.includes(f.value)) {
                                    temp.push(f.value);
                                  }
                                } else {
                                  temp = temp.filter((t) => t !== f.value);
                                }
                                formik.setFieldValue("fields", temp);
                              }}
                            />
                          }
                          label={
                            <Typography
                              variant="body2"
                              style={{ marginRight: 4 }}
                            >
                              <b>{f.name}</b>
                            </Typography>
                          }
                        />
                      ))}
                    </Box>
                  </FormControl>
                </Box>
              </Box>
              <Box className={classes.warningBox} mb={2}>
                <Box mb={0.5}>
                  <Typography variant="body2" className={classes.warningTitle}>
                    <b>{getLang(lang, "label.TIPS")}:</b>
                  </Typography>
                </Box>
                <Typography variant="body2" className={classes.warningText}>
                  {getLang(lang, "paragraph.EXPORT_OPTION_INSTRUCTION")}
                </Typography>
              </Box>
              <Box
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
                style={{ background: "white" }}
                p={2}
              >
                <Button
                  variant="outlined"
                  type="button"
                  color="primary"
                  onClick={handleClose}
                  style={{ marginRight: 16 }}
                >
                  {getLang(lang, "label.CANCEL")}
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={
                    !formik.values.format || !formik.values.fields.length || (batch?.quantity > 1000000 && (!formik.values.fromSeqNum || !formik.values.toSeqNum))
                  }
                >
                  {getLang(lang, "label.EXPORT")}
                </Button>
              </Box>
            </Box>
          </form>
        )}
      </Formik>
    </Dialog >
  );
}
