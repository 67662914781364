import React from "react"
import {
  Box,
  Button,
  makeStyles,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Checkbox,
} from "@material-ui/core";
import { StickyRightTableCell, StyledTableCell, StyledTableRow } from "components/table";
import AntSwitch from 'components/switch/switch';
import { SkeletonTableRow } from 'components/skeleton/skeleton.component';
import { ActionButton } from 'components/button';
import { TablePaginationActions } from 'components/tablePagination';
import RefreshTable from 'components/table/refreshTable';
import SelectAllButton from '../../selectAllButton';
import clsx from "clsx";
import { getLang } from "app/feature/constants";
import StickyLeftTableCell from "components/table/stickyLeftTableCell";

const useStyle = makeStyles(theme => ({
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    borderCollapse: "separate",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
    },
    "& th,td": {
      fontSize: ".75rem",
      borderRight: "1px solid rgba(58, 77, 84, 0.1)",
      padding: theme.spacing(1, 2, 1, 2),
    },
    "& th": {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightBold,
    },
    "& th:last-child, td:last-child": {
      width: 37,
      borderRight: "0px",
    },
    "& th:first-child, td:first-child": {
      width: 37,
    },
    "& th th": {
      borderBottom: "none !important",
    },
    "& td td": {
      borderBottom: "none !important",
    },
  },
  tableContainer: {
    maxHeight: 500,
    overflowY: "auto"
  },
  emptyContainer: {
    backgroundColor: theme.palette.background.paper,
    textAlign: "center"
  },
  button: {
    display: "flex",
    alignItems: "center",
    padding: 0,
    minWidth: 0,
    marginLeft: theme.spacing(1)
  },
  iconBtn: {
    borderRadius: theme.spacing(0.5),
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shortest,
    }),
    padding: "3px 5px"
  },
  pendingColor: {
    color: "#FFBF00"
  },
  activeColor: {
    color: "#344054"
  },
  deactiveColor: {
    color: "#D0D5DD"
  },
  hidden: {
    display: "none"
  },
  actionContainer: {

    justifyContent: "center"
  },
  defaultCurrencyText: {
    backgroundColor: "#6AAF68",
    color: "#FFFFFF",
    borderRadius: "10px",
    paddingLeft: "12px",
    paddingRight: "12px",
    marginLeft: "10px"
  }
}));

export default function CurrencyConfigListTable({
  data,
  isFetching,
  isLoadingCurrencyConfigListError,
  handleReload,
  page,
  rowsPerPage,
  paginationOptions,
  totalRecords,
  handleChangePage,
  handleChangeRowsPerPage,
  handleCurrencyConfigStatus,
  handleCreateCurrencyConfig,
  handleDeleteCurrencyConfig,
  handleSetDefaultCurrency,
  handleSelectCurrencyConfig,
  handleSelectAll,
  handleDeselectAll,
  lang,
  hasCompanyCurrencyEditAccessRight
}) {
  const classes = useStyle();

  const selectAllItems = [
    {
      label: getLang(lang, "label.SELECT_ALL_ON_THIS_PAGES"),
      action: () => handleSelectAll()
    },
    {
      label: getLang(lang, "label.DESELECT_ALL"),
      action: () => handleDeselectAll(),
      divider: true
    }
  ]

  return (
    <>
      {data.length > 0 || isFetching ? (
        <TableContainer>
          <Table className={classes.table} size="small" stickyHeader>
            <TableHead style={{ height: "45px" }}>
              <TableRow>
                <StickyLeftTableCell>
                  <StyledTableCell padding="checkbox">
                    <SelectAllButton
                      disabled={isFetching || data.length === 0}
                      actionItems={selectAllItems}
                    />
                  </StyledTableCell>
                </StickyLeftTableCell>
                <StyledTableCell>{getLang(lang, "label.CURRENCY")}</StyledTableCell>
                <StyledTableCell style={{ width: "140px" }}>{getLang(lang, "label.CURRENCY_CODE")}</StyledTableCell>
                <StyledTableCell style={{ width: "0px" }}>{getLang(lang, "label.STATUS")}</StyledTableCell>
                <StickyRightTableCell >
                  <StyledTableCell style={{ height: "45px", width: "67px", textAlign: "center" }}>{getLang(lang, "label.ACTION")}</StyledTableCell>
                </StickyRightTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isFetching ? (
                [...new Array(5)].map((i, index) => (
                  <SkeletonTableRow key={index} columnCount={5} />
                ))
              ) : data.map((item, index) => {
                const actionItems = [];

                actionItems.push(
                  {
                    label: getLang(lang, "label.SET_AS_DEFAULT"),
                    action: () => {
                      handleSetDefaultCurrency(item.alpha3)
                    },
                    disabled: (item.isDefault || !hasCompanyCurrencyEditAccessRight)
                  },
                  {
                    label: getLang(lang, "label.DELETE"),
                    divider: true,
                    errorText: true,
                    action: () => handleDeleteCurrencyConfig(item.alpha3, item.name),
                    disabled: (item.isDefault || !hasCompanyCurrencyEditAccessRight)
                  }
                )
                return (
                  <StyledTableRow key={index} hover tableIndex={-1} >
                    <StickyLeftTableCell>
                      <StyledTableCell padding='checkbox'>
                        <Checkbox
                          checked={item.isSelected}
                          color="primary"
                          onChange={(event) => {
                            handleSelectCurrencyConfig(index, event.target.checked)
                          }}
                          disabled={item.isDefault}
                        />
                      </StyledTableCell>
                    </StickyLeftTableCell>
                    <StyledTableCell>
                      <span>{item.name}
                        {item.isDefault ?
                          (
                            <span className={classes.defaultCurrencyText}>{getLang(lang, "label.DEFAULT")}</span>
                          )
                          : null
                        }
                      </span>
                    </StyledTableCell>
                    <StyledTableCell style={{ width: "140px" }}>
                      <span>{item.alpha3}</span>
                    </StyledTableCell>
                    <StyledTableCell style={{ width: "0px" }}>
                      <AntSwitch
                        checked={item.status === 1 ? true : false}
                        onChange={(event) => {
                          handleCurrencyConfigStatus(item.alpha3, event.target.checked)
                        }}
                        disabled={item.isDefault || !hasCompanyCurrencyEditAccessRight}
                      />
                    </StyledTableCell>
                    <StickyRightTableCell>
                      <StyledTableCell style={{ width: "67px", textAlign: "center" }}>
                        <ActionButton className={clsx(classes.activeColor, { [classes.deactiveColor]: item.isDefault })}
                          popperStyle={{ zIndex: 999 }} actionItems={actionItems} />
                      </StyledTableCell>
                    </StickyRightTableCell>
                  </StyledTableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : !isLoadingCurrencyConfigListError || !isFetching ? (
        <Box py={9} className={classes.emptyContainer}>
          <Typography variant='body1'>
            {getLang(lang, "paragraph.NO_CURRENCY_CONFIGURATION")}
          </Typography>
          <Box mt={2}>
            <Button
              variant="contained"
              disableElevation
              color='primary'
              style={{ textTransform: "uppercase" }}
              onClick={() => handleCreateCurrencyConfig(true)}
              disabled={!hasCompanyCurrencyEditAccessRight}
            >
              {getLang(lang, "label.ADD_CURRENCY")}
            </Button>
          </Box>
        </Box>
      ) : null
      }

      {
        !isFetching && isLoadingCurrencyConfigListError && (
          <RefreshTable handleReload={handleReload} />
        )
      }

      {
        !isFetching && data.length > 0 && (
          <TablePagination
            style={{
              backgroundColor: "#fff",
              boxShadow: "0px -1px 0px rgba(58, 77, 84, 0.15)",
            }}
            ActionsComponent={TablePaginationActions}
            rowsPerPageOptions={paginationOptions}
            component="div"
            count={totalRecords}
            rowsPerPage={rowsPerPage}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            page={page}
            labelRowsPerPage={getLang(lang, 'label.ROWS_PER_PAGE')}
            labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${getLang(lang, 'label.OF')} ${count}`}
          />
        )
      }
    </>
  )
}