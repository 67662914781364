import { Box, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import React from "react";
import EmptyChart from "../../global/empty-chart";
import TableCellNoData from "../table-cell";


const useStyles = makeStyles({
    checkboxChartCard: {
        padding: "20px",
        backgroundColor: "#FFFFFF",
        borderRadius: "12px",
        border: "1px solid #D0D5DD"
    },
    item: {
        position: "relative",
        borderRadius: "12px",
        border: "1px solid #D0D5DD"
    },
    item__behind: {
        zIndex: 0,
        opacity: 0.2,
    },
    item__front: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    item__title: {
        fontFamily: "Roboto",
        fontWeight: "700",
        fontSize: "16px",
        lineHeight: "24px",
        color: '#101828',
        textAlign: "center"
    },
    item__value: {
        fontFamily: "Roboto",
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "24px",
        color: '#98A2B3',
        textAlign: "center"
    },
    table__cell: {
        fontFamily: "Roboto",
        border: "1px solid #F2F4F7",
    },
})

export default function CampaignConversionsNoData({
    title, value, translate, columnTitle = [translate("label.CAMPAIGN"), translate("label.TOTAL_ENGAGEMENT")]
}) {
    const classes = useStyles();

    return (
        <Box className={classes.checkboxChartCard}>
            {/* chart */}
            <EmptyChart
                data={[translate("label.SURVEY"), translate("label.LUCKY_DRAW"), translate("label.INSTANT_LUCKY_DRAW"), translate("label.LOYALTY_PROGRAM"), translate("label.WARRANTY")]}
                title={title}
                value={value}
                labels= {['10 July', '11 July', '12 July', '13 July']}
            />

            {/* table */}
            <TableContainer component={ Paper }>
                <Table>
                    <TableHead>
                        <TableRow>
                            {columnTitle.map((item, index) => (
                                <TableCell key={index} className={classes.table__cell}>
                                    {item}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCellNoData 
                                title={title}
                                value={value}
                            />
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}