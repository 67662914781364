import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePaginationOptions, updateUrlQueryParam } from "lib/helper";
import { getCheckoutList, resetCheckoutListing } from "modules/stockflow/redux";
import moment from "moment";
import CheckoutListingComponent from "./checkoutListing.component";
import { useHistory } from "react-router-dom";
import { selectLoading } from "modules/notification";
import { getAllProducts } from "modules/product-mgmt/redux/action/products.action";

function CheckoutListingContainer() {
  const history = useHistory();
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.constant.languages);
  const language = useSelector((state) => state.profile.language)
  const isFetching = useSelector((state) =>
    selectLoading(state, getCheckoutList.typePrefix)
  );
  const productDropdown = useSelector((state) => state.products.allProducts);

  const totalFiltered = useSelector(
    (state) => state.stockflowCheckout.checkoutList.totalFiltered
  );
  const totalRecords = useSelector(
    (state) => state.stockflowCheckout.checkoutList.totalRecords
  );
  const checkoutList = useSelector(
    (state) => state.stockflowCheckout.checkoutList.list
  );

  const isError = useSelector(
    (state) => state.stockflowCheckout.checkoutList.isError
  );
  const isSearchResult = checkoutList.length < totalRecords;
  const [paginationOptions, setPaginationOptions] = useState([]);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isFilterUpdated, setIsFilterUpdated] = useState(false);
  const [tableFilter, setTableFilter] = useState({
    page: 0,
    rowsPerPage: 25,
    order: "desc",
    orderBy: "created_at",
    fromDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
    toDate: moment().format("YYYY-MM-DD"),
    nodeIds: [],
    branch: [],
    checkoutBy: [],
    search: "",
    snSearch: "",
    products: [],
    remark: "",
  });

  const query = new URLSearchParams(window.location.search);
  const tableFilterInitialRef = useRef(true);

  useEffect(() => {
    dispatch(getAllProducts());
  }, [dispatch]);

  useEffect(() => {
    const isFilter = query.has("is_filter");
    let filter = sessionStorage.getItem("checkout_filter");

    let temp = { ...tableFilter };
    if (isFilter && filter) {
      temp = JSON.parse(filter);
      setIsFilterUpdated(true);
      setIsFilterOpen(true);
    }

    if (isFilter) {
      updateUrlQueryParam(null, ["is_filter"]);
    }
    sessionStorage.removeItem("checkout_filter");

    setTableFilter(temp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!tableFilterInitialRef.current) {
      dispatch(resetCheckoutListing());
      handleDataLoad();
    }
    tableFilterInitialRef.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableFilter]);

  useEffect(() => {
    dispatch(resetCheckoutListing());

    return () => {
      dispatch(resetCheckoutListing());
    };
  }, [dispatch]);

  useEffect(() => {
    setPaginationOptions(generatePaginationOptions(totalFiltered));
  }, [totalFiltered]);

  const handleDataLoad = () => {
    let payload = {
      length: tableFilter.rowsPerPage,
      start: tableFilter.rowsPerPage * tableFilter.page,
      order: tableFilter.order,
      order_by: tableFilter.orderBy,
      filter: {
        search: tableFilter.search,
        remark: tableFilter.remark,
        serialnumber_search: tableFilter.snSearch,
        from_date: tableFilter.fromDate,
        to_date: tableFilter.toDate,
        checkout_by: tableFilter.checkoutBy,
        nodes: tableFilter.nodeIds,
        product_uuid: tableFilter.products.map((p) => p.id),
      },
    };
    dispatch(getCheckoutList(payload));
  };

  const handleChangePage = (event, newPage) => {
    let newData = {
      ...tableFilter,
      page: newPage,
    };
    setIsFilterUpdated(true);
    setTableFilter(newData);
  };

  const handleChangeRowsPerPage = (event) => {
    let newData = {
      ...tableFilter,
      page: 0,
      rowsPerPage: event.target.value,
    };
    setIsFilterUpdated(true);
    setTableFilter(newData);
  };

  const handleSearch = (filter) => {
    let newData = {
      ...tableFilter,
      page: 0,
      remark: filter.remark,
      nodeIds:
        filter.branch.length > 0 ? filter.branch.map((br) => br.node_id) : [],
      branch: filter.branch,
      search: filter.search,
      fromDate: filter.dateRange?.from
        ? moment(filter.dateRange.from).format("YYYY-MM-DD")
        : "",
      toDate: filter.dateRange?.to
        ? moment(filter.dateRange.to).format("YYYY-MM-DD")
        : "",

      snSearch: filter.snSearch,
      checkoutBy: filter.checkoutBy,
      products: filter.products,
    };
    setIsFilterUpdated(true);
    setTableFilter(newData);
  };

  const clearSearch = () => {
    let newData = {
      ...tableFilter,
      page: 0,
      remark: "",
      fromDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
      toDate: moment().format("YYYY-MM-DD"),
      branch: [],
      nodeIds: [],
      search: "",
      checkoutBy: [],
      snSearch: "",
      products: [],
    };
    setIsFilterUpdated(false);
    setTableFilter(newData);
  };

  const changeOrder = (orderBy, order) => {
    let newData = {
      ...tableFilter,
      order,
      orderBy,
    };
    setIsFilterUpdated(true);
    setTableFilter(newData);
  };

  const resetFilter = () => {
    let newData = {
      ...tableFilter,
      page: 0,
      checkoutBy: [],
      snSearch: "",
    };
    setIsFilterUpdated(true);
    setTableFilter(newData);
  };

  const handleViewDetail = (url) => {
    if (isFilterUpdated) {
      updateUrlQueryParam({ is_filter: true });
      sessionStorage.setItem("checkout_filter", JSON.stringify(tableFilter));
    }
    history.push(url);
  };

  return (
    <>
      <CheckoutListingComponent
        checkouts={checkoutList}
        isFetching={isFetching}
        isError={isError}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        language={language}
        page={tableFilter.page}
        rowsPerPage={tableFilter.rowsPerPage}
        totalFiltered={totalFiltered}
        handleSearch={handleSearch}
        clearSearch={clearSearch}
        handleReload={handleDataLoad}
        isSearchResult={isSearchResult}
        paginationOptions={paginationOptions}
        isFilterOpen={isFilterOpen}
        setIsFilterOpen={setIsFilterOpen}
        tableFilter={tableFilter}
        changeOrder={changeOrder}
        lang={lang}
        resetFilter={resetFilter}
        handleViewDetail={handleViewDetail}
        productDropdown={productDropdown}
      />
    </>
  );
}

export default CheckoutListingContainer;
