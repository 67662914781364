import React from "react";
import CounterfeitTableComponent from "./counterfeitTable.component";
import { useHistory } from "react-router-dom";
import { ROOT } from "../../../config/counterfeit-report.route";
import { useSelector } from "react-redux";

function CounterfeitTableContainer({
  isFetching,
  counterfeitReportData,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
  totalFiltered,
  search,
  handleReload,
  paginationOptions,
  lang,
  tableFilter,
  changeOrder
}) {
  const history = useHistory();
  const isLoadingCounterfeitReportError = useSelector(state => state.counterfeitReport.isLoadingCounterfeitReportError)
  const language = useSelector(state => state.profile.language)

  async function handleView(refCode) {
    history.push(`${ROOT}/View/${refCode}`);
  }

  return (
    <CounterfeitTableComponent
      rows={counterfeitReportData}
      page={page}
      rowsPerPage={rowsPerPage}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      isFetching={isFetching}
      totalFiltered={totalFiltered}
      language={language}
      search={search}
      handleView={handleView}
      handleReload={handleReload}
      isLoadingCounterfeitReportError={isLoadingCounterfeitReportError}
      paginationOptions={paginationOptions}
      lang={lang}
      tableFilter={tableFilter}
      changeOrder={changeOrder}
    />
  );
}

export default CounterfeitTableContainer;
