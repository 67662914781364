import { useState, useEffect } from "react";
import notificationApi from "app/api/notification";
import moment from "moment";

export default function useAlert() {
  const [alerts, setAlerts] = useState([]);
  const [hasNotReadCount, setHasNotReadCount] = useState(0);
  const [hasNotReadIds, setHasNotReadIds] = useState([]);
  const [isFetching, setFetching] = useState(false);

  useEffect(() => {
    const isReady = false;

    if (isReady) fetchNotification();

    // const fetchNotifLoop = setInterval(() => {
    //   //fetchNotification();
    // }, 180000);

    async function fetchNotification() {
      setFetching(true);
      const { sessionID, userAccountToken } = JSON.parse(
        localStorage.getItem("jwt")
      );

      const fetchAlert = await notificationApi.getAllAlerts({
        sessionID,
        userAccountToken
      });

      setFetching(false);

      if (fetchAlert.success) {
        const onlyGenerateSNAlert = [
          ...fetchAlert.alerts
            .filter(alert => alert.notifyModule === "GENERATESN")
            .sort((a, b) => moment(b.modifiedAt) - moment(a.modifiedAt))
        ];
        setAlerts(onlyGenerateSNAlert);
      }
    }

    return () => {
      //clearInterval(fetchNotifLoop);
    };
  }, []);

  useEffect(() => {
    if (!alerts.length) return;
    let getHasNotReadCount = 0;
    let getHasNotReadIds = [];

    alerts.forEach(alert => {
      if (alert.isRead) return;

      getHasNotReadCount++;
      getHasNotReadIds.push(alert.id);
    });

    setHasNotReadIds(getHasNotReadIds);
    setHasNotReadCount(getHasNotReadCount);
  }, [alerts]);

  const readNotifications = async () => {
    const previousReadCount = hasNotReadCount;
    const { sessionID, userAccountToken } = JSON.parse(
      localStorage.getItem("jwt")
    );

    const updateReadFlag = await notificationApi.updateReadFlag({
      sessionID,
      userAccountToken,
      id: hasNotReadIds,
      isRead: true
    });

    if (updateReadFlag.success) {
      setHasNotReadCount(0);
      setHasNotReadIds([]);
    } else {
      setHasNotReadCount(previousReadCount);
    }
  };

  return [alerts, isFetching, hasNotReadCount, readNotifications];
}
