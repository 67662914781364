import axios from "axios"

export default function updatePointCutStatus({
    id,
    status
}) {
    return new Promise((resolve, reject) => {
        axios.post(`/campaign/api/admin/items-setting/${id}/status`,
            {
                status,
            })
            .then(response => resolve(response.data))
            .catch(error => reject(error.response.data))
    })
}