import React from "react";
import AuthenticationComponent from "./authentication.component";
import { useSelector } from "react-redux";

export default function AuthenticationContainer({ id, currentLanguage }) {
  const { isVisible, content } = useSelector(
    state => state.productPage.components[id]
  );
  const isSnHidden = content.isSnHidden ?? false;
  const { productName, picture } = useSelector(state => state.productPage);
  const lang = useSelector(state => state.constant.languages);
  const userLanguage = useSelector(state => state.profile.language);
  const message = content.messages.find((e) => e.lang === currentLanguage).value;

  return (
    isVisible && (
      <AuthenticationComponent
        isSnHidden={isSnHidden}
        productName={productName}
        picture={picture}
        content={content}
        message={message}
        lang={lang}
        userLanguage={userLanguage}
      />
    )
  );
}
