import React from "react";
import { makeStyles, ButtonBase, Typography, Button, Box } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ClearIcon from '@material-ui/icons/Clear';
import clsx from "clsx";

const useStyle = makeStyles((theme) => ({
  root: {
    padding: "5px 12px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#ECEFF0",
    borderRadius: "4px",
    width: "100%",
    height: 43
  },
  inputText: {
    fontSize: "1rem",
    color: "#A4A6A8",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  },
  filled: {
    color: "rgba(0, 0, 0, 0.87)"
  },
  clearButton: {
    minWidth: 0
  }
}));

export default function WarrantySelectComponent({
  anchorRef,
  handleClick,
  isClearable,
  handleClear,
  value,
  open,
  placeholder
}) {
  const classes = useStyle();

  const isEmpty = !value.warrantyName ? true : false;
  return (
    <ButtonBase
      className={classes.root}
      disableRipple
      ref={anchorRef}
      onClick={handleClick}
    >
      <Typography
        variant="body2"
        className={clsx(classes.inputText, !isEmpty && classes.filled)}
      >
        {!value.warrantyName ? placeholder : value.warrantyName}
      </Typography>
      <Box
        style={{
          display: "flex",
          alignItems: "center"
        }}
      >
        {(!isEmpty && isClearable) &&
          <Button
            className={classes.clearButton}
            onClick={(e) => {
              e.stopPropagation();
              handleClear();
            }}
          >
            <ClearIcon style={{ fontSize: 16 }} />
          </Button>
        }

        {(open ? (
          <ArrowDropUpIcon fontSize='small' />
        ) : (
          <ArrowDropDownIcon fontSize='small' />
        ))}
      </Box>
    </ButtonBase>
  );
}
