import React from "react"
import { 
  makeStyles,
  ButtonBase,
  Typography,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import clsx from "clsx";

const useStyle = makeStyles((theme) => ({
  root: {
    padding: "5px 12px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#ECEFF0",
    borderRadius: "4px",
    width: "100%",
    overflow: "hidden"
  },
  inputText: {
    fontSize: "1rem",
    color: "#A4A6A8",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  },
  filled: {
    color: "rgba(0, 0, 0, 0.87)"
  }
}));

export default function BulkCreateBrandModelSelectComponent({
  handleClick,
  value,
  styles,
  disabled,
  placeholder,
  textStyle,
  id,
}) {
  const classes = useStyle();

  const getDisplay = () => {
    let display = placeholder;
    let tempBrand = [];
    let tempModels = [];

    if(value.length > 0) {
      value.map((v) => {
        if(v.models.length > 0) {
          v.models.map((m) => {
            tempModels.push(m.name)
            return null
          })
          tempBrand.push(`${v.name} (${tempModels.join(", ")})`)
        } else {
          tempBrand.push(v.name)
        }
        return null
      })
      
      display = tempBrand.join(", ")
    }

    return display
  }

  return (
    <ButtonBase
      aria-describedby={id}
      className={classes.root}
      disableRipple
      style={styles}
      onClick={handleClick}
      disabled={disabled}
    >
      <Typography
        variant="body2"
        style={textStyle}
        className={clsx(
          classes.inputText,
          getDisplay() !== placeholder && classes.filled 
        )}
      >
        {getDisplay()}
      </Typography>
      <ArrowDropDownIcon fontSize='small' />
    </ButtonBase>
  )
}