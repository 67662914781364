import React from "react";
import GroupBranchRuleTableComponent from "./groupBranchRuleTable.component";

function GroupBranchRuleTableContainer({
  rows,
  isEditable,
  lang,
  updateRules,
  isFetching,
  type,
  removeRule,
}) {
  return (
    <GroupBranchRuleTableComponent
      rows={rows}
      lang={lang}
      isEditable={isEditable}
      updateRules={updateRules}
      isFetching={isFetching}
      type={type}
      removeRule={removeRule}
    />
  );
}

export default GroupBranchRuleTableContainer;
