import React from "react";
import BillingDetailFormComponent from ".//billingDetailForm.component";
import PropTypes from "prop-types";
import { updateBillingDetail } from "../../../redux";
import { selectLoading } from "modules/notification";
import { connect, useSelector } from "react-redux";
import { getCountry } from "app/feature/constants"

function BillingDetailFormContainer({
  handleClose,
  billingDetail,
  updateBillingDetail,
  isChanging
}) {
  const lang = useSelector(state => state.constant.languages)
  const countryDropdown = useSelector(state => state.constant.country.data);
  const isCountryDropdownLoading = useSelector(state => selectLoading(state, getCountry.typePrefix));

  const handleSubmit = async (values) => {
    values.phone = values.phone.replace(/^0/, '');
    values.postal_code = values.postal_code.trim();
    const updateInfo = {
      ...values,
      contact_code: values.phoneCode.phone,
      country: values.country.label
    };
    await updateBillingDetail(updateInfo);

    handleClose()
  };

  return (
    <BillingDetailFormComponent
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      billingDetail={billingDetail}
      isChanging={isChanging}
      lang={lang}
      country={countryDropdown}
      isCountryDropdownLoading={isCountryDropdownLoading}
    />
  );
}

BillingDetailFormContainer.propTypes = {
  handleClose: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  isChanging: selectLoading(state, updateBillingDetail.typePrefix)
});

const mapDispatchToProps = dispatch => ({
  updateBillingDetail: billingDetailData => dispatch(updateBillingDetail(billingDetailData))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BillingDetailFormContainer);