import React from "react";
import { makeStyles, Button, Box } from '@material-ui/core';
import { Formik } from "formik";
import * as Yup from "yup";
import PropTypes from "prop-types"
import InputTextField from 'components/input/inputTextField';
import DatePicker from 'components/input/datePicker';
import SearchableSelect from '../../select/searchableSelect';
import { getLang } from "app/feature/constants";

const useStyle = makeStyles((theme) => ({
  mainContainer: {
    backgroundColor: theme.palette.background.paper,
  },
  searchContainer: {
    display: "grid",
    gridTemplateColumns: "3fr 2fr 2fr 1fr 1fr",
    columnGap: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1),
    },
  },
  button: {
    [theme.breakpoints.up("sm")]: {
      width: "fit-content",
      justifySelf: "flex-end",
    },
  },
  inputRoot: {
    paddingTop: "8px !important",
    paddingBottom: "7px !important",
  },
}));

function RespondentSearchComponent({
  handleSearch,
  productDropdown,
  isProductLoading,
  handleItemSearch,
  lang
}) {
  const classes = useStyle();

  return (
    <Box p={2} mb={2} className={classes.mainContainer}>
      <Formik
        initialValues={{
          search: "",
          productSelect: "",
          productName: "",
          dateSubmitted: null,
        }}
        validationSchema={Yup.object({
          search: Yup.string()
        })}
        onSubmit={handleSearch}
      >
        {(formik) => (
          <form className={classes.searchContainer} onSubmit={formik.handleSubmit}>
            <InputTextField
              variant="filled"
              size="small"
              margin="none"
              InputProps={{
                disableUnderline: true,
                margin: "none",
                style: {
                  borderRadius: "4px",
                  backgroundColor: "#eceff0"
                },
                classes: { input: classes.inputRoot }
              }}
              {...formik.getFieldProps("search")}
              error={formik.touched.search && formik.errors.search}
            />
            <SearchableSelect
              placeholder={getLang(lang, 'placeholder.ALL_PRODUCT')}
              handleChange={(value) => {
                formik.setFieldValue("productSelect", value.id)
                formik.setFieldValue("productName", value.name)
                }
              }
              value={formik.values.productName}
              disabled={false}
              dropdownItem={productDropdown}
              isLoading={isProductLoading}
              emptyMessage={getLang(lang, 'message.info.PRODUCT_NOT_FOUND')}
              searchable={true}
              handleItemSearch={handleItemSearch}
            />
            <DatePicker
              label=""
              disabled={formik.isSubmitting}
              disableFuture
              id="date-picker"
              value={formik.values.dateSubmitted}
              handleChange={(value) => formik.setFieldValue("dateSubmitted", value.format("YYYY-MM-DD"))}
              InputPropStyle={{
                margin: 0,
                height: 34
              }}
            />
            <Button
              color="secondary"
              variant="text"
              size="small"
              disableElevation
              onClick={() => {
                formik.resetForm()
                formik.handleSubmit()
              }}
              type="reset"
            >
              {getLang(lang, 'label.CLEAR')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              type="submit"
              size="small"
            >
              {getLang(lang, 'label.SEARCH')}
            </Button>
          </form>
        )}
      </Formik>
    </Box>
  )
}

RespondentSearchComponent.propTypes = {
  handleSearch: PropTypes.func.isRequired,
};

export default RespondentSearchComponent;
