import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ScanMapCardComponent from "./scanMapCard.component";
import { selectLoading } from "modules/notification";
import { fetchScanMap } from "../../../redux/action/dashboard.action";
import ScanDetailDialog from "../../dialog/scanDetailDialog";
import ClusterScanDetailDialog from "../../dialog/clusterScanDetailDialog";
import { resetClusterScanProducts } from "modules/dashboard/redux/slice/dashboard.slice";
import moment from "moment";

function ScanMapCardContainer({ startDate, endDate }) {
  const dispatch = useDispatch();
  const mapScans = useSelector((state) => state.dashboard.mapScans);
  const isLoading = useSelector((state) =>
    selectLoading(state, fetchScanMap.typePrefix)
  );
  const [isOpenDetailDialog, setIsOpenDetailDialog] = useState(false);
  const [isOpenClusterDialog, setIsOpenClusterDialog] = useState(false);
  const [selectedScan, setSelectedScan] = useState(null);
  const [revealProduct, setRevealProduct] = useState(false);
  const [clusterBound, setClusterBound] = useState(null);

  let start = startDate
    ? moment(startDate).format("YYYY-MM-DD")
    : moment().subtract(1, "months").format("YYYY-MM-DD");
  let end = endDate
    ? moment(endDate).format("YYYY-MM-DD")
    : moment().format("YYYY-MM-DD");

  useEffect(() => {
    dispatch(
      fetchScanMap({
        start_date: start,
        end_date: end,
      })
    );
  }, [dispatch, start, end]);

  useEffect(() => {
    setIsOpenDetailDialog(!!selectedScan);
  }, [selectedScan]);

  useEffect(() => {
    setIsOpenClusterDialog(!!clusterBound);
  }, [clusterBound]);

  const handleClusterClick = (bounds) => {
    dispatch(resetClusterScanProducts());
    setClusterBound(bounds);
  };

  const handleMarkerClick = (id) => {
    setSelectedScan(id);
  };

  const closeCluster = () => {
    setClusterBound(null);
  };

  return (
    <>
      <ScanMapCardComponent
        mapScans={mapScans}
        isLoading={isLoading}
        handleClusterClick={handleClusterClick}
        handleMarkerClick={handleMarkerClick}
        revealProduct={revealProduct}
        updateRevealProductStatus={(show) => setRevealProduct(show)}
      />
      <ScanDetailDialog
        isOpen={isOpenDetailDialog}
        scanId={selectedScan || ""}
        handleClose={() => setSelectedScan(null)}
      />
      <ClusterScanDetailDialog
        startDate={start}
        endDate={end}
        clusterBound={clusterBound}
        isOpen={isOpenClusterDialog}
        handleClose={closeCluster}
        handleClusterClick={handleClusterClick}
        handleMarkerClick={handleMarkerClick}
      />
    </>
  );
}

export default memo(ScanMapCardContainer);
