import React from "react";
import { makeStyles, ButtonBase, Typography } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import clsx from "clsx";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles((theme) => ({
  root: {
    padding: "5px 12px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#ECEFF0",
    borderRadius: "4px",
    width: "100%",
    height: 43,
    marginBottom: theme.spacing(1)
  },
  inputText: {
    fontSize: "1rem",
    color: "#A4A6A8",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  },
  filled: {
    color: "rgba(0, 0, 0, 0.87)"
  }
}));

export default function AssignModuleSelectComponent({
  anchorRef,
  handleClick,
  value,
  placeholder,
  disabled,
  lang
}) {
  const classes = useStyle();

  const isEmpty = value.length === 0 ? true : false;

  return (
    <ButtonBase
      className={classes.root}
      disableRipple
      ref={anchorRef}
      onClick={handleClick}
      disabled={disabled}
    >
      <Typography
        variant="body2"
        className={clsx(classes.inputText, !isEmpty && classes.filled)}
      >
        {isEmpty ? placeholder : value.length + " " + getLang(lang,"label.MODULES_SELECTED")}
      </Typography>
      <ArrowDropDownIcon fontSize="small" />
    </ButtonBase>
  );
}
