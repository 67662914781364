import React, { useEffect } from "react";
import VerifyInvitationComponent from "./verify-invitation.page";
import { verifyEmployee } from "modules/public/redux/action";
import { useDispatch, useSelector } from "react-redux"
import { useParams, useLocation, useHistory } from "react-router-dom"
import { selectLoading } from "modules/notification";
import { unwrapResult } from "@reduxjs/toolkit";
import { setEmployee } from "../../redux/slice/signup.slice"

function VerifyInvitationContainer() {
  const dispatch = useDispatch()
  const { id } = useParams();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const token = query.get('token');
  const history = useHistory();
  const isFetching = useSelector((state) => selectLoading(state, verifyEmployee.typePrefix));
  useEffect(() => {
    if (token) {
      dispatch(setEmployee({ id, token }))
      dispatch(verifyEmployee({ id, token }))
        .then(unwrapResult)
        .then((r) => {
          history.push("/signup");
        })
        .catch((error) => {
          history.push("/invalid")
        });
    }
    else {
      //redirect to expired page
    }
  }, [dispatch, history, token, id]);

  return <VerifyInvitationComponent isFetching={isFetching} />;
}


export default VerifyInvitationContainer;
