import axios from "axios";
import moment from "moment";

const API_HOST = process.env.REACT_APP_API_HOST_URL;
const saveFile = async (blob, type) => {
  const a = document.createElement("a");
  const date = moment().format("YYYYMMDD");
  a.download = `warrantees-list_${date}.${type}`;
  a.href = URL.createObjectURL(blob);
  a.addEventListener("click", (e) => {
    setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
  });
  a.click();
};

const getAll = ({ ...parameters }) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/consumer/admin/api/v1/warrantees`, {
        params: {
          draw: parameters.draw,
          length: parameters.length,
          start: parameters.start,
          column: parameters.column,
          order: parameters.order,
          search: parameters.search,
          searchColumn: parameters.searchColumn,
          warranties: parameters.warranties,
        },
      })
      .then((response) => resolve(response))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};
const exports = async ({ ...parameters }) => {
  let response = await fetch(
    `${API_HOST}/consumer/export/v1/warrantee?token=${parameters.token}`,
    {
      method: "Post",
      headers: {
        Authorization: `Bearer ${parameters.token}`,
      },
      body: JSON.stringify({
        draw: parameters.draw,
        length: parameters.length,
        start: parameters.start,
        column: parameters.column,
        order: parameters.order,
        search: parameters.search,
        searchColumn: parameters.searchColumn,
        warranties: parameters.warranties,
        type: parameters.type,
      }),
    }
  );

  let blob = await response.blob();

  saveFile(blob, parameters.type);
};

const warranteeList = {
  getAll,
  exports,
};

export default warranteeList;
