import axios from "axios";

const getCollaborationGroupByTypeAndCode = ({
  type,
  code
}) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/account/public/api/v1/collaboration-groups/${type}/${code}`)
      .then(response => resolve(response.data))
      .catch(error => {
        return reject(error.response);
      });
  });
};

export default getCollaborationGroupByTypeAndCode;
