import axios from 'axios';

export function updateAttributesStatus({
  filter,
  update,
}) {
  const url =  `/serialnumber/api/v1/product/attribute/bulk-update-status`;

  return new Promise((resolve, reject) => {
    axios.post(url, {
      filter: filter,
      update: update
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data))
  })
}