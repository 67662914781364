import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import ArrowBackRoundedIcon from "@material-ui/icons/ArrowBackRounded";

const useStyle = makeStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    transition: "all .4s ease",
    width: "fit-content",
    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer",
      transform: "translateY(-1px)"
    }
  }
}));

export default function ButtonBackComponent({ handleClick, text }) {
  const classes = useStyle();

  return (
    <Typography
      className={classes.root}
      variant="body1"
      color="textPrimary"
      onClick={handleClick}
    >
      <ArrowBackRoundedIcon fontSize="small" />
      {text}
    </Typography>
  );
}
