import React from 'react'
import {
    Box,
    Button,
    makeStyles,
    Typography
} from '@material-ui/core';
import Header from 'modules/public/components/header';
import SuccessImage from '../../utils/image/success.png'
import { CreditKoodFooter } from 'modules/public/components/layout';
import { getLang } from 'app/feature/constants';

const contentWidth = "500px";

const useStyle = makeStyles(theme => ({
    root: {
        width: "60%",
        height: "100%",
        [theme.breakpoints.down("md")]: {
            width: "80%"
        }
    },
    headerWrapper: {
        maxWidth: contentWidth,
        width: "100%",
        backgroundColor: theme.palette.common.white
    },

    centerContent: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column"
    },
    form: {
        marginTop: theme.spacing(3),
        maxWidth: contentWidth,
        width: "90%",
        backgroundColor: "white",
        borderRadius: 5,
        boxShadow: "0px 4px 4px rgba(60, 77, 84, 0.15)"
    },
    content: {
        marginTop: theme.spacing(1),
        textAlign: "center",
    },
    content1: {
        [theme.breakpoints.down("xs")]: {
            width: "100%"
        },
        alignSelf: "center",
        textAlign: "center"
    },
    emailImage: {
        marginTop: theme.spacing(2),
        alignSelf: "center"
    }
}));

export default function ResetPasswordSuccessPage({ handleContinue, lang }) {
    const classes = useStyle()

    return (
        <div
            style={{
                minHeight: "100%",
                backgroundColor: "#F5F6F6",
                overflow: "auto"
            }}
        >
            <Box className={classes.centerContent}>
                <Box display="flex" style={{ width: "100%", backgroundColor: "white", justifyContent: "center" }}>
                    <Box className={classes.headerWrapper}>
                        <Header />
                    </Box>
                </Box>
                <div className={classes.form}>
                    <Box p={4} display="grid" justifyItems="center">
                        <Box className={classes.emailImage}>
                            <img src={SuccessImage} alt="emailImage" />
                        </Box>
                        <Box className={classes.content}>
                            <Typography variant="h6" style={{ fontWeight: "bold" }} color="textPrimary">
                                {getLang(lang, "label.RESET_PASSWORD_SUCCESS")}
                            </Typography>
                        </Box>
                        <Box className={classes.content1}>
                            <Typography variant="body1" color="textPrimary">
                                {getLang(lang, "paragraph.PASSWORD_RESET_SUCCESSFULLY")}
                            </Typography>
                        </Box>
                        <Box py={5}>
                            <Button size="large" variant="contained" color="secondary" onClick={handleContinue}>{getLang(lang, "label.LOGIN_NOW")}</Button>
                        </Box>
                    </Box>
                </div>
            </Box>
            <CreditKoodFooter />
        </div>
    )
}
