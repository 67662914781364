import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import GenerateSerialNumberFormComponent from './generateSerialNumberForm.component';
import {
    fetchBatchRangeList,
    resetGenerateRangeList,
    submitSerialNumberGenerate
} from 'modules/serial-number/redux';
import { addAlert, selectLoading } from 'modules/notification';
import GenerateSuccessDialog from '../../dialog/generateSuccessDialog';
import { ROOT } from 'modules/serial-number';
import { fetchQuickSubscriptionSummary } from "modules/billing/redux";
import { unwrapResult } from '@reduxjs/toolkit';
import { getProductDropdownByBranch } from 'modules/product-mgmt/redux/action/products.action';
import { resetProductsDropdown } from 'modules/product-mgmt/redux/slice/products.slice';
import { SERIAL_NUMBER_BATCH_AUTHENTICATOR_EDIT } from 'lib/constants/accessRights';
import GeneralDialog from 'components/dialog/generalDialog';
import { getLang } from 'app/feature/constants';
import AlertIcon from 'assets/svg/alert.svg';

function GenerateSerialNumberFormContainer({
    isLoading,
    fetchBatchRangeList,
    productsDropdown,
    availableQuantity,
    batchRangeInfo,
    resetGenerateRangeList,
    submitSerialNumberGenerate,
    branchDropdown,
    fetchQuickSubscriptionSummary,
    tags,
    addAlert,
}) {
    const [isOpen, setIsOpen] = useState(false);
    const [showCreditErrorDialog, setShowCreditErrorDialog] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [generatedBatchID, setGeneratedBatchID] = useState("");
    const [generatedCreditUsed, setGeneratedCreditUsed] = useState(0);
    const [generatedRemainingCredit, setGeneratedRemainingCredit] = useState(0);
    const [isSameRange, setIsSameRange] = useState(false);
    const [isExceedLimit, setIsExceedLimit] = useState(false);
    const [isInputDisable, setIsInputDisable] = useState(true);
    const lang = useSelector(state => state.constant.languages)
    const registrationType = useSelector(state => state.profile.registrationType)
    const qrCredit = useSelector(state => state.billing.credits)

    const isLoadingProductDropdown = useSelector(state => selectLoading(state, getProductDropdownByBranch.typePrefix))
    const hasBatchAuthenticatorEditRight = useSelector((state) =>
        state.profile.accessRights.includes(SERIAL_NUMBER_BATCH_AUTHENTICATOR_EDIT)
    );

    const history = useHistory();

    const dispatch = useDispatch();

    function handleSubmit(values, { setSubmitting }) {
        submitSerialNumberGenerate(values)
            .then(unwrapResult)
            .then(response => {
                setGeneratedBatchID(response.batch_number);
                if (registrationType !== 3) {
                    setGeneratedCreditUsed(response.credit_used);
                    setGeneratedRemainingCredit(response.credit_balance);
                }
                fetchQuickSubscriptionSummary();
                setIsSuccess(true);
            })
            .catch(error => {
                setSubmitting(false);
            })
            .finally(() => {
                setIsOpen(false);
            })
    }

    function handleDiscard() {
        history.goBack();
    }

    function fetchHistory({ prefix, length, postfix, type }) {
        fetchBatchRangeList({ prefix, length, postfix, type });
    }

    function goToGenerateBatchPage() {
        history.push({
            pathname: ROOT,
            search: `?tab=batch`
        });
    }

    const loadProductDropdown = (formik, branchUuid) => {
        formik.setFieldValue("product", "");
        dispatch(getProductDropdownByBranch(branchUuid));
    }

    useEffect(() => {
        return () => {
            dispatch(resetProductsDropdown());
        }
    }, [dispatch])

    return (
        <>
            <GenerateSerialNumberFormComponent
                qrCredit={qrCredit}
                registrationType={registrationType}
                handleSubmit={handleSubmit}
                handleDiscard={handleDiscard}
                fetchHistory={fetchHistory}
                productsDropdown={productsDropdown}
                availableQuantity={availableQuantity}
                batchRangeInfo={batchRangeInfo}
                resetGenerateRangeList={resetGenerateRangeList}
                isLoading={isLoading}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                branchDropdown={branchDropdown}
                tags={tags}
                addAlert={addAlert}
                isSameRange={isSameRange}
                setIsSameRange={setIsSameRange}
                isExceedLimit={isExceedLimit}
                setIsExceedLimit={setIsExceedLimit}
                isInputDisable={isInputDisable}
                setIsInputDisable={setIsInputDisable}
                lang={lang}
                loadProductDropdown={loadProductDropdown}
                isLoadingProductDropdown={isLoadingProductDropdown}
                hasBatchAuthenticatorEditRight={hasBatchAuthenticatorEditRight}
                setShowCreditErrorDialog={setShowCreditErrorDialog}
            />
            <GenerateSuccessDialog
                registrationType={registrationType}
                isOpen={isSuccess}
                handleProceed={goToGenerateBatchPage}
                batchID={generatedBatchID}
                creditUsed={generatedCreditUsed}
                remainingCredit={generatedRemainingCredit}
            />
            <GeneralDialog
                isOpen={showCreditErrorDialog}
                handleClose={() => setShowCreditErrorDialog(false)}
                handleProceed={() => setShowCreditErrorDialog(false)}
                rejectOption={false}
                title={getLang(lang,"label.GENERATE_QUANTITY_EXCEED_LIMIT")}
                description={getLang(lang,"paragraph.GENERATE_QUANTITY_EXCEED_LIMIT_QUESTION")}
                type="danger"
                icon={<img src={AlertIcon} alt="alert" />}
            />
        </>
    );
}

const mapStateToProps = state => ({
    productsDropdown: state.products.productsDropdown,
    availableQuantity: state.serialNumber.generateSerialNumber.batchRange.availableQuantity,
    batchRangeInfo: state.serialNumber.generateSerialNumber.batchRange,
    isLoading: selectLoading(state, submitSerialNumberGenerate.typePrefix),
    branchDropdown: state.branch.branchDropdown,
    tags: state.serialNumberTag.tagsDropdown,
});

const mapDispatchToProps = dispatch => ({
    fetchBatchRangeList: values => dispatch(fetchBatchRangeList(values)),
    resetGenerateRangeList: () => dispatch(resetGenerateRangeList()),
    submitSerialNumberGenerate: values => dispatch(submitSerialNumberGenerate(values)),
    fetchQuickSubscriptionSummary: () => dispatch(fetchQuickSubscriptionSummary()),
    addAlert: value => dispatch(addAlert({ severity: "warning", message: value })),
});

export default connect(mapStateToProps, mapDispatchToProps)(GenerateSerialNumberFormContainer);