import getTotalScanTrending from "./trending/getTotalScanTrending.api";
import getTotalSuspendedTrending from "./trending/getTotalSuspendedTrending.api";
import getTotalDistributionPerformance from "./trending/getTotalDistributionPerformance.api";
import getTotalReportedTrending from "./trending/getTotalReportedTrending.api";
import getCampaignEngagedTrending from "./trending/getCampaignEngagedTrending.api";
import getActiveDealerTrending from "./trending/getActiveDealerTrending.api";
import getInactiveDealerTrending from "./trending/getInactiveDealerTrending.api";
import getDealerStockOutTrending from './trending/getDealerStockOutTrending.api';
import getTotalRecallTrending from "./trending/getTotalRecallTrending.api";
import getNewDealerTrending from './trending/getNewDealerTrending.api';
import getTotalCampaignSurveyTrending from './trending/getTotalCampaignSurveyTrending.api';
import getRequestStockoutTrending from './trending/getRequestStockoutTrending.api';
import getTotalPackageTrending from './trending/getTotalPackageTrending.api';
import getTotalRecalledPackageTrending from './trending/getTotalRecalledPackageTrending.api';
import getItemTotalPackageTrending from './trending/getItemTotalPackageTrending.api';
import getItemTotalRequestTrending from './trending/getItemTotalRequestTrending.api';
import getItemTotalRejectTrending from './trending/getItemTotalRejectTrending.api';
import getTopSuspendedProductTrending from "./trending/getTopSuspendedProductTrending";
import getTopSuspendedQrTrending from "./trending/getTopSuspendedQrTrending";

import getPlatformData from "./miscellaneous/getPlatformData.api";
import getLanguageData from "./miscellaneous/getLanguageData.api";
import getDeviceData from "./miscellaneous/getDeviceData.api";
import getBrowserData from "./miscellaneous/getBrowserData.api";

import getBranchSalesData from "./branch/getBranchSalesData.api";

import getCitySalesData from "./demographic/getCitySalesData.api";
import getCountrySalesData from "./demographic/getCountrySalesData.api";
import getDemographicAgeData from "./demographic/getDemographicAgeData.api";
import getDemographicGenderData from "./demographic/getDemographicGenderData.api";
import getDemographicAgeGenderData from "./demographic/getDemographicAgeGender.api";

import getCampaignConversionData from "./campaignConversion/getCampaignConversionData.api";

import getFilterBranchesData from "./filter/getFilterBranchesData.api";
import getFilterCountryCityData from "./filter/getFilterCountryCityData.api";

import getProductData from "./product/getProductData.api";
import getProductDetailData from './product/getProductDetailData.api';

import getProductCategoryData from "./product/getProductCategoryData.api";
import getProductCategoryDetailData from './product/category/getProductCategoryDetailData.api';
import getProductBrandData from "./product/getProductBrandData.api";
import getProductBrandDetailData from './product/brand/getProductBrandDetailData.api';
import getProductModelData from "./product/getProductModelData.api";
import getProductModelDetailData from './product/model/getProductModelDetailData.api';
import getProductListingData from "./product/getProductListingData.api";
import getSuspendedScanQrData from "./miscellaneous/getSuspendedScanQrData.api";
import getSuspendedListData from './product/getSuspendedListData.api'
import exportSuspendedListing from './product/exportSuspendedListing.api'

import getConsumerScanData from './consumer/getConsumerScanData.api';
import getConsumerListingData from './consumer/getConsumerListingData.api';
import exportConsumerListing from './consumer/exportConsumerListing.api';
import exportConsumerListingData from './consumer/exportConsumerListingData.api';

import getCampaignPerformanceData from './campaign/getCampaignPerformanceData.api';
import getCampaignSurveyListData from './campaign/getCampaignSurveyListData.api';
import getCampaignLuckyDrawListData from './campaign/getCampaignLuckyDrawListData.api';
import getCampaignInstantLuckyDrawListData from './campaign/getCampaignInstantLuckyDrawListData.api';
import getCampaignLoyaltyProgramListData from './campaign/getCampaignLoyaltyProgramListData.api'
import getCampaignWarrantyListData from './campaign/getCampaignWarrantyListData.api'
import getAllCampaignRespondentsData from './campaign/getAllCampaignRespondentsData.api';

import getDealerStockoutByBranchData from './stockout/getDealerStockoutByBranchData.api'
import getDealerStockoutByCategoryData from './stockout/getDealerStockoutByCategoryData.api'
import getDealerStockoutByBrandData from './stockout/getDealerStockoutByBrandData.api'
import getDealerStockoutByModelData from './stockout/getDealerStockoutByModelData.api'
import getDealerStockoutByProductData from './stockout/getDealerStockoutByProductData.api'
import getDealerStockoutData from './stockout/getDealerStockoutData.api';
import getDealerStockoutByEmployeeData from './stockout/getDealerStockoutByEmployeeData.api';

import getCampaignTypeSurveyDatalist from './campaign/getCampaignTypeSurveyDatalist.api'
import getRespondentsConsumerAllSurveyDatalist from './campaign/getRespondentsConsumerAllSurveyDatalist.api';
import getCampaignTypeLuckyDrawDatalist from './campaign/getCampaignTypeLuckyDrawDatalist.api';
import getRespondentsConsumerAllLuckyDrawDatalist from './campaign/getRespondentsConsumerAllLuckyDrawDatalist.api';
import getCampaignTypeInstantLuckyDrawDatalist from './campaign/getCampaignTypeInstantLuckyDrawDatalist.api';
import getRespondentsConsumerAllInstantLuckyDrawDatalist from './campaign/getRespondentsConsumerAllInstantLuckyDrawDatalist.api';
import getCampaignTypeLoyaltyProgramDatalist from './campaign/getCampaignTypeLoyaltyProgramDatalist.api';
import getRespondentsConsumerAllLoyaltyProgramDatalist from './campaign/getRespondentsConsumerAllLoyaltyProgramDatalist.api';
import getCampaignTypeWarrantyDatalist from './campaign/getCampaignTypeWarrantyDatalist.api';
import getRespondentsConsumerAllWarrantyDatalist from './campaign/getRespondentsConsumerAllWarrantyDatalist.api';

import getCampaignSummaryData from './campaign/getCampaignSummaryData.api';
import getCampaignTrendingData from './campaign/getCampaignTrendingData.api';
import getCampaignTypeData from './campaign/getCampaignTypeData.api';
import getCampaignProductData from './campaign/getCampaignProductData.api';
import getRespondentsSingleCampaignDatalist from './campaign/getRespondentsSingleCampaignDatalist.api'
import getCampaignPrizeDatalist from './campaign/getCampaignPrizeDatalist.api';

import getScanAgainstSuspendedData from './trending/getScanAgainstSuspendedData.api';
import getCampaignPrizeSummaryData from './campaign/getCampaignPrizeSummaryData.api';
import getWinnerParticipantDatalist from './campaign/getWinnerParticipantDatalist.api';

import getReportValidation from './validation/getReportValidation.api';
import getDealerBranchTitleData from './stockout/getDealerBranchTitleData.api';


const reportingApi = {

    // trending
    getTotalScanTrending,
    getTotalSuspendedTrending,
    getTotalDistributionPerformance,
    getTotalReportedTrending,
    getCampaignEngagedTrending,
    getActiveDealerTrending,
    getInactiveDealerTrending,
    getDealerStockOutTrending,
    getTotalRecallTrending,
    getNewDealerTrending,
    getTotalCampaignSurveyTrending,
    getScanAgainstSuspendedData,
    getRequestStockoutTrending,
    getTotalPackageTrending,
    getTotalRecalledPackageTrending,
    getItemTotalPackageTrending,
    getItemTotalRequestTrending,
    getItemTotalRejectTrending,
    getTopSuspendedProductTrending,
    getTopSuspendedQrTrending,

    // miscellaneous
    getPlatformData,
    getLanguageData,
    getDeviceData,
    getBrowserData,

    // branch table
    getBranchSalesData,

    // demographic
    getCitySalesData,
    getCountrySalesData,
    getDemographicAgeData,
    getDemographicGenderData,
    getDemographicAgeGenderData,

    // campaign conversion
    getCampaignConversionData,

    // filter
    getFilterBranchesData,
    getFilterCountryCityData,

    // product
    getProductData,
    getProductCategoryData,
    getProductBrandData,
    getProductBrandDetailData,
    getProductModelData,
    getProductModelDetailData,
    getProductListingData,
    getSuspendedScanQrData,
    getSuspendedListData,
    getProductDetailData,
    getProductCategoryDetailData,
    exportSuspendedListing,

    // campaign
    getAllCampaignRespondentsData,
    getCampaignPerformanceData,
    getCampaignSurveyListData,
    getCampaignLuckyDrawListData,
    getCampaignInstantLuckyDrawListData,
    getCampaignLoyaltyProgramListData,
    getCampaignWarrantyListData,

    getCampaignTypeSurveyDatalist,
    getRespondentsConsumerAllSurveyDatalist,
    getCampaignTypeLuckyDrawDatalist,
    getRespondentsConsumerAllLuckyDrawDatalist,
    getCampaignTypeInstantLuckyDrawDatalist,
    getRespondentsConsumerAllInstantLuckyDrawDatalist,
    getCampaignTypeLoyaltyProgramDatalist,
    getRespondentsConsumerAllLoyaltyProgramDatalist,
    getCampaignTypeWarrantyDatalist,
    getRespondentsConsumerAllWarrantyDatalist,

    getCampaignSummaryData,
    getCampaignTrendingData,
    getCampaignTypeData,
    getCampaignProductData,
    getRespondentsSingleCampaignDatalist,
    getCampaignPrizeDatalist,
    getCampaignPrizeSummaryData,
    getWinnerParticipantDatalist,

    // dealer - stockout
    getDealerStockoutByBranchData,
    getDealerStockoutByCategoryData,
    getDealerStockoutByBrandData,
    getDealerStockoutByModelData,
    getDealerStockoutByProductData,
    getDealerBranchTitleData,
    getDealerStockoutData,
    getDealerStockoutByEmployeeData,

    // consumer
    getConsumerScanData,
    getConsumerListingData,

    //exportexcel
    exportConsumerListing,
    exportConsumerListingData,
    // validation
    getReportValidation
};

export default reportingApi;