import { createAsyncThunk } from "@reduxjs/toolkit";
import reportingApi from "app/api/reporting";
import moment from "moment";

// (1) get all campaign respodents data -- start
export const getAllCampaignRespondentsData = createAsyncThunk(
    "reporting/getAllCampaignRespondentsData",
    async (payload, thunkApi) => {

        return reportingApi.getAllCampaignRespondentsData({
            length: payload.length,
            start: payload.start,
            search: payload.search,
            orderBy: payload.orderBy,
            orderDirection: payload.orderDirection,
            search_by: payload.searchBy,
            country: payload.countryCode,
            city_id: payload.city_uuid,
            branch_uuid: payload.branch_uuid,
            period: payload.period,
            start_date: moment(payload.start_date).format('YYYY-MM-DD'),
            end_date: moment(payload.end_date).format('YYYY-MM-DD'),
            url: payload.url,
        })
            .then((response) => response)
            .catch((error) => thunkApi.rejectWithValue(error));
    }
);
// (1) get all campaign respodents data -- end

// (2) get consumer scan data -- start
export const getConsumerScanData = createAsyncThunk(
    "reporting/getConsumerScanData",
    async (payload, thunkApi) => {
        return reportingApi.getConsumerScanData({
            length: payload.length,
            start: payload.start,
            search: payload.search,
            orderBy: payload.orderBy,
            orderDirection: payload.orderDirection,
            search_by: payload.searchBy,
            country: payload.countryCode,
            city_id: payload.city_uuid,
            branch_uuid: payload.branch_uuid,
            product_uuid: payload.product_uuid,
            period: payload.period,
            start_date: moment(payload.start_date).format('YYYY-MM-DD'),
            end_date: moment(payload.end_date).format('YYYY-MM-DD'),
            url: payload.url,
        })
            .then((response) => response)
            .catch((error) => thunkApi.rejectWithValue(error));
    }
);
// (2) get consumer scan data -- end

// (3) get consumer listing data -- start
export const getConsumerListingData = createAsyncThunk(
    "reporting/getConsumerListingData",
    async (payload, thunkApi) => {
        return reportingApi.getConsumerListingData({
            length: payload.length,
            start: payload.start,
            search: payload.search,
            orderBy: payload.orderBy,
            orderDirection: payload.orderDirection,
            search_by: payload.searchBy,
            country: payload.countryCode,
            city_id: payload.city_uuid,
            branch_uuid: payload.branch_uuid,
            product_uuid: payload.productUuid,
            period: payload.period,
            start_date: moment(payload.start_date).format('YYYY-MM-DD'),
            end_date: moment(payload.end_date).format('YYYY-MM-DD'),
            url: payload.url,
        })
            .then((response) => response)
            .catch((error) => thunkApi.rejectWithValue(error));
    }
);
// (3) get consumer listing data -- end
export const exportConsumerListingData = createAsyncThunk(
    "reporting/exportConsumerListingData",
    async (payload, thunkApi) => {
        return reportingApi.exportConsumerListingData({
            token: payload.token,
            length: payload.length,
            start: payload.start,
            search: payload.search,
            orderBy: payload.orderBy,
            orderDirection: payload.orderDirection,
            search_by: payload.searchBy,
            country: payload.countryCode,
            city_id: payload.city_uuid,
            branch_uuid: payload.branch_uuid,
            period: payload.period,
            start_date: moment(payload.start_date).format('YYYY-MM-DD'),
            end_date: moment(payload.end_date).format('YYYY-MM-DD'),
            url: payload.url,
        })
            .then((response) => response)
            .catch((error) => thunkApi.rejectWithValue(error));
    }
);

// (4) export excel consumer scan listing
export const exportConsumerListing = createAsyncThunk(
    "reporting/exportConsumerListing",
    async (payload, thunkApi) => {

        return reportingApi.exportConsumerListing({
            token: payload.token,
            search: payload.search,
            orderBy: payload.orderBy,
            orderDirection: payload.orderDirection,
            search_by: payload.searchBy,
            country: payload.countryCode,
            city_id: payload.city_uuid,
            branch_uuid: payload.branch_uuid,
            product_uuid: payload.product_uuid,
            period: payload.period,
            start_date: moment(payload.start_date).format('YYYY-MM-DD'),
            end_date: moment(payload.end_date).format('YYYY-MM-DD'),
            url: payload.url,
        })
            .then((response) => response)
            .catch((error) => thunkApi.rejectWithValue(error));
    }
);