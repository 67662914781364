import {
  submitSignupProfile,
  submitSignupProfileNoSignature,
  submitCompanyInfo,
  addPaymentMethod,
  setSignupPaymentMethod,
  setUserProfile,
  submitSignupCompany,
  fetchSignupData
} from "../action";
import { addAlert, setLoading } from "modules/notification";
import {
  goNextRegistrationStep,
  goToRegistrationStep,
} from "../slice/signupsteps.slice";
import { uploadMultipleFileToS3, uploadMultipleImageToS3 } from "app/feature";
import { UploadType } from "lib/constants/aws_s3";
import { unwrapResult } from "@reduxjs/toolkit";
import { fetchCompanyInfo, fetchLabel, setCompanyPendingApproval } from "modules/admin/redux";
import { LABEL_COMPANY, LABEL_PROFILE, LABEL_SUBSCRIPTION_PAYMENT, LABEL_VALUE_REJECTED } from "modules/admin/constants";
import { fetchProfile } from "modules/profile/redux";
import { v4 as uuidv4 } from "uuid";
import { setIsSubmit } from "../../redux/slice/signupsteps.slice";
import { getApiLang } from "app/feature/constants";

const submitSignupProfileMiddleware = ({
  dispatch,
  getState
}) => next => async action => {
  next(action);

  const { type, payload } = action;

  if (type === submitSignupProfile.type) {
    next(setLoading({ id: submitSignupProfile.type, state: true }));

    let arrayParams = [];
    let privacyPolicySignatureUrl;
    let endUserAgreementSignatureUrl;

    arrayParams.push({
      uploadType: UploadType.userProfilePrivacyPolicySignature,
      base64Image: payload.privacyPolicySignature,
      id: getState().session.idTokenPayload.sub,
      fileName: `privacyPolicy_${Date.now()}.png`
    });

    arrayParams.push({
      uploadType: UploadType.userProfileEndUserAgreementSignature,
      base64Image: payload.endUserAgreementSignature,
      id: getState().session.idTokenPayload.sub,
      fileName: `endUserAgreement_${Date.now()}.png`
    });

    await dispatch(uploadMultipleImageToS3(arrayParams))
      .then(unwrapResult)
      .then(result => {
        result.forEach(obj => {
          switch (obj.type) {
            case UploadType.userProfilePrivacyPolicySignature:
              privacyPolicySignatureUrl = obj.url.split("?")[0];
              break;
            case UploadType.userProfileEndUserAgreementSignature:
              endUserAgreementSignatureUrl = obj.url.split("?")[0];
              break;
            default:
              break;
          }
        });

        dispatch(
          setUserProfile({
            ...payload,
            privacyPolicySignatureUrl,
            endUserAgreementSignatureUrl
          })
        );
      })
      .catch(() => {
        next(setLoading({ id: submitSignupProfile.type, state: false }));
      });
  }
};

//To avoid submitting signatures when agreeing the privacy policy and user agreement, which is to deprecate the submitSignupProfileMiddleware
const submitSignupProfileNoSignatureMiddleware = ({
  dispatch,
  getState
}) => next => async action => {
  next(action);

  const { type, payload } = action;

  if (type === submitSignupProfileNoSignature.type) {
    next(setLoading({ id: submitSignupProfileNoSignature.type, state: true }));

    let privacyPolicySignatureUrl = "https://koodasiaprod.s3.ap-southeast-1.amazonaws.com/account/eb4dab7cd2204eff9ac0920958d68549/end_user_agreement/endUserAgreement_1663820692060.png";
    let endUserAgreementSignatureUrl = "https://koodasiaprod.s3.ap-southeast-1.amazonaws.com/account/eb4dab7cd2204eff9ac0920958d68549/privacy_policy/privacyPolicy_1663820692060.png";

    dispatch(
      setUserProfile({
        ...payload,
        privacyPolicySignatureUrl,
        endUserAgreementSignatureUrl
      })
    ).catch(() => {
      next(setLoading({ id: submitSignupProfile.type, state: false }));
    });
  }
};

const submitSignupCompanyMiddleware = ({
  dispatch,
  getState
}) => next => async action => {
  next(action);

  const { type, payload } = action;

  if (type === submitSignupCompany.type) {
    next(setLoading({ id: submitSignupCompany.type, state: true }));

    let articleAssociationUrl;
    let directorIdentificationUrl;
    let arrayParams = [];

    if (typeof payload.articleAssociation === "object") {
      const articleAssociationFileName = payload.articleAssociation.name;
      const articleAssociationlastDot = articleAssociationFileName.lastIndexOf(
        "."
      );

      arrayParams.push({
        uploadType: UploadType.companyArticleAssociation,
        file: payload.articleAssociation,
        id: getState().session.idTokenPayload.sub,
        fileName: `${articleAssociationFileName.slice(
          0,
          articleAssociationlastDot
        )}_${uuidv4()}${articleAssociationFileName.slice(
          articleAssociationlastDot
        )}`
      });
    } else {
      articleAssociationUrl = payload.articleAssociation;
    }

    if (typeof payload.directorIdentification === "object") {
      const directorIdentificationFileName =
        payload.directorIdentification.name;
      const directorIdentificationlastDot = directorIdentificationFileName.lastIndexOf(
        "."
      );

      arrayParams.push({
        uploadType: UploadType.companyDirectorIdentification,
        file: payload.directorIdentification,
        id: getState().session.idTokenPayload.sub,
        fileName: `${directorIdentificationFileName.slice(
          0,
          directorIdentificationlastDot
        )}_${uuidv4()}${directorIdentificationFileName.slice(
          directorIdentificationlastDot
        )}`
      });
    } else {
      directorIdentificationUrl = payload.directorIdentification;
    }

    await dispatch(uploadMultipleFileToS3(arrayParams))
      .then(unwrapResult)
      .then(result => {
        result.forEach(obj => {
          switch (obj.type) {
            case UploadType.companyArticleAssociation:
              articleAssociationUrl = obj.url.split("?")[0];
              break;
            case UploadType.companyDirectorIdentification:
              directorIdentificationUrl = obj.url.split("?")[0];
              break;
            default:
              break;
          }
        });

        dispatch(
          submitCompanyInfo({
            ...payload,
            articleAssociationUrl,
            directorIdentificationUrl
          })
        );
      })
      .catch(error => {
        next(setLoading({ id: submitSignupCompany.type, state: false }));
      })
  }
};

const setUserProfileMiddleware = ({ getState }) => next => action => {
  next(action);

  const { type, payload } = action;

  switch (type) {
    case setUserProfile.pending.type:
      next(setLoading({ id: setUserProfile.typePrefix, state: true }));
      break;

    case setUserProfile.fulfilled.type:
      next(setLoading({ id: submitSignupProfile.type, state: false }));
      next(setLoading({ id: setUserProfile.typePrefix, state: false }));
      break;

    case setUserProfile.rejected.type:
      const lang = getState().constant.languages;
      next(setLoading({ id: submitSignupProfile.type, state: false }));
      next(setLoading({ id: setUserProfile.typePrefix, state: false }));
      next(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
      break;

    default:
      break;
  }
}

const submitCompanyInfoMiddleware = ({ getState }) => next => action => {
  next(action);

  const { type, payload } = action;

  switch (type) {
    case submitCompanyInfo.pending.type:
      next(setLoading({ id: submitCompanyInfo.typePrefix, state: true }));
      break;

    case submitCompanyInfo.fulfilled.type:
      next(setLoading({ id: submitSignupCompany.type, state: false }));
      next(setLoading({ id: submitCompanyInfo.typePrefix, state: false }));
      break;

    case submitCompanyInfo.rejected.type:
      const lang = getState().constant.languages;
      next(setLoading({ id: submitSignupCompany.type, state: false }));
      next(setLoading({ id: submitCompanyInfo.typePrefix, state: false }));
      next(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
      break;

    default:
      break;
  }
}

const setSignupPaymentMethodMiddleware = ({ dispatch,getState }) => next => action => {
  next(action);

  const { type, payload } = action;

  switch (type) {
    case setSignupPaymentMethod.fulfilled.type:
      next(setLoading({ id: addPaymentMethod.type, state: false }));
      break;

    case setSignupPaymentMethod.rejected.type:
      dispatch(setIsSubmit(false));
      const lang = getState().constant.languages;
      next(setLoading({ id: addPaymentMethod.type, state: false }));
      next(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
      break;

    default:
      break;
  }
}

const registrationStepMiddleware = ({ dispatch, getState }) => next => action => {
  next(action);

  const { type } = action;

  switch (type) {

    case setUserProfile.fulfilled.type:
    case setSignupPaymentMethod.fulfilled.type:
      next(setCompanyPendingApproval())
      next(goNextRegistrationStep())
      break;
    case submitCompanyInfo.fulfilled.type:
      next(setCompanyPendingApproval())
      const labels = getState().session.labels
      const planLabel = labels.find(x => x.key === LABEL_SUBSCRIPTION_PAYMENT)

      if (planLabel && planLabel.value !== LABEL_VALUE_REJECTED) {
        next(goToRegistrationStep(3))
      }
      else {
        next(goNextRegistrationStep())
      }
      break;
    default:
      break;
  }
};

const addPaymentMethodMiddleware = ({
  dispatch,
  getState
}) => next => action => {
  if (action.type === addPaymentMethod.type) {
    // dispatch loading
    next(setLoading({ id: addPaymentMethod.type, state: true }));

    // const plans = getState().marketplace.subsciptionPlans;
    const selectedPlanGroup = getState().signupsteps.selectedPlanGroup;
    const selectedPlanGroupInterval = getState().signupsteps.selectedPlanGroupInterval;

    // dispatch backend call add payment method with stripe id
    // dispatch backend call add pre-subscription
    dispatch(
      setSignupPaymentMethod({
        pmTokenID: action.payload.pmTokenID,
        group: selectedPlanGroup,
        interval: selectedPlanGroupInterval
      })
    );
  } else {
    next(action);
  }
};

const fetchSignupDataMiddleware = ({ dispatch }) => next => async action => {
  next(action);

  const { type } = action;

  if (type === fetchSignupData.type) {
    await dispatch(fetchLabel())
      .then(unwrapResult)
      .then(result => {
        if (result) {
          if (result.some(label => label.key === LABEL_PROFILE)) {
            dispatch(fetchProfile());
          }

          if (result.some(label => label.key === LABEL_COMPANY)) {
            dispatch(fetchCompanyInfo());
          }
        }
      });
  }
};

export default [
  submitSignupProfileMiddleware,
  submitSignupProfileNoSignatureMiddleware,
  registrationStepMiddleware,
  addPaymentMethodMiddleware,
  setSignupPaymentMethodMiddleware,
  submitSignupCompanyMiddleware,
  fetchSignupDataMiddleware,
  setUserProfileMiddleware,
  submitCompanyInfoMiddleware
];
