import React, { Suspense, lazy, useEffect } from "react";
import SplashScreen from "modules/admin/components/screen/splash";
import Public from "modules/public";
import Notification from "modules/notification";
import Trackco from "modules/trackco";
import { compose } from "@reduxjs/toolkit";
import { ROOT, MOD_ADMIN, LIVE_DRAW, TRACKCO } from "./config/route";
import { createBrowserHistory } from "history";
import { Router, Switch, Route } from "react-router-dom";
import { withRecaptcha, withStore, withTheme } from "./utils";
import { checkSession, isSessionChecked } from "modules/admin";
import { connect, useDispatch } from "react-redux";
import BackDrop from "modules/backdrop";
import { LiveDrawPage } from "modules/lucky-draw/pages";
import {
  getIndustries,
  getCountry,
  getLanguages,
  getApiLanguages,
} from "./feature/constants";
import { getTimezone } from "modules/public/redux/action";
import { setCheckedSession } from "modules/admin/redux";
import { setFallbackLanguages } from "modules/public/redux/slice/constant.slice";
import MaintenancePage from "modules/admin/pages/maintenance";

const Admin = lazy(() => import("modules/admin"));

const history = createBrowserHistory();

function App({
  isSessionChecked,
  getIndustries,
  getTimezone,
  getCountry,
  getLanguages,
  getApiLanguages,
  setFallbackLanguages,
}) {
  const dispatch = useDispatch();

  useEffect(() => {
    getIndustries();
    getTimezone();
    getCountry();
    getLanguages();
    getApiLanguages();
    setFallbackLanguages();

    const sessionId = localStorage.getItem("session_id");
    const sessionExpiration = localStorage.getItem("session_expiration");

    if (sessionId && sessionExpiration) {
      dispatch(
        checkSession({
          sessionId,
          sessionExpiration,
        })
      );
    } else {
      dispatch(setCheckedSession(true));
    }
  }, [
    dispatch,
    getIndustries,
    getTimezone,
    getCountry,
    getLanguages,
    getApiLanguages,
    setFallbackLanguages,
  ]);

  if (!isSessionChecked) return <SplashScreen />;

  return (
    <Router history={history}>
      <Suspense fallback={<SplashScreen />}>
        <Notification />
        <BackDrop />
        <Switch>
          <Route exact path="/maintenance">
            <MaintenancePage />
          </Route>
          <Route path={LIVE_DRAW} component={LiveDrawPage} />
          <Route path={MOD_ADMIN} component={Admin} />
          <Route path={TRACKCO} component={Trackco} />
          <Route path={ROOT} component={Public} />
        </Switch>
      </Suspense>
    </Router>
  );
}

const mapDispatchToProps = (dispatch) => ({
  getIndustries: () => dispatch(getIndustries()),
  getTimezone: () => dispatch(getTimezone()),
  getCountry: () => dispatch(getCountry()),
  getLanguages: () => dispatch(getLanguages()),
  getApiLanguages: () => dispatch(getApiLanguages()),
  setFallbackLanguages: () => dispatch(setFallbackLanguages()),
});

const mapStateToProps = (state) => ({
  isSessionChecked: isSessionChecked(state),
});

export default compose(
  withStore,
  withRecaptcha,
  withTheme,
  connect(mapStateToProps, mapDispatchToProps)
)(App);
