import React from "react";
import { makeStyles, ButtonBase, Typography, Box, Button } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import clsx from "clsx";
import ClearIcon from '@material-ui/icons/Clear';

const useStyle = makeStyles((theme) => ({
  root: {
    padding: "5px 12px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#ECEFF0",
    borderRadius: "4px",
    width: "100%",
    height: 43
  },
  inputText: {
    fontSize: "1rem",
    color: "#A4A6A8",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  },
  filled: {
    color: "rgba(0, 0, 0, 0.87)"
  },
  clearButton: {
    minWidth: 0
  }
}));

export default function CategorySelectComponent({
  anchorRef,
  handleClick,
  value,
  placeholder,
  handleClear,
  isOpenList,
  style,
  disabled,
  textStyle,
  isClearable
}) {
  const classes = useStyle();
  const isEmpty = (value === null || value.length === 0) ? true : false;

  return (
    <ButtonBase
      className={classes.root}
      disableRipple
      ref={anchorRef}
      onClick={handleClick} 
      style={{ paddding: 12, ...style }}>
      <Typography
        variant="body2"
        className={clsx(classes.inputText, !isEmpty && classes.filled)}
        style={textStyle}
      >
        {(!value || !value.name) ? placeholder : value.name}
      </Typography>
      <Box
        style={{
          display: "flex",
          alignItems: "center"
        }}
      >
        {!isEmpty &&
          <Button
            className={classes.clearButton}
            onClick={(e) => {
              e.stopPropagation();
              handleClear();
            }}
          >
            <ClearIcon style={{ fontSize: 16 }} />
          </Button>
        }
        {(isOpenList ? (
          <ArrowDropUpIcon fontSize='small' />
        ) : (
          <ArrowDropDownIcon fontSize='small' />
        ))}
      </Box>
    </ButtonBase>
  );
}
