import React from "react";
import { makeStyles, Box, Typography } from "@material-ui/core";
import { getLang } from "app/feature/constants";
import BgImg from "assets/img/covered_warranty_BG.png";

const useStyle = makeStyles((theme) => ({
  container: {
    position: "relative",
    borderRadius: 16,
    minHeight: 143,
    display: "flex",
    justifyContent: "space-between",
  },
  bgIcon: {
    position: "absolute",
    top: 0,
    right: 0,
    height: "100%",
    zIndex: 0,
  },
  greyText: {
    color: "#20202073",
  },
  button: {
    textWrap: "nowrap",
    backgroundColor: "#632FF9",
    borderRadius: 100,
    padding: "6px 16px",
    color: "white",
    fontWeight: "bold",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#5728de",
    },
  },
}));

export default function WarrantyComponent({ lang, userLanguage }) {
  const classes = useStyle();

  return (
    <Box p={2}>
      <Box
        className={classes.container}
        style={{
          background: "linear-gradient(100.01deg, #F2FFF2 0%, #D8FFD8 88.17%)",
        }}
      >
        <img src={BgImg} alt="bg" className={classes.bgIcon} />
        <Box flexDirection="column" display="flex" p={2} zIndex={1}>
          <Box
            flex={1}
            display="flex"
            justifyContent="center"
            flexDirection="column"
            pb={1}
          >
            <Box display="flex" alignItems="center">
              <Typography
                variant="body2"
                className={classes.greyText}
                style={{ marginRight: 4 }}
              >
                {getLang(lang, "label.WARRANTY_STATUS")}
              </Typography>
            </Box>
            <Typography variant="h6" style={{ color: "#15CB1C", fontSize: 18 }}>
              <b>{getLang(lang, "label.COVERED_WARRANTY")}</b>
            </Typography>
          </Box>
          <Box>
            <Typography variant="body2">
              {getLang(lang, "label.VALID_UNTIL")}: 20 May 2024
            </Typography>
          </Box>
        </Box>
        <Box alignSelf="flex-end" pb={1.5} pr={1.5} zIndex={1}>
          <Box className={classes.button}>
            {getLang(lang, "label.CLAIM_WARRANTY")}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
