import axios from "axios"

const url = "/account/api/v1/company/currency/get-unused-dropdown-list";

function getUnusedCurrencyConfigData({
  start, length, search
}) {
  return new Promise((resolve, reject) => {
    axios.get(url, {
      params: {
        start, length, search
      }
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data))
  })
}

export default getUnusedCurrencyConfigData;