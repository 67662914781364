import React from 'react'
import {
    Box,
    makeStyles,
    Typography
} from '@material-ui/core'
import DrawButton from "assets/img/drawButton.png"
import clsx from 'clsx'
import TextMessage from "components/transition/textMessage"
import { getLang } from 'app/feature/constants'

const useStyle = makeStyles(theme => ({
    drawContainer: {
        border: "3px solid #FF6A23",
        margin: "15px 0px",
        backgroundColor: "white",
        borderRadius: 7,
    },
    drawedContainer: {
        margin: "30px 0px"
    },
    drawButton: {
        maxWidth: 180,
        cursor: "pointer"
    },
    unitLabel: {
        color: "#FF7D2C",
        fontSize: 20,
        fontWeight: "bold"
    },
    drawedUnitLabel: {
        fontSize: 14,
        fontWeight: 500,
        background: "linear-gradient(180deg, #FF7D1F 6.25%, #FF2775 100%)",
        borderRadius: 7,
        color: "white",
        position: "absolute",
        top: -17,
        left: 15,
        padding: "3px 15px",
        height: 25,
        display: "flex",
        alignItems: "center"
    },
    winnerName: {
        padding: "13px 20px",
        borderBottom: "1px solid #FF6A23",
        display: "flex",
        justifyContent: "center"
    },
    serialNumberContainer: {
        display: "flex",
        padding: 10
    },
    serialNumberLabel: {
        width: "50%",
        textAlign: "right",
        paddingRight: 15,
        fontSize: 18
    },
    serialNumberValue: {
        width: "50%",
        textAlign: "left",
        paddingLeft: 15,
        color: "#FF6A23",
        fontSize: 18,
        fontWeight: "bold"
    },
    textEllipsis: {
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        fontSize: "22px",
        fontWeight: "bold"
    }
}))

export default function LiveDrawPanelComponent({
    index,
    winnerName,
    serialNumber,
    contactNumber,
    isDraw,
    handleDraw,
    lang
}) {
    const classes = useStyle()
    const phoneNumber = contactNumber.substr(-4)

    return (
      <Box
        className={clsx(classes.drawContainer, {
          [classes.drawedContainer]: isDraw,
        })}
      >
        {isDraw ? (
          <Box
            style={{
              position: "relative",
            }}
          >
            <Typography className={classes.drawedUnitLabel}>
              {getLang(lang,"label.UNIT")} {index + 1}
            </Typography>
            <Box className={classes.winnerName}>
              <Typography className={classes.textEllipsis}>
                <TextMessage text={winnerName} />
              </Typography>
              <Typography style={{ fontSize: "22px", fontWeight: "bold" }}>
                &nbsp;-&nbsp;
                <TextMessage text={phoneNumber} />
              </Typography>
            </Box>

            <Box className={classes.serialNumberContainer}>
              <Typography
                className={classes.serialNumberLabel}
                color="textSecondary"
              >
                {getLang(lang,"label.SERIAL_NUMBER")}
              </Typography>
              <Typography className={classes.serialNumberValue}>
                <TextMessage text={serialNumber} />
              </Typography>
            </Box>
          </Box>
        ) : (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "20px 25px"
            }}
          >
            <Typography className={classes.unitLabel}>
              {getLang(lang,"label.UNIT")} {index + 1}
            </Typography>
            <img
              src={DrawButton}
              alt="drawButton"
              className={classes.drawButton}
              onClick={handleDraw}
            />
          </Box>
        )}
      </Box>
    );
}
