import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import VoidWarrantyDialogComponent from "./voidWarrantyDialog.component";
import {getLang} from "../../../../../app/feature/constants";

function VoidWarrantyDialogContainer({ isOpen, handleClose, handleSubmit }) {
  const lang = useSelector(state => state.constant.languages);

  const reasons = [
    getLang(lang, "paragraph.VOID_REASON1"),
    getLang(lang, "paragraph.VOID_REASON2"),
  ]

  return (
    <VoidWarrantyDialogComponent
      isOpen={isOpen}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      reasons={reasons}
      lang={lang}
    />
  );
}

VoidWarrantyDialogContainer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default VoidWarrantyDialogContainer;
