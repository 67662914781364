import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { validateEmailVertificationToken } from "modules/trackco/redux";
import { addAlert, selectLoading } from 'modules/notification';
import SignUpVerificationSuccessPage from "./signup-verification-success.page";
import { getLang } from "app/feature/constants";

function SignUpVerificationSuccessContainer() {
  const dispatch = useDispatch();
  const history = useHistory();
  const search = useLocation().search;
  const email = new URLSearchParams(search).get('email');
  const emailVerificationToken = new URLSearchParams(search).get('email_verification_token');
  const [isValidate, setIsValidate] = useState(false);
  const isValidateEmailVerificationTokenLoading = useSelector(state => selectLoading(state, validateEmailVertificationToken.typePrefix))
  const lang = useSelector(state => state.constant.languages);

  useEffect(() => {
    if(email !== "" && emailVerificationToken !== "") {
      dispatch(validateEmailVertificationToken({ email: email, emailVerificationToken: emailVerificationToken }))
        .then((response) => {
          if(!response.payload.data.is_valid) {
            dispatch(addAlert({ severity: "error", message: getLang(lang, 'message.error.VALIDATED_VERIFICATION_TOKEN')}))
            history.push("/404")
          }
          else {
            setIsValidate(true)
          }
        })
        .catch((error) => {
          dispatch(addAlert({ severity: "error", message: getLang(lang, 'message.error.FAIL_VALIDATE_TOKEN') }))
          history.push("/404")
        })
    }
    // eslint-disable-next-line
  },[email, emailVerificationToken, dispatch, history])

  return <SignUpVerificationSuccessPage
    isValidateEmailVerificationTokenLoading={isValidateEmailVerificationTokenLoading}
    isValidate={isValidate}
    lang={lang}
  />
}

export default SignUpVerificationSuccessContainer;