import React, { useState, useEffect } from "react"
import { ROOT as CONSUMER_ROOT } from "modules/consumer"
import GiftRedemptionPanelComponent from "./giftPointRedemptionPanel.component"
import qs from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { getLang } from "app/feature/constants";

function GiftPointRedemptionPanelContainer({ id }) {
  const history = useHistory()
  const location = useLocation()
  const lang = useSelector(state => state.constant.languages)
  const [activeIndex, setActiveIndex] = useState(null)

  let tabLabels = [
    getLang(lang, "label.GIFT_REDEMPTION"), 
    getLang(lang, "label.LOYALTY_POINT_HISTORY"), 
  ]
  
  const qsParams = qs.parse(location.search);
  const currentSearch = qsParams.tab || '';

  useEffect(() => {
    let tempIndex= 0
    switch(currentSearch) {
      case "gift": 
        tempIndex = 0
        break;
      case "point": 
        tempIndex = 1
        break;
      default:
        tempIndex = 0
    }
    setActiveIndex(tempIndex)
  }, [currentSearch])

  const handleChange = (event, index) => {
    setActiveIndex(index)

    let tab = "gift"
    if (index === 1) {
      tab = "point"
    } 
    history.replace({
      pathname: `${CONSUMER_ROOT}/${id}`,
      search: `?tab=${tab}`
    })
  }

  return (
    <GiftRedemptionPanelComponent 
      tabLabels={tabLabels}
      activeIndex={activeIndex}
      handleChange={handleChange}
      lang={lang}
    />
  )
}

export default GiftPointRedemptionPanelContainer
