import { createSlice } from "@reduxjs/toolkit";
import { COUNTERFEIT_REPORT } from "../../constants";
import { updateCounterfeitReportDetail, getCounterfeitReportList } from "../action";

const initialState = {
  CounterfeitReportTable: {
    data: [],
    totalRecords: 0,
    filteredTotalRecords: 0
  },
  isLoadingCounterfeitReportError: false,
  CounterfeitReportDetail: {
    consumerName: "",
    serialNumber: "",
    productName: "",
    contact: 0,
    email: "",
    purchasedFrom: "",
    purchasedDate: "",
    longitude: "",
    latitude: "",
    consumerRemarks: "",
    adminRemarks: "",
    status: "",
    createdAt: "",
    updatedAt: "",
    deletedAt: "",
    refCode: "",
    imageUrl: ""
  }
};

const counterfeitReportSlice = createSlice({
  name: COUNTERFEIT_REPORT,
  initialState,
  reducers: {
    setCounterfeitReportList(state, action) {
      const { payload } = action;
      const tempData = [];

      state.CounterfeitReportTable.totalRecords = payload.recordsTotal;
      state.CounterfeitReportTable.filteredTotalRecords =
        payload.recordsFiltered;

      payload.data.forEach((element) => {
        const order = {
          consumerName: element.consumer_name || "",
          serialNumber: element.serial_number || "",
          productName: element.product_name || "",
          phoneNumber: element.phone_number || "",
          email: element.email || "",
          purchasedFrom: element.purchased_from || "",
          purchasedDate: element.purchased_date || "",
          longitude: element.longitude || "",
          latitude: element.latitude || "",
          consumerRemarks: element.consumer_remarks || "",
          adminRemarks: element.admin_remarks || "",
          status: element.status || "",
          createdAt: element.created_at || "",
          refCode: element.ref_code || ""
        };

        tempData.push(order);
      });

      state.CounterfeitReportTable.data = tempData;
    },
    setCounterfeitDetail(state, action) {
      const { payload } = action;

      state.CounterfeitReportDetail.consumerName = payload.consumer_name || state.CounterfeitReportDetail.consumerName;
      state.CounterfeitReportDetail.serialNumber = payload.serial_number|| state.CounterfeitReportDetail.serialNumber;
      state.CounterfeitReportDetail.productName = payload.product_name|| state.CounterfeitReportDetail.productName;
      state.CounterfeitReportDetail.contact = payload.contact|| state.CounterfeitReportDetail.contact;
      state.CounterfeitReportDetail.email = payload.email|| state.CounterfeitReportDetail.email;
      state.CounterfeitReportDetail.purchasedFrom = payload.purchased_from|| state.CounterfeitReportDetail.purchasedFrom;
      state.CounterfeitReportDetail.purchasedDate = payload.purchased_date|| state.CounterfeitReportDetail.purchasedDate;
      state.CounterfeitReportDetail.longitude = payload.longitude|| state.CounterfeitReportDetail.longitude;
      state.CounterfeitReportDetail.latitude = payload.latitude|| state.CounterfeitReportDetail.latitude;
      state.CounterfeitReportDetail.consumerRemarks = payload.consumer_remarks|| "";
      state.CounterfeitReportDetail.adminRemarks = payload.admin_remarks|| "";
      state.CounterfeitReportDetail.status = payload.status|| state.CounterfeitReportDetail.status;
      state.CounterfeitReportDetail.createdAt = payload.created_at|| state.CounterfeitReportDetail.createdAt;
      state.CounterfeitReportDetail.updatedAt = payload.updated_at|| state.CounterfeitReportDetail.updatedAt;
      state.CounterfeitReportDetail.deletedAt = payload.deleted_at|| state.CounterfeitReportDetail.deletedAt;
      state.CounterfeitReportDetail.refCode = payload.ref_code|| state.CounterfeitReportDetail.refCode;
      state.CounterfeitReportDetail.imageUrl = payload.image_url|| state.CounterfeitReportDetail.imageUrl;
    },
    setCounterfeitDetailRefCode(state, action) {
      const { payload } = action;
      state.CounterfeitReportDetail.refCode = payload|| state.refCode;
    },
    resetCounterfeitDetail(state) {
        state.CounterfeitReportDetail = initialState.CounterfeitReportDetail;
    },
  },
  extraReducers: {
    [updateCounterfeitReportDetail.fulfilled]: (state, action) => {
      const { status, adminRemarks } = action.meta.arg;
      state.CounterfeitReportDetail.status = status;
      state.CounterfeitReportDetail.adminRemarks = adminRemarks;
    },
    [getCounterfeitReportList.fulfilled]: (state) => {
      state.isLoadingCounterfeitReportError = false;
    },
    [getCounterfeitReportList.rejected]: (state) => {
      state.isLoadingCounterfeitReportError = true;
    }
  }
});

export const {
  setCounterfeitReportList,
  setCounterfeitDetail,
  setCounterfeitDetailRefCode,
  resetCounterfeitDetail
} = counterfeitReportSlice.actions;
export const counterfeitReportReducer = counterfeitReportSlice.reducer;
