import { selectLoading } from "modules/notification";
import { getProductBrandDetailData } from "modules/reporting/redux";
import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProductBrandDetailPanel from './productBrandDetailPanel.component';

export default function ProductBrandDetailPanelContainer({
    currentFilter,
    urlProductBrandDetail,
    translate
}){
    const dispatch = useDispatch();

    // (1) get product brand detail data -- start
    const isFetchingProductBrandDetailData = useSelector(state => selectLoading(state, getProductBrandDetailData.typePrefix));
    const productBrandDetailData = useSelector(state => state.reporting.product.productBrandDetailData);
    // (1) get product brand detail data -- end

    useEffect(() => {
        dispatch(getProductBrandDetailData({
            branch_uuid: currentFilter.branchUuid,
            brand_uuid: currentFilter.brandUuid,
            period: currentFilter.period,
            start_date: moment(currentFilter.dateRange[0]).format('YYYY-MM-DD'),
            end_date: moment(currentFilter.dateRange[1]).format('YYYY-MM-DD'),
            url: urlProductBrandDetail
        }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentFilter, dispatch])

    return (
        <ProductBrandDetailPanel 
            status={{
                isFetchingProductBrandDetailData
            }}

            data={{
                productBrandDetailData
            }}

            currentFilter={currentFilter}

            translate={translate}
        />
    );
}