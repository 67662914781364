import axios from "axios";

export function createRequestTransfer(parameters) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/distributor/admin/api/v1/request-transfer/save-request`, {
        from_branch_uuid: parameters.from_branch_uuid,
        to_branch_uuid: parameters.to_branch_uuid,
        address_uuid: parameters.address_uuid,
        remarks: parameters.remarks,
        term: parameters.term,
        requests: parameters.requests,
      })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data));
  });
}
