import React from "react";
import { makeStyles, Backdrop, Fade, Dialog } from "@material-ui/core";

const style = theme => ({
  root: {

    paper: {
      backgroundColor: 'transparent'
    }
  }
});

const useStyle = makeStyles(style);
export default function ModalComponent({ isOpen, handleClose, maxWidth, children }) {
  const classes = useStyle();
  return (
    <Dialog
      className={classes.paper}
      open={isOpen}
      onClose={handleClose}
      scroll="body"
      closeAfterTransition
      BackdropComponent={Backdrop}
      maxWidth={maxWidth}
    >
      <Fade in={isOpen} style={{ outline: "none" }} >
        <div>{children}</div>
      </Fade>
    </Dialog>
  );
}
