import axios from 'axios';

const url =  "/serialnumber/api/v1/product/attribute/create";

export function addAttribute({
  name,
  options
}) {
  return new Promise((resolve, reject) => {
    axios.post(url, {
      name: name,
      options: options,
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data))
  })
}