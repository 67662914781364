import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetPassword } from "../../../redux/action";
import { useHistory } from "react-router-dom";
import NewPasswordFormComponent from "./newPasswordForm.component";
import { unwrapResult } from "@reduxjs/toolkit";
import { RESET_PASSWORD_SUCCESS } from "modules/public/config/public.route";
import { selectLoading } from "modules/notification";

export default function NewPasswordFormContainer({
  email,
  token
}) {
  const history = useHistory()
  const dispatch = useDispatch()
  const lang = useSelector(state => state.constant.languages)
  const isLoading = useSelector(state => selectLoading(state, resetPassword.typePrefix))
  const [ isShowPassword, setIsShowPassword ] = useState(false)

  const handleClickShowPassword = (type) => {
      setIsShowPassword(prev => !prev)
  }

  const handleMouseDownPassword = event => {
    event.preventDefault()
  }

  const handleSubmit = async values => {
    dispatch(resetPassword({
      email,
      token,
      password: values.password,
      confirmPassword: values.confirmPassword
    }))
      .then(unwrapResult)
      .then(() => {
        history.push(RESET_PASSWORD_SUCCESS)
      })
  };
  return (
    <NewPasswordFormComponent
      email={email}
      handleSubmit={handleSubmit}
      isLoading={isLoading}
      lang={lang}
      handleClickShowPassword={handleClickShowPassword}
      handleMouseDownPassword={handleMouseDownPassword}
      isShowPassword={isShowPassword}
    />
  );
}