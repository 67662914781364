import React, { useRef } from "react";
import {
  makeStyles,
  Typography,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  InputAdornment,
  Box,
  Button,
  Divider,
  MenuItem,
  MenuList,
  Checkbox,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import InputTextField from "components/input/inputTextField";
import DefaultImage from "assets/img/defaultPhoto.png";
import { getLang } from "app/feature/constants";
import InfiniteScroll from "react-infinite-scroll-component";
import LoadingComponent from "components/loading";

const useStyle = makeStyles((theme) => ({
  paper: {
    width: 425,
  },
  button: {
    padding: 0,
    minWidth: 0,
  },
  profile: {
    height: 26,
    width: 26,
    minWidth: 26,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& img": {
      height: "100%",
      minHeight: "100%",
      minWidth: "100%",
      objectFit: "cover",
      borderRadius: "50%",
      border: "1px solid #D0D5DD",
    },
  },
  list: {
    maxHeight: "30vh",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "5px",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "16px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#E4E7EC",
      borderRadius: "16px",
    },
  },
}));

export default function ConsumerSelectPanelComponent({
  selectAll,
  // value,
  anchorRef,
  open,
  isOpenList,
  handleClose,
  isFetching,
  consumerDropdown,
  handleSelectAll,
  // handleSelectConsumer,
  handleSelectedConsumer,
  handleSearch,
  lang,
  handleFetchMoreItems,
  totalFiltered,
}) {
  const classes = useStyle();
  const textRef = useRef();

  return (
    <Popper
      open={open}
      style={{ zIndex: 99 }}
      anchorEl={anchorRef.current}
      role={undefined}
      transition
      disablePortal
      placement="bottom-start"
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === "bottom-start" ? "center top" : "center bottom",
          }}
        >
          <Paper className={classes.paper}>
            <ClickAwayListener onClickAway={handleClose}>
              <Box>
                <Box py={0.5}>
                  <InputTextField
                    variant="filled"
                    size="small"
                    fullWidth
                    inputRef={textRef}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          style={{ paddingRight: 6 }}
                        >
                          <Button
                            onClick={() => handleSearch(textRef.current.value)}
                            className={classes.button}
                          >
                            <SearchIcon />
                          </Button>
                        </InputAdornment>
                      ),
                      style: {
                        backgroundColor: "#fff",
                        paddingRight: 0,
                      },
                    }}
                    inputProps={{
                      onKeyDown: (e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                          handleSearch(textRef.current.value);
                          return false;
                        }
                      },
                      style: {
                        padding: 8,
                      },
                    }}
                    placeholder={getLang(lang, "placeholder.SEARCH")}
                  />
                </Box>
                <Divider />
                  {
                  consumerDropdown.length > 0 ? (
                      <InfiniteScroll
                        height="100%"
                        className={classes.list}
                        dataLength={consumerDropdown.length}
                        next={handleFetchMoreItems}
                        hasMore={totalFiltered > consumerDropdown.length}
                        loader={<LoadingComponent height={100} />}
                        endMessage={
                          isFetching ? (
                            <LoadingComponent height={100} />
                          ) : !consumerDropdown.length ? (
                            <Box px={1.5} py={4}>
                              <Typography
                                variant="body2"
                                style={{ textAlign: "center" }}
                              >
                                <b>{getLang(lang, "label.NO_PRODUCT")}</b>
                              </Typography>
                            </Box>
                          ) : null
                        }
                      >
                        <MenuList
                          autoFocusItem={isOpenList}
                          id="menu-list-grow"
                          onClick={() => {
                            handleSelectAll();
                          }}
                          style={{ padding: 0 }}
                        >
                          <MenuItem style={{ padding: 0 }}>
                            <Checkbox checked={selectAll} />
                            <Typography
                              variant="inherit"
                              noWrap
                              style={{ paddingLeft: "8px" }}
                            >
                              {getLang(lang, "label.ALL")}
                            </Typography>
                          </MenuItem>
                        </MenuList>
                        <Divider />
                        <Box minHeight="25vh" overflow="auto">
                          {consumerDropdown.map((consumer, index) => {
                            return (
                              <React.Fragment key={index}>
                                <MenuItem
                                  style={{ padding: 0 }}
                                  // onClick={() => handleSelectConsumer(consumer)}
                                >
                                  <Checkbox
                                    // checked={
                                    //   !!value.find((v) => v === consumer.uuid)
                                    // }
                                    checked={ consumer.isSelected }
                                    onChange={(event) => {
                                      handleSelectedConsumer(index, event.target.checked)
                                    }}
                                  />
                                  <Box display="flex" alignItems="center">
                                    <Box
                                      className={classes.profile}
                                      mx={1}
                                      position="relative"
                                    >
                                      {!!consumer.picture ? (
                                        <img
                                          src={consumer.picture}
                                          alt={consumer.name}
                                        />
                                      ) : (
                                        <img
                                          src={DefaultImage}
                                          alt="default profile"
                                        />
                                      )}
                                    </Box>
                                    <Typography
                                      variant="inherit"
                                      noWrap
                                      style={{ paddingLeft: "8px" }}
                                    >
                                      {consumer.name}
                                    </Typography>
                                  </Box>
                                </MenuItem>
                              </React.Fragment>
                            );
                          })}
                        </Box>
                      </InfiniteScroll>
                  ) : (
                    <Box p={2} style={{ textAlign: "center" }}>
                      <Typography variant="body1">
                        {getLang(lang, "paragraph.NO_CONSUMER_EXIST")}
                      </Typography>
                    </Box>
                  )}
              </Box>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}
