import stockflowDealerMdl from "./stockflowDealer.mdl";
import stockflowRewardCenterMdl from "./stockflowRewardCenter.mdl";
import stockflowIncentiveProgramMdl from "./stockflowIncentiveProgram.mdl";
import stockflowStockoutMdl from "./stockflowStockout.mdl";
import stockflowRecallMdl from "./stockflowRecall.mdl";
import stockflowInventoryMdl from "./stockflowInventory.mdl";
import stockflowOrderMdl from "./stockflowOrder.mdl";
import stockflowTransfer from "./stockflowTransfer.mdl";
import stockflowReceiveTransfer from "./stockflowReceiveTransfer.mdl";
import stockflowRequestTransfer from "./stockflowRequestTransfer.mdl";
import stockflowCheckout from "./stockflowCheckout.mdl";
import stockflowStockTake from "./stockflowStockTake.mdl";
import stockflowCourier from "./stockflowCourier.mdl";

export default [
  ...stockflowDealerMdl,
  ...stockflowRewardCenterMdl,
  ...stockflowIncentiveProgramMdl,
  ...stockflowStockoutMdl,
  ...stockflowRecallMdl,
  ...stockflowInventoryMdl,
  ...stockflowOrderMdl,
  ...stockflowTransfer,
  ...stockflowReceiveTransfer,
  ...stockflowRequestTransfer,
  ...stockflowCheckout,
  ...stockflowStockTake,
  ...stockflowCourier,
];
