import axios from "axios";

const ENDPOINT_URL = "/payment/api/v1/transactions/history";

const get = (parameters) => {
  return new Promise((resolve, reject) => {
    axios
      .get(ENDPOINT_URL, {
        params: {
          length: parameters.length,
          start: parameters.start,
          search: parameters.search,
          order: parameters.order,
          column: parameters.column,
          draw: parameters.draw,
          year: parameters.year,
          month: parameters.month,
          type: parameters.type,
          user_sub: parameters.userSub
        }
      })
      .then((response) => resolve(response))
      .catch((error) => {
        return reject(error.message);
      });
  });
};

const viewEmployeeTransaction = {
  get
};

export default viewEmployeeTransaction;
