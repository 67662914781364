import axios from "axios"

const url = "/account/api/v1/company/currency/get-configured-dropdown-list"

export function getConfiguredCurrencyDropdownList({
  search
}) {
  return new Promise((resolve, reject) => {
    axios.get(url, {
      params: {
        search: search
      }
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data))
  })
}