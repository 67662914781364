import axios from "axios";

export function getBrandDropdown({
  search
}) {
  const url =  `/serialnumber/api/v1/product/brand-models/get-brand-dropdown-list`;

  return new Promise((resolve, reject) => {
    axios.get(url, {
      search
    })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data))
  })
}