import { getApiLang } from "app/feature/constants";
import { setLoading, addAlert } from "modules/notification";
import {
  getAcceptedOrderList,
  getCancelledOrderList,
  getNewOrderList,
  getOrderDetail,
  getOrderReceiverDropdown,
  getOrderRequestorDropdown,
  getRejectedOrderList,
} from "../action/stockflowOrder.action";

const getNewOrderListMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getNewOrderList.pending.type:
        dispatch(setLoading({ id: getNewOrderList.typePrefix, state: true }));
        break;
      case getNewOrderList.fulfilled.type:
        dispatch(setLoading({ id: getNewOrderList.typePrefix, state: false }));
        break;
      case getNewOrderList.rejected.type:
        const lang = getState().constant.languages;
        dispatch(setLoading({ id: getNewOrderList.typePrefix, state: false }));
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_GET_ORDER_LIST"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const getAcceptedOrderListMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getAcceptedOrderList.pending.type:
        dispatch(
          setLoading({ id: getAcceptedOrderList.typePrefix, state: true })
        );
        break;
      case getAcceptedOrderList.fulfilled.type:
        dispatch(
          setLoading({ id: getAcceptedOrderList.typePrefix, state: false })
        );
        break;
      case getAcceptedOrderList.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({ id: getAcceptedOrderList.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_GET_ORDER_LIST"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const getRejectedOrderListMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getRejectedOrderList.pending.type:
        dispatch(
          setLoading({ id: getRejectedOrderList.typePrefix, state: true })
        );
        break;
      case getRejectedOrderList.fulfilled.type:
        dispatch(
          setLoading({ id: getRejectedOrderList.typePrefix, state: false })
        );
        break;
      case getRejectedOrderList.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({ id: getRejectedOrderList.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_GET_ORDER_LIST"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const getCancelledOrderListMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getCancelledOrderList.pending.type:
        dispatch(
          setLoading({ id: getCancelledOrderList.typePrefix, state: true })
        );
        break;
      case getCancelledOrderList.fulfilled.type:
        dispatch(
          setLoading({ id: getCancelledOrderList.typePrefix, state: false })
        );
        break;
      case getCancelledOrderList.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({ id: getCancelledOrderList.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_GET_ORDER_LIST"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const getOrderDetailMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getOrderDetail.pending.type:
        dispatch(setLoading({ id: getOrderDetail.typePrefix, state: true }));
        break;
      case getOrderDetail.fulfilled.type:
        dispatch(setLoading({ id: getOrderDetail.typePrefix, state: false }));
        break;
      case getOrderDetail.rejected.type:
        const lang = getState().constant.languages;
        dispatch(setLoading({ id: getOrderDetail.typePrefix, state: false }));
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_GET_ORDER_DETAIL"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const getOrderRequestorDropdownMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getOrderRequestorDropdown.pending.type:
        dispatch(
          setLoading({ id: getOrderRequestorDropdown.typePrefix, state: true })
        );
        break;
      case getOrderRequestorDropdown.fulfilled.type:
        dispatch(
          setLoading({ id: getOrderRequestorDropdown.typePrefix, state: false })
        );
        break;
      case getOrderRequestorDropdown.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({ id: getOrderRequestorDropdown.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

const getOrderReceiverDropdownMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getOrderReceiverDropdown.pending.type:
        dispatch(
          setLoading({ id: getOrderReceiverDropdown.typePrefix, state: true })
        );
        break;
      case getOrderReceiverDropdown.fulfilled.type:
        dispatch(
          setLoading({ id: getOrderReceiverDropdown.typePrefix, state: false })
        );
        break;
      case getOrderReceiverDropdown.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({ id: getOrderReceiverDropdown.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

export default [
  getNewOrderListMiddleware,
  getAcceptedOrderListMiddleware,
  getRejectedOrderListMiddleware,
  getCancelledOrderListMiddleware,
  getOrderDetailMiddleware,
  getOrderRequestorDropdownMiddleware,
  getOrderReceiverDropdownMiddleware,
];
