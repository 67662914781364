import React from "react";
import RedemptionDetailsDialogComponent from './redemptionDetailsDialog.component';
import { useSelector } from "react-redux";

export default function RedemptionDetailsDialogContainer({
  hasStockflowDealerViewAccessRight,
  isOpen,
  handleClose,
  details,
  handleSelectedStatus,
  language,
  selectedStatus,
  remarks,
  handleChangeRemarks,
  handleUpdateStatus,
  isFetchingDetails,
  handleViewDealer,
  handleViewEmployee,
  isDealerPage = true
}) {
  const lang = useSelector(state => state.constant.languages);

  return (
    <RedemptionDetailsDialogComponent
      hasStockflowDealerViewAccessRight={hasStockflowDealerViewAccessRight}
      isOpen={isOpen}
      language={language}
      handleClose={handleClose}
      details={details}
      handleSelectedStatus={handleSelectedStatus}
      selectedStatus={selectedStatus}
      remarks={remarks}
      isFetchingDetails={isFetchingDetails}
      handleChangeRemarks={handleChangeRemarks}
      handleUpdateStatus={handleUpdateStatus}
      handleViewDealer={handleViewDealer}
      handleViewEmployee={handleViewEmployee}
      lang={lang}
      isDealerPage={isDealerPage}
    />
  )
}