import React from "react";
import {
  makeStyles,
  Box,
  IconButton,
  Dialog,
  Typography,
  Backdrop,
} from "@material-ui/core";
import { CloseRounded as CloseRoundedIcon } from "@material-ui/icons";
import { getLang } from "app/feature/constants";
import "react-image-lightbox/style.css";
import ClusterMapPanel from "../../panel/clusterMapPanel";
import ScanTableListPanel from "../../panel/scanTableListPanel";
import moment from "moment";
import "moment/min/locales.min";
import ConsumerScanSummaryIcon from "assets/img/consumerScanSummary.png";
import UniqueConsumerSummaryIcon from "assets/img/uniqueConsumerSummary.png";
import FirstScanSummaryIcon from "assets/img/firstScanSummary.png";
import SuspendedSummaryIcon from "assets/img/suspendedSummary.png";
import CounterfeitSummaryIcon from "assets/img/counterfeitSummary.png";
import ClusterSummaryCard from "../../card/clusterSummaryCard";

const useStyle = makeStyles((theme) => ({
  header: {
    padding: "12px 16px",
    borderBottom: "1px solid #D0D5DD",
  },
  container: {
    backgroundColor: "#F9FAFB",
    overflowY: "auto",
    padding: 16,
  },
  summarySection: {
    display: "grid",
    gridTemplateColumns: "repeat(5, minmax(0, 1fr))",
    columnGap: theme.spacing(1.5),
    gridRowGap: theme.spacing(2),
    rowGap: theme.spacing(2),
    marginBottom: 24,
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr 1fr 1fr",
    },
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
    },
  },
}));

export default function ClusterScanDetailDialogComponent({
  isOpen,
  handleClose,
  lang,
  language,
  handleClusterClick,
  handleMarkerClick,
  clusterBound,
  startDate,
  endDate,
  clusterDetail,
  loadingSummary,
}) {
  const classes = useStyle();

  if(language==="ZH")
  {
    moment.locale('zh-cn')
  }else if(language==="TL")
  {
    moment.locale('tl-ph')
  }else
  {
    moment.locale(language)
  }

  const summaryList = [
    {
      title: getLang(lang, "label.CONSUMER_SCANNED"),
      icon: ConsumerScanSummaryIcon,
      count: clusterDetail.consumerScannedSummary,
      loading: loadingSummary.consumerScannedSummary,
    },
    {
      title: getLang(lang, "label.UNIQUE_CONSUMER_SCANNED"),
      icon: UniqueConsumerSummaryIcon,
      count: clusterDetail.uniqueConsumerScannedSummary,
      loading: loadingSummary.uniqueConsumerScannedSummary,
    },
    {
      title: getLang(lang, "label.FIRST_SCAN_SN"),
      icon: FirstScanSummaryIcon,
      count: clusterDetail.firstScanSummary,
      loading: loadingSummary.firstScanSummary,
    },
    {
      title: getLang(lang, "label.SUSPENDED_SERIAL_NUMBER"),
      icon: SuspendedSummaryIcon,
      count: clusterDetail.suspendedSnSummary,
      loading: loadingSummary.suspendedSnSummary,
    },
    {
      title: getLang(lang, "label.COUNTERFEIT_REPORT_RECEIVED"),
      icon: CounterfeitSummaryIcon,
      count: clusterDetail.counterfeitReportSummary,
      loading: loadingSummary.counterfeitReportSummary,
    },
  ];

  return (
    <Dialog
      maxWidth="lg"
      open={isOpen}
      onClose={() => {}}
      aria-labelledby="modal-scan-detail"
      aria-describedby="modal-scan-detail"
      BackdropComponent={Backdrop}
      fullWidth
      PaperProps={{
        style: {
          overflowY: "hidden",
        },
      }}
    >
      <Box
        className={classes.header}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="body1">
          <b>{getLang(lang, "label.CONSUMER_SCAN_COUNT_TITLE")}</b>
        </Typography>
        <IconButton
          onClick={handleClose}
          edge="end"
          size="small"
          aria-label="close dialog"
          // disabled={isLoading}
        >
          <CloseRoundedIcon color="primary" />
        </IconButton>
      </Box>
      <Box className={classes.container}>
        {!!clusterBound && (
          <Box>
            <Box mb={1.5}>
              <Typography display="inline">
                {getLang(lang, "label.DATE_RANGE")}:{" "}
              </Typography>
              <Typography display="inline" color="textSecondary">
                {startDate === endDate
                  ? moment(startDate).format("ll")
                  : `${moment(startDate).format("ll")} - ${moment(
                      endDate
                    ).format("ll")}`}
              </Typography>
            </Box>
            <Box className={classes.summarySection}>
              {summaryList.map((s, i) => (
                <ClusterSummaryCard
                  key={i}
                  title={s.title}
                  icon={s.icon}
                  count={s.count}
                  loading={s.loading}
                />
              ))}
            </Box>
            <Box position="relative">
              <ClusterMapPanel
                clusterBound={clusterBound}
                startDate={startDate}
                endDate={endDate}
                handleClusterClick={handleClusterClick}
                handleMarkerClick={handleMarkerClick}
              />
            </Box>
            <Box mt={2}>
              <ScanTableListPanel
                clusterBound={clusterBound}
                startDate={startDate}
                endDate={endDate}
                handleMarkerClick={handleMarkerClick}
              />
            </Box>
          </Box>
        )}
      </Box>
    </Dialog>
  );
}
