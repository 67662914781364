import React, { useEffect } from "react";
import CreateEmployeeDialogComponent from "./createEmployeeDialog.component";
import { useDispatch, useSelector } from "react-redux";
import { createEmployee, checkEmailExists } from "modules/employee/redux";
import { hideBackdrop, showBackdrop } from "modules/backdrop";
import { unwrapResult } from "@reduxjs/toolkit";
import { addAlert } from "modules/notification";
import { fetchBranchDropdown } from "modules/branch/redux"

export default function CreateEmployeeDialogContainer({ isOpen, handleClose, handleReload }) {
  const dispatch = useDispatch();

  const lang = useSelector(state => state.constant.languages);
  const registrationType = useSelector(state => state.profile.registrationType)
  const subscriptionPlan = useSelector(state => state.billing.subscriptionPlan.productName)
  const companyUsersCount = useSelector((state) => state.session.company.usersCount);

  useEffect(() => {
    dispatch(fetchBranchDropdown())
  }, [dispatch])

  const handleSubmit = async (values) => {
    if(
      (registrationType === 1 && subscriptionPlan === 'Lite') || 
      (registrationType === 4)
    ){
      if(
        (
          companyUsersCount + values.employees.length > 5
        )
      ){
        dispatch(addAlert({ severity: "error", message: "You are not allow to have more than 5 users" }))
      }else{
        const info = [];
        for (const employee of values.employees) {
          const employeeInfo = {
            user_group_id: employee.role.userGroupId,
            email: employee.email,
            node_ids: []
          };
          for (const employeeBranch of employee.branch) {
            employeeInfo.node_ids.push(employeeBranch.node_id);
          }
          info.push(employeeInfo);
        }
        dispatch(showBackdrop());
        await dispatch(createEmployee(info))
          .then(unwrapResult)
          .finally((error) => {
            dispatch(hideBackdrop());
            handleReload()
            handleClose()
          });
      }
    }else{
      const info = [];
      for (const employee of values.employees) {
        const employeeInfo = {
          user_group_id: employee.role.userGroupId,
          email: employee.email,
          node_ids: []
        };
        for (const employeeBranch of employee.branch) {
          employeeInfo.node_ids.push(employeeBranch.node_id);
        }
        info.push(employeeInfo);
      }
      dispatch(showBackdrop());
      await dispatch(createEmployee(info))
        .then(unwrapResult)
        .finally((error) => {
          dispatch(hideBackdrop());
          handleReload()
          handleClose()
        });
    }
  };

  const alertError = (message) => {
    dispatch(addAlert({ severity: "error", message: message }));
  };

  const handleCheckEmail = async (email) => {
    dispatch(showBackdrop());
    await dispatch(checkEmailExists(email))
      .then(unwrapResult)
      .finally((error) => {
        dispatch(hideBackdrop());
      });
  };

  return (
    <CreateEmployeeDialogComponent
      isOpen={isOpen}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      alertError={alertError}
      handleCheckEmail={handleCheckEmail}
      lang={lang}
    />
  );
}
