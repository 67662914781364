import React from "react";
import ScanLocationCountryPanelComponent from './scanLocationCountryPanel.component';

export default function ScanLocationCountryPanelContainer({
  scanData,
  handleCountryClick
}) {
  return (
    < ScanLocationCountryPanelComponent scanData={scanData} handleCountryClick={handleCountryClick} />
  )
}