import { selectLoading } from "modules/notification";
import {
  exportSuspendedListing,
  getSuspendedListData,
  getSuspendedScanQrData,
  getTotalReportedTrending,
  getTotalSuspendedTrending,
} from "modules/reporting/redux";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SuspendedProductPanel from "./suspendedProductPanel.component";
import { unwrapResult } from "@reduxjs/toolkit";

export default function SuspendedProductPanelContainer({
  currentFilter,
  urlTotalSuspended,
  urlTotalReported,
  urlSuspendedListing,
  pageType,
  translate,
}) {
  const dispatchTrending = useDispatch();
  const dispatchTable = useDispatch();
  const token = useSelector((state) => state.session.accessToken);

  // (1) trending data -- start

  // (a) get trending: total suspended qr data
  const isFetchingTotalSuspendedTrending = useSelector((state) =>
    selectLoading(state, getTotalSuspendedTrending.typePrefix)
  );
  const totalSuspendedTrendingData = useSelector(
    (state) => state.reporting.trending.totalSuspendedData
  );

  // (b) get trending: total report data
  const isFetchingTotalReportedTrending = useSelector((state) =>
    selectLoading(state, getTotalReportedTrending.typePrefix)
  );
  const totalReportedTrendingData = useSelector(
    (state) => state.reporting.trending.totalReportedData
  );
  // (1) trending data -- end

  // (2) get suspended scan qr -- start
  const isFetchingSuspendedScanQR = useSelector((state) =>
    selectLoading(state, getSuspendedScanQrData.typePrefix)
  );
  const suspendedScanQRData = useSelector(
    (state) => state.reporting.trending.suspendedScanQrData
  );
  // (2) get suspended scan qr -- end

  // (3) get suspended list -- start
  const isFetchingSuspendedList = useSelector((state) =>
    selectLoading(state, getSuspendedListData.typePrefix)
  );
  const suspendedListData = useSelector(
    (state) => state.reporting.product.suspendedDatalist
  );
  // (3) get suspended list -- end

  const [tableFilter, setTableFilter] = useState({
    page: 0,
    rowsPerPage: 25,
    search: "",
    searchBy: "name",
    orderBy: "created_at",
    orderDirection: "desc",
  });
  useEffect(() => {
    dispatchTrending(
      getTotalSuspendedTrending({
        countryCode: currentFilter.countryCode,
        city_uuid: currentFilter.cityUuid,
        branch_uuid: currentFilter.branchUuid,
        period: currentFilter.period,
        start_date: moment(currentFilter.dateRange[0]).format("YYYY-MM-DD"),
        end_date: moment(currentFilter.dateRange[1]).format("YYYY-MM-DD"),
        url: urlTotalSuspended,
      })
    );

    dispatchTrending(
      getTotalReportedTrending({
        countryCode: currentFilter.countryCode,
        city_uuid: currentFilter.cityUuid,
        branch_uuid: currentFilter.branchUuid,
        period: currentFilter.period,
        start_date: moment(currentFilter.dateRange[0]).format("YYYY-MM-DD"),
        end_date: moment(currentFilter.dateRange[1]).format("YYYY-MM-DD"),
        url: urlTotalReported,
      })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatchTrending, currentFilter]);

  useEffect(() => {
    dispatchTable(
      getSuspendedListData({
        length: tableFilter.rowsPerPage,
        start: tableFilter.page * tableFilter.rowsPerPage,
        orderBy: tableFilter.orderBy,
        orderDirection: tableFilter.orderDirection,
        search: tableFilter.search,
        searchBy: tableFilter.searchBy,
        countryCode: currentFilter.countryCode,
        city_uuid: currentFilter.cityUuid,
        branch_uuid: currentFilter.branchUuid,
        period: currentFilter.period,
        start_date: moment(currentFilter.dateRange[0]).format("YYYY-MM-DD"),
        end_date: moment(currentFilter.dateRange[1]).format("YYYY-MM-DD"),
        page: tableFilter.page,
        rowsPerPage: tableFilter.rowsPerPage,
        url: urlSuspendedListing,
      })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatchTable, currentFilter, tableFilter]);

  const columns = [
    {
      title: translate("label.NUM"),
      field: null,
      render: (row) => {
        return <>{row.title}</>;
      },
      align: null,
    },
    {
      title: translate("label.SERIAL_NUMBER"),
      field: null,
      render: (row) => {
        return <>{row.title}</>;
      },
      size: "small",
    },
    {
      title: translate("label.PRODUCT"),
      field: null,
      render: (row) => {
        return <>{row.title}</>;
      },
      size: "medium",
    },
    {
      title: translate("label.TOTAL_SCAN_/_SCAN_LIMIT"),
      field: null,
      render: (row) => {
        return <>{row.title}</>;
      },
      size: "small",
    },
    {
      title: "Report Received",
      field: "report_count",
      render: (row) => {
        return <>{row.title}</>;
      },
      size: "small",
    },
    {
      title: translate("label.LAST_SCAN_DATE"),
      field: "created_at",
      render: (row) => {
        return <>{row.title}</>;
      },
      size: "small",
    },
  ];

  const handleChangePage = (e, newPage) => {
    setTableFilter((prev) => ({
      ...prev,
      page: newPage,
    }));
  };

  const handleChangeRowPerPage = (event) => {
    setTableFilter((prev) => ({
      ...prev,
      page: 0,
      rowsPerPage: event.target.value,
    }));
  };

  const handleRequestSort = (event, property) => {
    const isAsc =
      tableFilter.orderBy === property && tableFilter.orderDirection === "asc";
    setTableFilter((prev) => ({
      ...prev,
      orderDirection: isAsc ? "desc" : "asc",
      orderBy: property,
    }));
  };

  const handleExportExcel = () => {
    const info = {
      token: token,
      length: tableFilter.rowsPerPage,
      start: tableFilter.page * tableFilter.rowsPerPage,
      orderBy: tableFilter.orderBy,
      orderDirection: tableFilter.orderDirection,
      search: tableFilter.search,
      searchBy: tableFilter.searchBy,
      countryCode: currentFilter.countryCode,
      city_uuid: currentFilter.cityUuid,
      branch_uuid: currentFilter.branchUuid,
      product_uuid: currentFilter.productUuid,
      period: currentFilter.period,
      start_date: moment(currentFilter.dateRange[0]).format("YYYY-MM-DD"),
      end_date: moment(currentFilter.dateRange[1]).format("YYYY-MM-DD"),
      url: urlSuspendedListing,
    };

    dispatchTable(exportSuspendedListing(info))
      .then(unwrapResult)
      .finally(() => {});
  };

  return (
    <SuspendedProductPanel
      status={{
        isFetchingTotalSuspendedTrending,
        isFetchingTotalReportedTrending,
        isFetchingSuspendedScanQR,
        isFetchingSuspendedList,
      }}
      data={{
        totalSuspendedTrendingData,
        totalReportedTrendingData,
        suspendedScanQRData,
        suspendedListData,
      }}
      sort={{
        valueToOrderBy: tableFilter.orderBy,
        orderDirection: tableFilter.orderDirection,
        handleRequestSort: handleRequestSort,
      }}
      pagination={{
        handleChangePage: handleChangePage,
        handleChangeRowPerPage: handleChangeRowPerPage,
        page: tableFilter.page,
        rowsPerPage: tableFilter.rowsPerPage,
        rowsPerPageOptions: [5, 10, 25],
      }}
      columns={columns}
      currentFilter={currentFilter}
      pageType={pageType}
      translate={translate}
      handleExportExcel={handleExportExcel}
    />
  );
}
