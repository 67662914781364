import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyle = makeStyles(theme => ({
  root: {
    margin: theme.spacing(0, 2),
    borderRadius: 10,
    overflow: "hidden",
    minHeight: "fit-content",
    height: 396
  }
}));

export default function InstagramComponent({ path }) {
  const classes = useStyle();

  return (
    <div className={classes.root}>
      <iframe
        title="instagram-plugin-iframe"
        src={path}
        width="343"
        height="396"
        style={{ border: "none", overflow: "hidden" }}
        scrolling="no"
        frameBorder="0"
        //allowTransparency="true"
        allow="encrypted-media"
      ></iframe>
    </div>
  );
}
