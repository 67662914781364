import React, { useRef } from "react";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Paper,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { getLang } from "app/feature/constants";
import AdtInfoTitleTable from "../../table/adtInfoTitleTable";
import { AddCircle as AddIcon, Search as SearchIcon } from "@material-ui/icons";
import InputTextField from "components/input/inputTextField";

const useStyle = makeStyles((theme) => ({
  button: {
    padding: 0,
    minWidth: 0,
  },
}));

export default function AdtInfoTitleExportPanelComponent({
  lang,
  handleReload,
  totalFiltered,
  isError,
  isFetching,
  titles,
  supportedLangs,
  handleSearch,
  handleEdit,
  handleAddTitle,
  handleChangePage,
  handleChangeRowPerPage,
  formik,
  tableFilter,
  paginationOptions,
  totalRecords,
}) {
  const classes = useStyle();
  const textRef = useRef();

  let count = formik.values.isSelectAll
    ? totalRecords - formik.values.selectedIds.length
    : formik.values.selectedIds.length;

  return (
    <Paper elevation={0}>
      <Box p={2}>
        <Box
          mb={1}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="body1">
            <b>{getLang(lang, "label.TITLE")}</b>
          </Typography>
          <Box ml={0.5} display="flex" alignItems="center">
            {count > 0 && (
              <Box display="flex" justifyContent="flex-end" pr={3}>
                <Typography variant="body2" style={{ color: "#6aaf68" }}>
                  {getLang(lang, "paragraph.SELECTED_COUNT", {
                    count: count,
                  })}
                </Typography>
              </Box>
            )}
            <IconButton size="small" onClick={handleAddTitle}>
              <AddIcon />
            </IconButton>
          </Box>
        </Box>
        <Box mb={1}>
          <Box display="flex" alignItems="center">
            <InputTextField
              variant="filled"
              size="small"
              fullWidth
              inputRef={textRef}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      onClick={() => handleSearch(textRef.current.value)}
                      className={classes.button}
                    >
                      <SearchIcon fontSize="small" />
                    </Button>
                  </InputAdornment>
                ),
                style: {
                  backgroundColor: "#efefef",
                },
              }}
              inputProps={{
                onKeyDown: (e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    handleSearch(textRef.current.value);
                    return false;
                  }
                },
                style: {
                  padding: "10px",
                },
              }}
              placeholder={getLang(lang, "placeholder.SEARCH_TITLE")}
            />
          </Box>
        </Box>
        <Box>
          <AdtInfoTitleTable
            rows={titles}
            isFetching={isFetching}
            handleReload={handleReload}
            isError={isError}
            lang={lang}
            page={tableFilter.page}
            rowsPerPage={tableFilter.rowsPerPage}
            supportedLang={supportedLangs}
            isSelectAll={formik.values.isSelectAll}
            paginationOptions={paginationOptions}
            handleChangePage={handleChangePage}
            handleChangeRowPerPage={handleChangeRowPerPage}
            totalFiltered={totalFiltered}
            handleSelectAll={() => {
              formik.setFieldValue("selectedIds", []);
              formik.setFieldValue("isSelectAll", !formik.values.isSelectAll);
            }}
            selectedIds={formik.values.selectedIds}
            handleSelectTitle={(title, isSelected) => {
              let temp = [...formik.values.selectedIds];
              if (formik.values.isSelectAll && !isSelected) {
                if (isSelected) {
                  if (temp.includes(title.id)) {
                    let idx = temp.findIndex((t) => t === title.id);
                    if (idx >= 0) {
                      temp.splice(idx, 1);
                    }
                  }
                } else {
                  temp.push(title.id);
                }
              } else if (!formik.values.isSelectAll) {
                if (isSelected) {
                  temp.push(title.id);
                } else {
                  if (temp.includes(title.id)) {
                    let idx = temp.findIndex((t) => t === title.id);
                    if (idx >= 0) {
                      temp.splice(idx, 1);
                    }
                  }
                }
              }

              temp = [...new Set(temp)];

              formik.setFieldValue("selectedIds", temp);
              if (formik.values.isSelectAll && temp.length === totalFiltered) {
                formik.setFieldValue("isSelectAll", false);
              }
              if (!formik.values.isSelectAll && temp.length === totalFiltered) {
                formik.setFieldValue("isSelectAll", true);
                formik.setFieldValue("selectedIds", []);
              }
            }}
            handleEdit={handleEdit}
            handleAdd={handleAddTitle}
          />
        </Box>
      </Box>
    </Paper>
  );
}
