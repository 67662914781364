import React, { useState, useEffect } from "react";
import MainPageComponent from "./store.page";
import { useHistory } from "react-router-dom";
import qs from "query-string";
import { connect, useSelector } from "react-redux";
import { setQrCodeQty } from "../../redux";
import { SUBSCRIPTION_PLANS_PAGE } from '../../config/store.route'
import { MARKETPLACE_VIEW, QR_CODE_CREDIT_PURCHASE, SMS_CREDIT_PURCHASE } from "lib/constants/accessRights"
import LoadingComponent from "components/loading";

const TYPES = ["QRCode", "SMS", "SubscriptionPlans"];

function MainPageContainer({ setQrCodeQty }) {
  const lang = useSelector(state => state.constant.languages);
  const history = useHistory();
  const [activeType, setActiveType] = useState(null);
  const search = history.location.search;
  const isOwner = useSelector(state => state.profile.roleId === null ? true : false)
  const registrationType = useSelector(state => state.profile.registrationType)

  const hasMarketplaceViewAccessRight = useSelector(state => state.profile.accessRights.includes(MARKETPLACE_VIEW));
  const hasQrCodeCreditPurchaseAccessRight = useSelector(state => state.profile.accessRights.includes(QR_CODE_CREDIT_PURCHASE));
  const hasSmsCreditPurchaseAccessRight = useSelector(state => state.profile.accessRights.includes(SMS_CREDIT_PURCHASE));

  useEffect(() => {
    if(!hasMarketplaceViewAccessRight) {
      history.push('/admin/dashboard')
    }
  }, [history, hasMarketplaceViewAccessRight])


  useEffect(() => {
    const params = qs.parse(search);

    if (params && params.purchase && TYPES.includes(params.purchase)) {
      setActiveType(params.purchase);
    }
  }, [search]);

  if(!hasMarketplaceViewAccessRight) return <LoadingComponent />;

  const handleCancel = () => {
    setActiveType(null);
    if (window.history.pushState) {
      var newurl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname;
      window.history.pushState({ path: newurl }, "", newurl);
    }
  };

  const handleSelect = type => {
    if( type === TYPES[1] ){
      setActiveType(type);
      setQrCodeQty(0);
    }else if( type === TYPES[2] ){
      history.push(SUBSCRIPTION_PLANS_PAGE);
    }else{
      setActiveType(type);
      setQrCodeQty(0);
    }
  };

  return (
    <MainPageComponent
      hasQrCodeCreditPurchaseAccessRight={hasQrCodeCreditPurchaseAccessRight}
      hasSmsCreditPurchaseAccessRight={hasSmsCreditPurchaseAccessRight}
      handleCancel={handleCancel}
      handleSelect={handleSelect}
      types={TYPES}
      activeType={activeType}
      isOwner={isOwner}
      registrationType={registrationType}
      lang={lang}
    />
  );
}

const mapDispatchToProps = dispatch => ({
  setQrCodeQty: qty => dispatch(setQrCodeQty(qty))
});

export default connect(null, mapDispatchToProps)(MainPageContainer);
