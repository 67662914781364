import React from "react";
import { Box } from "@material-ui/core";
import WarrantyListTable from "../../table/warrantyListTable";
import WithProductGeneralSearch from "../../../../../components/search/withProductGeneralSearch";

export default function WarrantyListPanelComponent({
  hasWarrantyAddAccessRight,
  hasWarrantyEditAccessRight,
  hasWarranteeViewAccessRight,
  isFetching,
  warrantyListData,
  page,
  search,
  handlePageChange,
  handleRowPerPageChange,
  rowsPerPage,
  totalRecords,
  handleSearch,
  handleCreate,
  handleReload,
  paginationOptions,
  productDropdown,
  isFetchingProduct,
}) {
  return (
    <Box>
      <WithProductGeneralSearch
        handleFilter={(value) => {
          handleSearch(value);
        }}
        productDropdown={productDropdown}
        isFetchingProduct={isFetchingProduct}
      />

      <WarrantyListTable
        hasWarrantyAddAccessRight={hasWarrantyAddAccessRight}
        hasWarrantyEditAccessRight={hasWarrantyEditAccessRight}
        hasWarranteeViewAccessRight={hasWarranteeViewAccessRight}
        isFetching={isFetching}
        warrantyListData={warrantyListData}
        rowsPerPage={rowsPerPage}
        page={page}
        search={search}
        handlePageChange={handlePageChange}
        handleRowPerPageChange={handleRowPerPageChange}
        length={totalRecords}
        handleCreate={handleCreate}
        handleReload={handleReload}
        paginationOptions={paginationOptions}
      />
    </Box>
  );
}
