import React, { useEffect, useState } from "react";
import SubscriptionPlansComponent from "./subscription-plans.page";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getSubscriptionPlans, swapSubscriptionPlan, swapSubscriptionPlanPreview } from "../../redux";
import { fetchSubscriptionPlan, fetchQuickSubscriptionSummary } from "../../../billing/redux/billing.action";
import { fetchMasterInfo } from "../../../admin/redux"
import { filterPlansByRegistrationType } from "lib/planHelper";
import { getLang } from "app/feature/constants";


function SubscriptionPlansContainer() {
  const history = useHistory();
  const dispatch = useDispatch();
  const lang = useSelector(state => state.constant.languages);

  const isOwner = useSelector(state => state.profile.roleId === null ? true : false)
  const registrationType = useSelector(state => state.profile.registrationType)
  const userSubscriptionPlan = useSelector((state) => state.billing.subscriptionPlan)
  const subscriptionPlans = useSelector((state) => state.marketplace.subsciptionPlans)

  const [isLoadedSubscriptionPlans, setIsLoadedSubscriptionPlans] = useState(true)
  const [activeSubscriptionPlansPageIndex, setActiveSubscriptionPlansPageIndex] = useState(0)

  const [isCompareDialogOpen, setIsCompareDialogOpen] = useState(false);
  const [compareType, setCompareType] = useState('month');

  const [isLoadingChangeSubscriptionPlanCharges, setIsLoadingChangeSubscriptionPlanCharges] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [selectedPlanInterval, setSelectedPlanInterval] = useState(null);
  const [changeSubscriptionPlanCharges, setChangeSubscriptionPlanCharges] = useState(null)

  const [isChangePlanWarningDialogOpen, setIsChangePlanWarningDialogOpen] = useState(false);
  const [isLoadingChangePlanWarningDialog, setIsLoadingChangePlanWarningDialog] = useState(false);

  useEffect(() => {
    if (isOwner ? !(registrationType === 1 || registrationType === 4 || registrationType === 5) : true) {
      history.push("/admin/dashboard");
    }else{
      dispatch(getSubscriptionPlans())
        .then(() => {
          setIsLoadedSubscriptionPlans(false)
        })
    }
  }, [dispatch, history, isOwner, registrationType]);

  const onTabChange = (event, newValue) => {
    setActiveSubscriptionPlansPageIndex(newValue);
  };

  const onCompareClick = () => {
    setIsCompareDialogOpen(true)
  }

  const onCompareCloseClick = () => {
    setIsCompareDialogOpen(false)
  }
  
  const onCompareTypeClick = (type) => {
    setCompareType(type)
  }

  const onChangePlanClick = async (selectedPlan) => {
    let selectedInterval =  ''
    if(selectedPlan.name === userSubscriptionPlan.productName  ){
      if(userSubscriptionPlan.interval === 'month'){
        selectedInterval = 'year'
      }else{
        selectedInterval = 'month'
      }
    }else{
      selectedInterval = 'month'
    }
    setIsLoadingChangeSubscriptionPlanCharges(true)
    setSelectedPlan(selectedPlan)
    setSelectedPlanInterval(selectedInterval)
    dispatch(swapSubscriptionPlanPreview({ plan: selectedPlan.prices.find(price => price.interval === selectedInterval).stripe_plan }))
      .then(
        (response) => {
          setChangeSubscriptionPlanCharges(response.payload)
          setIsLoadingChangeSubscriptionPlanCharges(false)
        }
      )
  }

  const onChangePlanCloseClick = () => {
    setSelectedPlan(null)
  }

  const onChangePlanIntervalClick = (interval) => {
    setIsLoadingChangeSubscriptionPlanCharges(true)
    setSelectedPlanInterval(interval)
    dispatch(swapSubscriptionPlanPreview({ plan: selectedPlan.prices.find(price => price.interval === interval).stripe_plan }))
      .then(
        (response) => {
          setChangeSubscriptionPlanCharges(response.payload)
          setIsLoadingChangeSubscriptionPlanCharges(false)
        }
      )
  }

  const onProceedChangePlanClick = () => {
    setIsChangePlanWarningDialogOpen(true)
  }

  const onChangePlanWarningCloseClick = () => {
    setIsChangePlanWarningDialogOpen(false)
  }

  const onChangePlanWarningConfirmClick = async () => {
    setIsLoadingChangePlanWarningDialog(true)
    await dispatch(swapSubscriptionPlan({ plan: selectedPlan.prices.find(price => price.interval === selectedPlanInterval).stripe_plan }))
    await dispatch(fetchQuickSubscriptionSummary())
    await dispatch(fetchSubscriptionPlan())
    await dispatch(fetchMasterInfo());
    setSelectedPlan(null)
    setIsChangePlanWarningDialogOpen(false)
    setIsLoadingChangePlanWarningDialog(false)
  } 

  return (
    <SubscriptionPlansComponent
      title={getLang(lang, 'label.SUBSCRIPTION_PLANS')}
      isLoadedSubscriptionPlans={isLoadedSubscriptionPlans}
      registrationType={registrationType}
      userSubscriptionPlan={userSubscriptionPlan}
      subscriptionPlans={filterPlansByRegistrationType(subscriptionPlans, registrationType)}
      activeSubscriptionPlansPageIndex={activeSubscriptionPlansPageIndex}
      onTabChange={onTabChange}
      isCompareDialogOpen={isCompareDialogOpen}
      compareType={compareType}
      onCompareClick={onCompareClick}
      onCompareCloseClick={onCompareCloseClick}
      onCompareTypeClick={onCompareTypeClick}
      isLoadingChangeSubscriptionPlanCharges={isLoadingChangeSubscriptionPlanCharges}
      selectedPlan={selectedPlan}
      selectedPlanInterval={selectedPlanInterval}
      changeSubscriptionPlanCharges={changeSubscriptionPlanCharges}
      onChangePlanClick={onChangePlanClick}
      onChangePlanIntervalClick={onChangePlanIntervalClick}
      onChangePlanCloseClick={onChangePlanCloseClick}
      onProceedChangePlanClick={onProceedChangePlanClick}
      isChangePlanWarningDialogOpen={isChangePlanWarningDialogOpen}
      isLoadingChangePlanWarningDialog={isLoadingChangePlanWarningDialog}
      onChangePlanWarningCloseClick={onChangePlanWarningCloseClick}
      onChangePlanWarningConfirmClick={onChangePlanWarningConfirmClick}

      lang={lang}
    />
  );
}

export default SubscriptionPlansContainer;
