import React from "react";
import {
  makeStyles,
  Box,
  Typography,
  IconButton,
  Grid,
  Button,
  FormGroup,
  FormControlLabel,
  Divider
} from "@material-ui/core";
import { AddImage, ErrorSVG, EditSVG } from "../../../utils/static";
import InputTextField from "components/input/inputTextField";
import { ErrorMessage, Formik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import AntSwitch from "components/switch/switch";
import PrizeExample from "../../../utils/prizeExample.png";
import { drawType } from "modules/lucky-draw/constants";
import { getLang } from "app/feature/constants";

const style = (theme) => ({
  addPrizeContainer: {
    border: "1px solid #3A4D54",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
    margin: theme.spacing(3)
  },
  photoContainer: {
    background: "#EBEDEE",
    border: "1px solid rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
    width: "120px",
    height: "120px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden"
  },
  button: {
    width: "100%",
    height: "100%"
  },
  toggleControl: {
    margin: "7px 35px 7px 0px"
  },
  toggleFormGroup: {
    flexDirection: "row"
  },
  toggleButton: {
    marginRight: 10
  },
  viewPrizeContainer: {
    border: "1px solid rgba(0, 0, 0, 0.1)",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
    margin: theme.spacing(1, 3, 2, 3),

  },
  viewDetailPrizeContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 5fr 1fr"
  },
  viewPrizePhoto: {
    width: 100,
    height: 100,
    display: "flex",
    alignItems: "center",
    padding: "4px",
    placeContent: "center"
  },
  editPrize: {
    border: "1px solid rgba(0, 0, 0, 0.1)",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
    margin: theme.spacing(3)
  },
  viewPrizeInfo: {
    padding: '16px',
    width: "100%"
  }
});

const useStyles = makeStyles(style);

export default function LuckyDrawPrizeCardComponent({
  hasLuckyDrawEditAccessRight,
  handleDelete,
  handleSubmit,
  type,
  inputImgRef,
  handleImgChange,
  prizeDetail,
  handleEnableEdit,
  handleDisableEdit,
  prizeImage,
  lang,
  AddingPrizeFlag
}) {
  const classes = useStyles();
  const viewType = type === "view" ? true : false;

  return (
    <Box>
      <Formik
        initialValues={{
          prizeLabel: prizeDetail ? prizeDetail.prizeLabel : "",
          description: prizeDetail ? prizeDetail.description : "",
          prizeName: prizeDetail ? prizeDetail.prizeName : "",
          quantity: prizeDetail ? prizeDetail.quantity : "",
          drawMethod: prizeDetail ? prizeDetail.drawMethod : "All at Once",
          prizeImage: prizeDetail ? prizeDetail.prizeImage : ""
        }}
        validationSchema={Yup.object({
          prizeLabel: Yup.string().trim().required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
          prizeName: Yup.string().trim().required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
          quantity: Yup.number().moreThan(0, getLang(lang, "message.error.FORM_VALIDATE_MORETHAN", { value: 0 })),
          drawMethod: Yup.string().required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
        })}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            {viewType ? (
              <Box className={classes.viewPrizeContainer}>
                <Typography
                  variant="body2"
                  color="primary"
                  style={{ fontWeight: "bold", padding: "10px 16px" }}
                >
                  {formik.values.prizeLabel}
                </Typography>

                <Divider />
                <Box className={classes.viewDetailPrizeContainer}>
                  <Box className={classes.viewPrizePhoto}>
                    <img
                      src={prizeDetail ? prizeDetail.prizeImage : PrizeExample}
                      alt="example"
                      height="100%"
                      width="100%"
                      style={{ objectFit: "contain" }}
                    />
                  </Box>

                  <Box className={classes.viewPrizeInfo} display="flex" style={{ flexDirection: "column" }}>
                    <Box display="flex" pt={1} pb={1}>
                      <Typography
                        variant="body2"
                        style={{ color: " rgba(58, 77, 84, 0.33)" }}
                      >
                        {getLang(lang, "label.PRIZE_NAME")}:&nbsp;
                      </Typography>
                      <Typography
                        variant="body2"
                        color="primary"
                        style={{ fontWeight: "bold" }}
                      >
                        {formik.values.prizeName}
                      </Typography>
                    </Box>

                    <Box display="grid" gridTemplateColumns="2fr 6fr">
                      <div style={{ display: "flex" }}>
                        <Typography
                          variant="body2"
                          style={{ color: " rgba(58, 77, 84, 0.33)" }}
                        >
                          {getLang(lang, "label.QUANTITY")}:&nbsp;
                        </Typography>
                        <Typography
                          variant="body2"
                          color="primary"
                          style={{ fontWeight: "bold" }}
                        >
                          {formik.values.quantity.toLocaleString("en-US")}
                        </Typography>
                      </div>
                    </Box>
                    
                    <Box display="flex" pt={1} pb={1}>
                      <div style={{ display: "flex" }}>
                        <Typography
                          variant="body2"
                          style={{ color: " rgba(58, 77, 84, 0.33)" }}
                        >
                          {getLang(lang, "label.DRAW_PRIZE_METHOD")}:&nbsp;
                        </Typography>
                        <Typography
                          variant="body2"
                          color="primary"
                          style={{ fontWeight: "bold" }}
                        >
                          {formik.values.drawMethod === 'One by One' ? getLang(lang, 'label.ONE_BY_ONE')
                            : formik.values.drawMethod === 'All at Once' ? getLang(lang, 'label.ALL_AT_ONCE')
                              : formik.values.drawMethod}
                          {/* QUICK FIX FOR LANGUAGES TRANSLATION */}
                        </Typography>

                      </div>
                    </Box>
                  </Box>
                  {hasLuckyDrawEditAccessRight ? (
                    <Box
                      p={2}
                      display="flex"
                      alignSelf="end"
                      justifyContent="flex-end"
                    >
                      <IconButton
                        style={{ padding: 0 }}
                        onClick={handleEnableEdit}
                        disabled={AddingPrizeFlag}
                      >
                        {AddingPrizeFlag ? (
                          <EditSVG color="rgba(58, 77, 84, 0.33)" />
                        ) : (
                          <EditSVG />
                        )}
                      </IconButton>
                    </Box>
                  ) : null}
                </Box>
              </Box>
            ) : (
              <Box
                className={clsx({
                  [classes.editPrize]: type === "edit",
                  [classes.addPrizeContainer]: type === "create"
                })}
              >
                {type === "edit" ? (
                  <>
                    <Box px={3} pt={1} pb={1}>
                      <Typography
                        variant="subtitle2"
                        color="primary"
                        style={{ fontWeight: "bold" }}
                      >
                        {getLang(lang, "label.EDIT_PRIZE")}
                      </Typography>
                    </Box>
                    <Divider />
                  </>
                ) : (
                  ""
                )}
                <Box p={3}>
                  <Typography variant="body2" color="primary">
                    {getLang(lang, "paragraph.WHAT_PRIZE_QUESTION")} <Typography style={{ color: "#DE350B", display: 'inline-block' }}>*</Typography>
                  </Typography>
                  <InputTextField
                    required
                    variant="filled"
                    size="small"
                    fullWidth
                    placeholder={getLang(lang, "placeholder.EXAMPLE_PRIZE")}
                    {...formik.getFieldProps("prizeLabel")}
                  />

                  <Grid container spacing={1}>
                    <Grid item xs={3} sm={3} md={3}>
                      <Box pt={2}>
                        <Typography variant="body2" color="primary">
                          {getLang(lang, "label.PRIZE_IMAGE")} <Typography style={{ color: "#DE350B", display: 'inline-block' }}>*</Typography>
                        </Typography>
                        <Box className={classes.photoContainer}>
                          <input
                            accept="image/*"
                            id="contained-button-file"
                            type="file"
                            ref={inputImgRef}
                            style={{ display: "none" }}
                            onChange={handleImgChange}
                          />
                          <label
                            htmlFor="contained-button-file"
                            style={{ height: "100%", width: "100%" }}
                          >
                            <Button
                              size="small"
                              className={classes.button}
                              disableElevation
                              component="span"
                              type="button"
                            >
                              {prizeImage ? (
                                <img
                                  src={prizeImage}
                                  alt="prize"
                                  height="100%"
                                  width="100%"
                                />
                              ) : prizeDetail ? (
                                <img
                                  src={prizeDetail.prizeImage}
                                  alt="prize"
                                  height="100%"
                                  width="100%"
                                />
                              ) : (
                                <AddImage />
                              )}
                            </Button>
                          </label>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={9} sm={9} md={9}>
                      <Box pt={2}>
                        <Typography variant="body2" color="primary">
                          {getLang(lang, "label.PRIZE_NAME")} <Typography style={{ color: "#DE350B", display: 'inline-block' }}>*</Typography>
                        </Typography>
                        <InputTextField
                          required
                          variant="filled"
                          size="small"
                          fullWidth
                          placeholder={getLang(lang, "label.ENTER_NAME")}
                          {...formik.getFieldProps("prizeName")}
                          error={
                            formik.touched.prizeName && formik.errors.prizeName ? true : false
                          }
                          helperText={ErrorMessage({ name: "prizeName" })}
                        />

                        <Box pt={2} pb={2}>
                          <Typography variant="body2" color="primary">
                            {getLang(lang, "label.QUANTITY")} <Typography style={{ color: "#DE350B", display: 'inline-block' }}>*</Typography>
                          </Typography>
                          <InputTextField
                            required
                            variant="filled"
                            size="small"
                            fullWidth
                            type="number"
                            InputProps={{
                              min: "1"
                            }}
                            placeholder={getLang(lang, "label.ENTER_QUANTITY")}
                            {...formik.getFieldProps("quantity")}
                            error={
                              formik.touched.quantity && formik.errors.quantity
                                ? true
                                : false
                            }
                            helperText={ErrorMessage({ name: "quantity" })}
                          />
                        </Box>

                        <Typography variant="body2" color="primary">
                          {getLang(lang, "label.DESCRIPTION")}<Typography style={{ color: "#DE350B", display: 'inline-block' }}>*</Typography>
                        </Typography>
                        <InputTextField
                          required
                          multiline
                          rows={3}
                          variant="filled"
                          size="small"
                          fullWidth
                          {...formik.getFieldProps("description")}
                          error={
                            formik.touched.description && formik.errors.description ? true : false
                          }
                          helperText={ErrorMessage({ name: "description" })}
                        />
                      </Box>
                    </Grid>

                    <Grid
                      item
                      xs={3}
                      sm={3}
                      md={3}
                      style={{ display: "flex", placeItems: "flex-end" }}
                    ></Grid>
                    <Grid item xs={9} sm={9} md={9}>
                      <Typography
                        variant="body2"
                        color="primary"
                        style={{ paddingTop: "8px" }}
                      >
                        {getLang(lang, "label.DRAW_PRIZE_METHOD")} <Typography style={{ color: "#DE350B", display: 'inline-block' }}>*</Typography>
                      </Typography>

                      <FormGroup
                        classes={{
                          root: classes.toggleFormGroup
                        }}
                      >
                        <FormControlLabel
                          classes={{
                            root: classes.toggleControl
                          }}
                          control={
                            <AntSwitch
                              disabled={formik.isSubmitting}
                              classes={{
                                root: classes.toggleButton,
                              }}
                              checked={
                                formik.values.drawMethod === drawType.allAtOnce
                              }
                              onChange={() => {
                                formik.setFieldValue(
                                  "drawMethod",
                                  drawType.allAtOnce
                                );
                              }}
                            />
                          }
                          label={
                            <Typography variant="body2" color="primary">
                              {getLang(lang, 'label.ALL_AT_ONCE')}
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          classes={{
                            root: classes.toggleControl
                          }}
                          control={
                            <AntSwitch
                              disabled={formik.isSubmitting}
                              classes={{
                                root: classes.toggleButton
                              }}
                              checked={
                                formik.values.drawMethod === drawType.oneByOne
                              }
                              onChange={() => {
                                formik.setFieldValue(
                                  "drawMethod",
                                  drawType.oneByOne
                                );
                              }}
                            />
                          }
                          label={
                            <Typography variant="body2" color="primary">
                              {getLang(lang, "label.ONE_BY_ONE")}
                            </Typography>
                          }
                        />
                      </FormGroup>
                    </Grid>
                  </Grid>
                </Box>

                {type === "edit" ? (
                  <Box mt="auto">
                    <Divider />
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                      p={1}
                      px={2}
                    >
                      <div></div>
                      <Button
                        disableElevation
                        style={{ marginRight: "4px" }}
                        type="button"
                        onClick={(e) => {
                          formik.resetForm()
                          handleDisableEdit(e)
                        }}
                      >
                        {getLang(lang, "label.CANCEL")}
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        classes={{ root: classes.publishButton }}
                        disableElevation
                        type="submit"
                      >
                        {getLang(lang, "label.DONE")}
                      </Button>
                    </Box>
                  </Box>
                ) : (
                  <>
                    <Divider />
                    <Box
                      px={3}
                      pt={1}
                      pb={1}
                      display="flex"
                      alignItems="center"
                    >
                      <ErrorSVG />
                      <Typography
                        variant="caption"
                        style={{
                          paddingLeft: "4px",
                          color: "rgba(58, 77, 84, 0.33)",
                        }}
                      >
                        {getLang(lang, "paragraph.RECOMMENDED_IMAGE_SIZE_1")}
                      </Typography>
                    </Box>
                    <Divider />

                    <Box
                      style={{
                        display: "flex",
                        padding: "10px",
                        justifyContent: "flex-end"
                      }}
                    >
                      <Button
                        disableElevation
                        type="button"
                        style={{ marginRight: "4px" }}
                        onClick={(e) => {
                          formik.resetForm()
                          handleDelete(e)
                        }}
                      >
                        {getLang(lang, "label.CANCEL")}
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        classes={{ root: classes.publishButton }}
                        disableElevation
                        type="submit"
                      >
                        {getLang(lang, "label.ADD")}
                      </Button>
                    </Box>
                  </>
                )}
              </Box>
            )}
          </form>
        )}
      </Formik>
    </Box>
  );
}
