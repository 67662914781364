import {
  orangeWheel,
  blueWheel,
  redBox,
  greenBox
} from "assets/img/instantGameImage";
import { GreenTemplateBox, RedTemplateBox } from "modules/instant-lucky-draw/utils/template/treasureBox";
import { BlueTemplateWheel, OrangeTemplateWheel } from "modules/instant-lucky-draw/utils/template/wheelOfFortune";

export const gameType = {
  wheelOfFortune: "wheel",
  treasureBox: "box"
}

export const instantGameTemplate = [
  {
    label: "Wheel of Fortune",
    name: gameType.wheelOfFortune,
    type: [
      {
        label: "1",
        name: "orange",
        previewImage: orangeWheel,
        config: OrangeTemplateWheel
      },
      {
        label: "2",
        name: "blue",
        previewImage: blueWheel,
        config: BlueTemplateWheel
      }
    ]
  },
  {
    label: "Treasure Box",
    name: gameType.treasureBox,
    type: [
      {
        label: "1",
        name: "red",
        previewImage: redBox,
        config: RedTemplateBox
      },
      {
        label: "2",
        name: "green",
        previewImage: greenBox,
        config: GreenTemplateBox
      }
    ]
  }
];
