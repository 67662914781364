import { setLoading, addAlert } from "modules/notification";
import { getApiLang } from "app/feature/constants";
import {
  addAdtInfoTitle,
  checkHasPendingProcess,
  deleteAdtInfoTitle,
  editAdtInfoTitle,
  exportAdtInfoForm,
  getAdditionalInfoDropdown,
  getAdditionalInfoSequence,
  getAdtInfoImportLog,
  getAdtInfoListing,
  getAdtInfoTitles,
  getAdtProductListing,
  getAdtSnListing,
  getImportProgress,
  importAdtInfoForm,
  updateAdditionalInfo,
  updateAdditionalInfoSequence,
  updateSnAdtInfo,
  validateAdtInfoForm,
} from "../action";

export const getAdtInfoListingMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    switch (type) {
      case getAdtInfoListing.pending.type:
        dispatch(setLoading({ id: getAdtInfoListing.typePrefix, state: true }));
        break;
      case getAdtInfoListing.fulfilled.type:
        dispatch(
          setLoading({ id: getAdtInfoListing.typePrefix, state: false })
        );
        break;
      case getAdtInfoListing.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({ id: getAdtInfoListing.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

const editAdtInfoTitleMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { payload, type } = action;

    switch (type) {
      case editAdtInfoTitle.pending.type:
        dispatch(
          setLoading({
            id: editAdtInfoTitle.typePrefix,
            state: true,
          })
        );
        break;
      case editAdtInfoTitle.fulfilled.type:
        dispatch(
          setLoading({
            id: editAdtInfoTitle.typePrefix,
            state: false,
          })
        );
        break;
      case editAdtInfoTitle.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: editAdtInfoTitle.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

const addAdtInfoTitleMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { payload, type } = action;
    const lang = getState().constant.languages;

    switch (type) {
      case addAdtInfoTitle.pending.type:
        dispatch(
          setLoading({
            id: addAdtInfoTitle.typePrefix,
            state: true,
          })
        );
        break;
      case addAdtInfoTitle.fulfilled.type:
        dispatch(
          setLoading({
            id: addAdtInfoTitle.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "success",
            message: getApiLang(lang, "success.SUCCESS_CREATE_ADT_INFO_TITLES"),
          })
        );
        break;
      case addAdtInfoTitle.rejected.type:
        dispatch(
          setLoading({
            id: addAdtInfoTitle.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

const getAdtInfoTitlesMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { payload, type } = action;

    switch (type) {
      case getAdtInfoTitles.pending.type:
        dispatch(
          setLoading({
            id: getAdtInfoTitles.typePrefix,
            state: true,
          })
        );
        break;
      case getAdtInfoTitles.fulfilled.type:
        dispatch(
          setLoading({
            id: getAdtInfoTitles.typePrefix,
            state: false,
          })
        );
        break;
      case getAdtInfoTitles.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: getAdtInfoTitles.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

const validateAdtInfoFormmMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { payload, type } = action;

    switch (type) {
      case validateAdtInfoForm.pending.type:
        dispatch(
          setLoading({
            id: validateAdtInfoForm.typePrefix,
            state: true,
          })
        );
        break;
      case validateAdtInfoForm.fulfilled.type:
        dispatch(
          setLoading({
            id: validateAdtInfoForm.typePrefix,
            state: false,
          })
        );
        break;
      case validateAdtInfoForm.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: validateAdtInfoForm.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

const importAdtInfoFormMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { payload, type } = action;

    switch (type) {
      case importAdtInfoForm.pending.type:
        dispatch(
          setLoading({
            id: importAdtInfoForm.typePrefix,
            state: true,
          })
        );
        break;
      case importAdtInfoForm.fulfilled.type:
        dispatch(
          setLoading({
            id: importAdtInfoForm.typePrefix,
            state: false,
          })
        );
        break;
      case importAdtInfoForm.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: importAdtInfoForm.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

const exportAdtInfoFormMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { payload, type } = action;

    switch (type) {
      case exportAdtInfoForm.pending.type:
        dispatch(
          setLoading({
            id: exportAdtInfoForm.typePrefix,
            state: true,
          })
        );
        break;
      case exportAdtInfoForm.fulfilled.type:
        dispatch(
          setLoading({
            id: exportAdtInfoForm.typePrefix,
            state: false,
          })
        );
        break;
      case exportAdtInfoForm.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: exportAdtInfoForm.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

const checkHasPendingProcessMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { payload, type } = action;

    switch (type) {
      case checkHasPendingProcess.pending.type:
        dispatch(
          setLoading({
            id: checkHasPendingProcess.typePrefix,
            state: true,
          })
        );
        break;
      case checkHasPendingProcess.fulfilled.type:
        dispatch(
          setLoading({
            id: checkHasPendingProcess.typePrefix,
            state: false,
          })
        );
        break;
      case checkHasPendingProcess.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: checkHasPendingProcess.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

const getAdditionalInfoDropdownMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { payload, type } = action;

    switch (type) {
      case getAdditionalInfoDropdown.pending.type:
        dispatch(
          setLoading({ id: getAdditionalInfoDropdown.typePrefix, state: true })
        );
        break;
      case getAdditionalInfoDropdown.fulfilled.type:
        dispatch(
          setLoading({ id: getAdditionalInfoDropdown.typePrefix, state: false })
        );
        break;
      case getAdditionalInfoDropdown.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({ id: getAdditionalInfoDropdown.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

const updateAdditionalInfoMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { payload, type } = action;

    switch (type) {
      case updateAdditionalInfo.pending.type:
        dispatch(
          setLoading({ id: updateAdditionalInfo.typePrefix, state: true })
        );
        break;
      case updateAdditionalInfo.fulfilled.type:
        dispatch(
          setLoading({ id: updateAdditionalInfo.typePrefix, state: false })
        );
        break;
      case updateAdditionalInfo.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({ id: updateAdditionalInfo.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

const getAdtSnListingMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { payload, type } = action;

    switch (type) {
      case getAdtSnListing.pending.type:
        dispatch(setLoading({ id: getAdtSnListing.typePrefix, state: true }));
        break;
      case getAdtSnListing.fulfilled.type:
        dispatch(setLoading({ id: getAdtSnListing.typePrefix, state: false }));
        break;
      case getAdtSnListing.rejected.type:
        const lang = getState().constant.languages;
        dispatch(setLoading({ id: getAdtSnListing.typePrefix, state: false }));
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

const getAdditionalInfoSequenceMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { payload, type } = action;

    switch (type) {
      case getAdditionalInfoSequence.pending.type:
        dispatch(
          setLoading({ id: getAdditionalInfoSequence.typePrefix, state: true })
        );
        break;
      case getAdditionalInfoSequence.fulfilled.type:
        dispatch(
          setLoading({ id: getAdditionalInfoSequence.typePrefix, state: false })
        );
        break;
      case getAdditionalInfoSequence.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({ id: getAdditionalInfoSequence.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

const updateAdditionalInfoSequenceMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { payload, type } = action;

    switch (type) {
      case updateAdditionalInfoSequence.pending.type:
        dispatch(
          setLoading({
            id: updateAdditionalInfoSequence.typePrefix,
            state: true,
          })
        );
        break;
      case updateAdditionalInfoSequence.fulfilled.type:
        dispatch(
          setLoading({
            id: updateAdditionalInfoSequence.typePrefix,
            state: false,
          })
        );
        break;
      case updateAdditionalInfoSequence.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: updateAdditionalInfoSequence.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

const updateSnAdtInfoMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { payload, type } = action;

    switch (type) {
      case updateSnAdtInfo.pending.type:
        dispatch(
          setLoading({
            id: updateSnAdtInfo.typePrefix,
            state: true,
          })
        );
        break;
      case updateSnAdtInfo.fulfilled.type:
        dispatch(
          setLoading({
            id: updateSnAdtInfo.typePrefix,
            state: false,
          })
        );
        break;
      case updateSnAdtInfo.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: updateSnAdtInfo.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

const getAdtInfoImportLogMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { payload, type } = action;

    switch (type) {
      case getAdtInfoImportLog.pending.type:
        dispatch(
          setLoading({
            id: getAdtInfoImportLog.typePrefix,
            state: true,
          })
        );
        break;
      case getAdtInfoImportLog.fulfilled.type:
        dispatch(
          setLoading({
            id: getAdtInfoImportLog.typePrefix,
            state: false,
          })
        );
        break;
      case getAdtInfoImportLog.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: getAdtInfoImportLog.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

const getImportProgressMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getImportProgress.pending.type:
        dispatch(
          setLoading({
            id: getImportProgress.typePrefix,
            state: true,
          })
        );
        break;

      case getImportProgress.fulfilled.type:
        dispatch(
          setLoading({
            id: getImportProgress.typePrefix,
            state: false,
          })
        );
        break;

      case getImportProgress.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: getImportProgress.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

const getAdtProductListingMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getAdtProductListing.pending.type:
        dispatch(
          setLoading({
            id: getAdtProductListing.typePrefix,
            state: true,
          })
        );
        break;

      case getAdtProductListing.fulfilled.type:
        dispatch(
          setLoading({
            id: getAdtProductListing.typePrefix,
            state: false,
          })
        );
        break;

      case getAdtProductListing.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: getAdtProductListing.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

const deleteAdtInfoTitleMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case deleteAdtInfoTitle.pending.type:
        dispatch(
          setLoading({
            id: deleteAdtInfoTitle.typePrefix,
            state: true,
          })
        );
        break;

      case deleteAdtInfoTitle.fulfilled.type:
        dispatch(
          setLoading({
            id: deleteAdtInfoTitle.typePrefix,
            state: false,
          })
        );
        break;

      case deleteAdtInfoTitle.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: deleteAdtInfoTitle.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

export default [
  getAdtInfoListingMiddleware,
  editAdtInfoTitleMiddleware,
  addAdtInfoTitleMiddleware,
  getAdtInfoTitlesMiddleware,
  validateAdtInfoFormmMiddleware,
  importAdtInfoFormMiddleware,
  exportAdtInfoFormMiddleware,
  checkHasPendingProcessMiddleware,
  getAdditionalInfoDropdownMiddleware,
  updateAdditionalInfoMiddleware,
  getAdtSnListingMiddleware,
  getAdditionalInfoSequenceMiddleware,
  updateAdditionalInfoSequenceMiddleware,
  updateSnAdtInfoMiddleware,
  getAdtInfoImportLogMiddleware,
  getImportProgressMiddleware,
  getAdtProductListingMiddleware,
  deleteAdtInfoTitleMiddleware,
];
