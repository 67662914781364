
import axios from "axios"

export function getRecallRequestorDropdown ({ search }) {
  return new Promise((resolve, reject) => {
    axios.get(`/distributor/admin/api/v1/recall/get-requestor-dropdown`, {
      params: {
          search,
      }
    })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data))
  });
};