import axios from "axios";
import PropTypes from "prop-types";

const ENDPOINT_URL = "/payment/api/v1/payment-method";

const post = ({ isDefault, payment_method }) => {
  const body = {
    payment_method,
    default: isDefault
  };

  return new Promise((resolve, reject) => {
    axios
      .post(ENDPOINT_URL, body)
      .then(response => resolve(response))
      .catch(error => {
        return reject(error.response.data);
      });
  });
};

post.defaultProps = {
  isDefault: false
};

post.propTypes = {
  isDefault: PropTypes.bool,
  payment_method: PropTypes.string.isRequired
};

const Delete = ({ id }) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${ENDPOINT_URL}/${id}`)
      .then(response => resolve(response))
      .catch(error => reject(error.response.data));
  });
};

const put = ({ id }) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${ENDPOINT_URL}/${id}/default`)
      .then(response => resolve(response))
      .catch(error => reject(error.response.data));
  });
};

const paymentMethod = {
  post,
  put,
  Delete
};

export default paymentMethod;
