import React from "react";
import InstagramFormComponent from "./instagramForm.component";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

const TYPES = ["new", "edit", "readonly"];

function InstagramFormContainer({
  type,
  handleResult,
  initialValues,
  handleDelete,
  handleClose
}) {
  const lang = useSelector(state => state.constant.languages)

  return (
    <InstagramFormComponent
      type={type}
      handleSubmit={handleResult}
      initialValues={initialValues}
      handleDelete={handleDelete}
      lang={lang}
      handleClose={handleClose}
    />
  );
}

InstagramFormContainer.defaultProps = {
  type: TYPES[0],
  initialValues: { URL: "" }
};

InstagramFormContainer.propTypes = {
  type: PropTypes.oneOf(TYPES).isRequired,
  handleResult: PropTypes.func,
  handleDelete: PropTypes.func,
  initialValues: PropTypes.object
};

export { TYPES };

export default InstagramFormContainer;
