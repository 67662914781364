import axios from "axios";

const endpoint_url = "/campaign/api/admin/lucky-draw-prizes";

const get = (parameters) => {
  return new Promise((resolve, reject) => {
    axios
      .get(endpoint_url, {
        params: {
          draw: parameters.draw,
          limit: parameters.length,
          skip: parameters.start,
          sortBy: parameters.column,
          orderBy: parameters.order,
          search: parameters.search,
          luckyDrawId: parameters.luckyDrawId
        }
      })
      .then((response) => resolve(response))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

const getAllPrize = {
  get
};

export default getAllPrize;
