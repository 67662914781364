import { Box } from "@material-ui/core";
import React from "react";
import Chart from 'react-apexcharts';

export default function TrendingChartComponent({
    chart
}) {

    function getStrokeColorType(data) {
        if (!data)
            return '#98A2B3'

        if (typeof data !== 'object')
            return '#98A2B3'

        if (data.length === 0)
            return '#98A2B3'

        // positive trend
        if (data[0] < data[data.length - 1])
            return '#32D583'

        // negative trend
        if (data[0] > data[data.length - 1])
            return '#F64E60'

        // no trend
        return '#98A2B3'
    }

    function getBackgroundColorType(data) {
        if (!data)
            return '#FCFCFD'

        if (typeof data !== 'object')
            return '#FCFCFD'

        if (data.length === 0)
            return '#FCFCFD'

        // positive trend
        if (data[0] < data[data.length - 1])
            return '#F6FEF9'

        // negative trend
        if (data[0] > data[data.length - 1])
            return '#FFFBFA'

        // no trend
        return '#FCFCFD'
    }

    const series = chart.datasets;
    const categories = chart.labels;
    const dataDisplay = chart.datasets[0].display;

    const options = {
        colors: [getStrokeColorType(chart.datasets[0].data)],
        chart: {
            toolbar: {
                show: false
            },
            background: getBackgroundColorType(chart.datasets[0].data),
            sparkline: {
                enabled: true
            }
        },
        xaxis: {
            categories: categories,
            labels: {
                show: false
            }
        },
        yaxis: {
            show: false
        },
        legend: {
            show: false
        },
        grid: {
            padding: {
                top: 15,
                bottom: 15,
            }
        },
        tooltip: {
            fixed: {
                enabled: false
            },
            x: {
                show: true,
            },
            y: {
                title: {
                    formatter: function (seriesName) {
                        return ''
                    }
                },
                formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
                    return dataDisplay ?  dataDisplay[dataPointIndex] : value;
                }

            }
        },
        stroke: {
            width: 2,
            // curve: 'smooth',
            colors: [getStrokeColorType(chart.datasets[0].data)]
        }
    }


    return (
        <Box
            style={{
                width: '120px'
            }}
        >

            <Chart
                series={series}

                options={options}

                type="line"
            >

            </Chart>
        </Box>
    )
}