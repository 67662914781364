import React from "react"
import { useSelector } from "react-redux";
import CreateAttributesDialogComponent from './createAttributesDialog.component';

export default function CreateAttributesDialogContainer({
  isOpen,
  handleClose,
  handleSubmit,
  isSubmittingAddOption
}) {
  const lang = useSelector(state => state.constant.languages)
  return (
    <CreateAttributesDialogComponent
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      isOpen={isOpen}
      isSubmittingAddOption={isSubmittingAddOption}
      lang={lang}
    />
  )
}