import React, { Children, cloneElement } from 'react';

function classnames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function CompanyTreeNodeRendererV2(props){
  const {
    children,
    listIndex,
    swapFrom,
    swapLength,
    swapDepth,
    scaffoldBlockPxWidth,
    lowerSiblingCounts,
    connectDropTarget,
    isOver,
    draggedNode,
    canDrop,
    treeIndex,
    treeId, // Delete from otherProps
    getPrevRow, // Delete from otherProps
    node, // Delete from otherProps
    path, // Delete from otherProps
    rowDirection, // Ignore
    ...otherProps
  } = props;
  // This is for have branches but not yet loaded
  if (Object.keys(node).length === 0) {
    return <></>
  }
  // Construct the scaffold representing the structure of the tree
  const scaffoldBlockCount = lowerSiblingCounts.length;
  const scaffold = [];

  if (path.length === 1) { // if is master branch
    let lineClass = '';
    if (node.expanded === true) {
      if( node.children && node.children.length > 0 ) {
        lineClass = 'rst__lineHalfVerticalBottom';
      }
    }
    scaffold.push(
      <div
        key={`pre_${0}`}
        style={{ width: scaffoldBlockPxWidth, top: 45, left: 68, zIndex: 1 }}
        className={classnames('rst__lineBlock', lineClass)}
      />
    );
  } else {
    lowerSiblingCounts.forEach((lowerSiblingCount, i) => {
      let lineClass = '';
      if (lowerSiblingCount > 0) { // got child
        if (i === 0) { // first
          lineClass = 'rst__lineHalfHorizontalRight rst__lineHalfVerticalBottom';
        } else if (i === scaffoldBlockCount - 1) { // last
          lineClass = 'rst__lineHalfHorizontalRight rst__lineFullVertical';
        } else { // middle
          lineClass = 'rst__lineFullVertical';
        }
      } else if (i === scaffoldBlockCount - 1) {
        lineClass = 'rst__lineHalfVerticalTop rst__lineHalfHorizontalRight';
      }
      scaffold.push(
        <div
          key={`pre_${1 + i}`}
          style={{ width: scaffoldBlockPxWidth, left: 68 }}
          className={classnames('rst__lineBlock', lineClass)}
        />
      );
      if (i !== 0 && i === scaffoldBlockCount - 1) { // more than one child and is the last
        scaffold.shift()
        if (node.expanded) {
          if (node.isLoading || !node.children || node.children.length === 0) {
            scaffold.push(
              <div
                key={`pre_${0}`}
                style={{ width: scaffoldBlockPxWidth, left: 68 }}
                className={classnames('rst__lineBlock', 'rst__lineFullHorizontal')}
              />
            );
          }
          else {
            scaffold.push(
              <div
                key={`pre_${0}`}
                style={{ width: scaffoldBlockPxWidth, left: 68 }}
                className={classnames('rst__lineBlock', 'rst__lineFullHorizontal', 'rst__lineHalfVerticalBottom')}
              />
            );
          }
        }
        else {
          scaffold.push(
            <div
              key={`pre_${0}`}
              style={{ width: scaffoldBlockPxWidth, left: 68 }}
              className={classnames('rst__lineBlock', 'rst__lineFullHorizontal')}
            />
          );
        }
      }
    });
  }
  let style = { left: scaffoldBlockPxWidth * scaffoldBlockCount };
  return connectDropTarget(
    <div
      {...otherProps}
      className={'rst__node'}
    >
      {scaffold}
      <div className="rst__nodeContent" style={style}>
        {Children.map(children, child =>
          cloneElement(child, {
            isOver,
            canDrop,
            draggedNode,
          })
        )}
      </div>
    </div>
  );
}
