import axios from "axios";

const endpoint_url = "/serialnumber/api/v1/warranty-setting/assign-product";

const post = ({ warrantySettingId, productId }) => {
  const body = {
    warranty_setting_id: warrantySettingId,
    product_id: productId
  };

  return new Promise((resolve, reject) => {
    axios
      .post(endpoint_url, body)
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

const assignWarranty = {
  post
};

export default assignWarranty;
