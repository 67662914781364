import React, { useEffect, useCallback } from "react";
import OnlineEmployeePanelComponent from "./onlineEmployeePanel.component";
import { ROOT } from "../../../config/employee.route";
import { useHistory } from "react-router-dom";
import { selectLoading } from "modules/notification";
import {
  fetchOnlineEmployee,
  fetchMoreOnlineEmployee
} from "modules/employee/redux";
import { useDispatch, useSelector } from "react-redux";

export default function OnlineEmployeePanelContainer() {
  const dispatch = useDispatch();
  const history = useHistory();
  const isFetching = useSelector((state) =>
    selectLoading(state, fetchOnlineEmployee.typePrefix)
  );
  const hasMoretoLoad = useSelector(
    (state) => state.employee.onlineEmployeeList.hasMoretoLoad
  );
  const onlineEmployeeData = useSelector(
    (state) => state.employee.onlineEmployeeList.data
  );
  const isLoadingOnlineError = useSelector(
    (state) => state.employee.isLoadingOnlineError
  );

  const lang = useSelector(state => state.constant.languages);

  useEffect(() => {
    dispatch(fetchOnlineEmployee());
  }, [dispatch]);

  const fetchMoreData = useCallback(() => {
    if (!isFetching && hasMoretoLoad) {
      dispatch(fetchMoreOnlineEmployee());
    }
  }, [isFetching, hasMoretoLoad, dispatch]);

  const handleReload = () => {
    dispatch(fetchOnlineEmployee());
  };

  async function handleView(id) {
    history.push(`${ROOT}/View/${id}`);
  }

  return (
    <OnlineEmployeePanelComponent
      handleView={handleView}
      fetchMoreData={fetchMoreData}
      onlineEmployeeData={onlineEmployeeData}
      isFetching={isFetching}
      isLoadingOnlineError={isLoadingOnlineError}
      handleReload={handleReload}
      lang={lang}
    />
  );
}
