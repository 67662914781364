import React from "react";
import {
  makeStyles,
  Paper,
  Box,
  Typography,
  IconButton,
  Divider,
  Button,
  Grid,
  MenuItem,
  DialogActions,
  DialogContent
} from "@material-ui/core";
import Dialog from "components/dialog/customDialog";
import CloseIcon from '@material-ui/icons/Close';
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import InputTextField from "components/input/inputTextField";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles((theme) => ({
  root: {
    boxShadow: theme.shadows[5],
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "space-between",
    width: 600,
    borderRadius: "4px",
    fontSize: 14,
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    }
  },
  tagFields: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr",
    gridColumnGap: theme.spacing(1)
  },
  summary: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridColumnGap: theme.spacing(2),
    padding: "8px 0",
    borderBottom: "1px solid",
    borderBottomColor: theme.palette.divider,
    "& h6:last-child": {
      fontWeight: "normal"
    }
  },
  header: {
    paddingLeft: theme.spacing(3),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  detailsWrapper: {
    width: "75%",
    padding: theme.spacing(2, 3, 2, 3)
  }
}));

function getTagString({ prefix, snDigit, postfix, type }) {
  return `${prefix} {${snDigit}} ${postfix} {${type}}`;
}

export default function NewTagDialogComponent({
  open,
  isSummary,
  handleSubmit,
  handleReset,
  handleClose,
  handleBack,
  lang,
  tags,
  addAlert
}) {
  const classes = useStyle();

  return (
    <Dialog
      isOpen={open}
      handleClose={handleClose}
      disableEnforceFocus={true}
      disableAutoFocus={true}
      disableEscapeKeyDown={true}
    >
      <Paper elevation={0} className={classes.root}>
        <div className={classes.header}>
          <Typography variant="subtitle2" style={{ fontWeight: "bold" }}>
            {getLang(lang, 'label.CREATE_SERIAL_NUMBER_PATTERN')}
          </Typography>
          <IconButton color="primary" onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </div>
        <Divider />
        <Formik
          initialValues={{
            prefix: "",
            snDigit: "",
            postfix: "",
            type: "number",
            genType: "S",
            serialType: 1
            // assignToProduct: false,
            // product: null
          }}
          validationSchema={Yup.object({
            snDigit: Yup.number().required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
            type: Yup.string().required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED"))
          })} 
          onSubmit={handleSubmit}
        >
          {(formik) => {
            const tagExists = tags.filter(obj => {
              const formikValues = formik.values;
              return obj.format === `${formikValues.prefix},${formikValues.snDigit},${formikValues.postfix},${formikValues.type}`
            }).length === 0;

            function validateOnlyAlphabet(value) {
              const reg = new RegExp("^[a-zA-Z]*$");
              return reg.test(value);
            }

            return (
              <form onSubmit={formik.handleSubmit}>
                <DialogContent>
                  {isSummary ? (
                    <>
                      <Typography variant="subtitle1">Summary</Typography>
                      {[
                        { label: "Serial Number Type", value: "Running" },
                        {
                          label: "Serial Number Pattern",
                          value: getTagString(formik.values),
                        },
                      ].map((field, index) => (
                        <Box key={index} className={classes.summary}>
                          <Typography variant="subtitle2">
                            {field.label}
                          </Typography>
                          <Typography variant="subtitle2">
                            {field.value}
                          </Typography>
                        </Box>
                      ))}
                    </>
                  ) : (
                    <Box style={{ display: "flex", placeContent: "center" }}>
                      <Box className={classes.detailsWrapper}>
                        <Grid
                          container
                          style={{
                            alignItems: "center",
                            justifyContent: "center"
                          }}
                        >
                          <Grid item md={3} style={{ width: "100%" }}>
                            <Typography variant="body2" color="textSecondary">
                              {getLang(lang, 'label.TYPE')}
                            </Typography>
                          </Grid>
                          <Grid item md={9} style={{ width: "100%" }}>
                            <InputTextField
                              select
                              hiddenLabel
                              variant="filled"
                              size="small"
                              fullWidth
                              margin="dense"
                              InputProps={{
                                style: {
                                  padding: "0",
                                  backgroundColor: "#ECEFF0",
                                  borderRadius: "4px"
                                },
                              }}
                              disabled={formik.isSubmitting}
                              {...formik.getFieldProps("type")}
                              error={
                                formik.touched.type && formik.errors.type
                                  ? true
                                  : false
                              }
                              helperText={ErrorMessage({ name: "type" })}
                            >
                              {[
                                { label: getLang(lang, 'label.NUMBER'), value: "number" },
                                { label: getLang(lang, 'label.ALPHANUMERIC'), value: "alphanumeric" },
                              ].map((field, index) => (
                                <MenuItem
                                  value={field.value}
                                  key={index}
                                  onClick={() => {
                                    formik.setFieldValue("prefix", "");
                                    formik.setFieldValue("postfix", "");
                                  }}
                                >
                                  {field.label}
                                </MenuItem>
                              ))}
                            </InputTextField>
                          </Grid>
                          {formik.values.type === "number" &&
                            <>
                              <Grid item md={3} style={{ width: "100%" }}>
                                <Typography variant="body2" color="textSecondary">
                                  {getLang(lang, 'label.PREFIX')}
                                </Typography>
                              </Grid>
                              <Grid item md={9} style={{ width: "100%" }}>
                                <InputTextField
                                  hiddenLabel
                                  variant="filled"
                                  size="small"
                                  fullWidth
                                  margin="dense"
                                  inputProps={
                                    formik.getFieldProps("type").value ===
                                      "alphanumeric"
                                      ? {
                                        style: {
                                          padding: "5px",
                                          backgroundColor: "#ECECEC",
                                          borderRadius: "4px"
                                        }
                                      }
                                      : {
                                        style: {
                                          padding: "5px",
                                          backgroundColor: "#ECEFF0",
                                          borderRadius: "4px"
                                        }
                                      }
                                  }
                                  disabled={formik.isSubmitting}
                                  {...formik.getFieldProps("prefix")}
                                  error={
                                    formik.touched.prefix && formik.errors.prefix
                                      ? true
                                      : false
                                  }
                                  helperText={ErrorMessage({ name: "prefix" })}
                                  onChange={e => {
                                    const value = e.target.value.toLocaleUpperCase();
                                    if (validateOnlyAlphabet(value)) {
                                      formik.setFieldValue("prefix", value);
                                    }
                                    else {
                                      addAlert("Only alphabet allowed");
                                    }
                                  }}
                                />
                              </Grid>
                            </>
                          }
                          <Grid item md={3} style={{ width: "100%" }}>
                            <Typography variant="body2" color="textSecondary">
                              {getLang(lang, 'label.LENGTH')}
                            </Typography>
                          </Grid>
                          <Grid item md={9} style={{ width: "100%" }}>
                            <InputTextField
                              hiddenLabel
                              variant="filled"
                              size="small"
                              fullWidth
                              margin="dense"
                              type="number"
                              disabled={formik.isSubmitting}
                              {...formik.getFieldProps("snDigit")}
                              error={
                                formik.touched.snDigit && formik.errors.snDigit
                                  ? true
                                  : false
                              }
                              helperText={ErrorMessage({ name: "snDigit" })}
                              onKeyDown={(e) => {
                                const invalidChars = [
                                  "-",
                                  "+",
                                  "e",
                                  ".",
                                ];
                                if (invalidChars.includes(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                              onChange={e => {
                                const value = e.target.value;

                                if (value > 9) {
                                  addAlert("Maximum 9 digits");
                                  formik.setFieldValue("snDigit", 9);
                                }
                                else {
                                  formik.setFieldValue("snDigit", value);
                                }

                              }}
                              inputProps={{
                                min: 1,
                                max: 10
                              }}
                            />
                          </Grid>
                          {formik.values.type === "number" &&
                            <>
                              <Grid item md={3} style={{ width: "100%" }}>
                                <Typography variant="body2" color="textSecondary">
                                  {getLang(lang, 'label.POSTFIX')}
                                </Typography>
                              </Grid>
                              <Grid item md={9} style={{ width: "100%" }}>
                                <InputTextField
                                  hiddenLabel
                                  variant="filled"
                                  size="small"
                                  fullWidth
                                  margin="dense"
                                  inputProps={
                                    formik.getFieldProps("type").value ===
                                      "alphanumeric"
                                      ? {
                                        style: {
                                          padding: "5px",
                                          backgroundColor: "#ECECEC",
                                          borderRadius: "4px"
                                        }
                                      }
                                      : {
                                        style: {
                                          padding: "5px",
                                          backgroundColor: "#ECEFF0",
                                          borderRadius: "4px"
                                        }
                                      }
                                  }
                                  disabled={
                                    formik.isSubmitting ||
                                    formik.getFieldProps("type").value ===
                                    "alphanumeric"
                                  }
                                  {...formik.getFieldProps("postfix")}
                                  error={
                                    formik.touched.postfix && formik.errors.postfix
                                      ? true
                                      : false
                                  }
                                  helperText={ErrorMessage({ name: "postfix" })}
                                  onChange={e => {
                                    const value = e.target.value.toLocaleUpperCase();

                                    if (validateOnlyAlphabet(value)) {
                                      formik.setFieldValue("postfix", value);
                                    }
                                    else {
                                      addAlert(getLang(lang, 'message.warning.ONLY_ALPHABET_ALLOWED'));
                                    }
                                  }}
                                />
                              </Grid>
                            </>
                          }
                        </Grid>
                        {!tagExists &&
                          <Grid item={12}>
                            <Box textAlign="center">
                              <Typography color="error">{getLang(lang,'paragraph.TAG_ALREADY_IN_YOUR_SYSTEM')}</Typography>
                            </Box>
                          </Grid>
                        }
                      </Box>
                      {/* <FormControlLabel
                    control={
                      <Checkbox
                        name="checkedA"
                        checked={formik.values.assignToProduct}
                        onChange={ev => {
                          formik.setFieldValue(
                            "assignToProduct",
                            ev.target.checked
                          );
                        }}
                      />
                    }
                    label="Assign to product"
                  />
                  <ProductSelect
                    disabled={
                      formik.isSubmitting || !formik.values.assignToProduct
                    }
                    value={formik.values.product}
                    handleChange={(e, newValue) => {
                      formik.setFieldValue("product", newValue);
                    }}
                    error={formik.touched.product && formik.errors.product}
                    helperText={ErrorMessage({ name: "product" })}
                  /> */}
                    </Box>
                  )}
                </DialogContent>
                <DialogActions>
                  <Button
                    autoFocus
                    onClick={(e) => {
                      if (isSummary) {
                        handleBack();
                      } else {
                        formik.handleReset(e);
                        handleReset();
                      }
                    }}
                    disabled={formik.isSubmitting}
                    color="primary"
                  >
                    {isSummary ? getLang(lang,'label.BACK'): getLang(lang,'label.CANCEL')}
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    disableElevation
                    autoFocus
                    type="submit"
                    disabled={formik.isSubmitting || !tagExists}
                  >
                    {isSummary ? getLang(lang,'label.CREATE') : getLang(lang,'label.CONTINUE')}
                  </Button>
                </DialogActions>
              </form>
            )
          }
          }
        </Formik>
      </Paper>
    </Dialog>
  );
}
