import React from "react";
import {
  makeStyles,
  Box,
  Typography,
  IconButton,
  Button,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  DialogContent,
  DialogActions,
  Divider,
  Paper,
} from "@material-ui/core";
import {
  RemoveCircleOutlineOutlined as RemoveCircleIcon,
  AddCircleOutlineOutlined as AddCircleIcon,
} from "@material-ui/icons";
import { Field, Formik } from "formik";
import { getLang } from "app/feature/constants";
import InputTextField from "components/input/inputTextField";
import AdtInfoTitleExportPanel from "../adtInfoTitleExportPanel";
import languages from "lib/constants/languages";
import LanguageSelect from "../../select/languageSelect";

const useStyle = makeStyles((theme) => ({
  languageContainer: {
    border: "1px solid #D0D5DD",
    padding: 8,
    borderRadius: 4,
  },
  chip: {
    padding: "0px 4px",
    backgroundColor: "#ececec",
    marginRight: "0.25em",
    marginBottom: 4,
    marginTop: 4,
    border: "none",
    boxShadow:
      "0px 3px 1px -2px rgba(0,0,0,0.1), 0px 2px 2px 0px rgba(0,0,0,0.04), 0px 1px 5px 0px rgba(0,0,0,0.02)",
  },
  form: {
    backgroundColor: "#F2F4F7",
    maxHeight: "70vh",
    overflowY: "auto",
    padding: 16,
  },
  greenLabel: {
    color: "#6AAF68",
  },
  squareButton: {
    height: 45,
    width: 45,
    minWidth: "fit-content",
    padding: 0,
    backgroundColor: "white",
    border: "1px solid #D0D5DD",
    borderRadius: 8,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

function RenderFormikField({
  name,
  onChange,
  value,
  disabled,
  placeholder,
  error,
  formik,
}) {
  return (
    <>
      <Field name={name}>
        {({ field, form, meta }) => {
          return (
            <InputTextField
              {...field}
              value={value}
              hiddenLabel={true}
              variant="filled"
              fullWidth
              type={formik.values.type === 2 ? "number" : "text"}
              size="small"
              disabled={disabled}
              onKeyDown={(e) => {
                if (formik.values.type === 2) {
                  const invalidChars = ["-", "+", "e", "."];
                  if (invalidChars.includes(e.key)) {
                    e.preventDefault();
                  }
                }
              }}
              placeholder={placeholder}
              InputStyle={{
                backgroundColor: "white",
                border: "1px solid #D0D5DD",
                borderRadius: "4px",
              }}
              inputProps={{
                min: 0,
                // onBlur: () => {
                //   onBlur(name, index, value, range);
                // },
              }}
              error={error ? true : false}
              onChange={({ target: { value } }) => {
                if (formik.values.type === 2) {
                  const regex = /^[0-9]+$/;
                  if (value && !value.match(regex)) {
                    return;
                  }
                }

                onChange(value);
              }}
              helperText={error}
            />
          );
        }}
      </Field>
    </>
  );
}

export default function GenerateAdtInfoImportFilePanelComponent({
  handleSubmit,
  lang,
  handleBack,
  isExporting,
  validate,
}) {
  const classes = useStyle();

  return (
    <Box>
      <Formik
        initialValues={{
          type: 1,
          ranges: [
            {
              from: "",
              to: "",
            },
          ],
          languages: [languages.find((lang) => lang.code === "EN")],
          isSelectAll: false,
          selectedIds: [],
          errors: { ranges: [] },
          hasOverlapped: false,
        }}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {(formik) => {
          return (
            <form onSubmit={formik.handleSubmit}>
              <DialogContent className={classes.form}>
                <Box mb={2}>
                  <Typography variant="body1">
                    <u>
                      <b>{getLang(lang, "label.GENERATE_TEMPLATE")}</b>
                    </u>
                  </Typography>
                </Box>
                <Box>
                  <Box mb={2}>
                    <Paper elevation={0}>
                      <Box p={2}>
                        <Box>
                          <Typography variant="body1">
                            <b>{getLang(lang, "label.RANGE")}</b>
                          </Typography>
                        </Box>
                        <FormControl>
                          <RadioGroup
                            row
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={formik.values.type}
                            onChange={({ target: { value } }) => {
                              if (formik.values.type !== parseInt(value)) {
                                formik.setFieldValue("type", parseInt(value));
                                formik.setFieldValue("ranges", [
                                  {
                                    from: "",
                                    to: "",
                                  },
                                ]);
                                formik.setFieldValue("errors", { ranges: [] });
                                formik.setFieldValue("hasOverlapped", false);
                              }
                            }}
                          >
                            <FormControlLabel
                              value={1}
                              control={<Radio size="small" />}
                              label={
                                <Typography
                                  variant="body1"
                                  style={{ marginRight: 4 }}
                                >
                                  {getLang(lang, "label.SERIAL_NUMBER")}
                                </Typography>
                              }
                            />
                            <FormControlLabel
                              value={2}
                              control={<Radio size="small" />}
                              label={
                                <Typography
                                  variant="body1"
                                  style={{ marginRight: 4 }}
                                >
                                  {getLang(lang, "label.SEQUENCE_NUMBER")}
                                </Typography>
                              }
                            />
                          </RadioGroup>
                        </FormControl>
                        {formik.values.ranges.map((range, i) => (
                          <Box
                            display="flex"
                            alignItems="flex-start"
                            mt={i === 0 ? 0 : 1}
                            key={i}
                          >
                            <RenderFormikField
                              name={`ranges.${i}.from`}
                              onChange={(value) => {
                                let ranges = [...formik.values.ranges];
                                ranges[i] = {
                                  ...ranges[i],
                                  from: value,
                                };
                                formik.setFieldValue("ranges", ranges);

                                let errors = [...formik.values.errors.ranges];
                                delete errors[i]?.from;
                                formik.setFieldValue("errors.ranges", errors);

                                formik.setFieldValue("hasOverlapped", false);
                              }}
                              placeholder={getLang(lang, "label.FROM")}
                              value={range.from}
                              formik={formik}
                              type={formik.values.type}
                              error={
                                formik.values.errors.ranges.length >= i &&
                                formik.values.errors.ranges[i]?.from
                                  ? formik.values.errors.ranges[i]?.from
                                  : null
                              }
                            />
                            <Box px={1.5} mt={0.5}>
                              <Typography variant="h6">-</Typography>
                            </Box>
                            <RenderFormikField
                              name={`ranges.${i}.to`}
                              onChange={(value) => {
                                let ranges = [...formik.values.ranges];
                                ranges[i] = {
                                  ...ranges[i],
                                  to: value,
                                };
                                formik.setFieldValue("ranges", ranges);

                                let errors = [...formik.values.errors.ranges];
                                delete errors[i]?.to;
                                formik.setFieldValue("errors.ranges", errors);

                                formik.setFieldValue("hasOverlapped", false);
                              }}
                              placeholder={getLang(lang, "label.TO")}
                              value={range.to}
                              formik={formik}
                              type={formik.values.type}
                              error={
                                formik.values.errors.ranges.length >= i &&
                                formik.values.errors.ranges[i]?.to
                                  ? formik.values.errors.ranges[i]?.to
                                  : null
                              }
                            />
                            <Box display="flex" mt={-0.5} ml={0.5}>
                              <IconButton
                                size="small"
                                onClick={() => {
                                  let temp = [...formik.values.ranges];
                                  temp.splice(i, 1);
                                  formik.setFieldValue("ranges", temp);

                                  let errors = [...formik.values.errors.ranges];
                                  errors.splice(i, 1);
                                  formik.setFieldValue("errors.ranges", errors);

                                  formik.setFieldValue("hasOverlapped", false);
                                }}
                                disabled={formik.values.ranges.length <= 1}
                              >
                                <Box className={classes.squareButton}>
                                  <RemoveCircleIcon
                                    style={{
                                      color: "#F97066",
                                      opacity:
                                        formik.values.ranges.length <= 1
                                          ? 0.7
                                          : 1,
                                    }}
                                  />
                                </Box>
                              </IconButton>
                              <IconButton
                                size="small"
                                onClick={() => {
                                  let temp = [...formik.values.ranges];
                                  temp.push({
                                    from: "",
                                    to: "",
                                  });
                                  formik.setFieldValue("ranges", temp);
                                }}
                              >
                                <Box className={classes.squareButton}>
                                  <AddCircleIcon style={{ color: "#6AAF68" }} />
                                </Box>
                              </IconButton>
                            </Box>
                          </Box>
                        ))}
                        {formik.values.hasOverlapped && (
                          <Box m={1}>
                            <span
                              style={{
                                color: "#f44336",
                                fontSize: "0.75rem",
                              }}
                            >
                              {getLang(lang, "message.error.RANGES_OVRELAPPED")}
                            </span>
                          </Box>
                        )}
                      </Box>
                    </Paper>
                  </Box>
                  <Box mb={3}>
                    <Paper elevation={0}>
                      <Box p={2}>
                        <Box mb={1}>
                          <Typography variant="body1">
                            <b>{getLang(lang, "label.LANGUAGE")}</b>
                          </Typography>
                        </Box>
                        <Box>
                          <LanguageSelect
                            value={formik.values.languages}
                            handleChange={(values) => {
                              formik.setFieldValue("languages", values);
                            }}
                            isClearable={false}
                            style={{
                              height: 43,
                              background: "white",
                              border: "1px solid #D0D5DD",
                              borderRadius: 4,
                            }}
                            isEmpty={!formik.values.languages.length}
                            placeholder={getLang(
                              lang,
                              "placeholder.SELECT_LANGUAGE"
                            )}
                          />
                        </Box>
                      </Box>
                    </Paper>
                  </Box>
                  <Box mb={2}>
                    <AdtInfoTitleExportPanel formik={formik} />
                  </Box>
                </Box>
              </DialogContent>
              <Divider />
              <DialogActions>
                <Box>
                  <Button
                    autoFocus
                    onClick={(e) => {
                      handleBack();
                    }}
                    disabled={formik.isSubmitting}
                    color="primary"
                  >
                    {getLang(lang, "label.BACK")}
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    disableElevation
                    autoFocus
                    disabled={
                      (!formik.values.isSelectAll &&
                        !formik.values.selectedIds.length) ||
                      isExporting ||
                      !formik.values.languages.length
                    }
                    onClick={() => {
                      let valid = validate(formik);
                      if (valid) {
                        formik.handleSubmit();
                      }
                    }}
                  >
                    {isExporting
                      ? getLang(lang, "label.LOADING")
                      : getLang(lang, "label.DOWNLOAD")}
                  </Button>
                </Box>
              </DialogActions>
            </form>
          );
        }}
      </Formik>
    </Box>
  );
}
