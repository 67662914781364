import React, { useState, useRef, useEffect } from "react"
import { selectLoading } from "modules/notification"
import { useSelector, useDispatch } from "react-redux"
import { cloneDeep } from "lodash"
import GiftDropdownSelectComponent from "./giftDropdownSelect.component"
import GiftDropdownSelectPanelComponent from "./giftDropdownSelectPanel.component"
import { getGiftDropdown } from "modules/consumer/redux/action/consumer.action"

function GiftDropdownSelectContainer({
  handleChange,
  disabled,
  value,
  styles, 
  textStyles,
  placeholder,
  id
}) {
  const isFetching = useSelector(state => selectLoading(state, getGiftDropdown.typePrefix))
  const giftDropdown = useSelector((state) => state.consumer.giftDropdown)
  const lang = useSelector(state => state.constant.languages)
  const [open, setOpen] = useState(false)
  const [isOpenList] = useState(false)
  const anchorRef = useRef(null)
  const prevOpen = useRef(open)
  const prevOpenList = useRef(isOpenList)
  const [selectAll, setSelectAll] = useState(false)
  const [search, setSearch] = useState("")
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getGiftDropdown({ id, search }))
  }, [dispatch, id, search])

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])

  useEffect(() => {
    if (prevOpenList.current === true && isOpenList === false) {
      anchorRef.current.focus()
    }

    prevOpenList.current = isOpenList
  }, [isOpenList])

  useEffect(() => {
    setSelectAll(value.length === giftDropdown.length)
  }, [value, giftDropdown])

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
    setSearch("")
  }

  const handleSelectAll = async () => {
    if(!selectAll){
      let selectedGifts = giftDropdown.map(p => {
        return p.id
      })
      handleChange(selectedGifts)
    }else{
      handleChange([])
    }
  }

  const handleSelectGift = (gift) => {
    let temp = cloneDeep(value)

    if(temp.findIndex(t => t === gift.id) >= 0){
      temp = temp.filter( t => t !== gift.id )
    }else{
      temp.push(gift.id)
    }
    handleChange(temp)
  }

  const handleSearch = (value) => {
    setSearch(value)
  }

  return (
    <>
      <GiftDropdownSelectComponent
        anchorRef={anchorRef}
        disabled={disabled}
        value={value}
        handleClick={handleOpen}
        styles={styles}
        textStyles={textStyles}
        placeholder={placeholder}
      />
      <GiftDropdownSelectPanelComponent
        selectAll={selectAll}
        value={value}
        anchorRef={anchorRef}
        open={open}
        isOpenList={isOpenList}
        handleClose={handleClose}
        isFetching={isFetching}
        giftDropdown={giftDropdown}
        handleSelectAll={handleSelectAll}
        handleSelectGift={handleSelectGift}
        handleSearch={handleSearch}
        lang={lang}
      />
    </>
  )
}

export default GiftDropdownSelectContainer