import axios from "axios";

const url = "/serialnumber/api/v1/product/brand-models/set-image";

export function setBrandImage({
  id, imageUrl
}) {
  return new Promise((resolve, reject) => {
    axios.post(url, {
      id, image_url: imageUrl
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data))
  })
}
