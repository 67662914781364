import axios from "axios";

const validateAdtInfoForm = async (parameters) => {
  return new Promise((resolve, reject) => {
    const data = new FormData();
    data.append("file", parameters.file);

    axios
      .post(
        `/serialnumber/api/v1/additional-info/import/validate`,
        data,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data));
  });
};

export default validateAdtInfoForm;
