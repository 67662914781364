import { createAsyncThunk } from "@reduxjs/toolkit";
import { ADDITIONAL_INFO } from "../../constants";
import serialNumberApi from "app/api/serialnumber";

export const getAdtInfoListing = createAsyncThunk(
  `${ADDITIONAL_INFO}/getAdtInfoListing`,
  async (payload, thunkApi) => {
    return serialNumberApi
      .getAdtInfoListing(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const editAdtInfoTitle = createAsyncThunk(
  `${ADDITIONAL_INFO}/editAdtInfoTitle`,
  async (payload, thunkApi) => {
    return serialNumberApi
      .editAdtInfoTitle(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const addAdtInfoTitle = createAsyncThunk(
  `${ADDITIONAL_INFO}/addAdtInfoTitle`,
  async (payload, thunkApi) => {
    return serialNumberApi
      .addAdtInfoTitle(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

// for export dialog
export const getAdtInfoTitles = createAsyncThunk(
  `${ADDITIONAL_INFO}/getAdtInfoTitles`,
  async (payload, thunkApi) => {
    return serialNumberApi
      .getAdtInfoTitles(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const validateAdtInfoForm = createAsyncThunk(
  `${ADDITIONAL_INFO}/validateAdtInfoForm`,
  async (payload, thunkApi) => {
    return serialNumberApi
      .validateAdtInfoForm(payload)
      .then((response) => response)
      .catch((error) => error);
  }
);

export const importAdtInfoForm = createAsyncThunk(
  `${ADDITIONAL_INFO}/importAdtInfoForm`,
  async (payload, thunkApi) => {
    return serialNumberApi
      .importAdtInfoForm(payload)
      .then((response) => response)
      .catch((error) => error);
  }
);

export const exportAdtInfoForm = createAsyncThunk(
  `${ADDITIONAL_INFO}/exportAdtInfoForm`,
  async (payload, thunkApi) => {
    return serialNumberApi
      .exportAdtInfoForm(payload)
      .then((response) => response)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const checkHasPendingProcess = createAsyncThunk(
  `${ADDITIONAL_INFO}/checkHasPendingProcess`,
  async (payload, thunkApi) => {
    return serialNumberApi
      .checkHasPendingProcess(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const getAdditionalInfoDropdown = createAsyncThunk(
  `${ADDITIONAL_INFO}/getAdditionalInfoDropdown`,
  async (payload, thunkApi) => {
    return serialNumberApi
      .getAdditionalInfoDropdown(payload)
      .then((response) => response)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const updateAdditionalInfo = createAsyncThunk(
  `${ADDITIONAL_INFO}/updateAdditionalInfo`,
  async (payload, thunkApi) => {
    return serialNumberApi
      .updateAdditionalInfo(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const getAdtSnListing = createAsyncThunk(
  `${ADDITIONAL_INFO}/getAdtSnListing`,
  async (payload, thunkApi) => {
    return serialNumberApi
      .getAdtSnListing(payload)
      .then((response) => response)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const getAdtProductListing = createAsyncThunk(
  `${ADDITIONAL_INFO}/getAdtProductListing`,
  async (payload, thunkApi) => {
    return serialNumberApi
      .getAdtProductListing(payload)
      .then((response) => response)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const getAdditionalInfoSequence = createAsyncThunk(
  `${ADDITIONAL_INFO}/getAdditionalInfoSequence`,
  async (payload, thunkApi) => {
    return serialNumberApi
      .getAdditionalInfoSequence(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const updateAdditionalInfoSequence = createAsyncThunk(
  `${ADDITIONAL_INFO}/updateAdditionalInfoSequence`,
  async (payload, thunkApi) => {
    return serialNumberApi
      .updateAdditionalInfoSequence(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const updateSnAdtInfo = createAsyncThunk(
  `${ADDITIONAL_INFO}/updateSnAdtInfo`,
  async (payload, thunkApi) => {
    return serialNumberApi
      .updateSnAdtInfo(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const getAdtInfoImportLog = createAsyncThunk(
  `${ADDITIONAL_INFO}/getAdtInfoImportLog`,
  async (payload, thunkApi) => {
    return serialNumberApi
      .getAdtInfoImportLog(payload)
      .then((response) => response)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const getImportProgress = createAsyncThunk(
  `${ADDITIONAL_INFO}/getImportProgress`,
  async (payload, thunkApi) => {
    return serialNumberApi
      .getImportProgress(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const deleteAdtInfoTitle = createAsyncThunk(
  `${ADDITIONAL_INFO}/deleteAdtInfoTitle`,
  async (payload, thunkApi) => {
    return serialNumberApi
      .deleteAdtInfoTitle(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);
