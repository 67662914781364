import React from "react";
import { Paper, makeStyles, Grid, Box } from "@material-ui/core";
import SubscriptionPlanCard from "../../card/subscriptionPlanCard";
import BillingDetailCard from "../../card/billingDetailCard";
import PaymentMethodCard from "../../card/paymentMethodCard";

const style = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(2)
    },
    justifyItems: "center"
  }
});

const useStyles = makeStyles(style);


export default function BillingOverviewPanelComponent({ isOwner, registrationType }) {
  const classes = useStyles();

  return (
    <Box>
      <Paper elevation={0} className={classes.root}>
        <Grid container spacing={4}>
          {(isOwner && registrationType === 1) &&
            <Grid item xs={12} sm={6} md={6} style={{ width: "100%" }}>
              <SubscriptionPlanCard />
            </Grid>
          }
          <Grid item xs={12} sm={isOwner ? 6 : 12} md={isOwner ? 6 : 12} style={{ width: "100%" }}>
            <BillingDetailCard />
          </Grid>
        </Grid>

        <PaymentMethodCard />
      </Paper>
    </Box>
  );

}
