import axios from "axios";

const fetchTableLength = (parameter) => {
  return new Promise((resolve, reject) => {
    axios.post(
      `/serialnumber/api/v1/serialnumbers/get-pagination-total-record`,
      {
        'search_column': parameter.searchColumn,
        'search': parameter.search,
        'product_uuids': parameter.products,
        'node_uuids': parameter.nodes,
        'status': parameter.status,
        'additional_info': parameter.additionalInfo,
        'product_not_assigned': parameter.productNotAssigned,
      }
    )
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  });
}

export default fetchTableLength;
