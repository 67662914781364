import React from "react";
import {
  makeStyles,
  Box,
  Typography,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Radio,
  Paper,
} from "@material-ui/core";
import { convertToNumberString } from "lib/generalUtility";
//import PerfectScrollbar from "react-perfect-scrollbar"
import { getLang } from "app/feature/constants";
import { SkeletonTableRow } from "components/skeleton/skeleton.component";
import RefreshTable from "components/table/refreshTable";
import SearchableSelect from "components/select/searchableSelect";
import {
  SERIAL_NUMBER_TYPE_ALPHANUMERIC,
  SERIAL_NUMBER_TYPE_NUMBER,
} from "modules/serial-number";

const useStyle = makeStyles((theme) => ({
  container: {
    padding: 16,
    background: "white",
    borderRadius: 4,
  },
  tableContainer: {
    maxHeight: 350,
    overflowY: "auto",
  },
  table: {
    border: "1px solid #D0D5DD",
    tableLayout: "auto",
    whiteSpace: "nowrap",
    maxHeight: 500,
    overflowY: "auto",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
    },
    "& th": {
      backgroundColor: "white",
      color: "#98A2B3",
    },
  },
  greenLabel: {
    color: "#6AAF68",
  },
  emptyContainer: {
    backgroundColor: theme.palette.background.paper,
    alignItems: "center",
    display: "flex",
    placeContent: "center",
    border: "1px solid rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
  },
  filterSection: {
    display: "grid",
    gridTemplateColumns: "repeat(3,1fr)",
    columnGap: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
    },
  },
  filterBox: {
    height: 34,
    backgroundColor: "white",
    border: "2px solid #D0D5DD",
  },
  paperRoot: {
    width: "217px",
  },
}));

export default function UnassignSerialNumberComponent({
  isLoading,
  error,
  serialNumbers,
  activeType,
  handleChange,
  handleGenerate,
  handleRetry,
  lang,
  activeFormat,
  setActiveFormat,
  disabled,
  lengthList,
  prefixList,
  postfixList,
  filter,
  setFilter,
  filteredSN,
}) {
  const classes = useStyle();

  let patternNotFound =
    filteredSN.findIndex((sn) => sn.format.split(",")[3] === activeType) === -1;

  return (
    <Paper elevation={0} className={classes.container}>
      {!isLoading && error && <RefreshTable handleReload={handleRetry} />}
      {!isLoading && serialNumbers.length > 0 && (
        <Box mb={1}>
          <Typography variant="body2">
            {getLang(lang, "label.TIPS")}:{" "}
            {getLang(lang, "message.info.SELECT_PATTERN_TIPS")}
          </Typography>
          <Box mt={2}>
            <Typography variant="body2">
              {getLang(lang, "label.FILTER")}:
            </Typography>
            <Box className={classes.filterSection}>
              {activeType === SERIAL_NUMBER_TYPE_NUMBER && (
                <SearchableSelect
                  isClearable={true}
                  value={filter.prefix}
                  handleChange={(value) => {
                    setFilter({
                      ...filter,
                      prefix: value,
                    });
                  }}
                  handleClear={(value) => {
                    setFilter({
                      ...filter,
                      prefix: "",
                    });
                  }}
                  placeholder={getLang(lang, "label.PREFIX")}
                  searchable={false}
                  style={{
                    height: 34,
                    backgroundColor: "white",
                    border: "2px solid #D0D5DD",
                  }}
                  PaperProps={{
                    classes: { root: classes.paperRoot },
                  }}
                  dropdownItem={prefixList}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  isEmpty={!filter.prefix}
                  returnType="string"
                  emptyMessage={getLang(lang, "label.NO_DATA_AVAILABLE")}
                />
              )}
              <SearchableSelect
                isClearable={true}
                value={filter.length}
                handleChange={(value) => {
                  setFilter({
                    ...filter,
                    length: value.value,
                  });
                }}
                handleClear={(value) => {
                  setFilter({
                    ...filter,
                    length: "",
                  });
                }}
                placeholder={getLang(lang, "label.LENGTH")}
                searchable={false}
                style={{
                  height: 34,
                  backgroundColor: "white",
                  border: "2px solid #D0D5DD",
                }}
                PaperProps={{
                  classes: { root: classes.paperRoot },
                }}
                dropdownItem={lengthList}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                isEmpty={!filter.length}
                returnType="object"
                emptyMessage={getLang(lang, "label.NO_DATA_AVAILABLE")}
              />
              {activeType === SERIAL_NUMBER_TYPE_NUMBER && (
                <SearchableSelect
                  isClearable={true}
                  value={filter.postfix}
                  handleChange={(value) => {
                    setFilter({
                      ...filter,
                      postfix: value,
                    });
                  }}
                  handleClear={(value) => {
                    setFilter({
                      ...filter,
                      postfix: "",
                    });
                  }}
                  placeholder={getLang(lang, "label.POSTFIX")}
                  searchable={false}
                  style={{
                    height: 34,
                    backgroundColor: "white",
                    border: "2px solid #D0D5DD",
                  }}
                  PaperProps={{
                    classes: { root: classes.paperRoot },
                  }}
                  dropdownItem={postfixList}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  isEmpty={!filter.postfix}
                  returnType="string"
                  emptyMessage={getLang(lang, "label.NO_DATA_AVAILABLE")}
                />
              )}
            </Box>
          </Box>
        </Box>
      )}
      {(isLoading || filteredSN.length > 0 || !patternNotFound) && (
        <Box>
          <TableContainer className={classes.tableContainer}>
            <Table
              className={classes.table}
              aria-label="sn table"
              size="small"
              stickyHeader
            >
              <TableHead style={{ height: "45px", background: "white" }}>
                <TableRow>
                  <TableCell>{getLang(lang, "label.NUM")}</TableCell>
                  {activeType === SERIAL_NUMBER_TYPE_NUMBER && (
                    <>
                      <TableCell align="right">
                        {getLang(lang, "label.PREFIX")}
                      </TableCell>
                      <TableCell align="center">
                        {getLang(lang, "label.LENGTH")}
                      </TableCell>
                      <TableCell align="left">
                        {getLang(lang, "label.POSTFIX")}
                      </TableCell>
                    </>
                  )}
                  {activeType === SERIAL_NUMBER_TYPE_ALPHANUMERIC && (
                    <TableCell>
                      {getLang(lang, "label.CHARACTERS_LABEL")}
                    </TableCell>
                  )}
                  <TableCell align="right">
                    {getLang(lang, "label.AVAILABLE_QUANTITY")}
                  </TableCell>
                  <TableCell align="right">
                    {getLang(lang, "label.SELECT")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading
                  ? [...new Array(5)].map((v, index) => (
                      <SkeletonTableRow key={index} columnCount={6} />
                    ))
                  : filteredSN.map((sn, index) => {
                      const pattern = sn.format.split(",");

                      return (
                        <TableRow
                          key={index}
                          hover
                          role="checkbox"
                          tabIndex={-1}
                        >
                          <TableCell>
                            <Typography color="textSecondary" variant="body2">
                              <b>{index + 1}</b>
                            </Typography>
                          </TableCell>
                          {activeType === SERIAL_NUMBER_TYPE_NUMBER && (
                            <>
                              <TableCell align="right">
                                {pattern[0] ? (
                                  <Typography
                                    className={classes.greenLabel}
                                    variant="body2"
                                  >
                                    <b>{pattern[0]}</b>
                                  </Typography>
                                ) : (
                                  <Typography
                                    color="textSecondary"
                                    variant="body2"
                                  >
                                    <i>({getLang(lang, "label.NO_PREFIX")})</i>
                                  </Typography>
                                )}
                              </TableCell>
                              <TableCell align="center">
                                <b>
                                  [{pattern[1]} {getLang(lang, "label.DIGITS")}]
                                </b>
                              </TableCell>
                              <TableCell align="left">
                                {pattern[2] ? (
                                  <Typography
                                    className={classes.greenLabel}
                                    variant="body2"
                                  >
                                    <b>{pattern[2]}</b>
                                  </Typography>
                                ) : (
                                  <Typography
                                    color="textSecondary"
                                    variant="body2"
                                  >
                                    <i>({getLang(lang, "label.NO_POSTFIX")})</i>
                                  </Typography>
                                )}
                              </TableCell>
                            </>
                          )}
                          {activeType === SERIAL_NUMBER_TYPE_ALPHANUMERIC && (
                            <TableCell>
                              <b>
                                [{pattern[1]}{" "}
                                {getLang(lang, "label.CHARACTERS")}]
                              </b>
                            </TableCell>
                          )}
                          <TableCell align="right">
                            <Typography
                              variant="body2"
                              color={
                                sn.quantity <= 0
                                  ? "textSecondary"
                                  : "textPrimary"
                              }
                            >
                              <b>{convertToNumberString(sn.quantity)}</b>
                            </Typography>
                          </TableCell>
                          <TableCell align="right">
                            <Radio
                              checked={activeFormat === sn.format}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setActiveFormat(sn.format);
                                  handleChange(sn);
                                }
                              }}
                              name="pattern-selection"
                              size="small"
                              style={{ padding: "0px" }}
                              disabled={disabled || sn.quantity <= 0}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
      {!isLoading &&
        !error &&
        (filteredSN.length === 0 || !!patternNotFound) && (
          <Box>
            <Table
              className={classes.table}
              aria-label="product table"
              size="small"
              stickyHeader
            >
              <TableHead style={{ height: "45px" }}>
                <TableRow>
                  <TableCell>{getLang(lang, "label.NUM")}</TableCell>
                  <TableCell align="right">
                    {getLang(lang, "label.PREFIX")}
                  </TableCell>
                  <TableCell align="center">
                    {getLang(lang, "label.LENGTH")}
                  </TableCell>
                  <TableCell align="left">
                    {getLang(lang, "label.POSTFIX")}
                  </TableCell>
                  <TableCell align="right">
                    {getLang(lang, "label.AVAILABLE_QUANTITY")}
                  </TableCell>
                  <TableCell align="right">
                    {getLang(lang, "label.SELECT")}
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
            <Box py={9} className={classes.emptyContainer}>
              <Typography
                variant="body1"
                style={{ paddingBottom: "16px", fontWeight: "bold" }}
              >
                {patternNotFound
                  ? getLang(lang, "paragraph.NO_PATTERN_FOUND")
                  : getLang(lang, "paragraph.NO_UNASSIGNED_SERIAL_NUMBER")}
              </Typography>
              {!patternNotFound && (
                <Button
                  className={classes.ctaButton}
                  variant="outlined"
                  size="small"
                  onClick={handleGenerate}
                >
                  {getLang(lang, "label.GENERATE_SERIAL_NUMBER")}
                </Button>
              )}
            </Box>
          </Box>
        )}
    </Paper>
  );
}
