import axios from "axios";

const get = () => {
  return new Promise((resolve, reject) => {
    axios
      .get("/account/api/v1/user-groups/dropdown")
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

const getRoleDropdown = {
  get
};

export default getRoleDropdown;
