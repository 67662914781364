import React from 'react'
import { Box, Button, Divider, Typography } from '@material-ui/core'
import GenerateProductTable from '../../table/generateProductTable'
import ActionSelect from '../../select/actionSelect'
import { getLang } from 'app/feature/constants'

export default function BulkEditListPanelComponent({
  handleChangePage,
  handleChangeRowsPerPage,
  page,
  rowsPerPage,
  paginationOptions,
  clearProducts,
  deleteProducts,
  handleAction,
  isFetching,
  selectedIndex,
  isSelectAll,
  handleSelectAll,
  handleSelectProduct,
  updateProductDetail,
  products,
  handleSubmit,
  totalRecords,
  fieldRequireError,
  lang,
  handleSelectAvailable,
  selectedCount
}) {
  return (
    <Box style={{ backgroundColor: "#fff" }}>
      <Box px={4} pt={4} pb={2}>
        <Box display="flex" justifyContent="space-between"  >
          <Typography
            variant='body1'
            color='textPrimary'
            style={{ fontWeight: "bold", marginBottom: "17px" }}
          >
            {getLang(lang, "label.READY_TO_GENERATE")}
          </Typography>
          <ActionSelect
            label={getLang(lang, "label.ACTION")}
            dropdownItem={[getLang(lang, "label.DELETE_SELECTED")]}
            handleChange={(value) => handleAction(value)}
            disabled={isFetching || (!isSelectAll && !selectedIndex.length)}
            isFetching={false}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            style={{
              width: "100px",
              borderRadius: "8px",
              backgroundColor: "#ffffff",
              border: "1px solid #D0D5DD"
            }}
          />
        </Box>
        {selectedCount > 0 && (
          <Typography
            variant='body2'
            color='textPrimary'
          >
            {(isSelectAll && selectedCount === totalRecords) ? (
              <Typography variant='body2' style={{color:"#FDB022"}}>{getLang(lang,"paragraph.SELECTED_ALL_PRODUCTS", {count: selectedCount})}</Typography>
            ) : (
              <Typography variant='body2' color='primary'>
                {selectedCount > 1 ? 
                  getLang(lang, "paragraph.SELECTED_PRODUCTS_COUNT", { count: selectedCount })
                : 
                  getLang(lang, "paragraph.SELECTED_PRODUCT_COUNT", { count: selectedCount })
                }  
              </Typography>
            )}
          </Typography>
        )}
      </Box>
      <GenerateProductTable
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        isFetching={false}
        isLoading={false}
        page={page}
        rowsPerPage={rowsPerPage}
        paginationOptions={paginationOptions}
        clearProducts={clearProducts}
        deleteProducts={deleteProducts}
        isSelectAll={isSelectAll}
        handleSelectAll={handleSelectAll}
        handleSelectProduct={handleSelectProduct}
        updateProductDetail={updateProductDetail}
        products={products}
        totalRecords={totalRecords}
        fieldRequireError={fieldRequireError}
        lang={lang}
        selectedIndex={selectedIndex}
        handleSelectAvailable={handleSelectAvailable}
      />
      <Divider />
      <Box style={{ padding: "12px 32px", display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="contained"
          disableElevation
          color='secondary'
          onClick={handleSubmit}
        >
          {getLang(lang, "label.GENERATE")}
        </Button>
      </Box>
    </Box>
  )
}
