import React from "react";
import {
  makeStyles,
  Box,
  ButtonBase,
  fade,
  Typography
} from "@material-ui/core";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import clsx from "clsx";
import OpenInNewRoundedIcon from "@material-ui/icons/OpenInNewRounded";
import { purple } from "@material-ui/core/colors";
import ZoomOutMapRoundedIcon from "@material-ui/icons/ZoomOutMapRounded";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles(theme => ({
  root: {
    borderRadius: theme.spacing(2),
    display: "flex",
    minHeight: "fit-content",
    overflow: "hidden"
  },
  carousel: {
    borderRadius: theme.spacing(2),
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15))",
    position: "relative",
    "& img": {
      height: "100%",
      flexShrink: 0,
      minWidth: "100%",
      minHeight: "100%",
      objectFit: "cover"
    }
  },
  indicator: {
    width: 6,
    height: 6,
    borderRadius: "50%",
    backgroundColor: fade(theme.palette.common.white, 0.33),
    margin: theme.spacing(0, 0.25),
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  active: {
    backgroundColor: theme.palette.common.white,
    width: 20,
    borderRadius: 4
  },
  linkCard: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1, 1.5),
    borderRadius: 100,
    color: theme.palette.common.white,
    position: "absolute",
    bottom: 15,
    left: 15,
    backgroundColor: purple[600]
  },
  fullScreenBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    color: theme.palette.common.white,
    position: "absolute",
    width: 30,
    height: 30,
    top: 15,
    right: 15,
    backgroundColor: purple[600]
  },
  carouselContainer: {
    "& .slider-wrapper": {
      borderRadius: theme.spacing(2)
    }
  },
  placeholder: {
    width: "100%",
    height: 200,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.background.paper
  }
}));

export default function GalleryComponent({ order, images, lang, isEnableFullscreenBtn }) {
  const classes = useStyle();

  return (
    <Box mx={2} className={classes.root} elevation={0}>
      {order.length === 0 ? (
        <Box className={classes.placeholder}>
          <Typography
            variant="body2"
            color="primary"
            style={{ fontWeight: "bold" }}
          >
            {getLang(lang, "label.GALLERY_COMPONENT")}
          </Typography>
        </Box>
      ) : (
        <Carousel
          autoPlay={false}
          showThumbs={false}
          showStatus={false}
          showIndicators={order.length > 1 ? true : false}
          showArrows={false}
          className={classes.carouselContainer}
          renderIndicator={(clickHandler, isSelected, index) => {
            return (
              <ButtonBase
                disableRipple
                className={clsx(
                  classes.indicator,
                  isSelected && classes.active
                )}
                onClick={clickHandler}
              />
            );
          }}
        >
          {order.map((id, index) => (
            <div key={index} className={classes.carousel}>
              <img src={images[id].img} alt="" />
              {images[id].destinationURL && (
                <ButtonBase className={classes.linkCard}>
                  <Typography variant="caption" color="inherit">
                    {getLang(lang, "label.VISIT_WEBSITE")}
                  </Typography>
                  &ensp;
                  <OpenInNewRoundedIcon color="inherit" fontSize="small" />
                </ButtonBase>
              )}
              {isEnableFullscreenBtn && (
                <ButtonBase className={classes.fullScreenBtn}>
                  <ZoomOutMapRoundedIcon color="inherit" fontSize="small" />
                </ButtonBase>
              )}
            </div>
          ))}
        </Carousel>
      )}
    </Box>
  );
}
