import ConfirmationDialog from "components/dialog/confirmation";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import {
  fetchGiftListing,
  changeStatusGift,
  getGiftOrderSetting,
} from "modules/loyalty-program/redux/action/redemptionCenter.action";
import { setType, updateRedemptionPageSetting } from "modules/loyalty-program/redux/slice/redemptionCenter.slice";
import { connect, useDispatch, useSelector } from "react-redux";
import { GiftDetailsDialog } from "../../dialog";
import GiftListPanelComponent from "./giftListPanel.component";
import { selectLoading } from "modules/notification";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  GIFT_ADD,
  GIFT_EDIT,
  GIFT_ACTIVATE_OR_DEACTIVATE,
} from "lib/constants/accessRights";
import { generatePaginationOptions } from "lib/helper";
import { getLang } from "app/feature/constants";
import GiftSettingDialog from "../../dialog/giftSettingDialog";

const GiftListPanelContainer = forwardRef((_, ref) => {
  const hasGiftAddAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(GIFT_ADD)
  );
  const hasGiftEditAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(GIFT_EDIT)
  );
  const hasGiftActivateOrDeactivateAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(GIFT_ACTIVATE_OR_DEACTIVATE)
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [search, setSearch] = useState("");
  const [isDeactivateDialogOpen, setIsDeactivateDialogOpen] = useState(false);
  const [isActivateDialogOpen, setIsActivateDialogOpen] = useState(false);
  const [changeStatusTargetId, setChangeStatusTargetId] = useState("");
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [paginationOptions, setPaginationOptions] = useState([]);
  const [showSettingDialog, setShowSettingDialog] = useState(false);

  const dispatch = useDispatch();
  const data = useSelector(
    (state) => state.redemptionCenter.giftListTable.data
  );
  const totalRecords = useSelector(
    (state) => state.redemptionCenter.giftListTable.totalRecords
  );
  const filteredTotalRecords = useSelector(
    (state) => state.redemptionCenter.giftListTable.filteredTotalRecords
  );
  const isStatusUpdating = useSelector((state) =>
    selectLoading(state, changeStatusGift.typePrefix)
  );
  const isFetching = useSelector((state) =>
    selectLoading(state, fetchGiftListing.typePrefix)
  );
  const lang = useSelector((state) => state.constant.languages);

  useImperativeHandle(ref, () => ({
    openCreateLoyaltyProgramModal: () => {
      dispatch(setType({ index: "null", type: "create" }));
      setIsCreateDialogOpen(true);
    },
  }));

  useEffect(() => {
    dispatch(getGiftOrderSetting());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(
      fetchGiftListing({
        length: rowsPerPage,
        start: rowsPerPage * page,
        search: search,
      })
    );
  }, [search, rowsPerPage, page, dispatch]);

  useEffect(() => {
    setPaginationOptions(generatePaginationOptions(totalRecords));
  }, [totalRecords]);

  const handleFetchGiftList = () => {
    dispatch(
      fetchGiftListing({
        length: rowsPerPage,
        start: rowsPerPage * page,
        search: search,
      })
    );
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);

    dispatch(
      updateRedemptionPageSetting({
        start: rowsPerPage * newPage,
      })
    );
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(event.target.value);

    dispatch(
      updateRedemptionPageSetting({
        length: event.target.value,
        start: 0,
      })
    );
  };

  const handleSearch = async (values) => {
    setPage(0);
    setSearch(values);

    dispatch(
      updateRedemptionPageSetting({
        start: 0,
        search: values
      })
    );
  };

  const handleStatusChange = ({ currentStatus, id }) => {
    if (hasGiftActivateOrDeactivateAccessRight) {
      setChangeStatusTargetId(id);
      if (currentStatus) {
        setIsDeactivateDialogOpen(true);
      } else {
        setIsActivateDialogOpen(true);
      }
    }
  };

  const handleStatusSubmit = (status) => {
    if (hasGiftActivateOrDeactivateAccessRight) {
      dispatch(
        changeStatusGift({
          id: changeStatusTargetId,
          status: status ? "active" : "inactive",
        })
      )
        .then(unwrapResult)
        .then(() => handleFetchGiftList())
        .finally(() => {
          setIsDeactivateDialogOpen(false);
          setIsActivateDialogOpen(false);
        });
    }
  };

  const handleCreate = () => {
    if (hasGiftAddAccessRight) {
      dispatch(setType({ index: "null", type: "create" }));
      setIsCreateDialogOpen(true);
    }
  };

  return (
    <>
      <GiftListPanelComponent
        hasGiftAddAccessRight={hasGiftAddAccessRight}
        hasGiftEditAccessRight={hasGiftEditAccessRight}
        hasGiftActivateOrDeactivateAccessRight={
          hasGiftActivateOrDeactivateAccessRight
        }
        data={data}
        isFetching={isFetching}
        page={page}
        search={search}
        rowsPerPage={rowsPerPage}
        totalRecords={search ? filteredTotalRecords : totalRecords}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleSearch={handleSearch}
        handleCreate={handleCreate}
        handleStatusChange={handleStatusChange}
        handleReload={handleFetchGiftList}
        paginationOptions={paginationOptions}
        lang={lang}
        setShowSettingDialog={setShowSettingDialog}
      />
      <GiftDetailsDialog
        isOpen={isCreateDialogOpen}
        handleClose={() => setIsCreateDialogOpen(false)}
      />
      <GiftSettingDialog
        isOpen={showSettingDialog}
        handleClose={() => setShowSettingDialog(false)}
        handleReload={handleFetchGiftList}
      />
      <ConfirmationDialog
        isOpen={isActivateDialogOpen}
        handleClose={() => setIsActivateDialogOpen(false)}
        handleProceed={() => handleStatusSubmit(true)}
        isLoading={isStatusUpdating}
        type={"success"}
        description={getLang(lang, "paragraph.ACTIVATE_GIFT_QUESTION")}
        title={getLang(lang, "label.CONFIRMATION_NEEDED")}
      />
      <ConfirmationDialog
        isOpen={isDeactivateDialogOpen}
        handleClose={() => setIsDeactivateDialogOpen(false)}
        handleProceed={() => handleStatusSubmit(false)}
        isLoading={isStatusUpdating}
        type={"danger"}
        description={getLang(lang, "paragraph.DEACTIVATE_GIFT_QUESTION")}
        title={getLang(lang, "label.CONFIRMATION_NEEDED")}
      />
    </>
  );
});

export default connect(null, null, null, { forwardRef: true })(
  GiftListPanelContainer
);
