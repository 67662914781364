import axios from "axios";

// send email verification
export function resendVerificationEmail({
  email
}) {
  return new Promise((resolve, reject) => {
    axios.post("distributor/public/api/v1/auth/send-verification-link-email", {
      email: email,
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data))
  })
}