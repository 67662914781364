import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import {setLiveDrawAll, setPrizeIsDrawed} from "modules/lucky-draw/redux";
import LiveDrawPageComponent from "./liveDraw.page";
import { LUCKY_DRAW_VIEW } from "lib/constants/accessRights"
import LoadingComponent from "components/loading";

function LiveDrawPageContainer() {
  const history = useHistory();
  const dispatch = useDispatch()
  const hasLuckyDrawViewAccessRight = useSelector(state => state.profile.accessRights.includes(LUCKY_DRAW_VIEW))
  const prizes = useSelector(state => state.luckyDraw.liveDraw.prizes)
  const title = useSelector(state => state.luckyDraw.LuckyDrawDetail.luckyDrawTitle)
  const isLuckyDrawInPublished = useSelector(state => state.luckyDraw.LuckyDrawDetail.progress === "published")
  const lang = useSelector(state => state.constant.languages)
  
  useEffect(() => {
    if(!hasLuckyDrawViewAccessRight){
      history.push("/admin/dashboard")
    }
  }, [history, hasLuckyDrawViewAccessRight])

  useEffect(() => {
    if(!isLuckyDrawInPublished){
      history.goBack()
    }
  }, [history, isLuckyDrawInPublished])

  if (!hasLuckyDrawViewAccessRight) return <LoadingComponent />;

  const handleDraw = (id) => {
    dispatch(setPrizeIsDrawed(id))
    dispatch(setLiveDrawAll({id}));
  }

  return (
    <LiveDrawPageComponent
      prizes={prizes}
      handleDraw={handleDraw}
      title={title}
      lang={lang}
    />
  )
}

export default LiveDrawPageContainer;
