import React from "react";
import Chart from 'react-apexcharts';
import '../../../assets/css/customTooltip.css';

export default function ComparisonChartComponent({
    payload, translate
}){
    const chartSeries = () => {
        let series = JSON.stringify(payload);
        series = JSON.parse(series);

        series.datasets.forEach(e => {
            e.name = translate(e.name);
        });

        series.labels.forEach((e, index) => {
            series.labels[index] = translate(e);
        });

        return series;
    }

    const options = {
        chart: {
            type: 'bar',
            height: '350px',
            stacked: true,
        },
        colors: ['#448FFF', '#FF4487'],
        plotOptions: {
            bar: {
                horizontal: true,
                columnWidth: '70%',
                barHeight: '70%',
                // borderRadius: 4,
                dataLabels: {
                    style: {
                        fontSize: 16,
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 'bold',
                        colors: ['#f00', '#fff']
                    },
                    enabled: true
                }
            }
        },
        dataLabels: {
            enabled: false,
        },
        grid: {
            xaxis: {
                lines: {
                    show: true
                }
            },
        },
        annotations: {
            xaxis: [{
                x: 0,
                borderColor: '#92969c',
                strokeDashArray: 0,
                label: {
                    borderColor: '#92969c',
                }
            }]
            
        },
        yaxis: {
            min: -payload.limit,
            max: payload.limit,
        },
        tooltip: {
            shared: false,
            x: {
                formatter: function (val) {
                    return val
                }
            },
            y: {
                formatter: function (val) {
                    return Math.abs(val)
                }
            },
            custom: function ({series, seriesIndex, dataPointIndex, w}) {
                let totalAllData = Math.abs(series[0][dataPointIndex]) + Math.abs(series[1][dataPointIndex]); 
                return (
                    '<div class="tooltip__container">' +
                        '<div class="tooltip__header">' +
                            '<span class="tooltip__title">' + payload.labels[dataPointIndex] + '</span>' +
                        '</div>' +
                        '<div class="tooltip__content">' +
                            '<div class="tooltip__labels_container">' +
                                '<span style="background-color: ' + w.config.colors[0] + '" class="tooltip__marker"></span>' +
                                '<span class="tooltip__label">' + translate(payload.datasets[0].name ) + '</span>' +
                            '</div>' +
                            '<span class="tooltip__value">' + Math.abs(series[0][dataPointIndex]) + '</span>' +
                        '</div>' +
                        '<div class="tooltip__content">' +
                            '<div class="tooltip__labels_container">' +
                                '<span style="background-color: ' + w.config.colors[1] + '" class="tooltip__marker"></span>' +
                                '<span class="tooltip__label">' + translate(payload.datasets[1].name) + '</span>' +
                            '</div>' +
                            '<span class="tooltip__value">' + Math.abs(series[1][dataPointIndex]) + '</span>' +
                        '</div>' +
                        '<div class="tooltip__content">' +
                            '<span style="font-weight:600">Total</span>' +
                            '<span class="tooltip__value">' +totalAllData + '</span>' +
                        '</div>' +
                    '</div>'
                )
            }
        },
        stroke: {
            width: 1,
            colors: ['#fff']
        },
        xaxis: {
            categories: chartSeries().labels,
            labels: {
                formatter: function (val) {
                    return Math.abs(val)
                }
            }
        }
    }


    
    return (
        <Chart
            series={
                chartSeries().datasets
            }
            options={options}
            type="bar"
            height='350px'
        >

        </Chart>
    )
}