import React from "react";
import LandingPageThemeSettingPanelComponent from "./landingPageThemeSettingPanel.component";
import { getConsumerPageSetting, setThemeSetting } from "../../../redux";
import { selectLoading } from "modules/notification";
import { connect, useSelector } from "react-redux";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { COMPANY_PRODUCT_EDIT } from "lib/constants/accessRights";
import { useDispatch } from "react-redux"

function LandingPageThemeSettingPanelContainer({ settings, isFetching, }) {
  const dispatch = useDispatch()
  const hasCompanyProductEditAccessRight = useSelector(state => state.profile.accessRights.includes(COMPANY_PRODUCT_EDIT))

  const theme = useTheme();
  const mdWidth = useMediaQuery(theme.breakpoints.down("md"));
  const lang = useSelector(state => state.constant.languages)

  const handleChange = (name, value ) => {
    dispatch(setThemeSetting({ name: name, value: value }))
  }

  return (
    <LandingPageThemeSettingPanelComponent
      hasCompanyEditAccessRight={hasCompanyProductEditAccessRight}
      settings={settings}
      isFetching={isFetching}
      mdWidth={mdWidth}
      handleChange={handleChange}
      lang={lang}
    />
  );
}

const mapStateToProps = (state) => ({
  settings: state.setting,
  isFetching: selectLoading(state, getConsumerPageSetting.typePrefix),
});


export default connect(
  mapStateToProps,
  null
)(LandingPageThemeSettingPanelContainer);
