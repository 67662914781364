import { EMPLOYEE_ADD } from "lib/constants/accessRights";
import { createSelector } from "reselect";

export const hasEmployeeAddAccessRightSelector = createSelector(
    state => state.profile.accessRights,
    state => state.profile.registrationType,
    state => state.billing.subscriptionPlan.productName,
    state => state.session.company.usersCount,
    (accessRights, registrationType, subscriptionPlan, usersCount) => {
        if (!accessRights.includes(EMPLOYEE_ADD)) {
            return false
        }
        if (registrationType === 1 && subscriptionPlan === 'Lite') {
            if(usersCount >= 5){
                return false
            }
        } else if (registrationType === 4) {
            if(usersCount >= 5){
                return false
            }
        }
        return true
    }
);