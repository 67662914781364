import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  makeStyles,
} from "@material-ui/core";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    padding: "35px 30px",
    backgroundColor: "#ffffff",
    color: "black !important",
  },
  customDialogActionRoot: {
    justifyContent: "center",
  },
  customDialogTitle: {
    paddingTop: 0,
    color: "black",
  },
  customDialogPaperWithXs: {
    maxWidth: 360,
  },
  dialogRemarksCaption: {
    fontWeight: 200,
    marginLeft: 5,
  },
  dialogRemarks: {
    color: "#FD646F",
  },
  dangerCancelButton: {
    color: "white",
    borderColor: "white !important",
  },
  successCancelButton: {
    backgroundColor: theme.palette.primary.main,
  },
  dangerText: {
    color: "white",
  },
  successText: {
    color: "white",
  },
  defaultText: {
    color: "black",
  },
}));

export default function ApproveConfirmDialogComponent({
  isOpen,
  handleClose,
  lang,
  title,
  description,
  buttonColor,
  buttonText,
}) {
  const classes = useStyle();

  return (
    <Dialog
      classes={{
        paper: classes.root,
        paperWidthXs: classes.customDialogPaperWithXs,
      }}
      open={isOpen}
      disableBackdropClick
      disableEscapeKeyDown
      aria-labelledby="confirmation-dialog"
      maxWidth="xs"
    >
      <DialogTitle
        classes={{
          root: classes.customDialogTitle,
        }}
      >
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
      </DialogContent>
      <DialogActions classes={{ root: classes.customDialogActionRoot }}>
        <Button
          onClick={handleClose}
          color="primary"
          variant="contained"
          style={{ backgroundColor: buttonColor ?? "#6AAF68" }}
        >
          {buttonText || getLang(lang, "label.DONE")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
