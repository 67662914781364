import React from "react";
import {
  Slider,
  Paper,
  Button,
  Box,
  Grid,
  makeStyles
} from "@material-ui/core";
import {
  ZoomSmallImg,
  ZoomBigImg,
  FitImageImg,
  FlipImageImg,
  FitOutImageImg,
  RotateImageImg
} from "assets/js/resourse";
import { getLang } from "app/feature/constants";
import ReactCrop, { makeAspectCrop, centerCrop } from 'react-image-crop';
import { canvasPreview } from './canvasPrevies';
import 'react-image-crop/dist/ReactCrop.css';

function centerAspectCrop(
  mediaWidth,
  mediaHeight,
  aspect,
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 100,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  )
}

const style = (theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    padding: theme.spacing(3),
    borderRadius: 10,
    outline: "none",
    backgroundColor: theme.palette.primary.main
  },
  buttons: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(3),
    padding: theme.spacing(3)
  },
  zoom: {
    display: "flex",
    justifyContent: "center"
  },
  zoomBox: {
    display: "flex",
    width: "50%",
    columnGap: theme.spacing(2),
    alignItems: "center"
  },
  save: {
    backgroundColor: "#0095E1",
    "&:hover": {
      backgroundColor: "#0095E1"
    },
    color: "#ffffff",
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(1),
      paddingLeft: "10px",
      paddingRight: "10px"
    },
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1)
    }
  },
  actionIcon: {
    display: "flex",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    justifyContent: "center"
  },
  canvasFlipped: {
    transform: "scaleX(-1)"
  },
  grid: {
    placeItems: "center",
    [theme.breakpoints.down("xs")]: {
      width: "65%"
    }
  },
});

const useStyle = makeStyles(style);

export default function ImageEditorPanelComponent({
  img,
  zoom,
  isCircleRadius,
  width,
  height,
  refEditor,
  handleSave,
  handleZoom,
  handleCancel,
  handleRotate,
  handleFlip,
  aspectRatio=[1, 1],
  handleFitout,
  handleFit,
  rotate,
  flipped,
  lang,
  imgRef,
  previewCanvasRef,
  crop,
  setCrop,
  completedCrop,
  setCompletedCrop,
  useDebounceEffect
}) {
  const classes = useStyle();
  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          zoom,
          rotate,
          flipped ? -1 : 1
        )
      }
    },
    100,
    [completedCrop, zoom, rotate],
  );
  function onImageLoad(e) {
    if (true) {
      const { width, height } = e.currentTarget
      setCrop(centerAspectCrop(width, height, aspectRatio[0] / aspectRatio[1]))
    }
  }
  let flip = flipped ? -1 : 1;
  return (
    <Paper elevation={0} className={classes.paper}>
      <div>
        <Box className={classes.actionIcon}>
          <Box mr={4} onClick={handleFit}>
            <FitImageImg />
          </Box>
          <Box mr={4} onClick={handleFitout}>
            <FitOutImageImg />
          </Box>
          <Box mr={4} onClick={handleFlip}>
            <FlipImageImg />
          </Box>
          <Box onClick={handleRotate}>
            <RotateImageImg />
          </Box>
        </Box>
        <ReactCrop
          crop={crop}
          onChange={(percentCrop) => setCrop(percentCrop)}
          onComplete={(c) => setCompletedCrop(c)}
          aspect={aspectRatio[0] / aspectRatio[1]}
          style={{ maxWidth: '320px' }}
        >
          <img
            ref={imgRef}
            alt="Crop me"
            src={img}
            style={{ transform: `scale(${zoom}) rotate(${rotate}deg) scaleX(${flip})` }}

            onLoad={onImageLoad}
          />
        </ReactCrop>
        <canvas
          ref={previewCanvasRef}
          style={{
             display: 'none'
          }}
        />
        <Box className={classes.zoom}>
          <Box className={classes.zoomBox}>
            <ZoomSmallImg />
            <Slider
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              onChange={handleZoom}
              style={{ color: "#ffffff" }}
            />
            <ZoomBigImg />
          </Box>
        </Box>
      </div>
      <Box className={classes.buttons}>
        <Grid container className={classes.grid}>
          <Grid item xs={3} sm={3} md={3} style={{ width: "100%" }}>
            <Button
              size="small"
              style={{ color: "#FD646F" }}
              onClick={handleCancel}
            >
              {getLang(lang, "label.DISCARD")}
            </Button>
          </Grid>
          <Grid
            item
            xs={9}
            sm={9}
            md={9}
            style={{ width: "100%", textAlign: "end" }}
          >
            <Button size="small" className={classes.save} onClick={handleSave}>
              {getLang(lang, "label.DONE_EDIT_SAVE")}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}
