import React from "react";
import {
  makeStyles,
  Box,
  Paper,
  Button,
  Divider,
  Typography,
  IconButton,
  Backdrop
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { AddSVG, WarningSVG } from "../../../utils/static";
import {
  CreatePrizeCard,
  EditPrizeCard,
  BlankPrizeCard,
  ViewPrizeCard
} from "../../card";
import Dialog from "components/dialog/customDialog";
import { getLang } from "app/feature/constants";

const style = (theme) => ({
  header: {
    padding: "4px 0px",
    paddingLeft: theme.spacing(3),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  root: {
    width: 650,
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  content: {
    padding: theme.spacing(8, 4, 8, 4),
    textAlign: "center"
  },
  infoText: {
    paddingTop: theme.spacing(1),
    fontWeight: "bold"
  }
});

const useStyles = makeStyles(style);

export default function InstantPrizeDialogComponent({
  hasInstantLuckyDrawEditAccessRight,
  type,
  AddPrize,
  AddingPrizeFlag,
  handleAddPrizeSubmit,
  prizeList,
  handleEnableEdit,
  handleEdit,
  handleDeleteField,
  handleNewDelete,
  handleClose,
  isOpen,
  defaultPrizeData,
  isEditing,
  handleIsEditing,
  handleBlankPrizeEdit,
  lang
}) {
  const classes = useStyles();

  return (
    <Dialog
      isOpen={isOpen}
      handleClose={handleClose}
      disableEnforceFocus={true}
      disableAutoFocus={true}
      disableEscapeKeyDown={true}
      disableBackdropClick={true}
      closeAfterTransition
      BackdropComponent={Backdrop}
    >
      <Paper elevation={0}>
        <Box className={classes.root}>
          <Box className={classes.header}>
            <Typography
              variant="subtitle2"
              color="primary"
              style={{ fontWeight: "bold" }}
            >
              {type === "create"
                ? getLang(lang,"label.ADD_INSTANT_LUCKY_DRAW_PRIZE")
                : defaultPrizeData.instantLuckyDraw
                ? defaultPrizeData.instantLuckyDraw.title
                : getLang(lang,"label.INSTANT_LUCKY_DRAW_PRIZE")}
            </Typography>
            <IconButton color="primary" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>

          <Divider />

          <Box pt={1} pb={1} px={3} display="flex">
            <Typography
              variant="body2"
              color="primary"
              style={{ fontWeight: "bold" }}
            >
              {hasInstantLuckyDrawEditAccessRight ? getLang(lang,"label.MANAGE_PRIZE") : getLang(lang,"label.VIEW_PRIZE")}
            </Typography>
          </Box>

          {prizeList.length === 0 && !AddingPrizeFlag ? (
            <Box className={classes.content}>
              <WarningSVG />

              <Typography
                variant="body1"
                color="primary"
                className={classes.infoText}
              >
                {getLang(lang,"paragraph.NO_INSTANT_LUCKY_DRAW_PRIZE_ADDED")}
              </Typography>
            </Box>
          ) : null}

          {prizeList.length > 0 || AddingPrizeFlag ? (
            <Box maxHeight="50vh" overflow="auto">
              {prizeList.length > 0
                ? prizeList.map((value, index) =>
                    !value.editing ? (
                      <ViewPrizeCard
                        key={index}
                        prizeDetail={value}
                        handleEnableEdit={() => handleEnableEdit(index)}
                        AddingPrizeFlag={AddingPrizeFlag}
                      />
                    ) : (
                      <EditPrizeCard
                        key={index}
                        prizeDetail={value}
                        handleSave={handleEdit}
                        handleNewDelete={handleNewDelete}
                        handleIsEditing={handleIsEditing}
                        handleDelete={handleDeleteField}
                      />
                    )
                  )
                : null}

              {AddingPrizeFlag && !isEditing ? (
                <CreatePrizeCard
                  type={"create"}
                  handleSave={handleAddPrizeSubmit}
                  handleDelete={handleNewDelete}
                />
              ) : null}
            </Box>
          ) : null}

          <BlankPrizeCard
            AddingPrizeFlag={AddingPrizeFlag}
            AddPrize={AddPrize}
            handleIsEditing={handleIsEditing}
            handleBlankPrizeEdit={handleBlankPrizeEdit}
            handleNewDelete={handleNewDelete}
          />
          {prizeList.length < 7 && hasInstantLuckyDrawEditAccessRight ? (
            <Box p={2} pt={2} pl={3}>
              <Button
                variant="contained"
                color="primary"
                disableElevation
                onClick={AddPrize}
                disabled={AddingPrizeFlag}
              >
                <Box pr={0.5} display="flex">
                  <AddSVG />
                </Box>
                {getLang(lang,"label.ADD_PRIZE")}
              </Button>
            </Box>
          ) : null}
        </Box>
      </Paper>
    </Dialog>
  );
}
