import { Avatar, Box, Chip, Grid, TableCell, TableRow, Typography } from "@material-ui/core";
import { UnknownUserIcon } from "modules/reporting/assets/svg";
import { chipStyle, fontTextStyle, iconStyle, tableStyle } from "modules/reporting/styles";
import { drilldownPageSelection, responseHandler } from "modules/reporting/utils/helper";
import React from "react";
import { Link } from "react-router-dom";
import { TrendingNegativeCard, TrendingPositiveCard } from "../../card";
import { ContentCard } from "../../layout";
import { ChartCardLoading, TrendingCardLoading } from "../../skeleton/loading";
import DownloadableTable from "../../table/downloadable-table/downloadableTable.component";
import { MixedChart } from "../../charts";
import EmptyChart from "../../skeleton/global/empty-chart";

export default function ConsumerPanelComponent({
    status,
    data,
    sort,
    pagination,
    columns,
    currentFilter,
    handleExportExcel,
    pageType,
    translate
}) {
    const chipClasses = chipStyle();
    const tableClasses = tableStyle();
    const fontClasses = fontTextStyle();
    const iconClasses = iconStyle();

    // const renderData = (data) => {
    //     if (Object.keys(data).length === 0 || !data.isSuccess){
    //         return null;
    //     }

    //     return (
    //         data.trendingSales.map((item, index) => {
    //             return (
    //                 <TrendingDistributionPerformanceCard translate={translate} index={index} title={translate("label.PRODUCT")} data={item} />
    //             )
    //         })
    //     )
    // }

    const scanCountStatus = (scanCountStatus) => {
        if(scanCountStatus){
            return (
                <Chip label={translate("label.VALID")} className={chipClasses.chip__valid} />
            )
        }

        return (
            <Chip label={translate("label.SUSPENDED")} className={chipClasses.chip__suspended} />
        )
    }

    const renderTableData = (data) => {

        let rows = [];
        if(Object.keys(data).length === 0 || !data.isSuccess){
            return rows;
        }
        
        let imageProps = JSON.stringify(data);
        imageProps = JSON.parse(imageProps);

        let no_iteration = pagination.page * pagination.rowsPerPage + 1;
        imageProps.rows.forEach((item, index) => {
            let row = (
                <TableRow key={index}>
                    {/* no */}
                    <TableCell
                        className={tableClasses.table__cell}
                        align="left"
                        style={{minWidth:"50px"}}
                    >
                        <Typography className={fontClasses.text__table_cell}>
                            {no_iteration++}
                        </Typography>
                    </TableCell>
                    {/* image & name */}
                    <TableCell
                        className={tableClasses.table__cell}
                        align="left"
                        style={{minWidth:"190px"}}
                    >
                        <Box className={tableClasses.cell__product}>
                            {
                                item.image ?
                                <Avatar variant="square" alt={item.name} src={item.image} className={iconClasses.svg__icon}/>
                                :
                                <Avatar variant="square" alt={item.name} src={UnknownUserIcon} className={iconClasses.svg__icon}/>
                            }
                            
                            {
                                item.name ?
                                    (
                                        item.name !== "" ?
                                        <Link 
                                            target="_blank" rel="noopener noreferrer"
                                            className={fontClasses.text__hyperlink}
                                            to={() => {
                                                if(!item.uuid)
                                                    return;
            
                                                let filterProperties = {};
            
                                                if(item.uuid)
                                                    filterProperties.consumerUuid = item.uuid;
            
                                                let url = drilldownPageSelection(filterProperties, pageType, true);
                                                return url;
                                            }}
                                        >
                                            {item.name }
                                        </Link>
                                        :
                                        <Typography className={fontClasses.text__unknown}>
                                            {translate("label.UNKNOWN")}
                                        </Typography>
                                    )
                                : 
                                <Typography className={fontClasses.text__unknown}>
                                    {translate("label.UNKNOWN")}
                                </Typography>
                            }

                        </Box>
                    </TableCell>
                    {/* serial number */}
                    <TableCell className={tableClasses.table__cell} style={{minWidth:"185px"}}>
                        <Link 
                            target="_blank" rel="noopener noreferrer"
                            className={fontClasses.text__hyperlink}
                            to={() => {

                                let filterProperties = {};

                                if(item.enc)
                                    filterProperties.enc = item.enc;

                                let url = drilldownPageSelection(filterProperties, pageType, true);
                                return url;
                            }}
                        >
                            {item.serial_number}
                        </Link>
                    </TableCell>
                    {/* scan count */}
                    <TableCell className={tableClasses.table__cell} style={{minWidth:"190px"}}>
                        {
                            item.scan_count ? 
                            <Box
                                display="flex"
                                style={{
                                    gap: "5px",
                                }}
                                alignItems="center"
                            >
                                <Typography className={fontClasses.text__table_cell}>
                                    {item.scan_count}   /   {item.scan_limit === "label.NO_LIMIT" ? translate("label.NO_LIMIT") : item.scan_limit } 
                                </Typography>
                                {scanCountStatus(item.scan_count_status)}
                            </Box>
                            : <Typography className={fontClasses.text__unknown}>-</Typography>
                        }
                    </TableCell>
                    {/* gender */}
                    <TableCell
                        className={tableClasses.table__cell}
                        style={{minWidth:"90px"}}
                    >
                        {
                            item.gender ? 
                            <Typography className={fontClasses.text__table_cell}>
                                {item.gender ?? "-"}
                            </Typography>
                            :
                            <Typography className={fontClasses.text__unknown}>
                                -
                            </Typography>
                        }
                    </TableCell>
                    {/* age */}
                    <TableCell
                        className={tableClasses.table__cell}
                        style={{minWidth:"90px"}}
                    >
                        {
                            item.age ? 
                            <Typography className={fontClasses.text__table_cell}>
                                {item.age ?? "-"}
                            </Typography>
                            :
                            <Typography className={fontClasses.text__unknown}>
                                -
                            </Typography>
                        }
                    </TableCell>
                    {/* warranty */}
                    {/* <TableCell
                        className={classes.table__cell}
                    >
                        <Typography className={classes.table__text_cell}>
                            {row.warranty}
                        </Typography>
                    </TableCell> */}
                    {/* lucky draw */}
                    {/* <TableCell
                        className={classes.table__cell}
                    >
                        <Typography className={classes.table__text_cell}>
                            {row.lucky_draw}
                        </Typography>
                    </TableCell> */}
                    {/* instant lucky draw */}
                    {/* <TableCell
                        className={classes.table__cell}
                    >
                        <Typography className={classes.table__text_cell}>
                            {row.instantluckydraw}
                        </Typography>
                    </TableCell> */}
                    {/* survey */}
                    {/* <TableCell
                        className={classes.table__cell}
                    >
                        <Typography className={classes.table__text_cell}>
                            {row.survey}
                        </Typography>
                    </TableCell> */}
                    {/* total loyalty points */}
                    {/* <TableCell
                        className={classes.table__cell}
                    >
                        <Typography className={classes.table__text_cell}>
                            {row.loyalty_point}
                        </Typography>
                    </TableCell> */}
                    {/* date */}
                    <TableCell className={tableClasses.table__cell}  style={{minWidth:"160px"}}>
                        {
                            item.date ? 
                            <Typography className={fontClasses.text__table_cell}>
                                {item.date ?? "-"}
                            </Typography>
                            :
                            <Typography className={fontClasses.text__unknown}>
                                -
                            </Typography>
                        }
                    </TableCell>
                </TableRow>
            )

            
            item.render = row;
        })

        return imageProps;
    }

    return (
        <>
            <Box margin="20px">
                <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} sm={12} md={6} >
                        {
                            responseHandler(
                                data.totalScanTrendingData, 
                                status.isFetchingTotalScanTrending, 
                                <TrendingPositiveCard translate={translate} title={translate("label.TOTAL_VALID_SCAN_QR")} data={data.totalScanTrendingData} />, 
                                <TrendingCardLoading/>, 
                                null, 
                                null, 
                                null
                            )
                        }
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} >
                        {
                            responseHandler(
                                data.totalSuspendedTrendingData,
                                status.isFetchingTotalSuspendedTrending,
                                <TrendingNegativeCard translate={translate} title={translate("label.SUSPENDED_QR")} data={data.totalSuspendedTrendingData} />,
                                <TrendingCardLoading/>,
                                null,
                                null,
                                null
                            )
                        }
                    </Grid>
                    {/* <Grid item xs={12} sm={12} md={4} >
                        <Carousel        
                            autoPlay={true}
                            interval={5000}
                            infiniteLoop={true}
                        >
                            {
                                responseHandler(
                                    data.totalDistributionPerformanceData, 
                                    status.isFetchingTotalDistributionPerformance, 
                                    renderData(data.totalDistributionPerformanceData), 
                                    <TrendingCardLoading/>, 
                                    null, 
                                    null, 
                                    null
                                )
                            }
                        </Carousel>
                    </Grid> */}

                </Grid>
            </Box>

            <Box
                marginTop="20px"
                marginBottom="20px"
            >
                <ContentCard>
                    <Box marginBottom="24px" display="flex" justifyContent="space-between" alignContent="center">
                        <Typography className={fontClasses.text__card_title}>
                            {translate("label.TOTAL_SCAN_COUNT_AGAINST_SUSPENDED_QR")}
                        </Typography>
                    </Box>
                    {/* chart */}
                    {
                        responseHandler(
                            data.scanAgainstSuspendedData,
                            status.isFetchingScanAgainstSuspendedData,
                            (
                                <MixedChart 
                                    payload={data.scanAgainstSuspendedData.chart}
                                    chartHeight="340px"
                                    chartColors={[]}
                                    dontAddUnknown={true}
                                    translate={translate}
                                    multipleYAxis={[
                                        {
                                            name: translate("label.TOTAL_SCAN_QR"),
                                            color: "#6184FF"
                                        },
                                        {
                                            name: translate("label.SUSPENDED_QR"),
                                            color: "#F97066",
                                            opposite: true
                                        }
                                    ]}
                                />
                            ),
                            (
                                <ChartCardLoading />
                            ),
                            (
                                <EmptyChart
                                    data={[translate("label.TOTAL_SCAN"), translate("label.SUSPENDED")]}
                                    title={translate("label.CUSTOMER_NOT_FOUND")}
                                    value={translate("paragraph.NO_DATA_ON_SELECTED_FILTER")}
                                    labels= {['10 July', '11 July', '12 July', '13 July']}
                                />
                            ),
                            (
                                <EmptyChart
                                    data={[translate("label.TOTAL_SCAN"), translate("label.SUSPENDED")]}
                                    title={translate("label.CUSTOMER_NOT_FOUND")}
                                    value={translate("paragraph.NO_DATA_ON_SELECTED_FILTER")}
                                    labels= {['10 July', '11 July', '12 July', '13 July']}
                                />
                            ),
                            <EmptyChart
                                data={[translate("label.TOTAL_SCAN"), translate("label.SUSPENDED")]}
                                title={translate("label.LOADING_FAILED")}
                                value={translate("paragraph.LOADING_FAILED_DESCRIPTION")}
                                labels= {['10 July', '11 July', '12 July', '13 July']}
                            />
                        )
                    }
                </ContentCard>
            </Box>

            <Box
                marginTop="20px"
                marginBottom="20px"
            >
                <ContentCard>
                    {/* <Box marginBottom="24px" display="flex" justifyContent="space-between" alignContent="center">
                        <Typography className={fontClasses.text__card_title}>
                            {translate("label.CONSUMER_SCAN_COUNT")}
                        </Typography>
                    </Box> */}
                    <DownloadableTable 
                        title={translate("label.CONSUMER_SCAN_COUNT")}
                        sort={sort}
                        pagination={pagination}
                        columns={columns}
                        handleExportExcel={handleExportExcel}
                        status={status.isFetchingConsumerListingData}
                        data={renderTableData(data.consumerListingData)}
                        translate={translate}
                    />
                </ContentCard>

            </Box>
        </>
    )
}