import { createAsyncThunk } from "@reduxjs/toolkit";
import reportingApi from "app/api/reporting";
import moment from "moment";

// (1) branch table data -- start
export const getBranchSalesData = createAsyncThunk(
    "reporting/getBranchSalesData",
    async (payload, { rejectWithValue }) => {
        // await sleep(2000);
        return reportingApi.getBranchSalesData({
            length: payload.length,
            start: payload.start,
            search: payload.search,
            orderBy: payload.orderBy,
            orderDirection: payload.orderDirection,
            search_by: payload.searchBy,
            country : payload.countryCode,
            city_id: payload.city_uuid,
            branch_uuid: payload.branch_uuid,
            category_uuid: payload.category_uuid,
            model_uuid: payload.model_uuid,
            brand_uuid: payload.brand_uuid,
            product_uuid: payload.product_uuid,
            period: payload.period,
            start_date: moment(payload.start_date).format('YYYY-MM-DD'),
            end_date: moment(payload.end_date).format('YYYY-MM-DD'),
            url: payload.url,
            campaign_type: payload.campaign_type,
            campaign_uuid: payload.campaign_uuid,
        })
            .then((response) => response)
            .catch((error) => rejectWithValue(error));
    }
);
// (1) branch table data -- end