import { addAlert } from 'modules/notification'
import React from 'react'
import { useDispatch } from 'react-redux'
import EditSurveyQuestionCardComponent from './editSurveyQuestionCard.component'
import { useSelector } from "react-redux";

export default function EditSurveyQuestionCardContainer({
	dragHandleProps,
	handleDeleteQuestion,
	index,
	questions,
	setSurveyData,
	isError,
	setIsError
}) {
	const lang = useSelector(state => state.constant.languages);
	const dispatch = useDispatch()

	const alertWarningMessage = message => {
		dispatch(addAlert({ severity: "warning", message }))
	}

	return (
		<EditSurveyQuestionCardComponent
			questions={questions}
			alertWarningMessage={alertWarningMessage}
			dragHandleProps={dragHandleProps}
			handleDeleteQuestion={handleDeleteQuestion}
			index={index}
			setSurveyData={setSurveyData}
			isError={isError}
			setIsError={setIsError}
			lang={lang}
		/>
	)
}
