import { generatePaginationOptions } from "lib/helper"
import { getConsumerGiftRedemptionListing } from "modules/consumer/redux/action/consumer.action"
import { selectLoading } from "modules/notification"
import React, { useState } from "react"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useRouteMatch } from "react-router-dom"
import GiftRedemptionListPanelComponent from "./giftRedemptionListPanel.component"

function GiftRedemptListPanelContainer() {
  const dispatch = useDispatch()
  const match = useRouteMatch()
  const id = match.params.consumerId

  const list = useSelector(state => state.consumer.giftRedemptionList.data)
  const totalRecords = useSelector(state => state.consumer.giftRedemptionList.totalRecords)
  const totalFiltered = useSelector(state => state.consumer.giftRedemptionList.totalFiltered)
  const isFetching = useSelector(state => selectLoading(state, getConsumerGiftRedemptionListing.typePrefix))
  const isError = useSelector(state => state.consumer.isLoadingRedemptionListError)
  const lang = useSelector(state => state.constant.languages)
  const language = useSelector(state => state.profile.language)
  const isSearchResult = list.length < totalRecords
  const [paginationOptions, setPaginationOptions] = useState([])
  const [tableFilter, setTableFilter] = useState({
    page: 0,
    rowsPerPage: 25,
    order: "desc",
    date: "",
    gift: [],
    status: ""
  })

  useEffect(() => {
    dispatch(getConsumerGiftRedemptionListing({
      id,
      length: tableFilter.rowsPerPage,
      start: tableFilter.rowsPerPage * tableFilter.page,
      order: tableFilter.order,
      date: tableFilter.date,
      gift: tableFilter.gift,
      status: tableFilter.status,
    }))
  }, [dispatch, tableFilter, id])

  useEffect(() => {
    setPaginationOptions(generatePaginationOptions(totalFiltered))
  }, [totalFiltered])

  const handleReload = () => {
    dispatch(getConsumerGiftRedemptionListing({
      id,
      length: tableFilter.rowsPerPage,
      start: tableFilter.rowsPerPage * tableFilter.page,
      order: tableFilter.order,
      column: tableFilter.column,
      date: tableFilter.date,
      gift: tableFilter.gift,
      status: tableFilter.status,
    }))
  }

  const handleChangePage = (event, newPage) => {
    setTableFilter({
      ...tableFilter,
      page: newPage,
    })
  }

  const handleChangeRowsPerPage = event => {
    setTableFilter({
      ...tableFilter,
      page: 0,
      rowsPerPage: event.target.value,
    })
  }

  const handleFilter = (values) => {
    setTableFilter({
      ...tableFilter,
      page: 0,
      rowsPerPage: tableFilter.rowsPerPage,
      date: values.date,
      gift: values.gift,
      status: values.status,
    })
  }

  const changeOrder = (order) => {
    setTableFilter({
      ...tableFilter,
      order,
    })
  }

  return (
    <GiftRedemptionListPanelComponent
      list={list}
      isFetching={isFetching}
      isError={isError}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      page={tableFilter.page}
      rowsPerPage={tableFilter.rowsPerPage}
      totalFiltered={totalFiltered}
      handleReload={handleReload}
      isSearchResult={isSearchResult}
      paginationOptions={paginationOptions}
      tableFilter={tableFilter}
      language={language}
      changeOrder={changeOrder}
      handleFilter={handleFilter}
      id={id}
      lang={lang}
    />
  )
}

export default GiftRedemptListPanelContainer
