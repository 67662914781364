import React, { useRef, useState } from "react";
import {
  makeStyles,
  ButtonBase,
  Typography,
  Button,
  Box,
  Chip,
  Tooltip,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import clsx from "clsx";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "5px 12px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#ECEFF0",
    borderRadius: "4px",
    width: "100%",
  },
  inputText: {
    color: "#a4a6a8",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontWeight: 500,
  },
  selectedField: {
    display: "flex",
    alignItems: "center",
  },
  disabledField: {
    backgroundColor: "#eceff0",
  },
  filled: {
    minWidth: "auto",
    fontWeight: 500,
    color: theme.palette.primary.main,
    display: "inline-block",
    flexWrap: "wrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "100%",
    alignSelf: "center",
  },
  filledWithNum: {
    minWidth: "auto",
    fontWeight: 500,
    color: theme.palette.primary.main,
    display: "inline-block",
    flexWrap: "wrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "90%",
    alignSelf: "center",
  },
  textNum: {
    fontWeight: 500,
    color: theme.palette.primary.main,
    display: "inline-block",
  },
  chip: {
    backgroundColor: "#ececec",
    marginRight: "0.25em",
    marginBottom: "0.15em",
    marginTop: "0.15em",
    border: "none",
    boxShadow:
      "0px 3px 1px -2px rgba(0,0,0,0.1), 0px 2px 2px 0px rgba(0,0,0,0.04), 0px 1px 5px 0px rgba(0,0,0,0.02)",
  },
  tooltip: {
    maxHeight: "150px",
    overflow: "auto",
    display: "grid",
    "&::-webkit-scrollbar": {
      width: "0.3em",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "rgba(255,255,255,1)",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "2px",
      backgroundColor: "#ccd1d3",
    },
  },
  tooltipChip: {
    backgroundColor: "#FFF",
    margin: "0.5em 0.3em 0.5em 0.3em",
    display: "flex",
    justifyContent: "space-between",
    boxShadow:
      "0px 3px 1px -2px rgba(0,0,0,0.1), 0px 2px 2px 0px rgba(0,0,0,0.04), 0px 1px 5px 0px rgba(0,0,0,0.02)",
  },
}));

const CustomizeTooltip = withStyles({
  tooltip: {
    backgroundColor: "#f7f7f7",
    boxShadow:
      "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
  },
  arrow: {
    scale: 2,
    color: "#f7f7f7",
    top: "-4px !important",
    "&::before": {
      boxShadow:
        "0px 5px 0px -2px rgba(0,0,0,0.2), 0px 0px 2px -1px rgba(0,0,0,0.44), 0px 0px 5px -2px rgba(0,0,0,0.42)",
    },
  },
})(Tooltip);

export default function LanguageSelectComponent({
  handleClick,
  handleClear,
  value,
  placeholder,
  disabled,
  style,
  isEmpty,
  id,
  open,
  textStyle,
  isClearable,
  handleChipDelete,
  handleChange,
  drawerOpen,
  extraChip,
  adjustField,
  lang,
}) {
  const classes = useStyles();
  const ref = useRef(null);
  const [dimension, setDimension] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    // Add event when user zoom in/out or resize screen size
    const handleResize = () => {
      setDimension({
        width: window.innerHeight,
        height: window.innerWidth,
      });
      adjustField(ref);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    adjustField(ref);
    // eslint-disable-next-line
  }, [value, dimension]);

  useEffect(() => {
    setTimeout(() => {
      adjustField(ref);
      window.dispatchEvent(new Event("resize"));
    }, 200);
    // eslint-disable-next-line
  }, [drawerOpen]);

  const getDisplay = () => {
    let display = placeholder;
    if (value.length > 0) {
      display = value[0].label;
    }
    return display;
  };
  const displayValue = () => {
    return (
      <Box
        ref={ref}
        style={{
          display: "flex",
          flexWrap: "wrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          flex: "2",
        }}
      >
        <Typography
          variant="body2"
          noWrap
          style={
            extraChip.allOverflow ? { textOverflow: "clip" } : { textStyle }
          }
          className={clsx(
            classes.inputText,
            value.length > 1 && classes.filledWithNum,
            getDisplay() !== placeholder && classes.filled
          )}
        >
          {isEmpty ? (
            <span
              className={clsx(classes.inputText, {
                [classes.filled]: !isEmpty,
              })}
              style={textStyle}
            >
              {placeholder}
            </span>
          ) : (
            value.map((item, i) => (
              <Chip
                key={i}
                variant="outlined"
                label={item.label}
                size="small"
                className={classes.chip}
                onDelete={
                  item.code === "EN" ? null : () => handleChipDelete(item)
                }
                style={extraChip.allOverflow ? { visibility: "hidden" } : {}}
              />
            ))
          )}
        </Typography>
      </Box>
    );
  };

  const extraList = () => {
    return (
      <div className={classes.tooltip}>
        {extraChip.overflowChild.map((item) => (
          <Chip
            label={item.label}
            size="small"
            className={classes.tooltipChip}
            onDelete={() => handleChipDelete(item)}
          />
        ))}
      </div>
    );
  };

  return (
    <ButtonBase
      aria-describedby={id}
      className={clsx(classes.root, {
        [classes.disabledField]: disabled,
      })}
      disableRipple
      onClick={handleClick}
      disabled={disabled}
      style={{ padding: 12, ...style }}
    >
      {displayValue()}
      <Box
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {isClearable && !isEmpty && (
          <Button
            className={classes.clearButton}
            onClick={(e) => {
              e.stopPropagation();
              handleClear();
            }}
          >
            <ClearIcon style={{ fontSize: 16 }} />
          </Button>
        )}

        {extraChip.count >= 1 && !isEmpty ? (
          <CustomizeTooltip title={extraList()} arrow interactive>
            <Chip
              label={"+ " + extraChip.count}
              size="small"
              className={classes.chip}
              style={{
                left: extraChip.offset,
                position: "absolute",
                marginLeft: 3,
              }}
            />
          </CustomizeTooltip>
        ) : null}

        {open ? (
          <ArrowDropUpIcon fontSize="small" />
        ) : (
          <ArrowDropDownIcon fontSize="small" />
        )}
      </Box>
    </ButtonBase>
  );
}
