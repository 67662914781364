import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import MainPageComponent from "./main.page";
import { openCreationDialog } from "modules/instant-lucky-draw/redux";
import { INSTANT_LUCKY_DRAW_VIEW, INSTANT_LUCKY_DRAW_ADD } from "lib/constants/accessRights";
import LoadingComponent from "components/loading";
import { getLang } from "app/feature/constants";

export default function MainPageContainer() {
  const [activeIndex, setActiveIndex] = useState(0);
  const dispatch = useDispatch()
  const history = useHistory()
  const hasInstantLuckyDrawViewAccessRight = useSelector(state => state.profile.accessRights.includes(INSTANT_LUCKY_DRAW_VIEW))
  const hasInstantLuckyDrawAddAccessRight = useSelector(state => state.profile.accessRights.includes(INSTANT_LUCKY_DRAW_ADD))
  const lang = useSelector(state => state.constant.languages)

  useEffect(() => {
    if (!hasInstantLuckyDrawViewAccessRight) {
      history.push("/admin/dashboard")
    }
  }, [history, hasInstantLuckyDrawViewAccessRight])

  if (!hasInstantLuckyDrawViewAccessRight) return <LoadingComponent />;

  const tabLabels = [getLang(lang,"label.INSTANT_LUCKY_DRAW_LIST")];

  const handleChange = (event, index) => {
    setActiveIndex(index);
  };

  const handleCreateInstantLuckyDraw = () => {
    dispatch(openCreationDialog())
  }

  return (
    <MainPageComponent
      hasInstantLuckyDrawAddAccessRight={hasInstantLuckyDrawAddAccessRight}
      handleChange={handleChange}
      activeIndex={activeIndex}
      tabLabels={tabLabels}
      handleCreateInstantLuckyDraw={handleCreateInstantLuckyDraw}
      lang={lang}
    />
  );
}