import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import ApproveClaimExtendDialogComponent from "./approveClaimExtendDialog.component";

function ApproveClaimExtendDialogContainer({ isOpen, handleClose, handleConfirm, initialValue }) {
  const lang = useSelector(state => state.constant.languages);

  return (
    <ApproveClaimExtendDialogComponent
      isOpen={isOpen}
      handleClose={handleClose}
      handleConfirm={handleConfirm}
      initialValue={initialValue}
      lang={lang}
    />
  );
}

ApproveClaimExtendDialogContainer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
};

export default ApproveClaimExtendDialogContainer;
