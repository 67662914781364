import axios from "axios";

export function getRequestTransferAddressList(parameters) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/distributor/admin/api/v1/request-transfer/get-address-list`, {
        length: parameters.length,
        start: parameters.start,
        from_node_uuid: parameters.from_node_uuid
      })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data));
  });
}
