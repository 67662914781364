import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { COUNTERFEIT_REPORT_VIEW } from "lib/constants/accessRights"
import CounterfeitReportDetailComponent from "./counterfeit-report-detail.page";
import LoadingComponent from "components/loading";

export default function CounterfeitReportDetailContainer() {
  const history = useHistory()
  const hasCounterfeitReportViewAccessRight = useSelector(state => state.profile.accessRights.includes(COUNTERFEIT_REPORT_VIEW));
  const lang = useSelector(state => state.constant.languages)
  useEffect(() => {
    if(!hasCounterfeitReportViewAccessRight){
      history.push('/admin/dashboard')
    }
  }, [history, hasCounterfeitReportViewAccessRight])

  if (!hasCounterfeitReportViewAccessRight) return <LoadingComponent />;

  return <CounterfeitReportDetailComponent lang={lang}/>;
}
