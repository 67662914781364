import React from "react";
import SurveyAgeCardComponent from "./surveyAgeCard.component";

export default function SurveyAgeCardContainer({
  title,
  data
}) {
  return (
    <SurveyAgeCardComponent title={title} data={data}/>
  )
}