import React from "react";
import StockTakeProductListTableComponent from "./stockTakeProductListTable.component";

export default function StockTakeProductListTableContainer({
  rows,
  isFetching,
  isError,
  handleReload,
  handleChangePage,
  handleChangeRowsPerPage,
  totalFiltered,
  paginationOptions,
  tableFilter,
  changeOrder,
  lang,
  handleViewDetail,
  isCompleted,
}) {
  return (
    <StockTakeProductListTableComponent
      rows={rows}
      isFetching={isFetching}
      isError={isError}
      handleReload={handleReload}
      handleChangePage={handleChangePage}
      handleChangeRowPerPage={handleChangeRowsPerPage}
      totalFiltered={totalFiltered}
      paginationOptions={paginationOptions}
      tableFilter={tableFilter}
      changeOrder={changeOrder}
      lang={lang}
      handleViewDetail={handleViewDetail}
      isCompleted={isCompleted}
    />
  );
}
