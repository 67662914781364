import React from "react";
import {
  makeStyles,
  Paper,
  Button,
  Divider,
  Typography,
  IconButton,
  Backdrop,
  Box
} from "@material-ui/core";
import Dialog from "components/dialog/customDialog";
import CloseIcon from "@material-ui/icons/Close";
// import Box_Allocation from "../../../utils/image/9box_allocation.png";
import Wheel_Allocation from "../../../utils/image/wheel_allocation.png";
import { ErrorSVG, AddSVG, DragabbleSVG } from "../../../utils/static";
import PrizeSelect from "../../select/prizeSelect";
import { getLang } from "app/feature/constants";

const style = (theme) => ({
  header: {
    padding: "4px 0px",
    paddingLeft: theme.spacing(3),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  root: {
    width: 650,
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  gameContainer: {
    background: theme.palette.primary.main,
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(2)
  },
  content: {
    padding: theme.spacing(4),
    alignItems: "center",
    display: "flex",
    border: "1px solid rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
    margin: theme.spacing(0, 2),
    justifyContent: "center"
  },
  discardButton: {
    color: theme.palette.error.main
  },
  publishButton: {
    color: theme.palette.common.white
  },
  divider: {
    height: "70%",
    display: "flex",
    alignSelf: "center",
  },
  nameContainer: {
    height: 43,
    backgroundColor: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 70,
    borderRadius: "0px 4px 4px 0px"
  },
  dividerContainer: {
    backgroundColor: "#fff",
    display: "flex"
  },
  slotContainer: {
    display: "flex",
    border: "1px solid rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
    margin: theme.spacing(1, 2)
  }
});

const useStyles = makeStyles(style);

export default function PrizeAllocationDialogComponent({
  handleClose,
  isOpen,
  lang
}) {
  const classes = useStyles();

  return (
    <Dialog
      isOpen={isOpen}
      handleClose={handleClose}
      disableEnforceFocus={true}
      disableAutoFocus={true}
      disableEscapeKeyDown={true}
      disableBackdropClick={true}
      closeAfterTransition
      BackdropComponent={Backdrop}
    >
      <Paper elevation={0} className={classes.root}>
        <Box className={classes.header}>
          <Typography
            variant="subtitle2"
            color="primary"
            style={{ fontWeight: "bold" }}
          >
            {getLang(lang,"label.WHEEL_OF_FORTUNE")}
          </Typography>
          <IconButton color="primary" onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>

        <Divider />

        <div className={classes.gameContainer}>
          <img
            src={Wheel_Allocation}
            alt="allocation"
            width="40%"
            height="100%"
          />
        </div>

        <Box p={2} style={{ display: "flex", placeContent: "space-between" }}>
          <Typography variant="body2" color="primary">
            {getLang(lang,"paragraph.ALLOCATE_PRIZE")}
          </Typography>
          <Typography
            variant="body2"
            style={{ color: "rgba(58, 77, 84, 0.33)" }}
          >
            {getLang(lang,"label.MAXIMUM_SLOT")}:{" "}
            <span style={{ color: "black", fontWeight: "bold" }}>8</span>
          </Typography>
        </Box>

        <Box className={classes.slotContainer}>
          <Box display="flex">
            <Box className={classes.nameContainer}>
              <span
              // className={classes.dragIconButton}
              // {...provided.dragHandleProps}
              >
                <DragabbleSVG />
              </span>
              <Typography
                variant="subtitle2"
                style={{
                  fontWeight: "bold",
                  color: " rgba(58, 77, 84, 0.33)"
                }}
              >
                {getLang(lang,"label.SLOT_1")}
              </Typography>
            </Box>
            <Box className={classes.dividerContainer}>
              <Divider orientation="vertical" className={classes.divider} />
            </Box>
          </Box>
          <PrizeSelect />
        </Box>

        <Box className={classes.content}>
          <ErrorSVG />

          <Typography
            variant="body1"
            color="primary"
            style={{ paddingLeft: "4px" }}
          >
            {getLang(lang,"label.NO_PRIZE_ALLOCATION_SLOT")}
          </Typography>
        </Box>

        <Box p={2}>
          <Button variant="contained" color="primary" disableElevation>
            <Box pr={0.5} display="flex">
              <AddSVG />
            </Box>
            {getLang(lang,"label.ALLOCATION_SLOT")}
          </Button>
        </Box>

        <Box mt="auto">
          <Divider />
          <Box
            display="flex"
            justifyContent="space-between"
            px={2}
            pt={1}
            pb={1}
          >
            <Button
              variant="text"
              classes={{ root: classes.discardButton }}
              onClick={handleClose}
            >
              {getLang(lang,"label.DISCARD")}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              classes={{ root: classes.publishButton }}
              disableElevation
            >
              {getLang(lang,"label.SAVE")}
            </Button>
          </Box>
        </Box>
      </Paper>
    </Dialog>
  );
}
