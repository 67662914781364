import React from "react"
import {
  Box,
  Link,
  makeStyles,
  Typography,
} from "@material-ui/core"
import { Skeleton } from '@material-ui/lab'
import {
  CheckCircleRounded as CheckCircleIcon
} from '@material-ui/icons'
import RefreshTable from "components/table/refreshTable"
import { useHistory } from "react-router-dom"
import { ROOT as SURVEY_ROOT } from "modules/survey"
import { ROOT as WARRANTY_ROOT } from "modules/warranty"
import { ROOT as LUCK_DRAW_ROOT } from "modules/lucky-draw"
import { ROOT as INSTANT_LUCKY_DRAW_ROOT } from "modules/instant-lucky-draw"
import { ROOT as CONSUMER_ROOT } from "modules/consumer"
import { getLang } from "app/feature/constants"
import moment from "moment";
import "moment/min/locales.min";

const useStyles = makeStyles((theme) => ({
  container: {
    background: "white",
    borderRadius: 4,
    padding: "16px 20px",
    overflow: "auto",
  },
  list: {
    display: "grid",
    columnGap: theme.spacing(2),
    rowGap: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr 1fr",
    },
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
    },
  },
  card: {
    border: "1px solid #D0D5DD",
    padding: "8px 4px 8px 12px",
    borderRadius: 4,
  }
}))

export default function CampaignAssignListPanelComponent({
  campaigns,
  isFetching,
  isError,
  language,
  handleReload,
  lang
}) {
  const classes = useStyles()
  const history = useHistory()
  
  if(language==="ZH")
  {
    moment.locale('zh-cn')
  }else if(language==="TL")
  {
    moment.locale('tl-ph')
  }else
  {
    moment.locale(language)
  }

  const LUCKY_DRAW = "lucky_draw"
  const INSTANT_LUCKY_DRAW = "instant_lucky_draw"
  const WARRANTY = "Warranty"
  const SURVEY = "survey"

  return (
    <>
      <Box className={classes.container} mt={2}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="body1"><b>{getLang(lang, "label.CAMPAIGN_ASSIGNED")}</b></Typography>
        </Box>
        <Box>
          {!isFetching && (
            campaigns.length ? (
              <Box className={classes.list}>
                {campaigns.map((campaign, index) => (
                  <Box key={index} className={classes.card} display="flex" justifyContent="space-between" alignItems="center">
                    <Box>
                      <Typography variant="body1" style={{ marginBottom: 8 }}><b>{getLang(lang, campaign.title)}</b></Typography>
                      <Box mb={1}>
                        {[LUCKY_DRAW, INSTANT_LUCKY_DRAW, WARRANTY, SURVEY].includes(campaign.type) ? (
                          <>
                            {campaign.latest_join_time !== "" && campaign.type !== SURVEY ?
                              <Link
                                href={`${CONSUMER_ROOT}/${campaign.consumerUuid}`}
                                color="secondary"
                                onClick={(e) => {
                                  history.push(`${CONSUMER_ROOT}/${campaign.consumerUuid}`)
                                  e.preventDefault();
                                  return false;
                                }}
                              >
                                <Typography variant="body1">{campaign.consumerName}</Typography>
                              </Link> :
                              null}
                            <Link
                              href={
                                campaign.type === SURVEY 
                                ? `${SURVEY_ROOT}/view/${campaign.uuid}` 
                                : campaign.type === WARRANTY 
                                ? `${WARRANTY_ROOT}/View/${campaign.uuid}` 
                                : campaign.type === LUCKY_DRAW 
                                ? `${LUCK_DRAW_ROOT}/View/${campaign.uuid}` 
                                : `${INSTANT_LUCKY_DRAW_ROOT}/View/${campaign.uuid}`
                              }
                              style={{ display: "flex" }}
                              color="secondary"
                              onClick={(e) => {
                                if (campaign.type === SURVEY) {
                                  history.push(`${SURVEY_ROOT}/view/${campaign.uuid}`)
                                }
                                if (campaign.type === WARRANTY) {
                                  history.push(`${WARRANTY_ROOT}/View/${campaign.uuid}`);
                                }
                                if (campaign.type === LUCKY_DRAW) {
                                  history.push(`${LUCK_DRAW_ROOT}/View/${campaign.uuid}`);
                                }
                                if (campaign.type === INSTANT_LUCKY_DRAW) {
                                  history.push(`${INSTANT_LUCKY_DRAW_ROOT}/View/${campaign.uuid}`);
                                }
                                e.preventDefault();
                                return false;
                              }}
                            >
                              <Typography variant="body1" style={{ textAlign: "left" }}>
                                {campaign.name}
                              </Typography>
                            </Link>
                          </>
                        ) :
                          (
                            <>
                              {campaign.latest_join_time !== "" ?
                                <Link
                                  href={`${CONSUMER_ROOT}/${campaign.consumerUuid}`}
                                  color="secondary"
                                  onClick={(e) => {
                                    history.push(`${CONSUMER_ROOT}/${campaign.consumerUuid}`)
                                    e.preventDefault();
                                    return false;
                                  }}
                                >
                                  <Typography variant="body1">{campaign.consumerName}</Typography>
                                </Link> :
                                null}
                              <Typography variant="body1">{campaign.name}</Typography>
                            </>
                          )}
                      </Box>
                      <Typography variant="body1">
                        {`${getLang(lang, campaign.type === "loyalty_point" ? "label.COLLECTED_DATE" : "label.JOIN_DATE")} : ${campaign.latest_join_time ? moment(campaign.latest_join_time).format('lll') : "-"}`}
                      </Typography>
                    </Box>
                    <Box ml={1.5}>
                      <CheckCircleIcon style={{ fontSize: 85, color: campaign.latest_join_time ? "#32D583" : "#D0D5DD" }} />
                    </Box>
                  </Box>
                ))}
              </Box>
            ) : (
              <Box display="flex" justifyContent="center" my={4}>
                <Typography variant="body2"><b>{getLang(lang, "paragraph.NO_CAMPAIGN_ASSIGNED")}</b></Typography>
              </Box>
            )
          )}
          {!isFetching && isError && (
            <Box className={classes.tabContainer}>
              <Box style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <RefreshTable handleReload={handleReload} />
              </Box>
            </Box>
          )}
          {isFetching && (
            <Box flex={1}>
              <Skeleton variant="text" height={80} />
              <Skeleton variant="text" height={80} />
              <Skeleton variant="text" height={80} />
            </Box>
          )}
        </Box>
      </Box>
    </>
  )
}
