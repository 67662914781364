import React from "react";
import FacebookFormComponent from "./facebookForm.component";
import PropTypes from "prop-types";
import {
  createFacebookModal,
  isFacebookUrlValid
} from "../../../utils/productPage.util";
import { useSelector } from "react-redux";
const TYPES = ["new", "edit", "readonly"];

function FacebookFormContainer({
  type,
  handleResult,
  initialValues,
  handleDelete,
  handleClose
}) {
  const lang = useSelector(state => state.constant.languages)

  const handleSubmit = (values, thunkApi) => {
    if (!isFacebookUrlValid(values.pageURL)) {
      thunkApi.setFieldError("pageURL", "Invalid Facebook page URL");
      return;
    }

    handleResult({
      pageURL: values.pageURL,
      useSmallHeader: values.useSmallHeader,
      hideCoverPhoto: values.hideCoverPhoto,
      showFriendsFaces: values.showFriendsFaces,
      tabs: {
        timeline: values.tabsTimeline,
        events: values.tabsEvents,
        messages: values.tabsMessages
      }
    });
  };

  return (
    <FacebookFormComponent
      type={type}
      handleSubmit={handleSubmit}
      handleDelete={handleDelete}
      initialValues={initialValues}
      lang={lang}
      handleClose={handleClose}
    />
  );
}

FacebookFormContainer.defaultProps = {
  type: TYPES[0],
  initialValues: createFacebookModal().content
};

FacebookFormContainer.propTypes = {
  type: PropTypes.oneOf(TYPES).isRequired,
  handleResult: PropTypes.func,
  handleDelete: PropTypes.func,
  initialValues: PropTypes.object
};

export { TYPES };

export default FacebookFormContainer;
