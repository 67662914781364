import React from "react";
import {
  Box,
  Paper,
  Typography,
  makeStyles,
} from "@material-ui/core";

import LineChart from '../../visualisation/lineChart';
import { getLang } from "app/feature/constants";

const useStyles = makeStyles((theme) => ({
  container: {
    boxShadow: "0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);",
    borderRadius: 6,
    padding: theme.spacing(3, 2),
  },
}));

export default function TotalResponseCardComponent({
  totalResponse,
  lang
}) {
  const classes = useStyles()

  return (
    <Paper className={classes.container}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <Typography variant="subtitle1" color='textPrimary' style={{ fontWeight: "500" }}>{getLang(lang, 'label.TOTAL_RESPONSE')}</Typography>
        </Box>
      </Box>
      <Box pt={2}>
        <LineChart labels={totalResponse.surveyDate} data={totalResponse.surveyTotalCount}/>
      </Box>
    </Paper>
  )
}