import { createAsyncThunk } from "@reduxjs/toolkit";
import reportingApi from "app/api/reporting";

export const getReportValidation = createAsyncThunk(
    "reporting/getReportValidation",
    async (payload, { rejectWithValue }) => {
        // await sleep(2000);
        return reportingApi.getReportValidation({
            items: payload.items
        })
            .then((response) => response)
            .catch((error) => rejectWithValue(error));
    }
);
