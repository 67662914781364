import React, { useEffect, useState } from "react";
import MainPageComponent from "./main.page";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux"
import { EMPLOYEE_VIEW, ORGANISATION_STRUCTURE_VIEW } from "lib/constants/accessRights"
import LoadingComponent from "components/loading";
import { getLang } from "app/feature/constants";

function MainPageContainer() {
  const history = useHistory();
  const hasEmployeeViewAccessRight = useSelector(state => state.profile.accessRights.includes(EMPLOYEE_VIEW));
  const hasOrganisationStructureViewAccessRight = useSelector(state => state.profile.accessRights.includes(ORGANISATION_STRUCTURE_VIEW));
  const lang = useSelector(state => state.constant.languages);

  useEffect(() => {
    if(!hasOrganisationStructureViewAccessRight){
      history.push('/admin/dashboard')
    }
  }, [history, hasOrganisationStructureViewAccessRight])

  const [activeIndex, setActiveIndex] = useState(0);
  
  if (!hasOrganisationStructureViewAccessRight) return <LoadingComponent />;
  const tabLabels = [getLang(lang,"label.STRUCTURE_OVERVIEW")];

  const handleChange = (event, index) => {
    setActiveIndex(index);
  };

  return (
    <>
      <MainPageComponent
        hasEmployeeViewAccessRight={hasEmployeeViewAccessRight}
        handleChange={handleChange}
        activeIndex={activeIndex}
        tabLabels={tabLabels}
        lang={lang}
      />
    </>
  );
}

export default MainPageContainer;
