import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  makeStyles,
  Typography,
  Box,
  CircularProgress,
  Divider
} from "@material-ui/core";
import { Info as InfoIcon } from "@material-ui/icons";
import { useSelector } from "react-redux";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    padding: "35px 30px",
    width: "400px",
    display: "flex",
    alignItems: "center"
  },
  customDialogActionRoot: {
    justifyContent: "center",
    flexFlow: "column",
    rowGap: "16px",
    paddingTop: theme.spacing(3)
  },
  customDialogTitle: {
    paddingTop: 0,
    color: theme.palette.primary.main
  },
  customDialogContext: {
    textAlign: "start"
  },
  dialogRemarksCaption: {
    marginLeft: 5
  },
  dialogRemarks: {
    color: theme.palette.primary.main,
  },
  dialogContentText: {
    margin: "8px 0px",
    display: "flex",
    justifyContent: "space-between",
    gridTemplateColumns: "8fr 1fr",
    color: theme.palette.primary.main
  },
  numberText: {
    fontWeight: "bold"
  },
  deductedNumberText: {
    fontWeight: "bold",
    color: "#FF0000"
  },
  container: {
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    }
  }
}));

export default function ConfirmationDialogComponent({
  qrCredit,
  registrationType,
  isLoading,
  isOpen,
  handleClose,
  handleProceed,
  title,
  quantity,
  remarks
}) {
  const classes = useStyle();
  const lang = useSelector(state => state.constant.languages)
  
  return (
    <Dialog
      classes={{
        paper: classes.root
      }}
      open={isOpen}
      disableBackdropClick
      disableEscapeKeyDown
      aria-labelledby="confirmation-dialog"
      maxWidth="sm"
    >
      <Box className={classes.container}>
        <DialogTitle
          classes={{
            root: classes.customDialogTitle
          }}
        >
          {title}
        </DialogTitle>
        {
          registrationType !== 3 
            ?
            <DialogContent
              classes={{
                root: classes.customDialogContext
              }}
            >
              <DialogContentText className={classes.dialogContentText}>
              {getLang(lang, 'label.CURRENT_BALANCE')}
                <span className={classes.numberText}> {qrCredit} </span>
              </DialogContentText>
              <DialogContentText className={classes.dialogContentText}>
              {getLang(lang, 'label.REQUESTED_SERIAL_NO_QUANTITY')}{" "}
                <span className={classes.numberText}> {quantity} </span>
              </DialogContentText>
              <DialogContentText className={classes.dialogContentText}>
                {getLang(lang, 'label.TOTAL_COST_CREDIT')}{" "}
                <span className={classes.deductedNumberText}>-{quantity} </span>
              </DialogContentText>
              <Divider />
              <DialogContentText className={classes.dialogContentText}>
              {getLang(lang, 'label.NEW_BALANCE')}
                <span className={classes.numberText}> {qrCredit - quantity} </span>
              </DialogContentText>
            </DialogContent>
            : null
        }

        <DialogActions
          disableSpacing="true"
          classes={{ root: classes.customDialogActionRoot }}
        >
          {isLoading ? (
            <CircularProgress />
          ) : (
            <>
              <Typography
                variant="body2"
                color="primary"
                style={{ fontWeight: "bold" }}
              >
                {getLang(lang, 'paragraph.CONFIRM_TO_START_GENERATE')}
              </Typography>
              <div style={{ columnGap: "8px", display: "flex" }}>
                <Button
                  variant={"contained"}
                  onClick={handleClose}
                  color="primary"
                >
                  {getLang(lang, 'label.BACK')}
                </Button>

                <Button
                  onClick={handleProceed}
                  color="secondary"
                  variant="contained"
                  autoFocus
                >
                  {getLang(lang, 'label.CONFIRM')}
                </Button>
              </div>
            </>
          )}
        </DialogActions>
        {remarks && (
          <Box
            marginTop="20px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            classes={{
              root: classes.dialogRemarks
            }}
          >
            <InfoIcon />
            <Typography
              classes={{
                root: classes.dialogRemarksCaption
              }}
              variant="body2"
              display="block"
            >
              {remarks}
            </Typography>
          </Box>
        )}
      </Box>
    </Dialog>
  );
}
