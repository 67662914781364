import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import employeeApi from "app/api/employee";

const EMPLOYEE = "employee";

export const fetchOnlineEmployee = createAsyncThunk(
  `${EMPLOYEE}/fetchOnlineEmployee`,
  async (payload, thunkApi) => {
    return employeeApi.getOnlineEmployee.get(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));

  }
);

export const fetchMoreOnlineEmployee = createAction(
  `${EMPLOYEE}/fetchMoreOnlineEmployee`
);

export const fetchRoleDropdown = createAsyncThunk(
  `${EMPLOYEE}/fetchRoleDropdown`,
  async (payload, thunkApi) => {
    return employeeApi.getRoleDropdown.get(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));

  }
);

export const fetchAllEmployee = createAsyncThunk(
  `${EMPLOYEE}/fetchAllEmployee`,
  async (payload, thunkApi) => {
    return employeeApi.getAllEmployee.get(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));

  }
);

export const fetchBranchDropdown = createAsyncThunk(
  `${EMPLOYEE}/fetchBranchDropdown`,
  async (payload, thunkApi) => {
    return employeeApi.getBranchDropdown.get(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));

  }
);

export const createEmployee = createAsyncThunk(
  `${EMPLOYEE}/createEmployee`,
  async (payload, thunkApi) => {
    return employeeApi.employee.post(payload)
      .then((response) => response)
      .catch((error) => thunkApi.rejectWithValue(error));

  }
);

export const updateEmployeeDetail = createAsyncThunk(
  `${EMPLOYEE}/updateEmployeeDetail`,
  async (payload, thunkApi) => {
    return employeeApi.employee.put(payload)
      .then((response) => response)
      .catch((error) => thunkApi.rejectWithValue(error));

  }
);

export const fetchEmployeeDetail = createAsyncThunk(
  `${EMPLOYEE}/fetchEmployeeDetail`,
  async (payload, thunkApi) => {
    return employeeApi.employee.get({
      id: payload,
    })
      .then((response) => response)
      .catch((error) => thunkApi.rejectWithValue(error));

  }
);

export const fetchFilterBranch = createAsyncThunk(
  `${EMPLOYEE}/fetchFilterBranch`,
  async (payload, thunkApi) => {
    return employeeApi.getFilterBranch.get(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));

  }
);

export const resendEmail = createAsyncThunk(
  `${EMPLOYEE}/resendEmail`,
  async (payload, thunkApi) => {
    return employeeApi.resendEmail.post(payload)
      .then((response) => response)
      .catch((error) => thunkApi.rejectWithValue(error));

  }
);

export const removeEmployee = createAsyncThunk(
  `${EMPLOYEE}/removeEmployee`,
  async (payload, thunkApi) => {
    return employeeApi.employee.remove(payload)
      .then((response) => response)
      .catch((error) => thunkApi.rejectWithValue(error));

  }
);

export const changeEmployeeStatus = createAsyncThunk(
  `${EMPLOYEE}/changeEmployeeStatus`,
  async (payload, thunkApi) => {
    return employeeApi.changeEmployeeStatus.put({
      id: payload.id,
      state: payload.state,
      remarks: payload.remarks
    })
      .then((response) => response)
      .catch((error) => thunkApi.rejectWithValue(error));

  }
);

export const checkEmailExists = createAsyncThunk(
  `${EMPLOYEE}/checkEmailExists`,
  async (payload, thunkApi) => {
    return employeeApi.checkEmailExists.post(payload)
      .then((response) => response)
      .catch((error) => thunkApi.rejectWithValue(error));

  }
);
