import { Box, makeStyles, TableCell, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
    tableRow: {
        padding: "100px 0",
    },
    row__content: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "8px",
    },
    row__title: {
        fontFamily: "Roboto",
        fontWeight: "700",
        fontSize: "16px",
        lineHeight: "24px",
        color: '#101828',
        textAlign: "center"
    },
    row__value: {
        fontFamily: "Roboto",
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "24px",
        color: '#98A2B3',
        textAlign: "center"
    }
}))

export default function TableCellNoResult({
    title, value
}){
    const classes = useStyles();
    return (
        <TableCell align="center" className={classes.tableRow} colspan={"100%"}>
            <Box className={classes.row__content}>
                <Typography className={classes.row__title}>
                    {title}
                </Typography>
                <Typography className={classes.row__value}>
                    {value}
                </Typography>
            </Box>
        </TableCell>
    )
}