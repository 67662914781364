import { getLang } from "app/feature/constants"

export function instantCreated ({
  hasInstantLuckyDrawEditAccessRight,
  item,
  disableViewParticipants,
  handleView,
  handleViewParticipants,
  handleViewPrize,
  lang
}) {
  let actionItems = []
  actionItems.push({
    label: hasInstantLuckyDrawEditAccessRight ? getLang(lang,"label.VIEW_EDIT_DETAIL") : getLang(lang,"label.VIEW_DETAIL"),
    action: () => handleView(item.id)
  })
  if(hasInstantLuckyDrawEditAccessRight){
    actionItems.push({
      label: getLang(lang,"label.SET_PRIZE"),
      action: () => handleViewPrize(item, "prize")
    })
  }
  actionItems.push({
    label: getLang(lang,"label.VIEW_PARTICIPANTS"),
    action: () => handleViewParticipants(item.id),
    disabled: disableViewParticipants
  })
  if(hasInstantLuckyDrawEditAccessRight){
    actionItems.push({
      label: getLang(lang,"label.MANAGE_WINNER"),
      disabled: true,
      required: true,
      requiredText: getLang(lang,"label.REQUIRED_SET_PRIZE")
    })
  }
  actionItems.push({
    label: getLang(lang,"label.PUBLISH"),
    disabled: true,
    required: true,
    requiredText: getLang(lang,"label.REQUIRED_SET_PRIZE")
  })
  actionItems.push({
    label: getLang(lang,"label.DOWNLOAD_WINNER_LIST"),
    disabled: true,
    required: true,
    requiredText: getLang(lang,"label.REQUIRED_SET_PRIZE")
  })
  return actionItems
};

export function prizeCreated ({
  hasInstantLuckyDrawEditAccessRight,
  item,
  disableViewParticipants,
  handleView,
  handleViewParticipants,
  handleViewPrize,
  handleDownloadWinner,
  handlePublished,
  lang
}) {
  let actionItems = []
  actionItems.push({
    label: hasInstantLuckyDrawEditAccessRight ? getLang(lang,"label.VIEW_EDIT_DETAIL") : getLang(lang,"label.VIEW_DETAIL"),
    action: () => handleView(item.id)
  })
  actionItems.push({
    label: hasInstantLuckyDrawEditAccessRight ? getLang(lang,"label.MANAGE_PRIZE") : getLang(lang,"label.VIEW_PRIZE"),
    action: () => handleViewPrize(item, "prize")
  })
  actionItems.push({
    label: hasInstantLuckyDrawEditAccessRight ? getLang(lang,"label.MANAGE_WINNER") : getLang(lang,"label.VIEW_WINNER"),
    action: () => handleViewPrize(item, "winner")
  })
  actionItems.push({
    label: getLang(lang,"label.VIEW_PARTICIPANTS"),
    action: () => handleViewParticipants(item.id),
    disabled: disableViewParticipants
  })
  if(hasInstantLuckyDrawEditAccessRight){
    actionItems.push({
      label: getLang(lang, 'label.PUBLISH'),
      action: () => handlePublished(item)
    })
  }
  actionItems.push({
    label: getLang(lang,"label.DOWNLOAD_WINNER_LIST"),
    action: () => handleDownloadWinner(item.id)
  })
  return actionItems
};

export function published ({
  hasInstantLuckyDrawEditAccessRight,
  item,
  disableViewParticipants,
  handleView,
  handleViewParticipants,
  handleViewPrize,
  handleDownloadWinner,
  lang
}) {
  let actionItems = []
  actionItems.push({
    label: hasInstantLuckyDrawEditAccessRight ? getLang(lang,"label.VIEW_EDIT_DETAIL") : getLang(lang,"label.VIEW_DETAIL"),
    action: () => handleView(item.id)
  })
  actionItems.push({
    label: hasInstantLuckyDrawEditAccessRight ? getLang(lang,"label.MANAGE_PRIZE") : getLang(lang,"label.VIEW_PRIZE"),
    action: () => handleViewPrize(item, "prize")
  })
  actionItems.push({
    label: hasInstantLuckyDrawEditAccessRight ? getLang(lang,"label.MANAGE_WINNER") : getLang(lang,"label.VIEW_WINNER"),
    action: () => handleViewPrize(item, "winner")
  })
  actionItems.push({
    label: getLang(lang,"label.VIEW_PARTICIPANTS"),
    action: () => handleViewParticipants(item.id),
    disabled: disableViewParticipants
  })
  actionItems.push({
    label: getLang(lang,"label.DOWNLOAD_WINNER_LIST"),
    action: () => handleDownloadWinner(item.id)
  })
  return actionItems
};
