import React from "react"
import {
  Box,
  makeStyles,
  Typography,
} from "@material-ui/core"
import ScanHistoryTable from "../../table/scanHistoryTable"
import {
  GetApp as GetAppIcon
} from '@material-ui/icons'
import QrScanIcon from "assets/img/qrScanIcon.png"
import { Skeleton } from '@material-ui/lab'
import SuspendWarningIcon from "assets/img/suspendWarningIcon.png"
import ProductTraceabilityPanel from "../productTraceabilityPanel"
import { getLang } from "app/feature/constants"

const API_HOST = process.env.REACT_APP_API_HOST_URL;

const useStyles = makeStyles((theme) => ({
  container: {
    background: "white",
    borderRadius: 4,
    padding: "16px 20px"
  },
  infoSection: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    columnGap: theme.spacing(2),
    rowGap: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
      gridTemplateRows: "1fr auto",
      rowGap: theme.spacing(2)
    }
  },
  gender: {
    width: 25,
    height: 24,
    objectFit: "contain",
    marginRight: 12
  },
  addressBox: {
    border: "2px solid #D0D5DD",
    padding: "5px 10px"
  },
  summaryBox: {
    border: "1px solid #D0D5DD",
    borderRadius: 12,
    padding: 12,
    display: "flex",
    alignItems: "center",
    backgroundColor: "white",
    "& img": {
      height: 58,
      width: 58,
      objectFit: "contain",
      marginRight: 12
    }
  }
}))

export default function ScanHistoryPanelComponent({ 
  id,
  scanHistory,
  isFetching,
  isError,
  handleChangePage,
  handleChangeRowsPerPage,
  page,
  rowsPerPage,
  totalFiltered,
  totalProductScan,
  totalSuspend,
  handleReload,
  paginationOptions,
  tableFilter,
  changeOrder,
  isTraceabilityListFetching,
  token,
  lang,
  handleViewDetail
}) {
  const classes = useStyles()

  return (
    <>
      <Box className={classes.infoSection} mt={2}>
        <Box className={classes.summaryBox}>
          <img src={QrScanIcon} alt="total_product" />
          <Box flex={1}>
            {isTraceabilityListFetching ? (
              <>
                <Skeleton variant="text" height={40} mt={2}/>
                <Skeleton variant="text" height={40} mt={2}/>
              </>
            ) : (
              <>
                <Typography variant="h6">
                  {totalProductScan}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {getLang(lang, "label.TOTAL_PRODUCT")}
                </Typography>
              </>
            )}
          </Box>
        </Box>
        <Box className={classes.summaryBox}>
          <img src={SuspendWarningIcon} alt="total_suspend" />
          <Box flex={1}>
            {isTraceabilityListFetching ? (
              <>
                <Skeleton variant="text" height={40} mt={2}/>
                <Skeleton variant="text" height={40} mt={2}/>
              </>
            ) : (
              <>
                <Typography variant="h6" style={{ color: "#F97066" }}>
                  {totalSuspend}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {getLang(lang, "label.TOTAL_SUSPENDED")}
                </Typography>
              </>
            )}
          </Box>
        </Box>
      </Box>
      <ProductTraceabilityPanel />
      <Box className={classes.container} mt={2}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="body1"><b>{getLang(lang, "label.PRODUCT_SCAN_HISTORY")}</b></Typography>
          <GetAppIcon 
            fontSize="small" 
            style={{
              cursor: "pointer"
            }}
            onClick={() => window.open(`${API_HOST}/consumer/export/v1/consumer/${id}?token=${token}`, "_blank")}
          />
        </Box>
        <Box>
          <ScanHistoryTable 
            rows={scanHistory}
            isFetching={isFetching}
            isError={isError}
            handleReload={handleReload}
            page={page}
            rowsPerPage={rowsPerPage}
            totalFiltered={totalFiltered}
            paginationOptions={paginationOptions}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            tableFilter={tableFilter}
            changeOrder={changeOrder}
            handleViewDetail={handleViewDetail}
          />
        </Box>
      </Box>
    </>
  )
}
