import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function MultipleVisibleIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 22.64">
      <path
        d="M12,5.59A3.28,3.28,0,1,1,8.73,8.86,3.27,3.27,0,0,1,12,5.59Zm0,11.46A13,13,0,0,0,24,8.86a12.89,12.89,0,0,0-24,0A13,13,0,0,0,12,17.05ZM12,3.41A5.46,5.46,0,1,1,6.55,8.86,5.46,5.46,0,0,1,12,3.41Zm0,18.82A13.41,13.41,0,0,1,2.73,18,10.07,10.07,0,0,0,12,23.32,10.07,10.07,0,0,0,21.27,18,13.41,13.41,0,0,1,12,22.23ZM1.36,14.32A15.6,15.6,0,0,0,12,18.68a14.54,14.54,0,0,0,10.36-4.36S20.05,20.73,12,20.73A11.31,11.31,0,0,1,1.36,14.32Z"
        transform="translate(0 -0.68)"
      />
      />
    </SvgIcon>
  );
}
