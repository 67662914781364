import React, { useState, useRef } from "react";
import { makeStyles, ButtonBase, Box, Typography } from "@material-ui/core";
import clsx from "clsx"
import { getLang } from "app/feature/constants";

const useStyle = makeStyles(theme => ({
  root: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "flex-start",
    overflowX: "hidden",
    margin: theme.spacing(2, 0),
    '&::-webkit-scrollbar': {
      width: 0
    },
  },
  rootCenterDisplay: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "center",
    overflowX: "scroll",
    margin: theme.spacing(2, 0),
    '&::-webkit-scrollbar': {
      width: 0,
      display: "none"
    },
  },
  btnWrapper: {
    width: 70,
    display: "flex",
    borderRadius: 8,
    flexFlow: "column nowrap",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: theme.spacing(2),
    boxShadow: "0px 4px 8px 0px #0000001A",
    "&:last-child": {
      marginRight: theme.spacing(2)
    }
  },
  btnMarketplace: {
    width: "100%",
    maxHeight: 70,
    display: "flex",
    overflow: "hidden",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "8px 8px 0px 0px",
    "& img": {
      height: "100%",
      minWidth: "100%",
      minHeight: "100%",
      objectFit: "cover",
      flexShrink: 0
    }
  },
  marketplaceLabel: {
    backgroundColor: "white",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "5px 10px",
    boxSizing: "border-box",
    borderRadius: "0px 0px 8px 8px",
    "& small": {
      fontSize: 12
    }
  },
  placeholder: {
    width: "100%",
    height: 170,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.spacing(2),
    margin: theme.spacing(0, 2)
  }
}));

export default function MarketplaceComponent({ content, lang }) {
  const classes = useStyle();

  const { marketplaces, order } = content;

  const ButtonMarketplace = ({ marketplace: { label, iconSrc, url } }) => (
    <div className={classes.btnWrapper}>
      <ButtonBase className={classes.btnMarketplace}>
        <img src={iconSrc} alt={label} />
      </ButtonBase>
      <Box className={classes.marketplaceLabel}>
        <small>{label}</small>
      </Box>
    </div>
  );

  const [isScrolling, setIsScrolling] = useState(false)
  const [clientX, setClientX] = useState(0)
  const [scrollX, setScrollX] = useState(0)

  const ref = useRef()

  const onMouseDown = e => {
    setIsScrolling(true)
    setClientX(e.clientX)
  };

  const onMouseUp = () => {
    setIsScrolling(false)
  };

  const onMouseMove = e => {
    if (isScrolling) {
      ref.current.scrollLeft = scrollX + e.clientX - clientX;
      setScrollX(ref.current.scrollLeft + e.clientX - clientX)
      setClientX(e.clientX)
    }
  };

  return (
    <Box
      className={clsx(classes.root, {
        [classes.rootCenterDisplay]: order.length <= 4,
      })}
      ref={ref}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onMouseMove={onMouseMove}
    >
      {order.length === 0 && (
        <Box className={classes.placeholder}>
          <Typography
            variant="body2"
            color="primary"
            style={{ fontWeight: "bold" }}
          >
            {getLang(lang,"label.MARKETPLACE_COMPONENT")}
          </Typography>
        </Box>
      )}
      {order.map(id => (
        <ButtonMarketplace key={id} marketplace={marketplaces[id]} />
      ))}
    </Box>
  );
}
