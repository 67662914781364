import React from "react";
import { Paper, makeStyles, Typography, Link } from "@material-ui/core";
import { getLang } from "app/feature/constants";

const style = (theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(4, 5, 8, 5),
    marginTop: theme.spacing(2)
  },
  text: {
    color: "#92C892",
    cursor: "pointer"
  }
});

const useStyles = makeStyles(style);

export default function InfoEmployeePanelComponent({ handleBranch, handleChart, lang}) {
  const classes = useStyles();

  return (
    <Paper elevation={0} className={classes.root}>
      <Typography variant="body2" style={{ color: "#ffffff" }}>
        {getLang(lang, "label.ADD_EMPLOYEE_INTO")}
        <Link className={classes.text} onClick={handleBranch}>
          &nbsp;{getLang(lang, "label.BRANCH_1")}
        </Link>
        {getLang(lang, "label.RECOMMEND_ADDING_THROUGH")}
        <Link className={classes.text} onClick={handleChart}>
          &nbsp;{getLang(lang, "label.ORGANISATION_STRUCTURE")}&nbsp;
        </Link>
        {getLang(lang, "label.PAGE")}
      </Typography>
    </Paper>
  );
}
