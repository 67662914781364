import React from "react"
import { makeStyles, Box, Button, Typography } from '@material-ui/core';
import { Formik } from "formik";
import Input from "components/input/inputTextField";
import ActionSelect from "components/select/actionSelect";
//import FilterSvg from "assets/svg/filter.svg";
import RewardListTable from "../../table/rewardListTable/rewardListTable.container";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles((theme) => ({
  searchContainer: {
    backgroundColor: theme.palette.background.paper,
    display: "grid",
    gridTemplateColumns: "5fr 1fr 1fr",
    columnGap: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1)
    }
  },
  filterMainContainer: {
    backgroundColor: theme.palette.background.paper,
    paddingTop: "12px",
    paddingBottom: "12px",
    paddingRight: "20px",
    paddingLeft: "20px",
    marginBottom: "12px"
  },
  filterFieldContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
    columnGap: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1)
    }
  },
  button: {
    display: "flex",
    alignItems: "center",
    padding: 0,
    minWidth: 0,
    marginLeft: theme.spacing(1)
  },
  inputRootProduct: {
    paddingTop: "8px !important",
    paddingBottom: "7px !important",
    paddingLeft: "12px !important",
    paddingRight: "12px !important",
    fontSize: "0.875rem",
    fontWeight: 500
  },
  paperRoot: {
    width: "217px",
  }
}));

export default function RewardListPanelComponent({
  hasStockflowEditAccessRight,
  hasStockflowAddAccessRight,
  rows,
  rowsPerPage,
  paginationOptions,
  page,
  language,
  totalFiltered,
  isFetching,
  isError,
  isSelected,
  isSelectAll,
  selectedCount,
  selectedUUIDs,
  handleReload,
  handleSearch,
  handleViewDetail,
  handleChangePage,
  handleChangeRowPerPage,
  handleUpdateRewardStatus,
  handleDeleteReward,
  handleAddReward,
  handleSelectReward,
  handleSelectAvailable,
  handleDeselectAll,
  handleSelectAll,
  handleBulkUpdateRewardStatus,
  lang
}) {

  const classes = useStyle();

  return (
    <>
      <Formik
        initialValues={{
          search: "",
        }}
        onSubmit={handleSearch}
        onReset={handleSearch}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <Box p={2} mb={2} className={classes.searchContainer}>
              <Input
                hiddenLabel
                variant="filled"
                size="small"
                placeholder={getLang(lang, 'placeholder.SEARCH')}
                margin="none"
                InputProps={{
                  disableUnderline: true,
                  margin: "none",
                  style: {
                    borderRadius: "4px",
                    backgroundColor: "#eceff0",
                  },
                  classes: { input: classes.inputRootProduct }
                }}
                {...formik.getFieldProps("search")}
              />
              <Button
                variant='text'
                disableElevation
                color="primary"
                size='small'
                onClick={formik.handleReset}
              >
                {getLang(lang, 'label.CLEAR')}
              </Button>
              <Button
                disabled={isFetching}
                variant="contained"
                disableElevation
                color='primary'
                size='small'
                type='submit'
              >
                {getLang(lang, 'label.SEARCH')}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
      <Box style={{ display: "flex", justifyContent: "space-between", paddingBottom: "12px" }}>
        <Box style={{ display: "flex" }}>
          {selectedCount !== 0 ? (
            (isSelectAll && selectedCount === totalFiltered) ? (
              <Typography variant='body2' style={{ color: "#FDB022" }}>{getLang(lang, 'label.SELECTED')} {selectedCount} {selectedCount > 1 ? getLang(lang, 'label.REWARDS') : getLang(lang, 'label.REWARD')} [{getLang(lang, 'label.ALL')}]</Typography>
            ) : (
              <Typography variant='body2' color='primary'>{getLang(lang, 'label.SELECTED')}  {selectedCount} {selectedCount > 1 ? getLang(lang, 'label.REWARDS') : getLang(lang, 'label.REWARD')}</Typography>
            )
          ) : (
            ""
          )}
        </Box>
        <ActionSelect
          label={getLang(lang, 'label.ACTION')}
          dropdownItem={[getLang(lang, 'label.ACTIVATE_SELECTED'), getLang(lang, 'label.DEACTIVATE_SELECTED')]}  //"Bulk Edit"
          handleChange={(value) => handleBulkUpdateRewardStatus(value)}
          disabled={isFetching || !isSelected}
          isFetching={false}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          style={{
            width: "100px",
            borderRadius: "8px",
            backgroundColor: "#ffffff",
            border: "1px solid #D0D5DD"
          }}
        />
      </Box>
      <Box>
        <RewardListTable
          hasStockflowEditAccessRight={hasStockflowEditAccessRight}
          hasStockflowAddAccessRight={hasStockflowAddAccessRight}
          rows={rows}
          language={language}
          rowsPerPage={rowsPerPage}
          paginationOptions={paginationOptions}
          page={page}
          totalFiltered={totalFiltered}
          isFetching={isFetching}
          isError={isError}
          isSelectAll={isSelectAll}
          selectedUUIDs={selectedUUIDs}
          handleReload={handleReload}
          handleViewDetail={handleViewDetail}
          handleChangePage={handleChangePage}
          handleChangeRowPerPage={handleChangeRowPerPage}
          handleUpdateRewardStatus={handleUpdateRewardStatus}
          handleDeleteReward={handleDeleteReward}
          handleCreate={handleAddReward}
          handleSelectReward={handleSelectReward}
          handleSelectAvailable={handleSelectAvailable}
          handleDeselectAll={handleDeselectAll}
          handleSelectAll={handleSelectAll}
        />
      </Box>
    </>
  )
}