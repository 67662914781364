import axios from "axios";

const put = ({ currentPassowrd, password, confirmPassword }) => {
  const body = {
    current_password: currentPassowrd,
    password: password,
    confirm_password: confirmPassword,
  };

  return new Promise((resolve, reject) => {
    axios
      .put("/account/api/v1/password", body)
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

const changePassword = {
  put,
};

export default changePassword;
