import { Box, Button, Menu, MenuItem, withStyles } from "@material-ui/core";
import React, { useState } from "react";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { UnknownFlagIcon } from '../../assets/svg';
import * as FlagIcons from'country-flag-icons/react/3x2';
import { hasFlag } from 'country-flag-icons';
import { iconStyle } from "modules/reporting/styles";

const DropdownButton = withStyles((theme) => ({
    root: {
        padding: "8px",
    },
    label: {
        fontSize: "12px",
        fontFamily: "Roboto",
        fontWeight: 400,
        color: "#344054",
        lineHeight: "14.4px",
        textTransform: "none",
    },
    flag__icon: {
        width: "24.83px",
        height: "18.46px",
        boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
    },
}))(Button);

const CustomButton = withStyles((theme) => ({
    root: {
        padding: "10px",
    },
    label: {
        fontSize: "12px",
        fontFamily: "Roboto",
        fontWeight: 400,
        color: "#344054",
        lineHeight: "14.4px",
        textTransform: "none",
    },
}))(Button);



export default function CustomMenuComponent({
    menuItems = [],
    selected = null,
    tag = "",
    action = () => {},
    countryIcon = false
}) {
    const iconClasses = iconStyle();
  
    const [anchorEl, setAnchorEl] = useState(null);
    // const [selectedOption, setSelectedOption] = useState(menuItems.find(item => item.value === selected)?.key || menuText);
    const setTag = () => {

        let title = menuItems.find(({value}) => value === selected)?.name;
        let value = selected && title ? title : "All"

        return tag + ": " + (value || "All");
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (event, option) => {
        // option = {name: "All", value: "all"}
        action(option.value);
        setAnchorEl(null);
    };

    function getFlagIcon(countryCode) {

        if (!hasFlag(countryCode)) {
            return (
            <Box className={iconClasses.flag__icon}>
                <UnknownFlagIcon/>
            </Box>)
        }

        const FlagIcon = FlagIcons[countryCode];
        return (
            <Box  className={iconClasses.flag__icon}>
                <FlagIcon  title={countryCode} />
            </Box>
        )
    }

    return (
        <Box
            style={{
                padding: "12px",
            }}
        >
            {
                tag !== "test" ? 
                <DropdownButton
                    onClick={handleClick}
                    variant="outlined"
                    endIcon={<ArrowDropDownIcon />}
                    startIcon={countryIcon && selected ? getFlagIcon(selected.toUpperCase()) :  null}
                    // disabled={tag === "Period"} // Remove this line to enable Period selection
                >
                    {countryIcon && selected ? 
                        menuItems.find(({value}) => value === selected).name
                    :
                        setTag()
                    }

                </DropdownButton>
                :
                <CustomButton
                    onClick={handleClick}
                    variant="outlined"
                    disabled={tag === "Period"} // Remove this line to enable Period selection
                >
                    {countryIcon && selected ? 
                        menuItems.find(({value}) => value === selected).name
                    :
                        setTag()
                    }

                </CustomButton>
            }

            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {menuItems.map((option, index) => (
                <MenuItem
                    key={index}
                    selected={option.value === "all"}
                    onClick={(event) => handleMenuItemClick(event, option)}
                >
                    {option.name}
                </MenuItem>
                ))}
            </Menu>
        </Box>
    );
}
