import axios from "axios";

const getExportFileDropdown = async (batchNumber) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `/serialnumber/api/v1/serialnumber/export-file-dropdown/${batchNumber}`,
        {
          batchNumber: batchNumber,
        }
      )
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

export default getExportFileDropdown;
