import React from "react";
import HeaderComponent from "./header.component";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

function HeaderContainer({ handleClose }) {
  const title = useSelector(
    (state) => state.luckyDraw.LuckyDrawDetail.luckyDrawTitle
  );
  const headerType = useSelector((state) => state.luckyDraw.headerType);
  const lang = useSelector(state => state.constant.languages)

  return (
    <HeaderComponent
      handleClose={handleClose}
      title={title}
      headerType={headerType}
      lang={lang}
    />
  );
}

HeaderContainer.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default HeaderContainer;
