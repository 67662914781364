import React from "react";
import InstantLuckyDrawTemplateFormComponent from "./instantLuckydrawTemplateForm.component";
import { useDispatch, useSelector } from "react-redux";
import { ViewDemoDialog } from "../../dialog";
import { openDemoDialog } from "modules/instant-lucky-draw/redux";
import { hideBackdrop, showBackdrop } from "modules/backdrop";
import { addAlert } from "modules/notification";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  setInstantLuckyDrawDetail,
  updateInstantLuckyDrawDetail
} from "modules/instant-lucky-draw/redux";
import { getLang } from "app/feature/constants";

export default function InstantLuckyDrawTemplateFormContainer({
  children,
  type,
  changeBackView,
  handleNext
}) {
  const dispatch = useDispatch();
  const instantLuckyDrawDetail = useSelector(
    (state) => state.instantLuckyDraw.InstantLuckyDrawDialogInfo.info
  );
  const lang = useSelector(state => state.constant.languages)

  const handleDemo = ({ type, template }) => {
    dispatch(openDemoDialog({ type, template }));
  };

  const handleSubmit = async (values) => {
    if (type !== "create") {
      if (type === "edit") {
        dispatch(showBackdrop());

        const info = {
          instantLuckyDrawDetail: {
            ...values,
            game: values.game,
            id: instantLuckyDrawDetail.uuid
          }
        };

        dispatch(updateInstantLuckyDrawDetail(info))
          .then(unwrapResult)
          .then((response) => {
            dispatch(hideBackdrop());
            changeBackView();
          })
          .catch((error) => {
            dispatch(hideBackdrop());
          });
      }
    } else {
      if (!values.game) {
        dispatch(
          addAlert({
            severity: "error",
            message: getLang(lang,"message.error.PLEASE_SELECT_GAME_TEMPLATE")
          })
        );
        return;
      }

      const updateInfo = {
        ...instantLuckyDrawDetail,
        game: values.game
      };

      await dispatch(setInstantLuckyDrawDetail(updateInfo));
      handleNext();
    }
  };

  return (
    <>
      <InstantLuckyDrawTemplateFormComponent
        children={children}
        type={type}
        instantLuckyDrawDetail={instantLuckyDrawDetail}
        handleDemo={handleDemo}
        handleSubmit={handleSubmit}
        lang={lang}
      />
      <ViewDemoDialog />
    </>
  );
}
