import React from "react";
import { Typography, Box } from "@material-ui/core";
import { getLang } from "app/feature/constants";
import {
  SERIAL_NUMBER_BATCH_STATUS_FAILED,
  SERIAL_NUMBER_BATCH_STATUS_GENERATED,
} from "modules/serial-number/constants";

export default function GenerateStatusProgressComponent({
  lang,
  progress,
  status,
}) {
  return (
    <Box>
      <Typography
        variant="caption"
        style={{
          color:
            status === SERIAL_NUMBER_BATCH_STATUS_FAILED
              ? "#FDB022"
              : status === SERIAL_NUMBER_BATCH_STATUS_GENERATED
              ? "#32D583"
              : "#989898",
          textTransform: "capitalize",
        }}
      >
        {status === SERIAL_NUMBER_BATCH_STATUS_FAILED
          ? getLang(lang, "label.WARNING")
          : status === SERIAL_NUMBER_BATCH_STATUS_GENERATED
          ? getLang(lang, "label.GENERATED")
          : `${progress}%`}
      </Typography>
    </Box>
  );
}
