import { createSlice } from "@reduxjs/toolkit";
import { SIGNUP } from "../../constants";
import {
  checkResendVerificationEmailResendTimeLeft,
  isValidPartner,
  isValidReseller,
  resendEmailVerification
} from "../action";

const initialState = {
  isAccountCreated: false,
  email: "",
  id: "",
  preselectPlan: null,
  employee: {
    email: "",
    token: "",
    id: "",
    timezone: ""
  },
  resendEmailResendTimeLeft: 60,
  isValidRegistrationUrl: true,
};

const signupSlice = createSlice({
  name: SIGNUP,
  initialState,
  reducers: {
    setSignupSuccessData(state, action) {
      const { email, id } = action.payload;
      state.isAccountCreated = true;
      state.email = email;
      state.id = id;
    },
    initiateWithPreselectPlan(state, action) {
      const { plan, type } = action.payload;
      state.preselectPlan = { name: plan, type: parseInt(type) };
    },
    setEmail(state, action) {
      state.employee.email = action.payload
    },
    setTimezone(state, action) {
      state.employee.timezone = action.payload
    },
    setEmployee(state, action) {
      state.employee.id = action.payload.id;
      state.employee.token = action.payload.token;
    },
    resetSignup(state, action) {
      state.isAccountCreated = initialState.isAccountCreated;
      state.email = initialState.email;
      state.id = initialState.id;
      state.timezone = initialState.timezone;
    }
  },
  extraReducers: {
    [checkResendVerificationEmailResendTimeLeft.fulfilled]: (state, action) => {
      state.resendEmailResendTimeLeft = action.payload.data.time_left;
    },
    [resendEmailVerification.fulfilled]: (state, action) => {
      state.resendEmailResendTimeLeft = 60;
    },
    [isValidReseller.rejected]: (state, action) => {
      state.isValidRegistrationUrl = false;
    },
    [isValidPartner.rejected]: (state, action) => {
      state.isValidRegistrationUrl = false;
    },
  }
});

export const {
  initiateWithPreselectPlan,
  setSignupSuccessData,
  setEmail,
  setEmployee,
  resetSignup,
  setTimezone
} = signupSlice.actions;

export default signupSlice.reducer;
