import { MARKETPLACE_VIEW } from "lib/constants/accessRights";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import MainPageComponent from "./billing.page";
import LoadingComponent from "components/loading";
import { getLang } from "app/feature/constants";

export default function MainPageContainer() {
  const history = useHistory()
  const hasMarketplaceViewAccessRight = useSelector(state => state.profile.accessRights.includes(MARKETPLACE_VIEW));
  const [activeIndex, setActiveIndex] = useState(0);
  const lang = useSelector(state => state.constant.languages)
  
  useEffect(() => {
    if(!hasMarketplaceViewAccessRight){
      history.push('/admin/dashboard')
    }
  }, [history, hasMarketplaceViewAccessRight])
  
  if (!hasMarketplaceViewAccessRight) return <LoadingComponent />;

  const tabLabels = [
    getLang(lang, 'label.BILLING_OVERVIEW')
  ]

  const handleChange = (event, index) => {
    setActiveIndex(index);
  };
  return (
    <MainPageComponent
      handleChange={handleChange}
      activeIndex={activeIndex}
      tabLabels={tabLabels}
      lang={lang}
    />
  );

}
