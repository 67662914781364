import React from "react";
import { connect } from "react-redux";
import BranchTableComponent from "./branchTable.component";
import { fetchBranchList, selectNoCompanyBranchList } from "modules/branch/redux";
import { selectLoading } from "modules/notification";
import { useSelector } from "react-redux";

function BranchTableContainer({
  hasBranchAddAccessRight,
  hasBranchEditAccessRight,
  hasBranchActivateOrDeactivateAccessRight,
  branchList,
  isLoading,
  page,
  search,
  totalRecords,
  rowsPerPage,
  handlePageChange,
  handleRowPerPageChange,
  openCreateModal,
  handleEditClick,
  handleStatusChange,
  handleReload,
  paginationOptions,
  lang
}) {
  const isLoadingBranchError = useSelector(state => state.branch.isLoadingBranchError)

  return (
    <BranchTableComponent
      hasBranchAddAccessRight={hasBranchAddAccessRight}
      hasBranchEditAccessRight={hasBranchEditAccessRight}
      hasBranchActivateOrDeactivateAccessRight={hasBranchActivateOrDeactivateAccessRight}
      isLoading={isLoading}
      branchList={branchList}
      page={page}
      search={search}
      totalRecords={totalRecords}
      rowsPerPage={rowsPerPage}
      handlePageChange={handlePageChange}
      handleRowPerPageChange={handleRowPerPageChange}
      openCreateModal={openCreateModal}
      handleEditClick={handleEditClick}
      handleStatusChange={handleStatusChange}
      handleReload={handleReload}
      isLoadingBranchError={isLoadingBranchError}
      paginationOptions={paginationOptions}
      lang={lang}
    />
  );
}

const mapStateToProps = state => ({
  branchList: selectNoCompanyBranchList(state),
  isLoading: selectLoading(state, fetchBranchList.typePrefix)
});

export default connect(mapStateToProps, null)(BranchTableContainer);
