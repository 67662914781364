import axios from "axios";

const url = "/serialnumber/api/v1/product/brand-models/list";

export function getBrandModelListing({
  length,
  start,
  column,
  order,
  search,
  status,
}) {
  return new Promise((resolve, reject) => {
    axios.get(url, {
      params: {
        length,
        start,
        column,
        order,
        search,
        status
      }
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data))
  })
}