import React, { useRef } from 'react';
import {
  makeStyles,
  Typography,
  Popover,
  Box,
  Divider,
  InputAdornment,
  Button,
  MenuItem,
  Checkbox,
  IconButton,
  MenuList
} from "@material-ui/core";
import {
  Search as SearchIcon
} from "@material-ui/icons";
import { Skeleton } from '@material-ui/lab';
import InputTextField from 'components/input/inputTextField';
import ClearIcon from '@material-ui/icons/Clear';
import { getLang } from 'app/feature/constants';

const useStyle = makeStyles((theme) => ({
  button: {
    padding: 0,
    minWidth: 0,
  },
  perfectScrollBar: {
    height: 200
  },
  list: {
    paddingTop: 3,
    paddingBottom: 3,
    display: "flex",
    alignItems: 'center',
  },
  name: {
    whiteSpace: "normal",
    overflow: "hidden",
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "line-clamp": 2,
    "-webkit-box-orient": "vertical",
  },
}));

export default function CategoryPanelComponent({
  open,
  anchorEl,
  handleClose,
  uuid,
  style,
  searchable,
  handleSearch,
  placeholder,
  items,
  isFetching,
  emptyMessage,
  handleChange,
  anchorOrigin,
  transformOrigin,
  PaperProps,
  value,
  lang, 
  selectAll,
  handleAll
}) {
  const classes = useStyle();
  const textRef = useRef();
  const handleClearClick = () => {
    textRef.current.value = '';
  }
  return (
    <Popover
      uuid={uuid}
      open={open}
      style={style}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      elevation={2}
      PaperProps={{
        style: { minWidth: "20em", maxWidth: 400 },
        ...PaperProps
      }}
    >
      {searchable && (
        <Box>
          <InputTextField
            variant="filled"
            size="small"
            fullWidth
            inputRef={textRef}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" style={{ paddingRight: 6 }}>
                  <Button
                    onClick={() => handleSearch(textRef.current.value)}
                    className={classes.button}
                  >
                    <IconButton
                      onClick={handleClearClick}
                    >
                      <ClearIcon />
                    </IconButton>
                    <SearchIcon />
                  </Button>
                </InputAdornment>
              ),
              style: {
                backgroundColor: "#fff",
                paddingRight: 0
              }
            }}
            inputProps={{
              onChange: () => {
                handleSearch(textRef.current.value)
              },
              style: {
                padding: 8
              }
            }}
            placeholder={placeholder}
          />
        </Box>
      )}
      <Divider />
      <MenuList
        uuid="menu-list-grow"
        onClick={() => {
          handleAll();
        }}
        style={{ padding: 0 }}
      >
        <MenuItem style={{ padding: 0 }}>
          <Checkbox checked={selectAll} />
          <Typography
            variant="inherit"
            noWrap
            style={{ paddingLeft: "8px" }}
          >
            {getLang(lang, "label.ALL")}
          </Typography>
        </MenuItem>
      </MenuList>
      <Divider />
      <Box style={{ maxHeight: "250px", overflow: "auto" }}>
        {isFetching ? (
          <>
            <Skeleton variant="text" animation="wave" height={25} />
            <Skeleton variant="text" animation="wave" height={25} />
            <Skeleton variant="text" animation="wave" height={25} />
            <Skeleton variant="text" animation="wave" height={25} />
          </>
        ) : items.length > 0 ? (
          <Box>
            {items.map((item, index) => {
              return (
                <Box key={index}>
                  <MenuItem
                    style={{paddingTop: "0px", paddingBottom: "0px"}}
                    onClick={() => handleChange(item)}
                  >
                     <Checkbox
                        checked={
                          value.find(
                            (v) => v.uuid === item.uuid
                          )
                            ? true
                            : false
                        }
                      />
                    <Box className={classes.list}>
                      <Box className={classes.name}>
                        {item.name}
                      </Box>
                    </Box>
                  </MenuItem>
                </Box>
              )
            })}
          </Box>
        ) : (
          <Box py={9} style={{ textAlign: "center" }}>
            <Typography variant='body1'>{emptyMessage}</Typography>
          </Box>
        )}
      </Box>
    </Popover>
  )
}