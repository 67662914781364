import createPresignedUrl from "./createPresignedUrl";
import upload from "./upload";
import getUrl from "./getUrl";
import uploadJsonFile from "./uploadJsonFile";

const s3Api = {
  createPresignedUrl,
  upload,
  getUrl,
  uploadJsonFile
};

export default s3Api;
