import React from "react";
import { makeStyles, Typography, Button, Box, darken } from "@material-ui/core";
import Logo from "assets/img/logo-smartkood.png";
import { getLang } from "app/feature/constants";

const style = theme => ({
  root: {
    height: "100vh",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexFlow: "column wrap",
    "& svg": {
      marginBottom: theme.spacing(3)
    }
  },
  buttonPrimary: {
    backgroundColor: "#ff5000",
    color: "#fff",
    "&:hover": {
      backgroundColor: darken("#ff5000", 0.3)
    }
  },
  logo: {
    height: 30
  }
});

const useStyle = makeStyles(style);

export default function Index({
  error,
  errorMessage,
  handleLogout,
  handleReload,
  lang
}) {
  const classes = useStyle();

  return (
    <div className={classes.root}>
      <img
        className={classes.logo}
        src={Logo}
        alt="logo"
      />

      <Typography variant="subtitle1">
        {error ? errorMessage : getLang(lang, 'label.LOADING')}
      </Typography>
      <br />
      {error ? (
        <Box display="flex">
          <Button
            onClick={handleReload}
            disableElevation
            className={classes.buttonPrimary}
            variant="contained"
          >
            {getLang(lang, "label.TRY_AGAIN")}
          </Button>
          &ensp;
          <Button onClick={handleLogout} disableElevation variant="text">
            {getLang(lang, "label.LOGOUT")}
          </Button>
        </Box>
      ) : null}
    </div>
  );
}
