import React, { useEffect, useState, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { selectLoading } from "modules/notification"
import ScanHistoryPanelComponent from "./scanHistoryPanel.component"
import { generatePaginationOptions, updateUrlQueryParam } from "lib/helper"
import { fetchAllScanTrack, fetchConsumerScanHistoryListing } from "modules/consumer/redux/action/consumer.action"
import { useHistory, useRouteMatch } from "react-router-dom"

function ScanHistoryPanelContainer() {
  const dispatch = useDispatch()
  const match = useRouteMatch()
  const history = useHistory()
  const id = match.params.consumerId

  const token = useSelector(state => state.session.accessToken)
  const scanHistory = useSelector(state => state.consumer.scanHistory.data)
  const totalRecords = useSelector(state => state.consumer.scanHistory.totalRecords)
  const totalProductScan = useSelector(state => state.consumer.traceabilityList.totalProductScan)
  const totalSuspend = useSelector(state => state.consumer.traceabilityList.totalSuspend)
  const isFetching = useSelector(state => selectLoading(state, fetchConsumerScanHistoryListing.typePrefix))
  const isTraceabilityListFetching = useSelector(state => selectLoading(state, fetchAllScanTrack.typePrefix))
  const isError = useSelector(state => state.consumer.isLoadingScanError)
  const lang = useSelector(state => state.constant.languages)
  const [paginationOptions, setPaginationOptions] = useState([])
  const [isFilterUpdated, setIsFilterUpdated] = useState(false)
  const [tableFilter, setTableFilter] = useState({
    page: 0,
    rowsPerPage: 10,
    order: "desc",
    column: "created_at" // serial_number|product_name|created_at|location
  })

  const query = new URLSearchParams(window.location.search)
  const tableFilterInitialRef = useRef(true)

  useEffect(() => {
    const isFilter = query.has("is_scan_filter")
    let filter = sessionStorage.getItem(`consumer_scan_filter_${id}`);

    let temp = {...tableFilter}
    if (isFilter && filter) {
      temp = JSON.parse(filter)
      setIsFilterUpdated(true)
    }

    if (isFilter) {
      updateUrlQueryParam(null, ["is_scan_filter"])
    }
    sessionStorage.removeItem(`consumer_scan_filter_${id}`)

    setTableFilter(temp)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!tableFilterInitialRef.current && id) {
      getData()
    }
    tableFilterInitialRef.current = false
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableFilter, id])

  useEffect(() => {
    setPaginationOptions(generatePaginationOptions(totalRecords, [10, 25, 50, 100, 200, 400]))
  }, [totalRecords])

  const getData = () => {
    dispatch(fetchConsumerScanHistoryListing({
      id,
      length: tableFilter.rowsPerPage,
      start: tableFilter.rowsPerPage * tableFilter.page,
      order: tableFilter.order,
      column: tableFilter.column,
    }))
  }

  const handleChangePage = (event, newPage) => {
    setTableFilter({
      ...tableFilter,
      page: newPage,
      rowsPerPage: tableFilter.rowsPerPage,
    })
    setIsFilterUpdated(true)
  }

  const handleChangeRowsPerPage = event => {
    setTableFilter({
      ...tableFilter,
      page: 0,
      rowsPerPage: event.target.value,
    })
    setIsFilterUpdated(true)
  }

  const changeOrder = (column, order) => {
    setTableFilter({
      ...tableFilter,
      order,
      column
    })
    setIsFilterUpdated(true)
  }

  const handleViewDetail = (url) => {
    if (isFilterUpdated) {
      updateUrlQueryParam({ is_scan_filter: true })
      sessionStorage.setItem(`consumer_scan_filter_${id}`, JSON.stringify(tableFilter));
    }
    history.push(url)
  }

  return (
    <>
      <ScanHistoryPanelComponent
        id={id}
        scanHistory={scanHistory}
        isFetching={isFetching}
        isError={isError}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        page={tableFilter.page}
        rowsPerPage={tableFilter.rowsPerPage}
        totalFiltered={totalRecords}
        totalProductScan={totalProductScan}
        totalSuspend={totalSuspend}
        handleReload={getData}
        paginationOptions={paginationOptions}
        tableFilter={tableFilter}
        changeOrder={changeOrder}
        isTraceabilityListFetching={isTraceabilityListFetching}
        token={token}
        lang={lang}
        handleViewDetail={handleViewDetail}
      />
    </>
  )
}

export default ScanHistoryPanelContainer
