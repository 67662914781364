import { login, parseHash } from "../action";
import { setSession, setGrantAccess } from "modules/admin";
import { addAlert, setLoading } from "modules/notification";
import { setSignupSuccessData } from "../slice/signup.slice";
import { getApiLang } from "app/feature/constants";

const sessionLoginMiddleware = ({ getState, dispatch }) => next => action => {
  next(action);

  const { type, payload } = action;

  if (type.includes(login.typePrefix)) {
    switch (type) {
      case login.pending.type:
        next(setLoading({ id: login.typePrefix, state: true }));
        break;

      case login.fulfilled.type:
        localStorage.setItem('session_id', payload.session_id)
        localStorage.setItem('session_expiration', payload.session_expiration)
        dispatch(setSession(payload))
        next(setLoading({ id: login.typePrefix, state: false }));
        break;

      case login.rejected.type:
        const lang = getState().constant.languages;
        next(setLoading({ id: login.typePrefix, state: false }));
        next(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code)
          })
        );

        break;

      default:
        break;
    }
  }
};

const parseHashMiddleware = ({ getState }) => next => action => {
  next(action);

  const { type, payload } = action;

  switch (type) {
    case parseHash.pending.type:
      next(setLoading({ id: parseHash.typePrefix, state: true }));
      break;

    case parseHash.fulfilled.type:
      const { emailVerified, email, sub } = payload.idTokenPayload;

      if (!emailVerified) next(setSignupSuccessData({ email, id: sub }));

      next(setSession(payload));
      next(setLoading({ id: parseHash.typePrefix, state: false }));

      break;

    case parseHash.rejected.type:
      const lang = getState().constant.languages;
      next(setGrantAccess(false));
      next(setLoading({ id: parseHash.typePrefix, state: false }));
      next(
        addAlert({
          severity: "error",
          message: getApiLang(lang, payload.errorDescription.replace(/\s/g, ''))
        })
      );
      break;

    default:
      break;
  }
};

export default [sessionLoginMiddleware, parseHashMiddleware];
