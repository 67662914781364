import React, { useState } from "react";
import SingleColorPickerPanelComponent from "./singleColorPickerPanel.component";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

function SingleColorPickerPanelContainer({ label, color, handleChange, disabled }) {
  const [isOpen, setIsOpen] = useState(false);
  const [bufferColor, setBufferColor] = useState(color);
  const lang = useSelector(state => state.constant.languages)

  const handleToggle = () => {
    setIsOpen(prev => !prev);
  };

  const handleColorChange = (color, event) => {
    setBufferColor(color.hex);
  };

  const handleSave = () => {
    setIsOpen(false);
    handleChange(bufferColor);
  };

  const handleCancel = () => {
    setBufferColor(color);
    setIsOpen(false);
  };

  return (
    <SingleColorPickerPanelComponent
      disabled={disabled}
      isOpen={isOpen}
      handleToggle={handleToggle}
      label={label}
      bufferColor={bufferColor}
      handleColorChange={handleColorChange}
      handleSave={handleSave}
      handleCancel={handleCancel}
      lang={lang}
    />
  );
}

SingleColorPickerPanelContainer.propTypes = {
  label: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired
};

export default SingleColorPickerPanelContainer;
