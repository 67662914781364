import { createAsyncThunk } from "@reduxjs/toolkit";
import notificationApi from "app/api/notification";

const NOTIFICATION = "notification";

export const getUnprocessClaimNotification = createAsyncThunk(
  `${NOTIFICATION}/getUnprocessClaimNotification`,
  async (_, thunkApi) => {
    return notificationApi
      .getUnprocessClaimNotification()
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);
