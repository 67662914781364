import React, { useState, useEffect, useRef } from "react"
import PropTypes from 'prop-types';
import AttributeMultiSelectPanel from './attributeMultiSelectPanel.component';
import AttributeMultiSelectComponent from './attributeMultiSelect.component';
import { useSelector } from "react-redux";
import { getLang } from "app/feature/constants";

function AttributeMultiSelectContainer({
  placeholder = null,
  handleChange,
  value,
  disabled,
  styles,
  textStyle,
  dropdownItem,
  isLoading,
  searchPlaceholder,
  handleSearch,
  searchable
}) {
  const [open, setOpen] = useState(false);
  const [isOpenList] = useState(false);
  const anchorRef = useRef(null);
  const prevOpen = useRef(open)
  const prevOpenList = useRef(isOpenList);
  // const [selectAll, setSelectAll] = useState(false);
  const lang = useSelector(state => state.constant.languages);

  const handleClose = () => {
    setOpen(false)
  };

  const handleClick = () => {
    setOpen(true)
  }

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    if (prevOpenList.current === true && isOpenList === false) {
      anchorRef.current.focus();
    }

    prevOpenList.current = isOpenList;
  }, [isOpenList]);

  const handleSelectAttribute = (field, checked) => {
    let temp = [...value];
    let index = temp.findIndex((v) => v.id === field.id)
    if (checked) {
      let optionTemp = []
      field.options.map((option) => {
        optionTemp.push({
          id: option.id,
          value: option.value
        })
        return null;
      });
      if(index !== -1){
        temp[index].options = optionTemp;
        temp[index].isSelected = (field.id !== "-1")
      }
      else {
        temp.push({
          id: field.id,
          name: field.name,
          options: optionTemp,
          isSelected: (field.id !== "-1")
        });
      }
    } else {
      temp.splice(index, 1)
    }

    handleChange(temp)
  }

  const handleSelectOption = (field, optionsfield, checked) => {
    let temp = [...value];
    let optionsTemp = [];
    let valAttrIndex = temp.findIndex((a) => a.id === field.id);
    if (temp.length > 0 && valAttrIndex !== -1) {
      optionsTemp = temp[valAttrIndex].options;
      if(checked) {
        optionsTemp.push({
          id: optionsfield.id,
          value: optionsfield.value,
        });
        temp[valAttrIndex].options = optionsTemp;
        temp[valAttrIndex].isSelected = (field.id !== "-1" && optionsTemp.length === field.options.length);
      }
      else {
        let index = optionsTemp.findIndex((o) => o.id === optionsfield.id);
        optionsTemp.splice(index, 1);
        if (optionsTemp.length === 0){
          temp.splice(valAttrIndex, 1);
        }
        else {
          temp[valAttrIndex].options = optionsTemp;
          temp[valAttrIndex].isSelected = false;
        }
      }
    }
    else {
      if (checked) {
        optionsTemp.push({
          id: optionsfield.id,
          value: optionsfield.value,
        });
        temp.push({
          id: field.id,
          name: field.name,
          options: optionsTemp,
          isSelected: (optionsTemp.length === field.options.length)
        });
      }
    }
    handleChange(temp)
  }

  return (
    <>
      <AttributeMultiSelectComponent
        anchorRef={anchorRef}
        handleClick={handleClick}
        value={value}
        styles={styles}
        placeholder={placeholder ?? getLang(lang,"placeholder.SEARCH_ATTRIBUTE")}
        disabled={disabled}
        textStyle={textStyle}
      />
      <AttributeMultiSelectPanel
        value={value}
        anchorRef={anchorRef}
        open={open}
        isOpenList={isOpenList}
        handleClose={handleClose}
        isFetching={isLoading}
        dropdownItem={dropdownItem}
        handleSelectAttribute={handleSelectAttribute}
        handleSelectOptions={handleSelectOption}
        placeholder={searchPlaceholder}
        searchable={searchable}
        handleAttributeSearch={handleSearch}
        lang={lang}
      />
    </>
  )
}

AttributeMultiSelectContainer.propTypes = {
  placeholder: PropTypes.string
};

export default AttributeMultiSelectContainer