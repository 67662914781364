import React, { useState, useRef, useEffect } from "react";
import PrizeSelectComponent from "./prizeSelect.component";
import PrizePanelComponent from "./prizeSelectPanel.component";
import PropTypes from "prop-types";
import { selectLoading } from "modules/notification";
import { useDispatch, useSelector } from "react-redux";
import { getProductDropdown } from "modules/product-mgmt/redux/action/products.action";

function PrizeSelectContainer({ placeholder, handleChange, value, disabled }) {
  const [open, setOpen] = useState(false);
  const [isOpenList] = useState(false);
  const anchorRef = useRef(null);
  const prevOpen = useRef(open);
  const prevOpenList = useRef(isOpenList);

  const dispatch = useDispatch();
  const productDropdown = useSelector(
    (state) => state.products.productsDropdown
  );
  const isProductDropdownLoading = useSelector((state) =>
    selectLoading(state, getProductDropdown.typePrefix)
  );
  const lang = useSelector(state => state.constant.languages)

  useEffect(() => {
    dispatch(getProductDropdown());
  }, [dispatch]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    if (prevOpenList.current === true && isOpenList === false) {
      anchorRef.current.focus();
    }

    prevOpenList.current = isOpenList;
  }, [isOpenList]);

  function handleListKeyDown(v) {
    const temp = value.slice();
    const index = temp.findIndex((t) => t.id === v.id);
    if (index === -1) {
      temp.push(v);
    } else {
      temp.splice(index, 1);
    }
    handleChange(temp);
  }

  return (
    <>
      <PrizeSelectComponent
        anchorRef={anchorRef}
        handleClick={handleClick}
        value={value}
        placeholder={placeholder}
        disabled={disabled}
      />
      <PrizePanelComponent
        value={value}
        anchorRef={anchorRef}
        open={open}
        isOpenList={isOpenList}
        handleClose={handleClose}
        isFetching={isProductDropdownLoading}
        handleListKeyDown={handleListKeyDown}
        productDropdown={productDropdown}
        lang={lang}
      />
    </>
  );
}
PrizeSelectContainer.defaultProps = {
  placeholder: "Select prize(s)"
};

PrizeSelectContainer.propTypes = {
  placeholder: PropTypes.string
};

export default PrizeSelectContainer;
