import { createSelector } from "@reduxjs/toolkit";

export const selectInvisibleComponentIndexes = createSelector(
  state => state.productPage.columns.compIds,
  state => state.productPage.components,
  (compIds, components) => {
    const invisibleComponentIndexes = [];

    compIds.forEach((id, index) => {
      const { isVisible } = components[id];
      if (!isVisible) {
        invisibleComponentIndexes.push(index);
      }
    });

    return invisibleComponentIndexes;
  }
);

export const selectBranchName = createSelector(
  state => state.products.branchDropdown,
  state => state.productPage.nodes,
  (allNodes, nodes) => {
    return allNodes
      .filter(({ node_id }) => nodes.includes(node_id))
      .map(node => node.name)
      .join(", ");
  }
);
