import React from "react";
import {
  makeStyles,
  Box,
  IconButton,
  Dialog,
  Typography,
  Backdrop,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { CloseRounded as CloseRoundedIcon } from "@material-ui/icons";
import { getLang } from "app/feature/constants";
import "react-image-lightbox/style.css";
import { Formik } from "formik";
import CountrySelect from "components/select/countrySelect";
import Input from "components/input/inputTextField";
import InputTextField from "components/input/inputTextField";
import countries from "lib/constants/countries";

const useStyle = makeStyles((theme) => ({
  header: {
    padding: "12px 16px",
    borderBottom: "1px solid #D0D5DD",
  },
  container: {
    backgroundColor: "#ebedeec2",
    overflowY: "auto",
    padding: "20px",
    maxHeight: "70vh",
  },
}));

export default function RequestTransferAddressCreateDialogComponent({
  isOpen,
  handleClose,
  lang,
  handleSubmit,
  isSubmitting,
  branch,
  selectedEditAddr,
}) {
  const classes = useStyle();

  return (
    <Dialog
      maxWidth="sm"
      open={isOpen}
      onClose={() => {}}
      aria-labelledby="modal-scan-detail"
      aria-describedby="modal-scan-detail"
      BackdropComponent={Backdrop}
      fullWidth
      PaperProps={{
        style: {
          overflowY: "hidden",
        },
      }}
    >
      <Box
        className={classes.header}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="body1">
          <b>{getLang(lang, "label.ADD_ADDRESS")}</b>
        </Typography>
        <IconButton
          onClick={handleClose}
          edge="end"
          size="small"
          aria-label="close dialog"
          // disabled={isLoading}
        >
          <CloseRoundedIcon color="primary" />
        </IconButton>
      </Box>
      <Formik
        initialValues={{
          phoneCode: selectedEditAddr
            ? countries.find(
                ({ phone }) => phone === selectedEditAddr.phone_code
              )
            : null,
          phoneNumber: selectedEditAddr ? selectedEditAddr.phone_number : "",
          addressLine1: selectedEditAddr ? selectedEditAddr.address_line_1 : "",
          addressLine2: selectedEditAddr ? selectedEditAddr.address_line_2 : "",
          postalCode: selectedEditAddr ? selectedEditAddr.postal_code : "",
          city: selectedEditAddr ? selectedEditAddr.city : "",
          state: selectedEditAddr ? selectedEditAddr.state : "",
          country: selectedEditAddr
            ? countries.find(({ label }) => label === selectedEditAddr.country)
            : "",
        }}
        enableReinitialize
      >
        {(formik) => (
          <>
            <Box className={classes.container}>
              <Box>
                <Typography variant="body1" display="inline">
                  <b>{getLang(lang, "label.BRANCH")}: </b>
                </Typography>
                <Typography
                  variant="body1"
                  display="inline"
                  style={{ marginLeft: 8 }}
                >
                  {branch.name}
                </Typography>
              </Box>
              <Box mt={3}>
                <Typography variant="body1">
                  <b>{getLang(lang, "label.CONTACT")}</b>
                </Typography>
              </Box>
              <Box mt={2}>
                <Typography>
                  {getLang(lang, "placeholder.MOBILE_NUMBER")}{" "}
                  <span style={{ color: "#F97066" }}>*</span>
                </Typography>
                <Box display="flex" alignItems="center">
                  <CountrySelect
                    type="phone"
                    textFieldProps={{
                      required: true,
                      style: { width: 150 },
                      variant: "filled",
                      size: "small",
                      margin: "dense",
                      inputPropStyle: {
                        backgroundColor: "white",
                        border: "1px solid #D0D5DD",
                        borderRadius: 8,
                        height: 43,
                        padding: 0,
                      },
                    }}
                    formProps={{
                      ...formik.getFieldProps("phoneCode"),
                      value: formik.values.phoneCode,
                      onChange: (e, newValue) => {
                        formik.setFieldValue("phoneCode", newValue);
                      },
                      disabled: isSubmitting,
                    }}
                  />
                  <InputTextField
                    onKeyPress={(e) => {
                      if (!/[0-9]/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    hiddenLabel
                    variant="filled"
                    size="small"
                    fullWidth
                    margin="dense"
                    disabled={isSubmitting}
                    InputProps={{
                      disableUnderline: true,
                      margin: "none",
                      style: {
                        borderRadius: 8,
                        backgroundColor: "white",
                        border: "1px solid #D0D5DD",
                        minWidth: "50px",
                        height: 43,
                      },
                      classes: { input: classes.inputRootID },
                    }}
                    placeholder={getLang(lang, "placeholder.CONTACT_NUMBER")}
                    {...formik.getFieldProps("phoneNumber")}
                  />
                </Box>
              </Box>
              <Box mt={3}>
                <Typography variant="body1">
                  <b>{getLang(lang, "label.ADDRESS")}</b>
                </Typography>
              </Box>
              <Box mt={2}>
                <Typography>
                  {getLang(lang, "label.ADDRESS_LINE_1")}{" "}
                  <span style={{ color: "#F97066" }}>*</span>
                </Typography>
                <Input
                  hiddenLabel
                  variant="filled"
                  size="small"
                  placeholder={getLang(lang, "label.ADDRESS_LINE_1")}
                  margin="none"
                  style={{ width: "100%", marginTop: 4 }}
                  InputProps={{
                    disableUnderline: true,
                    margin: "none",
                    style: {
                      borderRadius: "8px",
                      backgroundColor: "white",
                      border: "1px solid #D0D5DD",
                      minWidth: "50px",
                      height: "100%",
                    },
                    classes: { input: classes.inputRootID },
                  }}
                  disabled={isSubmitting}
                  {...formik.getFieldProps("addressLine1")}
                />
              </Box>
              <Box mt={2}>
                <Typography>{getLang(lang, "label.ADDRESS_LINE_2")}</Typography>
                <Input
                  hiddenLabel
                  variant="filled"
                  size="small"
                  placeholder={getLang(lang, "label.ADDRESS_LINE_2")}
                  margin="none"
                  style={{ width: "100%", marginTop: 4 }}
                  InputProps={{
                    disableUnderline: true,
                    margin: "none",
                    style: {
                      borderRadius: "8px",
                      backgroundColor: "white",
                      border: "1px solid #D0D5DD",
                      minWidth: "50px",
                      height: "100%",
                    },
                    classes: { input: classes.inputRootID },
                  }}
                  disabled={isSubmitting}
                  {...formik.getFieldProps("addressLine2")}
                />
              </Box>
              <Box mt={2}>
                <Typography>
                  {getLang(lang, "label.POSTAL_CODE")}{" "}
                  <span style={{ color: "#F97066" }}>*</span>
                </Typography>
                <Input
                  hiddenLabel
                  variant="filled"
                  size="small"
                  placeholder={getLang(lang, "placeholder.POSTAL_CODE")}
                  margin="none"
                  style={{ width: "100%", marginTop: 4 }}
                  InputProps={{
                    disableUnderline: true,
                    margin: "none",
                    style: {
                      borderRadius: "8px",
                      backgroundColor: "white",
                      border: "1px solid #D0D5DD",
                      minWidth: "50px",
                      height: "100%",
                    },
                    classes: { input: classes.inputRootID },
                  }}
                  disabled={isSubmitting}
                  {...formik.getFieldProps("postalCode")}
                />
              </Box>
              <Box mt={2}>
                <Typography>
                  {getLang(lang, "label.CITY")}{" "}
                  <span style={{ color: "#F97066" }}>*</span>
                </Typography>
                <Input
                  hiddenLabel
                  variant="filled"
                  size="small"
                  placeholder={getLang(lang, "placeholder.CITY")}
                  margin="none"
                  style={{ width: "100%", marginTop: 4 }}
                  InputProps={{
                    disableUnderline: true,
                    margin: "none",
                    style: {
                      borderRadius: "8px",
                      backgroundColor: "white",
                      border: "1px solid #D0D5DD",
                      minWidth: "50px",
                      height: "100%",
                    },
                    classes: { input: classes.inputRootID },
                  }}
                  disabled={isSubmitting}
                  {...formik.getFieldProps("city")}
                />
              </Box>
              <Box mt={2}>
                <Typography>
                  {getLang(lang, "label.STATE")}{" "}
                  <span style={{ color: "#F97066" }}>*</span>
                </Typography>
                <Input
                  hiddenLabel
                  variant="filled"
                  size="small"
                  placeholder={getLang(lang, "placeholder.STATE")}
                  margin="none"
                  style={{ width: "100%", marginTop: 4 }}
                  InputProps={{
                    disableUnderline: true,
                    margin: "none",
                    style: {
                      borderRadius: "8px",
                      backgroundColor: "white",
                      border: "1px solid #D0D5DD",
                      minWidth: "50px",
                      height: "100%",
                    },
                    classes: { input: classes.inputRootID },
                  }}
                  disabled={isSubmitting}
                  {...formik.getFieldProps("state")}
                />
              </Box>
              <Box mt={2}>
                <Typography>
                  {getLang(lang, "label.COUNTRY")}{" "}
                  <span style={{ color: "#F97066" }}>*</span>
                </Typography>
                <CountrySelect
                  textFieldProps={{
                    fullWidth: true,
                    variant: "filled",
                    size: "small",
                    margin: "dense",
                    inputPropStyle: {
                      backgroundColor: "white",
                      border: "1px solid #D0D5DD",
                      borderRadius: 8,
                      height: 43,
                      padding: "0px",
                    },
                  }}
                  formProps={{
                    ...formik.getFieldProps("country"),
                    value: formik.values.country,
                    onChange: (e, newValue) => {
                      formik.setFieldValue("country", newValue);
                    },
                    disabled: isSubmitting,
                    disableClearable: true,
                  }}
                  type="countryOnly"
                />
              </Box>
            </Box>
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              p={2}
            >
              <Box display="flex" alignItems="center">
                <Button
                  variant="outlined"
                  color="primary"
                  style={{
                    marginRight: 10,
                  }}
                  onClick={() => handleClose()}
                >
                  {getLang(lang, "label.CANCEL")}
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={
                    !formik.values.phoneCode ||
                    !formik.values.phoneNumber ||
                    !formik.values.addressLine1 ||
                    !formik.values.city ||
                    !formik.values.state ||
                    !formik.values.postalCode ||
                    !formik.values.country
                  }
                  onClick={() => {
                    handleSubmit(formik.values);
                  }}
                >
                  {isSubmitting ? (
                    <CircularProgress size={20} style={{ color: "white" }} />
                  ) : (
                    <Typography>
                      {getLang(
                        lang,
                        selectedEditAddr ? "label.UPDATE" : "label.ADD"
                      )}
                    </Typography>
                  )}
                </Button>
              </Box>
            </Box>
          </>
        )}
      </Formik>
    </Dialog>
  );
}
