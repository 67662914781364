import React, { useState, useRef, useEffect } from "react"
import StockSearchComponent from "./stockProductSearch.component"
import { selectLoading } from "modules/notification"
import StockProductSearchPanelComponent from "./stockProductSearchPanel.component"
import { getStockProductDropdown } from "modules/stockflow/redux"
import { useSelector, useDispatch } from "react-redux";
import { cloneDeep } from "lodash"

function StockProductSearchContainer({
  handleChange,
  disabled,
  value,
  styles, 
  textStyles,
  placeholder,
  dealerUuid,
  type,
  code,
  handleChipDelete,
  isEmpty,
}) {
  const isFetching = useSelector(state => selectLoading(state, getStockProductDropdown.typePrefix))
  const lang = useSelector((state) => state.constant.languages);
  const stockProductDropdown = useSelector((state) => state.stockflowInventory.stockProductDropdown);
  const [open, setOpen] = useState(false)
  const [isOpenList] = useState(false)
  const anchorRef = useRef(null)
  const prevOpen = useRef(open)
  const prevOpenList = useRef(isOpenList)
  const [selectAll, setSelectAll] = useState(false)
  const [search, setSearch] = useState("")
  const dispatch = useDispatch()
  const drawerOpen = useSelector((state)=>state.app.drawerOpen);
  const [extraChip, setExtraChip] = useState({
    count : 0,
    offset : 0,
    allOverflow : false
  });

  const adjustField = (ref) => {
    let children = ref.current?.children[0]?.children;
    let p = ref.current?.children[0];
    
    if (!children) return;

    // max width is 90%. Use 0.9 to calc field width
    let panelWidth = ref.current?.scrollWidth * 0.9;
    let childWidth = 0;

    if (children.length > 0) {
      // Need to exclude placeholder
      if (children[0].role == null) return;

      children = [...children];

      // Check for no overflow child count
      let inRangeCount = children.filter((child, index)=>{
  
        let panelContentWidth = p.getBoundingClientRect().width
        // get exact width + padding with decimal
        childWidth += (parseInt(window.getComputedStyle(child).marginRight) + child.getBoundingClientRect().width);
        
        // Check if overflow
        if(panelWidth - panelContentWidth < 1) {
          // need to add 0.5 as the ellipsis padding (0.5 = ellipsis padding)
          // if the overflow happen before last child, need inlude another 10 for the ellipsis (10 = ellipsis width)
          return (index !== (children.length - 1)) ? (childWidth + 10.5) < panelContentWidth : childWidth + 0.5 < panelContentWidth;
        }

        return (panelWidth > panelContentWidth);
      }).length;

      if (inRangeCount > 0) {

        let lastRangeChild = children[inRangeCount - 1];

        let outRangeChild = value.slice(inRangeCount);
        // Check for overflow child count
        let extraCount = value.length - inRangeCount;
        setExtraChip({
          count : extraCount,
          offset : lastRangeChild.offsetLeft + lastRangeChild.offsetWidth,
          allOverflow : false,
          overflowChild : outRangeChild
        });
      } else {
        setExtraChip({
          count : value.length,
          offset : 0,
          allOverflow : true,
          overflowChild : value
        });
      }
    } else {
      setExtraChip({
          count : 0,
          offset : 0,
          allOverflow : false,
          overflowChild : []
        });
    }
  }

  useEffect(() => {
    dispatch(getStockProductDropdown({
      search,
      type,
      dealer: dealerUuid,
      code
    }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, dispatch, dealerUuid]);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])

  useEffect(() => {
    if (prevOpenList.current === true && isOpenList === false) {
      anchorRef.current.focus()
    }

    prevOpenList.current = isOpenList
  }, [isOpenList])

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const handleSelectAll = async () => {
    if(!selectAll){
      let selectedProducts = stockProductDropdown.map(product => {
        return product.id
      })
      handleChange(selectedProducts)
    }else{
      handleChange([])
    }
    setSelectAll(!selectAll)
  }

  const handleSelectProduct = (product) => {
    let temp = cloneDeep(value)

    if(temp.findIndex(t => t === product.id) >= 0){
      temp = temp.filter( t => t !== product.id )
    }else{
      temp.push(product.id)
    }
    handleChange(temp)
    setSelectAll(temp.length === stockProductDropdown.length)
  };

  const handleSearch = (value) => {
    setSearch(value)
  }

  return (
    <>
      <StockSearchComponent
        anchorRef={anchorRef}
        disabled={disabled}
        value={value}
        handleClick={handleOpen}
        styles={styles}
        textStyles={textStyles}
        placeholder={placeholder}
        lang={lang}
        handleChipDelete={handleChipDelete}
        drawerOpen={drawerOpen}
        extraChip={extraChip}
        isEmpty={isEmpty}
        adjustField={adjustField}
        stockProductDropdown={stockProductDropdown}
        handleChange={handleChange}

      />
      <StockProductSearchPanelComponent
        selectAll={selectAll}
        value={value}
        anchorRef={anchorRef}
        open={open}
        isOpenList={isOpenList}
        handleClose={handleClose}
        isFetching={isFetching}
        stockProductDropdown={stockProductDropdown}
        handleSelectAll={handleSelectAll}
        handleSelectProduct={handleSelectProduct}
        handleSearch={handleSearch}
        lang={lang}
        handleChipDelete={handleChipDelete}
        isEmpty={isEmpty}
      />
    </>
  )
}

export default StockProductSearchContainer