import React, { useState, useEffect } from "react";
import { useRouteMatch, useHistory } from 'react-router-dom';
import SurveyDetailPage from "./surveyDetails.page";
import { useSelector } from "react-redux";
import { SURVEY_VIEW } from "lib/constants/accessRights"
import LoadingComponent from "components/loading";
import { getLang } from "app/feature/constants";


function SurveyDetailContainer() {
  const match = useRouteMatch();
  const uuid = match.params.surveyUuid;
  const [ activeIndex, setActiveIndex ] = useState(0);
  const token = useSelector(state => state.session.accessToken);
  const respondCount = useSelector(state => state.survey.surveyAnalytic.response.value);
  const history = useHistory()
  const hasSurveyViewAccessRight = useSelector(state => state.profile.accessRights.includes(SURVEY_VIEW));
  const lang = useSelector(state => state.constant.languages);

  useEffect(() => {
    if (!hasSurveyViewAccessRight) {
      history.push('/admin/dashboard')
    }
  }, [history, hasSurveyViewAccessRight])

  if(!hasSurveyViewAccessRight) return <LoadingComponent />;

  const tabLabels = [
    getLang(lang, 'label.REPORT'),
    getLang(lang, 'label.RESULT'),
    getLang(lang, 'label.RESPONDENTS'),
  ];

  const handleChange = (event, index ) => {
    setActiveIndex(index);
  }

  return (
    <>
      <SurveyDetailPage
        handleChange={handleChange}
        activeIndex={activeIndex}
        tabLabels={tabLabels}
        uuid={uuid}
        token={token}
        respondCount={respondCount}
        lang={lang}

      />
    </>
  );
}

export default SurveyDetailContainer;