import MainPage from './main-page.page'
import React, { useEffect } from 'react';
import { REPORTING_VIEW } from "lib/constants/accessRights";
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getLang } from "app/feature/constants";
import { datetimeValidation, periodValidation } from 'modules/reporting/utils/helper';
import { getReportValidation, initReportValidation } from 'modules/reporting/redux';
import { checkMaintenanceAlert } from 'modules/admin/redux';


function MainPageContainer() {
    const query = new URLSearchParams(window.location.search);
    const branchUuid = query.get('branchUuid') ? query.get('branchUuid') : null;
    const period = query.get('period') ? query.get('period') : "daily";
    const date_start = query.get('start') ? datetimeValidation(query.get('start') , query.get('end'))[0] : new Date(Date.now() - 13 * 24 * 60 * 60 * 1000);
    const date_end = query.get('end') ?  datetimeValidation(query.get('start') , query.get('end'))[1] : new Date();

    const isDev = query.get('dev') ? parseInt(query.get('dev')) : null;

    const [currentFilter, setCurrentFilter] = useState({
        branchUuid: branchUuid,
        period: periodValidation(period) ? period : "daily",
        dateRange: [
            date_start,
            date_end
        ],
        isDev: isDev === 1 ? 1 : null
    });
    const [page, setPage] = useState(query.get('page') ? query.get('page') : 'qr');

    const history = useHistory()
    const hasReportingViewAccessRight = useSelector(state => state.profile.accessRights.includes(REPORTING_VIEW));
    const lang = useSelector(state => state.constant.languages)
    const dispatchValidation = useDispatch();
    const dispatchInit = useDispatch();

    useEffect(() => {
        if (!hasReportingViewAccessRight) {
            history.push('/admin/dashboard')
        }
    }, [history, hasReportingViewAccessRight])

    const translate = (text) => {
        return getLang(lang, text);
    }

    useEffect(() => {
        dispatchInit(checkMaintenanceAlert());
        dispatchInit(initReportValidation())
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatchInit])

    // VALIDATION START
    const validationData = useSelector(state => state.reporting.validation.reportValidation);

    useEffect(() => {
        let payload = [];
        if(currentFilter.branchUuid)
            payload.push({item_value: currentFilter.branchUuid, item_type: "branch_uuid"});

        dispatchValidation(getReportValidation({
            items: payload,
        }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatchValidation]);


    useEffect(() => {
        if(validationData.error === 404){
            history.push('/admin/invalid')
        } 
        
        if(validationData.error === 403){
            history.push('/admin/dashboard')
        }
            
    }, [validationData, history])

    if(!validationData.success){
        return null;
    }

    // VALIDATION END

    return (
        <MainPage
            filter={{
                currentFilter,
                setCurrentFilter
            }}
            pageMode={{
                page,
                setPage
            }}

            translate={translate}
        />
    )
}

export default MainPageContainer;