import React from "react";
import {
  Dialog,
  Button,
  makeStyles,
  Typography,
  Box,
  CircularProgress,
} from "@material-ui/core";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    padding: "35px 30px",
    backgroundColor: "#ffffff",
    color: "black !important",
    width: 400,
    maxWidth: "80vw",
    borderRadius: 12,
  },
  customDialogActionRoot: {
    justifyContent: "center",
  },
  customDialogTitle: {
    paddingTop: 0,
    color: "black",
  },
  customDialogPaperWithXs: {
    width: 400,
    maxWidth: "80vw",
  },
  dialogRemarksCaption: {
    fontWeight: 200,
    marginLeft: 5,
  },
  dialogRemarks: {
    color: "#FD646F",
  },
  dangerCancelButton: {
    color: "white",
    borderColor: "white !important",
  },
  successCancelButton: {
    backgroundColor: theme.palette.primary.main,
  },
  dangerText: {
    color: "white",
  },
  successText: {
    color: "white",
  },
  defaultText: {
    color: "black",
  },
}));

export default function ConfirmationDialogComponent({
  isLoading,
  isOpen,
  handleClose,
  handleProceed,
  lang,
  title,
  description,
  type,
}) {
  const classes = useStyle();

  return (
    <Dialog
      classes={{
        paper: classes.root,
        paperWidthXs: classes.customDialogPaperWithXs,
      }}
      open={isOpen}
      disableBackdropClick
      disableEscapeKeyDown
      aria-labelledby="confirmation-dialog"
      maxWidth="sm"
    >
      <Box textAlign="start">
        <Box mb={1}>
          <Typography variant="body1" align="center">
            <b>{title}</b>
          </Typography>
        </Box>
        <Box mb={4}>
          <Typography variant="body2" align="center">
            {description}
          </Typography>
        </Box>
        {isLoading ? (
          <Box display="flex" justifyContent="center" alignItems="center">
            <CircularProgress />
          </Box>
        ) : (
          <Box display="flex">
            <Box mr={2} flex={1}>
              <Button
                variant="outlined"
                onClick={() => {
                  handleClose();
                }}
                color="primary"
                style={{ width: "100%" }}
              >
                {getLang(lang, "label.CANCEL")}
              </Button>
            </Box>
            <Box flex={1}>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                onClick={() => handleProceed()}
                style={{
                  backgroundColor:
                    type === "danger"
                      ? "#F04438"
                      : type === "success"
                      ? "#6AAF68"
                      : "#3A4D54",
                  width: "100%",
                }}
              >
                {getLang(lang, "label.CONFIRM")}
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </Dialog>
  );
}
