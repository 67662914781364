import axios from "axios";

const put = ({ id, isPreset, quantity, serialNumber, fileKey }) => {
  const body = {
    isPreset: isPreset,
    quantity: quantity,
    serialNumbers: serialNumber,
    fileKey: fileKey
  };

  return new Promise((resolve, reject) => {
    axios
      .put(
        `/campaign/api/admin/instant-lucky-draw/prizes/${id}/winners/adjust`,
        body
      )
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

const adjustQuantity = {
  put
};

export default adjustQuantity;
