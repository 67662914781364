import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { unwrapResult } from '@reduxjs/toolkit';
import { selectLoading } from 'modules/notification';
import {
  addAttribute,
  getAllAttributeListing,
  updateAttributeStatus,
  updateAttributesStatus,
  deleteAttribute,
  editAttribute
} from "modules/product-mgmt/redux/action/attribute.action";
import {
  changeSelectedAttribute,
  changeBulkSelectedAttribute,
  setAttributeEditDetails,
  changeBulkSelectedAvailableAttribute,
  resetAttributesListing
} from "modules/product-mgmt/redux/slice/products.slice"
import { PRODUCT_ATTRIBUTE_VIEW, PRODUCT_ATTRIBUTE_EDIT, PRODUCT_ATTRIBUTE_DELETE } from "lib/constants/accessRights"
import AttributesListPanelComponent from './attributesListPanel.component';
import CreateAttributesDialog from 'modules/product-mgmt/components/dialog/createAttributesDialog';
import GeneralDialog from 'components/dialog/generalDialog';
import AlertIcon from 'assets/svg/alert.svg';
import EditAttributeDialog from '../../dialog/editAttributeDialog';
import { generatePaginationOptions } from "lib/helper";
import { getLang } from "app/feature/constants";

function AttributesListPanelContainer({
  handleCreateAttribute
}) {
  const dispatch = useDispatch()
  const history = useHistory();

  const isCreateDialogOpen = useSelector(state => state.products.isOpen)
  const hasProductAttributeViewAccessRight = useSelector(state => state.profile.accessRights.includes(PRODUCT_ATTRIBUTE_VIEW));
  const hasProductAttributeEditAccessRight = useSelector(state => state.profile.accessRights.includes(PRODUCT_ATTRIBUTE_EDIT));
  const hasProductAttributeDeleteAccessRight = useSelector(state => state.profile.accessRights.includes(PRODUCT_ATTRIBUTE_DELETE));
  const totalFiltered = useSelector(state => state.products.attributeList.totalFiltered);
  const data = useSelector(state => state.products.attributeList.data);
  const selectedIds = useSelector(state => state.products.attributeList.selectedId)
  const isFetching = useSelector(state => selectLoading(state, getAllAttributeListing.typePrefix));
  const isLoadingAttributeListError = useSelector(state => state.products.attributeList.isError)
  const isSubmittingAddOption = useSelector((state) => selectLoading(state, addAttribute.typePrefix))
  const lang = useSelector(state => state.constant.languages);
  const details = useSelector(state => state.products.attributeList.details)
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [deleteAttributeId, setDeleteAttributeId] = useState(false);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [paginationOptions, setPaginationOptions] = useState([]);
  const [filter, setFilter] = useState({
    search: "",
    status: "",
    page: 0,
    rowsPerPage: 25
  })
  const [isSaving, setIsSaving] = useState(false);
  
  useEffect(()=>{
    if(!hasProductAttributeViewAccessRight){
      history.push('/admin/dashboard')
    }
  }, [history, hasProductAttributeViewAccessRight])

  useEffect(() => {
    return () => {
      dispatch(resetAttributesListing())
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    dispatch(getAllAttributeListing({
      length: filter.rowsPerPage,
      start: filter.rowsPerPage * filter.page,
      search: filter.search,
      status: filter.status,
    }))
  }, [
    dispatch,
    filter
  ])

  useEffect(() => {
    setPaginationOptions(generatePaginationOptions(totalFiltered))
  }, [totalFiltered])

  const handleFetchAttributeList = () => {
    setFilter((prev) => ({
      ...prev,
      page: 0
    }))
    handleDeselectAll()
  }

  const handleReload = () => {
    dispatch(getAllAttributeListing({
      length: filter.rowsPerPage,
      start: filter.rowsPerPage * filter.page,
      search: filter.search,
      status: filter.status,
    }))
  }

  const handleChangePage = (event, newPage) => {
    setFilter((prev) => ({
      ...prev,
      page: newPage,
    }))
  }

  const handleChangeRowsPage = event => {
    setFilter((prev) => ({
      ...prev,
      page: 0,
      rowsPerPage: event.target.value
    }))
  }

  const handleSearch = (value) => {
    let tempStatus = "";

    if (value.status === "Enable") {
      tempStatus = "enabled"
    } else if (value.status === "Disable") {
      tempStatus = "disabled"
    }
    setFilter({
      search: value.search,
      status: tempStatus,
      page: 0,
      rowsPerPage: filter.rowsPerPage
    })
  }

  const handleSubmitAttributes = (value) => {
    dispatch(addAttribute({
      name: value.attribute,
      options: value.options
    }))
      .then(unwrapResult)
      .then(() => {
        handleFetchAttributeList()
        handleCreateAttribute(false)
      })
  }

  const handleAttributeStatus = (id, status) => {
    dispatch(updateAttributeStatus({ id: id, status: status ? "enabled" : "disabled" }))
  }

  const handleSelectAttribute = (index, isSelected) => {
    dispatch(changeSelectedAttribute({ index: index, isSelected: isSelected }))
  }

  const handleSelectAvailable = () => {
    dispatch(changeBulkSelectedAvailableAttribute({ value: true }))
  }

  const handleSelectAll = () => {
    setIsSelectAll(true)
    dispatch(changeBulkSelectedAttribute({ value: true }))
  }
  const handleDeselectAll = () => {
    setIsSelectAll(false)
    dispatch(changeBulkSelectedAttribute({ value: false }))
  }

  const handleChangeAttributesStatus = (value) => {
    const tempFilter = {
      length: filter.rowsPerPage,
      start: filter.rowsPerPage * filter.page,
      search: filter.search,
      status: filter.status,
    }

    const tempUpdate = {
      status: value === "Enable Selected" ? "enabled" : "disabled",
      isselectedall: isSelectAll,
      uuid: selectedIds
    }

    dispatch(updateAttributesStatus({ filter: tempFilter, update: tempUpdate }))
      .then(() => {
        handleFetchAttributeList()
      })
  }

  const handleDeleteAttribute = (id) => {
    setIsDeleteDialogOpen(true)
    setDeleteAttributeId(id)
  }

  const handleSubmitDeleteAttribute = () => {
    dispatch(deleteAttribute({
      id: deleteAttributeId
    }))
      .then(unwrapResult)
      .then(() => handleFetchAttributeList())
      .finally(() => {
        setIsDeleteDialogOpen(false)
      })
  }

  const handleEditAttribute = (isOpen, details) => {
    setEditDialogOpen(isOpen)
    if (isOpen) {
      dispatch(setAttributeEditDetails(details))
    }
  }

  const handleSubmitEditAttribute = (values) => {
    setIsSaving(true);
    let tempOptions = [];

    values.options.map(option => {
      tempOptions.push({
        uuid: option.id,
        value: option.value,
        status: option.status,
      })
      return null;
    })

    dispatch(editAttribute({
      id: values.attribute.id,
      name: values.attribute.name,
      status: values.attribute.status,
      options: tempOptions
    }))
      .then(unwrapResult)
      .then(() => {
        handleFetchAttributeList()
        setEditDialogOpen(false)
      })
      .finally(() => {
        setIsSaving(false);
      });
  }

  return (
    <>
      <AttributesListPanelComponent
        hasProductAttributeEditAccessRight={hasProductAttributeEditAccessRight}
        hasProductAttributeDeleteAccessRight={hasProductAttributeDeleteAccessRight}
        data={data}
        totalFiltered={totalFiltered}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPage}
        handleReload={handleReload}
        handleSearch={handleSearch}
        selectedCount={!isSelectAll ? selectedIds.length : totalFiltered - selectedIds.length}
        isFetching={isFetching}
        selectedIds={selectedIds}
        paginationOptions={paginationOptions}
        isSelected={(!isSelectAll && selectedIds.length > 0) || (isSelectAll && selectedIds.length < totalFiltered)}
        page={filter.page}
        search={filter.search}
        statusFilter={filter.status}
        rowsPerPage={filter.rowsPerPage}
        handleCreateAttribute={handleCreateAttribute}
        isLoadingAttributeListError={isLoadingAttributeListError}
        handleChangeStatus={handleAttributeStatus}
        handleSelectAttribute={handleSelectAttribute}
        handleSelectAvailable={handleSelectAvailable}
        handleSelectAll={handleSelectAll}
        handleDeselectAll={handleDeselectAll}
        handleChangeAttributesStatus={handleChangeAttributesStatus}
        isSelectAll={isSelectAll}
        handleDeleteAttribute={handleDeleteAttribute}
        handleEditAttribute={handleEditAttribute}
        lang={lang}
      />
      <CreateAttributesDialog
        isOpen={isCreateDialogOpen}
        handleClose={() => {
          handleCreateAttribute(false)
        }}
        handleSubmit={handleSubmitAttributes}
        isSubmittingAddOption={isSubmittingAddOption}
      />
      <GeneralDialog
        isOpen={isDeleteDialogOpen}
        handleClose={() => setIsDeleteDialogOpen(false)}
        handleProceed={handleSubmitDeleteAttribute}
        title={getLang(lang,"label.DELETE_ATTRIBUTE")}
        description={getLang(lang,"paragraph.DELETE_ATTRIBUTE_QUESTION")}
        type="danger"
        icon={<img src={AlertIcon} alt="alert" />}
      />
      <EditAttributeDialog
        isOpen={editDialogOpen}
        handleClose={() => handleEditAttribute(false)}
        handleSubmit={handleSubmitEditAttribute}
        data={details}
        isSaving={isSaving}
      />
    </>
  )
}

export default AttributesListPanelContainer;