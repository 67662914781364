import getAllGift from "./getAllGift.api";
import createGift from "./createGift.api";
import getGift from "./getGift.api";
import updateGift from "./updateGift.api";
import deleteGift from "./deleteGift.api";
import getGiftOrderSetting from "./getGiftOrderSetting.api"
import updateGiftOrderSetting from "./updateGiftOrderSetting.api"

import getAllPointCut from "./getAllPointCut.api";
import createPointCut from "./createPointCut.api";
import getPointCut from "./getPointCut.api";
import updatePointCut from "./updatePointCut.api";
import updatePointCutStatus from "./updatePointCutStatus.api";
import getGiftDropdown from "./getGiftDropdown.api";

import getAllOrder from "./getAllOrder.api";
import updateOrder from "./updateOrder.api";
import getOrder from "./getOrder.api"

const RedemptionCenterApi = {
    getAllGift,
    createGift,
    getGift,
    updateGift,
    deleteGift,
    getGiftOrderSetting,
    updateGiftOrderSetting,

    getAllPointCut,
    createPointCut,
    getPointCut,
    updatePointCut,
    updatePointCutStatus,
    getGiftDropdown,

    getAllOrder,
    updateOrder,
    getOrder
};

export default RedemptionCenterApi;