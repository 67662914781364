import { Avatar, Box, Chip, Grid, TableCell, TableRow, Typography } from "@material-ui/core";
import { responseHandler, getCountryName, drilldownPageSelection } from "modules/reporting/utils/helper";
import React from "react";
import { TrendingNegativeCard, TrendingPositiveCard } from "../../card";
import { ContentCard } from "../../layout";
import { ChartCardLoading, TrendingCardLoading } from "../../skeleton/loading";
import { DownloadableTable } from "../../table";
import { UnknownFlagIcon, UnknownUserIcon } from '../../../assets/svg';
import AndroidFullBodyIcon from "assets/img/androidFullbodyIcon.png";
import IosIcon from "assets/img/iosIcon.png";
import WindowsIcon from "assets/img/windowIcon.png";
import Safari from "assets/img/safariIcon.png";
import Chrome from "assets/img/chromeIcon.png";
import Firefox from "assets/img/firefoxIcon.png";
import InternetExplorer from "assets/img/internetExplorerIcon.png";
import MicrosoftEdge from "assets/img/microsoftEdgeIcon.png";
import Opera from "assets/img/operaIcon.png";
import Netscape from "assets/img/netscapeIcon.png";
import * as FlagIcons from 'country-flag-icons/react/3x2';
import { hasFlag } from 'country-flag-icons';
import { BROWSER_TYPE_LABEL_CHROME, BROWSER_TYPE_LABEL_EDGE, BROWSER_TYPE_LABEL_FIREFOX, BROWSER_TYPE_LABEL_IE, BROWSER_TYPE_LABEL_NETSCAPE, BROWSER_TYPE_LABEL_OPERA, BROWSER_TYPE_LABEL_SAFARI, DEVICE_TYPE_MOBILE, DEVICE_TYPE_TABLET, PLATFORM_TYPE_LABEL_LINUX, PLATFORM_TYPE_LABEL_MAC, PLATFORM_TYPE_LABEL_WINDOW } from "modules/reporting/constants";
import { chipStyle, fontTextStyle, iconStyle, tableStyle } from "modules/reporting/styles";
import { Link } from "react-router-dom";
import { MixedChart } from "../../charts";
import EmptyChart from "../../skeleton/global/empty-chart";
import moment from "moment";
import "moment/min/locales.min";

export default function ConsumerScanPanelComponent({
    status,
    data,
    sort,
    language,
    pagination,
    columns,
    currentFilter,
    handleExportExcel,
    pageType,
    translate
}) {
    // const classes = useStyles();
    const iconClasses = iconStyle();
    const fontClasses = fontTextStyle();
    const chipClasses = chipStyle();
    const tableClasses = tableStyle();

    if (language === "ZH") {
        moment.locale('zh-cn')
    } else if (language === "TL") {
        moment.locale('tl-ph')
    } else {
        moment.locale(language)
    }

    function getFlagIcon(countryCode) {

        if (!hasFlag(countryCode)) {
            return (
                <Box className={iconClasses.flag__icon}>
                    <UnknownFlagIcon />
                </Box>)
        }

        const FlagIcon = FlagIcons[countryCode];
        return (
            <Box className={iconClasses.flag__icon}>
                <FlagIcon title={countryCode} />
            </Box>
        )
    }

    function getPlatformIcon(platformType) {
        switch (platformType) {
            case PLATFORM_TYPE_LABEL_WINDOW:
                return (
                    <img className={iconClasses.svg__icon} src={WindowsIcon} alt="device" />
                )
            case PLATFORM_TYPE_LABEL_MAC:
                return (
                    <img className={iconClasses.svg__icon} src={IosIcon} alt="device" />
                )
            case PLATFORM_TYPE_LABEL_LINUX:
                return (
                    <img className={iconClasses.svg__icon} src={AndroidFullBodyIcon} alt="device" />
                )
            default:
                return <Typography className={fontClasses.text__unknown}>-</Typography>
        }
    }

    function getBrowserIcon(browserType) {
        switch (browserType) {
            case BROWSER_TYPE_LABEL_IE:
                return (
                    <img className={iconClasses.svg__icon} src={InternetExplorer} alt="browser" />
                )
            case BROWSER_TYPE_LABEL_EDGE:
                return (
                    <img className={iconClasses.svg__icon} src={MicrosoftEdge} alt="browser" />
                )
            case BROWSER_TYPE_LABEL_FIREFOX:
                return (
                    <img className={iconClasses.svg__icon} src={Firefox} alt="browser" />
                )
            case BROWSER_TYPE_LABEL_OPERA:
                return (
                    <img className={iconClasses.svg__icon} src={Opera} alt="browser" />
                )
            case BROWSER_TYPE_LABEL_CHROME:
                return (
                    <img className={iconClasses.svg__icon} src={Chrome} alt="browser" />
                )
            case BROWSER_TYPE_LABEL_SAFARI:
                return (
                    <img className={iconClasses.svg__icon} src={Safari} alt="browser" />
                )
            case BROWSER_TYPE_LABEL_NETSCAPE:
                return (
                    <img className={iconClasses.svg__icon} src={Netscape} alt="browser" />
                )
            default:
                return <Typography className={fontClasses.text__unknown}>-</Typography>
        }
    }

    function getDeviceName(deviceType) {
        switch (parseInt(deviceType)) {
            case DEVICE_TYPE_MOBILE:
                return translate("label.MOBILE")
            case DEVICE_TYPE_TABLET:
                return translate("label.TABLET")
            default:
                return "-"
        }
    }

    const scanCountStatus = (scanCountStatus) => {
        if (scanCountStatus) {
            return (
                <Chip label={translate("label.VALID")} className={chipClasses.chip__valid} />
            )
        }

        return (
            <Chip label={translate("label.SUSPENDED")} className={chipClasses.chip__suspended} />
        )
    }
    const renderData = (data) => {

        let rows = [];

        if (Object.keys(data).length === 0 || !data.isSuccess) {
            return rows;
        }

        let imageProps = JSON.stringify(data);
        imageProps = JSON.parse(imageProps);

        let no_iteration = pagination.page * pagination.rowsPerPage + 1;
        imageProps.rows.forEach((item, index) => {
            let row = (
                <TableRow key={index}>
                    <TableCell className={tableClasses.table__cell}>
                        <Typography className={fontClasses.text__table_cell}>{no_iteration++}</Typography>
                    </TableCell>
                    <TableCell className={tableClasses.table__cell} style={{ minWidth: "190px" }}>
                        <Box className={tableClasses.cell__product}>
                            {
                                item.image ?
                                    <Avatar variant="square" alt={item.name} src={item.image} className={iconClasses.svg__icon} />
                                    :
                                    <Avatar variant="square" alt={item.name} src={UnknownUserIcon} className={iconClasses.svg__icon} />
                            }
                            {
                                item.name ? (
                                    <Link
                                        target="_blank" rel="noopener noreferrer"
                                        className={fontClasses.text__hyperlink}
                                        to={() => {
                                            if (!item.uuid)
                                                return;

                                            let filterProperties = {};

                                            if (item.uuid)
                                                filterProperties.consumerUuid = item.uuid;

                                            let url = drilldownPageSelection(filterProperties, pageType, true);
                                            return url;
                                        }}
                                    >
                                        {item.name ?? translate("label.UNKNOWN")}
                                    </Link>
                                ) : (
                                    <Typography className={fontClasses.text__unknown}>
                                        {translate("label.UNKNOWN")}
                                    </Typography>
                                )
                            }
                        </Box>

                    </TableCell>
                    <TableCell className={tableClasses.table__cell} style={{ minWidth: "185px" }}>
                        <Link
                            target="_blank" rel="noopener noreferrer"
                            className={fontClasses.text__hyperlink}
                            to={() => {

                                let filterProperties = {};

                                if (item.enc)
                                    filterProperties.enc = item.enc;

                                let url = drilldownPageSelection(filterProperties, pageType, true);
                                return url;
                            }}
                        >
                            {item.serial_number ?? translate("label.UNKNOWN")}
                        </Link>
                    </TableCell>
                    <TableCell className={tableClasses.table__cell} style={{ minWidth: "90px" }}>
                        {
                            item.gender ?
                                (
                                    item.gender !== "" ?
                                        <Typography className={fontClasses.text__table_cell}>
                                            {item.gender}
                                        </Typography>
                                        :
                                        <Typography className={fontClasses.text__unknown}>-</Typography>
                                )
                                :
                                <Typography className={fontClasses.text__unknown}>-</Typography>
                        }
                    </TableCell>
                    <TableCell className={tableClasses.table__cell} style={{ minWidth: "90px" }}>
                        {
                            item.age ?
                                (
                                    item.age !== "" ?
                                        <Typography className={fontClasses.text__table_cell}>
                                            {item.age}
                                        </Typography>
                                        :
                                        <Typography className={fontClasses.text__unknown}>-</Typography>
                                )
                                :
                                <Typography className={fontClasses.text__unknown}>-</Typography>
                        }
                    </TableCell>
                    <TableCell className={tableClasses.table__cell} style={{ minWidth: "190px" }}>
                        {
                            item.scan_count ?
                                <Box
                                    display="flex"
                                    style={{
                                        gap: "5px",
                                    }}
                                    alignItems="center"
                                >
                                    <Typography className={fontClasses.text__table_cell}>
                                        {item.scan_count}  /  {item.scan_limit === "label.NO_LIMIT" ? translate("label.NO_LIMIT") : item.scan_limit}
                                    </Typography>
                                    {scanCountStatus(item.scan_count_status)}
                                </Box>
                                : <Typography className={fontClasses.text__unknown}>-</Typography>
                        }
                    </TableCell>
                    <TableCell className={tableClasses.table__cell} style={{ minWidth: "160px" }}>
                        {
                            item.email ?
                                (
                                    item.email !== "" ?
                                        <Typography className={fontClasses.text__table_cell}>
                                            {item.email}
                                        </Typography>
                                        :
                                        <Typography className={fontClasses.text__unknown}>-</Typography>
                                )
                                :
                                <Typography className={fontClasses.text__unknown}>-</Typography>
                        }
                    </TableCell>
                    <TableCell className={tableClasses.table__cell} style={{ minWidth: "160px" }}>
                        {
                            item.contact_no ?
                                (
                                    item.contact_no !== "" && item.contact_no !== " " ?
                                        <Typography className={fontClasses.text__table_cell}>
                                            {item.contact_no}
                                        </Typography>
                                        :
                                        <Typography className={fontClasses.text__unknown}>-</Typography>
                                )
                                :
                                <Typography className={fontClasses.text__unknown}>-</Typography>
                        }
                    </TableCell>
                    <TableCell className={tableClasses.table__cell} style={{ minWidth: "280px" }}>
                        {
                            item.scan_location ?
                                <Box
                                    display="flex"
                                    style={{
                                        gap: "5px",
                                    }}
                                    alignItems={"center"}
                                >
                                    {getFlagIcon(item.scan_location.country_code)}
                                    <Typography className={fontClasses.text__table_cell}>
                                        {getCountryName(item.scan_location.country_code) + ", " + item.scan_location.city}
                                    </Typography>
                                </Box>
                                : <Typography className={fontClasses.text__unknown}>-</Typography>
                        }
                    </TableCell>
                    <TableCell className={tableClasses.table__cell} style={{ minWidth: "160px" }}>
                        {
                            item.date ?
                                (
                                    item.date !== "" ?
                                        <Typography className={fontClasses.text__table_cell}>
                                            {moment(item.date).format('lll')}
                                        </Typography>
                                        :
                                        <Typography className={fontClasses.text__unknown}>-</Typography>
                                )
                                :
                                <Typography className={fontClasses.text__unknown}>-</Typography>
                        }
                    </TableCell>
                    <TableCell className={tableClasses.table__cell_icon} style={{ width: "90px" }}>
                        {getPlatformIcon(item.platform)}
                    </TableCell>
                    <TableCell className={tableClasses.table__cell_icon} style={{ width: "90px" }}>
                        {getBrowserIcon(item.browser)}
                    </TableCell>
                    <TableCell className={tableClasses.table__cell_icon} style={{ width: "90px" }}>
                        <Typography className={fontClasses.text__table_cell}>
                            {getDeviceName(item.device)}
                        </Typography>
                    </TableCell>
                    <TableCell className={tableClasses.table__cell} style={{ minWidth: "90px" }}>
                        {
                            item.language ?
                                (
                                    item.language !== "" ?
                                        <Typography className={fontClasses.text__table_cell}>
                                            {item.language}
                                        </Typography>
                                        :
                                        <Typography className={fontClasses.text__unknown}>-</Typography>
                                )
                                :
                                <Typography className={fontClasses.text__unknown}>-</Typography>
                        }
                    </TableCell>
                </TableRow>
            )


            item.render = row;
        })

        return imageProps;
    }

    // const renderTrendingDistributionData = (data) => {
    //     if (Object.keys(data).length === 0 || !data.isSuccess){
    //         return null;
    //     }

    //     return (
    //         data.trendingSales.map((item, index) => {
    //             return (
    //                 <TrendingDistributionPerformanceCard translate={translate} index={index} title={translate("label.DISTRIBUTION_PERFORMANCE")} data={item} />
    //             )
    //         })
    //     )
    // }

    return (
        <>
            <Box margin="20px">
                <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} sm={12} md={6} >
                        {
                            responseHandler(
                                data.totalScanTrendingData,
                                status.isFetchingTotalScanTrending,
                                <TrendingPositiveCard translate={translate} title={translate("label.TOTAL_VALID_SCAN_QR")} data={data.totalScanTrendingData} />,
                                <TrendingCardLoading />,
                                null,
                                null,
                                null
                            )
                        }
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} >
                        {
                            responseHandler(
                                data.totalSuspendedTrendingData,
                                status.isFetchingTotalSuspendedTrending,
                                <TrendingNegativeCard translate={translate} title={translate("label.SUSPENDED_QR")} data={data.totalSuspendedTrendingData} />,
                                <TrendingCardLoading />,
                                null,
                                null,
                                null
                            )
                        }
                    </Grid>
                    {/* <Grid item xs={12} sm={12} md={4} >
                        <Carousel        
                            autoPlay={true}
                            interval={5000}
                            infiniteLoop={true}
                        >    
                            {
                                responseHandler(
                                    data.totalDistributionPerformanceData, 
                                    status.isFetchingTotalDistributionPerformance, 
                                    renderTrendingDistributionData(data.totalDistributionPerformanceData), 
                                    <TrendingCardLoading/>, 
                                    null, 
                                    null, 
                                    null
                                )
                            }
                        </Carousel>
                    </Grid> */}
                </Grid>
            </Box>

            <Box
                marginTop="20px"
                marginBottom="20px"
            >
                <ContentCard>
                    <Box marginBottom="24px" display="flex" justifyContent="space-between" alignContent="center">
                        <Typography className={fontClasses.text__card_title}>
                            {translate("label.TOTAL_SCAN_COUNT_AGAINST_SUSPENDED_QR")}
                        </Typography>
                    </Box>
                    {/* chart */}
                    {
                        responseHandler(
                            data.scanAgainstSuspendedData,
                            status.isFetchingScanAgainstSuspendedData,
                            (
                                <MixedChart
                                    payload={data.scanAgainstSuspendedData.chart}
                                    chartHeight="340px"
                                    chartColors={[]}
                                    dontAddUnknown={true}
                                    translate={translate}
                                    multipleYAxis={[
                                        {
                                            name: translate("label.TOTAL_SCAN_QR"),
                                            color: "#6184FF"
                                        },
                                        {
                                            name: translate("label.SUSPENDED_QR"),
                                            color: "#F97066",
                                            opposite: true
                                        }
                                    ]}
                                />
                            ),
                            (
                                <ChartCardLoading />
                            ),
                            (
                                <EmptyChart
                                    data={[translate("label.TOTAL_SCAN"), translate("label.SUSPENDED")]}
                                    title={translate("label.CUSTOMER_NOT_FOUND")}
                                    value={translate("paragraph.NO_DATA_ON_SELECTED_FILTER")}
                                    labels={['10 July', '11 July', '12 July', '13 July']}
                                />
                            ),
                            (
                                <EmptyChart
                                    data={[translate("label.TOTAL_SCAN"), translate("label.SUSPENDED")]}
                                    title={translate("label.CUSTOMER_NOT_FOUND")}
                                    value={translate("paragraph.NO_DATA_ON_SELECTED_FILTER")}
                                    labels={['10 July', '11 July', '12 July', '13 July']}
                                />
                            ),
                            <EmptyChart
                                data={[translate("label.TOTAL_SCAN"), translate("label.SUSPENDED")]}
                                title={translate("label.LOADING_FAILED")}
                                value={translate("paragraph.LOADING_FAILED_DESCRIPTION")}
                                labels={['10 July', '11 July', '12 July', '13 July']}
                            />
                        )
                    }
                </ContentCard>
            </Box>

            <ContentCard>
                <DownloadableTable
                    title={translate("label.CONSUMER")}
                    sort={sort}
                    pagination={pagination}
                    columns={columns}
                    handleExportExcel={handleExportExcel}
                    exportExcel={true}
                    status={status.isFetchingConsumerScanData}
                    data={renderData(data.consumerScanData)}
                    translate={translate}
                />
            </ContentCard>
        </>
    );
}