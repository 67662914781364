import React from "react";
import {
  Box,
  Paper,
  Button,
  makeStyles,
  Typography,
  Grid
} from "@material-ui/core";
import { CameraImg, UploadImg } from "../../../utils/static";
import Dropzone from "react-dropzone";
import classnames from "classnames";
import DefaultPicture from "assets/img/default-profile-picture.png";
import { getLang } from "app/feature/constants";

const style = theme => ({
  paper: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(3)
  },
  avatar: {
    height: 172,
    width: 172,
    borderRadius: "50%",
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    "& img": {
      height: "100%",
      minWidth: "100%",
      minHeight: "100%",
      objectFit: "cover",
      flexShrink: 0
    }
  },
  logo: {
    height: "100%",
    minWidth: "100%",
    minHeight: "100%",
    objectFit: "cover",
    flexShrink: 0
  },
  label: {
    textTransform: "none",
    display: "grid",
    justifyItems: "center"
  },
  input: {
    display: "none"
  },
  mr1: {
    backgroundColor: "#4D626A",
    padding: theme.spacing(2),
    "&:hover": {
      backgroundColor: "#415D69"
    },
    color: "#ffffff",
    display: "flex"
  },
  text: {
    color: theme.palette.secondary.main
  },
  dragging: {
    backgroundColor: "#415D69",
    border: "dashed 2px"
  },
  dropzoneContainer: {
    backgroundColor: "#4D626A",
    padding: theme.spacing(2)
  }
});

const useStyles = makeStyles(style);

export default function PhotoProfilePanelComponent({
  profileImage,
  inputImgRef,
  handleImgChange,
  handleDrop,
  handleOpenCamera,
  isUploading,
  dragging,
  handleDragEnter,
  handleDragLeave,
  lang
}) {
  const classes = useStyles();

  return (
    <Box>
      <Paper elevation={0} className={classes.paper}>
        <Box style={{ display: "flex" }}>
          <Grid container>
            <Grid item xs={9} sm={9} md={9} style={{ width: "100%" }}>
              <Typography variant="body1" style={{ color: "#ffffff" }}>
                {getLang(lang,"label.PROFILE_PICTURE")}
              </Typography>
            </Grid>
            <Grid
              item
              xs={3}
              sm={3}
              md={3}
              style={{ width: "100%", textAlign: "end" }}
            >
              <Button
                size="small"
                classes={{ label: classes.label }}
                disableElevation
                onClick={handleOpenCamera}
              >
                <CameraImg />
              </Button>
            </Grid>
          </Grid>
        </Box>

        <Box style={{ display: "flex", placeContent: "center" }}>
          <Box mt={2} mb={2} className={classes.avatar}>
            <img
              src={profileImage !== "" ? profileImage : DefaultPicture}
              alt=""
              className={classes.logo}
            />
          </Box>
        </Box>

        <Box>
          <Box mb={1}>
            <Typography
              variant="body1"
              style={{ color: "#ffffff", textAlign: "center" }}
            >
              {getLang(lang,"label.UPLOAD_IMAGE")}
            </Typography>
          </Box>

          <Box className={classes.dropzoneContainer}>
            <Dropzone
              onDrop={handleDrop}
              noClick
              noKeyboard
              onDragEnter={handleDragEnter}
              onDragLeave={handleDragLeave}
            >
              {({ getRootProps }) => (
                <div {...getRootProps()}>
                  <input
                    accept="image/*"
                    className={classes.input}
                    id="contained-button-file"
                    type="file"
                    ref={inputImgRef}
                    onChange={handleImgChange}
                  />
                  <label htmlFor="contained-button-file">
                    <Button
                      variant="contained"
                      size="small"
                      component="span"
                      className={classnames(classes.mr1, {
                        [classes.dragging]: dragging,
                      })}
                      classes={{ label: classes.label }}
                      disableElevation
                    >
                      <UploadImg />
                      {dragging ? (
                        <Box mt={1}>{getLang(lang,"label.DROP_HERE")}</Box>
                      ) : (
                        <Box
                          mt={1}
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            justifyContent: "center",
                          }}
                        >
                          {getLang(lang,"paragraph.DROP_FILES")}
                          <span className={classes.text}>&nbsp;{getLang(lang,"paragraph.BROWSE")}</span>
                        </Box>
                      )}
                    </Button>
                  </label>
                </div>
              )}
            </Dropzone>
          </Box>

          <Box
            mt={2}
            style={{
              display: "flex",
              placeContent: "center",
              textAlign: "center",
            }}
          >
            <Typography variant="caption" style={{ color: "#ffffff" }}>
              {getLang(lang,"paragraph.RECOMMENDED_FILE_SIZE_LOGO_6")}
            </Typography>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}
