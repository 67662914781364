import React from "react";
import {
  makeStyles,
  Paper,
  Divider,
  Stepper,
  Step,
  StepLabel,
  Typography,
  Box,
  Button,
  StepConnector,
  withStyles,
  useTheme,
  useMediaQuery
} from "@material-ui/core";
import ArrowBackRoundedIcon from "@material-ui/icons/ArrowBackRounded";
import { getLang } from "app/feature/constants";

const Connector = withStyles(theme => ({
  alternativeLabel: {
    padding: "0 24px"
  },
  active: {
    "& $line": {
      borderColor: theme.palette.primary.main
    }
  },
  completed: {
    "& $line": {
      borderColor: theme.palette.primary.main
    }
  },
  line: {
    borderColor: "rgba(58, 77, 84, 0.33)",
    borderTopWidth: 4,
    borderRadius: 5
  }
}))(StepConnector);

const useStyle = makeStyles(theme => ({
  root: {
    fontWeight: theme.typography.fontWeightBold
  },
  cancelButton: {
    color: theme.palette.error.main
  },
  iconContainer: {
    minHeight: 38
  }
}));

export default function QRCodeCreditComponent({
  steps,
  activeStep,
  handleNext,
  handleBack,
  handleCancel,
  handleReset,
  lang 
}) {
  const classes = useStyle();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const SelectedStep = steps[activeStep];

  return (
    <Paper elevation={0}>
      {activeStep < steps.length ? (
        <>
          <Box px={{ xs: 2, sm: 6 }} py={2}>
            <Stepper
              activeStep={activeStep}
              alternativeLabel
              connector={<Connector />}
            >
              {steps.map((step, index) => (
                <Step key={index}>
                  <StepLabel
                    classes={{ iconContainer: classes.iconContainer }}
                    StepIconComponent={
                      activeStep >= index ? step.activeImage : step.labelImage
                    }
                  >
                    {step.label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
          <Divider />
          <div>
            <SelectedStep.form
              handleNext={handleNext}
              handleCancel={handleCancel}
              handleReset={handleReset}
            >
              {({ disabled }) => (
                <Box display="flex" justifyContent="space-between">
                  {activeStep === 2 ? (
                    <Button
                      variant="text"
                      startIcon={<ArrowBackRoundedIcon />}
                      onClick={handleBack}
                      disabled={disabled}
                    >
                      {getLang(lang, 'label.BACK')}
                    </Button>
                  ) : (
                    <Button
                      variant="text"
                      disableElevation
                      classes={{ root: classes.cancelButton }}
                      onClick={handleCancel}
                    >
                      {getLang(lang, 'label.CANCEL')}
                    </Button>
                  )}
                  <Box>
                    {activeStep === 1 && (
                      <>
                        <Button
                          variant="text"
                          startIcon={<ArrowBackRoundedIcon />}
                          onClick={handleBack}
                          disabled={disabled}
                        >
                          {isMobile ? getLang(lang, 'label.EDIT_QTY') : getLang(lang, 'label.EDIT_QUANTITY')}
                        </Button>
                        &ensp;
                      </>
                    )}
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={disabled}
                      disableElevation
                    >
                      {activeStep === 0 && getLang(lang, 'label.CONFIRM')}
                      {activeStep === 1 && getLang(lang, 'label.CHECKOUT')}
                      {activeStep === 2 && getLang(lang, 'label.MAKE_PAYMENT')}
                    </Button>
                  </Box>
                </Box>
              )}
            </SelectedStep.form>
          </div>
        </>
      ) : (
        <>
          <Typography variant="h6">{getLang(lang, 'label.PAYMENT_SUCCESSFUL')}</Typography>
          <Typography variant="body1" color="textSecondary">
            {getLang(lang, 'paragraph.THANK_YOU_PURCHASE_CREDIT_UPDATED')}
          </Typography>
        </>
      )}
    </Paper>
  );
}
