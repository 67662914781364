import React from "react";
import {
  makeStyles,
  Box,
  Typography,
  Divider,
  Dialog,
} from "@material-ui/core";
import ProPlan from "../../../utils/image/ProPlan.png";
import LitePlan from "../../../utils/image/LitePlan.png";
import PlanBackground from "../../../utils/image/PlanBackground.png"
import CloseSvg from "../../../utils/svg/close.svg";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles(theme => ({
  // Compare Dialog
  compareDialogTitleBarRoot: {
    display: "flex",
    width: "100%",
    height: 50,
    justifyContent: 'space-between',
    alignItems: "center",
    paddingRight: 20,
    paddingLeft: 20,
    color: "#3A4D54",
  },
  compareDialogTitleBarTitle: {
    fontSize: 13,
    fontWeight: "bold",
  },
  compareDialogTitleBarImage: {
    cursor: "pointer",
    width: 10,
    marginLeft: 87.5,
  },
  compareDialogTitleBarDivider: {
    width: "100%",
  },
  compareDialogTitleBarSwitchRoot: {
    display: "flex",
    height: 50,
    fontSize: 12,
    alignItems: "center",
  },
  compareDialogTitleBarSwitchTab: {
    marginLeft: 15,
    display: "flex",
    background: "#EFEFEF",
    borderRadius: 30,
    width: "65%",
    height: 30,
    alignItems: "center",
    cursor: "pointer",
  },
  compareDialogTitleBarSwitchTabButton: {
    borderRadius: 30,
    width: 100,
    height: 30,
    textAlign: "center",
    fontSize: 12,
    fontWeight: "bold",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  compareDialogTitleBarSwitchTabButtonActive: {
    borderRadius: 30,
    width: 100,
    height: 30,
    textAlign: "center",
    fontSize: 12,
    fontWeight: "bold",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#3A4D54",
    color: "#FFFFFF",
  },
  compareDialogSwitchTabPanelsRoot: {
    display: "flex",
    color: "#3A4D54"
  },
  compareDialogSwitchTabPanelRoot: {
    "&:not(:last-child)": {
      borderRight: "1px solid rgba(0, 0, 0, 0.1)"
    },
  },
  compareDialogSwitchTabPanelTitleBoxRoot: {
    backgroundImage: `url(${PlanBackground})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: 92,
    backgroundPosition: "top",
    height: 145,
    width: "100%",
  },
  compareDialogSwitchTabPanelTitleTopBox: {
    height: 90,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  compareDialogSwitchTabPanelTitleTopBoxImage: {
    width: 40
  },
  compareDialogSwitchTabPanelTitleTopBoxText: {
    fontSize: 18,
    fontWeight: "bold",
    marginLeft: 20,
  },
  compareDialogSwitchTabPanelTitleBottomBox: {
    height: 60,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  compareDialogSwitchTabPanelTitleBottomBoxPrice: {
    fontSize: 20,
    fontWeight: "bold",
    height: 24,
  },
  compareDialogSwitchTabPanelTitleBottomBoxUnit: {
    fontSize: 12,
    paddingTop: 12,
  },
  compareDialogSwitchTabPanelTitleBottomBoxSave: {
    fontSize: 12,
    color: "#4BCB0F",
  },
  compareDialogSwitchTabPanelTitleBottomBoxSaveBold: {
    fontSize: 12,
    fontWeight: "bold",
    color: "#4BCB0F",
  },
  compareDialogSwitchTabPanelRebateRoot: {
    display: "flex",
    height: 35,
    justifyContent: "center",
    alignItems: "center",
  },
  compareDialogSwitchTabPanelRebateText: {
    fontSize: 12,
  },
  compareDialogSwitchTabPanelRebateTextBold: {
    fontSize: 12,
    fontWeight: "bold",
  },
  compareDialogSwitchTabPanelRebateTextBoldGreen: {
    fontSize: 12,
    fontWeight: "bold",
    color: "#4BCB0F"
  },
  compareDialogSwitchTabPanelMetadataRoot: {
    padding: "20px 40px",
  },
  compareDialogSwitchTabPanelMetadataUnorderList: {
    marginTop: 0,
    marginBottom: 5,
    listStyle: "disc"
  },
  compareDialogSwitchTabPanelMetadataTitle: {
    fontSize: 12,
    fontWeight: "bold",
    color: "rgba(58, 77, 84, 0.5)",
    marginBottom: 5,
  },
  compareDialogSwitchTabPanelMetadataTitleActive: {
    fontSize: 12,
    fontWeight: "bold",
    color: "#3A4D54",
    marginBottom: 5,
  },
  compareDialogSwitchTabPanelMetadataText: {
    fontSize: 12,
    color: "rgba(58, 77, 84, 0.5)",
  },
  compareDialogSwitchTabPanelMetadataTextActive: {
    fontSize: 12,
    color: "#3A4D54",
  },
  compareDialogSwitchTabPanelDataTitle: {
    fontSize: 12,
    fontWeight: "bold",
    color: "rgba(58, 77, 84, 0.5)",
    marginBottom: 5,
  },
  compareDialogSwitchTabPanelDataTitleActive: {
    fontSize: 12,
    fontWeight: "bold",
    color: "#3A4D54",
    marginBottom: 5,
  },
  compareDialogSwitchTabPanelDataText: {
    fontSize: 12,
    color: "rgba(58, 77, 84, 0.5)",
  },
  compareDialogSwitchTabPanelDataTextActive: {
    fontSize: 12,
    color: "#3A4D54",
  },
}));

function ComparePlanDialogTitleBar({
  compareSubscriptionType,
  onCompareSubscriptionTypeChange,
  onCompareCloseClick,
  lang
}) {
  let classes = useStyle();
  return (
    <Box className={classes.compareDialogTitleBarRoot}>
      <Typography className={classes.compareDialogTitleBarTitle}>
        {getLang(lang, 'label.PLAN_COMPARISON')}
      </Typography>
      <SwitchTabs compareSubscriptionType={compareSubscriptionType} onCompareSubscriptionTypeChange={onCompareSubscriptionTypeChange} label={getLang(lang, 'label.SUBSCRIPTION_TYPE')} lang={lang} />
      <img src={CloseSvg} alt={"close svg"} className={classes.compareDialogTitleBarImage} onClick={() => onCompareCloseClick()} />
    </Box>

  )
}

function SwitchTabs({
  label,
  compareSubscriptionType,
  onCompareSubscriptionTypeChange,
  lang
}) {
  const classes = useStyle();
  return (
    <Box className={classes.compareDialogTitleBarSwitchRoot}>
      {label}
      <Box className={classes.compareDialogTitleBarSwitchTab}>
        <Box
          className={compareSubscriptionType === "month" ? classes.compareDialogTitleBarSwitchTabButtonActive : classes.compareDialogTitleBarSwitchTabButton}
          onClick={() => onCompareSubscriptionTypeChange("month")}
        >
          {getLang(lang, 'label.MONTHLY')}
        </Box>
        <Box
          className={compareSubscriptionType === "year" ? classes.compareDialogTitleBarSwitchTabButtonActive : classes.compareDialogTitleBarSwitchTabButton}
          onClick={() => onCompareSubscriptionTypeChange("year")}
        >
          {getLang(lang, 'label.YEARLY')}
        </Box>
      </Box>
    </Box>
  )
}

function SwitchTabPanels({
  registrationType,
  userSubscriptionPlan,
  subscriptionPlans,
  compareSubscriptionType,
  lang
}) {
  const classes = useStyle()
  return (
    <Box className={classes.compareDialogSwitchTabPanelsRoot} style={{ width: "100%" }}>
      {
        subscriptionPlans.map(
          (subscriptionPlan, i) => (
            <SwitchTabPanel
              key={i}
              registrationType={registrationType}
              userSubscriptionPlan={userSubscriptionPlan}
              subscriptionPlan={subscriptionPlan}
              compareSubscriptionType={compareSubscriptionType}
              lang={lang}
            />
          )
        )
      }
    </Box>
  )
}

function SwitchTabPanel({
  registrationType,
  userSubscriptionPlan,
  subscriptionPlan,
  compareSubscriptionType,
  lang
}) {
  const classes = useStyle()
  let image = null;
  if (subscriptionPlan.name.toLocaleLowerCase() === "lite") image = LitePlan
  if (subscriptionPlan.name.toLocaleLowerCase() === "pro") image = ProPlan
  const qrPrice = subscriptionPlan.qr_credit_prices.find(p => p.registration_type === registrationType)

  const saveAmount = (subscriptionPlan.prices.find(price =>  price.interval === 'month').cost * 12) - subscriptionPlan.prices.find(price => price.interval === 'year').cost

  return (
    <Box className={classes.compareDialogSwitchTabPanelRoot} style={{ width: "50%" }} >
      <Box className={classes.compareDialogSwitchTabPanelTitleBoxRoot}>
        <Box className={classes.compareDialogSwitchTabPanelTitleTopBox}>
          <img
            className={classes.compareDialogSwitchTabPanelTitleTopBoxImage}
            src={image}
            alt={subscriptionPlan.title} />
          <Typography className={classes.compareDialogSwitchTabPanelTitleTopBoxText}>
            {subscriptionPlan.title}
          </Typography>
        </Box>
        <Box className={classes.compareDialogSwitchTabPanelTitleBottomBox}>
          <Typography>
            <Typography component={"span"} className={classes.compareDialogSwitchTabPanelTitleBottomBoxPrice}>
              ${subscriptionPlan.prices.find(price => price.interval === compareSubscriptionType).cost}
            </Typography>
            <Typography component={"span"} className={classes.compareDialogSwitchTabPanelTitleBottomBoxUnit}>
              /{compareSubscriptionType === "month" ? getLang(lang, 'label.MONTH') : getLang(lang, 'label.YEAR')}
            </Typography>
          </Typography>
          {
            compareSubscriptionType === "year"
              ?
              (
                <Typography>
                  <Typography component={"span"} className={classes.compareDialogSwitchTabPanelTitleBottomBoxSave}>
                    {getLang(lang, 'label.SAVE')}&nbsp;
                  </Typography>
                  <Typography component={"span"} className={classes.compareDialogSwitchTabPanelTitleBottomBoxSaveBold}>
                    ${saveAmount}
                  </Typography>
                </Typography>
              )
              :
              <>
              </>
          }
        </Box>
      </Box>
      <Divider />
      <Box className={classes.compareDialogSwitchTabPanelRebateRoot}>
        <Typography component={"span"} className={classes.compareDialogSwitchTabPanelRebateText}>
          ${qrPrice.base_price}  {getLang(lang, 'label.PER_QR_CODE')}
        </Typography>
      </Box>
      <Divider />
      <Box className={classes.compareDialogSwitchTabPanelMetadataRoot}>
        {
          subscriptionPlan.metadata.map(
            (md, i) => (
              <Box key={i}>
                <Typography className={md.is_valid ? classes.compareDialogSwitchTabPanelMetadataTitleActive : classes.compareDialogSwitchTabPanelMetadataTitle}>
                  {getLang(lang, 'db_label.' + md.group )}
                </Typography>
                <ul className={classes.compareDialogSwitchTabPanelMetadataUnorderList}>
                  {
                    md.data.map(
                      (d, j) => (
                        <Box key={j} >
                          {d.module !== "SMS credit" && (
                            <li >
                              <Typography className={ 
                                d.parts.length > 0
                                  ?
                                  d.is_valid ? classes.compareDialogSwitchTabPanelDataTitleActive : classes.compareDialogSwitchTabPanelDataTitle
                                  :
                                  d.is_valid ? classes.compareDialogSwitchTabPanelMetadataTextActive : classes.compareDialogSwitchTabPanelMetadataText
                              }>
                                {getLang(lang, 'db_label.' + d.module )}
                              </Typography>
                            </li>
                          )}
                          <ul className={classes.compareDialogSwitchTabPanelMetadataUnorderList}>
                            {
                              d.parts.map(
                                (p, k) => (
                                  <li key={k} >
                                    <Typography className={p.is_valid ? classes.compareDialogSwitchTabPanelMetadataTextActive : classes.compareDialogSwitchTabPanelMetadataText}>
                                    {getLang(lang, 'db_label.' + p.module )}
                                    </Typography>
                                  </li>
                                )
                              )
                            }
                          </ul>
                        </Box>
                      )
                    )
                  }
                </ul>
              </Box>
            )
          )
        }
      </Box>
    </Box>
  )
}

export default function ComparePlanDialogComponent({
  registrationType,
  userSubscriptionPlan,
  isCompareDialogOpen,
  subscriptionPlans,
  compareType,
  onCompareCloseClick,
  lang,
  onCompareTypeClick,
}) {
  const classes = useStyle();
  return (
    <Dialog
    open={isCompareDialogOpen}
      PaperProps={{
        style: {
          maxWidth: "none",
          width: "916px"
        },
      }}
    onClose={() => onCompareCloseClick()}>
      <ComparePlanDialogTitleBar compareSubscriptionType={compareType} onCompareSubscriptionTypeChange={onCompareTypeClick} onCompareCloseClick={onCompareCloseClick} lang={lang}  />
      <Divider className={classes.compareDialogTitleBarDivider} />
      <SwitchTabPanels registrationType={registrationType} userSubscriptionPlan={userSubscriptionPlan} subscriptionPlans={subscriptionPlans} compareSubscriptionType={compareType} lang={lang} />
    </Dialog>
  )
}