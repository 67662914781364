import React from "react";
import SummaryFormComponent from "./summaryForm.component";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import { selectQrCostCalculation } from "../../../redux";

function SummaryFormContainer({ children, handleNext, calculation }) {
  const handlePurchase = e => {
    e.preventDefault();
    handleNext();
  };
  const lang = useSelector(state => state.constant.languages);
  return (
    <SummaryFormComponent
      children={children}
      calculation={calculation}
      handlePurchase={handlePurchase}
      lang={lang}

    />
  );
}

SummaryFormContainer.propTypes = {
  handleNext: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  calculation: selectQrCostCalculation(state)
});

export default connect(mapStateToProps)(SummaryFormContainer);
