import axios from "axios";

const getAdtProductListing = (parameters) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/serialnumber/api/v1/additional-info/products`, {
        length: parameters.length,
        start: parameters.start,
        order: parameters.order,
        column: parameters.column,
        product_uuids: parameters.product_uuids,
        product_not_assigned: parameters.productNotAssigned,
        additional_info_uuids: parameters.additional_info_uuids,
        additional_info: parameters.additionalInfo,
        after_key: parameters.afterKey,
        display_lang: parameters.displayLang,
      })
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

export default getAdtProductListing;
