import React from "react";
import TotalResponseCardComponent from './totalResponseCard.component';
import { useSelector } from "react-redux";

export default function TotalResponseCardContainer({
  totalResponse
}) {
  const lang = useSelector(state => state.constant.languages);

  return (
    <TotalResponseCardComponent totalResponse={totalResponse} lang={lang}/>
  )
}