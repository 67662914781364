import React, { useEffect,useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addAlert, selectLoading } from "modules/notification";
import DealerDetailsFormComponent from "./rewardDetailsForm.component";
import {
  createReward,
  getAdminRewardBranchDropdown,
  getRewardBranchDropdown,
  resetRewardBranchDropdownData,
  setFormType,
  updateRewardDetails,
} from "modules/stockflow/redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { STOCKFLOW_REWARD_CENTER_ADD, STOCKFLOW_REWARD_CENTER_EDIT } from "lib/constants/accessRights";
import ConfirmationDialogComponent from "components/dialog/confirmation/confirmationDialog.component";
import { useHistory } from "react-router-dom";
import { REWARD_CENTER_PAGE } from "modules/stockflow/config/stockflow.route";
import { uploadFileToS3, uploadImageToS3, uploadJSONToS3 } from "app/feature";
import { hideBackdrop, showBackdrop } from "modules/backdrop";
import { isFile, isFileExceedLimit, isImage, isPDF } from "lib/helper";
// import { toBase64 } from "modules/product-mgmt/utils/productPage.util";
import { FolderType, UploadType } from "lib/constants/aws_s3";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { isNull } from "lodash";
import { getLang } from "app/feature/constants";
import { Paper, Box } from "@material-ui/core";
import  ImageEditorPanel  from "components/panel/rewardCenterPageImageEditorPanel";
import Modal from "../../modal";
import {setImgFormatAndSize} from "../../../../../lib/FileHelper";

export default function DealerDetailsFormContainer({
  handleEdit,
  reloadContent,
  image,
}) {
  const dispatch = useDispatch();
  const inputImgRef = useRef();
  const history = useHistory();
  const hasStockflowAddAccessRight = useSelector(state => state.profile.accessRights.includes(STOCKFLOW_REWARD_CENTER_ADD));
  const hasStockflowEditAccessRight = useSelector(state => state.profile.accessRights.includes(STOCKFLOW_REWARD_CENTER_EDIT));
  const lang = useSelector(state => state.constant.languages);
  const language = useSelector(state => state.profile.language);
  const isSubmitting = useSelector(state => selectLoading(state, createReward.typePrefix));
  const rewardValues = useSelector(state => state.stockflowRewardCenter.rewardDetails);
  const isLoadingBranchDropdown = useSelector(state => selectLoading(state, getRewardBranchDropdown.typePrefix));
  const isLoadingAdminBranchDropdown = useSelector(state => selectLoading(state, getAdminRewardBranchDropdown.typePrefix));
  const branchDropdown = useSelector(state => state.stockflowRewardCenter.dropdowns.branchDropdownData);
  const isHqAdmin = useSelector(state => state.profile.isHqAdmin);
  const id = useSelector((state) => state.session.idTokenPayload.sub);
  const [successDialogModal, setSuccessDialogModal] = useState(false);
  const formType = useSelector(state => state.stockflowRewardCenter.formType)

  const formRef = useRef();
  
  const [bufferImg, setBufferImg] = useState();
  const [isCroping, setCroping] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  //PDF Viewer
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  useEffect(() => {
    if (isHqAdmin) {
      dispatch(getAdminRewardBranchDropdown())
        .then(unwrapResult)
    }
    else {
      dispatch(getRewardBranchDropdown())
        .then(unwrapResult)
    }
    return () => {
      dispatch(resetRewardBranchDropdownData())
    }
  }, [isHqAdmin, dispatch])

  const changeToUpdate = (e) => {
    if (hasStockflowEditAccessRight) {
      e.preventDefault();
      dispatch(setFormType("edit"))
      handleEdit(true)
    }
  }

  const changeBackView = () => {
    dispatch(setFormType("view"))
    handleEdit(false)
  }

  const handleDeleteImage = (formik, e, input) => {
    e.preventDefault()
    if (!input) {
      formik.setFieldValue("coverImageUrl", "");
    }
    //dispatch(resetRewardImage());
  }

  const handleNavigateToRewardList = () => {
    setSuccessDialogModal(false);
    history.push(REWARD_CENTER_PAGE);
  }

  const imageUploadToS3 = async (imgUrl) => {
    let url = ""
    let base64Image = imgUrl;

    base64Image = await setImgFormatAndSize(base64Image, 2160, 2160, "image/webp");

    const uploadInfo = {
      uploadType: FolderType.stockflowRewardImage,
      base64Image: base64Image,
      fileName: `${uuidv4()}.png`,
      id: id
    }
    await dispatch(uploadImageToS3(uploadInfo))
      .then(unwrapResult)
      .then(imageUrl => {
        url = imageUrl

      })
      .catch(error => {
        dispatch(hideBackdrop());
        dispatch(
          addAlert({
            severity: "error",
            message: error.message || getLang(lang, 'message.error.ERROR_UPLOAD_PICTURE')
          })
        )
      })
    return url
  }

  const tncUploadToS3 = async (values) => {
    let url = "";
    if (values.tncType === "text") {
      const fileName = "JsonFile";

      const uploadJSONdata = {
        uploadType: UploadType.stockflowRewardTermAndCondition,
        data: values.tncText,
        id: id,
        fileName: `${Date.now()}${fileName}`
      }
      await dispatch(uploadJSONToS3(uploadJSONdata))
        .then(unwrapResult)
        .then((jsonUrl) => {
          url = jsonUrl
        })
        .catch(error => {
          dispatch(hideBackdrop());
          dispatch(
            addAlert({
              severity: "error",
              message: error.message || getLang(lang, 'message.error.ERROR_UPLOAD_TERMS_CONDITIONS')
            })
          )
        })
      return url
    }
    else {
      const fileName = values.tncFile.name;

      const uploadPDFdata = {
        uploadType: UploadType.stockflowRewardTermAndCondition,
        file: values.tncFile,
        id: id,
        fileName: `${fileName}_${Date.now()}${fileName}`
      }
      await dispatch(uploadFileToS3(uploadPDFdata))
        .then(unwrapResult)
        .then((termUrl) => {
          url = termUrl
        })
        .catch(error => {
          dispatch(hideBackdrop());
          dispatch(
            addAlert({
              severity: "error",
              message: error.message || getLang(lang, 'message.error.ERROR_UPLOAD_TERMS_CONDITIONS')
            })
          )
        })
      return url
    }
  }

  const handleSubmit = async (values) => {
    values = {
      ...values,
      nodeUuid: values.branches.nodeId,
      displayStartDateTime: moment(values.displayStartDateTime).format('YYYY-MM-DD HH:mm:ss'),
      displayEndDateTime: moment(values.displayEndDateTime).format('YYYY-MM-DD HH:mm:ss'),
      validityStartDateTime: moment(values.validityStartDateTime).format('YYYY-MM-DD HH:mm:ss'),
      validityEndDateTime: moment(values.validityEndDateTime).format('YYYY-MM-DD HH:mm:ss')
    }
    if (validateForm(values)) {
      return;
    }

    //UPLOAD IMAGE TO S3
    let urlTemp = ""
    let tncUrl = ""
    dispatch(showBackdrop());
    if (formType === "create" && hasStockflowAddAccessRight) {

      urlTemp = await imageUploadToS3(values.coverImageUrl)
      if (urlTemp.length > 0) {
        values.coverImageUrl = urlTemp.split("?")[0];
      }
      tncUrl = await tncUploadToS3(values)
      if (tncUrl.length > 0) {
        values.tncUrl = tncUrl.split("?")[0];
      }

      createRewardDetails(values)
    }

    if (formType === "edit" && hasStockflowEditAccessRight) {
      if (isFile(values.coverImageUrl)) {
        urlTemp = await imageUploadToS3(values.coverImageUrl)
        if (urlTemp.length > 0) {
          values.coverImageUrl = urlTemp.split("?")[0];
        }
      }
      if ((values.tncType === "pdf" && isFile(values.tncFile)) || values.tncType === "text"){
        tncUrl = await tncUploadToS3(values)
        if (tncUrl.length > 0) {
          values.tncUrl = tncUrl.split("?")[0];
        }
      }
      updateReward(values);
    }
  }

  const createRewardDetails = (values) => {
    dispatch(createReward(values))
      .then(unwrapResult)
      .then(() => {
        dispatch(hideBackdrop());
        setSuccessDialogModal(true);
      })
      .catch((error) => {
        dispatch(hideBackdrop());
        dispatch(
          addAlert({
            severity: "error",
            message: error.message || getLang(lang, 'message.error.ERROR_UPLOAD_IMAGES')
          })
        )
      })
  }

  const updateReward = (values) => {
    dispatch(updateRewardDetails(values))
      .then(unwrapResult)
      .then(() => {
        dispatch(hideBackdrop());
        setSuccessDialogModal(true);
      })
      .catch((error) => {
        dispatch(hideBackdrop());
        dispatch(
          addAlert({
            severity: "error",
            message: error.message || getLang(lang, 'message.error.ERROR_UPLOAD_IMAGES')
          })
        )
      })
  }

  const validateForm = (values) => {
    //Check Image File
    if (isNull(values.coverImageUrl)) {
      dispatch(
        addAlert({
          severity: "error",
          message: getLang(lang, 'message.error.PLEASE_UPLOAD_IMAGE')
        })
      );
      return true;
    }

    if (formType === "create") {
      // if (!isImage(values.coverImageUrl)) {
      //   dispatch(
      //     addAlert({
      //       severity: "error",
      //       message: getLang(lang, 'message.error.PLEASE_UPLOAD_VALID_IMAGE')
      //     })
      //   );
      //   return true;
      // }

      if (isFileExceedLimit(values.coverImageUrl.size, 6, 'MB')) {
        dispatch(
          addAlert({
            severity: "error",
            message: getLang(lang, 'message.error.FORM_VALIDATE_FILE_SIZE', {value: '6'})
          })
        );
        return true;
      }
    }

    //Check TnC Field
    if(values.tncType === "pdf") {
      if (formType === "create") {
        if (values.tncFile === null || !isPDF(values.tncFile)) {
          dispatch(addAlert({
            severity: "error",
            message: getLang(lang, 'message.error.PLEASE_UPLOAD_TNC_FILE')
          }));
          return true;
        }
        if (isPDF(values.tncFile) && isFileExceedLimit(values.termsConditionDetail, 2, "MB")) {
          dispatch(
            addAlert({
              severity: "error",
              message: getLang(lang, 'message.error.FORM_VALIDATE_FILE_SIZE', {value: '2'})
            })
          );
          return true;
        }
      }
      else {
        if (values.tncFile !== null){
          if(!isPDF(values.tncFile)) {
            dispatch(addAlert({
              severity: "error",
              message: getLang(lang, 'message.error.PLEASE_UPLOAD_TNC_IN_PDF')
            }));
            return true;
          }
          if (isPDF(values.tncFile) && isFileExceedLimit(values.termsConditionDetail, 2, "MB")) {
            dispatch(
              addAlert({
                severity: "error",
                message: getLang(lang, 'message.error.FORM_VALIDATE_FILE_SIZE', {value: '2'})
              })
            );
            return true;
          }
        } 
      }
    }
    else {
      if (values.tncText.value === '<p></p>' || values.tncText.value === '<p></p>\n') {
        dispatch(addAlert({
          severity: "error",
          message: getLang(lang, 'message.error.EMPTY_TNC"')
        }));
        return true;
      }
    }
    return false;
  }

  const handleReload = () => {
    changeBackView();
    reloadContent();
  }

  const handleTncPdfChangePage = (isAddPage) => {
    setPageNumber(pageNumber + (isAddPage ? 1 : -1))
  }


  const handleImgChange = e => {
    const file = inputImgRef.current.files[0];

    if (file) {
      const rawFile = e.target.files[0];

      if (!isImage(rawFile)) {
        addAlert({ severity: "error", message: getLang(lang,"message.error.INVALID_IMAGE_FORMAT") });

        return;
      }
      if (isFileExceedLimit(rawFile.size, 12, "MB")) {
        addAlert({
          severity: "error",
          message: getLang(lang,"message.error.FILE_TOO_LARGE")
        });

        return;
      }
      const image = URL.createObjectURL(rawFile);
      setBufferImg(image);
      setIsModalOpen(true);
      setCroping(true);
    }
  };


  const handleClose = () => {
    setBufferImg(image);
    setIsModalOpen(false)
  };

  const handleResult = async (image) => {
    setIsUploading(true);
    formRef.current.setFieldValue("coverImageUrl",image);
    setCroping(false);
    setIsModalOpen(false);
    setIsUploading(false);
  };

  return (
    <>
      <DealerDetailsFormComponent
        isSubmitting={isSubmitting}
        lang={lang}
        formType={formType}
        rewardValues={rewardValues}
        branchDropdown={branchDropdown}
        isLoading={isLoadingBranchDropdown || isLoadingAdminBranchDropdown}
        hasStockflowAddAccessRight={hasStockflowAddAccessRight}
        hasStockflowEditAccessRight={hasStockflowEditAccessRight}
        handleDeleteImage={handleDeleteImage}
        handleSubmit={handleSubmit}
        changeToUpdate={changeToUpdate}
        changeBackView={changeBackView}
        handleNavigateToRewardList={handleNavigateToRewardList}
        numPages={numPages}
        onDocumentLoadSuccess={onDocumentLoadSuccess}
        language={language}
        pageNumber={pageNumber}
        handleTncPdfChangePage={handleTncPdfChangePage}
        image={image}
        inputImgRef={inputImgRef}
        handleImgChange={handleImgChange}
        formRef={formRef}
      />
      
      <Modal isOpen={isModalOpen} handleClose={handleClose}>
        {isCroping && (
          <ImageEditorPanel
            isCircleRadius={false}
            height={375}
            width={375}
            aspectRatio={[1, 1]}
            img={bufferImg}
            handleCancel={handleClose}
            handleResult={handleResult}
          />
        )}
        {isUploading && (
          <Paper elevation={0}>
            <Box p={2}>
              <h3>{getLang(lang, "label.UPLOADING")}</h3>
            </Box>
          </Paper>
        )}
      </Modal>
      <ConfirmationDialogComponent
        isOpen={successDialogModal}
        handleProceed={formType === "create" ? handleNavigateToRewardList : handleReload}
        type="success"
        title={formType === "create" ? getLang(lang, 'label.REWARD_ADDED_SUCCESSFULLY') : getLang(lang, 'label.REWARD_UPDATED_SUCCESSFULLY')}
        rejectOption={false}
        proceedLabel={getLang(lang, 'label.ALRIGHT')}
      />
    </>
  )
}
