import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { generatePaginationOptions, updateUrlQueryParam } from "lib/helper";
import { selectLoading } from "modules/notification";
import {
  getDealerStockoutList,
  resetDealerStockoutListing,
  updateStockoutListTab,
} from "modules/stockflow/redux";
import moment from "moment";
import DealerStockoutListPanelComponent from "./dealerStockoutListPanel.component";
import {
  STOCKFLOW_STOCKOUT_VIEW,
  STOCKFLOW_DEALER_VIEW,
} from "lib/constants/accessRights";

function DealerStockoutListPanelContainer({ type }) {
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const history = useHistory();
  const id = match.params.dealerId;
  const lang = useSelector((state) => state.constant.languages);

  const isStockoutFetching = useSelector((state) =>
    selectLoading(state, getDealerStockoutList.typePrefix)
  );
  const hasStockflowStockoutViewAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(STOCKFLOW_STOCKOUT_VIEW)
  );
  const hasStockflowDealerViewAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(STOCKFLOW_DEALER_VIEW)
  );

  const totalFiltered = useSelector(
    (state) => state.stockflowDealer.stockoutList.list[`${type}`].totalFiltered
  );
  const totalRecords = useSelector(
    (state) => state.stockflowDealer.stockoutList.list[`${type}`].totalRecords
  );
  const stockoutList = useSelector(
    (state) => state.stockflowDealer.stockoutList.list[`${type}`].list
  );

  const isError = useSelector(
    (state) => state.stockflowDealer.stockoutList.list.isError
  );
  const isSearchResult = stockoutList.length < totalRecords;
  const [paginationOptions, setPaginationOptions] = useState([]);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isFilterUpdated, setIsFilterUpdated] = useState(false);
  const [tableFilter, setTableFilter] = useState({
    page: 0,
    rowsPerPage: 25,
    order: "desc",
    orderBy: "created_at",
    search: "",
    fromDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
    toDate: moment().format("YYYY-MM-DD"),
    stockoutTo: [],
    respondDate: null,
  });

  const tableFilterInitialRef = useRef(true);

  useEffect(() => {
    return () => {
      dispatch(resetDealerStockoutListing());
      dispatch(updateStockoutListTab("new"));
    };
  }, [dispatch]);

  useEffect(() => {
    const searchParam = new URLSearchParams(window.location.search);
    const isFilter = searchParam.has("is_filter");
    let filter = sessionStorage.getItem(`dealer_stockout_filter_${id}`);

    let temp = { ...tableFilter };
    if (isFilter && filter) {
      temp = JSON.parse(filter);
      setIsFilterUpdated(true);
      setIsFilterOpen(true);
    }

    if (isFilter) {
      updateUrlQueryParam(null, ["is_filter"]);
    }
    sessionStorage.removeItem(`dealer_stockout_filter_${id}`);

    setTableFilter(temp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!tableFilterInitialRef.current) {
      dispatch(updateStockoutListTab(type));
      dispatch(resetDealerStockoutListing());
      getData();
    }
    tableFilterInitialRef.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableFilter, type]);

  useEffect(() => {
    setPaginationOptions(generatePaginationOptions(totalFiltered));
  }, [totalFiltered]);

  const getData = () => {
    dispatch(
      getDealerStockoutList({
        id,
        length: tableFilter.rowsPerPage,
        start: tableFilter.rowsPerPage * tableFilter.page,
        order: tableFilter.order,
        order_by: tableFilter.orderBy,
        status: type,
        filter: {
          search: tableFilter.search,
          from_date: tableFilter.fromDate,
          to_date: tableFilter.toDate,
          stockout_to: tableFilter.stockoutTo,
          respond_date: tableFilter.respondDate,
        },
      })
    );
  };

  const handleChangePage = (event, newPage) => {
    let newData = {
      ...tableFilter,
      page: newPage,
    };
    setIsFilterUpdated(true);
    setTableFilter(newData);
  };

  const handleChangeRowsPerPage = (event) => {
    let newData = {
      ...tableFilter,
      page: 0,
      rowsPerPage: event.target.value,
    };
    setIsFilterUpdated(true);
    setTableFilter(newData);
  };

  const handleSearch = (filter) => {
    const { search, dateRange, respondDate, stockoutTo } = filter;

    let newData = {
      ...tableFilter,
      page: 0,
      search: search,
      fromDate: dateRange?.from
        ? moment(dateRange.from).format("YYYY-MM-DD")
        : "",
      toDate: dateRange?.to ? moment(dateRange.to).format("YYYY-MM-DD") : "",
      respondDate: respondDate,
      stockoutTo: stockoutTo,
    };

    setIsFilterUpdated(true);
    setTableFilter(newData);
  };

  const clearSearch = () => {
    let newData = {
      ...tableFilter,
      page: 0,
      search: "",
      fromDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
      toDate: moment().format("YYYY-MM-DD"),
      stockoutTo: [],
      respondDate: null,
    };

    setIsFilterUpdated(false);
    setTableFilter(newData);
  };

  const resetFilter = () => {
    let newData = {
      ...tableFilter,
      page: 0,
      respondDate: "",
      stockoutTo: [],
    };
    setIsFilterUpdated(true);
    setTableFilter(newData);
  };

  const changeOrder = (orderBy, order) => {
    let newData = {
      ...tableFilter,
      order,
      orderBy,
    };
    setIsFilterUpdated(true);
    setTableFilter(newData);
  };

  const handleViewDetail = (url) => {
    if (isFilterUpdated) {
      updateUrlQueryParam({ is_filter: true });
      sessionStorage.setItem(
        `dealer_stockout_filter_${id}`,
        JSON.stringify(tableFilter)
      );
    }
    history.push(url);
  };

  return (
    <DealerStockoutListPanelComponent
      hasStockflowStockoutViewAccessRight={hasStockflowStockoutViewAccessRight}
      hasStockflowDealerViewAccessRight={hasStockflowDealerViewAccessRight}
      id={id}
      type={type}
      stockouts={stockoutList}
      isFetching={isStockoutFetching}
      isError={isError}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      page={tableFilter.page}
      rowsPerPage={tableFilter.rowsPerPage}
      totalFiltered={totalFiltered}
      handleSearch={handleSearch}
      clearSearch={clearSearch}
      handleReload={getData}
      isSearchResult={isSearchResult}
      paginationOptions={paginationOptions}
      isFilterOpen={isFilterOpen}
      setIsFilterOpen={setIsFilterOpen}
      tableFilter={tableFilter}
      changeOrder={changeOrder}
      lang={lang}
      resetFilter={resetFilter}
      handleViewDetail={handleViewDetail}
    />
  );
}

export default DealerStockoutListPanelContainer;
