import React from "react";
import {
  makeStyles,
  Typography,
  Box,
  Paper,
  IconButton,
} from "@material-ui/core";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CloseIcon from "@material-ui/icons/Close";
import Masonry from "components/masonry/Masonry";
import { getLang } from "app/feature/constants";

const style = (theme) => ({
  root: {
    boxShadow: theme.shadows[5],
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "space-between",
    borderRadius: "4px",
    fontSize: 14,
    width: 600,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  header: {
    padding: "4px 0px",
    paddingLeft: theme.spacing(3),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  context: {
    display: "flex",
    alignItems: "center",
  },
  contextData: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1, 0, 0, 3),
  },
  container: {
    padding: theme.spacing(1, 3, 3, 3),
    backgroundColor: "#EBEDEE",
  },
  box: {
    display: "flex",
    flexFlow: "column",
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(1, 2),
  },
});

const useStyles = makeStyles(style);

export default function PerksFormComponent({ handleClose, feature, lang }) {
  const classes = useStyles();

  return (
    <Paper elevation={0} className={classes.root}>
      <div className={classes.header}>
        <Typography
          variant="subtitle2"
          color="primary"
          style={{ fontWeight: "bold" }}
        >
          {getLang(lang, "label.PERKS")}
        </Typography>
        <IconButton color="primary" onClick={handleClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </div>

      <Box className={classes.container}>
        <Masonry container>
          {Object.entries(feature)
            .sort((a, b) => {
              return b[1] - a[1];
            })
            .map(([label, value], index) => {
              return (
                <Masonry item key={index} style={{ padding: "5px" }}>
                  <div className={classes.box}>
                    <Box className={classes.context}>
                      <CheckCircleOutlineIcon
                        style={{
                          color: value.is_valid ? "#1FD026" : "#ECEFF0",
                        }}
                      />

                      <Typography
                        variant="body2"
                        color="primary"
                        style={{ paddingLeft: "6px" }}
                      >
                        {getLang(lang, 'db_label.'+value.group)}
                      </Typography>
                    </Box>

                    {value.data &&
                      value.data.map((field, index) => {
                        return (
                          <Box className={classes.contextData} key={index}>
                            <CheckCircleOutlineIcon
                              style={{
                                color: value.is_valid ? "#1FD026" : "#ECEFF0",
                              }}
                            />

                            <Typography
                              variant="body2"
                              color="primary"
                              style={{ paddingLeft: "6px" }}
                              key={index}
                            >
                              {getLang(lang, 'db_label.'+field.module)}
                            </Typography>
                          </Box>
                        );
                      })}
                  </div>
                </Masonry>
              );
            })}
        </Masonry>
      </Box>
    </Paper>
  );
}
