import React, { useState } from "react";
import LuckyDrawListingTableComponent from "./luckyDrawListingTable.component";
import { useDispatch, useSelector } from "react-redux";
import {
  setType,
  fetchLuckyDrawDetail,
  fetchJSONURL,
  resetLuckyDrawDetail,
  resetRegisterFormType,
  resetRegisterForm,
  fetchAllLuckyDrawPrize,
  setLuckyDrawID,
  setProgress,
  setLuckyDrawTitle,
  changeLuckyDrawProgress,
  setLiveDraw,
  closeLuckyDrawPrizeDialog,
  resetExcludeList
} from "../../../redux";
import { hideBackdrop, showBackdrop } from "modules/backdrop";
import LuckyDrawDetailDialog from "../../dialog/luckyDrawDetailDialog";
import LuckyDrawPrizeDialog from "../../dialog/luckyDrawPrizeDialog";
import WinnerDialog from "../../dialog/setWinnerDialog";
import { ROOT } from "../../../config/lucky-draw.route";
import { unwrapResult } from "@reduxjs/toolkit";
import { useHistory } from "react-router-dom";
import { LIVE_DRAW } from "app/config/route";
import getWinnerExcel from "app/api/luckyDraw/getWinnerExcel.api";
import ConfirmationDialogComponent from "components/dialog/confirmation";
import { progressStatus } from "lib/constants/luckyDrawProgress";
import { getLang } from "app/feature/constants";

export default function LuckyDrawListingTableContainer({
  hasLuckyDrawAddAccessRight,
  hasLuckyDrawEditAccessRight,
  hasLuckyDrawActivateOrDeactivateAccessRight,
  data,
  isFetching,
  page,
  search,
  rowsPerPage,
  totalRecords,
  handleChangePage,
  handleChangeRowsPerPage,
  language,
  handleCreate,
  handleStatusChange,
  handleFetchLuckyDrawList,
  paginationOptions,
  lang
}) {
  const dispatch = useDispatch();
  const [isModalOpen, setModalOpen] = useState(false);
  const [isPrizeModalOpen, setPrizeModalOpen] = useState(false);
  const [isWinnerModalOpen, setWinnerModalOpen] = useState(false);
  const [luckyDrawProgress, setLuckyDrawProgress] = useState("");
  const [confirmationDialogModal, setConfirmationDialogModal] = useState(false);
  const accessToken = useSelector((state) => state.session.accessToken);
  const history = useHistory();
  const isLoadingLuckyDrawError = useSelector(state => state.luckyDraw.isLoadingLuckyDrawError)
  const luckyDrawId = useSelector(
    (state) => state.luckyDraw.LuckyDrawDetail.id
  );
  const progress = useSelector(
    (state) => state.luckyDraw.LuckyDrawDetail.progress
  );

  const handleView = async (id) => {
    dispatch(setType({ index: -1, type: "view" }));
    dispatch(showBackdrop());
    dispatch(fetchLuckyDrawDetail(id))
      .then(unwrapResult)
      .then(async (response) => {
        if (response.tncUrl.substr(response.tncUrl.length - 3) !== "pdf") {
          await dispatch(fetchJSONURL(response.tncUrl))
            .then(unwrapResult)
            .then(() => {
              dispatch(hideBackdrop());
              setModalOpen(true);
            });
        } else {
          dispatch(hideBackdrop());
          setModalOpen(true);
        }
      });
  };

  const closeModal = () => {
    dispatch(resetLuckyDrawDetail());
    dispatch(resetRegisterForm());
    dispatch(resetRegisterFormType());

    setModalOpen(false);
  };

  async function handleViewParticipants(id) {
    history.push(`${ROOT}/View/${id}`);
  }

  const closeDialogModal = () => {
    setPrizeModalOpen(false);
    setWinnerModalOpen(false);
    dispatch(closeLuckyDrawPrizeDialog());
    dispatch(resetExcludeList())  
  };

  async function handleViewPrize(id, progress) {
    dispatch(showBackdrop());
    const fetchPrizeTableInfo = {
      luckyDrawId: id,
      column: "order",
      order: "asc"
    };
    await dispatch(fetchAllLuckyDrawPrize(fetchPrizeTableInfo))
      .then(unwrapResult)
      .then(() => {
        dispatch(hideBackdrop());
        setLuckyDrawProgress(progress);
        setPrizeModalOpen(true);
      });
  }

  async function handleViewWinner(id, progress) {
    dispatch(showBackdrop());
    dispatch(setType({ index: 2, type: "view" }));
    const fetchPrizeTableInfo = {
      luckyDrawId: id,
      column: "order",
      order: "asc",
    };
    await dispatch(fetchAllLuckyDrawPrize(fetchPrizeTableInfo))
      .then(unwrapResult)
      .then(async () => {
        dispatch(hideBackdrop());
        await dispatch(setLuckyDrawID({ id: id }));
        await dispatch(setProgress({ progress: progress }));
        setWinnerModalOpen(true);
      });
  }

  const handleLiveDraw = (id, progress, luckyDrawTitle) => {
    if(progress === progressStatus.published){
      const fetchPrizeTableInfo = {
        luckyDrawId: id,
        column: "order",
        order: "asc"
      };
      dispatch(fetchAllLuckyDrawPrize(fetchPrizeTableInfo))
        .then(unwrapResult)
        .then(res => {
          dispatch(setLiveDraw(res))
          dispatch(setProgress({ progress: progress }));
          history.push(LIVE_DRAW)
        })
    }else{
      setConfirmationDialogModal(true);
      dispatch(setLuckyDrawID({ id: id }));
      dispatch(setLuckyDrawTitle({ luckyDrawTitle: luckyDrawTitle }));
    }
  };

  async function handleLiveProgress() {
    if (progress !== progressStatus.published) {
      await dispatch(
        changeLuckyDrawProgress({
          id: luckyDrawId,
          progress: progressStatus.published,
        })
      ).finally(() => {
        setConfirmationDialogModal(false);
        const fetchPrizeTableInfo = {
          luckyDrawId: luckyDrawId,
          column: "order",
          order: "asc"
        };

        dispatch(setProgress({ progress: progressStatus.published }));
        dispatch(fetchAllLuckyDrawPrize(fetchPrizeTableInfo))
          .then(unwrapResult)
          .then(res => {
            dispatch(setLiveDraw(res))
            history.push(LIVE_DRAW)
          })
      });
    }
  }

  const handleDownloadWinner = (id) => {
    getWinnerExcel.get({ id: id, token: accessToken });
  };

  return (
    <>
      <LuckyDrawListingTableComponent
        hasLuckyDrawAddAccessRight={hasLuckyDrawAddAccessRight}
        hasLuckyDrawEditAccessRight={hasLuckyDrawEditAccessRight}
        hasLuckyDrawActivateOrDeactivateAccessRight={hasLuckyDrawActivateOrDeactivateAccessRight}
        data={data}
        isFetching={isFetching}
        page={page}
        search={search}
        rowsPerPage={rowsPerPage}
        totalRecords={totalRecords}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleCreate={handleCreate}
        handleView={handleView}
        handleStatusChange={handleStatusChange}
        handleViewParticipants={handleViewParticipants}
        handleViewPrize={handleViewPrize}
        handleViewWinner={handleViewWinner}
        handleLiveDraw={handleLiveDraw}
        handleDownloadWinner={handleDownloadWinner}
        language={language}
        handleFetchLuckyDrawList={handleFetchLuckyDrawList}
        isLoadingLuckyDrawError={isLoadingLuckyDrawError}
        paginationOptions={paginationOptions}
        lang={lang}
      />
      <LuckyDrawDetailDialog isOpen={isModalOpen} handleClose={closeModal} />
      <LuckyDrawPrizeDialog
        isOpen={isPrizeModalOpen}
        handleClose={closeDialogModal}
        luckyDrawProgress={luckyDrawProgress}
      />
      <WinnerDialog isOpen={isWinnerModalOpen} handleClose={closeDialogModal} />
      <ConfirmationDialogComponent
        isOpen={confirmationDialogModal}
        handleClose={() => setConfirmationDialogModal(false)}
        handleProceed={handleLiveProgress}
        type={"success"}
        remark={getLang(lang,"paragraph.PUBLISH_LD_REMARK")}
        description={getLang(lang,"paragraph.PLEASE_CONFIRM")}
        title={getLang(lang,"paragraph.PUBLISH_LD_QUESTION")}
      />
    </>
  );
}
