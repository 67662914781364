import { createSlice } from "@reduxjs/toolkit";
import { REPORTING } from "modules/reporting/constants";
import {
    getPlatformData,
    getBrowserData,
    getDeviceData,
    getLanguageData,
} from "../action";

const initialState = {
    platformDatalist: {},
    browserDatalist: {},
    deviceDatalist: {},
    languageDatalist: {}
}

const reportingMiscellaneousSlice = createSlice({
    name: REPORTING,
    initialState,
    reducers: {
        initReportingMiscellaneous: (state) => {
            state.platformDatalist = initialState.platformDatalist;
            state.browserDatalist = initialState.browserDatalist;
            state.deviceDatalist = initialState.deviceDatalist;
            state.languageDatalist = initialState.languageDatalist;
        }
    },
    extraReducers: {
                // MISCELLANEOUS -- START
                [getPlatformData.fulfilled]: (state, action) => {
                    let data = action.payload.data;
                    data.isSuccess = action.payload.success;
                    data.noResult = (data.datasets.length === 0);
                    // data.noResult = false;
        
                    state.platformDatalist = data;
                },
                [getPlatformData.rejected]: (state, action) => {
                    state.platformDatalist = {
                        isSuccess: false,
                        noResult: true,
                        datasets: [],
                        labels: []
                    }
                },
                [getBrowserData.fulfilled]: (state, action) => {
                    let data = action.payload.data;
                    data.isSuccess = action.payload.success;
                    data.noResult = (data.datasets.length === 0);
                    // data.noResult = false;
        
                    state.browserDatalist = data;
                },
                [getBrowserData.rejected]: (state, action) => {
                    state.browserDatalist = {
                        isSuccess: false,
                        noResult: true,
                        datasets: [],
                        labels: []
                    }
                },
                [getDeviceData.fulfilled]: (state, action) => {
                    let data = action.payload.data;
                    data.isSuccess = action.payload.success;
                    data.noResult = (data.datasets.length === 0);
                    // data.noResult = false;
        
                    state.deviceDatalist = data;
                },
                [getDeviceData.rejected]: (state, action) => {
                    state.deviceDatalist = {
                        isSuccess: false,
                        noResult: true,
                        datasets: [],
                        labels: []
                    }
                },
                [getLanguageData.fulfilled]: (state, action) => {
                    let data = action.payload.data;
                    data.isSuccess = action.payload.success;
                    data.noResult = (data.datasets.length === 0);
                    // data.noResult = false;
        
                    state.languageDatalist = data;
                },
                [getLanguageData.rejected]: (state, action) => {
                    state.languageDatalist = {
                        isSuccess: false,
                        noResult: true,
                        datasets: [],
                        labels: []
                    }
                },
                // MISCELLANEOUS -- END
    }
})

export const {
    initReportingMiscellaneous
} = reportingMiscellaneousSlice.actions;

export const reportingMiscellaneousReducer = reportingMiscellaneousSlice.reducer;