import React, { useState } from "react";
import AttributeSelectComponent from './attributeSelect.component';
import AttributeSelectPanelComponent from './attributeSelectPanel.component';
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from '@reduxjs/toolkit';
import { selectLoading } from 'modules/notification';
import CreateAttributesDialog from 'modules/product-mgmt/components/dialog/createAttributesDialog';
import { addAttribute, getAttributeDropdown } from "modules/product-mgmt/redux/action/attribute.action";

export default function AttributeSelectContainer({
  searchable = false,
  onChange,
  value,
  disabled,
  style,
  handleItemSearch,
  isFetching,
  placeholder,
  panelStyle,
  dropdownItem,
  emptyMessage,
  anchorOrigin,
  transformOrigin,
  PaperProps,
  textStyle,
  isEmpty,
  isClearable,
  handleClear,
  handleChipDelete
}) {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const [openChildList, setOpenChildList] = useState("")
  const isSubmittingAddModel = useSelector((state) => selectLoading(state, addAttribute.typePrefix))
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false)
  const lang = useSelector(state => state.constant.languages);

  const handleSubmitAttribute = (value) => {
    dispatch(addAttribute({
      name: value.attribute,
      options: value.options
    }))
      .then(unwrapResult)
    setIsCreateDialogOpen(false)
  }
  const handleCreateAttributeModel = (value) => {
    setIsCreateDialogOpen(value)
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
    dispatch(getAttributeDropdown({
      search:""
    }))
  }

  const handleClose = () => {
    setAnchorEl(null)
    if (searchable)
      handleItemSearch("")
  }

  const handleChange = (item, option) => {
    let selected = value.find((attribute) => attribute.optionsId === option.id)
    let itemTemp = {}

    if (selected === undefined) {
      itemTemp = {
        id: item.id,
        name: item.name,
        options: option.value,
        optionsId: option.id,
        delete: false,
      }
    } else {
      itemTemp = {
        id: item.id,
        name: item.name,
        options: option.value,
        optionsId: option.id,
        delete: true,
      }
    }

    onChange(itemTemp)
  }

  const handleOpenChildList = (value, type) => {
    if (type === "more") {
      setOpenChildList(value)
    } else {
      setOpenChildList("")
    }

  }

  const selectedItem = (option) => {
    let selected = value.find((item) => item.optionsId === option.id)

    if (selected === undefined) {
      return false
    }

    if (selected.optionsId === option.id) {
      return true
    } else {
      return false
    }
  }

  return (
    <>
      <AttributeSelectComponent
        handleClick={handleClick}
        handleClear={handleClear}
        handleChipDelete={handleChipDelete}
        value={value}
        isEmpty={isEmpty}
        style={style}
        disabled={disabled}
        placeholder={placeholder}
        id={id}
        open={open}
        textStyle={textStyle}
        isClearable={isClearable}
      />
      <AttributeSelectPanelComponent
        anchorEl={anchorEl}
        open={open}
        handleClose={handleClose}
        style={panelStyle}
        items={dropdownItem}
        handleSearchItem
        isFetching={isFetching}
        searchable={searchable}
        handleChange={handleChange}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        PaperProps={PaperProps}
        openChildList={openChildList}
        handleOpenChildList={handleOpenChildList}
        value={value}
        emptyMessage={emptyMessage}
        selected={selectedItem}
        handleAddAttributeModel={() => {
          setIsCreateDialogOpen(true);
          handleClose()
        }}
        lang={lang}
        handleSearch={handleItemSearch}
      />
      <CreateAttributesDialog
        isOpen={isCreateDialogOpen}
        handleClose={() => {
          handleCreateAttributeModel(false)
        }}
        handleSubmit={handleSubmitAttribute}
        isSubmittingAddOption={isSubmittingAddModel}
      />
    </>
  )
}