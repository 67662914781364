import { selectLoading } from "modules/notification";
import { getCampaignSummaryData } from "modules/reporting/redux";
import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CampaignSummaryPanel from './campaignSummaryPanel.component';

export default function CampaignSummaryPanelContainer({
    urlCampaignSummary,
    currentFilter,
    translate
}){
    const dispatch = useDispatch();

    // (1) get campaign summary -- start
    const isFetchingCampaignSummaryData = useSelector(state => selectLoading(state, getCampaignSummaryData.typePrefix));
    const campaignSummaryData = useSelector(state => state.reporting.campaign.campaignSummaryData);
    // (1) get campaign summary -- end

    useEffect(() => {
        const payload = {
            period: currentFilter.period,
            start_date: moment(currentFilter.dateRange[0]).format('YYYY-MM-DD'),
            end_date: moment(currentFilter.dateRange[1]).format('YYYY-MM-DD'),
            url: urlCampaignSummary,
            campaign_uuid: currentFilter.campaignUuid,
            campaign_type: currentFilter.campaignType,
        }

        dispatch(getCampaignSummaryData(payload));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentFilter, dispatch])

    return (
        <CampaignSummaryPanel 
            status={{
                isFetchingCampaignSummaryData
            }}

            data={{
                campaignSummaryData
            }}

            currentFilter={currentFilter}

            translate={translate}
        />
    )
}