import { selectLoading } from "modules/notification";
import {  
    getDealerStockoutByBranchData, 
    getDealerStockOutTrending, 
    getItemTotalPackageTrending, 
    getItemTotalRejectTrending, 
    getItemTotalRequestTrending, 
    getNewDealerTrending, 
    getRequestStockoutTrending, 
    getTotalPackageTrending, 
    getTotalRecalledPackageTrending, 
    getTotalRecallTrending 
} from "modules/reporting/redux";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DealershipPanel from './dealershipPanel.component';


export default function DealershipPanelContainer({
    currentFilter,
    // urlActiveDealer,
    // urlInactiveDealer,
    // urlDealerStockOut,
    // urlTotalRecall,
    urlNewDealer,
    // urlDistributionPerformance,

    urlItemTotalPackage,
    urlItemTotalRequest,
    urlItemTotalReject,

    urlDealerStockoutBranch,
    urlRequestStockout,
    urlTotalRecalledPackage,
    urlTotalPackage,
    translate,
    pageType
}){
    const dispatchTrending = useDispatch();
    const dispatchStockout = useDispatch();

    // (1) trending data -- start
    // (a) get trending: active dealer
    // const isFetchingActiveDealerTrending = useSelector(state => selectLoading(state, getActiveDealerTrending.typePrefix));
    // const activeDealerTrendingData = useSelector(state => state.reporting.trending.activeDealerData);

    // (b) get trending: inactive dealer
    // const isFetchingInactiveDealerTrending = useSelector(state => selectLoading(state, getInactiveDealerTrending.typePrefix));
    // const inactiveDealerTrendingData = useSelector(state => state.reporting.trending.inactiveDealerData);

    // (c) get trending: dealer stock out
    const isFetchingDealerStockOutTrending = useSelector(state => selectLoading(state, getDealerStockOutTrending.typePrefix));
    const dealerStockOutTrendingData = useSelector(state => state.reporting.trending.dealerStockOutData);

    // (d) get trending: total recall
    const isFetchingTotalRecallTrending = useSelector(state => selectLoading(state, getTotalRecallTrending.typePrefix));
    const totalRecallTrendingData = useSelector(state => state.reporting.trending.totalRecallData);

    // (e) get trending: new dealer
    const isFetchingNewDealerTrending = useSelector(state => selectLoading(state, getNewDealerTrending.typePrefix));
    const newDealerTrendingData = useSelector(state => state.reporting.trending.newDealerData);

    // (f) get trending: distribution performance
    // const isFetchingTotalDistributionPerformance = useSelector(state => selectLoading(state, getTotalDistributionPerformance.typePrefix));
    // const totalDistributionPerformanceData = useSelector(state => state.reporting.trending.totalDistributionPerformanceData);

    // (g) get trending: request stockout
    const isFetchingRequestStockoutTrending = useSelector(state => selectLoading(state, getRequestStockoutTrending.typePrefix));
    const requestStockoutTrendingData = useSelector(state => state.reporting.trending.requestStockoutData);

    // (h) get trending: total package
    const isFetchingTotalPackageTrending = useSelector(state => selectLoading(state, getTotalPackageTrending.typePrefix));
    const totalPackageTrendingData = useSelector(state => state.reporting.trending.totalPackageData);

    // (i) get trending: total recalled package
    const isFetchingTotalRecalledPackageTrending = useSelector(state => selectLoading(state, getTotalRecalledPackageTrending.typePrefix));
    const totalRecalledPackageTrendingData = useSelector(state => state.reporting.trending.totalRecalledPackageData);

    // (j) get trending: item total package
    const isFetchingItemTotalPackageTrending = useSelector(state => selectLoading(state, getItemTotalPackageTrending.typePrefix));
    const itemTotalPackageTrendingData = useSelector(state => state.reporting.trending.itemTotalPackageData);

    // (k) get trending: item total request
    const isFetchingItemTotalRequestTrending = useSelector(state => selectLoading(state, getItemTotalRequestTrending.typePrefix));
    const itemTotalRequestTrendingData = useSelector(state => state.reporting.trending.itemTotalRequestData);

    // (l) get trending: item total reject
    const isFetchingItemTotalRejectTrending = useSelector(state => selectLoading(state, getItemTotalRejectTrending.typePrefix));
    const itemTotalRejectTrendingData = useSelector(state => state.reporting.trending.itemTotalRejectData);

    // (1) trending data -- end

    // (2) stockout branch data -- start
    const isFetchingDealerStockoutByBranch = useSelector(state => selectLoading(state, getDealerStockoutByBranchData.typePrefix));
    const dealerStockoutByBranchData = useSelector(state => state.reporting.stockout.dealer.stockoutByBranchData);
    // (2) stockout branch data -- end

    useEffect(() => {

        if(!(currentFilter.categoryUuid || currentFilter.brandUuid || currentFilter.modelUuid)){
            dispatchTrending(getNewDealerTrending({
                branch_uuid: currentFilter.branchUuid,
                category_uuid: currentFilter.categoryUuid,
                brand_uuid: currentFilter.brandUuid,
                model_uuid: currentFilter.modelUuid,
                product_uuid: currentFilter.productUuid,
                period: currentFilter.period,
                start_date: moment(currentFilter.dateRange[0]).format('YYYY-MM-DD'),
                end_date: moment(currentFilter.dateRange[1]).format('YYYY-MM-DD'),
                url: urlNewDealer
            }));

            dispatchTrending(getTotalRecalledPackageTrending({
                branch_uuid: currentFilter.branchUuid,
                category_uuid: currentFilter.categoryUuid,
                brand_uuid: currentFilter.brandUuid,
                model_uuid: currentFilter.modelUuid,
                product_uuid: currentFilter.productUuid,
                period: currentFilter.period,
                start_date: moment(currentFilter.dateRange[0]).format('YYYY-MM-DD'),
                end_date: moment(currentFilter.dateRange[1]).format('YYYY-MM-DD'),
                url: urlTotalRecalledPackage
            }))
    
            dispatchTrending(getRequestStockoutTrending({
                branch_uuid: currentFilter.branchUuid,
                category_uuid: currentFilter.categoryUuid,
                brand_uuid: currentFilter.brandUuid,
                model_uuid: currentFilter.modelUuid,
                product_uuid: currentFilter.productUuid,
                period: currentFilter.period,
                start_date: moment(currentFilter.dateRange[0]).format('YYYY-MM-DD'),
                end_date: moment(currentFilter.dateRange[1]).format('YYYY-MM-DD'),
                url: urlRequestStockout
            }));
    
            dispatchTrending(getTotalPackageTrending({
                branch_uuid: currentFilter.branchUuid,
                category_uuid: currentFilter.categoryUuid,
                brand_uuid: currentFilter.brandUuid,
                model_uuid: currentFilter.modelUuid,
                product_uuid: currentFilter.productUuid,
                period: currentFilter.period,
                start_date: moment(currentFilter.dateRange[0]).format('YYYY-MM-DD'),
                end_date: moment(currentFilter.dateRange[1]).format('YYYY-MM-DD'),
                url: urlTotalPackage
            }));
        }

        if(currentFilter.categoryUuid || currentFilter.brandUuid || currentFilter.modelUuid){
            dispatchTrending(getItemTotalPackageTrending({
                branch_uuid: currentFilter.branchUuid,
                category_uuid: currentFilter.categoryUuid,
                brand_uuid: currentFilter.brandUuid,
                model_uuid: currentFilter.modelUuid,
                period: currentFilter.period,
                start_date: moment(currentFilter.dateRange[0]).format('YYYY-MM-DD'),
                end_date: moment(currentFilter.dateRange[1]).format('YYYY-MM-DD'),
                url: urlItemTotalPackage
            }))

            dispatchTrending(getItemTotalRequestTrending({
                branch_uuid: currentFilter.branchUuid,
                category_uuid: currentFilter.categoryUuid,
                brand_uuid: currentFilter.brandUuid,
                model_uuid: currentFilter.modelUuid,
                period: currentFilter.period,
                start_date: moment(currentFilter.dateRange[0]).format('YYYY-MM-DD'),
                end_date: moment(currentFilter.dateRange[1]).format('YYYY-MM-DD'),
                url: urlItemTotalRequest
            }))

            dispatchTrending(getItemTotalRejectTrending({
                branch_uuid: currentFilter.branchUuid,
                category_uuid: currentFilter.categoryUuid,
                brand_uuid: currentFilter.brandUuid,
                model_uuid: currentFilter.modelUuid,
                period: currentFilter.period,
                start_date: moment(currentFilter.dateRange[0]).format('YYYY-MM-DD'),
                end_date: moment(currentFilter.dateRange[1]).format('YYYY-MM-DD'),
                url: urlItemTotalReject
            }))
        }


    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatchTrending, currentFilter]);

    const [tableFilter, setTableFilter] = useState({
        page: 0,
        rowsPerPage: 10,
        search: "",
        searchBy: "name",
        orderBy: "branch_id",
        orderDirection: "asc",
    })

    useEffect(() => {
        dispatchStockout(getDealerStockoutByBranchData({
            length: tableFilter.rowsPerPage,
            start: tableFilter.page * tableFilter.rowsPerPage,
            orderBy: tableFilter.orderBy,
            orderDirection: tableFilter.orderDirection,
            search: tableFilter.search,
            searchBy: tableFilter.searchBy,
            branch_uuid: currentFilter.branchUuid,
            category_uuid: currentFilter.categoryUuid,
            brand_uuid: currentFilter.brandUuid,
            model_uuid: currentFilter.modelUuid,
            product_uuid: currentFilter.productUuid,
            period: currentFilter.period,
            start_date: moment(currentFilter.dateRange[0]).format('YYYY-MM-DD'),
            end_date: moment(currentFilter.dateRange[1]).format('YYYY-MM-DD'),
            url: urlDealerStockoutBranch
        }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatchStockout, tableFilter, currentFilter]);

    const handleChangePage = (e, newPage) => {
        setTableFilter((prev) => ({
          ...prev,
          page: newPage,
        }))
    }

    const handleChangeRowPerPage = (event) => {
        setTableFilter((prev) => ({
          ...prev,
          page: 0,
          rowsPerPage: event.target.value
        }))
    }

    const handleRequestSort = (event, property) => {
        const isAsc = tableFilter.orderBy === property && tableFilter.orderDirection === 'asc';
        setTableFilter((prev) => ({
            ...prev,
            orderDirection: isAsc ? 'desc' : 'asc',
            orderBy: property,
        }))
    }

    return (
        <DealershipPanel 
            status={{
                // isFetchingActiveDealerTrending,
                // isFetchingInactiveDealerTrending,
                isFetchingDealerStockOutTrending,
                isFetchingTotalRecallTrending,
                isFetchingNewDealerTrending,
                // isFetchingTotalDistributionPerformance,
                isFetchingDealerStockoutByBranch,
                isFetchingRequestStockoutTrending,
                isFetchingTotalPackageTrending,
                isFetchingTotalRecalledPackageTrending,
                isFetchingItemTotalPackageTrending,
                isFetchingItemTotalRequestTrending,
                isFetchingItemTotalRejectTrending
            }}

            data={{
                // activeDealerTrendingData,
                // inactiveDealerTrendingData,
                dealerStockOutTrendingData,
                totalRecallTrendingData,
                newDealerTrendingData,
                // totalDistributionPerformanceData,
                dealerStockoutByBranchData,
                requestStockoutTrendingData,
                totalRecalledPackageTrendingData,
                totalPackageTrendingData,
                itemTotalPackageTrendingData,
                itemTotalRequestTrendingData,
                itemTotalRejectTrendingData
            }}

            sort={{
                valueToOrderBy: tableFilter.orderBy,
                orderDirection: tableFilter.orderDirection,
                handleRequestSort: handleRequestSort,
            }}

            pagination={{
                handleChangePage: handleChangePage,
                handleChangeRowPerPage: handleChangeRowPerPage,
                page: tableFilter.page,
                rowsPerPage: tableFilter.rowsPerPage,
                rowsPerPageOptions: [5, 10, 25],
            }}

            translate={translate}

            currentFilter={currentFilter}

            pageType={pageType}
        />
    )
}