import axios from "axios";

const endpoint_url = "/account/api/v1/profile";

const put = body => {
  return new Promise((resolve, reject) => {
    axios
      .put(endpoint_url, body)
      .then(response => {
        return resolve(response.data);
      })
      .catch(error => {
        return reject(error.response.data);
      });
  });
};

const get = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(endpoint_url)
      .then(response => {
        return resolve(response.data);
      })
      .catch(error => {
        return reject(error.response.data);
      });
  });
};

const profile = {
  put,
  get
};

export default profile;
