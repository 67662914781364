import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";

import {
    DonutChart,
    LineChart,
    // HorizontalBarChart,
    VerticalBarChart,
} from "modules/reporting/components/charts";

const useStyles = makeStyles({
    lineChartCard: {
        padding: "20px",
        backgroundColor: "#FFFFFF",
        borderRadius: "12px",
        border: "1px solid #D0D5DD",
        height: "100%",
        maxHeight: "483px",
        overflowY: "auto",
        overflowX: "hidden",
    },
    lineChartCard__title : {
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "20px",
        color: "#101828",
    }
});


export default function ChartContainer({ title, payload, type, translate,language }) {
    const classes = useStyles();

    return (
        <Box className={classes.lineChartCard}>
            {title && <Typography className={classes.lineChartCard__title}>
                {title}
            </Typography>}

            {/* { type === 'bar' && <HorizontalBarChart payload={payload} /> } */}
            { type === 'bar' && <VerticalBarChart translate={translate} language={language} payload={payload} stacked /> }
            { type === 'line' && <LineChart translate={translate} language={language} payload={payload} /> }
            { type === 'donut' && <DonutChart translate={translate} language={language} payload={payload} />} 
        </Box>
    )
}