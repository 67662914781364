import axios from "axios";

const put = ({ id, state, remarks }) => {
  const body = {
    state: state,
    remarks: remarks
  };

  return new Promise((resolve, reject) => {
    axios
      .put(`/account/api/v1/users/${id}/state`, body)
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

const changeStatus = {
  put
};

export default changeStatus;
