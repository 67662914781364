import getNewEmployee from './getNewEmployee.api';
import getOnlineEmployee from "./getOnlineEmployee.api";
import getRoleDropdown from "./getRoleDropdown.api";
import employee from "./employee.api";
import getAllEmployee from "./getAllEmployee.api";
import getBranchDropdown from "./getBranchDropdown.api";
import viewEmployeeTransaction from "./viewEmployeeTransaction.api";
import getFilterBranch from "./getFilterBranch.api";
import resendEmail from "./resendEmail.api";
import changeEmployeeStatus from "./changeEmployeeStatus.api";
import checkEmailExists from "./checkEmailExists.api"

const employeeApi = {
  getNewEmployee,
  getOnlineEmployee,
  getRoleDropdown,
  employee,
  getAllEmployee,
  getBranchDropdown,
  viewEmployeeTransaction,
  getFilterBranch,
  resendEmail,
  changeEmployeeStatus,
  checkEmailExists
};

export default employeeApi;
