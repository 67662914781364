import React from "react";
import {
  makeStyles,
  ButtonBase,
  Typography,
  Button,
  Box
} from "@material-ui/core";
import ClearIcon from '@material-ui/icons/Clear';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { isObject, isArray } from "lib/helper"

const useStyles = makeStyles(() => ({
  root: {
    padding: "5px 12px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#eceff0",
    borderRadius: 4,
    width: "100%",
    height: 43,
    overflow: "hidden"
  },
  inputText: {
    fontSize: "1rem",
    color: "#a4a6a8",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  },
  filled: {
    color: "rgba(0, 0, 0, 0.87)",
  },
  clearButton: {
    minWidth: 0
  }
}))

function BrandModelSingleSelectComponent({
  handleClick,
  handleClear,
  brandValue,
  modelValue,
  placeholder,
  disabled,
  style,
  isEmpty,
  id,
  open,
  textStyle,
  isClearable
}) {
  const classes = useStyles();

  const displayValue = () => {
    if (isEmpty) {
      return placeholder
    }

    if(isObject(brandValue) && isObject(modelValue)) {
      if(brandValue.name === undefined || brandValue === {} || brandValue.name === "" || brandValue.id === "-1") {
        return modelValue.name
      }

      if( modelValue.name === undefined || modelValue === {} || modelValue.name === "") {
        return brandValue.name
      }

      return `${brandValue.name}, ${modelValue.name}`
    }

    if(isArray(brandValue) && isArray(modelValue)) {
      if(brandValue.length === 0 && modelValue.length === 0){
        return placeholder;
      }

      if(modelValue.length === 0) {
        return brandValue[0].name
      }

      if(brandValue.length === 0) {
        return modelValue[0].name
      }

      if (brandValue[0].name && modelValue[0].name) {
        return `${brandValue[0].name}, ${modelValue[0].name}`
      } else if (brandValue[0].name){
        return `${brandValue[0].name}`
      } else if (modelValue[0].name) {
        return `${modelValue[0].name}`
      }
    }

    return undefined
  }

  return (
    <ButtonBase
      aria-describedby={id}
      className={classes.root}
      disableRipple
      onClick={handleClick}
      disabled={disabled}
      style={{ paddding: 12, ...style }}
    >
      <Typography
        variant='body2'
        className={clsx(classes.inputText, {
          [classes.filled]: !isEmpty
        })}
        style={textStyle}
      >
        {displayValue()}
      </Typography>
      <Box 
        style={{
          display: "flex",
          alignItems: "center"
        }}
      >
        {isClearable && !isEmpty &&
          <Button
            className={classes.clearButton}
            onClick={(e) => {
              e.stopPropagation()
              handleClear()
            }}
          >
            <ClearIcon style={{ fontSize: 16 }} />
          </Button>
        }
        {!disabled && (open ? (
          <ArrowDropUpIcon fontSize='small' />
        ) : (
          <ArrowDropDownIcon fontSize='small' />
        ))}
      </Box>
    </ButtonBase>
  )
}

BrandModelSingleSelectComponent.propTypes = {
  handleClick: PropTypes.func,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  isEmpty: PropTypes.bool
}

export default BrandModelSingleSelectComponent