import React from "react";
import { Box, makeStyles, Typography, Divider } from "@material-ui/core";
import DetailPanel from "../../components/panel/detailPanel";
import { BackButton } from "components/button";
import { getLang } from "app/feature/constants";

const style = (theme) => ({
  title: {
    marginBottom: theme.spacing(1),
    display: "flex",
    alignItems: "center"
  }
});

const useStyle = makeStyles(style);

export default function CounterfeitReportDetailComponent({lang}) {

  const classes = useStyle();

  return (
    <Box>
      <Box className={classes.title}>
        <BackButton />
        <Typography
          variant="h6"
          color="primary"
          style={{ fontWeight: "bold", marginLeft: 5 }}
        >
          {getLang(lang, "label.COUNTERFEIT_REPORT_DETAILS")}
        </Typography>
      </Box>

      <Divider style={{ backgroundColor: "rgba(58, 77, 84, 0.1)", marginBottom: 16 }} />

      <DetailPanel />
    </Box>
  );
}
