import React, { useState, useEffect } from "react";
import ProductSearchFieldComponent from "./productSearchField.component";
import ProductSearchPopoverComponent from './productSearchPopover.component';
import { useSelector } from "react-redux";

function ProductSearchFieldContainer({
  value,
  placeholder,
  searchPlaceholder,
  handleChange,
  disabled,
  isFetching,
  panelStyle,
  dropdownItem,
  anchorOrigin,
  transformOrigin,
  PaperProps,
  style,
  textStyle,
  isClearable,
  handleClear,
  emptyMessage,
  isBranchEmpty,
}) {
  const lang = useSelector((state) => state.constant.languages);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [branchList, setBranchList] = useState(dropdownItem);
  const drawerOpen = useSelector((state)=>state.app.drawerOpen);

  useEffect(() => {
    setBranchList(dropdownItem);
  }, [dropdownItem]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
    handleSearch("");
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSearch = (targetBranch) => {
    if (targetBranch.trim().length === 0) {
      setBranchList(dropdownItem);
      return;
    }
    let branchs = [];
    dropdownItem.forEach(branch => {
      let branchName = branch.name ?? "";
      if (branchName.toLowerCase().includes(targetBranch.toLowerCase())) {
        branchs.push(branch);
      }
    });

    setBranchList(branchs);
  }

  return (
    <>
      <ProductSearchFieldComponent
        handleClick={handleClick}
        handleClear={handleClear}
        value={value}
        style={style}
        disabled={disabled}
        placeholder={placeholder}
        open={open}
        handleChange={handleChange}
        textStyle={textStyle}
        isClearable={isClearable}
        drawerOpen={drawerOpen}
        items={dropdownItem}
      />
      <ProductSearchPopoverComponent
        anchorEl={anchorEl}
        open={open}
        handleClose={handleClose}
        style={panelStyle}
        items={branchList}
        handleSearch={handleSearch}
        isFetching={isFetching}
        handleChange={handleChange}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        PaperProps={PaperProps}
        value={value}
        emptyMessage={emptyMessage}
        placeholder={searchPlaceholder}
        isBranchEmpty={isBranchEmpty}
        lang={lang}
      />
    </>
  )
}

export default ProductSearchFieldContainer
