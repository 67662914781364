import axios from "axios";

export function getCampaignLoyaltyProgramListData({
    length,
    start,
    search,
    orderBy,
    orderDirection,
    search_by,
    country,
    city_id,
    branch_uuid,
    period,
    start_date,
    end_date,
    url,
    campaign_type
}) {
  let city = city_id;
  return new Promise((resolve, reject) => {
    axios
      .post(url, {
        length,
        start,
        search,
        orderBy,
        orderDirection,
        search_by,
        country,
        city,
        branch_uuid,
        period,
        start_date,
        end_date,
        campaign_type
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        if (error?.response?.data) {
          reject(error.response.data);
        } else {
          reject(error);
        }
      });
  });
};

export default getCampaignLoyaltyProgramListData;
