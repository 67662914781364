/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import AdditionalFieldTitleWithLangSelectComponent from "./additionalFieldTitleWithLangSelect.component";
import AdditionalFieldTitleWithLangSelectPopoverComponent from "./additionalFieldTitleWithLangSelectPopover.component";
import { useDispatch, useSelector } from "react-redux";
import { selectLoading } from "modules/notification";
import {
  getAdditionalInfoDropdown,
  resetAdditionalFieldDropdown,
} from "modules/serial-number/redux";

function AdditionalFieldTitleWithLangSelectContainer({
  value,
  placeholder,
  handleChange,
  disabled,
  style,
  textStyle,
  selectedIds,
  userLang,
}) {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.constant.languages);
  const isFetching = useSelector((state) =>
    selectLoading(state, getAdditionalInfoDropdown.typePrefix)
  );
  const additionalFields = useSelector(
    (state) => state.additionalInfo.additionalFieldDropdown.list
  );
  const isError = useSelector(
    (state) => state.additionalInfo.additionalFieldDropdown.isError
  );
  const totalFiltered = useSelector(
    (state) => state.additionalInfo.additionalFieldDropdown.totalFiltered
  );
  const hasNextPage = useSelector(
    (state) => state.additionalInfo.additionalFieldDropdown.hasNextPage
  );

  const [dropdown, setDropdown] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [filter, setFilter] = useState({
    page: 0,
    rowsPerPage: 25,
    search: "",
  });

  useEffect(() => {
    if (hasNextPage && !isFetching) {
      getData();
    }
  }, [filter, dispatch]);

  useEffect(() => {
    setDropdown(additionalFields.filter((a) => !selectedIds.includes(a.id)));
  }, [additionalFields, selectedIds]);

  const getData = () => {
    let param = {
      length: filter.rowsPerPage,
      start: filter.rowsPerPage * filter.page,
      search: filter.search,
    };

    dispatch(getAdditionalInfoDropdown(param));
  };

  const handleOpen = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFetchMoreItems = () => {
    if (hasNextPage) {
      const newFilter = { ...filter, page: filter.page + 1 };
      setFilter(newFilter);
    }
  };

  const handleSearch = (value) => {
    dispatch(resetAdditionalFieldDropdown());
    setFilter({
      ...filter,
      page: 0,
      search: value,
    });
  };

  const handleReload = () => {
    dispatch(resetAdditionalFieldDropdown());
    setFilter({
      ...filter,
      page: 0,
      search: "",
    });
  };

  const handleSelectField = (field) => {
    handleChange(field);
    handleClose();
  };

  return (
    <>
      <AdditionalFieldTitleWithLangSelectComponent
        handleClick={handleOpen}
        value={value}
        style={style}
        disabled={disabled}
        placeholder={placeholder}
        open={open}
        textStyle={textStyle}
        userLang={userLang}
      />
      <AdditionalFieldTitleWithLangSelectPopoverComponent
        additionalFields={additionalFields}
        value={value}
        anchorEl={anchorEl}
        open={open}
        handleClose={handleClose}
        isFetching={isFetching}
        dropdown={dropdown}
        handleSelectField={handleSelectField}
        lang={lang}
        isError={isError}
        totalFiltered={totalFiltered}
        hasNextPage={hasNextPage}
        handleFetchMoreItems={handleFetchMoreItems}
        handleReload={handleReload}
        handleSearch={handleSearch}
        userLang={userLang}
      />
    </>
  );
}

export default AdditionalFieldTitleWithLangSelectContainer;
