import React from "react";
import {
  makeStyles,
  Box,
  Typography,
  Divider,
  FormControlLabel,
  Radio,
  Checkbox,
  Button,
} from "@material-ui/core";
import { getLang } from "app/feature/constants";
import InputTextField from "components/input/inputTextField";
import SearchableSelect from "modules/survey/components/select/searchableSelect";
import { Skeleton } from "@material-ui/lab";
import moment from "moment";

const useStyle = makeStyles((theme) => ({
  container: {
    borderRadius: 16,
    backgroundColor: "white",
    padding: 20,
  },
  title: {
    fontSize: 16,
    fontWeight: "bold",
  },
  subtitle: {
    fontSize: 13,
  },
  value: {
    fontSize: 14,
    color: "#202020 !important",
  },
  root: {
    margin: "7px 10px 7px 9px",
    padding: 0,
    color: "#202020 !important",
  },
  scrollbar: {
    width: 5,
    height: 50,
    borderRadius: 16,
    position: "absolute",
    top: 20,
    right: 6,
    backgroundColor: "#00000080",
  },
  button: {
    textWrap: "nowrap",
    textAlign: "center",
    borderRadius: 100,
    padding: "6px 16px",
    minWidth: "11em",
    color: "white",
    fontWeight: "bold",
    textTransform: "capitalize",
  },
}));

export default function SurveyComponent({
  lang,
  survey,
  isFetching,
  uiSetting,
}) {
  const classes = useStyle();

  return (
    <Box p={2}>
      <Box className={classes.container} position="relative">
        {isFetching ? (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            style={{ width: "100%" }}
          >
            <Skeleton width={"100%"} height={30} />
            <Skeleton width={"100%"} height={30} />
            <Skeleton width={"100%"} height={30} />
            <Skeleton width={"100%"} height={30} />
          </Box>
        ) : survey ? (
          <Box>
            <Box className={classes.scrollbar} />
            <Box mb={1}>
              <Typography
                className={classes.title}
                style={{ color: uiSetting.colorPrimary }}
              >
                {survey?.survey_name}
              </Typography>
            </Box>
            <Divider />
            <Box mb={3} mt={1.5}>
              <Typography className={classes.subtitle}>
                {getLang(lang, "label.AVAILABLE_UNTIL")}:{" "}
                {survey?.end_date
                  ? moment(survey?.end_date).format("DD MMM YYYY")
                  : "-"}
              </Typography>
            </Box>
            <Box mb={1}>
              <Typography variant="body2" color="textPrimary">
                {survey?.survey_description}
              </Typography>
            </Box>
            <Box style={{ flex: 1 }}>
              {survey?.survey_questions?.slice(0, 3).map((item, i) => (
                <Box
                  style={{ display: "flex", margin: "25px 0px" }}
                  key={item + i}
                >
                  <Typography className={classes.title} color="textPrimary">
                    {i + 1}.
                  </Typography>
                  <Box marginLeft={1} style={{ width: "100%" }}>
                    <Typography
                      className={classes.title}
                      style={{ marginBottom: 8 }}
                      color="textPrimary"
                    >
                      {item.text}
                      {item.required && (
                        <span
                          style={{
                            color: "#FD646F",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {" "}
                          *
                        </span>
                      )}
                    </Typography>
                    {item.type === "text" && (
                      <InputTextField
                        fullWidth
                        disabled={true}
                        placeholder={item.placeholder}
                        style={{
                          fontWeight: 500,
                        }}
                        InputStyle={{
                          fontSize: 14,
                        }}
                      />
                    )}
                    {item.type === "select" && (
                      <>
                        {item.answers.map((item, i) => (
                          <FormControlLabel
                            key={i}
                            control={
                              <Radio
                                className={classes.root}
                                size="small"
                                disableRipple
                                disabled={true}
                                color="primary"
                              />
                            }
                            label={item}
                            classes={{
                              label: classes.value,
                            }}
                            style={{
                              display: "flex",
                            }}
                          />
                        ))}
                      </>
                    )}
                    {item.type === "dropdownlist" && (
                      <>
                        <Typography
                          variant="body2"
                          style={{
                            fontWeight: "600",
                            color: "rgba(32, 32, 32, 0.33)",
                            fontSize: "14px",
                          }}
                        ></Typography>
                        <>
                          <SearchableSelect
                            searchable={true}
                            placeholder={getLang(
                              lang,
                              "placeholder.SELECT_AN_OPTION"
                            )}
                            dropdownItem={item.answers}
                          />
                        </>
                      </>
                    )}
                    {item.type === "multipleChoiceMultiSelect" && (
                      <>
                        <Typography
                          variant="body2"
                          style={{
                            fontWeight: "600",
                            color: "rgba(32, 32, 32, 0.33)",
                            fontSize: "14px",
                          }}
                        ></Typography>
                        <>
                          {item.answers.map((choice, checkBoxIndex) => (
                            <FormControlLabel
                              key={checkBoxIndex}
                              classes={{
                                label: classes.value,
                              }}
                              control={
                                <Checkbox
                                  disabled={true}
                                  className={classes.root}
                                  value={choice}
                                  color="primary"
                                />
                              }
                              label={choice}
                            />
                          ))}
                        </>
                      </>
                    )}
                  </Box>
                </Box>
              ))}
            </Box>
            <Divider />
            <Box
              mt={1.75}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                padding: "0px 15px",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.button}
                backgroundColor={uiSetting.colorPrimary}
                style={{
                  backgroundColor: uiSetting.colorPrimary,
                }}
              >
                {getLang(lang, "label.SUBMIT")}
              </Button>
            </Box>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
}
