import React from "react";
import { Box, makeStyles, Tabs, Typography } from "@material-ui/core";
//import ProductSelect from "modules/serial-number/components/select/productSelect";
import { getLang } from "app/feature/constants";
import AdtProductListingPanel from "../adtProductListingPanel";
import SnListingPanel from "../snListingPanel";
import CustomTab from "components/tab";
import AdtInfoDisplayLangSelect from "../../select/adtInfoDisplayLangSelect";

const useStyle = makeStyles((theme) => ({
  searchContainer: {
    backgroundColor: theme.palette.background.paper,
    display: "grid",
    gridTemplateColumns: "7.5fr 2.5fr 3.5fr 1fr 1fr",
    columnGap: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1),
    },
  },
  inputRoot: {
    fontWeight: 500,
    paddingTop: "8px !important",
    paddingBottom: "7px !important",
    paddingLeft: "10px !important",
  },
  onFocusBorder: {
    borderRadius: 4,
    outlineWidth: "1px",
    outlineStyle: "solid",
    outlineColor: theme.palette.secondary.main,
  },
  colorIsSelected: {
    background: "#EBEDEE",
  },
  icon: {
    borderRight: "1px solid #3A4D5430",
  },
  dropdownSearchBox: {
    backgroundColor: "#E9EDEF",
    borderRadius: 4,
    width: "100%",
    alignItems: "center",
  },
  select: {
    [theme.breakpoints.down("xs")]: {
      minWidth: "50%",
    },
  },
  divider: {
    height: "70%",
    placeSelf: "center",
  },
  textFieldInput: {
    padding: "7px",
    paddingTop: "8px",
    fontWeight: 500,
    color: theme.palette.primary.main,
    [theme.breakpoints.down("xs")]: {
      minWidth: "50%",
    },
  },
  menuItemText: {
    padding: 10,
    fontWeight: 500,
  },
  filterContainer: {
    backgroundColor: theme.palette.background.paper,
    padding: "0px 16px 12px",
    display: "grid",
    gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
    columnGap: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1),
    },
  },
  infoControlSection: {
    border: "1px solid #c4c4c4",
    borderRadius: 4,
    padding: 4,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  emptyContainer: {
    backgroundColor: "white",
    borderRadius: 4,
    padding: 16,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  selectRoot: {
    fontSize: 14,
    backgroundColor: "#EBEDEE",
    borderRadius: 4,
    width: "100%",
    height: 38,
    padding: "0px 24px 0px 0px",
    display: "flex",
    alignItems: "center",
    "&:focus": {
      backgroundColor: "#EBEDEE",
      borderRadius: 4,
    },
  },
}));

export default function AdtInfoDataPanelComponent({
  selectedAdtInfos,
  tabLabels,
  activeIndex,
  handleChange,
  handleViewDetail,
  lang,
}) {
  const classes = useStyle();

  function a11yProps(index, label = "label") {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tab-${index}`,
      label: <Typography variant="button" children={label} />,
    };
  }

  function TabPanel({ children, value, index, ...other }) {
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </Typography>
    );
  }

  return (
    <Box>
      <Box display="flex" justifyContent="space-between">
        <Tabs
          value={activeIndex || 0}
          onChange={handleChange}
          aria-label="scrollable auto tabs example"
          textColor="primary"
          variant="scrollable"
          indicatorColor="primary"
          classes={{
            indicator: classes.indicator,
            scrollButtons: classes.scroll,
          }}
        >
          {tabLabels.map((item, index) => {
            return <CustomTab key={index} {...a11yProps(index, item)} />;
          })}
        </Tabs>
        <AdtInfoDisplayLangSelect />
      </Box>

      <Box>
        {activeIndex !== null &&
          tabLabels.map((item, index) => {
            if (item === getLang(lang, "label.PRODUCT")) {
              return (
                <TabPanel value={activeIndex} index={index} key={index}>
                  <Box className={classes.panel}>
                    <AdtProductListingPanel
                      selectedAdtInfos={selectedAdtInfos}
                      handleViewDetail={handleViewDetail}
                    />
                  </Box>
                </TabPanel>
              );
            }
            if (item === getLang(lang, "label.SERIAL_NUMBER")) {
              return (
                <TabPanel value={activeIndex} index={index} key={index}>
                  <Box className={classes.panel}>
                    <SnListingPanel
                      selectedAdtInfos={selectedAdtInfos}
                      handleViewDetail={handleViewDetail}
                    />
                  </Box>
                </TabPanel>
              );
            }
            return null;
          })}
      </Box>
    </Box>
  );
}
