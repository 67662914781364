import React from "react";
import { makeStyles, Typography, IconButton, Box } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const useStyle = makeStyles((theme) => ({
    header: {
        paddingLeft: theme.spacing(3),
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
}));

export default function DialogComponent({
    title,
    handleClose
}) {
    const classes = useStyle();

    return (
        <Box className={classes.header}>
            <Typography variant="subtitle2" style={{ fontWeight: "bold" }}>
                {title}
            </Typography>
            <IconButton color="primary" onClick={handleClose}>
                <CloseIcon fontSize="small" />
            </IconButton>
        </Box>
    );
}