/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import GroupRuleEditDialogComponent from "./groupRuleEditDialog.component";
import { useDispatch, useSelector } from "react-redux";
import { getGroupRules, resetRules } from "modules/group/redux";
import { selectLoading } from "modules/notification";
import SelectTargetBranchDialog from "../selectTargetBranchDialog";
import { getLang } from "app/feature/constants";
import ConfirmationDialog from "../confirmationDialog";

function GroupRuleEditDialogContainer({
  isOpen,
  handleClose,
  submitRuleUpdate,
  isSubmitting,
  id,
  group,
}) {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.constant.languages);
  const groupCustomRules = useSelector(
    (state) => state.branchGroup.customRules
  );
  const groupRules = useSelector((state) => state.branchGroup.rules);
  const isFetching = useSelector((state) =>
    selectLoading(state, getGroupRules.typePrefix)
  );
  const isError = useSelector((state) => state.branchGroup.isLoadingRulesError);

  const [customRules, setCustomRules] = useState([]);
  const [rules, setRules] = useState([]);
  const [selectedBranchUuid, setSelectedBranchUuid] = useState("");

  const [branchToAdd, setBranchToAdd] = useState(null);
  const [branchToRemove, setBranchToRemove] = useState(null);
  const [ruleWithEmptyTarget, setRuleWithEmptyTarget] = useState([]);

  const [openSaveDialog, setOpenSaveDialog] = useState(false);
  const [openRemoveRuleDialog, setOpenRemoveRuleDialog] = useState(false);
  const [openRemoveRuleTargetDialog, setOpenRemoveRuleTargetDialog] =
    useState(false);

  const [deletedCustomRuleFrom, setDeletedCustomRuleFrom] = useState([]);
  const [deletedCustomRuleTo, setDeletedCustomRuleTo] = useState([]);

  const [isOpenTargetBranchDialog, setIsOpenTargetBranchDialog] =
    useState(false);

  useEffect(() => {
    setRules(groupRules);
  }, [groupRules]);

  useEffect(() => {
    setCustomRules(groupCustomRules);
  }, [groupCustomRules]);

  useEffect(() => {
    if (isOpen) {
      getData();
    } else {
      dispatch(resetRules());
    }
  }, [isOpen]);

  const getData = () => {
    dispatch(getGroupRules(id));
  };

  const updateRules = (rule, name, isChecked) => {
    let temp = [...rules];
    let index = temp.findIndex((r) => r.uuid === rule.uuid);
    if (index >= 0) {
      temp[index] = {
        ...temp[index],
        [name]: isChecked ? 1 : 0,
      };
    }
    setRules(temp);
  };

  const updateCustomRule = (target, fromBranch, name, isChecked) => {
    let temp = [...customRules];
    let index = temp.findIndex(
      (r) => r.from_node_uuid === fromBranch.from_node_uuid
    );
    if (index >= 0) {
      let nodes = [...temp[index].to_node];
      let childIndex = nodes.findIndex((r) => r.uuid === target.uuid);
      if (childIndex >= 0) {
        nodes[childIndex] = {
          ...nodes[childIndex],
          [name]: isChecked ? 1 : 0,
        };
      }

      temp[index] = {
        ...temp[index],
        to_node: nodes,
      };
    }
    setCustomRules(temp);
  };

  const addBranchForCustomRules = () => {
    let index = customRules.findIndex(
      (r) => r.from_node_uuid === branchToAdd.uuid
    );
    if (index < 0) {
      let temp = {
        from_node_uuid: branchToAdd.uuid,
        from_node_name: branchToAdd.name,
        is_initial: 0,
        to_node: [],
      };
      setCustomRules([...customRules, temp]);
      setRuleWithEmptyTarget([...ruleWithEmptyTarget, branchToAdd.uuid]);
    }
    setBranchToAdd(null);
  };

  const removeBranchForCustomRules = () => {
    let temp = [...customRules];
    let index = temp.findIndex(
      (r) => r.from_node_uuid === branchToRemove.from_uuid
    );
    if (index >= 0) {
      if (temp[index].is_initial === 1) {
        setDeletedCustomRuleFrom([
          ...deletedCustomRuleFrom,
          temp[index].from_node_uuid,
        ]);
      }
      temp.splice(index, 1);
      setCustomRules(temp);
      setRuleWithEmptyTarget(
        ruleWithEmptyTarget.filter((r) => r !== branchToRemove.from_uuid)
      );
    }
    setBranchToRemove(null);
    setOpenRemoveRuleDialog(false);
  };

  const addTargetBranch = (branches) => {
    let temp = [...customRules];

    if (selectedBranchUuid) {
      let ruleIndex = customRules.findIndex(
        (r) => r.from_node_uuid === selectedBranchUuid
      );
      if (ruleIndex >= 0) {
        let rule = temp[ruleIndex];
        let branchUuid = rule.to_node.map((n) => n.uuid);

        let branchesToAdd = [];
        branches.forEach((branch) => {
          let isAdded = branchUuid.includes(branch.uuid);

          if (!isAdded && branch.uuid !== selectedBranchUuid) {
            let temp = {
              uuid: branch.uuid,
              name: branch.name,
              is_transfer_to: 1,
              is_send_request_to: 1,
              is_transfer_requires_approval: 1,
              is_request_requires_approval: 1,
              is_request_on_behalf: 1,
              is_initial: 0,
            };
            branchesToAdd.push(temp);
          }
        });

        temp[ruleIndex] = {
          ...rule,
          to_node: [...rule.to_node, ...branchesToAdd],
        };

        if (
          branchesToAdd.length &&
          ruleWithEmptyTarget.includes(selectedBranchUuid)
        ) {
          setRuleWithEmptyTarget(
            ruleWithEmptyTarget.filter((r) => r !== selectedBranchUuid)
          );
        }
      }
      setCustomRules(temp);
      setIsOpenTargetBranchDialog(false);
      setSelectedBranchUuid("");
    }
  };

  const removeTargetBranch = () => {
    let temp = [...customRules];

    if (branchToRemove) {
      let ruleIndex = customRules.findIndex(
        (r) => r.from_node_uuid === branchToRemove.from_uuid
      );
      if (ruleIndex >= 0) {
        let nodes = [...temp[ruleIndex].to_node];

        let index = nodes.findIndex((r) => r.uuid === branchToRemove.to_uuid);
        if (index >= 0) {
          let removed = nodes.splice(index, 1);
          temp[ruleIndex] = {
            ...temp[ruleIndex],
            to_node: nodes,
          };
          if (
            !nodes.length &&
            !ruleWithEmptyTarget.includes(branchToRemove.from_uuid)
          ) {
            setRuleWithEmptyTarget([
              ...ruleWithEmptyTarget,
              branchToRemove.from_uuid,
            ]);
          }

          if (removed[0].is_initial === 1) {
            setDeletedCustomRuleTo([
              ...deletedCustomRuleTo,
              { from_uuid: branchToRemove.from_uuid, to_uuid: removed[0].uuid },
            ]);
          }
        }
      }
      setCustomRules(temp);
      setBranchToRemove(null);
      setOpenRemoveRuleTargetDialog(false);
    }
  };

  const submitUpdate = () => {
    if (!ruleWithEmptyTarget.length) {
      let tempCustomRules = [];
      if (!group.is_default) {
        customRules.forEach((rule) => {
          rule.to_node.forEach((target) => {
            tempCustomRules.push({
              from_node_uuid: rule.from_node_uuid,
              to_node_uuid: target.uuid,
              is_transfer_to: target.is_transfer_to,
              is_send_request_to: target.is_send_request_to,
              is_transfer_requires_approval:
                target.is_transfer_requires_approval,
              is_request_requires_approval: target.is_request_requires_approval,
              is_request_on_behalf: target.is_request_on_behalf,
            });
          });
        });
      }

      setOpenSaveDialog(false);

      submitRuleUpdate(
        rules,
        tempCustomRules,
        deletedCustomRuleFrom,
        deletedCustomRuleTo
      );
    }
  };

  const reset = () => {
    setRules(groupRules);
    setCustomRules(groupCustomRules);
  };

  return (
    <>
      <GroupRuleEditDialogComponent
        isOpen={isOpen}
        handleClose={handleClose}
        rules={rules}
        customRules={customRules}
        lang={lang}
        isFetching={isFetching}
        isError={isError}
        handleReload={getData}
        isSubmitting={isSubmitting}
        submitRuleUpdate={() => setOpenSaveDialog(true)}
        updateRules={updateRules}
        updateCustomRule={updateCustomRule}
        group={group}
        branchToAdd={branchToAdd}
        updateBranchToAdd={setBranchToAdd}
        updateBranchToRemove={setBranchToRemove}
        addBranchForCustomRules={addBranchForCustomRules}
        openSelectTargetBranchDialog={(branchUuid) => {
          setSelectedBranchUuid(branchUuid);
          setIsOpenTargetBranchDialog(true);
        }}
        id={id}
        openRemoveRuleDialog={() => setOpenRemoveRuleDialog(true)}
        openRemoveRuleTargetDialog={() => setOpenRemoveRuleTargetDialog(true)}
        hasRuleWithEmptyTarget={!!ruleWithEmptyTarget.length}
        reset={reset}
      />
      <SelectTargetBranchDialog
        isOpen={isOpenTargetBranchDialog}
        handleClose={() => setIsOpenTargetBranchDialog(false)}
        addTargetBranch={addTargetBranch}
      />
      <ConfirmationDialog
        isOpen={openRemoveRuleDialog}
        handleClose={() => setOpenRemoveRuleDialog(false)}
        handleProceed={() => removeBranchForCustomRules()}
        isLoading={false}
        type={"danger"}
        description={getLang(lang, "paragraph.REMOVE_RULE_QUSTION")}
        title={getLang(lang, "label.REMOVE_RULE")}
      />
      <ConfirmationDialog
        isOpen={openRemoveRuleTargetDialog}
        handleClose={() => setOpenRemoveRuleTargetDialog(false)}
        handleProceed={() => removeTargetBranch()}
        isLoading={false}
        type={"danger"}
        description={getLang(lang, "paragraph.REMOVE_RULE_TARGET_QUSTION")}
        title={getLang(lang, "label.REMOVE_RULE_TARGET")}
      />
      <ConfirmationDialog
        isOpen={openSaveDialog}
        handleClose={() => setOpenSaveDialog(false)}
        handleProceed={() => submitUpdate()}
        isLoading={isSubmitting}
        type={"success"}
        description={getLang(lang, "paragraph.SAVE_RULES_QUESTION")}
        title={getLang(lang, "label.SAVE_GROUP_SETTING")}
      />
    </>
  );
}

export default GroupRuleEditDialogContainer;
