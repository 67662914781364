import React, { useState } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import LiveDrawPanelComponent from './liveDrawPanel.component'
import {setLiveDrawOne} from "../../../redux";

export default function LiveDrawPanelContainer({
    index,
    winnerName,
    serialNumber,
    contactNumber,
    startDraw,
    winnerId,
}) {
    const [isDraw, setIsDraw] = useState(startDraw)
    const dispatch = useDispatch()
    const lang = useSelector(state => state.constant.languages)

    const handleDraw = () => {
        setIsDraw(true);
        dispatch(setLiveDrawOne({id: winnerId}));
    }

    return (
        <LiveDrawPanelComponent
            index={index}
            winnerName={winnerName}
            serialNumber={serialNumber}
            contactNumber={contactNumber}
            isDraw={isDraw}
            handleDraw={handleDraw}
            lang={lang}
        />
    )
}
