import { selectLoading } from "modules/notification";
import { getDealerBranchTitleData } from "modules/reporting/redux";
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import BranchTitleCard from './branchTitleCard.component';

export default function BranchTitleCardContainer({
    currentFilter,
    translate,
    url,
}){
    const dispatch = useDispatch();

    // (1) branch title data -- start
    const isFetchingBranchTitleData = useSelector(state => selectLoading(state, getDealerBranchTitleData.typePrefix));
    const branchTitleData = useSelector(state => state.reporting.stockout.dealer.branchTitleData);
    // (1) branch title data -- end

    useEffect(() => {
        dispatch(getDealerBranchTitleData({
            branch_uuid: currentFilter.branchUuid,
            url: url
        }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, currentFilter])

    return (
        <BranchTitleCard 
            status={{
                isFetchingBranchTitleData
            }}

            data={{
                branchTitleData
            }}

            currentFilter={currentFilter}

            translate={translate}
        />
    )

}