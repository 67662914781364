import React from 'react';
import {
    makeStyles,
    Paper,
    Box,
    Button,
    TableHead,
    Table,
    TableContainer,
    TableRow,
    TableCell,
    TableBody,
    Typography,
    Divider,
    Grid
} from '@material-ui/core';
import {
    HighlightOff as HighlightOffIcon
} from '@material-ui/icons';
import { AddIcon } from "components/icon";
import { ErrorMessage, Field, FieldArray } from 'formik';
import InputTextField from 'components/input/inputTextField';
import clsx from 'clsx';
import { Alert, Skeleton } from '@material-ui/lab';
import { getLang } from 'app/feature/constants';

const useStyle = makeStyles(theme => ({
    availableRangeCard: {
        boxShadow: "0px 0px 4px 0px #adadad",
        marginTop: 15,
        marginBottom: 10,
        position: "relative",
        '&::after': {
            content: `''`,
            position: "absolute",
            width: 11,
            height: 11,
            transform: "rotate(45deg)",
            top: -5,
            left: 95,
            backgroundColor: "white",
            boxShadow: "-2px -2px 2px -1px #dadada"
        }
    },
    table: {
        tableLayout: "auto",
        whiteSpace: "nowrap",
        "& th:last-child, td:last-child": {
            width: 65,
            paddingRight: theme.spacing(3)
        },
        "& th,td": {
            fontSize: "small",
            borderBottom: "0",
            padding: theme.spacing(1, 2, 1, 2),
            color: theme.palette.primary.main
        },
        "& th": {
            borderBottom: "2px solid rgba(224, 224, 224, 1)",
            fontWeight: "bold"
        }
    },
    notAvailableRange: {
        color: "#b1b1b1 !important"
    },
    stripedRow: {
        backgroundColor: "#ececec"
    },
    balanceLabel: {
        fontSize: 13,
        display: "inline",
        marginRight: 10
    },
    balanceValue: {
        fontSize: 13,
        fontWeight: "bold",
        display: "inline"
    },
    deleteGrid: {
        display: "flex",
        justifyContent: "center"
    },
    deleteButton: {
        color: theme.palette.error.main
    },
    quantityLabel: {
        display: "inline",
        marginRight: 10
    },
    quantityValue: {
        fontWeight: "bold",
        display: "inline"
    },
    addButton: {
        color: theme.palette.common.white
    },
    addButtonLabel: {
        fontSize: 12
    }
}));

function SkeletonTableRow() {
    return (
        <TableRow>
            {[1, 2, 3, 4].map((id) => (
                <TableCell key={id}>
                    <Skeleton variant="text" animation="wave" />
                </TableCell>
            ))}
        </TableRow>
    );
}

function RenderFormikField({ name, formik, onChange, disabled }) {
    return (
        <>
            <Field name={name}>
                {({ field, form, meta }) => (
                    <InputTextField
                        {...field}
                        hiddenLabel={true}
                        variant="filled"
                        fullWidth
                        type="number"
                        size="small"
                        disabled={disabled}
                        onKeyDown={(e) => {
                            const invalidChars = [
                                "-",
                                "+",
                                "e",
                                ".",
                            ];
                            if (invalidChars.includes(e.key)) {
                                e.preventDefault();
                            }
                        }}
                        inputProps={{
                            min: 0
                        }}
                        error={meta.error && meta.touched ? true : false}
                        helperText={ErrorMessage({ name: field.name })}
                        onChange={onChange}
                    />
                )}
            </Field>
        </>
    );
}

export default function CustomRangePanelComponent({
    formik,
    isFetching,
    batchRangeInfo,
    isOpen,
    setIsOpen,
    addAlert,
    totalQuantity,
    onCustomRangeChange,
    isSameRange,
    lang,
    isExceedLimit,
    isInputDisable
}) {

    const classes = useStyle();

    return (
        <Box mt="10px">
            <Button
                variant="contained"
                color="primary"
                style={{
                    fontSize: 12
                }}
                onClick={() => setIsOpen(prevState => !prevState)}
            >
                {getLang(lang, "label.CHECK_AVAILABILITY_IN_RANGE")}
            </Button>
            <FieldArray name="generateRanges">
                {arrayHelper => {
                    return (
                        <>
                            {isOpen && (
                                <>
                                    <Paper classes={{ root: classes.availableRangeCard }}>
                                        <TableContainer component={Paper} style={{ maxHeight: 300 }}>
                                            <Table className={classes.table} aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>{getLang(lang, "label.RANGE")}</TableCell>
                                                        <TableCell align="right">{getLang(lang, "label.QTY_IN_RANGE")}</TableCell>
                                                        <TableCell align="center">{getLang(lang, "label.AVAILABLE")}</TableCell>
                                                        <TableCell></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {isFetching && (
                                                        <>
                                                            <SkeletonTableRow />
                                                            <SkeletonTableRow />
                                                            <SkeletonTableRow />
                                                            <SkeletonTableRow />
                                                        </>
                                                    )}
                                                    {!isFetching && batchRangeInfo.range && batchRangeInfo.range.length > 0 &&
                                                        batchRangeInfo.range.map((row, index) => (
                                                            <TableRow key={index} classes={{
                                                                root: clsx({ [classes.stripedRow]: row.generated })
                                                            }}>
                                                                <TableCell
                                                                    className={clsx({ [classes.notAvailableRange]: row.generated })}
                                                                >
                                                                    {row.from_sn} - {row.to_sn}
                                                                </TableCell>
                                                                <TableCell
                                                                    align="right"
                                                                    className={clsx({ [classes.notAvailableRange]: row.generated })}
                                                                >
                                                                    {row.quantity}
                                                                </TableCell>
                                                                <TableCell
                                                                    align="center"
                                                                    className={clsx({ [classes.notAvailableRange]: row.generated })}
                                                                >
                                                                    {row.generated ? getLang(lang, "label.NO_UPPERCASE") : getLang(lang, "label.YES")}
                                                                </TableCell>
                                                                <TableCell
                                                                    align="center"
                                                                    className={clsx({ [classes.notAvailableRange]: row.generated })}
                                                                >
                                                                    {!row.generated && (
                                                                        <Button
                                                                            variant="contained"
                                                                            color="secondary"
                                                                            size="small"
                                                                            classes={{ root: classes.addButton, label: classes.addButtonLabel }}
                                                                            onClick={() => {
                                                                                const newRange = {
                                                                                    from: row.from_sn,
                                                                                    to: row.to_sn
                                                                                };
                                                                                const replaceIndex = formik.values.generateRanges.findIndex(obj =>
                                                                                    obj.from === "" && obj.to === ""
                                                                                );

                                                                                if (replaceIndex >= 0) {
                                                                                    arrayHelper.replace(replaceIndex, newRange)
                                                                                }
                                                                                else {
                                                                                    arrayHelper.push(newRange);
                                                                                }
                                                                            }}
                                                                            disabled={formik.isSubmitting}
                                                                        >
                                                                            {getLang(lang, "label.ADD")}
                                                                        </Button>
                                                                    )}
                                                                </TableCell>
                                                            </TableRow>
                                                        ))
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        {!isFetching && (!batchRangeInfo.range || batchRangeInfo.range.length === 0) && (
                                            <Alert severity="info">{getLang(lang, "message.info.NO_RECORD_EXIST")}</Alert>
                                        )}
                                    </Paper>
                                    <Typography classes={{ root: classes.balanceLabel }} color="primary">
                                        {getLang(lang, "label.BALANCE_IN_RANGE")}
                                    </Typography>
                                    <Typography classes={{ root: classes.balanceValue }} color="primary">{batchRangeInfo.availableQuantity}</Typography>
                                </>
                            )}
                            <Divider style={{
                                margin: "10px 0px"
                            }} />
                            <Typography
                                variant="subtitle2"
                                color="primary"
                                style={{
                                    marginBottom: 10
                                }}
                            >
                                {getLang(lang, "label.RANGE_SET")}
                            </Typography>
                            <Grid container spacing={1}>
                                <Grid item xs={5}>
                                    <Typography variant="body2" color="primary">{getLang(lang, "label.FROM")}</Typography>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography variant="body2" color="primary">{getLang(lang, "label.TO")}</Typography>
                                </Grid>
                                <Grid item xs={2}></Grid>
                            </Grid>
                            {formik.values.generateRanges.map((range, i) => {
                                return (
                                    <Grid key={i} container spacing={1}>
                                        <Grid item xs={5}>
                                            <RenderFormikField
                                                name={`generateRanges.${i}.from`}
                                                formik={formik}
                                                onChange={(e) => onCustomRangeChange(e, formik, `generateRanges.${i}.from`, i)}
                                                isSameRange={isSameRange}
                                                disabled={isInputDisable}
                                            />
                                        </Grid>
                                        <Grid item xs={5}>
                                            <RenderFormikField
                                                name={`generateRanges.${i}.to`}
                                                formik={formik}
                                                onChange={(e) =>onCustomRangeChange(e, formik, `generateRanges.${i}.to`, i)}
                                                isSameRange={isSameRange}
                                                disabled={isInputDisable}
                                            />
                                        </Grid>
                                        <Grid item xs={2} classes={{ root: classes.deleteGrid }}>
                                            <Button
                                                classes={{ root: classes.deleteButton }}
                                                onClick={() => {
                                                    if (formik.values.generateRanges.length > 1) {
                                                        arrayHelper.remove(i);
                                                    }
                                                    else {
                                                        addAlert(getLang(lang, "message.warning.AT_LEAST_ONE_RANGE"));
                                                    }
                                                }}
                                            >
                                                <HighlightOffIcon />
                                            </Button>
                                        </Grid>
                                    </Grid>
                                );
                            })}
                            <Box>
                                {isSameRange && (
                                    <span style={{ color: "#f44336", fontSize: "0.75rem", margin: "0px" }}>{getLang(lang, "label.SIMILAR_RANGE_DETECTED")}</span>
                                )}
                            </Box>
                            <Box>
                                {isExceedLimit && (
                                    <span style={{ color: "#f44336", fontSize: "0.75rem", margin: "0px" }}>
                                        {getLang(lang, "label.MAX_ALLOWED_VALUE_COUNT", {
                                            value: "9".repeat(formik.values.digitLength),
                                        })}
                                    </span>
                                )}
                            </Box>
                            <Button
                                variant="text"
                                color="primary"
                                style={{
                                    textTransform: "unset",
                                    fontSize: 13,
                                    fontWeight: 500
                                }}
                                startIcon={
                                    <AddIcon color="secondary" style={{ fontSize: "13px" }} />
                                }
                                onClick={() => {
                                    if (!isInputDisable) {
                                        if (formik.values.generateRanges.length < 10) {
                                            arrayHelper.push({
                                                from: "",
                                                to: ""
                                            });
                                        } else {
                                            addAlert(getLang(lang, "message.warning.MAXIMUM_TEN_RANGES"));
                                        }
                                    }
                                }}
                            >
                                {getLang(lang, "label.ADD_RANGE_SET")}
                            </Button>
                        </>
                    )
                }}
            </FieldArray>
            <Divider style={{
                margin: "10px 0px"
            }} />
            <Typography classes={{ root: classes.quantityLabel }} variant="body2" color="primary">
                {getLang(lang, "label.TOTAL_QUANTITY")}
            </Typography>
            <Typography classes={{ root: classes.quantityValue }} variant="body2" color="primary">{totalQuantity}</Typography>
        </Box >
    );
}