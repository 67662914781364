import React from "react";
import HeaderComponent from "./header.component";
import { connect } from "react-redux";
import { useSelector } from "react-redux";

function HeaderContainer({ handleClose, headerType, title }) {
  const lang = useSelector(state => state.constant.languages);
  
  return (
    <HeaderComponent
      handleClose={handleClose}
      title={title}
      headerType={headerType}
      lang={lang}
    />
  );
}

const mapStateToProps = (state) => ({
  headerType: state.warranty.headerType,
  title: state.warranty.warrantySetting.warrantyName
});

export default connect(mapStateToProps, null)(HeaderContainer);
