import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  PRODUCT_PRODUCT_LIST_ADD,
  PRODUCT_PRODUCT_LIST_ASSIGN,
  PRODUCT_PRODUCT_LIST_EDIT,
  PRODUCT_PRODUCT_LIST_DELETE,
} from "lib/constants/accessRights";
import { selectLoading } from "modules/notification";
import ConfirmationDialog from "components/dialog/confirmation";
import { resetProductPage } from "modules/product-mgmt/redux/slice/productPage.slice";
import {
  changeSelectedProduct,
  changeBulkSelectedProduct,
  changeProductQuickEdit,
  changeBulkSelectedAvailableProduct,
  resetProductListing,
  resetProductListingCountings,
  setBulkEditListFilter,
  bulkUpdateStatus,
} from "modules/product-mgmt/redux/slice/products.slice";
import {
  NEW_PRODUCT_PAGE,
  ROOT,
  BULK_EDIT,
} from "modules/product-mgmt/config/product-mgmt.route";
import {
  getProductListing,
  updateProductStatus,
  deleteProduct,
  updateBulkProductStatus,
  quickUpdateProduct,
} from "../../../redux/action/products.action";
import { getCurrencyDropdown } from "modules/product-mgmt/redux/action/productPage.action";
import { getBrandModelDropdown } from "../../../redux/action/brandModels.action";
import { getProductCategoryDropdown } from "modules/product-mgmt/redux/action/productCategory.action";
import { getAttributeDropdown } from "modules/product-mgmt/redux/action/attribute.action";
import { AssignSerialNumberDialog } from "../../dialog";
import ProductListPanelComponent from "./productListPanel.component";
import { generatePaginationOptions, updateUrlQueryParam } from "lib/helper";
import { getLang } from "app/feature/constants";

function ProductListPanelContainer() {
  const hasProductAddAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(PRODUCT_PRODUCT_LIST_ADD)
  );
  const products = useSelector((state) => state.products.products);
  const selectedIds = useSelector((state) => state.products.selectedId);
  const totalRecords = useSelector((state) => state.products.totalRecords);
  const totalFiltered = useSelector((state) => state.products.totalFiltered);
  const isFetching = useSelector((state) =>
    selectLoading(state, getProductListing.typePrefix)
  );
  const isError = useSelector((state) => state.products.isLoadingProductError);
  const hasProductEditAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(PRODUCT_PRODUCT_LIST_EDIT)
  );
  const hasProductAssignAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(PRODUCT_PRODUCT_LIST_ASSIGN)
  );
  const hasProductDeleteAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(PRODUCT_PRODUCT_LIST_DELETE)
  );
  const isDeleting = useSelector((state) =>
    selectLoading(state, deleteProduct.typePrefix)
  );
  const isPublishing = useSelector((state) =>
    selectLoading(state, updateProductStatus.typePrefix)
  );
  const categoryDropdown = useSelector(
    (state) => state.products.categoryList.categoryDropdown
  );
  const isQuickEdit = useSelector((state) => state.products.isQuickEdit);
  const brandModelDropdown = useSelector(
    (state) => state.products.brandModelList.brandModelDropdown
  );
  const isBrandModelDropdownLoading = useSelector((state) =>
    selectLoading(state, getBrandModelDropdown.typePrefix)
  );
  const attributeDropdown = useSelector(
    (state) => state.products.attributeList.attributeDropdown
  );
  const isAttributeDropdownLoading = useSelector((state) =>
    selectLoading(state, getAttributeDropdown.typePrefix)
  );
  const currencyDropdown = useSelector(
    (state) => state.productPage.currencyDropdown
  );
  const lang = useSelector((state) => state.constant.languages);
  const history = useHistory();
  const dispatch = useDispatch();
  //const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
  const [isDeleteConfirmationDialogOpen, setIsDeleteConfirmationDialogOpen] =
    useState(false);
  const [deleteTargetId, setDeleteTargetId] = useState(null);
  const [isPublishConfirmationDialogOpen, setIsPublishConfirmationDialogOpen] =
    useState(false);
  const [publishTarget, setPublishTarget] = useState({ id: "", status: "" });
  const [product, setProduct] = useState(null);
  const [branchList, setBranchList] = useState([]);
  const isSearchResult = products.length < totalRecords;
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isSelectAll, setIsSelectAll] = useState(false);
  //const [ categoryDropdownSearch, setCategoryDropdownSearch ] = useState("")
  const [brandModelDropdownSearch, setBrandModelDropdownSearch] = useState("");
  const [attributeDropdownSearch, setAttributeDropdownSearch] = useState("");
  const [paginationOptions, setPaginationOptions] = useState([]);
  const [isFilterUpdated, setIsFilterUpdated] = useState(false);
  const [tableFilter, setTableFilter] = useState({
    page: 0,
    rowsPerPage: 25,
    search: "",
    nodes: "",
    category: "",
    brand: "",
    model: "",
    attribute: "",
    options: "",
    status: "",
    branch: [],
    brandModelList: [],
    attributeList: [],
    categoryList: [],
  });

  const query = new URLSearchParams(window.location.search);
  const tableFilterInitialRef = useRef(true);

  useEffect(() => {
    dispatch(resetProductListingCountings());
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetProductListing());
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const isFilter = query.has("is_filter");
    let filter = sessionStorage.getItem("product_filter");

    let temp = { ...tableFilter };
    if (isFilter && filter) {
      temp = JSON.parse(filter);
      setIsFilterUpdated(true);
      setIsFilterOpen(true);
    }

    if (isFilter) {
      updateUrlQueryParam(null, ["is_filter"]);
    }
    sessionStorage.removeItem("product_filter");

    setTableFilter(temp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!tableFilterInitialRef.current) {
      getData();
    }
    tableFilterInitialRef.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableFilter]);

  useEffect(() => {
    setPaginationOptions(generatePaginationOptions(totalFiltered));
  }, [totalFiltered]);

  useEffect(() => {
    dispatch(getProductCategoryDropdown({ search: "" }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getBrandModelDropdown({ search: brandModelDropdownSearch }));
  }, [dispatch, brandModelDropdownSearch]);

  useEffect(() => {
    dispatch(getAttributeDropdown({ search: attributeDropdownSearch }));
  }, [dispatch, attributeDropdownSearch]);

  const handleFetchProductList = () => {
    setTableFilter((prev) => ({
      ...prev,
      page: 0,
    }));
    handleDeselectAll();
  };

  useEffect(() => {
    dispatch(getCurrencyDropdown());
  }, [dispatch]);

  const getData = () => {
    dispatch(
      getProductListing({
        length: tableFilter.rowsPerPage,
        start: tableFilter.rowsPerPage * tableFilter.page,
        search: tableFilter.search,
        nodes: tableFilter.nodes,
        category: tableFilter.category,
        brand: tableFilter.brand,
        model: tableFilter.model,
        attribute: tableFilter.attribute,
        attributeoption: tableFilter.options,
        status: tableFilter.status,
      })
    );
  };

  const handleBrandModelSearch = (value) => {
    setBrandModelDropdownSearch(value);
  };

  const handleAttributeSearch = (value) => {
    setAttributeDropdownSearch(value);
  };

  const handleSearch = async (values) => {
    let brandList = values.brandModel.filter((b) => b.isSelected === true);
    let modelList = values.brandModel
      .filter((b) => b.isSelected === false)
      .map((b) => b.models.map((m) => m.id));
    let attributeList = values.attributes.filter((a) => a.isSelected === true);
    let optionList = values.attributes
      .filter((a) => a.isSelected === false)
      .map((a) => a.options.map((o) => o.id));

    //Get all Ids
    let categoryIds = values.category.map((category) => category.uuid);
    let brandIds = brandList.map((b) => b.id).join(",");
    let modelIds = modelList.flat(1).join(",");
    let attributeIds = attributeList.map((b) => b.id).join(",");
    let optionIds = optionList.flat(1).join(",");
    let nodeIds =
      values.branch.length > 0
        ? values.branch.map((br) => br.node_id).join(",")
        : "";

    let newData = {
      ...tableFilter,
      page: 0,
      search: values.search,
      nodes: nodeIds,
      branch: values.branch,
      category: categoryIds.join(","),
      categoryList: values.category,
      brand: brandIds,
      model: modelIds,
      brandModelList: values.brandModel,
      attribute: attributeIds,
      attributeList: values.attributes,
      options: optionIds,
      status: values.status,
    };
    setIsFilterUpdated(true);
    setTableFilter(newData);
  };

  const clearSearch = () => {
    let newData = {
      ...tableFilter,
      page: 0,
      search: "",
      nodes: "",
      category: "",
      brand: "",
      model: "",
      attribute: "",
      options: "",
      status: "",
      branch: [],
      brandModelList: [],
      attributeList: [],
      categoryList: [],
    };
    setIsFilterUpdated(false);
    setTableFilter(newData);
  };

  const handleChangePage = (event, newPage) => {
    let newData = {
      ...tableFilter,
      page: newPage,
    };
    setIsFilterUpdated(true);
    setTableFilter(newData);
  };

  const handleChangeRowsPerPage = (event) => {
    let newData = {
      ...tableFilter,
      page: 0,
      rowsPerPage: event.target.value,
    };
    setIsFilterUpdated(true);
    setTableFilter(newData);
  };

  const handleCreate = () => {
    if (hasProductAddAccessRight) {
      dispatch(resetProductPage());
      history.push(NEW_PRODUCT_PAGE);
    }
  };

  const handleEdit = (id) => {
    handleRedirectPage(`${ROOT}/${id}`);
  };

  const handleStatusChange = (id, status) => {
    setPublishTarget({
      id,
      status: status ? "published" : "unpublished",
    });
    setIsPublishConfirmationDialogOpen(true);
  };

  const handleDeleteClick = ({ id }) => {
    setDeleteTargetId(id);
    setIsDeleteConfirmationDialogOpen(true);
  };

  const handleDelete = ({ id }) => {
    dispatch(deleteProduct({ id }))
      .then(unwrapResult)
      .then(() => {
        handleFetchProductList();
        setIsDeleteConfirmationDialogOpen(false);
      })
      .catch(() => {
        setIsDeleteConfirmationDialogOpen(false);
      });
  };

  const handlePublish = ({ id, status }) => {
    dispatch(
      updateProductStatus({
        id,
        status,
      })
    )
      .then(unwrapResult)
      .then(() => {
        setIsPublishConfirmationDialogOpen(false);
      });
  };

  const handleAssignSerialNumber = ({ product, branchList }) => {
    setProduct(product);
    setBranchList(branchList);
    setIsAssignModalOpen(true);
  };

  const handleSelectProduct = (index, isSelected) => {
    dispatch(changeSelectedProduct({ index, isSelected }));
  };

  const handleSelectAvailable = () => {
    dispatch(changeBulkSelectedAvailableProduct({ value: true }));
  };

  const handleSelectAll = () => {
    setIsSelectAll(true);
    dispatch(changeBulkSelectedProduct({ value: true }));
  };

  const handleDeselectAll = () => {
    setIsSelectAll(false);
    dispatch(changeBulkSelectedProduct({ value: false }));
  };

  const resetFilter = () => {
    let newData = {
      ...tableFilter,
      page: 0,
      nodes: "",
      branch: [],
      category: "",
      categoryList: [],
      brand: "",
      model: "",
      brandModelList: [],
      attribute: "",
      attributeList: [],
      options: "",
      status: "",
    };
    setIsFilterUpdated(true);
    setTableFilter(newData);
  };

  const handleBulkUpdate = (values) => {
    const tempFilter = {
      length: tableFilter.rowsPerPage,
      start: tableFilter.rowsPerPage * tableFilter.page,
      search: tableFilter.search,
      status:
        tableFilter.status === ""
          ? ""
          : tableFilter.status === "Published"
          ? "published"
          : "unpublished",
      attribute: !tableFilter.attribute
        ? []
        : Array.from(tableFilter.attribute.split(",")),
      nodes: !tableFilter.nodes ? [] : Array.from(tableFilter.nodes.split(",")),
      category: !tableFilter.category
        ? []
        : Array.from(tableFilter.category.split(",")),
      brand: !tableFilter.brand ? [] : Array.from(tableFilter.brand.split(",")),
      model: !tableFilter.model ? [] : Array.from(tableFilter.model.split(",")),
    };

    const tempUpdate = {
      status:
        values === getLang(lang, "label.PUBLISH_SELECTED")
          ? "published"
          : "unpublished",
      isselectedall: isSelectAll,
      uuid: selectedIds,
    };

    if (values !== getLang(lang, "label.BULK_EDIT")) {
      dispatch(
        updateBulkProductStatus({
          filter: tempFilter,
          update: tempUpdate,
        })
      ).then(() => {
        dispatch(
          bulkUpdateStatus({
            filter: tempFilter,
            update: tempUpdate,
          })
        );
      });
    } else {
      handleBulkEdit();
    }
  };

  const handleQuickEdit = (index, isEdit) => {
    handleDeselectAll();
    dispatch(changeProductQuickEdit({ index, isEdit }));
  };

  const handleSubmitQuickEdit = (product) => {
    dispatch(quickUpdateProduct(product));
  };

  const handleBulkEdit = () => {
    dispatch(
      setBulkEditListFilter({
        isselectedall: isSelectAll,
        uuids: selectedIds,
        search: tableFilter.search,
        nodes: !tableFilter.nodes
          ? []
          : Array.from(tableFilter.nodes.split(",")),
        category: tableFilter.category
          ? []
          : Array.from(tableFilter.category.split(",")),
        brand: !tableFilter.brand
          ? []
          : Array.from(tableFilter.brand.split(",")),
        model: !tableFilter.model
          ? []
          : Array.from(tableFilter.model.split(",")),
        attribute: !tableFilter.attribute
          ? []
          : Array.from(tableFilter.attribute.split(",")),
        attributeoption: !tableFilter.options
          ? []
          : Array.from(tableFilter.options.split(",")),
      })
    );
    dispatch(resetProductListing());
    handleRedirectPage(BULK_EDIT);
  };

  const handleRedirectPage = (url) => {
    if (isFilterUpdated) {
      updateUrlQueryParam({ is_filter: true });
      sessionStorage.setItem("product_filter", JSON.stringify(tableFilter));
    }
    history.push(url);
  };

  return (
    <>
      <ProductListPanelComponent
        hasProductEditAccessRight={hasProductEditAccessRight}
        hasProductAddAccessRight={hasProductAddAccessRight}
        hasProductAssignAccessRight={hasProductAssignAccessRight}
        hasProductDeleteAccessRight={hasProductDeleteAccessRight}
        rows={products}
        page={tableFilter.page}
        rowsPerPage={tableFilter.rowsPerPage}
        paginationOptions={paginationOptions}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        selectedCount={
          !isSelectAll ? selectedIds.length : totalFiltered - selectedIds.length
        }
        isFetching={isFetching}
        isSelectAll={isSelectAll}
        selectedIds={selectedIds}
        isSelected={
          (!isSelectAll && selectedIds.length > 0) ||
          (isSelectAll && selectedIds.length < totalFiltered)
        }
        isError={isError}
        totalFiltered={totalFiltered}
        handleReload={getData}
        handleSearch={handleSearch}
        isSearchResult={isSearchResult}
        handleCreate={handleCreate}
        handleEdit={handleEdit}
        handleAssignSerialNumber={handleAssignSerialNumber}
        handleStatusChange={handleStatusChange}
        handleDeleteClick={handleDeleteClick}
        isFilterOpen={isFilterOpen}
        setIsFilterOpen={setIsFilterOpen}
        handleSelectProduct={handleSelectProduct}
        handleSelectAvailable={handleSelectAvailable}
        handleSelectAll={handleSelectAll}
        handleDeselectAll={handleDeselectAll}
        categoryDropdown={categoryDropdown}
        handleBulkUpdate={handleBulkUpdate}
        handleQuickEdit={handleQuickEdit}
        isQuickEdit={isQuickEdit}
        brandModelDropdown={brandModelDropdown}
        isBrandModelDropdownLoading={isBrandModelDropdownLoading}
        handleBrandModelSearch={handleBrandModelSearch}
        attributeDropdown={attributeDropdown}
        handleAttributeSearch={handleAttributeSearch}
        isAttributeLoading={isAttributeDropdownLoading}
        currencyDropdown={currencyDropdown}
        handleSubmitQuickEdit={handleSubmitQuickEdit}
        lang={lang}
        tableFilter={tableFilter}
        resetFilter={resetFilter}
        clearSearch={clearSearch}
      />
      <ConfirmationDialog
        isOpen={isPublishConfirmationDialogOpen}
        handleClose={() => setIsPublishConfirmationDialogOpen(false)}
        handleProceed={() => handlePublish(publishTarget)}
        type={publishTarget.status === "published" ? "" : "danger"}
        description={
          publishTarget.status === "published"
            ? getLang(lang, "paragraph.PUBLISH_PRODUCT_QUESTION")
            : getLang(lang, "paragraph.UNPUBLISH_PRODUCT_QUESTION")
        }
        title={
          publishTarget.status === "published"
            ? getLang(lang, "paragraph.PUBLISH_PRODUCT_TITLE")
            : getLang(lang, "paragraph.UNPUBLISH_PRODUCT_TITLE")
        }
        isLoading={isPublishing}
      />
      <ConfirmationDialog
        isOpen={isDeleteConfirmationDialogOpen}
        handleClose={() => setIsDeleteConfirmationDialogOpen(false)}
        handleProceed={() => handleDelete({ id: deleteTargetId })}
        type={"danger"}
        description={getLang(lang, "paragraph.DELETE_PRODUCT_QUESTION")}
        title={getLang(lang, "label.DELETE_PRODUCT")}
        isLoading={isDeleting}
      />
      <AssignSerialNumberDialog
        isOpen={isAssignModalOpen}
        handleClose={() => setIsAssignModalOpen(false)}
        product={product}
        branchList={branchList}
      />
    </>
  );
}

export default ProductListPanelContainer;
