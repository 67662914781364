import React from "react";
import {
  makeStyles,
  ButtonBase,
  Typography,
  Box,
  // Tooltip,
} from "@material-ui/core";
// import {withStyles} from "@material-ui/core/styles";
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import clsx from "clsx";
import DefaultImg from "../../../../../assets/img/img-default.png";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "5px 12px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#ECEFF0",
    borderRadius: "4px",
    width: "100%"
  },
  inputText: {
    color: "#a4a6a8",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontWeight: 500,
  },
  selectedField: {
    display: "flex",
    alignItems: "center"
  },
  disabledField: {
    backgroundColor: "#eceff0"
  },
  filled: {
    minWidth: "auto",
    fontWeight: 500,
    color: theme.palette.primary.main,
    display: "inline-block",
    flexWrap: "wrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "100%",
    alignSelf: "center"
  },
  filledWithNum: {
    minWidth: "auto",
    fontWeight: 500,
    color: theme.palette.primary.main,
    display: "inline-block",
    flexWrap: "wrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "90%",
    alignSelf: "center"
  },
  textNum: {
    fontWeight: 500,
    color: theme.palette.primary.main,
    display: "inline-block",
  },
  chip: {
    backgroundColor: "#FFF",
    marginRight: "0.25em",
    marginBottom: "0.15em",
    marginTop: "0.15em",
    border: "none",
    boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.1), 0px 2px 2px 0px rgba(0,0,0,0.04), 0px 1px 5px 0px rgba(0,0,0,0.02)"
  },
  tooltip: {
    maxHeight: "150px",
    overflow: "auto",
    display: "grid",
    '&::-webkit-scrollbar': {
      width: '0.3em'
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'rgba(255,255,255,1)',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '2px',
      backgroundColor: '#ccd1d3',
    }
  },
  tooltipChip: {
    backgroundColor: "#FFF",
    margin: "0.5em 0.3em 0.5em 0.3em",
    display: "flex",
    justifyContent: "space-between",
    boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.1), 0px 2px 2px 0px rgba(0,0,0,0.04), 0px 1px 5px 0px rgba(0,0,0,0.02)"
  },
  attributeText: {
    color: "#b2b2b2",
    fontSize: 12,
    whiteSpace: "break-spaces",
    textAlign: "start",
  },
  productPhoto: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #E4E7EC",
    width: "30px",
    height: "30px",
    marginRight: "10px",
    borderRadius: "4px",
    "& img": {
      height: "30px",
      width: "30px",
      borderRadius: "4px",
    },
  },
}));

export default function ProductSearchFieldComponent({
  handleClick,
  value,
  placeholder,
  disabled,
  style,
  isEmpty,
  id,
  open,
  textStyle,
  drawerOpen,
  items,
}) {
  const classes = useStyles();

  const item = items.find(i => i.id === value);

  return (
    <ButtonBase
      aria-describedby={id}
      className={clsx(classes.root, {
        [classes.disabledField]: disabled
      })}
      disableRipple
      onClick={handleClick}
      disabled={disabled}
      style={{ padding: 12, ...style }}
    >
      {value === "" ? (
        <Typography
          variant='body2'
          className={clsx(classes.inputText, {
            [classes.filled]: !isEmpty
          })}
          style={textStyle}
        >
          {placeholder}
        </Typography>
      ) : (
        <Box display="flex" alignItems="center" py={0.5}>
          <Box className={classes.productPhoto}>
            {item.picture === "" ? (
              <img src={DefaultImg} alt="emptyProduct" />
            ) : (
              <img src={item.picture} alt={item.name} />
            )}
          </Box>
          <Box>
            <Typography variant="body1">
              <b>{item.name}</b>
            </Typography>
            {!!item.attributes &&
              !!item.attributes.length && (
                <Typography
                  className={classes.attributeText}
                  variant="body2"
                >
                  {item.attributes.join(", ")}
                </Typography>
              )}
          </Box>
        </Box>
      )}
      <Box
        style={{
          display: "flex",
          alignItems: "center"
        }}
      >
        {/*{isClearable && value !== "" && (*/}
        {/*  <Button*/}
        {/*    className={classes.clearButton}*/}
        {/*    onClick={(e) => {*/}
        {/*      e.stopPropagation()*/}
        {/*      handleClear()*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    <ClearIcon style={{ fontSize: 16 }} />*/}
        {/*  </Button>*/}
        {/*)}*/}
        
        {open ? (
          <ArrowDropUpIcon fontSize='small'/>
        ) : (
          <ArrowDropDownIcon fontSize='small'/>
        )}
      </Box>
    </ButtonBase>
  )
}
