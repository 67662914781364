import axios from "axios";

// const s3Url = "http://localhost:3000" // Switch to this URL if want to test language files locally
const s3Url = process.env.REACT_APP_S3_BUCKET_URL

export const getLanguages = (locale = "EN") => {
    return new Promise((resolve, reject) => {
        axios.get(`${s3Url}/constants/customer/ui/${locale}.json`, {transformRequest: [(data, headers) => {
            delete headers.common.Authorization;
            return data;
        }]})
            .then(response => resolve(response.data))
            .catch(error => reject(error));
    });
};

export const getApiLanguages = (locale = "EN") => {
    return new Promise((resolve, reject) => {
        axios.get(`${s3Url}/constants/customer/api/${locale}.json`, {transformRequest: [(data, headers) => {
            delete headers.common.Authorization;
            return data;
        }]})
            .then(response => resolve(response.data))
            .catch(error => reject(error));
    });
};
