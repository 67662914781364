import { createAsyncThunk } from "@reduxjs/toolkit";
import { LOGIN } from "../../constants";
import { Auth0Client } from "app/feature";
import authApi from "app/api/authentication";

export const login = createAsyncThunk(
  LOGIN,
  async (payload, { rejectWithValue }) => {
    const { username, password, rememberMe } = payload;

    return authApi.signIn({
      username,
      password,
      rememberMe
    })
      .then(response => response.data.data)
      .catch(error => rejectWithValue(error.response.data))
  }
);

export const parseHash = createAsyncThunk(
  `${LOGIN}/parseHash`,
  async (payload, { rejectWithValue }) => {
    return new Auth0Client()
      .parseHash(payload)
      .then(user => user)
      .catch(error => {
        /**
         * error schema for access grant denied
         * error: "access_denied"
         * errorDescription: "User did not authorize the request"
         * state: "vod2u_2xYUArwym28tAHGfiwyZ5rJ8fz"
         */
        return rejectWithValue(error);
      });
  }
);
