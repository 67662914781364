import getMasterInfo from "./getMasterInfo.api";
import updateCompanyProfilePic from "./updateCompanyProfilePic.api";
import updateAccountSetting from "./updateAccountSetting.api";
import getAccessRights from "./getAccessRights.api";
import fetchLabel from "./fetchLabel.api";
import profile from "./profile.api";
import company from "./company.api";
import companySetting from "./companySetting.api";
import getAllBranches from "./getAllBranches.api";
import createBranch from "./createBranch.api";
import editBranch from "./editBranch.api";
import editBranchStatus from "./editBranchStatus.api";
import branchDropdown from "./branchDropdown.api";
import BranchAdminDropdown from "./branchAdminDropdown.api";
import verifyEmployee from "./verifyEmployee.api";
import signupEmployee from "./signupEmployee.api";
import bootstrap from "./bootstrap.api";
import changePassword from "./changePassword.api";
import checkResendVerificationEmailResendTimeLeft from "./checkResendVerificationEmailResendTimeLeft.api";
import resendVerificationEmail from "./resendVerificationEmail.api";
import checkResetPasswordResendTimeLeft from "./checkResetPasswordResendTimeLeft.api";
import requestResetPassword from "./requestResetPassword.api";
import getCurrencyConfigListing from "./currencyConfig/getCurrencyConfigListing.api";
import createCurrencyConfig from "./currencyConfig/createCurrencyConfig.api";
import getUnusedCurrencyConfigDataDropdown from "./currencyConfig/getUnusedCurrencyConfigData.api";
import removeCurrencyConfig from "./currencyConfig/removeCurrencyConfig.api";
import updateCurrencyConfigStatus from "./currencyConfig/updateCurrencyConfigStatus.api";
import updateDefaultCurrencyConfig from "./currencyConfig/updateDefaultCurrencyConfig.api";
import bulkUpdateCurrencyConfigStatus from "./currencyConfig/bulkUpdateCurrencyConfigStatus.api";
import { getConfiguredCurrencyDropdownList } from "./currencyConfig/getCurrencyDropdown.api";
import getCollaborationGroupByTypeAndCode from "./getCollaborationGroupByTypeAndCode.api";
import hqEditBranchDropdown from "./hqEditBranch.api";
import BranchDropdownSpecial from "./branchDropdownSpecial.api";
import createBranchGroup from "./createBranchGroup.api";
import getAllBranchGroups from "./getAllBranchGroups.api";
import getAvailableBranches from "./getAvailableBranches.api";
import getBranchGroupSummary from "./getBranchGroupSummary.api";
import updateGroupBranches from "./updateGroupBranches.api";
import deleteBranchGroup from "./deleteBranchGroup.api";
import getGroupRules from "./getGroupRules.api";
import getAllAvailableBranchesForRules from "./getAllAvailableBranchesForRules.api";
import getBranchesInGroup from "./getBranchesInGroup.api";
import updateGroupRules from "./updateGroupRules.api";
import editBranchGroup from "./editBranchGroup.api";
import getBranchGroupDropdown from "./getBranchGroupDropdown.api";
import { checkFeatureAvailability } from "./checkFeatureAvailability.api";
import checkMaintenanceAlert from "./checkMaintenanceAlert.api";
import setBranchProfile from "./setBranchProfile";
import getCompanyDisbursement from "./getCompanyDisbursement.api";

import getCampaignTngDisbursementList from "./disbursement/getCampaignTngDisbursementList.api";
import getCreditBalanceReloadLog from "./disbursement/getCreditBalanceReloadLog.api";
import getCreditAlertInfo from "./disbursement/getCreditAlertInfo.api";
import updateCreditAlertInfo from "./disbursement/updateCreditAlertInfo.api";
import getCreditOverview from "./disbursement/getCreditOverview.api";
import exportCreditReport from "./disbursement/exportCreditReport.api";

const accountApi = {
  profile,
  company,
  companySetting,
  getMasterInfo,
  getAccessRights,
  updateCompanyProfilePic,
  updateAccountSetting,
  fetchLabel,
  getAllBranches,
  createBranch,
  editBranch,
  editBranchStatus,
  branchDropdown,
  BranchAdminDropdown,
  verifyEmployee,
  signupEmployee,
  bootstrap,
  changePassword,
  checkResendVerificationEmailResendTimeLeft,
  resendVerificationEmail,
  checkResetPasswordResendTimeLeft,
  requestResetPassword,
  getCurrencyConfigListing,
  createCurrencyConfig,
  getUnusedCurrencyConfigDataDropdown,
  removeCurrencyConfig,
  updateCurrencyConfigStatus,
  updateDefaultCurrencyConfig,
  bulkUpdateCurrencyConfigStatus,
  getConfiguredCurrencyDropdownList,
  getCollaborationGroupByTypeAndCode,
  hqEditBranchDropdown,
  BranchDropdownSpecial,
  createBranchGroup,
  getAvailableBranches,
  getAllBranchGroups,
  getBranchGroupSummary,
  updateGroupBranches,
  deleteBranchGroup,
  getGroupRules,
  getAllAvailableBranchesForRules,
  getBranchesInGroup,
  updateGroupRules,
  editBranchGroup,
  getBranchGroupDropdown,
  checkFeatureAvailability,
  checkMaintenanceAlert,
  setBranchProfile,
  getCompanyDisbursement,

  getCampaignTngDisbursementList,
  getCreditBalanceReloadLog,
  getCreditAlertInfo,
  updateCreditAlertInfo,
  getCreditOverview,
  exportCreditReport,
};

export default accountApi;
