import React from "react";
import { Formik } from "formik";
import {
  Box,
  Paper,
  makeStyles,
  Typography,
  Divider,
  MenuItem,
  Grid
} from "@material-ui/core";
import BgImagePanel from "../bgImagePanel";
import SingleColorPickerPanel from "../singleColorPickerPanel";
import PreviewConsumerPagePanel from "../previewConsumerPagePanel";
import InputTextField from "components/input/inputTextField";
import { SkeletonSettingComponent } from "../../skeleton/skeleton.component";
import { getLang } from "app/feature/constants";
import BannerPanel from "../bannerPanel";


const style = theme => ({
  title: {
    padding: theme.spacing(2, 3, 2, 3),
    fontWeight: "500"
  },
  text: {
    padding: theme.spacing(1, 3, 1, 3),
    fontWeight: "500"
  },
  button: {
    display: "flex",
    justifyContent: "flex-end"
  }
});

const useStyles = makeStyles(style);

export default function LandingPageThemeSettingPanelComponent({
  hasCompanyEditAccessRight,
  mdWidth,
  settings,
  isFetching,
  handleChange,
  lang
}) {
  const BG_TYPE = [getLang(lang, "label.IMAGE"), getLang(lang, "label.SOLID_COLOR")];
  const BG_TYPE_VALUE = ["Image", "Solid color"];

  const classes = useStyles();
  return (
    <Box>
      <Paper elevation={0}>
        <Typography variant="body1" color="primary" className={classes.title}>
          {getLang(lang, "label.LANDING_PAGE_UI_SETTING")}
        </Typography>

        <Divider />
        {isFetching || settings.uiSetting.length === 0 ? (
          <SkeletonSettingComponent />
        ) : (
          <Formik
            initialValues={{
              bgType: settings.uiSetting.bgType,
              bgColor: settings.uiSetting.bgColor,
              bgImage: settings.uiSetting.bgImage,
              bannerImage: settings.uiSetting.bannerImage,
              colorPrimary: settings.uiSetting.colorPrimary,
              colorSecondary: settings.uiSetting.colorSecondary
            }}
          >
            {(formik) => (
              <form>
                <Box mb={2}>
                  {mdWidth ? null : (
                    <Typography variant="body2" className={classes.text}>
                      {getLang(lang, "label.LOOK_AND_FEEL")}
                    </Typography>
                  )}
                  <PreviewConsumerPagePanel
                    colorPrimary={formik.values.colorPrimary}
                    colorSecondary={formik.values.colorSecondary}
                    bgType={formik.values.bgType}
                    bgColor={formik.values.bgColor}
                    bgImage={formik.values.bgImage}
                    bannerImage={formik.values.bannerImage}
                  />
                </Box>

                <>
                  <Box>
                    <Typography
                      variant="body2"
                      color="primary"
                      className={classes.text}
                    >
                      {getLang(lang, "label.THEME_SETTING")}
                    </Typography>

                    <Box px={3} my={1} mt={2}>
                      <Typography variant="body2" color="textSecondary">
                        {getLang(lang, "label.BANNER_SETTING")}
                      </Typography>
                    </Box>

                    <Box my={1} mt={2}>
                      <BannerPanel
                        image={formik.values.bannerImage}
                        handleChange={(value) => {
                          formik.setFieldValue("bannerImage", value)
                          handleChange("bannerImage", value)
                        }}
                      />
                    </Box>

                    <Box pl={3} pr={3} style={{ display: "flex" }}>
                      <Grid
                        container
                        style={{
                          alignItems: "center",
                          marginBottom: "9px",
                        }}
                      >
                        <Grid
                          item
                          xs={4}
                          sm={4}
                          md={4}
                          style={{ width: "100%" }}
                        >
                          <Typography variant="body2" color="textSecondary">
                            {getLang(lang, "label.BACKGROUND")}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={8}
                          sm={8}
                          md={8}
                          style={{ width: "100%" }}
                        >
                          <InputTextField
                            select
                            name="Background"
                            hiddenLabel
                            variant="filled"
                            size="small"
                            fullWidth
                            margin="dense"
                            {...formik.getFieldProps("bgType")}
                            disabled={!hasCompanyEditAccessRight || formik.isSubmitting}
                            onChange={(e) => {
                              formik.setFieldValue("bgType", e.target.value)
                              handleChange(e.target.name, e.target.value)
                              formik.setFieldValue("bgImage", "")
                              handleChange("bgImage", "")
                            }}
                          >
                            {BG_TYPE.map((option, index) => (
                              <MenuItem key={index} value={BG_TYPE_VALUE[index]}>
                                {option}
                              </MenuItem>
                            ))}
                          </InputTextField>
                        </Grid>
                      </Grid>
                    </Box>
                    {formik.values.bgType === BG_TYPE_VALUE[0] && (
                      <Box my={1}>
                        <BgImagePanel
                          image={formik.values.bgImage}
                          handleChange={(c) =>{
                            formik.setFieldValue("bgImage", c)
                            handleChange("bgImage", c)
                          }}
                        />
                      </Box>
                    )}
                    <Box my={1}>
                      <SingleColorPickerPanel
                        label={getLang(lang, "label.BACKGROUND_COLOR")}
                        color={formik.values.bgColor}
                        disabled={!hasCompanyEditAccessRight}
                        handleChange={(c) => {
                          formik.setFieldValue("bgColor", c)
                          handleChange("bgColor", c)
                        }}
                      />
                    </Box>
                    <Box my={1}>
                      <SingleColorPickerPanel
                        label={getLang(lang, "label.PRIMARY_COLOR")}
                        color={formik.values.colorPrimary}
                        disabled={!hasCompanyEditAccessRight}
                        handleChange={(c) => {
                          formik.setFieldValue("colorPrimary", c)
                          handleChange("colorPrimary", c)
                        }}
                      />
                    </Box>
                    <Box my={1}>
                      <SingleColorPickerPanel
                        label={getLang(lang, "label.SECONDARY_COLOR")}
                        color={formik.values.colorSecondary}
                        disabled={!hasCompanyEditAccessRight}
                        handleChange={(c) =>{
                          formik.setFieldValue("colorSecondary", c)
                          handleChange("colorSecondary", c)
                        }}
                      />
                    </Box>
                  </Box>
                </>
              </form>
            )}
          </Formik>
        )}
      </Paper>
    </Box>
  );
}
