import axios from "axios";

const get = ({ id }) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/consumer/admin/api/v1/warranty/${id}`)
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

const getConsumerWarrantyDetail = {
  get
};

export default getConsumerWarrantyDetail;
