import { Box, Checkbox, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Typography, makeStyles } from "@material-ui/core";
import React from "react";
import { ContentCard } from "../../layout";
import { cardChartStyle, flexGapStyle, fontTextStyle, tableStyle } from "modules/reporting/styles";
import downloadjs from 'downloadjs';
import html2canvas from 'html2canvas';
import { Link } from "react-router-dom";
import moment from "moment";
import { drilldownPageSelection, numberFormatteer, responseHandler } from "modules/reporting/utils/helper";
import { DownloadIcon } from "modules/reporting/assets/svg";
import { BoxLoading } from "../../skeleton/loading";
import { generatePaginationOptions } from "lib/helper";
import { useEffect } from "react";
import Chart from 'react-apexcharts';
import { useState } from "react";
import ApexCharts from 'apexcharts'
import { setChartColors } from "modules/reporting/utils/chartConfig";
import { TablePaginationActions } from "components/tablePagination";
import { CampaignConversionsNoData } from "../../skeleton/no-data";
import { CampaignConversionsNoResult } from "../../skeleton/no-result";


const checkBoxStyles = makeStyles({
    checkbox__cell: {
        color: props => props.color
    }
})

export default function StockoutBrandPanelComponent({
    translate,
    data,
    status,
    sort,
    pagination,
    currentFilter,
    pageType
}){
    const fontClasses = fontTextStyle();
    const flexGapClasses = flexGapStyle();
    const tableClasses = tableStyle();
    const chartClasses = cardChartStyle();
    const [selected, setSelected] = useState([]);

    const [paginationOptions, setPaginationOptions] = useState([]);
    useEffect(() => {
        setPaginationOptions(generatePaginationOptions(data.dealerStockoutByBrandData ? data.dealerStockoutByBrandData.total_record : 0, [10, 25, 50, 100]))
    }, [data]);

    const columns = [
        {
            title: translate("label.NUM"),
            field: null,
            render: (row) => {
                return (
                    <>{row.title}</>
                )
            },
            align: "left",
            size: "medium"
        },
        {
            title: translate("label.BRANDS"),
            field: null,
            render: (row) => {
                return (
                    <>{row.title}</>
                )
            },
            align: "left",
            size: "medium"
        },
        // {
        //     title: translate("label.TOTAL_STOCKOUT"),
        //     field: "stockout_count",
        //     render: (row) => {
        //         return (
        //             <>{row.title}</>
        //         )
        //     },
        //     size: "small",
        //     align: "left"
        // },
        // {
        //     title: translate("label.TOTAL_STOCKOUT_WEIGHTAGE"),
        //     field: null,
        //     render: (row) => {
        //         return (
        //             <>{row.title}</>
        //         )
        //     },
        //     size: "small",
        //     align: "left"
        // },
        {
            title: translate("label.TOTAL_PACKAGE"),
            field: "package_count",
            render: (row) => {
                return (
                    <>{row.title}</>
                )
            },
            size: "small",
            align: "left"
        },
        // {
        //     title: translate("label.TOTAL_PACKAGE_WEIGHTAGE"),
        //     field: null,
        //     render: (row) => {
        //         return (
        //             <>{row.title}</>
        //         )
        //     },
        //     size: "small",
        //     align: "left"
        // },
        {
            title: translate("label.TOTAL_REQUEST"),
            field: "request_count",
            render: (row) => {
                return (
                    <>{row.title}</>
                )
            },
            size: "small",
            align: "left"
        },
        // {
        //     title: translate("label.TOTAL_REQUEST_WEIGHTAGE"),
        //     field: null,
        //     render: (row) => {
        //         return (
        //             <>{row.title}</>
        //         )
        //     },
        //     size: "small",
        //     align: "left"
        // },
        {
            title: translate("label.REJECTED"),
            field: "rejected_count",
            render: (row) => {
                return (
                    <>{row.title}</>
                )
            },
            size: "small",
            align: "left"
        }
    ]

    const handleDownloadCard = () => {
        let element = document.getElementById("stockout-by-brand");

        html2canvas(element).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            downloadjs(imgData, 'stockout-by-brand.png');
        });

    }

    // apexchart configuration & function
    const chartID = "stockout-product-chart";

    const CheckboxComponent = ({children, ...props}) => {
        const { checkbox__cell } = checkBoxStyles(props);
        const { checked, onClick, inputProps } = props;

        return (
            <Checkbox
                className={`${checkbox__cell}`}
                checked={checked}
                onClick={onClick}
                inputProps={inputProps}
            />
        )
    }

    const onSelectAllClick = (event) => {
        if (!event.target.checked){
            const newSelecteds = data.dealerStockoutByBrandData.rows.map((n) => n.name);
            hideAll();
            setSelected(newSelecteds);
        } else {
            showAll();
            setSelected([]);
        }
    }

    const hideAll = () => {
        data.dealerStockoutByBrandData.rows.forEach((n) => {
            ApexCharts.exec(chartID, 'hideSeries', n.name);
        })
    }

    const showAll = () => {
        data.dealerStockoutByBrandData.rows.forEach((n) => {
            ApexCharts.exec(chartID, 'showSeries', n.name);
        })
    }

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
        toggle(name)
    }

    const toggle = (name) => {
        ApexCharts.exec(chartID, 'toggleSeries', name);
    }

    const createSortHandler = (property) => (event) => {
        sort.handleRequestSort(event, property);
    };

    // render table data
    const displayData = (ref) => {
        let rows = [];
        if(Object.keys(ref).length === 0 || !ref.isSuccess){
            return rows;
        }

        const colorSet = setChartColors(ref.chart.datasets.length, true);

        const options = {
            chart: {
                id: chartID
            },
            tooltip:{
                followCursor: true,
                y: {
                    formatter: function (val) {
                        return parseInt(val)
                    }
                }
            },
            xaxis: {
                categories: ref.chart.labels,
                labels: {
                    rotateAlways: true,
                    rotate: -45,
                    hideOverlappingLabels: true,
                },
                tickPlacement: 'between',
            },
            legend: {
                position: 'bottom',
                showForSingleSeries: true,
                onItemClick: {
                    toggleDataSeries: false
                }
            },
            grid: {
                padding: {
                    left: 12,
                    right: 12,
                }
            },
            stroke: {
                width: 2,
            },
            colors: colorSet
    
        }

        let no_iteration = pagination.rowsPerPage * pagination.page + 1;

        return (
            <Box
                className={chartClasses.card}    
            >
                <Chart
                    options={options}
                    series={ref.chart.datasets}
                    type="line"
                    height="430px"
                ></Chart>
                <TableContainer component={ Paper }>
                    <Box style={{
                        overflowX: "auto",
                        width: "100%"
                    }}>
                        <Table>
                            <TableHead>
                                <TableRow
                                    hover
                                >
                                    <TableCell
                                        padding="checkbox"
                                        className={tableClasses.table__cell}
                                    >
                                        <Checkbox 
                                            onChange={onSelectAllClick}
                                            indeterminate={selected.length > 0 && selected.length < ref.rows.length}
                                            checked={ref.rows.length > 0 && selected.length === 0}
                                        />
                                    </TableCell>
                                    {columns.map((column, index) => (
                                        <TableCell className={[tableClasses.table__cell, fontClasses.text__header_table_cell]} key={column.title} size={column.size}>
                                            {
                                                column.field ? 
                                                (
                                                    <TableSortLabel
                                                        active={sort.valueToOrderBy === column.field ? true : false}
                                                        direction={sort.valueToOrderBy === column.field ? sort.orderDirection : 'desc'}
                                                        onClick={!status.isFetchingDealerStockoutByBrand && column.field ? createSortHandler(column.field) : null}
                                                    >
                                                        {column.render(column)}
                                                    </TableSortLabel>
                                                ) : 
                                                column.render(column)
                                            }
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {ref.rows.map((item, index) => {
                                    const isItemSelected = selected.indexOf(item.name) !== -1;
                                    const labelId = `enhanced-table-checkbox-${item.name}`;

                                    return (
                                        <TableRow
                                            hover
                                            aria-checked={isItemSelected}
                                            key={item.name}
                                            selected={isItemSelected}
                                        >
                                            <TableCell 
                                                padding="checkbox"
                                                className={tableClasses.table__cell}
                                            >
                                                <CheckboxComponent 
                                                    checked={!isItemSelected}
                                                    onClick={(event) => handleClick(event, item.name)}
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                    color={colorSet[index] + ' !important'}
                                                />
                                            </TableCell>
                                            <TableCell className={tableClasses.table__cell} style={{width: "20px"}}>
                                                <Typography className={fontClasses.text__table_cell}>{no_iteration++}</Typography>
                                            </TableCell>
                                            <TableCell className={tableClasses.table__cell} style={{width:"400px", minWidth:"400px"}}>
                                                <Link
                                                    target="_blank" rel="noopener noreferrer" 
                                                    className={fontClasses.text__hyperlink}
                                                    to={() => {
                                                        let filterProperties = {};

                                                        if(currentFilter.countryCode)
                                                            filterProperties.countryCode = currentFilter.countryCode;
                                                        
                                                        if(currentFilter.cityUuid)
                                                            filterProperties.cityUuid = currentFilter.cityUuid;
                                                        
                                                        if(item.uuid)
                                                            filterProperties.brandUuid = item.uuid;

                                                        if(currentFilter.period)
                                                            filterProperties.period = currentFilter.period;

                                                        if(currentFilter.dateRange){
                                                            filterProperties.start = moment(currentFilter.dateRange[0]).format('YYYY-MM-DD');
                                                            filterProperties.end = moment(currentFilter.dateRange[1]).format('YYYY-MM-DD');
                                                        }

                                                        if(currentFilter.productUuid)
                                                            filterProperties.productUuid = currentFilter.productUuid;

                                                        if(currentFilter.categoryUuid)
                                                            filterProperties.categoryUuid = currentFilter.categoryUuid;

                                                        if(currentFilter.modelUuid)
                                                            filterProperties.modelUuid = currentFilter.modelUuid;

                                                        if(currentFilter.branchUuid)
                                                            filterProperties.branchUuid = currentFilter.branchUuid;

                                                        let url = drilldownPageSelection(filterProperties, pageType, true);
                                                            return url;
                                                    }}
                                                
                                                >
                                                    {translate(item.name)}
                                                </Link>
                                            </TableCell>
                                            {/* <TableCell className={tableClasses.table__cell} align="right" style={{width: "120px", minWidth: "120px"}}>
                                                <Typography className={fontClasses.text__table_cell}>
                                                    {numberFormatteer(item.total_stockout)}
                                                </Typography>
                                            </TableCell> */}
                                            {/* <TableCell className={tableClasses.table__cell} align="right" style={{width: "120px", minWidth: "120px"}}>
                                                <Typography className={fontClasses.text__table_cell}>
                                                    {item.stockout_percentage}
                                                </Typography>
                                            </TableCell> */}
                                            <TableCell className={tableClasses.table__cell} align="right" style={{width: "120px", minWidth: "120px"}}>
                                                <Typography className={fontClasses.text__table_cell}>
                                                    {numberFormatteer(item.total_package)}
                                                </Typography>
                                            </TableCell>
                                            {/* <TableCell className={tableClasses.table__cell} align="right" style={{width: "120px", minWidth: "120px"}}>
                                                <Typography className={fontClasses.text__table_cell}>
                                                    {item.package_percentage}
                                                </Typography>
                                            </TableCell> */}
                                            <TableCell className={tableClasses.table__cell} align="right" style={{width: "120px", minWidth: "120px"}}>
                                                <Typography className={fontClasses.text__table_cell}>
                                                    {numberFormatteer(item.total_request)}
                                                </Typography>
                                            </TableCell>
                                            {/* <TableCell className={tableClasses.table__cell} align="right" style={{width: "120px", minWidth: "120px"}}>
                                                <Typography className={fontClasses.text__table_cell}>
                                                    {item.request_percentage}
                                                </Typography>
                                            </TableCell> */}
                                            <TableCell className={tableClasses.table__cell} align="right" style={{width: "120px", minWidth: "120px"}}>
                                                <Typography className={fontClasses.text__table_cell}>
                                                    {numberFormatteer(item.total_rejected)}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </Box>
                    <TablePagination
                        ActionsComponent={TablePaginationActions}
                        component="div"

                        // rowsPerPageOptions={pagination.rowsPerPageOptions}
                        rowsPerPageOptions={paginationOptions}
                        rowsPerPage={pagination.rowsPerPage}
                        page={pagination.page}
                        count={ref.total_record ?? 0}
                        onChangePage={pagination.handleChangePage}
                        onChangeRowsPerPage={pagination.handleChangeRowPerPage}
                    />
                </TableContainer>
            </Box>
        )
    }

    return (
        <ContentCard>
            <Box
                display="flex"
                flexDirection="column"
                id="stockout-by-brand"
            >
                {/* title and download icon/toggle icon */}
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    margin="24px 0"
                >
                    <Typography className={fontClasses.text__card_title}>
                        {translate("label.STOCKOUT_BY_BRAND")}
                    </Typography>
                    <Box display="flex" alignContent="center" className={flexGapClasses.global__flexGap20}>
                        <IconButton title="Download Charts" aria-label="download" onClick={handleDownloadCard} disableRipple>
                            <DownloadIcon />
                        </IconButton>
                    </Box>
                </Box>

                {
                    responseHandler(
                        data.dealerStockoutByBrandData,
                        status.isFetchingDealerStockoutByBrand,
                        displayData(data.dealerStockoutByBrandData),
                        <BoxLoading />,
                        <CampaignConversionsNoData 
                            title={translate("label.DEALERSHIP_NOT_FOUND")}
                            value={translate("paragraph.NO_DATA_ON_SELECTED_FILTER")}
                            columnTitle={[translate("label.BRANDS"), translate("label.TOTAL_COUNT"), translate("label.TOTAL_REQUEST"), translate("label.REJECTED")]}
                            translate={translate}
                        />,
                        <CampaignConversionsNoResult 
                            title={translate("label.DEALERSHIP_NOT_FOUND")}
                            value={translate("paragraph.NO_DATA_ON_SELECTED_FILTER")}
                            columnTitle={[translate("label.BRANDS"), translate("label.TOTAL_COUNT"), translate("label.TOTAL_REQUEST"), translate("label.REJECTED")]}
                            translate={translate}
                        />,
                        <CampaignConversionsNoResult 
                            title={translate("label.LOADING_FAILED")}
                            value={translate("paragraph.LOADING_FAILED_DESCRIPTION")}
                            columnTitle={[translate("label.BRANDS"), translate("label.TOTAL_COUNT"), translate("label.TOTAL_REQUEST"), translate("label.REJECTED")]}
                            translate={translate}
                        />
                    )
                }
            </Box>
        </ContentCard>
    )
}