import React from "react"
import { makeStyles, Box, Button, MenuItem, Divider, TextField } from "@material-ui/core"
import { Formik } from "formik"
import { getLang } from "app/feature/constants"
import ProductListTable from "../../table/productListTable"

const useStyles = makeStyles((theme) => ({
  container: {
    background: "white",
    borderRadius: 4,
    padding: "24px 20px"
  },
  searchContainer: {
    backgroundColor: theme.palette.background.paper,
    display: "grid",
    gridTemplateColumns: "10fr 1fr 2fr",
    columnGap: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1)
    }
  },
  textFieldInput: {
    padding: "7px",
    paddingTop: "8px",
    minWidth: 170,
    fontWeight: 500,
    color: theme.palette.primary.main
  },
  divider: {
    height: "70%",
    placeSelf: "center"
  },
  dropdownSearchBox: {
    backgroundColor: "#E9EDEF",
    borderRadius: 4
  },
  inputRoot: {
    fontWeight: 500,
    paddingTop: "8px !important",
    paddingBottom: "7px !important",
    paddingLeft: "10px !important"
  },
  menuItemText: {
    padding: 10,
    fontWeight: 500
  },
  select: {
    minWidth: 170,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      minWidth: "auto"
    }
  },
}))

export default function DealerProductListPanelComponent({
  hasStockflowInventoryViewAccessRight,
  id,
  products,
  isFetching,
  isError,
  handleChangePage,
  handleChangeRowsPerPage,
  totalFiltered,
  page,
  rowsPerPage,
  handleSearch,
  clearSearch,
  handleReload,
  isSearchResult,
  paginationOptions,
  tableFilter,
  lang,
  handleViewDetail
}) {
  const classes = useStyles()

  const columnDropdown = [
    { name: getLang(lang, "label.PRODUCT"), value: "name" },
    { name: getLang(lang, "label.SERIAL_NUMBER"), value: "serial_number" },
    { name: getLang(lang, "label.SEQUENCE_NUMBER"), value: "sequence_no" },
  ]

  return (
    <>
      <Box className={classes.container}>
        <Formik
          initialValues={{
            searchColumn: tableFilter.searchColumn || columnDropdown[0].value,
            search: tableFilter.search,
          }}
          enableReinitialize
          onSubmit={(values, actions) => {
            handleSearch(values)
            actions.setSubmitting(false)
          }}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <Box mb={2} className={classes.searchContainer}>
                <Box display="flex"
                  className={classes.dropdownSearchBox}>
                  <TextField
                    className={classes.select}
                    select
                    SelectProps={{
                      MenuProps: {
                        MenuListProps: {
                          style: { padding: 0 }
                        },
                        style: { marginTop: 5 },
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left"
                        },
                        getContentAnchorEl: null
                      }
                    }}
                    value={formik.values.searchColumn}
                    size="small"
                    margin="none"
                    InputProps={{
                      disableUnderline: true,
                      classes: { input: classes.inputRoot }
                    }}
                  >
                    {columnDropdown.map((column, index) => (
                      <MenuItem
                        className={classes.menuItemText}
                        value={column.value}
                        key={index}
                        onClick={() => {
                          formik.setFieldValue("searchColumn", column.value)
                        }}
                      >
                        {column.name}
                      </MenuItem>
                    ))}
                  </TextField>
                  <Divider orientation="vertical" className={classes.divider} />
                  <TextField
                    fullWidth
                    size="small"
                    margin="none"
                    placeholder={getLang(lang, "placeholder.SEARCH")}
                    InputProps={{
                      disableUnderline: true,
                      margin: "none",
                    }}
                    inputProps={{
                      className: classes.textFieldInput
                    }}
                    {...formik.getFieldProps("search")}
                  />
                </Box>
                <Button
                  variant='text'
                  disableElevation
                  color="secondary"
                  size='small'
                  onClick={() => {
                    clearSearch()
                  }}
                >
                  {getLang(lang, "label.CLEAR")}
                </Button>
                <Button
                  disabled={isFetching}
                  variant="contained"
                  disableElevation
                  color='primary'
                  size='small'
                  type='submit'
                >
                  {getLang(lang, "label.SEARCH")}
                </Button>
              </Box>
            </form>
          )}
        </Formik>
        <Box mt={3}>
          <ProductListTable
            hasStockflowInventoryViewAccessRight={hasStockflowInventoryViewAccessRight}
            isDealerPage={true}
            rows={products}
            isFetching={isFetching}
            isError={isError}
            handleReload={handleReload}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            isSearchResult={isSearchResult}
            totalFiltered={totalFiltered}
            paginationOptions={paginationOptions}
            tableFilter={tableFilter}
            lang={lang}
            dealerId={id}
            handleViewDetail={handleViewDetail}
          />
        </Box>
      </Box>
    </>
  )
}
