import axios from "axios";

const get = ({ id }) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/account/api/v1/users/${id}`)
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

const put = ({ userGroupId, nodeIds, id }) => {
  const body = {
    user_group_id: userGroupId,
    node_ids: nodeIds,
  };

  return new Promise((resolve, reject) => {
    axios
      .put(`/account/api/v1/users/${id}`, body)
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

const post = (request) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/account/api/v1/users", request)
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

const remove = (userId) => {
  const body = {
    user_id: userId
  };

  return new Promise((resolve, reject) => {
    axios
      .post("/account/api/v1/users/delete", body)
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

const employee = {
  put,
  get,
  post,
  remove
};

export default employee;
