import React from "react";
import TextComponent from "./text.component";
import { useSelector } from "react-redux";

export default function TextContainer({ id, currentLanguage }) {
  const {
    isVisible,
    content: { data }
  } = useSelector(state => state.productPage.components[id]);
  const lang = useSelector(state => state.constant.languages);

  const showText = data.find((e) => e.lang === currentLanguage) ?? data[0];

  return (
    isVisible && <TextComponent text={showText} lang={lang} />
  );
}
