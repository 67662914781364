import axios from "axios";

const endpoint_url = "/serialnumber/api/v1/tags";
const postEndpoint_url = "/serialnumber/api/v1/tag";


const get = (parameters) => {
  return new Promise((resolve, reject) => {
    axios
      .get(endpoint_url
        ,{
        params:{
          length: parameters.length,
          start:parameters.start,
          search:parameters.search,
          order:parameters.order,
          column:parameters.column,
          draw:parameters.draw,
        }}
      )
      .then((response) => resolve(response))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

const post = ({ prefix, snDigit, postfix, serialType }) => {
  const body = {
    prefix: prefix,
    sn_digit: snDigit,
    postfix: postfix,
    serial_type: serialType
  };

  return new Promise((resolve, reject) => {
    axios
      .post(postEndpoint_url, body)
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

const tagSerialNumber = {
  get,
  post
};

export default tagSerialNumber;
