import React, { useEffect, useState } from "react";
import ViewProfilePageComponent from "./viewProfilePage.page";
import { useSelector } from 'react-redux'
import { useHistory } from "react-router-dom";
import { EMPLOYEE_VIEW } from 'lib/constants/accessRights'
import LoadingComponent from "components/loading";
import { getLang } from "app/feature/constants";

function ViewProfilePageContainer() {
  const history = useHistory()
  const hasEmployeeViewAccessRight = useSelector(state => state.profile.accessRights.includes(EMPLOYEE_VIEW));
  const lang = useSelector(state => state.constant.languages);
  
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    if(!hasEmployeeViewAccessRight){
      history.push('/admin/dashboard')
    }
  }, [history, hasEmployeeViewAccessRight])

  if (!hasEmployeeViewAccessRight) return <LoadingComponent />;

  const tabLabels = [getLang(lang, "label.INFO_SETTING"), getLang(lang, "label.ORDER_HISTORY")];

  const handleChange = (event, index) => {
    setActiveIndex(index);
  };

  return (
    <>
      <ViewProfilePageComponent
        handleChange={handleChange}
        activeIndex={activeIndex}
        tabLabels={tabLabels}
        lang={lang}
      />
    </>
  );
}

export default ViewProfilePageContainer;
