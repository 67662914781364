import { setLoading, addAlert } from "modules/notification";
import {
  fetchProfile,
  updateProfile,
  updateUserProfilePicture,
  updateUserProfilePictureAPI,
  changePassword
} from "../action";
import { uploadImageToS3 } from "app/feature";
import { setUserProfilePicture } from "../slice/profile.slice";
import { FolderType } from "lib/constants/aws_s3";
import { getApiLang, getApiLanguages, getLanguages } from "app/feature/constants";

export const fetchProfileMiddleware = ({ dispatch, getState }) => (next) => (action) => {
  next(action);

  const { type, payload } = action;

  switch (type) {
    case fetchProfile.pending.type:
      // dispatch set loading to notification reducer
      dispatch(setLoading({ id: fetchProfile.typePrefix, state: true }));
      break;

    case fetchProfile.fulfilled.type:
      // dispatch remove loading from notification reducer
      //next(setProfile(action.payload.profile));
      dispatch(setLoading({ id: fetchProfile.typePrefix, state: false }));
      break;

    case fetchProfile.rejected.type:
      const lang = getState().constant.languages;
      dispatch(setLoading({ id: fetchProfile.typePrefix, state: false }));
      dispatch(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
      break;

    default:
      break;
  }
};

export const updateProfileMiddleware = ({ dispatch, getState }) => (next) => async (action) => {
  next(action);

  const { type, payload } = action;
  const lang = getState().constant.languages;

  switch (type) {
    case updateProfile.pending.type:
      // dispatch set loading to notification reducer
      dispatch(setLoading({ id: updateProfile.typePrefix, state: true }));
      break;

    case updateProfile.fulfilled.type:
      // dispatch remove loading from notification reducer
      dispatch(setLoading({ id: updateProfile.typePrefix, state: false }));
      dispatch(addAlert({ severity: "success", message: getApiLang(lang, "success.SUCCESS_UPDATE_PROFILE_DATA") }));
			next(await dispatch(getLanguages(getState().profile.language)));
			next(await dispatch(getApiLanguages(getState().profile.language)));
      break;

    case updateProfile.rejected.type:
      dispatch(setLoading({ id: updateProfile.typePrefix, state: false }));
      dispatch(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
      break;

    default:
      break;
  }
};

const updateUserProfilePictureMiddleware = ({ dispatch, getState }) => (
  next
) => (action) => {
  const { type } = action;
  const uploadType = FolderType.profilePicture;

  if (type === updateUserProfilePicture.type) {
    const loadingId = updateUserProfilePicture.type;
    const { fileName, base64Image } = action.payload;
    const userId = getState().profile.username.toString();
    next({ ...action, payload: { fileName, base64Image, userId } });

    dispatch(setLoading({ id: loadingId, state: true }));

    dispatch(
      uploadImageToS3({
        fileName,
        base64Image,
        id: userId,
        uploadType: uploadType,
      })
    );
  } else if (
    type.includes(uploadImageToS3.typePrefix) &&
    action.meta.arg.uploadType === uploadType
  ) {
    next(action);

    switch (type) {
      case uploadImageToS3.rejected.type:
        dispatch(
          setLoading({ id: updateUserProfilePicture.type, state: false })
        );
        break;

      case uploadImageToS3.fulfilled.type:
        const url = `${action.payload.split("?")[0]}`;

        dispatch(updateUserProfilePictureAPI(url));

        break;

      default:
        break;
    }
  } else {
    next(action);
  }
};

const updateUserProfilePictureApiMiddleware = ({ dispatch, getState }) => (next) => (
  action
) => {
  next(action);

  const { type, payload } = action;
  const lang = getState().constant.languages;

  switch (type) {
    case updateUserProfilePictureAPI.rejected.type:
      dispatch(setLoading({ id: updateUserProfilePicture.type, state: false }));
      dispatch(
        addAlert({
          severity: "error",
          message: getApiLang(lang, payload.code),
        })
      );
      break;

    case updateUserProfilePictureAPI.fulfilled.type:
      next(setUserProfilePicture(action.meta.arg));
      dispatch(setLoading({ id: updateUserProfilePicture.type, state: false }));
      dispatch(
        addAlert({ severity: "success", message: getApiLang(lang, "success.SUCCESS_UPDATE_IMAGE_API") })
      );
      break;

    default:
      break;
  }
};

export const changePasswordMiddleware = ({ dispatch, getState }) => (next) => (
  action
) => {
  next(action);

  const { type, payload } = action;
  const lang = getState().constant.languages

  switch (type) {
    case changePassword.pending.type:
      dispatch(setLoading({ id: changePassword.typePrefix, state: true }));
      break;

    case changePassword.fulfilled.type:
      dispatch(setLoading({ id: changePassword.typePrefix, state: false }));
      dispatch(
        addAlert({
          severity: "success",
          message: getApiLang(lang, "success.SUCCESS_CHANGE_PASSWORD")
        })
      );
      break;

    case changePassword.rejected.type:
      dispatch(setLoading({ id: changePassword.typePrefix, state: false }));
      dispatch(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
      break;

    default:
      break;
  }
};

export default [
  fetchProfileMiddleware,
  updateProfileMiddleware,
  updateUserProfilePictureMiddleware,
  updateUserProfilePictureApiMiddleware,
  changePasswordMiddleware
];
