import React from "react";
import TransferListTableComponent from "./transferListTable.component";

export default function TransferListTableContainer({
  rows,
  isFetching,
  isError,
  handleReload,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
  isSearchResult,
  totalFiltered,
  paginationOptions,
  language,
  tableFilter,
  changeOrder,
  lang,
  handleViewDetail,
  url,
  type
}) {
  return (
    <TransferListTableComponent
      rows={rows}
      isFetching={isFetching}
      isError={isError}
      language={language}
      handleReload={handleReload}
      page={page}
      rowsPerPage={rowsPerPage}
      handleChangePage={handleChangePage}
      handleChangeRowPerPage={handleChangeRowsPerPage}
      isSearchResult={isSearchResult}
      totalFiltered={totalFiltered}
      paginationOptions={paginationOptions}
      tableFilter={tableFilter}
      changeOrder={changeOrder}
      lang={lang}
      handleViewDetail={handleViewDetail}
      url={url}
      type={type}
    />
  );
}
