import React from "react";
import { makeStyles, Box } from "@material-ui/core";
import TrackcoLogo from "../../utils/image/svg/trackco.svg";
import clsx from "clsx";

const style = theme => ({
  flex: {
    display: "flex"
  },
  center: {
    justifyContent: "center",
  },
  start: {
    justifyContent: "flex-start",
  },
  end: {
    justifyContent: "flex-end",
  }
});

const useStyle = makeStyles(style);

export default function HeaderComponent({
  justifyContent,
  imgClass,
}) {
  const classes = useStyle();

  return (
    <Box className={clsx(classes.flex, {
      [classes.start]: justifyContent === "start",
      [classes.center]: justifyContent === "center",
      [classes.end]: justifyContent === "end",
    })}>
      <img src={TrackcoLogo} alt="trackco logo" className={imgClass}/>
    </Box>
  )
}