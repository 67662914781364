import axios from "axios";

const importAdtInfoForm = async (parameters) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `/serialnumber/api/v1/additional-info/import`,
        {
          file_path: parameters.file_path,
        }
      )
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data));
  });
};

export default importAdtInfoForm;
