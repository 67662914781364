import axios from "axios";

const ENDPOINT_URL = "/payment/api/v1/coupon";

const get = couponCode => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${ENDPOINT_URL}/${couponCode}`)
      .then(response => resolve(response))
      .catch(error => {
        reject(error.response.data);
      });
  });
};

const coupon = {
  get
};

export default coupon;
