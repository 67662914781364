import React from "react";
import {
  makeStyles,
  Box,
  Paper,
  Typography,
  Divider,
  Button,
  ButtonBase,
  Tooltip,
  InputAdornment,
  Grid,
  FormHelperText,
} from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import DateTimePicker from "components/input/dateTimePicker";
import InputTextField from "components/input/inputTextField";
import { ErrorMessage, Formik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import "moment/min/locales.min";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import clsx from "clsx";
import HelpOutlinedIcon from "@material-ui/icons/HelpOutlined";
import { AddImageSvg } from "modules/product-mgmt/utils/staticSvg";
import CloseIcon from "@material-ui/icons/Close";
import { isFile, isFileExceedLimit, isImage } from "lib/helper";
import { convertToBigNum } from "lib/generalUtility";
import InputHelper from "lib/InputHelper";
import BranchSingleFlatSelect from "components/select/branchSingleFlatSelect";
import PerfectScrollbar from "react-perfect-scrollbar";
import AntSwitch from "components/switch";
import UploadDocumentContainer from "components/uploadDocument/uploadDocument.container";
import EditorContainer from "components/editor/editor.container";
import { Document, Page, pdfjs } from "react-pdf";
import { getLang } from "app/feature/constants";
import { addAlert } from "modules/notification";
import { useDispatch } from "react-redux";
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      width: "calc(100% + 45px)",
    },
  },
  otherQtySelectButton: {
    width: 42,
    height: 42,
    borderRadius: theme.spacing(0.5),
    backgroundColor: "#ECEFF0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: theme.spacing(1.5),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
  },
  gridFieldPeriod: {
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  input: {
    display: "none",
  },
  dividerContainer: {
    backgroundColor: "#ECEFF0",
    display: "flex",
  },
  dateView: {
    paddingRight: theme.spacing(1),
  },
  dateContainer: {
    height: 43,
    backgroundColor: "#ECEFF0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 44,
    borderRadius: "4px 0px 0px 4px",
  },
  dividerEdit: {
    height: "70%",
    display: "flex",
    alignSelf: "center",
  },
  button: {
    marginBottom: "4px",
    boxShadow: "none",
    fontSize: "14px",
    fontFamily: "roboto",
    color: "#FD646F",
    width: "100%",
    borderRadius: 4,
    display: "flex",
    placeContent: "start",
    padding: "5px 10px",
    "&:hover": {
      backgroundColor: "#ECEFF0",
      boxShadow: "none",
    },
  },
  photoContainer: {
    width: "150px",
    height: "150px",
    marginBottom: "25px",
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "contain",
      border: "1px solid #0000001A",
    },
  },
  detailsWrapper: {
    padding: "0px 0px 20px 20px",
  },
  divider: {
    margin: "6px 0px",
  },
  photo: {
    padding: 0,
    marginRight: 20,
    alignItems: "center",
    minWidth: 0,
    width: "fit-content",
    cursor: "pointer",
  },
  imageTab: {
    width: 130,
    height: 130,
    backgroundColor: theme.palette.primary.main,
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: theme.spacing(0.5),
    margin: theme.spacing(2, 0),
    transition: theme.transitions.create("background-color", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "contain",
      backgroundColor: theme.palette.common.white,
      border: "1px solid #0000001A",
    },
  },
  removeButton: {
    width: 24,
    height: 24,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
    borderRadius: "50%",
    position: "absolute",
    zIndex: 1,
    bottom: 6,
    right: 6,
  },
  switchContainer: {
    display: "grid",
    paddingTop: theme.spacing(3),
    gridTemplateColumns: "2fr 1fr 4fr 10fr",
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "2fr 1fr 2fr 5fr",
    },
  },
  scroll: {
    overflowY: "auto",
    width: "100%",
  },
  pdfScrollContainer: {
    width: "60%",
    display: "flex",
    height: "42em",
    flexDirection: "column",
    alignItems: "center",
    border: "1px solid #00001A",
    borderRadius: "4px",
  },
  ScrollContainer: {
    width: "60%",
    display: "flex",
    maxHeight: "42em",
    flexDirection: "column",
    alignItems: "center",
    border: "1px solid #00001A",
    borderRadius: "4px",
  },
  textDisable: {
    fontWeight: "bold",
    paddingLeft: "8px",
    color: "rgba(58, 77, 84, 0.33)",
  },
  textActive: {
    fontWeight: "bold",
    paddingLeft: "8px",
    color: theme.palette.primary.main,
  },
  switch: {
    display: "flex",
    alignItems: "center",
  },
  viewTC: {
    background: "#EBEDEE",
    borderRadius: "0px 0px 4px 4px",
    padding: theme.spacing(1, 4, 1, 3),
    wordBreak: "break-all",
  },
  viewPDF: {
    background: "#EBEDEE",
  },
  viewUrl: {
    wordBreak: "break-all",
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(2),
    cursor: "pointer",
  },
  pageControl: {
    background: "white",
    borderRadius: "4px",
  },
}));

export default function DealerDetailsFormComponent({
  isSubmitting,
  handleSubmit,
  handleDeleteImage,
  formType,
  rewardValues,
  branchDropdown,
  isLoading,
  hasStockflowAddAccessRight,
  hasStockflowEditAccessRight,
  changeBackView,
  language,
  changeToUpdate,
  handleNavigateToRewardList,
  numPages,
  onDocumentLoadSuccess,
  pageNumber,
  handleTncPdfChangePage,
  lang,
  image,
  inputImgRef,
  handleImgChange,
  formRef,
}) {
  const classes = useStyle();
  const dispatch = useDispatch();

  if (language === "ZH") {
    moment.locale("zh-cn");
  } else if (language === "TL") {
    moment.locale("tl-ph");
  } else {
    moment.locale(language);
  }
  const options = {
    cMapUrl: "cmaps/",
    cMapPacked: true,
    standardFontDataUrl: "standard_fonts/",
  };

  const formComponents = {
    viewMode: [
      {
        title: getLang(lang, "label.DETAILS"),
        type: "details",
      },
      {
        title: getLang(lang, "label.DESCRIPTION"),
        type: "description",
      },
      {
        title: getLang(lang, "label.TNC"),
        type: "tnc",
      },
    ],
    editMode: [
      {
        title: getLang(lang, "label.THUMBNAIL_PHOTO"),
        type: "photo",
      },
      {
        title: getLang(lang, "label.DETAILS"),
        type: "details",
      },
      {
        title: getLang(lang, "label.DESCRIPTION"),
        type: "description",
      },
      {
        title: getLang(lang, "label.TNC"),
        type: "tnc",
      },
    ],
    viewDetails: [
      {
        title: getLang(lang, "label.REWARD_NAME"),
        name: "name",
        label: getLang(lang, "placeholder.ENTER_REWARD_NAME_HERE"),
        type: "text",
      },
      {
        title: getLang(lang, "label.BRANCH"),
        name: "branches",
        label: getLang(lang, "placeholder.SELECT_A_BRANCH"),
        type: "select",
      },
      {
        title: getLang(lang, "label.POINTS"),
        name: "point",
        label: getLang(lang, "placeholder.ENTER_REWARD_POINT_HERE"),
        type: "number",
        end: true,
      },
      {
        title: getLang(lang, "label.DISPLAY_DATE"),
        name: "display",
        label: getLang(lang, "label.FROM"),
        type: "date",
      },
      {
        title: getLang(lang, "label.VALIDITY_DATE"),
        name: "validity",
        label: getLang(lang, "label.TO"),
        type: "date",
      },
      {
        title: getLang(lang, "label.STOCK_QUANTITY"),
        name: "quantity",
        label: getLang(lang, "placeholder.ENTER_REWARD_QUANTITY"),
        type: "number",
      },
      {
        title: getLang(lang, "label.REDEMPTION_LIMIT_NUMBER_PER_DEALER"),
        name: "redemptionLimit",
        label: getLang(lang, "placeholder.ENTER_REDEMPTION_LIMIT"),
        type: "number",
      },
    ],
    editDetails: [
      {
        title: getLang(lang, "label.REWARD_NAME"),
        name: "name",
        label: getLang(lang, "placeholder.ENTER_REWARD_NAME_HERE"),
        type: "text",
      },
      {
        title: getLang(lang, "label.BRANCH"),
        name: "branches",
        label: getLang(lang, "placeholder.SELECT_A_BRANCH"),
        type: "select",
      },
      {
        title: getLang(lang, "label.POINTS"),
        name: "point",
        label: getLang(lang, "placeholder.ENTER_REWARD_POINT_HERE"),
        type: "number",
        end: true,
      },
      {
        title: getLang(lang, "label.DISPLAY_DATE"),
        name: "display",
        label: getLang(lang, "label.FROM"),
        type: "date",
      },
      {
        title: getLang(lang, "label.VALIDITY_DATE"),
        name: "validity",
        label: getLang(lang, "label.TO"),
        type: "date",
      },
      {
        title: getLang(lang, "label.STOCK_QUANTITY"),
        name: "quantity",
        label: getLang(lang, "placeholder.ENTER_REWARD_QUANTITY"),
        type: "number",
      },
      {
        title: getLang(lang, "label.REDEMPTION_LIMIT_NUMBER_PER_DEALER"),
        name: "redemptionLimit",
        label: getLang(lang, "placeholder.ENTER_REDEMPTION_LIMIT"),
        type: "number",
      },
    ],
  };

  const renderInputField = (field, index, formik) => {
    switch (field.type) {
      case "number":
        return (
          <Grid item xs={7} sm={8} md={8}>
            {formType === "view" ? (
              <Typography
                variant="body2"
                color="primary"
                style={{
                  fontWeight: "bold",
                  paddingTop: 10,
                  paddingBottom: 10,
                }}
              >
                {convertToBigNum(formik.getFieldProps(field.name).value)}
              </Typography>
            ) : (
              <InputTextField
                disabled={formik.isSubmitting}
                required
                variant="filled"
                size="small"
                fullWidth
                type={field.type}
                onPaste={(e) =>
                  InputHelper.preventCopyEDecimalAndNegetiveInNumberInput(e)
                }
                onKeyDown={(e) =>
                  InputHelper.preventTypeEDecimalAndNegetiveInNumberInput(e)
                }
                inputProps={{
                  min: 1,
                }}
                placeholder={field.label}
                {...formik.getFieldProps(field.name)}
                error={
                  formik.touched[field.name] && formik.errors[field.name]
                    ? true
                    : false
                }
                helperText={ErrorMessage({
                  name: field.name,
                })}
                InputProps={
                  field.end && {
                    endAdornment: (
                      <InputAdornment position="end">
                        <Box px={1} borderLeft="1px solid rgba(0, 0, 0, 0.1)">
                          <Typography variant="body2" color="textSecondary">
                            {getLang(lang, "label.PTS")}
                          </Typography>
                        </Box>
                      </InputAdornment>
                    ),
                  }
                }
              />
            )}
          </Grid>
        );
      case "date":
        return formType === "view" ? (
          <Typography
            variant="body2"
            color="primary"
            style={{ fontWeight: "bold", paddingTop: 10, paddingBottom: 10 }}
          >
            {moment(
              formik.getFieldProps(field.name + "StartDateTime").value
            ).format("lll")}{" "}
            -{" "}
            {moment(
              formik.getFieldProps(field.name + "EndDateTime").value
            ).format("lll")}
          </Typography>
        ) : (
          [
            {
              label: getLang(lang, "label.FROM"),
              name: field.name,
              date: "StartDateTime",
            },
            {
              label: getLang(lang, "label.TO"),
              name: field.name,
              date: "EndDateTime",
            },
          ].map((field, index) => (
            <Grid
              item
              xs={7}
              sm={4}
              md={4}
              key={index}
              className={classes.gridFieldPeriod}
            >
              <Box display="flex">
                <Box
                  className={clsx({
                    [classes.dateView]: formType === "view",
                    [classes.dateContainer]: formType !== "view",
                  })}
                >
                  <Typography
                    variant="subtitle2"
                    style={{
                      fontWeight: "bold",
                      color: " rgba(58, 77, 84, 0.33)",
                      paddingTop: 10,
                      paddingBottom: 10,
                    }}
                  >
                    {field.label}
                  </Typography>
                </Box>
                <Box className={classes.dividerContainer}>
                  <Divider
                    orientation="vertical"
                    className={clsx({
                      [classes.dividerEdit]: formType !== "view",
                    })}
                  />
                </Box>
              </Box>
              <DateTimePicker
                minDate={
                  field.label === getLang(lang, "label.TO")
                    ? new Date(
                        formik.getFieldProps(field.name + "StartDateTime").value
                      ).setDate(
                        new Date(
                          formik.getFieldProps(
                            field.name + "StartDateTime"
                          ).value
                        ).getDate()
                      )
                    : new Date()
                }
                required
                label=""
                disabled={
                  formik.isSubmitting ||
                  (field.label === getLang(lang, "label.FROM") &&
                    moment(
                      field.name === "display"
                        ? rewardValues.displayStartDateTime
                        : rewardValues.validityStartDateTime
                    ).isBefore(moment()))
                }
                disableFuture={false}
                id={field.name + field.label + "-picker-dialog"}
                value={formik.getFieldProps(field.name + field.date).value}
                handleChange={(v) => {
                  formik.setFieldValue(
                    field.name + field.date,
                    v.format("YYYY-MM-DD HH:mm")
                  );
                  if (
                    field.label === getLang(lang, "label.FROM") &&
                    moment(v).isSameOrAfter(
                      moment(
                        formik.getFieldProps(field.name + "EndDateTime").value
                      )
                    )
                  ) {
                    formik.setFieldValue(
                      field.name + "EndDateTime",
                      moment(v).add(1, "minutes").format("YYYY-MM-DD HH:mm")
                    );
                  }

                  if (
                    field.label === getLang(lang, "label.TO") &&
                    moment(v).isSameOrBefore(
                      moment(
                        formik.getFieldProps(field.name + "StartDateTime").value
                      )
                    )
                  ) {
                    formik.setFieldValue(
                      field.name + "EndDateTime",
                      moment(
                        formik.getFieldProps(field.name + "StartDateTime").value
                      )
                        .add(1, "minutes")
                        .format("YYYY-MM-DD HH:mm")
                    );
                  }

                  let oppositeName =
                    field.name === "display" ? "validity" : "display";
                  let labelArr =
                    field.label === getLang(lang, "label.FROM")
                      ? ["StartDateTime", "EndDateTime"]
                      : ["EndDateTime", "StartDateTime"];

                  if (
                    field.label === getLang(lang, "label.FROM") &&
                    field.name === "display"
                  ) {
                    if (
                      moment(v).isAfter(
                        moment(
                          formik.getFieldProps(oppositeName + labelArr[0]).value
                        )
                      )
                    ) {
                      formik.setFieldValue(
                        oppositeName + labelArr[0],
                        moment(v).format("YYYY-MM-DD HH:mm")
                      );
                    }
                    if (
                      moment(v).isAfter(
                        moment(
                          formik.getFieldProps(oppositeName + labelArr[1]).value
                        )
                      )
                    ) {
                      formik.setFieldValue(
                        oppositeName + labelArr[1],
                        moment(v).add(1, "days").format("YYYY-MM-DD HH:mm")
                      );
                    }
                  }
                  if (
                    field.label === getLang(lang, "label.FROM") &&
                    field.name === "validity"
                  ) {
                    if (
                      moment(v).isBefore(
                        moment(
                          formik.getFieldProps(oppositeName + labelArr[0]).value
                        )
                      )
                    ) {
                      formik.setFieldValue(
                        oppositeName + labelArr[0],
                        moment(v).format("YYYY-MM-DD HH:mm")
                      );
                    }
                  }
                  if (
                    field.label === getLang(lang, "label.TO") &&
                    field.name === "display"
                  ) {
                    if (
                      moment(v).isBefore(
                        moment(
                          formik.getFieldProps(oppositeName + labelArr[0]).value
                        )
                      )
                    ) {
                      formik.setFieldValue(
                        oppositeName + labelArr[0],
                        moment(v).format("YYYY-MM-DD HH:mm")
                      );
                    }
                    if (
                      moment(v).isBefore(
                        moment(
                          formik.getFieldProps(oppositeName + labelArr[1]).value
                        )
                      )
                    ) {
                      formik.setFieldValue(
                        oppositeName + labelArr[1],
                        moment(v).format("YYYY-MM-DD HH:mm")
                      );
                    }
                  }
                  if (
                    field.label === getLang(lang, "label.TO") &&
                    field.name === "validity"
                  ) {
                    if (
                      moment(v).isAfter(
                        moment(
                          formik.getFieldProps(oppositeName + labelArr[0]).value
                        )
                      )
                    ) {
                      formik.setFieldValue(
                        oppositeName + labelArr[0],
                        moment(v).format("YYYY-MM-DD HH:mm")
                      );
                    }
                  }
                }}
                InputPropStyle={{
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                }}
              />
            </Grid>
          ))
        );
      case "text":
        return (
          <Grid item xs={7} sm={8} md={8}>
            {formType === "view" ? (
              <Typography
                variant="body2"
                color="primary"
                style={{
                  fontWeight: "bold",
                  paddingTop: 10,
                  paddingBottom: 10,
                }}
              >
                {formik.getFieldProps(field.name).value}
              </Typography>
            ) : (
              <InputTextField
                required
                variant="filled"
                size="small"
                disabled={formik.isSubmitting}
                fullWidth
                placeholder={field.label}
                {...formik.getFieldProps(field.name)}
                error={
                  formik.touched[field.name] && formik.errors[field.name]
                    ? true
                    : false
                }
                helperText={ErrorMessage({
                  name: field.name,
                })}
              />
            )}
          </Grid>
        );
      case "paragraph":
        return (
          <Grid item xs={12} sm={12} md={12}>
            {formType === "view" ? (
              <Typography
                variant="body2"
                color="primary"
                style={{ paddingTop: 10, paddingBottom: 10 }}
              >
                {formik.getFieldProps(field.name).value}
              </Typography>
            ) : (
              <InputTextField
                required
                variant="filled"
                size="small"
                disabled={formik.isSubmitting}
                fullWidth
                multiline={true}
                rows={6}
                placeholder={field.label}
                {...formik.getFieldProps(field.name)}
                error={
                  formik.touched[field.name] && formik.errors[field.name]
                    ? true
                    : false
                }
                helperText={ErrorMessage({
                  name: field.name,
                })}
              />
            )}
          </Grid>
        );
      case "image":
        return (
          <Box>
            {formType === "view" ? (
              <Box className={classes.photoContainer}>
                <img src={rewardValues.coverImage} alt="rewardImage" />
              </Box>
            ) : (
              <>
                <input
                  accept="image/*"
                  className={classes.input}
                  id={"contained-button-file" + index}
                  type="file"
                  ref={inputImgRef}
                  onClick={(e) => {
                    e.target.value = null;
                  }}
                  // type="file"
                  onChange={(e) => {
                    e.persist();
                    // formik.setFieldValue(field.name, e.currentTarget.files[0])
                    if (e.currentTarget.files.length > 0) {
                      if (
                        isFileExceedLimit(
                          e.currentTarget.files[0].size,
                          6,
                          "MB"
                        )
                      ) {
                        dispatch(
                          addAlert({
                            severity: "error",
                            message: getLang(
                              lang,
                              "message.error.FORM_VALIDATE_FILE_SIZE",
                              { value: 6 }
                            ),
                          })
                        );
                        return;
                      }
                      handleImgChange(e);
                    }
                    e.target.value = null;
                  }}
                />
                <Box disabled={formik.isSubmitting}>
                  <Box className={clsx(classes.photo)}>
                    <label
                      htmlFor={"contained-button-file" + index}
                      style={{ cursor: "pointer" }}
                    >
                      <Box className={classes.imageTab}>
                        {formik.getFieldProps(field.name).value !== "" ? (
                          <>
                            <img
                              src={
                                formik.getFieldProps(field.name).value
                                  .length === undefined &&
                                isFile(formik.getFieldProps(field.name).value)
                                  ? URL.createObjectURL(
                                      formik.getFieldProps(field.name).value
                                    )
                                  : formik.getFieldProps(field.name).value
                              }
                              alt="poster"
                            />
                            <ButtonBase
                              className={classes.removeButton}
                              onClick={(e) => handleDeleteImage(formik, e)}
                            >
                              <CloseIcon fontSize="small" />
                            </ButtonBase>
                          </>
                        ) : (
                          <AddImageSvg />
                        )}
                      </Box>
                    </label>
                    <FormHelperText
                      error={formik.errors[field.name] ? true : false}
                    >
                      {ErrorMessage({ name: field.name })}
                    </FormHelperText>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <ReportProblemOutlinedIcon fontSize="small" color="error" />
                    &ensp;
                    <Typography color="textSecondary" variant="caption">
                      {getLang(
                        lang,
                        "paragraph.RECOMMENDED_IMAGE_SIZE_JPG_PNG_6"
                      )}
                    </Typography>
                  </Box>
                </Box>
              </>
            )}
          </Box>
        );
      case "select":
        return (
          <Grid item xs={7} sm={8} md={8}>
            {formType === "view" ? (
              <Typography
                variant="body2"
                color="primary"
                style={{
                  fontWeight: "bold",
                  paddingTop: 10,
                  paddingBottom: 10,
                }}
              >
                {rewardValues.branch
                  ? rewardValues.branch.name
                  : getLang(lang, "label.NONE")}
              </Typography>
            ) : (
              <>
                <BranchSingleFlatSelect
                  searchable={true}
                  value={formik.getFieldProps(field.name).value}
                  placeholder={field.label}
                  searchPlaceholder={getLang(
                    lang,
                    "placeholder.SEARCH_BRANCH_NAME"
                  )}
                  isClearable={false}
                  emptyMessage={getLang(lang, "message.info").NO_BRANCH_FOUND}
                  dropdownItem={branchDropdown}
                  isFetching={isLoading}
                  handleChange={(value) => {
                    formik.setFieldValue(field.name, value);
                  }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  isEmpty={formik.getFieldProps(field.name).value === null}
                />
                <FormHelperText
                  error={formik.errors[field.name] ? true : false}
                >
                  {ErrorMessage({ name: field.name })}
                </FormHelperText>
              </>
            )}
          </Grid>
        );
      case "tnc":
        return (
          <Grid item xs={12} sm={12} md={12}>
            {formType === "view" ? (
              <Box
                pt={2}
                pb={2}
                className={clsx({
                  [classes.pdfScrollContainer]: rewardValues.tncType === "pdf",
                  [classes.textScrollContainer]:
                    rewardValues.tncType === "text",
                })}
              >
                <PerfectScrollbar
                  className={classes.scroll}
                  options={{
                    suppressScrollX: true,
                  }}
                >
                  <Box
                    className={clsx({
                      [classes.viewTC]: rewardValues.tncType === "text",
                      [classes.viewPDF]: rewardValues.tncType === "pdf",
                    })}
                  >
                    {rewardValues.tncType === "pdf" ? (
                      <>
                        <Box>
                          <Document
                            file={rewardValues.tncUrl}
                            onLoadSuccess={onDocumentLoadSuccess}
                            options={options}
                          >
                            <Page pageNumber={pageNumber} />
                          </Document>
                        </Box>
                      </>
                    ) : (
                      <>
                        <Box
                          dangerouslySetInnerHTML={{
                            __html: rewardValues.tncText.value,
                          }}
                        />
                      </>
                    )}
                  </Box>
                </PerfectScrollbar>
                {rewardValues.tncType === "pdf" &&
                  numPages !== null &&
                  numPages > 0 && (
                    <Box className={classes.pageControl}>
                      <Button
                        onClick={() => handleTncPdfChangePage(false)}
                        disabled={pageNumber === 1}
                      >
                        <ChevronLeftIcon />
                      </Button>
                      <span>
                        {getLang(lang, "label.PAGE")} {pageNumber}{" "}
                        {getLang(lang, "label.OF")} {numPages}
                      </span>
                      <Button
                        onClick={() => handleTncPdfChangePage(true)}
                        disabled={pageNumber === numPages}
                      >
                        <ChevronRightIcon />
                      </Button>
                    </Box>
                  )}
              </Box>
            ) : (
              <>
                <Box className={classes.switchContainer}>
                  <Box className={classes.switch}>
                    <AntSwitch
                      checked={formik.values.tncType === "text"}
                      onClick={() => formik.setFieldValue("tncType", "text")}
                      disabled={formik.isSubmitting}
                    />
                    <Typography
                      variant="body2"
                      className={clsx(classes.textDisable, {
                        [classes.textActive]: formik.values.tncType === "text",
                      })}
                    >
                      {getLang(lang, "label.TEXT_BOX")}
                    </Typography>
                  </Box>

                  <Typography variant="body2" style={{ fontWeight: "bold" }}>
                    {getLang(lang, "label.OR")}
                  </Typography>

                  <Box className={classes.switch}>
                    <AntSwitch
                      checked={formik.values.tncType === "pdf"}
                      onClick={() => formik.setFieldValue("tncType", "pdf")}
                      disabled={formik.isSubmitting}
                    />
                    <Typography
                      variant="body2"
                      className={clsx(classes.textDisable, {
                        [classes.textActive]: formik.values.tncType === "pdf",
                      })}
                    >
                      {getLang(lang, "label.UPLOAD_PDF_DOCUMENT")}
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  {formik.values.tncType === "pdf" ? (
                    <>
                      <UploadDocumentContainer
                        value={formik.values.tncFile}
                        handleUpload={(file) => {
                          formik.setFieldValue("tncFile", file);
                        }}
                        maxUploadSize={{ size: 15, unit: "MB" }}
                        reuploadFlag={
                          formType !== "create" &&
                          rewardValues.tncType === "pdf"
                        }
                      />
                    </>
                  ) : (
                    <>
                      <EditorContainer
                        type={formType === "create" ? "new" : "edit"}
                        initialValues={formik.values.tncText}
                        handleChange={(value) => {
                          formik.setFieldValue("tncText", value);
                        }}
                      />
                    </>
                  )}
                </Box>
              </>
            )}
          </Grid>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Paper elevation={1} className={classes.root}>
        <Box
          style={{ padding: "8px 20px" }}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box style={{ width: "100%" }}>
            <Formik
              initialValues={{
                ...rewardValues,
                name: rewardValues.name || "",
                branches:
                  branchDropdown.find(
                    ({ nodeId }) => nodeId === rewardValues.branch.uuid
                  ) || null,
                point: rewardValues.point || 0,
                displayStartDateTime:
                  rewardValues.displayStartDateTime ||
                  new moment().format("YYYY-MM-DD HH:mm"),
                displayEndDateTime:
                  rewardValues.displayEndDateTime ||
                  new moment().add(7, "day").format("YYYY-MM-DD HH:mm"),
                validityStartDateTime:
                  rewardValues.validityStartDateTime ||
                  new moment().format("YYYY-MM-DD HH:mm"),
                validityEndDateTime:
                  rewardValues.validityEndDateTime ||
                  new moment().add(7, "day").format("YYYY-MM-DD HH:mm"),
                quantity: rewardValues.quantity || 0,
                redemptionLimit: rewardValues.redemptionLimit || 0,
                description: rewardValues.description || "",
                tncType: rewardValues.tncType || "text",
                tncText: rewardValues.tncText || "<p></p>",
                tncFile: null,
                coverImageUrl: rewardValues.coverImageUrl || "",
                tncUrl: rewardValues.tncUrl || "",
              }}
              innerRef={formRef}
              validationSchema={Yup.object({
                name: Yup.string()
                  .trim()
                  .required(
                    getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")
                  ),
                branches: Yup.object()
                  .required(
                    getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")
                  )
                  .nullable(),
                point: Yup.number()
                  .required(
                    getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")
                  )
                  .positive()
                  .integer(),
                displayStartDateTime: Yup.date().required(
                  getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")
                ),
                displayEndDateTime: Yup.date().required(
                  getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")
                ),
                validityStartDateTime: Yup.date().required(
                  getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")
                ),
                validityEndDateTime: Yup.date().required(
                  getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")
                ),
                quantity: Yup.number()
                  .required(
                    getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")
                  )
                  .moreThan(
                    0,
                    getLang(lang, "message.error.FORM_VALIDATE_MORETHAN", {
                      value: 0,
                    })
                  ),
                redemptionLimit: Yup.number()
                  .required(
                    getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")
                  )
                  .moreThan(
                    0,
                    getLang(lang, "message.error.FORM_VALIDATE_MORETHAN", {
                      value: 0,
                    })
                  ),
                description: Yup.string().required(
                  getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")
                ),
                tncType: Yup.string()
                  .trim()
                  .matches(/(pdf|text)/),
                coverImageUrl: Yup.lazy((value) => {
                  if (value instanceof File) {
                    return Yup.mixed()
                      .test(
                        "fileSize",
                        getLang(lang, "message.error.FILE_TOO_LARGE"),
                        (v) => {
                          return !isFileExceedLimit(v.size, 10, "MB");
                        }
                      )
                      .test(
                        "fileType",
                        getLang(lang, "message.error.FILE_NOT_IMAGE"),
                        (v) => {
                          return isImage(v);
                        }
                      );
                  } else {
                    return Yup.string().required(
                      getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")
                    );
                  }
                }),
                tncText: Yup.string().when("tncType", {
                  is: "text",
                  then: Yup.string().required(
                    getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")
                  ),
                  otherwise: Yup.string(),
                }),
                tncFile: Yup.mixed().when("tncType", {
                  is: "pdf",
                  then: Yup.lazy((value) => {
                    if (value instanceof File) {
                      return Yup.mixed()
                        .required(
                          getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")
                        )
                        .test(
                          "fileSize",
                          getLang(lang, "message.error.FILE_TOO_LARGE"),
                          (value) => value && value.size <= 15 * 1024 * 1024
                        )
                        .test(
                          "fileFormat",
                          "Unsupported File Type",
                          (value) =>
                            value && ["application/pdf"].includes(value.type)
                        );
                    } else {
                      return Yup.string().required(
                        getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")
                      );
                    }
                  }),
                  otherwise: Yup.mixed().nullable(),
                }),
              })}
              onSubmit={handleSubmit}
            >
              {(formik) => (
                <form onSubmit={formik.handleSubmit}>
                  <Box
                    className={classes.detailsWrapper}
                    style={{ flexDirection: "row", alignItems: "stretch" }}
                  >
                    {(formType === "view"
                      ? formComponents.viewMode
                      : formComponents.editMode
                    ).map((field, index) => (
                      <Box key={index}>
                        <Grid container spacing={1}>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            style={{ winth: "100%" }}
                          >
                            <Box my={2} display="flex">
                              <Typography
                                variant="body2"
                                color="primary"
                                style={{ fontWeight: "bold", marginRight: 5 }}
                              >
                                {field.title}
                              </Typography>
                              {field.tooltip && (
                                <Tooltip title={field.tooltip} arrow>
                                  <HelpOutlinedIcon
                                    color="primary"
                                    fontSize="small"
                                  />
                                </Tooltip>
                              )}
                            </Box>
                            {field.type === "photo" && formType !== "view" ? (
                              [
                                {
                                  name: "coverImageUrl",
                                  type: "image",
                                },
                              ].map((field, index) => (
                                <Grid key={index} item xs={12} sm={12} md={12}>
                                  {renderInputField(field, index, formik)}
                                  <Divider className={classes.divider} />
                                </Grid>
                              ))
                            ) : field.type === "details" ? (
                              <>
                                {(formType === "view"
                                  ? formComponents.viewDetails
                                  : formComponents.editDetails
                                ).map((field, index) => (
                                  <Grid
                                    container
                                    key={index}
                                    spacing={1}
                                    style={{
                                      alignItems: "center",
                                      marginBottom: "0.5em",
                                    }}
                                  >
                                    <Grid item xs={12} sm={3} md={3}>
                                      <Typography
                                        variant="body2"
                                        color="primary"
                                      >
                                        {field.title}
                                      </Typography>
                                    </Grid>
                                    {renderInputField(field, index, formik)}
                                  </Grid>
                                ))}
                                <Divider className={classes.divider} />
                              </>
                            ) : field.type === "description" ? (
                              <>
                                {[
                                  {
                                    title: getLang(lang, "label.DESCRIPTION"),
                                    name: "description",
                                    label: getLang(
                                      lang,
                                      "placeholder.ENTER_REWARD_DESCRIPTION_HERE"
                                    ),
                                    type: "paragraph",
                                  },
                                ].map((field, index) => (
                                  <Grid
                                    container
                                    key={index}
                                    spacing={1}
                                    style={{
                                      alignItems: "center",
                                      marginBottom: "0.5em",
                                    }}
                                  >
                                    {renderInputField(field, index, formik)}
                                  </Grid>
                                ))}
                                <Divider className={classes.divider} />
                              </>
                            ) : (
                              [
                                {
                                  title: getLang(lang, "label.TNC"),
                                  type: "tnc",
                                },
                              ].map((field, index) => (
                                <Grid
                                  container
                                  key={index}
                                  spacing={1}
                                  style={{
                                    alignItems: "center",
                                    marginBottom: "0.5em",
                                  }}
                                >
                                  {renderInputField(field, index, formik)}
                                </Grid>
                              ))
                            )}
                          </Grid>
                        </Grid>
                      </Box>
                    ))}
                  </Box>
                  {(formType === "create" && hasStockflowAddAccessRight) ||
                  (formType === "view" && hasStockflowEditAccessRight) ||
                  (formType === "edit" && hasStockflowEditAccessRight) ? (
                    <>
                      <Divider />
                      <Box p={2} display="flex" justifyContent="flex-end">
                        {formType !== "view" ? (
                          <Box display="flex" alignItems="center">
                            <Button
                              onClick={(e) => {
                                formik.resetForm();
                                formType === "edit"
                                  ? changeBackView(e)
                                  : handleNavigateToRewardList();
                              }}
                              disabled={formik.isSubmitting}
                              type="button"
                              style={{ marginRight: "4px" }}
                            >
                              {getLang(lang, "label.CANCEL")}
                            </Button>
                          </Box>
                        ) : (
                          ""
                        )}
                        <Box>
                          <Button
                            disabled={formik.isSubmitting}
                            variant="contained"
                            color="secondary"
                            disableElevation
                            type={formType === "view" ? "button" : "submit"}
                            onClick={
                              formType === "view" ? changeToUpdate : null
                            }
                          >
                            {formType === "create"
                              ? formik.isSubmitting
                                ? getLang(lang, "label.CREATING")
                                : getLang(lang, "label.CREATE")
                              : formType === "edit"
                              ? getLang(lang, "label.SAVE")
                              : getLang(lang, "label.EDIT")}
                          </Button>
                        </Box>
                      </Box>
                    </>
                  ) : null}
                </form>
              )}
            </Formik>
          </Box>
        </Box>
      </Paper>
    </>
  );
}
