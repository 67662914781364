import { Content } from "modules/reporting/components/layout";
import { FilterPanelV3, WarrantyListPanel } from "modules/reporting/components/panel";
import React from "react";

export default function WarrantyPage({
    filter,
    tagUrl,
    currentFilter,
    pageMode,
    campaignType,
    translate
}) {
    const pageType = "campaign-list";
    return (
        <>
            <FilterPanelV3
                filter={filter}
                currentFilter={currentFilter}
                pageMode={pageMode}
                translate={translate}
            />

            <Content>
                {/* warranty list --- START */}
                <WarrantyListPanel 
                    currentFilter={currentFilter}
                    urlWarrantyList={tagUrl + "get-warranty-info-list"}
                    // urlWarrantyList={"http://localhost:9080/warrantyList"}
                    pageType={pageType}
                    campaignType = {campaignType}

                    translate={translate}
                />
            </Content>
        </>
    )
}