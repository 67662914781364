import React, { useState, useRef, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useRouteMatch } from "react-router-dom"
import { generatePaginationOptions, updateUrlQueryParam } from "lib/helper"
import { selectLoading } from "modules/notification"
import moment from "moment"
import DealerPointHistoryListPanelComponent from "./dealerPointHistoryListPanel.component"
import { getDealerPointHistoryList, getRedemptionDetails, resetDealerPointHistoryListing } from "modules/stockflow/redux"
import RedemptionDetailsDialog from "../../dialog/redemptionDetails"
import { ROOT as EMPLOYEE_ROOT } from "modules/employee/config/employee.route";
import { ROOT as STOCKFLOW_ROOT } from "modules/stockflow/config/stockflow.route"
import { STOCKFLOW_REWARD_CENTER_VIEW } from "lib/constants/accessRights";

function DealerPointHistoryListPanelContainer() {
  const dispatch = useDispatch()
  const history = useHistory();
  const match = useRouteMatch()

  const id = match.params.dealerId
  const lang = useSelector(state => state.constant.languages)
  const language= useSelector(state => state.profile.language)
  const hasStockflowRewardCenterViewAccessRight = useSelector(state => state.profile.accessRights.includes(STOCKFLOW_REWARD_CENTER_VIEW));

  const isHistoryFetching = useSelector(state => selectLoading(state, getDealerPointHistoryList.typePrefix))

  const totalFiltered = useSelector(state => state.stockflowDealer.pointHistoryList.totalFiltered)
  const pointHistoryList = useSelector(state => state.stockflowDealer.pointHistoryList.list)
  const isError = useSelector(state => state.stockflowDealer.pointHistoryList.isError)
  
  const details = useSelector(state => state.stockflowRewardCenter.redemptionDetail)
  const isFetchingDetails = useSelector(state => selectLoading(state, getRedemptionDetails.typePrefix));
  
  const [paginationOptions, setPaginationOptions] = useState([]);
  const [tableFilter, setTableFilter] = useState({
    page: 0,
    rowsPerPage: 25,
    order: "desc",
    date: "",
  })
  const [isViewDialogOpen, setIsViewDialogOpen] = useState(false);

  const tableFilterInitialRef = useRef(true)

  useEffect(() => {
    return () => {
      dispatch(resetDealerPointHistoryListing())
    }
  }, [dispatch])

  useEffect(() => {
    const searchParam = new URLSearchParams(window.location.search);
    const date = searchParam.get("date")
    const order = searchParam.get("order")
    const page = searchParam.get("page")
    const length = searchParam.get("length")

    setTableFilter(prev => ({
      ...prev,
      page: page ? page - 1 : tableFilter.page,
      rowsPerPage: length || tableFilter.rowsPerPage,
      date: date || tableFilter.date,
      order: order || tableFilter.order,
    }))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!tableFilterInitialRef.current) {

      dispatch(resetDealerPointHistoryListing())
      getData()
    } 
    tableFilterInitialRef.current = false
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableFilter])

  useEffect(() => {
    setPaginationOptions(generatePaginationOptions(totalFiltered))
  }, [totalFiltered])

  const getData = () => {
    let payload = {
      id,
      length: tableFilter.rowsPerPage,
      start: tableFilter.rowsPerPage * tableFilter.page,
      date: tableFilter.date,
      order: tableFilter.order
    }

    dispatch(getDealerPointHistoryList(payload))
  }

  const handleChangePage = (event, newPage) => {
    let newData = {
      ...tableFilter,
      page: newPage,
    }
    updateUrlQueryParam({
      page: newData.page + 1,
      length: newData.rowsPerPage
    })
    setTableFilter(newData)
  }

  const handleChangeRowsPerPage = event => {
    let newData = {
      ...tableFilter,
      page: 0,
      rowsPerPage: event.target.value,
    }
    updateUrlQueryParam({
      page: newData.page + 1,
      length: newData.rowsPerPage
    })
    setTableFilter(newData)
  }

  const handleSearch = (filter) => {
    const { date } = filter

    let newData = {
      ...tableFilter,
      page: 0,
      date: date ? moment(date).format("YYYY-MM-DD") : "",
    }
    updateUrlQueryParam(
      { date: newData.date },
      ["page", "length"]
    );
    setTableFilter(newData)
  }

  const clearSearch = () => {
    let newData = {
      ...tableFilter,
      page: 0,
      date: "",
    }
    updateUrlQueryParam(
      null,
      ["page", "length", "date"]
    );
    setTableFilter(newData)
  }

  const changeOrder = (order) => {
    let newData = {
      ...tableFilter,
      order,
    }
    updateUrlQueryParam({ order: order });
    setTableFilter(newData)
  }

  const handleViewDetail = (data) => {
    if (data.type === "Recall") {
      history.push(`${STOCKFLOW_ROOT}/recall/${data.transaction_uuid}`)
    } else if (data.type === "Receive Stock") {
      history.push(`${STOCKFLOW_ROOT}/stockout/${data.transaction_uuid}`)
    } else {
      setIsViewDialogOpen(true)
      dispatch(getRedemptionDetails({
        id: data.transaction_uuid
      }))
    }
  }

  const handleViewEmployee = id => history.push(`${EMPLOYEE_ROOT}/View/${id}`);

  return (
    <>
      <DealerPointHistoryListPanelComponent
        hasStockflowRewardCenterViewAccessRight={hasStockflowRewardCenterViewAccessRight}
        id={id}
        language={language}
        pointHistory={pointHistoryList}
        isFetching={isHistoryFetching}
        isError={isError}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        page={tableFilter.page}
        rowsPerPage={tableFilter.rowsPerPage}
        totalFiltered={totalFiltered}
        handleSearch={handleSearch}
        clearSearch={clearSearch}
        handleReload={getData}
        paginationOptions={paginationOptions}
        tableFilter={tableFilter}
        changeOrder={changeOrder}
        lang={lang}
        handleViewDetail={handleViewDetail}
      />
      <RedemptionDetailsDialog
        isOpen={isViewDialogOpen}
        handleClose={() => setIsViewDialogOpen(false)}
        details={details}
        remarks=""
        isFetchingDetails={isFetchingDetails}
        handleViewDealer={() => {}}
        handleViewEmployee={handleViewEmployee}
        isDealerPage={true}
      />
    </>
  )
}

export default DealerPointHistoryListPanelContainer
