import { selectLoading } from "modules/notification";
import { getConsumerScanData, getScanAgainstSuspendedData, getTotalScanTrending, getTotalSuspendedTrending, exportConsumerListing } from "modules/reporting/redux";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ConsumerScanPanel from './consumerScanPanel.component';
import { unwrapResult } from "@reduxjs/toolkit";

export default function ConsumerScanPanelContainer({
    currentFilter,
    urlTotalScan,
    urlTotalSuspended,
    urlTotalDistributionPerformance,
    urlConsumerScan,
    urlScanAgainstSuspended,
    pageType,
    translate
}) {
    const dispatch = useDispatch();
    const dispatchTrending = useDispatch();
    const token = useSelector((state) => state.session.accessToken);
    const dispatchConsumer = useDispatch();
    // (1) trending data -- start
    // (a) get trending: total scan qr data
    const isFetchingTotalScanTrending = useSelector(state => selectLoading(state, getTotalScanTrending.typePrefix));
    const totalScanTrendingData = useSelector(state => state.reporting.trending.totalScanData);

    // (b) get trending: total suspended qr data
    const isFetchingTotalSuspendedTrending = useSelector(state => selectLoading(state, getTotalSuspendedTrending.typePrefix));
    const totalSuspendedTrendingData = useSelector(state => state.reporting.trending.totalSuspendedData);

    // (c) get trending: total distribution performance data
    // const isFetchingTotalDistributionPerformance = useSelector(state => selectLoading(state, getTotalDistributionPerformance.typePrefix));
    // const totalDistributionPerformanceData = useSelector(state => state.reporting.trending.totalDistributionPerformanceData);
    // (1) trending data -- end
    const language = useSelector(state => state.profile.language)


    // (2) consumer scan data -- start
    const isFetchingConsumerScanData = useSelector(state => selectLoading(state, getConsumerScanData.typePrefix));
    const consumerScanData = useSelector(state => state.reporting.consumer.consumerScanDatalist);
    // (2) consumer scan data -- end

    const isFetchingScanAgainstSuspendedData = useSelector(state => selectLoading(state, getScanAgainstSuspendedData.typePrefix));
    const scanAgainstSuspendedData = useSelector(state => state.reporting.trending.scanAgainstSuspendedData);

    useEffect(() => {
        dispatch(getScanAgainstSuspendedData({
            url: urlScanAgainstSuspended,
            countryCode: currentFilter.countryCode,
            city_uuid: currentFilter.cityUuid,
            branch_uuid: currentFilter.branchUuid,
            category_uuid: currentFilter.categoryUuid,
            model_uuid: currentFilter.modelUuid,
            brand_uuid: currentFilter.brandUuid,
            product_uuid: currentFilter.productUuid,
            period: currentFilter.period,
            start_date: currentFilter.dateRange[0],
            end_date: currentFilter.dateRange[1]
        }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, currentFilter])

    const [tableFilter, setTableFilter] = useState({
        page: 0,
        rowsPerPage: 25,
        search: "",
        searchBy: "name",
        orderBy: "created_at",
        orderDirection: "desc",
    })

    useEffect(() => {

        dispatchTrending(getTotalScanTrending({
            countryCode: currentFilter.countryCode,
            city_uuid: currentFilter.cityUuid,
            branch_uuid: currentFilter.branchUuid,
            product_uuid: currentFilter.productUuid,
            period: currentFilter.period,
            start_date: moment(currentFilter.dateRange[0]).format('YYYY-MM-DD'),
            end_date: moment(currentFilter.dateRange[1]).format('YYYY-MM-DD'),
            url: urlTotalScan
        }));

        dispatchTrending(getTotalSuspendedTrending({
            countryCode: currentFilter.countryCode,
            city_uuid: currentFilter.cityUuid,
            branch_uuid: currentFilter.branchUuid,
            period: currentFilter.period,
            product_uuid: currentFilter.productUuid,
            start_date: moment(currentFilter.dateRange[0]).format('YYYY-MM-DD'),
            end_date: moment(currentFilter.dateRange[1]).format('YYYY-MM-DD'),
            url: urlTotalSuspended
        }));

        // dispatchTrending(getTotalDistributionPerformance({
        //     countryCode: currentFilter.countryCode,
        //     city_uuid: currentFilter.cityUuid,
        //     branch_uuid: currentFilter.branchUuid,
        //     period: currentFilter.period,
        //     product_uuid: currentFilter.productUuid,
        //     start_date: moment(currentFilter.dateRange[0]).format('YYYY-MM-DD'),
        //     end_date: moment(currentFilter.dateRange[1]).format('YYYY-MM-DD'),
        //     url: urlTotalDistributionPerformance
        // }));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatchTrending, currentFilter]);

    useEffect(() => {
        dispatchConsumer(getConsumerScanData({
            length: tableFilter.rowsPerPage,
            start: tableFilter.page * tableFilter.rowsPerPage,
            orderBy: tableFilter.orderBy,
            orderDirection: tableFilter.orderDirection,
            search: tableFilter.search,
            searchBy: tableFilter.searchBy,
            countryCode: currentFilter.countryCode,
            city_uuid: currentFilter.cityUuid,
            branch_uuid: currentFilter.branchUuid,
            product_uuid: currentFilter.productUuid,
            period: currentFilter.period,
            start_date: moment(currentFilter.dateRange[0]).format('YYYY-MM-DD'),
            end_date: moment(currentFilter.dateRange[1]).format('YYYY-MM-DD'),
            url: urlConsumerScan
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatchConsumer, tableFilter, currentFilter]);

    const handleChangePage = (e, newPage) => {
        setTableFilter((prev) => ({
            ...prev,
            page: newPage,
        }))
    }

    const handleChangeRowPerPage = (event) => {
        setTableFilter((prev) => ({
            ...prev,
            page: 0,
            rowsPerPage: event.target.value
        }))
    }

    const handleRequestSort = (event, property) => {
        const isAsc = tableFilter.orderBy === property && tableFilter.orderDirection === 'asc';
        setTableFilter((prev) => ({
            ...prev,
            orderDirection: isAsc ? 'desc' : 'asc',
            orderBy: property,
        }))
    }

    const handleExportExcel = () => {
        const info = {
            token: token,
            orderBy: tableFilter.orderBy,
            orderDirection: tableFilter.orderDirection,
            search: tableFilter.search,
            searchBy: tableFilter.searchBy,
            countryCode: currentFilter.countryCode,
            city_uuid: currentFilter.cityUuid,
            branch_uuid: currentFilter.branchUuid,
            product_uuid: currentFilter.productUuid,
            period: currentFilter.period,
            start_date: moment(currentFilter.dateRange[0]).format('YYYY-MM-DD'),
            end_date: moment(currentFilter.dateRange[1]).format('YYYY-MM-DD'),
            url: urlConsumerScan
        }

        dispatch(exportConsumerListing(info))
            .then(unwrapResult)
            .finally(() => {

            });
    }

    const columns = [
        {
            title: translate("label.NUM"),
            field: null,
            render: (row) => {
                return (
                    <>{row.title}</>
                )
            },
            align: null
        },
        {
            title: translate("label.CONSUMER"),
            field: null,
            render: (row) => {
                return (
                    <>{row.title}</>
                )
            },
            align: null
        },
        {
            title: translate("label.SERIAL_NUMBER"),
            field: null,
            render: (row) => {
                return (
                    <>{row.title}</>
                )
            },
            align: null
        },
        {
            title: translate("label.GENDER"),
            field: null,
            render: (row) => {
                return (
                    <>{row.title}</>
                )
            },
            align: null
        },
        {
            title: translate("label.AGE"),
            field: null,
            render: (row) => {
                return (
                    <>{row.title}</>
                )
            },
            align: null
        },
        {
            title: translate("label.SCAN_COUNT"),
            field: null,
            render: (row) => {
                return (
                    <>{row.title}</>
                )
            },
            align: null
        },
        {
            title: translate("label.EMAIL"),
            field: null,
            render: (row) => {
                return (
                    <>{row.title}</>
                )
            },
            align: null
        },
        {
            title: translate("label.CONTACT"),
            field: null,
            render: (row) => {
                return (
                    <>{row.title}</>
                )
            },
            align: null
        },
        {
            title: translate("label.SCAN_LOCATION"),
            field: null,
            render: (row) => {
                return (
                    <>{row.title}</>
                )
            },
            align: null
        },
        {
            title: translate("label.DATE"),
            field: 'created_at',
            render: (row) => {
                return (
                    <>{row.title}</>
                )
            },
            align: null
        },
        {
            title: translate("label.PLATFORM"),
            field: null,
            render: (row) => {
                return (
                    <>{row.title}</>
                )
            },
            align: null
        },
        {
            title: translate("label.BROWSER"),
            field: null,
            render: (row) => {
                return (
                    <>{row.title}</>
                )
            },
            align: null
        },
        {
            title: translate("label.DEVICE"),
            field: null,
            render: (row) => {
                return (
                    <>{row.title}</>
                )
            },
            align: null
        },
        {
            title: translate("label.LANGUAGE"),
            field: null,
            render: (row) => {
                return (
                    <>{row.title}</>
                )
            },
            align: null
        }
    ]

    return (
        <ConsumerScanPanel
            status={{
                isFetchingTotalScanTrending,
                isFetchingTotalSuspendedTrending,
                // isFetchingTotalDistributionPerformance,
                isFetchingConsumerScanData,
                isFetchingScanAgainstSuspendedData
            }}
            data={{
                totalScanTrendingData,
                totalSuspendedTrendingData,
                // totalDistributionPerformanceData,
                consumerScanData,
                scanAgainstSuspendedData
            }}

            language={language}
            handleExportExcel={handleExportExcel}

            sort={{
                valueToOrderBy: tableFilter.orderBy,
                orderDirection: tableFilter.orderDirection,
                handleRequestSort: handleRequestSort,
            }}

            pagination={{
                handleChangePage: handleChangePage,
                handleChangeRowPerPage: handleChangeRowPerPage,
                page: tableFilter.page,
                rowsPerPage: tableFilter.rowsPerPage,
                rowsPerPageOptions: [5, 10, 25],
            }}

            columns={columns}

            currentFilter={currentFilter}

            pageType={pageType}
            translate={translate}
        />
    )
}