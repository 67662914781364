import axios from "axios"

// get the respondent answer list 
export function getRespondentAnswerList({
  survey_uuid,
  question_uuid,
  length,
  start,
  column,
  order,
  search,
  product_id
}) {
  return new Promise(( resolve, reject ) => {
    axios.get(`/consumer/admin/api/v1/survey-respondent/${survey_uuid}/${question_uuid}/answers`, {
      params: {
        length,
        start,
        column,
        order,
        search,
        product_id
      }
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data))
  })
}