const API_HOST = process.env.REACT_APP_API_HOST_URL;

const exportAssignSN = async ({ ...parameters }) => {

  openWindowWithPost(`${API_HOST}/serialnumber/export/v1/assign/${parameters.batchNumber}?format=${parameters.format}&fields=${parameters.fields}&token=${parameters.token}`,
    JSON.stringify({
      data: parameters.data,
    })

  );

};

function openWindowWithPost(url, data) {
  var form = document.createElement("form");
  form.target = "_blank";
  form.method = "POST";
  form.action = url;
  form.style.display = "none";


  var input = document.createElement("input");
  input.type = "hidden";
  input.name = 'data';
  input.value = data;
  form.appendChild(input);

  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);
}

export default exportAssignSN;
