import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { STOCKFLOW_REWARD_CENTER_VIEW } from "lib/constants/accessRights";
import {
  setFormType
} from "modules/stockflow/redux";
import AddRewardPageComponent from "./add-reward.page";

export default function AddRewardPageContainer() {
  
  const lang = useSelector(state => state.constant.languages);
  const dispatch = useDispatch();
  const history = useHistory();
  const hasStockflowViewAccessRight = useSelector(state => state.profile.accessRights.includes(STOCKFLOW_REWARD_CENTER_VIEW));

  useEffect(() => {
    dispatch(setFormType("create"))
    if (!hasStockflowViewAccessRight) {
      history.push('/admin/dashboard')
    }
  }, [history, hasStockflowViewAccessRight, dispatch])

  return (
    <AddRewardPageComponent
      lang={lang}
    />
  )
}