import React from "react";
import {
  makeStyles,
  Typography,
  Box,
  CircularProgress,
  Paper,
  Divider,
  Link,
  Button,
} from "@material-ui/core";
import { ChevronRightRounded as ChevronRightRoundedIcon } from "@material-ui/icons";
import { BackButton } from "components/button";
import RefreshTable from "components/table/refreshTable";
import DefaultImg from "assets/img/img-default.png";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { getLang } from "app/feature/constants";
import { TRANSFER_LIST_PAGE } from "modules/stockflow/config/stockflow.route";
import "moment/min/locales.min"
import DefaultImage from "assets/img/defaultPhoto.png";
import { DownloadSVG } from "modules/stockflow/utils/static";
import DOMPurify from "dompurify";

const useStyle = makeStyles((theme) => ({
  panelContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridGap: "1em",
    gridTemplateAreas: "'left-panel right-panel'",
    [theme.breakpoints.down("sm")]: {
      gridTemplateAreas: "'left-panel left-panel''right-panel right-panel'",
    },
  },
  productContainer: {
    marginBottom: "16px",
    padding: "20px 16px",
    borderRadius: "8px",
  },
  list: {
    maxHeight: "80vh",
    overflowY: "auto",
    padding: "4px 16px 16px",
    "&::-webkit-scrollbar": {
      width: "10px",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "16px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#E4E7EC",
      borderRadius: "16px",
    },
  },
  field: {
    display: "grid",
    gridTemplateColumns: "2fr 3fr",
    columnGap: theme.spacing(2),
  },
  panel: {
    backgroundColor: "#FFFFFF",
    borderRadius: 4,
    padding: "20px 16px",
    marginBottom: 16,
  },
  largeProfile: {
    height: 80,
    width: 80,
    minWidth: 80,
    padding: "2px",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& img": {
      height: "100%",
      minHeight: "100%",
      minWidth: "100%",
      objectFit: "cover",
      borderRadius: "50%",
      border: "1px solid #D0D5DD",
    },
  },
  productImage: {
    height: 50,
    width: 50,
    minWidth: 50,
    borderRadius: "4px",
    border: "1px solid #0000001A",
    padding: "2px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& img": {
      height: "100%",
      minHeight: "100%",
      minWidth: "100%",
      objectFit: "cover",
    },
  },
  item: {
    position: "relative",
    display: "flex",
    listStyle: "none",
  },
  seperator: {
    display: "flex",
    flexDirection: "column",
    flex: 0,
    alignItems: "center",
  },
  dot: {
    width: 12,
    height: 12,
    boxShadow: "none",
    backgroundColor: "#A749F0",
    color: "#ffffff",
    padding: 4,
    borderRadius: "50%",
    textAlign: "center",
    margin: "5px 0px",
  },
  connector: {
    width: 1,
    backgroundColor: "transparent",
    flexGrow: 1,
    minHeight: "25px",
    borderLeft: "2px dotted #D0D5DD",
  },
  flow: {
    paddingInlineStart: "0px",
  },
  statusChip: {
    color: "#FFFFFF",
    padding: "7px 18px",
    marginLeft: "10px",
    borderRadius: "4px",
  },
  profile: {
    height: 32,
    width: 32,
    minWidth: 32,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& img": {
      height: "100%",
      minHeight: "100%",
      minWidth: "100%",
      objectFit: "cover",
      border: "1px solid #D0D5DD",
      borderRadius: "50%",
    },
  },
  attributeText: {
    color: "#b2b2b2",
    fontSize: 12,
    whiteSpace: "break-spaces",
  },
}));

export default function RequestDetailsComponent({
  request,
  isFetching,
  language,
  isError,
  handleReload,
  lang,
  showApprovalConfirm,
  isExporting,
  exportPdf,
}) {
  const classes = useStyle();
  const history = useHistory();
  
  if(language==="ZH")
  {
    moment.locale('zh-cn')
  }else if(language==="TL")
  {
    moment.locale('tl-ph')
  }else
  {
    moment.locale(language)
  }


  const PENDING = "pending";
  const ACCEPTED = "accepted";
  const REJECTED = "rejected";
  const CANCELLED = "cancelled";

  const statusColor = {
    pending: "#FDB022",
    accepted: "#32D583",
    rejected: "#F97066",
    cancelled: "#98A2B3",
  };

  const statusPanelColor = {
    pending: "#FFFCF5",
    accepted: "#F6FEF9",
    rejected: "#FFFBFA",
    cancelled: "#FCFCFD",
  };

  const generateLink = (remark) => {
    let urlRegex = /(https?:\/\/[^\s]+)/g;
    let textWithLink = remark.replace(urlRegex, (url) => {
      return '<a href="' + url + '" target="_blank">' + url + "</a>";
    });

    const sanitizedHtmlContent = DOMPurify.sanitize(textWithLink);

    return (
      <Box
        dangerouslySetInnerHTML={{
          __html: sanitizedHtmlContent,
        }}
      />
    );
  };

  return (
    <Box className={classes.root}>
      <Box gridArea="topbar" mb={3}>
        <Box
          mb={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="flex" alignItems="flex-end" fontWeight="bold">
            <BackButton />
            <Box display="flex" ml={1} alignItems="flex-end">
              <Typography variant="h6" color="primary">
                {getLang(lang, "label.REQUEST_TRANSFER")}
              </Typography>
              <Box mx={0.5}>
                <ChevronRightRoundedIcon color="disabled" />
              </Box>
              <Box>
                <Typography variant="h6" color="primary">
                  {getLang(lang, "label.REQUEST_TRANSFER_DETAILS")}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              style={{
                padding: "5px 12px",
                background: "white",
                borderRadius: "8px",
              }}
              disableElevation
              onClick={() => {
                exportPdf();
              }}
              startIcon={
                isExporting ? (
                  <CircularProgress style={{ width: 10, height: 10 }} />
                ) : (
                  <DownloadSVG />
                )
              }
              disabled={isFetching || isExporting}
            >
              {getLang(lang, "label.DOWNLOAD_PURCHASE_ORDER")}
            </Button>
          </Box>
        </Box>
      </Box>
      <Box gridArea="panel">
        {!isFetching && !isError && request && (
          <>
            <Box
              display="flex"
              alignItems="center"
              px={2}
              py={2.5}
              mb={2}
              style={{ backgroundColor: statusPanelColor[request.status] }}
            >
              <Typography variant="h5">
                <b>
                  {getLang(lang, "label.TRANSFER_REQUEST_ID")}:{" "}
                  {request.reference_code}
                </b>
              </Typography>
              <Box
                className={classes.statusChip}
                style={{ backgroundColor: statusColor[request.status] }}
              >
                <Typography variant="h5">
                  <b>{getLang(lang, request.status_label)}</b>
                </Typography>
              </Box>
            </Box>
            <Box className={classes.panelContainer}>
              <Box gridArea="left-panel">
                <Paper
                  className={classes.panel}
                  elevation={3}
                  style={{ padding: "8px 16px" }}
                >
                  <Box mt={2}>
                    <ul className={classes.flow}>
                      <li className={classes.item}>
                        <Box className={classes.seperator}>
                          <Box
                            className={classes.dot}
                            style={{ backgroundColor: "#344054" }}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                          />
                          <Box className={classes.connector} />
                        </Box>
                        <Box ml={2} mb={3} mt={-0.5}>
                          <Box display="flex" alignItems="center" mb={0.5}>
                            <Typography variant="h6">
                              {getLang(lang, "label.REQUEST_TRANSFER_FROM")}
                            </Typography>
                          </Box>
                          <Box mb={1} display="flex" alignItems="center">
                            <Box className={classes.largeProfile} mr={1.5}>
                              {request.request_from?.picture ? (
                                <img
                                  src={request.request_from.picture}
                                  alt={request.request_from.name}
                                />
                              ) : (
                                <img src={DefaultImg} alt="default profile" />
                              )}
                            </Box>
                            <Box>
                              <Box mb={0.5}>
                                <Typography
                                  variant="body1"
                                  style={{ textAlign: "left" }}
                                >
                                  {request.request_from.name}
                                </Typography>
                              </Box>
                              <Box>
                                <Typography
                                  variant="body1"
                                  color="textSecondary"
                                >
                                  <span>
                                    {request.request_from.phone_code}{" "}
                                  </span>
                                  <span>
                                    {request.request_from.phone_number}
                                  </span>
                                </Typography>
                              </Box>
                              <Box>
                                <Typography
                                  variant="body1"
                                  color="textSecondary"
                                >
                                  {request.request_from.email}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </li>
                      <li className={classes.item}>
                        <Box className={classes.seperator}>
                          <Box
                            className={classes.dot}
                            style={{
                              backgroundColor: [
                                PENDING,
                                CANCELLED,
                                REJECTED,
                              ].includes(request.status)
                                ? "#98A2B3"
                                : "#344054",
                            }}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                          />
                        </Box>
                        <Box ml={2} mt={-0.5}>
                          <Box display="flex" alignItems="center" mb={0.5}>
                            <Typography
                              variant="h6"
                              color={
                                [PENDING, CANCELLED, REJECTED].includes(
                                  request.status
                                )
                                  ? "textSecondary"
                                  : "textPrimary"
                              }
                            >
                              {getLang(lang, "label.REQUEST_TRANSFER_TO")}
                            </Typography>
                          </Box>
                          <Box mb={1} display="flex" alignItems="center">
                            <Box className={classes.largeProfile} mr={1.5}>
                              {request.request_to?.picture ? (
                                <img
                                  src={request.request_to.picture}
                                  alt={request.request_to.name}
                                />
                              ) : (
                                <img src={DefaultImg} alt="default profile" />
                              )}
                            </Box>
                            <Box>
                              <Box mb={0.5}>
                                <Typography
                                  variant="body1"
                                  style={{ textAlign: "left" }}
                                >
                                  {request.request_to.name}
                                </Typography>
                              </Box>
                              <Box>
                                <Typography
                                  variant="body1"
                                  color="textSecondary"
                                >
                                  <span>{request.request_to.phone_code} </span>
                                  <span>{request.request_to.phone_number}</span>
                                </Typography>
                              </Box>
                              <Box>
                                <Typography
                                  variant="body1"
                                  color="textSecondary"
                                >
                                  {request.request_to.email}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </li>
                    </ul>
                  </Box>
                </Paper>
                <Paper className={classes.panel} elevation={3}>
                  <Box mb={2}>
                    <Typography variant="body1">
                      <b>{getLang(lang, "label.ORDER_INFORMATION")}</b>
                    </Typography>
                  </Box>
                  <Divider />
                  {!!request.transfer_detail && (
                    <>
                      <Box my={2}>
                        <Box className={classes.field} mt={2}>
                          <Typography variant="body1">
                            {getLang(lang, "label.TRANSFER_ID")}
                          </Typography>
                          <Box display="flex">
                            <Typography variant="body1" color="textSecondary">
                              :{" "}
                            </Typography>
                            <Box display="flex">
                              <Link
                                href={`${TRANSFER_LIST_PAGE}/${request.transfer_detail.uuid}`}
                                color="secondary"
                                onClick={(e) => {
                                  history.push(
                                    `${TRANSFER_LIST_PAGE}/${request.transfer_detail.uuid}`
                                  );
                                  e.preventDefault();
                                  return false;
                                }}
                              >
                                <Typography
                                  variant="body1"
                                  style={{ textAlign: "left" }}
                                >
                                  {request.transfer_detail.reference_code}
                                </Typography>
                              </Link>
                            </Box>
                          </Box>
                        </Box>
                        <Box className={classes.field} mt={2}>
                          <Typography variant="body1">
                            {getLang(lang, "label.TRANSFER_DATE")}
                          </Typography>
                          <Typography variant="body1" color="textSecondary">
                            <span>:</span>{" "}
                            <span>
                              {moment
                                .utc(request.transfer_detail.created_at)
                                .local()
                                .format("lll")}
                            </span>
                          </Typography>
                        </Box>
                      </Box>
                      <Divider />
                    </>
                  )}
                  {request.status === CANCELLED && (
                    <>
                      <Box my={2}>
                        <Box className={classes.field}>
                          <Typography variant="body1">
                            {getLang(lang, "label.CANCELLED_DATE")}
                          </Typography>
                          <Typography variant="body1" color="textSecondary">
                            <span>: </span>
                            <span>
                              {moment
                                .utc(request.respond_date)
                                .local()
                                .format("lll")}
                            </span>
                          </Typography>
                        </Box>
                        <Box className={classes.field} mt={2}>
                          <Typography variant="body1">
                            {getLang(lang, "label.CANCEL_REASON")}
                          </Typography>
                          <Box display="flex">
                            <Typography
                              variant="body1"
                              color="textSecondary"
                              style={{ marginRight: 3 }}
                            >
                              :{" "}
                            </Typography>
                            <Typography variant="body1" color="textSecondary">
                              <span>{request.reason || "-"}</span>
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Divider />
                    </>
                  )}
                  {!!request.status === REJECTED && (
                    <>
                      <Box my={2}>
                        <Box className={classes.field}>
                          <Typography variant="body1">
                            {getLang(lang, "label.REJECTED_DATE")}
                          </Typography>
                          <Typography variant="body1" color="textSecondary">
                            <span>: </span>
                            <span>
                              {moment
                                .utc(request.respond_date)
                                .local()
                                .format("lll")}
                            </span>
                          </Typography>
                        </Box>
                        {!!request.respond_by && (
                          <Box className={classes.field} mt={2}>
                            <Typography variant="body1">
                              {getLang(lang, "label.ACCEPTED_BY")}
                            </Typography>
                            <Box display="flex">
                              <Typography variant="body1" color="textSecondary">
                                :{" "}
                              </Typography>
                              <Box display="flex" ml={0.5}>
                                <Link
                                  href={`/admin/employee/View/${request.respond_by.uuid}`}
                                  color="secondary"
                                  onClick={(e) => {
                                    history.push(
                                      `/admin/employee/View/${request.respond_by.uuid}`
                                    );
                                    e.preventDefault();
                                    return false;
                                  }}
                                >
                                  <Typography
                                    variant="body1"
                                    style={{ textAlign: "left" }}
                                  >
                                    {request.respond_by.name}
                                  </Typography>
                                </Link>
                              </Box>
                            </Box>
                          </Box>
                        )}
                        <Box className={classes.field} mt={2}>
                          <Typography variant="body1">
                            {getLang(lang, "label.REJECTED_REASON")}
                          </Typography>
                          <Box display="flex">
                            <Typography
                              variant="body1"
                              color="textSecondary"
                              style={{ marginRight: 3 }}
                            >
                              :{" "}
                            </Typography>
                            <Typography variant="body1" color="textSecondary">
                              <span>{request.reason || "-"}</span>
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Divider />
                    </>
                  )}
                  {!!request.status === ACCEPTED && (
                    <>
                      <Box my={2}>
                        <Box className={classes.field}>
                          <Typography variant="body1">
                            {getLang(lang, "label.ACCEPTED_DATE")}
                          </Typography>
                          <Typography variant="body1" color="textSecondary">
                            <span>: </span>
                            <span>
                              {moment
                                .utc(request.respond_date)
                                .local()
                                .format("lll")}
                            </span>
                          </Typography>
                        </Box>
                        {!!request.respond_by && (
                          <Box className={classes.field} mt={2}>
                            <Typography variant="body1">
                              {getLang(lang, "label.ACCEPTED_BY")}
                            </Typography>
                            <Box display="flex">
                              <Typography variant="body1" color="textSecondary">
                                :{" "}
                              </Typography>
                              <Box display="flex">
                                <Link
                                  href={`/admin/employee/View/${request.respond_by.uuid}`}
                                  color="secondary"
                                  onClick={(e) => {
                                    history.push(
                                      `/admin/employee/View/${request.respond_by.uuid}`
                                    );
                                    e.preventDefault();
                                    return false;
                                  }}
                                >
                                  <Typography
                                    variant="body1"
                                    style={{ textAlign: "left" }}
                                  >
                                    {request.respond_by.name}
                                  </Typography>
                                </Link>
                              </Box>
                            </Box>
                          </Box>
                        )}
                      </Box>
                      <Divider />
                    </>
                  )}
                  {!!request.approval_date && (
                    <>
                      <Box my={2}>
                        <Box className={classes.field}>
                          <Typography variant="body1">
                            {getLang(lang, "label.APPROVAL_DATE")}
                          </Typography>
                          <Typography variant="body1" color="textSecondary">
                            <span>: </span>
                            <span>
                              {moment
                                .utc(request.approval_date)
                                .local()
                                .format("lll")}
                            </span>
                          </Typography>
                        </Box>
                      </Box>
                      <Divider />
                    </>
                  )}
                  {!!request.approval_reject_date && (
                    <>
                      <Box my={2}>
                        <Box className={classes.field}>
                          <Typography variant="body1">
                            {getLang(lang, "label.APPROVAL_REJECT_DATE")}
                          </Typography>
                          <Typography variant="body1" color="textSecondary">
                            <span>: </span>
                            <span>
                              {moment
                                .utc(request.approval_reject_date)
                                .local()
                                .format("lll")}
                            </span>
                          </Typography>
                        </Box>
                        <Box className={classes.field} mt={2}>
                          <Typography variant="body1">
                            {getLang(lang, "label.APPROVAL_REJECT_REASON")}
                          </Typography>
                          <Box display="flex">
                            <Typography
                              variant="body1"
                              color="textSecondary"
                              style={{ marginRight: 3 }}
                            >
                              :{" "}
                            </Typography>
                            <Typography variant="body1" color="textSecondary">
                              <span>{request.reason || "-"}</span>
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Divider />
                    </>
                  )}
                  <Box mt={2}>
                    <Box className={classes.field}>
                      <Typography variant="body1">
                        {getLang(lang, "label.REQUEST_DATE")}
                      </Typography>
                      <Typography variant="body1" color="textSecondary">
                        <span>: </span>
                        <span>
                          {moment
                            .utc(request.request_date)
                            .local()
                            .format("lll")}
                        </span>
                      </Typography>
                    </Box>
                    {!!request.request_by && (
                      <Box className={classes.field} mt={2}>
                        <Typography variant="body1">
                          {getLang(lang, "label.REQUEST_TRANSFER_BY")}
                        </Typography>
                        <Box display="flex">
                          <Typography variant="body1" color="textSecondary">
                            :{" "}
                          </Typography>
                          <Box display="flex" ml={0.5} flexDirection="column">
                            <Box display="flex" ml={0.5}>
                              <Box className={classes.profile} mr={1}>
                                {request.request_by?.picture ? (
                                  <img
                                    src={request.request_by.picture}
                                    alt={request.request_by.name}
                                  />
                                ) : (
                                  <img
                                    src={DefaultImage}
                                    alt="default profile"
                                  />
                                )}
                              </Box>
                              <Link
                                href={`/admin/employee/View/${request.request_by.uuid}`}
                                color="secondary"
                                onClick={(e) => {
                                  history.push(
                                    `/admin/employee/View/${request.request_by.uuid}`
                                  );
                                  e.preventDefault();
                                  return false;
                                }}
                              >
                                <Typography
                                  variant="body1"
                                  style={{ textAlign: "left" }}
                                >
                                  {request.request_by.name}
                                </Typography>
                              </Link>
                            </Box>
                            {!!request.request_by.branch && (
                              <Typography
                                variant="body1"
                                style={{ textAlign: "left" }}
                              >
                                {request.request_by.branch}
                              </Typography>
                            )}
                          </Box>
                        </Box>
                      </Box>
                    )}
                    <Box className={classes.field} mt={2}>
                      <Typography variant="body1">
                        {getLang(lang, "label.REMARK_BY_REQUESTOR")}
                      </Typography>
                      <Box display="flex">
                        <Typography
                          variant="body1"
                          color="textSecondary"
                          style={{ marginRight: 3 }}
                        >
                          :{" "}
                        </Typography>
                        <Typography variant="body1" color="textSecondary">
                          {request.remarks
                            ? generateLink(request.remarks)
                            : "-"}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Paper>
                <Paper className={classes.panel} elevation={3}>
                  <Box mb={2}>
                    <Typography variant="body1">
                      <b>{getLang(lang, "label.SHIPPING_DETAILS")}</b>
                    </Typography>
                  </Box>
                  <Divider />
                  <Box mt={2}>
                    <Box className={classes.field}>
                      <Typography variant="body1">
                        {getLang(lang, "label.NAME")}
                      </Typography>
                      <Typography variant="body1" color="textSecondary">
                        <span>: </span>
                        <span>
                          {request.shipping_detail
                            ? request.shipping_detail.name
                            : getLang(lang, "label.NONE")}
                        </span>
                      </Typography>
                    </Box>
                    <Box className={classes.field} mt={2}>
                      <Typography variant="body1">
                        {getLang(lang, "label.CONTACT")}
                      </Typography>
                      <Typography variant="body1" color="textSecondary">
                        <span>: </span>
                        <span>
                          {request.shipping_detail
                            ? `${request.shipping_detail.phone_code} ${request.shipping_detail.phone_number}`
                            : getLang(lang, "label.NONE")}
                        </span>
                      </Typography>
                    </Box>
                    <Box className={classes.field} mt={2}>
                      <Typography variant="body1">
                        {getLang(lang, "label.ADDRESS")}
                      </Typography>
                      <Box display="flex">
                        <Typography
                          variant="body1"
                          color="textSecondary"
                          style={{ marginRight: 3 }}
                        >
                          :{" "}
                        </Typography>
                        <Typography variant="body1" color="textSecondary">
                          <span>
                            {request.shipping_detail
                              ? request.shipping_detail.address
                              : getLang(lang, "label.NONE")}
                          </span>
                        </Typography>
                      </Box>
                    </Box>
                    {!request.shipping_detail && (
                      <Box mt={2}>
                        <Typography variant="body1" color="textSecondary">
                          {getLang(
                            lang,
                            "paragraph.REQUESTOR_SHIPPING_DETAILS_NOT_PROVIDED"
                          )}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Paper>
              </Box>

              <Box gridArea="right-panel">
                {request.is_approval_required === 1 && (
                  <Paper
                    elevation={0}
                    style={{ marginBottom: 16, padding: 10 }}
                  >
                    <Box display="flex" height={42}>
                      <Button
                        variant="contained"
                        disableElevation
                        color="secondary"
                        size="small"
                        type="submit"
                        style={{
                          flex: 1,
                          marginRight: 16,
                          backgroundColor: "#F04438",
                        }}
                        onClick={() => showApprovalConfirm(false)}
                      >
                        {getLang(lang, "label.REJECT")}
                      </Button>
                      <Button
                        variant="contained"
                        disableElevation
                        color="secondary"
                        size="small"
                        type="submit"
                        style={{ flex: 1 }}
                        onClick={() => showApprovalConfirm(true)}
                      >
                        {getLang(lang, "label.APPROVE")}
                      </Button>
                    </Box>
                  </Paper>
                )}
                <Paper
                  className={classes.panel}
                  style={{ height: "100%", padding: 0 }}
                >
                  <Box px={2} pt={2}>
                    <Typography variant="body1">
                      <b>{getLang(lang, "label.ITEMS_REQUESTED")}</b>
                    </Typography>
                  </Box>
                  <Box p={2}>
                    <Divider />
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    mb={2}
                    px={2}
                  >
                    <Typography variant="body1">
                      {getLang(lang, "label.PRODUCT_NAME")}
                    </Typography>
                    <Typography variant="body1">
                      {getLang(lang, "label.QUANTITY")}
                    </Typography>
                  </Box>
                  <Box className={classes.list}>
                    {request.items.map((item, index) => (
                      <Paper
                        key={item.id}
                        elevation={3}
                        className={classes.productContainer}
                      >
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Box display="flex" alignItems="center" mr={1}>
                            <Typography variant="body1">
                              <b>{index + 1}.</b>
                            </Typography>
                            <Box mx={2} className={classes.productImage}>
                              {item.picture ? (
                                <img src={item.picture} alt={item.name} />
                              ) : (
                                <img src={DefaultImg} alt="default product" />
                              )}
                            </Box>
                            <Box
                              display="flex"
                              flexDirection="column"
                              justifyContent="center"
                              alignItems="flex-start"
                            >
                              <Typography variant="body1">
                                <b>{item.name}</b>
                              </Typography>
                              {!!item.attributes &&
                                !!item.attributes.length && (
                                  <Typography
                                    className={classes.attributeText}
                                    variant="body2"
                                  >
                                    {item.attributes.join(", ")}
                                  </Typography>
                                )}
                            </Box>
                          </Box>
                          <Typography variant="body1">
                            {item.quantity.toLocaleString("en-US")}
                          </Typography>
                        </Box>
                      </Paper>
                    ))}
                  </Box>
                </Paper>
              </Box>
            </Box>
          </>
        )}
        {!isFetching && isError && (
          <Box className={classes.tabContainer}>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <RefreshTable handleReload={handleReload} />
            </Box>
          </Box>
        )}
        {isFetching && (
          <Box gridArea="main" mt={6} style={{ textAlign: "center" }}>
            <CircularProgress color="primary" />
          </Box>
        )}
      </Box>
    </Box>
  );
}
