import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import LoadingComponent from "components/loading";
import {
  STOCKFLOW_CHECKOUT_VIEW,
  STOCKFLOW_DELIVERY_INFO_EDIT,
} from "lib/constants/accessRights";
import { ROOT } from "modules/dashboard";
import CheckoutDetailsComponent from "./checkoutDetails.component";
import { selectLoading } from "modules/notification";
import {
  getCheckoutDeliveryInfo,
  getCheckoutDetail,
  resetCheckout,
  updateCheckoutDeliveryInfo,
  updateCheckoutDetail,
  updateCheckoutInfo,
} from "modules/stockflow/redux";
import { useState } from "react";
import { unwrapResult } from "@reduxjs/toolkit";

function CheckoutDetailsContainer() {
  const history = useHistory();
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const id = match.params.uuid;

  const hasStockflowViewAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(STOCKFLOW_CHECKOUT_VIEW)
  );

  const hasDeliveryEditAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(STOCKFLOW_DELIVERY_INFO_EDIT)
  );

  const checkout = useSelector((state) => state.stockflowCheckout.checkout);
  const isError = useSelector(
    (state) => state.stockflowCheckout.isLoadingCheckoutError
  );
  const isFetching = useSelector((state) =>
    selectLoading(state, getCheckoutDetail.typePrefix)
  );
  const isUpdating = useSelector((state) =>
    selectLoading(state, updateCheckoutDeliveryInfo.typePrefix)
  );
  const isUpdatingRemark = useSelector((state) =>
    selectLoading(state, updateCheckoutDetail.typePrefix)
  );

  const lang = useSelector((state) => state.constant.languages);
  const language = useSelector((state) => state.profile.language);

  const [isEdit, setIsEdit] = useState(false);
  const [isEditRemark, setIsEditRemark] = useState(false);

  useEffect(() => {
    if (hasStockflowViewAccessRight) {
      if (id && hasStockflowViewAccessRight) {
        dispatch(getCheckoutDetail(id));
      } else {
        history.push(ROOT);
      }
    } else {
      history.push("/admin/dashboard");
    }
  }, [history, id, dispatch, hasStockflowViewAccessRight]);

  useEffect(() => {
    return () => {
      dispatch(resetCheckout());
    };
  }, [dispatch]);

  if (!hasStockflowViewAccessRight) return <LoadingComponent />;

  const handleReload = () => {
    dispatch(getCheckoutDetail(id));
  };

  const updateDelivery = (values) => {
    values.postalCode = values.postalCode.trim();
    dispatch(
      updateCheckoutDeliveryInfo({
        uuid: id,
        tracking: values.tracking,
        courier_code: values.courier?.code,
        postal_code: values.postalCode,
        country: values.country,
      })
    ).then(() => {
      handleReload();
    });
  };

  const fetchDeliveryInfo = () => {
    dispatch(getCheckoutDeliveryInfo(id));
  };

  const handleUpdateRemark = (remark) => {
    dispatch(updateCheckoutDetail({ uuid: id, remark: remark.value }))
      .then(unwrapResult)
      .then(() => {
        dispatch(
          updateCheckoutInfo({
            remark: remark.value,
          })
        );
        setIsEditRemark(false);
      });
  };

  return (
    <CheckoutDetailsComponent
      hasDeliveryEditAccessRight={hasDeliveryEditAccessRight}
      checkout={checkout}
      isFetching={isFetching}
      isError={isError}
      language={language}
      handleReload={handleReload}
      lang={lang}
      id={id}
      updateDelivery={updateDelivery}
      isUpdating={isUpdating}
      isEdit={isEdit}
      setIsEdit={setIsEdit}
      fetchDeliveryInfo={fetchDeliveryInfo}
      setIsEditRemark={setIsEditRemark}
      isEditRemark={isEditRemark}
      handleUpdateRemark={handleUpdateRemark}
      isUpdatingRemark={isUpdatingRemark}
    />
  );
}

export default CheckoutDetailsContainer;
