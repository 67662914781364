import React from "react";
import PropTypes from "prop-types";
import { makeStyles, Box, Typography, Tabs, Divider } from "@material-ui/core";
import CustomTab from "components/tab";
import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";
import StructureOverviewPanel from "../../components/panel/structureOverviewPanel";
import NewEmployeePanel from "modules/organisation-structure/components/panel/newEmployeePanel";
import { getLang } from "app/feature/constants";

const style = (theme) => ({
  root: {
    display: "grid",
    gridTemplateColumns: "7fr 3fr",
    gridTemplayeRows: "auto auto",
    columnGap: theme.spacing(2),
    gridTemplateAreas: "'topbar .''panel photo'",
    [theme.breakpoints.down("md")]: {
      gridTemplateAreas: "'topbar topbar''panel photo'"
    },
    [theme.breakpoints.down("sm")]: {
      gridTemplateAreas: "'topbar topbar''panel panel''photo photo'"
    }
  },
  indicator: {
    border: "0.5px solid",
    borderColor: theme.palette.primary.main
  },
  scroll: {
    width: "min-content"
  }
});

const useStyles = makeStyles(style);

export default function MainPageComponent({
  hasEmployeeViewAccessRight,
  handleChange,
  activeIndex,
  tabLabels,
  lang
}) {
  const classes = useStyles();

  function a11yProps(index, label = "label") {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tab-${index}`,
      label: <Typography variant="button" children={label} />
    };
  }

  function TabPanel({ children, value, index, ...other }) {
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </Typography>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
  };

  return (
    <Box className={classes.root}>
      <Box gridArea="topbar">
        <Box
          mb={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="flex" alignItems="flex-end" fontWeight="bold">
            <Typography variant="h6" color="primary">
              {getLang(lang,"label.COMPANY")}
            </Typography>
            <Box mx={0.5}>
              <ChevronRightRoundedIcon color="disabled" />
            </Box>
            <Box>
              <Typography variant="h6" color="primary">
                {tabLabels[activeIndex]}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
      </Box>

      <Box gridArea="panel">
        <Box>
          <Box>
            <Tabs
              value={activeIndex}
              onChange={handleChange}
              aria-label="scrollable auto tabs example"
              textColor="primary"
              variant="scrollable"
              indicatorColor="primary"
              classes={{
                indicator: classes.indicator,
                scrollButtons: classes.scroll,
              }}
            >
              {tabLabels.map((item, index) => {
                return <CustomTab key={index} {...a11yProps(index, item)} />;
              })}
            </Tabs>
          </Box>

          <Box>
            <TabPanel value={activeIndex} index={0}>
              <StructureOverviewPanel />
            </TabPanel>
          </Box>
        </Box>
      </Box>

      {
        hasEmployeeViewAccessRight
          ?
          <Box gridArea="photo" mt={{ xs: 3, sm: 3, md: 6 }}>
            <TabPanel value={activeIndex} index={0}>

              <NewEmployeePanel />

            </TabPanel>
          </Box>
          : null
      }
    </Box>
  );
}
