import React from "react";
import {
  Paper,
  makeStyles,
  Typography,
  CircularProgress
} from "@material-ui/core";

const useStyle = makeStyles(theme => ({
  root: {
    padding: theme.spacing(15),
    borderRadius: 10,
    outline: 0,
    textAlign: "center"
  },
  text: {
    marginTop: theme.spacing(2)
  }
}));

export default function UploadingComponent() {
  const classes = useStyle();
  return (
    <Paper elevation={0} className={classes.root}>
      <CircularProgress />
      <Typography className={classes.text}>Uploading..</Typography>
    </Paper>
  );
}
