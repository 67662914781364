import React, { useState, useRef } from "react";
import NotificationBadgeComponent from "./notificationBadge.component";
import useAlert from "../../utils/useAlert.hook";
import { useSelector } from "react-redux";

export default function NotificationBadgeContainer() {
  const [isOpen, setIsOpen] = useState(false);
  const anchorRef = useRef(null);
  const [alerts, isFetching, hasNotReadCount, readNotifications] = useAlert();
  const lang = useSelector(state => state.constant.languages)

  const handleToggle = () => {
    if (!isOpen && hasNotReadCount !== 0) {
      readNotifications();
    }
    setIsOpen(prev => !prev);
  };
  const handleClose = () => setIsOpen(false);

  return (
    <NotificationBadgeComponent
      alerts={alerts}
      hasNotReadCount={hasNotReadCount}
      isFetching={isFetching}
      handleToggle={handleToggle}
      handleClose={handleClose}
      isOpen={isOpen}
      anchorRef={anchorRef}
      lang={lang}
    />
  );
}
