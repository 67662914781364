import { createSlice } from "@reduxjs/toolkit";
import { REPORTING } from "modules/reporting/constants";

import {
    getCampaignConversionData,
    getCampaignPerformanceData,
    getCampaignSurveyListData,
    getCampaignLuckyDrawListData,
    getCampaignInstantLuckyDrawListData,
    getCampaignLoyaltyProgramListData,
    getCampaignWarrantyListData,
    getAllCampaignRespondentsData,
    getCampaignTypeSurveyDatalist,
    getRespondentsConsumerAllSurveyDatalist,
    getCampaignTypeLuckyDrawDatalist,
    getRespondentsConsumerAllLuckyDrawDatalist,
    getCampaignTypeInstantLuckyDrawDatalist,
    getRespondentsConsumerAllInstantLuckyDrawDatalist,
    getCampaignTypeLoyaltyProgramDatalist,
    getRespondentsConsumerAllLoyaltyProgramDatalist,
    getCampaignTypeWarrantyDatalist,
    getRespondentsConsumerAllWarrantyDatalist,
    getCampaignSummaryData,
    getCampaignTypeData,
    getCampaignProductData,
    getRespondentsSingleCampaignDatalist,
    getCampaignPrizeDatalist,
    getCampaignPrizeSummaryData,
    getWinnerParticipantDatalist,
} from "../action";

const initialState = {
    campaignConversionData: {},
    campaignPerformanceDatalist: {},
    campaignSurveyDatalist: {},
    campaignLuckyDrawDatalist: {},
    campaignInstantLuckyDrawDatalist: {},
    campaignLoyaltyProgramDatalist: {},
    campaignWarrantyDatalist: {},
    campaignRespondentsData: {},

    campaignTypeSurveyDatalist: {},
    campaignTypeLuckyDrawDatalist: {},
    campaignTypeInstantLuckyDrawDatalist: {},
    campaignTypeLoyaltyProgramDatalist: {},
    campaignTypeWarrantyDatalist: {},

    respondentsConsumerAllSurveyDatalist: {},
    respondentsConsumerAllLuckyDrawDatalist: {},
    respondentsConsumerAllInstantLuckyDrawDatalist: {},
    respondentsConsumerAllLoyaltyProgramDatalist: {},
    respondentsConsumerAllWarrantyDatalist: {},

    campaignSummaryData: {},
    campaignTypeData: {},
    campaignProductData: {},

    respondentsSingleCampaignDatalist: {},
    campaignPrizeDatalist: {},
    campaignPrizeSummaryData: {},
    winnerParticipantDatalist: {},

}

const reportingCampaignSlice = createSlice({
    name: REPORTING,
    initialState,
    reducers: {
        initReportingCampaignConversion: (state) => {
            state.campaignConversionData = initialState.campaignConversionData;
        },
        initReportingCampaignPerformance: (state) => {
            state.campaignPerformanceDatalist = initialState.campaignPerformanceDatalist;
        },
        initReportingCampaignSurvey: (state) => {
            state.campaignSurveyDatalist = initialState.campaignSurveyDatalist;
        },
        initReportingCampaignLuckyDraw: (state) => {
            state.campaignLuckyDrawDatalist = initialState.campaignLuckyDrawDatalist;
        },
        initReportingCampaignInstantLuckyDraw: (state) => {
            state.campaignInstantLuckyDrawDatalist = initialState.campaignInstantLuckyDrawDatalist;
        },
        initReportingCampaignLoyaltyProgram: (state) => {
            state.campaignLoyaltyProgramDatalist = initialState.campaignLoyaltyProgramDatalist;
        },
        initReportingCampaignWarranty: (state) => {
            state.campaignWarrantyDatalist = initialState.campaignWarrantyDatalist;
        },
        initReportingCampaignTypeSurvey: (state) => {
            state.campaignTypeSurveyDatalist = initialState.campaignTypeSurveyDatalist;
        },
        initRespondentsConsumerAllSurvey: (state) => {
            state.respondentsConsumerAllSurveyDatalist = initialState.respondentsConsumerAllSurveyDatalist;
        },
        initReportingCampaignTypeLuckyDraw: (state) => {
            state.campaignTypeLuckyDrawDatalist = initialState.campaignTypeLuckyDrawDatalist;
        },
        initRespondentsConsumerAllLuckyDraw: (state) => {
            state.respondentsConsumerAllLuckyDrawDatalist = initialState.respondentsConsumerAllLuckyDrawDatalist;
        },
        initReportingCampaignTypeInstantLuckyDraw: (state) => {
            state.campaignTypeInstantLuckyDrawDatalist = initialState.campaignTypeInstantLuckyDrawDatalist;
        },
        initRespondentsConsumerAllInstantLuckyDraw: (state) => {
            state.respondentsConsumerAllInstantLuckyDrawDatalist = initialState.respondentsConsumerAllInstantLuckyDrawDatalist;
        },
        initReportingCampaignTypeLoyaltyProgram: (state) => {
            state.campaignTypeLoyaltyProgramDatalist = initialState.campaignTypeLoyaltyProgramDatalist;
        },
        initRespondentsConsumerAllLoyaltyProgram: (state) => {
            state.respondentsConsumerAllLoyaltyProgramDatalist = initialState.respondentsConsumerAllLoyaltyProgramDatalist;
        },
        initReportingCampaignTypeWarranty: (state) => {
            state.campaignTypeWarrantyDatalist = initialState.campaignTypeWarrantyDatalist;
        },
        initRespondentsConsumerAllWarranty: (state) => {
            state.respondentsConsumerAllWarrantyDatalist = initialState.respondentsConsumerAllWarrantyDatalist;
        },
        initReportingCampaignSummary: (state) => {
            state.campaignSummaryData = initialState.campaignSummaryData;
        },
        initReportingCampaignType: (state) => {
            state.campaignTypeData = initialState.campaignTypeData;
        },
        initReportingCampaignProduct: (state) => {
            state.campaignProductData = initialState.campaignProductData;
        },
        initRespondentsSingleCampaign: (state) => {
            state.respondentsSingleCampaignDatalist = initialState.respondentsSingleCampaignDatalist;
        },
        initCampaignPrize: (state) => {
            state.campaignPrizeDatalist = initialState.campaignPrizeDatalist;
        },
        initCampaignPrizeSummary: (state) => {
            state.campaignPrizeSummaryData = initialState.campaignPrizeSummaryData;
        },
        initWinnerParticipant: (state) => {
            state.winnerParticipantDatalist = initialState.winnerParticipantDatalist;
        }

    },
    extraReducers : {
        // (a) get campaign conversion data -- start
        // CAMPAIGN CONVERSION -- START
        [getCampaignConversionData.fulfilled]: (state, action) => {
            let data = action.payload.data;
            data.isSuccess = action.payload.success;

            let count = 0;
            data.rows.forEach((item) => {
                count += item.engagement ? 1 : 0;
            });

            data.noResult = (count === 0);
            // data.noResult = false;

            state.campaignConversionData = data;
        },
        [getCampaignConversionData.rejected]: (state, action) => {
            state.campaignConversionData = {
                isSuccess: false,
                noResult: true,
                rows: [],
                total: 0,
            };
        },
        // CAMPAIGN CONVERSION -- END

        // CAMPAIGN PERFORMANCE DATA -- START
        [getCampaignPerformanceData.fulfilled]: (state, action) => {
            let data = action.payload.data;
            data.isSuccess = action.payload.success;
            data.noResult = (data.rows.length === 0);

            state.campaignPerformanceDatalist = data;
        },
        [getCampaignPerformanceData.rejected]: (state, action) => {
            state.campaignPerformanceDatalist = {
                isSuccess: false,
                noResult: true,
                rows: [],
                total: 0,
            };
        },
        // CAMPAIGN PERFORMANCE DATA -- END

        // CAMPAIGN SURVEY LIST DATA -- START
        [getCampaignSurveyListData.fulfilled]: (state, action) => {
            let data = action.payload.data;
            data.isSuccess = action.payload.success;
            data.noResult = (data.rows.length === 0);

            state.campaignSurveyDatalist = data;
        },
        [getCampaignSurveyListData.rejected]: (state, action) => {
            state.campaignSurveyDatalist = {
                isSuccess: false,
                noResult: true,
                rows: [],
                total: 0,
            };
        },
        // CAMPAIGN SURVEY LIST DATA -- END

        // CAMPAIGN LUCKY DRAW LIST DATA -- START
        [getCampaignLuckyDrawListData.fulfilled]: (state, action) => {
            let data = action.payload.data;
            data.isSuccess = action.payload.success;
            data.noResult = (data.rows.length === 0);

            state.campaignLuckyDrawDatalist = data;
        },
        [getCampaignLuckyDrawListData.rejected]: (state, action) => {
            state.campaignLuckyDrawDatalist = {
                isSuccess: false,
                noResult: true,
                rows: [],
                total: 0,
            };
        },
        // CAMPAIGN LUCKY DRAW LIST DATA -- END

        // CAMPAIGN INSTANT LUCKY DRAW LIST DATA -- START
        [getCampaignInstantLuckyDrawListData.fulfilled]: (state, action) => {
            let data = action.payload.data;
            data.isSuccess = action.payload.success;
            data.noResult = (data.rows.length === 0);

            state.campaignInstantLuckyDrawDatalist = data;
        },
        [getCampaignInstantLuckyDrawListData.rejected]: (state, action) => {
            state.campaignInstantLuckyDrawDatalist = {
                isSuccess: false,
                noResult: true,
                rows: [],
                total: 0,
            };
        },
        // CAMPAIGN INSTANT LUCKY DRAW LIST DATA -- END

        // CAMPAIGN LOYALTY PROGRAM LIST DATA -- START
        [getCampaignLoyaltyProgramListData.fulfilled]: (state, action) => {
            let data = action.payload.data;
            data.isSuccess = action.payload.success;
            data.noResult = (data.rows.length === 0);

            state.campaignLoyaltyProgramDatalist = data;
        },
        [getCampaignLoyaltyProgramListData.rejected]: (state, action) => {
            state.campaignLoyaltyProgramDatalist = {
                isSuccess: false,
                noResult: true,
                rows: [],
                total: 0,
            };
        },
        // CAMPAIGN LOYALTY PROGRAM LIST DATA -- END

        // CAMPAIGN WARRANTY LIST DATA -- START
        [getCampaignWarrantyListData.fulfilled]: (state, action) => {
            let data = action.payload.data;
            data.isSuccess = action.payload.success;
            data.noResult = (data.rows.length === 0);

            state.campaignWarrantyDatalist = data;
        },
        [getCampaignWarrantyListData.rejected]: (state, action) => {
            state.campaignWarrantyDatalist = {
                isSuccess: false,
                noResult: true,
                rows: [],
                total: 0,
            };
        },
        // CAMPAIGN WARRANTY LIST DATA -- END

        // CAMPAIGN ALL RESPONDENTS DATA -- START
        [getAllCampaignRespondentsData.fulfilled]: (state, action) => {
            let data = action.payload.data;
            data.isSuccess = action.payload.success;
            data.noResult = (data.rows.length === 0);

            state.campaignRespondentsData = data;
        },
        [getAllCampaignRespondentsData.rejected]: (state, action) => {
            state.campaignRespondentsData = {
                isSuccess: false,
                noResult: true,
                rows: [],
                total: 0,
            };
        },
        // CAMPAIGN ALL RESPONDENTS DATA -- END

        // CAMPAIGN TYPE SURVEY DATA -- START
        [getCampaignTypeSurveyDatalist.fulfilled]: (state, action) => {
            let data = action.payload.data;
            data.isSuccess = action.payload.success;
            data.noResult = (data.rows.length === 0);

            state.campaignTypeSurveyDatalist = data;
        },
        [getCampaignTypeSurveyDatalist.rejected]: (state, action) => {
            state.campaignTypeSurveyDatalist = {
                isSuccess: false,
                noResult: true,
                rows: [],
                total: 0,
            };
        },
        // CAMPAIGN TYPE SURVEY DATA -- END

        // RESPONDENTS CONSUMER ALL SURVEY DATALIST -- START
        [getRespondentsConsumerAllSurveyDatalist.fulfilled]: (state, action) => {
            let data = action.payload.data;
            data.isSuccess = action.payload.success;
            data.noResult = (data.rows.length === 0);

            state.respondentsConsumerAllSurveyDatalist = data;
        },
        [getRespondentsConsumerAllSurveyDatalist.rejected]: (state, action) => {
            state.respondentsConsumerAllSurveyDatalist = {
                isSuccess: false,
                noResult: true,
                rows: [],
                total: 0,
            };
        },
        // RESPONDENTS CONSUMER ALL SURVEY DATALIST -- END

        // CAMPAIGN TYPE LUCKY DRAW DATA -- START
        [getCampaignTypeLuckyDrawDatalist.fulfilled]: (state, action) => {
            let data = action.payload.data;
            data.isSuccess = action.payload.success;
            data.noResult = (data.rows.length === 0);

            state.campaignTypeLuckyDrawDatalist = data;
        },
        [getCampaignTypeLuckyDrawDatalist.rejected]: (state, action) => {
            state.campaignTypeLuckyDrawDatalist = {
                isSuccess: false,
                noResult: true,
                rows: [],
                total: 0,
            };
        },
        // CAMPAIGN TYPE LUCKY DRAW DATA -- END

        // RESPONDENTS CONSUMER ALL LUCKY DRAW DATALIST -- START
        [getRespondentsConsumerAllLuckyDrawDatalist.fulfilled]: (state, action) => {
            let data = action.payload.data;
            data.isSuccess = action.payload.success;
            data.noResult = (data.rows.length === 0);

            state.respondentsConsumerAllLuckyDrawDatalist = data;
        },
        [getRespondentsConsumerAllLuckyDrawDatalist.rejected]: (state, action) => {
            state.respondentsConsumerAllLuckyDrawDatalist = {
                isSuccess: false,
                noResult: true,
                rows: [],
                total: 0,
            };
        },
        // RESPONDENTS CONSUMER ALL LUCKY DRAW DATALIST -- END

        // CAMPAIGN TYPE INSTANT LUCKY DRAW DATA -- START
        [getCampaignTypeInstantLuckyDrawDatalist.fulfilled]: (state, action) => {
            let data = action.payload.data;
            data.isSuccess = action.payload.success;
            data.noResult = (data.rows.length === 0);

            state.campaignTypeInstantLuckyDrawDatalist = data;
        },
        [getCampaignTypeInstantLuckyDrawDatalist.rejected]: (state, action) => {
            state.campaignTypeInstantLuckyDrawDatalist = {
                isSuccess: false,
                noResult: true,
                rows: [],
                total: 0,
            };
        },
        // CAMPAIGN TYPE INSTANT LUCKY DRAW DATA -- END

        // RESPONDENTS CONSUMER ALL INSTANT LUCKY DRAW DATALIST -- START
        [getRespondentsConsumerAllInstantLuckyDrawDatalist.fulfilled]: (state, action) => {
            let data = action.payload.data;
            data.isSuccess = action.payload.success;
            data.noResult = (data.rows.length === 0);

            state.respondentsConsumerAllInstantLuckyDrawDatalist = data;
        },
        [getRespondentsConsumerAllInstantLuckyDrawDatalist.rejected]: (state, action) => {
            state.respondentsConsumerAllInstantLuckyDrawDatalist = {
                isSuccess: false,
                noResult: true,
                rows: [],
                total: 0,
            };
        },
        // RESPONDENTS CONSUMER ALL INSTANT LUCKY DRAW DATALIST -- END
        
        // CAMPAIGN TYPE LOYALTY PROGRAM DATA -- START
        [getCampaignTypeLoyaltyProgramDatalist.fulfilled]: (state, action) => {
            let data = action.payload.data;
            data.isSuccess = action.payload.success;
            data.noResult = (data.rows.length === 0);

            state.campaignTypeLoyaltyProgramDatalist = data;
        },
        [getCampaignTypeLoyaltyProgramDatalist.rejected]: (state, action) => {
            state.campaignTypeLoyaltyProgramDatalist = {
                isSuccess: false,
                noResult: true,
                rows: [],
                total: 0,
            };
        },
        // CAMPAIGN TYPE LOYALTY PROGRAM DATA -- END

        // RESPONDENTS CONSUMER ALL LOYALTY PROGRAM DATALIST -- START
        [getRespondentsConsumerAllLoyaltyProgramDatalist.fulfilled]: (state, action) => {
            let data = action.payload.data;
            data.isSuccess = action.payload.success;
            data.noResult = (data.rows.length === 0);

            state.respondentsConsumerAllLoyaltyProgramDatalist = data;
        },
        [getRespondentsConsumerAllLoyaltyProgramDatalist.rejected]: (state, action) => {
            state.respondentsConsumerAllLoyaltyProgramDatalist = {
                isSuccess: false,
                noResult: true,
                rows: [],
                total: 0,
            };
        },
        // RESPONDENTS CONSUMER ALL LOYALTY PROGRAM DATALIST -- END

        // CAMPAIGN TYPE WARRANTY DATA -- START
        [getCampaignTypeWarrantyDatalist.fulfilled]: (state, action) => {
            let data = action.payload.data;
            data.isSuccess = action.payload.success;
            data.noResult = (data.rows.length === 0);

            state.campaignTypeWarrantyDatalist = data;
        },
        [getCampaignTypeWarrantyDatalist.rejected]: (state, action) => {
            state.campaignTypeWarrantyDatalist = {
                isSuccess: false,
                noResult: true,
                rows: [],
                total: 0,
            };
        },
        // CAMPAIGN TYPE WARRANTY DATA -- END

        // RESPONDENTS CONSUMER ALL WARRANTY DATALIST -- START
        [getRespondentsConsumerAllWarrantyDatalist.fulfilled]: (state, action) => {
            let data = action.payload.data;
            data.isSuccess = action.payload.success;
            data.noResult = (data.rows.length === 0);

            state.respondentsConsumerAllWarrantyDatalist = data;
        },
        [getRespondentsConsumerAllWarrantyDatalist.rejected]: (state, action) => {
            state.respondentsConsumerAllWarrantyDatalist = {
                isSuccess: false,
                noResult: true,
                rows: [],
                total: 0,
            };
        },
        // RESPONDENTS CONSUMER ALL WARRANTY DATALIST -- END

        // CAMPAIGN SUMMARY DATA -- START
        [getCampaignSummaryData.fulfilled]: (state, action) => {
            let data = action.payload.data;
            
            data.isSuccess = action.payload.success;
            data.noResult = false;

            state.campaignSummaryData = data;
        },
        [getCampaignSummaryData.rejected]: (state, action) => {
            state.campaignSummaryData = {
                isSuccess: false,
                noResult: true,
                rows: [],
                total: 0,
            };
        },
        // CAMPAIGN SUMMARY DATA -- END

        // CAMPAIGN TYPE DATA -- START
        [getCampaignTypeData.fulfilled]: (state, action) => {
            let data = action.payload.data;
            data.isSuccess = action.payload.success;
            data.noResult = false;

            state.campaignTypeData = data;
        },
        [getCampaignTypeData.rejected]: (state, action) => {
            state.campaignTypeData = {
                isSuccess: false,
                noResult: true,
                rows: [],
                total: 0,
            };
        },
        // CAMPAIGN TYPE DATA -- END

        // CAMPAIGN PRODUCT DATA -- START
        [getCampaignProductData.fulfilled]: (state, action) => {
            let data = action.payload.data;
            data.isSuccess = action.payload.success;
            data.noResult = false;

            state.campaignProductData = data;
        },
        [getCampaignProductData.rejected]: (state, action) => {
            state.campaignProductData = {
                isSuccess: false,
                noResult: true,
                rows: [],
                total: 0,
            };
        },
        // CAMPAIGN PRODUCT DATA -- END

        // RESPONDENTS SINGLE CAMPAIGN DATALIST -- START
        [getRespondentsSingleCampaignDatalist.fulfilled]: (state, action) => {
            let data = action.payload.data;
            data.isSuccess = action.payload.success;
            data.noResult = false;

            state.respondentsSingleCampaignDatalist = data;
        },
        [getRespondentsSingleCampaignDatalist.rejected]: (state, action) => {
            state.respondentsSingleCampaignDatalist = {
                isSuccess: false,
                noResult: true,
                rows: [],
                total: 0,
            };
        },
        // RESPONDENTS SINGLE CAMPAIGN DATALIST -- END

        // CAMPAIGN PRIZE DATALIST -- START
        [getCampaignPrizeDatalist.fulfilled]: (state, action) => {
            let data = action.payload.data;
            data.isSuccess = action.payload.success;
            data.noResult = false;

            state.campaignPrizeDatalist = data;
        },
        [getCampaignPrizeDatalist.rejected]: (state, action) => {
            state.campaignPrizeDatalist = {
                isSuccess: false,
                noResult: true,
                rows: [],
                total: 0,
            };
        },
        // CAMPAIGN PRIZE DATALIST -- END

        // CAMPAIGN PRIZE SUMMARY DATA -- START
        [getCampaignPrizeSummaryData.fulfilled]: (state, action) => {
            let data = action.payload.data;
            data.isSuccess = action.payload.success;
            data.noResult = false;

            state.campaignPrizeSummaryData = data;
        },
        [getCampaignPrizeSummaryData.rejected]: (state, action) => {
            state.campaignPrizeSummaryData = {
                isSuccess: false,
                noResult: true,
                rows: [],
                total: 0,
            };
        },
        // CAMPAIGN PRIZE SUMMARY DATA -- END

        // WINNER PARTICIPANT DATALIST -- START
        [getWinnerParticipantDatalist.fulfilled]: (state, action) => {
            let data = action.payload.data;
            data.isSuccess = action.payload.success;
            data.noResult = false;

            state.winnerParticipantDatalist = data;
        },
        [getWinnerParticipantDatalist.rejected]: (state, action) => {
            state.winnerParticipantDatalist = {
                isSuccess: false,
                noResult: true,
                rows: [],
                total: 0,
            };
        },
        // WINNER PARTICIPANT DATALIST -- END
    }
})

export const {
    initReportingCampaignConversion,
    initReportingCampaignPerformance,
    initReportingCampaignSurvey,
    initReportingCampaignLuckyDraw,
    initReportingCampaignInstantLuckyDraw,
    initReportingCampaignLoyaltyProgram,
    initReportingCampaignWarranty,
    initReportingCampaignTypeSurvey,
    initRespondentsConsumerAllSurvey,
    initReportingCampaignTypeLuckyDraw,
    initRespondentsConsumerAllLuckyDraw,
    initReportingCampaignTypeInstantLuckyDraw,
    initRespondentsConsumerAllInstantLuckyDraw,
    initReportingCampaignTypeLoyaltyProgram,
    initRespondentsConsumerAllLoyaltyProgram,
    initReportingCampaignTypeWarranty,
    initRespondentsConsumerAllWarranty,
    initReportingCampaignSummary,
    initReportingCampaignType,
    initReportingCampaignProduct,
    initRespondentsSingleCampaign,
    initCampaignPrize,
    initWinnerParticipant
} = reportingCampaignSlice.actions;

export const reportingCampaignReducer = reportingCampaignSlice.reducer;