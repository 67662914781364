import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addAlert, selectLoading } from "modules/notification"
import { validateResetPasswordToken, resetPassword } from "modules/trackco/redux";
import NewPasswordPage from "./new-password.page";
import { getLang } from "app/feature/constants";

function NewPasswordContainer() {
  const [isPasswordShow, setIsPasswordShow] = useState(false);
  const [isConfirmPasswordShow, setIsConfirmPasswordShow] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const search = useLocation().search;
  const email = new URLSearchParams(search).get('email');
  const resetPasswordToken = new URLSearchParams(search).get("reset_password_token");
  const lang = useSelector(state => state.constant.languages);

  const userEmail = useSelector(state => state.trackco.profile.email);
  const picture = useSelector(state => state.trackco.profile.picture);
  const isValidateResetPasswordToken = useSelector(state => selectLoading(state, validateResetPasswordToken.typePrefix))
  const [isValidate, setIsValidate] = useState(false);
  
  useEffect(() => {
    if(email !== "" && resetPasswordToken !== "") {
      dispatch(validateResetPasswordToken({ email: email, resetPasswordToken: resetPasswordToken }))
        .then((response) => {
          if(!response.payload.data.is_valid) {
            dispatch(addAlert({ severity: "error", message: getLang(lang, 'message.error.INVALID_RESET_PASSWORD_TOKEN') }))
            history.push("/404")
          }
          else  {
            setIsValidate(true)
          }
        })
        .catch((error) => {
          dispatch(addAlert({ severity: "error", message: getLang(lang, 'message.error.FAIL_VALIDATE_TOKEN') }))
          history.push("/404")
        })
    }
  },[email, resetPasswordToken, dispatch, history, lang ])

  const handleClickShowPassword = (field) => {
    if(field === "password") {
      setIsPasswordShow(prev => !prev)
    }

    if(field === "confirmPassword") {
      setIsConfirmPasswordShow(prev => !prev)
    }
  }

  const handleMouseDownPassword = event => {
    event.preventDefault()
  }

  const handleSubmit = (values) => {
    dispatch(resetPassword({ 
      email: userEmail,
      resetPasswordToken: resetPasswordToken,
      password: values.password,
      confirmPassword: values.confirmPassword,
    }))
      .then(() => {
        history.push("/trackco/new-password/success")
      })
      .catch(() => dispatch(addAlert({ severity: "error", message: getLang(lang, 'message.error.FAIL_VALIDATE_TOKEN') })))
  }

  return (
    <NewPasswordPage 
      isPasswordShown={isPasswordShow}
      isConfirmPasswordShow={isConfirmPasswordShow}
      handleClickShowPassword={handleClickShowPassword}
      handleMouseDownPassword={handleMouseDownPassword}
      handleSubmit={handleSubmit}
      email={userEmail}
      picture={picture}
      isLoading={isValidateResetPasswordToken}
      isValidate={isValidate}
      lang={lang}
    />
  )
}

export default NewPasswordContainer