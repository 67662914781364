import { Avatar, Box, Paper, TableCell, TableRow, Typography } from "@material-ui/core";
import { UnknownUserIcon } from "modules/reporting/assets/svg";
import { fontTextStyle, iconStyle, tableStyle } from "modules/reporting/styles";
import { drilldownPageSelection } from "modules/reporting/utils/helper";
import React from "react";
import { Link } from "react-router-dom";
import { ContentCard } from "../../layout";
import { DynamicTable } from "../../table";

export default function WinnerParticipantPanelComponent({
    status,
    data,
    pagination,
    sort,
    currentFilter,
    pageType,
    translate
}){
    const tableClass = tableStyle();
    const fontClass = fontTextStyle();
    const iconClass = iconStyle();

    const columns = [
        {
            title: translate("label.NUM"),
            field: null,
            render : (row) => {
                return (
                    <span>{row.title}</span>
                )
            },
            align: "left"
        },
        {
            title: translate("label.CONSUMER"),
            field: null,
            render : (row) => {
                return (
                    <span>{row.title}</span>
                )
            },
            align: null
        },
        {
            title: translate("label.PRODUCTS"),
            field: null, // total_scan
            render : (row) => {
                return (
                    <>{row.title}</>
                )
            },
            align: "left"
        },
        {
            title:  translate("label.SERIAL_NUMBER"),
            field: null,
            render : (row) => {
                return (
                    <span>{row.title}</span>
                )
            },
            align: "left"
        },
        {
            title:  translate("label.PARTICIPATE_DATE"),
            field: null,
            render : (row) => {
                return (
                    <span>{row.title}</span>
                )
            },
            align: "left"
        },
    ];

    const renderData = (data) => {
        let rows = [];

        if(Object.keys(data).length === 0 || !data.isSuccess){
            return rows;
        }

        let imageProps = JSON.stringify(data);
        imageProps = JSON.parse(imageProps);
        let no_iteration = pagination.page * pagination.rowsPerPage + 1;

        imageProps.rows.forEach((item, index) => {
            let row = (
                <TableRow key={index}>
                    <TableCell className={tableClass.table__cell}>
                        <Typography className={fontClass.text__table_cell}>{no_iteration++}</Typography>
                    </TableCell>
                    <TableCell className={tableClass.table__cell} style={{minWidth:"400px"}}>
                        <Box className={tableClass.cell__product}>
                            {
                                item.image ?
                                <Avatar variant="square" alt={item.name} src={item.image} className={iconClass.svg__icon}/>
                                :
                                <Avatar variant="square" alt={item.name} src={UnknownUserIcon} className={iconClass.svg__icon}/>
                            }
                            {
                                item.name ? (
                                    <Link
                                        target="_blank" rel="noopener noreferrer"
                                        className={fontClass.text__hyperlink}
                                        to={() => {
                                            if(!item.uuid)
                                                return;
        
                                            let filterProperties = {};
        
                                            if(item.uuid)
                                                filterProperties.consumerUuid = item.uuid;
        
                                            let url = drilldownPageSelection(filterProperties, pageType, true);
                                            return url;
                                        }}
                                    >
                                        {item.name !== "" ? item.name : translate("label.UNKNOWN")}
                                    </Link>
                                ) : (
                                    <Typography className={fontClass.text__unknown}>
                                        {translate("label.UNKNOWN")}
                                    </Typography>
                                )
                            }
                        </Box>
                    </TableCell>
                    <TableCell className={tableClass.table__cell} style={{minWidth:"400px"}}>
                        <Box className={tableClass.cell__product}>
                            <Avatar variant="square" alt={item.product.name} src={item.product.image} className={iconClass.svg__icon}/>
                            {
                                item.product.name ? (
                                    <Link
                                        target="_blank" rel="noopener noreferrer"
                                        className={fontClass.text__hyperlink}
                                        to={() => {
                                            if(!item.product.uuid)
                                                return;
        
                                            let filterProperties = {};
        
                                            if(item.product.uuid)
                                                filterProperties.productUuid = item.product.uuid;
        
                                            let url = drilldownPageSelection(filterProperties, pageType, true);
                                            return url;
                                        }}
                                    >
                                        {item.product.name !== "" ? item.product.name : translate("label.UNKNOWN")}
                                    </Link>
                                ) : (
                                    <Typography className={fontClass.text__unknown}>
                                        {translate("label.UNKNOWN")}
                                    </Typography>
                                )
                            }
                        </Box>
                    </TableCell>
                    <TableCell className={tableClass.table__cell} style={{minWidth:"185px"}}>
                        <Link 
                            target="_blank" rel="noopener noreferrer"
                            className={fontClass.text__hyperlink}
                            to={() => {

                                let filterProperties = {};

                                if(item.serial_number.uuid)
                                    filterProperties.enc = item.serial_number.uuid;

                                let url = drilldownPageSelection(filterProperties, pageType, true);
                                return url;
                            }}
                        >
                            {item.serial_number.name}
                        </Link>
                    </TableCell>
                    <TableCell className={tableClass.table__cell}  style={{minWidth:"160px"}}>
                        {
                            item.participate_date ? 
                            <Typography className={fontClass.text__table_cell}>
                                {item.participate_date ?? "-"}
                            </Typography>
                            :
                            <Typography className={fontClass.text__unknown}>
                                -
                            </Typography>
                        }
                    </TableCell>
                </TableRow>
            )

            item.render = row;
        });

        return imageProps;
    }

    return (
        <ContentCard>
            <Box marginBottom="24px" display="flex" justifyContent="space-between" alignContent="center">
                <Typography className={fontClass.text__card_title}>
                    {translate("label.WINNER_PARTICIPANTS")}
                </Typography>
            </Box>
            <DynamicTable 
                component={ Paper }
                sort={sort}
                pagination={pagination}
                columns={columns}
                data={renderData(data.winnerParticipantDatalist)}
                status={status.isFetchingWinnerParticipantDatalist}
                translate={translate}
            />
        </ContentCard>
    )
}