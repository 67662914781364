import {
  uploadImageToS3,
  uploadMultipleImageToS3,
  uploadFileToS3,
  uploadJSONToS3
} from "./s3.action";
import { addAlert } from "modules/notification";
import { getApiLang } from "../constants";

const uploadToS3Middleware = ({ dispatch, getState }) => next => action => {
  next(action);

  const { type, payload } = action;
  const lang = getState().constant.languages

  switch (type) {
    case uploadImageToS3.rejected.type:
    case uploadMultipleImageToS3.rejected.type:
      dispatch(
        addAlert({ severity: "error", message: getApiLang(lang, payload.code) })
      );
      break;
    case uploadFileToS3.rejected.type:
      dispatch(
        addAlert({ severity: "error", message: getApiLang(lang, payload.code) })
      );
      break;
    case uploadJSONToS3.rejected.type:
      dispatch(
        addAlert({ severity: "error", message: getApiLang(lang, payload.code) })
      );
      break;
    default:
      break;
  }
};

const s3Middleware = [uploadToS3Middleware];

export { s3Middleware };
