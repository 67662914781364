import React, { useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { updateConsumerPageSetting, getConsumerPageSetting, resetSetting, fetchJSONURL } from "../../../redux";
import { selectLoading } from "modules/notification";
import ConsumerPageSettingPanelComponent from "./consumerPageSettingPanel.component";
import { SkeletonSettingComponent } from "../../skeleton/skeleton.component";
import {COMPANY_PRODUCT_EDIT, COMPANY_PRODUCT_EDIT_PROFILE} from "lib/constants/accessRights";
import { addAlert } from "modules/notification";
import { getUploadS3FilePath } from "lib/helper";
import uploadImage from "../../../utils/uploadImage.util";
import { FolderType, UploadType } from "lib/constants/aws_s3";
import { getLang } from "app/feature/constants";
import languageType from "lib/constants/languages";
import { uploadJSONToS3 } from "app/feature";
import { unwrapResult } from "@reduxjs/toolkit";
import {setImgFormatAndSize} from "../../../../../lib/FileHelper";

function ConsumerPageSettingPanelContainer({
  settings,
  updateConsumerPageSetting,
  getConsumerPageSetting,
  isFetching,
  isChanging,
  resetSetting
}) {
  const dispatch = useDispatch()
  const lang = useSelector(state => state.constant.languages)
  const hasCompanyProductEditAccessRight = useSelector(state => state.profile.accessRights.includes(COMPANY_PRODUCT_EDIT))
  const hasCompanyEditProductProfileAccessRight = useSelector(state => state.profile.accessRights.includes(COMPANY_PRODUCT_EDIT_PROFILE))
  const isLoading = useSelector((state) =>
    selectLoading(state, fetchJSONURL.typePrefix)
  );
  const uiSetting = useSelector(state => state.setting.uiSetting)
  const companyId = useSelector(state => state.account.id)
  const id = useSelector((state) => state.session.idTokenPayload.sub);
  const tncUploadToS3 = async (values) => {
    let url = "";
    if (values.privacyPolicyMessage) {
      const fileName = "JsonFile";

      const uploadJSONdata = {
        uploadType: UploadType.privacyPolicyTermAndCondition,
        data: values.privacyPolicyMessage,
        id: id,
        fileName: `${Date.now()}${fileName}`
      }
      await dispatch(uploadJSONToS3(uploadJSONdata))
        .then(unwrapResult)
        .then((jsonUrl) => {
          url = jsonUrl
        })
        .catch(error => {
          dispatch(
            addAlert({
              severity: "error",
              message: error.message || "Upload Terms & Conditions Failed"
            })
          )
        })
      return url
    }
  }

  const handleSubmit = async values => {
    let file = uiSetting.bgImage;
    let bannerImageFile = uiSetting.bannerImage;
    let backgroundUrl = "";
    let bannerImageUrl = "";
    let tncUrl = ""
    let isPrivacyPolicyMessageModified = "0";
    let privacyPolicyMessage = values.privacyPolicyMessage
    if (values.enablePrivacyPolicy && values.privacyPolicyMessage && (values.privacyPolicyMessage.value === "<p></p>\n")) {
      dispatch(addAlert({
        severity: "error",
        message: getLang(lang, "message.error.EMPTY_TNC")
      }));
      return;
    }

    if (uiSetting.bgType === "Image" && uiSetting.bgImage === "") {
      dispatch(addAlert({
        severity: "error",
        message: getLang(lang, "message.error.ERROR_UPLOAD_IMAGES")
      }))
    }

    if (values.privacyPolicyMessage?.value !== settings.privacyPolicyMessage?.value)
      isPrivacyPolicyMessageModified = "1";

    tncUrl = await tncUploadToS3(values)
    if (tncUrl?.length > 0) {
      values.privacyPolicyMessage = tncUrl.split("?")[0];
    }

    if (!file.includes("https") && uiSetting.bgType === "Image") {
      const fileName = `consumerPageBg${companyId}${Date.now()}.webp`;
      const filePath = getUploadS3FilePath({
        type: FolderType.backgroundImageSetting,
        id: companyId,
        fileName: fileName
      });

      file = await setImgFormatAndSize(file, 1080, 1920, 'image/webp')

      const responseS3 = await uploadImage(filePath, file);

      if (responseS3.success) {
        backgroundUrl = `${responseS3.url.split("?")[0]}`;
      } else {
        dispatch(addAlert({
          severity: "error",
          message: getLang(lang, "message.error.ERROR_UPLOAD_IMAGES")
        }))
        return;
      }
    }

    if (bannerImageFile !== "" && !bannerImageFile.includes("https")) {
      const fileName = `consumerPageBanner${companyId}${Date.now()}.webp`;
      const filePath = getUploadS3FilePath({
        type: FolderType.consumerBanner,
        id: companyId,
        fileName: fileName
      });

      bannerImageFile = await setImgFormatAndSize(bannerImageFile, 3132, 1080, 'image/webp');

      const responseS3 = await uploadImage(filePath, bannerImageFile);

      if (responseS3.success) {
        bannerImageUrl = `${responseS3.url.split("?")[0]}`;
      } else {
        dispatch(addAlert({
          severity: "error",
          message: getLang(lang, "message.error.ERROR_UPLOAD_IMAGES")
        }))
        return;
      }
    }

    const payload = {
      ...values,
      defaultLanguage: languageType[values.defaultLanguage].code,
      uiSetting: {
        bgType: uiSetting.bgType,
        bgImage: file.includes("https") ? uiSetting.bgImage : backgroundUrl,
        bannerImage: bannerImageFile === ""
          ? ""
          : bannerImageFile.includes("https")
            ? uiSetting.bannerImage
            : bannerImageUrl,
        bgColor: uiSetting.bgColor,
        colorPrimary: uiSetting.colorPrimary,
        colorSecondary: uiSetting.colorSecondary,
      },
      isPrivacyPolicyMessageModified: isPrivacyPolicyMessageModified
    }

    updateConsumerPageSetting(payload);
    values.privacyPolicyMessage = privacyPolicyMessage
  };

  useEffect(() => {
    getConsumerPageSetting();
    return () => {
      resetSetting();
    }

  }, [getConsumerPageSetting, resetSetting]);

  if (isFetching) return <SkeletonSettingComponent />;

  return (
    <ConsumerPageSettingPanelComponent
      hasCompanyEditAccessRight={hasCompanyProductEditAccessRight}
      hasCompanyEditProductProfileAccessRight={hasCompanyEditProductProfileAccessRight}
      handleSubmit={handleSubmit}
      settings={settings}
      isChanging={isChanging}
      lang={lang}
      isLoading={isLoading}
    />
  );
}

const mapStateToProps = state => ({
  settings: state.setting,
  isChanging: selectLoading(state, updateConsumerPageSetting.typePrefix),
  isFetching: selectLoading(state, getConsumerPageSetting.typePrefix)
});

const mapDispatchToProps = (dispatch) => ({
  updateConsumerPageSetting: (values) =>
    dispatch(updateConsumerPageSetting(values)),
  getConsumerPageSetting: () => dispatch(getConsumerPageSetting()),
  resetSetting: () => dispatch(resetSetting()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConsumerPageSettingPanelContainer);
