import React from "react";
import { makeStyles, Typography, ButtonBase, Box } from "@material-ui/core";
import { ArrowDropDown as ArrowDropDownIcon } from "@material-ui/icons";
import clsx from "clsx";
import { getLang } from "app/feature/constants";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";

const useStyle = makeStyles((theme) => ({
  root: {
    padding: "5px 12px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "4px",
    width: "100%",
    backgroundColor: "white",
    border: "2px solid #D0D5DD",
  },
  inputText: {
    fontSize: "1rem",
    color: "#A4A6A8",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  filled: {
    color: "rgba(0, 0, 0, 0.87)",
  },
}));

export default function ConsumerSelectComponent({
  handleClick,
  anchorRef,
  disabled,
  value,
  textStyles,
  styles,
  placeholder,
  open,
  lang,
  selectAll,
}) {
  const classes = useStyle();

  const isEmpty = selectAll ? false : value.length === 0;

  return (
    <ButtonBase
      className={classes.root}
      disableRipple
      ref={anchorRef}
      style={styles}
      onClick={handleClick}
      disabled={disabled}
    >
      <Box display="flex" style={{ maxWidth: "85%" }}>
        <Typography
          variant="body2"
          style={textStyles}
          className={clsx(classes.inputText, !isEmpty && classes.filled)}
        >
          {isEmpty
            ? placeholder
            : selectAll
            ? `${getLang(lang, "label.CONSUMER")}: ${getLang(
                lang,
                "label.ALL"
              )}`
            : `${getLang(lang, "label.CONSUMER")}: ${value.length}`}
        </Typography>
      </Box>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {!disabled &&
          (open ? (
            <ArrowDropUpIcon fontSize="small" />
          ) : (
            <ArrowDropDownIcon fontSize="small" />
          ))}
      </Box>
    </ButtonBase>
  );
}
