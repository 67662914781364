import { addAlert, setLoading } from "modules/notification";
import {
  fetchScanMap,
  fetchScanDetail,
  fetchClusterScanMap,
  fetchClusterScanProductListing,
  fetchClusterScanConsumerDropdown,
  fetchClusterScanRecordListing,
  fetchClusterConsumerScannedSummary,
  fetchClusterUniqueConsumerScannedSummary,
  fetchClusterFirstScanSummary,
  fetchClusterSuspendSnSummary,
  fetchClusterCounterfeitReportSummary,
  getConsumerScannedSummary,
  getSuspendSnSummary,
  getCounterfeitReportSummary,
  getFirstScanSummary,
  getUniqueConsumerScannedSummary,
  getConsumerRegisteredSummary,
  getScanAgainstSuspendedData,
  getTopScanCities,
  getTopScanProduct,
  getSerialNumberFirstScanList,
  getNewConsumerRegisteredList,
  getUniqueConsumerScannedList,
} from "../action/dashboard.action";
import { getApiLang } from "app/feature/constants";

export const fetchScanMapMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case fetchScanMap.pending.type:
        dispatch(setLoading({ id: fetchScanMap.typePrefix, state: true }));
        break;
      case fetchScanMap.fulfilled.type:
        dispatch(setLoading({ id: fetchScanMap.typePrefix, state: false }));
        break;
      case fetchScanMap.rejected.type:
        const lang = getState().constant.languages;
        dispatch(setLoading({ id: fetchScanMap.typePrefix, state: false }));
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

export const fetchClusterScanMapMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case fetchClusterScanMap.pending.type:
        dispatch(
          setLoading({ id: fetchClusterScanMap.typePrefix, state: true })
        );
        break;
      case fetchClusterScanMap.fulfilled.type:
        dispatch(
          setLoading({ id: fetchClusterScanMap.typePrefix, state: false })
        );
        break;
      case fetchClusterScanMap.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({ id: fetchClusterScanMap.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

export const fetchClusterScanProductListingMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case fetchClusterScanProductListing.pending.type:
        dispatch(
          setLoading({
            id: fetchClusterScanProductListing.typePrefix,
            state: true,
          })
        );
        break;
      case fetchClusterScanProductListing.fulfilled.type:
        dispatch(
          setLoading({
            id: fetchClusterScanProductListing.typePrefix,
            state: false,
          })
        );
        break;
      case fetchClusterScanProductListing.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: fetchClusterScanProductListing.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

export const fetchClusterScanRecordListingMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case fetchClusterScanRecordListing.pending.type:
        dispatch(
          setLoading({
            id: fetchClusterScanRecordListing.typePrefix,
            state: true,
          })
        );
        break;
      case fetchClusterScanRecordListing.fulfilled.type:
        dispatch(
          setLoading({
            id: fetchClusterScanRecordListing.typePrefix,
            state: false,
          })
        );
        break;
      case fetchClusterScanRecordListing.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: fetchClusterScanRecordListing.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

export const fetchClusterScanConsumerDropdownMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case fetchClusterScanConsumerDropdown.pending.type:
        dispatch(
          setLoading({
            id: fetchClusterScanConsumerDropdown.typePrefix,
            state: true,
          })
        );
        break;
      case fetchClusterScanConsumerDropdown.fulfilled.type:
        dispatch(
          setLoading({
            id: fetchClusterScanConsumerDropdown.typePrefix,
            state: false,
          })
        );
        break;
      case fetchClusterScanConsumerDropdown.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: fetchClusterScanConsumerDropdown.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

export const fetchClusterConsumerScannedSummaryMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case fetchClusterConsumerScannedSummary.pending.type:
        dispatch(
          setLoading({
            id: fetchClusterConsumerScannedSummary.typePrefix,
            state: true,
          })
        );
        break;
      case fetchClusterConsumerScannedSummary.fulfilled.type:
        dispatch(
          setLoading({
            id: fetchClusterConsumerScannedSummary.typePrefix,
            state: false,
          })
        );
        break;
      case fetchClusterConsumerScannedSummary.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: fetchClusterConsumerScannedSummary.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

export const fetchClusterUniqueConsumerScannedSummaryMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case fetchClusterUniqueConsumerScannedSummary.pending.type:
        dispatch(
          setLoading({
            id: fetchClusterUniqueConsumerScannedSummary.typePrefix,
            state: true,
          })
        );
        break;
      case fetchClusterUniqueConsumerScannedSummary.fulfilled.type:
        dispatch(
          setLoading({
            id: fetchClusterUniqueConsumerScannedSummary.typePrefix,
            state: false,
          })
        );
        break;
      case fetchClusterUniqueConsumerScannedSummary.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: fetchClusterUniqueConsumerScannedSummary.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

export const fetchClusterFirstScanSummaryMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case fetchClusterFirstScanSummary.pending.type:
        dispatch(
          setLoading({
            id: fetchClusterFirstScanSummary.typePrefix,
            state: true,
          })
        );
        break;
      case fetchClusterFirstScanSummary.fulfilled.type:
        dispatch(
          setLoading({
            id: fetchClusterFirstScanSummary.typePrefix,
            state: false,
          })
        );
        break;
      case fetchClusterFirstScanSummary.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: fetchClusterFirstScanSummary.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

export const fetchClusterSuspendSnSummaryMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case fetchClusterSuspendSnSummary.pending.type:
        dispatch(
          setLoading({
            id: fetchClusterSuspendSnSummary.typePrefix,
            state: true,
          })
        );
        break;
      case fetchClusterSuspendSnSummary.fulfilled.type:
        dispatch(
          setLoading({
            id: fetchClusterSuspendSnSummary.typePrefix,
            state: false,
          })
        );
        break;
      case fetchClusterSuspendSnSummary.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: fetchClusterSuspendSnSummary.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

export const fetchClusterCounterfeitReportSummaryMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case fetchClusterCounterfeitReportSummary.pending.type:
        dispatch(
          setLoading({
            id: fetchClusterCounterfeitReportSummary.typePrefix,
            state: true,
          })
        );
        break;
      case fetchClusterCounterfeitReportSummary.fulfilled.type:
        dispatch(
          setLoading({
            id: fetchClusterCounterfeitReportSummary.typePrefix,
            state: false,
          })
        );
        break;
      case fetchClusterCounterfeitReportSummary.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: fetchClusterCounterfeitReportSummary.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

export const fetchScanDetailMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case fetchScanDetail.pending.type:
        dispatch(setLoading({ id: fetchScanDetail.typePrefix, state: true }));
        break;
      case fetchScanDetail.fulfilled.type:
        dispatch(setLoading({ id: fetchScanDetail.typePrefix, state: false }));
        break;
      case fetchScanDetail.rejected.type:
        const lang = getState().constant.languages;
        dispatch(setLoading({ id: fetchScanDetail.typePrefix, state: false }));
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

export const getConsumerScannedSummaryMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getConsumerScannedSummary.pending.type:
        dispatch(
          setLoading({ id: getConsumerScannedSummary.typePrefix, state: true })
        );
        break;
      case getConsumerScannedSummary.fulfilled.type:
        dispatch(
          setLoading({ id: getConsumerScannedSummary.typePrefix, state: false })
        );
        break;
      case getConsumerScannedSummary.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({ id: getConsumerScannedSummary.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

export const getSuspendSnSummaryMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getSuspendSnSummary.pending.type:
        dispatch(
          setLoading({ id: getSuspendSnSummary.typePrefix, state: true })
        );
        break;
      case getSuspendSnSummary.fulfilled.type:
        dispatch(
          setLoading({ id: getSuspendSnSummary.typePrefix, state: false })
        );
        break;
      case getSuspendSnSummary.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({ id: getSuspendSnSummary.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

export const getCounterfeitReportSummaryMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getCounterfeitReportSummary.pending.type:
        dispatch(
          setLoading({
            id: getCounterfeitReportSummary.typePrefix,
            state: true,
          })
        );
        break;
      case getCounterfeitReportSummary.fulfilled.type:
        dispatch(
          setLoading({
            id: getCounterfeitReportSummary.typePrefix,
            state: false,
          })
        );
        break;
      case getCounterfeitReportSummary.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: getCounterfeitReportSummary.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

export const getFirstScanSummaryMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getFirstScanSummary.pending.type:
        dispatch(
          setLoading({
            id: getFirstScanSummary.typePrefix,
            state: true,
          })
        );
        break;
      case getFirstScanSummary.fulfilled.type:
        dispatch(
          setLoading({
            id: getFirstScanSummary.typePrefix,
            state: false,
          })
        );
        break;
      case getFirstScanSummary.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: getFirstScanSummary.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

export const getUniqueConsumerScannedSummaryMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getUniqueConsumerScannedSummary.pending.type:
        dispatch(
          setLoading({
            id: getUniqueConsumerScannedSummary.typePrefix,
            state: true,
          })
        );
        break;
      case getUniqueConsumerScannedSummary.fulfilled.type:
        dispatch(
          setLoading({
            id: getUniqueConsumerScannedSummary.typePrefix,
            state: false,
          })
        );
        break;
      case getUniqueConsumerScannedSummary.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: getUniqueConsumerScannedSummary.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

export const getConsumerRegisteredSummaryMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getConsumerRegisteredSummary.pending.type:
        dispatch(
          setLoading({
            id: getConsumerRegisteredSummary.typePrefix,
            state: true,
          })
        );
        break;
      case getConsumerRegisteredSummary.fulfilled.type:
        dispatch(
          setLoading({
            id: getConsumerRegisteredSummary.typePrefix,
            state: false,
          })
        );
        break;
      case getConsumerRegisteredSummary.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: getConsumerRegisteredSummary.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

export const getScanAgainstSuspendedDataMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getScanAgainstSuspendedData.pending.type:
        dispatch(
          setLoading({
            id: getScanAgainstSuspendedData.typePrefix,
            state: true,
          })
        );
        break;
      case getScanAgainstSuspendedData.fulfilled.type:
        dispatch(
          setLoading({
            id: getScanAgainstSuspendedData.typePrefix,
            state: false,
          })
        );
        break;
      case getScanAgainstSuspendedData.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: getScanAgainstSuspendedData.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

export const getTopScanCitiesMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getTopScanCities.pending.type:
        dispatch(
          setLoading({
            id: getTopScanCities.typePrefix,
            state: true,
          })
        );
        break;
      case getTopScanCities.fulfilled.type:
        dispatch(
          setLoading({
            id: getTopScanCities.typePrefix,
            state: false,
          })
        );
        break;
      case getTopScanCities.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: getTopScanCities.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

export const getTopScanProductMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getTopScanProduct.pending.type:
        dispatch(
          setLoading({
            id: getTopScanProduct.typePrefix,
            state: true,
          })
        );
        break;
      case getTopScanProduct.fulfilled.type:
        dispatch(
          setLoading({
            id: getTopScanProduct.typePrefix,
            state: false,
          })
        );
        break;
      case getTopScanProduct.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: getTopScanProduct.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

export const getSerialNumberFirstScanListMiddleware =
  ({ dispatch, getState }) =>
    (next) =>
      (action) => {
        next(action);

        const { type, payload } = action;

        switch (type) {
          case getSerialNumberFirstScanList.pending.type:
            dispatch(
              setLoading({
                id: getSerialNumberFirstScanList.typePrefix,
                state: true,
              })
            );
            break;
          case getSerialNumberFirstScanList.fulfilled.type:


            dispatch(
              setLoading({
                id: getSerialNumberFirstScanList.typePrefix,
                state: false,
              })
            );
            break;
          case getSerialNumberFirstScanList.rejected.type:
            const lang = getState().constant.languages;
            dispatch(
              setLoading({
                id: getSerialNumberFirstScanList.typePrefix,
                state: false,
              })
            );
            dispatch(
              addAlert({
                severity: "error",
                message: getApiLang(lang, payload.code),
              })
            );
            break;
          default:
            break;
        }
      };

export const getUniqueConsumerScannedListMiddleware =
  ({ dispatch, getState }) =>
    (next) =>
      (action) => {
        next(action);

        const { type, payload } = action;

        switch (type) {
          case getUniqueConsumerScannedList.pending.type:
            dispatch(
              setLoading({
                id: getUniqueConsumerScannedList.typePrefix,
                state: true,
              })
            );
            break;
          case getUniqueConsumerScannedList.fulfilled.type:


            dispatch(
              setLoading({
                id: getUniqueConsumerScannedList.typePrefix,
                state: false,
              })
            );
            break;
          case getUniqueConsumerScannedList.rejected.type:
            const lang = getState().constant.languages;
            dispatch(
              setLoading({
                id: getUniqueConsumerScannedList.typePrefix,
                state: false,
              })
            );
            dispatch(
              addAlert({
                severity: "error",
                message: getApiLang(lang, payload.code),
              })
            );
            break;
          default:
            break;
        }
      };

export const getNewConsumerRegisteredListMiddleware =
  ({ dispatch, getState }) =>
    (next) =>
      (action) => {
        next(action);

        const { type, payload } = action;

        switch (type) {
          case getNewConsumerRegisteredList.pending.type:
            dispatch(
              setLoading({
                id: getNewConsumerRegisteredList.typePrefix,
                state: true,
              })
            );
            break;
          case getNewConsumerRegisteredList.fulfilled.type:


            dispatch(
              setLoading({
                id: getNewConsumerRegisteredList.typePrefix,
                state: false,
              })
            );
            break;
          case getNewConsumerRegisteredList.rejected.type:
            const lang = getState().constant.languages;
            dispatch(
              setLoading({
                id: getNewConsumerRegisteredList.typePrefix,
                state: false,
              })
            );
            dispatch(
              addAlert({
                severity: "error",
                message: getApiLang(lang, payload.code),
              })
            );
            break;
          default:
            break;
        }
      };


export default [
  fetchScanMapMiddleware,
  fetchClusterScanMapMiddleware,
  fetchClusterScanProductListingMiddleware,
  fetchScanDetailMiddleware,
  fetchClusterScanRecordListingMiddleware,
  fetchClusterScanConsumerDropdownMiddleware,
  fetchClusterConsumerScannedSummaryMiddleware,
  fetchClusterUniqueConsumerScannedSummaryMiddleware,
  fetchClusterFirstScanSummaryMiddleware,
  fetchClusterSuspendSnSummaryMiddleware,
  fetchClusterCounterfeitReportSummaryMiddleware,
  getConsumerScannedSummaryMiddleware,
  getSuspendSnSummaryMiddleware,
  getCounterfeitReportSummaryMiddleware,
  getFirstScanSummaryMiddleware,
  getUniqueConsumerScannedSummaryMiddleware,
  getConsumerRegisteredSummaryMiddleware,
  getScanAgainstSuspendedDataMiddleware,
  getTopScanCitiesMiddleware,
  getTopScanProductMiddleware,
  getSerialNumberFirstScanListMiddleware,
  getUniqueConsumerScannedListMiddleware,
  getNewConsumerRegisteredListMiddleware,

];
