import React from "react";
import {
  makeStyles,
  Typography,
  Popover,
  Box,
  Divider,
  MenuItem,
  Checkbox,
  MenuList,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles((theme) => ({
  button: {
    padding: 0,
    minWidth: 0,
  },
  perfectScrollBar: {
    height: 200,
  },
  list: {
    paddingTop: 6,
    paddingBottom: 6,
    display: "flex",
    alignItems: "center",
  },
  name: {
    whiteSpace: "normal",
    overflow: "hidden",
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "line-clamp": 2,
    "-webkit-box-orient": "vertical",
  },
  attributeText: {
    color: "#b2b2b2",
    fontSize: 12,
    whiteSpace: "break-spaces",
  },
}));

export default function LanguageSelectPopoverComponent({
  open,
  anchorEl,
  handleClose,
  id,
  style,
  items,
  isFetching,
  emptyMessage,
  handleChange,
  anchorOrigin,
  transformOrigin,
  PaperProps,
  value,
  lang,
  selectAll,
  handleAll,
}) {
  const classes = useStyle();

  return (
    <Popover
      id={id}
      open={open}
      style={style}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      elevation={2}
      PaperProps={{
        style: { minWidth: "20em", maxWidth: 400 },
        ...PaperProps,
      }}
    >
      <MenuList
        id="menu-list-grow"
        onClick={() => {
          handleAll();
        }}
        style={{ padding: 0 }}
      >
        <MenuItem style={{ padding: "0px 16px" }}>
          <Checkbox checked={selectAll} />
          <Typography variant="inherit" noWrap>
            {getLang(lang, "label.ALL")}
          </Typography>
        </MenuItem>
      </MenuList>
      <Divider />
      <Box style={{ maxHeight: "250px", overflow: "auto" }}>
        {isFetching ? (
          <>
            <Skeleton variant="text" animation="wave" height={25} />
            <Skeleton variant="text" animation="wave" height={25} />
            <Skeleton variant="text" animation="wave" height={25} />
            <Skeleton variant="text" animation="wave" height={25} />
          </>
        ) : items.length > 0 ? (
          <Box>
            {items.map((item, index) => {
              return (
                <Box key={index}>
                  <MenuItem
                    style={{ paddingTop: "0px", paddingBottom: "0px" }}
                    onClick={() => {
                      handleChange(item);
                    }}
                  >
                    <Checkbox
                      checked={
                        value.find((v) => v.code === item.code) ? true : false
                      }
                    />
                    <Box className={classes.list}>
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="flex-start"
                      >
                        <Typography className={classes.name} variant="body2">
                          {item.label}
                        </Typography>
                      </Box>
                    </Box>
                  </MenuItem>
                </Box>
              );
            })}
          </Box>
        ) : (
          <Box py={9} style={{ textAlign: "center" }}>
            <Typography variant="body1">{emptyMessage}</Typography>
          </Box>
        )}
      </Box>
    </Popover>
  );
}
