import React from "react"
import { makeStyles, Box, Button } from "@material-ui/core"
import { Formik } from "formik"
import { getLang } from "app/feature/constants"
import clsx from "clsx"
import DatePicker from "components/input/datePicker"
import PointHistoryListTable from "../../table/pointHistoryListTable"

const useStyles = makeStyles((theme) => ({
  container: {
    background: "white",
    borderRadius: 4,
    padding: "24px 20px"
  },
  searchContainer: {
    backgroundColor: theme.palette.background.paper,
    display: "grid",
    gridTemplateColumns: "4fr 5fr 1fr 2fr",
    columnGap: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1)
    }
  },
  inputRootID: {
    paddingTop: "8px !important",
    paddingBottom: "7px !important",
    paddingLeft: "12px !important",
    paddingRight: "12px !important",
  },
  emptyField: {
    "& .MuiInputBase-root": {
      color: "#A4A6A8"
    }
  }
}))

export default function DealerPointHistoryListPanelComponent({
  hasStockflowRewardCenterViewAccessRight,
  id,
  pointHistory,
  language,
  isFetching,
  isError,
  handleChangePage,
  handleChangeRowsPerPage,
  totalFiltered,
  page,
  rowsPerPage,
  handleSearch,
  clearSearch,
  handleReload,
  paginationOptions,
  tableFilter,
  changeOrder,
  lang,
  handleViewDetail
}) {
  const classes = useStyles()

  return (
    <>
      <Box className={classes.container}>
        <Formik
          initialValues={{
            date: tableFilter.date || null,
          }}
          enableReinitialize
          onSubmit={(values, actions) => {
            handleSearch(values)
            actions.setSubmitting(false)
          }}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <Box mb={2} className={classes.searchContainer}>
                <DatePicker
                  label=""
                  placeholder={getLang(lang, "placeholder.DATE")}
                  disabled={isFetching}
                  disableFuture={false}
                  className={clsx({
                    [classes.emptyField]: !formik.getFieldProps("date").value
                  })}
                  id={"created-picker-dialog"}
                  value={formik.getFieldProps("date").value}
                  handleChange={(v) => {
                    formik.setFieldValue("date", v)
                  }}
                  InputPropStyle={{
                    color: !formik.getFieldProps("date").value ? "#A4A6A8" : "#000000",
                    fontWeight: "normal"
                  }}
                  inputProps={{
                    fontWeight: "400",
                    fontSize: "16px",
                  }}
                />
                <Box />
                <Button
                  variant='text'
                  disableElevation
                  color="secondary"
                  size='small'
                  onClick={() => {
                    clearSearch()
                  }}
                >
                  {getLang(lang, "label.CLEAR")}
                </Button>
                <Button
                  disabled={isFetching}
                  variant="contained"
                  disableElevation
                  color='primary'
                  size='small'
                  type='submit'
                >
                  {getLang(lang, "label.SEARCH")}
                </Button>
              </Box>
            </form>
          )}
        </Formik>
        <Box mt={3}>
          <PointHistoryListTable
            hasStockflowRewardCenterViewAccessRight={hasStockflowRewardCenterViewAccessRight}
            rows={pointHistory}
            isFetching={isFetching}
            isError={isError}
            handleReload={handleReload}
            page={page}
            rowsPerPage={rowsPerPage}
            language={language}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            totalFiltered={totalFiltered}
            paginationOptions={paginationOptions}
            tableFilter={tableFilter}
            changeOrder={changeOrder}
            lang={lang}
            handleViewDetail={handleViewDetail}
          />
        </Box>
      </Box>
    </>
  )
}
