/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CreditReloadLogPanelComponent from "./creditReloadLogPanel.component";
import { selectLoading } from "modules/notification";
import {
  getCreditBalanceReloadLog,
  resetCreditReloadLog,
} from "modules/account/redux";
import { generatePaginationOptions } from "lib/helper";

export default function CreditReloadLogPanelContainer({ disbursementInfo }) {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.constant.languages);
  const isFetching = useSelector((state) =>
    selectLoading(state, getCreditBalanceReloadLog.typePrefix)
  );
  const creditReloadLog = useSelector(
    (state) => state.disbursement.creditReloadLog.list
  );
  const isError = useSelector(
    (state) => state.disbursement.creditReloadLog.isError
  );
  const totalFiltered = useSelector(
    (state) => state.disbursement.creditReloadLog.totalFiltered
  );

  const [paginationOptions, setPaginationOptions] = useState([]);
  const [tableFilter, setTableFilter] = useState({
    page: 0,
    rowsPerPage: 10,
  });

  useEffect(() => {
    return () => {
      dispatch(resetCreditReloadLog());
    };
  }, []);

  useEffect(() => {
    handleDataLoad();
  }, [tableFilter]);

  useEffect(() => {
    setPaginationOptions(generatePaginationOptions(totalFiltered));
  }, [totalFiltered]);

  const handleDataLoad = () => {
    let payload = {
      length: tableFilter.rowsPerPage,
      start: tableFilter.rowsPerPage * tableFilter.page,
    };
    dispatch(getCreditBalanceReloadLog(payload));
  };

  const handleChangePage = (event, newPage) => {
    let newData = {
      ...tableFilter,
      page: newPage,
    };
    setTableFilter(newData);
  };

  const handleChangeRowsPerPage = (event) => {
    let newData = {
      ...tableFilter,
      page: 0,
      rowsPerPage: event.target.value,
    };
    setTableFilter(newData);
  };

  return (
    <CreditReloadLogPanelComponent
      lang={lang}
      creditReloadLog={creditReloadLog}
      totalFiltered={totalFiltered}
      isFetching={isFetching}
      isError={isError}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      tableFilter={tableFilter}
      handleReload={handleDataLoad}
      paginationOptions={paginationOptions}
      disbursementInfo={disbursementInfo}
    />
  );
}
