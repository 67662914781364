import {Box, Grid, IconButton, Typography} from "@material-ui/core";
import { responseHandler } from "modules/reporting/utils/helper";
import React from "react";
import { TrendingNegativeCard, TrendingPositiveCard } from "../../card";
import { ChartCardLoading, TrendingCardLoading } from "../../skeleton/loading";
import { ProductTable } from "../../table";
import '../../../assets/css/carousel.css';
import { ContentCard } from "../../layout";
import { fontTextStyle } from "modules/reporting/styles";
import { MixedChart } from "../../charts";
import EmptyChart from "../../skeleton/global/empty-chart";
import SuspendedProductTable from "../../table/top10/suspendedProductTable";
import SuspendedQrTable from "../../table/top10/suspendedQrTable";
import {DownloadIcon} from "../../../assets/svg";
// import { PcpChart } from "../../charts";


export default function ProductPanel({
    status,
    data,
    sort,
    pagination,
    columns,
    suspendedProductColumns,
    suspendedQrColumns,
    currentFilter,
    pageType,
    translate,
    onQrScanExportClick,
}) {
    const fontClass = fontTextStyle();

    // const renderData = (data) => {
    //     if (Object.keys(data).length === 0 || !data.isSuccess){
    //         return null;
    //     }

    //     return (
    //         data.trendingSales.map((item, index) => {
    //             return (
    //                 <TrendingDistributionPerformanceCard translate={translate} index={index} title={translate("label.DISTRIBUTION_PERFORMANCE")} data={item} />
    //             )
    //         })
    //     )
    // }

    return (
        <>
            <Box margin="20px">
                <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} sm={12} md={6}>
                        {
                            responseHandler(
                                data.totalScanTrendingData,
                                status.isFetchingTotalScanTrending,
                                <TrendingPositiveCard translate={translate} title={translate("label.TOTAL_VALID_SCAN_QR")} data={data.totalScanTrendingData} />,
                                <TrendingCardLoading />,
                                null,
                                null,
                                null
                            )
                        }
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} >
                        {
                            responseHandler(
                                data.totalSuspendedTrendingData,
                                status.isFetchingTotalSuspendedTrending,
                                <TrendingNegativeCard translate={translate} title={translate("label.SUSPENDED_QR")} data={data.totalSuspendedTrendingData} />,
                                <TrendingCardLoading />,
                                null,
                                null,
                                null
                            )
                        }
                    </Grid>
                    {/* <Grid item xs={12} sm={12} md={4} >
                        <Carousel        
                            autoPlay={true}
                            interval={5000}
                            infiniteLoop={true}
                        >
                            {
                                responseHandler(
                                    data.totalDistributionPerformanceData, 
                                    status.isFetchingTotalDistributionPerformance, 
                                    renderData(data.totalDistributionPerformanceData), 
                                    <TrendingCardLoading/>, 
                                    null, 
                                    null, 
                                    null
                                )
                            }
                        </Carousel>
                    </Grid> */}
                </Grid>
            </Box>

            <ContentCard>
                <Grid container spacing={3} alignItems="stretch">
                    <Grid item xs={12} sm={12} md={6} >
                        <SuspendedProductTable
                            status={status.isFetchingTopSuspendedProductTrending}
                            data={data.topSuspendedProductTrendingData}
                            currentFilter={currentFilter}
                            pageType={pageType}
                            suspendedProductColumns={suspendedProductColumns}
                            translate={translate}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} >
                        <SuspendedQrTable
                            status={status.isFetchingTopSuspendedQrTrending}
                            data={data.topSuspendedQrTrendingData}
                            currentFilter={currentFilter}
                            pageType={pageType}
                            suspendedQrColumns={suspendedQrColumns}
                            translate={translate}
                        />
                    </Grid>
                </Grid>
            </ContentCard>

            <Box
                marginTop="20px"
                marginBottom="20px"
            >
                <ContentCard>
                    <Box marginBottom="24px" display="flex" justifyContent="space-between" alignContent="center">
                        <Typography className={fontClass.text__card_title}>
                            {translate("label.TOTAL_SCAN_COUNT_AGAINST_SUSPENDED_QR")}
                        </Typography>
                    </Box>
                    {/* chart */}
                    {
                        responseHandler(
                            data.scanAgainstSuspendedData,
                            status.isFetchingScanAgainstSuspendedData,
                            (
                                <MixedChart
                                    payload={data.scanAgainstSuspendedData.chart}
                                    chartHeight="340px"
                                    chartColors={[]}
                                    dontAddUnknown={true}
                                    translate={translate}
                                    multipleYAxis={[
                                        {
                                            name: translate("label.TOTAL_SCAN_QR"),
                                            color: "#6184FF"
                                        },
                                        {
                                            name: translate("label.SUSPENDED_QR"),
                                            color: "#F97066",
                                            opposite: true
                                        }
                                    ]}
                                />
                            ),
                            (
                                <ChartCardLoading />
                            ),
                            (
                                <EmptyChart
                                    data={[translate("label.TOTAL_SCAN"), translate("label.SUSPENDED")]}
                                    title={translate("label.CUSTOMER_NOT_FOUND")}
                                    value={translate("paragraph.NO_DATA_ON_SELECTED_FILTER")}
                                    labels={['10 July', '11 July', '12 July', '13 July']}
                                />
                            ),
                            (
                                <EmptyChart
                                    data={[translate("label.TOTAL_SCAN"), translate("label.SUSPENDED")]}
                                    title={translate("label.CUSTOMER_NOT_FOUND")}
                                    value={translate("paragraph.NO_DATA_ON_SELECTED_FILTER")}
                                    labels={['10 July', '11 July', '12 July', '13 July']}
                                />
                            ),
                            <EmptyChart
                                data={[translate("label.TOTAL_SCAN"), translate("label.SUSPENDED")]}
                                title={translate("label.LOADING_FAILED")}
                                value={translate("paragraph.LOADING_FAILED_DESCRIPTION")}
                                labels={['10 July', '11 July', '12 July', '13 July']}
                            />
                        )
                    }
                </ContentCard>
            </Box>

            <Box
                marginTop="20px"
                marginBottom="20px"
            >
                <ContentCard>
                    <Box marginBottom="24px" display="flex" justifyContent="space-between" alignContent="center">
                      <Box height="fit-content">
                        <Typography className={fontClass.text__card_title}>
                          {translate("label.QR_CODE_SCAN")}
                        </Typography>
                      </Box>
                      {!status.isFetchingProductData && data.productData?.rows && data.productData.rows.length > 0 && (
                        <Box>
                          <IconButton title="Export" aria-label="export" onClick={onQrScanExportClick} disableRipple>
                            <DownloadIcon />
                          </IconButton>
                        </Box>
                      )}
                    </Box>
                    {/* {
                        responseHandler(
                            data.productData,
                            status.isFetchingProductData,
                            (
                                <ParallelCoordinatePlotChart
                                    data={data.productData?.chart?.datasets ?? []}
                                    translate={translate} 
                                />
                            ),
                            (
                                <ChartCardLoading />
                            ),
                            (
                                <EmptyChart
                                    data={[translate("label.TOTAL_SCAN"), translate("label.SUSPENDED")]}
                                    title={translate("label.CUSTOMER_NOT_FOUND")}
                                    value={translate("paragraph.NO_DATA_ON_SELECTED_FILTER")}
                                    labels={['10 July', '11 July', '12 July', '13 July']}
                                />
                            ),
                            (
                                <EmptyChart
                                    data={[translate("label.TOTAL_SCAN"), translate("label.SUSPENDED")]}
                                    title={translate("label.CUSTOMER_NOT_FOUND")}
                                    value={translate("paragraph.NO_DATA_ON_SELECTED_FILTER")}
                                    labels={['10 July', '11 July', '12 July', '13 July']}
                                />
                            ),
                            <EmptyChart
                                data={[translate("label.TOTAL_SCAN"), translate("label.SUSPENDED")]}
                                title={translate("label.LOADING_FAILED")}
                                value={translate("paragraph.LOADING_FAILED_DESCRIPTION")}
                                labels={['10 July', '11 July', '12 July', '13 July']}
                            />
                        )
                    } */}
                    <ProductTable
                        status={status.isFetchingProductData}
                        data={data.productData}
                        sort={sort}
                        pagination={pagination}
                        columns={columns}
                        currentFilter={currentFilter}
                        pageType={pageType}
                        translate={translate}
                    />
                </ContentCard>
                {/* <PcpChart /> */}

            </Box>
        </>
    )
}
