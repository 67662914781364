import React from "react";
import { makeStyles, Box, Typography, IconButton } from "@material-ui/core";
import { getLang } from "app/feature/constants";
import { DownloadIcon, LineChartIcon } from "modules/reporting/assets/svg";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import Chart from "react-apexcharts";
import { setChartColors } from "modules/reporting/utils/chartConfig";
import { responseHandler } from "modules/reporting/utils/helper";
import { BoxLoading } from "modules/reporting/components/skeleton/loading";
import DealerInvitedNoData from "../../skeleton/no-data/dealerInvited";
import { cardChartStyle } from "modules/reporting/styles";
import DealerInvitedListTable from "../../table/dealerInvitedListTable";

const useStyles = makeStyles((theme) => ({
  indicator: {
    border: "0.5px solid",
    borderColor: theme.palette.primary.main,
  },
  scroll: {
    width: "min-content",
  },
  container: {
    background: "white",
    borderRadius: 12,
    marginTop: 16,
    border: "1px solid #D0D5DD",
    padding: "20px",
  },
  searchContainer: {
    backgroundColor: theme.palette.background.paper,
    display: "grid",
    gridTemplateColumns: "5fr 4fr 4fr 1fr 2fr",
    columnGap: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1),
    },
  },
  inputRootID: {
    paddingTop: "8px !important",
    paddingBottom: "7px !important",
    paddingLeft: "12px !important",
    paddingRight: "12px !important",
  },
  filterMainContainer: {
    backgroundColor: theme.palette.background.paper,
    paddingTop: "12px",
    paddingBottom: "12px",
    paddingRight: "20px",
    paddingLeft: "20px",
    marginBottom: "12px",
  },
  filterFieldContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
    columnGap: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1),
    },
  },
  paperRoot: {
    width: "217px",
  },
  emptyField: {
    "& .MuiInputBase-root": {
      color: "#A4A6A8",
    },
  },
}));

export default function DealerDashboardPanelComponent({
  hasStockflowDealerViewAccessRight,
  hasBranchViewAccessRight,
  summaryData,
  isSummaryLoading,
  lang,
  handleDownloadDealerInvitedSummaryCard,
  list,
  isError,
  isListFetching,
  page,
  rowsPerPage,
  totalFiltered,
  changeOrder,
  paginationOptions,
  tableFilter,
  handleChangePage,
  handleChangeRowsPerPage,
  handleReload,
  handleViewDetail
}) {
  const classes = useStyles();
  const chartClasses = cardChartStyle();
  const chartID = "dealer-invited-chart";

  const displayData = (ref) => {
    if (ref) {
      if (Object.keys(ref).length === 0 || !ref.isSuccess) {
        return null;
      }
      const colorSet = setChartColors(0, true);
      const options = {
        chart: {
          id: chartID,
        },
        tooltip:{
          followCursor: true,
          y: {
              formatter: function (val) {
                  return parseInt(val)
              }
          }
        },
        xaxis: {
            categories: ref.labels,
            labels: {
                rotateAlways: true,
                rotate: -45,
                hideOverlappingLabels: true,
            },
            tickPlacement: 'between',
        },
        legend: {
            position: 'bottom',
            showForSingleSeries: true,
            onItemClick: {
                toggleDataSeries: false
            }
        },
        grid: {
            padding: {
                left: 12,
                right: 12,
            }
        },
        stroke: {
            width: 2,
        },
        colors: colorSet
      };

      const chartSeries = () => {
        let temp = {...ref.datasets}
        temp.name = getLang(lang, ref.datasets.name);

        return [temp];
      };

      return (
        <Box className={chartClasses.card}>
          <Chart
            options={options}
            series={chartSeries()}
            type="line"
            height="430px"
          ></Chart>
        </Box>
      );
    }
  };

  return (
    <>
      <Box className={classes.container} id="dealer-invited">
        <Box
          mb={2}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="body1">
            <b>{getLang(lang, "label.DEALER_INVITED")}</b>
          </Typography>
          <Box display="flex" alignContent="center">
            <IconButton
              title="Download Charts"
              aria-label="download"
              onClick={handleDownloadDealerInvitedSummaryCard}
              disableRipple
            >
              <DownloadIcon />
            </IconButton>
            <ToggleButtonGroup value={"line"} exclusive>
              <ToggleButton value="stacked-bar">
                <LineChartIcon />
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </Box>
        <Box mb={3}>
          {responseHandler(
            summaryData || {},
            isSummaryLoading,
            displayData(summaryData || {}),
            <BoxLoading />,
            <DealerInvitedNoData
              title={getLang(lang, "label.DEALER_INVITED_NOT_FOUND")}
              value={getLang(lang, "paragraph.NO_DATA_ON_SELECTED_FILTER")}
            />,
            <DealerInvitedNoData
              title={getLang(lang, "label.DEALER_INVITED_NOT_FOUND")}
              value={getLang(lang, "paragraph.NO_DATA_ON_SELECTED_FILTER")}
            />,
            <DealerInvitedNoData
              title={getLang(lang, "label.LOADING_FAILED")}
              value={getLang(lang, "paragraph.LOADING_FAILED_DESCRIPTION")}
            />
          )}
        </Box>
        <DealerInvitedListTable 
          hasStockflowDealerViewAccessRight={hasStockflowDealerViewAccessRight}
          hasBranchViewAccessRight={hasBranchViewAccessRight}
          rows={list}
          isFetching={isListFetching}
          isError={isError}
          handleReload={handleReload}
          page={page}
          rowsPerPage={rowsPerPage}
          handleChangePage={handleChangePage}
          handleChangeRowPerPage={handleChangeRowsPerPage}
          totalFiltered={totalFiltered}
          paginationOptions={paginationOptions}
          tableFilter={tableFilter}
          changeOrder={changeOrder}
          lang={lang}
          handleViewDetail={handleViewDetail}
        />
      </Box>
    </>
  );
}
