import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { selectLoading } from "modules/notification"
import CampaignAssignListPanelComponent from "./campaignAssignListPanel.component"
import { useRouteMatch } from "react-router-dom"
import { getCampaignAssigned } from "modules/serial-number/redux"

function CampaignAssignListPanelContainer() {
  const dispatch = useDispatch()
  const match = useRouteMatch()
  const enc = match.params.enc

  const campaignAssigned = useSelector(state => state.serialNumber.serialNumber.campaignAssigned)
  const isFetching = useSelector(state => selectLoading(state, getCampaignAssigned.typePrefix))
  const isError = useSelector(state => state.serialNumber.isLoadingCampaignAssignedError)
  const lang = useSelector(state => state.constant.languages);
  const language = useSelector(state => state.profile.language);

  useEffect(() => {
    if (enc) {
      dispatch(getCampaignAssigned(enc))
    }
  }, [enc, dispatch])

  const handleReload = () => {
    dispatch(getCampaignAssigned(enc))
  }

  return (
    <>
      <CampaignAssignListPanelComponent 
        campaigns={campaignAssigned}
        isFetching={isFetching}
        isError={isError}
        handleReload={handleReload}
        lang={lang}
        language={language}
      />
    </>
  )
}

export default CampaignAssignListPanelContainer
