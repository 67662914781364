import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { selectLoading } from "modules/notification";
import {
  getInventoryLooseItemStockList,
  resetLooseItemStockListing,
} from "modules/stockflow/redux";
import LooseItemStockListPanelComponent from "./looseItemStockListPanel.component";
import { generatePaginationOptions, updateUrlQueryParam } from "lib/helper";
import { STOCKFLOW_DEALER_VIEW } from "lib/constants/accessRights";

function LooseItemStockListPanelContainer({ code }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const hasStockflowDealerViewAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(STOCKFLOW_DEALER_VIEW)
  );
  const lang = useSelector((state) => state.constant.languages);
  const looseItemStockList = useSelector(
    (state) => state.stockflowInventory.looseItemStockList
  );
  const totalFiltered = useSelector(
    (state) => state.stockflowInventory.totalFiltered
  );
  const totalRecords = useSelector(
    (state) => state.stockflowInventory.totalRecords
  );
  const isFetching = useSelector((state) =>
    selectLoading(state, getInventoryLooseItemStockList.typePrefix)
  );
  const isError = useSelector(
    (state) => state.stockflowInventory.isLoadingLooseItemStockListError
  );
  const isSearchResult = totalFiltered < totalRecords;
  const [paginationOptions, setPaginationOptions] = useState([]);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isFilterUpdated, setIsFilterUpdated] = useState(false);
  const [tableFilter, setTableFilter] = useState({
    page: 0,
    rowsPerPage: 25,
    search: "",
    searchColumn: "serial_number", // serial_number | sequence_no
    nodeIds: [],
    branch: [],
    products: [],
    locatedAt: [],
    box: false,
    order: "desc",
    orderBy: "sequence_number",
  });

  const query = new URLSearchParams(window.location.search);
  const tableFilterInitialRef = useRef(true);

  useEffect(() => {
    return () => {
      dispatch(resetLooseItemStockListing());
    };
  }, [dispatch]);

  useEffect(() => {
    const isFilter = query.has("is_filter");
    let filter = sessionStorage.getItem("inventory_loose_stock_filter");

    let temp = { ...tableFilter };
    if (isFilter && filter) {
      temp = JSON.parse(filter);
      setIsFilterUpdated(true);
      setIsFilterOpen(true);
    }

    if (isFilter) {
      updateUrlQueryParam(null, ["is_filter"]);
    }
    sessionStorage.removeItem("inventory_loose_stock_filter");

    setTableFilter(temp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!tableFilterInitialRef.current) {
      getData();
    }
    tableFilterInitialRef.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableFilter]);

  useEffect(() => {
    setPaginationOptions(generatePaginationOptions(totalFiltered));
  }, [totalFiltered]);

  const getData = () => {
    const dealer = query.get("dealer");

    dispatch(
      getInventoryLooseItemStockList({
        code,
        length: tableFilter.rowsPerPage,
        start: tableFilter.rowsPerPage * tableFilter.page,
        order: tableFilter.order,
        order_by: tableFilter.orderBy,
        dealer: dealer || "",
        filter: {
          search: tableFilter.search,
          search_by: tableFilter.searchColumn,
          nodes: tableFilter.nodeIds,
          products: tableFilter.products,
          located_at: tableFilter.locatedAt,
          box: tableFilter.box,
        },
      })
    );
  };

  const handleSearch = async (values) => {
    let nodeIds =
      values.branch.length > 0 ? values.branch.map((br) => br.node_id) : [];

    let newData = {
      ...tableFilter,
      page: 0,
      search: values.search,
      searchColumn: values.searchColumn,
      locatedAt: values.locatedAt,
      nodeIds: nodeIds,
      branch: values.branch,
      products: values.products,
      box: values.box.value || false,
    };
    setIsFilterUpdated(true);
    setTableFilter(newData);
  };

  const handleChangePage = (event, newPage) => {
    let newData = {
      ...tableFilter,
      page: newPage,
    };
    setIsFilterUpdated(true);
    setTableFilter(newData);
  };

  const handleChangeRowsPerPage = (event) => {
    let newData = {
      ...tableFilter,
      page: 0,
      rowsPerPage: event.target.value,
    };
    setIsFilterUpdated(true);
    setTableFilter(newData);
  };

  const clearSearch = () => {
    let newData = {
      ...tableFilter,
      page: 0,
      search: "",
      searchColumn: "serial_number",
      nodeIds: [],
      branch: [],
      products: [],
      locatedAt: [],
      box: false,
    };

    setIsFilterUpdated(false);
    setTableFilter(newData);
  };

  const changeSequenceOrder = (order) => {
    let newData = {
      ...tableFilter,
      order,
    };
    setIsFilterUpdated(true);
    setTableFilter(newData);
  };

  const resetFilter = () => {
    let newData = {
      ...tableFilter,
      page: 0,
      nodeIds: [],
      branch: [],
      products: [],
      box: false,
    };
    setIsFilterUpdated(true);
    setTableFilter(newData);
  };

  const handleViewDetail = (url) => {
    if (isFilterUpdated) {
      updateUrlQueryParam({ is_filter: true });
      sessionStorage.setItem(
        "inventory_loose_stock_filter",
        JSON.stringify(tableFilter)
      );
    }
    history.push(url);
  };

  return (
    <>
      <LooseItemStockListPanelComponent
        hasStockflowDealerViewAccessRight={hasStockflowDealerViewAccessRight}
        rows={looseItemStockList}
        isFetching={isFetching}
        isError={isError}
        handleReload={getData}
        page={tableFilter.page}
        rowsPerPage={tableFilter.rowsPerPage}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleSearch={handleSearch}
        isSearchResult={isSearchResult}
        totalFiltered={totalFiltered}
        isFilterOpen={isFilterOpen}
        setIsFilterOpen={setIsFilterOpen}
        tableFilter={tableFilter}
        changeSequenceOrder={changeSequenceOrder}
        lang={lang}
        resetFilter={resetFilter}
        paginationOption={paginationOptions}
        clearSearch={clearSearch}
        code={code}
        handleViewDetail={handleViewDetail}
      />
    </>
  );
}

export default LooseItemStockListPanelContainer;
