import React, { createRef } from "react";
import {
  makeStyles,
  Paper,
  Box,
  Typography,
  IconButton,
  Divider,
  Button,
  DialogActions,
  DialogContent,
  Dialog,
  Table,
  TableContainer,
  TableHead,
  TableCell,
  TableBody,
  CircularProgress,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {
  AttachFile as AttachFileIcon,
  WarningRounded as WarningIcon,
  CheckCircleRounded as CheckIcon,
} from "@material-ui/icons";
import { getLang } from "app/feature/constants";
import Dropzone from "react-dropzone";
import GenerateAdtInfoImportFilePanel from "../../panel/generateAdtInfoImportFilePanel";
import TableRow from "components/table/tableRow";
import clsx from "clsx";

const useStyle = makeStyles((theme) => ({
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    borderCollapse: "separate",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
    },
    "& th,td": {
      fontSize: ".75rem",
      borderRight: "1px solid rgba(58, 77, 84, 0.1)",
      padding: "10px 16px 10px 16px",
      backgroundColor: "white",
    },
    "& th": {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightBold,
      backgroundColor: "#F4F7F8",
    },
  },
  root: {
    borderRadius: 8,
    width: 1000,
    maxWidth: "95vw",
    boxShadow:
      "0px 20px 24px -4px rgba(16, 24, 40, 0.1), 0px 8px 8px -4px rgba(16, 24, 40, 0.04)",
    [theme.breakpoints.down("xs")]: {
      minWidth: "95vw",
    },
  },
  header: {
    paddingLeft: theme.spacing(3),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  dropzone: {
    border: "1px solid grey",
    borderStyle: "dashed",
    padding: "32px 16px",
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    flexDirection: "column",
    alignItems: "center",
    "&:focus": {
      border: "1px solid blue",
      borderStyle: "dashed",
    },
  },
  languageContainer: {
    border: "1px solid #D0D5DD",
    padding: 8,
    borderRadius: 4,
  },
  chip: {
    padding: "0px 4px",
    backgroundColor: "#ececec",
    marginRight: "0.25em",
    marginBottom: 4,
    marginTop: 4,
    border: "none",
    boxShadow:
      "0px 3px 1px -2px rgba(0,0,0,0.1), 0px 2px 2px 0px rgba(0,0,0,0.04), 0px 1px 5px 0px rgba(0,0,0,0.02)",
  },
  caption: {
    color: "#98A2B3",
  },
  form: {
    backgroundColor: "#F2F4F7",
    maxHeight: "80vh",
    overflowY: "auto",
    padding: 16,
  },
  greenLabel: {
    color: "#6AAF68",
  },
  errorCell: {
    color: "#ff0000",
    fontWeight: 400,
    border: "1px solid #ff0000 !important",
    backgroundColor: "#ff00000d !important",
  },
  completeIndicator: {
    color: "#32D583",
    fontSize: 20,
    marginLeft: 8,
  },
}));

export default function ImportFileDialogComponent({
  handleSubmit,
  handleValidate,
  isOpen,
  handleClose,
  lang,
  isSubmitting,
  file,
  setFile,
  setShowTemplateInput,
  showTemplateInput,
  handleFileChange,
  tableHeader,
  tableValues,
  errorField,
  errorTitles,
  showPreview,
  isValidFile,
  isValidating,
  isFileProcessing,
  setShowImportLog,
}) {
  const classes = useStyle();
  const dropzoneRef = createRef();
  const openDialog = () => {
    // Note that the ref is set async,
    // so it might be null at some point
    if (dropzoneRef.current) {
      dropzoneRef.current.open();
    }
  };

  let errorRow = null;

  const getPreview = () => {
    let start = 0;
    let end = 5;
    let row = 0;

    if (errorField) {
      // errorField.row include header as 0, so need deduct one
      row = errorField.row - 1;
      if (row - 2 >= 0) {
        if (row + 2 >= tableValues.length - 1) {
          end = tableValues.length;
          start = end - 5;
        } else {
          start = row - 2;
          end = start + 5;
        }
      }
    }

    if (start > 0) {
      errorRow = row - start;
    } else {
      errorRow = row;
    }

    return tableValues.slice(start, end);
  };

  return (
    <Dialog
      open={isOpen}
      disableBackdropClick
      disableEscapeKeyDown
      aria-labelledby='="general-dialog'
      // maxWidth="md"
      classes={{
        paper: classes.root,
      }}
      // fullWidth
    >
      <Paper elevation={0}>
        <div className={classes.header}>
          <Typography variant="subtitle2" style={{ fontWeight: "bold" }}>
            {getLang(lang, "label.IMPORT_ADDITIONAL_INFO")}
          </Typography>
          <IconButton color="primary" onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </div>
        <Divider />

        {showTemplateInput ? (
          <>
            <GenerateAdtInfoImportFilePanel
              handleBack={() => {
                setShowTemplateInput(false);
              }}
            />
          </>
        ) : (
          <>
            <DialogContent className={classes.form}>
              <Box mb={3}>
                <Paper elevation={0}>
                  <Box display="flex" p={2}>
                    <Box minWidth={70}>
                      <Typography variant="body1">
                        <b>{getLang(lang, "label.STEP_NUM", { number: 1 })}:</b>
                      </Typography>
                    </Box>
                    <Box>
                      <Box mb={1}>
                        <Typography variant="body1">
                          <b>{getLang(lang, "label.GET_TEMPLATE")}</b>
                        </Typography>
                        <Typography variant="body2" className={classes.caption}>
                          {getLang(
                            lang,
                            "paragraph.ADDITIONAL_INFO_TEMPLATE_INSTRUCTION"
                          )}
                        </Typography>
                        <Typography variant="body2" className={classes.caption}>
                          {getLang(
                            lang,
                            "paragraph.ADDITIONAL_INFO_TEMPLATE_INSTRUCTION_2"
                          )}
                          &nbsp;
                          <span style={{ color: "#f0c955" }}>
                            <b>(DD/MM/YYYY)</b>
                          </span>
                        </Typography>
                        <Typography variant="body2" className={classes.caption}>
                          {getLang(
                            lang,
                            "paragraph.ADDITIONAL_INFO_TEMPLATE_INSTRUCTION_3"
                          )}
                        </Typography>
                        <Typography variant="body2" className={classes.caption}>
                          {getLang(
                            lang,
                            "paragraph.ADDITIONAL_INFO_TEMPLATE_INSTRUCTION_4"
                          )}
                        </Typography>
                      </Box>
                      <Button
                        color="primary"
                        variant="contained"
                        disabled={isValidating || isSubmitting}
                        onClick={() => {
                          setShowTemplateInput(true);
                        }}
                      >
                        {getLang(lang, "label.TEMPLATE_LABEL")}
                      </Button>
                    </Box>
                  </Box>
                </Paper>
              </Box>
              <Box mb={2}>
                <Paper elevation={0}>
                  <Box display="flex" p={2}>
                    <Box minWidth={70}>
                      <Typography variant="body1">
                        <b>{getLang(lang, "label.STEP_NUM", { number: 2 })}:</b>
                      </Typography>
                    </Box>
                    <Box flex={1}>
                      <Box mb={1}>
                        <Typography variant="body1">
                          <b>{getLang(lang, "label.UPLOAD_CSV_FILE")}</b>
                        </Typography>
                      </Box>
                      <Dropzone
                        ref={dropzoneRef}
                        noClick
                        maxFiles={1}
                        noKeyboard
                        accept={[".csv"]}
                        onDrop={(acceptedFiles) => {
                          if (acceptedFiles.length) {
                            setFile(acceptedFiles[0]);
                            handleFileChange(acceptedFiles[0]);
                          } else {
                            setFile(null);
                          }
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <section>
                            <Box
                              {...getRootProps({ className: classes.dropzone })}
                            >
                              <input {...getInputProps()} />
                              <Typography
                                variant="body2"
                                style={{ marginBottom: 10 }}
                              >
                                {getLang(
                                  lang,
                                  "paragraph.DROP_CSV_OR_CHOOSE_FILE"
                                )}
                              </Typography>
                              <Button
                                color="primary"
                                variant="outlined"
                                disableElevation
                                style={{ backgroundColor: "white" }}
                                onClick={openDialog}
                              >
                                {getLang(lang, "label.CHOOSE_A_FILE")}
                              </Button>
                            </Box>
                          </section>
                        )}
                      </Dropzone>
                      {isFileProcessing ? (
                        <Box display="flex" mt={1}>
                          <CircularProgress
                            size={20}
                            style={{ marginLeft: 10 }}
                          />
                        </Box>
                      ) : (
                        !!file && (
                          <Box display="flex" mt={1}>
                            <AttachFileIcon
                              style={{
                                marginRight: 8,
                                fontSize: 18,
                                marginTop: 2,
                              }}
                            />
                            <Typography>{file.name}</Typography>
                          </Box>
                        )
                      )}
                      <Box mt={2} display="flex" alignItems="center">
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={() => {
                            if (!isValidFile && !isFileProcessing)
                              handleValidate();
                          }}
                          disabled={
                            !file ||
                            isSubmitting ||
                            isValidating ||
                            !!errorField ||
                            !!errorTitles.length
                          }
                        >
                          {getLang(lang, "label.VALIDATE")}
                        </Button>
                        {isValidating && (
                          <CircularProgress
                            size={20}
                            style={{ marginLeft: 10 }}
                          />
                        )}
                        {isValidFile && (
                          <CheckIcon className={classes.completeIndicator} />
                        )}
                      </Box>
                    </Box>
                  </Box>
                  {!!showPreview && (
                    <Box p={2}>
                      <Divider />
                      <Box mt={3} mb={1} display="flex" ml={1}>
                        <Typography variant="body1">
                          <b>{getLang(lang, "label.ERROR")}</b>
                        </Typography>
                        <WarningIcon style={{ fontSize: 20, marginLeft: 8 }} />
                      </Box>
                      <TableContainer style={{ border: "1px solid #3a4d541a" }}>
                        <Table className={classes.table}>
                          <TableHead>
                            <TableRow>
                              {tableHeader.map((header, index) => (
                                <TableCell
                                  key={index}
                                  style={{
                                    minWidth: 80,
                                    color: errorTitles.includes(index)
                                      ? "#ff0000"
                                      : "black",
                                    fontWeight: errorTitles.includes(index)
                                      ? "500"
                                      : "400",
                                  }}
                                >
                                  {header}
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {!!tableValues.length && (
                              <>
                                {!!errorField && errorField.row - 1 > 2 && (
                                  <TableRow>
                                    <TableCell colSpan={tableHeader.length}>
                                      <Box>...</Box>
                                    </TableCell>
                                  </TableRow>
                                )}
                                {getPreview().map((values, index) => (
                                  <TableRow key={index}>
                                    {values.map((val, i) => (
                                      <TableCell
                                        key={i}
                                        className={clsx({
                                          [classes.errorCell]:
                                            errorRow === index &&
                                            (errorField?.column === i ||
                                              errorField?.column === null),
                                        })}
                                        style={{
                                          minWidth: 80,
                                        }}
                                      >
                                        {val}
                                      </TableCell>
                                    ))}
                                  </TableRow>
                                ))}
                                {!!errorField &&
                                  errorField.row < tableValues.length - 2 && (
                                    <TableRow>
                                      <TableCell colSpan={tableHeader.length}>
                                        <Box>...</Box>
                                      </TableCell>
                                    </TableRow>
                                  )}
                              </>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  )}
                </Paper>
              </Box>
            </DialogContent>
            <Divider />
            <DialogActions>
              <Button
                autoFocus
                onClick={(e) => {
                  handleClose();
                }}
                disabled={isSubmitting}
                color="primary"
              >
                {getLang(lang, "label.CANCEL")}
              </Button>
              <Button
                color="secondary"
                variant="contained"
                disableElevation
                disabled={
                  isSubmitting ||
                  !file ||
                  !!errorField ||
                  !!errorTitles.length ||
                  !isValidFile
                }
                onClick={() => handleSubmit()}
              >
                {isSubmitting
                  ? getLang(lang, "label.SUBMITTING")
                  : getLang(lang, "label.SUBMIT")}
              </Button>
            </DialogActions>
          </>
        )}
      </Paper>
    </Dialog>
  );
}
