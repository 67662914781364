import React from "react";
import ClusterSummaryCardComponent from "./clusterSummaryCard.component";

function ClusterSummaryCardContainer({ title, icon, count, loading }) {
  return (
    <ClusterSummaryCardComponent
      title={title}
      icon={icon}
      count={count}
      loading={loading}
    />
  );
}

export default ClusterSummaryCardContainer;
