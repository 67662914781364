import React, { useState, useRef } from "react"
import { generatePaginationOptions, updateUrlQueryParam } from "lib/helper"
import { selectLoading } from "modules/notification"
import moment from "moment"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useRouteMatch } from "react-router-dom"
import DealerPointExpiringListPanelComponent from "./dealerPointExpiringListPanel.component"
import { 
  getDealerPointExpiringList,
  resetDealerPointExpiringListing, 
} from "modules/stockflow/redux"

function DealerPointExpiringListPanelContainer() {
  const dispatch = useDispatch()
  const match = useRouteMatch()
  const id = match.params.dealerId
  const lang = useSelector(state => state.constant.languages)
  const language = useSelector(state => state.profile.language)

  const isExpiringFetching = useSelector(state => selectLoading(state, getDealerPointExpiringList.typePrefix))

  const totalFiltered = useSelector(state => state.stockflowDealer.pointExpiringList.totalFiltered)
  const pointExpiringList = useSelector(state => state.stockflowDealer.pointExpiringList.list)
  const isError = useSelector(state => state.stockflowDealer.pointExpiringList.isError)
  
  const [paginationOptions, setPaginationOptions] = useState([]);
  const [tableFilter, setTableFilter] = useState({
    page: 0,
    rowsPerPage: 25,
    order: "desc",
    date: "",
  })

  const tableFilterInitialRef = useRef(true)

  useEffect(() => {
    return () => {
      dispatch(resetDealerPointExpiringListing())
    }
  }, [dispatch])

  useEffect(() => {
    const searchParam = new URLSearchParams(window.location.search);
    const date = searchParam.get("date")
    const order = searchParam.get("order")
    const page = searchParam.get("page")
    const length = searchParam.get("length")

    setTableFilter(prev => ({
      ...prev,
      page: page ? page - 1 : tableFilter.page,
      rowsPerPage: length || tableFilter.rowsPerPage,
      date: date || tableFilter.date,
      order: order || tableFilter.order,
    }))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  useEffect(() => {
    if (!tableFilterInitialRef.current) {
      dispatch(resetDealerPointExpiringListing())
      getData()
    } 
    tableFilterInitialRef.current = false
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableFilter])

  useEffect(() => {
    setPaginationOptions(generatePaginationOptions(totalFiltered))
  }, [totalFiltered])

  const getData = () => {
    dispatch(getDealerPointExpiringList({
      id,
      length: tableFilter.rowsPerPage,
      start: tableFilter.rowsPerPage * tableFilter.page,
      date: tableFilter.date,
      order: tableFilter.order
    }))
  }

  const handleChangePage = (event, newPage) => {
    let newData = {
      ...tableFilter,
      page: newPage,
    }
    updateUrlQueryParam({
      page: newData.page + 1,
      length: newData.rowsPerPage
    })
    setTableFilter(newData)
  }

  const handleChangeRowsPerPage = event => {
    let newData = {
      ...tableFilter,
      page: 0,
      rowsPerPage: event.target.value,
    }
    updateUrlQueryParam({
      page: newData.page + 1,
      length: newData.rowsPerPage
    })
    setTableFilter(newData)
  }

  const handleSearch = (filter) => {
    const { date } = filter

    let newData = {
      ...tableFilter,
      page: 0,
      date: date ? moment(date).format("YYYY-MM-DD") : "",
    }

    updateUrlQueryParam(
      { date: newData.date },
      ["page", "length"]
    );
    setTableFilter(newData)
  }

  const clearSearch = () => {
    let newData = {
      ...tableFilter,
      page: 0,
      date: "",
    }

    updateUrlQueryParam(
      null,
      ["page", "length", "date"]
    );
    setTableFilter(newData)
  }

  const changeOrder = (order) => {
    let newData = {
      ...tableFilter,
      order
    }

    updateUrlQueryParam({ order: order });
    setTableFilter(newData)
  }

  return (
    <>
      <DealerPointExpiringListPanelComponent
        id={id}
        pointExpiring={pointExpiringList}
        isFetching={isExpiringFetching}
        isError={isError}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        page={tableFilter.page}
        language={language}
        rowsPerPage={tableFilter.rowsPerPage}
        totalFiltered={totalFiltered}
        handleSearch={handleSearch}
        clearSearch={clearSearch}
        handleReload={getData}
        paginationOptions={paginationOptions}
        tableFilter={tableFilter}
        changeOrder={changeOrder}
        lang={lang}
      />
    </>
  )
}

export default DealerPointExpiringListPanelContainer
