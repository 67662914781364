import React, { useEffect, useState } from "react";
import ViewDemoDialogComponent from "./viewDemoDialog.component";
import { useSelector, useDispatch } from "react-redux";
import { closeDemoDialog } from "modules/instant-lucky-draw/redux";
import { instantGameTemplate } from "lib/constants/instantGameTemplate";

function ViewDemoDialogContainer() {
  const dispatch = useDispatch();
  const logo = useSelector((state) => state.account.logoImg);
  const dialogSetting = useSelector(
    (state) => state.instantLuckyDraw.createInstantLuckyDrawDialogSetting
  );
  const lang = useSelector(state => state.constant.languages)
  const [templateConfig, setTemplateConfig] = useState(null)

  useEffect(() => {
    renderTemplateConfig({
      type: dialogSetting.demoType,
      templateName: dialogSetting.demoTemplate
    })
  }, [dialogSetting.demoType, dialogSetting.demoTemplate])

  const renderTemplateConfig = ({ type, templateName }) => {
    const game = instantGameTemplate.find(x => x.name === type)

    if (game) {
      const template = game.type.find(x => x.name === templateName)

      if (template) {
        setTemplateConfig(template.config)
      }
    }
  }

  return (
    <ViewDemoDialogComponent
      isOpen={dialogSetting.demoOpen}
      handleClose={() => dispatch(closeDemoDialog())}
      logo={logo}
      type={dialogSetting.demoType}
      templateConfig={templateConfig}
      lang={lang}
    />
  );
}

export default ViewDemoDialogContainer;
