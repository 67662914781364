import React from "react";
import { makeStyles, ButtonBase, Typography, Box } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import clsx from "clsx";
import PropTypes from "prop-types";
import { getLang } from "app/feature/constants";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "5px 12px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: 4,
    width: "100%",
    overflow: "hidden",
    height: 34,
    backgroundColor: "white",
    border: "2px solid #D0D5DD",
  },
  inputText: {
    fontSize: "1rem",
    color: "#a4a6a8",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  filled: {
    color: "rgba(0, 0, 0, 0.87)",
  },
  clearButton: {
    minWidth: 0,
  },
}));

function StatusSelectComponent({
  handleClick,
  value,
  placeholder,
  disabled,
  style,
  isEmpty,
  id,
  open,
  textStyle,
  lang,
}) {
  const classes = useStyles();

  return (
    <ButtonBase
      aria-describedby={id}
      className={classes.root}
      disableRipple
      onClick={handleClick}
      disabled={disabled}
      style={{ paddding: 12, ...style }}
    >
      <Typography
        variant="body2"
        className={clsx(classes.inputText, {
          [classes.filled]: !isEmpty,
        })}
        style={textStyle}
      >
        {isEmpty ? (
          placeholder
        ) : (
          <>
            {getLang(lang, "label.STATUS")}:{" "}
            <span style={{ textTransform: "capitalize" }}>{value}</span>
          </>
        )}
      </Typography>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {!disabled &&
          (open ? (
            <ArrowDropUpIcon fontSize="small" />
          ) : (
            <ArrowDropDownIcon fontSize="small" />
          ))}
      </Box>
    </ButtonBase>
  );
}

StatusSelectComponent.propTypes = {
  handleClick: PropTypes.func,
  value: PropTypes.any,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  isEmpty: PropTypes.bool,
};

export default StatusSelectComponent;
