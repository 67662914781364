import React from "react";
import {
  Paper,
  makeStyles,
  Typography,
  Divider,
  Box,
  Grid,
  Button
} from "@material-ui/core";
import LaunchIcon from "@material-ui/icons/Launch";
import { Skeleton } from "@material-ui/lab";
import PerfectScrollbar from "react-perfect-scrollbar";
import { getLang } from "app/feature/constants";

const style = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    justifyItems: "center"
  },
  text: {
    color: "rgba(58, 77, 84, 0.33)",
    fontWeight: "bold"
  },
  viewContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center"
  },
  button: {
    padding: "4px",
    minWidth: 0,
    backgroundColor: "#ECEDEE",
    marginLeft: theme.spacing(1),
    borderRadius: "4px"
  },
  displayMessage: {
    padding: theme.spacing(3, 2, 3, 2),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100px"
  },
  cardContent: {
    overflowY: "auto",
    maxHeight: 450
  }
});

const useStyles = makeStyles(style);

export default function LastTransactionPanelComponent({
  handleViewAll,
  transactionLatest,
  isFetching,
  lang
}) {
  const classes = useStyles();
  return (
    <Paper elevation={0} className={classes.root}>
      <Box style={{ padding: "8px 16px" }}>
        <Grid container>
          <Grid
            item
            xs={6}
            sm={6}
            md={6}
            style={{ width: "100%", alignSelf: "center" }}
          >
            <Typography
              variant="subtitle2"
              color="primary"
              style={{ fontWeight: "bold" }}
            >
              {getLang(lang, "label.LAST_TRANSACTION")}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6} md={6} style={{ width: "100%" }}>
            {!isFetching && transactionLatest.data.length > 0 ? (
              <Box className={classes.viewContainer}>
                <Typography variant="subtitle2" className={classes.text}>
                  {getLang(lang, "label.VIEW_ALL")}
                </Typography>
                <Button className={classes.button} onClick={handleViewAll}>
                  <LaunchIcon fontSize="small" />
                </Button>
              </Box>
            ) : null}
          </Grid>
        </Grid>
      </Box>

      <Divider />

      {isFetching ? (
        <Box p={2}>
          <Skeleton width={130} height={30} />
          <Skeleton width={"100%"} height={30} />
          <Skeleton width={"100%"} height={30} />
          <Box pt={2} pb={2}>
            <Divider />
          </Box>

          <Skeleton width={130} height={30} />
          <Skeleton width={"100%"} height={30} />
          <Skeleton width={"100%"} height={30} />
          <Box pt={2} pb={2}>
            <Divider />
          </Box>

          <Skeleton width={130} height={30} />
          <Skeleton width={"100%"} height={30} />
          <Skeleton width={"100%"} height={30} />
        </Box>
      ) : (
        <Box>
          {!isFetching && transactionLatest.data.length > 0 ? (
            <Box>
              <PerfectScrollbar
                className={classes.cardContent}
                options={{
                  suppressScrollX: true,
                }}
              >
                {transactionLatest.data.map((field, index) => (
                  <Box key={index}>
                    <Box p={2}>
                      <Typography
                        variant="caption"
                        style={{ color: "rgba(58, 77, 84, 0.33)" }}
                      >
                        {field.dateDisplay}
                      </Typography>
                      {field.transactions.map((transaction, index) => (
                        <Box pt={1} key={index}>
                          <Grid container style={{ alignItems: "center" }}>
                            <Grid
                              item
                              xs={6}
                              sm={6}
                              md={6}
                              style={{ width: "100%" }}
                            >
                              {transaction.type === "plan" ? (
                                transaction.unit === "month" ? (
                                  <Typography variant="body2" color="primary">
                                    {getLang(lang, "label.MONTHLY_TRANSACTION")}
                                  </Typography>
                                ) : transaction.unit === "year" ? (
                                  <Typography variant="body2" color="primary">
                                    {getLang(lang, "label.YEARLY_TRANSACTION")}
                                  </Typography>
                                ) : null
                              ) : (
                                <Typography variant="body2" color="primary">
                                 {getLang(lang, "label.PURCHASE")} {transaction.name === "QR Credit" ? getLang(lang, 'label.QR_CREDIT') : transaction.name}
                                </Typography>
                              )}
                            </Grid>
                            <Grid
                              item
                              xs={6}
                              sm={6}
                              md={6}
                              style={{ width: "100%", textAlign: "end" }}
                            >
                              <Typography variant="body2" color="primary">
                                {parseFloat(transaction.amount).toFixed(2)} USD
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                      ))}
                    </Box>

                    {transactionLatest.data.length > index + 1 ? (
                      <Divider />
                    ) : null}
                  </Box>
                ))}
              </PerfectScrollbar>
            </Box>
          ) : (
            <Box className={classes.displayMessage}>
              <Typography variant="body1" color="primary">
                {getLang(lang, "paragraph.NO_TRANSACTION_HISTORY")}
              </Typography>
            </Box>
          )}
        </Box>
      )}
    </Paper>
  );
}
