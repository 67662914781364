import React, { useEffect, useState } from "react";
import BillingDetailCardComponent from "./billingDetailCard.component";
import Dialog from "components/dialog/customDialog";
import { connect, useSelector } from "react-redux";
import { fetchBillingDetail } from "../../../redux";
import BillingDetailForm from "../../form/billingDetailForm";
import { selectLoading } from "modules/notification";

function BillingDetailCardContainer({
  billingDetail,
  fetchBillingDetail,
  isFetching
}) {
  const [isModalOpen, setModalOpen] = useState(false);
  const lang = useSelector(state => state.constant.languages)

  useEffect(() => {
    fetchBillingDetail();
  }, [fetchBillingDetail]);

  const handleEditDetail = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      <BillingDetailCardComponent
        handleEditDetail={handleEditDetail}
        billingDetail={billingDetail}
        isFetching={isFetching}
        lang={lang}
      />
      <Dialog
        isOpen={isModalOpen}
        handleClose={closeModal}
        disableEnforceFocus={true}
        disableAutoFocus={true}
      >
        <BillingDetailForm
          handleClose={closeModal}
          billingDetail={billingDetail}
        />
      </Dialog>
    </>
  );
}

const mapStateToProps = (state) => ({
  billingDetail: state.billing.billingDetail,
  isFetching: selectLoading(state, fetchBillingDetail.typePrefix)
});

const mapDispatchToProps = (dispatch) => ({
  fetchBillingDetail: () => dispatch(fetchBillingDetail())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BillingDetailCardContainer);
