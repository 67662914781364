import axios from "axios";
import PropTypes from "prop-types";

const post = ({ module }) => {
  return new Promise((resolve, reject) => {
    const body = { module };

    axios
      .post("/payment/api/v1/payment/formula", body, {
        timeout: 10000
      })
      .then(response => resolve(response.data))
      .catch(error => {
        return reject(error.response.data);
      });
  });
};

post.propTypes = {
  module: PropTypes.string.isRequired
};

const formula = {
  post
};

export default formula;
