import { createSlice } from "@reduxjs/toolkit";
import { ROLE_ACCESS_RIGHT } from "modules/role-access-right/constant";
import { fetchAccessRightList, fetchRoleList, fetchRoleDetail } from "./roleAccessRight.action";

const initialState = {
  accessRightList: {
    data: [],
    totalRecords: 0,
    filteredTotalRecords: 0
  },
  roleList: {
    data: [],
    totalRecords: 0,
    filteredTotalRecords: 0
  },
  roleSetting: {
    type: 'create',
    isOpen: false
  },
  roleInfo: {
    id: '',
    name: '',
    accessRights: [],
    default: '',
    accessRightIds: []
  },
  isLoadingRoleError: false,
  accessRole: []
};

const roleAccessRightSlice = createSlice({
  name: ROLE_ACCESS_RIGHT,
  initialState,
  reducers: {
    openCreationDialog(state) {
      state.roleSetting.type = 'create';
      state.roleSetting.isOpen = true;
    },
    closeRoleDialog(state) {
      state.roleSetting = initialState.roleSetting;
      state.roleInfo = initialState.roleInfo;
    },
    openViewEditDialog(state) {
      state.roleSetting.type = 'edit';
      state.roleSetting.isOpen = true;
    },
  },
  extraReducers: {
    [fetchAccessRightList.fulfilled]: (state, action) => {
      const { payload } = action;
      const tempArray = [];

      state.accessRightList.totalRecords = payload.recordsFiltered;

      payload.data.forEach((item) => {

        tempArray.push({
          group: item.group,
          subgroup: item.subgroup,
        });
      });
      state.accessRightList.data = tempArray;
    },
    [fetchRoleList.fulfilled]: (state, action) => {
      const { payload } = action;
      const tempArray = [];

      state.roleList.totalRecords = payload.recordsFiltered;

      payload.data.forEach((item) => {
        tempArray.push({
          id: item.id,
          name: item.name,
          accessRight: item.access_rights,
          users: item.users,
          default: item.default
        });
      });
      state.roleList.data = tempArray;
      state.isLoadingRoleError = false;
    },
    [fetchRoleList.rejected]: (state, action) => {
      state.isLoadingRoleError = true;
    },
    [fetchRoleDetail.fulfilled]: (state, action) => {
      const { payload } = action;
      const tempArray = [];

      state.roleInfo.id = payload.id
      state.roleInfo.name = payload.name
      state.roleInfo.default = payload.default
      state.roleInfo.accessRights = payload.access_rights

      payload.access_rights.forEach((item) => {
        tempArray.push(item.id)
      })
      state.roleInfo.accessRightIds = tempArray;
    },
  },
});

export const roleAccessRightReducer = roleAccessRightSlice.reducer;
export const { openCreationDialog, closeRoleDialog, openViewEditDialog } =
  roleAccessRightSlice.actions;
