import React from "react";

export const DownloadSVG = () => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.30227 13.0506L9.30224 13.0506L9.30461 13.0529C9.39729 13.1416 9.50643 13.2113 9.62588 13.2581C9.74384 13.3097 9.87122 13.3364 10 13.3364C10.1288 13.3364 10.2562 13.3097 10.3741 13.2581C10.4936 13.2113 10.6027 13.1416 10.6954 13.0529L10.6954 13.0529L10.6977 13.0506L14.0311 9.71726C14.2161 9.53221 14.3201 9.28123 14.3201 9.01953C14.3201 8.75783 14.2161 8.50685 14.0311 8.3218C13.846 8.13675 13.595 8.03279 13.3333 8.03279C13.0716 8.03279 12.8207 8.13675 12.6356 8.3218L12.6354 8.32203L10.9833 9.98128V4.85286C10.9833 4.59207 10.8797 4.34195 10.6953 4.15754C10.5109 3.97313 10.2608 3.86953 10 3.86953C9.7392 3.86953 9.48909 3.97313 9.30468 4.15754C9.12027 4.34195 9.01667 4.59207 9.01667 4.85286V9.98128L7.36463 8.32203L7.3644 8.3218C7.27277 8.23017 7.16399 8.15749 7.04428 8.1079C6.92456 8.05831 6.79625 8.03279 6.66667 8.03279C6.53709 8.03279 6.40877 8.05831 6.28906 8.1079C6.16934 8.15749 6.06056 8.23017 5.96893 8.3218C5.87731 8.41343 5.80462 8.5222 5.75504 8.64192L5.89362 8.69932L5.75504 8.64192C5.70545 8.76164 5.67992 8.88995 5.67992 9.01953C5.67992 9.14911 5.70545 9.27742 5.75504 9.39714C5.80462 9.51686 5.87731 9.62564 5.96893 9.71726L9.30227 13.0506ZM5.13801 17.2149L5.24408 17.1088L5.13801 17.2149C5.32242 17.3993 5.57254 17.5029 5.83333 17.5029H14.1667C14.4275 17.5029 14.6776 17.3993 14.862 17.2149C15.0464 17.0304 15.15 16.7803 15.15 16.5195C15.15 16.2587 15.0464 16.0086 14.862 15.8242C14.6776 15.6398 14.4275 15.5362 14.1667 15.5362H5.83333C5.57254 15.5362 5.32242 15.6398 5.13801 15.8242C4.9536 16.0086 4.85 16.2587 4.85 16.5195C4.85 16.7803 4.9536 17.0304 5.13801 17.2149Z"
      fill="#3A4D54"
      stroke="#3A4D54"
      strokeWidth="0.3"
    />
  </svg>
);
