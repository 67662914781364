import React, { useEffect } from "react";
import ClusterMapPanelComponent from "./clusterMapPanel.component";
import { useDispatch, useSelector } from "react-redux";
import { fetchClusterScanMap } from "modules/dashboard/redux/action/dashboard.action";
import { selectLoading } from "modules/notification";
import { useState } from "react";
import { resetClusterScanProducts } from "modules/dashboard/redux/slice/dashboard.slice";

function ClusterMapPanelContainer({
  clusterBound,
  startDate,
  endDate,
  handleClusterClick,
  handleMarkerClick,
}) {
  const dispatch = useDispatch();
  const mapScans = useSelector((state) => state.dashboard.mapClusterScans);
  const isFetching = useSelector((state) =>
    selectLoading(state, fetchClusterScanMap.typePrefix)
  );
  const isError = useSelector(
    (state) => state.dashboard.isLoadingClusterScanError
  );

  const [showProductList, setShowProductList] = useState(true);
  const [revealProduct, setRevealProduct] = useState(false);
  const [isSelectAll, setIsSelectAll] = useState(true);
  const [selectedIds, setSelectedIds] = useState([]);

  useEffect(() => {
    return () => {
      dispatch(resetClusterScanProducts());
    };
  }, [dispatch]);

  useEffect(() => {
    if (clusterBound) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clusterBound]);

  const getData = () => {
    dispatch(
      fetchClusterScanMap({
        start_date: startDate,
        end_date: endDate,
        latitude: [clusterBound.south, clusterBound.north],
        longitude: [clusterBound.west, clusterBound.east],
      })
    );
  };

  const handleSelectAll = (selectAll) => {
    setSelectedIds([]);
    setIsSelectAll(selectAll);
  };

  const handleSelectProduct = (uuid, selected) => {
    if ((!isSelectAll && selected) || (isSelectAll && !selected)) {
      setSelectedIds(selectedIds.concat([uuid]));
    } else {
      let temp = [...selectedIds];
      let index = temp.findIndex((u) => u === uuid);
      if (index >= 0) {
        temp.splice(index, 1);
        setSelectedIds(temp);
      }
    }
  };

  return (
    <ClusterMapPanelComponent
      showProductList={showProductList}
      toggleProductList={(show) => setShowProductList(show)}
      mapScans={mapScans}
      handleClusterClick={handleClusterClick}
      handleMarkerClick={handleMarkerClick}
      clusterBound={clusterBound}
      startDate={startDate}
      endDate={endDate}
      revealProduct={revealProduct}
      updateRevealProductStatus={(show) => setRevealProduct(show)}
      handleSelectProduct={handleSelectProduct}
      handleSelectAll={handleSelectAll}
      isSelectAll={isSelectAll}
      selectedIds={selectedIds}
      isFetching={isFetching}
      isError={isError}
      handleReload={getData}
    />
  );
}

export default ClusterMapPanelContainer;
