import { LOGIN } from 'modules/public/config/public.route'
import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import ResetPasswordSuccessPage from './resetPasswordSuccess.page'

export default function ResetPasswordSuccessContainer() {
    const history = useHistory()

    const handleContinue = () => {
        history.push(LOGIN)
    }
    const lang = useSelector(state => state.constant.languages)

    return (
        <ResetPasswordSuccessPage handleContinue={handleContinue} lang={lang}/>
    )
}
