import React from "react";
import { useSelector } from "react-redux";

import PercentageBarComponent from './percentageBar.component';

export default function PercentageBarContainer({
  percentage,
  count,
  label
}) {
  const lang = useSelector(state => state.constant.languages)

  return (
    <PercentageBarComponent label={label} percentage={percentage} count={count} lang={lang}/>
  )
}