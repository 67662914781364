import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  STOCKFLOW_REQUEST_TRANSFER_EDIT,
  STOCKFLOW_REQUEST_TRANSFER_VIEW,
} from "lib/constants/accessRights";
import LoadingComponent from "components/loading";
import MainPageComponent from "./main.component";
import qs from "query-string";
import { REQUEST_TRANSFER_LIST_PAGE } from "modules/stockflow/config/stockflow.route";
import { getLang } from "app/feature/constants";

function MainPageContainer() {
  const hasStockflowViewAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(STOCKFLOW_REQUEST_TRANSFER_VIEW)
  );
  const hasStockflowEditAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(STOCKFLOW_REQUEST_TRANSFER_EDIT)
  );
  const lang = useSelector((state) => state.constant.languages);
  const history = useHistory();
  const location = useLocation();
  const [activeIndex, setActiveIndex] = useState(null);

  const qsParams = qs.parse(location.search);
  const currentSearch = qsParams.status || "";

  useEffect(() => {
    if (!hasStockflowViewAccessRight) {
      history.push("/admin/dashboard");
    }
  }, [history, hasStockflowViewAccessRight]);

  useEffect(() => {
    let tempIndex = 0;
    switch (currentSearch) {
      case "my":
        tempIndex = 0;
        break;
      case "branch":
        tempIndex = 1;
        break;
      default:
        tempIndex = 0;
    }
    setActiveIndex(tempIndex);
  }, [currentSearch]);

  if (!hasStockflowViewAccessRight) return <LoadingComponent />;

  let tabLabels = [];
  if (hasStockflowViewAccessRight) {
    tabLabels.push(getLang(lang, "label.MY_TRANSFER_REQUEST"));
    tabLabels.push(getLang(lang, "label.TRANSFER_REQUEST"));
  }

  const handleChange = (event, index) => {
    sessionStorage.removeItem("request_transfer_filter");

    if (activeIndex !== index) {
      setActiveIndex(index);
      history.replace({
        pathname: REQUEST_TRANSFER_LIST_PAGE,
        search: `?status=${index === 1 ? "branch" : "my"}`,
      });
    }
  };

  return (
    <>
      <MainPageComponent
        handleChange={handleChange}
        activeIndex={activeIndex}
        tabLabels={tabLabels}
        lang={lang}
        hasStockflowEditAccessRight={hasStockflowEditAccessRight}
      />
    </>
  );
}

export default MainPageContainer;
