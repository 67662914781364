import axios from "axios";

const getImportProgress = async (parameters) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `/serialnumber/api/v1/additional-info/import-progress/${parameters.import_uuid}`
      )
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data));
  });
};

export default getImportProgress;
