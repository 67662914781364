import { createSlice } from "@reduxjs/toolkit";
import { REPORTING } from "modules/reporting/constants";
import { getReportValidation } from "../action";

const initialState = {
    reportValidation: {
        success: false,
        error: null
    }
}

const reportingValidationSlice = createSlice({
    name: REPORTING,
    initialState,
    reducers: {
        initReportValidation: (state) => {
            state.reportValidation = initialState.reportValidation;
        }
    },
    extraReducers: {
        [getReportValidation.fulfilled]: (state, action) => {
            if(!action.payload.success){
                switch(action.payload.code){
                    case "error.ERROR_NOT_FOUND":
                        state.reportValidation.error = 404;
                        break;
                    case "error.ERROR_ACCESS_DENIED":
                        state.reportValidation.error = 403;
                        break;
                    default:
                        break;
                }
            }else {
                state.reportValidation.success = true;
            }
        },
        [getReportValidation.rejected]: (state, action) => {
            if(!action.payload.success){
                switch(action.payload.code){
                    case "error.ERROR_NOT_FOUND":
                        state.reportValidation.error = 404;
                        break;
                    case "error.ERROR_ACCESS_DENIED":
                        state.reportValidation.error = 403;
                        break;
                    default:
                        break;
                }
            }else {
                state.reportValidation.success = true;
            }
        }
    }
})

export const {
    initReportValidation
} = reportingValidationSlice.actions;

export const reportingValidationReducer = reportingValidationSlice.reducer;