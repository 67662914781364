import React from "react";
import {
  makeStyles,
  Box,
  Typography,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { ErrorSVG } from "modules/lucky-draw/utils/static";
import { StyledTableCell, StyledTableRow } from "components/table";
import { getLang } from "app/feature/constants";
import DefaultImg from "assets/img/img-default.png";

const style = (theme) => ({
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    borderCollapse: "separate",
    border: "1px solid rgba(58, 77, 84, 0.1)",
    borderBottom: "none",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
    },
    "& th,td": {
      fontSize: ".75rem",
      padding: "10px 16px 10px 16px",
    },
    "& th": {
      color: "rgba(58, 77, 84, 0.33)",
    },
  },
  emptyContainer: {
    backgroundColor: theme.palette.background.paper,
    alignItems: "center",
    display: "flex",
    placeContent: "center",
    border: "1px solid rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
    marginTop: theme.spacing(2),
  },
  attributeText: {
    color: "#b2b2b2",
    fontSize: 12,
    whiteSpace: "break-spaces",
  },
});

const useStyles = makeStyles(style);

export default function AssignProductTableComponent({
  productDropdown,
  tableData,
  lang,
}) {
  const classes = useStyles();

  return (
    <Box>
      {tableData.length > 0 ? (
        <TableContainer style={{ paddingTop: "16px" }}>
          <Table
            className={classes.table}
            size="small"
            aria-label="product table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <StyledTableCell>{getLang(lang, "label.NUM")}</StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.IMAGE")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.PRODUCT_NAME")}
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.map((row, index) => {
                let prd = productDropdown.find((p) => p.id === row.id);
                return (
                  <StyledTableRow
                    key={index}
                    hover
                    role="checkbox"
                    tabIndex={-1}
                  >
                    <StyledTableCell component="th" scope="row">
                      {index + 1}
                    </StyledTableCell>
                    <StyledTableCell>
                      {prd ? (
                        <img
                          src={prd.picture || DefaultImg}
                          style={{ width: "30px", height: "30px" }}
                          alt={productDropdown.id}
                        />
                      ) : (
                        <img
                          src={row.picture || DefaultImg}
                          style={{ width: "30px", height: "30px" }}
                          alt={productDropdown.id}
                        />
                      )}
                    </StyledTableCell>
                    <StyledTableCell>
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="flex-start"
                      >
                        {prd ? (
                          <>
                            <Typography variant="body2">{prd.name}</Typography>
                            {!!prd.attributes && !!prd.attributes.length && (
                              <Typography
                                className={classes.attributeText}
                                variant="body2"
                              >
                                {prd.attributes.join(", ")}
                              </Typography>
                            )}
                          </>
                        ) : (
                          <>
                            <Typography variant="body2">{row.name}</Typography>
                            {!!row.attributes && !!row.attributes.length && (
                              <Typography
                                className={classes.attributeText}
                                variant="body2"
                              >
                                {row.attributes.join(", ")}
                              </Typography>
                            )}
                          </>
                        )}
                      </Box>
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box py={6} className={classes.emptyContainer}>
          <ErrorSVG />
          <Typography variant="body2" style={{ paddingLeft: "4px" }}>
            {getLang(lang, "paragraph.NO_PRODUCT_ADDED")}
          </Typography>
        </Box>
      )}
    </Box>
  );
}
