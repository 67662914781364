import React from "react";
import {
  Box,
  Grid,
  makeStyles
} from "@material-ui/core";
import PercentageBar from "../../percentageBar";

const useStyles = makeStyles(theme => ({
  gridItem: {
    paddingTop: "8px !important",
    paddingBottom: "8px !important",
  }
}))

export default function ScanLocationCountryPanelComponent({ scanData, handleCountryClick }) {
  const classes = useStyles();
  const totalScanCount = scanData
    .map(item => item.scanCount)
    .reduce((prev, next) => prev + next)

  return (
    <Box style={{ paddingTop: 20 }}>
      <Grid container spacing={3} style={{ alignItems: "flex-end" }}>
        {scanData.map((item, index) => (
          <Grid
            item
            onClick={() => handleCountryClick(item.country)}
            key={index}
            xs={6}
            classes={{ item: classes.gridItem }}
          >
            <PercentageBar
              label={item.country}
              percentage={parseFloat(
                ((item.scanCount / totalScanCount) * 100).toFixed(2)
              )}
              count={item.scanCount}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}