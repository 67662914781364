import { getApiLang } from "app/feature/constants";
import { setLoading, addAlert } from "modules/notification";
import {
  getAcceptedStockoutList,
  getCancelledStockoutList,
  getNewStockoutList,
  getRejectedStockoutList,
  getStockoutCustomFieldDropdown,
  getStockoutDetail,
  getStockoutItemList,
  getStockoutReceiverDropdown,
  getStockoutRequestorDropdown,
} from "../action/stockflowStockout.action";

const getNewStockoutListMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getNewStockoutList.pending.type:
        dispatch(
          setLoading({ id: getNewStockoutList.typePrefix, state: true })
        );
        break;
      case getNewStockoutList.fulfilled.type:
        dispatch(
          setLoading({ id: getNewStockoutList.typePrefix, state: false })
        );
        break;
      case getNewStockoutList.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({ id: getNewStockoutList.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_GET_STOCKOUT_LIST"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const getAcceptedStockoutListMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getAcceptedStockoutList.pending.type:
        dispatch(
          setLoading({ id: getAcceptedStockoutList.typePrefix, state: true })
        );
        break;
      case getAcceptedStockoutList.fulfilled.type:
        dispatch(
          setLoading({ id: getAcceptedStockoutList.typePrefix, state: false })
        );
        break;
      case getAcceptedStockoutList.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({ id: getAcceptedStockoutList.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_GET_STOCKOUT_LIST"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const getRejectedStockoutListMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getRejectedStockoutList.pending.type:
        dispatch(
          setLoading({ id: getRejectedStockoutList.typePrefix, state: true })
        );
        break;
      case getRejectedStockoutList.fulfilled.type:
        dispatch(
          setLoading({ id: getRejectedStockoutList.typePrefix, state: false })
        );
        break;
      case getRejectedStockoutList.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({ id: getRejectedStockoutList.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_GET_STOCKOUT_LIST"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const getCancelledStockoutListMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getCancelledStockoutList.pending.type:
        dispatch(
          setLoading({ id: getCancelledStockoutList.typePrefix, state: true })
        );
        break;
      case getCancelledStockoutList.fulfilled.type:
        dispatch(
          setLoading({ id: getCancelledStockoutList.typePrefix, state: false })
        );
        break;
      case getCancelledStockoutList.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({ id: getCancelledStockoutList.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_GET_STOCKOUT_LIST"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const getStockoutDetailMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getStockoutDetail.pending.type:
        dispatch(setLoading({ id: getStockoutDetail.typePrefix, state: true }));
        break;
      case getStockoutDetail.fulfilled.type:
        dispatch(
          setLoading({ id: getStockoutDetail.typePrefix, state: false })
        );
        break;
      case getStockoutDetail.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({ id: getStockoutDetail.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_GET_STOCKOUT_DETAIL"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const getStockoutRequestorDropdownMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getStockoutRequestorDropdown.pending.type:
        dispatch(
          setLoading({
            id: getStockoutRequestorDropdown.typePrefix,
            state: true,
          })
        );
        break;
      case getStockoutRequestorDropdown.fulfilled.type:
        dispatch(
          setLoading({
            id: getStockoutRequestorDropdown.typePrefix,
            state: false,
          })
        );
        break;
      case getStockoutRequestorDropdown.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: getStockoutRequestorDropdown.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

const getStockoutReceiverDropdownMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getStockoutReceiverDropdown.pending.type:
        dispatch(
          setLoading({
            id: getStockoutReceiverDropdown.typePrefix,
            state: true,
          })
        );
        break;
      case getStockoutReceiverDropdown.fulfilled.type:
        dispatch(
          setLoading({
            id: getStockoutReceiverDropdown.typePrefix,
            state: false,
          })
        );
        break;
      case getStockoutReceiverDropdown.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: getStockoutReceiverDropdown.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

const getStockoutItemListMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getStockoutItemList.pending.type:
        dispatch(
          setLoading({ id: getStockoutItemList.typePrefix, state: true })
        );
        break;
      case getStockoutItemList.fulfilled.type:
        dispatch(
          setLoading({ id: getStockoutItemList.typePrefix, state: false })
        );
        break;
      case getStockoutItemList.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({ id: getStockoutItemList.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_GET_STOCKOUT_ITEMS"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const getStockoutCustomFieldDropdownMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getStockoutCustomFieldDropdown.pending.type:
        dispatch(
          setLoading({
            id: getStockoutCustomFieldDropdown.typePrefix,
            state: true,
          })
        );
        break;
      case getStockoutCustomFieldDropdown.fulfilled.type:
        dispatch(
          setLoading({
            id: getStockoutCustomFieldDropdown.typePrefix,
            state: false,
          })
        );
        break;
      case getStockoutCustomFieldDropdown.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: getStockoutCustomFieldDropdown.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

export default [
  getNewStockoutListMiddleware,
  getAcceptedStockoutListMiddleware,
  getRejectedStockoutListMiddleware,
  getCancelledStockoutListMiddleware,
  getStockoutDetailMiddleware,
  getStockoutRequestorDropdownMiddleware,
  getStockoutReceiverDropdownMiddleware,
  getStockoutItemListMiddleware,
  getStockoutCustomFieldDropdownMiddleware,
];
