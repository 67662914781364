import { TableRow, withStyles } from "@material-ui/core";

export default withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#F4F7F8",
    },
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.common.white,
    },
  },
}))(TableRow)