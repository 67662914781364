import { getApiLang } from "app/feature/constants";
import { setLoading, addAlert } from "modules/notification";
import {
  approveHandoff,
  getWarrantyHandoffDetail,
  getWarrantyHandoffList,
  rejectHandoff,
} from "../action";

const getWarrantyHandoffListMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getWarrantyHandoffList.pending.type:
        dispatch(
          setLoading({ id: getWarrantyHandoffList.typePrefix, state: true })
        );
        break;
      case getWarrantyHandoffList.fulfilled.type:
        dispatch(
          setLoading({ id: getWarrantyHandoffList.typePrefix, state: false })
        );
        break;
      case getWarrantyHandoffList.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({ id: getWarrantyHandoffList.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_GET_HANDOFF_LIST"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const getWarrantyHandoffDetailMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getWarrantyHandoffDetail.pending.type:
        dispatch(
          setLoading({ id: getWarrantyHandoffDetail.typePrefix, state: true })
        );
        break;
      case getWarrantyHandoffDetail.fulfilled.type:
        dispatch(
          setLoading({ id: getWarrantyHandoffDetail.typePrefix, state: false })
        );
        break;
      case getWarrantyHandoffDetail.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({ id: getWarrantyHandoffDetail.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_GET_HANDOFF_DETAIL"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const approveHandoffMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case approveHandoff.pending.type:
        dispatch(setLoading({ id: approveHandoff.typePrefix, state: true }));
        break;
      case approveHandoff.fulfilled.type:
        dispatch(
          setLoading({
            id: approveHandoff.typePrefix,
            state: false,
          })
        );
        break;
      case approveHandoff.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: approveHandoff.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

const rejectHandoffMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case rejectHandoff.pending.type:
        dispatch(setLoading({ id: rejectHandoff.typePrefix, state: true }));
        break;
      case rejectHandoff.fulfilled.type:
        dispatch(
          setLoading({
            id: rejectHandoff.typePrefix,
            state: false,
          })
        );
        break;
      case rejectHandoff.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: rejectHandoff.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

export default [
  getWarrantyHandoffListMiddleware,
  getWarrantyHandoffDetailMiddleware,
  approveHandoffMiddleware,
  rejectHandoffMiddleware,
];
