import React from "react";
import {
  Box,
  makeStyles,
  Table,
  TableHead,
  TableContainer,
  TableBody,
  TableRow,
  IconButton,
  Typography,
} from "@material-ui/core";
import { StyledTableCell, StyledTableRow } from "components/table";
import DefaultImg from "assets/img/img-default.png";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles((theme) => ({
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    borderCollapse: "seperate",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
    },
    "& th,td": {
      fontSize: ".75rem",
      borderRight: "1px solid rgba(58, 77, 84, 0.1)",
      padding: theme.spacing(1, 1, 1, 1),
    },
    "& th": {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightBold,
    },
    "& th:last-child, td:last-child": {
      width: 37,
      borderRight: "0px",
    },
    "& th th": {
      borderBottom: "none !important",
    },
    "& td td": {
      borderBottom: "none !important",
    },
  },
  container: {
    border: "1px solid rgba(58, 77, 84, 0.1)",
    maxHeight: "20em",
  },
  attributeText: {
    color: "#b2b2b2",
    fontSize: 12,
    whiteSpace: "break-spaces",
  },
}));

const DustBinIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.4444 16.8C10.6507 16.8 10.8486 16.7157 10.9944 16.5657C11.1403 16.4157 11.2222 16.2122 11.2222 16V11.2C11.2222 10.9878 11.1403 10.7843 10.9944 10.6343C10.8486 10.4843 10.6507 10.4 10.4444 10.4C10.2382 10.4 10.0403 10.4843 9.89447 10.6343C9.74861 10.7843 9.66667 10.9878 9.66667 11.2V16C9.66667 16.2122 9.74861 16.4157 9.89447 16.5657C10.0403 16.7157 10.2382 16.8 10.4444 16.8ZM18.2222 7.2H15.1111V6.4C15.1111 5.76348 14.8653 5.15303 14.4277 4.70294C13.9901 4.25286 13.3966 4 12.7778 4H11.2222C10.6034 4 10.0099 4.25286 9.57231 4.70294C9.13472 5.15303 8.88889 5.76348 8.88889 6.4V7.2H5.77778C5.5715 7.2 5.37367 7.28429 5.22781 7.43431C5.08194 7.58434 5 7.78783 5 8C5 8.21217 5.08194 8.41566 5.22781 8.56569C5.37367 8.71571 5.5715 8.8 5.77778 8.8H6.55556V17.6C6.55556 18.2365 6.80139 18.847 7.23897 19.2971C7.67656 19.7471 8.27005 20 8.88889 20H15.1111C15.7299 20 16.3234 19.7471 16.761 19.2971C17.1986 18.847 17.4444 18.2365 17.4444 17.6V8.8H18.2222C18.4285 8.8 18.6263 8.71571 18.7722 8.56569C18.9181 8.41566 19 8.21217 19 8C19 7.78783 18.9181 7.58434 18.7722 7.43431C18.6263 7.28429 18.4285 7.2 18.2222 7.2ZM10.4444 6.4C10.4444 6.18783 10.5264 5.98434 10.6723 5.83431C10.8181 5.68429 11.0159 5.6 11.2222 5.6H12.7778C12.9841 5.6 13.1819 5.68429 13.3277 5.83431C13.4736 5.98434 13.5556 6.18783 13.5556 6.4V7.2H10.4444V6.4ZM15.8889 17.6C15.8889 17.8122 15.8069 18.0157 15.6611 18.1657C15.5152 18.3157 15.3174 18.4 15.1111 18.4H8.88889C8.68261 18.4 8.48478 18.3157 8.33892 18.1657C8.19306 18.0157 8.11111 17.8122 8.11111 17.6V8.8H15.8889V17.6ZM13.5556 16.8C13.7618 16.8 13.9597 16.7157 14.1055 16.5657C14.2514 16.4157 14.3333 16.2122 14.3333 16V11.2C14.3333 10.9878 14.2514 10.7843 14.1055 10.6343C13.9597 10.4843 13.7618 10.4 13.5556 10.4C13.3493 10.4 13.1514 10.4843 13.0056 10.6343C12.8597 10.7843 12.7778 10.9878 12.7778 11.2V16C12.7778 16.2122 12.8597 16.4157 13.0056 16.5657C13.1514 16.7157 13.3493 16.8 13.5556 16.8Z"
      fill="#FD646F"
    />
  </svg>
);

export default function AssignedProductTable({ rows, handleDelete, lang }) {
  const classes = useStyle();

  return (
    <>
      {rows.length > 0 ? (
        <Box>
          <TableContainer className={classes.container}>
            <Table
              className={classes.table}
              aria-label="Assigned Product Table"
              size="small"
              stickyHeader
            >
              <TableHead style={{ height: "42px" }}>
                <TableRow>
                  <StyledTableCell>
                    {getLang(lang, "label.NUM")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.ASSIGNED_PRODUCT")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.ACTION")}
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => {
                  return (
                    <StyledTableRow key={row.id} hover role="checkbox">
                      <StyledTableCell>{index + 1}.</StyledTableCell>
                      <StyledTableCell>
                        <Box style={{ display: "flex", alignItems: "center" }}>
                          <Box style={{ marginRight: 10 }}>
                            <img
                              src={row.picture ? row.picture : DefaultImg}
                              style={{ width: "30px", height: "30px" }}
                              alt={row.id}
                            />
                          </Box>
                          <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="flex-start"
                          >
                            <Typography variant="body2">{row.name}</Typography>
                            {!!row.attributes && !!row.attributes.length && (
                              <Typography
                                className={classes.attributeText}
                                variant="body2"
                              >
                                {row.attributes.join(", ")}
                              </Typography>
                            )}
                          </Box>
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell>
                        <IconButton onClick={() => handleDelete(row.uuid)}>
                          <DustBinIcon />
                        </IconButton>
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      ) : null}
    </>
  );
}
