import React from "react";
import {
  makeStyles,
  Button,
  Box,
  MenuItem,
  Divider,
  Typography
} from "@material-ui/core";
import { TYPES } from "./marketplaceForm.container";
import clsx from "clsx";
import { ModalFooter, ContentWrapper } from "../../modal";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import MarketplaceTab from "../../tab/marketplaceTab";
import Input from "components/input/inputTextField";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles(theme => ({
  secondaryButton: {
    color: theme.palette.common.white
  },
  carousel: {
    "& li": {
      minWidth: "24% !important"
    }
  },
  slideFirst4: {
    "& ul": {
      transform: "translate3d(0px, 0px, 0px) !important"
    }
  },
  field: {
    display: "grid",
    gridTemplateColumns: "140px 1fr",
    columnGap: theme.spacing(1)
  }
}));

export default function AuthFormComponent({
  type,
  handleSubmit,
  handleDeleteMarketplaceItem,
  handleAddMarketplace,
  handleDelete,
  order,
  activeIndex,
  handleChangeTab,
  marketplaces,
  isEditing,
  marketplaceLabel,
  marketplaceUrl,
  selectedMarketplace,
  handleChangeMarketplaceLabel,
  handleChangeMarketplaceUrl,
  handleChangeMarketplace,
  MARKETPLACES,
  isMarketplaceUrlValid,
  handleClose,
  lang
}) {
  const classes = useStyle();

  const isAlteringImage = order[activeIndex] !== "placeholder";

  return (
    <Box mb={type === TYPES[2] ? 2 : 0}>
      <ContentWrapper disablePadding={true}>
        <Box px={2}>
          <Carousel
            className={clsx(classes.carousel, {
              [classes.slideFirst4]: activeIndex <= 3
            })}
            showThumbs={false}
            showIndicators={false}
            showStatus={false}
            swipeable={true}
            centerMode={true}
            centerSlidePercentage={24}
            selectedItem={activeIndex}
            onChange={handleChangeTab}
            onClickItem={handleChangeTab}
          >
            {order.map((id, index) => {
              const isPlaceholder = id === "placeholder";
              const iconSrc = isPlaceholder ? "" : marketplaces[id].iconSrc;
              const label = isPlaceholder
                ? getLang(lang,"label.MARKETPLACE")
                : marketplaces[id].label;

              return (
                <MarketplaceTab
                  key={id}
                  isEditing={isEditing}
                  imageSrc={iconSrc}
                  selected={activeIndex === index}
                  handleDelete={() => handleDeleteMarketplaceItem(id)}
                  label={label}
                />
              );
            })}
          </Carousel>
        </Box>

        <Divider />
          <Box>
            <Box p={2}>
              <Box mb={1} className={classes.field}>
                <Box display="flex" alignItems="center">
                  <Typography variant="body2" color="textSecondary">
                    {getLang(lang,"label.MARKETPLACE")} <Typography style={{ color: "#DE350B", display: 'inline-block' }}>*</Typography>
                  </Typography>
                </Box>
                <Input
                  required
                  hiddenLabel
                  variant="filled"
                  size="small"
                  margin="none"
                  placeholder={getLang(lang,"placeholder.SELECT_MARKETPLACE")}
                  fullWidth
                  select={true}
                  value={selectedMarketplace}
                  onChange={handleChangeMarketplace}
                >
                  {MARKETPLACES.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Input>
              </Box>
              <Box mb={1} className={classes.field}>
                <Box display="flex" alignItems="center">
                  <Typography variant="body2" color="textSecondary">
                    {getLang(lang,"label.LABEL")} <Typography style={{ color: "#DE350B", display: 'inline-block' }}>*</Typography>
                  </Typography>
                </Box>
                <Input
                  required
                  hiddenLabel
                  variant="filled"
                  size="small"
                  margin="none"
                  placeholder={getLang(lang,"label.MARKETPLACE_LABEL")}
                  fullWidth
                  value={marketplaceLabel}
                  onChange={handleChangeMarketplaceLabel}
                />
              </Box>

              <Box className={classes.field}>
                <Box display="flex" alignItems="center">
                  <Typography variant="body2" color="textSecondary">
                    {getLang(lang,"label.MARKETPLACE_URL")} <Typography style={{ color: "#DE350B", display: 'inline-block' }}>*</Typography>
                  </Typography>
                </Box>
                <Input
                  required
                  hiddenLabel
                  variant="filled"
                  size="small"
                  margin="none"
                  placeholder={getLang(lang,"placeholder.ENTER_URL_HERE")}
                  fullWidth
                  value={marketplaceUrl}
                  onChange={handleChangeMarketplaceUrl}
                  error={!isMarketplaceUrlValid}
                  helperText={isMarketplaceUrlValid ? "" : "Invalid URL"}
                />
              </Box>
            </Box>
            <Divider />
            <Box p={2} display="flex" alignItems="center">
              <Typography
                variant="caption"
                color="textSecondary"
                style={{ paddingRight: "16px" }}
              >
               <Typography style={{ color: "#DE350B", display: 'inline-block' }}>*</Typography>  {getLang(lang,"label.REQUIRED_FIELD")}
              </Typography>
              {
                !isAlteringImage ?
                (
                  <Button
                    style={{ marginLeft: "auto" }}
                    variant="contained"
                    color="secondary"
                    size="small"
                    classes={{ root: classes.secondaryButton }}
                    disableElevation
                    onClick={handleAddMarketplace}
                    disabled={
                      !isMarketplaceUrlValid ||
                      selectedMarketplace === "" ||
                      marketplaceLabel === "" ||
                      marketplaceUrl === ""
                    }
                  >
                    {getLang(lang,"label.ADD")}
                  </Button>
                ) : (
                  ""
                )
              }
            </Box>
          </Box>
      </ContentWrapper>
      <ModalFooter
        handleDelete={handleDelete}
        showDelete={type !== TYPES[0]}
        handleDisable={order.length === 1}
        handleClick={handleSubmit}
        handleClose={handleClose}
      />
    </Box>
  );
}
