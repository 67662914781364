import React from "react";
import { Box } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

export const SkeletonSettingComponent = () => (
  <Box style={{ display: "flex", justifyContent: "center" }} width={"100%"}>
    <Box pt={3} pb={5} width={"80%"}>
      <Skeleton width={"100%"} height={50} />
      <Skeleton width={"100%"} height={50} />
      <Skeleton width={"100%"} height={50} />
      <Skeleton width={"100%"} height={50} />
      <Skeleton width={"100%"} height={50} />
      <Skeleton width={"100%"} height={50} />
    </Box>
  </Box>
);
