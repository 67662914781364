import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FilterPanel from './filterPanel.component';

export default function FilterPanelContainer({
    filter,
    currentFilter,
    drilldown = false,
    pageMode,
    translate
}){
    const dispatch = useDispatch();
    const lang = useSelector(state => state.constant.languages)

    useEffect(() => {
        filter.leftSide.forEach(e => {
            if(e.dispatch)
                dispatch(e.dispatch)
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, currentFilter])

    return <FilterPanel
        filter={filter}
        drilldown={drilldown}
        currentFilter={currentFilter}
        pageMode={pageMode}
        lang={lang}
    />
}