import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  Button,
  makeStyles,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Checkbox,
  IconButton,
  Chip,
  Tooltip,
} from "@material-ui/core";
import {
  StyledTableCell,
  StyledTableRow,
  StickyRightTableCell,
  StickyLeftTableCell,
} from "components/table";
import PropTypes from "prop-types";
import AntSwitch from "components/switch/switch";
import { SkeletonTableRowStickyLeftRight } from "components/skeleton/skeleton.component";
import { ActionButton } from "components/button";
import { TablePaginationActions } from "components/tablePagination";
import RefreshTable from "components/table/refreshTable";
import SelectAllCheckBox from "components/selectAllCheckbox";
import { Close as CloseIcon, Check as CheckIcon } from "@material-ui/icons";
import clsx from "clsx";
import Input from "components/input/inputTextField";
import SearchableSelect from "components/select/searchableSelect";
import AttributeSelect from "../../select/attributeSelect";
import BrandModelSingleSelect from "../../select/brandModelSingleSelect";
import { isEmpty } from "lib/helper";
import DefaultImg from "assets/img/img-default.png";
import CategorySelect from "../../select/categorySelect";
import BranchSelect from "modules/stockflow/components/select/branchSelect";
import { getLang } from "app/feature/constants";
import BrandImage from "../../icon/brandImage";
import { addAlert } from 'modules/notification';

const useStyle = makeStyles((theme) => ({
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    borderCollapse: "separate",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
    },
    "& th,td": {
      fontSize: ".75rem",
      borderRight: "1px solid rgba(58, 77, 84, 0.1)",
      padding: theme.spacing(1, 2, 1, 2),
    },
    "& th": {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightBold,
    },
    "& th:last-child, td:last-child": {
      width: 37,
      borderRight: "0px",
    },
    "& th th": {
      borderBottom: "none !important",
    },
    "& td td": {
      borderBottom: "none !important",
    },
  },
  tableContainer: {
    maxHeight: 500,
    overflowY: "auto",
  },
  emptyContainer: {
    textAlign: "center",
  },
  button: {
    display: "flex",
    alignItems: "center",
    padding: 0,
    minWidth: 0,
    marginLeft: theme.spacing(1),
  },
  productPhoto: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #E4E7EC",
    width: "50px",
    height: "50px",
    "& img": {
      height: "50px",
      width: "50px",
    },
  },
  hidden: {
    display: "none",
  },
  show: {
    display: "flex",
  },
  actionContainer: {
    justifyContent: "center",
  },
  paperRoot: {
    width: "300px",
  },
  selectAll: {
    "&.Mui-checked": {
      color: "#FDB022",
    },
  },
  selectOne: {
    "&.Mui-checked": {
      color: "#6AAF68",
    },
  },
  tooltip: {
    backgroundColor: "#FFFFFF",
    fontSize: "0.75rem",
    color: "#3A4D54",
    minHeight: "40px",
    boxShadow:
      "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
    padding: "8px",
  },
}));

export default function ProductListTableComponent({
  hasProductEditAccessRight,
  hasProductAssignAccessRight,
  hasProductAddAccessRight,
  hasProductDeleteAccessRight,
  rows,
  isFetching,
  isSelectAll,
  selectedIds,
  isError,
  handleReload,
  page,
  rowsPerPage,
  paginationOptions,
  handleChangePage,
  handleChangeRowPerPage,
  isSearchResult,
  handleEdit,
  handleAssignSerialNumber,
  handleStatusChange,
  handleCreate,
  totalFiltered,
  handleSelectAvailable,
  handleSelectAll,
  handleDeselectAll,
  handleSelectProduct,
  isQuickEdit,
  handleQuickEdit,
  categoryDropdown,
  brandModelDropdown,
  attributeDropdown,
  currencyDropdown,
  handleSubmitQuickEdit,
  handleDeleteClick,
  lang,
  handleBrandModelSearch,
  handleAttributeSearch,
}) {
  const dispatch = useDispatch();
  const classes = useStyle();

  function CustomTooltip(props) {
    return (
      <Tooltip
        classes={{ tooltip: classes.tooltip }}
        title={
          <React.Fragment>
            {props.title.split("\n").map((item, key) => {
              return (
                <span key={key}>
                  {item}
                  <br />
                </span>
              );
            })}
          </React.Fragment>
        }
      >
        {props.children}
      </Tooltip>
    );
  }

  CustomTooltip.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.element.isRequired,
  };

  const selectAllItems = [
    {
      label: getLang(lang, "paragraph.SELECT_AVAILABLE_ITEM", {
        count: rows.length > rowsPerPage ? rowsPerPage : rows.length,
      }),
      action: () => handleSelectAvailable(),
    },
    {
      label: getLang(lang, "paragraph.SELECT_ALL_PRODUCTS"),
      action: () => handleSelectAll(),
    },
    {
      label: getLang(lang, "paragraph.DESELECT_ALL_PRODUCTS"),
      action: () => handleDeselectAll(),
      divider: true,
    },
  ];

  const [product, setProduct] = useState({
    id: "",
    name: "",
    category: {},
    brand: {},
    model: {},
    attribute: [],
    nodes: [],
    sku: "",
    currencyCode: "",
    currencyExp: 2,
    unitPrice: 0,
    unitPriceFormatted: "",
    status: "",
    scanLimit: 0,
    incentivePoint: 0,
  });

  const displayBrandModel = (brand, model) => {
    if (isEmpty(brand) && isEmpty(model)) {
      return "-";
    }

    if (brand.id === "-1" && model.id === "-1") {
      return "-";
    }

    if (model.id === "-1") {
      return (
        <Box display="flex" alignItems="center">
          <Box pr={1}>
            <BrandImage url={brand.image_url} imageSize="40px" iconSize={22} />
          </Box>
          <Box>{brand.name}</Box>
        </Box>
      );
    }

    if (brand.id === "-1") {
      return (
        <Box display="flex" alignItems="center">
          <Box pr={1}>
            <BrandImage url={brand.image_url} imageSize="40px" iconSize={22} />
          </Box>
          <Box>{model.name}</Box>
        </Box>
      );
    }

    return (
      <Box display="flex" alignItems="center">
        <Box pr={1}>
          <BrandImage url={brand.image_url} imageSize="40px" iconSize={22} />
        </Box>
        <Box>{brand.name + ", " + model.name}</Box>
      </Box>
    );
  };

  return (
    <>
      {!isFetching && isError && <RefreshTable handleReload={handleReload} />}
      {isFetching || rows.length > 0 ? (
        <Box>
          <TableContainer>
            <Table
              className={classes.table}
              aria-label="product table"
              size="small"
              stickyHeader
            >
              <TableHead style={{ height: "45px" }}>
                <TableRow>
                  <StickyLeftTableCell>
                    <StyledTableCell padding="checkbox">
                      <SelectAllCheckBox
                        actionItems={selectAllItems}
                        disabled={isFetching || isQuickEdit}
                        isSelectAll={isSelectAll}
                        selectedIdLength={selectedIds.length}
                        totalFiltered={totalFiltered}
                      />
                    </StyledTableCell>
                  </StickyLeftTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.IMAGE")}
                  </StyledTableCell>
                  <StyledTableCell style={{ minWidth: "22rem" }}>
                    {getLang(lang, "label.PRODUCT_NAME")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.ASSIGNED_SERIAL_NUMBER")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.ADDITIONAL_INFO")}
                  </StyledTableCell>
                  <StyledTableCell style={{ minWidth: "12rem" }}>
                    {getLang(lang, "label.CATEGORY")}
                  </StyledTableCell>
                  <StyledTableCell style={{ minWidth: "14rem" }}>
                    {getLang(lang, "label.BRAND_MODEL")}
                  </StyledTableCell>
                  <StyledTableCell style={{ minWidth: "16rem" }}>
                    {getLang(lang, "label.ATTRIBUTES")}
                  </StyledTableCell>
                  <StyledTableCell style={{ minWidth: "14rem" }}>
                    {getLang(lang, "label.BRANCH")}
                  </StyledTableCell>
                  <StyledTableCell style={{ minWidth: "10rem" }}>
                    {getLang(lang, "label.SKU")}
                  </StyledTableCell>
                  <StyledTableCell style={{ textAlign: "right" }}>
                    {getLang(lang, "label.PRICE")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.SCAN_LIMIT")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.INCENTIVE_POINT")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.WARRANTY_STATUS")}
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      width: "35px",
                      borderRight: "",
                      textAlign: "center",
                    }}
                  >
                    {getLang(lang, "label.STATUS")}
                  </StyledTableCell>
                  <StickyRightTableCell>
                    <StyledTableCell
                      style={{ width: "67px", textAlign: "center" }}
                    >
                      {getLang(lang, "label.ACTION")}
                    </StyledTableCell>
                  </StickyRightTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isFetching
                  ? [...new Array(5)].map((v, index) => (
                      <SkeletonTableRowStickyLeftRight
                        key={index}
                        columnCount={16}
                      />
                    ))
                  : rows.map((row, index) => {
                      const actionItems = [];

                      actionItems.push({
                        label: getLang(lang, "label.VIEW_EDIT"),
                        action: () => handleEdit(row.id),
                      });

                      if (hasProductEditAccessRight) {
                        actionItems.push({
                          label: getLang(lang, "label.QUICK_EDIT"),
                          action: () => {
                            handleQuickEdit(index, true, row);
                            setProduct({
                              id: row.id,
                              name: row.name,
                              category: row.category,
                              brand: row.brand,
                              model: row.model,
                              attribute: row.attribute,
                              nodes: row.nodes,
                              sku: row.sku,
                              currencyCode: row.currencyCode,
                              currencyExp: row.currencyExp,
                              unitPrice: Number(row.unitPrice).toFixed(
                                row.currencyExp
                              ),
                              unitPriceFormatted: Number(
                                row.unitPrice
                              ).toLocaleString(undefined, {
                                maximumFractionDigits: row.currencyExp,
                              }),
                              status: row.status,
                              scanLimit: row.scanLimit,
                              incentivePoint: row.incentivePoint,
                            });
                          },
                        });
                      }

                      if (hasProductAssignAccessRight) {
                        actionItems.push({
                          label: getLang(lang, "label.ASSIGN_SERIAL_NUMBER"),
                          disabled: row.status === "unpublished",
                          action: () => {
                            let temp = [];
                            row.nodes.forEach((item, index) => {
                              if (!item.exclude) {
                                temp.push({
                                  uuid: item.id,
                                  name: item.name,
                                  searchName: item.name.toLowerCase(),
                                  index: index,
                                });
                              }
                            });
                            handleAssignSerialNumber({
                              product: row,
                              branchList: temp,
                            });
                          },
                        });
                      }

                      actionItems.push({
                        label:
                          row.status === "published"
                            ? getLang(lang, "label.UNPUBLISH")
                            : getLang(lang, "label.PUBLISH"),
                        disabled: !hasProductEditAccessRight,
                        action: () => {
                          let checked =
                            row.status === "unpublished" ? true : false;
                          handleStatusChange(row.id, checked);
                        },
                        divider: true,
                        errorText: true,
                      });

                      if (hasProductDeleteAccessRight) {
                        actionItems.push({
                          label: "Delete",
                          action: () => handleDeleteClick({ id: row.id }),
                          divider: true,
                          errorText: true,
                          disabled: row.isAssignedSN,
                          tooltip: row.isAssignedSN
                            ? "Deleting product with assigned Serial Number is not allowed."
                            : null,
                        });
                      }

                      return (
                        <StyledTableRow
                          key={row.id}
                          hover
                          role="checkbox"
                          tabIndex={-1}
                        >
                          <StickyLeftTableCell>
                            <StyledTableCell padding="checkbox">
                              <Checkbox
                                className={clsx({
                                  [classes.selectAll]: isSelectAll,
                                  [classes.selectOne]:
                                    row.isSelected && isSelectAll === false,
                                })}
                                disabled={isQuickEdit}
                                checked={row.isSelected}
                                onChange={(event) =>
                                  handleSelectProduct(
                                    index,
                                    event.target.checked
                                  )
                                }
                              />
                            </StyledTableCell>
                          </StickyLeftTableCell>
                          <StyledTableCell style={{ width: "55px" }}>
                            <Box className={classes.productPhoto}>
                              {row.picture === "" ? (
                                <img src={DefaultImg} alt="emptyProduct" />
                              ) : (
                                <img src={row.picture} alt={row.name} />
                              )}
                            </Box>
                          </StyledTableCell>
                          <StyledTableCell>
                            {row.isEdit ? (
                              <Input
                                hiddenLabel
                                variant="filled"
                                size="small"
                                placeholder={getLang(
                                  lang,
                                  "placeholder.PRODUCT_NAME"
                                )}
                                margin="none"
                                InputProps={{
                                  disableUnderline: true,
                                  margin: "none",
                                  style: {
                                    borderRadius: "4px",
                                    backgroundColor: "#fff",
                                    border: "thin solid #eceff0",
                                    fontSize: "0.75rem",
                                  },
                                }}
                                style={{ width: "100%" }}
                                value={product.name}
                                onChange={(event) => {
                                  event.persist();
                                  setProduct((prev) => ({
                                    ...prev,
                                    name: event.target.value,
                                  }));
                                }}
                              />
                            ) : (
                              <Box
                                style={{
                                  whiteSpace: "pre-wrap",
                                  overflowWrap: "break-word",
                                }}
                              >
                                {row.name}
                              </Box>
                            )}
                          </StyledTableCell>
                          <StyledTableCell>
                            {row.totalAssigned?.toLocaleString("en-US") || 0}
                          </StyledTableCell>
                          <StyledTableCell>
                            {row.totalAdtInfo?.toLocaleString("en-US") || 0}
                          </StyledTableCell>
                          <StyledTableCell>
                            {row.isEdit ? (
                              <CategorySelect
                                value={product.category}
                                searchable={true}
                                placeholder={
                                  categoryDropdown.length === 0
                                    ? getLang(
                                        lang,
                                        "placeholder.NO_CATEGORY_CREATED"
                                      )
                                    : getLang(lang, "placeholder.CATEGORY")
                                }
                                style={{
                                  height: "28.25px",
                                  backgroundColor: "#fff",
                                  border: "thin solid #eceff0",
                                }}
                                textStyle={{ fontSize: "0.75rem" }}
                                PaperProps={{
                                  classes: { root: classes.paperRoot },
                                }}
                                dropdownItem={categoryDropdown}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "left",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "left",
                                }}
                                isEmpty={!product.categoryDropdown}
                                onCategoryChange={(value) => {
                                  setProduct((prev) => ({
                                    ...prev,
                                    category: value,
                                  }));
                                }}
                                handleClear={() => {
                                  setProduct((prev) => ({
                                    ...prev,
                                    category: [],
                                  }));
                                }}
                              />
                            ) : (
                              <span>
                                {row.category === null
                                  ? "-"
                                  : row.category?.name}
                              </span>
                            )}
                          </StyledTableCell>
                          <StyledTableCell>
                            {row.isEdit ? (
                              <BrandModelSingleSelect
                                searchable={true}
                                brandValue={product.brand}
                                modelValue={product.model}
                                isClearable={true}
                                onChange={(brand, model) => {
                                  setProduct((prev) => ({
                                    ...prev,
                                    brand,
                                    model,
                                  }));
                                }}
                                handleClear={() => {
                                  setProduct((prev) => ({
                                    ...prev,
                                    brand: { id: "-1", name: "" },
                                    model: { id: "-1", name: "" },
                                  }));
                                }}
                                placeholder={
                                  brandModelDropdown.length === 0
                                    ? getLang(
                                        lang,
                                        "placeholder.NO_BRAND_MODEL_CREATED"
                                      )
                                    : getLang(lang, "placeholder.BRAND_MODEL")
                                }
                                style={{
                                  height: "28.25px",
                                  backgroundColor: "#fff",
                                  border: "thin solid #eceff0",
                                }}
                                textStyle={{ fontSize: "0.75rem" }}
                                PaperProps={{
                                  classes: { root: classes.paperRoot },
                                }}
                                dropdownItem={brandModelDropdown}
                                emptyMessage={getLang(
                                  lang,
                                  "paragraph.BRAND_MODEL_NOT_FOUND"
                                )}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "left",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "left",
                                }}
                                isEmpty={
                                  product.brand.id === "-1" &&
                                  product.model.id === "-1"
                                }
                                handleSearch={handleBrandModelSearch}
                              />
                            ) : (
                              <span>
                                {displayBrandModel(row.brand, row.model)}
                              </span>
                            )}
                          </StyledTableCell>
                          <StyledTableCell>
                            {row.isEdit ? (
                              <AttributeSelect
                                value={product.attribute}
                                isClearable={true}
                                placeholder={
                                  attributeDropdown.length === 0
                                    ? getLang(
                                        lang,
                                        "placeholder.NO_ATTRIBUTE_CREATED"
                                      )
                                    : getLang(lang, "placeholder.ATTRIBUTE")
                                }
                                style={{
                                  backgroundColor: "#fff",
                                  border: "thin solid #eceff0",
                                }}
                                textStyle={{ fontSize: "0.75rem" }}
                                PaperProps={{
                                  classes: { root: classes.paperRoot },
                                }}
                                dropdownItem={attributeDropdown}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "left",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "left",
                                }}
                                isEmpty={product.attribute.length === 0}
                                onChange={(value) => {
                                  let temp = [...product.attribute];
                                  const index = temp.findIndex(
                                    (item) => item.id === value.id
                                  );
                                  let attribute = { ...temp[index] };

                                  if (value.delete) {
                                    const filteredAttributes = temp.filter(
                                      (item) => item.id !== value.id
                                    );
                                    temp = [...filteredAttributes];
                                  } else if (index === -1) {
                                    temp.push(value);
                                  } else {
                                    attribute.options = value.options;
                                    attribute.optionsId = value.optionsId;
                                    temp[index] = attribute;
                                  }

                                  setProduct((prev) => ({
                                    ...prev,
                                    attribute: temp,
                                  }));
                                }}
                                handleClear={() => {
                                  setProduct((prev) => ({
                                    ...prev,
                                    attribute: [],
                                  }));
                                }}
                                handleChipDelete={(attribute) => {
                                  let temp = [...product.attribute];
                                  if (temp.length > 0) {
                                    const filteredAttributes = temp.filter(
                                      (item) => item.id !== attribute.id
                                    );
                                    temp = [...filteredAttributes];
                                  }
                                  setProduct((prev) => ({
                                    ...prev,
                                    attribute: temp,
                                  }));
                                }}
                                searchable={true}
                                handleItemSearch={handleAttributeSearch}
                              />
                            ) : (
                              <Box
                                style={{ display: "flex", flexWrap: "wrap" }}
                              >
                                {row.attribute.length !== 0 ? (
                                  row.attribute.map((attribute, index) => (
                                    <>
                                      <Box
                                        key={index}
                                        style={{
                                          marginRight: "4px",
                                          marginBottom: "4px",
                                        }}
                                      >
                                        <Chip
                                          key={index}
                                          label={
                                            attribute.name +
                                            ": " +
                                            attribute.options
                                          }
                                          size="small"
                                          style={{
                                            backgroundColor: "#E4E7EC",
                                            fontSize: "0.75rem",
                                          }}
                                        />
                                      </Box>
                                    </>
                                  ))
                                ) : (
                                  <span>-</span>
                                )}
                              </Box>
                            )}
                          </StyledTableCell>
                          <StyledTableCell>
                            {row.isEdit ? (
                              <BranchSelect
                                isProductQuickSelecting={true}
                                productQuickSelectOriginalValue={product.nodes.map(
                                  (v) => v.id
                                )}
                                value={product.nodes}
                                initialValue={row.nodes}
                                styles={{
                                  backgroundColor: "#fff",
                                  border: "thin solid #eceff0",
                                }}
                                textStyles={{ fontSize: "0.75rem" }}
                                PaperProps={{
                                  classes: { root: classes.paperRoot },
                                }}
                                handleChange={(value) => {
                                  setProduct((prev) => ({
                                    ...prev,
                                    nodes: value,
                                  }));
                                }}
                                searchable={true}
                              />
                            ) : (
                              <Box
                                style={{ display: "flex", flexWrap: "wrap" }}
                              >
                                {row.nodes.slice(0, 3).map((node, index) => (
                                  <Box
                                    style={{
                                      marginRight: "4px",
                                      marginBottom: "4px",
                                    }}
                                    key={index}
                                  >
                                    <Chip
                                      key={index}
                                      label={node.name}
                                      size="small"
                                      style={{
                                        backgroundColor: "#E4E7EC",
                                        fontSize: "0.75rem",
                                      }}
                                    />
                                  </Box>
                                ))}
                                {row.nodes.length > 3 && (
                                  <CustomTooltip
                                    title={row.nodes
                                      .slice(3)
                                      .map((node) => node.name)
                                      .join("\n")}
                                    classes={{ tooltip: classes.tooltip }}
                                  >
                                    <Chip
                                      label={`+${row.nodes.length - 3}`}
                                      size="small"
                                      style={{
                                        backgroundColor: "#E4E7EC",
                                        fontSize: "0.75rem",
                                      }}
                                    />
                                  </CustomTooltip>
                                )}
                              </Box>
                            )}
                          </StyledTableCell>
                          <StyledTableCell>
                            {row.isEdit ? (
                              <Input
                                hiddenLabel
                                variant="filled"
                                size="small"
                                placeholder={getLang(lang, "placeholder.SKU")}
                                margin="none"
                                InputProps={{
                                  disableUnderline: true,
                                  margin: "none",
                                  style: {
                                    borderRadius: "4px",
                                    backgroundColor: "#fff",
                                    border: "thin solid #eceff0",
                                    fontSize: "0.75rem",
                                  },
                                }}
                                style={{ width: "100%" }}
                                value={product.sku}
                                onChange={(event) => {
                                  event.persist();
                                  setProduct((prev) => ({
                                    ...prev,
                                    sku: event.target.value,
                                  }));
                                }}
                              />
                            ) : (
                              <span>{row.sku === null ? "-" : row.sku}</span>
                            )}
                          </StyledTableCell>
                          <StyledTableCell>
                            {row.isEdit ? (
                              <Box style={{ display: "flex" }}>
                                <SearchableSelect
                                  value={product.currencyCode}
                                  handleChange={(value) => {
                                    setProduct((prev) => ({
                                      ...prev,
                                      currencyCode: value.alpha3,
                                      currencyExp: value.exp,
                                      unitPrice: Number(
                                        product.unitPrice
                                      ).toFixed(value.exp),
                                    }));
                                  }}
                                  placeholder={getLang(
                                    lang,
                                    "placeholder.CURRENCY"
                                  )}
                                  searchable={false}
                                  style={{
                                    height: "28.25px",
                                    marginRight: "8px",
                                    width: "130px",
                                    backgroundColor: "#fff",
                                    border: "thin solid #eceff0",
                                  }}
                                  textStyle={{ fontSize: "0.75rem" }}
                                  PaperProps={{
                                    classes: { root: classes.paperRoot },
                                  }}
                                  dropdownItem={currencyDropdown}
                                  emptyMessage={getLang(
                                    lang,
                                    "paragraph.NO_CURRENCY_CODE"
                                  )}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                  }}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                  }}
                                  isEmpty={product.currencyCode === ""}
                                  returnType="currencyCode"
                                />
                                <Input
                                  hiddenLabel
                                  variant="filled"
                                  size="small"
                                  placeholder={getLang(
                                    lang,
                                    "placeholder.UNIT_PRICE"
                                  )}
                                  margin="none"
                                  InputProps={{
                                    disableUnderline: true,
                                    margin: "none",
                                    style: {
                                      borderRadius: "4px",
                                      backgroundColor: "#fff",
                                      border: "thin solid #eceff0",
                                      fontSize: "0.75rem",
                                    },
                                  }}
                                  style={{ width: "100%" }}
                                  value={product.unitPrice}
                                  onChange={(e) => {
                                    let regex = new RegExp(
                                      "([0-9]*[.|,]{0,1}[0-9]{0," +
                                        product.currencyExp +
                                        "})",
                                      "s"
                                    );
                                    let value = e.target.value.match(regex)[0];

                                    setProduct((prev) => ({
                                      ...prev,
                                      unitPrice: value,
                                    }));
                                  }}
                                />
                              </Box>
                            ) : (
                              <Box style={{ textAlign: "right" }}>
                                <span>
                                  {row.currencyCode === null
                                    ? "-"
                                    : row.currencyCode +
                                      " " +
                                      row.unitPriceFormatted}
                                </span>
                              </Box>
                            )}
                          </StyledTableCell>
                          <StyledTableCell>
                            {row.isEdit ? (
                              <Input
                                hiddenLabel
                                type="number"
                                variant="filled"
                                size="small"
                                placeholder={getLang(
                                  lang,
                                  "placeholder.SCAN_LIMIT"
                                )}
                                margin="none"
                                InputProps={{
                                  disableUnderline: true,
                                  margin: "none",
                                  style: {
                                    borderRadius: "4px",
                                    backgroundColor: "#fff",
                                    border: "thin solid #eceff0",
                                    fontSize: "0.75rem",
                                  },
                                }}
                                style={{ width: "100%" }}
                                value={product.scanLimit}
                                onKeyDown={(e) => {
                                  const invalidChars = ["-", "+", "e", "."];
                                  if (invalidChars.includes(e.key)) {
                                    e.preventDefault();
                                  }
                                }}
                                onChange={(event) => {
                                  event.persist();
                                  const value = event.target.value;
                                  if (value > 1_000_000) {
                                    event.preventDefault();
                                    dispatch(
                                      addAlert({
                                        severity: "warning",
                                        message: getLang(lang, "message.warning.SCAN_LIMIT_EXCEEDED"),
                                      })
                                    );
                                    setProduct((prev) => ({
                                      ...prev,
                                      scanLimit: 1_000_000,
                                    }));
                                    return;
                                  }
                                  setProduct((prev) => ({
                                    ...prev,
                                    scanLimit: event.target.value,
                                  }));
                                }}
                              />
                            ) : (
                              <Box style={{ textAlign: "right" }}>
                                {row.scanLimit.toLocaleString("en-US")}
                              </Box>
                            )}
                          </StyledTableCell>
                          <StyledTableCell>
                            {row.isEdit ? (
                              <Input
                                hiddenLabel
                                type="number"
                                variant="filled"
                                size="small"
                                placeholder={getLang(
                                  lang,
                                  "placeholder.INCENTIVE_POINT"
                                )}
                                margin="none"
                                InputProps={{
                                  disableUnderline: true,
                                  margin: "none",
                                  style: {
                                    borderRadius: "4px",
                                    backgroundColor: "#fff",
                                    border: "thin solid #eceff0",
                                    fontSize: "0.75rem",
                                  },
                                  defaultValue: 0,
                                  inputProps: {
                                    min: 0,
                                    max: 9999999,
                                    style: {
                                      padding: "7px",
                                    },
                                  },
                                }}
                                style={{ width: "100%" }}
                                value={product.incentivePoint}
                                onKeyDown={(e) => {
                                  const invalidChars = ["-", "+", "e", "."];
                                  if (invalidChars.includes(e.key)) {
                                    e.preventDefault();
                                  }
                                }}
                                onChange={(event) => {
                                  event.persist();
                                  if (event.target.value > 9999999) {
                                    event.preventDefault();
                                  } else {
                                    setProduct((prev) => ({
                                      ...prev,
                                      incentivePoint: event.target.value,
                                    }));
                                  }
                                }}
                              />
                            ) : (
                              <Box style={{ textAlign: "right" }}>
                                {row.incentivePoint.toLocaleString("en-US")}
                              </Box>
                            )}
                          </StyledTableCell>
                          <StyledTableCell>
                            <Box
                              style={{
                                textAlign: "right",
                                textTransform: "capitalize",
                                color:
                                  row.warrantyStatus === "active"
                                    ? "green"
                                    : "grey",
                              }}
                            >
                              {row.warrantyStatus}
                            </Box>
                          </StyledTableCell>
                          <StyledTableCell style={{ width: "35px" }}>
                            {row.isEdit ? (
                              <AntSwitch
                                disabled={!hasProductEditAccessRight}
                                checked={
                                  product.status === "published" ? true : false
                                }
                                onChange={(event) => {
                                  setProduct((prev) => ({
                                    ...prev,
                                    status: event.target.checked
                                      ? "published"
                                      : "unpublished",
                                  }));
                                }}
                              />
                            ) : (
                              <AntSwitch
                                disabled={!hasProductEditAccessRight}
                                checked={
                                  row.status === "published" ? true : false
                                }
                                onChange={(event) =>
                                  handleStatusChange(
                                    row.id,
                                    event.target.checked
                                  )
                                }
                              />
                            )}
                          </StyledTableCell>
                          <StickyRightTableCell>
                            <StyledTableCell style={{ width: "67px" }}>
                              {row.isEdit ? (
                                <div>
                                  <IconButton
                                    style={{
                                      borderRadius: 0,
                                      padding: "6px",
                                      backgroundColor: "#fff",
                                      border: "1px solid #d7d7d7",
                                    }}
                                    onClick={() =>
                                      handleSubmitQuickEdit(product)
                                    }
                                  >
                                    <CheckIcon
                                      style={{
                                        fontSize: "1.3rem",
                                        color: "#32D583",
                                      }}
                                    />
                                  </IconButton>
                                  <IconButton
                                    style={{
                                      borderRadius: 0,
                                      padding: "6px",
                                      backgroundColor: "#fff",
                                      border: "1px solid #d7d7d7",
                                    }}
                                    onClick={() =>
                                      handleQuickEdit(index, false, row)
                                    }
                                  >
                                    <CloseIcon style={{ fontSize: "1.3rem" }} />
                                  </IconButton>
                                </div>
                              ) : (
                                <div
                                  className={clsx(classes.actionContainer, {
                                    [classes.hidden]: isQuickEdit,
                                    [classes.show]: !isQuickEdit,
                                  })}
                                >
                                  <ActionButton
                                    actionItems={actionItems}
                                    popperStyle={{ zIndex: 999 }}
                                  />
                                </div>
                              )}
                            </StyledTableCell>
                          </StickyRightTableCell>
                        </StyledTableRow>
                      );
                    })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            style={{
              backgroundColor: "#fff",
              boxShadow: "0px -1px 0px rgba(58, 77, 84, 0.15)",
            }}
            ActionsComponent={TablePaginationActions}
            rowsPerPageOptions={paginationOptions}
            component="div"
            count={totalFiltered}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowPerPage}
            labelRowsPerPage={getLang(lang, "label.ROWS_PER_PAGE")}
            labelDisplayedRows={({ from, to, count }) =>
              `${from.toLocaleString("en-US")}-${to.toLocaleString(
                "en-US"
              )} ${getLang(lang, "label.OF")} ${count.toLocaleString("en-US")}`
            }
          />
        </Box>
      ) : null}
      {!isFetching && !isError && rows.length === 0 && (
        <Box style={{ overflowX: "auto", backgroundColor: "#fff" }}>
          <Table
            className={classes.table}
            aria-label="product table"
            size="small"
            stickyHeader
          >
            <TableHead style={{ height: "45px" }}>
              <TableRow>
                <StickyLeftTableCell style={{ textAlign: "center" }}>
                  <SelectAllCheckBox disabled={true} />
                </StickyLeftTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.IMAGE")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.PRODUCT_NAME")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.ASSIGNED_SERIAL_NUMBER")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.ADDITIONAL_INFO")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.CATEGORY")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.BRAND_MODEL")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.BRANCH")}
                </StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.SKU")}</StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.PRICE")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.SCAN_LIMIT")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.INCENTIVE_POINT")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.STATUS")}
                </StyledTableCell>
                <StickyRightTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.ACTION")}
                  </StyledTableCell>
                </StickyRightTableCell>
              </TableRow>
            </TableHead>
          </Table>

          <Box py={9} className={classes.emptyContainer}>
            <Typography variant="body1" style={{ paddingBottom: "16px" }}>
              {isSearchResult
                ? getLang(lang, "paragraph.NO_PRODUCT_EXIST")
                : getLang(lang, "paragraph.NO_PRODUCT_CREATED")}
            </Typography>
            {hasProductAddAccessRight && (
              <Button
                onClick={handleCreate}
                variant="contained"
                disableElevation
                color="primary"
              >
                {getLang(lang, "label.ADD_PRODUCT")}
              </Button>
            )}
          </Box>
        </Box>
      )}
    </>
  );
}
