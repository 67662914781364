import React from 'react';
import { connect } from 'react-redux';

const PublicRoute = ({ component: Component, restricted = true, isAuthenticated, ...props }) => {

    return (
        <Component {...props} />
    );
};

const mapStateToProps = state => ({
    isAuthenticated: state.session.accessToken !== '' ? true : false
});

export default connect(mapStateToProps, null)(PublicRoute);