import { createSlice } from "@reduxjs/toolkit";
import { REPORTING } from "modules/reporting/constants";
import {
    getDealerStockoutByBranchData,
    getDealerStockoutByCategoryData,
    getDealerStockoutByBrandData,
    getDealerStockoutByModelData,
    getDealerStockoutByProductData,
    getDealerBranchTitleData,
    getDealerStockoutData,
    getDealerStockoutByEmployeeData
} from "../action";

const initialState = {
    dealer : {
        stockoutByBranchData: {},
        stockoutByCategoryData: {},
        stockoutByBrandData: {},
        stockoutByModelData: {},
        stockoutByProductData: {},
        branchTitleData: {},
        stockoutByDealerData: {},
        stockoutByEmployeeData: {},
    }
}

const reportingStockoutSlice = createSlice({
    name: REPORTING,
    initialState,
    reducers: {
        initReportingDealer: (state) => {
            state.dealer.stockoutByBranchData = initialState.dealer.stockoutByBranchData;
            state.dealer.stockoutByCategoryData = initialState.dealer.stockoutByCategoryData;
            state.dealer.stockoutByBrandData = initialState.dealer.stockoutByBrandData;
            state.dealer.stockoutByModelData = initialState.dealer.stockoutByModelData;
            state.dealer.stockoutByProductData = initialState.dealer.stockoutByProductData;
            state.dealer.branchTitleData = initialState.dealer.branchTitleData;
            state.dealer.stockoutByDealerData = initialState.dealer.stockoutByDealerData;
            state.dealer.stockoutByEmployeeData = initialState.dealer.stockoutByEmployeeData;
        }
    },
    extraReducers: {
        [getDealerStockoutByBranchData.fulfilled]: (state, action) => {
            let data = action.payload.data;
            data.isSuccess = action.payload.success;
            data.noResult = (data.rows.length === 0);

            state.dealer.stockoutByBranchData = data;
        },
        [getDealerStockoutByBranchData.rejected]: (state, action) => {
            state.dealer.stockoutByBranchData = {
                isSuccess: false,
                noResult: true,
                rows: [],
                total: 0,
            };
        },

        [getDealerStockoutByCategoryData.fulfilled]: (state, action) => {
            let data = action.payload.data;
            data.isSuccess = action.payload.success;
            data.noResult = (data.rows.length === 0);

            state.dealer.stockoutByCategoryData = data;
        },
        [getDealerStockoutByCategoryData.rejected]: (state, action) => {
            state.dealer.stockoutByCategoryData = {
                isSuccess: false,
                noResult: true,
                rows: [],
                total: 0,
            };
        },

        [getDealerStockoutByBrandData.fulfilled]: (state, action) => {
            let data = action.payload.data;
            data.isSuccess = action.payload.success;
            data.noResult = (data.rows.length === 0);

            state.dealer.stockoutByBrandData = data;
        },
        [getDealerStockoutByBrandData.rejected]: (state, action) => {
            state.dealer.stockoutByBrandData = {
                isSuccess: false,
                noResult: true,
                rows: [],
                total: 0,
            };
        },

        [getDealerStockoutByModelData.fulfilled]: (state, action) => {
            let data = action.payload.data;
            data.isSuccess = action.payload.success;
            data.noResult = (data.rows.length === 0);

            state.dealer.stockoutByModelData = data;
        },
        [getDealerStockoutByModelData.rejected]: (state, action) => {
            state.dealer.stockoutByModelData = {
                isSuccess: false,
                noResult: true,
                rows: [],
                total: 0,
            };
        },

        [getDealerStockoutByProductData.fulfilled]: (state, action) => {
            let data = action.payload.data;
            data.isSuccess = action.payload.success;
            data.noResult = (data.rows.length === 0);

            state.dealer.stockoutByProductData = data;
        },
        [getDealerStockoutByProductData.rejected]: (state, action) => {
            state.dealer.stockoutByProductData = {
                isSuccess: false,
                noResult: true,
                rows: [],
                total: 0,
            };
        },

        [getDealerBranchTitleData.fulfilled]: (state, action) => {
            let data = action.payload.data;
            data.isSuccess = action.payload.success;
            data.noResult = false;

            state.dealer.branchTitleData = data;
        },
        [getDealerBranchTitleData.rejected]: (state, action) => {
            state.dealer.branchTitleData = {
                isSuccess: false,
                noResult: true,
                rows: [],
                total: 0,
            };
        },

        [getDealerStockoutData.fulfilled]: (state, action) => {
            let data = action.payload.data;
            data.isSuccess = action.payload.success;
            data.noResult = false;

            state.dealer.stockoutByDealerData = data;
        },
        [getDealerStockoutData.rejected]: (state, action) => {
            state.dealer.stockoutByDealerData = {
                isSuccess: false,
                noResult: true,
                rows: [],
                total: 0,
            };
        },

        [getDealerStockoutByEmployeeData.fulfilled]: (state, action) => {
            let data = action.payload.data;
            data.isSuccess = action.payload.success;
            data.noResult = false;

            state.dealer.stockoutByEmployeeData = data;
        },
        [getDealerStockoutByEmployeeData.rejected]: (state, action) => {
            state.dealer.stockoutByEmployeeData = {
                isSuccess: false,
                noResult: true,
                rows: [],
                total: 0,
            };
        }
        
    }
})

export const {
    initReportingDealer
} = reportingStockoutSlice.actions;

export const reportingStockoutReducer = reportingStockoutSlice.reducer;