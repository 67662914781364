import React from "react"
import LooseItemStockListTableComponent from "./looseItemStockListTable.component"

export default function LooseItemStockListTableContainer({
  hasStockflowDealerViewAccessRight,
  rows,
  isFetching,
  isError,
  handleReload,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
  isSearchResult,
  totalFiltered,
  tableFilter,
  changeSequenceOrder,
  lang,
  paginationOptions,
  handleViewDetail
}) {
  return (
    <LooseItemStockListTableComponent
      hasStockflowDealerViewAccessRight={hasStockflowDealerViewAccessRight}
      rows={rows}
      isFetching={isFetching}
      isError={isError}
      handleReload={handleReload}
      page={page}
      rowsPerPage={rowsPerPage}
      handleChangePage={handleChangePage}
      handleChangeRowPerPage={handleChangeRowsPerPage}
      isSearchResult={isSearchResult}
      totalFiltered={totalFiltered}
      tableFilter={tableFilter}
      changeSequenceOrder={changeSequenceOrder}
      lang={lang}
      paginationOptions={paginationOptions}
      handleViewDetail={handleViewDetail}
    />
  )
}