import React from "react";
import { makeStyles, ButtonBase, Typography, Box } from "@material-ui/core";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "5px 12px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#ECEFF0",
    borderRadius: "4px",
    width: "40%",
  },
  inputText: {
    color: "#a4a6a8",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontWeight: 500,
  },
  disabledField: {
    backgroundColor: "#eceff0",
  },
  filled: {
    minWidth: "auto",
    fontWeight: 500,
    color: theme.palette.primary.main,
    display: "inline-block",
    flexWrap: "wrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "100%",
    alignSelf: "center",
  },
}));

export default function CustomFieldSelectComponent({
  handleClick,
  value,
  placeholder,
  disabled,
  style,
  id,
  open,
  textStyle,
  userLang,
}) {
  const classes = useStyles();

  const getDisplay = () => {
    let display = placeholder;
    if (value) {
      display = value.field_name
        ? value.field_name[userLang] ??
          value.field_name["EN"] ??
          Object.values(value.field_name)[0]
        : "";
    }
    return display;
  };

  const displayValue = () => {
    return (
      <Box
        style={{
          display: "flex",
          flexWrap: "wrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          flex: "2",
        }}
      >
        <Typography
          variant="body2"
          noWrap
          style={textStyle}
          className={clsx(
            classes.inputText,
            getDisplay() !== placeholder && classes.filled
          )}
        >
          {getDisplay()}
        </Typography>
      </Box>
    );
  };

  return (
    <ButtonBase
      aria-describedby={id}
      className={clsx(classes.root, {
        [classes.disabledField]: disabled,
      })}
      disableRipple
      onClick={handleClick}
      disabled={disabled}
      style={{ padding: 12, ...style }}
    >
      {displayValue()}
      {open ? (
        <ArrowDropUpIcon fontSize="small" />
      ) : (
        <ArrowDropDownIcon fontSize="small" />
      )}
    </ButtonBase>
  );
}
