import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectLoading } from "modules/notification";
import { generatePaginationOptions, updateUrlQueryParam } from "lib/helper";
import { resetRequestListing } from "modules/stockflow/redux";
import moment from "moment";
import RequestTransferListingComponent from "./requestTransferListing.component";
import { useHistory } from "react-router-dom";
import {
  getRequestReceiveList,
  getRequestSendList,
} from "modules/stockflow/redux/action/stockflowRequestTransfer.action";

function RequestTransferListingContainer({ type }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.constant.languages);
  const language = useSelector((state) => state.profile.language);

  const isFetching = useSelector((state) =>
    selectLoading(
      state,
      type === "Send"
        ? getRequestSendList.typePrefix
        : getRequestReceiveList.typePrefix
    )
  );

  const totalFiltered = useSelector(
    (state) =>
      state.stockflowRequestTransfer[`request${type}List`].totalFiltered
  );
  const totalRecords = useSelector(
    (state) => state.stockflowRequestTransfer[`request${type}List`].totalRecords
  );
  const requestList = useSelector(
    (state) => state.stockflowRequestTransfer[`request${type}List`].list
  );
  const isError = useSelector(
    (state) => state.stockflowRequestTransfer[`request${type}List`].isError
  );

  const isSearchResult = requestList.length < totalRecords;
  const [paginationOptions, setPaginationOptions] = useState([]);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isFilterUpdated, setIsFilterUpdated] = useState(false);
  const [tableFilter, setTableFilter] = useState({
    page: 0,
    rowsPerPage: 25,
    order: "desc",
    orderBy: "created_at",
    search: "",
    status: "",
    fromDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
    toDate: moment().format("YYYY-MM-DD"),
    requestFrom: [],
    requestTo: [],
    respondDate: null,
  });

  const query = new URLSearchParams(window.location.search);
  const tableFilterInitialRef = useRef(true);

  useEffect(() => {
    return () => {
      dispatch(resetRequestListing());
    };
  }, [dispatch]);

  useEffect(() => {
    const isFilter = query.has("is_filter");
    let filter = sessionStorage.getItem("request_transfer_filter");

    let temp = { ...tableFilter };
    if (isFilter && filter) {
      temp = JSON.parse(filter);
      setIsFilterUpdated(true);
      setIsFilterOpen(true);
    }

    if (isFilter) {
      updateUrlQueryParam(null, ["is_filter"]);
    }
    sessionStorage.removeItem("request_transfer_filter");

    setTableFilter(temp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!tableFilterInitialRef.current) {
      handleDataLoad();
    }
    tableFilterInitialRef.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableFilter]);

  useEffect(() => {
    dispatch(resetRequestListing());
  }, [dispatch]);

  useEffect(() => {
    setPaginationOptions(generatePaginationOptions(totalFiltered));
  }, [totalFiltered]);

  const handleDataLoad = () => {
    let payload = {
      length: tableFilter.rowsPerPage,
      start: tableFilter.rowsPerPage * tableFilter.page,
      order: tableFilter.order,
      order_by: tableFilter.orderBy,
      filter: {
        search: tableFilter.search,
        status: tableFilter.status,
        from_date: tableFilter.fromDate,
        to_date: tableFilter.toDate,
        request_from: tableFilter.requestFrom,
        request_to: tableFilter.requestTo,
        respond_date: tableFilter.respondDate,
      },
    };
    dispatch(
      type === "Receive"
        ? getRequestReceiveList(payload)
        : getRequestSendList(payload)
    );
  };

  const handleChangePage = (event, newPage) => {
    let newData = {
      ...tableFilter,
      page: newPage,
    };
    setIsFilterUpdated(true);
    setTableFilter(newData);
  };

  const handleChangeRowsPerPage = (event) => {
    let newData = {
      ...tableFilter,
      page: 0,
      rowsPerPage: event.target.value,
    };
    setIsFilterUpdated(true);
    setTableFilter(newData);
  };

  const handleSearch = (filter) => {
    let newData = {
      ...tableFilter,
      page: 0,
      search: filter.search,
      fromDate: filter.dateRange?.from
        ? moment(filter.dateRange.from).format("YYYY-MM-DD")
        : "",
      toDate: filter.dateRange?.to
        ? moment(filter.dateRange.to).format("YYYY-MM-DD")
        : "",
      status: filter.status ? filter.status.value : "",
      requestFrom: filter.requestFrom,
      requestTo: filter.requestTo,
      respondDate: filter.respondDate,
    };
    setIsFilterUpdated(true);
    setTableFilter(newData);
  };

  const clearSearch = () => {
    let newData = {
      ...tableFilter,
      page: 0,
      search: "",
      status: "",
      fromDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
      toDate: moment().format("YYYY-MM-DD"),
      requestFrom: [],
      requestTo: [],
      respondDate: null,
    };
    setIsFilterUpdated(false);
    setTableFilter(newData);
  };

  const changeOrder = (orderBy, order) => {
    let newData = {
      ...tableFilter,
      order,
      orderBy,
    };
    setIsFilterUpdated(true);
    setTableFilter(newData);
  };

  const resetFilter = () => {
    let newData = {
      ...tableFilter,
      page: 0,
      requestFrom: [],
      requestTo: [],
      respondDate: "",
      status: "",
    };
    setIsFilterUpdated(true);
    setTableFilter(newData);
  };

  const handleViewDetail = (url) => {
    if (isFilterUpdated) {
      updateUrlQueryParam({ is_filter: true });
      sessionStorage.setItem(
        "request_transfer_filter",
        JSON.stringify(tableFilter)
      );
    }
    history.push(url);
  };

  return (
    <>
      <RequestTransferListingComponent
        requests={requestList}
        isFetching={isFetching}
        isError={isError}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        page={tableFilter.page}
        rowsPerPage={tableFilter.rowsPerPage}
        totalFiltered={totalFiltered}
        handleSearch={handleSearch}
        language={language}
        clearSearch={clearSearch}
        handleReload={handleDataLoad}
        isSearchResult={isSearchResult}
        paginationOptions={paginationOptions}
        isFilterOpen={isFilterOpen}
        setIsFilterOpen={setIsFilterOpen}
        tableFilter={tableFilter}
        changeOrder={changeOrder}
        lang={lang}
        resetFilter={resetFilter}
        handleViewDetail={handleViewDetail}
        type={type}
      />
    </>
  );
}

export default RequestTransferListingContainer;
