import React from 'react';
import DisplayImageDialogComponent from "./displayImageDialog.component";
import { useSelector } from "react-redux";

export default function DisplayImageDialogContainer({
  isOpen,
  handleClose,
  src,
  type,
}) {
  const lang = useSelector(state => state.constant.languages);

  return (
    <DisplayImageDialogComponent
      lang={lang}
      isOpen={isOpen}
      handleClose={handleClose}
      src={src}
      type={type}
    />
  )
}
