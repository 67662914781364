import React from "react";
import { Button, CircularProgress } from "@material-ui/core";
import { DownloadSVG } from "modules/lucky-draw/utils/static";

export default function ExportExcelSelectComponent({
  isLoading,
  handleClick,
  placeholder,
  style,
  disabled,
  anchorRef,
}) {
  return (
    <Button
      ref={anchorRef}
      variant="outlined"
      color="primary"
      size="small"
      style={{ margin: "4px", background: "white", ...style }}
      onClick={handleClick}
      startIcon={
        isLoading ? (
          <CircularProgress style={{ width: 10, height: 10 }} />
        ) : (
          <DownloadSVG />
        )
      }
      disabled={disabled}
    >
      {placeholder}
    </Button>
  );
}
