import {
  fetchOnlineEmployee,
  fetchMoreOnlineEmployee,
  fetchRoleDropdown,
  fetchAllEmployee,
  fetchBranchDropdown,
  createEmployee,
  updateEmployeeDetail,
  fetchEmployeeDetail,
  fetchFilterBranch,
  resendEmail,
  removeEmployee,
  changeEmployeeStatus,
  checkEmailExists
} from "./employee.action";
import { setLoading, addAlert } from "modules/notification";
import { resetEmployeeDetail, setEmployeeID } from "./employee.slice";
import { getApiLang } from "app/feature/constants";

const fetchOnlineEmployeeMiddleware = ({ dispatch, getState }) => (next) => (action) => {
  next(action);

  const { type, payload } = action;

  switch (type) {
    case fetchOnlineEmployee.pending.type:
      dispatch(
        setLoading({ id: fetchOnlineEmployee.typePrefix, state: true })
      );
      break;

    case fetchOnlineEmployee.fulfilled.type:
      dispatch(
        setLoading({ id: fetchOnlineEmployee.typePrefix, state: false })
      );
      break;

    case fetchOnlineEmployee.rejected.type:
      const lang = getState().constant.languages
      dispatch(
        setLoading({ id: fetchOnlineEmployee.typePrefix, state: false })
      );
      dispatch(
        addAlert({
          severity: "error",
          message: getApiLang(lang, payload.code)
        })
      );
      break;

    default:
      break;
  }
};

const fetchMoreOnlineEmployeeMiddleware = ({ dispatch, getState }) => (next) => (action) => {
  next(action);

  const { type } = action;
  if (type === fetchMoreOnlineEmployee.type) {
    const w = getState().employee.onlineEmployeeList;
    const start = w.data.length;
    dispatch(fetchOnlineEmployee({ start: start }));
  }
};

const fetchRoleDropdownMiddleware = ({ dispatch, getState }) => (next) => (action) => {
  next(action);

  const { type, payload } = action;

  switch (type) {
    case fetchRoleDropdown.pending.type:
      dispatch(setLoading({ id: fetchRoleDropdown.typePrefix, state: true }));
      break;

    case fetchRoleDropdown.fulfilled.type:
      dispatch(
        setLoading({ id: fetchRoleDropdown.typePrefix, state: false })
      );
      break;

    case fetchRoleDropdown.rejected.type:
      const lang = getState().constant.languages
      dispatch(
        setLoading({ id: fetchRoleDropdown.typePrefix, state: false })
      );
      dispatch(
        addAlert({
          severity: "error",
          message: getApiLang(lang, payload.code)
        })
      );
      break;

    default:
      break;
  }
};

const fetchAllEmployeeMiddleware = ({ dispatch, getState }) => (next) => (action) => {
  next(action);

  const { type, payload } = action;

  switch (type) {
    case fetchAllEmployee.pending.type:
      dispatch(setLoading({ id: fetchAllEmployee.typePrefix, state: true }));
      break;

    case fetchAllEmployee.fulfilled.type:
      dispatch(setLoading({ id: fetchAllEmployee.typePrefix, state: false }));
      break;

    case fetchAllEmployee.rejected.type:
      const lang = getState().constant.languages
      dispatch(setLoading({ id: fetchAllEmployee.typePrefix, state: false }));
      dispatch(
        addAlert({
          severity: "error",
          message: getApiLang(lang, payload.code)
        })
      );
      break;

    default:
      break;
  }
};

const fetchBranchDropdownMiddleware = ({ dispatch, getState }) => (next) => (action) => {
  next(action);

  const { type, payload } = action;

  switch (type) {
    case fetchBranchDropdown.pending.type:
      dispatch(
        setLoading({ id: fetchBranchDropdown.typePrefix, state: true })
      );
      break;

    case fetchBranchDropdown.fulfilled.type:
      dispatch(
        setLoading({ id: fetchBranchDropdown.typePrefix, state: false })
      );
      break;

    case fetchBranchDropdown.rejected.type:
      const lang = getState().constant.languages
      dispatch(
        setLoading({ id: fetchBranchDropdown.typePrefix, state: false })
      );
      dispatch(
        addAlert({
          severity: "error",
          message: getApiLang(lang, payload.code)
        })
      );
      break;

    default:
      break;
  }
};

const createEmployeeMiddleware = ({ dispatch, getState }) => (next) => async (action) => {
  next(action);

  const { type, payload } = action;
  const lang = getState().constant.languages

  switch (type) {
    case createEmployee.pending.type:
      dispatch(setLoading({ id: createEmployee.typePrefix, state: true }));
      break;

    case createEmployee.fulfilled.type:
      next(resetEmployeeDetail());
      next(setEmployeeID(action.payload));
      dispatch(setLoading({ id: createEmployee.typePrefix, state: false }));
      dispatch(addAlert({ severity: "success", message: getApiLang(lang, "success.SUCCESS_CREATE_EMPLOYEE") }));
      break;

    case createEmployee.rejected.type:
      dispatch(setLoading({ id: createEmployee.typePrefix, state: false }));
      dispatch(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
      break;

    default:
      break;
  }
};

const updateEmployeeDetailMiddleware = ({ dispatch, getState }) => (next) => async (action) => {
  next(action);

  const { type, payload } = action;
  const lang = getState().constant.languages

  switch (type) {
    case updateEmployeeDetail.pending.type:
      dispatch(
        setLoading({
          id: updateEmployeeDetail.typePrefix,
          state: true
        })
      );
      break;

    case updateEmployeeDetail.fulfilled.type:
      dispatch(
        setLoading({
          id: updateEmployeeDetail.typePrefix,
          state: false
        })
      );
      dispatch(addAlert({ severity: "success", message: getApiLang(lang, "success.SUCCESS_UPDATE_EMPLOYEE") }));
      break;

    case updateEmployeeDetail.rejected.type:
      dispatch(
        setLoading({
          id: updateEmployeeDetail.typePrefix,
          state: false
        })
      );
      dispatch(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
      break;

    default:
      break;
  }
};

const fetchEmployeeDetailMiddleware = ({ dispatch, getState }) => (next) => (action) => {
  next(action);

  const { type, payload } = action;
  const lang = getState().constant.languages

  switch (type) {
    case fetchEmployeeDetail.pending.type:
      dispatch(
        setLoading({ id: fetchEmployeeDetail.typePrefix, state: true })
      );
      break;

    case fetchEmployeeDetail.fulfilled.type:
      dispatch(
        setLoading({
          id: fetchEmployeeDetail.typePrefix,
          state: false
        })
      );
      break;

    case fetchEmployeeDetail.rejected.type:
      dispatch(
        setLoading({
          id: fetchEmployeeDetail.typePrefix,
          state: false
        })
      );
      dispatch(
        addAlert({
          severity: "error",
          message: getApiLang(lang, payload.code)
        })
      );
      break;

    default:
      break;
  }
};

const fetchFilterBranchMiddleware = ({ dispatch, getState }) => (next) => (action) => {
  next(action);

  const { type, payload } = action;
  const lang = getState().constant.languages

  switch (type) {
    case fetchFilterBranch.pending.type:
      dispatch(setLoading({ id: fetchFilterBranch.typePrefix, state: true }));
      break;

    case fetchFilterBranch.fulfilled.type:
      dispatch(
        setLoading({ id: fetchFilterBranch.typePrefix, state: false })
      );
      break;

    case fetchFilterBranch.rejected.type:
      dispatch(
        setLoading({ id: fetchFilterBranch.typePrefix, state: false })
      );
      dispatch(
        addAlert({
          severity: "error",
          message: getApiLang(lang, payload.code)
        })
      );
      break;

    default:
      break;
  }
};

const resendEmailMiddleware = ({ dispatch, getState }) => (next) => async (action) => {
  next(action);

  const { type, payload } = action;
  const lang = getState().constant.languages

  switch (type) {
    case resendEmail.pending.type:
      dispatch(
        setLoading({
          id: resendEmail.typePrefix,
          state: true
        })
      );
      break;

    case resendEmail.fulfilled.type:
      dispatch(
        setLoading({
          id: resendEmail.typePrefix,
          state: false
        })
      );
      dispatch(addAlert({ severity: "success", message: getApiLang(lang, "success.SUCCESS_RESEND_EMAIL_EMPLOYEE") }));
      break;

    case resendEmail.rejected.type:
      dispatch(
        setLoading({
          id: resendEmail.typePrefix,
          state: false
        })
      );
      dispatch(
        addAlert({ severity: "error", message: getApiLang(lang, payload.code) })
      );
      break;

    default:
      break;
  }
};

const removeEmployeeMiddleware = ({ dispatch, getState }) => (next) => async (action) => {
  next(action);

  const { type, payload } = action;
  const lang = getState().constant.languages

  switch (type) {
    case removeEmployee.pending.type:
      dispatch(
        setLoading({
          id: removeEmployee.typePrefix,
          state: true
        })
      );
      break;

    case removeEmployee.fulfilled.type:
      dispatch(
        setLoading({
          id: removeEmployee.typePrefix,
          state: false
        })
      );
      dispatch(
        addAlert({ severity: "success", message: getApiLang(lang, "success.SUCCESS_DELETE_EMPLOYEE") })
      );
      break;

    case removeEmployee.rejected.type:
      dispatch(
        setLoading({
          id: resendEmail.typePrefix,
          state: false
        })
      );
      dispatch(
        addAlert({ severity: "error", message: getApiLang(lang, payload.code) })
      );
      break;

    default:
      break;
  }
};

const changeEmployeeStatusMiddleware = ({ dispatch, getState }) => (next) => (action) => {
  next(action);

  const { type, payload } = action;
  const lang = getState().constant.languages

  switch (type) {
    case changeEmployeeStatus.pending.type:
      dispatch(
        setLoading({ id: changeEmployeeStatus.typePrefix, state: true })
      );
      break;
    case changeEmployeeStatus.fulfilled.type:
      dispatch(
        setLoading({ id: changeEmployeeStatus.typePrefix, state: false })
      );
      dispatch(
        addAlert({
          severity: "success",
          message: getApiLang(lang, "success.SUCCESS_CHANGE_EMPLOYEE_STATUS")
        })
      );
      break;
    case changeEmployeeStatus.rejected.type:
      dispatch(
        setLoading({ id: changeEmployeeStatus.typePrefix, state: false })
      );
      dispatch(
        addAlert({
          severity: "error",
          message: getApiLang(lang, payload.code)
        })
      );
      break;
    default:
      break;
  }
};

const checkEmailExistsMiddleware = ({ dispatch, getState }) => (next) => async (action) => {
  next(action);

  const { type, payload } = action;
  const lang = getState().constant.languages

  switch (type) {
    case checkEmailExists.pending.type:
      dispatch(
        setLoading({
          id: checkEmailExists.typePrefix,
          state: true
        })
      );
      break;

    case checkEmailExists.fulfilled.type:
      dispatch(
        setLoading({
          id: checkEmailExists.typePrefix,
          state: false
        })
      );
      dispatch(addAlert({ severity: "success", message: getApiLang(lang, "success.SUCCESS_CHECK_EMAIL_EXISTS") }));
      break;

    case checkEmailExists.rejected.type:
      dispatch(
        setLoading({
          id: checkEmailExists.typePrefix,
          state: false
        })
      );
      dispatch(
        addAlert({ severity: "error", message: getApiLang(lang, payload.code) })
      );
      break;

    default:
      break;
  }
};

export const employeeMiddleware = [
  fetchOnlineEmployeeMiddleware,
  fetchMoreOnlineEmployeeMiddleware,
  fetchRoleDropdownMiddleware,
  fetchAllEmployeeMiddleware,
  fetchBranchDropdownMiddleware,
  createEmployeeMiddleware,
  updateEmployeeDetailMiddleware,
  fetchEmployeeDetailMiddleware,
  fetchFilterBranchMiddleware,
  resendEmailMiddleware,
  removeEmployeeMiddleware,
  changeEmployeeStatusMiddleware,
  checkEmailExistsMiddleware
];
