import React from "react";
import {
  makeStyles,
  Typography,
  Box,
  Button,
} from "@material-ui/core";
import Footer from 'modules/trackco/components/footer';
import Header from "../../components/header";
import { getLang } from "app/feature/constants";

const style = theme => ({
  mainContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh"
  },
  container: {
    padding: "40px 30px 40px 30px",
    backgroundColor: "#ffffff",
    borderRadius: "4px",
    marginBottom: "40px",
  },
  title: {
    fontWeight: 700,
    fontSize: "24px",
    fontFamily: "Montserrat"
  },
  description: {
    fontFamily: "Montserrat",
    fontWeight: 400,
    fontSize: "18px"
  },
  remark: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    color: "#667085"
  },
  button: {
    width: "100%",
    borderRadius: "42px",
    textTransform: "capitalize",
    fontSize: "16px"
  },
  linkContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "40px",
  },
  link: {
    fontFamily: "Montserrat",
    fontWeight: 700,
    fontSize: "16px",
  }
});

const useStyle = makeStyles(style);

export default function SignUpVerificationPage({
  counter,
  enableResent,
  handleResendEmailVerification,
  email,
  lang
}) {
  const classes = useStyle();

  return (
    <Box className={classes.mainContainer}>
      <Box style={{ width: "502px" }}>
        <Box style={{ marginBottom: "60px" }}>
          <Header justifyContent="center" />
        </Box>
        <Box className={classes.container}>
          <Box marginBottom="20px">
            <Typography variant='h5' className={classes.title}>{getLang(lang, 'paragraph.PLEASE_VERIFY_YOUR_EMAIL')}</Typography>
          </Box>
          <Box marginBottom="69px">
            <Typography variant='body1' className={classes.description}>
              {getLang(lang, 'paragraph.WE_SENT_VERIFICATION_LINK')} <span style={{ fontWeight: 700 }}>{email}&nbsp;</span>.
              {getLang(lang, 'paragraph.PLEASE_CHECK_EMAIL_CLICK_VERIFICATION_LINK')}
            </Typography>
          </Box>
          <Box marginBottom="8px">
            <Typography variant='subtitle1' className={classes.remark} style={{ color: '#667085' }}>{getLang(lang, 'paragraph.DIDNT_RECEIVED')}</Typography>
          </Box>
          {!enableResent ? (
            <Box marginBottom="8px">
              <Typography variant='subtitle2' className={classes.remark} style={{ color: '#ff0000' }}>{`${getLang(lang, 'paragraph.RESEND_VERIFICATION_LINK', {count: counter})}`}</Typography>
            </Box>
          ): null}
          <Box>
            <Button
              variant='contained'
              type='submit'
              size='large'
              color='primary'
              className={classes.button}
              disabled={!enableResent}
              onClick={handleResendEmailVerification}
            >
              {getLang(lang, 'label.RESEND')}
            </Button>
          </Box>
        </Box>
        <Box>
          <Footer type="tnc" />
        </Box>
      </Box>
    </Box>
  );
}