import React, { useEffect, useState, useRef } from "react"
import DashboardStockoutByProductPanelComponent from "./dashboardStockoutByProductPanel.component"
import { useDispatch, useSelector } from "react-redux"
import { getStockoutByProduct } from "modules/stockflow/redux"
import { selectLoading } from "modules/notification"
import html2canvas from "html2canvas";
import downloadjs from 'downloadjs';
import { generatePaginationOptions, updateUrlQueryParam } from "lib/helper"

function DashboardStockoutByProductPanelContainer({ id, filter }) {
  const lang = useSelector(state => state.constant.languages)
  const dispatch = useDispatch()

  // listing table
  const isFetching = useSelector(state => selectLoading(state, getStockoutByProduct.typePrefix))
  const stockoutByProductData = useSelector(state => state.stockflowDealer.dashboard.stockoutByProduct)
  const [paginationOptions, setPaginationOptions] = useState([]);
  const [isFilterUpdated, setIsFilterUpdated] = useState(false)
  const [tableFilter, setTableFilter] = useState({
    page: 0,
    rowsPerPage: 25,
    orderBy: "stockout_count",
    order: "desc",
  })

  const query = new URLSearchParams(window.location.search)
  const tableFilterInitialRef = useRef(true)

  const handleDownloadDealerInvitedSummaryCard = () => {
    let element = document.getElementById("dealer-invited");

    html2canvas(element).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      downloadjs(imgData, 'dealer-invited-report.png');
    });
  }

  useEffect(() => {
    const isFilter = query.has("is_filter_stockout")
    let filter = sessionStorage.getItem(`dealer_dashboard_stockout_product_filter_${id}`);

    let temp = {...tableFilter}
    if (isFilter && filter) {
      temp = JSON.parse(filter)
      setIsFilterUpdated(true)
    }

    if (isFilter) {
      updateUrlQueryParam(null, ["is_filter_stockout"])
    }
    sessionStorage.removeItem(`dealer_dashboard_stockout_product_filter_${id}`)

    setTableFilter(temp)

    return () => {
      if (isFilterUpdated) {
        updateUrlQueryParam({ is_filter_stockout: true })
        sessionStorage.setItem(`dealer_dashboard_stockout_product_filter_${id}`, JSON.stringify(tableFilter));
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!tableFilterInitialRef.current) {
      getData()
    }
    tableFilterInitialRef.current = false
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableFilter, filter])

  useEffect(() => {
    setPaginationOptions(generatePaginationOptions(stockoutByProductData.total_record))
  }, [stockoutByProductData])

  const getData = () => {
    dispatch(getStockoutByProduct({
      start_date: filter.dateRange[0],
      end_date: filter.dateRange[1],
      dealer_uuid: id,
      start: tableFilter.rowsPerPage * tableFilter.page,
      length: tableFilter.rowsPerPage,
      orderDirection: tableFilter.order,
      orderBy: tableFilter.orderBy
    }))
  }

  const handleChangePage = (event, newPage) => {
    let newData = {
      ...tableFilter,
      page: newPage,
    }
    setIsFilterUpdated(true)
    setTableFilter(newData)
  }

  const handleChangeRowsPerPage = event => {
    let newData = {
      ...tableFilter,
      page: 0,
      rowsPerPage: event.target.value,
    }
    setIsFilterUpdated(true)
    setTableFilter(newData)
  }

  const changeOrder = (orderBy, order) => {
    let newData = {
      ...tableFilter,
      order,
      orderBy
    }
    setIsFilterUpdated(true)
    setTableFilter(newData)
  }

  return (
    <DashboardStockoutByProductPanelComponent
      lang={lang}
      handleDownloadDealerInvitedSummaryCard={handleDownloadDealerInvitedSummaryCard}
      data={stockoutByProductData}
      isFetching={isFetching}
      page={tableFilter.page}
      rowsPerPage={tableFilter.rowsPerPage}
      changeOrder={changeOrder}
      paginationOptions={paginationOptions}
      tableFilter={tableFilter}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
    />
  )
}

export default DashboardStockoutByProductPanelContainer
