import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectLoading } from "modules/notification";
import { getProductDropdown } from "modules/product-mgmt/redux/action/products.action";
import RespondentSearchComponent from "./respondentSearch.component";

export default function RespondentsSearchContainer({ handleFilter }) {
  const dispatch = useDispatch()
  const [ productSearch, setProductSearch ] = useState("")
  const productDropdown = useSelector((state) => state.products.productsDropdown);
  const isProductDropdownLoading = useSelector((state) => selectLoading(state, getProductDropdown.typePrefix))
  const lang = useSelector(state => state.constant.languages);

  const handleSearch = async (values) => {
    await handleFilter(values)
  }

  const handleProductSearch = (values) => {
    setProductSearch(values)
  }

  useEffect(() => {
    dispatch(getProductDropdown({
      search: productSearch
    }))
  }, [productSearch, dispatch])

  return (
    <RespondentSearchComponent 
      handleSearch={handleSearch}
      isProductLoading={isProductDropdownLoading}
      productDropdown={productDropdown}
      handleItemSearch={handleProductSearch}
      lang={lang}
    />
  );
}