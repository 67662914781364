import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import RewardCenterPage from "./reward-center.page";
import { STOCKFLOW_REWARD_CENTER_VIEW, STOCKFLOW_REWARD_CENTER_ADD } from "lib/constants/accessRights";
import LoadingComponent from "components/loading";
import { REWARD_CREATE_PAGE } from "modules/stockflow/config/stockflow.route";
import { useHistory } from "react-router-dom";

function RewardCenterContainer() {
  const lang = useSelector(state => state.constant.languages);
  const hasStockflowViewAccessRight = useSelector(state => state.profile.accessRights.includes(STOCKFLOW_REWARD_CENTER_VIEW));
  const hasStockflowAddAccessRight = useSelector(state => state.profile.accessRights.includes(STOCKFLOW_REWARD_CENTER_ADD));
  const history = useHistory();
  const [ activeIndex, setActiveIndex ] = useState(0)

  const handleNewReward = () => {
    history.push(REWARD_CREATE_PAGE)
  }

  const handleChange = (event, index) => {
    setActiveIndex(index)
  }

  useEffect(() => {
    if (!hasStockflowViewAccessRight) {
      history.push('/admin/dashboard')
    }
  }, [history, hasStockflowViewAccessRight])

  if (!hasStockflowViewAccessRight) return <LoadingComponent />;

  return (
    <RewardCenterPage
      hasStockflowAddAccessRight={hasStockflowAddAccessRight}
      handleNewReward={handleNewReward}
      activeIndex={activeIndex}
      handleChange={handleChange}
      lang={lang}
    />
  )
}

export default RewardCenterContainer;