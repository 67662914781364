import { createSlice } from "@reduxjs/toolkit";
import { SERIAL_NUMBER_TAG } from "../../constants";
import { fetchTagsDropdown, getTagDetail, getTagSerialNumber } from "../action";

const initialState = {
  SerialNumberTagTable: {
    data: [],
    totalRecords: 0,
    filteredTotalRecords: 0,
  },
  tagsDropdown: [],
  isLoadingSerialNumberTagListError: false,
  isOpenaddPattern: false,
  tag: null,
  isLoadingSnTagError: false,
};

const tagSerialNumberSlice = createSlice({
  name: SERIAL_NUMBER_TAG,
  initialState,
  reducers: {
    setTagSerialNumber(state, action) {
      const { payload } = action;
      const tempData = [];

      state.SerialNumberTagTable.totalRecords = payload.recordsTotal;
      state.SerialNumberTagTable.filteredTotalRecords = payload.recordsFiltered;

      payload.data.forEach((element) => {
        const order = {
          id: element.id || "",
          prefix: element.format.split(",")[0],
          length: element.format.split(",")[1],
          postfix: element.format.split(",")[2],
          pinLength: element.format.split(",")[3],
          pinDigit: element.pin_digit || 0,
          status: element.status || "",
          datetime: element.datetime || "",
          mode: element.adt_info_mode || 0,
        };

        tempData.push(order);
      });

      state.SerialNumberTagTable.data = tempData;
    },
    openCreationDialog(state) {
      state.isOpenaddPattern = true;
    },
    closeCreationDialog(state) {
      state.isOpenaddPattern = false;
    },
    resetTag(state) {
      state.tag = initialState.tag;
      state.isLoadingSnTagError = initialState.isLoadingSnTagError;
    },
  },
  extraReducers: {
    [fetchTagsDropdown.fulfilled]: (state, action) => {
      const { payload } = action;

      state.tagsDropdown = payload;
    },
    [getTagSerialNumber.fulfilled]: (state) => {
      state.isLoadingSerialNumberTagListError = false;
    },
    [getTagSerialNumber.rejected]: (state) => {
      state.isLoadingSerialNumberTagListError = true;
    },
    [getTagDetail.pending]: (state) => {
      state.tag = initialState.tag;
      state.isLoadingSnTagError = false;
    },
    [getTagDetail.fulfilled]: (state, action) => {
      const { payload } = action;

      state.tag = {
        ...payload,
        prefix: payload.format.split(",")[0],
        length: payload.format.split(",")[1],
        postfix: payload.format.split(",")[2],
        pinLength: payload.format.split(",")[3],
      };
    },
    [getTagDetail.rejected]: (state) => {
      state.isLoadingSnTagError = true;
    },
  },
});

export const {
  setTagSerialNumber,
  openCreationDialog,
  closeCreationDialog,
  resetTag,
} = tagSerialNumberSlice.actions;
export const serialNumberTagReducer = tagSerialNumberSlice.reducer;
