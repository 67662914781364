import getStockFilterBranch from "./getStockFilterBranch";
import { getStockSummary } from "./getStockSummary.api";
import { getCountryDropdown } from "./getCountryDropdown.api";
import { getBranchDropdown } from "./getBranchDropdown.api";
import { getProductDropdown } from "./getProductDropdown.api";
import exportStockSummary from "./exportStockSummary.api";

const stockSummaryApi = {
  getStockFilterBranch,
  getStockSummary,
  getCountryDropdown,
  getBranchDropdown,
  getProductDropdown,
  exportStockSummary,
};

export default stockSummaryApi;
