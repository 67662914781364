import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { CLAIM_LIST_EDIT, REIMBURSEMENT_LIST_VIEW } from "../../../../../lib/constants/accessRights";
import { getLang } from "app/feature/constants";

import ClaimListTableComponent from "./claimListTable.component";
import ClaimDetailDialog from "../../dialog/claimDetailDialog";
import { clearConsumerClaimDetail, fetchConsumerClaimDetail } from "modules/warranty/redux";

export default function ClaimListTableContainer({
  rows,
  handleReload,
  isFetching,
  length,
  rowsPerPage,
  page,
  handlePageChange,
  handleRowPerPageChange,
  statusList,
  paginationOptions,
  search,
  handleProcessed,
}) {
  const dispatch = useDispatch();

  const lang = useSelector(state => state.constant.languages);
  const language = useSelector(state => state.profile.language);
  const hasClaimEditAccessRight = useSelector((state) => state.profile.accessRights.includes(CLAIM_LIST_EDIT));
  const hasReimbursementViewAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(REIMBURSEMENT_LIST_VIEW)
  );

  const isLoadingClaimTableError = useSelector(state => state.warranty.isLoadingConsumerClaimTableError);

  statusList.push(
    {
      value: 4,
      name: getLang(lang, "label.NA"),
      color: "#808080",
    },
    {
      value: 5,
      name: getLang(lang, "label.READY_FOR_SUBMISSION"),
      color: "#FF5733",
    }
  );

  const handleView = async (item) => {
    const info = {
      claimId: item.uuid,
      reimbursementId: '',
    };
    dispatch(fetchConsumerClaimDetail(info));
  };

  const handleCloseModel = () => {
    dispatch(clearConsumerClaimDetail());
  }

  return (
    <>
      <ClaimListTableComponent
        rows={rows}
        length={length}
        language={language}
        isFetching={isFetching}
        hasReimbursementViewAccessRight={hasReimbursementViewAccessRight}
        hasClaimEditAccessRight={hasClaimEditAccessRight}
        handlePageChange={handlePageChange}
        handleRowPerPageChange={handleRowPerPageChange}
        search={search}
        statusList={statusList}
        handleReload={handleReload}
        handleView={handleView}
        rowsPerPage={rowsPerPage}
        page={page}
        paginationOptions={paginationOptions}
        isLoadingError={isLoadingClaimTableError}
        lang={lang}
      />

      <ClaimDetailDialog
        handleClose={handleCloseModel}
        fromPanel={0}
        handleTableReload={handleReload}

        //  CLAIM  //
        handleClaimProcessed={() => {
          handleProcessed();
          handleCloseModel();
        }}
      />
    </>
  )
}
