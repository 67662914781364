import React from "react";
import {
  makeStyles,
  Box,
  Tabs,
  Tab,
  Typography,
  Button,
  Divider,
  Grid,
  useTheme,
} from "@material-ui/core";
import ProPlan from "../../utils/image/ProPlan.png";
import LitePlan from "../../utils/image/LitePlan.png";
import PlanAdvisor from "../../utils/image/PlanAdvisor.png"
import Check from "../../utils/image/Check.png"
// import CheckSvg from "../../utils/svg/check.svg"
import CreditCardSvg from "../../utils/svg/credit-card.svg";
import AccessibilitySvg from "../../utils/svg/accessibility.svg";
import CompareSvg from "../../utils/svg/compare.svg";
import LoadingComponent from "components/loading";
import ConfirmationDialogComponent from "components/dialog/confirmation/confirmationDialog.component";
import ComparePlanDialog from "modules/store/component/dialogs/comparePlanDialog";
import ChangePlanDialog from "modules/store/component/dialogs/changePlanDialog"
import { BUSINESS, LITE, PROFESSIONAL} from "lib/constants/subscriptionPlan";
import { BackButton } from "components/button";
import { getLang } from "app/feature/constants";
import { useSelector } from "react-redux";

const useStyle = makeStyles(theme => ({
  root: {
    color: "#3A4D54",
    display: "grid",
    gridTemplateColumns: "1fr 330px",
    columnGap: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "1fr",
      gridTemplateRows: "1fr auto",
      gridRowGap: theme.spacing(2),
      rowGap: theme.spacing(2)
    }
  },

  subscriptionPlansHeaderRoot: {

  },
  subscriptionPlansHeaderBackIcon: {
    padding: 5,
  },
  subscriptionPlansHeaderTitle: {
    fontWeight: "bold",
    marginLeft: 5,
  },
  subscriptionPlansWrapper: {
    textAlign: "center",
    width: "100%",
    overflowX: "hidden",
  },

  // Tab Header
  subscriptionPlanTabsWrapper: {
    display: "flex",
  },
  subscriptionPlanTabsIndicator: {
    background: '#3A4D54',
  },
  subscriptionPlanTabRoot: {
    background: '#F8F8F8',
    marginRight: 5,
    borderRadius: '5px 5px 0px 0px',
    padding: 15,
  },
  subscriptionPlanTabSelected: {
    background: "#FFFFFF",
  },
  subscriptionPlanTabContainer: {
    display: "flex"
  },
  subscriptionPlanTabImage: {
    height: 30,
  },
  subscriptionPlanTabTypography: {
    padding: 6,
    fontSize: 16,
    fontWeight: 700,
    color: "#3A4D54",
  },

  // Compare Button
  subscriptionPlansCompareButton: {
    display: "flex",
    width: 85,
    height: 36,
    padding: 15,
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    marginLeft: "auto",
  },
  subscriptionPlansCompareButtonText: {
    fontSize: 14,
    fontWeight: 900,
    paddingRight: 5,
  },
  subscriptionPlansCompareButtonSvg: {
    width: "16px",
    height: "16px",
  },

  // Tab Panel
  subscriptionPlanTabPanelRoot: {
    padding: 20
  },
  subscriptionPlanTabPanelCurrentPlan: {
    minHeight: 22,
    marginLeft: "auto",
    fontSize: 10,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 22,
    border: "1px solid #4BCB0F",
    color: "#4BCB0F",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& img": {
      marginRight: 10,
      width: 10,
    }
  },
  subscriptionPlanTabPanelDivider: {
    marginTop: 20,
    marginBottom: 20
  },
  subscriptionPlanTabPanelItemRoot: {
    width: "100%",
    display: "flex",
  },
  subscriptionPlanTabPanelItemIconRoot: {
    width: 20,
    marginRight: 20,
  },
  subscriptionPlanTabPanelItemIcon: {
    width: 20,
  },
  subscriptionPlanTabPanelItemContentRoot: {
    minHeight: 15,
    width: "100%",
    textAlign: "left"
  },
  subscriptionPlanTabPanelItemContentUl: {
    listStyle: "disc",
    margin: 0,
  },
  subscriptionPlanTabPanelItemContentTitle: {
    fontSize: 14,
    lineHeight: "20px",
    fontWeight: "bold",
    textAlign: "left",
    marginBottom: 15,
  },
  subscriptionPlanTabPanelItemContentSubtitle: {
    fontSize: 14,
    lineHeight: "20px",
    fontWeight: "bold",
    textAlign: "left",
  },
  subscriptionPlanTabPanelItemContentSubtitleWithoutBold: {
    fontSize: 14,
    lineHeight: "20px",
    textAlign: "left",
  },
  subscriptionPlanTabPanelItemContentText: {
    fontSize: 12,
    lineHeight: "20px",
    textAlign: "left",
  },
  subscriptionPlanTabPanelItemContentTextBold: {
    fontSize: 12,
    fontWeight: "bold",
    lineHeight: "20px",
    textAlign: "left",
  },
  subscriptionPlanTabPanelItemContentTextGreenBold: {
    fontSize: 12,
    fontWeight: "bold",
    lineHeight: "20px",
    textAlign: "left",
    color: "#4BCB0F",
  },

  subscriptionPlanTabPanelItemContentMetadataUnorderList: {
    marginTop: 0,
    marginBottom: 5,
    listStyle: "disc"
  },
  subscriptionPlanTabPanelItemContentMetadataTitle: {
    fontSize: 12,
    fontWeight: "bold",
    color: "rgba(58, 77, 84, 0.5)",
    marginBottom: 5,
  },
  subscriptionPlanTabPanelItemContentMetadataTitleActive: {
    fontSize: 12,
    fontWeight: "bold",
    color: "#3A4D54",
    marginBottom: 5,
  },
  subscriptionPlanTabPanelItemContentMetadataText: {
    fontSize: 12,
    color: "rgba(58, 77, 84, 0.5)",
  },
  subscriptionPlanTabPanelItemContentMetadataTextActive: {
    fontSize: 12,
    color: "#3A4D54",
  },
  subscriptionPlanTabPanelItemContentDataTitle: {
    fontSize: 12,
    fontWeight: "bold",
    color: "rgba(58, 77, 84, 0.5)",
    marginBottom: 5,
  },
  subscriptionPlanTabPanelItemContentDataTitleActive: {
    fontSize: 12,
    fontWeight: "bold",
    color: "#3A4D54",
    marginBottom: 5,
  },
  subscriptionPlanTabPanelItemContentDataText: {
    fontSize: 12,
    color: "rgba(58, 77, 84, 0.5)",
  },
  subscriptionPlanTabPanelItemContentDataTextActive: {
    fontSize: 12,
    color: "#3A4D54",
  },

  subscriptionPlanTabPanelItemContentPriceBoxRoot: {
    display: "flex",
    flexWrap: "wrap",
  },
  subscriptionPlanTabPanelItemContentPriceBoxButton: {
    width: 150,
    height: 50,
    borderRadius: 5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #3A4D54",
  },
  subscriptionPlanTabPanelItemContentPriceBoxButtonPrice: {
    fontSize: 24,
    fontWeight: "bold",
  },
  subscriptionPlanTabPanelItemContentPriceBoxButtonUnit: {
    paddingTop: 12,
    fontSize: 12,
  },
  subscriptionPlanTabPanelItemContentPriceBoxOr: {
    fontSize: 10,
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: 10,
    marginRight: 10,
  },
  subscriptionPlanTabPanelControllerRoot: {
    padding: 20,
    display: "flex",
    justifyContent: "flex-end",
  },
  subscriptionPlanTabPanelControllerUpgradeButton: {
    color: theme.palette.common.white,
  },
  subscriptionPlanTabPanelControllerUpgradeButtonDowngrade: {
    background: "#FD646F !important",
    color: theme.palette.common.white,
  },

  changePlanWarningDialogDescriptionText: {
    fontSize: 14,
    textAlign: "center",
    color: "#FFFFFF",
    marginTop: 15
  },

  // Loading Dialog
  loadingDialogRoot: {
    padding: 80
  },

  // Side Banner
  sideBannerContainer: {
    [theme.breakpoints.down("md")]: {
      display: "grid",
      // gridTemplateColumns: "repeat(2, 330px) auto",
      columnGap: theme.spacing(2)
    },
    [theme.breakpoints.down("xs")]: {
      display: "grid",
      gridTemplateColumns: "1fr",
      gridTemplateRows: "auto auto",
      gridRowGap: theme.spacing(2),
      rowGap: theme.spacing(2),
    }
  },
  adsBanner: {
    background: "#FFFFFF",
    color: "white",
    borderRadius: theme.spacing(0.5)
  },
  adsBannerTitleBox: {
    background: "#0066FF",
    color: "white",
    paddingTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
    borderTopLeftRadius: theme.spacing(0.5),
    borderTopRightRadius: theme.spacing(0.5),
  },
  adsBannerTitleBoxText: {
    fontWeight: "bold",
  },
  adsBannerTitleBoxLogo: {
    height: 110,
    background: `url(${PlanAdvisor})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "center"
  },
  adsBannerContentBox: {
    background: "#FFFFFF",
    color: "white",
    padding: 20,
  },
  adsBannerContentBoxRow: {
    display: "flex",
    marginTop: 12,
    marginBottom: 12
  },
  adsBannerContentBoxRowDot: {
    width: 16,
    marginRight: 10
  },
  adsBannerContentBoxRowDescription: {
    fontSize: 12,
    fontWeight: "bold",
    color: "#3A4D54",
  },
  adsBannerContactBox: {
    background: "#FFFFFF",
    color: "white",
    padding: 20,
    borderBottomLeftRadius: theme.spacing(0.5),
    borderBottomRightRadius: theme.spacing(0.5),
  },
  adsBannerContactBoxText: {
    fontSize: 12,
    color: "#3A4D54",
  },
  adsBannerContactBoxPhoneNumber: {
    fontSize: 12,
    fontWeight: "bold",
    color: "#3A4D54",
  }
}));

function SubscriptionPlansSideBanner() {
  const classes = useStyle();
  const lang = useSelector(state => state.constant.languages)
  return (
    <Box className={classes.sideBannerContainer}>
      <Box className={classes.adsBanner}>
        <Box className={classes.adsBannerTitleBox}>
          <Typography
            gutterBottom
            variant="subtitle1"
            className={classes.adsBannerTitleBoxText}
          >
            {getLang(lang, 'label.CUSTOM_PLAN')}
          </Typography>
          <Box className={classes.adsBannerTitleBoxLogo}></Box>
        </Box>
        <Box className={classes.adsBannerContentBox}>
          <Box className={classes.adsBannerContentBoxRow}>
            <img src={Check} alt={"check"} className={classes.adsBannerContentBoxRowDot} />
            <Typography className={classes.adsBannerContentBoxRowDescription}>
              {getLang(lang, 'label.WHITE_LABEL')}
            </Typography>
          </Box>
          <Box className={classes.adsBannerContentBoxRow}>
            <img src={Check} alt={"check"} className={classes.adsBannerContentBoxRowDot} />
            <Typography className={classes.adsBannerContentBoxRowDescription}>
              {getLang(lang, 'label.CUSTOM_LANGUAGE')}
            </Typography>
          </Box>
          <Box className={classes.adsBannerContentBoxRow}>
            <img src={Check} alt={"check"} className={classes.adsBannerContentBoxRowDot} />
            <Typography className={classes.adsBannerContentBoxRowDescription}>
              {getLang(lang, 'label.LICENSING')}
            </Typography>
          </Box>
          <Box className={classes.adsBannerContentBoxRow}>
            <img src={Check} alt={"check"} className={classes.adsBannerContentBoxRowDot} />
            <Typography className={classes.adsBannerContentBoxRowDescription}>
              {getLang(lang, 'label.MODULE_CUSTOMISATION')}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box className={classes.adsBannerContactBox}>
          <Typography className={classes.adsBannerContactBoxText} >
            <Typography component={"span"} className={classes.adsBannerContactBoxText}>
              {getLang(lang, 'paragraph.CUSTOM_PLAN_CONTACT_CUSTOMER_SERVICE')}&nbsp;
            </Typography>
            <Typography component={"span"} className={classes.adsBannerContactBoxPhoneNumber}>
              hello@smartkood.com
            </Typography>
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

function SubscriptionPlansHeader({
  title
}) {
  const classes = useStyle()
  return (
    <Box mb={1} display="flex" alignItems="center">
      <BackButton />
      <Typography color="primary" variant="h6" className={classes.subscriptionPlansHeaderTitle}>
        {title}
      </Typography>
    </Box>
  )
}

function TabHeader({
  title,
}) {
  const classes = useStyle();
  let image = null;
  if (title.toLocaleLowerCase() === "lite") image = LitePlan
  if (title.toLocaleLowerCase() === "pro") image = ProPlan
  return (
    <Box className={classes.subscriptionPlanTabContainer}>
      <img
        src={image}
        className={classes.subscriptionPlanTabImage}
        alt={title}
      />
      <Typography className={classes.subscriptionPlanTabTypography}>
        {title}
      </Typography>
    </Box>
  )
}

function TabPanel({
  children,
  value,
  index,
  ...other
}) {
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto--tabpanel-${index}`}
      aria-labelledby={`scrollable-auto--tab-${index}`}
      {...other}
      style={{
        background: "#FFFFFF",
      }}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
}

function SubscriptionPlanTabs({
  userSubscriptionPlan,
  subscriptionPlans,
  activeSubscriptionPlansPageIndex,
  onTabChange,
  onCompareClick,
  onChangePlanClick,
  lang
}) {
  const classes = useStyle()

  return (
    <Box className={classes.subscriptionPlansWrapper}>
      <Box className={classes.subscriptionPlanTabsWrapper}>
        <Tabs
          value={activeSubscriptionPlansPageIndex}
          onChange={onTabChange}
          variant="scrollable"
          classes={{
            indicator: classes.subscriptionPlanTabsIndicator,
          }}
        >
          {
            subscriptionPlans.map(
              (subscriptionPlan, i) =>
              (
                <Tab
                  key={i}
                  id={`simple-tab-${i}`}
                  aria-controls={`simple-tabpanel-${i}`}
                  value={i}
                  classes={{
                    selected: classes.subscriptionPlanTabSelected,
                    root: classes.subscriptionPlanTabRoot,
                  }}
                  label={
                    TabHeader({
                      title: subscriptionPlan.name,
                    })
                  }
                />
              )
            )
          }
        </Tabs>
        {
          subscriptionPlans.length > 1 &&
          <Box
            className={classes.subscriptionPlansCompareButton}
            onClick={() => onCompareClick()}
          >
            <Typography className={classes.subscriptionPlansCompareButtonText}>
            {getLang(lang, 'label.COMPARE')}
            </Typography>
            <img
              className={classes.subscriptionPlansCompareButtonSvg}
              src={CompareSvg}
              alt={"compare"}
            />
          </Box>
        }
      </Box>
      {
        subscriptionPlans.map(
          (subscriptionPlan, i) => {
            const metadataToSplit = Math.ceil(subscriptionPlan.metadata.length / 2)
            const gridOneSubscriptionPlanMetadata = subscriptionPlan.metadata.slice(0, metadataToSplit);
            const gridTwoSubscriptionPlanMetadata = subscriptionPlan.metadata.slice(metadataToSplit);

            const status = IsUpgradeDisableOrSwap({ userSubscriptionPlan, subscriptionPlan, lang });
            return (
              <TabPanel key={i} value={activeSubscriptionPlansPageIndex} index={i}>
                <Box className={classes.subscriptionPlanTabPanelRoot}>
                  <Box className={classes.subscriptionPlanTabPanelItemRoot}>
                    <Typography className={classes.subscriptionPlanTabPanelItemContentSubtitleWithoutBold}>
                      {getLang(lang, 'db_label.' + subscriptionPlan.description)}
                    </Typography>
                  </Box>
                  <Divider className={classes.subscriptionPlanTabPanelDivider} />
                  <Box className={classes.subscriptionPlanTabPanelItemRoot}>
                    <Box className={classes.subscriptionPlanTabPanelItemIconRoot}>
                      <div>
                        <img src={CreditCardSvg} alt={"credit card"} className={classes.subscriptionPlanTabPanelItemIcon} />
                      </div>
                    </Box>
                    <Box className={classes.subscriptionPlanTabPanelItemContentRoot}>
                      <Typography className={classes.subscriptionPlanTabPanelItemContentTitle}>
                        {getLang(lang, 'label.SUBSCRIPTION_TYPE')}
                      </Typography>
                      <Box className={classes.subscriptionPlanTabPanelItemContentPriceBoxRoot}>
                        <Box className={classes.subscriptionPlanTabPanelItemContentPriceBoxButton}>
                          <Typography className={classes.subscriptionPlanTabPanelItemContentPriceBoxButtonPrice}>
                            ${subscriptionPlan.prices.find(price => price.interval === 'month').cost}
                          </Typography>
                          <Typography className={classes.subscriptionPlanTabPanelItemContentPriceBoxButtonUnit}>
                            /{getLang(lang, 'label.MONTH')}
                          </Typography>
                        </Box>
                        <Typography className={classes.subscriptionPlanTabPanelItemContentPriceBoxOr}>
                          {getLang(lang, 'label.OR')}
                        </Typography>
                        <Box className={classes.subscriptionPlanTabPanelItemContentPriceBoxButton}>
                          <Typography className={classes.subscriptionPlanTabPanelItemContentPriceBoxButtonPrice}>
                            ${subscriptionPlan.prices.find(price => price.interval === 'year').cost}
                          </Typography>
                          <Typography className={classes.subscriptionPlanTabPanelItemContentPriceBoxButtonUnit}>
                            /{getLang(lang, 'label.YEAR')}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Divider className={classes.subscriptionPlanTabPanelDivider} />
                  <Box className={classes.subscriptionPlanTabPanelItemRoot}>
                    <Box className={classes.subscriptionPlanTabPanelItemIconRoot}>
                      <div>
                        <img src={AccessibilitySvg} alt={"accessibility"} className={classes.subscriptionPlanTabPanelItemIcon} />
                      </div>
                    </Box>
                    <Box className={classes.subscriptionPlanTabPanelItemContentRoot}>
                      <Typography className={classes.subscriptionPlanTabPanelItemContentTitle}>
                        {getLang(lang, 'label.ACCESSIBILITY')}
                      </Typography>
                      <Grid container>
                        <Grid item lg={6} md={6} xs={12}>
                          {
                            gridOneSubscriptionPlanMetadata.map(
                              (data, j) => {
                                if (data.is_valid === true) {
                                  return (
                                    <Box key={j} >
                                      <Typography className={classes.subscriptionPlanTabPanelItemContentMetadataTitleActive} >
                                        {getLang(lang, 'db_label.'+ data.group)}
                                      </Typography>
                                      <ul className={classes.subscriptionPlanTabPanelItemContentMetadataUnorderList}>
                                        {
                                          data.data.map(
                                            (d, k) => {
                                              if (d.is_valid === true) {
                                                return (
                                                  <li key={k}>
                                                    <Typography className={d.parts.length > 0 ? classes.subscriptionPlanTabPanelItemContentDataTitleActive : classes.subscriptionPlanTabPanelItemContentMetadataTextActive}>
                                                    {getLang(lang, 'db_label.'+ d.module)}  
                                                    </Typography>
                                                    {
                                                      d.parts.length > 0
                                                        ?
                                                        (
                                                          <ul className={classes.subscriptionPlanTabPanelItemContentMetadataUnorderList}>
                                                            {
                                                              d.parts.map(
                                                                (p, l) => {
                                                                  if (p.is_valid === true) {
                                                                    return (
                                                                      <li key={l}>
                                                                        <Typography className={classes.subscriptionPlanTabPanelItemContentDataTextActive}>
                                                                        {getLang(lang, 'db_label.'+ p.module)}  
                                                                        </Typography>
                                                                      </li>
                                                                    )
                                                                  }
                                                                  return null;
                                                                }
                                                              )
                                                            }
                                                          </ul>
                                                        )
                                                        :
                                                        (
                                                          <>
                                                          </>
                                                        )
                                                    }
                                                  </li>
                                                )
                                              }
                                              return null;
                                            }
                                          )
                                        }
                                      </ul>
                                    </Box>
                                  )
                                }
                                return null;
                              }
                            )
                          }
                        </Grid>
                        <Grid item lg={6} md={6} xs={12}>
                          {
                            gridTwoSubscriptionPlanMetadata.map(
                              (data, j) => {
                                if (data.is_valid === true) {
                                  return (
                                    <Box key={j} >
                                      <Typography className={classes.subscriptionPlanTabPanelItemContentMetadataTitleActive} >
                                      {getLang(lang, 'db_label.'+ data.group)}
                                      </Typography>
                                      <ul className={classes.subscriptionPlanTabPanelItemContentMetadataUnorderList}>
                                        {
                                          data.data.map(
                                            (d, k) => {
                                              if (d.is_valid === true) {
                                                return (
                                                  <li key={k}>
                                                    <Typography className={d.parts.length > 0 ? classes.subscriptionPlanTabPanelItemContentDataTitleActive : classes.subscriptionPlanTabPanelItemContentMetadataTextActive}>
                                                    {getLang(lang, 'db_label.'+ d.module)}
                                                    </Typography>
                                                    {
                                                      d.parts.length > 0
                                                        ?
                                                        (
                                                          <ul className={classes.subscriptionPlanTabPanelItemContentMetadataUnorderList}>
                                                            {
                                                              d.parts.map(
                                                                (p, l) => {
                                                                  if (p.is_valid === true) {
                                                                    return (
                                                                      <li key={l}>
                                                                        <Typography className={classes.subscriptionPlanTabPanelItemContentDataTextActive}>
                                                                        {getLang(lang, 'db_label.'+ p.module)}  
                                                                        </Typography>
                                                                      </li>
                                                                    )
                                                                  }
                                                                  return null;
                                                                }
                                                              )
                                                            }
                                                          </ul>
                                                        )
                                                        :
                                                        (
                                                          <>
                                                          </>
                                                        )
                                                    }
                                                  </li>
                                                )
                                              }
                                              return null;
                                            }
                                          )
                                        }
                                      </ul>
                                    </Box>
                                  )
                                }
                                return null;
                              }
                            )
                          }
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Box>
                {
                  status !== getLang(lang, 'label.DISABLE') &&
                  (
                    <>
                      <Divider />
                      <Box className={classes.subscriptionPlanTabPanelControllerRoot}>
                        <Button
                          variant="contained"
                          color="secondary"
                          classes={{
                            root: classes.subscriptionPlanTabPanelControllerUpgradeButton
                          }}
                          onClick={() => onChangePlanClick(subscriptionPlan)}
                          children={status}
                        />
                      </Box>
                    </>
                  )
                }
              </TabPanel>

            )
          }
        )
      }
    </Box>

  )
}

function IsUpgradeDisableOrSwap({
  userSubscriptionPlan,
  subscriptionPlan,
  lang
}) {
  if (userSubscriptionPlan.productName.toLocaleLowerCase() === subscriptionPlan.name.toLocaleLowerCase()) {
    return getLang(lang, 'label.SWAP')
  } else {
    if (userSubscriptionPlan.productName.toLocaleLowerCase() === PROFESSIONAL.toLocaleLowerCase()) {
      return getLang(lang, 'label.DISABLE')
    } else if (userSubscriptionPlan.productName.toLocaleLowerCase() === BUSINESS.toLocaleLowerCase()) {
      if (subscriptionPlan.name.toLocaleLowerCase() === PROFESSIONAL.toLocaleLowerCase()) {
        return getLang(lang, 'label.UPGRADE')
      }
      if (subscriptionPlan.name.toLocaleLowerCase() === LITE.toLocaleLowerCase()) {
        return getLang(lang, 'label.DISABLE')
      }
    } else if (userSubscriptionPlan.productName.toLocaleLowerCase() === LITE.toLocaleLowerCase()) {
      if (subscriptionPlan.name.toLocaleLowerCase() === PROFESSIONAL.toLocaleLowerCase()) {
        return getLang(lang, 'label.UPGRADE')
      }
    }
    return getLang(lang, 'label.DISABLE')
  }
}

function ChangePlanWarningDialog({
  isLoadingChangePlanWarningDialog,
  userSubscriptionPlan,
  subscriptionPlan,
  isChangePlanWarning,
  onChangePlanWarningCloseClick,
  onChangePlanWarningConfirmClick,
  lang
}) {
  const classes = useStyle()
  let title = getLang(lang, 'label.DOWNGRADE')
  if (subscriptionPlan) {
    title = IsUpgradeDisableOrSwap({ userSubscriptionPlan: userSubscriptionPlan, subscriptionPlan: subscriptionPlan , lang}).toLocaleLowerCase()
    title = title.charAt(0).toUpperCase() + title.slice(1);
  }

  return (
    <ConfirmationDialogComponent
      isOpen={isChangePlanWarning}
      handleClose={onChangePlanWarningCloseClick}
      handleProceed={onChangePlanWarningConfirmClick}
      title={title + ` ${getLang(lang, 'label.PLAN')}`}
      isLoading={isLoadingChangePlanWarningDialog}
      description={
        (
          <Box>
            <Typography className={classes.changePlanWarningDialogDescriptionText}>
              {getLang(lang, 'paragraph.CREDIT_BALANCE_REMAIN')}
            </Typography>
            <Typography className={classes.changePlanWarningDialogDescriptionText}>
              {getLang(lang, 'paragraph.PLEASE_CONFIRM')}
            </Typography>
          </Box>
        )
      }
      remarks={getLang(lang, 'paragraph.PROCESS_IRREVERSIBLE')}
      type={"danger"}
    />
  )
}

export default function SubscriptionPlansComponent({
  title,
  isLoadedSubscriptionPlans,
  registrationType,
  userSubscriptionPlan,
  subscriptionPlans,
  activeSubscriptionPlansPageIndex,
  onTabChange,
  isCompareDialogOpen,
  compareType,
  onCompareClick,
  onCompareCloseClick,
  onCompareTypeClick,
  isLoadingChangeSubscriptionPlanCharges,
  selectedPlan,
  selectedPlanInterval,
  changeSubscriptionPlanCharges,
  onChangePlanClick,
  onChangePlanIntervalClick,
  onChangePlanCloseClick,
  onProceedChangePlanClick,
  isLoadingChangePlanWarningDialog,
  isChangePlanWarningDialogOpen,
  onChangePlanWarningCloseClick,
  onChangePlanWarningConfirmClick,
  lang

}) {
  const classes = useStyle();
  const theme = useTheme(); // eslint-disable-line

  return (
    <Box>
      <SubscriptionPlansHeader
        title={title}
      />
      <Box className={classes.root}>
        {
          isLoadedSubscriptionPlans
            ?
            <LoadingComponent />
            :
            <SubscriptionPlanTabs
              registrationType={registrationType}
              userSubscriptionPlan={userSubscriptionPlan}
              subscriptionPlans={subscriptionPlans}
              activeSubscriptionPlansPageIndex={activeSubscriptionPlansPageIndex}
              onTabChange={onTabChange}
              onCompareClick={onCompareClick}
              onChangePlanClick={onChangePlanClick}
              lang={lang}
            />
        }
        <SubscriptionPlansSideBanner />
      </Box>
      <ComparePlanDialog
        registrationType={registrationType}
        userSubscriptionPlan={userSubscriptionPlan}
        isCompareDialogOpen={isCompareDialogOpen}
        subscriptionPlans={subscriptionPlans}
        compareType={compareType}
        onCompareCloseClick={onCompareCloseClick}
        onCompareTypeClick={onCompareTypeClick}
      />
      <ChangePlanDialog
        isLoadingChangeSubscriptionPlanCharges={isLoadingChangeSubscriptionPlanCharges}
        changeSubscriptionPlanCharges={changeSubscriptionPlanCharges}
        registrationType={registrationType}
        userSubscriptionPlan={userSubscriptionPlan}
        subscriptionPlan={selectedPlan}
        subscriptionType={selectedPlanInterval}
        onChangePlanCloseClick={onChangePlanCloseClick}
        onChangePlanIntervalClick={onChangePlanIntervalClick}
        onProceedChangePlanClick={onProceedChangePlanClick}
      />
      <ChangePlanWarningDialog
        isLoadingChangePlanWarningDialog={isLoadingChangePlanWarningDialog}
        userSubscriptionPlan={userSubscriptionPlan}
        subscriptionPlan={selectedPlan}
        isChangePlanWarning={isChangePlanWarningDialogOpen}
        onChangePlanWarningCloseClick={onChangePlanWarningCloseClick}
        onChangePlanWarningConfirmClick={onChangePlanWarningConfirmClick}
        lang={lang}
      />
    </Box >
  );
}
