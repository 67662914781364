import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { unwrapResult } from '@reduxjs/toolkit';
import { selectLoading } from "modules/notification";
import {
  getAllProductCategoryListing,
  addProductCategories,
  updateCategoryStatus,
  editProductCategory,
  deleteProductCategory,
  updateCategoriesStatus
} from "modules/product-mgmt/redux/action/productCategory.action";
import {
  changeQuickEdit,
  changeSelectedCategory,
  changeBulkSelectedAllCategory,
  changeBulkSelectedAvailableCategory,
  refreshCategoryQuickEdit,
  resetCategoryListing,
} from "modules/product-mgmt/redux/slice/products.slice"
import CreateCategoryDialog from 'modules/product-mgmt/components/dialog/createCategoryDialog';
import CategoryListPanelComponent from './categoryListPanel.component';
import GeneralDialog from 'components/dialog/generalDialog';
import AlertIcon from 'assets/svg/alert.svg';
import { PRODUCT_CATEGORY_VIEW, PRODUCT_CATEGORY_EDIT, PRODUCT_CATEGORY_DELETE } from "lib/constants/accessRights"
import { generatePaginationOptions } from "lib/helper";
import { getLang } from "app/feature/constants";

function CategoryListPanelContainer({
  handleCreateCategory
}) {
  const history = useHistory();
  const dispatch = useDispatch()
  
  const isCreateDialogOpen = useSelector(state => state.products.isOpen)
  const hasProductCategoryViewAccessRight = useSelector(state => state.profile.accessRights.includes(PRODUCT_CATEGORY_VIEW));
  const hasProductCategoryEditAccessRight = useSelector(state => state.profile.accessRights.includes(PRODUCT_CATEGORY_EDIT));
  const hasProductCategoryDeleteAccessRight = useSelector(state => state.profile.accessRights.includes(PRODUCT_CATEGORY_DELETE));
  const totalFiltered = useSelector(state => state.products.categoryList.totalFiltered);
  const data = useSelector(state => state.products.categoryList.data)
  const selectedIds = useSelector(state => state.products.categoryList.selectedId)
  const isFetching = useSelector(state => selectLoading(state, getAllProductCategoryListing.typePrefix))
  const isLoadingCategoryListError = useSelector(state => state.products.categoryList.isError)
  const isSubmittingAddCategory = useSelector((state) => selectLoading(state, addProductCategories.typePrefix))
  const isQuickEdit = useSelector(state => state.products.categoryList.isEdit)
  const lang = useSelector(state => state.constant.languages);
  const [newName, setNewName] = useState("");
  const [newStatus, setNewStatus] = useState(0);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [deleteCategoryId, setDeleteCategoryId] = useState("")
  const [isSelectAll, setIsSelectAll] = useState(false)
  const [paginationOptions, setPaginationOptions] = useState([]);
  const [filter, setFilter] = useState({
    search: "",
    status: "",
    page: 0,
    rowsPerPage: 25
  })

  useEffect(()=>{
    if(!hasProductCategoryViewAccessRight){
      history.push('/admin/dashboard')
    }
  }, [history, hasProductCategoryViewAccessRight])

  useEffect(() => {
    return () => {
      dispatch(resetCategoryListing())
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    dispatch(getAllProductCategoryListing({
      length: filter.rowsPerPage,
      start: filter.rowsPerPage * filter.page,
      search: filter.search,
      status: filter.status,
    }))    
  }, [
    dispatch,
    filter
  ])

  useEffect(() => {
    setPaginationOptions(generatePaginationOptions(totalFiltered))
  }, [totalFiltered])

  const handleFetchCategoryList = () => {
    setFilter((prev) => ({
      ...prev,
      page: 0
    }))
    handleDeselectAll()
  }

  const handleReload = () => {
    dispatch(getAllProductCategoryListing({
      length: filter.rowsPerPage,
      start: filter.rowsPerPage * filter.page,
      search: filter.search,
      status: filter.status,
    }))
  }

  const handleChangePage = (event, newPage) => {
    setFilter((prev) => ({
      ...prev,
      page: newPage,
    }))
  }

  const handleChangeRowsPerPage = event => {
    setFilter((prev) => ({
      ...prev,
      page: 0,
      rowsPerPage: event.target.value
    }))
  }

  const handleSearch = value => {
    let tempStatus = ""

    if (value.status === "Enable") {
      tempStatus = "enabled"
    } else if (value.status === "Disable") {
      tempStatus = "disabled"
    }

    setFilter({
      search: value.search,
      status: tempStatus,
      page: 0,
      rowsPerPage: filter.rowsPerPage
    })
    handleDeselectAll()
  }

  const handleSubmitCategories = (value) => {
    dispatch(addProductCategories({
      categories: value.categories
    }))
      .then(unwrapResult)
      .then(() => {
        handleFetchCategoryList()
        handleCreateCategory(false)
      })
  }

  const handleCategoryStatus = (id, status) => {
    dispatch(updateCategoryStatus({ id: id, status: status ? "enabled" : "disabled" }))
  }

  const handleUpdateCategories = (item, index) => {
    let statusTemp = ""

    if (newStatus === 0 || newName === "") {
      return;
    }

    if (newStatus === 1) {
      statusTemp = "enabled"
    }

    if (newStatus === 2) {
      statusTemp = "disabled"
    }

    dispatch(editProductCategory({
      id: item.id,
      name: newName,
      status: statusTemp
    }))
      .then(() => {
        handleQuickEdit(index, false, item)
        handleRefreshQuickEdit(index, newName)
      })
  }

  const handleRefreshQuickEdit = (index, name) => {
    dispatch(refreshCategoryQuickEdit({ index, name }))
  }

  const handleDeleteCategory = (id) => {
    setIsDeleteDialogOpen(true)
    setDeleteCategoryId(id)
  }

  const handleSubmitDeleteCategory = () => {
    dispatch(deleteProductCategory({
      id: deleteCategoryId
    }))
      .then(unwrapResult)
      .then(() => {
        handleDeselectAll()
        handleFetchCategoryList()
      })
      .finally(() => {
        setIsDeleteDialogOpen(false)
      })
  }

  const handleQuickEdit = (index, isEdit, item) => {
    updateNewName(item.name)
    updateNewStatus(item.status)
    handleDeselectAll()
    dispatch(changeQuickEdit({ index, isEdit }))
  }

  const updateNewName = (value) => {
    setNewName(value)
  }

  const updateNewStatus = (value) => {
    setNewStatus(value)
  }

  const handleSelectCategory = (index, isSelected) => {
    dispatch(changeSelectedCategory({ index: index, isSelected: isSelected }))
  }

  const handleSelectAvailable = () => {
    dispatch(changeBulkSelectedAvailableCategory({ value: true }))
  }

  const handleDeselectAll = () => {
    setIsSelectAll(false)
    dispatch(changeBulkSelectedAllCategory({ value: false }))
  }

  const handleSelectAll = () => {
    setIsSelectAll(true)
    dispatch(changeBulkSelectedAllCategory({ value: true }))
  }

  const handleChangeCategoriesStatus = (value) => {
    const tempFilter = {
      length: filter.rowsPerPage,
      start: filter.rowsPerPage * filter.page,
      search: filter.search,
      status: filter.status,
    }

    const tempUpdate = {
      status: value === "Enable Selected" ? "enabled" : "disabled",
      isselectedall: isSelectAll,
      uuid: selectedIds
    }

    dispatch(updateCategoriesStatus({ filter: tempFilter, update: tempUpdate }))
      .then(() => {
        handleFetchCategoryList()
      })
  }



  return (
    <>
      <CategoryListPanelComponent
        hasProductCategoryEditAccessRight={hasProductCategoryEditAccessRight}
        hasProductCategoryDeleteAccessRight={hasProductCategoryDeleteAccessRight}
        data={data}
        totalFiltered={totalFiltered}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleReload={handleReload}
        handleSearch={handleSearch}
        selectedCount={!isSelectAll ? selectedIds.length : totalFiltered - selectedIds.length}
        isFetching={isFetching}
        isSelectAll={isSelectAll}
        selectedIds={selectedIds}
        paginationOptions={paginationOptions}
        isSelected={(!isSelectAll && selectedIds.length > 0) || (isSelectAll && selectedIds.length < totalFiltered)}
        page={filter.page}
        search={filter.search}
        statusFilter={filter.status}
        rowsPerPage={filter.rowsPerPage}
        handleCategoryStatus={handleCategoryStatus}
        isLoadingCategoryListError={isLoadingCategoryListError}
        handleUpdateCategories={handleUpdateCategories}
        isQuickEdit={isQuickEdit}
        handleQuickEdit={handleQuickEdit}
        name={newName}
        status={newStatus}
        updateName={updateNewName}
        updateStatus={updateNewStatus}
        handleDeleteCategory={handleDeleteCategory}
        handleSelectCategory={handleSelectCategory}
        handleSelectAll={handleSelectAll}
        handleDeselectAll={handleDeselectAll}
        handleSelectAvailable={handleSelectAvailable}
        handleChangeCategoriesStatus={handleChangeCategoriesStatus}
        handleCreateCategory={handleCreateCategory}
        lang={lang}
      />
      <CreateCategoryDialog
        isOpen={isCreateDialogOpen}
        handleClose={() => handleCreateCategory(false)}
        handleSubmit={handleSubmitCategories}
        isSubmittingAddCategory={isSubmittingAddCategory}
      />
      <GeneralDialog
        isOpen={isDeleteDialogOpen}
        handleClose={() => setIsDeleteDialogOpen(false)}
        handleProceed={handleSubmitDeleteCategory}
        title={getLang(lang, "label.DELETE_CATEGORY")}
        description={getLang(lang, "paragraph.DELETE_CATEGORY_QUESTION")}
        type="danger"
        icon={<img src={AlertIcon} alt="alert" />}
      />
    </>
  )
}

export default CategoryListPanelContainer;