import { createAsyncThunk } from "@reduxjs/toolkit";
import { MARKETPLACE } from "./marketplace.slice";
import paymentApi from "app/api/payment";

export const fetchFormula = createAsyncThunk(
  `${MARKETPLACE}/fetchFormula`,
  async (payload, { rejectWithValue }) => {
    return paymentApi.formula
      .post({ module: payload })
      .then(response => response)
      .catch(error => rejectWithValue(error));
  }
);

export const checkoutPurchase = createAsyncThunk(
  `${MARKETPLACE}/checkoutPurchase`,
  async (payload, { rejectWithValue, getState }) => {
    const moduleName = payload;
    const { qrCode, selectedPaymentCardId } = getState().marketplace;

    const quantity = moduleName === "serial_number" ? qrCode.quantity : 1;
    const coupon_code = moduleName === "serial_number" ? qrCode.couponCode : "";

    return paymentApi.payment
      .post({
        payment_method: selectedPaymentCardId,
        quantity,
        module: moduleName,
        coupon_code
      })
      .then(response => response.data)
      .catch(error => rejectWithValue(error));
  }
);

export const checkCoupon = createAsyncThunk(
  `${MARKETPLACE}/checkCoupon`,
  async (payload, { rejectWithValue }) => {
    return paymentApi.coupon
      .get(payload)
      .then(response => response.data)
      .catch(error => rejectWithValue(error));
  }
);

export const getSubscriptionPlans = createAsyncThunk(
  `${MARKETPLACE}/getSubscriptionPlans`,
  async (payload, { rejectWithValue }) => {
    return paymentApi.localPlans
      .get(payload)
      .then(response => response.data)
      .catch(error => rejectWithValue(error));
  }
);

export const swapSubscriptionPlanPreview = createAsyncThunk(
  `${MARKETPLACE}/swapSubscriptionPlanPreview`,
  async (payload, { rejectWithValue }) => {
    return paymentApi.swapSubscriptionPreview
      .get(payload)
      .then(response => response.data)
      .catch(error => rejectWithValue(error));
  }
);

export const swapSubscriptionPlan = createAsyncThunk(
  `${MARKETPLACE}/swapSubscriptionPlan`,
  async (payload, { rejectWithValue }) => {
    return paymentApi.swapSubscription
      .post(payload)
      .then(response => response.data)
      .catch(error => rejectWithValue(error));
  }
);