import React from "react"
import { Route, Switch, Redirect } from "react-router-dom"
import { ROOT, CONSUMER_DETAILS } from "./config/consumer.route"
import ConsumerListingPage from "./pages/consumer-listing"
import ConsumerDetailsPage from "./pages/consumer-details"
import { consumerReducer } from './redux/slice/consumer.slice'

export default function Moduleconsumer() {
  return (
    <Switch>
      <Route exact path={ROOT} component={ConsumerListingPage} />
      <Route exact path={CONSUMER_DETAILS} component={ConsumerDetailsPage} />
      <Route path="*">
        <Redirect to="/admin/invalid" />
      </Route>
    </Switch>
  )
}

export {
  ROOT,
  CONSUMER_DETAILS,
  ConsumerDetailsPage,
  consumerReducer
}
