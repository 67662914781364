import axios from "axios";

const updateAdditionalInfo = (parameters) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/serialnumber/api/v1/additional-info-value/save`, {
        type: parameters.type,
        info: parameters.info,
        supported_languages: parameters.supported_languages,
      })
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

export default updateAdditionalInfo;
