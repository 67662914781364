import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Button from "@material-ui/core/Button";
import { withStyles, lighten } from "@material-ui/core/styles";

// We can inject some CSS into the DOM.
const styles = theme => ({
  root: {
    background: lighten(theme.palette.primary.light, 0.8),
    color: lighten(theme.palette.primary.main, 0.2),
    borderRadius: theme.spacing(1),
    textTransform: "none",
    "&:hover": {
      background: lighten(theme.palette.primary.light, 0.7)
    }
  }
});

function PrimaryLightButton(props) {
  const { classes, children, className, ...other } = props;

  return (
    <Button className={clsx(classes.root, className)} {...other}>
      {children || "Primary light button"}
    </Button>
  );
}

PrimaryLightButton.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string
};

export default withStyles(styles)(PrimaryLightButton);
