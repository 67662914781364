import React from "react";
import RegLuckyDrawFormComponent from "./regLuckyDrawForm.component";
import { useDispatch, useSelector } from "react-redux";
import {
  reorderRegisterFormField,
} from "modules/lucky-draw/redux";

function RegLuckyDrawFormContainer({ children, type,formRef}) {
  const dispatch = useDispatch();
  const luckyDrawRegisterForm = useSelector(
    (state) => state.luckyDraw.participantRegistrationForm
  );
  const lang = useSelector(state => state.constant.languages)

  const handleDragEnd = (destination, source) => {

    if (!destination) return;
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) return;
    dispatch(
      reorderRegisterFormField({
        sourceIndex: source.index,
        destinationIndex: destination.index,
        latestform:formRef.current.values.form
      })
    );
  };

  return (
    <RegLuckyDrawFormComponent
      children={children}
      luckyDrawRegisterForm={luckyDrawRegisterForm}
      formRef={formRef}
      type={type}
      handleDragEnd={handleDragEnd}
      lang={lang}
    />
  );
}

export default RegLuckyDrawFormContainer;
