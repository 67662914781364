export const SURVEY = "survey"

export const surveyQuestionType = {
    text: "text",
    selection: "select"
}

export const surveyTemplateType = {
    feedback: "feedback",
    followUp: "followUp",
    marketing: "marketing"
}