import axios from "axios";

export function getTotalResponseByChoiceCount({
  surveyUuid,
  questionUuid
}) {
  return new Promise((resolve, reject) => {
    axios.get(`/consumer/admin/api/v1/survey-respondent/${surveyUuid}/${questionUuid}/barChart`)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data))
  })
}