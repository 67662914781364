import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ITEM_REPLACEMENT_HANDOFF } from "lib/constants/accessRights";
import ReplacementHandoffPanelComponent from "./replacementHandoffPanel.component";
import LoadingComponent from "components/loading";
import { selectLoading } from "modules/notification";
import {
  exportHandoff,
  getWarrantyHandoffList,
  resetHandoffList,
} from "modules/warranty/redux";
import { generatePaginationOptions } from "lib/helper";
import HandoffDetailDialog from "../../dialog/handoffDetailDialog";
import {unwrapResult} from "@reduxjs/toolkit";

function ReplacementHandoffPanelContainer() {
  const history = useHistory();
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.constant.languages);
  const language = useSelector((state) => state.profile.language);
  const hasHandoffViewAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(ITEM_REPLACEMENT_HANDOFF)
  );

  const isFetching = useSelector((state) =>
    selectLoading(state, getWarrantyHandoffList.typePrefix)
  );

  const totalFiltered = useSelector(
    (state) => state.warrantyHandoff.handoffList.totalFiltered
  );
  const list = useSelector((state) => state.warrantyHandoff.handoffList.list);
  const isError = useSelector(
    (state) => state.warrantyHandoff.handoffList.isError
  );
  const token = useSelector((state) => state.session.accessToken);

  const [paginationOptions, setPaginationOptions] = useState([]);
  const [tableFilter, setTableFilter] = useState({
    page: 0,
    rowsPerPage: 25,
    nodeIds: [],
    branch: [],
    search: "",
    status: "",
    fromDate: null,
    toDate: null
  });
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [isExporting, setIsExporting] = useState(false);

  useEffect(() => {
    if (!hasHandoffViewAccessRight) {
      history.push("/admin/dashboard");
    }

    return () => {
      dispatch(resetHandoffList());
    };
  }, [dispatch, history, hasHandoffViewAccessRight]);

  useEffect(() => {
    handleDataLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableFilter]);

  useEffect(() => {
    setPaginationOptions(generatePaginationOptions(totalFiltered));
  }, [totalFiltered]);

  const handleDataLoad = () => {
    let payload = {
      length: tableFilter.rowsPerPage,
      start: tableFilter.rowsPerPage * tableFilter.page,
      filter: {
        search: tableFilter.search,
        status: tableFilter.status,
        nodes: tableFilter.nodeIds,
        fromDate: tableFilter.fromDate,
        toDate: tableFilter.toDate,
      },
    };
    dispatch(getWarrantyHandoffList(payload));
  };

  const handleChangePage = (event, newPage) => {
    let newData = {
      ...tableFilter,
      page: newPage,
    };
    setTableFilter(newData);
  };

  const handleChangeRowsPerPage = (event) => {
    let newData = {
      ...tableFilter,
      page: 0,
      rowsPerPage: event.target.value,
    };
    setTableFilter(newData);
  };

  const handleSearch = (filter) => {
    const { search, branch, status, dateRange } = filter;
    let newData = {
      ...tableFilter,
      page: 0,
      nodeIds: branch.length > 0 ? branch.map((br) => br.node_id) : [],
      branch: branch,
      search,
      status: status === null ? "" : status.value,
      fromDate: dateRange.from,
      toDate: dateRange.to,
    };
    setTableFilter(newData);
  };

  const clearSearch = () => {
    let newData = {
      ...tableFilter,
      page: 0,
      branch: [],
      nodeIds: [],
      status: "",
      search: "",
      fromDate: null,
      toDate: null
    };
    setTableFilter(newData);
  };

  const changeOrder = (orderBy, order) => {
    let newData = {
      ...tableFilter,
      order,
      orderBy,
    };
    setTableFilter(newData);
  };

  const handleViewDetail = (record) => {
    setSelectedRecord(record);
  };

  const handleExport = (type) => {
    setIsExporting(true);
    const info = {
      token: token,
      type: type,
      length: tableFilter.rowsPerPage,
      start: tableFilter.rowsPerPage * tableFilter.page,
      node: tableFilter.nodeIds,
      status: tableFilter.status,
      search: tableFilter.search,
      fromDate: tableFilter.fromDate,
      toDate: tableFilter.toDate,
    }
    dispatch(exportHandoff(info))
      .then(unwrapResult)
      .finally(() => {
        setIsExporting(false);
      });
  };

  if (!hasHandoffViewAccessRight) return <LoadingComponent />;

  return (
    <>
      <ReplacementHandoffPanelComponent
        lang={lang}
        language={language}
        list={list}
        isFetching={isFetching}
        isError={isError}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        page={tableFilter.page}
        rowsPerPage={tableFilter.rowsPerPage}
        totalFiltered={totalFiltered}
        handleSearch={(filter) => handleSearch(filter)}
        clearSearch={clearSearch}
        handleReload={handleDataLoad}
        paginationOptions={paginationOptions}
        tableFilter={tableFilter}
        changeOrder={changeOrder}
        handleViewDetail={handleViewDetail}
        handleExport={handleExport}
        isExporting={isExporting}
      />
      <HandoffDetailDialog
        uuid={selectedRecord?.uuid ?? null}
        handleClose={() => setSelectedRecord(null)}
        handleTableReload={handleDataLoad}
      />
    </>
  );
}

export default ReplacementHandoffPanelContainer;
