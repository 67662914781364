import axios from "axios"

export default function updateBonanzaPoint({
    id,
    point,
    modules,
    status,
    parentId,
    startAt,
    endAt
}) {
    return new Promise((resolve, reject) => {
        axios.put(`/campaign/api/admin/campaigns-setting/${id}`,
            {
                amount: point,
                productId: modules,
                status,
                parentId,
                startAt,
                endAt
            })
            .then(response => resolve(response.data))
            .catch(error => reject(error.response.data))
    })
}