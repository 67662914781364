import React, { useRef } from "react";
import {
  makeStyles,
  Typography,
  Popover,
  Box,
  Divider,
  InputAdornment,
  Button,
  // MenuList,
  // MenuItem,
  List,
  ListItem,
  ListItemText,
  Collapse
} from "@material-ui/core";
import {
  Search as SearchIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from "@material-ui/icons"
import { Skeleton } from '@material-ui/lab';
import InputTextField from 'components/input/inputTextField';
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles((theme) => ({
  button: {
    padding: 0,
    minWidth: 0
  },
  perfectScrollBar: {
    height: 200
  },
  list: {
    paddingTop: 6,
    paddingBottom: 6
  },
  buttonAddBrandModel: {
    display: "flex",
    padding: 0,
    minWidth: 0
  },
  createLink: {
    padding: theme.spacing(2)
  }
}));

function BrandModelSingleSelectPanelComponent({
  open,
  anchorEl,
  handleClose,
  id,
  style,
  searchable,
  handleSearch,
  placeholder,
  items,
  isFetching,
  emptyMessage,
  handleChange,
  anchorOrigin,
  transformOrigin,
  PaperProps,
  openChildList,
  handleOpenChildList,
  brandValue,
  modelValue,
  modelSelected,
  brandSelected,
  handleAddBrandModel,
  lang
}) {
  const classes = useStyle();
  const textRef = useRef();

  return (
    <Popover
      id={id}
      open={open}
      style={style}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      elevation={2}
      PaperProps={PaperProps}
    >
      {searchable && (
        <Box>
          <InputTextField
            variant="filled"
            size="small"
            fullWidth
            inputRef={textRef}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end' style={{ paddingRight: 6 }}>
                  <Button
                    onClick={() => handleSearch(textRef.current.value)}
                    className={classes.button}
                  >
                    <SearchIcon />
                  </Button>
                </InputAdornment>
              ),
              style: {
                backgroundColor: "#fff",
                paddingRight: 0
              }
            }}
            inputProps={{
              onKeyDown: (e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  handleSearch(textRef.current.value)
                  return false
                }
              },
              style: {
                padding: 8
              }
            }}
            placeholder={placeholder || getLang(lang, "placeholder.SEARCH")}
          />
        </Box>
      )}
      <Divider />
      <Box style={{ maxHeight: "250px", overflow: "auto" }}>
        {isFetching ? (
          <>
            <Skeleton variant="text" animation="wave" height={25} />
            <Skeleton variant="text" animation="wave" height={25} />
            <Skeleton variant="text" animation="wave" height={25} />
            <Skeleton variant="text" animation="wave" height={25} />
          </>
        ) : items.length > 0 ? (
          <List>
            {items.map((item) => {
              return (
                <Box key={item.id}>
                  <ListItem
                    button
                    onClick={() => {
                      if (openChildList === item.id) {
                        handleOpenChildList(item.id, "less")
                      }

                      if (openChildList !== item.id && item.models.length > 0) {
                        handleOpenChildList(item.id, "more")
                      }

                      if (item.models.length === 0) {
                        handleChange(item)
                      }
                    }}
                    selected={brandSelected(item)}
                  >
                    <ListItemText primary={item.name} />
                    {openChildList === item.id ?
                      <ExpandLessIcon onClick={() => handleOpenChildList(item.id, "less")} />
                      : openChildList !== item.id && item.models.length > 0 ?
                        <ExpandMoreIcon onClick={() => handleOpenChildList(item.id, "more")} />
                        : null}
                  </ListItem>
                  <Collapse in={openChildList === item.id} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {item.models.length > 0 && item.models.map((model, modelIndex) => (
                        <ListItem
                          key={modelIndex}
                          button
                          onClick={() => handleChange(item, model)}
                          selected={modelSelected(model)}
                        >
                          <ListItemText primary={model.name} />
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                </Box>
              )
            })}
          </List>
        ) : (
          <Box py={6} style={{ textAlign: "center" }}>
            <Typography variant='body1'>{emptyMessage}</Typography>
          </Box>
        )}
       
      </Box>
       <>
          <Divider />
          <Box className={classes.createLink}>
            <Button
              color="primary"
              className={classes.buttonAddBrandModel}
              onClick={handleAddBrandModel}
            >
              <Typography
                variant="body2"
                color="primary"
                style={{ fontWeight: "bold", paddingRight: "4px" }}
              >
                {getLang(lang,"label.ADD_NEW_BRAND_MODEL")}
              </Typography>
              <ArrowForwardIcon fontSize="small" />
            </Button>
          </Box>
        </>
    </Popover>
  )
}

export default BrandModelSingleSelectPanelComponent