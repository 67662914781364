import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import CounterfeitPanelComponent from "./counterfeitPanel.component";
import { connect } from "react-redux";
import { getCounterfeitReportList } from "../../../redux";
import { selectLoading } from "modules/notification";
import { generatePaginationOptions, updateUrlQueryParam } from "lib/helper";
import moment from "moment";

function CounterfeitPanelContainer({
  isFetching,
  counterfeitReportData,
  length,
  filteredlength,
  getCounterfeitReportList,
}) {
  const [paginationOptions, setPaginationOptions] = useState([]);
  const totalRecords = useSelector(
    (state) => state.counterfeitReport.CounterfeitReportTable.totalRecords
  );
  const isLoading = useSelector((state) =>
    selectLoading(state, getCounterfeitReportList.typePrefix)
  );
  const lang = useSelector((state) => state.constant.languages);

  const query = new URLSearchParams(window.location.search);
  const encode = query.get("enc");
  const date_start = query.get("start") || "";
  const date_end = query.get("end") || "";

  const [tableFilter, setTableFilter] = useState({
    page: 0,
    rowsPerPage: 25,
    order: "desc",
    orderBy: "created_at",
    search: "",
    fromDate: date_start || "",
    toDate: date_end || "",
  });

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableFilter, encode]);

  useEffect(() => {
    setPaginationOptions(generatePaginationOptions(totalRecords));
  }, [totalRecords]);

  const getData = () => {
    getCounterfeitReportList({
      length: tableFilter.rowsPerPage,
      start: tableFilter.rowsPerPage * tableFilter.page,
      order: tableFilter.order,
      column: tableFilter.orderBy,
      search: tableFilter.search,
      from_date: tableFilter.fromDate
        ? moment(tableFilter.fromDate).format("YYYY-MM-DD")
        : "",
      to_date: tableFilter.toDate
        ? moment(tableFilter.toDate).format("YYYY-MM-DD")
        : "",
      enc: encode,
    });
  };

  const handleChangePage = (event, newPage) => {
    let newData = {
      ...tableFilter,
      page: newPage,
    };
    setTableFilter(newData);
  };

  const handleChangeRowsPerPage = (event) => {
    let newData = {
      ...tableFilter,
      page: 0,
      rowsPerPage: event.target.value,
    };
    setTableFilter(newData);
  };

  const handleSearch = (values) => {
    let newData = {
      ...tableFilter,
      page: 0,
      search: values.search,
      fromDate: values.dateRange?.from || "",
      toDate: values.dateRange?.to || "",
    };
    setTableFilter(newData);
    if (values.dateRange) {
      updateUrlQueryParam({
        start: values.dateRange?.from,
        end: values.dateRange.to,
      });
    }
  };

  const clearSearch = () => {
    let newData = {
      ...tableFilter,
      page: 0,
      search: "",
      fromDate: "",
      toDate: "",
    };
    setTableFilter(newData);
    updateUrlQueryParam(null, ["start", "end"]);
  };

  const changeOrder = (order) => {
    let newData = {
      ...tableFilter,
      order,
    };
    setTableFilter(newData);
  };

  return (
    <CounterfeitPanelComponent
      counterfeitReportData={counterfeitReportData}
      isFetching={isFetching}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      isLoading={isLoading}
      totalFiltered={filteredlength}
      handleSearch={handleSearch}
      handleReload={getData}
      paginationOptions={paginationOptions}
      lang={lang}
      tableFilter={tableFilter}
      clearSearch={clearSearch}
      changeOrder={changeOrder}
    />
  );
}

const mapStateToProps = (state) => ({
  counterfeitReportData: state.counterfeitReport.CounterfeitReportTable.data,
  length: state.counterfeitReport.CounterfeitReportTable.totalRecords,
  filteredlength:
    state.counterfeitReport.CounterfeitReportTable.filteredTotalRecords,
  isFetching: selectLoading(state, getCounterfeitReportList.typePrefix),
});

const mapDispatchToProps = (dispatch) => ({
  getCounterfeitReportList: (pageSetting) =>
    dispatch(getCounterfeitReportList(pageSetting)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CounterfeitPanelContainer);
