import React from "react";
import {
  makeStyles,
  Box,
  Typography,
  Divider,
  Button,
  FormControl,
  RadioGroup,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Radio
} from "@material-ui/core";
import PreviewBackground from "../../../utils/image/consumer_background.png";
import DefaultImg from "assets/img/img-default.png";
import { CalenderSVG, UploadSVG } from "../../../utils/static";
import PerfectScrollbar from "react-perfect-scrollbar";
import { getLang } from "app/feature/constants";

const style = (theme) => ({
  root: {
    [theme.breakpoints.up("sm")]: {
      width: 375
    },
    [theme.breakpoints.down("xs")]: {
      height: "100vh"
    },
    backgroundImage: `url(${PreviewBackground})`,
    backgroundSize: "cover",
    padding: theme.spacing(4, 3, 4, 3),
    display: "grid",
    gridTemplateRows: "40px 1fr",
    gridTemplateColumns: "1fr"
  },
  dividerLogo: {
    gridRow: "1/2",
    gridColumn: "1/2",
    borderTopRightRadius: theme.spacing(2),
    borderTopLeftRadius: theme.spacing(2),
    position: "relative",
    display: "flex",
    justifyContent: "center",
    zIndex: 1
  },
  logo: {
    width: 90,
    height: 90,
    borderRadius: "50%",
    backgroundColor: theme.palette.common.white,
    display: "flex"
  },
  body: {
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.spacing(2)
  },
  primaryColor: {
    color: "#632FF9"
  },
  textField: {
    boxShadow: "0px 2px 0px #632FF9",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
    display: "flex",
    justifyContent: "space-between"
  },
  border: {
    borderBottom: "solid 3px",
    color: "#D6D6D6",
    width: "15%",
    display: "flex",
    alignSelf: "center"
  },
  multipleLine: {
    color: "rgba(32, 32, 32, 0.33)",
    fontWeight: "bold",
    paddingBottom: theme.spacing(4)
  },
  footer: {
    padding: theme.spacing(2, 5, 2, 5),
    justifyContent: "space-between",
    display: "flex",
    alignItems: "center"
  },
  button: {
    backgroundColor: "#632FF9",
    borderRadius: "100px",
    color: "#FFF",
    "&:hover": {
      background: "#632FF9"
    }
  },
  scroll: {
    overflowY: "auto",
    height: "650px",
    [theme.breakpoints.down("sm")]: {
      height: "600px"
    },
    [theme.breakpoints.down("xs")]: {
      height: "100vh"
    }
  }
});

const useStyles = makeStyles(style);

export default function PreviewRegFormComponent({
  warrantyRegisterForm,
  logo,
  lang
}) {
  const classes = useStyles();
  return (
    <PerfectScrollbar
      className={classes.scroll}
      options={{
        suppressScrollX: true,
      }}
    >
      <Box className={classes.root}>
        <Box className={classes.dividerLogo}>
          <img src={logo || DefaultImg} alt="logo" className={classes.logo} />
        </Box>

        <Box className={classes.body}>
          <Box p={5}>
            <Box pt={3} style={{ textAlign: "center" }}>
              <Typography variant="h6" className={classes.primaryColor}>
                {getLang(lang, 'label.WARRANTY_REGISTRATION')}
              </Typography>
            </Box>

            <Box style={{ display: "flex", placeContent: "center" }}>
              <Box className={classes.border} pt={2} />
            </Box>

            {warrantyRegisterForm.map((field, index) => (
              <Box key={index}>
                {field.visible ? (
                  <Box key={index} className={(field.type === "multipleChoice" || field.type === "multipleChoiceMultiSelect") ? null : classes.textField}>
                    {field.type === "multipleLine" ? (
                      <Typography
                        variant="body2"
                        className={classes.multipleLine}
                      >
                        {field.variantName}
                        {field.mandatory ? <Typography style={{ color: "#DE350B", display: 'inline-block' }}>*</Typography> : null}
                      </Typography>
                    ) : field.type === "multipleChoice" ? (
                      <Box
                        display="grid"
                        alignItems="flex-start"
                        pt={1}
                      >
                        <Typography
                          variant="body2"
                          style={{
                            fontWeight: "600",
                            color: "rgba(32, 32, 32, 0.33)",
                            fontSize: "14px",
                            paddingTop: "8px",
                            display: "flex"
                          }}
                        >
                          {
                            field.mandatory
                              ? (<>{field.variantName} <Typography style={{ color: "#DE350B", display: 'inline-block' }}>*</Typography></>)
                              : field.variantName
                          }
                        </Typography>

                        <FormControl>
                          <RadioGroup
                            name={field.variantName}
                          >
                            {field.choice.map((field, index) => (

                              <FormControlLabel key={index}
                                value={field}
                                control={<Radio color="primary" />}
                                label={field}
                              />

                            ))}
                          </RadioGroup>
                        </FormControl>
                      </Box>
                    ) : field.type === "multipleChoiceMultiSelect" ? (
                      <Box
                        display="grid"
                        alignItems="flex-start"
                        pt={1}
                      >
                        <Typography
                          variant="body2"
                          style={{
                            fontWeight: "600",
                            color: "rgba(32, 32, 32, 0.33)",
                            fontSize: "14px",
                            paddingTop: "8px",
                            display: "flex"
                          }}
                        >
                          {
                            field.mandatory
                              ? (<>{field.variantName} <Typography style={{ color: "#DE350B", display: 'inline-block' }}>*</Typography></>)
                              : field.variantName
                          }
                        </Typography>
                        <Box display="flex">
                          <FormControl>
                            <FormGroup
                              name={field.variantName}
                            >

                              {field.choice.map((choice, checkBoxIndex) => (

                                <FormControlLabel
                                  key={checkBoxIndex}
                                  name={`customDetails[${index}].value`}
                                  control={<Checkbox
                                    name={`customDetails[${index}].value`}
                                    value={choice}
                                    color="primary" />}
                                  label={choice}
                                />

                              ))}

                            </FormGroup>
                          </FormControl>
                        </Box>
                      </Box>
                    ) : (
                      <Typography
                        variant="body2"
                        style={{
                          color: "rgba(32, 32, 32, 0.33)",
                          fontWeight: "bold"
                        }}
                      >
                        {field.variantName}
                        {field.mandatory ? <Typography style={{ color: "#DE350B", display: 'inline-block' }}>*</Typography> : null}
                      </Typography>
                    )}

                    {field.type === "datePicker" ? (
                      <CalenderSVG />
                    ) : field.type === "uploadFile" ? (
                      <UploadSVG />
                    ) : null}
                  </Box>
                ) : null}
              </Box>
            ))}
          </Box>

          <Box pt={2}>
            <Divider />
            <Box className={classes.footer}>
              <Typography variant="caption" style={{ color: "#9CA6A9" }}>
              <Typography style={{ color: "#DE350B", display: 'inline-block' }}>*</Typography> {getLang(lang, 'label.REQUIRED_FIELD')}
              </Typography>
              <Button
                variant="contained"
                disable="true"
                className={classes.button}
              >
                {getLang(lang, 'label.SUBMIT')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </PerfectScrollbar>
  );
}
