import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from "react-router-dom";
import { STOCKFLOW_STOCKOUT_VIEW } from "lib/constants/accessRights";
import LoadingComponent from "components/loading";
import MainPageComponent from "./main.component";
import qs from "query-string";
import { STOCKOUT_LIST_PAGE } from "modules/stockflow/config/stockflow.route";
import { getLang } from "app/feature/constants";

function MainPageContainer() {
  const hasStockflowViewAccessRight = useSelector(state => state.profile.accessRights.includes(STOCKFLOW_STOCKOUT_VIEW));
  const lang = useSelector(state => state.constant.languages)
  const history = useHistory()
  const location = useLocation()
  const [activeIndex, setActiveIndex] = useState(null);

  const qsParams = qs.parse(location.search);
  const currentSearch = qsParams.status || '';

  useEffect(() => {
    if(!hasStockflowViewAccessRight){
      history.push('/admin/dashboard')
    }
  }, [history, hasStockflowViewAccessRight])

  useEffect(() => {
    let tempIndex= 0
    switch(currentSearch) {
      case "pending": 
        tempIndex = 0
        break;
      case "completed": 
        tempIndex = 1
        break;
      case "rejected": 
        tempIndex = 2
        break;
      case "cancelled": 
        tempIndex = 3
        break;
      default:
        tempIndex = 0
    }
    setActiveIndex(tempIndex)
  }, [currentSearch])

  if(!hasStockflowViewAccessRight) return <LoadingComponent />;

  let tabLabels = []
  if(hasStockflowViewAccessRight){
    tabLabels.push(getLang(lang, "label.PENDING"))
    tabLabels.push(getLang(lang, "label.COMPLETED"))
    tabLabels.push(getLang(lang, "label.REJECTED"))
    tabLabels.push(getLang(lang, "label.CANCELLED"))
  }

  const handleChange = (event, index) => {
    if (activeIndex !== index) {
      setActiveIndex(index);
      history.replace({
        pathname: STOCKOUT_LIST_PAGE,
        search: `?status=${index === 0 ? 'pending' 
          : index === 1 ? 'completed' 
          : index === 2 ? 'rejected' 
          : 'cancelled'}`
      })
    }
  };

  return (
    <>
      <MainPageComponent
        handleChange={handleChange}
        activeIndex={activeIndex}
        tabLabels={tabLabels}
        lang={lang}
      />
    </>
  );
}

export default MainPageContainer;