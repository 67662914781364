import React, { Fragment, useRef, useState } from "react"
import {
  makeStyles,
  Box,
  Divider,
  Checkbox,
  InputAdornment,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Collapse,
  ListItemIcon,
  Button,
  MenuList,
  Typography,
  MenuItem,
  Popover,
} from "@material-ui/core";
import { AddIcon } from "components/icon";
import Skeleton from "@material-ui/lab/Skeleton";
import InputTextField from 'components/input/inputTextField';
import {
  Search as SearchIcon,
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon
} from "@material-ui/icons"
import { getLang } from "app/feature/constants";

const useStyle = makeStyles((theme) => ({
  paper: {
    width: 425
  },
  button: {
    padding: 0,
    minWidth: 0
  },
  buttonAddBranch: {
    display: "flex",
    padding: 0,
    minWidth: 0
  },
  createLink: {
    padding: theme.spacing(2)
  },
  dropdownItem: {
    padding: 0
  }
}));

export default function BulkCreateBrandModelPanelComponent({
  id,
  open,
  anchorEl,
  handleClose,
  dropdownItem,
  isFetching,
  anchorOrigin,
  transformOrigin,
  value,
  handleSelectAll,
  selectAll,
  handleSelectBrand,
  handleSelectModel,
  placeholder,
  searchable,
  handleBrandModelSearch,
  handleAddBrandModel,
  PaperProps,
  style,
  lang
}) {
  const classes = useStyle()
  const textRef = useRef();
  const [openDropdown, setOpenDropdown] = useState("")

  return (
    <Popover
      id={id}
      open={open}
      style={style}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      elevation={2}
      PaperProps={PaperProps}
    >
      <Box>
        {(!!isFetching || (!isFetching && !!dropdownItem.length)) && (
          <>
            {searchable && (
              <Fragment>
                <InputTextField
                  variant="filled"
                  size="small"
                  fullWidth
                  inputRef={textRef}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end' style={{ paddingRight: 6 }}>
                        <IconButton onClick={() => handleBrandModelSearch(textRef.current.value)}>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                    style: {
                      backgroundColor: "#fff",
                      paddingRight: 0
                    }
                  }}
                  inputProps={{
                    onKeyDown: (e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        handleBrandModelSearch(textRef.current.value)
                      }
                    },
                    style: {
                      padding: "8px"
                    }
                  }}
                  placeholder={placeholder}
                />
              </Fragment>
            )}
            <Divider />
            <Box style={{ overflow: "auto" }}>
              {isFetching ? (
                <>
                  <Skeleton variant='text' animation="wave" />
                  <Skeleton variant='text' animation="wave" />
                  <Skeleton variant='text' animation="wave" />
                  <Skeleton variant='text' animation="wave" />
                </>
              ) : (
                <div>
                  <MenuList
                    id="menu-list-grow"
                    onClick={() => {
                      handleSelectAll()
                    }}
                    style={{ padding: 0 }}
                  >
                    <MenuItem style={{ padding: "0px 8px" }}>
                      <Checkbox checked={selectAll} />
                      <Typography
                        variant="inherit"
                        noWrap
                        style={{ paddingLeft: "8px" }}
                      >
                        {getLang(lang, "label.SELECT_ALL_BRAND_MODEL")}
                      </Typography>
                    </MenuItem>
                  </MenuList>
                  <Divider />
                  <Box maxHeight="25vh" overflow="auto">
                    <List>
                      {dropdownItem.map((field, index) => {
                        return (
                          <Fragment key={index}>
                            <ListItem>
                              <ListItemIcon style={{ paddingLeft: "0px", minWidth: "40px" }}>
                                <Checkbox
                                  checked={
                                    value.find((v) => v.id === field.id && v.models.length === field.models.length) ? true : false
                                  }
                                  indeterminate={
                                    value.find((v) => v.id === field.id && v.models.length !== field.models.length) ? true : false
                                  }
                                  disabled={false}
                                  style={{
                                    padding: 0
                                  }}
                                  onClick={(event) => {
                                    handleSelectBrand(field, event.target.checked)
                                  }}
                                />
                              </ListItemIcon>
                              <ListItemText primary={field.id === "-1" ? getLang(lang, "label.NO_BRAND") : field.name} />
                              {
                                field.models.length > 0 ?
                                  (openDropdown !== "" && field.id === openDropdown ? (
                                    <ArrowDropUpIcon onClick={() => {
                                      setOpenDropdown("")
                                    }} />
                                  ) : (
                                    <ArrowDropDownIcon onClick={() => {
                                      if (field.models.length > 0) {
                                        setOpenDropdown(field.id)
                                      }
                                    }} />
                                  ))
                                  : null
                              }
                            </ListItem>
                            <Collapse in={field.id === openDropdown}>
                              <List component="div" disablePadding>
                                {field.models.length > 0 ? field.models.map((model) => {
                                  return (
                                    <ListItem key={model.id}>
                                      <ListItemIcon style={{ paddingLeft: "20px", minWidth: "40px", paddingRight: 8 }}>
                                        <Checkbox
                                          checked={
                                            value.find((v) => v.models.find((v2) => v2.id === model.id)) ? true : false
                                          }
                                          disableRipple
                                          style={{
                                            padding: 0
                                          }}
                                          onClick={(event) => handleSelectModel(field, model, event.target.checked)}
                                        />
                                      </ListItemIcon>
                                      <ListItemText primary={model.name} />
                                    </ListItem>
                                  )
                                }) : (
                                  <></>
                                )}
                              </List>
                            </Collapse>
                          </Fragment>
                        )
                      })}
                    </List>
                  </Box>
                </div>
              )}
            </Box>
            <Divider />
          </>
        )}
        <Box className={classes.createLink} style={{ padding: '5px 10px' }}>
          <Button
            variant="text"
            color="primary"
            startIcon={
              <AddIcon color="secondary" style={{ fontSize: "13px" }} />
            }
            onClick={handleAddBrandModel}
            style={{
              marginRight: "8px"
            }}
          >
            {getLang(lang, "label.ADD_NEW")}
          </Button>
        </Box>
      </Box>
    </Popover>
  )
}