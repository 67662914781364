import React from "react"
import ActivityLogTimelineComponent from './activityLogTimeline.component'

function ActivityLogTimelineContainer({
  activityLog,
  lang,
  language
}) {
  return (
    <ActivityLogTimelineComponent activityLog={activityLog} lang={lang} language={language} />
  )
}

export default ActivityLogTimelineContainer