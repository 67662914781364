import React, { useRef } from "react";
import PropTypes from "prop-types";
import { makeStyles, Box, Typography, Tabs, Divider, Button, Tooltip } from "@material-ui/core";
import CustomTab from "components/tab";
import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";
import { AddIcon } from "components/icon";
import { PointListPanel, BonanzaListPanel, GiftListPanel, GiftRedemptionPanel, PointCutPanel } from 'modules/loyalty-program/components/panel';
import HelpOutlinedIcon from "@material-ui/icons/HelpOutlined";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles(theme => ({
  indicator: {
    border: "0.5px solid",
    borderColor: theme.palette.primary.main
  },
  scroll: {
    width: "min-content"
  }
}))

export default function LoyaltyProgramPage({
  hasLoyaltyProgramViewAccessRight,
  hasLoyaltyProgramAddAccessRight,
  hasBonanzaViewAccessRight,
  hasBonanzaAddAccessRight,
  handleChange,
  activeIndex,
  tabLabels,
  hasGiftAddAccessRight,
  hasPointCutAddAccessRight,
  lang
}) {
  const classes = useStyle();
  const loyaltyProgramRef = useRef();

  const openCreateLoyaltyProgramModal = () => {
    loyaltyProgramRef.current.openCreateLoyaltyProgramModal();
  };

  function a11yProps(index, label = "label") {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tab-${index}`,
      label: <Typography variant="button" style={{ textTransform: "none" }} children={label} />,
    };
  }

  function TabPanel({ children, value, index, ...other }) {
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </Typography>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
  };
  return (
    <Box>
      <Box gridArea="topbar">
        <Box
          mb={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="flex" alignItems="flex-end" fontWeight="bold">
            <Typography variant="h6" color="primary">
              {getLang(lang,"label.LOYALTY_PROGRAM")}
            </Typography>
            <Box mx={0.5}>
              <ChevronRightRoundedIcon color="disabled" />
            </Box>
            <Box display="flex" alignItems="center">
              <Typography variant="h6" color="primary">
                {tabLabels[activeIndex]}
              </Typography>
              {
                (tabLabels[activeIndex] === getLang(lang,"label.BONANZA_TAB") || tabLabels[activeIndex] === getLang(lang,"label.POINTS_CUT_TAB")) &&
                <Tooltip
                  title={tabLabels[activeIndex] === getLang(lang,"label.BONANZA_TAB") ? getLang(lang, 'tooltips.BONANZA_TOOLTIP_TEXT') : getLang(lang, 'tooltips.POINT_CUT_TOOLTIP_TEXT')}
                  arrow
                  style={{ marginLeft: 2 }}
                >
                  <HelpOutlinedIcon color="primary" fontSize="small" />
                </Tooltip>
              }
            </Box>
          </Box>
          {
            (tabLabels[activeIndex] === getLang(lang,"label.LOYALTY_POINT_TAB") && hasLoyaltyProgramAddAccessRight) ||
              (tabLabels[activeIndex] === getLang(lang,"label.BONANZA_TAB") && hasBonanzaAddAccessRight) ||
              (tabLabels[activeIndex] === getLang(lang,"label.GIFT_LIST_TAB") && hasGiftAddAccessRight) ||
              (tabLabels[activeIndex] === getLang(lang,"label.POINTS_CUT_TAB") && hasPointCutAddAccessRight)
              ?
              <Button
                variant="text"
                color="primary"
                startIcon={
                  <AddIcon color="secondary" style={{ fontSize: "13px" }} />
                }
                onClick={openCreateLoyaltyProgramModal}
              >
                {tabLabels[activeIndex] === getLang(lang,"label.GIFT_LIST_TAB") ? getLang(lang,"label.GIFT") : tabLabels[activeIndex]}
              </Button>
              : null
          }
        </Box>
        <Divider />
      </Box>
      <Box gridArea="panel">
        <Box>
          <Box display="flex">
            <Tabs
              value={activeIndex}
              onChange={handleChange}
              aria-label="scrollable auto tabs example"
              textColor="primary"
              variant="scrollable"
              indicatorColor="primary"
              classes={{
                indicator: classes.indicator,
                scrollButtons: classes.scroll
              }}
            >
              {tabLabels.map((item, index) => {
                return <CustomTab key={index} {...a11yProps(index, item)} />;
              })}
            </Tabs>
          </Box>
          <Box>
            {
              tabLabels.map((item, index) => {
                if (item === getLang(lang,"label.LOYALTY_POINT_TAB")) {
                  return (
                    <TabPanel value={activeIndex} index={index} key={index}>
                      <PointListPanel ref={loyaltyProgramRef} />
                    </TabPanel>
                  )
                }
                if (item === getLang(lang,"label.BONANZA_TAB")) {
                  return (
                    <TabPanel value={activeIndex} index={index} key={index}>
                      <BonanzaListPanel ref={loyaltyProgramRef} />
                    </TabPanel>
                  )
                }
                if (item === getLang(lang,"label.GIFT_LIST_TAB")) {
                  return (
                    <TabPanel value={activeIndex} index={index} key={index}>
                      <GiftListPanel ref={loyaltyProgramRef} />
                    </TabPanel>
                  )
                }
                if (item === getLang(lang,"label.GIFT_REDEMPTION_TAB")) {
                  return (
                    <TabPanel value={activeIndex} index={index} key={index}>
                      <GiftRedemptionPanel />
                    </TabPanel>
                  )
                }
                if (item === getLang(lang,"label.POINTS_CUT_TAB")) {
                  return (
                    <TabPanel value={activeIndex} index={index} key={index}>
                      <PointCutPanel ref={loyaltyProgramRef} />
                    </TabPanel>
                  )
                }
                return null;
              })
            }
          </Box>
        </Box>
      </Box>
      {
        /* 
        <Box gridArea="card1" pt={6}>
          <ItemExpiredCard />
        </Box>
        <Box gridArea="card2" pt={2}>
          <LowQuantityCard />
        </Box> 
        */
      }
    </Box>
  );
}
