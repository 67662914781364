import React, { useRef } from 'react'
import { LuckyGrid } from 'react-luck-draw'

export default function SpinGridComponent({
    config,
    prizes
}) {
    const ref = useRef()
    const axis = [[0, 0], [1, 0], [2, 0], [2, 1], [2, 2], [1, 2], [0, 2], [0, 1]]

    const renderPrizes = prizes.map((item, i) => ({
        index: i, x: axis[i][0], y: axis[i][1],
        fonts: [{
            text: item.title,
            fontSize: config.prize.fontSize,
            top: config.prize.fontTop
        }],
        imgs: item.image && [{
            src: item.image,
            width: config.prize.imgWidth,
            height: config.prize.imgHeight,
            top: config.prize.imgTop
        }]
    }))


    return (
        <LuckyGrid
            ref={ref}
            width={config.width}
            height={config.height}
            blocks={config.blocks}
            prizes={renderPrizes}
            buttons={config.spinButtons}
            defaultStyle={config.defaultStyle}
            defaultConfig={config.defaultConfig}
            activeStyle={config.activeStyle}
            onStart={() => {
                ref.current.play()
                setTimeout(() => {
                    ref.current.stop(Math.random() * prizes.length >> 0)
                }, 3000)
            }}
        />
    )
}
