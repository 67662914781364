import React from "react";
import WarrantyComponent from "./warranty.component";
import { useSelector } from "react-redux";

export default function WarrantyContainer({ id }) {
  const { isVisible } = useSelector(
    (state) => state.productPage.components[id]
  );
  const lang = useSelector((state) => state.constant.languages);
  const userLanguage = useSelector((state) => state.profile.language);

  return (
    isVisible && <WarrantyComponent lang={lang} userLanguage={userLanguage} />
  );
}
