import React, { useState, useRef } from "react";
import Modal from "../../modal";
import WebcamPanel from "../webcamPanel";
import PhotoProfilePanelComponent from "./photoProfilePanel.component";
import { connect, useSelector } from "react-redux";
import UploadingComponent from "./uploading.component";
import { selectLoading } from "modules/notification";
import { updateUserProfilePicture } from "../../../redux";
import { addAlert } from "modules/notification";
import { ImageEditorPanel } from "components/panel";
import { getLang } from "app/feature/constants";
import {setImgFormatAndSize} from "../../../../../lib/FileHelper";

function PhotoProfilePanelContainer({
  userId,
  profileImage,
  updateUserProfilePicture,
  isUploading,
  addAlert
}) {
  const inputImgRef = useRef();

  const [isCropping, setCropping] = useState(false);
  const [bufferImg, setBufferImg] = useState();
  const [isModalOpen, setModalOpen] = useState(false);
  const [isWebcamOpen, setIsWebcamOpen] = useState(false);
  const [dragging, setDragging] = useState(false);
  const allowedImageExtension = ['.jpeg', '.jpg', '.png'];
  const lang = useSelector(state => state.constant.languages)

  const handleImgChange = e => {
    const file = inputImgRef.current.files[0];

    if (file) {
      const rawFile = e.target.files[0];
      const image = URL.createObjectURL(rawFile);

      if (!ValidImageFormat(rawFile.name)) {
        addAlert({ severity: "error", message: getLang(lang,"message.error.INVALID_IMAGE_FORMAT") });

        return;
      }

      if (!isImageSizeValid(rawFile.size)) {
        addAlert({
          severity: "error",
          message: getLang(lang, "message.error.FORM_VALIDATE_FILE_SIZE", { value: 6 })
        });
        return;
      }
      setBufferImg(image);
      setModalOpen(true);
      setCropping(true);
      setIsWebcamOpen(false);
    }
  };

  const handleDrop = (dropped) => {
    setDragging(false);
    const rawFile = dropped[0];
    if (!ValidImageFormat(rawFile.name)) {
      addAlert({ severity: "error", message: getLang(lang,"message.error.INVALID_IMAGE_FORMAT") })

      return;
    }

    if (!isImageSizeValid(rawFile.size)) {
      addAlert({
        severity: "error",
        message: getLang(lang,"message.error.FILE_TOO_LARGE")
      });
      return;
    }

    const image = URL.createObjectURL(rawFile);

    setBufferImg(image);
    setModalOpen(true);
    setIsWebcamOpen(false);
    setCropping(true);

  }

  const ValidImageFormat = (file) => {
    for (let index = 0; index < allowedImageExtension.length; index++) {
      if (file.indexOf(allowedImageExtension[index]) !== -1) {
        return true;
      }
    }
    return false;
  };

  const isImageSizeValid = size => {
    // max file size is 6 mb
    if (size <= 1024 * 1024 * 6) return true;
    else return false;
  };

  const handleOpenCamera = () => {
    setModalOpen(true);
    setIsWebcamOpen(true);
    setCropping(false);
  };

  const handleCrop = image => {
    setBufferImg(image);
    setIsWebcamOpen(false);
    setCropping(true);
  };

  const handleClose = () => {
    if (isUploading) return;
    setModalOpen(false);
  };

  const handleResult = async base64Image => {
    setCropping(false);
    setModalOpen(false);
    setIsWebcamOpen(false);
    const fileName = `userProfile${userId}${Date.now()}.webp`;

    base64Image = await setImgFormatAndSize(base64Image, 2160, 2160, 'image/webp');

    updateUserProfilePicture({
      fileName,
      base64Image
    });
  };

  const handleDragEnter = () => {
    setDragging(true);
  }
  const handleDragLeave = () => {
    setDragging(false);
  }

  return (
    <>
      <PhotoProfilePanelComponent
        profileImage={profileImage}
        inputImgRef={inputImgRef}
        handleImgChange={handleImgChange}
        handleOpenCamera={handleOpenCamera}
        handleDragEnter={handleDragEnter}
        handleDragLeave={handleDragLeave}
        handleDrop={handleDrop}
        dragging={dragging}
        lang={lang}
      />
      <Modal
        isOpen={isModalOpen || isUploading}
        handleClose={handleClose}
        disableEnforceFocus={true}
        disableAutoFocus={true}
      >
        {isWebcamOpen && <WebcamPanel handleResult={handleCrop} />}
        {isCropping && (
          <ImageEditorPanel
            img={bufferImg}
            handleCancel={handleClose}
            handleResult={handleResult}
          />
        )}
        {isUploading && <UploadingComponent />}
      </Modal>
    </>
  );
}

const mapStateToProps = state => ({
  userId: state.profile.id,
  profileImage: state.profile.profileImg,
  isUploading: selectLoading(state, updateUserProfilePicture.type)
});

const mapDispatchToProps = dispatch => ({
  updateUserProfilePicture: imgUrl => dispatch(updateUserProfilePicture(imgUrl)),
  addAlert: payload => dispatch(addAlert(payload))

});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PhotoProfilePanelContainer);
