import { setLoading, addAlert } from "modules/notification";
import {
  fetchTagsDropdown,
  getTagSerialNumber,
  createTagSerialNumber,
  getTagDetail,
} from "../action";
import { setTagSerialNumber } from "modules/serial-number";
import { getApiLang } from "app/feature/constants";

const fetchTagsDropdownMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { payload, type } = action;

    switch (type) {
      case fetchTagsDropdown.pending.type:
        dispatch(setLoading({ id: fetchTagsDropdown.typePrefix, state: true }));
        break;
      case fetchTagsDropdown.fulfilled.type:
        dispatch(
          setLoading({ id: fetchTagsDropdown.typePrefix, state: false })
        );
        break;
      case fetchTagsDropdown.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({ id: fetchTagsDropdown.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

const getTagSerialNumberMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getTagSerialNumber.pending.type:
        // dispatch set loading to notification reducer
        dispatch(
          setLoading({ id: getTagSerialNumber.typePrefix, state: true })
        );
        break;

      case getTagSerialNumber.fulfilled.type:
        // dispatch remove loading from notification reducer
        next(setTagSerialNumber(action.payload));
        dispatch(
          setLoading({ id: getTagSerialNumber.typePrefix, state: false })
        );
        break;

      case getTagSerialNumber.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({ id: getTagSerialNumber.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

export const createTagSerialNumberMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages;

    switch (type) {
      case createTagSerialNumber.pending.type:
        // dispatch set loading to notification reducer
        dispatch(
          setLoading({ id: createTagSerialNumber.typePrefix, state: true })
        );
        break;

      case createTagSerialNumber.fulfilled.type:
        // dispatch remove loading from notification reducer
        dispatch(
          addAlert({
            severity: "success",
            message: getApiLang(
              lang,
              "success.SUCCESS_CREATE_TAG_SERIALNUMBER"
            ),
          })
        );
        dispatch(
          setLoading({ id: createTagSerialNumber.typePrefix, state: false })
        );
        break;

      case createTagSerialNumber.rejected.type:
        dispatch(
          setLoading({ id: createTagSerialNumber.typePrefix, state: false })
        );

        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

const getTagDetailMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { payload, type } = action;

    switch (type) {
      case getTagDetail.pending.type:
        dispatch(setLoading({ id: getTagDetail.typePrefix, state: true }));
        break;
      case getTagDetail.fulfilled.type:
        dispatch(setLoading({ id: getTagDetail.typePrefix, state: false }));
        break;
      case getTagDetail.rejected.type:
        const lang = getState().constant.languages;
        dispatch(setLoading({ id: getTagDetail.typePrefix, state: false }));
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

export default [
  getTagSerialNumberMiddleware,
  createTagSerialNumberMiddleware,
  fetchTagsDropdownMiddleware,
  getTagDetailMiddleware,
];
