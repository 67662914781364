import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import LiveDrawRemarkCardComponent from "./liveDrawRemarkCard.component";

function LiveDrawRemarkCardContainer({ progress }) {
  const [isOpen, setIsOpen] = useState(false);
  const anchorRef = useRef(null);
  const lang = useSelector(state => state.constant.languages)
  const handleToggleOpen = () => {
    setIsOpen((prev) => !prev);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setIsOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setIsOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(isOpen);
  useEffect(() => {
    if (prevOpen.current === true && isOpen === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = isOpen;
  }, [isOpen]);

  return (
    <LiveDrawRemarkCardComponent
      isOpen={isOpen}
      handleToggleOpen={handleToggleOpen}
      anchorRef={anchorRef}
      handleListKeyDown={handleListKeyDown}
      handleClose={handleClose}
      progress={progress}
      lang={lang}
    />
  );
}

export default LiveDrawRemarkCardContainer;
