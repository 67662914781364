import { createAsyncThunk } from '@reduxjs/toolkit';
import productApi from 'app/api/product';

export const PRODUCTS = "products";

export const addProductCategories = createAsyncThunk(
  `${PRODUCTS}/addProductCategories`,
  async (payload, { rejectWithValue }) => {
    return productApi.createProductCategories({
      categoryNames: payload.categories
    })
      .then(response => response.data)
      .catch(error => rejectWithValue(error))
  }
)

export const getAllProductCategoryListing = createAsyncThunk(
  `${PRODUCTS}/getAllProductCategoryListing`,
  async (payload, {rejectWithValue}) => {
    return productApi.getProductCategoryListing({
      length: payload.length,
      start: payload.start,
      column: payload.column,
      order: payload.order,
      search: payload.search,
      status: payload.status,
    })
      .then(response => response.data)
      .catch(error => rejectWithValue(error))
  }
)

export const updateCategoryStatus = createAsyncThunk(
  `${PRODUCTS}/updateCategory`,
  async (payload, {rejectWithValue}) => {
    return productApi.updateCategoryStatus({
      id: payload.id,
      status: payload.status,
    })
      .then(response => response.data)
      .catch(error => rejectWithValue(error))
  }
)

export const editProductCategory = createAsyncThunk(
  `${PRODUCTS}/editCategory`,
  async (payload, {rejectWithValue}) => {
    return productApi.editProductCategory({
      id: payload.id,
      name: payload.name,
      status: payload.status
    })
      .then(response => response.data)
      .catch(error => rejectWithValue(error))
  }
)

export const deleteProductCategory = createAsyncThunk(
  `${PRODUCTS}/deleteCategory`,
  async (payload, {rejectWithValue}) => {
    return productApi.deleteCategory({
      id: payload.id
    })
      .then(response => response.data)
      .catch(error => rejectWithValue(error))
  }
)

export const updateCategoriesStatus = createAsyncThunk(
  `${PRODUCTS}/updateCategoriesStatus`,
  async (payload, {rejectWithValue}) => {
    return productApi.updateCategoriesStatus({
      filter: payload.filter,
      update: payload.update
    })
      .then(response => response.data)
      .catch(error => rejectWithValue(error))
  }
)

export const getProductCategoryDropdown = createAsyncThunk(
  `${PRODUCTS}/getCategoriesDropdown`,
  async (payload, {rejectWithValue }) => {
    return productApi.getProductCategoryDropdown({
      search : payload.search
    })
      .then((response) => response.data)
      .catch(error => rejectWithValue(error))
  }
)

