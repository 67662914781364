import React from "react";
import {
  Box,
  LinearProgress,
  makeStyles,
  Typography,
  withStyles,
} from "@material-ui/core";
import { getLang } from "app/feature/constants";

const useStyles = makeStyles(theme => ({
  contentLocationAndCount: {
    fontSize: "0.8rem",
    fontWeight: "normal"
  },
  contentPercentage: {
    fontSize: "0.8rem",
    fontWeight: "bold"
  },
  contentScan: {
    fontSize: "0.65rem",
    fontWeight: "lighter",
    paddingLeft: 4
  }
}));

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 13,
    borderRadius: 999,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 999,
    backgroundColor: theme.palette.secondary.main,
  }
}))(LinearProgress);

export default function PercentageBarComponent({
  label,
  percentage,
  count,
  lang
}) {
  const classes = useStyles();

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="flex-end" mb={0.5}>
        <Typography color='primary' classes={{ root: classes.contentLocationAndCount }}>{label}</Typography>
        <Typography color='primary' classes={{ root: classes.contentPercentage }}>{percentage}%</Typography>
      </Box>
      <BorderLinearProgress variant='determinate' value={percentage} />
      <Box display="flex" alignItems="flex-and">
        <Typography color='primary' classes={{ root: classes.contentLocationAndCount }}>{count}</Typography>
        <Typography color='primary' classes={{ root: classes.contentScan }}>{getLang(lang, "label.SCANS")}</Typography>
      </Box>
    </>
  )
}