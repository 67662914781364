
import { Box, CircularProgress, makeStyles, Modal, Typography } from '@material-ui/core'
import { getLang } from 'app/feature/constants';
import React from 'react'
import { useSelector } from 'react-redux';

const useStyle = makeStyles(theme => ({
  modalBox: {
    borderRadius: 12, 
    backgroundColor: "white", 
    width: "fit-content", 
    maxWidth: "90%"
  }
}))
function GenerateProductLoadingModalComponent({
  open
}) {
  const classes = useStyle()
  const lang = useSelector(state => state.constant.languages);

  return (
    <Modal
      open={open}
      disableEscapeKeyDown={true}
      onClose={() => {}}
    >
      <Box height="100%" width="100%" display="flex" alignItems="center" justifyContent="center">
        <Box 
          px={5} 
          py={4} 
          display="flex" 
          flexDirection="column" 
          alignItems="center"
          className={classes.modalBox}
        >
          <CircularProgress/>
          <Typography variant="h6" align="center" style={{ marginBottom: 10, marginTop: 26 }}><b>{getLang(lang, "paragraph.GENERATING_PRODUCT")}</b></Typography>
          <Typography variant='body1' align="center">{getLang(lang, "paragraph.DO_NOT_CLOSE_WINDOWS")}</Typography>
        </Box>
      </Box>
    </Modal>
  )
}

export default GenerateProductLoadingModalComponent