import React from 'react'
import {
  Box,
  makeStyles,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import { StyledTableCell, StyledTableRow } from 'components/table';
import ErrorIcon from '@material-ui/icons/Error';
import { convertToBigNum } from "lib/generalUtility";
import { getLang } from 'app/feature/constants';

const useStyle = makeStyles(theme => ({
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    borderCollapse: "separate",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0
    },
    "& th,td": {
      fontSize: ".75rem",
      borderRight: "1px solid rgba(58, 77, 84, 0.1)",
      borderBottom: "none",
      padding: theme.spacing(1, 2, 1, 2)
    },
    "& th": {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightBold
    },
  },
  tableContainer: {
    maxHeight: 140,
    overflowY: "auto",
    border: "1px solid rgba(0, 0, 0, 0.1);",
    borderRadius: 4,
  },
  emptyContainer: {
    maxHeight: 100,
    backgroundColor: theme.palette.background.paper,
    justifyContent: "center",
    border: "1px solid rgba(0, 0, 0, 0.1);",
    borderRadius: 4,
    display: "flex",
  },
  button: {
    display: "flex",
    alignItems: "center",
    padding: 0,
    minWidth: 0,
    marginLeft: theme.spacing(1)
  },
  iconBtn: {
    borderRadius: theme.spacing(0.5),
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shortest,
    }),
    padding: "3px 5px"
  },
}));

export default function PointListingTableComponent({ productDropdown, tableData, bonanza, percent, lang }) {
  const classes = useStyle()
  return (
    <>
      {tableData.length > 0 ? (
        <TableContainer className={classes.tableContainer}>
          <Table
            className={classes.table}
            size="small"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell>{getLang(lang, "label.NUM")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.IMAGE")}</StyledTableCell>
                  <StyledTableCell>{getLang(lang, "label.PRODUCT_NAME")}</StyledTableCell>
                  {
                    bonanza &&
                    <>
                      <StyledTableCell>{getLang(lang, "label.PTS_BEFORE")}</StyledTableCell>
                      <StyledTableCell>{getLang(lang, "label.PTS_AFTER")}</StyledTableCell>
                    </>
                  }
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.map((item, index) => {
                const module = productDropdown.find(p => p.id === item.id)
                return (
                  <StyledTableRow
                    key={index}
                    hover
                    tabIndex={-1}
                  >
                    <StyledTableCell>{index + 1}</StyledTableCell>
                    <StyledTableCell>{productDropdown.find(p => p.id === item.id) ?
                  <img src={productDropdown.find(p => p.id === item.id).picture}
                    style={{ width: "30px", height: "30px" }} 
                    alt={item.id}
                    /> :
                  null}
                  </StyledTableCell>
                    <StyledTableCell>
                      {
                        module ? module.name : item.name
                      }
                    </StyledTableCell>
                    {
                      bonanza &&
                      <>
                        <StyledTableCell>
                          {
                            module ? convertToBigNum(module.point) : convertToBigNum(item.point)
                          }
                        </StyledTableCell>
                        <StyledTableCell>
                          {
                            module ? convertToBigNum((module.point * (1 + percent / 100)).toFixed(0)) : convertToBigNum((item.point * (1 + percent / 100)).toFixed(0))
                          }
                        </StyledTableCell>
                      </>
                    }
                  </StyledTableRow>
                );
              })
              }
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box>
          <Table
            className={classes.table}
            size="small"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell>{getLang(lang, "label.NUM")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.IMAGE")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.PRODUCT_NAME")}</StyledTableCell>
                {
                  bonanza &&
                  <>
                    <StyledTableCell>{getLang(lang, "label.PTS_BEFORE")}</StyledTableCell>
                    <StyledTableCell>{getLang(lang, "label.PTS_AFTER")}</StyledTableCell>
                  </>
                }
              </TableRow>
            </TableHead>
          </Table>
          <Box py={7} className={classes.emptyContainer}>
            <ErrorIcon style={{ paddingRight: 5 }}></ErrorIcon>
            <Typography variant="body1">
              {getLang(lang, "paragraph.NO_PRODUCT_ADDED")}
            </Typography>
          </Box>
        </Box>

      )}
    </>
  )
}