import React from "react";

export default function PieChartIcon(props) {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.7581 13.5157C16.6026 12.2519 17.0748 10.7781 17.1237 9.26238C17.1283 9.11766 17.0105 9.00022 16.8657 9.00022H9.26241C9.11761 9.00022 9.00022 8.88284 9.00022 8.73804L9.00022 1.13474C9.00022 0.989942 8.88279 0.872117 8.73806 0.876786C7.22234 0.925688 5.74857 1.39785 4.48473 2.24232C3.14815 3.1354 2.1064 4.40476 1.49124 5.8899C0.876077 7.37504 0.715122 9.00924 1.02873 10.5859C1.34234 12.1625 2.11642 13.6107 3.2531 14.7473C4.38977 15.884 5.83798 16.6581 7.41459 16.9717C8.99121 17.2853 10.6254 17.1244 12.1105 16.5092C13.5957 15.894 14.865 14.8523 15.7581 13.5157Z" stroke="#344054" stroke-width="1.5731" stroke-linecap="round"/>
            <path d="M11.3598 1.13474C11.3598 0.989942 11.4773 0.871937 11.6219 0.878512C13.0563 0.94371 14.4186 1.5422 15.4384 2.56197C16.4582 3.58174 17.0567 4.94404 17.1219 6.37845C17.1285 6.5231 17.0105 6.64057 16.8657 6.64057L11.622 6.64058C11.4772 6.64058 11.3598 6.52319 11.3598 6.3784V1.13474Z" fill="#344054"/>
        </svg>
    )

}