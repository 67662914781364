import React from "react";
import {
  Box,
  makeStyles,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import { StyledTableCell, StyledTableRow } from "components/table";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles((theme) => ({
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    borderCollapse: "separate",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0
    },
    "& th,td": {
      fontSize: ".75rem",
      padding: theme.spacing(1, 2, 1, 2)
    },
    "& th": {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightBold
    },
    "& th:last-child, td:last-child": {
      width: 55
    }
  },
  tableContainer: {
    maxHeight: 500,
    overflowY: "auto"
  },
  emptyContainer: {
    backgroundColor: theme.palette.background.paper,
    textAlign: "center"
  }
}));

export default function ViewWinnerTableComponent({ data, lang }) {
  const classes = useStyle();

  return (
    <>
      {data.length > 0 ? (
        <TableContainer style={{ borderRadius: "10px" }}>
          <Table className={classes.table} size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <StyledTableCell>{getLang(lang,"label.WINNER")}</StyledTableCell>
                <StyledTableCell>{getLang(lang,"label.SERIAL_NUMBER")}</StyledTableCell>
                <StyledTableCell>{getLang(lang,"label.NAME")}</StyledTableCell>
                <StyledTableCell>{getLang(lang,"label.PHONE_NUMBER_LAST_FOUR_DIGIT")}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((item, index) => {
                return (
                  <StyledTableRow key={index} hover tabIndex={-1}>
                    <StyledTableCell>{(index + 1).toLocaleString("en-US")}</StyledTableCell>
                    <StyledTableCell>
                      {item.serialNumber ? item.serialNumber : "-"}
                    </StyledTableCell>
                    <StyledTableCell>
                      {item.ldParticipant ? item.ldParticipant.name : "-"}
                    </StyledTableCell>
                    <StyledTableCell>
                      {item.ldParticipant
                        ? "*******" + item.ldParticipant.contactNumber.slice(item.ldParticipant.contactNumber.length - 4)
                        : "-"}
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box>
           <Table className={classes.table} size="small" stickyHeader>
            <TableHead>
              <TableRow>
              <StyledTableCell>{getLang(lang,"label.WINNER")}</StyledTableCell>
                <StyledTableCell>{getLang(lang,"label.SERIAL_NUMBER")}</StyledTableCell>
                <StyledTableCell>{getLang(lang,"label.NAME")}</StyledTableCell>
                <StyledTableCell>{getLang(lang,"label.PHONE_NUMBER_LAST_FOUR_DIGIT")}</StyledTableCell>
              </TableRow>
            </TableHead>
            </Table>
            <Box py={9} className={classes.emptyContainer}>
          <Typography variant="body1">{getLang(lang,"paragraph.NO_WINNER")}</Typography>
        </Box>
        </Box>
        
      )}
    </>
  );
}
