import React, { useState, useEffect } from "react";
import BulkCreateBrandModelSelectComponent from './bulkCreateBrandModelSelect.component';
import BulkCreateBrandModelPanelComponent from './bulkCreateBrandModelSelectPanel.component';
import PropTypes from "prop-types";
import { addBrandModel, getBrandModelDropdown } from "modules/product-mgmt/redux/action/brandModels.action";
import { useDispatch, useSelector } from "react-redux";
import { selectLoading } from "modules/notification";
import CreateBrandModelDialog from "../../dialog/createBrandModelDialog";
import { unwrapResult } from "@reduxjs/toolkit";

function BulkCreateBrandModelSelectContainer({
  placeholder,
  handleChange,
  value,
  disabled,
  styles,
  textStyles,
  searchPlaceholder,
  searchable,
  panelStyle,
  anchorOrigin,
  transformOrigin,
  PaperProps,
}) {
  const lang = useSelector(state => state.constant.languages)
  const brandModelDropdown = useSelector(state => state.products.brandModelList.brandModelDropdown)
  const isBrandModelDropdownLoading = useSelector(state => selectLoading(state, getBrandModelDropdown.typePrefix))
  const isAddingBrandModel = useSelector(state => selectLoading(state, addBrandModel.typePrefix))
  
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectAll, setSelectAll] = useState(false);
  const [brandModelDropdownSearch, setBrandModelDropdownSearch] = useState("")
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);

  const open = Boolean(anchorEl);
  const id = open ? "brand-model-popover" : undefined

  useEffect(() => {
    dispatch(getBrandModelDropdown({ search: brandModelDropdownSearch }))
  }, [dispatch, brandModelDropdownSearch])

  const handleClose = () => {
    setAnchorEl(null)
    setBrandModelDropdownSearch("")
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleSelectAll = () => {
    if (!disabled) {
      if (selectAll) {
        handleChange([])
      } else {
        let temp = [];
        brandModelDropdown.forEach(brand => {
          let modelTemp = []
          brand.models.forEach((model) => {
            modelTemp.push({
              id: model.id,
              name: model.name
            })
          });
  
          temp.push({
            id: brand.id,
            name: brand.name,
            models: modelTemp,
          });
        });
  
        handleChange(temp)
      }
      setSelectAll(!selectAll)
    }
  }

  const handleSelectBrand = (field, checked) => {
    let temp = [...value];
    let index = temp.findIndex((v) => v.id === field.id)
    if (checked) {
      let modelTemp = []
      field.models.map((model) => {
        modelTemp.push({
          id: model.id,
          name: model.name
        })
        return null;
      });
      if(index !== -1){
        temp[index].models = modelTemp;
      } else {
        temp.push({
          id: field.id,
          name: field.name,
          models: modelTemp,
        })
      }
    } else {
      temp.splice(index, 1)
      setSelectAll(false)
    }
    handleChange(temp)
  }

  const handleSelectModel = (field, modelfield, checked) => {
    let temp = [...value];
    let modelTemp = [];
    let valBrandIndex = temp.findIndex((b) => b.id === field.id);
    if (temp.length > 0 && valBrandIndex !== -1) {
      modelTemp = temp[valBrandIndex].models;
      if(checked) {
        modelTemp.push({
          id: modelfield.id,
          name: modelfield.name,
        });
        temp[valBrandIndex].models = modelTemp;
      }
      else {
        let index = modelTemp.findIndex((o) => o.id === modelfield.id);
        modelTemp.splice(index, 1);
        if (modelTemp.length === 0){
          temp.splice(valBrandIndex, 1);
        }
        else {
          temp[valBrandIndex].models = modelTemp;
        }
      }
    }
    else {
      if (checked) {
        modelTemp.push({
          id: modelfield.id,
          name: modelfield.name,
        });
        temp.push({
          id: field.id,
          name: field.name,
          models: modelTemp,
        });
      } else {
        setSelectAll(false)
      }
    }
    handleChange(temp)
  }

  const handleBrandModelSearch = (value) => {
    setBrandModelDropdownSearch(value)
  }

  const handleAddBrandModel = (value) => {
    setIsCreateDialogOpen(value)
  }

  const handleSubmitBrandModel = (value) => {
    dispatch(addBrandModel({
      name: value.brand,
      models: value.models
    }))
      .then(unwrapResult)
      .then(() => {
        dispatch(getBrandModelDropdown({ search: brandModelDropdownSearch }))
        setIsCreateDialogOpen(false)
      })
  }

  return (
    <>
      <BulkCreateBrandModelSelectComponent
        handleClick={handleClick}
        id={id}
        value={value}
        styles={styles}
        placeholder={placeholder}
        disabled={disabled}
        textStyle={textStyles}
      />
      <BulkCreateBrandModelPanelComponent
        id={id}
        anchorEl={anchorEl}
        selectAll={selectAll}
        style={panelStyle}
        value={value}
        open={open}
        handleClose={handleClose}
        isFetching={isBrandModelDropdownLoading}
        dropdownItem={brandModelDropdown}
        handleSelectAll={handleSelectAll}
        handleSelectBrand={handleSelectBrand}
        placeholder={searchPlaceholder}
        searchable={searchable}
        handleBrandModelSearch={handleBrandModelSearch}
        handleSelectModel={handleSelectModel}
        handleAddBrandModel={handleAddBrandModel}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        PaperProps={PaperProps}
        lang={lang}
      />
      <CreateBrandModelDialog 
        isOpen={isCreateDialogOpen}
        handleClose={() => {
          setIsCreateDialogOpen(false)
        }}
        handleSubmit={handleSubmitBrandModel}
        isSubmittingAddModel={isAddingBrandModel}
      />
    </>
  )
}

BulkCreateBrandModelSelectContainer.defaultProps = {
  placeholder: "Select Brand(s) & Model(s)"
}

BulkCreateBrandModelSelectContainer.propTypes = {
  placeholder: PropTypes.string
};

export default BulkCreateBrandModelSelectContainer

