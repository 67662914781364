import React from "react";
import { makeStyles, Box, Paper, Divider, Backdrop } from "@material-ui/core";
import HeaderComponent from "../../header";
import StepperComponent from "../../stepper";
import Dialog from "components/dialog/customDialog";
import Tab from "../../tab";

const style = (theme) => ({
  infoContainer: {
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    }
  },
  root: {
    height: "90%",
    width: 1100
  }
});

const useStyles = makeStyles(style);

export default function WarrantyPanelComponent({
  headerType,
  isOpen,
  handleClose,
  earlyAccess
}) {
  const classes = useStyles();
  return (
    <Dialog
      isOpen={isOpen}
      handleClose={handleClose}
      disableEnforceFocus={true}
      disableAutoFocus={true}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      closeAfterTransition
      BackdropComponent={Backdrop}
      maxWidth="none"
    >
      <Paper elevation={0} className={classes.root}>
        <Box className={classes.infoContainer}>
          <HeaderComponent handleClose={handleClose} />
          <Divider />
          {headerType === "create" ? (
            <StepperComponent handleClose={handleClose} earlyAccess={earlyAccess} />
          ) : (
            <Tab earlyAccess={earlyAccess} />
          )}
        </Box>
      </Paper>
    </Dialog>
  );
}
