import React from "react";
import {
  makeStyles,
  Divider,
  Box,
  Typography,
  Button
} from "@material-ui/core";
import ProductInfoForm from "../../form/productInfoForm";
import { getLang } from "app/feature/constants";
import moment from "moment";
import "moment/min/locales.min";

const useStyle = makeStyles(theme => ({
  root: {
    fontWeight: theme.typography.fontWeightBold
  },
  textPrimary: {
    color: theme.palette.text.primary
  },
  secondaryButton: {
    color: theme.palette.common.white
  },
  titleWrapper: {
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shortest
    })
  }
}));

export default function ProductInfoPanelComponent({
  hasProductEditAccessRight,
  isEditing,
  handleEdit,
  handleCancel,
  handleSaveChanges,
  isSaving,
  createdAt,
  updatedAt,
  lang
}) {
  const classes = useStyle();

  return (
    <Box style={{ overflowX: "scroll", backgroundColor: "#fff" }}>
      <Box minHeight="540px" display="flex" flexDirection="column" >
        <ProductInfoForm enableEdit={isEditing} handleNext={handleSaveChanges} handleCancel={handleCancel} formType="edit">
          {({ handleSubmit, handleCancel }) => (
            <Box mt="auto">
              <Divider />
              <Box display="flex" justifyContent="space-between" py={2} px={3}>
                <Typography variant="body2" color="textSecondary">
                  {getLang(lang,"label.CREATED_ON")}&nbsp;
                  <span className={classes.textPrimary}>
                    {moment(createdAt).format('lll')}
                  </span>
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={{ textAlign: "right" }}
                >
                  {getLang(lang,"label.LAST_UPDATE")}&nbsp;
                  <span className={classes.textPrimary}>
                    {moment(updatedAt).format('lll')}
                  </span>
                </Typography>
              </Box>
              {
                hasProductEditAccessRight
                  ?
                  <>
                    <Divider />
                    <Box display="flex" justifyContent="flex-end" py={2} px={3}>
                      {isEditing && (
                        <Button
                          onClick={() => {
                            handleCancel(false)
                          }}
                          type="button"
                          style={{marginRight: "4px"}}
                        >
                          {getLang(lang,"label.CANCEL")}
                        </Button>
                      )}
                      {isEditing && (
                        <Button
                          variant="contained"
                          color="secondary"
                          classes={{ root: classes.secondaryButton }}
                          disableElevation
                          onClick={handleSubmit}
                          type="submit"
                          disabled={isSaving}
                        >
                          {isSaving ? getLang(lang,"label.SAVING") : getLang(lang,"label.SAVE")}
                        </Button>
                      )}
                      {!isEditing && hasProductEditAccessRight && (
                        <Button
                          variant="contained"
                          color="secondary"
                          classes={{ root: classes.secondaryButton }}
                          disableElevation
                          onClick={() => handleEdit(true)}
                          type="button"
                        >
                          {getLang(lang,"label.EDIT")}
                        </Button>
                      )}
                    </Box>
                  </>
                  : null
              }
            </Box>
          )}
        </ProductInfoForm>
      </Box>
    </Box>
  );
}
