import React, { useEffect } from "react"
import { useHistory } from "react-router-dom"
import { useSelector } from "react-redux"
import ConsumerListingComponent from "./consumerListing.page"
import { CONSUMER_VIEW } from "lib/constants/accessRights"
import LoadingComponent from "components/loading"

export default function ConsumerListingContainer() {
  const token = useSelector(state => state.session.accessToken)
  const history = useHistory()
  const hasConsumerViewAccessRight = useSelector(state => state.profile.accessRights.includes(CONSUMER_VIEW))
  const lang = useSelector(state => state.constant.languages)
  useEffect(() => {
    if(!hasConsumerViewAccessRight){
      history.push('/admin/dashboard')
    }
  }, [history, hasConsumerViewAccessRight])

  if (!hasConsumerViewAccessRight) return <LoadingComponent />

  return (
    <ConsumerListingComponent
      token={token} 
      lang={lang}/>
  )
}
