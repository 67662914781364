import React, { useRef, useState } from "react";
import {
  Box,
  Button,
  Divider,
  makeStyles,
  MenuItem,
  TextField,
} from "@material-ui/core";
import clsx from "clsx";

import { getLang } from "../../../../../app/feature/constants";
import { Formik } from "formik";

import ClaimListTable from "../../table/claimListTable";
import ExportToExcelSelect from "../../../../../components/select/exportToExcelSelect";
import DateRangePicker from "../../../../../components/input/dateRangePicker";

const style = (theme) => ({
  searchContainer: {
    backgroundColor: theme.palette.background.paper,
    display: "grid",
    gridTemplateColumns: "4fr 2fr 1fr 1fr 1fr",
    columnGap: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1),
    },
  },
  inputRoot: {
    fontWeight: 500,
    paddingTop: "8px !important",
    paddingBottom: "7px !important",
    paddingLeft: "10px !important",
  },
  select: {
    minWidth: 170,
    [theme.breakpoints.down("xs")]: {
      minWidth: "50%",
    },
  },
  menuItemText: {
    padding: 10,
    fontWeight: 500,
  },
  onFocusBorder: {
    borderRadius: 4,
    outlineWidth: "1px",
    outlineStyle: "solid",
    outlineColor: theme.palette.secondary.main,
  },
  dropdownSearchBox: {
    backgroundColor: "#E9EDEF",
    borderRadius: 4,
  },
  dropdownStatusBox: {
    backgroundColor: "#E9EDEF",
    borderRadius: 4,
  },
  divider: {
    height: "70%",
    placeSelf: "center",
  },
  textFieldInput: {
    padding: "7px",
    paddingTop: "8px",
    minWidth: 140,
    fontWeight: 500,
    color: theme.palette.primary.main,
    [theme.breakpoints.down("xs")]: {
      minWidth: "50%",
    },
  },
});

const useStyles = makeStyles(style);

export default function ConsumerClaimPanelComponent({
  lang,
  isFetching,

  //  SEARCH & FILTER  //
  pageSetting,
  handleSearch,
  handleProcessed,
  search,

  //  TABLE LIST  //
  rows,
  handleReload,

  //  PAGINATION  //
  page,
  totalLength,
  filteredLength,
  rowPerPage,
  rowPerPageOption,
  handlePageChange,
  handleRowPerPageChange,

  isExporting,
  handleExport,
}) {
  const classes = useStyles();
  const [onFocus, setOnFocus] = useState(false);
  const textRef = useRef();

  const columnDropdown = [
    { name: getLang(lang, "label.WARRANTEE_NAME"), value: "warrantee_name" },
    { name: getLang(lang, "label.SERIAL_NUMBER"), value: "serial_number" },
    { name: getLang(lang, "label.PRODUCT"), value: "product_name" },
    { name: getLang(lang, "label.EMAIL"), value: "email" },
  ];

  const statusList = [
    {
      value: 1,
      name: getLang(lang, "label.PENDING"),
      color: "#F6C000",
    },
    {
      value: 2,
      name: getLang(lang, "label.APPROVED"),
      color: "#92C892",
    },
    {
      value: 3,
      name: getLang(lang, "label.REJECTED"),
      color: "#FD646F",
    },
  ];

  const exportDropdown = [{ name: "Excel", value: "xlsx" }];

  return (
    <>
      <Formik
        initialValues={{
          search: pageSetting.search,
          status: pageSetting.status,
          warranty: pageSetting.warranties,
          searchColumn: pageSetting.searchColumn,
          dateRange:
            pageSetting.fromDate && pageSetting.toDate
              ? {
                  from: pageSetting.fromDate,
                  to: pageSetting.toDate,
                }
              : null,
        }}
        enableReinitialize
        onSubmit={handleSearch}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <Box p={2} mb={1} className={classes.searchContainer}>
              <Box display="flex" className={classes.dropdownSearchBox}>
                <TextField
                  className={classes.select}
                  select
                  SelectProps={{
                    MenuProps: {
                      MenuListProps: {
                        style: { padding: 0 },
                      },
                      style: { marginTop: 5 },
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    },
                  }}
                  value={formik.values.searchColumn}
                  size="small"
                  margin="none"
                  InputProps={{
                    disableUnderline: true,
                    classes: { input: classes.inputRoot },
                  }}
                >
                  {columnDropdown.map((column, index) => (
                    <MenuItem
                      className={classes.menuItemText}
                      value={column.value}
                      key={index}
                      onClick={() => {
                        formik.setFieldValue("searchColumn", column.value);
                      }}
                    >
                      {column.name}
                    </MenuItem>
                  ))}
                </TextField>
                <Divider orientation="vertical" className={classes.divider} />
                <TextField
                  fullWidth
                  onFocus={() => setOnFocus(true)}
                  onBlur={() => setOnFocus(false)}
                  size="small"
                  margin="none"
                  placeholder={getLang(lang, "placeholder.SEARCH")}
                  inputRef={textRef}
                  InputProps={{
                    disableUnderline: true,
                    margin: "none",
                  }}
                  inputProps={{
                    className: classes.textFieldInput,
                  }}
                  value={formik.values.search}
                  onChange={(event) => {
                    formik.setFieldValue("search", event.target.value);
                  }}
                />
              </Box>
              <Box>
                <DateRangePicker
                  label=""
                  placeholder={getLang(lang, "placeholder.RESPONSE_DATE")}
                  disabled={isFetching}
                  disableFuture={true}
                  className={clsx({
                    [classes.emptyField]:
                      !formik.getFieldProps("dateRange").value,
                  })}
                  id={"created-picker-dialog"}
                  value={formik.getFieldProps("dateRange").value}
                  handleChange={(v) => {
                    formik.setFieldValue("dateRange", v);
                  }}
                  InputPropStyle={{
                    color: !formik.getFieldProps("dateRange").value
                      ? "#A4A6A8"
                      : "#000000",
                    fontWeight: "normal",
                  }}
                  inputProps={{
                    fontWeight: "400",
                    fontSize: "16px",
                  }}
                  title={getLang(lang, "placeholder.RESPONSE_DATE")}
                />
              </Box>
              <Box
                display="flex"
                alignItems="center"
                className={clsx(classes.dropdownStatusBox, {
                  [classes.onFocusBorder]: onFocus,
                })}
              >
                <TextField
                  className={classes.select}
                  select
                  SelectProps={{
                    MenuProps: {
                      MenuListProps: {
                        style: { padding: 0 },
                      },
                      style: { marginTop: 5 },
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    },
                  }}
                  value={formik.values.status}
                  size="small"
                  margin="none"
                  InputProps={{
                    disableUnderline: true,
                    classes: { input: classes.inputRoot },
                  }}
                >
                  <MenuItem
                    className={classes.menuItemText}
                    value={0}
                    onClick={() => {
                      formik.setFieldValue("status", 0);
                    }}
                  >
                    {getLang(lang, "label.ALL")}
                  </MenuItem>
                  {statusList.map((column, index) => (
                    <MenuItem
                      className={classes.menuItemText}
                      value={column.value}
                      onClick={() => {
                        formik.setFieldValue("status", column.value);
                      }}
                    >
                      {column.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
              <Button
                variant="text"
                disableElevation
                color="primary"
                size="small"
                onClick={() => {
                  formik.resetForm();
                  handleReload();
                }}
              >
                {getLang(lang, "label.CLEAR")}
              </Button>
              <Button
                variant="contained"
                color="primary"
                disableElevation
                type="submit"
                size="small"
                disabled={formik.isSubmitting}
              >
                {getLang(lang, "label.SEARCH")}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
      <Box
        display="flex"
        width="100%"
        justifyContent="end"
        alignItems="center"
        mb={1}
      >
        <ExportToExcelSelect
          disabled={isExporting || isFetching}
          dropdownItems={exportDropdown}
          isLoading={isExporting}
          handleSelect={(type) => handleExport(type)}
        />
      </Box>
      <ClaimListTable
        rows={rows}
        handleReload={handleReload}
        isFetching={isFetching}
        search={search}
        length={search ? filteredLength : totalLength}
        paginationOptions={rowPerPageOption}
        rowsPerPage={rowPerPage}
        page={page}
        statusList={statusList}
        handleRowPerPageChange={handleRowPerPageChange}
        handlePageChange={handlePageChange}
        handleProcessed={handleProcessed}
      />
    </>
  );
}
