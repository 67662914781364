import axios from "axios";

const endpoint_url = "/serialnumber/api/v1/warranty-setting/dropdown-name";

const get = ({ search }) => {
  return new Promise((resolve, reject) => {
    axios
      .get(endpoint_url, {
        params: {
          search: search
        }
      })
      .then(response => resolve(response.data))
      .catch(error => {
        return reject(error.response.data);
      });
  });
};

const warrantyNameDropdown = {
  get
};

export default warrantyNameDropdown;
