import { createAsyncThunk } from "@reduxjs/toolkit";
import { RESET_PASSWORD } from "../../constants";
import accountApi from "app/api/account";


export const checkResetPasswordResendTimeLeft = createAsyncThunk(
  `${RESET_PASSWORD}/checkResetPasswordResendTimeLeft`,
  async (payload, { rejectWithValue }) => {
    return accountApi.checkResetPasswordResendTimeLeft({ email: payload })
      .then((response) => response)
      .catch((error) => rejectWithValue(error));
  }
);

export const requestResetPassword = createAsyncThunk(
  `${RESET_PASSWORD}/request`,
  async (payload, { rejectWithValue }) => {
    return accountApi.requestResetPassword({ email: payload })
      .then(response => response)
      .catch(error => rejectWithValue(error));
  }
);
