import React, {useEffect} from "react";
import { useDispatch,useSelector } from "react-redux";
import CreateBrandModelDialogComponent from './createBrandModelDialog.component';
import { getBrandDropdownWithNoBrand } from "modules/product-mgmt/redux/action/brandModels.action";

export default function CreateBrandModelDialogContainer({
  handleSearch,
  isOpen,
  handleClose,
  handleSubmit,
  isSubmittingAddModel,
}) {
  const lang = useSelector(state => state.constant.languages)
  const brandDropdownWithNoBrand = useSelector(state => state.products.brandModelList.brandDropdownWithNoBrand)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getBrandDropdownWithNoBrand());
  }, [dispatch])

  return (
    <CreateBrandModelDialogComponent
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      handleSearch={handleSearch}
      isOpen={isOpen}
      isSubmittingAddModel={isSubmittingAddModel}
      lang={lang}
      brandDropdownWithNoBrand={brandDropdownWithNoBrand}

    />
  )
}