import { selectLoading } from "modules/notification";
import { getBranchSalesData } from "modules/reporting/redux";
import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import BranchSalesCampaignPanel from './branchSalesCampaignPanel.component';

export default function BranchSalesCampaignPanelContainer({
    url,
    currentFilter,
    pageType,
    translate
}){

    const dispatch = useDispatch();

    const isFetchingBranchSalesData = useSelector(state => selectLoading(state, getBranchSalesData.typePrefix));
    const branchSalesData = useSelector(state => state.reporting.sales.branchDatalist);

    const [tableFilter, setTableFilter] = useState({
        page: 0,
        rowsPerPage: 25,
        search: "",
        searchBy: "name",
        orderBy: "engagement",
        orderDirection: "desc",
    })

    useEffect(() => {
        dispatch(getBranchSalesData({
            length: tableFilter.rowsPerPage,
            start: tableFilter.page * tableFilter.rowsPerPage,
            orderBy: tableFilter.orderBy,
            orderDirection: tableFilter.orderDirection,
            search: tableFilter.search,
            searchBy: tableFilter.searchBy,
            countryCode: currentFilter.countryCode,
            city_uuid: currentFilter.cityUuid,
            branch_uuid: currentFilter.branchUuid,
            category_uuid: currentFilter.categoryUuid,
            model_uuid: currentFilter.modelUuid,
            brand_uuid: currentFilter.brandUuid,
            product_uuid: currentFilter.productUuid,
            period: currentFilter.period,
            start_date: moment(currentFilter.dateRange[0]).format('YYYY-MM-DD'),
            end_date: moment(currentFilter.dateRange[1]).format('YYYY-MM-DD'),
            url: url,
            campaign_type: currentFilter.campaignType,
            campaign_uuid: currentFilter.campaignUuid,
        })).then(unwrapResult);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, tableFilter, currentFilter]);

    const handleChangePage = (e, newPage) => {
        setTableFilter((prev) => ({
          ...prev,
          page: newPage,
        }))
    }

    const handleChangeRowPerPage = (event) => {
        setTableFilter((prev) => ({
          ...prev,
          page: 0,
          rowsPerPage: event.target.value
        }))
    }

    const handleRequestSort = (event, property) => {
        const isAsc = tableFilter.orderBy === property && tableFilter.orderDirection === 'asc';
        setTableFilter((prev) => ({
            ...prev,
            orderDirection: isAsc ? 'desc' : 'asc',
            orderBy: property,
        }))
    }

    return (
        <BranchSalesCampaignPanel 
            status={{
                isFetchingBranchSalesData,
            }}

            data={{
                branchSalesData,
            }}

            sort={{
                valueToOrderBy: tableFilter.orderBy,
                orderDirection: tableFilter.orderDirection,
                handleRequestSort: handleRequestSort,
            }}

            pagination={{
                page: tableFilter.page,
                rowsPerPage: tableFilter.rowsPerPage,
                rowsPerPageOptions: [5, 10, 25],
                handleChangePage: handleChangePage,
                handleChangeRowPerPage: handleChangeRowPerPage,
            }}

            currentFilter={currentFilter}

            pageType={pageType}

            translate={translate}
        />
    )
}