import React, { useState, useEffect } from "react";
import VideoFormComponent from "./videoForm.component";
import PropTypes from "prop-types";
import { getYoutubeIdByUrl, isUrlValid } from "../../../utils/productPage.util";
import { useSelector } from "react-redux";

const TYPES = ["new", "edit", "readonly"];

function VideoFormContainer({ type, handleResult, initialValues,handleDelete, handleClose }) {
  const [thumbnail, setThumbnail] = useState("");

  const lang = useSelector(state => state.constant.languages)

  const handleGetThumbnail = url => {
    if (url === "" || !isUrlValid(url)) return;
    const id = getYoutubeIdByUrl(url) || "";

    if (id === "") return;

    const thumbnailUrl = `https://img.youtube.com/vi/${id}/hqdefault.jpg`;
    setThumbnail(thumbnailUrl);
  };

  useEffect(() => {
    handleGetThumbnail(initialValues.URL);
  }, [initialValues]);

  return (
    <VideoFormComponent
      type={type}
      handleSubmit={handleResult}
      handleDelete={handleDelete}
      initialValues={initialValues}
      thumbnail={thumbnail}
      handleGetThumbnail={handleGetThumbnail}
      lang={lang}
      handleClose={handleClose}
    />
  );
}

VideoFormContainer.defaultProps = {
  type: TYPES[0],
  initialValues: { URL: "" }
};

VideoFormContainer.propTypes = {
  type: PropTypes.oneOf(TYPES).isRequired,
  handleResult: PropTypes.func,
  handleDelete: PropTypes.func,
  initialValues: PropTypes.object
};

export { TYPES };

export default VideoFormContainer;
