import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch, useHistory } from "react-router-dom";
import { STOCKFLOW_REWARD_CENTER_VIEW } from "lib/constants/accessRights";
import {
  fetchJSONURL,
  getRewardDetails,
  resetRewardDetails,
  setFormType
} from "modules/stockflow/redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { selectLoading } from "modules/notification";
import RewardDetailsComponent from "./reward-details.page";

export default function RewardDetailsPageContainer() {
  const dispatch = useDispatch();
  const history = useHistory();
  const lang = useSelector(state => state.constant.languages);
  const language = useSelector(state => state.profile.language);
  const match = useRouteMatch();
  const id = match.params.rewardId;
  const isFetching = useSelector(state => selectLoading(state, getRewardDetails.typePrefix));
  const isFetchingTncUrl = useSelector(state => selectLoading(state, fetchJSONURL.typePrefix));
  const isError = useSelector(state => state.stockflowRewardCenter.rewardDetails.isError);
  const hasStockflowViewAccessRight = useSelector(state => state.profile.accessRights.includes(STOCKFLOW_REWARD_CENTER_VIEW));
  const [activeIndex, setActiveIndex] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const rewardValues = useSelector(state => state.stockflowRewardCenter.rewardDetails);
  const isEdit = useSelector( state => state.stockflowRewardCenter.formType)

  useEffect(() => {
    if (!hasStockflowViewAccessRight) {
      history.push('/admin/dashboard')
    }
  }, [history, hasStockflowViewAccessRight, dispatch])

  const handleChange = (event, index) => {
    setActiveIndex(index);
    setIsEditing(false);
  }

  const handleEdit = (value) => {
    setIsEditing(value)
  }

  useEffect(() => {
    dispatch(setFormType("view"))
    if (id) {
      loadContent();
    }
    else {
      history.push('/admin/dashboard')
    }
    return () => {
      dispatch(resetRewardDetails());
    }
    // eslint-disable-next-line
  }, [id])

  const loadContent = () => {
    dispatch(getRewardDetails(id))
      .then(unwrapResult)
      .then(async (response) => {
        let tempTncUrl = response.data.data.tnc_url;
        if (response.data.data.tnc_type === "text") {
          await dispatch(fetchJSONURL(tempTncUrl))
            .then(unwrapResult)
        }
      })
  }

  const reloadContent = () => {
    loadContent();
  }

  return (
    <>
      <RewardDetailsComponent
        isFetching={isFetching || isFetchingTncUrl}
        isError={isError}
        handleChange={handleChange}
        handleEdit={handleEdit}
        language={language}
        isEditing={isEditing}
        id={id}
        activeIndex={activeIndex}
        rewardValues={rewardValues}
        reloadContent={reloadContent}
        isEdit={isEdit}
        lang={lang}
      />
    </>
  )
}