/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { cloneDeep } from "lodash";
import CheckoutInitiatorSelectComponent from "./checkoutInitiatorSelect.component";
import CheckoutInitiatorSelectPanelComponent from "./checkoutInitiatorSelectPanel.component";
import {
  getCheckoutByDropdown,
  resetCheckoutByDropdown,
} from "modules/stockflow/redux";
import { selectLoading } from "modules/notification";

function CheckoutInitiatorSelectContainer({
  handleChange,
  disabled,
  value,
  styles,
  textStyles,
  placeholder,
  dealerUuid,
}) {
  const dispatch = useDispatch();
  const isFetching = useSelector((state) =>
    selectLoading(state, getCheckoutByDropdown.typePrefix)
  );
  const checkoutInitiatorDropdown = useSelector(
    (state) => state.stockflowCheckout.checkoutInitiatorDropdown.list
  );
  const isError = useSelector(
    (state) => state.stockflowCheckout.checkoutInitiatorDropdown.isError
  );
  const totalFiltered = useSelector(
    (state) => state.stockflowCheckout.checkoutInitiatorDropdown.totalFiltered
  );
  const hasNextPage = useSelector(
    (state) => state.stockflowCheckout.checkoutInitiatorDropdown.hasNextPage
  );

  const lang = useSelector((state) => state.constant.languages);

  const [open, setOpen] = useState(false);
  const [isOpenList] = useState(false);
  const anchorRef = useRef(null);
  const prevOpen = useRef(open);
  const prevOpenList = useRef(isOpenList);
  const [selectAll, setSelectAll] = useState(false);
  const [filter, setFilter] = useState({
    page: 0,
    rowsPerPage: 25,
  });

  useEffect(() => {
    if (hasNextPage && !isFetching) {
      getData();
    }
  }, [filter, dispatch, dealerUuid]);

  const getData = () => {
    let param = {
      length: filter.rowsPerPage,
      start: filter.rowsPerPage * filter.page,
    };
    if (dealerUuid) {
      param.checkout_from_dealer = dealerUuid;
    }
    dispatch(getCheckoutByDropdown(param));
  };

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    if (prevOpenList.current === true && isOpenList === false) {
      anchorRef.current.focus();
    }

    prevOpenList.current = isOpenList;
  }, [isOpenList]);

  useEffect(() => {
    setSelectAll(value.length === checkoutInitiatorDropdown.length);
  }, [value, checkoutInitiatorDropdown]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    dispatch(resetCheckoutByDropdown());
    setOpen(false);
    setFilter({
      ...filter,
      page: 0,
      search: "",
    });
  };

  const handleSelectAll = async () => {
    if (!selectAll) {
      let selectedReceiver = checkoutInitiatorDropdown.map((p) => {
        return {
          uuid: p.uuid,
          is_dealer: p.is_dealer,
        };
      });
      handleChange(selectedReceiver);
    } else {
      handleChange([]);
    }
  };

  const handleSelectUser = (user) => {
    let temp = cloneDeep(value);

    if (temp.findIndex((t) => t.uuid === user.uuid) >= 0) {
      temp = temp.filter((t) => t.uuid !== user.uuid);
    } else {
      temp.push({
        uuid: user.uuid,
        is_dealer: user.is_dealer,
      });
    }
    handleChange(temp);
  };

  const handleFetchMoreItems = () => {
    if (hasNextPage) {
      const newFilter = { ...filter, page: filter.page + 1 };
      setFilter(newFilter);
    }
  };

  const handleSearch = (value) => {
    dispatch(resetCheckoutByDropdown());
    setFilter({
      ...filter,
      page: 0,
      search: value,
    });
  };

  const handleReload = () => {
    dispatch(resetCheckoutByDropdown());
    setFilter({
      ...filter,
      page: 0,
      search: "",
    });
  };

  return (
    <>
      <CheckoutInitiatorSelectComponent
        anchorRef={anchorRef}
        disabled={disabled}
        value={value}
        handleClick={handleOpen}
        styles={styles}
        textStyles={textStyles}
        placeholder={placeholder}
        lang={lang}
      />
      <CheckoutInitiatorSelectPanelComponent
        selectAll={selectAll}
        value={value}
        anchorRef={anchorRef}
        open={open}
        isOpenList={isOpenList}
        handleClose={handleClose}
        isFetching={isFetching}
        checkoutInitiatorDropdown={checkoutInitiatorDropdown}
        handleSelectAll={handleSelectAll}
        handleSelectUser={handleSelectUser}
        handleSearch={handleSearch}
        lang={lang}
        isError={isError}
        hasNextPage={hasNextPage}
        totalFiltered={totalFiltered}
        handleFetchMoreItems={handleFetchMoreItems}
        handleReload={handleReload}
      />
    </>
  );
}

export default CheckoutInitiatorSelectContainer;
