import React from "react";

import ChangePlanDialogComponent from "./changePlanDialog.component";
import { useSelector } from "react-redux";

export default function ChangePlanDialogContainer({
  isLoadingChangeSubscriptionPlanCharges,
  changeSubscriptionPlanCharges,
  registrationType,
  subscriptionType,
  userSubscriptionPlan,
  subscriptionPlan,
  onChangePlanCloseClick,
  onProceedChangePlanClick,
  onChangePlanIntervalClick
}) {
  
  const lang = useSelector(state => state.constant.languages);
  return (
    <ChangePlanDialogComponent
      isLoadingChangeSubscriptionPlanCharges={isLoadingChangeSubscriptionPlanCharges}
      changeSubscriptionPlanCharges={changeSubscriptionPlanCharges}
      registrationType={registrationType}
      subscriptionType={subscriptionType}
      userSubscriptionPlan={userSubscriptionPlan}
      subscriptionPlan={subscriptionPlan}
      onChangePlanCloseClick={onChangePlanCloseClick}
      onProceedChangePlanClick={onProceedChangePlanClick}
      onChangeSubscriptionTypeClick={onChangePlanIntervalClick}
      lang={lang}
    />
  );
}