import React, { useState, useRef, useEffect } from "react"
import RecallDealerSelectComponent from "./recallDealerSelect.component"
import { selectLoading } from "modules/notification"
import RecallDealerSelectPanelComponent from "./recallDealerSelectPanel.component"
import { getRecallDealerDropdown } from "modules/stockflow/redux"
import { useSelector, useDispatch } from "react-redux"
import { cloneDeep } from "lodash"

function RecallDealerSelectContainer({
  handleChange,
  disabled,
  value,
  styles, 
  textStyles,
  placeholder,
  dealerUuid,
}) {
  const isFetching = useSelector(state => selectLoading(state, getRecallDealerDropdown.typePrefix))
  const recallDealerDropdown = useSelector((state) => state.stockflowRecall.recallDealerDropdown)
  const lang = useSelector(state => state.constant.languages);
  const [open, setOpen] = useState(false)
  const [isOpenList] = useState(false)
  const anchorRef = useRef(null)
  const prevOpen = useRef(open)
  const prevOpenList = useRef(isOpenList)
  const [selectAll, setSelectAll] = useState(false)
  const [search, setSearch] = useState("")
  const dispatch = useDispatch()

  useEffect(() => {
    let param = { search }
    if (dealerUuid) {
      param.recall_from_dealer = dealerUuid
    }
    dispatch(getRecallDealerDropdown(param))
  }, [search, dispatch, dealerUuid])

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])

  useEffect(() => {
    if (prevOpenList.current === true && isOpenList === false) {
      anchorRef.current.focus()
    }

    prevOpenList.current = isOpenList
  }, [isOpenList])

  useEffect(() => {
    setSelectAll(value.length === recallDealerDropdown.length)
  }, [value, recallDealerDropdown])

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
    setSearch("")
  }

  const handleSelectAll = async () => {
    if(!selectAll){
      let selectedProducts = recallDealerDropdown.map(p => {
        return p.id
      })
      handleChange(selectedProducts)
    }else{
      handleChange([])
    }
  }

  const handleSelectDealer = (requestor) => {
    let temp = cloneDeep(value)

    if(temp.findIndex(t => t === requestor.id) >= 0){
      temp = temp.filter( t => t !== requestor.id )
    }else{
      temp.push(requestor.id)
    }
    handleChange(temp)
  }

  const handleSearch = (value) => {
    setSearch(value)
  }

  return (
    <>
      <RecallDealerSelectComponent
        anchorRef={anchorRef}
        disabled={disabled}
        value={value}
        handleClick={handleOpen}
        styles={styles}
        textStyles={textStyles}
        placeholder={placeholder}
        lang={lang}
      />
      <RecallDealerSelectPanelComponent
        selectAll={selectAll}
        value={value}
        anchorRef={anchorRef}
        open={open}
        isOpenList={isOpenList}
        handleClose={handleClose}
        isFetching={isFetching}
        recallDealerDropdown={recallDealerDropdown}
        handleSelectAll={handleSelectAll}
        handleSelectDealer={handleSelectDealer}
        handleSearch={handleSearch}
        lang={lang}
      />
    </>
  )
}

export default RecallDealerSelectContainer