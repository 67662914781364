import React, { useEffect, useState } from "react";
import WebsiteComponent from "./website.component";

import { useSelector } from "react-redux";
import apiLinkPreview from "app/api/linkpreview";

export default function WebsiteContainer({ id, currentLanguage }) {
  const {
    isVisible,
    content: { URL, descriptions }
  } = useSelector(state => state.productPage.components[id]);
  const lang = useSelector(state => state.constant.languages);
  const description = descriptions.find((e) => e.lang === currentLanguage).value;
  const [isFetching, setFetching] = useState(false);
  const [error, setError] = useState("");
  const [metadata, setMetadata] = useState({
    desc: "",
    image: "",
    favicon: "",
    title: ""
  });

  useEffect(() => {
    if (URL !== "" && isVisible) {
      setFetching(true);
      (async function () {
        const response = await apiLinkPreview(URL);

        const isSuccess =
          typeof response.data !== "string" && response.data !== "Fail";

        if (!isSuccess) {
          setError("Invalid url");
          setFetching(false);
          return;
        }

        const { description = "", image = "", favicon = "", title = ""} = response.data;

        setFetching(false);
        setError("");
        setMetadata({
          desc: description,
          image,
          favicon,
          title
        });
      })();
    }
  }, [URL, isVisible]);

  return (
    isVisible && (
      <WebsiteComponent
        isFetching={isFetching}
        error={error}
        url={URL}
        desc={description}
        alt_desc={metadata.desc}
        title={metadata.title}
        image={metadata.image}
        favicon={metadata.favicon}
        lang={lang}
      />
    )
  );
}
