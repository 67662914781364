import React from "react";
import { Box, Paper, makeStyles, Typography, Divider } from "@material-ui/core";
import { getLang } from "app/feature/constants";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  content: {
    width: "100%",
    textAlignLast: "start"
  },
  field: {
    display: "grid",
    gridTemplateColumns: "3fr 2fr 2fr",
    columnGap: theme.spacing(2),
    marginBottom: theme.spacing(1)
  }
}));

export default function SummaryFormComponent({
  children,
  calculation,
  handlePurchase,
  lang
}) {
  const classes = useStyles();

  return (
    <Paper elevation={0} className={classes.root}>
      <Box className={classes.content}>
        <Box mt={2} px={{ xs: 2, sm: 10 }}>
          <Box py={1}>
            {[
              { label: getLang(lang, 'label.CREDIT_QUANTITY'), value: calculation.quantity },
              { label: getLang(lang, 'label.COST_OR_CREDIT'), value: `$ ${calculation.basePrice}` },
              { label: getLang(lang, 'label.SUB_TOTAL'), value: `$ ${calculation.subTotal_1}` }
            ].map((field, index) => (
              <Box key={index} className={classes.field}>
                <Typography variant="body2" color="textSecondary">
                  {field.label}
                </Typography>
                <Typography variant="body2" style={{ fontWeight: "bold" }}>{field.value}</Typography>
              </Box>
            ))}
          </Box>
          {/* display discount - start */}
          {
            /*
            !calculation.coupon ? null : (
              <>
                <Divider />
                <Box pt={1}>
                  {[{ label: getLang(lang, 'label.COUPON_CODE'), value: calculation.couponCode }].map(
                    (field, index) => (
                      <Box key={index} className={classes.field}>
                        <Typography variant="body2" color="textSecondary">
                          {field.label}
                        </Typography>
                        <Typography variant="body2" style={{ fontWeight: "bold" }}>{field.value}</Typography>
                        { 
                          // <Box ml="auto">
                          // <Typography variant="body2" color="textSecondary">
                          //   Referral code
                          // </Typography>
                          // </Box>
                        }
                      </Box>
                    )
                  )}
                </Box>
              </>
            )
            */
          }
          {/* display discount - end */}
          <Divider />
          <Box pt={2} pb={1}>
            {
              /*
                !calculation.coupon ? null : (
                <>
                  {[
                    {
                      label: getLang(lang, 'label.PROMO'),
                      value: `${calculation.couponType === "fixed" ? "$ " : ""}${calculation ? calculation.coupon : 0
                        }${calculation.couponType === "percent" ? "%" : ""}`
                    },
                    {
                      label: getLang(lang, 'label.PROMO_AMOUNT'),
                      value: `$ ${calculation ? calculation.couponAmount : 0}`
                    },
                    {
                      label: getLang(lang, 'label.SUB_TOTAL_AFTER_PROMO'),
                      value: `$ ${calculation ? calculation.subTotal_2 : 0}`
                    }
                  ].map((field, index) => {
                    if (index === 1 && calculation.couponType === "fixed") return true;
                    return (
                      <Box key={index} className={classes.field}>
                        <Typography variant="body2" color="textSecondary">
                          {field.label}
                        </Typography>
                        <Typography variant="body2" style={{ fontWeight: "bold" }}>{field.value}</Typography>
                      </Box>
                    );
                  })}
                </>
              )
              */
            }
            {
              /*
              [
                { label: `${getLang(lang, 'label.REBATE')}(%)`, value: `${calculation.discounts}%` },
                {
                  label: getLang(lang, 'label.REBATE_PRICE'),
                  value: `$ ${calculation.discountAmount}`
                },
                {
                  label: getLang(lang, 'label.SUB_TOTAL_AFTER_REBATE'),
                  value: `$ ${calculation.subTotal_3}`
                }
              ].map((field, index) => (
                <Box key={index} className={classes.field}>
                  <Typography variant="body2" color="textSecondary">
                    {field.label}
                  </Typography>
                  <Typography variant="body2" style={{ fontWeight: "bold" }}>{field.value}</Typography>
                </Box>
              ))
              */
            }
            {/* tax field start */}
            {[
              {
                label: getLang(lang, 'label.SALES_AND_SERVICE_TAX'),
                value: `${calculation ? calculation.tax * 100 : 0}%`
              },
              {
                label: getLang(lang, 'label.TAX_AMOUNT'),
                value: `$ ${calculation ? calculation.taxAmount : 0}`
              }
            ].map((field, index) => (
              <Box key={index} className={classes.field}>
                <Typography variant="body2" color="textSecondary">
                  {field.label}
                </Typography>
                <Typography variant="body2" style={{ fontWeight: "bold" }}>{field.value}</Typography>
              </Box>
            ))}
            {/* tax field end */}
          </Box>

          <Divider />
          <Box py={2} className={classes.field}>
            <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
              {getLang(lang, 'label.TOTAL_COST')}
            </Typography>
            <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
              {`$ ${calculation.totalPrice}`}
            </Typography>
          </Box>

          {/* <Box p={2}>
            <CouponForm />
          </Box> */}
        </Box>

        {/* step footer */}

        <Divider />
        <Box p={2}>
          <form onSubmit={handlePurchase}>{children({ disabled: false })}</form>
        </Box>
      </Box>
    </Paper>
  );
}
