import React from "react";
import {
  Box,
  Paper,
  Typography,
  makeStyles,
  Button,
  Link,
  Divider,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import DefaultImg from "assets/img/img-default.png";
import DefaultImage from "assets/img/defaultPhoto.png";
import { ROOT } from "modules/stockflow/config/stockflow.route";
import { useHistory } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "components/accordion";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Input from "components/input/inputTextField";
import { Formik } from "formik";
import { TIER_ICON_PROPS } from "modules/stockflow/constants";
import { getLang } from "app/feature/constants";

const useStyles = makeStyles((theme) => ({
  productContainer: {
    padding: "20px 16px",
    borderRadius: "8px !important",
  },
  productPhoto: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #0000001a",
    width: "50px",
    height: "50px",
    borderRadius: "4px",
    "& img": {
      height: "100%",
      width: "100%",
    },
  },
  greenLabel: {
    color: "#6AAF68",
  },
  searchContainer: {
    backgroundColor: theme.palette.background.paper,
    display: "grid",
    gridTemplateColumns: "5fr 1fr 1fr",
    columnGap: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1),
    },
  },
  inputRootProduct: {
    paddingTop: "8px !important",
    paddingBottom: "7px !important",
    paddingLeft: "12px !important",
    paddingRight: "12px !important",
  },
  quantityChip: {
    backgroundColor: "#98A2B3",
    color: "#ffffff",
    borderRadius: "15px",
    padding: "0px 10px",
  },
  list: {
    maxHeight: "75vh",
    overflowY: "auto",
    padding: "4px 16px 16px",
    "&::-webkit-scrollbar": {
      width: "10px",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "16px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#E4E7EC",
      borderRadius: "16px",
    },
  },
  dealerContainer: {
    padding: "20px 16px",
    borderRadius: "8px",
  },
  dealerProfile: {
    height: 80,
    width: 80,
    minWidth: 80,
    padding: "2px",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& img": {
      height: "100%",
      minHeight: "100%",
      minWidth: "100%",
      objectFit: "cover",
      borderRadius: "50%",
      border: "1px solid #D0D5DD",
    },
  },
  tierIcon: {
    borderRadius: "4px",
    paddingLeft: "0.15em",
    paddingRight: "0.15em",
    display: "flex",
    alignItems: "center",
    position: "absolute",
    top: "2px",
    left: "-1px",
  },
  tierLabel: {
    color: "#FFFFFF",
  },
  attributeText: {
    color: "#b2b2b2",
    fontSize: 12,
    whiteSpace: "break-spaces",
  },
}));

export default function RecallItemListPanelComponent({
  hasStockflowDealerViewAccessRight,
  recallItems,
  isFetching,
  handleSearch,
  lang,
}) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Paper elevation={0}>
      {!isFetching ? (
        <Box p={0.5} pb={1}>
          <Box px={2} pt={2}>
            <Typography variant="body1">
              <b>{getLang(lang, "label.ITEMS_RECALLED")}</b>
            </Typography>
          </Box>
          <Formik
            initialValues={{
              keyword: "",
            }}
            onSubmit={handleSearch}
            onReset={handleSearch}
          >
            {(formik) => (
              <form onSubmit={formik.handleSubmit}>
                <Box p={2} className={classes.searchContainer}>
                  <Input
                    hiddenLabel
                    variant="filled"
                    size="small"
                    placeholder="Search product, box or package"
                    margin="none"
                    InputProps={{
                      disableUnderline: true,
                      margin: "none",
                      style: {
                        borderRadius: "4px",
                        backgroundColor: "#eceff0",
                      },
                      classes: { input: classes.inputRootProduct },
                    }}
                    {...formik.getFieldProps("keyword")}
                  />
                  <Button
                    variant="text"
                    disableElevation
                    color="primary"
                    size="small"
                    onClick={formik.handleReset}
                  >
                    {getLang(lang, "label.CLEAR")}
                  </Button>
                  <Button
                    variant="contained"
                    disableElevation
                    color="primary"
                    size="small"
                    type="submit"
                  >
                    {getLang(lang, "label.SEARCH")}
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
          <Box my={1} mx={2}>
            <Typography variant="body1">
              <b>
                {getLang(lang, "label.TOTAL_DEALERS", {
                  count: recallItems.length,
                })}
              </b>
            </Typography>
          </Box>
          <Box className={classes.list}>
            {recallItems.length ? (
              recallItems.map((dealer, index) => (
                <Accordion
                  key={dealer.id}
                  defaultExpanded={false}
                  style={{ marginTop: !!index ? "16px" : 0 }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className={classes.dealerContainer}
                  >
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      flex={1}
                    >
                      <Box display="flex" alignItems="center" mr={1}>
                        <Box mr={2} className={classes.dealerProfile}>
                          {dealer.picture ? (
                            <img src={dealer.picture} alt={dealer.name} />
                          ) : (
                            <img src={DefaultImage} alt="default profile" />
                          )}
                          {!!dealer.tier && (
                            <Box
                              className={classes.tierIcon}
                              style={{
                                backgroundColor: TIER_ICON_PROPS.find(
                                  ({ tier }) => tier === dealer.tier
                                ).color,
                              }}
                            >
                              <Typography
                                className={classes.tierLabel}
                                variant="body1"
                              >
                                T{dealer.tier}
                              </Typography>
                            </Box>
                          )}
                        </Box>
                        <Box>
                          {hasStockflowDealerViewAccessRight ? (
                            <Link
                              href={`${ROOT}/my-dealer/${dealer.uuid}`}
                              color="secondary"
                              onClick={(e) => {
                                history.push(
                                  `${ROOT}/my-dealer/${dealer.uuid}`
                                );
                                e.preventDefault();
                                return false;
                              }}
                            >
                              <Typography variant="body1">
                                {dealer.name}
                              </Typography>
                            </Link>
                          ) : (
                            <Typography variant="body1">
                              {dealer.name}
                            </Typography>
                          )}
                          {!!dealer.total_boxes && (
                            <Typography variant="body1" color="textSecondary">
                              {getLang(lang, "paragraph.BOX_PACKAGE_COUNT", {
                                box: dealer.total_boxes,
                                package: dealer.total_packages_in_box,
                              })}
                            </Typography>
                          )}
                          {!!dealer.total_packages && (
                            <Typography variant="body1" color="textSecondary">
                              {getLang(lang, "paragraph.SERIAL_NUMBER_COUNT", {
                                count: dealer.total_packages,
                              })}
                            </Typography>
                          )}
                        </Box>
                      </Box>
                      <Typography variant="body1" style={{ color: "#F97066" }}>
                        - {dealer.deduct_point.toLocaleString("en-US")}
                      </Typography>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <>
                      {!!dealer.boxes.length && (
                        <Box mx={2} mb={2}>
                          <Accordion
                            defaultExpanded={false}
                            style={{ border: "none", boxShadow: "none" }}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                              style={{ backgroundColor: "#F2F4F7" }}
                            >
                              <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                flex={1}
                              >
                                <Typography>
                                  <b>{getLang(lang, "label.BOX")}</b>
                                </Typography>
                                <Box className={classes.quantityChip}>
                                  <Typography variant="body1">
                                    {dealer.total_boxes.toLocaleString("en-US")}
                                  </Typography>
                                </Box>
                              </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                              {dealer.boxes.map((item, index) => (
                                <Box>
                                  {!!index && <Divider />}
                                  <Box p={2}>
                                    <Typography>
                                      <b>
                                        {index + 1}. {item.name}
                                      </b>
                                    </Typography>
                                  </Box>
                                  <Box px={5}>
                                    {item.products.map((p, i) => (
                                      <Box mt={!!i ? 1 : 0} key={i}>
                                        <Box
                                          display="flex"
                                          alignItems="center"
                                          justifyContent="space-between"
                                        >
                                          <Box
                                            display="flex"
                                            alignItems="center"
                                          >
                                            <Box
                                              style={{ width: 36, height: 36 }}
                                              className={classes.productPhoto}
                                              mr={2}
                                              ml={1}
                                            >
                                              {p?.picture === "" ? (
                                                <img
                                                  src={DefaultImg}
                                                  alt="emptyProduct"
                                                />
                                              ) : (
                                                <img
                                                  src={p.picture}
                                                  alt={p.name}
                                                />
                                              )}
                                            </Box>
                                            <Box
                                              display="flex"
                                              flexDirection="column"
                                              justifyContent="center"
                                              alignItems="flex-start"
                                              mr={1}
                                            >
                                              <Typography variant="body1">
                                                <b>{p.name}</b>
                                              </Typography>
                                              {!!p.attributes &&
                                                !!p.attributes.length && (
                                                  <Typography
                                                    className={
                                                      classes.attributeText
                                                    }
                                                    variant="body2"
                                                  >
                                                    {p.attributes.join(", ")}
                                                  </Typography>
                                                )}
                                            </Box>
                                          </Box>
                                          <Typography variant="body1">
                                            {p.quantity.toLocaleString("en-US")}
                                          </Typography>
                                        </Box>
                                        <Box ml={6.5} my={2}>
                                          {p.packages.map((pkg, seq) => (
                                            <Box mt={!!seq ? 1 : 0} key={seq}>
                                              <Box>
                                                <Typography variant="body1">
                                                  {seq + 1}. {pkg.name}
                                                </Typography>
                                              </Box>
                                              <Box px={3} pb={1} mt={1}>
                                                {pkg.children.map((child) => (
                                                  <Box
                                                    key={child.code}
                                                    mb={0.25}
                                                  >
                                                    <Typography
                                                      color="textSecondary"
                                                      style={{ marginRight: 3 }}
                                                      key={child.code}
                                                      display="inline"
                                                    >
                                                      {child.code}
                                                    </Typography>
                                                    {child.type ===
                                                      "Loose Item" && (
                                                      <Typography
                                                        color="textSecondary"
                                                        key={child.code}
                                                        display="inline"
                                                      >
                                                        (
                                                        {child.quantity?.toLocaleString(
                                                          "en-US"
                                                        ) || 0}
                                                        )
                                                      </Typography>
                                                    )}
                                                  </Box>
                                                ))}
                                              </Box>
                                            </Box>
                                          ))}
                                        </Box>
                                      </Box>
                                    ))}
                                  </Box>
                                </Box>
                              ))}
                            </AccordionDetails>
                          </Accordion>
                        </Box>
                      )}
                      {!!dealer.packages.length && (
                        <Box mx={2} mb={2}>
                          <Accordion
                            defaultExpanded={false}
                            style={{ border: "none", boxShadow: "none" }}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                              style={{ backgroundColor: "#F2F4F7" }}
                            >
                              <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                flex={1}
                              >
                                <Typography>
                                  <b>{getLang(lang, "label.SERIAL_NUMBER")}</b>
                                </Typography>
                                <Box className={classes.quantityChip}>
                                  <Typography variant="body1">
                                    {dealer.total_packages.toLocaleString(
                                      "en-US"
                                    )}
                                  </Typography>
                                </Box>
                              </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                              {dealer.packages.map((item, index) => (
                                <Box pt={2}>
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    pr={1}
                                  >
                                    <Box
                                      display="flex"
                                      alignItems="center"
                                      px={1}
                                      mr={1}
                                    >
                                      <Typography
                                        variant="body1"
                                        style={{ marginRight: 10 }}
                                      >
                                        <b>{index + 1}.</b>
                                      </Typography>
                                      <Box
                                        className={classes.productPhoto}
                                        mr={2}
                                        ml={1}
                                      >
                                        {item?.picture === "" ? (
                                          <img
                                            src={DefaultImg}
                                            alt="emptyProduct"
                                          />
                                        ) : (
                                          <img
                                            src={item.picture}
                                            alt={item.name}
                                          />
                                        )}
                                      </Box>
                                      <Box
                                        display="flex"
                                        flexDirection="column"
                                        justifyContent="center"
                                        alignItems="flex-start"
                                        mr={1}
                                      >
                                        <Typography variant="body1">
                                          <b>{item.name}</b>
                                        </Typography>
                                        {!!item.attributes &&
                                          !!item.attributes.length && (
                                            <Typography
                                              className={classes.attributeText}
                                              variant="body2"
                                            >
                                              {item.attributes.join(", ")}
                                            </Typography>
                                          )}
                                      </Box>
                                    </Box>
                                    <Typography>
                                      {item.quantity.toLocaleString("en-US")}
                                    </Typography>
                                  </Box>
                                  <Box px={9} pt={2} pb={1}>
                                    {item.packages.map((p, i) => (
                                      <Box mt={!!i ? 1 : 0} key={i}>
                                        <Box>
                                          <Typography variant="body1">
                                            {i + 1}. {p.name}
                                          </Typography>
                                        </Box>
                                        <Box px={3} pb={1} mt={1}>
                                          {p.children.map((child) => (
                                            <Box key={child.code} mb={0.25}>
                                              <Typography
                                                color="textSecondary"
                                                style={{ marginRight: 3 }}
                                                key={child.code}
                                                display="inline"
                                              >
                                                {child.code}
                                              </Typography>
                                              {child.type === "Loose Item" && (
                                                <Typography
                                                  color="textSecondary"
                                                  key={child.code}
                                                  display="inline"
                                                >
                                                  (
                                                  {child.quantity?.toLocaleString(
                                                    "en-US"
                                                  ) || 0}
                                                  )
                                                </Typography>
                                              )}
                                            </Box>
                                          ))}
                                        </Box>
                                      </Box>
                                    ))}
                                  </Box>
                                </Box>
                              ))}
                            </AccordionDetails>
                          </Accordion>
                        </Box>
                      )}
                    </>
                  </AccordionDetails>
                </Accordion>
              ))
            ) : (
              <Box p={5} display="flex" justifyContent="center">
                <Typography variant="body1">
                  <b>{getLang(lang, "paragraph.NO_ITEM_EXISTS")}</b>
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      ) : (
        <Box p={2}>
          <Skeleton variant="text" height={40} mt={2} />
          <Skeleton variant="text" height={60} mt={2} />
          <Skeleton variant="text" height={60} mt={2} />
          <Skeleton variant="text" height={60} mt={2} />
        </Box>
      )}
    </Paper>
  );
}
