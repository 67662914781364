import { Box, makeStyles, Typography } from '@material-ui/core';
import { numberFormatteer } from 'modules/reporting/utils/helper';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    trendingCard: {
        padding: "12px",
        backgroundColor: "#FFFFFF",
        borderRadius: "12px",
        border: "1px solid #D0D5DD"
    },
    trendingCard__totalCount: {
        fontSize: "20px",
        color: "#000000",
        fontWeight: "700",
        lineHeight: "30px",
        fontFamily: "Roboto",
    },
    trendingCard__trendingCountIncrement: {
        color: "#32D583",
        fontSize: "12px",
        fontWeight: "700",
        lineHeight: "30px",
        fontFamily: "Roboto",
    },
    trendingCard__trendingCountDecrement: {
        color: "#F97066",
        fontSize: "12px",
        fontWeight: "700",
        lineHeight: "30px",
        fontFamily: "Roboto",
    },
    trendingCard__trendingIndicatorIncrement: {
        color: "#FFFFFF",
        backgroundColor: "#32D583",
        display: "flex",
        alignItems: "center",
        gap: "4px",
        borderRadius: "8px",
        padding: "4px 8px",
    },
    trendingCard__trendingIndicatorDecrement: {
        color: "#FFFFFF",
        backgroundColor: "#F97066",
        display: "flex",
        alignItems: "center",
        gap: "4px",
        borderRadius: "8px",
        padding: "4px 8px",
    },
    trendingCard__trendingIndicatorEqual: {
        backgroundColor: "#98A2B3",
        color: "#FFFFFF",
        display: "flex",
        alignItems: "center",
        borderRadius: "8px",
        padding: "4px 8px",
    },
    trendingCard__title: {
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "20px",
        color: "#101828",
    },
    trendingCard_info: {
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "20px",
        color: "#7C8DB5"
    },
    global__flexGap3: {
        gap: "3px"
    }
}))

export default function TrendingSingleCampaignCardComponent({
    data,
    title,
    value = false
}){
    const classes = useStyles();

    return (
        <Box
            className={classes.trendingCard}
        >
            {/* 1st ROW: Total Count & Trending % */}
            <Box display="flex" flexDirection="column" fontFamily="Roboto">
                <Typography className={classes.trendingCard__totalCount}>{value !== false ? numberFormatteer(value) : 0}</Typography>
                <Typography style={{marginTop: "8px"}} className={classes.trendingCard__title}>{title}</Typography>
                <Typography className={classes.trendingCard_info} >{data.dateRange}</Typography>
            </Box>
        </Box>
    )
}