import React from "react";
import {
  makeStyles,
  Box,
  Typography,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Button
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { StyledTableCell, StyledTableRow } from "components/table";
import { SkeletonTableRow } from "components/skeleton/skeleton.component";
import { TablePaginationActions } from "components/tablePagination";
import RefreshTable from "components/table/refreshTable";
import { getLang } from "app/feature/constants";
import StickyLeftTableCell from "components/table/stickyLeftTableCell";
import StickyRightTableCell from "components/table/stickyRightTableCell";
import moment from "moment";
import "moment/min/locales.min";

const useStyle = makeStyles((theme) => ({
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    borderCollapse: "separate",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
    },
    "& th,td": {
      fontSize: ".75rem",
      borderRight: "1px solid rgba(58, 77, 84, 0.1)",
      padding: theme.spacing(1, 2, 1, 2),
    },
    "& th": {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightBold,
    },
    "& th:last-child, td:last-child": {
      width: 37,
      borderRight: "0px",
    },
    "& th th": {
      borderBottom: "none !important",
    },
    "& td td": {
      borderBottom: "none !important",
    },
  },
  emptyContainer: {
    backgroundColor: theme.palette.background.paper,
    textAlign: "center"
  }
}));

export default function LuckyDrawParticipantTableComponent({
  rows,
  isFetching,
  page,
  search,
  rowsPerPage,
  totalRecords,
  handleChangePage,
  handleChangeRowsPerPage,
  handleView,
  handleReload,
  isLoadingLuckyDrawParticipantError,
  paginationOptions,
  lang,
  language
}) {
  const classes = useStyle();
  
  if(language==="ZH")
  {
    moment.locale('zh-cn')
  }else if(language==="TL")
  {
    moment.locale('tl-ph')
  }else
  {
    moment.locale(language)
  }

  return (
    <>
      {rows.length > 0 || isFetching ? (
        <TableContainer>
          <Table
            className={classes.table}
            size="small"
            aria-label="product table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <StickyLeftTableCell>
                  <StyledTableCell>{getLang(lang, "label.NUM")}</StyledTableCell>
                </StickyLeftTableCell>
                <StyledTableCell>{getLang(lang, "label.NAME")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.PHONE_NUMBER")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.EMAIL")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.SERIAL_NUMBER")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.PRODUCT")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.JOIN_DATE_TIME")}</StyledTableCell>
                <StickyRightTableCell>
                  <StyledTableCell></StyledTableCell>
                </StickyRightTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isFetching
                ? [...new Array(5)].map((v, index) => (
                  <SkeletonTableRow key={index} columnCount={8} />
                ))
                : rows.map((row, index) => {
                  return (
                    <StyledTableRow
                      key={index}
                      hover
                      role="checkbox"
                      tabIndex={-1}
                    >
                      <StickyLeftTableCell>
                        <StyledTableCell component="th" scope="row" style={{ borderBottom: "0px" }}>
                          {index + 1 + rowsPerPage * page}
                        </StyledTableCell>
                      </StickyLeftTableCell>
                      <StyledTableCell>{row.name}</StyledTableCell>
                      <StyledTableCell>{row.contactNumber}</StyledTableCell>
                      <StyledTableCell>{row.email}</StyledTableCell>
                      <StyledTableCell>{row.serialNumber}</StyledTableCell>
                      <StyledTableCell>{row.productName}</StyledTableCell>
                      <StyledTableCell>
                        {moment(row.createdAt).format('lll')}
                      </StyledTableCell>
                      <StickyRightTableCell>
                        <StyledTableCell>
                          <Box
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Button
                              variant="contained"
                              color="primary"
                              size="small"
                              onClick={() => handleView(row.id)}
                            >
                              {getLang(lang, "label.DETAILS")}
                            </Button>
                          </Box>
                        </StyledTableCell>
                      </StickyRightTableCell>
                    </StyledTableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : !search && !isLoadingLuckyDrawParticipantError && (
        <Box>
          <Table
            className={classes.table}
            size="small"
            aria-label="product table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <StyledTableCell>{getLang(lang, "label.NUM")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.NAME")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.PHONE_NUMBER")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.EMAIL")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.SERIAL_NUMBER")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.PRODUCT")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.JOIN_DATE_TIME")}</StyledTableCell>
                <StyledTableCell></StyledTableCell>
              </TableRow>
            </TableHead>
          </Table>
          <Box py={9} className={classes.emptyContainer}>
            <Typography variant="body1">
              {getLang(lang, "paragraph.NO_CONSUMER")}
            </Typography>
          </Box>
        </Box>


      )}

      {!isFetching && rows.length === 0 && search && (
        <Alert severity="info">{getLang(lang, "message.info.NO_LD_PARTICIPANT")}</Alert>
      )}

      {!isFetching && isLoadingLuckyDrawParticipantError && (
        <RefreshTable handleReload={handleReload} />
      )}

      {!isFetching && rows.length > 0 && (
        <TablePagination
          style={{
            backgroundColor: "#fff",
            boxShadow: "0px -1px 0px rgba(58, 77, 84, 0.15)",
          }}
          ActionsComponent={TablePaginationActions}
          rowsPerPageOptions={paginationOptions}
          component="div"
          rows={rows}
          count={totalRecords}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage={getLang(lang, 'label.ROWS_PER_PAGE')}
          labelDisplayedRows={({ from, to, count }) => `${from.toLocaleString("en-US")}-${to.toLocaleString("en-US")} ${getLang(lang, 'label.OF')} ${count.toLocaleString("en-US")}`}
        />
      )}
    </>
  );
}
