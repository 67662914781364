import React, { useEffect, useState, useRef } from "react";
import { LuckyDrawDetailSVG, LuckyDrawRegFormSVG } from "../../utils/static";
import LuckyDrawDetailForm from "../form/luckyDrawDetailForm";
import RegLuckyForm from "../form/regLuckyDrawForm";
import StepperComponent from "./stepper.component";
import ConfirmationDialogComponent from "components/dialog/confirmation";
import LuckyDrawPrizeDialog from "../dialog/luckyDrawPrizeDialog";
import { useDispatch, useSelector } from "react-redux";
import { FolderType, UploadType } from "lib/constants/aws_s3";
import { uploadJSONToS3, uploadFileToS3, uploadImageToS3 } from "app/feature";
import { addAlert } from "modules/notification";
import { hideBackdrop, showBackdrop } from "modules/backdrop";
import { unwrapResult } from "@reduxjs/toolkit";
import { toBase64 } from "modules/product-mgmt/utils/productPage.util";
import { v4 as uuidv4 } from "uuid";
import {
  createLuckyDraw,
  resetLuckyDrawDetail,
  resetRegisterForm,
  resetRegisterFormType,
} from "modules/lucky-draw/redux";
import { LUCKY_DRAW_EDIT } from "lib/constants/accessRights";
import { getLang } from "app/feature/constants";
import {setImgFormatAndSize} from "../../../../lib/FileHelper";

function getSteps({ lang }) {
  const LuckyDrawDetailSVGWhite = () => (
    <LuckyDrawDetailSVG color="rgba(58, 77, 84, 0.33)" />
  );

  const LuckyDrawRegFormSVGWhite = () => (
    <LuckyDrawRegFormSVG color="rgba(58, 77, 84, 0.33)" />
  );

  let steps = [
    {
      form: LuckyDrawDetailForm,
      labelImage: LuckyDrawDetailSVGWhite,
      activeImage: LuckyDrawDetailSVG,
      label: getLang(lang, "label.LUCKY_DRAW_DETAILS")
    },
    {
      form: RegLuckyForm,
      labelImage: LuckyDrawRegFormSVGWhite,
      activeImage: LuckyDrawRegFormSVG,
      label: getLang(lang, "label.PARTICIPANT_REG_FORM")
    }
  ];
  return steps;
}

function StepperContainer({ handleClose }) {
  const lang = useSelector(state => state.constant.languages);
  const steps = getSteps({ lang });
  const dispatch = useDispatch();
  const [confirmationDialogModal, setConfirmationDialogModal] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [isModalOpen, setModalOpen] = useState(false);
  const [luckyDrawImageFile, setLuckyDrawImageFile] = useState(null);
  const [termConditionFile, setTermConditionFile] = useState(null);
  const hasLuckyDrawEditAccessRight = useSelector(state => state.profile.accessRights.includes(LUCKY_DRAW_EDIT))
  const id = useSelector((state) => state.session.idTokenPayload.sub);
  const luckyDrawDetailForm = useSelector(
    (state) => state.luckyDraw.LuckyDrawDetail
  );
  const formRef = useRef();

  useEffect(() => {
    return () => {
      dispatch(resetLuckyDrawDetail());
      dispatch(resetRegisterForm());
      dispatch(resetRegisterFormType());
    }
  }, [dispatch])

  const createLuckyDrawProcess = async (url) => {
    const fileName = `${uuidv4()}.webp`;
    let base64Image = await toBase64(luckyDrawImageFile);
    base64Image = await setImgFormatAndSize(base64Image, 2160, 2160, "image/webp")
    const uploadLuckyDrawImageData = {
      uploadType: FolderType.luckyDrawImage,
      base64Image: base64Image,
      id: id,
      fileName: fileName
    };

    formRef.current.handleSubmit();

    const participantregformdata = formRef.current.values.form;

    const uniqueVariantNames = new Set();

    for (const property of participantregformdata) {
      if (property.variantName === undefined || property.variantName.trim() === "") {
        await dispatch(
          addAlert({
            severity: "error",
            message: getLang(lang, "message.warning.FILL_IN_REQUIRED_FIELD")
          })
        );
        dispatch(hideBackdrop());
        return;
      }

      if (uniqueVariantNames.has(property.variantName)) {
        await dispatch(
          addAlert({
            severity: "error",
            message: getLang(lang, "message.warning.DUPLICATE_VARIANT_NAME")
          })
        );
        dispatch(hideBackdrop());
        return;
      }

      uniqueVariantNames.add(property.variantName);

      if (property.type === 'multipleChoice' || property.type==="dropdownlist" || property.type==="multipleChoiceMultiSelect") {

        for (const choices of property.choice) {
          if (choices.trim() === "" || choices === undefined) {
            await dispatch(
              addAlert({
                severity: "error",
                message: getLang(lang, "message.warning.CHOICES_CANNOT_BE_EMPTY")
              })
            );
            dispatch(hideBackdrop());
            return;
          }
        }

        const uniqueChoices = new Set(property.choice);
        if (uniqueChoices.size !== property.choice.length) {
          await dispatch(
            addAlert({
              severity: "error",
              message: getLang(lang, "message.warning.CHOICES_MUST_BE_UNIQUE")
            })
          );
          dispatch(hideBackdrop());
          return;
        }

        if (property.choice.length < 2) {
          await dispatch(
            addAlert({
              severity: "error",
              message: getLang(lang, "message.warning.MUST_HAVE_AT_LEAST_TWO_CHOICES")
            })
          );
          dispatch(hideBackdrop());
          return;
        }
      }

    }

    const formdata = formRef.current.values.form;


    dispatch(uploadImageToS3(uploadLuckyDrawImageData))
      .then(unwrapResult)
      .then(async (imageUrl) => {
        const productId = [];
        for (const product of luckyDrawDetailForm.assignProduct) {
          productId.push(product.id);
        }
        const info = {
          LuckyDrawDetail: {
            ...luckyDrawDetailForm,
            luckyDrawTermsConditionUrl: url,
            luckyDrawImageUrl: imageUrl
          },
          productId: productId,
          luckyDrawRegister: formdata
        };
        dispatch(createLuckyDraw(info))
          .then(unwrapResult)
          .then((response) => {
            dispatch(hideBackdrop());
            if (hasLuckyDrawEditAccessRight) {
              setConfirmationDialogModal(true);
            } else {
              handleClose();
            }
          })
          .catch((error) => {
            dispatch(hideBackdrop());
          });
      })
      .catch((error) => {
        dispatch(hideBackdrop());
        dispatch(
          addAlert({
            severity: "error",
            message: error.message || getLang(lang, "messgae.error.FAILED_UPLOAD_LD_IMAGE")
          })
        );
      });
  };
  const handleNext = async () => {
    // let participantRegistrationForm = [{
    //   fieldName: "name",
    //   labelType: getLang(lang, 'label.TEXT_FIELD'),
    //   type: "textField",
    //   mandatory: true,
    //   visible: true,
    //   enableDelete: false,
    //   editing: false,
    //   fieldType: "standard",
    //   variantName: getLang(lang, 'label.NAME'),
    //   choice: []
    // },
    // {
    //   fieldName: "address",
    //   labelType: getLang(lang, 'label.TEXT_FIELD'),
    //   type: "textField",
    //   mandatory: true,
    //   visible: true,
    //   enableDelete: false,
    //   editing: false,
    //   fieldType: "standard",
    //   variantName: getLang(lang, 'label.ADDRESS'),
    //   choice: []
    // },
    // {
    //   fieldName: "email",
    //   labelType: getLang(lang, 'label.TEXT_FIELD'),
    //   type: "textField",
    //   mandatory: true,
    //   visible: true,
    //   enableDelete: false,
    //   editing: false,
    //   fieldType: "standard",
    //   variantName: getLang(lang, 'label.EMAIL'),
    //   choice: []
    // },
    // {
    //   fieldName: "gender",
    //   labelType: getLang(lang, 'label.MULTIPLE_CHOICE'),
    //   type: "multipleChoice",
    //   mandatory: true,
    //   visible: true,
    //   enableDelete: false,
    //   editing: false,
    //   fieldType: "standard",
    //   variantName: getLang(lang, 'label.GENDER'),
    //   choice: [getLang(lang, 'label.MALE'), getLang(lang, 'label.FEMALE')]
    // },
    // {
    //   fieldName: "contactNumber",
    //   labelType: getLang(lang, 'label.CONTACT_NUMBER'),
    //   type: "contactNumber",
    //   mandatory: true,
    //   visible: true,
    //   enableDelete: false,
    //   editing: false,
    //   fieldType: "standard",
    //   variantName: getLang(lang, 'label.CONTACT_NUMBER'),
    //   choice: []
    // },
    // {
    //   fieldName: "ic",
    //   labelType: getLang(lang, 'label.TEXT_FIELD'),
    //   type: "textField",
    //   mandatory: true,
    //   visible: true,
    //   enableDelete: false,
    //   editing: false,
    //   fieldType: "standard",
    //   variantName: getLang(lang, 'label.IC_NUMBER'),
    //   choice: []
    // },
    // ];
    if (activeStep === 1) {
      dispatch(showBackdrop());

      if (luckyDrawDetailForm.luckyDrawTermsConditionText) {
        const termAndConditionFileName = "JsonFile";

        const uploadJSONdata = {
          uploadType: UploadType.luckyDrawTermAndCondition,
          data: luckyDrawDetailForm.luckyDrawTermsConditionDetail,
          id: id,
          fileName: `${Date.now()}${termAndConditionFileName}`
        };

        dispatch(uploadJSONToS3(uploadJSONdata))
          .then(unwrapResult)
          .then(async (jsonUrl) => {
            await createLuckyDrawProcess(jsonUrl);
          })
          .catch((error) => {
            dispatch(hideBackdrop());
            dispatch(
              addAlert({
                severity: "error",
                message: error.message || getLang(lang, "message.error.FAILED_UPLOAD_TNC_FILE")
              })
            );
          });
      } else {
        const termAndConditionFileName = termConditionFile.name;
        const uploadPdfData = {
          uploadType: UploadType.luckyDrawTermAndCondition,
          file: termConditionFile,
          id: id,
          fileName: `${termAndConditionFileName}_${Date.now()}${termAndConditionFileName}`
        };

        dispatch(uploadFileToS3(uploadPdfData))
          .then(unwrapResult)
          .then(async (termUrl) => {
            await createLuckyDrawProcess(termUrl);
          })
          .catch((error) => {
            dispatch(hideBackdrop());
            dispatch(
              addAlert({
                severity: "error",
                message: error.message || getLang(lang, "message.error.FAILED_UPLOAD_TNC_FILE")
              })
            );
          });
      }
    } else setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const closeConfirmationDialog = () => {
    setConfirmationDialogModal(false);
    handleClose();
  };

  const handleProceed = () => {
    setConfirmationDialogModal(false);
    handleAddPrize();
  };

  const handleAddPrize = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    handleClose();
  };

  const handleDiscard = () => {
    handleClose();
  };

  return (
    <>
      <StepperComponent
        steps={steps}
        activeStep={activeStep}
        handleBack={handleBack}
        handleNext={handleNext}
        handleDiscard={handleDiscard}
        termConditionFile={termConditionFile}
        setTermConditionFile={setTermConditionFile}
        luckyDrawImageFile={luckyDrawImageFile}
        setLuckyDrawImageFile={setLuckyDrawImageFile}
        formRef={formRef}
        lang={lang}
      />
      <LuckyDrawPrizeDialog
        isOpen={isModalOpen}
        handleClose={closeModal}
        type="create"
      />
      <ConfirmationDialogComponent
        isOpen={confirmationDialogModal}
        handleClose={closeConfirmationDialog}
        handleProceed={handleProceed}
        type={"success"}
        description={getLang(lang, "paragraph.ADD_PRIZE_QUESTION_LD")}
        title={getLang(lang, "label.LUCKY_DRAW_ADDED")}
        remarks={getLang(lang, "paragraph.ADD_PRIZE_REMARK")}
        proceedLabel={getLang(lang, "label.ADD_PRIZE")}
        rejectLabel={getLang(lang, "label.DO_IT_LATER")}
      />
    </>
  );
}

export default StepperContainer;
