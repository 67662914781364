import React, { useState, useRef } from "react"
import TagSearchComponent from "./tagSearch.component"
import { useSelector } from "react-redux"
import { selectLoading } from "modules/notification"
import { fetchTagsDropdown } from "modules/serial-number/redux"
import TagSearchPanelComponent from "./tagSearchPanel.component"
import { useEffect } from "react"
import { cloneDeep } from "lodash"

function TagSearchContainer({
    handleChange,
    tags,
    disabled,
    value,
    styles,
    textStyles,
    handleChipDelete,
    placeholder,
    isEmpty
}) {
    const isFetching = useSelector(state => selectLoading(state, fetchTagsDropdown.typePrefix))
    const lang = useSelector(state => state.constant.languages)
    const [open, setOpen] = useState(false)
    const [isOpenList] = useState(false)
    const anchorRef = useRef(null)
    const prevOpen = useRef(open)
    const prevOpenList = useRef(isOpenList)
    const [selectAll, setSelectAll] = useState(false)
    const drawerOpen = useSelector((state) => state.app.drawerOpen);
    const [extraChip, setExtraChip] = useState({
        count: 0,
        offset: 0,
        allOverflow: false,
        overflowChild: []
    });

    const adjustField = (ref) => {

        let children = ref.current?.children[0]?.children;
        let p = ref.current?.children[0];
        if (!children) return;

        if (children.length > 0) {
            // Need to exclude placeholder
            if (children[0].role == null) return;

            children = [...children];

            // max width is 90%. Use 0.9 to calc field width
            let panelWidth = ref.current?.getBoundingClientRect().width * 0.9;
            let childWidth = 0;

            // Check for no overflow child count
            let inRangeCount = children.filter((child, index) => {

                let panelContentWidth = p.getBoundingClientRect().width
                // get exact width + padding with decimal
                childWidth += (parseInt(window.getComputedStyle(child).marginRight) + child.getBoundingClientRect().width);

                // Check if overflow
                if (panelWidth - panelContentWidth < 1) {
                    // need to add 0.5 as the ellipsis padding (0.5 = ellipsis padding)
                    // if the overflow happen before last child, need inlude another 10 for the ellipsis (10 = ellipsis width)
                    return (index !== (children.length - 1)) ? (childWidth + 10.5) < panelContentWidth : childWidth + 0.5 < panelContentWidth;
                }

                return (panelWidth > panelContentWidth);
            }).length;


            if (inRangeCount > 0) {

                let lastRangeChild = children[inRangeCount - 1];

                let outRangeChild = value.slice(inRangeCount);

                // Check for overflow child count
                let extraCount = value.length - inRangeCount;
                setExtraChip({
                    count: extraCount,
                    offset: lastRangeChild.offsetLeft + lastRangeChild.offsetWidth,
                    allOverflow: false,
                    overflowChild: outRangeChild
                });
            } else {
                setExtraChip({
                    count: value.length,
                    offset: 0,
                    allOverflow: true,
                    overflowChild: value
                });
            }
        } else {
            setExtraChip({
                count: 0,
                offset: 0,
                allOverflow: false,
                overflowChild: []
            });
        }
    }

    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus()
        }

        prevOpen.current = open
    }, [open])

    useEffect(() => {
        if (!value.length || value.length < tags.length) {
            setSelectAll(false)
        }
    }, [value, tags])

    useEffect(() => {
        if (prevOpenList.current === true && isOpenList === false) {
            anchorRef.current.focus()
        }

        prevOpenList.current = isOpenList
    }, [isOpenList])

    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }

    const handleSelectAll = async () => {
        if (!selectAll) {
            let selectedTags = tags.map(tag => {
                return tag.format
            })
            handleChange(selectedTags)
        } else {
            handleChange([])
        }
        setSelectAll(!selectAll)
    }

    const handleSelectTag = (tag) => {
        let temp = cloneDeep(value)

        if (temp.findIndex(t => t === tag.format) >= 0) {
            temp = temp.filter(t => t !== tag.format)
        } else {
            temp.push(tag.format)
        }
        handleChange(temp)
        setSelectAll(temp.length === tags.length)
    };

    return (
        <>
            <TagSearchComponent
                anchorRef={anchorRef}
                disabled={disabled}
                value={value}
                handleClick={handleOpen}
                styles={styles}
                textStyles={textStyles}
                lang={lang}
                handleChange={handleChange}
                handleChipDelete={handleChipDelete}
                drawerOpen={drawerOpen}
                extraChip={extraChip}
                adjustField={adjustField}
                placeholder={placeholder}
                isEmpty={isEmpty}
            />
            <TagSearchPanelComponent
                selectAll={selectAll}
                value={value}
                anchorRef={anchorRef}
                open={open}
                isOpenList={isOpenList}
                handleClose={handleClose}
                isFetching={isFetching}
                tagDropdown={tags}
                handleSelectAll={handleSelectAll}
                handleSelectTag={handleSelectTag}
                lang={lang}
                isEmpty={isEmpty}
            />
        </>
    )
}

export default TagSearchContainer