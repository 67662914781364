import axios from "axios";

const connection = process.env.REACT_APP_AUTH_CONNECTION
const connectionId = process.env.REACT_APP_AUTH_CONNECTION_ID

export default function validateResetPasswordToken({
    email,
    token
}) {
    return axios.post(`/authentication/api/basic/validate-reset-password-token`,
        {
            connection,
            connection_id: connectionId,
            email,
            reset_password_token: token
        })
}