import React, { useState } from "react";
import {
  makeStyles,
  Paper,
  Typography,
  IconButton,
  Divider,
  Button,
  Dialog,
  Box,
  Tabs,
  CircularProgress,
  MenuItem,
  Menu,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CustomTab from "components/tab";
import { Remove as RemoveIcon, Add as AddIcon } from "@material-ui/icons";
import DeleteSvgIcon from "assets/svg/delete.svg";
import { getLang } from "app/feature/constants";
import mapValues from "lodash/mapValues";
import { Formik } from "formik";
import * as Yup from "yup";
import languages from "lib/constants/languages";
import GeneralDialog from "components/dialog/generalDialog";
import SingleSnAdditionalInfoAccordion from "../../accordion/singleSnAdditionalInfoAccordion";

const useStyle = makeStyles((theme) => ({
  root: {
    borderRadius: 8,
    boxShadow:
      "0px 20px 24px -4px rgba(16, 24, 40, 0.1), 0px 8px 8px -4px rgba(16, 24, 40, 0.04)",
    [theme.breakpoints.down("xs")]: {
      minWidth: "95vw",
    },
  },
  header: {
    paddingLeft: theme.spacing(3),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  inputRow: {
    backgroundColor: "#EBEDEE",
    borderRadius: 4,
    width: "100%",
    height: 38,
  },
  titleContainer: {
    padding: 16,
    border: "1px solid #3a4d544f",
    borderRadius: 4,
  },
  selectRoot: {
    backgroundColor: "#EBEDEE",
    borderRadius: 4,
    width: "100%",
    height: 38,
    padding: "0px 24px 0px 0px",
    display: "flex",
    alignItems: "center",
    "&:focus": {
      backgroundColor: "#EBEDEE",
      borderRadius: 4,
    },
  },
}));

export default function UpdateSnAdtInfoDialogComponent({
  isOpen,
  handleClose,
  lang,
  isSubmitting,
  handleSubmit,
  addAlert,
  languageTabIndex,
  setLanguageTabIndex,
  validate,
  additionalInfos,
  handleAddLang,
  handleRemoveLang,
}) {
  const classes = useStyle();
  const [anchorEl, setAnchorEl] = useState(null);

  function a11yProps(index, label = "label") {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tab-${index}`,
      label: <Typography variant="button" children={label} />,
    };
  }

  return (
    <Dialog
      open={isOpen}
      disableBackdropClick
      disableEscapeKeyDown
      aria-labelledby='="general-dialog'
      maxWidth="md"
      classes={{
        paper: classes.root,
      }}
      fullWidth
    >
      <Paper elevation={0}>
        <div className={classes.header}>
          <Typography variant="subtitle2" style={{ fontWeight: "bold" }}>
            {getLang(lang, "label.UPDATE_ADDITIONAL_INFO")}
          </Typography>
          <IconButton color="primary" onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </div>
        <Divider />
        <Box>
          <Formik
            initialValues={{
              additionalInfo: additionalInfos,
              selectedDeleteIndex: null,
              showSaveConfirm: false,
              usedLang: additionalInfos.length
                ? additionalInfos.reduce(
                    (langs, t) => {
                      if (t.format === 1) {
                        Object.keys(t.value).forEach((key) => {
                          let keyExist = langs.find(
                            (lang) => lang.code === key
                          );
                          if (!keyExist) {
                            langs.push(
                              languages.find((lang) => lang.code === key)
                            );
                          }
                        });
                      }

                      return langs;
                    },
                    [languages.find((lang) => lang.code === "EN")]
                  )
                : [languages.find((lang) => lang.code === "EN")],
              selectedTitleIds: additionalInfos.length
                ? additionalInfos.map((a) => a.id)
                : [],
            }}
            onSubmit={handleSubmit}
            validationSchema={Yup.object({
              additionalInfo: Yup.array().of(
                Yup.object({
                  value: Yup.lazy((obj) => {
                    switch (typeof obj) {
                      case "object":
                        return Yup.object(
                          mapValues(obj, (value, key) => {
                            if (key === "EN") return Yup.string().required();
                            else return Yup.string();
                          })
                        ).required(
                          getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")
                        );
                      case "string":
                        return Yup.string().required(
                          getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")
                        ); // schema for string
                      default:
                        return Yup.mixed().required(
                          getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")
                        ); // here you can decide what is the default
                    }
                  }),
                })
              ),
            })}
          >
            {(formik) => {
              return (
                <form onSubmit={formik.handleSubmit} id="additional_form">
                  <>
                    <Box maxHeight="70dvh" overflow="auto" p={2}>
                      {!!formik.values.additionalInfo.length && (
                        <>
                          <Box
                            display="flex"
                            flexDirection="row"
                            width="100%"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Tabs
                              value={languageTabIndex}
                              onChange={(event, index) => {
                                setLanguageTabIndex(index);
                              }}
                              aria-label="scrollable auto tabs example"
                              textColor="primary"
                              variant="scrollable"
                              indicatorColor="primary"
                              classes={{
                                indicator: classes.indicator,
                                scrollButtons: classes.scroll,
                              }}
                              style={{ flex: 1 }}
                            >
                              {formik.values.usedLang.map((item, index) => {
                                return (
                                  <CustomTab
                                    key={index}
                                    {...a11yProps(index, item.label)}
                                  />
                                );
                              })}
                            </Tabs>
                            <Box display="flex" alignItems="center" ml={2}>
                              <Box>
                                <IconButton
                                  size="small"
                                  onClick={() => {
                                    handleRemoveLang(formik);
                                  }}
                                  disabled={
                                    formik.values.usedLang.length <= 0 ||
                                    formik.values.usedLang[languageTabIndex]
                                      ?.code === "EN" ||
                                    isSubmitting
                                  }
                                >
                                  <RemoveIcon />
                                </IconButton>
                              </Box>
                              <Box ml={0.5}>
                                <IconButton
                                  size="small"
                                  onClick={(event) =>
                                    setAnchorEl(event.currentTarget)
                                  }
                                  disabled={
                                    languages.length -
                                      formik.values.usedLang.length ===
                                      0 || isSubmitting
                                  }
                                >
                                  <AddIcon />
                                </IconButton>
                                <Menu
                                  anchorEl={anchorEl}
                                  keepMounted
                                  open={Boolean(anchorEl)}
                                  onClose={() => setAnchorEl(null)}
                                >
                                  {languages
                                    .filter(
                                      (language) =>
                                        !formik.values.usedLang.some(
                                          (used) => used.code === language.code
                                        )
                                    )
                                    .map((language, index) => (
                                      <MenuItem
                                        onClick={() => {
                                          handleAddLang(language, formik);
                                          setAnchorEl(null);
                                        }}
                                        key={index}
                                      >
                                        {language.label}
                                      </MenuItem>
                                    ))}
                                </Menu>
                              </Box>
                            </Box>
                          </Box>
                          <Divider />
                        </>
                      )}
                      <Box mt={1}>
                        {formik.values.additionalInfo.length ? (
                          formik.values.additionalInfo.map((info, i) => (
                            <SingleSnAdditionalInfoAccordion
                              key={i}
                              info={info}
                              adtInfoIdx={i}
                              isUpdating={isSubmitting}
                              formik={formik}
                              currentLang={
                                formik.values.usedLang[languageTabIndex]
                                  ?.code ?? "EN"
                              }
                            />
                          ))
                        ) : (
                          <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                            mb={4}
                            mt={2}
                            mx={2}
                          >
                            <Box mb={1}>
                              <Typography variant="body1" align="center">
                                {getLang(
                                  lang,
                                  "paragraph.NO_ADDITIONAL_INFO_YET"
                                )}
                              </Typography>
                            </Box>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                let temp = [...formik.values.additionalInfo];
                                temp.push({
                                  id: null,
                                  title: null,
                                  format: 1,
                                  value: "",
                                });

                                formik.setFieldValue("additionalInfo", temp);
                              }}
                              style={{ color: "white" }}
                            >
                              <AddIcon
                                style={{
                                  fontSize: 20,
                                  marginRight: 8,
                                }}
                              />
                              {getLang(lang, "label.ADDITIONAL_INFORMATION")}
                            </Button>
                          </Box>
                        )}
                        {!!formik.values.additionalInfo.length && (
                          <Box display="flex" alignItems="center" mt={2}>
                            <Divider style={{ flex: 1, marginRight: 16 }} />
                            <Button
                              variant="outlined"
                              className={classes.button}
                              onClick={() => {
                                let temp = [...formik.values.additionalInfo];
                                temp.push({
                                  id: null,
                                  title: null,
                                  format: 1,
                                  value: "",
                                });

                                formik.setFieldValue("additionalInfo", temp);
                              }}
                            >
                              <AddIcon
                                style={{ fontSize: 20, marginRight: 8 }}
                              />
                              {getLang(lang, "label.ADDITIONAL_INFORMATION")}
                            </Button>
                            <Divider style={{ flex: 1, marginLeft: 16 }} />
                          </Box>
                        )}
                      </Box>
                    </Box>
                    {(!!formik.values.additionalInfo.length ||
                      !!additionalInfos.length) && (
                      <Box
                        display="flex"
                        justifyContent="flex-end"
                        mt={2}
                        p={2}
                      >
                        <Button
                          variant="outlined"
                          disableElevation
                          onClick={() => {
                            handleClose();
                          }}
                          style={{ marginRight: "8px" }}
                          disabled={isSubmitting}
                        >
                          {getLang(lang, "label.CANCEL")}
                        </Button>
                        <Button
                          variant="contained"
                          color="secondary"
                          disableElevation
                          type="button"
                          onClick={() => {
                            let valid = validate(formik);
                            if (valid) {
                              formik.setFieldValue("showSaveConfirm", true);
                            }
                          }}
                          disabled={isSubmitting}
                          startIcon={
                            isSubmitting ? (
                              <CircularProgress
                                style={{ width: 10, height: 10 }}
                              />
                            ) : null
                          }
                        >
                          {getLang(lang, "label.SAVE")}
                        </Button>
                      </Box>
                    )}
                  </>

                  <GeneralDialog
                    isOpen={formik.values.selectedDeleteIndex != null}
                    handleClose={() => {
                      formik.setFieldValue("selectedDeleteIndex", null);
                    }}
                    handleProceed={() => {
                      let temp = [...formik.values.additionalInfo];

                      let tempInfo = temp[formik.values.selectedDeleteIndex];
                      temp.splice(formik.values.selectedDeleteIndex, 1);
                      if (!temp.length) {
                        temp.push({
                          id: null,
                          title: null,
                          format: 1,
                          value: "",
                        });
                      }
                      formik.setFieldValue("additionalInfo", temp);
                      formik.setFieldValue("selectedDeleteIndex", null);

                      if (tempInfo) {
                        let ids = [...formik.values.selectedTitleIds];
                        if (ids.includes(tempInfo.id)) {
                          ids = ids.filter((i) => i !== tempInfo.id);
                        }
                        formik.setFieldValue("selectedTitleIds", ids);
                      }
                    }}
                    title={`${getLang(lang, "label.DELETE")} ${
                      formik.values.additionalInfo[
                        formik.values.selectedDeleteIndex ?? 0
                      ]?.title?.["EN"] ?? ""
                    }`}
                    description={getLang(
                      lang,
                      "paragraph.ARE_YOU_SURE_WANT_DELETE"
                    )}
                    type="danger"
                    proceedLabel={getLang(lang, "label.DELETE")}
                    icon={<img src={DeleteSvgIcon} alt="" />}
                  />
                  <GeneralDialog
                    isOpen={!!formik.values.showSaveConfirm}
                    handleClose={() => {
                      formik.setFieldValue("showSaveConfirm", false);
                    }}
                    handleProceed={() => {
                      formik.setFieldValue("showSaveConfirm", false);
                      handleSubmit(formik.values);
                    }}
                    title={getLang(lang, "label.SAVE_ADDITIONAL_INFO")}
                    description={getLang(
                      lang,
                      "paragraph.SAVE_ADDITIONAL_INFO_QUESTION"
                    )}
                    type="success"
                    proceedLabel={getLang(lang, "label.SAVE")}
                    icon={<img src={DeleteSvgIcon} alt="" />}
                  />
                </form>
              );
            }}
          </Formik>
        </Box>
      </Paper>
    </Dialog>
  );
}
