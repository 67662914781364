import React from "react";
import BranchTable from "../../table/branchTable";
import { GeneralSearch } from "components/search";

export default function BranchPanelComponent({
  hasBranchAddAccessRight,
  hasBranchEditAccessRight,
  hasBranchActivateOrDeactivateAccessRight,
  handleFetchBranchList,
  search,
  handleSearch,
  rowsPerPage,
  page,
  totalRecords,
  handlePageChange,
  handleRowPerPageChange,
  openCreateModal,
  handleEditClick,
  handleStatusChange,
  handleReload,
  paginationOptions,
  lang
}) {
  return (
    <>
      <GeneralSearch
        handleFilter={(value) => {
          handleSearch(value);
        }}
      />

      <BranchTable
        hasBranchAddAccessRight={hasBranchAddAccessRight}
        hasBranchEditAccessRight={hasBranchEditAccessRight}
        hasBranchActivateOrDeactivateAccessRight={
          hasBranchActivateOrDeactivateAccessRight
        }
        openCreateModal={openCreateModal}
        handleEditClick={handleEditClick}
        rowsPerPage={rowsPerPage}
        page={page}
        search={search}
        totalRecords={totalRecords}
        handlePageChange={handlePageChange}
        handleRowPerPageChange={handleRowPerPageChange}
        handleStatusChange={handleStatusChange}
        handleReload={handleReload}
        paginationOptions={paginationOptions}
        lang={lang}
      />
    </>
  );
}
