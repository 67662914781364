import {
  signup,
  resendEmailVerification,
  verifyEmployee,
  signupEmployee,
  resetPassword,
  isValidReseller,
  isValidPartner
} from "../action";
import { addAlert, setLoading } from "modules/notification";
import { setEmail, setTimezone } from "../slice/signup.slice";
import { setSession } from "modules/admin";
import { getApiLang } from "app/feature/constants";

const signupMiddleware = ({ getState, dispatch }) => next => action => {
  next(action);

  const { payload, type } = action;
  const lang = getState().constant.languages;
  switch (type) {
    case signup.fulfilled.type:
      localStorage.setItem('session_id', payload.session_id)
      localStorage.setItem('session_expiration', payload.session_expiration)
      dispatch(setSession(payload))
      break;
    case signup.rejected.type:
      next(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
      break;
    case resendEmailVerification.fulfilled.type:
      next(addAlert({ severity: "success", message: getApiLang(lang, "success.SUCCESS_RESEND_EMAIL_VERIFICATION") }));
      break;
    case resendEmailVerification.rejected.type:
      next(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
      break;

    default:
      break;
  }
};

const verifyEmployeeMiddleware = ({ getState }) => next => async action => {
  next(action)

  const { type, payload } = action;

  switch (type) {
    case verifyEmployee.pending.type:
      next(setLoading({ id: verifyEmployee.typePrefix, state: true }));
      break;

    case verifyEmployee.fulfilled.type:
      next(setEmail(payload.data.email));
      next(setTimezone(payload.data.timezone));
      next(setLoading({ id: verifyEmployee.typePrefix, state: false }));
      break;

    case verifyEmployee.rejected.type:
      const lang = getState().constant.languages;
      next(setLoading({ id: verifyEmployee.typePrefix, state: false }));
      next(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
      break;

    default:
      break;
  }
}

const signupEmployeeMiddleware = ({ dispatch, getState }) => next => async action => {
  next(action)

  const { type, payload } = action;

  switch (type) {
    case signupEmployee.pending.type:
      next(setLoading({ id: signupEmployee.typePrefix, state: true }));
      break;

    case signupEmployee.fulfilled.type:
      next(setLoading({ id: signupEmployee.typePrefix, state: false }));
      break;

    case signupEmployee.rejected.type:
      const lang = getState().constant.languages;
      next(setLoading({ id: signupEmployee.typePrefix, state: false }));
      next(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
      break;

    default:
      break;
  }
}

const resetPasswordMiddleware = ({ getState }) => next => async action => {
  next(action)

  const { type, payload } = action;

  switch (type) {
    case resetPassword.pending.type:
      next(setLoading({ id: resetPassword.typePrefix, state: true }));
      break;

    case resetPassword.fulfilled.type:
      next(setLoading({ id: resetPassword.typePrefix, state: false }));
      break;

    case resetPassword.rejected.type:
      const lang = getState().constant.languages;
      next(setLoading({ id: resetPassword.typePrefix, state: false }));
      next(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
      break;

    default:
      break;
  }
}

const isValidResellerMiddleware = ({ getState }) => next => async action => {
  next(action)
  const { type, payload } = action;
  switch (type) {
    case isValidReseller.pending.type:
      next(setLoading({ id: isValidReseller.typePrefix, state: true }));
      break;

    case isValidReseller.fulfilled.type:
      next(setLoading({ id: isValidReseller.typePrefix, state: false }));
      break;

    case isValidReseller.rejected.type:
      const lang = getState().constant.languages;
      next(setLoading({ id: isValidReseller.typePrefix, state: false }));
      next(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
      break;
    default:
      break;
  }
}

const isValidPartnerMiddleware = ({ getState }) => next => async action => {
  next(action)
  const { type, payload } = action;
  switch (type) {
    case isValidPartner.pending.type:
      next(setLoading({ id: isValidPartner.typePrefix, state: true }));
      break;

    case isValidPartner.fulfilled.type:
      next(setLoading({ id: isValidPartner.typePrefix, state: false }));
      break;

    case isValidPartner.rejected.type:
      const lang = getState().constant.languages;
      next(setLoading({ id: isValidPartner.typePrefix, state: false }));
      next(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
      break;
    default:
      break;
  }
}

export default [
  signupMiddleware,
  verifyEmployeeMiddleware,
  signupEmployeeMiddleware,
  resetPasswordMiddleware,
  isValidResellerMiddleware,
  isValidPartnerMiddleware,
];
