import { useDispatch, useSelector } from 'react-redux';
import CampaignPrizeCard from './campaignPrizeCard.component';
import React, { useEffect } from 'react';
import { selectLoading } from 'modules/notification';
import { getCampaignPrizeSummaryData } from 'modules/reporting/redux';

export default function CampaignPrizeCardContainer({
    currentFilter,
    translate,
    url
}){
    const dispatch = useDispatch();

    // (1) campaign prize data -- start
    const isFetchingCampaignPrizeSummaryData = useSelector(state => selectLoading(state, getCampaignPrizeSummaryData.typePrefix));
    const campaignPrizeSummaryData = useSelector(state => state.reporting.campaign.campaignPrizeSummaryData);
    // (1) campaign prize data -- end

    useEffect(() => {
        dispatch(getCampaignPrizeSummaryData({
            countryCode: currentFilter.countryCode,
            city_uuid: currentFilter.cityUuid,
            branch_uuid: currentFilter.branchUuid,
            period: currentFilter.period,
            start_date: currentFilter.dateRange[0],
            end_date: currentFilter.dateRange[1],
            campaignUuid: currentFilter.campaignUuid,
            campaign_type: currentFilter.campaignType,
            prize_uuid: currentFilter.prizeUuid,
            url: url
        }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, currentFilter])
    return (
        <CampaignPrizeCard 
            status={{
                isFetchingCampaignPrizeSummaryData
            }}

            data={{
                campaignPrizeSummaryData
            }}

            currentFilter={currentFilter}

            translate={translate}
        />
    )
}