import React from "react";
import { Box, Link, Typography } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { getLang } from "app/feature/constants";

function ErrorPanel({ handleClick }) {
  const lang = useSelector(state => state.constant.languages);
  return (
    <Box>
      <Alert severity="error">
        <AlertTitle>{getLang(lang, 'label.ERROR')}</AlertTitle>
        <Typography gutterBottom variant="body2">
          {getLang(lang, 'paragraph.SERVER_NO_RESPOND')}
        </Typography>
        <Link component="button" variant="body2" onClick={handleClick}>
          {getLang(lang, 'label.TRY_AGAIN')}
        </Link>
      </Alert>
    </Box>
  );
}

ErrorPanel.propTypes = {
  handleClick: PropTypes.func.isRequired
};

export { ErrorPanel };
