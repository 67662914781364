import React from "react"
import { useSelector } from "react-redux";
import CreateCategoryDialogComponent from './createCategoryDialog.component';

export default function CreateCategoryDialogContainer({
  isOpen,
  handleClose,
  handleSubmit,
  isSubmittingAddCategory
}) {
  const lang = useSelector(state => state.constant.languages)
  return (
    <CreateCategoryDialogComponent
      isOpen={isOpen}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
      isSubmittingAddCategory={isSubmittingAddCategory}
      lang={lang}
    />
  )
}