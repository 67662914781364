import React, { useState } from "react";
import {
  makeStyles,
  Paper,
  Typography,
  IconButton,
  Divider,
  Button,
  DialogActions,
  Dialog,
  Box,
  Tabs,
  Menu,
  MenuItem,
  Select,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CustomTab from "components/tab";
import Input from "components/input/inputTextField";
import {
  Remove as RemoveIcon,
  Add as AddIcon,
  RemoveCircleOutlineOutlined as RemoveCircleIcon,
  AddCircleOutlineOutlined as AddCircleIcon,
} from "@material-ui/icons";
import { getLang } from "app/feature/constants";
import mapValues from "lodash/mapValues";
import { Formik } from "formik";
import * as Yup from "yup";
import languages from "lib/constants/languages";

const useStyle = makeStyles((theme) => ({
  root: {
    borderRadius: 8,
    boxShadow:
      "0px 20px 24px -4px rgba(16, 24, 40, 0.1), 0px 8px 8px -4px rgba(16, 24, 40, 0.04)",
    [theme.breakpoints.down("xs")]: {
      minWidth: "95vw",
    },
  },
  header: {
    paddingLeft: theme.spacing(3),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  inputRow: {
    backgroundColor: "#EBEDEE",
    borderRadius: 4,
    width: "100%",
    height: 38,
  },
  titleContainer: {
    padding: 16,
    border: "1px solid #3a4d544f",
    borderRadius: 4,
  },
  selectRoot: {
    backgroundColor: "#EBEDEE",
    borderRadius: 4,
    width: "100%",
    height: 38,
    padding: "0px 24px 0px 0px",
    display: "flex",
    alignItems: "center",
    "&:focus": {
      backgroundColor: "#EBEDEE",
      borderRadius: 4,
    },
  },
}));

export default function CreateAdtInfoTitleDialogComponent({
  isOpen,
  handleClose,
  lang,
  isSubmitting,
  handleCreate,
  addAlert,
}) {
  const classes = useStyle();

  function a11yProps(index, label = "label") {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tab-${index}`,
      label: <Typography variant="button" children={label} />,
    };
  }
  const [anchorEl, setAnchorEl] = useState(null);

  const options = [
    { label: getLang(lang, "label.TEXT"), value: 1 },
    { label: getLang(lang, "label.DATE"), value: 2 },
    { label: getLang(lang, "label.NUMBER"), value: 3 },
  ];

  return (
    <Dialog
      open={isOpen}
      disableBackdropClick
      disableEscapeKeyDown
      aria-labelledby='="general-dialog'
      maxWidth="md"
      classes={{
        paper: classes.root,
      }}
      fullWidth
    >
      <Paper elevation={0}>
        <div className={classes.header}>
          <Typography variant="subtitle2" style={{ fontWeight: "bold" }}>
            {getLang(lang, "label.CREATE_ADDITIONAL_INFO_TITLE")}
          </Typography>
          <IconButton color="primary" onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </div>
        <Divider />
        <Formik
          initialValues={{
            languageTabIndex: 0,
            titles: [{ title: { EN: "" }, format: 1 }],
            usedLang: [languages.find((lang) => lang.code === "EN")],
          }}
          enableReinitialize
          onSubmit={handleCreate}
          validationSchema={Yup.object({
            titles: Yup.array().of(
              Yup.object({
                title: Yup.lazy((obj) =>
                  Yup.object(
                    mapValues(obj, (value, key) => {
                      return Yup.string().trim().required();
                    })
                  ).required(
                    getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")
                  )
                ),
              })
            ),
          })}
        >
          {(formik) => {
            let currentLang =
              formik.values.usedLang[formik.values.languageTabIndex]?.code ??
              "EN";

            return (
              <Box>
                <Box
                  maxHeight="70vh"
                  pt={1}
                  px={3}
                  pb={3}
                  style={{ overflowY: "auto" }}
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    width="100%"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Tabs
                      value={formik.values.languageTabIndex}
                      onChange={(event, index) => {
                        formik.setFieldValue(
                          "languageTabIndex",
                          parseInt(index)
                        );
                      }}
                      aria-label="scrollable auto tabs example"
                      textColor="primary"
                      variant="scrollable"
                      indicatorColor="primary"
                      classes={{
                        indicator: classes.indicator,
                        scrollButtons: classes.scroll,
                      }}
                      style={{ flex: 1 }}
                    >
                      {formik.values.usedLang.map((item, index) => {
                        return (
                          <CustomTab
                            key={index}
                            {...a11yProps(index, item.label)}
                          />
                        );
                      })}
                    </Tabs>
                    <Box display="flex" alignItems="center" ml={2}>
                      <Box>
                        <IconButton
                          size="small"
                          onClick={() => {
                            let toRemove =
                              formik.values.usedLang[
                                formik.values.languageTabIndex
                              ].code;

                            const temp = [...formik.values.usedLang].filter(
                              (item) =>
                                item.code !==
                                formik.values.usedLang[
                                  formik.values.languageTabIndex
                                ].code
                            );

                            let titleTemp = [...formik.values.titles].map(
                              (a) => {
                                let title = { ...a.title };
                                delete title[toRemove];

                                return {
                                  ...a,
                                  title,
                                };
                              }
                            );

                            formik.setFieldValue(
                              "languageTabIndex",
                              temp.length - 1
                            );
                            formik.setFieldValue("usedLang", temp);
                            formik.setFieldValue("titles", titleTemp);
                          }}
                          disabled={
                            formik.values.usedLang.length <= 1 ||
                            formik.values.usedLang[
                              formik.values.languageTabIndex
                            ]?.code === "EN" ||
                            isSubmitting
                          }
                        >
                          <RemoveIcon />
                        </IconButton>
                      </Box>
                      <Box ml={0.5}>
                        <IconButton
                          size="small"
                          onClick={(event) => setAnchorEl(event.currentTarget)}
                          disabled={
                            languages.length - formik.values.usedLang.length ===
                              0 || isSubmitting
                          }
                        >
                          <AddIcon />
                        </IconButton>
                        <Menu
                          anchorEl={anchorEl}
                          keepMounted
                          open={Boolean(anchorEl)}
                          onClose={() => setAnchorEl(null)}
                        >
                          {languages
                            .filter(
                              (language) =>
                                !formik.values.usedLang.some(
                                  (used) => used.code === language.code
                                )
                            )
                            .map((language, index) => (
                              <MenuItem
                                onClick={() => {
                                  const temp = [...formik.values.usedLang];
                                  temp.push(language);

                                  let titleTemp = [...formik.values.titles].map(
                                    (a) => {
                                      let title = { ...a.title };
                                      title[language.code] = "";
                                      return {
                                        ...a,
                                        title,
                                      };
                                    }
                                  );

                                  formik.setFieldValue("usedLang", temp);
                                  formik.setFieldValue(
                                    "languageTabIndex",
                                    temp.length - 1
                                  );
                                  formik.setFieldValue("titles", titleTemp);
                                  setAnchorEl(null);
                                }}
                                key={index}
                              >
                                {language.label}
                              </MenuItem>
                            ))}
                        </Menu>
                      </Box>
                    </Box>
                  </Box>
                  <Box className={classes.titleContainer}>
                    {formik.values.titles.map((t, index) => (
                      <Box
                        display="flex"
                        alignItems="center"
                        key={index}
                        mt={!index ? 0 : 1}
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          className={classes.inputRow}
                          mr={1}
                        >
                          <Input
                            hiddenLabel
                            variant="filled"
                            size="small"
                            placeholder={getLang(
                              lang,
                              "placeholder.PLEASE_ENTER_TITLE"
                            )}
                            disabled={isSubmitting}
                            margin="none"
                            InputProps={{
                              disableUnderline: true,
                              margin: "none",
                              style: {
                                backgroundColor: "transparent",
                                height: 38,
                              },
                              onBlur: formik.handleBlur,
                              name: `titles.[${index}].title.[${currentLang}]`,
                            }}
                            required={true}
                            inputProps={{ maxLength: 50 }}
                            style={{ width: "100%" }}
                            onChange={(e) => {
                              let temp = [...formik.values.titles];
                              temp[index] = {
                                ...temp[index],
                                title: {
                                  ...temp[index]["title"],
                                  [currentLang]: e.target.value,
                                },
                              };
                              formik.setFieldValue("titles", temp);
                            }}
                            onClick={(e) => e.stopPropagation()}
                            value={t?.title[currentLang] ?? ""}
                            error={
                              formik.touched.titles?.[index]?.title?.[
                                currentLang
                              ] &&
                              formik.errors.titles?.[index]?.title?.[
                                currentLang
                              ]
                                ? true
                                : false
                            }
                          />
                          <Box mx={1}>
                            <Typography variant="caption" color="textSecondary">
                              {t?.title[currentLang]?.length ?? 0}/50
                            </Typography>
                          </Box>
                        </Box>
                        <Box mr={2}>
                          <Select
                            value={t?.format}
                            style={{
                              width: "100%",
                            }}
                            classes={{ root: classes.selectRoot }}
                            disableUnderline
                            inputProps={{
                              style: {
                                padding: "6px",
                                // backgroundColor: "#ECEFF0",
                                borderRadius: "4px",
                                // ...InputPropStyle,
                              },
                            }}
                            renderValue={(value) => (
                              <Box ml={2}>{options[value - 1]?.label}</Box>
                            )}
                            onChange={(event) => {
                              let temp = [...formik.values.titles];
                              temp[index] = {
                                ...temp[index],
                                format: parseInt(event.target.value),
                              };
                              formik.setFieldValue("titles", temp);
                            }}
                          >
                            {options.map((option, index) => (
                              <MenuItem value={option.value} key={index}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </Box>
                        <Box display="flex" alignItems="center">
                          <IconButton
                            size="small"
                            onClick={() => {
                              let temp = [...formik.values.titles];
                              if (temp.length > 1) {
                                temp.splice(index, 1);
                                formik.setFieldTouched(
                                  `titles.[${index}].title`,
                                  formik.touched.titles?.[index + 1]?.title ??
                                    false
                                );
                              } else {
                                let newTitle = {};
                                formik.values.usedLang.forEach((l) => {
                                  newTitle[l.code] = "";
                                });
                                temp = [newTitle];
                                formik.setFieldTouched(
                                  `titles.[${index}].title`,
                                  false
                                );
                              }

                              formik.setFieldValue("titles", temp);
                            }}
                          >
                            <Box className={classes.squareButton}>
                              <RemoveCircleIcon style={{ color: "#F97066" }} />
                            </Box>
                          </IconButton>
                          <IconButton
                            size="small"
                            onClick={() => {
                              let temp = [...formik.values.titles];
                              let newTitle = {};
                              formik.values.usedLang.forEach((l) => {
                                newTitle[l.code] = "";
                              });
                              temp.push({
                                format: 1,
                                title: newTitle,
                              });
                              formik.setFieldValue("titles", temp);
                            }}
                          >
                            <Box className={classes.squareButton}>
                              <AddCircleIcon style={{ color: "#6AAF68" }} />
                            </Box>
                          </IconButton>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </Box>

                <Divider />
                <DialogActions>
                  <Button
                    autoFocus
                    onClick={(e) => {
                      handleClose();
                    }}
                    disabled={isSubmitting}
                    color="primary"
                  >
                    {getLang(lang, "label.CANCEL")}
                  </Button>
                  <Button
                    color="secondary"
                    variant="contained"
                    disableElevation
                    autoFocus
                    disabled={isSubmitting}
                    onClick={() => {
                      let valid = true;
                      let titles = formik.values.titles.reduce(
                        (list, t, idx) => {
                          let totalEmpty = 0;
                          Object.keys(t.title).forEach((key) => {
                            if (!t.title[key].trim()) {
                              totalEmpty += 1;
                            }
                          });
                          if (
                            totalEmpty > 0 &&
                            totalEmpty < formik.values.usedLang.length
                          ) {
                            valid = false;
                          }

                          if (totalEmpty !== formik.values.usedLang.length) {
                            list.push(t);

                            formik.values.usedLang.forEach((l) =>
                              formik.setFieldTouched(
                                `titles.[${idx}].title.[${l.code}]`,
                                true
                              )
                            );
                          }

                          return list;
                        },
                        []
                      );

                      if (titles.length) {
                        formik.setFieldValue("titles", titles);
                      } else {
                        let newTitle = {};
                        formik.values.usedLang.forEach((l) => {
                          newTitle[l.code] = "";
                        });

                        formik.setFieldValue("titles", [newTitle]);
                      }

                      if (!valid) {
                        addAlert(getLang(lang, "label.NOT_ALL_LANG_FILLED"));
                      } else {
                        formik.handleSubmit();
                      }
                    }}
                  >
                    {isSubmitting
                      ? getLang(lang, "label.SUBMITTING")
                      : getLang(lang, "label.CREATE")}
                  </Button>
                </DialogActions>
              </Box>
            );
          }}
        </Formik>
      </Paper>
    </Dialog>
  );
}
