import React from "react";
import {
  makeStyles,
  Box,
  Grid,
  Typography,
  Button,
  fade,
  ButtonBase
} from "@material-ui/core";
import AntSwitch from "components/switch";
import { DeleteSVG, VisaSVG } from "../../../utils/static";
import MastercardLogo from "assets/svg/mastercard-logo.svg";
import VisaLogo from "assets/svg/visa-logo.svg";
import UnionVisa from "assets/svg/unionVisa.svg";
import UnionMaster from "assets/svg/unionMaster.svg";
import clsx from "clsx";
import AddBoxOutlinedIcon from "@material-ui/icons/AddBoxOutlined";
import { Skeleton } from "@material-ui/lab";
import AddIcon from "@material-ui/icons/Add";
import { getLang } from "app/feature/constants";

const style = theme => ({
  root: {
    zIndex: "999"
  },
  buttonContainer: {
    display: "grid",
    gridTemplateColumns: "0.5fr 5fr 0.5fr",
    alignItems: "center"
  },
  buttonAction: {
    padding: 0,
    minWidth: 0
  },
  mockCard: {
    padding: theme.spacing(3),
    marginTop: theme.spacing(2),
    borderRadius: theme.spacing(1),
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    boxSizing: "border-box",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.15)"
  },
  bgMastercard: {
    color: "#313943",
    background: "linear-gradient(70.41deg, #60BCFF 0.09%, #76FFAD 100%)",
    boxShadow: "20px 20px 100px rgba(0, 0, 0, 0.2)"
  },
  bgVisa: {
    color: theme.palette.common.white,
    background: "linear-gradient(70.47deg, #3A4D54 0%, #625D81 100%)",
    boxShadow: "20px 20px 100px rgba(0, 0, 0, 0.2)"
  },
  logo: {
    height: 26,
    display: "flex",
    alignItems: "flex-end"
  },
  cardNumber: {
    fontSize: "large",
    padding: theme.spacing(4),
    display: "flex",
    alignSelf: "center"
  },
  visaExpDate: {
    color: fade(theme.palette.common.white, 0.33)
  },
  mastercardExpDate: {
    color: "rgba(49, 57, 67, 0.5)"
  },
  buttonCard: {
    margin: theme.spacing(1, 0),
    padding: theme.spacing(1, 2, 1, 2),
    borderRadius: "8px",
    border: "1px solid rgba(0, 0, 0, 0.1)",
    display: "flex",
    height: 60,
    width: "100%"
  },
  cardSelected: {
    borderColor: theme.palette.primary.main,
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.15)"
  },
  text: {
    color: "rgba(49, 57, 67, 0.33)"
  },
  addCard: {
    fontWeight: "bold",
    paddingLeft: theme.spacing(2)
  },
  card: {
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    border: "dashed 2px #9CA6A9",
    width: "100%",
    height: 140,
    minWidth: 190,
    maxWidth: 210,
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    boxSizing: "border-box",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.15)",
    backgroundColor: theme.palette.common.white,
    position: "relative"
  },
  addButton: {
    zIndex: 1,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.main,
    position: "absolute",
    height: 49,
    width: 49,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "50%"
  }
});

const useStyles = makeStyles(style);

const MAX_CARD_COUNT = 3;
let isVisa;

export default function PaymentMethodCardComponent({
  handleAddCard,
  paymentMethods,
  handleCardClick,
  currentPaymentMethod,
  handleChangePrimarySwitch,
  isProcessing,
  handleDelete,
  isPrimarySwitchChecked,
  lang
}) {
  const classes = useStyles();
  if (currentPaymentMethod)
  {
    isVisa = currentPaymentMethod.card.brand === "visa" ? true : false
  }

  const addCard = () => {
    const addCardArray = new Array(3 - paymentMethods.length);
    let content = [];
    for (let i = 0; i < addCardArray.length; i++) {
      content.push(i);
    }
    return content.map(id => (
      <Button className={classes.buttonCard} onClick={handleAddCard} key={id}>
        <AddBoxOutlinedIcon />
        <Typography variant="caption" className={classes.addCard}>
          {getLang(lang, "label.ADD_CARD")}
        </Typography>
      </Button>
    ));
  };

  return (
    <Box className={classes.root}>
      <Typography
        variant="body2"
        color="primary"
        style={{ fontWeight: "bold" }}
      >
        {getLang(lang, "label.PAYMENT_METHOD")}
      </Typography>

      <Box pt={2}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={6} style={{ width: "100%" }}>
            <Box className={classes.buttonContainer}>
              <AntSwitch
                disabled={isPrimarySwitchChecked || isProcessing}
                checked={isPrimarySwitchChecked}
                onChange={() => handleChangePrimarySwitch(currentPaymentMethod)}
              />

              <Typography
                variant="body2"
                color="primary"
                style={{ paddingLeft: "4px" }}
              >
                {getLang(lang, "label.PRIMARY_CARD")}
              </Typography>
              {currentPaymentMethod &&
                <Button
                  className={classes.buttonAction}
                  onClick={() => handleDelete(currentPaymentMethod.id)}
                >
                  <DeleteSVG />
                </Button>
              }
            </Box>

            {currentPaymentMethod ? <Box
              className={clsx(classes.mockCard, {
                [classes.bgMastercard]: !isVisa,
                [classes.bgVisa]: isVisa
              })}
            >
              <Box className={classes.logo}>
                <Grid container>
                  <Grid item xs={6} sm={6} md={6} style={{ width: "100%" }}>
                    <img
                      alt="card type"
                      src={isVisa ? VisaLogo : MastercardLogo}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    md={6}
                    style={{ width: "100%", textAlign: "end" }}
                  >
                    <img alt="union" src={isVisa ? UnionVisa : UnionMaster} />
                  </Grid>
                </Grid>
              </Box>

              <Typography variant="subtitle1" className={classes.cardNumber}>
                {`**** **** **** ${currentPaymentMethod.card.last4}`}
              </Typography>
              <Box>
                <Grid container>
                  <Grid item xs={4} sm={4} md={4} style={{ width: "100%" }}>
                    <Typography
                      variant="caption"
                      className={clsx({
                        [classes.visaExpDate]: isVisa,
                        [classes.mastercardExpDate]: !isVisa
                      })}
                    >
                      {getLang(lang, "label.EXP_DATE")}
                    </Typography>
                    <Typography variant="body2">
                      {`${currentPaymentMethod.card.exp_month <= 9 ? "0" : ""}${currentPaymentMethod.card.exp_month
                        }/${String(currentPaymentMethod.card.exp_year).substring(
                          2,
                          4
                        )}`}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={8}
                    sm={8}
                    md={8}
                    style={{ width: "100%", textAlign: "end" }}
                  >
                    <Typography
                      variant="caption"
                      className={clsx({
                        [classes.visaExpDate]: isVisa,
                        [classes.mastercardExpDate]: !isVisa
                      })}
                    >
                      {getLang(lang, "label.NAME_ON_CARD")}
                    </Typography>
                    <Typography variant="body2">
                      {currentPaymentMethod.billing_details.name}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Box>
              :
              <Box mt={2}>
                <Box className={classes.card}>
                  <ButtonBase className={classes.addButton} onClick={handleAddCard}>
                    <AddIcon fontSize="large" />
                  </ButtonBase>
                  <Skeleton animation={false} variant="text" width={54} />
                  <Skeleton animation={false} variant="text" width={147} />
                  <Skeleton animation={false} variant="text" width={54} />
                  <Skeleton animation={false} variant="text" width={37} />
                </Box>
                <Box p={1.5} display="flex" justifyContent="center" alignItems="center">
                  <Typography variant="body1" color="textSecondary">
                    {getLang(lang, "label.ADD_NEW_CARD")}
                  </Typography>
                </Box>
              </Box>
            }
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            style={{ width: "100%", alignSelf: "center" }}
          >
            {paymentMethods.map((field, index) => {
              const isSelected = currentPaymentMethod.id === field.id;
              return (
                <Button
                  className={clsx(
                    classes.buttonCard,
                    isSelected && classes.cardSelected
                  )}
                  key={index}
                  onClick={() => handleCardClick(field)}
                >
                  <Grid container style={{ alignItems: "center" }}>
                    <Grid
                      item
                      xs={4}
                      sm={4}
                      md={4}
                      style={{
                        width: "100%",
                        textAlign: "start",
                        display: "flex"
                      }}
                    >
                      {field.card.brand === "visa" ? (
                        <VisaSVG />
                      ) : (
                        <img alt="card type" src={MastercardLogo} />
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={8}
                      sm={8}
                      md={8}
                      style={{
                        width: "100%",
                        textAlign: "end",
                        display: "grid"
                      }}
                    >
                      <Typography
                        variant="body1"
                        style={{ fontWeight: "bold" }}
                      >
                        {`**** **** **** ${field.card.last4}`}
                      </Typography>

                      <Typography variant="caption" className={classes.text}>
                        {field.card.brand === "visa"
                          ? getLang(lang, "label.VISA_CARD")
                          : getLang(lang, "label.MASTER_CARD")}
                      </Typography>
                    </Grid>
                  </Grid>
                </Button>
              );
            })}

            {paymentMethods.length < MAX_CARD_COUNT && addCard()}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
