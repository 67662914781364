import { Box, Typography } from "@material-ui/core";
import { fontTextStyle } from "modules/reporting/styles";
import React from "react";
import { ContentCard } from "../../layout";

export default function CampainTitleCardComponent({
    currentFilter,
    translate
}){
    const fontClasses = fontTextStyle();

    const getCampaignType = (type) => {
        switch (type) {
            case 1:
                return translate("label.SURVEY");

            case 2:
                return translate("label.LUCKY_DRAW");

            case 3:
                return translate("label.INSTANT_LUCKY_DRAW");

            case 4:
                return translate("label.LOYALTY_PROGRAM");

            case 5:
                return translate("label.WARRANTY");

            default:
                return translate("label.UNKNOWN");
        }
    }

    return (
        <ContentCard>
            <Box
                display="flex"
                alignItems={"center"}
                padding="20px 30px"
            >
                <Typography className={fontClasses.text__detail_title}>
                    {translate("label.CAMPAIGN")} : {getCampaignType(currentFilter.campaignType)}
                </Typography>
            </Box>
        </ContentCard>
    )
}