import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { unwrapResult } from '@reduxjs/toolkit'
import { connect, useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { selectLoading } from 'modules/notification'
import { changeSurveyStatus, createSurvey, editSurvey, fetchSurveyListing, deleteSurvey } from 'modules/survey/redux/action'
import { CreateSurveyDialog, EditSurveyDialog } from '../../dialog'
import SurveyListingPanelComponent from './surveyListingPanel.component'
import { SURVEY_ADD, SURVEY_EDIT, SURVEY_ACTIVATE_OR_DEACTIVATE } from "lib/constants/accessRights"
import { addAlert } from 'modules/notification';
import GeneralDialog from 'components/dialog/generalDialog';
import AlertIcon from 'assets/svg/alert.svg';
import SuccessIcon from 'assets/svg/success.svg';
import { hideBackdrop } from "modules/backdrop";
import { generatePaginationOptions } from "lib/helper";
import { getLang } from 'app/feature/constants'

const SurveyListingPanelContainer = forwardRef((_, ref) => {
  const dispatch = useDispatch()
  const lang = useSelector(state => state.constant.languages);
  const hasSurveyAddAccessRight = useSelector(state => state.profile.accessRights.includes(SURVEY_ADD));
  const hasSurveyEditAccessRight = useSelector(state => state.profile.accessRights.includes(SURVEY_EDIT));
  const hasSurveyActivateOrDeactivateAccessRight = useSelector(state => state.profile.accessRights.includes(SURVEY_ACTIVATE_OR_DEACTIVATE));
  const totalRecords = useSelector(state => state.survey.surveyList.totalRecords)
  const data = useSelector(state => state.survey.surveyList.data)
  const isFetching = useSelector(state => selectLoading(state, fetchSurveyListing.typePrefix))
  const isStatusUpdating = useSelector(state => selectLoading(state, changeSurveyStatus.typePrefix));
  const isDeleteSurvey = useSelector(state => selectLoading(state, deleteSurvey.typePrefix))
  const [isDeactivateDialogOpen, setIsDeactivateDialogOpen] = useState(false)
  const [isActivateDialogOpen, setIsActivateDialogOpen] = useState(false)
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  const [deleteSurveyId, setDeleteSurveyId] = useState(false);
  const [changeStatusTargetId, setChangeStatusTargetId] = useState("")
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false)
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false)
  const [surveyType, setSurveyType] = useState("");

  const [paginationOptions, setPaginationOptions] = useState([]);
  const [filter, setFilter] = useState({
    rowsPerPage: 25,
    page: 0,
    search: "",
    filterStatus: "",
    dateCreated: "",
    productSelect: ""
  })


  useImperativeHandle(ref, () => ({
    openCreateSurveyModal: type => {
      setSurveyType(type)
      setIsCreateDialogOpen(true)
    }
  }))

  useEffect(() => {
    dispatch(fetchSurveyListing({
      length: filter.rowsPerPage,
      start: filter.rowsPerPage * filter.page,
      search: filter.search,
      filterStatus: filter.filterStatus,
      dateCreated: filter.dateCreated,
      productSelect: filter.productSelect
    }))
  }, [filter, dispatch])

  useEffect(() => {
    setPaginationOptions(generatePaginationOptions(totalRecords))
  }, [totalRecords])

  const handleReload = () => {
    dispatch(fetchSurveyListing({
      length: filter.rowsPerPage,
      start: filter.rowsPerPage * filter.page,
      search: filter.search,
      filterStatus: filter.filterStatus,
      dateCreated: filter.dateCreated,
      productSelect: filter.productSelect
    }))
  };

  const handleFetchSurveyList = () => {
    dispatch(fetchSurveyListing({
      length: filter.rowsPerPage,
      start: filter.rowsPerPage * filter.page,
      search: filter.search,
      filterStatus: filter.filterStatus,
      dateCreated: filter.dateCreated,
      productSelect: filter.productSelect
    }))
  }

  const handleChangePage = (event, newPage) => {
    setFilter((prev) => ({
      ...prev,
      page: newPage
    }))
  }

  const handleChangeRowsPerPage = event => {
    setFilter((prev) => ({
      ...prev,
      page: 0,
      rowsPerPage: event.target.value
    }))
  }

  const handleSearch = value => {
    setFilter((prev) => ({
      ...prev,
      page: 0,
      search: value.search,
      filterStatus: value.statusSelect,
      dateCreated: value.dateCreated,
      productSelect: value.productSelect
    }))
  }

  const handleStatusChange = (currentStatus, id) => {
    if (hasSurveyActivateOrDeactivateAccessRight) {
      setChangeStatusTargetId(id)
      if (currentStatus === 'published') {
        setIsDeactivateDialogOpen(true)
      }

      if (currentStatus === 'unpublished') {
        setIsActivateDialogOpen(true)
      }
    }
  }

  const handleStatusSubmit = status => {
    if (hasSurveyActivateOrDeactivateAccessRight) {
      dispatch(changeSurveyStatus({
        id: changeStatusTargetId,
        status
      }))
        .then(unwrapResult)
        .then(() => handleFetchSurveyList())
        .finally(() => {
          setIsDeactivateDialogOpen(false);
          setIsActivateDialogOpen(false);
        });
    }
  }

  const handleCreateSurvey = (values, isError) => {
    if (!hasSurveyEditAccessRight) {
      return dispatch(addAlert({ severity: "error", message: getLang(lang, 'message.error.PERMISSION_DENIED') }))
    }

    if (isError) {
      return dispatch(addAlert({ severity: "error", message: getLang(lang, 'message.error.ERROR_FOUND_IS_FIELD') }))
    }
    if (values.questions.length === 0) {
      return dispatch(addAlert({ severity: "error", message: getLang(lang, 'message.error.ERROR_PLEASE_ADD_QUESTION') }))
    }
    if (values.questions.length) {
      for (let i = 0; i < values.questions.length; i++) {
        values.questions[i].text = values.questions[i].text.trim();
        values.questions[i].placeholder = values.questions[i].placeholder.trim();
        if (values.questions[i].text === "")
          return dispatch(addAlert({ severity: "error", message: "Please fill in the question." }))
        if (values.questions[i].answers !== null) {
          for (let j = 0; j < values.questions[i].answers.length; j++) {

            if (values.questions[i].answers[j] === "")
              return dispatch(addAlert({ severity: "error", message: "Please fill in the option(s). " }))
            values.questions[i].answers[j] = values.questions[i].answers[j].trim();
          }
        }
      }

    }

   
    let temp = values.productIds.map((item) => item.id)

    let payload = {
      name: values.name,
      description: values.description,
      productIds: temp,
      startDate: moment(values.startDate).format("YYYY-MM-DD HH:mm"),
      endDate: moment(values.endDate).format("YYYY-MM-DD HH:mm"),
      questions: values.questions
    }

    dispatch(createSurvey(payload))
      .then(unwrapResult)
      .then(() => {
        dispatch(hideBackdrop())
        handleFetchSurveyList()
        setIsCreateDialogOpen(false)
      })

  }

  const handleCreate = () => {
    if (hasSurveyAddAccessRight) {
      setIsCreateDialogOpen(true)
    }
  }

  const handleEditSurvey = async (values, isError) => {
    
    if (!hasSurveyEditAccessRight) {
      return dispatch(addAlert({ severity: "error", message: getLang(lang, 'message.error.PERMISSION_DENIED') }))
    }

    if (isError) {
      return dispatch(addAlert({ severity: "error", message: getLang(lang, 'message.error.ERROR_FOUND_IS_FIELD')}))
    }

    let questions = JSON.parse(JSON.stringify(values.questions))
    if (questions.length) {
      for (let i = 0; i < questions.length; i++) {
        questions[i].text = questions[i].text.trim();
        questions[i].placeholder = questions[i].placeholder.trim();

        if (values.questions[i].text === "")
          return dispatch(addAlert({ severity: "error", message: "Please fill in the question." }))
        if (questions[i].answers !== null) {
          for (let j = 0; j < questions[i].answers.length; j++) {
            if (questions[i].answers[j] === "")
              return dispatch(addAlert({ severity: "error", message: "Please fill in the option(s). " }))
            questions[i].answers[j] = questions[i].answers[j].trim();
          }
        }
      }
    }

    let temp = values.productIds.map((item) => item.id)

    let payload = {
      id: values.id,
      name: values.name,
      description: values.description,
      productIds: temp,
      startDate: moment(values.startDate).format("YYYY-MM-DD HH:mm"),
      endDate: moment(values.endDate).format("YYYY-MM-DD HH:mm"),
      questions: questions
    }

    dispatch(editSurvey(payload))
      .then(unwrapResult)
      .then(() => {
        handleFetchSurveyList()
        closeEditSurveyModal()
      })
  }

  const handleDeleteSurvey = (id) => {
    setIsDeleteDialogOpen(true)
    setDeleteSurveyId(id)
  }

  const handleSubmitDeleteSurvey = () => {
    dispatch(deleteSurvey({
      id: deleteSurveyId
    }))
      .then(unwrapResult)
      .then(() => handleFetchSurveyList())
      .finally(() => {
        setIsDeleteDialogOpen(false)
      })
  }

  const closeEditSurveyModal = () => {
    setIsEditDialogOpen(false)
  }
  const closeCreateSurveyModal = () => {
    setIsCreateDialogOpen(false)
  }
  const openEditSurveyModal = () => {
    setIsEditDialogOpen(true)
  }

  return (
    <>
      <SurveyListingPanelComponent
        hasSurveyAddAccessRight={hasSurveyAddAccessRight}
        hasSurveyEditAccessRight={hasSurveyEditAccessRight}
        hasSurveyActivateOrDeactivateAccessRight={hasSurveyActivateOrDeactivateAccessRight}
        data={data}
        isFetching={isFetching}
        page={filter.page}
        search={filter.search}
        filterStatus={filter.filterStatus}
        dateCreated={filter.dateCreated}
        productSelect={filter.productSelect}
        rowsPerPage={filter.rowsPerPage}
        totalRecords={totalRecords}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleSearch={handleSearch}
        handleCreate={handleCreate}
        handleStatusChange={handleStatusChange}
        openEditSurveyModal={openEditSurveyModal}
        handleReload={handleReload}
        handleDeleteSurvey={handleDeleteSurvey}
        paginationOptions={paginationOptions}

      />
      {isCreateDialogOpen && (
        <CreateSurveyDialog
          isOpen={isCreateDialogOpen}
          handleClose={closeCreateSurveyModal}
          handleSubmit={handleCreateSurvey}
          type={surveyType}
        />
      )}

      {isEditDialogOpen && (
        <>
          <EditSurveyDialog
            isOpen={isEditDialogOpen}
            handleClose={closeEditSurveyModal}
            handleSubmit={handleEditSurvey}
          />
        </>
      )}
      <GeneralDialog
        isOpen={isDeactivateDialogOpen}
        isLoading={isStatusUpdating}
        handleClose={() => setIsDeactivateDialogOpen(false)}
        handleProceed={() => handleStatusSubmit("unpublished")}
        title={getLang(lang, 'label.UNPUBLISH_SURVEY')}
        description={getLang(lang, 'paragraph.DO_YOU_WANT_TO_UNPUBLISH_SURVEY')}
        type="danger"
        icon={<img src={AlertIcon} alt="" />}
      />
      <GeneralDialog
        isOpen={isActivateDialogOpen}
        isLoading={isStatusUpdating}
        handleClose={() => setIsActivateDialogOpen(false)}
        handleProceed={() => handleStatusSubmit("published")}
        title={getLang(lang, 'label.PUBLISH_SURVEY')}
        description={getLang(lang, 'paragraph.DO_YOU_WANT_TO_PUBLISH_SURVEY')}
        type="success"
        icon={<img src={SuccessIcon} alt="" />}
      />
      <GeneralDialog
        isOpen={isDeleteDialogOpen}
        isLoading={isDeleteSurvey}
        handleClose={() => setIsDeleteDialogOpen(false)}
        handleProceed={handleSubmitDeleteSurvey}
        title={getLang(lang, 'label.DELETE_SURVEY')}
        description={getLang(lang, 'paragraph.DO_YOU_WANT_TO_DELETE_SURVEY')}
        type="danger"
        icon={<img src={AlertIcon} alt="" />}
      />
    </>
  )
})

export default connect(null, null, null, { forwardRef: true })(SurveyListingPanelContainer)