import React from "react";
import {
  makeStyles,
  Typography,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  Box,
  Divider,
  MenuItem,
} from "@material-ui/core";
import { getLang } from "app/feature/constants";
import InfiniteScroll from "react-infinite-scroll-component";
import LoadingComponent from "components/loading";
import RefreshTable from "components/table/refreshTable";

const useStyle = makeStyles((theme) => ({
  paper: {
    width: 425,
  },
  button: {
    padding: 0,
    minWidth: 0,
  },
  buttonAddBranch: {
    display: "flex",
    padding: 0,
    minWidth: 0,
  },
  createLink: {
    padding: theme.spacing(2),
  },
  list: {
    maxHeight: "25vh",
    overflowY: "auto",
  },
}));

export default function RequestTransferranchSelectPanelComponent({
  open,
  anchorRef,
  handleClose,
  isOpenList,
  branchDropdown,
  isFetching,
  value,
  handleSelectBranch,
  lang,
  handleFetchMoreItems,
  isError,
  totalFiltered,
  handleReload,
  totalRecords,
}) {
  const classes = useStyle();

  return (
    <Popper
      open={open}
      style={{ zIndex: 99 }}
      anchorEl={anchorRef.current}
      role={undefined}
      transition
      disablePortal
      placement="bottom-start"
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === "bottom-start" ? "center top" : "center bottom",
          }}
        >
          <Paper className={classes.paper}>
            <ClickAwayListener onClickAway={handleClose}>
              <Box>
                <Typography
                  variant="body2"
                  color="primary"
                  style={{ padding: "8px 16px", fontWeight: "bold" }}
                >
                  {getLang(lang, "label.BRANCH")}
                </Typography>
                <Divider />
                {isError ? (
                  <Box style={{ textAlign: "center" }}>
                    <RefreshTable handleReload={handleReload} />
                  </Box>
                ) : totalRecords ? (
                  <div>
                    <InfiniteScroll
                      height="100%"
                      dataLength={totalRecords}
                      next={handleFetchMoreItems}
                      className={classes.list}
                      hasMore={totalFiltered > totalRecords}
                      loader={<LoadingComponent height={100} />}
                      endMessage={
                        !totalRecords ? (
                          isFetching ? (
                            <LoadingComponent height={100} />
                          ) : (
                            <Box px={1.5} py={4}>
                              <Typography
                                variant="body2"
                                style={{ textAlign: "center" }}
                              >
                                <b>
                                  {getLang(lang, "label.NO_BRANCH_AVAILABLE")}
                                </b>
                              </Typography>
                            </Box>
                          )
                        ) : null
                      }
                    >
                      <Box>
                        {branchDropdown.map((field, index) => {
                          return (
                            <React.Fragment key={index}>
                              <MenuItem
                                style={{ padding: 8 }}
                                onClick={() => handleSelectBranch(field)}
                              >
                                <Typography
                                  variant="inherit"
                                  noWrap
                                  style={{ paddingLeft: "8px" }}
                                >
                                  {field.name}
                                </Typography>
                              </MenuItem>
                            </React.Fragment>
                          );
                        })}
                      </Box>
                    </InfiniteScroll>
                  </div>
                ) : (
                  <Box p={2} style={{ textAlign: "center" }}>
                    <Typography variant="body1">
                      {getLang(lang, "paragraph.NO_BRANCH_AVAILABLE")}
                    </Typography>
                  </Box>
                )}
              </Box>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}
