import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import DragPanelComponent from "./dragPanel.component";
import getErrorMessageByCode from "lib/helper/react-dropzone";
import { useSelector } from "react-redux";

export default function DragPanelContainer({
  title = "",
  handleFile,
  handleRejected,
  multiple = false,
  isLoading = false
}) {
  const onDrop = useCallback(
    acceptedFiles => {
      // this callback will be called after files get dropped, we will get the acceptedFiles. If you want, you can even access the rejected files too

      handleFile(acceptedFiles);
    },
    [handleFile]
  );

  const onDropRejected = (res) => {
    const error = res[0].errors[0]

    const errorMessage = getErrorMessageByCode({
      errorCode: error.code,
      defaultErrorMessage: error.message
    })

    handleRejected(errorMessage)
  }
  const lang = useSelector(state => state.constant.languages);

  const accept = ".jpg, .jpeg, .png, .gif";

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
    multiple,
    onDropRejected
  });

  return (
    <DragPanelComponent
      title={title}
      getRootProps={getRootProps()}
      getInputProps={getInputProps}
      isDragActive={isDragActive}
      isLoading={isLoading}
      lang={lang}
    />
  );
}
