import { Avatar, Box, Paper, TableCell, TableRow, Typography } from "@material-ui/core";
import { fontTextStyle, iconStyle, tableStyle } from "modules/reporting/styles";
import { drilldownPageSelection } from "modules/reporting/utils/helper";
import React from "react";
import { Link } from "react-router-dom";
import { ContentCard } from "../../layout";
import { DynamicTable } from "../../table";
import { UnknownUserIcon } from "modules/reporting/assets/svg";

export default function RespondentsConsumerAllWarrantyPanelComponent({
    status,
    data,
    pagination,
    sort,
    currentFilter,
    pageType,
    translate
}){
    const tableClass = tableStyle();
    const fontClass = fontTextStyle();
    const iconClass = iconStyle();

    const columns = [
        {
            title: translate("label.NUM"),
            field: null,
            render : (row) => {
                return (
                    <>{row.title}</>
                )
            },
            align: "left"
        },
        {
            title: translate("label.CONSUMER"),
            field: null,
            render : (row) => {
                return (
                    <>{row.title}</>
                )
            },
            align: null
        },
        {
            title: translate("label.WARRANTY"),
            field: null,
            render : (row) => {
                return (
                    <>{row.title}</>
                )
            },
            align: "left"
        },
        {
            title: translate("label.SUBMITTED_DATE"),
            field: "created_at",
            render : (row) => {
                return (
                    <>{row.title}</>
                )
            },
            align: "left"
        }
    ];

    const renderData = (data) => {

        let rows = [];
        if(Object.keys(data).length === 0 || !data.isSuccess){
            return rows;
        }

        let imageProps = JSON.stringify(data);
        imageProps = JSON.parse(imageProps);

        let no_iteration = pagination.rowsPerPage * pagination.page + 1;
        imageProps.rows.forEach((item, index) => {
            let row = item.campaign_uuid ? (
                <TableRow key={index}>
                    <TableCell align="left" className={tableClass.table__cell} style={{width:"20px"}}>
                        <Typography className={fontClass.text__table_cell}>
                            {no_iteration++}
                        </Typography>
                    </TableCell>
                    <TableCell
                        align={"left"}
                        className={tableClass.table__cell}
                        style={{width:"844px"}}
                    >
                        <Box className={tableClass.cell__product}>
                            {
                                item.consumer_image ?
                                <Avatar variant="square" alt={item.consumer_name} src={item.consumer_image} className={iconClass.svg__icon}/>
                                :
                                <Avatar variant="square" alt={item.consumer_name} src={UnknownUserIcon} className={iconClass.svg__icon}/>
                            }
                            {
                                item.consumer_uuid ? (
                                    <Link 
                                        target="_blank" rel="noopener noreferrer"
                                        className={fontClass.text__hyperlink}
                                        to={() => {
                                            if(!item.consumer_uuid)
                                                return;
        
                                            let filterProperties = {};
        
                                            if(item.consumer_uuid)
                                                filterProperties.consumerUuid = item.consumer_uuid;
        
                                            let url = drilldownPageSelection(filterProperties, pageType, true);
                                            return url;
                                        }}
                                    >
                                        {item.consumer_name ?? translate("label.UNKNOWN")}
                                    </Link>
                                ) : (
                                    <Typography className={fontClass.text__unknown}>
                                        {translate("label.ANONYMOUS")}
                                    </Typography>
                                )
                            }
                        </Box>
                    </TableCell>
                    <TableCell align={"left"} className={tableClass.table__cell} style={{minWidth:"240px", maxWidth:"240px"}}>
                        {
                            item.campaign_name ? (
                                <Link 
                                    target="_blank" rel="noopener noreferrer"
                                    className={fontClass.text__hyperlink}
                                    to={() => {
        
                                        let filterProperties = {};
        
                                        if(item.campaign_uuid)
                                            filterProperties.campaignUuid = item.campaign_uuid;
        
                                        if(item.campaign_type)
                                            filterProperties.campaignType = item.campaign_type;
        
                                        let url = drilldownPageSelection(filterProperties, pageType, true);
                                        return url;
                                    }}
                                >
                                    <span>{item.campaign_name}</span>
                                </Link>
                            ) : (
                                <Typography className={fontClass.text__unknown}>
                                    {translate("-")}
                                </Typography>
                            )
                        }

                    </TableCell>
                    <TableCell align={"left"} className={tableClass.table__cell} style={{minWidth:"140px", maxWidth: "140px"}}>
                        <Typography className={fontClass.text__table_cell}>{item.submit_date ?? '-'}</Typography>
                    </TableCell>
                </TableRow>
            ) : (
                null
            )

            
            item.render = row;
        })
        
        return imageProps;
    }


    return (
        <ContentCard>
            <Box marginBottom="24px" display="flex" justifyContent="space-between" alignContent="center">
                <Typography className={fontClass.text__card_title}>
                    {translate("label.RESPONDENTS")}
                </Typography>
            </Box>
            <DynamicTable 
                component={ Paper }
                sort={sort}
                pagination={pagination}
                columns={columns}
                data={renderData(data.respondentsConsumerAllWarrantyDatalist)}
                status={status.isFetchingRespondentsConsumerAllWarrantyDatalist}
                translate={translate}
            />
        </ContentCard>
    )
}