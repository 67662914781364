import React, { useState } from "react";
import ActionSelectComponent from './actionSelect.component';
import ActionSelectPanelComponent from './actionSelectPanel.component';

export default function ActionSelectContainer({
  handleChange,
  disabled,
  style,
  isFetching,
  label,
  panelStyle,
  dropdownItem,
  emptyMessage,
  anchorOrigin,
  transformOrigin,
  PaperProps,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleListKeyDown = (field) => {
    if(field.name) {
      handleChange(field.name)
    } else {
      handleChange(field)
    }
    handleClose()
  }

  return (
    <>
      <ActionSelectComponent
        handleClick={handleClick}
        label={label}
        disabled={disabled}
        open={open}
        id={id}
        style={style}
      />
      <ActionSelectPanelComponent
        anchorEl={anchorEl}
        open={open}
        handleClose={handleClose}
        style={panelStyle}
        id={id}
        items={dropdownItem}
        isFetching={isFetching}
        emptyMessage={emptyMessage}
        handleListKeyDown={handleListKeyDown}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        PaperProps={PaperProps}
      />
    </>
  )
}