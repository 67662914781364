import React from "react";
import TiktokFormComponent from "./tiktokForm.component";
import PropTypes from "prop-types";
import { isUrlValid, getTiktokIdByUrl } from "../../../utils/productPage.util";
import { useSelector } from "react-redux";

const TYPES = ["new", "edit", "readonly"];

function TiktokFormContainer({
  type,
  handleResult,
  initialValues,
  handleDelete,
  handleClose
}) {
  const lang = useSelector(state => state.constant.languages)

  const handleSubmit = (values, formikApi) => {
    const url = values.URL;

    if (!isUrlValid(url) || !getTiktokIdByUrl(url)) {
      formikApi.setFieldError("URL", "Invalid TikTok video URL format");
      return;
    }

    handleResult(values);
  };

  return (
    <TiktokFormComponent
      type={type}
      handleSubmit={handleSubmit}
      handleDelete={handleDelete}
      initialValues={initialValues}
      lang={lang}
      handleClose={handleClose}
    />
  );
}

TiktokFormContainer.defaultProps = {
  type: TYPES[0],
  initialValues: { URL: "" }
};

TiktokFormContainer.propTypes = {
  type: PropTypes.oneOf(TYPES).isRequired,
  handleResult: PropTypes.func,
  handleDelete: PropTypes.func,
  initialValues: PropTypes.object
};

export { TYPES };

export default TiktokFormContainer;
