import { createSlice } from "@reduxjs/toolkit";
import { REPORTING } from "modules/reporting/constants";

import {
    getProductData,

    getProductDetailData,
    
    getProductCategoryData,

    getProductCategoryDetailData,

    getProductBrandData,

    getProductBrandDetailData,

    getProductModelData,

    getProductModelDetailData,

    getProductListingData,

    getSuspendedListData,

} from "../action";

const initialState = {
    productDatalist: {},

    productDetailData: {},

    productCategoryDatalist: {},

    productCategoryDetailData: {},

    productBrandDatalist: {},

    productBrandDetailData: {},

    productModelDatalist: {},

    productModelDetailData: {},

    suspendedDatalist: {},
}

const reportingProductSlice = createSlice({
    name: REPORTING,
    initialState,
    reducers: {
        initReportingProductData: (state) => {
            state.productDatalist = initialState.productDatalist;
        },
        initReportingProductDetailData: (state) => {
            state.productDetailData = initialState.productDetailData;
        },
        iniReportingProductCategoryData: (state) => {
            state.productCategoryDatalist = initialState.productCategoryDatalist;
        },
        initReportingProductCategoryDetailData: (state) => {
            state.productCategoryDetailData = initialState.productCategoryDetailData;
        },
        initReportingProductBrandData: (state) => {
            state.productBrandDatalist = initialState.productBrandDatalist;
        },
        initReportingProductBrandDetailData: (state) => {
            state.productBrandDetailData = initialState.productBrandDetailData;
        },
        initReportingProductModelData: (state) => {
            state.productModelDatalist = initialState.productModelDatalist;
        },
        initReportingProductModelDetailData: (state) => {
            state.productModelDetailData = initialState.productModelDetailData;
        },
        initReportingSuspendedListData: (state) => {
            state.suspendedDatalist = initialState.suspendedDatalist;
        }
    },
    extraReducers : {
        // (a) get product data -- start
        // PRODUCT DATA -- START
        [getProductData.fulfilled]: (state, action) => {
            // action.payload.isEmpty = true;
            let data = action.payload.data;
            data.isSuccess = action.payload.success;
            data.noResult = (data.rows ? (data.rows.length ? false : true) : true);

            state.productDatalist = data;
        },
        [getProductData.rejected]: (state, action) => {
            state.productDatalist = {
                isSuccess: false,
                noResult: true,
                rows: [],
                total: 0,
            };
        },
        // PRODUCT DATA -- END

        // PRODUCT DETAIL DATA -- START
        [getProductDetailData.fulfilled]: (state, action) => {
            // action.payload.isEmpty = true;
            let data = action.payload.data;
            data.isSuccess = action.payload.success;
            data.noResult = false // TODO::change this later based on wei keat api response

            state.productDetailData = data;
        },
        [getProductDetailData.rejected]: (state, action) => {
            state.productDetailData = {
                isSuccess: false,
                noResult: true,
                rows: [],
                total: 0,
            };
        },
        // PRODUCT DETAIL DATA -- END

        // PRODUCT CATEGORY DATA -- START
        [getProductCategoryData.fulfilled]: (state, action) => {
            let data = action.payload.data;
            data.isSuccess = action.payload.success;
            data.noResult = (data.rows ? (data.rows.length ? false : true) : true);

            state.productCategoryDatalist = data;
        },
        [getProductCategoryData.rejected]: (state, action) => {
            state.productCategoryDatalist = {
                isSuccess: false,
                noResult: true,
                rows: [],
                total: 0,
            };
        },
        // PRODUCT CATEGORY DATA -- END

        // PRODUCT CATEGORY DETAIL -- START
        [getProductCategoryDetailData.fulfilled]: (state, action) => {
            let data = action.payload.data;
            data.isSuccess = action.payload.success;
            data.noResult = false;

            state.productCategoryDetailData = data;
        },
        [getProductCategoryDetailData.rejected]: (state, action) => {
            state.productCategoryDetailData = {
                isSuccess: false,
                noResult: true,
                rows: [],
                total: 0,
            };
        },
        // PRODUCT CATEGORY DETAIL -- END

        // PRODUCT BRAND DATA -- START
        [getProductBrandData.fulfilled]: (state, action) => {
            let data = action.payload.data;
            data.isSuccess = action.payload.success;
            data.noResult = (data.rows ? (data.rows.length ? false : true) : true);

            state.productBrandDatalist = data;
        },
        [getProductBrandData.rejected]: (state, action) => {
            state.productBrandDatalist = {
                isSuccess: false,
                noResult: true,
                rows: [],
                total: 0,
            };
        },
        // PRODUCT BRAND DATA -- END

        // PRODUCT BRAND DETAIL -- START
        [getProductBrandDetailData.fulfilled]: (state, action) => {
            let data = action.payload.data;
            data.isSuccess = action.payload.success;
            data.noResult = false;

            state.productBrandDetailData = data;
        },
        [getProductBrandDetailData.rejected]: (state, action) => {
            state.productBrandDetailData = {
                isSuccess: false,
                noResult: true,
                rows: [],
                total: 0,
            };
        },
        // PRODUCT BRAND DETAIL -- END

        // PRODUCT MODEL DATA -- START
        [getProductModelData.fulfilled]: (state, action) => {
            let data = action.payload.data;
            data.isSuccess = action.payload.success;
            data.noResult = (data.rows ? (data.rows.length ? false : true) : true);

            state.productModelDatalist = data;
        },
        [getProductModelData.rejected]: (state, action) => {
            state.productModelDatalist = {
                isSuccess: false,
                noResult: true,
                rows: [],
                total: 0,
            };
        },
        // PRODUCT MODEL DATA -- END

        // PRODUCT MODEL DETAIL -- START
        [getProductModelDetailData.fulfilled]: (state, action) => {
            let data = action.payload.data;
            data.isSuccess = action.payload.success;
            data.noResult = false;

            state.productModelDetailData = data;
        },
        [getProductModelDetailData.rejected]: (state, action) => {
            state.productModelDetailData = {
                isSuccess: false,
                noResult: true,
                rows: [],
                total: 0,
            };
        },
        // PRODUCT MODEL DETAIL -- END

        // PRODUCT LISTING DATA -- START
        [getProductListingData.fulfilled]: (state, action) => {
            let data = action.payload.data;
            data.isSuccess = action.payload.success;
            data.noResult = (data.rows ? (data.rows.length ? false : true) : true);

            state.productDatalist = data;
        },
        [getProductListingData.rejected]: (state, action) => {
            state.productDatalist = {
                isSuccess: false,
                noResult: true,
                rows: [],
                total: 0,
            };
        },
        // PRODUCT LISTING DATA -- END

        // SUSPENDED LIST DATA -- START
        [getSuspendedListData.fulfilled]: (state, action) => {
            let data = action.payload.data;
            data.isSuccess = action.payload.success;
            data.noResult = (data.rows.length === 0);

            state.suspendedDatalist = data;
        },
        [getSuspendedListData.rejected]: (state, action) => {
            state.suspendedDatalist = {
                isSuccess: false,
                noResult: true,
                rows: [],
                total: 0,
            };
        },
        // SUSPENDED LIST DATA -- END
    }
})

export const {
    initReportingProductData,
    initReportingProductDetailData,
    iniReportingProductCategoryData,
    initReportingProductCategoryDetailData,
    initReportingProductBrandData,
    initReportingProductBrandDetailData,
    initReportingProductModelData,
    initReportingProductModelDetailData,
    initReportingSuspendedListData
} = reportingProductSlice.actions;

export const reportingProductReducer = reportingProductSlice.reducer;