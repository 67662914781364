import axios from "axios";

export function getStockTakeItemBoxProductList(parameters) {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `/distributor/admin/api/v1/stocktake/${parameters.id}/box/${parameters.box_uuid}`,
        {
          params: {
            search: parameters.search,
            status: parameters.status,
          },
        }
      )
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data));
  });
}
