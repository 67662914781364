import React, { useRef } from "react";
import {
  makeStyles,
  Box,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import { getLang } from "app/feature/constants";
import { Close as CloseIcon, Check as CheckIcon } from "@material-ui/icons";
import Input from "components/input/inputTextField";

const useStyle = makeStyles((theme) => ({
  actionButton: {
    borderRadius: 0,
    padding: "4px",
    backgroundColor: "#fff",
    border: "1px solid #d7d7d7",
  },
}));

export default function RedirectUrlTextFieldComponent({
  value,
  handleSubmit,
  handleCancel,
  lang,
  isSubmitting,
}) {
  const classes = useStyle();
  const textRef = useRef();

  return (
    <Box display="flex">
      <Input
        inputRef={textRef}
        hiddenLabel
        variant="filled"
        size="small"
        placeholder={getLang(lang, "placeholder.REDIRECT_TO_URL")}
        margin="none"
        InputProps={{
          disableUnderline: true,
          margin: "none",
          style: {
            borderRadius: "4px",
            backgroundColor: "#fff",
            border: "thin solid #eceff0",
            fontSize: "0.75rem",
            minWidth: 200,
          },
        }}
        style={{ width: "100%" }}
        defaultValue={value}
        // onChange={({ target: { value } }) => {
        //   textRef.current = value;
        // }}
      />
      <Box display="flex" alignItems="center">
        {isSubmitting ? (
          <Box ml={1}>
            <CircularProgress size={15} />
          </Box>
        ) : (
          <>
            <IconButton
              className={classes.actionButton}
              onClick={() => handleSubmit(textRef.current.value)}
            >
              <CheckIcon
                style={{
                  fontSize: "1.3rem",
                  color: "#32D583",
                }}
              />
            </IconButton>
            <IconButton
              className={classes.actionButton}
              onClick={() => handleCancel()}
            >
              <CloseIcon style={{ fontSize: "1.3rem" }} />
            </IconButton>
          </>
        )}
      </Box>
    </Box>
  );
}
