import axios from "axios";

const get = ({ ...parameters }) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `/serialnumber/api/v1/warranty-setting/${parameters.id}/warranty-extensions`,
        {
          params: {
            draw: parameters.draw,
            length: parameters.length,
            start: parameters.start,
            column: parameters.column,
            order: parameters.order,
            search: parameters.search
          }
        }
      )
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

const getWarrantyExtension = {
  get
};

export default getWarrantyExtension;
