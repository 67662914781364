import axios from "axios";

const getProductDropdown = async search => {
    const body = search

    const response = await axios.get('/serialnumber/api/v1/product/get-dropdown-list', { params: body });

    return response.data;
};

export default getProductDropdown;