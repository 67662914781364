import React from "react";
import { makeStyles, Box } from "@material-ui/core";

import backgroundMobileSignUp from "../../../utils/image/backgroundMobile-signUp.png";

const useStyle = makeStyles(theme => ({
    root: {
        minHeight: "100vh",
        width: "100%",
        flexFlow: "column nowrap",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",

        [theme.breakpoints.up("md")]: {
            backgroundColor: "#E5E5E5"
        }
    },
    mobileRoot: {
        minHeight: "100vh",
        width: "100%",
        display: "flex",
        backgroundColor: "#E5E5E5",
        justifyContent: "center"
    },
    top: {
        position: "fixed",
        width: "100%"
    }
}));

export default function SignLayoutComponent({ children, isMobile }) {
    const classes = useStyle();
    return (
        <div>
            { isMobile ?
                <Box className={classes.mobileRoot}>
                    <Box className={classes.top}>
                        <img src={backgroundMobileSignUp} alt="sign up mobile background" width="100%" />
                    </Box>
                    {children}
                </Box>
                :
                <Box className={classes.root}>
                    {children}
                </Box>
            }
        </div>
    )
}