import axios from "axios"

const url = "/serialnumber/api/v1/product/bulk-list"

const getBulkEditProductList = (parameters) => {
  return new Promise((resolve, reject) => {
    axios
      .post(url, parameters, { timeout: 15000 })
      .then(response => resolve(response.data))
      .catch(error => {
        return reject(error.response.data)
      })
  })
}

export default getBulkEditProductList
