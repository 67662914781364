import React, { useState, useRef } from "react";
import TabComponent from "./tab.component";
import { setType, setRegisterForm } from "modules/lucky-draw/redux";
import { useDispatch, useSelector } from "react-redux";
import {
  updateLuckyDrawDetail,
  changeLuckyDrawProgress,
  fetchAllLuckyDrawPrize,
  setLiveDraw
} from "modules/lucky-draw/redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { useHistory } from "react-router-dom";
import ConfirmationDialogComponent from "components/dialog/confirmation";
import { LIVE_DRAW } from "app/config/route";
import { LUCKY_DRAW_EDIT } from "lib/constants/accessRights";
import { getLang } from "app/feature/constants";
import { addAlert } from "modules/notification";
import { hideBackdrop } from "modules/backdrop";

function TabContainer() {
  const history = useHistory();
  const dispatch = useDispatch();
  const hasLuckyDrawEditAccessRight = useSelector(state => state.profile.accessRights.includes(LUCKY_DRAW_EDIT))
  const [activeIndex, setActiveIndex] = useState(0);
  const [confirmationDialogModal, setConfirmationDialogModal] = useState(false);
  const settingType = useSelector((state) => state.luckyDraw.settingType);
  const registerType = useSelector((state) => state.luckyDraw.registerType);
  const isFormEditing = useSelector((state) => state.luckyDraw.settingFormEditing)
  const progress = useSelector(
    (state) => state.luckyDraw.LuckyDrawDetail.progress
  );

  const participantRegistrationForm = useSelector(
    (state) => state.luckyDraw.participantRegistrationForm
  );

  const LuckyDrawDetail = useSelector(
    (state) => state.luckyDraw.LuckyDrawDetail
  );
  const lang = useSelector(state => state.constant.languages);
  const language = useSelector(state => state.profile.language);

  const [luckyDrawImageFile, setLuckyDrawImageFile] = useState(null);
  const [tempRegForm, setTempRegForm] = useState([]);

  const tabLabels = [getLang(lang, "label.LUCKY_DRAW_DETAILS"), getLang(lang, "label.PARTICIPANT_REGISTRATION_FORM")];
  const formRef = useRef();

  const changeToUpdate = () => {
    setTempRegForm(participantRegistrationForm);
    const updateInfo = {
      index: activeIndex,
      type: "edit"
    };
    dispatch(setType(updateInfo));
  };

  const changeBackView = () => {
    const updateInfo = {
      index: activeIndex,
      type: "view"
    };
    dispatch(setRegisterForm(tempRegForm))
    dispatch(setType(updateInfo));
  };

  const handleChange = (event, index) => {
    setActiveIndex(index);
  };

  const handleUpdate = async () => {

    const participantregformdata = formRef.current.values.form;

    const uniqueVariantNames = new Set();

    for (const property of participantregformdata) {
      if (property.variantName === undefined || property.variantName.trim() === "") {
        await dispatch(
          addAlert({
            severity: "error",
            message: getLang(lang, "message.warning.FILL_IN_REQUIRED_FIELD")
          })
        );
        dispatch(hideBackdrop());
        return;
      }

      if (uniqueVariantNames.has(property.variantName)) {
        await dispatch(
          addAlert({
            severity: "error",
            message: getLang(lang, "message.warning.DUPLICATE_VARIANT_NAME")
          })
        );
        dispatch(hideBackdrop());
        return;
      }

      uniqueVariantNames.add(property.variantName);

      if (property.type === 'multipleChoice' || property.type==="dropdownlist" || property.type==="multipleChoiceMultiSelect") {

        for (const choices of property.choice) {
          if (choices.trim() === "" || choices === undefined) {
            await dispatch(
              addAlert({
                severity: "error",
                message: getLang(lang, "message.warning.CHOICES_CANNOT_BE_EMPTY")
              })
            );
            dispatch(hideBackdrop());
            return;
          }
        }

        const uniqueChoices = new Set(property.choice);
        if (uniqueChoices.size !== property.choice.length) {
          await dispatch(
            addAlert({
              severity: "error",
              message: getLang(lang, "message.warning.CHOICES_MUST_BE_UNIQUE")
            })
          );
          dispatch(hideBackdrop());
          return;
        }

        if (property.choice.length < 2) {
          await dispatch(
            addAlert({
              severity: "error",
              message: getLang(lang, "message.warning.MUST_HAVE_AT_LEAST_TWO_CHOICES")
            })
          );
          dispatch(hideBackdrop());
          return;
        }
      }

    }

    const update = {
      LuckyDrawDetail: {
        id: LuckyDrawDetail.id,
      },
      metadata: participantregformdata
    };
    dispatch(updateLuckyDrawDetail(update))
      .then(unwrapResult)
      .then((r) => {
        const updateInfo = {
          index: activeIndex,
          type: "view"
        };
        dispatch(setType(updateInfo));
      });
  };

  const handleLiveDraw = (id) => {
    setConfirmationDialogModal(true);
  };

  async function handleLiveProgress() {
    if (LuckyDrawDetail.progress !== "published") {
      await dispatch(
        changeLuckyDrawProgress({
          id: LuckyDrawDetail.id,
          progress: "published",
        })
      ).finally(() => {
        setConfirmationDialogModal(false);
      });
    }

    const fetchPrizeTableInfo = {
      luckyDrawId: LuckyDrawDetail.id,
      column: "order",
      order: "asc"
    };

    dispatch(fetchAllLuckyDrawPrize(fetchPrizeTableInfo))
      .then(unwrapResult)
      .then(res => dispatch(setLiveDraw(res)))

    history.push(LIVE_DRAW)
  }

  return (
    <>
      <TabComponent
        hasLuckyDrawEditAccessRight={hasLuckyDrawEditAccessRight}
        handleChange={handleChange}
        activeIndex={activeIndex}
        tabLabels={tabLabels}
        settingType={settingType}
        registerType={registerType}
        changeBackView={changeBackView}
        changeToUpdate={changeToUpdate}
        handleUpdate={handleUpdate}
        luckyDrawImageFile={luckyDrawImageFile}
        setLuckyDrawImageFile={setLuckyDrawImageFile}
        progress={progress}
        handleLiveDraw={handleLiveDraw}
        formRef={formRef}
        lang={lang}
        language={language}
        isFormEditing={isFormEditing}
      />
      <ConfirmationDialogComponent
        isOpen={confirmationDialogModal}
        handleClose={() => setConfirmationDialogModal(false)}
        handleProceed={handleLiveProgress}
        type={"success"}
        remark={getLang(lang, "paragraph.PUBLISH_ILD_REMARK")}
        description={getLang(lang, "paragraph.PLEASE_CONFIRM")}
        title={getLang(lang, "paragraph.PUBLISH_LD_QUESTION")}
      />
    </>
  );
}

export default TabContainer;
