import React from "react";
import DraggableCardComponent from "./draggableCard.component";
import PropTypes from "prop-types";

function DraggableCardContainer({ id, index, children, title }) {
  return (
    <DraggableCardComponent
      id={id}
      index={index}
      children={children}
      title={title}
    />
  );
}

DraggableCardContainer.propTypes = {
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  title: PropTypes.string
};

export default DraggableCardContainer;
