import React from "react";
import {
  makeStyles,
  Box,
  Button,
  Typography
} from '@material-ui/core';
import { Formik } from "formik";
import Input from "components/input/inputTextField";
import SearchableSelect from 'components/select/searchableSelect';
import ActionSelect from '../../select/actionSelect';
import CategoryListTable from '../../table/categoryListTable';
import { getLang } from "app/feature/constants";


const useStyle = makeStyles((theme) => ({
  searchContainer: {
    backgroundColor: theme.palette.background.paper,
    display: "grid",
    gridTemplateColumns: "5fr 2fr 1fr 1fr",
    columnGap: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1)
    }
  },
  button: {
    display: "flex",
    alignItems: "center",
    padding: 0,
    minWidth: 0,
    marginLeft: theme.spacing(1)
  },
  inputRoot: {
    paddingTop: "10px !important",
    paddingBottom: "8px !important",
    paddingLeft: "12px !important",
    paddingRight: "12px !important",
    fontWeight: 550,
    fontSize: "0.875rem"
  },
  paperRoot: {
    width: "217px",
  }
}));

export default function CategoryListPanelComponent({
  hasProductCategoryEditAccessRight,
  hasProductCategoryDeleteAccessRight,
  handleSearch,
  isFetching,
  isSelected,
  isSelectAll,
  selectedIds,
  paginationOptions,
  selectedCount,
  handleChangePage,
  handleChangeRowsPerPage,
  handleReload,
  page,
  search,
  statusFilter,
  totalFiltered,
  data,
  rowsPerPage,
  handleCategoryStatus,
  handleUpdateCategories,
  isLoadingCategoryListError,
  isQuickEdit,
  handleQuickEdit,
  name,
  status,
  updateStatus,
  updateName,
  handleDeleteCategory,
  handleSelectCategory,
  handleSelectAvailable,
  handleSelectAll,
  handleDeselectAll,
  handleChangeCategoriesStatus,
  handleCreateCategory,
  lang
}) {
  const classes = useStyle();

  return (
    <>
      <Formik
        initialValues={{
          category: "",
          status: "",
        }}
        onSubmit={handleSearch}
        onReset={handleSearch}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <Box p={2} mb={2} className={classes.searchContainer}>
              <Input
                value={search}
                hiddenLabel
                variant="filled"
                size="small"
                placeholder={getLang(lang,"placeholder.SEARCH_CATEGORY")}
                margin="none"
                InputProps={{
                  disableUnderline: true,
                  margin: "none",
                  style: {
                    borderRadius: "4px",
                    backgroundColor: "#ECEFF0"
                  },
                  classes: { input: classes.inputRoot }
                }}
                {...formik.getFieldProps("search")}
              />
              <SearchableSelect
                value={formik.values.status}
                isEmpty={formik.values.status === ""}
                handleChange={(value) => {
                  formik.setFieldValue("status", value)
                }}
                placeholder={getLang(lang,"placeholder.SELECT_STATUS")}
                disabled={isFetching}
                searchable={false}
                style={{ height: "34px" }}
                panelStyle={{ width: "249px" }}
                PaperProps={{
                  classes: { root: classes.paperRoot }
                }}
                dropdownItem={[getLang(lang,"label.ENABLE"), getLang(lang,"label.DISABLE")]}
                emptyMessage={getLang(lang,"paragraph.STATUS_NOT_FOUND")}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left"
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left"
                }}
              />
              <Button
                variant='text'
                color='secondary'
                size='small'
                disabled={isFetching}
                disableElevation
                onClick={() => {
                  formik.setFieldValue("search", "");
                  formik.setFieldValue("status", "");
                  if (search !== "" || statusFilter !== "") {
                    formik.handleSubmit();
                  }
                }}
              >
                {getLang(lang,"label.CLEAR")}
              </Button>
              <Button
                type="submit"
                size='small'
                color="primary"
                variant="contained"
                disabled={isFetching}
                disableElevation
              >
                {getLang(lang,"label.SEARCH")}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
      <Box style={{ display: "flex", justifyContent: "space-between", paddingBottom: "12px" }}>
        <Box style={{ display: "flex", alignItems: "center" }}>
          {selectedCount !== 0 ? (
            (isSelectAll && selectedCount === totalFiltered) ? (
              <Typography variant='body2' style={{color:"#FDB022"}}>{getLang(lang,"paragraph.SELECTED_ALL_CATEGORY", {count: selectedCount})}</Typography>
            ) : (
              <Typography variant='body2' color='primary'>{(selectedCount > 1) ? getLang(lang, "paragraph.SELECTED_CATEGORIES_COUNT", { count: selectedCount })
              : getLang(lang, "paragraph.SELECTED_CATEGORY_COUNT", { count: selectedCount })}</Typography>
            )
          ) : (
            ""
          )}
        </Box>
        <Box style={{ display: "flex" }}>
          <ActionSelect
            label={getLang(lang, 'label.ACTION')}
            dropdownItem={[getLang(lang,"label.ENABLE_SELECTED"), getLang(lang,"label.DISABLE_SELECTED")]}
            handleChange={(value) => handleChangeCategoriesStatus(value)}
            disabled={isFetching || !isSelected}
            isFetching={isFetching}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            style={{
              width: "100px",
              borderRadius: "8px",
              backgroundColor: "#ffffff",
              border: "1px solid #D0D5DD"
            }}
          />
        </Box>
      </Box>
      <Box>
        <CategoryListTable
          hasProductCategoryEditAccessRight={hasProductCategoryEditAccessRight}
          hasProductCategoryDeleteAccessRight={hasProductCategoryDeleteAccessRight}
          data={data}
          isFetching={isFetching}
          isLoadingCategoryListError={isLoadingCategoryListError}
          isSelectAll={isSelectAll}
          selectedIds={selectedIds}
          paginationOptions={paginationOptions}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleReload={handleReload}
          page={page}
          search={search}
          totalFiltered={totalFiltered}
          rowsPerPage={rowsPerPage}
          handleCategoryStatus={handleCategoryStatus}
          isQuickEdit={isQuickEdit}
          handleQuickEdit={handleQuickEdit}
          handleUpdateCategories={handleUpdateCategories}
          name={name}
          status={status}
          updateName={updateName}
          updateStatus={updateStatus}
          handleDeleteCategory={handleDeleteCategory}
          handleSelectCategory={handleSelectCategory}
          handleSelectAvailable={handleSelectAvailable}
          handleSelectAll={handleSelectAll}
          handleDeselectAll={handleDeselectAll}
          handleCreateCategory={handleCreateCategory}
          lang={lang}
        />
      </Box>

    </>
  )
} 