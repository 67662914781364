import React from "react"
import ProductTraceabilityTimelineComponent from './productTraceabilityTimeline.component'

function ProductTraceabilityTimelineContainer({
  traceabilityList,
  serialNumber,
  updateRecordFocus,
  recordFocus,
  language,
  lang
}) {
  
  return (
    <ProductTraceabilityTimelineComponent 
      traceabilityList={traceabilityList} 
      serialNumber={serialNumber}
      updateRecordFocus={updateRecordFocus}
      recordFocus={recordFocus}
      language={language}
      lang={lang}
    />
  )
}

export default ProductTraceabilityTimelineContainer