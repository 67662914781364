import React from "react";
import PreviewConsumerPagePanelComponent from "./previewConsumerPagePanel.component";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';

function PreviewConsumerPagePanelContainer({
  colorPrimary,
  colorSecondary,
  bgType,
  bgColor,
  bgImage,
  logo,
  bannerImage,
}) {

  const theme = useTheme();
  const mdWidth = useMediaQuery(theme.breakpoints.down("md"));
  const lang = useSelector(state => state.constant.languages)

  return (
    <PreviewConsumerPagePanelComponent
      colorPrimary={colorPrimary}
      colorSecondary={colorSecondary}
      bgType={bgType}
      bgColor={bgColor}
      bgImage={bgImage}
      logo={logo}
      mdWidth={mdWidth}
      lang={lang}
      bannerImage={bannerImage}
    />
  );
}

PreviewConsumerPagePanelContainer.propTypes = {
  colorPrimary: PropTypes.string.isRequired,
  colorSecondary: PropTypes.string.isRequired,
  bgType: PropTypes.string.isRequired,
  bgColor: PropTypes.string,
  bgImage: PropTypes.string
};

const mapStateToProps = state => ({
  logo: state.account.logoImg
});

export default connect(mapStateToProps)(PreviewConsumerPagePanelContainer);
