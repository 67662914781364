import React from "react";
import {
  makeStyles,
  Typography,
  Box,
  Paper,
  IconButton,
  Divider,
  Button,
  useTheme,
  FormHelperText,
  CircularProgress,
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import InputTextField from "components/input/inputTextField";
import { CardElement } from "@stripe/react-stripe-js";
import CountrySelect from "components/select/countrySelect";
import { getLang } from "app/feature/constants";

const style = (theme) => ({
  infoContainer: {
    width: 600,
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    }
  },
  header: {
    padding: "4px 0px",
    paddingLeft: theme.spacing(3),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  fieldContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 3fr",
    columnGap: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  cardField: {
    borderRadius: "4px",
    padding: theme.spacing(1.5, 2),
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "1rem",
    backgroundColor: "#ECEFF0",
    transition: "background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms"
  }
});

const useStyles = makeStyles(style);

const GetCardElementOptions = () => {
  const theme = useTheme();
  return {
    hidePostalCode: true,
    style: {
      base: {
        color: "rgba(0, 0, 0, 0.87)",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4"
        }
      },
      invalid: {
        color: theme.palette.error.main,
        iconColor: theme.palette.error.main
      }
    }
  };
};

export default function AddCreditCardFormComponent({
  handleCard,
  error,
  handleClose,
  handleSubmit,
  isAddingPaymentMethod,
  countries,
  lang
}) {
  const classes = useStyles();

  return (
    <Paper elevation={0}>
      <div className={classes.header}>
        <Typography
          variant="subtitle2"
          color="primary"
          style={{ fontWeight: "bold" }}
        >
          {getLang(lang, "label.ADD_CARD")}
        </Typography>
        <IconButton color="primary" onClick={handleClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </div>

      <Divider />

      {isAddingPaymentMethod ? (
        <Box
          className={classes.infoContainer}
          py={6}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress color="primary" />
          <Box mt={2}>
            <Typography variant="body2" color="textSecondary">
              {getLang(lang, "label.ADDING_NEW_CARD")}
            </Typography>
          </Box>
        </Box>
      ) : (
        <Formik
          initialValues={{
            name: "",
            email: "",
            addressLine1: "",
            addressLine2: "",
            city: "",
            state: "",
            postCode: "",
            country: countries.find((country) => country.code === "MY").code
          }}
          validationSchema={Yup.object({
            name: Yup.string().trim().required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
            email: Yup.string().email(getLang(lang, "message.error.FORM_VALIDATE_EMAIL")).required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
            addressLine1: Yup.string().trim().required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
            addressLine2: Yup.string().trim(),
            city: Yup.string().trim().required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
            state: Yup.string().trim().required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
            postCode: Yup.string()
            .trim()
            .max(10, getLang(lang, "message.error.FORM_VALIDATE_MAX", {value: 10}))
            .required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED"))
            .matches(/^[0-9a-zA-Z-_ ]*$/, getLang(lang,"message.error.INVALID_POSTCODE")),
          })}
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <Box className={classes.infoContainer}>
                <Box pt={2} pb={2} px={3}>
                  <Typography
                    variant="body2"
                    style={{ fontWeight: "bold" }}
                    gutterBottom
                    color="primary"
                  >
                    {getLang(lang, "label.BILLING_INFORMATION")}
                  </Typography>
                  <Box px={{ xs: 2, sm: 5 }} pt={1}>
                    {[
                      {
                        key: "name",
                        label: getLang(lang, "label.NAME"),
                        placeholder: getLang(lang, "placeholder.ENTER_NAME")
                      },
                      {
                        key: "email",
                        label: getLang(lang, "label.EMAIL"),
                        placeholder: getLang(lang, "placeholder.ENTER_EMAIL")
                      },
                      {
                        key: "addressLine1",
                        label: getLang(lang, "label.ADDRESS_LINE_1"),
                        placeholder: getLang(lang, "placeholder.ENTER_ADDRESS_LINE_1")
                      },
                      {
                        key: "addressLine2",
                        label: getLang(lang, "label.ADDRESS_LINE_2"),
                        placeholder: getLang(lang, "placeholder.ENTER_ADDRESS_LINE_2")
                      },
                      {
                        key: "city",
                        label: getLang(lang, "label.CITY"),
                        placeholder: getLang(lang, "placeholder.ENTER_CITY")
                      },
                      {
                        key: "state",
                        label: getLang(lang, "label.STATE"),
                        placeholder: getLang(lang, "placeholder.ENTER_STATE")
                      },
                      {
                        key: "postCode",
                        label: getLang(lang, "label.POST_CODE"),
                        placeholder: getLang(lang, "placeholder.ENTER_POSTCODE")
                      }
                    ].map((field, index) => (
                      <Box key={index} className={classes.fieldContainer}>
                        <Box display="flex" alignItems="center">
                          <Typography
                            variant="body2"
                            color="textSecondary"
                          >{`${field.label}`}&nbsp; {field.key === "addressLine2" ? null : <Typography style={{ color: "#DE350B", display: 'inline-block' }}>*</Typography>} </Typography>
                        </Box>
                        <InputTextField
                          variant="filled"
                          size="small"
                          margin="none"
                          type={field.type || "text"}
                          fullWidth
                          placeholder={field.placeholder}
                          {...formik.getFieldProps(field.key)}
                          error={
                            formik.touched[field.key] &&
                              formik.errors[field.key]
                              ? true
                              : false
                          }
                          helperText={ErrorMessage({ name: field.key })}
                          disabled={formik.isSubmitting}
                        />
                      </Box>
                    ))}
                    <Box className={classes.fieldContainer}>
                      <Box display="flex" alignItems="center">
                        <Typography
                          variant="body2"
                          color="textSecondary"
                        >{getLang(lang, 'label.COUNTRY')}&nbsp;<Typography style={{ color: "#DE350B", display: 'inline-block' }}>*</Typography> </Typography>
                      </Box>
                      <CountrySelect
                        textFieldProps={{
                          required: true,
                          fullWidth: true,
                          variant: "filled",
                          size: "small",
                          margin: "dense",
                          error: formik.errors.country && formik.touched.country ? true : false,
                          helperText: ErrorMessage({ name: "country" }),
                          placeholder: getLang(lang, "placeholder.ENTER_COUNTRY")
                        }}
                        formProps={{
                          ...formik.getFieldProps("country"),
                          value: formik.values.country,
                          onChange: (e, newValue) => {
                            formik.setFieldValue("country", newValue);
                          },
                          disabled: formik.isSubmitting,
                          disableClearable: true,
                        }}
                        type="countryOnly"
                      />
                    </Box>
                  </Box>
                </Box>

                <Divider />
                <Box pt={2} pb={error ? 4 : 2} px={3}>
                  <Typography
                    variant="body2"
                    style={{ fontWeight: "bold" }}
                    gutterBottom
                    color="primary"
                  >
                    {getLang(lang, "label.CARD_DETAILS")}
                  </Typography>
                  <Box px={{ xs: 2, sm: 5 }} pt={2} pb={2}>
                    <Box height={"40px"}>
                      <CardElement
                        className={classes.cardField}
                        options={GetCardElementOptions()}
                        onChange={handleCard}
                      />
                      <FormHelperText
                        error={error ? true : false}
                        style={{ margin: "4px 14px 0px" }}
                      >
                        {error ? getLang(lang, 'message.error.'+error) : null}
                      </FormHelperText>
                    </Box>
                  </Box>
                </Box>
                <Divider />

                <Box px={3} py={2} display="flex" justifyContent="flex-end">
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    disableElevation
                    type="submit"
                    disabled={formik.isSubmitting}
                  >
                    {getLang(lang, "label.ADD_CARD")}
                  </Button>
                </Box>
              </Box>
            </form>
          )}
        </Formik>
      )}
    </Paper>
  );
}
