import getIndustries from './getIndustries.api'
import getCountry from "./getCountry.api"
import { getLanguages, getApiLanguages } from "./getLanguages.api"
import { getFallbackLanguage, getFallbackApiLanguage } from "./getFallbackLanguage.api"

const constantsApi = {
    getIndustries,
    getCountry,
    getLanguages,
    getApiLanguages,
    getFallbackLanguage,
    getFallbackApiLanguage
};

export default constantsApi;
