import React from "react";
import {
  makeStyles,
  Box,
  Typography,
  Button,
  FormHelperText,
  Dialog,
  DialogTitle,
  Divider,
  IconButton,
  useTheme,
  useMediaQuery,
  CircularProgress,
  MenuItem
} from "@material-ui/core";
import Input from "components/input/inputTextField";
import { CardElement } from "@stripe/react-stripe-js";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles(theme => ({
  root: {
    width: 500,
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    }
  },
  cardField: {
    borderTopRightRadius: theme.spacing(0.5),
    borderTopLeftRadius: theme.spacing(0.5),
    padding: theme.spacing(1.5, 2),
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "1rem",
    backgroundColor: "rgba(0, 0, 0, 0.09)",
    transition: "background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.13)"
    }
  },
  fieldContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 3fr",
    columnGap: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

const GetCardElementOptions = () => {
  const theme = useTheme();
  return {
    hidePostalCode: true,
    style: {
      base: {
        color: "rgba(0, 0, 0, 0.87)",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4"
        }
      },
      invalid: {
        color: theme.palette.error.main,
        iconColor: theme.palette.error.main
      }
    }
  };
};

export default function AddCardFormComponent({
  initialValues,
  handleSubmit,
  handleCard,
  error,
  isFormOpen,
  handleClose,
  isAddingPaymentMethod,
  countries,
  lang
}) {
  const classes = useStyle();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Dialog
      open={isFormOpen}
      onClose={handleClose}
      disableBackdropClick
      aria-labelledby="form-dialog-title"
      fullScreen={isMobile}
    >
      <DialogTitle disableTypography id="form-dialog-title">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
            {getLang(lang, 'label.ADD_NEW_CARD')}
          </Typography>
          <IconButton
            onClick={handleClose}
            edge="end"
            size="small"
            aria-label="close dialog"
          >
            <CloseRoundedIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider />

      {isAddingPaymentMethod ? (
        <Box
          className={classes.root}
          py={6}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress color="primary" />
          <Box mt={2}>
            <Typography variant="body2" color="textSecondary">
              {getLang(lang, 'label.ADDING_NEW_CARD')}
            </Typography>
          </Box>
        </Box>
      ) : (
        <Formik
          initialValues={{
            name: initialValues.name,
            email: initialValues.email,
            address: initialValues.address,
            city: initialValues.city,
            state: initialValues.state,
            postCode: initialValues.postCode,
            country: initialValues.country
          }}
          validationSchema={Yup.object({
            name: Yup.string().trim().required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
            email: Yup.string().email(getLang(lang, "message.error.FORM_VALIDATE_EMAIL")).required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
            address: Yup.string().trim().required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
            city: Yup.string().trim().required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
            state: Yup.string().trim().required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
            postCode: Yup.string()
              .trim()
              .max(10, getLang(lang, "message.error.FORM_VALIDATE_MAX", { value: 10 }))
              .required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED"))
              .matches(/^[0-9a-zA-Z-_ ]*$/, getLang(lang, "message.error.INVALID_POSTCODE")),
          })}
          onSubmit={handleSubmit}
        >
          {formik => (
            <form onSubmit={formik.handleSubmit}>
              <Box className={classes.root}>
                <Box pt={1} pb={1.5} px={3}>
                  <Typography variant="body2" gutterBottom>
                    {getLang(lang, 'label.BILLING_INFORMATION')}
                  </Typography>
                  <Box px={{ xs: 2, sm: 5 }}>
                    {[
                      {
                        key: "name",
                        label: getLang(lang, 'label.NAME'),
                        placeholder: getLang(lang, 'placeholder.ENTER_NAME')
                      },
                      {
                        key: "email",
                        label: getLang(lang, 'label.EMAIL'),
                        placeholder: getLang(lang, 'placeholder.ENTER_EMAIL')
                      },
                      {
                        key: "address",
                        label: getLang(lang, 'label.ADDRESS'),
                        fullSpan: true,
                        placeholder: getLang(lang, 'placeholder.ENTER_ADDRESS_HERE')
                      },
                      {
                        key: "state",
                        label: getLang(lang, 'label.STATE'),
                        placeholder: getLang(lang, 'placeholder.ENTER_STATE')
                      },
                      {
                        key: "city",
                        label: getLang(lang, 'label.CITY'),
                        placeholder: getLang(lang, 'placeholder.ENTER_CITY')
                      },
                      {
                        key: "postCode",
                        label: getLang(lang, 'label.POST_CODE'),
                        placeholder: getLang(lang, 'placeholder.ENTER_POST_CODE_HERE')
                      }
                    ].map((field, index) => (
                      <Box key={index} className={classes.fieldContainer}>
                        <Box display="flex" alignItems="center">
                          <Typography
                            variant="body2"
                            color="textSecondary"
                          >{`${field.label} `}</Typography> <Typography style={{ color: "#DE350B", display: 'inline-block' }}>*</Typography>
                        </Box>
                        <Input
                          variant="filled"
                          size="small"
                          margin="none"
                          type={field.type || "text"}
                          fullWidth
                          placeholder={field.placeholder}
                          {...formik.getFieldProps(field.key)}
                          error={
                            formik.touched[field.key] &&
                              formik.errors[field.key]
                              ? true
                              : false
                          }
                          helperText={ErrorMessage({ name: field.key })}
                          disabled={formik.isSubmitting}
                        />
                      </Box>
                    ))}
                    <Box className={classes.fieldContainer}>
                      <Box display="flex" alignItems="center">
                        <Typography
                          variant="body2"
                          color="textSecondary"
                        >{getLang(lang, 'label.COUNTRY')}  <Typography style={{ color: "#DE350B", display: 'inline-block' }}>*</Typography></Typography>
                      </Box>
                      <Input
                        variant="filled"
                        select
                        required
                        name="country"
                        size="small"
                        margin="none"
                        hiddenLabel
                        {...formik.getFieldProps("country")}
                        error={
                          formik.touched.country && formik.errors.country
                            ? true
                            : false
                        }
                        helperText={ErrorMessage({ name: "country" })}
                      >
                        {countries.map((option, index) => (
                          <MenuItem key={index} value={option.code}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </Input>
                    </Box>
                  </Box>
                </Box>

                <Divider />
                <Box pt={1} pb={error ? 4 : 2} px={3}>
                  <Typography variant="body2" gutterBottom>
                    {getLang(lang, 'label.CARD_DETAILS')}
                  </Typography>
                  <Box px={{ xs: 2, sm: 5 }}>
                    <Box height={"40px"}>
                      <CardElement
                        className={classes.cardField}
                        options={GetCardElementOptions()}
                        onChange={handleCard}
                      />
                      <FormHelperText
                        error={error ? true : false}
                        style={{ margin: "4px 14px 0px" }}
                      >
                        {error}
                      </FormHelperText>
                    </Box>
                  </Box>
                </Box>
                <Divider />

                <Box px={3} py={2} display="flex" justifyContent="flex-end">
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    disableElevation
                    type="submit"
                    disabled={formik.isSubmitting}
                  >
                    {getLang(lang, 'label.ADD_CARD')}
                  </Button>
                </Box>
              </Box>
            </form>
          )}
        </Formik>
      )}
    </Dialog>
  );
}
