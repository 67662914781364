import React from "react"
import ProfilePanelComponent from "./profilePanel.component"

function ProfilePanelContainer({ consumer, lang, language}) {
  return (
    <>
      <ProfilePanelComponent consumer={consumer} lang={lang} language={language} />
    </>
  )
}

export default ProfilePanelContainer
