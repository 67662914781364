import React from "react";
import PropTypes from "prop-types";

import ConfirmReimbursementSubmitDialogComponent from "./comfirmReimbursementSubmitDialog.component";

function ConfirmReimbursementSubmitDialogContainer({
  lang,
  isOpen,
  handleClose,
  handleConfirm
}) {
  return (
    <ConfirmReimbursementSubmitDialogComponent
      lang={lang}
      isOpen={isOpen}
      handleClose={handleClose}
      handleConfirm={handleConfirm}
    />
  );
}

ConfirmReimbursementSubmitDialogContainer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
};

export default ConfirmReimbursementSubmitDialogContainer;