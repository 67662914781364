import React from "react";
import { makeStyles, Box, Typography, Divider } from "@material-ui/core";
import LoadingComponent from "components/loading";
import { getLang } from "app/feature/constants";
import CampaignTngDisbursementPanel from "../campaignTngDisbursementPanel";
import CreditReloadLogPanel from "../creditReloadLogPanel";
import CreditAlertPanel from "../creditAlertPanel";
import CreditBalanceOverviewPanel from "../creditBalanceOverviewPanel";
import { provider } from "lib/constants/disbursementProvider";

const useStyle = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 3, 2, 3),
    borderRadius: "4px",
  },
  gridRowContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    columnGap: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      display: "block",
      "& > div:first-child": {
        marginBottom: 16,
      },
    },
  },
}));

export default function ConsumerEngagementSettingPanelComponent({
  disbursementInfos,
  lang,
  isFetching,
}) {
  const classes = useStyle();

  if (isFetching) {
    return (
      <Box
        className={classes.container}
        style={{ paddingTop: 40, paddingBottom: 40 }}
      >
        <LoadingComponent />
      </Box>
    );
  }

  return (
    <form>
      <Box className={classes.container}>
        {disbursementInfos.map((info, index) =>
          info.identifier === provider.touchNgo ? (
            <Box key={index}>
              <Typography
                variant="h6"
                color="primary"
                style={{ marginBottom: 8 }}
              >
                {getLang(lang, "label.TNG_DISBURSEMENT")}
              </Typography>
              <Divider />
              <Box mt={2} px={1}>
                <Box>
                  <CreditBalanceOverviewPanel disbursementInfo={info} />
                </Box>
                <Box mt={3}>
                  <CampaignTngDisbursementPanel disbursementInfo={info} />
                </Box>
                <Box className={classes.gridRowContainer} mt={3}>
                  <CreditAlertPanel disbursementInfo={info} />
                  <CreditReloadLogPanel disbursementInfo={info} />
                </Box>
              </Box>
            </Box>
          ) : null
        )}
      </Box>
    </form>
  );
}
