import { createSlice } from "@reduxjs/toolkit";
import { EMPLOYEE } from "modules/employee/constant";
import {
  fetchOnlineEmployee,
  fetchRoleDropdown,
  fetchAllEmployee,
  fetchBranchDropdown,
  fetchEmployeeDetail,
  fetchFilterBranch
} from "./employee.action";

const initialState = {
  onlineEmployeeList: {
    data: [],
    totalRecords: 0,
    filteredTotalRecords: 0,
    hasMoretoLoad: true
  },
  roleDropdown: [],
  branchDropdown: [],
  branchFilterDropdown: [],
  isLoadingEmployeeError: false,
  isLoadingOnlineError: false,
  employeeList: {
    data: [],
    totalRecords: 0,
    filteredTotalRecords: 0
  },
  employeeSetting: {
    isOpen: false
  },
  employeeDetail: {
    email: "",
    userGroupId: "",
    nodeIds: [],
    id: "",
    userId: "",
    firstName: "",
    lastName: "",
    phoneCountry: "",
    phoneNumber: "",
    phoneCode: "",
    companyName: "",
    timeZone: "",
    locale: "",
    imageProfileUrl: "",
    roleName: "",
    joinedDate: "",
    loggedinAt: "",
    lastLogin: "",
    fullName: "",
    nodes: [],
    role: {}
  }
};

const employeeSlice = createSlice({
  name: EMPLOYEE,
  initialState,
  reducers: {
    openCreationDialog(state) {
      state.employeeSetting.isOpen = true;
    },
    closeCreationDialog(state) {
      state.employeeSetting.isOpen = false;
    },
    resetEmployeeDetail(state) {
      state.employeeDetail = initialState.employeeDetail;
    },
    setEmployeeID(state, action) {
      const { payload } = action;

      state.employeeDetail.id = payload.id;
    },
  },
  extraReducers: {
    [fetchOnlineEmployee.fulfilled]: (state, action) => {
      const { payload } = action;
      const tempArray = [];

      state.onlineEmployeeList.totalRecords = payload.recordsFiltered;

      payload.data.forEach((item) => {
        tempArray.push({
          employeeId: item.id,
          employeeName: item.name,
          imageProfileUrl: item.image_profile_url,
          userGroup: item.user_group,
          nodeName: item.nodes_name,
          joinedAt: item.joined_at,
          state: item.state
        });
      });

      state.onlineEmployeeList.data = tempArray;

      state.onlineEmployeeList.hasMoretoLoad =
        state.onlineEmployeeList.totalRecords !==
        state.onlineEmployeeList.data.length;

      state.isLoadingOnlineError = false;
    },
    [fetchOnlineEmployee.rejected]: (state, action) => {
      state.isLoadingOnlineError = true;
    },
    [fetchRoleDropdown.fulfilled]: (state, action) => {
      const { payload } = action;
      const tempArray = [];

      payload.forEach((item) => {
        tempArray.push({
          userGroupId: item.user_group_id,
          name: item.name
        });
      });

      state.roleDropdown = tempArray;
    },
    [fetchAllEmployee.fulfilled]: (state, action) => {
      const { payload } = action;
      const tempArray = [];
      
      state.employeeList.totalRecords = payload.recordsFiltered;

      payload.data.forEach((item) => {
        tempArray.push({
          employeeId: item.id,
          employeeName: item.name,
          imageProfileUrl: item.image_profile_url,
          userGroup: item.user_group,
          nodeName: item.node_name,
          nodeImageUrl: item.node_profile_url,
          joinedAt: item.joined_at,
          state: item.state,
          inviteUrl: item.invite_url,
        });
      });

      state.employeeList.data = tempArray;
      state.isLoadingEmployeeError = false;
    },
    [fetchAllEmployee.rejected]: (state, action) => {
      state.isLoadingEmployeeError = true;
    },
    [fetchBranchDropdown.fulfilled]: (state, action) => {
      const { payload } = action;
      const tempArray = [];

      payload.forEach((item) => {
        tempArray.push({
          node_id: item.node_id,
          name: item.name,
          remarks: item.remarks,
          parent_node_id: item.parent_node_id,
          child: item.child
        });
      });

      state.branchDropdown = tempArray;
    },
    [fetchEmployeeDetail.fulfilled]: (state, action) => {
      const { payload } = action;

      state.employeeDetail.id = payload.data.id;
      state.employeeDetail.firstName = payload.data.first_name;
      state.employeeDetail.lastName = payload.data.last_name;
      state.employeeDetail.phoneCountry = payload.data.phone_country;
      state.employeeDetail.phoneNumber = payload.data.phone_number;
      state.employeeDetail.phoneCode = payload.data.phone_code;
      state.employeeDetail.companyName = payload.data.company_name;
      state.employeeDetail.email = payload.data.email;
      state.employeeDetail.timeZone = payload.data.timezone;
      state.employeeDetail.locale = payload.data.locale;
      state.employeeDetail.imageProfileUrl = payload.data.image_profile_url;
      state.employeeDetail.roleName = payload.data.role_name;
      state.employeeDetail.role = {
        name: payload.data.role_name,
        userGroupId: payload.data.role_id,
      }
      state.employeeDetail.joinedDate = payload.data.joined_date;
      state.employeeDetail.loggedinAt = payload.data.loggedin_at;
      state.employeeDetail.lastLogin = payload.data.last_login;
      state.employeeDetail.nodeIds = [];
      state.employeeDetail.nodeIds = payload.data.nodes;
      state.employeeDetail.nodes = payload.data.node_name;
    },
    [fetchFilterBranch.fulfilled]: (state, action) => {
      const { payload } = action;
      const tempArray = [];

      payload.forEach((item) => {
        tempArray.push({
          nodeId: item.node_id,
          name: item.name,
          remarks: item.remarks,
          parentNodeId: item.parent_node_id
        });
      });

      state.branchFilterDropdown = tempArray;
    },
  },
});

export const employeeReducer = employeeSlice.reducer;
export const {
  openCreationDialog,
  closeCreationDialog,
  resetEmployeeDetail,
  setEmployeeID
} = employeeSlice.actions;
