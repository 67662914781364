import React from "react";
import ProductReport from "./product-report.page";

function ProductReportContainer({
    filter,
    pageHandler,
    pageMode,
    translate
}) {

    return <ProductReport
        filter={filter}
        pageHandler={pageHandler}
        pageMode={pageMode}
        translate={translate}
    />;
}

export default ProductReportContainer;
