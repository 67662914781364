import React from "react";
import {
  Box,
  makeStyles,
  Table,
  TableHead,
  TableContainer,
  TableBody,
  TableRow,
  Typography,
  Button,
  InputAdornment
} from "@material-ui/core";
import {
  StyledTableCell,
  StyledTableRow,
} from "components/table";
import InputTextField from 'components/input/inputTextField';
import { isNumberInvalid } from "lib/generalUtility"
import { getLang } from "app/feature/constants";

const useStyle = makeStyles((theme) => ({
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    borderCollapse: "seperate",
    width: "100%",
    border: "1px solid rgba(58, 77, 84, 0.1)",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0
    },
    "& th,td": {
      fontSize: ".75rem",
      borderRight: "1px solid rgba(58, 77, 84, 0.1)",
      padding: theme.spacing(1, 2, 1, 2),
    },
    "& th": {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightBold,
    },
    "& th:last-child, td:last-child": {
      width: "11em",
      borderRight: "0px",
    },
    "& th th": {
      borderBottom: "none !important",
    },
    "& td td": {
      borderBottom: "none !important",
    },
  },
  inputRoot: {
    paddingTop: "8px !important",
    paddingBottom: "7px !important"
  },
  resultContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "15px",
    borderLeft: "1px solid rgba(58, 77, 84, 0.1)",
    borderRight: "1px solid rgba(58, 77, 84, 0.1)",
  }
}));

export default function DistributeIncentivePointTable({
  maxTier,
  rows,
  formik,
  defaultValue,
  lang
}) {
  const classes = useStyle();

  const calculateTotal = () => {
    let temp = [...rows]
    let totalTemp = 0

    temp.map((percentage) => {
      totalTemp = totalTemp + Number(percentage);
      return null;
    })

    return totalTemp
  }

  return (
    <>
      <Box>
        <TableContainer>
          <Table
            className={classes.table}
            aria-label="distribute incentive point table"
            size="small"
            stickyHeader
          >
            <TableHead style={{ height: "45px" }}>
              <TableRow>
                <StyledTableCell>{getLang(lang, 'label.TIER')}</StyledTableCell>
                <StyledTableCell>{getLang(lang, 'label.PERCENTAGE')}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <StyledTableRow
                  key={index}
                  hover
                  role="checkbox"
                >
                  <StyledTableCell >{getLang(lang, 'label.TIER')} {index + 1}</StyledTableCell>
                  <StyledTableCell>
                    <InputTextField
                      varaint="filled"
                      size="small"
                      margin="none"
                      type="number"
                      inputProps={{
                        min: 0,
                        max: 100
                      }}
                      InputProps={{
                        disableUnderline: true,
                        margin: "none",
                        style: {
                          borderRadius: "4px",
                          backgroundColor: "#ECEFF0"
                        },
                        classes: { input: classes.inputRoot },
                        endAdornment: (
                          <InputAdornment position="end">
                            <Box
                              px={1}
                              borderLeft="1px solid rgba(0, 0, 0, 0.1)"
                            >
                              <Typography
                                variant="body2"
                                color="textSecondary"
                              >
                                %
                              </Typography>
                            </Box>
                          </InputAdornment>
                        )
                      }}
                      value={row}
                      onChange={(event) => {
                        if (event.target.value > 100 || event.target.value < 0) {
                          event.preventDefault();
                        } else {
                          let temp = [...rows]

                          temp[index] = Number(event.target.value)
                          event.target.value = temp[index]

                          formik.setFieldValue("tier", temp)
                        }
                      }}
                      onKeyDown={(e) => isNumberInvalid(e.key) ? e.preventDefault() : null}
                    />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box className={classes.resultContainer}>
          <Box>
            <Typography variant='body2' color='primary'>
              {getLang(lang, 'label.TOTAL')}
            </Typography>
          </Box>
          <Box style={{ textAlign: "end" }}>
            <Typography
              variant='h6'
              style={{
                fontWeight: "bold",
                lineHeight: 1.2,
                color:  calculateTotal() < 100 ? "#FDB022" : calculateTotal() === 100 ? "#6AAF68" : "#F44336"
              }}
            >
              {calculateTotal()}%
            </Typography>
            <Typography variant='caption' style={{ color: calculateTotal() <= 100 ? "#98A2B3" : "#F44336" }}>
              {calculateTotal() <= 100 ? getLang(lang, 'paragraph.REMAINING_PERCENTAGE',{value:(100 - calculateTotal())}) :  getLang(lang, 'paragraph.EXCEEDED_PERCENTAGE',{value:((100 - calculateTotal()) * -1) })}
            </Typography>
          </Box>
        </Box>
        <Box
          style={{
            padding: "10px",
            display: "flex",
            justifyContent: "flex-end",
            border: "1px solid rgba(58, 77, 84, 0.1)"
          }}
        >
          <Button
            variant="outlined"
            disableElevation
            color="primary"
            onClick={() => {
              formik.setFieldValue("tier", defaultValue)
            }}
          >
            {getLang(lang, 'label.RESET_ALL')}
          </Button>
        </Box>
      </Box>
    </>
  )
}