import React from "react";
import ParticipantTableComponent from "./participantTable.component";
import { hideBackdrop, showBackdrop } from "modules/backdrop";
import { ParticipantDetailDialog } from "../../dialog";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchParticipantDetail,
  openParticipantDialog
} from "modules/instant-lucky-draw/redux";
import { unwrapResult } from "@reduxjs/toolkit";

function ParticipantTableContainer({
  rows,
  rowsPerPage,
  page,
  search,
  handleChangeRowsPerPage,
  handleChangePage,
  totalRecords,
  language,
  isFetching,
  handleReload,
  paginationOptions,
  lang
}) {
  const dispatch = useDispatch();
  const isLoadingInstantParticipantError = useSelector(
    (state) => state.instantLuckyDraw.isLoadingInstantParticipantError
  );

  const handleView = async (id) => {
    dispatch(showBackdrop());

    dispatch(fetchParticipantDetail(id))
      .then(unwrapResult)
      .then(() => {
        dispatch(hideBackdrop());
        dispatch(openParticipantDialog());
      })
      .catch((error) => {
        dispatch(hideBackdrop());
        return;
      });
  };

  return (
    <>
      <ParticipantTableComponent
        rows={rows}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        page={page}
        totalRecords={totalRecords}
        search={search}
        handleView={handleView}
        language={language}
        isFetching={isFetching}
        handleReload={handleReload}
        isLoadingInstantParticipantError={isLoadingInstantParticipantError}
        paginationOptions={paginationOptions}
        lang={lang}
      />
      <ParticipantDetailDialog />
    </>
  );
}
export default ParticipantTableContainer;
