import React, {useState} from "react";
import { useDispatch, useSelector } from 'react-redux'
import { fetchRespondentDetails } from "modules/survey/redux/action"
import RespondentListingTableComponent from "./respondentListingTable.component";
import { ViewRespondentDialog } from "../../dialog"

export default function RespondentListingTableContainer({
  isFetching,
  page,
  rowsPerPage,
  totalRecords,
  handleChangePage,
  handleChangeRowsPerPage,
  handleReload,
  data,
  paginationOptions
}) {
  
  const lang = useSelector(state => state.constant.languages);
  const dispatch = useDispatch()
  const [ isViewOpen, setIsViewOpen ] = useState(false);
  const isLoadingRespondentError = useSelector((state) => state.survey.isLoadingRespondentError);
  const handleView = ({ id }) => {
    dispatch(fetchRespondentDetails({ id }))
    setIsViewOpen(true)
  }
  const language = useSelector(state => state.profile.language);

  return (
    <>
      <RespondentListingTableComponent 
        data={data}
        isFetching={isFetching}
        page={page}
        language={language}
        rowsPerPage={rowsPerPage}
        totalRecords={totalRecords}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleView={handleView}
        handleReload={handleReload}
        isLoadingRespondentError={isLoadingRespondentError}
        paginationOptions={paginationOptions}
        lang={lang}
      />
      <ViewRespondentDialog 
        isOpen={isViewOpen}
        handleClose={() => setIsViewOpen(false)}
      />
    </>
  )
}