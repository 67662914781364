import { checkResetPasswordResendTimeLeft, requestResetPassword } from "../action/resetPassword.action";
import { setLoading, addAlert } from "modules/notification";
import { getApiLang } from "app/feature/constants";

export const checkResetPasswordResendTimeLeftMiddleware = () => next => action => {
  next(action);

  const { type } = action;

  switch (type) {
    case checkResetPasswordResendTimeLeft.pending.type:
      next(setLoading({ id: checkResetPasswordResendTimeLeft.typePrefix, state: true }));
      break;
    case checkResetPasswordResendTimeLeft.fulfilled.type:
      next(setLoading({ id: checkResetPasswordResendTimeLeft.typePrefix, state: false }));
      break;
    case checkResetPasswordResendTimeLeft.rejected.type:
      next(setLoading({ id: checkResetPasswordResendTimeLeft.typePrefix, state: false }));
      break;
    default:
      break;
  }
};

export const requestResetPasswordMiddleware = ({ getState }) => next => action => {
  next(action);

  const { type, payload } = action;
  const lang = getState().constant.languages;

  switch (type) {
    case requestResetPassword.pending.type:
      next(setLoading({ id: requestResetPassword.typePrefix, state: true }));
      break;

    case requestResetPassword.fulfilled.type:
      next(
        addAlert({
          severity: "success",
          message: getApiLang(lang, "success.SUCCESS_RESEND_EMAIL_FORGOT_PASSWORD")
        })
      );
      next(setLoading({ id: requestResetPassword.typePrefix, state: false }));
      break;

    case requestResetPassword.rejected.type:
      next(addAlert({ severity: "error", message: getApiLang(lang, payload.code)}));
      next(setLoading({ id: requestResetPassword.typePrefix, state: false }));
      
      break;
    default:
      break;
  }
};

export default [
  // checkResetPasswordResendTimeLeftMiddleware, 
  requestResetPasswordMiddleware
];
