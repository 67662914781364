import axios from "axios";

export default function getAllBranchGroups(parameters) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/account/api/v1/branch-group/lists`, {
        length: parameters.length,
        start: parameters.start,
        search: parameters.search,
        order: parameters.order,
        order_by: parameters.order_by,
        nodes: parameters.nodes,
      })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data));
  });
}
