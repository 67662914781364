import React from "react";
import SentResetPasswordPage from "./sentResetPassword.page";
import { connect } from "react-redux";

function SentResetPasswordContainer() {
  return <SentResetPasswordPage />;
}

const mapStateToProps = state => ({
});

export default connect(mapStateToProps)(SentResetPasswordContainer);
