import React from "react";
import AccountInfoTabComponent from "./accountInfoTab.component";
import { connect, useSelector } from "react-redux";
import { updateAccount } from "../../../redux";
import { selectLoading } from "modules/notification";
import { COMPANY_INFO_EDIT } from "lib/constants/accessRights";

function AccountInfoTabContainer({ account, updateAccount, isChanging }) {
  const hasCompanyInfoEditAccessRight = useSelector(state => state.profile.accessRights.includes(COMPANY_INFO_EDIT));
  const industries = useSelector(state => state.constant.industries.data)
  const lang = useSelector(state => state.constant.languages)
  const countries = useSelector(state => state.constant.country.data);
  const timezones = useSelector(state => state.constant.timezones);

  const handleSubmit = async values => {
    values.contactNumber= values.contactNumber.replace(/^0/,'');
    values.postCode = values.postCode.trim();
    const updateinfo = {
      ...values,
      contactCode: values.contactCode.phone,
      country: values.country.label,
      timezone: values.timezone.id,
    }
    updateAccount(updateinfo);
  };

  return (
    <AccountInfoTabComponent
      hasCompanyEditAccessRight={hasCompanyInfoEditAccessRight}
      handleSubmit={handleSubmit}
      account={account}
      isChanging={isChanging}
      industries={industries}
      lang={lang}
      countries={countries}
      timezones={timezones}
    />
  );
}

const mapStateToProps = state => ({
  account: state.account,
  isChanging: selectLoading(state, updateAccount.typePrefix)
});

const mapDispatchToProps = dispatch => ({
  updateAccount: accountData => dispatch(updateAccount(accountData))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountInfoTabContainer);
