import axios from "axios"

const url = "/distributor/admin/api/v1/rewards/list";

export function GetRewardListing({
  length,
  start,
  search,
  order,
  nodes,
  status
}) {
  return new Promise((resolve, reject) => {
    axios.get(url, {
      params: {
        length,
        start,
        search,
        order,
        nodes,
        status
      }
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data))
  })
}