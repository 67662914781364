import React from "react";
import { makeStyles, Paper, Typography, Box, Link } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { LOGIN, SIGN_UP } from "modules/public/config/public.route";
import { getLang } from "app/feature/constants";

const style = theme => ({
  root: {
    display: "flex",
    placeContent: "center",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(3)
  },
  paper: {
    width: "70%",
    backgroundColor: theme.palette.common.white
  },
  text: {
    color: theme.palette.secondary.main,
    fontWeight: "bold"
  },
  contextSignUp: {
    paddingTop: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      textAlign: "center"
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "smaller"
    }
  },
  context: {
    textAlign: "center",
    paddingTop: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      fontSize: "smaller"
    }
  },
  linkButton: {
    marginLeft: 8
  }
});

const useStyle = makeStyles(style);

export default function FooterComponent({ activeStep, type, handleClickAnotherEmailAddress, lang }) {
  const classes = useStyle();

  const renderFooter = (type, activeStep) => {
    switch (type) {
      case "SignUp":
        if (activeStep === 0) {
          return (
            <Typography
              variant="body2"
              color="textPrimary"
              className={classes.contextSignUp}
            >
              {getLang(lang, "label.LOGIN")}
              <Link
                component={RouterLink}
                variant="subtitle2"
                color="secondary"
                to={LOGIN}
                className={classes.linkButton}
              >
                {getLang(lang, "label.SIGN_IN")}
              </Link>
            </Typography>
          );
        } else {
          return (
            <Typography
              variant="body2"
              color="textPrimary"
              className={classes.contextSignUp}
            >
              {getLang(lang, "paragraph.CONTACT_CUSTOMER_SERVICE")}
              <span className={classes.text}> {getLang(lang, "label.KOODASIA_EMAIL")}</span>
            </Typography>
          );
        }

      case "Login":
        return (
          <Box>
            <Typography
              variant="body2"
              color="textPrimary"
              className={classes.context}
            >
              {getLang(lang, "paragraph.NO_ACCOUNT_QUESTION")}
              <Link
                component={RouterLink}
                variant="subtitle2"
                color="secondary"
                to={SIGN_UP}
                className={classes.linkButton}
              >
                {getLang(lang, "label.SIGN_UP")}
              </Link>
            </Typography>
          </Box>
        );
      case "ResetPasswordSent":
        return (
          <Box>
            <Typography
              variant="body2"
              color="textPrimary"
              className={classes.context}
            >
              {getLang(lang, "paragraph.NOT_RECEIVE_EMAIL_QUESTION")} &nbsp;
              <Link
                component="button"
                variant="subtitle2"
                color="secondary"
                onClick={handleClickAnotherEmailAddress}
              >
                {getLang(lang, "paragraph.TRY_ANOTHER_EMAIL")}
              </Link>
            </Typography>
          </Box>
        );
      default:
        break;
    }
  };
  return (
    <Box className={classes.root}>
      <Paper className={classes.paper} elevation={0}>
        {renderFooter(type, activeStep)}
      </Paper>
    </Box>
  );
}