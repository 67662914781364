import React from "react";
import {
  makeStyles,
  Box,
  Typography,
  IconButton,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TablePagination,
  TableBody,
  Checkbox,
  TableSortLabel,
  Avatar,
} from "@material-ui/core";
import { getLang } from "app/feature/constants";
import {
  DownloadIcon,
  LineChartIcon,
  UnknownUserIcon,
} from "modules/reporting/assets/svg";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import Chart from "react-apexcharts";
import { setChartColors } from "modules/reporting/utils/chartConfig";
import { responseHandler } from "modules/reporting/utils/helper";
import { BoxLoading } from "modules/reporting/components/skeleton/loading";
import DealerInvitedNoData from "../../skeleton/no-data/dealerInvited";
import {
  cardChartStyle,
  fontTextStyle,
  iconStyle,
  tableStyle,
} from "modules/reporting/styles";
import { TablePaginationActions } from "components/tablePagination";
import ApexCharts from "apexcharts";
import { useState } from "react";

const checkBoxStyles = makeStyles({
  checkbox__cell: {
    color: (props) => props.color,
  },
});

export default function DashboardStockoutByProductPanelComponent({
  lang,
  handleDownloadDealerInvitedSummaryCard,
  data,
  isFetching,
  page,
  rowsPerPage,
  changeOrder,
  paginationOptions,
  tableFilter,
  handleChangePage,
  handleChangeRowsPerPage,
}) {
  const chartClasses = cardChartStyle();
  const fontClasses = fontTextStyle();
  const tableClasses = tableStyle();
  const iconClasses = iconStyle();
  const chartID = "stockout-by-product-chart";
  const [selected, setSelected] = useState([]);

  const columns = [
    {
      title: getLang(lang, "label.NUM"),
      field: null,
      render: (row) => {
        return <>{row.title}</>;
      },
      align: "left",
      size: "medium",
    },
    {
      title: getLang(lang, "label.PRODUCTS"),
      field: null,
      render: (row) => {
        return <>{row.title}</>;
      },
      align: "left",
      size: "medium",
    },
    {
      title: getLang(lang, "label.TOTAL_STOCKOUT"),
      field: "stockout_count",
      render: (row) => {
        return <>{row.title}</>;
      },
      size: "small",
      align: "left",
    },
    {
      title: getLang(lang, "label.TOTAL_STOCKOUT_WEIGHTAGE"),
      field: null,
      render: (row) => {
          return (
              <>{row.title}</>
          )
      },
      size: "small",
      align: "left"
  },
    {
      title: getLang(lang, "label.TOTAL_REQUEST"),
      field: "request_count",
      render: (row) => {
        return <>{row.title}</>;
      },
      size: "small",
      align: "left",
    },
    {
      title: getLang(lang, "label.TOTAL_REQUEST_WEIGHTAGE"),
      field: null,
      render: (row) => {
          return (
              <>{row.title}</>
          )
      },
      size: "small",
      align: "left"
  },
    {
      title: getLang(lang, "label.REJECTED"),
      field: "rejected_count",
      render: (row) => {
        return <>{row.title}</>;
      },
      size: "small",
      align: "left",
    },
  ];

  const CheckboxComponent = ({ children, ...props }) => {
    const { checkbox__cell } = checkBoxStyles(props);
    const { checked, onClick, inputProps } = props;

    return (
      <Checkbox
        className={`${checkbox__cell}`}
        checked={checked}
        onClick={onClick}
        inputProps={inputProps}
      />
    );
  };

  const onSelectAllClick = (event) => {
    if (!event.target.checked) {
      const newSelecteds = data.dealerStockoutByProductData.rows.map(
        (n) => n.name
      );
      hideAll();
      setSelected(newSelecteds);
    } else {
      showAll();
      setSelected([]);
    }
  };

  const hideAll = () => {
    data.dealerStockoutByProductData.rows.forEach((n) => {
      ApexCharts.exec(chartID, "hideSeries", n.name);
    });
  };

  const showAll = () => {
    data.dealerStockoutByProductData.rows.forEach((n) => {
      ApexCharts.exec(chartID, "showSeries", n.name);
    });
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
    toggle(name);
  };

  const toggle = (name) => {
    ApexCharts.exec(chartID, "toggleSeries", name);
  };

  const displayData = (ref) => {
    let rows = [];
    if (Object.keys(ref).length === 0 || !ref.isSuccess) {
      return rows;
    }

    const colorSet = setChartColors(ref.chart?.datasets.length || 0, true);

    const options = {
      chart: {
        id: chartID,
      },
      tooltip: {
        followCursor: true,
        y: {
          formatter: function (val) {
            return parseInt(val);
          },
        },
      },
      xaxis: {
        categories: ref.chart?.labels || [],
        labels: {
          rotateAlways: true,
          rotate: -45,
          hideOverlappingLabels: true,
        },
        tickPlacement: "between",
      },
      legend: {
        position: "bottom",
        showForSingleSeries: true,
        onItemClick: {
          toggleDataSeries: false,
        },
      },
      grid: {
        padding: {
          left: 12,
          right: 12,
        },
      },
      stroke: {
        width: 2,
      },
      colors: colorSet,
    };

    let no_iteration = rowsPerPage * page + 1;

    return (
      <Box className={chartClasses.card}>
        <Chart
          options={options}
          series={ref.chart?.datasets || []}
          type="line"
          height="430px"
        ></Chart>
        <TableContainer component={Paper}>
          <Box
            style={{
              overflowX: "auto",
              width: "100%",
            }}
          >
            <Table>
              <TableHead>
                <TableRow hover>
                  <TableCell
                    padding="checkbox"
                    className={tableClasses.table__cell}
                  >
                    <Checkbox
                      onChange={onSelectAllClick}
                      indeterminate={
                        selected.length > 0 && selected.length < ref.rows.length
                      }
                      checked={ref.rows.length > 0 && selected.length === 0}
                    />
                  </TableCell>
                  {columns.map((column, index) => (
                    <TableCell
                      className={[
                        tableClasses.table__cell,
                        fontClasses.text__header_table_cell,
                      ]}
                      key={column.title}
                      size={column.size}
                    >
                      {column.field ? (
                        <TableSortLabel
                          active={
                            tableFilter.orderBy === column.field ? true : false
                          }
                          direction={
                            tableFilter.orderBy === column.field
                              ? tableFilter.order
                              : "desc"
                          }
                          onClick={() => {
                            const isAsc =
                              tableFilter.orderBy === column.field &&
                              tableFilter.order === "asc";
                            if (!isFetching && column.field) {
                              changeOrder(column.field, isAsc ? "desc" : "asc");
                            }
                          }}
                        >
                          {column.render(column)}
                        </TableSortLabel>
                      ) : (
                        column.render(column)
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {ref.rows.map((item, index) => {
                  const isItemSelected = selected.indexOf(item.name) !== -1;
                  const labelId = `enhanced-table-checkbox-${item.name}`;

                  return (
                    <TableRow
                      hover
                      aria-checked={isItemSelected}
                      key={item.name}
                      selected={isItemSelected}
                    >
                      <TableCell
                        padding="checkbox"
                        className={tableClasses.table__cell}
                      >
                        <CheckboxComponent
                          checked={!isItemSelected}
                          onClick={(event) => handleClick(event, item.name)}
                          inputProps={{ "aria-labelledby": labelId }}
                          color={colorSet[index] + " !important"}
                        />
                      </TableCell>
                      <TableCell
                        className={tableClasses.table__cell}
                        style={{ width: "20px" }}
                      >
                        <Typography className={fontClasses.text__table_cell}>
                          {no_iteration++}
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={tableClasses.table__cell}
                        style={{ width: "400px", minWidth: "400px" }}
                      >
                        <Box className={tableClasses.cell__product}>
                          {item.image ? (
                            <Avatar
                              variant="square"
                              alt={item.name}
                              src={item.image}
                              className={iconClasses.svg__icon}
                            />
                          ) : (
                            <Avatar
                              variant="square"
                              alt={item.name}
                              src={UnknownUserIcon}
                              className={iconClasses.svg__icon}
                            />
                          )}
                          {item.name}
                        </Box>
                      </TableCell>
                      <TableCell
                        className={tableClasses.table__cell}
                        align="right"
                        style={{ width: "120px", minWidth: "120px" }}
                      >
                        <Typography className={fontClasses.text__table_cell}>
                          {item.total_stockout}
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={tableClasses.table__cell}
                        align="right"
                        style={{ width: "120px", minWidth: "120px" }}
                      >
                        <Typography className={fontClasses.text__table_cell}>
                          {item.stockout_percentage}
                        </Typography>
                      </TableCell>

                      <TableCell
                        className={tableClasses.table__cell}
                        align="right"
                        style={{ width: "120px", minWidth: "120px" }}
                      >
                        <Typography className={fontClasses.text__table_cell}>
                          {item.total_request}
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={tableClasses.table__cell}
                        align="right"
                        style={{ width: "120px", minWidth: "120px" }}
                      >
                        <Typography className={fontClasses.text__table_cell}>
                          {item.request_percentage}
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={tableClasses.table__cell}
                        align="right"
                        style={{ width: "120px", minWidth: "120px" }}
                      >
                        <Typography className={fontClasses.text__table_cell}>
                          {item.total_rejected}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
          <TablePagination
            ActionsComponent={TablePaginationActions}
            component="div"
            rowsPerPageOptions={paginationOptions}
            rowsPerPage={rowsPerPage}
            page={page}
            count={ref.total_record ?? 0}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Box>
    );
  };

  return (
    <>
      <Box id="stockout-by-product" mt={3}>
        <Box
          mb={2}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="body1">
            <b>{getLang(lang, "label.STOCKOUT_BY_PRODUCTS")}</b>
          </Typography>
          <Box display="flex" alignContent="center">
            <IconButton
              title="Download Charts"
              aria-label="download"
              onClick={handleDownloadDealerInvitedSummaryCard}
              disableRipple
            >
              <DownloadIcon />
            </IconButton>
            <ToggleButtonGroup value={"line"} exclusive>
              <ToggleButton value="stacked-bar">
                <LineChartIcon />
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </Box>
        <Box mb={3}>
          {responseHandler(
            data,
            isFetching,
            displayData(data),
            <BoxLoading />,
            <DealerInvitedNoData
              title={getLang(lang, "label.STOCKOUT_RECORD_NOT_FOUND")}
              value={getLang(lang, "paragraph.NO_DATA_ON_SELECTED_FILTER")}
            />,
            <DealerInvitedNoData
              title={getLang(lang, "label.STOCKOUT_RECORD_NOT_FOUND")}
              value={getLang(lang, "paragraph.NO_DATA_ON_SELECTED_FILTER")}
            />,
            <DealerInvitedNoData
              title={getLang(lang, "label.LOADING_FAILED")}
              value={getLang(lang, "paragraph.LOADING_FAILED_DESCRIPTION")}
            />
          )}
        </Box>
      </Box>
    </>
  );
}
