import React, { useState, useRef, useEffect } from "react";
import AssignModuleSelectComponent from "./moduleSelect.component";
import AssignModulePanelComponent from "./moduleSelectPanel.component";
import PropTypes from "prop-types";
import { selectLoading } from "modules/notification";
import { useDispatch, useSelector } from "react-redux";
import { getProductDropdown } from "modules/product-mgmt/redux/action/products.action";
import { getModuleDropdown } from "../../../redux/action/loyaltyProgram.action"
import { getLang } from "app/feature/constants";
function ModuleSelectContainer({
  placeholder,
  handleChange,
  value,
  disabled,
  bonanza
}) {
  const [open, setOpen] = useState(false);
  const [isOpenList] = useState(false);
  const anchorRef = useRef(null);
  const prevOpen = useRef(open);
  const prevOpenList = useRef(isOpenList);
  const [search, setSearch] = useState("")
  const dispatch = useDispatch();

  const productDropdown = useSelector(
    (state) => state.products.productsDropdown
  );
  const isProductDropdownLoading = useSelector((state) =>
    selectLoading(state, getProductDropdown.typePrefix)
  );

  const moduleDropdown = useSelector(
    (state) => state.loyaltyProgram.modulesDropdown
  );
  const isModuleDropdownLoading = useSelector((state) =>
    selectLoading(state, getModuleDropdown.typePrefix)
  );
  const lang = useSelector(state => state.constant.languages)

  useEffect(() => {
    if (bonanza === true) {
      dispatch(getModuleDropdown({
        search: search
      }))
    } else {
      dispatch(getProductDropdown({
        search: search
      }));
    }
  }, [search, dispatch, bonanza]);

  const handleClose = () => {
    setOpen(false);
    setSearch("");
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleSearch =async (values) => {
    await setSearch(values)
  }

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    if (prevOpenList.current === true && isOpenList === false) {
      anchorRef.current.focus();
    }

    prevOpenList.current = isOpenList;
  }, [isOpenList]);

  function handleListKeyDown(v) {
    const temp = value.slice();
    const index = temp.findIndex(
      (t) => t.id === v.id
    );
    if (index === -1) {
      temp.push(v);
    } else {
      temp.splice(index, 1);
    }
    handleChange(temp);
  }

  return (
    <>
      <AssignModuleSelectComponent
        anchorRef={anchorRef}
        handleClick={handleClick}
        value={value}
        placeholder={getLang(lang, 'placeholder.SELECT_PRODUCT')}
        disabled={disabled}
        lang={lang}
      />
      <AssignModulePanelComponent
        value={value}
        anchorRef={anchorRef}
        open={open}
        isOpenList={isOpenList}
        handleClose={handleClose}
        isFetching={bonanza ? isModuleDropdownLoading : isProductDropdownLoading}
        handleListKeyDown={handleListKeyDown}
        productDropdown={bonanza ? moduleDropdown : productDropdown}
        bonanza={bonanza}
        handleSearch={handleSearch}
        lang={lang}
      />
    </>
  );
}


ModuleSelectContainer.propTypes = {
  placeholder: PropTypes.string
};


export default (ModuleSelectContainer);
