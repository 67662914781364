import axios from "axios";

export function getProductCategoryDetailData({
    country,
    city_id,
    category_uuid,
    branch_uuid,
    period,
    start_date,
    end_date,
    url
}) {
    let city = city_id;
    return new Promise((resolve, reject) => {
        axios
            .post(url, {
                country,
                city,
                category_uuid,
                branch_uuid,
                period,
                start_date,
                end_date
            })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                if (error?.response?.data) {
                    reject(error.response.data);
                } else {
                    reject(error);
                }
            });
    });
}

export default getProductCategoryDetailData;
