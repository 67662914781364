import React from "react";
import { makeStyles, TextField } from "@material-ui/core";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import clsx from "clsx";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles(theme => ({
  inputRoot: {
    paddingTop: "8px !important",
    paddingBottom: "7px !important"
  },
  option: {
    "& > span": {
      flex: 1
    }
  },
  label: {
    fontSize: "smaller"
  },
  paper: {
    width: "200%"
  }
}));

const filterOptions = () => createFilterOptions({
  stringify: option => option.id + option.text,
});

export default function TimezoneSelectComponent({ textFieldProps, formProps, timezones, lang }) {
  const classes = useStyle();
  return (
    <Autocomplete
      {...formProps}
      fullWidth={textFieldProps.fullWidth || false}
      options={timezones}
      noOptionsText={getLang(lang, "label.NO_RESULT_FOUND")}
      classes={{
        option: classes.option,
        inputRoot: clsx({
          [classes.inputRoot]: textFieldProps.variant === "filled"
        })
      }}
      autoHighlight
      filterOptions={filterOptions()}
      getOptionLabel={option => option.text}
      renderOption={option => (
        <React.Fragment>
          <span>{option.text}</span>
        </React.Fragment>
      )}
      renderInput={params => {
        return (
          <TextField
            name={"timezone"}
            {...params}
            {...textFieldProps}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              style: {
                padding: "5px",
                borderRadius: "4px",
                backgroundColor: textFieldProps.variant === "filled" ? "#ECEFF0" : null
              }
            }}
            placeholder={getLang(lang, "placeholder.SELECT_TIMEZONE")}
            inputProps={{
              ...params.inputProps,
              style: {
                paddingLeft: "8px"
              },
              //autoComplete: type === "phone" ? "phone" : "country" // disable autocomplete and autofill
            }}
          />
        );
      }}
    />
  );
}