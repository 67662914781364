import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTagDetail, resetTag } from "../../../redux";
import { selectLoading } from "modules/notification";
import TagDetailDialogComponent from "./tagDetailDialog.component";

function TagDetailDialogContainer({ isOpen, handleClose, uuid }) {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.constant.languages);
  const tag = useSelector((state) => state.serialNumberTag.tag);
  const isError = useSelector(
    (state) => state.serialNumberTag.isLoadingSnTagError
  );
  const isLoading = useSelector((state) =>
    selectLoading(state, getTagDetail.typePrefix)
  );

  useEffect(() => {
    return () => {
      dispatch(resetTag());
    };
  }, [dispatch]);

  useEffect(() => {
    if (uuid) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid]);

  const getData = () => {
    dispatch(getTagDetail(uuid));
  };

  return (
    <TagDetailDialogComponent
      open={isOpen}
      handleClose={handleClose}
      lang={lang}
      tag={tag}
      handleReload={getData}
      isError={isError}
      isLoading={isLoading}
    />
  );
}

export default TagDetailDialogContainer;
