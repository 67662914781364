import React from "react";
import Alert from "@material-ui/lab/Alert";
import ButtonBack from "../../components/button/buttonBack";
import { ROOT } from "../../config/product-mgmt.route";
import { getLang } from "app/feature/constants";

export default function FallbackComponent({lang}) {
  return (
    <>
      <ButtonBack to={ROOT} text="All Products" />
      <br />
      <Alert severity="error">{getLang(lang,"message.error.INVALID_PRODUCT")}</Alert>
    </>
  );
}
