import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { parseHash } from "../../redux/action";
import { isHashParsed, isAccessGranted } from "modules/admin";
import {
  LOGIN
} from "modules/public/config/public.route";
import { selectLoading } from "modules/notification";
import { getLang } from "app/feature/constants";

function AuthPage({
  history,
  parseHash,
  isHashParsed,
  isParsing,
  isAccessGranted
}) {
  const lang = useSelector(state => state.constant.languages)
  useEffect(() => {
    if (isHashParsed) {
      if (!isAccessGranted)
        history.push(LOGIN);
      else
        history.push("/");
    }
  }, [isHashParsed, isAccessGranted, history]);

  useEffect(() => {
    const hash = history.location.hash;
    
    if (hash) {
      if (!isParsing && !isHashParsed) {
        parseHash(hash);
      }
    }
    else
      history.push(LOGIN);
  }, [history, parseHash, isParsing, isHashParsed]);

  return <div>{getLang(lang, "paragraph.PLEASE_WAIT")}</div>;
}

const mapStateToProps = state => ({
  isHashParsed: isHashParsed(state),
  isAccessGranted: isAccessGranted(state),
  isParsing: selectLoading(state, parseHash.typePrefix)
});

const mapDispatchToProps = dispatch => ({
  parseHash: hash => dispatch(parseHash(hash))
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthPage);
