import {
  addBlankWinnerIntoPrize,
  deleteWinnerFromPrize,
  setWinnerIntoPrize
} from "modules/instant-lucky-draw/redux";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import SetWinnerTableComponent from "./setWinnerTable.component";
import { INSTANT_LUCKY_DRAW_EDIT } from "lib/constants/accessRights";

export default function SetWinnerTableContainer({
  dialog,
  quantity,
  winners,
  handleChange,
  balance,
  index,
  lang
}) {
  const dispatch = useDispatch();
  const hasInstantLuckyDrawEditAccessRight = useSelector(state => state.profile.accessRights.includes(INSTANT_LUCKY_DRAW_EDIT))

  const handleAddWinner = async () => {
    await dispatch(addBlankWinnerIntoPrize(index));
  };

  const handleAddWinnerIntoPrize = async (winnerIndex, serialNumber, enc) => {
    const info = {
      index: index,
      winnerIndex: winnerIndex,
      serialNumber: serialNumber,
      enc: enc
    };
    await dispatch(setWinnerIntoPrize(info));
  };

  const handleDelete = async (winnerIndex) => {
    const deleteInfo = {
      index: index,
      winnerIndex: winnerIndex
    };
    await dispatch(deleteWinnerFromPrize(deleteInfo));
  };
  return (
    <SetWinnerTableComponent
      hasInstantLuckyDrawEditAccessRight={hasInstantLuckyDrawEditAccessRight}
      dialog={dialog}
      quantity={quantity}
      winners={winners}
      handleChange={handleChange}
      balance={balance}
      handleAddWinner={handleAddWinner}
      handleDelete={handleDelete}
      handleAddWinnerIntoPrize={handleAddWinnerIntoPrize}
      lang={lang}
    />
  );
}
