import React, { useState, useRef, useEffect } from "react"
import OrderReceiverSearchComponent from "./orderReceiverSearch.component"
import { selectLoading } from "modules/notification"
import OrderReceiverSearchPanelComponent from "./orderReceiverSearchPanel.component"
import { getOrderReceiverDropdown } from "modules/stockflow/redux"
import { useSelector, useDispatch } from "react-redux"
import { cloneDeep } from "lodash"

function OrderReceiverSearchContainer({
  handleChange,
  disabled,
  value,
  styles, 
  textStyles,
  placeholder,
  type,
  dealerUuid,
  handleChipDelete,
  isEmpty,
}) {
  const isFetching = useSelector(state => selectLoading(state, getOrderReceiverDropdown.typePrefix))
  const lang = useSelector((state) => state.constant.languages)
  const orderReceiverDropdown = useSelector((state) => state.stockflowOrder.orderReceiverDropdown)
  const [open, setOpen] = useState(false)
  const [isOpenList] = useState(false)
  const anchorRef = useRef(null)
  const prevOpen = useRef(open)
  const prevOpenList = useRef(isOpenList)
  const [selectAll, setSelectAll] = useState(false)
  const [search, setSearch] = useState("")
  const dispatch = useDispatch()
  const drawerOpen = useSelector((state) => state.app.drawerOpen);
  const [extraChip, setExtraChip] = useState({
    count: 0,
    offset: 0,
    allOverflow: false,
    overflowChild: []
  });

  const adjustField = (ref) => {

    let children = ref.current?.children[0]?.children;
    let p = ref.current?.children[0];
    if (!children) return;

    if (children.length > 0) {
      // Need to exclude placeholder
      if (children[0].role == null) return;

      children = [...children];

      // max width is 90%. Use 0.9 to calc field width
      let panelWidth = ref.current?.getBoundingClientRect().width * 0.9;
      let childWidth = 0;

      // Check for no overflow child count
      let inRangeCount = children.filter((child, index) => {

        let panelContentWidth = p.getBoundingClientRect().width
        // get exact width + padding with decimal
        childWidth += (parseInt(window.getComputedStyle(child).marginRight) + child.getBoundingClientRect().width);

        // Check if overflow
        if (panelWidth - panelContentWidth < 1) {
          // need to add 0.5 as the ellipsis padding (0.5 = ellipsis padding)
          // if the overflow happen before last child, need inlude another 10 for the ellipsis (10 = ellipsis width)
          return (index !== (children.length - 1)) ? (childWidth + 10.5) < panelContentWidth : childWidth + 0.5 < panelContentWidth;
        }

        return (panelWidth > panelContentWidth);
      }).length;


      if (inRangeCount > 0) {

        let lastRangeChild = children[inRangeCount - 1];

        let outRangeChild = value.slice(inRangeCount);

        // Check for overflow child count
        let extraCount = value.length - inRangeCount;
        setExtraChip({
          count: extraCount,
          offset: lastRangeChild.offsetLeft + lastRangeChild.offsetWidth,
          allOverflow: false,
          overflowChild: outRangeChild
        });
      } else {
        setExtraChip({
          count: value.length,
          offset: 0,
          allOverflow: true,
          overflowChild: value
        });
      }
    } else {
      setExtraChip({
        count: 0,
        offset: 0,
        allOverflow: false,
        overflowChild: []
      });
    }
  }

  useEffect(() => {
    let param = { search, type }
    if (dealerUuid) {
      param.request_from_dealer = dealerUuid
    }
    dispatch(getOrderReceiverDropdown(param))
  }, [search, type, dispatch, dealerUuid])

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])

  useEffect(() => {
    if (prevOpenList.current === true && isOpenList === false) {
      anchorRef.current.focus()
    }

    prevOpenList.current = isOpenList
  }, [isOpenList])

  useEffect(() => {
    setSelectAll(value.length === orderReceiverDropdown.length)
  }, [value, orderReceiverDropdown])

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
    setSearch("")
  }

  const handleSelectAll = async () => {
    if(!selectAll){
      let selectedProducts = orderReceiverDropdown.map(p => {
        return {
          id: p.id,
          type: p.type,
          name: p.name
        }
      })
      handleChange(selectedProducts)
    }else{
      handleChange([])
    }
  }

  const handleSelectReceiver = (receiver) => {
    let temp = cloneDeep(value)

    if(temp.findIndex(t => t.id === receiver.id && t.type === receiver.type) >= 0){
      temp = temp.filter( t => !(t.id === receiver.id && t.type === receiver.type))
    }else{
      temp.push({
        id: receiver.id,
        type: receiver.type,
        name: receiver.name
      })
    }
    handleChange(temp)
  }

  const handleSearch = (value) => {
    setSearch(value)
  }

  return (
    <>
      <OrderReceiverSearchComponent
        anchorRef={anchorRef}
        disabled={disabled}
        value={value}
        handleClick={handleOpen}
        styles={styles}
        textStyles={textStyles}
        placeholder={placeholder}
        lang={lang}
        handleChange={handleChange}
        handleChipDelete={handleChipDelete}
        drawerOpen={drawerOpen}
        extraChip={extraChip}
        adjustField={adjustField}
        isEmpty={isEmpty}
      />
      <OrderReceiverSearchPanelComponent
        selectAll={selectAll}
        value={value}
        anchorRef={anchorRef}
        open={open}
        isOpenList={isOpenList}
        handleClose={handleClose}
        isFetching={isFetching}
        orderReceiverDropdown={orderReceiverDropdown}
        handleSelectAll={handleSelectAll}
        handleSelectReceiver={handleSelectReceiver}
        handleSearch={handleSearch}
        lang={lang}
        isEmpty={isEmpty}
      />
    </>
  )
}

export default OrderReceiverSearchContainer