import { Box, CircularProgress } from "@material-ui/core";
import React from "react";

export default function BoxLoading() {
    return (
        <>
            <Box
                height="430px"
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <CircularProgress />
            </Box>
        </>
    )
}