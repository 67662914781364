import axios from "axios";

const updateBatchAppAuthorizeStatus = ({
  uuid,
  status
}) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`/serialnumber/api/v1/generate/status/update/${uuid}`, {
        status,
      })
      .then(response => resolve(response.data))
      .catch(error => {
        return reject(error.response.data)
      });
  });
};

export default updateBatchAppAuthorizeStatus;