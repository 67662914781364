import React from "react";
import {
  Box,
  Paper,
  makeStyles,
  Typography,
  Grid,
  ButtonBase
} from "@material-ui/core";
import { UploadImg } from "modules/profile/utils/static";
import Dropzone from "react-dropzone";
import classnames from "classnames";
import DefaultPicture from "assets/img/img-default.png";
import { getLang } from "app/feature/constants";

const useStyles = makeStyles(theme => ({
  paper: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(3)
  },
  avatar: {
    height: 282,
    width: 282,
    border:"1px solid",
    borderRadius: "20px",
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    "& img": {
      height: "100%",
      minWidth: "100%",
      minHeight: "100%",
      objectFit: "contain",
      flexShrink: 0
    }
  },
  logo: {
    height: "100%",
    minWidth: "100%",
    minHeight: "100%",
    objectFit: "contain",
    flexShrink: 0
  },
  label: {
    textTransform: "none"
  },
  input: {
    display: "none"
  },
  mr1: {
    backgroundColor: "#4D626A",
    color: "#ffffff",
    padding: theme.spacing(2),
    "&:hover": {
      backgroundColor: "#415D69"
    },
    display: "flex",
    flexFlow: "column"
  },
  text: {
    color: theme.palette.secondary.main
  },
  dragging: {
    backgroundColor: "#415D69",
    border: "dashed 2px"
  },
  dropzoneContainer: {
    backgroundColor: "#4D626A",
    padding: theme.spacing(2)
  }
}));

export default function PhotoProductPanelComponent({
  hasProductAddAccessRight,
  hasProductEditAccessRight,
  type,
  productPicture,
  inputImgRef,
  handleImgChange,
  handleDrop,
  dragging,
  handleDragEnter,
  handleDragLeave,
  lang
}) {
  const classes = useStyles();

  return (
    <Box>
      <Paper elevation={0} className={classes.paper}>
        <Box style={{ display: "flex" }}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} style={{ width: "100%" }}>
              <Typography
                variant="body1"
                style={{ color: "#ffffff" }}
              >
                {getLang(lang,"label.PRODUCT_PHOTO")}
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Box style={{ display: "flex", placeContent: "center" }}>
          <Box mt={2} mb={2} className={classes.avatar}>
            <img
              src={productPicture !== "" ? productPicture : DefaultPicture}
              alt=""
              className={classes.logo}
            />
          </Box>
        </Box>

        {
          (type === "create" && hasProductAddAccessRight) ||
            (type === "edit" && hasProductEditAccessRight)
            ?
            <Box>
              <Box mb={1}>
                <Typography
                  variant="body2"
                  style={{ textAlign: "center", color: "#ffffff" }}
                >
                  {getLang(lang,"paragraph.UPLOAD_PRODUCT_PHOTO")}
                </Typography>
              </Box>
              <Box className={classes.dropzoneContainer}>
                <Dropzone
                  onDrop={handleDrop}
                  noClick
                  noKeyboard
                  onDragEnter={handleDragEnter}
                  onDragLeave={handleDragLeave}
                >
                  {({ getRootProps }) => (
                    <div {...getRootProps()}>
                      <input
                        accept="image/*"
                        className={classes.input}
                        id="contained-button-file"
                        type="file"
                        ref={inputImgRef}
                        onChange={handleImgChange}
                        onClick={(e) => e.target.value = null}
                      />
                      <label htmlFor="contained-button-file">
                        <ButtonBase
                          component="span"
                          className={classnames(classes.mr1, {
                            [classes.dragging]: dragging
                          })}
                        >
                          <UploadImg />
                          {dragging ? (
                            <Box mt={1}>{getLang(lang,"label.DROP_HERE")}</Box>
                          ) : (
                            <Box
                              mt={1}
                              style={{
                                display: "flex",
                                flexWrap: "wrap",
                                justifyContent: "center"
                              }}
                            >
                              <Typography
                                variant="button"
                                color="inherit"
                                style={{
                                  textTransform: "none",
                                  fontSize: "0.8125rem"
                                }}
                              >
                                {getLang(lang,"paragraph.DROP_FILES")}
                                <span className={classes.text}>&nbsp;{getLang(lang,"paragraph.BROWSE")}</span>
                              </Typography>
                            </Box>
                          )}
                        </ButtonBase>
                      </label>
                    </div>
                  )}
                </Dropzone>
              </Box>

              <Box
                mt={2}
                style={{
                  display: "flex",
                  placeContent: "center",
                  textAlign: "center"
                }}
              >
                <Typography variant="caption" style={{ color: "#ffffff" }}>
                  {getLang(lang,"paragraph.RECOMMENDED_FILE_SIZE_LOGO_12")}
                </Typography>
              </Box>
            </Box>
            : null
        }
      </Paper>


    </Box>
  );
}
