import { LOGIN } from 'modules/public/config/public.route'
import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import InvalidLinkPage from './invalidLink.page'

export default function InvalidLinkContainer() {
    const history = useHistory()
    const lang = useSelector(state => state.constant.languages)

    const handleContinue = () => {
        history.push(LOGIN)
    }

    return (
        <InvalidLinkPage handleContinue={handleContinue} lang={lang}/>
    )
}
