import React from "react";
import {
  Typography,
  Box,
  makeStyles,
  Tabs,
  Divider,
  InputBase
} from "@material-ui/core";
import * as Yup from "yup";
import { Formik, ErrorMessage } from "formik";
import { TYPES } from "./websiteForm.container";
import { ModalFooter, ContentWrapper } from "../../modal";
import Input from "components/input/inputTextField";
import LanguageTab from "../../tab/languageTab";
import LANGUAGES from "lib/constants/languages";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles(theme => ({
  btnSecondary: {
    color: theme.palette.common.white
  },
  editorPaper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)",
    borderRadius: theme.spacing(0.5)
  },
  tabsRoot: {
    textTransform: "none"
  },
  indicator: {
    border: "2px solid",
    borderColor: theme.palette.primary.main,
    width: "50px !important",
    transform: "translateX(12px)",
    borderRadius: theme.spacing(1, 1, 0, 0)
  },
  scroll: {
    width: "min-content"
  }
}));

export default function WebsiteFormComponent({
  type,
  handleSubmit,
  handleDelete,
  activeIndex,
  lang,
  URL,
  activeDesc,
  handleChangeActiveDesc,
  handleChangeActiveIndex,
  languages,
  handleClose
}) {
  const classes = useStyle();

  return (
    <Formik
      initialValues={{
        URL: URL
      }}
      validationSchema={Yup.object({
        URL: Yup.string().required(getLang(languages,"message.error.FORM_VALIDATE_REQUIRED"))
      })}
      onSubmit={handleSubmit}
    >
      {formik => (
        <form onSubmit={formik.handleSubmit}>
          <ContentWrapper resetPaper={true} disablePadding={true}>
            <Tabs
              value={activeIndex}
              onChange={handleChangeActiveIndex}
              aria-label="scrollable auto tabs example"
              textColor="primary"
              variant="scrollable"
              indicatorColor="primary"
              classes={{
                root: classes.tabsRoot,
                indicator: classes.indicator,
                scrollButtons: classes.scroll
              }}
            >

             {lang.map((label, index) => {
              const LABEL = LANGUAGES.some(langObj => langObj.code === label);
              return (
                <LanguageTab
                  key={index}
                  disabled={activeIndex === index ? true : false}
                  label={LABEL ? label : label.split("-")[1]}
                />
              );
            })}
            </Tabs>
            <Box className={classes.editorPaper}>
              <Box p={2} minHeight="120px">
                <InputBase
                  value={
                    activeDesc
                  }
                  onChange={handleChangeActiveDesc}
                  fullWidth
                  multiline
                  rowsMin={4}
                  rowsMax={16}
                  placeholder={getLang(languages,"placeholder.ENTER_WEBSITE_DESCRIPTION")}
                />
              </Box>
              <Divider />
              <Box py={1} px={2} display="flex" alignItems="center">
                <Typography
                  style={{ fontWeight: "bold", marginRight: 16 }}
                  variant="body2"
                >
                  {getLang(languages,"label.URL")}
                </Typography>
                <Input
                  required
                  hiddenLabel
                  variant="filled"
                  size="small"
                  margin="none"
                  placeholder={getLang(languages,"placeholder.ENTER_URL_HERE")}
                  fullWidth
                  {...formik.getFieldProps("URL")}
                  value={type === TYPES[2] ? URL : formik.values.URL}
                  error={
                    formik.touched.URL && formik.errors.URL ? true : false
                  }
                  helperText={ErrorMessage({ name: "URL" })}
                  inputProps={{ readOnly: type === TYPES[2] }}
                />
              </Box>
            </Box>
          </ContentWrapper>
          <ModalFooter
            handleDelete={handleDelete}
            showDelete={type !== TYPES[0]}
            type="submit"
            handleClose={handleClose}
          />
        </form>
      )}
    </Formik>
  );
}
