import React from "react";
import { makeStyles, Tab, Typography } from "@material-ui/core";
import LanguageIcon from "@material-ui/icons/Language";
import PropTypes from "prop-types";

const useStyle = makeStyles(theme => ({
  tabRoot: {
    padding: theme.spacing(0, 1.5),
    marginRight: theme.spacing(1),
    alignItems: "center",
    minWidth: 0,
    minHeight: theme.spacing(6),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.spacing(0.5, 0.5, 0, 0)
  },
  tabWrapper: {
    flexDirection: "row"
  },
  langIcon: {
    marginBottom: "0px !important"
  }
}));

function LanguageTabComponent({ label, ...other }) {
  const classes = useStyle();

  return (
    <Tab
      classes={{
        root: classes.tabRoot,
        wrapper: classes.tabWrapper
      }}
      id={`simple-tab-0`}
      {...{ "aria-controls": `tab-lang-${label}` }}
      icon={<LanguageIcon className={classes.langIcon} />}
      label={
        <Typography
          variant="body2"
          style={{ textTransform: "none", marginLeft: 8 }}
        >
          {label}
        </Typography>
      }
      {...other}
    />
  );
}

LanguageTabComponent.propTypes = {
  label: PropTypes.string.isRequired
};

export default LanguageTabComponent;
