import axios from "axios";

export function DeleteReward({
  id,
  status,
}) {
  const url = `/distributor/admin/api/v1/rewards/${id}/delete`;

  return new Promise((resolve, reject) => {
    axios.delete(url)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data))
  })
}