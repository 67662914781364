import React from 'react'
import SurveyTemplatePanelComponent from './surveyTemplatePanel.component'
import { useSelector } from "react-redux";

export default function SurveyTemplatePanelContainer({
    handleCreate
}) {
    const lang = useSelector(state => state.constant.languages);

    return (
        <SurveyTemplatePanelComponent handleCreate={handleCreate} lang={lang}  />
    )
}
