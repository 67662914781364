import React from "react";
import {
  makeStyles,
  Box,
  Dialog,
  Typography,
  Button,
  Backdrop,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormControl,
  FormLabel,
  Checkbox,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Grid,
  MenuItem,
} from "@material-ui/core";
import { ErrorMessage, Formik } from "formik";
import { getLang } from "app/feature/constants";
import { SkeletonTableRow } from "components/skeleton/skeleton.component";
import { convertToNumberString } from "lib/generalUtility";
import RefreshTable from "components/table/refreshTable";
import { ASSIGN_BATCH_STATUS_FAIL } from "modules/serial-number/constants";
import InputTextField from "components/input/inputTextField";
// import InputTextField from "components/input/inputTextField";
// import { Info } from "@material-ui/icons";

const useStyle = makeStyles((theme) => ({
  root: {
    padding: 24,
    [theme.breakpoints.down("xs")]: {
      padding: 8,
    },
  },
  greyBox: {
    backgroundColor: "#F9FAFB",
    padding: "8px 12px",
  },
  warningBox: {
    backgroundColor: "#FFFAEB",
    padding: "8px 12px",
  },
  warningText: {
    color: "#FDB022",
  },
  warningTitle: {
    color: "#7A2E0E",
  },
  tableContainer: {
    maxHeight: 250,
    overflowY: "auto",
  },
  table: {
    border: "1px solid #D0D5DD",
    tableLayout: "auto",
    whiteSpace: "nowrap",
    maxHeight: 500,
    overflowY: "auto",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
    },
    "& th": {
      backgroundColor: "white",
      color: "#98A2B3",
    },
    "& .MuiTableCell-sizeSmall": {
      padding: "6px 8px",
    },
  },
}));

export default function ExportAssignExcelDialogComponent({
  isOpen,
  handleClose,
  handleSubmit,
  lang,
  formats,
  fields,
  batch,
  isFetching,
  failJobList,
  isFetchFailJobError,
  handleReload,
  fileDropdown
}) {
  const classes = useStyle();

  return (
    !!batch && (
      <Dialog
        maxWidth="sm"
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-assign-serial-number-to-product"
        aria-describedby="modal-assign-serial-number-to-product"
        BackdropComponent={Backdrop}
        fullWidth
      >
        <Formik
          enableReinitialize
          initialValues={{
            format: "",
            fields: [],
            file: []
          }}
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <Box className={classes.root}>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  mb={3}
                >
                  <Box mb={0.5}>
                    <Typography variant="h6" align="center">
                      <b>{getLang(lang, "label.EXPORT_SERIAL_NUMBER")}</b>
                    </Typography>
                  </Box>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    align="center"
                  >
                    {getLang(lang, "label.PLEASE_SELECT_EXPORT_INFO")}
                  </Typography>
                </Box>
                <Box className={classes.greyBox} mb={2}>
                  <Box mb={0.5}>
                    <Typography variant="body1" color="textSecondary">
                      {getLang(lang, "label.BATCH_NUMBER")}
                    </Typography>
                  </Box>
                  <Typography variant="body1">
                    <b>{batch?.batchNumber || "-"}</b>
                  </Typography>
                </Box>
                {batch.status === ASSIGN_BATCH_STATUS_FAIL && (
                  <Box className={classes.greyBox} mb={2}>
                    <Box mb={1}>
                      <Typography variant="body2">
                        {getLang(lang, "paragraph.SN_EXPORT_WARNING")}
                      </Typography>
                    </Box>
                    <Box>
                      {!isFetching && isFetchFailJobError ? (
                        <RefreshTable handleReload={handleReload} />
                      ) : (
                        <TableContainer className={classes.tableContainer}>
                          <Table
                            className={classes.table}
                            aria-label="sn table"
                            size="small"
                            stickyHeader
                          >
                            <TableHead
                              style={{ height: "45px", background: "white" }}
                            >
                              <TableRow>
                                <TableCell>
                                  <Box>
                                    <Typography variant="body2">
                                      <b>{getLang(lang, "label.SERIAL_NO")}</b>
                                    </Typography>
                                    <Typography variant="body2">
                                      {getLang(lang, "label.FROM")}
                                    </Typography>
                                  </Box>
                                </TableCell>
                                <TableCell style={{ verticalAlign: "bottom" }}>
                                  <Typography variant="body2">
                                    {getLang(lang, "label.TO")}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Box>
                                    <Typography variant="body2">
                                      <b>
                                        {getLang(lang, "label.SEQUENCE_NO")}
                                      </b>
                                    </Typography>
                                    <Typography variant="body2">
                                      {getLang(lang, "label.FROM")}
                                    </Typography>
                                  </Box>
                                </TableCell>
                                <TableCell style={{ verticalAlign: "bottom" }}>
                                  <Typography variant="body2">
                                    {getLang(lang, "label.TO")}
                                  </Typography>
                                </TableCell>
                                <TableCell align="right">
                                  {getLang(lang, "label.QUANTITY")}
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {isFetching
                                ? [...new Array(5)].map((v, index) => (
                                  <SkeletonTableRow
                                    key={index}
                                    columnCount={5}
                                  />
                                ))
                                : failJobList.map((job, index) => {
                                  return (
                                    <TableRow
                                      key={index}
                                      hover
                                      role="checkbox"
                                      tabIndex={-1}
                                    >
                                      <TableCell>
                                        <Box
                                          display="flex"
                                          justifyContent="space-between"
                                          alignItems="center"
                                        >
                                          <Typography
                                            variant="body2"
                                            style={{ marginRight: 8 }}
                                          >
                                            {job.from_sn}
                                          </Typography>
                                          <Typography>
                                            <b>-</b>
                                          </Typography>
                                        </Box>
                                      </TableCell>
                                      <TableCell>
                                        <Typography variant="body2">
                                          {job.to_sn}
                                        </Typography>
                                      </TableCell>
                                      <TableCell>
                                        <Box
                                          display="flex"
                                          justifyContent="space-between"
                                          alignItems="center"
                                        >
                                          <Typography
                                            variant="body2"
                                            style={{ marginRight: 8 }}
                                          >
                                            {job.from_seq_num}
                                          </Typography>
                                          <Typography>
                                            <b>-</b>
                                          </Typography>
                                        </Box>
                                      </TableCell>
                                      <TableCell>
                                        <Typography variant="body2">
                                          {job.to_seq_num}
                                        </Typography>
                                      </TableCell>
                                      <TableCell align="right">
                                        <Typography variant="body2">
                                          {convertToNumberString(
                                            job.quantity
                                          )}
                                        </Typography>
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      )}
                    </Box>
                  </Box>
                )}

                <Box p={1.5} mb={0.5}>
                  <Box mb={2}>
                    <FormLabel>
                      <Typography variant="body1" style={{ color: "black" }}>
                        <b>{getLang(lang, "label.FILE_FORMAT")}</b>
                      </Typography>
                    </FormLabel>
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={formik.values.format}
                        onChange={({ target: { value } }) => {
                          formik.setFieldValue("format", value);
                        }}
                      >
                        {formats.map((f, i) => (
                          <FormControlLabel
                            key={i}
                            value={f}
                            control={<Radio />}
                            label={
                              <Typography
                                variant="body2"
                                style={{ marginRight: 4 }}
                              >
                                <b>.{f}</b>
                              </Typography>
                            }
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </Box>
                  <Box>
                    <FormLabel>
                      <Typography variant="body1" style={{ color: "black" }}>
                        <b>{getLang(lang, "label.INFORMATION")}</b>
                      </Typography>
                    </FormLabel>
                    <FormControl>
                      <Box display="flex" flexWrap="wrap">
                        {fields.map((f, i) => (
                          <FormControlLabel
                            key={i}
                            disabled={
                              formik.values.fields.length > 0 &&
                              formik.values.fields[0] === "all" &&
                              f.value !== "all"
                            }
                            control={
                              <Checkbox
                                checked={formik.values.fields.includes(f.value)}
                                onChange={({ target: { checked } }) => {
                                  let temp = [...formik.values.fields];
                                  if (checked) {
                                    if (f.value === "all") {
                                      temp = [f.value];
                                    } else if (!temp.includes(f.value)) {
                                      temp.push(f.value);
                                    }
                                  } else {
                                    temp = temp.filter((t) => t !== f.value);
                                  }
                                  formik.setFieldValue("fields", temp);
                                }}
                              />
                            }
                            label={
                              <Typography
                                variant="body2"
                                style={{ marginRight: 4 }}
                              >
                                <b>{f.name}</b>
                              </Typography>
                            }
                          />
                        ))}
                      </Box>
                    </FormControl>
                  </Box>
                  {fileDropdown?.total > 1000000 && <Grid item lg={7} sm={11} xs={10}>
                    <Typography variant="body1" style={{ color: "black" }}>
                      <b>{getLang(lang, "label.FILE")}</b>
                    </Typography>
                    <InputTextField
                      select
                      hiddenLabel={formik.values.file.length}
                      name="file"
                      size="small"
                      fullWidth
                      margin="dense"
                      variant="filled"
                      style={{
                        maxWidth: 300
                      }}
                      InputProps={{
                        style: {
                          padding: "0"
                        }
                      }}
                      {...formik.getFieldProps("file")}
                      error={
                        formik.touched["file"] &&
                          formik.errors["file"]
                          ? true
                          : false
                      }
                      helperText={ErrorMessage({
                        name: "file",
                      })}
                      label={!formik.values.file.length ? getLang(lang, "placeholder.SELECT_A_FILE") : ""}
                    >
                      {fileDropdown.data?.map((select, index) => (
                        <MenuItem
                          key={index}
                          value={select}
                          onClick={() => formik.setFieldValue("file", select)}
                        >
                          {getLang(lang, "label.FILE")} {index + 1}
                        </MenuItem>
                      ))}
                    </InputTextField>
                  </Grid>}
                </Box>
                <Box className={classes.warningBox} mb={2}>
                  <Box mb={0.5}>
                    <Typography
                      variant="body2"
                      className={classes.warningTitle}
                    >
                      <b>{getLang(lang, "label.TIPS")}:</b>
                    </Typography>
                  </Box>
                  <Typography variant="body2" className={classes.warningText}>
                    {getLang(lang, "paragraph.EXPORT_OPTION_INSTRUCTION")}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                  style={{ background: "white" }}
                  p={2}
                >
                  <Button
                    variant="outlined"
                    type="button"
                    color="primary"
                    onClick={handleClose}
                    style={{ marginRight: 16 }}
                  >
                    {getLang(lang, "label.CANCEL")}
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    disabled={
                      !fileDropdown ||
                      (fileDropdown?.total > 1000000 && !formik.values.file.length) ||
                      !formik.values.format ||
                      !formik.values.fields.length ||
                      isFetching
                    }
                  >
                    {getLang(lang, "label.EXPORT")}
                  </Button>

                </Box>
              </Box>
            </form>
          )}
        </Formik>
      </Dialog>
    )
  );
}
