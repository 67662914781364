import React from "react";
import {
  Box,
  makeStyles,
  Typography,
  Button,
  Link,
  Divider
} from "@material-ui/core";
import ArrowForwardRoundedIcon from "@material-ui/icons/ArrowForwardRounded";
import { convertToNumberString } from "lib/generalUtility";
import CheckoutSuccessBg from "../../../utils/image/checkoutSuccessBg.png";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles(theme => ({
  root: {
    backgroundImage: `url(${CheckoutSuccessBg})`,
    color: theme.palette.common.white,
    backgroundColor: "#92C892"
  }
}));

function SuccessCheckout({ handleClickOk, handleReset, postPaymentData, billingPage, lang }) {
  const classes = useStyle();

  return (
    <Box className={classes.root}>
      <Box
        display="flex"
        pt={5}
        pb={4}
        flexDirection="column"
        alignItems="center"
      >
        <Typography gutterBottom variant="h5" style={{ fontWeight: "bold" }}>
          {getLang(lang, 'paragraph.THANK_YOU_FOR_YOUR_PURCHASE')}
        </Typography>
        <Typography
          gutterBottom
          variant="body1"
          style={{ textAlign: "center" }}
        >
          {getLang(lang, 'paragraph.YOU_HAVE_PURCHASED')} {convertToNumberString(postPaymentData.quantity)}
          &nbsp; {getLang(lang, 'paragraph.QR_CREDIT')}
          <br />
          {getLang(lang, 'paragraph.YOUR_QR_CREDIT_IS')}&nbsp;
          {convertToNumberString(postPaymentData.credits)}
        </Typography>
        <Box my={2}>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            size="large"
            onClick={handleClickOk}
          >
            {getLang(lang, 'label.OK')}
          </Button>
        </Box>
        <Link
          component="button"
          variant="body2"
          color="inherit"
          onClick={handleReset}
        >
          {getLang(lang, 'label.MAKE_ANOTHER_PURCHASE')}
        </Link>
      </Box>
      <Divider />
      <Box
        display="flex"
        justifyContent="space-between"
        py={2}
        px={3}
        alignItems="center"
      >
        <Typography variant="body2">
          {getLang(lang, 'label.REFERENCE_ID')}&nbsp;
          <span style={{ fontWeight: "bold" }}>{postPaymentData.order_id}</span>
        </Typography>
        <Button
          variant="text"
          endIcon={<ArrowForwardRoundedIcon color="inherit" />}
          color="inherit"
          onClick={billingPage}
        >
          {getLang(lang, 'label.MY_PURCHASE')}
        </Button>
      </Box>
    </Box>
  );
}

export default SuccessCheckout;
