import React from "react";
import CircularChartComponent from './circularChart.component';

export default function CircularChartContainer({
  type,
  data,
  labels
}) {
  return (
    <CircularChartComponent value={data} labels={labels} type={type} />
  )
}