/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectLoading } from "modules/notification";
import { generatePaginationOptions, updateUrlQueryParam } from "lib/helper";
import {
  getAdtSnListing,
  resetSnAfterKey,
  updateAdtSnPageSetting,
} from "modules/serial-number/redux";
import SnListingPanelComponent from "./snListingPanel.page";
import qs from "query-string";
import { useLocation } from "react-router";

function SnListingPanelContainer({ selectedAdtInfos, handleViewDetail }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const totalFiltered = useSelector(
    (state) => state.additionalInfo.serialNumbers.totalFiltered
  );
  const snList = useSelector(
    (state) => state.additionalInfo.serialNumbers.list
  );
  const totalRecords = useSelector(
    (state) => state.additionalInfo.serialNumbers.totalRecords
  );
  const isLoading = useSelector((state) =>
    selectLoading(state, getAdtSnListing.typePrefix)
  );
  const afterKey = useSelector(
    (state) => state.additionalInfo.serialNumbers.afterKey
  );
  const language = useSelector((state) => state.profile.language);
  const productDropdown = useSelector((state) => state.products.allProducts);
  const lang = useSelector((state) => state.constant.languages);
  const displayLang = useSelector((state) => state.additionalInfo.displayLang);
  const snFilter = useSelector((state) => state.additionalInfo.snPageSetting);

  const qsParams = qs.parse(location.search);
  const isSearchResult = totalFiltered < totalRecords;
  const [paginationOptions, setPaginationOptions] = useState([]);
  const [urlLoaded, setUrlLoaded] = useState(false);

  useEffect(() => {
    const isFilter = !!qsParams.is_filter;
    let filterSn = sessionStorage.getItem("adt_info_sn_filter");

    let snTemp = { ...snFilter };
    if (isFilter && filterSn) {
      snTemp = JSON.parse(filterSn);
      dispatch(updateAdtSnPageSetting(snTemp));
    }

    if (isFilter) {
      updateUrlQueryParam(null, ["is_filter"]);
    }
    sessionStorage.removeItem("adt_info_sn_filter");

    setUrlLoaded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (urlLoaded && selectedAdtInfos.length) {
      fetchData();
    }
  }, [snFilter, selectedAdtInfos, urlLoaded]);

  useEffect(() => {
    setPaginationOptions(generatePaginationOptions(totalRecords));
  }, [totalRecords]);

  const fetchData = () => {
    let adtInfos = [];
    snFilter.additionalInfo.forEach((a) => {
      adtInfos.push({
        uuid: a.info.id,
        format: a.info.format,
        search: a.search,
      });
    });

    dispatch(
      getAdtSnListing({
        length: snFilter.length,
        start: snFilter.length * snFilter.start,
        search: snFilter.search,
        searchColumn: snFilter.searchColumn,
        node_uuids: snFilter.node_uuids,
        product_uuids: snFilter.product_uuids,
        additional_info_uuids: selectedAdtInfos
          .map((field) => field.id)
          .join(","),
        productNotAssigned: snFilter.productNotAssigned,
        additionalInfo: adtInfos,
        afterKey: afterKey ? afterKey[snFilter.start] : null,
        displayLang: displayLang,
      })
    );
  };

  const handleChangePage = (event, newPage) => {
    let newData = {
      ...snFilter,
      start: newPage,
    };

    dispatch(updateAdtSnPageSetting(newData));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(resetSnAfterKey());
    let newData = {
      ...snFilter,
      start: 0,
      length: event.target.value,
    };
    dispatch(updateAdtSnPageSetting(newData));
  };

  const handleSearch = (values) => {
    dispatch(resetSnAfterKey());

    let tempItem = values.products.map((item) => item.id);
    let tempBranch = values.branch.map((branch) => branch.node_id);

    let newData = {
      ...snFilter,
      start: 0,
      search: values.search,
      searchColumn: values.searchColumn,
      node_uuids: tempBranch.join(","),
      product_uuids: tempItem.join(","),
      products: values.products,
      branch: values.branch,
      additionalInfo: values.additionalInfo,
      productNotAssigned: values.productNotAssigned,
    };
    dispatch(updateAdtSnPageSetting(newData));
  };

  const clearSearch = () => {
    let newData = {
      ...snFilter,
      start: 0,
      search: "",
      searchColumn: "serial_number",
      node_uuids: [],
      product_uuids: [],
      products: [],
      branch: [],
      additionalInfo: [],
      productNotAssigned: false,
    };
    dispatch(resetSnAfterKey());
    dispatch(updateAdtSnPageSetting(newData));
  };

  const viewDetail = (url) => {
    handleViewDetail("sn", url);
  };

  return (
    <>
      <SnListingPanelComponent
        snList={snList}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        isFetching={isLoading}
        length={totalFiltered}
        handleSearch={handleSearch}
        clearSearch={clearSearch}
        // setSearchColumn={setSearchColumn}
        language={language}
        handleReload={fetchData}
        paginationOptions={paginationOptions}
        productDropdown={productDropdown}
        lang={lang}
        tableFilter={snFilter}
        handleViewDetail={viewDetail}
        isSearchResult={isSearchResult}
        additionalFields={selectedAdtInfos}
      />
    </>
  );
}

export default SnListingPanelContainer;
