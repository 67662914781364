import React from "react";
import ParticipantDetailDialogComponent from "./participantDetailDialog.component";
import { useDispatch, useSelector } from "react-redux";
import { closeParticipantDialog } from "modules/instant-lucky-draw/redux";

function ParticipantDialogContainer() {
  const dispatch = useDispatch();
  const isOpen = useSelector(
    (state) =>
      state.instantLuckyDraw.participantsDetail.detailDialogSetting.isOpen
  );
  const participantDetail = useSelector(
    (state) => state.instantLuckyDraw.participantsDetail
  );
  const lang = useSelector(state => state.constant.languages)
  const language = useSelector(state => state.profile.language)

  const handleView = async (value) => {
    window.open(value, "_blank");
  };

  return (
    <ParticipantDetailDialogComponent
      isOpen={isOpen}
      handleClose={() => dispatch(closeParticipantDialog())}
      participantDetail={participantDetail}
      handleView={handleView}
      lang={lang}
      language={language}
    />
  );
}

export default ParticipantDialogContainer;
