import axios from "axios";

const detailsSerialNumber = ({
  enc
}) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/serialnumber/api/v1/serialnumber/${enc}`)
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

export default detailsSerialNumber;
