import React from "react";
import {
  Box,
  //makeStyles,
  Typography
} from "@material-ui/core";
import { BackButton } from "components/button";
import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";
import DealerIncentiveProgramForm from 'modules/stockflow/components/form/dealerIncentiveProgramForm';
import { getLang } from "app/feature/constants";

export default function AddIncentiveProgramComponent({
  productDropdown,
  maxTier,
  handleSubmit,
  lang,
  isSubmitting,
  isFetchingProduct,
}) {

  return (
    <Box>
      <Box mb={4} display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" alignItems="flex-end" fontWeight="bold">
          <BackButton />
          <Typography variant='h6' color='primary'>
            {getLang(lang, 'label.DEALER_INCENTIVE_PROGRAM')}
          </Typography>
          <Box mx={0.5}>
            <ChevronRightRoundedIcon color="disabled" />
          </Box>
          <Box>
            <Typography variant="h6" color="primary">
              {getLang(lang, 'label.ADD_DEALER_INCENTIVE_PROGRAM')}
            </Typography>
          </Box>
        </Box>
      </Box>
      <DealerIncentiveProgramForm 
        type="create" 
        productDropdown={productDropdown}
        maxTier={maxTier}
        handleSubmit={handleSubmit}
        lang={lang}
        isSubmitting={isSubmitting}
        isFetchingProduct={isFetchingProduct}
      />
    </Box>
  )
}
