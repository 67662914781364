import React from "react";
import { Box, Button, Typography, makeStyles } from "@material-ui/core";
import { getLang } from "app/feature/constants";
import InfiniteScroll from "react-infinite-scroll-component";
import LoadingComponent from "components/loading";
import DefaultImg from "assets/img/img-default.png";
import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";

const useStyles = makeStyles((theme) => ({
  productRow: {
    border: "1px solid #D0D5DD",
    "& > div:first-child": {
      borderBottom: "1px solid #D0D5DD",
    },
    "& > div:last-child": {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      "& > div:first-child": {
        borderRight: "1px solid #D0D5DD",
      },
    },
  },
  productPhoto: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #0000001a",
    width: "45px",
    minWidth: "45px",
    height: "45px",
    borderRadius: "4px",
    "& img": {
      height: "100%",
      width: "100%",
    },
  },
  list: {
    maxHeight: "70vh",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "5px",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "16px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#E4E7EC",
      borderRadius: "16px",
    },
  },
  summaryRow: {
    backgroundColor: "#FFFFFF",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    border: "1px solid #D0D5DD",
    "& > div:first-child": {
      borderRight: "1px solid #D0D5DD",
    },
  },
  panel: {
    backgroundColor: "white",
    padding: 16,
  },
  attributeText: {
    color: "#b2b2b2",
    fontSize: 12,
    whiteSpace: "break-spaces",
  },
}));

export default function ReceiveItemsProductListPanelComponent({
  lang,
  isFetching,
  list,
  totalFiltered,
  isError,
  handleFetchMoreItems,
  hasNextPage,
  handleReload,
  viewProduct,
  status,
}) {
  const classes = useStyles();
  const PARTIAL_RECEIVE = "Partial Receive";

  return (
    <Box className={classes.panel}>
      <Box mb={2}>
        <Typography variant="h6">{getLang(lang, "label.PRODUCT")}</Typography>
      </Box>
      {isError ? (
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
          pt={2}
          pb={3}
        >
          <Typography
            variant="body2"
            color="primary"
            style={{ fontWeight: "bold", paddingBottom: "8px" }}
          >
            {getLang(lang, "label.NO_DATA_RECEIVED")}
          </Typography>
          <Typography
            variant="body2"
            style={{ color: "#9CA6A9", paddingBottom: "16px" }}
          >
            {getLang(lang, "label.SOMETHING_WENT_WRONG")}
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleReload}
            disableElevation
          >
            {getLang(lang, "label.REFRESH")}
          </Button>
        </Box>
      ) : (
        <InfiniteScroll
          height="100%"
          className={classes.list}
          dataLength={list.length}
          next={() => {
            if (hasNextPage) handleFetchMoreItems();
          }}
          hasMore={totalFiltered > list.length}
          loader={<LoadingComponent height={100} />}
          endMessage={
            isFetching ? (
              <LoadingComponent height={100} />
            ) : !list.length ? (
              <Box px={1.5} py={4}>
                <Typography variant="body2" style={{ textAlign: "center" }}>
                  <b>{getLang(lang, "label.NO_PRODUCT")}</b>
                </Typography>
              </Box>
            ) : null
          }
        >
          {[...list].map((item, i) => (
            <Box key={i} className={classes.productRow} mt={i === 0 ? 0 : 2}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p={1}
              >
                <Box display="flex" alignItems="center">
                  <Box className={classes.productPhoto} mr={2}>
                    {!item?.picture ? (
                      <img src={DefaultImg} alt="emptyProduct" />
                    ) : (
                      <img src={item.picture} alt={item.name} />
                    )}
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="flex-start"
                  >
                    <Typography variant="body1">
                      <b>{item.name}</b>
                    </Typography>{" "}
                    {!!item.attributes && !!item.attributes.length && (
                      <Typography
                        className={classes.attributeText}
                        variant="body2"
                      >
                        {item.attributes.join(", ")}
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Box display="flex" alignItems="center">
                  {item.total_transfer - item.total_receive === 0 && (
                    <CheckCircleRoundedIcon
                      style={{
                        color: "#32D583",
                        fontSize: 20,
                        marginRight: 6,
                      }}
                    />
                  )}
                  <ChevronRightRoundedIcon
                    color="primary"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      viewProduct(item);
                    }}
                  />
                </Box>
              </Box>
              <Box>
                <Box p={1}>
                  <Box>
                    <Typography variant="body1">
                      <b>{item.total_receive.toLocaleString("en-US")}</b>
                    </Typography>
                    <Typography variant="body2">
                      {getLang(lang, "label.RECEIVED")}
                    </Typography>
                  </Box>
                </Box>
                <Box p={1}>
                  <Box>
                    <Typography
                      variant="body1"
                      style={{
                        color:
                          item.total_transfer - item.total_receive !== 0
                            ? "#F97066"
                            : "#101828",
                      }}
                    >
                      <b>
                        {(
                          item.total_transfer - item.total_receive
                        ).toLocaleString("en-US")}
                      </b>
                    </Typography>
                    <Typography variant="body2">
                      {status === PARTIAL_RECEIVE
                        ? getLang(lang, "label.PENDING")
                        : getLang(lang, "label.MISSING")}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          ))}
        </InfiniteScroll>
      )}
    </Box>
  );
}
