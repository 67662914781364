import React from "react";
import {
  makeStyles,
  Paper,
  Typography,
  Grid,
  Box,
  Divider,
  Button,
  FormHelperText,
  Backdrop,
  Modal,
} from "@material-ui/core";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import InputTextField from "components/input/inputTextField";
import AntSwitch from "components/switch";
import EditorComponent from "components/editor/editor.container";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";

import UploadDocumentComponent from "components/uploadDocument/uploadDocument.container";
import { Formik, ErrorMessage } from "formik";
import DateTimePicker from "components/input/dateTimePicker";
import moment from "moment";
import "moment/min/locales.min";
import * as Yup from "yup";
import { AddImageSvg } from "modules/product-mgmt/utils/staticSvg";
import clsx from "clsx";
import AssignProductSelect from "components/select/assignProductSelect";
import AssignProductTable from "components/table/assignProductTable";
import { isFile, isFileExceedLimit } from "lib/helper";
import { addAlert } from "modules/notification";
import { useDispatch } from "react-redux";
import PerfectScrollbar from "react-perfect-scrollbar";
import { getLang } from "app/feature/constants";
import { Document, Page, pdfjs } from "react-pdf";
import ImageEditorPanel from "../../../../../components/panel/landingPageImageEditorPanel";
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const style = (theme) => ({
  root: {
    maxWidth: "100%",
    width: "1100px",
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    }
  },
  gridFieldPeriod: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    paddingBottom: "7px !important"
  },
  gridEditField: {
    paddingTop: "10px !important"
  },
  input: {
    display: "none"
  },
  switchContainer: {
    display: "grid",
    paddingTop: theme.spacing(3),
    gridTemplateColumns: "3fr 1fr 10fr",
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "3fr 1fr 4fr"
    }
  },
  switch: {
    display: "flex",
    alignItems: "center"
  },
  helperText: {
    marginTop: 0
  },
  textDisable: {
    fontWeight: "bold",
    paddingLeft: "8px",
    color: "rgba(58, 77, 84, 0.33)"
  },
  textActive: {
    fontWeight: "bold",
    paddingLeft: "8px",
    color: theme.palette.primary.main
  },
  viewTC: {
    background: "#EBEDEE",
    borderRadius: "0px 0px 4px 4px",
    padding: theme.spacing(1, 4, 1, 3),
    wordBreak: "break-all",
    height: "100%"
  },
  viewUrl: {
    wordBreak: "break-all",
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(2)
  },
  scroll: {
    overflowY: "auto",
    height: "230px"
  },
  dateView: {
    paddingRight: theme.spacing(1)
  },
  dateContainer: {
    height: 43,
    backgroundColor: "#ECEFF0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 60,
    borderRadius: "4px 0px 0px 4px"
  },
  divider: {
    height: "100%",
    display: "flex",
    alignSelf: "center"
  },
  dividerEdit: {
    height: "70%"
  },
  dividerContainer: {
    backgroundColor: "#ECEFF0",
    display: "flex"
  },
  button: {
    backgroundColor: "#ECEFF0",
    marginBottom: "4px",
    boxShadow: "none",
    fontSize: "14px",
    fontFamily: "roboto",
    color: "rgba(32, 32, 32, 0.33)",
    width: "100%",
    height: 43,
    borderRadius: 4,
    display: "flex",
    placeContent: "start",
    padding: "5px 10px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#ECEFF0",
      boxShadow: "none"
    }
  },
  buttonLabel: {
    display: "flex",
    justifyContent: "space-between"
  },
  buttonLabelImage: {
    color: "rgba(0, 0, 0, 0.87)"
  },
  endIcon: {
    marginRight: 0
  },
  photoContainer: {
    "& img": {
      maxWidth: 200,
      maxHeight: 200,
    },
  },
  gridContainerField: {
    paddingTop: theme.spacing(1)
  },
  checkbox: {
    padding: "0px 9px 0px 0px"
  },
  dropdown: {
    paddingTop: 12,
    paddingBottom: 12,
    borderRadius: 4
  },
  gridContainereView: {
    paddingBottom: theme.spacing(2)
  },
  productTableView: {
    marginTop: "-16px"
  },
  pageControl: {
    background: "white",
    borderRadius: "4px"
  },
  pdfScrollContainer: {
    width: "40%",
    display: "flex",
    height: "21em",
    flexDirection: "column",
    alignItems: "center",
    border: "1px solid #00001A",
    borderRadius: "4px",
  },
  imageInputBox: {
    width: 130,
    height: 130,
    backgroundColor: theme.palette.primary.main,
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: theme.spacing(0.5),
    margin: theme.spacing(2, 0),
    transition: theme.transitions.create("background-color", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
});

const useStyles = makeStyles(style);

export default function InstantLuckyDrawFormComponent({
  children,
  handleSubmit,
  instantLuckyDrawDetail,
  type,
  lang,
  onDocumentLoadSuccess,
  pageNumber,
  numPages,
  handleTncPdfChangePage,
  setLuckyDrawImageFile,
  luckyDrawImageFile,
  isCropping,
  handleCloseCropping,
  handleOpenCropping,
  language
}) {
  const classes = useStyles();
  const dispatch = useDispatch(); 
  
  if(language==="ZH")
  {
    moment.locale('zh-cn')
  }else if(language==="TL")
  {
    moment.locale('tl-ph')
  }else
  {
    moment.locale(language)
  }


  const viewType = type === "view" ? true : false;

  const options = {
    cMapUrl: 'cmaps/',
    cMapPacked: true,
    standardFontDataUrl: 'standard_fonts/',
  };

  const renderFormField = (field, index, formik) => {
    switch (field.type) {
      case "date":
        return [
          {
            label: getLang(lang, "label.FROM"),
            date: "eventPeriodStart"
          },
          {
            label: getLang(lang, "label.TO"),
            date: "eventPeriodEnd"
          },
        ].map((field, index) => (
          <Grid
            item
            xs={7}
            sm={4}
            md={4}
            key={index}
            className={classes.gridFieldPeriod}
          >
            <Box display="flex">
              <Box
                className={clsx({
                  [classes.dateView]: viewType,
                  [classes.dateContainer]: !viewType,
                })}
              >
                <Typography
                  variant="subtitle2"
                  style={{
                    fontWeight: "bold",
                    color: " rgba(58, 77, 84, 0.33)"
                  }}
                >
                  {field.label}
                </Typography>
              </Box>
              <Box className={classes.dividerContainer}>
                <Divider
                  orientation="vertical"
                  className={clsx(classes.divider, {
                    [classes.dividerEdit]: !viewType
                  })}
                />
              </Box>
            </Box>

            {viewType ? (
              <Typography
                variant="body2"
                color="primary"
                style={{
                  fontWeight: "bold",
                  paddingLeft: "16px"
                }}
              >
                {moment(formik.getFieldProps(field.date).value).format('lll')}
              </Typography>
            ) : (
              <DateTimePicker
                required
                label=""
                disabled={formik.isSubmitting || (field.label === getLang(lang, 'label.FROM') && moment(instantLuckyDrawDetail.eventPeriodStartStatic).isBefore(moment()))}
                id={field.label + "-picker-dialog"}
                value={formik.getFieldProps(field.date).value}
                handleChange={(v) => {
                  formik.setFieldValue(field.date, v.format("YYYY-MM-DD HH:mm"))
                  if (
                    field.date === "eventPeriodStart" && moment(v).isSameOrAfter(moment(formik.values.eventPeriodEnd))
                  ) {
                    formik.setFieldValue("eventPeriodEnd", moment(v).add(1, 'minutes').format("YYYY-MM-DD HH:mm"))
                  }

                  if (
                    field.date === "eventPeriodEnd" && moment(v).isSameOrBefore(moment(formik.values.eventPeriodStart))
                  ) {
                    formik.setFieldValue("eventPeriodEnd", moment(formik.values.eventPeriodStart).add(1, 'minutes').format("YYYY-MM-DD HH:mm"))
                  }
                }}
                InputPropStyle={{
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0
                }}
                minDate={
                  field.label === getLang(lang, "label.TO")
                    ? moment(formik.values.eventPeriodStart)
                    : new Date()
                }
              />
            )}
          </Grid>
        ));
      case "text":
      case "paragraph":
        return (
          <Grid item xs={7} sm={8} md={8}>
            {viewType ? (
              <Typography
                variant="body2"
                color="primary"
                style={{ fontWeight: "bold" }}
              >
                {formik.getFieldProps(field.name).value}
              </Typography>
            ) : (
              <InputTextField
                required
                variant="filled"
                size="small"
                fullWidth
                multiline={field.type === "paragraph"}
                rows={field.type === "paragraph" ? 3 : null}
                placeholder={field.label}
                {...formik.getFieldProps(field.name)}
                error={
                  formik.touched[field.name] && formik.errors[field.name]
                    ? true
                    : false
                }
                helperText={ErrorMessage({
                  name: field.name
                })}
              />
            )}
          </Grid>
        );

      case "image":
        return (
          <>
            <Grid item xs={7} sm={8} md={8}>
              {viewType ? (
                <Box className={classes.photoContainer}>
                  {instantLuckyDrawDetail ? (
                    <img src={instantLuckyDrawDetail.image} alt="instant" />
                  ) : (
                    getLang(lang, "label.NO_PHOTO")
                  )}
                </Box>
              ) : (
                <>
                  <input
                    accept="image/*"
                    className={classes.input}
                    id={"contained-button-file" + index}
                    type="file"
                    onChange={(e) => {
                      e.persist();
                      if (e.currentTarget.files.length > 0) {
                        if (isFileExceedLimit(e.currentTarget.files[0].size, 12, 'MB')) {
                          dispatch(addAlert({
                            severity: "error",
                            message: getLang(lang, "message.error.FORM_VALIDATE_FILE_SIZE", { value: 12 })
                          }));
                          return;
                        }
                        formik.setFieldValue('cropping', e.currentTarget.files[0]);
                        handleOpenCropping();
                      }
                      e.target.value = null;
                    }}
                  />
                  <Box disabled={formik.isSubmitting}>
                    <Box>
                      <label htmlFor={"contained-button-file" + index} style={{ cursor: "pointer" }}>
                        {formik.values.imageFile || formik.getFieldProps(field.name).value !== '' ? (
                          <Box
                            display="flex"
                            alignItems="center"
                            className={classes.photoContainer}
                          >
                            {!!formik.values.imageFile ? (
                              <img
                                src={
                                  formik.values.imageFile && isFile(formik.values.imageFile)
                                    ? URL.createObjectURL(formik.values.imageFile)
                                    : formik.values.imageFile
                                }
                                alt="poster"
                              />
                            ) : (
                              <img
                                src={formik.values.image}
                                alt="poster"
                              />
                            )}
                            <Typography
                              color="textSecondary"
                              variant="body2"
                              style={{ marginLeft: 12 }}
                            >
                              {getLang(lang, "label.CHOOSE_IMAGE")}
                            </Typography>
                          </Box>
                        ) : (
                          <Box className={classes.imageInputBox}>
                            <AddImageSvg />
                          </Box>
                        )}
                      </label>
                      <FormHelperText
                        error={formik.errors[field.name] ? true : false}
                      >
                        {ErrorMessage({ name: field.name })}
                      </FormHelperText>
                    </Box>
                    <Box display="flex" alignItems="center">
                      <ReportProblemOutlinedIcon
                        fontSize="small"
                        color="error"
                      />
                      &ensp;
                      <Typography
                        variant="caption"
                        style={{ color: "rgba(58, 77, 84, 0.33)" }}
                      >
                        {getLang(lang, "paragraph.RECOMMENDED_IMAGE_SIZE_CUSTOM", { resolution: '1024(w)px X 1024(h)', size: 12, type: 'JPG, JPEG & PNG' })}
                      </Typography>
                    </Box>
                  </Box>
                </>
              )}
            </Grid>
            <Modal
              style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
              open={isCropping}
              onClose={handleCloseCropping}
              closeAfterTransition
              BackdropComponent={Backdrop}
            >
              {isCropping && (
                <Box>
                  <ImageEditorPanel
                    isCircleRadius={false}
                    height={375}
                    width={375}
                    aspectRatio={[1, 1]}
                    img={isFile(formik.values.cropping) ? URL.createObjectURL(formik.values.cropping) : formik.values.cropping}
                    handleCancel={handleCloseCropping}
                    handleResult={async (value) => {
                      const newFileName = formik.values.cropping.name.split('.');
                      newFileName.pop();
                      newFileName.push('webp');

                      const file = await new File([await (await fetch(value)).blob()], newFileName.join('.'), {type: 'image/webp'});

                      formik.setFieldValue('imageFile', file);
                      handleCloseCropping();
                    }}
                  />
                </Box>
              )}
            </Modal>
          </>
        );
      case "select":
        return (
          <Grid item xs={7} sm={8} md={8}>
            {field.name === "productIds" && (
              <>
                {!viewType && (
                  <AssignProductSelect
                    value={formik.values.productIds}
                    handleChange={(value) =>
                      formik.setFieldValue("productIds", value)
                    }
                    disabled={type === "view"}
                  />
                )}
                <div
                  className={clsx({
                    [classes.productTableView]: viewType,
                  })}
                >
                  <AssignProductTable tableData={formik.values.productIds} />
                </div>
                {!viewType && (
                  <Typography
                    variant="caption"
                    style={{ color: "rgba(58, 77, 84, 0.33)" }}
                  >
                    {getLang(lang, "paragraph.PRODUCT_TIED_WITH_OTHER_ILD")}
                  </Typography>
                )}
              </>
            )}
          </Grid>
        );
      default:
        return null;
    }
  };

  return (
    <Paper elevation={0} className={classes.root}>
      <Formik
        initialValues={{
          name: instantLuckyDrawDetail.name || "",
          eventPeriodStart: instantLuckyDrawDetail.eventPeriodStart
            ? instantLuckyDrawDetail.eventPeriodStart
            : new moment().format("YYYY-MM-DD HH:mm"),
          eventPeriodEnd: instantLuckyDrawDetail.eventPeriodEnd
            ? instantLuckyDrawDetail.eventPeriodEnd
            : new moment().add(7, "day").format("YYYY-MM-DD HH:mm"),
          description: instantLuckyDrawDetail.description || "",
          image: instantLuckyDrawDetail.image || "",
          productIds: instantLuckyDrawDetail.productIds,
          termsConditionText:
            instantLuckyDrawDetail.termsConditionText,
          termsConditionDocument:
            instantLuckyDrawDetail.termsConditionDocument,
          termsConditionDetail:
            instantLuckyDrawDetail.termsConditionDetail || "",
          termsConditionDetailFile:
            instantLuckyDrawDetail.termsConditionDetailFile || null,
          termsAndConditions:
            instantLuckyDrawDetail.termsAndConditions,
          imageFile: luckyDrawImageFile || null
        }}
        validationSchema={Yup.object({
          name: Yup.string().trim().max(50, getLang(lang, "message.error.FORM_VALIDATE_MAX", { value: 50 })).required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
          eventPeriodStart: Yup.string().required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
          eventPeriodEnd: Yup.string().required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED"))
        })}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <Box paddingTop={"16px"} paddingRight={"24px"} paddingBottom={"24px"} paddingLeft={"24px"} height="55vh" overflow="auto">
              <Box>
                {[
                  {
                    title: getLang(lang, "label.INSTANT_LUCKY_DRAW_NAME"),
                    label: getLang(lang, "placeholder.ENTER_INSTANT_LUCKY_DRAW_NAME"),
                    name: "name",
                    type: "text"
                  },
                  {
                    title: getLang(lang, "label.EVENT_PERIOD"),
                    type: "date"
                  },
                  {
                    title: getLang(lang, "label.DESCRIPTION"),
                    label: getLang(lang, "placeholder.ENTER_DESCRIPTION"),
                    name: "description",
                    type: "paragraph"
                  },
                  {
                    title: getLang(lang, "label.INSTANT_LUCKY_DRAW_IMAGE"),
                    label: getLang(lang, "placeholder.UPLOAD_IMAGE"),
                    name: "image",
                    type: "image"
                  },
                  {
                    title: getLang(lang, "label.ASSIGN_PRODUCT"),
                    label: getLang(lang, "placeholder.SELECT_PRODUCT"),
                    name: "productIds",
                    type: "select"
                  },
                ].map((field, index) => (
                  <Grid
                    container
                    key={index}
                    spacing={1}
                    className={clsx(classes.gridContainerField, {
                      [classes.gridContainereView]: viewType
                    })}
                  >
                    <Grid
                      item
                      xs={5}
                      sm={4}
                      md={4}
                      className={clsx({
                        [classes.gridEditField]: !viewType
                      })}
                    >
                      <Typography variant="body2" color="primary">
                        {field.title} <span style={{ color: "#DE350B", display: 'inline-block' }}>*</span>
                      </Typography>
                    </Grid>
                    {renderFormField(field, index, formik)}
                  </Grid>
                ))}

                <Typography variant="body2" color="primary">
                  {getLang(lang, "label.TNC")}  <span style={{ color: "#DE350B", display: 'inline-block' }}>*</span>
                </Typography>
                {type === "view" ? (
                  <Box>
                    <PerfectScrollbar
                      className={classes.scroll}
                      options={{
                        suppressScrollX: true,
                      }}
                    >
                      <Box
                        className={clsx({
                          [classes.viewUrl]: formik.getFieldProps("termsConditionDocument").value,
                          [classes.viewTC]: formik.getFieldProps("termsConditionText").value
                        })}
                      >
                        {instantLuckyDrawDetail.termsConditionDocument ? (
                          <>
                            <Box>
                              <Document
                                file={formik.getFieldProps("termsAndConditions").value}
                                onLoadSuccess={onDocumentLoadSuccess}
                                options={options}
                              >
                                <Page pageNumber={pageNumber} />
                              </Document>
                            </Box>
                          </>
                        ) : (
                          <>
                            <Box
                              dangerouslySetInnerHTML={{
                                __html: formik.getFieldProps("termsConditionDetail").value.value,
                              }}
                            />
                          </>
                        )}
                      </Box>
                    </PerfectScrollbar>
                    {formik.getFieldProps("termsConditionDocument").value && numPages !== null && numPages > 0 && (
                      <Box className={classes.pageControl}>
                        <Button onClick={() => handleTncPdfChangePage(false)} disabled={pageNumber === 1}>
                          <ChevronLeftIcon />
                        </Button>
                        <span>Page {pageNumber} of {numPages}</span>
                        <Button onClick={() => handleTncPdfChangePage(true)} disabled={pageNumber === numPages}>
                          <ChevronRightIcon />
                        </Button>
                      </Box>
                    )}
                  </Box>
                ) : (
                  <>
                    <Box className={classes.switchContainer}>
                      <Box className={classes.switch}>
                        <AntSwitch
                          checked={
                            formik.getFieldProps("termsConditionText").value
                          }
                          onClick={(e) => {
                            formik.setFieldValue("termsConditionDocument", false)

                            formik.setFieldValue("termsConditionText", true);
                          }}
                          disabled={formik.isSubmitting}
                        />
                        <Typography
                          variant="body2"
                          className={clsx(classes.textDisable, {
                            [classes.textActive]:
                              formik.getFieldProps("termsConditionText")
                                .value,
                          })}
                        >
                          {getLang(lang, "label.TEXT_BOX")}
                        </Typography>
                      </Box>

                      <Typography
                        variant="body2"
                        style={{ fontWeight: "bold" }}
                      >
                        {getLang(lang, "label.OR")}
                      </Typography>

                      <Box className={classes.switch}>
                        <AntSwitch
                          checked={
                            formik.getFieldProps("termsConditionDocument").value
                          }
                          onClick={(e) => {
                            formik.setFieldValue("termsConditionText", false);

                            formik.setFieldValue("termsConditionDocument", true);
                          }}
                          disabled={formik.isSubmitting}
                        />
                        <Typography
                          variant="body2"
                          className={clsx(classes.textDisable, {
                            [classes.textActive]: formik.getFieldProps(
                              "termsConditionDocument"
                            ).value,
                          })}
                        >
                          {getLang(lang, "label.UPLOAD_DOCUMENT")}
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      {formik.getFieldProps("termsConditionDocument").value && (
                        <UploadDocumentComponent
                          value={
                            formik.getFieldProps("termsConditionDetailFile").value
                          }
                          handleUpload={(file) => {
                            formik.setFieldValue("termsConditionDetailFile", file);
                          }}
                          reuploadFlag={type !== "create" && instantLuckyDrawDetail.termsAndConditions.substr(instantLuckyDrawDetail.termsAndConditions.length - 3) === "pdf"}
                          maxUploadSize={{ size: 15, unit: 'MB' }}
                        />
                      )}
                      {formik.getFieldProps("termsConditionText").value && (
                        <EditorComponent
                          type={type === "create" ? "new" : "edit"}
                          initialValues={
                            formik.getFieldProps("termsConditionDetail").value
                          }
                          handleChange={(value) => {
                            formik.setFieldValue("termsConditionDetail", value);
                          }}
                        />
                      )}
                    </Box>
                  </>
                )}
              </Box>
            </Box>
            {children ? children() : null}
          </form>
        )}
      </Formik>
    </Paper>
  );
}
