import React from "react";
import { makeStyles, CircularProgress, Fade, Typography, Box } from "@material-ui/core";
import clsx from "clsx";
import { UploadIconSvg } from '../../../utils/staticSvg';
import { getLang } from "app/feature/constants";

const useStyle = makeStyles(theme => ({
  dropArea: {
    padding: 20,
    width: "100%",
    backgroundColor: "white",
    backgroundImage: "url(\"data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='mediumseagreen' stroke-width='4' stroke-dasharray='11' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e\")",
    borderRadius: 8,
    height: "100%",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    "&:hover": {
      cursor: "pointer"
    }
  },
  active: {
    border: "2px solid #72B370",
    backgroundColor: "#F7FBF7",
    backgroundImage: "none"
  },
  loadingScreen: {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 999,
    height: "100%",
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "inherit",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexFlow: "column nowrap"
  },
  highlightText: {
    color: theme.palette.secondary.main,
    fontWeight: 500
  },
  smallText: {
    fontWeight: "unset"
  }
}));

export default function DragPanelComponent({
  getRootProps,
  getInputProps,
  isDragActive,
  isLoading,
  lang
}) {
  const classes = useStyle();

  const getClassName = () => {
    return clsx(classes.dropArea, { [classes.active]: isDragActive });
  };

  return (
    <>
      <Fade in={isLoading}>
        <div className={classes.loadingScreen}>
          <CircularProgress />
          <br />
          {getLang(lang,"label.PROCESSING")}
        </div>
      </Fade>

      <div className={getClassName()} {...getRootProps}>
        <Box style={{
          marginBottom: 10
        }}>
          <UploadIconSvg />
        </Box>
        <input className="dropzone-input" {...getInputProps()} />
        <div className="text-center">
          {isDragActive ? (
            <p className="dropzone-content">{getLang(lang,"paragraph.RELEASE_TO_DROP_FILES")}</p>
          ) : (
            <>
              <Typography color="textSecondary">
                <span className={classes.highlightText}>{getLang(lang,"paragraph.CLICK_TO_CHOOSE_FILE")}</span> {getLang(lang,"paragraph.DRAG_OR_DROP")}
              </Typography>
              <Typography className={classes.smallText} variant="subtitle2" color="textSecondary">
                {getLang(lang,"paragraph.IMAGE_RECOMMEND_TYPE_BRANDING")}
              </Typography>
            </>
          )}
        </div>
      </div>
    </>
  );
}
