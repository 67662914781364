export const ACCOUNT = "account";
export const SETTING = "setting";
export const STOCK_MODULE_SETTING = "stock_module_setting";
export const BRANCH = "branch";
export const DISBURSEMENT = "DISBURSEMENT";

export const COLOR_PRIMARY = "#3A4D54";
export const COLOR_SECONDARY = "#ECEFF0";

export const NODE_TYPE_COMPANY = "company";
export const NODE_TYPE_REGION = "region";
export const NODE_TYPE_BRANCH = "branch";

export const NODE_STATUS_ACTIVE = "active";
export const NODE_STATUS_INACTIVE = "inactive";