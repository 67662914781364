import axios from "axios";

// get survey details
export function getSurveyDropdown({ length, start, survey_name, product_id }) {
  return new Promise((resolve, reject) => {
    axios
      .get("/consumer/admin/api/v1/survey/available-dropdown", {
        params: {
          length,
          start,
          survey_name,
          product_id,
        },
      })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data));
  });
}
