import axios from "axios";

function removeCurrencyConfig({
  code,
}) {
  const url =  `/account/api/v1/company/currency/remove/${code}`;

  return new Promise((resolve, reject) => {
    axios.delete(url)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data))
  })
}

export default removeCurrencyConfig;