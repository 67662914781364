import axios from "axios";

export default function deleteBranchGroup(uuid) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/account/api/v1/branch-group/delete`, {
        branch_group_uuid: uuid,
      })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data));
  });
}
