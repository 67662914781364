import React from "react";
import {
  makeStyles,
  Box,
  Typography,
  Paper,
  RadioGroup,
  InputAdornment,
  FormControlLabel,
  Radio,
  Tabs,
} from "@material-ui/core";
import InputTextField from "components/input/inputTextField";
import InputHelper from "lib/InputHelper";
import CustomTab from "components/tab";
import CustomReasonsPanel from "../../panel/customReasonsPanel";
import languages from "lib/constants/languages";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles((theme) => ({
  inputContainer: {
    "&:not(:last-child)": {
      marginBottom: "20px",
    },
    padding: "20px",
  },
  inputLabel: {
    fontSize: "16px",
    color: "#101828",
  },
  inputSubLabel: {
    fontSize: "14px",
    color: "#98A2B3",
  },
  inputSecondarySubLabel: {
    fontSize: "14px",
    color: "#101828",
  },
  inputEndormentLabel: {
    fontSize: "14px",
    color: "#101828",
  },
}));

export default function CustomReasonSettingCardComponent({
  attribute,
  isEditable,
  formik,
  attributeValue,
  changeTab,
  activeIndex,
  tabLabels,
  updateReason,
  hasEmptyReasonField,
  lang,
  isSubmitting,
}) {
  const classes = useStyle();

  function a11yProps(index, data) {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tab-${index}`,
      label: <Typography variant="button" children={data?.label ?? ""} />,
    };
  }

  let type = tabLabels[activeIndex].value;

  return (
    <Paper className={classes.inputContainer} elevation={2}>
      <Typography className={classes.inputLabel}>{attribute.label}</Typography>
      {attribute.subLabel && (
        <Typography className={classes.inputSubLabel}>
          {attribute.subLabel}
        </Typography>
      )}
      {attribute.secondarySubLabel && (
        <Typography className={classes.inputSecondarySubLabel}>
          {attribute.secondarySubLabel}
        </Typography>
      )}
      {attribute.type === "number" && (
        <InputTextField
          value={attributeValue}
          type={attribute.type}
          disabled={!isEditable || isSubmitting}
          onPaste={(e) =>
            InputHelper.preventCopyEDecimalAndNegetiveInNumberInput(e)
          }
          onKeyDown={(e) =>
            InputHelper.preventTypeEDecimalAndNegetiveInNumberInput(e)
          }
          onChange={(e) =>
            formik.setFieldValue(
              attribute.fieldName,
              attribute.valuesType === "number"
                ? parseInt(e.target.value)
                : e.target.value
            )
          }
          InputProps={{
            endAdornment: attribute.endAdornment && (
              <InputAdornment position="end">
                <Box px={1} borderLeft="1px solid rgba(0, 0, 0, 0.1)">
                  <Typography className={classes.inputEndormentLabel}>
                    {attribute.endAdornment}
                  </Typography>
                </Box>
              </InputAdornment>
            ),
          }}
        />
      )}
      {attribute.type === "radio" && (
        <RadioGroup
          name={attribute.fieldName}
          defaultValue={attributeValue}
          value={attributeValue}
          onChange={(e) =>
            formik.setFieldValue(
              attribute.fieldName,
              attribute.valuesType === "number"
                ? parseInt(e.target.value)
                : e.target.value
            )
          }
        >
          {attribute.values.map((value, index) => (
            <Box key={index}>
              <FormControlLabel
                disabled={!isEditable || isSubmitting}
                label={value.label}
                value={value.value}
                control={<Radio />}
              />
              {value.enableCustomField && (
                <Box paddingLeft={4}>
                  <InputTextField
                    disabled={
                      !isEditable ||
                      attributeValue !== value.value ||
                      isSubmitting
                    }
                    value={formik.values[value.customFieldFieldName]}
                    type={value.customFieldType}
                    onPaste={(e) =>
                      InputHelper.preventCopyEDecimalAndNegetiveInNumberInput(e)
                    }
                    onKeyDown={(e) =>
                      InputHelper.preventTypeEDecimalAndNegetiveInNumberInput(e)
                    }
                    onChange={(e) =>
                      formik.setFieldValue(
                        value.customFieldFieldName,
                        e.target.value
                      )
                    }
                    InputProps={{
                      endAdornment: value.customFieldEndAdornment && (
                        <InputAdornment position="end">
                          <Box px={1} borderLeft="1px solid rgba(0, 0, 0, 0.1)">
                            <Typography className={classes.inputEndormentLabel}>
                              {value.customFieldEndAdornment}
                            </Typography>
                          </Box>
                        </InputAdornment>
                      ),
                    }}
                    inputProps={{
                      min: 1,
                    }}
                  />
                </Box>
              )}
            </Box>
          ))}
        </RadioGroup>
      )}
      {attributeValue === 1 && (
        <Box mt={2}>
          <Box>
            <Tabs
              value={activeIndex}
              onChange={changeTab}
              aria-label="scrollable auto tabs example"
              textColor="primary"
              variant="scrollable"
              indicatorColor="primary"
              classes={{
                indicator: classes.indicator,
                scrollButtons: classes.scroll,
              }}
            >
              {Object.entries(tabLabels).map(([key, value], index) => {
                return <CustomTab key={index} {...a11yProps(index, value)} />;
              })}
            </Tabs>
          </Box>
          <Box mt={2}>
            <CustomReasonsPanel
              type={type}
              reasons={
                formik.values.reasons &&
                Object.keys(formik.values.reasons).length
                  ? formik.values.reasons[type]?.reasons
                  : []
              }
              usedLang={
                formik.values.reasons &&
                Object.keys(formik.values.reasons).length
                  ? formik.values.reasons[type].usedLang
                  : [languages.find((lang) => lang.code === "EN")]
              }
              updateReason={updateReason}
              isSubmitting={isSubmitting}
            />
            {hasEmptyReasonField.includes(type) && (
              <Box mt={1}>
                <Typography style={{ color: "#f44336" }} variant="caption">
                  <b>{getLang(lang, "label.NOT_ALL_LANG_FILLED")}</b>
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Paper>
  );
}
