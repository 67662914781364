import React from 'react'
import SurveyListingTable from '../../table/surveyListingTable'
import { Box } from '@material-ui/core';
import SurveySearch from '../../search/surveySearch';

export default function SurveyListingPanelComponent({
  hasSurveyAddAccessRight,
  hasSurveyEditAccessRight,
  hasSurveyActivateOrDeactivateAccessRight,
  data,
  isFetching,
  page,
  search,
  filterStatus,
  dateCreated,
  productSelect,
  rowsPerPage,
  totalRecords,
  handleChangePage,
  handleChangeRowsPerPage,
  handleSearch,
  handleCreate,
  handleStatusChange,
  openEditSurveyModal,
  handleDeleteSurvey,
  handleReload,
  paginationOptions
}) {
  return (
    <Box>
      <SurveySearch 
        handleFilter={(value) => {
          handleSearch(value);
        }}
      />
      <SurveyListingTable
        hasSurveyAddAccessRight={hasSurveyAddAccessRight}
        hasSurveyEditAccessRight={hasSurveyEditAccessRight}
        hasSurveyActivateOrDeactivateAccessRight={hasSurveyActivateOrDeactivateAccessRight}
        data={data}
        isFetching={isFetching}
        page={page}
        search={search}
        filterStatus={filterStatus}
        dateCreated={dateCreated}
        productSelect={productSelect}
        rowsPerPage={rowsPerPage}
        totalRecords={totalRecords}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleCreate={handleCreate}
        handleStatusChange={handleStatusChange}
        handleDeleteSurvey={handleDeleteSurvey}
        openEditSurveyModal={openEditSurveyModal}
        handleReload={handleReload}
        paginationOptions={paginationOptions}
      />
    </Box>
  );
}
