import { setLoading, addAlert } from "modules/notification";
import { setListingSerialNumber } from "modules/serial-number";
import {
  getListingSerialNumber,
  submitSerialNumberGenerate,
  updateSNStatus,
} from "../action";
import { getApiLang } from "app/feature/constants";

// const pageSetting = {
//   length: 10,
//   start: 10 * 0,
//   column: "seq_number",
//   order: "desc",
//   search: ""
// };

export const getListingSerialNumberMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    switch (type) {
      case getListingSerialNumber.pending.type:
        // dispatch set loading to notification reducer
        dispatch(
          setLoading({ id: getListingSerialNumber.typePrefix, state: true })
        );
        break;

      case getListingSerialNumber.fulfilled.type:
        // dispatch remove loading from notification reducer
        next(setListingSerialNumber(payload));
        dispatch(
          setLoading({ id: getListingSerialNumber.typePrefix, state: false })
        );
        break;

      case getListingSerialNumber.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({ id: getListingSerialNumber.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

export const submitSerialNumberGenerateMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);

    const { payload, type } = action;

    switch (type) {
      case submitSerialNumberGenerate.pending.type:
        dispatch(
          setLoading({ id: submitSerialNumberGenerate.typePrefix, state: true })
        );
        break;
      case submitSerialNumberGenerate.fulfilled.type:
        dispatch(
          setLoading({
            id: submitSerialNumberGenerate.typePrefix,
            state: false,
          })
        );
        break;
      case submitSerialNumberGenerate.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: submitSerialNumberGenerate.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity:
              payload.code === "error.ERROR_FEATURE_DISABLED"
                ? "warning"
                : "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

export const updateSNStatusMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    switch (type) {
      case updateSNStatus.pending.type:
        dispatch(setLoading({ id: updateSNStatus.typePrefix, state: true }));
        break;

      case updateSNStatus.fulfilled.type:
        dispatch(setLoading({ id: updateSNStatus.typePrefix, state: false }));
        break;

      case updateSNStatus.rejected.type:
        const lang = getState().constant.languages;
        dispatch(setLoading({ id: updateSNStatus.typePrefix, state: false }));
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

export default [
  updateSNStatusMiddleware,
  getListingSerialNumberMiddleware,
  submitSerialNumberGenerateMiddleware,
];
