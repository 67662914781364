import React from "react";

import {
  Box,
  IconButton,
  Divider,
  Typography,
  Button,
  makeStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Formik } from "formik";
import * as Yup from "yup";

import { getLang } from "app/feature/constants";
import InputHelper from "lib/InputHelper";

import Dialog from "components/dialog/customDialog";
import InputTextField from "components/input/inputTextField";

const style = (theme) => ({
  header: {
    position: "sticky",
    top: 0,
    width: "100%",
    backgroundColor: "white",
    paddingTop: "4px",
    paddingLeft: theme.spacing(3),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    flexGrow: 1,
    backgroundColor: "white",
  },
  field: {
    display: "grid",
    gridTemplateColumns: "150px 400px",
    columnGap: theme.spacing(2)
  },
  helperText: {
    marginTop: 0,
  },
  rejectButton: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.light,
    '&:hover': {
      borderColor: theme.palette.error.dark,
    },
  },
});

const useStyles = makeStyles(style);

export default function ApproveClaimExtendDialogComponent({
  isOpen,
  handleClose,
  handleConfirm,
  initialValue,
  lang,
}) {
  const classes = useStyles();

  return (
    <Dialog
      isOpen={isOpen}
      handleClose={handleClose}
      disableEnforceFocus={true}
      disableAutoFocus={true}
      disableEscapeKeyDown={true}
      disableBackdropClick={true}
      maxWidth="80%"
    >
      <Box className={classes.header}>
        <Typography variant="subtitle2" color="primary" style={{ fontWeight: "bold" }}>
          {getLang(lang, 'label.WARRANTY_EXTENSION')}
        </Typography>
        <IconButton color="primary" onClick={handleClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>

      <Divider />

      <Box>
        <Formik
          initialValues={{
            warrantyPeriod: initialValue.extendPeriod,
          }}
          validationSchema={Yup.object({
            warrantyPeriod: Yup.number()
              .min(0, getLang(lang, "label.FORM_VALIDATE_MIN"), { value: 0 })
              .integer()
          })}
          onSubmit={handleConfirm}
        >
          {(formik) => {
            return (
              <form className={classes.root} onSubmit={formik.handleSubmit}>
                <Box style={{ overflowY: "auto" }} display="flex" height="100%" flexDirection="column" flexGrow={1}>
                  <Box py={3} px={3} className={classes.field}>
                    <Box display="flex" pt={1.5}>
                      {getLang(lang, "label.WARRANTY_PERIOD")} <Typography style={{ color: "#DE350B", display: 'inline-block' }}>&nbsp; *</Typography>
                    </Box>

                    <Box display="flex" flexWrap="no-wrap" style={{ overflowX: "auto" }}>
                      <InputTextField
                        required
                        variant="filled"
                        size="small"
                        type="number"
                        onPaste={(e) => InputHelper.preventCopyEDecimalAndNegetiveInNumberInput(e)}
                        onKeyDown={(e) => InputHelper.preventTypeEDecimalAndNegetiveInNumberInput(e)}
                        InputProps={{
                          inputProps: {
                            min: 0,
                            style: {
                              padding: "8px",
                              backgroundColor: "#ECEFF0",
                              borderRadius: "4px 0px 0px 4px",
                            },
                          },
                          style: {
                            padding: "4px",
                            backgroundColor: "#ECEFF0",
                            borderRadius: "4px 0px 0px 4px",
                          },
                        }}
                        FormHelperTextProps={{
                          className: classes.helperText,
                        }}
                        placeholder={getLang(lang, "label.ENTER")}
                        {...formik.getFieldProps('warrantyPeriod')}
                        error={
                          formik.touched['warrantyPeriod'] &&
                            formik.errors['warrantyPeriod']
                            ? true
                            : false
                        }
                      />
                    </Box>
                  </Box>
                </Box>

                <Divider />

                <Box
                  display="flex"
                  justifyContent="flex-end"
                  px={2}
                  py={2}
                  gridGap={10}
                >
                  <Button
                    variant="outlined"
                    classes={{ root: classes.rejectButton }}
                    onClick={handleClose}
                  >
                    {getLang(lang, 'label.CANCEL')}
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    type={'submit'}
                  >
                    {getLang(lang, 'label.SUBMIT')}
                  </Button>
                </Box>
              </form>
            )
          }}
        </Formik >
      </Box>
    </Dialog>
  )
}
