import React from "react";
import ProductListTableComponent from "./productListTable.component";

export default function ProductListTableContainer({
  hasProductEditAccessRight,
  hasProductAssignAccessRight,
  hasProductAddAccessRight,
  hasProductDeleteAccessRight,
  rows,
  isFetching,
  selectedIds,
  isSelectAll,
  isError,
  handleReload,
  page,
  rowsPerPage,
  paginationOptions,
  handleChangePage,
  handleChangeRowsPerPage,
  isSearchResult,
  handleCreate,
  totalFiltered,
  handleEdit,
  handleAssignSerialNumber,
  handleStatusChange,
  handleDeleteClick,
  handleSelectProduct,
  handleSelectAvailable,
  handleSelectAll,
  handleDeselectAll,
  isQuickEdit,
  handleQuickEdit,
  categoryDropdown,
  brandModelDropdown,
  attributeDropdown,
  currencyDropdown,
  handleSubmitQuickEdit,
  lang,
  handleBrandModelSearch,
  handleAttributeSearch
}) {
  return (
    <ProductListTableComponent 
      hasProductEditAccessRight={hasProductEditAccessRight}
      hasProductAddAccessRight={hasProductAddAccessRight}
      hasProductAssignAccessRight={hasProductAssignAccessRight}
      hasProductDeleteAccessRight={hasProductDeleteAccessRight}
      rows={rows}
      isFetching={isFetching}
      isSelectAll={isSelectAll} 
      selectedIds={selectedIds}
      isError={isError}
      handleReload={handleReload}
      page={page}
      rowsPerPage={rowsPerPage}
      paginationOptions={paginationOptions}
      handleChangePage={handleChangePage}
      handleChangeRowPerPage={handleChangeRowsPerPage}
      isSearchResult={isSearchResult}
      handleCreate={handleCreate}
      totalFiltered={totalFiltered}
      handleEdit={handleEdit}
      handleAssignSerialNumber={handleAssignSerialNumber}
      handleStatusChange={handleStatusChange}
      handleDeleteClick={handleDeleteClick}
      handleSelectProduct={handleSelectProduct}
      handleSelectAvailable={handleSelectAvailable}
      handleSelectAll={handleSelectAll}
      handleDeselectAll={handleDeselectAll}
      handleQuickEdit={handleQuickEdit}
      isQuickEdit={isQuickEdit}
      categoryDropdown={categoryDropdown}
      brandModelDropdown={brandModelDropdown}
      attributeDropdown={attributeDropdown}
      currencyDropdown={currencyDropdown}
      handleSubmitQuickEdit={handleSubmitQuickEdit}
      lang={lang}
      handleBrandModelSearch={handleBrandModelSearch}
      handleAttributeSearch={handleAttributeSearch}
    />
  )
}