import axios from "axios";

const endpoint_url = "/serialnumber/api/v1/serialnumbers";

const post = (parameters) => {
  return new Promise((resolve, reject) => {
    axios
      .post(endpoint_url, {
        length: parameters.length,
        start: parameters.start,
        search: parameters.search,
        order: parameters.order,
        column: parameters.column,
        draw: parameters.draw,
        search_column: parameters.searchColumn,
        node_uuids: parameters.node_uuids,
        product_uuids: parameters.product_uuids,
        status: parameters.status,
        product_not_assigned: parameters.productNotAssigned,
      })
      .then((response) => resolve(response))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

const listingSerialNumber = {
  post,
};

export default listingSerialNumber;
