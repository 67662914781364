import axios from "axios";

const getMasterInfo = async () => {
  const response = await axios({
    method: "POST",
    url: "/account/user/masterinfo",
    timeout: 15000
  });

  return response.data;
};

export default getMasterInfo;
