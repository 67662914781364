import React from "react";
import CategoryListTable from './categoryListTable.component';

export default function CategoryListTableContainer({
  hasProductCategoryEditAccessRight,
  hasProductCategoryDeleteAccessRight,
  isSelectAll,
  selectedIds,
  paginationOptions,
  data,
  isFetching,
  isLoadingCategoryListError,
  handleChangePage,
  handleChangeRowsPerPage,
  handleReload,
  page,
  search,
  totalFiltered,
  rowsPerPage,
  handleCategoryStatus,
  handleUpdateCategories,
  isQuickEdit,
  handleQuickEdit,
  status,
  name,
  updateName,
  updateStatus,
  handleDeleteCategory,
  handleSelectCategory,
  handleSelectAvailable,
  handleSelectAll,
  handleDeselectAll,
  handleCreateCategory,
  lang
}){
  return (
    <CategoryListTable
      hasProductCategoryEditAccessRight={hasProductCategoryEditAccessRight}
      hasProductCategoryDeleteAccessRight={hasProductCategoryDeleteAccessRight}
      isSelectAll={isSelectAll} 
      selectedIds={selectedIds}
      paginationOptions={paginationOptions}
      data={data}
      isFetching={isFetching}
      isLoadingCategoryListError={isLoadingCategoryListError}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      handleReload={handleReload}
      page={page}
      rowsPerPage={rowsPerPage}
      search={search}
      totalFiltered={totalFiltered}
      handleCategoryStatus={handleCategoryStatus}
      isQuickEdit={isQuickEdit}
      handleUpdateCategories={handleUpdateCategories}
      handleQuickEdit={handleQuickEdit}
      name={name}
      status={status}
      updateName={updateName}
      updateStatus={updateStatus}
      handleCategoryDelete={handleDeleteCategory}
      handleSelectCategory={handleSelectCategory}
      handleSelectAvailable={handleSelectAvailable}
      handleSelectAll={handleSelectAll}
      handleDeselectAll={handleDeselectAll}
      handleCreateCategory={handleCreateCategory}
      lang={lang}
    />
  )
}