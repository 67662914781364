/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { STOCKFLOW_REQUEST_TRANSFER_EDIT } from "lib/constants/accessRights";
import CreateRequestTransferComponent from "./createRequestTransfer.component";
import {
  createRequestTransfer,
  resetAddressList,
  resetRequestStockList,
  resetRequestTransferRequestBranchDropdown,
  resetRequestTransferTargetBranchDropdown,
} from "modules/stockflow/redux";
import { REQUEST_TRANSFER_LIST_PAGE } from "modules/stockflow/config/stockflow.route";
import GeneralDialog from "components/dialog/generalDialog";
import { getLang } from "app/feature/constants";
import AlertIcon from "assets/svg/alert.svg";
import { selectLoading } from "modules/notification";

function CreateRequestTransferContainer() {
  const history = useHistory();
  const dispatch = useDispatch();

  const hasStockflowEditAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(STOCKFLOW_REQUEST_TRANSFER_EDIT)
  );
  const lang = useSelector((state) => state.constant.languages);
  const isSubmitting = useSelector((state) =>
    selectLoading(state, createRequestTransfer.typePrefix)
  );

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [request, setRequest] = useState(null);

  useEffect(() => {
    return () => {
      dispatch(resetRequestTransferTargetBranchDropdown());
      dispatch(resetRequestTransferRequestBranchDropdown());
      dispatch(resetAddressList());
      dispatch(resetRequestStockList());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!hasStockflowEditAccessRight) {
      history.push("/admin/dashboard");
    }
  }, [history, hasStockflowEditAccessRight]);

  const handleSubmit = () => {
    let products = Object.keys(request.products).reduce((list, key) => {
      list.push({
        product_uuid: key,
        quantity: request.products[key],
      });
      return list;
    }, []);

    let params = {
      from_branch_uuid: request.fromBranch.uuid,
      to_branch_uuid: request.toBranch.uuid,
      address_uuid: request.receiver.uuid,
      remarks: request.remark,
      term: request.term === "Others" ? request.customTerm : request.term,
      requests: products,
    };

    dispatch(createRequestTransfer(params)).then(() => {
      setRequest(null);
      setShowConfirmDialog(false);
      history.replace(REQUEST_TRANSFER_LIST_PAGE);
    });
  };

  return (
    <>
      <CreateRequestTransferComponent
        lang={lang}
        isSubmitting={isSubmitting}
        handleSubmit={(values) => {
          setRequest(values);
          setShowConfirmDialog(true);
        }}
      />
      <GeneralDialog
        isLoading={isSubmitting}
        isOpen={showConfirmDialog}
        handleClose={() => setShowConfirmDialog(false)}
        handleProceed={handleSubmit}
        title={getLang(lang, "paragraph.COMFIRM_SUBMIT_REQUEST_TRANSFER_TITLE")}
        description={getLang(
          lang,
          "paragraph.COMFIRM_SUBMIT_REQUEST_TRANSFER_DESC"
        )}
        type="success"
        icon={<img src={AlertIcon} alt="alert" />}
      />
    </>
  );
}

export default CreateRequestTransferContainer;
