import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { DETAIL_PAGE, ROOT } from "./config/group.route";
import { BranchGroupDetailPage, MainPage } from "./pages";

export default function GroupModule() {
  return (
    <Switch>
      <Route exact path={ROOT} component={MainPage} />
      <Route exact path={DETAIL_PAGE} component={BranchGroupDetailPage} />
      <Route path="*">
        <Redirect to="/admin/invalid" />
      </Route>
    </Switch>
  );
}

export { ROOT };
