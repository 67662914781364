import React from "react"
import {
  Box,
  makeStyles,
  Typography,
} from "@material-ui/core"
import ProductTraceabilityMap from "../../map/productTraceabilityMap"
import { Skeleton } from '@material-ui/lab'
import ProductTraceabilityTimeline from "../../timeline/productTraceabilityTimeline"
import RefreshTable from "components/table/refreshTable"
import { getLang } from "app/feature/constants"

const useStyles = makeStyles((theme) => ({
  container: {
    background: "white",
    borderRadius: 4,
    padding: "16px 20px"
  },
  infoSection: {
    display: "grid",
    gridTemplateColumns: "2fr 3fr",
    columnGap: theme.spacing(2),
    rowGap: theme.spacing(2),
    gridTemplateAreas: "'left right'",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
      gridTemplateRows: "1fr auto",
      rowGap: theme.spacing(2),
      gridTemplateAreas: "'right''left'"
    }
  },
  addressBox: {
    border: "2px solid #D0D5DD",
    padding: "5px 10px"
  },
  summaryBox: {
    border: "1px solid #D0D5DD",
    borderRadius: 12,
    padding: 12,
    display: "flex",
    alignItems: "center",
    backgroundColor: "white",
    "& img": {
      height: 58,
      width: 58,
      objectFit: "contain",
      marginRight: 12
    }
  },
  timelineContainer: {
    maxHeight: 600,
    overflowY: "auto",
    border: "2px solid #D0D5DD",
    borderRadius: 8,
    padding: 16,
    "-webkit-overflow-scrolling": "auto !important",
    '&::-webkit-scrollbar': {
      width: 8
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: 16
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: "#E4E7EC",
      borderRadius: 16
    },
  },
  colorIndicator: {
    borderRadius: "50%",
    height: 12,
    width: 12,
    marginRight: 5
  }
}))

export default function ProductTraceabilityPanelComponent({ 
  traceabilityList,
  serialNumber,
  isFetching,
  language,
  isError,
  recordFocus,
  updateRecordFocus,
  handleReload,
  lang
}) {
  const classes = useStyles()

  return (
    <Box className={classes.container} mt={2}>
      <Box mb={2}>
        <Typography variant="body1"><b>{getLang(lang, "label.PRODUCT_TRACEABILITY")}</b></Typography>
      </Box>
      {!isFetching && isError ? (
        <RefreshTable handleReload={handleReload} />
      ) : !isFetching ? (
        <Box className={classes.infoSection}>
          <Box className={classes.timelineContainer} id="timeline-container" gridArea="left">
            <Box display="flex" alignItems="center" mb={2}>
              <Box display="flex" alignItems="center">
                <Box className={classes.colorIndicator} style={{ background: "#35CA91" }} />
                <Box>
                  <Typography variant="body2" color="textSecondary">{getLang(lang, "label.CONSUMER")}</Typography>
                </Box>
              </Box>
              <Box display="flex" alignItems="center" ml={2}>
                <Box className={classes.colorIndicator} style={{ background: "#6184FF" }} />
                <Box>
                  <Typography variant="body2" color="textSecondary">{getLang(lang, "label.DEALER")}</Typography>
                </Box>
              </Box>
              <Box display="flex" alignItems="center" ml={2}>
                <Box className={classes.colorIndicator} style={{ background: "#A749F0" }} />
                <Box>
                  <Typography variant="body2" color="textSecondary">{getLang(lang, "label.EMPLOYEE")}</Typography>
                </Box>
              </Box>
            </Box>
            <ProductTraceabilityTimeline 
              traceabilityList={traceabilityList}
              serialNumber={serialNumber}
              updateRecordFocus={updateRecordFocus} 
              language={language}
              recordFocus={recordFocus}
              lang={lang}
            />
          </Box>
          <Box className={classes.mapSection} gridArea="right">
            <ProductTraceabilityMap 
              traceabilityList={traceabilityList} 
              updateRecordFocus={updateRecordFocus} 
              recordFocus={recordFocus} 
            />
          </Box>
        </Box>
      ) : (
        <Box className={classes.infoSection}>
          <Box>
            <Skeleton variant="text" height={40} mt={2}/>
            <Skeleton variant="text" height={40} mt={2}/>
            <Skeleton variant="text" height={40} mt={2}/>
            <Skeleton variant="text" height={40} mt={2}/>
            <Skeleton variant="text" height={40} mt={2}/>
            <Skeleton variant="text" height={40} mt={2}/>
          </Box>
          <Box>
            <Skeleton variant='rect' height={250} />
          </Box>
        </Box>
      )}
    </Box>
  )
}
