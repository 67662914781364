import React, { useState } from "react";
import EmployeeTableComponent from "./employeeTable.component";
import { ROOT } from "../../../config/employee.route";
import { useHistory } from "react-router-dom";
import EditEmployeeDialog from "../../dialog/editEmployeeDialog";
import { hideBackdrop, showBackdrop } from "modules/backdrop";
import { useDispatch, useSelector } from "react-redux";
import {addAlert, selectLoading} from "modules/notification";
import ConfirmationDialog from "components/dialog/confirmation";
import RemarksDialog from "../../../components/dialog/remarksDialog";
import {
  fetchEmployeeDetail,
  resendEmail,
  removeEmployee,
  changeEmployeeStatus
} from "modules/employee/redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { fetchBranchDropdown } from "modules/employee/redux";
import { SUSPEND } from "../../../constant/index";
import { EMPLOYEE_EDIT, EMPLOYEE_ACTIVATE_OR_DEACTIVATE, EMPLOYEE_INVITATION_CANCEL } from "lib/constants/accessRights";
import { getLang } from "app/feature/constants";
import { hasEmployeeAddAccessRightSelector } from "modules/profile/redux/selector/profile.selector";

function EmployeeTableContainer({
  data,
  isFetching,
  page,
  rowsPerPage,
  totalRecords,
  handleChangePage,
  handleChangeRowsPerPage,
  search,
  handleCreate,
  handleReload,
  paginationOptions
}) {
  const history = useHistory();
  const dispatch = useDispatch();

  const currentUserUuid = useSelector(state => state.profile.uuid)
  const hasEmployeeAddAccessRight = useSelector(state => hasEmployeeAddAccessRightSelector(state));
  const hasEmployeeEditAccessRight = useSelector(state => state.profile.accessRights.includes(EMPLOYEE_EDIT));
  const hasEmployeeActivateOrDeactivateAccessRight = useSelector(state => state.profile.accessRights.includes(EMPLOYEE_ACTIVATE_OR_DEACTIVATE));
  const hasEmployeeInvitationCancelAccessRight = useSelector(state => state.profile.accessRights.includes(EMPLOYEE_INVITATION_CANCEL));
  const isLoadingEmployeeError = useSelector(state => state.employee.isLoadingEmployeeError)
  const lang = useSelector(state => state.constant.languages);
  const language = useSelector(state => state.profile.language);

  const [editEmployeeDialogOpen, setEditEmployeeDialogOpen] = useState(false);
  const [isDeactivateDialogOpen, setIsDeactivateDialogOpen] = useState(false);
  const [isActivateDialogOpen, setIsActivateDialogOpen] = useState(false);
  const [changeStatusTargetId, setChangeStatusTargetId] = useState("");
  const [employeeName, setEmployeeName] = useState("")
  const isStatusUpdating = useSelector((state) =>
    selectLoading(state, changeEmployeeStatus.typePrefix)
  );

  async function handleViewProfile(id) {
    history.push(`${ROOT}/View/${id}`);
  }

  const handleEdit = async (id) => {
    if(hasEmployeeEditAccessRight && currentUserUuid !== id){
      dispatch(showBackdrop());
      await dispatch(fetchBranchDropdown())
        .then(unwrapResult)
        .then(async () => {
          await dispatch(fetchEmployeeDetail(id))
            .then(unwrapResult)
            .finally((response) => {
              dispatch(hideBackdrop());
              setEditEmployeeDialogOpen(true);
            });
        });
    }
  };

  const handleResendEmail = async (id) => {
    dispatch(showBackdrop());
    await dispatch(resendEmail(id))
      .then(unwrapResult)
      .finally((response) => {
        dispatch(hideBackdrop());
      });
  };

  const handleDeleteEmployee = async (id) => {
    if(hasEmployeeInvitationCancelAccessRight && currentUserUuid !== id){
      dispatch(showBackdrop());
      await dispatch(removeEmployee(id))
        .then(unwrapResult)
        .finally((response) => {
          dispatch(hideBackdrop());
          handleReload();
        });
    }
  };

  const handleStatusChange = ({ currentStatus, id, employeeName }) => {
    if (hasEmployeeActivateOrDeactivateAccessRight && currentUserUuid !== id){
      setChangeStatusTargetId(id);
      setEmployeeName(employeeName)
      if (currentStatus === SUSPEND) {
        setIsActivateDialogOpen(true);
      } else {
        setIsDeactivateDialogOpen(true);
      }
    }
  };

  const handleStatusSubmit = (state, reasons) => {
    if(hasEmployeeActivateOrDeactivateAccessRight && currentUserUuid !== changeStatusTargetId){
      dispatch(
        changeEmployeeStatus({
          id: changeStatusTargetId,
          state: state ? "active" : "suspend",
          remarks: !state && reasons
        })
      )
        .then(unwrapResult)
        .finally(() => {
          setIsDeactivateDialogOpen(false);
          setIsActivateDialogOpen(false);
          handleReload()
        });
    }
  };

  const handleCopyInviteUrl = (url) => {
    navigator.clipboard.writeText(url).then(() => {
      dispatch(
        addAlert({
          severity: "success",
          message: getLang(lang, "message.success.LINK_COPIED_TO_CLIPBOARD"),
        })
      );
    });
  }

  return (
    <>
      <EmployeeTableComponent
        currentUserUuid={currentUserUuid}
        hasEmployeeAddAccessRight={hasEmployeeAddAccessRight}
        hasEmployeeEditAccessRight={hasEmployeeEditAccessRight}
        hasEmployeeActivateOrDeactivateAccessRight={hasEmployeeActivateOrDeactivateAccessRight}
        hasEmployeeInvitationCancelAccessRight={hasEmployeeInvitationCancelAccessRight}
        rows={data}
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        isFetching={isFetching}
        totalRecords={totalRecords}
        handleViewProfile={handleViewProfile}
        language={language}
        search={search}
        handleEdit={handleEdit}
        handleResendEmail={handleResendEmail}
        handleDeleteEmployee={handleDeleteEmployee}
        handleStatusChange={handleStatusChange}
        handleCopyInviteUrl={handleCopyInviteUrl}
        handleCreate={handleCreate}
        isLoadingEmployeeError={isLoadingEmployeeError}
        handleReload={handleReload}
        paginationOptions={paginationOptions}
        lang={lang}
      />
      <EditEmployeeDialog
        isOpen={editEmployeeDialogOpen}
        handleClose={() => setEditEmployeeDialogOpen(false)}
        handleReload={handleReload}
      />
      <RemarksDialog
        isOpen={isDeactivateDialogOpen}
        handleClose={() => setIsDeactivateDialogOpen(false)}
        handleProceed={(reasons) => handleStatusSubmit(false, reasons)}
        isLoading={isStatusUpdating}
        description={getLang(lang, "paragraph.DEACTIVATE_EMPLOYEE_QUESTION")}
        title={getLang(lang, "label.CONFIRMATION_NEEDED")}
        employeeName={employeeName}
      />
      <ConfirmationDialog
        isOpen={isActivateDialogOpen}
        handleClose={() => setIsActivateDialogOpen(false)}
        handleProceed={() => handleStatusSubmit(true)}
        isLoading={isStatusUpdating}
        type={"success"}
        description={getLang(lang, "paragraph.ACTIVATE_EMPLOYEE_QUESTION")}
        title={getLang(lang, "label.CONFIRMATION_NEEDED")}
      />
    </>
  );
}

export default EmployeeTableContainer;
