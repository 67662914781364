import axios from "axios";

export function getDealerBranchTitleData({
  url,
  branch_uuid
}) {

  return new Promise((resolve, reject) => {
    axios
      .post(url, {
        branch_uuid
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        if (error?.response?.data) {
          reject(error.response.data);
        } else {
          reject(error);
        }
      });
  });
}

export default getDealerBranchTitleData;
