import React, { useEffect, useState } from "react";
import LuckyDrawParticipantPageComponent from "./luckyDrawParticipant.page";
import { useDispatch, useSelector } from "react-redux";
import { selectLoading } from "modules/notification";
import { fetchLuckyDrawParticipantTable ,resetParticipantTable} from "modules/lucky-draw/redux";
import { useHistory } from "react-router";
import getParticipantExcel from "app/api/luckyDraw/getParticipantExcel.api";
import { LUCKY_DRAW_VIEW } from "lib/constants/accessRights";
import LoadingComponent from "components/loading";
import { generatePaginationOptions } from "lib/helper";
import { getLang } from "app/feature/constants";

function LuckyDrawParticipantPageContainer() {
  const history = useHistory();
  const hasLuckyDrawViewAccessRight = useSelector(state => state.profile.accessRights.includes(LUCKY_DRAW_VIEW))
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [search, setSearch] = useState("");
  const [paginationOptions, setPaginationOptions] = useState([]);
  const dispatch = useDispatch();
  const accessToken = useSelector((state) => state.session.accessToken);
  const totalRecords = useSelector(
    (state) => state.luckyDraw.luckyDrawParticipantTable.filteredTotalRecords
  );
  const luckyDrawParticipantData = useSelector(
    (state) => state.luckyDraw.luckyDrawParticipantTable.data
  );
  const isFetching = useSelector((state) =>
    selectLoading(state, fetchLuckyDrawParticipantTable.typePrefix)
  );
  const lang = useSelector(state => state.constant.languages)
  const [activeIndex, setActiveIndex] = useState(0);
  const tabLabels = [getLang(lang,"label.LUCKY_DRAW_NAME")];
  const language = useSelector(state => state.profile.language);


  const luckyDrawId = history.location.pathname.substring(
    history.location.pathname.lastIndexOf("/") + 1
  );

  useEffect(() => {
    if(!hasLuckyDrawViewAccessRight){
      history.push("/admin/dashboard")
    }
  }, [history, hasLuckyDrawViewAccessRight])

  useEffect(() => {
    dispatch(
      fetchLuckyDrawParticipantTable({
        luckyDrawId,
        length: rowsPerPage,
        start: rowsPerPage * page,
        search: search,
      })
    );

    return () => {
      dispatch(resetParticipantTable());
    };
  }, [dispatch, luckyDrawId, search, rowsPerPage, page]);

  useEffect(() => {
    setPaginationOptions(generatePaginationOptions(totalRecords))
  }, [totalRecords])

  if (!hasLuckyDrawViewAccessRight) return <LoadingComponent />;

  const handleReload = () => {
    dispatch(
      fetchLuckyDrawParticipantTable({
        luckyDrawId,
        length: rowsPerPage,
        start: rowsPerPage * page,
        search: search
      })
    );
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(event.target.value);
  };

  const handleSearch = (value) => {
    setPage(0);
    setSearch(value);
  };

  const handleChange = (event, index) => {
    setActiveIndex(index);
  };

  const handleExport = () => {
    getParticipantExcel.get({ id: luckyDrawId, token: accessToken });
  };

  return (
    <LuckyDrawParticipantPageComponent
      luckyDrawParticipantData={luckyDrawParticipantData}
      isFetching={isFetching}
      totalRecords={totalRecords}
      page={page}
      search={search}
      rowsPerPage={rowsPerPage}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      handleSearch={handleSearch}
      handleChange={handleChange}
      activeIndex={activeIndex}
      tabLabels={tabLabels}
      handleExport={handleExport}
      handleReload={handleReload}
      paginationOptions={paginationOptions}
      lang={lang}
      language={language}
    />
  );
}

export default LuckyDrawParticipantPageContainer;
