import React from "react";
import PropTypes from "prop-types";
import {
  makeStyles,
  Box,
  Typography,
  Tabs,
  Divider,
  Button,
  Grid
} from "@material-ui/core";
import LiveDrawRemarkCard from "../card/liveDrawRemarkCard";
import CustomTab from "components/tab";
import LuckyDrawDetailForm from "../form/luckyDrawDetailForm";
import RegLuckyDrawForm from "../form/regLuckyDrawForm";
import { progressStatus } from "lib/constants/luckyDrawProgress";
import { getLang } from "app/feature/constants";

const style = (theme) => ({
  indicator: {
    border: "0.5px solid",
    borderColor: theme.palette.primary.main
  },
  scroll: {
    width: "min-content"
  },
  publishButton: {
    color: theme.palette.common.white
  },
  button: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    placeItems: "center",
    [theme.breakpoints.down("xs")]: {
      paddingRight: theme.spacing(2)
    }
  }
});

const useStyles = makeStyles(style);

export default function TabComponent({
  hasLuckyDrawEditAccessRight,
  handleChange,
  activeIndex,
  tabLabels,
  settingType,
  registerType,
  changeToUpdate,
  changeBackView,
  handleUpdate,
  luckyDrawImageFile,
  setLuckyDrawImageFile,
  formRef,
  progress,
  handleLiveDraw,
  isFormEditing,
  lang,
  language
}) {
  const classes = useStyles();
  function a11yProps(index, label = "label") {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tab-${index}`,
      label: <Typography variant="button" children={label} />
    };
  }
  function TabPanel({ children, value, index, ...other }) {
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </Typography>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
  };

  return (
    <Box>
      <Box px={3}>
        <div style={{ width: "100% " }}>
          <Grid container>
            <Grid item xs={9} sm={8} md={8} style={{ width: "100%" }}>
              <Tabs
                value={activeIndex}
                onChange={handleChange}
                aria-label="scrollable auto tabs example"
                textColor="primary"
                variant="scrollable"
                indicatorColor="primary"
                classes={{
                  indicator: classes.indicator,
                  scrollButtons: classes.scroll,
                }}
              >
                {tabLabels.map((item, index) => {
                  return <CustomTab
                    key={index}
                    disabled={(settingType !== 'view' || registerType !== 'view') && activeIndex !== index}
                    {...a11yProps(index, item)}
                  />;
                })}
              </Tabs>
            </Grid>
            <Grid item xs={3} sm={4} md={4} className={classes.button}>
              <>
                <LiveDrawRemarkCard progress={progress} />

                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{ margin: "4px", height: "80%" }}
                  disabled={
                    progress === progressStatus.confirmWinner ||
                      progress === progressStatus.published
                      ? false
                      : true
                  }
                  onClick={handleLiveDraw}
                >
                  {getLang(lang, "label.LIVE_DRAW")}
                </Button>
              </>
            </Grid>
          </Grid>
        </div>
      </Box>
      <Divider />

      <Box>
        <TabPanel value={activeIndex} index={0}>
          <LuckyDrawDetailForm
            type={settingType}
            changeBackView={changeBackView}
            luckyDrawImageFile={luckyDrawImageFile}
            setLuckyDrawImageFile={setLuckyDrawImageFile}
            language={language}
          >
            {() =>
              hasLuckyDrawEditAccessRight
                ?
                (
                  <Box mt="auto">
                    <Divider />
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                      px={2}
                      pt={1}
                      pb={1}
                    >
                      {settingType === "edit" ? (
                        <Button
                          onClick={(e) => {
                            changeBackView(e)
                          }}
                          type="button"
                          style={{ marginRight: "4px" }}
                        >
                          {getLang(lang, "label.CANCEL")}
                        </Button>
                      ) : (
                        ""
                      )}
                      <Button
                        variant="contained"
                        color="secondary"
                        classes={{ root: classes.publishButton }}
                        disableElevation
                        type={settingType === "edit" ? "submit" : "button"}
                        onClick={settingType === "edit" ? null : changeToUpdate}
                      >
                        {settingType === "edit" ? getLang(lang, "label.SAVE") : getLang(lang, "label.EDIT")}
                      </Button>
                    </Box>
                  </Box>
                )
                : null
            }
          </LuckyDrawDetailForm>
        </TabPanel>
        <TabPanel value={activeIndex} index={1}>
          <RegLuckyDrawForm 
          type={registerType} 
          formRef={formRef}
          >
            {() =>
              hasLuckyDrawEditAccessRight
                ?
                (
                  <Box mt="auto">
                    <Divider />
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                      px={2}
                      pt={1}
                      pb={1}
                    >
                      {registerType === "edit" ? (
                        <Button
                          onClick={(e) => {
                            changeBackView(e)
                          }}
                          type="button"
                          style={{ marginRight: "4px" }}
                        >
                          {getLang(lang, "label.CANCEL")}
                        </Button>
                      ) : (
                        ""
                      )}
                      <Button
                        variant="contained"
                        color="secondary"
                        classes={{ root: classes.publishButton }}
                        disableElevation
                        type={"button"}
                        onClick={registerType === "edit" ? handleUpdate : changeToUpdate}
                      >
                        {registerType === "edit" ? getLang(lang, "label.SAVE") : getLang(lang, "label.EDIT")}
                      </Button>
                    </Box>
                  </Box>
                )
                : null
            }
          </RegLuckyDrawForm>
        </TabPanel>
      </Box>
    </Box>
  );
}
