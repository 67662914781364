import React from "react";
import { ContentCard } from "../../layout";
import { Avatar, Box, Typography } from "@material-ui/core";
import { BoxError } from "../../skeleton/error";
import { BoxLoading } from "../../skeleton/loading";
import { responseHandler } from "modules/reporting/utils/helper";
import { fontTextStyle, iconStyle } from "modules/reporting/styles";

export default function ProductTitleCardComponent({
    status,
    data,
    translate
}){
    const fontClasses = fontTextStyle();
    const iconClasses = iconStyle();
    const renderData = (data) => {

        if (Object.keys(data).length === 0 || !data.isSuccess){
            return null;
        }

        const { product_detail } = data;
        return (
            <Box display="flex" flexDirection="column" style={{gap:"12px"}} justifyContent={"center"} alignContent="center">
                <Typography className={fontClasses.text__muted}>
                    Drilldown by product
                </Typography>
                <Box
                    display="flex"
                    style={{
                        gap: "12px",
                    }}
                    alignItems="center"
                >
                    <Avatar alt="product image" src={product_detail.image_url} className={iconClasses.small_image__icon}/>
                    <Typography className={fontClasses.text__card_title}>
                        {product_detail.name}
                    </Typography>
                </Box>
            </Box>
        )
    }

    return (
        <ContentCard>
            <Box
                padding="15px 20px"
            >
                {
                    responseHandler(
                        data.productDetailData,
                        status.isFetchingProductDetailData,
                        (
                            renderData(data.productDetailData)
                        ),
                        (
                            <BoxLoading />
                        ),
                        (
                            <>empty</>
                        ),
                        (
                            <>no result</>
                        ),
                        (
                            <BoxError 
                                title={translate("label.LOADING_FAILED")}
                                value={translate("paragraph.LOADING_FAILED_DESCRIPTION")}
                            />
                        )
                    )
                }
            </Box>
        </ContentCard>
    )
}