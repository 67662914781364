import axios from "axios";

export function getModelDropdown({
  id,
  search
}) {
  const url =  `/serialnumber/api/v1/product/brand-models/get-model-dropdown-name/${id}`;

  return new Promise((resolve, reject) => {
    axios.get(url, {
      search
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data))
  })
}