import React, { useState } from "react";
import AuthFormComponent from "./authForm.component";
import {
  createAuthModal,
  getInitialAuthenticationContent
} from "../../../utils/productPage.util";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const TYPES = ["new", "edit", "readonly"];

function AuthFormContainer({
  type,
  handleResult,
  initialValues,
  lang,
  handleDelete,
  productInfo,
  handleClose,
  languages,
  userLanguage
}) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeLang, setActiveLang] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [authentication, setAuthentication] = useState(
    getInitialAuthenticationContent(type, lang, initialValues)
  );
  const [activeMessage, setActiveMessage] = useState("");
  const [isSnHidden, setIsSnHidden] = useState(authentication.isSnHidden ?? false);

  const handleChangeActiveMessage = e => {
    setActiveMessage(e.target.value);
  };

  const handleChangeIsSnHidden = () => {
    setIsSnHidden(prev => prev !== true);
  };

  const handleEnableEdit = () => {
    setActiveMessage(authentication.messages[activeLang].value);
    setIsEditing(true);
  };

  const handleChangeTabState = (e, index) => {
    if (isEditing) {
      setIsEditing(false);
    }
    setActiveIndex(index);
  };

  const handleChangeTabLang = (e, index) => {
    if (isEditing) {
      setIsEditing(false);
    }
    setActiveLang(index);
  };

  const handleSubmit = () => {
    handleResult(authentication);
  };

  const handleSave = () => {
    const newMessages = Array.from(authentication.messages);

    newMessages.splice(activeLang, 1, {
      ...newMessages[activeLang],
      value: activeMessage
    });

    setAuthentication({ messages: newMessages, isSnHidden: isSnHidden });
    setIsEditing(false);
  };

  return (
    <AuthFormComponent
      type={type}
      handleSubmit={handleSubmit}
      handleDelete={handleDelete}
      initialValues={initialValues}
      activeIndex={activeIndex}
      activeLang={activeLang}
      handleChangeTabState={handleChangeTabState}
      handleChangeTabLang={handleChangeTabLang}
      lang={lang}
      userLanguage={userLanguage}
      authentication={authentication}
      isEditing={isEditing}
      handleEnableEdit={handleEnableEdit}
      activeMessage={activeMessage}
      handleChangeActiveMessage={handleChangeActiveMessage}
      handleSave={handleSave}
      productInfo={productInfo}
      handleClose={handleClose}
      languages={languages}
      isSnHidden={isSnHidden}
      handleChangeIsSnHidden={handleChangeIsSnHidden}
    />
  );
}

AuthFormContainer.defaultProps = {
  type: TYPES[0],
  initialValues: createAuthModal().content
};

AuthFormContainer.propTypes = {
  type: PropTypes.oneOf(TYPES).isRequired,
  handleResult: PropTypes.func,
  handleDelete: PropTypes.func
};

const mapStateToProps = state => ({
  languages: state.constant.languages,
  lang: state.productPage.lang,
  userLanguage: state.profile.language,
  productInfo: {
    name: state.productPage.productName,
    picture: state.productPage.picture
  }
});

export { TYPES };

export default connect(mapStateToProps)(AuthFormContainer);
