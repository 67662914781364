import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GenerateProductTableComponent from './generateProductTable.component';
import { fetchMeAndBranchDropdown } from "modules/product-mgmt/redux/action/products.action";

export default function GenerateProductTableContainer({
  isFetching,
  handleChangePage,
  handleChangeRowsPerPage,
  page,
  rowsPerPage,
  paginationOptions,
  isSelectAll,
  handleSelectAll,
  updateProductDetail,
  products,
  totalRecords,
  fieldRequireError,
  lang,
  selectedIndex,
  handleSelectAvailable
}) {
  const dispatch = useDispatch()
  const currencyDropdown = useSelector(state => state.productPage.currencyDropdown)
  const [branchSearch, setBranchSearch] = useState("")

  useEffect(() => {
    dispatch(fetchMeAndBranchDropdown({ search: branchSearch }));
  }, [dispatch, branchSearch]);

  const handleBranchSearch = (value) => {
    setBranchSearch(value)
  }

  return (
    <>
      <GenerateProductTableComponent
        isSelectAll={isSelectAll}
        data={products}
        isFetching={isFetching}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        page={page}
        rowsPerPage={rowsPerPage}
        totalRecords={totalRecords}
        currencyDropdown={currencyDropdown}
        paginationOptions={paginationOptions}
        updateProductDetail={updateProductDetail}
        handleBranchSearch={handleBranchSearch}
        handleSelectAll={handleSelectAll}
        fieldRequireError={fieldRequireError}
        lang={lang}
        handleSelectAvailable={handleSelectAvailable}
        selectedIndex={selectedIndex}
      />
    </>
  )
}