import React from "react"
import DashboardFilterPanel from "modules/stockflow/components/panel/dashboardFilterPanel"
import DashboardDealerInvitedPanel from "modules/stockflow/components/panel/dashboardDealerInvitedPanel"
import { Box, Grid, makeStyles } from "@material-ui/core"
import { responseHandler } from "modules/reporting/utils/helper"
import { TrendingPositiveCard } from "modules/reporting/components/card"
import { TrendingCardLoading } from "modules/reporting/components/skeleton/loading"
import DashboardStockoutByProductPanel from "../dashboardStockoutByProductPanel"

const useStyles = makeStyles((theme) => ({
  container: {
  background: "white",
  borderRadius: 12,
  marginTop: 16,
  border: "1px solid #D0D5DD",
  padding: "20px",
  },
}))

export default function DealerDashboardPanelComponent({
  id,
  filter,
  updatePeriod,
  updateDateRange,
  periodMenuItem,
  translate,
  status,
  trendingData
}) {
  const classes = useStyles();

  return (
    <>
      <DashboardFilterPanel
        filter={filter}
        drilldown={false}
        updatePeriod={updatePeriod}
        updateDateRange={updateDateRange}
        periodMenuItem={periodMenuItem}
      />
      <Box className={classes.container}>
        <Box>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} sm={12} md={4} >
              {
                responseHandler(
                  trendingData.totalStockoutData, 
                  status.isFetchingTotalStockoutTrending, 
                  <TrendingPositiveCard translate={translate} title={translate("label.TOTAL_STOCKOUT")} data={trendingData.totalStockoutData} />, 
                  <TrendingCardLoading />, 
                  null, 
                  null, 
                  null
                )
              }
            </Grid>
            <Grid item xs={12} sm={12} md={4} >
              {
                responseHandler(
                  trendingData.totalRecallData,
                  status.isFetchingTotalRecallTrending,
                  <TrendingPositiveCard translate={translate} title={translate("label.TOTAL_RECALL")} data={trendingData.totalRecallData} />,
                  <TrendingCardLoading/>,
                  null,
                  null,
                  null
                )
              }
            </Grid>
            <Grid item xs={12} sm={12} md={4} >
              {
                responseHandler(
                  trendingData.dealerInvitedData,
                  status.isFetchingDealerInvitedTrending,
                  <TrendingPositiveCard translate={translate} title={translate("label.DEALER_INVITED")} data={trendingData.dealerInvitedData} />,
                  <TrendingCardLoading/>,
                  null,
                  null,
                  null
                )
              }
            </Grid>
          </Grid>
        </Box>
        <DashboardStockoutByProductPanel id={id} filter={filter} />
      </Box>
      <DashboardDealerInvitedPanel id={id} filter={filter} />
    </>
  )
}
