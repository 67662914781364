import React, { useEffect, useState } from "react";
import MainPageComponent from "./main.page";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { STOCKFLOW_INVENTORY_VIEW } from "lib/constants/accessRights";
import LoadingComponent from "components/loading";
import { INVENTORY_LIST_PAGE } from "modules/stockflow/config/stockflow.route";
import qs from "query-string";
import { getLang } from "app/feature/constants";

function MainPageContainer() {
  const hasStockflowViewAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(STOCKFLOW_INVENTORY_VIEW)
  );
  const lang = useSelector((state) => state.constant.languages);
  const history = useHistory();
  const location = useLocation();
  const [activeIndex, setActiveIndex] = useState(null);
  const [activeSummaryIndex, setActiveSummaryIndex] = useState(null);

  const qsParams = qs.parse(location.search);
  const currentSearch = qsParams.type || "";
  const currentSummary = qsParams.summary || "";

  useEffect(() => {
    if (!hasStockflowViewAccessRight) {
      history.push("/admin/dashboard");
    }
  }, [history, hasStockflowViewAccessRight]);

  useEffect(() => {
    let tempIndex = 0;
    switch (currentSearch) {
      case "product":
        tempIndex = 0;
        break;
      case "package":
        tempIndex = 1;
        break;
      case "box":
        tempIndex = 2;
        break;
      case "loose-item":
        tempIndex = 3;
        break;
      default:
        tempIndex = 0;
    }
    setActiveIndex(tempIndex);
  }, [currentSearch]);

  useEffect(() => {
    let tempIndex = 0;
    switch (currentSummary) {
      case "to-branch":
        tempIndex = 0;
        break;
      case "to-dealer":
        tempIndex = 1;
        break;
      case "by-branch":
        tempIndex = 2;
        break;
      case "by-dealer":
        tempIndex = 3;
        break;
      default:
        tempIndex = 0;
    }
    setActiveSummaryIndex(tempIndex);
  }, [currentSummary]);


  if (!hasStockflowViewAccessRight) return <LoadingComponent />;

  let tabLabels = [];
  if (hasStockflowViewAccessRight) {
    tabLabels.push(getLang(lang, "label.PRODUCT_TAB"));
    tabLabels.push(getLang(lang, "label.PACKAGE_TAB"));
    tabLabels.push(getLang(lang, "label.BOX_TAB"));
    tabLabels.push(getLang(lang, "label.LOOSE_ITEM_TAB"));
  }

  const handleChange = (event, index) => {
    
    setActiveIndex(index);
    history.replace({
      pathname: INVENTORY_LIST_PAGE,
      search: `?type=${
        index === 0
          ? "product"
          : index === 1
          ? "package"
          : index === 2
          ? "box"
          : "loose-item"
      }`,
    });
  };

  return (
    <>
      <MainPageComponent
        handleChange={handleChange}
        activeIndex={activeIndex}
        tabLabels={tabLabels}
        activeSummaryIndex={activeSummaryIndex}
        lang={lang}
      />
    </>
  );
}

export default MainPageContainer;
