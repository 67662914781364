import React from "react";
import * as Yup from "yup";
import { Formik, ErrorMessage } from "formik";
import InputTextField from "components/input/inputTextField";
import LockTextField from "components/input/lockTextField";
import {
  makeStyles,
  Box,
  Paper,
  Button,
  MenuItem,
  Typography,
  Grid,
  Divider
} from "@material-ui/core";
import CountrySelect from "components/select/countrySelect";
import country from "lib/constants/countries";
import { getLang } from "app/feature/constants";
import TimezoneSelect from "components/select/timezoneSelect";

const useStyles = makeStyles(theme => ({
  paper: {
    display: "flex",
    flexFlow: "column nowrap",
    boxShadow: theme.shadows[0],
    height: "max-content",
    alignItems: "center"
  },
  button: {
    display: "flex",
    justifyContent: "flex-end"
  }
}));

export default function AccountInfoTabComponent({
  hasCompanyEditAccessRight,
  handleSubmit,
  account = {},
  isChanging,
  industries,
  lang,
  countries,
  timezones
}) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Box>
        <Paper elevation={0}>
          <Box p={3}>
            <Typography variant="body1" color="primary" style={{ fontWeight: "500" }}>
              {getLang(lang, "label.COMPANY_INFO")}
            </Typography>
          </Box>
          <Paper elevation={0} className={classes.paper}>
            <Box style={{ width: "100%" }}>
              <Formik
                enableReinitialize
                initialValues={{
                  name: account.name,
                  description: account.description ?? '',
                  registerationNo: account.registerationNo,
                  industry: account.industry,
                  companyEmail: account.companyEmail,
                  contactNumber: account.contactNumber,
                  contactCode: country.find(({ phone }) => phone === account.contactCode),
                  addressLine1: account.addressLine1,
                  addressLine2: account.addressLine2,
                  state: account.state,
                  city: account.city,
                  postCode: account.postCode,
                  country: country.find(({ label }) => label === account.country),
                  timezoneText: timezones.find(({ id }) => id === account.timezone)?.text,
                  timezone: timezones.find(({ id }) => id === account.timezone),
                }}
                validationSchema={Yup.object({
                  name: Yup.string()
                    .trim()
                    .required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
                  registerationNo: Yup.string()
                    .trim()
                    .required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
                  industry: Yup.string()
                    .trim()
                    .required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
                  companyEmail: Yup.string()
                    .email(getLang(lang, "message.error.FORM_VALIDATE_EMAIL"))
                    .required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
                  contactNumber: Yup.string()
                    .trim()
                    .min(6, getLang(lang, "message.error.FORM_VALIDATE_MIN", {value: getLang(lang, "message.error.NUMBERS_6")}))
                    .max(20, getLang(lang, "message.error.FORM_VALIDATE_MAX", {value: getLang(lang, "message.error.NUMBERS_20")}))
                    .matches( /^[1-9]{1}[0-9]{5,19}$/, getLang(lang, "message.error.FORM_VALIDATE_PHONE"))
                    .required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
                  addressLine1: Yup.string()
                    .trim()
                    .max(255, getLang(lang, "message.error.FORM_VALIDATE_MAX", {value: 255}))
                    .required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
                  addressLine2: Yup.string()
                    .trim()
                    .max(255, getLang(lang, "message.error.FORM_VALIDATE_MAX", {value: 255})),
                  state: Yup.string()
                    .trim()
                    .max(50, getLang(lang, "message.error.FORM_VALIDATE_MAX", {value: 50}))
                    .required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
                  city: Yup.string()
                    .trim()
                    .max(255, getLang(lang, "message.error.FORM_VALIDATE_MAX", {value: 255}))
                    .required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
                  postCode: Yup.string()
                    .trim()
                    .max(10, getLang(lang, "message.error.FORM_VALIDATE_MAX", {value: 10}))
                    .required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED"))
                    .matches(/^[0-9a-zA-Z-_ ]*$/, getLang(lang,"message.error.INVALID_POSTCODE")),
                  country: Yup.string()
                    .trim()
                    .max(255, getLang(lang, "message.error.FORM_VALIDATE_MAX", {value: 255}))
                    .required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED"))
                  //timezone: Yup.string().required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED"))
                })}
                onSubmit={handleSubmit}
              >
                {(formik) => (
                  <form onSubmit={formik.handleSubmit}>
                    <Box style={{ display: "flex", placeContent: "center" }}>
                      <Box style={{ width: "80%" }}>
                        {[
                          {
                            label: getLang(lang, "label.COMPANY_NAME"),
                            name: "name",
                            lock: true
                          },
                          {
                            label: getLang(lang, "label.REGISTRATION_NO"),
                            name: "registerationNo",
                            lock: true,
                          },
                          {
                            label: getLang(lang, "label.INDUSTRY"),
                            name: "industry",
                            selects: industries,
                            lock: !hasCompanyEditAccessRight
                          },
                          // {
                          //   label: getLang(lang, "label.EMAIL"),
                          //   name: "companyEmail",
                          //   lock: true
                          // },
                          {
                            label: getLang(lang, "label.CONTACT"),
                            name: "contactNumber",
                            lock: !hasCompanyEditAccessRight
                          },
                          {
                            label: getLang(lang, "label.ADDRESS_LINE_1"),
                            name: "addressLine1",
                            lock: !hasCompanyEditAccessRight
                          },
                          {
                            label: getLang(lang, "label.ADDRESS_LINE_2"),
                            name: "addressLine2",
                            lock: !hasCompanyEditAccessRight
                          },
                          {
                            label: getLang(lang, "label.CITY"),
                            name: "city",
                            lock: !hasCompanyEditAccessRight
                          },
                          {
                            label: getLang(lang, "label.POST_CODE"),
                            name: "postCode",
                            lock: !hasCompanyEditAccessRight
                          },
                          {
                            label: getLang(lang, "label.STATE"),
                            name: "state",
                            lock: !hasCompanyEditAccessRight
                          },
                          {
                            label: getLang(lang, "label.COUNTRY"),
                            name: "country",
                            selects: countries,
                            lock: !hasCompanyEditAccessRight,
                            emptyMessage: getLang(lang, "label.COUNTRY_NOT_EXIST")
                          },
                          {
                            label: "Time Zone",
                            name: "timezoneText",
                            selects: timezones,
                            lock: true
                          }
                        ].map((field, index) => {
                          return (
                            <Box key={index}>
                              {field.selects ? (
                                <Box style={{ display: "flex" }}>
                                  <Grid
                                    container
                                    style={{
                                      alignItems: "center"
                                    }}
                                  >
                                    <Grid item md={3} style={{ width: "100%" }}>
                                      <Typography
                                        variant="body2"
                                        color="textSecondary"
                                      >
                                        {field.label}
                                      </Typography>
                                    </Grid>
                                    <Grid item md={9} style={{ width: "100%" }}>
                                      {
                                        field.lock
                                          ? (
                                            <LockTextField
                                              name={field.name}
                                              hiddenLabel
                                              disabled
                                              variant="filled"
                                              size="small"
                                              fullWidth
                                              margin="dense"
                                              color="primary"
                                              {...formik.getFieldProps(field.name)}
                                              error={
                                                formik.touched[field.name] &&
                                                  formik.errors[field.name]
                                                  ? true
                                                  : false
                                              }
                                              helperText={ErrorMessage({
                                                name: field.name,
                                              })}
                                            />
                                          ) : field.name === "country" ? (
                                            <CountrySelect
                                              textFieldProps={{
                                                fullWidth: true,
                                                variant: "filled",
                                                size: "small",
                                                margin: "dense",
                                                error: formik.errors.country && formik.touched.country ? true : false,
                                                helperText: ErrorMessage({ name: "country" })
                                              }}
                                              formProps={{
                                                ...formik.getFieldProps("country"),
                                                value: formik.values.country,
                                                onChange: (e, newValue) => {
                                                  formik.setFieldValue("country", newValue);
                                                },
                                                disabled: formik.isSubmitting,
                                                disableClearable: true
                                              }}
                                              type="countryOnly"
                                            />
                                          ) : field.name === "timezone" ? (
                                            <TimezoneSelect
                                              textFieldProps={{
                                                required: true,
                                                variant: "filled",
                                                size: "small",
                                                margin: "dense",
                                                error: formik.errors.timezone && formik.touched.timezone ? true : false,
                                                helperText: ErrorMessage({ name: "timezone" })
                                              }}
                                              formProps={{
                                                ...formik.getFieldProps("timezone"),
                                                value: formik.values.timezone,
                                                onChange: (e, newValue) => {
                                                  formik.setFieldValue("timezone", newValue);
                                                },
                                                disabled: formik.isSubmitting,
                                                disableClearable: true
                                              }}
                                            />
                                          ) : (
                                            <InputTextField
                                              select
                                              name={field.name}
                                              hiddenLabel
                                              variant="filled"
                                              size="small"
                                              fullWidth
                                              margin="dense"
                                              InputProps={{
                                                disableUnderline: true,
                                                style: {
                                                  padding: "0",
                                                  backgroundColor: "#ECEFF0"
                                                },
                                              }}
                                              {...formik.getFieldProps(field.name)}
                                              disabled={formik.isSubmitting}
                                              error={
                                                formik.touched[field.name] &&
                                                  formik.errors[field.name]
                                                  ? true
                                                  : false
                                              }
                                              helperText={ErrorMessage({
                                                name: field.name,
                                              })}
                                            >
                                              {field.selects.map((select) => (
                                                <MenuItem
                                                  key={select.id}
                                                  value={select.id}
                                                >
                                                  {select.name}
                                                </MenuItem>
                                              ))
                                              }
                                            </InputTextField>
                                          )
                                      }
                                    </Grid>
                                  </Grid>
                                </Box>
                              ) : (
                                <Box style={{ display: "flex" }}>
                                  <Grid
                                    container
                                    style={{
                                      alignItems: "center"
                                    }}
                                  >
                                    <Grid item md={3} style={{ width: "100%" }}>
                                      <Typography
                                        variant="body2"
                                        color="textSecondary"
                                      >
                                        {field.label}
                                      </Typography>
                                    </Grid>
                                    <Grid item md={9} style={{ width: "100%" }}>
                                      {field.lock ? (
                                        <LockTextField
                                          name={field.name}
                                          hiddenLabel
                                          disabled
                                          variant="filled"
                                          size="small"
                                          fullWidth
                                          margin="dense"
                                          color="primary"
                                          {...formik.getFieldProps(field.name)}
                                          multiline={field.name === "address"}
                                          // disabled={formik.isSubmitting}
                                          error={
                                            formik.touched[field.name] &&
                                              formik.errors[field.name]
                                              ? true
                                              : false
                                          }
                                          helperText={ErrorMessage({
                                            name: field.name,
                                          })}
                                        />
                                      ) : (
                                        <Box display="flex" gridColumnGap={12}>
                                          {
                                            field.name === "contactNumber" &&
                                            <CountrySelect
                                              type="phone"
                                              textFieldProps={{
                                                required: true,
                                                style: { width: 200 },
                                                variant: "filled",
                                                size: "small",
                                                margin: "dense",
                                                error: formik.errors.contactCode && formik.touched.contactCode ? true : false,
                                                helperText: ErrorMessage({ name: "contactCode" }),
                                              }}
                                              formProps={{
                                                ...formik.getFieldProps("contactCode"),
                                                value: formik.values.contactCode,
                                                onChange: (e, newValue) => {
                                                  formik.setFieldValue("contactCode", newValue);
                                                },
                                                disabled: formik.isSubmitting
                                              }}
                                            />
                                          }
                                          {field.name === "contactNumber" ?
                                            <InputTextField
                                              onKeyPress={(e) => {
                                                if (!/[0-9]/.test(e.key)) {
                                                  e.preventDefault();
                                                }
                                              }}
                                              name={field.name}
                                              hiddenLabel
                                              variant="filled"
                                              size="small"
                                              fullWidth
                                              margin="dense"
                                              {...formik.getFieldProps(field.name)}
                                              multiline={field.name === "address"}
                                              disabled={formik.isSubmitting}
                                              error={
                                                formik.touched[field.name] &&
                                                  formik.errors[field.name]
                                                  ? true
                                                  : false
                                              }
                                              helperText={ErrorMessage({
                                                name: field.name,
                                              })}
                                            /> :
                                            <InputTextField
                                              name={field.name}
                                              hiddenLabel
                                              variant="filled"
                                              size="small"
                                              fullWidth
                                              margin="dense"
                                              {...formik.getFieldProps(field.name)}
                                              multiline={field.name === "address" || field.name === 'description'}
                                              disabled={formik.isSubmitting}
                                              error={
                                                formik.touched[field.name] &&
                                                  formik.errors[field.name]
                                                  ? true
                                                  : false
                                              }
                                              helperText={ErrorMessage({
                                                name: field.name,
                                              })}
                                            />
                                          }

                                        </Box>
                                      )}
                                    </Grid>
                                  </Grid>
                                </Box>
                              )}
                            </Box>
                          );
                        })}
                      </Box>
                    </Box>
                    {
                      hasCompanyEditAccessRight
                        ?
                        <Box mt={3}>
                          <Divider />
                          <Box mt={3} pr={3} className={classes.button}>
                            <Button
                              color="default"
                              variant="contained"
                              disableElevation
                              type="button"
                              onClick={formik.resetForm}
                              disabled={formik.isSubmitting || isChanging}
                              style={{ marginRight: "8px" }}
                            >
                              {getLang(lang, "label.RESET")}
                            </Button>

                            <Button
                              color="primary"
                              variant="contained"
                              disableElevation
                              type="submit"
                              disabled={formik.isSubmitting || isChanging}
                            >
                              {getLang(lang, "label.UPDATE")}
                            </Button>
                          </Box>
                          &ensp;
                        </Box>
                        : null
                    }
                  </form>
                )}
              </Formik>
            </Box>
          </Paper>
        </Paper>
      </Box>
    </React.Fragment>
  );
}
