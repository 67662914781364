import React, { useState } from "react";
import SubscriptionPlanCardComponent from "./subscriptionPlanCard.component";
import Dialog from "components/dialog/customDialog";
import PerksForm from "../../form/perksForm";
import { connect, useSelector } from "react-redux";
import { SUBSCRIPTION_PLANS_PAGE } from "modules/store/config/store.route";
import { useHistory } from "react-router-dom";

function SubscriptionPlanCardContainer({
  subscriptionPlan
}) {
  const [isModalOpen, setModalOpen] = useState(false);
  const history = useHistory();
  const lang = useSelector(state => state.constant.languages)

  const handleOpenPerks = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  async function handleSubcription() {
    history.push(`${SUBSCRIPTION_PLANS_PAGE}`);
  }

  return (
    <>
      <SubscriptionPlanCardComponent
        handleOpenPerks={handleOpenPerks}
        subscriptionPlan={subscriptionPlan}
        handleSubcription={handleSubcription}
        lang={lang}
      />
      <Dialog
        isOpen={isModalOpen}
        handleClose={closeModal}
        disableEnforceFocus={true}
        disableAutoFocus={true}
      >
        <PerksForm handleClose={closeModal} lang={lang} />
      </Dialog>
    </>
  );
}

const mapStateToProps = (state) => ({
  subscriptionPlan: state.billing.subscriptionPlan
});

export default connect(
  mapStateToProps
)(SubscriptionPlanCardContainer);
