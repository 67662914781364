import React from "react";
import { makeStyles } from "@material-ui/core";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles(theme => ({
  root: {
    wordWrap: "break-word",
    margin: theme.spacing(0, 2),
    borderRadius: theme.spacing(2),
    padding: theme.spacing(1, 2),
    backgroundColor: theme.palette.background.paper,
    overflow: "hidden",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.15)"
  }
}));

export default function TextComponent({ text, lang }) {
  const classes = useStyle();

  return (
    <div
      className={classes.root}
      dangerouslySetInnerHTML={{
        __html:
          text && (text.value !== "<p></p>" && text.value.trim() !== "<p></p>")
            ? text.value
            : `<p>${getLang(lang,"label.NO_TEXT_INPUT")}</p>`
      }}
    />
  );
}
