import { getLang } from 'app/feature/constants';
import React from 'react';
import { useSelector } from 'react-redux';
import ScanLocationCardComponent from './scanLocationCard.component';

function ScanLocationCardContainer({
  isLoading,
  scanData,
}) {
  const lang = useSelector(state => state.constant.languages)

  const tabs = [getLang(lang, "label.STATE"), getLang(lang, "label.COUNTRY")]

  return (
    <ScanLocationCardComponent scanData={scanData} isLoading={isLoading} tabs={tabs} lang={lang}/>
  )
}

export default ScanLocationCardContainer