import React from "react";
import {
  makeStyles,
  Box,
  IconButton,
  Typography,
  Divider,
  Button,
  MenuItem,
  Paper
} from "@material-ui/core";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import InputTextField from "components/input/inputTextField";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ItemTable } from "../../table"
import clsx from "clsx";
import { getLang } from "app/feature/constants";
import moment from "moment";
import "moment/min/locales.min";


const useStyle = makeStyles(theme => ({
  root: {
    width: 650,
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    }
  },
  threeColumnsTitle: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gridColumnGap: theme.spacing(4),
    color: "#899498"
  },
  threeColumnsDescription: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gridColumnGap: theme.spacing(4),
  },

  btnSecondary: {
    color: theme.palette.common.white
  },
  form: {
    minWidth: 450
  },
  unprocressed: {
    color: "#FD646F"
  },
  processed: {
    color: "#1FD026"
  },
  pending: {
    color: "#F69F00"
  }
}));

export default function OrderDetailsFormComponent({
  hasGiftRedemptionEditAccessRight,
  handleClose,
  order,
  handleSubmit,
  language,
  changeToUpdate,
  orderType,
  lang,
  changeBackView
}) {
  const viewType = orderType === "view" ? true : false;
  const classes = useStyle();
  
  if(language==="ZH")
  {
    moment.locale('zh-cn')
  }else if(language==="TL")
  {
    moment.locale('tl-ph')
  }else
  {
    moment.locale(language)
  }

  
  return (
    <Paper elevation={0} className={classes.root}>
      <Box
        py={1}
        px={3}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography
          variant="body1"
          color="primary"
          style={{ fontWeight: "bold" }}
        >
          {getLang(lang, "label.GIFT_REDEMPTION")}
        </Typography>
        <IconButton
          onClick={handleClose}
          edge="end"
          size="small"
          aria-label="close dialog"
        >
          <CloseRoundedIcon color="primary" />
        </IconButton>
      </Box>
      <Divider />
      <Formik
        initialValues={{
          ...order,
          status: order.status || "",
          adminRemark: order.adminRemark || "",
        }}
        validationSchema={Yup.object({
          adminRemark: Yup.string().trim().required(getLang(lang,"message.error.FORM_VALIDATE_REQUIRED"))
        })}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <form className={classes.form} onSubmit={formik.handleSubmit}>
            <Box py={2} px={3}>
              <Box pb={2}>
                <Typography
                  color="primary"
                  variant="body1"
                  style={{ fontWeight: "bold", padding: "16px 0px" }}
                >
                  {getLang(lang, "label.REDEEMER_DETAIL")}
                </Typography>
                <Box className={classes.threeColumnsTitle}>
                  <Typography
                    gutterBottom
                    variant="body2"
                  >
                    {getLang(lang, "label.NAME")}
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="body2"
                  >
                    {getLang(lang, "label.REQUESTED_DATE")}
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="body2"
                  >
                    {getLang(lang, "label.CONTACT")}
                  </Typography>
                </Box>
                <Box className={classes.threeColumnsDescription} pb={2}>
                  <Typography
                    gutterBottom
                    variant="body2"
                    style={{
                      fontWeight: 700
                    }}
                  >
                    {order.name}
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="body2"
                  >
                    {moment(order.requestDate).format('lll')}
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="body2"
                  >
                    {order.contact}
                  </Typography>
                </Box>
              </Box>
              <Divider />
              <Box pb={2}>
                <Typography
                  color="primary"
                  variant="body1"
                  style={{ fontWeight: "bold", padding: "16px 0px" }}
                >
                  {getLang(lang, "label.RECEIVER_DETAILS")}
                </Typography>
                <Box className={classes.threeColumnsTitle}>
                  <Typography
                    gutterBottom
                    variant="body2"
                  >
                    {getLang(lang, "label.NAME")}
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="body2"
                  >
                    {getLang(lang, "label.DELIVERY_ADDRESS")}
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="body2"
                  >
                    {getLang(lang, "label.CONTACT")}
                  </Typography>
                </Box>
                <Box className={classes.threeColumnsDescription} pb={2}>
                  <Typography
                    gutterBottom
                    variant="body2"
                    style={{
                      fontWeight: 700
                    }}
                  >
                    {order.address.name}
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="body2"
                    style={{ overflowWrap: "anywhere" }}
                  >
                    {order.address.address}, {order.address.city}, {order.address.postcode}, {order.address.state}, {order.address.country}
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="body2"
                  >
                    {order.address.contact_number}
                  </Typography>
                </Box>
              </Box>
              <Divider />
              <Box pt={2}>
                <Box>
                  <Typography
                    color="primary"
                    variant="body1"
                    style={{ fontWeight: "bold" }}
                    gutterBottom
                  >
                    {getLang(lang, "label.REQUESTED_GIFT_DETAILS")}
                  </Typography>
                  <ItemTable />
                  <Box py={2} display="flex" alignItems="center">
                    <Typography style={{ fontWeight: "bold", marginRight: "30px" }}>
                      {getLang(lang, "label.PROCESS_STATUS")}
                    </Typography>
                    {viewType ?
                      <Typography
                        className={clsx({
                          [classes.unprocressed]: order.status === "rejected",
                          [classes.processed]: order.status === "approved",
                          [classes.pending]: order.status === "pending"
                        })}>
                        {order.status === 'approved' ? getLang(lang,'label.APPROVED')
                        : order.status === 'rejected' ? getLang(lang, 'label.REJECTED')
                        : getLang(lang, 'label.PENDING')}
                        {/* QUICK FIX FOR LANGUAGES TRANSLATION */}
                      </Typography>
                      :
                      <InputTextField
                        disabled={viewType || formik.isSubmitting}
                        required
                        variant="filled"
                        size="small"
                        margin="dense"
                        color="primary"
                        select
                        hiddenLabel
                        {...formik.getFieldProps("status")}
                      >
                        <MenuItem value={'rejected'}>{getLang(lang, "label.REJECT")}</MenuItem>
                        <MenuItem value={'approved'}>{getLang(lang, "label.APPROVE")}</MenuItem>
                        <MenuItem value={'pending'}>{getLang(lang, "label.PENDING")}</MenuItem>
                      </InputTextField>}
                  </Box>
                </Box>
                <Divider />
              </Box>
              <Box py={2}>
                <Box display="flex">
                  <Typography
                    color="primary"
                    variant="body1"
                    style={{ fontWeight: "bold" }}
                    gutterBottom
                  >
                    {getLang(lang, "label.DELIVERY_UPDATE")}
                  </Typography>
                </Box>
                {viewType ?
                  <Typography
                    variant="body2"
                    color="primary"
                    style={{ fontWeight: "bold", paddingTop: 10, paddingBottom: 10 }}>
                    {formik.getFieldProps("adminRemark").value}
                  </Typography> :
                  <InputTextField
                    disabled={formik.isSubmitting}
                    variant="filled"
                    multiline={true}
                    rows={4}
                    fullWidth
                    {...formik.getFieldProps("adminRemark")}
                    error={
                      formik.touched["adminRemark"] && formik.errors["adminRemark"]
                        ? true
                        : false
                    }
                    helperText={ErrorMessage({
                      name: "adminRemark"
                    })}
                  />
                }
              </Box>
            </Box>
            {
              order.status === "pending" && hasGiftRedemptionEditAccessRight
                ?
                <>
                  <Divider />
                  <Box display="flex" justifyContent="flex-end" py={2} px={3}>
                    <Box>
                      { !viewType ? (
                        <Button
                        disabled={formik.isSubmitting}
                        onClick={(e) => {
                          formik.resetForm()
                          changeBackView(e)
                        }}
                        style={{marginRight: "4px"}}
                        type="button"
                      >
                        {getLang(lang, "label.CANCEL")}
                      </Button>
                      ) : (
                        ""
                      )}
                      <Button
                        variant="contained"
                        color="secondary"
                        classes={{ root: classes.btnSecondary }}
                        type={viewType ? "button" : "submit"}
                        onClick={viewType ? changeToUpdate : null}
                      >
                        {viewType ? getLang(lang, "label.UPDATE_STATUS") : getLang(lang, "label.DONE")}
                      </Button>
                    </Box>
                  </Box>
                </>
                : null
            }
          </form>
        )}
      </Formik>
    </Paper >
  );
}
