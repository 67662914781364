import axios from "axios";

const url = "/account/api/v1/company/currency/create";

function createCurrencyConfig({
  currencyCode, isDefault
}) {
  return new Promise((resolve, reject) => {
    axios.post(url, {
      currencyCode: currencyCode,
      isDefault: isDefault
    })
      .then(response => resolve(response))
      .catch(error => reject(error.response.data))
  })
}

export default createCurrencyConfig;