import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { selectLoading } from "modules/notification";
import LoadingComponent from "components/loading";
import {
  approveTransfer,
  generateDnPdf,
  getTransferDeliveryInfo,
  getTransferDetail,
  rejectApproveTransfer,
  resetTransfer,
  updateTransferDeliveryInfo,
} from "modules/stockflow/redux";
import {
  STOCKFLOW_DELIVERY_INFO_EDIT,
  STOCKFLOW_TRANSFER_VIEW,
} from "lib/constants/accessRights";
import { ROOT } from "modules/dashboard";
import TransferDetailsComponent from "./transferDetails.component";
import { getLang } from "app/feature/constants";
import InfoDialog from "modules/stockflow/components/dialog/infoDialog";
import RejectConfirmDialog from "modules/stockflow/components/dialog/rejectConfirmDialog";
import GeneralDialog from "components/dialog/generalDialog";

function TransferDetailsContainer() {
  const history = useHistory();
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const id = match.params.uuid;
  const hasStockflowViewAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(STOCKFLOW_TRANSFER_VIEW)
  );
  const hasDeliveryEditAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(STOCKFLOW_DELIVERY_INFO_EDIT)
  );

  const transfer = useSelector((state) => state.stockflowTransfer.transfer);
  const isError = useSelector(
    (state) => state.stockflowTransfer.isLoadingTransferError
  );
  const isFetching = useSelector((state) =>
    selectLoading(state, getTransferDetail.typePrefix)
  );
  const lang = useSelector((state) => state.constant.languages);
  const language = useSelector((state) => state.profile.language);

  const isApproving = useSelector((state) =>
    selectLoading(state, approveTransfer.typePrefix)
  );
  const isRejecting = useSelector((state) =>
    selectLoading(state, rejectApproveTransfer.typePrefix)
  );
  const isUpdating = useSelector((state) =>
    selectLoading(state, updateTransferDeliveryInfo.typePrefix)
  );
  const token = useSelector((state) => state.session.accessToken);

  const [isExporting, setIsExporting] = useState(false);
  const [showApproveConfirm, setShowApproveConfirm] = useState(false);
  const [showRejectConfirm, setShowRejectConfirm] = useState(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);

  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    if (!hasStockflowViewAccessRight) {
      history.push("/admin/dashboard");
    }
  }, [history, hasStockflowViewAccessRight]);

  useEffect(() => {
    if (id) {
      dispatch(getTransferDetail(id));
    } else {
      history.push(ROOT);
    }
  }, [history, id, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetTransfer());
    };
  }, [dispatch]);

  if (!hasStockflowViewAccessRight) return <LoadingComponent />;

  const handleReload = () => {
    dispatch(getTransferDetail(id));
  };

  const approve = () => {
    dispatch(approveTransfer(id)).then(() => {
      setShowApproveConfirm(false);
      setShowSuccessDialog(true);
      handleReload();
    });
  };

  const rejectApprove = (values) => {
    dispatch(rejectApproveTransfer({ id, reasons: values.reasons })).then(
      () => {
        setShowRejectConfirm(false);
        handleReload();
      }
    );
  };

  const showApprovalConfirm = (isApprove) => {
    if (isApprove) {
      setShowApproveConfirm(true);
    } else {
      setShowRejectConfirm(true);
    }
  };

  const updateDelivery = (values) => {
    values.postalCode = values.postalCode.trim();
    dispatch(
      updateTransferDeliveryInfo({
        uuid: id,
        tracking: values.tracking,
        courier_code: values.courier?.code,
        postal_code: values.postalCode,
        country: values.country,
      })
    ).then(() => {
      handleReload();
    });
  };

  const fetchDeliveryInfo = () => {
    dispatch(getTransferDeliveryInfo(id));
  };

  const exportPdf = () => {
    setIsExporting(true);
    dispatch(
      generateDnPdf({
        uuid: id,
        token,
        reference_code: transfer.reference_code,
      })
    ).then(() => setIsExporting(false));
  };

  return (
    <>
      <TransferDetailsComponent
        hasDeliveryEditAccessRight={hasDeliveryEditAccessRight}
        transfer={transfer}
        isFetching={isFetching}
        language={language}
        isError={isError}
        handleReload={handleReload}
        lang={lang}
        id={id}
        showApprovalConfirm={showApprovalConfirm}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        fetchDeliveryInfo={fetchDeliveryInfo}
        updateDelivery={updateDelivery}
        isUpdating={isUpdating}
        isExporting={isExporting}
        exportPdf={exportPdf}
      />
      <GeneralDialog
        isLoading={isApproving}
        isOpen={showApproveConfirm}
        handleClose={() => setShowApproveConfirm(false)}
        handleProceed={approve}
        title={getLang(lang, "label.APPROVE_TRANSFER")}
        description={getLang(lang, "paragraph.APPROVE_TRANSFER_DESC")}
        type="success"
        proceedLabel={getLang(lang, "label.CONFIRM")}
      />
      <RejectConfirmDialog
        isLoading={isRejecting}
        isOpen={showRejectConfirm}
        handleClose={() => setShowRejectConfirm(false)}
        handleProceed={rejectApprove}
        title={getLang(lang, "label.REJECT_TRANSFER")}
        description={getLang(lang, "paragraph.REJECT_TRANSFER_DESC")}
      />
      <InfoDialog
        isOpen={showSuccessDialog}
        handleClose={() => setShowSuccessDialog(false)}
        title={getLang(lang, "label.SUCCESSFULLY_APPROVE_TRANSFER_REQUEST")}
        description={getLang(
          lang,
          "paragraph.SUCCESSFULLY_APPROVE_TRANSFER_REQUEST_DESC"
        )}
      />
    </>
  );
}

export default TransferDetailsContainer;
