import React from "react"
import {
  Typography,
  Box,
} from "@material-ui/core";
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import { BackButton } from "components/button";
import BulkEditListPanel from "modules/product-mgmt/components/panel/bulkEditListPanel";
import { getLang } from "app/feature/constants";

export default function BulkEditComponent({ lang }) {
  //const classes = useStyle()

  return (
    <Box>
      <Box mb={1} display="flex" justifyContent="space-between" style={{ marginBottom: 10 }}>
        <Box display="flex" alignItems="flex-end" fontWeight="bold">
          <BackButton />
          <Typography variant="h6" color="primary">{getLang(lang, "label.PRODUCT")}</Typography>
          <Box mx={0.5}>
            <ChevronRightRoundedIcon color="disabled" />
          </Box>
          <Typography variant="h6" color="primary">{getLang(lang, "label.BULK_EDIT")}</Typography>
        </Box>
      </Box>
      <Box>
        <BulkEditListPanel />
      </Box>
    </Box>
  )
}