import React from "react";
import GiftSettingDialogComponent from "./giftSettingDialog.component";
import { useDispatch, useSelector } from "react-redux";
import { updateGiftOrderSetting } from "modules/loyalty-program/redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { selectLoading } from "modules/notification";

export default function GiftSettingDialogContainer({
  isOpen,
  handleClose,
  handleReload,
}) {
  const giftOrderSetting = useSelector(
    (state) => state.redemptionCenter.giftOrderSetting
  );
  const isSubmitting = useSelector((state) =>
    selectLoading(state, updateGiftOrderSetting.typePrefix)
  );
  const lang = useSelector((state) => state.constant.languages);
  const dispatch = useDispatch();

  const updateSetting = (values) => {
    dispatch(
      updateGiftOrderSetting({
        value: {
          order: values.order,
          column: values.column,
        },
      })
    )
      .then(unwrapResult)
      .then(() => {
        handleReload();
        handleClose();
      });
  };

  return (
    <GiftSettingDialogComponent
      isOpen={isOpen}
      handleClose={handleClose}
      giftOrderSetting={giftOrderSetting}
      updateSetting={updateSetting}
      lang={lang}
      isSubmitting={isSubmitting}
    />
  );
}
