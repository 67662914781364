import React from "react";
import {
  Backdrop,
  Dialog,
  makeStyles,
  Typography,
  Paper,
  Box,
  IconButton,
  Divider,
  Button,
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputAdornment,
  CircularProgress
} from "@material-ui/core";
import { Close as CloseIcon } from '@material-ui/icons'
import InputTextField from "components/input/inputTextField";
import { TIER_ICON_PROPS } from "modules/stockflow/constants";
import { convertToBigNum } from "lib/generalUtility";
import { getLang } from "app/feature/constants";


const useStyle = makeStyles((theme) => ({
  dialog: {
    maxWidth: "none"
  },
  root: {
    boxShadow: theme.shadows[5],
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "space-between",
    borderRadius: "0.25em",
    fontSize: "1rem",
    overflow: "hidden",
    "@media (max-width: 600px)": {
      maxWidth: "40em"
    },
    "@media (min-width: 600px)": {
      width: "60em"
    }
  },
  header: {
    paddingLeft: theme.spacing(3),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  containerBackground: {
    backgroundColor: "#EBEDEE"
  },
  content: {
    margin: "1em",
    backgroundColor: "#FFFFFF"
  },
  buttonContainer: {
    padding: "10px 16px",
    display: "flex",
    justifyContent: "flex-end"
  },
  selectRoot: {
    padding: "8px 14px"
  },
  inputRoot: {
    paddingTop: "8px !important",
    paddingBottom: "7px !important",
    paddingLeft: "12px !important",
    paddingRight: "12px !important"
  },
  fieldContainer: {
    paddingTop: "1em",
    margin: "1em",
    paddingBottom: "1em"
  },
  field: {
    display: "flex",
    alignItems: "flex-start"
  },
  field2: {
    display: "flex",
    alignItems: "center"
  },
  dealerField: {
    display: "flex",
    alignItems: "center"
  },
  dealerProfileTierCombo: {
    display: "flex",
    flexDirection: "row",
    marginRight: "0.5em"
  },
  tierIconDealer: {
    width: "1em",
    height: "1em",
    borderRadius: "3px",
    display: "flex",
    position: "absolute",
    alignItems: "center",
    justifyContent: "center"
  },
  tierLabelDealer: {
    color: "#FFFFFF",
    fontSize: "0.625rem"
  },
  profilePhotoDealer: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #E4E7EC",
    borderRadius: "50%",
    width: "33px",
    height: "33px",
    marginRight: "0.25em",
    marginLeft: "0.25em",
    marginTop: "0.25em",
    "& img": {
      borderRadius: "50%",
      height: "33px",
      width: "33px"
    },
  },
  dealerLabel: {
    fontWeight: 500,
    fontSize: "1rem",
    lineHeight: "1rem",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  radioGroup: {
    flexDirection: "row"
  },
  dialogFooter: {
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "flex-start",
    margin: "1em"
  }
}))

export default function EditIncentivePointDialogComponent({
  isOpen,
  handleClose,
  isFetching,
  isSubmitting,
  details,
  handleChangePointPolarity,
  selectedPointPolarity,
  handleChangePointValue,
  newPoint,
  newBalance,
  remark,
  handleChangeRemark,
  handleSubmit,
  newPointError,
  setNewPointError,
  lang
}) {
  const classes = useStyle();

  return (
    <Dialog
      maxWidth="sm"
      open={isOpen}
      onClose={handleClose}
      disableBackdropClick
      closeAfterTransition
      BackdropComponent={Backdrop}
      classes={{
        paperWidthSm: classes.dialog
      }}
    >
      <Paper elevation={0} className={classes.root}>
        <Box className={classes.header}>
          <Typography
            variant="subtitle2"
            color="primary"
            style={{ fontWeight: "bold", fontSize: "initial" }}
          >
            {getLang(lang, 'label.EDIT_DEALER_INCENTIVE_POINT')}
          </Typography>
          <IconButton color="primary" onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
        <Divider />
        <Box className={classes.containerBackground}>
          {!isFetching && isOpen ? (
            <>
              <Box className={classes.content}>
                <Box className={classes.fieldContainer}>
                  <Grid container className={classes.field}>
                    <Grid item xs={12} sm={12}>
                      <Box className={classes.dealerField}>
                        <Box className={classes.dealerProfileTierCombo}>
                          <Box
                            className={classes.tierIconDealer}
                            style={{
                              backgroundColor: details.tier ? TIER_ICON_PROPS.find(({ tier }) => tier === details.tier).color : null
                            }}
                          >
                            <Typography className={classes.tierLabelDealer}>
                              T{details.tier}
                            </Typography>
                          </Box>
                          <Box className={classes.profilePhotoDealer}>
                            <img src={details.picture} alt={details.name} />
                          </Box>
                        </Box>
                        <Typography className={classes.dealerLabel}>
                          {details.name}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={4} style={{ display: "flex", alignItems: "center", marginTop: "1em" }}>
                      <Typography variant="body2" color="primary">
                        {getLang(lang, 'label.CURRENT_INCENTIVE_POINT_BALANCE')}
                      </Typography>&nbsp;<Typography variant="body2" color="error">*</Typography>
                    </Grid>
                    <Grid item xs={12} sm={8} style={{ display: "flex", alignItems: "center", marginTop: "1em", justifyContent: "flex-end"}}>
                      <Typography variant="body2" style={{fontWeight: 400}}>
                        {convertToBigNum(details.pointBalance.toLocaleString())}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} style={{ display: "flex", alignItems: "flex-start", paddingTop: "0.75em", marginTop: "1em" }}>
                      <Typography variant="body2" color="primary">
                        {getLang(lang, 'label.INCENTIVE_POINT_ADJUSTMENT')}
                      </Typography>&nbsp;<Typography variant="body2" color="error">*</Typography>
                    </Grid>
                    <Grid item xs={12} sm={8} style={{ marginTop: "1em" }}>
                      <Box>
                        <RadioGroup aria-label="change-point-polarity" name="change-point-polarity" value={selectedPointPolarity} onChange={handleChangePointPolarity} className={classes.radioGroup}>
                          <FormControlLabel value="increase" control={<Radio />} label={`(+) ${getLang(lang, 'label.INCREASE')}`} style={{marginRight: "3em"}}/>
                          <FormControlLabel value="decrease" control={<Radio />} label={`(-) ${getLang(lang, 'label.DECREASE')}`}  />
                        </RadioGroup>
                        <InputTextField
                          required
                          variant="outlined"
                          size="small"
                          disabled={isSubmitting}
                          fullWidth
                          placeholder={getLang(lang, 'placeholder.ENTER_A_NUMBER')}
                          value={newPoint}
                          onChange={(e) => {
                            handleChangePointValue(e.target.value)
                          }}
                          type="number"
                          InputStyle={{
                            backgroundColor: "#FFFFFF"
                          }}
                          InputProps={{
                            inputProps: { min: 0 },
                            defaultValue: 0,
                            startAdornment: (
                              <InputAdornment position="start">
                                <Box
                                  px={1}
                                  borderRight="1px solid rgba(0, 0, 0, 0.1)"
                                >
                                  <Typography
                                    variant="body2"
                                    color="textSecondary"
                                  >
                                    {selectedPointPolarity === "increase" ? "+" : "-"}
                                  </Typography>
                                </Box>
                              </InputAdornment>

                            )
                          }}
                          error={newPointError !== ""}
                          helperText={newPointError}
                          onKeyDown={(e) => {
                            const invalidChars = [
                              "-",
                              "+",
                              "e",
                              ".",
                            ];
                            if (invalidChars.includes(e.key)) {
                              e.preventDefault();
                            }
                            else {
                              setNewPointError("");
                            }
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={4} style={{ marginTop: "1em" }}>
                      <Typography variant="body2" color="primary">
                        {getLang(lang, 'label.REMARK')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8} style={{ display: "flex", alignItems: "flex-start", marginTop: "1em" }}>
                      <InputTextField
                        required
                        variant="outlined"
                        size="small"
                        disabled={isSubmitting}
                        fullWidth
                        multiline={true}
                        rows={4}
                        placeholder={getLang(lang, 'placeholder.REMARK_TO_YOUR_DEALER')}
                        value={remark}
                        onChange={(e) => {
                          handleChangeRemark(e.target.value)
                        }}
                        InputStyle={{
                          backgroundColor: "#FFFFFF"
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Divider />
                <Box className={classes.fieldContainer}>
                  <Grid container style={{marginBottom: "1em"}}>
                    <Grid item xs={12} sm={4} style={{ display: "flex", alignItems: "flex-start"}}>
                      <Typography variant="body2" color="primary">
                        {getLang(lang, 'label.NEW_INCENTIVE_POINT_BALANCE')}
                      </Typography>&nbsp;<Typography variant="body2" color="error">*</Typography>
                    </Grid>
                    <Grid item xs={12} sm={8} style={{display: "flex", justifyContent: "flex-end"}}>
                      <Typography variant="body2" style={{fontWeight: 500}}>
                        {convertToBigNum(newBalance.toLocaleString())}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </>
          ) : (
            <Box classes={classes.content} style={{textAlign: "center"}}>
              <Box className={classes.fieldContainer}>
                <CircularProgress color="primary" />
              </Box>
            </Box>
          )}
        </Box>
        <Divider />
        <Box className={classes.dialogFooter}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleSubmit()}
            disabled={isSubmitting}
          >
            {getLang(lang, 'label.UPDATE')}
          </Button>
          <Button
            color="primary"
            onClick={() => handleClose()}
            disabled={isSubmitting}
            style={{marginRight: "1em"}}
          >
            {getLang(lang, 'label.CANCEL')}
          </Button>
        </Box>
      </Paper>
    </Dialog>

  )
}