import axios from "axios";

const get = () => {
  return new Promise((resolve, reject) => {
    axios
      .get("/payment/api/v1/plans")
      .then(response => resolve(response.data))
      .catch(error => {
        return reject(error.message);
      });
  });
};

const plans = {
  get
};

export default plans;
