const preventCopyEInNumberInput = (e) => {
  if ((e.clipboardData || window.clipboardData).getData('text').toLowerCase().includes('e')) {
    e.preventDefault()
  }
}

const preventCopyDecimalInNumberInput = (e) => {
  if(parseFloat((e.clipboardData || window.clipboardData).getData('text')) % 1 > 0){
    e.preventDefault()
  }
}

const preventCopyNegetiveInNumberInput = (e) => {
  if ((e.clipboardData || window.clipboardData).getData('text').includes('-')) {
    e.preventDefault()
  }
}

const preventCopyEDecimalAndNegetiveInNumberInput = (e) => {
  if (
    (e.clipboardData || window.clipboardData).getData('text').toLowerCase().includes('e') ||
    parseFloat((e.clipboardData || window.clipboardData).getData('text')) % 1 > 0 || 
    (e.clipboardData || window.clipboardData).getData('text').includes('-')
  )
  {
    e.preventDefault()
  }
}


const preventTypeEInNumberInput = (e) => {
  if(e.key.toLowerCase() === 'e'){
    e.preventDefault()
  }
}

const preventTypeDecimalInNumberInput = (e) => {
  if(e.key === '.'){
    e.preventDefault()
  }
}

const preventTypeNegetiveInNumberInput = (e) => {
  if(e.key === '-'){
    e.preventDefault()
  }
}

const preventTypeEDecimalAndNegetiveInNumberInput = (e) => {
  if(
    e.key.toLowerCase() === 'e' ||
    e.key === '.' ||
    e.key === '-'
  )
  {
    e.preventDefault()
  }
}

const phoneRegex = /^\+?[0-9]{6,14}$/

export default {
  preventCopyEInNumberInput,
  preventCopyDecimalInNumberInput,
  preventCopyNegetiveInNumberInput,
  preventCopyEDecimalAndNegetiveInNumberInput,
  preventTypeEInNumberInput,
  preventTypeDecimalInNumberInput,
  preventTypeNegetiveInNumberInput,
  preventTypeEDecimalAndNegetiveInNumberInput,
  phoneRegex
}