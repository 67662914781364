import axios from "axios";

const editBranchStatus = async ({
    id,
    status
}) => {
    const response = await axios.put(
        `/account/api/v1/node/${id}/status`,
        {
            status
        }
    );

    return response;
};

export default editBranchStatus;