import React from 'react'
import { useSelector } from 'react-redux'
import GalleryDetailFormComponent from './galleryDetailForm.component'

export default function GalleryDetailFormContainer(props) {
    const lang = useSelector(state => state.constant.languages)
    return (
        <GalleryDetailFormComponent {...props} lang={lang}/>
    )
}
