import React from "react";
import { Box, Typography, Paper } from "@material-ui/core";
import { getLang } from "app/feature/constants";
import CreditBalanceReloadLogTable from "../../table/creditBalanceReloadLogTable";

export default function CreditReloadLogPanelComponent({
  lang,
  creditReloadLog,
  totalFiltered,
  isFetching,
  isError,
  handleChangePage,
  handleChangeRowsPerPage,
  tableFilter,
  handleReload,
  paginationOptions,
  disbursementInfo,
}) {
  return (
    <Box style={{
      display: "flex",
      flexDirection: "column",
    }}>
      <Box mb={1.5}>
        <Typography variant="body1">
          <b>{getLang(lang, "label.CREDIT_BALANCE_RELOAD_LOG")}</b>
        </Typography>
      </Box>
      <Paper elevation={2} style={{
        height: "100%",
      }}>
        <Box p={2}>
          <CreditBalanceReloadLogTable
            rows={creditReloadLog}
            isFetching={isFetching}
            isError={isError}
            handleReload={handleReload}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            totalFiltered={totalFiltered}
            paginationOptions={paginationOptions}
            tableFilter={tableFilter}
            lang={lang}
            disbursementInfo={disbursementInfo}
          />
        </Box>
      </Paper>
    </Box>
  );
}
