import { addProductCategories, getProductCategoryDropdown } from "modules/product-mgmt/redux/action/productCategory.action";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CreateCategoryDialog from "../../dialog/createCategoryDialog";
import SearchableSelectComponent from './bulkCreateCategorySelect.component';
import SearchableSelectPanelComponent from "./bulkCreateCategorySelectPanel.component";
import { unwrapResult } from '@reduxjs/toolkit';
import { selectLoading } from "modules/notification";

export default function BulkCreateCategorySelectContainer({
  searchable,
  handleChange,
  value,
  disabled,
  style,
  placeholder,
  panelStyle,
  emptyMessage,
  anchorOrigin,
  transformOrigin,
  PaperProps,
  textStyle,
  returnType,
  isEmpty,
  type,
  handleFetchMoreItems,
  totalFilter,
  isClearable,
  handleClear,
}) {
  const lang = useSelector(state => state.constant.languages)
  const categoryDropdown = useSelector(state => state.products.categoryList.categoryDropdown)
  const isCategoryDropdownLoading = useSelector(state => selectLoading(state, getProductCategoryDropdown.typePrefix))
  const isAddingCategory = useSelector(state => selectLoading(state, addProductCategories.typePrefix))
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedField, setSelectedField] = useState(value)
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [categoryDropdownSearch, setCategoryDropdownSearch] = useState("")

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProductCategoryDropdown({ search: categoryDropdownSearch }))
  }, [dispatch, categoryDropdownSearch])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setCategoryDropdownSearch("")
    setAnchorEl(null)
  }

  const handleListKeyDown = (field) => {
    if (field) {
      if(selectedField !== null && selectedField.id === field.uuid && returnType === "object") {
        handleChange(field)
      }
      else if (returnType === "object" || field.name === undefined) {
        handleChange(field)
      } else if (field.alpha3) {
        handleChange(field)
      } else if (field.name) {
        handleChange(field.name)
      } else {
        handleChange(field)
      }
    } else {
      handleChange("")
    }
    
    handleClose()
  }

  const selectedItem = (item) => {
    if(isEmpty || value === undefined) {
      return false
    }

    if (item?.uuid !== undefined || value?.id !== undefined) {
      if (item?.uuid === value?.id) {
        setSelectedField(value)
        return true
      } else {
        return false
      }
    }
  }

  const handleCreateCategory = (value) => {
    setIsCreateDialogOpen(value)
  }

  const handleSubmitCategories = (value) => {
    dispatch(addProductCategories({
        categories: value.categories
    }))
      .then(unwrapResult)
      .then(() => {
        dispatch(getProductCategoryDropdown({ search: categoryDropdownSearch }))
      })
      .finally(() => {
        setIsCreateDialogOpen(false)
      })
  }

  const handleCategorySearch = (value) => {
    setCategoryDropdownSearch(value)
  }
  
  return (
    <>
      <SearchableSelectComponent
        handleClick={handleClick}
        handleClear={handleClear}
        value={value}
        isEmpty={isEmpty}
        style={style}
        disabled={disabled}
        placeholder={placeholder}
        id={id}
        open={open}
        textStyle={textStyle}
        isClearable={isClearable}
      />
      <SearchableSelectPanelComponent
        anchorEl={anchorEl}
        open={open}
        handleClose={handleClose}
        style={panelStyle}
        id={id}
        items={[null, ...categoryDropdown]}
        handleSearch={handleCategorySearch}
        isFetching={isCategoryDropdownLoading}
        searchable={searchable}
        emptyMessage={emptyMessage}
        handleListKeyDown={handleListKeyDown}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        PaperProps={PaperProps}
        type={type}
        handleFetchMoreItems={handleFetchMoreItems}
        totalFilter={totalFilter}
        selected={selectedItem}
        handleAddCategory={() => setIsCreateDialogOpen(true)}
        lang={lang}
      />
      <CreateCategoryDialog
        isOpen={isCreateDialogOpen}
        handleClose={() => handleCreateCategory(false)}
        handleSubmit={handleSubmitCategories}
        isSubmittingAddCategory={isAddingCategory}
      />
    </>
  )
}