import React from "react";
import {
  makeStyles,
  Paper,
  Typography,
  Grid,
  Box,
  Divider,
  Button,
  Tooltip,
  Radio,
  IconButton,
  FormControlLabel,
  RadioGroup,
  Chip,
  Checkbox
} from "@material-ui/core";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import InputTextField from "components/input/inputTextField";
import HelpOutlineOutlined from "@material-ui/icons/HelpOutlineOutlined";
import AntSwitch from "components/switch";
import EditorComponent from "components/editor/editor.container";
import UploadDocumentComponent from "components/uploadDocument/uploadDocument.container";
import { Formik, ErrorMessage } from "formik";
import WarrantyFormAssignProductSelect from "components/select/warrantyFormAssignProductSelect";
import AssignProductTable from "components/table/assignProductTable";
import clsx from "clsx";
import PerfectScrollbar from "react-perfect-scrollbar";
import * as Yup from "yup";
import InputHelper from "lib/InputHelper";
import { getLang } from "app/feature/constants";
import { Document, Page, pdfjs } from "react-pdf";
import LanguageSelect from "../../select/languageSelect";
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const style = (theme) => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  gridFieldContainer: {
    paddingTop: theme.spacing(1),
    alignItems: "center",
  },
  gridFieldTableContainer: {
    paddingTop: theme.spacing(4),
    alignItems: "start",
  },
  gridFieldPeriod: {
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  monthContainer: {
    height: 43,
    backgroundColor: "#ECEFF0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 70,
    borderRadius: "0px 4px 4px 0px",
  },
  divider: {
    height: "100%",
    display: "flex",
    alignSelf: "center",
  },
  dividerEdit: {
    height: "70%",
  },
  dividerContainer: {
    backgroundColor: "#ECEFF0",
    display: "flex",
  },
  switchContainer: {
    display: "grid",
    paddingTop: theme.spacing(3),
    gridTemplateColumns: "3fr 1fr 10fr",
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "3fr 1fr 4fr",
    },
  },
  switch: {
    display: "flex",
    alignItems: "center",
  },
  discardButton: {
    color: theme.palette.error.main,
  },
  publishButton: {
    color: theme.palette.common.white,
  },
  helperText: {
    marginTop: 0,
  },
  textDisable: {
    fontWeight: "bold",
    paddingLeft: "8px",
    color: "rgba(58, 77, 84, 0.33)",
  },
  textActive: {
    fontWeight: "bold",
    paddingLeft: "8px",
    color: theme.palette.primary.main,
  },
  monthView: {
    paddingLeft: theme.spacing(1),
  },
  viewTC: {
    minHeight: "100%",
    background: "#EBEDEE",
    borderRadius: "0px 0px 4px 4px",
    padding: theme.spacing(1, 4, 1, 3),
    wordBreak: "break-all",
  },
  viewUrl: {
    wordBreak: "break-all",
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(2),
  },
  scroll: {
    overflowY: "auto",
    height: "230px",
  },
  viewField: {
    fontWeight: "bold",
    height: 43,
    display: "flex",
    alignItems: "center",
  },
  switchPadding: {
    paddingTop: theme.spacing(1),
  },
  gridDateView: {
    height: 43,
  },
  switchEdit: {
    height: 43,
    display: "flex",
    alignItems: "center",
    paddingTop: "8px !important",
  },
  switchTableEdit: {
    paddingTop: "8px !important",
  },
  productTableView: {
    marginTop: "-16px",
  },
  pageControl: {
    background: "white",
    borderRadius: "4px",
  },
  pdfScrollContainer: {
    width: "40%",
    display: "flex",
    height: "21em",
    flexDirection: "column",
    alignItems: "center",
    border: "1px solid #00001A",
    borderRadius: "4px",
  },
});

const useStyles = makeStyles(style);

export default function WarrantySettingFormComponent({
  children,
  earlyAccess,
  handleSubmit,
  warrantySettingForm,
  type,
  setTncEdited,
  lang,
  onDocumentLoadSuccess,
  pageNumber,
  numPages,
  handleTncPdfChangePage,
  languageTabIndex,
  setLanguageTabIndex,
  usedLang,
  anchorEl,
  handleAddLang,
  handleAddLangClick,
  handleCloseAddLang,
  addableLangs,
  handleRemoveLang,
  emptyErrorOnLang,
  setEmptyErrorOnLang,
  duplicateErrorOnLang,
  setDuplicateErrorOnLang,
  processReason,
  hasWarrantyPreRegisterEditRight,
  hasWarrantyValidateDoNumEditRight,
}) {
  const classes = useStyles();
  const viewType = type === "view" ? true : false;

  const sections = [
    {
      num: "01",
      title: getLang(lang, "label.WARRANTY_DETAILS"),
      type: "textField",
    },
    {
      num: "02",
      title: getLang(lang, "label.WARRANTY_TERMS_CONDITIONS"),
      type: "textBox",
    },
    {
      num: earlyAccess ? "04" : "03",
      title: getLang(lang, "label.WARRANTY_EMAIL_SETTING"),
      type: "emailSetting",
    },
  ];

  const field = [
    {
      title: getLang(lang, "label.WARRANTY_NAME"),
      label: getLang(lang, "label.ENTER_NAME"),
      name: "warrantyName",
      type: "text",
      required: true,
    },
    {
      title: getLang(lang, "label.WARRANTY_PERIOD"),
      label: getLang(lang, "label.ENTER"),
      name: "warrantyPeriod",
      type: "date",
      required: true,
    },
    {
      title: getLang(lang, "label.REQUIRE_APPROVAL"),
      name: "warrantyApproval",
      type: "switch",
      required: false,
    },
    {
      title: getLang(lang, "label.ASSIGN_PRODUCT"),
      name: "productIds",
      type: "table",
      required: true,
    },
  ];

  if (earlyAccess) {
    sections.splice(2, 0, {
      num: "03",
      title: getLang(lang, "label.WARRANTY_REASON_OPTION"),
      type: "itemList",
    });

    field.splice(3, 0, {
      title: getLang(lang, "label.ENABLE_ITEM_REPLACEMENT_PROCESS"),
      name: "itemReplacementProcessEnabled",
      type: "switch",
      required: false,
    });
  }

  if (hasWarrantyValidateDoNumEditRight) {
    field.splice(3, 0, {
      title: getLang(lang, "label.VALIDATE_DO"),
      name: "warrantyRequiredValidation",
      type: "switch",
      required: false,
      tooltip: getLang(lang, "tooltips.VALIDATE_DO_TOOLTIP"),
    });
  }

  if (hasWarrantyPreRegisterEditRight) {
    field.splice(3, 0, {
      title: getLang(lang, "label.PREREGISTER_WARRANTY_OPTION"),
      name: "warrantyPreregisterEnabled",
      type: "switch",
      required: false,
      tooltip: getLang(lang, "tooltips.PREREGISTER_WARRANTY_OPTION_TOOLTIP"),
    });
  }

  const options = {
    cMapUrl: "cmaps/",
    cMapPacked: true,
    standardFontDataUrl: "standard_fonts/",
  };
  const isEdit = warrantySettingForm.productIds.length > 0;

  return (
    <Paper elevation={0} className={classes.root}>
      <Formik
        enableReinitialize
        initialValues={{
          warrantyName: warrantySettingForm.warrantyName,
          warrantyPeriod: warrantySettingForm.warrantyPeriod,
          warrantyApproval: warrantySettingForm.warrantyApproval,
          warrantyRequiredValidation:
            warrantySettingForm.warrantyRequiredValidation,
          productIds: warrantySettingForm.productIds,
          allowMultipleClaimReason:
            warrantySettingForm.allowMultipleClaimReason,
          claimReasons: warrantySettingForm.claimReasons,
          warrantyRegisterNotificationType: warrantySettingForm.warrantyRegisterNotificationType,
          claimApplicationNotificationType: warrantySettingForm.claimApplicationNotificationType,
          warrantyRegisterCustomRecipientField: '',
          registerEmail: (warrantySettingForm.customRecipient.warranty_registration || []),
          claimApplicationCustomRecipientField: '',
          claimEmail: (warrantySettingForm.customRecipient.claim_application || []),
          ...warrantySettingForm,
        }}
        validationSchema={Yup.object({
          warrantyName: Yup.string()
            .trim()
            .required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
          warrantyPeriod: Yup.number().moreThan(
            0,
            getLang(lang, "message.error.FORM_VALIDATE_MORETHAN", { value: 0 })
          ),
          claimReasons: Yup.lazy((reasons) => {
            const reasonByLang = {};

            for (const reason of reasons) {
              for (const used of usedLang) {
                reasonByLang[used.code] = reasonByLang[used.code]
                  ? [...reasonByLang[used.code], reason.name[used.code]]
                  : [reason.name[used.code]];
              }
            }

            return Yup.mixed()
              .test(
                "claimReasonEmpty",
                {
                  error: "claimReasonEmpty",
                  label: getLang(lang, "label.CLAIM_REASON_REQUIRED"),
                },
                () => {
                  if (earlyAccess) {
                    return reasons.length > 0;
                  } else {
                    return true;
                  }
                }
              )
              .test(
                "allFieldFilled",
                {
                  error: "allFieldFilled",
                  label: getLang(lang, "label.NOT_ALL_LANG_FILLED"),
                },
                () => {
                  let valid = true;
                  for (const key in reasonByLang) {
                    if (
                      reasonByLang[key].filter((r) => !!r).length !==
                      reasons.length
                    ) {
                      valid = false;
                      if (!emptyErrorOnLang.includes(key)) {
                        setEmptyErrorOnLang([...emptyErrorOnLang, key]);
                      }
                    } else {
                      if (emptyErrorOnLang.includes(key)) {
                        setEmptyErrorOnLang(
                          [...emptyErrorOnLang].filter((lang) => lang !== key)
                        );
                      }
                    }
                  }

                  return valid;
                }
              )
              .test(
                "allLabelUnique",
                {
                  error: "allLabelUnique",
                  label: getLang(lang, "label.NOT_ALL_LABEL_UNIQUE"),
                },
                () => {
                  let valid = true;
                  for (const key in reasonByLang) {
                    if (
                      reasonByLang[key].filter((r) => !!r).length ===
                        reasons.length &&
                      new Set(reasonByLang[key]).size !==
                        reasonByLang[key].length
                    ) {
                      valid = false;
                      if (!duplicateErrorOnLang.includes(key)) {
                        setDuplicateErrorOnLang([...duplicateErrorOnLang, key]);
                      }
                    } else {
                      if (duplicateErrorOnLang.includes(key)) {
                        setDuplicateErrorOnLang(
                          [...duplicateErrorOnLang].filter(
                            (lang) => lang !== key
                          )
                        );
                      }
                    }
                  }

                  return valid;
                }
              );
          }),
          registerEmail: Yup.array().when("warrantyRegisterNotificationType", {
            is: 2,
            then: Yup.array().required(
              getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")
            ),
          }),
          claimEmail: Yup.array().when("claimApplicationNotificationType", {
            is: 2,
            then: Yup.array().required(
              getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")
            ),
          }),
        })}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <form className={classes.root} onSubmit={formik.handleSubmit}>
            <Box p={3} pt={2} height="54vh" overflow="auto">
              {sections.map((section, index) => (
                <Box key={index}>
                  <Grid container>
                    <Grid item xs={1} sm={1} md={1} style={{ width: "100%" }}>
                      <Typography
                        variant="body2"
                        color="primary"
                        style={{ fontWeight: "bold" }}
                      >
                        {section.num}
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      xs={11}
                      sm={11}
                      md={11}
                      style={{ width: "100%" }}
                    >
                      <Typography
                        variant="body2"
                        color="primary"
                        style={{ fontWeight: "bold" }}
                      >
                        {section.title}
                      </Typography>

                      {section.type === "textField" ? (
                        field.map((field, index) => (
                          <Grid
                            container
                            key={index}
                            spacing={1}
                            className={
                              field.type !== "table"
                                ? classes.gridFieldContainer
                                : classes.gridFieldTableContainer
                            }
                          >
                            <Grid
                              item
                              xs={5}
                              sm={4}
                              md={4}
                              style={{
                                width: "100%",
                                marginBottom: type === "view" && field.name === 'itemReplacementProcessEnabled' &&
                                formik.getFieldProps("itemReplacementProcessEnabled").value ? '40px' : '0'
                              }}
                            >
                              <Typography
                                variant="body2"
                                color="primary"
                                className={clsx({
                                  [classes.switchPadding]:
                                    field.type === "switch" && type !== "view",
                                })}
                              >
                                {field.title}
                                {type !== "view" && field.required === true ? (
                                  type === "edit" &&
                                  field.name === "productIds" ? null : (
                                    <Typography
                                      style={{
                                        color: "#DE350B",
                                        display: "inline-block",
                                      }}
                                    >
                                      &nbsp;*
                                    </Typography>
                                  )
                                ) : null}
                                {field.tooltip && (
                                  <Tooltip title={field.tooltip}>
                                    <HelpOutlineOutlined
                                      style={{
                                        fontSize: "1rem",
                                        marginLeft: "0.5rem",
                                      }}
                                    />
                                  </Tooltip>
                                )}
                              </Typography>
                            </Grid>

                            {field.type === "date" ? (
                              <Grid
                                item
                                xs={7}
                                sm={4}
                                md={4}
                                className={clsx(classes.gridFieldPeriod, {
                                  [classes.gridDateView]: type === "view",
                                })}
                              >
                                {type === "view" ? (
                                  <Typography
                                    variant="body2"
                                    color="primary"
                                    style={{
                                      fontWeight: "bold",
                                      paddingRight: "16px",
                                    }}
                                  >
                                    {formik.getFieldProps(field.name).value}
                                  </Typography>
                                ) : (
                                  <InputTextField
                                    required
                                    variant="filled"
                                    size="small"
                                    type="number"
                                    onPaste={(e) =>
                                      InputHelper.preventCopyEDecimalAndNegetiveInNumberInput(
                                        e
                                      )
                                    }
                                    onKeyDown={(e) =>
                                      InputHelper.preventTypeEDecimalAndNegetiveInNumberInput(
                                        e
                                      )
                                    }
                                    InputProps={{
                                      inputProps: {
                                        min: 1,
                                        style: {
                                          padding: "8px",
                                          backgroundColor: "#ECEFF0",
                                          borderRadius: "4px 0px 0px 4px",
                                        },
                                      },
                                      style: {
                                        padding: "4px",
                                        backgroundColor: "#ECEFF0",
                                        borderRadius: "4px 0px 0px 4px",
                                      },
                                    }}
                                    FormHelperTextProps={{
                                      className: classes.helperText,
                                    }}
                                    placeholder={field.label}
                                    {...formik.getFieldProps(field.name)}
                                    error={
                                      formik.touched[field.name] &&
                                      formik.errors[field.name]
                                        ? true
                                        : false
                                    }
                                  />
                                )}

                                <Box display="flex">
                                  <Box className={classes.dividerContainer}>
                                    <Divider
                                      orientation="vertical"
                                      className={clsx(classes.divider, {
                                        [classes.dividerEdit]: type !== "view",
                                      })}
                                    />
                                  </Box>
                                  <Box
                                    className={clsx({
                                      [classes.monthView]: viewType,
                                      [classes.monthContainer]: !viewType,
                                    })}
                                  >
                                    <Typography
                                      variant="subtitle2"
                                      style={{
                                        fontWeight: "bold",
                                        color: " rgba(58, 77, 84, 0.33)",
                                      }}
                                    >
                                      {getLang(lang, "label.MONTH")}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Grid>
                            ) : field.type === "text" ? (
                              <Grid item xs={7} sm={8} md={8}>
                                {type === "view" ? (
                                  <Typography
                                    variant="body2"
                                    color="primary"
                                    className={classes.viewField}
                                  >
                                    {formik.getFieldProps(field.name).value}
                                  </Typography>
                                ) : (
                                  <InputTextField
                                    required
                                    variant="filled"
                                    size="small"
                                    fullWidth
                                    placeholder={getLang(
                                      lang,
                                      "label.ENTER_NAME"
                                    )}
                                    {...formik.getFieldProps(field.name)}
                                    error={
                                      formik.touched[field.name] &&
                                      formik.errors[field.name]
                                        ? true
                                        : false
                                    }
                                    helperText={ErrorMessage({
                                      name: field.name,
                                    })}
                                  />
                                )}
                              </Grid>
                            ) : field.type === "switch" ? (
                              <>
                                <Grid
                                  item
                                  xs={7}
                                  sm={8}
                                  md={8}
                                  className={clsx({
                                    [classes.switchEdit]: type === "edit",
                                  })}
                                >
                                  {type === "view" ? (
                                    <Grid item>
                                      <Typography
                                        variant="body2"
                                        color="primary"
                                        className={classes.viewField}
                                      >
                                        {formik.getFieldProps(field.name).value
                                          ? getLang(lang, "label.YES")
                                          : getLang(lang, "label.NO")}
                                      </Typography>
                                      {field.name === "itemReplacementProcessEnabled" &&
                                        formik.getFieldProps("itemReplacementProcessEnabled").value ? (
                                        <Grid item>
                                          <Grid
                                            container
                                            direction="row"
                                            alignItems="center"
                                            spacing={1}
                                          >
                                            <Grid item>
                                              <Checkbox
                                                disabled
                                                checked={Boolean(
                                                  formik.values.allowDifferentProductReplacement
                                                )}
                                              />
                                            </Grid>
                                            <Grid item>
                                              <Typography style={{ fontSize: "14px" }}>
                                                {getLang(lang, "Allow different product for replacement in this warranty")}
                                              </Typography>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      ) : null}
                                    </Grid>
                                  ) : (
                                    <Grid
                                      item
                                      style={{
                                        marginTop: field.name === 'itemReplacementProcessEnabled' ? '40px' : '0'
                                      }}
                                    >
                                      <AntSwitch
                                        checked={Boolean(
                                          formik.getFieldProps(field.name).value
                                        )}
                                        onChange={(e) => {
                                          formik.setFieldValue(
                                            field.name,
                                            !Boolean(
                                              formik.getFieldProps(field.name).value
                                            )
                                          );

                                      if (
                                        field.name === "warrantyApproval" ||
                                        field.name ===
                                        "warrantyPreregisterEnabled"
                                      ) {
                                        if (
                                          !formik.getFieldProps(field.name)
                                            .value === true
                                        ) {
                                          let opposite =
                                            field.name === "warrantyApproval"
                                              ? "warrantyPreregisterEnabled"
                                              : "warrantyApproval";

                                              formik.setFieldValue(opposite, false);
                                            }
                                          }
                                        }}
                                        disabled={formik.isSubmitting}
                                      />
                                      {field.name === "itemReplacementProcessEnabled" ? (
                                        <Grid item>
                                          <Grid
                                            container
                                            direction="row"
                                            alignItems="center"
                                            spacing={1}
                                          >
                                            <Grid item>
                                              <Checkbox
                                                checked={Boolean(
                                                  formik.values.allowDifferentProductReplacement
                                                )}
                                                onChange={(e) =>
                                                  formik.setFieldValue("allowDifferentProductReplacement",
                                                    e.target.checked
                                                  )}
                                              />
                                            </Grid>
                                            <Grid item>
                                              <Typography style={{ fontSize: "14px" }}>
                                                {getLang(lang, "Allow replacing different products allocated to this warranty")}
                                                {/* remember to change this */}
                                              </Typography>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      ) : null}
                                    </Grid>
                                  )}
                                </Grid>
                              </>
                            ) : (
                              field.type === "table" && (
                                <Grid
                                  item
                                  xs={7}
                                  sm={8}
                                  md={8}
                                  className={clsx({
                                    [classes.switchTableEdit]: type === "edit",
                                  })}
                                >
                                  {!viewType && (
                                    <WarrantyFormAssignProductSelect
                                      value={formik.values.productIds}
                                      handleChange={(value) =>
                                        formik.setFieldValue(
                                          "productIds",
                                          value
                                        )
                                      }
                                      disabled={viewType}
                                      isEdit={isEdit}
                                    />
                                  )}
                                  <div
                                    className={clsx({
                                      [classes.productTableView]: viewType,
                                    })}
                                  >
                                    <AssignProductTable
                                      tableData={formik.values.productIds}
                                    />
                                  </div>
                                </Grid>
                              )
                            )}
                          </Grid>
                        ))
                      ) : section.type === "textBox" ? (
                        <Box>
                          {type === "view" ? (
                            <Box>
                              <PerfectScrollbar
                                className={classes.scroll}
                                options={{
                                  suppressScrollX: true,
                                }}
                              >
                                <Box
                                  className={clsx({
                                    [classes.viewTC]: formik.getFieldProps(
                                      "warrantyTermsConditionText"
                                    ).value,
                                    [classes.viewUrl]: formik.getFieldProps(
                                      "warrantyTermsConditionDocument"
                                    ).value,
                                  })}
                                >
                                  {warrantySettingForm.warrantyTermsConditionDocument ? (
                                    <>
                                      <Box>
                                        <Document
                                          file={
                                            formik.getFieldProps("fileUrl")
                                              .value
                                          }
                                          onLoadSuccess={onDocumentLoadSuccess}
                                          options={options}
                                        >
                                          <Page pageNumber={pageNumber} />
                                        </Document>
                                      </Box>
                                    </>
                                  ) : (
                                    <>
                                      <Box
                                        dangerouslySetInnerHTML={{
                                          __html: formik.getFieldProps(
                                            "termsConditionDetail"
                                          ).value.value,
                                        }}
                                      />
                                    </>
                                  )}
                                </Box>
                              </PerfectScrollbar>
                              {formik.getFieldProps(
                                "warrantyTermsConditionDocument"
                              ).value &&
                                numPages !== null &&
                                numPages > 0 && (
                                  <Box className={classes.pageControl}>
                                    <Button
                                      onClick={() =>
                                        handleTncPdfChangePage(false)
                                      }
                                      disabled={pageNumber === 1}
                                    >
                                      <ChevronLeftIcon />
                                    </Button>
                                    <span>
                                      Page {pageNumber} of {numPages}
                                    </span>
                                    <Button
                                      onClick={() =>
                                        handleTncPdfChangePage(true)
                                      }
                                      disabled={pageNumber === numPages}
                                    >
                                      <ChevronRightIcon />
                                    </Button>
                                  </Box>
                                )}
                            </Box>
                          ) : (
                            <>
                              <Box className={classes.switchContainer}>
                                <Box className={classes.switch}>
                                  <AntSwitch
                                    checked={
                                      formik.getFieldProps(
                                        "warrantyTermsConditionText"
                                      ).value
                                    }
                                    onClick={(e) => {
                                      formik.setFieldValue(
                                        "warrantyTermsConditionText",
                                        true
                                      );

                                      formik.setFieldValue(
                                        "warrantyTermsConditionDocument",
                                        false
                                      );
                                    }}
                                    disabled={formik.isSubmitting}
                                  />
                                  <Typography
                                    variant="body2"
                                    className={clsx(classes.textDisable, {
                                      [classes.textActive]:
                                        formik.getFieldProps(
                                          "warrantyTermsConditionText"
                                        ).value,
                                    })}
                                  >
                                    {getLang(lang, "label.TEXT_BOX")}
                                  </Typography>
                                </Box>

                                <Typography
                                  variant="body2"
                                  style={{ fontWeight: "bold" }}
                                >
                                  {getLang(lang, "label.OR")}
                                </Typography>

                                <Box className={classes.switch}>
                                  <AntSwitch
                                    checked={
                                      formik.getFieldProps(
                                        "warrantyTermsConditionDocument"
                                      ).value
                                    }
                                    onChange={(e) => {
                                      formik.setFieldValue(
                                        "warrantyTermsConditionText",
                                        false
                                      );

                                      formik.setFieldValue(
                                        "warrantyTermsConditionDocument",
                                        true
                                      );
                                    }}
                                    disabled={formik.isSubmitting}
                                  />
                                  <Typography
                                    variant="body2"
                                    className={clsx(classes.textDisable, {
                                      [classes.textActive]:
                                        formik.getFieldProps(
                                          "warrantyTermsConditionDocument"
                                        ).value,
                                    })}
                                  >
                                    {getLang(lang, "label.UPLOAD_DOCUMENT")}
                                  </Typography>
                                </Box>
                              </Box>
                              <Box>
                                {formik.getFieldProps(
                                  "warrantyTermsConditionDocument"
                                ).value && (
                                  <UploadDocumentComponent
                                    value={
                                      formik.getFieldProps(
                                        "termsConditionDetail"
                                      ).value
                                    }
                                    handleUpload={(file) => {
                                      setTncEdited(true);
                                      formik.setFieldValue(
                                        "termsConditionDetail",
                                        file
                                      );
                                    }}
                                    reuploadFlag={
                                      type !== "create" &&
                                      warrantySettingForm.fileUrl.substr(
                                        warrantySettingForm.fileUrl.length - 3
                                      ) === "pdf"
                                    }
                                    maxUploadSize={{ size: 15, unit: "MB" }}
                                  />
                                )}
                                {formik.getFieldProps(
                                  "warrantyTermsConditionText"
                                ).value && (
                                  <EditorComponent
                                    type={type === "create" ? "new" : "edit"}
                                    initialValues={
                                      formik.getFieldProps(
                                        "termsConditionDetail"
                                      ).value
                                    }
                                    handleChange={(value) => {
                                      if (
                                        formik.getFieldProps(
                                          "termsConditionDetail"
                                        ).value.value !==
                                        formik.initialValues
                                          .termsConditionDetail.value
                                      ) {
                                        setTncEdited(true);
                                      } else {
                                        setTncEdited(false);
                                      }
                                      formik.setFieldValue(
                                        "termsConditionDetail",
                                        value
                                      );
                                    }}
                                  />
                                )}
                              </Box>
                            </>
                          )}
                        </Box>
                      ) : earlyAccess && section.type === "itemList" ? (
                        <Box>
                          <Box mt={3}>
                            <RadioGroup
                              row
                              name="allowMultipleClaimReason"
                              defaultValue="0"
                              value={
                                formik.values.allowMultipleClaimReason
                                  ? "1"
                                  : "0"
                              }
                              onChange={(e) => {
                                formik.setFieldValue(
                                  "allowMultipleClaimReason",
                                  !formik.values.allowMultipleClaimReason
                                );
                              }}
                            >
                              <FormControlLabel
                                value="0"
                                control={
                                  <Radio
                                    disabled={
                                      formik.isSubmitting ||
                                      !(type === "edit" || type === "create")
                                    }
                                  />
                                }
                                label="Radio Button (Single Option)"
                                labelPlacement="end"
                              />
                              <FormControlLabel
                                value="1"
                                control={
                                  <Radio
                                    disabled={
                                      formik.isSubmitting ||
                                      !(type === "edit" || type === "create")
                                    }
                                  />
                                }
                                label="Checkbox (Multiple Options)"
                                labelPlacement="end"
                              />
                            </RadioGroup>
                          </Box>
                          <Box
                            border={1}
                            borderRadius={6}
                            mt={2}
                            borderColor="rgba(0, 0, 0, 0.1)"
                          >
                            <Box>
                              <LanguageSelect
                                handleAddLang={(code) => {
                                  const language = addableLangs.find(e => e.code === code);
                                  handleAddLang(language);
                                }}
                                handleDeleteLang={(code) => {
                                  const temp = [];
                                  for (let reason of formik.values
                                    .claimReasons) {
                                    temp.push(processReason(reason, code));
                                  }
                                  handleRemoveLang(code);
                                  usedLang = [...usedLang].filter((item) => item.code !== code);
                                  formik.setFieldValue("claimReasons", temp);
                                }}
                                handleChangeLang={(code) => {
                                  const index = usedLang.findIndex(e => e.code === code);
                                  setLanguageTabIndex(index);
                                }}
                                textStyle={{
                                  fontWeight: "normal"
                                }}
                                value={usedLang[languageTabIndex].code}
                                selectedLanguages={usedLang.map(e => e.code)}
                                disabled={!(type === "edit" || type === "create")}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "right"
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "right",
                                }}
                              />
                            </Box>
                            <Divider color="rgba(0, 0, 0, 0.1)" />
                            <Box p={2}>
                              {formik.values.claimReasons.length > 0 && (
                                <Box width="100%" mt={1} mb={1}>
                                  <Grid container>
                                    <Grid item xs={10}>
                                      {getLang(lang, "label.REASON")}
                                    </Grid>
                                    {type === "edit" || type === "create" ? (
                                      <Grid item xs={1}>
                                        <Box textAlign="center">
                                          {getLang(lang, "label.REMOVE")}
                                        </Box>
                                      </Grid>
                                    ) : (
                                      <Grid item xs={1} />
                                    )}
                                    <Grid item xs={1}>
                                      <Box textAlign="center">
                                        {getLang(lang, "label.ENABLE")}
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </Box>
                              )}

                              {formik.values.claimReasons.map(
                                (reason, index) => (
                                  <Box mb={1} width="100%">
                                    <Grid container>
                                      {/*Field for reason name, disable if have id*/}
                                      <Grid item xs={10}>
                                        <Box
                                          display="flex"
                                          justifyContent="center"
                                          alignItems="center"
                                          height="100%"
                                        >
                                          <InputTextField
                                            variant="filled"
                                            size="small"
                                            fullWidth
                                            placeholder={getLang(
                                              lang,
                                              "placeholder.CLAIM_REASON"
                                            )}
                                            onChange={(e) => {
                                              const temp = [
                                                ...formik.values.claimReasons,
                                              ];
                                              const code =
                                                usedLang[languageTabIndex].code;
                                              temp[index] = {
                                                ...temp[index],
                                                name: {
                                                  ...temp[index].name,
                                                  [code]: e.target.value,
                                                },
                                                command:
                                                  temp[index].command &&
                                                  temp[index].command.includes(
                                                    "add"
                                                  )
                                                    ? [
                                                        ...(temp[index]
                                                          .command || []),
                                                      ]
                                                    : temp[index].command &&
                                                      temp[
                                                        index
                                                      ].command.includes("edit")
                                                    ? [
                                                        ...(temp[index]
                                                          .command || []),
                                                      ]
                                                    : [
                                                        ...(temp[index]
                                                          .command || []),
                                                        "edit",
                                                      ],
                                              };
                                              formik.setFieldValue(
                                                "claimReasons",
                                                temp
                                              );
                                            }}
                                            disabled={
                                              formik.isSubmitting ||
                                              !(
                                                type === "edit" ||
                                                type === "create"
                                              )
                                            }
                                            value={
                                              reason.name[
                                                usedLang[languageTabIndex].code
                                              ] || ""
                                            }
                                          />
                                        </Box>
                                      </Grid>
                                      {type === "edit" || type === "create" ? (
                                        <>
                                          {/*Delete button for unsave added reason*/}
                                          <Grid item xs={1}>
                                            <Box
                                              display="flex"
                                              justifyContent="center"
                                              alignItems="center"
                                            >
                                              <IconButton
                                                onClick={() => {
                                                  const temp = [
                                                    ...formik.values
                                                      .claimReasons,
                                                  ];
                                                  temp.splice(index, 1);
                                                  formik.setFieldValue(
                                                    "claimReasons",
                                                    temp
                                                  );
                                                }}
                                                disabled={
                                                  formik.isSubmitting ||
                                                  !!reason.id
                                                }
                                              >
                                                <DeleteForeverIcon />
                                              </IconButton>
                                            </Box>
                                          </Grid>
                                        </>
                                      ) : (
                                        <>
                                          <Grid item xs={1} />
                                        </>
                                      )}
                                      {/*Switch to enable reason*/}
                                      <Grid item xs={1}>
                                        <Box
                                          display="flex"
                                          justifyContent="center"
                                          alignItems="center"
                                          height="100%"
                                        >
                                          <AntSwitch
                                            disabled={
                                              formik.isSubmitting ||
                                              !reason.id ||
                                              !(
                                                type === "edit" ||
                                                type === "create"
                                              )
                                            }
                                            checked={
                                              !(
                                                formik.values.claimReasons[
                                                  index
                                                ].command &&
                                                formik.values.claimReasons[
                                                  index
                                                ].command.includes("disable")
                                              ) &&
                                              ((formik.values.claimReasons[
                                                index
                                              ].command &&
                                                formik.values.claimReasons[
                                                  index
                                                ].command.includes("enable")) ||
                                                !!formik.values.claimReasons[
                                                  index
                                                ].isEnabled)
                                            }
                                            onChange={(value) => {
                                              const temp = [
                                                ...formik.values.claimReasons,
                                              ];
                                              let newCommand = temp[index]
                                                .command
                                                ? temp[index].command.filter(
                                                    (item) =>
                                                      item !== "enable" &&
                                                      item !== "disable"
                                                  )
                                                : [];

                                              if (value.target.checked) {
                                                if (!temp[index].isEnabled) {
                                                  newCommand.push("enable");
                                                }
                                              } else {
                                                if (temp[index].isEnabled) {
                                                  newCommand.push("disable");
                                                }
                                              }
                                              temp[index] = {
                                                ...temp[index],
                                                command: newCommand,
                                              };
                                              formik.setFieldValue(
                                                "claimReasons",
                                                temp
                                              );
                                            }}
                                          />
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </Box>
                                )
                              )}

                              {/*Button to add new reason*/}
                              {(type === "edit" || type === "create") && (
                                <Box mt={2}>
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    disabled={formik.isSubmitting}
                                    onClick={() => {
                                      const temp = [
                                        ...formik.values.claimReasons,
                                      ];
                                      temp.push({
                                        name: {},
                                        command: ["add"],
                                        isEnabled: 1,
                                      });
                                      formik.setFieldValue(
                                        "claimReasons",
                                        temp
                                      );
                                    }}
                                  >
                                    {getLang(lang, "label.ADD")}
                                  </Button>
                                </Box>
                              )}
                            </Box>
                          </Box>
                          {formik.errors.claimReasons && (
                            <Box color="error.main" my={1} fontSize={10}>
                              {formik.errors.claimReasons.label}
                            </Box>
                          )}
                        </Box>
                      ) : section.type === "emailSetting" ? (
                        <>
                          <Box mt={3}>
                            <Box fontWeight="fontWeightBold">
                              {getLang(lang, "label.WARRANTY_REGISTRATIONS")}
                            </Box>
                            <Box mt={2}>
                              <RadioGroup
                                name="warrantyRegisterNotificationType"
                                defaultValue={1}
                                value={
                                  formik.values.warrantyRegisterNotificationType
                                }
                                onChange={(e, value) => {
                                  formik.setFieldValue(
                                    "warrantyRegisterNotificationType",
                                    parseInt(value)
                                  );
                                }}
                              >
                                <FormControlLabel
                                  value={0}
                                  control={
                                    <Radio
                                      disabled={
                                        formik.isSubmitting ||
                                        !(type === "edit" || type === "create")
                                      }
                                    />
                                  }
                                  label={getLang(
                                    lang,
                                    "label.WARRANTY_EMAIL_NOTIFY_NONE"
                                  )}
                                  labelPlacement="end"
                                />
                                <FormControlLabel
                                  value={1}
                                  control={
                                    <Radio
                                      disabled={
                                        formik.isSubmitting ||
                                        !(type === "edit" || type === "create")
                                      }
                                    />
                                  }
                                  label={getLang(
                                    lang,
                                    "label.WARRANTY_EMAIL_NOTIFY_ALL"
                                  )}
                                  labelPlacement="end"
                                />
                                <FormControlLabel
                                  value={2}
                                  control={
                                    <Radio
                                      disabled={
                                        formik.isSubmitting ||
                                        !(type === "edit" || type === "create")
                                      }
                                    />
                                  }
                                  label={getLang(
                                    lang,
                                    "label.WARRANTY_EMAIL_NOTIFY_SPECIFIC"
                                  )}
                                  labelPlacement="end"
                                />
                              </RadioGroup>

                              <Box
                                style={{
                                  display: "flex",
                                  marginTop: "10px",
                                  flexWrap: "wrap",
                                }}
                              >
                                {formik.getFieldProps(
                                  "warrantyRegisterNotificationType"
                                ).value === 2 ? (
                                  formik.values.registerEmail.length === 0 ? (
                                    <span style={{ color: "red" }}>
                                      *
                                      {getLang(
                                        lang,
                                        "message.error.FORM_VALIDATE_REQUIRED"
                                      )}
                                    </span>
                                  ) : null
                                ) : null}

                                {formik.values.registerEmail.length > 0
                                  ? formik.values.registerEmail.map((email) => (
                                      <Box
                                        key={email}
                                        style={{
                                          marginRight: "8px",
                                          marginBottom: "8px",
                                        }}
                                      >
                                        <Chip
                                          label={email}
                                          onDelete={() => {
                                            let temp =
                                              formik.values.registerEmail.filter(
                                                (war) => war !== email
                                              );
                                            formik.setFieldValue(
                                              "registerEmail",
                                              temp
                                            );
                                          }}
                                          disabled={
                                            formik.isSubmitting ||
                                            !(
                                              type === "edit" ||
                                              type === "create"
                                            ) ||
                                            formik.getFieldProps(
                                              "warrantyRegisterNotificationType"
                                            ).value !== 2
                                          }
                                        />
                                      </Box>
                                    ))
                                  : null}
                              </Box>
                              {type !== "view" ? (
                                <>
                                  <InputTextField
                                    variant="filled"
                                    size="small"
                                    fullWidth
                                    placeholder={getLang(
                                      lang,
                                      "placeholder.ENTER_EMAIL"
                                    )}
                                    InputProps={{
                                      style: {
                                        marginTop: "1rem",
                                        padding: "4px",
                                        backgroundColor: "#ECEFF0",
                                        borderRadius: "4px 0px 0px 4px",
                                      },
                                      classes: { input: classes.inputRoot },
                                    }}
                                    onChange={(e) => {
                                      formik.setFieldValue(
                                        "warrantyRegisterCustomRecipientField",
                                        e.target.value
                                      );
                                    }}
                                    onKeyPress={(e) => {
                                      if (
                                        (e.key === "Enter" || e.key === ";") &&
                                        formik.values
                                          .warrantyRegisterCustomRecipientField !==
                                          ""
                                      ) {
                                        e.preventDefault();
                                        let errorMsg = "";
                                        let isError = false;
                                        let array = [
                                          formik.values.warrantyRegisterCustomRecipientField.trim(),
                                        ];
                                        let arrayToInsert = [];

                                        if (array.length !== 0) {
                                          array.forEach((input) => {
                                            let val = input.trim();
                                            if (
                                              !formik.errors
                                                .warrantyRegisterCustomRecipientField
                                            ) {
                                              if (formik.values.registerEmail) {
                                                const isValidEmail =
                                                  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\s*;\s*[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})*$/.test(
                                                    val
                                                  );

                                                if (isValidEmail) {
                                                  if (
                                                    formik.values.registerEmail.filter(
                                                      (email) => email === val
                                                    ).length === 0 &&
                                                    arrayToInsert.filter(
                                                      (value) => value === val
                                                    ).length === 0
                                                  ) {
                                                    arrayToInsert = [
                                                      ...arrayToInsert,
                                                      val,
                                                    ];
                                                  } else {
                                                    isError = true;
                                                    errorMsg = getLang(
                                                      lang,
                                                      "message.error.ERROR_DUPLICATE_EMAIL"
                                                    );
                                                  }
                                                } else {
                                                  isError = true;
                                                  errorMsg = getLang(
                                                    lang,
                                                    "message.error.FORM_VALIDATE_EMAIL"
                                                  );
                                                }
                                              } else {
                                                isError = true;
                                                errorMsg = getLang(
                                                  lang,
                                                  "message.error.FORM_VALIDATE_EMAIL"
                                                );
                                              }
                                            }
                                          });
                                        }
                                        if (
                                          arrayToInsert.length !== 0 &&
                                          !isError
                                        ) {
                                          formik.setFieldValue(
                                            "registerEmail",
                                            [
                                              ...formik.values.registerEmail,
                                              ...arrayToInsert,
                                            ]
                                          );
                                          formik.setFieldValue(
                                            "warrantyRegisterCustomRecipientField",
                                            ""
                                          );
                                        }
                                        formik.setFieldError(
                                          "warrantyRegisterCustomRecipientField",
                                          errorMsg
                                        );
                                      }
                                    }}
                                    error={
                                      formik.errors
                                        .warrantyRegisterCustomRecipient
                                        ? true
                                        : false
                                    }
                                    helperText={
                                      formik.errors
                                        .warrantyRegisterCustomRecipient
                                    }
                                    {...formik.getFieldProps(
                                      "warrantyRegisterCustomRecipientField"
                                    )}
                                    disabled={
                                      formik.isSubmitting ||
                                      !(type === "edit" || type === "create") ||
                                      formik.getFieldProps(
                                        "warrantyRegisterNotificationType"
                                      ).value !== 2
                                    }
                                  />
                                  <Box my={1} fontSize={13}>
                                    {getLang(
                                      lang,
                                      "paragraph.ENTER_MULTIPLE_EMAIL_WITH_SEPARATOR",
                                      {
                                        separatorName: getLang(
                                          lang,
                                          "label.SEMICOLUN"
                                        ),
                                        separator: ";",
                                      }
                                    )}
                                  </Box>
                                </>
                              ) : (
                                <></>
                              )}
                            </Box>
                          </Box>

                          <Box mt={3}>
                            <Box fontWeight="fontWeightBold">
                              {getLang(lang, "label.WARRANTY_CLAIMS")}
                            </Box>
                            <Box mt={2}>
                              <RadioGroup
                                name="claimApplicationNotificationType"
                                defaultValue={1}
                                value={
                                  formik.values.claimApplicationNotificationType
                                }
                                onChange={(e, value) => {
                                  formik.setFieldValue(
                                    "claimApplicationNotificationType",
                                    parseInt(value)
                                  );
                                }}
                              >
                                <FormControlLabel
                                  value={0}
                                  control={
                                    <Radio
                                      disabled={
                                        formik.isSubmitting ||
                                        !(type === "edit" || type === "create")
                                      }
                                    />
                                  }
                                  label={getLang(
                                    lang,
                                    "label.WARRANTY_EMAIL_NOTIFY_NONE"
                                  )}
                                  labelPlacement="end"
                                />
                                <FormControlLabel
                                  value={1}
                                  control={
                                    <Radio
                                      disabled={
                                        formik.isSubmitting ||
                                        !(type === "edit" || type === "create")
                                      }
                                    />
                                  }
                                  label={getLang(
                                    lang,
                                    "label.WARRANTY_EMAIL_NOTIFY_ALL"
                                  )}
                                  labelPlacement="end"
                                />
                                <FormControlLabel
                                  value={2}
                                  control={
                                    <Radio
                                      disabled={
                                        formik.isSubmitting ||
                                        !(type === "edit" || type === "create")
                                      }
                                    />
                                  }
                                  label={getLang(
                                    lang,
                                    "label.WARRANTY_EMAIL_NOTIFY_SPECIFIC"
                                  )}
                                  labelPlacement="end"
                                />
                              </RadioGroup>

                              <Box
                                style={{
                                  display: "flex",
                                  marginTop: "10px",
                                  flexWrap: "wrap",
                                }}
                              >
                                {formik.getFieldProps(
                                  "claimApplicationNotificationType"
                                ).value === 2 ? (
                                  formik.values.claimEmail.length === 0 ? (
                                    <span style={{ color: "red" }}>
                                      *
                                      {getLang(
                                        lang,
                                        "message.error.FORM_VALIDATE_REQUIRED"
                                      )}
                                    </span>
                                  ) : null
                                ) : null}
                                {formik.values.claimEmail.length > 0
                                  ? formik.values.claimEmail.map((email) => (
                                      <Box
                                        key={email}
                                        style={{
                                          marginRight: "8px",
                                          marginBottom: "8px",
                                        }}
                                      >
                                        <Chip
                                          label={email}
                                          onDelete={() => {
                                            let temp =
                                              formik.values.claimEmail.filter(
                                                (war) => war !== email
                                              );
                                            formik.setFieldValue(
                                              "claimEmail",
                                              temp
                                            );
                                          }}
                                          disabled={
                                            formik.isSubmitting ||
                                            !(
                                              type === "edit" ||
                                              type === "create"
                                            ) ||
                                            formik.getFieldProps(
                                              "warrantyRegisterNotificationType"
                                            ).value !== 2
                                          }
                                        />
                                      </Box>
                                    ))
                                  : null}
                              </Box>

                              {type !== "view" ? (
                                <>
                                  <InputTextField
                                    variant="filled"
                                    size="small"
                                    fullWidth
                                    placeholder={getLang(
                                      lang,
                                      "placeholder.ENTER_EMAIL"
                                    )}
                                    InputProps={{
                                      style: {
                                        marginTop: "1rem",
                                        padding: "4px",
                                        backgroundColor: "#ECEFF0",
                                        borderRadius: "4px 0px 0px 4px",
                                      },
                                      classes: { input: classes.inputRoot },
                                    }}
                                    onChange={(e) => {
                                      formik.setFieldValue(
                                        "claimApplicationCustomRecipientField",
                                        e.target.value
                                      );
                                    }}
                                    onKeyPress={(e) => {
                                      if (
                                        (e.key === "Enter" || e.key === ";") &&
                                        formik.values
                                          .claimApplicationCustomRecipientField !==
                                          ""
                                      ) {
                                        e.preventDefault();
                                        let errorMsg = "";
                                        let isError = false;
                                        let array = [
                                          formik.values.claimApplicationCustomRecipientField.trim(),
                                        ];
                                        let arrayToInsert = [];
                                        if (array.length !== 0) {
                                          array.forEach((input) => {
                                            let val = input.trim();
                                            if (
                                              val !== "" &&
                                              !formik.errors
                                                .claimApplicationCustomRecipientField
                                            ) {
                                              if (formik.values.claimEmail) {
                                                const isValidEmail =
                                                  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\s*;\s*[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})*$/.test(
                                                    val
                                                  );

                                                if (isValidEmail) {
                                                  if (
                                                    formik.values.claimEmail.filter(
                                                      (email) => email === val
                                                    ).length === 0 &&
                                                    arrayToInsert.filter(
                                                      (value) => value === val
                                                    ).length === 0
                                                  ) {
                                                    arrayToInsert = [
                                                      ...arrayToInsert,
                                                      val,
                                                    ];
                                                  } else {
                                                    isError = true;
                                                    errorMsg = getLang(
                                                      lang,
                                                      "message.error.ERROR_DUPLICATE_EMAIL"
                                                    );
                                                  }
                                                } else {
                                                  isError = true;
                                                  errorMsg = getLang(
                                                    lang,
                                                    "message.error.FORM_VALIDATE_EMAIL"
                                                  );
                                                }
                                              } else {
                                                isError = true;
                                                errorMsg = getLang(
                                                  lang,
                                                  "message.error.FORM_VALIDATE_EMAIL"
                                                );
                                              }
                                            }
                                          });
                                        }

                                        if (
                                          arrayToInsert.length !== 0 &&
                                          !isError
                                        ) {
                                          formik.setFieldValue("claimEmail", [
                                            ...formik.values.claimEmail,
                                            ...arrayToInsert,
                                          ]);
                                          formik.setFieldValue(
                                            "claimApplicationCustomRecipientField",
                                            ""
                                          );
                                        }
                                        formik.setFieldError(
                                          "claimApplicationCustomRecipientField",
                                          errorMsg
                                        );
                                      }
                                    }}
                                    error={
                                      formik.errors
                                        .claimApplicationCustomRecipientField
                                        ? true
                                        : false
                                    }
                                    helperText={
                                      formik.errors
                                        .claimApplicationCustomRecipientField
                                    }
                                    {...formik.getFieldProps(
                                      "claimApplicationCustomRecipientField"
                                    )}
                                    disabled={
                                      formik.isSubmitting ||
                                      !(type === "edit" || type === "create") ||
                                      formik.getFieldProps(
                                        "claimApplicationNotificationType"
                                      ).value !== 2
                                    }
                                  />
                                  <Box my={1} fontSize={13}>
                                    {getLang(
                                      lang,
                                      "paragraph.ENTER_MULTIPLE_EMAIL_WITH_SEPARATOR",
                                      {
                                        separatorName: getLang(
                                          lang,
                                          "label.SEMICOLUN"
                                        ),
                                        separator: ";",
                                      }
                                    )}
                                  </Box>
                                </>
                              ) : (
                                <></>
                              )}
                            </Box>
                          </Box>
                        </>
                      ) : null}
                    </Grid>
                  </Grid>

                  {
                    sections.length > index + 1 ? (
                      <Box pt={3} pb={2}>
                        <Divider />
                      </Box>
                    ) : null
                  }
                </Box>
              ))}
            </Box>

            {children ? children() : null}
          </form>
        )}
      </Formik>
    </Paper >
  );
}
