import axios from "axios";

const endpoint_url = "/campaignV2/customer/instantLuckyDraw/list";

const getAllInstantLuckyDraw = (parameters) => {
  return new Promise((resolve, reject) => {
    axios
      .get(endpoint_url, {
        params: {
          draw: parameters.draw,
          limit: parameters.length,
          skip: parameters.start,
          sortBy: parameters.column,
          orderBy: parameters.order,
          search: parameters.search,
          product: parameters.product,
        },
      })
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

export default getAllInstantLuckyDraw;
