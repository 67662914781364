import React from "react";
import { makeStyles, Box, Typography, Paper } from "@material-ui/core";
import { getLang } from "app/feature/constants";
import CampaignTngDisbursementTable from "../../table/campaignTngDisbursementTable";

const useStyle = makeStyles((theme) => ({
  greyText: {
    color: "#a1a1a1",
  },
}));

export default function CampaignTngDisbursementPanelComponent({
  lang,
  campaignLogs,
  totalFiltered,
  isFetching,
  isError,
  handleChangePage,
  handleChangeRowsPerPage,
  tableFilter,
  handleReload,
  paginationOptions,
  handleExport,
  disbursementInfo,
}) {
  const classes = useStyle();

  return (
    <Box>
      <Box mb={0.5}>
        <Typography variant="body1">
          <b>{getLang(lang, "label.CAMPAIGN_TNG_DISBURSEMENT_TITLE")}</b>
        </Typography>
      </Box>
      <Box mb={1.5}>
        <Typography variant="body2" className={classes.greyText}>
          {getLang(lang, "paragraph.CAMPAIGN_TNG_DISBURSEMENT_DESC")}
        </Typography>
      </Box>
      <Paper elevation={2}>
        <Box p={2}>
          <CampaignTngDisbursementTable
            rows={campaignLogs}
            isFetching={isFetching}
            isError={isError}
            handleReload={handleReload}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            totalFiltered={totalFiltered}
            paginationOptions={paginationOptions}
            tableFilter={tableFilter}
            lang={lang}
            handleExport={handleExport}
            disbursementInfo={disbursementInfo}
          />
        </Box>
      </Paper>
    </Box>
  );
}
