import React from "react";
import {
  makeStyles,
  Box,
  IconButton,
  Dialog,
  Typography,
  Backdrop,
  Divider,
  Button,
  Tabs,
} from "@material-ui/core";
import { CloseRounded as CloseRoundedIcon } from "@material-ui/icons";
import { getLang } from "app/feature/constants";
import "react-image-lightbox/style.css";
import DefaultImg from "assets/img/img-default.png";
import ProductSNListTable from "../../table/productSNListTable";
import Input from "components/input/inputTextField";
import { Formik } from "formik";
import CustomTab from "components/tab";

const useStyle = makeStyles((theme) => ({
  header: {
    padding: "12px 16px",
    borderBottom: "1px solid #D0D5DD",
  },
  productContainer: {
    backgroundColor: "#FFFFFF",
    border: "1px solid #D0D5DD",
    "& > div:not(:last-child)": {
      borderBottom: "1px solid #D0D5DD",
    },
  },
  container: {
    backgroundColor: "#EBEDEE",
    overflowY: "auto",
    padding: "16px 16px 34px 16px",
  },
  productPhoto: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #0000001a",
    width: "45px",
    minWidth: "45px",
    height: "45px",
    borderRadius: "4px",
    "& img": {
      height: "100%",
      width: "100%",
    },
  },
  searchContainer: {
    backgroundColor: theme.palette.background.paper,
    display: "grid",
    gridTemplateColumns: "7fr 1fr 1fr",
    columnGap: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1),
    },
  },
  inputRoot: {
    paddingTop: "8px !important",
    paddingBottom: "7px !important",
    paddingLeft: "12px !important",
    paddingRight: "12px !important",
  },
  summaryRow: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    "& > div:not(:last-child)": {
      borderRight: "1px solid #D0D5DD",
    },
  },
  attributeText: {
    color: "#b2b2b2",
    fontSize: 12,
    whiteSpace: "break-spaces",
  },
}));

export default function StocktakeProductSnListingDialogComponent({
  isOpen,
  handleClose,
  lang,
  productInfo,
  list,
  isFetching,
  isError,
  totalFiltered,
  paginationOptions,
  changeOrder,
  handleChangePage,
  handleChangeRowsPerPage,
  tableFilter,
  handleReload,
  boxUuid,
  handleSearch,
  isCompleted,
  changeTab,
  activeIndex,
  handleClearSearch,
}) {
  const classes = useStyle();

  const labels = [
    getLang(lang, "label.MATCHED"),
    getLang(lang, "label.EXTRA"),
    getLang(lang, "label.MISSING"),
  ];

  function a11yProps(index, label = "label") {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tab-${index}`,
      label: <Typography variant="button" children={label} />,
    };
  }

  return (
    <Dialog
      maxWidth="md"
      open={isOpen}
      onClose={() => {}}
      aria-labelledby="modal-scan-detail"
      aria-describedby="modal-scan-detail"
      BackdropComponent={Backdrop}
      fullWidth
      PaperProps={{
        style: {
          overflowY: "hidden",
        },
      }}
    >
      <Box
        className={classes.header}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="body1">
          <b>{getLang(lang, "label.STOCK_TAKE_RESULT")}</b>
        </Typography>
        <IconButton
          onClick={handleClose}
          edge="end"
          size="small"
          aria-label="close dialog"
        >
          <CloseRoundedIcon color="primary" />
        </IconButton>
      </Box>
      <Box className={classes.container}>
        <Box mb={2}>
          <Typography variant="body1">
            <b>{getLang(lang, "label.PRODUCT_DETAILS")}</b>
          </Typography>
        </Box>
        {!!productInfo && (
          <>
            <Box className={classes.productContainer}>
              <Box display="flex" alignItems="center" p={1}>
                <Box className={classes.productPhoto} mr={2}>
                  {!productInfo?.picture ? (
                    <img src={DefaultImg} alt="emptyProduct" />
                  ) : (
                    <img src={productInfo.picture} alt={productInfo.name} />
                  )}
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="flex-start"
                >
                  <Typography variant="body1">
                    <b>{productInfo.name}</b>
                  </Typography>
                  {!!productInfo.attributes &&
                    !!productInfo.attributes.length && (
                      <Typography
                        className={classes.attributeText}
                        variant="body2"
                      >
                        {productInfo.attributes.join(", ")}
                      </Typography>
                    )}
                </Box>
              </Box>
              {isCompleted && (
                <Box p={1} style={{ background: "#F9FAFB" }}>
                  <Typography variant="body1">
                    <b>{productInfo.total_quantity.toLocaleString("en-US")}</b>
                  </Typography>
                  <Typography variant="body2">
                    {getLang(lang, "label.INVENTORY_QTY")}
                  </Typography>
                </Box>
              )}
              <Box className={classes.summaryRow}>
                <Box p={1}>
                  <Box>
                    <Typography variant="body1">
                      <b>
                        {(isCompleted
                          ? productInfo.matched
                          : productInfo.total_quantity
                        ).toLocaleString("en-US")}
                      </b>
                    </Typography>
                    <Typography variant="body2">
                      {isCompleted
                        ? getLang(lang, "label.TOTAL_RESULT")
                        : getLang(lang, "label.INVENTORY_QTY")}
                    </Typography>
                  </Box>
                </Box>
                <Box p={1}>
                  <Box>
                    <Typography
                      variant="body1"
                      style={{
                        color: isCompleted ? "#F97066" : "#101828",
                      }}
                    >
                      <b>
                        {(isCompleted
                          ? productInfo.missing
                          : productInfo.matched
                        ).toLocaleString("en-US")}
                      </b>
                    </Typography>
                    <Typography variant="body2">
                      {isCompleted
                        ? getLang(lang, "label.MISSING")
                        : getLang(lang, "label.RESULT")}
                    </Typography>
                  </Box>
                </Box>
                <Box p={1}>
                  <Box>
                    <Typography variant="body1" style={{ color: "#FDB022" }}>
                      <b>{productInfo.extra.toLocaleString("en-US")}</b>
                    </Typography>
                    <Typography variant="body2">
                      {getLang(lang, "label.EXTRA")}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box p={2} style={{ background: "white" }} mt={2} pt={0}>
              <Box mb={3}>
                <Tabs
                  value={activeIndex}
                  onChange={changeTab}
                  aria-label="scrollable auto tabs example"
                  textColor="primary"
                  variant="scrollable"
                  indicatorColor="primary"
                  classes={{
                    indicator: classes.indicator,
                    scrollButtons: classes.scroll,
                  }}
                >
                  {labels.map((item, index) => {
                    return (
                      <CustomTab key={index} {...a11yProps(index, item)} />
                    );
                  })}
                </Tabs>
                <Divider />
              </Box>
              <Formik
                initialValues={{
                  search: tableFilter.search,
                }}
                enableReinitialize
                onSubmit={handleSearch}
                disabled={isFetching}
              >
                {(formik) => (
                  <Box mb={3}>
                    <form onSubmit={formik.handleSubmit}>
                      <Box className={classes.searchContainer}>
                        <Input
                          hiddenLabel
                          variant="filled"
                          size="small"
                          placeholder={getLang(
                            lang,
                            "placeholder.SEARCH_SERIAL_NUMBER_BOX_CODE"
                          )}
                          margin="none"
                          InputProps={{
                            disableUnderline: true,
                            margin: "none",
                            style: {
                              borderRadius: "4px",
                              backgroundColor: "#eceff0",
                            },
                            classes: { input: classes.inputRoot },
                          }}
                          {...formik.getFieldProps("search")}
                        />
                        <Button
                          variant="text"
                          disableElevation
                          color="secondary"
                          size="small"
                          onClick={() => handleClearSearch()}
                        >
                          {getLang(lang, "label.CLEAR")}
                        </Button>
                        <Button
                          variant="contained"
                          disableElevation
                          color="primary"
                          size="small"
                          type="submit"
                        >
                          {getLang(lang, "label.SEARCH")}
                        </Button>
                      </Box>
                    </form>
                  </Box>
                )}
              </Formik>
              <ProductSNListTable
                rows={list}
                isFetching={isFetching}
                isError={isError}
                handleReload={handleReload}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                totalFiltered={totalFiltered}
                paginationOptions={paginationOptions}
                tableFilter={tableFilter}
                changeOrder={changeOrder}
                lang={lang}
                boxUuid={boxUuid}
              />
            </Box>
          </>
        )}
      </Box>
    </Dialog>
  );
}
