import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { ACCOUNT } from "../../constants";
import accountApi from "app/api/account";

export const updateAccountProfilePicture = createAction(
  `${ACCOUNT}/updateProfilePicture`
);

export const updateAccountProfilePictureApi = createAsyncThunk(
  `${ACCOUNT}/updateProfilePictureApi`,
  async (payload, thunkApi) => {
    const {
      account: { id },
    } = thunkApi.getState();

    return accountApi.company
      .put({
        compID: id,
        image_logo_url: payload,
      })
      .then((response) => response)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const updateAccount = createAsyncThunk(
  `${ACCOUNT}/update`,
  async (payload, thunkApi) => {
    const response = accountApi.company
      .put({
        compID: thunkApi.getState().account.id,
        name: payload.name,
        description: payload.description,
        addressLine1: payload.addressLine1,
        addressLine2: payload.addressLine2,
        city: payload.city,
        country: payload.country,
        postalCode: payload.postCode,
        state: payload.state,
        registerationNo: payload.registerationNo,
        code: payload.contactCode,
        contactNumber: payload.contactNumber,
        companyEmail: payload.companyEmail,
        industry: payload.industry,
        timezone: payload.timezone,
      })
      .then((response) => response)
      .catch((error) => thunkApi.rejectWithValue(error));

    return response;
  }
);

export const getAvailableCurrencyConfigListing = createAsyncThunk(
  `${ACCOUNT}/getAvailableCurrencyConfigListing`,
  async (payload, { rejectWithValue }) => {
    return accountApi
      .getCurrencyConfigListing({
        length: payload.length,
        start: payload.start,
        column: payload.column,
        prder: payload.order,
      })
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const addCurrencyConfig = createAsyncThunk(
  `${ACCOUNT}/addCurrencyConfig`,
  async (payload, { rejectWithValue }) => {
    return accountApi
      .createCurrencyConfig({
        currencyCode: payload.currencyCode,
        isDefault: payload.isDefault,
      })
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const getUnusedCurrencyConfigData = createAsyncThunk(
  `${ACCOUNT}/getUnusedCurrencyConfigData`,
  async (payload, { rejectWithValue }) => {
    return accountApi
      .getUnusedCurrencyConfigDataDropdown({
        search: payload.search,
      })
      .then((response) => response)
      .catch((error) => rejectWithValue(error));
  }
);

export const updateCurrencyConfigStatus = createAsyncThunk(
  `${ACCOUNT}/updateCurrencyConfigStatus`,
  async (payload, { rejectWithValue }) => {
    return accountApi
      .updateCurrencyConfigStatus({
        currencyCode: payload.currencyCode,
        status: payload.status,
      })
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const updateDefaultCurrency = createAsyncThunk(
  `${ACCOUNT}/updateDefaultCurrency`,
  async (payload, { rejectWithValue }) => {
    return accountApi
      .updateDefaultCurrencyConfig({
        currencyCode: payload.currencyCode,
      })
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const deleteCurrencyConfig = createAsyncThunk(
  `${ACCOUNT}/deleteCurrencyConfig`,
  async (payload, { rejectWithValue }) => {
    return accountApi
      .removeCurrencyConfig({
        code: payload.code,
      })
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const bulkUpdateCurrencyStatus = createAsyncThunk(
  `${ACCOUNT}/bulkUpdateCurrencyStatus`,
  async (payload, { rejectWithValue }) => {
    return accountApi
      .bulkUpdateCurrencyConfigStatus({
        currencyCodes: payload.currencyCodes,
        status: payload.status,
      })
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const checkFeatureAvailability = createAsyncThunk(
  `${ACCOUNT}/checkFeatureAvailability`,
  async (payload, { rejectWithValue }) => {
    return accountApi
      .checkFeatureAvailability({
        access_right: payload,
      })
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const getCompanyDisbursement = createAsyncThunk(
  `${ACCOUNT}/getCompanyDisbursement`,
  async (_, { rejectWithValue }) => {
    return accountApi
      .getCompanyDisbursement()
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);
