import axios from "axios"

export default async function getSerialNumberActivityLog(enc) {
    return new Promise((resolve, reject) => {
        axios
        .get(`/serialnumber/api/v1/serialnumber/activity-log/${enc}`)
        .then((response) => resolve(response.data))
        .catch((error) => {
            return reject(error.response.data)
        })
    })
}
