import { withStyles } from "@material-ui/core";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";

export default withStyles({
  root: {
    backgroundColor: "#fff",
    padding: "0 16px 0 24px",
    marginBottom: -1,
    minHeight: 49,
    "&$expanded": {
      minHeight: 49
    }
  },
  content: {
    justifyContent: "space-between",
    minHeight: 49,
    display: "flex",
    alignItems: "center",
    margin: 0,
    "&$expanded": {
      margin: "0"
    }
  },
  expanded: {}
})(MuiAccordionSummary);
