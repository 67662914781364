import React, { useState, useEffect, useRef } from "react";
import BrandModelSelectComponent from './brandModelSelect.component';
import BrandModelPanelComponent from './brandModelSelectPanel.component';
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { getLang } from "app/feature/constants";

function BrandModelSelectContainer({
  placeholder = null,
  handleChange,
  value,
  disabled,
  styles,
  textStyles,
  dropdownItem,
  isLoading,
  // panelLabel,
  searchPlaceholder,
  handleSearch,
  searchable
}) {
  const [open, setOpen] = useState(false);
  const [isOpenList] = useState(false);
  const anchorRef = useRef(null);
  const prevOpen = useRef(open);
  const prevOpenList = useRef(isOpenList);
  // const [selectAll, setSelectAll] = useState(false);
  const lang = useSelector(state => state.constant.languages);

  const handleClose = () => {
    setOpen(false);
    handleSearch("")
  };

  const handleClick = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    if (prevOpenList.current === true && isOpenList === false) {
      anchorRef.current.focus();
    }

    prevOpenList.current = isOpenList;
  }, [isOpenList]);

  const handleSelectBrand = (field, checked) => {
    let temp = [...value];
    let index = temp.findIndex((v) => v.id === field.id)
    if (checked) {
      let modelTemp = []
      field.models.map((model) => {
        modelTemp.push({
          id: model.id,
          name: model.name
        })
        return null;
      });
      if(index !== -1){
        temp[index].models = modelTemp;
        temp[index].isSelected = (field.id !== "-1");
      } else {
        temp.push({
          id: field.id,
          name: field.name,
          models: modelTemp,
          isSelected: (field.id !== "-1")
        })
      }
    } else {
      temp.splice(index, 1)
    }
    handleChange(temp)
  }

  const handleSelectModel = (field, modelfield, checked) => {
    let temp = [...value];
    let modelTemp = [];
    let valBrandIndex = temp.findIndex((b) => b.id === field.id);
    if (temp.length > 0 && valBrandIndex !== -1) {
      modelTemp = temp[valBrandIndex].models;
      if(checked) {
        modelTemp.push({
          id: modelfield.id,
          name: modelfield.name,
        });
        temp[valBrandIndex].models = modelTemp;
        temp[valBrandIndex].isSelected = (field.id !== "-1" && modelTemp.length === field.models.length);
      }
      else {
        let index = modelTemp.findIndex((o) => o.id === modelfield.id);
        modelTemp.splice(index, 1);
        if (modelTemp.length === 0){
          temp.splice(valBrandIndex, 1);
        }
        else {
          temp[valBrandIndex].models = modelTemp;
          temp[valBrandIndex].isSelected = false;
        }
      }
    }
    else {
      if (checked) {
        modelTemp.push({
          id: modelfield.id,
          name: modelfield.name,
        });
        temp.push({
          id: field.id,
          name: field.name,
          models: modelTemp,
          isSelected: (field.id !== "-1" && modelTemp.length === field.models.length)
        });
      }
    }
    handleChange(temp)
  }

  return (
    <>
      <BrandModelSelectComponent
        anchorRef={anchorRef}
        handleClick={handleClick}
        value={value}
        styles={styles}
        placeholder={placeholder ?? getLang(lang,"placeholder.SELECT_BRAND_MODEL")}
        disabled={disabled}
        textStyle={textStyles}
      />
      <BrandModelPanelComponent
        // selectAll={selectAll}
        value={value}
        anchorRef={anchorRef}
        open = {open}
        isOpenList = {isOpenList}
        handleClose={handleClose}
        isFetching = {isLoading}
        dropdownItem={dropdownItem}
        // handleSelectAll={handleSelectAll}
        handleSelectBrand={handleSelectBrand}
        placeholder={searchPlaceholder}
        searchable={searchable}
        handleBrandModelSearch={handleSearch}
        handleSelectModel={handleSelectModel}
        lang={lang}
      />
    </>
  )
}

BrandModelSelectContainer.propTypes = {
  placeholder: PropTypes.string
};

export default BrandModelSelectContainer

