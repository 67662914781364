import axios from "axios";

export function updateTransferDeliveryInfo(params) {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `/distributor/admin/api/v1/transfer/${params.uuid}/update-delivery`,
        {
          tracking: params.tracking,
          courier_code: params.courier_code,
          postal_code: params.postal_code,
          country: params.country,
        }
      )
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data));
  });
}
