import React from "react";
import {
  Box,
  makeStyles,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@material-ui/core";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../../../../components/table";
import StickyLeftTableCell from "../../../../../components/table/stickyLeftTableCell";
import { getLang } from "../../../../../app/feature/constants";
import { SkeletonTableRow } from "components/skeleton/skeleton.component";
import StickyRightTableCell from "../../../../../components/table/stickyRightTableCell";
import ActionButton from "../../../../../components/button/actionDetailButton";
import Alert from "@material-ui/lab/Alert";
import RefreshTable from "../../../../../components/table/refreshTable";
import { TablePaginationActions } from "../../../../../components/tablePagination";
import moment from "moment";
import "moment/min/locales.min";

const useStyle = makeStyles((theme) => ({
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    borderCollapse: "separate",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
    },
    "& th,td": {
      fontSize: ".75rem",
      borderRight: "1px solid rgba(58, 77, 84, 0.1)",
      padding: theme.spacing(1, 2, 1, 2),
    },
    "& th": {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightBold,
    },
    "& th:last-child, td:last-child": {
      width: 37,
      borderRight: "0px",
    },
    "& th th": {
      borderBottom: "none !important",
    },
    "& td td": {
      borderBottom: "none !important",
    },
  },
  emptyContainer: {
    textAlign: "center",
  },
  attributeText: {
    color: "#b2b2b2",
    fontSize: 12,
    whiteSpace: "break-spaces",
  },
}));

function statusLabel(statusList, status) {
  return (
    <Typography
      variant="caption"
      style={{ color: statusList[status - 1].color, fontWeight: "bold" }}
      display="inline"
    >
      {statusList[status - 1].name}
    </Typography>
  );
}

export default function ClaimListTableComponent({
  rows,
  isFetching,
  hasClaimEditAccessRight,
  handlePageChange,
  handleRowPerPageChange,
  search,
  statusList,
  language,
  handleReload,
  handleView,
  rowsPerPage,
  page,
  isLoadingError,
  paginationOptions,
  length,
  lang,
  hasReimbursementViewAccessRight,
}) {
  const classes = useStyle();

  if (language === "ZH") {
    moment.locale("zh-cn");
  } else if (language === "TL") {
    moment.locale("tl-ph");
  } else {
    moment.locale(language);
  }

  return (
    <>
      {rows.length > 0 || isFetching ? (
        <TableContainer>
          <Table
            className={classes.table}
            size="small"
            aria-label="product table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <StickyLeftTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.NUM")}
                  </StyledTableCell>
                </StickyLeftTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.SERIAL_NUMBER")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.WARRANTEE_EMAIL")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.WARRANTEE_NAME")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.PRODUCT")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.WARRANTY_NAME")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.STATUS")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.RESPONSE_DATE")}
                </StyledTableCell>
                {hasReimbursementViewAccessRight && (
                  <StyledTableCell>
                    {getLang(lang, "label.REIMBURSEMENT_STATUS")}
                  </StyledTableCell>
                )}
                <StickyRightTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.ACTION")}
                  </StyledTableCell>
                </StickyRightTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isFetching
                ? [...new Array(5)].map((v, index) => (
                    <SkeletonTableRow
                      key={index}
                      columnCount={hasReimbursementViewAccessRight ? 10 : 9}
                    />
                  ))
                : rows.map((row, index) => {
                    const actionItems = [
                      {
                        label: hasClaimEditAccessRight
                          ? getLang(lang, "label.VIEW_EDIT")
                          : getLang(lang, "label.VIEW"),
                        action: () => handleView(row),
                      },
                    ];

                    return (
                      <StyledTableRow
                        key={index}
                        hover
                        role="checkbox"
                        tabIndex={-1}
                      >
                        <StickyLeftTableCell>
                          <StyledTableCell
                            component="th"
                            scope="row"
                            style={{ borderBottom: "0px" }}
                          >
                            {index + 1 + rowsPerPage * page}
                          </StyledTableCell>
                        </StickyLeftTableCell>
                        <StyledTableCell>{row.serialNumber}</StyledTableCell>
                        <StyledTableCell>
                          {row.warranteeEmail ?? "-"}
                        </StyledTableCell>
                        <StyledTableCell>
                          {row.warranteeName ?? "-"}
                        </StyledTableCell>
                        <StyledTableCell>
                          <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="flex-start"
                          >
                            <Typography
                              variant="body2"
                              style={{ fontSize: 12 }}
                            >
                              {row.productName}
                              <span style={{ color: "grey" }}>
                                {!!row.productNameOld &&
                                  ` (${row.productNameOld})`}
                              </span>
                            </Typography>
                            {!!row.attributes && !!row.attributes.length && (
                              <Typography
                                className={classes.attributeText}
                                variant="body2"
                              >
                                {row.attributes.join(", ")}
                              </Typography>
                            )}
                          </Box>
                        </StyledTableCell>
                        <StyledTableCell>
                          {row.warrantySettingName}
                        </StyledTableCell>
                        <StyledTableCell>
                          {statusLabel(statusList, row.status)}{" "}
                          {!!row.status === 2 &&
                            row.replacementUuid &&
                            !row.isReplacementCompleted && (
                              <Typography
                                variant="caption"
                                style={{
                                  color: "#92C892",
                                  fontWeight: "bold",
                                }}
                                display="inline"
                              >
                                -&nbsp;
                                {getLang(
                                  lang,
                                  "label.PENDING_ITEM_REPLACEMENT"
                                )}
                              </Typography>
                            )}
                        </StyledTableCell>
                        <StyledTableCell>
                          {row.status === 1
                            ? moment(row.createdAt).format("lll")
                            : row.status === 2
                            ? moment(row.approvedAt).format("lll")
                            : row.status === 3
                            ? moment(row.updatedAt).format("lll")
                            : null}
                        </StyledTableCell>
                        {hasReimbursementViewAccessRight && (
                          <StyledTableCell style={{ textAlign: "center" }}>
                            {statusLabel(statusList, row.reimbursementStatus)}
                          </StyledTableCell>
                        )}
                        <StickyRightTableCell>
                          <StyledTableCell>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <ActionButton
                                actionItems={actionItems}
                                popperStyle={{ zIndex: 999 }}
                              />
                            </div>
                          </StyledTableCell>
                        </StickyRightTableCell>
                      </StyledTableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : !search && !isLoadingError ? (
        <Box
          style={{ overflowX: "auto", backgroundColor: "#fff", width: "100%" }}
        >
          <Table
            className={classes.table}
            size="small"
            aria-label="product table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <StickyLeftTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.NUM")}
                  </StyledTableCell>
                </StickyLeftTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.REFERENCE_CODE")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.WARRANTEE_NAME")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.PRODUCT")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.SERIAL_NUMBER")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.WARRANTY_NAME")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.STATUS")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.RESPONSE_DATE")}
                </StyledTableCell>
                {hasReimbursementViewAccessRight && (
                  <StyledTableCell>
                    {getLang(lang, "label.REIMBURSEMENT_STATUS")}
                  </StyledTableCell>
                )}
                <StickyRightTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.ACTION")}
                  </StyledTableCell>
                </StickyRightTableCell>
              </TableRow>
            </TableHead>
          </Table>
          <Box py={11} className={classes.emptyContainer}>
            <Typography variant="body1">
              {getLang(lang, "paragraph.NO_CONSUMER")}
            </Typography>
          </Box>
        </Box>
      ) : null}

      {!isFetching && rows.length === 0 && search && (
        <Alert severity="info">
          {getLang(lang, "message.info.NO_WARRANTY_CLAIM")}
        </Alert>
      )}

      {!isFetching && isLoadingError && (
        <RefreshTable handleReload={handleReload} />
      )}

      {!isFetching && rows.length > 0 && (
        <TablePagination
          style={{
            backgroundColor: "#fff",
            boxShadow: "0px -1px 0px rgba(58, 77, 84, 0.15)",
          }}
          ActionsComponent={TablePaginationActions}
          rowsPerPageOptions={paginationOptions}
          component="div"
          count={length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowPerPageChange}
          labelRowsPerPage={getLang(lang, "label.ROWS_PER_PAGE")}
          labelDisplayedRows={({ from, to, count }) =>
            `${from.toLocaleString("en-US")}-${to.toLocaleString(
              "en-US"
            )} ${getLang(lang, "label.OF")} ${count.toLocaleString("en-US")}`
          }
        />
      )}
    </>
  );
}
