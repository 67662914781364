import React from "react";
import CountryCodeSelectComponent from "./countryCodeSelect.component";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { getLang } from "app/feature/constants";

function CountryCodeSelectContainer({ textFieldProps, formProps, type, textFieldStyle }) {
  const lang = useSelector(state => state.constant.languages);

  return (
    <CountryCodeSelectComponent
      textFieldProps={textFieldProps}
      formProps={formProps}
      type={type}
      textFieldStyle={textFieldStyle}
      placeholder={getLang(lang, "placeholder.COUNTRY_CODE")}
      lang={lang}
    />
  );
}

CountryCodeSelectContainer.propTypes = {
  textFieldProps: PropTypes.object,
  formProps: PropTypes.object,
  type: PropTypes.string,
  textFieldStyle: PropTypes.object,
  placeholder: PropTypes.string
};

export default CountryCodeSelectContainer;
