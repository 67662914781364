import { Box, Typography, makeStyles } from "@material-ui/core";
import React from "react";
import { getLang } from "app/feature/constants";
import parse from "html-react-parser";

const useStyle = makeStyles((theme) => ({
  container: {
    display: "flex",
    minHeight: "100vh",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
    },
  },
  img: {
    height: "80%",
    width: "80%",
    objectFit: "contain",
    maxWidth: "fit-content",
    maxHeight: "550px",
    justifySelf: "center",
    alignSelf: "center",
    padding: 24,
    [theme.breakpoints.down("sm")]: {
      maxHeight: "50vh",
      maxWidth: "550px",
    },
  },
}));

function MaintenancePage({ lang, refresh }) {
  const classes = useStyle();

  const options = {
    replace({ attribs, children }) {
      if (!attribs) {
        return;
      }

      if (attribs.id === "link") {
        return (
          <span
            style={{ color: "#6AAF68", cursor: "pointer" }}
            onClick={refresh}
          >
            <b>{getLang(lang, "label.HERE")}</b>
          </span>
        );
      }
    },
  };

  return (
    <Box className={classes.container}>
      <Box justifySelf="center" p={3} width="560px" maxWidth="90vw">
        <Box mb={2}>
          <Typography variant="h3">
            {getLang(lang, "label.UNDER_MAINTENANCE")}
          </Typography>
        </Box>
        <Box mb={2}>
          <Typography variant="h5" color="textSecondary">
            {getLang(lang, "paragraph.MAINTENANCE_INSTRUCTION")}
          </Typography>
        </Box>
        <Typography variant="body1" color="textSecondary">
          {parse(
            getLang(lang, "paragraph.MAINTENANCE_SUB_INSTRUCTION", {
              link: `<span id="link"></span>`,
            }),
            options
          )}
        </Typography>
      </Box>
      <img
        src={`${process.env.REACT_APP_S3_BUCKET_URL}/constants/maintenance_dashboard.png`}
        alt="maintenance"
        className={classes.img}
      />
    </Box>
  );
}

export default MaintenancePage;
