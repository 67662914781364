import React from "react";
import { makeStyles, Box } from "@material-ui/core";
import FieldForm from "components/regField/fieldForm";
import { getLang } from "app/feature/constants";

const style = (theme) => ({
  root: {
    maxWidth: "100%",
    width: "1100px",
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    }
  }
});

const useStyles = makeStyles(style);

export default function InstantLuckyDrawRegFormComponent({
  children,
  instantLuckyDrawRegisterForm,
  type,
  handleDragEnd,
  formRef,
  lang
}) {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <FieldForm
        children={children}
        type={type}
        form={instantLuckyDrawRegisterForm}
        formRef={formRef}
        AddFieldChoice={[
          {
            labelType: getLang(lang, "label.TEXT_FIELD"),
            value: "textField",
            enableDelete: true,
            type: "textField",
            fieldType: "custom",
            visible: false,
            mandatory: false,
          },
          {
            labelType: getLang(lang, "label.MULTIPLE_CHOICE"),
            value: "multipleChoice",
            enableDelete: true,
            type: "multipleChoice",
            fieldType: "custom",
            visible: false,
            mandatory: false,
          },
          {
            labelType: getLang(lang, "label.MULTIPLE_CHOICE_MULTI_SELECT"),
            value: "multipleChoiceMultiSelect",
            enableDelete: true,
            type: "multipleChoiceMultiSelect",
            fieldType: "custom",
            visible: false,
            mandatory: false,
          },
          {
            labelType: getLang(lang, "label.FILE_UPLOAD"),
            value: "uploadFile",
            enableDelete: true,
            type: "uploadFile",
            fieldType: "custom",
            visible: false,
            mandatory: false,
          },
          {
            labelType: getLang(lang, "label.IMAGE_UPLOAD"),
            value: "uploadImage",
            enableDelete: true,
            type: "uploadImage",
            fieldType: "custom",
            visible: false,
            mandatory: false,
          },
          {
            labelType: getLang(lang, "label.CONTACT_NUMBER"),
            value: "contactNumber",
            type: "contactNumber",
            enableDelete: true,
            fieldType: "custom",
            visible: false,
            mandatory: false,
          },
          {
            labelType: getLang(lang, "label.DROPDOWN_LIST"),
            value: "dropdownlist",
            enableDelete: true,
            type: "dropdownlist",
            fieldType: "custom",
            mandatory: false,
            visible: false,
          },
        ]}
        Reorder={handleDragEnd}
      />
    </Box>
  );
}
