import axios from "axios"

export function getRedemptionDetails({ id }) {
  
  const url = `/distributor/admin/api/v1/rewards/redemptions/${id}`;
  
  return new Promise((resolve, reject) => {
    axios.get(url)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data))
  })
}