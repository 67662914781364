import { getTimezone, fetchTimezone } from "../action";
import { setLoading } from "modules/notification";
import { setTimezone } from "../slice/constant.slice";

const getTimezoneMiddleware = ({
    dispatch
}) => next => async action => {
    next(action)

    const { type, payload } = action;

    if (getTimezone.type === type) {
        dispatch(fetchTimezone());
    }

    switch (type) {
        case fetchTimezone.pending.type:
            next(setLoading({ id: fetchTimezone.typePrefix, state: true }));
            break;

        case fetchTimezone.fulfilled.type:
            next(setTimezone(payload.data));
            next(setLoading({ id: fetchTimezone.typePrefix, state: false }));
            break;

        case fetchTimezone.rejected.type:
            next(setLoading({ id: fetchTimezone.typePrefix, state: false }));
            break;

        default:
            break;
    }
}

export default [
    getTimezoneMiddleware
];