import axios from "axios";

const get = (parameters) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`consumer/admin/api/v1/warranty-activities`, {
        params: {
          draw: parameters.draw,
          length: parameters.length,
          start: parameters.start,
          column: parameters.column,
          order: parameters.order,
          enc: parameters.enc,
          search: parameters.search
        }
      })
      .then((response) => resolve(response))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

const post = ({ enc, action, remarks, extendMonth }) => {
  const body = {
    enc: enc,
    action: action,
    remarks: remarks,
    extend: extendMonth
  };

  return new Promise((resolve, reject) => {
    axios
      .post(`consumer/admin/api/v1/warranty-activity/update-action`, body)
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

const warrantyLog = {
  post,
  get
};

export default warrantyLog;
