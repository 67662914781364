import axios from "axios";


export function BulkUpdateIncentiveProgramStatus({
  programUUIDs,
  isSelectAll,
  status,
  filter
}) {

  const url = `/distributor/admin/api/v1/programs/bulk-update-status`;

  return new Promise((resolve, reject) => {
    axios.post(url, {
      program_uuids: programUUIDs,
      is_select_all: isSelectAll,
      status,
      filter
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data))
  })
}