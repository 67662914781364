import React, { useRef } from "react";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  InputAdornment,
  makeStyles,
  Typography,
} from "@material-ui/core";
//import ProductSelect from "modules/serial-number/components/select/productSelect";
import { getLang } from "app/feature/constants";
import InfiniteScroll from "react-infinite-scroll-component";
import LoadingComponent from "components/loading";
import InputTextField from "components/input/inputTextField";
import clsx from "clsx";
import { AddIcon } from "components/icon";
import { Search as SearchIcon } from "@material-ui/icons";
import { ActionButton } from "components/button";

const useStyle = makeStyles((theme) => ({
  paper: {
    backgroundColor: "white",
    borderRadius: 4,
    padding: 16,
  },
  list: {
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "5px",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "16px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#E4E7EC",
      borderRadius: "16px",
    },
  },
  captionText: {
    fontSize: 12,
    color: "#b2b2b2",
  },
  button: {
    padding: 0,
    minWidth: 0,
  },
  listContainer: {
    border: "1px solid #e6e6e6",
  },
  selectAll: {
    "&.Mui-checked": {
      color: "#FDB022",
    },
  },
  selectOne: {
    "&.Mui-checked": {
      color: "#6AAF68",
    },
  },
}));

export default function AdtInfoTitleListComponent({
  lang,
  handleFetchMore,
  handleReload,
  adtInfos,
  totalFiltered,
  isError,
  hasNextPage,
  isLoading,
  language,
  handleSearch,
  selectedIds,
  handleSelectAdtInfo,
  handleEdit,
  handleAddTitle,
  showSnResult,
  displayLang,
  handleDelete,
}) {
  const classes = useStyle();
  const textRef = useRef();

  return (
    <Box className={classes.paper}>
      <Box>
        <Box display="flex" alignItems="center">
          <InputTextField
            variant="filled"
            size="small"
            fullWidth
            inputRef={textRef}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    onClick={() => handleSearch(textRef.current.value)}
                    className={classes.button}
                  >
                    <SearchIcon fontSize="small" />
                  </Button>
                </InputAdornment>
              ),
              style: {
                backgroundColor: "#efefef",
              },
            }}
            inputProps={{
              onKeyDown: (e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  handleSearch(textRef.current.value);
                  return false;
                }
              },
              style: {
                padding: "10px",
              },
            }}
            placeholder={getLang(lang, "placeholder.SEARCH_TITLE")}
          />
        </Box>
      </Box>

      <Box className={classes.listContainer} py={1} mt={1}>
        {isError ? (
          <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
            pt={2}
            pb={3}
          >
            <Typography
              variant="body2"
              color="primary"
              style={{ fontWeight: "bold", paddingBottom: "8px" }}
            >
              {getLang(lang, "label.NO_DATA_RECEIVED")}
            </Typography>
            <Typography
              variant="body2"
              style={{ color: "#9CA6A9", paddingBottom: "16px" }}
            >
              {getLang(lang, "label.SOMETHING_WENT_WRONG")}
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleReload}
              disableElevation
            >
              {getLang(lang, "label.REFRESH")}
            </Button>
          </Box>
        ) : (
          <>
            <InfiniteScroll
              height="calc(100dvh - 465px)"
              className={classes.list}
              dataLength={adtInfos.length}
              next={() => {
                if (hasNextPage) handleFetchMore();
              }}
              hasMore={totalFiltered > adtInfos.length}
              loader={<LoadingComponent height={100} />}
              endMessage={
                isLoading ? (
                  <LoadingComponent height={100} />
                ) : !adtInfos.length ? (
                  <Box px={1.5} py={4}>
                    <Typography variant="body2" style={{ textAlign: "center" }}>
                      <b>{getLang(lang, "message.info.NO_ADDITIONAL_INFO")}</b>
                    </Typography>
                  </Box>
                ) : null
              }
            >
              <Box px={1}>
                {[...adtInfos].map((item, i) => {
                  const actionItems = [
                    {
                      label: getLang(lang, "label.EDIT"),
                      action: () => handleEdit(item),
                    },
                    {
                      label: getLang(lang, "label.DELETE"),
                      action: () => handleDelete(item),
                    },
                  ];

                  return (
                    <Box key={i}>
                      {i !== 0 && (
                        <Box my={0.5}>
                          <Divider />
                        </Box>
                      )}
                      <Box display="flex" alignItems="center" pr={1}>
                        <Checkbox
                          className={clsx(classes.root, classes.selectOne)}
                          checked={selectedIds.includes(item.id)}
                          onChange={(e) => {
                            handleSelectAdtInfo(item, e.target.checked);
                          }}
                        />
                        <Box flex={1} px={1}>
                          <Box>
                            <Typography variant="body2">
                              {item.title[displayLang] ||
                                item.title["EN"] ||
                                Object.values(item.title)[0]}{" "}
                            </Typography>
                          </Box>
                        </Box>
                        <Box pl={1} display="flex" alignItems="center">
                          <Box pr={1.5}>
                            <Typography
                              variant="body2"
                              className={classes.captionText}
                            >
                              {item.format === 2
                                ? getLang(lang, "label.DATE")
                                : item.format === 3
                                ? getLang(lang, "label.NUMBER")
                                : getLang(lang, "label.TEXT")}
                            </Typography>
                          </Box>
                          <ActionButton
                            actionItems={actionItems}
                            popperStyle={{ zIndex: 999 }}
                            variant="vertical"
                          />
                          {/* <EditIcon
                          style={{ cursor: "pointer", fontSize: 20 }}
                          onClick={() => {
                            // setSelectedEditAddr(addr);
                            handleEdit(item);
                          }}
                        /> */}
                        </Box>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </InfiniteScroll>
          </>
        )}
      </Box>
      {!isError && (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mt={2}
        >
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            {!isError && !(isLoading && !adtInfos.length) && (
              <Button
                variant="text"
                color="primary"
                startIcon={
                  <AddIcon color="secondary" style={{ fontSize: "13px" }} />
                }
                onClick={handleAddTitle}
              >
                {getLang(lang, "label.TITLE")}
              </Button>
            )}
          </Box>
          <Box display="flex">
            <Button
              disableElevation
              color="secondary"
              variant="contained"
              disabled={!selectedIds.length}
              onClick={() => {
                showSnResult();
              }}
            >
              {getLang(lang, "label.SHOW")}
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
}
