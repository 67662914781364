import { createSlice } from '@reduxjs/toolkit';
import {
  STOCKFLOWINCENTIVEPROGRAM,
  getIncentiveProgramListing,
  getStockModuleSetting,
  getProgramDetails,
  getNewProgramProductDropdown,
  getEditProgramProductDropdown,
  updateProgramStatus
} from "../action/stockflowIncentiveProgram.action"

const initialState = {
  incentiveProgramList: {
    totalRecords: 0,
    totalFiltered: 0,
    totalLoaded: 0,
    data: [],
    isSelectAll: false,
    selectedUUIDs: [],
    isError: false
  },
  productDropdown: [],
  maxTier: 0,
  details: {
    uuid: "",
    name: "",
    percentages: [],
    products: []
  }
};

const stockflowIncentiveProgramSlice = createSlice({
  name: STOCKFLOWINCENTIVEPROGRAM,
  initialState,
  reducers: {
    resetIncentiveProgramList(state) {
      state.incentiveProgramList = initialState.incentiveProgramList
    },
    resetIncentiveProgramListData(state) {
      state.incentiveProgramList.data = initialState.incentiveProgramList.data
    },
    resetProductDropdown(state) {
      state.productDropdown = initialState.productDropdown
    },
    changeSelectedProgram(state, action) {
      const { payload } = action;
      state.incentiveProgramList.data[payload.index].isSelected = payload.isSelected;
      if((!state.incentiveProgramList.isSelectAll && payload.isSelected) || (state.incentiveProgramList.isSelectAll && !payload.isSelected)) {
        state.incentiveProgramList.selectedUUIDs = [...state.incentiveProgramList.selectedUUIDs, state.incentiveProgramList.data[payload.index].uuid];
      }
      else {
        state.incentiveProgramList.selectedUUIDs = state.incentiveProgramList.selectedUUIDs.filter((uuid) => uuid !== state.incentiveProgramList.data[payload.index].uuid);
      }
    },
    changeBulkSelectedAllProgram(state, action) {
      const { payload } = action;

      state.incentiveProgramList.data.forEach((program) => {
        program.isSelected = payload.value
      });
      state.incentiveProgramList.isSelectAll = payload.value;
      state.incentiveProgramList.selectedUUIDs = [];
    },
    changeBulkSelectedAvailableProgram(state, action) {
      const { payload } = action;

      state.incentiveProgramList.data.forEach((program) => {
        program.isSelected = payload.value
        if (((!state.incentiveProgramList.isSelectAll && payload.value) || (state.incentiveProgramList.isSelectAll && !payload.value))) {
          if (state.incentiveProgramList.selectedUUIDs.findIndex((uuid) => uuid === program.uuid) === -1) {
            state.incentiveProgramList.selectedUUIDs = [...state.incentiveProgramList.selectedUUIDs, program.uuid];
          }
        }
        else {
          state.incentiveProgramList.selectedUUIDs = state.incentiveProgramList.selectedUUIDs.filter((uuid) => uuid !== program.uuid);
        }
      })
    }
  },
  extraReducers: {
    [getIncentiveProgramListing.pending]: (state) => {
      state.incentiveProgramList = initialState.incentiveProgramList
    },
    [getIncentiveProgramListing.fulfilled]: (state, action) => {
      const { payload } = action;
      const temp = []
      payload.data.forEach((item) => {
        let selectedState = !state.incentiveProgramList.isSelectAll ? 
          state.incentiveProgramList.selectedUUIDs.findIndex((uuid) => uuid === item.uuid) !== -1 : 
          state.incentiveProgramList.selectedUUIDs.findIndex((uuid) => uuid === item.uuid) === -1;
        temp.push({
          uuid: item.uuid,
          name: item.name,
          productCount: item.product_count,
          createAt: item.created_at,
          percentages: item.percentages,
          status: item.status,
          isSelected: selectedState
        })
      });
      state.incentiveProgramList.data = temp;
      state.incentiveProgramList.totalRecords = payload.recordsTotal;
      state.incentiveProgramList.totalFiltered = payload.recordsFiltered;
      state.incentiveProgramList.totalLoadead = payload.recordsLoaded;
      state.incentiveProgramList.isError = false
    },
    [getIncentiveProgramListing.rejected]: (state) => {
      state.incentiveProgramList.data = [];
      state.incentiveProgramList.totalRecords = 0;
      state.incentiveProgramList.totalFiltered = 0;
      state.incentiveProgramList.totalLoadead = 0;
      state.incentiveProgramList.isError = true;
    },
    [getNewProgramProductDropdown.pending]: (state) => {
      state.productDropdown = initialState.productDropdown;
    },
    [getNewProgramProductDropdown.fulfilled]: (state, action) => {
      const { payload } = action;
      const temp = []
      payload.data.forEach((item) => (
        temp.push({
          uuid: item.uuid,
          name: item.name,
          point: item.point,
          picture: item.picture,
          attributes: item.attributes,
          searchName: item.name.toLowerCase()
        })
      ));

      state.productDropdown = [...temp]
    },
    [getEditProgramProductDropdown.pending]: (state) => {
      state.productDropdown = initialState.productDropdown;
    },
    [getEditProgramProductDropdown.fulfilled]: (state, action) => {
      const { payload } = action;
      const temp = []
      payload.data.forEach((item) => (
        temp.push({
          uuid: item.uuid,
          name: item.name,
          point: item.point,
          picture: item.picture,
          attributes: item.attributes ?? [],
          searchName: item.name.toLowerCase()
        })
      ));

      state.productDropdown = [...temp]
    },
    [getStockModuleSetting.fulfilled]: (state, action) => {
      const { payload } = action;

      state.maxTier = payload.max_tier;
    },
    [getProgramDetails.fulfilled]: (state, action) => {
      const { payload } = action;

      state.details.uuid = payload.uuid;
      state.details.name = payload.name;
      state.details.percentages = [...payload.percentages];
      state.details.products = [...payload.products]
      
    },
    [updateProgramStatus.fulfilled]: (state, action) => {
      const payload = action;
      const index = state.incentiveProgramList.data.findIndex(
        ({ uuid }) => uuid === payload.uuid
      );
      if (index > -1) {
        state.incentiveProgramList.data[index].status = payload.status.toLowerCase();
      }
    },
  }
});

export const {
  resetIncentiveProgramList,
  resetIncentiveProgramListData,
  resetProductDropdown,
  changeSelectedProgram,
  changeBulkSelectedAllProgram,
  changeBulkSelectedAvailableProgram
} = stockflowIncentiveProgramSlice.actions;

export const stockflowIncentiveProgramReducer = stockflowIncentiveProgramSlice.reducer;