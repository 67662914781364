import axios from "axios";

const endpoint_url = "/serialnumber/api/v1/warranty-setting";

const post = ({
  warrantyName,
  warrantyApproval,
  requiredValidation,
  productId,
  durationMonth,
  tncUrl,
  metadata,
  claimAdd,
  allowMultipleClaimReason,
  warrantyPreregisterEnabled,
  itemReplacementProcessEnabled,
  warrantyRegisterNotificationType,
  claimApplicationNotificationType,
  customRecipient,
}) => {
  const body = {
    warranty_name: warrantyName,
    isApproval: warrantyApproval,
    product_id: productId,
    duration_month: durationMonth,
    required_validation: requiredValidation,
    tnc_url: tncUrl,
    metadata: metadata,
    claim_reason_add: claimAdd,
    allow_multiple_claim_reason: allowMultipleClaimReason,
    is_preregister_enabled: warrantyPreregisterEnabled,
    item_replacement_enabled: itemReplacementProcessEnabled,
    warranty_register_notification_type: warrantyRegisterNotificationType,
    claim_application_notification_type: claimApplicationNotificationType,
    custom_recipient: {
      warranty_registration: customRecipient.warrantyRegister.length === 0 ? [] : customRecipient.warrantyRegister.split(";"),
      claim_application: customRecipient.claimApplication.length === 0  ? [] : customRecipient.claimApplication.split(";")
    },
  };

  return new Promise((resolve, reject) => {
    axios
      .post(endpoint_url, body)
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

const createWarrantySetting = {
  post
};

export default createWarrantySetting;
