import React from "react";
import {
  makeStyles,
  Box,
  Typography,
  Divider,
  CircularProgress,
  Tabs,
  Grid,
  Paper
} from "@material-ui/core";
import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";
import { BackButton } from "components/button";
import clsx from 'clsx';
import CustomTab from "components/tab";
import RewardDetailsForm from "modules/stockflow/components/form/rewardDetailsForm.component";
import RedemptionPanel from 'modules/stockflow/components/panel/redemptionPanel';
import CustomizedBadges from "components/customizedBadges";
import { getLang } from "app/feature/constants";
import moment from "moment";
import "moment/min/locales.min";

const useStyle = makeStyles(theme => ({
  root: {
    gridTemplateAreas: "'top .' 'main side'",
    gridTemplateColumns: "1fr",
    [theme.breakpoints.up("lg")]: {
      display: "grid"
    },
    columnGap: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      gridTemplateAreas: "'top' 'main' 'side'",
      gridTemplateColumns: "1fr",
      gridTemplateRows: "1fr auto",
      rowGap: theme.spacing(2)
    }
  },
  fullWidthGrid: {
    gridTemplateAreas: "'top' 'main'",
    gridTemplateColumns: "1fr"
  },
  paper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(5, 0),
    width: "90%",
    maxWidth: 1000,
    display: "inherit",
    justifyContent: "inherit"
  },
  content: {
    width: "95%",
    maxWidth: 850
  },

  sideBanner: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridRowGap: theme.spacing(2),
    rowGap: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(3),
      gridTemplateColumns: "1fr 1fr",
      columnGap: theme.spacing(2)
    },
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr"
    }
  },

  publishButton: {
    color: theme.palette.common.white
  },
  backButton: {
    marginRight: theme.spacing(1)
  },
  discardButton: {
    color: theme.palette.error.main
  },
  iconContainer: {
    minHeight: 38
  },
  stepperRoot: {
    paddingBottom: 0
  },
  stepLabelRoot: {
    height: 100,
    position: "relative"
  },
  stepLabelActive: {
    "&::after": {
      content: "''",
      backgroundColor: theme.palette.primary.main,
      width: "20%",
      height: 3,
      position: "absolute",
      bottom: 0,
      borderRadius: theme.spacing(1, 1, 0, 0)
    }
  },
  indicator: {
    border: "0.5px solid",
    borderColor: theme.palette.primary.main,
    borderRadius: "4px 4px 0px 0px"
  },
  scroll: {
    width: "min-content"
  },
  topContainerPaper: {
    backgroundColor: theme.palette.background.paper
  },
  topContainer: {
    padding: "1em"
  },
  topContainerRow: {
    display: "flex",
    [theme.breakpoints.up("xs")]: {
      flexDirection: "row"
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column"
    }
  },
  topContainerColumn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly"
  },
  topContainerColumnGrid: {
    display: "grid",
    gridTemplateColumns: "3fr 9fr",
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr"
    },
  },
  gridFieldTopContainer: {
    marginTop: "0.25em"
  },
  photoContainer: {
    width: "150px",
    height: "150px",
    "& img": {
      height: "inherit"
    },
    marginRight: "1em",
    overflow: "hidden"
  },
  tabStyle: {
    display: "flex",
    flexDirection: "row", 
    alignItems: "center"
  }
}));

export default function RewardDetailsComponent({
  isFetching,
  activeIndex,
  language,
  isError,
  isEditing,
  handleEdit,
  handleChange,
  rewardValues,
  id,
  reloadContent,
  isEdit,
  lang
}) {
  const classes = useStyle();
  
  if(language==="ZH")
  {
    moment.locale('zh-cn')
  }else if(language==="TL")
  {
    moment.locale('tl-ph')
  }else
  {
    moment.locale(language)
  }


  function a11yProps(index, label = "label", badgeContent = 0) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
      label: (
        <Box className={classes.tabStyle}>
          <Typography variant="button" children={label} />
          <CustomizedBadges
            badgeContent={badgeContent} max={99}
          />
        </Box>
      ),
    };
  }

  function TabPanel({ children, value, index, ...other }) {
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {activeIndex === index && <Box>{children}</Box>}
      </Typography>
    );
  }

  return (
    <>
      <Box className={clsx(classes.root)}>
        <Box gridArea="top">
          <Box
            mb={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" alignItems="flex-end" fontWeight="bold">
              <BackButton />
              <Typography variant="h6" color="primary">
                {getLang(lang, 'label.REWARD_CENTER')}
              </Typography>
              <Box mx={0.5}>
                <ChevronRightRoundedIcon color="disabled" />
              </Box>
              <Typography variant="h6" color="primary">
                {getLang(lang, 'label.REWARD')}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box gridArea="main">
          {!isFetching && !isError && (
            <>
              <Box>
                <Tabs
                  value={activeIndex}
                  onChange={handleChange}
                  aria-label="scrollable auto tabs example"
                  textColor="primary"
                  variant="scrollable"
                  indicatorColor="primary"
                  classes={{
                    indicator: classes.indicator,
                    scrollButtons: classes.scroll
                  }}
                >
                  <CustomTab key={0} {...a11yProps(0, getLang(lang, 'label.REWARD'))} />
                  <CustomTab key={1} {...a11yProps(1, getLang(lang, 'label.REDEMPTION'), rewardValues.pendingRedemptionCount)} disabled={isEdit === "edit"}/>
                </Tabs>
              </Box>
              <Divider />
              <Box>
                {!isEditing && (
                  <Box style={{ marginBottom: "1em" }}>
                    <Paper elevation={1} className={classes.topContainerPaper}>
                      <Box className={classes.topContainer}>
                        <Box className={classes.topContainerRow}>
                          <Box className={classes.photoContainer}>
                            <img src={rewardValues.coverImageUrl} alt={rewardValues.name} />
                          </Box>
                          <Box className={classes.topContainerColumn}>
                            <Typography variant="h4">{rewardValues.name}</Typography>
                            <Grid container>
                              <Grid item xs={12} sm={3} md={3} className={classes.gridFieldTopContainer}>
                                <Typography variant="body2">{getLang(lang, 'label.TOTAL_REDEMPTION')}</Typography>
                              </Grid>
                              <Grid item xs={12} sm={9} md={9} className={classes.gridFieldTopContainer}>
                                <Typography variant="body2" color="primary" style={{ fontWeight: "bold" }}>{rewardValues.totalRedemptionCount}</Typography>
                              </Grid>
                              <Grid item xs={12} sm={3} md={3} className={classes.gridFieldTopContainer}>
                                <Typography variant="body2">{getLang(lang, 'label.TOTAL_QUANTITY')}</Typography>
                              </Grid>
                              <Grid item xs={12} sm={9} md={9} className={classes.gridFieldTopContainer}>
                                <Typography variant="body2" color="primary" style={{ fontWeight: "bold" }}>{rewardValues.quantity}</Typography>
                              </Grid>
                              <Grid item xs={12} sm={3} md={3} className={classes.gridFieldTopContainer}>
                                <Typography variant="body2">{getLang(lang, 'label.AVAILABLE')}</Typography>
                              </Grid>
                              <Grid item xs={12} sm={9} md={9} className={classes.gridFieldTopContainer}>
                                <Typography variant="body2" color="primary" style={{ fontWeight: "bold" }}>{rewardValues.available}</Typography>
                              </Grid>
                              <Grid item xs={12} sm={3} md={3} className={classes.gridFieldTopContainer}>
                                <Typography variant="body2">{getLang(lang, 'label.VALIDITY_DATE')}</Typography>
                              </Grid>
                              <Grid item xs={12} sm={9} md={9} className={classes.gridFieldTopContainer}>
                                <Typography variant="body2" color="primary" style={{ fontWeight: "bold" }}>{moment(rewardValues.validityStartDateTime).format('lll')} - {moment(rewardValues.validityEndDateTime).format('lll')}</Typography>
                              </Grid>
                            </Grid>
                          </Box>
                        </Box>
                      </Box>
                    </Paper>
                  </Box>
                )}
                <TabPanel value={activeIndex} index={0}>
                  <RewardDetailsForm handleEdit={handleEdit} reloadContent={reloadContent} />
                </TabPanel>
                <TabPanel value={activeIndex} index={1}>
                  <RedemptionPanel id={id} />
                </TabPanel>
              </Box>
            </>
          )}
        </Box>
      </Box>
      {isFetching && (
        <>
          <Box
            gridArea="main" mt={6}
            style={{
              textAlign: "center"
            }}
          >
            <CircularProgress color="primary" />
          </Box>
        </>
      )}
    </>
  );
}
