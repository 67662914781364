import React, { useState, useRef, useEffect } from "react";
import BranchSelectComponent from "./branchSelect.component";
import BranchPanelComponent from "./branchPanel.component";
import PropTypes from "prop-types";
import { selectLoading } from "modules/notification";
import { useDispatch, useSelector } from "react-redux";
import { fetchMeAndBranchDropdownSpecial } from "modules/product-mgmt/redux/action/products.action";
import cloneDeep from "lodash/cloneDeep";

function BranchSelectContainer({
  placeholder,
  handleChange,
  value,
  disabled,
  styles,
  textStyles,
  isProductQuickSelecting = false,
  productQuickSelectOriginalValue = [],
}) {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.constant.languages);
  const branchDropdown = useSelector((state) => state.products.branchDropdown);
  const isBranchDropdownLoading = useSelector((state) =>
    selectLoading(state, fetchMeAndBranchDropdownSpecial.typePrefix)
  );
  const [open, setOpen] = useState(false);
  const [isOpenList] = useState(false);
  const anchorRef = useRef(null);
  const prevOpen = useRef(open);
  const prevOpenList = useRef(isOpenList);
  const [selectAll, setSelectAll] = useState(false);
  let flattenBranch = [];

  useEffect(() => {
    dispatch(fetchMeAndBranchDropdownSpecial({ search: "" }));
  }, [dispatch]);

  useEffect(() => {
    for (const masterBranch of branchDropdown) {
      flattenBranch.push({
        name: masterBranch.name,
        node_id: masterBranch.node_id,
        parent_node_id: masterBranch.parent_node_id,
        remarks: masterBranch.remarks,
      });
      if (masterBranch.child !== undefined && masterBranch.child.length > 0) {
        for (const subBranch of masterBranch.child) {
          flattenBranch.push({
            name: subBranch.name,
            node_id: subBranch.node_id,
            parent_node_id: subBranch.parent_node_id,
            remarks: subBranch.remarks,
          });
        }
      }
    }
  }, [branchDropdown, flattenBranch]);

  useEffect(() => {
    let temp = cloneDeep(value);
    let isSelectAll = true;
    for (const masterBranch of flattenBranch) {
      if (
        !(temp.filter((t) => t.node_id === masterBranch.node_id).length === 1)
      ) {
        isSelectAll = false;
      }
    }
    setSelectAll(isSelectAll);
  }, [value, flattenBranch]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    if (prevOpenList.current === true && isOpenList === false) {
      anchorRef.current.focus();
    }

    prevOpenList.current = isOpenList;
  }, [isOpenList]);

  const handleSelectAll = async () => {
    let temp = cloneDeep(value);
    if (selectAll) {
      for (const masterBranch of branchDropdown) {
        temp = temp.filter((t) => t.node_id !== masterBranch.node_id);
        if (
          temp.filter((t) => t.node_id === masterBranch.parent_node_id)
            .length === 0
        ) {
          if (masterBranch.child.length > 0) {
            for (const branch of masterBranch.child) {
              temp = temp.filter((t) => t.node_id !== branch.node_id);
              if (branch.remarks === "region" && branch.child?.length > 0) {
                for (const childBranch of branch.child) {
                  temp = temp.filter((t) => t.node_id !== childBranch.node_id);
                }
              }
            }
          }
        }
      }
    } else {
      for (const masterBranch of branchDropdown) {
        temp = temp.filter((t) => t.node_id !== masterBranch.node_id);
        if (masterBranch.child.length > 0) {
          for (const branch of masterBranch.child) {
            temp = temp.filter((t) => t.node_id !== branch.node_id);
          }
        }
        if (
          temp.filter((t) => t.node_id === masterBranch.parent_node_id)
            .length === 0
        ) {
          temp.push({
            name: masterBranch.name,
            node_id: masterBranch.node_id,
            parent_node_id: masterBranch.parent_node_id,
            remarks: masterBranch.remarks,
          });

          if (masterBranch.child.length > 0) {
            for (const branch of masterBranch.child) {
              temp.push({
                name: branch.name,
                node_id: branch.node_id,
                parent_node_id: branch.parent_node_id,
                remarks: branch.remarks,
              });
              if (branch.remarks === "region" && branch.child) {
                for (const childBranch of branch.child) {
                  temp.push({
                    name: childBranch.name,
                    node_id: childBranch.node_id,
                    parent_node_id: childBranch.parent_node_id,
                    remarks: childBranch.remarks,
                  });
                }
              }
            }
          }
        }
      }
    }
    setSelectAll(!selectAll);
    handleChange(temp);
  };

  const handleSelectBranch = (branch) => {
    let temp = cloneDeep(value);
    if (temp.filter((t) => t.node_id === branch.node_id).length > 0) {
      if (isProductQuickSelecting && productQuickSelectOriginalValue.some(v => v === branch.node_id)) {

      }
      else {
        temp = temp.filter((t) => t.node_id !== branch.node_id);
      }
    } else {
      temp.push({
        name: branch.name,
        node_id: branch.node_id,
        parent_node_id: branch.parent_node_id,
        remarks: branch.remarks,
      });
    }
    handleChange(temp);
  };

  return (
    <>
      <BranchSelectComponent
        anchorRef={anchorRef}
        handleClick={handleClick}
        value={value}
        styles={styles}
        placeholder={placeholder}
        disabled={disabled}
        branchDropDown={branchDropdown}
        textStyles={textStyles}
        lang={lang}
      />
      <BranchPanelComponent
        selectAll={selectAll}
        value={value}
        anchorRef={anchorRef}
        open={open}
        isOpenList={isOpenList}
        handleClose={handleClose}
        isFetching={isBranchDropdownLoading}
        branchDropdown={branchDropdown}
        handleSelectAll={handleSelectAll}
        handleSelectBranch={handleSelectBranch}
        lang={lang}
        isProductQuickSelecting={isProductQuickSelecting}
        productQuickSelectOriginalValue={productQuickSelectOriginalValue}
      />
    </>
  );
}
BranchSelectContainer.defaultProps = {
  placeholder: "Select Branch(s)",
};

BranchSelectContainer.propTypes = {
  placeholder: PropTypes.string,
};

export default BranchSelectContainer;
