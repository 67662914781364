const API_HOST = process.env.REACT_APP_API_HOST_URL;

const exportStockSummary = async ({ ...parameters }) => {
  openWindowWithPost(`${API_HOST}/analytic/export/v1/stock/export-stock-summary?&token=${parameters.token}`,
    JSON.stringify({
      view_by: parameters.view_by,
      month: parameters.month,
      year: parameters.year,
      country: parameters.country,
      branch_uuid: parameters.branch_uuid,
      product_uuid: parameters.product_uuid
    })

  );
};

function openWindowWithPost(url, data) {
  var form = document.createElement("form");
  form.target = "_blank";
  form.method = "POST";
  form.action = url;
  form.style.display = "none";


  var input = document.createElement("input");
  input.type = "hidden";
  input.name = 'data';
  input.value = data;
  form.appendChild(input);

  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);
}

export default exportStockSummary;
