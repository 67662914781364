import React, { useRef } from "react";
import {
  makeStyles,
  Typography,
  Popover,
  Box,
  Divider,
  InputAdornment,
  Button,
  MenuItem,
  IconButton,
  MenuList,
} from "@material-ui/core";
import { Search as SearchIcon } from "@material-ui/icons";
import InputTextField from "components/input/inputTextField";
import DefaultImg from "assets/img/img-default.png";
import ClearIcon from "@material-ui/icons/Clear";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles((theme) => ({
  button: {
    padding: 0,
    minWidth: 0,
  },
  perfectScrollBar: {
    height: 200,
  },
  list: {
    paddingTop: 6,
    paddingBottom: 6,
    display: "flex",
    alignItems: "center",
  },
  listImage: {
    marginRight: "0.5em",
  },
  name: {
    whiteSpace: "normal",
    overflow: "hidden",
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "line-clamp": 2,
    "-webkit-box-orient": "vertical",
  },
  attributeText: {
    color: "#b2b2b2",
    fontSize: 12,
    whiteSpace: "break-spaces",
  },
  productPhoto: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #E4E7EC",
    width: "30px",
    height: "30px",
    marginRight: "10px",
    borderRadius: "4px",
    "& img": {
      height: "30px",
      width: "30px",
      borderRadius: "4px",
    },
  },
}));

export default function ProductSearchPopoverComponent({
  open,
  anchorEl,
  handleClose,
  id,
  style,
  searchable,
  handleSearch,
  placeholder,
  items,
  isFetching,
  emptyMessage,
  handleChange,
  anchorOrigin,
  transformOrigin,
  PaperProps,
  value,
  lang,
  isBranchEmpty,
}) {
  const classes = useStyle();
  const textRef = useRef();
  const handleClearClick = () => {
    textRef.current.value = "";
  };
  return (
    <Popover
      id={id}
      open={open}
      style={style}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      elevation={2}
      PaperProps={{
        style: { minWidth: "20em", maxWidth: 400 },
        ...PaperProps,
      }}
    >
      <Box>
        <InputTextField
          variant="filled"
          size="small"
          fullWidth
          inputRef={textRef}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" style={{ paddingRight: 6 }}>
                <Button
                  onClick={() => handleSearch(textRef.current.value)}
                  className={classes.button}
                >
                  <IconButton onClick={handleClearClick}>
                    <ClearIcon />
                  </IconButton>
                  <SearchIcon />
                </Button>
              </InputAdornment>
            ),
            style: {
              backgroundColor: "#fff",
              paddingRight: 0,
            },
          }}
          inputProps={{
            onChange: () => {
              handleSearch(textRef.current.value);
            },
            style: {
              padding: 8,
            },
          }}
          placeholder={placeholder}
        />
      </Box>
      <Divider />
      <Box style={{ maxHeight: "250px", overflow: "auto" }}>
        {isFetching ? (
          <Box
            style={{
              paddingLeft: 10,
              paddingRight: 10,
              height: 100,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography variant="body1">
              {getLang(lang, "label.LOADING")}
            </Typography>
          </Box>
        ) : items.length > 0 ? (
          <MenuList>
            {items.map((select, index) => (
              <MenuItem
                key={index}
                value={select.id}
                onClick={(_) => {
                  handleChange(select.id);
                  handleClose();
                }}
              >
                <Box display="flex" alignItems="center" py={0.5}>
                  <Box className={classes.productPhoto}>
                    {select.picture === "" ? (
                      <img src={DefaultImg} alt="emptyProduct" />
                    ) : (
                      <img src={select.picture} alt={select.name} />
                    )}
                  </Box>
                  <Box>
                    <Typography variant="body1">
                      <b>{select.name}</b>
                    </Typography>
                    {!!select.attributes &&
                      !!select.attributes.length && (
                        <Typography
                          className={classes.attributeText}
                          variant="body2"
                        >
                          {select.attributes.join(", ")}
                        </Typography>
                      )}
                  </Box>
                </Box>
              </MenuItem>
            ))}
          </MenuList>
        ) : isBranchEmpty ? (
          <Box
            style={{
              paddingLeft: 10,
              paddingRight: 10,
              height: 100,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography variant="body1">
              {getLang(lang, "paragraph.PLEASE_SELECT_A_BRANCH")}
            </Typography>
          </Box>
        ) : (
          <Box py={9} style={{ textAlign: "center" }}>
            <Typography variant="body1">{emptyMessage}</Typography>
          </Box>
        )}
      </Box>
    </Popover>
  );
}
