import React from "react";
import {
  Box,
  Paper,
  Divider,
  makeStyles,
  Typography,
  MenuItem,
  IconButton,
  Button,
  Grid,
  Tooltip,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import InputTextField from "components/input/inputTextField";
import CountrySelect from "components/select/countrySelect";
import TimezoneSelect from "components/select/timezoneSelect";
import country from "lib/constants/countries";
import HelpOutlinedIcon from "@material-ui/icons/HelpOutlined";
import { getLang } from "app/feature/constants";
import BranchGroupSelect from "../../select/branchGroupSelect";
import BranchLogoPanel from "../../panel/branchLogoPanel";
import Input from "../../../../../components/input/inputTextField";
import AntSwitch from "../../../../../components/switch";
import languageDropdown from "../../../../../lib/constants/languages";

const style = (theme) => ({
  infoContainer: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  root: {
    height: "80%",
    boxShadow: theme.shadows[5],
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "space-between",
    borderRadius: "4px",
    fontSize: 14,
    maxWidth: 900,
  },
  detailsWrapper: {
    width: "100%",
    padding: theme.spacing(2, 3, 2, 3),
    maxHeight: "calc(100vh - 260px)", // Adjust the value as needed
    overflowY: "auto",
  },
  switch: {
    paddingLeft: theme.spacing(2),
  },
  header: {
    paddingLeft: theme.spacing(3),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
});

const useStyles = makeStyles(style);

export default function BranchDialogComponent({
  parentBranchList,
  handleClose,
  handleSubmit,
  branch,
  isEditMode,
  isBranchOnly,
  lang,
  timezones,
  ownedCompany,
  companyName,
  isHqAdmin,
  editBranchList,
  isOpen,
  companyShortName,
}) {
  const classes = useStyles();

  const socialMediasList = [
    {
      label: getLang(lang, 'label.FACEBOOK'),
      name: 'facebook',
    },
    {
      label: getLang(lang, 'label.WHATSAPP'),
      name: 'whatsapp',
    },
    {
      label: getLang(lang, 'label.YOUTUBE'),
      name: 'youtube',
    },
  ];

  let branchOwner = false;
  if (isEditMode) {
    for (let i = 0; i < ownedCompany.length; i++) {
      if (branch.branchName === ownedCompany[i].name) branchOwner = true;
    }
  }

  return (
    <Paper elevation={0} className={classes.root}>
      <Box className={classes.infoContainer}>
        <div className={classes.header}>
          <Typography variant="subtitle2" style={{ fontWeight: "bold" }}>
            {isEditMode
              ? getLang(lang, "label.EDIT_BRANCH")
              : getLang(lang, "label.ADD_BRANCH")}
          </Typography>
          <IconButton color="primary" onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </div>
        <Divider />

        <Formik
          initialValues={
            isEditMode
              ? {
                id: branch.id,
                parentBranch: branch.parentBranch,
                branchName: branch.branchName,
                branchShortName: branch.shortName ? branch.shortName : "-",
                desc: branch.description ?? "",
                addressLine1: branch.addressLine1,
                addressLine2: branch.addressLine2,
                city: branch.city,
                country: country.find(
                  ({ label }) => label === branch.country
                ),
                postalCode: branch.postalCode,
                state: branch.state,
                contact: branch.contact,
                contactCode: country.find(
                  ({ phone }) => phone === branch.contactCode
                ),
                email: branch.email,
                isShowEmail: branch.isShowEmail ?? true,
                isShowContact: branch.isShowContact ?? true,
                timezone: timezones.find(({ id }) => id === branch.timezone),
                branchGroup: branch?.branchGroup ?? null,
                branchGroupUuid: branch?.branchGroup?.uuid ?? "",
                profileImage: branch?.profileImageUrl ?? null,
                socialMediaUrls: {
                  facebook: {
                    url: branch.socialMediaUrls?.facebook?.url ?? "",
                    enabled: branch.socialMediaUrls?.facebook?.enabled ?? true,
                  },
                  whatsapp: {
                    url: branch.socialMediaUrls?.whatsapp?.url ?? "",
                    enabled: branch.socialMediaUrls?.whatsapp?.enabled ?? true,
                  },
                  youtube: {
                    url: branch.socialMediaUrls?.youtube?.url ?? "",
                    enabled: branch.socialMediaUrls?.youtube?.enabled ?? true,
                  },
                },
                whiteLabel: branch?.whiteLabel,
                landingPageLanguage: branch?.landingPageLanguage ?? 'default',
              }
              : {
                id: "",
                parentBranch: "",
                branchName: "",
                branchShortName: "",
                desc: "",
                addressLine1: "",
                addressLine2: "",
                city: "",
                country: "",
                postalCode: "",
                state: "",
                contact: "",
                contactCode: null,
                email: "",
                timezone: "",
                isShowEmail: true,
                isShowContact: true,
                branchGroup: null,
                branchGroupUuid: "",
                profileImage: null,
                socialMediaUrls: {
                  facebook: {
                    url: "",
                    enabled: true,
                  },
                  whatsapp: {
                    url: "",
                    enabled: true,
                  },
                  youtube: {
                    url: "",
                    enabled: true,
                  },
                },
                landingPageLanguage: 'default',
              }
          }
          validationSchema={Yup.object({
            parentBranch: Yup.string().required(
              getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")
            ),
            branchName: Yup.string()
              .trim()
              .required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
            branchShortName: !isEditMode
              ? Yup.string()
                .trim()
                .matches(
                  /^^[a-zA-Z0-9-_]+$/,
                  getLang(
                    lang,
                    "message.error.FORM_VALIDATE_ALPANUMERIC_DASH_UNDERSCORE"
                  )
                )
                .required(
                  getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")
                )
              : Yup.string().nullable(),
            addressLine1: Yup.string()
              .trim()
              .max(
                255,
                getLang(lang, "message.error.FORM_VALIDATE_MAX", { value: 255 })
              )
              .required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
            addressLine2: Yup.string()
              .trim()
              .max(
                255,
                getLang(lang, "message.error.FORM_VALIDATE_MAX", { value: 255 })
              ),
            city: Yup.string()
              .trim()
              .max(
                255,
                getLang(lang, "message.error.FORM_VALIDATE_MAX", { value: 255 })
              )
              .required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
            country: Yup.string()
              .trim()
              .max(
                255,
                getLang(lang, "message.error.FORM_VALIDATE_MAX", { value: 255 })
              )
              .required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
            postalCode: Yup.string()
              .trim()
              .max(10, getLang(lang, "message.error.FORM_VALIDATE_MAX", { value: 10 }))
              .required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED"))
              .matches(/^[0-9a-zA-Z-_ ]*$/, getLang(lang, "message.error.INVALID_POSTCODE")),
            state: Yup.string()
              .trim()
              .max(
                50,
                getLang(lang, "message.error.FORM_VALIDATE_MAX", { value: 50 })
              )
              .required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
            contact: Yup.string()
              .trim()
              .min(
                6,
                getLang(lang, "message.error.FORM_VALIDATE_MIN", {
                  value: getLang(lang, "message.error.NUMBERS_6"),
                })
              )
              .max(
                20,
                getLang(lang, "message.error.FORM_VALIDATE_MAX", {
                  value: getLang(lang, "message.error.NUMBERS_20"),
                })
              )
              .matches(
                /^[1-9]{1}[0-9]{5,19}$/,
                getLang(lang, "message.error.FORM_VALIDATE_PHONE")
              )
              .required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
            email: Yup.string()
              .email(getLang(lang, "message.error.FORM_VALIDATE_EMAIL"))
              .required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
            timezone: Yup.string().required(
              getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")
            ),
            profileImage: Yup.mixed().nullable(),
          })}
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <Box className={classes.detailsWrapper}>
                <Box display="flex">
                  <Box>
                    <Grid
                      container
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {
                        <>
                          <Grid item md={3} style={{ width: "67%" }}>
                            <Typography variant="body2" color="textSecondary">
                              {getLang(lang, "label.OWNED_BY")}{" "}
                              <span
                                style={{
                                  color: "#DE350B",
                                  display: "inline-block",
                                }}
                              >
                            *
                          </span>
                            </Typography>
                          </Grid>
                          <Grid item md={9} style={{ width: "67%" }}>
                            {isHqAdmin && isEditMode ? (
                              <>
                                {" "}
                                <InputTextField
                                  select
                                  hiddenLabel
                                  variant="filled"
                                  fullWidth
                                  margin="dense"
                                  InputProps={{
                                    disableUnderline: true,
                                    style: {
                                      padding: "0",
                                      backgroundColor: "#ECEFF0",
                                    },
                                  }}
                                  {...formik.getFieldProps("parentBranch")}
                                  disabled={formik.isSubmitting}
                                  error={
                                    formik.touched.parentBranch &&
                                    formik.errors.parentBranch
                                      ? true
                                      : false
                                  }
                                  helperText={ErrorMessage({
                                    name: "parentBranch",
                                  })}
                                >
                                  {editBranchList.map((item, index) => {
                                    return (
                                      <MenuItem key={index} value={item.node_id}>
                                        {item.name}
                                      </MenuItem>
                                    );
                                  })}
                                </InputTextField>
                              </>
                            ) : branchOwner ? (
                              <Typography variant="body2" style={{ margin: "8px" }}>
                                {branch.parentName}
                              </Typography>
                            ) : (
                              <InputTextField
                                select
                                hiddenLabel
                                variant="filled"
                                fullWidth
                                margin="dense"
                                InputProps={{
                                  disableUnderline: true,
                                  style: {
                                    padding: "0",
                                    backgroundColor: "#ECEFF0",
                                  },
                                }}
                                {...formik.getFieldProps("parentBranch")}
                                disabled={formik.isSubmitting}
                                error={
                                  formik.touched.parentBranch &&
                                  formik.errors.parentBranch
                                    ? true
                                    : false
                                }
                                helperText={ErrorMessage({
                                  name: "parentBranch",
                                })}
                              >
                                {parentBranchList.map((item, index) => {
                                  return (
                                    <MenuItem key={index} value={item.node_id}>
                                      {item.name}
                                    </MenuItem>
                                  );
                                })}
                              </InputTextField>
                            )}
                          </Grid>
                        </>
                      }
                      <Grid item md={3} style={{ width: "67%" }}>
                        <Typography variant="body2" color="textSecondary">
                          {getLang(lang, "label.BRANCH_NAME")}{" "}
                          <span
                            style={{ color: "#DE350B", display: "inline-block" }}
                          >
                        *
                      </span>
                        </Typography>
                      </Grid>
                      <Grid item md={9} style={{ width: "67%" }}>
                        <InputTextField
                          hiddenLabel
                          variant="filled"
                          size="small"
                          fullWidth
                          margin="dense"
                          disabled={formik.isSubmitting}
                          {...formik.getFieldProps("branchName")}
                          error={
                            formik.touched.branchName && formik.errors.branchName
                              ? true
                              : false
                          }
                          helperText={ErrorMessage({ name: "branchName" })}
                        />
                      </Grid>
                      <Grid item md={3} style={{ width: "67%" }}>
                        <Typography variant="body2" color="textSecondary">
                          {getLang(lang, "label.BRANCH_SHORT_NAME")}{" "}
                          <span
                            style={{ color: "#DE350B", display: "inline-block" }}
                          >
                        *
                      </span>
                          <Tooltip
                            title={getLang(
                              lang,
                              "paragraph.BRANCH_SHORT_NAME_CANNOT_CHANGE"
                            )}
                            arrow
                          >
                            <HelpOutlinedIcon color="primary" fontSize="small" />
                          </Tooltip>
                        </Typography>
                      </Grid>
                      <Grid item md={9} style={{ width: "67%" }}>
                        {isEditMode ? (
                          <Typography style={{ margin: "12px" }}>
                            {formik.initialValues.branchShortName}
                          </Typography>
                        ) : (
                          <InputTextField
                            hiddenLabel
                            variant="filled"
                            size="small"
                            fullWidth
                            margin="dense"
                            disabled={formik.isSubmitting || isEditMode}
                            {...formik.getFieldProps("branchShortName")}
                            error={
                              formik.touched.branchShortName &&
                              formik.errors.branchShortName
                                ? true
                                : false
                            }
                            helperText={ErrorMessage({ name: "branchShortName" })}
                            onKeyDown={(e) => {
                              if (e.keyCode === 32) {
                                e.preventDefault();
                                return false;
                              }
                            }}
                          />
                        )}
                      </Grid>
                      {isEditMode && branch?.whiteLabel ? (
                        <>
                          <Grid item md={3} style={{ width: "67%" }}>
                            <Typography variant="body2" color="textSecondary">
                              {getLang(lang, "label.WHITE_LABEL")}{" "}
                              <span
                                style={{ color: "#DE350B", display: "inline-block" }}
                              >
                                *
                              </span>
                            </Typography>
                          </Grid>
                          <Grid item md={9} style={{ width: "67%" }}>
                            <Typography style={{ margin: "12px" }}>
                              {formik.initialValues.whiteLabel}
                            </Typography>
                          </Grid>
                        </>
                      ) : ""}
                      <Grid item md={3} style={{ width: "67%" }}>
                        <Typography variant="body2" color="textSecondary">
                          {getLang(lang, "label.PREVIEW_URL")}{" "}
                          <span
                            style={{ color: "#DE350B", display: "inline-block" }}
                          >
                        *
                      </span>
                          <Tooltip
                            title={getLang(
                              lang,
                              "tooltips.PREVIEW_QR_CODE_URL_BRANCH"
                            )}
                            arrow
                          >
                            <HelpOutlinedIcon color="primary" fontSize="small" />
                          </Tooltip>
                        </Typography>
                      </Grid>
                      <Grid item md={9} style={{ width: "67%" }}>
                        <Box style={{ width: "100%", overflowWrap: "break-word"}}>
                          <Typography style={{ margin: "12px" }}>
                            {branch?.whiteLabel ? branch.whiteLabel : process.env.REACT_APP_CONSUMER_PORTAL_URL}{companyShortName && companyShortName.length > 0 ? '/' + companyShortName : ''}
                            {!isEditMode ? (
                              <b>{(formik.touched.branchShortName && formik.errors.branchShortName) || formik.values['branchShortName'].trim().length === 0 ? `/[${getLang(lang, "label.BRANCH_SHORT_NAME")}]` : '/' + formik.values['branchShortName']}</b>
                            ) : formik.values['branchShortName'] !== '-' ? (
                              <>
                                <>/</>
                                <b>{formik.values['branchShortName']}</b>
                              </>
                            ) : null}
                            /{'X'.repeat(10)}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item md={3} style={{ width: "67%" }}>
                        <Typography variant="body2" color="textSecondary">
                          {getLang(lang, "label.DESCRIPTION")}
                        </Typography>
                      </Grid>
                      <Grid item md={9} style={{ width: "67%" }}>
                        <Input
                          hiddenLabel
                          multiline
                          rows={4}
                          variant="filled"
                          size="small"
                          margin="none"
                          fullWidth
                          {...formik.getFieldProps("desc")}
                          helperText={ErrorMessage({ name: "desc" })}
                        />
                      </Grid>
                      <Grid item md={3} style={{ width: "67%" }}>
                        <Typography variant="body2" color="textSecondary">
                          {getLang(lang, "label.ADDRESS_LINE_1")}{" "}
                          <span
                            style={{ color: "#DE350B", display: "inline-block" }}
                          >
                        *
                      </span>
                        </Typography>
                      </Grid>
                      <Grid item md={9} style={{ width: "67%" }}>
                        <InputTextField
                          hiddenLabel
                          variant="filled"
                          size="small"
                          fullWidth
                          margin="dense"
                          disabled={formik.isSubmitting}
                          {...formik.getFieldProps("addressLine1")}
                          error={
                            formik.touched.addressLine1 &&
                            formik.errors.addressLine1
                              ? true
                              : false
                          }
                          helperText={ErrorMessage({ name: "addressLine1" })}
                        />
                      </Grid>
                      <Grid item md={3} style={{ width: "67%" }}>
                        <Typography variant="body2" color="textSecondary">
                          {getLang(lang, "label.ADDRESS_LINE_2")}
                        </Typography>
                      </Grid>
                      <Grid item md={9} style={{ width: "67%" }}>
                        <InputTextField
                          hiddenLabel
                          variant="filled"
                          size="small"
                          fullWidth
                          margin="dense"
                          disabled={formik.isSubmitting}
                          {...formik.getFieldProps("addressLine2")}
                          error={
                            formik.touched.addressLine2 &&
                            formik.errors.addressLine2
                              ? true
                              : false
                          }
                          helperText={ErrorMessage({ name: "addressLine2" })}
                        />
                      </Grid>
                      <Grid item md={3} style={{ width: "67%" }}>
                        <Typography variant="body2" color="textSecondary">
                          {getLang(lang, "label.CITY")}{" "}
                          <span
                            style={{ color: "#DE350B", display: "inline-block" }}
                          >
                        *
                      </span>
                        </Typography>
                      </Grid>
                      <Grid item md={9} style={{ width: "67%" }}>
                        <InputTextField
                          hiddenLabel
                          variant="filled"
                          size="small"
                          fullWidth
                          margin="dense"
                          disabled={formik.isSubmitting}
                          {...formik.getFieldProps("city")}
                          error={
                            formik.touched.city && formik.errors.city ? true : false
                          }
                          helperText={ErrorMessage({ name: "city" })}
                        />
                      </Grid>
                      <Grid item md={3} style={{ width: "67%" }}>
                        <Typography variant="body2" color="textSecondary">
                          {getLang(lang, "label.POSTAL_CODE")}{" "}
                          <span
                            style={{ color: "#DE350B", display: "inline-block" }}
                          >
                        *
                      </span>
                        </Typography>
                      </Grid>
                      <Grid item md={9} style={{ width: "67%" }}>
                        <InputTextField
                          hiddenLabel
                          variant="filled"
                          size="small"
                          fullWidth
                          margin="dense"
                          disabled={formik.isSubmitting}
                          {...formik.getFieldProps("postalCode")}
                          error={
                            formik.touched.postalCode && formik.errors.postalCode
                              ? true
                              : false
                          }
                          helperText={ErrorMessage({ name: "postalCode" })}
                        />
                      </Grid>
                      <Grid item md={3} style={{ width: "67%" }}>
                        <Typography variant="body2" color="textSecondary">
                          {getLang(lang, "label.STATE")}{" "}
                          <span
                            style={{ color: "#DE350B", display: "inline-block" }}
                          >
                        *
                      </span>
                        </Typography>
                      </Grid>
                      <Grid item md={9} style={{ width: "67%" }}>
                        <InputTextField
                          hiddenLabel
                          variant="filled"
                          size="small"
                          fullWidth
                          margin="dense"
                          disabled={formik.isSubmitting}
                          {...formik.getFieldProps("state")}
                          error={
                            formik.touched.state && formik.errors.state
                              ? true
                              : false
                          }
                          helperText={ErrorMessage({ name: "state" })}
                        />
                      </Grid>
                      <Grid item md={3} style={{ width: "67%" }}>
                        <Typography variant="body2" color="textSecondary">
                          {getLang(lang, "label.COUNTRY")}{" "}
                          <span
                            style={{ color: "#DE350B", display: "inline-block" }}
                          >
                        *
                      </span>
                        </Typography>
                      </Grid>
                      <Grid item md={9} style={{ width: "67%" }}>
                        <CountrySelect
                          textFieldProps={{
                            fullWidth: true,
                            variant: "filled",
                            size: "small",
                            margin: "dense",
                            error:
                              formik.errors.country && formik.touched.country
                                ? true
                                : false,
                            helperText: ErrorMessage({ name: "country" }),
                          }}
                          formProps={{
                            ...formik.getFieldProps("country"),
                            value: formik.values.country,
                            onChange: (e, newValue) => {
                              formik.setFieldValue("country", newValue);
                            },
                            disabled: formik.isSubmitting,
                            disableClearable: true,
                          }}
                          type="countryOnly"
                        />
                      </Grid>
                      <Grid item md={3} style={{ width: "67%" }}>
                        <Typography variant="body2" color="textSecondary">
                          {getLang(lang, "label.CONTACT")}{" "}
                          <span
                            style={{ color: "#DE350B", display: "inline-block" }}
                          >
                        *
                      </span>
                        </Typography>
                      </Grid>
                      <Grid item md={9} style={{ width: "67%" }}>
                        <Box display="flex" gridColumnGap={12}>
                          <CountrySelect
                            type="phone"
                            textFieldProps={{
                              required: true,
                              style: { width: 150 },
                              variant: "filled",
                              size: "small",
                              margin: "dense",
                              error:
                                formik.errors.contactCode &&
                                formik.touched.contactCode
                                  ? true
                                  : false,
                              helperText: ErrorMessage({ name: "contactCode" }),
                            }}
                            formProps={{
                              ...formik.getFieldProps("contactCode"),
                              value: formik.values.contactCode,
                              onChange: (e, newValue) => {
                                formik.setFieldValue("contactCode", newValue);
                              },
                              disabled: formik.isSubmitting,
                            }}
                          />
                          <InputTextField
                            hiddenLabel
                            variant="filled"
                            size="small"
                            fullWidth
                            margin="dense"
                            disabled={formik.isSubmitting}
                            {...formik.getFieldProps("contact")}
                            error={
                              formik.touched.contact && formik.errors.contact
                                ? true
                                : false
                            }
                            helperText={ErrorMessage({ name: "contact" })}
                            onKeyPress={(e) => {
                              if (!/[0-9]/.test(e.key)) {
                                e.preventDefault();
                              }
                            }}
                          />
                        </Box>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                          <Typography
                            variant="body2"
                            color="textSecondary"
                          >
                            {getLang(lang, "label.SHOW_ON_PRODUCT_LANDING_PAGE")}
                          </Typography>
                          <AntSwitch
                            checked={formik.values.isShowContact}
                            onChange={(ev) => {
                              formik.setFieldValue(
                                'isShowContact',
                                ev.target.checked
                              );
                            }}
                            disabled={formik.isSubmitting}
                          />
                        </Box>
                      </Grid>
                      <Grid item md={3} style={{ width: "67%" }}>
                        <Typography variant="body2" color="textSecondary">
                          {getLang(lang, "label.EMAIL")}{" "}
                          <span
                            style={{ color: "#DE350B", display: "inline-block" }}
                          >
                        *
                      </span>
                        </Typography>
                      </Grid>
                      <Grid item md={9} style={{ width: "67%" }}>
                        <InputTextField
                          hiddenLabel
                          variant="filled"
                          size="small"
                          fullWidth
                          margin="dense"
                          disabled={formik.isSubmitting}
                          {...formik.getFieldProps("email")}
                          error={
                            formik.touched.email && formik.errors.email
                              ? true
                              : false
                          }
                          helperText={ErrorMessage({ name: "email" })}
                        />
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                          <Typography
                            variant="body2"
                            color="textSecondary"
                          >
                            {getLang(lang, "label.SHOW_ON_PRODUCT_LANDING_PAGE")}
                          </Typography>
                          <AntSwitch
                            checked={formik.values.isShowEmail}
                            onChange={(ev) => {
                              formik.setFieldValue(
                                'isShowEmail',
                                ev.target.checked
                              );
                            }}
                            disabled={formik.isSubmitting}
                          />
                        </Box>
                      </Grid>
                      <Grid item md={3} style={{ width: "67%" }}>
                        <Typography variant="body2" color="textSecondary">
                          {getLang(lang, "label.TIME_ZONE")}{" "}
                          <span
                            style={{ color: "#DE350B", display: "inline-block" }}
                          >
                        *
                      </span>
                        </Typography>
                      </Grid>
                      <Grid item md={9} style={{ width: "67%" }}>
                        <TimezoneSelect
                          textFieldProps={{
                            required: true,
                            variant: "filled",
                            size: "small",
                            margin: "dense",
                            error:
                              formik.errors.timezone && formik.touched.timezone
                                ? true
                                : false,
                            helperText: ErrorMessage({ name: "timezone" }),
                          }}
                          formProps={{
                            ...formik.getFieldProps("timezone"),
                            value: formik.values.timezone,
                            onChange: (e, newValue) => {
                              formik.setFieldValue("timezone", newValue);
                            },
                            disabled: formik.isSubmitting,
                            disableClearable: true,
                          }}
                        />
                      </Grid>
                      {socialMediasList.map((e) => (
                        <>
                          <Grid item md={3} style={{ width: "67%" }}>
                            <Typography variant="body2" color="textSecondary">
                              {e.label}
                            </Typography>
                          </Grid>
                          <Grid item md={9} style={{ width: "67%" }}>
                            <InputTextField
                              hiddenLabel
                              variant="filled"
                              size="small"
                              fullWidth
                              margin="dense"
                              placeholder={getLang(lang, "label.ENTER_URL")}
                              disabled={formik.isSubmitting}
                              {...formik.getFieldProps(`socialMediaUrls.${e.name}.url`)}
                              error={
                                !!(formik.touched[`socialMediaUrls.${e.name}`] && formik.errors[`socialMediaUrls.${e.name}`])
                              }
                              helperText={ErrorMessage({ name: `socialMediaUrls.${e.name}` })}
                            />
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                              <Typography
                                variant="body2"
                                color="textSecondary"
                              >
                                {getLang(lang, "label.SHOW_ON_PRODUCT_LANDING_PAGE")}
                              </Typography>
                              <AntSwitch
                                checked={formik.values.socialMediaUrls[e.name].enabled}
                                onChange={(ev) => {
                                  formik.setFieldValue(
                                    `socialMediaUrls.${e.name}.enabled`,
                                    ev.target.checked
                                  );
                                }}
                                disabled={formik.isSubmitting}
                              />
                            </Box>
                          </Grid>
                        </>
                      ))}
                      <Grid item md={3} style={{ width: "67%" }}>
                        <Typography variant="body2" color="textSecondary">
                          {getLang(lang, "label.BRANCH_GROUP")}
                        </Typography>
                      </Grid>
                      <Grid item md={9} style={{ width: "67%" }}>
                        <BranchGroupSelect
                          textFieldProps={{
                            required: false,
                            variant: "filled",
                            size: "small",
                            margin: "dense",
                          }}
                          formProps={{
                            value: formik.values.branchGroup,
                            onChange: (e, newValue) => {
                              formik.setFieldValue("branchGroup", newValue);
                              formik.setFieldValue(
                                "branchGroupUuid",
                                newValue ? newValue.uuid : ""
                              );
                            },
                            disabled: formik.isSubmitting,
                            disableClearable: true,
                          }}
                          isOpen={isOpen}
                        />
                      </Grid>
                      <Grid item md={3} style={{ width: "67%" }} pr={1}>
                        <Typography variant="body2" color="textSecondary">
                          {getLang(lang, "label.LANDING_PAGE_LANGUAGE")}
                        </Typography>
                      </Grid>
                      <Grid item md={9} style={{ width: "67%" }}>
                        <InputTextField
                          select
                          name="landingPageLanguage"
                          hiddenLabel
                          variant="filled"
                          size="small"
                          fullWidth
                          margin="dense"
                          disabled={formik.isSubmitting}
                          error={!!(formik.touched["landingPageLanguage"] && formik.errors["landingPageLanguage"])}
                          helperText={ErrorMessage({
                            name: "landingPageLanguage"
                          })}
                          {...formik.getFieldProps("landingPageLanguage")}
                        >
                          {[{ code: "default", label: "label.DEFAULT" }, ...languageDropdown].map((item, index) => (
                            <MenuItem value={item.code} key={index}>
                              {getLang(lang, item.label === 'label.DEFAULT' ? item.label : `constant.language.${item.code}`)}
                            </MenuItem>
                          ))}
                        </InputTextField>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box pl={2}>
                    <BranchLogoPanel
                      logo={formik.values.profileImage}
                      handleChange={(base64Image) => {
                        formik.setFieldValue('profileImage', base64Image);
                      }}
                      handleRemove={() => {
                        formik.setFieldValue('profileImage', null);
                      }}
                    />
                  </Box>
                </Box>
              </Box>
              <Divider />
              <Box m={2} display="flex" justifyContent="space">
                <Box flexGrow={1} display="flex" alignItems="center">
                  <Typography
                    style={{ color: "#DE350B", display: "inline-block" }}
                  >
                    *&nbsp;
                  </Typography>{" "}
                  <Typography variant="caption">
                    {" "}
                    {getLang(lang, "label.REQUIRED_FIELD")}
                  </Typography>
                </Box>
                <Box>
                  <Button
                    disableElevation
                    type="button"
                    style={{ marginRight: "4px" }}
                    onClick={(e) => {
                      formik.resetForm();
                      handleClose(e);
                    }}
                  >
                    {getLang(lang, "label.CANCEL")}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    size="small"
                    type="submit"
                    disabled={formik.isSubmitting}
                  >
                    {formik.isSubmitting
                      ? getLang(lang, "label.SUBMITTING")
                      : getLang(lang, "label.SUBMIT")}
                  </Button>
                </Box>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Paper>
  );
}
