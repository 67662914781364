import React from "react";
import {
  Box,
  Paper,
  makeStyles,
  Typography,
  Grid,
  Button,
  Divider
} from "@material-ui/core";
import clsx from "clsx";
import { Formik, ErrorMessage } from "formik";
import MapsContainer from "../../maps/maps.container";
import { SkeletonCounterfeitReportDetailComponent } from "components/skeleton/skeleton.component";
import InputTextField from "components/input/inputTextField";
import * as Yup from "yup";
import { getLang } from "app/feature/constants";
import moment from "moment";
import "moment/min/locales.min";

const style = (theme) => ({
  root: {
    borderRadius: "4px",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
    padding: theme.spacing(3)
  },
  textArea: {
    width: "100%",
    marginTop: theme.spacing(1)
  },
  statusInactive: {
    color: "#FFBF00"
  },
  statusActive: {
    color: "#6AAF68"
  },
  button: {
    display: "flex",
    justifyContent: "flex-end"
  },
  photoContainer: {
    background: "#EBEDEE",
    border: "1px solid rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
    width: "250px",
    height: "250px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    padding: 4
  },
  title: {
    fontWeight: "bold",
    paddingBottom: theme.spacing(2)
  },
  textColor: {
    color: "#899498 "
  }
});

const useStyles = makeStyles(style);

export default function DetailPanelComponent({
  hasCounterfeitReportEditAccessRight,
  isFetching,
  CounterfeitReportDetail,
  isChanging,
  language,
  handleSubmit,
  lang
}) {
  const classes = useStyles();
  
  if(language==="ZH")
  {
    moment.locale('zh-cn')
  }else if(language==="TL")
  {
    moment.locale('tl-ph')
  }else
  {
    moment.locale(language)
  }


  const displayStatus = (status) => {
    return (
      <Typography
        variant="body2"
        className={clsx({
          [classes.statusInactive]: status === "pending",
          [classes.statusActive]: status === "processed"
        })}
      >
        {status.toUpperCase()}
      </Typography>
    );
  };

  return (
    <Paper elevation={0} className={classes.root}>
      {isFetching ? (
        <SkeletonCounterfeitReportDetailComponent />
      ) : (
        <Box>
          <Formik
            initialValues={{
              status: CounterfeitReportDetail.status,
              adminRemarks: CounterfeitReportDetail.adminRemarks,
            }}
            validationSchema={Yup.object({
              adminRemarks: Yup.string()
                .trim()
                .max(500, getLang(lang, "message.error.FORM_VALIDATE_MAX", {value: 500}))
            })}
            onSubmit={handleSubmit}
          >
            {(formik) => (
              <form onSubmit={formik.handleSubmit}>
                <Typography
                  variant="body2"
                  color="primary"
                  className={classes.title}
                >
                  {getLang(lang, "label.PRODUCT_DETAILS")}
                </Typography>
                <Typography variant="caption" className={classes.textColor}>
                  {getLang(lang, "label.PHOTO")}
                </Typography>
                <Box className={classes.photoContainer}>
                  <img
                    src={CounterfeitReportDetail.imageUrl}
                    alt="product"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain"
                    }}
                  />
                </Box>

                <div style={{ paddingTop: "16px" }}>
                  <Typography variant="caption" className={classes.textColor}>
                    {getLang(lang, "label.PRODUCT_NAME")}
                  </Typography>
                  <Typography variant="body1" color="primary">
                    {CounterfeitReportDetail.productName}
                  </Typography>
                </div>

                <Grid container style={{ paddingTop: "16px" }}>
                  {[
                    {
                      title: getLang(lang, "label.SERIAL_NUMBER"),
                      name: CounterfeitReportDetail.serialNumber
                    },
                    {
                      title: getLang(lang, "label.PURCHASED_DATE"),
                      name: CounterfeitReportDetail.purchasedDate
                    },
                    {
                      title: getLang(lang, "label.REPORTED_DATE"),
                      name: CounterfeitReportDetail.createdAt
                    },
                  ].map((field, index) => {
                    return (
                      <React.Fragment key={index}>
                        <Grid
                          item
                          xs={3}
                          sm={3}
                          md={3}
                          style={{ width: "100%" }}
                        >
                          <Typography
                            variant="caption"
                            className={classes.textColor}
                          >
                            {field.title}
                          </Typography>
                          <Typography variant="body1" color="primary">
                            {field.title!==getLang(lang, "label.SERIAL_NUMBER")?moment(field.name).format('ll'):field.name}
                          </Typography>
                        </Grid>
                      </React.Fragment>
                    );
                  })}
                </Grid>

                <Grid container style={{ padding: "16px 0px" }}>
                  {[
                    {
                      title: getLang(lang, "label.PURCHASED_FROM"),
                      name: CounterfeitReportDetail.purchasedFrom,
                    },
                    {
                      title: getLang(lang, "label.REFERENCE_CODE"),
                      name: CounterfeitReportDetail.refCode
                    },
                  ].map((field, index) => {
                    return (
                      <React.Fragment key={index}>
                        <Grid
                          item
                          xs={3}
                          sm={3}
                          md={3}
                          style={{ width: "100%" }}
                        >
                          <Typography
                            variant="caption"
                            className={classes.textColor}
                          >
                            {field.title}
                          </Typography>
                          <Typography variant="body1" color="primary">
                            {field.name}
                          </Typography>
                        </Grid>
                      </React.Fragment>
                    );
                  })}
                </Grid>

                <Divider />

                <div style={{ paddingTop: "16px" }}>
                  <Typography
                    variant="body2"
                    color="primary"
                    className={classes.title}
                  >
                    {getLang(lang, "label.CONSUMER_DETAILS")}
                  </Typography>
                </div>

                <Grid container>
                  {[
                    {
                      title: getLang(lang, "label.NAME"),
                      name: CounterfeitReportDetail.consumerName
                    },
                    {
                      title: getLang(lang, "label.CONTACT_NUMBER"),
                      name: CounterfeitReportDetail.contact
                    },
                    {
                      title: getLang(lang, "label.EMAIL"),
                      name: CounterfeitReportDetail.email
                    },
                  ].map((field, index) => {
                    return (
                      <React.Fragment key={index}>
                        <Grid
                          item
                          xs={3}
                          sm={3}
                          md={3}
                          style={{ width: "100%" }}
                        >
                          <Typography
                            variant="caption"
                            className={classes.textColor}
                          >
                            {field.title}
                          </Typography>
                          <Typography variant="body1" color="primary">
                            {field.name}
                          </Typography>
                        </Grid>
                      </React.Fragment>
                    );
                  })}
                </Grid>

                <Box pt={2}>
                  <Typography variant="body2" className={classes.textColor}>
                    {getLang(lang, "label.ADDRESS")}
                  </Typography>
                  <MapsContainer
                    latitude={CounterfeitReportDetail.latitude}
                    longitude={CounterfeitReportDetail.longitude}
                  />
                </Box>

                <Box pt={2} pb={2}>
                  <Typography variant="body2" className={classes.textColor}>
                    {getLang(lang, "label.CONSUMER_REMARKS")}
                  </Typography>
                  <Typography variant="body1" color="primary">
                    {CounterfeitReportDetail.consumerRemarks}
                  </Typography>
                </Box>

                <Divider />

                <Box pt={2}>
                  <Typography variant="body2" className={classes.textColor}>
                    {getLang(lang, "label.STATUS")}
                  </Typography>
                  <Typography variant="caption">
                    {displayStatus(CounterfeitReportDetail.status)}
                  </Typography>
                </Box>

                <Box mt={3}>
                  <Typography variant="body2" className={classes.textColor}>
                    {getLang(lang, "label.ADMIN_REMARKS")}
                  </Typography>
                  <Box>
                    <InputTextField
                      multiline
                      rows={4}
                      className={classes.textArea}
                      {...formik.getFieldProps("adminRemarks")}
                      disabled={formik.isSubmitting || !hasCounterfeitReportEditAccessRight}
                      error={
                        formik.touched.adminRemarks &&
                          formik.errors.adminRemarks
                          ? true
                          : false
                      }
                      helperText={ErrorMessage({ name: "adminRemarks" })}
                    />
                  </Box>
                </Box>
                {
                  hasCounterfeitReportEditAccessRight
                    ?
                    <Box mt={3} className={classes.button}>
                      <Button
                        color="primary"
                        variant="contained"
                        disableElevation
                        type="submit"
                        disabled={formik.isSubmitting || isChanging}
                      >
                        {getLang(lang, "label.UPDATE")}
                      </Button>
                    </Box>
                    : null
                }
              </form>
            )}
          </Formik>
        </Box>
      )}
    </Paper>
  );
}
  