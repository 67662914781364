import axios from "axios";

const url =  "/serialnumber/api/v1/product/category/bulk-create";

export function createProductCategories({
  categoryNames,
}) {
  return new Promise((resolve, reject) => {
    axios.post(url, {
      names: categoryNames
    })
      .then(response => resolve(response))
      .catch(error => reject(error.response.data))
  })
}