import ConfirmationDialog from "components/dialog/confirmation";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import {
  fetchLuckyDrawListTable,
  setType,
  changeLuckyDrawStatus,
  updateLdPageSetting,
} from "modules/lucky-draw/redux";
import { connect, useDispatch, useSelector } from "react-redux";
import LuckyDrawDetailDialog from "../../dialog/luckyDrawDetailDialog";
import LuckyDrawListingPanelComponent from "./luckyDrawListingPanel.component";
import { selectLoading } from "modules/notification";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  LUCKY_DRAW_EDIT,
  LUCKY_DRAW_ACTIVATE_OR_DEACTIVATE,
  LUCKY_DRAW_ADD,
} from "lib/constants/accessRights";
import { generatePaginationOptions } from "lib/helper";
import { getLang } from "app/feature/constants";
import { getAllProducts } from "../../../../product-mgmt/redux/action/products.action";

const LuckyDrawListingPanelContainer = forwardRef((_, ref) => {
  const hasLuckyDrawAddAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(LUCKY_DRAW_ADD)
  );
  const hasLuckyDrawEditAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(LUCKY_DRAW_EDIT)
  );
  const hasLuckyDrawActivateOrDeactivateAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(LUCKY_DRAW_ACTIVATE_OR_DEACTIVATE)
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [search, setSearch] = useState({ search: "", product: [] });
  const [isDeactivateDialogOpen, setIsDeactivateDialogOpen] = useState(false);
  const [isActivateDialogOpen, setIsActivateDialogOpen] = useState(false);
  const [changeStatusTargetId, setChangeStatusTargetId] = useState("");
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [paginationOptions, setPaginationOptions] = useState([]);

  const dispatch = useDispatch();
  const data = useSelector((state) => state.luckyDraw.luckyDrawListTable.data);
  const totalRecords = useSelector(
    (state) => state.luckyDraw.luckyDrawListTable.totalRecords
  );
  const filteredTotalRecords = useSelector(
    (state) => state.luckyDraw.luckyDrawListTable.filteredTotalRecords
  );
  const isStatusUpdating = useSelector((state) =>
    selectLoading(state, changeLuckyDrawStatus.typePrefix)
  );
  const isFetching = useSelector((state) =>
    selectLoading(state, fetchLuckyDrawListTable.typePrefix)
  );
  const productDropdown = useSelector((state) => state.products.allProducts);
  const isFetchingProduct = useSelector((state) =>
    selectLoading(state, getAllProducts.typePrefix)
  );
  const lang = useSelector((state) => state.constant.languages);
  const language = useSelector((state) => state.profile.language);

  useEffect(() => {
    dispatch(getAllProducts());
  }, [dispatch]);

  useImperativeHandle(ref, () => ({
    openCreateLuckyDrawModal: () => {
      dispatch(setType({ index: "null", type: "create" }));
      setIsCreateDialogOpen(true);
    },
  }));

  useEffect(() => {
    dispatch(
      fetchLuckyDrawListTable({
        length: rowsPerPage,
        start: rowsPerPage * page,
        search: search.searchString,
        product: search.product.map((item) => item.id),
      })
    );
  }, [search, rowsPerPage, page, dispatch]);

  useEffect(() => {
    setPaginationOptions(generatePaginationOptions(totalRecords));
  }, [totalRecords]);

  const handleFetchLuckyDrawList = () => {
    dispatch(
      fetchLuckyDrawListTable({
        length: rowsPerPage,
        start: rowsPerPage * page,
        search: search,
      })
    );
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);

    dispatch(
      updateLdPageSetting({
        start: rowsPerPage * newPage,
      })
    );
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(event.target.value);

    dispatch(
      updateLdPageSetting({
        length: event.target.value,
        start: 0,
      })
    );
  };

  const handleSearch = (value) => {
    setPage(0);
    setSearch(value);

    dispatch(
      updateLdPageSetting({
        start: 0,
        search: value.searchString ?? "",
        product: value.product.map((item) => item.id),
      })
    );
  };

  const handleStatusChange = ({ currentStatus, id }) => {
    if (hasLuckyDrawActivateOrDeactivateAccessRight) {
      setChangeStatusTargetId(id);
      if (currentStatus) {
        setIsDeactivateDialogOpen(true);
      } else {
        setIsActivateDialogOpen(true);
      }
    }
  };

  const handleStatusSubmit = (status) => {
    if (hasLuckyDrawActivateOrDeactivateAccessRight) {
      dispatch(
        changeLuckyDrawStatus({
          id: changeStatusTargetId,
          status: status ? "active" : "inactive",
        })
      )
        .then(unwrapResult)
        .finally(() => {
          setIsDeactivateDialogOpen(false);
          setIsActivateDialogOpen(false);
        });
    }
  };

  const handleCreateSurvey = (values) => {
    setIsCreateDialogOpen(false);
  };

  const handleCreate = () => {
    dispatch(setType({ index: "null", type: "create" }));
    setIsCreateDialogOpen(true);
  };

  return (
    <>
      <LuckyDrawListingPanelComponent
        hasLuckyDrawAddAccessRight={hasLuckyDrawAddAccessRight}
        hasLuckyDrawEditAccessRight={hasLuckyDrawEditAccessRight}
        hasLuckyDrawActivateOrDeactivateAccessRight={
          hasLuckyDrawActivateOrDeactivateAccessRight
        }
        data={data}
        isFetching={isFetching}
        page={page}
        search={search}
        rowsPerPage={rowsPerPage}
        totalRecords={search ? filteredTotalRecords : totalRecords}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleSearch={handleSearch}
        handleCreate={handleCreate}
        handleStatusChange={handleStatusChange}
        handleFetchLuckyDrawList={handleFetchLuckyDrawList}
        paginationOptions={paginationOptions}
        productDropdown={productDropdown}
        isFetchingProduct={isFetchingProduct}
        lang={lang}
        language={language}
      />
      <LuckyDrawDetailDialog
        isOpen={isCreateDialogOpen}
        handleClose={() => setIsCreateDialogOpen(false)}
        handleSubmit={handleCreateSurvey}
      />
      <ConfirmationDialog
        isOpen={isDeactivateDialogOpen}
        handleClose={() => setIsDeactivateDialogOpen(false)}
        handleProceed={() => handleStatusSubmit(false)}
        isLoading={isStatusUpdating}
        type={"danger"}
        description={getLang(lang, "paragraph.DEACTIVATE_LD_QUESTION")}
        title={getLang(lang, "label.CONFIRMATION_NEEDED")}
      />
      <ConfirmationDialog
        isOpen={isActivateDialogOpen}
        handleClose={() => setIsActivateDialogOpen(false)}
        handleProceed={() => handleStatusSubmit(true)}
        isLoading={isStatusUpdating}
        description={getLang(lang, "paragraph.ACTIVATE_LD_QUESTION")}
        title={getLang(lang, "label.CONFIRMATION_NEEDED")}
      />
    </>
  );
});

export default connect(null, null, null, { forwardRef: true })(
  LuckyDrawListingPanelContainer
);
