import { selectLoading } from "modules/notification";
import { getCampaignTypeData } from "modules/reporting/redux";
import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CampaignTrendingPanel from './campaignTrendingPanel.component';

export default function CampaignTrendingPanelContainer({
    currentFilter,
    urlCampaignSummary,
    translate
}){
    const dispatch = useDispatch();

    // (1) get campaign type data -- start
    const isFetchingCampaignTypeData = useSelector(state => selectLoading(state, getCampaignTypeData.typePrefix));
    const campaignTypeData = useSelector(state => state.reporting.campaign.campaignTypeData);
    // (1) get campaign type data -- end

    useEffect(() => {
        dispatch(getCampaignTypeData({
            countryCode: currentFilter.countryCode,
            city_uuid: currentFilter.cityUuid,
            branch_uuid: currentFilter.branchUuid,
            period: currentFilter.period,
            start_date: moment(currentFilter.dateRange[0]).format('YYYY-MM-DD'),
            end_date: moment(currentFilter.dateRange[1]).format('YYYY-MM-DD'),
            url: urlCampaignSummary,

            campaign_uuid: currentFilter.campaignUuid,
            campaign_type: currentFilter.campaignType
        }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, currentFilter]);

    return (
        <CampaignTrendingPanel 
            status= {{
                isFetchingCampaignTypeData
            }}

            data = {{
                campaignTypeData
            }}

            currentFilter={currentFilter}
            
            translate={translate}
        />
    )
}