import { createAsyncThunk } from "@reduxjs/toolkit";
import surveyApi from "app/api/survey";
import { SURVEY } from "modules/survey/constants";

export const fetchSurveyListing = createAsyncThunk(
  `${SURVEY}/fetchSurveyListing`,
  async (payload, { rejectWithValue }) => {
    return surveyApi
      .getAllSurvey({
        length: payload.length,
        start: payload.start,
        column: payload.column,
        order: payload.order,
        survey_name: payload.search,
        status: payload.filterStatus,
        created_at: payload.dateCreated,
        product_id: payload.productSelect,
      })
      .then((response) => response)
      .catch((error) => rejectWithValue(error));
  }
);

export const changeSurveyStatus = createAsyncThunk(
  `${SURVEY}/changeSurveyStatus`,
  async (payload, { rejectWithValue }) => {
    return surveyApi
      .updateSurveyStatus({
        id: payload.id,
        status: payload.status,
      })
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const createSurvey = createAsyncThunk(
  `${SURVEY}/createSurvey`,
  async (payload, { rejectWithValue }) => {
    return surveyApi
      .createSurvey({
        name: payload.name,
        description: payload.description,
        startDate: payload.startDate,
        endDate: payload.endDate,
        productIds: payload.productIds,
        questions: payload.questions,
      })
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const getSurvey = createAsyncThunk(
  `${SURVEY}/getSurvey`,
  async (payload, { rejectWithValue }) => {
    return surveyApi
      .getSurvey({
        id: payload.id,
      })
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const editSurvey = createAsyncThunk(
  `${SURVEY}/editSurvey`,
  async (payload, { rejectWithValue }) => {
    return surveyApi
      .updateSurvey({
        id: payload.id,
        name: payload.name,
        description: payload.description,
        startDate: payload.startDate,
        endDate: payload.endDate,
        productIds: payload.productIds,
        questions: payload.questions,
      })
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const fetchRespondentListing = createAsyncThunk(
  `${SURVEY}/fetchRespondentListing`,
  async (payload, { rejectWithValue }) => {
    return surveyApi
      .getRespondentListing({
        length: payload.length,
        start: payload.start,
        column: payload.column,
        order: payload.order,
        product_id: payload.productSelect,
        created_at: payload.dateCreated,
        search: payload.search,
        uuid: payload.uuid,
      })
      .then((response) => response)
      .catch((error) => rejectWithValue(error));
  }
);

export const fetchRespondentDetails = createAsyncThunk(
  `${SURVEY}/fetchRespondentDetails`,
  async (payload, { rejectWithValue }) => {
    return surveyApi
      .getRespondentDetails({
        uuid: payload.id,
      })
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const fetchRespondentQuestionListing = createAsyncThunk(
  `${SURVEY}/fetchRespondentQuestionListing`,
  async (payload, { rejectWithValue }) => {
    return surveyApi
      .getRespondentQuestionList({
        uuid: payload.uuid,
      })
      .then((response) => response)
      .catch((error) => rejectWithValue(error));
  }
);

export const fetchRespondentAnswerListing = createAsyncThunk(
  `${SURVEY}/fetchRespondentAnswerListing`,
  async (payload, { rejectWithValue }) => {
    return surveyApi
      .getRespondentAnswerList({
        survey_uuid: payload.survey_uuid,
        question_uuid: payload.question_uuid,
        length: payload.length,
        start: payload.start,
        column: payload.column,
        order: payload.order,
        search: payload.search,
        product_id: payload.productSelect,
      })
      .then((response) => response)
      .catch((error) => rejectWithValue(error));
  }
);

export const fetchTotalResponse = createAsyncThunk(
  `${SURVEY}/fetchTotalResponse`,
  async (payload, { rejectWithValue }) => {
    return surveyApi
      .getTotalResponse({
        survey_uuid: payload.survey_uuid,
        last_period: payload.last_period,
      })
      .then((response) => response)
      .catch((error) => rejectWithValue(error));
  }
);

export const fetchTotalVisit = createAsyncThunk(
  `${SURVEY}/fetchTotalVisit`,
  async (payload, { rejectWithValue }) => {
    return surveyApi
      .getTotalVisit({
        survey_uuid: payload.survey_uuid,
        last_period: payload.last_period,
      })
      .then((response) => response)
      .catch((error) => rejectWithValue(error));
  }
);

export const fetchSurveyResponseCount = createAsyncThunk(
  `${SURVEY}/fetchResponseCount`,
  async (payload, { rejectWithValue }) => {
    return surveyApi
      .getResponse({
        survey_uuid: payload.survey_uuid,
        last_period: payload.last_period,
      })
      .then((response) => response)
      .catch((error) => rejectWithValue(error));
  }
);

export const fetchSurveyByProduct = createAsyncThunk(
  `${SURVEY}/fetchSurveyByProduct`,
  async (payload, { rejectWithValue }) => {
    return surveyApi
      .getSurveyByProduct({
        survey_uuid: payload.survey_uuid,
        last_period: payload.last_period,
      })
      .then((response) => response)
      .catch((error) => rejectWithValue(error));
  }
);

export const fetchSurveyByGender = createAsyncThunk(
  `${SURVEY}/fetchSurveyByGender`,
  async (payload, { rejectWithValue }) => {
    return surveyApi
      .getSurveyByGender({
        survey_uuid: payload.survey_uuid,
        last_period: payload.last_period,
      })
      .then((response) => response)
      .catch((error) => rejectWithValue(error));
  }
);

export const fetchSurveyByDevice = createAsyncThunk(
  `${SURVEY}/fetchSurveyByDevice`,
  async (payload, { rejectWithValue }) => {
    return surveyApi
      .getSurveyByDevice({
        survey_uuid: payload.survey_uuid,
        last_period: payload.last_period,
      })
      .then((response) => response)
      .catch((error) => rejectWithValue(error));
  }
);

export const fetchSurveyGeoCount = createAsyncThunk(
  `${SURVEY}/fetchSurveyGeoCount`,
  async (payload, { rejectWithValue }) => {
    return surveyApi
      .getSurveyGeoCount({
        survey_uuid: payload.survey_uuid,
        last_period: payload.last_period,
      })
      .then((response) => response)
      .catch((error) => rejectWithValue(error));
  }
);

export const fetchSurveyByAge = createAsyncThunk(
  `${SURVEY}/fetchSurveyByAge`,
  async (payload, { rejectWithValue }) => {
    return surveyApi
      .getSurveyByAge({
        survey_uuid: payload.survey_uuid,
        last_period: payload.last_period,
      })
      .then((response) => response)
      .catch((error) => rejectWithValue(error));
  }
);

export const fetchResponseByChoiceCount = createAsyncThunk(
  `${SURVEY}/fetchResponseByChoiceCount`,
  async (payload, { rejectWithValue }) => {
    return surveyApi
      .getTotalResponseByChoiceCount({
        surveyUuid: payload.surveyUuid,
        questionUuid: payload.questionUuid,
      })
      .then((response) => response)
      .catch((error) => rejectWithValue(error));
  }
);

export const deleteSurvey = createAsyncThunk(
  `${SURVEY}/deleteSurvey`,
  async (payload, { rejectWithValue }) => {
    return surveyApi
      .deleteSurvey({
        id: payload.id,
      })
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const getAvailableSurveys = createAsyncThunk(
  `${SURVEY}/getAvailableSurveys`,
  async (payload, { rejectWithValue }) => {
    return surveyApi
      .getSurveyDropdown({
        length: payload.length,
        start: payload.start,
        survey_name: payload.search,
        product_id: payload.product_id,
      })
      .then((response) => response)
      .catch((error) => rejectWithValue(error));
  }
);
