import { selectLoading } from "modules/notification";
import { getRespondentsSingleCampaignDatalist } from "modules/reporting/redux";
import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import RespondentSingleCampaignPanel from './respondentSingleCampaignPanel.component';
import { fetchRespondentDetails } from "modules/survey/redux/action";
import { ViewRespondentDialog } from "modules/survey/components/dialog";
import { resetRespondentState } from "modules/survey/redux/slice";

export default function RespondentSingleCampaignPanelContainer({
    currentFilter,
    translate,
    pageType,
    urlRespondent
}){
    const dispatch = useDispatch();
    const resetDispatch = useDispatch();

    const isFetchingRespondentsSingleCampaignDatalist = useSelector(state => selectLoading(state, getRespondentsSingleCampaignDatalist.typePrefix));
    const respondentsSingleCampaignDatalist = useSelector(state => state.reporting.campaign.respondentsSingleCampaignDatalist);
    
    const [tableFilter, setTableFilter] = useState({
        page: 0,
        rowsPerPage: 25,
        search: "",
        searchBy: "name",
        orderBy: "created_at",
        orderDirection: "desc",
    })

    useEffect(() => {
        dispatch(getRespondentsSingleCampaignDatalist({
            length: tableFilter.rowsPerPage,
            start: tableFilter.page * tableFilter.rowsPerPage,
            orderBy: tableFilter.orderBy,
            orderDirection: tableFilter.orderDirection,
            search: tableFilter.search,
            searchBy: tableFilter.searchBy,
            countryCode: currentFilter.countryCode,
            city_uuid: currentFilter.cityUuid,
            branch_uuid: currentFilter.branchUuid,
            period: currentFilter.period,
            start_date: moment(currentFilter.dateRange[0]).format('YYYY-MM-DD'),
            end_date: moment(currentFilter.dateRange[1]).format('YYYY-MM-DD'),
            url: urlRespondent,
            campaign_type: currentFilter.campaignType,
            campaign_uuid: currentFilter.campaignUuid
        }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, tableFilter, currentFilter])

    const handleChangePage = (e, newPage) => {
        setTableFilter((prev) => ({
          ...prev,
          page: newPage,
        }))
    }

    const handleChangeRowPerPage = (event) => {
        setTableFilter((prev) => ({
          ...prev,
          page: 0,
          rowsPerPage: event.target.value
        }))
    }

    const handleRequestSort = (event, property) => {
        const isAsc = tableFilter.orderBy === property && tableFilter.orderDirection === 'asc';
        setTableFilter((prev) => ({
            ...prev,
            orderDirection: isAsc ? 'desc' : 'asc',
            orderBy: property,
        }))
    }

    const [ isViewOpen, setIsViewOpen ] = useState(false);
    const handleView = ({ id }) => {
        if(id){
            dispatch(fetchRespondentDetails({ id }))
            setIsViewOpen(true)
        }
    }

    return (
        <>
            <RespondentSingleCampaignPanel 
                status={{
                    isFetchingRespondentsSingleCampaignDatalist,
                }}

                data={{
                    respondentsSingleCampaignDatalist,
                }}

                sort={{
                    valueToOrderBy: tableFilter.orderBy,
                    orderDirection: tableFilter.orderDirection,
                    handleRequestSort: handleRequestSort,
                }}

                pagination={{
                    page: tableFilter.page,
                    rowsPerPage: tableFilter.rowsPerPage,
                    rowsPerPageOptions: [5, 10, 25],
                    handleChangePage: handleChangePage,
                    handleChangeRowPerPage: handleChangeRowPerPage,
                }}

                currentFilter={currentFilter}

                pageType={pageType}
                translate={translate}
                handleView={handleView}
            />
            <ViewRespondentDialog 
                isOpen={isViewOpen}
                handleClose={() => {
                    setIsViewOpen(false)
                    resetDispatch(resetRespondentState());
                }}
            />
        </>

    )
}