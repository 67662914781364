import React from "react";
import PropTypes from "prop-types"
import { Line } from "react-chartjs-2";
import {Chart, Filler} from 'chart.js';
Chart.register(Filler);

const LineChartComponent = ({
  labels,
  data,
}) => {
  return (
    <Line 
      data={{
        labels: [...labels],
        datasets: [{
          data: [...data],
          fill: true,
          borderColor: "#32D583",
          backgroundColor: `rgba(50, 213, 131, 0.5)`,
        }]
      }}
      width={600}
      height={400}
      options={{
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false
          }
        }
      }}
    />
  )
}

LineChartComponent.propTypes = {
  data: PropTypes.array.isRequired,
  labels: PropTypes.array.isRequired
}

LineChartComponent.defaultProps = {
  labels: ['January','Febuary','March','April','May','June','July','August'],
  data: [20, 30, 50, 30, 40, 25, 70, 55, 12]
}

export default LineChartComponent