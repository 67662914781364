import React, { useState } from "react";
import { useDispatch,useSelector } from "react-redux";
import { unwrapResult } from '@reduxjs/toolkit';
import { selectLoading } from 'modules/notification';
import BrandModelSingleSelectComponent from './brandModelSingleSelect.component';
import BrandModelSingleSelectPanelComponent from './branchModelSingleSelectPanel.component';
import CreateBrandModelDialog from 'modules/product-mgmt/components/dialog/createBrandModelDialog';
import { addBrandModel, getBrandModelDropdown} from "modules/product-mgmt/redux/action/brandModels.action";

export default function BrandModelSingleSelectContainer({
  searchable = false,
  onChange,
  brandValue,
  modelValue,
  disabled,
  style,
  isFetching,
  placeholder,
  panelStyle,
  dropdownItem,
  emptyMessage,
  anchorOrigin,
  transformOrigin,
  PaperProps,
  textStyle,
  isEmpty,
  isClearable,
  handleClear,
  handleSearch
}) {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl ] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const [ openChildList, setOpenChildList ] = useState("");
  const isSubmittingAddModel = useSelector((state) => selectLoading(state, addBrandModel.typePrefix))
  const [ isCreateDialogOpen, setIsCreateDialogOpen] = useState(false)
  const lang = useSelector(state => state.constant.languages);
 
  const handleSubmitBrandModel = (value) => {
    dispatch(addBrandModel({
      name: value.brand,
      models: value.models
    }))
      .then(unwrapResult)
      setIsCreateDialogOpen(false)
  }

  const handleCreateBrandModel = (value) => {
    setIsCreateDialogOpen(value)
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
    dispatch(getBrandModelDropdown({
      search:""
    }))
  }

  const handleClose = () => {
    setAnchorEl(null)
    if (searchable)
      handleSearch("")
  }

  const handleChange = (brand, model) => {
    let newBrand = brand;
    let newModel = model;

    if(!model) {
      newModel = {
        id: "-1",
        name: ""
      }
    } else if (modelValue.id === model.id) {
      newModel = {
        id: "-1",
        name: ""
      }
      newBrand = {
        id: "-1",
        name: ""
      }
    }

    onChange(newBrand, newModel);

    handleClose()
  }

  const handleOpenChildList = (value, type) => {
    if (type === "more") {
      setOpenChildList(value)
    } else {
      setOpenChildList("")
    }
    
  }

  const brandSelected = (brand) => {

    if(isEmpty) {
      return false
    }

    if(brand.id === brandValue.id) {
      return true
    } else {
      return false
    }

  } 

  const modelSelected = (item) => {
    if(isEmpty) {
      return false
    }

    if(item.id === modelValue.id) {
      return true
    } else {
      return false
    }
  }

  return (
    <>
      <BrandModelSingleSelectComponent
        handleClick={handleClick}
        handleClear={handleClear}
        brandValue={brandValue}
        modelValue={modelValue}
        isEmpty={isEmpty}
        style={style}
        disabled={disabled}
        placeholder={placeholder}
        id={id}
        open={open}
        textStyle={textStyle}
        isClearable={isClearable}
      />
      <BrandModelSingleSelectPanelComponent
        anchorEl={anchorEl}
        open={open}
        handleClose={handleClose}
        style={panelStyle}
        items={dropdownItem}
        handleSearch={handleSearch}
        isFetching={isFetching}
        searchable={searchable}
        handleChange={handleChange}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        PaperProps={PaperProps}
        openChildList={openChildList}
        handleOpenChildList={handleOpenChildList}
        modelValue={modelValue}
        brandValue={brandValue}
        modelSelected={modelSelected}
        brandSelected={brandSelected}
        emptyMessage={emptyMessage}
        handleAddBrandModel={() => {
          setIsCreateDialogOpen(true);
          handleClose();
        }}
        lang={lang}
      />
      <CreateBrandModelDialog
        isOpen={isCreateDialogOpen}
        handleClose={() => {
          handleCreateBrandModel(false)
        }}
        handleSubmit={handleSubmitBrandModel}
        isSubmittingAddModel={isSubmittingAddModel}
      />
    </>
  )
}