import React, { Fragment, useRef, useState } from "react"
import {
  makeStyles,
  Box,
  Divider,
  Checkbox,
  InputAdornment,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Collapse,
  ListItemIcon,
  Button,
  MenuList,
  Typography,
  MenuItem,
} from "@material-ui/core";
import { AddIcon } from "components/icon";
import Skeleton from "@material-ui/lab/Skeleton";
import InputTextField from 'components/input/inputTextField';
import {
  Search as SearchIcon,
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon
} from "@material-ui/icons"
import { getLang } from "app/feature/constants";

const useStyle = makeStyles((theme) => ({
  paper: {
    width: 425
  },
  button: {
    padding: 0,
    minWidth: 0
  },
  buttonAddBranch: {
    display: "flex",
    padding: 0,
    minWidth: 0
  },
  createLink: {
    padding: theme.spacing(2)
  },
  dropdownItem: {
    padding: 0
  },
  attributeFormContainer: {
    flex: 1,
    border: "1px solid #D0D5DD",
    borderRadius: "8px",
    boxShadow: '0px 12px 16px -4px rgba(16, 24, 40, 0.1), 0px 4px 6px -2px rgba(16, 24, 40, 0.05)'
  },
}));

export default function BulkCreateAttributePanelComponent({
  dropdownItem,
  isFetching,
  value,
  handleSelectAll,
  selectAll,
  handleSelectAttribute,
  handleSelectOptions,
  placeholder,
  searchable,
  handleAttributeSearch,
  handleAddAttribute,
  lang
}) {
  const classes = useStyle()
  const textRef = useRef();
  const [openDropdown, setOpenDropdown] = useState("")

  return (
    <Box className={classes.attributeFormContainer}>
      {(!!isFetching || (!isFetching && !!dropdownItem.length)) && (
        <>
          {searchable && (
            <Fragment>
              <InputTextField
                variant="filled"
                size="small"
                fullWidth
                inputRef={textRef}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end' style={{ paddingRight: 6 }}>
                      <IconButton onClick={() => handleAttributeSearch(textRef.current.value)}>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                  style: {
                    backgroundColor: "#fff",
                    paddingRight: 0,
                    borderRadius: 8
                  }
                }}
                inputProps={{
                  onKeyDown: (e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      handleAttributeSearch(textRef.current.value)
                    }
                  },
                  style: {
                    padding: "8px"
                  }
                }}
                placeholder={placeholder || getLang(lang, "placeholder.SEARCH")}
              />
            </Fragment>
          )}
          <Divider />
          <Box style={{ overflow: "auto" }}>
            {isFetching ? (
              <Box p={1}>
                <Skeleton variant='text' animation="wave" height={35} />
                <Skeleton variant='text' animation="wave" height={35} />
                <Skeleton variant='text' animation="wave" height={35} />
                <Skeleton variant='text' animation="wave" height={35} />
              </Box>
            ) : (
              <div>
                <MenuList
                  id="menu-list-grow"
                  onClick={() => {
                    handleSelectAll()
                  }}
                  style={{ padding: 0 }}
                >
                  <MenuItem style={{ padding: "0px 8px" }}>
                    <Checkbox checked={selectAll} />
                    <Typography
                      variant="inherit"
                      noWrap
                      style={{ paddingLeft: "8px" }}
                    >
                      {getLang(lang, "label.SELECT_ALL_ATTRIBUTES")}
                    </Typography>
                  </MenuItem>
                </MenuList>
                <Divider />
                <Box maxHeight="25vh" overflow="auto">
                  <List>
                    {dropdownItem.map((field, index) => {
                      return (
                        <Fragment key={index}>
                          <ListItem>
                            <ListItemIcon style={{ paddingLeft: "0px", minWidth: "40px" }}>
                              <Checkbox
                                checked={
                                  value.find((v) => v.id === field.id && v.options.length === field.options.length) ? true : false
                                }
                                indeterminate={
                                  value.find((v) => v.id === field.id && v.options.length !== field.options.length) ? true : false
                                }
                                disabled={false}
                                style={{
                                  padding: 0
                                }}
                                onClick={(event) => {
                                  handleSelectAttribute(field, event.target.checked)
                                }}
                              />
                            </ListItemIcon>
                            <ListItemText primary={field.name} style={{ overflowWrap: 'anywhere' }}/>
                            {openDropdown !== "" && field.id === openDropdown ? (
                              <ArrowDropUpIcon onClick={() => {
                                setOpenDropdown("")
                              }} />
                            ) : (
                              <ArrowDropDownIcon onClick={() => {
                                if (field.options.length > 0) {
                                  setOpenDropdown(field.id)
                                }
                              }} />
                            )}
                          </ListItem>
                          <Collapse in={field.id === openDropdown}>
                            <List component="div" disablePadding>
                              {field.options.length > 0 ? field.options.map((option) => {
                                return (
                                  <ListItem key={option.id}>
                                    <ListItemIcon style={{ paddingLeft: "20px", minWidth: "40px", paddingRight: 8 }}>
                                      <Checkbox
                                        checked={
                                          !!value.some((v) => v.options.some((v2) => v2.id === option.id))
                                        }
                                        disableRipple
                                        style={{
                                          padding: 0
                                        }}
                                        onClick={(event) => {
                                          handleSelectOptions(field, option, event.target.checked)
                                        }}
                                      />
                                    </ListItemIcon>
                                    <ListItemText primary={option.value} />
                                  </ListItem>
                                )
                              }) : (
                                <></>
                              )}
                            </List>
                          </Collapse>
                        </Fragment>
                      )
                    })}
                  </List>
                </Box>
              </div>
            )}
          </Box>
          <Divider />
        </>
      )}
      <Box className={classes.createLink} style={{ padding: '5px 10px' }}>
        <Button
          variant="text"
          color="primary"
          startIcon={
            <AddIcon color="secondary" style={{ fontSize: "13px" }} />
          }
          onClick={handleAddAttribute}
          style={{
            marginRight: "8px"
          }}
        >
          {getLang(lang, "label.ADD_NEW")}
        </Button>
      </Box>
    </Box>
  )
}