import React, { useState } from "react";
import ViewPrizeCardComponent from "./viewPrizeCard.component";
import {
  changePrizeStatus,
  fetchAllInstantPrizeLegacy,
  activeOrDeactivePrize
} from "modules/instant-lucky-draw/redux";
import ConfirmationDialog from "components/dialog/confirmation";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { selectLoading } from "modules/notification";
import { INSTANT_LUCKY_DRAW_EDIT } from "lib/constants/accessRights";
import { getLang } from "app/feature/constants";

function ViewPrizeCardContainer({ prizeDetail, handleEnableEdit, AddingPrizeFlag }) {
  const [isDeactivateDialogOpen, setIsDeactivateDialogOpen] = useState(false);
  const [isActivateDialogOpen, setIsActivateDialogOpen] = useState(false);
  const [changeStatusTargetId, setChangeStatusTargetId] = useState("");
  const hasInstantLuckyDrawEditAccessRight = useSelector(state => state.profile.accessRights.includes(INSTANT_LUCKY_DRAW_EDIT))
  const dispatch = useDispatch();
  const isFetching = useSelector((state) =>
    selectLoading(state, fetchAllInstantPrizeLegacy.typePrefix)
  );
  const isStatusUpdating = useSelector((state) =>
    selectLoading(state, changePrizeStatus.typePrefix)
  );
  const lang = useSelector(state => state.constant.languages);

  const handleActiveChange = ({ currentStatus, id }) => {
    setChangeStatusTargetId(id);

    if (currentStatus) {
      setIsDeactivateDialogOpen(true);
    } else {
      setIsActivateDialogOpen(true);
    }
  };

  const handleStatusSubmit = async (status) => {
    dispatch(
      changePrizeStatus({
        id: changeStatusTargetId,
        status: status ? "active" : "inactive"
      })
    )
      .then(unwrapResult)
      .then(dispatch(activeOrDeactivePrize(changeStatusTargetId)))
      .finally(() => {
        setIsDeactivateDialogOpen(false);
        setIsActivateDialogOpen(false);
      });
  };

  return (
    <>
      <ViewPrizeCardComponent
        hasInstantLuckyDrawEditAccessRight={hasInstantLuckyDrawEditAccessRight}
        prizeDetail={prizeDetail}
        handleEnableEdit={handleEnableEdit}
        isFetching={isFetching}
        handleActiveChange={handleActiveChange}
        AddingPrizeFlag={AddingPrizeFlag}
        lang={lang}
      />
      <ConfirmationDialog
        isOpen={isDeactivateDialogOpen}
        handleClose={() => setIsDeactivateDialogOpen(false)}
        handleProceed={() => handleStatusSubmit(false)}
        isLoading={isStatusUpdating}
        type={"danger"}
        description={getLang(lang,"paragraph.DEACTIVATE_PRIZE")}
        title={getLang(lang,"paragraph.DEACTIVATE_PRIZE_QUESTION")}
      />
      <ConfirmationDialog
        isOpen={isActivateDialogOpen}
        handleClose={() => setIsActivateDialogOpen(false)}
        handleProceed={() => handleStatusSubmit(true)}
        isLoading={isStatusUpdating}
        type={"success"}
        description={getLang(lang,"label.ACTIVATE_PRIZE_QUESTION")}
        title={getLang(lang,"label.ACTIVATE_PRIZE")}
      />
    </>
  );
}

export default ViewPrizeCardContainer;
