import { createAsyncThunk } from '@reduxjs/toolkit';
import accountApi from 'app/api/account';
import stockflowApi from 'app/api/stockflow';

export const STOCKFLOWREWARDCENTER = "stockflowRewardCenter";

export const getAllRewardListing = createAsyncThunk(
  `${STOCKFLOWREWARDCENTER}/getAllDealerListing`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi.GetRewardListing({
      length: payload.length,
      start: payload.start,
      order: payload.order,
      search: payload.search,
      nodes: payload.nodes,
      status: payload.status
    })
    .then(response => response.data)
    .catch(error => rejectWithValue(error))
  }  
)

export const updateRewardStatus = createAsyncThunk(
  `${STOCKFLOWREWARDCENTER}/updateRewardStatus`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi.SetRewardUpdateStatus({
      id: payload.id,
      status: payload.status,
    })
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const deleteReward = createAsyncThunk(
  `${STOCKFLOWREWARDCENTER}/deleteReward`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi.DeleteReward({
      id: payload
    })
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const getRewardDetails = createAsyncThunk(
  `${STOCKFLOWREWARDCENTER}/getRewardDetails`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi.rewardDetails.get(payload)
      .then((response) => response)
      .catch((error) => rejectWithValue(error.response));
  }
)

export const createReward = createAsyncThunk(
  `${STOCKFLOWREWARDCENTER}/createReward`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi.rewardDetails.postCreate(payload)
      .then((response) => response)
      .catch((error) => rejectWithValue(error.response));
  }
)

export const updateRewardDetails = createAsyncThunk(
  `${STOCKFLOWREWARDCENTER}/updateRewardDetails`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi.rewardDetails.postUpdate(payload)
      .then((response) => response)
      .catch((error) => rejectWithValue(error.response));
  }
)

export const getRewardBranchDropdown = createAsyncThunk(
  `${STOCKFLOWREWARDCENTER}/getBranchDropdown`,
  async (payload, { rejectWithValue }) => {
    return accountApi.branchDropdown()
      .then(response => response)
      .catch(error => rejectWithValue(error));
  }
)

export const getAdminRewardBranchDropdown = createAsyncThunk(
  `${STOCKFLOWREWARDCENTER}/getAdminBranchDropdown`,
  async (payload, { rejectWithValue }) => {
    return accountApi.BranchAdminDropdown()
      .then(response => response)
      .catch(error => rejectWithValue(error));
  }
)

export const fetchJSONURL = createAsyncThunk(
  `${STOCKFLOWREWARDCENTER}/fetchJSONURL`,
  async (payload, thunkApi) => {
    return fetch(payload)
      .then((res) => res.json())
      .then((out) => {
        return out;
      })
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const getRedemptionList = createAsyncThunk(
  `${STOCKFLOWREWARDCENTER}/getRedemptionList`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi.getRedemptionList({
      id: payload.id,
      length: payload.length,
      start: payload.start,
      search: payload.search,
      order: payload.order      
    })
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response));
  }
)

export const getRedemptionDetails = createAsyncThunk(
  `${STOCKFLOWREWARDCENTER}/getRedemptionDetails`,
  async (payload, {rejectWithValue}) => {
    return stockflowApi.getRedemptionDetails({
      id: payload.id
    })
      .then((response) => response)
      .catch(error => rejectWithValue(error.response))
  }
)

export const respondRedemption = createAsyncThunk(
  `${STOCKFLOWREWARDCENTER}/respondRedemption`,
  async (payload, {rejectWithValue}) => {
    return stockflowApi.respondRedemption({
      status: payload.status,
      remarks: payload.remarks,
      id: payload.id
    })
      .then((response) => response)
      .catch(error => rejectWithValue(error.response))
  }
)

export const bulkUpdateRewardStatus = createAsyncThunk(
  `${STOCKFLOWREWARDCENTER}/bulkUpdateRewardStatus`,
  async (payload, {rejectWithValue}) => {
    return stockflowApi.BulkUpdateRewardStatus(payload)
      .then(response => response.data)
      .catch(error => rejectWithValue(error))
  }
)