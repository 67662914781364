import React, { useRef } from "react";
import {
  makeStyles,
  Typography,
  Box,
  CircularProgress,
  Paper,
  Link,
  Button,
  IconButton,
} from "@material-ui/core";
import {
  ChevronRightRounded as ChevronRightRoundedIcon,
  Edit as EditIcon,
} from "@material-ui/icons";
import { BackButton } from "components/button";
import RefreshTable from "components/table/refreshTable";
import { getLang } from "app/feature/constants";
import DefaultImage from "assets/img/defaultPhoto.png";
import { useHistory } from "react-router-dom";
import CheckoutItemListPanel from "modules/stockflow/components/panel/checkoutItemListPanel";
import moment from "moment";
import DeliveryInfoPanel from "modules/stockflow/components/panel/deliveryInfoPanel";
import DeliveryDetailInputPanel from "modules/stockflow/components/panel/deliveryDetailInputPanel";
import "moment/min/locales.min";
import InputTextField from "components/input/inputTextField";
import DOMPurify from "dompurify";

const useStyle = makeStyles((theme) => ({
  panelContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridGap: "1em",
    gridTemplateAreas: "'left-panel right-panel'",
    [theme.breakpoints.down("sm")]: {
      gridTemplateAreas: "'left-panel left-panel''right-panel right-panel'",
    },
  },
  field: {
    display: "grid",
    gridTemplateColumns: "2fr 3fr",
    columnGap: theme.spacing(2),
  },
  panel: {
    backgroundColor: "#FFFFFF",
    borderRadius: 4,
    padding: 16,
    marginBottom: 16,
  },
  respondProfile: {
    height: 32,
    width: 32,
    minWidth: 32,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& img": {
      height: "100%",
      minHeight: "100%",
      minWidth: "100%",
      objectFit: "cover",
      border: "1px solid #D0D5DD",
      borderRadius: "50%",
    },
  },
  greyText: {
    color: "#98A2B3",
  },
  sectionCard: {
    backgroundColor: "#f7f7f7",
    padding: 15,
  },
  inputRootID: {
    paddingTop: "8px !important",
    paddingBottom: "7px !important",
    paddingLeft: "12px !important",
    paddingRight: "12px !important",
    borderRadius: 8,
  },
  deliveredCard: {
    background: "linear-gradient(180deg, #90D68D 0%, #6AAF68 100%)",
    borderRadius: 4,
    color: "white",
    padding: "15px",
  },
}));

export default function CheckoutDetailsComponent({
  checkout,
  isFetching,
  isError,
  handleReload,
  lang,
  id,
  updateDelivery,
  isUpdating,
  hasDeliveryEditAccessRight,
  isEdit,
  language,
  setIsEdit,
  fetchDeliveryInfo,
  setIsEditRemark,
  isEditRemark,
  handleUpdateRemark,
  isUpdatingRemark,
}) {
  const classes = useStyle();
  const history = useHistory();

  if (language === "ZH") {
    moment.locale("zh-cn");
  } else if (language === "TL") {
    moment.locale("tl-ph");
  } else {
    moment.locale(language);
  }
  const textRef = useRef("");

  const generateLink = (remark) => {
    let urlRegex = /(https?:\/\/[^\s]+)/g;
    let textWithLink = remark.replace(urlRegex, (url) => {
      return '<a href="' + url + '" target="_blank">' + url + "</a>";
    });

    const sanitizedHtmlContent = DOMPurify.sanitize(textWithLink);

    return (
      <Box
        dangerouslySetInnerHTML={{
          __html: sanitizedHtmlContent,
        }}
      />
    );
  };

  return (
    <Box className={classes.root}>
      <Box gridArea="topbar" mb={3}>
        <Box
          mb={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="flex" alignItems="flex-end" fontWeight="bold">
            <BackButton />
            <Box display="flex" ml={1} alignItems="flex-end">
              <Typography variant="h6" color="primary">
                {getLang(lang, "label.CHECKOUT_TAB")}
              </Typography>
              <Box mx={0.5}>
                <ChevronRightRoundedIcon color="disabled" />
              </Box>
              <Box>
                <Typography variant="h6" color="primary">
                  {getLang(lang, "label.CHECKOUT_DETAILS")}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box gridArea="panel">
        {!isFetching && !isError && checkout && (
          <>
            <Box className={classes.panelContainer}>
              <Box gridArea="left-panel">
                <Paper className={classes.panel} elevation={3}>
                  <Box>
                    <Typography className={classes.greyText}>
                      {getLang(lang, "label.CHECKOUT_BRANCH")}
                    </Typography>
                  </Box>
                  <Box mb={1.5}>
                    <Typography variant="h6">
                      <b>{checkout.branch}</b>
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center" mb={1}>
                    <Typography>
                      {getLang(lang, "label.CHECKOUT_ID")}:
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{ textAlign: "left", marginLeft: 8 }}
                    >
                      {checkout.reference_code}
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center" mb={1}>
                    <Typography>
                      {getLang(lang, "label.CHECKOUT_BY")}:
                    </Typography>
                    <Box display="flex" alignItems="center" ml={1}>
                      <Box className={classes.respondProfile} mr={1}>
                        {checkout.checkout_by?.picture ? (
                          <img
                            src={checkout.checkout_by.picture}
                            alt={checkout.checkout_by.name}
                          />
                        ) : (
                          <img src={DefaultImage} alt="default profile" />
                        )}
                      </Box>
                    </Box>
                    <Link
                      href={`/admin/employee/View/${checkout.checkout_by?.uuid}`}
                      color="secondary"
                      onClick={(e) => {
                        history.push(
                          `/admin/employee/View/${checkout.checkout_by?.uuid}`
                        );
                        e.preventDefault();
                        return false;
                      }}
                    >
                      <Typography variant="body1" style={{ textAlign: "left" }}>
                        {checkout.checkout_by?.name ?? "-"}
                      </Typography>
                    </Link>
                  </Box>
                  <Box>
                    <Typography variant="body2" className={classes.greyText}>
                      {moment.utc(checkout.created_at).local().format("lll")}
                    </Typography>
                  </Box>
                </Paper>
                <Paper className={classes.panel} elevation={3}>
                  <Box>
                    <Typography>
                      <b>{getLang(lang, "label.CONSUMER_INFO")}</b>
                    </Typography>
                  </Box>
                  <Box className={classes.field} mt={2}>
                    <Typography variant="body1">
                      {getLang(lang, "label.NAME")}
                    </Typography>
                    <Box display="flex">
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        style={{ marginRight: 3 }}
                      >
                        :{" "}
                      </Typography>
                      <Typography variant="body1" color="textSecondary">
                        <span>{checkout.checkout_to?.name ?? "-"}</span>
                      </Typography>
                    </Box>
                  </Box>
                  <Box className={classes.field} mt={2}>
                    <Typography variant="body1">
                      {getLang(lang, "label.CONTACT_NUMBER")}
                    </Typography>
                    <Box display="flex">
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        style={{ marginRight: 3 }}
                      >
                        :{" "}
                      </Typography>
                      <Typography variant="body1" color="textSecondary">
                        {checkout.checkout_to?.phone_number ? (
                          <>
                            <span>{checkout.checkout_to?.phone_code} </span>
                            <span>{checkout.checkout_to?.phone_number}</span>
                          </>
                        ) : (
                          "-"
                        )}
                      </Typography>
                    </Box>
                  </Box>
                  <Box className={classes.field} mt={2}>
                    <Typography variant="body1">
                      {getLang(lang, "label.EMAIL")}
                    </Typography>
                    <Box display="flex">
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        style={{ marginRight: 3 }}
                      >
                        :{" "}
                      </Typography>
                      <Typography variant="body1" color="textSecondary">
                        <span>{checkout.checkout_to?.email ?? "-"}</span>
                      </Typography>
                    </Box>
                  </Box>
                </Paper>
                {!checkout.delivery_info && (
                  <Paper className={classes.panel} elevation={3}>
                    <DeliveryDetailInputPanel
                      handleSubmit={updateDelivery}
                      isUpdating={isUpdating}
                      deliveryInfo={null}
                      handleCancel={() => {}}
                      hasDeliveryEditAccessRight={hasDeliveryEditAccessRight}
                    />
                  </Paper>
                )}
                <Paper className={classes.panel} elevation={3}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography>
                      <b>{getLang(lang, "label.REMARK")}</b>
                    </Typography>
                    {!isEditRemark && (
                      <IconButton
                        size="small"
                        aria-label="option"
                        onClick={() => {
                          textRef.current = { value: checkout.remark ?? "" };
                          setIsEditRemark(true);
                        }}
                        style={{ marginLeft: 5 }}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                    )}
                  </Box>
                  <Box mt={2}>
                    {isEditRemark ? (
                      <InputTextField
                        multiline
                        fullWidth
                        rows={6}
                        name="remark"
                        placeholder={getLang(
                          lang,
                          "placeholder.ENTER_REMARKS_HERE"
                        )}
                        inputRef={textRef}
                        defaultValue={checkout.remark ?? ""}
                        inputProps={{
                          style: {
                            fontSize: "14px",
                            padding: "7px",
                          },
                        }}
                        InputStyle={{
                          backgroundColor: "white",
                          border: "1px solid #d0d5dd",
                        }}
                      />
                    ) : (
                      <Typography style={{ whiteSpace: "break-spaces" }}>
                        {checkout.remark ? generateLink(checkout.remark) : "-"}
                      </Typography>
                    )}
                  </Box>
                  {isEditRemark && (
                    <Box display="flex" justifyContent="flex-end" mt={1}>
                      <Button
                        variant="text"
                        disableElevation
                        onClick={() => setIsEditRemark(false)}
                        style={{ marginRight: "4px" }}
                      >
                        {getLang(lang, "label.CANCEL")}
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        classes={{ root: classes.publishButton }}
                        disableElevation
                        type="button"
                        onClick={() => handleUpdateRemark(textRef.current)}
                      >
                        {isUpdatingRemark ? (
                          <CircularProgress
                            size={20}
                            style={{ color: "white" }}
                          />
                        ) : (
                          <Typography>{getLang(lang, "label.SAVE")}</Typography>
                        )}
                      </Button>
                    </Box>
                  )}
                </Paper>
                {!!checkout.delivery_info && (
                  <Paper className={classes.panel} elevation={3}>
                    <Box>
                      <Typography>
                        <b>{getLang(lang, "label.DELIVERY_INFO")}</b>
                      </Typography>
                    </Box>
                    {checkout.delivery_info.status === "delivered" && (
                      <Box mt={2} className={classes.deliveredCard}>
                        <Typography variant="body1">
                          <b>{getLang(lang, `label.DELIVERED`)}</b>
                        </Typography>
                        <Typography variant="body2">
                          {getLang(lang, "paragraph.DELIVERED_DESC")}
                        </Typography>
                      </Box>
                    )}
                    {isEdit ? (
                      <DeliveryDetailInputPanel
                        handleSubmit={updateDelivery}
                        isUpdating={isUpdating}
                        deliveryInfo={checkout.delivery_info}
                        handleCancel={() => setIsEdit(false)}
                        hasDeliveryEditAccessRight={hasDeliveryEditAccessRight}
                      />
                    ) : (
                      <Box
                        mt={2}
                        className={classes.sectionCard}
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Box>
                          <Box>
                            <Typography
                              variant="body1"
                              display="inline"
                              className={classes.greyText}
                            >
                              {getLang(lang, "label.TRACKING_NUMBER")}:&nbsp;
                            </Typography>
                            <Typography
                              variant="body1"
                              style={{ color: "#6AAF68" }}
                              display="inline"
                            >
                              <b>{checkout.delivery_info.tracking ?? "-"}</b>
                            </Typography>
                          </Box>
                          <Box mt={1}>
                            <Typography
                              variant="body2"
                              className={classes.greyText}
                              display="inline"
                            >
                              {getLang(lang, "label.COURIER_COMPANY")}:&nbsp;
                            </Typography>
                            <Typography variant="body2" display="inline">
                              <b>
                                {checkout.delivery_info.courier_name ?? "-"}
                              </b>
                            </Typography>
                          </Box>
                        </Box>
                        {hasDeliveryEditAccessRight && (
                          <Box>
                            <Button
                              variant="text"
                              color="secondary"
                              onClick={() => setIsEdit(true)}
                            >
                              {getLang(lang, "label.CHANGE")}
                            </Button>
                          </Box>
                        )}
                      </Box>
                    )}
                    {!!checkout.delivery_info.courier_code && (
                      <Box
                        mt={2}
                        className={classes.sectionCard}
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <DeliveryInfoPanel
                          fetchData={fetchDeliveryInfo}
                          storeName="stockflowCheckout"
                        />
                      </Box>
                    )}
                  </Paper>
                )}
              </Box>
              <Box gridArea="right-panel">
                <CheckoutItemListPanel id={id} />
              </Box>
            </Box>
          </>
        )}
        {!isFetching && isError && (
          <Box className={classes.tabContainer}>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <RefreshTable handleReload={handleReload} />
            </Box>
          </Box>
        )}
        {isFetching && (
          <Box gridArea="main" mt={6} style={{ textAlign: "center" }}>
            <CircularProgress color="primary" />
          </Box>
        )}
      </Box>
    </Box>
  );
}
