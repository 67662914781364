import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import QueryString from "query-string";
import SentResetPasswordPanelComponent from "./sentResetPasswordPanel.component";
import { isEmail } from "lib/generalUtility";
import { useDispatch, useSelector } from "react-redux";
import { checkResetPasswordResendTimeLeft, requestResetPassword } from "modules/public/redux/action";
import { selectLoading } from "modules/notification";

function SentResetPasswordPanelContainer() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const isLoading = useSelector((state) => selectLoading(state, checkResetPasswordResendTimeLeft.typePrefix));
  const resetPasswordResendTimeLeft = useSelector(state => state.resetPassword.resetPasswordResendTimeLeft)
  const lang = useSelector(state => state.constant.languages)
  const [counter, setCounter] = useState(60);

  useEffect(() => {
    const params = QueryString.parse(location.search);
    if( params.email && isEmail(params.email) ){
      dispatch(checkResetPasswordResendTimeLeft(params.email))
    }else{
      history.push('/reset-password')
    }
  }, [dispatch, history, location])


  useEffect(() => {
    setCounter(resetPasswordResendTimeLeft)
  }, [resetPasswordResendTimeLeft]);

  useEffect(() => {
    if(counter > 0 ){
      const timer = setInterval(() => setCounter(counter - 1), 1000);
      return () => clearInterval(timer);
    }
  }, [counter])

  if(isLoading){
    return "Loading";
  }

  const handleClick = () => {
    history.push('/reset-password')
  }

  const handleSubmit = (email) => {
    dispatch(requestResetPassword(email))
      .then(() => {
        setCounter(60);
      })
  }

  return (    
    <SentResetPasswordPanelComponent
      isLoading={isLoading}
      email={QueryString.parse(location.search).email}
      counter={counter}
      enableResend={counter === 0}
      handleClick={handleClick}ß
      handleSubmit={handleSubmit}
      lang={lang}
    />
  )
}

export default SentResetPasswordPanelContainer;
