import { borderStyle, chipStyle, fontTextStyle } from "modules/reporting/styles";
import React from "react";
import { ContentCard } from "../../layout";
import { Box, Chip, Grid, Typography } from "@material-ui/core";
import { responseHandler } from "modules/reporting/utils/helper";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { BoxError } from "../../skeleton/error";
import { BoxLoading } from "../../skeleton/loading";

export default function BranchTitleComponent({
    status,
    data,
    translate
}){
    const fontClass = fontTextStyle();
    const chipClasses = chipStyle();
    const borderClass = borderStyle();

    const renderData = (ref) => {
        if(!ref) return null;
        if (Object.keys(ref).length === 0 || !ref.isSuccess){
            return null;
        }
        const branch_data = ref.branch_summary;

        return (
            <Grid container spacing={3}>
                {/* branch detail: 1st part */}
                <Grid item xs={12} sm={12} md={4} >
                    <Box
                        display="flex"
                        flexDirection="column"
                        style={{
                            gap: "6px"
                        }}
                    >
                        <Chip label={translate("label.BRANCH")} className={chipClasses.chip__branch} />
                        <Typography
                            className={fontClass.text__detail_title}
                        >
                            {branch_data.name}
                        </Typography>
                        <Chip 
                            icon={<FiberManualRecordIcon className={fontClass.text__success}/>}
                            label={branch_data.status ? translate("label.PUBLISHED") : translate("label.UNPUBLISHED")}
                            className={chipClasses.chip__publish_success}
                        />
                    </Box>
                </Grid>

                {/* branch detail: 2nd part */}
                <Grid item xs={12} sm={12} md={4} 
                    className={borderClass.border__middle_detail}
                    alignItems="center"
                >
                    <Grid container spacing={3} alignItems="center" >
                        <Grid item xs={7}>
                            <Typography className={fontClass.text__detail_subtitle}>
                                {translate("label.DIRECT_DEALER")}
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography className={fontClass.text__detail_subtitle}>
                                :
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography className={fontClass.text__detail_subtitle_value}>
                                {branch_data.direct_dealer_count}
                            </Typography>
                        </Grid>
                    </Grid> 
                    <Grid container spacing={3} alignItems="center" >
                        <Grid item xs={7}>
                            <Typography className={fontClass.text__detail_subtitle}>
                                {translate("label.TOTAL_DEALER")}
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography className={fontClass.text__detail_subtitle}>
                                :
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography className={fontClass.text__detail_subtitle_value}>
                                {branch_data.total_dealer_count}
                            </Typography>
                        </Grid>
                    </Grid> 
                    <Grid container spacing={3} alignItems="center" >
                        <Grid item xs={7}>
                            <Typography className={fontClass.text__detail_subtitle}>
                                {translate("label.SUB_BRANCHES_DEALER")}
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography className={fontClass.text__detail_subtitle}>
                                :
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography className={fontClass.text__detail_subtitle_value}>
                                {branch_data.sub_branch_dealer_count}
                            </Typography>
                        </Grid>
                    </Grid> 
                </Grid>

                {/* branch detail : 3rd part */}
                <Grid item xs={12} sm={12} md={4} 
                    className={borderClass.border__middle_detail}
                    alignItems="center"
                >
                    <Grid container spacing={3} alignItems="center" >
                        <Grid item xs={4}>
                            <Typography className={fontClass.text__detail_subtitle}>
                                {translate("label.CREATED_DATE")}
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography className={fontClass.text__detail_subtitle}>
                                :
                            </Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <Typography className={fontClass.text__detail_subtitle_value}>
                                {branch_data.created_date}
                            </Typography>
                        </Grid>
                    </Grid> 
                </Grid>
            </Grid>
        )
    }
    return (
        <ContentCard>
            <Box padding="12px 10px">
                {
                    responseHandler(
                        data.branchTitleData,
                        status.isFetchingBranchTitleData,
                        (
                            renderData(data.branchTitleData)
                        ),
                        (
                            <BoxLoading />
                        ),
                        (
                            <>empty</>
                        ),
                        (
                            <>no result</>
                        ),
                        (
                            <BoxError 
                                title={translate("label.LOADING_FAILED")}
                                value={translate("paragraph.LOADING_FAILED_DESCRIPTION")}
                            />
                        )
                    )
                }
            </Box>
        </ContentCard>
    )
}