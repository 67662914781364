/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import RequestTransferProductListPanelComponent from "./requestTransferProductListPanel.component";
import {
  getRequestStockList,
  resetRequestStockList,
} from "modules/stockflow/redux";
import { selectLoading } from "modules/notification";
import { useRef } from "react";

export default function RequestTransferProductListPanelContainer({
  fromUuid,
  toUuid,
  selectProducts,
  products,
}) {
  const lang = useSelector((state) => state.constant.languages);
  const dispatch = useDispatch();

  const isFetching = useSelector((state) =>
    selectLoading(state, getRequestStockList.typePrefix)
  );
  const totalFiltered = useSelector(
    (state) => state.stockflowRequestTransfer.stockList.totalFiltered
  );
  const stockList = useSelector(
    (state) => state.stockflowRequestTransfer.stockList.list
  );
  const isError = useSelector(
    (state) => state.stockflowRequestTransfer.stockList.isError
  );

  const [filter, setFilter] = useState({
    page: 0,
    rowsPerPage: 25,
  });

  const fromUuidRef = useRef(fromUuid);
  const toUuidRef = useRef(toUuid);

  useEffect(() => {
    return () => {
      dispatch(resetRequestStockList());
    };
  }, [dispatch]);

  useEffect(() => {
    if (fromUuid && toUuid) {
      if (fromUuid !== fromUuidRef.current || toUuid !== toUuidRef.current) {
        handleReload();
      } else {
        fetchData();
      }
    }
    fromUuidRef.current = fromUuid;
    toUuidRef.current = toUuid;
  }, [fromUuid, toUuid, filter]);

  const fetchData = () => {
    dispatch(
      getRequestStockList({
        length: filter.rowsPerPage,
        start: stockList.length ? filter.rowsPerPage * filter.page : 0,
        from_node_id: fromUuid,
        to_node_id: toUuid,
      })
    );
  };

  const handleFetchMoreItems = () => {
    setFilter({
      ...filter,
      page: filter.page + 1,
    });
  };

  const handleReload = () => {
    dispatch(resetRequestStockList());
    setFilter({
      ...filter,
      page: 0,
    });
  };

  const updateQuantity = (item, quantity) => {
    let temp = { ...products };
    if (parseInt(quantity) > 0) {
      temp[item.uuid] = parseInt(quantity);
    } else {
      if (temp[item.uuid]) {
        delete temp[item.uuid];
      }
    }
    selectProducts(temp);
  };

  return (
    <>
      <RequestTransferProductListPanelComponent
        lang={lang}
        isFetching={isFetching}
        list={stockList}
        totalFiltered={totalFiltered}
        isError={isError}
        handleFetchMoreItems={handleFetchMoreItems}
        handleReload={handleReload}
        updateQuantity={updateQuantity}
        products={products}
      />
    </>
  );
}
