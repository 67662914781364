import React, { useState } from "react";
import OrderDetailsFormComponent from "./orderDetailsForm.component";
import { useDispatch, useSelector } from "react-redux";
import { updateOrder } from "modules/loyalty-program/redux";
import { setType } from "modules/loyalty-program/redux/slice/redemptionCenter.slice"
import { unwrapResult } from "@reduxjs/toolkit";
import { hideBackdrop, showBackdrop } from "modules/backdrop";
import OrderSucessDialog from "../../dialog/orderSuccessDialog";
import { redemptionCenterType } from "modules/loyalty-program/constants";
import { GIFT_REDEMPTION_EDIT } from "lib/constants/accessRights";

export default function OrderDetailsFormContainer({ handleClose }) {
  const dispatch = useDispatch();
  const hasGiftRedemptionEditAccessRight = useSelector(state => state.profile.accessRights.includes(GIFT_REDEMPTION_EDIT));
  const [succes, setSuccess] = useState(false);
  const [statusResult, setStatusResult] = useState(null);
  const orderType = useSelector((state) => state.redemptionCenter.orderType);
  const order = useSelector(state => state.redemptionCenter.order)
  const lang = useSelector(state => state.constant.languages)
  const language = useSelector(state => state.profile.language)

  const changeToUpdate = (e) => {
    if(hasGiftRedemptionEditAccessRight){
      e.preventDefault();
      const updateInfo = {
        index: redemptionCenterType.order,
        type: "update",
      };
      dispatch(setType(updateInfo));
    }
  };

const changeBackView = () => {
  const updateInfo = {
    index: redemptionCenterType.point,
    type: "view",
  };
  dispatch(setType(updateInfo));
}

  const handleUpdate = async (values) => {
    if(hasGiftRedemptionEditAccessRight){
      dispatch(showBackdrop());
      await dispatch(updateOrder(values))
        .then(unwrapResult)
        .then(() => {
          dispatch(hideBackdrop());
        })
        .catch((error) => {
          dispatch(hideBackdrop());
        });
      setStatusResult(values.status);
      setSuccess(true)
    }
  };

  return (
    <>
      <OrderDetailsFormComponent
        hasGiftRedemptionEditAccessRight={hasGiftRedemptionEditAccessRight}
        order={order}
        handleClose={handleClose}
        handleSubmit={handleUpdate}
        orderType={orderType}
        changeToUpdate={changeToUpdate}
        lang={lang}
        language={language}
        changeBackView={changeBackView}
      />
      <OrderSucessDialog
        isOpen={succes}
        handleClose={handleClose}
        lang={lang}
        statusResult={statusResult}
      />
    </>
  );
}
