import { addAlert, setLoading } from "modules/notification";
import { getBrowserData, getDeviceData, getLanguageData, getPlatformData } from "../action";

// (1) platform data -- start
export const getPlatformDataMiddleware = ({ dispatch, getState}) => next => action => {
    next(action);
    if (action.type === getPlatformData.pending.type) {
        dispatch(setLoading({ id: getPlatformData.typePrefix, state: true }));
    }
    if (action.type === getPlatformData.fulfilled.type) {
        dispatch(setLoading({ id: getPlatformData.typePrefix, state: false }));
    }
    if (action.type === getPlatformData.rejected.type) {
        dispatch(setLoading({ id: getPlatformData.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: action.payload.message}));
    }
}
// (1) platform data -- end

// (2) browser data -- start
export const getBrowserDataMiddleware = ({ dispatch, getState}) => next => action => {
    next(action);
    if (action.type === getBrowserData.pending.type) {
        dispatch(setLoading({ id: getBrowserData.typePrefix, state: true }));
    }
    if (action.type === getBrowserData.fulfilled.type) {
        dispatch(setLoading({ id: getBrowserData.typePrefix, state: false }));
    }
    if (action.type === getBrowserData.rejected.type) {
        dispatch(setLoading({ id: getBrowserData.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: action.payload.message}));
    }
}
// (2) browser data -- end

// (3) device data -- start
export const getDeviceDataMiddleware = ({ dispatch, getState}) => next => action => {
    next(action);
    if (action.type === getDeviceData.pending.type) {
        dispatch(setLoading({ id: getDeviceData.typePrefix, state: true }));
    }
    if (action.type === getDeviceData.fulfilled.type) {
        dispatch(setLoading({ id: getDeviceData.typePrefix, state: false }));
    }
    if (action.type === getDeviceData.rejected.type) {
        dispatch(setLoading({ id: getDeviceData.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: action.payload.message}));
    }
}
// (3) device data -- end

// (4) language data -- start
export const getLanguageDataMiddleware = ({ dispatch, getState}) => next => action => {
    next(action);
    if (action.type === getLanguageData.pending.type) {
        dispatch(setLoading({ id: getLanguageData.typePrefix, state: true }));
    }
    if (action.type === getLanguageData.fulfilled.type) {
        dispatch(setLoading({ id: getLanguageData.typePrefix, state: false }));
    }
    if (action.type === getLanguageData.rejected.type) {
        dispatch(setLoading({ id: getLanguageData.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: action.payload.message}));
    }
}
// (4) language data -- end

export default [
    getPlatformDataMiddleware,
    getBrowserDataMiddleware,
    getDeviceDataMiddleware,
    getLanguageDataMiddleware,
]