import axios from "axios";

const url = "/consumer/admin/api/v1/item-replacement/handoff";
export function getWarrantyHandoffList(parameters) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, {
        length: parameters.length,
        start: parameters.start,
        filter: {
          search: parameters.filter.search,
          status: parameters.filter.status,
          nodes: parameters.filter.nodes,
          fromDate: parameters.filter.fromDate || "",
          toDate: parameters.filter.toDate || "",
        },
      })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data));
  });
}
