import React, { useEffect, useState } from "react";
import MainPageComponent from "./main.page";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  BRANCH_GROUP_VIEW,
  BRANCH_GROUP_ADD,
} from "lib/constants/accessRights";
import LoadingComponent from "components/loading";
import CreateBranchGroupDialog from "modules/group/components/dialog/createBranchGroupDialog";
import { selectLoading } from "modules/notification";
import { createBranchGroup } from "modules/group/redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { ROOT } from "modules/group/config/group.route";

function MainPageContainer() {
  const history = useHistory();
  const dispatch = useDispatch();
  const hasGroupViewAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(BRANCH_GROUP_VIEW)
  );
  const hasGroupAddAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(BRANCH_GROUP_ADD)
  );
  const isBranchOnly = useSelector((state) =>
    state.profile.nodeName.every((item) => item.remarks === "branch")
  );
  const isSubmittingAddGroup = useSelector((state) =>
    selectLoading(state, createBranchGroup.typePrefix)
  );

  const lang = useSelector((state) => state.constant.languages);

  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);

  useEffect(() => {
    if (!hasGroupViewAccessRight) {
      history.push("/admin/dashboard");
    }
  }, [history, hasGroupViewAccessRight]);

  if (!hasGroupViewAccessRight) return <LoadingComponent />;

  const handleSubmitGroup = (value) => {
    dispatch(createBranchGroup({ name: value.name }))
      .then(unwrapResult)
      .then((result) => {
        setIsCreateDialogOpen(false);
        history.push(`${ROOT}/${result.uuid}`);
      });
  };

  return (
    <>
      <MainPageComponent
        hasGroupAddAccessRight={hasGroupAddAccessRight}
        lang={lang}
        isBranchOnly={isBranchOnly}
        openCreateDialog={() => setIsCreateDialogOpen(true)}
      />
      <CreateBranchGroupDialog
        isOpen={isCreateDialogOpen}
        handleClose={() => setIsCreateDialogOpen(false)}
        handleSubmit={handleSubmitGroup}
        isSubmittingAddCategory={isSubmittingAddGroup}
      />
    </>
  );
}

export default MainPageContainer;
