import React from "react";
import {
  makeStyles,
  Box,
  Typography,
  Button,
  Link
} from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Header from "../../components/header";
import { Link as RouterLink } from "react-router-dom";
import { Formik } from 'formik';
import * as Yup from "yup";
import InputTextField from 'components/input/inputTextField';
import Footer from "../../components/footer"
import { getLang } from "app/feature/constants";


const style = theme => ({
  mainContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh"
  },
  title: {
    fontWeight: "700",
    fontSize: "24px",
    fontFamily: "Montserrat"
  },
  description: {
    fontFamily: "Montserrat",
    fontWeight: 400,
    fontSize: "18px",
  },
  label: {
    fontFamily: "Open Sans"
  },
  root: {
    width: "100%",
  },
  inputRoot: {
    paddingTop: "8px !important",
    paddingBottom: "7px !important",
  },
  button: {
    width: "100%",
    borderRadius: "42px",
    textTransform: "capitalize",
    fontSize: "16px"
  },
  link: {
    fontFamily: "Montserrat",
    fontWeight: 700,
    fontSize: "16px",
  },
  linkContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "40px"
  },
  remark: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    color: "#667085"
  }
});

const useStyle = makeStyles(style);

export default function ForgotPasswordPage({
  handleSubmit,
  isEmailSent,
  email,
  enableResent,
  counter,
  handleResendPassword,
  invitationCode,
  lang
}) {
  const classes = useStyle();

  return (
    <Box className={classes.mainContainer}>
      <Box style={{ width: "502px" }}>
        <Box style={{ marginBottom: "60px" }}>
          <Header justifyContent="center" />
        </Box>
        <Box style={{ padding: "40px 30px 40px 30px", backgroundColor: "#ffffff", borderRadius: "4px", marginBottom: "40px" }}>
          <Box paddingBottom="20px">
            <Typography variant='h5' className={classes.title}>{isEmailSent ? "Check your email" : "Forgot Password?"}</Typography>
          </Box>
          <Box paddingBottom="30px">
            {isEmailSent ? (
              <Typography variant="body1" className={classes.description}>
                {getLang(lang, 'paragraph.WE_SENT_AN_EMAIL', { email: email })}
                {getLang(lang, 'paragraph.CLICK_LINK_UPDATE_NEW_PASSWORD')}
              </Typography>
            ) : (
              <Typography variant="body1" className={classes.description}>
                {getLang(lang, 'paragraph.ENTER_EMAIL_WE_SEND_LINK_TO_RESET_PASSWORD')}
              </Typography>
            )}

          </Box>
          {isEmailSent ? (
            <Box>
              <Box marginBottom="8px">
                <Typography variant='subtitle1' className={classes.remark} style={{ color: '#667085' }}>{getLang(lang, 'paragraph.DIDNT_RECEIVED')}</Typography>
              </Box>
              {!enableResent ? (
                <Box marginBottom="8px">
                  <Typography variant='subtitle2' className={classes.remark} style={{ color: '#ff0000' }}>{getLang(lang, 'paragraph.RESEND_VERIFICATION_LINK', { count: counter })}</Typography>
                </Box>
              ) : null}
              <Box>
                <Button
                  variant='contained'
                  type='submit'
                  size="large"
                  color="primary"
                  className={classes.button}
                  disabled={!enableResent}
                  onClick={handleResendPassword}
                >
                  {getLang(lang, 'label.RESEND')}
                </Button>
              </Box>
            </Box>
          ) : (
            <Formik
              initialValues={{ email: "" }}

              validationSchema={Yup.object({
                email: Yup.string(),
              })}

              onSubmit={handleSubmit}
            >
              {(formik) => (
                <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
                  <Box style={{ paddingBottom: "40px" }}>
                    <Typography variant='body2' className={classes.label}>{getLang(lang, 'label.EMAIL')}</Typography>
                    <InputTextField
                      className={classes.root}
                      variant="filled"
                      size="small"
                      margin="none"
                      InputProps={{
                        disableUnderline: true,
                        margin: "none",
                        style: {
                          borderRadius: "4px",
                          backgroundColor: "#ffffff",
                          border: "1px solid #E6E6E6",
                          boxShadow: "0px 0px 10px 2px rgba(118, 156, 214, 0.1)",
                        },
                        classes: { input: classes.inputRoot }
                      }}
                      placeholder={getLang(lang, 'placeholder.EMAIL')}
                      {...formik.getFieldProps("email")}
                      error={formik.touched.email && formik.errors.email}
                    />
                  </Box>
                  <Box>
                    <Button variant='contained' type='submit' size='large' color='primary' className={classes.button}>
                      {getLang(lang, 'label.RESET_PASSWORD')}
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          )}

        </Box>
        {invitationCode !== "" &&
          <Box className={classes.linkContainer}>
            <ArrowBackIcon style={{ color: "#5A9559", fontSize: "20px" }} />
            <Link
              component={RouterLink}
              variant="body1"
              color="secondary"
              to={`/trackco/${invitationCode}/login`}
              style={{ marginLeft: "8px" }}
            >
              {getLang(lang, "label.BACK")}
            </Link>
          </Box>
        }
        <Box>
          <Footer type="tnc" />
        </Box>
      </Box>
    </Box>
  );
}