import React, { useState } from "react";
import PaymentMethodCardComponent from "./paymentMethodCard.component";
import Dialog from "components/dialog/customDialog";
import AddCreditCardForm from "../../form/addCreditCardForm";
import { connect, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  deletePaymentMethod,
  updateDefaultPaymentMethod
} from "../../../redux";
import usePaymentMethod from "../../../utils/usePaymentMethod.hook";
import { SkeletonPaymentMethodComponent } from "components/skeleton/skeleton.component";
import { ErrorPanel } from "../../panel/errorPanel";
import ConfirmationDialogComponent from "components/dialog/confirmation";
import { addAlert } from "modules/notification";
import { getLang } from "app/feature/constants";

function PaymentMethodCardContainer({
  deletePaymentMethod,
  updateDefaultPaymentMethod,
  addAlert
}) {
  const [isModalOpen, setModalOpen] = useState(false);
  const [
    paymentMethods,
    isFetchingPaymentMethod,
    errorPaymentMethod,
    reload,
    currentPaymentMethod,
    setCurrentPaymentMethod
  ] = usePaymentMethod();

  const [isPrimarySwitchChecked, setIsPrimarySwitchChecked] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const [confirmationDialogModal, setConfirmationDialogModal] = useState(false);
  const [deleteID, setDeleteID] = useState(null);
  const lang = useSelector(state => state.constant.languages)

  const handleChangePrimarySwitch = (currentPayment) => {
  
    setIsPrimarySwitchChecked(!isPrimarySwitchChecked);
    setIsProcessing(true);

    updateDefaultPaymentMethod(currentPayment.id)
      .then(unwrapResult)
      .then((response) => {
        setIsProcessing(false);
        reload();
      })
      .catch((error) => {
        setIsPrimarySwitchChecked(!isPrimarySwitchChecked);
        setIsProcessing(false);
      });
  };

  const handleAddCard = () => {
    setModalOpen(true);
  };

  const handleAddNewCardSuccess = () => {
    reload();
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const closeConfirmationDialog = () => {
    setConfirmationDialogModal(false);
  };

  const handleProceed = () => {
    setIsProcessing(true);

    deletePaymentMethod(deleteID)
      .then(unwrapResult)
      .then((response) => {
        setIsProcessing(false);
        setConfirmationDialogModal(false);
        reload();
      })
      .catch((error) => {
        setIsProcessing(false);
        setConfirmationDialogModal(false);

      });
  };

  const handleDelete = (id) => {
    if (paymentMethods.length === 1) {
      addAlert({
        severity: "warning",
        message: getLang(lang, "message.warning.ADD_CARD_BEFORE_REMOVE_CARD"),
      });
      return;
    }
    setDeleteID(id);
    setConfirmationDialogModal(true);
  };

  const handleCardClick = (card) => {
    setIsPrimarySwitchChecked(card.default)
    setCurrentPaymentMethod(card);
  };

  if (isFetchingPaymentMethod) return <SkeletonPaymentMethodComponent />;
  if (!isFetchingPaymentMethod && errorPaymentMethod)
    return <ErrorPanel handleClick={reload} />;

  return (
    <>
      <PaymentMethodCardComponent
        handleAddCard={handleAddCard}
        isFetching={isFetchingPaymentMethod}
        paymentMethods={paymentMethods}
        handleDelete={handleDelete}
        isPrimarySwitchChecked={isPrimarySwitchChecked}
        handleChangePrimarySwitch={handleChangePrimarySwitch}
        isProcessing={isProcessing}
        handleCardClick={handleCardClick}
        currentPaymentMethod={currentPaymentMethod}
        handleAddNewCardSuccess={handleAddNewCardSuccess}
        lang={lang}
      />
      <Dialog
        isOpen={isModalOpen}
        handleClose={closeModal}
        disableEnforceFocus={true}
        disableAutoFocus={true}
      >
        <AddCreditCardForm
          handleResult={handleAddNewCardSuccess}
          handleClose={closeModal}
          setIsPrimary={currentPaymentMethod ? false : true}
        />
      </Dialog>
      <ConfirmationDialogComponent
        isOpen={confirmationDialogModal}
        handleClose={closeConfirmationDialog}
        handleProceed={handleProceed}
        isLoading={isProcessing}
        type={"danger"}
        description={getLang(lang, "paragraph.DELETE_CARD_QUESTION")}
        title={getLang(lang, "label.CONFIRMATION_NEEDED")}
      />
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  deletePaymentMethod: (id) => dispatch(deletePaymentMethod(id)),
  updateDefaultPaymentMethod: (id) => dispatch(updateDefaultPaymentMethod(id)),
  addAlert: (payload) => dispatch(addAlert(payload))
});

export default connect(null, mapDispatchToProps)(PaymentMethodCardContainer);
