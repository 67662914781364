/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import GroupBranchEditDialogComponent from "./groupBranchEditDialog.component";
import { useDispatch, useSelector } from "react-redux";
import {
  getAvailableBranches,
  resetAvailableBranches,
} from "modules/group/redux";
import { selectLoading } from "modules/notification";
import ConfirmationDialog from "../confirmationDialog";
import { getLang } from "app/feature/constants";

function GroupBranchEditDialogContainer({
  isOpen,
  handleClose,
  submitBranchUpdate,
  isSubmitting,
  id,
}) {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.constant.languages);
  const branchesNotInGroup = useSelector(
    (state) => state.branchGroup.availableBranches
  );
  const branchesInGroup = useSelector(
    (state) => state.branchGroup.selectedBranches
  );
  const isFetching = useSelector((state) =>
    selectLoading(state, getAvailableBranches.typePrefix)
  );
  const isError = useSelector(
    (state) => state.branchGroup.isLoadingAvailableBranchError
  );

  const [availableBranch, setAvailableBranch] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState([]);

  const [branchToAdd, setBranchToAdd] = useState([]);
  const [branchToRemove, setBranchToRemove] = useState([]);

  const [openSaveDialog, setOpenSaveDialog] = useState(false);

  useEffect(() => {
    setAvailableBranch(branchesNotInGroup);
  }, [branchesNotInGroup]);

  useEffect(() => {
    setSelectedBranch(branchesInGroup);
  }, [branchesInGroup]);

  useEffect(() => {
    if (isOpen) {
      getData();
    } else {
      dispatch(resetAvailableBranches());
    }
  }, [isOpen]);

  const getData = () => {
    dispatch(getAvailableBranches(id));
  };

  const removeBranch = () => {
    let uuids = branchToRemove.map((b) => b.id);

    let temp = selectedBranch.filter((b) => !uuids.includes(b.id));
    setSelectedBranch(temp);

    setAvailableBranch([...availableBranch, ...branchToRemove]);
    setBranchToRemove([]);
  };

  const removeAllAvailableBranch = () => {
    setSelectedBranch([]);
    setAvailableBranch([...selectedBranch, ...availableBranch]);
    setBranchToRemove([]);
  };

  const addBranch = () => {
    let uuids = branchToAdd.map((b) => b.id);

    setSelectedBranch([...selectedBranch, ...branchToAdd]);

    let temp = availableBranch.filter((b) => !uuids.includes(b.id));
    setAvailableBranch(temp);

    setBranchToAdd([]);
  };

  const addAllAvailableBranch = () => {
    setSelectedBranch([...selectedBranch, ...availableBranch]);
    setAvailableBranch([]);
    setBranchToAdd([]);
  };

  const selectBranchToAdd = (branch) => {
    let index = branchToAdd.findIndex((b) => b.id === branch.id);
    if (index >= 0) {
      setBranchToAdd(branchToAdd.filter((b) => b.id !== branch.id));
    } else {
      setBranchToAdd([...branchToAdd, branch]);
    }
    setBranchToRemove([]);
  };

  const selectBranchToRemove = (branch) => {
    let index = branchToRemove.findIndex((b) => b.id === branch.id);
    if (index >= 0) {
      setBranchToRemove(branchToRemove.filter((b) => b.id !== branch.id));
    } else {
      setBranchToRemove([...branchToRemove, branch]);
    }
    setBranchToAdd([]);
  };

  const submitUpdate = () => {
    setOpenSaveDialog(false);
    submitBranchUpdate(selectedBranch);
  };

  const resetList = () => {
    setAvailableBranch(branchesNotInGroup);
    setSelectedBranch(branchesInGroup);
    setBranchToAdd([]);
    setBranchToRemove([]);
  };

  return (
    <>
      <GroupBranchEditDialogComponent
        isOpen={isOpen}
        handleClose={handleClose}
        lang={lang}
        addBranch={addBranch}
        addAllAvailableBranch={addAllAvailableBranch}
        removeBranch={removeBranch}
        removeAllAvailableBranch={removeAllAvailableBranch}
        availableBranch={availableBranch}
        selectedBranch={selectedBranch}
        selectBranchToAdd={selectBranchToAdd}
        selectBranchToRemove={selectBranchToRemove}
        branchToAdd={branchToAdd}
        branchToRemove={branchToRemove}
        submitUpdate={() => setOpenSaveDialog(true)}
        isSubmitting={isSubmitting}
        isFetching={isFetching}
        handleReload={getData}
        isError={isError}
        resetList={resetList}
      />
      <ConfirmationDialog
        isOpen={openSaveDialog}
        handleClose={() => setOpenSaveDialog(false)}
        handleProceed={() => submitUpdate()}
        isLoading={isSubmitting}
        type={"success"}
        description={getLang(lang, "paragraph.SAVE_GROUP_BRANCHES_QUESTION")}
        title={getLang(lang, "label.UPDATE_GROUP_BRANCHES")}
      />
    </>
  );
}

export default GroupBranchEditDialogContainer;
