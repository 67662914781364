import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  STOCKFLOW_REWARD_CENTER_ADD,
  STOCKFLOW_REWARD_CENTER_EDIT
} from "lib/constants/accessRights"
import RewardListPanelComponent from './rewardListPanel.component';
import { REWARD_CENTER_REWARD_TAB } from "modules/stockflow/config/stockflow.route";
import { deleteReward, getAllRewardListing, resetRewardList, updateRewardStatus } from "modules/stockflow/redux";
import { selectLoading } from "modules/notification";
import { useEffect } from "react";
import { generatePaginationOptions } from "lib/helper";
import { unwrapResult } from "@reduxjs/toolkit";
import GeneralDialog from "components/dialog/generalDialog";
import AlertIcon from 'assets/svg/alert.svg';
import { changeSelectedReward, changeBulkSelectedAllReward, changeBulkSelectedAvailableReward } from "modules/stockflow/redux/slice/stockflowRewardCenter.slice";
import { bulkUpdateRewardStatus } from "modules/stockflow/redux/action/stockflowRewardCenter.action";
import { getLang } from "app/feature/constants";



function RewardListPanelContainer({
  handleAddReward
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const lang = useSelector(state => state.constant.languages);
  const language = useSelector(state => state.profile.language);
  const hasStockflowAddAccessRight = useSelector(state => state.profile.accessRights.includes(STOCKFLOW_REWARD_CENTER_ADD));
  const hasStockflowEditAccessRight = useSelector(state => state.profile.accessRights.includes(STOCKFLOW_REWARD_CENTER_EDIT));
  const [tableFilter, setTableFilter] = useState({
    page: 0,
    rowsPerPage: 25,
    search: "",
    order: "desc",
    nodes: "",
    status: 0
  })
  const [paginationOptions, setPaginationOptions] = useState([]);
  const isFetching = useSelector(state => selectLoading(state, getAllRewardListing.typePrefix));
  const isError = useSelector(state => state.stockflowRewardCenter.rewardList.isError);
  const data = useSelector(state => state.stockflowRewardCenter.rewardList.data);
  const totalFiltered = useSelector(state => state.stockflowRewardCenter.rewardList.totalFiltered);
  const selectedUUIDs = useSelector(state => state.stockflowRewardCenter.rewardList.selectedUUIDs);
  
  //Delete dialog
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [deleteRewardId, setDeleteRewardId] = useState("")
  const [isSelectAll, setIsSelectAll] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(resetRewardList())
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    dispatch(getAllRewardListing({
      length: tableFilter.rowsPerPage,
      start: tableFilter.rowsPerPage * tableFilter.page,
      order: tableFilter.order,
      search: tableFilter.search,
      nodes: tableFilter.nodes,
      status: tableFilter.status
    }))
  }, [
    dispatch, tableFilter
  ])

  useEffect(() => {
    setPaginationOptions(generatePaginationOptions(totalFiltered))
  }, [totalFiltered]);

  const handleViewDetail = id => history.push(`${REWARD_CENTER_REWARD_TAB}/${id}`);

  const handleReload = () => {
    dispatch(getAllRewardListing({
      length: tableFilter.rowsPerPage,
      start: tableFilter.rowsPerPage * tableFilter.page,
      order: tableFilter.order,
      search: tableFilter.search,
      nodes: tableFilter.nodes,
      status: tableFilter.status
    }))
  }

  const handleUpdateRewardStatus = (id, status) => {
    dispatch(updateRewardStatus({ id, status: status ? 1 : 2 }));
  }
  
  const handleSearch = (values) => {
    setTableFilter({
      search: values.search,
      page: 0,
      rowsPerPage: 25
    })
  }

  const handleChangePage = (event, newPage) => {
    setTableFilter((prev) => ({
      ...prev,
      page: newPage,
    }))
  }

  const handleChangeRowPerPage = (event) => {
    setTableFilter((prev) => ({
      ...prev,
      page: 0,
      rowsPerPage: event.target.value
    }))
  }

  const handleDeleteReward = (id) => {
    setDeleteRewardId(id)
    setIsDeleteDialogOpen(true)
  }

  const handleSubmitDeleteReward = async () => {
    await dispatch(deleteReward(deleteRewardId))
      .then(unwrapResult)
      .then(() => handleReload())
      .catch()
    setDeleteRewardId('')
    setIsDeleteDialogOpen(false)
  }

  const handleSelectReward = (index, isSelected) => {
    dispatch(changeSelectedReward({ index: index, isSelected: isSelected }))
  }

  const handleSelectAvailable = () => {
    dispatch(changeBulkSelectedAvailableReward({ value: true }))
  }

  const handleDeselectAll = () => {
    setIsSelectAll(false)
    dispatch(changeBulkSelectedAllReward({ value: false }))
  }

  const handleSelectAll = () => {
    setIsSelectAll(true)
    dispatch(changeBulkSelectedAllReward({ value: true }))
  }

  const handleBulkUpdateRewardStatus = (value) => {

    const request = {
      status: value === getLang(lang, 'label.ACTIVATE_SELECTED') ? 1 : 2,
      isSelectAll: isSelectAll,
      rewardUUIDs: selectedUUIDs,
      filter: {
        search: tableFilter.search
      }
    }

    dispatch(bulkUpdateRewardStatus(request))
      .then(() => {
        handleReload()
        handleDeselectAll()
      })
  }

  return (
    <>
      <RewardListPanelComponent
        hasStockflowEditAccessRight={hasStockflowEditAccessRight}
        hasStockflowAddAccessRight={hasStockflowAddAccessRight}
        rows={data}
        language={language}
        page={tableFilter.page}
        rowsPerPage={tableFilter.rowsPerPage}
        paginationOptions={paginationOptions}
        totalFiltered={totalFiltered}
        isFetching={isFetching}
        isError={isError}
        isSelected={(!isSelectAll && selectedUUIDs.length > 0) || (isSelectAll && selectedUUIDs.length < totalFiltered)}
        isSelectAll={isSelectAll}
        selectedCount={!isSelectAll ? selectedUUIDs.length : totalFiltered - selectedUUIDs.length}
        selectedUUIDs={selectedUUIDs}
        handleReload={handleReload}
        handleSearch={handleSearch}
        handleChangePage={handleChangePage}
        handleChangeRowPerPage={handleChangeRowPerPage}
        handleUpdateRewardStatus={handleUpdateRewardStatus}
        handleViewDetail={handleViewDetail}
        handleDeleteReward={handleDeleteReward}
        handleAddReward={handleAddReward}
        handleSelectReward={handleSelectReward}
        handleSelectAvailable={handleSelectAvailable}
        handleDeselectAll={handleDeselectAll}
        handleSelectAll={handleSelectAll}
        handleBulkUpdateRewardStatus={handleBulkUpdateRewardStatus}
        lang={lang}
      />
      <GeneralDialog
        isOpen={isDeleteDialogOpen}
        handleClose={() => setIsDeleteDialogOpen(false)}
        handleProceed={handleSubmitDeleteReward}
        title={getLang(lang, 'label.DELETE_REWARD')}
        description={getLang(lang, 'paragraph.DO_YOU_WANT_TO_DELETE_REWARD')}
        type="danger"
        icon={<img src={AlertIcon} alt="alert" />}
      />
    </>
  )
}

export default RewardListPanelContainer