/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import GenerateComponent from "./generate.page";
import { connect, useSelector } from "react-redux";
import { fetchBranchDropdownSpecial } from "modules/branch/redux";
import { fetchTagsDropdown } from "modules/serial-number/redux";
import { SERIAL_NUMBER_GENERATE } from "lib/constants/accessRights"
import { useHistory } from "react-router-dom";
import LoadingComponent from "components/loading";
import { checkFeatureAvailability } from "modules/account/redux";
import { FEATURE_SERIAL_NUMBER_GENERATE } from "lib/constants/featureAccessRight";

function GenerateContainer({
  getProductDropdown,
  fetchBranchDropdownSpecial,
  fetchTagsDropdown,
  checkFeatureAvailability
}) {
  const history = useHistory()
  const hasSerialNumberGenerateAccessRight = useSelector(state => state.profile.accessRights.includes(SERIAL_NUMBER_GENERATE));
  const lang = useSelector(state => state.constant.languages);
  useEffect(() => {
    if(!hasSerialNumberGenerateAccessRight){
      history.push('/admin/dashboard')
    }
  }, [history, hasSerialNumberGenerateAccessRight])

  useEffect(() => {
    fetchBranchDropdownSpecial();
    fetchTagsDropdown();
  }, [getProductDropdown, fetchBranchDropdownSpecial, fetchTagsDropdown])

  useEffect(() => {
    checkFeatureAvailability(FEATURE_SERIAL_NUMBER_GENERATE)
  }, [])

  if (!hasSerialNumberGenerateAccessRight) return <LoadingComponent />;

  return (
    <GenerateComponent lang={lang}/>
  );
}

const mapDispatchToProps = dispatch => ({
  fetchBranchDropdownSpecial: () => dispatch(fetchBranchDropdownSpecial()),
  fetchTagsDropdown: () => dispatch(fetchTagsDropdown()),
  checkFeatureAvailability: (payload) => dispatch(checkFeatureAvailability(payload))
});

export default connect(null, mapDispatchToProps)(GenerateContainer);
