import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { selectLoading } from "modules/notification";
import {
  approveRequestTransfer,
  generatePoPdf,
  getRequestDetail,
  rejectApproveRequestTransfer,
} from "modules/stockflow/redux";
import { STOCKFLOW_REQUEST_TRANSFER_VIEW } from "lib/constants/accessRights";
import { ROOT } from "modules/dashboard";
import RequestDetailsComponent from "./requestDetails.component";
import { useState } from "react";
import { getLang } from "app/feature/constants";
import InfoDialog from "modules/stockflow/components/dialog/infoDialog";
import RejectApproveRequestDialog from "modules/stockflow/components/dialog/rejectApproveRequestDialog";
import GeneralDialog from "components/dialog/generalDialog";

function RequestDetailsContainer() {
  const history = useHistory();
  const dispatch = useDispatch();
  const match = useRouteMatch();

  const id = match.params.uuid;
  const hasStockflowViewAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(STOCKFLOW_REQUEST_TRANSFER_VIEW)
  );
  const lang = useSelector((state) => state.constant.languages);
  const language = useSelector((state) => state.profile.language);
  const request = useSelector(
    (state) => state.stockflowRequestTransfer.request
  );
  const isError = useSelector(
    (state) => state.stockflowRequestTransfer.isLoadingRequestError
  );
  const isFetching = useSelector((state) =>
    selectLoading(state, getRequestDetail.typePrefix)
  );

  const isApproving = useSelector((state) =>
    selectLoading(state, approveRequestTransfer.typePrefix)
  );
  const isRejecting = useSelector((state) =>
    selectLoading(state, rejectApproveRequestTransfer.typePrefix)
  );
  const token = useSelector((state) => state.session.accessToken);

  const [isExporting, setIsExporting] = useState(false);
  const [showApproveConfirm, setShowApproveConfirm] = useState(false);
  const [showRejectConfirm, setShowRejectConfirm] = useState(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);

  useEffect(() => {
    if (!hasStockflowViewAccessRight) {
      history.push("/admin/dashboard");
    }
  }, [history, hasStockflowViewAccessRight]);

  useEffect(() => {
    if (id) {
      dispatch(getRequestDetail({ id, is_my_request: 0 }));
    } else {
      history.push(ROOT);
    }
  }, [history, id, dispatch]);

  const handleReload = () => {
    dispatch(getRequestDetail({ id, is_my_request: 0 }));
  };

  const showApprovalConfirm = (isApprove) => {
    if (isApprove) {
      setShowApproveConfirm(true);
    } else {
      setShowRejectConfirm(true);
    }
  };

  const approve = () => {
    dispatch(approveRequestTransfer(id)).then(() => {
      setShowApproveConfirm(false);
      setShowSuccessDialog(true);
    });
  };

  const rejectApprove = (reason) => {
    dispatch(rejectApproveRequestTransfer({ id, reasons: reason })).then(() => {
      setShowRejectConfirm(false);
      handleReload();
    });
  };

  const exportPdf = () => {
    setIsExporting(true);
    dispatch(
      generatePoPdf({
        uuid: id,
        token,
        reference_code: request.reference_code,
      })
    ).then(() => setIsExporting(false));
  };

  return (
    <>
      <RequestDetailsComponent
        request={request}
        isFetching={isFetching}
        language={language}
        isError={isError}
        handleReload={handleReload}
        lang={lang}
        showApprovalConfirm={showApprovalConfirm}
        isExporting={isExporting}
        exportPdf={exportPdf}
      />
      <GeneralDialog
        isLoading={isApproving}
        isOpen={showApproveConfirm}
        handleClose={() => setShowApproveConfirm(false)}
        handleProceed={approve}
        title={getLang(lang, "label.APPROVE_TRANSFER_REQUEST")}
        description={getLang(lang, "paragraph.APPROVE_TRANSFER_REQUEST_DESC")}
        type="success"
        proceedLabel={getLang(lang, "label.CONFIRM")}
      />
      <RejectApproveRequestDialog
        isLoading={isRejecting}
        isOpen={showRejectConfirm}
        handleClose={() => setShowRejectConfirm(false)}
        handleProceed={rejectApprove}
      />
      <InfoDialog
        isOpen={showSuccessDialog}
        handleClose={() => {
          setShowSuccessDialog(false);
          handleReload();
        }}
        title={getLang(lang, "label.SUCCESSFULLY_APPROVE_TRANSFER_REQUEST")}
        description={getLang(
          lang,
          "paragraph.SUCCESSFULLY_APPROVE_TRANSFER_REQUEST_DESC"
        )}
      />
    </>
  );
}

export default RequestDetailsContainer;
