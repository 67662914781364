import React from 'react'
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core'
import {
  DragIndicator as DragIndicatorIcon,
  AddRounded as AddIcon,
  HighlightOff as HighlightOffIcon
} from '@material-ui/icons';
import DustBinIcon from "assets/svg/dustBin.svg"
import InputTextField from 'components/input/inputTextField'
import AntSwitch from 'components/switch/switch'
import { surveyQuestionType } from 'modules/survey/constants'
import { getLang } from 'app/feature/constants';

const useStyle = makeStyles(theme => ({
  container: {
    position: "relative",
    borderColor: theme.palette.primary.main,
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.1)"
  },
  questionField: {
    width: "100%"
  },
  answerField: {
    width: "60%"
  },
  label: {
    fontSize: 12
  },
  editButton: {
    position: "absolute",
    bottom: 5,
    right: 5
  },
  icon: {
    borderRadius: 3,
    width: 16,
    height: 16,
    boxShadow: 'none',
    background: 'rgba(206,217,224,.5)'
  },
  checkbox: {
    padding: "7px 10px 7px 0px"
  },
  labelGrid: {
    paddingTop: 12
  },
  dropdownContainer: {
    width: "100%"
  },
  dropdown: {
    paddingLeft: 10,
    fontSize: 14,
    fontWeight: 500
  },
  dropdownIcon: {
    right: 5,
    fill: theme.palette.primary.main
  },
  toggleControl: {
    marginLeft: 0
  },
  toggleButton: {
    marginRight: 10
  },
  dragIconButton: {
    position: "absolute",
    width: "100%",
    top: 0,
    left: 0,
    padding: 0,
    textAlign: "center"
  }
}))

export default function EditSurveyQuestionCardComponent({
  questions,
  alertWarningMessage,
  dragHandleProps,
  handleDeleteQuestion,
  index,
  setSurveyData,
  isError,
  setIsError,
  lang
}) {
  const classes = useStyle()
  return (
    <Paper variant='outlined' className={classes.container}>
      <Box style={{ padding: "25px 20px 15px 20px" }}>
        <div className={classes.dragIconButton} {...dragHandleProps}>
          <DragIndicatorIcon style={{ color: "#3a4d5454", rotate: "90deg" }} />
        </div>
        <Grid container style={{ marginBottom: "10px" }}>
          <Grid item xs={2} className={classes.labelGrid}>
            <Typography className={classes.label} color="textSecondary" style={{ textTransform: "uppercase" }}>
              {getLang(lang, 'label.QUESTION')}  <Typography style={{ color: "#DE350B", display: 'inline-block' }}>*</Typography>
            </Typography>
          </Grid>
          <Grid item xs={10} >
            <InputTextField
              InputStyle={{
                fontSize: 14,
                fontWeight: 400
              }}
              className={classes.questionField}
              value={questions[index].text}
              onChange={(event) => {
                let questionsTemp = [...questions]
                let question = { ...questionsTemp[index] }

                if (event.target.value === "") {
                  setIsError(`question-${index}`)
                } else {
                  setIsError("")
                }

                question.text = event.target.value
                questionsTemp[index] = question

                setSurveyData((prev) => ({
                  ...prev,
                  questions: [...questionsTemp]
                }))
              }}
            />
            {isError === `question-${index}` && (
              <span style={{ color: "#f44336", fontSize: "0.75rem", margin: "0px" }}>{getLang(lang, 'message.error.FORM_VALIDATE_REQUIRED')}</span>
            )}
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={2} className={classes.labelGrid}>
            <Typography className={classes.label} color="textSecondary" style={{ textTransform: "uppercase" }}>
              {getLang(lang, 'label.ANSWER')}  {(questions[index].type === surveyQuestionType.selection || questions[index].type === "multipleChoiceMultiSelect")
                && (<Typography style={{ color: "#DE350B", display: 'inline-block' }}>*</Typography>)}
            </Typography>
          </Grid>
          <Grid item xs={10}>
            {questions[index].type === surveyQuestionType.text ? (
              <>
                <InputTextField
                  placeholder={`(${getLang(lang, 'label.OPTIONAL')})`}
                  InputStyle={{
                    fontSize: 14
                  }}
                  value={questions[index].placeholder}
                  className={classes.answerField}
                  onChange={(event) => {
                    let questionsTemp = [...questions]
                    let question = { ...questionsTemp[index] }
                    question.placeholder = event.target.value
                    questionsTemp[index] = question
                    setSurveyData((prev) => ({
                      ...prev,
                      questions: [...questionsTemp]
                    }))
                  }}
                />
              </>
            ) : (
              <>
                {questions[index].answers.map((item, answerIndex) => (
                  <Box key={answerIndex} style={{ marginBottom: "5px" }}>
                    <Box style={{ display: "flex", alignItems: "flex-start" }}>

                      <InputTextField
                        placeholder={getLang(lang, 'placeholder.ENTER_OPTION')}
                        InputStyle={{
                          fontSize: 14
                        }}
                        style={{
                          width: "60%"
                        }}
                        value={item}
                        onChange={(event) => {
                          questions = JSON.parse(JSON.stringify(questions))
                          let questionsTemp = [...questions]
                          let answers = [...questionsTemp[index].answers]

                          answers[answerIndex] = event.target.value

                          if (event.target.value === "") {
                            setIsError(`answer-${answerIndex}`)
                          } else {
                            setIsError("")
                          }
                          questionsTemp[index].answers = answers

                          setSurveyData((prev) => ({
                            ...prev,
                            questions: [...questionsTemp]
                          }))
                        }}
                      />
                      <IconButton
                        style={{ color: "#fd646f" }}
                        onClick={() => {

                          if (questions[index].answers.length <= 2) {
                            alertWarningMessage(getLang(lang, 'message.warning.MUST_HAVE_2_OPTIONS_FOR_MCQ'))
                          }
                          else {
                            let answers = [...questions[index].answers]
                            let questionsTemp = [...questions]

                            answers.splice(answerIndex, 1)

                            questionsTemp[index].answers = answers

                            setSurveyData((prev) => ({
                              ...prev,
                              questions: [...questionsTemp]
                            }))
                          }
                        }}
                      >
                        <HighlightOffIcon fontSize="small" />
                      </IconButton>
                    </Box>
                    {isError === `answer-${answerIndex}` && (
                      <span style={{ color: "#f44336", fontSize: "0.75rem", margin: "0px" }}>{getLang(lang, 'message.error.FORM_VALIDATE_REQUIRED')}</span>
                    )}
                  </Box>
                ))}
                <Button
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={() => {
                    if (questions[index].type !== "dropdownlist") {
                      if (questions[index].answers.length < 50) {
                        let answers = [...questions[index].answers]
                        let questionsTemp = [...questions]

                        answers.push("")

                        questionsTemp[index].answers = answers

                        setSurveyData((prev) => ({
                          ...prev,
                          questions: [...questionsTemp]
                        }))
                      } else {
                        alertWarningMessage(getLang(lang, 'message.warning.MAXIMUM_10_OPTIONS'))
                      }
                    }
                    else {
                      let answers = [...questions[index].answers]
                      let questionsTemp = [...questions]

                      answers.push("")

                      questionsTemp[index].answers = answers

                      setSurveyData((prev) => ({
                        ...prev,
                        questions: [...questionsTemp]
                      }))
                    }
                  }}
                >
                  {getLang(lang, 'label.ADD_FIELD')}
                </Button>
              </>
            )}
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Grid container >
        <Grid item xs={1} style={{ borderRight: "1px solid #0000001f", display: "flex", justifyContent: "center" }}>

          <IconButton onClick={() => handleDeleteQuestion(index)}>
            <img src={DustBinIcon} alt="delete" />
          </IconButton>

        </Grid>
        <Grid item xs={7} style={{ borderRight: "1px solid #0000001f", display: "flex", alignItems: "center" }}>
          <Typography style={{ margin: "0px 0px 0px 16px" }} >
            {questions[index].type === "text" ? "Text Field" : questions[index].type === "select" ? getLang(lang, 'label.MULTIPLE_CHOICE') : questions[index].type === "multipleChoiceMultiSelect"
              ? getLang(lang, "label.MULTIPLE_CHOICE_MULTI_SELECT") : questions[index].type === "dropdownlist" ? getLang(lang, 'label.DROPDOWN_LIST') : null}
          </Typography>
        </Grid>
        <Grid item xs={4} style={{ display: "flex", paddingLeft: "10px" }}>
          <FormControlLabel
            classes={{
              root: classes.toggleControl
            }}
            control={
              <AntSwitch
                checked={questions[index].required}
                classes={{
                  root: classes.toggleButton
                }}
                onChange={e => {
                  let questionsTemp = [...questions]

                  questionsTemp[index].required = e.target.checked

                  setSurveyData((prev) => ({
                    ...prev,
                    questions: [...questionsTemp]
                  }))
                }}
              />
            }
            label={
              <Typography variant='body2' color='primary'>
                {getLang(lang, 'message.error.FORM_VALIDATE_REQUIRED')}
              </Typography>
            }
          />
        </Grid>
      </Grid>
    </Paper >
  )
}
