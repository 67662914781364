import React, { useState, useEffect } from "react";
import { selectLoading } from "modules/notification"
import { useDispatch, useSelector } from "react-redux";
import { fetchRespondentListing } from "modules/survey/redux/action"
import RespondentsListingPanelComponent from "./respondentsListingPanel.components";
import { generatePaginationOptions } from "lib/helper";

export default function RespondentsListingPanelContainer({
  uuid
}) {
  const dispatch = useDispatch();

  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [productSelect, setProductSelect] = useState("");
  const [dateCreated, setDateCreated] = useState("");
  const [paginationOptions, setPaginationOptions] = useState([]);

  const isFetching = useSelector(state => selectLoading(state, fetchRespondentListing.typePrefix));
  const totalRecords = useSelector(state => state.survey.respondentList.totalRecords);
  const data = useSelector(state => state.survey.respondentList.data)

  useEffect(() => {
    dispatch(fetchRespondentListing({
      length: rowsPerPage,
      start: rowsPerPage * page,
      search: search,
      dateCreated: dateCreated,
      productSelect: productSelect,
      uuid: uuid
    }))
  }, [
    search,
    rowsPerPage,
    page,
    dateCreated,
    productSelect,
    dispatch,
    uuid
  ])

  useEffect(() => {
    setPaginationOptions(generatePaginationOptions(totalRecords))
  }, [totalRecords])

  const handleReload = () => {
    dispatch(fetchRespondentListing({
      length: rowsPerPage,
      start: rowsPerPage * page,
      search: search,
      dateCreated: dateCreated,
      productSelect: productSelect,
      uuid: uuid
    }))
  }

  const handleSearch = value => {
    setPage(0)
    setSearch(value.search)
    setProductSelect(value.productSelect)
    setDateCreated(value.dateSubmitted)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setPage(0)
    setRowsPerPage(event.target.value)
  }

  return (
    <RespondentsListingPanelComponent
      isFetching={isFetching}
      page={page}
      rowsPerPage={rowsPerPage}
      totalRecords={totalRecords}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      handleReload={handleReload}
      handleSearch={handleSearch}
      data={data}
      paginationOptions={paginationOptions}
    />
  )
}