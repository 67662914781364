import axios from "axios";

export function getStockTakeItemBoxList(parameters) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/distributor/admin/api/v1/stocktake/${parameters.id}/boxes`, {
        params: {
          length: parameters.length,
          start: parameters.start,
          search: parameters.search,
          status: parameters.status,
          order: parameters.order,
          order_by: parameters.order_by,
        },
      })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data));
  });
}
