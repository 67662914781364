const API_HOST = process.env.REACT_APP_API_HOST_URL;

const generateDnPdf = async ({ ...parameters }) => {
  let response = await fetch(
    `${API_HOST}/distributor/admin/api/v1/transfer/${parameters.uuid}/generate-dn-pdf`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${parameters.token}`,
      },
    }
  );

  let blob = await response.blob();

  saveFile(blob, parameters.reference_code);
};

const saveFile = async (blob, referenceCode) => {
  const a = document.createElement("a");
  a.download = `delivery_note_${referenceCode}.pdf`;
  a.href = URL.createObjectURL(blob);
  a.addEventListener("click", (e) => {
    setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
  });
  a.click();
};

export default generateDnPdf;
