import axios from "axios"

//  update survey status
export function updateSurveyStatus({
  id,
  status
}) {
  return new Promise((resolve, reject) => {
    axios.put(`/consumer/admin/api/v1/survey/${id}/status`, {
      status
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data))
  })
}