import React from "react";
import RoleSelectComponent from "./roleSelect.component";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

function RoleSelectContainer({
    label,
    handleSelectAll,
    handleDeselectAll,
    ...props
}) {
    const lang = useSelector(state => state.constant.languages);
    return (
        <RoleSelectComponent
            label={label}
            handleSelectAll={handleSelectAll}
            handleDeselectAll={handleDeselectAll}
            lang={lang}
            {...props}
        />
    )
}

RoleSelectContainer.defaultProps = {
    label: "Label",
};

RoleSelectContainer.propTypes = {
    label: PropTypes.string,
};

export default RoleSelectContainer;
