import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { selectLoading } from "modules/notification";
import CurrencyConfigSettingPanelComponent from './currencyConfigSettingPanel.component';
import GeneralDialog from 'components/dialog/generalDialog';
import AlertIcon from 'assets/svg/alert.svg';
import { generatePaginationOptions } from "lib/helper";
import {
  getAvailableCurrencyConfigListing,
  addCurrencyConfig,
  deleteCurrencyConfig,
  updateDefaultCurrency,
  updateCurrencyConfigStatus,
  bulkUpdateCurrencyStatus,
  changeBulkSelected,
  changeSelectedCurrencyConfig
} from "../../../redux";
import AddCurrencyConfigDialog from "../../dialog/addCurrencyConfigDialog";
import { getLang } from "app/feature/constants";
import { hideBackdrop, showBackdrop } from "modules/backdrop";
import { COMPANY_CURRENCY_EDIT } from "lib/constants/accessRights";

function CurrencyConfigSettingPanelContainer() {
  const dispatch = useDispatch()
  const hasCompanyCurrencyEditAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(COMPANY_CURRENCY_EDIT)
  );

  const totalRecords = useSelector(state => state.account.currencyConfig.totalRecords);
  const data = useSelector(state => state.account.currencyConfig.data)
  const isFetching = useSelector(state => selectLoading(state, getAvailableCurrencyConfigListing.typePrefix))
  const isLoadingCurrencyConfigListError = useSelector(state => state.account.currencyConfig.isError)
  const [isAddCurrencyDialogOpen, setIsAddCurrencyDialogOpen] = useState(false);
  const [isDeleteCurrencyConfigDialogOpen, setIsDeleteCurrencyConfigDialogOpen] = useState(false);
  const [deleteCurrencyCode, setDeleteCurrencyCode] = useState("");
  const [deleteCurrencyName, setDeleteCurrencyName] = useState("");
  const [isChangeCurrencyStatusDialogOpen, setIsChangeCurrencyStatusDialogOpen] = useState(false);
  const [isBulkUpdate, setIsBulkUpdate] = useState(false)
  const [updateCurrencyStatusCount, setUpdateCurrencyStatusCount] = useState(0);
  const [updateCurrencyCode, setUpdateCurrencyCode] = useState("");
  const [isEnableCurrency, setIsEnableCurrency] = useState(true);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [selectedCount, setSelectedCount] = useState(0);
  const [isNewCurrencySelected, setIsNewCurrencySelected] = useState(false)
  const [paginationOptions, setPaginationOptions] = useState([]);
  const lang = useSelector(state => state.constant.languages)

  const [filter, setFilter] = useState({
    page: 0,
    rowsPerPage: 25
  })
  // const [status, setStatus] = useState("");

  useEffect(() => {
    dispatch(getAvailableCurrencyConfigListing({
      length: filter.rowsPerPage,
      start: filter.rowsPerPage * filter.page
    }))
  }, [dispatch, filter])

  useEffect(() => {
    setPaginationOptions(generatePaginationOptions(totalRecords))
  }, [totalRecords])

  const handleFetchCurrencyConfigList = () => {
    dispatch(getAvailableCurrencyConfigListing({
      length: filter.rowsPerPage,
      start: filter.rowsPerPage * filter.page
    }))
    setIsSelectAll(false)
    setSelectedCount(0)
  }

  const handleReload = () => {
    dispatch(getAvailableCurrencyConfigListing({
      length: filter.rowsPerPage,
      start: filter.rowsPerPage * filter.page
    }))
    setIsSelectAll(false)
    setSelectedCount(0)
  }

  const handleChangePage = (event, newPage) => {
    setFilter({
      page: newPage,
      rowsPerPage: filter.rowsPerPage
    })
    setIsSelectAll(false)
    setSelectedCount(0)
  }

  const handleChangeRowsPerPage = event => {
    setFilter({
      page: 0,
      rowsPerPage: event.target.value
    })
    setIsSelectAll(false)
    setSelectedCount(0)
  }

  const handleSubmitCurrencyConfig = (value) => {
    dispatch(showBackdrop());
    dispatch(addCurrencyConfig({ currencyCode: value.currencyCode, isDefault: value.isDefault ? true : false }))
      .then(unwrapResult)
      .then(() => {
        handleFetchCurrencyConfigList()
        handleAddCurrency(false)
        dispatch(hideBackdrop())
      })
    setIsNewCurrencySelected(false);
  }

  const handleAddCurrency = (value) => {
    setIsAddCurrencyDialogOpen(value)
  }

  const handleSetDefaultCurrency = (value) => {
    dispatch(updateDefaultCurrency({
      currencyCode: value
    }))
      .then(unwrapResult)
      .then(() => {
        handleFetchCurrencyConfigList()
      })
  }

  const handleSubmitBulkChangeCurrencyConfigStatus = () => {
    dispatch(showBackdrop());
    let tempCode = [];

    data.map((currency) => {
      if (currency.isSelected) {
        tempCode.push(currency.alpha3)
      }
      return null;
    });

    dispatch(bulkUpdateCurrencyStatus({ currencyCodes: tempCode, status: (isEnableCurrency ? "enabled" : "disabled") }))
      .then(() => {
        setIsSelectAll(false)
        setSelectedCount(0)
        setIsChangeCurrencyStatusDialogOpen(false)
        dispatch(hideBackdrop())
      })
  }

  const handleSubmitSingleChangeCurrencyConfigStatus = () => {
    dispatch(showBackdrop());
    dispatch(updateCurrencyConfigStatus({ currencyCode: updateCurrencyCode, status: (isEnableCurrency ? "enabled" : "disabled") }))
      .then(() => {
        setIsSelectAll(false)
        setSelectedCount(0)
        setIsChangeCurrencyStatusDialogOpen(false)
        dispatch(hideBackdrop())
      })
  }

  const handleDeleteCurrencyConfig = (id, name) => {
    setDeleteCurrencyCode(id);
    setDeleteCurrencyName(name);
    setIsDeleteCurrencyConfigDialogOpen(true);
  }

  const handleSubmitDeleteCurrencyConfig = () => {
    dispatch(showBackdrop());
    dispatch(deleteCurrencyConfig({
      code: deleteCurrencyCode
    }))
      .then(unwrapResult)
      .then(() => handleFetchCurrencyConfigList())
      .finally(() => {
        dispatch(hideBackdrop())
        setIsDeleteCurrencyConfigDialogOpen(false)
      })
  }

  const handleShowChangeCurrencyStatusDialog = (isEnable) => {
    setIsEnableCurrency(isEnable === "Enable Selected")
    setIsBulkUpdate(true)
    let selectedCount = data.filter(currency => currency.isSelected === true && currency.isDefault === false).length;
    setUpdateCurrencyStatusCount(selectedCount)
    setIsChangeCurrencyStatusDialogOpen(true)
  }

  const handleSelectAll = () => {
    setIsSelectAll(true)
    setSelectedCount(data.filter(currency => currency.isDefault === false).length)
    dispatch(changeBulkSelected({ value: true }))
  }

  const handleDeselectAll = () => {
    setIsSelectAll(false)
    setSelectedCount(0)
    dispatch(changeBulkSelected({ value: false }))
  }

  const handleSelectCurrencyConfig = (index, isSelected) => {
    let falseProps = []
    dispatch(changeSelectedCurrencyConfig({ index, isSelected }))
    falseProps = data.filter(currency => currency.isSelected === false && currency.isDefault === false);
    setSelectedCount(isSelected ? selectedCount + 1 : selectedCount - 1)
    setIsSelectAll(isSelected && falseProps.length <= 1);
  }

  const handleSingleCurrencyConfigStatus = (code, isEnable) => {
    setIsEnableCurrency(isEnable)
    setIsBulkUpdate(false)
    setUpdateCurrencyStatusCount(1)
    setUpdateCurrencyCode(code)
    setIsChangeCurrencyStatusDialogOpen(true)
  }

  return (
    <>
      <CurrencyConfigSettingPanelComponent
        data={data}
        totalRecords={totalRecords}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleChangeCurrencyConfigStatus={handleShowChangeCurrencyStatusDialog}
        handleSetDefaultCurrency={handleSetDefaultCurrency}
        handleDeleteCurrencyConfig={handleDeleteCurrencyConfig}
        handleReload={handleReload}
        handleSelectAll={handleSelectAll}
        handleDeselectAll={handleDeselectAll}
        handleSelectCurrencyConfig={handleSelectCurrencyConfig}
        isFetching={isFetching}
        page={filter.page}
        rowsPerPage={filter.rowsPerPage}
        paginationOptions={paginationOptions}
        handleAddCurrencyConfig={handleAddCurrency}
        isSelectAll={isSelectAll}
        isSelected={selectedCount > 0}
        selectedCount={selectedCount}
        handleCurrencyConfigStatus={handleSingleCurrencyConfigStatus}
        isLoadingCurrencyConfigListError={isLoadingCurrencyConfigListError}
        handleCreateCurrencyConfig={handleAddCurrency}
        lang={lang}
        hasCompanyCurrencyEditAccessRight={hasCompanyCurrencyEditAccessRight}
      // handleUpdateCurrencyConfig={handleUpdateCurrencyConfig}
      />
      <AddCurrencyConfigDialog
        isOpen={isAddCurrencyDialogOpen}
        handleClose={() => {
          handleAddCurrency(false);
          setIsNewCurrencySelected(false);
        }}
        handleSubmit={handleSubmitCurrencyConfig}
        isNewCurrencySelected={isNewCurrencySelected}
        setIsNewCurrencySelected={setIsNewCurrencySelected}

      />
      <GeneralDialog
        isOpen={isChangeCurrencyStatusDialogOpen}
        handleClose={() => setIsChangeCurrencyStatusDialogOpen(false)}
        handleProceed={isBulkUpdate ? handleSubmitBulkChangeCurrencyConfigStatus : handleSubmitSingleChangeCurrencyConfigStatus}
        title={(isEnableCurrency ? getLang(lang, "label.ENABLE") : getLang(lang, "label.DISABLE")) + getLang(lang, "label.CURRENCY")}
        description={(isEnableCurrency ? getLang(lang, "paragraph.ENABLE_CURRENCY_QUESTION") : getLang(lang, "paragraph.DISABLE_CURRENCIES_QUESTION")) + (updateCurrencyStatusCount > 1 ? getLang(lang, "paragraph.CURRENCIES_COUNT", { count: updateCurrencyStatusCount })
          : getLang(lang, "paragraph.CURRENCY_COUNT", { count: updateCurrencyStatusCount }))}
        type="danger"
        icon={<img src={AlertIcon} alt="alert" />}
      />
      <GeneralDialog
        isOpen={isDeleteCurrencyConfigDialogOpen}
        handleClose={() => setIsDeleteCurrencyConfigDialogOpen(false)}
        handleProceed={handleSubmitDeleteCurrencyConfig}
        title={getLang(lang, "label.DELETE_CURRENCY")}
        description={getLang(lang, "paragraph.DELETE_CURRENCY_QUESTION", { name: deleteCurrencyName })}
        type="danger"
        icon={<img src={AlertIcon} alt="alert" />}
      />
    </>
  )
}

export default CurrencyConfigSettingPanelContainer;