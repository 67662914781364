import axios from "axios";

const post = (email) => {
  const body = {
    email: email
  };

  return new Promise((resolve, reject) => {
    axios
      .post("/account/api/v1/users/emailexists", body)
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

const checkEmailExists = {
  post
};

export default checkEmailExists;
