import { makeStyles } from "@material-ui/core";

export const flexGapStyle = makeStyles((theme) => ({

    global__flexGap20: {
        gap: "20px"
    },
    global__flexGap3 :{
        gap: "3px"
    }
}))