import React from "react";
import { Box, makeStyles } from "@material-ui/core";
import { Formik } from "formik";
import { TYPES } from "./surveyForm.container";
import { ModalFooter } from "../../modal";
import SurveySelect from "../../select/surveySelect";
import { getLang } from "app/feature/constants";
import SurveyDetailCard from "../../card/surveyDetailCard";

const useStyle = makeStyles((theme) => ({
  container: {
    maxHeight: "75vh",
    overflowY: "auto",
    backgroundColor: "#f0f3f5",
    padding: 16,
  },
}));

export default function SurveyFormComponent({
  type,
  initialValues,
  handleDelete,
  handleSubmit,
  lang,
  handleClose,
}) {
  const classes = useStyle();

  return (
    <Formik
      initialValues={{
        id: initialValues?.id ?? null,
      }}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <form onSubmit={formik.handleSubmit}>
          <Box className={classes.container}>
            <SurveySelect
              value={formik.values.id}
              handleChange={(value) => {
                if (value) {
                  formik.setFieldValue("id", value.uuid);
                }
              }}
              disabled={false}
              styles={{
                backgroundColor: "#FFFFFF",
                borderRadius: 8,
                border: "1px solid #D0D5DD",
              }}
              searchable={true}
              placeholder={getLang(lang, "placeholder.SELECT_SURVEY")}
            />
            {!!formik.values.id && (
              <Box mt={2}>
                <SurveyDetailCard id={formik.values.id} />
              </Box>
            )}
          </Box>
          <ModalFooter
            handleDelete={handleDelete}
            showDelete={type !== TYPES[0]}
            handleClose={handleClose}
          />
        </form>
      )}
    </Formik>
  );
}
