import React from "react"
import {
  Box,
  // Button,
  makeStyles,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Paper,
  Link,
} from "@material-ui/core"
import {
  StyledTableCell,
  StyledTableRow,
} from "components/table"
import { SkeletonTableRowStickyLeftRight } from "components/skeleton/skeleton.component"
import { TablePaginationActions } from "components/tablePagination"
import RefreshTable from "components/table/refreshTable"
import { ROOT } from "modules/stockflow/config/stockflow.route"
import { getLang } from "app/feature/constants"

const useStyle = makeStyles((theme) => ({
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    borderCollapse: "separate",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
    },
    "& th,td": {
      fontSize: ".75rem",
      borderRight: "1px solid rgba(58, 77, 84, 0.1)",
      padding: theme.spacing(1, 2, 1, 2),
    },
    "& th": {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightBold,
    },
    "& th:last-child, td:last-child": {
      width: 37,
      borderRight: "0px",
    },
    "& th th": {
      borderBottom: "none !important",
    },
    "& td td": {
      borderBottom: "none !important",
    },
  },
  tableContainer: {
    maxHeight: 500,
    overflowY: "auto",
  },
  emptyContainer: {
    backgroundColor: theme.palette.background.paper,
    textAlign: "center",
  },
  button: {
    display: "flex",
    alignItems: "center",
    padding: 0,
    minWidth: 0,
    marginLeft: theme.spacing(1),
  },
  paperRoot: {
    width: "300px",
  },
}))

export default function LooseItemListTableComponent({
  hasStockflowInventoryViewAccessRight,
  rows,
  isFetching,
  isError,
  handleReload,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowPerPage,
  isSearchResult,
  totalFiltered,
  token,
  paginationOptions,
  lang,
  isDealerPage,
  dealerId,
  handleViewDetail
}) {
  const classes = useStyle()

  return (
    <>
      {!isFetching && isError && <RefreshTable handleReload={handleReload} />}
      {isFetching || rows.length > 0 ? (
        <Paper id="test"style={{overflowX: 'auto', width: "100%"}}>
          <TableContainer>
            <Table
              className={classes.table}
              aria-label="loose-item table"
              size="small"
              stickyHeader
            >
              <TableHead style={{ height: "45px" }}>
                <TableRow>
                  <StyledTableCell style={{ minWidth: "20rem" }}>{getLang(lang, "label.LOOSE_ITEM_TAB")}</StyledTableCell>
                  <StyledTableCell>{getLang(lang, "label.QUANTITY")}</StyledTableCell>
                  <StyledTableCell>{getLang(lang, "label.PACKAGES")}</StyledTableCell>
                  {!isDealerPage && (
                    <StyledTableCell>{getLang(lang, "label.BRANCH_ASSIGNED")}</StyledTableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {isFetching
                  ? [...new Array(5)].map((v, index) => (
                    <SkeletonTableRowStickyLeftRight key={index} columnCount={isDealerPage ? 3 : 4} />
                  ))
                  : rows.map((row, index) => {
                    return (
                      <StyledTableRow
                        key={row.id}
                        hover
                        role="checkbox"
                        tabIndex={-1}
                      >
                        <StyledTableCell>
                          <Box display="flex" alignItems="center">
                            {hasStockflowInventoryViewAccessRight ? (
                              <Link
                                href={
                                  `${ROOT}/inventory/loose-item/detail?code=${row.code}` + 
                                  (isDealerPage && dealerId ? `&dealer=${dealerId}` : '')
                                }
                                color="secondary"
                                variant="caption"
                                onClick={(e) => {
                                  let url = `${ROOT}/inventory/loose-item/detail?code=${row.code}`
                                  if (isDealerPage && dealerId) {
                                    url += `&dealer=${dealerId}`
                                  }
                                  handleViewDetail(url)
                                  e.preventDefault();
                                  return false;
                                }}
                              >
                                {row.code}
                              </Link>
                            ) : (
                              row.code
                            )}
                          </Box>
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          <span>
                            {(row.quantity || 0).toLocaleString("en-US")}
                          </span>
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          <span>
                            {row.packages ? row.packages.toLocaleString("en-US") : '-'}
                          </span>
                        </StyledTableCell>
                        {!isDealerPage && (
                          <StyledTableCell>
                            {row.nodes.map((n, i) => (
                              <span>
                                {i > 0 ? ", " : ""}{n.name}
                              </span>
                            )) || '-'}
                          </StyledTableCell>
                        )}
                      </StyledTableRow>
                    )
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            style={{
              backgroundColor: "#fff",
              boxShadow: "0px -1px 0px rgba(58, 77, 84, 0.15)",
            }}
            ActionsComponent={TablePaginationActions}
            rowsPerPageOptions={paginationOptions}
            component="div"
            count={totalFiltered}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowPerPage}
            labelRowsPerPage={getLang(lang, 'label.ROWS_PER_PAGE')}
            labelDisplayedRows={({ from, to, count }) => `${from.toLocaleString("en-US")}-${to.toLocaleString("en-US")} ${getLang(lang, 'label.OF')} ${count.toLocaleString("en-US")}`}
          />
        </Paper>
      ) : null}
      {!isFetching && !isError && rows.length === 0 && (
        <Box>
          <Table
            className={classes.table}
            aria-label="product table"
            size="small"
            stickyHeader
          >
            <TableHead style={{ height: "45px" }}>
              <TableRow>
                <StyledTableCell style={{ minWidth: "20rem" }}>{getLang(lang, "label.LOOSE_ITEM_TAB")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.QUANTITY")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.PACKAGES")}</StyledTableCell>
                {!isDealerPage && (
                  <StyledTableCell>{getLang(lang, "label.BRANCH_ASSIGNED")}</StyledTableCell>
                )}
              </TableRow>
            </TableHead>
          </Table>
          <Box py={9} className={classes.emptyContainer}>
            <Typography variant="body1" style={{ paddingBottom: "16px", fontWeight: "bold" }}>
              {isSearchResult || isDealerPage
                ? getLang(lang, "paragraph.NO_LOOSE_ITEM_EXIST")
                : getLang(lang, "paragraph.INVENTORY_EMPTY")}
            </Typography>
            {!isSearchResult && !isDealerPage && (
              <>
                <Typography variant="body2" style={{ paddingBottom: "16px" }}>
                  {getLang(lang, "paragraph.INVENTORY_INSTRUCTION")}
                </Typography>
                {/* <Button
                  onClick={() => {
                    // TODO: add link
                  }}
                  variant="contained"
                  disableElevation
                  color="primary"
                >
                  {getLang(lang, "label.CREATE_QR_CODE")}
                </Button> */}
              </>
            )}
          </Box>
        </Box>
      )}
    </>
  )
}
