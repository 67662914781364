import React from "react"
import AssignedProductTableComponent from './assignedProductTable.component';
import { useSelector } from "react-redux";

export default function AssignedProductTableContainer({
  rows,
  handleDelete
}) {
  const lang = useSelector(state => state.constant.languages);

  return (
    <AssignedProductTableComponent rows={rows} handleDelete={handleDelete} lang={lang}/>
  )
}