import { createSlice } from "@reduxjs/toolkit";
import { SERIAL_NUMBER_BATCH } from "../../constants";
import {
  getGenerateBatch,
  updateBatchAppAuthorizeStatus,
  updateBatchRedirectTo,
  updateBatchScanVerifyStatus,
} from "../action";

const initialState = {
  SerialNumberBatchTable: {
    data: [],
    totalRecords: 0,
    filteredTotalRecords: 0,
  },
  isLoadingSerialNumberBatchError: false,
};

const generateBatchSlice = createSlice({
  name: SERIAL_NUMBER_BATCH,
  initialState,
  reducers: {
    setGenerateBatch(state, action) {
      const { payload } = action;
      const tempData = [];

      state.SerialNumberBatchTable.totalRecords = payload.recordsTotal;
      state.SerialNumberBatchTable.filteredTotalRecords =
        payload.recordsFiltered;

      payload.data.forEach((element) => {
        const order = {
          id: element.id || "",
          uuid: element.uuid || "",
          batchNumber: element.batch_number || "",
          prefix: element.format.split(",")[0],
          length: element.format.split(",")[1],
          postfix: element.format.split(",")[2],
          type: element.format.split(",")[3],
          format: element.format || "",
          genType: element.gen_type || "",
          branchName: element.branch_name || "-",
          status: element.status || "",
          appAuthorizeRequire: element.app_authorize_require || 0,
          isVerifyRequired: element.is_verify_required || 0,
          redirectTo: element.redirect_to,
          datetime: element.datetime || "",
          quantity: element.quantity || 0,
          range: [],
          fromSeqNum: element.fromSeqNum,
          toSeqNum: element.toSeqNum,
        };
        order.range = element.range || [];

        tempData.push(order);
      });

      state.SerialNumberBatchTable.data = tempData;
    },
    changeBatchQuickEdit(state, action) {
      const { payload } = action;

      state.SerialNumberBatchTable.data[payload.index].isEdit = payload.isEdit;
    },
    quickUpdateBatch(state, action) {
      const { payload } = action;

      state.SerialNumberBatchTable.data[payload.index].tempRedirectTo =
        payload.value;
    },
  },
  extraReducers: {
    [getGenerateBatch.fulfilled]: (state) => {
      state.isLoadingSerialNumberBatchError = false;
    },
    [getGenerateBatch.rejected]: (state) => {
      state.isLoadingSerialNumberBatchError = true;
    },
    [updateBatchAppAuthorizeStatus.fulfilled]: (state, action) => {
      const { payload } = action;
      const index = state.SerialNumberBatchTable.data.findIndex(
        (data) => data.uuid === payload.sn_job_uuid
      );
      state.SerialNumberBatchTable.data[index] = {
        ...state.SerialNumberBatchTable.data[index],
        appAuthorizeRequire: payload.is_use_auth_app || 0,
      };
    },
    [updateBatchScanVerifyStatus.fulfilled]: (state, action) => {
      const { payload } = action;
      const index = state.SerialNumberBatchTable.data.findIndex(
        (data) => data.uuid === payload.sn_job_uuid
      );
      state.SerialNumberBatchTable.data[index] = {
        ...state.SerialNumberBatchTable.data[index],
        isVerifyRequired: payload.is_verify_required || 0,
      };
    },
    [updateBatchRedirectTo.fulfilled]: (state, action) => {
      const { payload } = action;
      const index = state.SerialNumberBatchTable.data.findIndex(
        (data) => data.uuid === payload.sn_job_uuid
      );
      state.SerialNumberBatchTable.data[index] = {
        ...state.SerialNumberBatchTable.data[index],
        redirectTo: payload.redirect_to,
      };
    },
  },
});

export const { setGenerateBatch, changeBatchQuickEdit, quickUpdateBatch } =
  generateBatchSlice.actions;
export const serialNumberBatchReducer = generateBatchSlice.reducer;
