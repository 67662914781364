import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectLoading } from "modules/notification";
import { generatePaginationOptions, updateUrlQueryParam } from "lib/helper";
import OrderListingComponent from "./orderListing.component";
import {
  changeBulkSelectedAvailableOrder,
  changeBulkSelectedOrder,
  changeSelectedOrder,
  exportOrder,
  getAcceptedOrderList,
  getCancelledOrderList,
  getNewOrderList,
  getRejectedOrderList,
  resetOrderListing,
} from "modules/stockflow/redux";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  STOCKFLOW_ORDER_VIEW,
  STOCKFLOW_DEALER_VIEW,
} from "lib/constants/accessRights";

function OrderListingContainer({ type }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const hasStockflowOrderViewAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(STOCKFLOW_ORDER_VIEW)
  );
  const hasStockflowDealerViewAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(STOCKFLOW_DEALER_VIEW)
  );
  const lang = useSelector((state) => state.constant.languages);
  const token = useSelector((state) => state.session.accessToken);
  const isNewOrderFetching = useSelector((state) =>
    selectLoading(state, getNewOrderList.typePrefix)
  );
  const isAcceptedOrderFetching = useSelector((state) =>
    selectLoading(state, getAcceptedOrderList.typePrefix)
  );
  const isRejectedOrderFetching = useSelector((state) =>
    selectLoading(state, getRejectedOrderList.typePrefix)
  );
  const isCancelledOrderFetching = useSelector((state) =>
    selectLoading(state, getCancelledOrderList.typePrefix)
  );

  const totalFiltered = useSelector(
    (state) => state.stockflowOrder[`${type}OrderList`].totalFiltered
  );
  const totalRecords = useSelector(
    (state) => state.stockflowOrder[`${type}OrderList`].totalRecords
  );
  const orderList = useSelector(
    (state) => state.stockflowOrder[`${type}OrderList`].list
  );

  const selectedIds = useSelector((state) => state.stockflowOrder.selectedIds);
  const isSelectAll = useSelector((state) => state.stockflowOrder.isSelectAll);
  const isError = useSelector(
    (state) => state.stockflowOrder.isLoadingOrderListError
  );
  const isSearchResult = orderList.length < totalRecords;
  const [paginationOptions, setPaginationOptions] = useState([]);
  const [isExporting, setIsExporting] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isFilterUpdated, setIsFilterUpdated] = useState(false);
  const [tableFilter, setTableFilter] = useState({
    page: 0,
    rowsPerPage: 25,
    order: "desc",
    orderBy: "created_at",
    search: "",
    nodeIds: [],
    branch: [],
    fromDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
    toDate: moment().format("YYYY-MM-DD"),
    requestFrom: [],
    requestTo: [],
    respondDate: null,
  });

  const query = new URLSearchParams(window.location.search);
  const tableFilterInitialRef = useRef(true);

  useEffect(() => {
    return () => {
      dispatch(resetOrderListing());
    };
  }, [dispatch]);

  useEffect(() => {
    const isFilter = query.has("is_filter");
    let filter = sessionStorage.getItem("order_filter");

    let temp = { ...tableFilter };
    if (isFilter && filter) {
      temp = JSON.parse(filter);
      setIsFilterUpdated(true);
      setIsFilterOpen(true);
    }

    if (isFilter) {
      updateUrlQueryParam(null, ["is_filter"]);
    }
    sessionStorage.removeItem("order_filter");

    setTableFilter(temp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!tableFilterInitialRef.current) {
      dispatch(resetOrderListing());
      handleDataLoad();
    }
    tableFilterInitialRef.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, tableFilter]);

  useEffect(() => {
    setPaginationOptions(generatePaginationOptions(totalFiltered));
  }, [totalFiltered]);

  const handleDataLoad = () => {
    let payload = {
      length: tableFilter.rowsPerPage,
      start: tableFilter.rowsPerPage * tableFilter.page,
      order: tableFilter.order,
      orderBy: tableFilter.orderBy,
      filter: {
        search: tableFilter.search,
        nodes: tableFilter.nodeIds,
        from_date: tableFilter.fromDate,
        to_date: tableFilter.toDate,
        request_from: tableFilter.requestFrom,
        request_to: tableFilter.requestTo,
        respond_date: tableFilter.respondDate,
      },
    };
    switch (type) {
      case "new":
        dispatch(getNewOrderList(payload));
        break;
      case "accepted":
        dispatch(getAcceptedOrderList(payload));
        break;
      case "rejected":
        dispatch(getRejectedOrderList(payload));
        break;
      case "cancelled":
        dispatch(getCancelledOrderList(payload));
        break;
      default:
        break;
    }
  };

  const handleChangePage = (event, newPage) => {
    let newData = {
      ...tableFilter,
      page: newPage,
    };
    setIsFilterUpdated(true);
    setTableFilter(newData);
  };

  const handleChangeRowsPerPage = (event) => {
    let newData = {
      ...tableFilter,
      page: 0,
      rowsPerPage: event.target.value,
    };
    setIsFilterUpdated(true);
    setTableFilter(newData);
  };

  const handleSearch = (filter) => {
    let nodeIds =
      filter.branch.length > 0 ? filter.branch.map((br) => br.node_id) : [];

    let newData = {
      ...tableFilter,
      page: 0,
      search: filter.search,
      fromDate: filter.dateRange?.from
        ? moment(filter.dateRange.from).format("YYYY-MM-DD")
        : "",
      toDate: filter.dateRange?.to
        ? moment(filter.dateRange.to).format("YYYY-MM-DD")
        : "",
      nodeIds: nodeIds,
      branch: filter.branch,
      respondDate: filter.respondDate,
      requestFrom: filter.requestFrom,
      requestTo: filter.requestTo,
    };
    setIsFilterUpdated(true);
    setTableFilter(newData);
  };

  const clearSearch = () => {
    let newData = {
      ...tableFilter,
      page: 0,
      search: "",
      nodeIds: [],
      branch: [],
      fromDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
      toDate: moment().format("YYYY-MM-DD"),
      requestFrom: [],
      requestTo: [],
      respondDate: null,
    };
    setIsFilterUpdated(false);
    setTableFilter(newData);
  };

  const handleSelectOrder = (index, isSelected) => {
    dispatch(changeSelectedOrder({ type, index, isSelected }));
  };

  const handleSelectAvailable = () => {
    dispatch(changeBulkSelectedAvailableOrder({ type, value: true }));
  };

  const handleSelectAll = () => {
    dispatch(changeBulkSelectedOrder({ type, value: true }));
  };

  const handleDeselectAll = () => {
    dispatch(changeBulkSelectedOrder({ type, value: false }));
  };

  const changeOrder = (orderBy, order) => {
    let newData = {
      ...tableFilter,
      order,
      orderBy,
    };
    setIsFilterUpdated(true);
    setTableFilter(newData);
  };

  const resetFilter = () => {
    let newData = {
      ...tableFilter,
      page: 0,
      nodeIds: [],
      branch: [],
      respondDate: null,
      requestFrom: [],
      requestTo: [],
    };
    setIsFilterUpdated(true);
    setTableFilter(newData);
  };

  const handleViewDetail = (url) => {
    if (isFilterUpdated) {
      updateUrlQueryParam({ is_filter: true });
      sessionStorage.setItem("order_filter", JSON.stringify(tableFilter));
    }
    history.push(url);
  };
  const handleExport = (fileType) => {
    setIsExporting(true);
    const info = {
      token: token,
      fileType: fileType,
      orderType: type,
      ...tableFilter,
    };
    dispatch(exportOrder(info))
      .then(unwrapResult)
      .finally(() => {
        setIsExporting(false);
      });
  };
  return (
    <>
      <OrderListingComponent
        hasStockflowOrderViewAccessRight={hasStockflowOrderViewAccessRight}
        hasStockflowDealerViewAccessRight={hasStockflowDealerViewAccessRight}
        type={type}
        orders={orderList}
        isFetching={
          type === "cancelled"
            ? isCancelledOrderFetching
            : type === "accepted"
            ? isAcceptedOrderFetching
            : type === "rejected"
            ? isRejectedOrderFetching
            : isNewOrderFetching
        }
        selectedIds={selectedIds}
        isSelectAll={isSelectAll}
        selectedCount={
          !isSelectAll ? selectedIds.length : totalFiltered - selectedIds.length
        }
        isError={isError}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        page={tableFilter.page}
        rowsPerPage={tableFilter.rowsPerPage}
        totalFiltered={totalFiltered}
        handleSearch={handleSearch}
        clearSearch={clearSearch}
        handleReload={handleDataLoad}
        isSearchResult={isSearchResult}
        handleSelectOrder={handleSelectOrder}
        handleSelectAvailable={handleSelectAvailable}
        handleSelectAll={handleSelectAll}
        handleDeselectAll={handleDeselectAll}
        paginationOptions={paginationOptions}
        isExporting={isExporting}
        isFilterOpen={isFilterOpen}
        setIsFilterOpen={setIsFilterOpen}
        tableFilter={tableFilter}
        changeOrder={changeOrder}
        lang={lang}
        resetFilter={resetFilter}
        handleViewDetail={handleViewDetail}
        handleExport={handleExport}
      />
    </>
  );
}

export default OrderListingContainer;
