import React from 'react'
import PointListingTable from '../../table/pointListingTable'
import { Box } from '@material-ui/core'
import WithProductGeneralSearch from "../../../../../components/search/withProductGeneralSearch";

export default function PointListingPanelComponent({
  hasLoyaltyProgramAddAccessRight,
  hasLoyaltyProgramEditAccessRight,
  hasLoyaltyProgramActivateOrDeactivateAccessRight,
  data,
  isFetching,
  page,
  search,
  rowsPerPage,
  totalRecords,
  handleChangePage,
  handleChangeRowsPerPage,
  handleSearch,
  handleCreate,
  handleStatusChange,
  handleReload,
  paginationOptions,
  productDropdown,
  isFetchingProduct,
}) {
  return (
    <Box>
      <WithProductGeneralSearch
        handleFilter={(value) => {
          handleSearch(value);
        }}
        productDropdown={productDropdown}
        isFetchingProduct={isFetchingProduct}
      />
      <PointListingTable
        hasLoyaltyProgramAddAccessRight={hasLoyaltyProgramAddAccessRight}
        hasLoyaltyProgramEditAccessRight={hasLoyaltyProgramEditAccessRight}
        hasLoyaltyProgramActivateOrDeactivateAccessRight={hasLoyaltyProgramActivateOrDeactivateAccessRight}
        data={data}
        isFetching={isFetching}
        page={page}
        search={search}
        rowsPerPage={rowsPerPage}
        totalRecords={totalRecords}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleCreate={handleCreate}
        handleStatusChange={handleStatusChange}
        handleReload={handleReload}
        paginationOptions={paginationOptions}
      />
    </Box>
  )
}
