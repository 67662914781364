import React from "react";
import { Backdrop, Dialog, makeStyles } from "@material-ui/core";
import { PointCutForm } from "../../form";

const useStyle = makeStyles(theme => ({
  dialog: {
    maxWidth: "none"
  }
}))

export default function RedeemItemDialogComponent({
  headerType,
  isOpen,
  handleClose
}) {
  const classes = useStyle();
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      disableEnforceFocus={true}
      disableAutoFocus={true}
      disableEscapeKeyDown={true}
      disableBackdropClick={true}
      closeAfterTransition
      BackdropComponent={Backdrop}
      maxWidth="700px"
      classes={{
        paperWidthSm: classes.dialog
      }}
    >
      <PointCutForm
        handleClose={handleClose}
        headerType={headerType}
      />
    </Dialog>
  )
}
