import React from "react"
import ProductInfoCardComponent from "./productInfoCard.component";

export default function ProductInfoCardContainer({
  product,
  isFetching,
  lang
}) {
  return (
    <ProductInfoCardComponent
      product={product} 
      isFetching={isFetching}
      lang={lang}
    />
  )
}