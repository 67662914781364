import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { unwrapResult } from '@reduxjs/toolkit';
import {addAlert, selectLoading} from 'modules/notification';
import {
  addBrandModel,
  getAllBrandModelsListing,
  updateBrandModelStatus,
  editBrandModel,
  deleteBrandModels,
  updateBrandModelsStatus,
  addModelsToExistingBrand,
  getBrandDropdownWithNoBrand
} from "modules/product-mgmt/redux/action/brandModels.action";
import {
  setBrandModelEditDetails,
  changeBulkSelectedBrandModel,
  changeSelectedBrandModel,
  changeBulkSelectedAvailableBrandModel,
  resetBrandModelListing
} from "../../../redux/slice/products.slice";
import { PRODUCT_BRAND_MODEL_VIEW, PRODUCT_BRAND_MODEL_EDIT, PRODUCT_BRAND_MODEL_DELETE } from "lib/constants/accessRights"
import BrandModelListPanelComponent from './brandModelListPanel.component';
import CreateBrandModelDialog from 'modules/product-mgmt/components/dialog/createBrandModelDialog';
import EditBrandModelDialog from '../../dialog/editBrandModelDialog';
import GeneralDialog from 'components/dialog/generalDialog';
import AlertIcon from 'assets/svg/alert.svg';
import { generatePaginationOptions } from "lib/helper";
import { getLang } from "app/feature/constants";
import {setImgFormatAndSize} from "../../../../../lib/FileHelper";
import {FolderType} from "../../../../../lib/constants/aws_s3";
import {uploadImageToS3} from "../../../../../app/feature";
import {hideBackdrop} from "../../../../backdrop";
import {isDataUrl} from "../../../../../lib/generalUtility";

function BrandModelListPanelContainer({
  handleCreateBrandModel
}) {
  const dispatch = useDispatch()
  const history = useHistory();

  const isCreateDialogOpen = useSelector(state => state.products.isOpen)
  const hasProductBrandModelViewAccessRight = useSelector(state => state.profile.accessRights.includes(PRODUCT_BRAND_MODEL_VIEW));
  const hasProductBrandModelEditAccessRight = useSelector(state => state.profile.accessRights.includes(PRODUCT_BRAND_MODEL_EDIT));
  const hasProductBrandModelDeleteAccessRight = useSelector(state => state.profile.accessRights.includes(PRODUCT_BRAND_MODEL_DELETE));
  const totalFiltered = useSelector(state => state.products.brandModelList.totalFiltered);
  const hasNoBrandRecord = useSelector(state => state.products.brandModelList.hasNoBrandRecord);
  const data = useSelector(state => state.products.brandModelList.data)
  const selectedIds = useSelector(state => state.products.brandModelList.selectedId)
  const isFetching = useSelector(state => selectLoading(state, getAllBrandModelsListing.typePrefix));
  const isLoadingBrandModelListError = useSelector(state => state.products.brandModelList.isError);
  const brandModelDetails = useSelector(state => state.products.brandModelList.brandModelDetails)
  const lang = useSelector(state => state.constant.languages);
  const brandDropdownWithNoBrand = useSelector(state => state.products.brandModelList.brandDropdownWithNoBrand)
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [deleteBrandId, setDeleteBrandId] = useState(true);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [paginationOptions, setPaginationOptions] = useState([]);
  const [searchBrandModelDropdownWithNoBrand, setSearchable] = useState('');
  const [filter, setFilter] = useState({
    search: "",
    status: "",
    page: 0,
    rowsPerPage: 25
  })
  const [isSaving, setIsSaving] = useState(false);

  useEffect(()=>{
    if(!hasProductBrandModelViewAccessRight){
      history.push('/admin/dashboard')
    }
  }, [history, hasProductBrandModelViewAccessRight])

  useEffect(() => {
    return () => {
      dispatch(resetBrandModelListing())
    }
    // eslint-disable-next-line
  }, [dispatch])

  useEffect(() => {
    dispatch(getAllBrandModelsListing({
      length: filter.rowsPerPage,
      start: filter.rowsPerPage * filter.page,
      search: filter.search,
      status: filter.status,
    }))
  }, [
    dispatch,
    filter
  ])

  useEffect(() => {
    setPaginationOptions(generatePaginationOptions(totalFiltered))
  }, [totalFiltered])

  const handleFetchBrandModelList = () => {
    setFilter((prev) => ({
      ...prev,
      page: 0
    }))
    handleDeselectAll()
  }

  const handleReload = () => {
    dispatch(getAllBrandModelsListing({
      length: filter.rowsPerPage,
      start: filter.rowsPerPage * filter.page,
      search: filter.search,
      status: filter.status,
    }))
  }

  const handleChangePage = (event, newPage) => {
    setFilter((prev) => ({
      ...prev,
      page: newPage,
    }))
  }

  const handleChangeRowsPerPage = event => {
    setFilter((prev) => ({
      ...prev,
      page: 0,
      rowsPerPage: event.target.value
    }))
  }

  const handleSearch = value => {
    let tempStatus = "";

    if (value.status === "Enable") {
      tempStatus = "enabled"
    } else if (value.status === "Disable") {
      tempStatus = "disabled"
    }
    setFilter({
      search: value.search,
      status: tempStatus,
      page: 0,
      rowsPerPage: filter.rowsPerPage
    })
    handleDeselectAll()
  }

  const handleSearchBrandModel = value => {
    setSearchable(value)
  }

  const handleSubmitBrandModel = async (value) => {
    if (value.brandOption === 'add_new') {
      dispatch(addBrandModel({
        name: value.brand,
        models: value.models,
      }))
        .then(unwrapResult)
        .then(() => {
          handleFetchBrandModelList()
          handleCreateBrandModel(false)
        })
    } else {
      dispatch(addModelsToExistingBrand({
        brand_uuid: value.brand_uuid.value,
        models: value.models
      }))
        .then(unwrapResult)
        .then(() => {
          handleFetchBrandModelList()
          handleCreateBrandModel(false)
        })
    }
  }


  const handleBrandModelStatus = (id, status) => {
    dispatch(updateBrandModelStatus({ id: id, status: status ? "enabled" : "disabled" }))
  }

  const handleEditBrandModel = (isOpen, brand) => {
    setEditDialogOpen(isOpen)
    if (isOpen) {
      dispatch(setBrandModelEditDetails(brand))
    }
  }

  const handleSubmitEditBrandModel = async (values) => {
    setIsSaving(true);
    let tempModels = []

    values.models.map(model => {
      tempModels.push({
        uuid: model.id,
        name: model.name,
        status: model.status
      })
      return null;
    })

    let url;
    if (values.details.imageUrl && isDataUrl(values.details.imageUrl)) {
      url = await uploadImage(values.details.imageUrl, values.details.id);
    }

    dispatch(editBrandModel({
      id: values.details.id,
      name: values.details.name,
      description: values.details.description,
      imageUrl: url ?? values.details.imageUrl,
      status: values.details.status,
      models: tempModels
    }))
      .then(unwrapResult)
      .then(() => {
        handleFetchBrandModelList()
        setEditDialogOpen(false)
      })
      .finally(() => {
        setIsSaving(false);
      });

  }

  async function uploadImage(base64Image, brandId) {
    let url = ""

    base64Image = await setImgFormatAndSize(base64Image, 2160, 2160, "image/webp");

    const uploadInfo = {
      uploadType: FolderType.branchProfile,
      base64Image: base64Image,
      fileName: `brandImage${brandId}${Date.now()}.webp`,
      id: brandId
    }

    await dispatch(uploadImageToS3(uploadInfo))
      .then(unwrapResult)
      .then(imageUrl => {
        url = imageUrl.split('?')[0]
      })
      .catch(error => {
        dispatch(hideBackdrop());
        dispatch(
          addAlert({
            severity: "error",
            message: error.message || getLang(lang,"message.error.FAILED_UPLOAD_PICTURE")
          })
        )
      })

    return url
  }

  const handleDeleteBrandModel = (id) => {
    setIsDeleteDialogOpen(true)
    setDeleteBrandId(id)
  }

  const handleSubmitDeleteBrandModel = () => {
    dispatch(deleteBrandModels({
      id: deleteBrandId
    }))
      .then(unwrapResult)
      .then(() => handleFetchBrandModelList())
      .finally(() => {
        setIsDeleteDialogOpen(false)
        dispatch(getBrandDropdownWithNoBrand())
      })
  }

  const handleSelectBrandModel = (index, isSelected) => {
    dispatch(changeSelectedBrandModel({ index: index, isSelected: isSelected }))
  }

  const handleSelectAvailable = () => {
    dispatch(changeBulkSelectedAvailableBrandModel({ value: true }))
  }

  const handleSelectAll = () => {
    setIsSelectAll(true)
    dispatch(changeBulkSelectedBrandModel({ value: true }))
  }

  const handleDeselectAll = () => {
    setIsSelectAll(false)
    dispatch(changeBulkSelectedBrandModel({ value: false }))
  }

  const handleChangeBrandModels = (value) => {
    const tempFilter = {
      length: filter.rowsPerPage,
      start: filter.rowsPerPage * filter.page,
      search: filter.search,
      status: filter.status,
    }

    const tempUpdate = {
      status: value === "Enable Selected" ? "enabled" : "disabled",
      isselectedall: isSelectAll,
      uuid: selectedIds
    }

    dispatch(updateBrandModelsStatus({ filter: tempFilter, update: tempUpdate }))
      .then(() => {
        handleFetchBrandModelList()
      })
  }

  const searchResult = () => {
    return brandDropdownWithNoBrand.filter(function (obj) {
      return obj.name.includes(searchBrandModelDropdownWithNoBrand);
    });

  }

  return (
    <>
      <BrandModelListPanelComponent
        hasProductBrandModelEditAccessRight={hasProductBrandModelEditAccessRight}
        hasProductBrandModelDeleteAccessRight={hasProductBrandModelDeleteAccessRight}
        data={data}
        totalFiltered={totalFiltered}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleReload={handleReload}
        handleSearch={handleSearch}
        selectedCount={!isSelectAll ? selectedIds.length : (totalFiltered - (hasNoBrandRecord ? 1 : 0)) - selectedIds.length}
        isFetching={isFetching}
        isSelectAll={isSelectAll}
        selectedIds={selectedIds}
        paginationOptions={paginationOptions}
        isSelected={(!isSelectAll && selectedIds.length > 0) || (isSelectAll && selectedIds.length < (totalFiltered - (hasNoBrandRecord ? 1 : 0)))}
        page={filter.page}
        search={filter.search}
        statusFilter={filter.status}
        rowsPerPage={filter.rowsPerPage}
        isLoadingBrandModelListError={isLoadingBrandModelListError}
        handleChangeStatus={handleBrandModelStatus}
        handleEditBrandModel={handleEditBrandModel}
        handleCreateBrandModel={handleCreateBrandModel}
        handleDeleteBrandModel={handleDeleteBrandModel}
        handleSelectBrandModel={handleSelectBrandModel}
        handleSelectAvailable={handleSelectAvailable}
        handleSelectAll={handleSelectAll}
        handleDeselectAll={handleDeselectAll}
        handleChangeBrandModels={handleChangeBrandModels}
        lang={lang}
      />
      <CreateBrandModelDialog
        isOpen={isCreateDialogOpen}
        handleClose={() => {
          handleCreateBrandModel(false)
        }}
        brandDropdownWithNoBrand={searchResult()}
        handleSearch={handleSearchBrandModel}
        handleSubmit={handleSubmitBrandModel}
      />
      <EditBrandModelDialog
        isOpen={editDialogOpen}
        handleClose={() => handleEditBrandModel(false)}
        handleSubmit={handleSubmitEditBrandModel}
        data={brandModelDetails}
        isSaving={isSaving}
      />
      <GeneralDialog
        isOpen={isDeleteDialogOpen}
        handleClose={() => setIsDeleteDialogOpen(false)}
        handleProceed={handleSubmitDeleteBrandModel}
        title={getLang(lang, "label.DELETE_BRAND_MODEL")}
        description={getLang(lang, "paragraph.DELETE_BRAND_MODEL_QUESTION")}
        type="danger"
        icon={<img src={AlertIcon} alt="alert" />}
      />
    </>
  )
}

export default BrandModelListPanelContainer
