import { addAlert, setLoading } from "modules/notification";
import { getDealerBranchTitleData, getDealerStockoutByBranchData, getDealerStockoutByBrandData, getDealerStockoutByCategoryData, getDealerStockoutByEmployeeData, getDealerStockoutByModelData, getDealerStockoutByProductData, getDealerStockoutData } from "../action";

// (1) dealer: stockout by branch -- start
export const getStockoutByBranchDataMiddleware = ({ dispatch, getState}) => next => action => {
    next(action);
    if (action.type === getDealerStockoutByBranchData.pending.type) {
        dispatch(setLoading({ id: getDealerStockoutByBranchData.typePrefix, state: true }));
    }
    if (action.type === getDealerStockoutByBranchData.fulfilled.type) {
        dispatch(setLoading({ id: getDealerStockoutByBranchData.typePrefix, state: false }));
    }
    if (action.type === getDealerStockoutByBranchData.rejected.type) {
        dispatch(setLoading({ id: getDealerStockoutByBranchData.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: action.payload.message}));
    }
}
// (1) dealer: stockout by branch -- end

// (2) dealer: stockout by category -- start
export const getStockoutByCategoryDataMiddleware = ({ dispatch, getState}) => next => action => {
    next(action);
    if (action.type === getDealerStockoutByCategoryData.pending.type) {
        dispatch(setLoading({ id: getDealerStockoutByCategoryData.typePrefix, state: true }));
    }
    if (action.type === getDealerStockoutByCategoryData.fulfilled.type) {
        dispatch(setLoading({ id: getDealerStockoutByCategoryData.typePrefix, state: false }));
    }
    if (action.type === getDealerStockoutByCategoryData.rejected.type) {
        dispatch(setLoading({ id: getDealerStockoutByCategoryData.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: action.payload.message}));
    }
}
// (2) dealer: stockout by category -- end

// (3) dealer: stockout by brand -- start
export const getStockoutByBrandDataMiddleware = ({ dispatch, getState}) => next => action => {
    next(action);
    if (action.type === getDealerStockoutByBrandData.pending.type) {
        dispatch(setLoading({ id: getDealerStockoutByBrandData.typePrefix, state: true }));
    }
    if (action.type === getDealerStockoutByBrandData.fulfilled.type) {
        dispatch(setLoading({ id: getDealerStockoutByBrandData.typePrefix, state: false }));
    }
    if (action.type === getDealerStockoutByBrandData.rejected.type) {
        dispatch(setLoading({ id: getDealerStockoutByBrandData.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: action.payload.message}));
    }
};

// (3) dealer: stockout by brand -- end

// (4) dealer: stockout by model -- start
export const getStockoutByModelDataMiddleware = ({ dispatch, getState}) => next => action => {
    next(action);
    if (action.type === getDealerStockoutByModelData.pending.type) {
        dispatch(setLoading({ id: getDealerStockoutByModelData.typePrefix, state: true }));
    }
    if (action.type === getDealerStockoutByModelData.fulfilled.type) {
        dispatch(setLoading({ id: getDealerStockoutByModelData.typePrefix, state: false }));
    }
    if (action.type === getDealerStockoutByModelData.rejected.type) {
        dispatch(setLoading({ id: getDealerStockoutByModelData.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: action.payload.message}));
    }
}
// (4) dealer: stockout by model -- end

// (5) dealer: stockout by product -- start
export const getStockoutByProductDataMiddleware = ({ dispatch, getState}) => next => action => {
    next(action);
    if (action.type === getDealerStockoutByProductData.pending.type) {
        dispatch(setLoading({ id: getDealerStockoutByProductData.typePrefix, state: true }));
    }
    if (action.type === getDealerStockoutByProductData.fulfilled.type) {
        dispatch(setLoading({ id: getDealerStockoutByProductData.typePrefix, state: false }));
    }
    if (action.type === getDealerStockoutByProductData.rejected.type) {
        dispatch(setLoading({ id: getDealerStockoutByProductData.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: action.payload.message}));
    }
}
// (5) dealer: stockout by product -- end

// (6) dealer: branch title -- start
export const getDealerBranchTitleDataMiddleware = ({ dispatch, getState}) => next => action => {
    next(action);
    if (action.type === getDealerBranchTitleData.pending.type) {
        dispatch(setLoading({ id: getDealerBranchTitleData.typePrefix, state: true }));
    }
    if (action.type === getDealerBranchTitleData.fulfilled.type) {
        dispatch(setLoading({ id: getDealerBranchTitleData.typePrefix, state: false }));
    }
    if (action.type === getDealerBranchTitleData.rejected.type) {
        dispatch(setLoading({ id: getDealerBranchTitleData.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: action.payload.message}));
    }
}
// (6) dealer: branch title -- end

// (7) stockout by dealer -- start
export const getDealerStockoutDataMiddleware = ({ dispatch, getState}) => next => action => {
    next(action);
    if (action.type === getDealerStockoutData.pending.type) {
        dispatch(setLoading({ id: getDealerStockoutData.typePrefix, state: true }));
    }
    if (action.type === getDealerStockoutData.fulfilled.type) {
        dispatch(setLoading({ id: getDealerStockoutData.typePrefix, state: false }));
    }
    if (action.type === getDealerStockoutData.rejected.type) {
        dispatch(setLoading({ id: getDealerStockoutData.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: action.payload.message}));
    }
}
// (7) stockout by dealer -- end

// (8) stockout by employee -- start
export const getStockoutByEmployeeDataMiddleware = ({ dispatch, getState}) => next => action => {
    next(action);
    if (action.type === getDealerStockoutByEmployeeData.pending.type) {
        dispatch(setLoading({ id: getDealerStockoutByEmployeeData.typePrefix, state: true }));
    }
    if (action.type === getDealerStockoutByEmployeeData.fulfilled.type) {
        dispatch(setLoading({ id: getDealerStockoutByEmployeeData.typePrefix, state: false }));
    }
    if (action.type === getDealerStockoutByEmployeeData.rejected.type) {
        dispatch(setLoading({ id: getDealerStockoutByEmployeeData.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: action.payload.message}));
    }
}
// (8) stockout by employee -- end

export default [
    getStockoutByBranchDataMiddleware,
    getStockoutByCategoryDataMiddleware,
    getStockoutByBrandDataMiddleware,
    getStockoutByModelDataMiddleware,
    getStockoutByProductDataMiddleware,
    getDealerBranchTitleDataMiddleware,
    getDealerStockoutDataMiddleware,
]