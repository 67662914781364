import React from "react";
import { makeStyles, Box, Select, MenuItem } from "@material-ui/core";
import languages from "lib/constants/languages";

const useStyles = makeStyles((theme) => ({
  selectRoot: {
    fontSize: 14,
    backgroundColor: "#EBEDEE",
    borderRadius: 4,
    width: "100%",
    height: 38,
    padding: "0px 24px 0px 0px",
    display: "flex",
    alignItems: "center",
    "&:focus": {
      backgroundColor: "#EBEDEE",
      borderRadius: 4,
    },
  },
}));

export default function AdtInfoDisplayLangSelectComponent({
  value,
  handleChangeLang,
}) {
  const classes = useStyles();

  return (
    <Box>
      <Select
        value={value}
        onChange={handleChangeLang}
        disableUnderline
        classes={{ root: classes.selectRoot }}
      >
        {languages.map((l, i) => (
          <MenuItem value={l.code} key={i}>
            {l.label}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
}
