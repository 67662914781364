import React from "react";
import {
  Backdrop,
  Box,
  Paper,
  Divider,
  makeStyles,
  Button,
} from "@material-ui/core";
import Dialog from "components/dialog/customDialog";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import DialogHeader from "components/header/dialogHeader";
import RoleNameInput from "../../input/roleName";
import RoleSelect from "../../input/roleSelect";
import Accordion from "../../accordion";
import Masonry from "components/masonry/Masonry";
import { getLang } from "app/feature/constants";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  infoContainer: {
    flex: "1 1 auto",
    width: "650px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  toggleControl: {
    margin: theme.spacing(2, 0),
  },
  toggleControlTitle: {
    margin: theme.spacing(0),
    marginTop: theme.spacing(1),
  },
  accessContainer: {
    background: "#ECEFF0",
  },
  accessBox: {
    display: "flex",
    flexFlow: "column",
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(0, 1, 1, 1),
  },
  publishButton: {
    color: theme.palette.common.white,
  },
  scroll: {
    maxHeight: "calc(100vh - 260px)", // Adjust the value as needed
    overflowY: "auto",
  },
  container: {
    padding: theme.spacing(2),
  },
}));

export default function RoleDialogComponent({
  currentUserRoleId,
  hasRoleAddAccessRight,
  hasRoleEditAccessRight,
  inheritAccessRights,
  isOpen,
  handleClose,
  handleSubmit,
  roleAccessRightList,
  roleInfo,
  type,
  lang,
  handleSelectAll,
  handleDeselectAll,
  setAccess,
  accessRole,
  isFetching,
}) {
  const classes = useStyles();
  const isUpdate = type === "edit" ? true : false;

  return (
    <Dialog
      isOpen={isOpen}
      handleClose={handleClose}
      disableEnforceFocus={true}
      disableAutoFocus={true}
      disableEscapeKeyDown={true}
      disableBackdropClick={true}
      closeAfterTransition
      BackdropComponent={Backdrop}
    >
      <Paper elevation={0} className={classes.root}>
        <DialogHeader
          title={
            !isUpdate
              ? getLang(lang, "label.ADD_NEW_ROLE_LOWERCASE")
              : getLang(lang, "label.EDIT_ROLE")
          }
          handleClose={handleClose}
        />
        <Divider />
        <Formik
          initialValues={{
            roleName: isUpdate ? roleInfo.name : "",
          }}
          validationSchema={Yup.object({
            roleName: Yup.string()
              .trim()
              .required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
          })}
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <Box className={classes.infoContainer}>
                <div className={classes.scroll}>
                  <div className={classes.container}>
                    <RoleNameInput
                      label={getLang(lang, "label.ROLE_NAME")}
                      placeholder={getLang(lang, "placeholder.ENTER_ROLE_NAME")}
                      required
                      size="small"
                      fullWidth
                      margin="dense"
                      variant="outlined"
                      disabled={
                        formik.isSubmitting
                          ? true
                          : (type === "create" && hasRoleAddAccessRight) ||
                            (type === "edit" &&
                              hasRoleEditAccessRight &&
                              currentUserRoleId !== roleInfo.id &&
                              roleInfo.default === 0)
                          ? false
                          : true
                      }
                      error={
                        formik.touched.roleName && formik.errors.roleName
                          ? true
                          : false
                      }
                      {...formik.getFieldProps("roleName")}
                      helperText={ErrorMessage({
                        name: "roleName",
                      })}
                    />
                  </div>
                  <Divider />
                  <div className={classes.container}>
                    <RoleSelect
                      label={getLang(lang, "label.ASSIGN_ROLE_ACCESS_RIGHT")}
                      handleSelectAll={handleSelectAll}
                      handleDeselectAll={handleDeselectAll}
                    />
                  </div>

                  <Box className={classes.accessContainer}>
                    {isFetching ? (
                      <Box style={{ backgroundColor: "white" }} px={3} pb={3}>
                        <Skeleton variant="text" animation="wave" height={40} />
                        <Skeleton variant="text" animation="wave" height={40} />
                        <Skeleton variant="text" animation="wave" height={40} />
                        <Skeleton variant="text" animation="wave" height={40} />
                      </Box>
                    ) : (
                      <div className={classes.accessBox}>
                        <div className={classes.container}>
                          <Masonry container>
                            {roleAccessRightList.map((field, index) => {
                              const disableSummaryAccordion =
                                field.subgroup.some((element) => {
                                  return !inheritAccessRights.includes(
                                    element.details[0].action
                                  );
                                });
                              if (disableSummaryAccordion && field.group === 'Marketplace') {
                                return null;
                              }
                              return (
                                <Accordion
                                  key={index}
                                  label={field.group}
                                  subgroup={field.subgroup}
                                  setAccess={setAccess}
                                  accessRole={accessRole}
                                  inheritAccessRights={inheritAccessRights}
                                  disableSummaryAccordion={
                                    disableSummaryAccordion
                                  }
                                  disabledCheckbox={
                                    formik.isSubmitting
                                      ? true
                                      : (type === "create" &&
                                          hasRoleAddAccessRight) ||
                                        (type === "edit" &&
                                          hasRoleEditAccessRight &&
                                          currentUserRoleId !== roleInfo.id &&
                                          roleInfo.default === 0)
                                      ? false
                                      : true
                                  }
                                />
                              );
                            })}
                          </Masonry>
                        </div>
                      </div>
                    )}
                  </Box>
                </div>
              </Box>
              {(type === "create" && hasRoleAddAccessRight) ||
              (type === "edit" &&
                hasRoleEditAccessRight &&
                currentUserRoleId !== roleInfo.id &&
                roleInfo.default === 0) ? (
                <>
                  <Divider />
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    px={2}
                    pt={1}
                    pb={1}
                  >
                    <Button
                      disableElevation
                      style={{ marginRight: "4px" }}
                      type="button"
                      onClick={(e) => {
                        formik.resetForm();
                        handleClose(e);
                      }}
                    >
                      {getLang(lang, "label.CANCEL")}
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      classes={{ root: classes.publishButton }}
                      disableElevation
                      disabled={formik.isSubmitting}
                      type="submit"
                    >
                      {getLang(lang, "label.SAVE")}
                    </Button>
                  </Box>
                </>
              ) : null}
            </form>
          )}
        </Formik>
      </Paper>
    </Dialog>
  );
}
