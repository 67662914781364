import React, { useRef, useState } from "react";
import LanguageSelectPanelComponent from "./languageSelectPanel.component";
import LanguageSelectComponent from "./languageSelect.component";
import {useSelector} from "react-redux";

export default function LanguageSelectContainer({
  handleAddLang,
  handleDeleteLang,
  handleChangeLang,
  value,
  disabled,
  style,
  panelStyle,
  languageValue,
  anchorOrigin,
  transformOrigin,
  PaperProps,
  textStyle,
}) {
  const lang = useSelector(state => state.constant.languages)
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined
  const anchorRef = useRef();

  const languages = [
    'VI', 'TH', 'ZH', 'ZH-TW', 'MS', 'ID', 'TL'
  ];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleNotLocalisedClick = (code) => {
    handleAddLang(code);
    handleClose();
  }

  const handleLocalisedDelete = (code) => {
    handleDeleteLang(code);
  }

  const handleLocalisedClick = (code) => {
    handleChangeLang(code);
    handleClose();
  }

  return (
    <>
      <LanguageSelectComponent
        handleClick={handleClick}
        value={value}
        style={style}
        disabled={disabled}
        anchorRef={anchorRef}
        open={open}
        textStyle={textStyle}
        lang={lang}
      />
      <LanguageSelectPanelComponent
        anchorEl={anchorEl}
        open={open}
        handleClose={handleClose}
        style={panelStyle}
        id={id}
        empty={[...languages, "EN"].filter(value => languageValue[value] === '')}
        localisedLanguages={languages.filter(value => !!languageValue[value] || languageValue[value] === '')}
        notLocalisedLanguages={languages.filter(value => !languageValue[value] && languageValue[value] !== '')}
        handleNotLocalisedClick={handleNotLocalisedClick}
        handleLocalisedClick={handleLocalisedClick}
        handleLocalisedDelete={handleLocalisedDelete}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        PaperProps={PaperProps}
        lang={lang}
      />
    </>
  )
}
