import React from "react";
import { 
  //makeStyles, 
  Box 
} from '@material-ui/core';
import RedemptionTable from '../../table/redemptionTable';

// const useStyle = makeStyles((theme) => ({

// }));

export default function RedemptionPanelComponent({
  rows,
  rowsPerPage,
  handleChangePage,
  handleChangeRowPerPage,
  handleViewDetail,
  isError,
  isFetching,
  language,
  isSearchResult,
  page,
  paginationOptions,
  totalFiltered
}) {
  // const classes = useStyle();

  return (
    <Box>
      {/* <Box style={{padding: "15px", backgroundColor: "#fff", display: "flex" }}>
        <Box style={{ marginRight: 18 }}>
          <img src={DefaultImg} style={{ width: 100, height: 100 }} />
        </Box>
        <Box>
          <Typography variant='h6'>Reward Name A</Typography>
          <Box style={{ display: "flex" }}>
            <Typography variant='body2' color="primary" style={{ lineHeight: 1.7, width: 180 }}>Total Redemption</Typography>
            <Typography variant='body1' color="primary" style={{ fontWeight: "bold" }}>20</Typography>
          </Box>
          <Box style={{ display: "flex" }}>
            <Typography variant='body2' color="primary" style={{ lineHeight: 1.7, width: 180 }}>Available</Typography>
            <Typography variant='body1' color="primary" style={{ fontWeight: "bold" }}>8</Typography>
          </Box>
          <Box style={{ display: "flex" }}>
            <Typography variant='body2' color="primary" style={{ lineHeight: 1.7, width: 180 }}>Validity Date</Typography>
            <Typography variant='body1' color="primary" style={{ fontWeight: "bold" }}>11 Oct 2022, 15:21 - 18 Oct 2022, 15:21</Typography>
          </Box>
        </Box>
      </Box> */}
      <Box style={{ marginTop: "32px" }}>
        <RedemptionTable
          rows={rows}
          rowsPerPage={rowsPerPage}
          handleChangePage={handleChangePage}
          language={language}
          handleChangeRowPerPage={handleChangeRowPerPage}
          handleViewDetail={handleViewDetail}
          isError={isError}
          isFetching={isFetching}
          isSearchResult={isSearchResult}
          page={page}
          paginationOptions={paginationOptions}
          totalFiltered={totalFiltered}
        />
      </Box>
    </Box>
  )
}
