import React, { useState, useRef, useEffect } from "react";
import BranchSelectComponent from "./branchSelect.component";
import BranchPanelComponent from "./branchPanel.component";
import PropTypes from "prop-types";
import { selectLoading } from "modules/notification";
import { useDispatch, useSelector } from "react-redux";
import { fetchMeAndBranchDropdown } from "modules/product-mgmt/redux/action/products.action";
import cloneDeep from "lodash/cloneDeep";
import { getLang } from "app/feature/constants";

function BranchSelectContainer({ 
  placeholder = null, 
  searchable = false,
  handleChange, 
  value, 
  initialValue = [],
  disabled, 
  styles, 
  textStyles
}) {
  const dispatch = useDispatch();
  const branchDropdown = useSelector((state) => state.products.branchDropdown);
  const isBranchDropdownLoading = useSelector((state) => selectLoading(state, fetchMeAndBranchDropdown.typePrefix));
  const isHqAdmin = useSelector(state => state.profile.isHqAdmin)
  const [open, setOpen] = useState(false);
  const [isOpenList] = useState(false);
  const anchorRef = useRef(null);
  const prevOpen = useRef(open);
  const prevOpenList = useRef(isOpenList);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const lang = useSelector(state => state.constant.languages);
  
  let flattenBranch = []

  useEffect(() => {
    dispatch(fetchMeAndBranchDropdown({ search: searchKeyword }));
  }, [dispatch, isHqAdmin, searchKeyword]);

  useEffect(() => {
    for (const masterBranch of branchDropdown) {
      flattenBranch.push({
        name: masterBranch.name,
        node_id: masterBranch.node_id,
        parent_node_id: masterBranch.parent_node_id,
        remarks: masterBranch.remarks,
      })
      if (masterBranch.child !== undefined && masterBranch.child.length > 0) {
        for (const subBranch of masterBranch.child) {
          flattenBranch.push({
            name: subBranch.name,
            node_id: subBranch.node_id,
            parent_node_id: subBranch.parent_node_id,
            remarks: subBranch.remarks,
          })
        }
      }
    }
  }, [branchDropdown, flattenBranch]);

  useEffect(() => {
    let temp = cloneDeep(value)
    let isSelectAll = true
    for (const masterBranch of flattenBranch) {
      if (temp && !(temp.filter(t => t.node_id === masterBranch.node_id).length === 1)
      ) {
        isSelectAll = false
      }
    }
    setSelectAll(isSelectAll)
  }, [value, flattenBranch]);

  const handleClose = () => {
    setOpen(false);
    setSearchKeyword("")
  };

  const handleClick = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    if (prevOpenList.current === true && isOpenList === false) {
      anchorRef.current.focus();
    }

    prevOpenList.current = isOpenList;
  }, [isOpenList]);

  const handleSelectAll = async () => {
    let temp = cloneDeep(value)
    if (selectAll) {
      if (!initialValue.length || initialValue.length !== value.length) {
        for (const masterBranch of branchDropdown) {
          if (!initialValue.some(t => t.node_id === masterBranch.node_id)) {
            temp = temp.filter(t => t.node_id !== masterBranch.node_id)
          }

          if (temp.filter(t => t.node_id === masterBranch.parent_node_id).length === 0) {
            if (masterBranch.child.length > 0) {
              for (const branch of masterBranch.child) {
                if (!initialValue.some(t => t.node_id === branch.node_id)) {
                  temp = temp.filter(t => t.node_id !== branch.node_id)
                }
                if (branch.remarks === "region" && branch.child.length > 0) {
                  for (const childBranch of branch.child) {
                    if (!initialValue.some(t => t.node_id === childBranch.node_id)) {
                      temp = temp.filter(t => t.node_id !== childBranch.node_id)
                    }
                  }
                }
              }
            }
          }
        }
      }
    } else {
      for (const masterBranch of branchDropdown) {
        temp = temp.filter(t => t.node_id !== masterBranch.node_id)
        if (masterBranch.child.length > 0) {
          for (const branch of masterBranch.child) {
            temp = temp.filter(t => t.node_id !== branch.node_id)
          }
        }
        if (temp.filter(t => t.node_id === masterBranch.parent_node_id).length === 0) {
          temp.push({
            name: masterBranch.name,
            node_id: masterBranch.node_id,
            parent_node_id: masterBranch.parent_node_id,
            remarks: masterBranch.remarks
          });

          if (masterBranch.child.length > 0) {
            for (const branch of masterBranch.child) {
              temp.push({
                name: branch.name,
                node_id: branch.node_id,
                parent_node_id: branch.parent_node_id,
                remarks: branch.remarks
              });
              if (branch.remarks === "region" && branch.child.length > 0) {
                for (const childBranch of branch.child) {
                  temp.push({
                    name: childBranch.name,
                    node_id: childBranch.node_id,
                    parent_node_id: childBranch.parent_node_id,
                    remarks: childBranch.remarks
                  });
                }
              }
            }
          }
        }
      }
    }
    setSelectAll(!selectAll)
    handleChange(temp)
  };

  const handleSelectBranch = (branch) => {
    let temp = cloneDeep(value)
    if (!initialValue.length || !initialValue.some(t => t.node_id === branch.node_id)) {
      if (temp.filter(t => t.node_id === branch.node_id).length > 0) {
        temp = temp.filter(t => t.node_id !== branch.node_id)
      } else {
        // const parentBranch = branchDropdown.find(b => b.node_id === branch.parent_node_id);
        // if (!branch.child && !temp.some(t => t.node_id === parentBranch.node_id)) {
        //   temp.push({
        //     name: parentBranch.name,
        //     node_id: parentBranch.node_id,
        //     parent_node_id: parentBranch.parent_node_id,
        //     remarks: parentBranch.remarks
        //   })
        // }
        temp.push({
          name: branch.name,
          node_id: branch.node_id,
          parent_node_id: branch.parent_node_id,
          remarks: branch.remarks
        })
      }
      handleChange(temp)
    }
  };

  const handleSearch = (keyword) => {
    setSearchKeyword(keyword)
  }

  return (
    <>
      <BranchSelectComponent
        anchorRef={anchorRef}
        handleClick={handleClick}
        value={value}
        styles={styles}
        placeholder={placeholder ?? getLang(lang, "placeholder.SELECT_BRANCH")}
        disabled={disabled}
        branchDropDown={branchDropdown}
        textStyles={textStyles}
        lang={lang}
        open={open}
      />
      <BranchPanelComponent
        selectAll={selectAll}
        value={value}
        anchorRef={anchorRef}
        open={open}
        isOpenList={isOpenList}
        handleClose={handleClose}
        isFetching={isBranchDropdownLoading}
        branchDropdown={branchDropdown}
        handleSelectAll={handleSelectAll}
        handleSelectBranch={handleSelectBranch}
        initialValue={initialValue}
        lang={lang}
        searchable={searchable}
        handleSearch={handleSearch}
      />
    </>
  );
}

BranchSelectContainer.propTypes = {
  placeholder: PropTypes.string
};

export default BranchSelectContainer;
