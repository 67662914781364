import axios from "axios";

const get = () => {
  return new Promise((resolve, reject) => {
    axios
      .get("/payment/api/v1/local-plans")
      .then(response => resolve(response.data))
      .catch(error => {
        return reject(error.response.data);
      });
  });
};

const localPlans = {
  get
};

export default localPlans;
