import React from "react";
import {
  makeStyles,
  Box,
  Button,
  Typography,
} from '@material-ui/core';
import { Formik } from "formik"
import Input from "components/input/inputTextField";
import SearchableSelect from 'components/select/searchableSelect';
import ActionSelect from '../../select/actionSelect';
import AttributesListTable from '../../table/attributesListTable';
import { getLang } from "app/feature/constants";

const useStyle = makeStyles((theme) => ({
  searchContainer: {
    backgroundColor: theme.palette.background.paper,
    display: "grid",
    gridTemplateColumns: "5fr 2fr 1fr 1fr",
    columnGap: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1)
    }
  },
  button: {
    display: "flex",
    alignItems: "center",
    padding: 0,
    minWidth: 0,
    marginLeft: theme.spacing(1)
  },
  inputRoot: {
    paddingTop: "10px !important",
    paddingBottom: "8px !important",
    paddingLeft: "12px !important",
    paddingRight: "12px !important",
    fontWeight: 550,
    fontSize: "0.875rem"
  },
  paperRoot: {
    width: "217px",
  }
}));

export default function AttributesListPanelComponent({
  hasProductAttributeEditAccessRight,
  hasProductAttributeDeleteAccessRight,
  handleSearch,
  isFetching,
  isSelected,
  isSelectAll,
  selectedIds,
  paginationOptions,
  selectedCount,
  handleChangePage,
  handleChangeRowsPerPage,
  handleReload,
  page,
  search,
  statusFilter,
  totalFiltered,
  data,
  rowsPerPage,
  handleCreateAttribute,
  isLoadingAttributeListError,
  handleChangeStatus,
  handleSelectAttribute,
  handleSelectAvailable,
  handleSelectAll,
  handleDeselectAll,
  handleChangeAttributesStatus,
  handleDeleteAttribute,
  handleEditAttribute,
  lang
}) {
  const classes = useStyle();

  return (
    <>
      <Formik
        initialValues={{
          search: "",
          status: "",
        }}
        onSubmit={handleSearch}
        onReset={handleSearch}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <Box p={2} mb={2} className={classes.searchContainer}>
              <Input
                hiddenLabel
                variant="filled"
                size="small"
                placeholder={getLang(lang, "placeholder.SEARCH_ATTRIBUTE")}
                margin="none"
                InputProps={{
                  disableUnderline: true,
                  margin: "none",
                  style: {
                    borderRadius: "4px",
                    backgroundColor: "#ECEFF0"
                  },
                  classes: { input: classes.inputRoot }
                }}
                {...formik.getFieldProps("search")}
              />
              <SearchableSelect
                value={formik.values.status}
                isEmpty={formik.values.status === ""}
                handleChange={(value) => {
                  formik.setFieldValue("status", value)
                }}
                placeholder={getLang(lang, "placeholder.SELECT_STATUS")}
                disabled={isFetching}
                searchable={false}
                style={{ height: "34px" }}
                panelStyle={{ width: "249px" }}
                PaperProps={{
                  classes: { root: classes.paperRoot }
                }}
                dropdownItem={[getLang(lang, "label.ENABLE"), getLang(lang, "label.DISABLE")]}
                emptyMessage={getLang(lang, "paragraph.STATUS_NOT_FOUND")}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left"
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left"
                }}
              />
              <Button
                type="reset"
                variant='text'
                color='secondary'
                size='small'
                disabled={isFetching}
                disableElevation
                onClick={() => {
                  formik.setFieldValue("status", "");
                  formik.setFieldValue("search", "");
                  (search !== "" || statusFilter !== "") && formik.handleReset();
                }}
              >
                {getLang(lang, "label.CLEAR")}
              </Button>
              <Button
                type="submit"
                size='small'
                color="primary"
                variant="contained"
                disabled={isFetching}
                disableElevation
              >
                {getLang(lang, "label.SEARCH")}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
      <Box style={{ display: "flex", justifyContent: "space-between ", paddingBottom: "12px" }}>
        <Box style={{ display: "flex", alignItems: "center" }}>
          {selectedCount !== 0 ? (
            (isSelectAll && selectedCount === totalFiltered) ? (
              <Typography variant='body2' style={{ color: "#FDB022" }}>{getLang(lang, "paragraph.SELECTED_ALL_ATTRIBUTES", { count: selectedCount })}</Typography>
            ) : (
              <Typography variant='body2' color='primary'>{(selectedCount > 1) ? getLang(lang, "paragraph.SELECTED_ATTRIBUTES_COUNT", { count: selectedCount })
                : getLang(lang, "paragraph.SELECTED_ATTRIBUTE_COUNT", { count: selectedCount })}</Typography>
            )
          ) : (
            ""
          )}
        </Box>
        <ActionSelect
          label={getLang(lang, 'label.ACTION')}
          dropdownItem={[getLang(lang, "label.ENABLE_SELECTED"), getLang(lang, "label.DISABLE_SELECTED")]}
          handleChange={(value) => handleChangeAttributesStatus(value)}
          disabled={isFetching || !isSelected}
          isFetching={false}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          style={{
            width: "100px",
            borderRadius: "8px",
            backgroundColor: "#ffffff",
            border: "1px solid #D0D5DD"
          }}
        />
      </Box>
      <Box>
        <AttributesListTable
          hasProductAttributeEditAccessRight={hasProductAttributeEditAccessRight}
          hasProductAttributeDeleteAccessRight={hasProductAttributeDeleteAccessRight}
          data={data}
          isFetching={isFetching}
          isSelectAll={isSelectAll}
          selectedIds={selectedIds}
          paginationOptions={paginationOptions}
          isLoadingAttributeListError={isLoadingAttributeListError}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleReload={handleReload}
          page={page}
          search={search}
          totalFiltered={totalFiltered}
          rowsPerPage={rowsPerPage}
          handleCreateAttribute={handleCreateAttribute}
          handleChangeStatus={handleChangeStatus}
          handleSelectAttribute={handleSelectAttribute}
          handleSelectAvailable={handleSelectAvailable}
          handleSelectAll={handleSelectAll}
          handleDeselectAll={handleDeselectAll}
          handleDeleteAttribute={handleDeleteAttribute}
          handleEditAttribute={handleEditAttribute}
          lang={lang}
        />
      </Box>
    </>
  )
}