import React from 'react'
import {
  Box,
  Button,
  makeStyles,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@material-ui/core';
import Alert from "@material-ui/lab/Alert";
import { StyledTableCell, StyledTableRow } from 'components/table';
import { SkeletonTableRow } from 'components/skeleton/skeleton.component';
import { ActionButton } from 'components/button';
import { TablePaginationActions } from "components/tablePagination";
import AntSwitch from 'components/switch/switch';
import { convertToBigNum } from "lib/generalUtility";
import RefreshTable from "components/table/refreshTable";
import { getLang } from 'app/feature/constants';
import StickyRightTableCell from 'components/table/stickyRightTableCell';
import StickyLeftTableCell from 'components/table/stickyLeftTableCell';
import ProductCountDetailPopover from "../../../../../components/popover/productCountDetailPopover";
import moment from 'moment';
import "moment/min/locales.min";


const useStyle = makeStyles(theme => ({
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    borderCollapse: "separate",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
    },
    "& th,td": {
      fontSize: ".75rem",
      borderRight: "1px solid rgba(58, 77, 84, 0.1)",
      padding: theme.spacing(1, 2, 1, 2),
    },
    "& th": {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightBold,
    },
    "& th:last-child, td:last-child": {
      width: 37,
      borderRight: "0px",
    },
    "& th th": {
      borderBottom: "none !important",
    },
    "& td td": {
      borderBottom: "none !important",
    },
  },
  emptyContainer: {
    backgroundColor: theme.palette.background.paper,
    textAlign: "center"
  },
  attributeText: {
    color: "#b2b2b2",
    fontSize: 12,
    whiteSpace: "break-spaces",
  },
}));

export default function PointListingTableComponent({
  hasLoyaltyProgramAddAccessRight,
  hasLoyaltyProgramEditAccessRight,
  hasLoyaltyProgramActivateOrDeactivateAccessRight,
  data,
  isFetching,
  page,
  search,
  rowsPerPage,
  totalRecords,
  handleChangePage,
  handleChangeRowsPerPage,
  handleCreate,
  handleView,
  language,
  handleStatusChange,
  handleReload,
  isLoadingPointError,
  paginationOptions,
  lang
}) {
  const classes = useStyle();

  
  if(language==="ZH")
  {
    moment.locale('zh-cn')
  }else if(language==="TL")
  {
    moment.locale('tl-ph')
  }else
  {
    moment.locale(language)
  }

  return (
    <>
      {data.length > 0 || isFetching ? (
        <TableContainer>
          <Table className={classes.table} size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <StickyLeftTableCell>
                  <StyledTableCell>{getLang(lang, "label.NUM")}</StyledTableCell>
                </StickyLeftTableCell>
                <StyledTableCell>{getLang(lang, "label.POINT")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.DATE_CREATED")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.ATTACHED_PRODUCT")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.STATUS")}</StyledTableCell>
                <StickyRightTableCell>
                  <StyledTableCell>{getLang(lang, "label.ACTION")}</StyledTableCell>
                </StickyRightTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isFetching
                ? [...new Array(10)].map((i, index) => (
                  <SkeletonTableRow key={index} columnCount={6} />
                ))
                : data.map((item, index) => {
                  let status = item.status === "active" ? true : false
                  const actionItems = [
                    {
                      label: hasLoyaltyProgramEditAccessRight ? getLang(lang, "label.VIEW_EDIT") : getLang(lang, "label.VIEW"),
                      action: () => handleView({ id: item.loyaltyId }),
                      disabled: !status && true
                    },
                  ]

                  return (
                    <StyledTableRow key={index} hover tabIndex={-1}>
                      <StickyLeftTableCell>
                        <StyledTableCell>{index + 1 + rowsPerPage * page}</StyledTableCell>
                      </StickyLeftTableCell>
                      <StyledTableCell>{convertToBigNum(item.point)}</StyledTableCell>
                      <StyledTableCell>
                        {moment(item.dateAdded).format('ll')}
                      </StyledTableCell>
                      <ProductCountDetailPopover
                        products={item.module}
                        itemTranslator={(product) => ({
                          id: product.productId,
                          name: product.productName,
                          imageUrl: product.imageUrl,
                          attributes: product.attributes,
                        })}
                      />
                      {/* <StyledTableCell style={{ color: "#4BCB0F" }}>{item.addedBy}</StyledTableCell> */}
                      <StyledTableCell>
                        <AntSwitch
                          checked={status}
                          disabled={!hasLoyaltyProgramActivateOrDeactivateAccessRight}
                          onChange={() =>
                            handleStatusChange({
                              currentStatus: status,
                              id: item.loyaltyId
                            })
                          }
                        />
                      </StyledTableCell>
                      <StickyRightTableCell>
                        <StyledTableCell>
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <ActionButton actionItems={actionItems} popperStyle={{ zIndex: 999 }}                            />
                          </div>
                        </StyledTableCell>
                      </StickyRightTableCell>
                    </StyledTableRow>
                  );
                })
              }
            </TableBody>
          </Table>
        </TableContainer>
      ) : !search && !isLoadingPointError && (
        <Box>
          <Table className={classes.table} size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <StyledTableCell>{getLang(lang, "label.NUM")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.POINT")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.DATE_CREATED")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.ATTACHED_PRODUCT")}</StyledTableCell>
                {/* <StyledTableCell>Added by</StyledTableCell> */}
                <StyledTableCell>{getLang(lang, "label.STATUS")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.ACTION")}</StyledTableCell>
              </TableRow>
            </TableHead>
          </Table>
          <Box py={9} className={classes.emptyContainer}>
            <Typography variant="body1">
              {getLang(lang, "paragraph.NO_LOYALTY_POINT")}
            </Typography>
            {
              hasLoyaltyProgramAddAccessRight
                ?
                <Box mt={2}>
                  <Button
                    onClick={handleCreate}
                    variant="contained"
                    disableElevation
                    color="primary"
                  >
                    {getLang(lang, "label.ADD_LOYALTY_POINT")}
                  </Button>
                </Box>
                : null
            }
          </Box>
        </Box>

      )}

      {!isFetching && data.length === 0 && search && (
        <Alert severity="info">{getLang(lang, "message.info.NO_POINT")}</Alert>
      )}

      {!isFetching && isLoadingPointError && (
        <RefreshTable handleReload={handleReload} />
      )}

      {!isFetching && data.length > 0 && (
        <TablePagination
          style={{
            backgroundColor: "#fff",
            boxShadow: "0px -1px 0px rgba(58, 77, 84, 0.15)",
          }}
          ActionsComponent={TablePaginationActions}
          rowsPerPageOptions={paginationOptions}
          component="div"
          count={totalRecords}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage={getLang(lang, 'label.ROWS_PER_PAGE')}
          labelDisplayedRows={({ from, to, count }) => `${from.toLocaleString("en-US")}-${to.toLocaleString("en-US")} ${getLang(lang, 'label.OF')} ${count.toLocaleString("en-US")}`}
        />
      )}
    </>
  )
}
