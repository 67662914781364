import React from "react";
import ParticipantDetailDialogComponent from "./participantDetailDialog.component";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

function ParticipantDialogContainer({ isOpen, handleClose }) {
  const participantDetail = useSelector(
    (state) => state.luckyDraw.participantsDetail
  );
  const lang = useSelector(state => state.constant.languages)
  const language = useSelector(state => state.profile.language);


  const handleView = async (value) => {
    window.open(value, "_blank");
  };

  return (
    <ParticipantDetailDialogComponent
      isOpen={isOpen}
      handleClose={handleClose}
      participantDetail={participantDetail}
      handleView={handleView}
      lang={lang}
      language={language}
    />
  );
}

ParticipantDialogContainer.propTypes = {
  handleClose: PropTypes.func.isRequired
};

export default ParticipantDialogContainer;
