import React, { useState } from "react";
import RoleTableComponent from "./roleTable.component";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchRoleDetail,
  openCreationDialog,
  openViewEditDialog,
  deleteRole,
} from "modules/role-access-right/redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { hideBackdrop, showBackdrop } from "modules/backdrop";
import ConfirmationDialogComponent from "components/dialog/confirmation";
import { addAlert } from "modules/notification";
import { getLang } from "app/feature/constants";

function RoleTableContainer({
  currentUserRoleId,
  hasRoleAddAccessRight,
  hasRoleEditAccessRight,
  hasRoleDeleteAccessRight,
  data,
  search,
  isFetching,
  page,
  rowsPerPage,
  totalRecords,
  handleChangePage,
  handleChangeRowsPerPage,
  handleReload,
  paginationOptions
}) {
  const dispatch = useDispatch();
  const [confirmationDialogModal, setConfirmationDialogModal] = useState(false);
  const [roleData, setRoleData] = useState("");
  const isLoadingRoleError = useSelector(state => state.roleAccessRight.isLoadingRoleError)
  const lang = useSelector(state => state.constant.languages);

  const handleCreate = () => {
    if(hasRoleAddAccessRight){
      dispatch(openCreationDialog());
    }
  };

  const handleViewEdit = (role) => {
    dispatch(showBackdrop());
    dispatch(fetchRoleDetail(role.id))
      .then(unwrapResult)
      .then(() => {
        dispatch(hideBackdrop());
        dispatch(openViewEditDialog());
      });
  };

  const handleDelete = (role) => {
    if(hasRoleDeleteAccessRight){
      setConfirmationDialogModal(true);
      setRoleData(role);
    }
  };

  const handleProceedDelete = () => {
    if(hasRoleDeleteAccessRight){
      dispatch(showBackdrop());
      if (roleData.users !== 0) {
        dispatch(hideBackdrop());
        setConfirmationDialogModal(false);
        dispatch(
          addAlert({
            severity: "error",
            message: getLang(lang, "message.error.ROLE_BIND_EMPLOYEE"),
          })
        );
      } else {
        dispatch(deleteRole(roleData.id))
          .then(unwrapResult)
          .finally(() => {
            setConfirmationDialogModal(false);
            dispatch(hideBackdrop());
          });
      }
    }
  };

  return (
    <>
      <RoleTableComponent
        currentUserRoleId={currentUserRoleId}
        hasRoleAddAccessRight={hasRoleAddAccessRight}
        hasRoleEditAccessRight={hasRoleEditAccessRight}
        hasRoleDeleteAccessRight={hasRoleDeleteAccessRight}
        rows={data}
        page={page}
        search={search}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        isFetching={isFetching}
        totalRecords={totalRecords}
        handleCreate={handleCreate}
        handleViewEdit={handleViewEdit}
        handleDelete={handleDelete}
        isLoadingRoleError={isLoadingRoleError}
        handleReload={handleReload}
        paginationOptions={paginationOptions}
        lang={lang}
      />
      <ConfirmationDialogComponent
        isOpen={confirmationDialogModal}
        handleClose={() => setConfirmationDialogModal(false)}
        handleProceed={handleProceedDelete}
        type={"danger"}
        remark={getLang(lang, "paragraph.PROCESS_IRREVERSIBLE")}
        description={getLang(lang, "paragraph.PLEASE_CONFIRM")}
        title={getLang(lang, "paragraph.DELETE_ROLE_QUESTION")}
      />
    </>
  );
}

export default RoleTableContainer;
