import axios from "axios";

export default async function getConsumerScanListing({
    id,
    length,
    start,
    column,
    order,
}) {
    return new Promise((resolve, reject) => {
        axios
        .get(`/consumer/admin/api/v1/user-scan-listing/${id}`, {
            params: {
                length,
                start,
                column,
                order,
            },
        })
        .then((response) => resolve(response.data))
        .catch((error) => {
            return reject(error.response.data);
        });
    });
}
