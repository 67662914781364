import axios from "axios";

// validate email
export function validateEmail({
  email,
}) {
  return new Promise((resolve, reject) => {
    axios.post("distributor/public/api/v1/is-email-used", {
      email: email
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data))
  })
}