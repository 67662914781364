import React, { useState } from "react";
import TextFormComponent from "./textForm.component";
import PropTypes from "prop-types";
import {
  EditorState,
  convertToRaw,
  ContentState
} from "draft-js";
import DraftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { connect } from "react-redux";

const TYPES = ["new", "edit", "readonly"];

function transformHTMLToRaw(html) {
  const blocksFromHTML = htmlToDraft(html);
  const content = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap
  );
  return EditorState.createWithContent(content);
}

function TextFormContainer({
  type,
  handleResult,
  initialValues,
  lang,
  handleDelete,
  handleClose,
  languages
}) {
  const [texts, setTexts] = useState(
    type === TYPES[0]
      ? lang.map(code => ({ lang: code, value: initialValues.init }))
      : initialValues.data
  );
  const [editorState, setEditorState] = useState(
    transformHTMLToRaw(texts[0].value)
  );

  const [activeIndex, setActiveIndex] = useState(0);

  const handleSubmit = () => {
    const html = DraftToHtml(convertToRaw(editorState.getCurrentContent()));
    const newText = { ...texts[activeIndex], value: html };
    const newTexts = Array.from(texts);
    newTexts.splice(activeIndex, 1, newText);
    handleResult({ data: newTexts });
  };
  
  const handleChangeTab = (e, index) => {
    const html = DraftToHtml(convertToRaw(editorState.getCurrentContent()));
    const newText = { ...texts[activeIndex], value: html };
    const newTexts = Array.from(texts);
    newTexts.splice(activeIndex, 1, newText);
    setTexts(newTexts);
    setEditorState(transformHTMLToRaw(texts[index].value));
    setActiveIndex(index);
  };

  return (
    <TextFormComponent
      type={type}
      handleSubmit={handleSubmit}
      handleDelete={handleDelete}
      handleChange={setEditorState}
      editorState={editorState}
      initialValues={initialValues}
      activeIndex={activeIndex}
      handleChangeTab={handleChangeTab}
      lang={lang}
      handleClose={handleClose}
      languages={languages}
    />
  );
}

TextFormContainer.defaultProps = {
  type: TYPES[0],
  initialValues: { init: "<p></p>", data: [] }
};

TextFormContainer.propTypes = {
  type: PropTypes.oneOf(TYPES).isRequired,
  handleResult: PropTypes.func,
  handleDelete: PropTypes.func,
  initialValues: PropTypes.object
};

const mapStateToProps = state => ({
  languages: state.constant.languages,
  lang: state.productPage.lang
});

export { TYPES, transformHTMLToRaw };

export default connect(mapStateToProps)(TextFormContainer);
