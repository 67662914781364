import axios from "axios";
import PropTypes from "prop-types";

const get = ({ plan }) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/payment/api/v1/subscription/swap-preview?plan=${plan}`)
      .then(response => resolve(response.data))
      .catch(error => {
        return reject(error.response.data);
      });
  });
};

get.propTypes = {
  plan: PropTypes.string.isRequired
};

const swapSubscriptionPreview = {
  get
};

export default swapSubscriptionPreview;
