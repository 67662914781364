import axios from "axios";

export function getStockTakeDetail(id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/distributor/admin/api/v1/stocktake/${id}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data));
  });
}
