import React, { useRef } from "react";
import {
  Typography,
  InputAdornment,
  Box,
  Divider,
  MenuItem,
  MenuList,
  Checkbox,
  Popover,
  ClickAwayListener,
  Paper,
  IconButton,
} from "@material-ui/core";
import PerfectScrollbar from "react-perfect-scrollbar";
import SearchIcon from "@material-ui/icons/Search";
import Skeleton from "@material-ui/lab/Skeleton";
import InputTextField from "components/input/inputTextField";
import { getLang } from "app/feature/constants";

export default function BranchSelectPanelComponent({
  selectAll,
  value,
  anchorEl,
  open,
  isOpenList,
  handleClose,
  isFetching,
  branchDropdown,
  handleSelectAll,
  handleSelectBranch,
  handleSearch,
  lang,
}) {
  const textRef = useRef();

  return (
    <Popover
      id={open ? "simple-popover" : undefined}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      elevation={2}
      PaperProps={{
        style: { maxWidth: "63vw", width: 400 },
      }}
    >
      <Paper>
        <ClickAwayListener onClickAway={handleClose}>
          <Box>
            {/* <Typography
              variant="body2"
              color="primary"
              style={{ padding: "8px 16px", fontWeight: "bold" }}
            >
              Branch
            </Typography>
            <Divider /> */}
            <InputTextField
              variant="filled"
              size="small"
              fullWidth
              inputRef={textRef}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" style={{ paddingRight: 6 }}>
                    <IconButton
                      onClick={() => handleSearch(textRef.current.value)}
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
                style: {
                  backgroundColor: "#fff",
                  paddingRight: 0,
                },
              }}
              inputProps={{
                onKeyDown: (e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    handleSearch(textRef.current.value);
                  }
                },
                style: {
                  padding: "8px",
                },
              }}
              placeholder={getLang(lang, "placeholder.SEARCH")}
            />
            <Divider />
            <PerfectScrollbar>
              {isFetching ? (
                <>
                  <Skeleton variant="text" animation="wave" />
                  <Skeleton variant="text" animation="wave" />
                  <Skeleton variant="text" animation="wave" />
                  <Skeleton variant="text" animation="wave" />
                </>
              ) : (
                <div>
                  <MenuList
                    autoFocusItem={isOpenList}
                    id="menu-list-grow"
                    onClick={() => {
                      handleSelectAll();
                    }}
                    style={{ padding: 0 }}
                  >
                    <MenuItem style={{ padding: 0 }}>
                      <Checkbox checked={selectAll} />
                      <Typography
                        variant="inherit"
                        noWrap
                        style={{ paddingLeft: "8px" }}
                      >
                        {getLang(lang, "label.ALL")}
                      </Typography>
                    </MenuItem>
                  </MenuList>
                  <Divider />
                  <Box height="25vh" overflow="auto">
                    {branchDropdown.map((branch, index) => {
                      return (
                        <React.Fragment key={index}>
                          <MenuItem
                            style={{ padding: 0 }}
                            onClick={() => handleSelectBranch(branch)}
                          >
                            <Checkbox
                              checked={
                                !!value.find((v) => v.uuid === branch.uuid)
                              }
                            />
                            <Typography
                              variant="inherit"
                              noWrap
                              style={{ paddingLeft: "8px" }}
                            >
                              {branch.name}
                            </Typography>
                          </MenuItem>
                        </React.Fragment>
                      );
                    })}
                  </Box>
                </div>
              )}
            </PerfectScrollbar>
          </Box>
        </ClickAwayListener>
      </Paper>
    </Popover>
  );
}
