import React from "react";
import IncentiveProgramTableComponent from './incentiveProgramTable.component';
import { useSelector } from "react-redux";

export default function IncentiveProgramTableContainer({
  hasStockflowAddAccessRight,
  hasStockflowEditAccessRight,
  handleSelectAllProgram,
  isError,
  isFetching,
  isSelectAll,
  selectedUUIDs,
  rows,
  tierLimit,
  isSearchResult,
  paginationOptions,
  rowsPerPage,
  handleChangePage,
  page,
  totalFiltered,
  handleChangeRowPerPage,
  handleEdit,
  handleChangeStatus,
  handleDelete,
  handleAddIncentiveProgram,
  handleSelectProgram,
  handleSelectAll,
  handleDeselectAll,
  handleSelectAvailable
}) {
  const lang = useSelector(state => state.constant.languages);
  return (
    <IncentiveProgramTableComponent
      hasStockflowAddAccessRight={hasStockflowAddAccessRight}
      hasStockflowEditAccessRight={hasStockflowEditAccessRight}
      handleSelectAllProgram={handleSelectAllProgram}
      isError={isError}
      isFetching={isFetching}
      isSelectAll={isSelectAll}
      selectedUUIDs={selectedUUIDs}
      rows={rows}
      tierLimit={tierLimit}
      isSearchResult={isSearchResult}
      rowsPerPage={rowsPerPage}
      paginationOptions={paginationOptions}
      handleChangePage={handleChangePage}
      handleChangeRowPerPage={handleChangeRowPerPage}
      page={page}
      totalFiltered={totalFiltered}
      handleEdit={handleEdit}
      handleChangeStatus={handleChangeStatus}
      handleDelete={handleDelete}
      handleAddIncentiveProgram={handleAddIncentiveProgram}
      handleSelectProgram={handleSelectProgram}
      handleSelectAll={handleSelectAll}
      handleDeselectAll={handleDeselectAll}
      handleSelectAvailable={handleSelectAvailable}
      lang={lang}

    />
  )
}