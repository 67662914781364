import React, { useState, useRef, useEffect } from "react";
import AssignProductSelectComponent from "./assignProductSelect.component";
import AssignProductPanelComponent from "./assignProductPanel.component";
import PropTypes from "prop-types";
import { selectLoading } from "modules/notification";
import { useSelector, useDispatch } from "react-redux";
import { getProductDropdown } from "modules/product-mgmt/redux/action/products.action";
import { getLang } from "app/feature/constants";

function AssignProductSelectContainer({
  handleChange,
  value,
  disabled
}) {
  const [open, setOpen] = useState(false);
  const [isOpenList] = useState(false);
  const anchorRef = useRef(null);
  const prevOpen = useRef(open);
  const prevOpenList = useRef(isOpenList);
  const [search, setSearch] = useState("")
  const dispatch = useDispatch()
  const lang = useSelector(state => state.constant.languages)

  const productDropdown = useSelector(
    (state) => state.products.productsDropdown
  );
  const isProductDropdownLoading = useSelector((state) =>
    selectLoading(state, getProductDropdown.typePrefix)
  );

  useEffect(() => {
    dispatch(getProductDropdown({
      search: search
    }));
  }, [search, dispatch]);

  const handleClose = () => {
    setOpen(false);
    setSearch("");
  };

  const handleClick = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    if (prevOpenList.current === true && isOpenList === false) {
      anchorRef.current.focus();
    }

    prevOpenList.current = isOpenList;
  }, [isOpenList]);

  function handleListKeyDown(v) {
    const temp = value.slice();
    const index = temp.findIndex(
      (t) => t.id === v.id
    );
    if (index === -1) {
      temp.push(v);
    } else {
      temp.splice(index, 1);
    }
    handleChange(temp);
  }

  const handleSearch = async (values) => {
    await setSearch(values)
  }

  return (
    <>
      <AssignProductSelectComponent
        anchorRef={anchorRef}
        handleClick={handleClick}
        value={value}
        placeholder={getLang(lang, "label.SELECT_PRODUCT")}
        disabled={disabled}
        lang={lang}
      />
      <AssignProductPanelComponent
        value={value}
        anchorRef={anchorRef}
        open={open}
        isOpenList={isOpenList}
        handleClose={handleClose}
        isFetching={isProductDropdownLoading}
        handleListKeyDown={handleListKeyDown}
        productDropdown={productDropdown}
        handleSearch={handleSearch}
        lang={lang}
      />
    </>
  );
}

AssignProductSelectContainer.propTypes = {
  placeholder: PropTypes.string
};


export default (AssignProductSelectContainer);
