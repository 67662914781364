import React from "react";
import {
  makeStyles,
  Typography,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  Box,
  Divider,
  MenuItem,
  MenuList,
  Checkbox,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles((theme) => ({
  paper: {
    width: 425,
  },
  button: {
    padding: 0,
    minWidth: 0,
  },
  buttonAddBranch: {
    display: "flex",
    padding: 0,
    minWidth: 0,
  },
  createLink: {
    padding: theme.spacing(2),
  },
}));

export default function StockTakeBranchSelectPanelComponent({
  open,
  anchorRef,
  handleClose,
  isOpenList,
  branchDropdown,
  isFetching,
  value,
  handleSelectBranch,
  lang,
}) {
  const classes = useStyle();

  return (
    <Popper
      open={open}
      style={{ zIndex: 99 }}
      anchorEl={anchorRef.current}
      role={undefined}
      transition
      disablePortal
      placement="bottom-start"
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === "bottom-start" ? "center top" : "center bottom",
          }}
        >
          <Paper className={classes.paper}>
            <ClickAwayListener onClickAway={handleClose}>
              <Box>
                <Typography
                  variant="body2"
                  color="primary"
                  style={{ padding: "8px 16px", fontWeight: "bold" }}
                >
                  {getLang(lang, "label.BRANCH")}
                </Typography>
                <Divider />
                {isFetching ? (
                  <Box p={1}>
                    <Skeleton variant="text" animation="wave" />
                    <Skeleton variant="text" animation="wave" />
                    <Skeleton variant="text" animation="wave" />
                    <Skeleton variant="text" animation="wave" />
                  </Box>
                ) : (
                  <div>
                    <Box height="25vh" overflow="auto">
                      {branchDropdown.map((field, index) => {
                        return (
                          <React.Fragment key={index}>
                            <MenuItem
                              style={{ padding: 0 }}
                              onClick={() => handleSelectBranch(field)}
                            >
                              <Checkbox
                                checked={
                                  value?.node_id === field.node_id
                                    ? true
                                    : false
                                }
                              />
                              <Typography
                                variant="inherit"
                                noWrap
                                style={{ paddingLeft: "8px" }}
                              >
                                {field.name}
                              </Typography>
                            </MenuItem>

                            {field?.child?.map((branch, index) => (
                              <React.Fragment key={index}>
                                <MenuList
                                  autoFocusItem={isOpenList}
                                  id="menu-list-grow"
                                  style={{ padding: 0 }}
                                >
                                  <MenuItem
                                    style={{
                                      paddingTop: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 40,
                                      paddingRight: 0,
                                    }}
                                    onClick={() => {
                                      handleSelectBranch(branch);
                                    }}
                                  >
                                    <Checkbox
                                      checked={
                                        value?.node_id === branch.node_id
                                          ? true
                                          : false
                                      }
                                    />

                                    <Typography
                                      variant="inherit"
                                      noWrap
                                      style={{ paddingLeft: "8px" }}
                                    >
                                      {branch.name}
                                    </Typography>
                                  </MenuItem>
                                </MenuList>
                                {branch.remarks === "region" &&
                                  branch.child &&
                                  branch.child.map(
                                    (childBranch, childIndex) => (
                                      <React.Fragment key={childIndex}>
                                        <MenuList
                                          autoFocusItem={isOpenList}
                                          id="menu-list-grow"
                                          style={{ padding: 0 }}
                                        >
                                          <MenuItem
                                            style={{
                                              paddingTop: 0,
                                              paddingBottom: 0,
                                              paddingLeft: 80,
                                              paddingRight: 0,
                                            }}
                                            onClick={() => {
                                              handleSelectBranch(childBranch);
                                            }}
                                          >
                                            <Checkbox
                                              checked={
                                                value?.node_id ===
                                                childBranch.node_id
                                                  ? true
                                                  : false
                                              }
                                            />

                                            <Typography
                                              variant="inherit"
                                              noWrap
                                              style={{ paddingLeft: "8px" }}
                                            >
                                              {childBranch.name}
                                            </Typography>
                                          </MenuItem>
                                        </MenuList>
                                      </React.Fragment>
                                    )
                                  )}
                              </React.Fragment>
                            ))}
                          </React.Fragment>
                        );
                      })}
                    </Box>
                  </div>
                )}
              </Box>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}
