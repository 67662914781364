import axios from "axios"

const url = "/distributor/admin/api/v1/getDealers";

export function GetDealerListing({
  length,
  start,
  search,
  order,
  search_by,
  dealer
}) {
  return new Promise((resolve, reject) => {
    axios.get(url, {
      params: {
        length,
        start,
        search,
        order,
        search_by,
        dealer
      }
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data))
  })
}