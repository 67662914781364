import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useRouteMatch } from "react-router-dom"
import { selectLoading } from 'modules/notification'
import {
  getBoxActivityLog,
  getPackageActivityLog,
  resetActivityLog
} from "modules/stockflow/redux"
import ActivityLogPanelComponent from './activityLogPanel.component'

function ActivityLogPanelContainer({
  type,
  language,
  loading
}) {
  const match = useRouteMatch()
  const uuid = match.params.uuid

  const lang = useSelector(state => state.constant.languages)
  const activityLog = useSelector(state => state.stockflowInventory.activityLog)
  const isFetching = useSelector(state => selectLoading(state, (type === "box" ? getBoxActivityLog.typePrefix : getPackageActivityLog.typePrefix)))
  const isError = useSelector(state => state.stockflowInventory.isLoadingActivityLogError)
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      dispatch(resetActivityLog())
    }
  }, [dispatch])

  useEffect(() => {
    if (type === "box") {
      dispatch(getBoxActivityLog(uuid))
    }
    if (type === "package") {
      dispatch(getPackageActivityLog(uuid))
    }
  }, [dispatch, uuid, type])

  return (
    <ActivityLogPanelComponent
      log={activityLog}
      isFetching={isFetching}
      isError={isError}
      loading={loading}
      lang={lang}
      language={language}
    />
  )
}

export default ActivityLogPanelContainer