import React from "react";
import { useSelector } from "react-redux";
import ModuleTableComponent from "./moduleTable.component";

export default function ModuleTableContainer({
  tableData,
  bonanza,
  percent
}) {
  const productDropdown = useSelector(
    (state) => state.products.productsDropdown
  );

  const moduleDropdown = useSelector(
    (state) => state.loyaltyProgram.modulesDropdown
  );

  const lang = useSelector(state => state.constant.languages)

  return (
    <ModuleTableComponent
      tableData={tableData}
      productDropdown={bonanza ? moduleDropdown : productDropdown}
      bonanza={bonanza}
      percent={percent}
      lang={lang}
    />
  );
}
