import React from "react";
import {IconButton} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

export default function LanguageSelectComponent({
  handleClick,
  anchorRef,
  disabled,
}) {
  return (
    <IconButton
      disabled={disabled}
      ref={anchorRef}
      onClick={handleClick}
    >
      <AddIcon />
    </IconButton>
  );
}
