import { createSlice } from "@reduxjs/toolkit";
import { STOCK_SUMMARY } from "modules/stock-summary/constants";
import {
  getBranchDropdown,
  getBranchStockSummary,
  getCountryDropdown,
  getMonthlyStockSummary,
  getProductDropdown,
  getProductStockSummary,
} from "../action/stockSummary.action";

const initialState = {
  filterState: {
    country: null,
    branch: null,
    product: null,
    year: null,
    month: null,
  },
  countryDropdown: [],
  branchDropdown: [],
  productDropdown: [],
  monthlySummary: {
    list: [],
    totalFiltered: 0,
    totalRecords: 0,
    isError: false,
    totalSummary: null,
  },
  branchSummary: {
    list: [],
    totalFiltered: 0,
    totalRecords: 0,
    isError: false,
    totalSummary: null,
  },
  productSummary: {
    list: [],
    totalFiltered: 0,
    totalRecords: 0,
    isError: false,
    totalSummary: null,
  },
};

const stockSummarySlice = createSlice({
  name: STOCK_SUMMARY,
  initialState,
  reducers: {
    resetSummary(state) {
      state.monthlySummary = initialState.monthlySummary;
      state.branchSummary = initialState.branchSummary;
      state.productSummary = initialState.productSummary;
      state.countryDropdown = initialState.countryDropdown;
      state.branchDropdown = initialState.branchDropdown;
      state.productDropdown = initialState.productDropdown;
    },
  },
  extraReducers: {
    [getMonthlyStockSummary.pending]: (state, action) => {
      state.monthlySummary = initialState.monthlySummary;
    },
    [getMonthlyStockSummary.fulfilled]: (state, action) => {
      const { payload } = action;
      state.monthlySummary.totalSummary = payload.total;
      state.monthlySummary.list = payload.data;
      state.monthlySummary.totalRecords = payload.recordsTotal;
      state.monthlySummary.totalFiltered = payload.recordsFiltered;
    },
    [getMonthlyStockSummary.rejected]: (state, action) => {
      state.monthlySummary.isError = true;
    },
    [getBranchStockSummary.pending]: (state, action) => {
      state.branchSummary = initialState.branchSummary;
    },
    [getBranchStockSummary.fulfilled]: (state, action) => {
      const { payload } = action;
      state.branchSummary.totalSummary = payload.total;
      state.branchSummary.list = payload.data;
      state.branchSummary.totalRecords = payload.recordsTotal;
      state.branchSummary.totalFiltered = payload.recordsFiltered;
    },
    [getBranchStockSummary.rejected]: (state, action) => {
      state.branchSummary.isError = true;
    },
    [getProductStockSummary.pending]: (state, action) => {
      state.productSummary = initialState.productSummary;
    },
    [getProductStockSummary.fulfilled]: (state, action) => {
      const { payload } = action;
      state.productSummary.totalSummary = payload.total;
      state.productSummary.list = payload.data;
      state.productSummary.totalRecords = payload.recordsTotal;
      state.productSummary.totalFiltered = payload.recordsFiltered;
    },
    [getProductStockSummary.rejected]: (state, action) => {
      state.productSummary.isError = true;
    },
    [getCountryDropdown.fulfilled]: (state, action) => {
      state.countryDropdown = action.payload;
    },
    [getBranchDropdown.fulfilled]: (state, action) => {
      state.branchDropdown = action.payload;
    },
    [getProductDropdown.fulfilled]: (state, action) => {
      const { payload } = action;
      state.productDropdown = payload.data;
    },
  },
});

export const { resetSummary } = stockSummarySlice.actions;

export const stockSummaryReducer = stockSummarySlice.reducer;
