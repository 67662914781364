import React from "react";
import StockTakeBoxListTableComponent from "./stockTakeBoxListTable.component";

export default function StockTakeBoxListTableContainer({
  rows,
  isFetching,
  isError,
  handleReload,
  handleChangePage,
  handleChangeRowPerPage,
  totalFiltered,
  paginationOptions,
  tableFilter,
  changeOrder,
  lang,
  handleViewDetail,
  isCompleted,
}) {
  return (
    <StockTakeBoxListTableComponent
      rows={rows}
      isFetching={isFetching}
      isError={isError}
      handleReload={handleReload}
      handleChangePage={handleChangePage}
      handleChangeRowPerPage={handleChangeRowPerPage}
      totalFiltered={totalFiltered}
      paginationOptions={paginationOptions}
      tableFilter={tableFilter}
      changeOrder={changeOrder}
      lang={lang}
      handleViewDetail={handleViewDetail}
      isCompleted={isCompleted}
    />
  );
}
