import React from "react";
import {
  Box,
  makeStyles,
} from "@material-ui/core";
import {
  SurveyInfoCard,
  TotalResponseCard,
  SurveyGeneralCard,
  CircularChartCard,
  SurveyAgeCard,
  SurveyDeviceInfoCard,
} from "../../card"
import { ScanLocationCard } from "components/card";
import SearchableSelect from '../../select/searchableSelect';
import { getLang } from "app/feature/constants";

const useStyles = makeStyles(() => ({
  mainContainer: {
    gridGap: 16,
    gridTemplateColumns: "3fr 7fr",
  },
  halfContainer: {
    display: "grid",
    gridGap: 16,
    gridTemplateColumns: "6fr 6fr",
  }
}));

export default function SurveyReportPanelComponent({
  isScanLocationLoading,
  surveyDetails,
  totalResponse,
  totalVisit,
  language,
  responseCount,
  surveyCountByAge,
  surveyCountByProduct,
  surveyCountByGender,
  surveyCountByDevice,
  surveyCountByGeo,
  totalDevice,
  isDetailsLoading,
  handleLastPeriod,
  lastPeriod,
  openEditSurveyModal,
  lang
}) {
  const classes = useStyles();
  
  return (
    <Box className={classes.mainContainer}>
      <Box>
        <Box mb={2} display="flex" justifyContent="flex-end">
          <SearchableSelect
            handleChange={(value) => handleLastPeriod(value)}
            disabled={false}
            value={lastPeriod}
            dropdownItem={[getLang(lang, 'label.TODAY'), getLang(lang, 'label.ONE_WEEK'), getLang(lang, 'label.ONE_MONTH'), getLang(lang, 'label.ONE_YEAR'), getLang(lang, 'label.ALL')]}
            emptyMessage={getLang(lang, 'message.info').NO_RESPONSE_FOUND}
            style={{ width: 120, backgroundColor: "#fff" }}
          />
        </Box>
        <Box style={{ marginBottom: "16px" }}>
        <SurveyInfoCard
          language={language}
          surveyDetails={surveyDetails}
          isDetailLoading={isDetailsLoading}
          openEditSurveyModal={openEditSurveyModal}
        />
        </Box>
        <Box mb={2}>
          <TotalResponseCard totalResponse={totalResponse} />
        </Box>
        <Box mb={2} className={classes.halfContainer}>
          <SurveyGeneralCard title={getLang(lang, 'label.SURVEY_VISITS')} data={totalVisit.value} percentage={totalVisit.percentage} type={totalVisit.type} />
          <SurveyGeneralCard title={getLang(lang, 'label.NUMBER_OF_RESPONSE')} data={responseCount.value} percentage={responseCount.percentage} type={responseCount.type} />
        </Box>
        <Box mb={2}>
          <SurveyAgeCard title={getLang(lang, 'label.AGE')} data={surveyCountByAge} />
        </Box>
        <Box mb={2} className={classes.halfContainer}>
          <CircularChartCard title={getLang(lang, 'label.PRODUCT')} type="pie" data={surveyCountByProduct.data} labels={surveyCountByProduct.label} />
          <CircularChartCard title={getLang(lang, 'label.GENDER')} type="doughnut" data={surveyCountByGender.data} labels={surveyCountByGender.label} />
        </Box>
        <Box mb={2} className={classes.halfContainer}>
          <SurveyDeviceInfoCard data={surveyCountByDevice} total={totalDevice} />
          <ScanLocationCard isLoading={isScanLocationLoading} scanData={surveyCountByGeo} />
        </Box>
      </Box>
    </Box>
  )
}

