import React, { useState } from "react";
import AddCreditCardFormComponent from "./addCreditCardForm.component";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import { addPaymentMethod } from "../../../redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { selectLoading } from "modules/notification";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";

function AddCreditCardFormContainer({
  handleResult,
  handleClose,
  addPaymentMethod,
  isAddingPaymentMethod,
  setIsPrimary
}) {
  const [error, setError] = useState(null);
  const stripe = useStripe();
  const elements = useElements();
  const countries = useSelector(state => state.constant.country.data)
  const lang = useSelector(state => state.constant.languages)

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    if (!stripe || !elements) {
      setSubmitting(false);
      return;
    }

    values.postCode = values.postCode.trim();

    const cardElement = elements.getElement(CardElement);

    const billing_details = {
      name: values.name,
      email: values.email,
      address: {
        city: values.city,
        country: values.country.code,
        line1: values.addressLine1,
        line2: values.addressLine2,
        state: values.state,
        postal_code: values.postCode
      }
    };

    const createPaymentMethod = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details
    });

    if (createPaymentMethod.error) {
      setError(createPaymentMethod.error.message);
      setSubmitting(false);
      return;
    }

    await addPaymentMethod({
      isDefault: setIsPrimary,
      payment_method: createPaymentMethod.paymentMethod.id,
    })
      .then(unwrapResult)
      .then((response) => {
        setSubmitting(false);
        handleResult();
      })
      .catch((error) => {
        setSubmitting(false);
      });

    handleClose();
  };

  const handleCard = (event) => {
    if (event.error) {
      setError(event.error.code);
    } else {
      setError(null);
    }
  };

  return (
    <AddCreditCardFormComponent
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      handleCard={handleCard}
      error={error}
      isAddingPaymentMethod={isAddingPaymentMethod}
      countries={countries}
      lang={lang}
    />
  );
}

AddCreditCardFormContainer.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleResult: PropTypes.func
};

const mapStateToProps = (state) => ({
  isAddingPaymentMethod: selectLoading(state, addPaymentMethod.typePrefix)
});

const mapDispatchToProps = (dispatch) => ({
  addPaymentMethod: (payload) => dispatch(addPaymentMethod(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddCreditCardFormContainer);
