import React from 'react'
import PointCutDialogComponent from './pointCutDialog.component'
import { useSelector } from 'react-redux'

export default function PointCutDialogContainer({ isOpen, handleClose }) {
    const headerType = useSelector((state) => state.redemptionCenter.headerType);
    
    return (
        <PointCutDialogComponent
            isOpen={isOpen}
            handleClose={handleClose}
            headerType={headerType}
        />
    )
}