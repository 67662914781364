import React from "react";
import {
  makeStyles,
  ButtonBase,
  Typography
} from "@material-ui/core";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import Proptypes from "prop-types";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "5px 12px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#eceff0",
    borderRadius: 4,
    height: 43,
    overflow: "hidden"
  },
  inputText: {
    fontSize: "1rem",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    paddingLeft: "6px"
  },
  enabledColor: {
    color: "#344054",
  },
  disabledColor: {
    color: "#D0D5DD",
  },
  filled: {
    color: "rgba(0, 0, 0, 0.87)",
  }
}))

function ActionSelectComponent({
  handleClick,
  label,
  disabled,
  style,
  open,
  id
}) {
  const classes = useStyles();

  return (
    <ButtonBase
      aria-describedby={id}
      className={classes.root}
      disableRipple
      onClick={handleClick}
      disabled={disabled}
      style={{ padding: 12, ...style }}
    >
      <Typography
        variant='body2'
        className={clsx(classes.inputText, { 
          [classes.disabledColor]: disabled,
          [classes.enabledColor] : !disabled 
        })}
      >
        {label}
      </Typography>
      {open ? (
        <ArrowDropUpIcon
          fontSize='small'
          className={clsx(classes.enabledColor, { [classes.disabledColor]: disabled })}
        />
      ) : (
        <ArrowDropDownIcon
          fontSize='small'
          className={clsx(classes.enabledColor, { [classes.disabledColor]: disabled })}
        />
      )}
    </ButtonBase>
  )
}

ActionSelectComponent.propTypes = {
  handleClick: Proptypes.func,
  label: Proptypes.string,
  disabled: Proptypes.bool
}

export default ActionSelectComponent

