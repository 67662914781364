import React from "react";

export const EditImg = () => (
  <svg
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.5455 15.2728C14.5455 14.8711 14.2199 14.5455 13.8182 14.5455H0.727278C0.325613 14.5455 0 14.8711 0 15.2728C0 15.6745 0.325614 16.0001 0.727278 16.0001H13.8182C14.2199 16.0001 14.5455 15.6745 14.5455 15.2728Z"
      fill="#3A4D54"
    />
    <path
      d="M11.1362 0.707106C10.7457 0.316582 10.1126 0.316582 9.72204 0.707107L1.74749 8.68165C1.55996 8.86918 1.4546 9.12354 1.4546 9.38875V12.0909C1.4546 12.6432 1.90232 13.0909 2.4546 13.0909H5.15677C5.42198 13.0909 5.67634 12.9856 5.86387 12.798L13.8384 4.82348C14.2289 4.43296 14.2289 3.7998 13.8384 3.40927L11.1362 0.707106ZM5.26023 11.3435C5.07269 11.531 4.81833 11.6364 4.55312 11.6364H3.90916C3.35688 11.6364 2.90916 11.1886 2.90916 10.6364V9.9924C2.90916 9.72718 3.01452 9.47283 3.20205 9.28529L9.72204 2.76528C10.1126 2.37476 10.7457 2.37476 11.1363 2.76528L11.7802 3.40924C12.1707 3.79976 12.1707 4.43293 11.7802 4.82345L5.26023 11.3435Z"
      fill="#3A4D54"
    />
  </svg>
);
