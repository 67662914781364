import paymentMethod from "./paymentMethod.api";
import paymentMethods from "./paymentMethods.api";
import subscription from "./subscription.api";
import presubscription from "./presubscription.api";
import plans from "./plans.api";
import localPlans from "./local-plans.api";
import formula from "./formula.api";
import payment from "./payment.api";
import coupon from "./coupon.api";
import transaction from "./transaction.api";
import swapSubscription from "./swap-subscription.api";
import swapSubscriptionPreview from "./swap-subscription-preview.api";


const paymentApi = {
  plans,
  localPlans,
  paymentMethod,
  paymentMethods,
  presubscription,
  subscription,
  formula,
  payment,
  coupon,
  transaction,
  swapSubscription,
  swapSubscriptionPreview
};

export default paymentApi;
