import React from "react"
import { useSelector } from "react-redux"
import BulkEditComponent from './bulkEdit.page'

function BulkEditContainer() {
  const lang = useSelector(state => state.constant.languages)

  return (
    <BulkEditComponent lang={lang} />
  )
}

export default BulkEditContainer