import { createAsyncThunk } from '@reduxjs/toolkit';
import productApi from 'app/api/product';

export const PRODUCTS = "products";

export const addBrandModel = createAsyncThunk(
  `${PRODUCTS}/addBrandModels`,
  async (payload, { rejectWithValue }) => {
    return productApi.addBrandModel({
      name: payload.name,
      models: payload.models,
    })
      .then(response => ({...response, data: {...response.data}}))
      .catch(error => rejectWithValue(error))
  }
)

export const getAllBrandModelsListing = createAsyncThunk(
  `${PRODUCTS}/getAllBrandModelsListing`,
  async (payload, { rejectWithValue }) => {
    return productApi.getBrandModelListing({
      length: payload.length,
      start: payload.start,
      column: payload.column,
      order: payload.order,
      search: payload.search,
      status: payload.status,
    })
      .then(response => response.data)
      .catch(error => rejectWithValue(error))
  }
)

export const updateBrandModelStatus = createAsyncThunk(
  `${PRODUCTS}/updateBrandModelStatus`,
  async (payload, { rejectWithValue }) => {
    return productApi.updateBrandModelStatus({
      id: payload.id,
      status: payload.status,
    })
      .then(response => response.data)
      .catch(error => rejectWithValue(error))
  }
)

export const editBrandModel = createAsyncThunk(
  `${PRODUCTS}/editBrandModel`,
  async (payload, { rejectWithValue }) => {
    return productApi.editBrandModel({
      id: payload.id,
      name: payload.name,
      models: payload.models,
      status: payload.status,
      description: payload.description,
      imageUrl: payload.imageUrl,
    })
      .then(response => response.data)
      .catch(error => rejectWithValue(error))
  }
)

export const deleteBrandModels = createAsyncThunk(
  `${PRODUCTS}/deleteBrandModels`,
  async (payload, { rejectWithValue }) => {
    return productApi.deleteBrandModels({
      id: payload.id,
    })
      .then(response => response.data)
      .catch(error => rejectWithValue(error))
  }
)

export const updateBrandModelsStatus = createAsyncThunk(
  `${PRODUCTS}/updateBrandModelsStatus`,
  async (payload, { rejectWithValue }) => {
    return productApi.updateBrandModelsStatus({
      filter: payload.filter,
      update: payload.update,
    })
      .then(response => response.data)
      .catch(error => rejectWithValue(error))
  }
)

export const getBrandModelDropdown = createAsyncThunk(
  `${PRODUCTS}/getBrandModelDropdown`,
  async (payload, { rejectWithValue }) => {
    return productApi.getBrandModelDropdown({
      search: payload.search
    })
      .then(response => response.data)
      .catch(error => rejectWithValue(error))
  }
)

export const getBrandDropdownWithNoBrand = createAsyncThunk(
  `${PRODUCTS}/getBrandDropdownWithNoBrand`, 
  async (payload, { rejectWithValue }) => {
    return productApi.getBrandDropdownWithNoBrand({}
      )
      .then(response => response.data)
      .catch(error => rejectWithValue(error))
  }
);

export const addModelsToExistingBrand = createAsyncThunk(
  `${PRODUCTS}/addModelsToExistingBrand`, 
  async (payload, { rejectWithValue }) => {
    return productApi.addModelsToExistingBrand({
      brand_uuid : payload.brand_uuid,
      models: payload.models,
    })
      .then(response => response.data)
      .catch(error => rejectWithValue(error))
  }
);

export const setBrandImage = createAsyncThunk(
  `${PRODUCTS}/setBrandImage`,
  async (payload, { rejectWithValue }) => {
    return productApi.setBrandImage({
      id: payload.id,
      imageUrl: payload.imageUrl,
    })
      .then(response => response.data)
      .catch(error => rejectWithValue(error))
  }
)
