import React, { useState } from "react";
import StocktakeItemsProductListPanelComponent from "./stocktakeItemsProductListPanel.component";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import StocktakeProductSnListingDialog from "../../dialog/stocktakeProductSnListingDialog";
import {
  getStockTakeItemProductList,
  resetStockTakeProductListing,
} from "modules/stockflow/redux";
import { selectLoading } from "modules/notification";
import { generatePaginationOptions } from "lib/helper";

export default function StocktakeItemsProductListPanelContainer({
  lang,
  isSearchable,
  isCompleted,
  id,
}) {
  const dispatch = useDispatch();

  const stocktake = useSelector((state) => state.stockflowStockTake.stocktake);

  const isFetching = useSelector((state) =>
    selectLoading(state, getStockTakeItemProductList.typePrefix)
  );
  const totalFiltered = useSelector(
    (state) => state.stockflowStockTake.stockTakeProducts.totalFiltered
  );
  const list = useSelector(
    (state) => state.stockflowStockTake.stockTakeProducts.list
  );
  const isError = useSelector(
    (state) => state.stockflowStockTake.stockTakeProducts.isError
  );

  const [filter, setFilter] = useState({
    page: 0,
    rowsPerPage: 25,
    search: "",
    order: "asc",
    orderBy: "name", // name|total_quantity|matched|extra|missing
  });
  const [paginationOptions, setPaginationOptions] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);

  useEffect(() => {
    fetchData();

    return () => {
      dispatch(resetStockTakeProductListing());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, filter]);

  useEffect(() => {
    setPaginationOptions(generatePaginationOptions(totalFiltered));
  }, [totalFiltered]);

  const fetchData = () => {
    dispatch(
      getStockTakeItemProductList({
        id,
        length: filter.rowsPerPage,
        start: filter.rowsPerPage * filter.page,
        search: filter.search,
        order: filter.order,
        order_by: filter.orderBy,
      })
    );
  };

  const handleChangePage = (event, newPage) => {
    let newData = {
      ...filter,
      page: newPage,
    };
    setFilter(newData);
  };

  const handleChangeRowsPerPage = (event) => {
    let newData = {
      ...filter,
      page: 0,
      rowsPerPage: event.target.value,
    };
    setFilter(newData);
  };

  const handleReload = () => {
    const newFilter = {
      ...filter,
      page: 0,
    };
    setFilter(newFilter);
  };

  const handleSearch = (values) => {
    dispatch(resetStockTakeProductListing());
    const newFilter = {
      ...filter,
      page: 0,
      search: values.search,
    };
    setFilter(newFilter);
  };

  const handleClear = () => {
    dispatch(resetStockTakeProductListing());
    const newFilter = {
      ...filter,
      page: 0,
      search: "",
    };
    setFilter(newFilter);
  };

  const changeOrder = (orderBy, order) => {
    let newData = {
      ...filter,
      order,
      orderBy,
    };
    setFilter(newData);
  };

  const viewProduct = (prd) => {
    setSelectedProduct(prd);
  };

  return (
    <>
      <StocktakeItemsProductListPanelComponent
        lang={lang}
        isFetching={isFetching}
        list={list}
        totalFiltered={totalFiltered}
        isError={isError}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleReload={handleReload}
        changeOrder={changeOrder}
        paginationOptions={paginationOptions}
        isSearchable={isSearchable}
        filter={filter}
        handleSearch={handleSearch}
        isCompleted={isCompleted}
        viewProduct={viewProduct}
        handleClear={handleClear}
      />
      <StocktakeProductSnListingDialog
        isOpen={!!selectedProduct}
        handleClose={() => setSelectedProduct(null)}
        id={id}
        productInfo={selectedProduct}
        isCompleted={stocktake?.status === "Completed"}
      />
    </>
  );
}
