import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import ApproveClaimDialogComponent from "./approveClaimDialog.component";

function ApproveClaimDialogContainer({ isOpen, handleClose, handleConfirm }) {

  const lang = useSelector(state => state.constant.languages);

  return (
    <ApproveClaimDialogComponent
      isOpen={isOpen}
      handleClose={handleClose}
      handleConfirm={handleConfirm}
      lang={lang}
    />
  );
}

ApproveClaimDialogContainer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
};

export default ApproveClaimDialogContainer;
