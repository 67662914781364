import React from "react";
import OrderDetailsDialogComponent from "./orderSuccessDialog.component";

export default function OrderDetailsDialogContainer({ isOpen, handleClose, lang, statusResult }) {

  return (
    <OrderDetailsDialogComponent
      isOpen={isOpen}
      handleClose={handleClose}
      lang={lang}
      statusResult={statusResult}
    />
  );
}
