import React from "react";
import {
  Paper,
  makeStyles,
  Box,
  Typography,
  Grid,
  Button
} from "@material-ui/core";
import { EditSVG } from "../../../utils/static";
import SubscriptionPlanBG from "../../../utils/image/subscription_plan_bg.png";
import ProPlan from "../../../utils/image/ProPlan.png";
import BizPlan from "../../../utils/image/BizPlan.png";
import LitePlan from "../../../utils/image/LitePlan.png";
import { LITE, BUSINESS, PROFESSIONAL } from "lib/constants/subscriptionPlan";
import { getLang } from "app/feature/constants";

const style = (theme) => ({
  root: {
    zIndex: "999"
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: "20px 20px 100px rgba(0, 0, 0, 0.25)",
    borderRadius: "10px",
    marginTop: theme.spacing(2),
    position: "relative"
  },
  image: {
    width: "auto",
    maxWidth: "100%"
  },
  imagePlan: {
    paddingTop: theme.spacing(4),
    maxWidth: 86
  },
  button: {
    position: "absolute",
    margin: theme.spacing(3, 2, 3, 2),
    background: "#ECEFF0",
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)",
    borderRadius: "100px",
    zIndex: "9",
    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(2)
    }
  },
  background: {
    display: "flex",
    placeContent: "center"
  },
  planName: {
    textAlign: "center",
    paddingTop: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(1)
    }
  },
  border: {
    borderBottom: "solid 3px",
    color: "#D6D6D6",
    width: "30%",
    display: "flex",
    alignSelf: "center"
  },
  gridContainer: {
    width: "100%",
    display: "flex",
    placeContent: "flex-end"
  },
  text: {
    color: "rgba(58, 77, 84, 0.5)"
  },
  buttonEdit: {
    padding: 0,
    minWidth: 0
  }
});

const useStyles = makeStyles(style);

export default function SubscriptionPlanCardComponent({
  handleOpenPerks,
  subscriptionPlan,
  handleSubcription,
  lang
}) {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Grid container style={{ alignItems: "center" }}>
        <Grid item xs={6} sm={6} md={6} style={{ width: "100%" }}>
          <Typography
            variant="body2"
            color="primary"
            style={{ fontWeight: "bold" }}
          >
            {getLang(lang, "label.SUBSCRIPTION_PLAN")}
          </Typography>
        </Grid>

        <Grid item xs={6} sm={6} md={6} className={classes.gridContainer}>
          <Button className={classes.buttonEdit} onClick={handleSubcription}>
            <EditSVG />
          </Button>
        </Grid>
      </Grid>
      <Paper elevation={0} className={classes.paper}>
        <Button
          variant="outlined"
          color="primary"
          size="small"
          className={classes.button}
          onClick={handleOpenPerks}
        >
          {getLang(lang, "label.PERKS")}
        </Button>

        <Box className={classes.background}>
          <img
            src={SubscriptionPlanBG}
            alt="background"
            className={classes.image}
          />

          <Box position="absolute">
            {subscriptionPlan.productName === LITE ? (
              <img
                src={LitePlan}
                alt="lite plan"
                className={classes.imagePlan}
              />
            ) : subscriptionPlan.productName === BUSINESS ? (
              <img src={BizPlan} alt="biz plan" className={classes.imagePlan} />
            ) : subscriptionPlan.productName === PROFESSIONAL ? (
              <img src={ProPlan} alt="pro plan" className={classes.imagePlan} />
            ) : null}

            <Box className={classes.planName}>
              <Typography
                variant="h4"
                color="primary"
                style={{ fontWeight: 900 }}
              >
                {subscriptionPlan.productName === LITE
                  ? getLang(lang, "label.LITE")
                  : subscriptionPlan.productName === BUSINESS
                  ? getLang(lang, "label.BIZ")
                  : subscriptionPlan.productName === PROFESSIONAL
                  ? getLang(lang, "label.PRO")
                  : null}
              </Typography>
              <Box style={{ display: "flex", placeContent: "center" }}>
                <Box className={classes.border} pt={1} />
              </Box>
            </Box>
          </Box>
        </Box>

        <Box p={2}>
          <Grid container>
            <Grid item xs={6} sm={6} md={6} style={{ width: "100%" }}>
              <Typography variant="caption" className={classes.text}>
                {getLang(lang, "label.EXPIRY_DATE")}
              </Typography>
              <br />
              <Typography
                variant="caption"
                color="primary"
                style={{ fontWeight: "bold" }}
              >
                {subscriptionPlan.currentPeriodEnd}
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              sm={6}
              md={6}
              style={{ width: "100%", textAlign: "end" }}
            >
              <Typography variant="caption" className={classes.text}>
              {getLang(lang, "label.COST_PER")} 
              {(subscriptionPlan.interval.slice(1) === "month" ? getLang(lang, 'label.MONTH') : getLang(lang, 'label.YEAR'))} 
              </Typography>
              <br />
              <Typography
                variant="caption"
                color="primary"
                style={{ fontWeight: "bold" }}
              >
                {subscriptionPlan.amount + " " + subscriptionPlan.currency}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
}
