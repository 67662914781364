import React, { useEffect } from "react";
import WinnerDialogComponent from "./winnerDialog.component";
import {
  closeWinnerDialog,
  presetWinner,
} from "modules/instant-lucky-draw/redux";
import { useDispatch, useSelector } from "react-redux";
import { setWinner } from "modules/instant-lucky-draw/redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { hideBackdrop, showBackdrop } from "modules/backdrop";
import { INSTANT_LUCKY_DRAW_EDIT } from "lib/constants/accessRights";
import {
  fetchSerialNumberList,
  resetSerialNumberList,
} from "modules/instant-lucky-draw/redux";

function WinnerDialogContainer({ instantLuckyDraw }) {
  const dispatch = useDispatch();
  const hasInstantLuckyDrawEditAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(INSTANT_LUCKY_DRAW_EDIT)
  );
  const isOpen = useSelector(
    (state) => state.instantLuckyDraw.winnerDialogSetting.isOpen
  );
  const prizeList = useSelector(
    (state) => state.instantLuckyDraw.prizeDialogInfo.prizeList
  );
  const instantID = useSelector(
    (state) => state.instantLuckyDraw.InstantLuckyDrawDialogInfo.info.id
  );
  const search = useSelector(
    (state) => state.instantLuckyDraw.searchForSerialNumber
  );
  const id = useSelector(
    (state) => state.instantLuckyDraw.InstantLuckyDrawDialogInfo.info.id
  );

  const excludeList = useSelector(
    (state) => state.instantLuckyDraw.excludeWinnerList
  );
  const lang = useSelector((state) => state.constant.languages);

  useEffect(() => {
    if (isOpen) {
      const exclude = [];
      excludeList.forEach((element, index) => exclude.push(element.enc));

      const fetchInfo = {
        id,
        length: 25,
        start: 0,
        search: search,
        status: "active",
        exclude: exclude,
      };
      dispatch(fetchSerialNumberList(fetchInfo));
      return () => {
        dispatch(resetSerialNumberList());
      };
    }
  }, [dispatch, id, search, excludeList, isOpen]);

  const handleSave = async () => {
    dispatch(showBackdrop());

    const prizesUpdateInfo = [];
    for (const prize of prizeList) {
      const prizeWinners = {
        prizeId: prize.id,
        enc: [],
      };

      for (const winner of prize.winners) {
        if (!winner.status) prizeWinners.enc.push(winner.enc);
      }
      prizesUpdateInfo.push(prizeWinners);
    }
    const info = {
      instantLuckyDrawId: instantID,
      winnerField: prizesUpdateInfo,
    };

    await dispatch(
      instantLuckyDraw.type === "current"
        ? presetWinner(info)
        : setWinner(info)
    )
      .then(unwrapResult)
      .then((response) => {
        dispatch(hideBackdrop());
        dispatch(closeWinnerDialog());
      })
      .catch((error) => {
        dispatch(hideBackdrop());
      });
  };

  return (
    <WinnerDialogComponent
      hasInstantLuckyDrawEditAccessRight={hasInstantLuckyDrawEditAccessRight}
      handleClose={() => dispatch(closeWinnerDialog())}
      isOpen={isOpen}
      prizeList={prizeList}
      handleSave={handleSave}
      lang={lang}
    />
  );
}

export default WinnerDialogContainer;
