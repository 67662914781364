import { convertToNumberString } from "lib/generalUtility";

export const calculateQRCreditCost = (qty, formula, discountCalculation) => {
  if (!qty || !formula) return;

  const basePrice = Number(formula.credit_plan.base_price);
  const isDiscounted = formula.discount ? qty > Number(formula.discount.min_order) : false;
  const discountType = formula.discount ? formula.discount.type : '';
  const discounts = isDiscounted ? (formula.discount ? Number(formula.discount.price) : 0) : 0;
  const subTotal_1 = qty * basePrice;

  const coupon = discountCalculation ? discountCalculation.price : 0;
  const couponType = discountCalculation ? discountCalculation.type : "";
  const couponAmount = discountCalculation
    ? getCouponAmount(discountCalculation, subTotal_1)
    : 0;

  const subTotal_2 = couponAmount < subTotal_1 ? subTotal_1 - couponAmount : 0;

  const discountAmount = isDiscounted
    ? discountType === "percent"
      ? subTotal_2 * (discounts / 100)
      : discounts
    : 0;

  const subTotal_3 = subTotal_2 - discountAmount;

  const taxType = formula.credit_plan.tax_type;
  const tax =
    taxType === "percent"
      ? Number(formula.credit_plan.tax) / 100
      : Number(formula.credit_plan.tax);
  const taxAmount = taxType === "percent" ? subTotal_3 * tax : tax;

  const totalPrice = subTotal_3 + taxAmount;
  return {
    quantity: qty,
    basePrice,
    subTotal_1: convertToNumberString(subTotal_1),
    discounts,
    discountAmount: convertToNumberString(discountAmount),
    subTotal_2: convertToNumberString(subTotal_2),
    coupon: couponType === "fixed" ? convertToNumberString(coupon) : coupon,
    couponType,
    couponAmount: convertToNumberString(couponAmount),
    subTotal_3: convertToNumberString(subTotal_3),
    tax,
    taxAmount: convertToNumberString(taxAmount),
    totalPrice: convertToNumberString(totalPrice)
  };
};

function getCouponAmount(discountCalculation, totalAmount) {
  const type = discountCalculation.type;
  const price = discountCalculation.price;

  switch (type) {
    case "fixed":
      return price;

    case "percent":
      return (price / 100) * totalAmount;

    default:
      return 0;
  }
}
