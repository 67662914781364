import React from "react";
import {
  makeStyles,
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from "@material-ui/core";
import { StyledTableCell, StyledTableRow } from "components/table";
import { TablePaginationActions } from "components/tablePagination";
import { ActionButton } from 'components/button';
import { SkeletonTableRow } from 'components/skeleton/skeleton.component';
import AntSwitch from 'components/switch/switch';
import Alert from "@material-ui/lab/Alert";
import { convertToBigNum } from "lib/generalUtility";
import RefreshTable from "components/table/refreshTable";
import { getLang } from "app/feature/constants";
import StickyLeftTableCell from "components/table/stickyLeftTableCell";
import StickyRightTableCell from "components/table/stickyRightTableCell";
import moment from "moment";
import "moment/min/locales.min";

const useStyle = makeStyles(theme => ({
  tableContainer: {
    maxHeight: 500,
    overflowY: "auto"
  },
  noProductContainer: {
    backgroundColor: theme.palette.background.paper,
    textAlign: "center"
  },
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    borderCollapse: "separate",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
    },
    "& th,td": {
      fontSize: ".75rem",
      borderRight: "1px solid rgba(58, 77, 84, 0.1)",
      padding: theme.spacing(1, 2, 1, 2),
    },
    "& th": {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightBold,
    },
    "& th:last-child, td:last-child": {
      width: 37,
      borderRight: "0px",
    },
    "& th th": {
      borderBottom: "none !important",
    },
    "& td td": {
      borderBottom: "none !important",
    },
  },
  productPhoto: {
    height: 100,
    width: 100,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    "& img": {
      height: "100%",
      minWidth: "100%",
      minHeight: "100%",
      objectFit: "cover",
      flexShrink: 0
    }
  },
  iconBtn: {
    borderRadius: theme.spacing(0.5),
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shortest,
    }),
    padding: "3px 5px"
  },
  button: {
    display: "flex",
    alignItems: "center",
    padding: 0,
    minWidth: 0,
    marginLeft: theme.spacing(1)
  },
}));

export default function RedemptionsTableComponent({
  hasGiftAddAccessRight,
  hasGiftEditAccessRight,
  hasGiftActivateOrDeactivateAccessRight,
  data,
  isFetching,
  page,
  rowsPerPage,
  totalRecords,
  handleChangePage,
  handleChangeRowsPerPage,
  handleCreate,
  handleView,
  handleStatusChange,
  search,
  isLoadingGiftListError,
  handleReload,
  paginationOptions,
  language,
  lang
}) {
  const classes = useStyle();
  
  if(language==="ZH")
  {
    moment.locale('zh-cn')
  }else if(language==="TL")
  {
    moment.locale('tl-ph')
  }else
  {
    moment.locale(language)
  }

  return (
    <>
      {isFetching || data.length > 0 ? (
        <TableContainer>
          <Table className={classes.table} aria-label="product table" stickyHeader>
            <TableHead>
              <TableRow>
                <StickyLeftTableCell>
                  <StyledTableCell>{getLang(lang, "label.NUM")}</StyledTableCell>
                </StickyLeftTableCell>
                <StyledTableCell>{getLang(lang, "label.GIFT_NAME")}</StyledTableCell>
                <StyledTableCell style={{ textAlign: "right" }}>{getLang(lang, "label.POINT_REQUIRED")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.DATE_CREATED")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.PERIOD")}</StyledTableCell>
                <StyledTableCell style={{ textAlign: "right" }}>{getLang(lang, "label.STOCK")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.STATUS")}</StyledTableCell>
                <StickyRightTableCell>
                  <StyledTableCell>{getLang(lang, "label.ACTION")}</StyledTableCell>
                </StickyRightTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isFetching
                ? [...new Array(5)].map((i, index) => (
                  <SkeletonTableRow key={index} columnCount={8} />
                ))
                : data.map((row, index) => {
                  let status = row.status === "active" ? true : false
                  const actionItems = [
                    {
                      label: hasGiftEditAccessRight ? getLang(lang, "label.VIEW_EDIT") : getLang(lang, "label.VIEW"),
                      action: () => handleView({ id: row.giftId }),
                      disabled: !status && true
                    }
                  ]

                  return (
                    <StyledTableRow key={index} hover role="checkbox" tabIndex={-1}>
                      <StickyLeftTableCell>
                        <StyledTableCell component="th" scope="row" style={{ borderBottom: "0px" }}>
                          {index + 1 + rowsPerPage * page}
                        </StyledTableCell>
                      </StickyLeftTableCell>
                      <StyledTableCell>{row.name}</StyledTableCell>
                      <StyledTableCell style={{ textAlign: "right" }}>{convertToBigNum(row.point)}</StyledTableCell>
                      <StyledTableCell>{moment(row.dateAdded).format('ll')}</StyledTableCell>
                      <StyledTableCell>{moment(row.startAt).format('lll')} - {moment(row.endAt).format('lll')}</StyledTableCell>
                      <StyledTableCell style={{ textAlign: "right" }}>{convertToBigNum(row.stock)}</StyledTableCell>
                      <StyledTableCell>
                        <AntSwitch
                          checked={status}
                          disabled={!hasGiftActivateOrDeactivateAccessRight}
                          onChange={() =>
                            handleStatusChange({
                              currentStatus: status,
                              id: row.giftId
                            })
                          }
                        />
                      </StyledTableCell>
                      <StickyRightTableCell>
                        <StyledTableCell>
                          <div style={{ display: "flex", justifyContent: "center" }}>
                            <ActionButton actionItems={actionItems} popperStyle={{ zIndex: 999 }} />
                          </div>
                        </StyledTableCell>
                      </StickyRightTableCell>
                    </StyledTableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : !search && !isLoadingGiftListError && (
        <Box>
          <Table className={classes.table} aria-label="product table" stickyHeader>
            <TableHead>
              <TableRow>
                <StyledTableCell>{getLang(lang, "label.NUM")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.GIFT_NAME")}</StyledTableCell>
                <StyledTableCell style={{ textAlign: "right" }}>{getLang(lang, "label.POINT_REQUIRED")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.DATE_CREATED")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.PERIOD")}</StyledTableCell>
                <StyledTableCell style={{ textAlign: "right" }}>{getLang(lang, "label.STOCK")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.STATUS")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.ACTION")}</StyledTableCell>
              </TableRow>
            </TableHead>
          </Table>
          <Box py={9} className={classes.noProductContainer}>
            <Typography variant="body1">
              {getLang(lang, "paragraph.NO_GIFT")}
            </Typography>
            {
              hasGiftAddAccessRight
                ?
                <Box mt={2}>
                  <Button
                    onClick={handleCreate}
                    variant="contained"
                    disableElevation
                    color="primary"
                  >
                    {getLang(lang, "label.ADD_GIFT")}
                  </Button>
                </Box>
                : null
            }
          </Box>
        </Box>

      )}

      {!isFetching && data.length === 0 && search && (
        <Alert severity="info">{getLang(lang, "message.info.NO_GIFT")}</Alert>
      )}

      {!isFetching && isLoadingGiftListError && (
        <RefreshTable handleReload={handleReload} />
      )}

      {!isFetching && data.length > 0 && (
        <TablePagination
          style={{
            backgroundColor: "#fff",
            boxShadow: "0px -1px 0px rgba(58, 77, 84, 0.15)",
          }}
          ActionsComponent={TablePaginationActions}
          rowsPerPageOptions={paginationOptions}
          component="div"
          count={totalRecords}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage={getLang(lang, 'label.ROWS_PER_PAGE')}
          labelDisplayedRows={({ from, to, count }) => `${from.toLocaleString("en-US")}-${to.toLocaleString("en-US")} ${getLang(lang, 'label.OF')} ${count.toLocaleString("en-US")}`}
        />
      )}
    </>
  )
}
