import { createAsyncThunk } from "@reduxjs/toolkit";
import stockflowApi from "app/api/stockflow";

export const STOCKFLOWREQUESTTRANSFER = "stockflowRequestTransfer";

export const getRequestSendList = createAsyncThunk(
  `${STOCKFLOWREQUESTTRANSFER}/getRequestSendList`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi
      .getRequestTransferSendList({
        ...payload,
      })
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const getRequestReceiveList = createAsyncThunk(
  `${STOCKFLOWREQUESTTRANSFER}/getRequestReceiveList`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi
      .getRequestTransferReceiveList({
        ...payload,
      })
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const getRequestDetail = createAsyncThunk(
  `${STOCKFLOWREQUESTTRANSFER}/getRequestDetail`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi
      .getRequestDetail(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const getRequestSenderDropdown = createAsyncThunk(
  `${STOCKFLOWREQUESTTRANSFER}/getRequestSenderDropdown`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi
      .getRequestSenderDropdown(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const getRequestReceiverDropdown = createAsyncThunk(
  `${STOCKFLOWREQUESTTRANSFER}/getRequestReceiverDropdown`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi
      .getRequestReceiverDropdown(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const approveRequestTransfer = createAsyncThunk(
  `${STOCKFLOWREQUESTTRANSFER}/approveRequestTransfer`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi
      .approveRequestTransfer(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const rejectApproveRequestTransfer = createAsyncThunk(
  `${STOCKFLOWREQUESTTRANSFER}/rejectApproveRequestTransfer`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi
      .rejectApproveRequestTransfer(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const getRequestStockList = createAsyncThunk(
  `${STOCKFLOWREQUESTTRANSFER}/getRequestStockList`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi
      .getRequestStockList(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const getRequestTransferRequestBranches = createAsyncThunk(
  `${STOCKFLOWREQUESTTRANSFER}/getRequestTransferRequestBranches`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi
      .getRequestTransferRequestBranches(payload)
      .then((response) => response)
      .catch((error) => rejectWithValue(error));
  }
);

export const getRequestTransferTargetBranches = createAsyncThunk(
  `${STOCKFLOWREQUESTTRANSFER}/getRequestTransferTargetBranches`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi
      .getRequestTransferTargetBranches(payload)
      .then((response) => response)
      .catch((error) => rejectWithValue(error));
  }
);

export const getRequestTransferAddressList = createAsyncThunk(
  `${STOCKFLOWREQUESTTRANSFER}/getRequestTransferAddressList`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi
      .getRequestTransferAddressList(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const addRequestTransferAddress = createAsyncThunk(
  `${STOCKFLOWREQUESTTRANSFER}/addRequestTransferAddress`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi
      .addRequestTransferAddress(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const updateRequestTransferAddress = createAsyncThunk(
  `${STOCKFLOWREQUESTTRANSFER}/updateRequestTransferAddress`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi
      .updateRequestTransferAddress(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const createRequestTransfer = createAsyncThunk(
  `${STOCKFLOWREQUESTTRANSFER}/createRequestTransfer`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi
      .createRequestTransfer(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const generatePoPdf = createAsyncThunk(
  `${STOCKFLOWREQUESTTRANSFER}/generatePoPdf`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi
      .generatePoPdf(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);
