/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useSelector } from "react-redux";
import DeliveryDetailInputPanelComponent from "./deliveryDetailInputPanel.component";

function DeliveryDetailInputPanelContainer({
  handleSubmit,
  isUpdating,
  deliveryInfo,
  handleCancel,
  hasDeliveryEditAccessRight
}) {
  const lang = useSelector((state) => state.constant.languages);

  return (
    <DeliveryDetailInputPanelComponent
      updateDelivery={handleSubmit}
      isUpdating={isUpdating}
      lang={lang}
      deliveryInfo={deliveryInfo}
      handleCancel={handleCancel}
      hasDeliveryEditAccessRight={hasDeliveryEditAccessRight}
    />
  );
}

export default DeliveryDetailInputPanelContainer;
