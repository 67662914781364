import React from "react";
import {
  Box,
  Button,
  Link,
  makeStyles,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@material-ui/core";
import { StyledTableCell, StyledTableRow } from "components/table";
import { SkeletonTableRow } from "components/skeleton/skeleton.component";
import { Alert } from "@material-ui/lab";
import { ActionButton } from "components/button";
import { TablePaginationActions } from "components/tablePagination";
import AntSwitch from "components/switch/switch";
import { progressStatus } from "lib/constants/instantLuckyDrawProgress";
import { instantCreated, prizeCreated, published } from "./instantProgress";
import RefreshTable from "components/table/refreshTable";
import { getLang } from "app/feature/constants";
import StickyLeftTableCell from "components/table/stickyLeftTableCell";
import StickyRightTableCell from "components/table/stickyRightTableCell";
import ProductCountDetailPopover from "../../../../../components/popover/productCountDetailPopover";
import moment from "moment";
import "moment/min/locales.min";

const useStyle = makeStyles((theme) => ({
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    borderCollapse: "separate",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
    },
    "& th,td": {
      fontSize: ".75rem",
      borderRight: "1px solid rgba(58, 77, 84, 0.1)",
      padding: theme.spacing(1, 2, 1, 2),
    },
    "& th": {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightBold,
    },
    "& th:last-child, td:last-child": {
      width: 37,
      borderRight: "0px",
    },
    "& th th": {
      borderBottom: "none !important",
    },
    "& td td": {
      borderBottom: "none !important",
    },
  },
  emptyContainer: {
    textAlign: "center",
  },
  statusChip: {
    padding: theme.spacing(0.2, 0.5),
    borderRadius: theme.spacing(0.5),
    color: theme.palette.common.white,
    textAlign: "center",
  },
  statusInactive: {
    backgroundColor: "#FF8000",
  },
  statusActive: {
    backgroundColor: "#1f8d35",
  },
  button: {
    display: "flex",
    alignItems: "center",
    padding: 0,
    minWidth: 0,
    marginLeft: theme.spacing(1),
  },
  attributeText: {
    color: "#b2b2b2",
    fontSize: 12,
    whiteSpace: "break-spaces",
  },
}));

export default function InstantLuckyDrawTableComponent({
  hasInstantLuckyDrawAddAccessRight,
  hasInstantLuckyDrawEditAccessRight,
  hasInstantLuckyDrawActivateOrDeactivateAccessRight,
  isFetching,
  data,
  rowsPerPage,
  page,
  search,
  handlePageChange,
  handleRowPerPageChange,
  totalRecords,
  handleStatusChange,
  handleCreate,
  handleView,
  handleViewParticipants,
  handleViewPrize,
  handleDownloadWinner,
  handlePublished,
  isLoadingInstantError,
  handleReload,
  paginationOptions,
  lang,
  language,
}) {
  const classes = useStyle();

  if (language === "ZH") {
    moment.locale('zh-cn')
  } else if (language === "TL") {
    moment.locale('tl-ph')
  } else {
    moment.locale(language)
  }

  return (
    <>
      {data.length > 0 || isFetching ? (
        <TableContainer>
          <Table
            className={classes.table}
            aria-label="product table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <StickyLeftTableCell>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      padding: "0px 12px",
                    }}
                  >
                    {getLang(lang, "label.NUM")}
                  </Box>
                </StickyLeftTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.INSTANT_LUCKY_DRAW_NAME")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.IMAGE")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.DATE_CREATED")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.PRODUCTS")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.PUBLISHED")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.EVENT_PERIOD")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.PRIZE_QUANTITY")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.PARTICIPANTS")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.REMAIN_TRANSACTION")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.STATUS")}
                </StyledTableCell>
                <StickyRightTableCell>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      padding: "0px 12px",
                    }}
                  >
                    {getLang(lang, "label.ACTION")}
                  </Box>
                </StickyRightTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isFetching
                ? [...new Array(5)].map((v, index) => (
                    <SkeletonTableRow key={index} columnCount={11} />
                  ))
                : data.map((item, index) => {
                    var actionItems;
                    switch (item.progress) {
                      case progressStatus.new:
                        actionItems = instantCreated({
                          hasInstantLuckyDrawEditAccessRight,
                          item,
                          disableViewParticipants: item.participantCount === 0,
                          handleView,
                          handleViewParticipants,
                          handleViewPrize,
                          lang,
                        });
                        break;
                      case progressStatus.setPrize:
                        actionItems = prizeCreated({
                          hasInstantLuckyDrawEditAccessRight,
                          item,
                          disableViewParticipants: item.participantCount === 0,
                          handleView,
                          handleViewParticipants,
                          handleViewPrize,
                          handleDownloadWinner,
                          handlePublished,
                          lang,
                        });
                        break;
                      case progressStatus.published:
                        actionItems = published({
                          hasInstantLuckyDrawEditAccessRight,
                          item,
                          disableViewParticipants: item.participantCount === 0,
                          handleView,
                          handleViewParticipants,
                          handleViewPrize,
                          handleDownloadWinner,
                          lang,
                        });
                        break;
                      default:
                        break;
                    }
                    return (
                      <StyledTableRow
                        key={index}
                        hover
                        role="checkbox"
                        tabIndex={-1}
                      >
                        <StickyLeftTableCell>
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              padding: "0px 12px",
                            }}
                          >
                            {index + 1 + rowsPerPage * page}
                          </Box>
                        </StickyLeftTableCell>
                        <StyledTableCell>{item.name}</StyledTableCell>
                        <StyledTableCell>
                          <img
                            src={item.imgUrl}
                            style={{ width: "30px", height: "30px" }}
                            alt="poster"
                          />
                        </StyledTableCell>
                        <StyledTableCell>
                          {moment(item.createdAt).format("ll")}
                        </StyledTableCell>
                        <ProductCountDetailPopover
                          products={item.products}
                          itemTranslator={(product) => ({
                            id: product.id,
                            name: product.name,
                            imageUrl: product.picture,
                            attributes: product.attributes,
                          })}
                        />
                        <StyledTableCell>
                          {item.progress === progressStatus.published ? (
                            <Typography
                              variant="caption"
                              style={{ color: "#4BCB0F" }}
                            >
                              {getLang(lang, "label.PUBLISHED")}
                            </Typography>
                          ) : (
                            <Typography
                              variant="caption"
                              style={{ color: "#FF1D1D" }}
                            >
                              {getLang(lang, "label.NOT_YET")}
                            </Typography>
                          )}
                        </StyledTableCell>
                        <StyledTableCell>
                          {moment.utc(item.startAt).local().format("lll")}
                          &ensp;-&ensp;
                          {moment.utc(item.endAt).local().format("lll")}
                        </StyledTableCell>
                        <StyledTableCell>
                          {item.prizeQty.toLocaleString("en-US")}
                        </StyledTableCell>
                        <StyledTableCell>
                          {item.participantCount > 0 ? (
                            <Link
                              color="secondary"
                              href="#"
                              onClick={(event) => {
                                event.preventDefault();
                                handleViewParticipants(item.id);
                              }}
                            >
                              {item.participantCount.toLocaleString("en-US")}
                            </Link>
                          ) : (
                            <Box>
                              {item.participantCount.toLocaleString("en-US")}
                            </Box>
                          )}
                        </StyledTableCell>
                        <StyledTableCell>
                          {item.transactionRemaining.toLocaleString("en-US")}
                        </StyledTableCell>
                        <StyledTableCell>
                          <AntSwitch
                            checked={item.status}
                            disabled={
                              !hasInstantLuckyDrawActivateOrDeactivateAccessRight
                            }
                            onChange={() =>
                              handleStatusChange({
                                currentStatus: item.status,
                                id: item.id,
                              })
                            }
                          />
                        </StyledTableCell>
                        <StickyRightTableCell>
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <ActionButton
                              actionItems={actionItems}
                              popperStyle={{ zIndex: 999 }}
                            />
                          </Box>
                        </StickyRightTableCell>
                      </StyledTableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : !search && !isLoadingInstantError ? (
        <Box style={{ overflowX: "auto", backgroundColor: "#fff" }}>
          <Table
            className={classes.table}
            aria-label="product table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <StickyLeftTableCell>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      padding: "0px 12px",
                    }}
                  >
                    {getLang(lang, "label.NUM")}
                  </Box>
                </StickyLeftTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.INSTANT_LUCKY_DRAW_NAME")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.IMAGE")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.DATE_CREATED")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.PRODUCTS")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.PUBLISHED")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.EVENT_PERIOD")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.PRIZE_QUANTITY")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.PARTICIPANTS")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.REMAIN_TRANSACTION")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.STATUS")}
                </StyledTableCell>
                <StickyRightTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.ACTION")}
                  </StyledTableCell>
                </StickyRightTableCell>
              </TableRow>
            </TableHead>
          </Table>
          <Box py={9} className={classes.emptyContainer}>
            <Typography variant="body1">
              {getLang(lang, "paragraph.NO_INSTANT_LUCKY_DRAW_ADDED")}
            </Typography>
            {hasInstantLuckyDrawAddAccessRight ? (
              <Box mt={2}>
                <Button
                  onClick={handleCreate}
                  variant="contained"
                  disableElevation
                  color="primary"
                >
                  {getLang(lang, "label.ADD_INSTANT_LUCKY_DRAW")}
                </Button>
              </Box>
            ) : null}
          </Box>
        </Box>
      ) : null}

      {!isFetching && data.length === 0 && search && (
        <Alert severity="info">
          {getLang(lang, "message.info.NO_RECORD_EXIST")}
        </Alert>
      )}

      {!isFetching && isLoadingInstantError && (
        <RefreshTable handleReload={handleReload} />
      )}

      {!isFetching && data.length > 0 && (
        <TablePagination
          style={{
            backgroundColor: "#fff",
            boxShadow: "0px -1px 0px rgba(58, 77, 84, 0.15)",
          }}
          ActionsComponent={TablePaginationActions}
          component="div"
          count={totalRecords}
          rowsPerPageOptions={paginationOptions}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowPerPageChange}
          SelectProps={{
            classes: classes.toolbar,
          }}
          labelRowsPerPage={getLang(lang, "label.ROWS_PER_PAGE")}
          labelDisplayedRows={({ from, to, count }) =>
            `${from.toLocaleString("en-US")}-${to.toLocaleString(
              "en-US"
            )} ${getLang(lang, "label.OF")} ${count.toLocaleString("en-US")}`
          }
        />
      )}
    </>
  );
}
