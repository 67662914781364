import React from "react"
import { useSelector } from "react-redux";

import { REIMBURSEMENT_LIST_EDIT } from "lib/constants/accessRights";

import WarrantyReimbursementListTableComponent from "./warrantyReimbursementListTable.component"

export default function WarrantyReimbursementListTableContainer({
  page,
  length,
  isFetching,
  rows,
  rowPerPage,
  rowPerPageOption,
  handlePageChange,
  handleRowPerPageChange,
  language,
  handleView,
  handleReload,
  lang
}) {
  const hasReimbursementEditAccessRight = useSelector((state) => state.profile.accessRights.includes(REIMBURSEMENT_LIST_EDIT));
  const isLoadingReimbursementTableError = useSelector(state => state.warranty.isLoadingWarrantyReimbursementError)

  return (
    <>
      <WarrantyReimbursementListTableComponent
        hasReimbursementEditAccessRight={hasReimbursementEditAccessRight}
        isFetching={isFetching}
        rows={rows}
        language={language}
        paginationOptions={rowPerPageOption}
        rowPerPage={rowPerPage}
        page={page}
        length={length}
        handlePageChange={handlePageChange}
        handleRowPerPageChange={handleRowPerPageChange}
        handleView={handleView}
        handleReload={handleReload}
        isLoadingError={isLoadingReimbursementTableError}
        lang={lang}
      />
    </>
  )
}