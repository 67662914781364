import { selectLoading } from "modules/notification";
import { getCitySalesData, getCountrySalesData, getDemographicAgeData, getDemographicAgeGenderData, getDemographicGenderData } from "modules/reporting/redux";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DemographicPanel from "./demographicPanel.component";
import moment from "moment";

import { unwrapResult } from "@reduxjs/toolkit";
import { Box } from "@material-ui/core";
import { flexGapStyle, fontTextStyle } from "modules/reporting/styles";


export default function DemographicPanelContainer({
    currentFilter,
    urlAge,
    urlGender,
    urlGenderAge,
    urlCountry,
    urlCity,
    pageType,
    translate
}) {
    const fontClasses = fontTextStyle();
    const flexGapClasses = flexGapStyle();

    const dispatchAgeGender = useDispatch();
    const isFetchingDemographicAgeData = useSelector(state => selectLoading(state, getDemographicAgeData.typePrefix))
    const demographicAgeData = useSelector(state => state.reporting.demographic.ageData);

    const isFetchingDemographicGenderData = useSelector(state => selectLoading(state, getDemographicGenderData.typePrefix))
    const demographicGenderData = useSelector(state => state.reporting.demographic.genderData);

    const isFetchingDemographicAgeGenderData = useSelector(state => selectLoading(state, getDemographicAgeGenderData.typePrefix))
    const demographicAgeGenderData = useSelector(state => state.reporting.demographic.ageGenderData);
    
    React.useEffect(() => {
        dispatchAgeGender(getDemographicAgeData({
            countryCode: currentFilter.countryCode,
            city_uuid: currentFilter.cityUuid,
            branch_uuid: currentFilter.branchUuid,
            category_uuid: currentFilter.categoryUuid,
            model_uuid: currentFilter.modelUuid,
            brand_uuid: currentFilter.brandUuid,
            product_uuid: currentFilter.productUuid,
            period: currentFilter.period,
            start_date: moment(currentFilter.dateRange[0]).format('YYYY-MM-DD'),
            end_date: moment(currentFilter.dateRange[1]).format('YYYY-MM-DD'),
            url: urlAge
        })).then(unwrapResult);
        dispatchAgeGender(getDemographicGenderData({
            countryCode: currentFilter.countryCode,
            city_uuid: currentFilter.cityUuid,
            branch_uuid: currentFilter.branchUuid,
            category_uuid: currentFilter.categoryUuid,
            model_uuid: currentFilter.modelUuid,
            brand_uuid: currentFilter.brandUuid,
            product_uuid: currentFilter.productUuid,
            period: currentFilter.period,
            start_date: moment(currentFilter.dateRange[0]).format('YYYY-MM-DD'),
            end_date: moment(currentFilter.dateRange[1]).format('YYYY-MM-DD'),
            url: urlGender
        })).then(unwrapResult);
        dispatchAgeGender(getDemographicAgeGenderData({
            countryCode: currentFilter.countryCode,
            city_uuid: currentFilter.cityUuid,
            branch_uuid: currentFilter.branchUuid,
            category_uuid: currentFilter.categoryUuid,
            model_uuid: currentFilter.modelUuid,
            brand_uuid: currentFilter.brandUuid,
            product_uuid: currentFilter.productUuid,
            period: currentFilter.period,
            start_date: moment(currentFilter.dateRange[0]).format('YYYY-MM-DD'),
            end_date: moment(currentFilter.dateRange[1]).format('YYYY-MM-DD'),
            url: urlGenderAge
        })).then(unwrapResult);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatchAgeGender, currentFilter]);

    const dispatchCity = useDispatch();
    const isFetchingCitySalesData = useSelector(state => selectLoading(state, getCitySalesData.typePrefix));
    const citySalesData = useSelector(state => state.reporting.demographic.cityDatalist);
    
    const [tableCityFilter, setTableCityFilter] = useState({
        page: 0,
        rowsPerPage: 10,
        search: "",
        searchBy: "name",
        orderBy: "total_scan",
        orderDirection: "desc",
    })

    React.useEffect(() => {
        dispatchCity(getCitySalesData({
            length: tableCityFilter.rowsPerPage,
            start: tableCityFilter.page * tableCityFilter.rowsPerPage,
            orderBy: tableCityFilter.orderBy,
            orderDirection: tableCityFilter.orderDirection,
            search: tableCityFilter.search,
            searchBy: tableCityFilter.searchBy,
            countryCode: currentFilter.countryCode,
            city_uuid: currentFilter.cityUuid,
            branch_uuid: currentFilter.branchUuid,
            category_uuid: currentFilter.categoryUuid,
            model_uuid: currentFilter.modelUuid,
            brand_uuid: currentFilter.brandUuid,
            product_uuid: currentFilter.productUuid,
            period: currentFilter.period,
            start_date: moment(currentFilter.dateRange[0]).format('YYYY-MM-DD'),
            end_date: moment(currentFilter.dateRange[1]).format('YYYY-MM-DD'),
            url: urlCity
        })).then(unwrapResult);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatchCity, currentFilter, tableCityFilter]);

    const handleCityChangePage = (e, newPage) => {
        setTableCityFilter((prev) => ({
          ...prev,
          page: newPage,
        }))
    }

    const handleCityChangeRowPerPage = (event) => {
        setTableCityFilter((prev) => ({
          ...prev,
          page: 0,
          rowsPerPage: event.target.value
        }))
    }

    const handleCityRequestSort = (event, property) => {
        const isAsc = tableCityFilter.orderBy === property && tableCityFilter.orderDirection === 'asc';
        setTableCityFilter((prev) => ({
            ...prev,
            orderDirection: isAsc ? 'desc' : 'asc',
            orderBy: property,
        }))
    }

    const columnsCity = [
        {
            title: translate("label.NUM"),
            field: null,
            render : (row) => {
                return (
                    <>{row.title}</>
                )
            },
            align: "left"
        },
        {
            title: translate("label.CITY"),
            subtitle: "(" + translate("paragraph.CITY_DRILLDOWN_DESCRIPTION") + ")",
            field: null,
            render : (row) => {
                return (
                    <Box
                        display="flex"
                        className={flexGapClasses.global__flexGap3}
                    >
                        <span>{row.title}</span>
                        <span className={fontClasses.text__subtitle_muted}>{row.subtitle}</span>
                    </Box>
                )
            },
            align: null
        },
        {
            title: translate("label.TOTAL_SCAN"),
            field: "total_scan",
            render : (row) => {
                return (
                    <>{row.title}</>
                )
            },
            align: "left"
        },
        {
            title: translate("label.SUSPENDED"),
            field: "total_suspended",
            render : (row) => {
                return (
                    <span>{row.title}</span>
                )
            },
            align: "left"
        },
        // {
        //     title: translate("label.TOTAL_SALES"),
        //     field: null,
        //     render : (row) => {
        //         return (
        //             <span>{row.title}</span>
        //         )
        //     },
        //     align: "left"
        // },
    ];

    const dispatchCountry = useDispatch();
    const isFetchingCountrySalesData = useSelector(state => selectLoading(state, getCountrySalesData.typePrefix));
    const countrySalesData = useSelector(state => state.reporting.demographic.countryDatalist);

    const [tableCountryFilter, setTableCountryFilter] = useState({
        page: 0,
        rowsPerPage: 10,
        search: "",
        searchBy: "name",
        orderBy: "total_scan",
        orderDirection: "desc",
    })

    React.useEffect(() => {
        dispatchCountry(getCountrySalesData({
            length: tableCountryFilter.rowsPerPage,
            start: tableCountryFilter.page * tableCountryFilter.rowsPerPage,
            orderBy: tableCountryFilter.orderBy,
            orderDirection: tableCountryFilter.orderDirection,
            search: tableCountryFilter.search,
            searchBy: tableCountryFilter.searchBy,
            countryCode: currentFilter.countryCode,
            city_uuid: currentFilter.cityUuid,
            branch_uuid: currentFilter.branchUuid,
            model_uuid: currentFilter.modelUuid,
            category_uuid: currentFilter.categoryUuid,
            product_uuid: currentFilter.productUuid,
            brand_uuid: currentFilter.brandUuid,
            period: currentFilter.period,
            start_date: moment(currentFilter.dateRange[0]).format('YYYY-MM-DD'),
            end_date: moment(currentFilter.dateRange[1]).format('YYYY-MM-DD'),
            url: urlCountry
        })).then(unwrapResult);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatchCountry, tableCountryFilter, currentFilter])

    const handleCountryChangePage = (e, newPage) => {
        setTableCountryFilter((prev) => ({
          ...prev,
          page: newPage,
        }))
    }

    const handleCountryChangeRowPerPage = (event) => {
        setTableCountryFilter((prev) => ({
          ...prev,
          page: 0,
          rowsPerPage: event.target.value
        }))
    }

    const handleCountryRequestSort = (event, property) => {
        const isAsc = tableCountryFilter.orderBy === property && tableCountryFilter.orderDirection === 'asc';
        setTableCountryFilter((prev) => ({
            ...prev,
            orderDirection: isAsc ? 'desc' : 'asc',
            orderBy: property,
        }))
    }

    const columnsCountry = [
        {
            title: translate("label.NUM"),
            field: null,
            render : (row) => {
                return (
                    <>{row.title}</>
                )
            },
            align: "left"
        },
        {
            title: translate("label.COUNTRY"),
            subtitle: "(" + translate("paragraph.COUNTRY_DRILLDOWN_DESCRIPTION") + ")",
            field: null,
            render : (row) => {
                return (
                    <Box
                        display="flex"
                        className={flexGapClasses.global__flexGap3}
                    >
                        <span>{row.title}</span>
                        <span className={fontClasses.text__subtitle_muted}>{row.subtitle}</span>
                    </Box>
                )
            },
            align: null
        },
        {
            title: translate("label.TOTAL_SCAN"),
            field: "total_scan",
            render : (row) => {
                return (
                    <>{row.title}</>
                )
            },
            align: "left"
        },
        {
            title: translate("label.SUSPENDED"),
            field: "total_suspended",
            render : (row) => {
                return (
                    <span>{row.title}</span>
                )
            },
            align: "left"
        },
        // {
        //     title: translate("label.TOTAL_SALES"),
        //     field: null,
        //     render : (row) => {
        //         return (
        //             <span>{row.title}</span>
        //         )
        //     },
        //     align: "left"
        // },
    ];

    return (
        <DemographicPanel
        
            status={{
                isFetchingDemographicAgeData,
                isFetchingDemographicGenderData,
                isFetchingDemographicAgeGenderData,
                isFetchingCitySalesData,
                isFetchingCountrySalesData
            }}

            data={{
                demographicAgeData,
                demographicGenderData,
                demographicAgeGenderData,
                citySalesData,
                countrySalesData
            }}

            currentFilter={currentFilter}

            sortCity={{
                valueToOrderBy: tableCityFilter.orderBy,
                orderDirection: tableCityFilter.orderDirection,
                handleRequestSort: handleCityRequestSort,
            }}

            paginationCity={{
                handleChangePage: handleCityChangePage,
                handleChangeRowPerPage: handleCityChangeRowPerPage,
                page: tableCityFilter.page,
                rowsPerPage: tableCityFilter.rowsPerPage,
                rowsPerPageOptions: [5, 10, 25],
            }}

            columnsCity={columnsCity}

            sortCountry={{
                valueToOrderBy: tableCountryFilter.orderBy,
                orderDirection: tableCountryFilter.orderDirection,
                handleRequestSort: handleCountryRequestSort,
            }}

            paginationCountry={{
                handleChangePage: handleCountryChangePage,
                handleChangeRowPerPage: handleCountryChangeRowPerPage,
                page: tableCountryFilter.page,
                rowsPerPage: tableCountryFilter.rowsPerPage,
                rowsPerPageOptions: [5, 10, 25],
            }}

            pageType={pageType}

            columnsCountry={columnsCountry}

            translate={translate}
        />
    )
}