import axios from "axios";

const get = ({ id }) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/consumer/admin/api/v1/claims/listing?id=${id}`)
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

const post = ({ id, status, reason, extendPeriod }) => {
  const body = {
    id: id,
    status: status,
    reason: reason,
    extend_period: extendPeriod,
  };

  return new Promise((resolve, reject) => {
    axios
      .post(`/consumer/admin/api/v1/claims/update`, body)
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

const all = ({ length, start, column, search, status, from, to }) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/consumer/admin/api/v1/claims`, {
        params: {
          length: length,
          start: start,
          column: column,
          search: search === "" ? null : search,
          status: status,
          from: from,
          to: to,
        }
      })
      .then((response) => resolve(response.data))
      .catch(error => reject(error.response.data));
  })
}

const show = ({ claimId, reimbursementId }) => {
  return new Promise((resolve, reject) => {
    axios.post(`/consumer/admin/api/v1/claims/show`, {
      claim_id: claimId,
      reimbursement_id: reimbursementId
    })
      .then((response) => resolve(response.data))
      .catch(error => reject(error.response.data));
  })
}

const exportExcel = ({ token, type, length, start, search, searchColumn, status, fromDate, toDate }) => {
  return new Promise((resolve, reject) => {
    axios.post(`/consumer/export/v1/claims`, {
      token,
      type,
      length,
      start,
      search,
      column: searchColumn,
      status,
      from: fromDate,
      to: toDate,
    }, {responseType: 'arraybuffer'})
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'claim.xlsx');
        document.body.appendChild(link);
        link.click();

        resolve(response.data)
      })
      .catch(error => reject(error.response.data));
  })
}

const warrantyClaims = {
  get,
  post,
  all,
  show,
  exportExcel
};

export default warrantyClaims;
