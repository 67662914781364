import React from "react";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";

// We can inject some CSS into the DOM.
const styles = theme => ({
  label: {
    fontSize: "smaller"
  }
});

function CustomTextField(props) {
  const { classes, className, InputProps, ...other } = props;

  return (
    <TextField
      InputLabelProps={{
        className: classes.label
      }}
      InputProps={InputProps}
      {...other}
      className={className}
    />
  );
}

export default withStyles(styles)(CustomTextField);
