import React, { useEffect } from "react";
import ListingComponent from "./adtInfoListing.page";
import { useDispatch, useSelector } from "react-redux";
import AdtInfoAddInstructionDialog from "modules/serial-number/components/dialog/adtInfoAddInstructionDialog";
import { getAllProducts } from "modules/product-mgmt/redux/action/products.action";
import { getConsumerPageSetting } from "modules/account/redux";

function AdtInfoListingContainer() {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.constant.languages);

  useEffect(() => {
    dispatch(getConsumerPageSetting());
    dispatch(getAllProducts());
  }, [dispatch]);

  return (
    <>
      <ListingComponent lang={lang} />
      <AdtInfoAddInstructionDialog />
    </>
  );
}

export default AdtInfoListingContainer;
