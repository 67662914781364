import axios from "axios";
import PropTypes from "prop-types";

const post = ({ group, interval }) => {
  return new Promise((resolve, reject) => {
    const body = {
      group,
      interval
    };
    axios
      .post("/payment/api/v1/pre-subscription", body)
      .then(response => resolve(response))
      .catch(error => {
        const message = error.toJSON().message;
        return reject(message);
      });
  });
};

post.propTypes = {
  plan: PropTypes.string.isRequired
};

const preSubscription = {
  post
};

export default preSubscription;
