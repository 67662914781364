import React, { useState, useRef, useEffect } from "react";
import SetWinnerSelectComponent from "./setWinnerSelect.component";
import SetWinnerPanelContainer from "./setWinnerPanel.container";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { getLang } from "app/feature/constants";

function SetWinnerSelectContainer({ placeholder, handleChange, value }) {
  const [open, setOpen] = useState(false);
  const lang = useSelector(state => state.constant.languages)
  const [isOpenList] = useState(false);
  const anchorRef = useRef(null);
  const prevOpen = useRef(open);
  const prevOpenList = useRef(isOpenList);
  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    if (prevOpenList.current === true && isOpenList === false) {
      anchorRef.current.focus();
    }

    prevOpenList.current = isOpenList;
  }, [isOpenList]);

  function handleListKeyDown(serialNumber, id) {
    handleChange(serialNumber, id);
    setOpen(false);
  }

  return (
    <>
      <SetWinnerSelectComponent
        anchorRef={anchorRef}
        handleClick={handleClick}
        value={value}
        placeholder={getLang(lang, 'placeholder.SELECT_WINNER')}
      />
      <SetWinnerPanelContainer
        value={value}
        anchorRef={anchorRef}
        open={open}
        isOpenList={isOpenList}
        handleClose={handleClose}
        handleListKeyDown={handleListKeyDown}
      />
    </>
  );
}

SetWinnerSelectContainer.propTypes = {
  placeholder: PropTypes.string
};

export default SetWinnerSelectContainer;
