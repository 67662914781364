import React from "react";
import ModalComponent from "./modal.component";
import PropTypes from "prop-types";

function ModalContainer({
  title,
  isOpen,
  handleClose,
  children,
  helperText,
  maxWidth,
}) {
  return (
    <ModalComponent
      isOpen={isOpen}
      handleClose={handleClose}
      title={title}
      children={children}
      helperText={helperText}
      maxWidth={maxWidth}
    />
  );
}

ModalContainer.defaultProps = {
  title: "",
  helperText: "",
};

ModalContainer.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  helperText: PropTypes.string,
};

export default ModalContainer;
