import { CircularProgress, makeStyles, TableCell } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
    tableRow: {
        padding: "100px 0",
    }
}))

export default function TableCellLoadingComponent(){
    const classes = useStyles();
    return (
        <TableCell align="center" className={classes.tableRow} colspan={"100%"}>
            <CircularProgress />
        </TableCell>
    )
}