import PropTypes from "prop-types";
import koodServerInstance from "./instance";

const getPresignedImageUrl = async fileName => {
  const data = { fileName };

  const response = await koodServerInstance.post(
    '/getPresignedUrl', data
  );
  
  return response.data.data;
};

getPresignedImageUrl.propTypes = {
  fileName: PropTypes.string.isRequired
};

export default getPresignedImageUrl;
