import React from "react"
import {
  Backdrop,
  Dialog,
  makeStyles,
  Typography,
  Paper,
  Box,
  Button,
  Divider,
  IconButton,
  Tooltip
} from "@material-ui/core";
import { Close as CloseIcon, Add as AddIcon } from "@material-ui/icons";
import { Formik } from "formik"
import Input from 'components/input/inputTextField';
import AntSwitch from 'components/switch/switch';
import { DustBinIconSvg } from 'modules/product-mgmt/utils/staticSvg';
import { isEmpty } from 'lib/helper';
import { getLang } from "app/feature/constants";

const useStyle = makeStyles((theme) => ({
  dialog: {
    maxWidth: "none",
  },
  root: {
    boxShadow: theme.shadows[5],
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "space-between",
    width: 560,
    borderRadius: "4px",
    fontSize: 14
  },
  header: {
    paddingLeft: theme.spacing(3),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  content: {
    padding: "20px 16px",
    overflow: "auto",
    maxHeight: "500px"
  },
  buttonContainer: {
    padding: "10px 16px",
    display: "flex",
    justifyContent: "flex-end"
  },
  inputRoot: {
    paddingTop: "8px !important",
    paddingBottom: "7px !important",
    paddingLeft: "12px !important",
    paddingRight: "12px !important"
  },
  modelContainer: {
    display: "grid",
    gridTemplateColumns: "5fr 1fr 1fr",
    columnGap: theme.spacing(1)
  }
}));

export default function EditAttributeDialogComponent({
  isOpen,
  handleClose,
  handleSubmit,
  data,
  lang,
  isSaving,
}) {
  const classes = useStyle()

  return (
    <Dialog
      maxWidth="sm"
      open={isOpen}
      onClose={handleClose}
      disableBackdropClick
      closeAfterTransition
      BackdropComponent={Backdrop}
      classes={{
        papperWidthSm: classes.dialog
      }}
      style={{
        overflow: "unset"
      }}
    >
      <Paper elevation={0} className={classes.root}>
        <Box className={classes.header}>
          <Typography variant='subtitle2' color="primary">{getLang(lang,"label.EDIT_ATTRIBUTE")}</Typography>
          <IconButton color='primary' onClick={handleClose}>
            <CloseIcon fontSize='small' />
          </IconButton>
        </Box>
        <Divider />
        <Formik
          initialValues={{
            attribute: data,
            options: data.options || []
          }}
          enableReinitialize
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              {!isEmpty(formik.values.attribute) ? (
                <Box >
                  <Box className={classes.content}>
                    <Box style={{ display: "flex", justifyContent: "space-between" }}>
                      <Box style={{ flex: 1 }}>
                        <Box style={{ marginBotom: "16px" }}>
                          <Typography variant='body2' color='primary' style={{ fontFamily: "Roboto" }}>{getLang(lang,"label.ATTRIBUTES")}</Typography>
                        </Box>
                        <Input
                          hiddenLabel
                          variant="filled"
                          placeholder={getLang(lang,"placeholder.ENTER_ATTRIBUTE_NAME")}
                          margin="none"
                          InputProps={{
                            disableUnderline: true,
                            margin: "none",
                            style: {
                              borderRadius: "4px",
                              backgroundColor: "#eceff0",
                              height: "36.5px"
                            }
                          }}
                          style={{
                            width: "95%",
                          }}
                          value={formik.values.attribute?.name}
                          onChange={(event) => {
                            let temp = { ...formik.values.attribute }

                            temp.name = event.target.value

                            formik.setFieldValue("attribute", temp)
                          }}
                          disabled={isSaving}
                        />
                      </Box>
                      <Box style={{ display: "flex", flexDirection: "column" }}>
                        <Box style={{ marginBottom: "16px" }}>
                          <Typography variant='body2' style={{ fontFamily: "Roboto", color: "#98A2B3" }}>{getLang(lang,"label.STATUS")}</Typography>
                        </Box>
                        <Box style={{ display: "flex", flex: 1, justifyContent: "center", alignItems: "center" }}>
                          <AntSwitch
                            checked={formik.values.attribute?.status === 1 ? true : false}
                            onChange={(event) => {
                              let temp = { ...formik.values.attribute }

                              temp.status = event.target.checked

                              formik.setFieldValue("attribute", temp)
                            }}
                            disabled={isSaving}
                          />
                        </Box>
                      </Box>
                    </Box>
                    <Box style={{ marginTop: "24px", backgroundColor: "#f9faf8", padding: "16px" }}>
                      <Box style={{ display: "flex", justifyContent: "space-between", marginBottom: "16px" }}>
                        <Box>
                          <Typography variant='body2' color='primary' style={{ fontFamily: "Roboto" }}>{getLang(lang,"label.OPTIONS")}</Typography>
                        </Box>
                        <Box style={{ display: "flex", flexDirection: "row" }}>
                          <Button
                            variant='text'
                            color='secondary'
                            disableElevation
                            onClick={() => {
                              let temp = [...formik.values.options]
                              let optionArray = []

                              temp.map((option) => {
                                let optionTemp = { ...option }

                                optionTemp.status = 2

                                optionArray.push(optionTemp)

                                return null;
                              })

                              formik.setFieldValue("options", optionArray)

                            }}
                            style={{ fontFamily: "Roboto", paddingRight: "12px", fontSize: "14px", textTransform: "capitalize", borderRight: "1px solid #d1d1d1" }}
                            disabled={isSaving}
                          >
                            {getLang(lang,"label.DISABLE_ALL")}
                          </Button>
                          <Button
                            variant='text'
                            color='secondary'
                            disableElevation
                            onClick={() => {
                              let temp = [...formik.values.options]
                              let optionArray = []

                              temp.map((option) => {
                                let optionTemp = { ...option }

                                optionTemp.status = 1

                                optionArray.push(optionTemp)

                                return null;
                              })

                              formik.setFieldValue("options", optionArray)

                            }}
                            style={{ fontFamily: "Roboto", paddingLeft: "12px", fontSize: "14px", textTransform: "capitalize" }}
                            disabled={isSaving}
                          >
                            {getLang(lang,"label.ENABLE_ALL")}
                          </Button>
                        </Box>
                      </Box>
                      <Box className={classes.modelContainer}>
                        <Box></Box>
                        <Typography variant='body2' style={{ fontFamily: "Roboto", color: "#98A2B3", textAlign: "center" }}>{getLang(lang,"label.DELETE")}</Typography>
                        <Typography variant='body2' style={{ fontFamily: "Roboto", color: "#98A2B3", textAlign: "center" }}>{getLang(lang,"label.STATUS")}</Typography>
                      </Box>
                      {formik.values.options.length > 0 && formik.values.options.map((option, index) => (
                        <>
                          {option.status !== 3 && (
                            <Box key={option.id + index} className={classes.modelContainer} style={{ marginTop: "12px" }}>
                              <Input
                                hiddenLabel
                                variant="filled"
                                size="small"
                                placeholder={getLang(lang,"placeholder.PLEASE_FILL_OPTION")}
                                margin="none"
                                InputProps={{
                                  disableUnderline: true,
                                  margin: "none",
                                  style: {
                                    borderRadius: "4px",
                                    backgroundColor: "#ECEFF0"
                                  },
                                  classes: { input: classes.inputRoot }
                                }}
                                value={option.value}
                                onChange={(event) => {
                                  let temp = [...formik.values.options]
                                  let option = { ...temp[index] }

                                  option.value = event.target.value

                                  temp[index] = option

                                  formik.setFieldValue("options", temp)

                                }}
                                disabled={isSaving}
                              />
                              <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                {
                                  formik.values.options[index].products_count > 0
                                    || formik.values.options.filter((option) => option.status !== 3).length < 2
                                    ? <Tooltip title={getLang(lang,"tooltips.ATTRIBUTE_CANNOT_BE_DELETED")}>
                                      <span>
                                        <IconButton
                                          color="primary"
                                          onClick={() => {
                                            let temp = [...formik.values.options]
                                            let option = { ...temp[index] }

                                            if (isEmpty(option.id)) {
                                              temp.splice(index, 1)
                                            }
                                            else {
                                              option.status = 3
                                              temp[index] = option
                                            }
                                            formik.setFieldValue("options", temp)
                                          }}
                                          disabled={
                                            formik.values.options[index].products_count > 0
                                              || formik.values.options.filter((option) => option.status !== 3).length < 2
                                              || isSaving
                                              ? true : false}
                                          style={{ padding: "0px" }}
                                        >
                                          <DustBinIconSvg color={
                                            formik.values.options[index].products_count > 0
                                              || formik.values.options.filter((option) => option.status !== 3).length < 2
                                              || isSaving
                                              ? '#c9c9c9' : "#F97066"} />
                                        </IconButton>
                                      </span>
                                    </Tooltip>
                                    : <IconButton
                                      color="primary"
                                      onClick={() => {
                                        let temp = [...formik.values.options]
                                        let option = { ...temp[index] }

                                        if (isEmpty(option.id)) {
                                          temp.splice(index, 1)
                                        }
                                        else {
                                          option.status = 3
                                          temp[index] = option
                                        }
                                        formik.setFieldValue("options", temp)
                                      }}
                                      disabled={
                                        formik.values.options[index].products_count > 0
                                          || formik.values.options.filter((option) => option.status !== 3).length < 2
                                          || isSaving
                                          ? true : false}
                                      style={{ padding: "0px" }}
                                    >
                                      <DustBinIconSvg color={
                                        formik.values.options[index].products_count > 0
                                          || formik.values.options.filter((option) => option.status !== 3).length < 2
                                          || isSaving
                                          ? '#c9c9c9' : "#F97066"} />
                                    </IconButton>
                                }


                              </Box>
                              <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <AntSwitch
                                  checked={option.status === 1 ? true : false}
                                  onChange={(event) => {
                                    let temp = [...formik.values.options]
                                    let optionTemp = { ...temp[index] }

                                    optionTemp.status = event.target.checked ? 1 : 2

                                    temp[index] = optionTemp

                                    formik.setFieldValue("options", temp)
                                  }}
                                  disabled={isSaving}
                                />
                              </Box>
                            </Box>
                          )}
                        </>
                      ))}
                      <Box style={{ marginTop: "24px" }}>
                        <Button
                          variant='text'
                          color="primary "
                          startIcon={<AddIcon />}
                          onClick={() => {
                            let temp = [...formik.values.options]

                            temp.push({
                              id: "",
                              value: "",
                              status: 1,
                            })

                            formik.setFieldValue("options", temp)
                          }}
                          disabled={isSaving}
                        >
                          {getLang(lang,"label.ADD_OPTION")}
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                  <Divider />
                  <Box style={{ display: "flex", justifyContent: "flex-end", padding: "10px 16px" }}>
                    <Button
                      variant='text'
                      color='secondary'
                      disableElevation
                      onClick={() => {
                        formik.setFieldValue("attribute", data)
                        formik.setFieldValue("options", data.options)

                      }}
                      style={{ marginRight: "8px" }}
                      disabled={isSaving}
                    >
                      {getLang(lang,"label.RESET")}
                    </Button>
                    <Button
                      variant='contained'
                      color='secondary'
                      type='submit'
                      disabled={isSaving}
                      disableElevation
                    >
                    {isSaving
                      ? getLang(lang, "label.SAVING")
                      : getLang(lang, "label.UPDATE")}
                    </Button>
                  </Box>
                </Box>
              ) : null}
            </form>
          )}
        </Formik>
      </Paper>

    </Dialog>
  )
}