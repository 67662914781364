import React from "react";
import {
  makeStyles,
  Paper,
  Button,
  Divider,
  Typography,
  IconButton,
  Backdrop,
  Box,
  Link
} from "@material-ui/core";
import Dialog from "components/dialog/customDialog";
import CloseIcon from "@material-ui/icons/Close";
import AntSwitch from "components/switch";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import {
  AddQuantitySVG,
  ErrorSVG,
  UploadImageIcon
} from "../../../utils/static";
import InputTextField from "components/input/inputTextField";
import { SetWinnerTable } from "../../table";
import { isNumberInvalid } from "lib/generalUtility"
import { getLang } from "app/feature/constants";

const style = (theme) => ({
  header: {
    padding: "4px 0px",
    paddingLeft: theme.spacing(3),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  root: {
    width: 550,
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  discardButton: {
    color: theme.palette.error.main
  },
  publishButton: {
    color: theme.palette.common.white
  },
  switchContainer: {
    display: "grid",
    padding: theme.spacing(3),
    gridTemplateColumns: "3fr 6fr"
  },
  switch: {
    display: "flex",
    alignItems: "center"
  },
  textActive: {
    paddingLeft: "8px",
    color: theme.palette.primary.main
  },
  quantityContainer: {
    backgroundColor: "#EBEDEE",
    borderRadius: "4px",
    margin: theme.spacing(0, 3, 2, 3),
    padding: theme.spacing(2),
    display: "grid",
    gridTemplateColumns: "2fr 2fr 3fr",
    alignItems: "center",
    gridColumnGap: theme.spacing(1),
    "&::before": {
      content: '""',
      position: "absolute",
      width: "0",
      height: "0",
      top: "106px",
      left: "30px",
      border: "8px solid",
      borderColor: "transparent transparent transparent",
      borderBottomColor: "#EBEDEE",
      zIndex: 999
    }
  },
  reduceContainer: {
    "&::before": {
      left: "200px"
    }
  },
  iconContainer: {
    borderRadius: "4px 0px 0px 4px",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "80px"
  },
  addIcon: {
    backgroundColor: "#4BCB0F"
  },
  reduceIcon: {
    backgroundColor: "#FD646F",
    color: "#fff"
  },
  addBorder: {
    border: "#4BCB0F 1px solid",
    borderRadius: "0px 4px 4px 0px"
  },
  reduceBorder: {
    border: "#FD646F 1px solid",
    borderRadius: "0px 4px 4px 0px"
  },
  buttonExcel: {
    backgroundColor: "#ECEFF0",
    marginBottom: "4px",
    boxShadow: "none",
    fontWeight: "500",
    color: "rgba(32, 32, 32, 0.33)",
    fontSize: "14px",
    fontFamily: "roboto",
    width: "100%",
    borderRadius: 4,
    display: "flex",
    placeContent: "start",
    padding: "9px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#ECEFF0",
      boxShadow: "none"
    }
  },
  buttonLabel: {
    display: "flex",
    justifyContent: "space-between"
  },
  buttonLabelImage: {
    color: "black"
  },
  endIcon: {
    marginRight: 4
  },
  input: {
    display: "none"
  }
});

const useStyles = makeStyles(style);

export default function QuantityAdjusterDialogComponent({
  handleClose,
  isOpen,
  handleSubmit,
  prizeDetail,
  handleDownloadTemplate,
  handleAddWinnerAddPrize,
  lang
}) {
  const classes = useStyles();

  return (
    <Dialog
      isOpen={isOpen}
      handleClose={handleClose}
      disableEnforceFocus={true}
      disableAutoFocus={true}
      disableEscapeKeyDown={true}
      disableBackdropClick={true}
      closeAfterTransition
      BackdropComponent={Backdrop}
    >
      <Paper elevation={0} className={classes.root}>
        <Box className={classes.header}>
          <Typography
            variant="subtitle2"
            color="primary"
            style={{ fontWeight: "bold" }}
          >
            {getLang(lang,"label.ADJUST_PRIZE_QUANTITY")}
          </Typography>
          <IconButton color="primary" onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>

        <Divider />

        <Formik
          initialValues={{
            AddQuantity: true,
            RandomWinner: true,
            Winners: [],
            adjustExcel: ""
          }}
          validationSchema={Yup.object({
            Quantity: Yup.number().moreThan(0, getLang(lang,"message.error.FORM_VALIDATE_MORETHAN", {value: 0}))
          })}
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <div style={{ height: "50vh", overflow: "auto" }}>
                <Box className={classes.switchContainer}>
                  <Box className={classes.switch}>
                    <AntSwitch
                      checked={formik.getFieldProps("AddQuantity").value}
                      onChange={(e) => {
                        formik.setFieldValue(
                          "AddQuantity",
                          !formik.getFieldProps("AddQuantity").value
                        );
                      }}
                      disabled={formik.isSubmitting}
                    />
                    <Typography variant="body2" className={classes.textActive}>
                      {getLang(lang,"label.ADD_QUANTITY")}
                    </Typography>
                  </Box>

                  <Box className={classes.switch}>
                    <AntSwitch
                      checked={!formik.getFieldProps("AddQuantity").value}
                      onChange={(e) => {
                        formik.setFieldValue(
                          "AddQuantity",
                          !formik.getFieldProps("AddQuantity").value
                        );
                      }}
                      disabled={formik.isSubmitting}
                    />
                    <Typography variant="body2" className={classes.textActive}>
                      {getLang(lang,"label.REDUCE_QUANTITY")}
                    </Typography>
                  </Box>
                </Box>

                <Box
                  className={clsx(classes.quantityContainer, {
                    [classes.reduceContainer]:
                      !formik.getFieldProps("AddQuantity").value
                  })}
                >
                  <Box display="flex" height="100%">
                    <div
                      className={clsx(classes.iconContainer, {
                        [classes.addIcon]:
                          formik.getFieldProps("AddQuantity").value,
                        [classes.reduceIcon]:
                          !formik.getFieldProps("AddQuantity").value
                      })}
                    >
                      {!formik.getFieldProps("AddQuantity").value ? (
                        "-"
                      ) : (
                        <AddQuantitySVG />
                      )}
                    </div>
                    <div
                      className={clsx({
                        [classes.addBorder]:
                          formik.getFieldProps("AddQuantity").value,
                        [classes.reduceBorder]:
                          !formik.getFieldProps("AddQuantity").value
                      })}
                    >
                      <InputTextField
                        hiddenLabel
                        required
                        variant="filled"
                        size="small"
                        fullWidth
                        margin="none"
                        InputProps={{
                          style: {
                            padding: "5px",
                            backgroundColor: "#fff",
                            height: "35px"
                          },
                        }}
                        placeholder="0-100"
                        inputProps= {{ min: 0, max: 100 }}
                        {...formik.getFieldProps("Quantity")}
                        onChange={(e) => {
                          const fakeArray = [];
                          if (e.target.value > 100) {
                            e.preventDefault();
                          }
                          else {
                            formik.setFieldValue("Quantity", e.target.value);
                          }
                          for (
                            let index = 0;
                            index < parseFloat(e.target.value);
                            index++
                          ) {
                            const info = {
                              serialNumber: "",
                              enc: ""
                            };
                            fakeArray.push(info);
                          }
                          formik.setFieldValue("Winners", fakeArray);
                        }}
                        onKeyDown={(e) => isNumberInvalid(e.key) ? e.preventDefault() : null}
                      />
                    </div>
                  </Box>
                  <Typography
                    variant="body2"
                    style={{ color: "rgba(58, 77, 84, 0.33)" }}
                  >
                    {getLang(lang,"label.ENTER_AMOUNT")}
                  </Typography>
                  <div style={{ display: "flex", placeContent: "flex-end" }}>
                    <Typography
                      variant="body2"
                      style={{ color: "rgba(58, 77, 84, 0.33)" }}
                    >
                      {getLang(lang,"label.PRIZE_BALANCE")}:&ensp;
                    </Typography>
                    <Typography variant="body2">
                      {prizeDetail.balance}
                    </Typography>
                  </div>
                </Box>
                <ErrorMessage
                  name="Quantity"
                  render={errorMsg => (
                    <span style={{ color: "#f44336", fontSize: "0.75rem", margin: "0 24px 0 24px" }}>{errorMsg}</span>
                  )}
                />
                {prizeDetail.isGiftCode &&
                  formik.getFieldProps("AddQuantity").value ? (
                  <>
                    <Box px={3} pt={2}>
                      <Typography variant="body2" style={{ paddingBottom: "8px" }}>
                        {getLang(lang,"label.UPLOAD_GIFT_CODE_EXCEL")}
                      </Typography>
                      <input
                        accept=".xlsx, .xls, .csv"
                        className={classes.input}
                        id={"adjustExcel"}
                        type="file"
                        onChange={(e) => {
                          e.persist();
                          formik.setFieldValue(
                            "adjustExcel",
                            e.currentTarget.files[0]
                          );
                        }}
                      />
                      <label htmlFor={"adjustExcel"}>
                        <Button
                          variant="contained"
                          component="span"
                          type="button"
                          endIcon={<UploadImageIcon />}
                          classes={{
                            root: classes.buttonExcel,

                            label: clsx(classes.buttonLabel, {
                              [classes.buttonLabelImage]:
                                formik.getFieldProps("adjustExcel").value
                            }),
                            endIcon: classes.endIcon,
                          }}
                        >
                          {formik.getFieldProps("adjustExcel").value
                            ? formik.getFieldProps("adjustExcel").value.name
                            : getLang(lang,"label.UPLOAD_EXCEL_FILE")}
                        </Button>
                      </label>
                    </Box>
                    <Link
                      variant="caption"
                      color="primary"
                      style={{ cursor: "pointer", padding: "0px 24px" }}
                      onClick={handleDownloadTemplate}
                    >
                      {getLang(lang,"label.DOWNLOAD_TEMPLATE")}
                    </Link>
                  </>
                ) : null}

                {!formik.getFieldProps("AddQuantity").value && (
                  <Box display="flex" px={3} pb={7} alignItems="center" style={{ paddingTop: "24px", paddingBottom: "24px" }}>
                    <ErrorSVG color="#FD646F" />
                    <Typography
                      variant="body2"
                      style={{ color: "#FD646F", paddingLeft: "8px" }}
                    >
                      {getLang(lang,"paragraph.AMOUNT_NOT_BIGGER_THAN_BALANCE")}
                    </Typography>
                  </Box>
                )}

                {formik.getFieldProps("AddQuantity").value ? (
                  <>
                    <Box className={classes.switchContainer}>
                      <Box className={classes.switch}>
                        <AntSwitch
                          checked={formik.getFieldProps("RandomWinner").value}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "RandomWinner",
                              !formik.getFieldProps("RandomWinner").value
                            );
                          }}
                          disabled={formik.isSubmitting}
                        />
                        <Typography
                          variant="body2"
                          className={classes.textActive}
                        >
                          {getLang(lang,"label.RANDOM_WINNER")}
                        </Typography>
                      </Box>

                      <Box className={classes.switch}>
                        <AntSwitch
                          checked={!formik.getFieldProps("RandomWinner").value}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "RandomWinner",
                              !formik.getFieldProps("RandomWinner").value
                            );
                          }}
                          disabled={formik.isSubmitting}
                        />
                        <Typography
                          variant="body2"
                          className={classes.textActive}
                        >
                          {getLang(lang,"label.PRESET_WINNER")}
                        </Typography>
                      </Box>
                    </Box>

                    {!formik.getFieldProps("RandomWinner").value ? (
                      <SetWinnerTable
                        quantity={formik.getFieldProps("Quantity").value}
                        winners={formik.getFieldProps("Winners").value}
                        handleChange={(index, value, enc) => {
                          let temp = [...formik.values.Winners];
                          let isNew = temp[index].serialNumber ? false : true;
                          temp[index].serialNumber = value;
                          temp[index].enc = enc;
                          formik.setFieldValue("Winners", temp);
                          handleAddWinnerAddPrize(index, value, enc, isNew)
                        }}
                        lang={lang}
                      />
                    ) : null}
                  </>
                ) : null}
              </div>

              <Box mt="auto">
                <Divider />
                <Box
                  display="flex"
                  justifyContent="space-between"
                  px={2}
                  pt={1}
                  pb={1}
                >
                  <Button
                    variant="text"
                    classes={{ root: classes.discardButton }}
                    onClick={handleClose}
                  >
                    {getLang(lang,"label.CANCEL")}
                  </Button>

                  <Button
                    variant="contained"
                    color="secondary"
                    disableElevation
                    classes={{ root: classes.publishButton }}
                    type="submit"
                  >
                    {getLang(lang,"label.SAVE")}
                  </Button>
                </Box>
              </Box>
            </form>
          )}
        </Formik>
      </Paper>
    </Dialog>
  );
}
