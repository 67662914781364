import React from "react";
import {
  makeStyles,
  Link,
  Box,
  Typography,
  InputAdornment,
  IconButton,
  Button
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { LoginLayout } from "../../components/layout/login";
import Header from "../../components/header";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import InputTextField from 'components/input/inputTextField';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { FORGOT_PASSWORD } from "../../config/trackco.route";
import SplashScreen from "modules/admin/components/screen/splash";
import { getLang } from "app/feature/constants";

const style = theme => ({
  inputRoot: {
    padddingTop: "8px !important",
    paddingBottom: "7px !important",
  },
  root: {
    width: "100%",
  },
  title: {
    fontWeight: "700",
    fontFamily: "Montserrat",
    [theme.breakpoints.up('xs')]: {
      fontSize: "18px",
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: "24px",
    },
  },
  label: {
    fontFamily: "Open Sans"
  },
  forgotPassword: {
    fontFamily: "Open Sans",
    color: "#4AA94A",
    lineHeight: "20px"
  },
  forgotPasswordContainer: {
    display: "flex",
    justifyContent: "flex-end",
    paddingBottom: "40px"
  },
  button: {
    width: "100%",
    borderRadius: "42px",
    textTransform: "capitalize",
    fontSize: "16px",
  },
  header: {
    [theme.breakpoints.up('xs')]: {
      width: "64px",
    },
    '@media(min-width: 400px)': {
      width: "75px",
    },
    '@media(min-width: 500px)': {
      width: "90px",
    },
    [theme.breakpoints.up('sm')]: {
      width: "100px",
    },
    '@media(min-width: 700px)': {
      width: "107px",
    },
    '@media(min-width: 1000px)': {
      width: "85px",
    },
    '@media(min-width: 1100px)': {
      width: "95px",
    },
    '@media(min-width: 1200px)': {
      width: "100px",
    },
    [theme.breakpoints.up('lg')]: {
      width: "107px",
    },
  },
  profile: {
    position: "relative",
    flexDirection: "column",

    [theme.breakpoints.up('xs')]: {
      top: "0.8%",
    },
    '@media(min-width: 400px)': {
      top: "2%",
    },
    '@media(min-width: 500px)': {
      top: "4%",
    },
    [theme.breakpoints.up('sm')]: {
      top: "6%",
    },
    '@media(min-width: 700px)': {
      top: "7.3%",
    },
    '@media(min-width: 800px)': {
      top: "10%",
    },
    '@media(min-width: 900px)': {
      top: "12%",
    },
    [theme.breakpoints.up('md')]: {
      top: "13%",
    },
    '@media(min-width: 1000px)': {
      top: "5%",
    },
    '@media(min-width: 1200px)': {
      top: "5%",
    },
    [theme.breakpoints.up('lg')]: {
      top: "7%",
    },
    '@media(min-width: 1400px)': {
      top: "9%",
    },
    '@media(min-width: 1500px)': {
      top: "10%",
    },
    '@media(min-width: 1600px)': {
      top: "11.5%",
    },
    '@media(min-width: 1700px)': {
      top: "12.5%",
    },
    '@media(min-width: 1800px)': {
      top: "13.5%",
    },
    '@media(min-width: 1900px)': {
      top: "15%",
    },
  },
  profilePhoto: {
    width: "100%",
    [theme.breakpoints.up('xs')]: {
      width: "50px",
    },
    '@media(min-width: 400px)': {
      width: "65px",
    },
    '@media(min-width: 500px)': {
      width: "70px",
    },
    [theme.breakpoints.up('sm')]: {
      width: "75px",
    },
    '@media(min-width: 700px)': {
      width: "80px",
    },
    '@media(min-width: 1000px)': {
      width: "65px",
    },
    '@media(min-width: 1100px)': {
      width: "75px",
    },
    '@media(min-width: 1200px)': {
      width: "80px",
    },
  },
});

const useStyle = makeStyles(style);

export default function Login({
  handleSubmit,
  handleClickShowPassword,
  handleMouseDownPassword,
  isPasswordShown,
  invitationCode,
  branchDetail,
  distributorDetail,
  languages,
  isValidateInvitationLoading,
  isInvitationCodeValidated,
  lang
}) {
  const classes = useStyle();

  return (
    (!isValidateInvitationLoading && isInvitationCodeValidated) ? (
      <LoginLayout
        header={<Header justifyContent="start" imgClass={classes.header} />}
        profileStyle={classes.profile}
        profilePhotoStyle={classes.profilePhoto}
        isLoading={isValidateInvitationLoading}
        branch={branchDetail}
        distributor={distributorDetail}
        lang={lang}
      >
        <Box style={{ marginTop: "40px", marginBottom: "40px" }}>
          <Typography variant='h5' className={classes.title}>{getLang(lang, 'paragraph.JOIN_WITH_EXISTING_ACCOUNT')}</Typography>
        </Box>
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}

          validationSchema={Yup.object({
            email: Yup.string().email(getLang(lang, 'message.error.FORM_VALIDATE_EMAIL')).required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
            password: Yup.string().required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED"))
          })}

          onSubmit={handleSubmit}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
              <Box style={{ paddingBottom: "20px" }}>
                <Typography variant='body2' className={classes.label}>{getLang(lang, 'label.EMAIL')}</Typography>
                <InputTextField
                  className={classes.root}
                  variant="filled"
                  size="small"
                  margin="none"
                  InputProps={{
                    disableUnderline: true,
                    margin: "none",
                    style: {
                      borderRadius: "4px",
                      backgroundColor: "#ffffff",
                      border: "1px solid #E6E6E6",
                      boxShadow: "0px 0px 10px 2px rgba(118, 156, 214, 0.1)",
                    },
                    classes: { input: classes.inputRoot }
                  }}
                  placeholder="Email"
                  {...formik.getFieldProps("email")}
                  error={formik.touched.email && formik.errors.email ? true : false}
                  helperText={ErrorMessage({
                    name: "email"
                  })}
                />
              </Box>
              <Box style={{ paddingBottom: "20px" }}>
                <Typography variant='body2' className={classes.label}>{getLang(lang, 'label.PASSWORD')}</Typography>
                <InputTextField
                  className={classes.root}
                  variant="filled"
                  size="small"
                  margin="none"
                  type={isPasswordShown ? "text" : "password"}
                  InputProps={{
                    disableUnderline: true,
                    margin: "none",
                    style: {
                      borderRadius: "4px",
                      backgroundColor: "#ffffff",
                      border: "1px solid #E6E6E6",
                      boxShadow: "0px 0px 10px 2px rgba(118, 156, 214, 0.1)",
                    },
                    classes: { input: classes.inputRoot },
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='toggle password visibility'
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {isPasswordShown ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  placeholder="Password"
                  {...formik.getFieldProps("password")}
                  error={formik.touched.password && formik.errors.password ? true : false}
                  helperText={ErrorMessage({
                    name: "password"
                  })}
                />

              </Box>
              <Box className={classes.forgotPasswordContainer}>
                <Link
                  component={RouterLink}
                  variant="subtitle2"
                  color='secondary'
                  to={FORGOT_PASSWORD}
                  className={classes.forgotPassword}
                >
                  {getLang(lang, 'paragraph.FORGOT_PASSWORD')}
                </Link>
              </Box>
              <Box>
                <Button variant='contained' type='submit' size='large' color='primary' className={classes.button}>
                  {getLang(lang, 'label.JOIN_NOW')}
                </Button>
              </Box>
            </form>
          )}
        </Formik>
        <Box style={{ marginTop: '20px', marginBottom: '40px' }}>
          <Typography variant='subtitle2'>
            {getLang(lang, 'paragraph.DONT_HAVE_ACCOUNT')}
            <Link
              component={RouterLink}
              variant="subtitle2"
              color='secondary'
              to={`/trackco/${invitationCode}/signup`}
              style={{ marginLeft: "8px" }}
            >
              {getLang(lang, 'paragraph.CREATE_ACCOUNT')}
            </Link>
          </Typography>
        </Box>
      </LoginLayout>
    ) : (
      <SplashScreen />
    )
  );
}

