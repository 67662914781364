import { makeStyles } from '@material-ui/core'
import React, { useRef } from 'react'
import { LuckyWheel } from 'react-luck-draw'

const useStyle = makeStyles(() => ({
    container: {
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    spinWheelBackground: {
        width: "368px",
        zIndex: 10,
        pointerEvents: "none"
    }
}))

export default function SpinWheelComponent({
    config,
    prizes
}) {
    const classes = useStyle()
    const ref = useRef()
    const prizeColor = config.prizeSegmentColor

    const prizeSegment = prizes.map((item, index) => ({
        title: item.title,
        imgs: item.image && [{
            src: item.image,
            width: config.prize.imgWidth,
            height: config.prize.imgHeight,
            top: config.prize.imgTop
        }],
        background: prizeColor[index % prizeColor.length],
        fonts: [{ text: item.title, top: config.prize.fontTop }]
    }))

    return (
        <div className={classes.container}>
            <img src={config.frameImage} className={classes.spinWheelBackground} alt="spinwheel" />
            <div style={{
                position: "absolute"
            }}>
                <LuckyWheel
                    ref={ref}
                    width={config.width}
                    height={config.height}
                    blocks={config.blocks}
                    prizes={prizeSegment}
                    buttons={config.spinButtons}
                    defaultStyle={config.defaultStyle}
                    defaultConfig={config.defaultConfig}
                    onStart={() => {
                        ref.current.play()

                        setTimeout(() => {
                            ref.current.stop(Math.random() * prizes.length >> 0)
                        }, 3000)
                    }}
                />
            </div>
        </div>
    )
}
