import React from "react";
import { TRACKCO } from "app/config/route";
import { Switch, Redirect } from 'react-router-dom';
import { compose } from '@reduxjs/toolkit';
import { withCompanyTheme } from './utils';
import {
  Login,
  LoginSuccess,
  SignUp,
  SignUpVerification,
  SignUpVerificationSuccess,
  ForgotPassword,
  NewPassword,
  NewPasswordSuccess,
  PublicNotFound
} from "./pages";
import {
  LOGIN,
  LOGIN_SUCCESS,
  SIGN_UP,
  SIGN_UP_VERIFICATION,
  VERIFY_EMAIL,
  FORGOT_PASSWORD,
  NEW_PASSWORD,
  NEW_PASSWORD_SUCCESS,
  INVITATION_CODE,
  PUBLIC_NOT_FOUND,
} from "./config/trackco.route";
import Route from "./components/route";

function TrackcoModule() {
  return (
    <Switch>
      <Route exact path={LOGIN} component={Login} />
      <Route exact path={LOGIN_SUCCESS} component={LoginSuccess} />
      <Route exact path={SIGN_UP} component={SignUp} />
      <Route exact path={SIGN_UP_VERIFICATION} component={SignUpVerification} />
      <Route exact path={VERIFY_EMAIL} component={SignUpVerificationSuccess} />
      <Route exact path={FORGOT_PASSWORD} component={ForgotPassword} />
      <Route exact path={NEW_PASSWORD} component={NewPassword} />
      <Route exact path={NEW_PASSWORD_SUCCESS} component={NewPasswordSuccess} />
      <Redirect exact from={TRACKCO} to={PUBLIC_NOT_FOUND} />
      <Redirect exact from={INVITATION_CODE} to={PUBLIC_NOT_FOUND} />
      <Route exact path={PUBLIC_NOT_FOUND} component={PublicNotFound} />
      <Redirect path="/trackco/:invitationCode/*" to={PUBLIC_NOT_FOUND} />
      <Redirect path="/trackco/*" to={PUBLIC_NOT_FOUND} />
    </Switch>
  );
}

export default compose(withCompanyTheme(TrackcoModule))

