import axios from "axios";

const getAdtInfoListing = (parameters) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `/serialnumber/api/v1/additional-info-titles`,
        {
          params: {
            length: parameters.length,
            start: parameters.start,
            search: parameters.search,
          },
        }
      )
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

export default getAdtInfoListing;
