import { setLoading, addAlert } from "modules/notification";
import { setCounterfeitReportList, setCounterfeitDetail } from "modules/counterfeit-report";
import { getCounterfeitReportList, getCounterfeitReportDetail, updateCounterfeitReportDetail } from "../action";
import { getApiLang } from "app/feature/constants";

export const getCounterfeitReportListMiddleware = ({ dispatch, getState }) => (next) => (
  action
) => {
  next(action);

  const { payload, type } = action;
  switch (type) {
    case getCounterfeitReportList.pending.type:
      // dispatch set loading to notification reducer
      dispatch(
        setLoading({ id: getCounterfeitReportList.typePrefix, state: true })
      );
      break;

    case getCounterfeitReportList.fulfilled.type:
      // dispatch remove loading from notification reducer
      next(setCounterfeitReportList(payload));
      dispatch(
        setLoading({ id: getCounterfeitReportList.typePrefix, state: false })
      );
      break;

    case getCounterfeitReportList.rejected.type:
      const lang = getState().constant.languages
      dispatch(
        setLoading({ id: getCounterfeitReportList.typePrefix, state: false })
      );
      dispatch(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
      break;

    default:
      break;
  }
};

export const getCounterfeitReportDetailMiddleware = ({ dispatch, getState }) => (next) => (
  action
) => {
  next(action);

  const { type, payload } = action;
  switch (type) {
    case getCounterfeitReportDetail.pending.type:
      // dispatch set loading to notification reducer
      dispatch(
        setLoading({ id: getCounterfeitReportDetail.typePrefix, state: true })
      );
      break;

    case getCounterfeitReportDetail.fulfilled.type:
      // dispatch remove loading from notification reducer

      next(setCounterfeitDetail(payload));
      dispatch(
        setLoading({ id: getCounterfeitReportDetail.typePrefix, state: false })
      );
      break;

    case getCounterfeitReportDetail.rejected.type:
      const lang = getState().constant.languages
      dispatch(
        setLoading({ id: getCounterfeitReportDetail.typePrefix, state: false })
      );
      dispatch(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
      break;

    default:
      break;
  }
};

export const updateCounterfeitReportDetailMiddleware = ({ dispatch, getState }) => (next) => async (
  action
) => {
  next(action);

  const { payload, type } = action;
  const lang = getState().constant.languages

  switch (type) {
    case updateCounterfeitReportDetail.pending.type:
      // dispatch set loading to notification reducer
      dispatch(setLoading({ id: updateCounterfeitReportDetail.typePrefix, state: true }));
      break;

    case updateCounterfeitReportDetail.fulfilled.type:
      // dispatch remove loading from notification reducer
      dispatch(setLoading({ id: updateCounterfeitReportDetail.typePrefix, state: false }));
      dispatch(addAlert({ severity: "success", message: getApiLang(lang, "success.SUCCESS_UPDATE_COUNTERFEIT_REPORT") }));
      break;

    case updateCounterfeitReportDetail.rejected.type:
      dispatch(setLoading({ id: updateCounterfeitReportDetail.typePrefix, state: false }));
      dispatch(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
      break;

    default:
      break;
  }
};

export default [
  getCounterfeitReportListMiddleware,
  getCounterfeitReportDetailMiddleware,
  updateCounterfeitReportDetailMiddleware
];
