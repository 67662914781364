import axios from "axios"

export default function addBonanzaPoint({
    modules,
    point,
    status,
    parentId,
    startAt,
    endAt
}) {
    return new Promise((resolve, reject) => {
        axios.post("/campaign/api/admin/campaigns-setting",
            {
                productId: modules,
                amount: point,
                status: status,
                parentId,
                startAt,
                endAt
            })
            .then(response => resolve(response.data))
            .catch(error => reject(error.response.data))
    })
}