import { selectLoading } from "modules/notification";
import { getCampaignProductData } from "modules/reporting/redux";
import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CampaignProductPanel from './campaignProductPanel.component';
import { unwrapResult } from "@reduxjs/toolkit";
import { CAMPAIGN_TYPE_SURVEY, CAMPAIGN_TYPE_WARRANTY } from "modules/reporting/constants";

export default function CampaignProductPanelContainer({
    currentFilter,
    url,
    pageType,
    translate
}){
    const dispatch = useDispatch();
    const isFetchingCampaignProductData = useSelector(state => selectLoading(state, getCampaignProductData.typePrefix));
    const campaignProductData = useSelector(state => state.reporting.campaign.campaignProductData);

    // table configuration & function
    const [tableFilter, setTableFilter] = useState({
        page: 0,
        rowsPerPage: 10,
        search: "",
        searchBy: "name",
        orderBy: "engagement",
        orderDirection: "desc",
    })

    const getCampaignTitle = (campaignType) => {
        if(campaignType === CAMPAIGN_TYPE_SURVEY)
            return translate("label.SURVEY_RECEIVED");
        
        if(campaignType === CAMPAIGN_TYPE_WARRANTY)
            return translate("label.WARRANTY_REGISTRATION");
        
        return translate("label.PARTICIPANTS");
    }

    const columns = [
        {
            title: translate("label.NUM"),
            field: null,
            render: (row) => {
                return (
                    <>{row.title}</>
                )
            },
            align: "left",
            size: "medium"
        },
        {
            title: translate("label.PRODUCTS"),
            field: null,
            render: (row) => {
                return (
                    <>{row.title}</>
                )
            },
            align: "left",
            size: "medium"
        },
        {
            title: getCampaignTitle(currentFilter.campaignType),
            field: "engagement",
            render: (row) => {
                return (
                    <>{row.title}</>
                )
            },
            size: "small",
            align: "left"
        },
        {
            title: translate("label.ENGAGEMENT_WEIGHTAGE"),
            field: null,
            render: (row) => {
                return (
                    <>{row.title}</>
                )
            },
            size: "small",
            align: "left"
        }
    ];

    useEffect(() => {
        dispatch(getCampaignProductData({
            length: tableFilter.rowsPerPage,
            start: tableFilter.page * tableFilter.rowsPerPage,
            orderBy: tableFilter.orderBy,
            orderDirection: tableFilter.orderDirection,
            search: tableFilter.search,
            searchBy: tableFilter.searchBy,
            countryCode: currentFilter.countryCode,
            city_uuid: currentFilter.cityUuid,
            branch_uuid: currentFilter.branchUuid,
            brand_uuid: currentFilter.brandUuid,
            category_uuid: currentFilter.categoryUuid,
            model_uuid: currentFilter.modelUuid,
            product_uuid: currentFilter.productUuid,
            period: currentFilter.period,
            start_date: moment(currentFilter.dateRange[0]).format('YYYY-MM-DD'),
            end_date: moment(currentFilter.dateRange[1]).format('YYYY-MM-DD'),
            url: url,

            campaign_uuid: currentFilter.campaignUuid,
            campaign_type: currentFilter.campaignType,
        })).then(unwrapResult);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, currentFilter, tableFilter]);

    const handleRequestSort = (event, property) => {
        const isAsc = tableFilter.orderBy === property && tableFilter.orderDirection === 'asc';
        setTableFilter((prev) => ({
            ...prev,
            orderDirection: isAsc ? 'desc' : 'asc',
            orderBy: property,
        }))
    }

    const handleChangePage = (e, newPage) => {
        setTableFilter((prev) => ({
          ...prev,
          page: newPage,
        }))
    }

    const handleChangeRowPerPage = (event) => {
        setTableFilter((prev) => ({
          ...prev,
          page: 0,
          rowsPerPage: event.target.value
        }))
    }

    return (
        <CampaignProductPanel 
            status={{
                isFetchingCampaignProductData,
            }}

            data={{
                campaignProductData,
            }}

            currentFilter={currentFilter}

            pageType={pageType}

            columns={columns}

            sort={{
                valueToOrderBy: tableFilter.orderBy,
                orderDirection: tableFilter.orderDirection,
                handleRequestSort: handleRequestSort,
            }}

            pagination={{
                handleChangePage: handleChangePage,
                handleChangeRowPerPage: handleChangeRowPerPage,
                page: tableFilter.page,
                rowsPerPage: tableFilter.rowsPerPage,
                rowsPerPageOptions: [5, 10, 25],
            }}

            translate={translate}
        
        />
    )
}