import React from "react";
import {
  Box,
  makeStyles,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Paper,
  TableSortLabel,
  Link,
} from "@material-ui/core";
import {
  StyledTableCell,
  StyledTableRow,
  StickyRightTableCell,
} from "components/table";
import { ActionButton } from "components/button";
import { TablePaginationActions } from "components/tablePagination";
import RefreshTable from "components/table/refreshTable";
import { KeyboardArrowDown } from "@material-ui/icons";
import DefaultImg from "assets/img/img-default.png";
import { getLang } from "app/feature/constants";
import { SkeletonTableRow } from "components/skeleton/skeleton.component";

const useStyle = makeStyles((theme) => ({
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    borderCollapse: "separate",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
    },
    "& th,td": {
      fontSize: ".75rem",
      borderRight: "1px solid rgba(58, 77, 84, 0.1)",
      padding: theme.spacing(0.75, 1, 0.75, 1),
    },
    "& th": {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightBold,
    },
    "& th:last-child, td:last-child": {
      width: 37,
      borderRight: "0px",
    },
    "& th th": {
      borderBottom: "none !important",
    },
    "& td td": {
      borderBottom: "none !important",
    },
  },
  emptyContainer: {
    backgroundColor: theme.palette.background.paper,
    textAlign: "center",
  },
  productPhoto: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #0000001a",
    width: "35px",
    minWidth: "35px",
    height: "35px",
    borderRadius: "4px",
    "& img": {
      height: "100%",
      width: "100%",
    },
  },
  ellipsisText: {
    maxWidth: "18em",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: "0.75rem",
  },
  attributeText: {
    color: "#b2b2b2",
    fontSize: 12,
    whiteSpace: "break-spaces",
  },
}));

export default function StockTakeProductListTableComponent({
  rows,
  isFetching,
  isError,
  handleReload,
  handleChangePage,
  handleChangeRowPerPage,
  totalFiltered,
  paginationOptions,
  tableFilter,
  changeOrder,
  lang,
  handleViewDetail,
  isCompleted,
}) {
  const classes = useStyle();

  return (
    <>
      {!isFetching && isError && <RefreshTable handleReload={handleReload} />}
      {isFetching || rows.length > 0 ? (
        <Paper id="test" style={{ overflowX: "auto", width: "100%" }}>
          <TableContainer>
            <Table
              className={classes.table}
              aria-label="stocktake prd table"
              size="small"
              stickyHeader
            >
              <TableHead style={{ height: "45px" }}>
                <TableRow>
                  <StyledTableCell>
                    <TableSortLabel
                      active={tableFilter.orderBy === "name"}
                      direction={tableFilter.order}
                      onClick={() => {
                        const differentTab = tableFilter.orderBy !== "name";
                        const order = differentTab
                          ? tableFilter.order
                          : tableFilter.order === "asc"
                          ? "desc"
                          : "asc";
                        changeOrder("name", order);
                      }}
                      IconComponent={KeyboardArrowDown}
                    >
                      {getLang(lang, "label.PRODUCT")}
                    </TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell>
                    <TableSortLabel
                      active={tableFilter.orderBy === "total_quantity"}
                      direction={tableFilter.order}
                      onClick={() => {
                        const differentTab =
                          tableFilter.orderBy !== "total_quantity";
                        const order = differentTab
                          ? tableFilter.order
                          : tableFilter.order === "asc"
                          ? "desc"
                          : "asc";
                        changeOrder("total_quantity", order);
                      }}
                      IconComponent={KeyboardArrowDown}
                    >
                      {getLang(lang, "label.INVENTORY_QTY")}
                    </TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell>
                    <TableSortLabel
                      active={tableFilter.orderBy === "matched"}
                      direction={tableFilter.order}
                      onClick={() => {
                        const differentTab = tableFilter.orderBy !== "matched";
                        const order = differentTab
                          ? tableFilter.order
                          : tableFilter.order === "asc"
                          ? "desc"
                          : "asc";
                        changeOrder("matched", order);
                      }}
                      IconComponent={KeyboardArrowDown}
                    >
                      {getLang(lang, "label.MATCHED")}
                    </TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell>
                    <TableSortLabel
                      active={tableFilter.orderBy === "extra"}
                      direction={tableFilter.order}
                      onClick={() => {
                        const differentTab = tableFilter.orderBy !== "extra";
                        const order = differentTab
                          ? tableFilter.order
                          : tableFilter.order === "asc"
                          ? "desc"
                          : "asc";
                        changeOrder("extra", order);
                      }}
                      IconComponent={KeyboardArrowDown}
                    >
                      {getLang(lang, "label.EXTRA")}
                    </TableSortLabel>
                  </StyledTableCell>
                  {isCompleted && (
                    <StyledTableCell>
                      <TableSortLabel
                        active={tableFilter.orderBy === "missing"}
                        direction={tableFilter.order}
                        onClick={() => {
                          const differentTab =
                            tableFilter.orderBy !== "missing";
                          const order = differentTab
                            ? tableFilter.order
                            : tableFilter.order === "asc"
                            ? "desc"
                            : "asc";
                          changeOrder("missing", order);
                        }}
                        IconComponent={KeyboardArrowDown}
                      >
                        {getLang(lang, "label.MISSING")}
                      </TableSortLabel>
                    </StyledTableCell>
                  )}
                  <StickyRightTableCell
                    style={{
                      width: "67px",
                      textAlign: "center",
                    }}
                  >
                    {getLang(lang, "label.ACTION")}
                  </StickyRightTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isFetching
                  ? [...new Array(5)].map((v, index) => (
                      <SkeletonTableRow
                        key={index}
                        columnCount={isCompleted ? 6 : 5}
                      />
                    ))
                  : rows.map((row, index) => {
                      const actionItems = [];

                      actionItems.push({
                        label: getLang(lang, "label.VIEW"),
                        action: () => {
                          handleViewDetail(row);
                        },
                      });

                      return (
                        <StyledTableRow
                          key={index}
                          hover
                          role="checkbox"
                          tabIndex={-1}
                        >
                          <StyledTableCell>
                            <Box display="flex" alignItems="center">
                              <Box className={classes.productPhoto} mr={2}>
                                {!row?.picture ? (
                                  <img src={DefaultImg} alt="emptyProduct" />
                                ) : (
                                  <img src={row.picture} alt={row.name} />
                                )}
                              </Box>
                              <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                alignItems="flex-start"
                              >
                                <Link
                                  color="secondary"
                                  component="button"
                                  onClick={() => {
                                    handleViewDetail(row);
                                  }}
                                >
                                  <Typography className={classes.ellipsisText}>
                                    {row.name}
                                  </Typography>
                                </Link>
                                {!!row.attributes &&
                                  !!row.attributes.length && (
                                    <Typography
                                      className={classes.attributeText}
                                      variant="body2"
                                    >
                                      {row.attributes.join(", ")}
                                    </Typography>
                                  )}
                              </Box>
                            </Box>
                          </StyledTableCell>
                          <StyledTableCell>
                            {row.total_quantity.toLocaleString("en-US")}
                          </StyledTableCell>
                          <StyledTableCell>
                            {row.matched?.toLocaleString("en-US")}
                          </StyledTableCell>
                          <StyledTableCell>
                            <Typography
                              variant="caption"
                              style={{ color: "#FDB022" }}
                            >
                              {row.extra?.toLocaleString("en-US")}
                            </Typography>
                          </StyledTableCell>
                          {isCompleted && (
                            <StyledTableCell>
                              <Typography
                                variant="caption"
                                style={{ color: "#F97066" }}
                              >
                                {row.missing?.toLocaleString("en-US")}
                              </Typography>
                            </StyledTableCell>
                          )}
                          <StickyRightTableCell
                            style={{
                              width: "67px",
                              textAlign: "center",
                            }}
                          >
                            <ActionButton
                              actionItems={actionItems}
                              popperStyle={{ zIndex: 999 }}
                            />
                          </StickyRightTableCell>
                        </StyledTableRow>
                      );
                    })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            style={{
              backgroundColor: "#fff",
              boxShadow: "0px -1px 0px rgba(58, 77, 84, 0.15)",
            }}
            ActionsComponent={TablePaginationActions}
            rowsPerPageOptions={paginationOptions}
            component="div"
            count={totalFiltered}
            rowsPerPage={tableFilter.rowsPerPage}
            page={tableFilter.page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowPerPage}
            labelRowsPerPage={getLang(lang, "label.ROWS_PER_PAGE")}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} ${getLang(lang, "label.OF")} ${count}`
            }
          />
        </Paper>
      ) : null}
      {!isFetching && !isError && rows.length === 0 && (
        <Box>
          <Table
            className={classes.table}
            aria-label="stocktake table"
            size="small"
            stickyHeader
          >
            <TableHead style={{ height: "45px" }}>
              <TableRow>
                <StyledTableCell>
                  {getLang(lang, "label.PRODUCT")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.INVENTORY_QTY")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.MATCHED")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.EXTRA")}
                </StyledTableCell>
                {isCompleted && (
                  <StyledTableCell>
                    {getLang(lang, "label.MISSING")}
                  </StyledTableCell>
                )}
                <StyledTableCell>
                  {getLang(lang, "label.ACTION")}
                </StyledTableCell>
              </TableRow>
            </TableHead>
          </Table>
          <Box py={9} className={classes.emptyContainer}>
            <Typography
              variant="body1"
              style={{ paddingBottom: "16px", fontWeight: "bold" }}
            >
              {getLang(lang, "label.NO_PRODUCT")}
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
}
