import React, { useEffect } from "react";
import ExportAssignExcelDialogComponent from "./exportAssignExcelDialog.component";
import { useDispatch, useSelector } from "react-redux";
import { ASSIGN_BATCH_STATUS_FAIL } from "modules/serial-number/constants";
import { getAssignFailJob } from "modules/serial-number/redux/action/assignBatch.action";
import { selectLoading } from "modules/notification";
import { exportAssignSN, getExportFileDropdown } from "modules/serial-number/redux";
import { unwrapResult } from "@reduxjs/toolkit";

function ExportAssignExcelDialogContainer({
  isOpen,
  handleClose,
  batch,
  exportFile,
}) {
  const lang = useSelector((state) => state.constant.languages);
  const failJobList = useSelector(
    (state) => state.assignBatch.selectedBatchFailJob
  );
  const isFetchFailJobError = useSelector(
    (state) => state.assignBatch.isFetchFailJobError
  );
  const fileDropdown = useSelector(
    (state) => state.assignBatch.exportFile
  );
  const isFetching = useSelector((state) =>
    selectLoading(state, getAssignFailJob.typePrefix)
  );
  const token = useSelector((state) => state.session.accessToken);
  const dispatch = useDispatch();

  const formats = [
    "xlsx",
    "csv",
    "txt"
  ];

  const fields = [
    {
      name: "All",
      value: "all",
    },
    {
      name: "Index",
      value: "index",
    },
    {
      name: "Product Name",
      value: "product",
    },
    {
      name: "Serial Number",
      value: "serial_no",
    },
    {
      name: "Sequence Number",
      value: "seq_no",
    },
    {
      name: "QR URL",
      value: "qr",
    },
    {
      name: "PIN Number",
      value: "pin",
    },
  ];

  useEffect(() => {
    dispatch(getExportFileDropdown(batch?.batchNumber));
  }, [dispatch, batch]);

  useEffect(() => {
    if (batch?.status === ASSIGN_BATCH_STATUS_FAIL) {
      getFailJob();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [batch]);



  const getFailJob = () => {
    dispatch(getAssignFailJob(batch.uuid));
  };
  const handleExport = (values) => {
    if (fileDropdown?.total > 1000000 && !values.file.length)
      return;
    if (fileDropdown?.total <= 1000000)
      var file = fileDropdown?.data[0];
    else file = values.file;
    const info = {
      token: token,
      format: values.format,
      fields: values.fields,
      batchNumber: batch.batchNumber,
      data: file
    };
    dispatch(exportAssignSN(info))
      .then(unwrapResult)
      .finally(() => {
        
      });
  };

  return (
    <ExportAssignExcelDialogComponent
      isOpen={isOpen}
      handleClose={handleClose}
      handleSubmit={handleExport}
      lang={lang}
      formats={formats}
      fields={fields}
      batch={batch}
      failJobList={failJobList}
      isFetchFailJobError={isFetchFailJobError}
      isFetching={isFetching}
      fileDropdown={fileDropdown}
    />
  );
}

export default ExportAssignExcelDialogContainer;
