import React from "react";
import {
  Box,
  Button,
  makeStyles,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Paper,
  Link,
  TableSortLabel,
} from "@material-ui/core";
import {
  StyledTableCell,
  StyledTableRow,
  StickyRightTableCell,
  StickyLeftTableCell,
} from "components/table";
import { SkeletonTableRowStickyLeftRight } from "components/skeleton/skeleton.component";
import { ActionButton } from "components/button";
import { TablePaginationActions } from "components/tablePagination";
import RefreshTable from "components/table/refreshTable";
import DefaultImg from "assets/img/img-default.png";
import { ROOT } from "modules/stockflow/config/stockflow.route";
import { KeyboardArrowDown } from "@material-ui/icons";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles((theme) => ({
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    borderCollapse: "separate",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
    },
    "& th,td": {
      fontSize: ".75rem",
      borderRight: "1px solid rgba(58, 77, 84, 0.1)",
      padding: theme.spacing(1, 2, 1, 2),
    },
    "& th": {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightBold,
    },
    "& th:last-child, td:last-child": {
      width: 37,
      borderRight: "0px",
    },
    "& th th": {
      borderBottom: "none !important",
    },
    "& td td": {
      borderBottom: "none !important",
    },
  },
  tableContainer: {
    maxHeight: 500,
    overflowY: "auto",
  },
  emptyContainer: {
    backgroundColor: theme.palette.background.paper,
    textAlign: "center",
  },
  button: {
    display: "flex",
    alignItems: "center",
    padding: 0,
    minWidth: 0,
    marginLeft: theme.spacing(1),
  },
  productPhoto: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #E4E7EC",
    width: "30px",
    height: "30px",
    marginRight: "10px",
    "& img": {
      height: "30px",
      width: "30px",
    },
  },
  actionContainer: {
    justifyContent: "center",
  },
  paperRoot: {
    width: "300px",
  },
  selectAll: {
    "&.Mui-checked": {
      color: "#FDB022",
    },
  },
  selectOne: {
    "&.Mui-checked": {
      color: "#6AAF68",
    },
  },
  attributeText: {
    color: "#b2b2b2",
    fontSize: 12,
    whiteSpace: "break-spaces",
  },
}));

export default function SummaryByBranchTableComponent({
  hasStockflowInventoryViewAccessRight,
  rows,
  isFetching,
  isSelectAll,
  selectedIds,
  isError,
  handleReload,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowPerPage,
  isSearchResult,
  totalFiltered,
  handleSelectAvailable,
  handleSelectAll,
  handleDeselectAll,
  handleSelectProduct,
  token,
  paginationOptions,
  lang,
  isDealerPage,
  dealerId,
  handleViewDetail,
  tableFilter,
  changeSequenceOrder,
}) {
  const classes = useStyle();

  return (
    <>
      {!isFetching && isError && <RefreshTable handleReload={handleReload} />}
      {isFetching || rows.length > 0 ? (
        <Paper id="test" style={{ overflowX: "auto", width: "100%" }}>
          <TableContainer>
            <Table
              className={classes.table}
              aria-label="product table"
              size="small"
              stickyHeader
            >
              <TableHead style={{ height: "45px" }}>
                <TableRow>
                  {!isDealerPage && (
                    <StyledTableCell>
                      {getLang(lang, "label.NUM")}
                    </StyledTableCell>
                  )}
                  <StyledTableCell style={{ minWidth: "20rem" }}>
                    {getLang(lang, "label.PRODUCT_NAME")}
                  </StyledTableCell>
                  <StyledTableCell>
                    <TableSortLabel
                      active={true}
                      direction={tableFilter.order}
                      onClick={() => {
                        if (tableFilter.order === "asc") {
                          changeSequenceOrder("desc");
                        } else {
                          changeSequenceOrder("asc");
                        }
                      }}
                      IconComponent={KeyboardArrowDown}
                    >
                      {getLang(lang, "label.IN_STOCK_COUNT")}
                    </TableSortLabel>
                  </StyledTableCell>
                  <StickyRightTableCell>
                    <StyledTableCell
                      style={{ width: "67px", textAlign: "center" }}
                    >
                      {getLang(lang, "label.ACTION")}
                    </StyledTableCell>
                  </StickyRightTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isFetching
                  ? [...new Array(5)].map((v, index) => (
                      <SkeletonTableRowStickyLeftRight
                        key={index}
                        columnCount={isDealerPage ? 3 : 4}
                      />
                    ))
                  : rows.map((row, index) => {
                      const actionItems = [];

                      actionItems.push({
                        label: getLang(lang, "label.VIEW_DETAIL"),
                        action: () => {
                          let url = `${ROOT}/inventory/product/${row.uuid}`;

                          if (isDealerPage && dealerId) {
                            url += `?dealer=${dealerId}`;
                          }
                          handleViewDetail(url);
                        },
                      });
                      // actionItems.push({
                      //   label: "Export XLS",
                      //   action: () => {
                      //     // TODO: add export function
                      //   },
                      // })

                      return (
                        <StyledTableRow
                          key={row.id}
                          hover
                          role="checkbox"
                          tabIndex={-1}
                        >
                          {!isDealerPage && (
                            <StickyLeftTableCell>
                              <StyledTableCell
                                component="th"
                                scope="row"
                                style={{ borderBottom: "0px" }}
                              >
                                {page * rowsPerPage + index + 1}
                              </StyledTableCell>
                            </StickyLeftTableCell>
                          )}
                          <StyledTableCell>
                            <Box display="flex" alignItems="center">
                              <Box className={classes.productPhoto}>
                                {row.picture === "" ? (
                                  <img src={DefaultImg} alt="emptyProduct" />
                                ) : (
                                  <img src={row.picture} alt={row.name} />
                                )}
                              </Box>
                              <Box>
                                {hasStockflowInventoryViewAccessRight ? (
                                  <Link
                                    href={
                                      `${ROOT}/inventory/product/${row.uuid}` +
                                      (isDealerPage && dealerId ? `?dealer=${dealerId}` : '')
                                    }
                                    color="secondary"
                                    variant="caption"
                                    onClick={(e) => {
                                      let url = `${ROOT}/inventory/product/${row.uuid}`;
                                      if (isDealerPage && dealerId) {
                                        url += `?dealer=${dealerId}`;
                                      }
                                      handleViewDetail(url);
                                      e.preventDefault();
                                      return false;
                                    }}
                                  >
                                    {row.name}
                                  </Link>
                                ) : (
                                  <Typography
                                    vriant="body2"
                                    style={{ fontSize: 12 }}
                                    align="start"
                                  >
                                    {row.name}
                                  </Typography>
                                )}
                                {!!row.attributes &&
                                  !!row.attributes.length && (
                                    <Typography
                                      className={classes.attributeText}
                                      variant="body2"
                                    >
                                      {row.attributes.join(", ")}
                                    </Typography>
                                  )}
                              </Box>
                            </Box>
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            <span>{row.currentStock ? row.currentStock.toLocaleString("en-US") : "-"}</span>
                          </StyledTableCell>
                          <StickyRightTableCell>
                            <StyledTableCell style={{ width: "67px" }}>
                              <ActionButton
                                actionItems={actionItems}
                                popperStyle={{ zIndex: 999 }}
                              />
                            </StyledTableCell>
                          </StickyRightTableCell>
                        </StyledTableRow>
                      );
                    })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            style={{
              backgroundColor: "#fff",
              boxShadow: "0px -1px 0px rgba(58, 77, 84, 0.15)",
            }}
            ActionsComponent={TablePaginationActions}
            rowsPerPageOptions={paginationOptions}
            component="div"
            count={totalFiltered}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowPerPage}
            labelRowsPerPage={getLang(lang, "label.ROWS_PER_PAGE")}
            labelDisplayedRows={({ from, to, count }) =>
              `${from.toLocaleString("en-US")}-${to.toLocaleString("en-US")} ${getLang(lang, "label.OF")} ${count.toLocaleString("en-US")}`
            }
          />
        </Paper>
      ) : null}
      {!isFetching && !isError && rows.length === 0 && (
        <Box>
          <Table
            className={classes.table}
            aria-label="product table"
            size="small"
            stickyHeader
          >
            <TableHead style={{ height: "45px" }}>
              <TableRow>
                {!isDealerPage && (
                  <StyledTableCell>
                    {getLang(lang, "label.NUM")}
                  </StyledTableCell>
                )}
                <StyledTableCell style={{ minWidth: "20rem" }}>
                  {getLang(lang, "label.PRODUCT_NAME")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.IN_STOCK_COUNT")}
                </StyledTableCell>
                <StickyRightTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.ACTION")}
                  </StyledTableCell>
                </StickyRightTableCell>
              </TableRow>
            </TableHead>
          </Table>
          <Box py={9} className={classes.emptyContainer}>
            <Typography
              variant="body1"
              style={{ paddingBottom: "16px", fontWeight: "bold" }}
            >
              {isSearchResult || isDealerPage
                ? getLang(lang, "paragraph.NO_PRODUCT_EXIST")
                : getLang(lang, "paragraph.INVENTORY_EMPTY")}
            </Typography>
            {!isSearchResult && !isDealerPage && (
              <>
                <Typography variant="body2" style={{ paddingBottom: "16px" }}>
                  {getLang(lang, "paragraph.INVENTORY_INSTRUCTION")}
                </Typography>
                <Button
                  onClick={() => {
                    // TODO: add link
                  }}
                  variant="contained"
                  disableElevation
                  color="primary"
                >
                  {getLang(lang, "label.CREATE_QR_CODE")}
                </Button>
              </>
            )}
          </Box>
        </Box>
      )}
    </>
  );
}
