import { createAsyncThunk } from '@reduxjs/toolkit';
import productApi from 'app/api/product';

export const PRODUCTS = "products";

export const addAttribute = createAsyncThunk(
  `${PRODUCTS}/addAttribute`,
  async (payload, { rejectWithValue }) => {
    return productApi.addAttribute({
      name: payload.name,
      options: payload.options,
    })
      .then(response => response.data)
      .catch(error => rejectWithValue(error))
  }
)

export const getAllAttributeListing = createAsyncThunk(
  `${PRODUCTS}/getAllAttributeListing`,
  async (payload, {rejectWithValue }) => {
    return productApi.getAttributeListing({
      length: payload.length,
      start: payload.start,
      column: payload.column,
      order: payload.order,
      search: payload.search,
      status: payload.status
    })
      .then(response => response.data)
      .catch(error => rejectWithValue(error))
  }
)

export const updateAttributeStatus = createAsyncThunk(
  `${PRODUCTS}/updateAttributeStatus`,
  async (payload, {rejectWithValue }) => {
    return productApi.updateAttributeStatus({
      id: payload.id,
      status: payload.status,
    })
      .then(response => response.data)
      .catch(error => rejectWithValue(error))
  }
)

export const updateAttributesStatus = createAsyncThunk(
  `${PRODUCTS}/updateAttributesStatus`,
  async (payload, {rejectWithValue }) => {
    return productApi.updateAttributesStatus({
      filter: payload.filter,
      update: payload.update
    })
      .then(response => response.data)
      .catch(error => rejectWithValue(error))
  }
)

export const editAttribute = createAsyncThunk(
  `${PRODUCTS}/editAttribute`,
  async (payload, {rejectWithValue }) => {
    return productApi.editAttribute({
      id: payload.id,
      name: payload.name,
      status: payload.status,
      options: payload.options
    })
      .then(response => response.data)
      .catch(error => rejectWithValue(error))
  }
)

export const deleteAttribute = createAsyncThunk(
  `${PRODUCTS}/deleteAttribute`,
  async (payload, {rejectWithValue }) => {
    return productApi.deleteAttribute({
      id: payload.id,
    })
      .then(response => response.data)
      .catch(error => rejectWithValue(error))
  }
)

export const getAttributeDropdown = createAsyncThunk(
  `${PRODUCTS}/getAttributeDropdown`,
  async (payload, {rejectWithValue }) => {
    return productApi.getAttributeDropdown({
      search: payload.search
    })
      .then(response => response.data)
      .catch(error => rejectWithValue(error))
  }
)