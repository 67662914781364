import React from "react"
import GiftRedemptionTableComponent from "./giftRedemptionTable.component"

export default function GiftRedemptionTableContainer({
  rows,
  isFetching,
  isError,
  page,
  tableFilter,
  language,
  rowsPerPage,
  totalFiltered,
  handleChangePage,
  handleChangeRowsPerPage,
  handleReload,
  paginationOptions,
  changeOrder,
  lang
}) {

  return (
    <GiftRedemptionTableComponent
      rows={rows}
      isFetching={isFetching}
      isError={isError}
      language={language}
      handleReload={handleReload}
      page={page}
      rowsPerPage={rowsPerPage}
      totalFiltered={totalFiltered}
      paginationOptions={paginationOptions}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      tableFilter={tableFilter}
      changeOrder={changeOrder}
      lang={lang}
    />
  )
}
