import React, { useEffect, useState } from "react";
import MainPageComponent from "./main.page";
import { useDispatch } from "react-redux";
import { openCreationDialog } from "modules/role-access-right/redux";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { ROLE_VIEW, ROLE_ADD } from "lib/constants/accessRights"
import LoadingComponent from "components/loading";
import { getLang } from "app/feature/constants";

function MainPageContainer() {
  const history = useHistory()
  const [activeIndex, setActiveIndex] = useState(0);
  const dispatch = useDispatch();

  const hasRoleViewAccessRight = useSelector(state => state.profile.accessRights.includes(ROLE_VIEW));
  const hasRoleAddAccessRight = useSelector(state => state.profile.accessRights.includes(ROLE_ADD));
  const lang = useSelector(state => state.constant.languages);

  useEffect(() => {
    if(!hasRoleViewAccessRight){
      history.push('/admin/dashboard')
    }
  }, [history, hasRoleViewAccessRight])

  if (!hasRoleViewAccessRight) return <LoadingComponent />;

  const tabLabels = [getLang(lang, "label.ROLE_ACCESS_CONTROL")];

  const handleChange = (event, index) => {
    setActiveIndex(index);
  };

  const handleCreateRole = () => {
    if(hasRoleAddAccessRight){
      dispatch(openCreationDialog());
    }
  };

  return (
    <MainPageComponent
      hasRoleAddAccessRight={hasRoleAddAccessRight}
      handleChange={handleChange}
      activeIndex={activeIndex}
      tabLabels={tabLabels}
      handleCreateRole={handleCreateRole}
      lang={lang}
    />
  );
}

export default MainPageContainer;
