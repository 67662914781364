import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { selectLoading } from 'modules/notification'
import LoadingComponent from "components/loading";
import { 
  getInventoryPackageDetail, 
} from "modules/stockflow/redux";
import { STOCKFLOW_INVENTORY_VIEW, STOCKFLOW_DEALER_VIEW } from "lib/constants/accessRights";
import { ROOT } from "modules/dashboard";
import PackageDetailComponent from "./packageDetails.component";
import { getLang } from "app/feature/constants";

function PackageDetailsContainer() {
  const history = useHistory()
  const dispatch = useDispatch()
  const match = useRouteMatch()
  
  const id = match.params.uuid;
  const hasStockflowViewAccessRight = useSelector(state => state.profile.accessRights.includes(STOCKFLOW_INVENTORY_VIEW));
  const hasStockflowDealerViewAccessRight = useSelector(state => state.profile.accessRights.includes(STOCKFLOW_DEALER_VIEW));
  const lang = useSelector(state => state.constant.languages)
  const language = useSelector(state => state.profile.language)
  const token = useSelector(state => state.session.accessToken)
  const packageDetail = useSelector(state => state.stockflowInventory.package);
  const isLoading = useSelector(state => selectLoading(state, getInventoryPackageDetail.typePrefix))

  useEffect(() => {
    if (!hasStockflowViewAccessRight) {
      history.push('/admin/dashboard')
    }
  }, [history, hasStockflowViewAccessRight])

  useEffect(() => {
    if (id) {
      dispatch(getInventoryPackageDetail(id))
    }
    else {
      history.push(ROOT)
    }
  }, [history, id, dispatch])

  if (!hasStockflowViewAccessRight) return <LoadingComponent />;

  let tabLabels = []
  if (hasStockflowViewAccessRight) {
    tabLabels.push(getLang(lang, "label.INVENTORY"))
  }

  return (
    <PackageDetailComponent
      hasStockflowDealerViewAccessRight={hasStockflowDealerViewAccessRight}
      packageDetail={packageDetail}
      isLoading={isLoading}
      language={language}
      token={token}
      lang={lang}
    />
  );
}

export default PackageDetailsContainer;
