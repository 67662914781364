import PropTypes from "prop-types";
import axios from "axios";

const updateCompanyProfilePic = async ({ compID, profilePicture }) => {
  const response = await axios({
    url: "/account/Company/UpdateCompanyProfilePic",
    method: "POST",
    data: { compID, profilePicture }
  });

  return response;
};

updateCompanyProfilePic.propTypes = {
  compID: PropTypes.number.isRequired,
  profilePicture: PropTypes.string.isRequired
};

export default updateCompanyProfilePic;
