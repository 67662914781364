import React from "react";
import { Box, Button, makeStyles } from "@material-ui/core";
import { getLang } from "app/feature/constants";
import { Formik } from "formik";
import Input from "components/input/inputTextField";
import StockTakeProductListTable from "../../table/stockTakeProductListTable";

const useStyles = makeStyles((theme) => ({
  productList: {
    border: "1px solid #D0D5DD",
    "& > div:not(:last-child)": {
      borderBottom: "1px solid #D0D5DD",
    },
  },
  productPhoto: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #0000001a",
    width: "45px",
    minWidth: "45px",
    height: "45px",
    borderRadius: "4px",
    "& img": {
      height: "100%",
      width: "100%",
    },
  },
  list: {
    maxHeight: 600,
    overflowY: "auto",
  },
  inputRootProduct: {
    paddingTop: "8px !important",
    paddingBottom: "7px !important",
    paddingLeft: "12px !important",
    paddingRight: "12px !important",
  },
  twoColumnRow: {
    display: "grid",
    gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
    columnGap: theme.spacing(2),
    rowGap: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1),
    },
  },
  productName: {
    display: "-webkit-box",
    WebkitLineClamp: 2,
    lineClamp: 2,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    wordWrap: "break-word",
  },
  searchContainer: {
    backgroundColor: theme.palette.background.paper,
    display: "grid",
    gridTemplateColumns: "6fr 1fr 1fr",
    columnGap: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1),
    },
  },
  summaryRow: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    "& > div:not(:last-child)": {
      borderRight: "1px solid #D0D5DD",
    },
  },
}));

export default function StocktakeItemsProductListPanelComponent({
  lang,
  isFetching,
  list,
  totalFiltered,
  isError,
  handleChangePage,
  handleChangeRowsPerPage,
  changeOrder,
  paginationOptions,
  handleReload,
  isSearchable,
  filter,
  handleSearch,
  isCompleted,
  viewProduct,
  handleClear,
}) {
  const classes = useStyles();

  return (
    <Box>
      {!!isSearchable && (
        <Formik
          initialValues={{
            search: filter.search,
          }}
          onSubmit={handleSearch}
        >
          {(formik) => (
            <Box mb={3}>
              <form onSubmit={formik.handleSubmit}>
                <Box className={classes.searchContainer}>
                  <Input
                    hiddenLabel
                    variant="filled"
                    size="small"
                    placeholder={getLang(
                      lang,
                      "placeholder.SEARCH_PRODUCT_NAME"
                    )}
                    margin="none"
                    InputProps={{
                      disableUnderline: true,
                      margin: "none",
                      style: {
                        borderRadius: "4px",
                        backgroundColor: "#eceff0",
                      },
                      classes: { input: classes.inputRootProduct },
                    }}
                    {...formik.getFieldProps("search")}
                  />
                  <Button
                    variant="text"
                    disableElevation
                    color="secondary"
                    size="small"
                    onClick={() => handleClear()}
                  >
                    {getLang(lang, "label.CLEAR")}
                  </Button>
                  <Button
                    variant="contained"
                    disableElevation
                    color="primary"
                    size="small"
                    type="submit"
                  >
                    {getLang(lang, "label.SEARCH")}
                  </Button>
                </Box>
              </form>
            </Box>
          )}
        </Formik>
      )}
      <StockTakeProductListTable
        rows={list}
        isFetching={isFetching}
        isError={isError}
        handleReload={handleReload}
        handleChangePage={handleChangePage}
        handleChangeRowPerPage={handleChangeRowsPerPage}
        totalFiltered={totalFiltered}
        paginationOptions={paginationOptions}
        tableFilter={filter}
        changeOrder={changeOrder}
        lang={lang}
        handleViewDetail={viewProduct}
        isCompleted={isCompleted}
      />
    </Box>
  );
}
