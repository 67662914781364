import React from "react";
import AssignTableComponent from "./assignTable.component";

function AssignTableContainer({
  rows,
  isFetching,
  isError,
  handleReload,
  page,
  rowsPerPage,
  language,
  handleChangePage,
  handleChangeRowsPerPage,
  totalFiltered,
  paginationOptions,
  tableFilter,
  changeOrder,
  lang,
  setSelectedBatch,
  retryAssign
}) {
  return (
    <AssignTableComponent
      rows={rows}
      isFetching={isFetching}
      isError={isError}
      handleReload={handleReload}
      page={page}
      rowsPerPage={rowsPerPage}
      language={language}
      handleChangePage={handleChangePage}
      handleChangeRowPerPage={handleChangeRowsPerPage}
      totalFiltered={totalFiltered}
      paginationOptions={paginationOptions}
      tableFilter={tableFilter}
      changeOrder={changeOrder}
      lang={lang}
      setSelectedBatch={setSelectedBatch}
      retryAssign={retryAssign}
    />
  );
}

export default AssignTableContainer;
