import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: 12,
    border: "1px solid #D0D5DD",
    padding: 10,
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  },
  icon: {
    height: 40,
    width: 40,
    objectFit: "contain",
    marginRight: 5,
  },
}));

export default function ClusterSummaryCardComponent({
  title,
  icon,
  count,
  loading,
}) {
  const classes = useStyles();

  return (
    <Box className={classes.card}>
      <Box display="flex" alignItems="center">
        <img src={icon} alt="icon" className={classes.icon} />
        <Typography variant="body1">{title}</Typography>
      </Box>
      <Box mt={0.5} ml={0.5}>
        {loading ? (
          <Skeleton variant="text" animation="wave" width="100%" height={30} />
        ) : (
          <Typography variant="h5">
            <b>{count}</b>
          </Typography>
        )}
      </Box>
    </Box>
  );
}
