import React from "react";
import { ModalFooter } from "./modal.component";
import PropTypes from "prop-types";

function ModalFooterContainer({
  showDelete,
  handleClick,
  handleDelete,
  type,
  handleClose,
  handleDisable,
  isLoading,
}) {
  return (
    <ModalFooter
      showDelete={showDelete}
      handleClick={handleClick}
      handleDelete={handleDelete}
      type={type}
      handleClose={handleClose}
      handleDisable={handleDisable}
      isLoading={isLoading}
    />
  );
}

ModalFooterContainer.defaultProps = {
  showDelete: true,
  handleClick: () => {},
  handleDelete: () => {},
  type: "submit",
  isLoading: false,
};

ModalFooterContainer.propTypes = {
  showDelete: PropTypes.bool,
  handleClick: PropTypes.func,
  handleDelete: PropTypes.func,
  isLoading: PropTypes.bool,
  type: PropTypes.oneOf(["submit", "button", "reset"]),
};

export default ModalFooterContainer;
