import axios from "axios";

export function deleteIncentiveProgram({
  id
}) {
  const url = `/distributor/admin/api/v1/programs/${id}/delete`;

  return new Promise((resolve, reject) => {
    axios.delete(url)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data))
  })
}