import { addAlert, setLoading } from "modules/notification";
import {
    fetchLoyaltyPointListing,
    addLoyaltyPoint,
    getLoyaltyPoint,
    editLoyaltyPoint,
    changeStatusLoyaltyPoint,
    getModuleDropdown,
    fetchBonanzaPointListing,
    addBonanzaPoint,
    getBonanzaPoint,
    editBonanzaPoint,
    changeStatusBonanzaPoint
} from "../action";
import {
    resetLoyaltyPoint,
    resetBonanzaPoint
} from '../slice'
import { getApiLang } from "app/feature/constants";

const fetchLoyaltyPointListingMiddleware = ({ dispatch, getState }) => next => action => {
    next(action);

    const { type, payload } = action;

    switch (type) {
        case fetchLoyaltyPointListing.pending.type:
            dispatch(setLoading({ id: fetchLoyaltyPointListing.typePrefix, state: true }));
            break;
        case fetchLoyaltyPointListing.fulfilled.type:
            dispatch(setLoading({ id: fetchLoyaltyPointListing.typePrefix, state: false }));
            break;
        case fetchLoyaltyPointListing.rejected.type:
            const lang = getState().constant.languages
            dispatch(setLoading({ id: fetchLoyaltyPointListing.typePrefix, state: false }));
            dispatch(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
            break;
        default:
            break;
    }
};

const addLoyaltyPointMiddleware = ({ dispatch, getState }) => next => action => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages
    const pageSetting = getState().loyaltyProgram.pageSetting;

    switch (type) {
        case addLoyaltyPoint.pending.type:
            dispatch(setLoading({ id: addLoyaltyPoint.typePrefix, state: true }));
            break;

        case addLoyaltyPoint.fulfilled.type:
            next(resetLoyaltyPoint());
            dispatch(fetchLoyaltyPointListing(pageSetting))
            dispatch(setLoading({ id: addLoyaltyPoint.typePrefix, state: false }));
            dispatch(addAlert({ severity: "success", message: getApiLang(lang, "success.SUCCESS_ADD_LOYALTY_POINT") }));
            break;

        case addLoyaltyPoint.rejected.type:
            dispatch(setLoading({ id: addLoyaltyPoint.typePrefix, state: false }));
            dispatch(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
            break;

        default:
            break;
    }
};

const getLoyaltyPointMiddleware = ({ dispatch, getState }) => next => action => {
    next(action);

    const { type, payload } = action;

    switch (type) {
        case getLoyaltyPoint.pending.type:
            dispatch(setLoading({ id: getLoyaltyPoint.typePrefix, state: true }));
            break;
        case getLoyaltyPoint.fulfilled.type:
            dispatch(setLoading({ id: getLoyaltyPoint.typePrefix, state: false }));
            break;
        case getLoyaltyPoint.rejected.type:
            const lang = getState().constant.languages
            dispatch(setLoading({ id: getLoyaltyPoint.typePrefix, state: false }));
            dispatch(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
            break;
        default:
            break;
    }
};

const editLoyaltyPointMiddleware = ({ dispatch, getState }) => next => action => {
    next(action);

    const { type, payload } = action;
    const pageSetting = getState().loyaltyProgram.pageSetting;
    const lang = getState().constant.languages

    switch (type) {
        case editLoyaltyPoint.pending.type:
            dispatch(setLoading({ id: editLoyaltyPoint.typePrefix, state: true }));
            break;
        case editLoyaltyPoint.fulfilled.type:
            dispatch(setLoading({ id: editLoyaltyPoint.typePrefix, state: false }));
            dispatch(fetchLoyaltyPointListing(pageSetting))
            dispatch(addAlert({ severity: "success", message: getApiLang(lang, "success.SUCCESS_EDIT_LOYALTY_POINT") }));
            break;
        case editLoyaltyPoint.rejected.type:
            dispatch(setLoading({ id: editLoyaltyPoint.typePrefix, state: false }));
            dispatch(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
            break;
        default:
            break;
    }
};

const changeStatusLoyaltyPointMiddleware = ({ dispatch, getState }) => next => action => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages
    const pageSetting = getState().loyaltyProgram.pageSetting;

    switch (type) {
        case changeStatusLoyaltyPoint.pending.type:
            dispatch(setLoading({ id: changeStatusLoyaltyPoint.typePrefix, state: true }));
            break;
        case changeStatusLoyaltyPoint.fulfilled.type:
            dispatch(setLoading({ id: changeStatusLoyaltyPoint.typePrefix, state: false }));
            dispatch(fetchLoyaltyPointListing(pageSetting))
            dispatch(addAlert({ severity: "success", message: getApiLang(lang, "success.SUCCESS_CHANGE_STATUS_LOYALTY_POINT") }));
            break;
        case changeStatusLoyaltyPoint.rejected.type:
            dispatch(setLoading({ id: changeStatusLoyaltyPoint.typePrefix, state: false }));
            dispatch(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
            break;
        default:
            break;
    }
};

const getModuleDropdownMiddleware = ({ dispatch, getState }) => next => async action => {
    next(action);

    const { type, payload } = action;

    switch (type) {
        case getModuleDropdown.pending.type:
            dispatch(setLoading({ id: getModuleDropdown.typePrefix, state: true }));
            break;
        case getModuleDropdown.fulfilled.type:
            dispatch(setLoading({ id: getModuleDropdown.typePrefix, state: false }));
            break;
        case getModuleDropdown.rejected.type:
            const lang = getState().constant.languages
            dispatch(setLoading({ id: getModuleDropdown.typePrefix, state: false }));
            dispatch(
                addAlert({
                    severity: "error",
                    message: getApiLang(lang, payload.code)
                })
            );
            break;
        default:
            break;
    }
};

const fetchBonanzaPointListingMiddleware = ({ dispatch, getState }) => next => action => {
    next(action);

    const { type, payload } = action;

    switch (type) {
        case fetchBonanzaPointListing.pending.type:
            dispatch(setLoading({ id: fetchBonanzaPointListing.typePrefix, state: true }));
            break;
        case fetchBonanzaPointListing.fulfilled.type:
            dispatch(setLoading({ id: fetchBonanzaPointListing.typePrefix, state: false }));
            break;
        case fetchBonanzaPointListing.rejected.type:
            const lang = getState().constant.languages
            dispatch(setLoading({ id: fetchBonanzaPointListing.typePrefix, state: false }));
            dispatch(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
            break;
        default:
            break;
    }
};


const addBonanzaPointMiddleware = ({ dispatch, getState }) => next => action => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages
    const pageSetting = getState().loyaltyProgram.pageSetting;

    switch (type) {
        case addBonanzaPoint.pending.type:
            dispatch(setLoading({ id: addBonanzaPoint.typePrefix, state: true }));
            break;

        case addBonanzaPoint.fulfilled.type:
            next(resetBonanzaPoint());
            dispatch(fetchBonanzaPointListing(pageSetting))
            dispatch(setLoading({ id: addBonanzaPoint.typePrefix, state: false }));
            dispatch(addAlert({ severity: "success", message: getApiLang(lang, "success.SUCCESS_ADD_BONANZA_POINT") }));
            break;

        case addBonanzaPoint.rejected.type:
            dispatch(setLoading({ id: addBonanzaPoint.typePrefix, state: false }));
            dispatch(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
            break;

        default:
            break;
    }
};

const getBonanzaPointMiddleware = ({ dispatch, getState }) => next => action => {
    next(action);

    const { type, payload } = action;

    switch (type) {
        case getBonanzaPoint.pending.type:
            dispatch(setLoading({ id: getBonanzaPoint.typePrefix, state: true }));
            break;
        case getBonanzaPoint.fulfilled.type:
            dispatch(setLoading({ id: getBonanzaPoint.typePrefix, state: false }));
            break;
        case getBonanzaPoint.rejected.type:
            const lang = getState().constant.languages
            dispatch(setLoading({ id: getBonanzaPoint.typePrefix, state: false }));
            dispatch(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
            break;
        default:
            break;
    }
};

const editBonanzaPointMiddleware = ({ dispatch, getState }) => next => action => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages
    const pageSetting = getState().loyaltyProgram.pageSetting;

    switch (type) {
        case editBonanzaPoint.pending.type:
            dispatch(setLoading({ id: editBonanzaPoint.typePrefix, state: true }));
            break;
        case editBonanzaPoint.fulfilled.type:
            //next(resetBonanzaPoint());
            dispatch(setLoading({ id: editBonanzaPoint.typePrefix, state: false }));
            dispatch(fetchBonanzaPointListing(pageSetting))
            dispatch(addAlert({ severity: "success", message: getApiLang(lang, "success.SUCCESS_EDIT_BONANZA_POINT") }));
            break;
        case editBonanzaPoint.rejected.type:
            dispatch(setLoading({ id: editBonanzaPoint.typePrefix, state: false }));
            dispatch(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
            break;
        default:
            break;
    }
};


const changeStatusBonanzaPointMiddleware = ({ dispatch, getState }) => next => action => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages
    const pageSetting = getState().loyaltyProgram.pageSetting;

    switch (type) {
        case changeStatusBonanzaPoint.pending.type:
            dispatch(setLoading({ id: changeStatusBonanzaPoint.typePrefix, state: true }));
            break;
        case changeStatusBonanzaPoint.fulfilled.type:
            dispatch(setLoading({ id: changeStatusBonanzaPoint.typePrefix, state: false }));
            dispatch(fetchLoyaltyPointListing(pageSetting))
            dispatch(addAlert({ severity: "success", message: getApiLang(lang, "success.SUCCESS_CHANGE_STATUS_BONANZA_POINT") }));
            break;
        case changeStatusBonanzaPoint.rejected.type:
            dispatch(setLoading({ id: changeStatusBonanzaPoint.typePrefix, state: false }));
            dispatch(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
            break;
        default:
            break;
    }
};


export default [
    fetchLoyaltyPointListingMiddleware,
    addLoyaltyPointMiddleware,
    getLoyaltyPointMiddleware,
    editLoyaltyPointMiddleware,
    changeStatusLoyaltyPointMiddleware,
    getModuleDropdownMiddleware,
    fetchBonanzaPointListingMiddleware,
    addBonanzaPointMiddleware,
    editBonanzaPointMiddleware,
    getBonanzaPointMiddleware,
    changeStatusBonanzaPointMiddleware
]