import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectLoading } from "modules/notification";
import { getProductDropdown } from "modules/product-mgmt/redux/action/products.action";
import SurveySearchComponent from "./surveySearch.component";

export default function SurveySearchContainer({ handleFilter }) {
  const lang = useSelector(state => state.constant.languages);
  const [productSearch, setProductSearch] = useState("")
  const dispatch = useDispatch()
  const productDropdown = useSelector(
    (state) => state.products.productsDropdown
  );
  const isProductDropdownLoading = useSelector(
    (state) => selectLoading(state, getProductDropdown.typePrefix)
  )

  const handleSearch = async (values) => {
    await handleFilter(values);
  };

  const handleProductSearch = (values) => {
    setProductSearch(values)
  }

  useEffect(() => {
    dispatch(getProductDropdown({
      search: productSearch
    }))
  }, [productSearch, dispatch])

  return (
    <SurveySearchComponent 
      handleSearch={handleSearch}
      isProductLoading={isProductDropdownLoading}
      productDropdown={productDropdown}
      handleItemSearch={handleProductSearch}
      lang={lang}
    />
  );
}