
import React from "react";
import { makeStyles, Typography, Box } from "@material-ui/core";
import InputTextField from "components/input/inputTextField";

const useStyle = makeStyles((theme) => ({
    body: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(0, 3),
        columnGap: theme.spacing(4)
    },
}));

export default function RoleNameInputComponent({
    label,
    ...props
}) {
    const classes = useStyle();

    return (
        <Box className={classes.body}>
            <Box>
                <Typography variant="body2" color="primary">
                    {label}
                </Typography>
            </Box>
            <Box flexGrow={1}>
                <InputTextField
                    InputStyle={{ backgroundColor: "unset", borderRadius: 8 }}
                    {...props}
                />
            </Box>
        </Box>

    );
}