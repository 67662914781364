import React from "react";
import ExportExcelDialogComponent from "./exportExcelDialog.component";
import { useSelector } from "react-redux";

function ExportExcelDialogContainer({
  isOpen,
  handleClose,
  batch,
  exportFile,
}) {
  const lang = useSelector((state) => state.constant.languages);

  const formats = [
    "xlsx",
    "csv",
    "txt"
  ];

  const fields = [
    {
      name: "All",
      value: "all",
    },
    {
      name: "Index",
      value: "index",
    },
    {
      name: "Product Name",
      value: "product",
    },
    {
      name: "Serial Number",
      value: "serial_no",
    },
    {
      name: "Sequence Number",
      value: "seq_no",
    },
    {
      name: "QR URL",
      value: "qr",
    },
    {
      name: "PIN Number",
      value: "pin",
    },
    {
      name: "Status",
      value: "status",
    }
  ];

  const handleSubmit = (values) => {
    if (batch?.quantity > 999999 && (Number(values.fromSeqNum) > batch?.toSeqNum || Number(values.toSn) > batch?.toSeqNum || (Number(values.fromSeqNum) > Number(values.toSeqNum))
      || (Number(values.toSeqNum) - Number(values.fromSeqNum)) > 999999))
      return;
    let format = values.format;
    let fields = values.fields.join(",");
    exportFile(format, fields, values.fromSeqNum, values.toSeqNum);
  };

  return (
    <ExportExcelDialogComponent
      isOpen={isOpen}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      lang={lang}
      formats={formats}
      fields={fields}
      batch={batch}
    />
  );
}

export default ExportExcelDialogContainer;
