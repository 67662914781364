import React from "react";
import {
  makeStyles,
  Typography,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  Box,
  Button,
  Divider,
  MenuItem,
  MenuList,
  Checkbox,
} from "@material-ui/core";
import PerfectScrollbar from "react-perfect-scrollbar";
import Skeleton from "@material-ui/lab/Skeleton";
import { TIER_ICON_PROPS } from "modules/stockflow/constants";
import DefaultImage from "assets/img/defaultPhoto.png";
import DefaultImg from "assets/img/img-default.png";
import { getLang } from "app/feature/constants";
import LoadingComponent from "components/loading";
import InfiniteScroll from "react-infinite-scroll-component";

const useStyle = makeStyles((theme) => ({
  paper: {
    width: 425,
  },
  button: {
    padding: 0,
    minWidth: 0,
  },
  buttonAddBranch: {
    display: "flex",
    padding: 0,
    minWidth: 0,
  },
  greenLabel: {
    color: theme.palette.secondary.main,
  },
  profile: {
    height: 26,
    width: 26,
    minWidth: 26,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& img": {
      height: "100%",
      minHeight: "100%",
      minWidth: "100%",
      objectFit: "cover",
      borderRadius: "50%",
      border: "1px solid #D0D5DD",
    },
  },
  tierIcon: {
    borderRadius: "3px",
    paddingLeft: "0.15em",
    paddingRight: "0.15em",
    display: "flex",
    alignItems: "center",
    position: "absolute",
    top: "-3px",
    left: "-8px",
  },
  tierLabel: {
    color: "#FFFFFF",
    fontSize: "0.6rem",
  },
  list: {
    maxHeight: "25vh",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "5px",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "16px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#E4E7EC",
      borderRadius: "16px",
    },
  },
}));

export default function CheckoutInitiatorSelectPanelComponent({
  selectAll,
  value,
  anchorRef,
  open,
  isOpenList,
  handleClose,
  isFetching,
  checkoutInitiatorDropdown,
  handleSelectAll,
  handleSelectUser,
  lang,
  isError,
  totalFiltered,
  hasNextPage,
  handleFetchMoreItems,
  handleReload,
}) {
  const classes = useStyle();

  return (
    <Popper
      open={open}
      style={{ zIndex: 99 }}
      anchorEl={anchorRef.current}
      role={undefined}
      transition
      disablePortal
      placement="bottom-start"
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === "bottom-start" ? "center top" : "center bottom",
          }}
        >
          <Paper className={classes.paper}>
            <ClickAwayListener onClickAway={handleClose}>
              <Box>
                <PerfectScrollbar>
                  {isFetching ? (
                    <Box p={1}>
                      <Skeleton variant="text" animation="wave" />
                      <Skeleton variant="text" animation="wave" />
                      <Skeleton variant="text" animation="wave" />
                      <Skeleton variant="text" animation="wave" />
                    </Box>
                  ) : (
                    <Box>
                      {isError ? (
                        <Box
                          display="flex"
                          justifyContent="center"
                          flexDirection="column"
                          alignItems="center"
                          pt={2}
                          pb={3}
                        >
                          <Typography
                            variant="body2"
                            color="primary"
                            style={{ fontWeight: "bold", paddingBottom: "8px" }}
                          >
                            {getLang(lang, "label.NO_DATA_RECEIVED")}
                          </Typography>
                          <Typography
                            variant="body2"
                            style={{ color: "#9CA6A9", paddingBottom: "16px" }}
                          >
                            {getLang(lang, "label.SOMETHING_WENT_WRONG")}
                          </Typography>
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleReload}
                            disableElevation
                          >
                            {getLang(lang, "label.REFRESH")}
                          </Button>
                        </Box>
                      ) : (
                        <>
                          <MenuList
                            autoFocusItem={isOpenList}
                            id="menu-list-grow"
                            onClick={() => {
                              handleSelectAll();
                            }}
                            style={{ padding: 0 }}
                          >
                            <MenuItem style={{ padding: 0 }}>
                              <Checkbox checked={selectAll} />
                              <Typography
                                variant="inherit"
                                noWrap
                                style={{ paddingLeft: "8px" }}
                              >
                                {getLang(lang, "label.ALL")}
                              </Typography>
                            </MenuItem>
                          </MenuList>
                          <Divider />
                          <InfiniteScroll
                            height="100%"
                            className={classes.list}
                            dataLength={checkoutInitiatorDropdown.length}
                            next={() => {
                              if (hasNextPage) handleFetchMoreItems();
                            }}
                            hasMore={
                              totalFiltered > checkoutInitiatorDropdown.length
                            }
                            loader={<LoadingComponent height={100} />}
                            endMessage={
                              isFetching ? (
                                <LoadingComponent height={100} />
                              ) : !checkoutInitiatorDropdown.length ? (
                                <Box p={2} style={{ textAlign: "center" }}>
                                  <Typography variant="body1">
                                    {getLang(
                                      lang,
                                      "message.info.NO_RECORD_EXIST"
                                    )}
                                  </Typography>
                                </Box>
                              ) : null
                            }
                          >
                            {checkoutInitiatorDropdown.map((user, index) => {
                              return (
                                <React.Fragment key={index}>
                                  <MenuItem
                                    style={{ padding: 0 }}
                                    onClick={() => handleSelectUser(user)}
                                  >
                                    <Checkbox
                                      checked={
                                        !!value.find(
                                          (v) => v.uuid === user.uuid
                                        )
                                      }
                                    />
                                    <Box display="flex" alignItems="center">
                                      <Box
                                        className={classes.profile}
                                        mx={1}
                                        position="relative"
                                      >
                                        {!!user.picture ? (
                                          <img
                                            src={user.picture}
                                            alt={user.name}
                                          />
                                        ) : (
                                          <img
                                            src={
                                              user.is_branch
                                                ? DefaultImg
                                                : DefaultImage
                                            }
                                            alt="default profile"
                                          />
                                        )}
                                        {!user.is_branch && !!user.tier && (
                                          <Box
                                            className={classes.tierIcon}
                                            style={{
                                              backgroundColor:
                                                TIER_ICON_PROPS.find(
                                                  ({ tier }) =>
                                                    tier === user.tier
                                                ).color,
                                            }}
                                          >
                                            <Typography
                                              className={classes.tierLabel}
                                            >
                                              T{user.tier}
                                            </Typography>
                                          </Box>
                                        )}
                                      </Box>
                                      <Typography
                                        variant="inherit"
                                        noWrap
                                        style={{ paddingLeft: "8px" }}
                                      >
                                        {user.name}
                                      </Typography>
                                    </Box>
                                  </MenuItem>
                                </React.Fragment>
                              );
                            })}
                          </InfiniteScroll>
                        </>
                      )}
                    </Box>
                  )}
                </PerfectScrollbar>
              </Box>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}
