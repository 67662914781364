import { appReducer, sessionReducer } from "modules/admin/redux";
import { profileReducer } from "modules/profile";
import { notificationReducer } from "modules/notification";
import {
  accountReducer,
  settingReducer,
  disbursementReducer,
  stockModuleSettingReducer,
} from "modules/account";
import { productPageReducer, productsReducer } from "modules/product-mgmt";
import { marketplaceReducer } from "modules/store/redux";
import {
  constantReducer,
  signUpReducer,
  resetPasswordReducer,
} from "modules/public/redux/reducer";
import {
  signupstepsReducer,
  stripePlanReducer,
} from "modules/private/redux/reducer";
import { billingReducer } from "modules/billing/redux";
import {
  serialNumberReducer,
  serialNumberBatchReducer,
  serialNumberTagReducer,
  assignBatchReducer,
  adtInfoReducer,
} from "modules/serial-number/redux";
import { dashboardReducer } from "modules/dashboard";
import { counterfeitReportReducer } from "modules/counterfeit-report";
import { consumerReducer } from "modules/consumer";
import { surveyReducer } from "modules/survey";
import {
  warrantyReducer,
  replacementHandoffReducer,
} from "modules/warranty/redux";
import { backdropReducer } from "modules/backdrop";
import { instantLuckyDrawReducer } from "modules/instant-lucky-draw";
import { luckyDrawReducer } from "modules/lucky-draw/redux";
import { branchReducer } from "modules/branch/redux";
import { branchGroupReducer } from "modules/group/redux";
import { employeeReducer } from "modules/employee/redux";
import { roleAccessRightReducer } from "modules/role-access-right/redux";
import {
  loyaltyProgramReducer,
  redemptionCenterReducer,
} from "modules/loyalty-program/redux";
import { organisationStructureReducer } from "modules/organisation-structure/redux";
import { trackcoReducer } from "modules/trackco/redux";
import {
  stockflowDealerReducer,
  stockflowRewardCenterReducer,
  stockflowIncentiveProgramReducer,
  stockflowInventoryReducer,
  stockflowStockoutReducer,
  stockflowRecallReducer,
  stockflowOrderReducer,
  stockflowRequestTransferReducer,
  stockflowTransferReducer,
  stockflowCheckoutReducer,
  stockflowCourierReducer,
  stockflowReceiveTransferReducer,
  stockflowStockTakeReducer,
} from "modules/stockflow/redux";
import {
  reportingTrendingReducer,
  reportingFilterReducer,
  reportingProductReducer,
  reportingCampaignReducer,
  reportingDemographicReducer,
  reportingConsumerReducer,
  reportingStockoutReducer,
  reportingValidationReducer,
  reportingMiscellaneousReducer,
  reportingSalesReducer,
} from "modules/reporting/redux/slice";
import { combineReducers } from "redux";
import { stockSummaryReducer } from "modules/stock-summary/redux";

const allReducers = {
  app: appReducer,
  session: sessionReducer,
  profile: profileReducer,
  billing: billingReducer,
  notification: notificationReducer,
  account: accountReducer,
  disbursement: disbursementReducer,
  setting: settingReducer,
  organisationStructure: organisationStructureReducer,
  products: productsReducer,
  productPage: productPageReducer,
  marketplace: marketplaceReducer,
  constant: constantReducer,
  signup: signUpReducer,
  signupsteps: signupstepsReducer,
  stripePlan: stripePlanReducer,
  resetPassword: resetPasswordReducer,
  branch: branchReducer,
  branchGroup: branchGroupReducer,
  employee: employeeReducer,
  roleAccessRight: roleAccessRightReducer,
  serialNumber: serialNumberReducer,
  serialNumberBatch: serialNumberBatchReducer,
  serialNumberTag: serialNumberTagReducer,
  assignBatch: assignBatchReducer,
  additionalInfo: adtInfoReducer,
  dashboard: dashboardReducer,
  counterfeitReport: counterfeitReportReducer,
  consumer: consumerReducer,
  survey: surveyReducer,
  warranty: warrantyReducer,
  warrantyHandoff: replacementHandoffReducer,
  backdrop: backdropReducer,
  instantLuckyDraw: instantLuckyDrawReducer,
  luckyDraw: luckyDrawReducer,
  loyaltyProgram: loyaltyProgramReducer,
  redemptionCenter: redemptionCenterReducer,
  trackco: trackcoReducer,
  stockModuleSetting: stockModuleSettingReducer,
  stockflowDealer: stockflowDealerReducer,
  stockflowRewardCenter: stockflowRewardCenterReducer,
  stockflowIncentiveProgram: stockflowIncentiveProgramReducer,
  reporting: combineReducers({
    stockout: reportingStockoutReducer,
    trending: reportingTrendingReducer,
    filter: reportingFilterReducer,
    product: reportingProductReducer,
    campaign: reportingCampaignReducer,
    demographic: reportingDemographicReducer,
    sales: reportingSalesReducer,
    miscellaneous: reportingMiscellaneousReducer,
    consumer: reportingConsumerReducer,
    validation: reportingValidationReducer,
  }),
  stockflowStockout: stockflowStockoutReducer,
  stockflowRecall: stockflowRecallReducer,
  stockflowInventory: stockflowInventoryReducer,
  stockSummary: stockSummaryReducer,
  stockflowOrder: stockflowOrderReducer,
  stockflowRequestTransfer: stockflowRequestTransferReducer,
  stockflowTransfer: stockflowTransferReducer,
  stockflowReceiveTransfer: stockflowReceiveTransferReducer,
  stockflowCheckout: stockflowCheckoutReducer,
  stockflowStockTake: stockflowStockTakeReducer,
  stockflowCourier: stockflowCourierReducer,
};

export default allReducers;
