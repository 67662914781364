import React from 'react';
import CompaniesReactSortableTreeComponent from './companiesReactSortableTree.component'
export default function CompaniesReactSortableTreeContainer({
  organisationStructure
}){

  return (
    <CompaniesReactSortableTreeComponent
      treeData={organisationStructure}
    />
  )
}