import React, { useEffect } from "react";
import SurveyDetailCardComponent from "./surveyDetailCard.component";
import { useDispatch, useSelector } from "react-redux";
import { getSurvey } from "modules/survey/redux/action";
import { selectLoading } from "modules/notification";

export default function SurveyDetailCardContainer({ id }) {
  const dispatch = useDispatch();
  const survey = useSelector((state) => state.survey.survey);
  const isLoading = useSelector((state) =>
    selectLoading(state, getSurvey.typePrefix)
  );
  const lang = useSelector((state) => state.constant.languages);
  const uiSetting = useSelector((state) => state.setting.uiSetting);

  useEffect(() => {
    dispatch(getSurvey({ id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <SurveyDetailCardComponent
      survey={survey}
      lang={lang}
      isLoading={isLoading}
      uiSetting={uiSetting}
    />
  );
}
