import React from "react";
import PerksFormComponent from "./perksForm.component";
import PropTypes from "prop-types";
import { connect } from "react-redux";

function PerksFormContainer({ handleClose, feature, lang }) {
  return <PerksFormComponent handleClose={handleClose} feature={feature} lang={lang}/>;
}

PerksFormContainer.propTypes = {
  handleClose: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  feature: state.billing.subscriptionPlan.metadata
});

export default connect(mapStateToProps, null)(PerksFormContainer);
