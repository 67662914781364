import axios from "axios"

export default async function getConsumerCampaignListing({
    id,
    length,
    start,
    column,
    order,
    date,
    type,
    products,
    patterns
}) {
    return new Promise((resolve, reject) => {
        axios
        .post(`/campaign/api/admin/campaigns/${id}/list`, {
            limit: length,
            skip: start,
            sortBy: column,
            orderBy: order,
            date,
            type,
            products,
            patterns
        })
        .then((response) => resolve(response.data))
        .catch((error) => {
            return reject(error.response.data)
        })
    })
}
