import React from "react"
import { makeStyles, Box, Typography, Paper, TableSortLabel } from "@material-ui/core"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TablePagination from "@material-ui/core/TablePagination"
import { TablePaginationActions } from "components/tablePagination"
import RefreshTable from "components/table/refreshTable"
import {
  StyledTableCell,
  StyledTableRow,
} from "components/table"
import { SkeletonTableRowStickyLeftRight } from "components/skeleton/skeleton.component"
import { KeyboardArrowDown } from "@material-ui/icons"
import moment from "moment";
import "moment/min/locales.min";
import { getLang } from "app/feature/constants"

const useStyle = makeStyles((theme) => ({
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    borderCollapse: "separate",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
    },
    "& th,td": {
      fontSize: ".75rem",
      borderRight: "1px solid rgba(58, 77, 84, 0.1)",
      padding: theme.spacing(1, 2, 1, 2),
    },
    "& th": {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightBold,
    },
    "& th:last-child, td:last-child": {
      width: 37,
      borderRight: "0px",
    },
    "& th th": {
      borderBottom: "none !important",
    },
    "& td td": {
      borderBottom: "none !important",
    },
  },
  emptyContainer: {
    backgroundColor: theme.palette.background.paper,
    textAlign: "center",
  },
}))

export default function LoyaltyPointHistoryTableComponent({
  rows,
  isFetching,
  isError,
  page,
  tableFilter,
  rowsPerPage,
  totalFiltered,
  language,
  handleChangePage,
  handleChangeRowsPerPage,
  handleReload,
  paginationOptions,
  changeOrder,
  lang
}) {
  const classes = useStyle()

  if(language==="ZH")
  {
    moment.locale('zh-cn')
  }else if(language==="TL")
  {
    moment.locale('tl-ph')
  }else
  {
    moment.locale(language)
  }

  const statusColor = {
    increment: "#32D583",
    decrement: "#F97066"
  }

  const getTransactionType = (history) => {
    let transaction = getLang(lang, history.type)

    if (transaction === getLang(lang, "label.LOYALTY_PROGRAM")) {
      if (history.serialNumber) {
        transaction += " - " + history.serialNumber
      }
    } else {
      if (history.orderItems.length) {
        transaction += " - " + history.orderItems.map(i => i.itemName).join(", ")
      }
    }

    return transaction
  }

  return (
    <>
      {!isFetching && isError && <RefreshTable handleReload={handleReload} />}
      {isFetching || rows.length > 0 ? (
        <Paper id="test"style={{overflowX: 'auto', width: "100%"}}>
          <TableContainer>
            <Table
              className={classes.table}
              aria-label="gift table"
              size="small"
              stickyHeader
            >
              <TableHead style={{ height: "45px" }}>
                <TableRow>
                  <StyledTableCell style={{ minWidth: "10rem" }}>{getLang(lang, "label.TRANSACTION")}</StyledTableCell>
                  <StyledTableCell>{getLang(lang, "label.BALANCE_BEFORE")}</StyledTableCell>
                  <StyledTableCell>{getLang(lang, "label.AMOUNT")}</StyledTableCell>
                  <StyledTableCell>{getLang(lang, "label.BALANCE_AFTER")}</StyledTableCell>
                  <StyledTableCell>
                    <TableSortLabel
                      active={true}
                      direction={tableFilter.order}
                      onClick={() => {
                        const order = tableFilter.order === 'asc' ? "desc" : "asc"
                        changeOrder(order)
                      }}
                      IconComponent={KeyboardArrowDown}
                    >
                      {getLang(lang, "label.DATE")}
                    </TableSortLabel>
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isFetching
                  ? [...new Array(5)].map((v, index) => (
                    <SkeletonTableRowStickyLeftRight key={index} columnCount={5} />
                  ))
                  : rows.map((row, index) => (
                    <StyledTableRow
                      key={index}
                      hover
                      tabIndex={-1}
                    >
                      <StyledTableCell>
                        {getTransactionType(row)}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <Typography variant="caption">
                          {row.beforeBalance.toLocaleString("en-US")}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <Typography variant="caption" style={{ color: statusColor[row.beforeBalance > row.afterBalance ? 'decrement' : 'increment'] }}>
                          {row.beforeBalance > row.afterBalance ? "-" : row.beforeBalance < row.afterBalance ? "+" : ""}
                          {row.amount.toLocaleString("en-US")}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <Typography variant="caption">
                          {row.afterBalance.toLocaleString("en-US")}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Typography variant="caption">
                          {moment.utc(row.date).local().format("lll")}
                        </Typography>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            style={{
              backgroundColor: "#fff",
              boxShadow: "0px -1px 0px rgba(58, 77, 84, 0.15)",
            }}
            ActionsComponent={TablePaginationActions}
            rowsPerPageOptions={paginationOptions}
            component="div"
            count={totalFiltered}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage={getLang(lang, 'label.ROWS_PER_PAGE')}
            labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${getLang(lang, 'label.OF')} ${count}`}
          />
        </Paper>
      ) : null}
      {!isFetching && !isError && rows.length === 0 && (
        <Box>
          <Table
            className={classes.table}
            aria-label="product table"
            size="small"
            stickyHeader
          >
            <TableHead style={{ height: "45px" }}>
              <TableRow>
                <StyledTableCell style={{ minWidth: "10rem" }}>{getLang(lang, "label.TRANSACTION")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.BALANCE_BEFORE")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.AMOUNT")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.BALANCE_AFTER")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.DATE")}</StyledTableCell>
              </TableRow>
            </TableHead>
          </Table>
          <Box py={9} className={classes.emptyContainer}>
            <Typography variant="caption" style={{ paddingBottom: "16px", fontWeight: "bold" }}>
              {getLang(lang, "paragraph.NO_LOYALTY_POINT_HISTORY")}
            </Typography>
          </Box>
        </Box>
      )}
    </>
  )
}
