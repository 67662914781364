import BackgroundImage from './image/background.png'
import FrameImage from './image/wheel.png'
import ButtonImage from './image/button.png'

export default {
    blocks: [
        {
            background: '#E77151'
        }
    ],
    backgroundImage: BackgroundImage,
    frameImage: FrameImage,
    prizeSegmentColor: [
        "#D7D7D7",
        "#FEF43F",
        "#EF7683"
    ],
    spinButtons: [
        {
            radius: "35px",
            imgs: [
                {
                    src: ButtonImage,
                    height: "100px",
                    top: "-50px"
                }
            ]
        }
    ],
    prize: {
        imgWidth: '35px',
        imgHeight: '35px',
        imgTop: '50%',
        fontTop: '15%'
    },
    defaultStyle: {
        fontColor: "#000",
        fontSize: "14px"
    },
    defaultConfig: {
        gutter: 1
    },
    width: "300px",
    height: "300px"
}