import accountMdl from "./account.mdl";
import settingMdl from "./setting.mdl";
import stockModuleSettingMdl from "./stockModuleSetting.mdl";
import disbursementMdl from "./disbursement.mdl";

export default [
  ...accountMdl,
  ...settingMdl,
  ...stockModuleSettingMdl,
  ...disbursementMdl,
];
