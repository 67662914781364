import React, { useState } from "react";
import GiftDetailsFormComponent from "./giftDetailsForm.component";
import { useDispatch, useSelector } from "react-redux";
import { createGift, updateGift, resetImage } from "modules/loyalty-program/redux";
import { setType } from "modules/loyalty-program/redux/slice/redemptionCenter.slice"
import { unwrapResult } from "@reduxjs/toolkit";
import ConfirmationDialogComponent from "components/dialog/confirmation";
import { hideBackdrop, showBackdrop } from "modules/backdrop";
import { toBase64 } from "modules/product-mgmt/utils/productPage.util";
import { v4 as uuidv4 } from "uuid";
import { FolderType } from "lib/constants/aws_s3";
import { uploadImageToS3 } from "app/feature";
import { addAlert } from "modules/notification";
import { isFile } from "lib/helper";
import { redemptionCenterType } from "modules/loyalty-program/constants";
import { GIFT_ADD, GIFT_EDIT } from "lib/constants/accessRights";
import { getLang } from "app/feature/constants";
import {setImgFormatAndSize} from "../../../../../lib/FileHelper";

export default function GiftDetailsContainer({ handleClose, headerType }) {
  const dispatch = useDispatch();
  const hasGiftAddAccessRight = useSelector(state => state.profile.accessRights.includes(GIFT_ADD));
  const hasGiftEditAccessRight = useSelector(state => state.profile.accessRights.includes(GIFT_EDIT));
  const [confirmationDialogModal, setConfirmationDialogModal] = useState(false);
  const giftType = useSelector((state) => state.redemptionCenter.giftType);
  const gift = useSelector(state => state.redemptionCenter.gift)
  const id = useSelector((state) => state.session.idTokenPayload.sub);
  const lang = useSelector(state => state.constant.languages)
  const [isCropping, setIsCropping] = useState(false);
  const language = useSelector(state => state.profile.language)

  const changeToUpdate = (e) => {
    if(hasGiftEditAccessRight){
      e.preventDefault();
      const updateInfo = {
        index: redemptionCenterType.gift,
        type: "edit",
      };
      dispatch(setType(updateInfo));
    }
  };

  const uploadToS3 = async (imgUrl) => {
    let url = ""
    let base64Image = await toBase64(imgUrl);
    base64Image = await setImgFormatAndSize(base64Image, 2160, 2160, "image/webp");
    const uploadInfo = {
      uploadType: FolderType.giftImage,
      base64Image: base64Image,
      fileName: `${uuidv4()}.webp`,
      id: id
    }
    await dispatch(uploadImageToS3(uploadInfo))
      .then(unwrapResult)
      .then(imageUrl => {
        url = imageUrl 
      })
      .catch(error => {
        dispatch(hideBackdrop());
        dispatch(
          addAlert({
            severity: "error",
            message: error.message || getLang(lang,"message.error.FAILED_UPLOAD_PICTURE")
          })
        )
      })
      return url
  }

  const createGiftDetails = (values) => {
    dispatch(createGift(values))
      .then(unwrapResult)
      .then(() => {
        dispatch(hideBackdrop());
        setConfirmationDialogModal(true);
      })
      .catch((error) => {
        dispatch(hideBackdrop());
      })
  }

  const updateGiftDetail = (values) => {
    dispatch(updateGift(values))
      .then(unwrapResult)
      .then(() => {
        changeBackView();
        dispatch(hideBackdrop());
      })
      .catch(error => {
        dispatch(hideBackdrop());
        dispatch(
          addAlert({
            severity: "error",
            message: error.message || getLang(lang,"message.info.FAILED_UPLOAD_PICTURE")
          })
        )
      })
  }

  const changeBackView = () => {
    const updateInfo = {
      index: redemptionCenterType.gift,
      type: "view",
    };
    dispatch(setType(updateInfo));
  };

  const handleSubmit = async (values) => {
    let urlTemp = " "
    dispatch(showBackdrop());
    if(giftType === "create" && hasGiftAddAccessRight) {
      if(isFile(values.imgUrl)) {
        urlTemp = await uploadToS3(values.imgUrl)
        if(urlTemp.length > 0 ) {
          values.imgUrl = urlTemp
        }
      }
      createGiftDetails(values)
    }

    if(giftType === "edit" && hasGiftEditAccessRight) {
      if(isFile(values.imgUrl)) {
        urlTemp = await uploadToS3(values.imgUrl)
        if(urlTemp.length > 0 ) {
          values.imgUrl = urlTemp
          updateGiftDetail(values);
        }
      } else {
        updateGiftDetail(values);
      }
    }
  }

  const handleDelete = (formik, e, input) => {
    e.preventDefault()
    if (!input){
    formik.setFieldValue("imgUrl", "");
    }
    dispatch(resetImage());
  }

  const handleCloseCropping = () => setIsCropping(false);

  const handleOpenCropping = () => setIsCropping(true);

  return (
    <>
      <GiftDetailsFormComponent
        hasGiftAddAccessRight={hasGiftAddAccessRight}
        hasGiftEditAccessRight={hasGiftEditAccessRight}
        headerType={headerType}
        gift={gift}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        giftType={giftType}
        language={language}
        changeToUpdate={changeToUpdate}
        handleDelete={handleDelete}
        lang={lang}
        changeBackView={changeBackView}
        handleCloseCropping={handleCloseCropping}
        handleOpenCropping={handleOpenCropping}
        isCropping={isCropping}
      />
      <ConfirmationDialogComponent
        isOpen={confirmationDialogModal}
        handleProceed={handleClose}
        type="success"
        title={getLang(lang,"label.GIFT_ADDED_SUCCESSFULLY")}
        rejectOption={false}
        proceedLabel={getLang(lang,"label.ALRIGHT")}
      />
    </>
  );
}
