import { Box, Checkbox, IconButton, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import { DownloadIcon, HorizontalBarChartIcon } from "modules/reporting/assets/svg";
import downloadjs from 'downloadjs';
import html2canvas from 'html2canvas';
import React, { useState } from "react";
import { BoxLoading } from "../../skeleton/loading";
import { responseHandler } from "modules/reporting/utils/helper";
import Chart from 'react-apexcharts';
import { setChartColors } from "modules/reporting/utils/chartConfig";
import { ToggleButton } from "@material-ui/lab";
import ApexCharts from 'apexcharts'
import { CampaignConversionsNoData } from "../../skeleton/no-data";
import { CampaignConversionsNoResult } from "../../skeleton/no-result";

const useStyles = makeStyles((theme) => ({
    card__title: {
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "20px",
        color: "#101828",
    },
    global__flexGap20: {
        gap: "20px"
    },
    global__flexGap3 :{
        gap: "3px"
    },
    text__muted : {
        fontFamily: "Roboto",
        fontWeight: "400",
        fontSize: "12px",
        lineHeight: "24px",
        color: '#98A2B3'
    },
    text__hyperlink: {
        fontFamily: "Roboto",
        fontWeight: "400",
        fontSize: "12px",
        // lineHeight: "24px",
        color: '#6AAF68',
        '&:hover': {
            cursor: 'pointer',
            textDecoration: 'underline'
        }
    },
    table__cell: {
        fontFamily: "Roboto",
        border: "1px solid #F2F4F7",
    },
    table__text_cell: {
        fontFamily: "Roboto",
        color: "#344054",
        fontWeight: "500",
        fontSize: "16px",
    },
}))

const checkBoxStyles = makeStyles({
    checkbox__cell: {
        color: props => props.color
    }
})

export default function CheckboxTableChartDownloadCard({
    title,
    id,
    data = {},
    status,
    chartType = 'bar',
    chartID,
    columns,
    translate
}) {
    const classes = useStyles();
    const colorSet = setChartColors(Object.keys(data).length === 0 ? 1 : data.datasets.length, true);
    const [selected, setSelected] = useState([]);
    const [stacked, setStacked] = useState(false);
    const handleDownloadCard = () => {
        let element = document.getElementById(id);

        html2canvas(element).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            downloadjs(imgData, `${title}.png`);
        });

        
    }

    // chart config
    const options = {
        chart: {
            stacked: stacked,
            id: chartID
        },
        plotOptions: {
            bar: {
                dataLabels: {
                    style: {
                        fontSize: 16,
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 'bold',
                        colors: ['#f00', '#fff']
                    },
                    enabled: false,
                    total: {
                        enabled: false,
                        offsetX: 10,
                        offsetY: 0,
                        style: {
                            color: '#373d3f',
                            fontSize: '12px',
                            fontFamily: undefined,
                            fontWeight: 600
                        }
                    }
                }
            }
        },
        tooltip:{
            followCursor: true
        },
        xaxis: {
            categories: data.labels,
        },
        legend: {
            position: 'bottom',
            itemMargin: {
                horizontal: 20,
                vertical: 16
            },
            onItemClick: {
                toggleDataSeries: false
            },
            showForSingleSeries: true
        },
        fill: {
            opacity: 1
        },
        stroke: {
            width: 2,
        },
        dataLabels:{
            enabled: false,
        },
        colors: colorSet

    }

    const hideAll = () => {
        data.rows.forEach((n) => {
            ApexCharts.exec(chartID, 'hideSeries', n.name);
        })
    }

    const showAll = () => {
        data.rows.forEach((n) => {
            ApexCharts.exec(chartID, 'showSeries', n.name);
        })
    }

    const toggle = (name) => {
        ApexCharts.exec(chartID, 'toggleSeries', name);
    }

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
        toggle(name)
    }

    const onSelectAllClick = (event) => {
        if (!event.target.checked){
            const newSelecteds = data.rows.map((n) => n.name);
            hideAll();
            setSelected(newSelecteds);
            return;
        }

        showAll();
        setSelected([]);
    }

    const CheckboxComponent = ({children, ...props}) => {
        const { checkbox__cell } = checkBoxStyles(props);
        const { checked, onClick, inputProps } = props;

        return (
            <Checkbox
                className={`${checkbox__cell}`}
                checked={checked}
                onClick={onClick}
                inputProps={inputProps}
            />            
        )
    }

    const displayData = (data) => {
        if(Object.keys(data).length === 0) return null;
        return (
            <>
                <Chart
                    options={options}
                    series={data.datasets}
                    type={chartType}
                    height="430px"
                >
                </Chart>
                <TableContainer component={ Paper }>
                    <Table>
                        <TableHead>
                            <TableRow
                                // hover
                            >
                                <TableCell
                                    padding="checkbox"
                                >
                                    <Checkbox 
                                        onChange={onSelectAllClick}
                                        indeterminate={selected.length > 0 && selected.length < data.rows.length}
                                        checked={data.rows.length > 0 && selected.length === 0}
                                    />
                                </TableCell>
                                {columns.map((column, index) => (
                                    <TableCell
                                        key={index}
                                        align={column.align
                                            ? column.align
                                            : "left"
                                        }
                                        className={classes.table__cell}
                                        size={column.size}
                                    >
                                        {column.render(column)}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.rows.map((row, index) => {
                                const isItemSelected = selected.indexOf(row.name) !== -1;
                                const labelId = `enhanced-table-checkbox-${row.name}`;
                                
                                return (
                                    <TableRow
                                        // hover
                                        aria-checked={isItemSelected}
                                        key={row.name}
                                        selected={isItemSelected}
                                    >
                                        <TableCell 
                                            padding="checkbox"
                                            className={classes.table__cell}
                                        >
                                            <CheckboxComponent 
                                                checked={!isItemSelected}
                                                onClick={(event) => handleClick(event, row.name)}
                                                inputProps={{ 'aria-labelledby': labelId }}
                                                color={colorSet[index] + ' !important'}
                                            />
                                        </TableCell>
                                        {
                                            row.renderCells.map((cell, indexCell) => {
                                                return cell
                                            })
                                        }
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>             
            </>

        )
    }

    return (
        <Box id={id}>
            <Box marginBottom="24px" display="flex" justifyContent="space-between" alignItems="center">
                <Typography className={classes.card__title}>
                    {title}
                </Typography>

                <Box display="flex" alignItems="center">
                    <IconButton title="Download Charts" aria-label="download" onClick={handleDownloadCard} disableRipple>
                        <DownloadIcon />
                    </IconButton>
                    {
                        chartType === 'bar' ? (
                            <ToggleButton 
                                selected={stacked}
                                value="chart"
                                onChange={() => setStacked(!stacked)}
                            >
                                <HorizontalBarChartIcon  />
                            </ToggleButton>
                        ): null
                    }
                </Box>

            </Box>

            {
                responseHandler(
                    data,
                    status,
                    (
                        displayData(data)
                    ),
                    (
                        <BoxLoading />
                    ),
                    (
                        <CampaignConversionsNoData 
                            title="Campaign Data Not Found"
                            value="You don't have any customer data on the selected filter."
                            translate={translate}
                        />
                    ),
                    (
                        <CampaignConversionsNoResult 
                            title="Campaign Data Not Found"
                            value="You don't have any customer data on the selected filter."
                            translate={translate}
                        />
                    ),
                    (
                        <CampaignConversionsNoResult 
                            title="Loading Failed"
                            value="We're sorry, we can't load the data at the moment. Please try again later."
                            translate={translate}
                        />
                    )
                )
            }
        </Box>
    )
}