import React from 'react'
import { CreditKoodFooter, LoginLayout } from 'modules/public/components/layout'
import {
    Box,
    Divider,
    makeStyles,
    Paper
} from '@material-ui/core'
import Header from 'modules/public/components/header'
import NewPasswordForm from 'modules/public/components/form/new-password-form'

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: "450px",
        position: "absolute",

        boxShadow: "0px 10px 15px rgba(0, 0, 0, 0.1)",
        [theme.breakpoints.down("md")]: {
            alignSelf: "center",
            width: "45%"
        },
        [theme.breakpoints.down("sm")]: {
            alignSelf: "center",
            width: "60%"
        },
        [theme.breakpoints.down("xs")]: {
            alignSelf: "center",
            width: "80%"
        }
    }
}));

export default function NewPasswordPage({
    email,
    token
}) {
    const classes = useStyles()

    return (
        <LoginLayout>
            <Box className={classes.root}>
                <Paper className={classes.paper} elevation={0}>
                    <Header />
                    <Divider />
                    <Paper className={classes.form} elevation={0}>
                        <NewPasswordForm
                            email={email}
                            token={token}
                        />
                    </Paper>
                </Paper>
                <CreditKoodFooter />
            </Box>
        </LoginLayout>
    )
}
