import React, { useEffect, useState } from "react";
import ImportLogDialogComponent from "./importLogDialog.component";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { getAdtInfoImportLog } from "modules/serial-number/redux";
import { selectLoading } from "modules/notification";
import { generatePaginationOptions } from "lib/helper";

function ImportLogDialogContainer({ isOpen, handleClose }) {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.constant.languages);
  const logs = useSelector((state) => state.additionalInfo.importLog.list);
  const isFetching = useSelector((state) =>
    selectLoading(state, getAdtInfoImportLog.typePrefix)
  );
  const totalFiltered = useSelector(
    (state) => state.additionalInfo.importLog.totalFiltered
  );
  const isError = useSelector(
    (state) => state.additionalInfo.importLog.isError
  );

  const [paginationOptions, setPaginationOptions] = useState([]);
  const [tableFilter, setTableFilter] = useState({
    page: 0,
    rowsPerPage: 25,
    order: "desc",
    orderBy: "created_at",
  });

  useEffect(() => {
    if (isOpen) {
      handleDataLoad();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableFilter, isOpen]);

  useEffect(() => {
    setPaginationOptions(generatePaginationOptions(totalFiltered));
  }, [totalFiltered]);

  const handleDataLoad = () => {
    let payload = {
      length: tableFilter.rowsPerPage,
      start: tableFilter.rowsPerPage * tableFilter.page,
      order: tableFilter.order,
    };
    dispatch(getAdtInfoImportLog(payload));
  };

  const handleChangePage = (event, newPage) => {
    let newData = {
      ...tableFilter,
      page: newPage,
    };
    setTableFilter(newData);
  };

  const handleChangeRowsPerPage = (event) => {
    let newData = {
      ...tableFilter,
      page: 0,
      rowsPerPage: event.target.value,
    };
    setTableFilter(newData);
  };

  const changeOrder = (order) => {
    let newData = {
      ...tableFilter,
      order,
    };
    setTableFilter(newData);
  };

  return (
    <>
      <ImportLogDialogComponent
        logs={logs}
        isOpen={isOpen}
        lang={lang}
        isFetching={isFetching}
        isError={isError}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        totalFiltered={totalFiltered}
        handleReload={handleDataLoad}
        paginationOptions={paginationOptions}
        tableFilter={tableFilter}
        changeOrder={changeOrder}
        handleClose={handleClose}
      />
    </>
  );
}

ImportLogDialogContainer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ImportLogDialogContainer;
