import axios from "axios";

const endpoint_url = "/payment/api/v1/billing";

const put = ({
  name,
  email,
  phone,
  contact_code,
  line1,
  line2,
  city,
  postalCode,
  state,
  country
}) => {
  const body = {
    name: name,
    email: email,
    phone: phone,
    contact_code,
    line1: line1,
    line2: line2,
    city: city,
    postal_code: postalCode,
    state: state,
    country: country
  };

  return new Promise((resolve, reject) => {
    axios
      .put(endpoint_url, body)
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

const get = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(endpoint_url)
      .then(response => resolve(response.data))
      .catch(error => {
        return reject(error.response.data);
      });
  });
};

const billingDetail = {
  put,
  get
};

export default billingDetail;
