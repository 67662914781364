import React from "react"
import {
  Box,
  makeStyles,
  Typography,
} from "@material-ui/core"
import ScanHistoryTable from "../../table/scanHistoryTable"
import {
  GetApp as GetAppIcon
} from '@material-ui/icons'
import { getLang } from "app/feature/constants";


const API_HOST = process.env.REACT_APP_API_HOST_URL;

const useStyles = makeStyles((theme) => ({
  container: {
    background: "white",
    borderRadius: 4,
    padding: "16px 20px",
    overflowX: "auto"
  },
}))

export default function ScanHistoryPanelComponent({ 
  scanHistory,
  isFetching,
  isError,
  handleChangePage,
  handleChangeRowsPerPage,
  page,
  rowsPerPage,
  totalFiltered,
  handleReload,
  paginationOptions,
  tableFilter,
  changeOrder,
  snScanLimit,
  enc,
  language,
  token,
  lang,
  handleViewDetail
}) {
  const classes = useStyles()

  return (
    <>
      <Box className={classes.container} mt={2}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="body1"><b>{getLang(lang, "label.CONSUMER_SCAN_HISTORY")}</b></Typography>
          <GetAppIcon 
            fontSize="small" 
            style={{
              cursor: "pointer"
            }}
            onClick={() => window.open(`${API_HOST}/consumer/export/v1/scan/${enc}?token=${token}`, "_blank")}
          />
        </Box>
        <Box>
          <ScanHistoryTable 
            rows={scanHistory}
            isFetching={isFetching}
            isError={isError}
            handleReload={handleReload}
            page={page}
            rowsPerPage={rowsPerPage}
            language={language}
            totalFiltered={totalFiltered}
            paginationOptions={paginationOptions}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            tableFilter={tableFilter}
            changeOrder={changeOrder}
            snScanLimit={snScanLimit}
            lang={lang}
            handleViewDetail={handleViewDetail}
          />
        </Box>
      </Box>
    </>
  )
}
