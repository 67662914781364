import React from "react";
import { Box } from "@material-ui/core";
import RespondentsSearch from '../../search/respondentSearch';
import RespondentListingTable from '../../table/respondentListingTable';

export default function RespondentsListingPanelComponent({
  data,
  isFetching,
  page,
  rowsPerPage,
  totalRecords,
  handleChangePage,
  handleChangeRowsPerPage,
  handleSearch,
  handleReload,
  paginationOptions
}) {
  return (
    <Box>
      <RespondentsSearch 
        handleFilter={(value) => {
          handleSearch(value);
        }}
      />
      <RespondentListingTable
        isFetching={isFetching}
        page={page}
        rowsPerPage={rowsPerPage}
        totalRecords={totalRecords}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleReload={handleReload}
        data={data}
        paginationOptions={paginationOptions}
      />
    </Box>
  );
}