import React from "react";
import PreviewRegFormComponent from "./previewRegForm.component";
import { connect } from "react-redux";
import { useSelector } from "react-redux";

function PreviewRegContainer({ warrantyRegisterForm, logo }) {
  const lang = useSelector(state => state.constant.languages);
  return (
    <PreviewRegFormComponent
      warrantyRegisterForm={warrantyRegisterForm}
      logo={logo}
      lang={lang}
    />
  );
}

const mapStateToProps = (state) => ({
  logo: state.account.logoImg
});

export default connect(mapStateToProps, null)(PreviewRegContainer);
