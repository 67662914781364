import { createAsyncThunk } from "@reduxjs/toolkit";
import stockflowApi from "app/api/stockflow";

export const STOCKFLOWRECEIVETRANSFER = "stockflowReceiveTransfer";

export const getReceiveTransferList = createAsyncThunk(
  `${STOCKFLOWRECEIVETRANSFER}/getReceiveTransferList`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi
      .getReceiveTransferList({
        ...payload,
      })
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const getReceiveTransferDetail = createAsyncThunk(
  `${STOCKFLOWRECEIVETRANSFER}/getReceiveTransferDetail`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi
      .getReceiveTransferDetail(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const getReceiveTransferReceiverDropdown = createAsyncThunk(
  `${STOCKFLOWRECEIVETRANSFER}/getReceiveTransferReceiverDropdown`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi
      .getReceiveTransferReceiverDropdown(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const getReceiveTransferSenderDropdown = createAsyncThunk(
  `${STOCKFLOWRECEIVETRANSFER}/getReceiveTransferSenderDropdown`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi
      .getReceiveTransferSenderDropdown(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const getReceiveTransferItemBoxList = createAsyncThunk(
  `${STOCKFLOWRECEIVETRANSFER}/getReceiveTransferItemBoxList`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi
      .getReceiveTransferItemBoxList(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const getReceiveTransferItemBoxProductList = createAsyncThunk(
  `${STOCKFLOWRECEIVETRANSFER}/getReceiveTransferItemBoxProductList`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi
      .getReceiveTransferItemBoxProductList(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const getReceiveTransferItemProductList = createAsyncThunk(
  `${STOCKFLOWRECEIVETRANSFER}/getReceiveTransferItemProductList`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi
      .getReceiveTransferItemProductList(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const getReceiveTransferItemProductSNList = createAsyncThunk(
  `${STOCKFLOWRECEIVETRANSFER}/getReceiveTransferItemProductSNList`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi
      .getReceiveTransferItemProductSNList(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const getReceiveTransferDeliveryInfo = createAsyncThunk(
  `${STOCKFLOWRECEIVETRANSFER}/getReceiveTransferDeliveryInfo`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi
      .getReceiveTransferDeliveryInfo(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);
