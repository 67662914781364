import { Box, Checkbox, IconButton, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Typography } from "@material-ui/core";
import html2canvas from "html2canvas";
import { DownloadIcon, StackedBarIcon, VerticalBarChartIcon } from "modules/reporting/assets/svg";
import React, { useState } from "react";
import { ContentCard } from "../../layout";
import downloadjs from 'downloadjs';
import { setChartColors } from "modules/reporting/utils/chartConfig";
import Chart from 'react-apexcharts';
import ApexCharts from 'apexcharts'
import { drilldownPageSelection, responseHandler } from "modules/reporting/utils/helper";
import { BoxLoading } from "../../skeleton/loading";

import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { CampaignConversionsNoData } from "../../skeleton/no-data";
import { CampaignConversionsNoResult } from "../../skeleton/no-result";
import { cardChartStyle, fontTextStyle, tableStyle } from "modules/reporting/styles";
import { Link } from "react-router-dom";
import moment from "moment";
import "moment/min/locales.min"

const checkBoxStyles = makeStyles({
    checkbox__cell: {
        color: props => props.color
    }
})

export default function CampaignConversionPanel({
    status,
    data,
    columns,
    sort,
    translate,
    pagination,
    currentFilter,
    language,
    pageType
}){
    // const classes = useStyles();
    const chartClasses = cardChartStyle();
    const tableClasses = tableStyle();
    const fontClasses = fontTextStyle();

    const [selected, setSelected] = useState([]);
    const [stacked, setStacked] = useState(true);
    const [chartTypeSelection, setChartTypeSelection] = useState("stacked-bar");
    const handleChartTypeSelection = (event, newChartTypeSelection) => {
        if (newChartTypeSelection === chartTypeSelection || newChartTypeSelection === null) {
            return;
        }
        setChartTypeSelection(newChartTypeSelection);
    };
    
    // const [horizontal, setHorizontal] = useState(false);

    // download campaign conversion
    const handleDownloadCampaignCard = () => {
        let element = document.getElementById("campaign-conversion");

        html2canvas(element).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            downloadjs(imgData, 'campaign-conversion-qr-report.png');
        });
    }

    // apexchart configuration & function
    const chartID = "campaign-conversion-chart";

    const hideAll = () => {
        data.campaignConversionData.rows.forEach((n) => {
            if(!n.campaign_type)
                return;
            ApexCharts.exec(chartID, 'hideSeries', translate(n.name));
        })
    }

    const showAll = () => {
        data.campaignConversionData.rows.forEach((n) => {
            if(!n.campaign_type)
                return;
            ApexCharts.exec(chartID, 'showSeries', translate(n.name));
        })
    }

    const toggle = (name) => {
        ApexCharts.exec(chartID, 'toggleSeries', translate.getLang(translate.lang, name));
    }



    const CheckboxComponent = ({children, ...props}) => {
        const { checkbox__cell } = checkBoxStyles(props);
        const { checked, onClick, inputProps } = props;

        return (
            <Checkbox
                className={`${checkbox__cell}`}
                checked={checked}
                onClick={onClick}
                inputProps={inputProps}
            />
        )
    }

    const onSelectAllClick = (event) => {
        if (!event.target.checked){
            const newSelecteds = data.campaignConversionData.rows.map((n) => translate(n.name));
            hideAll();
            setSelected(newSelecteds);
        } else {
            showAll();
            setSelected([]);
        }
    }

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
        
        toggle(name)
    }

    const createSortHandler = (property) => (event) => {
        sort.handleRequestSort(event, property);
    };

    let no_iteration = pagination.rowsPerPage * pagination.page + 1;

    const isDev = currentFilter.isDev;
    const displayData = (ref) => {

        if (Object.keys(ref).length === 0 || !ref.isSuccess){
            return null;
        }
        const colorSet = setChartColors(ref.datasets.length, true);

        const options = {
            chart: {
                stacked: stacked,
                id: chartID
            },
            plotOptions: {
                bar: {
                    dataLabels: {
                        style: {
                            fontSize: 16,
                            fontFamily: 'Helvetica, Arial, sans-serif',
                            fontWeight: 'bold',
                            colors: ['#f00', '#fff']
                        },
                        enabled: false,
                        total: {
                            enabled: false,
                            offsetX: 10,
                            offsetY: 0,
                            style: {
                                color: '#373d3f',
                                fontSize: '12px',
                                fontFamily: undefined,
                                fontWeight: 600
                            }
                        }
                    }
                }
            },
            dataLabels:{
                enabled: false,
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return parseInt(val)
                    }
                }
            },
            stroke: {
                width: 1,
                colors: ['#fff']
            },
            xaxis: {
                categories: ref.labels.map(date => moment(date).format('ll')),
                labels: {
                    hideOverlappingLabels: true,
                    rotateAlways: true,
                    rotate: -45
                }
            },
            fill: {
                opacity: 1
            },
            legend: {
                position: 'bottom',
                itemMargin: {
                    horizontal: 20,
                    vertical: 16
                },
                onItemClick: {
                    toggleDataSeries: false
                }
            },
            colors: colorSet
    
        }

        const chartSeries = () => {
            let series = JSON.stringify(ref);
            series = JSON.parse(series);
    
            series.datasets.forEach(e => {
                e.name = translate(e.name);
            });
    
            return series;
        }

        return (
            <Box
                className={chartClasses.card}
            >
                <Chart
                    options={options}
                    series={chartSeries().datasets}
                    type="bar"
                    height="430px"
                ></Chart>
                <TableContainer component={ Paper }>
                    <Table>
                        <TableHead>
                            <TableRow
                                hover
                            >
                                <TableCell
                                    padding="checkbox"
                                    className={tableClasses.table__cell}
                                >
                                    <Checkbox 
                                        onChange={onSelectAllClick}
                                        indeterminate={selected.length > 0 && selected.length < ref.rows.length}
                                        checked={ref.rows.length > 0 && selected.length === 0}
                                    />
                                </TableCell>
                                {columns.map((column, index) => (
                                    <TableCell className={[tableClasses.table__cell, fontClasses.text__header_table_cell]} key={column.title} size={column.size}>
                                        {
                                            column.field ? 
                                            (
                                                <TableSortLabel
                                                    active={sort.valueToOrderBy === column.field ? true : false}
                                                    direction={sort.valueToOrderBy === column.field ? sort.orderDirection : 'desc'}
                                                    onClick={!status.isFetchingCampaignConversionData && column.field ? createSortHandler(column.field) : null}
                                                >
                                                    {column.render(column)}
                                                </TableSortLabel>
                                            ) : 
                                            column.render(column)
                                        }
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {ref.rows.map((row, index) => {
                                const isItemSelected = selected.indexOf(translate(row.name)) !== -1;
                                const labelId = `enhanced-table-checkbox-${translate(row.name)}`;
                                
                                if(!row.campaign_type)
                                    return null;
                                
                                return (
                                    <TableRow
                                        hover
                                        aria-checked={isItemSelected}
                                        key={translate(row.name)}
                                        selected={isItemSelected}
                                    >
                                        <TableCell 
                                            padding="checkbox"
                                            className={tableClasses.table__cell}
                                        >
                                            <CheckboxComponent 
                                                checked={!isItemSelected}
                                                onClick={(event) => handleClick(event, translate(row.name))}
                                                inputProps={{ 'aria-labelledby': labelId }}
                                                color={colorSet[index] + ' !important'}
                                            />
                                        </TableCell>
                                        <TableCell className={tableClasses.table__cell} style={{width: "20px"}}>
                                            <Typography className={fontClasses.text__table_cell}>{no_iteration++}</Typography>
                                        </TableCell>
                                        <TableCell
                                            className={tableClasses.table__cell}
                                        >
                                            {
                                                isDev ? (
                                                    <Link 
                                                        className={fontClasses.text__hyperlink}
                                                        target="_blank" rel="noopener noreferrer"
                                                        to={() => {
                                                            let filterProperties = {};
                                                            if(currentFilter.countryCode)
                                                                filterProperties.countryCode = currentFilter.countryCode;
                                                            
                                                            if(currentFilter.cityUuid)
                                                                filterProperties.cityUuid = currentFilter.cityUuid;
                                                            
                                                            if(currentFilter.branchUuid)
                                                                filterProperties.branchUuid = currentFilter.branchUuid;
                            
                                                            if(currentFilter.period)
                                                                filterProperties.period = currentFilter.period;
                            
                                                            if(currentFilter.dateRange){
                                                                filterProperties.start = moment(currentFilter.dateRange[0]).format('YYYY-MM-DD');
                                                                filterProperties.end = moment(currentFilter.dateRange[1]).format('YYYY-MM-DD');
                                                            }
        
                                                            if(currentFilter.hasOwnProperty("productUuid"))
                                                                filterProperties.productUuid = currentFilter.productUuid;
                                                            
                                                            if(currentFilter.hasOwnProperty('categoryUuid'))
                                                                filterProperties.categoryUuid = currentFilter.categoryUuid;
                            
                                                            if(currentFilter.hasOwnProperty('modelUuid'))
                                                                filterProperties.modelUuid = currentFilter.modelUuid;
                                                            
                                                            if(currentFilter.hasOwnProperty("brandUuid"))
                                                                filterProperties.brandUuid = currentFilter.brandUuid;
        
                                                            if(row.campaign_type)
                                                                filterProperties.campaignType = row.campaign_type;
                                                            
                                                            let url = drilldownPageSelection(filterProperties, pageType, true);
                                                            return url;
                                                        }}
                                                    >
                                                        {translate(row.name)}
                                                    </Link>
                                                ) : (
                                                    <Typography 
                                                        className={fontClasses.text__table_cell}
                                                    >
                                                        {translate(row.name)}
                                                    </Typography>
                                                )
                                            }

                                        </TableCell>
                                        <TableCell
                                            className={tableClasses.table__cell}
                                            size="small"
                                            align="right"
                                            style={{
                                                maxWidth: '180px',
                                                width: '180px',
                                                
                                            }}
                                        >
                                            <Typography className={fontClasses.text__table_cell}>{row.engagement}</Typography>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        )
    }

    return (
        <ContentCard id="campaign-conversion">
            <Box marginBottom="24px" display="flex" justifyContent="space-between" alignContent="center">
                <Typography className={fontClasses.text__card_title}>
                    {translate("label.CAMPAIGN_CONVERSION")}
                </Typography>

                <Box display="flex" alignContent="center">
                    <IconButton title="Download Charts" aria-label="download" onClick={handleDownloadCampaignCard} disableRipple>
                        <DownloadIcon />
                    </IconButton>
                
                    <ToggleButtonGroup
                        value={chartTypeSelection}
                        exclusive
                        onChange={handleChartTypeSelection}
                    >
                        <ToggleButton value="stacked-bar" onClick={() => setStacked(true)}>
                            <StackedBarIcon />
                        </ToggleButton>
                        <ToggleButton value="bar" onClick={() => setStacked(false)}>
                            <VerticalBarChartIcon />
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Box>
            </Box>
            {
                responseHandler(
                    data.campaignConversionData,
                    status.isFetchingCampaignConversionData,
                    displayData(data.campaignConversionData),
                    <BoxLoading />,
                    <CampaignConversionsNoData 
                        title={translate("label.CAMPAIGN_NOT_FOUND")}
                        value={translate("paragraph.NO_DATA_ON_SELECTED_FILTER")}
                        translate={translate}
                    />,
                    <CampaignConversionsNoResult 
                        title={translate("label.CAMPAIGN_NOT_FOUND")}
                        value={translate("paragraph.NO_DATA_ON_SELECTED_FILTER")}
                        translate={translate}
                    />,
                    <CampaignConversionsNoResult 
                        title={translate("label.LOADING_FAILED")}
                        value={translate("paragraph.LOADING_FAILED_DESCRIPTION")}
                        translate={translate}
                    />
                )
            }
        </ContentCard>
    )
} 