import React from "react";
import SummaryByDealerTableComponent from "./summaryByDealerTable.component";

export default function SummaryByDealerTableContainer({
  hasStockflowInventoryViewAccessRight,
  rows,
  isFetching,
  selectedIds,
  isSelectAll,
  isError,
  handleReload,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
  isSearchResult,
  totalFiltered,
  handleSelectProduct,
  handleSelectAvailable,
  handleSelectAll,
  handleDeselectAll,
  token,
  paginationOptions,
  lang,
  isDealerPage = false,
  dealerId,
  handleViewDetail,
  tableFilter,
  changeSequenceOrder
}) {
  return (
    <SummaryByDealerTableComponent
      hasStockflowInventoryViewAccessRight={
        hasStockflowInventoryViewAccessRight
      }
      rows={rows}
      isFetching={isFetching}
      isSelectAll={isSelectAll}
      selectedIds={selectedIds}
      isError={isError}
      handleReload={handleReload}
      page={page}
      rowsPerPage={rowsPerPage}
      handleChangePage={handleChangePage}
      handleChangeRowPerPage={handleChangeRowsPerPage}
      isSearchResult={isSearchResult}
      totalFiltered={totalFiltered}
      handleSelectProduct={handleSelectProduct}
      handleSelectAvailable={handleSelectAvailable}
      handleSelectAll={handleSelectAll}
      handleDeselectAll={handleDeselectAll}
      token={token}
      paginationOptions={paginationOptions}
      lang={lang}
      isDealerPage={isDealerPage}
      dealerId={dealerId}
      handleViewDetail={handleViewDetail}
      tableFilter={tableFilter}
      changeSequenceOrder={changeSequenceOrder}
    />
  );
}
