import axios from "axios"

const url = "/distributor/admin/api/v1/dealers"

const get = (uuid) => {
  return new Promise((resolve, reject) => {
    axios.get(`${url}/${uuid}/get-point`)
      .then(response => resolve(response))
      .catch(error => reject(error.response));
  })
}

const post = ({
  uuid,
  value, 
  isPositive,
  remark
}) => {
  const body = {
    value,
    is_positive: isPositive,
    remark
  }
  return new Promise((resolve, reject) => {
    axios
      .post(`${url}/${uuid}/update-point`, body)
      .then((response) => resolve(response))
      .catch((error) => {
        return reject(error.response);
      });
  });
}

const incentivePoint = {
  get,
  post
}

export default incentivePoint;