import React, { useState, useEffect } from "react"
import DealerRequestPanelComponent from "./dealerRequestPanel.component"
import { useSelector } from "react-redux";
import { getLang } from "app/feature/constants";

function DealerRequestPanelContainer() {
  const lang = useSelector(state => state.constant.languages)
  const [activeIndex, setActiveIndex] = useState(null)
  const query = new URLSearchParams(window.location.search);

  let tabLabels = [
    getLang(lang, "label.NEW_ORDER"), 
    getLang(lang, "label.ACCEPTED"), 
    getLang(lang, "label.REJECTED"), 
    getLang(lang, "label.CANCELLED"), 
  ]
  
  const status = query.get("status");

  const statusList = [
    "new",
    "accepted",
    "rejected",
    "cancelled"
  ]

  useEffect(() => {
    let tempIndex= statusList.findIndex(t => t === status)
    setActiveIndex(tempIndex >= 0 ? tempIndex : 0)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status])

  const handleChange = (event, index) => {
    if (activeIndex !== index) {
      setActiveIndex(index);
      query.set('status', statusList[index]);
      query.delete('is_filter');
      query.delete('page');
      query.delete('length');
      window.history.replaceState({}, '', `?${query}`);
    }
  }

  return (
    <DealerRequestPanelComponent 
      tabLabels={tabLabels}
      activeIndex={activeIndex}
      handleChange={handleChange}
      lang={lang}
    />
  )
}

export default DealerRequestPanelContainer
