import axios from "axios";

const endpoint_url = "/payment/api/v1/transactions";

const get = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(endpoint_url)
      .then(response => {
        return resolve(response.data);
      })
      .catch(error => {
        return reject(error.message);
      });
  });
};

const transaction = {
  get
};

export default transaction;
