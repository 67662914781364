import React from "react";
import CountrySelectComponent from "./countrySelect.component";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

function CountrySelectContainer({ textFieldProps, formProps, inputProps, type }) {
  const lang = useSelector(state => state.constant.languages)

  return (
    <CountrySelectComponent
      textFieldProps={textFieldProps}
      formProps={formProps}
      inputProps={inputProps}
      type={type}
      lang={lang}
    />
  );
}

CountrySelectContainer.propTypes = {
  textFieldProps: PropTypes.object,
  formProps: PropTypes.object,
  type: PropTypes.string
};

export default CountrySelectContainer;
