import axios from "axios";

export function getIncentiveProgramList({
  length, 
  start,
  search,
  status,
  product,
}){
  const url = `/distributor/admin/api/v1/programs/list`;

  return new Promise((resolve, reject) => {
    axios.get(url, {
      params: {
        length,
        start,
        search,
        status,
        product,
      }
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data))
  })
}
