import { createSlice } from "@reduxjs/toolkit";
import { REPORTING } from "modules/reporting/constants";
import {
    getBranchSalesData,
} from "../action";

const initialState = {
    branchDatalist: {}
}

const reportingSalesSlice = createSlice({
    name: REPORTING,
    initialState,
    reducers: {
        initReportingSales: (state) => {
            state.branchDatalist = initialState.branchDatalist;
        }
    },
    extraReducers: {
        // BRANCH SALES -- START
        [getBranchSalesData.fulfilled]: (state, action) => {
            let data = action.payload.data;
            data.isSuccess = action.payload.success;
            data.noResult = (data.rows.length === 0);


            state.branchDatalist = data;
        },
        [getBranchSalesData.rejected]: (state, action) => {
            state.branchDatalist = {
                isSuccess: false,
                noResult: true,
                rows: [],
                total: 0,
            }
        },
        // BRANCH SALES -- END
    }
})

export const {
    initReportingSales
} = reportingSalesSlice.actions;

export const reportingSalesReducer = reportingSalesSlice.reducer;