import { join } from "./join.api";
import { sendResetPasswordLink } from "./sendResetPasswordLink.api";
import { validateEmail } from "./validateEmail.api";
import { validateInvitationCode } from "./validateInvitationCode.api";
import { validateEmailVerificationToken } from "./validateEmailVertificationToken.api";
import { sendVerificationEmail } from "./sendVerificationEmail.api";
import { resetPassword } from "./resetPassword.api";
import { validateResetPasswordToken } from "./validateResetPasswordToken.api";
import { verifyPhoneNumber } from "./verifyPhoneNumber.api";
import { validateOTP } from "./validateOTP.api";

const trackcoApi = {
  join,
  sendResetPasswordLink,
  sendVerificationEmail,
  validateEmail,
  validateInvitationCode,
  validateEmailVerificationToken,
  resetPassword,
  validateResetPasswordToken,
  verifyPhoneNumber,
  validateOTP
};

export default trackcoApi;