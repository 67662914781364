import * as XLSX from 'xlsx'

export default function exportJsonAsXlsx(filename, data) {
  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.json_to_sheet(data);

  const cols = Object.keys(data[0]);
  const colWidths = cols.map(col => {
    return data.reduce((maxWidth, row) => {
      const cellValue = row[col] ? row[col].toString() : '';
      return Math.max(maxWidth, cellValue.length);
    }, col.length);
  });

  worksheet['!cols'] = colWidths.map(width => ({ wch: width + 2 }));

  XLSX.utils.book_append_sheet(workbook, worksheet, 'Export');
  return XLSX.writeFile(workbook, filename);
}
