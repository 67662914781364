export function setChartColors(dataLength, dontAddUnknown = false) {
    // default colors
    let colors = [
        // first six set colour
        '#6184FF',
        '#23EAD2',
        '#35CA91',
        '#FDB022',
        '#A749F0',
        '#F9F24F' ,

        // material ui colors
        // '#FFB900',
        '#E74856',
        '#0078D7',
        '#0099BC',
        '#FF8C00',
        '#E81123',
        '#0063B1',
        '#2D7D9A',
        '#F7630C',
        '#EA005E',
        '#8E8CD8',
        '#00B7C3',
        '#CA5010',
        '#C30052',
        '#6B69D6',
        '#038387',
        '#DA3B01',
        '#E3008C',
        '#8764B8',
        '#00B294',
        '#EF6950',
        '#BF0077',
        '#744DA9',
        '#018574',
        '#D13438',
        '#C239B3',
        '#B146C2',
        '#00CC6A',
        '#498205',
        '#847545',
        '#FF4343',
        '#9A0089',
        '#881798',
        '#10893E',
        '#107C10'
    ];

    // let dataLength = data.length;
    let colorPayload = [];
    for (let i = 0; i <= dataLength - 1; i++) {
        colorPayload.push(colors[i]);
    }
    // if(!dontAddUnknown && dataLength > 1)
    //     colorPayload = addUnknownColor(colorPayload);

    // colorPayload.push(colors[dataLength]);

    if(colorPayload.length === 0)
        colorPayload.push(colors[0])
        
    return colorPayload;
}

// function addUnknownColor(data){
//     data.push('#D0D5DD');
//     return data;
// }