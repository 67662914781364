import React, { useEffect, useState, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { selectLoading } from "modules/notification"
import { getInventoryLooseItemListing } from "modules/stockflow/redux/action/stockflowInventory.action"
import LooseItemListingComponent from "./looseItemListing.component"
import { generatePaginationOptions, updateUrlQueryParam } from "lib/helper"
import { resetLooseItemList } from "modules/stockflow/redux"
import { useHistory } from "react-router-dom"
import { STOCKFLOW_INVENTORY_VIEW } from "lib/constants/accessRights"

function LooseItemListingContainer() {
  const history = useHistory()
  const dispatch = useDispatch()

  const hasStockflowInventoryViewAccessRight = useSelector(state => state.profile.accessRights.includes(STOCKFLOW_INVENTORY_VIEW));
  const lang = useSelector(state => state.constant.languages)
  const token = useSelector(state => state.session.accessToken)
  const isFetching = useSelector(state => selectLoading(state, getInventoryLooseItemListing.typePrefix))
  const totalFiltered = useSelector(state => state.stockflowInventory.totalFiltered)
  const totalRecords = useSelector(state => state.stockflowInventory.totalRecords)
  const looseItems = useSelector(state => state.stockflowInventory.looseItems)
  const isError = useSelector(state => state.stockflowInventory.isLoadingLooseItemListError)
  const isSearchResult = looseItems.length < totalRecords
  const [isFilterUpdated, setIsFilterUpdated] = useState(false)
  const [paginationOptions, setPaginationOptions] = useState([])
  const [tableFilter, setTableFilter] = useState({
    page: 0,
    rowsPerPage: 25,
    search: "",
    nodeIds: [],
    branch: []
  })

  const query = new URLSearchParams(window.location.search)
  const tableFilterInitialRef = useRef(true)

  useEffect(() => {
    return () => {
      dispatch(resetLooseItemList())
    }
  }, [dispatch])

  useEffect(() => {
    const isFilter = query.has("is_filter")
    let filter = sessionStorage.getItem("inventory_loose_item_filter");

    let temp = {...tableFilter}
    if (isFilter && filter) {
      temp = JSON.parse(filter)
      setIsFilterUpdated(true)
    }

    if (isFilter) {
      updateUrlQueryParam(null, ["is_filter"])
    }
    sessionStorage.removeItem("inventory_loose_item_filter")

    setTableFilter(temp)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!tableFilterInitialRef.current) {
      getData()
    }
    tableFilterInitialRef.current = false
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableFilter])

  useEffect(() => {
    setPaginationOptions(generatePaginationOptions(totalFiltered))
  }, [totalFiltered])

  const getData = () => {
    dispatch(getInventoryLooseItemListing({
      length: tableFilter.rowsPerPage,
      start: tableFilter.rowsPerPage * tableFilter.page,
      filter: {
        search: tableFilter.search,
        nodes: tableFilter.nodeIds
      }
    }))
  }

  const handleChangePage = (event, newPage) => {
    let newData = {
      ...tableFilter,
      page: newPage,
    }
    updateUrlQueryParam({
      page: newData.page + 1,
      length: newData.rowsPerPage
    })
    setIsFilterUpdated(true)
    setTableFilter(newData)
  }

  const handleChangeRowsPerPage = event => {
    let newData = {
      ...tableFilter,
      page: 0,
      rowsPerPage: event.target.value,
    }
    updateUrlQueryParam({
      page: newData.page + 1,
      length: newData.rowsPerPage
    })
    setIsFilterUpdated(true)
    setTableFilter(newData)
  }

  const handleSearch = (filter) => {
    let newData = {
      ...tableFilter,
      page: 0,
      search: filter.search,
      nodeIds: filter.branch.length > 0 ? filter.branch.map((br) => br.node_id) : [],
      branch: filter.branch
    }

    updateUrlQueryParam(
      { is_filter: true },
      ["page", "length"]
    )
    setIsFilterUpdated(true)
    setTableFilter(newData)
  }

  const clearSearch = () => {
    let newData = {
      ...tableFilter,
      page: 0,
      search: "",
      nodeIds: [],
      branch: []
    }
    setIsFilterUpdated(false)
    setTableFilter(newData)
  }

  const handleViewDetail = (url) => {
    if (isFilterUpdated) {
      updateUrlQueryParam({ is_filter: true })
      sessionStorage.setItem("inventory_loose_item_filter", JSON.stringify(tableFilter));
    }
    history.push(url)
  }

  return (
    <>
      <LooseItemListingComponent
        hasStockflowInventoryViewAccessRight={hasStockflowInventoryViewAccessRight}
        looseItems={looseItems}
        isFetching={isFetching}
        isError={isError}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        page={tableFilter.page}
        rowsPerPage={tableFilter.rowsPerPage}
        totalFiltered={totalFiltered}
        handleSearch={handleSearch}
        clearSearch={clearSearch}
        handleReload={getData}
        isSearchResult={isSearchResult}
        token={token}
        paginationOptions={paginationOptions}
        lang={lang}
        tableFilter={tableFilter}
        handleViewDetail={handleViewDetail}
      />
    </>
  )
}

export default LooseItemListingContainer
