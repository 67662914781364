import React from "react"
import { makeStyles, Box, Typography, Paper, TableSortLabel } from "@material-ui/core"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TablePagination from "@material-ui/core/TablePagination"
import { TablePaginationActions } from "components/tablePagination"
import DefaultImg from "assets/img/img-default.png"
import RefreshTable from "components/table/refreshTable"
import {
  StyledTableCell,
  StyledTableRow,
} from "components/table"
import { SkeletonTableRowStickyLeftRight } from "components/skeleton/skeleton.component"
import { KeyboardArrowDown } from "@material-ui/icons"
import moment from "moment"
import "moment/min/locales.min";
import { getLang } from "app/feature/constants"

const useStyle = makeStyles((theme) => ({
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    borderCollapse: "separate",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
    },
    "& th,td": {
      fontSize: ".75rem",
      borderRight: "1px solid rgba(58, 77, 84, 0.1)",
      padding: theme.spacing(1, 2, 1, 2),
    },
    "& th": {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightBold,
    },
    "& th:last-child, td:last-child": {
      width: 37,
      borderRight: "0px",
    },
    "& th th": {
      borderBottom: "none !important",
    },
    "& td td": {
      borderBottom: "none !important",
    },
  },
  emptyContainer: {
    backgroundColor: theme.palette.background.paper,
    textAlign: "center",
  },
  productPhoto: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #E4E7EC",
    width: "30px",
    height: "30px",
    marginRight: "10px", 
    "& img": {
      height: "30px",
      width: "30px",
    },
  },
}))

export default function GiftRedemptionTableComponent({
  rows,
  language,
  isFetching,
  isError,
  page,
  tableFilter,
  rowsPerPage,
  totalFiltered,
  handleChangePage,
  handleChangeRowsPerPage,
  handleReload,
  paginationOptions,
  changeOrder,
  lang
}) {
  const classes = useStyle()
  
  if(language==="ZH")
  {
    moment.locale('zh-cn')
  }else if(language==="TL")
  {
    moment.locale('tl-ph')
  }else
  {
    moment.locale(language)
  }

  const statusColor = {
    pending: "#FDB022",
    approved: "#32D583",
    rejected: "#F97066"
  }

  return (
    <>
      {!isFetching && isError && <RefreshTable handleReload={handleReload} />}
      {isFetching || rows.length > 0 ? (
        <Paper id="test"style={{overflowX: 'auto', width: "100%"}}>
          <TableContainer>
            <Table
              className={classes.table}
              aria-label="gift table"
              size="small"
              stickyHeader
            >
              <TableHead style={{ height: "45px" }}>
                <TableRow>
                  <StyledTableCell>
                    <TableSortLabel
                      active={true}
                      direction={tableFilter.order}
                      onClick={() => {
                        const order = tableFilter.order === 'asc' ? "desc" : "asc"
                        changeOrder(order)
                      }}
                      IconComponent={KeyboardArrowDown}
                    >
                      {getLang(lang, "label.DATE")}
                    </TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell style={{ minWidth: "10rem" }}>{getLang(lang, "label.GIFT")}</StyledTableCell>
                  <StyledTableCell>{getLang(lang, "label.QUANTITY")}</StyledTableCell>
                  <StyledTableCell>{getLang(lang, "label.STATUS")}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isFetching
                  ? [...new Array(5)].map((v, index) => (
                    <SkeletonTableRowStickyLeftRight key={index} columnCount={4} />
                  ))
                  : rows.map((row, index) => (
                    <StyledTableRow
                      key={index}
                      hover
                      tabIndex={-1}
                    >
                      <StyledTableCell>
                        <Typography variant="caption">
                          {moment(row.createdAt).format('lll')}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell>
                        {row.items.map((item, index) => (
                          <Box display="flex" alignItems="center" key={index} mt={index ? 1 : 0}>
                            <Box className={classes.productPhoto}>
                              {item.itemImgUrl ? (
                                <img src={item.itemImgUrl} alt={item.itemName} />
                              ) : (
                                <img src={DefaultImg} alt="emptyProduct" />
                              )}
                            </Box>
                            <Typography variant="caption">{item.itemName}</Typography>
                          </Box>
                        ))}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {row.items.map((item, index) => (
                          <Box key={index} height={30} mt={index ? 1 : 0} display="flex" alignItems="center" justifyContent="flex-end">
                            <Typography variant="caption">
                              {item.quantity ? item.quantity.toLocaleString("en-US") : 0}
                            </Typography>
                          </Box>
                        ))}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <Typography variant="caption" style={{ textTransform: "capitalize", color: statusColor[row.status] }}>
                          {getLang(lang, `label.${row.status.toUpperCase()}`)}
                        </Typography>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            style={{
              backgroundColor: "#fff",
              boxShadow: "0px -1px 0px rgba(58, 77, 84, 0.15)",
            }}
            ActionsComponent={TablePaginationActions}
            rowsPerPageOptions={paginationOptions}
            component="div"
            count={totalFiltered}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage={getLang(lang, 'label.ROWS_PER_PAGE')}
            labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${getLang(lang, 'label.OF')} ${count}`}
          />
        </Paper>
      ) : null}
      {!isFetching && !isError && rows.length === 0 && (
        <Box>
          <Table
            className={classes.table}
            aria-label="product table"
            size="small"
            stickyHeader
          >
            <TableHead style={{ height: "45px" }}>
              <TableRow>
                <StyledTableCell>{getLang(lang, "label.DATE")}</StyledTableCell>
                <StyledTableCell style={{ minWidth: "10rem" }}>{getLang(lang, "label.GIFT")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.QUANTITY")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.STATUS")}</StyledTableCell>
              </TableRow>
            </TableHead>
          </Table>
          <Box py={9} className={classes.emptyContainer}>
            <Typography variant="caption" style={{ paddingBottom: "16px", fontWeight: "bold" }}>
              {getLang(lang, "paragraph.NO_REDEMPTION_HISTORY")}
            </Typography>
          </Box>
        </Box>
      )}
    </>
  )
}
