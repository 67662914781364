import React from "react";
import DialogComponent from "./dialogHeader.component";
import PropTypes from "prop-types";

function DialogHeaderContainer({
    title = null,
    handleClose,
    ...props
}) {

    return (
        <DialogComponent
            title={title}
            handleClose={handleClose}
            {...props}
        />
    )
}

DialogHeaderContainer.propTypes = {
    title: PropTypes.string,
    handleClose: PropTypes.func,
};

export default DialogHeaderContainer;
