import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { LOGIN } from 'modules/public/config/public.route';

const PrivateRoute = ({ component: Component, isAuthenticated, ...props }) => {
    return (
        <Route {...props} render={props => (
            !isAuthenticated ?
                <Redirect to={LOGIN} />
                : <Component {...props} />
        )} />
    );
};

const mapStateToProps = state => ({
    isAuthenticated: state.session.accessToken !== '' ? true : false
});

export default connect(mapStateToProps, null)(PrivateRoute);