import React, { useState, useRef, useEffect } from "react";
import ExportExcelSelectComponent from "./exportExcelReimbursement.component";
import ExportExcelSelectPanelComponent from "./exportExcelReimbursementPanel.component";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { getLang } from "app/feature/constants";

function ExportExcelSelectContainer({
  disabled,
  style,
  placeholder = null,
  dropdownItem,
  isLoading,
  handleSelection,
}) {
  const [open, setOpen] = useState(false);
  const [isOpenList] = useState(false);
  const anchorRef = useRef(null);
  const prevOpen = useRef(open);
  const prevOpenList = useRef(isOpenList);
  const lang = useSelector((state) => state.constant.languages);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    if (prevOpenList.current === true && isOpenList === false) {
      anchorRef.current.focus();
    }

    prevOpenList.current = isOpenList;
  }, [isOpenList]);

  return (
    <>
      <ExportExcelSelectComponent
        anchorRef={anchorRef}
        isLoading={isLoading}
        handleClick={handleClick}
        placeholder={placeholder ?? getLang(lang, "label.EXPORT")}
        style={style}
        disabled={disabled}
      />
      <ExportExcelSelectPanelComponent
        anchorRef={anchorRef}
        open={open}
        handleClose={handleClose}
        dropdownItem={dropdownItem}
        isOpenList={isOpenList}
        handleSelection={(type) => {
          handleSelection(type);
          handleClose();
        }}
      />
    </>
  );
}

ExportExcelSelectContainer.propTypes = {
  placeholder: PropTypes.string,
};

export default ExportExcelSelectContainer;
