import { createAsyncThunk } from "@reduxjs/toolkit";
import { DISBURSEMENT } from "../../constants";
import accountApi from "app/api/account";

export const getCampaignTngDisbursementList = createAsyncThunk(
  `${DISBURSEMENT}/getCampaignTngDisbursementList`,
  async (payload, thunkApi) => {
    const response = accountApi
      .getCampaignTngDisbursementList(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));

    return response;
  }
);

export const getCreditBalanceReloadLog = createAsyncThunk(
  `${DISBURSEMENT}/getCreditBalanceReloadLog`,
  async (payload, thunkApi) => {
    const response = accountApi
      .getCreditBalanceReloadLog(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));

    return response;
  }
);

export const getCreditAlertInfo = createAsyncThunk(
  `${DISBURSEMENT}/getCreditAlertInfo`,
  async (_, thunkApi) => {
    const response = accountApi
      .getCreditAlertInfo()
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));

    return response;
  }
);

export const updateCreditAlertInfo = createAsyncThunk(
  `${DISBURSEMENT}/updateCreditAlertInfo`,
  async (payload, thunkApi) => {
    const response = accountApi
      .updateCreditAlertInfo(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));

    return response;
  }
);

export const getCreditOverview = createAsyncThunk(
  `${DISBURSEMENT}/getCreditOverview`,
  async (_, thunkApi) => {
    const response = accountApi
      .getCreditOverview()
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));

    return response;
  }
);

export const exportCreditReport = createAsyncThunk(
  `${DISBURSEMENT}/exportCreditReport`,
  async (payload, thunkApi) => {
    const response = accountApi
      .exportCreditReport(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));

    return response;
  }
);
