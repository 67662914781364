import React from "react";
import EllipseSvg from "assets/svg/Ellipse.svg";
import { makeStyles, Box, Typography } from "@material-ui/core";
import { purple } from "@material-ui/core/colors";
import DefaultImg from "assets/img/img-default.png";
import langHelper from "lib/langHelper";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles(theme => ({
  authContent: {
    padding: "20px 0px 0px 20px",
    backgroundImage: `url(${EllipseSvg})`,
    backgroundRepeat: "no-repeat",
    backgroundColor: theme.palette.background.paper,
    backgroundSize: "contain",
    borderRadius: theme.spacing(2)
  },
  productName: {
    fontWeight: theme.typography.fontWeightBold,
    color: purple[400],
    marginTop: theme.spacing(1)
  },
  productImageContainer: {
    height: 90,
    width: 90,
    padding: 5,
    border: "2px dashed #1FD026",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: theme.spacing(1, 0)
  },
  productImage: {
    borderRadius: "50%",
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    "& img": {
      height: "100%",
      minHeight: "100%",
      minWidth: "100%",
      objectFit: "cover",
      flexShrink: 0
    }
  },
  genuineLabel: {
    fontWeight: theme.typography.fontWeightBold,
    color: "#1FD026"
  },
  genuineBackground: {
    width: 250,
    position: "absolute",
    bottom: 0,
    right: 0
  }
}));

export default function AuthenticationComponent({
  isSnHidden,
  productName,
  picture,
  content,
  message,
  lang,
  userLanguage
}) {
  const classes = useStyle();


  return (
    <Box className={classes.authContent} mx={2} pb={message === "" ? 1 : 0}>
      <Box display="flex" style={{ gap: 10 }}>
        <Box className={classes.productImageContainer}>
          <Box className={classes.productImage}>
            <img src={picture || DefaultImg} alt="" />
          </Box>
        </Box>
        <Box pr="20px" width="180px">
          <Typography variant="body1" className={classes.productName}>
            {productName}
          </Typography>
          <Typography style={{ wordWrap: "break-word" }} display="block" variant="body2">{message}</Typography>
        </Box>
      </Box>
      <Box pb={4} display="flex" flexDirection="column" position="relative">
        {!isSnHidden && (
          <Box>
            <Typography variant="caption" color="textSecondary">
              {getLang(lang, "label.SERIAL_NUMBER")}
            </Typography>
            <Typography variant="body2">A783k929443283CEF</Typography>
          </Box>
        )}
        <Box mt={0.5}>
          <Typography variant="caption" color="textSecondary">
            {getLang(lang, "label.SCAN_COUNT")}
          </Typography>
          <Typography variant="body2">5</Typography>
        </Box>
        <img className={classes.genuineBackground} src={langHelper.getGenuineBackground(userLanguage)} alt="genuine" />
      </Box>
    </Box>
  );
}
