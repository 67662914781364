import { selectLoading } from "modules/notification";
import { getAllCampaignRespondentsData } from "modules/reporting/redux";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CampaignRespodentPanel from './campaignRespondentPanel.component';
import { unwrapResult } from "@reduxjs/toolkit";
import { useEffect } from "react";

export default function CampaignRespodentPanelContainer({
    currentFilter,
    urlCampaignRespondent,
    translate
}) {
    const dispatch = useDispatch();

    const isFetchingAllCampaignRespodents = useSelector(state => selectLoading(state, getAllCampaignRespondentsData.typePrefix));
    const allCampaignRespodents = useSelector(state => state.reporting.campaignRespondentsData);

    const [tableFilter, setTableFilter] = useState({
        page: 0,
        rowsPerPage: 25,
        search: "",
        searchBy: "name",
        orderBy: null,
        orderDirection: "asc",
    })

    useEffect(() => {
        dispatch(getAllCampaignRespondentsData({
            length: tableFilter.rowsPerPage,
            start: tableFilter.page * tableFilter.rowsPerPage,
            orderBy: tableFilter.orderBy,
            orderDirection: tableFilter.orderDirection,
            search: tableFilter.search,
            searchBy: tableFilter.searchBy,
            branch_uuid: currentFilter.branchUuid,
            period: currentFilter.period,
            start_date: moment(currentFilter.dateRange[0]).format('YYYY-MM-DD'),
            end_date: moment(currentFilter.dateRange[1]).format('YYYY-MM-DD'),
            url: urlCampaignRespondent
        })).then(unwrapResult);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, tableFilter, currentFilter]);

    const handleChangePage = (e, newPage) => {
        setTableFilter((prev) => ({
          ...prev,
          page: newPage,
        }))
    }

    const handleChangeRowPerPage = (event) => {
        setTableFilter((prev) => ({
          ...prev,
          page: 0,
          rowsPerPage: event.target.value
        }))
    }

    const handleRequestSort = (event, property) => {
        const isAsc = tableFilter.orderBy === property && tableFilter.orderDirection === 'asc';
        setTableFilter((prev) => ({
            ...prev,
            orderDirection: isAsc ? 'desc' : 'asc',
            orderBy: property,
        }))
    }

    const columns = [
        {
            title: translate("label.CONSUMER"),
            field: 'name',
            render : (row) => {
                return (
                    <>{row.title}</>
                )
            },
            size: 'medium'

        },
        {
            title: translate("label.SURVEY"),
            field: 'survey',
            render : (row) => {
                return (
                    <>{row.title}</>
                )
            },
            size: 'small'
        },
        {
            title: translate("label.SUBMITTED_DATE"),
            field: 'submitted_date',
            render : (row) => {
                return (
                    <>{row.title}</>
                )
            },
            size: 'small'
        }
    ];

    return (
        <CampaignRespodentPanel 
            status={{
                isFetchingAllCampaignRespodents,
            }}

            data={{
                allCampaignRespodents,
            }}

            sort={{
                valueToOrderBy: tableFilter.orderBy,
                orderDirection: tableFilter.orderDirection,
                handleRequestSort: handleRequestSort,
            }}

            pagination={{
                page: tableFilter.page,
                rowsPerPage: tableFilter.rowsPerPage,
                rowsPerPageOptions: [5, 10, 25],
                handleChangePage: handleChangePage,
                handleChangeRowPerPage: handleChangeRowPerPage,
            }}

            currentFilter={currentFilter}
            columns={columns}
            translate={translate}
        />
    );
}