import { createSlice } from "@reduxjs/toolkit";
import { REPORTING } from "modules/reporting/constants";

import {
    getTotalScanTrending,
    getTotalSuspendedTrending,
    getTotalDistributionPerformance,

    getSuspendedScanQrData,
    getTotalReportedTrending,
    getCampaignEngagedTrending,

    getTotalCampaignSurveyTrending,
    getCampaignTrendingData,
    getScanAgainstSuspendedData,

    getActiveDealerTrending,
    getInactiveDealerTrending,
    getDealerStockOutTrending,
    getTotalRecallTrending,

    getNewDealerTrending,
    getRequestStockoutTrending,
    getTotalPackageTrending,
    getTotalRecalledPackageTrending,

    getItemTotalPackageTrending,
    getItemTotalRequestTrending,
    getItemTotalRejectTrending,

    getTopSuspendedProductTrending,
    getTopSuspendedQrTrending,

} from "../action";

const initialState = {
    totalScanData: {},
    totalSuspendedData: {},
    totalDistributionPerformanceData: {},
    suspendedScanQrData: {},
    totalReportedData: {},
    campaignEngagedData: {},
    campaignSurveyData: {},
    campaignTrendingData: {},
    scanAgainstSuspendedData: {},
    activeDealerData: {},
    inactiveDealerData: {},
    dealerStockOutData: {},
    totalRecallData: {},
    newDealerData: {},
    requestStockoutData: {},
    totalPackageData: {},
    totalRecalledPackageData: {},
    itemTotalPackageData: {},
    itemTotalRequestData: {},
    itemTotalRejectData: {},
    topSuspendedProductData: {},
    topSuspendedQrData: {},
}

const reportingTrendingSlice = createSlice({
    name: REPORTING,
    initialState,
    reducers: {
        initReportingTrending: (state) => {
            state.totalScanData = initialState.totalScanData;
            state.totalSuspendedData = initialState.totalSuspendedData;
            state.totalDistributionPerformanceData = initialState.totalDistributionPerformanceData;
            state.suspendedScanQrData = initialState.suspendedScanQrData;
            state.totalReportedData = initialState.totalReportedData;
            state.campaignEngagedData = initialState.campaignEngagedData;
            state.campaignSurveyData = initialState.campaignSurveyData;
            state.campaignTrendingData = initialState.campaignTrendingData;
            state.activeDealerData = initialState.activeDealerData;
            state.inactiveDealerData = initialState.inactiveDealerData;
            state.dealerStockOutData = initialState.dealerStockOutData;
            state.totalRecallData = initialState.totalRecallData;
            state.newDealerData = initialState.newDealerData;
            state.requestStockoutData = initialState.requestStockoutData;
            state.totalPackageData = initialState.totalPackageData;
            state.totalRecalledPackageData = initialState.totalRecalledPackageData;
            state.itemTotalPackageData = initialState.itemTotalPackageData;
            state.itemTotalRequestData = initialState.itemTotalRequestData;
            state.itemTotalRejectData = initialState.itemTotalRejectData;
            state.topSuspendedProductData = initialState.topSuspendedProductData;
            state.topSuspendedQrData = initialState.topSuspendedQrData;
        },
        initScanAgainstSuspendedData: (state) => {
            state.scanAgainstSuspendedData = initialState.scanAgainstSuspendedData;
        }
    },
    extraReducers : {
        // (a) get trending: total scan data -- start
        [getTotalScanTrending.pending]: (state, action) => {

            state.totalScanData = {
                ...state.totalScanData,
                isLoading: true,
            }
        },
        [getTotalScanTrending.fulfilled]: (state, action) => {
            let data = action.payload.data;
            data.isSuccess = action.payload.success;
            data.noResult = false;
            data.isLoading = false;

            state.totalScanData = data;
        },
        [getTotalScanTrending.rejected]: (state, action) => {
            state.totalScanData = {
                isSuccess: false,
                noResult: true,
                isLoading: false,
                datasets: [],
                labels: []
            }
        },
        // (a) get trending: total scan data -- end

        // (b) get trending: total suspended data -- start
        [getTotalSuspendedTrending.fulfilled]: (state, action) => {
            let data = action.payload.data;
            data.isSuccess = action.payload.success;
            data.noResult = false;

            state.totalSuspendedData = data;
        },
        [getTotalSuspendedTrending.rejected]: (state, action) => {
            state.totalSuspendedData = {
                isSuccess: false,
                noResult: true,
                datasets: [],
                labels: []
            }
        },
        // (b) get trending: total suspended data -- end

        // (c) get trending: total distribution performance data -- start
        [getTotalDistributionPerformance.fulfilled]: (state, action) => {
            let data = action.payload.data;
            data.isSuccess = action.payload.success;
            data.noResult = false;

            state.totalDistributionPerformanceData = data;
        },
        [getTotalDistributionPerformance.rejected]: (state, action) => {
            state.totalDistributionPerformanceData = {
                isSuccess: false,
                noResult: true,
                datasets: [],
                labels: []
            }
        },
        // (c) get trending: total distribution performance data -- end

        // (d) SUSPENDED SCAN QR DATA -- START
        [getSuspendedScanQrData.fulfilled]: (state, action) => {
            let data = action.payload.data;
            data.isSuccess = action.payload.success;
            data.noResult = (data.datasets.length === 0);

            state.suspendedScanQrData = data;
        },
        [getSuspendedScanQrData.rejected]: (state, action) => {
            state.suspendedScanQrData = {
                isSuccess: false,
                noResult: true,
                datasets: [],
                labels: [],
            };
        },
        // (d) SUSPENDED SCAN QR DATA -- END

        [getTotalReportedTrending.fulfilled]: (state, action) => {
            let data = action.payload.data;
            data.isSuccess = action.payload.success;
            data.noResult = false;

            state.totalReportedData = data;
        },
        [getTotalReportedTrending.rejected]: (state, action) => {
            state.totalReportedData = {
                isSuccess: false,
                noResult: true,
                datasets: [],
                labels: []
            }
        },

        [getCampaignEngagedTrending.fulfilled]: (state, action) => {
            let data = action.payload.data;
            data.isSuccess = action.payload.success;
            data.noResult = false;

            state.campaignEngagedData = data;
        },
        [getCampaignEngagedTrending.rejected]: (state, action) => {
            state.campaignEngagedData = {
                isSuccess: false,
                noResult: true,
                datasets: [],
                labels: []
            }
        },

        [getTotalCampaignSurveyTrending.fulfilled]: (state, action) => {
            let data = action.payload.data;
            data.isSuccess = action.payload.success;
            data.noResult = false;

            state.campaignSurveyData = data;
        },
        [getTotalCampaignSurveyTrending.rejected]: (state, action) => {
            state.campaignSurveyData = {
                isSuccess: false,
                noResult: true,
                datasets: [],
                labels: []
            }
        },

        [getCampaignTrendingData.fulfilled]: (state, action) => {
            let data = action.payload.data;
            data.isSuccess = action.payload.success;
            data.noResult = false;

            state.campaignTrendingData = data;
        },
        [getCampaignTrendingData.rejected]: (state, action) => {
            state.campaignTrendingData = {
                isSuccess: false,
                noResult: true,
                datasets: [],
                labels: []
            }
        },

        [getScanAgainstSuspendedData.fulfilled]: (state, action) => {
            let data = action.payload.data;
            data.isSuccess = action.payload.success;
            data.noResult = false;
            
            if(data.chart){
                let count = 0;
                data.chart.datasets.forEach((dataset) => {
                    dataset.data.forEach((data) => {
                        count += data;
                    });
                });

                data.noResult = (count === 0);
            }

            state.scanAgainstSuspendedData = data;
        },
        [getScanAgainstSuspendedData.rejected]: (state, action) => {
            state.scanAgainstSuspendedData = {
                isSuccess: false,
                noResult: true,
                datasets: [],
                labels: []
            }
        },

        [getActiveDealerTrending.fulfilled]: (state, action) => {
            let data = action.payload.data;
            data.isSuccess = action.payload.success;
            data.noResult = false;

            state.activeDealerData = data;
        },
        [getActiveDealerTrending.rejected]: (state, action) => {
            state.activeDealerData = {
                isSuccess: false,
                noResult: true,
                datasets: [],
                labels: []
            }
        },

        [getInactiveDealerTrending.fulfilled]: (state, action) => {
            let data = action.payload.data;
            data.isSuccess = action.payload.success;
            data.noResult = false;

            state.inactiveDealerData = data;
        },
        [getInactiveDealerTrending.rejected]: (state, action) => {
            state.inactiveDealerData = {
                isSuccess: false,
                noResult: true,
                datasets: [],
                labels: []
            }
        },

        [getDealerStockOutTrending.fulfilled]: (state, action) => {
            let data = action.payload.data;
            data.isSuccess = action.payload.success;
            data.noResult = false;

            state.dealerStockOutData = data;
        },
        [getDealerStockOutTrending.rejected]: (state, action) => {
            state.dealerStockOutData = {
                isSuccess: false,
                noResult: true,
                datasets: [],
                labels: []
            }
        },

        [getTotalRecallTrending.fulfilled]: (state, action) => {
            let data = action.payload.data;
            data.isSuccess = action.payload.success;
            data.noResult = false;

            state.totalRecallData = data;
        },
        [getTotalRecallTrending.rejected]: (state, action) => {
            state.totalRecallData = {
                isSuccess: false,
                noResult: true,
                datasets: [],
                labels: []
            }
        },

        [getNewDealerTrending.fulfilled]: (state, action) => {
            let data = action.payload.data;
            data.isSuccess = action.payload.success;
            data.noResult = false;

            state.newDealerData = data;
        },
        [getNewDealerTrending.rejected]: (state, action) => {
            state.newDealerData = {
                isSuccess: false,
                noResult: true,
                datasets: [],
                labels: []
            }
        },

        [getRequestStockoutTrending.fulfilled]: (state, action) => {
            let data = action.payload.data;
            data.isSuccess = action.payload.success;
            data.noResult = false;

            state.requestStockoutData = data;
        },
        [getRequestStockoutTrending.rejected]: (state, action) => {
            state.requestStockoutData = {
                isSuccess: false,
                noResult: true,
                datasets: [],
                labels: []
            }
        },  

        [getTotalPackageTrending.fulfilled]: (state, action) => {
            let data = action.payload.data;
            data.isSuccess = action.payload.success;
            data.noResult = false;
            
            state.totalPackageData = data;
        },
        [getTotalPackageTrending.rejected]: (state, action) => {
            state.totalPackageData = {
                isSuccess: false,
                noResult: true,
                datasets: [],
                labels: []
            }
        },

        [getTotalRecalledPackageTrending.fulfilled]: (state, action) => {
            let data = action.payload.data;
            data.isSuccess = action.payload.success;
            data.noResult = false;
            
            state.totalRecalledPackageData = data;
        },
        [getTotalRecalledPackageTrending.rejected]: (state, action) => {
            state.totalRecalledPackageData = {
                isSuccess: false,
                noResult: true,
                datasets: [],
                labels: []
            }
        },

        [getItemTotalPackageTrending.fulfilled]: (state, action) => {
            let data = action.payload.data;
            data.isSuccess = action.payload.success;
            data.noResult = false;
            
            state.itemTotalPackageData = data;
        },
        [getItemTotalPackageTrending.rejected]: (state, action) => {
            state.itemTotalPackageData = {
                isSuccess: false,
                noResult: true,
                datasets: [],
                labels: []
            }
        },

        [getItemTotalRequestTrending.fulfilled]: (state, action) => {
            let data = action.payload.data;
            data.isSuccess = action.payload.success;
            data.noResult = false;
            
            state.itemTotalRequestData = data;
        },
        [getItemTotalRequestTrending.rejected]: (state, action) => {
            state.itemTotalRequestData = {
                isSuccess: false,
                noResult: true,
                datasets: [],
                labels: []
            }
        },

        [getItemTotalRejectTrending.fulfilled]: (state, action) => {
            let data = action.payload.data;
            data.isSuccess = action.payload.success;
            data.noResult = false;
            
            state.itemTotalRejectData = data;
        },
        [getItemTotalRejectTrending.rejected]: (state, action) => {
            state.itemTotalRejectData = {
                isSuccess: false,
                noResult: true,
                datasets: [],
                labels: []
            }
        },
        [getTopSuspendedProductTrending.fulfilled]: (state, action) => {
            let data = action.payload.data;
            data.isSuccess = action.payload.success;
            data.noResult = (data ? (data.length ? false : true) : true);

            state.topSuspendedProductData = data;
        },
        [getTopSuspendedProductTrending.rejected]: (state, action) => {
            state.topSuspendedProductData = {
                isSuccess: false,
                noResult: true,
                rows: [],
                total: 0,
            };
        },

        [getTopSuspendedQrTrending.fulfilled]: (state, action) => {
            let data = action.payload.data;
            data.isSuccess = action.payload.success;
            data.noResult = (data ? (data.length ? false : true) : true);

            state.topSuspendedQrData = data;
        },
        [getTopSuspendedQrTrending.rejected]: (state, action) => {
            state.topSuspendedQrData = {
                isSuccess: false,
                noResult: true,
                rows: [],
                total: 0,
            };
        },
    }
})

export const {
    initReportingTrending,
    initScanAgainstSuspendedData
} = reportingTrendingSlice.actions;

export const reportingTrendingReducer = reportingTrendingSlice.reducer;