import React, { useState, useEffect, useRef } from "react";
import { Grid, withStyles } from "@material-ui/core";

const style = {
  masonry: {
    display: "flex",
    flexDirection: "row",
    alignContent: "stretch",
    width: "100%",
    margin: "auto"
  },
  tile: {
    "& img": {
      width: "100%"
    }
  }
};

const Masonry = ({ container, item, ...props }) => {
  if (container && item) {
    // console.error("Only set either `container` or `tile`");
    return null;
  }
  if (container || (!container && !item)) return <Container {...props} />;
  if (item) return <Tile {...props} />;
};

const Container = props => {
  const { classes, children, theme } = props;
  const breakPoints = Object.values(theme.breakpoints.values);
  const [columns, setColumns] = useState(1);
  const masonryRef = useRef(null);

  useEffect(() => {
    onResize();
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  });

  const getColumns = w => {
    return (
      breakPoints.reduceRight((p, c, i) => {
        return c < w ? p : i;
      }, breakPoints.length) + 1
    );
  };

  const onResize = () => {
    const newColumns = getColumns(masonryRef.current.offsetWidth);
    if (newColumns !== columns) {
      setColumns(newColumns);
    }
  };

  const mapChildren = () => {
    let col = [];
    const numC = 2;
    for (let i = 0; i < numC; i++) {
      col.push([]);
    }
    return children.reduce((p, c, i) => {
      p[i % numC].push(c);
      return p;
    }, col);
  };

  return (
    <Grid container className={classes.masonry} ref={masonryRef}>
      {mapChildren().map((col, ci) => {
        return (
          <Grid item key={ci} style={{width:"50%"}}>
            <Grid container direction="column" className={classes.column}>
              {col}
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
};

const Tile = ({ classes, ...props }) => {
  return <Grid item className={classes.tile} {...props} />;
};

export default withStyles(style, { withTheme: true })(Masonry);
