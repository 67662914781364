import React, { useState } from "react";
import LanguageSelectComponent from "./languageSelect.component";
import ProductSearchPopoverComponent from "./languageSelectPopover.component";
import { useSelector } from "react-redux";
import languages from "lib/constants/languages";

function LanguageSelectContainer({
  value,
  placeholder,
  handleChange,
  disabled,
  isFetching,
  panelStyle,
  anchorOrigin,
  transformOrigin,
  PaperProps,
  style,
  textStyle,
  isEmpty,
  isClearable,
  emptyMessage,
}) {
  const lang = useSelector((state) => state.constant.languages);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [extraChip, setExtraChip] = useState({
    count: 0,
    offset: 0,
    allOverflow: false,
    overflowChild: [],
  });
  const [isSelectAll, setIsSelectAll] = useState(false);

  const adjustField = (ref) => {
    let children = ref.current?.children[0]?.children;
    let p = ref.current?.children[0];
    if (!children) return;

    if (children.length > 0) {
      // Need to exclude placeholder
      if (children[0].role == null) return;

      children = [...children];

      // max width is 90%. Use 0.9 to calc field width
      let panelWidth = ref.current?.getBoundingClientRect().width * 0.9;
      let childWidth = 0;

      // Check for no overflow child count
      let inRangeCount = children.filter((child, index) => {
        let panelContentWidth = p.getBoundingClientRect().width;
        // get exact width + padding with decimal
        childWidth +=
          parseInt(window.getComputedStyle(child).marginRight) +
          child.getBoundingClientRect().width;

        // Check if overflow
        if (panelWidth - panelContentWidth < 1) {
          // need to add 0.5 as the ellipsis padding (0.5 = ellipsis padding)
          // if the overflow happen before last child, need inlude another 10 for the ellipsis (10 = ellipsis width)
          return index !== children.length - 1
            ? childWidth + 10.5 < panelContentWidth
            : childWidth + 0.5 < panelContentWidth;
        }

        return panelWidth > panelContentWidth;
      }).length;

      if (inRangeCount > 0) {
        let lastRangeChild = children[inRangeCount - 1];

        let outRangeChild = value.slice(inRangeCount);

        // Check for overflow child count
        let extraCount = value.length - inRangeCount;
        setExtraChip({
          count: extraCount,
          offset: lastRangeChild.offsetLeft + lastRangeChild.offsetWidth,
          allOverflow: false,
          overflowChild: outRangeChild,
        });
      } else {
        setExtraChip({
          count: value.length,
          offset: 0,
          allOverflow: true,
          overflowChild: value,
        });
      }
    } else {
      setExtraChip({
        count: 0,
        offset: 0,
        allOverflow: false,
        overflowChild: [],
      });
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAll = async () => {
    if (!isSelectAll) {
      handleChange(languages);
    } else {
      handleChange([languages.find((v) => v.code === "EN")]);
    }
    setIsSelectAll(!isSelectAll);
  };

  const handleSelectItem = (item) => {
    if (item.code !== "EN") {
      if (value.find((v) => v.code === item.code)) {
        setIsSelectAll(false);
        handleChange(value.filter((v) => v.code !== item.code));
      } else {
        let temp = [...value, item];
        if (temp.length === languages.length) {
          setIsSelectAll(true);
        }
        handleChange(temp);
      }
    }
  };

  const handleChipDelete = (item) => {
    if (item.code !== "EN") {
      handleChange(value.filter((v) => v.code === item.code));
      setIsSelectAll(false);
    }
  };

  const handleClear = () => {
    handleChange([]);
    setIsSelectAll(false);
  };

  return (
    <>
      <LanguageSelectComponent
        handleClick={handleClick}
        handleClear={handleClear}
        value={value}
        isEmpty={isEmpty}
        style={style}
        disabled={disabled}
        placeholder={placeholder}
        open={open}
        handleChange={handleSelectItem}
        textStyle={textStyle}
        isClearable={isClearable}
        handleChipDelete={handleChipDelete}
        extraChip={extraChip}
        adjustField={adjustField}
        lang={lang}
      />
      <ProductSearchPopoverComponent
        anchorEl={anchorEl}
        open={open}
        handleClose={handleClose}
        style={panelStyle}
        items={languages}
        isFetching={isFetching}
        handleChange={handleSelectItem}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        PaperProps={PaperProps}
        value={value}
        emptyMessage={emptyMessage}
        lang={lang}
        handleAll={handleAll}
        selectAll={isSelectAll}
      />
    </>
  );
}

export default LanguageSelectContainer;
