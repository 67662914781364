import { Box, Button, CircularProgress, withStyles } from "@material-ui/core";
import React from "react";

const CustomButton = withStyles((theme) => ({
    root: {
        padding: "10px 20px",
        border: "1px solid #E0E0E0",
        borderRadius: "4px",
    },
    label: {
        fontSize: "12px",
        fontFamily: "Roboto",
        fontWeight: 400,
        color: "#344054",
        lineHeight: "14.4px",
        textTransform: "none",
    },
}))(Button);

export default function FilterLoadingComponent(){
    // const classes = useStyles();
    return (
        <Box
            style={{
                padding: "12px",
            }}
        >
            <CustomButton>
                <CircularProgress size={20}/>
            </CustomButton>
        </Box>

    )
}