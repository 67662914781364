import axios from "axios";

const connection = process.env.REACT_APP_AUTH_CONNECTION
const connectionId = process.env.REACT_APP_AUTH_CONNECTION_ID

export default function verifyEmail({
    email,
    token
}) {
    return axios.post(`/authentication/api/basic/validate-email-verification-token-and-verify-email`,
        {
            connection,
            connection_id: connectionId,
            email,
            email_verification_token: token
        })
}