import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { SURVEY_VIEW, SURVEY_ADD } from "lib/constants/accessRights"
import SurveyListingComponent from './surveyListing.page'
import LoadingComponent from "components/loading";

export default function SurveyListingContainer() {
  const history = useHistory()
  const hasSurveyViewAccessRight = useSelector(state => state.profile.accessRights.includes(SURVEY_VIEW));
  const hasSurveyAddAccessRight = useSelector(state => state.profile.accessRights.includes(SURVEY_ADD));
  const lang = useSelector(state => state.constant.languages);

  useEffect(() => {
    if (!hasSurveyViewAccessRight) {
      history.push('/admin/dashboard')
    }
  }, [history, hasSurveyViewAccessRight])

  if(!hasSurveyViewAccessRight) return <LoadingComponent />;

  return (
    <SurveyListingComponent
      hasSurveyAddAccessRight={hasSurveyAddAccessRight}
      lang={lang}
    />
  )
}