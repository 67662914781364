/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { selectLoading } from "../../../../notification";
import {
  clearConsumerClaimsTable,
  exportClaim,
  fetchConsumerClaimTable,
} from "../../../../warranty/redux";
import ConsumerClaimPanelComponent from "./consumerClaimPanel.component";
import { unwrapResult } from "@reduxjs/toolkit";

export default function ConsumerClaimPanelContainer({ lang }) {
  const dispatch = useDispatch();

  const claimTable = useSelector((state) => state.warranty.consumerClaimTable);
  const isFetching = useSelector((state) =>
    selectLoading(state, fetchConsumerClaimTable.typePrefix)
  );
  const token = useSelector((state) => state.session.accessToken);
  const [isExporting, setIsExporting] = useState(false);

  const [page, setPage] = useState(0);
  const rowPerPageOption = [25, 50, 100, 200, 400];
  const [rowPerPage, setRowPerPage] = useState(rowPerPageOption[0]);

  const [pageSetting, setPageSetting] = useState({
    length: 25,
    start: 0,
    search: "",
    searchColumn: "warrantee_name",
    status: 0,
    fromDate: null,
    toDate: null,
  });

  const fetchClaimTable = (payload) => {
    dispatch(fetchConsumerClaimTable(payload));
  };

  useEffect(() => {
    fetchClaimTable({ ...pageSetting });

    return () => {
      dispatch(clearConsumerClaimsTable());
    };

    // eslint-disable-next-line
  }, [dispatch]);

  const handleSearch = async (value) => {
    const temp = {
      ...pageSetting,
      start: 0,
      search: value.search,
      searchColumn: value.searchColumn,
      status: value.status,
      fromDate: value.dateRange?.from,
      toDate: value.dateRange?.to,
    };

    await setPageSetting(temp);
    fetchClaimTable({ ...temp });
  };

  const handleReload = async () => {
    const temp = {
      length: 25,
      start: 0,
      search: "",
      searchColumn: "warrantee_name",
      status: 0,
      fromDate: null,
      toDate: null,
    };

    await setPageSetting(temp);
    fetchClaimTable({ ...temp });
  };

  const handlePageChange = async (_, value) => {
    await setPage(value);

    const temp = {
      ...pageSetting,
      start: value * rowPerPage,
    };

    await setPageSetting(temp);
    fetchClaimTable({ ...temp });
  };

  const handleRowPerPageChange = async (value) => {
    await setRowPerPage(value.target.value);

    const temp = {
      ...pageSetting,
      start: 0,
      length: value.target.value,
    };

    await setPageSetting(temp);
    fetchClaimTable({ ...temp });
  };

  const handleProcessed = async () => {
    fetchClaimTable({ ...pageSetting });
  };

  const handleExport = (type) => {
    setIsExporting(true);
    const info = {
      token: token,
      type: type,
      ...pageSetting,
    };
    dispatch(exportClaim(info))
      .then(unwrapResult)
      .finally(() => {
        setIsExporting(false);
      });
  };

  return (
    <ConsumerClaimPanelComponent
      lang={lang}
      isFetching={isFetching}
      //  SEARCH & FILTER  //
      pageSetting={pageSetting}
      handleSearch={handleSearch}
      handleProcessed={handleProcessed}
      search={pageSetting.search}
      //  TABLE LIST  //
      rows={claimTable.data}
      handleReload={handleReload}
      //  PAGINATION  //
      page={page}
      totalLength={claimTable.totalRecords}
      filteredLength={claimTable.filteredTotalRecords}
      rowPerPage={rowPerPage}
      rowPerPageOption={rowPerPageOption}
      handlePageChange={handlePageChange}
      handleRowPerPageChange={handleRowPerPageChange}
      isExporting={isExporting}
      handleExport={handleExport}
    />
  );
}
