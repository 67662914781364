import React from "react";
import ItemTableComponent from "./itemTable.component";
import { useSelector } from "react-redux";

export default function ItemTableContainer() {
  const data = useSelector((state) => state.redemptionCenter.order.items);
  const lang = useSelector(state => state.constant.languages)
  return (
    <ItemTableComponent
      data={data}
      lang={lang}
    />
  );
}
