import axios from "axios";

// Validate Reset Password Token 
export function verifyPhoneNumber({
  phoneCode,
  phoneNumber,
  invitationCode
}) {
  return new Promise((resolve, reject) => {
    axios.post("distributor/public/api/v2/verifyPhoneNumber", {
      phone_code: phoneCode,
      phone_number: phoneNumber,
      invitation_code: invitationCode,
    },
  )
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data))
  })
}