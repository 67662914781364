import React, { Suspense } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { 
    ROOT,
    REPORTING_DRILLDOWN_QR_TO_COUNTRY_CITY_BRANCH,
    REPORTING__QR_PAGE,
    REPORTING__PRODUCT_PAGE,
    REPORTING_DRILLDOWN_TO_COUNTRY_CITY_BRANCH_PRODUCT,
    REPORTING_DRILLDOWN_QR_TO_CCB_PRODUCT_CAMPAIGN,

    REPORTING_DRILLDOWN_QR_TO_COUNTRY_CITY_BRANCH_CAMPAIGN,

    REPORTING_DRILLDOWN_PRODUCT_CATEGORY_TO_CATEGORY,
    REPORTING_DRILLDOWN_PRODUCT_CATEGORY_TO_CCB,

    REPORTING_DRILLDOWN_PRODUCT_BRAND_TO_BRAND,
    REPORTING_DRILLDOWN_PRODUCT_BRAND_TO_CCB,

    REPORTING_DRILLDOWN_PRODUCT_MODEL_TO_MODEL,
    REPORTING_DRILLDOWN_PRODUCT_MODEL_TO_CCB,

    REPORTING_DRILLDOWN_PRODUCT_LIST_TO_CCB,

    REPORTING_DRILLDOWN_PRODUCT_SUSPENDED_TO_CCB,

    REPORTING_DRILLDOWN_CONSUMER_TO_CCB,

    REPORTING_DRILLDOWN_CAMPAIGN_SUMMARY_TO_CAMPAIGN_TYPE,
    REPORTING_DRILLDOWN_CAMPAIGN_SUMMARY_TO_CCB,
    REPORTING_DRILLDOWN_CAMPAIGN_TO_SPECIFIC,
    REPORTING_DRILLDOWN_CAMPAIGN_SPECIFIC_TO_CAMPAIGN_PRIZE,
    REPORTING_DRILLDOWN_DEALERSHIP_BY_TYPE
} from './config/reporting.route';
import { LoadingPanel } from './components/panel';
import {
    MainPage
}  from './pages';

export default function ModuleReporting() {
    return (
        <Suspense fallback={<LoadingPanel /> }>
            <Switch>
                <Route exact path={ROOT} component={MainPage} />
                {/* <Route exact path={REPORTING__PRODUCT_PAGE} component={ProductReportPage} /> */}
                {/* <Redirect exact from="/admin/reporting" to={HOME} /> */}
                <Route path="*">
                    <Redirect to="/admin/invalid" />
                </Route>
            </Switch>
        </Suspense>
    )
}

export { 
    ROOT, 
    REPORTING_DRILLDOWN_QR_TO_COUNTRY_CITY_BRANCH,
    REPORTING_DRILLDOWN_TO_COUNTRY_CITY_BRANCH_PRODUCT,
    REPORTING_DRILLDOWN_QR_TO_CCB_PRODUCT_CAMPAIGN,

    
    REPORTING_DRILLDOWN_QR_TO_COUNTRY_CITY_BRANCH_CAMPAIGN,

    REPORTING_DRILLDOWN_PRODUCT_CATEGORY_TO_CATEGORY,
    REPORTING_DRILLDOWN_PRODUCT_CATEGORY_TO_CCB,

    REPORTING_DRILLDOWN_PRODUCT_BRAND_TO_BRAND,
    REPORTING_DRILLDOWN_PRODUCT_BRAND_TO_CCB,

    REPORTING_DRILLDOWN_PRODUCT_MODEL_TO_MODEL,
    REPORTING_DRILLDOWN_PRODUCT_MODEL_TO_CCB,

    REPORTING_DRILLDOWN_PRODUCT_LIST_TO_CCB,

    REPORTING_DRILLDOWN_PRODUCT_SUSPENDED_TO_CCB,

    REPORTING_DRILLDOWN_CONSUMER_TO_CCB,

    REPORTING_DRILLDOWN_CAMPAIGN_SUMMARY_TO_CAMPAIGN_TYPE,
    REPORTING_DRILLDOWN_CAMPAIGN_SUMMARY_TO_CCB,
    REPORTING_DRILLDOWN_CAMPAIGN_TO_SPECIFIC,
    REPORTING_DRILLDOWN_CAMPAIGN_SPECIFIC_TO_CAMPAIGN_PRIZE,
    REPORTING_DRILLDOWN_DEALERSHIP_BY_TYPE,
    
    REPORTING__QR_PAGE, 
    REPORTING__PRODUCT_PAGE 
};
