import React from "react"
import { makeStyles, Typography, Box, Button } from "@material-ui/core"
import { Formik } from "formik"
import DatePicker from 'components/input/datePicker'
import moment from "moment"
import SearchableSelect from "components/select/searchableSelect"
import CampaignListTable from "../../table/campaignListTable"
import CampaignProductDropdownSearch from "../../select/campaignProductDropdownSearch"
import TagSearch from "../../select/tagSearch"
import { getLang } from "app/feature/constants"

const useStyles = makeStyles((theme) => ({
  container: {
    background: "white",
    borderRadius: 4,
    padding: "16px 20px"
  },
  filterFieldContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr 0.3fr 0.3fr",
    columnGap: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1)
    }
  },
}))

export default function CampaignListPanelComponent({
  id,
  list,
  isFetching,
  isError,
  handleChangePage,
  handleChangeRowsPerPage,
  language,
  page,
  rowsPerPage,
  totalFiltered,
  handleReload,
  paginationOptions,
  tableFilter,
  changeOrder,
  handleFilter,
  tags,
  lang,
  resetFilter,
  handleViewDetail
}) {
  const classes = useStyles()

  return (
    <>
      <Box className={classes.container}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="body1"><b>{getLang(lang, "label.CAMPAIGN_LIST")}</b></Typography>
        </Box>
        <Box className={classes.filterMainContainer}>
          <Formik
            initialValues={{
              date: tableFilter.date,
              products: tableFilter.products,
              patterns: tableFilter.patterns,
              type: tableFilter.type,
            }}
            enableReinitialize
            onSubmit={(values, actions) => {
              handleFilter(values)
              actions.setSubmitting(false)
            }}
          >
            {(formik) => (
              <form onSubmit={formik.handleSubmit}>
                <Box>
                  <Box className={classes.filterFieldContainer} style={{ marginBottom: "12px" }}>
                    <DatePicker
                      label=""
                      disabled={formik.isSubmitting || isFetching}
                      disableFuture
                      id="date-picker"
                      value={formik.values.date}
                      handleChange={(value) => {
                        formik.setFieldValue("date", value.format("YYYY-MM-DD"))
                      }}
                      format="DD MMM YYYY"
                      InputPropStyle={{
                        margin: 0,
                        height: 34
                      }}
                      inputStyle={{
                        fontWeight: "normal",
                        color: formik.values.date ? "black" : "#98A2B3",
                        fontSize: "16px",
                        paddingLeft: 5
                      }}
                      emptyLabel={getLang(lang, "placeholder.DATE")}
                      maxDate={moment().format("YYYY-MM-DD")}
                    />
                    <SearchableSelect
                      value={formik.values.type}
                      handleChange={(value) => {
                        formik.setFieldValue("type", value === "-" ? "" : value)
                      }}
                      placeholder={getLang(lang, "placeholder.CAMPAIGN_TYPE")}
                      disabled={formik.isSubmitting || isFetching}
                      searchable={false}
                      style={{ height: 34 }}
                      panelStyle={{ width: "200px" }}
                      PaperProps={{
                        classes: { root: classes.paperRoot }
                      }}
                      dropdownItem={['-', 'Instant Lucky Draw', 'Lucky Draw', 'Survey', 'Warranty']}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left"
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left"
                      }}
                      isEmpty={!formik.values.type}
                      returnType="object"
                    />
                    <CampaignProductDropdownSearch
                      placeholder={getLang(lang, "placeholder.PRODUCT")}
                      value={formik.values.products}
                      handleChange={(value) => formik.setFieldValue("products", value)}
                      id={id}
                      isEmpty={formik.values.products?.length === 0}
                      styles={{
                        padding: "0.25em 0.75em",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        borderRadius: 4,
                        width: "100%",
                        overflow: "hidden",
                      }}
                      handleChipDelete={(product) => {
                        let temp = [...formik.values.products];
                        if (temp.length > 0) {
                          const filteredProducts = temp.filter((item) => item !== product);
                          temp = [...filteredProducts];
                        }
                        formik.setFieldValue("products", temp);
                      }}
                    />
                    <TagSearch
                      placeholder={getLang(lang, "placeholder.PATTERN")}
                      tags={tags}
                      value={formik.values.patterns}
                      handleChange={(value) => {
                        formik.setFieldValue("patterns", value)
                      }}
                      isEmpty={formik.values.patterns?.length === 0}
                      styles={{
                        padding: "0.25em 0.75em",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        borderRadius: 4,
                        width: "100%",
                        overflow: "hidden",
                      }}
                      handleChipDelete={(tag) => {
                        let temp = [...formik.values.patterns];
                        if (temp.length > 0) {
                          const filteredTags = temp.filter((item) => item !== tag);
                          temp = [...filteredTags];
                        }
                        formik.setFieldValue("patterns", temp);
                      }}
                    />
                    <Button
                      variant='text'
                      disableElevation
                      color='secondary'
                      size='small'
                      onClick={resetFilter}
                      style={{
                        marginRight: "8px"
                      }}
                    >
                      {getLang(lang, "label.CLEAR")}
                    </Button>
                    <Button
                      disabled={isFetching}
                      variant="contained"
                      disableElevation
                      color="primary"
                      size='small'
                      type='submit'
                    >
                      {getLang(lang, "label.SEARCH")}
                    </Button>
                  </Box>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
        <Box mt={3}>
          <CampaignListTable
            rows={list}
            isFetching={isFetching}
            language={language}
            isError={isError}
            handleReload={handleReload}
            page={page}
            rowsPerPage={rowsPerPage}
            totalFiltered={totalFiltered}
            paginationOptions={paginationOptions}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            tableFilter={tableFilter}
            changeOrder={changeOrder}
            lang={lang}
            handleViewDetail={handleViewDetail}
          />
        </Box>
      </Box>
    </>
  )
}
