import axios from "axios";

export function getRequestStockList(parameters) {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `/distributor/admin/api/v1/request-transfer/get-products?length=${parameters.length}&start=${parameters.start}&from_node_id=${parameters.from_node_id}&to_node_id=${parameters.to_node_id}`
      )
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data));
  });
}
