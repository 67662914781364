import React from "react";
import { Box, Divider, Tabs, Typography, makeStyles } from "@material-ui/core";
import { getLang } from "app/feature/constants";
import CustomTab from "components/tab";
import ReceiveItemsProductListPanel from "../receiveItemsProductListPanel";
import ReceiveItemsBoxListPanel from "../receiveItemsBoxListPanel";

const useStyles = makeStyles((theme) => ({
  productRow: {
    border: "1px solid #D0D5DD",
    "& > div:first-child": {
      borderBottom: "1px solid #D0D5DD",
    },
    "& > div:last-child": {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      "& > div:first-child": {
        borderRight: "1px solid #D0D5DD",
      },
    },
  },
  productPhoto: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #0000001a",
    width: "45px",
    minWidth: "45px",
    height: "45px",
    borderRadius: "4px",
    "& img": {
      height: "100%",
      width: "100%",
    },
  },
  list: {
    maxHeight: "70vh",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "5px",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "16px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#E4E7EC",
      borderRadius: "16px",
    },
  },
  summaryRow: {
    backgroundColor: "#FFFFFF",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    border: "1px solid #D0D5DD",
    "& > div:first-child": {
      borderRight: "1px solid #D0D5DD",
    },
  },
  panel: {
    backgroundColor: "white",
    padding: 16,
  },
}));

export default function ReceiveItemsListPanelComponent({
  totalBox,
  totalPackage,
  totalReceiveBox,
  totalReceivePackage,
  status,
  lang,
  activeIndex,
  handleChangeType,
  statusTab,
  changeStatusTab,
  isFetching,
  list,
  totalFiltered,
  isError,
  handleFetchMoreItems,
  hasNextPage,
  handleReload,
  viewProduct,
  viewBox,
  handleSearch,
  filter,
}) {
  const classes = useStyles();

  const PARTIAL_RECEIVE = "Partial Receive";

  const tabLabels = [
    getLang(lang, "label.BOX"),
    getLang(lang, "label.PRODUCT"),
  ];

  function a11yProps(index, label = "label") {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tab-${index}`,
      label: <Typography variant="button" children={label} />,
    };
  }

  function TabPanel({ children, value, index, ...other }) {
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </Typography>
    );
  }

  return (
    <Box>
      <Box className={classes.summaryRow} mb={2}>
        <Box p={1.5}>
          <Typography variant="h6">
            <b>{totalReceiveBox.toLocaleString("en-US")}</b>
          </Typography>
          <Typography variant="body2">
            {getLang(lang, "label.TOTAL_RECEIVED_BOX")}
          </Typography>
        </Box>
        <Box p={1.5}>
          <Typography
            variant="h6"
            style={{
              color: totalBox - totalReceiveBox !== 0 ? "#F97066" : "#101828",
            }}
          >
            <b>{(totalBox - totalReceiveBox).toLocaleString("en-US")}</b>
          </Typography>
          <Typography variant="body2">
            {status === PARTIAL_RECEIVE
              ? getLang(lang, "label.TOTAL_PENDING_BOX")
              : getLang(lang, "label.TOTAL_MISSING_BOX")}
          </Typography>
        </Box>
      </Box>
      <Box className={classes.summaryRow} mb={2}>
        <Box p={1.5}>
          <Typography variant="h6">
            <b>{totalReceivePackage.toLocaleString("en-US")}</b>
          </Typography>
          <Typography variant="body2">
            {getLang(lang, "label.TOTAL_RECEIVED_SERIAL_NUMBERS")}
          </Typography>
        </Box>
        <Box p={1.5}>
          <Typography
            variant="h6"
            style={{
              color:
                totalPackage - totalReceivePackage !== 0
                  ? "#F97066"
                  : "#101828",
            }}
          >
            <b>
              {(totalPackage - totalReceivePackage).toLocaleString("en-US")}
            </b>
          </Typography>
          <Typography variant="body2">
            {status === PARTIAL_RECEIVE
              ? getLang(lang, "label.TOTAL_PENDING_SERIAL_NUMBERS")
              : getLang(lang, "label.TOTAL_MISSING_SERIAL_NUMBERS")}
          </Typography>
        </Box>
      </Box>
      <Box mb={2}>
        <Tabs
          value={activeIndex}
          onChange={handleChangeType}
          aria-label="scrollable auto tabs example"
          textColor="primary"
          variant="scrollable"
          indicatorColor="primary"
          classes={{
            indicator: classes.indicator,
            scrollButtons: classes.scroll,
          }}
        >
          {tabLabels.map((item, index) => {
            return <CustomTab key={index} {...a11yProps(index, item)} />;
          })}
        </Tabs>
        <Divider />
      </Box>
      <Box>
        {activeIndex !== null &&
          tabLabels.map((item, index) => {
            if (item === getLang(lang, "label.BOX")) {
              return (
                <TabPanel value={activeIndex} index={index} key={index}>
                  <ReceiveItemsBoxListPanel
                    lang={lang}
                    isFetching={isFetching}
                    list={list}
                    totalFiltered={totalFiltered}
                    isError={isError}
                    handleFetchMoreItems={handleFetchMoreItems}
                    viewBox={viewBox}
                    changeStatusTab={changeStatusTab}
                    statusTab={statusTab}
                    handleReload={handleReload}
                    status={status}
                    hasNextPage={hasNextPage}
                    handleSearch={handleSearch}
                    filter={filter}
                  />
                </TabPanel>
              );
            } else {
              return (
                <TabPanel value={activeIndex} index={index} key={index}>
                  <ReceiveItemsProductListPanel
                    lang={lang}
                    isFetching={isFetching}
                    list={list}
                    totalFiltered={totalFiltered}
                    isError={isError}
                    handleFetchMoreItems={handleFetchMoreItems}
                    viewProduct={viewProduct}
                    handleReload={handleReload}
                    status={status}
                    hasNextPage={hasNextPage}
                  />
                </TabPanel>
              );
            }
          })}
      </Box>
    </Box>
  );
}
