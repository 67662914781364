import axios from "axios";

// get the respondent listing based on the survey uuid
export function getRespondentListing({
  length,
  start,
  column,
  order,
  search,
  product_id,
  created_at,
  uuid
}) {
  return new Promise ((resolve, reject) => {
    axios.get(`/consumer/admin/api/v1/survey-respondents/${uuid}`, {
      params: {
        length,
        start,
        column,
        order,
        search,
        product_id,
        created_at
      }
    })
    .then(response => resolve(response.data))
    .catch(error => reject(error.response.data))
  })
}