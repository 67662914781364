import React from "react";
import CustomReasonSettingCardComponent from "./customReasonSettingCard.component";
import { useSelector } from "react-redux";
import { memo } from "react";

function CustomReasonSettingCardContainer({
  attribute,
  isEditable,
  formik,
  attributeValue,
  activeIndex,
  changeTab,
  tabLabels,
  hasEmptyReasonField,
  isSubmitting,
}) {
  const lang = useSelector((state) => state.constant.languages);

  const updateReason = (type, usedLang, reasons) => {
    let newVal = {
      ...formik.values.reasons,
      [type]: {
        usedLang: usedLang,
        reasons: reasons,
      },
    };

    formik.setFieldValue("reasons", newVal);
  };

  return (
    <CustomReasonSettingCardComponent
      attribute={attribute}
      isEditable={isEditable}
      formik={formik}
      attributeValue={attributeValue}
      lang={lang}
      activeIndex={activeIndex}
      changeTab={changeTab}
      tabLabels={tabLabels}
      updateReason={updateReason}
      hasEmptyReasonField={hasEmptyReasonField}
      isSubmitting={isSubmitting}
    />
  );
}

export default memo(CustomReasonSettingCardContainer);
