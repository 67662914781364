import React from "react";
import SetWinnerCardComponent from "./setWinnerCard.component";
import { useSelector, useDispatch } from "react-redux";
import { randomParticipant, setWinnerIntoPrize } from "../../../redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { addAlert, selectLoading } from "modules/notification";
import { getLang } from "app/feature/constants";

function SetWinnerCardContainer({ prizeDetail, prizeIndex }) {
  const dispatch = useDispatch();
  const prizeList = useSelector((state) => state.luckyDraw.prizeListTable.data);
  const winnerList = useSelector((state) => state.luckyDraw.winnerList);
  const winnerType = useSelector((state) => state.luckyDraw.winnerType);
  const luckyDrawId = useSelector((state) => state.luckyDraw.LuckyDrawDetail.id);
  const isRandomParticipantLoading = useSelector((state) => selectLoading(state, randomParticipant.typePrefix))
  const lang = useSelector(state => state.constant.languages)

  const handleRandomPickWinnerForOnePrize = (winnerIndex) => {
    const ids = [];
    for (const prize of prizeList) {
      for (const winner of prize.winners) {
        ids.push(winner.participantId);
      }
    }
    const settingRandom = {
      id: luckyDrawId,
      count: 1,
      exclude: ids
    };
    dispatch(randomParticipant(settingRandom))
      .then(unwrapResult)
      .then((winner) => {
        const info = {
          prizeIndex,
          winnerIndex,
          serialNumber: winner[0].serialNumber,
          id: winner[0].id
        };
        dispatch(setWinnerIntoPrize(info));
      })
      .catch((error) => {
        dispatch(addAlert({ severity: "error", message: getLang(lang,"message.error.NOT_ENOUGH_PARTICIPANT")}));
      });
  };

  const handleSetPrize = (value, winnerIndex, id) => {
    const info = {
      prizeIndex,
      winnerIndex,
      serialNumber: value,
      id: id
    };
    dispatch(setWinnerIntoPrize(info));
  };

  return (
    <SetWinnerCardComponent
      handleSetPrize={handleSetPrize}
      prizeDetail={prizeDetail}
      winnerList={winnerList}
      handleRandomPickWinnerForOnePrize={handleRandomPickWinnerForOnePrize}
      winnerType={winnerType}
      isRandomParticipantLoading={isRandomParticipantLoading}
      lang={lang}
    />
  );
}

export default SetWinnerCardContainer;
