import React from "react";
import { makeStyles, Box, Typography, Grid, Button } from "@material-ui/core";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary
} from "components/accordion";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AntSwitch from "components/switch/switch";
import { Formik } from "formik";
import { instantGameTemplate } from "lib/constants/instantGameTemplate";
import { PreviewSVG } from "../../../utils/static";
import { getLang } from "app/feature/constants";

const style = (theme) => ({
  root: {
    maxWidth: "100%",
    width: "1100px",
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    }
  },
  switch: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(1, 2)
  },
  gameContainer: {
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
    background: theme.palette.common.white
  },
  form: {
    height: "55vh",
    overflow: "auto"
  },
  button: {
    position: "absolute",
    borderRadius: "100px",
    margin: theme.spacing(1),
    padding: "2px 0px"
  },
  viewGameContainer: {
    border: "1px solid rgba(0, 0, 0, 0.1)",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
    margin: theme.spacing(3),
    display: "grid",
    gridTemplateColumns: "6fr 1fr"
  },
  templateContainer: {
    borderLeft: "1px solid rgba(0, 0, 0, 0.1)",
    padding: theme.spacing(2),
    textAlign: "center"
  }
});

const useStyles = makeStyles(style);

export default function InstantLuckyDrawTemplateFormComponent({
  children,
  handleSubmit,
  instantLuckyDrawDetail,
  handleDemo,
  type,
  lang
}) {
  const classes = useStyles();
  const viewType = type === "view" ? true : false;

  const getCorrectField = () => {
    const gameType = instantGameTemplate.find(
      (e) => e.name === instantLuckyDrawDetail.game.split("-")[0]
    );

    if (gameType) {
      for (const type of gameType.type) {
        if (instantLuckyDrawDetail.game.split("-")[1] === type.name) {
          return (
            <Box mb={1} className={classes.viewGameContainer}>
              <Box
                p={2}
                px={3}
                display="grid"
                gridTemplateColumns="1fr 3fr"
                alignItems="center"
              >
                {[
                  {
                    label: getLang(lang, "label.TEMPLATE_TYPE"),
                    name: gameType.label
                  },
                  {
                    label: getLang(lang, "label.TEMPLATE"),
                    name: type.label
                  },
                  {
                    label: getLang(lang, "label.PRIZE_SLOT"),
                    name: "8"
                  },
                ].map((field, index) => (
                  <React.Fragment key={index}>
                    <Box>
                      <Typography variant="body2" color="primary">
                        {field.label}
                      </Typography>
                    </Box>

                    <Box>
                      <Typography
                        variant="body2"
                        color="primary"
                        style={{ fontWeight: "bold" }}
                      >
                            {field.name === 'Wheel of Fortune' ? getLang(lang, 'label.WHEEL_OF_FORTUNE_TITLE')
                            : field.name === 'Treasure Box' ? getLang(lang, 'label.TREASURE_BOX')
                              : field.name}
                          {/* QUICK FIX FOR LANGUAGES TRANSLATION */}
                      </Typography>
                    </Box>
                  </React.Fragment>
                ))}
              </Box>

              <Box className={classes.templateContainer}>
                <Box width={100} height={100}>
                  <img
                    src={type.previewImage}
                    alt="game"
                    width="100%"
                    height="100%"
                  />
                </Box>

                <Box
                  pt={1}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    cursor: "pointer"
                  }}
                  onClick={() => {
                    handleDemo({ type: gameType.name, template: type.name })
                  }}
                >
                  <Typography
                    variant="caption"
                    color="primary"
                    style={{ fontWeight: "bold", paddingRight: "8px" }}
                  >
                    {getLang(lang, "label.PREVIEW")}
                  </Typography>
                  <PreviewSVG />
                </Box>
              </Box>
            </Box>
          );
        }
      }
    }
    return null;
  };

  return (
    <Box className={classes.root}>
      <Formik
        initialValues={{
          game: instantLuckyDrawDetail.game || ""
        }}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <Box className={classes.form}>
              {viewType ? (
                <>{getCorrectField()}</>
              ) : (
                <React.Fragment>
                  {instantGameTemplate.map((field, index) => (
                    <Accordion key={index} defaultExpanded={true}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography
                          riant="body2"
                          color="primary"
                          style={{ fontWeight: "bold" }}
                        >
                          {field.label === 'Wheel of Fortune' ? getLang(lang, 'label.WHEEL_OF_FORTUNE_TITLE')
                            : field.label === 'Treasure Box' ? getLang(lang, 'label.TREASURE_BOX')
                              : field.label}
                          {/* QUICK FIX FOR LANGUAGES TRANSLATION */}
                        </Typography>
                      </AccordionSummary>

                      <AccordionDetails>
                        <Box pb={2} px={3}>
                          <Grid container spacing={1}>
                            {field.type.map((game, index) => (
                              <Grid
                                item
                                xs={4}
                                sm={4}
                                md={4}
                                key={index}
                                style={{ width: "100%" }}
                              >
                                <Box mb={1} className={classes.gameContainer}>
                                  <div
                                    style={{
                                      display: "flex",
                                      placeContent: "flex-end"
                                    }}
                                  >
                                    <Button
                                      variant="contained"
                                      color="secondary"
                                      size="small"
                                      className={classes.button}
                                      onClick={() => {
                                        handleDemo({ type: field.name, template: game.name })
                                      }}
                                    >
                                      {getLang(lang, "label.DEMO")}
                                    </Button>
                                  </div>
                                  <img
                                    src={game.previewImage}
                                    alt="game"
                                    width="100%"
                                  />

                                  <Box className={classes.switch}>
                                    <Typography variant="body2">
                                      #{game.label}
                                    </Typography>
                                    <AntSwitch
                                      checked={
                                        formik
                                          .getFieldProps("game")
                                          .value.split("-")[1] === game.name
                                      }
                                      onChange={(e) =>
                                        formik.setFieldValue(
                                          "game",
                                          field.name + "-" + game.name
                                        )
                                      }
                                    />
                                  </Box>
                                </Box>
                              </Grid>
                            ))}
                          </Grid>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </React.Fragment>
              )}
            </Box>
            {children ? children() : null}
          </form>
        )}
      </Formik>
    </Box>
  );
}
