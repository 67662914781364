import React from "react";
import { useDispatch, useSelector } from "react-redux";
import InstantLuckyDrawFormComponent from "./instantLuckyDrawForm.component";
import { isPDF, isImage, isFileExceedLimit, isFile } from "lib/helper";
import { unwrapResult } from "@reduxjs/toolkit";
import { toBase64 } from "modules/product-mgmt/utils/productPage.util";
import { v4 as uuidv4 } from "uuid";
import { uploadFileToS3, uploadImageToS3, uploadJSONToS3 } from "app/feature";
import { FolderType, UploadType } from "lib/constants/aws_s3";
import { hideBackdrop, showBackdrop } from "modules/backdrop";
import { addAlert } from "modules/notification";
import {
  setInstantLuckyDrawDetail,
  updateInstantLuckyDrawDetail,
  fetchJSONURL
} from "modules/instant-lucky-draw/redux";
import moment from "moment";
import { isNull } from 'lodash';
import { getLang } from "app/feature/constants";
import { useState } from "react";
import {setImgFormatAndSize} from "../../../../../lib/FileHelper";

export default function InstantLuckyDrawFormContainer({
  children,
  changeBackView,
  handleNext,
  type,
  setTermConditionFile,
  termConditionFile,
  luckyDrawImageFile,
  setLuckyDrawImageFile,
}) {
  const dispatch = useDispatch();
  const id = useSelector((state) => state.session.idTokenPayload.sub);
  const instantLuckyDrawDetail = useSelector(
    (state) => state.instantLuckyDraw.InstantLuckyDrawDialogInfo.info
  );
  const lang = useSelector(state => state.constant.languages)
  const language = useSelector(state => state.profile.language)

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [isCropping, setIsCropping] = useState(false);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }

  const handleTncPdfChangePage = (isAddPage) => {
    setPageNumber(pageNumber + (isAddPage ? 1 : -1))
  }

  const handleSubmit = async (values) => {
    let tncUrl = ""
    let urlTemp = ""
    if (type !== "create") {
      if (type === "edit") {
        dispatch(showBackdrop());
        
        // if (values.productIds.length === 0) {
        //   dispatch(
        //     addAlert({
        //       severity: "error",
        //       message: getLang(lang, "message.error.PLEASE_SELECT_ONE_PRODUCT")
        //     })
        //   );
        //   dispatch(hideBackdrop());
        //   return;
        // }

        if (isImage(values.imageFile)) {
          urlTemp = await imageUploadToS3(values.imageFile)
          if (urlTemp.length > 0) {
            values.coverImageUrl = urlTemp.split("?")[0];
          }
        }
        if ((values.termsConditionDocument && isFile(values.termsConditionDetailFile)) || values.termsConditionText) {

          if (values.termsConditionDocument && isFile(values.termsConditionDetailFile) && !isPDF(values.termsConditionDetailFile)) {
            dispatch(
              addAlert({
                severity: "error",
                message: getLang(lang, "message.error.UPLOAD_PDF_ONLY")
              })
            );
            return;
          }
          else {
            tncUrl = await tncUploadToS3(values)
            if (tncUrl.length > 0) {
              values.termsAndConditions = tncUrl.split("?")[0];
            }
          }
        }

        const productId = [];
        for (const product of values.productIds) {
          productId.push(product.id);
        }

        const info = {
          instantLuckyDrawDetail: {
            ...values,
            termsAndConditions: values.termsAndConditions,
            image: values.coverImageUrl,
            id: instantLuckyDrawDetail.uuid,
            imageFile: null
          },
          productId: productId
        };

        await dispatch(updateInstantLuckyDrawDetail(info))
          .then(unwrapResult)
          .then(() => {
            let tempTncUrl = values.termsAndConditions;
            if (values.termsConditionText) {
              dispatch(fetchJSONURL(tempTncUrl))
                .then(unwrapResult)
            }
            dispatch(hideBackdrop());
            changeBackView();
          })
          .catch((error) => {
            dispatch(hideBackdrop());
            dispatch(
              addAlert({
                severity: "error",
                message: error.message || getLang(lang, "messgae.error.FAILED_UPLOAD_ILD_IMAGE")
              })
            )
          });
      }
    } else {
      if (moment(values.eventPeriodStart).isBefore(moment().format("YYYY-MM-DD")) || moment(values.eventPeriodEnd).isBefore(values.eventPeriodStart)) {
        dispatch(
          addAlert({
            severity: "error",
            message: getLang(lang, "message.error.START_DATE_END_DATE_CANT_CROSS")
          })
        );
        return;
      }

      if (isNull(values.imageFile)) {
        dispatch(
          addAlert({
            severity: "error",
            message: getLang(lang, "message.error.PLEASE_UPLOAD_IMAGE")
          })
        );
        return;
      }

      if (!isImage(values.imageFile)) {
        dispatch(
          addAlert({
            severity: "error",
            message: getLang(lang, "message.error.PLEASE_UPLOAD_VALID_IMAGE")
          })
        );
        return;
      } else {
        setLuckyDrawImageFile(values.imageFile)
      }

      if (isFileExceedLimit(values.imageFile?.size, 2, 'MB')) {
        dispatch(
          addAlert({
            severity: "error",
            message: getLang(lang, "message.error.FORM_VALIDATE_FILE_SIZE", { value: 2 })
          })
        );
        return;
      }

      const updateInfo = {
        ...values,
        game: instantLuckyDrawDetail.game,
        imageFile: null,
      };

      if (values.productIds.length === 0) {
        dispatch(
          addAlert({
            severity: "error",
            message: getLang(lang, "message.error.PLEASE_SELECT_ONE_PRODUCT")
          })
        );
        return;
      }

      if (values.termsConditionDocument && ((termConditionFile === null || termConditionFile.name !== values.termsConditionDetailFile) && !isPDF(values.termsConditionDetailFile))) {
        dispatch(addAlert({
          severity: "error",
          message: getLang(lang, "message.error.PLEASE_UPLOAD_TNC_FILE")
        }));
        return;
      }
      if (values.termsConditionDocument && ((termConditionFile === null || termConditionFile.name !== values.termsConditionDetailFile) && isPDF(values.termsConditionDetailFile) && isFileExceedLimit(values.termsConditionDetailFile, 2, "MB"))) {
        dispatch(
          addAlert({
            severity: "error",
            message: getLang(lang, "message.error.FORM_VALIDATE_FILE_SIZE", { value: 2 })
          })
        );
        return;
      }

      if (!values.termsConditionDocument && (values.termsConditionDetail.value === '<p></p>\n' || values.termsConditionDetail === "" || values.termsConditionDetail.value === '<p></p>')) {
        dispatch(addAlert({
          severity: "error",
          message: getLang(lang, "message.error.EMPTY_TNC")
        }));
        return;
      }

      if (values.termsConditionDocument && ((termConditionFile === null || termConditionFile.name !== values.termsConditionDetailFile) && isPDF(values.termsConditionDetailFile))) {
        updateInfo.termsConditionDetailFile = updateInfo.termsConditionDetailFile.name;
      }

      if (values.termsConditionDocument && ((termConditionFile === null || termConditionFile.name !== values.termsConditionDetailFile) && isPDF(values.termsConditionDetailFile))) {
        await setTermConditionFile(values.termsConditionDetailFile);
      }
      else if (!values.termsConditionDocument) {
        await setTermConditionFile(null);
      }

      await dispatch(setInstantLuckyDrawDetail(updateInfo));
      handleNext();
    }
  };

  const imageUploadToS3 = async (image) => {
    let url = ""
    const fileName = `${uuidv4()}.webp`;
    let base64Image = await toBase64(image);
    base64Image = await setImgFormatAndSize(base64Image, 2160, 2160, "image/webp");
    const uploadLuckyDrawImageData = {
      uploadType: FolderType.instantLuckyDrawImage,
      base64Image: base64Image,
      id: id,
      fileName: fileName
    };
    await dispatch(uploadImageToS3(uploadLuckyDrawImageData))
      .then(unwrapResult)
      .then(async (imageUrl) => {
        url = imageUrl;
      })
      .catch((error) => {
        dispatch(hideBackdrop());
        dispatch(
          addAlert({
            severity: "error",
            message: error.message || "Upload lucky draw image failed!"
          })
        );
      });
    return url
  }

  const tncUploadToS3 = async (values) => {
    let url = "";
    if (values.termsConditionText) {
      const fileName = "JsonFile";

      const uploadJSONdata = {
        uploadType: UploadType.instantLuckyDrawTermAndCondition,
        data: values.termsConditionDetail,
        id: id,
        fileName: `${Date.now()}${fileName}`
      }
      await dispatch(uploadJSONToS3(uploadJSONdata))
        .then(unwrapResult)
        .then((jsonUrl) => {
          url = jsonUrl
        })
        .catch(error => {
          dispatch(hideBackdrop());
          dispatch(
            addAlert({
              severity: "error",
              message: error.message || "Upload Terms & Conditions Failed"
            })
          )
        })
      return url
    }
    else {
      const fileName = values.termsConditionDetailFile.name;
      const fileType = values.termsConditionDetailFile.type;

      if (fileType !== "application/pdf") {
        dispatch(
          addAlert({
            severity: "error",
            message: getLang(lang, "message.error.UPLOAD_PDF_ONLY")
          })
        )
        return url;
      }

      const uploadPDFdata = {
        uploadType: UploadType.instantLuckyDrawTermAndCondition,
        file: values.termsConditionDetailFile,
        id: id,
        fileName: `${fileName}_${Date.now()}${fileName}`
      }
      await dispatch(uploadFileToS3(uploadPDFdata))
        .then(unwrapResult)
        .then((termUrl) => {
          url = termUrl
        })
        .catch(error => {
          dispatch(hideBackdrop());
          dispatch(
            addAlert({
              severity: "error",
              message: error.message || "Upload Terms & Conditions Failed"
            })
          )
        })
      return url
    }
  }

  const handleCloseCropping = () => setIsCropping(false);

  const handleOpenCropping = () => setIsCropping(true);

  return (
    <InstantLuckyDrawFormComponent
      children={children}
      handleSubmit={handleSubmit}
      instantLuckyDrawDetail={instantLuckyDrawDetail}
      setLuckyDrawImageFile={setLuckyDrawImageFile}
      luckyDrawImageFile={luckyDrawImageFile}
      type={type}
      language={language}
      lang={lang}
      onDocumentLoadSuccess={onDocumentLoadSuccess}
      pageNumber={pageNumber}
      numPages={numPages}
      handleTncPdfChangePage={handleTncPdfChangePage}
      handleCloseCropping={handleCloseCropping}
      handleOpenCropping={handleOpenCropping}
      isCropping={isCropping}
    />
  );
}
