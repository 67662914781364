import axios from "axios";

const endpoint_url = "/serialnumber/api/v1/warranty-settings";

const get = (parameters) => {
  return new Promise((resolve, reject) => {
    axios
      .get(endpoint_url, {
        params: {
          draw: parameters.draw,
          length: parameters.length,
          start: parameters.start,
          column: parameters.column,
          order: parameters.order,
          search: parameters.search,
          product: parameters.product,
        },
      })
      .then((response) => resolve(response))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

const getAllWarrantySetting = {
  get
};

export default getAllWarrantySetting;
