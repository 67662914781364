import React from "react";
import { makeStyles, Box, Button } from "@material-ui/core";
import { Formik } from "formik";
import DateRangePicker from "components/input/dateRangePicker";
import Input from "components/input/inputTextField";
import FilterSvg from "assets/svg/filter.svg";
import { getLang } from "app/feature/constants";
import clsx from "clsx";
import OrderListTable from "../../table/orderListTable";
import OrderRequestorSelect from "../../select/orderRequestorSelect";

const useStyles = makeStyles((theme) => ({
  container: {
    background: "white",
    borderRadius: 4,
    padding: "24px 20px",
  },
  filterFieldContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    columnGap: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1),
    },
  },
  searchContainer: {
    backgroundColor: theme.palette.background.paper,
    display: "grid",
    gridTemplateColumns: "6fr 4fr 2fr 1fr 2fr",
    columnGap: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1),
    },
  },
  inputRootID: {
    paddingTop: "8px !important",
    paddingBottom: "7px !important",
    paddingLeft: "12px !important",
    paddingRight: "12px !important",
  },
  emptyField: {
    "& .MuiInputBase-root": {
      color: "#A4A6A8",
    },
  },
}));

export default function DealerOrderListPanelComponent({
  hasStockflowOrderViewAccessRight,
  hasStockflowDealerViewAccessRight,
  id,
  type,
  language,
  orders,
  isFetching,
  isError,
  handleChangePage,
  handleChangeRowsPerPage,
  totalFiltered,
  page,
  rowsPerPage,
  handleSearch,
  clearSearch,
  handleReload,
  isSearchResult,
  paginationOptions,
  isFilterOpen,
  setIsFilterOpen,
  tableFilter,
  changeOrder,
  lang,
  resetFilter,
  handleViewDetail,
}) {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.container}>
        <Formik
          initialValues={{
            search: tableFilter.search,
            dateRange: {
              from: tableFilter.fromDate,
              to: tableFilter.toDate,
            },
            respondDate: tableFilter.respondDate,
            requestFrom: tableFilter.requestFrom,
          }}
          enableReinitialize
          onSubmit={(values, actions) => {
            handleSearch(values);
            actions.setSubmitting(false);
          }}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <Box mb={2} className={classes.searchContainer}>
                <Input
                  hiddenLabel
                  variant="filled"
                  size="small"
                  placeholder={getLang(lang, "placeholder.SEARCH_ORDER_ID")}
                  margin="none"
                  InputProps={{
                    disableUnderline: true,
                    margin: "none",
                    style: {
                      borderRadius: "4px",
                      backgroundColor: "#eceff0",
                      minWidth: "50px",
                      height: "100%",
                    },
                    classes: { input: classes.inputRootID },
                  }}
                  {...formik.getFieldProps("search")}
                />
                <DateRangePicker
                  label=""
                  placeholder={getLang(lang, "placeholder.REQUESTED_DATE")}
                  disabled={isFetching}
                  disableFuture={false}
                  className={clsx({
                    [classes.emptyField]:
                      !formik.getFieldProps("dateRange").value,
                  })}
                  id={"created-picker-dialog"}
                  value={formik.getFieldProps("dateRange").value}
                  handleChange={(v) => {
                    formik.setFieldValue("dateRange", v);
                  }}
                  InputPropStyle={{
                    color: !formik.getFieldProps("dateRange").value
                      ? "#A4A6A8"
                      : "#000000",
                    fontWeight: "normal",
                  }}
                  inputProps={{
                    fontWeight: "400",
                    fontSize: "16px",
                  }}
                  title={getLang(lang, "placeholder.REQUESTED_DATE")}
                />
                <Button
                  variant="outlined"
                  size="small"
                  disableElevation
                  onClick={() => setIsFilterOpen(!isFilterOpen)}
                  startIcon={<img src={FilterSvg} alt="filter svg" />}
                >
                  {getLang(lang, "label.ADD_FILTER")}
                </Button>
                <Button
                  variant="text"
                  disableElevation
                  color="secondary"
                  size="small"
                  onClick={() => {
                    clearSearch();
                  }}
                >
                  {getLang(lang, "label.CLEAR")}
                </Button>
                <Button
                  disabled={isFetching}
                  variant="contained"
                  disableElevation
                  color="primary"
                  size="small"
                  type="submit"
                >
                  {getLang(lang, "label.SEARCH")}
                </Button>
              </Box>
              {isFilterOpen && (
                <Box className={classes.filterMainContainer}>
                  <Box
                    className={classes.filterFieldContainer}
                    style={{ marginBottom: "12px" }}
                  >
                    <OrderRequestorSelect
                      value={formik.values.requestFrom}
                      handleChange={(value) =>
                        formik.setFieldValue("requestFrom", value)
                      }
                      placeholder={getLang(lang, "placeholder.REQUEST_FROM")}
                      disabled={isFetching}
                      type={type}
                      dealerUuid={id}
                    />
                    {type !== "new" && (
                      <DateRangePicker
                        label=""
                        disabled={formik.isSubmitting || isFetching}
                        placeholder={getLang(lang, "placeholder.RESPOND_DATE")}
                        disableFuture={false}
                        id="date-picker"
                        className={clsx({
                          [classes.emptyField]:
                            !formik.getFieldProps("respondDate").value,
                        })}
                        value={formik.values.respondDate}
                        handleChange={(value) => {
                          formik.setFieldValue("respondDate", value);
                        }}
                        InputPropStyle={{
                          color: !formik.getFieldProps("respondDate").value
                            ? "#A4A6A8"
                            : "#000000",
                          fontWeight: "normal",
                        }}
                        inputProps={{
                          fontWeight: "400",
                          fontSize: "16px",
                        }}
                      />
                    )}
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      variant="text"
                      disableElevation
                      color="secondary"
                      size="small"
                      onClick={resetFilter}
                      style={{
                        marginRight: "8px",
                      }}
                    >
                      {getLang(lang, "label.CLEAR")}
                    </Button>
                    <Button
                      disabled={isFetching}
                      variant="contained"
                      disableElevation
                      color="secondary"
                      size="small"
                      type="submit"
                    >
                      {getLang(lang, "label.APPLY_FILTER")}
                    </Button>
                  </Box>
                </Box>
              )}
            </form>
          )}
        </Formik>

        <Box mt={3}>
          <OrderListTable
            hasStockflowOrderViewAccessRight={hasStockflowOrderViewAccessRight}
            hasStockflowDealerViewAccessRight={
              hasStockflowDealerViewAccessRight
            }
            isDealerPage={true}
            displayRequestOnly={false}
            type={type}
            rows={orders}
            isFetching={isFetching}
            isError={isError}
            handleReload={handleReload}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            language={language}
            isSearchResult={isSearchResult}
            totalFiltered={totalFiltered}
            paginationOptions={paginationOptions}
            tableFilter={tableFilter}
            changeOrder={changeOrder}
            lang={lang}
            handleViewDetail={handleViewDetail}
          />
        </Box>
      </Box>
    </>
  );
}
