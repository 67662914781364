import React from "react";
import { makeStyles, Typography, ButtonBase, Box } from "@material-ui/core";
import { ArrowDropDown as ArrowDropDownIcon } from "@material-ui/icons";
import clsx from "clsx";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles((theme) => ({
  root: {
    padding: "5px 12px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#ECEFF0",
    borderRadius: "4px",
    width: "100%",
  },
  inputText: {
    fontSize: "0.875rem",
    color: "#A4A6A8",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontWeight: 500
  },
  filled: {
    color: "rgba(0, 0, 0, 0.87)",
  },
  tablePaper: {
    height: 400,
  },
  adornmentIcon: {
    color: "#707070",
  },
  input: {
    paddingRight: theme.spacing(1),
    cursor: "pointer !important",
  },
  paper: {
    width: 425,
  },
  tableContainer: {
    maxHeight: 300,
  },
  smallCase: {
    textTransform: "none",
  },
  greenLabel: {
    color: theme.palette.secondary.main,
  },
  list: {
    padding: "8px 16px",
  },
}));

export default function TransferReceiverSelectComponent({
  handleClick,
  anchorRef,
  disabled,
  value,
  textStyles,
  styles,
  placeholder,
  lang,
}) {
  const classes = useStyle();

  const isEmpty = value.length === 0 ? true : false;

  return (
    <ButtonBase
      className={classes.root}
      disableRipple
      ref={anchorRef}
      style={styles}
      onClick={handleClick}
      disabled={disabled}
    >
      <Box display="flex" style={{ maxWidth: "85%" }}>
        <Typography
          variant="body2"
          style={textStyles}
          className={clsx(classes.inputText, !isEmpty && classes.filled)}
        >
          {isEmpty
            ? placeholder
            : getLang(lang, "placeholder.SELECTED_RECEIVER", {
                count: value.length,
              })}
        </Typography>
      </Box>
      <ArrowDropDownIcon fontSize="small" />
    </ButtonBase>
  );
}
