import React, { useEffect } from "react";
import DetailPanelComponent from "./detailPanel.component";
import { connect, useSelector } from "react-redux";
import {
  resetCounterfeitDetail,
  updateCounterfeitReportDetail,
  getCounterfeitReportDetail,
} from "../../../redux";
import { selectLoading } from "modules/notification";
import { useHistory } from "react-router";
import { COUNTERFEIT_REPORT_EDIT } from "lib/constants/accessRights";

function DetailPanelContainer({
  CounterfeitReportDetail,
  getCounterfeitReportDetail,
  updateCounterfeitReportDetail,
  isFetching,
  isChanging
}) {
  const history = useHistory();
  const hasCounterfeitReportEditAccessRight = useSelector(state => state.profile.accessRights.includes(COUNTERFEIT_REPORT_EDIT));
  const lang = useSelector(state => state.constant.languages)
  const language = useSelector(state => state.profile.language)

  useEffect(() => {
    const refCode = history.location.pathname.substring(history.location.pathname.lastIndexOf("/")+1);
    getCounterfeitReportDetail(refCode);
  }, [getCounterfeitReportDetail, history.location.pathname]);

  useEffect(() => {
    return () => {
      resetCounterfeitDetail();
    };
  }, []);

  const handleSubmit = async values => {
    const reportData = {
      refCode: CounterfeitReportDetail.refCode,
      status: "processed",
      adminRemarks: values.adminRemarks
    }
    await updateCounterfeitReportDetail(reportData);
  };


  return (
    <DetailPanelComponent
      hasCounterfeitReportEditAccessRight={hasCounterfeitReportEditAccessRight}
      isFetching={isFetching}
      language={language}
      CounterfeitReportDetail={CounterfeitReportDetail}
      isChanging={isChanging}
      handleSubmit={handleSubmit}
      lang={lang}
    />
  );
}

const mapStateToProps = (state) => ({
  CounterfeitReportDetail: state.counterfeitReport.CounterfeitReportDetail,
  isFetching: selectLoading(state, getCounterfeitReportDetail.typePrefix),
  isChanging: selectLoading(state, updateCounterfeitReportDetail.typePrefix)
});

const mapDispatchToProps = (dispatch) => ({
  getCounterfeitReportDetail: (refCode) =>
    dispatch(getCounterfeitReportDetail(refCode)),
  resetCounterfeitDetail: () => dispatch(resetCounterfeitDetail),
  updateCounterfeitReportDetail: (counterfeitReportData) =>
    dispatch(updateCounterfeitReportDetail(counterfeitReportData)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailPanelContainer);
