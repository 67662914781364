import { Box, Divider, makeStyles, Tab, Tabs, useTheme, withStyles } from "@material-ui/core";
import { CAMPAIGN_TYPE_INSTANTLUCKYDRAW, CAMPAIGN_TYPE_LOYALTYPROGRAM, CAMPAIGN_TYPE_LUCKYDRAW, CAMPAIGN_TYPE_SUMMARY, CAMPAIGN_TYPE_SURVEY, CAMPAIGN_TYPE_WARRANTY } from "modules/reporting/constants";
import React from "react";
import SwipeableViews from "react-swipeable-views";

import { 
    SummaryPage,
    SurveyPage,
    LuckyDrawPage,
    InstantLuckyDrawPage,
    LoyaltyProgramPage,
    WarrantyPage
} from "./pages";

const useStyles = makeStyles({
    header__wrapper: {
        backgroundColor: "#FFFFFF",
        paddingTop: "0px",
        paddingLeft: "32px",
        paddingRight: "32px",

    },
    header__title: {
        fontSize: "18px",
        fontWeight: 700,
        lineHeight: "28px",
        color: "#344054",
        fontFamily: "Roboto",
    },
    fullWidth: {
        width: "100%",
        margin: "0px"
    }
})

export default function CampaignPage({
    filter,
    pageHandler,
    pageMode,
    campaignType,
    translate
}) {
    const classes = useStyles();
    const theme = useTheme();

    // page tab navigation -- START
    const CustomizeTabs = withStyles({
        indicator: {
            display: "flex",
            justifyContent: "center",
            backgroundColor: "transparent",
            "& > span": {
                maxWidth: "50px",
                width: "100%",
                backgroundColor: "#344054",
            }
        }
    })((props) => <Tabs {...props} TabIndicatorProps={{children: <span />}} />);
    
    const CustomizeTab = withStyles((theme) => ({
        root: {
            textTransform: 'none',
            color: '#344054',
            fontWeight: 500,
            fontSize: "14px",
            '&:focus': {
                opacity: 1,
            },
            minWidth: "80px",
        },
        // }))((props) => <Tab to={props.to} component={Link} disableRipple {...props} />);
    }))((props) => <Tab disableRipple {...props} />);

    const handlePageValueChange = (event, newValue) => {
        pageHandler.setCampaignPageValue(newValue);

        if(newValue === 0) {
            pageMode.setPage("campaign-summary");
            campaignType.setCampaignTypeValue(CAMPAIGN_TYPE_SUMMARY);
        } else if(newValue === 1) {
            pageMode.setPage("campaign-survey");
            campaignType.setCampaignTypeValue(CAMPAIGN_TYPE_SURVEY);
        } else if(newValue === 2) {
            pageMode.setPage("campaign-lucky-draw");
            campaignType.setCampaignTypeValue(CAMPAIGN_TYPE_LUCKYDRAW);
        } else if(newValue === 3) {
            pageMode.setPage("campaign-instant-lucky-draw");
            campaignType.setCampaignTypeValue(CAMPAIGN_TYPE_INSTANTLUCKYDRAW);
        } else if(newValue === 4) {
            pageMode.setPage("campaign-loyalty-program");
            campaignType.setCampaignTypeValue(CAMPAIGN_TYPE_LOYALTYPROGRAM);
        } else if(newValue === 5) {
            pageMode.setPage("campaign-warranty");
            campaignType.setCampaignTypeValue(CAMPAIGN_TYPE_WARRANTY);
        }
    }

    const handlePageChangeIndex = (index) => {
        pageHandler.setCampaignPageValue(index);

        if(index === 0) {
            pageMode.setPage("campaign-summary");
            campaignType.setCampaignTypeValue(CAMPAIGN_TYPE_SUMMARY);
        } else if(index === 1) {
            pageMode.setPage("campaign-survey");
            campaignType.setCampaignTypeValue(CAMPAIGN_TYPE_SURVEY);
        } else if(index === 2) {
            pageMode.setPage("campaign-lucky-draw");
            campaignType.setCampaignTypeValue(CAMPAIGN_TYPE_LUCKYDRAW);
        } else if(index === 3) {
            pageMode.setPage("campaign-instant-lucky-draw");
            campaignType.setCampaignTypeValue(CAMPAIGN_TYPE_INSTANTLUCKYDRAW);
        } else if(index === 4) {
            pageMode.setPage("campaign-loyalty-program");
            campaignType.setCampaignTypeValue(CAMPAIGN_TYPE_LOYALTYPROGRAM);
        } else if(index === 5) {
            pageMode.setPage("campaign-warranty");
            campaignType.setCampaignTypeValue(CAMPAIGN_TYPE_WARRANTY);
        }
    }

    function TabPanel(props) {
        const { children, value, index, ...other } = props;
    
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`full-width-tabpanel-${index}`}
                aria-labelledby={`full-width-tab-${index}`}
                {...other}
            >
                {value === index && (
                <Box>
                    {children}
                </Box>
                )}
            </div>
        );
    }

    return (
        <>
            <Box className={classes.header__wrapper}>

                {/* page navigation */}
                <CustomizeTabs value={pageHandler.campaignPageValue} onChange={handlePageValueChange} >
                    <CustomizeTab label={translate('label.SUMMARY')}/>
                    <CustomizeTab label={translate('label.SURVEY')}/>
                    <CustomizeTab label={translate('label.LUCKY_DRAW')} />
                    <CustomizeTab label={translate('label.INSTANT_LUCKY_DRAW')} />
                    <CustomizeTab label={translate('label.LOYALTY_PROGRAM')} />
                    <CustomizeTab label={translate('label.WARRANTY')} />
                </CustomizeTabs>
            </Box>
            <Divider className={classes.fullWidth}/>
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={pageHandler.campaignPageValue}
                onChangeIndex={handlePageChangeIndex}
            >
                <TabPanel value={pageHandler.campaignPageValue} index={0} dir={theme.direction}>
                    <SummaryPage 
                        filter={filter}
                        pageMode = {pageMode}
                        campaignType={campaignType}
                        translate={translate}
                    />
                </TabPanel>
                <TabPanel value={pageHandler.campaignPageValue} index={1} dir={theme.direction}>
                    <SurveyPage 
                        filter={filter}
                        pageMode = {pageMode}
                        campaignType={campaignType}
                        translate={translate}
                    />
                </TabPanel>
                <TabPanel value={pageHandler.campaignPageValue} index={2} dir={theme.direction}>
                    <LuckyDrawPage 
                        filter={filter}
                        pageMode = {pageMode}
                        campaignType={campaignType}
                        translate={translate}
                    />
                </TabPanel>
                <TabPanel value={pageHandler.campaignPageValue} index={3} dir={theme.direction}>
                    <InstantLuckyDrawPage 
                        filter={filter}
                        pageMode = {pageMode}
                        campaignType={campaignType}
                        translate={translate}
                    />
                </TabPanel>
                <TabPanel value={pageHandler.campaignPageValue} index={4} dir={theme.direction}>
                    <LoyaltyProgramPage 
                        filter={filter}
                        pageMode = {pageMode}
                        campaignType={campaignType}
                        translate={translate}
                    />
                </TabPanel>
                <TabPanel value={pageHandler.campaignPageValue} index={5} dir={theme.direction}>
                    <WarrantyPage 
                        filter={filter}
                        pageMode = {pageMode}
                        campaignType={campaignType}
                        translate={translate}
                    />
                </TabPanel>
            </SwipeableViews>
        </>
    )
}