import React from "react"
import {
  Box,
  makeStyles,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Checkbox,
  Tooltip
} from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { StyledTableCell, StyledTableRow } from "components/table";
import { SkeletonTableRow } from 'components/skeleton/skeleton.component';
import { TablePaginationActions } from 'components/tablePagination';
import Input from "components/input/inputTextField";
import SelectAllCheckBox from 'components/selectAllCheckbox';
import SearchableSelect from "components/select/searchableSelect";
import BulkCreateBranchSelect from "../../select/bulkCreateBranchSelect";
import { getLang } from "app/feature/constants";
import clsx from "clsx";

const useStyle = makeStyles(theme => ({
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    borderCollapse: "separate",
    width: "max-content",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0
    },
    "& th,td": {
      fontSize: ".75rem",
      borderRight: "1px solid rgba(58, 77, 84, 0.1)",
      padding: theme.spacing(1, 2, 1, 2)
    },
    "& th": {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightBold
    },
    "& th:last-child, td:last-child": {
      width: 55
    }
  },
  tableContainer: {
    maxHeight: 500,
    overflowY: "auto"
  },
  tableSection: {
    padding: "0px 32px 32px 32px",
    "& > div": {
      boxShadow: "0px 2px 5px 3px #8f8f8f21"
    }
  },
  warningText: {
    color: "#F04438"
  },
  selectAll: {
    '&.Mui-checked': {
      color: "#FDB022",
    },
  },
  selectOne: {
    '&.Mui-checked': {
      color: "#6AAF68",
    },
  },
}));

export default function GenerateProductTableComponent({
  data,
  isFetching,
  page,
  rowsPerPage,
  totalRecords,
  handleChangePage,
  handleChangeRowsPerPage,
  currencyDropdown,
  paginationOptions,
  updateProductDetail,
  handleBranchSearch,
  isSelectAll,
  handleSelectAll,
  fieldRequireError,
  lang,
  selectedIndex,
  handleSelectAvailable
}) {
  const classes = useStyle();
  const numberReg = /^-?\d*(\.\d*)?$/

  const selectAllItems = [
    {
      label: getLang(lang,"paragraph.SELECT_AVAILABLE_ITEM", {count: totalRecords > rowsPerPage ? rowsPerPage : totalRecords}),
      action: () => handleSelectAvailable(),
    },
    {
      label: getLang(lang,"paragraph.SELECT_ALL_PRODUCTS"),
      action: () => handleSelectAll(true),
    },
    {
      label: getLang(lang,"paragraph.DESELECT_ALL_PRODUCTS"),
      action: () => handleSelectAll(false),
      divider: true,
    },
  ];

  return (
    <Box className={classes.tableSection}>
      <Box>
        {totalRecords > 0 || isFetching ? (
          <TableContainer>
            <Table className={classes.table} size="small" stickHeader>
              <TableHead style={{ height: "45px" }}>
                <TableRow>
                  <StyledTableCell padding='checkbox'>
                    {/* <Checkbox
                      checked={isSelectAll}
                      indeterminate={false}
                      color="secondary"
                      onChange={() => {
                        handleSelectAll()
                      }}
                    /> */}
                    <SelectAllCheckBox
                      actionItems={selectAllItems}
                      disabled={isFetching}
                      isSelectAll={isSelectAll} 
                      selectedIdLength={selectedIndex.length}
                      totalFiltered={totalRecords}
                    />
                  </StyledTableCell>
                  <StyledTableCell style={{ minWidth: "280px" }}>{getLang(lang, "label.PRODUCT_NAME")}</StyledTableCell>
                  <StyledTableCell>{getLang(lang, "label.CATEGORY")}</StyledTableCell>
                  <StyledTableCell>{getLang(lang, "label.BRAND_MODEL")}</StyledTableCell>
                  <StyledTableCell>{getLang(lang, "label.ATTRIBUTES")}</StyledTableCell>
                  <StyledTableCell>
                    <Box display="flex">
                      <Typography style={{ marginRight: 4 }} variant="caption"><b>{getLang(lang, "label.BRANCH")}</b></Typography> 
                      <Tooltip
                        title={getLang(lang,"tooltips.CANNOT_DELETE_BRANCH")}
                        arrow
                      >
                        <InfoOutlinedIcon className={classes.helpIcon} style={{ fontSize: 18 }}/>
                      </Tooltip>
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell>{getLang(lang, "label.SKU")}</StyledTableCell>
                  <StyledTableCell>{getLang(lang, "label.PRICE")}</StyledTableCell>
                  <StyledTableCell>{getLang(lang, "label.SCAN_LIMIT")}</StyledTableCell>
                  <StyledTableCell>{getLang(lang, "label.INCENTIVE_POINT")}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isFetching ? (
                  [...new Array(5)].map((i, index) => (
                    <SkeletonTableRow key={index} columnCount={9} />
                  ))
                ) : data.map((item, index) => {
                  return (
                    <StyledTableRow key={index} hover tabIndex={-1}>
                      <StyledTableCell padding="checkbox">
                        <Checkbox
                          checked={item.isSelected}
                          className={clsx({
                            [classes.selectAll]: isSelectAll,
                            [classes.selectOne]: item.isSelected && !isSelectAll
                          })}
                          color="secondary"
                          onChange={(event) => {
                            updateProductDetail({
                              index: item.index,
                              name: "isSelected",
                              value: event.target.checked
                            })
                          }}
                        />
                      </StyledTableCell>
                      <StyledTableCell>
                        <Input
                          hiddenLabel
                          variant="filled"
                          size="small"
                          placeholder={getLang(lang, "placeholder.PRODUCT_NAME")}
                          margin="none"
                          InputProps={{
                            disableUnderline: true,
                            margin: "none",
                            style: {
                              borderRadius: "4px",
                              backgroundColor: "#fff",
                              border: "thin solid #eceff0",
                              fontSize: "0.75rem"
                            }
                          }}
                          style={{ width: "100%" }}
                          value={item.name}
                          onChange={({ target: { value } }) => {
                            updateProductDetail({
                              index: item.index,
                              name: "name",
                              value
                            })
                          }}
                          error={
                            !!fieldRequireError && !item.name
                          }
                        />
                        {!!fieldRequireError && !item.branch.length && (
                          <Box mt={0.25}>
                            <Typography variant='caption' className={classes.warningText}>{getLang(lang, "label.REQUIRED_FIELD")}</Typography>
                          </Box>
                        )}
                      </StyledTableCell>
                      <StyledTableCell>{item.category?.name || "-"}</StyledTableCell>
                      <StyledTableCell>{item.brand?.name || "No Brand"}{item.model ? `, ${item.model.name}` : ""}</StyledTableCell>
                      <StyledTableCell>
                        {item.attributes.length > 0 ? item.attributes.map((a, i) => (
                          <Typography style={{ fontSize: "0.75rem" }} key={i} display="inline">
                            {!!i && ", "}{a.name}: {a.option.value}
                          </Typography>
                        )) : (<span>-</span>)}
                      </StyledTableCell>
                      <StyledTableCell>
                        <BulkCreateBranchSelect
                          value={item.branch}
                          placeholder={getLang(lang, "placeholder.BRANCH")}
                          handleChange={(value) => {
                            updateProductDetail({
                              index: item.index,
                              name: "branch",
                              value: [...value]
                            })
                          }}
                          styles={{
                            backgroundColor: "#fff",
                            border: "thin solid #eceff0",
                          }}
                          searchable={true}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                          textStyles={{ fontSize: "0.75rem" }}
                          handleBranchSearch={handleBranchSearch}
                          showAllButton={false}
                        />
                        {!!fieldRequireError && !item.branch.length && (
                          <Box mt={0.25}>
                            <Typography variant='caption' className={classes.warningText}>{getLang(lang, "label.REQUIRED_FIELD")}</Typography>
                          </Box>
                        )}
                      </StyledTableCell>
                      <StyledTableCell>
                        <Input
                          hiddenLabel
                          variant="filled"
                          size="small"
                          placeholder={getLang(lang, "placeholder.SKU")}
                          margin="none"
                          InputProps={{
                            disableUnderline: true,
                            margin: "none",
                            style: {
                              borderRadius: "4px",
                              backgroundColor: "#fff",
                              border: "thin solid #eceff0",
                              fontSize: "0.75rem"
                            }
                          }}
                          style={{ width: "100%" }}
                          value={item.sku}
                          onChange={({ target: { value } }) => {
                            updateProductDetail({
                              index: item.index,
                              name: "sku",
                              value
                            })
                          }}
                        />
                      </StyledTableCell>
                      <StyledTableCell>
                        <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                          <SearchableSelect
                            value={item.currencyCode.alpha3}
                            handleChange={(value) => {
                              updateProductDetail({
                                index: item.index,
                                name: "currencyCode",
                                value
                              })
                            }}
                            placeholder={getLang(lang, "placeholder.CURRENCY")}
                            searchable={false}
                            style={{
                              height: "28.25px",
                              marginRight: "8px",
                              width: "130px",
                              backgroundColor: "#fff",
                              border: "thin solid #eceff0",
                            }}
                            textStyle={{ fontSize: "0.75rem" }}
                            PaperProps={{
                              classes: { root: classes.paperRoot },
                            }}
                            dropdownItem={currencyDropdown}
                            emptyMessage={getLang(lang, "paragraph.NO_CURRENCY_CODE")}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                            isEmpty={!item.currencyCode}
                            returnType="currencyCode"
                          />
                          <Input
                            hiddenLabel
                            variant="filled"
                            size="small"
                            placeholder="Price"
                            margin="none"
                            InputProps={{
                              disableUnderline: true,
                              margin: "none",
                              style: {
                                borderRadius: "4px",
                                backgroundColor: "#fff",
                                border: "thin solid #eceff0",
                                fontSize: "0.75rem"
                              }
                            }}
                            style={{ width: "100%" }}
                            value={item.price}
                            onChange={({ target: { value } }) => {
                              if (!isNaN(value) && numberReg.test(value)) {
                                updateProductDetail({
                                  index: item.index,
                                  name: "price",
                                  value
                                })
                              }
                            }}
                          />
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Input
                          hiddenLabel
                          variant="filled"
                          size="small"
                          placeholder={getLang(lang, "placeholder.SCAN_LIMIT")}
                          margin="none"
                          InputProps={{
                            disableUnderline: true,
                            margin: "none",
                            style: {
                              borderRadius: "4px",
                              backgroundColor: "#fff",
                              border: "thin solid #eceff0",
                              fontSize: "0.75rem"
                            }
                          }}
                          style={{ width: "100%" }}
                          value={item.scanLimit}
                          onChange={({ target: { value } }) => {
                            if (!isNaN(value) && numberReg.test(value)) {
                              updateProductDetail({
                                index: item.index,
                                name: "scanLimit",
                                value
                              })
                            }
                          }}
                        />
                      </StyledTableCell>
                      <StyledTableCell>
                        <Input
                          hiddenLabel
                          variant="filled"
                          size="small"
                          placeholder={getLang(lang, "placeholder.INCENTIVE_POINT")}
                          margin="none"
                          InputProps={{
                            disableUnderline: true,
                            margin: "none",
                            style: {
                              borderRadius: "4px",
                              backgroundColor: "#fff",
                              border: "thin solid #eceff0",
                              fontSize: "0.75rem"
                            }
                          }}
                          style={{ width: "100%" }}
                          value={item.incentivePoint}
                          onChange={({ target: { value } }) => {
                            if (!isNaN(value) && numberReg.test(value)) {
                              updateProductDetail({
                                index: item.index,
                                name: "incentivePoint",
                                value
                              })
                            }
                          }}
                        />
                      </StyledTableCell>
                    </StyledTableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : null}
        {!isFetching && totalRecords > 0 && (
          <TablePagination
            style={{
              backgroundColor: "#fff",
              boxShadow: "0px -1px 0px rgba(58, 77, 84, 0.15)",
            }}
            ActionsComponent={TablePaginationActions}
            rowsPerPageOptions={paginationOptions}
            component="div"
            count={totalRecords}
            rowsPerPage={rowsPerPage}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            page={page}
            labelRowsPerPage={getLang(lang, 'label.ROWS_PER_PAGE')}
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${getLang(lang, 'label.OF')} ${count}`}
          />
        )}
      </Box>
    </Box>
  )
}