import React from "react"

export default function StackedBarIcon(props) {
    return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        width="22" height="19" viewBox="0 0 200.000000 200.000000"
        preserveAspectRatio="xMidYMid meet">

        <g transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)"
        fill="#000000" stroke="none">
        <path d="M1060 1140 l0 -760 180 0 180 0 0 760 0 760 -180 0 -180 0 0 -760z"/>
        <path d="M580 1160 l0 -260 180 0 180 0 0 260 0 260 -180 0 -180 0 0 -260z"/>
        <path d="M1560 850 l0 -270 170 0 170 0 0 270 0 270 -170 0 -170 0 0 -270z"/>
        <path d="M100 700 l0 -220 170 0 170 0 0 220 0 220 -170 0 -170 0 0 -220z"/>
        <path d="M580 460 l0 -360 180 0 180 0 0 360 0 360 -180 0 -180 0 0 -360z"/>
        <path d="M1560 300 l0 -200 170 0 170 0 0 200 0 200 -170 0 -170 0 0 -200z"/>
        <path d="M100 250 l0 -150 170 0 170 0 0 150 0 150 -170 0 -170 0 0 -150z"/>
        <path d="M1060 200 l0 -100 180 0 180 0 0 100 0 100 -180 0 -180 0 0 -100z"/>
        </g>
    </svg>
    )
}
