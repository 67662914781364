import React from "react";
import CategoryTitleCard from './categoryTitleCard.component';
import { useDispatch, useSelector } from "react-redux";
import { selectLoading } from "modules/notification";
import { getProductCategoryDetailData } from "modules/reporting/redux";
import { useEffect } from "react";
import moment from "moment";

export default function CategoryTitleCardContainer({
    urlCategoryDetail,
    currentFilter,
    translate
}){
    const dispatch = useDispatch();

    // (1) get product category detail data -- start
    const isFetchingProductCategoryDetailData = useSelector(state => selectLoading(state, getProductCategoryDetailData.typePrefix));
    const productCategoryDetailData = useSelector(state => state.reporting.product.productCategoryDetailData);
    // (1) get product category detail data -- end

    useEffect(() => {
        dispatch(getProductCategoryDetailData({
            category_uuid: currentFilter.categoryUuid,
            branch_uuid: currentFilter.branchUuid,
            period: currentFilter.period,
            start_date: moment(currentFilter.dateRange[0]).format('YYYY-MM-DD'),
            end_date: moment(currentFilter.dateRange[1]).format('YYYY-MM-DD'),
            url: urlCategoryDetail
        }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentFilter, dispatch])



    return (
        <CategoryTitleCard 
            status={{
                isFetchingProductCategoryDetailData
            }}

            data={{
                productCategoryDetailData
            }}

            currentFilter={currentFilter}

            translate={translate}
        />
    )
}