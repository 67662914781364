import React, { useEffect, useState } from "react";
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  validateInvitationCode,
  setInvitationCode,
  newJoin,
  setVerificationEmail,
  verifyPhoneNumber,
  validateOTP
} from "modules/trackco/redux";
import { selectLoading } from 'modules/notification';
import SignUpPage from "./signup.page";

function SignUpContainer() {
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const history = useHistory();
  const invitationCode = match.params.invitationCode;

  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const [isConfirmPasswordShow, setIsConfirmPasswordShown] = useState(false);

  const distributor = useSelector(state => state.trackco.inviter.distributor)
  const branch = useSelector(state => state.trackco.inviter.branch);
  const lang = useSelector(state => state.constant.languages);
  const isInvitationValid = useSelector(state => state.trackco.inviter.isInvitationValid)
  const isValidateInvitationLoading = useSelector(state => selectLoading(state, validateInvitationCode.typePrefix))
  const [counter, setCounter] = useState(0);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [disableInput, setDisableInput] = useState(false);
  const [OTP, setOTP] = useState("");

  useEffect(() => {
    document.title = "Signup - Trackco @ Customer Portal";

    if (!isInvitationValid) {
      dispatch(validateInvitationCode(invitationCode))
        .then(response => {
          if (!response.payload.data.is_valid) {
            history.push("/404")
          } else {
            dispatch(setInvitationCode({ invitationCode: invitationCode }))
          }
        })
    }

  }, [invitationCode, dispatch, isInvitationValid, history]);
  useEffect(() => {
    if (counter > 0) {
      const timer = setInterval(() => setCounter(counter - 1), 1000);
      return () => clearInterval(timer)
    }
  }, [counter])

  const handleClickShowPassword = (field) => {
    if (field === "password") {
      setIsPasswordShown(prev => !prev)
    }

    if (field === "confirmPassword") {
      setIsConfirmPasswordShown(prev => !prev)
    }
  }

  const handleMouseDownPassword = event => {
    event.preventDefault()
  }

  const handleGenderChange = (event, formik) => {
    formik.setFieldValue("gender", event.target.value)
  }

  const handleRegister = async (values) => {
    values.postalCode = values.postalCode.trim();
    const payload = {
      invitationCode: invitationCode,
      email: values.email,
      password: values.password,
      confirmPassword: values.confirmPassword,
      firstName: values.firstName,
      lastName: values.lastName,
      gender: values.gender,
      countryCode: values.countryCode.phone,
      phoneNumber: values.phoneNo,
      addressLine1: values.address1,
      addressLine2: values.address2,
      city: values.city,
      state: values.state,
      country: values.country.label,
      locale: "English",
      postalCode: values.postalCode,
      dateOfBirth: values.dateOfBirth,
    }

    let result = await dispatch(newJoin(payload))

    if (result.payload.success) {
      history.push(`/trackco/signup-verification`);
      dispatch(setVerificationEmail({ email: payload.email }))
      localStorage.setItem('email', payload.email)
    }
  }
  const getOTP = () => {
    setCounter(122)
  }
  const inputOTP = (value) => {
    setOTP(value)
  }
  const displayOTPVerification = () => {
    setShowOTP(true)
  }
  const verifyOTP = async (code, number) => {
    const payload = {
      phoneCode: code,
      phoneNumber: number,
      otp: OTP,
    }
    let result = await dispatch(validateOTP(payload));
    if (result.payload.success) {
      setDisableInput(true);
    }

  }
  const verifyPhone = async (code, number) => {
    const payload = {
      phoneCode: code,
      phoneNumber: number,
      invitationCode: invitationCode,
    }
    let result = await dispatch(verifyPhoneNumber(payload));
    if (result.payload.code === "OK_PHONE_NUMBER_VALIDATED") {
      setDisableInput(true);
    }
    else if (result.payload.success) {
      displayOTPVerification();
      getOTP();
    }

  }
  return (
    <SignUpPage
      handleClickShowPassword={handleClickShowPassword}
      handleMouseDownPassword={handleMouseDownPassword}
      isConfirmPasswordShow={isConfirmPasswordShow}
      isPasswordShown={isPasswordShown}
      invitationCode={invitationCode}
      onChangeGender={handleGenderChange}
      branchDetail={branch}
      distributorDetail={distributor}
      isValidateInvitationLoading={isValidateInvitationLoading}
      isInvitationCodeValidated={isInvitationValid}
      handleSubmit={handleRegister}
      lang={lang}
      isDatePickerOpen={isDatePickerOpen}
      setIsDatePickerOpen={setIsDatePickerOpen}
      enableResent={counter === 0}
      counter={counter}
      getOTP={getOTP}
      showOTP={showOTP}
      displayOTPVerification={displayOTPVerification}
      disableInput={disableInput}
      verifyOTP={verifyOTP}
      OTP={OTP}
      setOTP={inputOTP}
      verifyPhone={verifyPhone}
    />
  )
}

export default SignUpContainer
