import axios from "axios";

const getBranchGroupDropdown = async () => {
  return new Promise((resolve, reject) => {
    axios
      .get("/account/api/v1/branch-group/dropdown")
      .then((response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

export default getBranchGroupDropdown;
