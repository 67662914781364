import * as TYPE from "../../config/profile.type";
import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { PROFILE } from "../../constants";
import accountApi from "app/api/account";
import { PUBLIC } from "modules/public/constants"
import publicApi from 'app/api/public';
/**
 * redux action using redux-toolkit
 */



export const fetchProfile = createAsyncThunk(
  `${PROFILE}/fetchProfile`,
  async (payload, thunkApi) => {
    return accountApi.profile.get()
    .then((response) => response.data)
    .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const updateProfile = createAsyncThunk(
  `${PROFILE}/update`,
  async (payload, thunkApi) => {
    const { firstName, lastName, email, contactNumber, locale, timezone, language, phoneCode } = payload;

    return accountApi.profile.put({
      first_name: firstName,
      last_name: lastName,
      email,
      phone_number: contactNumber,
      phone_code: phoneCode,
      locale,
      timezone,
      language
    })
    .then((response) => response)
    .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const updateUserProfilePicture = createAction(
  `${PROFILE}/updateProfilePicture`
);
export const getTimezone = createAction(
  `${PUBLIC}/getTimezone`
);

export const fetchTimezone = createAsyncThunk(
  `${PUBLIC}/fetchTimezone`,
  async (payload, { rejectWithValue }) => {
    return publicApi.getTimezone()
      .then(response => response)
      .catch(error => rejectWithValue(error));
  }
);

export const updateUserProfilePictureAPI = createAsyncThunk(
  `${PROFILE}/updateProfilePictureApi`,
  async (payload, { rejectWithValue }) => {

    return accountApi.profile.put({ image_profile_url: payload })
    .then((response) => response.data)
    .catch((error) => rejectWithValue(error));
  }
);

export const changePassword = createAsyncThunk(
  `${PROFILE}/changePassword`,
  async (payload, thunkApi) => {
    return accountApi.changePassword.put({
      currentPassowrd: payload.currentPassowrd,
      password: payload.password,
      confirmPassword: payload.confirmPassword
    })
      .then(response => response)
      .catch(error => thunkApi.rejectWithValue(error));;

  }
);

/**
 * old redux action, configure refactoring
 */

export const updateUserMasterInfo = payload => ({
  type: TYPE.UPDATE_USER_MASTER_INFO,
  payload
});

export const setProfileImg = payload => ({
  type: TYPE.SET_PROFILE_IMG,
  payload
});
