import React from "react";
import {
  makeStyles,
  Box,
  Button,
  Select,
  MenuItem,
  Divider,
  IconButton,
  Menu,
  Grid,
  Tooltip,
} from "@material-ui/core";
import { getLang } from "app/feature/constants";
import {
  Remove as RemoveIcon,
  Add as AddIcon,
  InfoOutlined as InfoOutlinedIcon,
} from "@material-ui/icons";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import DraggableReasonCard from "../../card/draggableReasonCard";
import { AddIcon as AddIconGreen } from "components/icon";

const style = (theme) => ({
  deleteIcon: {
    color: "#F04438",
    cursor: "pointer",
  },
});

const useStyles = makeStyles(style);

export default function CustomReasonsPanelComponent({
  lang,
  type,
  languageTabIndex,
  setLanguageTabIndex,
  usedLang,
  addableLangs,
  handleCloseAddLang,
  handleRemoveLang,
  handleAddLangClick,
  emptyErrorOnLang,
  setEmptyErrorOnLang,
  duplicateErrorOnLang,
  setDuplicateErrorOnLang,
  anchorEl,
  handleAddLang,
  reasons,
  updateReason,
  isSubmitting,
}) {
  const classes = useStyles();

  return (
    <Box>
      {usedLang.length > 0 && (
        <Box
          border={1}
          borderRadius={6}
          mt={2}
          borderColor="rgba(0, 0, 0, 0.1)"
        >
          <Box display="flex" flexDirection="row" width="100%">
            <Box flexGrow={1}>
              <Select
                disabled={isSubmitting}
                value={languageTabIndex}
                style={{
                  width: "100%",
                  height: "100%",
                }}
                renderValue={(value) => (
                  <Box ml={2}>
                    {getLang(lang, "label.LANGUAGE")}: {usedLang[value]?.label}
                  </Box>
                )}
                onChange={(event) =>
                  setLanguageTabIndex(parseInt(event.target.value))
                }
              >
                {usedLang.map((language, index) => (
                  <MenuItem value={index} key={index}>
                    {language.label}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Divider
              orientation="vertical"
              flexItem
              color="rgba(0, 0, 0, 0.1)"
            />
            <Box>
              <IconButton
                onClick={() => {
                  let temp = [...reasons];
                  temp = temp.map((t) => {
                    const propNameToRemove = usedLang[languageTabIndex].code;
                    const { [propNameToRemove]: removedProperty, ...newName } =
                      t.name;

                    return {
                      ...t,
                      name: newName,
                      is_edited: true,
                    };
                  });

                  handleRemoveLang();
                  usedLang = [...usedLang].filter(
                    (item) => item.code !== usedLang[languageTabIndex].code
                  );

                  updateReason(type, usedLang, temp);
                }}
                disabled={
                  usedLang.length <= 0 ||
                  usedLang[languageTabIndex]?.code === "EN" ||
                  isSubmitting
                }
              >
                <RemoveIcon />
              </IconButton>
            </Box>
            <Box>
              <IconButton
                onClick={handleAddLangClick}
                disabled={addableLangs.length === 0 || isSubmitting}
              >
                <AddIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleCloseAddLang}
              >
                {addableLangs.map((language, index) => (
                  <MenuItem
                    onClick={() => {
                      handleAddLang(language);
                      handleCloseAddLang();
                    }}
                    key={index}
                  >
                    {language.label}
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Box>
          <Divider color="rgba(0, 0, 0, 0.1)" />
          <Box p={2}>
            {reasons.length > 0 && (
              <Box width="100%" mt={1} mb={1}>
                <Grid container>
                  <Grid item xs={10}>
                    {getLang(lang, "label.REASON")}
                  </Grid>
                  <Grid item xs={1}>
                    <Box textAlign="center">
                      {getLang(lang, "label.ENABLE")}
                    </Box>
                  </Grid>
                  <Grid item xs={1}>
                    <Box textAlign="center" display="flex" alignItems="center">
                      <Box mr={0.5}>{getLang(lang, "label.DEFAULT")}</Box>
                      <Tooltip
                        title={getLang(
                          lang,
                          "tooltips.DEFAULT_REASON_RECEIVE_TRANSFER_EXPLAIN"
                        )}
                        arrow
                      >
                        <InfoOutlinedIcon
                          className={classes.helpIcon}
                          style={{ fontSize: 18 }}
                        />
                      </Tooltip>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            )}
            <DndProvider backend={HTML5Backend}>
              {reasons.map((reason, index) => (
                <DraggableReasonCard
                  key={index}
                  index={index}
                  lang={lang}
                  updateReason={(val) => {
                    updateReason(type, usedLang, val);
                  }}
                  reasons={reasons}
                  reason={reason}
                  usedLang={usedLang}
                  languageTabIndex={languageTabIndex}
                  isSubmitting={isSubmitting}
                />
              ))}
            </DndProvider>
            {reasons.length > 0 && (
              <Box mt={3} mb={2}>
                <Divider />
              </Box>
            )}
            <Box display="flex">
              <Button
                variant="text"
                color="primary"
                startIcon={
                  <AddIconGreen
                    color="secondary"
                    style={{ fontSize: "13px" }}
                  />
                }
                disableElevation
                onClick={() => {
                  const temp = [...reasons];

                  temp.push({
                    uuid: null,
                    name: {},
                    is_enabled: true,
                    is_default: temp.length > 0 ? false : true,
                    is_edited: true,
                    sequence: reasons.length,
                  });

                  updateReason(type, usedLang, temp);
                }}
                disabled={isSubmitting}
              >
                {getLang(lang, "label.ADD_REASON")}
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}
