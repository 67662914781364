import React from "react";
import {
  makeStyles,
  Box,
  Typography,
  Divider,
  Paper,
  Tabs,
  CircularProgress
} from "@material-ui/core";
import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";
import PhotoProductPanel from "../../components/panel/photoProductPanel";
import ProductInfoPanel from "../../components/panel/productInfoPanel";
import CustomTab from "components/tab";
import PropTypes from "prop-types";
import LandingPagePanel from "../../components/panel/landingPagePanel";
import { BackButton } from "components/button";
import clsx from 'clsx';
import { getLang } from "app/feature/constants";

const useStyle = makeStyles(theme => ({
  root: {
    gridTemplateAreas: "'top .' 'main side'",
    gridTemplateColumns: "1fr 330px",
    [theme.breakpoints.up("lg")]: {
      display: "grid"
    },
    columnGap: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      gridTemplateAreas: "'top' 'main' 'side'",
      gridTemplateColumns: "1fr",
      gridTemplateRows: "1fr auto",
      rowGap: theme.spacing(2)
    }
  },
  fullWidthGrid: {
    gridTemplateAreas: "'top' 'main'",
    gridTemplateColumns: "1fr"
  },
  paper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(5, 0),
    width: "90%",
    maxWidth: 1000,
    display: "inherit",
    justifyContent: "inherit"
  },
  content: {
    width: "95%",
    maxWidth: 850
  },

  sideBanner: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridRowGap: theme.spacing(2),
    rowGap: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(3),
      gridTemplateColumns: "1fr 1fr",
      columnGap: theme.spacing(2)
    },
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr"
    }
  },

  publishButton: {
    color: theme.palette.common.white
  },
  backButton: {
    marginRight: theme.spacing(1)
  },
  discardButton: {
    color: theme.palette.error.main
  },
  iconContainer: {
    minHeight: 38
  },
  stepperRoot: {
    paddingBottom: 0
  },
  stepLabelRoot: {
    height: 100,
    position: "relative"
  },
  stepLabelActive: {
    "&::after": {
      content: "''",
      backgroundColor: theme.palette.primary.main,
      width: "20%",
      height: 3,
      position: "absolute",
      bottom: 0,
      borderRadius: theme.spacing(1, 1, 0, 0)
    }
  },
  indicator: {
    border: "0.5px solid",
    borderColor: theme.palette.primary.main,
    borderRadius: "4px 4px 0px 0px"
  },
  scroll: {
    width: "min-content"
  },
  tabContainer: {
    backgroundColor: "white",
    padding: "0px 20px",
    borderRadius: "6px 6px 0px 0px"
  }
}));

function a11yProps(index, label = "label") {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tab-${index}`,
    label: (
      <Typography
        variant="button"
        style={{ textTransform: "none" }}
        children={label}
      />
    )
  };
}

function TabPanel({ children, value, index, ...other }) {
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

export default function EditProductComponent({
  hasProductEditAccessRight,
  isLoading,
  activeIndex,
  handleChange,
  lang
}) {
  const classes = useStyle();

  return (
    <>
      <Box className={clsx(classes.root, {
        [classes.fullWidthGrid]: activeIndex === 1
      })}>
        <Box gridArea="top">
          <Box
            mb={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" alignItems="flex-end" fontWeight="bold">
              <BackButton />
              <Typography variant="h6" color="primary">
                {getLang(lang,"label.PRODUCT")}
              </Typography>
              <Box mx={0.5}>
                <ChevronRightRoundedIcon color="disabled" />
              </Box>
              <Typography variant="h6" color="primary">
                {hasProductEditAccessRight ? getLang(lang,"label.VIEW_EDIT") : getLang(lang,"label.VIEW")} {getLang(lang,"label.PRODUCT")}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box gridArea="main">
          {!isLoading && (
            <Box>
              <Box className={classes.tabContainer}>
                <Tabs
                  value={activeIndex}
                  onChange={handleChange}
                  aria-label="scrollable auto tabs example"
                  textColor="primary"
                  variant="scrollable"
                  indicatorColor="primary"
                  classes={{
                    indicator: classes.indicator,
                    scrollButtons: classes.scroll
                  }}
                >
                  {[getLang(lang,"label.PRODUCT_INFO"), getLang(lang,"label.LANDING_PAGE")].map((item, index) => {
                    return <CustomTab key={index} {...a11yProps(index, item)} />;
                  })}
                </Tabs>
              </Box>
              <Divider />
              <TabPanel value={activeIndex} index={0}>
                <ProductInfoPanel />
              </TabPanel>
              <TabPanel value={activeIndex} index={1}>
                <Box>
                  <LandingPagePanel />
                </Box>
              </TabPanel>
            </Box>
          )}
        </Box>
        {/* Kood side banner */}
        {
          !isLoading && (
            <Box gridArea="side" mt={6}>
              {activeIndex === 0 && (
                <Paper elevation={3}>
                  <PhotoProductPanel isDirectUpload />
                </Paper>
              )}
            </Box>
          )
        }
      </Box>
      {isLoading && (
        <>
          <Box
            gridArea="main" mt={6}
            style={{
              textAlign: "center"
            }}
          >
            <CircularProgress color="primary" />
          </Box>
        </>
      )}
    </>
  );
}
