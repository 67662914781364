import React from "react";
import {
  makeStyles,
  Typography,
  Paper,
  ClickAwayListener,
  Box,
  MenuItem,
  Popover,
} from "@material-ui/core";
import clsx from "clsx";

const useStyle = makeStyles((theme) => ({
  paper: {
    width: 380,
    maxWidth: "80vw",
  },
  button: {
    padding: 0,
    minWidth: 0,
  },
  buttonAddBranch: {
    display: "flex",
    padding: 0,
    minWidth: 0,
  },
  greenLabel: {
    color: theme.palette.secondary.main,
  },
  list: {
    maxHeight: "25vh",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "5px",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "16px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#E4E7EC",
      borderRadius: "16px",
    },
  },
  selected: {
    backgroundColor: "#f1f1f1",
  },
}));

export default function AdditionalFieldSelectPanelComponent({
  additionalFields,
  value,
  anchorEl,
  open,
  handleClose,
  dropdown,
  handleSelectField,
  lang,
  userLang,
}) {
  const classes = useStyle();

  return (
    <Popover
      id={open ? "simple-popover" : undefined}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      elevation={2}
      PaperProps={{
        style: { maxWidth: "63vw", width: 400 },
      }}
    >
      <Paper>
        <ClickAwayListener onClickAway={handleClose}>
          <Box>
            <Box>
              {[...dropdown].map((info, index) => {
                return (
                  <React.Fragment key={index}>
                    <MenuItem
                      style={{ padding: 0 }}
                      onClick={() => handleSelectField(info)}
                      className={clsx({
                        [classes.selected]: value?.id === info.id,
                      })}
                    >
                      <Box display="flex" alignItems="center" p={1}>
                        <Typography variant="inherit">
                          {info.title
                            ? info.title[userLang] ??
                              info.title["EN"] ??
                              Object.values(info.title)[0]
                            : ""}
                        </Typography>
                      </Box>
                    </MenuItem>
                  </React.Fragment>
                );
              })}
            </Box>
          </Box>
        </ClickAwayListener>
      </Paper>
    </Popover>
  );
}
