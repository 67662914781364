import axios from "axios"

export default async function getGiftDropdown({ id, search }) {
    return new Promise((resolve, reject) => {
        axios.get(`/campaign/api/admin/orders/${id}/gift-list`, {
            params: {
                search,
            }
        })
        .then((response) => resolve(response.data))
        .catch((error) => {
            return reject(error.response.data)
        })
    })
}
