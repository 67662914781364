import axios from "axios";

const url = "/distributor/admin/api/v1/transfer/list";
export function getTransferList(parameters) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, {
        length: parameters.length,
        start: parameters.start,
        order: parameters.order,
        order_by: parameters.order_by,
        filter: {
          search: parameters.filter.search,
          status: parameters.filter.status,
          from_date: parameters.filter.from_date,
          to_date: parameters.filter.to_date,
          transfer_from: parameters.filter.transfer_from,
          transfer_to: parameters.filter.transfer_to,
          respond_date: parameters.filter.respond_date,
        },
      })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data));
  });
}
