/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import AdtInfoDataPanelComponent from "./adtInfoDataPanel.page";
import { useDispatch, useSelector } from "react-redux";
import { getLang } from "app/feature/constants";
import qs from "query-string";
import { useHistory, useLocation } from "react-router";
import { updateUrlQueryParam } from "lib/helper";
import {
  resetAdtProductPageSetting,
  resetAdtSnPageSetting,
  resetSelectedAdtInfos,
  resetSnAfterKey,
  saveAdtPrdPageSettingSession,
  saveAdtSnPageSettingSession,
  updateAdtSnPageSetting,
} from "modules/serial-number/redux";

function AdtInfoDataPanelContainer() {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.constant.languages);
  const selectedAdtInfos = useSelector(
    (state) => state.additionalInfo.selectedAdtInfos
  );
  const displayLang = useSelector((state) => state.additionalInfo.displayLang);
  const history = useHistory();
  const location = useLocation();
  const qsParams = qs.parse(location.search);
  const currentSearch = qsParams.type || "";

  const [activeIndex, setActiveIndex] = useState(null);

  const preservedSelectedAdt = useRef(false);

  useEffect(() => {
    return () => {
      if (!preservedSelectedAdt.current) {
        dispatch(resetSelectedAdtInfos());
      }
    };
  }, []);

  useEffect(() => {
    dispatch(resetAdtSnPageSetting());
    dispatch(resetAdtProductPageSetting());
  }, [selectedAdtInfos, displayLang]);

  useEffect(() => {
    let tempIndex = 0;
    switch (currentSearch) {
      case "product":
        tempIndex = 0;
        break;
      case "sn":
        tempIndex = 1;
        break;
      default:
        tempIndex = 0;
    }
    setActiveIndex(tempIndex);
  }, [currentSearch]);

  let tabLabels = [
    getLang(lang, "label.PRODUCT"),
    getLang(lang, "label.SERIAL_NUMBER"),
  ];

  const handleChange = (event, index) => {
    setActiveIndex(index);
    window.history.pushState(
      {},
      "",
      `?tab=adt-info&type=${index === 1 ? "sn" : "product"}`
    );
  };

  const handleViewDetail = (type, url, filter) => {
    if (type === "product") {
      dispatch(resetSnAfterKey());
      setActiveIndex(1);

      dispatch(
        updateAdtSnPageSetting({
          start: 0,
          productNotAssigned: filter.productNotAssigned,
          product_uuids: filter.product_uuids,
          products: filter.products,
          additionalInfo: filter.additionalInfo,
        })
      );

      window.history.pushState({}, "", "?tab=adt-info&type=sn");
    } else {
      preservedSelectedAdt.current = true;
      updateUrlQueryParam({ is_filter: true });
      dispatch(saveAdtSnPageSettingSession());
      dispatch(saveAdtPrdPageSettingSession());
      history.push(url);
    }
  };

  return (
    <>
      <AdtInfoDataPanelComponent
        lang={lang}
        selectedAdtInfos={selectedAdtInfos}
        tabLabels={tabLabels}
        activeIndex={activeIndex}
        handleChange={handleChange}
        handleViewDetail={handleViewDetail}
      />
    </>
  );
}

export default AdtInfoDataPanelContainer;
