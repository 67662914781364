import React from "react";
import {
  Paper,
  Box,
  Typography,
  makeStyles
} from "@material-ui/core";
import CircularChart from "../../visualisation/circularChart";
import EmptyComponent from 'components/empty';

const useStyles = makeStyles((theme) => ({
  container: {
    boxShadow: "0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);",
    borderRadius: 6,
    padding: theme.spacing(2),
  },
}));

export default function CircularChartCardComponent({
  type,
  labels,
  data,
  title,
}) {
  const classes = useStyles();

  return (
    <Paper className={classes.container}>
      <Box mb={2}>
        <Typography variant="h6" style={{ fontSize: 16 }}>{title}</Typography>
      </Box>
      {data.length > 0 ? (
        <Box>
          <CircularChart type={type} labels={labels} data={data} />
        </Box>
      ) : (
        <Box>
          <EmptyComponent />
        </Box>
      )}
    </Paper>
  );
}