import React from "react";
import { makeStyles, Box, Paper } from "@material-ui/core";
import ConsumerPageSettingPanel from "../../panel/consumerPageSettingPanel";

const style = theme => ({
  paper: {
    display: "flex",
    flexFlow: "column nowrap",
    boxShadow: theme.shadows[0],
    height: "max-content",
    alignItems: "center",
    paddingTop: theme.spacing(3)
  }
});

const useStyles = makeStyles(style);

export default function SettingTabComponent() {
  const classes = useStyles();

  return (
    <Box>
      <Paper elevation={0} className={classes.paper}>
        <ConsumerPageSettingPanel />
      </Paper>
    </Box>
  );
}
