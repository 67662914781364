import React, { useRef } from "react";
import {
  makeStyles,
  Typography,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  Box,
  Divider,
  InputAdornment,
  Button,
  MenuItem,
  MenuList
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import PerfectScrollbar from "react-perfect-scrollbar";
import InputTextField from "components/input/inputTextField";
import Skeleton from "@material-ui/lab/Skeleton";
import AntSwitch from "components/switch/switch";
import { convertToBigNum } from "lib/generalUtility";
import DefaultImg from "assets/img/img-default.png";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles((theme) => ({
  paper: {
    width: 418
  },
  button: {
    padding: 0,
    minWidth: 0
  },
  perfectScrollbar: {
    height: 200
  }
}));

export default function AssignProductPanelComponent({
  open,
  anchorRef,
  handleClose,
  handleListKeyDown,
  isOpenList,
  productDropdown,
  isFetching,
  value,
  handleSearch,
  lang
}) {
  const listDropDown = (field, index) => {
    return (
      <Box key={index}>
        <MenuList
          style={{ paddingTop: 0, paddingBottom: 0 }}
          autoFocusItem={isOpenList}
          id="menu-list-grow"
          onClick={() => {
            handleListKeyDown(field)
          }
          }
        >
          <MenuItem>
            <AntSwitch checked={value.find(v => v.id === field.id) ? true : false} />
            <Box className={classes.listImage}>
              <img
                src={field.picture ? field.picture : DefaultImg}
                style={{ width: "45px", height: "45px" }}
                alt={field.id}
              />
            </Box>
            <Box display="grid" p={1}>

              <Typography variant="inherit" noWrap style={{ paddingLeft: "8px" }}>
                {field.name}
              </Typography>
              <Box>
                <Typography variant="caption" noWrap style={{ paddingLeft: "8px", color: "#D4D7DB" }}>
                  {getLang(lang, "label.CURRENT_REQUIRED_POINT")}
                </Typography>
                <Typography variant="inherit" noWrap style={{ paddingLeft: "4px" }}>
                  {convertToBigNum(field.point)}
                </Typography>
              </Box>
            </Box>
          </MenuItem>
        </MenuList>
        <Divider />
      </Box>
    );

  }
  const textRef = useRef();
  const classes = useStyle();
  return (
    <Popper
      open={open}
      style={{ zIndex: 99 }}
      anchorEl={anchorRef.current}
      role={undefined}
      transition
      disablePortal
      placement="bottom-start"
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === "bottom-start" ? "center top" : "center bottom"
          }}
        >
          <Paper className={classes.paper}>
            <ClickAwayListener onClickAway={handleClose}>
              <Box>
                <Box>
                  <InputTextField
                    variant="filled"
                    size="small"
                    fullWidth
                    inputRef={textRef}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          style={{ paddingRight: "6px" }}
                        >
                          <Button onClick={() => handleSearch(textRef.current.value)} className={classes.button}>
                            <SearchIcon fontSize="small" />
                          </Button>
                        </InputAdornment>
                      ),
                      style: {
                        backgroundColor: "#ECEFF0"
                      }
                    }}
                    inputProps={{
                      style: {
                        padding: "14px"
                      }
                    }}
                    placeholder={getLang(lang, 'placeholder.SEARCH_GIFT')}


                  />
                </Box>

                <Divider />

                <PerfectScrollbar className={classes.perfectScrollbar}>
                  {isFetching ? (
                    <>
                      <Skeleton height={70} variant="text" animation="wave" />
                      <Skeleton height={70} variant="text" animation="wave" />
                      <Skeleton height={70} variant="text" animation="wave" />
                      <Skeleton height={70} variant="text" animation="wave" />
                    </>
                  ) :
                    <>
                      {
                        productDropdown.length > 0 ? productDropdown.map((field, index) => {
                          return listDropDown(field, index)
                        })
                          : <Box py={9} style={{ textAlign: "center" }} >
                            <Typography variant="body1"> {getLang(lang, "label.NO_GIFT")}</Typography>
                          </Box>
                      }
                    </>
                  }
                </PerfectScrollbar>
              </Box>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}
