import axios from "axios";

const get = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/account/api/v1/user-group/${id}`)
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

const put = ({ name, access_rights, id }) => {
  const body = {
    name: name,
    access_rights: access_rights,
  };

  return new Promise((resolve, reject) => {
    axios
      .put(`/account/api/v1/user-group/${id}`, body)
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

const post = (request) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/account/api/v1/user-group", request)
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

const remove = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`/account/api/v1/user-group/${id}`)
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

const roleAccessRight = {
  put,
  get,
  post,
  remove
};

export default roleAccessRight;
