import axios from "axios";

const getRangeList = () => {
  return new Promise((resolve, reject) => {
    axios
      .get("/serialnumber/api/v1/generate/range-list")
      .then(response => resolve(response.data))
      .catch(error => {
        return reject(error.response.data);
      });
  });
};

export default getRangeList;
