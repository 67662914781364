import axios from "axios";

const updateInstantLuckyDrawPrizes = ({
  instantLuckyDrawId,
  hasWinRate,
  deletedPrizeUuids,
  prizes,
}) => {
  const body = {
    instantLuckyDrawId: instantLuckyDrawId,
    hasWinRate: hasWinRate,
    deletedPrizeIds: deletedPrizeUuids,
    prizes: prizes,
  };

  return new Promise((resolve, reject) => {
    axios
      .post("/campaignV2/customer/instantLuckyDraw/updatePrizes", body)
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

export default updateInstantLuckyDrawPrizes;
