import { createAsyncThunk } from '@reduxjs/toolkit';
import stockflowApi from 'app/api/stockflow';

export const STOCKFLOWORDER = "stockflowOrder";

export const getNewOrderList = createAsyncThunk(
  `${STOCKFLOWORDER}/getNewOrderList`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi.getOrderList({
      ...payload,
      type: "new"
    })
      .then(response => response.data)
      .catch(error => rejectWithValue(error))
  }
)

export const exportOrder = createAsyncThunk(
  `${STOCKFLOWORDER}/exportOrder`,
  async (payload, thunkApi) => {
    return stockflowApi.exportOrder({
      ...payload
    })
      .then(response => response)
      .catch(error => thunkApi.rejectWithValue(error))
  }
);
export const getAcceptedOrderList = createAsyncThunk(
  `${STOCKFLOWORDER}/getAcceptedOrderList`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi.getOrderList({
      ...payload,
      type: "accepted"
    })
      .then(response => response.data)
      .catch(error => rejectWithValue(error))
  }
)

export const getRejectedOrderList = createAsyncThunk(
  `${STOCKFLOWORDER}/getRejectedOrderList`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi.getOrderList({
      ...payload,
      type: "rejected"
    })
      .then(response => response.data)
      .catch(error => rejectWithValue(error))
  }
)

export const getCancelledOrderList = createAsyncThunk(
  `${STOCKFLOWORDER}/getCancelledOrderList`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi.getOrderList({
      ...payload,
      type: "cancelled"
    })
      .then(response => response.data)
      .catch(error => rejectWithValue(error))
  }
)


export const getOrderDetail = createAsyncThunk(
  `${STOCKFLOWORDER}/getOrderDetail`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi.getOrderDetail(payload)
      .then(response => response.data)
      .catch(error => rejectWithValue(error))
  }
)

export const getOrderRequestorDropdown = createAsyncThunk(
  `${STOCKFLOWORDER}/getOrderRequestorDropdown`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi.getOrderRequestorDropdown(payload)
      .then(response => response.data)
      .catch(error => rejectWithValue(error))
  }
)

export const getOrderReceiverDropdown = createAsyncThunk(
  `${STOCKFLOWORDER}/getOrderReceiverDropdown`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi.getOrderReceiverDropdown(payload)
      .then(response => response.data)
      .catch(error => rejectWithValue(error))
  }
)