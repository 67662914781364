import React from "react";
import { Box, Typography } from "@material-ui/core";
import CounterfeitPanel from "../../components/panel/counterfeitPanel";
import { getLang } from "app/feature/constants";


export default function MainPageComponent({lang}) {

  return (
    <Box>
      <Typography variant="h6" color="primary" style={{ fontWeight: "bold" }}>
        {getLang(lang, "label.COUNTERFEIT_REPORT")}
      </Typography>
      <CounterfeitPanel />
    </Box>
  );
}
