import axios from "axios";

const index = ({ companyUuid, length, start, column, search, status, branch, product }) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`consumer/admin/api/v1/item-replacement`, {
        uuid: companyUuid,
        length: length,
        start: start,
        column: column,
        search: search === "" ? null : search,
        status: status,
        branch: branch,
        product: product
      })
      .then((response) => resolve(response.data))
      .catch(error => reject(error.response.data));
  });
}

const show = () => {
  // return new Promise((resolve, reject) => {
  //   axios
  //     .post(`consumer/api/v1/warranty-item-replacement`, {

  //     })
  //     .then((response) => resolve(response.data))
  //     .catch(error => reject(error.response.data));
  // });
}

const exportHandoff = ({ token, type, length, start, node, status, search, fromDate, toDate }) => {
  return new Promise((resolve, reject) => {
    axios.post(`/consumer/export/v1/handoff`, {
      token,
      type,
      length,
      start,
      node,
      status,
      search,
      fromDate,
      toDate,
    }, {responseType: 'arraybuffer'})
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'handoff.xlsx');
        document.body.appendChild(link);
        link.click();

        resolve(response.data)
      })
      .catch(error => reject(error.response.data));
  })
}

const warrantyItemReplacement = {
  index,
  show,
  exportHandoff,
};

export default warrantyItemReplacement;
