import React from "react";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import { IconButton, InputAdornment } from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";

// We can inject some CSS into the DOM.
const styles = (theme) => ({
  label: {
    paddingTop: "10px",
  },
  errorIcon: {
    color: "#f44336 !important",
  },
});

function InputTextField(props) {
  const {
    classes,
    InputProps,
    inputProps,
    InputStyle,
    SelectProps,
    inputStyle,
    error,
    select,
    onChange,
    onBlur,
    ...other
  } = props;

  return (
    <TextField
      onBlur={onBlur}
      SelectProps={{
        style: {
          backgroundColor: "#ECEFF0",
          borderRadius: "4px",
        },
        ...SelectProps,
      }}
      InputProps={{
        disableUnderline: true,
        style: {
          padding: "5px",
          backgroundColor: "#ECEFF0",
          borderRadius: "4px",
          ...InputStyle,
        },
        endAdornment: error && !select && (
          <InputAdornment>
            <IconButton
              edge="end"
              disabled
              classes={{
                root: classes.errorIcon,
              }}
            >
              <ErrorIcon />
            </IconButton>
          </InputAdornment>
        ),
        ...InputProps,
      }}
      inputProps={{
        style: {
          padding: "7px",
          ...inputStyle,
        },
        ...inputProps,
      }}
      error={error}
      select={select}
      onChange={onChange}
      {...other}
    />
  );
}

export default withStyles(styles)(InputTextField);
