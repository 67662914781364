import axios from "axios";

const quickUpdateProduct = ({
  id,
  name,
  nodes,
  scanlimit,
  category,
  brand,
  model,
  attributeoptions,
  sku,
  currency_code,
  unit_price,
  status,
  incentive_point
}) => {
  return new Promise((resolve, reject) => {
    axios.post(`/serialnumber/api/v1/product/quick-update/${id}`, {
      name,
      nodes,
      scanlimit,
      category,
      brand,
      model,
      attributeoptions,
      sku,
      currency_code,
      unit_price,
      status,
      incentive_point
    })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data))
  })
}

export default quickUpdateProduct