import React from "react";
import TrendingSummaryCardComponent from "./trendingSummaryCard.component";
import { useSelector } from "react-redux";

function TrendingSummaryCardContainer({ title, icon, data, loading, url, allowPopup, popup }) {
  const lang = useSelector((state) => state.constant.languages);
  const language = useSelector((state) => state.profile.language);

  return (
    <TrendingSummaryCardComponent
      title={title}
      icon={icon}
      language={language}
      data={data}
      loading={loading}
      lang={lang}
      url={url}
      allowPopup={allowPopup}
      popup={popup}
    />
  );
}

export default TrendingSummaryCardContainer;
