import { addAlert, setLoading } from "modules/notification";
import { getAllCampaignRespondentsData, getConsumerListingData, getConsumerScanData } from "../action";

// (1) get all campaign respodents data -- start
export const getAllCampaignRespondentsDataMiddleware = ({ dispatch, getState}) => next => action => {
    next(action);
    if (action.type === getAllCampaignRespondentsData.pending.type) {
        dispatch(setLoading({ id: getAllCampaignRespondentsData.typePrefix, state: true }));
    }
    if (action.type === getAllCampaignRespondentsData.fulfilled.type) {
        dispatch(setLoading({ id: getAllCampaignRespondentsData.typePrefix, state: false }));
    }
    if (action.type === getAllCampaignRespondentsData.rejected.type) {
        dispatch(setLoading({ id: getAllCampaignRespondentsData.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: action.payload.message}));
    }
}
// (1) get all campaign respodents data -- end

// (2) get consumer scan data -- start
export const getConsumerScanDataMiddleware = ({ dispatch, getState}) => next => action => {
    next(action);
    if (action.type === getConsumerScanData.pending.type) {
        dispatch(setLoading({ id: getConsumerScanData.typePrefix, state: true }));
    }
    if (action.type === getConsumerScanData.fulfilled.type) {
        dispatch(setLoading({ id: getConsumerScanData.typePrefix, state: false }));
    }
    if (action.type === getConsumerScanData.rejected.type) {
        dispatch(setLoading({ id: getConsumerScanData.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: action.payload.message}));
    }
}
// (2) get consumer scan data -- end

// (3) get consumer listing data -- start
export const getConsumerListingDataMiddleware = ({ dispatch, getState}) => next => action => {
    next(action);
    if (action.type === getConsumerListingData.pending.type) {
        dispatch(setLoading({ id: getConsumerListingData.typePrefix, state: true }));
    }
    if (action.type === getConsumerListingData.fulfilled.type) {
        dispatch(setLoading({ id: getConsumerListingData.typePrefix, state: false }));
    }
    if (action.type === getConsumerListingData.rejected.type) {
        dispatch(setLoading({ id: getConsumerListingData.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: action.payload.message}));
    }
}
// (3) get consumer listing data -- end

export default [
    getAllCampaignRespondentsDataMiddleware,
    getConsumerScanDataMiddleware,
    getConsumerListingDataMiddleware,
]