/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import RequestSenderSelectComponent from "./requestSenderSelect.component";
import { selectLoading } from "modules/notification";
import RequestSenderSelectPanelComponent from "./requestSenderSelectPanel.component";
import { useSelector, useDispatch } from "react-redux";
import { cloneDeep } from "lodash";
import { getRequestSenderDropdown } from "modules/stockflow/redux";

function RequestSenderSelectContainer({
  handleChange,
  disabled,
  value,
  styles,
  textStyles,
  placeholder,
  type,
}) {
  const isFetching = useSelector((state) =>
    selectLoading(state, getRequestSenderDropdown.typePrefix)
  );
  const requestSenderDropdown = useSelector(
    (state) => state.stockflowRequestTransfer.requestSenderDropdown
  );
  const lang = useSelector((state) => state.constant.languages);
  const [open, setOpen] = useState(false);
  const [isOpenList] = useState(false);
  const anchorRef = useRef(null);
  const prevOpen = useRef(open);
  const prevOpenList = useRef(isOpenList);
  const [selectAll, setSelectAll] = useState(false);
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getRequestSenderDropdown({
        search,
        is_my_request: type === "Send" ? 1 : 0,
      })
    );
  }, [search, dispatch]);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    if (prevOpenList.current === true && isOpenList === false) {
      anchorRef.current.focus();
    }

    prevOpenList.current = isOpenList;
  }, [isOpenList]);

  useEffect(() => {
    setSelectAll(value.length === requestSenderDropdown.length);
  }, [value, requestSenderDropdown]);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setSearch("");
  };

  const handleSelectAll = async () => {
    if (!selectAll) {
      let selectedSender = requestSenderDropdown.map((p) => {
        return p.uuid;
      });
      handleChange(selectedSender);
    } else {
      handleChange([]);
    }
  };

  const handleSelectSender = (sender) => {
    let temp = cloneDeep(value);

    if (temp.findIndex((t) => t === sender.uuid) >= 0) {
      temp = temp.filter((t) => t !== sender.uuid);
    } else {
      temp.push(sender.uuid);
    }
    handleChange(temp);
  };

  const handleSearch = (value) => {
    setSearch(value);
  };

  return (
    <>
      <RequestSenderSelectComponent
        anchorRef={anchorRef}
        disabled={disabled}
        value={value}
        handleClick={handleOpen}
        styles={styles}
        textStyles={textStyles}
        placeholder={placeholder}
        lang={lang}
      />
      <RequestSenderSelectPanelComponent
        selectAll={selectAll}
        value={value}
        anchorRef={anchorRef}
        open={open}
        isOpenList={isOpenList}
        handleClose={handleClose}
        isFetching={isFetching}
        requestSenderDropdown={requestSenderDropdown}
        handleSelectAll={handleSelectAll}
        handleSelectSender={handleSelectSender}
        handleSearch={handleSearch}
        lang={lang}
      />
    </>
  );
}

export default RequestSenderSelectContainer;
