import React, { useEffect, useState } from "react";
import AdtInfoTitleListComponent from "./adtInfoTitleListPanel.page";
import { useDispatch, useSelector } from "react-redux";
import { selectLoading } from "modules/notification";
import {
  deleteAdtInfoTitle,
  getAdtInfoListing,
  resetAdtInfoListing,
  updateSelectedAdtInfos,
} from "modules/serial-number/redux";
import EditAdtInfoTitleDialog from "modules/serial-number/components/dialog/editAdtInfoTitleDialog";
import CreateAdtInfoTitleDialog from "modules/serial-number/components/dialog/createAdtInfoTitleDialog";
import GeneralDialog from "components/dialog/generalDialog";
import DeleteSvgIcon from "assets/svg/delete.svg";
import { getLang } from "app/feature/constants";
import { unwrapResult } from "@reduxjs/toolkit";

function AdtInfoTitleListContainer() {
  const dispatch = useDispatch();
  const adtInfos = useSelector(
    (state) => state.additionalInfo.adtInfoList.list
  );
  const totalFiltered = useSelector(
    (state) => state.additionalInfo.adtInfoList.totalFiltered
  );
  const isError = useSelector(
    (state) => state.additionalInfo.adtInfoList.isError
  );
  const hasNextPage = useSelector(
    (state) => state.additionalInfo.adtInfoList.hasNextPage
  );
  const selectedInfos = useSelector(
    (state) => state.additionalInfo.selectedAdtInfos
  );
  const isLoading = useSelector((state) =>
    selectLoading(state, getAdtInfoListing.typePrefix)
  );
  const isDeleting = useSelector((state) =>
    selectLoading(state, deleteAdtInfoTitle.typePrefix)
  );
  const displayLang = useSelector((state) => state.additionalInfo.displayLang);
  const language = useSelector((state) => state.profile.language);
  const lang = useSelector((state) => state.constant.languages);
  const [selectedAdtInfos, setSelectedAdtInfos] = useState(selectedInfos);
  const [showAddDialog, setShowAddDialog] = useState(false);
  // const [showEditDialog, setShowEditDialog] = useState(false);
  const [selectedEditTitle, setSelectedEditTitle] = useState(null);
  const [selectedDeleteTitle, setSelectedDeleteTitle] = useState(null);

  const [tableFilter, setTableFilter] = useState({
    page: 0,
    rowsPerPage: 25,
    search: "",
  });

  const updateTableFilter = (updatedFilter) => {
    setTableFilter({
      ...updatedFilter,
    });
  };

  useEffect(() => {
    return () => {
      dispatch(resetAdtInfoListing());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableFilter]);

  useEffect(() => {
    setSelectedAdtInfos(selectedInfos);
  }, [selectedInfos]);

  const fetchData = () => {
    dispatch(
      getAdtInfoListing({
        length: tableFilter.rowsPerPage,
        start: tableFilter.rowsPerPage * tableFilter.page,
        search: tableFilter.search,
      })
    );
  };

  const handleFetchMore = () => {
    if (hasNextPage) {
      let newData = {
        ...tableFilter,
        page: tableFilter.page + 1,
      };
      updateTableFilter(newData, true);
    }
  };

  const handleReload = () => {
    dispatch(resetAdtInfoListing());
    let newData = {
      ...tableFilter,
      page: 0,
    };
    updateTableFilter(newData);
  };

  const handleSearch = (value) => {
    dispatch(resetAdtInfoListing());
    let newData = {
      ...tableFilter,
      page: 0,
      search: value,
    };
    updateTableFilter(newData, true);
  };

  const handleSelectAdtInfo = (adtInfo, isSelected) => {
    if (isSelected) {
      let selectedIds = selectedAdtInfos.map((i) => i.id);
      if (!selectedIds.includes(adtInfo.id) && selectedIds.length < 5) {
        setSelectedAdtInfos([...selectedAdtInfos, adtInfo]);
      }
    } else {
      setSelectedAdtInfos(
        selectedAdtInfos.filter((info) => info.id !== adtInfo.id)
      );
    }
  };

  const showSnResult = () => {
    // handleShowSn(selectedAdtInfos);
    dispatch(updateSelectedAdtInfos(selectedAdtInfos));
  };

  const handleEdit = (title) => {
    setSelectedEditTitle(title);
  };

  const handleCloseEditDialog = (title = null) => {
    setSelectedEditTitle(null);

    if (title) {
      let updated = false;
      let newTitles = selectedAdtInfos.map((t) => {
        let data = { ...t };
        if (t.id === title.id) {
          data.title = title.title;
          updated = true;
        }
        return data;
      });

      if (updated) {
        setSelectedAdtInfos(newTitles);
      }
    }
  };

  const handleDelete = () => {
    dispatch(deleteAdtInfoTitle(selectedDeleteTitle.id))
      .then(unwrapResult)
      .then(() => {
        setSelectedDeleteTitle(null);

        let idx = selectedAdtInfos.findIndex(
          (a) => a.id === selectedDeleteTitle.id
        );
        if (idx >= 0) {
          setSelectedAdtInfos(
            selectedAdtInfos.filter((a) => a.id !== selectedDeleteTitle.id)
          );
        }
      });
  };

  return (
    <>
      <AdtInfoTitleListComponent
        lang={lang}
        handleFetchMore={handleFetchMore}
        handleReload={handleReload}
        adtInfos={adtInfos}
        totalFiltered={totalFiltered}
        isError={isError}
        hasNextPage={hasNextPage}
        isLoading={isLoading}
        language={language}
        selectedIds={selectedAdtInfos.map((i) => i.id)}
        handleSelectAdtInfo={handleSelectAdtInfo}
        handleSearch={handleSearch}
        handleEdit={handleEdit}
        handleAddTitle={() => setShowAddDialog(true)}
        showSnResult={showSnResult}
        displayLang={displayLang}
        handleDelete={(title) => {
          setSelectedDeleteTitle(title);
        }}
      />
      <EditAdtInfoTitleDialog
        isOpen={!!selectedEditTitle}
        handleClose={handleCloseEditDialog}
        selectedTitle={selectedEditTitle}
      />
      <CreateAdtInfoTitleDialog
        isOpen={showAddDialog}
        handleClose={() => setShowAddDialog(false)}
        handleReload={handleReload}
      />
      <GeneralDialog
        isOpen={!!selectedDeleteTitle}
        handleClose={() => {
          setSelectedDeleteTitle(null);
        }}
        handleProceed={handleDelete}
        title={`${getLang(lang, "label.DELETE")} ${
          selectedDeleteTitle?.title
            ? selectedDeleteTitle?.title[displayLang] ??
              selectedDeleteTitle?.title["EN"] ??
              ""
            : ""
        }`}
        description={getLang(lang, "paragraph.DELETE_ADT_TITLE_QUESTION")}
        type="danger"
        proceedLabel={getLang(lang, "label.DELETE")}
        icon={<img src={DeleteSvgIcon} alt="" />}
        isLoading={isDeleting}
      />
    </>
  );
}

export default AdtInfoTitleListContainer;
