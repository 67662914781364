import React from "react";
import {
  makeStyles,
  Typography,
  Box,
  Link
} from '@material-ui/core';
import LogoSmartKood from "assets/img/logo-smartkood.png";
import { getLang } from "app/feature/constants";

const style = theme => ({
  container: {
    display: "flex",
    alignItems: "center",
    "& img": {
      width: 83
    }
  },
});

const useStyle = makeStyles(style);

export default function FooterComponent({
  type,
  lang
}) {
  const classes = useStyle();
  return (
    <Box>
      {type === "logo" && (
        <Box className={classes.container}>
          <Typography
            variant='subtitle2'
            style={{ marginRight: "8px", color: '#98A2B3', fontFamily: "Open Sans", fontWeight: 400 }}
          >
            {getLang(lang, 'label.POWERED_BY')}
          </Typography>
          <img src={LogoSmartKood} alt="product logo" />
        </Box>
      )}
      {type === "tnc" && (
        <Box>
          <Typography variant='subtitle2' style={{ color: "#344054", textAlign: "center" }}>
            &copy; {getLang(lang, 'paragraph.TRACKCO_ALL_RIGHT_RESERVED')}
          </Typography>
          <Typography variant='subtitle2' style={{ color: "#667085", textAlign: "center" }}>
          <Link
            className={classes.font}
            color="secondary"
            href={process.env.REACT_APP_KOOD_ASIA_PRIVACY_POLICY_URL}
            target="_blank"
          >
            {getLang(lang, 'paragraph.PRIVACY_POLICY')}
          </Link>
          &nbsp;{getLang(lang, 'paragraph.AND')}&nbsp;
          <Link
            className={classes.font}
            color="secondary"
            href={process.env.REACT_APP_KOOD_ASIA_USER_AGREEMENT_URL}
            target="_blank"
          >
            {getLang(lang, 'paragraph.USER_AGREEMENT')}
          </Link>
          .
          </Typography>
        </Box>
      )}
    </Box>
  );
}