import axios from "axios";

const connection = process.env.REACT_APP_AUTH_CONNECTION
const connectionId = process.env.REACT_APP_AUTH_CONNECTION_ID

export default function signUp({
    email,
    username,
    password,
    confirmPassword,
    metadata
}) {
    return axios.post(`/authentication/api/basic/signup`,
        {
            connection,
            connection_id: connectionId,
            name: username,
            email,
            username,
            password,
            confirm_password: confirmPassword,
            metadata: metadata ? metadata : null
        }
    )
}