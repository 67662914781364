import React from "react";
import ReceiveItemsBoxListPanelComponent from "./receiveItemsBoxListPanel.component";

export default function ReceiveItemsBoxListPanelContainer({
  isFetching,
  list,
  totalFiltered,
  isError,
  handleFetchMoreItems,
  viewBox,
  lang,
  changeStatusTab,
  statusTab,
  status,
  handleReload,
  hasNextPage,
  handleSearch,
  filter,
}) {
  return (
    <>
      <ReceiveItemsBoxListPanelComponent
        lang={lang}
        isFetching={isFetching}
        list={list}
        totalFiltered={totalFiltered}
        isError={isError}
        handleFetchMoreItems={handleFetchMoreItems}
        viewBox={viewBox}
        changeStatusTab={changeStatusTab}
        tab={statusTab}
        status={status}
        handleReload={handleReload}
        hasNextPage={hasNextPage}
        handleSearch={handleSearch}
        filter={filter}
      />
    </>
  );
}
