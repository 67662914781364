import React from "react"
import RemoveBannerDialogComponent from "./removeBannerDialog.component"
import {useSelector} from 'react-redux'

function RemoveBannerDialogContainer({
   isOpen,
   handleClose,
   handleConfirm,
}) {
  const lang = useSelector(state => state.constant.languages)
    
  return (
    <RemoveBannerDialogComponent
      isOpen={isOpen}
      handleConfirm={handleConfirm}
      handleClose={handleClose}
      lang={lang}
    />
  )
}

export default RemoveBannerDialogContainer;
