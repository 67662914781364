import { STOCK_MODULE_SETTING } from "../../constants";
import { createSlice } from "@reduxjs/toolkit";
import { getStockModuleSetting, updateStockModuleSetting } from "../action";
import languages from "lib/constants/languages";

const initialState = {
  rules: {
    maxTier: 0,
    requestStock: 0,
    autoReceiveStock: 0,
    autoReceiveStockDays: 0,
    rewardPoint: 0,
    rewardPointMin: 0,
    rewardPointExpiration: 0,
    rewardPointExpirationMonths: 0,
    rewardPointRecallDeduction: 0,
    autoReceiveTransfer: 0,
    autoReceiveTransferDays: 0,
    enableTransferRules: 0,
    enableCustomReasons: 0,
    customEmailOption: 1,
    customEmailRecipients: [],
    reasons: {},
  },
  isLoadingStockModuleSetting: true,
};

const stockModuleSettingSlice = createSlice({
  name: STOCK_MODULE_SETTING,
  initialState,
  reducers: {
    resetState(state, action) {
      state = initialState;
    },
    resetCustomReasons(state) {
      state.reasons = initialState.reasons;
    },
  },
  extraReducers: {
    [getStockModuleSetting.pending]: (state, action) => {
      state.isLoadingStockModuleSetting = true;
    },
    [getStockModuleSetting.fulfilled]: (state, action) => {
      const { payload } = action;
      state.isLoadingStockModuleSetting = false;
      state.rules.maxTier = payload.data.max_tier;
      state.rules.requestStock = payload.data.request_stock;
      state.rules.autoReceiveStock = payload.data.auto_receive_stock;
      state.rules.autoReceiveStockDays = payload.data.auto_receive_stock_days;
      state.rules.rewardPoint = payload.data.reward_point;
      state.rules.rewardPointMin = payload.data.reward_point_min;
      state.rules.rewardPointExpiration = payload.data.reward_point_expiration;
      state.rules.rewardPointExpirationMonths =
        payload.data.reward_point_expiration_months;
      state.rules.rewardPointRecallDeduction =
        payload.data.reward_point_recall_deduction;
      state.rules.autoReceiveTransfer = payload.data.auto_receive_transfer;
      state.rules.autoReceiveTransferDays =
        payload.data.auto_receive_transfer_days;
      state.rules.enableTransferRules = payload.data.enable_transfer_rules;
      state.rules.enableCustomReasons = payload.data.enable_custom_reasons;
      state.rules.customEmailOption = payload.data.custom_email_option;
      state.rules.customEmailRecipients = payload.data.custom_email_recipients;

      let temp = {};
      payload.data.reasons.forEach((r) => {
        temp[r.type] = {
          usedLang:
            r.data === 0
              ? [languages.find((lang) => lang.code === "EN")]
              : Object.keys(r.data[0].name).map((key) =>
                  languages.find((lang) => lang.code === key)
                ),
          reasons: r.data,
        };
      });
      state.rules.reasons = temp;
    },
    [updateStockModuleSetting.pending]: (state, action) => {
      state.isLoadingStockModuleSetting = true;
    },
    [updateStockModuleSetting.fulfilled]: (state, action) => {
      const { payload } = action;
      state.isLoadingStockModuleSetting = false;
      state.rules.maxTier = payload.data.max_tier;
      state.rules.requestStock = payload.data.request_stock;
      state.rules.autoReceiveStock = payload.data.auto_receive_stock;
      state.rules.autoReceiveStockDays = payload.data.auto_receive_stock_days;
      state.rules.rewardPoint = payload.data.reward_point;
      state.rules.rewardPointMin = payload.data.reward_point_min;
      state.rules.rewardPointExpiration = payload.data.reward_point_expiration;
      state.rules.rewardPointExpirationMonths =
        payload.data.reward_point_expiration_months;
      state.rules.rewardPointRecallDeduction =
        payload.data.reward_point_recall_deduction;
      state.rules.autoReceiveTransfer = payload.data.auto_receive_transfer;
      state.rules.autoReceiveTransferDays =
        payload.data.auto_receive_transfer_days;
      state.rules.enableTransferRules = payload.data.enable_transfer_rules;
      state.rules.enableCustomReasons = payload.data.enable_custom_reasons;

      let temp = {};
      payload.data.reasons.forEach((r) => {
        temp[r.type] = {
          usedLang:
            r.data === 0
              ? [languages.find((lang) => lang.code === "EN")]
              : Object.keys(r.data[0].name).map((key) =>
                  languages.find((lang) => lang.code === key)
                ),
          reasons: r.data,
        };
      });
      state.rules.reasons = temp;
    },
  },
});

export const { resetState, resetCustomReasons } =
  stockModuleSettingSlice.actions;
export const stockModuleSettingReducer = stockModuleSettingSlice.reducer;
