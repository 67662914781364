import { Avatar, Box, Paper, TableCell, TableRow, Typography } from "@material-ui/core";
import { UnknownUserIcon } from "modules/reporting/assets/svg";
import { CAMPAIGN_TYPE_SURVEY, CAMPAIGN_TYPE_WARRANTY } from "modules/reporting/constants";
import { fontTextStyle, iconStyle, tableStyle } from "modules/reporting/styles";
import { drilldownPageSelection } from "modules/reporting/utils/helper";
import React from "react";
import { Link } from "react-router-dom";
import { ContentCard } from "../../layout";
import { DynamicTable } from "../../table";
import moment from "moment";
import { ActionButton } from "components/button";

export default function RespondentSingleCampaignPanelComponent({
    status,
    data,
    pagination,
    sort,
    currentFilter,
    pageType,
    translate,
    handleView
}){
    const tableClass = tableStyle();
    const fontClass = fontTextStyle();
    const iconClass = iconStyle();

    const getCardTitle = (campaignType) => {
        if(campaignType === CAMPAIGN_TYPE_SURVEY)
            return translate("label.RESPONDENTS");

        if(campaignType === CAMPAIGN_TYPE_WARRANTY)
            return translate("label.WARRANTEE");

        return translate("label.PARTICIPANTS")
    }

    const getConsumerTitleHeader = (campaignType) => {

        if(campaignType === CAMPAIGN_TYPE_WARRANTY)
            return translate("label.WARRANTEE");

        return translate("label.CONSUMER")
    }


    const columns = [
        {
            title: translate("label.NUM"),
            field: null,
            render : (row) => {
                return (
                    <>{row.title}</>
                )
            },
            align: "left"
        },
        {
            title: getConsumerTitleHeader(currentFilter.campaignType),
            field: null,
            render : (row) => {
                return (
                    <>{row.title}</>
                )
            },
            align: null
        },
        {
            title: translate("label.PRODUCTS"),
            field: null,
            render : (row) => {
                return (
                    <>{row.title}</>
                )
            },
            align: "left"
        },
        {
            title: translate("label.SERIAL_NUMBER"),
            field: null,
            render : (row) => {
                return (
                    <>{row.title}</>
                )
            },
            align: "left"
        },
        {
            title: translate("label.SUBMITTED_DATE"),
            field: "created_at",
            render : (row) => {
                return (
                    <>{row.title}</>
                )
            },
            align: "left"
        },
        {
            title: translate("label.ACTION"),
            field: null,
            render : (row) => {
                return (
                    <>{row.title}</>
                )
            },
            align: "left"
        }
    ];

    const renderData = (data) => {

        let rows = [];
        if(Object.keys(data).length === 0 || !data.isSuccess){
            return rows;
        }

        let imageProps = JSON.stringify(data);
        imageProps = JSON.parse(imageProps);

        let no_iteration = pagination.rowsPerPage * pagination.page + 1;
        imageProps.rows.forEach((item, index) => {
            const actionItems = [
                {
                  label: "View Details",
                  action: () => handleView({ id: item.survey_respondent_uuid })
                }
            ];

            let row =  (
                <TableRow key={index}>
                    <TableCell align="left" className={tableClass.table__cell} style={{width:"20px"}}>
                        <Typography className={fontClass.text__table_cell}>
                            {no_iteration++}
                        </Typography>
                    </TableCell>
                    <TableCell className={tableClass.table__cell} style={{minWidth:"190px"}}>
                        <Box className={tableClass.cell__product}>
                            {
                                item.image ?
                                <Avatar variant="square" alt={item.name} src={item.image} className={iconClass.svg__icon}/>
                                :
                                <Avatar variant="square" alt={item.name} src={UnknownUserIcon} className={iconClass.svg__icon}/>
                            }
                            {
                                item.name && item.name !== "" ? (
                                    <Link 
                                        target="_blank" rel="noopener noreferrer"
                                        className={fontClass.text__hyperlink}
                                        to={() => {
                                            if(!item.uuid)
                                                return;
        
                                            let filterProperties = {};
        
                                            if(item.uuid)
                                                filterProperties.consumerUuid = item.uuid;
        
                                            let url = drilldownPageSelection(filterProperties, pageType, true);
                                            return url;
                                        }}
                                    >
                                        {item.name}
                                    </Link>
                                ) : (
                                    <Typography className={fontClass.text__unknown}>
                                        {translate("label.ANONYMOUS")}
                                    </Typography>
                                )
                            }
                        </Box>

                    </TableCell>
                    <TableCell align={"left"} className={tableClass.table__cell} style={{minWidth:"240px", maxWidth:"240px"}}>
                        <Box className={tableClass.cell__product}>
                            {
                                item.product.name ? (
                                    <>
                                        {
                                            item.product.image ?
                                            <Avatar variant="square" alt={item.product.name} src={item.product.image} className={iconClass.svg__icon}/>
                                            :
                                            <Avatar variant="square" alt={item.product.name} src={UnknownUserIcon} className={iconClass.svg__icon}/>
                                        }

                                            <Link 
                                                target="_blank" rel="noopener noreferrer"
                                                className={fontClass.text__hyperlink}
                                                to={() => {
                                                    if(!item.product.uuid)
                                                        return;
                
                                                    let filterProperties = {};

                                                    if(currentFilter.countryCode)
                                                        filterProperties.countryCode = currentFilter.countryCode;
                                                    
                                                    if(currentFilter.cityUuid)
                                                        filterProperties.city = currentFilter.cityUuid;
                                                    
                                                    if(currentFilter.branchUuid)
                                                        filterProperties.branchUuid = currentFilter.branchUuid;

                                                    if(currentFilter.period)
                                                        filterProperties.period = currentFilter.period;

                                                    if(currentFilter.dateRange){
                                                        filterProperties.start = moment(currentFilter.dateRange[0]).format('YYYY-MM-DD');
                                                        filterProperties.end = moment(currentFilter.dateRange[1]).format('YYYY-MM-DD');
                                                    }
                
                                                    if(item.uuid)
                                                        filterProperties.productUuid = item.product.uuid;
                
                                                    let url = drilldownPageSelection(filterProperties, pageType, true);
                                                    return url;
                                                }}
                                            >
                                                {item.product.name ?? "Unknown"}
                                            </Link>
                                        </>

                                    
                                ) : (
                                    <Typography className={fontClass.text__unknown}>
                                        {translate("-")}
                                    </Typography>
                                )
                            }

                        </Box>
                    </TableCell>
                    <TableCell className={tableClass.table__cell} style={{minWidth:"185px"}}>
                        {
                            item.serial_number.name ? (
                                <Link 
                                    target="_blank" rel="noopener noreferrer"
                                    className={fontClass.text__hyperlink}
                                    to={() => {
            
                                        let filterProperties = {};
            
                                        if(item.serial_number.uuid)
                                            filterProperties.enc = item.serial_number.uuid;
            
                                        let url = drilldownPageSelection(filterProperties, pageType, true);
                                        return url;
                                    }}
                                >
                                    {item.serial_number.name}
                                </Link>
                            ) : (
                                <Typography className={fontClass.text__unknown}>
                                    {translate("-")}
                                </Typography>
                            )
                        }

                </TableCell>
                    <TableCell align={"left"} className={tableClass.table__cell} style={{minWidth:"140px", maxWidth: "140px"}}>
                        <Typography className={fontClass.text__table_cell}>{item.submit_date ?? '-'}</Typography>
                    </TableCell>
                    <TableCell className={tableClass.table_cell} style={{minWidth:"60px", width: "60px"}}>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <ActionButton actionItems={actionItems} />
                        </div>
                    </TableCell>
                </TableRow>

            )
            
            item.render = row;
        })
        
        return imageProps;
    }

    return (
        <ContentCard>
            <Box marginBottom="24px" display="flex" justifyContent="space-between" alignContent="center">
                <Typography className={fontClass.text__card_title}>
                    {getCardTitle(currentFilter.campaignType)}
                </Typography>
            </Box>
            <DynamicTable 
                component={ Paper }
                sort={sort}
                pagination={pagination}
                columns={columns}
                data={renderData(data.respondentsSingleCampaignDatalist)}
                status={status.isFetchingRespondentsSingleCampaignDatalist}
                translate={translate}
            />
        </ContentCard>
    )
}