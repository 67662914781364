import React from "react"
import {
  Box,
  Paper,
  Typography,
  makeStyles,
} from "@material-ui/core"
import { Skeleton } from '@material-ui/lab'
import { getLang } from "app/feature/constants"

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2, 2),
  },
  infoPanel: {
    display: "grid",
    gridTemplateColumns: "4fr 1fr 1fr",
    columnGap: theme.spacing(2)
  },
  infoBox: {
    borderLeft: "1px solid #D0D5DD",
    textAlign: "right",
    paddingLeft: 20
  }
}))

export default function LooseItemInfoCardComponent({
  looseItem,
  isFetching,
  lang
}) {
  const classes = useStyles()

  return (
    <Paper elevation={0} className={classes.container}>
      {!isFetching ? (
        <Box display="flex" justifyContent="space-between" className={classes.infoPanel}>
          <Box display="flex" mr={1} alignItems="center">
            <Typography variant="h5">
              <b>{looseItem?.code}</b>
            </Typography>
          </Box>
          <Box className={classes.infoBox}>
            <Typography variant="subtitle1" color="textSecondary">
              {getLang(lang, "label.QUANTITY")}
            </Typography>
            <Typography variant="subtitle1">
              <Typography display="inline" style={{ fontWeight: "700" }}>{looseItem?.quantity || 0}</Typography>{' '}
              <Typography display="inline" color="textSecondary" >{getLang(lang, "label.ITEMS")}</Typography>
            </Typography>
          </Box>
          <Box className={classes.infoBox}>
            <Typography variant="subtitle1" color="textSecondary">
              {getLang(lang, "label.PACKAGES")}
            </Typography>
            <Typography variant="subtitle1">
              <b>{looseItem?.packages || 0}</b>
            </Typography>
          </Box>
        </Box>
      ): (
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" flex={1}>
            <Box display="flex" alignItems="center" justifyContent="center" mr={1}>
              <Skeleton variant='rect' width={40} height={40} />
            </Box>
            <Skeleton variant="text" height={40} width={100}/>
          </Box>
          <Skeleton variant="text" height={40}  width={100}/>
        </Box>
      )}
    </Paper>
  )
}