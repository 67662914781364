import { createSlice } from "@reduxjs/toolkit";
import { REPORTING } from "modules/reporting/constants";
import { sortByNameAscending } from "modules/reporting/utils/helper";

import {
    getFilterBranchesData,
    getFilterCountryCityData,
} from "../action";

const initialState = {
    branch: {},
    country: {},
    city: {}
}

const reportingFilterSlice = createSlice({
    name: REPORTING,
    initialState,
    reducers: {
        initReportingFilter: (state) => {
            state.branch = initialState.branch;
            state.country = initialState.country;
            state.city = initialState.city;
        }
    },
    extraReducers : {
        // FILTER -- START
        // BRANCH
        [getFilterBranchesData.fulfilled]: (state, action) => {
            let data = {};
            data.branches = action.payload.data;
            data.isSuccess = action.payload.success;
            data.noResult = (data.branches.length === 0);

            action.payload.data = data;

            let option = [];
            option.push({
                value: null,
                name: "All",
            });

            let countries = []
            data.branches.forEach((item) => {
                countries.push({
                    value: item.node_id,
                    name: item.name,
                });
            });
            countries = sortByNameAscending(countries);
            option = option.concat(countries);
            data.options = option;

            state.branch = data;
        },

        // COUNTRY-CITY
        [getFilterCountryCityData.fulfilled]: (state, action) => {
            let data = {};
            data.info = action.payload.data;
            data.isSuccess = action.payload.success;
            data.noResult = (data.info.length === 0);

            let option = [];
            option.push({
                value: null,
                name: "All",
            });

            let countries = [];
            data.info.forEach((item) => {
                countries.push({
                    value: item.country_code.toLowerCase(),
                    name: item.name,
                });
            });

            countries = sortByNameAscending(countries);
            option = option.concat(countries);
            data.options = option;

            state.country = data;

            data = {};
            data.info = action.payload.data;
            data.isSuccess = action.payload.success;
            data.noResult = (data.info.length === 0);

            option = [];
            option.push({
                value: null,
                name: "All",
                country_code: null
            });

            let cities = [];
            data.info.forEach((item) => {
                item.cities.forEach((city) => {
                    cities.push({
                        value: city.id,
                        name: city.name,
                        country_code: item.country_code,
                    });
                });
            });

            cities = sortByNameAscending(cities);
            option = option.concat(cities);
            data.options = option;
            state.city = data;
        },
        // FILTER -- END
    }
})

export const {
    initReportingFilter
} = reportingFilterSlice.actions;

export const reportingFilterReducer = reportingFilterSlice.reducer;