import React from "react";
import CircularChartCardComponent from "./circularChartCard.component";

export default function CircularChartCardContainer ({
  type,
  labels,
  data,
  title
}) {
  return (
    <CircularChartCardComponent title={title} type={type} data={data} labels={labels} />
  )
}