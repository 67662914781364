import React, { useEffect, useState } from "react"
import GeneratedProductListPanelComponent from './generatedProductListPanel.component'

function GeneratedProductListPanelContainer({
  isFetching,
  paginationOptions,
  generatedProducts,
  clearProducts,
  deleteProducts,
  handleSubmit,
  fieldRequireError,
  lang
}) {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [selectAll, setSelectAll] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState([])
  const [products, setProducts] = useState([]);
  const [currentPageData, setCurrentPageData] = useState([]) // split data into chunk for update

  // useEffect(() => {
  //   if (!!products.length && selectedIndex.length >= products.length) {
  //     setSelectAll(true)
  //   } 
  // }, [selectedIndex, products])

  useEffect(() => {
    if (products.length) {
      setCurrentPageData(products.slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage))
    }
  }, [page, rowsPerPage, products])

  const handleAction = (value) => {
    if (value === "Delete Selected") {
      if (!selectAll) {
        deleteProducts(selectedIndex)
      } else {
        clearProducts()
      }
      setSelectedIndex([])
      setSelectAll(false)
    }
  }

  const handleSelectAvailable = () => {
    let temp = [...products]
    let indexList = [...selectedIndex]
    for (let i = page * rowsPerPage; i < page * rowsPerPage + rowsPerPage && i < temp.length; i ++) {
      if (!selectAll) {
        if (!selectedIndex.includes(temp[i].index)) {
          indexList.push(temp[i].index)
        }
      } else {
        indexList = indexList.filter((id) => id !== temp[i].index)
      }

      temp[i] = {
        ...temp[i],
        isSelected: true
      }
    }
    setProducts(temp)
    setSelectedIndex(indexList)
  }

  const handleSelectAll = (value) => {
    let temp = [...products]
    temp = temp.map(t => ({
        ...t,
        isSelected: value
    }))
    setProducts(temp)
    setSelectedIndex([])
    setSelectAll(value)
  }

  const handleSelectProduct = (item) => {
    if (!!item.value) {
      if (!selectedIndex.includes(item.index)) {
        let newList = selectedIndex.concat([item.index])
        setSelectedIndex(newList)
      }
    } else {
      if (selectAll) {
        if (!selectedIndex.includes(item.index)) {
          let tempList = selectedIndex.concat([item.index])
          if (tempList.length === products.length) {
            setSelectedIndex([])
            setSelectAll(false)
          } else {
            setSelectedIndex(tempList)
          }
        }
      } else {
        setSelectedIndex(selectedIndex.filter(i => i !== item.index))
      }
    }
  }

  useEffect(() => {
    setProducts([...generatedProducts])
  }, [generatedProducts])

  const updateProductDetail = (item) => {
    let temp = [...currentPageData]
    let index = temp.findIndex(t => t.index === item.index)
    temp[index] = {
      ...temp[index],
      [item.name]: item.value
    }
    setCurrentPageData(temp)

    if (item.name === "isSelected") {
      handleSelectProduct(item)
    }
  }

  const handleChangePage = (event, newPage) => {
    if (currentPageData.length) {
      let temp = [...products]
      temp.splice(page * rowsPerPage, rowsPerPage, ...currentPageData);
      setProducts(temp)
    }

    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    if (currentPageData.length) {
      let temp = [...products]
      temp.splice(page * rowsPerPage, rowsPerPage, ...currentPageData);
      setProducts(temp)
    }

    setPage(0)
    setRowsPerPage(event.target.value)
  }

  const handleSubmitProduct = () => {
    let temp = [...products]
    if (currentPageData.length) {
      temp.splice(page * rowsPerPage, rowsPerPage, ...currentPageData);
      setProducts(temp)
    }
    handleSubmit(temp)
  }

  return (
    <>
      <GeneratedProductListPanelComponent
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        page={page}
        rowsPerPage={rowsPerPage}
        paginationOptions={paginationOptions}
        clearProducts={clearProducts}
        deleteProducts={deleteProducts}
        handleAction={handleAction}
        isFetching={isFetching}
        selectedIndex={selectedIndex}
        selectedCount={!selectAll ? selectedIndex.length : products.length - selectedIndex.length}
        isSelectAll={selectAll}
        handleSelectAll={handleSelectAll}
        updateProductDetail={updateProductDetail}
        products={currentPageData}
        handleSubmit={handleSubmitProduct}
        totalRecords={products.length}
        fieldRequireError={fieldRequireError}
        lang={lang}
        handleSelectAvailable={handleSelectAvailable}
      />
    </>
  )
}

export default GeneratedProductListPanelContainer