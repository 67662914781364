import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import WarrantyPageComponent from "./warranty.page";
import { WARRANTY_LIST_VIEW, WARRANTY_LIST_ADD } from "lib/constants/accessRights"
import LoadingComponent from "components/loading";
import { getLang } from "app/feature/constants";

function WarrantyPageContainer() {
  const history = useHistory()
  const hasWarrantyViewAccessRight = useSelector(state => state.profile.accessRights.includes(WARRANTY_LIST_VIEW))
  const hasWarrantyAddAccessRight = useSelector(state => state.profile.accessRights.includes(WARRANTY_LIST_ADD))
  const [activeIndex, setActiveIndex] = useState(0);
  const lang = useSelector(state => state.constant.languages);

  useEffect(() => {
    if(!hasWarrantyViewAccessRight){
      history.push('/admin/dashboard')
    }
  }, [history, hasWarrantyViewAccessRight])

  if(!hasWarrantyViewAccessRight) return <LoadingComponent />;

  const tabLabels = [getLang(lang, 'label.WARRANTY_LIST')];

  const handleChange = (event, index) => {
    setActiveIndex(index);
  };

  return (
    <WarrantyPageComponent
      hasWarrantyAddAccessRight={hasWarrantyAddAccessRight}
      handleChange={handleChange}
      activeIndex={activeIndex}
      tabLabels={tabLabels}
      lang={lang}
    />
  );
}

export default WarrantyPageContainer;
