import React from "react"
import { makeStyles, Box, Button, Typography, Checkbox, FormControlLabel } from '@material-ui/core';
import { Formik } from "formik";
import Input from "components/input/inputTextField";
import ActionSelect from "components/select/actionSelect";
import MyDealerListTable from "../../table/myDealerListTable/myDealerListTable.container";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles((theme) => ({
  searchContainer: {
    backgroundColor: theme.palette.background.paper,
    display: "grid",
    gridTemplateColumns: "5fr 1.25fr 1fr 1fr",
    columnGap: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1)
    }
  },
  filterMainContainer: {
    backgroundColor: theme.palette.background.paper,
    paddingTop: "12px",
    paddingBottom: "12px",
    paddingRight: "20px",
    paddingLeft: "20px",
    marginBottom: "12px"
  },
  filterFieldContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr",
    columnGap: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1)
    }
  },
  button: {
    display: "flex",
    alignItems: "center",
    padding: 0,
    minWidth: 0,
    marginLeft: theme.spacing(1)
  },
  inputRootProduct: {
    paddingTop: "8px !important",
    paddingBottom: "7px !important",
    paddingLeft: "12px !important",
    paddingRight: "12px !important",
    borderRadius: "4px",
    backgroundColor: "#eceff0",
    fontSize: "1rem",
    fontWeight: 500
  },
  paperRoot: {
    width: "217px",
  },
  filterCheckBoxContainer: {
    marginRight: "1em",
    marginLeft: "1em",
    width: "100%"
  }
}));

export default function MyDealerListPanelComponent({
  hasStockflowDealerViewAccessRight,
  hasStockflowDealerEditAccessRight,
  hasStockflowDealerAddAccessRight,
  rows,
  rowsPerPage,
  paginationOptions,
  page,
  totalFiltered,
  isFetching,
  isError,
  isSearchResult,
  handleReload,
  handleSearch,
  handleViewDetail,
  handleResendEmail,
  handleReplaceDealerDialog,
  handleEditPointDialog,
  handleChangePage,
  handleChangeRowPerPage,
  handleUpdateDealerStatus,
  handleVisibleInviteDialog,
  handleLoadIncentivePointDialog,
  handleBulkUpdateDealerStatus,
  handleSelectAllDealer,
  handleSelectAvailable,
  handleDeselectAll,
  handleSelectDealer,
  isSelected,
  isSelectAll,
  selectedCount,
  selectedUUIDs,
  lang
}) {

  const classes = useStyle();

  return (
    <>
      <Formik
        initialValues={{
          search: "",
          searchByFlag: false,
        }}
        onSubmit={handleSearch}
        onReset={handleSearch}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <Box p={2} mb={2} className={classes.searchContainer}>
              <Input
                hiddenLabel
                variant="filled"
                size="medium"
                placeholder={getLang(lang, 'placeholder.SEARCH')}
                margin="none"
                disabled={isFetching}
                style={{
                  justifyContent: "center"
                }}
                InputProps={{
                  disableUnderline: true,
                  margin: "none",
                  classes: { input: classes.inputRootProduct }
                }}
                {...formik.getFieldProps("search")}
              />
              <FormControlLabel
                className={classes.filterCheckBoxContainer}
                disabled={isFetching}
                control={
                  <Checkbox
                    {...formik.getFieldProps("searchByFlag")}
                    checked={formik.values.searchByFlag}
                  />
                }
                label={
                  <Typography variant="body" style={{ whiteSpace: 'nowrap' }} >
                    {getLang(lang, 'label.SEARCH_BY_UPLINE')}
                  </Typography>
                }
              />
              <Button
                variant='text'
                disableElevation
                color="primary"
                size='small'
                disabled={isFetching}
                onClick={formik.handleReset}
              >
                {getLang(lang, 'label.CLEAR')}
              </Button>
              <Button
                disabled={isFetching}
                variant="contained"
                disableElevation
                color='primary'
                size='small'
                type='submit'
              >
                {getLang(lang, 'label.SEARCH')}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
      <Box style={{ display: "flex", justifyContent: "space-between", paddingBottom: "12px" }}>
        <Box style={{ display: "flex", alignItems: "center" }}>
          {selectedCount !== 0 ? (
            (isSelectAll && selectedCount === totalFiltered) ? (
              <Typography variant='body2' style={{ color: "#FDB022" }}>{getLang(lang, 'label.SELECTED')} {selectedCount} {selectedCount > 1 ? getLang(lang, 'label.DEALERS') : getLang(lang, 'label.DEALER')} [{getLang(lang, 'label.ALL')}]</Typography>
            ) : (
              <Typography variant='body2' color='primary'>{getLang(lang, 'label.SELECTED')} {selectedCount} {selectedCount > 1 ? getLang(lang, 'label.DEALERS') : getLang(lang, 'label.DEALER')}</Typography>
            )
          ) : (
            ""
          )}
        </Box>
        <ActionSelect
          label={getLang(lang, 'label.ACTION')}
          dropdownItem={[getLang(lang, 'label.ACTIVATE_SELECTED'), getLang(lang, 'label.DEACTIVATE_SELECTED')]} //"Bulk Edit"
          handleChange={(value) => handleBulkUpdateDealerStatus(value)}
          disabled={isFetching || !isSelected}
          isFetching={false}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          style={{
            width: "100px",
            borderRadius: "8px",
            backgroundColor: "#ffffff",
            border: "1px solid #D0D5DD"
          }}
        />
      </Box>
      <Box>
        <MyDealerListTable
          hasStockflowDealerViewAccessRight={hasStockflowDealerViewAccessRight}
          hasStockflowDealerEditAccessRight={hasStockflowDealerEditAccessRight}
          hasStockflowDealerAddAccessRight={hasStockflowDealerAddAccessRight}
          rows={rows}
          rowsPerPage={rowsPerPage}
          paginationOptions={paginationOptions}
          page={page}
          totalFiltered={totalFiltered}
          isFetching={isFetching}
          isError={isError}
          isSearchResult={isSearchResult}
          handleReload={handleReload}
          handleViewDetail={handleViewDetail}
          handleReplaceDealerDialog={handleReplaceDealerDialog}
          handleEditPointDialog={handleEditPointDialog}
          handleChangePage={handleChangePage}
          handleChangeRowPerPage={handleChangeRowPerPage}
          handleUpdateDealerStatus={handleUpdateDealerStatus}
          handleVisibleInviteDialog={handleVisibleInviteDialog}
          handleLoadIncentivePointDialog={handleLoadIncentivePointDialog}
          handleSelectAllDealer={handleSelectAllDealer}
          handleSelectAvailable={handleSelectAvailable}
          handleDeselectAll={handleDeselectAll}
          handleSelectDealer={handleSelectDealer}
          isSelectAll={isSelectAll}
          selectedUUIDs={selectedUUIDs}
          handleResendEmail={handleResendEmail}
        />
      </Box>
    </>
  )
}