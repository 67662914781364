import React from "react";
import {
  Box,
  Button,
  makeStyles,
  TextField,
  Grow,
  Paper,
  ClickAwayListener,
  Popper
} from "@material-ui/core";
import Picker from 'react-mobile-picker';
import InputAdornment from '@material-ui/core/InputAdornment';
import EventIcon from '@material-ui/icons/Event';
import {getLang} from "../../../app/feature/constants";
import moment from "moment";

const useStyles = makeStyles((_) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#eceff0",
    width: "100%",
    height: 34,
    overflow: "hidden"
  },
}));

export default function DatePickerDialogComponent({
  label,
  isOpen,
  handleClick,
  handleClose,
  lang,
  disablePast,
  disableFuture,
  months,
  monthsNoPast,
  monthsNoFuture,
  startYear,
  date,
  defaultDate,
  pickerValue,
  handlePickerChange,
  getDayArray,
  selectDate,
  anchorEl,
  displayFormat,
  enableTime,
  InputProps,
  inputProps,
  InputLabelProps,
  style,
  forceDisplay,
}) {
  const classes = useStyles();

  return (
    <>
      <TextField
        className={classes.root}
        defaultValue={defaultDate || label}
        fullWidth
        style={{ height: 34, ...style }}
        InputProps={{
          endAdornment: (
            <Box p={1}>
              <InputAdornment position="end">
                <EventIcon/>
              </InputAdornment>
            </Box>
          ),
          color: "rgba(0, 0, 0, 0.87)",
          disableUnderline: true,
          ...InputProps,
        }}
        InputLabelProps={{
          ...InputLabelProps,
          style: {
            fontSize: "0.875rem",
            color: "#a4a6a8",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            fontWeight: 500,
            ...InputLabelProps?.style
          },
        }}
        inputProps={{
          ...inputProps,
          style: {
            padding: 10,
            fontSize: "0.875rem",
            color: "#a4a6a8",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            fontWeight: 500,
            ...inputProps?.style,
          },
          readOnly: true
        }}
        value={forceDisplay ? forceDisplay : date && date.format(displayFormat)}
        onClick={handleClick}
      />

      <Popper
        open={isOpen}
        style={{ zIndex: 99, width: enableTime ? "480px" : "300px"}}
        anchorEl={anchorEl}
        role={undefined}
        transition
        disablePortal
        placement="bottom-start"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "center top" : "center bottom"
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <Box>
                  <Box p={1} px={9}>
                    <Picker
                      value={pickerValue}
                      onChange={handlePickerChange}
                      wheelMode="natural"
                    >
                      <Picker.Column name="day" style={{ marginLeft: 8, marginRight: 8 }}>
                        {getDayArray(Number(pickerValue.year), pickerValue.month)
                          .slice(
                            0,
                            disableFuture
                            && pickerValue.year === moment().year().toString() &&
                            pickerValue.month === moment().format('MMMM')
                              ? moment().date()
                              : 31
                          )
                          .slice(
                            disablePast && pickerValue.year === moment().year().toString() && pickerValue.month === moment().format('MMMM')
                              ? moment().date() - 1
                              : 0
                          )
                          .map((day) => (
                            <Picker.Item key={day} value={day}>
                              {({ selected }) => (
                                <Box className={selected ? 'font-semibold text-neutral-900' : 'text-neutral-400'}>
                                  {day}
                                </Box>
                              )}
                            </Picker.Item>
                          )
                        )}
                      </Picker.Column>
                      <Picker.Column name="month" style={{ marginLeft: 8, marginRight: 8 }}>
                        {(disableFuture && pickerValue.year === moment().year().toString() ? monthsNoFuture : disablePast && pickerValue.year === moment().year().toString() ? monthsNoPast : months).map((month) => (
                          <Picker.Item key={month} value={month}>
                            {({ selected }) => (
                              <Box className={selected ? 'font-semibold text-neutral-900' : 'text-neutral-400'}>
                                {getLang(lang, "label." + month.toUpperCase() + "_MONTH")}
                              </Box>
                            )}
                          </Picker.Item>
                        ))}
                      </Picker.Column>
                      <Picker.Column name="year" style={{ marginLeft: 8, marginRight: 8 }}>
                        { Array.from({ length: disableFuture ? 151 : disablePast ? 100 : 251 }, (_, i) => `${disablePast ? parseInt(new moment().format('YYYY')) + i : startYear + i}`).map((year) => (
                          <Picker.Item key={year} value={year}>
                            {({ selected }) => (
                              <Box className={selected ? 'font-semibold text-neutral-900' : 'text-neutral-400'}>
                                {year}
                              </Box>
                            )}
                          </Picker.Item>
                        ))}
                      </Picker.Column>
                      {enableTime ? (
                        <>
                          <Picker.Column name="hour" style={{ marginLeft: 8, marginRight: 8 }}>
                            {Array.from({length: 24}, (_, i) => String("0" + i).slice(-2)).map((hour) => (
                              <Picker.Item key={hour} value={hour}>
                                {({ selected }) => (
                                  <Box className={selected ? 'font-semibold text-neutral-900' : 'text-neutral-400'}>
                                    {hour}
                                  </Box>
                                )}
                              </Picker.Item>
                            ))}
                          </Picker.Column>
                          <Picker.Column name="minute" style={{ marginLeft: 8, marginRight: 8 }}>
                            {Array.from({length: 60}, (_, i) => String("0" + i).slice(-2)).map((minute) => (
                              <Picker.Item key={minute} value={minute}>
                                {({ selected }) => (
                                  <Box className={selected ? 'font-semibold text-neutral-900' : 'text-neutral-400'}>
                                    {minute}
                                  </Box>
                                )}
                              </Picker.Item>
                            ))}
                          </Picker.Column>
                        </>
                      ) : null}
                    </Picker>
                  </Box>
                  <Box p={1} style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      color="primary"
                      variant="contained"
                      type="button"
                      onClick={()=>{selectDate()}}
                    >
                      {getLang(lang, "label.CONFIRM")}
                    </Button>
                  </Box>
                </Box>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
