import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import BranchPanelComponent from "./branchPanel.component";
import { connect, useSelector, useDispatch } from "react-redux";
import {
  createBranch,
  editBranch,
  fetchBranchList,
  editBranchStatus,
  setBranchProfile,
} from "modules/branch/redux";
import { unwrapResult } from "@reduxjs/toolkit";
import BranchDialog from "../../dialog/branchDialog";
import Modal from "../../modal";
import { NODE_STATUS_ACTIVE } from "modules/account/constants";
import ConfirmationDialog from "components/dialog/confirmation";
import {
  BRANCH_ADD,
  BRANCH_EDIT,
  BRANCH_ACTIVATE_OR_DEACTIVATE,
} from "lib/constants/accessRights";
import { generatePaginationOptions } from "lib/helper";
import { fetchEditBranchDropdown } from "modules/branch/redux";
import { getLang } from "app/feature/constants";
import {setImgFormatAndSize} from "../../../../../lib/FileHelper";
import {FolderType} from "../../../../../lib/constants/aws_s3";
import {hideBackdrop} from "../../../../backdrop";
import {addAlert} from "../../../../notification";
import {uploadImageToS3} from "../../../../../app/feature";
import {isDataUrl} from "../../../../../lib/generalUtility";

const BranchPanelContainer = forwardRef(
  ({ fetchBranchList, createBranch, editBranch, editBranchStatus, setProfile }, ref) => {
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [page, setPage] = useState(0);
    const [search, setSearch] = useState("");
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [edittingBranch, setEdittingBranch] = useState(null);
    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
    const [confirmationDescription, setConfirmationDescription] = useState("");
    const [branchIdToChangeStatus, setBranchIdToChangeStatus] = useState("");
    const [branchStatusToChange, setBranchStatusToChange] = useState(true);
    const [editBranchStatusLoading, setEditBranchStatusLoading] =
      useState(false);
    const [branchStatusMode, setBranchStatusMode] = useState("");
    const [paginationOptions, setPaginationOptions] = useState([]);

    const hasBranchAddAccessRight = useSelector((state) =>
      state.profile.accessRights.includes(BRANCH_ADD)
    );
    const hasBranchEditAccessRight = useSelector((state) =>
      state.profile.accessRights.includes(BRANCH_EDIT)
    );
    const hasBranchActivateOrDeactivateAccessRight = useSelector((state) =>
      state.profile.accessRights.includes(BRANCH_ACTIVATE_OR_DEACTIVATE)
    );
    const totalRecords = useSelector((state) => state.branch.totalRecords);
    const lang = useSelector((state) => state.constant.languages);
    const dispatch = useDispatch();

    useEffect(() => {
      fetchBranchList({
        length: rowsPerPage,
        start: rowsPerPage * page,
        order: "asc",
        search,
      });
    }, [fetchBranchList, page, rowsPerPage, search]);

    useEffect(() => {
      setPaginationOptions(generatePaginationOptions(totalRecords));
    }, [totalRecords]);

    const handleReload = () => {
      fetchBranchList({
        length: rowsPerPage,
        start: rowsPerPage * page,
        order: "asc",
        ...search,
      });
    };

    function handleSearch(value) {
      setSearch(value);
    }

    const handlePageChange = (event, newPage) => {
      setPage(newPage);
    };

    const handleRowPerPageChange = (event) => {
      setRowsPerPage(event.target.value);
      setPage(0);
    };

    useImperativeHandle(ref, () => ({
      openModal: () => {
        openCreateModal();
      },
    }));

    function handleFetchBranchList() {
      const start = rowsPerPage * page;

      fetchBranchList({
        start,
        length: rowsPerPage,
        search,
        order: "asc",
      });
    }

    function openCreateModal() {
      if (hasBranchAddAccessRight) {
        setIsCreateModalOpen(true);
      }
    }

    function closeCreateModal() {
      if (hasBranchAddAccessRight) {
        setIsCreateModalOpen(false);
      }
    }

    function closeEditModal() {
      if (hasBranchEditAccessRight) {
        setIsEditModalOpen(false);
      }
    }

    function handleEditClick(branch) {
      dispatch(fetchEditBranchDropdown({ uuid: branch.id }));
      if (hasBranchEditAccessRight) {
        branch.postalCode = branch.postalCode.trim();
        setEdittingBranch({
          id: branch.id,
          parentBranch: branch.parentBranch,
          branchName: branch.branchName,
          shortName: branch.shortName,
          description: branch.description,
          addressLine1: branch.addressLine1,
          addressLine2: branch.addressLine2,
          city: branch.city,
          country: branch.country,
          postalCode: branch.postalCode,
          state: branch.state,
          contact: branch.contact,
          contactCode: branch.contactCode,
          email: branch.email,
          timezone: branch.timezone,
          parentName: branch.parentName,
          branchGroup: branch.branchGroup,
          profileImageUrl: branch.profileImageUrl,
          socialMediaUrls: branch.socialMediaUrls,
          isShowContact: branch.isShowContact,
          isShowEmail: branch.isShowEmail,
          whiteLabel: branch.whiteLabel,
          landingPageLanguage: branch.landingPageLanguage,
        });
        setIsEditModalOpen(true);
      }
    }

    async function handleCreateSubmit(values, { setSubmitting }) {
      values.contact = values.contact.replace(/^0/, "");
      values.postalCode = values.postalCode.trim();

      if (hasBranchAddAccessRight) {
        createBranch(values)
          .then(unwrapResult)
          .then(async (response) => {
            if (values.profileImage && isDataUrl(values.profileImage)) {
              const url = await uploadImage(values.profileImage, response.data.id);
              await setProfile({id: response.data.id, url}).then(unwrapResult).then(() => {
                handleFetchBranchList();
                closeCreateModal();
              });

              return;
            }

            handleFetchBranchList();
            closeCreateModal();
          })
          .catch((error) => {
            setSubmitting(false);
          });
      }
    }

    async function uploadImage(base64Image, branchId) {
      let url = ""

      base64Image = await setImgFormatAndSize(base64Image, 2160, 2160, "image/webp");

      const uploadInfo = {
        uploadType: FolderType.branchProfile,
        base64Image: base64Image,
        fileName: `branchProfile${branchId}${Date.now()}.webp`,
        id: branchId
      }

      await dispatch(uploadImageToS3(uploadInfo))
        .then(unwrapResult)
        .then(imageUrl => {
          url = imageUrl.split('?')[0]
        })
        .catch(error => {
          dispatch(hideBackdrop());
          dispatch(
            addAlert({
              severity: "error",
              message: error.message || getLang(lang,"message.error.FAILED_UPLOAD_PICTURE")
            })
          )
        })

      return url
    }

    async function handleEditSubmit(values, { setSubmitting }) {
      values.contact = values.contact.replace(/^0/, "");
      if (hasBranchEditAccessRight) {
        editBranch({...values,
          profileImage: values.profileImage && isDataUrl(values.profileImage)
            ? await uploadImage(values.profileImage, values.id)
            : values.profileImage,
        })
          .then(unwrapResult)
          .then((response) => {
            handleFetchBranchList();
            closeEditModal();
          })
          .catch((error) => {
            setSubmitting(false);
          });
      }
    }

    function handleStatusSubmit() {
      if (hasBranchActivateOrDeactivateAccessRight) {
        setEditBranchStatusLoading(true);
        editBranchStatus({
          id: branchIdToChangeStatus,
          status: branchStatusToChange,
        })
          .then(unwrapResult)
          .then(() => handleFetchBranchList())
          .finally(() => {
            closeConfirmation();
            setEditBranchStatusLoading(false);
          });
      }
    }

    function handleStatusChange(id, currentStatus) {
      if (hasBranchActivateOrDeactivateAccessRight) {
        if (currentStatus === NODE_STATUS_ACTIVE) {
          setConfirmationDescription(
            getLang(lang, "paragraph.INACTIVE_BRANCH_QUESTION")
          );
          setBranchIdToChangeStatus(id);
          setBranchStatusToChange(false);
          setBranchStatusMode("danger");
        } else {
          setConfirmationDescription(
            getLang(lang, "paragraph.ACTIVE_BRANCH_QUESTION")
          );
          setBranchIdToChangeStatus(id);
          setBranchStatusToChange(true);
          setBranchStatusMode("");
        }
        openConfirmation();
      }
    }

    function openConfirmation() {
      if (hasBranchActivateOrDeactivateAccessRight) {
        setConfirmationDialogOpen(true);
      }
    }

    function closeConfirmation() {
      if (hasBranchActivateOrDeactivateAccessRight) {
        setConfirmationDialogOpen(false);
      }
    }

    return (
      <>
        <BranchPanelComponent
          hasBranchAddAccessRight={hasBranchAddAccessRight}
          hasBranchEditAccessRight={hasBranchEditAccessRight}
          hasBranchActivateOrDeactivateAccessRight={
            hasBranchActivateOrDeactivateAccessRight
          }
          handleFetchBranchList={handleFetchBranchList}
          handleSearch={handleSearch}
          search={search}
          rowsPerPage={rowsPerPage}
          handlePageChange={handlePageChange}
          handleRowPerPageChange={handleRowPerPageChange}
          page={page}
          totalRecords={totalRecords}
          openCreateModal={openCreateModal}
          handleEditClick={handleEditClick}
          handleStatusChange={handleStatusChange}
          openConfirmation={openConfirmation}
          handleReload={handleReload}
          paginationOptions={paginationOptions}
          lang={lang}
        />
        <Modal isOpen={isCreateModalOpen} maxWidth="800px">
          <BranchDialog
            isOpen={isCreateModalOpen}
            handleClose={closeCreateModal}
            handleSubmit={handleCreateSubmit}
          />
        </Modal>
        <Modal isOpen={isEditModalOpen} maxWidth="800px">
          <BranchDialog
            isOpen={isEditModalOpen}
            handleClose={closeEditModal}
            handleSubmit={handleEditSubmit}
            isEditMode={true}
            branch={edittingBranch}
          />
        </Modal>
        <ConfirmationDialog
          isLoading={editBranchStatusLoading}
          isOpen={confirmationDialogOpen}
          handleClose={closeConfirmation}
          handleProceed={handleStatusSubmit}
          title={getLang(lang, "label.CONFIRMATION")}
          description={confirmationDescription}
          type={branchStatusMode}
        />
      </>
    );
  }
);

const mapDispatchToProps = (dispatch) => ({
  fetchBranchList: (values) => dispatch(fetchBranchList(values)),
  createBranch: (values) => dispatch(createBranch(values)),
  editBranch: (values) => dispatch(editBranch(values)),
  editBranchStatus: (values) => dispatch(editBranchStatus(values)),
  setProfile: (values) => dispatch(setBranchProfile(values)),
});

export default connect(null, mapDispatchToProps, null, { forwardRef: true })(
  BranchPanelContainer
);
