import { Box, CircularProgress, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
    trendingCard: {
        padding: "12px",
        backgroundColor: "#FFFFFF",
        borderRadius: "12px",
        border: "1px solid #D0D5DD",
        height: "138px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
}))


export default function TrendingCardLoadingComponent(){
    const classes = useStyles();
    return (
        <Box className={classes.trendingCard}>
            <CircularProgress />
        </Box>
    )
}