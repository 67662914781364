import React from "react";
import {
  makeStyles,
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from "@material-ui/core";
import { StyledTableCell, StyledTableRow } from "components/table";
import { TablePaginationActions } from "components/tablePagination";
import { ActionButton } from 'components/button';
import { SkeletonTableRow } from 'components/skeleton/skeleton.component';
import AntSwitch from "components/switch/switch";
import Alert from "@material-ui/lab/Alert";
import RefreshTable from "components/table/refreshTable";
import { getLang } from "app/feature/constants";
import StickyLeftTableCell from "components/table/stickyLeftTableCell";
import StickyRightTableCell from "components/table/stickyRightTableCell";
import moment from "moment";
import "moment/min/locales.min";

const useStyle = makeStyles(theme => ({
  emptyContainer: {
    backgroundColor: theme.palette.background.paper,
    textAlign: "center"
  },
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    borderCollapse: "separate",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
    },
    "& th,td": {
      fontSize: ".75rem",
      borderRight: "1px solid rgba(58, 77, 84, 0.1)",
      padding: theme.spacing(1, 2, 1, 2),
    },
    "& th": {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightBold,
    },
    "& th:last-child, td:last-child": {
      width: 37,
      borderRight: "0px",
    },
    "& th th": {
      borderBottom: "none !important",
    },
    "& td td": {
      borderBottom: "none !important",
    },
  },
}));

export default function RedemptionsTableComponent({
  hasPointCutAddAccessRight,
  hasPointCutEditAccessRight,
  hasPointCutActivateOrDeactivateAccessRight,
  data,
  isFetching,
  page,
  search,
  rowsPerPage,
  totalRecords,
  handleChangePage,
  language,
  handleChangeRowsPerPage,
  handleCreate,
  handleView,


  handleStatusChange,
  isLoadingPointCutError,
  handleReload,
  paginationOptions,
  lang
}) {
  const classes = useStyle();
  
  if(language==="ZH")
  {
    moment.locale('zh-cn')
  }else if(language==="TL")
  {
    moment.locale('tl-ph')
  }else
  {
    moment.locale(language)
  }

  return (
    <>
      {isFetching || data.length > 0 ? (
        <TableContainer>
          <Table className={classes.table} aria-label="product table" stickyHeader>
            <TableHead>
              <TableRow>
                <StickyLeftTableCell>
                  <StyledTableCell>{getLang(lang, "label.NUM")}</StyledTableCell>
                </StickyLeftTableCell>
                <StyledTableCell style={{ textAlign: "right" }}>{getLang(lang, "label.POINT_CUT")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.DATE_CREATED")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.PERIOD")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.TOTAL_GIFT")}</StyledTableCell>
                {/* <StyledTableCell>Added by</StyledTableCell> */}
                <StyledTableCell>{getLang(lang, "label.STATUS")}</StyledTableCell>
                <StickyRightTableCell>
                  <StyledTableCell>{getLang(lang, "label.ACTION")}</StyledTableCell>
                </StickyRightTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isFetching
                ? [...new Array(10)].map((i, index) => (
                  <SkeletonTableRow key={index} columnCount={7} />
                ))
                : data.map((row, index) => {
                  let status = row.status === "active" ? true : false
                  const actionItems = [
                    {
                      label: hasPointCutEditAccessRight ? getLang(lang, "label.VIEW_EDIT") : getLang(lang, "label.EDIT"),
                      action: () => handleView({ id: row.pointCutId }),
                      disabled: !status && true
                    }
                  ]

                  return (
                    <StyledTableRow key={index} hover role="checkbox" tabIndex={-1}>
                      <StickyLeftTableCell>
                        <StyledTableCell>{index + 1 + rowsPerPage * page}</StyledTableCell>
                      </StickyLeftTableCell>
                      <StyledTableCell style={{ textAlign: "right" }}>{row.percent}</StyledTableCell>
                      <StyledTableCell>
                        {moment(row.dateAdded).format('lll')}
                      </StyledTableCell>
                      <StyledTableCell>
                        {moment(row.startAt).format('lll')} - {moment(row.endAt).format('lll')}
                      </StyledTableCell>
                      <StyledTableCell>{row.gift.length}</StyledTableCell>
                      {/* <StyledTableCell style={{ color: "#4BCB0F" }}>{item.addedBy}</StyledTableCell> */}
                      <StyledTableCell>
                        <AntSwitch
                          disabled={!hasPointCutActivateOrDeactivateAccessRight}
                          checked={status}
                          onChange={() =>
                            handleStatusChange({
                              currentStatus: status,
                              id: row.pointCutId
                            })
                          }
                        />
                      </StyledTableCell>
                      <StickyRightTableCell>
                        <StyledTableCell>
                          <div style={{ display: "flex", justifyContent: "center" }}>
                            <ActionButton actionItems={actionItems} popperStyle={{ zIndex: 999 }} />
                          </div>
                        </StyledTableCell>
                      </StickyRightTableCell>
                    </StyledTableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : !search && !isLoadingPointCutError && (
        <Box>
          <Table className={classes.table} aria-label="product table" stickyHeader>
            <TableHead>
              <TableRow>
                <StyledTableCell>{getLang(lang, "label.NUM")}</StyledTableCell>
                <StyledTableCell style={{ textAlign: "right" }}>{getLang(lang, "label.POINT_CUT")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.DATE_CREATED")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.PERIOD")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.TOTAL_GIFT")}</StyledTableCell>
                {/* <StyledTableCell>Added by</StyledTableCell> */}
                <StyledTableCell>{getLang(lang, "label.STATUS")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.ACTION")}</StyledTableCell>
              </TableRow>
            </TableHead>
          </Table>
          <Box py={9} className={classes.emptyContainer}>
            <Typography variant="body1">
              {getLang(lang, "paragraph.NO_POINT_CUT")}
            </Typography>
            {
              hasPointCutAddAccessRight
                ?
                <Box mt={2}>
                  <Button
                    onClick={handleCreate}
                    variant="contained"
                    disableElevation
                    color="primary"
                  >
                    {getLang(lang, "label.ADD_POINT_CUT")}
                  </Button>
                </Box>
                : null
            }
          </Box>
        </Box>

      )}

      {!isFetching && data.length === 0 && search && (
        <Alert severity="info">{getLang(lang, "message.info.NO_POINT_CUT")}</Alert>
      )}

      {!isFetching && isLoadingPointCutError && (
        <RefreshTable handleReload={handleReload} />
      )}

      {!isFetching && data.length > 0 && (
        <TablePagination
          style={{
            backgroundColor: "#fff",
            boxShadow: "0px -1px 0px rgba(58, 77, 84, 0.15)",
          }}
          ActionsComponent={TablePaginationActions}
          rowsPerPageOptions={paginationOptions}
          component="div"
          count={totalRecords}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage={getLang(lang, 'label.ROWS_PER_PAGE')}
          labelDisplayedRows={({ from, to, count }) => `${from.toLocaleString("en-US")}-${to.toLocaleString("en-US")} ${getLang(lang, 'label.OF')} ${count.toLocaleString("en-US")}`}
        />
      )}
    </>
  )
}
