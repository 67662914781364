import React from "react";
import Chart from 'react-apexcharts';
import '../../../assets/css/customTooltip.css';
import { setChartColors } from "modules/reporting/utils/chartConfig";

export default function DonutChartComponent({payload, translate}){
    const chartColors = setChartColors(payload.pieTotal.length, true);

    if(payload.pieLabels.length > 0){
        let indexOfUnknown = -1;
        payload.pieLabels.forEach((e, index) => {
            if(translate(payload.pieLabels[index]).toUpperCase() === translate('label.UNKNOWN').toUpperCase())
                indexOfUnknown = payload.pieLabels.indexOf(e)
        })

        if(indexOfUnknown !== -1){
            chartColors.splice(indexOfUnknown, 1, '#98A2B3')
        }
    }

    const chartLabels = () => {
        let series = JSON.stringify(payload);
        series = JSON.parse(series);

        series.pieLabels.forEach((e, index) => {
            series.pieLabels[index] = translate(e);
        });

        return series;
    }

    const options = {
        colors: chartColors,
        tooltip:{
            followCursor: true,
            custom: function({series, seriesIndex, dataPointIndex, w}) {
                return (
                    '<div class="tooltip__container">' +
                        '<div class="tooltip__header">' +
                            '<span class="tooltip__title">'+ payload.filteredComparedDate +'</span>' +
                        '</div>' +
                        '<div class="tooltip__content">' +
                            '<div class="tooltip__labels_container">' +
                                '<span style="background-color: ' + w.config.colors[seriesIndex] + '" class="tooltip__marker"></span>'+
                                '<span class="tooltip__label">' + translate(w.config.labels[seriesIndex]) + '</span>'+
                            '</div>' +
                            '<span class="tooltip__value">' + series[seriesIndex] + '</span>' +
                        '</div>' +
                    '</div>'
                )
            }
        },
        xaxis: {
            categories: payload.labels,
        },
        legend: {
            position: 'bottom',
            showForSingleSeries: true
        },
        labels: chartLabels().pieLabels
    }

    return (
        <Chart
            series={
                payload.pieTotal
            }

            options= {
                options
            }

            type="donut"
            height={400}
        >
            
        </Chart>
    )
}