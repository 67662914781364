import React from "react";
import { Typography, makeStyles, Box, Switch } from "@material-ui/core";
import * as Yup from "yup";
import { Formik, ErrorMessage } from "formik";
import { TYPES } from "./videoForm.container";
import ThumbnailDefault from "assets/img/img-default.png";
import { ModalFooter, ContentWrapper } from "../../modal";
import Input from "components/input/inputTextField";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles(theme => ({
  images: {
    height: 90,
    width: 160,
    backgroundColor: "transparent",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: theme.spacing(2),
    overflow: "hidden",
    "& img": {
      width: "100%",
      height: "auto"
    }
  }
}));

export default function VideoFormComponent({
  type,
  initialValues,
  handleSubmit,
  handleDelete,
  handleGetThumbnail,
  thumbnail,
  lang,
  handleClose
}) {
  const classes = useStyle();

  return (
    <Formik
      initialValues={{
        URL: initialValues.URL,
        enableAutoplay: initialValues.enableAutoplay ?? false,
      }}
      validationSchema={Yup.object({
        URL: Yup.string().required(getLang(lang,"message.error.FORM_VALIDATE_REQUIRED"))
      })}
      onSubmit={handleSubmit}
    >
      {formik => (
        <form onSubmit={formik.handleSubmit}>
          <ContentWrapper>
            <Typography variant="body2" gutterBottom>
              {getLang(lang,"label.INSERT_YOUTUBE_URL")} <Typography style={{ color: "#DE350B", display: 'inline-block' }}>*</Typography>
            </Typography>
            <Box display="flex" alignItems="center" mb={3}>
              <Box className={classes.images}>
                <img
                  src={thumbnail === "" ? ThumbnailDefault : thumbnail}
                  alt={""}
                />
              </Box>
              <Box>
                <Input
                  required
                  hiddenLabel
                  variant="filled"
                  size="small"
                  margin="normal"
                  placeholder={getLang(lang,"placeholder.ENTER_URL_HERE")}
                  fullWidth
                  {...formik.getFieldProps("URL")}
                  onChange={e => {
                    formik.handleChange(e);
                    handleGetThumbnail(e.target.value);
                  }}
                  value={
                    type === TYPES[2] ? initialValues.URL : formik.values.URL
                  }
                  error={formik.touched.URL && formik.errors.URL ? true : false}
                  helperText={ErrorMessage({ name: "URL" })}
                  inputProps={{ readOnly: type === TYPES[2] }}
                />
                <Typography variant="body2">
                  <span style={{ color: "gray" }}>{getLang(lang,"label.EXAMPLE")}&nbsp;</span>
                  https://www.youtube.com/watch?v=P_-PI71WbJ4
                </Typography>
              </Box>
            </Box>
            <Typography variant="caption" color="textSecondary">
            <Typography style={{ color: "#DE350B", display: 'inline-block' }}>*</Typography> {getLang(lang,"label.REQUIRED_FIELD")}
            </Typography>
          </ContentWrapper>
          <Box
            px={2}
            py={1}
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
          >
            <Typography variant="subtitle1">{getLang(lang, "label.ENABLE_AUTOPLAY")}</Typography>
            <Switch
              color="primary"
              checked={formik.values.enableAutoplay}
              onChange={() => formik.setFieldValue('enableAutoplay', !formik.values.enableAutoplay)}
            />
          </Box>
          <ModalFooter
            handleDelete={handleDelete}
            showDelete={type !== TYPES[0]}
            handleClose={handleClose}
          />
        </form>
      )}
    </Formik>
  );
}
