import axios from "axios";

const url = "/distributor/admin/api/v1/stocktake/list";
export function getStockTakeList(parameters) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, {
        length: parameters.length,
        start: parameters.start,
        order: parameters.order,
        order_by: parameters.order_by,
        filter: {
          search: parameters.filter.search,
          status: parameters.filter.status,
          from_date: parameters.filter.from_date,
          to_date: parameters.filter.to_date,
          nodes: parameters.filter.nodes,
        },
      })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data));
  });
}
