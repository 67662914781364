import React, { useEffect, useState } from "react";
import MainPageComponent from "./main.page";
import { useLocation } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { GENERATE_SN, ROOT } from "modules/serial-number";
import { openCreationDialog, openImportDialog } from "modules/serial-number/redux";
import {
  SERIAL_NUMBER_VIEW,
  SERIAL_NUMBER_PATTERN_VIEW,
  SERIAL_NUMBER_GENERATE,
  ADDITIONAL_INFO_VIEW,
  ADDITIONAL_INFO_ADD,
} from "lib/constants/accessRights";
import LoadingComponent from "components/loading";
import { getLang } from "app/feature/constants";
import qs from "query-string";

function MainPageContainer() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [activeIndex, setActiveIndex] = useState(null);
  const location = useLocation();

  const hasSerialNumberGenerateAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(SERIAL_NUMBER_GENERATE)
  );
  const hasSerialNumberViewAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(SERIAL_NUMBER_VIEW)
  );
  const hasSerialNumberPatternViewAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(SERIAL_NUMBER_PATTERN_VIEW)
  );
  const hasAdtInfoViewAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(ADDITIONAL_INFO_VIEW)
  );
  const hasAdtInfoAddAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(ADDITIONAL_INFO_ADD)
  );
  const lang = useSelector((state) => state.constant.languages);

  const qsParams = qs.parse(location.search);
  const currentSearch = qsParams.tab || "";

  useEffect(() => {
    if (
      !(hasSerialNumberViewAccessRight || hasSerialNumberPatternViewAccessRight)
    ) {
      history.push("/admin/dashboard");
    }
  }, [
    history,
    hasSerialNumberViewAccessRight,
    hasSerialNumberPatternViewAccessRight,
  ]);

  useEffect(() => {
    let tempIndex = 0;
    switch (currentSearch) {
      case "sn":
        tempIndex = 0;
        break;
      case "batch":
        tempIndex = 1;
        break;
      case "pattern":
        tempIndex = 2;
        break;
      case "assign":
        tempIndex = 3;
        break;
      case "adt-info":
        tempIndex = 4;
        break;
      default:
        tempIndex = 0;
    }
    setActiveIndex(tempIndex);
  }, [currentSearch]);

  if (
    !(hasSerialNumberViewAccessRight || hasSerialNumberPatternViewAccessRight)
  )
    return <LoadingComponent />;

  let tabLabels = [];
  if (hasSerialNumberViewAccessRight) {
    tabLabels.push(getLang(lang, "label.SERIAL_NUMBER_LIST"));
    tabLabels.push(getLang(lang, "label.SERIAL_NUMBER_BATCH"));
  }
  if (hasSerialNumberPatternViewAccessRight) {
    tabLabels.push(getLang(lang, "label.SAVED_PATTERN"));
  }
  if (hasSerialNumberViewAccessRight) {
    tabLabels.push(getLang(lang, "label.SERIAL_NUMBER_ASSIGN_LIST"));
  }
  if (hasAdtInfoViewAccessRight) {
    tabLabels.push(getLang(lang, "label.ADDITIONAL_INFO"));
  }

  const handleChange = (event, index) => {
    setActiveIndex(index);
    history.replace({
      pathname: ROOT,
      search: `?tab=${
        index === 1
          ? "batch"
          : index === 2
          ? "pattern"
          : index === 3
          ? "assign"
          : index === 4
          ? "adt-info"
          : "sn"
      }`,
    });
  };

  function handleGoToGenerate() {
    if (hasSerialNumberGenerateAccessRight) {
      history.push(GENERATE_SN);
    }
  }

  const handleAddPattern = () => {
    dispatch(openCreationDialog());
  };

  const handleAddAdtInfo = () => {
    dispatch(openImportDialog());
  };

  return (
    <>
      <MainPageComponent
        hasSerialNumberGenerateAccessRight={hasSerialNumberGenerateAccessRight}
        handleChange={handleChange}
        activeIndex={activeIndex}
        tabLabels={tabLabels}
        handleGoToGenerate={handleGoToGenerate}
        handleAddPattern={handleAddPattern}
        lang={lang}
        handleAddAdtInfo={handleAddAdtInfo}
        hasAdtInfoAddAccessRight={hasAdtInfoAddAccessRight}
      />
    </>
  );
}

export default MainPageContainer;
