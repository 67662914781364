import axios from "axios";

const getGiftDropdown = async search => {
    const body = search

    const response = await axios.get('/campaign/api/admin/items/dropdown', { params: body });

    return response.data;
};

export default getGiftDropdown;