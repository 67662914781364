import { addAlert, setLoading } from "modules/notification";
import { getCampaignConversionData, getCampaignInstantLuckyDrawListData, getCampaignLoyaltyProgramListData, getCampaignLuckyDrawListData, getCampaignPerformanceData, getCampaignSurveyListData, getCampaignWarrantyListData, getCampaignTypeSurveyDatalist, getRespondentsConsumerAllSurveyDatalist, getCampaignTypeLuckyDrawDatalist, getRespondentsConsumerAllLuckyDrawDatalist, getCampaignTypeInstantLuckyDrawDatalist, getRespondentsConsumerAllInstantLuckyDrawDatalist, getCampaignTypeLoyaltyProgramDatalist, getRespondentsConsumerAllLoyaltyProgramDatalist, getCampaignTypeWarrantyDatalist, getRespondentsConsumerAllWarrantyDatalist, getCampaignSummaryData, getCampaignTypeData, getCampaignProductData, getRespondentsSingleCampaignDatalist, getCampaignPrizeDatalist, getCampaignPrizeSummaryData, getWinnerParticipantDatalist } from "../action";

// (1) campaign conversion -- start
export const getCampaignConversionDataMiddleware = ({ dispatch, getState}) => next => action => {
    next(action);
    if (action.type === getCampaignConversionData.pending.type) {
        dispatch(setLoading({ id: getCampaignConversionData.typePrefix, state: true }));
    }
    if (action.type === getCampaignConversionData.fulfilled.type) {
        dispatch(setLoading({ id: getCampaignConversionData.typePrefix, state: false }));
    }
    if (action.type === getCampaignConversionData.rejected.type) {
        dispatch(setLoading({ id: getCampaignConversionData.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: action.payload.message}));
    }
}
// (1) campaign conversion -- end

// (2) campaign performance -- start
export const getCampaignPerformanceDataMiddleware = ({ dispatch, getState}) => next => action => {
    next(action);
    if (action.type === getCampaignPerformanceData.pending.type) {
        dispatch(setLoading({ id: getCampaignPerformanceData.typePrefix, state: true }));
    }
    if (action.type === getCampaignPerformanceData.fulfilled.type) {
        dispatch(setLoading({ id: getCampaignPerformanceData.typePrefix, state: false }));
    }
    if (action.type === getCampaignPerformanceData.rejected.type) {
        dispatch(setLoading({ id: getCampaignPerformanceData.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: action.payload.message}));
    }
}
// (2) campaign performance -- end

// (3) campaign survey list -- start
export const getCampaignSurveyListDataMiddleware = ({ dispatch, getState}) => next => action => {
    next(action);
    if (action.type === getCampaignSurveyListData.pending.type) {
        dispatch(setLoading({ id: getCampaignSurveyListData.typePrefix, state: true }));
    }
    if (action.type === getCampaignSurveyListData.fulfilled.type) {
        dispatch(setLoading({ id: getCampaignSurveyListData.typePrefix, state: false }));
    }
    if (action.type === getCampaignSurveyListData.rejected.type) {
        dispatch(setLoading({ id: getCampaignSurveyListData.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: action.payload.message}));
    }
}
// (3) campaign survey list -- end

// (4) campaign lucky draw list -- start
export const getCampaignLuckyDrawListDataMiddleware = ({ dispatch, getState}) => next => action => {
    next(action);
    if (action.type === getCampaignLuckyDrawListData.pending.type) {
        dispatch(setLoading({ id: getCampaignLuckyDrawListData.typePrefix, state: true }));
    }
    if (action.type === getCampaignLuckyDrawListData.fulfilled.type) {
        dispatch(setLoading({ id: getCampaignLuckyDrawListData.typePrefix, state: false }));
    }
    if (action.type === getCampaignLuckyDrawListData.rejected.type) {
        dispatch(setLoading({ id: getCampaignLuckyDrawListData.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: action.payload.message}));
    }
}
// (4) campaign lucky draw list -- end

// (5) campaign instant lucky draw list -- start
export const getCampaignInstantLuckyDrawListDataMiddleware = ({ dispatch, getState}) => next => action => {
    next(action);
    if (action.type === getCampaignInstantLuckyDrawListData.pending.type) {
        dispatch(setLoading({ id: getCampaignInstantLuckyDrawListData.typePrefix, state: true }));
    }
    if (action.type === getCampaignInstantLuckyDrawListData.fulfilled.type) {
        dispatch(setLoading({ id: getCampaignInstantLuckyDrawListData.typePrefix, state: false }));
    }
    if (action.type === getCampaignInstantLuckyDrawListData.rejected.type) {
        dispatch(setLoading({ id: getCampaignInstantLuckyDrawListData.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: action.payload.message}));
    }
}
// (5) campaign instant lucky draw list -- end

// (6) campaign loyalty program list -- start
export const getCampaignLoyaltyProgramListDataMiddleware = ({ dispatch, getState}) => next => action => {
    next(action);
    if (action.type === getCampaignLoyaltyProgramListData.pending.type) {
        dispatch(setLoading({ id: getCampaignLoyaltyProgramListData.typePrefix, state: true }));
    }
    if (action.type === getCampaignLoyaltyProgramListData.fulfilled.type) {
        dispatch(setLoading({ id: getCampaignLoyaltyProgramListData.typePrefix, state: false }));
    }
    if (action.type === getCampaignLoyaltyProgramListData.rejected.type) {
        dispatch(setLoading({ id: getCampaignLoyaltyProgramListData.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: action.payload.message}));
    }
}
// (6) campaign loyalty program list -- end

// (7) campaign warranty list -- start
export const getCampaignWarrantyListDataMiddleware = ({ dispatch, getState}) => next => action => {
    next(action);
    if (action.type === getCampaignWarrantyListData.pending.type) {
        dispatch(setLoading({ id: getCampaignWarrantyListData.typePrefix, state: true }));
    }
    if (action.type === getCampaignWarrantyListData.fulfilled.type) {
        dispatch(setLoading({ id: getCampaignWarrantyListData.typePrefix, state: false }));
    }
    if (action.type === getCampaignWarrantyListData.rejected.type) {
        dispatch(setLoading({ id: getCampaignWarrantyListData.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: action.payload.message}));
    }
}
// (7) campaign warranty list -- end

// (8) campaign type survey list -- start
export const getCampaignTypeSurveyDatalistMiddleware = ({ dispatch, getState}) => next => action => {
    next(action);
    if (action.type === getCampaignTypeSurveyDatalist.pending.type) {
        dispatch(setLoading({ id: getCampaignTypeSurveyDatalist.typePrefix, state: true }));
    }
    if (action.type === getCampaignTypeSurveyDatalist.fulfilled.type) {
        dispatch(setLoading({ id: getCampaignTypeSurveyDatalist.typePrefix, state: false }));
    }
    if (action.type === getCampaignTypeSurveyDatalist.rejected.type) {
        dispatch(setLoading({ id: getCampaignTypeSurveyDatalist.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: action.payload.message}));
    }
}
// (8) campaign type survey list -- end

// (9) respondents consumer all survey list -- start
export const getRespondentsConsumerAllSurveyDatalistMiddleware = ({ dispatch, getState}) => next => action => {
    next(action);
    if (action.type === getRespondentsConsumerAllSurveyDatalist.pending.type) {
        dispatch(setLoading({ id: getRespondentsConsumerAllSurveyDatalist.typePrefix, state: true }));
    }
    if (action.type === getRespondentsConsumerAllSurveyDatalist.fulfilled.type) {
        dispatch(setLoading({ id: getRespondentsConsumerAllSurveyDatalist.typePrefix, state: false }));
    }
    if (action.type === getRespondentsConsumerAllSurveyDatalist.rejected.type) {
        dispatch(setLoading({ id: getRespondentsConsumerAllSurveyDatalist.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: action.payload.message}));
    }
}
// (9) respondents consumer all survey list -- end

// (10) campaign type lucky draw datalist -- start
export const getCampaignTypeLuckyDrawDatalistMiddleware = ({ dispatch, getState}) => next => action => {
    next(action);
    if (action.type === getCampaignTypeLuckyDrawDatalist.pending.type) {
        dispatch(setLoading({ id: getCampaignTypeLuckyDrawDatalist.typePrefix, state: true }));
    }
    if (action.type === getCampaignTypeLuckyDrawDatalist.fulfilled.type) {
        dispatch(setLoading({ id: getCampaignTypeLuckyDrawDatalist.typePrefix, state: false }));
    }
    if (action.type === getCampaignTypeLuckyDrawDatalist.rejected.type) {
        dispatch(setLoading({ id: getCampaignTypeLuckyDrawDatalist.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: action.payload.message}));
    }
}
// (10) campaign type lucky draw datalist -- end

// (11) respondents consumer all lucky draw datalist -- start
export const getRespondentsConsumerAllLuckyDrawDatalistMiddleware = ({ dispatch, getState}) => next => action => {
    next(action);
    if (action.type === getRespondentsConsumerAllLuckyDrawDatalist.pending.type) {
        dispatch(setLoading({ id: getRespondentsConsumerAllLuckyDrawDatalist.typePrefix, state: true }));
    }
    if (action.type === getRespondentsConsumerAllLuckyDrawDatalist.fulfilled.type) {
        dispatch(setLoading({ id: getRespondentsConsumerAllLuckyDrawDatalist.typePrefix, state: false }));
    }
    if (action.type === getRespondentsConsumerAllLuckyDrawDatalist.rejected.type) {
        dispatch(setLoading({ id: getRespondentsConsumerAllLuckyDrawDatalist.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: action.payload.message}));
    }
}
// (11) respondents consumer all lucky draw datalist -- end

// (12) campaign type instant lucky draw datalist -- start
export const getCampaignTypeInstantLuckyDrawDatalistMiddleware = ({ dispatch, getState}) => next => action => {
    next(action);
    if (action.type === getCampaignTypeInstantLuckyDrawDatalist.pending.type) {
        dispatch(setLoading({ id: getCampaignTypeInstantLuckyDrawDatalist.typePrefix, state: true }));
    }
    if (action.type === getCampaignTypeInstantLuckyDrawDatalist.fulfilled.type) {
        dispatch(setLoading({ id: getCampaignTypeInstantLuckyDrawDatalist.typePrefix, state: false }));
    }
    if (action.type === getCampaignTypeInstantLuckyDrawDatalist.rejected.type) {
        dispatch(setLoading({ id: getCampaignTypeInstantLuckyDrawDatalist.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: action.payload.message}));
    }
}
// (12) campaign type instant lucky draw datalist -- end

// (13) respondents consumer all instant lucky draw datalist -- start
export const getRespondentsConsumerAllInstantLuckyDrawDatalistMiddleware = ({ dispatch, getState}) => next => action => {
    next(action);
    if (action.type === getRespondentsConsumerAllInstantLuckyDrawDatalist.pending.type) {
        dispatch(setLoading({ id: getRespondentsConsumerAllInstantLuckyDrawDatalist.typePrefix, state: true }));
    }
    if (action.type === getRespondentsConsumerAllInstantLuckyDrawDatalist.fulfilled.type) {
        dispatch(setLoading({ id: getRespondentsConsumerAllInstantLuckyDrawDatalist.typePrefix, state: false }));
    }
    if (action.type === getRespondentsConsumerAllInstantLuckyDrawDatalist.rejected.type) {
        dispatch(setLoading({ id: getRespondentsConsumerAllInstantLuckyDrawDatalist.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: action.payload.message}));
    }
}
// (13) respondents consumer all instant lucky draw datalist -- end

// (14) campaign type loyalty program datalist -- start
export const getCampaignTypeLoyaltyProgramDatalistMiddleware = ({ dispatch, getState}) => next => action => {
    next(action);
    if (action.type === getCampaignTypeLoyaltyProgramDatalist.pending.type) {
        dispatch(setLoading({ id: getCampaignTypeLoyaltyProgramDatalist.typePrefix, state: true }));
    }
    if (action.type === getCampaignTypeLoyaltyProgramDatalist.fulfilled.type) {
        dispatch(setLoading({ id: getCampaignTypeLoyaltyProgramDatalist.typePrefix, state: false }));
    }
    if (action.type === getCampaignTypeLoyaltyProgramDatalist.rejected.type) {
        dispatch(setLoading({ id: getCampaignTypeLoyaltyProgramDatalist.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: action.payload.message}));
    }
}
// (14) campaign type loyalty program datalist -- end

// (15) respondents consumer all loyalty program datalist -- start
export const getRespondentsConsumerAllLoyaltyProgramDatalistMiddleware = ({ dispatch, getState}) => next => action => {
    next(action);
    if (action.type === getRespondentsConsumerAllLoyaltyProgramDatalist.pending.type) {
        dispatch(setLoading({ id: getRespondentsConsumerAllLoyaltyProgramDatalist.typePrefix, state: true }));
    }
    if (action.type === getRespondentsConsumerAllLoyaltyProgramDatalist.fulfilled.type) {
        dispatch(setLoading({ id: getRespondentsConsumerAllLoyaltyProgramDatalist.typePrefix, state: false }));
    }
    if (action.type === getRespondentsConsumerAllLoyaltyProgramDatalist.rejected.type) {
        dispatch(setLoading({ id: getRespondentsConsumerAllLoyaltyProgramDatalist.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: action.payload.message}));
    }
}
// (15) respondents consumer all loyalty program datalist -- end

// (16) campaign type warranty datalist -- start
export const getCampaignTypeWarrantyDatalistMiddleware = ({ dispatch, getState}) => next => action => {
    next(action);
    if (action.type === getCampaignTypeWarrantyDatalist.pending.type) {
        dispatch(setLoading({ id: getCampaignTypeWarrantyDatalist.typePrefix, state: true }));
    }
    if (action.type === getCampaignTypeWarrantyDatalist.fulfilled.type) {
        dispatch(setLoading({ id: getCampaignTypeWarrantyDatalist.typePrefix, state: false }));
    }
    if (action.type === getCampaignTypeWarrantyDatalist.rejected.type) {
        dispatch(setLoading({ id: getCampaignTypeWarrantyDatalist.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: action.payload.message}));
    }
}
// (16) campaign type warranty datalist -- end

// (17) respondents consumer all warranty datalist -- start
export const getRespondentsConsumerAllWarrantyDatalistMiddleware = ({ dispatch, getState}) => next => action => {
    next(action);
    if (action.type === getRespondentsConsumerAllWarrantyDatalist.pending.type) {
        dispatch(setLoading({ id: getRespondentsConsumerAllWarrantyDatalist.typePrefix, state: true }));
    }
    if (action.type === getRespondentsConsumerAllWarrantyDatalist.fulfilled.type) {
        dispatch(setLoading({ id: getRespondentsConsumerAllWarrantyDatalist.typePrefix, state: false }));
    }
    if (action.type === getRespondentsConsumerAllWarrantyDatalist.rejected.type) {
        dispatch(setLoading({ id: getRespondentsConsumerAllWarrantyDatalist.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: action.payload.message}));
    }
}
// (17) respondents consumer all warranty datalist -- end

// (18) campaign summary data -- start
export const getCampaignSummaryDataMiddleware = ({ dispatch, getState}) => next => action => {
    next(action);
    if (action.type === getCampaignSummaryData.pending.type) {
        dispatch(setLoading({ id: getCampaignSummaryData.typePrefix, state: true }));
    }
    if (action.type === getCampaignSummaryData.fulfilled.type) {
        dispatch(setLoading({ id: getCampaignSummaryData.typePrefix, state: false }));
    }
    if (action.type === getCampaignSummaryData.rejected.type) {
        dispatch(setLoading({ id: getCampaignSummaryData.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: action.payload.message}));
    }
}
// (18) campaign summary data -- end

// (19) campaign type data -- start
export const getCampaignTypeDataMiddleware = ({ dispatch, getState}) => next => action => {
    next(action);
    if (action.type === getCampaignTypeData.pending.type) {
        dispatch(setLoading({ id: getCampaignTypeData.typePrefix, state: true }));
    }
    if (action.type === getCampaignTypeData.fulfilled.type) {
        dispatch(setLoading({ id: getCampaignTypeData.typePrefix, state: false }));
    }
    if (action.type === getCampaignTypeData.rejected.type) {
        dispatch(setLoading({ id: getCampaignTypeData.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: action.payload.message}));
    }
}
// (19) campaign type data -- end

// (20) campaign product data -- start
export const getCampaignProductDataMiddleware = ({ dispatch, getState}) => next => action => {
    next(action);
    if (action.type === getCampaignProductData.pending.type) {
        dispatch(setLoading({ id: getCampaignProductData.typePrefix, state: true }));
    }
    if (action.type === getCampaignProductData.fulfilled.type) {
        dispatch(setLoading({ id: getCampaignProductData.typePrefix, state: false }));
    }
    if (action.type === getCampaignProductData.rejected.type) {
        dispatch(setLoading({ id: getCampaignProductData.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: action.payload.message}));
    }
}
// (20) campaign product data -- end

// (21) respondents single campaign -- start
export const getRespondentsSingleCampaignDatalistMiddleware = ({ dispatch, getState}) => next => action => {
    next(action);
    if (action.type === getRespondentsSingleCampaignDatalist.pending.type) {
        dispatch(setLoading({ id: getRespondentsSingleCampaignDatalist.typePrefix, state: true }));
    }
    if (action.type === getRespondentsSingleCampaignDatalist.fulfilled.type) {
        dispatch(setLoading({ id: getRespondentsSingleCampaignDatalist.typePrefix, state: false }));
    }
    if (action.type === getRespondentsSingleCampaignDatalist.rejected.type) {
        dispatch(setLoading({ id: getRespondentsSingleCampaignDatalist.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: action.payload.message}));
    }
}
// (21) respondents single campaign -- end

// (22) campaign prize datalist -- start
export const getCampaignPrizeDatalistMiddleware = ({ dispatch, getState}) => next => action => {
    next(action);
    if (action.type === getCampaignPrizeDatalist.pending.type) {
        dispatch(setLoading({ id: getCampaignPrizeDatalist.typePrefix, state: true }));
    }
    if (action.type === getCampaignPrizeDatalist.fulfilled.type) {
        dispatch(setLoading({ id: getCampaignPrizeDatalist.typePrefix, state: false }));
    }
    if (action.type === getCampaignPrizeDatalist.rejected.type) {
        dispatch(setLoading({ id: getCampaignPrizeDatalist.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: action.payload.message}));
    }
}
// (22) campaign prize datalist -- end

// (23) campaign prize summary data -- start
export const getCampaignPrizeSummaryDataMiddleware = ({ dispatch, getState}) => next => action => {
    next(action);
    if (action.type === getCampaignPrizeSummaryData.pending.type) {
        dispatch(setLoading({ id: getCampaignPrizeSummaryData.typePrefix, state: true }));
    }
    if (action.type === getCampaignPrizeSummaryData.fulfilled.type) {
        dispatch(setLoading({ id: getCampaignPrizeSummaryData.typePrefix, state: false }));
    }
    if (action.type === getCampaignPrizeSummaryData.rejected.type) {
        dispatch(setLoading({ id: getCampaignPrizeSummaryData.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: action.payload.message}));
    }
}
// (23) campaign prize summary data -- end

// (24) winner participant datalist -- start
export const getWinnerParticipantDatalistMiddleware = ({ dispatch, getState}) => next => action => {
    next(action);
    if (action.type === getWinnerParticipantDatalist.pending.type) {
        dispatch(setLoading({ id: getWinnerParticipantDatalist.typePrefix, state: true }));
    }
    if (action.type === getWinnerParticipantDatalist.fulfilled.type) {
        dispatch(setLoading({ id: getWinnerParticipantDatalist.typePrefix, state: false }));
    }
    if (action.type === getWinnerParticipantDatalist.rejected.type) {
        dispatch(setLoading({ id: getWinnerParticipantDatalist.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: action.payload.message}));
    }
}
// (24) winner participant datalist -- end

export default [
    getCampaignConversionDataMiddleware,
    getCampaignPerformanceDataMiddleware,
    getCampaignSurveyListDataMiddleware,
    getCampaignLuckyDrawListDataMiddleware,
    getCampaignInstantLuckyDrawListDataMiddleware,
    getCampaignLoyaltyProgramListDataMiddleware,
    getCampaignWarrantyListDataMiddleware,
    getCampaignTypeSurveyDatalistMiddleware,
    getRespondentsConsumerAllSurveyDatalistMiddleware,
    getCampaignTypeLuckyDrawDatalistMiddleware,
    getRespondentsConsumerAllLuckyDrawDatalistMiddleware,
    getCampaignTypeInstantLuckyDrawDatalistMiddleware,
    getRespondentsConsumerAllInstantLuckyDrawDatalistMiddleware,
    getCampaignTypeLoyaltyProgramDatalistMiddleware,
    getRespondentsConsumerAllLoyaltyProgramDatalistMiddleware,
    getCampaignTypeWarrantyDatalistMiddleware,
    getRespondentsConsumerAllWarrantyDatalistMiddleware,
    getCampaignSummaryDataMiddleware,
    getCampaignTypeDataMiddleware,
    getCampaignProductDataMiddleware,
    getRespondentsSingleCampaignDatalistMiddleware,
    getCampaignPrizeDatalistMiddleware,
    getCampaignPrizeSummaryDataMiddleware,
    getWinnerParticipantDatalistMiddleware
]