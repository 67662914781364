import React from "react"
import {
  makeStyles,
  Typography,
  Box,
  Tabs,
  // Button,
  Slide,
  // CircularProgress,
  Paper,
} from "@material-ui/core"
import {
  ChevronRightRounded as ChevronRightRoundedIcon
} from "@material-ui/icons"
import { BackButton } from "components/button"
import CustomTab from "components/tab"
import ProductInfoCard from "modules/stockflow/components/card/productInfoCard"
// import { DownloadSVG } from "modules/stockflow/utils/static"
import ProductStockListPanel from "modules/stockflow/components/panel/productStockListPanel"
import SerialNumberActivityLogPanel from "modules/stockflow/components/panel/serialNumberActivityLogPanel"
import { getLang } from "app/feature/constants"

const useStyle = makeStyles(theme => ({
  title: {
    fontWeight: "bold"
  },
  root: {
    display: "grid",
    gridTemplateAreas: "'topbar .''panel panel'",
    [theme.breakpoints.down("md")]: {
      gridTemplateAreas: "'topbar topbar''panel panel'"
    },
    [theme.breakpoints.down("sm")]: {
      gridTemplateAreas: "'topbar topbar''panel panel'"
    }
  },
  indicator: {
    border: "0.5px solid",
    borderColor: theme.palette.primary.main
  },
  scroll: {
    width: "min-content"
  },
  logContainer: {
    height: "100%",
    zIndex: 91,
  },
  slideInPanel: {
    position: "absolute",
    top: "0px",
    maxWidth: "90%",
    width: "700px",
    right: "0px",
    height: "100%", 
    zIndex: 91,
    minHeight: "50vh"
  },
}))

export default function ProductDetailComponent({
  tabLabels,
  activeIndex,
  handleChange,
  product,
  isLoading, 
  showLog,
  viewActivityLog,
  resetLog,
  // exportListing,
  isExporting,
  lang
}) {
  const classes = useStyle()

  function a11yProps(index, label = "label") {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tab-${index}`,
      label: <Typography variant="button" children={label} />,
    }
  }

  return (
    <Box className={classes.root}>
      <Box gridArea="topbar">
        <Box
          mb={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="flex" alignItems="flex-end" fontWeight="bold">
            <BackButton />
            <Box display="flex" ml={1} alignItems="flex-end">
              <Typography
                variant="h6"
                color="primary"
              >
                {getLang(lang, "label.INVENTORY")}
              </Typography>
              <Box mx={0.5}>
                <ChevronRightRoundedIcon color="disabled" />
              </Box>
              <Box>
                <Typography variant="h6" color="primary">
                  {getLang(lang, "label.VIEW_DETAIL")}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box gridArea="panel">
        <Box>
          <Box display="flex" justifyContent="space-between">
            <Box>
              <Tabs
                value={activeIndex}
                onChange={handleChange}
                aria-label="scrollable auto tabs example"
                textColor="primary"
                variant="scrollable"
                indicatorColor="primary"
                classes={{
                  indicator: classes.indicator,
                  scrollButtons: classes.scroll,
                }}
              >
                {tabLabels.map((item, index) => {
                  return <CustomTab key={index} {...a11yProps(index, item)} />
                })}
              </Tabs>
            </Box>
            {/* <Box>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                style={{ padding: "5px 12px", background: "white", borderRadius: "8px" }}
                disableElevation
                onClick={() => {
                  // exportListing()
                }}
                startIcon={isExporting ? <CircularProgress style={{ width: 10, height: 10 }}/> : <DownloadSVG />}
                disabled={isLoading}
              >
                EXPORT XLS
              </Button>
            </Box> */}
          </Box>
          <Box style={{ height: "100%", position: "relative", overflowX: "clip" }}>
            <Box mb={2}>
              <ProductInfoCard
                product={product}
                isFetching={isLoading}
                lang={lang}
              />
            </Box>
            <Box style={{ height: "100%" }}>
              <ProductStockListPanel 
                viewActivityLog={viewActivityLog}
              />
            </Box>
            <Slide
              direction="left"
              in={showLog}
              timeout={200}
              mountOnEnter
              unmountOnExit
              onExited={resetLog}
              style={{ height: "100%" }}
              className={classes.slideInPanel}
            >
              <Paper className={classes.logContainer} elevation={3}>
                <SerialNumberActivityLogPanel
                  handleClose={() => viewActivityLog(null)}
                />
              </Paper>
            </Slide>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
