import axios from "axios"

export default async function getCampaignAssigned(enc) {
    return new Promise((resolve, reject) => {
        axios
        .get(`/campaign/api/admin/campaigns/${enc}/serial-number-campaign-list`)
        .then((response) => resolve(response.data))
        .catch((error) => {
            return reject(error.response.data)
        })
    })
}
