import { selectLoading } from "modules/notification";
import { REPORT_CAMPAIGN__API_TAG_URL } from "modules/reporting/constants";
import { REPORTING_TITLE__CAMPAIGN_LUCKYDRAW } from "modules/reporting/constants/pageTitle";
import { getFilterBranchesData, initReportingCampaignLuckyDraw, initReportingFilter } from "modules/reporting/redux";
import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LuckyDrawPage from "./luckyDraw.page";

export default function LuckyDrawPageContainer({
    filter,
    pageMode,
    campaignType,
    translate
}) {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(initReportingFilter());
        dispatch(initReportingCampaignLuckyDraw());
    }, [dispatch])


    document.title = translate("label.REPORTING") + REPORTING_TITLE__CAMPAIGN_LUCKYDRAW;
    const isFetchingBranchesData = useSelector(state => selectLoading(state, getFilterBranchesData.typePrefix));

    const branchesData = useSelector(state => state.reporting.filter.branch);
    const tagUrl = REPORT_CAMPAIGN__API_TAG_URL;

    if(pageMode.page !== "campaign-lucky-draw")
        return null;

    return (
        <LuckyDrawPage 
            filter = {
                {
                    leftSide: [
                        {
                            tag: translate("label.BRANCH"),
                            action :(value) => filter.setCurrentFilter((prev) => {
                                return {...prev, branchUuid: value}}
                            ),
                            current: filter.currentFilter.branchUuid,
                            menuItems: branchesData,
                            dispatch: getFilterBranchesData(),
                            fetching: isFetchingBranchesData
                        }   ,   
                    ],
                    rightSide: {
                        period: {
                            current: filter.currentFilter.period,
                            action: (value) => {
                                var start, end = null;
                                
                                switch(value){
                                    case "daily":
                                        filter.setCurrentFilter((prev) => {return {...prev, period: value}})
                                        break;
                                    case "weekly":
    
                                        start = moment(filter.currentFilter.dateRange[0]).toDate();
                                        end = moment(filter.currentFilter.dateRange[1]).toDate();
                                        let week = Math.floor(moment(end).diff(start, 'days')/7) ;
    
                                        end = moment(start).add(6, 'days').toDate();
    
                                        if(week >= 1){
                                            let days = week * 7;
                                            end = moment(end).add(days, 'days').toDate();
                                        }
    
                                        if(end.getTime() > Date.now()){
                                            end = moment().toDate();
                                        }
    
                                        filter.setCurrentFilter((prev) => {return {...prev, period: value, dateRange: [start, end]}})
                                        break;
                                    case "monthly":
                                        start = moment(filter.currentFilter.dateRange[0]).startOf("month").toDate();
                                        end = moment(filter.currentFilter.dateRange[1]).endOf("month").toDate();
    
                                        if(end > Date.now()){
                                            end = moment().toDate();
                                        }
                                        filter.setCurrentFilter((prev) => {return {...prev, period: value, dateRange: [start, end]}})
                                        break;
                                    default:
                                        break;
                                }
                            },
                            menuItems: [
                                {
                                    name: translate("label.DAILY"),
                                    value: "daily"
                                },
                                {
                                    name: translate("label.WEEKLY"),
                                    value: "weekly"
                                },
                                {
                                    name: translate("label.MONTHLY"),
                                    value: "monthly"
                                }
                            ]
                        },
                        dateRange: {
                            current: filter.currentFilter.dateRange,
                            action: (start, end, period = null) => {
                                if(period){
                                    filter.setCurrentFilter((prev) => {return {...prev, dateRange: [start, end], period: period}})
                                }
                                else{
                                    filter.setCurrentFilter((prev) => {return {...prev, dateRange: [start, end]}})
                                }
                            } 
                        }
                    }
                }
            }

            currentFilter = {filter.currentFilter}

            tagUrl = {tagUrl}
            pageMode = {pageMode}

            campaignType = {campaignType}
            translate={translate}
        
        />
        
    );
}
