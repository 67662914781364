import React from "react";
import {
  makeStyles,
  withStyles,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination
} from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import Skeleton from "@material-ui/lab/Skeleton";
import { TablePaginationActions } from "components/tablePagination";
import { getLang } from "app/feature/constants";
import moment from 'moment';
import 'moment/min/locales.min';

const API_HOST = process.env.REACT_APP_API_HOST_URL;

const useStyle = makeStyles((theme) => ({
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    borderCollapse: "separate",
    borderTop: "1px solid rgba(58, 77, 84, 0.1)",
    borderLeft: "1px solid rgba(58, 77, 84, 0.1)",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0
    },
    "& th,td": {
      fontSize: ".75rem",
      borderRight: "1px solid rgba(58, 77, 84, 0.1)",
      padding: "10px 16px 10px 16px"
    },
    "& th": {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightBold
    },
    "& th:last-child": {
      width: "10px"
    }
  },
  emptyContainer: {
    backgroundColor: theme.palette.background.paper,
    textAlign: "center"
  }
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#F4F7F8"
    },
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.common.white
    }
  }
}))(TableRow);

export default function ExtensionListTableComponent({
  rows,
  isFetching,
  page,
  rowsPerPage,
  handlePageChange,
  handleRowPerPageChange,
  length,
  token,
  lang,
  language
}) {
  const classes = useStyle();
  
  if(language==="ZH")
  {
    moment.locale('zh-cn')
  }else if(language==="TL")
  {
    moment.locale('tl-ph')
  }else
  {
    moment.locale(language)
  }


  return (
    <>
      {rows.length > 0 || isFetching ? (
        <TableContainer>
          <Table
            className={classes.table}
            size="small"
            aria-label="product table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <StyledTableCell>{getLang(lang, 'label.NUM')}</StyledTableCell>
                <StyledTableCell>{getLang(lang, 'label.QUANTITY')}</StyledTableCell>
                <StyledTableCell>{getLang(lang, 'label.DATE')}</StyledTableCell>
                <StyledTableCell>{getLang(lang, 'label.ACTION')}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isFetching ? (
                <>
                  <SkeletonTableRow />
                  <SkeletonTableRow />
                  <SkeletonTableRow />
                  <SkeletonTableRow />
                  <SkeletonTableRow />
                </>
              ) : (
                rows.map((row, index) => {
                  return (
                    <StyledTableRow
                      key={index}
                      hover
                      role="checkbox"
                      tabIndex={-1}
                    >
                      <StyledTableCell component="th" scope="row">
                        {index + 1 + rowsPerPage * page}
                      </StyledTableCell>
                      <StyledTableCell>{row.quantity}</StyledTableCell>
                      <StyledTableCell>{moment(row.createdAt).format('lll')}</StyledTableCell>
                      <StyledTableCell>
                        <Button
                          onClick={() =>
                            window.open(
                              `${API_HOST}/serialnumber/export/v1/warranty-extension/${row.batchNumber}?token=${token}`,
                              "_blank"
                            )
                          }
                          type="button"
                          style={{ minWidth: 0, padding: 0 }}
                        >
                          <GetAppIcon fontSize="small" />
                        </Button>
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
      ) : null}

      {!isFetching && rows.length > 0 && (
        <TablePagination
          style={{
            backgroundColor: "#fff",
            boxShadow: "0px -1px 0px rgba(58, 77, 84, 0.15)",
            border: "1px solid rgba(58, 77, 84, 0.1)",
            borderTop: "tranparent"
          }}
          ActionsComponent={TablePaginationActions}
          rowsPerPageOptions={[5, 10, 15]}
          component="div"
          count={length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={(event) =>
            handleRowPerPageChange(event.target.value)
          }
          labelRowsPerPage={getLang(lang, 'label.ROWS_PER_PAGE')}
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${getLang(lang, 'label.OF')} ${count}`}
        />
      )}
    </>
  );
}

function SkeletonTableRow() {
  return (
    <StyledTableRow>
      {[1, 2, 3, 4].map((id) => (
        <StyledTableCell key={id}>
          <Skeleton variant="text" animation="wave" />
        </StyledTableCell>
      ))}
    </StyledTableRow>
  );
}
