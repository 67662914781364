import React from "react";
import {
  ButtonBase,
  Popper,
  Grow,
  Box,
  Paper,
  makeStyles,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Typography,
  Divider
} from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import MoreVertIcon from "@material-ui/icons/MoreVert"
import clsx from "clsx";
import RequireSvg from "assets/svg/require.svg";

const style = (theme) => ({
  root: {
    borderRadius: theme.spacing(0.5),
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shortest,
    })
  },
  paper: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    borderRadius: "4px"
  },
  boxSubmenu: {
    "&::before": {
      content: '""',
      position: "absolute",
      width: "0",
      height: "0",
      top: "-14px",
      right: "143px",
      border: "8px solid",
      borderColor: "transparent transparent transparent",
      borderBottomColor: theme.palette.primary.main,
      zIndex: 999
    }
  },
  menuItem: {
    fontSize: "small",
    display: "block"
  },
  menuItemRequired: {
    flexFlow: "column",
    placeItems: "flex-start",
    color: "rgba(255, 255, 255, 0.33)"
  },
  divider: {
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    margin: "0px -16px 6px -16px"
  },
  dividerStyle2: {
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    margin: "0px 0px 6px 0px"
  },
  errorText: {
    color: "#FD646F",
    fontWeight: 700
  },
  activeColor: {
    color: "#344054"
  },
  deactiveColor: {
    color: "#D0D5DD"
  }
});

const useStyles = makeStyles(style);

export default function SelectAllButtonComponent({
  variant,
  isOpen,
  handleToggleOpen,
  anchorRef,
  handleListKeyDown,
  handleClose,
  actionItems,
  popperStyle,
  disablePortal,
  disabled
}) {
  const classes = useStyles()

  return (
    <Box>
      <Popper
        open={isOpen}
        anchorEl={anchorRef.current}
        disablePortal={false}
        role={undefined}
        style={{ zIndex: 999, ...popperStyle }}
        transition
        placement="bottom-start"
      >
        {({ TransitionProps, placement }) => {
          return (
            <Grow
              {...TransitionProps}
              
            >
              <Box className={classes.boxSubmenu}>
                <Paper className={classes.paper}>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={isOpen}
                      id="menu-list-grow"
                      onKeyDown={handleListKeyDown}
                    >
                      {actionItems.map((item, index) => (
                        <MenuItem
                          key={index}
                          className={clsx(classes.menuItem, {
                            [classes.menuItemRequired]: item.required,
                            [classes.errorText]: item.errorText
                          })}
                          onClick={(e) => {
                            handleClose(e);
                            item.action();
                          }}
                          disabled={item.disabled}
                        >
                          {item.divider && <Divider className={classes.dividerStyle2}/>}
                          {item.required ? (
                            <Divider className={classes.divider} />
                          ) : null}
                          {item.label}

                          {item.required ? (
                            <div style={{ display: "flex" }}>
                              <img src={RequireSvg} alt="required" />
                              <Typography
                                variant="caption"
                                style={{ color: "#FD646F", paddingLeft: "4px" }}
                              >
                                {item.requiredText}
                              </Typography>
                            </div>
                          ) : null}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Box>
            </Grow>
          );
        }}
      </Popper>

      <ButtonBase
        className={clsx(classes.root, {
          [classes.open]: isOpen,
          [classes.closed]: !isOpen,
          [classes.deactiveColor]: disabled,
          [classes.activeColor]: !disabled
        })}
        ref={anchorRef}
        disabled={disabled}
        onClick={handleToggleOpen}
      >
        {
          variant === 'horizontal'
            ?
            <MoreHorizIcon />
            :
            <MoreVertIcon />
        }
        
      </ButtonBase>
    </Box>
  )
}