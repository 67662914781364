import React from "react";
import {
  Box,
  Paper,
  Typography,
  makeStyles
} from "@material-ui/core";
import { ArrowUpward as ArrowUpwardIcon, ArrowDownward as ArrowDownwardIcon } from '@material-ui/icons';
import clsx from "clsx"

const useStyles = makeStyles((theme) => ({
  container: {
    boxShadow: "0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);",
    borderRadius: 6,
    padding: theme.spacing(2, 3),
  },
  percentageContainer: {
    height: "24px",
    width: "65px",
    borderRadius: "16px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: 2,
    paddingRight: 2
  },
  positive: {
    backgroundColor: "#ECFDF3",
    color: "#027A48",
  },
  negative: {
    backgroundColor: "#FEF3F2",
    color: "#B42318",
  }
}));

export default function SurveyGeneralCardContainer({
  title,
  data,
  percentage,
  type
}) {
  const classes = useStyles();

  return (
    <Paper className={classes.container}>
      <Box mb={1}>
        <Typography variant="subtitle1" color='textSecondary' style={{ fontWeight: "500" }}>{title}</Typography>
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <Typography variant="h4" style={{ fontWeight: "500", color: "#101828" }}>{data.toLocaleString("en-US")}</Typography>
        </Box>
        {percentage > 0 && (
          <Box className={clsx(classes.percentageContainer,{
            [classes.positive]: type === "positive",
            [classes.negative]: type === "negative"
          })}>
            {type === "positive" ? (
              <ArrowUpwardIcon style={{ width: 17, marginRight: 4 }} />
            ) : (
              <ArrowDownwardIcon style={{ width: 17, marginRight: 4 }} />
            )}
            <Typography variant="body2" style={{ fontWeight: "500" }}>{parseFloat(percentage).toFixed(2)}%</Typography>
          </Box>
        )}
      </Box>
    </Paper>
  )
}
