import { makeStyles } from "@material-ui/core";

export const layoutStyle = makeStyles((theme) => ({
    layout__filter_right_sides: {
        display: "flex",
        alignContent: "center",
    },
    [theme.breakpoints.up("lg")]: {
        layout__filter_right_sides: {
            justifyContent: "flex-end",
        }
    },
    [theme.breakpoints.down("sm")]: {
        layout__filter_right_sides: {
            justifyContent: "flex-start",
        }
    },
}))