/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import StockTakeBranchSelectComponent from "./stockTakeBranchSelect.component";
import StockTakeBranchSelectPanelComponent from "./stockTakeBranchSelectPanel.component";
import PropTypes from "prop-types";
import { selectLoading } from "modules/notification";
import { useDispatch, useSelector } from "react-redux";
import { fetchMeAndBranchDropdownSpecial } from "modules/product-mgmt/redux/action/products.action";

function StockTakeBranchSelectContainer({
  placeholder,
  handleChange,
  value,
  disabled,
  styles,
  textStyles,
}) {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.constant.languages);
  const branchDropdown = useSelector((state) => state.products.branchDropdown);
  const isBranchDropdownLoading = useSelector((state) =>
    selectLoading(state, fetchMeAndBranchDropdownSpecial.typePrefix)
  );
  const [open, setOpen] = useState(false);
  const [isOpenList] = useState(false);
  const anchorRef = useRef(null);
  const prevOpen = useRef(open);
  const prevOpenList = useRef(isOpenList);

  useEffect(() => {
    dispatch(fetchMeAndBranchDropdownSpecial({ search: "" }));
  }, [dispatch]);

  useEffect(() => {
    if (branchDropdown.length) {
      handleChange(branchDropdown[0]);
    }
  }, [branchDropdown]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    if (prevOpenList.current === true && isOpenList === false) {
      anchorRef.current.focus();
    }

    prevOpenList.current = isOpenList;
  }, [isOpenList]);

  const handleSelectBranch = (branch) => {
    handleChange(branch);
  };

  return (
    <>
      <StockTakeBranchSelectComponent
        anchorRef={anchorRef}
        handleClick={handleClick}
        value={value}
        styles={styles}
        placeholder={placeholder}
        disabled={disabled}
        branchDropDown={branchDropdown}
        textStyles={textStyles}
        lang={lang}
      />
      <StockTakeBranchSelectPanelComponent
        value={value}
        anchorRef={anchorRef}
        open={open}
        isOpenList={isOpenList}
        handleClose={handleClose}
        isFetching={isBranchDropdownLoading}
        branchDropdown={branchDropdown}
        handleSelectBranch={handleSelectBranch}
        lang={lang}
      />
    </>
  );
}
StockTakeBranchSelectContainer.defaultProps = {
  placeholder: "Select Branch(s)",
};

StockTakeBranchSelectContainer.propTypes = {
  placeholder: PropTypes.string,
};

export default StockTakeBranchSelectContainer;
