import React from "react";
import { Box, Paper, Typography, Divider, makeStyles } from "@material-ui/core";
import DefaultProfileImg from "assets/img/default-profile-picture.png";
import { getLang } from "app/feature/constants";
import moment from "moment";
import "moment/min/locales.min";

const style = theme => ({
  root: {
    textAlign: "end",
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(2, 3, 2, 3)
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2)
    }
  },
  box: {
    width: "100%",
    padding: theme.spacing(2, 0, 2, 0)
  },
  roleDisplay: {
    display: "flex",
    width: "100%",
    padding: theme.spacing(0, 6),
    [theme.breakpoints.down("sm")]: {
      width: "80%",
      alignItems: "center"
    }
  },
  text: {
    color: theme.palette.primary.main,
    fontWeight: "bold"
  },
  memberDate: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "small"
    }
  },
  roleIcon: {
    borderBottom: "solid",
    color: theme.palette.primary.main
  },
  listIcon: {
    display: "list-item",
    listStyleType: "disc",
    color: "#9CA6A9",
    fontSize: "small"
  },
  textRole: {
    color: "#9CA6A9",
    fontWeight: "300"
  },
  avatar: {
    height: 56,
    width: 56,
    borderRadius: "50%",
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    "& img": {
      height: "100%",
      minWidth: "100%",
      minHeight: "100%",
      objectFit: "cover",
      flexShrink: 0
    }
  }
});

const useStyles = makeStyles(style);

export default function ProfileHeaderComponent({ profileData, lang,language }) {
  const classes = useStyles();

  
  
  if(language==="ZH")
  {
    moment.locale('zh-cn')
  }else if(language==="TL")
  {
    moment.locale('tl-ph')
  }else
  {
    moment.locale(language)
  }

  const joinedDateProfile = moment(profileData.joinedDate).format("ll");
  
  return (
    <Paper elevation={0}>
      <Box className={classes.root}>
        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.memberDate}
        >
          {getLang(lang,"label.KOOD_MEMBER_SINCE")}{" "}
          <span className={classes.text}>{joinedDateProfile}</span>
        </Typography>
      </Box>
      <Divider />

      <Box className={classes.box}>
        <Box style={{ display: "flex", placeContent: "center" }}>
          <Box className={classes.roleDisplay}>
            <Box className={classes.roleIcon}>
              <Box mb={2} className={classes.avatar}>
                <img
                  src={
                    profileData.profileImg !== ""
                      ? profileData.profileImg
                      : DefaultProfileImg
                  }
                  alt=""
                  className={classes.logo}
                />
              </Box>
            </Box>

            <Box ml={4} mt={1}>
              <Typography
                variant="h5"
                color="primary"
                style={{ fontWeight: "500" }}
              >
                {profileData.firstName + " " + profileData.lastName}
              </Typography>
              <Box display="flex">
                <Typography variant="body1" className={classes.textRole}>
                  {/* {profileData.role && (
               
               profileData.role.map((value,index)=>{
                return(
                 <React.Fragment key={index}>
                 {value} 
              </React.Fragment>
            

                )}))
               } */}

                  {profileData.role === "owner" ? getLang(lang, 'label.OWNER') : profileData.role}
              {/* QUICK FIX FOR LANGUAGES TRANSLATION */}
                </Typography>
                <Box className={classes.listIcon} ml={3}>
                  <Typography variant="body1" className={classes.textRole}>
                    {profileData.companyName}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
}
