import React, { useEffect } from "react";
import ProductTitleCard from "./productTitleCard.component";
import { useDispatch, useSelector } from "react-redux";
import { selectLoading } from "modules/notification";
import { getProductDetailData } from "modules/reporting/redux";
import moment from "moment";

export default function ProductTitleCardContainer({
    currentFilter,
    translate,
    urlProductDetail
}){
    const dispatchProductDetail = useDispatch();

    // (1) product detail data -- start
    const isFetchingProductDetailData = useSelector(state => selectLoading(state, getProductDetailData.typePrefix));
    const productDetailData = useSelector(state => state.reporting.product.productDetailData);
    // (1) product detail data -- end

    useEffect(() => {
        dispatchProductDetail(getProductDetailData({
            product_uuid: currentFilter.productUuid,
            branch_uuid: currentFilter.branchUuid,
            period: currentFilter.period,
            start_date: moment(currentFilter.dateRange[0]).format('YYYY-MM-DD'),
            end_date: moment(currentFilter.dateRange[1]).format('YYYY-MM-DD'),
            url: urlProductDetail
        }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatchProductDetail, currentFilter])

    return (
        <ProductTitleCard 
            status={{
                isFetchingProductDetailData
            }}
            data={{
                productDetailData
            }}

            translate={translate}
        />
    )
}