import React, { useState, useRef } from "react";
import TagSelectComponent from "./tagSelect.component";
import { connect } from "react-redux";
import { selectLoading } from "modules/notification";
import { fetchTagsDropdown } from "modules/serial-number/redux";

function TagSelectContainer({
  handleChange,
  tags,
  isFetching,
  type,
  lang
}) {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handlePreprocessChange = ({ prefix, postfix, digitLength }) => {
    setOpen(false);
    handleChange({ prefix, postfix, digitLength });
  };

  return (
    <TagSelectComponent
      open={open}
      isFetching={isFetching}
      handleOpen={handleOpen}
      handleClose={handleClose}
      anchorRef={anchorRef}
      options={tags.filter(tag => tag.format.split(',')[3] === type)}
      handleChange={handlePreprocessChange}
      lang={lang}
    />
  );
}

const mapStateToProps = state => ({
  isFetching: selectLoading(state, fetchTagsDropdown.typePrefix)
});

export default connect(mapStateToProps, null)(TagSelectContainer)