export const REPORTING = "reporting"
export const REPORT_QR_DATA__API_TAG_URL = "/analytic/api/v1/qrdata/"
export const REPORT_PRODUCT_CATEGORY__API_TAG_URL = "/analytic/api/v1/productcategory/"
export const REPORT_PRODUCT_BRAND__API_TAG_URL = "/analytic/api/v1/productbrand/"
export const REPORT_PRODUCT_MODEL__API_TAG_URL = "/analytic/api/v1/productmodel/"
export const REPORT_PRODUCT_LIST__API_TAG_URL = "/analytic/api/v1/productlist/"
export const REPORT_SUSPENDED_PRODUCT__API_TAG_URL = "/analytic/api/v1/productcounterfeit/"
export const REPORT_CONSUMER__API_TAG_URL = "/analytic/api/v1/consumer/"
export const REPORT_CAMPAIGN__API_TAG_URL = "/analytic/api/v1/campaign/"
export const REPORT_DEALERSHIP__API_TAG_URL = "/analytic/api/v1/dealership/"

// Platform Type
export const PLATFORM_TYPE_WINDOW = 1;
export const PLATFORM_TYPE_MAC = 2;
export const PLATFORM_TYPE_LINUX = 3; 

// Platform Type
export const PLATFORM_TYPE_LABEL_WINDOW = "windows";
export const PLATFORM_TYPE_LABEL_MAC = "mac";
export const PLATFORM_TYPE_LABEL_LINUX = "linux"; 

// Browser Type
export const BROWSER_TYPE_IE = 1;
export const BROWSER_TYPE_EDGE = 2;
export const BROWSER_TYPE_FIREFOX = 3;
export const BROWSER_TYPE_OPERA = 4;
export const BROWSER_TYPE_CHROME = 5;
export const BROWSER_TYPE_SAFARI = 6;
export const BROWSER_TYPE_NETSCAPE = 7;

// Browser Type (User Scan Track)
export const BROWSER_TYPE_LABEL_IE = "MSIE";
export const BROWSER_TYPE_LABEL_EDGE = "Edge";
export const BROWSER_TYPE_LABEL_FIREFOX = "Firefox";
export const BROWSER_TYPE_LABEL_OPERA = "Opera";
export const BROWSER_TYPE_LABEL_CHROME = "Chrome";
export const BROWSER_TYPE_LABEL_SAFARI = "Safari";
export const BROWSER_TYPE_LABEL_NETSCAPE = "Netscape";

// Device Type

export const DEVICE_TYPE_MOBILE = 1;
export const DEVICE_TYPE_TABLET = 2;

// Campaign Type
export const CAMPAIGN_TYPE_SUMMARY = null;
export const CAMPAIGN_TYPE_SURVEY = 1;
export const CAMPAIGN_TYPE_LUCKYDRAW = 2;
export const CAMPAIGN_TYPE_INSTANTLUCKYDRAW = 3;
export const CAMPAIGN_TYPE_LOYALTYPROGRAM = 4;
export const CAMPAIGN_TYPE_WARRANTY = 5;