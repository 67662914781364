import React, { Fragment, useRef } from "react";
import {
  makeStyles,
  Typography,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  Box,
  Divider,
  MenuItem,
  MenuList,
  IconButton,
  InputAdornment,
  Checkbox,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { getLang } from "app/feature/constants";
import InputTextField from "components/input/inputTextField";
import {
  Search as SearchIcon,
} from "@material-ui/icons"

const useStyle = makeStyles((theme) => ({
  paper: {
    width: 425
  },
  button: {
    padding: 0,
    minWidth: 0
  },
  buttonAddBranch: {
    display: "flex",
    padding: 0,
    minWidth: 0
  },
  createLink: {
    padding: theme.spacing(2)
  }
}));

export default function BranchPanelComponent({
  open,
  anchorRef,
  handleClose,
  isOpenList,
  branchDropdown,
  handleBranchSearch,
  handleSelectAll,
  isFetching,
  value,
  selectAll,
  handleSelectBranch,
  lang
}) {
  const classes = useStyle();
  const textRef = useRef();

  return (
    <Popper
      open={open}
      style={{ zIndex: 99 }}
      anchorEl={anchorRef.current}
      role={undefined}
      transition
      disablePortal
      placement="bottom-start"
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === "bottom-start" ? "center top" : "center bottom"
          }}
        >
          <Paper className={classes.paper}>
            <ClickAwayListener onClickAway={handleClose}>
              <Box>
                <Fragment>
                  <InputTextField
                    variant="filled"
                    size="small"
                    fullWidth
                    inputRef={textRef}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end' style={{ paddingRight: 6 }}>
                          <IconButton>
                            <SearchIcon onClick={() => handleBranchSearch(textRef.current.value)}/>
                          </IconButton>
                        </InputAdornment>
                      ),
                      style: {
                        backgroundColor: "#fff",
                        paddingRight: 0
                      }
                    }}
                    inputProps={{
                      onChange: (e) => {
                        handleBranchSearch(textRef.current.value);
                      },
                      style: {
                        padding: "8px"
                      }
                    }}
                    placeholder={getLang(lang, "placeholder.SEARCH")}
                  />
              </Fragment>
                 <Divider /> 
                 <MenuList
                      autoFocusItem={isOpenList}
                      id="menu-list-grow"
                      style={ {padding: "0px"} }
                    >
                      <MenuItem onClick={() => { handleSelectAll() }}
                      style={{paddingTop: 0, paddingBottom: 0}}>
                        <Checkbox checked={selectAll} />
                        <Typography
                          variant="inherit"
                          noWrap
                          style={{ paddingLeft: "8px" }}
                        >
                          {getLang(lang, "label.ALL")}
                        </Typography>
                      </MenuItem>
                    </MenuList>
                    <Divider />
                {isFetching ? (
                  <Box p={1}>
                    <Skeleton variant="text" animation="wave" />
                    <Skeleton variant="text" animation="wave" />
                    <Skeleton variant="text" animation="wave" />
                    <Skeleton variant="text" animation="wave" />
                  </Box>
                ) : (
                  <div>
                    <Box height="25vh" overflow="auto">
                      {branchDropdown.map((field, index) => {
                        return (
                          <React.Fragment key={index}>
                            <MenuItem
                              onClick={() => handleSelectBranch(field)}
                              style={{paddingTop: 0, paddingBottom: 0}}
                            >
                              <Checkbox
                                checked={
                                  value.find(
                                    (v) => v.node_id === field.node_id
                                  )
                                    ? true
                                    : false
                                }
                              />
                              <Typography
                                variant="inherit"
                                noWrap
                                style={{ paddingLeft: "8px" }}
                              >
                                {field.name}
                              </Typography>
                            </MenuItem>
                          </React.Fragment>
                        );
                      })}
                    </Box>
                  </div>
                )}
              </Box>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}
