import {Box, Divider, IconButton, makeStyles, MenuItem, MenuList, Popover, Typography} from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import {getLang} from "../../../../../app/feature/constants";
import DeleteIcon from "@material-ui/icons/Delete";
import React from "react";

const useStyle = makeStyles(_ => ({
  button: {
    padding: 0,
    minWidth: 0
  },
  perfectScrollBar: {
    maxHeight: 200,
    overflow: "auto"
  },
  list: {
    paddingTop: 6,
    paddingBottom: 6
  }
}));

export default function LanguageSelectPanelComponent({
  open,
  anchorEl,
  handleClose,
  style,
  selectedLanguages,
  availableLanguages,
  handleSelectLanguage,
  handleDeleteLanguage,
  anchorOrigin,
  transformOrigin,
  PaperProps,
  lang,
}) {
  const classes = useStyle();

  return (
    <Popover
      open={open}
      style={style}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      elevation={2}
      PaperProps={PaperProps}
    >
      <Box className={classes.perfectScrollBar}>
        <Box>
          <MenuList
            id='menu-list-grow'
            className={classes.list}
          >
            {selectedLanguages.map((item) => (
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box flexGrow={1}>
                  <MenuItem>
                    <Box display="flex" alignItems="center">
                      <DoneIcon />
                      <Typography variant='body1' noWrap style={{ paddingLeft: 8, paddingRight: 8 }}>
                        {getLang(lang, `constant.language.${item}`)}
                      </Typography>
                    </Box>
                  </MenuItem>
                </Box>
                {item !== 'EN' && (
                  <Box>
                    <IconButton
                      onClick={() => handleDeleteLanguage(item)}
                      style={{ padding: 0, color: 'red' }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                )}
              </Box>
            ))}
          </MenuList>
          <Box pb={1}>
            <Divider />
          </Box>
          <Typography variant="caption" style={{ paddingLeft: 24 }}>
            {getLang(lang, 'label.NOT_LOCALIZED')}
          </Typography>
          <MenuList
            id='menu-list-grow'
            className={classes.list}
          >
            {availableLanguages.map((item) => (
              <MenuItem
                onClick={() => handleSelectLanguage(item)}
              >
                <Box display="flex" alignItems="center">
                  <DoneIcon style={{color: "rgba(0, 0, 0, 0)"}} />
                  <Typography variant='body1' noWrap style={{ paddingLeft: 8, paddingRight: 32 }}>
                    {getLang(lang, `constant.language.${item.toUpperCase()}`)}
                  </Typography>
                </Box>
              </MenuItem>
            ))}
          </MenuList>
        </Box>
      </Box>
    </Popover>
  )
}
