import { Content } from "modules/reporting/components/layout";
import { FilterPanelV3, InstantLuckyDrawListPanel } from "modules/reporting/components/panel";
import React from "react";

export default function InstantLuckyDrawPage({
    filter,
    tagUrl,
    currentFilter,
    pageMode,
    campaignType,
    translate
}){
    const pageType="campaign-list"
    return (
        <>
            <FilterPanelV3
                filter={filter}
                currentFilter={currentFilter}
                pageMode={pageMode}
                translate={translate}
            />
            <Content>
                {/* instant lucky list --- START */}
                <InstantLuckyDrawListPanel
                    currentFilter={currentFilter}
                    urlInstantLuckyDrawList={tagUrl + "get-instant-lucky-draw-info-list"}
                    // urlInstantLuckyDrawList={"http://localhost:9080/instantLuckyDrawList"}
                    pageType={pageType}

                    campaignType={campaignType}

                    translate={translate}
                />
            </Content>
        </>
    )
}