import React from "react";
import Chart from 'react-apexcharts';
import { setChartColors } from "modules/reporting/utils/chartConfig";
import moment from "moment";    
import "moment/min/locales.min";

export default function LineChartComponent({payload,chartColors=[],chartHeight, translate,language}){

    let fontSize = '12px';
    
    
  if(language==="ZH")
  {
    moment.locale('zh-cn')
  }else if(language==="TL")
  {
    moment.locale('tl-ph')
  }else
  {
    moment.locale(language)
  }


    payload.labels.forEach((label, index) => {
        if(label.length > 15){
            fontSize = '8px';
        }
    });

    const options = {
        colors: chartColors.length > 0 ? chartColors : setChartColors(payload.datasets.length, true),
        tooltip:{
            followCursor: true,
            y: {
                formatter: function (val) {
                    return parseInt(val)
                }
            }
        },
        xaxis: {
            categories:payload.labels.map(date => moment(date).format('ll')),
            labels: {
                style:{
                    fontSize: fontSize,
                },
                rotateAlways: true,
                rotate: -45,
                hideOverlappingLabels: true,
            },
            tickPlacement: 'between',
        },
        legend: {
            position: 'bottom',
            showForSingleSeries: true
        },
        grid: {
            padding: {
                left: 12,
                right: 12,
            }
        },
        stroke: {
            width: 2,
        },
    }

    const chartSeries = () => {
        let series = JSON.stringify(payload);
        series = JSON.parse(series);

        series.datasets.forEach(e => {
            e.name = translate(e.name);
        });

        return series;
    }

    return (
        <Chart
            series={
                chartSeries().datasets
            }

            options= {
                options
            }

            type="line"
            // height={chartHeight ?? 'auto'}
            height={400}
        >
            
        </Chart>
    )
}