import { Box, Grid, IconButton, Typography } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { DownloadIcon, LineChartIcon, PieChartIcon, VerticalBarChartIcon } from "modules/reporting/assets/svg";
import React, { useState } from "react";
import { ChartCard } from "../../card";
import downloadjs from 'downloadjs';
import html2canvas from 'html2canvas';
import { ContentCard } from "../../layout";
import { ChartCardLoading } from "../../skeleton/loading";
import EmptyChart from "../../skeleton/global/empty-chart";
import { responseHandler } from "modules/reporting/utils/helper";
import { flexGapStyle, fontTextStyle } from "modules/reporting/styles";


export default function MiscellaneousPanelComponent({
    status,
    data,
    language,
    translate
}) {
    const fontClasses = fontTextStyle();
    const flexGapClasses = flexGapStyle();

    const [chartTypeSelection, setChartTypeSelection] = useState("line");
    const handleChartTypeSelection = (event, newChartTypeSelection) => {
        if (newChartTypeSelection === chartTypeSelection || newChartTypeSelection === null) {
            return;
        }
        setChartTypeSelection(newChartTypeSelection);
    };

    // download miscellaneous chart
    const handleDownloadMiscellaneousChart = () => {
        let element = document.getElementById("miscellaneous-card");

        // hide element have id = remove-this inside target
        let removeThis = element.querySelector("#miscellaneous-button-group");
        removeThis.style.display = "none";

        html2canvas(element).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            downloadjs(imgData, 'miscellaneous-qr-report.png');
        });

        // show element have id = remove-this inside target
        removeThis.style.display = "flex";

    }

    // function change chart type
    const [chartType, setChartType] = useState("line");
    function changeChartType(type) {
        setChartType(type);
    }

    const errorChart = (items) => {
        return (
            <EmptyChart
                data={items}
                title={translate("label.LOADING_FAILED")}
                value={translate("paragraph.LOADING_FAILED_DESCRIPTION")}
                labels= {['10 July', '11 July', '12 July', '13 July']}
            />
        )
    }

    const emptyChart = (items) => {
        return (
            <EmptyChart
                data={items}
                title={translate("label.CUSTOMER_NOT_FOUND")}
                value={translate("paragraph.NO_DATA_ON_SELECTED_FILTER")}
                labels= {['10 July', '11 July', '12 July', '13 July']}
            />
        )
    }

    const [first, setFirst] = useState(true);
    if(Object.keys(data.browserData).length > 0 && Object.keys(data.platformData).length > 0 && Object.keys(data.deviceData).length > 0 && Object.keys(data.languageData).length > 0){

        if(data.browserData.labels.length === 1 && first && data.platformData.labels.length === 1 && data.deviceData.labels.length === 1 && data.languageData.labels.length === 1) {
            setFirst(false);
            setChartTypeSelection("bar")
            setChartType("bar")
        }
    }

    return (
        <ContentCard id="miscellaneous-card">
            <Box marginBottom="24px" display="flex" justifyContent="space-between" alignContent="center">
                <Typography className={fontClasses.text__card_title}>
                    {translate("label.MISCELLANEOUS")}
                </Typography>
                <Box display="flex" alignContent="center" className={flexGapClasses.global__flexGap20} id="miscellaneous-button-group">
                    <IconButton title="Download Charts" aria-label="download" onClick={handleDownloadMiscellaneousChart} disableRipple>
                        <DownloadIcon />
                    </IconButton>
                    <ToggleButtonGroup
                        value={chartTypeSelection}
                        exclusive
                        onChange={handleChartTypeSelection}
                    >
                        <ToggleButton value="line" onClick={() => changeChartType("line")}>
                            <LineChartIcon />
                        </ToggleButton>
                        <ToggleButton value="donut" onClick={() => changeChartType("donut")}>
                            <PieChartIcon />
                        </ToggleButton>
                        <ToggleButton value="bar" onClick={() => changeChartType("bar")}>
                            <VerticalBarChartIcon />
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Box>
            </Box>
            <Grid container spacing={3} alignItems="stretch">
                <Grid item xs={12} sm={12} md={6} >
                    {
                        responseHandler(
                            data.platformData,
                            status.isFetchingPlatformData,
                            (
                                <ChartCard translate={translate} language={language}  type={chartType} title={translate("label.PLATFORM")} payload={data.platformData} />
                            ),
                            (
                                <ChartCardLoading />
                            ),
                            (
                                chartType === 'donut' ? 
                                emptyChart([translate("label.ANDROID"), translate("label.IPHONE"), translate("label.UNKNOWN")]) : 
                                <ChartCard translate={translate} language={language}  type={chartType} title={translate("label.PLATFORM")} payload={data.platformData} />
                            ),
                            (
                                chartType === 'donut' ? 
                                emptyChart([translate("label.ANDROID"), translate("label.IPHONE"), translate("label.UNKNOWN")]) : 
                                <ChartCard translate={translate} language={language}  type={chartType} title={translate("label.PLATFORM")} payload={data.platformData} />
                            ),
                            (
                                errorChart([translate("label.ANDROID"), translate("label.IPHONE"), translate("label.UNKNOWN")])
                            )
                        )
                    }
                </Grid>
                <Grid item xs={12} sm={12} md={6} >
                    {
                        responseHandler(
                            data.browserData,
                            status.isFetchingBrowserData,
                            (
                                <ChartCard translate={translate} language={language}  type={chartType} title={translate("label.BROWSER")} payload={data.browserData} />
                            ),
                            (
                                <ChartCardLoading />
                            ),
                            (
                                chartType === 'donut' ?
                                emptyChart([translate("label.CHROME"), translate("label.FIREFOX"), translate("label.SAFARI"), translate("label.UNKNOWN")]) :
                                <ChartCard translate={translate} language={language}  type={chartType} title={translate("label.BROWSER")} payload={data.browserData} />
                            ),
                            (
                                chartType === 'donut' ?
                                emptyChart([translate("label.CHROME"), translate("label.FIREFOX"), translate("label.SAFARI"), translate("label.UNKNOWN")]) :
                                <ChartCard translate={translate} language={language}  type={chartType} title={translate("label.BROWSER")} payload={data.browserData} />
                            ),
                            (
                                errorChart([translate("label.CHROME"), translate("label.FIREFOX"), translate("label.SAFARI"), translate("label.UNKNOWN")])
                            )
                        )
                    }
                </Grid>
                <Grid item xs={12} sm={12} md={6} >
                    {
                        responseHandler(
                            data.deviceData,
                            status.isFetchingDeviceData,
                            (
                                <ChartCard translate={translate} language={language}  type={chartType} title={translate("label.DEVICE")} payload={data.deviceData} />
                            ),
                            (
                                <ChartCardLoading />
                            ),
                            (
                                chartType === 'donut' ? 
                                emptyChart([translate("label.MOBILE"), translate("label.MOBILE"), translate("label.UNKNOWN")]) : 
                                <ChartCard translate={translate} language={language}  type={chartType} title={translate("label.DEVICE")} payload={data.deviceData} />
                            ),
                            (
                                chartType === 'donut' ? 
                                emptyChart([translate("label.MOBILE"), translate("label.MOBILE"), translate("label.UNKNOWN")]) : 
                                <ChartCard translate={translate} language={language}  type={chartType} title={translate("label.DEVICE")} payload={data.deviceData} />
                            ),
                            (
                                errorChart([translate("label.MOBILE"), translate("label.MOBILE"), translate("label.UNKNOWN")])
                            )
                        )
                    }
                </Grid>
                <Grid item xs={12} sm={12} md={6} >
                    {
                        responseHandler(
                            data.languageData,
                            status.isFetchingLanguageData,
                            (
                                <ChartCard translate={translate} language={language}  type={chartType} title={translate("label.LANGUAGE")} payload={data.languageData} />
                            ),
                            (
                                <ChartCardLoading />
                            ),
                            (
                                chartType === 'donut' ? 
                                emptyChart(['English', 'Chinese', 'Thai', translate("label.UNKNOWN")]) :
                                <ChartCard translate={translate} language={language}  type={chartType} title={translate("label.LANGUAGE")} payload={data.languageData} />
                            ),
                            (
                                chartType === 'donut' ? 
                                emptyChart(['English', 'Chinese', 'Thai', translate("label.UNKNOWN")]) :
                                <ChartCard translate={translate} language={language} type={chartType} title={translate("label.LANGUAGE")} payload={data.languageData} />
                            ),
                            (
                                errorChart(['English', 'Chinese', 'Thai', translate("label.UNKNOWN")])
                            )
                        )
                    }
                </Grid>
            </Grid>
        </ContentCard>
    );
}