import React from "react";
import { Box, makeStyles } from "@material-ui/core";
import GiftListTable from "../../table/giftListTable";
import { GeneralSearch } from "components/search";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles((theme) => ({
  actionButton: {
    padding: "5px 20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#ffffff",
    borderRadius: "8px",
    height: 43,
    border: "1px solid #D0D5DD",
    cursor: "pointer",
    fontSize: 15
  },
}));

export default function GiftListPanelComponent({
  hasGiftAddAccessRight,
  hasGiftEditAccessRight,
  hasGiftActivateOrDeactivateAccessRight,
  data,
  isFetching,
  page,
  search,
  rowsPerPage,
  totalRecords,
  handleChangePage,
  handleChangeRowsPerPage,
  handleSearch,
  handleCreate,
  handleStatusChange,
  handleReload,
  paginationOptions,
  lang,
  setShowSettingDialog
}) {
  const classes = useStyle();

  return (
    <Box>
      <GeneralSearch
        handleFilter={(value) => {
          handleSearch(value);
        }}
      />

      <Box
        style={{
          display: "flex",
          justifyContent: "flex-end",
          paddingBottom: "12px",
        }}
      >
        <Box className={classes.actionButton} onClick={() => setShowSettingDialog(true)}>
          {getLang(lang, "label.SETTING")}
        </Box>
      </Box>

      <GiftListTable
        hasGiftAddAccessRight={hasGiftAddAccessRight}
        hasGiftEditAccessRight={hasGiftEditAccessRight}
        hasGiftActivateOrDeactivateAccessRight={
          hasGiftActivateOrDeactivateAccessRight
        }
        data={data}
        isFetching={isFetching}
        page={page}
        search={search}
        rowsPerPage={rowsPerPage}
        totalRecords={totalRecords}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleCreate={handleCreate}
        handleStatusChange={handleStatusChange}
        handleReload={handleReload}
        paginationOptions={paginationOptions}
      />
    </Box>
  );
}
