import { createAsyncThunk } from "@reduxjs/toolkit";
import { SERIAL_NUMBER_BATCH } from "../../constants";
import serialNumberApi from "app/api/serialnumber";

export const getGenerateBatch = createAsyncThunk(
  `${SERIAL_NUMBER_BATCH}/getGenerateBatch`,
  async (payload, thunkApi) => {
    return serialNumberApi.generateBatch
      .post(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const fetchBatchRangeList = createAsyncThunk(
  `${SERIAL_NUMBER_BATCH}/fetchBatchRangeList`,
  async (payload, thunkApi) => {
    const { prefix, postfix, length, type } = payload;

    return serialNumberApi
      .getBatchRangeList({
        prefix,
        sn_digit: length,
        postfix,
        serial_type: type,
      })
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error.response.data));
  }
);

export const updateBatchAppAuthorizeStatus = createAsyncThunk(
  `${SERIAL_NUMBER_BATCH}/updateSNStatus`,
  async (payload, { rejectWithValue }) => {
    return serialNumberApi
      .updateBatchAppAuthorizeStatus({
        uuid: payload.uuid,
        status: payload.status,
      })
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const updateBatchRedirectTo = createAsyncThunk(
  `${SERIAL_NUMBER_BATCH}/updateBatchRedirectTo`,
  async (payload, { rejectWithValue }) => {
    return serialNumberApi
      .updateBatchRedirectTo({
        uuid: payload.uuid,
        redirect_to: payload.redirect_to,
      })
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const updateBatchScanVerifyStatus = createAsyncThunk(
  `${SERIAL_NUMBER_BATCH}/updateBatchScanVerifyStatus`,
  async (payload, { rejectWithValue }) => {
    return serialNumberApi
      .updateBatchScanVerifyStatus({
        uuid: payload.uuid,
        status: payload.status,
      })
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const getGenerateProgress = createAsyncThunk(
  `${SERIAL_NUMBER_BATCH}/getGenerateProgress`,
  async (payload, thunkApi) => {
    return serialNumberApi
      .getGenerateProgress(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);
export const getExportFileDropdown = createAsyncThunk(
  `${SERIAL_NUMBER_BATCH}/getExportFileDropdown`,
  async (payload, { rejectWithValue }) => {
    return serialNumberApi
      .getExportFileDropdown(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const exportAssignSN = createAsyncThunk(
  `${SERIAL_NUMBER_BATCH}/exportAssignSN`,
  async (payload, thunkApi) => {
    return serialNumberApi
      .exportAssignSN({
        ...payload,
      })
      .then((response) => response)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);
