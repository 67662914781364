import React from "react";
import {
  Dialog,
  Button,
  makeStyles,
  Typography,
  Box,
  CircularProgress,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import { getLang } from "app/feature/constants";
import InputTextField from "components/input/inputTextField";
import { Formik } from "formik";
import { Info } from "@material-ui/icons";

const useStyle = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    padding: "35px 30px",
    backgroundColor: "#ffffff",
    width: 400,
    maxWidth: "90vw",
    margin: 20,
    color: "black !important",
    borderRadius: 12,
  },
  customDialogActionRoot: {
    justifyContent: "center",
  },
  customDialogTitle: {
    paddingTop: 0,
    color: "black",
  },
  dialogRemarksCaption: {
    fontWeight: 200,
    marginLeft: 5,
  },
  dialogRemarks: {
    color: "#FD646F",
  },
  dangerCancelButton: {
    color: "white",
    borderColor: "white !important",
  },
  successCancelButton: {
    backgroundColor: theme.palette.primary.main,
  },
  dangerText: {
    color: "white",
  },
  successText: {
    color: "white",
  },
  defaultText: {
    color: "black",
  },
}));

export default function RejectApproveRequestDialogComponent({
  isLoading,
  isOpen,
  handleClose,
  handleProceed,
  lang,
}) {
  const classes = useStyle();

  const reasonType = [
    getLang(lang, "label.OPERATIONAL_DISRUPTION"),
    getLang(lang, "label.OUT_OF_STOCK"),
    getLang(lang, "label.OTHERS"),
  ];

  return (
    <Dialog
      classes={{
        paper: classes.root,
      }}
      open={isOpen}
      disableBackdropClick
      disableEscapeKeyDown
      aria-labelledby="confirmation-dialog"
      maxWidth="sm"
    >
      <Box textAlign="start">
        <Box mb={1}>
          <Typography variant="body1">
            <b>{getLang(lang, "label.REJECT_TRANSFER_REQUEST")}</b>
          </Typography>
        </Box>
        <Box mb={2}>
          <Typography variant="body2" style={{ color: "#98A2B3" }}>
            {getLang(lang, "paragraph.REJECT_TRANSFER_REQUEST_QUESTION")}
          </Typography>
        </Box>
        <Formik
          initialValues={{
            selection: null,
            reason: "",
          }}
          onSubmit={handleProceed}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <Box width="100%" mb={2.5}>
                <RadioGroup
                  defaultValue={null}
                  value={formik.values.selection}
                  onChange={(e) =>
                    formik.setFieldValue("selection", parseInt(e.target.value))
                  }
                >
                  {reasonType.map((value, index) => (
                    <Box key={index}>
                      <FormControlLabel
                        label={value}
                        value={index + 1}
                        control={<Radio />}
                      />
                      {index === 2 && (
                        <Box pl={3.5}>
                          <InputTextField
                            multiline
                            rows={4}
                            disabled={
                              formik.values.selection !== 3 || isLoading
                            }
                            className={classes.textArea}
                            value={formik.values.reason}
                            placeholder={`${getLang(
                              lang,
                              "placeholder.ENTER_A_REASON"
                            )}...`}
                            onChange={(e) => {
                              formik.setFieldValue(
                                "reason",
                                e.target.value || ""
                              );
                            }}
                            InputStyle={{
                              background: "white",
                              border: "1px solid #D0D5DD",
                              flex: 1,
                              borderRadius: 8,
                            }}
                            fullWidth
                          />
                        </Box>
                      )}
                    </Box>
                  ))}
                </RadioGroup>
              </Box>
              <Box display="flex" alignItems="center" mb={3}>
                <Info fontSize="small" color="error" />
                <Typography variant="caption" color="error">
                  {getLang(lang, "message.info.ACTION_IRREVERSIBLE")}
                </Typography>
              </Box>
              {isLoading ? (
                <CircularProgress />
              ) : (
                <Box display="flex">
                  <Box mr={2} flex={1}>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        formik.handleReset();
                        handleClose();
                      }}
                      color="primary"
                      style={{ width: "100%" }}
                    >
                      {getLang(lang, "label.CANCEL")}
                    </Button>
                  </Box>
                  <Box flex={1}>
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      style={{
                        backgroundColor: "#F04438",
                        width: "100%",
                        opacity:
                          !formik.values.selection ||
                          (formik.values.selection === 3 &&
                            !formik.values.reason.trim())
                            ? 0.5
                            : 1,
                      }}
                      disabled={
                        !formik.values.selection ||
                        (formik.values.selection === 3 &&
                          !formik.values.reason.trim())
                      }
                    >
                      {getLang(lang, "label.CONFIRM")}
                    </Button>
                  </Box>
                </Box>
              )}
            </form>
          )}
        </Formik>
      </Box>
    </Dialog>
  );
}
