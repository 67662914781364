import React from "react";
import { makeStyles, ButtonBase, Typography } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import clsx from "clsx";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles((theme) => ({
  root: {
    padding: "5px 12px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#ECEFF0",
    borderRadius: "4px",
    width: "100%",
    height: 43
  },
  inputText: {
    fontSize: "1rem",
    color: "#A4A6A8",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  },
  filled: {
    color: "rgba(0, 0, 0, 0.87)"
  }
}));

export default function BranchSelectComponent({
  anchorRef,
  handleClick,
  value,
  disabled,
  lang
}) {
  const classes = useStyle();

  const getDisplay = () => {
    let display = getLang(lang, "paragraph.NO_BRANCH_SELECTED");
    if (value.length > 0) {
      display = value[0].name;
      if (value.length > 1) {
        display = display + " + " + (value.length - 1).toString();
      }
    }
    return display;
  };

  return (
    <ButtonBase
      className={classes.root}
      disableRipple
      ref={anchorRef}
      onClick={handleClick}
      disabled={disabled}
    >
      <Typography
        variant="body2"
        className={clsx(
          classes.inputText,
          getDisplay() !== getLang(lang, "paragraph.NO_BRANCH_SELECTED") && classes.filled
        )}
      >
        {getDisplay()}
      </Typography>
      <ArrowDropDownIcon fontSize="small" />
    </ButtonBase>
  );
}
