import React, { useState } from "react";
import DealerSelectFieldComponent from "./dealerSelectField.component";
import DealerSelectPopoverComponent from "./dealerSelectPopover.component";
import { useSelector } from "react-redux";

function DealerSelectContainer({
  searchable,
  value,
  placeholder,
  searchPlaceholder,
  handleChange,
  disabled,
  isFetching,
  panelStyle,
  dropdownItem,
  anchorOrigin,
  transformOrigin,
  PaperProps,
  style,
  textStyle,
  isEmpty,
  isClearable,
  handleClear,
  emptyMessage
}) {
  const [anchorEl, setAnchorEl ] = useState(null);
  const [searchText, setSearchText] = useState("");
  const open = Boolean(anchorEl);
  const lang = useSelector(state => state.constant.languages);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
    handleSearch("");
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const selectedValue = (selectedValue) => {
    if(isEmpty){
      return false
    }
    return selectedValue.index === value.index
  }

  const handleSearch = (val) => {
    setSearchText(val.toLowerCase());
  }

  return (
    <>
      <DealerSelectFieldComponent
        handleClick={handleClick}
        handleClear={handleClear}
        value={value}
        isEmpty={isEmpty}
        style={style}
        disabled={disabled}
        placeholder={placeholder}
        open={open}
        textStyle={textStyle}
        isClearable={isClearable}
        lang={lang}

      />
      <DealerSelectPopoverComponent
        anchorEl={anchorEl}
        open={open}
        handleClose={handleClose}
        style={panelStyle}
        items={dropdownItem}
        handleSearch={handleSearch}
        isFetching={isFetching}
        searchable={searchable}
        handleChange={handleChange}
        searchText={searchText}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        PaperProps={PaperProps}
        value={value}
        selectedValue={selectedValue}
        emptyMessage={emptyMessage}
        placeholder={searchPlaceholder}
        lang={lang}

      />
    </>
  )
}

export default DealerSelectContainer