import React, { useRef, useState } from "react";
import LanguageSelectPanelComponent from "./languageSelectPanel.component";
import LanguageSelectComponent from "./languageSelect.component";
import {useSelector} from "react-redux";

export default function LanguageSelectContainer({
  value,
  options,
  onSelect,
  disabled,
  style,
  panelStyle,
  anchorOrigin,
  transformOrigin,
  PaperProps,
  textStyle,
}) {
  const lang = useSelector(state => state.constant.languages)
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined
  const anchorRef = useRef();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const handleClose = () => {
    setAnchorEl(null);
  }

  const handleLanguageClick = (language) => {
    onSelect(language);
    setAnchorEl(null);
  }

  return (
    <>
      <LanguageSelectComponent
        handleClick={handleClick}
        value={value}
        style={style}
        disabled={disabled}
        anchorRef={anchorRef}
        open={open}
        textStyle={textStyle}
        lang={lang}
      />
      <LanguageSelectPanelComponent
        anchorEl={anchorEl}
        open={open}
        handleClose={handleClose}
        handleClick={handleLanguageClick}
        options={options}
        style={panelStyle}
        id={id}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        PaperProps={PaperProps}
        lang={lang}
      />
    </>
  )
}
