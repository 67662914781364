import React from "react";
import SurveyChoiceOverviewCardComponent from "./surveyChoiceOverviewCard.component";
import { useSelector } from "react-redux";

export default function SurveyChoiceOverviewCardContainer({
  data,
  labels
}) {
  const lang = useSelector(state => state.constant.languages);

  return (
    <SurveyChoiceOverviewCardComponent data={data} labels={labels} lang={lang} />  
  )
}