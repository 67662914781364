import axios from "axios";

const url =  "/account/api/v1/company/currency/update-status";

function updateCurrencyConfigStatus({
  currencyCode, status
}) {
  return new Promise((resolve, reject) => {
    axios.post(url, {
      currencyCode: currencyCode,
      status: status
    })
      .then(response => resolve(response))
      .catch(error => reject(error.response.data))
  })
}

export default updateCurrencyConfigStatus;