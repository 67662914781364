import React from "react";
import PropTypes from "prop-types";
import ConfirmationDialogComponent from "./confirmationDialog.component";
import { useSelector } from "react-redux";
import { getLang } from "app/feature/constants";

function ConfirmationDialogContainer({
  isLoading,
  isOpen,
  handleClose,
  handleProceed,
  title,
  description,
  remarks,
  type,  // default, success, danger
  rejectOption,
  proceedLabel,
  rejectLabel,
}) {
  const lang = useSelector(state => state.constant.languages)

  return (
    <ConfirmationDialogComponent
      isLoading={isLoading}
      isOpen={isOpen}
      handleClose={handleClose}
      handleProceed={handleProceed}
      title={title}
      description={description}
      remarks={remarks}
      type={type}
      proceedLabel={proceedLabel || getLang(lang, "label.CONFIRM")}
      rejectLabel={rejectLabel || getLang(lang, "label.CANCEL")}
      rejectOption={rejectOption}
    />
  );
}

ConfirmationDialogContainer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleProceed: PropTypes.func.isRequired
};

export default ConfirmationDialogContainer;