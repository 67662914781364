import axios from "axios"

export function getDealerInvitedList (parameters) {
  return new Promise((resolve, reject) => {
    axios.post(`/distributor/admin/api/v1/dealership/get-dealer-invited-list`, {
      start_date: parameters.start_date,
      end_date: parameters.end_date,
      dealer_uuid: parameters.dealer_uuid,
      start: parameters.start,
      length: parameters.length,
      order: parameters.order,
      order_by: parameters.order_by
    })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data))
  });
};