const API_HOST = process.env.REACT_APP_API_HOST_URL;

const post = async (payload) => {
  return new Promise((resolve, reject) => {
    fetch(`${API_HOST}/analytic/export/v1/dashboard/cluster-scan-listing`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${payload.token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: payload.token,
        order: payload.order,
        order_by: payload.order_by,
        filter: {
          start_date: payload.start_date,
          end_date: payload.end_date,
          longitude: payload.longitude,
          latitude: payload.latitude,
          consumers: payload.consumers,
          status: payload.status,
        },
      }),
    })
      .then((response) => resolve(response.blob()))
      .catch((error) => reject(error.response.data));
  });
};

export const exportClusterScanListing = {
  post,
};
