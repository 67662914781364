import React from "react";
import {
  makeStyles,
  Typography,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  Box,
  Divider,
  MenuItem,
  MenuList,
  Checkbox,
  InputAdornment,
  Button,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { getLang } from "app/feature/constants";
import InputTextField from "components/input/inputTextField";
import { Search as SearchIcon } from "@material-ui/icons"
import { useRef } from "react";

const useStyle = makeStyles((theme) => ({
  paper: {
    width: 425
  },
  button: {
    padding: 0,
    minWidth: 0
  },
  buttonAddBranch: {
    display: "flex",
    padding: 0,
    minWidth: 0
  },
  createLink: {
    padding: theme.spacing(2)
  }
}));

export default function BranchPanelComponent({
  open,
  anchorRef,
  handleClose,
  isOpenList,
  branchDropdown,
  isFetching,
  value,
  handleSelectAll,
  selectAll,
  handleSelectBranch,
  initialValue,
  lang,
  searchable,
  handleSearch
}) {
  const classes = useStyle();
  const textRef = useRef();

  const generateLayeredSwitch = (nodes, layer) => {
    if (!nodes || nodes.length === 0) {
      return null;
    }

    return nodes.map((node, index) => {
      return layer === -1 ? (
        <React.Fragment key={index}>
          <MenuItem
            style={{ padding: 0 }}
            onClick={() => handleSelectBranch(node)}
            disabled={!!value.find((v) => v.node_id === node.parent_node_id)}
          >
            <Checkbox
              checked={!!value.find((v) => v.node_id === node.node_id)}
              disabled={!!initialValue.some(t => t.node_id === node.node_id)}
            />
            <Typography
              variant="inherit"
              noWrap
              style={{ paddingLeft: "8px" }}
            >
              {node.name}
            </Typography>
          </MenuItem>

          {generateLayeredSwitch(
            node.child,
            1,
          )}
        </React.Fragment>
      ) : (
        <React.Fragment key={index}>
          <MenuList
            autoFocusItem={isOpenList}
            id="menu-list-grow"
            style={{ padding: 0 }}
          >
            <MenuItem
              style={{ paddingTop: 0, paddingBottom: 0, paddingLeft: layer * 40, paddingRight: 0 }}
              onClick={() => { handleSelectBranch(node) }}
            >
              <Checkbox
                checked={!!value.find((v) => v.node_id === node.node_id)}
                disabled={!!initialValue.some(t => t.node_id === node.node_id)}
              />

              <Typography
                variant="inherit"
                noWrap
                style={{ paddingLeft: "8px" }}
              >
                {node.name}
              </Typography>
            </MenuItem>
          </MenuList>

          {generateLayeredSwitch(
            node.child,
            layer + 1
          )}
        </React.Fragment>
      );
    });
  }

  return (
    <Popper
      open={open}
      style={{ zIndex: 99 }}
      anchorEl={anchorRef.current}
      role={undefined}
      transition
      disablePortal
      placement="bottom-start"
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === "bottom-start" ? "center top" : "center bottom"
          }}
        >
          <Paper className={classes.paper}>
            <ClickAwayListener onClickAway={handleClose}>
              <Box>
                {/* <Typography
                  variant="body2"
                  color="primary"
                  style={{ padding: "8px 16px", fontWeight: "bold" }}
                >
                  {getLang(lang,"label.BRANCH")}
                </Typography>
                <Divider /> */}

                  {searchable && (
                    <Box>
                      <InputTextField
                        variant="filled"
                        size="small"
                        fullWidth
                        inputRef={textRef}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='end' style={{ paddingRight: 6 }}>
                              <Button
                                onClick={() => handleSearch(textRef.current.value)}
                                className={classes.button}
                              >
                                <SearchIcon />
                              </Button>
                            </InputAdornment>
                          ),
                          style: {
                            backgroundColor: "#fff",
                            paddingRight: 0
                          }
                        }}
                        inputProps={{
                          onKeyDown: (e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              handleSearch(textRef.current.value)
                              return false
                            }
                          },
                          style: {
                            padding: 8
                          }
                        }}
                        placeholder={getLang(lang, "placeholder.SEARCH")}
                      />
                    </Box>
                  )}
                  <Divider />
                  {isFetching ? (
                    <>
                      <Skeleton variant="text" animation="wave" />
                      <Skeleton variant="text" animation="wave" />
                      <Skeleton variant="text" animation="wave" />
                      <Skeleton variant="text" animation="wave" />
                    </>
                  ) : (
                    <div>
                      <MenuList
                        autoFocusItem={isOpenList}
                        id="menu-list-grow"
                        onClick={() => {
                          handleSelectAll();
                        }}
                        style={{ padding: 0 }}
                      >
                        <MenuItem style={{ padding: 0 }}>
                          <Checkbox checked={selectAll} />
                          <Typography
                            variant="inherit"
                            noWrap
                            style={{ paddingLeft: "8px" }}
                          >
                            {getLang(lang, "label.ALL")}
                          </Typography>
                        </MenuItem>
                      </MenuList>
                      <Divider />

                      <Box height="26vh" overflow="auto">
                        {generateLayeredSwitch(branchDropdown, -1)}
                      </Box>
                    </div>
                  )}
              </Box>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}
