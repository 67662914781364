import React, { Fragment } from "react";
import {
  makeStyles,
  Typography,
  Paper,
  ClickAwayListener,
  Box,
  Divider,
  MenuItem,
  MenuList,
  Checkbox,
  IconButton,
  InputAdornment,
  Popover,
} from "@material-ui/core";
import PerfectScrollbar from "react-perfect-scrollbar";
import Skeleton from "@material-ui/lab/Skeleton";
import {
  Search as SearchIcon,
} from "@material-ui/icons"
import InputTextField from "components/input/inputTextField";
import { useRef } from "react";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles((theme) => ({
  paper: {
    width: 425
  },
  button: {
    padding: 0,
    minWidth: 0
  },
  buttonAddBranch: {
    display: "flex",
    padding: 0,
    minWidth: 0
  },
  createLink: {
    padding: theme.spacing(2)
  }
}));

export default function BulkCreateBranchPanelComponent({
  open,
  anchorEl,
  handleClose,
  isOpenList,
  branchDropdown,
  isFetching,
  value,
  handleSelectAll,
  selectAll,
  handleSelectBranch,
  handleBranchSearch,
  searchable,
  placeholder,
  anchorOrigin,
  transformOrigin,
  lang,
  showAllButton
}) {
  const classes = useStyle();
  const textRef = useRef();

  const generateComponent = (nodes, layer) => {
    if (!nodes || nodes.length === 0) {
      return null;
    }

    return nodes.map((node, index) => {
      return layer === -1 ? (
        <React.Fragment key={index}>
          <MenuItem
            style={{ padding: 0 }}
            onClick={() => handleSelectBranch(node)}
            disabled={!!value.find((v) => v.node_id === node.parent_node_id)}
          >
            <Checkbox
              checked={!!value.find((v) => v.node_id === node.node_id)}
            />
            <Typography
              variant="inherit"
              noWrap
              style={{ paddingLeft: "8px" }}
            >
              {node.name}
            </Typography>
          </MenuItem>
          {generateComponent(node.child, 1)}
        </React.Fragment>
      ) : (
        <React.Fragment key={index}>
          <MenuList
            autoFocusItem={isOpenList}
            id="menu-list-grow"
            style={{ padding: 0 }}
          >
            <MenuItem
              style={{ paddingTop: 0, paddingBottom: 0, paddingLeft: layer * 40, paddingRight: 0 }}
              onClick={() => { handleSelectBranch(node) }}
            >
              <Checkbox
                checked={!!value.find((v) => v.node_id === node.node_id)}
              />

              <Typography
                variant="inherit"
                noWrap
                style={{ paddingLeft: "8px" }}
              >
                {node.name}
              </Typography>
            </MenuItem>
          </MenuList>

          {generateComponent(node.child, layer + 1)}
        </React.Fragment>
      );
    });
  }

  return (
    <Popover
      id={open ? "simple-popover" : undefined}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      elevation={2}
    >
      <Paper className={classes.paper}>
        <ClickAwayListener onClickAway={handleClose}>
          <Box>
            {/* <Typography
              variant="body2"
              color="primary"
              style={{ padding: "8px 16px", fontWeight: "bold" }}
            >
              Branch
            </Typography>
            <Divider /> */}
            {searchable && (
              <Fragment>
                <InputTextField
                  variant="filled"
                  size="small"
                  fullWidth
                  inputRef={textRef}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end' style={{ paddingRight: 6 }}>
                        <IconButton onClick={() => handleBranchSearch(textRef.current.value)}>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                    style: {
                      backgroundColor: "#fff",
                      paddingRight: 0
                    }
                  }}
                  inputProps={{
                    onKeyDown: (e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        handleBranchSearch(textRef.current.value)
                      }
                    },
                    style: {
                      padding: "8px"
                    }
                  }}
                  placeholder={placeholder || getLang(lang, "placeholder.SEARCH")}
                />
              </Fragment>
            )}
            <Divider />
            <PerfectScrollbar>
              {isFetching ? (
                <>
                  <Skeleton variant="text" animation="wave" />
                  <Skeleton variant="text" animation="wave" />
                  <Skeleton variant="text" animation="wave" />
                  <Skeleton variant="text" animation="wave" />
                </>
              ) : (
                <div>
                  {!!showAllButton && (
                    <>
                      <MenuList
                        autoFocusItem={isOpenList}
                        id="menu-list-grow"
                        onClick={() => {
                          handleSelectAll();
                        }}
                        style={{ padding: 0 }}
                      >
                        <MenuItem style={{ padding: 0 }}>
                          <Checkbox checked={selectAll} />
                          <Typography
                            variant="inherit"
                            noWrap
                            style={{ paddingLeft: "8px" }}
                          >
                            {getLang(lang, "label.ALL")}
                          </Typography>
                        </MenuItem>
                      </MenuList>
                      <Divider />
                    </>
                  )}
                  <Box height="25vh" overflow="auto">
                    {generateComponent(branchDropdown, -1)}
                  </Box>
                </div>
              )}
            </PerfectScrollbar>
          </Box>
        </ClickAwayListener>
      </Paper>
    </Popover>
  );
}
