import React, { useEffect } from "react";
import InfoHeaderComponent from "./infoHeader.component";
import { fetchEmployeeDetail } from "modules/employee/redux";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

function InfoHeaderContainer({ isFetching }) {
  const history = useHistory();
  const employeeDetail = useSelector((state) => state.employee.employeeDetail);
  const dispatch = useDispatch();
  const lang = useSelector(state => state.constant.languages);
  const language = useSelector(state => state.profile.language);
  const id = history.location.pathname.substring(
    history.location.pathname.lastIndexOf("/") + 1
  );

  useEffect(() => {
    dispatch(fetchEmployeeDetail(id));
  }, [id, dispatch]);

  return (
    <InfoHeaderComponent
      employeeDetail={employeeDetail}
      isFetching={isFetching}
      lang={lang}
      language={language}
    />
  );
}

export default InfoHeaderContainer;
