import axios from "axios";

export function updateRequestTransferAddress(parameters) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/distributor/admin/api/v1/request-transfer/edit-address`, {
        uuid: parameters.uuid,
        branch_uuid: parameters.branch_uuid,
        phone_code: parameters.phone_code,
        phone_number: parameters.phone_number,
        address_line_1: parameters.address_line_1,
        address_line_2: parameters.address_line_2,
        postal_code: parameters.postal_code,
        city: parameters.city,
        state: parameters.state,
        country: parameters.country,
      })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data));
  });
}
