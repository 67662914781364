import React from "react";
import {
  makeStyles,
  Box,
  Typography,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";
import {
  StyledTableCell,
  StyledTableRow,
  StickyRightTableCell,
} from "components/table";
import {
  NavigateBeforeRounded as ArrowPreviousIcon,
  NavigateNextRounded as ArrowNextIcon,
} from "@material-ui/icons";
import { SkeletonTableRow } from "components/skeleton/skeleton.component";
import ActionButton from "components/button/actionDetailButton";
import RefreshTable from "components/table/refreshTable";
import { getLang } from "app/feature/constants";
import moment from "moment";
import "moment/min/locales.min";

const useStyle = makeStyles((theme) => ({
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    borderCollapse: "separate",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
    },
    "& th,td": {
      fontSize: ".75rem",
      borderRight: "1px solid rgba(58, 77, 84, 0.1)",
      padding: theme.spacing(1, 2, 1, 2),
    },
    "& th": {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightBold,
    },
    "& th:last-child, td:last-child": {
      width: 37,
      right: "-2px",
    },
    "& th th": {
      borderBottom: "none !important",
    },
    "& td td": {
      borderBottom: "none !important",
    },
  },
  emptyContainer: {
    textAlign: "center",
  },
  button: {
    display: "flex",
    alignItems: "center",
    padding: 0,
    minWidth: 0,
    marginLeft: theme.spacing(1),
  },
  attributeText: {
    color: "#b2b2b2",
    fontSize: 12,
    whiteSpace: "break-spaces",
  },
  select: {
    fontSize: 14,
    backgroundColor: "#ffffff",
    "&:hover": {
      backgroundColor: "#ffffff",
    },
    "& .MuiSelect-filled.MuiSelect-filled": {
      padding: "0px 32px 0px 0px",
    },
  },
}));

export default function AdtProductListTableComponent({
  rows,
  page,
  rowsPerPage,
  language,
  handleChangePage,
  handleChangeRowsPerPage,
  isFetching,
  handleReload,
  lang,
  userLang,
  additionalFields,
  handleViewDetail,
  isError,
  hasNextPage,
  displayLang,
}) {
  const classes = useStyle();

  if (language === "ZH") {
    moment.locale("zh-cn");
  } else if (language === "TL") {
    moment.locale("tl-ph");
  } else {
    moment.locale(language);
  }

  const paginationOption = [25, 50, 100, 200, 400];

  return (
    <>
      {rows.length > 0 || isFetching ? (
        <Box>
          <TableContainer>
            <Table className={classes.table} aria-label="product table">
              <TableHead>
                <TableRow>
                  <StyledTableCell
                    style={{
                      width: "48px",
                      position: "sticky",
                      left: 0,
                      zIndex: 1,
                      backgroundColor: "white",
                    }}
                  >
                    {getLang(lang, "label.NUM")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.PRODUCT")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.QUANTITY")}
                  </StyledTableCell>
                  {!!additionalFields.length &&
                    additionalFields.map((a, i) => (
                      <StyledTableCell key={i}>
                        {a.title
                          ? a.title[displayLang] ??
                            a.title[userLang] ??
                            a.title["EN"] ??
                            Object.values(a.title)[0]
                          : ""}
                      </StyledTableCell>
                    ))}
                  <StickyRightTableCell>
                    <Box style={{ padding: "8px 16px" }}>
                      {getLang(lang, "label.ACTION")}
                    </Box>
                  </StickyRightTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isFetching
                  ? [...new Array(5)].map((v, index) => (
                      <SkeletonTableRow
                        key={index}
                        columnCount={4 + additionalFields.length}
                      />
                    ))
                  : rows.map((row, index) => {
                      const actionItems = [
                        {
                          label: getLang(lang, "label.VIEW_DETAIL"),
                          action: () => handleViewDetail(row),
                        },
                      ];

                      return (
                        <StyledTableRow
                          key={index}
                          hover
                          role="checkbox"
                          tabIndex={-1}
                        >
                          <StyledTableCell
                            style={{
                              width: "48px",
                              position: "sticky",
                              left: 0,
                              zIndex: 1,
                              backgroundColor: "white",
                            }}
                          >
                            {index + 1 + rowsPerPage * page}
                          </StyledTableCell>
                          <StyledTableCell>
                            <Box
                              display="flex"
                              flexDirection="column"
                              justifyContent="center"
                              alignItems="flex-start"
                            >
                              <Typography
                                variant="body2"
                                style={{ fontSize: 12 }}
                              >
                                {row.product ? row.product.name : "-"}
                              </Typography>
                              {!!row.attributes && !!row.attributes.length && (
                                <Typography
                                  className={classes.attributeText}
                                  variant="body2"
                                >
                                  {row.attributes.join(", ")}
                                </Typography>
                              )}
                            </Box>
                          </StyledTableCell>
                          <StyledTableCell>{row.quantity}</StyledTableCell>
                          {!!additionalFields.length &&
                            additionalFields.map((a, i) => {
                              let info = row.additionalFields.find(
                                (f) => f.id === a.id
                              );
                              return (
                                <StyledTableCell key={i}>
                                  {info && info.value
                                    ? info.format === 1
                                      ? info.value[displayLang] ?? "-"
                                      : info.value
                                    : "-"}
                                </StyledTableCell>
                              );
                            })}
                          <StickyRightTableCell>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <ActionButton
                                actionItems={actionItems}
                                popperStyle={{ zIndex: 999 }}
                              />
                            </div>
                          </StickyRightTableCell>
                        </StyledTableRow>
                      );
                    })}
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            alignItems="center"
            style={{ backgroundColor: "#fff" }}
          >
            <Box display="flex" alignItems="center">
              <Box mr={1}>
                <Typography variant="body2">
                  {getLang(lang, "label.ROWS_PER_PAGE")}
                </Typography>
              </Box>
              <FormControl>
                <Select
                  value={rowsPerPage}
                  onChange={handleChangeRowsPerPage}
                  disableUnderline
                  displayEmpty
                  style={{ fontSize: 14 }}
                  variant="filled"
                  className={classes.select}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  {paginationOption.map((p, i) => (
                    <MenuItem value={p} key={i}>
                      {p}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <IconButton
              disabled={!page}
              onClick={() => handleChangePage(page - 1)}
            >
              <ArrowPreviousIcon />
            </IconButton>
            <Typography variant="body2">{page + 1}</Typography>
            <IconButton
              disabled={!hasNextPage}
              onClick={() => handleChangePage(page + 1)}
            >
              <ArrowNextIcon />
            </IconButton>
          </Box>
        </Box>
      ) : !isFetching && rows.length === 0 ? (
        <Box style={{ overflowX: "auto", backgroundColor: "#fff" }}>
          <Table
            className={classes.table}
            aria-label="product table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <StyledTableCell>{getLang(lang, "label.NUM")}</StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.PRODUCT")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.QUANTITY")}
                </StyledTableCell>
                {!!additionalFields.length &&
                  additionalFields.map((a, i) => (
                    <StyledTableCell key={i}>
                      {a.title
                        ? a.title[displayLang] ??
                          a.title[userLang] ??
                          a.title["EN"] ??
                          Object.values(a.title)[0]
                        : ""}
                    </StyledTableCell>
                  ))}
                <StickyRightTableCell>
                  <Box style={{ padding: "8px 16px" }}>
                    {getLang(lang, "label.ACTION")}
                  </Box>
                </StickyRightTableCell>
              </TableRow>
            </TableHead>
          </Table>
          <Box py={9} className={classes.emptyContainer}>
            <Typography variant="body1">
              {getLang(lang, "message.info.NO_RECORD_EXIST")}
            </Typography>
          </Box>
        </Box>
      ) : null}

      {!isFetching && isError && <RefreshTable handleReload={handleReload} />}
    </>
  );
}
