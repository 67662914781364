import React, { memo } from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";
import MarkerIcon from "assets/img/marker.png";


const containerStyle = {
  width: "100%",
  height: "250px"
};

const mapOptions = {
  mapTypeControl: false,
  streetViewControl: false,
  rotateControl: false,
  fullscreenControl: false,
  gestureHandling: "none",
  clickableIcons: false,
  keyboardShortcuts: false
};

function MapsComponent({ latitude, longitude, isLoaded }) {
  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={{
        lat: parseFloat(latitude),
        lng: parseFloat(longitude)
      }}
      zoom={12}
      options={mapOptions}
    >
      <Marker
        icon={{
          url: MarkerIcon,
          scaledSize: {
            width: 30,
            height: 30
          },
        }}
        position={{
          lat: parseFloat(latitude),
          lng: parseFloat(longitude)
        }}
      />
    </GoogleMap>
  ) : (
    <></>
  );
}

export default memo(MapsComponent)