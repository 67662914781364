import React from "react";
import {
  makeStyles,
  Box,
  Typography,
  IconButton,
  Button,
  TextField,
  MenuItem,
} from "@material-ui/core";
import {
  EmailOutlined as EmailIcon,
  PhoneOutlined as PhoneIcon,
  CakeOutlined as CakeIcon,
} from "@material-ui/icons";
import GenderIcon from "assets/img/gender.png";
import RefreshTable from "components/table/refreshTable";
import PanelBG from "assets/img/consumer-panel-bg.png";
import { Skeleton } from "@material-ui/lab";
import DefaultImage from "assets/img/defaultPhoto.png";
import { getLang } from "app/feature/constants";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import LoyaltyIcon from "assets/img/loyaltyIcon.png";
import moment from "moment";
import EditIcon from "@material-ui/icons/Edit";
import { ErrorMessage, Formik } from "formik";
import countries from "lib/constants/countries";
import * as Yup from "yup";
import DatePicker from "components/input/datePicker";
import CountryCodeSelect from "components/select/countryCodeSelect";
import "moment/min/locales.min";

const useStyle = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    color: "#000000",
  },
  profileContainer: {
    backgroundImage: "linear-gradient(#FFFFFF, #FFFFF3, #FFFEF2)",
    borderRadius: 4,
    position: "relative",
    alignItems: "center",
  },
  loyaltyBar: {
    backgroundImage: "linear-gradient(to right, #FFF0E380, #FFC08980)",
    width: "fit-content",
    padding: "4px 16px",
    borderRadius: "50px",
    marginTop: 5,
    color: "#DC6803",
    "& img": {
      height: "18px",
      minHeight: "18px",
      minWidth: "18px",
      objectFit: "contain",
      marginRight: 8,
    },
  },
  panelBG: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "60%",
    objectFit: "contain",
  },
  profile: {
    height: 140,
    width: 140,
    minWidth: 140,
    padding: "2px",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& img": {
      height: "100%",
      minHeight: "100%",
      minWidth: "100%",
      objectFit: "cover",
      borderRadius: "50%",
      border: "1px solid #D0D5DD",
    },
  },
  newDealerContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    background:
      "linear-gradient(270deg, rgba(210, 255, 232, 0.5) 4.61%, rgba(241, 255, 248, 0.5) 100%)",
    borderRadius: "22px",
  },
  newDealerCircle: {
    color: "#32D583",
    margin: "0.4em",
    width: "1em",
    height: "1em",
  },
  newDealerLabel: {
    fontWeight: 500,
    fontSize: "1rem",
    lineHeight: "24px",
    color: "#32D583",
    paddingRight: "1rem",
  },
  tabContainer: {
    backgroundColor: "white",
    padding: "0px 20px",
    borderRadius: "6px 6px 0px 0px",
  },
  panel: {
    background: "white",
    borderRadius: 4,
    marginTop: 2,
    padding: "16px 20px",
  },
  infoSection: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    columnGap: theme.spacing(2),
    rowGap: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
      gridTemplateRows: "1fr auto",
      rowGap: theme.spacing(2),
    },
  },
  icon: {
    color: "#98A2B3",
    marginRight: 12,
    fontSize: 24,
  },
  gender: {
    width: 25,
    height: 24,
    objectFit: "contain",
    marginRight: 12,
  },
  field: {
    display: "grid",
    gridTemplateColumns: "1fr 2fr",
    columnGap: theme.spacing(2),
    marginBottom: 4,
  },
  detailContainer: {
    display: "grid",
    backgroundColor: "#FFFFFF",
    gridTemplateColumns: "0.8fr 1fr",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
    },
    "& >div:last-child": {
      borderLeft: "2px solid #e6e6e6",
      paddingLeft: 8,
    },
  },
  editNameContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    marginBottom: 8
  },
  editNameField: {
    marginRight: "1rem",
  },
  editPhoneContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
}));

export default function DealerProfilePanelComponent({
  dealer,
  isLoading,
  isError,
  handleReload,
  lang,
  isEdit,
  setIsEdit,
  isSaving,
  handleUpdateProfile,
  hasStockflowDealerEditAccessRight,
  isDatePickerOpen,
  language,
  setIsDatePickerOpen,
}) {
  const classes = useStyle();

  if(language==="ZH")
  {
    moment.locale('zh-cn')
  }else if(language==="TL")
  {
    moment.locale('tl-ph')
  }else
  {
    moment.locale(language)
  }
  
  const genderItems = [
    {
      value: "male",
      label: "Male",
    },
    {
      value: "female",
      label: "Female",
    },
    {
      value: "others",
      label: "Others",
    },
  ];

  return (
    <Box className={classes.root}>
      <Box gridArea="panel">
        {!isLoading && dealer && (
          <Formik
            initialValues={{
              firstName: dealer.firstName,
              lastName: dealer.lastName,
              gender: dealer.gender,
              phoneCode:
                countries.find(({ phone }) => phone === dealer.phoneCode) ?? {},
              phoneNumber: dealer.phoneNumber,
              dateOfBirth: dealer.dateOfBirth,
            }}
            enableReinitialize
            validationSchema={Yup.object({
              firstName: Yup.string()
                .trim()
                .max(
                  100,
                  getLang(lang, "message.error.FORM_VALIDATE_MAX", {
                    value: 255,
                  })
                )
                .required(
                  getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")
                ),
              lastName: Yup.string()
                .trim()
                .max(
                  100,
                  getLang(lang, "message.error.FORM_VALIDATE_MAX", {
                    value: 255,
                  })
                )
                .required(
                  getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")
                ),
              gender: Yup.string()
                .trim()
                .required(
                  getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")
                ),
              phoneCode: Yup.object().required(
                getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")
              ),
              phoneNumber: Yup.string()
                .trim()
                .min(
                  6,
                  getLang(lang, "message.error.FORM_VALIDATE_MAX", {
                    value: 6,
                  })
                )
                .max(
                  20,
                  getLang(lang, "message.error.FORM_VALIDATE_MAX", {
                    value: 20,
                  })
                )
                .matches(
                  /^[1-9]{1}[0-9]{4,19}$/,
                  getLang(lang, "message.error.FORM_VALIDATE_PHONE")
                )
                .required(
                  getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")
                ),
              dateOfBirth: Yup.string(),
            })}
            onSubmit={handleUpdateProfile}
          >
            {(formik) => (
              <form onSubmit={formik.handleSubmit}>
                <Box className={classes.profileContainer} px={3} py={2.5}>
                  <img src={PanelBG} className={classes.panelBG} alt="bg" />
                  <Box display="flex" alignItems="center">
                    <Box className={classes.profile} mr={1.5}>
                      {dealer.picture ? (
                        <img src={dealer.picture} alt={dealer.name} />
                      ) : (
                        <img src={DefaultImage} alt="default profile" />
                      )}
                    </Box>
                    <Box>
                      {isEdit ? (
                        <Box className={classes.editNameContainer}>
                          <TextField
                            className={classes.editNameField}
                            required
                            hiddenLabel
                            variant="outlined"
                            margin="small"
                            size="small"
                            placeholder={getLang(
                              lang,
                              "placeholder.FIRST_NAME_REQUIRED"
                            )}
                            fullWidth
                            {...formik.getFieldProps("firstName")}
                            error={
                              formik.touched.firstName &&
                              formik.errors.firstName
                                ? true
                                : false
                            }
                            helperText={ErrorMessage({ name: "firstName" })}
                            disabled={isSaving}
                          />
                          <TextField
                            fontSize=""
                            className={classes.editNameField}
                            required
                            hiddenLabel
                            variant="outlined"
                            margin="small"
                            size="small"
                            placeholder={getLang(
                              lang,
                              "placeholder.LAST_NAME_REQUIRED"
                            )}
                            fullWidth
                            {...formik.getFieldProps("lastName")}
                            error={
                              formik.touched.lastName && formik.errors.lastName
                                ? true
                                : false
                            }
                            helperText={ErrorMessage({ name: "lastName" })}
                            disabled={isSaving}
                          />
                        </Box>
                      ) : (
                        <Typography variant="h5">
                          <b>{dealer.name}</b>
                        </Typography>
                      )}
                      <Box display="flex" alignItems="center">
                        <Box
                          className={classes.loyaltyBar}
                          display="flex"
                          alignItems="center"
                        >
                          <img src={LoyaltyIcon} alt="loyalty" />
                          <Typography variant="subtitle2">
                            <b>{dealer.incentivePoint}</b>
                          </Typography>
                        </Box>
                        {dealer.newDealerFlag && (
                          <Box className={classes.newDealerContainer}>
                            <FiberManualRecordIcon
                              className={classes.newDealerCircle}
                              fontSize={"small"}
                            />
                            <Typography className={classes.newDealerLabel}>
                              {getLang(lang, "label.NEW_DEALER")}
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    </Box>
                    {!!hasStockflowDealerEditAccessRight &&
                      (!isEdit ? (
                        <Box position="absolute" top={4} right={4}>
                          <IconButton
                            aria-label="option"
                            onClick={() => setIsEdit(true)}
                          >
                            <EditIcon />
                          </IconButton>
                        </Box>
                      ) : (
                        <Box
                          alignSelf="flex-start"
                          justifySelf="flex-end"
                          display="flex"
                          justifyContent="flex-end"
                          flex={1}
                        >
                          <Button
                            onClick={() => setIsEdit(false)}
                            type="button"
                            style={{ marginRight: 6 }}
                          >
                            {getLang(lang, "label.CANCEL")}
                          </Button>
                          <Button
                            variant="contained"
                            color="secondary"
                            classes={{ root: classes.secondaryButton }}
                            disableElevation
                            type="submit"
                            disabled={isSaving}
                          >
                            {isSaving
                              ? getLang(lang, "label.SAVING")
                              : getLang(lang, "label.SAVE")}
                          </Button>
                        </Box>
                      ))}
                  </Box>
                </Box>
                <Box
                  className={classes.detailContainer}
                  display="flex"
                  justifyContent="space-between"
                  mt={1.5}
                  p={2}
                >
                  <Box pr={1} mb={1}>
                    <Typography variant="body1">
                      <b>{getLang(lang, "label.PERSONAL_INFORMATOIN")}</b>
                    </Typography>
                    <Box className={classes.infoSection}>
                      <Box>
                        <Box display="flex" alignItems="center" mt={1.5}>
                          <EmailIcon className={classes.icon} />
                          <Typography>{dealer.email || "-"}</Typography>
                        </Box>
                        <Box display="flex" alignItems="center" mt={1.5}>
                          <PhoneIcon className={classes.icon} />
                          {isEdit ? (
                            <Box className={classes.editPhoneContainer} flex={1}>
                              <CountryCodeSelect
                                type="phone"
                                textFieldProps={{
                                  required: true,
                                  variant: "outlined",
                                  margin: "dense",
                                  error:
                                    formik.errors.phoneCode &&
                                    formik.touched.phoneCode
                                      ? true
                                      : false,
                                  helperText: ErrorMessage({
                                    name: "phoneCode",
                                  }),
                                }}
                                formProps={{
                                  variant: "outlined",
                                  margin: "dense",
                                  style: {
                                    width: "40%",
                                    marginRight: "1em",
                                  },
                                  ...formik.getFieldProps("phoneCode"),
                                  value: formik.values.phoneCode,
                                  onChange: (e, newValue) => {
                                    formik.setFieldValue("phoneCode", newValue);
                                  },
                                  disabled: isSaving,
                                  disableClearable: true,
                                }}
                              />
                              <TextField
                                required
                                hiddenLabel
                                variant="outlined"
                                margin="dense"
                                placeholder={"Phone Number (Required)"}
                                style={{ width: "60%" }}
                                {...formik.getFieldProps("phoneNumber")}
                                error={
                                  formik.touched.phoneNumber &&
                                  formik.errors.phoneNumber
                                    ? true
                                    : false
                                }
                                helperText={ErrorMessage({
                                  name: "phoneNumber",
                                })}
                                disabled={isSaving}
                              />
                            </Box>
                          ) : (
                            <Typography>
                              {!!dealer.phoneCode && (
                                <span>{dealer.phoneCode} </span>
                              )}
                              {dealer.phoneNumber || "-"}
                            </Typography>
                          )}
                        </Box>
                      </Box>
                      <Box>
                        <Box display="flex" alignItems="center" mt={1.5}>
                          <CakeIcon className={classes.icon} />
                          {isEdit ? (
                            <DatePicker
                              required
                              label=""
                              variant="inline"
                              open={isDatePickerOpen}
                              onOpen={() => {
                                setIsDatePickerOpen(true);
                              }}
                              onClose={() => {
                                setIsDatePickerOpen(false);
                              }}
                              onClick={() => {
                                setIsDatePickerOpen(!isDatePickerOpen);
                              }}
                              placeholder={getLang(
                                lang,
                                "placeholder.DATE_OF_BIRTH_REQUIRED"
                              )}
                              format="DD MMM YYYY"
                              disabled={isSaving}
                              id="date-picker-dialog"
                              value={moment(
                                formik.getFieldProps("dateOfBirth").value,
                                "DD-MM-YYYY"
                              )}
                              handleChange={(v) => {
                                formik.setFieldValue(
                                  "dateOfBirth",
                                  v.format("DD-MM-YYYY")
                                );
                              }}
                              InputPropStyle={{
                                borderTopLeftRadius: 0,
                                borderBottomLeftRadius: 0,
                                paddingLeft: "0.25em",
                                backgroundColor: "rgba(0,0,0,0)",
                              }}
                              maxDate={moment().format("DD-MM-YYYY")}
                              inputVariant="outlined"
                            />
                          ) : (
                            <Typography>
                              {dealer.dateOfBirth ? (
                                <>
                                  {moment(
                                    dealer.dateOfBirth,
                                    "DD-MM-YYYY"
                                  ).format("ll")}{" "}
                                  <Typography
                                    color="textSecondary"
                                    display="inline"
                                  >
                                    ({getLang(lang, "label.AGE")}{" "}
                                    {Math.floor(
                                      moment().diff(
                                        moment(
                                          dealer.dateOfBirth,
                                          "DD-MM-YYYY"
                                        ),
                                        "years",
                                        true
                                      )
                                    )}
                                    )
                                  </Typography>
                                </>
                              ) : (
                                "-"
                              )}
                            </Typography>
                          )}
                        </Box>
                        <Box display="flex" alignItems="center" mt={1.5}>
                          <img
                            src={GenderIcon}
                            alt="gender"
                            className={classes.gender}
                          />
                          {isEdit ? (
                            <TextField
                              required
                              select
                              hiddenLabel
                              variant="outlined"
                              margin="dense"
                              placeholder={getLang(
                                lang,
                                "placeholder.GENDER_REQUIRED"
                              )}
                              fullWidth
                              value={formik.values.gender}
                              onChange={(event) => {
                                formik.setFieldValue(
                                  "gender",
                                  event.target.value
                                );
                              }}
                              error={
                                formik.touched.gender && formik.errors.gender
                                  ? true
                                  : false
                              }
                              helperText={ErrorMessage({ name: "gender" })}
                              disabled={isSaving}
                            >
                              {genderItems.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </MenuItem>
                              ))}
                            </TextField>
                          ) : (
                            <Typography>
                              {dealer.gender
                                ? dealer.gender === "male"
                                  ? getLang(lang, "label.MALE")
                                  : dealer.gender === "female"
                                  ? getLang(lang, "label.FEMALE")
                                  : getLang(lang, "label.OTHERS")
                                : "-"}
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    <Typography variant="body1">
                      <b>{getLang(lang, "label.BRANCH_UPLINE")}</b>
                    </Typography>
                    <Box className={classes.infoSection} mt={1.5}>
                      <Box>
                        <Box className={classes.field}>
                          <Typography color="textSecondary">
                            {getLang(lang, "label.BRANCH")}
                          </Typography>
                          <Box display="flex">
                            <Typography
                              color="textSecondary"
                              style={{ marginRight: 5 }}
                            >
                              :
                            </Typography>
                            <Box>
                              <Typography
                                variant="body1"
                                style={{ textAlign: "left" }}
                              >
                                {dealer.branch?.name || "-"}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                        <Box className={classes.field}>
                          <Typography color="textSecondary">
                            {getLang(lang, "label.INCENTIVE_POINT")}
                          </Typography>
                          <Box display="flex">
                            <Typography
                              color="textSecondary"
                              style={{ marginRight: 5 }}
                            >
                              :
                            </Typography>
                            <Box>
                              <Typography
                                variant="body1"
                                color="textSecondary"
                                style={{ textAlign: "left" }}
                              >
                                {dealer.incentivePoint.toLocaleString("en-US") || "-"}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      <Box>
                        <Box className={classes.field}>
                          <Typography color="textSecondary">
                            {getLang(lang, "label.INVITED_BY")}
                          </Typography>
                          <Box display="flex">
                            <Typography
                              color="textSecondary"
                              style={{ marginRight: 5 }}
                            >
                              :
                            </Typography>
                            <Box>
                              <Typography
                                variant="body1"
                                style={{ textAlign: "left" }}
                              >
                                {dealer.upline?.name || "-"}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                        <Box className={classes.field}>
                          <Typography color="textSecondary">
                            {getLang(lang, "label.DATE_JOINED")}
                          </Typography>
                          <Box display="flex">
                            <Typography
                              color="textSecondary"
                              style={{ marginRight: 5 }}
                            >
                              :
                            </Typography>
                            <Box>
                              <Typography
                                variant="body1"
                                style={{ textAlign: "left" }}
                              >
                                {dealer.joinedAt
                                  ? moment
                                      .utc(dealer.joinedAt)
                                      .local()
                                      .format("lll")
                                  : "-"}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </form>
            )}
          </Formik>
        )}
        {!isLoading && isError && (
          <Box className={classes.tabContainer}>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <RefreshTable handleReload={handleReload} />
            </Box>
          </Box>
        )}
        {isLoading && (
          <Box gridArea="main">
            <Box px={3} py={2.5} className={classes.profileContainer}>
              <img src={PanelBG} className={classes.panelBG} alt="bg" />
              <Box display="flex" alignItems="center" mr={1} flex={1}>
                <Skeleton variant="rect" width={140} height={140} />
                <Box ml={1} flex={1}>
                  <Skeleton variant="text" height={40} />
                  <Skeleton variant="text" height={40} />
                </Box>
              </Box>
            </Box>
            <Box p={2} display="flex" mt={1.5} style={{ background: "white" }}>
              <Box flex={1} mr={1}>
                <Skeleton variant="text" height={40} mt={2} />
                <Skeleton variant="text" height={40} mt={2} />
              </Box>
              <Box flex={1} ml={1}>
                <Skeleton variant="text" height={40} mt={2} />
                <Skeleton variant="text" height={40} mt={2} />
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}
