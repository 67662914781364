import { selectLoading } from "modules/notification";
import { getCampaignInstantLuckyDrawListData } from "modules/reporting/redux";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InstantLuckyDrawListPanel from './instantLuckyDrawListPanel.component';

export default function InstantLuckyDrawListPanelContainer({
    currentFilter,
    urlInstantLuckyDrawList,
    campaignType,
    pageType,
    translate
}) {
    const dispatch = useDispatch();

    // (1) instant lucky draw list data -- start
    const isFetchingCampaignInstantLuckyDrawList = useSelector(state => selectLoading(state, getCampaignInstantLuckyDrawListData.typePrefix));
    const campaignInstantLuckyDrawListData = useSelector(state => state.reporting.campaign.campaignInstantLuckyDrawDatalist);
    // (1) instant lucky draw list data -- end
    const language = useSelector(state => state.profile.language);


    const [tableFilter, setTableFilter] = useState({
        page: 0,
        rowsPerPage: 25,
        search: "",
        searchBy: "name",
        orderBy: "created_at",
        orderDirection: "desc",
    })

    useEffect(() => {

        const payload = {
            length: tableFilter.rowsPerPage,
            start: tableFilter.page * tableFilter.rowsPerPage,
            orderBy: tableFilter.orderBy,
            orderDirection: tableFilter.orderDirection,
            search: tableFilter.search,
            searchBy: tableFilter.searchBy,
            branch_uuid: currentFilter.branchUuid,
            period: currentFilter.period,
            start_date: moment(currentFilter.dateRange[0]).format('YYYY-MM-DD'),
            end_date: moment(currentFilter.dateRange[1]).format('YYYY-MM-DD'),
            url: urlInstantLuckyDrawList
        }

        if (campaignType.campaignTypeValue)
            payload.campaign_type = campaignType.campaignTypeValue;

        dispatch(getCampaignInstantLuckyDrawListData(payload));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, tableFilter, currentFilter]);

    const handleChangePage = (e, newPage) => {
        setTableFilter((prev) => ({
            ...prev,
            page: newPage,
        }))
    }

    const handleChangeRowPerPage = (event) => {
        setTableFilter((prev) => ({
            ...prev,
            page: 0,
            rowsPerPage: event.target.value
        }))
    }

    const handleRequestSort = (event, property) => {
        const isAsc = tableFilter.orderBy === property && tableFilter.orderDirection === 'asc';
        setTableFilter((prev) => ({
            ...prev,
            orderDirection: isAsc ? 'desc' : 'asc',
            orderBy: property,
        }))
    }

    const columns = [
        {
            title: translate("label.NUM"),
            field: null,
            render: (row) => {
                return (
                    <>{row.title}</>
                )
            }
        },
        {
            title: translate("label.INSTANT_LUCKY_DRAW"),
            field: null,
            render: (row) => {
                return (
                    <>{row.title}</>
                )
            },
            size: 'small'

        },
        {
            title: 'Product(s) - Engagement',
            field: null,
            render: (row) => {
                return (
                    <>{row.title}</>
                )
            },
            size: 'small'
        },
        {
            title: translate("label.TOTAL_PARTICIPANT"),
            field: null,
            render: (row) => {
                return (
                    <>{row.title}</>
                )
            },
            size: 'small'
        },
        {
            title: translate("label.DATE_CREATED"),
            field: 'created_at',
            render: (row) => {
                return (
                    <>{row.title}</>
                )
            },
            size: 'small'
        },
        {
            title: translate("label.ACTIVE_PERIOD"),
            field: null,
            render: (row) => {
                return (
                    <>{row.title}</>
                )
            },
            size: 'small'
        },
        {
            title: translate("label.STATUS"),
            field: null,
            render: (row) => {
                return (
                    <>{row.title}</>
                )
            },
            size: 'small'
        },
    ];

    return (
        <InstantLuckyDrawListPanel
            status={{
                isFetchingCampaignInstantLuckyDrawList
            }}

            data={{
                campaignInstantLuckyDrawListData
            }}

            sort={{
                valueToOrderBy: tableFilter.orderBy,
                orderDirection: tableFilter.orderDirection,
                handleRequestSort: handleRequestSort,
            }}

            pagination={{
                handleChangePage: handleChangePage,
                handleChangeRowPerPage: handleChangeRowPerPage,
                page: tableFilter.page,
                rowsPerPage: tableFilter.rowsPerPage,
                rowsPerPageOptions: [5, 10, 25],
            }}
            language={language}
            currentFilter={currentFilter}
            columns={columns}
            pageType={pageType}

            translate={translate}
        />
    )
}