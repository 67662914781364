import { unwrapResult } from "@reduxjs/toolkit";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  randomParticipant,
  setWinnerIntoPrize,
  saveWinner,
  setType,
  changeLuckyDrawProgress,
  fetchLuckyDrawListTable,
  resetWinnerList,
} from "../../../redux";
import SetWinnerDialogComponent from "./setWinnerDialog.component";
import ConfirmationDialogComponent from "components/dialog/confirmation";
import { hideBackdrop, showBackdrop } from "modules/backdrop";
import { progressStatus } from "lib/constants/luckyDrawProgress";
import { getLang } from "app/feature/constants";
import { addAlert } from "modules/notification";

export default function SetWinnerDialogContainer({ isOpen, handleClose }) {
  const dispatch = useDispatch();
  const prizeList = useSelector((state) => state.luckyDraw.prizeListTable.data);
  const winnerType = useSelector((state) => state.luckyDraw.winnerType);
  const [confirmationDialogModal, setConfirmationDialogModal] = useState(false);
  const luckyDrawId = useSelector(
    (state) => state.luckyDraw.LuckyDrawDetail.id
  );
  const progress = useSelector(
    (state) => state.luckyDraw.LuckyDrawDetail.progress
  );
  const lang = useSelector((state) => state.constant.languages);

  const changeToUpdate = () => {
    const updateInfo = {
      index: 2,
      type: "edit",
    };
    dispatch(setType(updateInfo));
  };

  const handleRandomPickWinnerForAllPrize = () => {
    dispatch(showBackdrop());

    let totalQuantity = 0;
    for (const prize of prizeList) {
      totalQuantity += parseFloat(prize.quantity);
    }
    const settingRandom = {
      id: luckyDrawId,
      count: totalQuantity,
      exclude: [],
    };

    prizeList.forEach((prize, prizeIndex) => {
      prize.winners.forEach((w, winnerIndex) => {
        const info = {
          prizeIndex,
          winnerIndex,
        };
        dispatch(resetWinnerList(info));
      });
    });

    dispatch(randomParticipant(settingRandom))
      .then(unwrapResult)
      .then((winner) => {
        for (let i = 0; i < winner.length; i++) {
          prizeList.forEach((prize, prizeIndex) => {
            prize.winners.forEach((w, winnerIndex) => {
              const info = {
                prizeIndex,
                winnerIndex,
                serialNumber: winner[i].serialNumber,
                id: winner[i].id,
              };
              dispatch(setWinnerIntoPrize(info));
              i++;
            });
          });
        }
        dispatch(hideBackdrop());
      })
      .catch((error) => {
        dispatch(hideBackdrop());
      });
  };

  const handleSaveWinner = async () => {
    dispatch(showBackdrop());

    let hasEmptyField = false;

    const prizesUpdateInfo = [];
    for (const prize of prizeList) {
      const prizeWinners = {
        id: prize.id,
        winnerParticipantIds: [],
      };
      let count = 0;
      for (const winner of prize.winners) {
        count++;

        if (winner.participantId) {
          prizeWinners.winnerParticipantIds.push(winner.participantId);
        }
      }

      if (prizeWinners.winnerParticipantIds < count) {
        hasEmptyField = true;
        break;
      }

      prizesUpdateInfo.push(prizeWinners);
    }

    if (hasEmptyField) {
      dispatch(hideBackdrop());
      dispatch(
        addAlert({
          severity: "error",
          message: getLang(lang, "message.error.FAILED_SET_WINNER"),
        })
      );
      return;
    }

    const info = {
      luckyDrawId,
      prizes: prizesUpdateInfo,
    };

    await dispatch(saveWinner(info))
      .then(unwrapResult)
      .then((response) => {
        const updateInfo = {
          index: 2,
          type: "view",
        };
        dispatch(setType(updateInfo));
        dispatch(hideBackdrop());
      })
      .catch((error) => {
        dispatch(hideBackdrop());
      });
  };

  const handleFetchLuckyDrawList = () => {
    dispatch(fetchLuckyDrawListTable());
  };

  const handleConfirmWinner = () => {
    setConfirmationDialogModal(true);
  };

  const handleProcessConfirmWinner = () => {
    dispatch(
      changeLuckyDrawProgress({
        id: luckyDrawId,
        progress: progressStatus.confirmWinner,
      })
    )
      .then(unwrapResult)
      .then(() => handleFetchLuckyDrawList())
      .finally(() => {
        setConfirmationDialogModal(false);
        handleClose();
      });
  };

  return (
    <>
      <SetWinnerDialogComponent
        prizeList={prizeList}
        isOpen={isOpen}
        handleClose={handleClose}
        handleRandomPickWinnerForAllPrize={handleRandomPickWinnerForAllPrize}
        handleSaveWinner={handleSaveWinner}
        winnerType={winnerType}
        changeToUpdate={changeToUpdate}
        handleConfirmWinner={handleConfirmWinner}
        progress={progress}
        lang={lang}
      />
      <ConfirmationDialogComponent
        isOpen={confirmationDialogModal}
        handleClose={() => setConfirmationDialogModal(false)}
        handleProceed={handleProcessConfirmWinner}
        type={"success"}
        remark={getLang(lang, "paragraph.PUBLISH_LD_REMARK")}
        description={getLang(lang, "paragraph.PLEASE_CONFIRM")}
        title={getLang(lang, "paragraph.WINNER_CANNOT_CHANGED_ONCE_CONFIRMED")}
      />
    </>
  );
}
