import { getStockModuleSetting, updateStockModuleSetting } from "../action";
import { setLoading, addAlert } from "modules/notification";
import { getApiLang } from "app/feature/constants";

const getStockModuleSettingMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    switch (type) {
      case getStockModuleSetting.pending.type:
        dispatch(
          setLoading({ id: getStockModuleSetting.typePrefix, state: true })
        );
        break;

      case getStockModuleSetting.fulfilled.type:
        dispatch(
          setLoading({ id: getStockModuleSetting.typePrefix, state: false })
        );
        break;

      case getStockModuleSetting.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({ id: getStockModuleSetting.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

const updateStockModuleSettingMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages;

    switch (type) {
      case updateStockModuleSetting.pending.type:
        dispatch(
          setLoading({ id: updateStockModuleSetting.typePrefix, state: true })
        );
        break;

      case updateStockModuleSetting.fulfilled.type:
        dispatch(
          setLoading({ id: updateStockModuleSetting.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "success",
            message: getApiLang(lang, "success.SUCCESS_UPDATE_STOCK_MODULE_SETTINGS"),
          })
        );
        break;

      case updateStockModuleSetting.rejected.type:
        dispatch(
          setLoading({ id: updateStockModuleSetting.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

export default [
  getStockModuleSettingMiddleware,
  updateStockModuleSettingMiddleware,
];
