import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { ROOT } from "./config/profile.route";
import { ProfilePage } from "./pages";
import { profileReducer, setProfile, setAccessRights } from "./redux";

export default function Profile() {
  return (
    <Switch>
      <Route exact path={ROOT} component={ProfilePage} />
      <Route path="*">
        <Redirect to="/admin/invalid" />
      </Route>
    </Switch>
  );
}

export { ROOT, profileReducer, setProfile, setAccessRights };
