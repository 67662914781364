import React, { useRef } from "react";
import {
  makeStyles,
  Typography,
  Popover,
  Box,
  Divider,
  InputAdornment,
  Button,
  List,
  ListItem,
  ListItemText
} from "@material-ui/core";
import {
  Search as SearchIcon,
} from "@material-ui/icons"
import { Skeleton } from '@material-ui/lab';
import InputTextField from 'components/input/inputTextField';
import { TIER_ICON_PROPS } from "modules/stockflow/constants";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles((theme) => ({
  button: {
    padding: 0,
    minWidth: 0
  },
  perfectScrollBar: {
    height: 200
  },
  list: {
    paddingTop: 3,
    paddingBottom: 3
  },
  uplineProfileTierCombo: {
    display: "flex",
    flexDirection: "row",
    marginRight: "0.5em"
  },
  tierIconUpline: {
    width: "1em",
    height: "1em",
    borderRadius: "3px",
    display: "flex",
    position: "absolute",
    alignItems: "center",
    justifyContent: "center"
  },
  tierLabelUpline: {
    color: "#FFFFFF",
    fontSize: "0.625rem"
  },
  profilePhotoUpline: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #E4E7EC",
    borderRadius: "50%",
    width: "33px",
    height: "33px",
    marginRight: "0.25em",
    marginLeft: "0.25em",
    marginTop: "0.25em",
    "& img": {
      borderRadius: "50%",
      height: "33px",
      width: "33px"
    },
  },
}));

export default function DealerSelectPopoverComponent({
  open,
  anchorEl,
  handleClose,
  id,
  style,
  searchable,
  handleSearch,
  placeholder,
  items,
  isFetching,
  emptyMessage,
  handleChange,
  anchorOrigin,
  transformOrigin,
  PaperProps,
  searchText,
  selectedValue,
  lang
}) {
  const classes = useStyle();
  const textRef = useRef();

  return (
    <Popover
      id={id}
      open={open}
      style={style}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      elevation={2}
      PaperProps={{
        style: { minWidth: "20em" },
        ...PaperProps
      }}
    >
      {searchable && (
        <Box>
          <InputTextField
            variant="filled"
            size="small"
            fullWidth
            inputRef={textRef}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end' style={{ paddingRight: 6 }}>
                  <Button
                    onClick={() => handleSearch(textRef.current.value)}
                    className={classes.button}
                  >
                    <SearchIcon />
                  </Button>
                </InputAdornment>
              ),
              style: {
                backgroundColor: "#fff",
                paddingRight: 0
              }
            }}
            inputProps={{
              onKeyDown: (e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  handleSearch(textRef.current.value)
                  return false
                }
              },
              style: {
                padding: 8
              }
            }}
            placeholder={placeholder}
          />
        </Box>
      )}
      <Divider />
      <Box style={{ maxHeight: "250px", overflow: "auto" }}>
        {isFetching ? (
          <>
            <Skeleton variant="text" animation="wave" height={25} />
            <Skeleton variant="text" animation="wave" height={25} />
            <Skeleton variant="text" animation="wave" height={25} />
            <Skeleton variant="text" animation="wave" height={25} />
          </>
        ) : items.length > 0 || (searchText !== "" && items.filter(({ name }) => name.includes(searchText)).length > 0) ? (
          <List>
            {items.map((item) => {
              if (searchText === "" || (searchText !== "" && item.searchName.includes(searchText))) {
                return (
                  <Box key={item.index}>
                    <ListItem
                      button
                      onClick={() => {
                        handleChange(item);
                        handleClose();
                      }}
                      selected={selectedValue(item)}
                    >
                      <Box className={classes.uplineProfileTierCombo}>
                        <Box
                          className={classes.tierIconUpline}
                          style={{
                            backgroundColor: TIER_ICON_PROPS.find(({ tier }) => tier === item.tier).color
                          }}
                        >
                          <Typography className={classes.tierLabelUpline}>
                            T{item.tier}
                          </Typography>
                        </Box>
                        <Box className={classes.profilePhotoUpline}>
                          <img src={item.picture} alt={item.name} />
                        </Box>
                      </Box>
                      <ListItemText
                        primary={item.name}
                        secondary={
                          <React.Fragment>
                            <Typography  variant="caption">{getLang(lang, 'label.DOWNLINE')}: {item.downlineCount}</Typography>
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                  </Box>
                )
              }
              else {
                return null;
              }
            })}
          </List>
        ) : (
          <Box py={9} style={{ textAlign: "center" }}>
            <Typography variant='body1'>{emptyMessage}</Typography>
          </Box>
        )}
      </Box>
    </Popover>
  )
}