import axios from "axios";

export function getBranchSalesData({
    length,
    start,
    search,
    orderBy,
    orderDirection,
    search_by,
    country,
    city_id,
    branch_uuid,
    category_uuid,
    brand_uuid,
    model_uuid,
    product_uuid,
    period,
    start_date,
    end_date,
    url,
    campaign_type,
    campaign_uuid
}) {
    let city = city_id;
    return new Promise((resolve, reject) => {
        axios
            .post(url, {
                length,
                start,
                search,
                orderBy,
                orderDirection,
                search_by,
                country,
                city,
                branch_uuid,
                category_uuid,
                product_uuid,
                model_uuid,
                brand_uuid,
                period,
                start_date,
                end_date,
                campaign_type,
                campaign_uuid
            })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                if (error?.response?.data) {
                    reject(error.response.data);
                } else {
                    reject(error);
                }
            });
    });
}

export default getBranchSalesData;
