import React, { useState } from 'react'
import {
    Box,
    Grid,
    makeStyles,
    Tooltip,
    Typography
} from '@material-ui/core'
import HelpIcon from "@material-ui/icons/Help";
import { ContentWrapper } from '../../modal';
import InputTextField from 'components/input/inputTextField';
import { ModalFooter } from '../../modal/modal.component';
import { isUrlValid } from "../../../utils/productPage.util";
import { getLang } from 'app/feature/constants';

const useStyle = makeStyles(theme => ({
    helpIcon: {
        cursor: "pointer",
        color: theme.palette.primary.main,
        fontSize: 16
    }
}))

export default function GalleryDetailFormComponent({
    imageName,
    imageUrl,
    imageLink,
    handleChangeLink,
    handleBack,
    lang
}) {
    const classes = useStyle()
    const [url, setUrl] = useState(imageLink)
    const [isImageLinkValid, setIsImageLinkValid] = useState(isUrlValid(imageLink) || imageLink === "")

    const handleOnChange = event => {
        const value = event.target.value;
        setUrl(value);

        if (isUrlValid(value) || value === "") {
            setIsImageLinkValid(true);
        } else {
            setIsImageLinkValid(false);
        }
    }

    return (
        <Box>
            <ContentWrapper>
                <Box style={{
                    display: "flex",
                    flexDirection: "column"
                }}>
                    <Typography variant='subtitle2' color='primary'>{imageName}</Typography>
                    <Box style={{
                        margin: "20px 0px"
                    }}>
                        <img
                            style={{
                                maxWidth: 200,
                                width: "auto",
                                height: "auto",
                                maxHeight: 150
                            }}
                            src={imageUrl}
                            alt="gallery"
                        />
                    </Box>
                    <Grid container>
                        <Grid item xs={3} style={{
                            display: "flex",
                            gap: 10,
                            alignItems: "center"
                        }}>
                            <Typography
                                variant="body2"
                                color="primary"
                            >
                                {getLang(lang,"label.URL_LINK")}
                            </Typography>
                            <Tooltip
                                title={getLang(lang,"tooltips.REDIRECT_TO_ANOTHER_WEB_PAGE")}
                                arrow
                            >
                                <HelpIcon className={classes.helpIcon} />
                            </Tooltip>
                        </Grid>
                        <Grid item xs={9}>
                            <InputTextField
                                required
                                hiddenLabel
                                variant="filled"
                                size="small"
                                margin="none"
                                placeholder={getLang(lang,"placeholder.ENTER_URL_HERE")}
                                fullWidth
                                value={url}
                                onChange={handleOnChange}
                                error={!isImageLinkValid}
                                helperText={isImageLinkValid ? "" : "Invalid URL"}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </ContentWrapper>
            <ModalFooter
                handleBack={handleBack}
                showBack={true}
                handleClick={() => handleChangeLink({ name: imageName, value: url })}
                disableProceed={!isImageLinkValid}
                submitLabel={getLang(lang,"label.UPDATE")}
            />
        </Box>
    )
}
