import { createSlice } from "@reduxjs/toolkit";
import { DASHBOARD } from "modules/dashboard/constants";
import {
  fetchClusterConsumerScannedSummary,
  fetchClusterCounterfeitReportSummary,
  fetchClusterFirstScanSummary,
  fetchClusterScanConsumerDropdown,
  fetchClusterScanMap,
  fetchClusterScanProductListing,
  fetchClusterScanRecordListing,
  fetchClusterSuspendSnSummary,
  fetchClusterUniqueConsumerScannedSummary,
  fetchScanDetail,
  fetchScanMap,
  getConsumerRegisteredSummary,
  getConsumerScannedSummary,
  getCounterfeitReportSummary,
  getFirstScanSummary,
  getScanAgainstSuspendedData,
  getSuspendSnSummary,
  getTopScanCities,
  getTopScanProduct,
  getUniqueConsumerScannedSummary,
  getNewConsumerRegisteredList,
  getSerialNumberFirstScanList,
  getUniqueConsumerScannedList,
} from "../action/dashboard.action";

const initialState = {
  mapScans: [],
  consumers: [],
  selectedId: [],
  isSelectAll: false,
  mapClusterScans: {
    scans: [],
    products: [],
  },
  isLoadingClusterScanError: false,
  clusterScanProducts: {
    list: [],
    totalFiltered: 0,
    totalRecords: 0,
    hasNextPage: true,
    isError: false,
    filter: {
      length: 25,
      page: 0,
      search: "",
    },
  },
  clusterDetail: {
    consumerScannedSummary: 0,
    uniqueConsumerScannedSummary: 0,
    firstScanSummary: 0,
    suspendedSnSummary: 0,
    counterfeitReportSummary: 0,
  },
  scanDetail: null,
  isLoadingScanDetailError: false,
  scanRecords: {
    list: [],
    totalFiltered: 0,
    totalRecords: 0,
    isError: false,
  },
  consumerDropdown: {
    list: [],
    totalFiltered: 0,
    totalRecords: 0,
    hasNextPage: true,
    isError: false,
  },
  summaryCard: {
    consumerScanned: { data: null, isError: false },
    suspendedSN: { data: null, isError: false },
    counterfeitReports: { data: null, isError: false },
    firstScan: { data: null, isError: false },
    uniqueConsumerScanned: { data: null, isError: false },
    newConsumer: { data: null, isError: false },
  },
  scanAgainstSuspendedData: {},
  topScanCities: {
    data: [],
    isError: false,
  },
  topScanProducts: {
    data: [],
    isError: false,
  },
  uniqueConsumerScannedList: {
    records: [],
    totalRecords: 0,
    hasNextPage: true,
  },
  newConsumerRegisteredList: {
    records: [],
    totalRecords: 0,
    hasNextPage: true,
  },
  firstScanSerialNumberList: {
    records: [],
    totalRecords: 0,
    hasNextPage: true,
  },
};

const dashboardSlice = createSlice({
  name: DASHBOARD,
  initialState,
  reducers: {
    resetScanDetail(state) {
      state.scanDetail = initialState.scanDetail;
      state.isLoadingScanDetailError = false;
    },
    resetClusterScanProducts(state) {
      state.clusterScanProducts = initialState.clusterScanProducts;
    },
    resetScanRecords(state) {
      state.scanRecords = initialState.scanRecords;
    },
    resetConsumerDropdown(state) {
      state.consumerDropdown = initialState.consumerDropdown;
    },
    resetClusterSummary(state) {
      state.clusterDetail = initialState.clusterDetail;
    },
    resetDashboard(state) {
      state.summaryCard = initialState.summaryCard;
      state.scanAgainstSuspendedData = initialState.scanAgainstSuspendedData;
      state.topScanCities = initialState.topScanCities;
      state.topScanProducts = initialState.topScanProducts;
    },
    updateClsuterProductFilter(state, action) {
      state.clusterScanProducts.filter = action.payload;
    },
    changeSelectedConsumer(state, action) {
      const { payload } = action;

      state.consumerDropdown.list[payload.index].isSelected = payload.isSelected;
      if (
        (!state.isSelectAll && payload.isSelected) ||
        (state.isSelectAll && !payload.isSelected)
      ) {
        state.selectedId = [
          ...state.selectedId,
          state.consumerDropdown.list[payload.index].uuid,
        ];
      } else {
        state.selectedId = state.selectedId.filter(
          (id) => id !== state.consumerDropdown.list[payload.index].uuid
        );
      }
    },
    changeBulkSelectedConsumer(state, action) {
      const { payload } = action;

      state.consumerDropdown.list = state.consumerDropdown.list.map(consumer => ({
        ...consumer,
        isSelected: payload.value,
      }));
      state.isSelectAll = payload.value;
      state.selectedId = [];
    },
  },
  extraReducers: {
    [fetchScanMap.fulfilled]: (state, action) => {
      const { payload } = action;
      const tempArray = [];
      payload.scans.forEach((item) => {
        tempArray.push({
          scanId: item.scan_id,
          lng: parseFloat(item.lng),
          lat: parseFloat(item.lat),
          status: item.sts,
          report: item.report,
        });
      });
      state.mapScans = tempArray;
    },
    [fetchClusterScanMap.pending]: (state) => {
      state.isLoadingClusterScanError = false;
      state.mapClusterScans = initialState.mapClusterScans;
    },
    [fetchClusterScanMap.fulfilled]: (state, action) => {
      const { payload } = action;
      const tempArray = [];
      payload.scans.forEach((item) => {
        tempArray.push({
          scanId: item.scan_id,
          lng: parseFloat(item.lng),
          lat: parseFloat(item.lat),
          status: item.sts,
          report: item.report,
          productUuid: item.prd,
        });
      });
      state.mapClusterScans.scans = tempArray;
      state.mapClusterScans.products = payload.products;
    },
    [fetchClusterScanMap.rejected]: (state) => {
      state.isLoadingClusterScanError = true;
    },
    [fetchClusterScanProductListing.pending]: (state) => {
      state.clusterScanProducts = initialState.clusterScanProducts;
    },
    [fetchClusterScanProductListing.fulfilled]: (state, action) => {
      const { payload } = action;
      state.clusterScanProducts.list =
        state.clusterScanProducts.filter.page === 0
          ? payload.data
          : state.clusterScanProducts.list.concat(payload.data);
      state.clusterScanProducts.hasNextPage = payload.nextPage;
      state.clusterScanProducts.totalRecords = payload.recordsTotal;
      state.clusterScanProducts.totalFiltered = payload.recordsFiltered;
    },
    [fetchClusterScanProductListing.rejected]: (state) => {
      state.clusterScanProducts.isError = true;
    },
    [fetchClusterScanRecordListing.pending]: (state) => {
      state.scanRecords = initialState.scanRecords;
    },
    [fetchClusterScanRecordListing.fulfilled]: (state, action) => {
      const { payload } = action;
      state.scanRecords.list = payload.data;
      state.scanRecords.hasNextPage = payload.nextPage;
      state.scanRecords.totalRecords = payload.recordsTotal;
      state.scanRecords.totalFiltered = payload.recordsFiltered;
    },
    [fetchClusterScanRecordListing.rejected]: (state) => {
      state.scanRecords.isError = true;
    },
    [fetchClusterScanConsumerDropdown.fulfilled]: (state, action) => {
      const { payload } = action;
      if (state.consumerDropdown.hasNextPage) {
      state.consumerDropdown.list = state.consumerDropdown.list.concat(
        payload.data.map((item) => ({
          ...item,
          isSelected: false,
        }))
      ); 
      }
      state.consumerDropdown.totalRecords = payload.recordsTotal;
      state.consumerDropdown.totalFiltered = payload.recordsFiltered;
      state.consumerDropdown.hasNextPage = state.consumerDropdown.list.length < payload.recordsFiltered;

    },
    [fetchClusterScanConsumerDropdown.rejected]: (state) => {
      state.consumerDropdown.isError = true;
    },
    [fetchClusterConsumerScannedSummary.fulfilled]: (state, action) => {
      const { payload } = action;
      state.clusterDetail.consumerScannedSummary = payload.totalCount;
    },
    [fetchClusterUniqueConsumerScannedSummary.fulfilled]: (state, action) => {
      const { payload } = action;
      state.clusterDetail.uniqueConsumerScannedSummary = payload.totalCount;
    },
    [fetchClusterFirstScanSummary.fulfilled]: (state, action) => {
      const { payload } = action;
      state.clusterDetail.firstScanSummary = payload.totalCount;
    },
    [fetchClusterSuspendSnSummary.fulfilled]: (state, action) => {
      const { payload } = action;
      state.clusterDetail.suspendedSnSummary = payload.totalCount;
    },
    [fetchClusterCounterfeitReportSummary.fulfilled]: (state, action) => {
      const { payload } = action;
      state.clusterDetail.counterfeitReportSummary = payload.totalCount;
    },
    [fetchScanDetail.pending]: (state) => {
      state.scanDetail = initialState.scanDetail;
      state.isLoadingScanDetailError = false;
    },
    [fetchScanDetail.fulfilled]: (state, action) => {
      state.scanDetail = action.payload;
    },
    [fetchScanDetail.rejected]: (state) => {
      state.isLoadingScanDetailError = true;
    },
    [getConsumerScannedSummary.pending]: (state) => {
      state.summaryCard.consumerScanned =
        initialState.summaryCard.consumerScanned;
    },
    [getConsumerScannedSummary.fulfilled]: (state, action) => {
      state.summaryCard.consumerScanned.data = action.payload;
    },
    [getConsumerScannedSummary.rejected]: (state) => {
      state.summaryCard.consumerScanned.isError = true;
    },
    [getSuspendSnSummary.pending]: (state) => {
      state.summaryCard.suspendedSN = initialState.summaryCard.suspendedSN;
    },
    [getSuspendSnSummary.fulfilled]: (state, action) => {
      state.summaryCard.suspendedSN.data = action.payload;
    },
    [getSuspendSnSummary.rejected]: (state) => {
      state.summaryCard.suspendedSN.isError = true;
    },
    [getCounterfeitReportSummary.pending]: (state) => {
      state.summaryCard.counterfeitReports =
        initialState.summaryCard.counterfeitReports;
    },
    [getCounterfeitReportSummary.fulfilled]: (state, action) => {
      state.summaryCard.counterfeitReports.data = action.payload;
    },
    [getCounterfeitReportSummary.rejected]: (state) => {
      state.summaryCard.counterfeitReports.isError = true;
    },
    [getFirstScanSummary.pending]: (state) => {
      state.summaryCard.firstScan = initialState.summaryCard.firstScan;
    },
    [getFirstScanSummary.fulfilled]: (state, action) => {
      state.summaryCard.firstScan.data = action.payload;
    },
    [getFirstScanSummary.rejected]: (state) => {
      state.summaryCard.firstScan.isError = true;
    },
    [getUniqueConsumerScannedSummary.pending]: (state) => {
      state.summaryCard.uniqueConsumerScanned =
        initialState.summaryCard.uniqueConsumerScanned;
    },
    [getUniqueConsumerScannedSummary.fulfilled]: (state, action) => {
      state.summaryCard.uniqueConsumerScanned.data = action.payload;
    },
    [getUniqueConsumerScannedSummary.rejected]: (state) => {
      state.summaryCard.uniqueConsumerScanned.isError = true;
    },
    [getConsumerRegisteredSummary.pending]: (state) => {
      state.summaryCard.newConsumer = initialState.summaryCard.newConsumer;
    },
    [getConsumerRegisteredSummary.fulfilled]: (state, action) => {
      state.summaryCard.newConsumer.data = action.payload;
    },
    [getConsumerRegisteredSummary.rejected]: (state) => {
      state.summaryCard.newConsumer.isError = true;
    },
    [getScanAgainstSuspendedData.pending]: (state) => {
      state.scanAgainstSuspendedData = initialState.scanAgainstSuspendedData;
    },
    [getScanAgainstSuspendedData.fulfilled]: (state, action) => {
      state.scanAgainstSuspendedData = action.payload;
      let data = action.payload;
      data.isSuccess = true;
      data.noResult = false;

      if (data.chart) {
        let count = 0;
        data.chart.datasets.forEach((dataset) => {
          dataset.data.forEach((d) => {
            count += d;
          });
        });
        data.noResult = count === 0;
      }

      state.scanAgainstSuspendedData = data;
    },
    [getScanAgainstSuspendedData.rejected]: (state, action) => {
      state.scanAgainstSuspendedData = {
        isSuccess: false,
        noResult: true,
        datasets: [],
        labels: [],
      };
    },
    [getTopScanCities.pending]: (state) => {
      state.topScanCities = initialState.topScanCities;
    },
    [getTopScanCities.fulfilled]: (state, action) => {
      state.topScanCities.data = action.payload;
    },
    [getTopScanCities.rejected]: (state) => {
      state.topScanCities.isError = true;
    },
    [getTopScanProduct.pending]: (state) => {
      state.topScanProducts = initialState.topScanProducts;
    },
    [getTopScanProduct.fulfilled]: (state, action) => {
      state.topScanProducts.data = action.payload;
    },
    [getTopScanProduct.rejected]: (state) => {
      state.topScanProducts.isError = true;
    },
    [getSerialNumberFirstScanList.pending]: (state, action) => {
      if (action.meta.arg.page === 0)
        state.firstScanSerialNumberList = initialState.firstScanSerialNumberList;
    },
    [getSerialNumberFirstScanList.fulfilled]: (state, action) => {
      const temp = state.firstScanSerialNumberList.records;
      state.firstScanSerialNumberList = {
        ...action.payload,
        records: [...temp, ...action.payload.records],
      };
    },
    [getSerialNumberFirstScanList.rejected]: (state) => {
      state.firstScanSerialNumberList.isError = true;
    },
    [getUniqueConsumerScannedList.pending]: (state, action) => {
      if (action.meta.arg.page === 0)
        state.uniqueConsumerScannedList = initialState.uniqueConsumerScannedList;
    },
    [getUniqueConsumerScannedList.fulfilled]: (state, action) => {
      const temp = state.uniqueConsumerScannedList.records;
      state.uniqueConsumerScannedList = {
        ...action.payload,
        records: [...temp, ...action.payload.records],
      };
    },
    [getUniqueConsumerScannedList.rejected]: (state) => {
      state.uniqueConsumerScannedList.isError = true;
    },
    [getNewConsumerRegisteredList.pending]: (state, action) => {
      if (action.meta.arg.page === 0)
        state.newConsumerRegisteredList = initialState.newConsumerRegisteredList;
    },
    [getNewConsumerRegisteredList.fulfilled]: (state, action) => {
      const temp = state.newConsumerRegisteredList.records;
      state.newConsumerRegisteredList = {
        ...action.payload,
        records: [...temp, ...action.payload.records],
      };
    },
    [getNewConsumerRegisteredList.rejected]: (state) => {
      state.newConsumerRegisteredList.isError = true;
    },
  },
});

export const {
  resetScanDetail,
  resetClusterScanProducts,
  resetScanRecords,
  resetConsumerDropdown,
  resetDashboard,
  changeSelectedConsumer,
  changeBulkSelectedConsumer,
  updateClsuterProductFilter,
} = dashboardSlice.actions;
export const dashboardReducer = dashboardSlice.reducer;
