import React from "react"
import LooseItemListTableComponent from "./looseItemListTable.component"

export default function LooseItemListTableContainer({
  hasStockflowInventoryViewAccessRight,
  rows,
  isFetching,
  isError,
  handleReload,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
  isSearchResult,
  totalFiltered,
  paginationOptions,
  lang,
  isDealerPage = false,
  dealerId,
  handleViewDetail
}) {
  return (
    <LooseItemListTableComponent
      hasStockflowInventoryViewAccessRight={hasStockflowInventoryViewAccessRight}
      rows={rows}
      isFetching={isFetching}
      isError={isError}
      handleReload={handleReload}
      page={page}
      rowsPerPage={rowsPerPage}
      handleChangePage={handleChangePage}
      handleChangeRowPerPage={handleChangeRowsPerPage}
      isSearchResult={isSearchResult}
      totalFiltered={totalFiltered}
      paginationOptions={paginationOptions}
      lang={lang}
      isDealerPage={isDealerPage}
      dealerId={dealerId}
      handleViewDetail={handleViewDetail}
    />
  )
}