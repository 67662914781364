import axios from "axios";
const url = "/analytic/api/v1/qrdata/check-validation";

export function getReportValidation({
  items
}) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, {
        items
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        if (error?.response?.data) {
          reject(error.response.data);
        } else {
          reject(error);
        }
      });
  });
}

export default getReportValidation;
