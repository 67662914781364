import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export function AddIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M23,8.52H16.55A1.51,1.51,0,0,1,15,7V.57c0-.22,0-.44-.22-.44h-6c-.22,0-.44.22-.44.66v6.4A1.51,1.51,0,0,1,6.84,8.74H.44c-.44,0-.44,0-.44.44v5.74a.7.7,0,0,0,.66.66h6.4a1.51,1.51,0,0,1,1.55,1.54V23.3a.71.71,0,0,0,.66.67H15a.7.7,0,0,0,.66-.67V17.12a1.51,1.51,0,0,1,1.55-1.54h6.4a.58.58,0,0,0,.66-.66V9.18C23.62,8.74,23.4,8.52,23,8.52Z" />
    </SvgIcon>
  );
}
