import React from "react";
import { makeStyles } from "@material-ui/core";

const style = theme => ({
  root: {
    width: "100%",
    height: "100vh",
    display: "grid",
    gridTemplateColumns: "50% 50%",
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "1fr",
      display: "flex",
      justifyContent: "center"
    }
  }
});

const useStyle = makeStyles(style);

export default function TwoColumnsLayout({ children }) {
  const classes = useStyle();

  return <div className={classes.root}>{children}</div>;
}
