import React from "react";
import {
  makeStyles,
  Typography,
  Box,
  Switch,
  Divider
} from "@material-ui/core";
import { TYPES } from "./galleryForm.container";
import clsx from "clsx";
import { ModalFooter, ContentWrapper } from "../../modal";
import ImageGalleryTab from "../../tab/imageGalleryTab";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import DragPanel from "../../panel/dragPanel";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles(theme => ({
  secondaryButton: {
    color: theme.palette.common.white
  },
  carousel: {
    "& li": {
      minWidth: "40% !important"
    }
  },
  helpIcon: {
    cursor: "pointer",
    color: theme.palette.primary.main,
    fontSize: 16
  },
  field: {
    display: "grid",
    gridTemplateColumns: "110px 1fr",
    columnGap: theme.spacing(1)
  },
  uploadImageInputButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(0.25, 1),
    borderRadius: theme.spacing(0.5),
    border: "2px solid",
    borderColor: theme.palette.primary.main
  },
  dragPanelContainer: {
    height: 170
  },
  carouselContainer: {
    backgroundColor: "white",
    borderRadius: 8,
    marginTop: 15,
    padding: "15px 20px"
  },
  greyText: {
    color: theme.palette.text.secondary
  }
}));

export default function GalleryFormComponent({
  type,
  order,
  images,
  handleDeleteImage,
  handleDelete,
  handleSubmit,
  activeIndex,
  handleChangeTab,
  MAX_IMAGE_COUNT,
  handleRejected,
  isLoading,
  handleFileUpload,
  handleInsertLink,
  handleClose,
  isEnableFullscreenBtn,
  handleChangeEnableFullscreenBtn,
  lang
}) {
  const classes = useStyle();

  return (
    <Box mb={type === TYPES[2] ? 2 : 0}>
      <ContentWrapper disablePadding={true} resetPaper={true}>
        <Box className={classes.dragPanelContainer}>
          <DragPanel
            handleFile={handleFileUpload}
            multiple={false}
            isLoading={isLoading}
            handleRejected={handleRejected}
          />
        </Box>
        {order.length > 0 && (
          <Box className={classes.carouselContainer}>
            <Typography variant="body2" color="primary">{getLang(lang, "label.UPLOAD_IMAGE")}
              <span className={classes.greyText}> {order.length} {getLang(lang, "label.OF")} {MAX_IMAGE_COUNT}</span>
            </Typography>
            <Carousel
              className={clsx(order.length === 2 && classes.carousel)}
              showThumbs={false}
              showIndicators={false}
              showStatus={false}
              swipeable={true}
              centerMode={true}
              centerSlidePercentage={order.length === 2 ? 50 : 40}
              selectedItem={activeIndex}
              onChange={handleChangeTab}
              onClickItem={handleChangeTab}
            >
              {order.map((id, index) => (
                <ImageGalleryTab
                  key={id}
                  imageSrc={images[id].img}
                  selected={activeIndex === index}
                  handleDelete={() => handleDeleteImage(id)}
                  handleInsertLink={() => handleInsertLink(id)}
                />
              ))}
            </Carousel>
          </Box>
        )}
      </ContentWrapper>
      <Box
        px={2}
        py={1}
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
      >
        <Typography variant="subtitle1">{getLang(lang, "label.ENABLE_FULLSCREEN_BUTTON")}</Typography>
        <Switch
          color="primary"
          checked={isEnableFullscreenBtn}
          onChange={handleChangeEnableFullscreenBtn}
        />
      </Box>
      <Divider />
      <ModalFooter
        handleDelete={handleDelete}
        showDelete={type !== TYPES[0]}
        handleClick={handleSubmit}
        handleClose={handleClose}
      />
    </Box>
  );
}
