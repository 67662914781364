import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const style = theme => ({
  root: {
    height: "100vh",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background:
      "radial-gradient(61.81% 120% at 47.71% 50%, #F5F8FF 0%, #EFEFEF 100%)"
  }
});

const useStyle = makeStyles(style);

export default function CenteredLayout({ children }) {
  const classes = useStyle();

  return <div className={classes.root}>{children}</div>;
}
