import React from "react";
import AutoCompleteStockTransferSettingCardComponent from "./autoCompleteStockTransferSettingCard.component";

export default function AutoCompleteStockTransferSettingCardContainer({
  enableAutoCompleteTransfer,
  actionAfterAutoCompleteTransfer,
  autoReceiveTransferDays,
  lang,
  updateFormikValue,
}) {
  return (
    <AutoCompleteStockTransferSettingCardComponent
      updateFormikValue={updateFormikValue}
      enableAutoCompleteTransfer={enableAutoCompleteTransfer}
      actionAfterAutoCompleteTransfer={actionAfterAutoCompleteTransfer}
      autoReceiveTransferDays={autoReceiveTransferDays}
      lang={lang}
    />
  );
}
