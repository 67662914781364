import React from 'react'
import {
    Backdrop,
    Dialog,
    makeStyles
} from '@material-ui/core'
import { EditSurveyForm } from '../../form'

const useStyle = makeStyles(() => ({
    dialog: {
        maxWidth: "none"
    },
    paper: {
        overflowY: "unset"
    }
}))

export default function EditSurveyDialogComponent({
    isOpen,
    handleClose,
    handleSubmit,
    survey,
    isLoading
}) {
    const classes = useStyle()

    return (
        <Dialog disableEnforceFocus
            open={isOpen}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            classes={{
                paperWidthSm: classes.dialog,
                paper: classes.paper
            }}
        >
            <EditSurveyForm
                handleSubmit={handleSubmit}
                handleClose={handleClose}
                isEditMode={true}
                survey={survey}
                isLoading={isLoading}
            />
        </Dialog>
    )
}
