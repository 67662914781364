import React from "react";
import SplashComponent from "./splash.component";
import PropTypes from "prop-types";
import { LOGOUT } from "modules/public/config/public.route";
import { useSelector } from "react-redux";
export default function SplashContainer({ errorMessage, error, logout }) {
  const handleLogout = () => {
    window.location.href = LOGOUT
  };

  const handleReload = () => {
    window.location.reload();
  };

  const lang = useSelector(state => state.constant.languages)

  return (
    <SplashComponent
      error={error}
      errorMessage={errorMessage}
      handleLogout={handleLogout}
      handleReload={handleReload}
      lang={lang}
    />
  );
}

SplashContainer.propTypes = {
  errorMessage: PropTypes.string,
  error: PropTypes.bool
};

SplashContainer.defaultProps = {
  errorMessage: "Error occured",
  error: false
};