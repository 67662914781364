import React, { useState } from "react";
import EditorComponent from "./editor.component";
import PropTypes from "prop-types";
import {
  EditorState,
  convertToRaw,
  ContentState
} from "draft-js";
import DraftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { isFile } from "lib/helper";
import { useSelector } from "react-redux";

const TYPES = ["new", "edit", "readonly"];

function transformHTMLToRaw(html) {
  const blocksFromHTML = htmlToDraft(html);
  const content = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap
  );
  return EditorState.createWithContent(content);
}

function EditorContainer({
  type,
  handleResult,
  initialValues,
  handleDelete,
  handleChange
}) {
  const [texts, setTexts] = useState(
    typeof initialValues === "object" && !isFile(initialValues)
      ? initialValues
      : { value: "<p></p>", data: [] }
  );
  const lang = useSelector(state => state.constant.languages)
  const [editorState, setEditorState] = useState(
    transformHTMLToRaw(texts.value)
  );
  const [enableWriting, setEnableWriting] = useState(false);
  const [isReady, setIsReady] = useState(type !== TYPES[0]);

  const handleStartWriting = () => {
    setIsReady(true);
    setEnableWriting(true);
  };

  const handleSubmit = () => {
    handleResult({ data: texts });
  };

  const handleSave = () => {
    const html = DraftToHtml(convertToRaw(editorState.getCurrentContent()));
    const newText = { ...texts, value: html };
    setTexts(newText);
    setEnableWriting(false);
    handleChange(newText);
  };

  return (
    <EditorComponent
      type={type}
      handleSubmit={handleSubmit}
      handleChange={setEditorState}
      handleChangeText={handleChange}
      handleDelete={handleDelete}
      editorState={editorState}
      initialValues={initialValues}
      enableWriting={enableWriting}
      handleSave={handleSave}
      handleStartWriting={handleStartWriting}
      isReady={isReady}
      texts={texts}
      lang={lang}
    />
  );
}

EditorContainer.defaultProps = {
  type: TYPES[0],
  initialValues: { value: "<p></p>", data: [] }
};

EditorContainer.propTypes = {
  type: PropTypes.oneOf(TYPES).isRequired,
  handleResult: PropTypes.func,
  handleDelete: PropTypes.func,
  initialValues: PropTypes.object
};

export { TYPES, transformHTMLToRaw };

export default EditorContainer;
