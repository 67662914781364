import React from "react"
import { 
  makeStyles, 
  Box, 
  Typography, 
} from '@material-ui/core'
import moment from "moment"
import DefaultImage from "assets/img/defaultPhoto.png"
import { getLang } from "app/feature/constants"
import "moment/min/locales.min";

const useStyle = makeStyles((theme) => ({
  item: {
    position: 'relative', 
    display: 'flex', 
    listStyle: 'none'
  },
  seperator: {
    display: 'flex',
    flexDirection: 'column',
    flex: 0,
    alignItems: 'center',
  },
  sequenceIndicator: {
    boxShadow: 'none',
    backgroundColor: '#A749F0',
    color: "#ffffff",
    padding: 4,
    borderRadius: '50%',
    aspectRatio: "1 / 1",
    height: "20px",
    textAlign: "center",
    margin: "5px 0px"
  },
  connector: {
    width: 1,
    backgroundColor: "transparent",
    flexGrow: 1,
    minHeight: "25px",
    borderLeft: '2px dotted #D0D5DD'
  },
  list: {
    paddingInlineStart: "0px"
  },
  content: {
    border: "1px solid #ECEEEE",
    padding: theme.spacing(1, 2),
    borderRadius: "4px",
    margin: "10px 15px",
    flex: 1
  },
  dot: {
    color: "#D0D5DD",
    lineHeight: "13px"
  },
  profile: {
    height: 30,
    width: 30,
    minWidth: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& img": {
      height: "100%",
      minHeight: "100%",
      minWidth: "100%",
      objectFit: "cover",
      flexShrink: 0,
      borderRadius: "50%"
    }
  },
}))

export default function ActivityLogPanelComponent({
  activityLog,
  lang,
  language
}) {
  const classes = useStyle()
  
  if(language==="ZH")
  {
    moment.locale('zh-cn')
  }else if(language==="TL")
  {
    moment.locale('tl-ph')
  }else
  {
    moment.locale(language)
  }

  const getLogType = (log) => {
    let typeLabel = ""
    if (log.type === "packed to package") {
      const packCode = log.title.split('_').pop();
      typeLabel += getLang(lang, "label.PACKED_TO_PACKAGE_CODE", { package: packCode })
    } else if (log.type === "removed from package") {
      const packCode = log.title.split('_').pop();
      typeLabel += getLang(lang, "label.REMOVE_FROM_PACKAGE_CODE", { package: packCode })
    } else if(log.type === "boxing") {
      typeLabel += getLang(lang, "label.BOXING")

      if (log.title.indexOf("_") >= 0) {
        const box = log.title.split('_').pop();
        typeLabel += " - " + box
      }
    } else if (log.type === "unboxed") {
      const box = log.title.split('_').pop();
      typeLabel += getLang(lang, "label.REMOVE_FROM_BOX", { box })
    } else {
      typeLabel += getLang(lang, log.title)
    }

    return typeLabel
  }
  return (
    <Box display="flex" flexDirection="column">
      <Box mb={2}>
        <Typography>{getLang(lang, "label.TIMELINE_END")}</Typography>
      </Box>
      <ul className={classes.list}>
        {activityLog.map((log, i) => (
          <li key={i} className={classes.item}>
            <Box className={classes.seperator}>
              {i === 0 && (
                <Typography className={classes.dot} style={{ marginTop: "-13px" }}><b>●</b></Typography>
              )}
              <Box className={classes.connector} />
              <Box className={classes.sequenceIndicator} display="flex" justifyContent="center" alignItems="center">
                {activityLog.length - i}
              </Box>
              <Box className={classes.connector} />
              {i === activityLog.length - 1 && (
                <Typography className={classes.dot} style={{ marginBottom: "-13px" }}><b>●</b></Typography>
              )}
            </Box>
            <Box className={classes.content}>
              <Box display="flex" alignItems="center" mb={1}>
                <Typography variant="body1" color="textSecondary" style={{ textTransform: 'capitalize' }}>
                  {moment.utc(log.date).local().format("lll")}
                </Typography>
              </Box>
              <Box mb={1}>
                <Typography variant="body1" style={{ textTransform: 'capitalize' }}>
                  <b>{getLogType(log)}</b>
                </Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <Box mr={1} className={classes.profile}>
                  {!log.done_by?.picture ? (
                    <img src={DefaultImage} alt="default profile" />
                  ) : (
                    <img src={log.done_by.picture} alt={log.done_by?.name || "-"} />
                  )}
                </Box>
                <Typography variant="body1" style={{ textTransform: 'capitalize' }}>
                  {log.done_by?.name || "-"}
                </Typography>
              </Box>
            </Box>
          </li>
        ))}
      </ul>
      <Box mt={1}>
        <Typography>{getLang(lang, "label.TIMELINE_START")}</Typography>
      </Box>
    </Box>
  )
}