import React from "react";
import CampaignTngDisbursementTableComponent from "./campaignTngDisbursementTable.component";
import { useSelector } from "react-redux";

function CampaignTngDisbursementTableContainer({
  rows,
  isFetching,
  isError,
  handleReload,
  handleChangePage,
  handleChangeRowsPerPage,
  totalFiltered,
  paginationOptions,
  tableFilter,
  lang,
  handleExport,
  disbursementInfo,
}) {
  const language = useSelector((state) => state.profile.language);

  return (
    <CampaignTngDisbursementTableComponent
      rows={rows}
      isFetching={isFetching}
      isError={isError}
      handleReload={handleReload}
      handleChangePage={handleChangePage}
      handleChangeRowPerPage={handleChangeRowsPerPage}
      totalFiltered={totalFiltered}
      paginationOptions={paginationOptions}
      tableFilter={tableFilter}
      lang={lang}
      handleExport={handleExport}
      language={language}
      disbursementInfo={disbursementInfo}
    />
  );
}

export default CampaignTngDisbursementTableContainer;
