/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import RequestTransferAddressCreateDialogComponent from "./requestTransferAddressCreateDialog.component";
import { useSelector } from "react-redux";
import { selectLoading } from "modules/notification";
import {
  addRequestTransferAddress,
  updateRequestTransferAddress,
} from "modules/stockflow/redux";

function RequestTransferAddressCreateDialogContainer({
  isOpen,
  handleClose,
  handleSubmit,
  branch,
  selectedEditAddr,
}) {
  const lang = useSelector((state) => state.constant.languages);

  const isSubmitting = useSelector((state) =>
    selectLoading(
      state,
      selectedEditAddr
        ? updateRequestTransferAddress.typePrefix
        : addRequestTransferAddress.typePrefix
    )
  );

  return (
    <RequestTransferAddressCreateDialogComponent
      isOpen={isOpen}
      handleClose={handleClose}
      lang={lang}
      handleSubmit={handleSubmit}
      isSubmitting={isSubmitting}
      branch={branch}
      selectedEditAddr={selectedEditAddr}
    />
  );
}

export default RequestTransferAddressCreateDialogContainer;
