import axios from "axios";

const deleteProduct = ({ id }) => {
    return new Promise((resolve, reject) => {
        axios
            .delete(`/serialnumber/api/v1/product/delete/${id}`)
            .then((response) => resolve(response.data))
            .catch((error) => reject(error.response.data));
    });
};

export default deleteProduct;