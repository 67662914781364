import React from "react";
import PropTypes from "prop-types";
import {
  makeStyles,
  Box,
  Typography,
  Tabs,
  Divider,
  Button,
} from "@material-ui/core";
import CustomTab from "components/tab";
import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";
import TagPage from "../tag-page";
import ListingPage from "../listing-page";
import BatchPage from "../batch-page";
import { AddIcon } from "components/icon";
import { getLang } from "app/feature/constants";
import AssignList from "../assign-listing-page";
import AdtInfoListing from "../adt-info-listing-page";

const style = (theme) => ({
  indicator: {
    border: "0.5px solid",
    borderColor: theme.palette.primary.main,
  },
  scroll: {
    width: "min-content",
  },
});

const useStyles = makeStyles(style);

export default function MainPageComponent({
  hasSerialNumberGenerateAccessRight,
  handleChange,
  activeIndex,
  tabLabels,
  handleGoToGenerate,
  handleAddPattern,
  lang,
  handleAddAdtInfo,
  hasAdtInfoAddAccessRight
}) {
  const classes = useStyles();

  function a11yProps(index, label = "label") {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tab-${index}`,
      label: <Typography variant="button" children={label} />,
    };
  }

  function TabPanel({ children, value, index, ...other }) {
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </Typography>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  return (
    <Box>
      <Box gridArea="topbar">
        <Box
          mb={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="flex" alignItems="flex-end" fontWeight="bold">
            <Typography variant="h6" color="primary">
              {getLang(lang, "label.QR_CODE")}
            </Typography>
            <Box mx={0.5}>
              <ChevronRightRoundedIcon color="disabled" />
            </Box>
            <Box>
              <Typography variant="h6" color="primary">
                {tabLabels[activeIndex]}
              </Typography>
            </Box>
          </Box>

          {tabLabels[activeIndex] ===
            getLang(lang, "label.SERIAL_NUMBER_LIST") &&
          hasSerialNumberGenerateAccessRight ? (
            <Box display="flex" justifyContent="center" alignItems="center">
              <Button
                variant="text"
                color="primary"
                startIcon={
                  <AddIcon color="secondary" style={{ fontSize: "13px" }} />
                }
                onClick={handleGoToGenerate}
              >
                {getLang(lang, "label.SERIAL_NUMBER")}
              </Button>
            </Box>
          ) : null}

          {tabLabels[activeIndex] === getLang(lang, "label.SAVED_PATTERN") &&
          hasSerialNumberGenerateAccessRight ? (
            <Button
              variant="text"
              color="primary"
              startIcon={
                <AddIcon color="secondary" style={{ fontSize: "13px" }} />
              }
              onClick={handleAddPattern}
            >
              {getLang(lang, "label.SERIAL_NUMBER_PATTERN")}
            </Button>
          ) : null}

          {tabLabels[activeIndex] === getLang(lang, "label.ADDITIONAL_INFO") &&
          hasAdtInfoAddAccessRight ? (
            <Button
              variant="text"
              color="primary"
              startIcon={
                <AddIcon color="secondary" style={{ fontSize: "13px" }} />
              }
              onClick={handleAddAdtInfo}
            >
              {getLang(lang, "label.ADDITIONAL_INFO")}
            </Button>
          ) : null}
        </Box>
        <Divider />
      </Box>

      <Box gridArea="panel">
        <Box>
          <Box>
            <Tabs
              value={activeIndex || 0}
              onChange={handleChange}
              aria-label="scrollable auto tabs example"
              textColor="primary"
              variant="scrollable"
              indicatorColor="primary"
              classes={{
                indicator: classes.indicator,
                scrollButtons: classes.scroll,
              }}
            >
              {tabLabels.map((item, index) => {
                return <CustomTab key={index} {...a11yProps(index, item)} />;
              })}
            </Tabs>
          </Box>

          <Box>
            {activeIndex !== null &&
              tabLabels.map((item, index) => {
                if (item === getLang(lang, "label.SERIAL_NUMBER_LIST")) {
                  return (
                    <TabPanel value={activeIndex} index={index} key={index}>
                      <Box className={classes.panel}>
                        <ListingPage />
                      </Box>
                    </TabPanel>
                  );
                }
                if (item === getLang(lang, "label.SERIAL_NUMBER_BATCH")) {
                  return (
                    <TabPanel value={activeIndex} index={index} key={index}>
                      <Box className={classes.panel}>
                        <BatchPage />
                      </Box>
                    </TabPanel>
                  );
                }
                if (item === getLang(lang, "label.SAVED_PATTERN")) {
                  return (
                    <TabPanel value={activeIndex} index={index} key={index}>
                      <Box className={classes.panel}>
                        <TagPage />
                      </Box>
                    </TabPanel>
                  );
                }
                if (item === getLang(lang, "label.SERIAL_NUMBER_ASSIGN_LIST")) {
                  return (
                    <TabPanel value={activeIndex} index={index} key={index}>
                      <Box className={classes.panel}>
                        <AssignList />
                      </Box>
                    </TabPanel>
                  );
                }
                if (item === getLang(lang, "label.ADDITIONAL_INFO")) {
                  return (
                    <TabPanel value={activeIndex} index={index} key={index}>
                      <Box className={classes.panel}>
                        <AdtInfoListing />
                      </Box>
                    </TabPanel>
                  );
                }
                return null;
              })}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
