import React from "react";
import {
  makeStyles,
  Box,
  Paper,
  Typography,
  Divider,
  Button, Grid
} from "@material-ui/core";
import { WarningSVG, AddSVG } from "../../../utils/static";
import WarrantyExtensionCard from "../../card/warrantyExtensionCard";
import Skeleton from "@material-ui/lab/Skeleton";
import { getLang } from "app/feature/constants";
import moment from "moment/moment";
import clsx from "clsx";
import "moment/min/locales.min";

const style = (theme) => ({
  header: {
    padding: "4px 0px",
    paddingLeft: theme.spacing(3),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  root: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  content: {
    padding: theme.spacing(9, 4, 9, 4),
    textAlign: "center"
  },
  infoText: {
    paddingTop: theme.spacing(1),
    fontWeight: "bold"
  },
  button: {
    marginTop: theme.spacing(4)
  },
  discardButton: {
    color: theme.palette.error.main
  },
  publishButton: {
    color: theme.palette.common.white
  },
  scroll: {
    overflowY: "scroll",
    height: "100%"
  },
  container: {
    boxShadow: "0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);",
    borderRadius: 6,
    padding: theme.spacing(3, 2),
  },
  perfectScrollbar: {
    height: 400,
    marginTop: theme.spacing(2),
    padding: theme.spacing(1)
  },
  questionContainer: {
    padding: 12,
    border: "1px solid #D0D5DD",
    borderRadius: 6,
    marginBottom: 12,
    cursor: "pointer",
  },
  selected: {
    border: "1px solid #6AAF68",
    boxShadow: "0px 0px 0px 4px #E5F1E4",
    cursor: "pointer",
  },
  disableQuestionContainer: {
    opacity: 0.6,
    cursor: "not-allowed",
    pointerEvents: "none",
  }
});

const useStyles = makeStyles(style);

export default function WarrantyExtensionPanelComponent({
  hasWarrantyEditAccessRight,
  currentAddExtension,
  addExtension,
  handleClose,
  handleSave,
  isFetching,
  handleDelete,
  handleExistingDelete,
  handleDoneEdit,
  isProcessing,
  type,
  changeToUpdate,
  currentSelectedExtension,
  language,
  warrantyExtensionList,
  currentShowing,
  fetchMoreData,
  lang,
  onExtensionItemClick
}) {
  const classes = useStyles();
  
  
  if(language==="ZH")
  {
    moment.locale('zh-cn')
  }else if(language==="TL")
  {
    moment.locale('tl-ph')
  }else
  {
    moment.locale(language)
  }
  

  return (
    <Paper elevation={0}>
      <Box className={classes.root}>
        {isFetching ? (
          <Box style={{ display: "flex", justifyContent: "center" }}>
            <Box width={"90%"}>
              <Skeleton animation="wave" height={60} />
              <Skeleton animation="wave" height={60} />
              <Skeleton animation="wave" height={60} />
            </Box>
          </Box>
        ) : (
          <Box
            style={{
              height: "56vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between"
            }}
          >
            <Grid container style={{ height: "100%" }}>
              {warrantyExtensionList.length === 0 && currentAddExtension === null && type==="view" ? (
                <Box className={classes.content} display="flex" justifyContent="center" alignContent="center" flexDirection="column" width="100%">
                  <Box>
                    <WarningSVG />
                  </Box>

                  <Typography
                    variant="body1"
                    color="primary"
                    className={classes.infoText}
                  >
                    {getLang(lang, 'paragraph.NO_EXTENSION_ADDED')}
                  </Typography>

                  {
                    hasWarrantyEditAccessRight
                      ?
                      <Box>
                        <Button
                          variant="contained"
                          color="primary"
                          disableElevation
                          className={classes.button}
                          onClick={addExtension}
                        >
                          <Box pr={0.5} display="flex">
                            <AddSVG />
                          </Box>
                          {getLang(lang, 'label.ADD_EXTENSION')}
                        </Button>
                      </Box>
                      : null
                  }
                </Box>
              ) : warrantyExtensionList.length === 0 && currentAddExtension === null ? (
                <Box width="100%">
                  <WarrantyExtensionCard
                    type="add"
                    index={warrantyExtensionList.length}
                    handleSave={handleSave}
                    handleDelete={handleDelete}
                  />
                </Box>
              ) : (
                <>
                  <Grid item xs={4} borderRight={1} className={classes.scroll}>
                    <Box p={2} pb={0}>
                      <Typography component="div">
                        <Box fontWeight="fontWeightBold">Extension</Box>
                      </Typography>
                    </Box>
                    <Box>
                      {(currentAddExtension !== null ||
                        warrantyExtensionList.length > 0) && hasWarrantyEditAccessRight ? (
                        <Box p={2} pb={0}>
                          <Button
                            variant="contained"
                            color="primary"
                            disableElevation
                            disabled={currentAddExtension != null || type === 'edit' || type === 'add'}
                            onClick={addExtension}
                          >
                            <Box pr={0.5} display="flex">
                              <AddSVG />
                            </Box>
                            {getLang(lang, 'label.ADD_EXTENSION')}
                          </Button>
                        </Box>
                      ) : null}
                      {warrantyExtensionList.length === 0 && currentAddExtension === null ? (
                        <Box className={classes.content}>
                          <WarningSVG />

                          <Typography
                            variant="body1"
                            color="primary"
                            className={classes.infoText}
                          >
                            {getLang(lang, 'paragraph.NO_EXTENSION_ADDED')}
                          </Typography>

                          {
                            hasWarrantyEditAccessRight
                              ?
                              <Button
                                variant="contained"
                                color="primary"
                                disableElevation
                                className={classes.button}
                                onClick={addExtension}
                              >
                                <Box pr={0.5} display="flex">
                                  <AddSVG />
                                </Box>
                                {getLang(lang, 'label.ADD_EXTENSION')}
                              </Button>
                              : null
                          }
                        </Box>
                      ) : !isProcessing ?
                        warrantyExtensionList
                          .map((field, index) => {
                            return (
                              <Box
                                className={clsx({
                                  [classes.questionContainer]: true,
                                  [classes.selected]: currentSelectedExtension === index,
                                  [classes.disableQuestionContainer]: type === 'add' || (type === 'edit' && currentSelectedExtension !== index),
                                })}
                                onClick={() => { onExtensionItemClick(index) }}
                                p={1}
                                m={2}
                              >
                                <Typography component="div">
                                  <Box fontWeight="fontWeightBold">
                                    Extension #{index + 1} ({field.extensionPeriod} months)
                                  </Box>
                                  <Box fontWeight="fontWeightLight">
                                    Effective Period
                                  </Box>
                                  <Box fontWeight="fontWeightLight">
                                    {moment(field.effectiveDateStart).format("lll")}-{moment(field.effectiveDateEnd).format("lll")}
                                  </Box>
                                </Typography>
                              </Box>
                            );
                          }
                          ) : null}
                    </Box>
                  </Grid>
                  <Grid item xs={8} className={classes.scroll}>
                    <Box>
                      <WarrantyExtensionCard
                        type={type}
                        key={type === 'view' ? currentSelectedExtension : null}
                        index={type === 'add' ? warrantyExtensionList.length : currentSelectedExtension}
                        handleSave={handleSave}
                        handleDelete={handleDelete}
                        changeToUpdate={changeToUpdate}
                        info={type !== 'add' ? warrantyExtensionList[currentSelectedExtension] : null}
                      />
                      {/* {currentAddExtension !== null ? (
                        <WarrantyExtensionCard
                          type="add"
                          index={warrantyExtensionList.length}
                          handleSave={handleSave}
                          handleDelete={handleDelete}
                        />
                      ) : (
                        <WarrantyExtensionCard
                          type="view"
                          key={currentSelected}
                          index={currentSelected}
                          handleSave={handleSave}
                          handleDelete={handleDelete}
                          info={warrantyExtensionList[currentSelected]}
                        />
                      )} */}
                    </Box>
                  </Grid>
                </>
              )}

            </Grid>

            {type === "create" ? (
              <Box mt="auto" width="100%">
                <Divider />
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  px={2}
                  pt={1}
                  pb={1}
                >
                  <Box display="flex">
                    {currentAddExtension === null ? (
                      <Button
                        variant="contained"
                        color="secondary"
                        classes={{ root: classes.publishButton }}
                        disableElevation
                        type="button"
                        onClick={handleClose}
                      >
                        {getLang(lang, 'label.DONE')}
                      </Button>
                    ) : null}
                  </Box>
                </Box>
              </Box>
            ) : null}
          </Box>
        )}
      </Box>
    </Paper>
  );
}
