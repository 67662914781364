import { createAsyncThunk } from '@reduxjs/toolkit';
import stockflowApi from 'app/api/stockflow';

export const STOCKFLOWRECALL = "stockflowRecall";

export const getRecallList = createAsyncThunk(
  `${STOCKFLOWRECALL}/getStockoutList`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi.getRecallList(payload)
      .then(response => response.data)
      .catch(error => rejectWithValue(error))
  }
)

export const getRecallDetail = createAsyncThunk(
  `${STOCKFLOWRECALL}/getRecallDetail`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi.getRecallDetail(payload)
      .then(response => response.data)
      .catch(error => rejectWithValue(error))
  }
)

export const getRecallRequestorDropdown = createAsyncThunk(
  `${STOCKFLOWRECALL}/getRecallRequestorDropdown`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi.getRecallRequestorDropdown(payload)
      .then(response => response.data)
      .catch(error => rejectWithValue(error))
  }
)

export const getRecallDealerDropdown = createAsyncThunk(
  `${STOCKFLOWRECALL}/getRecallDealerDropdown`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi.getRecallDealerDropdown(payload)
      .then(response => response.data)
      .catch(error => rejectWithValue(error))
  }
)

export const getRecallItemList = createAsyncThunk(
  `${STOCKFLOWRECALL}/getRecallItemList`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi.getRecallItemList(payload)
      .then(response => response.data)
      .catch(error => rejectWithValue(error))
  }
)