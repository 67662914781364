import React from "react";
import BillingOverviewPanelComponent from "./billingOverviewPanel.component";
import { useSelector } from "react-redux";

export default function BillingOverviewPanelContainer() {
  const isOwner = useSelector(state => state.profile.roleId === null ? true : false)
  const registrationType = useSelector(state => state.profile.registrationType)

  return <BillingOverviewPanelComponent
    isOwner={isOwner}
    registrationType={registrationType}
  />;
}
