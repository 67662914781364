import React from 'react'
import { selectBranchName } from 'modules/product-mgmt/redux/selector/productPage.selector'
import { useSelector } from 'react-redux'
import ComponentMenuPanelComponent from './componentMenuPanel.component'

export default function ComponentMenuPanelContainer() {
    const productInfo = useSelector(state => state.productPage)
    const branch = useSelector(state => selectBranchName(state))
    const lang = useSelector(state => state.constant.languages);


    return (
        <ComponentMenuPanelComponent
            productInfo={productInfo}
            branch={branch}
            lang={lang}
        />
    )
}
