import React from "react";
import {
  makeStyles,
  Box,
  Button,
  Typography,
} from "@material-ui/core";
import CurrencyConfigListTable from '../../table/currencyConfigListTable';
import ActionSelect from "../../select/actionSelect";
import { AddIcon } from "components/icon";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles((theme) => ({
  settingPanel: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 3, 2, 3),
    borderRadius: "4px",
  },
  activeColor: {
    color: "#4BCB0F"
  },
  deactiveColor: {
    color: "#9CA6A9"
  }
}));


export default function CurrencyConfigSettingPanelComponent({
  data,
  isFetching,
  isLoadingCurrencyConfigListError,
  isSelectAll,
  isSelected,
  selectedCount,
  search,
  handleSearch,
  handleReload,
  page,
  rowsPerPage,
  paginationOptions,
  totalRecords,
  handleChangePage,
  handleChangeRowsPerPage,
  handleCurrencyConfigStatus,
  handleUpdateCurrencyConfig,
  name,
  isoCode,
  status,
  updateStatus,
  defaultCurrency,
  handleSetDefaultCurrency,
  handleDeleteCurrencyConfig,
  handleAddCurrencyConfig,
  handleSelectCurrencyConfig,
  handleSelectAvailable,
  handleSelectAll,
  handleDeselectAll,
  handleChangeCurrencyConfigStatus,
  handleCreateCurrencyConfig,
  lang,
  hasCompanyCurrencyEditAccessRight
}) {
  const classes = useStyle();
  return (
    <>
      <Box className={classes.settingPanel}>
        <Box mb={1} display="flex" justifyContent="space-between">
          <Box display="flex" alignItems="flex-end" fontWeight="bold">
            <Typography variant='h6' color='primary'>
              {getLang(lang, "label.CURRENCY_SETTING")}
            </Typography>
          </Box>
          {!!hasCompanyCurrencyEditAccessRight && (
            <Button
              variant='text'
              color='primary'
              startIcon={
                <AddIcon color="secondary" style={{ fontSize: "13px" }} />
              }
              style={{ textTransform: "uppercase" }}
              onClick={() => handleAddCurrencyConfig(true)}
            >
              {getLang(lang, "label.CURRENCY")}
            </Button>
          )}
        </Box>
        <Box style={{ display: "flex", paddingBottom: "12px", justifyContent: "space-between" }}>
          <Box style={{ display: "flex", alignItems: "center" }}>
            {selectedCount > 0 && (<Typography variant='body2' color='primary'>{selectedCount > 1 ? getLang(lang, "paragraph.SELECT_CURRENCIES_COUNT", { count: selectedCount })
              : getLang(lang, "paragraph.SELECT_CURRENCY_COUNT", { count: selectedCount })}</Typography>)}
          </Box>
          <Box style={{ display: "flex" }}>
            <ActionSelect
              label={getLang(lang, "label.ACTION")}
              dropdownItem={[getLang(lang, "label.ENABLE_SELECTED"), getLang(lang, "label.DISABLE_SELECTED")]}
              handleChange={(value) => handleChangeCurrencyConfigStatus(value)}
              disabled={!isSelected || !hasCompanyCurrencyEditAccessRight}
              isFetching={isFetching}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              style={{
                width: "120px",
                borderRadius: "8px",
                backgroundColor: "#ffffff",
                border: '1px solid #D0D5DD',
              }}
            />
          </Box>
        </Box>
        <Box>
          <CurrencyConfigListTable
            isSelectAll={isSelectAll}
            data={data}
            isFetching={isFetching}
            isLoadingCurrencyConfigListError={isLoadingCurrencyConfigListError}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleReload={handleReload}
            page={page}
            rowsPerPage={rowsPerPage}
            paginationOptions={paginationOptions}
            search={search}
            totalRecords={totalRecords}
            handleCurrencyConfigStatus={handleCurrencyConfigStatus}
            handleUpdateCurrencyConfig={handleUpdateCurrencyConfig}
            name={name}
            isoCode={isoCode}
            status={status}
            updateStatus={updateStatus}
            defaultCurrency={defaultCurrency}
            handleSetDefaultCurrency={handleSetDefaultCurrency}
            handleDeleteCurrencyConfig={handleDeleteCurrencyConfig}
            handleSelectCurrencyConfig={handleSelectCurrencyConfig}
            handleSelectAvailable={handleSelectAvailable}
            handleSelectAll={handleSelectAll}
            handleDeselectAll={handleDeselectAll}
            handleSearch={handleSearch}
            handleCreateCurrencyConfig={handleCreateCurrencyConfig}
            lang={lang}
            hasCompanyCurrencyEditAccessRight={hasCompanyCurrencyEditAccessRight}
          />
        </Box>
      </Box>
    </>
  )
}