import React from "react"
import DealerInvitedListTableComponent from "./dealerInvitedListTable.component"

export default function DealerInvitedListTableContainer({
  hasStockflowDealerViewAccessRight,
  hasBranchViewAccessRight,
  rows,
  isFetching,
  isError,
  handleReload,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
  totalFiltered,
  paginationOptions,
  tableFilter,
  changeOrder,
  lang,
  handleViewDetail
}) {
  return (
    <DealerInvitedListTableComponent 
      hasStockflowDealerViewAccessRight={hasStockflowDealerViewAccessRight}
      hasBranchViewAccessRight={hasBranchViewAccessRight}
      rows={rows}
      isFetching={isFetching}
      isError={isError}
      handleReload={handleReload}
      page={page}
      rowsPerPage={rowsPerPage}
      handleChangePage={handleChangePage}
      handleChangeRowPerPage={handleChangeRowsPerPage}
      totalFiltered={totalFiltered}
      paginationOptions={paginationOptions}
      tableFilter={tableFilter}
      changeOrder={changeOrder}
      lang={lang}
      handleViewDetail={handleViewDetail}
    />
  )
}