import { selectLoading } from "modules/notification";
import { getCampaignEngagedTrending, getCampaignTypeLuckyDrawDatalist, getTotalScanTrending } from "modules/reporting/redux";
import moment from "moment";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CampaignLuckyDrawPanel from './campaignLuckyDraw.component';

export default function CampaignLuckyDrawPanelContainer({
    currentFilter,
    urlTotalScan,
    urlCampaignEngaged,
    urlLuckyDrawDatalist,
    pageType,
    translate
}){
    const dispatchTrending = useDispatch();
    const dispatchTable = useDispatch();

    // (1) trending data -- start
    // (a) get trending: total scan qr data
    const isFetchingTotalScanTrending = useSelector(state => selectLoading(state, getTotalScanTrending.typePrefix));
    const totalScanTrendingData = useSelector(state => state.reporting.trending.totalScanData);

    // (b) get trending: campaign engaged data
    const isFetchingCampaignEngagedTrending = useSelector(state => selectLoading(state, getCampaignEngagedTrending.typePrefix));
    const campaignEngagedTrendingData = useSelector(state => state.reporting.trending.campaignEngagedData);
    // (1) trending data -- end
    
    // (2) get campaign performance -- start
    const isFetchingCampaignLuckyDrawDatalist = useSelector(state => selectLoading(state, getCampaignTypeLuckyDrawDatalist.typePrefix));
    const campaignLuckyDrawDatalist = useSelector(state => state.reporting.campaign.campaignTypeLuckyDrawDatalist);
    // (2) get campaign performance -- end

    const [tableFilter, setTableFilter] = useState({
        page: 0,
        rowsPerPage: 25,
        search: "",
        searchBy: "name",
        orderBy: "engagement",
        orderDirection: "desc",
    })

    useEffect(() => {
        dispatchTrending(getTotalScanTrending({
            countryCode: currentFilter.countryCode,
            city_uuid: currentFilter.cityUuid,
            branch_uuid: currentFilter.branchUuid,
            period: currentFilter.period,
            start_date: moment(currentFilter.dateRange[0]).format('YYYY-MM-DD'),
            end_date: moment(currentFilter.dateRange[1]).format('YYYY-MM-DD'),
            url: urlTotalScan
        }));

        dispatchTrending(getCampaignEngagedTrending({
            countryCode: currentFilter.countryCode,
            city_uuid: currentFilter.cityUuid,
            branch_uuid: currentFilter.branchUuid,
            period: currentFilter.period,
            start_date: moment(currentFilter.dateRange[0]).format('YYYY-MM-DD'),
            end_date: moment(currentFilter.dateRange[1]).format('YYYY-MM-DD'),
            url: urlCampaignEngaged,
            campaign_type: currentFilter.campaignType
        }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatchTrending]);

    useEffect(() => {
        dispatchTable(getCampaignTypeLuckyDrawDatalist({
            countryCode: currentFilter.countryCode,
            city_uuid: currentFilter.cityUuid,
            branch_uuid: currentFilter.branchUuid,
            period: currentFilter.period,
            start_date: moment(currentFilter.dateRange[0]).format('YYYY-MM-DD'),
            end_date: moment(currentFilter.dateRange[1]).format('YYYY-MM-DD'),
            url: urlLuckyDrawDatalist,
            campaign_type: currentFilter.campaignType,
        }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatchTable, currentFilter]);

    const columns = [
        {
            title: translate("label.NUM"),
            field: null,
            render : (row) => {
                return (
                    <>{row.title}</>
                )
            },
        },
        {
            title:  translate("label.CONTENT"),
            field: null,
            render : (row) => {
                return (
                    <>{row.title}</>
                )
            },
            size: 'medium'

        },
        {
            title: translate("label.TOTAL_ENGAGEMENT"),
            field: 'engagement',
            render : (row) => {
                return (
                    <>{row.title}</>
                )
            },
            size: 'medium'
        }
    ];

    const handleChangePage = (e, newPage) => {
        setTableFilter((prev) => ({
          ...prev,
          page: newPage,
        }))
    }

    const handleChangeRowPerPage = (event) => {
        setTableFilter((prev) => ({
          ...prev,
          page: 0,
          rowsPerPage: event.target.value
        }))
    }

    const handleRequestSort = (event, property) => {
        const isAsc = tableFilter.orderBy === property && tableFilter.orderDirection === 'asc';
        setTableFilter((prev) => ({
            ...prev,
            orderDirection: isAsc ? 'desc' : 'asc',
            orderBy: property,
        }))
    }

    return (
        <CampaignLuckyDrawPanel 
            status= {{
                isFetchingTotalScanTrending,
                isFetchingCampaignEngagedTrending,
                isFetchingCampaignLuckyDrawDatalist
            }}

            data = {{
                totalScanTrendingData,
                campaignEngagedTrendingData,
                campaignLuckyDrawDatalist
            }}

            sort={{
                valueToOrderBy: tableFilter.orderBy,
                orderDirection: tableFilter.orderDirection,
                handleRequestSort: handleRequestSort,
            }}

            pagination={{
                handleChangePage: handleChangePage,
                handleChangeRowPerPage: handleChangeRowPerPage,
                page: tableFilter.page,
                rowsPerPage: tableFilter.rowsPerPage,
                rowsPerPageOptions: [5, 10, 25],
            }}

            currentFilter={currentFilter}
            pageType={pageType}
            columns={columns}

            translate={translate}
        />
    )
}