import { createSlice } from "@reduxjs/toolkit"
import { 
  getAcceptedOrderList, 
  getCancelledOrderList, 
  getNewOrderList, 
  getOrderDetail, 
  getOrderReceiverDropdown, 
  getOrderRequestorDropdown, 
  getRejectedOrderList, 
  STOCKFLOWORDER 
} from "../action/stockflowOrder.action"

const initialState = {
  newOrderList: {
    list: [],
    totalFiltered: 0,
    totalRecords: 0,
  },
  acceptedOrderList: {
    list: [],
    totalFiltered: 0,
    totalRecords: 0,
  },
  rejectedOrderList: {
    list: [],
    totalFiltered: 0,
    totalRecords: 0,
  },
  cancelledOrderList: {
    list: [],
    totalFiltered: 0,
    totalRecords: 0,
  },
  isLoadingOrderListError: false,
  selectedIds: [],
  isSelectAll: false,
  order: null,
  isLoadingOrderError: false,
  orderRequestorDropdown: [],
  orderReceiverDropdown: [],
}

const stockflowOrderSlice = createSlice({
  name: STOCKFLOWORDER,
  initialState,
  reducers: {
    changeSelectedOrder(state, action) {
      const { payload } = action

      state[`${payload.type}OrderList`].list[payload.index].isSelected = payload.isSelected
      let orderId = state[`${payload.type}OrderList`].list[payload.index]
      if ((!state.isSelectAll && payload.isSelected) || ((state.isSelectAll && !payload.isSelected))) {
        state.selectedIds = [...state.selectedIds, orderId]
      }
      else {
        state.selectedIds = state.selectedIds.filter((id) => id !== orderId)
      }
    },
    changeBulkSelectedOrder(state, action) {
      const { payload } = action

      state[`${payload.type}OrderList`].list.forEach((order) => {
        order.isSelected = payload.value
      })
      state.isSelectAll = payload.value
      state.selectedIds = []
    },
    changeBulkSelectedAvailableOrder(state, action) {
      const { payload } = action
      state[`${payload.type}OrderList`].list.forEach((order) => {
        order.isSelected = payload.value
        if (((!state.isSelectAll && payload.value) || (state.isSelectAll && !payload.value))) {
          if (state.selectedIds.findIndex((id) => id === order.id) === -1) {
            state.selectedIds = [...state.selectedIds, order.id]
          }
        }
        else {
          state.selectedIds = state.selectedIds.filter((id) => id !== order.id)
        }
      })
    },
    resetOrderListing(state) {
      state.newOrderList = initialState.newOrderList
      state.acceptedOrderList = initialState.acceptedOrderList
      state.rejectedOrderList = initialState.rejectedOrderList
      state.cancelledOrderList = initialState.cancelledOrderList
      state.selectedIds = initialState.selectedIds
      state.isSelectAll = initialState.isSelectAll
    },
    resetOrder(state) {
      state.order = initialState.order
    },
  },
  extraReducers: {
    [getNewOrderList.pending]: (state) => {
      state.newOrderList = initialState.newOrderList
      state.isLoadingOrderListError = false
    },
    [getNewOrderList.fulfilled]: (state, action) => {
      const { payload } = action
      const temp = []
      payload.data.forEach((item) => {
        let selectedState = !state.isSelectAll ? state.selectedIds.findIndex((id) => id === item.id) !== -1 : state.selectedIds.findIndex((id) => id === item.id) === -1
        temp.push({
          ...item,
          isSelected: selectedState
        })
        return null
      })
      state.newOrderList.list = temp
      state.newOrderList.totalRecords = payload.recordsTotal
      state.newOrderList.totalFiltered = payload.recordsFiltered
    },
    [getNewOrderList.rejected]: (state) => {
      state.isLoadingOrderListError = true
    },
    [getAcceptedOrderList.pending]: (state) => {
      state.acceptedOrderList = initialState.acceptedOrderList
      state.isLoadingOrderListError = false
    },
    [getAcceptedOrderList.fulfilled]: (state, action) => {
      const { payload } = action
      const temp = []
      payload.data.forEach((item) => {
        let selectedState = !state.isSelectAll ? state.selectedIds.findIndex((id) => id === item.id) !== -1 : state.selectedIds.findIndex((id) => id === item.id) === -1
        temp.push({
          ...item,
          isSelected: selectedState
        })
        return null
      })
      state.acceptedOrderList.list = temp
      state.acceptedOrderList.totalRecords = payload.recordsTotal
      state.acceptedOrderList.totalFiltered = payload.recordsFiltered
    },
    [getAcceptedOrderList.rejected]: (state) => {
      state.isLoadingOrderListError = true
    },
    [getRejectedOrderList.pending]: (state) => {
      state.rejectedOrderList = initialState.rejectedOrderList
      state.isLoadingOrderListError = false
    },
    [getRejectedOrderList.fulfilled]: (state, action) => {
      const { payload } = action
      const temp = []
      payload.data.forEach((item) => {
        let selectedState = !state.isSelectAll ? state.selectedIds.findIndex((id) => id === item.id) !== -1 : state.selectedIds.findIndex((id) => id === item.id) === -1
        temp.push({
          ...item,
          isSelected: selectedState
        })
        return null
      })
      state.rejectedOrderList.list = temp
      state.rejectedOrderList.totalRecords = payload.recordsTotal
      state.rejectedOrderList.totalFiltered = payload.recordsFiltered
    },
    [getRejectedOrderList.rejected]: (state) => {
      state.isLoadingOrderListError = true
    },
    [getCancelledOrderList.pending]: (state) => {
      state.cancelledOrderList = initialState.cancelledOrderList
      state.isLoadingOrderListError = false
    },
    [getCancelledOrderList.fulfilled]: (state, action) => {
      const { payload } = action
      const temp = []
      payload.data.forEach((item) => {
        let selectedState = !state.isSelectAll ? state.selectedIds.findIndex((id) => id === item.id) !== -1 : state.selectedIds.findIndex((id) => id === item.id) === -1
        temp.push({
          ...item,
          isSelected: selectedState
        })
        return null
      })
      state.cancelledOrderList.list = temp
      state.cancelledOrderList.totalRecords = payload.recordsTotal
      state.cancelledOrderList.totalFiltered = payload.recordsFiltered
    },
    [getCancelledOrderList.rejected]: (state) => {
      state.isLoadingOrderListError = true
    },
    [getOrderDetail.pending]: (state) => {
      state.order = initialState.order
      state.isLoadingOrderError = false
    },
    [getOrderDetail.fulfilled]: (state, action) => {
      state.order = action.payload
    },
    [getOrderDetail.rejected]: (state) => {
      state.isLoadingOrderError = true
    },
    [getOrderRequestorDropdown.pending]: (state) => {
      state.orderRequestorDropdown = initialState.orderRequestorDropdown
    },
    [getOrderRequestorDropdown.fulfilled]: (state, action) => {
      state.orderRequestorDropdown = action.payload
    },
    [getOrderReceiverDropdown.pending]: (state) => {
      state.orderReceiverDropdown = initialState.orderReceiverDropdown
    },
    [getOrderReceiverDropdown.fulfilled]: (state, action) => {
      state.orderReceiverDropdown = action.payload
    },
  }
})

export const {
  changeSelectedOrder,
  changeBulkSelectedOrder,
  changeBulkSelectedAvailableOrder,
  resetOrderListing,
} = stockflowOrderSlice.actions

export const stockflowOrderReducer = stockflowOrderSlice.reducer