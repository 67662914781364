import React from 'react'
import { useSelector } from 'react-redux'
import ProductPagePreviewDialogComponent from './productPagePreviewDialog.component'

export default function ProductPagePreviewDialogContainer({
    isOpen,
    handleClose
}) {
    const lang = useSelector(state => state.constant.languages)
    return (
        <ProductPagePreviewDialogComponent
            isOpen={isOpen}
            handleClose={handleClose}
            lang={lang}
        />
    )
}
