import React, { useEffect } from "react";
import WarrantySettingFormComponent from "./warrantySettingForm.component";
import { connect, useDispatch, useSelector } from "react-redux";
import { addAlert } from "modules/notification";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  setWarrantySetting,
  updateWarrantySettingDetail,
  fetchJSONURL,
  fetchWarrantySettingDetail,
  setWarrantySettingClaimReason,
} from "../../../redux";
import { uploadFileToS3, uploadJSONToS3 } from "app/feature";
import { isFile, isFileExceedLimit, isPDF } from "lib/helper";
import { getLang } from "app/feature/constants";
import { useState } from "react";
import { UploadType } from "lib/constants/aws_s3";
import { hideBackdrop, showBackdrop } from "../../../../backdrop";
import languages from "../../../../../lib/constants/languages";
import { WARRANTY_PRE_REGISTER_EDIT, WARRANTY_VALIDATE_DO_EDIT } from "lib/constants/accessRights";

function WarrantySettingFormContainer({
  earlyAccess,
  type,
  children,
  handleNext,
  setWarrantySetting,
  changeBackView,
  file,
  setFile,
  addAlert,
  updateWarrantySettingDetail
}) {
  const dispatch = useDispatch();
  const lang = useSelector(state => state.constant.languages);
  const id = useSelector((state) => state.session.idTokenPayload.sub);
  const warrantySettingForm = useSelector((state) => state.warranty.warrantySetting);
  const hasWarrantyPreRegisterEditRight = useSelector((state) =>
    state.profile.accessRights.includes(WARRANTY_PRE_REGISTER_EDIT)
  );
  const hasWarrantyValidateDoNumEditRight = useSelector((state) =>
    state.profile.accessRights.includes(WARRANTY_VALIDATE_DO_EDIT)
  );
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [languageTabIndex, setLanguageTabIndex] = useState(0)
  const [usedLang, setUsedLang] = useState(warrantySettingForm.claimReasons.length === 0
    ? [languages.find((lang) => lang.code === 'EN')]
    : Object.keys(warrantySettingForm.claimReasons[0].name).map((key) => languages.find(lang => lang.code === key))
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const [emptyErrorOnLang, setEmptyErrorOnLang] = useState([]);
  const [duplicateErrorOnLang, setDuplicateErrorOnLang] = useState([]);
  const [tncEdit, setTncEdit] = useState(false);

  useEffect(() => {
    if (type === 'create') {
      dispatch(setWarrantySettingClaimReason([
        { name: { EN: 'Faulty Product' }, command: ['add'], isEnabled: 1 },
        { name: { EN: 'Accidental Damage' }, command: ['add'], isEnabled: 1 },
        { name: { EN: 'Others' }, command: ['add'], isEnabled: 1 },
      ]));
    }
    // eslint-disable-next-line
  }, [])

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }

  const handleTncPdfChangePage = (isAddPage) => {
    setPageNumber(pageNumber + (isAddPage ? 1 : -1))
  }

  const setTncEdited = async (values) => {
    setTncEdit(values)
  }

  const handleSubmitForm = async (values) => {
    let tncUrl = ""
    if (type !== "create") {
      if (type === "edit") {
        dispatch(showBackdrop());

        if (tncEdit) {
          if ((values.warrantyTermsConditionDocument && isFile(values.termsConditionDetail)) || (values.warrantyTermsConditionText)) {

            if (values.warrantyTermsConditionDocument && isFile(values.termsConditionDetail) && !isPDF(values.termsConditionDetail)) {
              dispatch(
                addAlert({
                  severity: "error",
                  message: getLang(lang,"message.error.UPLOAD_PDF_ONLY")
                })
              )
            }
            else {
              tncUrl = await tncUploadToS3(values)
              if (tncUrl.length > 0) {
                values.termsConditionDetail = tncUrl.split("?")[0];
              }
            }
          }
        }

        const productId = [];
        for (const product of values.productIds) {
          productId.push(product.id);
        }
        const updateInfo = {
          ...values,
          fileUrl: tncEdit ? values.termsConditionDetail : values.fileUrl,
          productId: productId,
          warrantyRequiredValidation: values.warrantyRequiredValidation ? 1 : 0,
          warrantyApproval: values.warrantyApproval ? 1 : 0,
          warrantyPreregisterEnabled: values.warrantyPreregisterEnabled ? 1 : 0,
          itemReplacementProcessEnabled: values.itemReplacementProcessEnabled ? 1 : 0,
          allowDifferentProductReplacement: values.allowDifferentProductReplacement ? 1 : 0,
          claimAdd: values.claimReasons.filter(item => item.command && item.command.includes('add')).map(item => item.name),
          claimDisable: values.claimReasons.filter(item => item.command && item.command.includes('disable')).map(item => item.id),
          claimEnable: values.claimReasons.filter(item => item.command && item.command.includes('enable')).map(item => item.id),
          claimEdit: values.claimReasons.filter(item => item.command && item.command.includes('edit')).map(item => {
            return {
              id: item.id,
              name: item.name
            }
          }),
          warrantyRegisterNotificationType: values.warrantyRegisterNotificationType,
          claimApplicationNotificationType: values.claimApplicationNotificationType,
          customRecipient: {
            warrantyRegister: values.registerEmail,
            claimApplication: values.claimEmail,
          },
        };

        await updateWarrantySettingDetail(updateInfo)
          .then(unwrapResult)
          .then(dispatch(fetchWarrantySettingDetail(values.id)))
          .then(() => {
            let tempTncUrl = tncEdit ? values.termsConditionDetail : values.fileUrl;
            if (values.warrantyTermsConditionText) {
              dispatch(fetchJSONURL(tempTncUrl))
                .then(unwrapResult)
            }
            changeBackView();
          })
        dispatch(hideBackdrop());
      }
    }
    else {
      const updateInfo = {
        ...values,
        warranty_name: values.warrantyName,
        duration_month: values.warrantyPeriod,
        warrantyRequiredValidation: values.warrantyRequiredValidation ? 1 : 0,
        warrantyApproval: values.warrantyApproval ? 1 : 0,
        warrantyPreregisterEnabled: values.warrantyPreregisterEnabled ? 1 : 0,
        itemReplacementProcessEnabled: values.itemReplacementProcessEnabled ? 1 : 0,
        allowDifferentProductReplacement: values.allowDifferentProductReplacement ? 1 : 0,
        product: values.productIds,
        claimAdd: values.claimReasons.filter(item => item.command && item.command.includes('add')).map(item => item.name),
        claimDisable: values.claimReasons.filter(item => item.command && item.command.includes('disable')).map(item => item.id),
        claimEnable: values.claimReasons.filter(item => item.command && item.command.includes('enable')).map(item => item.id),
        claimEdit: values.claimReasons.filter(item => item.command && item.command.includes('edit')).map(item => {
          return {
            id: item.id,
            name: item.name
          }
        }),
        warrantyRegisterNotificationType: values.warrantyRegisterNotificationType,
        claimApplicationNotificationType: values.claimApplicationNotificationType,
        customRecipient: {
          warrantyRegister: values.registerEmail,
          claimApplication: values.claimEmail,
        },
      };

      if (!values.productIds.length) {
        addAlert({
          severity: "error",
          message: "Please assign at least 1 product to the warranty"
        });
        return
      }
      if (values.warrantyTermsConditionDocument) {
        if ((file === null || file.name !== values.termsConditionDetail) && !isPDF(values.termsConditionDetail)) {
          addAlert({
            severity: "error",
            message: getLang(lang, 'message.error.ERROR_INVALID_PDF_FILE')
          });
          return
        }
        if (isPDF(values.termsConditionDetail) && isFileExceedLimit(values.termsConditionDetail.size, 2, 'MB')) {
          addAlert({
            severity: "error",
            message: getLang(lang, 'message.error.ERROR_FILE_EXCEED_LIMIT')
          });
          return
        }
        if (isFile(values.termsConditionDetail)) {
          updateInfo.termsConditionDetail = values.termsConditionDetail.name;
        }

      } else {
        if (!values.termsConditionDetail || !values.termsConditionDetail.value || values.termsConditionDetail.value.replaceAll('&nbsp;', '').replaceAll('\\n', '').replaceAll('<p></p>', '').trim() === '') {
          addAlert({
            severity: "error",
            message: getLang(lang, 'message.error.EMPTY_TNC')
          });
          return;
        }
      }
      if (isFile(values.termsConditionDetail)) {
        await setFile(values.termsConditionDetail);
      }

      await setWarrantySetting(updateInfo);
      handleNext(values.warrantyRequiredValidation);
    }
  };

  const tncUploadToS3 = async (values) => {
    let url = "";
    if (values.warrantyTermsConditionText) {
      const fileName = "JsonFile";

      const uploadJSONdata = {
        uploadType: UploadType.warrantyTermAndCondition,
        data: values.termsConditionDetail,
        id: id,
        fileName: `${Date.now()}${fileName}`
      }
      await dispatch(uploadJSONToS3(uploadJSONdata))
        .then(unwrapResult)
        .then((jsonUrl) => {
          url = jsonUrl
        })
        .catch(error => {
          dispatch(
            addAlert({
              severity: "error",
              message: error.message || "Upload Terms & Conditions Failed"
            })
          )
        })
      return url
    }
    else {
      const fileName = values.termsConditionDetail.name;

      const uploadPDFdata = {
        uploadType: UploadType.warrantyTermAndCondition,
        file: values.termsConditionDetail,
        id: id,
        fileName: `${fileName}_${Date.now()}${fileName}`
      }
      await dispatch(uploadFileToS3(uploadPDFdata))
        .then(unwrapResult)
        .then((termUrl) => {
          url = termUrl
        })
        .catch(error => {
          dispatch(
            addAlert({
              severity: "error",
              message: error.message || "Upload Terms & Conditions Failed"
            })
          )
        })
      return url
    }

  }

  const processReason = (reason, code) => {
    if (code) {
      const { remove, ...newName } = Object
        .fromEntries(Object.entries(reason.name)
          .filter(([key]) => key !== code)
        );

      return {
        ...reason,
        name: newName,
        command: (reason.command || []).includes("add")
          ? ["add"]
          : (reason.command || []).includes("edit")
            ? [...(reason.command || [])]
            : [...(reason.command || []), "edit"],
      };
    }

    const { remove, ...newName } = Object
      .fromEntries(Object.entries(reason.name)
        .filter(([key]) => key !== usedLang[languageTabIndex].code)
      );

    return {
      ...reason,
      name: newName,
      command: (reason.command || []).includes("add")
        ? ["add"]
        : (reason.command || []).includes("edit")
          ? [...(reason.command || [])]
          : [...(reason.command || []), "edit"],
    };
  }

  return (
    <WarrantySettingFormComponent
      children={children}
      earlyAccess={earlyAccess}
      handleSubmit={handleSubmitForm}
      warrantySettingForm={warrantySettingForm}
      type={type}
      lang={lang}
      onDocumentLoadSuccess={onDocumentLoadSuccess}
      setTncEdited={setTncEdited}
      pageNumber={pageNumber}
      numPages={numPages}
      handleTncPdfChangePage={handleTncPdfChangePage}
      languageTabIndex={languageTabIndex}
      setLanguageTabIndex={(value) => setLanguageTabIndex(value)}
      usedLang={usedLang}
      setUsedLang={setUsedLang}
      anchorEl={anchorEl}
      handleAddLangClick={(event) => setAnchorEl(event.currentTarget)}
      handleCloseAddLang={() => setAnchorEl(null)}
      handleAddLang={(lang) => {
        const temp = [...usedLang];
        temp.push(lang);
        setLanguageTabIndex(temp.indexOf(lang));
        setUsedLang(temp);
      }}
      handleRemoveLang={(code = null) => {
        if (code) {
          const temp = [...usedLang].filter((item) => item.code !== code);
          setLanguageTabIndex(0);
          setUsedLang(temp);
        }
        else {
          const temp = [...usedLang].filter((item) => item.code !== usedLang[languageTabIndex].code);
          setLanguageTabIndex(0);
          setUsedLang(temp);
        }
      }}
      emptyErrorOnLang={emptyErrorOnLang}
      setEmptyErrorOnLang={setEmptyErrorOnLang}
      duplicateErrorOnLang={duplicateErrorOnLang}
      setDuplicateErrorOnLang={setDuplicateErrorOnLang}
      addableLangs={languages.filter(language => !usedLang.some(used => used.code === language.code))}
      processReason={processReason}
      hasWarrantyPreRegisterEditRight={hasWarrantyPreRegisterEditRight}
      hasWarrantyValidateDoNumEditRight={hasWarrantyValidateDoNumEditRight}
    />
  );
}

const mapStateToProps = (state) => ({
  id: state.session.idTokenPayload.sub
});

const mapDispatchToProps = (dispatch) => ({
  setWarrantySetting: (values) => dispatch(setWarrantySetting(values)),
  updateWarrantySettingDetail: (values) =>
    dispatch(updateWarrantySettingDetail(values)),
  addAlert: (notif) => dispatch(addAlert(notif))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WarrantySettingFormContainer);
