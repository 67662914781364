import { createSlice } from "@reduxjs/toolkit";
import { APP } from "../../constants";
import { setCurrentModule } from "../action";

const initialState = {
  navigator: { module: "CheckNow System" },
  drawerOpen: true
};

const appSlice = createSlice({
  name: APP,
  initialState,
  reducers: {
    setDrawerState(state, action) {
      switch (action.payload.type) {
        case "Normal":
          state.drawerOpen = !state.drawerOpen;
          break;
        case "Close":
          state.drawerOpen = false;
          break;
        case "Open":
          state.drawerOpen = true;
          break;
        default:
          state.drawerOpen = !state.drawerOpen;
          break;
      }
    }
  },
  extraReducers: {
    [setCurrentModule]: (state, action) => {
      state.navigator.module = action.payload;
    }
  }
});

export const {
  setDrawerState
} = appSlice.actions;
export const appReducer = appSlice.reducer;
