import React from "react";
import CampaignPage from './campaign.page';


export default function CampaignPageContainer({
    filter,
    pageHandler,
    pageMode,
    translate,
    campaignType
}) {
    
    return (
        <CampaignPage 
            filter={filter}
            pageHandler={pageHandler}
            pageMode={pageMode}
            campaignType={campaignType}
            translate={translate}
        />
    )
}