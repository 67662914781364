import { Box, Button, Grid, makeStyles, Menu, MenuItem, withStyles } from "@material-ui/core";
import { responseHandler, sortByNameAscending } from "modules/reporting/utils/helper";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { DateRangePicker } from "rsuite";
import CustomMenu from "../../menu";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import "rsuite/dist/rsuite.min.css";
import { FilterLoading } from "../../skeleton/loading";
import '../../../assets/css/customDateRangePicker.css';
import { layoutStyle } from "modules/reporting/styles";
import { getLang } from "app/feature/constants";

const useStyles = makeStyles((theme) => ({
    filter__container : {
        background : '#FFFFFF',
        padding : "0px 32px",
        display : 'flex',
        alignItems : 'center',
        justifyContent : 'space-between'
    }
}));

const CustomButton = withStyles((theme) => ({
    root: {
      minWidth: "36px",
    },
    label: {
      fontSize: "12px",
      fontFamily: "Roboto",
      fontWeight: 400,
      color: "#344054",
      lineHeight: "14.4px",
      textTransform: "none",
    },
  }))(Button);

export default function FilterPanel({
    filter,
    currentFilter,
    pageMode,
    lang
}){
    const classes = useStyles();
    const layoutClass = layoutStyle();
    const [anchorEl, setAnchorEl] = useState(null);
    const leftSides = filter.leftSide;
    const rightSides = filter.rightSide;

    // range selection
    let selectionSubDateRange = null;
    const subDateRange = (date) => {   
        // new date with moment
        let result = moment(date).toDate();

        switch(rightSides.period.current) {
            case 'daily':
                return result;
            case 'weekly':
                return result;
            case 'monthly':
                // set first day of the month
                return result = moment(result).startOf('month').toDate();
            default:
                return result
        }  
    }

    const addDateRange = (date) => {
        let result = moment(date).toDate();
        switch(rightSides.period.current) {
            case 'daily':
                return result;
            case 'weekly':
                let week = Math.floor(moment(result).diff(selectionSubDateRange, 'days')/7) ;
                
                if(selectionSubDateRange){
                    if(result >= selectionSubDateRange){
                        result = moment(selectionSubDateRange).add(6, 'days').toDate();
                    } else {
                        result = moment(selectionSubDateRange).add(-6, 'days').toDate();
                    }
                    
                    if(week >= 1){
                        result = moment(result).add(week * 7, 'days').toDate();
                    } else if (week < -1){
                        let negativeWeek = week + 1;
                        result = moment(result).add(negativeWeek * 7, 'days').toDate();
                    }

                }else{
                    result = moment(result).add(week * 7, 'days').toDate();
                }

                if (result.getTime() > Date.now()) {
                    result = moment().toDate();
                }
                return result;
            case 'monthly':
                result = moment(result).endOf('month').toDate();
                if (result.getTime() > Date.now()) {
                    result = moment().toDate();
                }    

                return result;
            default:
                return result
        }
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCityMenu = (item) => {
        if(item.tag !== 'City') return item.menuItems.options;
        if(Object.keys(item.menuItems).length === 0){
            return [];
        }

        const country_code = leftSides.find((item) => item.tag === 'Country').current;

        if(country_code){
            let cities = item.menuItems.options.filter((city) => {
                if(!city.country_code)
                    return true;

                return city.country_code.toLowerCase() === country_code;
            });
            cities = sortByNameAscending(cities);

            return cities;
        } else {
            return item.menuItems.options;
        }
    }

    useEffect(() => {
        let params = '?';

        if(pageMode){
            params += `page=${pageMode.page}&`;
        }

        if(currentFilter.countryCode)
            params += `countryCode=${currentFilter.countryCode}&`;

        if(currentFilter.cityUuid)
            params += `cityUuid=${currentFilter.cityUuid}&`;

        if(currentFilter.branchUuid)
            params += `branchUuid=${currentFilter.branchUuid}&`;

        if(currentFilter.brandUuid)
            params += `brandUuid=${currentFilter.brandUuid}&`;

        if(currentFilter.modelUuid)
            params += `modelUuid=${currentFilter.modelUuid}&`; 
            
        if(currentFilter.categoryUuid)
            params += `categoryUuid=${currentFilter.categoryUuid}&`;  

        if(currentFilter.period)
            params += `period=${currentFilter.period}&`;
        
        // date range sql format in moment
        if(currentFilter.dateRange)
            params += `start=${moment(currentFilter.dateRange[0]).format('YYYY-MM-DD')}&`;

        if(currentFilter.dateRange)
            params += `end=${moment(currentFilter.dateRange[1]).format('YYYY-MM-DD')}&`;

        if(currentFilter.productUuid)
            params += `productUuid=${currentFilter.productUuid}&`;
        
        if(currentFilter.campaignType)
            params += `campaignType=${currentFilter.campaignType}&`;

        if(currentFilter.campaignUuid)
            params += `campaignUuid=${currentFilter.campaignUuid}&`;

        if(currentFilter.prizeUuid)
            params += `prizeUuid=${currentFilter.prizeUuid}&`;

        if(currentFilter.isDev)
            params += `dev=${currentFilter.isDev}&`;
            
        if(currentFilter){
            window.history.replaceState(null, null, `${params}` );
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentFilter, pageMode]);

    return (
        <Box className={classes.filter__container}>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    {/* left side */}
                    <Box display="flex" flexWrap="wrap"> 
                        {leftSides.map((item, index) => {
                            
                            return (
                                responseHandler(
                                    item.menuItems ?? [],
                                    item.fetching,
                                    (
                                        <CustomMenu
                                            key={index}
                                            tag={item.tag}
                                            menuItems={handleCityMenu(item) ?? []}
                                            countryIcon={item.tag === 'Country' ? true : false}
                                            selected={item.current}
                                            action={item.action}
                                        />
                                    ),
                                    (
                                        <FilterLoading />
                                    ),
                                    (
                                        <>empty</>
                                    ),
                                    (
                                        <>no result</>
                                    ),
                                    (
                                        <>error</>
                                    )
                                )
                            )
                        })}
                    </Box>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    {/* right side */}
                    <Box 
                        className={layoutClass.layout__filter_right_sides}
                    >
                        <CustomMenu
                            menuItems={rightSides.period.menuItems}
                            selected={rightSides.period.current}
                            action={rightSides.period.action}
                            tag={getLang(lang, "label.PERIOD")}
                        />
                        <Box
                            display="flex"
                            alignContent="center"
                            flexWrap="wrap"
                        >
                            <DateRangePicker
                                // config
                                character=" - " // separator between two dates
                                cleanable={false}
                                placeholder="Select Date Range" 
                                format="dd MMMM yyyy" 
                                placement="auto"
                                disabledDate={date => date.getTime() > Date.now()}
                                editable={false}
                                hoverRange={(date, test) => [subDateRange(date, test), addDateRange(date, test)]}
                                ranges={[]}
                                // get date range start and end
                                onChange={(value) => {
                                    // value[0] = start date
                                    // value[1] = end date
                                    rightSides.dateRange.action(value[0] , value[1], null);
                                }}

                                onSelect={(value) => {
                                    if(selectionSubDateRange){
                                        selectionSubDateRange = null;
                                    } else {
                                        selectionSubDateRange = value;
                                    }
                                }}

                                value={rightSides.dateRange.current}
                            />
                            <Box
                                style={{
                                    padding: 0
                                }}
                            >
                                <CustomButton
                                    onClick={handleClick}
                                    variant="outlined"
                                    endIcon={<ArrowDropDownIcon />}
                                    style={{
                                        borderTopLeftRadius: "0px",
                                        borderBottomLeftRadius: "0px",
                                        padding: "7px 0px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "start",
                                    }}
                                >
                                </CustomButton>
                                <Menu
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                >
                                    <MenuItem
                                        selected={null}
                                        onClick={() => {
                                            rightSides.dateRange.action(
                                                moment().subtract(7, "days").toDate(),
                                                moment().toDate(),
                                                'daily'
                                            );
                                            setAnchorEl(null);
                                        }}
                                    >
                                        {getLang(lang, "label.LAST_COUNT_DAYS", { day: 7 })}
                                    </MenuItem>
                                    <MenuItem
                                        selected={null}
                                        onClick={() => {
                                            rightSides.dateRange.action(
                                                moment().subtract(14, "days").toDate(),
                                                moment().toDate(),
                                                'daily'
                                            );
                                            setAnchorEl(null);
                                        }}
                                    >
                                        {getLang(lang, "label.LAST_COUNT_DAYS", { day: 14 })}
                                    </MenuItem>
                                    <MenuItem
                                        selected={null}
                                        onClick={() => {
                                            rightSides.dateRange.action(
                                                moment().subtract(28, "days").toDate(),
                                                moment().toDate(),
                                                'daily'
                                            );
                                            setAnchorEl(null);
                                        }}
                                    >
                                        {getLang(lang, "label.LAST_COUNT_DAYS", { day: 28 })}
                                    </MenuItem>
                                    <MenuItem
                                        selected={null}
                                        onClick={() => {
                                            rightSides.dateRange.action(
                                                moment().startOf('month').toDate(),
                                                moment().toDate(),
                                                'daily'
                                            );
                                            setAnchorEl(null);
                                        }}
                                    >
                                        {getLang(lang, "label.THIS_MONTH")}
                                    </MenuItem>
                                    <MenuItem
                                        selected={null}
                                        onClick={() => {
                                            rightSides.dateRange.action(
                                                moment().subtract(1, 'month').startOf('month').toDate(),
                                                moment().subtract(1, 'month').endOf('month').toDate(),
                                                'daily'
                                            );
                                            setAnchorEl(null);
                                        }}
                                    >
                                        {getLang(lang, "label.LAST_MONTH")}
                                    </MenuItem>
                                </Menu>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}