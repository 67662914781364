import { Content } from "modules/reporting/components/layout";
import { FilterPanelV3, LoyaltyProgramListPanel } from "modules/reporting/components/panel";
import React from "react";

export default function LoyaltyProgramPage({
    filter,
    tagUrl,
    currentFilter,
    pageMode,
    campaignType,
    translate
}) {
    const pageType = "campaign-list";
    return (
        <>
            <FilterPanelV3
                filter={filter}
                currentFilter={currentFilter}
                pageMode={pageMode}
                translate={translate}
            />
            <Content>
                {/* loyalty program list --- START */}
                <LoyaltyProgramListPanel
                    currentFilter={currentFilter}
                    urlProgramLoyaltyList={tagUrl + "get-loyalty-program-info-list"}
                    // urlProgramLoyaltyList={"http://localhost:9080/loyaltyProgramList"}
                    pageType={pageType}
                    campaignType={campaignType}

                    translate={translate}
                />
            </Content>
        </>
    )
}