import React from "react";
import PushNotification from "./components/snackBar/snackBar.container";
import NotificationBadge from "./components/notificationBadge";
import {
  addAlert,
  setLoading,
  selectLoading,
  reducer as notificationReducer,
  middleware as notificationMiddleware
} from "./redux";

export default function Notification() {
  return <PushNotification />;
}

export {
  NotificationBadge,
  addAlert,
  setLoading,
  selectLoading,
  notificationReducer,
  notificationMiddleware
};
