import PropTypes from "prop-types";

const HOST = process.env.REACT_APP_API_GATEWAY_URL;

const updateReadFlag = async ({ sessionID, userAccountToken, id, isRead }) => {
  const API_URL = `${HOST}/notification/Alert/IsReadFlag/Update`;

  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("sessionID", sessionID);
  headers.append("userAccountToken", userAccountToken);

  const body = JSON.stringify({ id, isRead });

  const requestOptions = { method: "POST", headers, body };

  const response = await fetch(API_URL, requestOptions)
    .then(res => res.json())
    .catch(err => {
      return { success: false, message: err.toString() };
    });

  return response;
};

updateReadFlag.propTypes = {
  sessionID: PropTypes.string.isRequired,
  userAccountToken: PropTypes.string.isRequired,
  id: PropTypes.arrayOf(PropTypes.number).isRequired,
  isRead: PropTypes.bool.isRequired
};

export default updateReadFlag;
