import axios from "axios";

const ENDPOINT_URL = "/payment/api/v1/subscription/summary";

const getSummary = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(ENDPOINT_URL)
      .then(response => resolve(response))
      .catch(error => {
        return reject(error.response.data);
      });
  });
};

const subscription = {
  summary: {
    get: getSummary
  }
};

export default subscription;
