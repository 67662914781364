import React from 'react'
import { Box } from '@material-ui/core'
import ConsumerTable from '../../table/consumerTable'
import { GeneralSearch } from "components/search";

export default function ConsumerListingPanelComponent({
    consumers,
    isFetching,
    page,
    search,
    rowsPerPage,
    totalRecords,
    handleChangePage,
    handleChangeRowsPerPage,
    handleSearch,
    handleReload,
    paginationOptions
}) {
    return (
        <Box pt={2}>
            <GeneralSearch
                handleFilter={(value) => {
                    handleSearch(value);
                }}
            />
            <ConsumerTable
                consumers={consumers}
                isFetching={isFetching}
                page={page}
                search={search}
                rowsPerPage={rowsPerPage}
                totalRecords={totalRecords}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                handleReload={handleReload}
                paginationOptions={paginationOptions}
            />
        </Box>
    )
}