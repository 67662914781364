import axios from "axios";

const updateInstantLuckyDraw = ({
  title,
  description,
  imgUrl,
  tncUrl,
  metadata,
  productId,
  startAt,
  endAt,
  game,
  status,
  id,
}) => {
  const body = {
    title: title,
    description: description,
    imgUrl: imgUrl,
    tncUrl: tncUrl,
    customField: metadata,
    productId: productId,
    startAt: startAt,
    endAt: endAt,
    game: game,
    status: status,
    instant_lucky_draw_id: id,
  };

  return new Promise((resolve, reject) => {
    axios
      .post("/campaignV2/customer/instantLuckyDraw/update", body)
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

export default updateInstantLuckyDraw;
