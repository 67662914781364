import React, { useEffect, useState } from "react";
import ScanDetailDialogComponent from "./scanDetailDialog.component";
import { useDispatch, useSelector } from "react-redux";
import { resetScanDetail } from "modules/dashboard/redux/slice/dashboard.slice";
import { fetchScanDetail } from "modules/dashboard/redux/action/dashboard.action";
import { selectLoading } from "modules/notification";
import { COUNTERFEIT_REPORT_EDIT } from "lib/constants/accessRights";
import { updateCounterfeitReportDetail } from "modules/counterfeit-report/redux";

function ScanDetailDialogContainer({ isOpen, handleClose, scanId }) {
  const dispatch = useDispatch();

  const hasCounterfeitReportEditAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(COUNTERFEIT_REPORT_EDIT)
  );
  const isReportSubmitting = useSelector((state) =>
    selectLoading(state, updateCounterfeitReportDetail.typePrefix)
  );
  const lang = useSelector((state) => state.constant.languages);
  const language = useSelector((state) => state.profile.language);
  const scanDetail = useSelector((state) => state.dashboard.scanDetail);
  const isLoadingScanDetailError = useSelector(
    (state) => state.dashboard.isLoadingScanDetailError
  );
  const isFetching = useSelector((state) =>
    selectLoading(state, fetchScanDetail.typePrefix)
  );

  const [center, setCenter] = useState({
    lat: 3.139,
    lng: 101.6869,
  });

  useEffect(() => {
    return () => {
      dispatch(resetScanDetail());
    };
  }, [dispatch]);

  useEffect(() => {
    if (scanId) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scanId]);

  useEffect(() => {
    if (scanDetail) {
      setCenter({
        lat: parseFloat(scanDetail.location.latitude),
        lng: parseFloat(scanDetail.location.longitude),
      });
    } else {
      setCenter({
        lat: 3.139,
        lng: 101.6869,
      });
    }
  }, [scanDetail]);

  const getData = () => {
    dispatch(fetchScanDetail(scanId));
  };

  const handleSubmit = async (formik) => {
    const reportData = {
      refCode: scanDetail.counterfeit_report.ref_code,
      status: "processed",
      adminRemarks: formik.values.adminRemarks,
    };
    dispatch(updateCounterfeitReportDetail(reportData)).then(() => {
      formik.setFieldValue("status", "processed");
    });
  };

  return (
    <ScanDetailDialogComponent
      isOpen={isOpen}
      handleClose={handleClose}
      scanDetail={scanDetail}
      isFetching={isFetching}
      lang={lang}
      language={language}
      handleReload={getData}
      center={center}
      hasCounterfeitReportEditAccessRight={hasCounterfeitReportEditAccessRight}
      isSubmitting={isReportSubmitting}
      handleSubmit={handleSubmit}
      isLoadingScanDetailError={isLoadingScanDetailError}
    />
  );
}

export default ScanDetailDialogContainer;
