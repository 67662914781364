import React, { useEffect } from "react";
import LuckyDrawDialogComponent from "./luckyDrawDetailDialog.component";
import { useSelector } from "react-redux";
import { resetRegisterFormType } from "modules/warranty/redux";

export default function LuckyDrawDialogContainer({ isOpen, handleClose }) {
  const headerType = useSelector((state) => state.luckyDraw.headerType);

  useEffect(() => {
    return () => {
      resetRegisterFormType();
    }
  })

  return (
    <LuckyDrawDialogComponent
      isOpen={isOpen}
      handleClose={handleClose}
      headerType={headerType}
    />
  );
}
