import React from 'react'
import { Box, Button, Typography } from '@material-ui/core'
import { Formik } from "formik"
import BulkEditTable from '../../table/bulkEditTable'
import { getLang } from 'app/feature/constants'

export default function BulkEditListPanelComponent({
  rows,
  page,
  rowsPerPage,
  selectedCount,
  paginationOptions,
  isSelectAll,
  selectedIds,
  isFetching,
  isError,
  totalFiltered,
  categoryDropdown,
  handleCategorySearch,
  brandModelDropdown,
  isBrandModelDropdownLoading,
  handleBrandModelSearch,
  attributeDropdown,
  handleAttributeSearch,
  // isAttributeLoading,
  currencyDropdown,
  handleCurrencyCodeSearch,
  handleChangePage,
  handleChangeRowsPerPage,
  totalRecords,
  handleProceed,
  handleBulkEditFieldUpdate,
  handleReset,
  lang
}) {
  return (
    <>
      <Box style={{ display: "flex", justifyContent: "space-between", paddingBottom: "12px" }}>
        <Box style={{ display: "flex", alignItems: "center" }}>
          {selectedCount !== 0 ? (
            (isSelectAll && selectedCount === totalFiltered) ? (
              <Typography variant='body2' style={{color:"#FDB022"}}>{getLang(lang, "paragraph.SELECTED_ALL_PRODUCTS", { count: selectedCount })}</Typography>
            ) : (
              <Typography variant='body2' color='primary'>
                {selectedCount > 1 ? (
                  getLang(lang, "paragraph.SELECTED_PRODUCTS_COUNT", { count: selectedCount })
                ) : (
                  getLang(lang, "paragraph.SELECTED_PRODUCT_COUNT", { count: selectedCount })
                )}
              </Typography>
            )
          ) : (
            ""
          )}
        </Box>
      </Box>
      <Formik
        initialValues={{
          category: "",
          brand: [],
          nodes: [],
          model: [],
          branch: [],
          attribute: [],
          sku: "",
          currencyCode: "",
          price: 0.00,
          scanLimit: 0,
          incentivePoint: 0,
          status: false
        }}
        onSubmit={handleProceed}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <Box>
              <BulkEditTable
                data={rows}
                formik={formik}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                isFetching={isFetching}
                isSelectAll={isSelectAll}
                selectedIds={selectedIds}
                page={page}
                rowsPerPage={rowsPerPage}
                totalRecords={totalRecords}
                categoryDropdownData={categoryDropdown}
                handleCategorySearch={handleCategorySearch}
                currencyDropdownData={currencyDropdown}
                handleCurrencyCodeSearch={handleCurrencyCodeSearch}
                brandModelDropdownData={brandModelDropdown}
                attributeDropdownData={attributeDropdown}
                handleAttributeSearch={handleAttributeSearch}
                handleBrandModelSearch={handleBrandModelSearch}
                isBrandModelDropdownLoading={isBrandModelDropdownLoading}
                handleBulkEditFieldUpdate={handleBulkEditFieldUpdate}
                paginationOptions={paginationOptions}
                lang={lang}
              />
              <Box style={{ backgroundColor: "#fff", display: "flex", justifyContent: "flex-end", padding: "13px 32px"}}>
                <Box mt={2}>
                  <Button
                    variant="text"
                    disableElevation
                    color='secondary'
                    style={{ textTransform: "uppercase", marginRight: "16px" }}
                    onClick={() => {
                      formik.resetForm()
                      handleReset()
                    }}
                  >
                    {getLang(lang, "label.RESET_ALL")}
                  </Button>
                  <Button
                    variant="contained"
                    disableElevation
                    color='secondary'
                    style={{ textTransform: "uppercase" }}
                    type="submit"
                  >
                    {getLang(lang, "label.UPDATE")}
                  </Button>
                </Box>
              </Box>
            </Box>
          </form>
        )}
      </Formik>
    </>
  )
}
