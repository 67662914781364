import React from "react";
import {
  Box,
  Typography,
  makeStyles,
  Button,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import { DownloadIcon } from "modules/reporting/assets/svg";
import { getLang } from "app/feature/constants";
import ScanRecordTable from "../../table/scanRecordTable";
import StatusSelect from "../../select/statusSelect";
import ConsumerSelect from "../../select/consumerSelect";
import { Formik } from "formik";

const useStyles = makeStyles((theme) => ({
  container: {
    background: "white",
    padding: 12,
    borderRadius: 8,
    border: "1px solid #D0D5DD",
  },
  filterContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(2, minmax(0, 1fr)) 0.5fr",
    maxWidth: 500,
    columnGap: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1),
    },
  },
}));

export default function ScanTableListPanelComponent({
  list,
  isFetching,
  isError,
  handleChangePage,
  handleChangeRowsPerPage,
  page,
  rowsPerPage,
  totalFiltered,
  handleReload,
  paginationOptions,
  tableFilter,
  changeOrder,
  handleFilter,
  lang,
  resetFilter,
  handleMarkerClick,
  startDate,
  endDate,
  language,
  clusterBound,
  isExporting,
  exportListing,
}) {
  const classes = useStyles();

  const statusDropdown = [
    { name: getLang(lang, "label.ALL"), value: "all" },
    { name: getLang(lang, "label.ACTIVE"), value: "active" },
    { name: getLang(lang, "label.SUSPENDED"), value: "suspend" },
  ];

  return (
    <Box className={classes.container}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="body1">
          <b>{getLang(lang, "label.CONSUMER_SCANS_DATA")}</b>
        </Typography>
        <Box display="flex" alignItems="center">
          {!!isExporting && (
            <Box mr={1}>
              <CircularProgress color="primary" size={13} />
            </Box>
          )}
          <IconButton
            title="Download Charts"
            aria-label="download"
            onClick={exportListing}
            disableRipple
          >
            <DownloadIcon />
          </IconButton>
        </Box>
      </Box>
      <Box>
        <Formik
          initialValues={{
            status: tableFilter.status,
            consumers: tableFilter.consumers,
          }}
          enableReinitialize
          onSubmit={handleFilter}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <Box className={classes.filterContainer}>
                <StatusSelect
                  value={formik.values.status}
                  handleChange={(value) => {
                    formik.setFieldValue("status", value);
                  }}
                  placeholder={getLang(lang, "placeholder.STATUS")}
                  disabled={isFetching}
                  searchable={false}
                  panelStyle={{ width: 230 }}
                  textStyle={{
                    fontSize: 14,
                  }}
                  dropdownItem={statusDropdown}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                />
                <ConsumerSelect
                  handleChange={(value) => {
                    formik.setFieldValue("consumers", value);
                  }}
                  disabled={isFetching}
                  value={formik.values.consumers}
                  textStyles={{
                    fontSize: 14,
                  }}
                  placeholder={getLang(lang, "label.CONSUMER")}
                  startDate={startDate}
                  endDate={endDate}
                  clusterBound={clusterBound}
                />
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  type="submit"
                  size="small"
                >
                  {getLang(lang, "label.SEARCH")}
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
      <Box mt={2}>
        <ScanRecordTable
          rows={list}
          isFetching={isFetching}
          isError={isError}
          language={language}
          handleReload={handleReload}
          page={page}
          rowsPerPage={rowsPerPage}
          totalFiltered={totalFiltered}
          paginationOptions={paginationOptions}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          tableFilter={tableFilter}
          changeOrder={changeOrder}
          lang={lang}
          handleMarkerClick={handleMarkerClick}
        />
      </Box>
    </Box>
  );
}
