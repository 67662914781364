import React from "react";
import {
  makeStyles,
  Typography,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  Box,
  Divider,
  MenuItem,
  MenuList,
  Checkbox,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles((theme) => ({
  paper: {
    width: 425,
  },
  button: {
    padding: 0,
    minWidth: 0,
  },
  buttonAddBranch: {
    display: "flex",
    padding: 0,
    minWidth: 0,
  },
  createLink: {
    padding: theme.spacing(2),
  },
}));

export default function SingleBranchSelectPanelComponent({
  open,
  anchorRef,
  handleClose,
  isOpenList,
  branchDropdown,
  isFetching,
  value,
  handleSelectBranch,
  lang,
}) {
  const classes = useStyle();

  const generateComponent = (nodes, layer) => {
    if (!nodes || nodes.length === 0) {
      return null;
    }

    return nodes.map((node, index) => {
      return layer === -1 ? (
        <React.Fragment key={index}>
          <MenuItem
            style={{ padding: 0 }}
            onClick={() => handleSelectBranch(node)}
          >
            <Checkbox
              checked={value?.node_id === node.node_id}
            />
            <Typography
              variant="inherit"
              noWrap
              style={{ paddingLeft: "8px" }}
            >
              {node.name}
            </Typography>
          </MenuItem>

          {generateComponent(node.child, 1)}
        </React.Fragment>
      ) : (
        <React.Fragment key={index}>
          <MenuList
            autoFocusItem={isOpenList}
            id="menu-list-grow"
            style={{ padding: 0 }}
          >
            <MenuItem
              style={{
                paddingTop: 0,
                paddingBottom: 0,
                paddingLeft: layer * 40,
                paddingRight: 0,
              }}
              onClick={() => {
                handleSelectBranch(node);
              }}
            >
              <Checkbox
                checked={value?.node_id === node.node_id}
              />

              <Typography
                variant="inherit"
                noWrap
                style={{ paddingLeft: "8px" }}
              >
                {node.name}
              </Typography>
            </MenuItem>
          </MenuList>

          {generateComponent(node.child, layer + 1)}
        </React.Fragment>
      );
    });
  }

  return (
    <Popper
      open={open}
      style={{ zIndex: 99 }}
      anchorEl={anchorRef.current}
      role={undefined}
      transition
      disablePortal
      placement="bottom-start"
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === "bottom-start" ? "center top" : "center bottom",
          }}
        >
          <Paper className={classes.paper}>
            <ClickAwayListener onClickAway={handleClose}>
              <Box>
                <Typography
                  variant="body2"
                  color="primary"
                  style={{ padding: "8px 16px", fontWeight: "bold" }}
                >
                  {getLang(lang, "label.BRANCH")}
                </Typography>
                <Divider />
                {isFetching ? (
                  <Box p={1}>
                    <Skeleton variant="text" animation="wave" />
                    <Skeleton variant="text" animation="wave" />
                    <Skeleton variant="text" animation="wave" />
                    <Skeleton variant="text" animation="wave" />
                  </Box>
                ) : (
                  <div>
                    <Box height="25vh" overflow="auto">
                      {generateComponent(branchDropdown, -1)}
                    </Box>
                  </div>
                )}
              </Box>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}
