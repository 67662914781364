import { createSlice } from "@reduxjs/toolkit";
import { LUCKYDRAW } from "../constants";
import {
  fetchLuckyDrawListTable,
  fetchLuckyDrawParticipantTable,
  fetchLuckyDrawParticipant,
  changeLuckyDrawStatus,
} from "./luckyDraw.action";
import moment from "moment";

const initialState = {
  luckyDrawListTable: {
    data: [],
    totalRecords: 0,
    filteredTotalRecords: 0,
  },
  LuckyDrawDetail: {
    id: "",
    luckyDrawTitle: "",
    luckyDrawDescription: "",
    luckyDrawImageUrl: "",
    luckyDrawImage: "",
    assignProduct: [],
    startAt: "",
    startAtStatic: "",
    endAt: "",
    status: "",
    progress: "",
    luckyDrawTermsConditionText: true,
    luckyDrawTermsConditionDocument: false,
    luckyDrawTermsConditionDetail: { value: "<p></p>\n", data: [] },
    luckyDrawTermsConditionUrl: "",
  },
  participantRegistrationForm: [
    {
      fieldName: "name",
      labelType: "Text Field",
      type: "textField",
      mandatory: true,
      visible: true,
      enableDelete: false,
      editing: false,
      fieldType: "standard",
      variantName: "Name",
      choice: [],
    },
    {
      fieldName: "address",
      labelType: "Address",
      type: "address",
      mandatory: true,
      visible: true,
      enableDelete: false,
      editing: false,
      fieldType: "standard",
      variantName: "Address",
      choice: [],
    },
    {
      fieldName: "email",
      labelType: "Email Text Field",
      type: "textField",
      mandatory: true,
      visible: true,
      enableDelete: false,
      editing: false,
      fieldType: "standard",
      variantName: "Email",
      choice: [],
    },
    {
      fieldName: "gender",
      labelType: "Multiple Choice",
      type: "multipleChoice",
      mandatory: true,
      visible: true,
      enableDelete: false,
      editing: false,
      fieldType: "standard",
      variantName: "Gender",
      choice: ["Male", "Female"],
    },
    {
      fieldName: "contactNumber",
      labelType: "Contact Number",
      type: "contactNumber",
      mandatory: true,
      visible: true,
      enableDelete: false,
      editing: false,
      fieldType: "standard",
      variantName: "Contact Number",
      choice: [],
    },
    {
      fieldName: "ic",
      labelType: "Identification Text Field",
      type: "textField",
      mandatory: true,
      visible: true,
      enableDelete: false,
      editing: false,
      fieldType: "standard",
      variantName: "IC Number",
      choice: [],
    },
  ],
  prizeListTable: {
    data: [],
    totalRecords: 0,
    filteredTotalRecords: 0,
  },
  settingType: "view",
  settingFormEditing: false,
  registerType: "view",
  prizeType: "view",
  winnerType: "view",
  headerType: "view",
  luckyDrawAddField: {
    choice: ["", "", ""],
    enableDelete: true,
    labelType: "",
    value: "",
  },
  luckyDrawParticipantTable: {
    data: [],
    totalRecords: 0,
    filteredTotalRecords: 0,
    hasMoretoLoad: false,
    currentSearch: "",
  },
  participantsDetail: {
    id: "",
    address: "",
    consumerProfileUrl: "",
    contactNumber: "",
    customField: [],
    email: "",
    enc: "",
    name: "",
    productId: "",
    productName: "",
    createdAt: "",
    serialNumber: "",
    status: "",
  },
  participantList: {
    recordsTotal: 0,
    recordsFiltered: 0,
    data: [],
  },
  searchForParticipants: "",
  excludeList: [],
  liveDraw: {
    prizes: [],
  },
  isLoadingLuckyDrawError: false,
  isLoadingLuckyDrawParticipantError: false,
  pageSetting: {
    length: 25,
    start: 0,
    search: "",
    product: [],
  },
};

const luckyDrawSlice = createSlice({
  name: LUCKYDRAW,
  initialState,
  reducers: {
    setLuckyDrawList(state, action) {
      const { payload } = action;
      const tempData = [];

      state.luckyDrawListTable.totalRecords = payload.recordsTotal;
      state.luckyDrawListTable.filteredTotalRecords = payload.recordsFiltered;

      payload.data.forEach((element) => {
        const order = {
          id: element.id || "",
          luckyDrawTitle: element.title || "",
          luckyDrawDescription: element.description || "",
          luckyDrawImageUrl: element.imageUrl || "",
          assignProduct: element.products || [],
          startAt: element.startAt || "",
          endAt: element.endAt || "",
          status: element.status === "active" ? true : false,
          luckyDrawTermsConditionUrl: element.tncUrl || "",
          createdAt: element.createdAt || "",
          updatedAt: element.updatedAt || "",
          customField: element.customField || "",
          progress: element.progress || "",
          participantsCount: element.participantsCount || 0,
        };

        tempData.push(order);
      });

      state.luckyDrawListTable.data = tempData;
    },

    //For create
    setLuckyDrawDetail(state, action) {
      const { payload } = action;

      state.LuckyDrawDetail.luckyDrawTitle = payload.luckyDrawTitle || "";
      state.LuckyDrawDetail.luckyDrawDescription =
        payload.luckyDrawDescription || "";
      state.LuckyDrawDetail.luckyDrawImage = payload.luckyDrawImage || "";
      state.LuckyDrawDetail.startAt = payload.startAt || "";
      state.LuckyDrawDetail.endAt = payload.endAt || "";
      state.LuckyDrawDetail.assignProduct = payload.assignProduct || "";
      state.LuckyDrawDetail.luckyDrawTermsConditionText =
        payload.luckyDrawTermsConditionText || false;
      state.LuckyDrawDetail.luckyDrawTermsConditionDocument =
        payload.luckyDrawTermsConditionDocument || false;
      state.LuckyDrawDetail.luckyDrawTermsConditionDetail =
        payload.luckyDrawTermsConditionDetail || "";
    },

    setLuckyDrawTNC(state, action) {
      const { payload } = action;
      state.LuckyDrawDetail.luckyDrawTermsConditionDetail = payload;
    },

    setLuckyDrawDetailFromApi(state, action) {
      const { payload } = action;

      state.LuckyDrawDetail.luckyDrawTitle = payload.title || "";
      state.LuckyDrawDetail.luckyDrawDescription = payload.description || "";
      state.LuckyDrawDetail.luckyDrawImageUrl = payload.imageUrl || "";
      state.LuckyDrawDetail.startAt = payload.startAt || "";
      state.LuckyDrawDetail.startAtStatic = payload.startAt || "";
      state.LuckyDrawDetail.endAt = payload.endAt || "";
      state.LuckyDrawDetail.progress = payload.progress || "";

      const products = [];
      payload.products.forEach((product) => {
        const id = {
          id: product.productId,
          name: product.productName,
        };
        products.push(id);
      });

      state.LuckyDrawDetail.assignProduct = products;
      state.LuckyDrawDetail.luckyDrawTermsConditionText =
        payload.luckyDrawTermsConditionText || false;
      state.LuckyDrawDetail.luckyDrawTermsConditionDocument =
        payload.luckyDrawTermsConditionDocument || false;
      state.LuckyDrawDetail.luckyDrawTermsConditionDetail =
        payload.luckyDrawTermsConditionDetail || "";
      state.LuckyDrawDetail.luckyDrawTermsConditionUrl = payload.tncUrl || "";

      if (payload.tncUrl.substr(payload.tncUrl.length - 3) === "pdf") {
        state.LuckyDrawDetail.luckyDrawTermsConditionDetail =
          decodeURI(
            payload.tncUrl
              .split("/")
              [payload.tncUrl.split("/").length - 1].split(".pdf")[0]
          ) + ".pdf";
        state.LuckyDrawDetail.luckyDrawTermsConditionDocument = true;
        state.LuckyDrawDetail.luckyDrawTermsConditionText = false;
      } else {
        state.LuckyDrawDetail.luckyDrawTermsConditionText = true;
        state.LuckyDrawDetail.luckyDrawTermsConditionDocument = false;
      }
      state.participantRegistrationForm = payload.customField || [];
      state.LuckyDrawDetail.id = payload.id;
    },

    setLuckyDrawID(state, action) {
      const { payload } = action;

      state.LuckyDrawDetail.id = payload.id;
    },

    setProgress(state, action) {
      const { payload } = action;

      state.LuckyDrawDetail.progress = payload.progress;
    },

    setLuckyDrawTitle(state, action) {
      const { payload } = action;

      state.LuckyDrawDetail.luckyDrawTitle = payload.luckyDrawTitle;
    },

    setParticipantRegistrationForm(state, action) {
      const { payload } = action;
      const newForm = Array.from(payload.participantRegistrationForm);
      state.participantRegistrationForm = newForm;
    },

    setLuckyDrawDetailUpdate(state, action) {
      const { payload } = action;
      state.LuckyDrawDetail.luckyDrawTitle =
        payload.title || state.LuckyDrawDetail.luckyDrawTitle;
      state.LuckyDrawDetail.luckyDrawDescription =
        payload.description || state.LuckyDrawDetail.luckyDrawDescription;
      state.LuckyDrawDetail.luckyDrawImageUrl =
        payload.imageUrl || state.LuckyDrawDetail.luckyDrawImageUrl;
      state.LuckyDrawDetail.startAt =
        payload.LuckyDrawDetail.startAt || state.LuckyDrawDetail.startAt;
      state.LuckyDrawDetail.endAt =
        payload.LuckyDrawDetail.endAt || state.LuckyDrawDetail.endAt;
      if (payload.productId) {
        const products = [];

        payload.productId.forEach((product) => {
          const id = {
            id: product,
          };
          products.push(id);
        });
        state.LuckyDrawDetail.assignProduct = products;
      }

      if (payload.LuckyDrawDetail.luckyDrawTermsConditionDetail) {
        state.LuckyDrawDetail.luckyDrawTermsConditionText =
          payload.luckyDrawTermsConditionText ||
          state.LuckyDrawDetail.luckyDrawTermsConditionText;
        state.LuckyDrawDetail.luckyDrawTermsConditionDocument =
          payload.luckyDrawTermsConditionDocument ||
          state.LuckyDrawDetail.luckyDrawTermsConditionDocument;
        state.LuckyDrawDetail.luckyDrawTermsConditionDetail =
          payload.luckyDrawTermsConditionDetail ||
          state.LuckyDrawDetail.luckyDrawTermsConditionDetail;
        state.LuckyDrawDetail.luckyDrawTermsConditionUrl =
          payload.tncUrl || state.LuckyDrawDetail.luckyDrawTermsConditionUrl;
        state.LuckyDrawDetail.luckyDrawTermsConditionDocument =
          payload.LuckyDrawDetail.luckyDrawTermsConditionDocument;
        state.LuckyDrawDetail.luckyDrawTermsConditionText =
          payload.LuckyDrawDetail.luckyDrawTermsConditionText;
        if (payload.LuckyDrawDetail.luckyDrawTermsConditionText) {
          state.LuckyDrawDetail.luckyDrawTermsConditionDetail =
            payload.LuckyDrawDetail.luckyDrawTermsConditionDetail;
          state.LuckyDrawDetail.luckyDrawTermsConditionUrl =
            initialState.LuckyDrawDetail.luckyDrawTermsConditionUrl;
        } else {
          state.LuckyDrawDetail.luckyDrawTermsConditionDetail =
            decodeURI(
              payload.LuckyDrawDetail.luckyDrawTermsConditionDetail
                .split("/")
                [
                  payload.LuckyDrawDetail.luckyDrawTermsConditionDetail.split(
                    "/"
                  ).length - 1
                ].split(".pdf")[0]
            ) + ".pdf";
          state.LuckyDrawDetail.luckyDrawTermsConditionUrl =
            payload.LuckyDrawDetail.luckyDrawTermsConditionDetail;
        }
      }
      state.participantRegistrationForm =
        payload.metadata || state.participantRegistrationForm;

      const newList = state.luckyDrawListTable.data;
      const index = newList.findIndex(
        (value) => value.id === payload.LuckyDrawDetail.id
      );

      if (index) {
        newList[index] = {
          ...newList[index],
          luckyDrawTitle: payload.title,
          luckyDrawDescription: payload.description,
          luckyDrawImageUrl: payload.imageUrl,
          assignProduct: payload.productId,
          startAt: moment(payload.startAt).format("DD MMM YYYY, hh:mm"),
          endAt: moment(payload.endAt).format("DD MMM YYYY, hh:mm"),
        };

        state.luckyDrawListTable.data = newList;
      }
    },

    setRegisterForm(state, action) {
      const { payload } = action;
      state.participantRegistrationForm = payload;
      state.settingFormEditing = false;
      state.luckyDrawAddField.labelType = "";
      state.luckyDrawAddField.value = "";
      state.luckyDrawAddField.enableDelete = true;
      state.luckyDrawAddField.choice = ["", "", ""];
    },

    setRegisterFormEdit(state) {
      state.participantRegistrationForm.forEach((element) => {
        element.editing = true;
        state.settingFormEditing = true;
      });
    },

    setRegisterFormFinishEdit(state, action) {
      const { payload } = action;
      state.participantRegistrationForm = payload.FinishEditForm;
    },

    setLuckyDrawNewField(state, action) {
      const { payload } = action;
      state.luckyDrawAddField.labelType = payload.labelType;
      state.luckyDrawAddField.value = payload.value;
      state.luckyDrawAddField.enableDelete = payload.enableDelete;
      state.luckyDrawAddField.choice = payload.choice;
      state.settingFormEditing = payload.settingFormEditing;
    },

    setRegisterFormDelete(state, action) {
      const { payload } = action;
      state.participantRegistrationForm.splice(payload, 1);
      state.settingFormEditing = false;
    },

    setPrizeList(state, action) {
      const { payload } = action;

      state.prizeListTable.data = payload;
      state.prizeListTable.totalRecords++;
      state.prizeListTable.filteredTotalRecords++;
    },

    setPrizeListTable(state, action) {
      const { payload } = action;
      state.LuckyDrawDetail.id = payload.id;
      const tempData = [];
      state.prizeListTable.totalRecords = payload.recordsTotal;
      state.prizeListTable.filteredTotalRecords = payload.recordsFiltered;

      payload.data.forEach((element) => {
        const order = {
          prizeLabel: element.title || "",
          prizeName: element.name || "",
          description: element.description || "",
          quantity: element.quantity || "",
          prizeImage: element.imageUrl || "",
          drawMethod: element.type || "",
          order: element.order,
          id: element.id,
          winners: element.winners || [],
          editing: false,
        };

        tempData.push(order);
      });
      state.prizeListTable.data = tempData;
    },

    setPrizeEdit(state, action) {
      const { payload } = action;
      state.prizeListTable.data.forEach((element, index) => {
        if (index === payload) element.editing = true;
        else element.editing = false;
      });
    },

    setCancelEdit(state, action) {
      const { payload } = action;

      state.prizeListTable.data[payload.index].editing = false;
    },

    setPrizeFinishEdit(state, action) {
      const { payload } = action;

      state.prizeListTable.data[payload.index].prizeLabel =
        payload.prizeLabel ||
        state.prizeListTable.data[payload.index].prizeLabel;
      state.prizeListTable.data[payload.index].prizeName =
        payload.prizeName || state.prizeListTable.data[payload.index].prizeName;
      state.prizeListTable.data[payload.index].description =
        payload.description ||
        state.prizeListTable.data[payload.index].description;
      state.prizeListTable.data[payload.index].quantity =
        payload.quantity || state.prizeListTable.data[payload.index].quantity;
      state.prizeListTable.data[payload.index].prizeImage =
        payload.prizeImage ||
        state.prizeListTable.data[payload.index].prizeImage;
      state.prizeListTable.data[payload.index].drawMethod =
        payload.drawMethod ||
        state.prizeListTable.data[payload.index].drawMethod;
      state.prizeListTable.data[payload.index].editing = false;
      state.prizeListTable.data[payload.index].order =
        payload.order || state.prizeListTable.data[payload.index].order;
    },

    setPrizeListDelete(state, action) {
      const { payload } = action;

      state.prizeListTable.data.splice(payload, 1);
    },

    setType(state, action) {
      const { payload } = action;
      state.headerType = payload.type;
      switch (payload.index) {
        case 0:
          state.settingType = payload.type;
          break;
        case 1:
          state.registerType = payload.type;
          break;
        case 2:
          state.winnerType = payload.type;
          break;
        default:
          state.settingType = payload.type;
          state.registerType = payload.type;
          state.winnerType = payload.type;
          break;
      }
    },

    resetLuckyDrawDetail(state, action) {
      state.LuckyDrawDetail = initialState.LuckyDrawDetail;
    },

    resetRegisterForm(state, action) {
      state.participantRegistrationForm =
        initialState.participantRegistrationForm;
    },

    resetPrizeList(state, action) {
      state.prizeListTable = initialState.prizeListTable;
    },

    resetRegisterFormType(state, action) {
      state.registerType = initialState.registerType;
      state.headerType = initialState.headerType;
      state.settingFormEditing = initialState.settingFormEditing;
      state.luckyDrawAddField = initialState.luckyDrawAddField;
      state.settingType = initialState.settingType;
    },

    reorderRegisterFormField(state, action) {
      const { payload } = action;
      const newForm = Array.from(payload.latestform);
      const [removed] = newForm.splice(payload.sourceIndex, 1);
      newForm.splice(payload.destinationIndex, 0, removed);

      state.participantRegistrationForm = newForm;
    },
    closeLuckyDrawPrizeDialog(state) {
      state.prizeListTable = initialState.prizeListTable;
    },
    setLuckyDrawParticipant(state, action) {
      const { payload } = action;
      let tempData = [];
      if (
        state.searchForParticipants ===
        state.luckyDrawParticipantTable.currentSearch
      ) {
        tempData = state.luckyDrawParticipantTable.data;
      }

      state.luckyDrawParticipantTable.currentSearch =
        state.searchForParticipants;

      state.luckyDrawParticipantTable.totalRecords = payload.recordsTotal;
      state.luckyDrawParticipantTable.filteredTotalRecords =
        payload.recordsFiltered;

      payload.data.forEach((element) => {
        const order = {
          id: element.id || "",
          name: element.name || "",
          address: element.address || "",
          email: element.email || "",
          contactNumber: element.contactNumber || "",
          enc: element.enc || "",
          serialNumber: element.serialNumber || "",
          productId: element.productId || "",
          status: element.status || "",
          customField: element.customField || "",
          userId: element.userId || "",
          luckyDrawId: element.luckyDrawId || "",
          createdAt: element.createdAt || "",
          updatedAt: element.updatedAt || "",
        };
        tempData.push(order);
      });
      state.luckyDrawParticipantTable.hasMoretoLoad =
        state.luckyDrawParticipantTable.filteredTotalRecords >
        state.luckyDrawParticipantTable.data.length;

      state.luckyDrawParticipantTable.data = tempData;
    },
    //For table
    setLuckyDrawParticipantTable(state, action) {
      const { payload } = action;
      const tempData = [];

      state.luckyDrawParticipantTable.totalRecords = payload.recordsTotal;
      state.luckyDrawParticipantTable.filteredTotalRecords =
        payload.recordsFiltered;

      payload.data.forEach((element) => {
        const order = {
          id: element.id || "",
          name: element.name || "",
          address: element.address || "",
          email: element.email || "",
          contactNumber: element.contactNumber || "",
          enc: element.enc || "",
          serialNumber: element.serialNumber || "",
          productName: element.productName || "",
          status: element.status || "",
          customField: element.customField || "",
          userId: element.userId || "",
          luckyDrawId: element.luckyDrawId || "",
          createdAt: element.createdAt || "",
          updatedAt: element.updatedAt || "",
        };
        tempData.push(order);
      });

      state.luckyDrawParticipantTable.data = tempData;
    },
    setParticipantDetail(state, action) {
      const { payload } = action;

      state.participantsDetail.id = payload.id;
      state.participantsDetail.address = payload.address;
      state.participantsDetail.consumerProfileUrl = payload.consumerProfileUrl;
      state.participantsDetail.contactNumber = payload.contactNumber;
      for (const field of payload.customField) {
        field.value =
          typeof field.value === "object" &&
          Object.keys(field.value).length === 0
            ? ""
            : field.value;
      }
      state.participantsDetail.customField = payload.customField;
      state.participantsDetail.email = payload.email;
      state.participantsDetail.enc = payload.enc;
      state.participantsDetail.name = payload.name;
      state.participantsDetail.productId = payload.productId;
      state.participantsDetail.productName = payload.productName;
      state.participantsDetail.createdAt = payload.createdAt;
      state.participantsDetail.serialNumber = payload.serialNumber;
      state.participantsDetail.status = payload.status;
    },

    resetWinnerList(state, action) {
      const { payload } = action;
      state.prizeListTable.data[payload.prizeIndex].winners[
        payload.winnerIndex
      ].serialNumber = null;
      state.prizeListTable.data[payload.prizeIndex].winners[
        payload.winnerIndex
      ].id = null;
    },

    setWinnerIntoPrize(state, action) {
      const { payload } = action;

      state.prizeListTable.data[payload.prizeIndex].winners[
        payload.winnerIndex
      ].serialNumber = payload.serialNumber;
      state.prizeListTable.data[payload.prizeIndex].winners[
        payload.winnerIndex
      ].participantId = payload.id;

      const temp = [];
      state.prizeListTable.data.forEach((prize) => {
        prize.winners.forEach((winner) => {
          if (winner.serialNumber) temp.push(winner.serialNumber);
        });
      });
      state.excludeList = temp;
    },
    resetExcludeList(state, action) {
      state.excludeList = [];
    },

    setParticipantSearch(state, action) {
      const { payload } = action;
      state.searchForParticipants = payload;
    },

    resetParticipantTable(state, action) {
      state.luckyDrawParticipantTable = initialState.luckyDrawParticipantTable;
    },

    setPrizeIsDrawed(state, action) {
      const { payload } = action;
      let liveDrawPrizes = state.liveDraw.prizes;

      const objIndex = liveDrawPrizes.findIndex((x) => x.id === payload);

      liveDrawPrizes[objIndex].isDrawed = true;

      state.liveDraw.prizes = liveDrawPrizes;
    },

    setLiveDraw(state, action) {
      const { payload } = action;
      const tempData = [];

      payload.data.forEach((element) => {
        const tempWinners = [];

        element.winners.forEach((item) => {
          tempWinners.push({
            name: item.ldParticipant.name,
            serialNumber: item.serialNumber,
            contactNumber: item.ldParticipant.contactNumber,
            id: item.id,
          });
        });

        const order = {
          id: element.id,
          order: element.order,
          title: element.title,
          name: element.name,
          image: element.imageUrl,
          quantity: element.quantity,
          drawType: element.type,
          winners: tempWinners,
          isDrawed: false,
        };

        tempData.push(order);
      });

      state.liveDraw.prizes = tempData;
    },
    updateLdPageSetting(state, action) {
      const { payload } = action;
      state.pageSetting = {
        ...state.pageSetting,
        ...payload,
      };
    },
  },
  extraReducers: {
    [fetchLuckyDrawListTable.fulfilled]: (state, action) => {
      state.isLoadingLuckyDrawError = false;
    },
    [fetchLuckyDrawListTable.rejected]: (state, action) => {
      state.isLoadingLuckyDrawError = true;
    },
    [fetchLuckyDrawParticipantTable.fulfilled]: (state, action) => {
      state.isLoadingLuckyDrawParticipantError = false;
    },
    [fetchLuckyDrawParticipantTable.rejected]: (state, action) => {
      state.isLoadingLuckyDrawParticipantError = true;
    },
    [fetchLuckyDrawParticipant.fulfilled]: (state, action) => {
      const { meta, payload } = action;

      state.participantList.recordsTotal = payload.recordsTotal;
      state.participantList.recordsFiltered = payload.recordsFiltered;

      let temp = [];

      payload.data.forEach((participant) => {
        temp.push({
          serialNumber: participant.serialNumber,
          id: participant.id,
          name: participant.name,
          contactNumber: participant.contactNumber,
        });
      });

      if (meta.arg.start === 0) {
        state.participantList.data = temp;
      } else {
        let data = state.participant.data;
        state.participant.data = data.concat(temp);
      }
    },
    [changeLuckyDrawStatus.fulfilled]: (state, action) => {
      const { payload } = action;

      const index = state.luckyDrawListTable.data.findIndex(
        (response) => response.id === payload.id
      );
      if (index > -1) {
        state.luckyDrawListTable.data[index].status =
          payload.status === "active" ? true : false;
      }
    },
  },
});

export const luckyDrawReducer = luckyDrawSlice.reducer;
export const {
  setLuckyDrawList,
  setLuckyDrawDetail,
  setLuckyDrawTNC,
  setLuckyDrawDetailFromApi,
  setLuckyDrawID,
  setProgress,
  setLuckyDrawTitle,
  setLuckyDrawDetailUpdate,
  setRegisterFormEdit,
  setRegisterFormFinishEdit,
  setLuckyDrawNewField,
  setParticipantRegistrationForm,
  setRegisterFormDelete,
  setRegisterFormChoiceDelete,
  setRegisterForm,
  setPrizeList,
  setPrizeListTable,
  setPrizeEdit,
  setPrizeFinishEdit,
  setPrizeListDelete,
  setCancelEdit,
  setType,
  closeLuckyDrawPrizeDialog,
  resetLuckyDrawDetail,
  resetRegisterForm,
  resetPrizeList,
  resetRegisterFormType,
  reorderRegisterFormField,
  setLuckyDrawParticipant,
  setParticipantDetail,
  setWinnerIntoPrize,
  setParticipantSearch,
  resetParticipantTable,
  setPrizeIsDrawed,
  setLiveDraw,
  setLuckyDrawParticipantTable,
  resetWinnerList,
  resetExcludeList,
  updateLdPageSetting,
} = luckyDrawSlice.actions;
