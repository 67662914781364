import { createSelector } from "@reduxjs/toolkit";
import { NODE_TYPE_BRANCH, NODE_TYPE_COMPANY } from "modules/branch/constants";

export const selectNoCompanyBranchList = createSelector(
    state => state.branch,
    branch => branch.branchList.filter(item => { return item.remarks !== NODE_TYPE_COMPANY })
);

export const selectParentBranchList = createSelector(
    state => state.branch,
    branch => branch.branchDropdown.filter(item => { return item.remarks !== NODE_TYPE_BRANCH })
);

export const selectParentBranchListV2 = createSelector(
  state => state.branch,
  branch => branch.branchDropdown
)

export const hqSelectParentBranchList = createSelector(
    state => state.branch,
    branch => branch.fetchEditBranchDropdown.filter(item => { return item.remarks !== NODE_TYPE_BRANCH })
);

export const hqSelectParentBranchListV2 = createSelector(
  state => state.branch,
  branch => branch.fetchEditBranchDropdown,
);

export const selectNoCompanyBranchDropdown = createSelector(
    state => state.branch,
    branch => branch.branchDropdown.filter(item => { return item.remarks !== NODE_TYPE_COMPANY })
)
