import axios from "axios";

const updateProductStatus = ({ id, status }) => {
    return new Promise((resolve, reject) => {
        axios
            .post(`/serialnumber/api/v1/product/update-status/${id}`, {
                status
            })
            .then((response) => resolve(response.data))
            .catch((error) => reject(error.response.data));
    });
};

export default updateProductStatus;
