import React from "react"
import {
  Box,
  makeStyles,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  TableSortLabel,
  Link,
  Paper,
  TableCell,
} from "@material-ui/core"
import { TablePaginationActions } from "components/tablePagination"
import RefreshTable from "components/table/refreshTable"
import DefaultProfile from "assets/img/defaultPhoto.png"
import { ROOT } from "modules/stockflow/config/stockflow.route"
import { getLang } from "app/feature/constants"
import { REPORTING_DRILLDOWN_DEALERSHIP_BY_TYPE } from "modules/reporting"
import { fontTextStyle, tableStyle } from "modules/reporting/styles"
import { TableCellLoading } from "modules/reporting/components/skeleton/loading"

// const API_HOST = process.env.REACT_APP_API_HOST_URL

const useStyle = makeStyles((theme) => ({
  emptyContainer: {
    backgroundColor: theme.palette.background.paper,
    textAlign: "center",
  },
  button: {
    display: "flex",
    alignItems: "center",
    padding: 0,
    minWidth: 0,
    marginLeft: theme.spacing(1),
  },
  productPhoto: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #E4E7EC",
    width: "30px",
    height: "30px",
    marginRight: "10px", 
    "& img": {
      height: "30px",
      width: "30px",
    },
  },
  actionContainer: {
    justifyContent: "center",
  },
  paperRoot: {
    width: "300px",
  },
  selectAll: {
    '&.Mui-checked': {
      color: "#FDB022",
    },
  },
  selectOne: {
    '&.Mui-checked': {
      color: "#6AAF68",
    },
  },
  activeText: {
    color: "#32D583",
  },
  suspendText: {
    color: "#F97066",
  },
  profile: {
    height: 26,
    width: 26,
    minWidth: 26,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& img": {
      height: "100%",
      minHeight: "100%",
      minWidth: "100%",
      objectFit: "cover",
      borderRadius: "50%",
      border: "1px solid #D0D5DD",
    }
  },
  ellipsisText: {
    maxWidth: "18em",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: "0.75rem"
  },
}))

export default function DealerInvitedListTableComponent({
  hasStockflowDealerViewAccessRight,
  hasBranchViewAccessRight,
  rows,
  isFetching,
  isError,
  handleReload,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
  totalFiltered,
  paginationOptions,
  tableFilter,
  changeOrder,
  lang,
  handleViewDetail
}) {
  const classes = useStyle()
  const fontClasses = fontTextStyle();
  const tableClasses = tableStyle();

  const columns = [
    {
        title: getLang(lang, "label.DEALER"),
        field: null,
        render: (row) => {
            return (
                <>{row.title}</>
            )
        },
        align: "left",
        size: "small"
    },
    {
        title: getLang(lang, "label.BRANCH"),
        field: null,
        render: (row) => {
            return (
                <>{row.title}</>
            )
        },
        align: "left",
        size: "small"
    },
    {
        title: getLang(lang, "label.DATE_JOINED"),
        field: "created_at",
        render: (row) => {
            return (
                <>{row.title}</>
            )
        },
        size: "small",
        align: "left"
    }
  ];

  return (
    <>
      {!isFetching && isError && <RefreshTable handleReload={handleReload} />}
      {isFetching || rows.length > 0 ? (
        <Box>
          <TableContainer component={ Paper }>
            <Box style={{
              overflowX: "auto",
              width: "100%"
            }}>
              <Table>
                <TableHead>
                  <TableRow hover>
                    {columns.map((column, index) => (
                      <TableCell className={[tableClasses.table__cell, fontClasses.text__header_table_cell]} key={index} size={column.size}>
                        {
                          column.field ? 
                          (
                              <TableSortLabel
                                  active={tableFilter.orderBy === column.field}
                                  direction={tableFilter.order}
                                  onClick={() => {
                                    const differentTab = tableFilter.orderBy !== column.field
                                    const order = differentTab ? tableFilter.order : tableFilter.order === 'asc' ? "desc" : "asc"
                                    changeOrder(order, column.field)
                                  }}
                              >
                                  {column.render(column)}
                              </TableSortLabel>
                          ) : 
                          column.render(column)
                        }
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isFetching
                    ? [...new Array(5)].map((v, index) => (
                      <TableRow>
                        <TableCellLoading />
                      </TableRow>
                    ))
                    : rows.map((row, index) => {
                      return (
                        <TableRow
                          key={row.id}
                          hover
                          tabIndex={-1}
                        >
                          <TableCell className={tableClasses.table__cell}>
                            <Box display="flex" alignItems="center">
                              <Box className={classes.profile} mr={1} ml={0.5} position="relative" >
                                {!row.picture ? (
                                  <img src={DefaultProfile} alt="default profile" />
                                ) : (
                                  <img src={row.picture} alt={row.name} />
                                )}
                              </Box>
                              {hasStockflowDealerViewAccessRight ? (
                                <Link
                                  href={`${ROOT}/my-dealer/${row.uuid}`}
                                  color="secondary"
                                  onClick={(e) => {
                                    handleViewDetail(`${ROOT}/my-dealer/${row.uuid}`)
                                    e.preventDefault();
                                    return false;
                                  }}
                                >
                                  <Typography variant="caption">{row.name}</Typography>
                                </Link>
                              ) : (
                                <Typography variant="caption">{row.name}</Typography>
                              )}
                            </Box>
                          </TableCell>
                          <TableCell className={tableClasses.table__cell}>
                            {row.node ? (
                              hasBranchViewAccessRight ? (
                                <Link
                                  color="secondary"
                                  component="button"
                                  variant="caption"
                                  onClick={() => {
                                    window.open(`${REPORTING_DRILLDOWN_DEALERSHIP_BY_TYPE}?branchUuid=${row.node.uuid}`, "_blank");
                                  }}
                                >
                                  {row.node.name}
                                </Link>
                              ) : (
                                row.node.name
                              )
                            ) : "-"}
                          </TableCell>
                          <TableCell className={tableClasses.table__cell}>
                            <span>
                              {row.date_joined}
                            </span>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                </TableBody>
              </Table>
            </Box>
          </TableContainer>
          <TablePagination
            style={{
              backgroundColor: "#fff",
              boxShadow: "0px -1px 0px rgba(58, 77, 84, 0.15)",
            }}
            ActionsComponent={TablePaginationActions}
            rowsPerPageOptions={paginationOptions}
            component="div"
            count={totalFiltered}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage={getLang(lang, 'label.ROWS_PER_PAGE')}
            labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${getLang(lang, 'label.OF')} ${count}`}
          />
        </Box>
      ) : null}
      {!isFetching && !isError && rows.length === 0 && (
        <Box>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={[tableClasses.table__cell, fontClasses.text__header_table_cell]} size="medium">
                    {getLang(lang, "label.DEALER")}
                  </TableCell>
                  <TableCell className={[tableClasses.table__cell, fontClasses.text__header_table_cell]} size="medium">
                    {getLang(lang, "label.BRANCH")}
                  </TableCell>
                  <TableCell className={[tableClasses.table__cell, fontClasses.text__header_table_cell]} size="medium">
                    {getLang(lang, "label.DATE_JOINED")}
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>
          <Box py={9} className={classes.emptyContainer}>
            <Typography variant="body1" style={{ paddingBottom: "16px", fontWeight: "bold" }}>
              {getLang(lang, "label.DEALER_INVITED_NOT_FOUND")}
            </Typography>
            <Typography variant="subtitle2" style={{ paddingBottom: "16px", color: "#98A2B3" }}>
              {getLang(lang, "paragraph.NO_DATA_ON_SELECTED_FILTER")}
            </Typography>
          </Box>
        </Box>
      )}
    </>
  )
}
