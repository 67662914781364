import axios from "axios";

const endpoint_url = "/campaignV2/customer/instantLuckyDraw/participants";

const get = (parameters) => {
  return new Promise((resolve, reject) => {
    axios
      .get(endpoint_url, {
        params: {
          limit: parameters.length,
          skip: parameters.start,
          sortBy: parameters.column,
          orderBy: parameters.order,
          search: parameters.search,
          instantId: parameters.instantId,
        },
      })
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

const getAllParticipant = {
  get,
};

export default getAllParticipant;
