import React, { useEffect, useState } from "react";
import BatchComponent from "./batch.page";
import { connect, useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  changeBatchQuickEdit,
  getGenerateBatch,
  updateBatchAppAuthorizeStatus,
  updateBatchScanVerifyStatus,
} from "../../redux";
import { selectLoading } from "modules/notification";
import {
  SERIAL_NUMBER_BATCH_AUTHENTICATOR_EDIT,
  SERIAL_NUMBER_BATCH_VERIFICATION_EDIT,
  SERIAL_NUMBER_EDIT,
  SERIAL_NUMBER_GENERATE,
} from "lib/constants/accessRights";
import { generatePaginationOptions } from "lib/helper";
import ConfirmationDialog from "components/dialog/confirmation";
import ExportExcelDialog from "modules/serial-number/components/dialog/exportExcelDialog";
import { getLang } from "app/feature/constants";

const API_HOST = process.env.REACT_APP_API_HOST_URL;

function BatchContainer({
  isFetching,
  generateBatchData,
  length,
  filteredlength,
  getGenerateBatch,
  updateBatchAppAuthorizeStatus,
  updateBatchScanVerifyStatus,
  isStatusUpdating,
  isVerifyStatusUpdating,
  token,
}) {
  const dispatch = useDispatch();
  const hasSerialNumberGenerateAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(SERIAL_NUMBER_GENERATE)
  );
  const lang = useSelector((state) => state.constant.languages);
  const language = useSelector((state) => state.profile.language);
  const hasSerialNumberGenerateEditRight = useSelector((state) =>
    state.profile.accessRights.includes(SERIAL_NUMBER_EDIT)
  );
  const hasBatchVerificationEditRight = useSelector((state) =>
    state.profile.accessRights.includes(SERIAL_NUMBER_BATCH_VERIFICATION_EDIT)
  );
  const hasBatchAuthenticatorEditRight = useSelector((state) =>
    state.profile.accessRights.includes(SERIAL_NUMBER_BATCH_AUTHENTICATOR_EDIT)
  );
  const isLoading = useSelector((state) =>
    selectLoading(state, getGenerateBatch.typePrefix)
  );
  const isSearchResult = generateBatchData.length < length;
  const [paginationOptions, setPaginationOptions] = useState([]);
  const [isDeactivateDialogOpen, setIsDeactivateDialogOpen] = useState(false);
  const [isActivateDialogOpen, setIsActivateDialogOpen] = useState(false);

  const [isVerifyDeactivateDialogOpen, setIsVerifyDeactivateDialogOpen] =
    useState(false);
  const [isVerifyActivateDialogOpen, setIsVerifyActivateDialogOpen] =
    useState(false);

  const [changeStatusTargetId, setChangeStatusTargetId] = useState("");
  const [selectedBatch, setSelectedBatch] = useState(null);
  const [showExportDialog, setShowExportDialog] = useState(false);
  const [tableFilter, setTableFilter] = useState({
    rowsPerPage: 25,
    page: 0,
    order: "desc",
    column: "created_at",
    search: "",
    searchColumn: "batch_number",
    node_uuids: "",
    branch: [],
  });

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableFilter]);

  useEffect(() => {
    setPaginationOptions(generatePaginationOptions(length));
  }, [length]);

  const getData = () => {
    getGenerateBatch({
      length: tableFilter.rowsPerPage,
      start: tableFilter.rowsPerPage * tableFilter.page,
      search: tableFilter.search,
      search_column: tableFilter.searchColumn,
      order: tableFilter.order,
      column: tableFilter.column,
      node_uuids: tableFilter.node_uuids,
    });
  };

  const handleChangePage = (event, newPage) => {
    let newData = {
      ...tableFilter,
      page: newPage,
    };
    setTableFilter(newData);
  };

  const handleChangeRowsPerPage = (event) => {
    let newData = {
      ...tableFilter,
      page: 0,
      rowsPerPage: event.target.value,
    };
    setTableFilter(newData);
  };

  const handleSearch = (values) => {
    let tempBranch = values.branch.map((branch) => branch.node_id);
    let newData = {
      ...tableFilter,
      page: 0,
      search: values.search,
      searchColumn: values.searchColumn,
      node_uuids: tempBranch.join(","),
      branch: values.branch,
    };
    setTableFilter(newData);
  };

  const clearSearch = () => {
    let newData = {
      ...tableFilter,
      page: 0,
      search: "",
      searchColumn: "batch_number",
      node_uuids: [],
      branch: [],
    };
    setTableFilter(newData);
  };

  const handleAppAuthorizeStatusChange = ({ currentStatus, uuid }) => {
    if (hasSerialNumberGenerateEditRight) {
      setChangeStatusTargetId(uuid);
      if (currentStatus) {
        setIsDeactivateDialogOpen(true);
      } else {
        setIsActivateDialogOpen(true);
      }
    }
  };

  const handleScanVerifyStatusChange = ({ currentStatus, uuid }) => {
    if (hasSerialNumberGenerateEditRight) {
      setChangeStatusTargetId(uuid);
      if (currentStatus) {
        setIsVerifyDeactivateDialogOpen(true);
      } else {
        setIsVerifyActivateDialogOpen(true);
      }
    }
  };

  const handleAppAuthorizeStatusSubmit = (status) => {
    if (hasSerialNumberGenerateEditRight) {
      updateBatchAppAuthorizeStatus({
        uuid: changeStatusTargetId,
        status: status ? "enable" : "disable",
      })
        .then(unwrapResult)
        .finally(() => {
          setIsDeactivateDialogOpen(false);
          setIsActivateDialogOpen(false);
        });
    }
  };

  const handleScanVerifyStatusSubmit = (status) => {
    if (hasSerialNumberGenerateEditRight) {
      updateBatchScanVerifyStatus({
        uuid: changeStatusTargetId,
        status: status ? "enable" : "disable",
      })
        .then(unwrapResult)
        .finally(() => {
          setIsVerifyDeactivateDialogOpen(false);
          setIsVerifyActivateDialogOpen(false);
        });
    }
  };

  const selectBatch = (batch) => {
    setSelectedBatch(batch);
    setShowExportDialog(true);
  };

  const exportFile = (format, fields, fromSeqNumber, toSeqNumber) => {
    window.open(
      `${API_HOST}/serialnumber/export/v1/serialnumber/${selectedBatch.batchNumber}?format=${format}&fields=${fields}&token=${token}&fromSeqNumber=${fromSeqNumber}&toSeqNumber=${toSeqNumber}`,
      "_blank"
    );
    setShowExportDialog(false);
    setSelectedBatch(null);
  };

  const setQuickEdit = (index, isEdit) => {
    dispatch(changeBatchQuickEdit({ index, isEdit }));
  };

  return (
    <>
      <BatchComponent
        hasSerialNumberGenerateAccessRight={hasSerialNumberGenerateAccessRight}
        generateBatchData={generateBatchData}
        isFetching={isFetching}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        isLoading={isLoading}
        length={filteredlength}
        handleSearch={handleSearch}
        handleReload={getData}
        language={language}
        paginationOptions={paginationOptions}
        handleAppAuthorizeStatusChange={handleAppAuthorizeStatusChange}
        handleScanVerifyStatusChange={handleScanVerifyStatusChange}
        lang={lang}
        setSelectedBatch={selectBatch}
        tableFilter={tableFilter}
        clearSearch={clearSearch}
        isSearchResult={isSearchResult}
        setQuickEdit={setQuickEdit}
        hasBatchVerificationEditRight={hasBatchVerificationEditRight}
        hasBatchAuthenticatorEditRight={hasBatchAuthenticatorEditRight}
      />
      <ConfirmationDialog
        isOpen={isDeactivateDialogOpen}
        handleClose={() => setIsDeactivateDialogOpen(false)}
        handleProceed={() => handleAppAuthorizeStatusSubmit(false)}
        isLoading={isStatusUpdating}
        type={"danger"}
        description={getLang(lang, "paragraph.DEACTIVATE_APP_AUTHENTICATOR")}
        title={getLang(lang, "label.CONFIRMATION_NEEDED")}
      />
      <ConfirmationDialog
        isOpen={isActivateDialogOpen}
        handleClose={() => setIsActivateDialogOpen(false)}
        handleProceed={() => handleAppAuthorizeStatusSubmit(true)}
        isLoading={isStatusUpdating}
        description={getLang(lang, "paragraph.ACTIVATE_APP_AUTHENTICATOR")}
        title={getLang(lang, "label.CONFIRMATION_NEEDED")}
        lang={lang}
      />
      <ConfirmationDialog
        isOpen={isVerifyDeactivateDialogOpen}
        handleClose={() => setIsVerifyDeactivateDialogOpen(false)}
        handleProceed={() => handleScanVerifyStatusSubmit(false)}
        isLoading={isVerifyStatusUpdating}
        type={"danger"}
        description={getLang(lang, "paragraph.DEACTIVATE_SCAN_VERIFY")}
        title={getLang(lang, "label.CONFIRMATION_NEEDED")}
      />
      <ConfirmationDialog
        isOpen={isVerifyActivateDialogOpen}
        handleClose={() => setIsVerifyActivateDialogOpen(false)}
        handleProceed={() => handleScanVerifyStatusSubmit(true)}
        isLoading={isVerifyStatusUpdating}
        description={getLang(lang, "paragraph.ACTIVATE_SCAN_VERIFY")}
        title={getLang(lang, "label.CONFIRMATION_NEEDED")}
        lang={lang}
      />
      <ExportExcelDialog
        isOpen={showExportDialog}
        handleClose={() => {
          setShowExportDialog(false);
          setSelectedBatch(null);
        }}
        exportFile={exportFile}
        batch={selectedBatch}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  generateBatchData: state.serialNumberBatch.SerialNumberBatchTable.data,
  length: state.serialNumberBatch.SerialNumberBatchTable.totalRecords,
  filteredlength:
    state.serialNumberBatch.SerialNumberBatchTable.filteredTotalRecords,
  isFetching: selectLoading(state, getGenerateBatch.typePrefix),
  isStatusUpdating: selectLoading(
    state,
    updateBatchAppAuthorizeStatus.typePrefix
  ),
  isVerifyStatusUpdating: selectLoading(
    state,
    updateBatchScanVerifyStatus.typePrefix
  ),
  token: state.session.accessToken,
});

const mapDispatchToProps = (dispatch) => ({
  getGenerateBatch: (pageSetting) => dispatch(getGenerateBatch(pageSetting)),
  updateBatchAppAuthorizeStatus: (statusData) =>
    dispatch(updateBatchAppAuthorizeStatus(statusData)),
  updateBatchScanVerifyStatus: (statusData) =>
    dispatch(updateBatchScanVerifyStatus(statusData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BatchContainer);
