import { getPlan } from "../slice/stripePlan.slice";

const getPlanMiddleware = () => next => async action => {
  const { type, payload } = action;

  if (type === getPlan.fulfilled.type) {
    const plans = payload.data.data.filter(plan => plan.active === true);
    const planNames = plans.map(plan => plan.product.name);
    const sortedPlanNames = planNames.filter(
      (n, i) => planNames.indexOf(n) === i
    );
    const reversePlanNames = sortedPlanNames.reverse();

    next({ ...action, payload: { plans, planNames: reversePlanNames } });
  } else {
    next(action);
  }
};

export default [getPlanMiddleware];
