import { createSlice } from "@reduxjs/toolkit";
import {
  STOCKFLOWCHECKOUT,
  getCheckoutBoxList,
  getCheckoutByDropdown,
  getCheckoutDeliveryInfo,
  getCheckoutDetail,
  getCheckoutList,
  getCheckoutProductDetail,
  getCheckoutProductList,
} from "../action/stockflowCheckout.action";

const initialState = {
  checkoutList: {
    list: [],
    totalFiltered: 0,
    totalRecords: 0,
    isError: false,
  },
  checkout: null,
  productList: {
    list: [],
    totalFiltered: 0,
    totalRecords: 0,
    isError: false,
    hasNextPage: true,
  },
  boxList: {
    list: [],
    totalFiltered: 0,
    totalRecords: 0,
    isError: false,
    hasNextPage: true,
  },
  productSnList: {
    list: [],
    totalFiltered: 0,
    totalRecords: 0,
    isError: false,
    hasNextPage: true,
  },
  isLoadingCheckoutError: false,
  checkoutInitiatorDropdown: {
    list: [],
    totalFiltered: 0,
    totalRecords: 0,
    isError: false,
    hasNextPage: true,
  },
  deliveryInfo: {
    list: [],
    isLoading: false,
    isError: false,
  },
};

const stockflowCheckoutSlice = createSlice({
  name: STOCKFLOWCHECKOUT,
  initialState,
  reducers: {
    resetCheckoutListing(state) {
      state.checkoutList = initialState.checkoutList;
      state.checkoutInitiatorDropdown = initialState.checkoutInitiatorDropdown;
    },
    resetCheckout(state) {
      state.checkout = initialState.checkout;
      state.productList = initialState.productList;
      state.boxList = initialState.boxList;
      state.productSnList = initialState.productSnList;
      state.deliveryInfo = initialState.deliveryInfo;
    },
    resetProductSnList(state) {
      state.productSnList = initialState.productSnList;
    },
    resetCheckoutByDropdown(state) {
      state.checkoutInitiatorDropdown = initialState.checkoutInitiatorDropdown;
    },
    updateCheckoutInfo(state, action) {
      state.checkout = {
        ...state.checkout,
        ...action.payload,
      };
    },
  },
  extraReducers: {
    [getCheckoutList.pending]: (state) => {
      state.checkoutList = initialState.checkoutList;
    },
    [getCheckoutList.fulfilled]: (state, action) => {
      const { payload } = action;
      state.checkoutList.list = payload.data;
      state.checkoutList.totalRecords = payload.recordsTotal;
      state.checkoutList.totalFiltered = payload.recordsFiltered;
    },
    [getCheckoutList.rejected]: (state) => {
      state.checkoutList.isError = true;
    },
    [getCheckoutDetail.pending]: (state) => {
      state.checkout = initialState.checkout;
      state.isLoadingCheckoutError = false;
    },
    [getCheckoutDetail.fulfilled]: (state, action) => {
      state.checkout = action.payload;
    },
    [getCheckoutDetail.rejected]: (state) => {
      state.isLoadingCheckoutError = true;
    },
    [getCheckoutByDropdown.pending]: (state) => {
      state.checkoutInitiatorDropdown.isError = false;
    },
    [getCheckoutByDropdown.fulfilled]: (state, action) => {
      const { payload } = action;
      if (state.checkoutInitiatorDropdown.hasNextPage) {
        state.checkoutInitiatorDropdown.list = [
          ...state.checkoutInitiatorDropdown.list,
          ...payload.data,
        ];
      }
      state.checkoutInitiatorDropdown.totalRecords = payload.recordsTotal;
      state.checkoutInitiatorDropdown.totalFiltered = payload.recordsFiltered;
      state.checkoutInitiatorDropdown.hasNextPage =
        state.checkoutInitiatorDropdown.list.length <
        state.checkoutInitiatorDropdown.totalFiltered;
    },
    [getCheckoutByDropdown.rejected]: (state) => {
      state.checkoutInitiatorDropdown.isError = true;
    },
    [getCheckoutBoxList.pending]: (state) => {
      state.boxList.isError = false;
    },
    [getCheckoutBoxList.fulfilled]: (state, action) => {
      const { payload } = action;
      if (state.boxList.hasNextPage) {
        state.boxList.list = [...state.boxList.list, ...payload.data];
      }
      state.boxList.totalRecords = payload.recordsTotal;
      state.boxList.totalFiltered = payload.recordsFiltered;
      state.boxList.hasNextPage =
        state.boxList.list.length < state.boxList.totalFiltered;
    },
    [getCheckoutBoxList.rejected]: (state) => {
      state.boxList.isError = true;
    },
    [getCheckoutProductList.pending]: (state) => {
      state.productList.isError = false;
    },
    [getCheckoutProductList.fulfilled]: (state, action) => {
      const { payload } = action;
      if (state.productList.hasNextPage) {
        state.productList.list = [...state.productList.list, ...payload.data];
      }
      state.productList.totalRecords = payload.recordsTotal;
      state.productList.totalFiltered = payload.recordsFiltered;
      state.productList.hasNextPage =
        state.productList.list.length < state.productList.totalFiltered;
    },
    [getCheckoutProductList.rejected]: (state) => {
      state.productList.isError = true;
    },
    [getCheckoutProductDetail.pending]: (state) => {
      state.productSnList.isError = false;
    },
    [getCheckoutProductDetail.fulfilled]: (state, action) => {
      const { payload } = action;
      if (state.productSnList.hasNextPage) {
        state.productSnList.list = [
          ...state.productSnList.list,
          ...payload.data,
        ];
      }
      state.productSnList.totalRecords = payload.recordsTotal;
      state.productSnList.totalFiltered = payload.recordsFiltered;
      state.productSnList.hasNextPage =
        state.productSnList.list.length < payload.recordsFiltered;
    },
    [getCheckoutProductDetail.rejected]: (state) => {
      state.productSnList.isError = true;
    },
    [getCheckoutDeliveryInfo.pending]: (state) => {
      state.deliveryInfo.isLoading = true;
      state.deliveryInfo.list = [];
      state.deliveryInfo.isError = false;
    },
    [getCheckoutDeliveryInfo.fulfilled]: (state, action) => {
      state.deliveryInfo.isLoading = false;
      state.deliveryInfo.list = action.payload;
    },
    [getCheckoutDeliveryInfo.rejected]: (state) => {
      state.deliveryInfo.isLoading = false;
      state.deliveryInfo.isError = true;
    },
  },
});

export const {
  resetCheckoutListing,
  resetCheckout,
  resetProductSnList,
  resetCheckoutByDropdown,
  updateCheckoutInfo,
} = stockflowCheckoutSlice.actions;

export const stockflowCheckoutReducer = stockflowCheckoutSlice.reducer;
