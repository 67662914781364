import React, { useEffect, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectLoading } from "modules/notification";
import TrendingSummaryPanelComponent from "./trendingSummaryPanel.component";
import {
  getConsumerRegisteredSummary,
  getConsumerScannedSummary,
  getCounterfeitReportSummary,
  getFirstScanSummary,
  getSuspendSnSummary,
  getUniqueConsumerScannedSummary,
} from "modules/dashboard/redux/action/dashboard.action";
import moment from "moment";

function TrendingSummaryPanelContainer({ dateRange, isToday, isMonth, showPopup }) {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.constant.languages);
  const summaryCard = useSelector((state) => state.dashboard.summaryCard);

  const isFetchingConsumerScanned = useSelector((state) =>
    selectLoading(state, getConsumerScannedSummary.typePrefix)
  );
  const isFetchingSuspendSn = useSelector((state) =>
    selectLoading(state, getSuspendSnSummary.typePrefix)
  );
  const isFetchingCounterfeitReport = useSelector((state) =>
    selectLoading(state, getCounterfeitReportSummary.typePrefix)
  );
  const isFetchingFirstScan = useSelector((state) =>
    selectLoading(state, getFirstScanSummary.typePrefix)
  );
  const isFetchingUniqueConsumerScanned = useSelector((state) =>
    selectLoading(state, getUniqueConsumerScannedSummary.typePrefix)
  );
  const isFetchingConsumerRegistered = useSelector((state) =>
    selectLoading(state, getConsumerRegisteredSummary.typePrefix)
  );

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange]);

  const getData = () => {
    let param = {
      start_date: moment(dateRange[0]).format("YYYY-MM-DD"),
      end_date: moment(dateRange[1]).format("YYYY-MM-DD"),
      is_today: isToday ? 1 : 0,
      is_month: isMonth ? 1 : 0,
    };
    dispatch(getConsumerScannedSummary(param));
    dispatch(getSuspendSnSummary(param));
    dispatch(getCounterfeitReportSummary(param));
    dispatch(getFirstScanSummary(param));
    dispatch(getUniqueConsumerScannedSummary(param));
    dispatch(getConsumerRegisteredSummary(param));
  };

  return (
    <TrendingSummaryPanelComponent
      handleReload={getData}
      lang={lang}
      startDate={moment(dateRange[0]).format("YYYY-MM-DD")}
      endDate={moment(dateRange[1]).format("YYYY-MM-DD")}
      list={summaryCard}
      loading={{
        consumerScanned: isFetchingConsumerScanned,
        suspendedSN: isFetchingSuspendSn,
        counterfeitReports: isFetchingCounterfeitReport,
        firstScan: isFetchingFirstScan,
        uniqueConsumerScanned: isFetchingUniqueConsumerScanned,
        newConsumer: isFetchingConsumerRegistered,
      }}
      showPopup={showPopup}
    />
  );
}

export default memo(TrendingSummaryPanelContainer);
