import React, { useState, useEffect } from "react";
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { fetchRespondentQuestionListing, fetchRespondentAnswerListing, fetchResponseByChoiceCount } from "modules/survey/redux/action";
import { setSelectedQuestion } from "modules/survey/redux/slice"
import { selectLoading } from 'modules/notification';
import SurveyResultPanelComponent from './surveyResultPanel.component';
import { addAlert } from "modules/notification";
import { getLang } from "app/feature/constants";

const SurveyResultPanelContainer = () => {
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const surveyUuid = match.params.surveyUuid;
  const isQuestionFetching = useSelector(state => selectLoading(state, fetchRespondentQuestionListing.typePrefix))
  const isAnswerFetching = useSelector(state => selectLoading(state, fetchRespondentAnswerListing.typePrefix))
  const questionList = useSelector(state => state.survey.questionList);
  const selectedQuestion = useSelector(state => state.survey.selectedQuestion);
  const answerList = useSelector(state => state.survey.answerList);
  const choicesCount = useSelector(state => state.survey.surveyAnalytic.choicesCount)
  const [isShowAnswer, setIsShowAnswer] = useState(false);
  const [search, setSearch] = useState("");
  const [productSelect, setProductSelect] = useState("");
  const [isAnswerFilter, setIsAnswerFilter] = useState(false);
  const [questionUUID, setQuestionUUID] = useState("");
  const lang = useSelector(state => state.constant.languages);
  const language = useSelector(state => state.profile.language);
  const CONSUMER_HOST = process.env.REACT_APP_CONSUMER_PORTAL_URL

  function copyLink() {
    navigator.clipboard.writeText(`${CONSUMER_HOST}/survey/${surveyUuid}`)
    dispatch(addAlert({ severity: "success", message: getLang(lang, 'message.success.SUCCESS_COPY_LINK_TO_CLIPBOARD')}))
  }

  useEffect(() => {
    dispatch(fetchRespondentQuestionListing({ uuid: surveyUuid }))
      .then(unwrapResult)
      .then((response) => {
        setQuestionUUID(response.data.questions_answers[0].id)
        dispatch(fetchRespondentAnswerListing({
          survey_uuid: surveyUuid,
          question_uuid: response.data.questions_answers[0].id,
          search: search,
          productSelect: productSelect,
          start: 0
        }))

        if (response.data.questions_answers[0].type === "select" || response.data.questions_answers[0].type === "dropdownlist" ) {
          dispatch(fetchResponseByChoiceCount({
            surveyUuid: surveyUuid,
            questionUuid: response.data.questions_answers[0].id,
          }))
        }
      })
  }, [dispatch, surveyUuid, search, productSelect])

  const handleSelectedQuestion = (question, index) => {
    const payload = {
      ...question,
      index
    };
    setQuestionUUID(question.id)
    dispatch(setSelectedQuestion(payload));
    dispatch(fetchRespondentAnswerListing({
      survey_uuid: surveyUuid,
      question_uuid: question.id,
      search: search,
      productSelect: productSelect,
      start: 0
    }))

    if (question.type === "select" || question.type === "dropdownlist") {
      dispatch(fetchResponseByChoiceCount({
        surveyUuid: surveyUuid,
        questionUuid: question.id,
      }))
    }
  }
  
  const handleFetchMoreAnswerListing = () => {
    dispatch(fetchRespondentAnswerListing({
      survey_uuid: surveyUuid,
      question_uuid: questionUUID,
      search: search,
      productSelect: productSelect,
      start: answerList.data.length
    }))
  }

  const handleShowAnswer = (value) => {
    setIsShowAnswer(value)
  }

  const handleSearch = value => {
    setSearch(value.search);
    setProductSelect(value.productSelect);
  }

  const handleFilter = value => {
    setIsAnswerFilter(value)
  }

  return (
    <SurveyResultPanelComponent
      data={questionList}
      selectedQuestion={selectedQuestion}
      handleSelectedQuestion={handleSelectedQuestion}
      isShowAnswer={isShowAnswer}
      handleShowAnswer={handleShowAnswer}
      isQuestionFetching={isQuestionFetching}
      language={language}
      totalResponse={answerList.recordTotal}
      totalAnswer={answerList.recordsAnswer}
      totalSkip={answerList.recordsSkip}
      totalFilter={answerList.recordsFiltered}
      answerList={answerList.data}
      handleSearch={handleSearch}
      isAnswerFetching={isAnswerFetching}
      handleFilter={handleFilter}
      isAnswerFilter={isAnswerFilter}
      choicesCount={choicesCount}
      handleFetchMoreAnswerListing={handleFetchMoreAnswerListing}
      copyLink={copyLink}
    />
  )
}

export default SurveyResultPanelContainer