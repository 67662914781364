import axios from "axios";

// reset password
export function resetPassword({
  email,
  resetPasswordToken,
  password,
  confirmPassword
}) {
  return new Promise((resolve, reject) => {
    axios.post("distributor/public/api/v1/auth/reset-password", {
      email: email,
      reset_password_token: resetPasswordToken,
      password: password,
      confirm_password: confirmPassword
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data))
  })
}