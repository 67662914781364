import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { ROOT } from "./config/billing.route";
import { MainPage } from "./pages";

export default function Modulebilling() {
  return (
    <Switch>
      <Route exact path={ROOT} component={MainPage} />
      <Route path="*">
        <Redirect to="/admin/invalid" />
      </Route>
    </Switch>
  );
}

export { ROOT };
