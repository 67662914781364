import React from "react";
import {
  makeStyles,
  Typography,
  Popover,
  Box,
  Divider,
  MenuItem,
  MenuList, IconButton,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import DoneIcon from '@material-ui/icons/Done';
import ErrorIcon from '@material-ui/icons/Error';
import {getLang} from "../../../../../app/feature/constants";

const useStyle = makeStyles((theme) => ({
  button: {
    padding: 0,
    minWidth: 0
  },
  perfectScrollBar: {
    maxHeight: 200,
    overflow: "auto"
  },
  list: {
    paddingTop: 6,
    paddingBottom: 6
  }
}));

function LanguageSelectPanelComponent({
  open,
  anchorEl,
  handleClose,
  id,
  style,
  localisedLanguages,
  notLocalisedLanguages,
  handleNotLocalisedClick,
  handleLocalisedClick,
  handleLocalisedDelete,
  anchorOrigin,
  transformOrigin,
  PaperProps,
  lang,
  empty,
}) {
  const classes = useStyle();

  return (
    <Popover
      id={id}
      open={open}
      style={style}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      elevation={2}
      PaperProps={PaperProps}
    >
      <Box className={classes.perfectScrollBar}>
        <Box>
          <MenuList
            id='menu-list-grow'
            className={classes.list}
          >
            <MenuItem
              onClick={() => handleLocalisedClick("EN")}
            >
              <Box display="flex" alignItems="center">
                {empty.includes("EN") ? (
                  <ErrorIcon style={{ color: 'red' }}/>
                ) : (
                  <DoneIcon />
                )}
                <Typography variant='body1' noWrap style={{ paddingLeft: 8, paddingRight: 8 }}>
                  {getLang(lang, 'constant.language.EN')}
                </Typography>
              </Box>
            </MenuItem>
            {localisedLanguages.map((item) => (
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box flexGrow={1}>
                  <MenuItem
                    onClick={() => handleLocalisedClick(item)}
                  >
                    <Box display="flex" alignItems="center">
                      {empty.includes(item) ? (
                        <ErrorIcon style={{ color: 'red' }}/>
                      ) : (
                        <DoneIcon />
                      )}

                      <Typography variant='body1' noWrap style={{ paddingLeft: 8, paddingRight: 8 }}>
                        {getLang(lang, `constant.language.${item.toUpperCase()}`)}
                      </Typography>
                    </Box>
                  </MenuItem>
                </Box>
                <Box>
                  <IconButton
                    onClick={() => handleLocalisedDelete(item)}
                    style={{ padding: 0, color: 'red' }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Box>
            ))}
          </MenuList>
          <Box pb={1}>
            <Divider />
          </Box>
          <Typography variant="caption" style={{ paddingLeft: 24 }}>
            {getLang(lang, 'label.NOT_LOCALIZED')}
          </Typography>
          <MenuList
            id='menu-list-grow'
            className={classes.list}
          >
            {notLocalisedLanguages.map((item) => (
              <MenuItem
                onClick={() => handleNotLocalisedClick(item)}
              >
                <Box display="flex" alignItems="center">
                  <DoneIcon style={{color: "rgba(0, 0, 0, 0)"}} />
                  <Typography variant='body1' noWrap style={{ paddingLeft: 8, paddingRight: 32 }}>
                    {getLang(lang, `constant.language.${item.toUpperCase()}`)}
                  </Typography>
                </Box>
              </MenuItem>
            ))}
          </MenuList>
        </Box>
      </Box>
    </Popover>
  )
}

export default LanguageSelectPanelComponent
