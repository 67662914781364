import React, {useState} from "react";
import WarrantyClaimPanelComponent from "./warrantyClaimPanel.component";
import {useDispatch, useSelector} from "react-redux";
import ApproveClaimDialog from "../../dialog/approveClaimDialog";
import RejectClaimDialog from "../../dialog/rejectClaimDialog";
import {fetchWarranteeClaims, processWarranteeClaims} from "../../../../warranty/redux";
import {hideBackdrop, showBackdrop} from "../../../../backdrop";
import {selectLoading} from "../../../../notification";
import DisplayImageDialog from "../../dialog/displayImageDialog";
import {CLAIM_LIST_EDIT} from "../../../../../lib/constants/accessRights";

function WarrantyClaimPanelContainer() {
  const dispatch = useDispatch();
  const hasClaimListEditAccessRight = useSelector(state => state.profile.accessRights.includes(CLAIM_LIST_EDIT))
  const lang = useSelector(state => state.constant.languages);
  const language = useSelector(state => state.profile.language);
  const loading = useSelector(state => selectLoading(state, fetchWarranteeClaims.typePrefix));
  const rows = useSelector(state => state.warranty.warranteeClaimsTable.data);
  const count = useSelector(state => state.warranty.warranteeClaimsTable.totalRecords);
  const consumerWarrantyStatus = useSelector((state) => state.warranty.consumerWarrantyDetail.status);
  const claimReasonOptions = useSelector((state) => {
      const options = state.warranty.consumerWarrantyDetail.warranty.claimReasonOptions;
      if (!options) return {};
      let optionsKey = {};

      for (const option of options) {
        optionsKey = {...optionsKey, [option.id]: option.reason_name}
      }

      return optionsKey;
    }
  );
  const profileLang = useSelector((state) => state.profile.language);

  const [currentSelected, setCurrentSelected] = useState(0);
  const [isRejectDialogOpen, setIsRejectDialogOpen] = useState(false);
  const [isApproveDialogOpen, setIsApproveDialogOpen] = useState(false);
  const [displayImage, setDisplayImage] = useState(null);

  const handleItemClick = (index) => {
    setCurrentSelected(index);
  };

  const handleRejectClick = async (value) => {
    await dispatch(showBackdrop());
    await dispatch(processWarranteeClaims({
      index: currentSelected,
      id: rows[currentSelected],
      status: "rejected",
      reason: value.selected === "others" ? value.otherReason : value.selected,
    }));
    await dispatch(hideBackdrop());
    setIsRejectDialogOpen(false);
  }

  const handleApproveClick = async () => {
    await dispatch(showBackdrop());
    await dispatch(processWarranteeClaims({
      index: currentSelected,
      id: rows[currentSelected],
      status: "approved",
    }));
    await dispatch(hideBackdrop());
    setIsApproveDialogOpen(false);
  }

  return (
    <>
      <WarrantyClaimPanelComponent
        lang={lang}
        language={language}
        isFetching={loading}
        claims={rows}
        count={count}
        currentSelected={currentSelected}
        handleItemClick={handleItemClick}
        handleMediaClick={(src, type) => {
          setDisplayImage({src, type})
        }}
        onApproveClick={() => setIsApproveDialogOpen(true)}
        onRejectClick={() => setIsRejectDialogOpen(true)}
        consumerWarrantyStatus={consumerWarrantyStatus}
        claimReasonOptions={claimReasonOptions}
        profileLang={profileLang}
        hasClaimListEditAccessRight={hasClaimListEditAccessRight}
      />
      <ApproveClaimDialog
        isOpen={isApproveDialogOpen}
        handleConfirm={handleApproveClick}
        handleClose={() => setIsApproveDialogOpen(false)}
      />
      <RejectClaimDialog
        isOpen={isRejectDialogOpen}
        handleSubmit={handleRejectClick}
        handleClose={() => setIsRejectDialogOpen(false)}
      />
      <DisplayImageDialog
        isOpen={!!displayImage}
        handleClose={() => {setDisplayImage(null)}}
        src={!!displayImage ? displayImage.src : null}
        type={!!displayImage ? displayImage.type : null}
      />
    </>
  );
}

export default WarrantyClaimPanelContainer;
