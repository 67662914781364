import React, { useState, useRef, useEffect } from "react";
import BranchSelectComponent from "./branchSelect.component";
import BranchPanelComponent from "./branchPanel.component";
import PropTypes from "prop-types";
import { selectLoading } from "modules/notification";
import { useDispatch, useSelector } from "react-redux";
import { fetchBranchDropdown } from "modules/employee/redux";
import { createBranch, fetchBranchList } from "modules/branch/redux";
import BranchDialog from "modules/branch/components/dialog/branchDialog";
import Modal from "modules/branch/components/modal";
import cloneDeep from "lodash/cloneDeep";
import { unwrapResult } from "@reduxjs/toolkit";
import { BRANCH_ADD } from "lib/constants/accessRights";

function BranchSelectContainer({ placeholder, handleChange, value, disabled }) {
  const [open, setOpen] = useState(false);
  const [isOpenList] = useState(false);
  const anchorRef = useRef(null);
  const prevOpen = useRef(open);
  const prevOpenList = useRef(isOpenList);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const dispatch = useDispatch();
  const branchDropdown = useSelector((state) => state.employee.branchDropdown);
  const hasBranchAddAccessRight = useSelector(state => state.profile.accessRights.includes(BRANCH_ADD));
  const isBranchDropdownLoading = useSelector((state) =>
    selectLoading(state, fetchBranchDropdown.typePrefix)
  );
  const lang = useSelector(state => state.constant.languages);

  useEffect(() => {
    dispatch(fetchBranchDropdown());
  }, [dispatch]);

  useEffect(() => {
    let temp = cloneDeep(value)
    let isSelectAll = true
    for (const masterBranch of branchDropdown) {
      if( 
        !(
          temp.filter(t => t.node_id === masterBranch.node_id).length === 1 ||
          temp.filter(t => t.node_id === masterBranch.parent_node_id).length === 1 
        )
      ){
        isSelectAll = false
      }
    }
    setSelectAll(isSelectAll)
  }, [value, branchDropdown]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    if (prevOpenList.current === true && isOpenList === false) {
      anchorRef.current.focus();
    }

    prevOpenList.current = isOpenList;
  }, [isOpenList]);

  const handleSelectAll = async () => {
    let temp = cloneDeep(value)
    if( selectAll ){
      let branchChildren = []
      for( let i = 0; i < branchDropdown.length; i++ ){
        branchChildren = branchChildren.concat(getBranchChildren(branchDropdown[i]))
      }
      let branchChildrenNodeId = branchChildren.map( child => child.node_id )
      if( temp.filter( t => branchChildrenNodeId.includes(t.node_id) ) ){
        temp = temp.filter( t => !branchChildrenNodeId.includes(t.node_id) )
      }
      for (const masterBranch of branchDropdown) {
        temp = temp.filter( t => t.node_id !== masterBranch.node_id )
      }
    }else{
      let branchChildren = []
      for( let i = 0; i < branchDropdown.length; i++ ){
        branchChildren = branchChildren.concat(getBranchChildren(branchDropdown[i]))
      }
      let branchChildrenNodeId = branchChildren.map( child => child.node_id )
      if( temp.filter( t => branchChildrenNodeId.includes(t.node_id) ) ){
        temp = temp.filter( t => !branchChildrenNodeId.includes(t.node_id) )
      }
      for (const masterBranch of branchDropdown) {
        temp = temp.filter( t => t.node_id !== masterBranch.node_id )
        if( temp.filter( t => t.node_id === masterBranch.parent_node_id).length === 0  ){
          temp.push({
            name: masterBranch.name,
            node_id: masterBranch.node_id,
            parent_node_id: masterBranch.parent_node_id,
            remarks: masterBranch.remarks
          });
        }
      }
    }
    setSelectAll(!selectAll)
    handleChange(temp)
  };

  const handleSelectBranch = (branch) => {
    let temp = cloneDeep(value)
    if( temp.filter( t => t.node_id === branch.node_id ).length > 0 ){
      temp = temp.filter( t => t.node_id !== branch.node_id )
    }else{
      let branchChildren = getBranchChildren(branch)
      let branchChildrenNodeId = branchChildren.map( child => child.node_id )
      if( temp.filter( t => branchChildrenNodeId.includes(t.node_id) ) ){
        temp = temp.filter( t => !branchChildrenNodeId.includes(t.node_id) )
      }
      temp.push({
        name: branch.name,
        node_id: branch.node_id,
        parent_node_id: branch.parent_node_id,
        remarks: branch.remarks
      })
    }
    handleChange(temp)
  };

  const getBranchChildren = (branch) => {
    let branchChildren = []
    if(branch.child && branch.child.length > 0 ){
      branchChildren = branchChildren.concat(branch.child)
      for(let i = 0; i < branch.child.length; i++){
        if(branch.child[i].child && branch.child[i].child.length > 0){
          branchChildren = branchChildren.concat(getBranchChildren(branch.child[i]))
        }
      }
    }
    return branchChildren;
  }

  const handleAddBranch = async () => {
    await dispatch(
      fetchBranchList({
        start: 0,
        length: 20,
        search: ""
      })
    );
    setIsCreateModalOpen(true);
  };

  function closeCreateModal() {
    setIsCreateModalOpen(false);
  }

  function handleCreateSubmit(values, { setSubmitting }) {
    values.contact=values.contact.replace(/^0/,'');
    dispatch(createBranch(values))
      .then(unwrapResult)
      .then(async (response) => {
        closeCreateModal();
        dispatch(fetchBranchDropdown());
      })
      .catch((error) => {
        setSubmitting(false);
      });
  }

  return (
    <>
      <BranchSelectComponent
        anchorRef={anchorRef}
        handleClick={handleClick}
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        branchDropDown={branchDropdown}
        lang={lang}
      />
      <BranchPanelComponent
        hasBranchAddAccessRight={hasBranchAddAccessRight}
        selectAll={selectAll}
        value={value}
        anchorRef={anchorRef}
        open={open}
        isOpenList={isOpenList}
        handleClose={handleClose}
        isFetching={isBranchDropdownLoading}
        branchDropdown={branchDropdown}
        handleAddBranch={handleAddBranch}
        handleSelectAll={handleSelectAll}
        handleSelectBranch={handleSelectBranch}
        lang={lang}
      />
      <Modal isOpen={isCreateModalOpen} handleClose={closeCreateModal}>
        <BranchDialog
          isOpen={isCreateModalOpen}
          handleClose={closeCreateModal}
          handleSubmit={handleCreateSubmit}
        />
      </Modal>
    </>
  );
}
BranchSelectContainer.defaultProps = {
  placeholder: "Select Branch(s)"
};

BranchSelectContainer.propTypes = {
  placeholder: PropTypes.string
};

export default BranchSelectContainer;
