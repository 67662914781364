import React from "react"
import {
  Box,
  makeStyles,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Checkbox,
  Paper,
  TableSortLabel,
  Link,
} from "@material-ui/core"
import {
  StyledTableCell,
  StyledTableRow,
  StickyRightTableCell,
  StickyLeftTableCell,
} from "components/table"
import { SkeletonTableRowStickyLeftRight } from "components/skeleton/skeleton.component"
import { ActionButton } from "components/button"
import { TablePaginationActions } from "components/tablePagination"
import RefreshTable from "components/table/refreshTable"
import SelectAllCheckBox from 'components/selectAllCheckbox'
import clsx from "clsx"
import { ROOT } from "modules/stockflow/config/stockflow.route"
import moment from "moment"
import { KeyboardArrowDown } from "@material-ui/icons"
import DefaultImage from "assets/img/defaultPhoto.png"
import DefaultImg from "assets/img/img-default.png"
import { TIER_ICON_PROPS } from "modules/stockflow/constants"
import { getLang } from "app/feature/constants"
import "moment/min/locales.min"
import BranchIcon from "../../../../../components/icon/branchIcon";

const useStyle = makeStyles((theme) => ({
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    borderCollapse: "separate",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
    },
    "& th,td": {
      fontSize: ".75rem",
      borderRight: "1px solid rgba(58, 77, 84, 0.1)",
      padding: theme.spacing(1, 2, 1, 2),
    },
    "& th": {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightBold,
    },
    "& th:last-child, td:last-child": {
      width: 37,
      borderRight: "0px",
    },
    "& th th": {
      borderBottom: "none !important",
    },
    "& td td": {
      borderBottom: "none !important",
    },
  },
  emptyContainer: {
    backgroundColor: theme.palette.background.paper,
    textAlign: "center",
  },
  actionContainer: {
    justifyContent: "center",
  },
  paperRoot: {
    width: "300px",
  },
  selectAll: {
    '&.Mui-checked': {
      color: "#FDB022",
    },
  },
  selectOne: {
    '&.Mui-checked': {
      color: "#6AAF68",
    },
  },
  profile: {
    height: 26,
    width: 26,
    minWidth: 26,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& img": {
      height: "100%",
      minHeight: "100%",
      minWidth: "100%",
      objectFit: "cover",
      borderRadius: "50%",
      border: "1px solid #D0D5DD",
    }
  },
  tierIcon: {
    borderRadius: "3px",
    paddingLeft: "0.15em",
    paddingRight: "0.15em",
    display: "flex",
    alignItems: "center",
    position: "absolute",
    top: "-3px",
    left: "-8px"
  },
  tierLabel: {
    color: "#FFFFFF",
    fontSize: "0.6rem"
  },
  ellipsisText: {
    maxWidth: "18em",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: "0.75rem"
  },
}))

export default function RecallListTableComponent({
  hasStockflowRecallViewAccessRight,
  hasStockflowDealerViewAccessRight,
  rows,
  isFetching,
  isSelectAll,
  selectedIds,
  isError,
  handleReload,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowPerPage,
  isSearchResult,
  totalFiltered,
  handleSelectAvailable,
  handleSelectAll,
  handleDeselectAll,
  handleSelectRecall,
  paginationOptions,
  tableFilter,
  changeOrder,
  language,
  lang,
  isDealerPage,
  handleViewDetail
}) {
  const classes = useStyle()
  
  if(language==="ZH")
  {
    moment.locale('zh-cn')
  }else if(language==="TL")
  {
    moment.locale('tl-ph')
  }else
  {
    moment.locale(language)
  }

  const selectAllItems = [
    {
      label: getLang(lang,"paragraph.SELECT_AVAILABLE_ITEM", {count: rows.length > rowsPerPage ? rowsPerPage : rows.length}),
      action: () => handleSelectAvailable(),
    },
    {
      label: getLang(lang, "paragraph.SELECT_ALL_RECALLS"),
      action: () => handleSelectAll(),
    },
    {
      label: getLang(lang, "paragraph.DESELECT_ALL_RECALLS"),
      action: () => handleDeselectAll(),
      divider: true,
    },
  ]

  return (
    <>
      {!isFetching && isError && <RefreshTable handleReload={handleReload} />}
      {isFetching || rows.length > 0 ? (
        <Paper id="test"style={{overflowX: 'auto', width: "100%"}}>
          <TableContainer>
            <Table
              className={classes.table}
              aria-label="recall table"
              size="small"
              stickyHeader
            >
              <TableHead style={{ height: "45px" }}>
                <TableRow>
                  {!isDealerPage && (
                    <StickyLeftTableCell>
                      <StyledTableCell padding="checkbox">
                        <SelectAllCheckBox
                          actionItems={selectAllItems}
                          disabled={isFetching}
                          isSelectAll={isSelectAll} 
                          selectedIdLength={selectedIds.length}
                          totalFiltered={totalFiltered}
                        />
                      </StyledTableCell>
                    </StickyLeftTableCell>
                  )}
                  <StyledTableCell>{getLang(lang, "label.RECALL_ID")}</StyledTableCell>
                  {!isDealerPage && (
                    <>
                      <StyledTableCell>{getLang(lang, "label.BRANCH")}</StyledTableCell>
                      <StyledTableCell>
                        <TableSortLabel
                          active={tableFilter.orderBy === 'recall_by'}
                          direction={tableFilter.order}
                          onClick={() => {
                            const differentTab = tableFilter.orderBy !== "recall_by"
                            const order = differentTab ? tableFilter.order : tableFilter.order === 'asc' ? "desc" : "asc"
                            changeOrder("recall_by", order)
                          }}
                          IconComponent={KeyboardArrowDown}
                        >
                          {getLang(lang, "label.RECALL_BY")}
                        </TableSortLabel>
                      </StyledTableCell>
                    </>
                  )}
                  <StyledTableCell>
                    <TableSortLabel
                      active={tableFilter.orderBy === 'recall_from'}
                      direction={tableFilter.order}
                      onClick={() => {
                        const differentTab = tableFilter.orderBy !== "recall_from"
                        const order = differentTab ? tableFilter.order : tableFilter.order === 'asc' ? "desc" : "asc"
                        changeOrder("recall_from", order)
                      }}
                      IconComponent={KeyboardArrowDown}
                    >
                      {getLang(lang, "label.DEALER")}
                    </TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell>{getLang(lang, "label.POINT_DEDUCTION")}</StyledTableCell>
                  <StyledTableCell>
                    <TableSortLabel
                      active={tableFilter.orderBy === 'created_at'}
                      direction={tableFilter.order}
                      onClick={() => {
                        const differentTab = tableFilter.orderBy !== "created_at"
                        const order = differentTab ? tableFilter.order : tableFilter.order === 'asc' ? "desc" : "asc"
                        changeOrder("created_at", order)

                      }}
                      IconComponent={KeyboardArrowDown}
                    >
                      {getLang(lang, "label.RECALL_DATE")}
                    </TableSortLabel>
                  </StyledTableCell>
                  {!isDealerPage && (
                    <StickyRightTableCell>
                      <StyledTableCell
                        style={{ width: "67px", textAlign: "center" }}
                      >
                        {getLang(lang, "label.ACTION")}
                      </StyledTableCell>
                    </StickyRightTableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {isFetching
                  ? [...new Array(5)].map((v, index) => (
                    <SkeletonTableRowStickyLeftRight key={index} columnCount={isDealerPage ? 4 : 8} />
                  ))
                  : rows.map((row, index) => {
                    const actionItems = []

                    actionItems.push({
                      label: getLang(lang, "label.VIEW_DETAILS"),
                      action: () => {
                        handleViewDetail(`${ROOT}/recall/${row.uuid}`)
                      },
                    })
                    // actionItems.push({
                    //   label: "Export XLS",
                    //   action: () => {
                    //     // TODO: add export function
                    //   },
                    // })

                    return (
                      <StyledTableRow
                        key={row.id}
                        hover
                        role="checkbox"
                        tabIndex={-1}
                      >
                        {!isDealerPage && (
                          <StickyLeftTableCell>
                            <StyledTableCell padding="checkbox">
                              <Checkbox
                                className={clsx({
                                  [classes.selectAll]: isSelectAll,
                                  [classes.selectOne]: row.isSelected && isSelectAll === false
                                })}
                                checked={row.isSelected}
                                onChange={(event) =>
                                  handleSelectRecall(
                                    index,
                                    event.target.checked
                                  )
                                }
                              />
                            </StyledTableCell>
                          </StickyLeftTableCell>
                        )}
                        <StyledTableCell>
                          {hasStockflowRecallViewAccessRight ? (
                            <Link
                              href={`${ROOT}/recall/${row.uuid}`}
                              color="secondary"
                              onClick={(e) => {
                                handleViewDetail(`${ROOT}/recall/${row.uuid}`)
                                e.preventDefault();
                                return false;
                              }}
                            >
                              <Typography className={classes.ellipsisText}>{row.reference_code}</Typography>
                            </Link>
                          ) : (
                            <Typography className={classes.ellipsisText}>{row.reference_code}</Typography>
                          )}
                        </StyledTableCell>
                        {!isDealerPage && (
                          <>
                            <StyledTableCell>
                              <Box display="flex" alignItems="center">
                                <Box pr={1}>
                                  <BranchIcon url={row.node_image_url} imageSize="40px" iconSize={22}/>
                                </Box>
                                <Box>
                                  {row.node || '-'}
                                </Box>
                              </Box>
                            </StyledTableCell>
                            <StyledTableCell>
                              {row.recall_by ? (
                                <Box display="flex" alignItems="center">
                                  <Box className={classes.profile} mr={1} position="relative" >
                                    {row.recall_by.picture === "" ? (
                                      <img src={row.recall_by.type === "Headquarter" ? DefaultImg : DefaultImage} alt="default profile" />
                                    ) : (
                                      <img src={row.recall_by.picture} alt={row.name} />
                                    )}
                                    {!!row.recall_by.tier && (
                                      <Box
                                        className={classes.tierIcon}
                                        style={{
                                          backgroundColor: TIER_ICON_PROPS.find(({ tier }) => tier === row.recall_by.tier).color
                                        }}
                                      >
                                        <Typography className={classes.tierLabel}>
                                          T{row.recall_by.tier}
                                        </Typography>
                                      </Box>
                                    )}
                                  </Box>
                                  {row.recall_by.type === "Headquarter" || !hasStockflowDealerViewAccessRight ? (
                                    <Typography className={classes.ellipsisText}>{row.recall_by.name}</Typography>
                                  ) : (
                                    <Link
                                      href={row.recall_by.type !== "Headquarter" ? `${ROOT}/my-dealer/${row.recall_by.uuid}` : "#" }
                                      color="secondary"
                                      onClick={(e) => {
                                        if (
                                          row.recall_by.type !== "Headquarter"
                                        ) {
                                          handleViewDetail(`${ROOT}/my-dealer/${row.recall_by.uuid}`)
                                        }
                                        e.preventDefault();
                                        return false;
                                      }}
                                    >
                                      <Typography className={classes.ellipsisText}>{row.recall_by.name}</Typography>
                                    </Link>
                                  )}
                                </Box>
                              ) : (
                                <span>-</span>
                              )}
                            </StyledTableCell>
                          </>
                        )}
                        <StyledTableCell>
                          {row.recall_from.map((target, index) => (
                            <Box display="flex" key={index} mt={index ? 1.5 : 0}>
                              <Box className={classes.profile} mr={1} position="relative">
                                {!!target.picture ? (
                                  <img src={target.picture} alt={row.name} />
                                ) : (
                                  <img src={DefaultImage} alt="default profile" />
                                )}
                                {!!target.tier && (
                                  <Box
                                    className={classes.tierIcon}
                                    style={{
                                      backgroundColor: TIER_ICON_PROPS.find(({ tier }) => tier === target.tier).color
                                    }}
                                  >
                                    <Typography className={classes.tierLabel}>
                                      T{target.tier}
                                    </Typography>
                                  </Box>
                                )}
                              </Box>
                              {hasStockflowDealerViewAccessRight ? (
                                <Link
                                  href={`${ROOT}/my-dealer/${target.uuid}`}
                                  color="secondary"
                                  onClick={(e) => {
                                    handleViewDetail(`${ROOT}/my-dealer/${target.uuid}`)
                                    e.preventDefault();
                                    return false;
                                  }}
                                >
                                  <Typography className={classes.ellipsisText}>{target.name}</Typography>
                                </Link>
                              ) : (
                                  <Typography className={classes.ellipsisText}>{target.name}</Typography>
                              )}
                            </Box>
                          ))}
                        </StyledTableCell>
                        <StyledTableCell>
                          {row.recall_from.map((target, index) => (
                            <Box mt={index !== 0 ? 1.5 : 0} minHeight={26} display="flex" justifyContent="flex-end" alignItems="center">
                              <span>{target.deduct_point.toLocaleString("en-US")}</span>
                            </Box>
                          ))}
                        </StyledTableCell>
                        <StyledTableCell>
                          <span>
                            {moment.utc(row.recall_date).local().format('lll')}
                          </span>
                        </StyledTableCell>
                        {!isDealerPage && (
                          <StickyRightTableCell>
                            <StyledTableCell style={{ width: "67px" }}>
                              <ActionButton
                                actionItems={actionItems}
                                popperStyle={{ zIndex: 999 }}
                              />
                            </StyledTableCell>
                          </StickyRightTableCell>
                        )}
                      </StyledTableRow>
                    )
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            style={{
              backgroundColor: "#fff",
              boxShadow: "0px -1px 0px rgba(58, 77, 84, 0.15)",
            }}
            ActionsComponent={TablePaginationActions}
            rowsPerPageOptions={paginationOptions}
            component="div"
            count={totalFiltered}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowPerPage}
            labelRowsPerPage={getLang(lang, 'label.ROWS_PER_PAGE')}
            labelDisplayedRows={({ from, to, count }) => `${from.toLocaleString("en-US")}-${to.toLocaleString("en-US")} ${getLang(lang, 'label.OF')} ${count.toLocaleString("en-US")}`}
          />
        </Paper>
      ) : null}
      {!isFetching && !isError && rows.length === 0 && (
        <Box>
          <TableContainer>
            <Table
              className={classes.table}
              aria-label="recall table"
              size="small"
              stickyHeader
            >
              <TableHead style={{ height: "45px" }}>
                <TableRow>
                  {!isDealerPage && (
                    <StickyLeftTableCell style={{ textAlign: "center" }}>
                      <SelectAllCheckBox disabled={true} />
                    </StickyLeftTableCell>
                  )}
                  <StyledTableCell>{getLang(lang, "label.RECALL_ID")}</StyledTableCell>
                  {!isDealerPage && (
                    <>
                      <StyledTableCell>{getLang(lang, "label.BRANCH")}</StyledTableCell>
                      <StyledTableCell>{getLang(lang, "label.RECALL_BY")}</StyledTableCell>
                    </>
                  )}
                  <StyledTableCell>{getLang(lang, "label.DEALER")}</StyledTableCell>
                  <StyledTableCell>{getLang(lang, "label.POINT_DEDUCTION")}</StyledTableCell>
                  <StyledTableCell>{getLang(lang, "label.RECALL_DATE")}</StyledTableCell>
                  {!isDealerPage && (
                    <StickyRightTableCell>
                      <StyledTableCell>{getLang(lang, "label.ACTION")}</StyledTableCell>
                    </StickyRightTableCell>
                  )}
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>
          <Box py={9} className={classes.emptyContainer}>
            <Typography variant="body1" style={{ paddingBottom: "16px", fontWeight: "bold" }}>
              {isSearchResult || isDealerPage
                ? getLang(lang, "paragraph.NO_RECALL_EXISTS")
                : getLang(lang, "paragraph.NO_RECALL_CREATED")}
            </Typography>
            {!isSearchResult && !isDealerPage && (
              <Typography variant="body2" style={{ paddingBottom: "16px" }}>
                {getLang(lang, "paragraph.RECALL_INSTRUCTION")}
              </Typography>
            )}
          </Box>
        </Box>
      )}
    </>
  )
}
