import axios from "axios"

const url = "/distributor/admin/api/v1/recall/list"
export function getRecallList (parameters) {
  return new Promise((resolve, reject) => {
    axios.post(url, {
      length: parameters.length,
      start: parameters.start,
      order: parameters.order,
      order_by: parameters.order_by,
      filter: {
        nodes: parameters.filter.nodes,
        search: parameters.filter.search,
        from_date: parameters.filter.from_date,
        to_date: parameters.filter.to_date,
        recall_by: parameters.filter.recall_by,
        recall_from: parameters.filter.recall_from,
      }
    })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data))
  })
}