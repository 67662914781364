import reportingCampaignMiddleware from './reportingCampaign.mdl';
import reportingDemographicMiddleware from './reportingDemographic.mdl';
import reportingFilterMiddleware from './reportingFilter.mdl';
import reportingMiscellaneousMiddleware from './reportingMiscellaneous.mdl';
import reportingProductMiddleware from './reportingProduct.mdl';
import reportingSalesMiddleware from './reportingSales.mdl';
import reportingTrendingMiddleware from './reportingTrending.mdl';
import reportingConsumerMiddleware from './reportingConsumer.mdl';
import reportingStockoutMiddleware from './reportingStockout.mdl';
import reportingValidationMiddleware from './reportingValidation.mdl';

export default [
    ...reportingTrendingMiddleware,
    ...reportingMiscellaneousMiddleware,
    ...reportingSalesMiddleware,
    ...reportingDemographicMiddleware,
    ...reportingCampaignMiddleware,
    ...reportingFilterMiddleware,
    ...reportingProductMiddleware,
    ...reportingConsumerMiddleware,
    ...reportingStockoutMiddleware,
    ...reportingValidationMiddleware
];