import React from "react";
import {
  makeStyles,
  ButtonBase,
  Typography,
} from "@material-ui/core";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "5px 12px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#eceff0",
    borderRadius: 4,
    width: "100%",
    height: 34,
    overflow: "hidden"
  },
  inputText: {
    fontSize: "0.875rem",
    color: "#a4a6a8",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontWeight: 500
  },
  filled: {
    color: "rgba(0, 0, 0, 0.87)",
  },
}));

function SearchableSelectComponent({
  anchorRef,
  handleClick,
  value,
  placeholder,
  disabled,
  style
}) {
  const classes = useStyles();
  const isEmpty = value === "" ? true : false;

  return (
    <ButtonBase
      className={classes.root}
      disableRipple
      ref={anchorRef}
      onClick={handleClick}
      disabled={disabled}
      style={{ padding: 12, ...style }}
    >
      <Typography
        variant='body2'
        className={clsx(classes.inputText, !isEmpty && classes.filled)}
      >
        {isEmpty ? placeholder : value}
      </Typography>
      <ArrowDropDownIcon fontSize='small' />
    </ButtonBase>
  )
}

SearchableSelectComponent.propTypes = {
  handleClick: PropTypes.func,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
}

export default SearchableSelectComponent;