import React from "react";
import PrizeAllocationDialogComponent from "./prizeAllocationDialog.component";
import { closeAllocationDialog } from "modules/instant-lucky-draw/redux";
import { useDispatch, useSelector } from "react-redux";

function PrizeAllocationDialogContainer() {
  const dispatch = useDispatch();
  const isOpen = useSelector(
    (state) => state.instantLuckyDraw.prizeAllocation.isOpenAllocation
  );
  const lang = useSelector(state => state.constant.languages)

  return (
    <>
      <PrizeAllocationDialogComponent
        handleClose={() => dispatch(closeAllocationDialog())}
        isOpen={isOpen}
        lang={lang}
      />
    </>
  );
}

export default PrizeAllocationDialogContainer;
