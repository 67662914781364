import React from "react";
import {
  Backdrop,
  Box,
  Paper,
  Divider,
  makeStyles,
  Container,
} from "@material-ui/core";
import HeaderComponent from "../../header";
import StepperComponent from "../../stepper";
import TabComponent from "../../tab";
import Dialog from "components/dialog/customDialog";

const styles = makeStyles((theme) => ({
  root: {
    height: "80%",
  },
  container: {
    height: "100%",
    width: "1100px",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    paddingLeft: "0px !important",
    paddingRight: "0px !important",
  },
  content: {
    flex: 1,
  },
}));

export default function LuckyDrawDetailDialogComponent({
  headerType,
  isOpen,
  handleClose,
}) {
  const classes = styles();

  return (
    <Dialog
      isOpen={isOpen}
      handleClose={handleClose}
      disableEnforceFocus={true}
      disableAutoFocus={true}
      disableEscapeKeyDown={true}
      disableBackdropClick={true}
      closeAfterTransition
      BackdropComponent={Backdrop}
      maxWidth="1100px"
    >
      <Paper elevation={0} className={classes.root}>
        <Container className={classes.container}>
          <HeaderComponent handleClose={handleClose} headerType={headerType} />
          <Divider />
          <Box className={classes.content}>
            {headerType === "create" ? (
              <StepperComponent handleClose={handleClose} />
            ) : (
              <TabComponent />
            )}
          </Box>
        </Container>
      </Paper>
    </Dialog>
  );
}
