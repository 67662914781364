import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import BackDropComponent from "./backdrop.component";

function BackDropContainer({ show }) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (show) setOpen(true);
    else setOpen(false);
  }, [show]);

  return <BackDropComponent open={open} />;
}

const mapStateToProps = (state) => ({
  show: state.backdrop.show
});

export default connect(mapStateToProps, null)(BackDropContainer);
