const API_HOST = process.env.REACT_APP_API_HOST_URL

const post = async (parameters) => {
  return new Promise((resolve, reject) => {
    fetch(
      `${API_HOST}/distributor/admin/api/v1/inventory/export/product/list`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${parameters.token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          isSelectAll: parameters.isSelectAll,
          selectedIds: parameters.selectedIds,
          filter: {
            nodes: parameters.filter.nodes,
            search: parameters.filter.search,
            search_by: parameters.filter.search_by,
          }
        })
      }
    )
    .then((response) => resolve(response.blob()))
    .catch((error) => reject(error.response.data))
  })
}

export const exportProductListing = {
  post
}