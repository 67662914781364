import React, { useRef, useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useRouteMatch } from "react-router-dom"
import { generatePaginationOptions, updateUrlQueryParam } from "lib/helper"
import { selectLoading } from "modules/notification"
import { getDealerInventoryPackageListing, resetDealerInventoryListing } from "modules/stockflow/redux"
import DealerPackageListPanelComponent from "./dealerPackageListPanel.component"
import { STOCKFLOW_INVENTORY_VIEW } from "lib/constants/accessRights";

function DealerPackageListPanelContainer() {
  const dispatch = useDispatch()
  const match = useRouteMatch()
  const history = useHistory()

  const id = match.params.dealerId
  const lang = useSelector(state => state.constant.languages)
  const query = new URLSearchParams(window.location.search);
  const hasStockflowInventoryViewAccessRight = useSelector(state => state.profile.accessRights.includes(STOCKFLOW_INVENTORY_VIEW));

  const isFetching = useSelector(state => selectLoading(state, getDealerInventoryPackageListing.typePrefix))

  const totalFiltered = useSelector(state => state.stockflowDealer.inventoryPackageList.totalFiltered)
  const totalRecords = useSelector(state => state.stockflowDealer.inventoryPackageList.totalRecords)
  const packageList = useSelector(state => state.stockflowDealer.inventoryPackageList.list)

  const isError = useSelector(state => state.stockflowDealer.inventoryPackageList.isError)
  const isSearchResult = packageList.length < totalRecords
  const [paginationOptions, setPaginationOptions] = useState([]);
  const [isFilterUpdated, setIsFilterUpdated] = useState(false)
  const [tableFilter, setTableFilter] = useState({
    page: 0,
    rowsPerPage: 25,
    search: "",
    searchColumn: "",
    order: "desc",
    orderBy: "updated_at",
    products: []
  })

  const tableFilterInitialRef = useRef(true)

  useEffect(() => {
    return () => {
      dispatch(resetDealerInventoryListing())
    }
  }, [dispatch])

  useEffect(() => {
    const isFilter = query.has("is_filter")
    let filter = sessionStorage.getItem(`dealer_package_filter_${id}`);

    let temp = {...tableFilter}
    if (isFilter && filter) {
      temp = JSON.parse(filter)
      setIsFilterUpdated(true)
    }

    if (isFilter) {
      updateUrlQueryParam(null, ["is_filter"])
    }
    sessionStorage.removeItem(`dealer_package_filter_${id}`)

    setTableFilter(temp)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!tableFilterInitialRef.current) {
      getData()
    }
    tableFilterInitialRef.current = false
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableFilter])


  useEffect(() => {
    setPaginationOptions(generatePaginationOptions(totalFiltered))
  }, [totalFiltered])

  const getData = () => {
    dispatch(getDealerInventoryPackageListing({
      dealer: id,
      length: tableFilter.rowsPerPage,
      start: tableFilter.rowsPerPage * tableFilter.page,
      order: tableFilter.order,
      order_by: tableFilter.orderBy,
      filter: {
        search: tableFilter.search,
        search_by: tableFilter.searchColumn,
        products: tableFilter.products,
      }
    }))
  }

  const handleChangePage = (event, newPage) => {
    let newData = {
      ...tableFilter,
      page: newPage,
    }
    setIsFilterUpdated(true)
    setTableFilter(newData)
  }

  const handleChangeRowsPerPage = event => {
    let newData = {
      ...tableFilter,
      page: 0,
      rowsPerPage: event.target.value,
    }
    setIsFilterUpdated(true)
    setTableFilter(newData)
  }

  const handleSearch = (filter) => {
    const { search, searchColumn, products } = filter

    let newData = {
      ...tableFilter,
      page: 0,
      search: search,
      searchColumn: searchColumn,
      products: products,
    }
    setIsFilterUpdated(true)
    setTableFilter(newData)
  }

  const clearSearch = () => {
    let newData = {
      ...tableFilter,
      page: 0,
      search: "",
      searchColumn: "",
      products: []
    }
    setIsFilterUpdated(false)
    setTableFilter(newData)
  }

  const changeOrder = (orderBy, order) => {
    let newData = {
      ...tableFilter,
      order,
      orderBy
    }
    setIsFilterUpdated(true)
    setTableFilter(newData)
  }

  const handleViewDetail = (url) => {
    if (isFilterUpdated) {
      updateUrlQueryParam({ is_filter: true })
      sessionStorage.setItem(`dealer_package_filter_${id}`, JSON.stringify(tableFilter));
    }
    history.push(url)
  }

  return (
    <DealerPackageListPanelComponent
      hasStockflowInventoryViewAccessRight={hasStockflowInventoryViewAccessRight}
      id={id}
      packages={packageList}
      isFetching={isFetching}
      isError={isError}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      page={tableFilter.page}
      rowsPerPage={tableFilter.rowsPerPage}
      totalFiltered={totalFiltered}
      handleSearch={handleSearch}
      clearSearch={clearSearch}
      handleReload={getData}
      isSearchResult={isSearchResult}
      paginationOptions={paginationOptions}
      tableFilter={tableFilter}
      lang={lang}
      changeOrder={changeOrder}
      handleViewDetail={handleViewDetail}
    />
  )
}

export default DealerPackageListPanelContainer
