import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import DealerRewardPointPanelComponent from "./dealerRewardPointPanel.component"
import { getLang } from "app/feature/constants"

function DealerRewardPointPanelContainer() {
  const lang = useSelector(state => state.constant.languages)
  const [activeIndex, setActiveIndex] = useState(null)
  const query = new URLSearchParams(window.location.search)

  let tabLabels = [
    getLang(lang, "label.REWARDS"), 
    getLang(lang, "label.POINT_HISTORY"), 
    getLang(lang, "label.EXPIRING_POINTS"), 
  ]
  
  const tab = query.get("tab")

  const tabList = [
    "reward",
    "point-history",
    "expiring-point",
  ]

  useEffect(() => {
    let tempIndex = tabList.findIndex(t => t === tab)
    setActiveIndex(tempIndex >= 0 ? tempIndex : 0)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab])

  const handleChange = (event, index) => {
    if (activeIndex !== index) {
      setActiveIndex(index)

      const newQuery = new URLSearchParams()
      newQuery.set('tab', tabList[index])
      window.history.replaceState({}, '', `?${newQuery}`)
    }
  }

  return (
    <DealerRewardPointPanelComponent 
      tabLabels={tabLabels}
      activeIndex={activeIndex}
      handleChange={handleChange}
      lang={lang}
    />
  )
}

export default DealerRewardPointPanelContainer
