import axios from "axios"

export default async function getConsumerProfile(uuid) {
  return new Promise((resolve, reject) => {
    axios
      .get("/consumer/admin/api/v1/user/profile/" + uuid)
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data)
      })
  })
}
