import React, { useState } from "react";
import {
  makeStyles,
  Box,
  Button,
  TextField,
  MenuItem,
  Divider,
} from "@material-ui/core";
import { Formik } from "formik";
import FilterSvg from "assets/svg/filter.svg";
import BranchSelect from "../../select/branchSelect";
import clsx from "clsx";
import SearchableSelect from "components/select/searchableSelect";
import { getLang } from "app/feature/constants";
import StockHolderSelect from "../../select/stockHolderSelect";
import StockProductSelect from "../../select/stockProductSelect";
import LooseItemStockListTable from "../../table/looseItemStockListTable";

const useStyle = makeStyles((theme) => ({
  searchContainer: {
    backgroundColor: theme.palette.background.paper,
    display: "grid",
    gridTemplateColumns: "6fr 4fr 1.8fr 1fr 1fr",
    columnGap: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1),
    },
  },
  filterMainContainer: {
    backgroundColor: theme.palette.background.paper,
    paddingTop: "12px",
    paddingBottom: "12px",
    paddingRight: "20px",
    paddingLeft: "20px",
    marginBottom: "12px",
  },
  filterFieldContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
    columnGap: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1),
    },
  },
  inputRootProduct: {
    paddingTop: "8px !important",
    paddingBottom: "7px !important",
    paddingLeft: "12px !important",
    paddingRight: "12px !important",
  },
  paperRoot: {
    // width: "217px",
  },
  dropdownSearchBox: {
    backgroundColor: "#E9EDEF",
    borderRadius: 4,
  },
  onFocusBorder: {
    borderRadius: 4,
    outlineWidth: "1px",
    outlineStyle: "solid",
    outlineColor: theme.palette.secondary.main,
  },
  divider: {
    height: "70%",
    placeSelf: "center",
  },
  menuItemText: {
    padding: 10,
    fontWeight: 500,
  },
  select: {
    minWidth: 170,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      minWidth: "auto",
    },
  },
  inputRoot: {
    fontWeight: 500,
    paddingTop: "8px !important",
    paddingBottom: "7px !important",
    paddingLeft: "10px !important",
  },
  textFieldInput: {
    padding: "7px",
    paddingTop: "8px",
    minWidth: 170,
    fontWeight: 500,
    color: theme.palette.primary.main,
  },
}));

export default function LooseItemStockListPanelComponent({
  hasStockflowDealerViewAccessRight,
  rows,
  isFetching,
  isError,
  handleReload,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
  handleSearch,
  isSearchResult,
  totalFiltered,
  isFilterOpen,
  setIsFilterOpen,
  viewActivityLog,
  tableFilter,
  changeSequenceOrder,
  lang,
  resetFilter,
  clearSearch,
  paginationOptions,
  code,
  handleViewDetail,
}) {
  const classes = useStyle();

  const columnDropdown = [
    { name: getLang(lang, "label.SERIAL_NUMBER"), value: "serial_number" },
    { name: getLang(lang, "label.SEQUENCE_NUMBER"), value: "sequence_no" },
    { name: getLang(lang, "label.BOX_CODE"), value: "box_code" },
  ];

  const boxDropdown = [
    { name: "-", value: false },
    { name: getLang(lang, "label.BOXED"), value: true },
  ];

  const [onFocus, setOnFocus] = useState(false);

  return (
    <Box display="flex" flexDirection="column">
      <Formik
        initialValues={{
          searchColumn: tableFilter.searchColumn || columnDropdown[0].value,
          search: tableFilter.search,
          locatedAt: tableFilter.locatedAt,
          box: tableFilter.box
            ? boxDropdown.find((v) => v.value === tableFilter.box)
            : boxDropdown[0],
          branch: tableFilter.branch,
          products: tableFilter.products,
        }}
        enableReinitialize
        onSubmit={(values, actions) => {
          handleSearch(values);
          actions.setSubmitting(false);
        }}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <Box p={2} mb={2} className={classes.searchContainer}>
              <Box
                display="flex"
                className={clsx(classes.dropdownSearchBox, {
                  [classes.onFocusBorder]: onFocus,
                })}
              >
                <TextField
                  className={classes.select}
                  select
                  SelectProps={{
                    MenuProps: {
                      MenuListProps: {
                        style: { padding: 0 },
                      },
                      style: { marginTop: 5 },
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    },
                  }}
                  value={formik.values.searchColumn}
                  size="small"
                  margin="none"
                  InputProps={{
                    disableUnderline: true,
                    classes: { input: classes.inputRoot },
                  }}
                >
                  {columnDropdown.map((column, index) => (
                    <MenuItem
                      className={classes.menuItemText}
                      value={column.value}
                      key={index}
                      onClick={() => {
                        formik.setFieldValue("searchColumn", column.value);
                      }}
                    >
                      {column.name}
                    </MenuItem>
                  ))}
                </TextField>
                <Divider orientation="vertical" className={classes.divider} />
                <TextField
                  onFocus={() => setOnFocus(true)}
                  onBlur={() => setOnFocus(false)}
                  fullWidth
                  size="small"
                  margin="none"
                  placeholder={getLang(lang, "placeholder.SEARCH")}
                  InputProps={{
                    disableUnderline: true,
                    margin: "none",
                  }}
                  inputProps={{
                    className: classes.textFieldInput,
                  }}
                  {...formik.getFieldProps("search")}
                />
              </Box>
              <StockHolderSelect
                value={formik.values.locatedAt}
                handleChange={(value) =>
                  formik.setFieldValue("locatedAt", value)
                }
                placeholder={getLang(lang, "placeholder.LOCATED_AT")}
                disabled={isFetching}
                type="loose_item"
              />
              <Button
                variant="outlined"
                size="small"
                disableElevation
                onClick={() => setIsFilterOpen(!isFilterOpen)}
                startIcon={<img src={FilterSvg} alt="filter svg" />}
              >
                {getLang(lang, "label.ADD_FILTER")}
              </Button>
              <Button
                variant="text"
                disableElevation
                color="secondary"
                size="small"
                onClick={clearSearch}
              >
                {getLang(lang, "label.CLEAR")}
              </Button>
              <Button
                disabled={isFetching}
                variant="contained"
                disableElevation
                color="primary"
                size="small"
                type="submit"
              >
                {getLang(lang, "label.SEARCH")}
              </Button>
            </Box>
            {isFilterOpen && (
              <Box className={classes.filterMainContainer}>
                <Box
                  className={classes.filterFieldContainer}
                  style={{ marginBottom: "12px" }}
                >
                  <SearchableSelect
                    value={formik.values.box}
                    handleChange={(value) => {
                      formik.setFieldValue("box", value);
                    }}
                    placeholder={getLang(lang, "placeholder.BOX")}
                    disabled={formik.isSubmitting || isFetching}
                    searchable={false}
                    panelStyle={{ width: "249px" }}
                    PaperProps={{
                      classes: { root: classes.paperRoot },
                    }}
                    dropdownItem={boxDropdown}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    isEmpty={!formik.values.box.value}
                    returnType="object"
                  />
                  <StockProductSelect
                    value={formik.values.products}
                    handleChange={(value) =>
                      formik.setFieldValue("products", value)
                    }
                    placeholder={getLang(lang, "placeholder.PRODUCTS")}
                    disabled={isFetching}
                    type="loose_item"
                    code={code}
                  />
                  <BranchSelect
                    placeholder={getLang(lang, "placeholder.BRANCH")}
                    value={formik.values.branch}
                    handleChange={(value) =>
                      formik.setFieldValue("branch", value)
                    }
                    styles={{
                      padding: "6px 10px",
                    }}
                  />
                </Box>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    variant="text"
                    disableElevation
                    color="secondary"
                    size="small"
                    onClick={resetFilter}
                    style={{
                      marginRight: "8px",
                    }}
                  >
                    {getLang(lang, "label.CLEAR")}
                  </Button>
                  <Button
                    disabled={isFetching}
                    variant="contained"
                    disableElevation
                    color="secondary"
                    size="small"
                    type="submit"
                  >
                    {getLang(lang, "label.APPLY_FILTER")}
                  </Button>
                </Box>
              </Box>
            )}
          </form>
        )}
      </Formik>

      <LooseItemStockListTable
        hasStockflowDealerViewAccessRight={hasStockflowDealerViewAccessRight}
        rows={rows}
        isFetching={isFetching}
        isError={isError}
        handleReload={handleReload}
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        isSearchResult={isSearchResult}
        totalFiltered={totalFiltered}
        tableFilter={tableFilter}
        changeSequenceOrder={changeSequenceOrder}
        paginationOptions={paginationOptions}
        lang={lang}
        handleViewDetail={handleViewDetail}
      />
    </Box>
  );
}
