import React from "react";
import {
  makeStyles,
  Box,
  Typography,
  Divider,
  Paper,
  Button,
  StepConnector,
  StepLabel,
  Stepper,
  Step
} from "@material-ui/core";
import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";

import { withStyles } from "@material-ui/styles";
import clsx from "clsx";
import PhotoProductPanel from "../../components/panel/photoProductPanel";
import { BackButton } from "components/button";
import { getLang } from "app/feature/constants";

const Connector = withStyles(theme => ({
  alternativeLabel: {
    padding: "0 60px"
  },
  active: {
    "& $line": {
      borderColor: theme.palette.primary.main
    }
  },
  completed: {
    "& $line": {
      borderColor: theme.palette.primary.main
    }
  },
  line: {
    borderColor: "rgba(58, 77, 84, 0.33)",
    borderTopWidth: 4,
    borderRadius: 5
  }
}))(StepConnector);

const useStyle = makeStyles(theme => ({
  root: {
    display: "grid",
    gridTemplateColumns: "1fr 330px",
    columnGap: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "1fr",
      gridTemplateRows: "1fr auto",
      rowGap: theme.spacing(2),
      gridRowGap: theme.spacing(2)
    }
  },
  paper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(5, 0),
    width: "90%",
    maxWidth: 1000,
    display: "inherit",
    justifyContent: "inherit"
  },
  content: {
    width: "95%",
    maxWidth: 850
  },

  sideBanner: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridRowGap: theme.spacing(2),
    rowGap: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(3),
      gridTemplateColumns: "1fr 1fr",
      columnGap: theme.spacing(2)
    },
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr"
    }
  },

  publishButton: {
    color: theme.palette.common.white
  },
  backButton: {
    marginRight: theme.spacing(1)
  },
  discardButton: {
    color: theme.palette.error.main
  },
  iconContainer: {
    minHeight: 38
  },
  stepperRoot: {
    paddingBottom: 0
  },
  stepLabelRoot: {
    height: 100,
    position: "relative"
  },
  stepLabelActive: {
    "&::after": {
      content: "''",
      backgroundColor: theme.palette.primary.main,
      width: "20%",
      height: 3,
      position: "absolute",
      bottom: 0,
      borderRadius: theme.spacing(1, 1, 0, 0)
    }
  },
  fullWidthGrid: {
    gridTemplateColumns: "1fr"
  }
}));

function NewProductPage({
  activeStep,
  steps,
  handleNext,
  handleBack,
  handleDiscard,
  lang
}) {
  const classes = useStyle();
  const SelectedStep = steps[activeStep];

  const showBackBtn = activeStep !== 0;

  return (
    <Box>
      <Box
        mb={1}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex" alignItems="flex-end" fontWeight="bold">
          <BackButton />
          <Typography variant="h6" color="primary">
            {getLang(lang,"label.PRODUCT")}
          </Typography>
          <Box mx={0.5}>
            <ChevronRightRoundedIcon color="disabled" />
          </Box>
          <Typography variant="h6" color="primary">
            {getLang(lang,"label.ADD_NEW_PRODUCT")}
          </Typography>
        </Box>
      </Box>

      <Box className={clsx(classes.root, {
        [classes.fullWidthGrid]: activeStep === 1
      })}>
        <Paper
          elevation={0}
          style={{
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
            backgroundColor: "transparent"
          }}
        >
          <Box style={{
            boxShadow: "0px 1px 0px 0px #3A4D5426",
            zIndex: 10
          }}>
            <Stepper
              activeStep={activeStep}
              alternativeLabel
              connector={<Connector />}
              classes={{ root: classes.stepperRoot }}
            >
              {steps.map((step, index) => (
                <Step key={index}>
                  <StepLabel
                    classes={{
                      root: clsx(
                        classes.stepLabelRoot,
                        activeStep === index && classes.stepLabelActive
                      ),
                      iconContainer: classes.iconContainer
                    }}
                    StepIconComponent={
                      activeStep >= index ? step.activeImage : step.labelImage
                    }
                  >
                    {step.label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
          <SelectedStep.form handleNext={handleNext} formType="create">
            {({ handleSubmit, renderSubmitButton }) => (
              <Box mt="auto">
                <Divider />
                <Box
                  display="flex"
                  justifyContent="space-between"
                  p={2}
                  style={{
                    backgroundColor: "white"
                  }}>
                  <Button
                    variant="text"
                    classes={{ root: classes.discardButton }}
                    onClick={handleDiscard}
                  >
                    {getLang(lang,"label.DISCARD")}
                  </Button>
                  <Box display="flex">
                    {showBackBtn ? (
                      <Button
                        variant="outlined"
                        disableElevation
                        onClick={handleBack}
                        type="button"
                        classes={{ root: classes.backButton }}
                      >
                        {getLang(lang,"label.BACK")}
                      </Button>
                    ) : null}
                    {renderSubmitButton ? (
                      renderSubmitButton
                    ) : (
                      <Button
                        variant="contained"
                        color="secondary"
                        classes={{ root: classes.publishButton }}
                        disableElevation
                        type="submit"
                        onClick={handleSubmit}
                      >
                        {getLang(lang,"label.NEXT")}
                      </Button>
                    )}
                  </Box>
                </Box>
              </Box>
            )}
          </SelectedStep.form>
        </Paper>

        {activeStep === 0 &&
          <Paper elevation={3} style={{ height: "fit-content" }}>
            <PhotoProductPanel />
          </Paper>
        }
      </Box>
    </Box>
  );
}

export default NewProductPage;
