import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import organisationStructureApi from "app/api/organisationStructure";
import employeeApi from "app/api/employee";

export const ORGANISATION_STRUCTURE = "organisationStructure";

export const fetchOrganisationStructureDetails = createAsyncThunk(
  `${ORGANISATION_STRUCTURE}/fetchOrganisationStructureDetails`,
  async (payload, { rejectWithValue }) => {
    return organisationStructureApi
      .organisationStructureDetails
      .get(payload)
      .then((response) => {
        if (response.success) return response;
        else return rejectWithValue(response.message);
      })
      .catch((error) => rejectWithValue(error));
  }
);

export const fetchNewEmployee = createAsyncThunk(
  `${ORGANISATION_STRUCTURE}/fetchNewEmployee`,
  async (payload, { rejectWithValue }) => {
      return employeeApi
        .getNewEmployee
        .get(payload)
        .then((response) => {
          if (response.status === 200) return response.data;
          else return rejectWithValue(response.message);
        })
        .catch((error) => rejectWithValue(error));
  }
);

export const fetchMoreNewEmployee = createAction(
  `${ORGANISATION_STRUCTURE}/fetchMoreNewEmployee`
);