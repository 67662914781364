import React from "react"
import {
  makeStyles,
  Typography,
  Box,
  Tabs,
} from "@material-ui/core"
import {
  ChevronRightRounded as ChevronRightRoundedIcon
} from "@material-ui/icons"
import { BackButton } from "components/button"
import CustomTab from "components/tab"
import { getLang } from "app/feature/constants"
import LooseItemInfoCard from "modules/stockflow/components/card/looseItemInfoCard"
import LooseItemStockListPanel from "modules/stockflow/components/panel/looseItemStockListPanel"

const useStyle = makeStyles(theme => ({
  title: {
    fontWeight: "bold"
  },
  root: {
    display: "grid",
    gridTemplateAreas: "'topbar .''panel panel'",
    [theme.breakpoints.down("md")]: {
      gridTemplateAreas: "'topbar topbar''panel panel'"
    },
    [theme.breakpoints.down("sm")]: {
      gridTemplateAreas: "'topbar topbar''panel panel'"
    }
  },
  indicator: {
    border: "0.5px solid",
    borderColor: theme.palette.primary.main
  },
  scroll: {
    width: "min-content"
  },
  logContainer: {
    height: "100%",
    zIndex: 91,
  },
  slideInPanel: {
    position: "absolute",
    top: "0px",
    maxWidth: "90%",
    width: "700px",
    right: "0px",
    height: "100%", 
    zIndex: 91,
    minHeight: "50vh"
  },
}))

export default function LooseItemDetailsComponent({
  tabLabels,
  activeIndex,
  handleChange,
  looseItem,
  isLoading, 
  lang,
  code
}) {
  const classes = useStyle()

  function a11yProps(index, label = "label") {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tab-${index}`,
      label: <Typography variant="button" children={label} />,
    }
  }

  return (
    <Box className={classes.root}>
      <Box gridArea="topbar">
        <Box
          mb={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="flex" alignItems="flex-end" fontWeight="bold">
            <BackButton />
            <Box display="flex" ml={1} alignItems="flex-end">
              <Typography
                variant="h6"
                color="primary"
              >
                {getLang(lang, "label.INVENTORY")}
              </Typography>
              <Box mx={0.5}>
                <ChevronRightRoundedIcon color="disabled" />
              </Box>
              <Box>
                <Typography variant="h6" color="primary">
                  {getLang(lang, "label.LOOSE_ITEM_DETAILS")}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box gridArea="panel">
        <Box>
          <Box display="flex" justifyContent="space-between">
            <Box>
              <Tabs
                value={activeIndex}
                onChange={handleChange}
                aria-label="scrollable auto tabs example"
                textColor="primary"
                variant="scrollable"
                indicatorColor="primary"
                classes={{
                  indicator: classes.indicator,
                  scrollButtons: classes.scroll,
                }}
              >
                {tabLabels.map((item, index) => {
                  return <CustomTab key={index} {...a11yProps(index, item)} />
                })}
              </Tabs>
            </Box>
            {/* <Box>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                style={{ padding: "5px 12px", background: "white", borderRadius: "8px" }}
                disableElevation
                onClick={() => {
                  // exportListing()
                }}
                startIcon={isExporting ? <CircularProgress style={{ width: 10, height: 10 }}/> : <DownloadSVG />}
                disabled={isLoading}
              >
                EXPORT XLS
              </Button>
            </Box> */}
          </Box>
          <Box style={{ height: "100%", position: "relative" }}>
            <Box mb={2}>
              <LooseItemInfoCard
                looseItem={looseItem}
                isFetching={isLoading}
                lang={lang}
              />
            </Box>
            <Box style={{ height: "100%" }}>
              <LooseItemStockListPanel code={code} />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
