import { Box, Grid, IconButton, TableCell, TableRow, Typography } from "@material-ui/core";
import html2canvas from "html2canvas";
import { DownloadIcon } from "modules/reporting/assets/svg";
import { flexGapStyle, fontTextStyle, tableStyle } from "modules/reporting/styles";
import { drilldownPageSelection, responseHandler } from "modules/reporting/utils/helper";
import React from "react";
import { TrendingPositiveCard } from "../../card";
import { MixedChart } from "../../charts";
import { ContentCard } from "../../layout";
import EmptyChart from "../../skeleton/global/empty-chart";
import { ChartCardLoading, TrendingCardLoading } from "../../skeleton/loading";
import downloadjs from 'downloadjs';
import { DynamicTable } from "../../table";
import { Link } from "react-router-dom";
import moment from "moment";

export default function CampaignSurveyPanelComponent({
    data,
    status,
    title="campaign-performance",
    sort,
    pagination,
    columns,
    currentFilter,
    pageType,
    translate
}) {
    const tableClasses = tableStyle();
    const fontClasses = fontTextStyle();
    const flexGapClasses = flexGapStyle();

    let buttonGroupId = title + '_buttonGroup';
    // download miscellaneous chart
    const handleDownloadCard = () => {
        let element = document.getElementById(title);

        // hide element have id = remove-this inside target
        let removeThis = element.querySelector(buttonGroupId);
        removeThis.style.display = "none";

        html2canvas(element).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            downloadjs(imgData, 'chart_'+ title +'.png');
        });

        // show element have id = remove-this inside target
        removeThis.style.display = "flex";
    }

    const renderData = (data) => {


        let rows = [];
        if(Object.keys(data).length === 0 || !data.isSuccess){
            return rows;
        }

        let imageProps = JSON.stringify(data);
        imageProps = JSON.parse(imageProps);

        let no_iteration = pagination.rowsPerPage * pagination.page + 1;
        imageProps.rows.forEach((item, index) => {

            let row = item.uuid ? (
                <TableRow>
                    <TableCell className={tableClasses.table__cell} style={{width: "20px"}}>
                        <Typography className={fontClasses.text__table_cell}>{no_iteration++}</Typography>
                    </TableCell>
                    <TableCell className={tableClasses.table__cell}>
                        <Link
                            target="_blank" rel="noopener noreferrer" 
                            className={fontClasses.text__hyperlink}
                            to={() => {
                                let filterProperties = {};

                                if(currentFilter.countryCode)
                                    filterProperties.countryCode = currentFilter.countryCode;
                                
                                if(currentFilter.cityUuid)
                                    filterProperties.cityUuid = currentFilter.cityUuid;
                                
                                if(currentFilter.branchUuid)
                                    filterProperties.branchUuid = currentFilter.branchUuid;

                                if(currentFilter.period)
                                    filterProperties.period = currentFilter.period;

                                if(currentFilter.dateRange){
                                    filterProperties.start = moment(currentFilter.dateRange[0]).format('YYYY-MM-DD');
                                    filterProperties.end = moment(currentFilter.dateRange[1]).format('YYYY-MM-DD');
                                }

                                if(item.uuid)
                                    filterProperties.campaignUuid = item.uuid;

                                if(item.campaign_type)
                                    filterProperties.campaignType = item.campaign_type;

                                let url = drilldownPageSelection(filterProperties, pageType, true);
                                    return url;
                            }}
                        
                        >
                            {item.name}
                        </Link>
                    </TableCell>
                    <TableCell className={tableClasses.table__cell} align="right" style={{width: "180px", minWidth: "180px"}}>
                        <Typography className={fontClasses.text__table_cell}>
                            {item.engagement}
                        </Typography>
                    </TableCell>
                </TableRow>
            ) : (
                null
            )

            
            item.render = row;
        })
        
        return imageProps;
    }

    return (
        <ContentCard>
            {/* trending start */}
            <Grid container spacing={3} alignItems="center">
                <Grid item xs={12} sm={12} md={6} >
                    {
                        responseHandler(
                            data.totalScanTrendingData,
                            status.isFetchingTotalScanTrending,
                            <TrendingPositiveCard translate={translate} title={translate("label.TOTAL_VALID_SCAN_QR")} data={data.totalScanTrendingData} />,
                            <TrendingCardLoading/>,
                            null,
                            null,
                            null
                        )
                    }
                </Grid>
                <Grid item xs={12} sm={12} md={6} >
                    {
                        responseHandler(
                            data.campaignEngagedTrendingData,
                            status.isFetchingCampaignEngagedTrending,
                            <TrendingPositiveCard translate={translate} title={translate("label.TOTAL_PARTICIPANT")} data={data.campaignEngagedTrendingData} />,
                            <TrendingCardLoading/>,
                            null,
                            null,
                            null
                        )
                    }
                </Grid>
            </Grid>
            {/* trending end */}


            {/* campaign performance start */}
            <Box
                display="flex"
                flexDirection="column"
            >
                {/* title and download icon/toggle icon */}
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    margin="24px 0"
                >
                    <Typography className={fontClasses.text__card_title}>
                        {translate("label.CAMPAIGN_PERFORMANCE")}
                    </Typography>
                    <Box display="flex" alignContent="center" className={flexGapClasses.global__flexGap20} id={buttonGroupId}>
                        <IconButton title="Download Charts" aria-label="download" onClick={handleDownloadCard} disableRipple>
                            <DownloadIcon />
                        </IconButton>
                    </Box>
                </Box>

                {/* chart */}
                {
                    responseHandler(
                        data.campaignSurveyDatalist,
                        status.isFetchingCampaignSurveyDatalist,
                        (
                            <MixedChart 
                                payload={ data.campaignSurveyDatalist.chart}
                                chartHeight="340px"
                                chartColors={[]}
                                dontAddUnknown={true}
                                translate={translate}
                                multipleYAxis={[
                                    {
                                        name: translate("label.TOTAL_SCAN_QR"),
                                        color: "#6184FF"
                                    },
                                    {
                                        name: translate("label.CAMPAIGN_ENGAGED"),
                                        color: "#35CA91",
                                        opposite: true
                                    }
                                ]}
                                dashLine={true}
                            />
                        ),
                        (
                            <ChartCardLoading />
                        ),
                        (
                            <EmptyChart
                                data={[translate("label.SURVEY")]}
                                title={translate("label.CUSTOMER_NOT_FOUND")}
                                value={translate("paragraph.NO_DATA_ON_SELECTED_FILTER")}
                                labels= {['10 July', '11 July', '12 July', '13 July']}
                            />
                        ),
                        (
                            <EmptyChart
                                data={[translate("label.SURVEY")]}
                                title={translate("label.CUSTOMER_NOT_FOUND")}
                                value={translate("paragraph.NO_DATA_ON_SELECTED_FILTER")}
                                labels= {['10 July', '11 July', '12 July', '13 July']}
                            />
                        ),
                        <EmptyChart
                            data={[translate("label.SURVEY")]}
                            title={translate("label.LOADING_FAILED")}
                            value={translate("paragraph.LOADING_FAILED_DESCRIPTION")}
                            labels= {['10 July', '11 July', '12 July', '13 July']}
                        />
                    )
                }

                {/* Table */}
                <Box
                    marginTop="24px"
                >
                    <DynamicTable 
                        sort={sort}
                        pagination={pagination}
                        columns={columns}
                        data={renderData(data.campaignSurveyDatalist)}
                        status={status.isFetchingCampaignSurveyDatalist}
                        translate={translate}
                    />
                </Box>
            </Box>
            {/* campaign performance end */}

        </ContentCard>
    )
}