import axios from "axios";

const put = ({ id, progress }) => {
  const body = {
    progress: progress
  };

  return new Promise((resolve, reject) => {
    axios
      .put(`/campaign/api/admin/lucky-draws/${id}/progress`, body)
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

const changeProgress = {
  put
};

export default changeProgress;
