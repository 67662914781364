export function datetimeValidation(date_start, date_end) {
  let valid = true;
  let dateStart = new Date(date_start);
  let dateEnd = new Date(date_end);

  if (dateStart > dateEnd) valid = false;

  if (isNaN(dateStart.getTime()) || isNaN(dateEnd.getTime())) valid = false;

  // if (dateStart > new Date(Date.now()) || dateEnd > new Date(Date.now()))
  //   valid = false;

  if (!valid) {
    dateStart = new Date(Date.now() - 13 * 24 * 60 * 60 * 1000);
    dateEnd = new Date(Date.now());
  }

  return [dateStart, dateEnd];
}
