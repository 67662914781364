import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { generatePaginationOptions } from 'lib/helper';
import {
  getRedemptionList,
  getRedemptionDetails,
  respondRedemption
} from "modules/stockflow/redux";
import { selectLoading } from "modules/notification";
import RedemptionPanelComponent from './redemptionPanel.component';
import RedemptionDetailsDialog from '../../dialog/redemptionDetails';
import { unwrapResult } from '@reduxjs/toolkit';
import { MY_DEALER_LIST_PAGE } from "modules/stockflow/config/stockflow.route";
import { ROOT} from "modules/employee/config/employee.route";
import { useHistory } from "react-router-dom";
import { STOCKFLOW_DEALER_VIEW } from "lib/constants/accessRights";

export default function RedemptionPanelContainer({
  id
}) {
  const dispatch = useDispatch();
  const history = useHistory();

  const hasStockflowDealerViewAccessRight = useSelector(state => state.profile.accessRights.includes(STOCKFLOW_DEALER_VIEW));
  const [tableFilter, setTableFilter] = useState({
    page: 0,
    rowsPerPage: 25,
  })
  const [paginationOptions, setPaginationOptions] = useState([]);
  const isFetching = useSelector(state => selectLoading(state, getRedemptionList.typePrefix));
  const isFetchingDetails = useSelector(state => selectLoading(state, getRedemptionDetails.typePrefix));
  const isError = useSelector(state => state.stockflowRewardCenter.redemptionList.isError);
  const isSearchResult = false;
  const data = useSelector(state => state.stockflowRewardCenter.redemptionList.data);
  const totalFiltered = useSelector(state => state.stockflowRewardCenter.redemptionList.totalFiltered);
  const details = useSelector(state => state.stockflowRewardCenter.redemptionDetail)
  const [isViewDialogOpen, setIsViewDialogOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const language = useSelector((state)=> state.profile.language)
  const [remarks, setRemarks] = useState("")

  useEffect(() => {
    dispatch(getRedemptionList({
      length: tableFilter.rowsPerPage,
      start: tableFilter.rowsPerPage * tableFilter.page,
      id: id
    }))
  }, [dispatch, tableFilter, id])

  useEffect(() => {
    setPaginationOptions(generatePaginationOptions(totalFiltered))
  }, [totalFiltered])

  const handleChangePage = (event, newPage) => {
    setTableFilter((prev) => ({
      ...prev,
      page: newPage
    }))
  }

  const handleViewDealer = id => history.push(`${MY_DEALER_LIST_PAGE}/${id}`);

  const handleViewEmployee = id => history.push(`${ROOT}/View/${id}`);

  const handleChangeRowPerPage = (event) => {
    setTableFilter((prev) => ({
      ...prev,
      page: 0,
      rowsPerPage: event.target.value
    }))
  }

  const handleFetchRedemptionList = () => {
    dispatch(getRedemptionList({
      length: tableFilter.rowsPerPage,
      start: tableFilter.rowsPerPage * tableFilter.page,
      id: id
    }))
  }

  const handleViewDetail = (uuid) => {
    setIsViewDialogOpen(true)
    dispatch(getRedemptionDetails({
      id: uuid
    }))
  }

  const handleSelectedStatus = (status) => {
    setSelectedStatus(status)
  }

  const handleChangeRemarks = (value) => {
    setRemarks(value);
  }

  const handleUpdateStatus = (id) => {
    let statusTemp = 0;

    if (selectedStatus === "Approve") {
      statusTemp = 1
    }

    if (selectedStatus === "Reject") {
      statusTemp = 2
    }

    if (remarks === "" || statusTemp === 0) {
      return;
    }

    dispatch(respondRedemption({
      status: statusTemp,
      remarks: remarks,
      id: id
    }))
      .then(unwrapResult)
      .then(() => {
        handleFetchRedemptionList()
      })
      .finally(() => {
        setIsViewDialogOpen(false)
      })
  }

  return (
    <>
      <RedemptionPanelComponent
        handleChangePage={handleChangePage}
        handleChangeRowPerPage={handleChangeRowPerPage}
        handleViewDetail={handleViewDetail}
        language={language}
        isError={isError}
        isFetching={isFetching}
        isSearchResult={isSearchResult}
        page={tableFilter.page}
        rowsPerPage={tableFilter.rowsPerPage}
        paginationOptions={paginationOptions}
        totalFiltered={totalFiltered}
        rows={data}
      />
      <RedemptionDetailsDialog
        hasStockflowDealerViewAccessRight={hasStockflowDealerViewAccessRight}
        isOpen={isViewDialogOpen}
        handleClose={() => setIsViewDialogOpen(false)}
        language={language}
        details={details}
        handleSelectedStatus={handleSelectedStatus}
        selectedStatus={selectedStatus}
        remarks={remarks}
        isFetchingDetails={isFetchingDetails}
        handleChangeRemarks={handleChangeRemarks}
        handleUpdateStatus={handleUpdateStatus}
        handleViewDealer={handleViewDealer}
        handleViewEmployee={handleViewEmployee}
      />
    </>
  );
}