import axios from "axios";

const url = "/serialnumber/api/v1/product/brand-models/bulk-update-status";

export function updateBrandModelsStatus({
  filter,
  update
}) {
  return new Promise((resolve, reject) => {
    axios.post(url, {
      filter,
      update
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data))
  })
}