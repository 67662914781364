import React from "react";
import {
  Box,
  CircularProgress,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { getLang } from "app/feature/constants";
import NoDataChart from "../../chart/noDataChart";
import MixedChart from "../../chart/mixed-chart";

const useStyles = makeStyles((theme) => ({
  panel: {
    background: "white",
    border: "1px solid #D0D5DD",
    borderRadius: 12,
    padding: 20,
    marginTop: 16,
  },
  loadingSection: {
    backgroundColor: "#FFFFFF",
    borderRadius: "12px",
    border: "1px solid #D0D5DD",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "458px",
    marginTop: 16,
  },
  float: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
}));

export default function ScanAgainstSuspendPanelComponent({
  lang,
  data,
  loading,
}) {
  const classes = useStyles();

  const datasets = [
    getLang(lang, "label.TOTAL_SCAN"),
    getLang(lang, "label.SUSPENDED"),
  ].map((item) => {
    return {
      name: item,
      data: [0, 0, 0, 0],
    };
  });

  return loading ? (
    <Box className={classes.loadingSection}>
      <CircularProgress />
    </Box>
  ) : !data.isSuccess || data.noResult ? (
    <Box className={classes.panel} position="relative">
      <Box zIndex={0} style={{ opacity: 0.2 }}>
        <NoDataChart
          labels={["10 July", "11 July", "12 July", "13 July"]}
          datasets={datasets}
          type="bar"
        />
      </Box>
      <Box className={classes.float}>
        <Typography align="center" variant="body2">
          <b>
            {getLang(
              lang,
              !data.isSuccess
                ? "label.LOADING_FAILED"
                : "label.CUSTOMER_NOT_FOUND"
            )}
          </b>
        </Typography>
        <Typography align="center" variant="body2">
          {getLang(
            lang,
            !data.isSuccess
              ? "paragraph.LOADING_FAILED_DESCRIPTION"
              : "paragraph.NO_DATA_ON_SELECTED_FILTER"
          )}
        </Typography>
      </Box>
    </Box>
  ) : (
    <Box className={classes.panel}>
      <Typography variant="body1" style={{ marginBottom: 8 }}>
        <b>{getLang(lang, "label.TOTAL_SCAN_COUNT_AGAINST_SUSPENDED_QR")}</b>
      </Typography>
      <MixedChart
        payload={data.chart}
        chartHeight="340px"
        chartColors={[]}
        dontAddUnknown={true}
        lang={lang}
        multipleYAxis={[
          {
            name: getLang(lang, "label.TOTAL_SCAN_QR"),
            color: "#6184FF",
          },
          {
            name: getLang(lang, "label.SUSPENDED_QR"),
            color: "#F97066",
            opposite: true,
          },
        ]}
      />
    </Box>
  );
}
