import axios from "axios";

const one = ({id}) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`/campaign/api/admin/lucky-draw/winners/draw-one/${id}`)
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

const all = ({id}) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`/campaign/api/admin/lucky-draw/winners/draw-all/${id}`)
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

const liveDraw = {
  one,
  all,
};

export default liveDraw;
