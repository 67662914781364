import {
  addPaymentMethod,
  updateDefaultPaymentMethod,
  deletePaymentMethod,
  fetchQuickSubscriptionSummary,
  fetchBillingDetail,
  updateBillingDetail,
  fetchSubscriptionPlan,
  fetchTransactionLatest,
  fetchTransactionHistory,
  fetchPaymentMethods
} from "./billing.action";
import {
  setBillingDetail,
  setSubcriptionPlan,
  setTransactionHistory,
  setTransactionLatest
} from "./billing.slice";
import { setLoading, addAlert } from "modules/notification";
import { getApiLang } from "app/feature/constants";

const addPaymentMethodMiddleware = ({ dispatch, getState }) => (next) => (action) => {
  next(action);

  const { type, payload } = action;
  const lang = getState().constant.languages

  switch (type) {
    case addPaymentMethod.pending.type:
      next(setLoading({ id: addPaymentMethod.typePrefix, state: true }));
      break;

    case addPaymentMethod.fulfilled.type:
      next(setLoading({ id: addPaymentMethod.typePrefix, state: false }));
      dispatch(addAlert({ severity: "success", message: getApiLang(lang, "success.SUCCESS_ADD_PAYMENT_METHOD") }));
      break;
    case addPaymentMethod.rejected.type:
      next(setLoading({ id: addPaymentMethod.typePrefix, state: false }));
      next(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
      break;

    default:
      break;
  }
};

const fetchQuickSubscriptionSummaryMiddleware = () => (next) => (action) => {
  next(action);

  const { type } = action;

  switch (type) {
    case fetchQuickSubscriptionSummary.pending.type:
      next(
        setLoading({
          id: fetchQuickSubscriptionSummary.typePrefix,
          state: true
        })
      );
      break;

    case fetchQuickSubscriptionSummary.fulfilled.type:
      next(
        setLoading({
          id: fetchQuickSubscriptionSummary.typePrefix,
          state: false
        })
      );
      break;
    case fetchQuickSubscriptionSummary.rejected.type:
      next(
        setLoading({
          id: fetchQuickSubscriptionSummary.typePrefix,
          state: false
        })
      );
      break;

    default:
      break;
  }
};

const updateDefaultPaymentMethodMiddleware = ({ dispatch, getState }) => (next) => (
  action
) => {
  next(action);

  const { type, payload } = action;
  const lang = getState().constant.languages

  switch (type) {
    case updateDefaultPaymentMethod.pending.type:
      dispatch(
        setLoading({ id: updateDefaultPaymentMethod.typePrefix, state: true })
      );
      break;

    case updateDefaultPaymentMethod.fulfilled.type:
      dispatch(
        setLoading({ id: updateDefaultPaymentMethod.typePrefix, state: false })
      );
      dispatch(addAlert({ severity: "success", message: getApiLang(lang, "success.SUCCESS_SET_PRIMARY_CARD") }));
      break;

    case updateDefaultPaymentMethod.rejected.type:
      dispatch(
        setLoading({ id: updateDefaultPaymentMethod.typePrefix, state: false })
      );
      dispatch(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
      break;

    default:
      break;
  }
};

const deletePaymentMethodMiddleware = ({ dispatch, getState }) => (next) => (action) => {
  next(action);

  const { type, payload } = action;
  const lang = getState().constant.languages

  switch (type) {
    case deletePaymentMethod.pending.type:
      dispatch(setLoading({ id: deletePaymentMethod.typePrefix, state: true }));
      break;

    case deletePaymentMethod.fulfilled.type:
      dispatch(
        setLoading({ id: deletePaymentMethod.typePrefix, state: false })
      );
      dispatch(addAlert({ severity: "success", message: getApiLang(lang, "success.SUCCESS_DELETE_PAYMENT_METHOD") }));
      break;

    case deletePaymentMethod.rejected.type:
      dispatch(
        setLoading({ id: deletePaymentMethod.typePrefix, state: false })
      );
      dispatch(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
      break;

    default:
      break;
  }
};

export const fetchBillingDetailMiddleware = ({ dispatch, getState }) => (next) => (
  action
) => {
  next(action);

  const { type, payload } = action;
  const lang = getState().constant.languages

  switch (type) {
    case fetchBillingDetail.pending.type:
      dispatch(setLoading({ id: fetchBillingDetail.typePrefix, state: true }));
      break;

    case fetchBillingDetail.fulfilled.type:
      next(setBillingDetail(action.payload));
      dispatch(setLoading({ id: fetchBillingDetail.typePrefix, state: false }));
      break;

    case fetchBillingDetail.rejected.type:
      dispatch(setLoading({ id: fetchBillingDetail.typePrefix, state: false }));
      dispatch(addAlert({ severity: "error", message: getApiLang(lang, payload.code)}));
      break;

    default:
      break;
  }
};

export const updateBillingDetailMiddleware = ({ dispatch, getState }) => (next) => async (
  action
) => {
  next(action);

  const { type, payload } = action;
  const lang = getState().constant.languages

  switch (type) {
    case updateBillingDetail.pending.type:
      // dispatch set loading to notification reducer
      dispatch(setLoading({ id: updateBillingDetail.typePrefix, state: true }));
      break;

    case updateBillingDetail.fulfilled.type:
      // dispatch remove loading from notification reducer
      next(setBillingDetail(action.meta.arg));
      dispatch(
        setLoading({ id: updateBillingDetail.typePrefix, state: false })
      );
      dispatch(addAlert({ severity: "success", message: getApiLang(lang, "success.SUCCESS_UPDATE_BILLING_DETAIL") }));
      break;

    case updateBillingDetail.rejected.type:
      dispatch(
        setLoading({ id: updateBillingDetail.typePrefix, state: false })
      );
      dispatch(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
      break;

    default:
      break;
  }
};

export const fetchSubscriptionPlanMiddleware = ({ dispatch, getState }) => (next) => (
  action
) => {
  next(action);

  const { type, payload } = action;
  switch (type) {
    case fetchSubscriptionPlan.pending.type:
      dispatch(
        setLoading({ id: fetchSubscriptionPlan.typePrefix, state: true })
      );
      break;

    case fetchSubscriptionPlan.fulfilled.type:
      next(setSubcriptionPlan(action.payload));
      dispatch(
        setLoading({ id: fetchSubscriptionPlan.typePrefix, state: false })
      );
      break;

    case fetchSubscriptionPlan.rejected.type:
      const lang = getState().constant.languages
      dispatch(
        setLoading({ id: fetchSubscriptionPlan.typePrefix, state: false })
      );
      dispatch(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
      break;

    default:
      break;
  }
};

export const fetchTransactionHistoryMiddleware = ({ dispatch, getState }) => (next) => (
  action
) => {
  next(action);

  const { type, payload } = action;
  switch (type) {
    case fetchTransactionHistory.pending.type:
      dispatch(
        setLoading({ id: fetchTransactionHistory.typePrefix, state: true })
      );
      break;

    case fetchTransactionHistory.fulfilled.type:
      next(setTransactionHistory(action.payload));
      dispatch(
        setLoading({ id: fetchTransactionHistory.typePrefix, state: false })
      );
      break;

    case fetchTransactionHistory.rejected.type:
      const lang = getState().constant.languages
      dispatch(
        setLoading({ id: fetchTransactionHistory.typePrefix, state: false })
      );
      dispatch(addAlert({ severity: "error", message: getApiLang(lang, payload.code)}));
      break;

    default:
      break;
  }
};

export const fetchTransactionLatestMiddleware = ({ dispatch, getState }) => (next) => (
  action
) => {
  next(action);

  const { type, payload } = action;
  switch (type) {
    case fetchTransactionLatest.pending.type:
      dispatch(
        setLoading({ id: fetchTransactionLatest.typePrefix, state: true })
      );
      break;

    case fetchTransactionLatest.fulfilled.type:
      next(setTransactionLatest(action.payload));
      dispatch(
        setLoading({ id: fetchTransactionLatest.typePrefix, state: false })
      );
      break;

    case fetchTransactionLatest.rejected.type:
      const lang = getState().constant.languages
      dispatch(
        setLoading({ id: fetchTransactionLatest.typePrefix, state: false })
      );
      dispatch(addAlert({ severity: "error", message: getApiLang(lang, payload.code)}));
      break;

    default:
      break;
  }
};

const fetchPaymentMethodsMiddleware = () => next => action => {
  if (action.type === fetchPaymentMethods.fulfilled.type) {
    const { payload } = action;

    // put primary card as the first element in the array
    const paymentMethods = payload.data.sort(function(x, y) {
      return x.default === y.default ? 0 : x.default ? -1 : 1;
    });

    next({ ...action, payload: { ...action.payload, data: paymentMethods } });
  } else next(action);
};

export const billingMiddleware = [
  addPaymentMethodMiddleware,
  updateDefaultPaymentMethodMiddleware,
  deletePaymentMethodMiddleware,
  fetchQuickSubscriptionSummaryMiddleware,
  fetchBillingDetailMiddleware,
  updateBillingDetailMiddleware,
  fetchSubscriptionPlanMiddleware,
  fetchTransactionHistoryMiddleware,
  fetchTransactionLatestMiddleware,
  fetchPaymentMethodsMiddleware
];
