import { Avatar, Box, Chip, Grid, Typography } from "@material-ui/core";
import { LoyaltyPointIcon } from "modules/reporting/assets/svg";
import { ContentCard } from "modules/reporting/components/layout";
import { BoxLoading } from "modules/reporting/components/skeleton/loading";
import { responseHandler } from "modules/reporting/utils/helper";
import React from "react";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { BoxError } from "modules/reporting/components/skeleton/error";
import { borderStyle, chipStyle, fontTextStyle, iconStyle } from "modules/reporting/styles";

export default function ProductDetailPanelComponent({
    status,
    data,
    translate
}) {

    const chipClasses = chipStyle();
    const iconClasses = iconStyle();
    const fontClasses = fontTextStyle();
    const borderClasses = borderStyle();

    const renderData = (ref) => {

        if (Object.keys(ref).length === 0 || !ref.isSuccess){
            return null;
        }

        // if(!ref.isSuccess)
        //     return null;

        // console.log(ref, 'hmm')

        const { product_detail } = ref;
        return (
            <Grid container spacing={3}>
                {/* product detail : 1st part */}
                <Grid item xs={12} sm={12} md={4}
                    style={{
                        display: "flex",
                        // alignContent:"center",
                        flexWrap: "wrap",
                    }}
                >
                    <Box
                        display="flex"
                        style={{
                            gap: "6px"
                        }}
                    >
                        <Avatar alt="product image" src={product_detail.image_url} className={iconClasses.image__icon}/>
                        <Box
                            display="flex"
                            flexDirection="column"
                            style={{
                                gap: "6px"
                            }}
                        >
                            <Chip label="PRODUCT" className={chipClasses.chip__product}/>
                            <Typography className={fontClasses.text__detail_title}>
                                {product_detail.name}
                            </Typography>
                            <Box
                                display="flex"
                                style={{
                                    gap: "6px"
                                }}
                            >
                                <Chip 
                                    icon={<LoyaltyPointIcon />} 
                                    label={product_detail.incentive_point}
                                    className={chipClasses.chip__incentive_points}
                                />
                                <Chip 
                                    icon={<FiberManualRecordIcon className={fontClasses.text__success}/>}
                                    label={product_detail.status ? "Published" : "Unpublished"}
                                    className={product_detail.status? chipClasses.chip__publish_success : chipClasses.chip__publish_failed}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Grid>

                {/* product detail : 2nd part */}
                <Grid item xs={12} sm={12} md={4} 
                    className={borderClasses.border__middle_detail}
                >
                    <Grid container spacing={3} alignItems="center" >
                        <Grid item xs={3}>
                            <Typography className={fontClasses.text__detail_subtitle}>
                                {translate("label.PRODUCT")}
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography className={fontClasses.text__detail_subtitle}>
                                :
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography className={fontClasses.text__detail_subtitle_value}>
                                {product_detail.category ?? "-"}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} alignItems="center" >
                        <Grid item xs={3}>
                            <Typography className={fontClasses.text__detail_subtitle}>
                                {translate("label.BRAND")}
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography className={fontClasses.text__detail_subtitle}>
                                :
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography className={fontClasses.text__detail_subtitle_value}>
                                {product_detail.brand ?? "-"}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} alignItems="center" >
                        <Grid item xs={3}>
                            <Typography className={fontClasses.text__detail_subtitle}>
                                {translate("label.MODEL")}
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography className={fontClasses.text__detail_subtitle}>
                                :
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography className={fontClasses.text__detail_subtitle_value}>
                                {product_detail.model ?? "-"}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} >
                        <Grid item xs={3}>
                            <Typography className={fontClasses.text__detail_subtitle}>
                                {translate("label.ATTRIBUTES")}
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography className={fontClasses.text__detail_subtitle}>
                                :
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Box
                                display="flex"
                                style={{
                                    gap: "6px",
                                    flexWrap: "wrap"
                                }}
                            >
                                {
                                    product_detail.attributes.length > 0 ? 
                                    (
                                        product_detail.attributes.map((attribute, index) => {
                                            return (
                                                <Chip 
                                                    key={index}
                                                    label={attribute}
                                                    className={chipClasses.chip__attributes}
                                                />
                                            )
                                        })
                                    ) :
                                    (
                                        "-"
                                    )
                                }
                            </Box>

                        </Grid>
                    </Grid>
                </Grid>

                {/* product detail : 3rd part */}
                <Grid item xs={12} sm={12} md={4}>
                    <Grid container spacing={3} alignItems="center" >
                        <Grid item xs={3}>
                            <Typography className={fontClasses.text__detail_subtitle}>
                                {translate("label.PRICE")}
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography className={fontClasses.text__detail_subtitle}>
                                :
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography className={fontClasses.text__detail_subtitle_value}>
                                {product_detail.price ?? "-"}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} alignItems="center" >
                        <Grid item xs={3}>
                            <Typography className={fontClasses.text__detail_subtitle}>
                                {translate("label.SKU")}
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography className={fontClasses.text__detail_subtitle}>
                                :
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography className={fontClasses.text__detail_subtitle_value}>
                                {product_detail.sku ?? "-"}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} alignItems="center" >
                        <Grid item xs={3}>
                            <Typography className={fontClasses.text__detail_subtitle}>
                                {translate("label.SCAN_LIMIT")}
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography className={fontClasses.text__detail_subtitle}>
                                :
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography className={fontClasses.text__detail_subtitle_value}>
                                {translate(product_detail.scanlimit) ?? "-"}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
    return (
        <ContentCard>
            <Box
                padding="15px 20px"
            >
                {
                    responseHandler(
                        data.productDetailData,
                        status.isFetchingProductDetailData,
                        (
                            renderData(data.productDetailData)
                        ),
                        (
                            <BoxLoading />
                        ),
                        (
                            <>empty</>
                        ),
                        (
                            <>no result</>
                        ),
                        (
                            <BoxError 
                                title={translate("label.LOADING_FAILED")}
                                value={translate("paragraph.LOADING_FAILED_DESCRIPTION")}
                            />
                        )
                    )
                }
            </Box>

        </ContentCard>
    );
}