import axios from "axios";

export function GetProgramDetails({ id }) {
  const url = `/distributor/admin/api/v1/programs/${id}`;

  return new Promise((resolve, reject) => {
    axios.get(url)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data))
  })
}