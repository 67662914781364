import { createAsyncThunk } from "@reduxjs/toolkit";
import stockflowApi from "app/api/stockflow";

export const STOCKFLOWTRANSFER = "stockflowTransfer";

export const getTransferList = createAsyncThunk(
  `${STOCKFLOWTRANSFER}/getTransferList`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi
      .getTransferList({
        ...payload,
      })
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const getTransferDetail = createAsyncThunk(
  `${STOCKFLOWTRANSFER}/getTransferDetail`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi
      .getTransferDetail(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const getTransferReceiverDropdown = createAsyncThunk(
  `${STOCKFLOWTRANSFER}/getTransferReceiverDropdown`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi
      .getTransferReceiverDropdown(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const getTransferSenderDropdown = createAsyncThunk(
  `${STOCKFLOWTRANSFER}/getTransferSenderDropdown`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi
      .getTransferSenderDropdown(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const getTransferItemBoxList = createAsyncThunk(
  `${STOCKFLOWTRANSFER}/getTransferItemBoxList`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi
      .getTransferItemBoxList(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const getTransferItemBoxProductList = createAsyncThunk(
  `${STOCKFLOWTRANSFER}/getTransferItemBoxProductList`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi
      .getTransferItemBoxProductList(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const getTransferItemProductList = createAsyncThunk(
  `${STOCKFLOWTRANSFER}/getTransferItemProductList`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi
      .getTransferItemProductList(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const getTransferItemProductSNList = createAsyncThunk(
  `${STOCKFLOWTRANSFER}/getTransferItemProductSNList`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi
      .getTransferItemProductSNList(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const approveTransfer = createAsyncThunk(
  `${STOCKFLOWTRANSFER}/approveTransfer`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi
      .approveTransfer(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const rejectApproveTransfer = createAsyncThunk(
  `${STOCKFLOWTRANSFER}/rejectApproveTransfer`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi
      .rejectApproveTransfer(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const updateTransferDeliveryInfo = createAsyncThunk(
  `${STOCKFLOWTRANSFER}/updateTransferDeliveryInfo`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi
      .updateTransferDeliveryInfo(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const getTransferDeliveryInfo = createAsyncThunk(
  `${STOCKFLOWTRANSFER}/getTransferDeliveryInfo`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi
      .getTransferDeliveryInfo(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const generateDnPdf = createAsyncThunk(
  `${STOCKFLOWTRANSFER}/generateDnPdf`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi
      .generateDnPdf(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);
