import React from "react";
import { makeStyles, Box } from "@material-ui/core";
import Marquee from "react-marquee-slider";
import backgroundLogin from "../../../../../assets/img/grow-business-data.png";

const useStyle = makeStyles(theme => ({
  root: {
    minHeight: "100vh",
    width: "100%",
    flexFlow: "column nowrap",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    backgroundColor: "#E5E5E5"
  },
  background: {
    position: "fixed",
    height: "132px",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      width: "130%"
    },
    [theme.breakpoints.down("xs")]: {
      width: "250%"
    }
  },
  reference: {
    bottom: "40px",
    position: "absolute",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      bottom: "50px"
    },
    [theme.breakpoints.down("xs")]: {
      bottom: "20px"
    }
  },
  center: {
    alignItems: "center"
  }
}));

export default function LoginLayoutComponent({ children }) {
  const classes = useStyle();

  return (
    <Box className={classes.root}>
      <Box className={classes.background}>
        <Marquee velocity={70}>
          <></>
          <img src={backgroundLogin} alt="login background" width="100%" />
        </Marquee>
      </Box>
      {children}
    </Box>
  );
}
