import { Content } from "modules/reporting/components/layout";
import { 
    DealershipPanel, 
    FilterPanelV3,
    StockoutBrandPanel,
    StockoutCategoryPanel,
    StockoutModelPanel,
    StockoutProductPanel,
    // StockoutBrandPanel, 
    // StockoutModelPanel 
} from "modules/reporting/components/panel";
import React from "react";

export default function DealershipPage({
    filter,
    currentFilter,
    tagUrl,
    pageMode,
    translate
}) {
    const pageType = "dealership";
    return (
        <>
            <FilterPanelV3
                filter={filter}
                currentFilter={currentFilter}
                pageMode={pageMode}
                translate={translate}
            />

            <Content>
                {/* trending & stockout by branch --- START */}
                <DealershipPanel 
                    currentFilter={currentFilter}
                    // urlActiveDealer="http://localhost:9080/activeDealer"
                    // urlInactiveDealer="http://localhost:9080/inactiveDealer"
                    // urlDealerStockOut="http://localhost:9080/dealerStockout"
                    // urlTotalRecall="http://localhost:9080/totalRecall"
                    // urlNewDealer="http://localhost:9080/newDealer"
                    // urlRequestStockout="http://localhost:9080/requestStockout"
                    // urlDealerStockoutBranch="http://localhost:9080/dealerStockoutBranch"
                    // urlDistributionPerformance="http://localhost:9080/dealerDistibutionPerformance"
                    urlItemTotalPackage={tagUrl + "get-total-package-drilldown"}
                    urlItemTotalRequest={tagUrl + "get-total-request-drilldown"}
                    urlItemTotalReject={tagUrl + "get-total-reject-drilldown"}
                    
                    urlDealerStockOut={tagUrl + "get-dealer-stock-out"}
                    urlTotalRecall={tagUrl + "get-total-recall"}
                    urlNewDealer={tagUrl + "get-new-dealer"}
                    urlRequestStockout={tagUrl + "get-total-request-stock"}
                    urlTotalPackage={tagUrl + "get-total-package"}
                    urlTotalRecalledPackage={ tagUrl + "get-total-recall-package"}

                    urlDealerStockoutBranch={tagUrl + "get-stockout-by-branch"}
                    

                    translate={translate}

                    pageType={pageType}
                />
                {/* trending & stockout by branch --- END */}

                {/* stockout by product --- START */}
                <StockoutProductPanel 
                    currentFilter={currentFilter}
                    // urlDealerStockoutProduct="http://localhost:9080/dealerStockoutProduct"
                    urlDealerStockoutProduct={tagUrl + "get-stockout-by-product"}
                    translate={translate}
                    pageType={pageType}
                />
                {/* stockout by product --- END */}

                {/* stockout by category --- START */}
                <StockoutCategoryPanel
                    currentFilter={currentFilter}
                    // urlDealerStockoutCategory="http://localhost:9080/dealerStockoutCategory"
                    urlDealerStockoutCategory={tagUrl + "get-stockout-by-category"}
                    translate={translate}
                    pageType={pageType}
                />
                {/* stockout by category --- END */}

                {/* stockout by brand --- START */}
                <StockoutBrandPanel 
                    currentFilter={currentFilter}
                    // urlDealerStockoutBrand="http://localhost:9080/dealerStockoutBrand"
                    urlDealerStockoutBrand={tagUrl + "get-stockout-by-brand"}
                    translate={translate}
                    pageType={pageType}
                />
                {/* stockout by brand --- END */}

                {/* stockout by model --- START */}
                <StockoutModelPanel
                    currentFilter={currentFilter}
                    // urlDealerStockoutModel="http://localhost:9080/dealerStockoutModel"
                    urlDealerStockoutModel={tagUrl + "get-stockout-by-model"}
                    translate={translate}
                    pageType={pageType}
                />
                {/* stockout by model --- END */}
            </Content>
        </>
    )
}