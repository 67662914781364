import { getApiLang } from "app/feature/constants";
import { setLoading, addAlert } from "modules/notification";
import {
  getInventoryProductDetail,
  getInventoryProductStockList,
  getSerialNumberActivityLog,
  getInventoryPackageListing,
  getInventoryPackageDetail,
  getPackageActivityLog,
  getInventoryBoxListing,
  getInventoryBoxDetail,
  getBoxActivityLog,
  getStockProductDropdown,
  getStockHolderDropdown,
  getInventoryLooseItemDetail,
  getInventoryLooseItemStockList,
  getInventoryLooseItemListing,
  getProductListingByBranch,
  getProductListingByDealer,
  getBranchInventory,
  getDealerInventory,
} from "../action/stockflowInventory.action";

const getProductListingByBranchMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, meta, payload } = action;
    const lang = getState().constant.languages;

    switch (type) {
      case getProductListingByBranch.pending.type:
        dispatch(
          setLoading({ id: getProductListingByBranch.typePrefix, state: true })
        );
        break;
      case getProductListingByBranch.fulfilled.type:
        dispatch(
          setLoading({ id: getProductListingByBranch.typePrefix, state: false })
        );
        break;
      case getProductListingByBranch.rejected.type:
        dispatch(
          setLoading({ id: getProductListingByBranch.typePrefix, state: false })
        );
        if (!meta.arg.cancelToken?.reason?.message) {
          dispatch(
            addAlert({
              severity: "error",
              message: getApiLang(
                lang,
                payload.code === "error.ERROR_ACCESS_DENIED"
                  ? payload.code
                  : "error.ERROR_GET_PRODUCT_LIST"
              ),
            })
          );
        }
        break;
      default:
        break;
    }
  };

const getProductListingByDealerMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, meta, payload } = action;
    const lang = getState().constant.languages;

    switch (type) {
      case getProductListingByDealer.pending.type:
        dispatch(
          setLoading({ id: getProductListingByDealer.typePrefix, state: true })
        );
        break;
      case getProductListingByDealer.fulfilled.type:
        dispatch(
          setLoading({ id: getProductListingByDealer.typePrefix, state: false })
        );
        break;
      case getProductListingByDealer.rejected.type:
        dispatch(
          setLoading({ id: getProductListingByDealer.typePrefix, state: false })
        );
        if (!meta.arg.cancelToken?.reason?.message) {
          dispatch(
            addAlert({
              severity: "error",
              message: getApiLang(
                lang,
                payload.code === "error.ERROR_ACCESS_DENIED"
                  ? payload.code
                  : "error.ERROR_GET_PRODUCT_LIST"
              ),
            })
          );
        }
        break;
      default:
        break;
    }
  };

const getBranchInventoryMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, meta, payload } = action;
    const lang = getState().constant.languages;

    switch (type) {
      case getBranchInventory.pending.type:
        dispatch(
          setLoading({ id: getBranchInventory.typePrefix, state: true })
        );
        break;
      case getBranchInventory.fulfilled.type:
        dispatch(
          setLoading({ id: getBranchInventory.typePrefix, state: false })
        );
        break;
      case getBranchInventory.rejected.type:
        dispatch(
          setLoading({ id: getBranchInventory.typePrefix, state: false })
        );
        if (!meta.arg.cancelToken?.reason?.message) {
          dispatch(
            addAlert({
              severity: "error",
              message: getApiLang(
                lang,
                payload.code === "error.ERROR_ACCESS_DENIED"
                  ? payload.code
                  : "error.ERROR_GET_PRODUCT_LIST"
              ),
            })
          );
        }
        break;
      default:
        break;
    }
  };

const getDealerInventoryMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, meta, payload } = action;
    const lang = getState().constant.languages;

    switch (type) {
      case getDealerInventory.pending.type:
        dispatch(
          setLoading({ id: getDealerInventory.typePrefix, state: true })
        );
        break;
      case getDealerInventory.fulfilled.type:
        dispatch(
          setLoading({ id: getDealerInventory.typePrefix, state: false })
        );
        break;
      case getDealerInventory.rejected.type:
        dispatch(
          setLoading({ id: getDealerInventory.typePrefix, state: false })
        );
        if (!meta.arg.cancelToken?.reason?.message) {
          dispatch(
            addAlert({
              severity: "error",
              message: getApiLang(
                lang,
                payload.code === "error.ERROR_ACCESS_DENIED"
                  ? payload.code
                  : "error.ERROR_GET_PRODUCT_LIST"
              ),
            })
          );
        }
        break;
      default:
        break;
    }
  };

const getInventoryProductDetailMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages;

    switch (type) {
      case getInventoryProductDetail.pending.type:
        dispatch(
          setLoading({ id: getInventoryProductDetail.typePrefix, state: true })
        );
        break;
      case getInventoryProductDetail.fulfilled.type:
        dispatch(
          setLoading({ id: getInventoryProductDetail.typePrefix, state: false })
        );
        break;
      case getInventoryProductDetail.rejected.type:
        dispatch(
          setLoading({ id: getInventoryProductDetail.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_GET_PRODUCT_DETAIL"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const getInventoryProductStockListMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages;

    switch (type) {
      case getInventoryProductStockList.pending.type:
        dispatch(
          setLoading({
            id: getInventoryProductStockList.typePrefix,
            state: true,
          })
        );
        break;
      case getInventoryProductStockList.fulfilled.type:
        dispatch(
          setLoading({
            id: getInventoryProductStockList.typePrefix,
            state: false,
          })
        );
        break;
      case getInventoryProductStockList.rejected.type:
        dispatch(
          setLoading({
            id: getInventoryProductStockList.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_GET_PRODUCT_STOCK_LIST"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const getSerialNumberActivityLogMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages;

    switch (type) {
      case getSerialNumberActivityLog.pending.type:
        dispatch(
          setLoading({ id: getSerialNumberActivityLog.typePrefix, state: true })
        );
        break;
      case getSerialNumberActivityLog.fulfilled.type:
        dispatch(
          setLoading({
            id: getSerialNumberActivityLog.typePrefix,
            state: false,
          })
        );
        break;
      case getSerialNumberActivityLog.rejected.type:
        dispatch(
          setLoading({
            id: getSerialNumberActivityLog.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_GET_SN_ACTIVITY_LOG"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const getInventoryPackageListingMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages;

    switch (type) {
      case getInventoryPackageListing.pending.type:
        dispatch(
          setLoading({ id: getInventoryPackageListing.typePrefix, state: true })
        );
        break;
      case getInventoryPackageListing.fulfilled.type:
        dispatch(
          setLoading({
            id: getInventoryPackageListing.typePrefix,
            state: false,
          })
        );
        break;
      case getInventoryPackageListing.rejected.type:
        dispatch(
          setLoading({
            id: getInventoryPackageListing.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_GET_PACKAGE_LIST"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const getInventoryPackageDetailMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages;

    switch (type) {
      case getInventoryPackageDetail.pending.type:
        dispatch(
          setLoading({ id: getInventoryPackageDetail.typePrefix, state: true })
        );
        break;
      case getInventoryPackageDetail.fulfilled.type:
        dispatch(
          setLoading({ id: getInventoryPackageDetail.typePrefix, state: false })
        );
        break;
      case getInventoryPackageDetail.rejected.type:
        dispatch(
          setLoading({ id: getInventoryPackageDetail.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_GET_PACKAGE_DETAIL"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const getPackageActivityLogMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages;

    switch (type) {
      case getPackageActivityLog.pending.type:
        dispatch(
          setLoading({ id: getPackageActivityLog.typePrefix, state: true })
        );
        break;
      case getPackageActivityLog.fulfilled.type:
        dispatch(
          setLoading({ id: getPackageActivityLog.typePrefix, state: false })
        );
        break;
      case getPackageActivityLog.rejected.type:
        dispatch(
          setLoading({ id: getPackageActivityLog.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_GET_PACKAGE_ACTIVITY_LOG"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const getInventoryBoxListingMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages;

    switch (type) {
      case getInventoryBoxListing.pending.type:
        dispatch(
          setLoading({ id: getInventoryBoxListing.typePrefix, state: true })
        );
        break;
      case getInventoryBoxListing.fulfilled.type:
        dispatch(
          setLoading({ id: getInventoryBoxListing.typePrefix, state: false })
        );
        break;
      case getInventoryBoxListing.rejected.type:
        dispatch(
          setLoading({ id: getInventoryBoxListing.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_GET_BOX_LIST"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const getInventoryBoxDetailMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages;

    switch (type) {
      case getInventoryBoxDetail.pending.type:
        dispatch(
          setLoading({ id: getInventoryBoxDetail.typePrefix, state: true })
        );
        break;
      case getInventoryBoxDetail.fulfilled.type:
        dispatch(
          setLoading({ id: getInventoryBoxDetail.typePrefix, state: false })
        );
        break;
      case getInventoryBoxDetail.rejected.type:
        dispatch(
          setLoading({ id: getInventoryBoxDetail.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_GET_BOX_DETAIL"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const getBoxActivityLogMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages;

    switch (type) {
      case getBoxActivityLog.pending.type:
        dispatch(setLoading({ id: getBoxActivityLog.typePrefix, state: true }));
        break;
      case getBoxActivityLog.fulfilled.type:
        dispatch(
          setLoading({ id: getBoxActivityLog.typePrefix, state: false })
        );
        break;
      case getBoxActivityLog.rejected.type:
        dispatch(
          setLoading({ id: getBoxActivityLog.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_GET_BOX_ACTIVITY_LOG"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const getStockProductDropdownMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getStockProductDropdown.pending.type:
        dispatch(
          setLoading({ id: getStockProductDropdown.typePrefix, state: true })
        );
        break;
      case getStockProductDropdown.fulfilled.type:
        dispatch(
          setLoading({ id: getStockProductDropdown.typePrefix, state: false })
        );
        break;
      case getStockProductDropdown.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({ id: getStockProductDropdown.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

const getStockHolderDropdownMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getStockHolderDropdown.pending.type:
        dispatch(
          setLoading({ id: getStockHolderDropdown.typePrefix, state: true })
        );
        break;
      case getStockHolderDropdown.fulfilled.type:
        dispatch(
          setLoading({ id: getStockHolderDropdown.typePrefix, state: false })
        );
        break;
      case getStockHolderDropdown.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({ id: getStockHolderDropdown.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

const getInventoryLooseItemListingMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages;

    switch (type) {
      case getInventoryLooseItemListing.pending.type:
        dispatch(
          setLoading({
            id: getInventoryLooseItemListing.typePrefix,
            state: true,
          })
        );
        break;
      case getInventoryLooseItemListing.fulfilled.type:
        dispatch(
          setLoading({
            id: getInventoryLooseItemListing.typePrefix,
            state: false,
          })
        );
        break;
      case getInventoryLooseItemListing.rejected.type:
        dispatch(
          setLoading({
            id: getInventoryLooseItemListing.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_GET_LOOSE_ITEM_LIST"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const getInventoryLooseItemDetailMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages;

    switch (type) {
      case getInventoryLooseItemDetail.pending.type:
        dispatch(
          setLoading({
            id: getInventoryLooseItemDetail.typePrefix,
            state: true,
          })
        );
        break;
      case getInventoryLooseItemDetail.fulfilled.type:
        dispatch(
          setLoading({
            id: getInventoryLooseItemDetail.typePrefix,
            state: false,
          })
        );
        break;
      case getInventoryLooseItemDetail.rejected.type:
        dispatch(
          setLoading({
            id: getInventoryLooseItemDetail.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_GET_LOOSE_ITEM_DETAIL"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const getInventoryLooseItemStockListMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages;

    switch (type) {
      case getInventoryLooseItemStockList.pending.type:
        dispatch(
          setLoading({
            id: getInventoryLooseItemStockList.typePrefix,
            state: true,
          })
        );
        break;
      case getInventoryLooseItemStockList.fulfilled.type:
        dispatch(
          setLoading({
            id: getInventoryLooseItemStockList.typePrefix,
            state: false,
          })
        );
        break;
      case getInventoryLooseItemStockList.rejected.type:
        dispatch(
          setLoading({
            id: getInventoryLooseItemStockList.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_GET_LOOSE_ITEM_STOCK_LIST"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

export default [
  getInventoryProductDetailMiddleware,
  getInventoryProductStockListMiddleware,
  getSerialNumberActivityLogMiddleware,
  getInventoryPackageListingMiddleware,
  getInventoryPackageDetailMiddleware,
  getPackageActivityLogMiddleware,
  getInventoryBoxListingMiddleware,
  getInventoryBoxDetailMiddleware,
  getBoxActivityLogMiddleware,
  getInventoryLooseItemListingMiddleware,
  getInventoryLooseItemDetailMiddleware,
  getInventoryLooseItemStockListMiddleware,
  getStockProductDropdownMiddleware,
  getStockHolderDropdownMiddleware,
  getProductListingByBranchMiddleware,
  getProductListingByDealerMiddleware,
  getBranchInventoryMiddleware,
  getDealerInventoryMiddleware,
];
