import { Box, Button, makeStyles, styled, TableCell, Typography } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    tableRow: {
        padding: "100px 0",
    },
    row__content: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "8px",
    },
    row__title: {
        fontFamily: "Roboto",
        fontWeight: "700",
        fontSize: "16px",
        lineHeight: "24px",
        color: '#101828',
        textAlign: "center"
    },
    row__value: {
        fontFamily: "Roboto",
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "24px",
        color: '#98A2B3',
        textAlign: "center"
    }
}))

const ColorButton = styled(Button)(({ theme }) => ({
    color: '#FFFFFF',
    backgroundColor: '#3A4D54',
    '&:hover': {
        color: '#3A4D54',
        backgroundColor: '#FFFFFF',
    },
}));

export default function TableCellNoData({
    title, value, buttonText = null, link = null
}){
    const classes = useStyles();
    const history = useHistory();
    return (
        <TableCell align="center" className={classes.tableRow} colspan={"100%"}>
            <Box className={classes.row__content}>
                <Typography className={classes.row__title}>
                    {title}
                </Typography>
                <Typography className={classes.row__value}>
                    {value}
                </Typography>
                {
                    (buttonText && link) ? (
                        <ColorButton variant="contained" onClick={() => {
                            history.push(link);
                        }} >
                            {buttonText}
                        </ColorButton>
                    )                
                : 
                    null
                }

            </Box>
        </TableCell>
    )
}