import React, { useEffect, useState } from "react"
import { cloneDeep } from 'lodash'
import BoxProductListPanelComponent from "./boxProductListPanel.component"

export default function BoxProductListPanelContainer({
  box,
  isFetching,
  lang
}) {
  const [list, setList] = useState([])
  const [totalProduct, setTotalProduct] = useState(0)

  useEffect(() => {
    if (box) {
      setList(box.products || [])
      setTotalProduct(box.total_product_count)
    } else {
      setList([])
      setTotalProduct(0)
    }
  }, [box])
  
  const handleSearch = (values) => {
    if (values.sn) {
      let temp = cloneDeep(list)
      temp = temp.filter(product => 
        product.packages.findIndex(p => p.code.includes(values.sn)) >= 0
      )
  
      setList(temp)
    } else {
      setList(box.products)
    }
  }
  
  return (
    <BoxProductListPanelComponent
      products={list}
      totalProduct={totalProduct}
      isFetching={isFetching}
      handleSearch={handleSearch}
      lang={lang}
    />
  )
}