import { createAsyncThunk } from "@reduxjs/toolkit";
import reportingApi from "app/api/reporting";
import moment from "moment";

// (1) platform -- start
export const getPlatformData = createAsyncThunk(
    "reporting/getPlatformData",
    async (payload, thunkApi) => {
        return reportingApi.getPlatformData({
            country : payload.countryCode,
            city_id: payload.city_uuid,
            branch_uuid: payload.branch_uuid,
            brand_uuid: payload.brand_uuid,
            category_uuid: payload.category_uuid,
            product_uuid: payload.product_uuid,
            model_uuid: payload.model_uuid,
            period: payload.period,
            start_date: moment(payload.start_date).format('YYYY-MM-DD'),
            end_date: moment(payload.end_date).format('YYYY-MM-DD'),
            url: payload.url,
            campaign_type: payload.campaign_type,
            campaign_uuid: payload.campaign_uuid
        })
            .then((response) => response)
            .catch((error) => thunkApi.rejectWithValue(error));
    }
);
// (1) platform -- end

// (2) browser -- start
export const getBrowserData = createAsyncThunk(
    "reporting/getBrowserData",
    async (payload, thunkApi) => {
        return reportingApi.getBrowserData({
            country : payload.countryCode,
            city_id: payload.city_uuid,
            branch_uuid: payload.branch_uuid,
            category_uuid: payload.category_uuid,
            brand_uuid: payload.brand_uuid,
            model_uuid: payload.model_uuid,
            product_uuid: payload.product_uuid,
            period: payload.period,
            start_date: moment(payload.start_date).format('YYYY-MM-DD'),
            end_date: moment(payload.end_date).format('YYYY-MM-DD'),
            url: payload.url,
            campaign_type: payload.campaign_type,
            campaign_uuid: payload.campaign_uuid
        })
            .then((response) => response)
            .catch((error) => thunkApi.rejectWithValue(error));
    }
);
// (2) browser -- end

// (3) device -- start
export const getDeviceData = createAsyncThunk(
    "reporting/getDeviceData",
    async (payload, thunkApi) => {
        return reportingApi.getDeviceData({
            country : payload.countryCode,
            city_id: payload.city_uuid,
            branch_uuid: payload.branch_uuid,
            brand_uuid: payload.brand_uuid,
            category_uuid: payload.category_uuid,
            model_uuid: payload.model_uuid,
            product_uuid: payload.product_uuid,
            period: payload.period,
            start_date: moment(payload.start_date).format('YYYY-MM-DD'),
            end_date: moment(payload.end_date).format('YYYY-MM-DD'),
            url: payload.url,
            campaign_type: payload.campaign_type,
            campaign_uuid: payload.campaign_uuid
        })
            .then((response) => response)
            .catch((error) => thunkApi.rejectWithValue(error));
    }
);
// (3) device -- end

// (4) language -- start
export const getLanguageData = createAsyncThunk(
    "reporting/getLanguageData",
    async (payload, thunkApi) => {
        return reportingApi.getLanguageData({
            country : payload.countryCode,
            city_id: payload.city_uuid,
            branch_uuid: payload.branch_uuid,
            category_uuid: payload.category_uuid,
            brand_uuid: payload.brand_uuid,
            model_uuid: payload.model_uuid,
            product_uuid: payload.product_uuid,
            period: payload.period,
            start_date: moment(payload.start_date).format('YYYY-MM-DD'),
            end_date: moment(payload.end_date).format('YYYY-MM-DD'),
            url: payload.url,
            campaign_type: payload.campaign_type,
            campaign_uuid: payload.campaign_uuid
        })
            .then((response) => response)
            .catch((error) => thunkApi.rejectWithValue(error));
    }
);
// (4) language -- end