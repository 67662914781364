import React from "react";
import { makeStyles, Typography, Box, Divider, Link } from "@material-ui/core";
import OpenInNewRoundedIcon from "@material-ui/icons/OpenInNewRounded";
import { purple } from "@material-ui/core/colors";
import clsx from "clsx";
import LoadingComponent from "components/loading";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles(theme => ({
  root: {
    borderRadius: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    overflow: "hidden",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.15)"
  },
  image: {
    height: 40,
    width: 40,
    borderRadius: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    "& img": {
      height: "100%",
      flexShrink: 0,
      minWidth: "100%",
      minHeight: "100%",
      objectFit: "cover"
    }
  },

  link: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    color: purple[500]
  },
  openTabIcon: {
    color: purple[500]
  },
  desc: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: "40px",
    marginLeft: theme.spacing(2),
    overflow: "hidden",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    display: "-webkit-box"
  },
  margin0: {
    margin: 0
  },
  placeholder: {
    width: "100%",
    height: 120,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.background.paper
  }
}));

export default function WebsiteComponent({
  desc,
  alt_desc,
  title,
  url,
  image,
  favicon,
  error,
  isFetching,
  lang
}) {
  const classes = useStyle();

  const isEmpty = url === "";

  return (
    <Box mx={2} className={classes.root}>
      {isEmpty ? (
        <Box className={classes.placeholder}>
          <Typography
            variant="body2"
            color="primary"
            style={{ fontWeight: "bold" }}
          >
            {getLang(lang,"label.URL_COMPONENT")}
          </Typography>
        </Box>
      ) : (
        <>
          {isFetching ?
            <Box style={{
              height: 150
            }}>
              <LoadingComponent />
            </Box>
            :
            <>
              <Box display="flex" p={2} alignItems="center">
                {error === "" && (
                  <Box className={classes.image}>
                    <img src={image || favicon} alt="meta" />
                  </Box>
                )}
                <Typography
                  variant="body2"
                  className={clsx(
                    classes.desc,
                    error !== "" && classes.margin0
                  )}
                >
                  {desc || alt_desc || title}
                </Typography>
              </Box>
              <Divider />
              <Box display="flex">
                <Box
                  p={2}
                  display="flex"
                  flexGrow={1}
                  flexBasis="52px"
                  flexShrink={1}
                  alignItems="center"
                  overflow="hidden"
                >
                  <Link
                    href={url}
                    target="_blank"
                  >
                    <Typography variant="body2" className={classes.link}>
                      {url}
                    </Typography>
                  </Link>
                </Box>
                <Divider flexItem orientation="vertical" />
                <Box
                  width="52px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Link
                    href={url}
                    target="_blank"
                  >
                    <OpenInNewRoundedIcon
                      fontSize="small"
                      className={classes.openTabIcon}
                    />
                  </Link>
                </Box>
              </Box>
            </>
          }
        </>
      )}
    </Box>
  );
}
