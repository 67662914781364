import React from "react";
import { makeStyles, Box, Paper } from "@material-ui/core";
import {
  ArrowLeftRounded as ArrowLeftRoundedIcon,
  ArrowRightRounded as ArrowRightRoundedIcon,
} from "@material-ui/icons";
import LoadingComponent from "components/loading";
import "react-image-lightbox/style.css";
import RefreshTable from "components/table/refreshTable";
import DashboardScanCountMap from "../../map/dashboardScanCountMap";
import ClusterProductListingPanel from "../clusterProductListingPanel";
import clsx from "clsx";

const useStyle = makeStyles((theme) => ({
  container: {
    height: 560,
    overflow: "hidden",
    borderRadius: 4,
    background: "white",
    border: "1px solid #D0D5DD",
  },
  slidePanel: {
    position: "absolute",
    top: 0,
    maxWidth: "70%",
    width: 250,
    height: "100%",
    zIndex: 1,
  },
  slideIn: {
    width: 250,
    animation: `$slideRight 0.5s ${theme.transitions.easing.easeInOut}`,
  },
  "@keyframes slideRight": {
    "0%": {
      left: -250,
    },
    "100%": {
      left: 0,
    },
  },
  slideOut: {
    left: -250,
    animation: `$slideLeft 0.5s ${theme.transitions.easing.easeInOut}`,
  },
  "@keyframes slideLeft": {
    "0%": {
      left: 0,
    },
    "100%": {
      left: -250,
    },
  },
  listContainer: {
    position: "relative",
    height: "100%",
  },
  showHideButton: {
    width: 24,
    position: "absolute",
    top: "45%",
    right: -24,
    background: "#ffffff",
    height: 40,
    display: "flex",
    alignItems: "center",
    border: "1px solid #D0D5DD",
    borderTopRightRadius: 6,
    borderBottomRightRadius: 6,
    cursor: "pointer",
    borderLeft: 0,
  },
}));

export default function ClusterScanDetailDialogComponent({
  showProductList,
  mapScans,
  isFetching,
  handleClusterClick,
  handleMarkerClick,
  clusterBound,
  startDate,
  endDate,
  toggleProductList,
  revealProduct,
  updateRevealProductStatus,
  handleSelectProduct,
  handleSelectAll,
  selectedIds,
  isSelectAll,
  isError,
  handleReload,
}) {
  const classes = useStyle();

  return (
    <Box position="relative" className={classes.container}>
      {!isFetching && mapScans ? (
        <Box>
          <Box>
            <Box
              className={clsx(classes.slidePanel, {
                [classes.slideIn]: !!showProductList,
                [classes.slideOut]: !showProductList,
              })}
            >
              <Paper className={classes.listContainer} elevation={3}>
                <Box
                  className={classes.showHideButton}
                  onClick={() => toggleProductList(!showProductList)}
                >
                  {showProductList ? (
                    <ArrowLeftRoundedIcon style={{ color: "#98A2B3" }} />
                  ) : (
                    <ArrowRightRoundedIcon style={{ color: "#98A2B3" }} />
                  )}
                </Box>
                <ClusterProductListingPanel
                  clusterBound={clusterBound}
                  startDate={startDate}
                  endDate={endDate}
                  updateRevealProductStatus={updateRevealProductStatus}
                  revealProduct={revealProduct}
                  handleSelectProduct={handleSelectProduct}
                  handleSelectAll={handleSelectAll}
                  selectedIds={selectedIds}
                  isSelectAll={isSelectAll}
                />
              </Paper>
            </Box>
          </Box>
          <DashboardScanCountMap
            mapScans={mapScans.scans}
            products={mapScans.products}
            handleClusterClick={handleClusterClick}
            handleMarkerClick={handleMarkerClick}
            revealProduct={revealProduct}
            isMain={false}
            displayAllProduct={isSelectAll}
            selectedProductIds={selectedIds}
          />
        </Box>
      ) : !isFetching && isError ? (
        <RefreshTable handleReload={handleReload} />
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height={560}
        >
          <LoadingComponent />
        </Box>
      )}
    </Box>
  );
}
