import { createAsyncThunk } from "@reduxjs/toolkit";
import stockflowApi from "app/api/stockflow";

export const STOCKFLOWSTOCKOUT = "stockflowStockout";

export const getNewStockoutList = createAsyncThunk(
  `${STOCKFLOWSTOCKOUT}/getNewStockoutList`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi
      .getStockoutList({
        ...payload,
        status: "new",
      })
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const getAcceptedStockoutList = createAsyncThunk(
  `${STOCKFLOWSTOCKOUT}/getAcceptedStockoutList`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi
      .getStockoutList({
        ...payload,
        status: "accepted",
      })
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const getRejectedStockoutList = createAsyncThunk(
  `${STOCKFLOWSTOCKOUT}/getRejectedStockoutList`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi
      .getStockoutList({
        ...payload,
        status: "rejected",
      })
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const getCancelledStockoutList = createAsyncThunk(
  `${STOCKFLOWSTOCKOUT}/getCancelledStockoutList`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi
      .getStockoutList({
        ...payload,
        status: "cancelled",
      })
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const getStockoutDetail = createAsyncThunk(
  `${STOCKFLOWSTOCKOUT}/getStockoutDetail`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi
      .getStockoutDetail(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const getStockoutRequestorDropdown = createAsyncThunk(
  `${STOCKFLOWSTOCKOUT}/getStockoutRequestorDropdown`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi
      .getStockoutRequestorDropdown(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const getStockoutReceiverDropdown = createAsyncThunk(
  `${STOCKFLOWSTOCKOUT}/getStockoutReceiverDropdown`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi
      .getStockoutReceiverDropdown(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const getStockoutItemList = createAsyncThunk(
  `${STOCKFLOWSTOCKOUT}/getStockoutItemList`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi
      .getStockoutItemList(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const getStockoutCustomFieldDropdown = createAsyncThunk(
  `${STOCKFLOWSTOCKOUT}/getStockoutCustomFieldDropdown`,
  async (payload, thunkApi) => {
    return stockflowApi
      .getStockoutCustomFieldDropdown(payload)
      .then((response) => response)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);
