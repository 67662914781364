import React from "react";
import {
  makeStyles,
  Box,
  Typography,
  IconButton,
  Divider,
  Grid,
  FormControlLabel
} from "@material-ui/core";
import { EditSVG } from "../../../utils/static";
import AntSwitch from "components/switch/switch";
import Skeleton from "@material-ui/lab/Skeleton";
import clsx from "clsx";
import { getLang } from "app/feature/constants";

const style = (theme) => ({
  root: {
    border: "1px solid rgba(0, 0, 0, 0.1)",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
    margin: theme.spacing(1, 3, 2, 3)
  },
  viewPrizeContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 5fr 1fr"
  },
  viewPrizePhoto: {
    width: 100,
    height: 100,
    display: "flex",
    alignItems: "center",
    padding: "4px",
    placeContent: "center"
  },
  toggleControl: {
    margin: "7px 35px 7px 0px"
  },
  textBold: {
    fontWeight: "bold"
  },
  inactive: {
    color: "rgba(58, 77, 84, 0.5)"
  }
});

const useStyles = makeStyles(style);

export default function ViewPrizeCardComponent({
  hasInstantLuckyDrawEditAccessRight,
  prizeDetail,
  handleEnableEdit,
  handleActiveChange,
  isFetching,
  AddingPrizeFlag,
  lang
}) {
  const classes = useStyles();
  const prizeInactive = !prizeDetail.status ? true : false;

  return (
    <Box className={classes.root}>
      {isFetching ? (
        <Grid
          container
          spacing={1}
          style={{ display: "flex", alignItems: "center" }}
        >
          <Grid item xs={2} sm={2} md={2} style={{ width: "100%" }}>
            <Skeleton variant="rect" width={"100%"} height={100} />
          </Grid>
          <Grid item xs={10} sm={10} md={10}>
            <Skeleton width={"100%"} height={50} />
            <Skeleton width={"100%"} height={50} />
          </Grid>
        </Grid>
      ) : (
        <>
          <Box className={classes.viewPrizeContainer}>
            <Box className={classes.viewPrizePhoto}>
              <img
                src={prizeDetail.prizeImage}
                alt="example"
                height="100%"
                width="100%"
                style={{ objectFit: "contain" }}
              />
            </Box>

            <Box p={2} width="100%">
              <Box display="flex" pt={1} pb={1}>
                <Typography
                  variant="body2"
                  style={{ color: " rgba(58, 77, 84, 0.33)" }}
                >
                  {getLang(lang, "label.PRIZE_NAME")}:&nbsp;
                </Typography>
                <Typography
                  variant="body2"
                  color="primary"
                  className={clsx(classes.textBold, {
                    [classes.inactive]: prizeInactive
                  })}
                >
                  {prizeDetail.prizeName}
                </Typography>
              </Box>

              <Box display="grid" gridTemplateColumns="2fr 6fr">
                <div style={{ display: "flex" }}>
                  <Typography
                    variant="body2"
                    style={{ color: " rgba(58, 77, 84, 0.33)" }}
                  >
                    {getLang(lang, "label.QUANTITY")}:&nbsp;
                  </Typography>
                  <Typography
                    variant="body2"
                    color="primary"
                    className={clsx(classes.textBold, {
                      [classes.inactive]: prizeInactive
                    })}
                  >
                    {prizeDetail.quantity.toLocaleString("en-US")}
                  </Typography>
                </div>
              </Box>

              <Box display="flex" pt={1} pb={1}>
                <div style={{ display: "flex" }}>
                  <Typography
                    variant="body2"
                    style={{ color: " rgba(58, 77, 84, 0.33)" }}
                  >
                    {getLang(lang, "label.WINNER")}:&nbsp;
                  </Typography>
                  <Typography
                    variant="body2"
                    color="primary"
                    className={clsx(classes.textBold, {
                      [classes.inactive]: prizeInactive
                    })}
                  >
                    {prizeDetail.winners.length ? getLang(lang, "label.PRESET") : getLang(lang, "label.RANDOM")}
                  </Typography>
                </div>
              </Box>
            </Box>
          </Box>
          {
            hasInstantLuckyDrawEditAccessRight
              ?
              <>
                <Divider />
                <Box
                  p={1}
                  px={2}
                  display="flex"
                  alignSelf="end"
                  justifyContent="space-between"
                >
                  <FormControlLabel
                    classes={{
                      root: classes.toggleControl
                    }}
                    control={
                      <AntSwitch
                        checked={prizeDetail.status}
                        onChange={() =>
                          handleActiveChange({
                            currentStatus: prizeDetail.status,
                            id: prizeDetail.id
                          })
                        }
                      />
                    }
                    label={
                      <Typography variant="body2" color="primary">
                        &ensp;{getLang(lang, "label.ACTIVE")}
                      </Typography>
                    }
                  />
                  <IconButton
                    style={{ padding: 0 }}
                    onClick={handleEnableEdit}
                    disabled={prizeInactive || AddingPrizeFlag}
                  >
                    {prizeInactive || AddingPrizeFlag ? (
                      <EditSVG color="rgba(58, 77, 84, 0.33)" />
                    ) : (
                      <EditSVG />
                    )}
                  </IconButton>
                </Box>
              </>
              : null
          }
        </>
      )}
    </Box>
  );
}
