import axios from "axios";

const connection = process.env.REACT_APP_AUTH_CONNECTION
const connectionId = process.env.REACT_APP_AUTH_CONNECTION_ID

export default function resendVerificationEmail({
    email
}) {
    return axios.post(`/authentication/api/basic/send-verification-link-email`,
        {
            connection,
            connection_id: connectionId,
            email
        })
}