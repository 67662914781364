import React, { useRef, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  makeStyles,
  Typography,
  Box,
  CircularProgress,
  Paper,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormGroup
} from "@material-ui/core";
import { Error as ErrorIcon } from "@material-ui/icons";
import clsx from "clsx";
import InputTextField from "components/input/inputTextField";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles(theme => ({
  root: {
    textAlign: 'center',
    padding: '35px 30px',
    backgroundColor: '#EB6D76',
    color: 'white !important'
  },
  customDialogActionRoot: {
    marginTop: 24,
    justifyContent: 'center'
  },
  customDialogTitle: {
    paddingTop: 0,
    color: theme.palette.common.white
  },
  customDialogPaperWithXs: {
    maxWidth: 360
  },
  dangerCancelButton: {
    color: 'white',
    borderColor: 'white !important'
  },
  dangerText: {
    color: 'white'
  },
  formControlLabel: {
    display: "flex",
    "& .MuiFormControlLabel-label": {
      textAlign: "left",
      color: theme.palette.primary.main,
      lineHeight: "16px",
      fontSize: 14
    }
  },
  errorEmptyInput: {
    borderRadius: 4,
    outlineWidth: "1px",
    outlineStyle: "solid",
    outlineColor: theme.palette.error.main,
  },
  paperTitleText: {
    textAlign: "left",
    fontWeight: 500,
    fontSize: 14,
    marginBottom: 16
  }
}));

export default function ConfirmationDialogComponent({
  isLoading,
  isOpen,
  handleClose,
  handleProceed,
  title,
  description,
  employeeName,
  rejectRemarks,
  setRejectRemarks,
  lang
}) {
  const inputRef = useRef();
  const classes = useStyle();
  const [isEmpty, setIsEmpty] = useState(false);
  const [isInputEmpty, setIsInputEmpty] = useState(false);
  

  const resetRemarks = (rejectRemarks) => {
    let newCheck = [...rejectRemarks];
    newCheck.forEach(remark => {
      remark.checked = false
    })
    setRejectRemarks(newCheck)
  }

  const handleChange = (event) => {
    let newCheck = [...rejectRemarks];
    newCheck.forEach((remark, index) => {
      if (parseInt(event.target.value) === index) {
        remark.checked = event.target.checked
      } else {
        remark.checked = false
      }
    })

    if (newCheck[2].checked === false) {
      inputRef.current.value = ""
    }
    setIsEmpty(false)
    setRejectRemarks(newCheck)
  }

  const inputTickOthers = () => {
    setIsInputEmpty(false)
    let newCheck = [...rejectRemarks];
    newCheck.forEach((remark, index) => {
      if (index === 2) {
        remark.checked = true
      } else {
        remark.checked = false
      }
    })
    setRejectRemarks(newCheck)
  }

  const handleSubmit = (rejectRemarks) => {
    if (rejectRemarks[2].checked && inputRef.current.value === "") return setIsInputEmpty(true)
    let reasons = []
    rejectRemarks.forEach(remark => {
      if (remark.checked) {
        if (remark.name === getLang(lang, "label.OTHERS")) {
          reasons.push(inputRef.current.value)
        } else {
          reasons.push(remark.name)
        }
      }
    })
    if (reasons.length === 0) {
      return setIsEmpty(true)
    }
    else if (reasons.length === 1) {
      resetRemarks(rejectRemarks);
      return handleProceed(reasons.toString())
    }
    else {
      resetRemarks(rejectRemarks);
      return handleProceed(reasons.join(", "))
    }
  }

  return (
    <Dialog
      classes={{
        paper: classes.root,
        paperWidthXs: classes.customDialogPaperWithXs
      }}
      open={isOpen}
      disableBackdropClick
      disableEscapeKeyDown
      aria-labelledby="confirmation-dialog"
      maxWidth="xs"
    >
      <DialogTitle classes={{ root: classes.customDialogTitle }}>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText classes={{ root: classes.dangerText }}>
          {description}  <span style={{ fontWeight: "bold" }}>{employeeName}</span> ?
        </DialogContentText>
      </DialogContent>
      <Box justifyContent="center">
        {isLoading ? (
          <CircularProgress />
        ) :
          <>
            <Paper style={{ padding: "20px 16px" }}>
              <Box>
                <Typography className={classes.paperTitleText}>
                <Typography style={{ color: "#DE350B", display: 'inline-block' }}>*</Typography>
                  {getLang(lang, "paragraph.DELETE_QUESTION")}
                </Typography>
                <FormControl
                  required
                  error={true}
                >
                  <FormGroup >
                    {rejectRemarks.map((remark, index) => {
                      return (
                        <FormControlLabel
                          key={index}
                          className={classes.formControlLabel}
                          control={
                            <Checkbox
                              value={index}
                              checked={remark.checked}
                              onChange={handleChange}
                              name={remark.name}
                            />
                          }
                          label={remark.name}
                          name={remark.name}
                        />
                      )
                    })}
                    <Box ml={4} >
                      <InputTextField
                        className={clsx({
                          [classes.errorEmptyInput]: isInputEmpty
                        })}
                        multiline
                        fullWidth
                        rows={2}
                        name="inputOthers"
                        onFocus={inputTickOthers}
                        placeholder={getLang(lang, "placeholder.TYPE_SOMETHING_HERE")}
                        inputRef={inputRef}
                        inputProps={{
                          style: {
                            fontSize: "14px",
                            padding: "7px"
                          },
                        }}
                      />
                      {isInputEmpty &&
                        <FormHelperText>{getLang(lang, "paragraph.MUST_TYPE_SOMETHING")}</FormHelperText>
                      }
                    </Box>
                  </FormGroup>
                  {isEmpty &&
                    <Box display="flex">
                      <ErrorIcon color="error" />
                      <FormHelperText>{getLang(lang, "paragraph.PICK_REASON")}</FormHelperText>
                    </Box>
                  }
                </FormControl>
              </Box>
            </Paper>

            <DialogActions classes={{ root: classes.customDialogActionRoot }}>
              <Button
                variant="outlined"
                onClick={handleClose}
                color="primary"
                className={classes.dangerCancelButton}
              >
                {getLang(lang, "label.BACK")}
              </Button>

              <Button
                onClick={() => handleSubmit(rejectRemarks)}
                color="primary"
                variant="contained"
                autoFocus
              >
                {getLang(lang, "label.CONFIRM")}
              </Button>
            </DialogActions>
          </>
        }
      </Box>
    </Dialog>
  );
}
