import React from "react";
import { Typography, Box, makeStyles } from "@material-ui/core";
import "./animation.css";
import classnames from "classnames";

const style = theme => ({
  planText: {
      fontSize: "12px"
  },
});

const useStyles = makeStyles(style);

export default function FadeTextComponent({ fadeText }) {
  const classes = useStyles();

  return (
    <Box className={classnames("fade-in-out")} style={{ display: "flex", textAlign: "end"}}>
      <Typography variant="body2" color="primary" className={classes.planText}>
        {fadeText}
      </Typography>
    </Box>
  );
}
