import React from "react";
import { makeStyles, Button, Box } from "@material-ui/core";
import { Formik } from "formik";
import * as Yup from 'yup';
import PropTypes from "prop-types";
import InputTextField from "components/input/inputTextField";
import SearchableSelect from '../../select/searchableSelect';
import { getLang } from "app/feature/constants";
import DatepickerDialog from "../../../../../components/dialog/datepicker/datepickerDialog.container";

const useStyle = makeStyles((theme) => ({
  mainContainer: {
    backgroundColor: theme.palette.background.paper,
  },
  searchContainer: {
    display: "grid",
    gridTemplateColumns: "3fr 2fr 2fr 2fr 1fr 1fr",
    columnGap: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1),
    },
  },
  button: {
    [theme.breakpoints.up("sm")]: {
      width: "fit-content",
      justifySelf: "flex-end",
    },
  },
  inputRoot: {
    paddingTop: "8px !important",
    paddingBottom: "7px !important",
    fontSize: "0.875rem",
    fontWeight: 500
  },
}));

function SurveySearchComponent({
  handleSearch,
  productDropdown,
  isProductLoading,
  handleItemSearch,
  lang
}) {
  const classes = useStyle();

  return (
    <Box p={2} mb={2} className={classes.mainContainer}>
      <Formik
        initialValues={{
          search: "",
          productSelect: "",
          productName: "",
          dateCreated: "",
          statusSelect: "",
        }}
        validationSchema={Yup.object({
          search: Yup.string()
        })}
        onSubmit={handleSearch}
        onReset={handleSearch}
      >
        {(formik) => (
          <form className={classes.searchContainer} onSubmit={formik.handleSubmit}>
            <InputTextField
              placeholder={getLang(lang, 'placeholder.SEARCH')}
              variant="filled"
              size="small"
              margin="none"
              InputProps={{
                disableUnderline: true,
                margin: "none",
                style: {
                  borderRadius: "4px",
                  backgroundColor: "#eceff0",
                },
                classes: { input: classes.inputRoot }
              }}
              {...formik.getFieldProps("search")}
              error={formik.touched.search && formik.errors.search}
            />
            <SearchableSelect
              placeholder={getLang(lang, 'placeholder.ALL_PRODUCT')}
              handleChange={(value) => {
                formik.setFieldValue("productSelect", value.id)
                formik.setFieldValue("productName", value.name)
              }}
              value={formik.values.productName}
              disabled={false}
              dropdownItem={productDropdown}
              isLoading={isProductLoading}
              emptyMessage={getLang(lang, 'message.info.PRODUCT_NOT_FOUND')}
              searchable={true}
              handleItemSearch={handleItemSearch}
            />
            <DatepickerDialog
              handleChange={(value) => {
                formik.setFieldValue("dateCreated", value.format("YYYY-MM-DD"))
              }}
              value={formik.values.dateCreated}
              label={getLang(lang, 'placeholder.SELECT_DATE')}
              disabled={formik.isSubmitting}
              disableFuture={true}
              displayFormat="DD MMMM YYYY"
            />
            <SearchableSelect
              placeholder={getLang(lang, 'placeholder.ALL_STATUS')}
              handleChange={(value) => formik.setFieldValue("statusSelect", value)}
              value={formik.values.statusSelect}
              disabled={false}
              dropdownItem={[getLang(lang, 'label.PUBLISHED'), getLang(lang, 'label.UNPUBLISHED'), getLang(lang, 'label.EXPIRED')]}
              isFetching={false}
              searchable={false}
            />
            <Button
              color="secondary"
              variant="text"
              size="small"
              disableElevation
              onClick={formik.handleReset}
            >
              {getLang(lang, 'label.CLEAR')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              type="submit"
              size="small"
            >
              {getLang(lang, 'label.SEARCH')}
            </Button>
          </form>
        )}
      </Formik>
    </Box>
  );
}

SurveySearchComponent.propTypes = {
  handleSearch: PropTypes.func.isRequired,
};

export default SurveySearchComponent;
