import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { selectLoading } from 'modules/notification'
import LoadingComponent from "components/loading";
import { changeSelectedSerialNumber, getInventoryProductDetail, resetActivityLog } from "modules/stockflow/redux";
import { STOCKFLOW_INVENTORY_VIEW } from "lib/constants/accessRights";
import { ROOT } from "modules/dashboard";
import ProductDetailComponent from "./productDetails.component";
import { exportProductListing } from "app/api/stockflow/inventory/exportProductListing.api";
import { getLang } from "app/feature/constants";

function ProductDetailsContainer() {
  const history = useHistory()
  const dispatch = useDispatch()
  const match = useRouteMatch()
  
  const id = match.params.productId;
  const hasStockflowViewAccessRight = useSelector(state => state.profile.accessRights.includes(STOCKFLOW_INVENTORY_VIEW));
  const lang = useSelector(state => state.constant.languages);
  const token = useSelector(state => state.session.accessToken)
  const product = useSelector(state => state.stockflowInventory.product);
  const isLoading = useSelector(state => selectLoading(state, getInventoryProductDetail.typePrefix))
  const [activeIndex, setActiveIndex] = useState(0);
  const [isExporting, setIsExporting] = useState(false)
  const [showLog, setShowLog] = useState(false)

  useEffect(() => {
    if (!hasStockflowViewAccessRight) {
      history.push('/admin/dashboard')
    }
  }, [history, hasStockflowViewAccessRight])

  useEffect(() => {
    if (id) {
      dispatch(getInventoryProductDetail(id))
    }
    else {
      history.push(ROOT)
    }
  }, [history, id, dispatch])

  if (!hasStockflowViewAccessRight) return <LoadingComponent />;

  let tabLabels = []
  if (hasStockflowViewAccessRight) {
    tabLabels.push(getLang(lang, "label.INVENTORY"))
  }

  const handleChange = (event, index) => {
    setActiveIndex(index);
  };

  const viewActivityLog = (sn) => {
    if (sn) {
      dispatch(changeSelectedSerialNumber(sn))
    }
    setShowLog(!!sn)
  }

  const resetLog = () => {
    dispatch(resetActivityLog())
  }
  
  const exportListing = () => {
    setIsExporting(true)
    exportProductListing.post({
      token,
      isSelectAll: false,
      selectedIds: [product.id],
      filter: {
        search: "",
        search_by: "",
        nodes: [],
      }
    }).then((blob) => {
      saveFile(blob)
      setIsExporting(false)
    }).catch(()=> {
      setIsExporting(false)
    })
  }

  const saveFile = async (blob) => {
    const a = document.createElement("a")
    a.download = `inventory_product_${product.name?.toLowerCase()?.replace(/ /g,"_")}.xlsx`
    a.href = URL.createObjectURL(blob)
    a.addEventListener("click", (e) => {
      setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000)
    })
    a.click()
  }

  return (
    <ProductDetailComponent
      tabLabels={tabLabels}
      activeIndex={activeIndex}
      handleChange={handleChange}
      product={product}
      isLoading={isLoading}
      token={token}
      showLog={showLog}
      resetLog={resetLog}
      viewActivityLog={viewActivityLog}
      exportListing={exportListing}
      isExporting={isExporting}
      lang={lang}
    />
  );
}

export default ProductDetailsContainer;
