/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import StocktakeProductSnListingDialogComponent from "./stocktakeProductSnListingDialog.component";
import { useDispatch, useSelector } from "react-redux";
// import { selectLoading } from "modules/notification";
import {
  getStockTakeItemProductSNList,
  // getTransferItemProductSNList,
  resetStockTakeSnListing,
} from "modules/stockflow/redux";
import { generatePaginationOptions } from "lib/helper";
import { selectLoading } from "modules/notification";

function StocktakeProductSnListingDialogContainer({
  isOpen,
  handleClose,
  boxUuid,
  id,
  productInfo,
  isCompleted,
}) {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.constant.languages);

  const isFetching = useSelector((state) =>
    selectLoading(state, getStockTakeItemProductSNList.typePrefix)
  );
  const totalFiltered = useSelector(
    (state) => state.stockflowStockTake.stockTakeSnList.totalFiltered
  );
  const list = useSelector(
    (state) => state.stockflowStockTake.stockTakeSnList.list
  );
  const isError = useSelector(
    (state) => state.stockflowStockTake.stockTakeSnList.isError
  );

  const [activeIndex, setActiveIndex] = useState(0);
  const [paginationOptions, setPaginationOptions] = useState([]);
  const [tableFilter, setTableFilter] = useState({
    page: 0,
    rowsPerPage: 25,
    order: "asc",
    orderBy: "sequence_number", // sequence_number|serial_number
    status: "",
    search: "",
  });

  useEffect(() => {
    return () => {
      dispatch(resetStockTakeSnListing());
    };
  }, [dispatch]);

  useEffect(() => {
    if (productInfo) {
      handleDataLoad();
    }
  }, [tableFilter, productInfo]);

  useEffect(() => {
    if (!isOpen) {
      setTableFilter({
        page: 0,
        rowsPerPage: 25,
        order: "asc",
        orderBy: "sequence_number",
        status: "matched",
        search: "",
      });
      setActiveIndex(0);
    }
  }, [isOpen]);

  useEffect(() => {
    setPaginationOptions(generatePaginationOptions(totalFiltered));
  }, [totalFiltered]);

  const handleDataLoad = () => {
    let payload = {
      id: id,
      product_uuid: productInfo.uuid,
      length: tableFilter.rowsPerPage,
      start: tableFilter.rowsPerPage * tableFilter.page,
      order: tableFilter.order,
      order_by: tableFilter.orderBy,
      status: tableFilter.status,
      box_uuid: boxUuid,
      search: tableFilter.search,
    };
    dispatch(getStockTakeItemProductSNList(payload));
  };

  const handleChangePage = (event, newPage) => {
    let newData = {
      ...tableFilter,
      page: newPage,
    };
    setTableFilter(newData);
  };

  const handleChangeRowsPerPage = (event) => {
    let newData = {
      ...tableFilter,
      page: 0,
      rowsPerPage: event.target.value,
    };
    setTableFilter(newData);
  };

  const changeOrder = (orderBy, order) => {
    let newData = {
      ...tableFilter,
      order,
      orderBy,
    };
    setTableFilter(newData);
  };

  const handleReload = () => {
    let newData = {
      ...tableFilter,
      page: 0,
    };
    setTableFilter(newData);
  };

  const handleSearch = (values) => {
    setTableFilter({
      ...tableFilter,
      page: 0,
      search: values.search,
    });
  };

  const handleClear = (values) => {
    setTableFilter({
      ...tableFilter,
      page: 0,
      search: "",
    });
  };

  const changeTab = (event, val) => {
    if (val !== activeIndex) {
      setActiveIndex(val);
      setTableFilter({
        ...tableFilter,
        page: 0,
        status: val === 1 ? "extra" : val === 2 ? "missing" : "matched",
      });
    }
  };

  return (
    <StocktakeProductSnListingDialogComponent
      isOpen={isOpen}
      handleClose={handleClose}
      lang={lang}
      productInfo={productInfo}
      list={list}
      isFetching={isFetching}
      isError={isError}
      page={tableFilter.page}
      rowsPerPage={tableFilter.rowsPerPage}
      totalFiltered={totalFiltered}
      paginationOptions={paginationOptions}
      changeOrder={changeOrder}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      tableFilter={tableFilter}
      handleReload={handleReload}
      handleSearch={handleSearch}
      isCompleted={isCompleted}
      changeTab={changeTab}
      activeIndex={activeIndex}
      handleClearSearch={handleClear}
    />
  );
}

export default StocktakeProductSnListingDialogContainer;
