import React, { useState, useRef, useEffect } from "react"
import StockSelectSelectComponent from "./stockProductSelect.component"
import { selectLoading } from "modules/notification"
import StockProductSelectPanelComponent from "./stockProductSelectPanel.component"
import { getStockProductDropdown } from "modules/stockflow/redux"
import { useSelector, useDispatch } from "react-redux";
import { cloneDeep } from "lodash"

function StockProductSelectContainer({
  handleChange,
  disabled,
  value,
  styles, 
  textStyles,
  placeholder,
  dealerUuid,
  type,
  code
}) {
  const isFetching = useSelector(state => selectLoading(state, getStockProductDropdown.typePrefix))
  const lang = useSelector((state) => state.constant.languages);
  const stockProductDropdown = useSelector((state) => state.stockflowInventory.stockProductDropdown);
  const [open, setOpen] = useState(false)
  const [isOpenList] = useState(false)
  const anchorRef = useRef(null)
  const prevOpen = useRef(open)
  const prevOpenList = useRef(isOpenList)
  const [selectAll, setSelectAll] = useState(false)
  const [search, setSearch] = useState("")
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getStockProductDropdown({
      search,
      type,
      dealer: dealerUuid,
      code
    }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, dispatch, dealerUuid]);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])

  useEffect(() => {
    if (prevOpenList.current === true && isOpenList === false) {
      anchorRef.current.focus()
    }

    prevOpenList.current = isOpenList
  }, [isOpenList])

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const handleSelectAll = async () => {
    if(!selectAll){
      let selectedProducts = stockProductDropdown.map(p => {
        return p.id
      })
      handleChange(selectedProducts)
    }else{
      handleChange([])
    }
    setSelectAll(!selectAll)
  }

  const handleSelectProduct = (product) => {
    let temp = cloneDeep(value)

    if(temp.findIndex(t => t === product.id) >= 0){
      temp = temp.filter( t => t !== product.id )
    }else{
      temp.push(product.id)
    }
    handleChange(temp)
    setSelectAll(temp.length === stockProductDropdown.length)
  };

  const handleSearch = (value) => {
    setSearch(value)
  }

  return (
    <>
      <StockSelectSelectComponent
        anchorRef={anchorRef}
        disabled={disabled}
        value={value}
        handleClick={handleOpen}
        styles={styles}
        textStyles={textStyles}
        placeholder={placeholder}
        lang={lang}
      />
      <StockProductSelectPanelComponent
        selectAll={selectAll}
        value={value}
        anchorRef={anchorRef}
        open={open}
        isOpenList={isOpenList}
        handleClose={handleClose}
        isFetching={isFetching}
        stockProductDropdown={stockProductDropdown}
        handleSelectAll={handleSelectAll}
        handleSelectProduct={handleSelectProduct}
        handleSearch={handleSearch}
        lang={lang}
      />
    </>
  )
}

export default StockProductSelectContainer