import React from "react";
import {
  Box,
  makeStyles,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Paper,
  TableSortLabel,
  Link,
} from "@material-ui/core";
import {
  StyledTableCell,
  StyledTableRow,
  StickyRightTableCell,
} from "components/table";
import { ActionButton } from "components/button";
import { TablePaginationActions } from "components/tablePagination";
import RefreshTable from "components/table/refreshTable";
import moment from "moment";
import { KeyboardArrowDown } from "@material-ui/icons";
import { getLang } from "app/feature/constants";
import { SkeletonTableRow } from "components/skeleton/skeleton.component";
import "moment/min/locales.min";
import BusinessIcon from "@material-ui/icons/Business";

const useStyle = makeStyles((theme) => ({
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    borderCollapse: "separate",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
    },
    "& th,td": {
      fontSize: ".75rem",
      borderRight: "1px solid rgba(58, 77, 84, 0.1)",
      padding: theme.spacing(1, 2, 1, 2),
    },
    "& th": {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightBold,
    },
    "& th:last-child, td:last-child": {
      width: 37,
      borderRight: "0px",
    },
    "& th th": {
      borderBottom: "none !important",
    },
    "& td td": {
      borderBottom: "none !important",
    },
  },
  emptyContainer: {
    backgroundColor: theme.palette.background.paper,
    textAlign: "center",
  },
  profile: {
    height: 26,
    width: 26,
    minWidth: 26,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& img": {
      height: "100%",
      minHeight: "100%",
      minWidth: "100%",
      objectFit: "cover",
      borderRadius: "50%",
      border: "1px solid #D0D5DD",
    },
  },
  tierIcon: {
    borderRadius: "3px",
    paddingLeft: "0.15em",
    paddingRight: "0.15em",
    display: "flex",
    alignItems: "center",
    position: "absolute",
    top: "-3px",
    left: "-8px",
  },
  tierLabel: {
    color: "#FFFFFF",
    fontSize: "0.6rem",
  },
  ellipsisText: {
    maxWidth: "18em",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: "0.75rem",
  },
}));

export default function StockTakeListTableComponent({
  rows,
  isFetching,
  isError,
  handleReload,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowPerPage,
  isSearchResult,
  totalFiltered,
  paginationOptions,
  tableFilter,
  changeOrder,
  lang,
  handleViewDetail,
  url,
  handleCancel,
  hasStockflowEditAccessRight,
  language,
}) {
  const classes = useStyle();

  if(language==="ZH")
  {
    moment.locale('zh-cn')
  }else if(language==="TL")
  {
    moment.locale('tl-ph')
  }else
  {
    moment.locale(language)
  }

  const statusColor = {
    "In Progress": "#FDB022",
    Completed: "#32D583",
    Cancelled: "#98A2B3",
  };

  return (
    <>
      {!isFetching && isError && <RefreshTable handleReload={handleReload} />}
      {isFetching || rows.length > 0 ? (
        <Paper id="test" style={{ overflowX: "auto", width: "100%" }}>
          <TableContainer>
            <Table
              className={classes.table}
              aria-label="stocktake table"
              size="small"
              stickyHeader
            >
              <TableHead style={{ height: "45px" }}>
                <TableRow>
                  <StyledTableCell>
                    {getLang(lang, "label.STOCK_TAKE_NAME")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.BRANCH")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.STATUS")}
                  </StyledTableCell>
                  <StyledTableCell>
                    <TableSortLabel
                      active={tableFilter.orderBy === "created_at"}
                      direction={tableFilter.order}
                      onClick={() => {
                        const differentTab =
                          tableFilter.orderBy !== "created_at";
                        const order = differentTab
                          ? tableFilter.order
                          : tableFilter.order === "asc"
                          ? "desc"
                          : "asc";
                        changeOrder("created_at", order);
                      }}
                      IconComponent={KeyboardArrowDown}
                    >
                      {getLang(lang, "label.CREATED_DATE")}
                    </TableSortLabel>
                  </StyledTableCell>
                  <StickyRightTableCell>
                    <StyledTableCell
                      style={{ width: "67px", textAlign: "center" }}
                    >
                      {getLang(lang, "label.ACTION")}
                    </StyledTableCell>
                  </StickyRightTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isFetching
                  ? [...new Array(5)].map((v, index) => (
                      <SkeletonTableRow key={index} columnCount={5} />
                    ))
                  : rows.map((row, index) => {
                      const actionItems = [];

                      actionItems.push({
                        label: getLang(lang, "label.VIEW_DETAILS"),
                        action: () => {
                          handleViewDetail(`${url}/${row.uuid}`);
                        },
                      });

                      if (!row.status === "In Progress") {
                        actionItems.push({
                          label: getLang(lang, "label.CANCEL"),
                          divider: true,
                          errorText: true,
                          action: () => {
                            handleCancel(row);
                          },
                          disabled: !hasStockflowEditAccessRight,
                        });
                      }

                      return (
                        <StyledTableRow
                          key={row.id}
                          hover
                          role="checkbox"
                          tabIndex={-1}
                        >
                          <StyledTableCell>
                            <Link
                              href={`${url}/${row.uuid}`}
                              color="secondary"
                              onClick={(e) => {
                                handleViewDetail(`${url}/${row.uuid}`);
                                e.preventDefault();
                                return false;
                              }}
                            >
                              <Typography className={classes.ellipsisText}>
                                {row.name}
                              </Typography>
                            </Link>
                          </StyledTableCell>
                          <StyledTableCell>
                            <Box display="flex" alignItems="center">
                              <Box
                                className={classes.profile}
                                mr={1}
                                position="relative"
                              >
                                <Box
                                  style={{
                                    width: 26,
                                    height: 26,
                                    borderRadius: '100%',
                                    border: '2px solid #6AAF68',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    boxShadow: '0px 0px 4px 0px rgb(0 0 0 / 25%)',
                                  }}
                                >
                                  <BusinessIcon style={{ fontSize: 18, color: '#6AAF68' }} />
                                </Box>
                              </Box>
                              <Typography className={classes.ellipsisText}>
                                {row.branch}
                              </Typography>
                            </Box>
                          </StyledTableCell>
                          <StyledTableCell>
                            <span style={{ color: statusColor[row.status] }}>
                              {row.status
                                ? getLang(lang, `db_label.${row.status}`)
                                : "-"}
                            </span>
                          </StyledTableCell>
                          <StyledTableCell>
                            {moment
                              .utc(row.created_at)
                              .local()
                              .format("lll")}
                          </StyledTableCell>
                          <StickyRightTableCell>
                            <StyledTableCell style={{ width: "67px" }}>
                              <ActionButton
                                actionItems={actionItems}
                                popperStyle={{ zIndex: 999 }}
                              />
                            </StyledTableCell>
                          </StickyRightTableCell>
                        </StyledTableRow>
                      );
                    })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            style={{
              backgroundColor: "#fff",
              boxShadow: "0px -1px 0px rgba(58, 77, 84, 0.15)",
            }}
            ActionsComponent={TablePaginationActions}
            rowsPerPageOptions={paginationOptions}
            component="div"
            count={totalFiltered}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowPerPage}
            labelRowsPerPage={getLang(lang, "label.ROWS_PER_PAGE")}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} ${getLang(lang, "label.OF")} ${count}`
            }
          />
        </Paper>
      ) : null}
      {!isFetching && !isError && rows.length === 0 && (
        <Box>
          <TableContainer>
            <Table
              className={classes.table}
              aria-label="stocktake table"
              size="small"
              stickyHeader
            >
              <TableHead style={{ height: "45px" }}>
                <TableRow>
                  <StyledTableCell>
                    {getLang(lang, "label.STOCK_TAKE_NAME")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.BRANCH")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.STATUS")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.CREATED_DATE")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.ACTION")}
                  </StyledTableCell>
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>
          <Box py={9} className={classes.emptyContainer}>
            <Typography
              variant="body1"
              style={{ paddingBottom: "16px", fontWeight: "bold" }}
            >
              {isSearchResult
                ? getLang(lang, "paragraph.NO_STOCK_TAKE_EXISTS")
                : getLang(lang, "paragraph.YOU_DONT_HAVE_STOCK_TAKE")}
            </Typography>
            {!isSearchResult && (
              <Typography variant="body2" style={{ paddingBottom: "16px" }}>
                {getLang(lang, "paragraph.STOCK_TAKE_INSTRUCTION")}
              </Typography>
            )}
          </Box>
        </Box>
      )}
    </>
  );
}
