import React from "react";
import BranchGroupSelectComponent from "./branchGroupSelect.component";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getBranchGroupDropdown } from "modules/branch/redux";

function BranchGroupSelectContainer({ textFieldProps, formProps, isOpen }) {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.constant.languages);
  const branchGroups = useSelector((state) => state.branch.branchGroupDropdown);

  useEffect(() => {
    if (isOpen) {
      dispatch(getBranchGroupDropdown());
    }
  }, [dispatch, isOpen]);

  return (
    <BranchGroupSelectComponent
      textFieldProps={textFieldProps}
      formProps={formProps}
      lang={lang}
      branchGroups={branchGroups}
    />
  );
}

export default BranchGroupSelectContainer;
