import React from "react";
import { makeStyles, Box, Typography, Button } from "@material-ui/core";
import ImageRoundedIcon from "@material-ui/icons/ImageRounded";
import clsx from "clsx";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles(theme => ({
  root: {
    fontWeight: theme.typography.fontWeightBold,
    display: "flex",
    flexFlow: "column",
    alignItems: "center",
    padding: theme.spacing(2),
    backgroundColor: "#ECEFF0",
    borderRadius: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3)
  },
  image: {
    width: "100%",
    border: "1px solid",
    borderRadius: "20px",
    borderColor: theme.palette.grey[500],
    display: "flex",
    overflow: "hidden",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    "& img": {
      height: "100%",
      minWidth: "100%",
      maxWidth: "100%",
      minHeight: "100%",
      objectFit: "contain",
      flexShrink: 0
    }
  },
  hideBorder: {
    border: "none"
  },
  wrapper: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    flexWrap: "nowrap",
  },
  input: {
    display: "none"
  }
}));

export default function BannerPanelComponent({
  image,
  inputImgRef,
  handleImgChange,
  handleCancel,
  lang
}) {
  const classes = useStyle();

  return (
    <Box className={classes.root}>
      <Box
        className={clsx(classes.image, { [classes.hideBorder]: image !== "" })}
      >
        {image ? (
          <img src={image} alt="banner company" />
        ) : (
          <Box width="100%" py={2} >
            <Box display="flex" flexDirection="column" flexWrap="wrap" justifyContent="center" alignContent="center">
              <ImageRoundedIcon color="disabled" fontSize="large" style={{alignSelf:"center"}}/>
              <Typography variant="caption" color="textSecondary">
                {getLang(lang, "label.NO_IMAGE")}
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
      <Box className={classes.wrapper} mt={2}>
        <Typography variant="body1">{getLang(lang, "label.UPLOAD_BANNER")}</Typography>
        <Typography variant="body2" color="textSecondary" gutterBottom>
          {getLang(lang, "paragraph.RECOMMENDED_BANNER_SIZE_LANDING_PAGE_12")}
        </Typography>

        <Box style={{ placeSelf: "flex-end" }} display="flex" flexDirection="row" mt={1}>
          {(image || image !== "") && (
            <Box mr={1}>
              <Button
                variant="contained"
                disableElevation
                size="small"
                component="span"
                color="primary"
                onClick={handleCancel}
              >
                {getLang(lang, "label.REMOVE")}
              </Button>
            </Box>
          )}
          <input
            accept="image/*"
            className={classes.input}
            id="banner-image"
            type="file"
            ref={inputImgRef}
            onChange={handleImgChange}
            onClick={(e) => {
              e.target.value = null
            }}
          />
          <label htmlFor="banner-image">
            <Button
              variant="contained"
              disableElevation
              size="small"
              component="span"
              color="secondary"
            >
              {getLang(lang, "label.UPLOAD")}
            </Button>
          </label>
        </Box>
      </Box>
    </Box>
  );
}
