import React from "react";
import {
  makeStyles,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TablePagination,
  Typography,
  Box,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import Alert from "@material-ui/lab/Alert";
import moment from "moment";

import {
  StyledTableCell,
  StyledTableRow,
  StickyLeftTableCell,
  StickyRightTableCell,
} from "../../../../../components/table";
import RefreshTable from "components/table/refreshTable";
import ActionButton from "../../../../../components/button/actionDetailButton";
import { TablePaginationActions } from "../../../../../components/tablePagination";

import { getLang } from "app/feature/constants";

const useStyle = makeStyles((theme) => ({
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    borderCollapse: "separate",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
    },
    "& th,td": {
      fontSize: ".75rem",
      borderRight: "1px solid rgba(58, 77, 84, 0.1)",
      padding: theme.spacing(1, 2, 1, 2),
    },
    "& th": {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightBold,
    },
    "& th:last-child, td:last-child": {
      width: 37,
      borderRight: "0px",
    },
    "& th th": {
      borderBottom: "none !important",
    },
    "& td td": {
      borderBottom: "none !important",
    },
  },
  attributeText: {
    color: "#b2b2b2",
    fontSize: 12,
    whiteSpace: "break-spaces",
  },
}));

export default function WarrantyReimbursementListTableComponent({
  hasReimbursementEditAccessRight,
  rows,
  isFetching,
  language,
  rowPerPage,
  page,
  length,
  paginationOptions,
  handlePageChange,
  handleRowPerPageChange,
  handleView,
  handleReload,
  isLoadingError,
  lang,
}) {
  const classes = useStyle();

  if(language==="ZH")
  {
    moment.locale('zh-cn')
  }else if(language==="TL")
  {
    moment.locale('tl-ph')
  }else
  {
    moment.locale(language)
  }

  const statusList = [
    {
      value: 1,
      name: getLang(lang, "label.PENDING"),
      color: "#F6C000",
    },
    {
      value: 2,
      name: getLang(lang, "label.APPROVED"),
      color: "#92C892",
    },
    {
      value: 3,
      name: getLang(lang, "label.REJECTED"),
      color: "#FD646F",
    },
  ];

  const SkeletonTableRow = () => {
    return (
      <StyledTableRow>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((id) => (
          <StyledTableCell key={id}>
            <Skeleton variant="text" animation="wave" />
          </StyledTableCell>
        ))}
      </StyledTableRow>
    );
  };

  function statusLabel(status) {
    return (
      <Typography
        variant="caption"
        style={{ color: statusList[status - 1].color, fontWeight: "bold" }}
      >
        {statusList[status - 1].name}
      </Typography>
    );
  }

  return (
    <>
      {(rows.length > 0 || isFetching) && (
        <TableContainer>
          <Table
            className={classes.table}
            size="small"
            aria-label="product table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <StickyLeftTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.NUM")}
                  </StyledTableCell>
                </StickyLeftTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.PRODUCT")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.SERIAL_NUMBER")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.WARRANTY_CLAIM_REFERENCE_CODE")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.REQUEST_FROM")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.REQUEST_BY")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.SHIPPING_FEE")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.STATUS")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.REQUESTED_DATE")}
                </StyledTableCell>
                <StickyRightTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.ACTION")}
                  </StyledTableCell>
                </StickyRightTableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {isFetching ? (
                <>
                  <SkeletonTableRow />
                  <SkeletonTableRow />
                  <SkeletonTableRow />
                  <SkeletonTableRow />
                  <SkeletonTableRow />
                </>
              ) : (
                rows.map((row, index) => {
                  const actionItems = [
                    {
                      label: hasReimbursementEditAccessRight
                        ? getLang(lang, "label.VIEW_EDIT")
                        : getLang(lang, "label.VIEW"),
                      action: () => handleView(row),
                    },
                  ];

                  return (
                    <StyledTableRow
                      key={index}
                      hover
                      role="checkbox"
                      tabIndex={-1}
                    >
                      <StickyLeftTableCell>
                        <StyledTableCell
                          component="th"
                          scope="row"
                          style={{ borderBottom: "0px" }}
                        >
                          {index + 1 + rowPerPage * page}
                        </StyledTableCell>
                      </StickyLeftTableCell>
                      <StyledTableCell>
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          alignItems="flex-start"
                        >
                          <Typography variant="body2" style={{ fontSize: 12 }}>
                            <b>{row.product.name}</b>
                          </Typography>
                          {!!row.product.attributes &&
                            !!row.product.attributes.length && (
                              <Typography
                                className={classes.attributeText}
                                variant="body2"
                              >
                                {row.product.attributes.join(", ")}
                              </Typography>
                            )}
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell>
                        {row.product.serialNumber}
                      </StyledTableCell>
                      <StyledTableCell>
                        {row.claimReferenceCode}
                      </StyledTableCell>
                      <StyledTableCell>{row.node.name}</StyledTableCell>
                      <StyledTableCell>
                        {row.node.requestEmployee
                          ? `${
                              row.node.requestEmployee.firstName
                                ? row.node.requestEmployee.firstName
                                : ""
                            } 
                            ${
                              row.node.requestEmployee.lastName
                                ? row.node.requestEmployee.lastName
                                : ""
                            }`
                          : "-"}
                      </StyledTableCell>
                      <StyledTableCell>
                        {row.currencyCode} {row.shippingFee}
                      </StyledTableCell>
                      <StyledTableCell>
                        {statusLabel(row.status)}
                      </StyledTableCell>
                      <StyledTableCell>
                        {moment(row.createdAt).format("lll")}
                      </StyledTableCell>

                      <StickyRightTableCell>
                        <StyledTableCell>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <ActionButton
                              actionItems={actionItems}
                              popperStyle={{ zIndex: 999 }}
                            />
                          </div>
                        </StyledTableCell>
                      </StickyRightTableCell>
                    </StyledTableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {!isFetching && rows.length === 0 && !isLoadingError && (
        <Alert severity="info">
          {getLang(lang, "message.info.NO_WARRANTY_REIMBURSEMENT")}
        </Alert>
      )}

      {!isFetching && isLoadingError && (
        <RefreshTable handleReload={handleReload} />
      )}

      {!isFetching && rows.length > 0 && (
        <TablePagination
          style={{
            backgroundColor: "#fff",
            boxShadow: "0px -1px 0px rgba(58, 77, 84, 0.15)",
          }}
          ActionsComponent={TablePaginationActions}
          rowsPerPageOptions={paginationOptions}
          component="div"
          count={length}
          rowsPerPage={rowPerPage}
          page={page}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowPerPageChange}
          labelRowsPerPage={getLang(lang, 'label.ROWS_PER_PAGE')}
          labelDisplayedRows={({ from, to, count }) => `${from.toLocaleString("en-US")}-${to.toLocaleString("en-US")} ${getLang(lang, 'label.OF')} ${count.toLocaleString("en-US")}`}
        />
      )}
    </>
  );
}
