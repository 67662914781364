import { Content } from "modules/reporting/components/layout";
import { FilterPanelV3, LuckyDrawListPanel } from "modules/reporting/components/panel";
import React from "react";


export default function LuckyDrawPage({
    filter,
    tagUrl,
    currentFilter,
    pageMode,
    campaignType,
    translate
}) {
    const pageType="campaign-list"
    return (
        <>
            <FilterPanelV3
                filter={filter}
                currentFilter={currentFilter}
                pageMode={pageMode}
                translate={translate}
            />

            <Content>
                {/* survey list --- START */}
                <LuckyDrawListPanel 
                    currentFilter={currentFilter}
                    urlLuckyDrawList={tagUrl + "get-lucky-draw-info-list"}
                    // urlLuckyDrawList={"http://localhost:9080/luckyDrawList"}
                    pageType={pageType}
                    campaignType={campaignType}

                    translate={translate}
                />
            </Content>
        </>
    )
}