import axios from "axios";

const getAllProductByIds = async ({
    ids
}) => {
    const response = await axios.post('/serialnumber/api/v1/products/name-image-url', { ids });

    return response.data;
};

export default getAllProductByIds;