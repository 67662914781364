import React from "react"
import {
    makeStyles,
    Typography,
    Popper,
    Grow,
    Paper,
    ClickAwayListener,
    Box,
    Divider,
    MenuItem,
    MenuList,
    Checkbox
} from "@material-ui/core"
import PerfectScrollbar from "react-perfect-scrollbar"
import Skeleton from "@material-ui/lab/Skeleton"

const useStyle = makeStyles((theme) => ({
    paper: {
        width: 425
    },
    button: {
        padding: 0,
        minWidth: 0
    },
    buttonAddBranch: {
        display: "flex",
        padding: 0,
        minWidth: 0
    },
    greenLabel: {
        color: theme.palette.secondary.main
    }
}))

export default function TagSearchPanelComponent({
    selectAll,
    value,
    anchorRef,
    open,
    isOpenList,
    handleClose,
    isFetching,
    tagDropdown,
    handleSelectAll,
    handleSelectTag
}) {
    const classes = useStyle()

    return (
        <Popper
            open={open}
            style={{ zIndex: 99 }}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            placement="bottom-start"
        >
            {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{
                        transformOrigin:
                            placement === "bottom-start" ? "center top" : "center bottom"
                    }}
                >
                    <Paper className={classes.paper}>
                        <ClickAwayListener onClickAway={handleClose}>
                            <Box>
                                <PerfectScrollbar>
                                    {isFetching ? (
                                        <>
                                            <Skeleton variant="text" animation="wave" />
                                            <Skeleton variant="text" animation="wave" />
                                            <Skeleton variant="text" animation="wave" />
                                            <Skeleton variant="text" animation="wave" />
                                        </>
                                    ) : (
                                        <div>
                                            <MenuList
                                                autoFocusItem={isOpenList}
                                                id="menu-list-grow"
                                                onClick={() => {
                                                    handleSelectAll()
                                                }}
                                                style={{ padding: 0 }}
                                            >
                                                <MenuItem style={{ padding: 0 }}>
                                                    <Checkbox checked={selectAll} />
                                                    <Typography
                                                        variant="inherit"
                                                        noWrap
                                                        style={{ paddingLeft: "8px" }}
                                                    >
                                                        All
                                                    </Typography>
                                                </MenuItem>
                                            </MenuList>
                                            <Divider />

                                            <Box height="25vh" overflow="auto">
                                                {tagDropdown.map((tag, index) => {
                                                    const tagFormatInfoArray = tag.format.split(',')
                                                    const prefix = tagFormatInfoArray[0]
                                                    const digitLength = tagFormatInfoArray[1]
                                                    const postfix = tagFormatInfoArray[2]

                                                    return (
                                                        <React.Fragment key={index}>
                                                            <MenuItem
                                                                style={{ padding: 0 }}
                                                                onClick={() => handleSelectTag(tag)}
                                                            >
                                                                <Checkbox
                                                                    checked={!!value.find((v) => v === tag.format)}
                                                                />
                                                                <Typography
                                                                    variant="inherit"
                                                                    noWrap
                                                                    style={{ paddingLeft: "8px" }}
                                                                >
                                                                    <span className={classes.greenLabel}>{prefix}</span>
                                                                    <span>{` [${digitLength} digit(s)] `}</span>
                                                                    <span className={classes.greenLabel}>{postfix}</span>
                                                                </Typography>
                                                            </MenuItem>
                                                        </React.Fragment>
                                                    )
                                                })}
                                            </Box>
                                        </div>
                                    )}
                                </PerfectScrollbar>
                            </Box>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    )
}
