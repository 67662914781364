import axios from "axios";
import PropTypes from "prop-types";

const post = ({ payment_method, quantity, module, coupon_code }) => {
  const body = {
    payment_method,
    quantity,
    module,
    coupon_code
  };

  return new Promise((resolve, reject) => {
    axios
      .post("/payment/api/v1/payment", body)
      .then(response => resolve(response))
      .catch(error => {
        reject(error.response.data);
      });
  });
};

post.defaultProps = {
  coupon_code: ""
};

post.propTypes = {
  payment_method: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  module: PropTypes.string.isRequired,
  coupon_code: PropTypes.string.isRequired
};

const payment = {
  post
};

export default payment;
