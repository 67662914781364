import React from "react";
import { makeStyles, Box, Typography } from "@material-ui/core";
import { Editor } from "react-draft-wysiwyg";
import { TYPES, transformHTMLToRaw } from "./editor.container";
import { getLang } from "app/feature/constants";

const style = (theme) => ({
  root: {
    border: "1px solid rgba(0, 0, 0, 0.1)",
    borderRadius: "4px"
  },
  dropdownToolbar: {
    border: "none",
    fontFamily: "Roboto"
  },
  editor: {
    backgroundColor: "#EBEDEE",
    padding: theme.spacing(0, 1),
    maxHeight: 300,
    minHeight: 200
  },
  toolbar: {
    border: "none",
    marginBottom: 0,
    borderBottom: "1px solid",
    borderBottomColor: theme.palette.divider
  },
  btnSecondary: {
    color: theme.palette.common.white
  },
  textContainer: {
    padding: "10px 16px",
    borderBottom: "1px solid rgba(0, 0, 0, 0.1)"
  }
});

const useStyles = makeStyles(style);

export default function EditorComponent({
  type,
  handleChange,
  handleChangeText,
  editorState,
  initialValues,
  enableWriting,
  handleSave,
  isReady,
  handleStartWriting,
  texts,
  lang
}) {
  const classes = useStyles();

  return (
    <Box pt={2}>
      <Box className={classes.root}>
        <Box className={classes.textContainer}>
          <Typography
            variant="body2"
            style={{ color: "rgba(58, 77, 84, 0.5)" }}
          >
            {getLang(lang, 'label.PASTE_TERMS_CONDITIONS_BELOW')}
          </Typography>
        </Box>
        <Editor
          readOnly={type === TYPES[2]}
          toolbarHidden={type === TYPES[2]}
          editorState={
            type === TYPES[2]
              ? transformHTMLToRaw(initialValues.html)
              : editorState
          }
          onEditorStateChange={handleChange}
          toolbar={{
            options: [
              "inline",
              "list",
              "emoji",
              "link",
              "textAlign",
              "blockType",
              "fontSize"
            ],
            inline: {
              inDropdown: true,
              options: ["bold", "italic", "underline", "strikethrough"],
              className: classes.dropdownToolbar
            },
            list: {
              inDropdown: true,
              className: classes.dropdownToolbar
            },
            textAlign: {
              inDropdown: true,
              className: classes.dropdownToolbar
            },
            blockType: {
              inDropdown: true,
              className: classes.dropdownToolbar
            },
            link: {
              inDropdown: true,
              className: classes.dropdownToolbar
            },
            fontSize: {
              className: classes.dropdownToolbar
            },
            emoji: {
              className: classes.dropdownToolbar
            }
          }}
          editorClassName={classes.editor}
          toolbarClassName={classes.toolbar}
          onContentStateChange={handleSave}
        />
      </Box>
    </Box>
  );
}
