import React from "react";
import { Box, Typography, Divider, Button } from "@material-ui/core";
import { AddIcon } from "components/icon";
import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";
import { getLang } from "app/feature/constants";
import GroupListPanel from "modules/group/components/panel/groupListPanel";

export default function MainPageComponent({
  hasGroupAddAccessRight,
  lang,
  isBranchOnly,
  openCreateDialog,
}) {
  return (
    <Box>
      <Box gridArea="topbar">
        <Box
          mb={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="flex" alignItems="flex-end" fontWeight="bold">
            <Typography variant="h6" color="primary">
              {getLang(lang, "label.BRANCH")}
            </Typography>
            <Box mx={0.5}>
              <ChevronRightRoundedIcon color="disabled" />
            </Box>
            <Box>
              <Typography variant="h6" color="primary">
                {getLang(lang, "label.GROUP")}
              </Typography>
            </Box>
          </Box>

          {hasGroupAddAccessRight && !isBranchOnly ? (
            <Button
              variant="text"
              color="primary"
              startIcon={
                <AddIcon color="secondary" style={{ fontSize: "13px" }} />
              }
              disableElevation
              onClick={openCreateDialog}
            >
              {getLang(lang, "label.GROUP")}
            </Button>
          ) : null}
        </Box>
        <Divider />
      </Box>

      <Box gridArea="panel" mt={2}>
        <Box>
          <Box>
            <GroupListPanel openCreateDialog={openCreateDialog} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
