import React from "react";
import {
  makeStyles,
  ButtonBase,
  Typography
} from "@material-ui/core";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import clsx from "clsx";

const useStyle = makeStyles((theme) => ({
  root: {
    padding: "5px 12px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#ECEFF0",
    borderRadius: "4px",
    width: "100%",
    overflow: "hidden"
  },
  inputText: {
    fontSize: "0.875rem",
    color: "#A4A6A8",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontWeight: 500
  },
  filled: {
    color: "rgba(0, 0, 0, 0.87)"
  }
}));

export default function AttributeMultiSelectComponent({
  anchorRef,
  handleClick,
  value,
  styles,
  disabled,
  placeholder,
  textStyle
}) {
  const classes = useStyle();

  const getDisplay = () => {
    let display = placeholder;
    let attributeTemp = [];
    let optionsTemp = [];

    if(value.length > 0) {
      value.map((v) => {
        if(v.options.length > 0) {
          v.options.map((m) => {
            optionsTemp.push(m.value)
            return null
          })
          attributeTemp.push(`${v.name}(${optionsTemp.join(", ")})`)
        }
        return null;
      })

      display = attributeTemp.join(", ")
    }

    return display

  }

  return (
    <ButtonBase
      className={classes.root}
      disableRipple
      ref={anchorRef}
      styles={styles}
      onClick={handleClick}
      disabled={disabled}
    >
      <Typography
        variant='body2'
        style={textStyle}
        className={clsx(
          classes.inputText,
          getDisplay() !== placeholder && classes.filled
        )}
      >
        {getDisplay()}
      </Typography>
      <ArrowDropDownIcon fontSize='small'/>
    </ButtonBase>
  )
}