import PropTypes from "prop-types";
import axios from "axios";

const instance = axios.create({
  baseURL: ''
})

const upload = async (presignedPostData, file) => {
  if (!presignedPostData || !file) return;

  const { url, fields } = presignedPostData;

  const formData = new FormData();
  Object.keys(fields).forEach(key => {
    formData.append(key, fields[key]);
  });
  formData.append("file", file);

  const response = await instance({
    url,
    method: "POST",
    data: formData
    // headers: formData.getHeaders()
  });

  return response;
};

upload.propTypes = {
  presignedPostData: PropTypes.object.isRequired,
  file: PropTypes.object.isRequired
};

export default upload;
