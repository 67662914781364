import { makeStyles } from "@material-ui/core";

export const iconStyle = makeStyles((theme) => ({
    flag__icon: {
        width: "24.83px",
        height: "18.46px",
        boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
    },
    svg__icon: {
        width: "24px",
        height: "24px",
    },
    image__icon: {
        width: "140px",
        height: "140px",
    },
    small_image__icon: {
        width: "46px",
        height: "46px",
    },
    tierIcon: {
        borderRadius: "3px",
        marginRight: "0.75em",
        paddingLeft: "0.15em",
        paddingRight: "0.15em",
        display: "flex",
        alignItems: "center"
    },
}));