import React from "react";
import OrderHistoryPanelComponent from "./orderHistoryPanel.component";
import { getYearList } from "lib/generalUtility";
import { useSelector } from "react-redux";
import { getLang } from "app/feature/constants";

function OrderHistoryPanelContainer() {
  const [selectedYear, setselectedYear] = React.useState(
    new Date().getFullYear()
  );
  const lang = useSelector(state => state.constant.languages);
  const language = useSelector(state => state.profile.language);

  const [activeIndex, setActiveIndex] = React.useState(0);
  const tabLabels = [getLang(lang, "label.ALL"), getLang(lang, "label.QR_CREDIT"), getLang(lang, "label.SMS_CREDIT")];
  const yearlist = getYearList();

  const handleControl = (event) => {
    setselectedYear(event.target.value);
  };

  const handleChange = (event, newActiveIndex) => {
    setActiveIndex(newActiveIndex);
  };

  const compProps = {
    handleChange,
    activeIndex
  };

  return (
    <OrderHistoryPanelComponent
      {...compProps}
      tabLabels={tabLabels}
      selectedYear={selectedYear}
      handleControl={handleControl}
      yearlist={yearlist}
      lang={lang}
      language={language}
    />
  );
}

export default OrderHistoryPanelContainer;
