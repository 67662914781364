import React from "react";
import {
  ThemeProvider,
  createMuiTheme,
  responsiveFontSizes,
} from "@material-ui/core/styles"

const companyDefaultTheme = {
  palette: {
    type: "light",
    primary: {
      main: "#3C4D54",
    },
    secondary: {
      main: "#5A9559",
      light: "#92C892",
    },
    background: {
      default: "#ffffff",
      text: "#000000",
      paper: "#ffffff",
    },
    text: {
      primary: "#3C4D54",
      secondary: "758287",
    }
  },
  typography: {
    fontFamily: [
      "Roboto",
      "Montserrat",
      "Open Sans"
    ].join(",")
  },
};

const withCompanyTheme = Component => props => {
  const appTheme = responsiveFontSizes(createMuiTheme(companyDefaultTheme))

  return (
    <ThemeProvider theme={appTheme} >
      <Component {...props} />
    </ThemeProvider>
  );
};

export { withCompanyTheme };