import React from "react";
import {
  Typography,
  makeStyles,
  Box,
  Tabs,
  Divider,
  Button
} from "@material-ui/core";
import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";
import { TabPanel, allyProps } from "components/tabPanel";
import CustomTab from "components/tab";
import { AddIcon } from "components/icon";
import BrandModelListPanel from "../../components/panel/brandModelListPanel"
import { getLang } from "app/feature/constants";

const useStyle = makeStyles(theme => ({
  indicator: {
    border: "0.5px solid",
    borderColor: theme.palette.primary.main
  },
  scroll: {
    width: "min-content"
  }  
}));

export default function BrandModelComponent({
  hasProductBrandModelAddAccessRight,
  handleCreateBrandModel,
  isCreateDialogOpen,
  lang
}) {
  const classes = useStyle();

  return (
    <Box>
      <Box mb={1} display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="flex-end" fontWeight="bold">
          <Typography variant='h6' color='primary'>
            {getLang(lang,"label.PRODUCT")}
          </Typography>
          <Box mx={0.5}>
            <ChevronRightRoundedIcon color="disabled"/>
          </Box>
          <Typography variant='h6' color='primary'>
            {getLang(lang,"label.BRAND_MODEL")}
          </Typography>
        </Box>
        {hasProductBrandModelAddAccessRight && (
          <Button
            variant='text'
            color='primary'
            startIcon={
              <AddIcon color="secondary" style={{ fontSize: "13px" }} />
            }
            style={{ textTransform: "uppercase" }}
            onClick={() => handleCreateBrandModel(true)}
          >
            {getLang(lang,"label.BRAND_MODEL")}
          </Button>
        )}
      </Box>
      <Divider/>
      <Box>
        <Tabs
          value={0}
          aria-label="scrollable auto tabs example"
          textColor='primary'
          variant='scrollable'
          indicatorColor='primary'
          classes={{
            indicator: classes.indicator,
            scrollButtons: classes.scroll
          }}
        >
          <CustomTab {...allyProps(0, getLang(lang, 'label.BRAND_MODEL_LIST'))} />
        </Tabs>
        <TabPanel value={0} index={0}>
          <BrandModelListPanel handleCreateBrandModel={handleCreateBrandModel} isCreateDialogOpen={isCreateDialogOpen} />
        </TabPanel>
      </Box>
    </Box>
  )
}