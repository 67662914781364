/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import CourierCompanySelectComponent from "./courierCompanySelect.component";
import { selectLoading } from "modules/notification";
import CourierCompanySelectPanelComponent from "./courierCompanySelectPanel.component";
import { detectCourier } from "modules/stockflow/redux";
import { useSelector, useDispatch } from "react-redux";

function CourierCompanySelectContainer({
  handleChange,
  disabled,
  value,
  styles,
  textStyles,
  placeholder,
  tracking,
}) {
  const isFetching = useSelector((state) =>
    selectLoading(state, detectCourier.typePrefix)
  );
  const lang = useSelector((state) => state.constant.languages);
  const couriers = useSelector(
    (state) => state.stockflowCourier.courierList.list
  );
  const isError = useSelector(
    (state) => state.stockflowCourier.courierList.isError
  );

  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const prevOpen = useRef(open);
  const dispatch = useDispatch();

  useEffect(() => {
    if (couriers.length && !value && tracking) {
      handleChange(couriers[0]);
    }
  }, [couriers]);

  useEffect(() => {
    if (tracking) {
      dispatch(detectCourier(tracking));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, tracking]);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleSelectCourier = (courier) => {
    handleChange(courier);
    setOpen(false);
  };

  const handleReload = () => {
    dispatch(detectCourier(tracking));
  };

  return (
    <>
      <CourierCompanySelectComponent
        anchorRef={anchorRef}
        disabled={disabled}
        value={value}
        handleClick={handleOpen}
        styles={styles}
        textStyles={textStyles}
        placeholder={placeholder}
        lang={lang}
        isFetching={isFetching}
      />
      <CourierCompanySelectPanelComponent
        value={value}
        anchorRef={anchorRef}
        open={open}
        handleClose={handleClose}
        isFetching={isFetching}
        couriers={couriers}
        handleSelectCourier={handleSelectCourier}
        lang={lang}
        handleReload={handleReload}
        isError={isError}
      />
    </>
  );
}

export default CourierCompanySelectContainer;
