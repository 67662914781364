import React, { useState, useRef, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useRouteMatch } from "react-router-dom"
import { generatePaginationOptions, updateUrlQueryParam } from "lib/helper"
import { selectLoading } from "modules/notification"
import moment from "moment"
import DealerRewardListPanelComponent from "./dealerRewardListPanel.component"
import { getDealerRewardList, getRedemptionDetails, resetDealerRewardListing } from "modules/stockflow/redux"
import RedemptionDetailsDialog from "../../dialog/redemptionDetails"
import { ROOT as EMPLOYEE_ROOT } from "modules/employee/config/employee.route";
import { STOCKFLOW_REWARD_CENTER_VIEW } from "lib/constants/accessRights";

function DealerRewardListPanelContainer() {
  const dispatch = useDispatch()
  const history = useHistory();
  const match = useRouteMatch()

  const id = match.params.dealerId
  const lang = useSelector(state => state.constant.languages)
  const language = useSelector(state => state.profile.language)
  const hasStockflowRewardCenterViewAccessRight = useSelector(state => state.profile.accessRights.includes(STOCKFLOW_REWARD_CENTER_VIEW));

  const isRewardFetching = useSelector(state => selectLoading(state, getDealerRewardList.typePrefix))

  const totalFiltered = useSelector(state => state.stockflowDealer.rewardList.totalFiltered)
  const rewardList = useSelector(state => state.stockflowDealer.rewardList.list)
  const isError = useSelector(state => state.stockflowDealer.rewardList.isError)
  
  const details = useSelector(state => state.stockflowRewardCenter.redemptionDetail)
  const isFetchingDetails = useSelector(state => selectLoading(state, getRedemptionDetails.typePrefix));
  
  const [paginationOptions, setPaginationOptions] = useState([]);
  const [tableFilter, setTableFilter] = useState({
    page: 0,
    rowsPerPage: 25,
    order: "desc",
    orderBy: "created_at", // created_at|name
    search: "",
    fromDate: "",
    toDate: "",
  })
  const [isViewDialogOpen, setIsViewDialogOpen] = useState(false);

  const tableFilterInitialRef = useRef(true)
  
  useEffect(() => {
    return () => {
      dispatch(resetDealerRewardListing())
    }
  }, [dispatch])

  useEffect(() => {
    const searchParam = new URLSearchParams(window.location.search);
    const page = searchParam.get("page")
    const length = searchParam.get("length")
    const order = searchParam.get("order")
    const orderBy = searchParam.get("order_by")
    const search = searchParam.get("search")
    const fromDate = searchParam.get("from_date")
    const toDate = searchParam.get("to_date")

    setTableFilter(prev => ({
      ...prev,
      page: page ? page - 1 : tableFilter.page,
      rowsPerPage: length || tableFilter.rowsPerPage,
      search: search || tableFilter.search,
      fromDate: fromDate || tableFilter.fromDate,
      toDate: toDate || tableFilter.toDate,
      order: order || tableFilter.order,
      orderBy: orderBy || tableFilter.orderBy
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!tableFilterInitialRef.current) {
      dispatch(resetDealerRewardListing())
      getData()
    } 
    tableFilterInitialRef.current = false
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableFilter])

  useEffect(() => {
    setPaginationOptions(generatePaginationOptions(totalFiltered))
  }, [totalFiltered])

  const getData = () => {
    dispatch(getDealerRewardList({
      id,
      length: tableFilter.rowsPerPage,
      start: tableFilter.rowsPerPage * tableFilter.page,
      order: tableFilter.order,
      order_by: tableFilter.orderBy,
      filter: {
        search: tableFilter.search,
        from_date: tableFilter.fromDate,
        to_date: tableFilter.toDate,
      }
    }))
  }

  const handleChangePage = (event, newPage) => {
    let newData = {
      ...tableFilter,
      page: newPage,
    }
    updateUrlQueryParam({
      page: newData.page + 1,
      length: newData.rowsPerPage
    })
    setTableFilter(newData)
  }

  const handleChangeRowsPerPage = event => {
    let newData = {
      ...tableFilter,
      page: 0,
      rowsPerPage: event.target.value,
    }
    updateUrlQueryParam({
      page: newData.page + 1,
      length: newData.rowsPerPage
    })
    setTableFilter(newData)
  }

  const handleSearch = (filter) => {
    const { search, dateRange } = filter

    let newData = {
      ...tableFilter,
      page: 0,
      search,
      fromDate: dateRange?.from ? moment(dateRange.from).format("YYYY-MM-DD") : "",
      toDate: dateRange?.to ? moment(dateRange.to).format("YYYY-MM-DD") : "",
    }

    updateUrlQueryParam(
      { search: newData.search, from_date: newData.fromDate, to_date: newData.toDate },
      ["page", "length"]
    );
    setTableFilter(newData)
  }

  const clearSearch = () => {
    let newData = {
      ...tableFilter,
      page: 0,
      search: "",
      fromDate: "",
      toDate: "",
    }
    updateUrlQueryParam(
      null,
      ["page", "length", "search", "from_date", "to_date"]
    );
    setTableFilter(newData)
  }

  const changeOrder = (orderBy, order) => {
    let newData = {
      ...tableFilter,
      order,
      orderBy
    }
    updateUrlQueryParam({ order: newData.order, order_by: newData.orderBy });
    setTableFilter(newData)
  }
  
  const handleViewDetail = (uuid) => {
    setIsViewDialogOpen(true)
    dispatch(getRedemptionDetails({
      id: uuid
    }))
  }

  const handleViewEmployee = id => history.push(`${EMPLOYEE_ROOT}/View/${id}`);

  return (
    <>
      <DealerRewardListPanelComponent
        hasStockflowRewardCenterViewAccessRight={hasStockflowRewardCenterViewAccessRight}
        id={id}
        rewards={rewardList}
        isFetching={isRewardFetching}
        isError={isError}
        handleChangePage={handleChangePage}
        language={language}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        page={tableFilter.page}
        rowsPerPage={tableFilter.rowsPerPage}
        totalFiltered={totalFiltered}
        handleSearch={handleSearch}
        clearSearch={clearSearch}
        handleReload={getData}
        paginationOptions={paginationOptions}
        tableFilter={tableFilter}
        changeOrder={changeOrder}
        lang={lang}
        handleViewDetail={handleViewDetail}
      />
      <RedemptionDetailsDialog
        isOpen={isViewDialogOpen}
        handleClose={() => setIsViewDialogOpen(false)}
        details={details}
        remarks=""
        isFetchingDetails={isFetchingDetails}
        handleViewDealer={() => {}}
        handleViewEmployee={handleViewEmployee}
        isDealerPage={true}
      />
    </>
  )
}

export default DealerRewardListPanelContainer
