import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { 
  resetProductDropdown,
  getStockModuleSetting,
  createIncentiveProgram,
  getNewProgramProductDropdown
} from "modules/stockflow/redux";
import AddIncentiveProgramComponent from "./add-incentive-program.page";
import { DEALEAR_PROGRAM_LIST_PAGE } from "modules/stockflow/config/stockflow.route"
import { useHistory } from "react-router-dom"
import { unwrapResult } from "@reduxjs/toolkit";
import { selectLoading } from "modules/notification";

export default function AddIncentiveProgramContainer() {
  const dispatch = useDispatch();
  const history = useHistory();
  const productDropdown = useSelector(state => state.stockflowIncentiveProgram.productDropdown);
  const maxTier = useSelector(state => state.stockflowIncentiveProgram.maxTier)
  const isFetchingProduct = useSelector(state => selectLoading(state, getNewProgramProductDropdown.typePrefix))
  const isSubmitting = useSelector(state => selectLoading(state, createIncentiveProgram.typePrefix));
  const lang = useSelector(state => state.constant.languages);
  useEffect(() => {
    dispatch(getNewProgramProductDropdown())
    dispatch(getStockModuleSetting())

    return () => {
      dispatch(resetProductDropdown())
    }
  }, [dispatch])

  const handleSubmit = async (values) => {

    await dispatch(createIncentiveProgram({
      name: values.name.trim(),
      percentages: values.tier,
      isSelectAll: false,
      products: values.assignProduct
    }))
      .then(unwrapResult)
      .then(() => {
        history.push(DEALEAR_PROGRAM_LIST_PAGE)
      })
      .catch()
  }

  return (
    <AddIncentiveProgramComponent 
      productDropdown={productDropdown}
      maxTier={maxTier}
      handleSubmit={handleSubmit}
      lang={lang}
      isSubmitting={isSubmitting}
      isFetchingProduct={isFetchingProduct}
    />
  )
}
