import React from "react";
import { useSelector } from "react-redux";
import LoyaltyPointComponent from "./loyaltyPoint.component";

export default function LoyaltyPointContainer({ id }) {
  const { isVisible } = useSelector(
    (state) => state.productPage.components[id]
  );
  const lang = useSelector((state) => state.constant.languages);
  const userLanguage = useSelector((state) => state.profile.language);

  return (
    isVisible && <LoyaltyPointComponent lang={lang} userLanguage={userLanguage} />
  );
}