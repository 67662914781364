import React from "react";
import {
  makeStyles,
  Typography,
  Popover,
  Box,
  MenuItem,
  MenuList,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

const useStyle = makeStyles((theme) => ({
  button: {
    padding: 0,
    minWidth: 0,
  },
  perfectScrollBar: {
    maxHeight: 200,
    overflow: "auto",
  },
  list: {
    paddingTop: 6,
    paddingBottom: 6,
  },
}));

function StatusSelectPanelComponent({
  open,
  anchorEl,
  handleClose,
  id,
  style,
  placeholder,
  items,
  isFetching,
  emptyMessage,
  handleListKeyDown,
  anchorOrigin,
  transformOrigin,
  PaperProps,
  selected,
}) {
  const classes = useStyle();

  const displayMenuItem = () => {
    return (
      <>
        {isFetching ? (
          <>
            <Skeleton variant="text" animation="wave" height={25} />
            <Skeleton variant="text" animation="wave" height={25} />
            <Skeleton variant="text" animation="wave" height={25} />
            <Skeleton variant="text" animation="wave" height={25} />
          </>
        ) : items.length > 0 ? (
          items.map((item) => (
            <Box key={item.name ? item.name : item}>
              <MenuList id="menu-list-grow" className={classes.list}>
                <MenuItem
                  selected={selected(item)}
                  onClick={() => handleListKeyDown(item)}
                >
                  <Typography
                    variant="body1"
                    noWrap
                    style={{ paddingLeft: 8, paddingRight: 8 }}
                  >
                    {item.name ? item.name : item}
                  </Typography>
                </MenuItem>
              </MenuList>
            </Box>
          ))
        ) : (
          <Box py={9} style={{ textAlign: "center" }}>
            <Typography variant="body1">{emptyMessage}</Typography>
          </Box>
        )}
      </>
    );
  };

  return (
    <Popover
      id={id}
      open={open}
      style={style}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      elevation={2}
      PaperProps={PaperProps}
    >
      <Box className={classes.perfectScrollBar}>{displayMenuItem()}</Box>
    </Popover>
  );
}

export default StatusSelectPanelComponent;
