import React, { useRef } from "react";
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Radio,
  makeStyles,
} from "@material-ui/core";
import { useDrag, useDrop } from "react-dnd";
import InputTextField from "components/input/inputTextField";
import { getLang } from "app/feature/constants";
import AntSwitch from "components/switch/switch";
import {
  Delete as DeleteIcon,
  DragIndicator as DragIcon,
} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  deleteIcon: {
    color: "#F04438",
    cursor: "pointer",
  },
}));

export default function DraggableReasonCardComponent({
  index,
  moveCard,
  lang,
  reason,
  updateReason,
  reasons,
  usedLang,
  languageTabIndex,
  isSubmitting,
}) {
  const classes = useStyles();
  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: "card",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      if (!isSubmitting) {
        moveCard(dragIndex, hoverIndex, reasons);
      }
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: "card",
    item: { index, type: "card" },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return (
    <Box ref={ref} style={{ opacity }} data-handler-id={handlerId}>
      <Box mb={1} width="100%" key={index}>
        <Grid container>
          {/*Field for reason name, disable if have id*/}
          <Grid item xs={10}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <Box mr={1}>
                <DragIcon />
              </Box>

              <InputTextField
                variant="filled"
                size="small"
                fullWidth
                disabled={isSubmitting}
                placeholder={getLang(lang, "placeholder.REASON_INPUT")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <IconButton
                        edge="end"
                        onClick={() => {
                          if (!reason.uuid) {
                            let temp = [...reasons];
                            temp.splice(index, 1);
                            updateReason(temp);
                          }
                        }}
                        disabled={!!reason.uuid}
                      >
                        <DeleteIcon
                          size={18}
                          className={classes.deleteIcon}
                          style={{ opacity: !!reason.uuid ? 0.5 : 1 }}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  let temp = [...reasons];
                  const code = usedLang[languageTabIndex].code;
                  temp[index] = {
                    ...temp[index],
                    name: {
                      ...temp[index].name,
                      [code]: e.target.value,
                    },
                    is_edited: true,
                  };
                  updateReason(temp);
                }}
                value={
                  Object.keys(reason.name).length
                    ? reason?.name[usedLang[languageTabIndex].code] ?? ""
                    : ""
                }
              />
            </Box>
          </Grid>
          {/*Switch to enable reason*/}
          <Grid item xs={1}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <AntSwitch
                disabled={isSubmitting}
                checked={!!reason.is_enabled}
                onChange={(value) => {
                  let temp = [...reasons];
                  temp[index] = {
                    ...temp[index],
                    is_enabled: value.target.checked,
                    is_edited: true,
                  };
                  updateReason(temp);
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={1}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <Radio
                disabled={isSubmitting}
                checked={reason.is_default}
                name="pattern-selection"
                size="small"
                style={{ padding: "6px 10px 6px 6px" }}
                onChange={(event) => {
                  let temp = [...reasons];

                  let prvIndex = temp.findIndex((r) => !!r.is_default);
                  temp[prvIndex] = {
                    ...temp[prvIndex],
                    is_default: false,
                    is_edited: true,
                  };
                  temp[index] = {
                    ...temp[index],
                    is_default: true,
                    is_edited: true,
                  };

                  updateReason(temp);
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
