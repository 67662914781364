import React from "react";
import { Typography, Box } from "@material-ui/core";
import * as Yup from "yup";
import { Formik, ErrorMessage } from "formik";
import { TYPES } from "./tiktokForm.container";
import { ModalFooter, ContentWrapper } from "../../modal";
import Input from "components/input/inputTextField";
import { getLang } from "app/feature/constants";

export default function TiktokFormComponent({
  type,
  initialValues,
  handleDelete,
  handleSubmit,
  lang,
  handleClose
}) {
  return (
    <Formik
      initialValues={{
        URL: initialValues.URL
      }}
      validationSchema={Yup.object({
        URL: Yup.string().required(getLang(lang,"message.error.FORM_VALIDATE_REQUIRED"))
      })}
      onSubmit={handleSubmit}
    >
      {formik => (
        <form onSubmit={formik.handleSubmit}>
          <ContentWrapper>
            <Typography variant="body2" gutterBottom>
              {getLang(lang,"label.INSERT_TIKTOK_URL")} <Typography style={{ color: "#DE350B", display: 'inline-block' }}>*</Typography>
            </Typography>
            <Input
              required
              hiddenLabel
              variant="filled"
              size="small"
              margin="normal"
              placeholder={getLang(lang,"placeholder.ENTER_URL_HERE")}
              fullWidth
              {...formik.getFieldProps("URL")}
              value={type === TYPES[2] ? initialValues.URL : formik.values.URL}
              error={formik.touched.URL && formik.errors.URL ? true : false}
              helperText={ErrorMessage({ name: "URL" })}
              inputProps={{ readOnly: type === TYPES[2] }}
            />
            <Typography variant="body2">
              <span style={{ color: "gray" }}>{getLang(lang,"label.EXAMPLE")}&nbsp;</span>
              https://www.tiktok.com/@scout2015/video/6718335390845095173
            </Typography>
            <Box mt={3}>
              <Typography variant="caption" color="textSecondary">
              <Typography style={{ color: "#DE350B", display: 'inline-block' }}>*</Typography> {getLang(lang,"label.REQUIRED_FIELD")}
              </Typography>
            </Box>
          </ContentWrapper>
          <ModalFooter
            handleDelete={handleDelete}
            showDelete={type !== TYPES[0]}
            handleClose={handleClose}
          />
        </form>
      )}
    </Formik>
  );
}
