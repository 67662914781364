import React, { useState } from "react";
import { useSelector } from "react-redux";
import StatusSelectComponent from "./statusSelect.component";
import StatusSelectPanelComponent from "./statusSelectPanel.component";

export default function StatusSelectContainer({
  handleChange,
  value,
  disabled,
  style,
  placeholder,
  panelStyle,
  dropdownItem,
  emptyMessage,
  anchorOrigin,
  transformOrigin,
  PaperProps,
  textStyle,
  isEmpty,
}) {
  const lang = useSelector((state) => state.constant.languages);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleListKeyDown = (item) => {
    handleChange(item.value);
    handleClose();
  };

  const selected = (item) => {
    return value === item.value;
  };

  return (
    <>
      <StatusSelectComponent
        handleClick={handleClick}
        value={value}
        isEmpty={isEmpty}
        style={style}
        disabled={disabled}
        placeholder={placeholder}
        id={id}
        open={open}
        textStyle={textStyle}
        lang={lang}
      />
      <StatusSelectPanelComponent
        anchorEl={anchorEl}
        open={open}
        handleClose={handleClose}
        style={panelStyle}
        id={id}
        items={dropdownItem}
        emptyMessage={emptyMessage}
        handleListKeyDown={handleListKeyDown}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        PaperProps={PaperProps}
        selected={selected}
      />
    </>
  );
}
