import React, { useState, useEffect } from "react";
import BranchSearchFieldComponent from "./branchSearchField.component";
import BranchSearchPopoverComponent from './branchSearchPopover.component';
import { useSelector } from "react-redux";

function BranchSearchFieldContainer({
  value,
  placeholder,
  searchPlaceholder,
  handleChange,
  disabled,
  isFetching,
  panelStyle,
  dropdownItem,
  anchorOrigin,
  transformOrigin,
  PaperProps,
  style,
  textStyle,
  isClearable,
  handleClear,
  emptyMessage,
}) {
  const lang = useSelector((state) => state.constant.languages);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [productList, setProductList] = useState(dropdownItem);
  const drawerOpen = useSelector((state)=>state.app.drawerOpen);

  useEffect(() => {
    setProductList(dropdownItem);
  }, [dropdownItem]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
    handleSearch("");
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSearch = (targetProduct) => {
    if (targetProduct.trim().length === 0) {
      setProductList(dropdownItem);
      return;
    }
    let products = [];
    dropdownItem.forEach(product => {
      let productName = product.name ?? "";
      if (productName.toLowerCase().includes(targetProduct.toLowerCase())) {
        products.push(product);
      }
    });

    setProductList(products);
  }

  return (
    <>
      <BranchSearchFieldComponent
        handleClick={handleClick}
        handleClear={handleClear}
        value={value}
        style={style}
        disabled={disabled}
        placeholder={placeholder}
        open={open}
        handleChange={handleChange}
        textStyle={textStyle}
        isClearable={isClearable}
        drawerOpen={drawerOpen}
        items={dropdownItem}
      />
      <BranchSearchPopoverComponent
        anchorEl={anchorEl}
        open={open}
        handleClose={handleClose}
        style={panelStyle}
        items={productList}
        handleSearch={handleSearch}
        isFetching={isFetching}
        handleChange={handleChange}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        PaperProps={PaperProps}
        value={value}
        emptyMessage={emptyMessage}
        placeholder={searchPlaceholder}
        lang={lang}
      />
    </>
  )
}

export default BranchSearchFieldContainer
