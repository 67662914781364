import React, { useEffect, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectLoading } from "modules/notification";
import TopScanProductsPanelComponent from "./topScanProductsPanel.component";
import { getTopScanProduct } from "modules/dashboard/redux/action/dashboard.action";
import moment from "moment";

function TopScanProductsPanelContainer({ dateRange }) {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.constant.languages);
  const topScanProducts = useSelector(
    (state) => state.dashboard.topScanProducts.data
  );
  const isError = useSelector(
    (state) => state.dashboard.topScanProducts.isError
  );
  const isFetching = useSelector((state) =>
    selectLoading(state, getTopScanProduct.typePrefix)
  );

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange]);

  const getData = () => {
    let param = {
      start_date: moment(dateRange[0]).format("YYYY-MM-DD"),
      end_date: moment(dateRange[1]).format("YYYY-MM-DD"),
    };
    dispatch(getTopScanProduct(param));
  };

  return (
    <TopScanProductsPanelComponent
      handleReload={getData}
      lang={lang}
      data={topScanProducts}
      isError={isError}
      loading={isFetching}
    />
  );
}

export default memo(TopScanProductsPanelContainer);
