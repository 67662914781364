import React from "react";
import {
  makeStyles,
  Box,
  Paper,
  Divider,
  Typography,
  IconButton,
  Backdrop
} from "@material-ui/core";
import PerfectScrollbar from "react-perfect-scrollbar";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Dialog from "components/dialog/customDialog";
import PhoneTopView from "../../../utils/image/phoneTopView.png";
import PreviewBackground from "../../../utils/image/consumer_background.png";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import DefaultImg from "assets/img/img-default.png";
import { SpinGrid, SpinWheel } from "../../game";
import { gameType } from "lib/constants/instantGameTemplate";
import demoPrizes from "modules/instant-lucky-draw/utils/demoPrizes";
import { getLang } from "app/feature/constants";

const style = (theme) => ({
  header: {
    padding: "4px 0px",
    display: "grid",
    gridTemplateColumns: "1fr 4fr 6fr",
    alignItems: "center"
  },
  root: {
    width: 650,
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  content: {
    background: "#F4F6F8",
    padding: theme.spacing(2),
    textAlign: "center"
  },
  previewContent: {
    backgroundColor: "#262D30",
    borderRadius: "20px",
    maxWidth: 400,
    padding: theme.spacing(1)
  },
  consumerBackground: {
    backgroundImage: `url(${PreviewBackground})`,
    backgroundSize: "cover",
    overflow: "hidden",
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20
  },
  logo: {
    width: 50,
    height: 50,
    borderRadius: "50%",
    backgroundColor: theme.palette.common.white,
    display: "flex"
  },
  scroll: {
    overflowY: "auto",
    height: "650px",
    [theme.breakpoints.down("sm")]: {
      height: "600px"
    },
    [theme.breakpoints.down("xs")]: {
      height: "100vh"
    }
  },
  demoRoot: {
    backgroundSize: "cover",
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    minHeight: "100vh"
  },
  title: {
    color: "white",
    fontSize: "2.2em",
    textAlign: "center",
    maxWidth: "80%",
    fontFamily: "verdana",
    fontWeight: 700,
    textShadow: `2px 4px 3px rgb(146 146 146),
1px 22px 10px rgba(16,16,16,0.2),
1px 25px 35px rgba(16,16,16,0.2),
1px 3px 0px rgba(150, 150, 150, 1)`,
    zIndex: 10,
    marginBottom: 30
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: 40
  }
});

const useStyles = makeStyles(style);

export default function ViewDemoDialogComponent({
  logo,
  isOpen,
  handleClose,
  type,
  templateConfig,
  lang
}) {
  const classes = useStyles();

  const renderGame = ({
    templateConfig,
    type
  }) => {
    const prizes = demoPrizes({lang})
  
    switch (type) {
      case gameType.wheelOfFortune:
        return <SpinWheel
          config={templateConfig}
          prizes={prizes}
        />
      case gameType.treasureBox:
        return <SpinGrid
          config={templateConfig}
          prizes={prizes}
        />
      default:
        break;
    }
  }

  const LayoutHeader = () => (
    <Box
      display="grid"
      alignItems="center"
      gridTemplateColumns="1fr 6fr 1fr"
      px={3}
      pt={2}
      pb={1}
    >
      <ArrowBackIosIcon
        fontSize="small"
        style={{ color: "#fff" }}
      />
      <Typography
        variant="subtitle1"
        color="primary"
        style={{ fontWeight: "bold", color: "#fff" }}
      >
        {getLang(lang,"label.INSTANT_LUCKY_DRAW")}
      </Typography>

      <img
        src={logo || DefaultImg}
        alt="logo"
        className={classes.logo}
      />
    </Box>
  )
  if (!templateConfig) {
    return <></>
  } else {
    return (
      <Dialog
        isOpen={isOpen}
        handleClose={handleClose}
        disableEnforceFocus={true}
        disableAutoFocus={true}
        disableEscapeKeyDown={true}
        closeAfterTransition
        BackdropComponent={Backdrop}
      >
        <Paper elevation={0} className={classes.root}>
          <Box className={classes.header}>
            <IconButton color="primary" onClick={handleClose}>
              <ArrowBackIcon fontSize="small" />
            </IconButton>

            <Typography
              variant="subtitle2"
              color="primary"
              style={{ fontWeight: "bold" }}
            >
              {getLang(lang,"label.DETAILS")}
            </Typography>

            <Typography
              variant="subtitle2"
              color="primary"
              style={{ fontWeight: "bold" }}
            >
              {getLang(lang,"label.DEMO")}
            </Typography>
          </Box>

          <Divider />

          <PerfectScrollbar
            className={classes.scroll}
            options={{
              suppressScrollX: true,
            }}
          >
            <Box className={classes.content}>
              <Box
                mt={2}
                style={{
                  display: "flex",
                  flexFlow: "column",
                  alignItems: "center"
                }}
              >
                <div className={classes.previewContent}>
                  <div style={{ padding: "0 8px" }}>
                    <img src={PhoneTopView} alt="phoneTop" width="100%" />
                  </div>
                  <Box className={classes.consumerBackground}>
                    <LayoutHeader />
                    <Box className={classes.demoRoot} style={{
                      backgroundImage: `url('${templateConfig.backgroundImage}')`,
                    }}>
                      <Box className={classes.contentContainer}>
                        <Typography className={classes.title}>
                          {getLang(lang,"label.GREAT_PRIZE_TO_BE_WON")}
                        </Typography>
                        {renderGame({
                          templateConfig,
                          type
                        })}
                      </Box>
                    </Box>
                  </Box>
                </div>
              </Box>
            </Box>
          </PerfectScrollbar>
        </Paper>
      </Dialog>
    );
  }
}
