import {useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import ProductSelectComponent from "./productSelect.component";
import ProductSelectPopoverComponent from "./productSelectPopover.component";

export default function ProductSelectFieldContainer({
  searchable,
  value,
  placeholder,
  searchPlaceholder,
  handleChange,
  disabled,
  isFetching,
  panelStyle,
  dropdownItem,
  anchorOrigin,
  transformOrigin,
  PaperProps,
  style,
  textStyle,
  isEmpty,
  isClearable,
  handleClear,
  emptyMessage,
  handleDeleteChip,
}) {

  const lang = useSelector(state => state.constant.languages);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [productList, setProductList] = useState(dropdownItem);
  const drawerOpen = useSelector((state)=>state.app.drawerOpen);
  const [extraChip, setExtraChip] = useState({
    count : 0,
    offset : 0,
    allOverflow : false,
    overflowChild : []
  });

  const adjustField = (ref) => {
    let children = ref.current?.children[0]?.children;
    let p = ref.current?.children[0];
    if (!children) return;

    if (children.length > 0) {
      // Need to exclude placeholder
      if (children[0].role == null) return;

      children = [...children];

      // max width is 90%. Use 0.9 to calc field width
      let panelWidth = ref.current?.getBoundingClientRect().width * 0.9;
      let childWidth = 0;

      // Check for no overflow child count
      let inRangeCount = children.filter((child, index)=>{

        let panelContentWidth = p.getBoundingClientRect().width
        // get exact width + padding with decimal
        childWidth += (parseInt(window.getComputedStyle(child).marginRight) + child.getBoundingClientRect().width);

        // Check if overflow
        if(panelWidth - panelContentWidth < 1) {
          // need to add 0.5 as the ellipsis padding (0.5 = ellipsis padding)
          // if the overflow happen before last child, need inlude another 10 for the ellipsis (10 = ellipsis width)
          return (index !== (children.length - 1)) ? (childWidth + 10.5) < panelContentWidth : childWidth + 0.5 < panelContentWidth;
        }

        return (panelWidth > panelContentWidth);
      }).length;


      if (inRangeCount > 0) {

        let lastRangeChild = children[inRangeCount - 1];

        let outRangeChild = value.slice(inRangeCount);

        // Check for overflow child count
        let extraCount = value.length - inRangeCount;
        setExtraChip({
          count : extraCount,
          offset : lastRangeChild.offsetLeft + lastRangeChild.offsetWidth,
          allOverflow : false,
          overflowChild : outRangeChild
        });
      } else {
        setExtraChip({
          count : value.length,
          offset : 0,
          allOverflow : true,
          overflowChild : value
        });
      }
    } else {
      setExtraChip({
        count : 0,
        offset : 0,
        allOverflow : false,
        overflowChild : []
      });
    }
  }

  useEffect(() => {
    setProductList(dropdownItem);
  }, [dropdownItem]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
    handleSearch("");
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSearch = (targetProduct) => {
    if (targetProduct.trim().length === 0) {
      setProductList(dropdownItem);
      return;
    }
    let products = [];
    dropdownItem.forEach(product => {
      let productName = product.name ?? "";
      if (productName.toLowerCase().includes(targetProduct.toLowerCase())) {
        products.push(product);
      }
    });

    setProductList(products);
  }

  const selectedValue = (selectedValue, index) => {
    if (isEmpty) {
      return false
    }

    return false
  }

  return (
    <>
      <ProductSelectComponent
        handleClick={handleClick}
        handleClear={handleClear}
        value={value}
        style={style}
        disabled={disabled}
        placeholder={placeholder}
        open={open}
        textStyle={textStyle}
        isClearable={isClearable}
        lang={lang}
        adjustField={adjustField}
        extraChip={extraChip}
        drawerOpen={drawerOpen}
        handleDeleteChip={handleDeleteChip}
        handleChange={handleChange}
      />
      <ProductSelectPopoverComponent
        anchorEl={anchorEl}
        open={open}
        handleClose={handleClose}
        style={panelStyle}
        items={productList}
        handleSearch={handleSearch}
        isFetching={isFetching}
        searchable={searchable}
        handleChange={handleChange}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        PaperProps={PaperProps}
        value={value}
        selectedValue={selectedValue}
        emptyMessage={emptyMessage}
        placeholder={searchPlaceholder}
      />
    </>
  )
}
