import { createSlice } from "@reduxjs/toolkit";
import { fetchQuickSubscriptionSummary } from "./billing.action";

export const BILLING = "billing";

const initialState = {
  credits: null,
  smsCredit: null,
  licenseExpDate: null,

  billingDetail: {
    name: "",
    email: "",
    phone: 0,
    phoneCode: "",
    line1: "",
    line2: "",
    city: "",
    postalCode: "",
    state: "",
    country: ""
  },
  subscriptionPlan: {
    id: "",
    object: "",
    active: "",
    aggregateUsage: null,
    amount: 0,
    amountDecimal: 0,
    billingScheme: "",
    created: "",
    currency: "",
    interval: "",
    intervalCount: "",
    livemode: "",
    metadata: {},
    nickname: "",
    product: "",
    tiers: null,
    tiersMode: null,
    transformUsage: null,
    trialPeriodDays: null,
    usageType: "",
    productName: "",
    currentPeriodEnd: ""
  },
  //Table
  transactionHistory: {
    data: [],
    totalRecords: 0,
    filteredTotalRecords: 0
  },
  smsTransactionHistory: {
    data: [],
    totalRecords: 0,
    filteredTotalRecords: 0
  },
  qrTransactionHistory: {
    data: [],
    totalRecords: 0,
    filteredTotalRecords: 0
  },
  //Latest3
  transactionLatest: {
    data: []
  }
};

const billingSlice = createSlice({
  name: BILLING,
  initialState,
  reducers: {
    setCredit(state, action) {
      state.credits = action.payload;
    },
    setBillingDetail(state, action) {
      const { payload } = action;
      state.billingDetail.name = payload.name || state.billingDetail.name;
      state.billingDetail.email = payload.email || state.billingDetail.email;
      state.billingDetail.phone = payload.phone || state.billingDetail.phone;
      state.billingDetail.phoneCode = payload.contact_code || state.billingDetail.phoneCode;
      state.billingDetail.line1 = payload.line1 || state.billingDetail.line1;
      state.billingDetail.line2 = payload.line2 || state.billingDetail.line2;
      state.billingDetail.city = payload.city || state.billingDetail.city;
      state.billingDetail.postalCode =
        payload.postal_code || state.billingDetail.postalCode;
      state.billingDetail.state = payload.state || state.billingDetail.state;
      state.billingDetail.country =
        payload.country || state.billingDetail.country;
    },
    setSubcriptionPlan(state, action) {
      const { payload } = action;

      if (payload != null) {
        var amount = parseFloat(payload.amount).toFixed(2);

        state.subscriptionPlan.stripePlan =
          payload.stripe_plan || state.subscriptionPlan.stripePlan;
        state.subscriptionPlan.interval =
          payload.interval || state.subscriptionPlan.interval;
        state.subscriptionPlan.currency =
          payload.currency.toUpperCase() || state.subscriptionPlan.currency;
        state.subscriptionPlan.amount = amount || state.subscriptionPlan.amount;
        state.subscriptionPlan.productName =
          payload.product_name || state.subscriptionPlan.productName;
        state.subscriptionPlan.currentPeriodEnd =
          payload.current_period_end || state.subscriptionPlan.currentPeriodEnd;
        if (payload.metadata) {
          const feature = JSON.parse(payload.metadata);
          state.subscriptionPlan.metadata = feature;
        }
      }
    },
    setTransactionHistory(state, action) {
      const { payload } = action;
      const tempData = [];
      payload.data.forEach((element) => {
        const order = {
          dateDisplay: element.date_display || "",
          date: element.date || "",
          transactions: element.transactions || ""
        };
        tempData.push(order);
      });

      state.transactionHistory.totalRecords = payload.recordsTotal;
      state.transactionHistory.filteredTotalRecords = payload.recordsFiltered;
      state.transactionHistory.data = tempData;
    },
    setTransactionLatest(state, action) {
      const { payload } = action;
      const tempData = [];

      payload.forEach((element) => {
        const order = {
          dateDisplay: element.date_display || "",
          date: element.date || "",
          transactions: element.transactions || ""
        };
        tempData.push(order);
      });
      state.transactionLatest.data = tempData;
    },
  },
  extraReducers: {
    [fetchQuickSubscriptionSummary.fulfilled]: (state, action) => {
      const summary = action.payload.data;
      state.credits = summary.serial_number_credits;
      state.smsCredit = summary.sms_credits;
    }
  }
});

export const billingReducer = billingSlice.reducer;
export const {
  setCredit,
  setBillingDetail,
  setSubcriptionPlan,
  setTransactionHistory,
  setTransactionLatest
} = billingSlice.actions;
