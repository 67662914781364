import React, { useEffect, useState } from "react";
import SurveyComponent from "./survey.component";
import { useDispatch, useSelector } from "react-redux";
import { getSurvey } from "modules/survey/redux/action";
import { unwrapResult } from "@reduxjs/toolkit";

export default function SurveyContainer({ id }) {
  const dispatch = useDispatch();
  const { isVisible, content } = useSelector(
    (state) => state.productPage.components[id]
  );

  const lang = useSelector((state) => state.constant.languages);
  const uiSetting = useSelector((state) => state.setting.uiSetting);

  const [survey, setSurvey] = useState(null);
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    if (content.id) {
      setIsFetching(true);
      dispatch(getSurvey({ id: content.id }))
        .then(unwrapResult)
        .then((res) => {
          if (res) {
            setSurvey(res);
            setIsFetching(false);
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);

  return (
    isVisible && (
      <SurveyComponent
        lang={lang}
        survey={survey}
        isFetching={isFetching}
        uiSetting={uiSetting}
      />
    )
  );
}
