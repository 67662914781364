import React from "react";
import InstagramComponent from "./instagram.component";
import Skeleton from "@material-ui/lab/Skeleton";
import { useSelector } from "react-redux";
import { isInstagramUrlValid } from "../../../utils/productPage.util";

function SkeletonFrame() {
  return (
    <div
      style={{
        backgroundColor: "white",
        border: "1px solid lightgrey",
        borderRadius: 10,
        marginBottom: 16,
        minWidth: "max-content"
      }}
    >
      <div
        style={{ padding: "8px 16px", display: "flex", alignItems: "center" }}
      >
        <Skeleton variant="circle" animation={false} width={35} height={35} />
        <Skeleton
          variant="text"
          animation={false}
          width={50}
          style={{ marginLeft: 10 }}
        />
      </div>
      <Skeleton variant="rect" height={130} animation={false} />
      <div style={{ padding: "8px 16px" }}>
        <Skeleton variant="text" animation={false} width={70} />
        <Skeleton variant="text" animation={false} />
        <Skeleton variant="text" animation={false} />
      </div>
    </div>
  );
}

export default function InstagramContainer({ id }) {
  const {
    isVisible,
    content: { URL }
  } = useSelector(state => state.productPage.components[id]);

  if (!isInstagramUrlValid(URL)) {
    return <SkeletonFrame />;
  }

  function trimURL(rawUrl) {
    let postId = rawUrl.split("instagram.com/p/")[1];
    postId = postId.split("/")[0];
    return postId;
  }

  const path = `https://www.instagram.com/p/${trimURL(URL)}/embed`;

  return (
    isVisible && <InstagramComponent path={path} />
  );
}
