import axios from "axios"

export default async function getScanTrackDetail(id) {
    return new Promise((resolve, reject) => {
        axios
        .get(`/consumer/admin/api/v1/scan/${id}/detail`)
        .then((response) => resolve(response.data))
        .catch((error) => {
            return reject(error.response.data)
        })
    })
}
