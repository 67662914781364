import { createAsyncThunk } from "@reduxjs/toolkit";
import stockflowApi from "app/api/stockflow";

export const STOCKFLOWCOURIER = "stockflowCourier";

export const detectCourier = createAsyncThunk(
  `${STOCKFLOWCOURIER}/detectCourier`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi
      .detectCourier(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);
