import { createSlice } from "@reduxjs/toolkit";

const BACKDROP = "backdrop";

const initialState = {
  show: false
};

const backdropSlice = createSlice({
  name: BACKDROP,
  initialState,
  reducers: {
    showBackdrop(state, action) {
      state.show = true;
    },
    hideBackdrop(state, action) {
      state.show = false;
    }
  }
});

export const { showBackdrop, hideBackdrop } = backdropSlice.actions;
export const { reducer } = backdropSlice;
