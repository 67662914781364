import React from "react";
import {
  makeStyles,
  Paper,
  Typography,
  IconButton,
  Divider,
  DialogContent,
  Dialog,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { getLang } from "app/feature/constants";
import AdtInfoImportLogTable from "../../table/adtInfoImportLogTable";

const useStyle = makeStyles((theme) => ({
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    borderCollapse: "separate",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
    },
    "& th,td": {
      fontSize: ".75rem",
      borderRight: "1px solid rgba(58, 77, 84, 0.1)",
      padding: "10px 16px 10px 16px",
      backgroundColor: "white",
    },
    "& th": {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightBold,
      backgroundColor: "#F4F7F8",
    },
  },
  root: {
    borderRadius: 8,
    width: 750,
    maxWidth: "95vw",
    boxShadow:
      "0px 20px 24px -4px rgba(16, 24, 40, 0.1), 0px 8px 8px -4px rgba(16, 24, 40, 0.04)",
    [theme.breakpoints.down("xs")]: {
      minWidth: "95vw",
    },
  },
  header: {
    paddingLeft: theme.spacing(3),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  dropzone: {
    border: "1px solid grey",
    borderStyle: "dashed",
    padding: "32px 16px",
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    flexDirection: "column",
    alignItems: "center",
    "&:focus": {
      border: "1px solid blue",
      borderStyle: "dashed",
    },
  },
  languageContainer: {
    border: "1px solid #D0D5DD",
    padding: 8,
    borderRadius: 4,
  },
  chip: {
    padding: "0px 4px",
    backgroundColor: "#ececec",
    marginRight: "0.25em",
    marginBottom: 4,
    marginTop: 4,
    border: "none",
    boxShadow:
      "0px 3px 1px -2px rgba(0,0,0,0.1), 0px 2px 2px 0px rgba(0,0,0,0.04), 0px 1px 5px 0px rgba(0,0,0,0.02)",
  },
  caption: {
    color: "#98A2B3",
  },
  form: {
    backgroundColor: "#F2F4F7",
    maxHeight: "80vh",
    overflowY: "auto",
    padding: 16,
  },
  greenLabel: {
    color: "#6AAF68",
  },
  errorCell: {
    color: "#ff0000",
    fontWeight: 400,
    border: "1px solid #ff0000 !important",
    backgroundColor: "#ff00000d !important",
  },
  completeIndicator: {
    color: "#32D583",
    fontSize: 20,
    marginLeft: 8,
  },
}));

export default function ImportLogDialogComponent({
  logs,
  isOpen,
  lang,
  isFetching,
  isError,
  handleChangePage,
  handleChangeRowsPerPage,
  totalFiltered,
  handleReload,
  paginationOptions,
  tableFilter,
  changeOrder,
  handleClose,
}) {
  const classes = useStyle();

  return (
    <Dialog
      open={isOpen}
      disableBackdropClick
      disableEscapeKeyDown
      aria-labelledby='="general-dialog'
      // maxWidth="md"
      classes={{
        paper: classes.root,
      }}
      // fullWidth
    >
      <Paper elevation={0}>
        <div className={classes.header}>
          <Typography variant="subtitle2" style={{ fontWeight: "bold" }}>
            {getLang(lang, "label.ADDITIONAL_INFO_UPDATE_LOGS")}
          </Typography>
          <IconButton color="primary" onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </div>
        <Divider />
        <DialogContent className={classes.form}>
          <AdtInfoImportLogTable
            rows={logs}
            isFetching={isFetching}
            isError={isError}
            handleReload={handleReload}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            totalFiltered={totalFiltered}
            paginationOptions={paginationOptions}
            tableFilter={tableFilter}
            changeOrder={changeOrder}
            lang={lang}
          />
        </DialogContent>
      </Paper>
    </Dialog>
  );
}
