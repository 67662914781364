import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ROOT } from "../../config/product-mgmt.route";
import ProductPage from "./newproduct.page";
import { ProductInfoSvg, EditPageSvg } from "../../utils/staticSvg";
import ProductInfoForm from "../../components/form/productInfoForm";
import EditPagePanel from "../../components/panel/editPagePanel";
import {
  resetProductPage,
  setEditorMode,
} from "../../redux/slice/productPage.slice";
import { getLang } from "app/feature/constants";

function getSteps({ lang }) {
  const ProductInfoSvgWhite = () => (
    <ProductInfoSvg color="rgba(58, 77, 84, 0.33)" />
  );

  const EditPageSvgWhite = () => <EditPageSvg color="rgba(58, 77, 84, 0.33)" />;

  let steps = [
    {
      form: ProductInfoForm,
      labelImage: ProductInfoSvgWhite,
      activeImage: ProductInfoSvg,
      label: getLang(lang, "label.PRODUCT_DETAILS"),
    },
    {
      form: EditPagePanel,
      labelImage: EditPageSvgWhite,
      activeImage: EditPageSvg,
      label: getLang(lang, "label.PRODUCT_PAGE"),
    },
  ];
  return steps;
}

function ProductContainer({ resetProductPage, lastStep, resetPageEditor }) {
  const lang = useSelector((state) => state.constant.languages);
  const steps = getSteps({ lang });

  const history = useHistory();
  const [activeStep, setActiveStep] = useState(lastStep || 0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleDiscard = () => {
    resetProductPage();
    history.push(ROOT);
  };

  useEffect(() => {
    resetPageEditor();
  }, [resetPageEditor]);

  return (
    <ProductPage
      steps={steps}
      activeStep={activeStep}
      handleBack={handleBack}
      handleNext={handleNext}
      handleDiscard={handleDiscard}
      lang={lang}
    />
  );
}

const mapDispatchToProps = (dispatch) => ({
  resetProductPage: () => dispatch(resetProductPage()),
  resetPageEditor: () => {
    dispatch(setEditorMode(false));
  },
});

const mapStateToProps = (state) => ({
  lastStep: state.productPage.lastNewProductStep,
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductContainer);
