import React from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";
import { getLang } from "app/feature/constants";
import ProductIcon from "assets/img/productIcon.png";
import RefreshTable from "components/table/refreshTable";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  icon: {
    height: 45,
    width: 45,
  },
  panel: {
    borderRadius: 12,
  },
  header: {
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    padding: "8px 20px",
    background:
      "linear-gradient(180deg, rgba(255, 190, 113, 0.22) -1.73%, rgba(255, 255, 255, 0.22) 75.66%)",
  },
  productIcon: {
    width: "24.83px",
    height: "16.55px",
    boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
  },
  row: {
    display: "grid",
    alignItems: "center",
    gridTemplateColumns: "0.8fr 6fr 1.5fr",
    gridColumnGap: theme.spacing(1),
    borderBottom: "1px solid #D0D5DD",
    "&:last-child": {
      borderBottom: "none",
    },
  },
  progressBar: {
    width: "100%",
    position: "relative",
    borderRadius: 50,
    overflow: "hidden",
    background: "#E4E7EC",
    height: 18,
    display: "flex",
    alignItems: "center",
    "& > div": {
      display: "flex",
      alignItems: "center",
    },
  },
  positive: {
    background: "#32D583",
    height: "100%",
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  negative: {
    background: "#F97066",
    height: "100%",
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  productImg: {
    height: 30,
    width: 30,
    borderRadius: "50%",
    objectFit: "cover",
  },
  attributeText: {
    color: "#b2b2b2",
    fontSize: 12,
    whiteSpace: "break-spaces",
    marginBottom: 4,
  },
}));

export default function TopScanProductsPanelComponent({
  handleReload,
  lang,
  data,
  isError,
  loading,
}) {
  const classes = useStyles();

  return (
    <Box className={classes.panel}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        className={classes.header}
      >
        <Box mr={1}>
          <Typography variant="body1" display="inline">
            <b>{getLang(lang, "label.TOP_SCAN_PRODUCTS")}</b>&nbsp;
          </Typography>
          <Typography
            variant="body1"
            display="inline"
            style={{ color: "#98A2B3" }}
          >
            <b>({data.length.toLocaleString("en-US") || 0})</b>
          </Typography>
        </Box>
        <img src={ProductIcon} alt="product" className={classes.icon} />
      </Box>
      <Box px={2} pb={2}>
        {isError ? (
          <RefreshTable handleReload={handleReload} />
        ) : loading ? (
          <>
            <Skeleton variant="text" animation="wave" height={35} />
            <Skeleton variant="text" animation="wave" height={35} />
            <Skeleton variant="text" animation="wave" height={35} />
            <Skeleton variant="text" animation="wave" height={35} />
          </>
        ) : data?.length > 0 ? (
          data.map((d, index) => (
            <Box key={index} className={classes.row} pb={1.5} pt={1}>
              <Box>
                <Typography color="textSecondary" variant="body2">
                  <b>{index + 1}</b>
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" flex={1}>
                <img
                  src={d.image}
                  alt="product"
                  className={classes.productImg}
                />
                <Box ml={1.5} flex={1}>
                  <Typography variant="body2">{d.name}</Typography>
                  {!!d.attributes && !!d.attributes.length && (
                    <Typography
                      className={classes.attributeText}
                      variant="body2"
                    >
                      {d.attributes.join(", ")}
                    </Typography>
                  )}
                  <Box className={classes.progressBar}>
                    <Box
                      style={{
                        width: `${
                          (d.total_count / data[0].total_count) * 100
                        }%`,
                      }}
                    >
                      <Box
                        className={classes.positive}
                        style={{
                          width: `${(d.active_count / d.total_count) * 100}%`,
                        }}
                      >
                        {d.active_count > 0 && (
                          <Typography variant="caption">
                            {d.active_count}
                          </Typography>
                        )}
                      </Box>
                      <Box
                        className={classes.negative}
                        style={{
                          width: `${(d.suspend_count / d.total_count) * 100}%`,
                        }}
                      >
                        {d.suspend_count > 0 && (
                          <Typography variant="caption">
                            {d.suspend_count}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box>
                <Typography variant="body1" align="right">
                  <b>{(d.total_count || 0).toLocaleString("en-US")}</b>
                </Typography>
              </Box>
            </Box>
          ))
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            height={65}
          >
            <Typography variant="body2" align="center">
              <b>{getLang(lang, "label.NO_DATA_FOUND")}</b>
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}
