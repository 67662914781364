import React, { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectLoading } from "modules/notification";
import TopScanCitiesPanelComponent from "./topScanCitiesPanel.component";
import { getTopScanCities } from "modules/dashboard/redux/action/dashboard.action";
import moment from "moment";

function TopScanCitiesPanelContainer({ dateRange }) {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.constant.languages);
  const topScanCities = useSelector(
    (state) => state.dashboard.topScanCities.data
  );
  const isError = useSelector((state) => state.dashboard.topScanCities.isError);
  const isFetching = useSelector((state) =>
    selectLoading(state, getTopScanCities.typePrefix)
  );

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange]);

  const getData = () => {
    let param = {
      start_date: moment(dateRange[0]).format("YYYY-MM-DD"),
      end_date: moment(dateRange[1]).format("YYYY-MM-DD"),
    };
    dispatch(getTopScanCities(param));
  };

  return (
    <TopScanCitiesPanelComponent
      handleReload={getData}
      lang={lang}
      data={topScanCities}
      isError={isError}
      loading={isFetching}
    />
  );
}

export default memo(TopScanCitiesPanelContainer);
