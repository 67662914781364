import axios from "axios";

const get = ({...parameters}) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/consumer/admin/api/v1/${parameters.id}/warranties`, {
        params: {
          draw: parameters.draw,
          length: parameters.length,
          start: parameters.start,
          column: parameters.column,
          order: parameters.order,
          search: parameters.search
        }
      })
      .then((response) => resolve(response))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

const getWarranteeList = {
  get
};

export default getWarranteeList;
