import React, { useState } from "react";
import AddPointFormComponent from "./addPointForm.component";
import { setType } from "modules/loyalty-program/redux";
import { useDispatch, useSelector } from "react-redux";
import {
  addLoyaltyPoint,
  editLoyaltyPoint,
} from "modules/loyalty-program/redux";
import { unwrapResult } from "@reduxjs/toolkit";
import ConfirmationDialogComponent from "components/dialog/confirmation";
import { hideBackdrop, showBackdrop } from "modules/backdrop";
import { loyaltyType } from 'modules/loyalty-program/constants'
import { LOYALTY_PROGRAM_ADD, LOYALTY_PROGRAM_EDIT } from "lib/constants/accessRights";
import { getLang } from "app/feature/constants";

export default function AddPointFormContainer({ handleClose, headerType }) {
  const dispatch = useDispatch();
  const hasLoyaltyProgramAddAccessRight = useSelector(state => state.profile.accessRights.includes(LOYALTY_PROGRAM_ADD));
  const hasLoyaltyProgramEditAccessRight = useSelector(state => state.profile.accessRights.includes(LOYALTY_PROGRAM_EDIT));
  const [confirmationDialogModal, setConfirmationDialogModal] = useState(false);
  const loyaltyPointType = useSelector(
    (state) => state.loyaltyProgram.loyaltyPointType
  );
  const loyaltyPoint = useSelector(
    (state) => state.loyaltyProgram.loyaltyPoint
  );
  const lang = useSelector(state => state.constant.languages)

  const changeToUpdate = (e) => {
    if(hasLoyaltyProgramEditAccessRight){
      e.preventDefault();
      const updateInfo = {
        index: loyaltyType.point,
        type: "edit",
      };
      dispatch(setType(updateInfo));
    }
  };

  const changeBackView = () => {
    const updateInfo = {
      index: loyaltyType.point,
      type: "view",
    };
    dispatch(setType(updateInfo));
  };

  const handleSubmit = async (values) => {
    if (loyaltyPointType === "create") {
      if(hasLoyaltyProgramAddAccessRight){
        const modulesId = [];
        for (const modules of values.modules) {
          modulesId.push(modules.id);
        }
        const info = {
          modules: modulesId,
          point: values.point,
          status: "active",
        };
        await dispatch(addLoyaltyPoint(info))
          .then(unwrapResult)
          .then((r) => {
            dispatch(hideBackdrop());
            setConfirmationDialogModal(true);
          })
          .catch((error) => {
            dispatch(hideBackdrop());
          });
      }
    } else {
      if(hasLoyaltyProgramEditAccessRight){
        dispatch(showBackdrop());
        const modulesId = [];
        for (const modules of values.modules) {
          modulesId.push(modules.id);
        }
        const info = {
          modules: modulesId,
          point: values.point,
          id: values.id,
          status: "active",
        };
        await dispatch(editLoyaltyPoint(info))
          .then(unwrapResult)
          .then((r) => {
            dispatch(hideBackdrop());
            changeBackView();
          })
          .catch((error) => {
            dispatch(hideBackdrop());
          });
      }
    }
  };

  return (
    <>
      <AddPointFormComponent
        hasLoyaltyProgramAddAccessRight={hasLoyaltyProgramAddAccessRight}
        hasLoyaltyProgramEditAccessRight={hasLoyaltyProgramEditAccessRight}
        headerType={headerType}
        loyaltyPoint={loyaltyPoint}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        loyaltyPointType={loyaltyPointType}
        changeToUpdate={changeToUpdate}
        lang={lang}
        changeBackView={changeBackView}
      />
      <ConfirmationDialogComponent
        isOpen={confirmationDialogModal}
        handleProceed={handleClose}
        type="success"
        title={getLang(lang,"label.POINT_ADDED_SUCCESSFULLY")}
        rejectOption={false}
        proceedLabel={getLang(lang,"label.ALRIGHT")}
      />
    </>
  );
}
