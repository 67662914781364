import React, { useEffect, useState } from "react";
import LoginPage from "./login.page";
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { additionalJoin, validateInvitationCode, setInvitationCode } from "modules/trackco/redux"
import { selectLoading } from 'modules/notification';

function LoginContainer() {
  const match = useRouteMatch();
  const invitationCode = match.params.invitationCode;
  const history = useHistory();
  const dispatch = useDispatch();

  const distributor = useSelector(state => state.trackco.inviter.distributor);
  const branch = useSelector(state => state.trackco.inviter.branch);
  const lang = useSelector(state => state.constant.languages);
  const isInvitationValid = useSelector(state => state.trackco.inviter.isInvitationValid)
  const isValidateInvitationLoading = useSelector(state => selectLoading(state, validateInvitationCode.typePrefix))

  const [isPasswordShown, setIsPasswordShown] = useState(false);

  useEffect(() => {
    document.title = "Login - Trackco @ Customer Portal";
    
    if (!isInvitationValid) {
      dispatch(validateInvitationCode(invitationCode))
        .then(response => {
          if (!response.payload.data.is_valid) {
            history.push("/404")
          } else {
            dispatch(setInvitationCode({ invitationCode: invitationCode }))
          }
        })
    }
  }, [invitationCode, dispatch, isInvitationValid, history]);

  const handleClickShowPassword = () => {
    setIsPasswordShown(prev => !prev)
  }
  
  const handleMouseDownPassword = event => {
    event.preventDefault()
  }

  const handleAdditionalJoin = async (values) => {
    const payload = {
      invitationCode: invitationCode,
      email: values.email,
      password: values.password,
    }

    dispatch(additionalJoin(payload))
    .then(() => {
      history.push("/trackco/login/success")
    })
  }

  return (
    <LoginPage
      invitationCode={invitationCode}
      distributorDetail={distributor}
      branchDetail={branch}
      lang={lang}
      isValidateInvitationLoading={isValidateInvitationLoading}
      isInvitationCodeValidated={isInvitationValid}
      handleSubmit={handleAdditionalJoin}
      handleClickShowPassword={handleClickShowPassword}
      handleMouseDownPassword={handleMouseDownPassword}
      isPasswordShown={isPasswordShown}
    />
  )
}

export default LoginContainer;