import {
  updateAccount,
  updateAccountProfilePicture,
  updateAccountProfilePictureApi,
  getAvailableCurrencyConfigListing,
  getUnusedCurrencyConfigData,
  updateCurrencyConfigStatus,
  updateDefaultCurrency,
  deleteCurrencyConfig,
  addCurrencyConfig,
  bulkUpdateCurrencyStatus,
  checkFeatureAvailability,
  getCompanyDisbursement,
} from "../action";
import { uploadImageToS3 } from "app/feature";
import { setLoading, addAlert } from "modules/notification";
import { setAccount, setAccountProfilePicture } from "../slice";
import { FolderType } from "lib/constants/aws_s3";
import { getApiLang } from "app/feature/constants";

export const updateAccountMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages;

    switch (type) {
      case updateAccount.pending.type:
        // dispatch set loading to notification reducer
        dispatch(setLoading({ id: updateAccount.typePrefix, state: true }));
        break;

      case updateAccount.fulfilled.type:
        // dispatch remove loading from notification reducer
        next(setAccount(action.meta.arg));
        dispatch(setLoading({ id: updateAccount.typePrefix, state: false }));
        dispatch(
          addAlert({
            severity: "success",
            message: getApiLang(lang, "success.SUCCESS_UPDATE_ACCOUNT_DATA"),
          })
        );
        break;

      case updateAccount.rejected.type:
        dispatch(setLoading({ id: updateAccount.typePrefix, state: false }));
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

const updateAccountProfilePictureMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    const { type } = action;
    const uploadType = FolderType.logo;

    if (type === updateAccountProfilePicture.type) {
      const loadingId = updateAccountProfilePicture.type;
      const { fileName, base64Image } = action.payload;
      const companyId = getState().account.id;
      // continue the action
      next({ ...action, payload: { fileName, base64Image, companyId } });
      // set loading to indicate uploading is in process
      dispatch(setLoading({ id: loadingId, state: true }));
      // dispatch action to start uploading image to s3
      dispatch(
        uploadImageToS3({
          fileName,
          base64Image,
          id: companyId,
          uploadType: uploadType,
        })
      );
    } else if (
      type.includes(uploadImageToS3.typePrefix) &&
      action.meta.arg.uploadType === uploadType
    ) {
      next(action);

      switch (type) {
        case uploadImageToS3.rejected.type:
          dispatch(
            setLoading({ id: updateAccountProfilePicture.type, state: false })
          );
          break;

        case uploadImageToS3.fulfilled.type:
          // update the new url to backend
          const url = `${action.payload.split("?")[0]}`;
          dispatch(updateAccountProfilePictureApi(url));

          break;

        default:
          break;
      }
    } else {
      next(action);
    }
  };

const updateAccountProfilePictureApiMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages;

    switch (type) {
      case updateAccountProfilePictureApi.rejected.type:
        dispatch(
          setLoading({ id: updateAccountProfilePicture.type, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      case updateAccountProfilePictureApi.fulfilled.type:
        next(setAccountProfilePicture(action.meta.arg));
        dispatch(
          setLoading({ id: updateAccountProfilePicture.type, state: false })
        );
        dispatch(
          addAlert({
            severity: "success",
            message: getApiLang(lang, payload.code),
          })
        );

        break;

      default:
        break;
    }
  };

export const getAvailableCurrencyConfigListingMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type } = action;

    switch (type) {
      case getAvailableCurrencyConfigListing.pending.type:
        dispatch(
          setLoading({
            id: getAvailableCurrencyConfigListing.typePrefix,
            state: true,
          })
        );
        break;
      case getAvailableCurrencyConfigListing.fulfilled.type:
        dispatch(
          setLoading({
            id: getAvailableCurrencyConfigListing.typePrefix,
            state: false,
          })
        );
        break;
      case getAvailableCurrencyConfigListing.rejected.type:
        dispatch(
          setLoading({
            id: getAvailableCurrencyConfigListing.typePrefix,
            state: false,
          })
        );
        break;
      default:
        break;
    }
  };

export const getUnusedCurrencyConfigDataMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type } = action;

    switch (type) {
      case getUnusedCurrencyConfigData.pending.type:
        dispatch(
          setLoading({
            id: getUnusedCurrencyConfigData.typePrefix,
            state: true,
          })
        );
        break;
      case getUnusedCurrencyConfigData.fulfilled.type:
        dispatch(
          setLoading({
            id: getUnusedCurrencyConfigData.typePrefix,
            state: false,
          })
        );
        break;
      case getUnusedCurrencyConfigData.rejected.type:
        dispatch(
          setLoading({
            id: getUnusedCurrencyConfigData.typePrefix,
            state: false,
          })
        );
        break;
      default:
        break;
    }
  };

export const updateCurrencyConfigStatusMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type } = action;

    switch (type) {
      case updateCurrencyConfigStatus.pending.type:
        dispatch(
          setLoading({ id: updateCurrencyConfigStatus.typePrefix, state: true })
        );
        break;
      case updateCurrencyConfigStatus.fulfilled.type:
        dispatch(
          setLoading({
            id: updateCurrencyConfigStatus.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "success",
            message: "Currency status successfully updated.",
          })
        );
        break;
      case updateCurrencyConfigStatus.rejected.type:
        dispatch(
          setLoading({
            id: updateCurrencyConfigStatus.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message:
              "Error in updating the currency status. Please try again later. ",
          })
        );
        break;
      default:
        break;
    }
  };

export const updateDefaultCurrencyConfigMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type } = action;

    switch (type) {
      case updateDefaultCurrency.pending.type:
        dispatch(
          setLoading({ id: updateDefaultCurrency.typePrefix, state: true })
        );
        break;
      case updateDefaultCurrency.fulfilled.type:
        dispatch(
          setLoading({ id: updateDefaultCurrency.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "success",
            message: "Default currency successfully updated.",
          })
        );
        break;
      case updateDefaultCurrency.rejected.type:
        dispatch(
          setLoading({ id: updateDefaultCurrency.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message:
              "Error in updating the default currency. Please try again later. ",
          })
        );
        break;
      default:
        break;
    }
  };

export const bulkUpdateCurrencyStatusMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type } = action;

    switch (type) {
      case bulkUpdateCurrencyStatus.pending.type:
        dispatch(
          setLoading({ id: bulkUpdateCurrencyStatus.typePrefix, state: true })
        );
        break;
      case bulkUpdateCurrencyStatus.fulfilled.type:
        dispatch(
          setLoading({ id: bulkUpdateCurrencyStatus.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "success",
            message: "Currency/currencies status successfully updated.",
          })
        );
        break;
      case bulkUpdateCurrencyStatus.rejected.type:
        dispatch(
          setLoading({ id: bulkUpdateCurrencyStatus.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message:
              "Error in updating the currency/currencies status. Please try again later. ",
          })
        );
        break;
      default:
        break;
    }
  };

export const deleteCurrencyConfigMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type } = action;

    switch (type) {
      case deleteCurrencyConfig.pending.type:
        dispatch(
          setLoading({ id: deleteCurrencyConfig.typePrefix, state: true })
        );
        break;
      case deleteCurrencyConfig.fulfilled.type:
        dispatch(
          setLoading({ id: deleteCurrencyConfig.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "success",
            message: "Currency successfully deleted.",
          })
        );
        break;
      case deleteCurrencyConfig.rejected.type:
        dispatch(
          setLoading({ id: deleteCurrencyConfig.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: "Error in deleting the currency. Please try again later. ",
          })
        );
        break;
      default:
        break;
    }
  };

export const addCurrencyConfigMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type } = action;
    const lang = getState().constant.languages;
    switch (type) {
      case addCurrencyConfig.pending.type:
        dispatch(setLoading({ id: addCurrencyConfig.typePrefix, state: true }));
        break;
      case addCurrencyConfig.fulfilled.type:
        dispatch(
          setLoading({ id: addCurrencyConfig.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "success",
            message: getApiLang(lang, "success.SUCCESS_ADD_NEW_CURRENCY"),
          })
        );
        break;
      case addCurrencyConfig.rejected.type:
        dispatch(
          setLoading({ id: addCurrencyConfig.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, "error.ERROR_ADD_NEW_CURRENCY_SETTING"),
          })
        );
        break;
      default:
        break;
    }
  };

export const checkFeatureAvailabilityMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages;
    switch (type) {
      case checkFeatureAvailability.pending.type:
        dispatch(
          setLoading({ id: checkFeatureAvailability.typePrefix, state: true })
        );
        break;
      case checkFeatureAvailability.fulfilled.type:
        dispatch(
          setLoading({ id: checkFeatureAvailability.typePrefix, state: false })
        );
        if (payload.canAccess === false)
          dispatch(
            addAlert({
              severity: "warning",
              message: getApiLang(lang, "error.ERROR_FEATURE_DISABLED"),
            })
          );
        break;
      case checkFeatureAvailability.rejected.type:
        dispatch(
          setLoading({ id: checkFeatureAvailability.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

export const getCompanyDisbursementMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages;

    switch (type) {
      case getCompanyDisbursement.pending.type:
        // dispatch set loading to notification reducer
        dispatch(
          setLoading({ id: getCompanyDisbursement.typePrefix, state: true })
        );
        break;

      case getCompanyDisbursement.fulfilled.type:
        // dispatch remove loading from notification reducer
        dispatch(
          setLoading({ id: getCompanyDisbursement.typePrefix, state: false })
        );
        break;

      case getCompanyDisbursement.rejected.type:
        dispatch(
          setLoading({ id: getCompanyDisbursement.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

export default [
  updateAccountMiddleware,
  updateAccountProfilePictureMiddleware,
  updateAccountProfilePictureApiMiddleware,
  getAvailableCurrencyConfigListingMiddleware,
  getUnusedCurrencyConfigDataMiddleware,
  updateCurrencyConfigStatusMiddleware,
  updateDefaultCurrencyConfigMiddleware,
  deleteCurrencyConfigMiddleware,
  addCurrencyConfigMiddleware,
  bulkUpdateCurrencyStatusMiddleware,
  checkFeatureAvailabilityMiddleware,
  getCompanyDisbursementMiddleware,
];
