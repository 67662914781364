import React from "react";
import CurrencyConfigListTable from './currencyConfigListTable.component';

export default function CurrencyConfigListTableContainer({
  data,
  isFetching,
  isLoadingCurrencyConfigListError,
  isSelectAll,
  search,
  handleReload,
  page,
  rowsPerPage,
  paginationOptions,
  totalRecords,
  handleChangePage,
  handleChangeRowsPerPage,
  handleCurrencyConfigStatus,
  handleUpdateCurrencyConfig,
  name,
  isoCode,
  status,
  updateStatus,
  defaultCurrency,
  handleSetDefaultCurrency,
  handleDeleteCurrencyConfig,
  handleSelectCurrencyConfig,
  handleSelectAvailable,
  handleSelectAll,
  handleDeselectAll,
  handleSearch,
  handleCreateCurrencyConfig,
  lang,
  hasCompanyCurrencyEditAccessRight
}) {
  return (
    <CurrencyConfigListTable 
      isSelectAll={isSelectAll} 
      data={data} 
      isFetching={isFetching}
      isLoadingCurrencyConfigListError={isLoadingCurrencyConfigListError}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      handleReload={handleReload}
      page={page}
      rowsPerPage={rowsPerPage}
      paginationOptions={paginationOptions}
      search={search}
      totalRecords={totalRecords}
      handleCurrencyConfigStatus={handleCurrencyConfigStatus}
      handleUpdateCurrencyConfig={handleUpdateCurrencyConfig}
      name={name}
      isoCode={isoCode}
      status={status}
      updateStatus={updateStatus}
      defaultCurrency={defaultCurrency}
      handleSetDefaultCurrency={handleSetDefaultCurrency}
      handleDeleteCurrencyConfig={handleDeleteCurrencyConfig}
      handleSelectCurrencyConfig={handleSelectCurrencyConfig}
      handleSelectAvailable={handleSelectAvailable}
      handleSelectAll={handleSelectAll}
      handleDeselectAll={handleDeselectAll}
      handleSearch={handleSearch}
      handleCreateCurrencyConfig={handleCreateCurrencyConfig}
      lang={lang}
      hasCompanyCurrencyEditAccessRight={hasCompanyCurrencyEditAccessRight}
    />
  )
}