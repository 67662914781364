import { getApiLang } from "app/feature/constants";
import { setLoading, addAlert } from "modules/notification";
import { fetchAllScanTrack, fetchConsumerListing, fetchConsumerProfile, fetchConsumerScanHistoryListing, getCampaignProductDropdown, getConsumerCampaignListing, getConsumerGiftRedemptionListing, getConsumerLoyaltyPointHistory, getGiftDropdown } from "../action/consumer.action";


export const fetchConsumerListingMiddleware = ({ dispatch, getState }) => (next) => (action) => {
    next(action);

    const { payload, type } = action;

    switch (type) {
        case fetchConsumerListing.pending.type:
            dispatch(setLoading({ id: fetchConsumerListing.typePrefix, state: true }));
            break;

        case fetchConsumerListing.fulfilled.type:
            dispatch(setLoading({ id: fetchConsumerListing.typePrefix, state: false }));
            break;

        case fetchConsumerListing.rejected.type:
            const lang = getState().constant.languages
            dispatch(setLoading({ id: fetchConsumerListing.typePrefix, state: false }));
            dispatch(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
            break;

        default:
            break;
    }
};

export const fetchConsumerProfileMiddleware = ({ dispatch, getState }) => (next) => (action) => {
    next(action);

    const { payload, type } = action;

    switch (type) {
        case fetchConsumerProfile.pending.type:
            dispatch(setLoading({ id: fetchConsumerProfile.typePrefix, state: true }));
            break;

        case fetchConsumerProfile.fulfilled.type:
            dispatch(setLoading({ id: fetchConsumerProfile.typePrefix, state: false }));
            break;

        case fetchConsumerProfile.rejected.type:
            const lang = getState().constant.languages
            dispatch(setLoading({ id: fetchConsumerProfile.typePrefix, state: false }));
            dispatch(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
            break;

        default:
            break;
    }
};

export const fetchConsumerScanHistoryListingMiddleware = ({ dispatch, getState }) => (next) => (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
        case fetchConsumerScanHistoryListing.pending.type:
            dispatch(setLoading({ id: fetchConsumerScanHistoryListing.typePrefix, state: true }));
            break;

        case fetchConsumerScanHistoryListing.fulfilled.type:
            dispatch(setLoading({ id: fetchConsumerScanHistoryListing.typePrefix, state: false }));
            break;

        case fetchConsumerScanHistoryListing.rejected.type:
            const lang = getState().constant.languages
            dispatch(setLoading({ id: fetchConsumerScanHistoryListing.typePrefix, state: false }));
            dispatch(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
            break;

        default:
            break;
    }
};

export const fetchAllScanTrackMiddleware = ({ dispatch, getState }) => (next) => (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
        case fetchAllScanTrack.pending.type:
            dispatch(setLoading({ id: fetchAllScanTrack.typePrefix, state: true }));
            break;

        case fetchAllScanTrack.fulfilled.type:
            dispatch(setLoading({ id: fetchAllScanTrack.typePrefix, state: false }));
            break;

        case fetchAllScanTrack.rejected.type:
            const lang = getState().constant.languages
            dispatch(setLoading({ id: fetchAllScanTrack.typePrefix, state: false }));
            dispatch(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
            break;

        default:
            break;
    }
};

export const getConsumerGiftRedemptionListingMiddleware = ({ dispatch, getState }) => (next) => (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
        case getConsumerGiftRedemptionListing.pending.type:
            dispatch(setLoading({ id: getConsumerGiftRedemptionListing.typePrefix, state: true }));
            break;

        case getConsumerGiftRedemptionListing.fulfilled.type:
            dispatch(setLoading({ id: getConsumerGiftRedemptionListing.typePrefix, state: false }));
            break;

        case getConsumerGiftRedemptionListing.rejected.type:
            const lang = getState().constant.languages
            dispatch(setLoading({ id: getConsumerGiftRedemptionListing.typePrefix, state: false }));
            dispatch(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
            break;

        default:
            break;
    }
};

export const getGiftDropdownMiddleware = ({ dispatch, getState }) => (next) => (action) => {
    next(action);

    const { payload, type } = action;

    switch (type) {
        case getGiftDropdown.pending.type:
            dispatch(setLoading({ id: getGiftDropdown.typePrefix, state: true }));
            break;

        case getGiftDropdown.fulfilled.type:
            dispatch(setLoading({ id: getGiftDropdown.typePrefix, state: false }));
            break;

        case getGiftDropdown.rejected.type:
            const lang = getState().constant.languages
            dispatch(setLoading({ id: getGiftDropdown.typePrefix, state: false }));
            dispatch(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
            break;

        default:
            break;
    }
};

export const getConsumerLoyaltyPointHistoryMiddleware = ({ dispatch, getState }) => (next) => (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
        case getConsumerLoyaltyPointHistory.pending.type:
            dispatch(setLoading({ id: getConsumerLoyaltyPointHistory.typePrefix, state: true }));
            break;

        case getConsumerLoyaltyPointHistory.fulfilled.type:
            dispatch(setLoading({ id: getConsumerLoyaltyPointHistory.typePrefix, state: false }));
            break;

        case getConsumerLoyaltyPointHistory.rejected.type:
            const lang = getState().constant.languages
            dispatch(setLoading({ id: getConsumerLoyaltyPointHistory.typePrefix, state: false }));
            dispatch(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
            break;

        default:
            break;
    }
};


export const getConsumerCampaignListingMiddleware = ({ dispatch, getState }) => (next) => (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
        case getConsumerCampaignListing.pending.type:
            dispatch(setLoading({ id: getConsumerCampaignListing.typePrefix, state: true }));
            break;

        case getConsumerCampaignListing.fulfilled.type:
            dispatch(setLoading({ id: getConsumerCampaignListing.typePrefix, state: false }));
            break;

        case getConsumerCampaignListing.rejected.type:
            const lang = getState().constant.languages
            dispatch(setLoading({ id: getConsumerCampaignListing.typePrefix, state: false }));
            dispatch(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
            break;

        default:
            break;
    }
};

export const getCampaignProductDropdownMiddleware = ({ dispatch, getState }) => (next) => (action) => {
    next(action);

    const { payload, type } = action;

    switch (type) {
        case getCampaignProductDropdown.pending.type:
            dispatch(setLoading({ id: getCampaignProductDropdown.typePrefix, state: true }));
            break;

        case getCampaignProductDropdown.fulfilled.type:
            dispatch(setLoading({ id: getCampaignProductDropdown.typePrefix, state: false }));
            break;

        case getCampaignProductDropdown.rejected.type:
            const lang = getState().constant.languages
            dispatch(setLoading({ id: getCampaignProductDropdown.typePrefix, state: false }));
            dispatch(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
            break;

        default:
            break;
    }
};

export default [
    fetchConsumerListingMiddleware,
    fetchConsumerProfileMiddleware,
    fetchConsumerScanHistoryListingMiddleware,
    fetchAllScanTrackMiddleware,
    getConsumerGiftRedemptionListingMiddleware,
    getGiftDropdownMiddleware,
    getConsumerLoyaltyPointHistoryMiddleware,
    getConsumerCampaignListingMiddleware,
    getCampaignProductDropdownMiddleware
];
