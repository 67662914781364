import { Grid } from "@material-ui/core";
import { responseHandler } from "modules/reporting/utils/helper";
import React from "react";
import {
    DownloadChartCard
} from "../../card";
import EmptyChart from "../../skeleton/global/empty-chart";
import { ChartCardLoading } from "../../skeleton/loading";


export default function AgeGenderPanelComponent({
    status,
    data,
    translate
}){
    return (
        <Grid container spacing={3} alignItems="stretch" style={{marginTop:"12px"}}>
            <Grid item xs={12} sm={6}>
                {
                    responseHandler(
                        data.demographicAgeData,
                        status.isFetchingDemographicAgeData,
                        <DownloadChartCard translate={translate} type="line" title={translate("label.AGE")} payload={data.demographicAgeData} canSwitchChartType={true} />,
                        <ChartCardLoading />,
                        <DownloadChartCard translate={translate} type="line" title={translate("label.AGE")} payload={data.demographicAgeData} canSwitchChartType={true} />,
                        <DownloadChartCard translate={translate} type="line" title={translate("label.AGE")} payload={data.demographicAgeData} canSwitchChartType={true} />,
                        <EmptyChart
                            data={[translate("label.BELOW_17"), '18-24', '25-34', '45-54', '35-44', '45-54', translate("label.ABOVE_65"), translate("label.UNKNOWN")]}
                            title={translate("label.LOADING_FAILED")}
                            value={translate("paragraph.LOADING_FAILED_DESCRIPTION")}
                            labels= {['10 July', '11 July', '12 July', '13 July']}
                        />
                    )
                }
            </Grid>
            <Grid item xs={12} sm={6}>
                {
                    responseHandler(
                        data.demographicGenderData,
                        status.isFetchingDemographicGenderData,
                        <DownloadChartCard translate={translate} type="line" title={translate("label.GENDER")} payload={data.demographicGenderData} canSwitchChartType={true} />,
                        <ChartCardLoading />,
                        <DownloadChartCard translate={translate} type="line" title={translate("label.GENDER")} payload={data.demographicGenderData} canSwitchChartType={true} />,
                        <DownloadChartCard translate={translate} type="line" title={translate("label.GENDER")} payload={data.demographicGenderData} canSwitchChartType={true} />,
                        <EmptyChart
                            data={[translate("label.BELOW_17"), '18-24', '25-34', '45-54', '35-44', '45-54', translate("label.ABOVE_65"), translate("label.UNKNOWN")]}
                            title={translate("label.LOADING_FAILED")}
                            value={translate("paragraph.LOADING_FAILED_DESCRIPTION")}
                            labels= {['10 July', '11 July', '12 July', '13 July']}
                        />
                    )
                }
            </Grid>
            <Grid item xs={12} sm={12}>
                {
                    responseHandler(
                        data.demographicAgeGenderData,
                        status.isFetchingDemographicAgeGenderData,
                        <DownloadChartCard translate={translate} type="comparison" title={translate("paragraph.TOTAL_COUNT_BY_GENDER_AGE")} payload={data.demographicAgeGenderData} canSwitchChartType={false} />,
                        <ChartCardLoading />,
                        <EmptyChart
                            data={[translate("label.BELOW_17"), '18-24', '25-34', '45-54', '35-44', '45-54', translate("label.ABOVE_65"), translate("label.UNKNOWN")]}
                            title={translate("label.CUSTOMER_NOT_FOUND")}
                            value={translate("paragraph.NO_DATA_ON_SELECTED_FILTER")}
                            labels= {['10 July', '11 July', '12 July', '13 July']}
                        />,
                        <EmptyChart
                            data={[translate("label.BELOW_17"), '18-24', '25-34', '45-54', '35-44', '45-54', translate("label.ABOVE_65"), translate("label.UNKNOWN")]}
                            title={translate("label.CUSTOMER_NOT_FOUND")}
                            value={translate("paragraph.NO_DATA_ON_SELECTED_FILTER")}
                            labels= {['10 July', '11 July', '12 July', '13 July']}
                        />,
                        <EmptyChart
                            data={[translate("label.BELOW_17"), '18-24', '25-34', '45-54', '35-44', '45-54', translate("label.ABOVE_65"), translate("label.UNKNOWN")]}
                            title={translate("label.LOADING_FAILED")}
                            value={translate("paragraph.LOADING_FAILED_DESCRIPTION")}
                            labels= {['10 July', '11 July', '12 July', '13 July']}
                        />
                    )
                }
            </Grid>
        </Grid>
    )
}