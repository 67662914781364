/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import AdtProductListingPanelComponent from "./adtProductListingPanel.page";
import { useDispatch, useSelector } from "react-redux";
import { selectLoading } from "modules/notification";
import {
  getAdtProductListing,
  resetPrdAfterKey,
  updateAdtProductPageSetting,
} from "modules/serial-number/redux";
import { useLocation } from "react-router";
import qs from "query-string";
import moment from "moment";
import { updateUrlQueryParam } from "lib/helper";

function AdtProductListingPanelContainer({
  selectedAdtInfos,
  handleViewDetail,
}) {
  const dispatch = useDispatch();
  const location = useLocation();
  const qsParams = qs.parse(location.search);
  const products = useSelector((state) => state.additionalInfo.products.list);
  const afterKey = useSelector(
    (state) => state.additionalInfo.products.afterKey
  );
  const isError = useSelector((state) => state.additionalInfo.products.isError);
  const isLoading = useSelector((state) =>
    selectLoading(state, getAdtProductListing.typePrefix)
  );
  const prdFilter = useSelector(
    (state) => state.additionalInfo.productPageSetting
  );
  const language = useSelector((state) => state.profile.language);
  const productDropdown = useSelector((state) => state.products.allProducts);
  const lang = useSelector((state) => state.constant.languages);
  const displayLang = useSelector((state) => state.additionalInfo.displayLang);
  const [urlLoaded, setUrlLoaded] = useState(false);

  useEffect(() => {
    const isFilter = !!qsParams.is_filter;
    let filterPrd = sessionStorage.getItem("adt_info_prd_filter");

    let prdTemp = { ...prdFilter };
    if (isFilter && filterPrd) {
      prdTemp = JSON.parse(filterPrd);
      dispatch(updateAdtProductPageSetting(prdTemp));
    }

    if (isFilter) {
      updateUrlQueryParam(null, ["is_filter"]);
    }
    sessionStorage.removeItem("adt_info_prd_filter");

    setUrlLoaded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (urlLoaded && selectedAdtInfos.length) {
      fetchData();
    }
  }, [prdFilter, selectedAdtInfos, urlLoaded]);

  const fetchData = () => {
    let adtInfos = [];
    prdFilter.additionalInfo.forEach((a) => {
      adtInfos.push({
        uuid: a.info.id,
        format: a.info.format,
        search: a.search,
      });
    });

    dispatch(
      getAdtProductListing({
        length: prdFilter.length,
        start: prdFilter.length * prdFilter.start,
        product_uuids: prdFilter.product_uuids,
        additional_info_uuids: selectedAdtInfos
          .map((field) => field.id)
          .join(","),
        productNotAssigned: prdFilter.productNotAssigned,
        additionalInfo: adtInfos,
        afterKey: afterKey ? afterKey[prdFilter.start] : null,
        displayLang: displayLang,
      })
    );
  };

  const handleChangePage = (newPage) => {
    let isNext = newPage > prdFilter.start;
    let newData = {
      ...prdFilter,
      start: newPage,
      isNextPage: isNext,
      previousKey: isNext ? afterKey : prdFilter.previousKey,
    };

    dispatch(updateAdtProductPageSetting(newData));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(resetPrdAfterKey());
    let newData = {
      ...prdFilter,
      start: 0,
      length: event.target.value,
      previousKey: null,
    };
    dispatch(updateAdtProductPageSetting(newData));
  };

  const handleSearch = (values) => {
    dispatch(resetPrdAfterKey());
    let tempItem = values.products.map((item) => item.id);

    let newData = {
      ...prdFilter,
      start: 0,
      product_uuids: tempItem.join(","),
      products: values.products,
      additionalInfo: values.additionalInfo,
      productNotAssigned: values.productNotAssigned,
    };
    dispatch(updateAdtProductPageSetting(newData));
  };

  const clearSearch = () => {
    dispatch(resetPrdAfterKey());
    let newData = {
      ...prdFilter,
      start: 0,
      product_uuids: [],
      products: [],
      additionalInfo: [],
      productNotAssigned: false,
    };

    dispatch(updateAdtProductPageSetting(newData));
  };

  const viewDetail = (selectedDetail) => {
    handleViewDetail("product", "", {
      ...prdFilter,
      productNotAssigned: selectedDetail.product ? false : true,
      product_uuids: selectedDetail.product ? selectedDetail.product.id : "",
      products: selectedDetail.product ? [selectedDetail.product] : [],
      additionalInfo: selectedDetail.additionalFields.map((a) => {
        return {
          info: a,
          search: a.value
            ? a.format === 1
              ? a.value[displayLang] ?? ""
              : a.format === 3
              ? { from: a.value, to: "" }
              : a.format === 3
              ? moment(a.value, "DD MMM YYYY").format("YYYY-MM-DD")
              : a.value
            : "",
        };
      }),
    });
  };

  return (
    <>
      <AdtProductListingPanelComponent
        productList={products}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        isFetching={isLoading}
        handleSearch={handleSearch}
        clearSearch={clearSearch}
        language={language}
        handleReload={fetchData}
        productDropdown={productDropdown}
        lang={lang}
        tableFilter={prdFilter}
        handleViewDetail={viewDetail}
        additionalFields={selectedAdtInfos}
        isError={isError}
        hasNextPage={!!afterKey[prdFilter.start + 1]}
      />
    </>
  );
}

export default AdtProductListingPanelContainer;
