import React from "react"
import ConsumerTableComponent from "./consumerTable.component"
import { useSelector } from "react-redux"

export default function ConsumerTableContainer({
  consumers,
  isFetching,
  page,
  search,
  rowsPerPage,
  totalRecords,
  handleChangePage,
  handleChangeRowsPerPage,
  handleReload,
  paginationOptions
}) {
  const isLoadingConsumerError = useSelector(state => state.consumer.isLoadingConsumerError)
  const lang = useSelector(state => state.constant.languages)
  const language = useSelector(state => state.profile.language)

  return (
    <ConsumerTableComponent
      isFetching={isFetching}
      consumers={consumers}
      page={page}
      language={language}
      search={search}
      rowsPerPage={rowsPerPage}
      totalRecords={totalRecords}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      handleReload={handleReload}
      isLoadingConsumerError={isLoadingConsumerError}
      paginationOptions={paginationOptions}
      lang={lang}
    />
  )
}
