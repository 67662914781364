import axios from "axios";

export default function getCreditAlertInfo(parameters) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/campaignV2/customer/company/alert`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data));
  });
}
