import React from "react";
import { makeStyles, Box, Paper, Grid, Divider } from "@material-ui/core";
import SignUpLayout from "../../components/layout/signUp";
import VerificationForm from "../../components/form/verificationForm";
import Header from "../../components/header";
import Footer from "../../components/footer";

import BackgroundSignUp from "../../utils/image/background-signup.png";

const useStyle = makeStyles(theme => ({
  root: {
    maxWidth: 800,
    boxShadow: "0px 10px 15px rgba(0, 0, 0, 0.1)",
    [theme.breakpoints.down("sm")]: {
      height: "100%",
      alignSelf: "center",
      width: "70%"
    },
    [theme.breakpoints.down("xs")]: {
      height: "100%",
      alignSelf: "center",
      width: "90%"
    }
  },
  paper: {
    [theme.breakpoints.down("sm")]: {
      position: "relative"
    }
  },
  form: {
    display: "flex",
    placeContent: "center",
    width: "100%"
  },
  background: {
    height: "100%"
  }
}));

export default function SignUpVerificationPage({ isMobile, type }) {
  const classes = useStyle();

  const content = (
    <>
      <Header />
      <Divider />
      <Paper className={classes.form} elevation={0}>
        <VerificationForm />
      </Paper>
      <Divider />
      <Box className={classes.footer}>
        <Footer activeStep={1} type={type} />
      </Box>
    </>
  );

  return (
    <SignUpLayout isMobile={isMobile}>
      <Box className={classes.root}>
        <Paper className={classes.paper} elevation={0}>
          {isMobile ? (
            <Box>{content}</Box>
          ) : (
            <Grid container>
              <Grid item sm={7} md={7} style={{ width: "100%" }}>
                {content}
              </Grid>
              <Grid item sm={5} md={5} style={{ width: "100%" }}>
                <Box className={classes.background}>
                  <img
                    src={BackgroundSignUp}
                    alt="sign up background"
                    height="100%"
                    width="100%"
                  />
                </Box>
              </Grid>
            </Grid>
          )}
        </Paper>
      </Box>
    </SignUpLayout>
  );
}
