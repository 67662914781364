import {
  deleteReward,
  fetchJSONURL,
  getAllRewardListing,
  updateRewardStatus,
  getRedemptionDetails,
  getRedemptionList,
  respondRedemption,
  getRewardDetails,
  bulkUpdateRewardStatus, getAdminRewardBranchDropdown, getRewardBranchDropdown
} from "../action/stockflowRewardCenter.action"
import { setLoading, addAlert } from "modules/notification"
import { setRewardTnc } from "../slice";
import { getApiLang } from "app/feature/constants";

const getAllRewardListingMiddleware = ({ dispatch, getState }) => next => action => {
  next(action)

  const { type } = action;
  const lang = getState().constant.languages;
  switch (type) {
    case getAllRewardListing.pending.type:
      dispatch(setLoading({ id: getAllRewardListing.typePrefix, state: true }));
      break;
    case getAllRewardListing.fulfilled.type:
      dispatch(setLoading({ id: getAllRewardListing.typePrefix, state: false }));
      break;
    case getAllRewardListing.rejected.type:
      dispatch(setLoading({ id: getAllRewardListing.typePrefix, state: false }));
      dispatch(addAlert({ severity: "error", message: getApiLang(lang, 'error.ERROR_GET_REWARD_LIST') }));
      break;
    default:
      break;
  }
}

const updateRewardStatusMiddleware = ({ dispatch, getState }) => next => action => {
  next(action)

  const { type } = action;
  const lang = getState().constant.languages;
  switch (type) {
    case updateRewardStatus.pending.type:
      dispatch(setLoading({ id: updateRewardStatus.typePrefix, state: true }));
      break;
    case updateRewardStatus.fulfilled.type:
      dispatch(setLoading({ id: updateRewardStatus.typePrefix, state: false }));
      dispatch(addAlert({ severity: "success", message: getApiLang(lang, 'success.SUCCESS_UPDATE_REWARD_STATUS') }));
      break;
    case updateRewardStatus.rejected.type:
      dispatch(setLoading({ id: updateRewardStatus.typePrefix, state: false }));
      dispatch(addAlert({ severity: "error", message: getApiLang(lang, 'error.ERROR_UPDATE_REWARD_STATUS') }));
      break;
    default:
      break;
  }
}

const deleteRewardMiddleware = ({ dispatch, getState }) => next => action => {
  next(action)

  const { type, payload } = action;
  const lang = getState().constant.languages;
  switch (type) {
    case deleteReward.pending.type:
      dispatch(setLoading({ id: deleteReward.typePrefix, state: true }));
      break;
    case deleteReward.fulfilled.type:
      dispatch(setLoading({ id: deleteReward.typePrefix, state: false }));
      dispatch(addAlert({ severity: "success", message: payload.code }));
      break;
    case deleteReward.rejected.type:
      dispatch(setLoading({ id: deleteReward.typePrefix, state: false }));
      dispatch(addAlert({ severity: "error", message: getApiLang(lang, 'error.ERROR_DELETE_REWARD') }));
      break;
    default:
      break;
  }
}

const getRedemptionListMiddleware = ({ dispatch, getState }) => next => action => {
  next(action)

  const { type } = action;
  const lang = getState().constant.languages;
  switch (type) {
    case getRedemptionList.pending.type:
      dispatch(setLoading({ id: getRedemptionList.typePrefix, state: true }));
      break;
    case getRedemptionList.fulfilled.type:
      dispatch(setLoading({ id: getRedemptionList.typePrefix, state: false }));
      break;
    case getRedemptionList.rejected.type:
      dispatch(setLoading({ id: getRedemptionList.typePrefix, state: false }));
      dispatch(addAlert({ severity: "error", message: getApiLang(lang, 'error.ERROR_RETRIEVE_REDEMPTION_LIST') }));
      break;
    default:
      break;
  }
}

const getRedemptionDetailsMiddleware = ({ dispatch, getState }) => next => action => {
  next(action)

  const { type } = action;
  const lang = getState().constant.languages;
  switch (type) {
    case getRedemptionDetails.pending.type:
      dispatch(setLoading({ id: getRedemptionDetails.typePrefix, state: true }));
      break;
    case getRedemptionDetails.fulfilled.type:
      dispatch(setLoading({ id: getRedemptionDetails.typePrefix, state: false }));
      break;
    case getRedemptionDetails.rejected.type:
      dispatch(setLoading({ id: getRedemptionDetails.typePrefix, state: false }));
      dispatch(addAlert({ severity: "error", message: getApiLang(lang, 'error.ERROR_RETRIEVE_REDEMPTION_DETAIL') }));
      break;
    default:
      break;
  }
}

const respondRedemptionMiddleware = ({ dispatch, getState }) => next => action => {
  next(action)

  const { type } = action;
  const lang = getState().constant.languages;
  switch (type) {
    case respondRedemption.pending.type:
      dispatch(setLoading({ id: respondRedemption.typePrefix, state: true }));
      break;
    case respondRedemption.fulfilled.type:
      dispatch(setLoading({ id: respondRedemption.typePrefix, state: false }));
      dispatch(addAlert({ severity: "success", message: getApiLang(lang, 'success.SUCCESS_REDEMPTION_REPONSED') }));
      break;
    case respondRedemption.rejected.type:
      dispatch(setLoading({ id: respondRedemption.typePrefix, state: false }));
      dispatch(addAlert({ severity: "error", message: getApiLang(lang, 'error.ERROR_REDEMPTION_RESPOND') }));
      break;
    default:
      break;
  }
}

const getRewardDetailsMiddleware = ({ dispatch, getState }) => next => action => {
  next(action)

  const { type } = action;
  const lang = getState().constant.languages;
  switch (type) {
    case getRewardDetails.pending.type:
      dispatch(setLoading({ id: getRewardDetails.typePrefix, state: true }));
      break;
    case getRewardDetails.fulfilled.type:
      dispatch(setLoading({ id: getRewardDetails.typePrefix, state: false }));
      break;
    case getRewardDetails.rejected.type:
      dispatch(setLoading({ id: getRewardDetails.typePrefix, state: false }));
      dispatch(addAlert({ severity: "error", message: getApiLang(lang, 'error.ERROR_GET_REWARD_DETAILS') }));
      break;
    default:
      break;
  }
}

const fetchJSONURLMiddleware = ({ dispatch, getState }) => next => action => {
  next(action);
  const { type, payload } = action;
  switch (type) {
    case fetchJSONURL.pending.type:
      dispatch(setLoading({ id: fetchJSONURL.typePrefix, state: true }));
      break;
    case fetchJSONURL.fulfilled.type:
      next(setRewardTnc(action.payload));
      dispatch(setLoading({ id: fetchJSONURL.typePrefix, state: false }));
      break;
    case fetchJSONURL.rejected.type:
      const lang = getState().constant.languages
      dispatch(setLoading({ id: fetchJSONURL.typePrefix, state: false }));
      dispatch(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
      break;

    default:
      break;
  }
};

const bulkUpdateRewardStatusMiddleware = ({ dispatch, getState }) => next => action => {
  next(action)

  const { type } = action;
  const lang = getState().constant.languages;
  switch (type) {
    case bulkUpdateRewardStatus.pending.type:
      dispatch(setLoading({ id: bulkUpdateRewardStatus.typePrefix, state: true }));
      break;
    case bulkUpdateRewardStatus.fulfilled.type:
      dispatch(setLoading({ id: bulkUpdateRewardStatus.typePrefix, state: false }));
      dispatch(addAlert({ severity: "success", message: getApiLang(lang, 'success.SUCCESS_UPDATE_REWARD_STATUS') }));
      break;
    case bulkUpdateRewardStatus.rejected.type:
      dispatch(setLoading({ id: bulkUpdateRewardStatus.typePrefix, state: false }));
      dispatch(addAlert({ severity: "error", message: getApiLang(lang, 'error.ERROR_UPDATE_REWARD_STATUS') }));
      break;
    default:
      break;
  }
}

const getAdminRewardBranchDropdownMiddleware = ({ dispatch, _ }) => next => action => {
  next(action)

  const { type } = action;
  switch (type) {
    case getAdminRewardBranchDropdown.pending.type:
      dispatch(setLoading({ id: getAdminRewardBranchDropdown.typePrefix, state: true }));
      break;
    case getAdminRewardBranchDropdown.fulfilled.type:
      dispatch(setLoading({ id: getAdminRewardBranchDropdown.typePrefix, state: false }));
      break;
    case getAdminRewardBranchDropdown.rejected.type:
      dispatch(setLoading({ id: getAdminRewardBranchDropdown.typePrefix, state: false }));
      break;
    default:
      break;
  }
}

const getRewardBranchDropdownMiddleware = ({ dispatch, _ }) => next => action => {
  next(action)

  const { type } = action;
  switch (type) {
    case getRewardBranchDropdown.pending.type:
      dispatch(setLoading({ id: getRewardBranchDropdown.typePrefix, state: true }));
      break;
    case getRewardBranchDropdown.fulfilled.type:
      dispatch(setLoading({ id: getRewardBranchDropdown.typePrefix, state: false }));
      break;
    case getRewardBranchDropdown.rejected.type:
      dispatch(setLoading({ id: getRewardBranchDropdown.typePrefix, state: false }));
      break;
    default:
      break;
  }
}

export default [
  getAllRewardListingMiddleware,
  updateRewardStatusMiddleware,
  deleteRewardMiddleware,
  getRedemptionListMiddleware,
  getRedemptionDetailsMiddleware,
  respondRedemptionMiddleware,
  getRewardDetailsMiddleware,
  fetchJSONURLMiddleware,
  bulkUpdateRewardStatusMiddleware,
  getAdminRewardBranchDropdownMiddleware,
  getRewardBranchDropdownMiddleware,
];
