import React from "react";
import { makeStyles, Box, Typography, Divider } from "@material-ui/core";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles((theme) => ({
  container: {
    borderRadius: 16,
    backgroundColor: "white",
    padding: 20,
  },
}));

export default function AdditionalInfoComponent({ lang }) {
  const classes = useStyle();

  return (
    <Box p={2}>
      <Box className={classes.container}>
        <Box mb={1.5}>
          <Typography variant="body1" style={{ color: "#632FF9" }}>
            <b>{getLang(lang, "label.ADDITIONAL_INFORMATION")}</b>
          </Typography>
        </Box>
        <Divider />
        <Box mt={1.5}>
          <Box>
            <Typography variant="body1">
              <b>
                {getLang(lang, "label.EXAMPLE_LABEL")}:{" "}
                {getLang(lang, "label.EXPIRED_DATE")}
              </b>
            </Typography>
            <Typography variant="body2">
              {getLang(lang, "label.EXAMPLE_LABEL")}: 12 May 2025
            </Typography>
          </Box>
          <Box mt={2}>
            <Typography variant="body1">
              <b>
                {getLang(lang, "label.EXAMPLE_LABEL")}:{" "}
                {getLang(lang, "label.MANUFACTURED_DATE")}
              </b>
            </Typography>
            <Typography variant="body2">
              {getLang(lang, "label.EXAMPLE_LABEL")}: 12 May 2023
            </Typography>
          </Box>
          <Box mt={2}>
            <Typography variant="body1">
              <b>
                {getLang(lang, "label.EXAMPLE_LABEL")}:{" "}
                {getLang(lang, "label.INGREDIENT")}
              </b>
            </Typography>
            <Typography variant="body2">
              {getLang(lang, "label.EXAMPLE_LABEL")}: Lorem ipsum dolor sit
              amet, cum sapientem
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
