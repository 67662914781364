import axios from "axios";

export function editProductCategory({
  id,
  name,
  status
}) {
  const url =  `/serialnumber/api/v1/product/category/update/${id}`;

  return new Promise((resolve, reject) => {
    axios.post(url, {
      name: name,
      status: status,
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data))
  })
}