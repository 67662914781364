import React from "react";
import { Box, makeStyles, Typography, Divider } from "@material-ui/core";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import Input from "components/input/inputTextField";
import Skeleton from "@material-ui/lab/Skeleton";
// import CouponForm from "../forms/couponForm";
import Alert from "@material-ui/lab/Alert";
import { isEmpty } from "lodash";
import { getLang } from "app/feature/constants";

const useStyles = makeStyles(theme => ({
  indicator: {
    border: "0.5px solid",
    borderColor: theme.palette.primary.main
  },
  root: {
    display: "flex",
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.spacing(0.5)
  },
  content: {
    width: "100%",
    textAlignLast: "start"
  },
  field: {
    display: "grid",
    gridTemplateColumns: "3fr 2fr 2fr",
    columnGap: theme.spacing(2),
    marginBottom: theme.spacing(1)
  }
}));

export default function QrTabComponent({
  handleSubmit,
  handleCalculateCost,
  initialQty,
  children,
  isLoading,
  basePrice,
  calculation,
  lang
}) {
  const classes = useStyles();
  let number = new RegExp('^[0-9]+$');

  return (
    <Formik
      initialValues={{
        quantity: initialQty === 0 ? "" : initialQty
      }}
      validationSchema={Yup.object({
        quantity: Yup.number()
          .required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED"))
        .max(2147483647, getLang(lang, 'message.error.FORM_VALIDATE_MAX_CREDIT_QUANTITY', { value: 2147483647 }))
      })}
      onSubmit={handleSubmit}
    >
      {formik => (
        <form onSubmit={formik.handleSubmit} className={classes.root}>
          <Box className={classes.content}>
            <Box my={2} px={{ xs: 2, sm: 10 }}>
              <Box className={classes.field}>
                <Box display="flex" alignItems="center">
                  <Typography
                    variant="subtitle1"
                    style={{ fontWeight: "bold" }}
                  >
                    {getLang(lang, 'label.CREDIT_QUANTITY')} <Typography style={{ color: "#DE350B", display: 'inline-block' }}>*</Typography>
                  </Typography>
                </Box>
                <Box gridColumn="2/4">
                  <Input
                    size="small"
                    type="number"
                    placeholder="0"
                    hiddenLabel
                    variant="filled"
                    fullWidth
                    disabled={isLoading}
                    inputProps={{ min: 0 }}
                    {...formik.getFieldProps("quantity")}
                    onKeyDown={(e) => {
                      const invalidChars = [
                        "-",
                        "+",
                        "e",
                        ".",
                      ];
                      if (invalidChars.includes(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    onChange={(e) => {
                      formik.handleChange(e);
                      if (number.test(e.target.value) || isEmpty(e.target.value)) {
                        handleCalculateCost(e);
                      }
                    }}
                    error={
                      formik.touched.quantity && formik.errors.quantity
                        ? true
                        : false
                    }
                    helperText={ErrorMessage({
                      name: "quantity"
                    })}
                  />
                </Box>
              </Box>
            </Box>
            <Divider />
            <Box mt={2} px={{ xs: 2, sm: 10 }}>
              <Box py={1}>
                {[
                  {
                    label: getLang(lang, 'label.COST_OR_CREDIT'),
                    value: `$ ${basePrice}`
                  },
                  {
                    label: getLang(lang, 'label.SUB_TOTAL'),
                    value: `$ ${calculation ? calculation.subTotal_1 : 0}`
                  }
                ].map((field, index) => (
                  <Box key={index} className={classes.field}>
                    <Typography variant="body2" color="textSecondary">
                      {field.label}
                    </Typography>
                    <Typography variant="body2" style={{ fontWeight: "bold" }}>
                      {isLoading ? <Skeleton variant="text" /> : field.value}
                    </Typography>
                  </Box>
                ))}
              </Box>
              {
                /**
                <Divider />
                <Box py={1}>
                  <CouponForm disabled={isLoading} />
                </Box>
                */
              }
              <Divider />
              <Box pt={2} pb={1}>
                {
                  /*
                  isLoading ||
                  !calculation ||
                  (calculation && !calculation.coupon) ? null : (
                    <>
                      {[
                        {
                          label: "Promo",
                          value: `${
                            calculation.couponType === "fixed" ? "$ " : ""
                          }${calculation ? calculation.coupon : 0}${
                            calculation.couponType === "percent" ? "%" : ""
                          }`
                        },
                        {
                          label: "Promo Amount",
                          value: `$ ${
                            calculation ? calculation.couponAmount : 0
                          }`
                        },
                        {
                          label: "Sub Total after Promo",
                          value: `$ ${calculation ? calculation.subTotal_2 : 0}`
                        }
                      ].map((field, index) => {
                        if (index === 1 && calculation.couponType === "fixed") {
                          return (
                            <Box key={index} className={classes.field}>
                              <Typography variant="body2" color="textSecondary">
                                {field.label}
                              </Typography>
                              <Typography
                                variant="body2"
                                style={{ fontWeight: "bold" }}
                              >
                                {isLoading ? (
                                  <Skeleton variant="text" />
                                ) : (
                                  field.value
                                )}
                              </Typography>
                            </Box>
                          );
                        }
                        return true;
                      })}
                    </>
                  )
                  */
                }

            {
                  /*
                  [
                {
                  label: `${getLang(lang, 'label.REBATE')}(%)`,
                  value: `${calculation ? calculation.discounts : 0}%`
                },
                {
                  label: getLang(lang, 'label.REBATE_PRICE'),
                  value: `$ ${calculation ? calculation.discountAmount : 0}`
                },
                {
                  label: getLang(lang, 'label.SUB_TOTAL_AFTER_REBATE'),
                  value: `$ ${calculation ? calculation.subTotal_3 : 0}`
                }
              ].map((field, index) => (
                <Box key={index} className={classes.field}>
                  <Typography variant="body2" color="textSecondary">
                    {field.label}
                  </Typography>
                  <Typography variant="body2" style={{ fontWeight: "bold" }}>
                    {isLoading ? <Skeleton variant="text" /> : field.value}
                  </Typography>
                </Box>
              ))
                  */
                }

            {/* tax field start */}
            {[
              {
                label: getLang(lang, 'label.SALES_AND_SERVICE_TAX'),
                value: `${calculation ? calculation.tax * 100 : 0}%`
              },
              {
                label: getLang(lang, 'label.TAX_AMOUNT'),
                value: `$ ${calculation ? calculation.taxAmount : 0}`
              }
            ].map((field, index) => (
              <Box key={index} className={classes.field}>
                <Typography variant="body2" color="textSecondary">
                  {field.label}
                </Typography>
                <Typography variant="body2" style={{ fontWeight: "bold" }}>
                  {isLoading ? <Skeleton variant="text" /> : field.value}
                </Typography>
              </Box>
            ))}
            {/* tax field end */}
          </Box>

          <Divider />
          <Box py={2} className={classes.field}>
            <Typography variant="subtitle1">{getLang(lang, 'label.TOTAL_COST')}</Typography>
            <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
              {isLoading ? (
                <Skeleton variant="text" />
              ) : (
                `$ ${calculation ? calculation.totalPrice : 0}`
              )}
            </Typography>
          </Box>
        </Box>

            {/* step footer */}
            <Divider />
            <Box p={2}>
              {
                (!calculation || calculation.totalPrice < 15) 
                ? (
                    <Alert severity="info">{getLang(lang, 'message.info.TOTAL_MUST_MORE_THAN_15USD')}</Alert>
                  ) 
                : (
                    children({
                      disabled:
                        formik.values.quantity <= 0 ||
                        formik.values.quantity > 2147483647 ||
                        !number.test(formik.values.quantity) ||
                        isLoading,
                    })
                )
              }
            </Box>
          </Box>
        </form>
      )}
    </Formik>
  );
}
