import React, { useState, useRef, useEffect } from "react";
import CurrencySelectComponent from "./currencySelect.component";
import CurrencyPanelComponent from "./currencyPanel.component";
import { selectLoading } from "modules/notification";
import { useDispatch, useSelector } from "react-redux";
import cloneDeep from "lodash/cloneDeep";
import { getCurrencyDropdown } from "modules/product-mgmt/redux/action/productPage.action";

function CurrencySelectContainer({ 
  placeholder, 
  handleChange, 
  value, 
  disabled, 
  styles, 
  searchPlaceholder,
  searchable,
  textStyles
}) {
  const dispatch = useDispatch();
  const lang = useSelector(state => state.constant.languages)
  const currencyDropdown = useSelector(state => state.productPage.currencyDropdown)
  const isCurrencyDropdownLoading = useSelector((state) => selectLoading(state, getCurrencyDropdown.typePrefix));
  const [open, setOpen] = useState(false);
  const [isOpenList] = useState(false);
  const anchorRef = useRef(null);
  const prevOpen = useRef(open);
  const prevOpenList = useRef(isOpenList);
  const [selectAll, setSelectAll] = useState(false);
  const [currencyCodeDropdownSearch, setCurrencyCodeDropdownSearch] = useState("")

  useEffect(() => {
    dispatch(getCurrencyDropdown({ search: currencyCodeDropdownSearch }))
  }, [dispatch, currencyCodeDropdownSearch])

  const handleClose = () => {
    setOpen(false);
    setCurrencyCodeDropdownSearch("")
  };

  const handleClick = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    if (prevOpenList.current === true && isOpenList === false) {
      anchorRef.current.focus();
    }

    prevOpenList.current = isOpenList;
  }, [isOpenList]);

  const handleSelectAll = async () => {
    let temp = cloneDeep(value)
    let newSelectAll = !selectAll;

    if (newSelectAll) {
      for (const currency of currencyDropdown) {
        if (temp.findIndex(t => t.alpha3 === currency.alpha3) < 0) {
          temp.push(currency)
        }
      }
    } else {
      for (const currency of currencyDropdown) {
        temp = temp.filter(t => t.alpha3 !== currency.alpha3)
      }
    }
    setSelectAll(!selectAll)
    handleChange(temp)
  };

  const handleSelectCurrency = (currency) => {
    let temp = cloneDeep(value)
    if (temp.findIndex(t => t.alpha3 === currency.alpha3) >= 0) {
      temp = temp.filter(t => t.alpha3 !== currency.alpha3)
    } else {
      temp.push(currency)
    }
    handleChange(temp)
  };

  const handleSearch = (value) => {
    setCurrencyCodeDropdownSearch(value)
  }

  return (
    <>
      <CurrencySelectComponent
        anchorRef={anchorRef}
        handleClick={handleClick}
        value={value}
        styles={styles}
        placeholder={placeholder}
        disabled={disabled}
        textStyles={textStyles}
        lang={lang}
      />
      <CurrencyPanelComponent
        selectAll={selectAll}
        value={value}
        anchorRef={anchorRef}
        open={open}
        isOpenList={isOpenList}
        handleClose={handleClose}
        isFetching={isCurrencyDropdownLoading}
        currencyDropdown={currencyDropdown}
        handleSelectAll={handleSelectAll}
        handleSelectCurrency={handleSelectCurrency}
        searchable={searchable}
        placeholder={searchPlaceholder}
        handleSearch={handleSearch}
        lang={lang}
      />
    </>
  );
}

export default CurrencySelectContainer;
