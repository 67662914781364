import { createSelector } from "@reduxjs/toolkit";

const selectSignupState = createSelector(
  state => state.signup,
  result => result
);

export const selectAccountCreatedBoolean = createSelector(
  selectSignupState,
  signupState => signupState.isAccountCreated
);

export const selectNewAccountEmail = createSelector(
  selectSignupState,
  signupState => signupState.email
);

