import React, { useEffect, useState } from "react";
import MainPageComponent from "./main.page";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { BRANCH_VIEW, BRANCH_ADD } from "lib/constants/accessRights";
import LoadingComponent from "components/loading";
import { getLang } from "app/feature/constants";

function MainPageContainer() {
  const history = useHistory()
  const hasBranchViewAccessRight = useSelector(state => state.profile.accessRights.includes(BRANCH_VIEW));
  const hasBranchAddAccessRight = useSelector(state => state.profile.accessRights.includes(BRANCH_ADD));
  const isBranchOnly = useSelector(state => state.profile.nodeName.every(item => item.remarks === "branch"))
  const lang = useSelector(state => state.constant.languages)

  useEffect(() => {
    if(!hasBranchViewAccessRight){
      history.push('/admin/dashboard')
    }
  }, [history, hasBranchViewAccessRight])

  const [activeIndex, setActiveIndex] = useState(0);

  if (!hasBranchViewAccessRight) return <LoadingComponent />;

  const tabLabels = [getLang(lang, "label.BRANCH_LIST")];

  const handleChange = (event, index) => {
    setActiveIndex(index);
  };

  return (
    <MainPageComponent
      hasBranchAddAccessRight={hasBranchAddAccessRight}
      handleChange={handleChange}
      activeIndex={activeIndex}
      tabLabels={tabLabels}
      isBranchOnly={isBranchOnly}
      lang={lang}
    />
  );
}

export default MainPageContainer;
