import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import ApproveRegistrationDialogComponent from "./approveRegistrationDialog.component";

function ApproveRegistrationDialogContainer({ isOpen, handleClose, handleConfirm }) {

  const lang = useSelector(state => state.constant.languages);

  return (
    <ApproveRegistrationDialogComponent
      isOpen={isOpen}
      handleClose={handleClose}
      handleConfirm={handleConfirm}
      lang={lang}
    />
  );
}

ApproveRegistrationDialogContainer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
};

export default ApproveRegistrationDialogContainer;
