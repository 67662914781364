import React from "react";
import ProductCountDetailPopoverComponent from "./productCountDetailPopover.component";
import {useSelector} from "react-redux";
import {useState} from "react";

export default function ProductCountDetailPopoverContainer({
  products,
  itemTranslator,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const lang = useSelector(state => state.constant.languages);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const handleClose = () => {
    setAnchorEl(null);
  }

  return <ProductCountDetailPopoverComponent
    products={products}
    itemTranslator={itemTranslator}
    isOpen={!!anchorEl}
    anchorEl={anchorEl}
    handleOpen={handleOpen}
    handleClose={handleClose}
    lang={lang}
  />
}
