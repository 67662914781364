import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import {
  ROOT,
  INSTANT_LUCKY_DRAW_PARTICIPANT
} from "./config/instant-lucky-draw.route";
import { MainPage, ParticipantPage } from "./pages";
import { instantLuckyDrawReducer } from "./redux";

export default function LuckyDrawModule() {
  return (
    <Switch>
      <Route exact path={ROOT} component={MainPage} />
      <Route
        exact
        path={INSTANT_LUCKY_DRAW_PARTICIPANT}
        component={ParticipantPage}
      />
      <Route path="*">
        <Redirect to="/admin/invalid" />
      </Route>
    </Switch>
  );
}

export { ROOT, instantLuckyDrawReducer };
