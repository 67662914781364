import React, { useState } from "react";
import { withStyles, makeStyles, FormControlLabel, Checkbox, FormControl, FormGroup, Box } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Masonry from "components/masonry/Masonry";
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import { getLang } from "app/feature/constants";

const useStyle = makeStyles((theme) => ({
    body: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(0, 3),
        columnGap: theme.spacing(4)
    },
    formControl: {
        marginLeft: theme.spacing(6),

    },
    formControlLabel: {
        "& .MuiFormControlLabel-label": {
            fontWeight: "bold"
        }
    }
}));

export default function RoleNameInputComponent({
    label,
    subgroup,
    accessRight,
    formik,
    setAccess,
    accessRole,
    inheritAccessRights,
    disabledCheckbox,
    disableSummaryAccordion,
    lang,
    ...props
}) {
    const classes = useStyle();
    const Accordion = withStyles({
        root: {
            boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
            '&:not(:last-child)': {
                borderBottom: 0,
            },
            '&:before': {
                display: 'none',
            },
            '&$expanded': {
                margin: 'auto',
            },
        },
        expanded: {},
    })(MuiAccordion);
    const AccordionSummary = withStyles({
        root: {
            minHeight: 56,
            '&$expanded': {
                minHeight: 56,
            },
        },
        content: {
            margin: '0px 0',
            '&$expanded': {
                margin: '0px 0',
            },
        },
        expanded: {},
    })(MuiAccordionSummary);

    const AccordionDetails = withStyles((theme) => ({
        root: {
            padding: "unset",
        },
    }))(MuiAccordionDetails);
    
    const groupCheck = (subgroup, isChecked) => {
        if (isChecked) {
            let isGroupCheck = true;
            let groupIds = []
            subgroup.map((field) => {
                return field.details.forEach((info) => groupIds.push(info.id))
            })
            setAccess({
                isGroupCheck: isGroupCheck,
                infos: groupIds
            })
        } else {
            subgroup.map((field) => {
                return field.details.forEach((info) => setAccess(info.id))
            })
        }
    };

    const subgroupCheck = (details, isChecked) => {
        if (isChecked) {
            let isSubgroupCheck = true;
            let groupIds = []
            details.forEach((info) => {
                groupIds.push(info.id)
            })
            setAccess({
                isGroupCheck: isSubgroupCheck,
                infos: groupIds
            })
        } else {
            details.forEach((info) => {
                setAccess(info.id)
            })
        }
    };

    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    let groups = [];

    subgroup.map((field) => {
        return field.details.forEach((info) => groups.push(info.id))
    })

    return (
        <Masonry item style={{ padding: "5px" }}>
            <Accordion expanded={expanded === label} onChange={handleChange(label)}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <FormControlLabel
                        onClick={(event) => event.stopPropagation()}
                        onFocus={(event) => event.stopPropagation()}
                        control={<Checkbox
                            indeterminateIcon={
                                <IndeterminateCheckBoxIcon color={disabledCheckbox || disableSummaryAccordion ? "disabled" : "secondary"} />
                            }
                            checked={
                                groups.every(group => {
                                    return accessRole.includes(group);
                                })
                            }
                            indeterminate={
                                groups.some(group => {
                                    return accessRole.includes(group);
                                }) &&
                                  groups.every(group => {
                                    return accessRole.includes(group);
                                }) === false
                            }
                        />}
                        onChange={(event) => {
                            groupCheck(subgroup, event.target.checked)
                        }}
                        label={getLang(lang, 'db_label.'+label)  }
                        className={classes.formControlLabel}
                        disabled={disabledCheckbox || disableSummaryAccordion}
                    />
                </AccordionSummary>
                <AccordionDetails>
                    <FormControl component="fieldset" className={classes.formControl}>
                        <FormGroup>
                            {subgroup.map((field) => {
                                let ids = [];
                                const hasInheritAccessRights = inheritAccessRights.includes(field.details[0].action);

                                field.details.map((info) => ids.push(info.id));
                                return (
                                    <>
                                        {
                                            field.name && (
                                                <FormControlLabel
                                                    control={<Checkbox
                                                        indeterminateIcon={<IndeterminateCheckBoxIcon  color="secondary" />}
                                                        checked={
                                                            ids.every(id => {
                                                                return accessRole.includes(id);
                                                            })
                                                        }
                                                        indeterminate={
                                                            ids.some(id => {
                                                                return accessRole.includes(id);
                                                            }) &&
                                                            ids.every(id => {
                                                                return accessRole.includes(id);
                                                            }) === false
                                                        }
                                                    />}
                                                    label={getLang(lang, 'db_label.'+field.name)}
                                                    onChange={(event) =>
                                                        subgroupCheck(field.details, event.target.checked)
                                                    }
                                                    disabled={disabledCheckbox || !hasInheritAccessRights}
                                                />)
                                        }
                                        <Box display='flex' flexDirection='column' ml={field.name ? 3 : 0}>
                                            {
                                                field.details.map((info, index) => 
                                                    <FormControlLabel key={index}
                                                        control={<Checkbox
                                                            checked={accessRole.includes(info.id)}
                                                        />}
                                                        label={getLang(lang, 'db_label.'+info.title)}
                                                        onChange={(event) => {
                                                            setAccess(info.id)
                                                        }}
                                                        disabled={disabledCheckbox || !hasInheritAccessRights}
                                                    />
                                                )
                                            }
                                        </Box>
                                    </>
                                )
                            }
                            )}
                        </FormGroup>
                    </FormControl>
                </AccordionDetails>
            </Accordion>
        </Masonry >
    );
}