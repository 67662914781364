import axios from "axios";

export function getSurveyByDevice({
  survey_uuid,
  last_period
}) {
  return new Promise((resolve, reject) => {
    axios.get(`/analytic/api/v1/consumer/survey-device/${survey_uuid}`, {
      params: {
        last_period: last_period
      }
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data))
  });
}