import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { ROOT } from "./config/account.route";
import { MainPage } from "./pages";
import {
  accountReducer,
  settingReducer,
  stockModuleSettingReducer,
  setAccount,
  setAppSetting,
  setConsumerPortalSetting,
  setAccountProfilePicture,
  disbursementReducer,
} from "./redux";

export default function Moduleaccount() {
  return (
    <Switch>
      <Route exact path={ROOT} component={MainPage} />
      <Route path="*">
        <Redirect to="/admin/invalid" />
      </Route>
    </Switch>
  );
}

export {
  ROOT,
  accountReducer,
  disbursementReducer,
  settingReducer,
  stockModuleSettingReducer,
  setAccount,
  setAppSetting,
  setConsumerPortalSetting,
  setAccountProfilePicture,
};
