import React, { useState } from 'react'
import {useDispatch, useSelector} from 'react-redux';
import { ProductPagePreviewDialog } from '../../dialog'
import PageWorkspacePanelComponent from './pageWorkspacePanel.component'
import {setPageWorkspaceLanguage} from "../../../redux/slice/productPage.slice";

export default function PageWorkspacePanelContainer() {
    const [isPreviewDialogOpen, setIsPreviewDialogOpen] = useState(false)
    const lang = useSelector(state => state.constant.languages);
    const productLanguages = useSelector(state => state.productPage.lang);
    const currentLanguage = useSelector(state => state.productPage.pageWorkspaceLanguage);
    const dispatch = useDispatch();

    const handlePreview = () => {
        setIsPreviewDialogOpen(true)
    }

    const onSetLanguage = (language) => {
      dispatch(setPageWorkspaceLanguage(language));
    }

    return (
        <>
            <PageWorkspacePanelComponent
                handlePreview={handlePreview}
                lang={lang}
                productLanguages={productLanguages}
                currentLanguage={currentLanguage}
                onSetLanguage={onSetLanguage}
            />
            <ProductPagePreviewDialog
                isOpen={isPreviewDialogOpen}
                handleClose={() => setIsPreviewDialogOpen(false)}
            />
        </>
    )
}
