import React, { useState, useEffect } from "react";
import ProductSearchFieldComponent from "./productSearchField.component";
import ProductSearchPopoverComponent from "./productSearchPopover.component";
import { useSelector } from "react-redux";

function ProductSearchFieldContainer({
  searchable,
  value,
  placeholder,
  searchPlaceholder,
  handleChange,
  disabled,
  isFetching,
  panelStyle,
  dropdownItem,
  anchorOrigin,
  transformOrigin,
  PaperProps,
  style,
  textStyle,
  isEmpty,
  isClearable,
  handleClear,
  emptyMessage,
  handleSelectAll,
  handleChipDelete,
  handleSelectNotAssigned,
  isNotAssignSelected = false,
}) {
  const lang = useSelector((state) => state.constant.languages);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectAll, setSelectAll] = useState(false);
  const [selectNotAssigned, setSelectNotAssigned] =
    useState(isNotAssignSelected);
  const open = Boolean(anchorEl);
  const [productList, setProductList] = useState(dropdownItem);
  const drawerOpen = useSelector((state) => state.app.drawerOpen);
  const [extraChip, setExtraChip] = useState({
    count: 0,
    offset: 0,
    allOverflow: false,
    overflowChild: [],
  });

  const adjustField = (ref) => {
    let children = ref.current?.children[0]?.children;
    let p = ref.current?.children[0];
    if (!children) return;

    if (children.length > 0) {
      // Need to exclude placeholder
      if (children[0].role == null) return;

      children = [...children];

      // max width is 90%. Use 0.9 to calc field width
      let panelWidth = ref.current?.getBoundingClientRect().width * 0.9;
      let childWidth = 0;

      // Check for no overflow child count
      let inRangeCount = children.filter((child, index) => {
        let panelContentWidth = p.getBoundingClientRect().width;
        // get exact width + padding with decimal
        childWidth +=
          parseInt(window.getComputedStyle(child).marginRight) +
          child.getBoundingClientRect().width;

        // Check if overflow
        if (panelWidth - panelContentWidth < 1) {
          // need to add 0.5 as the ellipsis padding (0.5 = ellipsis padding)
          // if the overflow happen before last child, need inlude another 10 for the ellipsis (10 = ellipsis width)
          return index !== children.length - 1
            ? childWidth + 10.5 < panelContentWidth
            : childWidth + 0.5 < panelContentWidth;
        }

        return panelWidth > panelContentWidth;
      }).length;

      if (inRangeCount > 0) {
        let lastRangeChild = children[inRangeCount - 1];

        let outRangeChild = value.slice(inRangeCount);

        // Check for overflow child count
        let extraCount = value.length - inRangeCount;
        setExtraChip({
          count: extraCount,
          offset: lastRangeChild.offsetLeft + lastRangeChild.offsetWidth,
          allOverflow: false,
          overflowChild: outRangeChild,
        });
      } else {
        setExtraChip({
          count: value.length,
          offset: 0,
          allOverflow: true,
          overflowChild: value,
        });
      }
    } else {
      setExtraChip({
        count: 0,
        offset: 0,
        allOverflow: false,
        overflowChild: [],
      });
    }
  };

  useEffect(() => {
    setProductList(dropdownItem);
  }, [dropdownItem]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    handleSearch("");
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSearch = (targetProduct) => {
    if (targetProduct.trim().length === 0) {
      setProductList(dropdownItem);
      return;
    }
    let products = [];
    dropdownItem.forEach((product) => {
      let productName = product.name ?? "";
      if (productName.toLowerCase().includes(targetProduct.toLowerCase())) {
        products.push(product);
      }
    });

    setProductList(products);
  };

  const handleAll = async () => {
    if (selectNotAssigned) {
      handleSelectNotAssigned(false);
      setSelectNotAssigned(false);
    }

    handleSelectAll(!selectAll);
    setSelectAll(!selectAll);
  };

  const handleNotAssigned = async () => {
    if (selectAll) {
      handleSelectAll(false);
      setSelectAll(false);
    }

    handleSelectNotAssigned(!selectNotAssigned);
    setSelectNotAssigned(!selectNotAssigned);
  };

  const handleDeleteNotAssigned = async () => {
    handleSelectNotAssigned(false);
    setSelectNotAssigned(false);
  };

  return (
    <>
      <ProductSearchFieldComponent
        handleClick={handleClick}
        handleClear={handleClear}
        value={value}
        isEmpty={isEmpty}
        style={style}
        disabled={disabled}
        placeholder={placeholder}
        open={open}
        handleChange={handleChange}
        textStyle={textStyle}
        isClearable={isClearable}
        handleChipDelete={handleChipDelete}
        drawerOpen={drawerOpen}
        extraChip={extraChip}
        adjustField={adjustField}
        selectNotAssigned={selectNotAssigned}
        handleDeleteNotAssigned={handleDeleteNotAssigned}
        lang={lang}
      />
      <ProductSearchPopoverComponent
        anchorEl={anchorEl}
        open={open}
        handleClose={handleClose}
        style={panelStyle}
        items={productList}
        handleSearch={handleSearch}
        isFetching={isFetching}
        searchable={searchable}
        handleChange={(item) => {
          handleChange(item);
          setSelectNotAssigned(false);
        }}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        PaperProps={PaperProps}
        value={value}
        emptyMessage={emptyMessage}
        placeholder={searchPlaceholder}
        lang={lang}
        selectAll={selectAll}
        handleAll={handleAll}
        handleNotAssigned={handleNotAssigned}
        selectNotAssigned={selectNotAssigned}
      />
    </>
  );
}

export default ProductSearchFieldContainer;
