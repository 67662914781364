import React from "react";
import {
  makeStyles,
  Paper,
  Button,
  Divider,
  Typography,
  IconButton,
  Backdrop,
  Box
} from "@material-ui/core";
import Dialog from "components/dialog/customDialog";
import CloseIcon from "@material-ui/icons/Close";
import { ErrorSVG } from "../../../utils/static";
import { SetWinnerTable } from "../../table";
import { getLang } from "app/feature/constants";

const style = (theme) => ({
  header: {
    padding: "4px 0px",
    paddingLeft: theme.spacing(3),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  root: {
    width: 550,
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  discardButton: {
    color: theme.palette.error.main
  },
  publishButton: {
    color: theme.palette.common.white
  },
  winnerContainer: {
    border: "1px solid rgba(0, 0, 0, 0.1)",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
    margin: theme.spacing(1, 3, 2, 3)
  },
  viewPrizeContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 5fr",
    padding: theme.spacing(0, 2)
  },
  viewPrizePhoto: {
    width: 100,
    height: 100,
    display: "flex",
    alignItems: "center",
    padding: "4px",
    placeContent: "center"
  },
  form: {
    height: "65vh",
    overflow: "auto"
  }
});

const useStyles = makeStyles(style);

export default function WinnerDialogComponent({
  hasInstantLuckyDrawEditAccessRight,
  handleClose,
  isOpen,
  prizeList,
  handleSave,
  lang
}) {
  const classes = useStyles();

  return (
    <Dialog
      isOpen={isOpen}
      handleClose={handleClose}
      disableEnforceFocus={true}
      disableAutoFocus={true}
      disableEscapeKeyDown={true}
      disableBackdropClick={true}
      closeAfterTransition
      BackdropComponent={Backdrop}
    >
      <Paper elevation={0} className={classes.root}>
        <div className={classes.form}>
          <Box className={classes.header}>
            <Typography
              variant="subtitle2"
              color="primary"
              style={{ fontWeight: "bold" }}
            >
              {hasInstantLuckyDrawEditAccessRight ? getLang(lang,"label.MANAGE_INSTANT_LUCKY_DRAW_WINNER") : getLang(lang,"label.VIEW_INSTANT_LUCKY_DRAW_WINNER")}
            </Typography>
            <IconButton color="primary" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>

          <Divider />
          {
            hasInstantLuckyDrawEditAccessRight
              ?
              <Box px={3} pt={2} display="flex">
                <div style={{ paddingTop: "3px" }}>
                  <ErrorSVG />
                </div>
                <Typography
                  variant="caption"
                  color="primary"
                  style={{
                    paddingLeft: "4px",
                  }}
                >
                  {getLang(lang,"paragraph.PRESS_ADD_WINNER_BUTTON")}
                </Typography>
              </Box>
              : null
          }
          {prizeList.length > 0
            ? prizeList.map((value, index) => (
              <React.Fragment key={index}>
                <Box className={classes.winnerContainer}>
                  <div className={classes.viewPrizeContainer}>
                    <Box className={classes.viewPrizePhoto}>
                      <img
                        src={value.prizeImage}
                        alt="example"
                        height="100%"
                        width="100%"
                        style={{ objectFit: "contain" }}
                      />
                    </Box>

                    <Box p={2}>
                      <Box display="flex" pt={1} pb={1}>
                        <Typography
                          variant="body2"
                          style={{ color: " rgba(58, 77, 84, 0.33)" }}
                        >
                          {getLang(lang,"label.PRIZE_NAME")}:&nbsp;
                        </Typography>
                        <Typography
                          variant="body2"
                          color="primary"
                          style={{ fontWeight: "bold" }}
                        >
                          {value.prizeName}
                        </Typography>
                      </Box>

                      <Box display="flex">
                        <Typography
                          variant="body2"
                          style={{ color: " rgba(58, 77, 84, 0.33)" }}
                        >
                          {getLang(lang,"label.PRIZE_QUANTITY")}:&nbsp;
                        </Typography>
                        <Typography
                          variant="body2"
                          color="primary"
                          style={{ fontWeight: "bold" }}
                        >
                          {value.quantity.toLocaleString("en-US")}
                        </Typography>
                      </Box>
                    </Box>
                  </div>
                  <Divider />
                  <SetWinnerTable
                    dialog="winner"
                    quantity={value.winners.filter((v) => v.prizeType === "preset" || v.prize_type === "preset").length}
                    winners={value.winners.filter((v) => v.prizeType === "preset" || v.prize_type === "preset")}
                    balance={value.balance}
                    index={index}
                    lang={lang}
                  />
                </Box>
              </React.Fragment>
            ))
            : null}
        </div>
        {
          hasInstantLuckyDrawEditAccessRight
            ?
            <Box mt="auto">
              <Divider />
              <Box
                display="flex"
                justifyContent="flex-end"
                px={2}
                pt={1}
                pb={1}
              >
                <Button
                  variant="text"
                  disableElevation
                  onClick={handleClose}
                  style={{marginRight: "4px"}}
                >
                  {getLang(lang,"label.CANCEL")}
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  classes={{ root: classes.publishButton }}
                  disableElevation
                  type="button"
                  onClick={handleSave}
                >
                  {getLang(lang,"label.SAVE")}
                </Button>
              </Box>
            </Box>
            : null
        }
      </Paper>
    </Dialog>
  );
}
