import React, { useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { setProductInfo } from "../../../redux/slice/productPage.slice";
import { getProductCategoryDropdown } from "modules/product-mgmt/redux/action/productCategory.action"
import { getCurrencyDropdown } from "modules/product-mgmt/redux/action/productPage.action"
import { getAttributeDropdown } from "modules/product-mgmt/redux/action/attribute.action"
import { getBrandModelDropdown } from "../../../redux/action/brandModels.action";
import { addAlert } from "modules/notification";
import ProductInfoFormComponent from "./productInfoForm.component";
import { selectLoading } from "modules/notification";
import { getLang } from "app/feature/constants";

function ProductInfoFormContainer({
  children,
  setProductInfo,
  handleNext,
  handleCancel,
  formType,
  productPage,
  enableEdit,
  allNodes,
  hasPicture,
  addAlert
}) {
  const dispatch = useDispatch();
  const lang = useSelector(state => state.constant.languages)
  const categoryDropdown = useSelector(state => state.products.categoryList.categoryDropdown)
  const currencyDropdown = useSelector(state => state.productPage.currencyDropdown)
  const attributeDropdown = useSelector(state => state.products.attributeList.attributeDropdown)
  const brandModelDropdown = useSelector(state => state.products.brandModelList.brandModelDropdown)
  const isFetching = useSelector(state => selectLoading(state, getCurrencyDropdown.typePrefix))
  const [isScanLimitDisabled, setIsScanLimitDisabled] = useState(null);
  // const [ categoryDropdownSearch, setCategoryDropdownSearch ] = useState("");
  // const [ attributeDropdownSearch, setAttributeDropdownSearch ] = useState("");
  // const [ brandModelDropdownSearch, setBrandModelDropdownSearch ] = useState("")

  useEffect(() => {
    dispatch(getCurrencyDropdown())
    dispatch(getProductCategoryDropdown({ search: "" }))
    dispatch(getAttributeDropdown({ search: "" }))
    dispatch(getBrandModelDropdown({ search: "" }))
  }, [dispatch])

  useEffect(() => {

    setIsScanLimitDisabled(productPage.scanLimit === 0);

  }, [productPage])

  const handleSubmitForm = async (values) => {
    if (hasPicture) {
      const productInfo = {
        ...values,
        currencyCode: typeof values.currencyCode === 'string' ? values.currencyCode : values.currencyCode.alpha3
      };
      await setProductInfo(productInfo);
      handleNext();
    } else {
      addAlert({
        severity: "error",
        message: getLang(lang, "message.error.PLEASE_UPLOAD_IMAGE")
      });
    }
  };
  return (
    <ProductInfoFormComponent
      children={children}
      formType={formType}
      handleSubmit={handleSubmitForm}
      handleCancel={handleCancel}
      initialValues={productPage}
      enableEdit={enableEdit}
      allNodes={allNodes}
      lang={lang}
      categoryDropdown={categoryDropdown}
      currencyDropdown={currencyDropdown}
      attributeDropdown={attributeDropdown}
      brandModelDropdown={brandModelDropdown}
      isFetching={isFetching}
      isScanLimitDisabled={isScanLimitDisabled}
      setIsScanLimitDisabled={setIsScanLimitDisabled}
    />
  );
}

ProductInfoFormContainer.defaultProps = {
  handleNext: () => { },
  enableEdit: true
};

ProductInfoFormContainer.propTypes = {
  handleNext: PropTypes.func,
  formType: PropTypes.string,
  enableEdit: PropTypes.bool
};

const mapStateToProps = (state) => ({
  productPage: state.productPage,
  allNodes: state.products.branchDropdown,
  hasPicture: state.productPage.picture !== ""
});

const mapDispatchToProps = (dispatch) => ({
  setProductInfo: (payload) => dispatch(setProductInfo(payload)),
  addAlert: (payload) => dispatch(addAlert(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductInfoFormContainer);
