import {
	initializeApp,
	fetchMasterInfo,
	logout,
	checkSession,
	fetchAppSetting
} from "../action";
import { setSession, setAppInitialized, setLabels, setCompanySession, setMaintenanceStatus, setMaintenanceAlert } from "../slice";
import { setProfile, setAccessRights } from "modules/profile";
import { setSubcriptionPlan } from "modules/billing/redux";
import { setAccount, setAppSetting } from "modules/account";
import { setSignupSuccessData } from "modules/public/redux/slice/signup.slice";
import { addAlert, setLoading } from "modules/notification";
import { LABEL_COMPANY } from "modules/admin/constants";
import { getApiLang, getApiLanguages, getLanguages } from "app/feature/constants";
import { getCompanyDisbursement } from "modules/account/redux";

const initializeAppMiddleware = ({
	dispatch,
	getState
}) => next => async action => {
	next(action);

	const { type } = action;

	if (type === initializeApp.type) {
		const sessionState = getState().session;
		const { emailVerified, email, sub } = sessionState.idTokenPayload;

		if (!emailVerified) next(setSignupSuccessData({ email, id: sub }));

		await dispatch(fetchMasterInfo());
		next(setAppInitialized());
	}
};

const fetchAppSettingMiddleware = () => next => async action => {
	next(action);
	const { type, payload } = action;

	switch (type) {
		case fetchAppSetting.fulfilled.type:
			const company = payload.data;
			next(setAppSetting(company));

			break;

		default:
			break;
	}
};

const fetchMasterInfoMiddleware = ({ dispatch, getState }) => next => async action => {
	next(action);

	const { type, payload } = action;
	const { sub, name } = getState().session.idTokenPayload

	switch (type) {
		case fetchMasterInfo.fulfilled.type:
			/**
			 * break up giant data from masterInfo and access rights,
			 * then dispatch an action to set data to respective reducer
			 */

			const username = { username: name, auth0: sub };
			const subscriptionPlan = payload.data.subscription;
			const maintenance = payload.data.maintenance;
			const maintenanceAlert = payload.data.maintenance_alert;
			const profile = payload.data.user;
			const labels = payload.data.label
			const company = payload.data.company;
			const accessRights = payload.data.user.access_rights;
			if (labels.some((label) => label.key === LABEL_COMPANY)) {
				if (company != null) {
					next(setCompanySession(company));
				}
			}

			next(setLabels(labels));
			next(setSubcriptionPlan(subscriptionPlan));
			next(setAccount(company));
			next(setProfile(profile));
			next(setProfile(username));
			next(setAccessRights(accessRights));
			next(setMaintenanceStatus(maintenance));
			next(setMaintenanceAlert(maintenanceAlert));

			//next(setAppSetting(company));
			/**
			 * need to resolve on consumer, company, and profile api
			 *
			 * next(setConsumerPortalSetting(account.consumer_UISettings));
			 * next(setAccountProfilePicture(account.profilePicture));
			 */
			next(setLoading({ id: fetchMasterInfo.typePrefix, state: false }));
			next(await dispatch(getCompanyDisbursement()));
			next(await dispatch(getLanguages(profile.locale)));
			next(await dispatch(getApiLanguages(profile.locale)));

			break;

		case fetchMasterInfo.rejected.type:
			next(setLoading({ id: fetchMasterInfo.typePrefix, state: false }));
			break;

		case fetchMasterInfo.pending.type:
			next(setLoading({ id: fetchMasterInfo.typePrefix, state: true }));
			break;

		default:
			break;
	}
};

const logoutMiddleware = ({ getState, dispatch }) => next => action => {
	next(action);

	const { payload, type } = action;

	switch (type) {
		case logout.rejected.type:
			const lang = getState().constant.languages
			dispatch(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
			break;

		default:
			break;
	}
};

const checkSessionMiddleware = ({ dispatch, getState }) => next => action => {
	next(action);

	const { type, payload, meta } = action;

	switch (type) {
		case checkSession.pending.type:
			if (getState().session.accessToken === "") {
				const silent = meta.arg ? meta.arg.silent : false;
				if (!silent)
					next(setLoading({ id: checkSession.typePrefix, state: true }));
			}
			break;

		case checkSession.fulfilled.type:
			dispatch(setSession(payload));
			next(setLoading({ id: checkSession.typePrefix, state: false }));
			break;

		case checkSession.rejected.type:
			next(setLoading({ id: checkSession.typePrefix, state: false }));
			break;

		default:
			break;
	}
};

export default [
	initializeAppMiddleware,
	fetchAppSettingMiddleware,
	checkSessionMiddleware,
	fetchMasterInfoMiddleware,
	logoutMiddleware
];
