import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { selectLoading } from "modules/notification";
import LoadingComponent from "components/loading";
import {
  getReceiveTransferDeliveryInfo,
  getReceiveTransferDetail,
  resetReceiveTransfer,
} from "modules/stockflow/redux";
import { STOCKFLOW_RECEIVE_TRANSFER_VIEW } from "lib/constants/accessRights";
import { ROOT } from "modules/dashboard";
import ReceiveTransferDetailsComponent from "./receiveTransferDetails.component";

function ReceiveTransferDetailsContainer() {
  const history = useHistory();
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const id = match.params.uuid;
  const hasStockflowViewAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(STOCKFLOW_RECEIVE_TRANSFER_VIEW)
  );

  const transfer = useSelector(
    (state) => state.stockflowReceiveTransfer.receiveTransfer
  );
  const isError = useSelector(
    (state) => state.stockflowReceiveTransfer.isLoadingReceiveTransferError
  );
  const isFetching = useSelector((state) =>
    selectLoading(state, getReceiveTransferDetail.typePrefix)
  );
  const lang = useSelector((state) => state.constant.languages);
  const language = useSelector((state) => state.profile.language);

  useEffect(() => {
    return () => {
      dispatch(resetReceiveTransfer());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!hasStockflowViewAccessRight) {
      history.push("/admin/dashboard");
    }
  }, [history, hasStockflowViewAccessRight]);

  useEffect(() => {
    if (id) {
      dispatch(getReceiveTransferDetail(id));
    } else {
      history.push(ROOT);
    }
  }, [history, id, dispatch]);

  if (!hasStockflowViewAccessRight) return <LoadingComponent />;

  const handleReload = () => {
    dispatch(getReceiveTransferDetail(id));
  };

  const fetchDeliveryInfo = () => {
    dispatch(getReceiveTransferDeliveryInfo(id));
  };

  return (
    <>
      <ReceiveTransferDetailsComponent
        transfer={transfer}
        isFetching={isFetching}
        language={language}
        isError={isError}
        handleReload={handleReload}
        lang={lang}
        id={id}
        fetchDeliveryInfo={fetchDeliveryInfo}
      />
    </>
  );
}

export default ReceiveTransferDetailsContainer;
