import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { selectLoading } from 'modules/notification'
import {
  getSerialNumberActivityLog, 
  resetActivityLog
} from "modules/stockflow/redux"
import SerialNumberActivityLogPanelComponent from './serialNumberActivityLogPanel.component'

function SerialNumberActivityLogPanelContainer({
  handleClose
}) {
  const lang = useSelector(state => state.constant.languages);
  const selectedSerialNumber = useSelector(state => state.stockflowInventory.selectedSerialNumber)
  const activityLog = useSelector(state => state.stockflowInventory.activityLog)
  const isFetching = useSelector(state => selectLoading(state, getSerialNumberActivityLog.typePrefix))
  const isError = useSelector(state => state.stockflowInventory.isLoadingActivityLogError)
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      dispatch(resetActivityLog())
    }
  }, [dispatch])

  useEffect(() => {
    dispatch(getSerialNumberActivityLog(selectedSerialNumber?.serial_number))
  }, [dispatch, selectedSerialNumber])

  return (
    <SerialNumberActivityLogPanelComponent
      log={activityLog}
      serialNumber={selectedSerialNumber}
      isFetching={isFetching}
      isError={isError}
      handleClose={handleClose}
      lang={lang}
    />
  )
}

export default SerialNumberActivityLogPanelContainer