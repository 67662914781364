import React, { useState, useRef, useEffect } from "react";
import WarrantySelectComponent from "./warrantySelect.component";
import WarrantyPanelComponent from "./warrantyPanel.component";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import WarrantyFormPanel from "modules/warranty/components/panel/warrantyPanel";
import { setType, fetchWarrantyNameDropdown } from "modules/warranty/redux";
import { selectLoading } from "modules/notification";
import { WARRANTY_ADD } from "lib/constants/accessRights";
import { getLang } from "app/feature/constants";

function WarrantySelectContainer({
  setType,
  placeholder = null,
  isFetching,
  warrantyNameDropdown,
  fetchWarrantyNameDropdown,
  handleChange,
  value,
  popperPlacemenet,
  isClearable,
  handleClear
}) {
  const hasWarrantyAddAccessRight = useSelector(state => state.profile.accessRights.includes(WARRANTY_ADD))
  const [open, setOpen] = useState(false);
  const [isOpenList] = useState(false);
  const anchorRef = useRef(null);
  const prevOpen = useRef(open);
  const prevOpenList = useRef(isOpenList);
  const [isModalOpen, setModalOpen] = useState(false);
  const lang = useSelector(state => state.constant.languages);

  useEffect(() => {
    fetchWarrantyNameDropdown({ search: "" });
  }, [fetchWarrantyNameDropdown, isModalOpen]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    if (prevOpenList.current === true && isOpenList === false) {
      anchorRef.current.focus();
    }

    prevOpenList.current = isOpenList;
  }, [isOpenList]);

  function handleListKeyDown(value) {
    handleChange(value);
    setOpen(false);
  }

  const handleAddWarranty = () => {
    setType({ index: "null", type: "create" });
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      <WarrantySelectComponent
        anchorRef={anchorRef}
        handleClick={handleClick}
        isClearable={isClearable}
        handleClear={handleClear}
        value={value}
        open={open}
        placeholder={placeholder ?? getLang(lang, "placeholder.SELECT_WARRANTY")}
      />
      <WarrantyPanelComponent
        hasWarrantyAddAccessRight={hasWarrantyAddAccessRight}
        anchorRef={anchorRef}
        open={open}
        isOpenList={isOpenList}
        handleClose={handleClose}
        isFetching={isFetching}
        handleListKeyDown={handleListKeyDown}
        handleAddWarranty={handleAddWarranty}
        warrantyNameDropdown={warrantyNameDropdown}
        lang={lang}
        popperPlacemenet={popperPlacemenet}
      />
      <WarrantyFormPanel isOpen={isModalOpen} handleClose={closeModal} />
    </>
  );
}

WarrantySelectContainer.propTypes = {
  placeholder: PropTypes.string
};

const mapStateToProps = (state) => ({
  warrantyNameDropdown: state.warranty.warrantyNameDropdown,
  isFetching: selectLoading(state, fetchWarrantyNameDropdown.typePrefix)
});

const mapDispatchToProps = (dispatch) => ({
  setType: (values) => dispatch(setType(values)),
  fetchWarrantyNameDropdown: (values) =>
    dispatch(fetchWarrantyNameDropdown(values))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WarrantySelectContainer);
