import React, { useState, useRef } from "react";
import ViewEditInstantLuckyDrawDialogComponent from "./viewEditInstantLuckyDrawDialog.component";
import {
  setType,
  setRegisterForm,
  updateInstantLuckyDrawDetail,
  resetRegisterFormType,
  closeViewEditDialog
} from "modules/instant-lucky-draw/redux";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { hideBackdrop, showBackdrop } from "modules/backdrop";
import { INSTANT_LUCKY_DRAW_EDIT } from "lib/constants/accessRights";
import { getLang } from "app/feature/constants";
import { useEffect } from "react";
import { addAlert } from "modules/notification";

export default function ViewEditInstantLuckyDrawDialogContainer({
  handleClose
}) {
  const [activeIndex, setActiveIndex] = useState(0);
  const dispatch = useDispatch();
  const hasInstantLuckyDrawEditAccessRight = useSelector(state => state.profile.accessRights.includes(INSTANT_LUCKY_DRAW_EDIT))
  const isOpen = useSelector(
    (state) =>
      state.instantLuckyDraw.viewEditInstantLuckyDrawDialogSetting.isOpen
  );
  const settingType = useSelector(
    (state) =>
      state.instantLuckyDraw.viewEditInstantLuckyDrawDialogSetting.settingType
  );
  const registerType = useSelector(
    (state) =>
      state.instantLuckyDraw.viewEditInstantLuckyDrawDialogSetting.registerType
  );
  const templateType = useSelector(
    (state) =>
      state.instantLuckyDraw.viewEditInstantLuckyDrawDialogSetting.templateType
  );
  const participantRegistrationForm = useSelector(
    (state) =>
      state.instantLuckyDraw.InstantLuckyDrawDialogInfo.registrationForm
  );
  const instantLuckyDrawDetail = useSelector(
    (state) => state.instantLuckyDraw.InstantLuckyDrawDialogInfo.info
  );

  const isFormEditing = useSelector(
    (state) => state.instantLuckyDraw.settingFormEditing
  )

  const formRef = useRef();

  const lang = useSelector(state => state.constant.languages)

  const [luckyDrawImageFile, setLuckyDrawImageFile] = useState(null);
  const [tempRegForm, setTempRegForm] = useState([]);

  const tabLabels = [
    getLang(lang, 'label.INSTANT_LUCKY_DRAW_DETAILS'),
    getLang(lang, 'label.GAME_TEMPLATE'),
    getLang(lang, 'label.PARTICIPANT_REGISTRATION_FORM')
  ];

  const changeToUpdate = () => {
    setTempRegForm(participantRegistrationForm);
    if (hasInstantLuckyDrawEditAccessRight) {
      const updateInfo = {
        index: activeIndex,
        type: "edit"
      };
      dispatch(setType(updateInfo));
    }
  };

  const changeBackView = () => {
    const updateInfo = {
      index: activeIndex,
      type: "view"
    };
    dispatch(setRegisterForm(tempRegForm));
    dispatch(setType(updateInfo));
  };

  const handleChange = (event, index) => {
    setActiveIndex(index);
  };

  const handleUpdate = async () => {

    if (hasInstantLuckyDrawEditAccessRight) {

      const participantRegistrationForm = formRef.current.values.form;

      const uniqueVariantNames = new Set();

      for (const property of participantRegistrationForm) {
        if (property.variantName === undefined || property.variantName.trim() === "") {
          await dispatch(
            addAlert({
              severity: "error",
              message: getLang(lang, "message.warning.FILL_IN_REQUIRED_FIELD")
            })
          );
          dispatch(hideBackdrop());
          return;
        }

        if (uniqueVariantNames.has(property.variantName)) {
          await dispatch(
            addAlert({
              severity: "error",
              message: getLang(lang, "message.warning.DUPLICATE_VARIANT_NAME")
            })
          );
          dispatch(hideBackdrop());
          return;
        }

        uniqueVariantNames.add(property.variantName);

        if (property.type === 'multipleChoice'  || property.type==="dropdownlist" || property.type==="multipleChoiceMultiSelect") {

          for (const choices of property.choice) {
            if (choices.trim() === "" || choices === undefined) {
              await dispatch(
                addAlert({
                  severity: "error",
                  message: getLang(lang, "message.warning.CHOICES_CANNOT_BE_EMPTY")
                })
              );
              dispatch(hideBackdrop());
              return;
            }
          }

          const uniqueChoices = new Set(property.choice);
          if (uniqueChoices.size !== property.choice.length) {
            await dispatch(
              addAlert({
                severity: "error",
                message: getLang(lang, "message.warning.CHOICES_MUST_BE_UNIQUE")
              })
            );
            dispatch(hideBackdrop());
            return;
          }

          if (property.choice.length < 2) {
            await dispatch(
              addAlert({
                severity: "error",
                message: getLang(lang, "message.warning.MUST_HAVE_AT_LEAST_TWO_CHOICES")
              })
            );
            dispatch(hideBackdrop());
            return;
          }
        }

      }
      dispatch(showBackdrop());
      const update = {
        instantLuckyDrawDetail: {
          id: instantLuckyDrawDetail.uuid
        },
        metadata: participantRegistrationForm
      };
      dispatch(updateInstantLuckyDrawDetail(update))
        .then(unwrapResult)
        .then((r) => {
          const updateInfo = {
            index: activeIndex,
            type: "view"
          };
          dispatch(setType(updateInfo));
          dispatch(hideBackdrop());
        })
        .catch((error) => {
          dispatch(hideBackdrop());
        });
    }
  };

  const handleCloseDialog = () => {
    setActiveIndex(0);
    dispatch(resetRegisterFormType());
    handleClose();
  };

  useEffect(() => {
    return () => {
      closeViewEditDialog();
    }
  })

  return (
    <ViewEditInstantLuckyDrawDialogComponent
      hasInstantLuckyDrawEditAccessRight={hasInstantLuckyDrawEditAccessRight}
      handleChange={handleChange}
      activeIndex={activeIndex}
      tabLabels={tabLabels}
      settingType={settingType}
      registerType={registerType}
      templateType={templateType}
      changeBackView={changeBackView}
      changeToUpdate={changeToUpdate}
      isFormEditing={isFormEditing}
      handleUpdate={handleUpdate}
      luckyDrawImageFile={luckyDrawImageFile}
      setLuckyDrawImageFile={setLuckyDrawImageFile}
      isOpen={isOpen}
      handleClose={handleCloseDialog}
      formRef={formRef}
      instantLuckyDrawDetail={instantLuckyDrawDetail}
      lang={lang}
    />
  );
}
