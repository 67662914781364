import axios from "axios";

const url = "/serialnumber/api/v1/product/category/bulk-update-status";

export function updateCategoriesStatus({
  filter,
  update,
}) {
  return new Promise((resolve, reject) => {
    axios.post(url, {
      filter,
      update
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data))
  })
}