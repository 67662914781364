import React from "react";
import {
  makeStyles,
  Box,
  Typography,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { StyledTableCell, StyledTableRow } from "components/table";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles((theme) => ({
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    borderCollapse: "separate",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
    },
    "& th": {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightBold,
      backgroundColor: "#F2F4F7",
      padding: theme.spacing(1, 2, 1, 2),
    },
    "& td": {
      backgroundColor: "#FFFFFF",
      padding: theme.spacing(1, 2, 1, 2),
    },
    "& th:last-child, td:last-child": {
      width: 37,
      borderRight: "0px",
    },
  },
  tableContainer: {
    maxHeight: 400,
    overflowY: "auto",
    border: "1px solid #D0D5DD",
  },
  emptyContainer: {
    backgroundColor: theme.palette.background.paper,
    textAlign: "center",
  },
  removeButton: {
    color: "#F04438",
    cursor: "pointer",
  },
  selectAll: {
    "&.Mui-checked": {
      color: "#FDB022",
    },
  },
  selectOne: {
    "&.Mui-checked": {
      color: "#6AAF68",
    },
  },
}));

export default function GroupBranchTableComponent({ rows, lang }) {
  const classes = useStyle();

  return (
    <>
      {rows.length > 0 ? (
        <>
          <TableContainer className={classes.tableContainer}>
            <Table
              className={classes.table}
              aria-label="product table"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell width="100%">
                    <Box display="flex" alignItems="center">
                      <Typography variant="body2">
                        <b>{getLang(lang, "label.BRANCH_NAME")}</b>
                      </Typography>
                    </Box>
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => {
                  return (
                    <StyledTableRow
                      key={index}
                      hover
                      role="checkbox"
                      tabIndex={-1}
                    >
                      <StyledTableCell width="100%">
                        <Box display="flex" alignItems="center">
                          <Typography variant="body2">{row.name}</Typography>
                        </Box>
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : null}
      {rows.length === 0 && (
        <Box>
          <TableContainer className={classes.tableContainer}>
            <Table
              className={classes.table}
              aria-label="product table"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell>
                    {getLang(lang, "label.NAME")}
                  </StyledTableCell>
                </TableRow>
              </TableHead>
            </Table>
            <Box py={4} className={classes.emptyContainer}>
              <Typography variant="body1">
                {getLang(lang, "message.info.NO_BRANCH_EXIST")}
              </Typography>
            </Box>
          </TableContainer>
        </Box>
      )}
    </>
  );
}
