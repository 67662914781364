import React from "react";
import {
  makeStyles,
  Box,
  Typography,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
} from "@material-ui/core";
import { StyledTableCell, StyledTableRow } from "components/table";
import Skeleton from "@material-ui/lab/Skeleton";
import { TablePaginationActions } from "components/tablePagination";
import Alert from "@material-ui/lab/Alert";
import ActionButton from "components/button/actionDetailButton";
import RefreshTable from "components/table/refreshTable";
import { getLang } from "app/feature/constants";
import StickyLeftTableCell from "components/table/stickyLeftTableCell";
import StickyRightTableCell from "components/table/stickyRightTableCell";
import { KeyboardArrowDown } from "@material-ui/icons";
import moment from "moment";
import "moment/min/locales.min";

const useStyle = makeStyles((theme) => ({
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    borderCollapse: "separate",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
    },
    "& th,td": {
      fontSize: ".75rem",
      borderRight: "1px solid rgba(58, 77, 84, 0.1)",
      padding: theme.spacing(1, 2, 1, 2),
    },
    "& th": {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightBold,
    },
    "& th:last-child, td:last-child": {
      width: 37,
      borderRight: "0px",
    },
    "& th th": {
      borderBottom: "none !important",
    },
    "& td td": {
      borderBottom: "none !important",
    },
  },
  emptyContainer: {
    backgroundColor: theme.palette.background.paper,
    textAlign: "center",
  },
  attributeText: {
    color: "#b2b2b2",
    fontSize: 12,
    whiteSpace: "break-spaces",
  },
}));

function statusLabel(status, lang) {
  switch (status) {
    case "pre-register":
      return (
        <Typography
          variant="caption"
          style={{ color: "#9e9e9e", fontWeight: "bold" }}
        >
          {getLang(lang, "label.PRE_REGISTERED")}
        </Typography>
      );

    case "approved":
      return (
        <Typography
          variant="caption"
          style={{ color: "#92C892", fontWeight: "bold" }}
        >
          {getLang(lang, "label.APPROVED")}
        </Typography>
      );

    case "processing":
      return (
        <Typography
          variant="caption"
          style={{ color: "#F6C000", fontWeight: "bold" }}
        >
          {getLang(lang, "label.PROCESSING_WORD")}
        </Typography>
      );

    case "voided":
      return (
        <Typography
          variant="caption"
          style={{ color: "#3A4D54", fontWeight: "bold" }}
        >
          {getLang(lang, "label.VOID")}
        </Typography>
      );

    case "rejected":
      return (
        <Typography
          variant="caption"
          style={{ color: "#FD646F", fontWeight: "bold" }}
        >
          {getLang(lang, "label.REJECTED")}
        </Typography>
      );

    case "claimed":
      return (
        <Typography
          variant="caption"
          style={{ color: "#3A4D54", fontWeight: "bold" }}
        >
          {getLang(lang, "label.CLAIMED")}
        </Typography>
      );

    default:
      break;
  }
}

export default function WarranteeListTableComponent({
  hasWarrantyEditAccessRight,
  rows,
  isFetching,
  page,
  rowsPerPage,
  handlePageChange,
  handleRowPerPageChange,
  length,
  search,
  handleView,
  handleReload,
  isLoadingWarrantyParticipantError,
  paginationOptions,
  lang,
  tableFilter,
  language,
  changeOrder,
}) {
  const classes = useStyle();

  if (language === "ZH") {
    moment.locale("zh-cn");
  } else if (language === "TL") {
    moment.locale("tl-ph");
  } else {
    moment.locale(language);
  }
  return (
    <>
      {rows.length > 0 || isFetching ? (
        <TableContainer>
          <Table
            className={classes.table}
            size="small"
            aria-label="product table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <StickyLeftTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.NUM")}
                  </StyledTableCell>
                </StickyLeftTableCell>
                <StyledTableCell>
                  <TableSortLabel
                    active={tableFilter.column === "warrantee_name"}
                    direction={tableFilter.order}
                    onClick={() => {
                      const differentTab =
                        tableFilter.column !== "warrantee_name";
                      const order = differentTab
                        ? tableFilter.order
                        : tableFilter.order === "asc"
                        ? "desc"
                        : "asc";
                      changeOrder("warrantee_name", order);
                    }}
                    IconComponent={KeyboardArrowDown}
                  >
                    {getLang(lang, "label.WARRANTEE_NAME")}
                  </TableSortLabel>
                </StyledTableCell>
                <StyledTableCell>
                  <TableSortLabel
                    active={tableFilter.column === "created_at"}
                    direction={tableFilter.order}
                    onClick={() => {
                      const differentTab = tableFilter.column !== "created_at";
                      const order = differentTab
                        ? tableFilter.order
                        : tableFilter.order === "asc"
                        ? "desc"
                        : "asc";
                      changeOrder("created_at", order);
                    }}
                    IconComponent={KeyboardArrowDown}
                  >
                    {getLang(lang, "label.REGISTRATION_DATE")}
                  </TableSortLabel>
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.STATUS")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.SERIAL_NUMBER")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.PRODUCT")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.WARRANTY_STATUS")}
                </StyledTableCell>
                <StickyRightTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.ACTION")}
                  </StyledTableCell>
                </StickyRightTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isFetching ? (
                <>
                  <SkeletonTableRow />
                  <SkeletonTableRow />
                  <SkeletonTableRow />
                  <SkeletonTableRow />
                  <SkeletonTableRow />
                </>
              ) : (
                rows.map((row, index) => {
                  const actionItems = [
                    {
                      label: hasWarrantyEditAccessRight
                        ? getLang(lang, "label.VIEW_EDIT")
                        : getLang(lang, "label.VIEW"),
                      action: () => handleView(row.id),
                    },
                  ];

                  return (
                    <StyledTableRow
                      key={index}
                      hover
                      role="checkbox"
                      tabIndex={-1}
                    >
                      <StickyLeftTableCell>
                        <StyledTableCell
                          component="th"
                          scope="row"
                          style={{ borderBottom: "0px" }}
                        >
                          {index + 1 + rowsPerPage * page}
                        </StyledTableCell>
                      </StickyLeftTableCell>
                      <StyledTableCell>
                        {row.metadata.map((data, index) => {
                          return (
                            <div key={index}>
                              {data.key === "name"
                                ? !!data.value
                                  ? data.value
                                  : "-"
                                : null}
                            </div>
                          );
                        })}
                      </StyledTableCell>
                      <StyledTableCell>
                        {moment(row.createdAt).format("lll")}
                      </StyledTableCell>
                      <StyledTableCell>
                        {statusLabel(row.status, lang)}
                      </StyledTableCell>
                      <StyledTableCell>{row.serialNumber}</StyledTableCell>
                      <StyledTableCell>
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          alignItems="flex-start"
                        >
                          <Typography variant="body2" style={{ fontSize: 12 }}>
                            {row.productName}
                          </Typography>
                          {!!row.attributes && !!row.attributes.length && (
                            <Typography
                              className={classes.attributeText}
                              variant="body2"
                            >
                              {row.attributes.join(", ")}
                            </Typography>
                          )}
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell>
                        {row.verified === "Yes"
                          ? getLang(lang, "label.ACTIVE")
                          : getLang(lang, "label.EXPIRED")}
                      </StyledTableCell>
                      <StickyRightTableCell>
                        <StyledTableCell>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <ActionButton
                              actionItems={actionItems}
                              popperStyle={{ zIndex: 999 }}
                            />
                          </div>
                        </StyledTableCell>
                      </StickyRightTableCell>
                    </StyledTableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
      ) : !search && !isLoadingWarrantyParticipantError ? (
        <Box>
          <Table
            className={classes.table}
            size="small"
            aria-label="product table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <StyledTableCell>{getLang(lang, "label.NUM")}</StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.WARRANTEE_NAME")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.SERIAL_NUMBER")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.PRODUCT")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.PERIOD")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.VALIDITY_DATE")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.WARRANTY_STATUS")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.STATUS")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.ACTION")}
                </StyledTableCell>
              </TableRow>
            </TableHead>
          </Table>
          <Box py={9} className={classes.emptyContainer}>
            <Typography variant="body1">
              {getLang(lang, "paragraph.NO_CONSUMER")}
            </Typography>
          </Box>
        </Box>
      ) : null}

      {!isFetching && rows.length === 0 && search && (
        <Alert severity="info">
          {getLang(lang, "label.NO_WARRANTEE_LIST")}
        </Alert>
      )}

      {!isFetching && isLoadingWarrantyParticipantError && (
        <RefreshTable handleReload={handleReload} />
      )}

      {!isFetching && rows.length > 0 && (
        <TablePagination
          style={{
            backgroundColor: "#fff",
            boxShadow: "0px -1px 0px rgba(58, 77, 84, 0.15)",
          }}
          ActionsComponent={TablePaginationActions}
          rowsPerPageOptions={paginationOptions}
          component="div"
          count={length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowPerPageChange}
          labelRowsPerPage={getLang(lang, "label.ROWS_PER_PAGE")}
          labelDisplayedRows={({ from, to, count }) =>
            `${from.toLocaleString("en-US")}-${to.toLocaleString(
              "en-US"
            )} ${getLang(lang, "label.OF")} ${count.toLocaleString("en-US")}`
          }
        />
      )}
    </>
  );
}

function SkeletonTableRow() {
  return (
    <StyledTableRow>
      {[1, 2, 3, 4, 5, 6, 7, 8].map((id) => (
        <StyledTableCell key={id}>
          <Skeleton variant="text" animation="wave" />
        </StyledTableCell>
      ))}
    </StyledTableRow>
  );
}
