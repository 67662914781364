import React from "react";
import ProfileHeaderComponent from "./profileHeader.component";
import { connect, useSelector } from "react-redux";

function ProfileHeaderContainer({ profileData }) {
  const lang = useSelector(state => state.constant.languages)
  const language = useSelector(state => state.profile.language)
  return <ProfileHeaderComponent profileData={profileData} lang={lang} language={language} />;
}

const mapStateToProps = (state) => ({
  profileData: state.profile
});

export default connect(mapStateToProps)(ProfileHeaderContainer);
