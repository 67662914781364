import React, { useState } from "react";
import SingleCheckoutBoxAccordionComponent from "./singleCheckoutBoxAccordion.component";

export default function SingleCheckoutBoxAccordionContainer({
  box,
  selectViewItem,
  selectedBox,
  lang,
  id,
}) {
  const [selectedItem, setSelectedItem] = useState(null);

  const viewItem = (item, type, boxUuid) => {
    setSelectedItem(
      item
        ? {
            ...item,
            type,
            box_uuid: boxUuid ?? null,
          }
        : null
    );
  };

  return (
    <SingleCheckoutBoxAccordionComponent
      box={box}
      lang={lang}
      handleExpand={selectViewItem}
      selectedBox={selectedBox}
      viewItem={viewItem}
      selectedItem={selectedItem}
      id={id}
    />
  );
}
