import { setLoading } from "modules/notification";
import { getFallbackApiLanguage, getFallbackLanguage } from "app/feature/constants";

export const getFallbackLanguageMiddleware = ({ dispatch, getState }) => next => action => {
  next(action)

  const { type } = action;

  switch (type) {
    case getFallbackLanguage.pending.type:
      dispatch(setLoading({ id: getFallbackLanguage.typePrefix, state: true }));
      break;
    case getFallbackLanguage.fulfilled.type:
      dispatch(setLoading({ id: getFallbackLanguage.typePrefix, state: false }));
      break;
    case getFallbackLanguage.rejected.type:
      dispatch(setLoading({ id: getFallbackLanguage.typePrefix, state: false }));
      break;
    default:
      break;
  }
};

export const getFallbackApiLanguageMiddleware = ({ dispatch, getState }) => next => action => {
  next(action)

  const { type } = action;

  switch (type) {
    case getFallbackApiLanguage.pending.type:
      dispatch(setLoading({ id: getFallbackApiLanguage.typePrefix, state: true }));
      break;
    case getFallbackApiLanguage.fulfilled.type:
      dispatch(setLoading({ id: getFallbackApiLanguage.typePrefix, state: false }));
      break;
    case getFallbackApiLanguage.rejected.type:
      dispatch(setLoading({ id: getFallbackApiLanguage.typePrefix, state: false }));
      break;
    default:
      break;
  }
};


export default [
  getFallbackLanguageMiddleware
];