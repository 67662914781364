import React from 'react'
import {
    Box,
    Button,
    Divider,
    makeStyles,
    Paper,
    Typography
} from '@material-ui/core'
import {
    Info as InfoIcon,
    ArrowForwardRounded as ArrowForwardRoundedIcon
} from '@material-ui/icons'
import { surveyTemplateType } from 'modules/survey/constants'
import { getLang } from 'app/feature/constants'

const useStyle = makeStyles(() => ({
    smallBoldText: {
        fontSize: 14,
        fontWeight: "bold"
    },
    remarksCaption: {
        marginLeft: 10,
        fontSize: 12
    },
    button: {
        width: "100%",
        padding: 20,
        textTransform: "capitalize",
        fontSize: 14,
        fontWeight: "bold",
        borderRadius: 0
    },
    buttonLabel: {
        justifyContent: "flex-start"
    },
    buttonText: {
        marginRight: "auto",
        textTransform: "capitalize",
        fontSize: 14,
        fontWeight: "bold",
        textAlign: "left"
    }
}))


export default function SurveyTemplatePanelComponent({ handleCreate, lang }) {
    const classes = useStyle()
    const templates = [
        { name: getLang(lang, 'label.PRODUCT_OR_SERVICE_FEEDBACK'), type: surveyTemplateType.feedback },
        { name: getLang(lang, 'label.FOLLOW_UP_SURVEY_TEMPLATE'), type: surveyTemplateType.followUp },
        { name: getLang(lang, 'label.MARKETING_SURVEY_TEMPLATE'), type: surveyTemplateType.marketing }
    ]
    
    const TemplateButton = ({ name, type }) => (
        <Button
            classes={{
                root: classes.button,
                label: classes.buttonLabel
            }}
            onClick={() => {
                handleCreate(type)
            }}
        >
            <Typography className={classes.buttonText} color="primary">{name}</Typography>
            <ArrowForwardRoundedIcon color="primary" />
        </Button>
    )

    return (
        <Paper elevation={0}>
            <Box p={2}>
                <Typography
                    className={classes.smallBoldText}
                    style={{
                        marginBottom: 5
                    }}
                    color="primary"
                >
                    {getLang(lang, 'paragraph.START_NEW_SURVEY')}
                </Typography>
                <Box
                    display='flex'
                >
                    <InfoIcon color="primary" fontSize="small" />
                    <Typography
                        className={classes.remarksCaption}
                        color="primary"
                    >
                        {getLang(lang, 'paragraph.USE_OR_EDIT_PRESET_TEMPLATE')}
                    </Typography>
                </Box>
            </Box>
            <Divider />
            {templates.map((item, i) => (
                <Box key={i}>
                    <TemplateButton
                        name={item.name}
                        type={item.type}
                    />
                    <Divider />
                </Box>
            ))}
            <TemplateButton
                name={getLang(lang, 'label.CREATE_NEW_SURVEY')}
                type=""
            />
        </Paper>
    )
}
