import axios from "axios";

const url = "/analytic/api/v1/drilldown/get-filter-selection";

export function getFilterCountryCityData() {
  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        if (error?.response?.data) {
          reject(error.response.data);
        } else {
          reject(error);
        }
      });
  });
}

export default getFilterCountryCityData;
