import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import CreateInstantLuckyDrawDialogComponent from "./createInstantLuckyDrawDialog.component";
import { DetailSVG, TemplateSVG, RegFormSVG } from "../../../utils/static";
import {
  InstantLuckyDrawForm,
  InstantLuckyDrawTemplateForm,
  InstantLuckyDrawRegForm,
} from "../../form";
import { FolderType, UploadType } from "lib/constants/aws_s3";
import { uploadJSONToS3, uploadFileToS3, uploadImageToS3 } from "app/feature";
import { addAlert } from "modules/notification";
import { hideBackdrop, showBackdrop } from "modules/backdrop";
import { unwrapResult } from "@reduxjs/toolkit";
import { toBase64 } from "modules/product-mgmt/utils/productPage.util";
import { v4 as uuidv4 } from "uuid";
import ConfirmationDialogComponent from "components/dialog/confirmation";
import InstantPrizeDialog from "../instant-prize-dialog";
import {
  createInstantLuckyDraw,
  closeCreationDialog,
  nextStep,
  backStep,
  resetRegisterFormType,
  openPrizeDialogV2,
  fetchAllInstantPrize,
} from "modules/instant-lucky-draw/redux";
import { INSTANT_LUCKY_DRAW_EDIT } from "lib/constants/accessRights";
import { getLang } from "app/feature/constants";
import { setImgFormatAndSize } from "../../../../../lib/FileHelper";
import InstantPrizeDialogV2 from "../instant-prize-dialog-v2";

export default function CreateInstantLuckyDrawDialogContainer() {
  const activeStep = useSelector(
    (state) => state.instantLuckyDraw.createInstantLuckyDrawDialogSetting.step
  );
  const isOpen = useSelector(
    (state) => state.instantLuckyDraw.createInstantLuckyDrawDialogSetting.isOpen
  );
  const instantLuckyDrawDetailForm = useSelector(
    (state) => state.instantLuckyDraw.InstantLuckyDrawDialogInfo.info
  );
  const lang = useSelector((state) => state.constant.languages);
  const hasInstantLuckyDrawEditAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(INSTANT_LUCKY_DRAW_EDIT)
  );
  const id = useSelector((state) => state.session.idTokenPayload.sub);
  const dispatch = useDispatch();
  const [luckyDrawImageFile, setLuckyDrawImageFile] = useState(null);
  const [confirmationDialogModal, setConfirmationDialogModal] = useState(false);
  const [termConditionFile, setTermConditionFile] = useState(null);
  const formRef = useRef();

  const createdIldRef = useRef();

  const steps = [
    {
      form: InstantLuckyDrawForm,
      image: DetailSVG,
      label: getLang(lang, "label.INSTANT_LUCKY_DRAW_DETAILS"),
    },
    {
      form: InstantLuckyDrawTemplateForm,
      image: TemplateSVG,
      label: getLang(lang, "label.SELECT_GAME_TEMPLATE"),
    },
    {
      form: InstantLuckyDrawRegForm,
      image: RegFormSVG,
      label: getLang(lang, "label.REGISTRATION_FORM"),
    },
  ];

  const createInstantLuckyDrawProcess = async (url) => {
    const fileName = `${uuidv4()}.webp`;
    let base64Image = await toBase64(luckyDrawImageFile);
    base64Image = await setImgFormatAndSize(
      base64Image,
      2160,
      2160,
      "image/webp"
    );

    const uploadLuckyDrawImageData = {
      uploadType: FolderType.instantLuckyDrawImage,
      base64Image: base64Image,
      id: id,
      fileName: fileName,
    };

    formRef.current.handleSubmit();

    const participantRegistrationForm = formRef.current.values.form;

    const uniqueVariantNames = new Set();

    for (const property of participantRegistrationForm) {
      if (
        property.variantName === undefined ||
        property.variantName.trim() === ""
      ) {
        await dispatch(
          addAlert({
            severity: "error",
            message: getLang(lang, "message.warning.FILL_IN_REQUIRED_FIELD"),
          })
        );
        dispatch(hideBackdrop());
        return;
      }

      if (uniqueVariantNames.has(property.variantName)) {
        await dispatch(
          addAlert({
            severity: "error",
            message: getLang(lang, "message.warning.DUPLICATE_VARIANT_NAME"),
          })
        );
        dispatch(hideBackdrop());
        return;
      }

      uniqueVariantNames.add(property.variantName);

      if (
        property.type === "multipleChoice" ||
        property.type === "dropdownlist" ||
        property.type === "multipleChoiceMultiSelect"
      ) {
        for (const choices of property.choice) {
          if (choices.trim() === "" || choices === undefined) {
            await dispatch(
              addAlert({
                severity: "error",
                message: getLang(
                  lang,
                  "message.warning.CHOICES_CANNOT_BE_EMPTY"
                ),
              })
            );
            dispatch(hideBackdrop());
            return;
          }
        }

        const uniqueChoices = new Set(property.choice);
        if (uniqueChoices.size !== property.choice.length) {
          await dispatch(
            addAlert({
              severity: "error",
              message: getLang(lang, "message.warning.CHOICES_MUST_BE_UNIQUE"),
            })
          );
          dispatch(hideBackdrop());
          return;
        }

        if (property.choice.length < 2) {
          await dispatch(
            addAlert({
              severity: "error",
              message: getLang(
                lang,
                "message.warning.MUST_HAVE_AT_LEAST_TWO_CHOICES"
              ),
            })
          );
          dispatch(hideBackdrop());
          return;
        }
      }
    }

    const formdata = formRef.current.values.form;

    dispatch(uploadImageToS3(uploadLuckyDrawImageData))
      .then(unwrapResult)
      .then(async (imageUrl) => {
        const productId = [];
        for (const product of instantLuckyDrawDetailForm.productIds) {
          productId.push(product.id);
        }
        const info = {
          instantLuckyDrawDetail: {
            ...instantLuckyDrawDetailForm,
            termsAndConditions: url,
            image: imageUrl,
          },
          productId: productId,
          instantLuckyDrawRegister: formdata,
        };

        dispatch(createInstantLuckyDraw(info))
          .then(unwrapResult)
          .then(async (response) => {
            const fetchPrizeTableInfo = {
              type: "dialog",
              instantLuckyDrawId: response.data.uuid,
              isWinnerShow: 1,
            };

            await dispatch(fetchAllInstantPrize(fetchPrizeTableInfo)).then(
              unwrapResult
            );

            createdIldRef.current = response.data;
            dispatch(hideBackdrop());
            if (hasInstantLuckyDrawEditAccessRight) {
              setConfirmationDialogModal(true);
            } else {
              handleClose();
            }
          })
          .catch((error) => {
            dispatch(hideBackdrop());
          });
      })
      .catch((error) => {
        dispatch(hideBackdrop());
        dispatch(
          addAlert({
            severity: "error",
            message:
              error.message ||
              getLang(lang, "message.error.ERROR_UPLOAD_IMAGES"),
          })
        );
      });
  };

  const handleNext = async () => {
    // let registrationForm = [
    //   {
    //     fieldName: "name",
    //     labelType: "Text Field",
    //     type: "textField",
    //     mandatory: true,
    //     visible: true,
    //     enableDelete: false,
    //     editing: false,
    //     fieldType: "standard",
    //     variantName: "Name",
    //     choice: []
    //   },
    //   {
    //     fieldName: "address",
    //     labelType: "Text Field",
    //     type: "textField",
    //     mandatory: true,
    //     visible: true,
    //     enableDelete: false,
    //     editing: false,
    //     fieldType: "standard",
    //     variantName: "Address",
    //     choice: []
    //   },
    //   {
    //     fieldName: "email",
    //     labelType: "Text Field",
    //     type: "textField",
    //     mandatory: true,
    //     visible: true,
    //     enableDelete: false,
    //     editing: false,
    //     fieldType: "standard",
    //     variantName: "Email",
    //     choice: []
    //   },
    //   {
    //     fieldName: "gender",
    //     labelType: "Multiple Choice",
    //     type: "multipleChoice",
    //     mandatory: true,
    //     visible: true,
    //     enableDelete: false,
    //     editing: false,
    //     fieldType: "standard",
    //     variantName: "Gender",
    //     choice: ["Male", "Female"]
    //   },
    //   {
    //     fieldName: "contactNumber",
    //     labelType: "Contact Number",
    //     type: "contactNumber",
    //     mandatory: true,
    //     visible: true,
    //     enableDelete: false,
    //     editing: false,
    //     fieldType: "standard",
    //     variantName: "Contact Number",
    //     choice: []
    //   },
    //   {
    //     fieldName: "ic",
    //     labelType: "Text Field",
    //     type: "textField",
    //     mandatory: true,
    //     visible: true,
    //     enableDelete: false,
    //     editing: false,
    //     fieldType: "standard",
    //     variantName: "IC Number",
    //     choice: []
    //   }
    // ]
    if (activeStep === 2) {
      dispatch(showBackdrop());

      if (instantLuckyDrawDetailForm.termsConditionText) {
        const termAndConditionFileName = "JsonFile";

        const uploadJSONdata = {
          uploadType: UploadType.instantLuckyDrawTermAndCondition,
          data: instantLuckyDrawDetailForm.termsConditionDetail,
          id: id,
          fileName: `${Date.now()}${termAndConditionFileName}`,
        };

        dispatch(uploadJSONToS3(uploadJSONdata))
          .then(unwrapResult)
          .then(async (jsonUrl) => {
            await createInstantLuckyDrawProcess(jsonUrl);
          })
          .catch((error) => {
            dispatch(hideBackdrop());
            dispatch(
              addAlert({
                severity: "error",
                message:
                  error.message ||
                  getLang(lang, "message.error.ERROR_UPLOAD_FILE"),
              })
            );
          });
      } else {
        const termAndConditionFileName = termConditionFile.name;
        const uploadPdfData = {
          uploadType: UploadType.instantLuckyDrawTermAndCondition,
          file: termConditionFile,
          id: id,
          fileName: `${termAndConditionFileName}_${Date.now()}${termAndConditionFileName}`,
        };

        dispatch(uploadFileToS3(uploadPdfData))
          .then(unwrapResult)
          .then(async (termUrl) => {
            await createInstantLuckyDrawProcess(termUrl);
          })
          .catch((error) => {
            dispatch(hideBackdrop());
            dispatch(
              addAlert({
                severity: "error",
                message:
                  error.message ||
                  getLang(lang, "message.error.FAILED_UPLOAD_TNC_FILE"),
              })
            );
          });
      }
    } else dispatch(nextStep());
  };

  const handleProceed = () => {
    setConfirmationDialogModal(false);
    dispatch(closeCreationDialog());
    dispatch(
      openPrizeDialogV2({
        type: "create",
        instantLuckyDraw: {
          ...createdIldRef.current,
          id: createdIldRef.current?.uuid,
        },
      })
    );
  };

  const handleConfirmClose = () => {
    setConfirmationDialogModal(false);
    dispatch(closeCreationDialog());
  };

  const handleBack = () => {
    dispatch(backStep());
  };

  const handleClose = () => {
    dispatch(closeCreationDialog());
    dispatch(resetRegisterFormType());
    setLuckyDrawImageFile(null);
  };

  return (
    <>
      <CreateInstantLuckyDrawDialogComponent
        steps={steps}
        activeStep={activeStep}
        handleNext={handleNext}
        handleBack={handleBack}
        isOpen={isOpen}
        termConditionFile={termConditionFile}
        setTermConditionFile={setTermConditionFile}
        luckyDrawImageFile={luckyDrawImageFile}
        setLuckyDrawImageFile={setLuckyDrawImageFile}
        formRef={formRef}
        handleClose={handleClose}
        lang={lang}
      />
      <InstantPrizeDialog />
      <InstantPrizeDialogV2 />
      <ConfirmationDialogComponent
        isOpen={confirmationDialogModal}
        handleClose={handleConfirmClose}
        handleProceed={handleProceed}
        type={"success"}
        description={getLang(lang, "paragraph.ADD_PRIZE_QUESTION_ILD")}
        title={getLang(lang, "label.INSTANT_LUCKY_DRAW_ADDED")}
        remarks={getLang(lang, "paragraph.ADD_PRIZE_REMARK")}
        proceedLabel={getLang(lang, "label.ADD_PRIZE")}
        rejectLabel={getLang(lang, "label.DO_IT_LATER")}
      />
    </>
  );
}
