import React from "react";
import {
  makeStyles,
  Box,
  Grid,
  Typography,
  Button,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  IconButton
} from "@material-ui/core";
import CustomTextField from "components/textField";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { getLang } from "app/feature/constants";

const useStyle = makeStyles(theme => ({
  root: {
    width: "70%",
    [theme.breakpoints.down("sm")]: {
      width: "80%"
    },
    paddingTop: theme.spacing(4)
  },
  button: {
    [theme.breakpoints.up("md")]: {
      size: "large"
    },
    [theme.breakpoints.down("sm")]: {
      size: "medium"
    }
  },
  signed: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "smaller"
    }
  },
  input: {
    backgroundColor: theme.palette.common.white
  }
}));

export default function LoginFormComponent({
  handleLogin,
  isLoading,
  lang,
  handleClickShowPassword,
  handleMouseDownPassword,
  isPasswordShown
}) {
  const classes = useStyle();

  const textFieldProps = {
    fullWidth: true,
    variant: "outlined",
    size: "small",
    margin: "dense"
  };

  return (
    <Box className={classes.root}>
      <Typography variant="body1" style={{ fontWeight: "bold" }}>
        {getLang(lang, "label.LOGIN")}
      </Typography>

      <Formik
        initialValues={{ username: "", password: "", isStaySignedIn: true }}
        validationSchema={Yup.object({
          username: Yup.string().trim().required(getLang(lang,"message.error.FORM_VALIDATE_REQUIRED")),
          password: Yup.string().required(getLang(lang,"message.error.FORM_VALIDATE_REQUIRED")),
          isStaySignedIn: Yup.boolean()
        })}
        onSubmit={handleLogin}
      >
        {formik => (
          <form onSubmit={formik.handleSubmit}>
            <CustomTextField
              {...textFieldProps}
              {...formik.getFieldProps("username")}
              label={<><span>{getLang(lang, "label.USERNAME_EMAIL")} </span><span style={{ color: "#DE350B", display: 'inline-block' }}>*</span></>}
              placeholder={getLang(lang, "placeholder.USERNAME_EMAIL")}
              error={
                formik.touched.username && formik.errors.username ? true : false
              }
              helperText={ErrorMessage({ name: "username" })}
              disabled={formik.isSubmitting || isLoading}
            />

            <CustomTextField
              {...textFieldProps}
              {...formik.getFieldProps("password")}
              label={<><span>{getLang(lang, "label.PASSWORD")} </span><span style={{ color: "#DE350B", display: 'inline-block' }}>*</span></>}
              placeholder={getLang(lang, "placeholder.PASSWORD")}
              type={isPasswordShown ? "text" : "password"}
              error={
                formik.touched.password && formik.errors.password ? true : false
              }
              helperText={ErrorMessage({ name: "password" })}
              disabled={formik.isSubmitting || isLoading}
              InputProps={{
                classes: {
                  input: classes.input
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {isPasswordShown ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />

            <Box mt={5}>
              <Grid container>
                <Grid
                  item
                  xs={7}
                  sm={6}
                  md={6}
                  style={{ width: "100%", alignSelf: "center" }}
                >
                  <FormControlLabel
                    disabled={formik.isSubmitting || isLoading}
                    control={
                      <Checkbox
                        color="secondary"
                        {...formik.getFieldProps("isStaySignedIn")}
                        checked={formik.values.isStaySignedIn}
                      />
                    }
                    label={
                      <Typography variant="body2" className={classes.signed}>
                        {getLang(lang, "label.STAY_LOGGED_IN")}
                      </Typography>
                    }
                  />
                </Grid>
                <Grid
                  item
                  xs={5}
                  sm={6}
                  md={6}
                  style={{ width: "100%", textAlign: "end" }}
                >
                  <Button
                    variant="contained"
                    type="submit"
                    color="secondary"
                    disabled={formik.isSubmitting || isLoading}
                    className={classes.button}
                  >
                    {getLang(lang, "label.LOGIN")}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
}
