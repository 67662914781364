import React, { useState } from "react";
import CardFormComponent from "./cardForm.component";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import { checkoutPurchase, resetCreditPurchase } from "../../../redux";
import usePaymentMethod from "modules/billing/utils/usePaymentMethod.hook";
import { selectLoading } from "modules/notification";
import { ErrorPanel } from "../../panel/error";
import CheckoutLoading from "./checkoutLoading";
import { unwrapResult } from "@reduxjs/toolkit";
import SuccessCheckout from "./successCheckout";
import { useHistory } from "react-router-dom";
import { ROOT as BILLING_ROOT } from "modules/billing";

function CardFormContainer({
  children,
  hasSelectedCard,
  checkoutPurchase,
  isCheckingOut,
  handleCancel,
  handleReset,
  handleNext,
  quantity,
  resetCreditPurchase
}) {
  const [
    paymentMethods,
    isFetchingPaymentMethod,
    errorPaymentMethod,
    reload
  ] = usePaymentMethod();
  const [purchaseSuccessful, setPurchaseSuccessful] = useState(false);
  const [postPaymentData, setPostPaymentData] = useState(null);
  const history = useHistory();
  const lang = useSelector(state => state.constant.languages);

  const handlePurchase = async e => {
    e.preventDefault();
    checkoutPurchase()
      .then(unwrapResult)
      .then(response => {
        if (response.success) {
          setPostPaymentData({ quantity, ...response.data });
          setPurchaseSuccessful(true);
        }
      });
  };

  const handleAddNewCardSuccess = () => {
    reload();
  };

  const handleStartNewPurchase = () => {
    resetCreditPurchase();
    handleReset();
  };

  const handleClosePurchaseSuccess = () => {
    resetCreditPurchase();
    handleCancel();
  };

  const billingPage = () => {
    history.push(BILLING_ROOT);
  }

  if (isCheckingOut) return <CheckoutLoading lang={lang}/>;

  if (purchaseSuccessful)
    return (
      <SuccessCheckout
        handleClickOk={handleClosePurchaseSuccess}
        handleReset={handleStartNewPurchase}
        postPaymentData={postPaymentData}
        billingPage={billingPage}
        lang={lang}
      />
    );

  if (!isFetchingPaymentMethod && errorPaymentMethod)
    return <ErrorPanel handleClick={reload} />;

  return (
    <CardFormComponent
      children={children}
      isFetching={isFetchingPaymentMethod}
      paymentMethods={paymentMethods}
      hasSelectedCard={hasSelectedCard}
      handlePurchase={handlePurchase}
      handleAddNewCardSuccess={handleAddNewCardSuccess}
      handleReloadPaymentMethod={reload}
      lang={lang}
    />
  );
}

CardFormContainer.propTypes = {
  handleNext: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  quantity: state.marketplace.qrCode.quantity,
  hasSelectedCard: state.marketplace.selectedPaymentCardId !== "",
  isCheckingOut: selectLoading(state, checkoutPurchase.typePrefix)
});

const mapDispatchToProps = dispatch => ({
  resetCreditPurchase: () => dispatch(resetCreditPurchase()),
  checkoutPurchase: () => dispatch(checkoutPurchase("serial_number"))
});

export default connect(mapStateToProps, mapDispatchToProps)(CardFormContainer);
