import React, { useEffect } from "react";
import ClusterScanDetailDialogComponent from "./clusterScanDetailDialog.component";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchClusterConsumerScannedSummary,
  fetchClusterCounterfeitReportSummary,
  fetchClusterFirstScanSummary,
  fetchClusterSuspendSnSummary,
  fetchClusterUniqueConsumerScannedSummary,
} from "modules/dashboard/redux/action/dashboard.action";
import { selectLoading } from "modules/notification";

function ClusterScanDetailDialogContainer({
  isOpen,
  handleClose,
  clusterBound,
  startDate,
  endDate,
  handleClusterClick,
  handleMarkerClick,
}) {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.constant.languages);
  const language = useSelector((state) => state.profile.language);
  const clusterDetail = useSelector((state) => state.dashboard.clusterDetail);

  const isConsumerScannedSummaryLoading = useSelector((state) =>
    selectLoading(state, fetchClusterConsumerScannedSummary.typePrefix)
  );
  const isUniqueConsumerSummaryLoading = useSelector((state) =>
    selectLoading(state, fetchClusterUniqueConsumerScannedSummary.typePrefix)
  );
  const isFirstScanSummaryLoading = useSelector((state) =>
    selectLoading(state, fetchClusterFirstScanSummary.typePrefix)
  );
  const isSuspendSNSummaryLoading = useSelector((state) =>
    selectLoading(state, fetchClusterSuspendSnSummary.typePrefix)
  );
  const isCounterfeitSummaryLoading = useSelector((state) =>
    selectLoading(state, fetchClusterCounterfeitReportSummary.typePrefix)
  );

  useEffect(() => {
    if (clusterBound) {
      let param = {
        start_date: startDate,
        end_date: endDate,
        latitude: [clusterBound.south, clusterBound.north],
        longitude: [clusterBound.west, clusterBound.east],
      };
      dispatch(fetchClusterConsumerScannedSummary(param));
      dispatch(fetchClusterUniqueConsumerScannedSummary(param));
      dispatch(fetchClusterFirstScanSummary(param));
      dispatch(fetchClusterSuspendSnSummary(param));
      dispatch(fetchClusterCounterfeitReportSummary(param));
    }
  }, [startDate, endDate, clusterBound, dispatch]);

  return (
    <ClusterScanDetailDialogComponent
      isOpen={isOpen}
      handleClose={handleClose}
      lang={lang}
      handleClusterClick={handleClusterClick}
      handleMarkerClick={handleMarkerClick}
      clusterBound={clusterBound}
      startDate={startDate}
      language={language}
      endDate={endDate}
      clusterDetail={clusterDetail}
      loadingSummary={{
        consumerScannedSummary: isConsumerScannedSummaryLoading,
        uniqueConsumerScannedSummary: isUniqueConsumerSummaryLoading,
        firstScanSummary: isFirstScanSummaryLoading,
        suspendedSnSummary: isSuspendSNSummaryLoading,
        counterfeitReportSummary: isCounterfeitSummaryLoading,
      }}
    />
  );
}

export default ClusterScanDetailDialogContainer;
