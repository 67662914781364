import axios from "axios";

// Validate Reset Password Token 
export function validateOTP({
  phoneCode,
  phoneNumber,
  otp
}) {
  return new Promise((resolve, reject) => {
    axios.post("distributor/public/api/v2/validateOTP", {
      phone_code: phoneCode,
      phone_number: phoneNumber,
      otp: otp,
    },
  )
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data))
  })
}