import React from "react";
import {
  Box,
  Paper,
  Typography,
  Divider,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination
} from "@material-ui/core";
import { SkeletonTableRow } from "components/skeleton/skeleton.component";
import {
  QRCreditImg,
  SMSCreditImg,
  PlanImg
} from "modules/employee/utils/static";
import { TablePaginationActions } from "components/tablePagination";
import { getLang } from "app/feature/constants";
import moment from "moment";
import "moment/min/locales.min";

const style = (theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    paddingBottom: theme.spacing(4)
  },
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    "& th:last-child, td:last-child": {
      paddingRight: theme.spacing(3)
    },
    "& th,td": {
      backgroundColor: theme.palette.common.white,
      fontSize: "small",
      borderBottom: "0",
      padding: theme.spacing(1, 2, 1, 2)
    },
    "& th": {
      color: "#899498",
      borderBottom: "1px solid rgba(224, 224, 224, 1)"
    },
  },
  tableContainer: {
    maxHeight: 700,
    overflowX: "auto"
  },
  month: {
    padding: theme.spacing(2, 3, 2)
  }
});

const useStyles = makeStyles(style);

export default function OverallTableComponent({
  isFetching,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
  tableUI,
  language,
  orderHistoryData,
  totalRecords,
  paginationOptions,
  lang
}) {
  const classes = useStyles();
  
  if(language==="ZH")
  {
    moment.locale('zh-cn')
  }else if(language==="TL")
  {
    moment.locale('tl-ph')
  }else
  {
    moment.locale(language)
  }

  return (
    <Paper elevation={0} className={classes.root}>
      {tableUI.map((value, index) => {
        return (
          <React.Fragment key={index}>
            <Box className={classes.month}>
              <Typography variant="subtitle2" color="primary">
                {value.label}
              </Typography>
            </Box>
            <Divider />
            <TableContainer className={classes.tableContainer}>
              <Table className={classes.table} aria-label="overall table">
                <TableHead>
                  <TableRow>
                    <TableCell>{getLang(lang, "label.CREDIT_TYPE")}</TableCell>
                    <TableCell>{getLang(lang, "label.AMOUNT")}</TableCell>
                    <TableCell>{getLang(lang, "label.COST_USD")}</TableCell>
                    <TableCell>{getLang(lang, "label.DATE")}</TableCell>
                    <TableCell>{getLang(lang, "label.CARD_LAST_FOUR_DIGIT")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isFetching ? (
                    [...new Array(5)].map((v, index) => (
                      <SkeletonTableRow key={index} columnCount={5} />
                    ))
                  ) : !isFetching && orderHistoryData.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan="6">{getLang(lang, "paragraph.NO_TRANSACTION")}</TableCell>
                    </TableRow>
                  ) : (
                    orderHistoryData.map((row, index) => {
                      return row.transactions.map((value, i) => {
                        return (
                          <TableRow key={i} hover tabIndex={-1}>
                            <TableCell scope="row">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center"
                                }}
                              >
                                <Box mr={1} display="flex">
                                  {value.type === "qrcredit" ? (
                                    <QRCreditImg />
                                  ) : value.type === "sms" ? (
                                    <SMSCreditImg />
                                  ) : (
                                    <PlanImg />
                                  )}
                                </Box>
                                {value.name}
                              </div>
                            </TableCell>
                            <TableCell>{value.unit}</TableCell>
                            <TableCell>
                              {parseFloat(value.amount).toFixed(2)}
                            </TableCell>
                            <TableCell>
                              {moment(row.date).format('ll')}
                            </TableCell>
                            <TableCell>{value.card}</TableCell>
                          </TableRow>
                        );
                      });
                    })
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            {!isFetching && orderHistoryData.length > 0 && (
              <TablePagination
                style={{
                  backgroundColor: "#fff",
                  boxShadow: "0px -1px 0px rgba(58, 77, 84, 0.15)"
                }}
                ActionsComponent={TablePaginationActions}
                rowsPerPageOptions={paginationOptions}
                component="div"
                count={totalRecords}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage={getLang(lang, 'label.ROWS_PER_PAGE')}
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${getLang(lang, 'label.OF')} ${count}`}
              />
            )}
            {tableUI.length - 1 > index ? (
              <Box mt={2}>
                <Divider
                  style={{ backgroundColor: "rgba(58, 77, 84, 0.25)" }}
                />
              </Box>
            ) : null}
          </React.Fragment>
        );
      })}
    </Paper>
  );
}
