import React from "react";
import { Box, Typography, Divider } from "@material-ui/core";
import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";
import { getLang } from "app/feature/constants";
import CheckoutListing from "../checkout-listing";

export default function MainPageComponent({ lang }) {
  return (
    <Box>
      <Box gridArea="topbar">
        <Box
          mb={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="flex" alignItems="flex-end" fontWeight="bold">
            <Typography variant="h6" color="primary">
              Trackco
            </Typography>
            <Box mx={0.5}>
              <ChevronRightRoundedIcon color="disabled" />
            </Box>
            <Box>
              <Typography variant="h6" color="primary">
                {getLang(lang, "label.CHECKOUT_TAB")}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
      </Box>

      <Box gridArea="panel" mt={2}>
        <CheckoutListing />
      </Box>
    </Box>
  );
}
