import React from "react";
import { useSelector } from "react-redux";
import DashboardSingleScanMapComponent from "./dashboardSingleScanMap.component";
import { useJsApiLoader } from "@react-google-maps/api";

const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

export default function DashboardSingleScanMapContainer({ center, detail }) {
  const locale = useSelector((state) => state.profile.language);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: API_KEY,
    language: locale,
  });

  return (
    <DashboardSingleScanMapComponent
      center={center}
      isLoaded={isLoaded}
      detail={detail}
    />
  );
}
