import axios from "axios";

export function getCheckoutProductDetail(parameters) {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `/distributor/admin/api/v1/checkout/${parameters.id}/products/${parameters.product_uuid}/serialnumbers`,
        {
          params: {
            box_uuid: parameters.box_uuid,
            start: parameters.start,
            length: parameters.length,
          },
        }
      )
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data));
  });
}
