import React, { useState } from "react";
import {
  makeStyles,
  Paper,
  Typography,
  IconButton,
  Divider,
  Button,
  DialogActions,
  Dialog,
  Box,
  Tabs,
  Menu,
  MenuItem,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CustomTab from "components/tab";
import Input from "components/input/inputTextField";
import { Remove as RemoveIcon, Add as AddIcon } from "@material-ui/icons";
import { getLang } from "app/feature/constants";
import mapValues from "lodash/mapValues";
import { Formik } from "formik";
import * as Yup from "yup";
import languages from "lib/constants/languages";

const useStyle = makeStyles((theme) => ({
  root: {
    borderRadius: 8,
    boxShadow:
      "0px 20px 24px -4px rgba(16, 24, 40, 0.1), 0px 8px 8px -4px rgba(16, 24, 40, 0.04)",
    [theme.breakpoints.down("xs")]: {
      minWidth: "95vw",
    },
  },
  header: {
    paddingLeft: theme.spacing(3),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  inputRow: {
    backgroundColor: "#EBEDEE",
    borderRadius: 4,
    width: "100%",
    height: 38,
  },
  titleContainer: {
    padding: 16,
    border: "1px solid #3a4d544f",
    borderRadius: 4,
  },
}));

export default function EditAdtInfoTitleDialogComponent({
  isOpen,
  handleClose,
  lang,
  isSubmitting,
  handleEdit,
  addAlert,
  selectedTitle,
}) {
  const classes = useStyle();

  function a11yProps(index, label = "label") {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tab-${index}`,
      label: <Typography variant="button" children={label} />,
    };
  }
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <Dialog
      open={isOpen}
      disableBackdropClick
      disableEscapeKeyDown
      aria-labelledby='="general-dialog'
      maxWidth="sm"
      classes={{
        paper: classes.root,
      }}
      fullWidth
    >
      <Paper elevation={0}>
        <div className={classes.header}>
          <Typography variant="subtitle2" style={{ fontWeight: "bold" }}>
            {getLang(lang, "label.EDIT_ADDITIONAL_INFO_TITLE")}
          </Typography>
          <IconButton color="primary" onClick={() => handleClose()}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </div>
        <Divider />
        <Formik
          initialValues={{
            languageTabIndex: 0,
            title: selectedTitle,
            usedLang: selectedTitle
              ? Object.keys(selectedTitle.title).map((key) =>
                  languages.find((lang) => lang.code === key)
                )
              : [languages.find((lang) => lang.code === "EN")],
          }}
          enableReinitialize
          onSubmit={handleEdit}
          validationSchema={Yup.object({
            title: Yup.object({
              title: Yup.lazy((obj) =>
                Yup.object(
                  mapValues(obj, (value, key) => {
                    return Yup.string().trim().required();
                  })
                ).required(
                  getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")
                )
              ),
            }),
          })}
        >
          {(formik) => {
            let currentLang =
              formik.values.usedLang[formik.values.languageTabIndex]?.code ??
              "EN";

            return (
              <Box>
                <Box
                  maxHeight="70vh"
                  pt={1}
                  px={3}
                  pb={3}
                  style={{ overflowY: "auto" }}
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    width="100%"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Tabs
                      value={formik.values.languageTabIndex}
                      onChange={(event, index) => {
                        formik.setFieldValue(
                          "languageTabIndex",
                          parseInt(index)
                        );
                      }}
                      aria-label="scrollable auto tabs example"
                      textColor="primary"
                      variant="scrollable"
                      indicatorColor="primary"
                      classes={{
                        indicator: classes.indicator,
                        scrollButtons: classes.scroll,
                      }}
                      style={{ flex: 1 }}
                    >
                      {formik.values.usedLang.map((item, index) => {
                        return (
                          <CustomTab
                            key={index}
                            {...a11yProps(index, item.label)}
                          />
                        );
                      })}
                    </Tabs>
                    <Box display="flex" alignItems="center" ml={2}>
                      <Box>
                        <IconButton
                          size="small"
                          onClick={() => {
                            let toRemove =
                              formik.values.usedLang[
                                formik.values.languageTabIndex
                              ].code;

                            const temp = [...formik.values.usedLang].filter(
                              (item) =>
                                item.code !==
                                formik.values.usedLang[
                                  formik.values.languageTabIndex
                                ].code
                            );

                            let ttl = { ...formik.values.title.title };
                            delete ttl[toRemove];

                            let titleObj = { ...formik.values.title };
                            titleObj["title"] = ttl;

                            formik.setFieldValue(
                              "languageTabIndex",
                              temp.length - 1
                            );
                            formik.setFieldValue("usedLang", temp);
                            formik.setFieldValue("title", titleObj);
                          }}
                          disabled={
                            formik.values.usedLang.length <= 1 ||
                            formik.values.usedLang[
                              formik.values.languageTabIndex
                            ]?.code === "EN" ||
                            isSubmitting
                          }
                        >
                          <RemoveIcon />
                        </IconButton>
                      </Box>
                      <Box ml={0.5}>
                        <IconButton
                          size="small"
                          onClick={(event) => setAnchorEl(event.currentTarget)}
                          disabled={
                            languages.length - formik.values.usedLang.length ===
                              0 || isSubmitting
                          }
                        >
                          <AddIcon />
                        </IconButton>
                        <Menu
                          anchorEl={anchorEl}
                          keepMounted
                          open={Boolean(anchorEl)}
                          onClose={() => setAnchorEl(null)}
                        >
                          {languages
                            .filter(
                              (language) =>
                                !formik.values.usedLang.some(
                                  (used) => used.code === language.code
                                )
                            )
                            .map((language, index) => (
                              <MenuItem
                                onClick={() => {
                                  const temp = [...formik.values.usedLang];
                                  temp.push(language);

                                  let title = { ...formik.values.title };
                                  title["title"] = {
                                    ...title["title"],
                                    [language.code]: "",
                                  };

                                  formik.setFieldValue("usedLang", temp);
                                  formik.setFieldValue(
                                    "languageTabIndex",
                                    temp.length - 1
                                  );
                                  formik.setFieldValue("title", title);
                                  setAnchorEl(null);
                                }}
                                key={index}
                              >
                                {language.label}
                              </MenuItem>
                            ))}
                        </Menu>
                      </Box>
                    </Box>
                  </Box>
                  <Box className={classes.titleContainer}>
                    <Box display="flex" alignItems="center">
                      <Box
                        display="flex"
                        alignItems="center"
                        className={classes.inputRow}
                        mr={1}
                      >
                        <Input
                          hiddenLabel
                          variant="filled"
                          size="small"
                          placeholder={getLang(
                            lang,
                            "placeholder.PLEASE_ENTER_TITLE"
                          )}
                          disabled={isSubmitting}
                          margin="none"
                          InputProps={{
                            disableUnderline: true,
                            margin: "none",
                            style: {
                              backgroundColor: "transparent",
                              height: 38,
                            },
                            onBlur: formik.handleBlur,
                            name: `title.title[${currentLang}]`,
                          }}
                          required={true}
                          inputProps={{ maxLength: 50 }}
                          style={{ width: "100%" }}
                          onChange={(e) => {
                            let temp = { ...formik.values.title };
                            temp["title"] = {
                              ...temp["title"],
                              [currentLang]: e.target.value,
                            };
                            formik.setFieldValue("title", temp);
                          }}
                          onClick={(e) => e.stopPropagation()}
                          value={formik.values.title?.title[currentLang] ?? ""}
                          error={
                            formik.touched.title?.title?.[currentLang] &&
                            formik.errors.title?.title?.[currentLang]
                              ? true
                              : false
                          }
                        />
                        <Box mx={1}>
                          <Typography variant="caption" color="textSecondary">
                            {formik.values.title?.title[currentLang]?.length ??
                              0}
                            /50
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Divider />
                <DialogActions>
                  <Button
                    autoFocus
                    onClick={(e) => {
                      handleClose();
                    }}
                    disabled={isSubmitting}
                    color="primary"
                  >
                    {getLang(lang, "label.CANCEL")}
                  </Button>
                  <Button
                    color="secondary"
                    variant="contained"
                    disableElevation
                    autoFocus
                    disabled={isSubmitting}
                    onClick={() => {
                      let valid = true;

                      let t = formik.values.title;
                      let totalEmpty = 0;
                      formik.values.usedLang.forEach((lang) => {
                        if (
                          !t.title?.[lang.code] ||
                          !t.title?.[lang.code].trim()
                        ) {
                          totalEmpty += 1;
                        }
                      });

                      if (
                        totalEmpty > 0 &&
                        totalEmpty < formik.values.usedLang.length
                      ) {
                        valid = false;

                        formik.values.usedLang.forEach((l) =>
                          formik.setFieldTouched(
                            `title.title.[${l.code}]`,
                            true
                          )
                        );
                      }

                      if (!valid) {
                        addAlert(getLang(lang, "label.NOT_ALL_LANG_FILLED"));
                      } else {
                        formik.handleSubmit();
                      }
                    }}
                  >
                    {isSubmitting
                      ? getLang(lang, "label.SUBMITTING")
                      : getLang(lang, "label.UPDATE")}
                  </Button>
                </DialogActions>
              </Box>
            );
          }}
        </Formik>
      </Paper>
    </Dialog>
  );
}
