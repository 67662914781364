import React from "react";
import {
  Box,
  Typography,
  Grid,
  Button,
} from "@material-ui/core";
import Dialog from "components/dialog/customDialog";
import {getLang} from "../../../../../app/feature/constants";

export default function ApproveClaimDialogComponent({
  isOpen,
  handleClose,
  handleConfirm,
  lang,
}) {
  return (
    <Dialog
      isOpen={isOpen}
      handleClose={handleClose}
      disableEnforceFocus={true}
      disableAutoFocus={true}
      disableEscapeKeyDown={true}
      disableBackdropClick={true}
      PaperProps={{ style: { borderRadius: "10px" } }}
    >
      <Box p={4} py={3}>
        <Typography>
          <Box fontWeight="fontWeightBold" textAlign="center">
            {getLang(lang, "paragraph.APPROVE_CLAIMS_DIALOG_TITLE")}
          </Box>
          <Box fontcolor="text.secondary" textAlign="center" fontSize="fontSize" pt={1} pb={3}>
            {getLang(lang, "paragraph.APPROVE_CLAIMS_DIALOG_CONTENT")}
          </Box>
          <Grid container>
            <Grid item xs={6}>
              <Box display="flex" justifyContent="center" pr={1}>
                <Button
                  size="large"
                  variant="outlined"
                  fullWidth={true}
                  onClick={handleClose}
                >
                  {getLang(lang, "label.CANCEL")}
                </Button>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box display="flex" justifyContent="center" pl={1}>
                <Button
                  size="large"
                  color="secondary"
                  variant="contained"
                  fullWidth={true}
                  onClick={handleConfirm}
                >
                  {getLang(lang, "label.APPROVE")}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Typography>
      </Box>
    </Dialog>
  );
}
