import React from "react";
import { connect } from "react-redux";
import AdtProductListTableComponent from "./adtProductListTable.component";

function AdtProductListTableContainer({
  isFetching,
  productList,
  rowsPerPage,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
  language,
  handleReload,
  lang,
  handleViewDetail,
  userLang,
  additionalFields,
  isError,
  hasNextPage,
  displayLang,
}) {
  return (
    <AdtProductListTableComponent
      rows={productList}
      page={page}
      rowsPerPage={rowsPerPage}
      language={language}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      isFetching={isFetching}
      handleReload={handleReload}
      lang={lang}
      userLang={userLang}
      additionalFields={additionalFields}
      handleViewDetail={handleViewDetail}
      isError={isError}
      hasNextPage={hasNextPage}
      displayLang={displayLang}
    />
  );
}

const mapStateToProps = (state) => ({
  userLang: state.profile.language,
  displayLang: state.additionalInfo.displayLang,
});

export default connect(mapStateToProps, null)(AdtProductListTableContainer);
