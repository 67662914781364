import { 
  getRespondentDetails, 
  getRespondentListing, 
  getRespondentQuestionList,
  getRespondentAnswerList
} from "./respondent";
import {
  getResponse,
  getSurveyByAge,
  getSurveyByDevice,
  getTotalResponse,
  getSurveyByGender,
  getSurveyByProduct,
  getSurveyGeoCount,
  getTotalVisit,
  getTotalResponseByChoiceCount
} from "./analytics";
import { createSurvey } from "./createSurvey.api";
import { getAllSurvey } from "./getAllSurvey.api";
import { getSurvey } from "./getSurvey.api";
import { getSingleSurveyInfo } from "./getSingleSurveyInfo.api";
import { updateSurvey } from "./updateSurvey.api";
import { updateSurveyStatus } from "./updateSurveyStatus.api";
import { deleteSurvey } from "./deleteSurvey.api";
import { getSurveyDropdown } from "./getSurveyDropdown.api";

const surveyApi = {
  getAllSurvey,
  createSurvey,
  getSurvey,
  updateSurvey,
  updateSurveyStatus,
  getRespondentListing,
  getRespondentDetails,
  getRespondentQuestionList,
  getRespondentAnswerList,
  getResponse,
  getSurveyByAge,
  getSurveyByDevice,
  getTotalResponse,
  getSurveyByGender,
  getSurveyByProduct,
  getSurveyGeoCount,
  getTotalVisit,
  getTotalResponseByChoiceCount,
  deleteSurvey,
  getSurveyDropdown,
  getSingleSurveyInfo
};

export default surveyApi;