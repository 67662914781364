import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import luckyDrawApi from "app/api/luckyDraw";
import { timezoneOffSetFormatTime } from "lib/helper";

const LUCKYDRAW = "luckyDraw";

export const fetchLuckyDrawListTable = createAsyncThunk(
  `${LUCKYDRAW}/fetchLuckyDrawListTable`,
  async (payload, thunkApi) => {
    return luckyDrawApi.getAllLuckyDraw.get(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const createLuckyDraw = createAsyncThunk(
  `${LUCKYDRAW}/createLuckyDraw`,
  async (payload, thunkApi) => {
    const timezone = thunkApi.getState().profile.timezone;
    return luckyDrawApi.luckyDraw.post({
      title: payload.LuckyDrawDetail.luckyDrawTitle,
      description: payload.LuckyDrawDetail.luckyDrawDescription,
      imageUrl: payload.LuckyDrawDetail.luckyDrawImageUrl.split("?")[0],
      tncUrl: payload.LuckyDrawDetail.luckyDrawTermsConditionUrl.split("?")[0],
      metadata: payload.luckyDrawRegister,
      productId: payload.productId,
      startAt: timezoneOffSetFormatTime(payload.LuckyDrawDetail.startAt, timezone),
      endAt: timezoneOffSetFormatTime(payload.LuckyDrawDetail.endAt, timezone),
      status: payload.LuckyDrawDetail.status
    })
      .then(response => response)
      .catch(error => thunkApi.rejectWithValue(error))
  }
);

export const fetchLuckyDrawDetail = createAsyncThunk(
  `${LUCKYDRAW}/fetchLuckyDrawDetail`,
  async (payload, thunkApi) => {
    return luckyDrawApi.luckyDraw.get({ id: payload })
      .then((response) => response)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const updateLuckyDrawDetail = createAsyncThunk(
  `${LUCKYDRAW}/updateLuckyDrawDetail`,
  async (payload, thunkApi) => {
    const timezone = thunkApi.getState().profile.timezone;
    const info = {
      ...payload,
      title: payload.LuckyDrawDetail
        ? payload.LuckyDrawDetail.luckyDrawTitle
        : undefined,
      description: payload.LuckyDrawDetail
        ? payload.LuckyDrawDetail.luckyDrawDescription
        : undefined,
      imageUrl: payload.LuckyDrawDetail
        ? payload.LuckyDrawDetail.luckyDrawImageUrl
          ? payload.LuckyDrawDetail.luckyDrawImageUrl.split("?")[0]
          : undefined
        : undefined,
      startAt: payload.LuckyDrawDetail
        ? timezoneOffSetFormatTime(payload.LuckyDrawDetail.startAt, timezone)
        : undefined,
      endAt: payload.LuckyDrawDetail
        ? timezoneOffSetFormatTime(payload.LuckyDrawDetail.endAt, timezone)
        : undefined,
      metadata: payload.metadata,
      id: payload.LuckyDrawDetail.id,
      tncUrl: payload.LuckyDrawDetail.luckyDrawTermsConditionDetail
    };

    return luckyDrawApi.luckyDraw.put(info)
      .then(() => info)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const fetchJSONURL = createAsyncThunk(
  `${LUCKYDRAW}/fetchJSONURL`,
  async (payload, thunkApi) => {
    return fetch(payload)
      .then((res) => res.json())
      .then((out) => {
        return out;
      })
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const fetchAllLuckyDrawPrize = createAsyncThunk(
  `${LUCKYDRAW}/fetchAllLuckyDrawPrize`,
  async (payload, thunkApi) => {

    return luckyDrawApi.getAllPrize.get(payload)
      .then((response) => {
        response.data.id = payload.luckyDrawId;
        return response.data
      })
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const createPrize = createAsyncThunk(
  `${LUCKYDRAW}/createPrize`,
  async (payload, thunkApi) => {
    return luckyDrawApi.prize.post({
      luckyDrawId: payload.luckyDrawId,
      name: payload.prizeName,
      type: payload.drawMethod,
      imageUrl: payload.prizeImage.split("?")[0],
      order: payload.order,
      quantity: payload.quantity,
      title: payload.prizeLabel,
      description: payload.description
    })
      .then(response => response)
      .catch(error => thunkApi.rejectWithValue(error))
  }
);

export const updatePrize = createAsyncThunk(
  `${LUCKYDRAW}/updatePrize`,
  async (payload, thunkApi) => {
    const info = {
      luckyDrawId: payload.luckyDrawId,
      name: payload.prizeName,
      type: payload.drawMethod,
      imageUrl: payload.prizeImage,
      order: payload.order,
      quantity: payload.quantity,
      title: payload.prizeLabel,
      description: payload.description,
      id: payload.id
    };

    return luckyDrawApi.prize.put(info)
      .then(() => info)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const fetchMoreLuckyDrawParticipant = createAction(`${LUCKYDRAW}/fetchMoreLuckyDrawParticipant`)

export const fetchLuckyDrawParticipant = createAsyncThunk(
  `${LUCKYDRAW}/fetchLuckyDrawParticipant`,
  async (payload, thunkApi) => {

    return luckyDrawApi.getAllParticipant.get(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

//For table
export const fetchLuckyDrawParticipantTable = createAsyncThunk(
  `${LUCKYDRAW}/fetchLuckyDrawParticipantTable`,
  async (payload, thunkApi) => {
    return luckyDrawApi.getAllParticipant.get(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const fetchParticipantDetail = createAsyncThunk(
  `${LUCKYDRAW}/fetchParticipantDetail`,
  async (payload, thunkApi) => {
    return luckyDrawApi.getParticipantDetail.get({
      id: payload,
    }).then(response => response)
      .catch(error => thunkApi.rejectWithValue(error))

  }
);

export const changeLuckyDrawStatus = createAsyncThunk(
  `${LUCKYDRAW}/changeLuckyDrawStatus`,
  async (payload, thunkApi) => {
    return luckyDrawApi.changeStatus.put({
      id: payload.id,
      status: payload.status
    }).then(response => response)
      .catch(error => thunkApi.rejectWithValue(error))
  }
);

export const randomParticipant = createAsyncThunk(
  `${LUCKYDRAW}/randomParticipant`,
  async (payload, thunkApi) => {

    return luckyDrawApi.getAllRandomParticipant.get({
      id: payload.id,
      count: payload.count,
      exclude: payload.exclude
    })
      .then((response) => response)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const saveWinner = createAsyncThunk(
  `${LUCKYDRAW}/saveWinner`,
  async (payload, thunkApi) => {
    const info = {
      id: payload.luckyDrawId,
      prizes: payload.prizes
    };

    return luckyDrawApi.saveWinner.post(info)
      .then(() => info)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const changeLuckyDrawProgress = createAsyncThunk(
  `${LUCKYDRAW}/changeLuckyDrawProgress`,
  async (payload, thunkApi) => {
    return luckyDrawApi.changeProgress.put({
      id: payload.id,
      progress: payload.progress
    }).then(response => response)
      .catch(error => thunkApi.rejectWithValue(error))

  }
);

export const setLiveDrawOne = createAsyncThunk(
  `${LUCKYDRAW}/setLiveDrawOne`,
  async (payload, thunkApi) => {
    return luckyDrawApi.liveDraw.one({
      id: payload.id,
    }).then(response => response)
      .catch(error => thunkApi.rejectWithValue(error))

  }
);

export const setLiveDrawAll = createAsyncThunk(
  `${LUCKYDRAW}/setLiveDrawAll`,
  async (payload, thunkApi) => {
    return luckyDrawApi.liveDraw.all({
      id: payload.id,
    }).then(response => response)
      .catch(error => thunkApi.rejectWithValue(error))

  }
);
