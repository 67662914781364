import React from "react";
import {
  makeStyles,
  Box,
  Typography,
  Button,
  Divider,
  Grid,
  Link,
} from "@material-ui/core";
import { getLang } from "app/feature/constants";
import DateTimePicker from "../../../../../components/input/dateTimePicker";
import { getDate } from "../../../../../lib/generalUtility";
import moment from "moment/moment";
import "moment/min/locales.min";

const style = (theme) => ({
  root: {
    width: 700,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    maxHeight: "80vh",
    overflowY: "scroll",
  },
  image: {
    paddingTop: theme.spacing(2),
    display: "flex",
    "& img": {
      height: "100%",
      flexShrink: 0,
      minWidth: "100%",
      minHeight: "100%",
      objectFit: "cover",
      width: 250,
    },
  },
  dateContainer: {
    height: 43,
    backgroundColor: "#ECEFF0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    borderRadius: "4px 0px 0px 4px",
  },
  dateView: {
    paddingRight: theme.spacing(1),
  },
  divider: {
    height: "100%",
    display: "flex",
    alignSelf: "center",
  },
  dividerContainer: {
    backgroundColor: "#ECEFF0",
    display: "flex",
  },
  toggleFormGroup: {
    flexDirection: "row",
  },
  toggleControl: {
    margin: "7px 35px 7px 0px",
  },
  approvalContainer: {
    padding: theme.spacing(1, 3, 1, 3),
    display: "flex",
    alignItems: "center",
  },
  buttonContanier: {
    padding: "12px 24px",
    display: "flex",
    justifyContent: "flex-end",
  },
  textStyle: {
    fontWeight: "bold",
  },
  rejectButton: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.light,
    "&:hover": {
      borderColor: theme.palette.error.dark,
    },
  },
  footer: {
    position: "sticky",
    bottom: 0,
    width: "100%",
    backgroundColor: "white",
  },
  attributeText: {
    color: "#b2b2b2",
    fontSize: 12,
    whiteSpace: "break-spaces",
  },
});

const useStyles = makeStyles(style);

export default function ApprovalWarrantyPanelComponent({
  hasWarrantyEditAccessRight,
  consumerWarrantyDetail,
  warrantySetting,
  warrantyRegisterForm,
  onApproveClick,
  onReinstateClick,
  onRejectClick,
  language,
  onVoidClick,
  handleView,
  lang,
}) {
  const classes = useStyles();
  const receipt = consumerWarrantyDetail.metadata.find(
    (f) => f.key === "proof_of_purchase"
  );

  if (language === "ZH") {
    moment.locale("zh-cn");
  } else if (language === "TL") {
    moment.locale("tl-ph");
  } else {
    moment.locale(language);
  }

  return (
    <>
      <Box className={classes.root}>
        <Grid container>
          <Grid item xs={12} sm={7} md={7} style={{ width: "100%" }}>
            <Box p={3} pt={2}>
              <Typography
                variant="body2"
                color="primary"
                style={{ fontWeight: "bold" }}
              >
                {getLang(lang, "label.WARRANTEE_DETAILS")}
              </Typography>

              {consumerWarrantyDetail.metadata.map((field, index) => {
                const matched = warrantyRegisterForm.find(
                  (w) => w.fieldName === field.key
                );
                if (matched)
                  return (
                    field.key !== "proof_of_purchase" && (
                      <Box key={index} pt={2}>
                        <Grid container>
                          <Grid
                            item
                            xs={5}
                            sm={5}
                            md={5}
                            style={{ width: "100%" }}
                          >
                            <Typography variant="body2" color="primary">
                              {matched.variantName}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={7}
                            sm={7}
                            md={7}
                            style={{ width: "100%", display: "flex" }}
                          >
                            {field.type === "contactNumber" ? (
                              <Typography
                                variant="body2"
                                color="primary"
                                className={classes.textStyle}
                              >
                                {field.value?.contactCode}{" "}
                                {field.value?.contactNumber}
                              </Typography>
                            ) : field.value.includes("https://") ? (
                              <Link
                                variant="body2"
                                href="#"
                                color="primary"
                                underline="always"
                                onClick={() => handleView(field.value)}
                                className={classes.textStyle}
                                style={{
                                  color: "royalblue",
                                }}
                              >
                                {getLang(lang, "label.VIEW")}
                              </Link>
                            ) : Array.isArray(field.value) ? (
                              <ul
                                style={{
                                  margin: "0",
                                  padding: "0",
                                  paddingLeft: "16px",
                                  listStyle: "square",
                                }}
                              >
                                {field.value.map((response) => {
                                  return (
                                    <li>
                                      {" "}
                                      <Typography
                                        variant="body2"
                                        color="primary"
                                        className={classes.textStyle}
                                      >
                                        {response}{" "}
                                      </Typography>
                                    </li>
                                  );
                                })}
                              </ul>
                            ) : (
                              <Typography
                                variant="body2"
                                color="primary"
                                className={classes.textStyle}
                              >
                                {field.value !== "" ? field.value : "-"}
                              </Typography>
                            )}
                          </Grid>
                        </Grid>
                      </Box>
                    )
                  );
                return null;
              })}
              {receipt ? (
                <Box pt={2}>
                  <Grid container>
                    <Grid item xs={5} sm={5} md={5} style={{ width: "100%" }}>
                      <Typography variant="body2" color="primary">
                        {getLang(lang, "label.PROOF_OF_PURCHASE")}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={7}
                      sm={7}
                      md={7}
                      style={{ width: "100%", display: "flex" }}
                    >
                      <Box
                        className={classes.image}
                        border={1}
                        borderColor="grey.500"
                        borderRadius="borderRadius"
                        p={1}
                      >
                        <Box>
                          <img
                            style={{ cursor: "pointer" }}
                            src={receipt.value}
                            alt="Receipt"
                            onClick={() => window.open(receipt.value, "_blank")}
                          />
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              ) : null}
            </Box>
          </Grid>
        </Grid>

        <Divider />

        <Box p={3} pt={2}>
          <Typography
            variant="body2"
            color="primary"
            style={{ fontWeight: "bold" }}
          >
            {getLang(lang, "label.PRODUCT_DETAILS")}
          </Typography>

          {[
            {
              type: "product",
              label: getLang(lang, "label.PRODUCT"),
              name: consumerWarrantyDetail.product.productName,
              oldName: consumerWarrantyDetail.product.productNameOld,
              attributes: consumerWarrantyDetail.product.attributes,
            },
            {
              type: "sn",
              label: getLang(lang, "label.SERIAL_NUMBER"),
              name: consumerWarrantyDetail.serialNumber,
            },
          ].map((field, index) => {
            return (
              <Box key={index} pt={2}>
                <Grid container>
                  <Grid item xs={5} sm={3} md={3} style={{ maxWidth: "150px" }}>
                    <Typography variant="body2" color="primary">
                      {field.label}
                    </Typography>
                  </Grid>
                  <Grid item xs={7} sm={9} md={9} style={{ width: "100%" }}>
                    {field.type === "product" ? (
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="flex-start"
                      >
                        <Typography variant="body2">
                          <b>{field.name}</b>
                          <span style={{ color: "grey" }}>
                            {field.oldName && ` (${field.oldName})`}
                          </span>
                        </Typography>
                        {!!field.attributes && !!field.attributes.length && (
                          <Typography
                            className={classes.attributeText}
                            variant="body2"
                          >
                            {field.attributes.join(", ")}
                          </Typography>
                        )}
                      </Box>
                    ) : (
                      <Typography
                        variant="body2"
                        color="primary"
                        style={{ fontWeight: "bold" }}
                      >
                        {field.name}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Box>
            );
          })}
        </Box>

        <Divider />

        <Box p={3} pt={2}>
          <Typography
            variant="body2"
            color="primary"
            style={{ fontWeight: "bold" }}
          >
            {getLang(lang, "label.REGISTRATION_INFO")}
          </Typography>

          {[
            {
              label: getLang(lang, "label.REGISTRATION_DATE"),
              name: moment(
                consumerWarrantyDetail.createdAt.split("T")[0]
              ).format("ll"),
            },
            {
              label: getLang(lang, "label.REGISTRATION_STATUS"),
              name:
                consumerWarrantyDetail.status === "voided"
                  ? consumerWarrantyDetail.beforeVoidedStatus
                  : consumerWarrantyDetail.status,
            },
            (consumerWarrantyDetail.reason.trim().length > 0 &&
              consumerWarrantyDetail.status !== "voided") ||
            (consumerWarrantyDetail.status === "voided" &&
              consumerWarrantyDetail.beforeVoidedReason.trim().length > 0)
              ? {
                  label: getLang(lang, "label.REASON"),
                  name:
                    consumerWarrantyDetail.status === "voided"
                      ? consumerWarrantyDetail.beforeVoidedReason
                      : consumerWarrantyDetail.reason,
                }
              : null,
          ].map((field, index) => {
            if (!field) return null;
            return (
              <Box key={index} pt={2}>
                <Grid container>
                  <Grid item xs={5} sm={3} md={3} style={{ maxWidth: "150px" }}>
                    <Typography variant="body2" color="primary">
                      {field.label}
                    </Typography>
                  </Grid>
                  <Grid item xs={7} sm={9} md={9} style={{ width: "100%" }}>
                    {field.name === "approved" ? (
                      <Typography
                        variant="body2"
                        style={{ fontWeight: "bold" }}
                      >
                        <Box color="success.main">
                          {getLang(lang, "label.APPROVED")}
                        </Box>
                      </Typography>
                    ) : field.name === "processing" ? (
                      <Typography
                        variant="body2"
                        style={{ fontWeight: "bold" }}
                      >
                        <Box color="warning.main">
                          {getLang(lang, "label.PENDING_APPROVAL")}
                        </Box>
                      </Typography>
                    ) : field.name === "rejected" ? (
                      <Typography
                        variant="body2"
                        style={{ fontWeight: "bold" }}
                      >
                        <Box color="error.main">
                          {getLang(lang, "label.REJECTED")}
                        </Box>
                      </Typography>
                    ) : (
                      <Typography
                        variant="body2"
                        color="primary"
                        style={{ fontWeight: "bold" }}
                      >
                        {field.name}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Box>
            );
          })}
        </Box>

        <Divider />

        <Box p={3} pt={2}>
          <Typography
            variant="body2"
            color="primary"
            style={{ fontWeight: "bold" }}
          >
            {getLang(lang, "label.WARRANTY_DETAILS")}
          </Typography>

          {[
            {
              label: getLang(lang, "label.WARRANTY_STATUS"),
              name:
                consumerWarrantyDetail.status === "voided"
                  ? "voided"
                  : new Date(consumerWarrantyDetail.endAt).getTime() <
                    new Date().getTime()
                  ? "expired"
                  : "active",
            },
            consumerWarrantyDetail.status === "voided"
              ? {
                  label: getLang(lang, "label.REASON"),
                  name: consumerWarrantyDetail.reason,
                }
              : null,
            {
              label: getLang(lang, "label.WARRANTY_NAME"),
              name: warrantySetting.warrantyName,
            },
            {
              label: getLang(lang, "label.COVERAGE_PERIOD"),
              name:
                consumerWarrantyDetail.durationMonth +
                (consumerWarrantyDetail.extMonth &&
                  " + " + consumerWarrantyDetail.extMonth) +
                ` ${getLang(lang, "label.MONTH_S")}`,
            },
            {
              label: getLang(lang, "label.VALIDITY_DATE"),
              name: {
                endAt: consumerWarrantyDetail.endAt.split(" ")[0],
                startAt: consumerWarrantyDetail.startAt.split(" ")[0],
              },
            },
          ].map((field, index) => {
            if (!field) return null;
            return (
              <Box key={index} pt={2}>
                <Grid container>
                  <Grid item xs={5} sm={3} md={3} style={{ maxWidth: "150px" }}>
                    <Typography variant="body2" color="primary">
                      {field.label}
                    </Typography>
                  </Grid>
                  <Grid item xs={7} sm={9} md={9} style={{ width: "100%" }}>
                    {field.name.endAt && field.name.startAt ? (
                      <Box>
                        <Grid container>
                          <Grid item xs={3} style={{ width: "100%" }}>
                            <Box display="flex">
                              <Box className={classes.dateContainer}>
                                <Typography
                                  variant="subtitle2"
                                  style={{
                                    fontWeight: "bold",
                                    color: " rgba(58, 77, 84, 0.33)",
                                  }}
                                >
                                  {getLang(lang, "label.START")}
                                </Typography>
                              </Box>
                              <Box className={classes.dividerContainer}>
                                <Divider
                                  orientation="vertical"
                                  className={classes.divider}
                                />
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={9} style={{ width: "100%" }}>
                            <DateTimePicker
                              required
                              label=""
                              disabled={true}
                              value={field.name.startAt}
                              format="DD MMM YYYY"
                              InputPropStyle={{
                                borderTopLeftRadius: 0,
                                borderBottomLeftRadius: 0,
                              }}
                            />
                          </Grid>
                        </Grid>
                        <Box mt={2}>
                          <Grid container>
                            <Grid item xs={3} style={{ width: "100%" }}>
                              <Box display="flex">
                                <Box className={classes.dateContainer}>
                                  <Typography
                                    variant="subtitle2"
                                    style={{
                                      fontWeight: "bold",
                                      color: " rgba(58, 77, 84, 0.33)",
                                    }}
                                  >
                                    {getLang(lang, "label.END")}
                                  </Typography>
                                </Box>
                                <Box className={classes.dividerContainer}>
                                  <Divider
                                    orientation="vertical"
                                    className={classes.divider}
                                  />
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={9} style={{ width: "100%" }}>
                              <DateTimePicker
                                required
                                label=""
                                disabled={true}
                                format="DD MMM YYYY"
                                value={field.name.endAt}
                                InputPropStyle={{
                                  borderTopLeftRadius: 0,
                                  borderBottomLeftRadius: 0,
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                    ) : (
                      <Box>
                        {field.name === "voided" ? (
                          <Typography
                            variant="body2"
                            style={{ fontWeight: "bold" }}
                          >
                            <Box>{getLang(lang, "label.VOID")}</Box>
                          </Typography>
                        ) : field.name === "active" ? (
                          <Typography
                            variant="body2"
                            style={{ fontWeight: "bold" }}
                          >
                            <Box color="success.main">
                              {getLang(lang, "label.COVER")}
                            </Box>
                          </Typography>
                        ) : field.name === "expired" ? (
                          <Typography
                            variant="body2"
                            style={{ fontWeight: "bold" }}
                          >
                            <Box color="error.main">
                              {getLang(lang, "label.NOT_COVER")}
                            </Box>
                          </Typography>
                        ) : (
                          <Typography
                            variant="body2"
                            style={{ fontWeight: "bold" }}
                          >
                            {field.name}
                          </Typography>
                        )}
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </Box>
            );
          })}
        </Box>

        <Divider />

        <Box p={3} pt={2}>
          <Typography
            variant="body2"
            color="primary"
            style={{ fontWeight: "bold" }}
          >
            {getLang(lang, "label.EXTEND_WARRANTY")}
          </Typography>

          {[
            ...(consumerWarrantyDetail.extCode
              ? [
                  {
                    date: new Date(consumerWarrantyDetail.extAt),
                    code: consumerWarrantyDetail.extCode,
                    extendedMonth: consumerWarrantyDetail.extMonth,
                  },
                ]
              : []),
          ].map((field, index) => {
            return (
              <Box
                key={index}
                mt={2}
                py={2}
                px={1}
                border={1}
                borderRadius="4px"
              >
                <Grid container>
                  <Grid item xs={3}>
                    <Typography variant="body2" color="primary">
                      <Box fontWeight="fontWeightBold">
                        {getDate(field.date, true)}
                      </Box>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" color="primary">
                      <Box>
                        {getLang(lang, "label.EXTENSION_CODE")} - {field.code}
                      </Box>
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      variant="body2"
                      color="primary"
                      style={{ fontWeight: "bold" }}
                    >
                      <Box fontWeight="fontWeightBold" textAlign="end">
                        +{field.extendedMonth} {getLang(lang, "label.MONTH_S")}
                      </Box>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            );
          })}

          {!consumerWarrantyDetail.extCode && (
            <Box mt={2} py={2} px={1} border={1} borderRadius="4px">
              <Typography variant="body2" color="primary">
                <Box fontWeight="fontWeightBold">
                  {getLang(lang, "label.EMPTY_WARRANTY_EXTENSION")}
                </Box>
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      {hasWarrantyEditAccessRight && (
        <Box className={classes.footer}>
          {/*Button display logic*/}
          {/*- Processing status: void, reject, approve*/}
          {/*- Approved status: void*/}
          {/*- Rejected status: void*/}
          {/*- Voided status: reinstate*/}
          <Divider />
          <Box className={classes.buttonContanier}>
            {consumerWarrantyDetail.status === "processing" && (
              <Box display="flex" alignItems="center" flexGrow="1">
                <Box
                  fontWeight="fontWeightBold"
                  textAlign="left"
                  height="fit-content"
                >
                  Registration Approval
                </Box>
              </Box>
            )}
            {consumerWarrantyDetail.status !== "voided" &&
            consumerWarrantyDetail.status !== "claimed" ? (
              <Button
                color="secondary"
                variant="outlined"
                onClick={onVoidClick}
              >
                {getLang(lang, "label.VOID")}
              </Button>
            ) : null}
            {consumerWarrantyDetail.status === "processing" ? (
              <>
                <Box p={1} />
                <Button
                  classes={{ root: classes.rejectButton }}
                  variant="outlined"
                  onClick={onRejectClick}
                >
                  {getLang(lang, "label.REJECT")}
                </Button>
                <Box p={1} />
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={onApproveClick}
                >
                  {getLang(lang, "label.APPROVE")}
                </Button>
              </>
            ) : null}
            {consumerWarrantyDetail.status === "voided" ? (
              <>
                <Box p={1} />
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={onReinstateClick}
                >
                  {getLang(lang, "label.REINSTATE")}
                </Button>
              </>
            ) : null}
          </Box>
        </Box>
      )}
    </>
  );
}
