import React from "react";
import {
  Backdrop,
  Dialog,
  makeStyles,
  Typography,
  Paper,
  Box,
  Divider,
  Button,
  IconButton,
  Grid,
  CircularProgress,
  Link
} from "@material-ui/core";
import { Close as CloseIcon } from '@material-ui/icons';
import clsx from 'clsx';
import InputTextField from 'components/input/inputTextField';
import { convertToBigNum } from "lib/generalUtility";
import { getLang } from "app/feature/constants";
import moment from "moment";
import "moment/min/locales.min";

const useStyle = makeStyles((theme) => ({
  dialog: {
    maxWidth: "none"
  },
  root: {
    boxShadow: theme.shadows[5],
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "space-between",
    borderRadius: "0.25em",
    fontSize: "1rem",
    overflow: "hidden",
    "@media (max-width: 600px)": {
      maxWidth: "40em"
    },
    "@media (min-width: 600px)": {
      width: "34em"
    }
  },
  header: {
    paddingLeft: theme.spacing(3),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  containerBackground: {
    backgroundColor: "#EBEDEE"
  },
  content: {
    margin: "1em",
    backgroundColor: "#FFFFFF"
  },
  buttonContainer: {
    padding: "10px 16px",
    display: "flex",
    justifyContent: "flex-end"
  },
  selectRoot: {
    padding: "8px 14px"
  },
  inputRoot: {
    paddingTop: "8px !important",
    paddingBottom: "7px !important",
    paddingLeft: "12px !important",
    paddingRight: "12px !important"
  },
  fieldContainer: {
    paddingTop: "1em",
    margin: "1em",
    paddingBottom: "1em"
  },
  field: {
    display: "flex",
    alignItems: "flex-start"
  },
  field2: {
    display: "flex",
    alignItems: "center"
  },
  deselect: {
    borderColor: "red",
    color: "red"
  },
  selected: {
    backgroundColor: "red",
    color: "#fff"
  },
  form: {
    width: "100%"
  },
  completed: {
    fontWeight: "bold",
    color: "#FFFFFF",
    backgroundColor: "#32D583",
    width: "fit-content",
    padding: "0.1em 0.5em",
    borderRadius: "0.5em"
  },
  rejected: {
    fontWeight: "bold",
    color: "#FFFFFF",
    backgroundColor: "#F97066",
    width: "fit-content",
    padding: "0.1em 0.5em",
    borderRadius: "0.5em"
  },
  inProgress: {
    fontWeight: "bold",
    color: "#FFFFFF",
    backgroundColor: "#FDB022",
    width: "fit-content",
    padding: "0.1em 0.5em",
    borderRadius: "0.5em"
  },
  ellipsisText: {
    maxWidth: "18em",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: "0.75rem"
  },
  profilePhoto: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #E4E7EC",
    borderRadius: "50%",
    width: "24px",
    height: "24px",
    marginRight: "0.75em",
    "& img": {
      borderRadius: "50%",
      height: "24px",
      width: "24px"
    },
  },
  profileBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  }
}));

export default function RedemptionDetailsDialogComponent({
  hasStockflowDealerViewAccessRight,
  isOpen,
  handleClose,
  details,
  handleSelectedStatus,
  selectedStatus,
  language,
  remarks,
  handleChangeRemarks,
  handleUpdateStatus,
  isFetchingDetails,
  handleViewDealer,
  handleViewEmployee,
  lang,
  isDealerPage
}) {
  const classes = useStyle();
  
  if(language==="ZH")
  {
    moment.locale('zh-cn')
  }else if(language==="TL")
  {
    moment.locale('tl-ph')
  }else
  {
    moment.locale(language)
  }


  return (
    <Dialog
      maxWidth="sm"
      open={isOpen}
      onClose={handleClose}
      disableBackdropClick
      closeAfterTransition
      BackdropComponent={Backdrop}
      classes={{
        paperWidthSm: classes.dialog
      }}
    >
      <Paper elevation={0} className={classes.root}>
        <>
          <Box className={classes.header}>
            <Typography
              variant="subtitle2"
              color="primary"
              style={{ fontWeight: "bold" }}
            >
              {getLang(lang, 'label.VIEW_DETAILS')}
            </Typography>
            <IconButton color='primary' onClick={handleClose}>
              <CloseIcon fontSize='small' />
            </IconButton>
          </Box>
          <Divider />
          {!isFetchingDetails && (
            <Box style={{ padding: "24px" }}>
              <Box
                className={clsx({
                  [classes.inProgress]: details.status === "In Progress",
                  [classes.rejected]: details.status === "Rejected",
                  [classes.completed]: details.status === "Completed"
                })}
              >
                <Typography
                  variant="body2"
                >
                  {details.status}
                </Typography>
              </Box>
              <Box
                style={{
                  display: 'flex',
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 20
                }}
              >
                <Typography
                  variant="h6"
                  color="primary"
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  {details.orderId}
                </Typography>
                <Typography
                  variant="subtitle1"
                  color='primary'
                >
                  {moment(details.orderDatetime).format('lll')}
                </Typography>
              </Box>
              <Divider />
              <Box style={{ marginTop: 20, marginBottom: 20 }}>
                <Box style={{ marginBottom: 12 }}>
                  <Typography
                    variant="body1"
                    color="primary"
                    style={{ fontWeight: "bold" }}
                  >
                    {getLang(lang, 'label.DEALER_DETAILS')}
                  </Typography>
                </Box>
                <Box >
                  <Grid container style={{ marginBottom: 12 }}>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                      <Typography
                        variant="body2"
                        color="primary"
                      >
                        {getLang(lang, 'label.NAME')}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                      <Box className={classes.profileBox}>
                        <Box className={classes.profilePhoto}>
                          <img src={details.distributor.picture} alt={details.distributor.name} />
                        </Box>
                        {hasStockflowDealerViewAccessRight ? (
                          <Link
                            href={`/admin/stockflow/my-dealer/${details.distributor.uuid}`}
                            color="secondary"
                            onClick={() => handleViewDealer(details.distributor.uuid)}
                          >
                            <Typography className={classes.ellipsisText}>{details.distributor.name}</Typography>
                          </Link>
                        ) : (
                          <Typography className={classes.ellipsisText}>{details.distributor.name}</Typography>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container style={{ marginBottom: 12 }}>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                      <Typography
                        variant="body2"
                        color="primary"
                      >
                        {getLang(lang, 'label.CONTACT')}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                      <Typography
                        variant="body2"
                        color="primary"
                      >
                        {details.distributor.phone}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container style={{ marginBottom: 12 }}>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                      <Typography
                        variant="body2"
                        color="primary"
                      >
                        {getLang(lang, 'label.EMAIL')}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                      <Typography
                        variant="body2"
                        color="primary"
                      >
                        {details.distributor.email}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container style={{ marginBottom: 12 }}>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                      <Typography
                        variant="body2"
                        color="primary"
                      >
                        {getLang(lang, 'label.BALANCE_BEFORE')}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                      <Typography
                        variant="body2"
                        color="primary"
                      >
                        {convertToBigNum(details.balanceBefore)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                      <Typography
                        variant="body2"
                        color="primary"
                      >
                        {getLang(lang, 'label.BALANCE_AFTER')}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                      <Typography
                        variant="body2"
                        color="primary"
                      >
                        {convertToBigNum(details.balanceAfter)} (-{details.pointDeducted})
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              {details.status !== "In Progress" && details.status !== " " ? (
                <Box style={{ marginTop: 20, marginBottom: 20 }}>
                  <Box style={{ marginBottom: 12 }}>
                    <Typography
                      variant="body1"
                      color="primary"
                      style={{ fontWeight: "bold" }}
                    >
                      {getLang(lang, 'label.RESPOND_DETAILS')}
                    </Typography>
                  </Box>
                  <Box>
                    <Grid container style={{ marginBottom: 12 }}>
                      <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Typography
                          variant="body2"
                          color="primary"
                        >
                          {getLang(lang, 'label.RESPONSE_DATE')}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Typography
                          variant="body2"
                          color="primary"
                        >
                          {details.respondDatetime ?moment(details.respondDatetime).format('lll'): "-"}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container style={{ marginBottom: 12 }}>
                      <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Typography
                          variant="body2"
                          color="primary"
                        >
                          {getLang(lang, 'label.RESPONSE_BY')}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Box className={classes.profileBox}>
                          <Box className={classes.profilePhoto}>
                            <img src={details.respondBy ? details.respondBy.picture : null} alt={details.respondBy ? details.respondBy.name : "-"} />
                          </Box>
                          <Link
                            href={`/admin/employee/View/${details.respondBy.uuid}`}
                            color="secondary"
                            onClick={() => handleViewEmployee(details.respondBy.uuid)}
                          >
                            <Typography className={classes.ellipsisText}>{details.respondBy ? details.respondBy.name : "-"}</Typography>
                          </Link>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container style={{ marginBottom: 12 }}>
                      <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Typography
                          variant="body2"
                          color="primary"
                        >
                          {getLang(lang, 'label.REMARK')}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Typography
                          variant="body2"
                          color="primary"
                        >
                          {details.remarks ?? "-"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              ) : (
                <Box style={{ marginTop: 20, marginBottom: 20 }}>
                  <Box style={{ marginBottom: 12 }}>
                    <Typography
                      variant="body1"
                      color="primary"
                      style={{ fontWeight: "bold" }}
                    >
                      {getLang(lang, 'label.ACTION')}
                    </Typography>
                  </Box>
                  <Box style={{ marginBottom: 12, display: "flex", justifyContent: "flex-end" }}>
                    <Grid container>
                      <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Typography
                          variant="body2"
                          color="primary"
                        >
                          {getLang(lang, 'label.UPDATE_STATUS')}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Button
                      variant={selectedStatus === "Approve" ? "contained" : "outlined"}
                      color='primary'
                      onClick={() => handleSelectedStatus("Approve")}
                      disabled={isDealerPage}
                    >
                      {getLang(lang, 'label.APPROVED')}
                    </Button>
                    <Button
                      variant={selectedStatus === "Reject" ? "contained" : "outlined"}
                      style={{ marginLeft: 8 }}
                      className={clsx({
                        [classes.selected]: selectedStatus === "Reject",
                        [classes.deselect]: selectedStatus !== "Reject",
                      })}
                      onClick={() => handleSelectedStatus("Reject")}
                      disabled={isDealerPage}
                    >
                      {getLang(lang, 'label.REJECT')}
                    </Button>
                  </Box>
                  <Grid container style={{ marginBottom: 20 }}>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                      <Typography
                        variant="body2"
                        color="primary"
                      >
                        {getLang(lang, 'label.REMARK')}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                      <InputTextField
                        variant="filled"
                        size="small"
                        multiline
                        fullWidth
                        rows={4}
                        inputProps={{
                          style: {
                            padding: "5px"
                          }
                        }}
                        disabled={isDealerPage}
                        value={remarks}
                        onChange={(event) => handleChangeRemarks(event.target.value)}
                      />
                    </Grid>
                  </Grid>
                  <Divider />
                  <Box style={{ marginTop: "16px", display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      variant="outlined"
                      color='primary'
                      onClick={() => {
                        handleSelectedStatus("")
                        handleChangeRemarks("")
                      }}
                      disabled={isDealerPage}
                    >
                      {getLang(lang, 'label.RESET')}
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ marginLeft: 8 }}
                      onClick={() => handleUpdateStatus(details.uuid)}
                      disabled={isDealerPage}
                    >
                      {getLang(lang, 'label.UPDATE')}
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
          )}
          {isFetchingDetails && (
            <>
              <Box
                gridArea="main" mt={6}
                style={{
                  textAlign: "center",
                  paddingBottom: "4em"
                }}
              >
                <CircularProgress color="primary" />
              </Box>
            </>
          )}
        </>
      </Paper>
    </Dialog>
  )
}