import axios from "axios";

export function getCheckoutByDropdown(params) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/distributor/admin/api/v1/checkout/get-checkout-dropdown`, {
        params: {
          start: params.start,
          length: params.length,
          checkout_from_dealer: params.checkout_from_dealer,
        },
      })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data));
  });
}
