import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyle = makeStyles(theme => ({
  root: {
    margin: theme.spacing(0, 2),
    borderRadius: 10,
    overflow: "hidden",
    minHeight: "fit-content",
    height: "200px",
    "& iframe": {
      border: "none",
      overflow: "hidden"
    }
  }
}));

export default function VideoComponent({ url }) {
  const classes = useStyle();

  return (
    <div className={classes.root}>
      <iframe
        title="iframe-youtube"
        width="343"
        height="200"
        src={url}
      ></iframe>
    </div>
  );
}
