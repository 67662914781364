import React from 'react';
import { Info } from '@material-ui/icons';
import {
    makeStyles,
    Paper,
    Box,
    Typography,
    Divider,
    Grid
} from '@material-ui/core';
import { ErrorMessage } from 'formik';
import InputTextField from 'components/input/inputTextField';
import { SERIAL_NUMBER_TYPE_NUMBER } from 'modules/serial-number/constants';
import { getLang } from 'app/feature/constants';

const useStyle = makeStyles(theme => ({
    lastGenCard: {
        boxShadow: "0px 0px 4px 0px #adadad",
        marginTop: 10,
        position: "relative",
        '&::after': {
            content: `''`,
            position: "absolute",
            width: 11,
            height: 11,
            transform: "rotate(45deg)",
            top: -5,
            left: 8,
            backgroundColor: "white",
            boxShadow: "-2px -2px 2px -1px #dadada"
        }
    },
    lastGenValue: {
        fontWeight: 600,
        marginLeft: 20
    }
}));

export default function LastGeneratePanelComponent({
    formik,
    startingDigit,
    maxQuantity,
    lang,
    isInputDisable
}) {

    const classes = useStyle();

    return (
        <Paper classes={{ root: classes.lastGenCard }}>
            {formik.values.type === SERIAL_NUMBER_TYPE_NUMBER && (
                <>
                    <Box style={{
                        padding: "17px 20px",
                        display: "flex"
                    }}>
                        <Typography variant="body2" color="primary">
                            {getLang(lang, "label.STARTING_FROM")}
                        </Typography>
                        <Typography
                            classes={{
                                root: classes.lastGenValue
                            }}
                            variant="body2"
                            color="primary"
                        >
                            {startingDigit}
                        </Typography>
                    </Box>
                    <Divider />
                </>
            )}
            <Box
                style={{
                    padding: "15px 20px"
                }}
            >
                <Grid
                    container
                    alignItems="center"
                    justify="center"
                >
                    <Grid item xs={3}>
                        <Typography
                            variant="subtitle2"
                            color="primary"
                        >
                            {getLang(lang, "label.QUANTITY")}
                        </Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <InputTextField
                            {...formik.getFieldProps("generateAmount")}
                            hiddenLabel={true}
                            variant="filled"
                            fullWidth
                            disabled={isInputDisable}
                            placeholder={getLang(lang, "placeholder.ENTER_QUANTITY")}
                            type="number"
                            size="small"
                            onKeyDown={(e) => {
                                const invalidChars = [
                                    "-",
                                    "+",
                                    "e",
                                    ".",
                                ];
                                if (invalidChars.includes(e.key)) {
                                    e.preventDefault();
                                }
                            }}
                            onChange={e => {
                                const value = e.target.value;

                                formik.setFieldValue("generateAmount", value);
                            }}
                            inputProps={{
                                min: 1
                            }}
                            error={
                                formik.touched.generateAmount &&
                                    formik.errors.generateAmount
                                    ? true
                                    : false
                            }
                            helperText={ErrorMessage({ name: "generateAmount" })}
                        />
                    </Grid>
                </Grid>
                <Box
                    style={{
                        display: "flex",
                        margin: "15px 0px",
                        alignItems: "center"
                    }}
                >
                    <Info color="primary" />
                    <Typography
                        variant="body2"
                        display="block"
                        color="primary"
                        style={{
                            marginLeft: 5
                        }}
                    >
                        {getLang(lang, "label.MAX_ALLOWED_QUANTITY")} {maxQuantity}
                    </Typography>
                </Box>
            </Box>
        </Paper>
    );
}