/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import AdtInfoTitleExportPanelComponent from "./adtInfoTitleExportPanel.page";
import { useDispatch, useSelector } from "react-redux";
import { selectLoading } from "modules/notification";
import {
  getAdtInfoTitles,
  resetAdtinfoExportTitles,
} from "modules/serial-number/redux";
import EditAdtInfoTitleDialog from "modules/serial-number/components/dialog/editAdtInfoTitleDialog";
import CreateAdtInfoTitleDialog from "modules/serial-number/components/dialog/createAdtInfoTitleDialog";
import { generatePaginationOptions } from "lib/helper";

function AdtInfoTitleExportPanelContainer({ formik }) {
  const dispatch = useDispatch();
  const titles = useSelector((state) => state.additionalInfo.titles.list);
  const supportedLangs = useSelector(
    (state) => state.additionalInfo.titles.supportedLang
  );
  const isFetching = useSelector((state) =>
    selectLoading(state, getAdtInfoTitles.typePrefix)
  );
  const totalFiltered = useSelector(
    (state) => state.additionalInfo.titles.totalFiltered
  );
  const totalRecords = useSelector(
    (state) => state.additionalInfo.titles.totalRecords
  );
  const isError = useSelector((state) => state.additionalInfo.titles.isError);
  const lang = useSelector((state) => state.constant.languages);
  const [selectedTitle, setSelectedTitle] = useState(null);
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const [paginationOptions, setPaginationOptions] = useState([]);
  const [tableFilter, setTableFilter] = useState({
    rowsPerPage: 15,
    page: 0,
    search: "",
  });

  useEffect(() => {
    setPaginationOptions(generatePaginationOptions(totalRecords));
  }, [totalRecords]);

  useEffect(() => {
    getData();
  }, [tableFilter]);

  useEffect(() => {
    return () => {
      dispatch(resetAdtinfoExportTitles());
    };
  }, []);

  const getData = () => {
    dispatch(
      getAdtInfoTitles({
        length: tableFilter.rowsPerPage,
        start: tableFilter.rowsPerPage * tableFilter.page,
        search: tableFilter.search,
      })
    );
  };

  const handleEdit = (adtInfo) => {
    setSelectedTitle(adtInfo);
  };

  const handleChangePage = (event, newPage) => {
    setTableFilter({
      ...tableFilter,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setTableFilter({
      ...tableFilter,
      page: 0,
      rowsPerPage: event.target.value,
    });
  };

  const handleSearch = (value) => {
    setTableFilter({
      ...tableFilter,
      page: 0,
      search: value,
    });
  };

  const handleReload = () => {
    setTableFilter({
      ...tableFilter,
      page: 0,
    });
  };

  const handleClose = (title) => {
    setSelectedTitle(null);
    if (title) {
      handleReload();
    }
  };

  return (
    <>
      <AdtInfoTitleExportPanelComponent
        lang={lang}
        handleReload={handleReload}
        totalFiltered={totalFiltered}
        isError={isError}
        isFetching={isFetching}
        titles={titles}
        supportedLangs={supportedLangs}
        handleSearch={handleSearch}
        handleEdit={handleEdit}
        handleAddTitle={() => setShowCreateDialog(true)}
        handleChangePage={handleChangePage}
        handleChangeRowPerPage={handleChangeRowsPerPage}
        formik={formik}
        tableFilter={tableFilter}
        paginationOptions={paginationOptions}
        totalRecords={totalRecords}
      />
      <CreateAdtInfoTitleDialog
        isOpen={showCreateDialog}
        handleClose={() => setShowCreateDialog(false)}
        handleReload={handleReload}
        supportedLangs={supportedLangs}
      />
      <EditAdtInfoTitleDialog
        isOpen={!!selectedTitle}
        handleClose={handleClose}
        selectedTitle={selectedTitle}
        setSelectedAdtInfos={() => {}}
        supportedLangs={supportedLangs}
      />
    </>
  );
}

export default AdtInfoTitleExportPanelContainer;
