import React from "react";
import AdditionalInfoComponent from "./additionalInfo.component";
import { useSelector } from "react-redux";

export default function AdditionalInfoContainer({ id }) {
  const { isVisible } = useSelector(
    (state) => state.productPage.components[id]
  );
  const lang = useSelector((state) => state.constant.languages);

  return isVisible && <AdditionalInfoComponent lang={lang} />;
}
