/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState } from "react";
import StocktakeItemsBoxListPanelComponent from "./stocktakeItemsBoxListPanel.component";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  getStockTakeItemBoxList,
  resetStockTakeBoxListing,
} from "modules/stockflow/redux";
import StocktakeBoxDetailDialog from "../../dialog/stocktakeBoxDetailDialog";
import { selectLoading } from "modules/notification";
import { generatePaginationOptions } from "lib/helper";

export default function StocktakeItemsBoxListPanelContainer({
  lang,
  isSearchable,
  isCompleted,
  id,
  status, // all, extra, missing, matched,
}) {
  const dispatch = useDispatch();
  const isFetching = useSelector((state) =>
    selectLoading(state, getStockTakeItemBoxList.typePrefix)
  );
  const totalFiltered = useSelector(
    (state) => state.stockflowStockTake.stockTakeBoxes.totalFiltered
  );
  const list = useSelector(
    (state) => state.stockflowStockTake.stockTakeBoxes.list
  );
  const isError = useSelector(
    (state) => state.stockflowStockTake.stockTakeBoxes.isError
  );

  const [filter, setFilter] = useState({
    page: 0,
    rowsPerPage: 25,
    search: "",
    order: "asc",
    orderBy: "code",
  });
  const [paginationOptions, setPaginationOptions] = useState([]);
  const [selectedBox, setSelectedBox] = useState(null);

  useEffect(() => {
    fetchData();

    return () => {
      dispatch(resetStockTakeBoxListing());
    };
  }, [filter]);

  const initialLoad = useRef(true);

  useEffect(() => {
    if (!initialLoad.current) {
      dispatch(resetStockTakeBoxListing());
      setFilter({
        ...filter,
        page: 0,
        rowsPerPage: 25,
        search: "",
      });
    }
    initialLoad.current = false;
  }, [dispatch, status]);

  useEffect(() => {
    setPaginationOptions(generatePaginationOptions(totalFiltered));
  }, [totalFiltered]);

  const fetchData = () => {
    dispatch(
      getStockTakeItemBoxList({
        id,
        length: filter.rowsPerPage,
        start: filter.rowsPerPage * filter.page,
        search: filter.search,
        status,
        order: filter.order,
        order_by: filter.orderBy,
      })
    );
  };

  const handleChangePage = (event, newPage) => {
    let newData = {
      ...filter,
      page: newPage,
    };
    setFilter(newData);
  };

  const handleChangeRowsPerPage = (event) => {
    let newData = {
      ...filter,
      page: 0,
      rowsPerPage: event.target.value,
    };
    setFilter(newData);
  };

  const handleReload = () => {
    const newFilter = {
      ...filter,
      page: 0,
    };
    setFilter(newFilter);
  };

  const handleSearch = (values) => {
    dispatch(resetStockTakeBoxListing());
    const newFilter = {
      ...filter,
      page: 0,
      search: values.search,
    };
    setFilter(newFilter);
  };

  const handleClear = (values) => {
    dispatch(resetStockTakeBoxListing());
    const newFilter = {
      ...filter,
      page: 0,
      search: "",
    };
    setFilter(newFilter);
  };

  const changeOrder = (orderBy, order) => {
    let newData = {
      ...filter,
      order,
      orderBy,
    };
    setFilter(newData);
  };

  const viewBox = (box) => {
    setSelectedBox(box);
  };

  return (
    <>
      <StocktakeItemsBoxListPanelComponent
        lang={lang}
        isFetching={isFetching}
        list={list}
        totalFiltered={totalFiltered}
        isError={isError}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleReload={handleReload}
        changeOrder={changeOrder}
        isSearchable={isSearchable}
        filter={filter}
        handleSearch={handleSearch}
        isCompleted={isCompleted}
        viewBox={viewBox}
        handleClear={handleClear}
        paginationOptions={paginationOptions}
      />
      <StocktakeBoxDetailDialog
        isOpen={!!selectedBox}
        handleClose={() => setSelectedBox(null)}
        id={id}
        boxInfo={
          selectedBox
            ? {
                ...selectedBox,
                type: status,
              }
            : null
        }
      />
    </>
  );
}
