import { createSelector } from "@reduxjs/toolkit";

export const selectAlerts = createSelector(
  state => state.notification.alerts,
  allAlerts => allAlerts
);

export const selectLoading = createSelector(
  (state, loadingId) =>
    state.notification.inProcess.findIndex(({ id }) => id === loadingId),
  index => index !== -1
);
