import React, { useCallback } from "react";
import SetWinnerPanelComponent from "./setWinnerPanel.component";
import { selectLoading } from "modules/notification";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSerialNumberList,
  fetchMoreSerialNumber,
  setSerialNumberSearch
} from "modules/instant-lucky-draw/redux";

function SetWinnerPanelContainer({
  value,
  anchorRef,
  open,
  isOpenList,
  handleClose,
  handleListKeyDown,
  currentWinnerList,
}) {
  const dispatch = useDispatch();
  const hasMoretoLoad = useSelector(
    (state) => state.instantLuckyDraw.serialNumberList.hasMoretoLoad
  );
  const excludeList = useSelector(
    (state) => state.instantLuckyDraw.excludeWinnerList
  );

  const serialNumberData = useSelector(
    (state) => state.instantLuckyDraw.serialNumberList.data
  );
  const isFetching = useSelector((state) =>
    selectLoading(state, fetchSerialNumberList.typePrefix)
  );
  const lang = useSelector(state => state.constant.languages)

  const fetchMoreData = useCallback(() => {
    if (!isFetching && hasMoretoLoad) {
      dispatch(fetchMoreSerialNumber());
    }
  }, [isFetching, hasMoretoLoad, dispatch]);

  const handleSearch = (value) => {
    dispatch(setSerialNumberSearch(value));
  };

  return (
    <SetWinnerPanelComponent
      excludeList={currentWinnerList.concat(excludeList)}
      value={value}
      anchorRef={anchorRef}
      open={open}
      isOpenList={isOpenList}
      handleClose={handleClose}
      isFetching={isFetching}
      handleListKeyDown={handleListKeyDown}
      handleSearch={handleSearch}
      serialNumberData={serialNumberData}
      fetchMoreData={fetchMoreData}
      hasMoretoLoad={hasMoretoLoad}
      lang={lang}
    />
  );
}

export default SetWinnerPanelContainer;
