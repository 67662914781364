import React from "react";
import {
  Box,
  Button,
  Divider,
  IconButton,
  makeStyles,
  Paper,
  Typography,
  Backdrop
} from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { StepperComponent } from "components/stepper";
import Dialog from "components/dialog/customDialog";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles((theme) => ({
  header: {
    padding: "4px 0px",
    paddingLeft: theme.spacing(3),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  discardButton: {
    color: theme.palette.error.main
  },
  backButton: {
    marginRight: theme.spacing(1)
  },
  publishButton: {
    color: theme.palette.common.white
  }
}));

export default function CreateInstantLuckyDrawDialogComponent({
  steps,
  isOpen,
  handleNext,
  handleBack,
  handleClose,
  activeStep,
  termConditionFile,
  formRef,
  setTermConditionFile,
  luckyDrawImageFile,
  setLuckyDrawImageFile,
  lang
}) {
  const classes = useStyle();
  const SelectedStep = steps[activeStep];
  const isLastStep = activeStep === steps.length - 1;

  return (
    <Dialog
      isOpen={isOpen}
      handleClose={handleClose}
      disableEnforceFocus={true}
      disableAutoFocus={true}
      disableEscapeKeyDown={true}
      disableBackdropClick={true}
      closeAfterTransition
      BackdropComponent={Backdrop}
      maxWidth="1100px"
    >
      <Paper elevation={0}>
        <Box className={classes.header}>
          <Typography
            variant="subtitle2"
            color="primary"
            style={{ fontWeight: "bold" }}
          >
            {getLang(lang,"label.ADD_INSTANT_LUCKY_DRAW")}
          </Typography>
          <IconButton color="primary" onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
      </Paper>
      <Divider />
      <Box p={2} pb={0}>
        <StepperComponent steps={steps} activeStep={activeStep} />
      </Box>
      <Divider />
      <SelectedStep.form
        type={"create"}
        handleNext={handleNext}
        termConditionFile={termConditionFile}
        formRef={formRef}
        setTermConditionFile={setTermConditionFile}
        luckyDrawImageFile={luckyDrawImageFile}
        setLuckyDrawImageFile={setLuckyDrawImageFile}
      >
        {() => (
          <Box mt="auto">
            <Divider />
            <Box
              display="flex"
              justifyContent="space-between"
              px={2}
              pt={1}
              pb={1}
            >
              {activeStep === 0 ? (
                <Button
                  variant="text"
                  classes={{ root: classes.discardButton }}
                  onClick={handleClose}
                >
                  {getLang(lang,"label.DISCARD")}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  onClick={handleBack}
                  type="button"
                  classes={{ root: classes.backButton }}
                >
                  {getLang(lang,"label.BACK")}
                </Button>
              )}
              <Button
                variant="contained"
                color="secondary"
                classes={{ root: classes.publishButton }}
                disableElevation
                type="submit"
                onClick={isLastStep ? handleNext : null}
              >
                {isLastStep ? getLang(lang,"label.DONE") : getLang(lang,"label.NEXT")}
              </Button>
            </Box>
          </Box>
        )}
      </SelectedStep.form>
    </Dialog>
  );
}
