/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import GroupTransferRulePanelComponent from "./groupTransferRulePanel.component";

function GroupTransferRulePanelContainer({ lang, group }) {
  return (
    <>
      <GroupTransferRulePanelComponent lang={lang} group={group} />
    </>
  );
}

export default GroupTransferRulePanelContainer;
