import axios from "axios"

export default async function getConsumerGiftRedemptionListing({
    id,
    length,
    start,
    column,
    order,
    date,
    gift,
    status
}) {
    return new Promise((resolve, reject) => {
        axios
        .post(`/campaign/api/admin/orders/${id}/redemption-list`, {
            limit: length,
            skip: start,
            sortBy: column,
            orderBy: order,
            date,
            gift,
            status
        })
        .then((response) => resolve(response.data))
        .catch((error) => {
            return reject(error.response.data)
        })
    })
}
