import axios from "axios";

const connection = process.env.REACT_APP_AUTH_CONNECTION
const connectionId = process.env.REACT_APP_AUTH_CONNECTION_ID

export default function signIn({
    username,
    password,
    rememberMe
}) {
    return axios.post(`/authentication/api/basic/signin`,
        {
            connection,
            connection_id: connectionId,
            username,
            password,
            remember_me: rememberMe
        })
}