import axios from "axios";

const get = ({ id }) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/campaign/api/admin/lucky-draw-prizes/${id}`)
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

const put = ({
  luckyDrawId,
  name,
  type,
  imageUrl,
  order,
  quantity,
  title,
  description,
  id
}) => {
  const body = {
    luckyDrawId: luckyDrawId,
    name: name,
    type: type,
    imageUrl: imageUrl,
    order: order,
    quantity: quantity,
    title: title,
    description: description
  };

  return new Promise((resolve, reject) => {
    axios
      .put(`/campaign/api/admin/lucky-draw-prizes/${id}`, body)
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

const post = ({
  luckyDrawId,
  name,
  type,
  imageUrl,
  order,
  quantity,
  title,
  description
}) => {
  const body = {
    luckyDrawId: luckyDrawId,
    name: name,
    type: type,
    imageUrl: imageUrl,
    order: order,
    quantity: quantity,
    title: title,
    description: description
  };

  return new Promise((resolve, reject) => {
    axios
      .post("/campaign/api/admin/lucky-draw-prizes", body)
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

const prize = {
  put,
  get,
  post
};

export default prize;
