import React from "react";
import { Box } from "@material-ui/core";
import Skeleton from '@material-ui/lab/Skeleton';
import Grid from '@material-ui/core/Grid';

export const SkeletonProfileComponent = () => (
    <Box pt={2} width={'100%'}>
        <Skeleton width={'100%'} height={50} />

        <Grid container>
            <Grid item xs={4} sm={2} md={2} style={{ width: '100%' }}>
                <Skeleton variant="circle" width={100} height={100} />
            </Grid>
            <Grid item xs={8} sm={10} md={10} style={{ width: '100%' }}>
                <Skeleton width={'100%'} height={50} />
                <Skeleton width={'100%'} height={50} />
            </Grid>
        </Grid>

        <Box pt={1}>
            <Skeleton width={'100%'} height={50} />
            <Skeleton width={'100%'} height={50} />
            <Skeleton width={'100%'} height={50} />
            <Skeleton width={'100%'} height={50} />
            <Skeleton width={'100%'} height={50} />
        </Box>
    </Box>
);

export const SkeletonTimeComponent = () => (
    <Box pt={1}>
        <Box pt={1}>
            <Skeleton width={'100%'} height={50} />
            <Skeleton width={'100%'} height={50} />
        </Box>
    </Box>

);