import React from "react";
import {
  makeStyles,
  ButtonBase,
  Typography,
  Button,
  Box
} from "@material-ui/core";
import ClearIcon from '@material-ui/icons/Clear';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import clsx from 'clsx';
import { TIER_ICON_PROPS } from "modules/stockflow/constants";
import { getLang } from "app/feature/constants";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0.25em 0.75em",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: 4,
    border: "1px solid #D0D5DD",
    width: "100%",
    height: "4em",
    overflow: "hidden"
  },
  inputText: {
    fontSize: "1rem",
    color: "#a4a6a8",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  },
  selectedField: {
    display: "flex",
    alignItems: "center"
  },
  disabledField: {
    backgroundColor: "#eceff0",
  },
  filled: {
    color: "rgba(0, 0, 0, 0.87)",
  },
  clearButton: {
    minWidth: 0
  },
  uplineProfileTierCombo: {
    display: "flex",
    flexDirection: "row",
    marginRight: "0.5em"
  },
  tierIconUpline: {
    width: "1em",
    height: "1em",
    borderRadius: "3px",
    display: "flex",
    position: "absolute",
    alignItems: "center",
    justifyContent: "center"
  },
  tierLabelUpline: {
    color: "#FFFFFF",
    fontSize: "0.625rem"
  },
  profilePhotoUpline: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #E4E7EC",
    borderRadius: "50%",
    width: "33px",
    height: "33px",
    marginRight: "0.25em",
    marginLeft: "0.25em",
    marginTop: "0.25em",
    "& img": {
      borderRadius: "50%",
      height: "33px",
      width: "33px"
    },
  },
  uplineLabel: {
    fontWeight: 400,
    fontSize: "1rem",
    lineHeight: "1rem",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  uplineLabelDownlineCombo: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start"
  },
}))

export default function DealerSelectFieldComponent({
  handleClick,
  handleClear,
  value,
  placeholder,
  disabled,
  style,
  isEmpty,
  id,
  open,
  textStyle,
  isClearable,
  lang
}) {
  const classes = useStyles();

  const displayValue = () => {
    if (isEmpty) {
      return (
        <Typography
          variant='body2'
          className={clsx(classes.inputText, {
            [classes.filled]: !isEmpty
          })}
          style={textStyle}
        >
          {placeholder}
        </Typography>
      )
    }

    if (value !== undefined || value !== null) {
      return (
        <Box className={classes.selectedField}>
          <Box className={classes.uplineProfileTierCombo}>
            <Box
              className={classes.tierIconUpline}
              style={{
                backgroundColor: TIER_ICON_PROPS.find(({ tier }) => tier === value.tier).color
              }}
            >
              <Typography className={classes.tierLabelUpline}>
                T{value.tier}
              </Typography>
            </Box>
            <Box className={classes.profilePhotoUpline}>
              <img src={value.picture} alt={value.name} />
            </Box>
          </Box>
          <Box className={classes.uplineLabelDownlineCombo}>
            <Typography className={classes.uplineLabel}>
              {value.name}
            </Typography>
            <Typography variant="caption">
              {getLang(lang, 'label.DOWNLINE')}: {value.downlineCount}
            </Typography>
          </Box>
        </Box>
      )
    }
    return undefined
  }

  return (
    <ButtonBase
      aria-describedby={id}
      className={clsx(classes.root, {
        [classes.disabledField]: disabled
      })}
      disableRipple
      onClick={handleClick}
      disabled={disabled}
      style={{ paddding: 12, ...style }}
    >
      {displayValue()}
      <Box
        style={{
          display: "flex",
          alignItems: "center"
        }}
      >
        {isClearable && !isEmpty &&
          <Button
            className={classes.clearButton}
            onClick={(e) => {
              e.stopPropagation()
              handleClear()
            }}
          >
            <ClearIcon style={{ fontSize: 16 }} />
          </Button>
        }
        {!disabled && (open ? (
          <ArrowDropUpIcon fontSize='small' />
        ) : (
          <ArrowDropDownIcon fontSize='small' />
        ))}
      </Box>
    </ButtonBase>
  )
}