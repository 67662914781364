import React from "react";
import {
  makeStyles,
  Paper,
  Typography,
  Box,
  Button,
  Divider,
  Grid,
  Checkbox,
  FormControlLabel
} from "@material-ui/core";
import clsx from "clsx";
import { Formik } from "formik";
import * as Yup from "yup";
import ProfileHeader from "../../profileHeader";
import InputTextField from "components/input/inputTextField";
import { getLang } from "app/feature/constants";

const style = theme => ({
  paper: {
    display: "flex",
    flexFlow: "column nowrap",
    boxShadow: theme.shadows[0],
    height: "max-content",
    alignItems: "center"
  },
  passwordValidatorWrapper: {
    display: "flex",
    flexFlow: "row wrap",
    width: "100%"
  },
  pwValidator: {
    [theme.breakpoints.up("sm")]: {
      width: "50%"
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    },
    display: "flex",
    alignItems: "center"
  },
  dot: {
    height: 7,
    width: 7,
    borderRadius: "50%",
    backgroundColor: "#8DD054",
    margin: "4px"
  },
  dotOutside: {
    backgroundColor: "rgba(141, 208, 84, 0.5)",
    height: 15,
    width: 15,
    borderRadius: "50%",
    marginRight: theme.spacing(1)
  },
  grayDot: {
    height: 7,
    width: 7,
    borderRadius: "50%",
    backgroundColor: "gray",
    margin: "4px",
    opacity: 0.5
  },
  valid: {
    "& div:first-child": {
      backgroundColor: "green"
    },
    "& span": {
      fontWeight: theme.typography.fontWeightBold
    }
  },
  error: {
    marginLeft: "0.75rem",
    marginTop: "-0.5rem",
    background: "black"
  },
  pwStrengthLabel: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold
  },
  button: {
    display: "flex",
    justifyContent: "flex-end"
  },
  passwordRule: {
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(8)
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(5)
    }
  }
});

const useStyle = makeStyles(style);

export default function ChangePasswordPanelComponent({
  validators,
  handleSubmit,
  isChanging,
  IsShowPassword,
  showPassword,
  lang
}) {
  const classes = useStyle();
  return (
    <Box>
      <Paper elevation={0}>
        <ProfileHeader />
        <Paper elevation={0} className={classes.paper}>
          <Box mt={1} style={{ width: "100%" }}>
            <Formik
              initialValues={{
                currentPw: "",
                newPw: "",
                confirmPw: ""
              }}
              validationSchema={Yup.object({
                currentPw: Yup.string().required(getLang(lang,"message.error.FORM_VALIDATE_REQUIRED"))
                  .min(8, getLang(lang,"message.error.FORM_VALIDATE_MIN", {value: getLang(lang, "message.error.CHARACTERS_8")}))
                  .matches(/^(?=.*[a-z])/, getLang(lang,"message.error.FORM_VALIDATE_MATCH", {value: getLang(lang, "message.error.LOWERCASE_CHARACTER_1")}))
                  .matches(/^(?=.*[A-Z])/, getLang(lang,"message.error.FORM_VALIDATE_MATCH", {value: getLang(lang, "message.error.UPPERCASE_CHARACTER_1")}))
                  .matches(/[!@#$%^&~`*()_+\-={}[\]:";'<>,.?|]/, getLang(lang,"message.error.FORM_VALIDATE_MATCH", {value: getLang(lang, "message.error.SPECIAL_CHARACTER_1")}))
                  .matches(/^(?=.*[0-9])/, getLang(lang,"message.error.FORM_VALIDATE_MATCH", {value: getLang(lang, "message.error.NUMBER_1")})),
                newPw: Yup.string()
                  .required(getLang(lang,"message.error.FORM_VALIDATE_REQUIRED"))
                  .min(8, getLang(lang,"message.error.FORM_VALIDATE_MIN", {value: getLang(lang, "message.error.CHARACTERS_8")}))
                  .matches(/^(?=.*[a-z])/, getLang(lang,"message.error.FORM_VALIDATE_MATCH", {value: getLang(lang, "message.error.LOWERCASE_CHARACTER_1")}))
                  .matches(/^(?=.*[A-Z])/, getLang(lang,"message.error.FORM_VALIDATE_MATCH", {value: getLang(lang, "message.error.UPPERCASE_CHARACTER_1")}))
                  .matches(/[!@#$%^&~`*()_+\-={}[\]:";'<>,.?|]/, getLang(lang,"message.error.FORM_VALIDATE_MATCH", {value: getLang(lang, "message.error.SPECIAL_CHARACTER_1")}))
                  .matches(/^(?=.*[0-9])/, getLang(lang,"message.error.FORM_VALIDATE_MATCH", {value: getLang(lang, "message.error.NUMBER_1")})),
                confirmPw: Yup.string()
                  .oneOf([Yup.ref("newPw"), null], getLang(lang,"message.error.FORM_VALIDATE_PASSWORD"))
                  .required(getLang(lang,"message.error.FORM_VALIDATE_REQUIRED"))
              })}
              onSubmit={handleSubmit}
            >
              {(formik) => (
                <form onSubmit={formik.handleSubmit}>
                  <Box
                    p={3}
                    style={{
                      display: "flex",
                      placeContent: "center",
                      backgroundColor: "#F4F6F8"
                    }}
                  >
                    <Box
                      p={2}
                      px={3}
                      style={{
                        width: "90%",
                        background: "#fff"
                      }}
                    >
                      {[
                        {
                          label: getLang(lang,"label.CURRENT_PASSWORD"),
                          name: "currentPw",
                        },
                        {
                          label: getLang(lang,"label.NEW_PASSWORD"),
                          name: "newPw",
                        },
                        {
                          label: getLang(lang,"label.CONFIRM_NEW_PASSWORD"),
                          name: "confirmPw",
                        }
                      ].map((field, index) => {
                        return (
                          <Box key={index}>
                            {field.name === "newPw" && (
                              <Box mt={1} mb={1}>
                                <Divider />
                              </Box>
                            )}

                            <Box style={{ display: "flex" }}>
                              <Grid
                                container
                                style={{
                                  alignItems: "center"
                                }}
                              >
                                <Grid item xs={4} sm={4} md={4} style={{ width: "100%" }}>
                                  <Typography
                                    variant="body2"
                                    color="textSecondary"
                                  >
                                    {field.label} <Typography style={{ color: "#DE350B", display: 'inline-block' }}>*</Typography>
                                  </Typography>
                                </Grid>
                                <Grid item xs={8} sm={8} md={8} style={{ width: "100%" }}>
                                  <InputTextField
                                    name={field.name}
                                    type={showPassword ? "text" : "password"}
                                    fullWidth
                                    hiddenLabel
                                    size="small"
                                    autoComplete="off"
                                    variant="filled"
                                    margin="dense"
                                    color="primary"
                                    required
                                    InputProps={{
                                      disableUnderline: true,
                                      style: {
                                        padding: "5px",
                                        backgroundColor: "#ECEFF0",
                                        borderRadius: "4px"
                                      }
                                    }}
                                    disabled={formik.isSubmitting}
                                    {...formik.getFieldProps(field.name)}
                                    error={
                                      formik.touched[field.name] &&
                                        formik.errors[field.name]
                                        ? true
                                        : false
                                    }
                                    helperText={
                                      formik.touched[field.name] &&
                                        formik.errors[field.name]
                                        ? formik.errors[field.name]
                                        : null
                                    }
                                  />
                                  
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>
                        );
                      })}
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="secondary"
                            onChange={(state) => {
                              IsShowPassword(state.showPassword)
                            }}
                          />
                        }
                        label={<Typography variant="body2">
                          {getLang(lang,"label.SHOW_PASSWORD")}
                        </Typography>}
                      />

                      <Box className={classes.passwordRule}>
                        <Typography
                          variant="caption"
                          className={classes.pwStrengthLabel}
                        >
                          {getLang(lang,"label.PASSWORD_RULES")}
                        </Typography>
                        <Box
                          mt={1}
                          className={classes.passwordValidatorWrapper}
                        >
                          {validators(formik.values.newPw).map(
                            (value, index) => (
                              <Box key={index} className={classes.pwValidator}>
                                <Box
                                  className={clsx({
                                    [classes.dotOutside]: value.isValidated
                                  })}
                                >
                                  <Box
                                    className={clsx({
                                      [classes.grayDot]: !value.isValidated,
                                      [classes.dot]: value.isValidated
                                    })}
                                  />
                                </Box>
                                <Typography variant="caption">
                                  {value.name}
                                </Typography>
                              </Box>
                            )
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </Box>

                  <Box mb={3}>
                    <Divider />
                    <Box mt={3} pr={3} className={classes.button}>
                      <Button
                        color="primary"
                        variant="contained"
                        disableElevation
                        type="submit"
                        disabled={formik.isSubmitting || isChanging}
                      >
                        {getLang(lang,"label.UPDATE")}
                      </Button>
                    </Box>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </Paper>
      </Paper>
    </Box>
  );
}
