import axios from "axios";

const resetScanCount = ({
  enc
}) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`/serialnumber/api/v1/serialnumber/reset-scan-count/${enc}`)
      .then(response => resolve(response.data))
      .catch(error => {
        return reject(error.response.data)
      });
  });
};

export default resetScanCount;