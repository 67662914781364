import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useLocation, useRouteMatch } from "react-router-dom"
import { selectLoading } from 'modules/notification'
import LoadingComponent from "components/loading"
import { ROOT } from "modules/dashboard"
import { ROOT as CONSUMER_ROOT } from "modules/consumer"
import ConsumerDetailComponent from "./consumerDetails.component"
import { fetchAllScanTrack, fetchConsumerProfile } from "modules/consumer/redux/action/consumer.action"
import { CONSUMER_VIEW } from "lib/constants/accessRights"
import { resetConsumerData } from "modules/consumer/redux/slice/consumer.slice"
import qs from "query-string";
import { getLang } from "app/feature/constants"

function ConsumerDetailsContainer() {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const match = useRouteMatch()
  const id = match.params.consumerId
  const lang = useSelector(state => state.constant.languages)
  const language = useSelector(state => state.profile.language)
  const hasConsumerViewAccessRight = useSelector(state => state.profile.accessRights.includes(CONSUMER_VIEW))
  const consumer = useSelector(state => state.consumer.consumer)
  const isLoading = useSelector(state => selectLoading(state, fetchConsumerProfile.typePrefix))
  const isError = useSelector(state => state.consumer.isLoadingConsumerprofileError)
  const [activeIndex, setActiveIndex] = useState(null)

  const qsParams = qs.parse(location.search);
  const currentSearch = qsParams.tab || '';
  
  useEffect(() => {
    return () => {
      dispatch(resetConsumerData())
    }
  }, [dispatch])

  useEffect(() => {
    if (!hasConsumerViewAccessRight) {
      history.push('/admin/dashboard')
    }
  }, [history, hasConsumerViewAccessRight])

  useEffect(() => {
    if (id) {
      dispatch(fetchConsumerProfile(id))
      dispatch(fetchAllScanTrack({
        id,
        length: 0,
        order: "asc",
        column: "created_at"
      }))
    }
    else {
      history.push(ROOT)
    }
  }, [history, id, dispatch])

  useEffect(() => {
    let tempIndex= 0
    switch(currentSearch) {
      case "profile": 
        tempIndex = 0
        break;
      case "scan": 
        tempIndex = 1
        break;
      case "campaign": 
        tempIndex = 2
        break;
      case "gift": 
      case "point": 
        tempIndex = 3
        break;
      default:
        tempIndex = 0
    }
    setActiveIndex(tempIndex)
  }, [currentSearch])

  if (!hasConsumerViewAccessRight) return <LoadingComponent />

  let tabLabels = []
  if (hasConsumerViewAccessRight) {
    tabLabels.push(getLang(lang, "label.PROFILE"))
    tabLabels.push(getLang(lang, "label.SCAN_HISTORY"))
    tabLabels.push(getLang(lang, "label.CAMPAIGN"))
    tabLabels.push(getLang(lang, "label.GIFT_REDEMPTION_POINT"))
  }

  const handleChange = (event, index) => {
    setActiveIndex(index)

    let tab = "profile"
    if (index === 1) {
      tab = "scan"
    } else if (index === 2) {
      tab = "campaign"
    } else if (index === 3) {
      tab= "gift"
    }

    history.replace({
      pathname: `${CONSUMER_ROOT}/${id}`,
      search: `?tab=${tab}`
    })
  }

  const handleReload = () => {
    dispatch(fetchConsumerProfile(id))
  }

  return (
    <ConsumerDetailComponent
      tabLabels={tabLabels}
      activeIndex={activeIndex}
      handleChange={handleChange}
      consumer={consumer}
      isLoading={isLoading}
      language={language}
      isError={isError}
      handleReload={handleReload}
      id={id}
      lang={lang}
    />
  )
}

export default ConsumerDetailsContainer