import axios from "axios";
import store from "./config/store";
import { setMaintenanceStatus } from "modules/admin/redux";

const AxiosDefaultSetting = (props) => {
  axios.defaults.headers.common["Content-Type"] = "application/json";
  axios.defaults.baseURL = process.env.REACT_APP_API_HOST_URL;
  axios.interceptors.response.use(null, (error) => {
    if (
      process.env.REACT_APP_MAINTENANCE_MODE === "true" ||
      error.response?.data?.code === "error.ERROR_UNDER_MAINTENANCE"
    ) {
      store.dispatch(setMaintenanceStatus(true));
    }
    return Promise.reject(error);
  });
  return props.children;
};

export default AxiosDefaultSetting;
