import axios from "axios"

export default function updateOrder({
    id,
    status,
    adminRemark
}) {
    return new Promise((resolve, reject) => {
        axios.put(`/campaign/api/admin/orders/${id}`,
            {
                status,
                adminRemark: adminRemark
            })
            .then(response => resolve(response.data))
            .catch(error => reject(error.response.data))
    })
}