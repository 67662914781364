import { setChartColors } from "modules/reporting/utils/chartConfig";
import Chart from 'react-apexcharts';
import React from "react";
import moment from "moment";
import "moment/min/locales.min";

export default function VerticalBarChartComponent({
    payload,
    chartHeight, 
    chartColors=[], 
    stacked, 
    dontAddUnknown = true,
    translate,
    language
}){

    
  if(language==="ZH")
  {
    moment.locale('zh-cn')
  }else if(language==="TL")
  {
    moment.locale('tl-ph')
  }else
  {
    moment.locale(language)
  }


    const options = {
        chart: {
            type: 'bar',
            height: 350,
            stacked: stacked,
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            curve: "straight",
            lineCap: "butt",
            width: 2,
        },
        xaxis: {
            categories:payload.labels.map(date => moment(date).format('ll')),
            tickPlacement: 'between',
            labels: {
                hideOverlappingLabels: true,
                rotateAlways: true,
                rotate: -45,
            },
        },
        fill: {
            opacity: 1
        },
        tooltip:{
            // followCursor: true,
            y: {
                formatter: function (val) {
                    return parseInt(val)
                }
            },
            custom: function ({series, seriesIndex, dataPointIndex, w}) {
                let tooltip_contents = '';

                let total = 0;
                series.forEach((e, index) => {
                    tooltip_contents += 
                    '<div class="tooltip__content">' +
                        '<div class="tooltip__labels_container">' +
                            '<span style="background-color: ' + w.config.colors[index] + '" class="tooltip__marker"></span>' +
                            '<span class="tooltip__label">' + translate(payload.datasets[index].name) + '</span>' +
                        '</div>' +
                        '<span class="tooltip__value">' + Math.abs(series[index][dataPointIndex]) + '</span>' +
                    '</div>';
                    total += series[index][dataPointIndex];
                });
                tooltip_contents += 
                '<div class="tooltip__content" style="border-top: 1px solid black">' +
                    '<span style="font-weight:600">Total</span>' +
                    '<span class="tooltip__value">' + total + '</span>' +
                '</div>'

                return (
                    '<div class="tooltip__container">' +
                        '<div class="tooltip__header">' +
                            '<span class="tooltip__title">' + payload.labels[dataPointIndex] + '</span>' +
                        '</div>' +
                        tooltip_contents +
                    '</div>'
                )
            }
        },
        legend: {
            position: 'bottom',
            showForSingleSeries: true
        },
        colors: chartColors.length > 0 ? chartColors : setChartColors(payload.datasets.length, dontAddUnknown),
    }

    const chartSeries = () => {
        let series = JSON.stringify(payload);
        series = JSON.parse(series);

        series.datasets.forEach(e => {
            e.name = translate(e.name);
        });

        return series;
    }

    return (
        <Chart
            series={
                chartSeries().datasets
            }

            options={
                options
            }

            type="bar"
            height={chartHeight ?? 400}
        >

        </Chart>
    )
}