import { createSlice } from "@reduxjs/toolkit";
import {
  REPLACEMENTHANDOFF,
  getWarrantyHandoffDetail,
  getWarrantyHandoffList,
} from "../action";

const initialState = {
  handoffList: {
    list: [],
    totalFiltered: 0,
    totalRecords: 0,
    isError: false,
  },
  handoff: null,
  isLoadingHandoffError: false,
};

const replacementHandoffSlice = createSlice({
  name: REPLACEMENTHANDOFF,
  initialState,
  reducers: {
    resetHandoffList(state) {
      state.handoffList = initialState.handoffList;
    },
    resetHandoff(state) {
      state.handoff = initialState.handoff;
    },
  },
  extraReducers: {
    [getWarrantyHandoffList.pending]: (state) => {
      state.handoffList = initialState.handoffList;
    },
    [getWarrantyHandoffList.fulfilled]: (state, action) => {
      const { payload } = action;
      state.handoffList.list = payload.data;
      state.handoffList.totalRecords = payload.recordsTotal;
      state.handoffList.totalFiltered = payload.recordsFiltered;
    },
    [getWarrantyHandoffList.rejected]: (state) => {
      state.handoffList.isError = true;
    },
    [getWarrantyHandoffDetail.pending]: (state) => {
      state.handoff = initialState.handoff;
      state.isLoadingHandoffError = false;
    },
    [getWarrantyHandoffDetail.fulfilled]: (state, action) => {
      state.handoff = action.payload;
    },
    [getWarrantyHandoffDetail.rejected]: (state) => {
      state.isLoadingHandoffError = true;
    },
  },
});

export const { resetHandoffList, resetHandoff } =
  replacementHandoffSlice.actions;

export const replacementHandoffReducer = replacementHandoffSlice.reducer;
