import { selectLoading } from "modules/notification";
import { getCampaignWarrantyListData } from "modules/reporting/redux";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import WarrantyListPanel from './warrantyListPanel.component';

export default function WarrantyListPanelContainer({
    currentFilter,
    urlWarrantyList,
    campaignType,
    pageType,
    translate
}) {
    const dispatch = useDispatch();

    // (1) warranty list data -- start
    const isFetchingCampaignWarrantyList = useSelector(state => selectLoading(state, getCampaignWarrantyListData.typePrefix));
    const campaignWarrantyListData = useSelector(state => state.reporting.campaign.campaignWarrantyDatalist);
    // (1) warranty list data -- end

    const [tableFilter, setTableFilter] = useState({
        page: 0,
        rowsPerPage: 25,
        search: "",
        searchBy: "name",
        orderBy: "created_at",
        orderDirection: "desc",
    })
    
    useEffect(() => {
        const payload = {
            length: tableFilter.rowsPerPage,
            start: tableFilter.page * tableFilter.rowsPerPage,
            orderBy: tableFilter.orderBy,
            orderDirection: tableFilter.orderDirection,
            search: tableFilter.search,
            searchBy: tableFilter.searchBy,
            branch_uuid: currentFilter.branchUuid,
            period: currentFilter.period,
            start_date: moment(currentFilter.dateRange[0]).format('YYYY-MM-DD'),
            end_date: moment(currentFilter.dateRange[1]).format('YYYY-MM-DD'),
            url: urlWarrantyList
        }

        if(campaignType.campaignTypeValue)
            payload.campaign_type = campaignType.campaignTypeValue;
        
        dispatch(getCampaignWarrantyListData(payload));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, tableFilter, currentFilter]);

    const handleChangePage = (e, newPage) => {
        setTableFilter((prev) => ({
          ...prev,
          page: newPage,
        }))
    }

    const handleChangeRowPerPage = (event) => {
        setTableFilter((prev) => ({
          ...prev,
          page: 0,
          rowsPerPage: event.target.value
        }))
    }

    const handleRequestSort = (event, property) => {
        const isAsc = tableFilter.orderBy === property && tableFilter.orderDirection === 'asc';
        setTableFilter((prev) => ({
            ...prev,
            orderDirection: isAsc ? 'desc' : 'asc',
            orderBy: property,
        }))
    }

    const columns = [
        {
            title: translate("label.NUM"),
            field: null,
            render : (row) => {
                return (
                    <>{row.title}</>
                )
            }
        },
        {
            title: translate("label.NAME"),
            field: null,
            render : (row) => {
                return (
                    <>{row.title}</>
                )
            },
            size: 'small'

        },
        {
            title: 'Product(s) - Engagement',
            field: null,
            render : (row) => {
                return (
                    <>{row.title}</>
                )
            },
            size: 'small'
        },
        {
            title: translate("label.TOTAL_WARRANTEES"),
            field: null,
            render : (row) => {
                return (
                    <>{row.title}</>
                )
            },
            size: 'small'
        },
        {
            title: translate("label.DATE_CREATED"),
            field: 'created_at',
            render : (row) => {
                return (
                    <>{row.title}</>
                )
            },
            size: 'small'
        },
        {
            title: translate("label.WARRANTY_PERIOD"),
            field: null,
            render : (row) => {
                return (
                    <>{row.title}</>
                )
            },
            size: 'small'
        },
        {
            title: translate("label.STATUS"),
            field: null,
            render : (row) => {
                return (
                    <>{row.title}</>
                )
            },
            size: 'small'
        },
    ];

    return (
        <WarrantyListPanel 
            status={{
                isFetchingCampaignWarrantyList
            }}
            
            data={{
                campaignWarrantyListData
            }}

            sort={{
                valueToOrderBy: tableFilter.orderBy,
                orderDirection: tableFilter.orderDirection,
                handleRequestSort: handleRequestSort,
            }}

            pagination={{
                handleChangePage: handleChangePage,
                handleChangeRowPerPage: handleChangeRowPerPage,
                page: tableFilter.page,
                rowsPerPage: tableFilter.rowsPerPage,
                rowsPerPageOptions: [5, 10, 25],
            }}

            columns = {columns}
            pageType = {pageType}
            currentFilter = {currentFilter}

            translate={translate}
        
        />
    )
}