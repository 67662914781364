import axios from "axios";

export function getProductCategoryDropdown({
  search,
}) {
  const url =  `/serialnumber/api/v1/product/category/get-dropdown-list`;

  return new Promise((resolve, reject) => {
    axios.get(url, {
      params: {
        search: search
      }
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error.response))
  })
}