import React from "react";
import {
  Button,
  makeStyles,
  Dialog,
  Box,
  InputAdornment,
} from "@material-ui/core";
import Picker from "react-mobile-picker";
import { getLang } from "app/feature/constants";
import InputTextField from "components/input/inputTextField";
import {
  Event as EventIcon,
  ErrorRounded as ErrorRoundedIcon,
} from "@material-ui/icons";

const useStyle = makeStyles((theme) => ({
  underline: {
    "&:before": {
      borderBottom: "2px solid",
      borderBottomColor: theme.palette.primary.main,
    },
  },
  input: {
    fontSize: "14px",
    fontWeight: "bold",
    color: theme.palette.common.black,
  },
  dialogAction: {
    display: "flex",
    justifyContent: "flex-end",
    padding: 12,
  },
  button: {
    minWidth: "auto",
  },
}));

export default function DatePickerScrollDialogComponent({
  label,
  isOpen,
  setIsOpen,
  lang,
  months,
  startYear,
  date,
  defaultDate,
  pickerValue,
  handlePickerChange,
  getDayArray,
  selectDate,
  error,
  inputProps,
  InputProps,
  disabled,
}) {
  const classes = useStyle();

  return (
    <>
      <InputTextField
        value={date}
        hiddenLabel={true}
        variant="filled"
        fullWidth
        type="text"
        size="small"
        placeholder={getLang(lang, "placeholder.SELECT_DATE")}
        InputStyle={{
          backgroundColor: "white",
          border: "1px solid #D0D5DD",
          borderRadius: "4px",
        }}
        disabled={disabled}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              {!!error && (
                <ErrorRoundedIcon
                  style={{
                    color: "#f44336",
                    marginLeft: 8,
                  }}
                />
              )}
              <Button
                onClick={() => setIsOpen(true)}
                className={classes.button}
              >
                <EventIcon />
              </Button>
            </InputAdornment>
          ),
          ...InputProps,
        }}
        inputProps={{
          min: 0,
          ...inputProps,
        }}
        onClick={() => setIsOpen(true)}
      />
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        disableEscapeKeyDown
        aria-labelledby="picker-dialog"
        fullWidth
        maxWidth="xs"
      >
        <Box>
          <Picker
            value={pickerValue}
            onChange={handlePickerChange}
            wheelMode="natural"
          >
            <Picker.Column name="day">
              {getDayArray(Number(pickerValue.year), pickerValue.month).map(
                (day) => (
                  <Picker.Item key={day} value={day}>
                    {({ selected }) => (
                      <div
                        className={
                          selected
                            ? "font-semibold text-neutral-900"
                            : "text-neutral-400"
                        }
                      >
                        {day}
                      </div>
                    )}
                  </Picker.Item>
                )
              )}
            </Picker.Column>

            <Picker.Column name="month">
              {months.map((month) => (
                <Picker.Item key={month} value={month}>
                  {({ selected }) => (
                    <div
                      className={
                        selected
                          ? "font-semibold text-neutral-900"
                          : "text-neutral-400"
                      }
                    >
                      {getLang(lang, "label." + month.toUpperCase() + "_MONTH")}
                    </div>
                  )}
                </Picker.Item>
              ))}
            </Picker.Column>
            <Picker.Column name="year">
              {Array.from({ length: 200 }, (_, i) => `${startYear + i}`).map(
                (year) => (
                  <Picker.Item key={year} value={year}>
                    {({ selected }) => (
                      <div
                        className={
                          selected
                            ? "font-semibold text-neutral-900"
                            : "text-neutral-400"
                        }
                      >
                        {year}
                      </div>
                    )}
                  </Picker.Item>
                )
              )}
            </Picker.Column>
          </Picker>
          <Box className={classes.dialogAction}>
            <Button
              color="secondary"
              variant="contained"
              type="button"
              onClick={() => {
                selectDate();
              }}
            >
              {getLang(lang, "label.CONFIRM")}
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
}
