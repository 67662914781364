import React from "react";
import { makeStyles, Box, Typography } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyle = makeStyles((theme) => ({
  card: {
    borderRadius: 12,
    background: "white",
    border: "1px solid #D0D5DD",
    padding: 10,
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  },
}));

export default function SnSummaryCardComponent({ value, title, isLoading }) {
  const classes = useStyle();

  return (
    <Box className={classes.card}>
      <Box>
        <Typography variant="body1" style={{ color: "#7f7f7f" }}>
          {title}
        </Typography>
      </Box>
      <Box mt={0.5} mb={1} ml={0.5} display="flex" alignItems="flex-end">
        {isLoading ? (
          <Skeleton variant="text" animation="wave" width="100%" height={30} />
        ) : (
          <Typography variant="h5" style={{ marginRight: 4 }}>
            <b>{value?.toLocaleString("en-US") || 0}</b>
          </Typography>
        )}
      </Box>
    </Box>
  );
}
