export const SEARCH_NAME_COLUMN = "name"
export const SEARCH_UPLINE_NAME_COLUMN = "upline_name"
export const TIER_ICON_PROPS = [
  {
    tier: 1,
    label: "T1",
    color: "#700CAD"
  },
  {
    tier: 2,
    label: "T2",
    color: "#E60000"
  },
  {
    tier: 3,
    label: "T3",
    color: "#DC6803"
  },
  {
    tier: 4,
    label: "T4",
    color: "#FDB022"
  },
  {
    tier: 5,
    label: "T5",
    color: "#046BE3"
  },
  {
    tier: 6,
    label: "T6",
    color: "#6AAF68"
  },
  {
    tier: 7,
    label: "T7",
    color: "#B757F1"
  },
  {
    tier: 8,
    label: "T8",
    color: "#F44141"
  },
  {
    tier: 9,
    label: "T9",
    color: "#F79009"
  },
  {
    tier: 10,
    label: "T10",
    color: "#FEC84B"
  },
  {
    tier: 11,
    label: "T11",
    color: "#3089F1"
  },
  {
    tier: 12,
    label: "T12",
    color: "#98C897"
  }
]

export const DEALER_STATUS = [
  {
    code: "active",
    text: "Activated",
    color: "#6AAF68"
  },
  {
    code: "inactive",
    text: "Deactivated",
    color: "#FD646F"
  },
  {
    code: "pending",
    text: "Pending Verification",
    color: "#FDB022"
  }
]

export const REWARD_STATUS = [
  {
    code: "active",
    text: "Activated",
    color: "#6AAF68"
  },
  {
    code: "inactive",
    text: "Deactivated",
    color: "#FD646F"
  }
];

export const STATUS = [
  {
    code: "active",
    text: "Activated",
    color: "#6AAF68"
  },
  {
    code: "inactive",
    text: "Deactivated",
    color: "#FD646F"
  }
]