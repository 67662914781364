import { selectLoading } from "modules/notification";
import { getBrowserData, getDeviceData, getLanguageData, getPlatformData } from "modules/reporting/redux";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import MiscellaneousPanel from "./miscellaneousPanel.component";
import moment from "moment";

import { unwrapResult } from "@reduxjs/toolkit";

export default function MiscellaneousPanelContainer({
    currentFilter,
    urlPlatform,
    urlBrowser,
    urlDevice,
    urlLanguage,
    translate
}) {
    // (2) miscellaneous data -- start
    // (a) get miscellaneous: browser data
    const isFetchingBrowserData = useSelector(state => selectLoading(state, getBrowserData.typePrefix));
    const browserData = useSelector(state => state.reporting.miscellaneous.browserDatalist);

    // (b) get miscellaneous: device data
    const isFetchingDeviceData = useSelector(state => selectLoading(state, getDeviceData.typePrefix));
    const deviceData = useSelector(state => state.reporting.miscellaneous.deviceDatalist);

    // (c) get miscellaneous: language data
    const isFetchingLanguageData = useSelector(state => selectLoading(state, getLanguageData.typePrefix));
    const languageData = useSelector(state => state.reporting.miscellaneous.languageDatalist);

    // (d) get miscellaneous: platform data
    const isFetchingPlatformData = useSelector(state => selectLoading(state, getPlatformData.typePrefix));
    const platformData = useSelector(state => state.reporting.miscellaneous.platformDatalist);

    const language = useSelector(state => state.profile.language);
    // (2) miscellaneous data -- end

    const dispatch = useDispatch();
    useEffect(() => {
        
        dispatch(getBrowserData({
            countryCode: currentFilter.countryCode,
            city_uuid: currentFilter.cityUuid,
            branch_uuid: currentFilter.branchUuid,
            category_uuid: currentFilter.categoryUuid,
            model_uuid: currentFilter.modelUuid,
            brand_uuid: currentFilter.brandUuid,
            period: currentFilter.period,
            product_uuid: currentFilter.productUuid,
            start_date: moment(currentFilter.dateRange[0]).format('YYYY-MM-DD'),
            end_date: moment(currentFilter.dateRange[1]).format('YYYY-MM-DD'),
            url: urlBrowser,
            campaign_type: currentFilter.campaignType,
            campaign_uuid: currentFilter.campaignUuid,
        })).then(unwrapResult);
        dispatch(getDeviceData({
            countryCode: currentFilter.countryCode,
            city_uuid: currentFilter.cityUuid,
            branch_uuid: currentFilter.branchUuid,
            category_uuid: currentFilter.categoryUuid,
            model_uuid: currentFilter.modelUuid,
            brand_uuid: currentFilter.brandUuid,
            period: currentFilter.period,
            product_uuid: currentFilter.productUuid,
            start_date: moment(currentFilter.dateRange[0]).format('YYYY-MM-DD'),
            end_date: moment(currentFilter.dateRange[1]).format('YYYY-MM-DD'),
            url: urlDevice,
            campaign_type: currentFilter.campaignType,
            campaign_uuid: currentFilter.campaignUuid,
        })).then(unwrapResult);
        dispatch(getLanguageData({
            countryCode: currentFilter.countryCode,
            city_uuid: currentFilter.cityUuid,
            branch_uuid: currentFilter.branchUuid,
            category_uuid: currentFilter.categoryUuid,
            model_uuid: currentFilter.modelUuid,
            brand_uuid: currentFilter.brandUuid,
            period: currentFilter.period,
            product_uuid: currentFilter.productUuid,
            start_date: moment(currentFilter.dateRange[0]).format('YYYY-MM-DD'),
            end_date: moment(currentFilter.dateRange[1]).format('YYYY-MM-DD'),
            url: urlLanguage,
            campaign_type: currentFilter.campaignType,
            campaign_uuid: currentFilter.campaignUuid,
        })).then(unwrapResult);
        dispatch(getPlatformData({
            countryCode: currentFilter.countryCode,
            city_uuid: currentFilter.cityUuid,
            branch_uuid: currentFilter.branchUuid,
            category_uuid: currentFilter.categoryUuid,
            model_uuid: currentFilter.modelUuid,
            brand_uuid: currentFilter.brandUuid,
            period: currentFilter.period,
            product_uuid: currentFilter.productUuid,
            start_date: moment(currentFilter.dateRange[0]).format('YYYY-MM-DD'),
            end_date: moment(currentFilter.dateRange[1]).format('YYYY-MM-DD'),
            url: urlPlatform,
            campaign_type: currentFilter.campaignType,
            campaign_uuid: currentFilter.campaignUuid,
            })).then(unwrapResult);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , [dispatch, currentFilter])

    return (
        <MiscellaneousPanel 
            status={{
                isFetchingBrowserData,
                isFetchingDeviceData,
                isFetchingLanguageData,
                isFetchingPlatformData
            }}

            language = {language}

            data={{
                browserData,
                deviceData,
                languageData,
                platformData
            }}

            translate={translate}
        />
    );
}