import React from 'react'
import {
  Box,
  Button,
  Checkbox,
  Divider,
  IconButton,
  makeStyles,
  Paper,
  Radio,
  Typography
} from '@material-ui/core'
import {
  Close as CloseIcon
} from '@material-ui/icons'
import { surveyQuestionType } from 'modules/survey/constants';
import InputTextField from 'components/input/inputTextField';
import { Skeleton } from '@material-ui/lab';
import { getLang } from 'app/feature/constants';
import SearchableSelect from "components/select/searchableSelect";

const useStyle = makeStyles(theme => ({
  root: {
    boxShadow: theme.shadows[5],
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "space-between",
    width: 600,
    borderRadius: "4px",
    fontSize: 14
  },
  title: {
    fontWeight: "bold",
    fontSize: 17,
    marginBottom: 10
  },
  detailsWrapper: {
    padding: theme.spacing(3, 5)
  },
  header: {
    paddingLeft: theme.spacing(3),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
}));

export default function ViewSurveyFormComponent({
  hasSurveyEditAccessRight,
  survey,
  handleClose,
  isLoading,
  handleEdit,
  lang
}) {
  const classes = useStyle()

  const OptionItem = ({ name }) => (
    <Box style={{
      display: "flex",
      alignItems: "center"
    }}>
      <Checkbox
        disabled
        color="primary"
      />
      <Typography variant="body2" color="primary">
        {name}
      </Typography>
    </Box>
  )
  const RadioItem = ({ name }) => (
    <Box style={{
      display: "flex",
      alignItems: "center"
    }}>
      <Radio
        disabled
        color="primary"
      />
      <Typography variant="body2" color="primary">
        {name}
      </Typography>
    </Box>
  )
  return (
    <Paper elevation={0} className={classes.root}>
      <div className={classes.header}>
        <Typography variant="subtitle2" color="primary" style={{ fontWeight: "bold" }}>
          {getLang(lang, 'label.VIEW_SURVEY')}
        </Typography>
        <IconButton color="primary" onClick={handleClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </div>
      <Divider />
      <Box style={{ display: "flex", flexDirection: "column", alignItems: "stretch" }}>
        <Box className={classes.detailsWrapper}>
          <Typography color="primary" className={classes.title}>
            {isLoading ? <Skeleton animation="wave" style={{ maxWidth: 300 }} /> : survey.name}
          </Typography>
          <Typography variant="body2" color="primary">
            {isLoading ? <Skeleton variant="rect" animation="wave" height={100} /> : survey.description}
          </Typography>
          <Box style={{
            marginTop: 30
          }}>
            {isLoading ?
              [...new Array(3)].map(i => (
                <Skeleton
                  key={i}
                  variant="rect"
                  animation="wave"
                  height={150}
                  style={{ marginBottom: 10 }}
                />
              ))
              :
              survey.questions && survey.questions.map((question, i) => (
                <Box key={i} style={{ margin: "15px 0px" }}>
                  <Typography variant="subtitle2" color="primary" style={{ marginBottom: 5 }}>
                    {i + 1}. {question.text} <span style={{ color: "#FD646F" }}>{question.required && (<Typography style={{ color: "#DE350B", display: 'inline-block' }}>*</Typography>)}</span>
                  </Typography>
                  {question.type === surveyQuestionType.text ?
                    <InputTextField
                      hiddenLabel
                      variant="filled"
                      placeholder={question.placeholder}
                      fullWidth
                      InputStyle={{
                        fontSize: 14
                      }}
                      disabled={true}
                    />
                    :
                    question.type === "select" || question.type === "multipleChoiceMultiSelect" ?
                      <Box>
                        {question.type === "select" ?
                          question.answers.map((answer, i) => (
                            <RadioItem key={answer} name={answer} />
                          ))
                          : question.answers.map((answer, i) => (
                            <OptionItem key={answer} name={answer} />
                          ))
                        }
                      </Box>
                      :
                      <Box>
                        <SearchableSelect
                          placeholder={getLang(lang, "placeholder.SELECT_STATUS")}
                          searchable={true}
                          style={{ height: "2.5rem"}}
                          viewOnly={true}
                          PaperProps={{
                            classes: { root: classes.paperRoot },
                          }}
                          dropdownItem={question.answers}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                        />
                      </Box>
                  }
                </Box>
              ))
            }
          </Box>
        </Box>
      </Box>
      {
        hasSurveyEditAccessRight
          ?
          <>
            <Divider />
            <Box p={2} display="flex" justifyContent="flex-end">
              <Button
                onClick={handleClose}
                disableElevation
                style={{ marginRight: "4px" }}
                type="button"
              >
                {getLang(lang, 'label.CLOSE')}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                disableElevation
                disabled={isLoading}
                onClick={handleEdit}
              >
                {getLang(lang, 'label.EDIT')}
              </Button>
            </Box>
          </>
          : null
      }
    </Paper>
  )
}
