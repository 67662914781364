import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SelectTargetBranchDialogComponent from "./selectTargetBranchDialog.component";

export default function SelectTargetBranchDialogContainer({
  isOpen,
  handleClose,
  addTargetBranch,
}) {
  const lang = useSelector((state) => state.constant.languages);

  const [selectedBranch, setSelectedBranch] = useState([]);

  useEffect(() => {
    if (!isOpen) {
      setSelectedBranch([]);
    }
  }, [isOpen]);

  const handleSubmit = () => {
    addTargetBranch(selectedBranch);
  };

  return (
    <SelectTargetBranchDialogComponent
      isOpen={isOpen}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
      lang={lang}
      selectedBranch={selectedBranch}
      updateSelectedBranch={setSelectedBranch}
    />
  );
}
