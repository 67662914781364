/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CampaignTngDisbursementPanelComponent from "./campaignTngDisbursementPanel.component";
import { selectLoading } from "modules/notification";
import {
  exportCreditReport,
  getCampaignTngDisbursementList,
  resetCampaignDisbursementLog,
} from "modules/account/redux";
import { generatePaginationOptions } from "lib/helper";

export default function CampaignTngDisbursementPanelContainer({
  disbursementInfo,
}) {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.constant.languages);
  const isFetching = useSelector((state) =>
    selectLoading(state, getCampaignTngDisbursementList.typePrefix)
  );
  const campaignLogs = useSelector(
    (state) => state.disbursement.campaignDisbursementLogs.list
  );
  const isError = useSelector(
    (state) => state.disbursement.campaignDisbursementLogs.isError
  );
  const totalFiltered = useSelector(
    (state) => state.disbursement.campaignDisbursementLogs.totalFiltered
  );
  const token = useSelector((state) => state.session.accessToken);

  const [paginationOptions, setPaginationOptions] = useState([]);
  const [tableFilter, setTableFilter] = useState({
    page: 0,
    rowsPerPage: 25,
  });

  useEffect(() => {
    return () => {
      dispatch(resetCampaignDisbursementLog());
    };
  }, []);

  useEffect(() => {
    handleDataLoad();
  }, [tableFilter]);

  useEffect(() => {
    setPaginationOptions(generatePaginationOptions(totalFiltered));
  }, [totalFiltered]);

  const handleDataLoad = () => {
    let payload = {
      length: tableFilter.rowsPerPage,
      start: tableFilter.rowsPerPage * tableFilter.page,
    };
    dispatch(getCampaignTngDisbursementList(payload));
  };

  const handleChangePage = (event, newPage) => {
    let newData = {
      ...tableFilter,
      page: newPage,
    };
    setTableFilter(newData);
  };

  const handleChangeRowsPerPage = (event) => {
    let newData = {
      ...tableFilter,
      page: 0,
      rowsPerPage: event.target.value,
    };
    setTableFilter(newData);
  };

  const handleExport = (record) => {
    const info = {
      token: token,
      id: record.uuid,
    };
    dispatch(exportCreditReport(info));
  };

  return (
    <CampaignTngDisbursementPanelComponent
      lang={lang}
      campaignLogs={campaignLogs}
      totalFiltered={totalFiltered}
      isFetching={isFetching}
      isError={isError}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      tableFilter={tableFilter}
      handleReload={handleDataLoad}
      paginationOptions={paginationOptions}
      handleExport={handleExport}
      disbursementInfo={disbursementInfo}
    />
  );
}
