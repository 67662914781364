/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import RequestTransferAddressDialogComponent from "./requestTransferAddressDialog.component";
import { useDispatch, useSelector } from "react-redux";
import { selectLoading } from "modules/notification";
import {
  addRequestTransferAddress,
  getRequestTransferAddressList,
  resetAddressList,
  updateAddress,
  updateRequestTransferAddress,
} from "modules/stockflow/redux";
import RequestTransferAddressCreateDialog from "../requestTransferAddressCreateDialog";
import { unwrapResult } from "@reduxjs/toolkit";

function RequestTransferAddressDialogContainer({
  isOpen,
  handleClose,
  receiver,
  updateReceiver,
  branch,
}) {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.constant.languages);

  const isFetching = useSelector((state) =>
    selectLoading(state, getRequestTransferAddressList.typePrefix)
  );
  const addresses = useSelector(
    (state) => state.stockflowRequestTransfer.addressList.list
  );
  const isError = useSelector(
    (state) => state.stockflowRequestTransfer.addressList.isError
  );
  const totalFiltered = useSelector(
    (state) => state.stockflowRequestTransfer.addressList.totalFiltered
  );

  const [selectedAddress, setSelectedAddress] = useState(null);
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [selectedEditAddr, setSelectedEditAddr] = useState(null);
  const [filter, setFilter] = useState({
    page: 0,
    rowsPerPage: 25,
  });

  const branchRef = useRef(branch);

  useEffect(() => {
    if (branch) {
      if (branchRef.current && branchRef.current.uuid !== branch.uuid) {
        handleReload();
      } else {
        handleDataLoad();
      }
    }
    branchRef.current = branch;

    return () => {
      dispatch(resetAddressList());
    };
  }, [branch, filter]);

  useEffect(() => {
    if (addresses.length && !receiver) {
      let address = addresses.find((a) => !!a.is_default);
      updateReceiver(address ?? addresses[0]);
      setSelectedAddress(address ?? addresses[0]);
    }
  }, [addresses]);

  useEffect(() => {
    if (selectedEditAddr) {
      setShowAddDialog(true);
    }
  }, [selectedEditAddr]);

  const handleDataLoad = () => {
    let payload = {
      from_node_uuid: branch.uuid,
      length: filter.rowsPerPage,
      start: filter.rowsPerPage * filter.page,
    };
    dispatch(getRequestTransferAddressList(payload));
  };

  const handleReload = () => {
    dispatch(resetAddressList());
    setFilter({
      ...filter,
      page: 0,
    });
  };

  const handleFetchMoreItems = () => {
    setFilter({
      ...filter,
      start: filter.page + 1,
    });
  };

  const selectAddress = (address) => {
    setSelectedAddress(address);
  };

  const handleSubmit = (values) => {
    let params = {
      branch_uuid: branch.uuid,
      phone_code: values.phoneCode.phone,
      phone_number: values.phoneNumber,
      address_line_1: values.addressLine1,
      address_line_2: values.addressLine2,
      postal_code: values.postalCode,
      city: values.city,
      state: values.state,
      country: values.country.label,
    };

    if (selectedEditAddr) {
      params.uuid = selectedEditAddr.uuid;

      dispatch(updateRequestTransferAddress(params))
        .then(unwrapResult)
        .then((res) => {
          if (selectedEditAddr.uuid === receiver.uuid) {
            updateReceiver(res);
          }
          setShowAddDialog(false);
          setSelectedEditAddr(null);
          dispatch(updateAddress(res));
        });
    } else {
      dispatch(addRequestTransferAddress(params))
        .then(unwrapResult)
        .then(() => {
          setShowAddDialog(false);
          handleReload();
        });
    }
  };

  return (
    <>
      <RequestTransferAddressDialogComponent
        isOpen={isOpen}
        handleClose={handleClose}
        lang={lang}
        list={addresses}
        isFetching={isFetching}
        isError={isError}
        handleReload={handleReload}
        handleFetchMoreItems={handleFetchMoreItems}
        selectAddress={selectAddress}
        totalFiltered={totalFiltered}
        receiver={receiver}
        updateReceiver={updateReceiver}
        selectedAddress={selectedAddress}
        setShowAddDialog={setShowAddDialog}
        setSelectedEditAddr={setSelectedEditAddr}
      />
      <RequestTransferAddressCreateDialog
        isOpen={showAddDialog}
        handleClose={() => setShowAddDialog(false)}
        handleSubmit={handleSubmit}
        branch={branch}
        selectedEditAddr={selectedEditAddr}
      />
    </>
  );
}

export default RequestTransferAddressDialogContainer;
