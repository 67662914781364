import { createSlice } from "@reduxjs/toolkit";
import { ASSIGN_BATCH } from "../../constants";
import { getAssignListing } from "../action/assignBatch.action";
import { getAssignFailJob } from "../action/assignBatch.action";
import { getExportFileDropdown } from "../action";

const initialState = {
  assignBatchTable: {
    data: [],
    totalRecords: 0,
    totalFiltered: 0,
    isError: false,
  },
  selectedBatchFailJob: [],
  isFetchFailJobError: false,
  exportFile: []
};

const assignBatchSlice = createSlice({
  name: ASSIGN_BATCH,
  initialState,
  reducers: {
    resetAssignList(state) {
      state.assignBatchTable = initialState.assignBatchTable;
    },
  },
  extraReducers: {
    [getAssignListing.pending]: (state) => {
      state.assignBatchTable = initialState.assignBatchTable;
    },
    [getAssignListing.fulfilled]: (state, action) => {
      const { payload } = action;
      state.assignBatchTable.totalRecords = payload.recordsTotal;
      state.assignBatchTable.totalFiltered = payload.recordsFiltered;

      let temp = [];
      payload.data.forEach((item) => {
        temp.push({
          id: item.id,
          uuid: item.uuid,
          batchNumber: item.batch_number,
          product: item.product,
          status: item.status,
          progress: item.progress,
          date: item.created_at,
          quantity: item.quantity,
          // fromSeqNum:item.fromSeqNum,
          // toSeqNum:item.toSeqNum,
          retry_count: item.retry_count,
        });
      });
      state.assignBatchTable.data = temp;
    },
    [getAssignListing.rejected]: (state) => {
      state.assignBatchTable.isError = true;
    },
    [getAssignFailJob.pending]: (state) => {
      state.selectedBatchFailJob = initialState.selectedBatchFailJob;
      state.isFetchFailJobError = false;
    },
    [getAssignFailJob.fulfilled]: (state, action) => {
      const { payload } = action;
      state.selectedBatchFailJob = payload;
    },
    [getExportFileDropdown.fulfilled]: (state, action) => {
      const { payload } = action;
      state.exportFile = payload;
    },
    [getAssignFailJob.rejected]: (state) => {
      state.isFetchFailJobError = true;
    },
  },
});

export const { resetAssignList } = assignBatchSlice.actions;
export const assignBatchReducer = assignBatchSlice.reducer;
