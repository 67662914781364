import { Box, Chip, Grid, Typography } from "@material-ui/core";
import { responseHandler } from "modules/reporting/utils/helper";
import React from "react";
import { ContentCard } from "../../layout";
import { BoxLoading } from "../../skeleton/loading";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { BoxError } from "../../skeleton/error";
import { borderStyle, chipStyle, fontTextStyle } from "modules/reporting/styles";

export default function ProductBrandDetailPanelComponent({
    data,
    status,
    translate
}) {
    const chipClasses = chipStyle();
    const fontClasses = fontTextStyle();
    const borderClasses = borderStyle();

    const renderData = (data) => {
        if (Object.keys(data).length === 0 || !data.isSuccess){
            return null;
        }

        return (
            <Grid container spacing={3}>
                {/* product detail : 1st part */}
                <Grid item xs={12} sm={12} md={4}>
                    <Box
                        display="flex"
                        flexDirection="column"
                        style={{
                            gap: "6px"
                        }}
                    >
                        <Chip label={translate("label.BRAND")} className={chipClasses.chip__brand} />
                        <Typography
                            className={fontClasses.text__detail_title}
                        >
                            {data.brand_summary.name}
                        </Typography>
                        <Chip 
                            icon={<FiberManualRecordIcon className={fontClasses.text__success}/>}
                            label={data.brand_summary.publish_status ? translate("label.PUBLISHED") : translate("label.UNPUBLISHED")}
                            className={chipClasses.chip__publish_success}
                        />
                    </Box>

                </Grid>

                {/* product detail : 2nd part */}
                <Grid item xs={12} sm={12} md={4} 
                    className={borderClasses.border__middle_detail}
                    alignItems="center"
                >
                    <Grid container spacing={3} alignItems="center" >
                        <Grid item xs={3}>
                            <Typography className={fontClasses.text__detail_subtitle}>
                                {translate("label.PRODUCTS")}
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography className={fontClasses.text__detail_subtitle}>
                                :
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography className={fontClasses.text__detail_subtitle_value}>
                                {data.brand_summary.total_product}
                            </Typography>
                        </Grid>
                    </Grid> 
                    <Grid container spacing={3} alignItems="center" >
                        <Grid item xs={3}>
                            <Typography className={fontClasses.text__detail_subtitle}>
                                {translate("label.MODELS")}
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography className={fontClasses.text__detail_subtitle}>
                                :
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography className={fontClasses.text__detail_subtitle_value}>
                                {data.brand_summary.total_model}
                            </Typography>
                        </Grid>
                    </Grid> 
                </Grid>

                {/* product detail : 3rd part */}
                <Grid item xs={12} sm={12} md={4} 
                    className={borderClasses.border__middle_detail}
                    alignItems="center"
                >
                    <Grid container spacing={3} alignItems="center" >
                        <Grid item xs={3}>
                            <Typography className={fontClasses.text__detail_subtitle}>
                                {translate("label.CREATED_DATE")}
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography className={fontClasses.text__detail_subtitle}>
                                :
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography className={fontClasses.text__detail_subtitle_value}>
                                {data.brand_summary.created_date}
                            </Typography>
                        </Grid>
                    </Grid> 
                </Grid>

            </Grid>
        )
    }
    return (
        <ContentCard>
            {   
                responseHandler(
                    data.productBrandDetailData,
                    status.isFetchingProductBrandDetailData,
                    (
                        renderData(data.productBrandDetailData)
                    ),
                    (
                        <BoxLoading />
                    ),
                    (
                        <>empty</>
                    ),
                    (
                        <>no result</>
                    ),
                    (
                        <BoxError 
                            title={translate("label.LOADING_FAILED")}
                            value={translate("paragraph.LOADING_FAILED_DESCRIPTION")}
                        />
                    )
                )
            }
        </ContentCard>
    );
}