import {
  fetchJSONURL,
  getConsumerPageSetting,
  updateAppearance,
  updateConsumerPageSetting,
} from "../action";
import { setLoading, addAlert } from "modules/notification";
import {
  setAppearance,
  setConsumerPortalSetting,
  setPrivacyPolicyTNC,
} from "../slice";
import { getApiLang } from "app/feature/constants";

export const getConsumerPageSettingMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages;
    const isValidUrl = (urlString) => {
      try {
        return Boolean(new URL(urlString));
      } catch (e) {
        return false;
      }
    };
    switch (type) {
      case getConsumerPageSetting.pending.type:
        // dispatch set loading to notification reducer
        dispatch(
          setLoading({ id: getConsumerPageSetting.typePrefix, state: true })
        );
        break;

      case getConsumerPageSetting.fulfilled.type:
        // dispatch remove loading from notification reducer
        next(setConsumerPortalSetting(action.payload));
        if (isValidUrl(action.payload.privacy_policy_msg))
          dispatch(fetchJSONURL(action.payload.privacy_policy_msg));
        dispatch(
          setLoading({ id: getConsumerPageSetting.typePrefix, state: false })
        );
        break;

      case getConsumerPageSetting.rejected.type:
        dispatch(
          setLoading({ id: getConsumerPageSetting.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

export const updateAppearanceMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages;

    switch (type) {
      case updateAppearance.pending.type:
        dispatch(setLoading({ id: updateAppearance.typePrefix, state: true }));
        break;

      case updateAppearance.fulfilled.type:
        next(setAppearance(action.meta.arg));
        dispatch(setLoading({ id: updateAppearance.typePrefix, state: false }));
        dispatch(
          addAlert({
            severity: "success",
            message: getApiLang(lang, "success.SUCCESS_UPDATE_APPEARANCE"),
          })
        );
        break;

      case updateAppearance.rejected.type:
        dispatch(setLoading({ id: updateAppearance.typePrefix, state: false }));
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

export const updateConsumerPageSettingMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);

    const { payload, type } = action;
    const lang = getState().constant.languages;

    switch (type) {
      case updateConsumerPageSetting.pending.type:
        dispatch(
          setLoading({ id: updateConsumerPageSetting.typePrefix, state: true })
        );
        break;

      case updateConsumerPageSetting.fulfilled.type:
        // next(setConsumerPortalSetting(action.meta.arg));
        dispatch(
          setLoading({ id: updateConsumerPageSetting.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "success",
            message: getApiLang(lang, "success.SUCCESS_UPDATE_PAGE_SETTING"),
          })
        );
        break;

      case updateConsumerPageSetting.rejected.type:
        dispatch(
          setLoading({ id: updateConsumerPageSetting.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

const fetchJSONURLMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    switch (type) {
      case fetchJSONURL.pending.type:
        dispatch(setLoading({ id: fetchJSONURL.typePrefix, state: true }));
        break;

      case fetchJSONURL.fulfilled.type:
        next(setPrivacyPolicyTNC(action.payload));
        dispatch(setLoading({ id: fetchJSONURL.typePrefix, state: false }));
        break;

      case fetchJSONURL.rejected.type:
        const lang = getState().constant.languages;
        dispatch(setLoading({ id: fetchJSONURL.typePrefix, state: false }));
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

export default [
  getConsumerPageSettingMiddleware,
  updateAppearanceMiddleware,
  updateConsumerPageSettingMiddleware,
  fetchJSONURLMiddleware,
];
