import React from "react";
import Chart from "react-apexcharts";
import { setChartColors } from "modules/reporting/utils/chartConfig";
import { getLang } from "app/feature/constants";
import moment from "moment";
import "moment/min/locales.min";
import { useSelector } from "react-redux";

export default function MixedChart({
  payload,
  chartHeight,
  chartColors = [],
  lang,
  dontAddUnknown = true,
  multipleYAxis = [],
  dashLine = false,
}) {
  const language = useSelector((state)=> state.profile.language)
  
  if(language==="ZH")
  {
    moment.locale('zh-cn')
  }else if(language==="TL")
  {
    moment.locale('tl-ph')
  }else
  {
    moment.locale(language)
  }
;

  const chartSeries = () => {
    let series = JSON.stringify(payload);
    series = JSON.parse(series);

    series.datasets.forEach((e) => {
      e.name = getLang(lang, e.name);
    });

    return series;
  };

  let yAxis = [];

  if (multipleYAxis.length > 0) {
    multipleYAxis.forEach((e, i) => {
      let newYaxis = {
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: e.color ?? "#008FFB",
        },
        title: {
          text: e.name ?? "?",
          color: e.color ?? "#008FFB",
        },
        opposite: e.opposite ?? false,
      };
      yAxis.push(newYaxis);
    });
  }

  const options = {
    markers: {
      size: 4,
    },
    colors:
      chartColors.length > 0
        ? chartColors
        : setChartColors(payload.datasets.length, dontAddUnknown),
    xaxis: {
      categories: payload.labels.map(date => moment(date).format('ll')),
      tickPlacement: 'between',
      labels: {
        hideOverlappingLabels: true,
        rotateAlways: true,
        rotate: -45
      }
    },

    fill: {
      opacity: 1,
    },
    legend: {
      position: "bottom",
      itemMargin: {
        horizontal: 20,
        vertical: 16,
      },
      offsetX: 40,
    },
    yaxis: yAxis,
    stroke: {
      width: [2, 2],
      dashArray: [0, 0],
    },
    locales: [
      {
        locale: lang,
        options: {
          months: [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
          ],
          shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
          days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
          shortDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
          toolbar: {
            download: 'Download SVG',
            selection: 'Selection',
            selectionZoom: 'Selection Zoom',
            zoomIn: 'Zoom In',
            zoomOut: 'Zoom Out',
            pan: 'Panning',
            reset: 'Reset Zoom',
          }
        }
      }
    ],
    defaultLocale: 'en',
  };

  return (
    <Chart
      series={chartSeries().datasets}
      options={options}
      type="line"
      height={chartHeight ?? "auto"}
    ></Chart>
  );
}
