export const LOGIN = "/login";
export const LOGOUT = "/logout";
export const LOGIN_AUTH = "/auth";
export const RESET_PASSWORD = "/reset-password";
export const SENT_RESET_PASSWORD = "/sent-reset-password";
export const VERIFY_INVITATION = `/verify-email/:id`;
export const SIGN_UP = "/signup";

export const PARTIAL_FREE_SIGN_UP = "/partial-free-signup";
export const FULL_FREE_SIGN_UP = "/full-free-signup";

export const RESELLER_PLAN_A_SIGN_UP = "/rPlanA/:code/signup";
export const RESELLER_PLAN_B_SIGN_UP = "/rPlanB/:code/signup";
export const PARTNER_PLAN_A_SIGN_UP = "/pPlanA/:code/signup";
export const PARTNER_PLAN_B_SIGN_UP = "/pPlanB/:code/signup";

export const SIGN_UP_VERIFICATION = "/signup/verification";
export const EMAIL_VERIFIED = "/email-verified";
export const VERIFY_EMAIL = "/verify-email";
export const INVALID_LINK = "/invalid-link";
export const NEW_PASSWORD = "/new-password";
export const RESET_PASSWORD_SUCCESS = "/reset-password/success";