import axios from "axios";

const url = "/distributor/admin/api/v1/request-transfer/receive-list";
export function getRequestTransferReceiveList(parameters) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, {
        length: parameters.length,
        start: parameters.start,
        order: parameters.order,
        order_by: parameters.order_by,
        filter: {
          search: parameters.filter.search,
          status: parameters.filter.status,
          from_date: parameters.filter.from_date,
          to_date: parameters.filter.to_date,
          request_from: parameters.filter.request_from,
          request_to: parameters.filter.request_to,
          respond_date: parameters.filter.respond_date,
        },
      })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data));
  });
}
