import React, { Suspense, lazy } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import {
  ROOT,
  PRODUCT_PAGE,
  FALLBACK,
  NEW_PRODUCT_PAGE,
  CATEGORY,
  BRAND_AND_MODEL,
  ATTRIBUTES,
  GENERATE_PRODUCT,
  BULK_EDIT
} from "./config/product-mgmt.route";
import LoadingPanel from "./components/panel/loadingPanel";
import { 
  ProductPage, 
  FallbackPage, 
  EditProductPage, 
  CategoryPage,
  BrandNModelPage,
  AttributesPage,
  GenerateProductPage,
  BulkEditPage
} from "./pages";

const ListingPage = lazy(() => import("./pages/listing-page"));

export default function ModuleProductMgmt() {
  return (
    <Suspense fallback={<LoadingPanel />}>
      <Switch>
        {/* Static Route must register first before dynamic route */}
        <Route exact path={ROOT} component={ListingPage} />
        <Route exact path={FALLBACK} component={FallbackPage} />
        <Route exact path={CATEGORY} component={CategoryPage} />
        <Route exact path={BRAND_AND_MODEL} component={BrandNModelPage} />
        <Route exact path={ATTRIBUTES} component={AttributesPage} />
        <Route exact path={GENERATE_PRODUCT} component={GenerateProductPage} />
        <Route exact path={BULK_EDIT} component={BulkEditPage} />
        <Route exact path={NEW_PRODUCT_PAGE} component={ProductPage} />
        <Route exact path={PRODUCT_PAGE} component={EditProductPage} />
        <Route path="*">
          <Redirect to="/admin/invalid" />
        </Route>
      </Switch>
    </Suspense>
  );
}

export { productPageReducer } from "./redux/slice/productPage.slice";
export { productsReducer } from "./redux/slice/products.slice";
export { 
  ROOT,
  CATEGORY,
  BRAND_AND_MODEL,
  ATTRIBUTES,
  GENERATE_PRODUCT
};
