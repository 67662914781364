import TrendingCardLoading from './trending-card';
import TableCellLoading from './table-cell';
import BoxLoading from './box';
import ChartCardLoading from './chart-card';
import FilterLoading from './filter';

export {
    TrendingCardLoading,
    TableCellLoading,
    BoxLoading,
    ChartCardLoading,
    FilterLoading
}