import axios from "axios";

export function getRequestDetail(parameters) {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `/distributor/admin/api/v1/request-transfer/${parameters.id}?is_my_request=${parameters.is_my_request}`
      )
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data));
  });
}
