import React, { useState, useRef, useEffect } from "react"
import OrderReceiverSelectComponent from "./orderReceiverSelect.component"
import { selectLoading } from "modules/notification"
import OrderReceiverSelectPanelComponent from "./orderReceiverSelectPanel.component"
import { getOrderReceiverDropdown } from "modules/stockflow/redux"
import { useSelector, useDispatch } from "react-redux"
import { cloneDeep } from "lodash"

function OrderReceiverSelectContainer({
  handleChange,
  disabled,
  value,
  styles, 
  textStyles,
  placeholder,
  type,
  dealerUuid
}) {
  const isFetching = useSelector(state => selectLoading(state, getOrderReceiverDropdown.typePrefix))
  const lang = useSelector((state) => state.constant.languages)
  const orderReceiverDropdown = useSelector((state) => state.stockflowOrder.orderReceiverDropdown)
  const [open, setOpen] = useState(false)
  const [isOpenList] = useState(false)
  const anchorRef = useRef(null)
  const prevOpen = useRef(open)
  const prevOpenList = useRef(isOpenList)
  const [selectAll, setSelectAll] = useState(false)
  const [search, setSearch] = useState("")
  const dispatch = useDispatch()

  useEffect(() => {
    let param = { search, type }
    if (dealerUuid) {
      param.request_from_dealer = dealerUuid
    }
    dispatch(getOrderReceiverDropdown(param))
  }, [search, type, dispatch, dealerUuid])

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])

  useEffect(() => {
    if (prevOpenList.current === true && isOpenList === false) {
      anchorRef.current.focus()
    }

    prevOpenList.current = isOpenList
  }, [isOpenList])

  useEffect(() => {
    setSelectAll(value.length === orderReceiverDropdown.length)
  }, [value, orderReceiverDropdown])

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
    setSearch("")
  }

  const handleSelectAll = async () => {
    if(!selectAll){
      let selectedProducts = orderReceiverDropdown.map(p => {
        return {
          id: p.id,
          type: p.type
        }
      })
      handleChange(selectedProducts)
    }else{
      handleChange([])
    }
  }

  const handleSelectReceiver = (receiver) => {
    let temp = cloneDeep(value)

    if(temp.findIndex(t => t.id === receiver.id && t.type === receiver.type) >= 0){
      temp = temp.filter( t => !(t.id === receiver.id && t.type === receiver.type))
    }else{
      temp.push({
        id: receiver.id,
        type: receiver.type
      })
    }
    handleChange(temp)
  }

  const handleSearch = (value) => {
    setSearch(value)
  }

  return (
    <>
      <OrderReceiverSelectComponent
        anchorRef={anchorRef}
        disabled={disabled}
        value={value}
        handleClick={handleOpen}
        styles={styles}
        textStyles={textStyles}
        placeholder={placeholder}
        lang={lang}
      />
      <OrderReceiverSelectPanelComponent
        selectAll={selectAll}
        value={value}
        anchorRef={anchorRef}
        open={open}
        isOpenList={isOpenList}
        handleClose={handleClose}
        isFetching={isFetching}
        orderReceiverDropdown={orderReceiverDropdown}
        handleSelectAll={handleSelectAll}
        handleSelectReceiver={handleSelectReceiver}
        handleSearch={handleSearch}
        lang={lang}
      />
    </>
  )
}

export default OrderReceiverSelectContainer