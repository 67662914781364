import React, { useEffect, useState } from "react";
import TagComponent from "./tag.page";
import NewTagDialog from "../../components/dialog/newTagDialog";
import { connect, useSelector } from "react-redux";
import { getTagSerialNumber } from "../../redux";
import { selectLoading } from "modules/notification";
import {
  ADDITIONAL_INFO_VIEW,
  SERIAL_NUMBER_PATTERN_ADD,
} from "lib/constants/accessRights";
import {
  closeCreationDialog,
  openCreationDialog,
} from "modules/serial-number/redux";
import { generatePaginationOptions } from "lib/helper";
import TagDetailDialog from "modules/serial-number/components/dialog/tagDetailDialog";

function TagContainer({
  isFetching,
  tagSerialNumberData,
  length,
  filteredlength,
  getTagSerialNumber,
  closeCreationDialog,
  openCreationDialog,
}) {
  const hasAdtInfoViewAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(ADDITIONAL_INFO_VIEW)
  );
  const hasSerialNumberPatternAddAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(SERIAL_NUMBER_PATTERN_ADD)
  );
  const isOpen = useSelector((state) => state.serialNumberTag.isOpenaddPattern);
  const lang = useSelector((state) => state.constant.languages);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [paginationOptions, setPaginationOptions] = useState([]);
  const [selectedTag, setSelectedTag] = useState(null);

  useEffect(() => {
    getTagSerialNumber({
      length: rowsPerPage,
      start: rowsPerPage * page,
      search: search,
    }).then((res) => setIsLoading(false));
  }, [search, rowsPerPage, page, getTagSerialNumber]);

  useEffect(() => {
    setPaginationOptions(generatePaginationOptions(length));
  }, [length]);

  const handleReload = () => {
    getTagSerialNumber({
      length: rowsPerPage,
      start: rowsPerPage * page,
      search: search,
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(event.target.value);
  };

  const handleSearch = (value) => {
    setPage(0);
    setSearch(value);
  };

  function refreshTable() {
    getTagSerialNumber({
      length: rowsPerPage,
      start: rowsPerPage * page,
      search: search,
    }).then((res) => setIsLoading(false));
  }
  const handleCloseModal = () => {
    if (hasSerialNumberPatternAddAccessRight) {
      closeCreationDialog(false);
    }
  };

  const handleOpenModal = () => {
    if (hasSerialNumberPatternAddAccessRight) {
      openCreationDialog(true);
    }
  };

  const handleViewDetail = (tagUuid) => {
    setSelectedTag(tagUuid);
  };

  return (
    <>
      <TagComponent
        hasSerialNumberPatternAddAccessRight={
          hasSerialNumberPatternAddAccessRight
        }
        hasAdtInfoViewAccessRight={hasAdtInfoViewAccessRight}
        handleOpenModal={handleOpenModal}
        tagSerialNumberData={tagSerialNumberData}
        isFetching={isFetching}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        page={page}
        search={search}
        rowsPerPage={rowsPerPage}
        isLoading={isLoading}
        length={filteredlength}
        handleSearch={handleSearch}
        handleReload={handleReload}
        paginationOptions={paginationOptions}
        lang={lang}
        handleViewDetail={handleViewDetail}
      />
      <NewTagDialog
        isOpen={isOpen}
        handleClose={handleCloseModal}
        refreshTable={refreshTable}
        // handleResult={addNewTag}
      />
      <TagDetailDialog
        isOpen={!!selectedTag}
        handleClose={() => setSelectedTag(null)}
        uuid={selectedTag}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  tagSerialNumberData: state.serialNumberTag.SerialNumberTagTable.data,
  length: state.serialNumberTag.SerialNumberTagTable.totalRecords,
  filteredlength:
    state.serialNumberTag.SerialNumberTagTable.filteredTotalRecords,
  isFetching: selectLoading(state, getTagSerialNumber.typePrefix),
});

const mapDispatchToProps = (dispatch) => ({
  getTagSerialNumber: (pageSetting) =>
    dispatch(getTagSerialNumber(pageSetting)),
  closeCreationDialog: () => dispatch(closeCreationDialog()),
  openCreationDialog: () => dispatch(openCreationDialog()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TagContainer);
