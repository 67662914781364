import React from "react";
import {
  Box,
  Button,
  makeStyles,
  Typography,
  // CircularProgress,
} from "@material-ui/core";
import SummaryByDealerTable from "modules/stockflow/components/table/summaryByDealerTable";
import { Formik } from "formik";
// import { DownloadSVG } from "modules/stockflow/utils/static"
import DealerSearch from "modules/stockflow/components/select/dealerSearch";
import ProductSearch from "modules/stockflow/components/select/productSearch";
import BranchSearch from "modules/stockflow/components/select/branchSearch";
import { getLang } from "app/feature/constants";
import ExportExcelSelect from "modules/warrantee/components/select/exportExcelSelect";

const useStyle = makeStyles((theme) => ({
  searchContainer: {
    backgroundColor: theme.palette.background.paper,
    display: "grid",
    gridTemplateColumns: "4fr 4fr 4fr 1fr 1fr",
    columnGap: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1),
    },
  },
  inputRoot: {
    fontWeight: 500,
    paddingTop: "8px !important",
    paddingBottom: "7px !important",
    paddingLeft: "10px !important",
  },
  dropdownSearchBox: {
    backgroundColor: "#E9EDEF",
    borderRadius: 4,
  },
  select: {
    minWidth: 170,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      minWidth: "auto",
    },
  },
  divider: {
    height: "70%",
    placeSelf: "center",
  },
  textFieldInput: {
    padding: "7px",
    paddingTop: "8px",
    minWidth: 170,
    fontWeight: 500,
    color: theme.palette.primary.main,
  },
  menuItemText: {
    padding: 10,
    fontWeight: 500,
  },
  exportButton: {
    backgroundColor: "#ffffff",
    height: 43,
    borderRadius: 4,
    padding: "5px 12px",
    borderColor: "#D0D5DD",
  },
}));

export default function SummaryByDealerComponent({
  hasStockflowInventoryViewAccessRight,
  products,
  isFetching,
  selectedIds,
  isSelectAll,
  selectedCount,
  isError,
  handleChangePage,
  handleChangeRowsPerPage,
  totalFiltered,
  page,
  rowsPerPage,
  handleSearch,
  clearSearch,
  handleReload,
  isSearchResult,
  handleSelectProduct,
  handleSelectAvailable,
  handleSelectAll,
  handleDeselectAll,
  token,
  paginationOptions,
  handleExport,
  isExporting,
  lang,
  tableFilter,
  handleViewDetail,
  productDropdown,
  changeSequenceOrder
}) {
  const classes = useStyle();

  const columnDropdown = [
    { name: getLang(lang, "label.PRODUCT"), value: "uuid" }
  ];

  const exportDropdown = [
    // { name: "CSV", value: "csv" },
    { name: "Excel", value: "xlsx" },
  ];

  return (
    <>
      <Formik
        initialValues={{
          searchColumn: tableFilter.searchColumn || columnDropdown[0].value,
          search: tableFilter.search,
          branch: tableFilter.branch,
          dealer: tableFilter.dealer,
          products: tableFilter.products,
        }}
        enableReinitialize
        onSubmit={handleSearch}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <Box p={2} mb={2} className={classes.searchContainer}>
              <ProductSearch
                searchable={true}
                value={formik.values.products}
                placeholder={getLang(lang, 'placeholder.SEARCH_BY_PRODUCT')}
                searchPlaceholder={getLang(lang, 'placeholder.SEARCH_A_PRODUCT_NAME')}
                isClearable={false}
                emptyMessage={getLang(lang, 'message.info.NO_PRODUCT_FOUND')}
                dropdownItem={productDropdown}
                isFetching={false}
                isEmpty={formik.values.products?.length === 0}
                selectedProduct={formik.values.products}
                style={{
                  padding: "0.25em 0.75em",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderRadius: 4,
                  width: "100%",
                  overflow: "hidden",
                }}
                handleSelectAll={(selectAll) => {
                  if(selectAll){
                    formik.setFieldValue("products", productDropdown)
                  }else{
                    formik.setFieldValue("products", [])
                  }
                }}
                handleChange={(value) => {
                  let temp = [...formik.values.products]

                  if (temp.length === 0) {
                    temp.push(value)
                  } else {
                    const isProductExist = temp.find((product) => product.id === value.id)

                    if (isProductExist === undefined) {
                      temp.push(value)
                    } else {
                      let filter = temp.filter((product) => product.id !== value.id)

                      temp = [...filter]
                    }
                  }
                  formik.setFieldValue("products", [...temp])
                }}
                handleChipDelete={(product) => {
                  let temp = [...formik.values.products];
                  if (temp.length > 0 ) {
                    const filteredProducts = temp.filter((item) => item.id !== product.id);
                    temp = [...filteredProducts];
                  }
                  formik.setFieldValue("products", temp);
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left"
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                disabled={false}
              />
              <BranchSearch
                placeholder={getLang(lang, "placeholder.BRANCH")}
                value={formik.values.branch}
                handleChange={(value) => formik.setFieldValue("branch", value)}
                isEmpty={formik.values.branch?.length === 0}
                styles={{
                  padding: "0.25em 0.75em",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderRadius: 4,
                  width: "100%",
                  overflow: "hidden",
                }}
                handleChipDelete={(branch) => {
                  let temp = [...formik.values.branch];
                  if (temp.length > 0 ) {
                    const filteredBranchs = temp.filter((item) => item.node_id !== branch.node_id);
                    temp = [...filteredBranchs];
                  }
                  formik.setFieldValue("branch", temp);
                }}
              />
              <DealerSearch
                placeholder={getLang(lang, "placeholder.DEALER")}
                value={formik.values.dealer}
                handleChange={(value) => formik.setFieldValue("dealer", value)}
                branchDropdown={formik.values.branch}
                isEmpty={formik.values.dealer?.length === 0}
                styles={{
                  padding: "0.25em 0.75em",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderRadius: 4,
                  width: "100%",
                  overflow: "hidden",
                }}
                handleChipDelete={(dealer) => {
                  let temp = [...formik.values.dealer];
                  if (temp.length > 0 ) {
                    const filteredDealers = temp.filter((item) => item.uuid !== dealer.uuid);
                    temp = [...filteredDealers];
                  }
                  formik.setFieldValue("dealer", temp);
                }}
              />
              <Button
                variant="text"
                disableElevation
                color="secondary"
                size="small"
                onClick={() => {
                  clearSearch();
                }}
              >
                {getLang(lang, "label.CLEAR")}
              </Button>
              <Button
                disabled={isFetching}
                variant="contained"
                disableElevation
                color="primary"
                size="small"
                type="submit"
              >
                {getLang(lang, "label.SEARCH")}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingBottom: "12px",
        }}
      >
        <Box style={{ display: "flex" }}>
          <Box style={{ display: "flex", alignItems: "center" }}>
            {selectedCount !== 0 ? (
              isSelectAll && selectedCount === totalFiltered ? (
                <Typography variant="body2" style={{ color: "#FDB022" }}>
                  {getLang(lang, "paragraph.SELECTED_ALL_PRODUCTS", {
                    count: selectedCount,
                  })}
                </Typography>
              ) : (
                <Typography variant="body2" color="primary">
                  {selectedCount > 1
                    ? getLang(lang, "paragraph.SELECTED_PRODUCTS_COUNT", {
                        count: selectedCount,
                      })
                    : getLang(lang, "paragraph.SELECTED_PRODUCT_COUNT", {
                        count: selectedCount,
                      })}
                </Typography>
              )
            ) : (
              ""
            )}
          </Box>
        </Box>
        <ExportExcelSelect
        disabled={isExporting || isFetching || products.length === 0 }
          dropdownItem={exportDropdown}
          isLoading={isExporting}
          handleSelection={(type) => handleExport(type)}
        />
      </Box>
      <SummaryByDealerTable
        hasStockflowInventoryViewAccessRight={
          hasStockflowInventoryViewAccessRight
        }
        rows={products}
        isFetching={isFetching}
        selectedIds={selectedIds}
        isSelectAll={isSelectAll}
        handleReload={handleReload}
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        isSearchResult={isSearchResult}
        totalFiltered={totalFiltered}
        handleSelectProduct={handleSelectProduct}
        handleSelectAvailable={handleSelectAvailable}
        handleSelectAll={handleSelectAll}
        handleDeselectAll={handleDeselectAll}
        isError={isError}
        token={token}
        paginationOptions={paginationOptions}
        lang={lang}
        tableFilter={tableFilter}
        handleViewDetail={handleViewDetail}
        changeSequenceOrder={changeSequenceOrder}
      />
    </>
  );
}
