import axios from "axios";

export function editBrandModel({
  id,
  name,
  status,
  models,
  description,
  imageUrl,
}) {
  const url =  `/serialnumber/api/v1/product/brand-models/update/${id}`;

  return new Promise((resolve, reject) => {
    axios.post(url, {
      name,
      status,
      models,
      description,
      image_url: imageUrl,
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data))
  })
}
