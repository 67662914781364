import getScanMap from "./getScanMap.api";
import getClusterScanMap from "./getClusterScanMap.api";
import getClusterScanProductListing from "./getClusterScanProductListing.api";
import getClusterScanRecordListing from "./getClusterScanRecordListing.api";
import getClusterScanConsumerDropdown from "./getClusterScanConsumerDropdown.api";
import getClusterConsumerScannedSummary from "./getClusterConsumerScannedSummary.api";
import getClusterUniqueConsumerScannedSummary from "./getClusterUniqueConsumerScannedSummary.api";
import getClusterFirstScanSummary from "./getClusterFirstScanSummary.api";
import getClusterSuspendSnSummary from "./getClusterSuspendSnSummary.api";
import getClusterCounterfeitReportSummary from "./getClusterCounterfeitReportSummary.api";
import getScanDetail from "./getScanDetail.api";
import getConsumerScannedSummary from "./getConsumerScannedSummary.api";
import getSuspendSnSummary from "./getSuspendSnSummary.api";
import getCounterfeitReportSummary from "./getCounterfeitReportSummary.api";
import getFirstScanSummary from "./getFirstScanSummary.api";
import getUniqueConsumerScannedSummary from "./getUniqueConsumerScannedSummary.api";
import getConsumerRegisteredSummary from "./getConsumerRegisteredSummary.api";
import getScanAgainstSuspendedData from "./getScanAgainstSuspendedData.api";
import getTopScanCities from "./getTopScanCities.api";
import getTopScanProduct from "./getTopScanProduct.api";
import getSerialNumberFirstScanList from "./getSerialNumberFirstScanList.api";
import getNewConsumerRegisteredList from "./getNewConsumerRegisteredList.api";
import getUniqueConsumerScannedList from "./getUniqueConsumerScannedList.api";


const dashboardApi = {
  getScanMap,
  getClusterScanMap,
  getClusterScanProductListing,
  getScanDetail,
  getClusterScanRecordListing,
  getClusterScanConsumerDropdown,
  getClusterConsumerScannedSummary,
  getClusterUniqueConsumerScannedSummary,
  getClusterFirstScanSummary,
  getClusterSuspendSnSummary,
  getClusterCounterfeitReportSummary,
  getConsumerScannedSummary,
  getSuspendSnSummary,
  getCounterfeitReportSummary,
  getFirstScanSummary,
  getUniqueConsumerScannedSummary,
  getConsumerRegisteredSummary,
  getScanAgainstSuspendedData,
  getTopScanCities,
  getTopScanProduct,
  getSerialNumberFirstScanList,
  getNewConsumerRegisteredList,
  getUniqueConsumerScannedList,
};

export default dashboardApi;
