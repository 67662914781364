import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { ROOT, SUBSCRIPTION_PLANS_PAGE } from "./config/store.route";
import { MainPage, SubscriptionPlansPage } from "./pages";

export default function Modulestore() {
  return (
    <Switch>
      <Route exact path={ROOT} component={MainPage} />
      <Route exact path={SUBSCRIPTION_PLANS_PAGE} component={SubscriptionPlansPage} />
      <Route path="*">
        <Redirect to="/admin/invalid" />
      </Route>
    </Switch>
  );
}

export { ROOT };
