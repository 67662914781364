import React from "react";
import { Box, makeStyles } from "@material-ui/core";
import Tooltip from '@material-ui/core/Tooltip';
import HelpOutlinedIcon from '@material-ui/icons/HelpOutlined';
import { getLang } from "app/feature/constants";
import ConsumerScanSummaryIcon from "assets/img/consumerScanSummary.png";
import UniqueConsumerSummaryIcon from "assets/img/uniqueConsumerSummary.png";
import FirstScanSummaryIcon from "assets/img/firstScanSummary.png";
import SuspendedSummaryIcon from "assets/img/suspendedSummary.png";
import CounterfeitSummaryIcon from "assets/img/counterfeitSummary.png";
import NewConsumerSummaryIcon from "assets/img/newConsumerSummary.png";
import TrendingSummaryCard from "../../card/trendingSummaryCard";
import { ROOT as REPORTING_ROOT } from "modules/reporting";
import { ROOT as COUNTERFEIT_ROOT } from "modules/counterfeit-report";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "grid",
    gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
    columnGap: theme.spacing(1.5),
    gridRowGap: theme.spacing(2),
    rowGap: theme.spacing(2),
    marginBottom: 16,
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr 1fr",
    },
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
    },
  },
}));

export default function TrendingSummaryPanelComponent({
  lang,
  startDate,
  endDate,
  list,
  loading,
  showPopup,
}) {
  const classes = useStyles();

  const summaryList = [
    {
      title: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {getLang(lang, "label.TOTAL_SCANNED")}
          <Tooltip
            title={getLang(lang, 'tooltips.TOTAL_SCANNED_TOOLTIP')}
            arrow
          >
            <HelpOutlinedIcon 
              color="primary"
              fontSize="inherit"
              style={{ marginLeft: 6, fontsize: 12 }} 
            />
          </Tooltip>
        </div>
      ),
      icon: ConsumerScanSummaryIcon,
      data: list.consumerScanned.data,
      loading: loading.consumerScanned,
      positive: true,
      url: `${REPORTING_ROOT}?page=qr&period=daily&start=${startDate}&end=${endDate}`,
    },
    {
      title: getLang(lang, "label.SUSPENDED_SERIAL_NUMBER"),
      icon: SuspendedSummaryIcon,
      data: list.suspendedSN.data,
      loading: loading.suspendedSN,
      positive: false,
      url: `${REPORTING_ROOT}?page=qr&period=daily&start=${startDate}&end=${endDate}`,
    },
    {
      title: getLang(lang, "label.COUNTERFEIT_REPORT_RECEIVED"),
      icon: CounterfeitSummaryIcon,
      data: list.counterfeitReports.data,
      loading: loading.counterfeitReports,
      positive: false,
      url: `${COUNTERFEIT_ROOT}?start=${startDate}&end=${endDate}`
    },
    {
      title: getLang(lang, "label.FIRST_SCAN_SN"),
      icon: FirstScanSummaryIcon,
      data: list.firstScan.data,
      loading: loading.firstScan,
      positive: true,
      allowPopup: !!list.firstScan.data && list.firstScan.data.totalCount > 0,
      popup: () => {
        showPopup('serialNumberFirstScan');
      }
    },
    {
      title: getLang(lang, "label.UNIQUE_CONSUMER_SCANNED"),
      icon: UniqueConsumerSummaryIcon,
      data: list.uniqueConsumerScanned.data,
      loading: loading.uniqueConsumerScanned,
      positive: true,
      allowPopup: !!list.uniqueConsumerScanned.data && list.uniqueConsumerScanned.data.totalCount > 0,
      popup: () => {
        showPopup('uniqueConsumer');
      }
    },
    {
      title: getLang(lang, "label.NEW_CONSUMER_REGISTERED"),
      icon: NewConsumerSummaryIcon,
      data: list.newConsumer.data,
      loading: loading.newConsumer,
      positive: true,
      allowPopup: !!list.newConsumer.data && list.newConsumer.data.totalCount > 0,
      popup: () => {
        showPopup('newConsumer');
      }
    },
  ];

  return (
    <Box className={classes.container}>
      {summaryList.map((s, i) => (
        <TrendingSummaryCard
          key={i}
          title={s.title}
          icon={s.icon}
          data={s.data}
          loading={s.loading}
          url={s.url}
          allowPopup={s.allowPopup}
          popup={s.popup}
        />
      ))}
    </Box>
  );
}
