import { useState } from "react";
import { lighten } from "@material-ui/core/styles";

export const THEME_OPTIONS = ["Light", "Light blue", "Dark"];

let lightTheme = {
  palette: {
    type: "light",
    primary: {
      main: "#3A4D54"
    },
    secondary: {
      main: "#6AAF68",
      contrastText: "#fff"
    },
    background: {
      paper: " #fafafa"
    },
    text: {
      primary: "#3A4D54",
      secondary: "#758287"
    }
  },
  typography: {
    fontFamily: "Roboto"
  },
  mixins: {
    toolbar: {
      minHeight: 40,
      "@media (min-width:600px)": {
        minHeight: 40
      }
    }
  }
};

const lightBlueTheme = {
  ...lightTheme,
  palette: {
    ...lightTheme.palette,
    background: {
      default: "#EBEDEE",
      paper: "#ffffff"
    }
  }
};

const darkTheme = {
  ...lightTheme,
  palette: {
    ...lightTheme.palette,
    type: "dark",
    background: {
      default: "#212529",
      paper: "#272b2f",
      text: "	#ffffff"
    }
  }
};

const useThemes = () => {
  const getThemeByThemeName = themeName => {
    switch (themeName) {
      case THEME_OPTIONS[0]:
        return lightTheme;
      case THEME_OPTIONS[1]:
        return lightBlueTheme;
      case THEME_OPTIONS[2]:
        return darkTheme;

      default:
        break;
    }
  };

  const [theme, setTheme] = useState(getThemeByThemeName(THEME_OPTIONS[1]));

  const toggleThemes = (themeName, customColor) => {
    const { primary, secondary } = customColor;

    const newTheme = getThemeByThemeName(themeName);

    //merge theme
    const mergedNewTheme = {
      ...newTheme,
      palette: {
        ...newTheme.palette,
        primary: {
          main: primary,
          light: lighten(primary, 0.5)
        },
        secondary: {
          main: secondary
        }
      }
    };
    setTheme(mergedNewTheme);
  };
  return [theme, toggleThemes];
};

export { useThemes };
export default lightTheme;
