import React from "react";
import AdtInfoTitleTableComponent from "./adtInfoTitleTable.component";

function AdtInfoTitleTableContainer({
  isFetching,
  rows,
  handleReload,
  isError,
  lang,
  page,
  rowsPerPage,
  supportedLang,
  isSelectAll,
  paginationOptions,
  handleChangePage,
  handleChangeRowPerPage,
  totalFiltered,
  handleSelectAll,
  selectedIds,
  handleSelectTitle,
  handleEdit,
  handleAdd,
}) {
  return (
    <AdtInfoTitleTableComponent
      rows={rows}
      isFetching={isFetching}
      handleReload={handleReload}
      isError={isError}
      lang={lang}
      supportedLang={supportedLang}
      isSelectAll={isSelectAll}
      handleSelectAll={handleSelectAll}
      selectedIds={selectedIds}
      handleSelectTitle={handleSelectTitle}
      handleEdit={handleEdit}
      handleAdd={handleAdd}
      page={page}
      rowsPerPage={rowsPerPage}
      paginationOptions={paginationOptions}
      handleChangePage={handleChangePage}
      handleChangeRowPerPage={handleChangeRowPerPage}
      totalFiltered={totalFiltered}
    />
  );
}

export default AdtInfoTitleTableContainer;
