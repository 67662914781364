import React from "react";
import {
  Box,
  Button,
  Divider,
  Tabs,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { getLang } from "app/feature/constants";
import CustomTab from "components/tab";
import SingleCheckoutProductAccordion from "../../accordion/singleCheckoutProductAccordion";
import SingleCheckoutBoxAccordion from "../../accordion/singleCheckoutBoxAccordion";
import InfiniteScroll from "react-infinite-scroll-component";
import LoadingComponent from "components/loading";

const useStyles = makeStyles((theme) => ({
  productRow: {
    border: "1px solid #D0D5DD",
    "& > div:first-child": {
      borderBottom: "1px solid #D0D5DD",
    },
  },
  list: {
    maxHeight: "70vh",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "5px",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "16px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#E4E7EC",
      borderRadius: "16px",
    },
  },
  panel: {
    backgroundColor: "white",
    padding: "16px",
  },
  searchContainer: {
    backgroundColor: theme.palette.background.paper,
    display: "grid",
    gridTemplateColumns: "5fr 1fr 1fr",
    columnGap: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1),
    },
  },
  inputRootProduct: {
    paddingTop: "8px !important",
    paddingBottom: "7px !important",
    paddingLeft: "12px !important",
    paddingRight: "12px !important",
  },
}));

export default function CheckoutItemListPanelComponent({
  lang,
  activeIndex,
  changeTab,
  selectItem,
  selectedItem,
  list,
  isError,
  totalFiltered,
  handleFetchMoreItems,
  hasNextPage,
  handleReload,
  isFetching,
  id,
}) {
  const classes = useStyles();

  let tabLabels = [getLang(lang, "label.PRODUCT"), getLang(lang, "label.BOX")];

  function a11yProps(index, label = "label") {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tab-${index}`,
      label: <Typography variant="button" children={label} />,
    };
  }

  return (
    <Box className={classes.panel}>
      <Box mb={1}>
        <Typography variant="h6">
          {getLang(lang, "label.CHECKOUT_LIST")}
        </Typography>
      </Box>
      <Box mb={2}>
        <Tabs
          value={activeIndex}
          onChange={changeTab}
          aria-label="scrollable auto tabs example"
          textColor="primary"
          variant="scrollable"
          indicatorColor="primary"
          classes={{
            indicator: classes.indicator,
            scrollButtons: classes.scroll,
          }}
        >
          {tabLabels.map((item, index) => {
            return <CustomTab key={index} {...a11yProps(index, item)} />;
          })}
        </Tabs>
        <Divider />
      </Box>
      <Box>
        {isError ? (
          <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
            pt={2}
            pb={3}
          >
            <Typography
              variant="body2"
              color="primary"
              style={{ fontWeight: "bold", paddingBottom: "8px" }}
            >
              {getLang(lang, "label.NO_DATA_RECEIVED")}
            </Typography>
            <Typography
              variant="body2"
              style={{ color: "#9CA6A9", paddingBottom: "16px" }}
            >
              {getLang(lang, "label.SOMETHING_WENT_WRONG")}
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleReload}
              disableElevation
            >
              {getLang(lang, "label.REFRESH")}
            </Button>
          </Box>
        ) : (
          <InfiniteScroll
            height="100%"
            className={classes.list}
            dataLength={list.length}
            next={() => {
              if (hasNextPage) handleFetchMoreItems();
            }}
            hasMore={totalFiltered > list.length}
            loader={<LoadingComponent height={100} />}
            endMessage={
              isFetching ? (
                <LoadingComponent height={100} />
              ) : !list.length ? (
                <Box px={1.5} py={4}>
                  <Typography variant="body2" style={{ textAlign: "center" }}>
                    <b>
                      {getLang(
                        lang,
                        activeIndex === 1 ? "label.NO_BOX" : "label.NO_PRODUCT"
                      )}
                    </b>
                  </Typography>
                </Box>
              ) : null
            }
          >
            {activeIndex === 1
              ? [...list].map((box, i) => (
                  <SingleCheckoutBoxAccordion
                    key={i}
                    box={box}
                    lang={lang}
                    selectViewItem={selectItem}
                    selectedBox={selectedItem}
                    id={id}
                  />
                ))
              : [...list].map((product, i) => (
                  <SingleCheckoutProductAccordion
                    key={i}
                    product={product}
                    lang={lang}
                    selectViewItem={selectItem}
                    selectedProduct={selectedItem}
                    id={id}
                  />
                ))}
          </InfiniteScroll>
        )}
      </Box>
    </Box>
  );
}
