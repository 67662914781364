import axios from "axios";

const getItemTotalPackageTrending = ({
  country,
  city_id,
  branch_uuid,
  category_uuid,
  brand_uuid,
  model_uuid,
  product_uuid,
  period,
  start_date,
  end_date,
  url
}) => {

  let city = city_id;
  return new Promise((resolve, reject) => {
    axios
      .post(url, {
        country,
        city,
        branch_uuid,
        category_uuid,
        brand_uuid,
        model_uuid,
        product_uuid,
        period,
        start_date,
        end_date,
      })
      .then((response) => resolve(response.data))
      .catch((error) => {
        if (error?.response?.data) {
          reject(error.response.data);
        } else {
          reject(error);
        }
      });
  });
};

export default getItemTotalPackageTrending;
