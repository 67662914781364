import BackgroundImage from './image/background.png'
import ButtonImage from './image/button.png'

export default {
    backgroundImage: BackgroundImage,
    blocks: [
        { padding: '4px', background: '#93C53A', borderRadius: 9 },
        { padding: '3px', background: '#BDD234', borderRadius: 9 },
        { padding: '10px', background: '#0E6630', borderRadius: 9 },
    ],
    prize: {
        imgWidth: '45px',
        imgHeight: '45px',
        imgTop: '12%',
        fontSize: 12,
        fontTop: '65%'
    },
    spinButtons: [
        {
            x: 1,
            y: 1,
            background: 'rgba(0, 0, 0, 0)',
            imgs: [
                { src: ButtonImage, width: '100%' }
            ]
        }
    ],
    defaultConfig: {
        gutter: 5,
    },
    defaultStyle: {
        borderRadius: 15,
        fontColor: '#D55A05',
        fontSize: '14px',
        textAlign: 'center',
        background: '#F4F6CC',
        shadow: '0 10 1 #E9E966'
    },
    activeStyle: {
        background: '#E9E967',
        shadow: '0 10 1 #E1E13b'
    },
    width: "350px",
    height: "350px"
}