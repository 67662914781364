import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Link } from "@material-ui/core";
import { LinkIconSvg, DustBinIconSvg } from "../../../utils/staticSvg";
import { getLang } from "app/feature/constants";

// We can inject some CSS into the DOM.
const useStyle = makeStyles(theme => ({
  root: {
    padding: 0,
    marginRight: 20,
    // borderBottom: "#3A4D54 2px solid",
    alignItems: "center",
    minWidth: 0,
    cursor: "pointer"
  },
  imageTab: {
    position: "relative",
    width: 153,
    height: 86,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#EBEDEE",
    opacity: 0.33,
    borderRadius: theme.spacing(0.5),
    margin: theme.spacing(2, 0),
    transition: theme.transitions.create("background-color", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    overflow: "hidden",
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "contain"
    }
  },
  indicator: {
    position: "absolute",
    width: 90,
    height: 5,
    backgroundColor: theme.palette.primary.main,
    borderTopLeftRadius: theme.spacing(0.5),
    borderTopRightRadius: theme.spacing(0.5),
    bottom: -18,
    left: "50%",
    transform: "translateX(-50%)"
  },

  imageTabActive: {
    backgroundColor: theme.palette.primary.main,
    opacity: 1
  },
  removeButton: {
    width: 24,
    height: 24,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
    borderRadius: "50%",
    position: "absolute",
    zIndex: 1,
    bottom: 6,
    right: 6
  },
  isEditing: {
    border: "2px dashed darkgray",
    backgroundColor: "#EBEDEE",
    opacity: 1
  },
  opacity1: {
    opacity: 1
  },
  imageSelected: {
    boxShadow: theme.shadows[3]
  },
  actionOverlay: {
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    background: "rgba(0,0,0,0.6)",
    opacity: 0,
    transition: "all 0.5s",
    WebkitTransition: "all 0.5s",
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: 8,
    padding: "15%",
    "&:hover": {
      opacity: 1
    }
  },
  link: {
    color: "white"
  },
  actionContainer: {
    display: "flex",
    gap: 10
  }
}));

function ImageGalleryTabComponent({
  selected,
  imageSrc = "",
  handleDelete,
  handleInsertLink,
  lang,
  ...other
}) {
  const classes = useStyle();

  return (
    <Box className={classes.root} {...other}>
      <Box
        className={clsx(classes.imageTab, {
          [classes.opacity1]: imageSrc !== "",
          [classes.imageTabActive]: selected && !imageSrc,
          [classes.imageSelected]: imageSrc !== "" && selected
        })}
      >
        {imageSrc && (
          <>
            <img src={imageSrc} alt="poster" />
            <Box className={classes.actionOverlay}>
              <Box className={classes.actionContainer}>
                <LinkIconSvg color="white" />
                <Link
                  component="button"
                  variant="body2"
                  onClick={handleInsertLink}
                  className={classes.link}
                >
                  {getLang(lang,"label.INSERT_LINK")}
                </Link>
              </Box>
              <Box className={classes.actionContainer}>
                <DustBinIconSvg color="white" />
                <Link
                  component="button"
                  variant="body2"
                  onClick={handleDelete}
                  className={classes.link}
                >
                  {getLang(lang,"label.DELETE")}
                </Link>
              </Box>
            </Box>
          </>
        )}
        {selected && <Box className={classes.indicator} />}
      </Box>
    </Box>
  );
}

ImageGalleryTabComponent.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default ImageGalleryTabComponent;
