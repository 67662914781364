import PropTypes from "prop-types";
import axios from "axios";

const updateAccountSetting = async ({
  timeZone_Description,
  timeZone_Adjustment,
  theme,
  customization
}) => {
  const response = await axios({
    method: "POST",
    url: "/account/Company/Settings/Update",
    data: {
      timeZone_Description,
      timeZone_Adjustment,
      theme,
      customization
    }
  });

  return response.data;
};

updateAccountSetting.propTypes = {
  timeZone_Description: PropTypes.string.isRequired,
  timeZone_Adjustment: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
  customization: PropTypes.string.isRequired
};

export default updateAccountSetting;
