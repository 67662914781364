// Obsolete: Use the Warrantee List Table under warrantee module

import React, { useState } from "react";
import WarranteeListTableComponent from "./warranteeListTable.component";
import { connect, useSelector } from "react-redux";
import { hideBackdrop, showBackdrop } from "modules/backdrop";
import WarranteeDetailDialog from "../../../../warrantee/components/dialog/warranteeDetailDialog";
import {
  fetchConsumerWarrantyDetail,
  fetchWarrantySettingDetail
} from "modules/warranty/redux";

function WarranteeListTableContainer({
  hasWarrantyEditAccessRight,
  isFetching,
  rows,
  rowsPerPage,
  page,
  search,
  handlePageChange,
  handleRowPerPageChange,
  length,
  fetchConsumerWarrantyDetail,
  hideBackdrop,
  showBackdrop,
  fetchWarrantySettingDetail,
  pageSetting,
  handleReload,
  paginationOptions,
  tableFilter,
  changeOrder
}) {
  const [isModalOpen, setModalOpen] = useState(false);
  const isLoadingWarrantyParticipantError = useSelector(state => state.warranty.isLoadingWarrantyParticipantError)
  const lang = useSelector(state => state.constant.languages);
  const language = useSelector(state => state.profile.language);

  const handleView = async (id) => {
    showBackdrop();
    const response = await fetchConsumerWarrantyDetail(id);
    if (response.type === "warranty/fetchConsumerWarrantyDetail/fulfilled") {
      await fetchWarrantySettingDetail(
        response.payload.data["warranty-setting"].id
      );
    }
    hideBackdrop();
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      <WarranteeListTableComponent
        hasWarrantyEditAccessRight={hasWarrantyEditAccessRight}
        rows={rows}
        isFetching={isFetching}
        language={language}
        rowsPerPage={rowsPerPage}
        handlePageChange={handlePageChange}
        handleRowPerPageChange={handleRowPerPageChange}
        page={page}
        length={length}
        search={search}
        handleView={handleView}
        handleReload={handleReload}
        isLoadingWarrantyParticipantError={isLoadingWarrantyParticipantError}
        paginationOptions={paginationOptions}
        lang={lang}
        tableFilter={tableFilter}
        changeOrder={changeOrder}
      />
      <WarranteeDetailDialog
        isOpen={isModalOpen}
        handleClose={closeModal}
        pageSetting={pageSetting}
      />
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  fetchConsumerWarrantyDetail: (v) => dispatch(fetchConsumerWarrantyDetail(v)),
  hideBackdrop: () => dispatch(hideBackdrop()),
  showBackdrop: () => dispatch(showBackdrop()),
  fetchWarrantySettingDetail: (values) =>
    dispatch(fetchWarrantySettingDetail(values))
});

export default connect(null, mapDispatchToProps)(WarranteeListTableContainer);
