import { createAsyncThunk } from "@reduxjs/toolkit";
import paymentApi from "app/api/payment";
import billingApi from "app/api/billing";

export const BILLING = "billing";

export const fetchPaymentMethods = createAsyncThunk(
  `${BILLING}/fetchPaymentMethods`,
  async (payload, { rejectWithValue }) => {
    return paymentApi.paymentMethods
      .get()
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const addPaymentMethod = createAsyncThunk(
  `${BILLING}/addPaymentMethod`,
  async (payload, { rejectWithValue }) => {
    const { isDefault, payment_method } = payload;
    return paymentApi.paymentMethod
      .post({ isDefault, payment_method })
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const deletePaymentMethod = createAsyncThunk(
  `${BILLING}/deletePaymentMethod`,
  async (payload, { rejectWithValue }) => {
    const id = payload;
    return paymentApi.paymentMethod
      .Delete({ id })
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const updateDefaultPaymentMethod = createAsyncThunk(
  `${BILLING}/updateDefaultPaymentMethod`,
  async (payload, { rejectWithValue }) => {
    const id = payload;
    return paymentApi.paymentMethod
      .put({ id })
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const fetchQuickSubscriptionSummary = createAsyncThunk(
  `${BILLING}/fetchQuickSubscriptionSummary`,
  async (payload, { rejectWithValue }) => {
    return paymentApi.subscription.summary
      .get()
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const fetchBillingDetail = createAsyncThunk(
  `${BILLING}/fetchBillingDetail`,
  async (payload, { rejectWithValue }) => {
    return billingApi.billingDetail
      .get()
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const updateBillingDetail = createAsyncThunk(
  `${BILLING}/updateBillingDetail`,
  async (payload, thunkApi) => {
    return billingApi.billingDetail.put({
      name: payload.name,
      email: payload.email,
      phone: payload.phone,
      contact_code: payload.contact_code,
      line1: payload.line1,
      line2: payload.line2,
      city: payload.city,
      postalCode: payload.postal_code,
      state: payload.state,
      country: payload.country
    })
      .then((response) => response)
      .catch((error) => thunkApi.rejectWithValue(error));

  }
);

export const fetchSubscriptionPlan = createAsyncThunk(
  `${BILLING}/fetchSubscriptionPlan`,
  async (payload, { rejectWithValue }) => {
    return billingApi.subscriptionPlan
      .get()
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const fetchTransactionHistory = createAsyncThunk(
  `${BILLING}/fetchTransactionHistory`,
  async (payload, { rejectWithValue }) => {
    return billingApi.transactionHistory
      .get(payload)
      .then((response) => {
        response.data.module = payload.type;
        return response.data;
      })
      .catch((error) => rejectWithValue(error));
  }
);

export const fetchTransactionLatest = createAsyncThunk(
  `${BILLING}/fetchTransactionLatest`,
  async (payload, { rejectWithValue }) => {
    return billingApi.latestTransaction
      .get()
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);