import axios from "axios";

function BranchDropdown() {
  return new Promise((resolve, reject) => {
    axios
      .get("/account/api/v1/nodes/me-bottom-dropdown")
      .then(response => {
        return resolve(response.data);
      })
      .catch(error => {
        return reject(error.response.data);
      });
  });
};

export default BranchDropdown;