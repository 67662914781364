import React, { useState } from "react"
import GenerateProductComponent from './generateProduct.component'
import { generatePaginationOptions } from "lib/helper"
import GenerateProductLoadingModal from "modules/product-mgmt/components/modal/generateProductLoadingModal"
import ConfirmationDialog from "components/dialog/confirmation"
import { useDispatch, useSelector } from "react-redux"
import { createBulkProduct } from "modules/product-mgmt/redux/action/products.action"
import GeneralDialog from "components/dialog/generalDialog"
import AlertIcon from 'assets/svg/alert.svg'
import SuccessIcon from 'assets/svg/successTick.svg'
import { unwrapResult } from "@reduxjs/toolkit"
import { useHistory } from "react-router-dom"
import { ROOT as PRODUCT_LIST } from "modules/product-mgmt/config/product-mgmt.route"
import { getLang } from "app/feature/constants"

function GenerateProductContainer() {
  const lang = useSelector(state => state.constant.languages);
  const dispatch = useDispatch()
  const history = useHistory()
  const [isAddProduct, setIsAddProduct ] = useState(false)
  const [paginationOptions, setPaginationOptions] = useState([])
  const [isGeneratingProduct, setIsGeneratingProduct] = useState(false)
  const [generatedProducts, setGeneratedProducts] = useState([])
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false)
  const [isGenerateSuccessDialogOpen, setIsGenerateSuccessDialogOpen] = useState(false)
  const [isGenerateErrorDialogOpen, setIsGenerateErrorDialogOpen] = useState(false)
  const [generateLoading, setGenerateLoading] = useState(false);
  const [fieldRequireError, setFieldRequireError] = useState(false)

  const handleAddProducts = (products, values, setSubmitting) => {
    setIsGeneratingProduct(true)
    setIsAddProduct(true)
    
    let generatedPrds = products.map((product, index) => {
      let productDetail = {
        index,
        name: "",
        category: null,
        brand: null,
        model: null,
        branch: [],
        attributes: [],
        currencyCode: null,
        price: 0,
        sku: "",
        scanLimit: "",
        incentivePoint: "",
        isSelected: false
      }
      product.forEach(item => {
        if (item.name === "brandModel") {
          productDetail['brand'] = item.brand
          productDetail['model'] = item.model
        } else {
          productDetail[item.name] = item.value
        }
      })
      let name = ""
      if (values.isCategory && productDetail.category) {
        name += productDetail.category.name + " "
      }
      if (values.isBrand && productDetail.brand && productDetail.brand.id !== "-1") {
        name += productDetail.brand.name + " "
      }
      if (values.isModel && productDetail.model) {
        name += productDetail.model.name + " "
      }
      if (values.isAttribute && productDetail.attributes?.length) {
        for (let attr of productDetail.attributes) {
          name += attr.option?.value + " "
        }
      }
      productDetail['name'] = name.trim()

      return productDetail
    })

    setGeneratedProducts(generatedPrds)
    setPaginationOptions(generatePaginationOptions(generatedPrds.length))

    setIsGeneratingProduct(false)
    setSubmitting(false)
  }

  
  const clearProducts = () => {
    setGeneratedProducts([])
    setIsAddProduct(false)
  }

  const deleteProducts = (list) => {
    let temp = [...generatedProducts]
    temp = temp.filter(t => !list.includes(t.index))
    setGeneratedProducts(temp)
  }

  const handleSubmit = (products) => {
    setIsConfirmationDialogOpen(true)
    setGeneratedProducts(products)
  }

  const bulkCreate = () => {
    setIsConfirmationDialogOpen(false)
    setGenerateLoading(true)

    let missingField = false
    let prds = generatedProducts.map((p) => {
      if (!p.name || !p.branch.length) {
        missingField = true
      }
      let item = {}
      item.name = p.name
      item.category = p.category?.uuid || ""
      item.brand = p.brand?.id && p.brand.id !== "-1" ? p.brand.id : ""
      item.model = p.model?.id || ""
      item.nodes = p.branch.length ? p.branch.map(b => b.node_id) : []
      item.attributeoptions = p.attributes.length ? p.attributes.map(a => a.option.id) : []
      item.scanlimit = p.scanLimit || 0
      item.sku = p.sku || ""
      item.currency_code = p.currencyCode?.alpha3 || ""
      item.unit_price = p.price || 0
      item.incentive_point = p.incentivePoint || 0

      return item
    })

    if (!missingField) {
      setFieldRequireError(false)
      dispatch(createBulkProduct({ data: prds }))
      .then(unwrapResult)
      .then(() => {
        setIsGenerateSuccessDialogOpen(true)
      })
      .catch((e) => {
        setIsGenerateErrorDialogOpen(true)
      })
      .finally(() => {
        setGenerateLoading(false)
      })
    } else {
      setFieldRequireError(true)
      setGenerateLoading(false)
    }
  }

  return (
    <>
      <GenerateProductComponent
        handleAddProducts={handleAddProducts}
        isAddProduct={isAddProduct}
        paginationOptions={paginationOptions}
        isGeneratingProduct={isGeneratingProduct}
        generatedProducts={generatedProducts}
        clearProducts={clearProducts}
        deleteProducts={deleteProducts}
        handleSubmit={handleSubmit}
        fieldRequireError={fieldRequireError}
        lang={lang}
      />
      <GenerateProductLoadingModal open={generateLoading} />
      <ConfirmationDialog
        isOpen={isConfirmationDialogOpen}
        handleClose={() => setIsConfirmationDialogOpen(false)}
        handleProceed={() => bulkCreate()}
        description={getLang(lang, "paragraph.GENERATE_PRODUCTS_QUESTION")}
        title={getLang(lang, "label.CONFIRMATION_NEEDED")}
      />
      <GeneralDialog
        isOpen={isGenerateSuccessDialogOpen}
        handleClose={() => {
          setIsGenerateSuccessDialogOpen(false)
          history.push(PRODUCT_LIST)
        }}
        handleProceed={() => {
          setIsGenerateSuccessDialogOpen(false)
          history.push(PRODUCT_LIST)
        }}
        title={getLang(lang, "label.PRODUCT_GENERATED")}
        description={getLang(lang, "paragraph.GENERATE_PRODUCTS_SUCCESS", { count: generatedProducts.length })}
        type="success"
        icon={<img src={SuccessIcon} alt="alert" />}
        proceedLabel={getLang(lang, "label.OK")}
        rejectOption={false}
      />
      <GeneralDialog
        isOpen={isGenerateErrorDialogOpen}
        handleClose={() => setIsGenerateErrorDialogOpen(false)}
        handleProceed={() => setIsGenerateErrorDialogOpen(false)}
        title={getLang(lang, "label.PRODUCT_GENERATE_FAILED")}
        description={getLang(lang, "paragraph.GENERATE_PRODUCTS_FAIL")}
        type="danger"
        icon={<img src={AlertIcon} alt="alert" />}
        proceedLabel={getLang(lang, "label.OK")}
        rejectOption={false}
      />
    </>
  )
}

export default GenerateProductContainer