import axios from "axios";

const editAdtInfoTitle = (title) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/serialnumber/api/v1/additional-info-title/update`, {
        title: title,
      })
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

export default editAdtInfoTitle;
