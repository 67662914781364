import { getApiLang } from "app/feature/constants";
import { setLoading, addAlert } from "modules/notification";
import {
  cancelStockTake,
  createStockTake,
  endStockTake,
  getStockTakeDetail,
  getStockTakeItemBoxList,
  getStockTakeItemBoxProductList,
  getStockTakeItemProductList,
  getStockTakeItemProductSNList,
  getStockTakeList,
  updateStockTake,
} from "../action";

const getStockTakeListMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getStockTakeList.pending.type:
        dispatch(setLoading({ id: getStockTakeList.typePrefix, state: true }));
        break;
      case getStockTakeList.fulfilled.type:
        dispatch(setLoading({ id: getStockTakeList.typePrefix, state: false }));
        break;
      case getStockTakeList.rejected.type:
        const lang = getState().constant.languages;
        dispatch(setLoading({ id: getStockTakeList.typePrefix, state: false }));
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_GET_STOCK_TAKE_LIST"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const getStockTakeDetailMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getStockTakeDetail.pending.type:
        dispatch(
          setLoading({ id: getStockTakeDetail.typePrefix, state: true })
        );
        break;
      case getStockTakeDetail.fulfilled.type:
        dispatch(
          setLoading({ id: getStockTakeDetail.typePrefix, state: false })
        );
        break;
      case getStockTakeDetail.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({ id: getStockTakeDetail.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_GET_STOCK_TAKE_DETAIL"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const createStockTakeMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case createStockTake.pending.type:
        dispatch(
          setLoading({
            id: createStockTake.typePrefix,
            state: true,
          })
        );
        break;
      case createStockTake.fulfilled.type:
        dispatch(
          setLoading({
            id: createStockTake.typePrefix,
            state: false,
          })
        );
        break;
      case createStockTake.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: createStockTake.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

const updateStockTakeMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case updateStockTake.pending.type:
        dispatch(
          setLoading({
            id: updateStockTake.typePrefix,
            state: true,
          })
        );
        break;
      case updateStockTake.fulfilled.type:
        dispatch(
          setLoading({
            id: updateStockTake.typePrefix,
            state: false,
          })
        );
        break;
      case updateStockTake.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: updateStockTake.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

const cancelStockTakeMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case cancelStockTake.pending.type:
        dispatch(
          setLoading({
            id: cancelStockTake.typePrefix,
            state: true,
          })
        );
        break;
      case cancelStockTake.fulfilled.type:
        dispatch(
          setLoading({
            id: cancelStockTake.typePrefix,
            state: false,
          })
        );
        break;
      case cancelStockTake.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: cancelStockTake.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_CANCEL_STOCK_TAKE"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const endStockTakeMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case endStockTake.pending.type:
        dispatch(
          setLoading({
            id: endStockTake.typePrefix,
            state: true,
          })
        );
        break;
      case endStockTake.fulfilled.type:
        dispatch(
          setLoading({
            id: endStockTake.typePrefix,
            state: false,
          })
        );
        break;
      case endStockTake.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: endStockTake.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_END_STOCK_TAKE"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const getStockTakeItemBoxListMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getStockTakeItemBoxList.pending.type:
        dispatch(
          setLoading({ id: getStockTakeItemBoxList.typePrefix, state: true })
        );
        break;
      case getStockTakeItemBoxList.fulfilled.type:
        dispatch(
          setLoading({ id: getStockTakeItemBoxList.typePrefix, state: false })
        );
        break;
      case getStockTakeItemBoxList.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({ id: getStockTakeItemBoxList.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_GET_STOCK_TAKE_BOXES"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const getStockTakeItemBoxProductListMiddleWare =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getStockTakeItemBoxProductList.pending.type:
        dispatch(
          setLoading({
            id: getStockTakeItemBoxProductList.typePrefix,
            state: true,
          })
        );
        break;
      case getStockTakeItemBoxProductList.fulfilled.type:
        dispatch(
          setLoading({
            id: getStockTakeItemBoxProductList.typePrefix,
            state: false,
          })
        );
        break;
      case getStockTakeItemBoxProductList.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: getStockTakeItemBoxProductList.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_INVALID_BOX_ID"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const getStockTakeItemProductSNListMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getStockTakeItemProductSNList.pending.type:
        dispatch(
          setLoading({
            id: getStockTakeItemProductSNList.typePrefix,
            state: true,
          })
        );
        break;
      case getStockTakeItemProductSNList.fulfilled.type:
        dispatch(
          setLoading({
            id: getStockTakeItemProductSNList.typePrefix,
            state: false,
          })
        );
        break;
      case getStockTakeItemProductSNList.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: getStockTakeItemProductSNList.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_GET_STOCK_TAKE_PRODUCT_SN_LIST"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const getStockTakeItemProductListMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getStockTakeItemProductList.pending.type:
        dispatch(
          setLoading({
            id: getStockTakeItemProductList.typePrefix,
            state: true,
          })
        );
        break;
      case getStockTakeItemProductList.fulfilled.type:
        dispatch(
          setLoading({
            id: getStockTakeItemProductList.typePrefix,
            state: false,
          })
        );
        break;
      case getStockTakeItemProductList.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: getStockTakeItemProductList.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_GET_STOCK_TAKE_PRODUCTS"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

export default [
  getStockTakeListMiddleware,
  getStockTakeDetailMiddleware,
  createStockTakeMiddleware,
  updateStockTakeMiddleware,
  cancelStockTakeMiddleware,
  endStockTakeMiddleware,
  getStockTakeItemBoxListMiddleware,
  getStockTakeItemBoxProductListMiddleWare,
  getStockTakeItemProductListMiddleware,
  getStockTakeItemProductSNListMiddleware,
];
