import React from "react";
import AttributesListTableComponent from './attributesListTable.component';

export default function AttributesListTableContainer({
  hasProductAttributeEditAccessRight,
  hasProductAttributeDeleteAccessRight,
  isSelectAll,
  selectedIds,
  paginationOptions,
  data,
  isFetching,
  isLoadingAttributeListError,
  handleChangePage,
  handleChangeRowsPerPage,
  handleReload,
  page,
  search,
  totalFiltered,
  rowsPerPage,
  handleCreateAttribute,
  handleChangeStatus,
  handleSelectAttribute,
  handleSelectAvailable,
  handleSelectAll,
  handleDeselectAll,
  handleDeleteAttribute,
  handleEditAttribute,
  lang
}) {
  return (
    <AttributesListTableComponent
      hasProductAttributeEditAccessRight={hasProductAttributeEditAccessRight}
      hasProductAttributeDeleteAccessRight={hasProductAttributeDeleteAccessRight}
      isSelectAll={isSelectAll}
      selectedIds={selectedIds}
      paginationOptions={paginationOptions}
      data={data}
      isFetching={isFetching}
      isLoadingAttributesListError={isLoadingAttributeListError}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      handleReload={handleReload}
      page={page}
      rowsPerPage={rowsPerPage}
      search={search}
      totalFiltered={totalFiltered}
      handleCreateAttribute={handleCreateAttribute}
      handleChangeStatus={handleChangeStatus}
      handleSelectAttribute={handleSelectAttribute}
      handleSelectAvailable={handleSelectAvailable}
      handleSelectAll={handleSelectAll}
      handleDeselectAll={handleDeselectAll}
      handleDeleteAttribute={handleDeleteAttribute}
      handleEditAttribute={handleEditAttribute}
      lang={lang}
    />
  )
}