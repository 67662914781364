import React from "react";
import {
  makeStyles,
  Typography,
  Box,
  CircularProgress,
  Paper,
  Button,
  Divider,
} from "@material-ui/core";
import { BackButton } from "components/button";
import Input from "components/input/inputTextField";
import { Formik } from "formik";
import { useHistory } from "react-router";
import RequestTransferBranchSelect from "modules/stockflow/components/select/requestTransferranchSelect";
import RequestTransferProductListPanel from "modules/stockflow/components/panel/requestTransferProductListPanel";
import SearchableSelect from "components/select/searchableSelect";
import { getLang } from "app/feature/constants";
import RequestTransferReceiverPanel from "modules/stockflow/components/panel/requestTransferReceiverPanel";

const useStyle = makeStyles((theme) => ({
  panel: {
    backgroundColor: "#FFFFFF",
    borderRadius: 4,
  },
  inputSection: {
    display: "grid",
    gridTemplateColumns: "2fr 6fr",
    columnGap: theme.spacing(2),
    alignItems: "center",
  },
  inputRootID: {
    paddingTop: "8px !important",
    paddingBottom: "7px !important",
    paddingLeft: "12px !important",
    paddingRight: "12px !important",
    backgroundColor: "#FFFFFF",
    border: "1px solid #D0D5DD",
    borderRadius: 4,
  },
}));

export default function CreateRequestTransferComponent({
  lang,
  isSubmitting,
  handleSubmit,
}) {
  const classes = useStyle();
  const history = useHistory();

  const termDropdown = [
    "-",
    "Telegraphic Transfer",
    "Register Wallet",
    "Others",
  ];

  return (
    <Box className={classes.root}>
      <Box gridArea="topbar" mb={3}>
        <Box
          mb={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="flex" alignItems="flex-end" fontWeight="bold">
            <BackButton />
            <Box display="flex" ml={1} alignItems="flex-end">
              <Typography variant="h6" color="primary">
                {getLang(lang, "label.REQUEST_TRANSFER")}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box>
        <Formik
          initialValues={{
            fromBranch: null,
            toBranch: null,
            products: {},
            term: "",
            customTerm: "",
            receiver: null,
            remark: "",
          }}
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <Box gridArea="panel" mb={2}>
                <Paper
                  className={classes.panel}
                  elevation={3}
                  style={{ height: "100%" }}
                >
                  <Box p={3}>
                    <Box className={classes.inputSection} mb={2}>
                      <Box>
                        <Typography>
                          {getLang(lang, "label.REQUEST_BY")}{" "}
                          <span style={{ color: "#F97066" }}>*</span>
                        </Typography>
                      </Box>
                      <RequestTransferBranchSelect
                        placeholder={getLang(lang, "placeholder.SELECT_BRANCH")}
                        value={formik.values.fromBranch}
                        handleChange={(value) => {
                          if (
                            !value ||
                            !formik.values.fromBranch ||
                            value.uuid !== formik.values.fromBranch.uuid
                          ) {
                            formik.setFieldValue("fromBranch", value);
                            formik.setFieldValue("toBranch", null);
                            formik.setFieldValue("products", {});
                            formik.setFieldValue("receiver", null);
                          }
                        }}
                        styles={{
                          padding: "6px 10px",
                        }}
                        disabled={isSubmitting}
                        type="from"
                      />
                    </Box>
                    <Box className={classes.inputSection} mb={2}>
                      <Box>
                        <Typography>
                          {getLang(lang, "label.REQUEST_STOCK_FROM")}{" "}
                          <span style={{ color: "#F97066" }}>*</span>
                        </Typography>
                      </Box>
                      <RequestTransferBranchSelect
                        placeholder={getLang(lang, "placeholder.SELECT_BRANCH")}
                        value={formik.values.toBranch}
                        handleChange={(value) => {
                          if (
                            !value ||
                            !formik.values.toBranch ||
                            value.uuid !== formik.values.toBranch.uuid
                          ) {
                            formik.setFieldValue("toBranch", value);
                            formik.setFieldValue("products", {});
                          }
                        }}
                        styles={{
                          padding: "6px 10px",
                        }}
                        disabled={isSubmitting || !formik.values.fromBranch}
                        type="to"
                        fromBranch={formik.values.fromBranch}
                      />
                    </Box>
                    {!!formik.values.fromBranch && !!formik.values.toBranch && (
                      <>
                        <Box
                          className={classes.inputSection}
                          style={{ alignItems: "flex-start" }}
                        >
                          <Box mt={0.5}>
                            <Typography>
                              {getLang(lang, "label.PRODUCTS")}{" "}
                              <span style={{ color: "#F97066" }}>*</span>
                            </Typography>
                          </Box>
                          <RequestTransferProductListPanel
                            fromUuid={formik.values.fromBranch.uuid}
                            toUuid={formik.values.toBranch.uuid}
                            selectProducts={(prdList) => {
                              formik.setFieldValue("products", prdList);
                            }}
                            products={formik.values.products}
                          />
                        </Box>
                        <Box py={3}>
                          <Divider />
                        </Box>
                        <Box
                          className={classes.inputSection}
                          mb={2}
                          style={{ alignItems: "flex-start" }}
                        >
                          <Box mt={0.5}>
                            <Typography>
                              {getLang(lang, "label.RECEIVER")}{" "}
                              <span style={{ color: "#F97066" }}>*</span>
                            </Typography>
                          </Box>
                          <RequestTransferReceiverPanel
                            receiver={formik.values.receiver}
                            handleChange={(receiver) => {
                              formik.setFieldValue("receiver", receiver);
                            }}
                            branch={formik.values.fromBranch}
                          />
                        </Box>
                        <Box
                          className={classes.inputSection}
                          mb={2}
                          style={{ alignItems: "flex-start" }}
                        >
                          <Box mt={0.5}>
                            <Typography>
                              {getLang(lang, "label.TERM")}
                            </Typography>
                          </Box>
                          <Box>
                            <SearchableSelect
                              value={formik.values.term}
                              isEmpty={formik.values.term === ""}
                              handleChange={(value) => {
                                if (
                                  formik.values.term !== value &&
                                  formik.values.customTerm
                                ) {
                                  formik.setFieldValue("customTerm", "");
                                }
                                formik.setFieldValue(
                                  "term",
                                  value === "-" ? "" : value
                                );
                              }}
                              placeholder={getLang(
                                lang,
                                "placeholder.SELECT_TERM"
                              )}
                              disabled={isSubmitting}
                              searchable={false}
                              panelStyle={{ width: "249px" }}
                              style={{
                                height: "2.5rem",
                                backgroundColor: "white",
                                border: "1px solid #D0D5DD",
                              }}
                              textStyle={{ fontWeight: 400, fontSize: 16 }}
                              dropdownItem={termDropdown}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                              }}
                              returnType="value"
                            />
                            {formik.values.term === "Others" && (
                              <Box mt={1}>
                                <Input
                                  hiddenLabel
                                  variant="filled"
                                  size="small"
                                  placeholder={getLang(
                                    lang,
                                    "placeholder.ENTER_VALUE_HERE"
                                  )}
                                  margin="none"
                                  InputProps={{
                                    disableUnderline: true,
                                    margin: "none",
                                    style: {
                                      padding: 0,
                                      backgroundColor: "white",
                                      width: "100%",
                                    },
                                    classes: { input: classes.inputRootID },
                                  }}
                                  style={{
                                    width: "100%",
                                  }}
                                  disabled={isSubmitting}
                                  value={formik.values.customTerm}
                                  {...formik.getFieldProps("customTerm")}
                                />
                              </Box>
                            )}
                          </Box>
                        </Box>
                        <Box
                          className={classes.inputSection}
                          mb={2}
                          style={{ alignItems: "flex-start" }}
                        >
                          <Box mt={0.5}>
                            <Typography>
                              {getLang(lang, "label.REMARKS")}
                            </Typography>
                          </Box>
                          <Input
                            hiddenLabel
                            variant="filled"
                            size="small"
                            multiline="textArea"
                            rows={5}
                            placeholder={getLang(
                              lang,
                              "placeholder.ENTER_REMARKS_HERE"
                            )}
                            margin="none"
                            InputProps={{
                              disableUnderline: true,
                              margin: "none",
                              style: {
                                padding: 0,
                                backgroundColor: "white",
                              },
                              classes: { input: classes.inputRootID },
                            }}
                            disabled={isSubmitting}
                            {...formik.getFieldProps("remark")}
                          />
                        </Box>
                      </>
                    )}
                  </Box>
                  <Divider />
                  <Box display="flex" justifyContent="flex-end" p={2}>
                    <Button
                      variant="text"
                      disableElevation
                      color="primary"
                      size="small"
                      style={{ marginRight: 4 }}
                      onClick={() => history.goBack()}
                    >
                      {getLang(lang, "label.CANCEL")}
                    </Button>
                    <Button
                      variant="contained"
                      disableElevation
                      color="secondary"
                      size="small"
                      type="submit"
                      loading={isSubmitting}
                      disabled={
                        !formik.values.fromBranch ||
                        !formik.values.toBranch ||
                        !Object.keys(formik.values.products).length ||
                        !formik.values.receiver ||
                        (formik.values.term === "Others" &&
                          !formik.values.customTerm)
                      }
                    >
                      {isSubmitting ? (
                        <CircularProgress
                          size={20}
                          style={{ color: "white" }}
                        />
                      ) : (
                        getLang(lang, "label.SAVE")
                      )}
                    </Button>
                  </Box>
                </Paper>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Box>
  );
}
