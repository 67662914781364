import React from "react";
import { makeStyles, Typography, Box, Grid } from "@material-ui/core";
import LogoSmartKood from "../../../../assets/img/logo-smartkood.png";
import { getLang } from "app/feature/constants";

const style = theme => ({
  root: {
    padding: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(2, 3, 2, 3)
    }
  },
  logo: {
    "& img": {
      width: 83
    }
  },
  title: {
    justifyContent: "flex-end",
    width: "100%",
    display: "flex",
    alignItems: "center"
  }
});

const useStyle = makeStyles(style);

export default function HeaderComponent({lang}) {
  const classes = useStyle();
  return (
    <Box className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={5} sm={5} md={6} className={classes.logo}>
          <img
            src={LogoSmartKood}
            alt="logo company"
            style={{ verticalAlign: "top" }}
          />
        </Grid>
        <Grid item xs={7} sm={7} md={6} className={classes.title}>
          <Box>
            <Typography
              variant="body1"
              style={{ fontWeight: "bold", margin: 0 }}
              gutterBottom
            >
              {getLang(lang, "label.GROW_BUSINESS_DATA_UPPERCASE")}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
