import React from "react";
import {
  Box,
  Button,
  Divider,
  Paper,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { getLang } from "app/feature/constants";
import InfiniteScroll from "react-infinite-scroll-component";
import LoadingComponent from "components/loading";
import DefaultImg from "assets/img/img-default.png";
import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";

const useStyles = makeStyles((theme) => ({
  summaryBox: {
    border: "2px solid #D0D5DD",
    backgroundColor: "#ffffff",
    cursor: "pointer",
  },
  selectedSummaryBox: {
    border: "2px solid #6AAF68",
    backgroundColor: "#F7FBF7",
    cursor: "pointer",
  },
  productRow: {
    border: "1px solid #D0D5DD",
    "& > div:first-child": {
      borderBottom: "1px solid #D0D5DD",
    },
  },
  productPhoto: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #0000001a",
    width: "45px",
    minWidth: "45px",
    height: "45px",
    borderRadius: "4px",
    "& img": {
      height: "100%",
      width: "100%",
    },
  },
  list: {
    maxHeight: "70vh",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "5px",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "16px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#E4E7EC",
      borderRadius: "16px",
    },
  },
  attributeText: {
    color: "#b2b2b2",
    fontSize: 12,
    whiteSpace: "break-spaces",
  },
}));

export default function TransferItemsListPanelComponent({
  totalBox,
  totalPackage,
  lang,
  tab,
  changeTab,
  isFetching,
  list,
  totalFiltered,
  isError,
  handleFetchMoreItems,
  hasNextPage,
  handleReload,
  viewProduct,
  viewBox,
}) {
  const classes = useStyles();

  return (
    <Paper elevation={0}>
      <Box px={2} py={2}>
        <Box mb={1.5}>
          <Typography variant="body1">
            <b>{getLang(lang, "label.ITEMS_TO_RECEIVE")}</b>
          </Typography>
        </Box>
        <Divider />
        <Box mt={2} mb={3} display="flex">
          <Box
            className={
              tab === 0 ? classes.selectedSummaryBox : classes.summaryBox
            }
            px={2}
            py={1}
            onClick={() => changeTab(0)}
            flex={1}
          >
            <Typography variant="body1">
              <b>{totalBox ?? 0}</b>
            </Typography>
            <Typography variant="body1">
              {getLang(lang, "label.TOTAL_BOX")}
            </Typography>
          </Box>
          <Box
            className={
              tab === 1 ? classes.selectedSummaryBox : classes.summaryBox
            }
            px={2}
            py={1}
            onClick={() => changeTab(1)}
            flex={1}
          >
            <Typography variant="body1">
              <b>{totalPackage ?? 0}</b>
            </Typography>
            <Typography variant="body1">
              {getLang(lang, "label.TOTAL_PRODUCT")}
            </Typography>
          </Box>
        </Box>
        {isError ? (
          <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
            pt={2}
            pb={3}
          >
            <Typography
              variant="body2"
              color="primary"
              style={{ fontWeight: "bold", paddingBottom: "8px" }}
            >
              {getLang(lang, "label.NO_DATA_RECEIVED")}
            </Typography>
            <Typography
              variant="body2"
              style={{ color: "#9CA6A9", paddingBottom: "16px" }}
            >
              {getLang(lang, "label.SOMETHING_WENT_WRONG")}
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleReload}
              disableElevation
            >
              {getLang(lang, "label.REFRESH")}
            </Button>
          </Box>
        ) : (
          <InfiniteScroll
            height="100%"
            className={classes.list}
            dataLength={list.length}
            next={() => {
              if (hasNextPage) handleFetchMoreItems();
            }}
            hasMore={totalFiltered > list.length}
            loader={<LoadingComponent height={100} />}
            endMessage={
              isFetching ? (
                <LoadingComponent height={100} />
              ) : !list.length ? (
                <Box px={1.5} py={4}>
                  <Typography variant="body2" style={{ textAlign: "center" }}>
                    <b>
                      {getLang(
                        lang,
                        tab === 0 ? "label.NO_BOX" : "label.NO_PRODUCT"
                      )}
                    </b>
                  </Typography>
                </Box>
              ) : null
            }
          >
            {[...list].map((item, i) => (
              <Box key={i} className={classes.productRow} mt={i === 0 ? 0 : 2}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  p={1}
                >
                  {tab === 0 ? (
                    <Box>
                      <Typography variant="body1">
                        <b>{item.name}</b>
                      </Typography>
                    </Box>
                  ) : (
                    <Box display="flex" alignItems="center">
                      <Box className={classes.productPhoto} mr={2}>
                        {!item?.picture ? (
                          <img src={DefaultImg} alt="emptyProduct" />
                        ) : (
                          <img src={item.picture} alt={item.name} />
                        )}
                      </Box>
                      <Box>
                        <Typography variant="body1">
                          <b>{item.name}</b>
                        </Typography>
                        {!!item.attributes && !!item.attributes.length && (
                          <Typography
                            className={classes.attributeText}
                            variant="body2"
                          >
                            {item.attributes.join(", ")}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  )}
                  <ChevronRightRoundedIcon
                    color="primary"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      if (tab === 0) {
                        viewBox(item);
                      } else {
                        viewProduct(item);
                      }
                    }}
                  />
                </Box>
                <Box p={1}>
                  {tab === 0 ? (
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography variant="body2">
                        {getLang(lang, "label.SERIAL_NUMBER_QTY")}
                      </Typography>
                      <Typography variant="body1">
                        <b>{item.quantity}</b>
                      </Typography>
                    </Box>
                  ) : (
                    <Box>
                      <Typography variant="body1">
                        <b>{item.total_transfer}</b>
                      </Typography>
                      <Typography variant="body2">
                        {getLang(lang, "label.TRANSFERRED")}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            ))}
          </InfiniteScroll>
        )}
      </Box>
    </Paper>
  );
}
