import React from "react";

export const ImageSvg = ({ color = "#899498" }) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 0H4C1.79395 0 0 1.79395 0 4V28C0 30.2061 1.79395 32 4 32H28C30.2061 32 32 30.2061 32 28V4C32 1.79395 30.2061 0 28 0ZM4 2.66667H28C28.7354 2.66667 29.3333 3.26465 29.3333 4V13.4479L24.9427 9.05729C24.4219 8.53678 23.5781 8.53678 23.0573 9.05729L13.6667 18.4479L10.276 15.0573C9.75521 14.5368 8.91146 14.5368 8.39062 15.0573L2.66667 20.7812V4C2.66667 3.26465 3.26465 2.66667 4 2.66667ZM28 29.3333H4C3.26465 29.3333 2.66667 28.7354 2.66667 28V24.5521L9.33333 17.8854L17.0573 25.6087C17.318 25.8701 17.6585 26 18 26C18.3415 26 18.682 25.8701 18.9427 25.6094C19.4632 25.0885 19.4632 24.2448 18.9427 23.724L15.5521 20.3333L24 11.8861L29.3333 17.2194V28C29.3333 28.7354 28.7354 29.3333 28 29.3333Z"
      fill={color}
    />
    <path
      d="M10.6668 7.99967C10.6668 9.47233 9.47282 10.6663 8.00016 10.6663C6.52751 10.6663 5.3335 9.47233 5.3335 7.99967C5.3335 6.52702 6.52751 5.33301 8.00016 5.33301C9.47282 5.33301 10.6668 6.52702 10.6668 7.99967Z"
      fill={color}
    />
  </svg>
);

export const AddImageSvg = ({ color = "#fff" }) => (
  <svg
    width="44"
    height="44"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.7068 14.0597C16.7068 15.5236 15.5241 16.7063 14.0602 16.7063C12.5963 16.7063 11.4136 15.5236 11.4136 14.0597C11.4136 12.5958 12.5963 11.4131 14.0602 11.4131C15.5241 11.4131 16.7068 12.5958 16.7068 14.0597Z"
      fill={color}
    />
    <path
      d="M43.661 30.5937C43.4956 30.2049 43.2227 29.8824 42.8836 29.6508C42.5445 29.4192 42.1227 29.2786 41.6843 29.2869H38.7069V26.3094C38.7069 26.0117 38.649 25.7305 38.5414 25.4741C38.376 25.0854 38.1031 24.7628 37.764 24.5312C37.4249 24.2997 37.0031 24.1591 36.5648 24.1673C36.267 24.1673 35.9858 24.2252 35.7294 24.3328C35.3407 24.4982 35.0181 24.7711 34.7866 25.1102C34.555 25.4493 34.4144 25.8711 34.4226 26.3094V29.2869H31.4452C31.1474 29.2869 30.8662 29.3448 30.6099 29.4523C30.2211 29.6177 29.8986 29.8906 29.667 30.2297C29.4354 30.5688 29.2948 30.9906 29.3031 31.429C29.3031 31.7267 29.361 32.0079 29.4685 32.2643C29.6339 32.6531 29.9068 32.9756 30.2459 33.2072C30.585 33.4388 31.0068 33.5794 31.4452 33.5711H34.4226V36.5485C34.4226 36.8463 34.4805 37.1275 34.5881 37.3839C34.7535 37.7726 35.0264 38.0952 35.3655 38.3267C35.7046 38.5583 36.1264 38.6989 36.5648 38.6906C36.8625 38.6906 37.1437 38.6328 37.4001 38.5252C37.7888 38.3598 38.1114 38.0869 38.3429 37.7478C38.5745 37.4087 38.7151 36.9869 38.7069 36.5485V33.5711H41.6843C41.9821 33.5711 42.2633 33.5132 42.5196 33.4057C42.9084 33.2403 43.2309 32.9673 43.4625 32.6282C43.6941 32.2891 43.8347 31.8673 43.8264 31.429C43.8347 31.1395 43.7685 30.85 43.661 30.5937Z"
      fill={color}
    />
    <path
      d="M32.7517 36.5562V35.2329H31.4284C31.3705 35.2329 31.3209 35.2329 31.263 35.2329H10.09C9.36222 35.2329 8.76673 34.6374 8.76673 33.9096V30.4855L15.3833 23.869L23.0502 31.5359C23.3066 31.7923 23.6457 31.9246 23.9848 31.9246C24.3239 31.9246 24.663 31.7923 24.9194 31.5359C25.4321 31.0149 25.4321 30.1795 24.9194 29.6667L21.5532 26.3006L29.9397 17.9141L34.9021 22.8765C34.96 22.8517 35.0096 22.8186 35.0675 22.7938C35.5224 22.6036 36.0186 22.4961 36.5397 22.4961C36.5479 22.4961 36.5479 22.4961 36.5479 22.4961H36.5562C36.9449 22.4961 37.3254 22.554 37.681 22.6697C37.7472 22.6863 37.8133 22.7111 37.8712 22.7359V10.09C37.8712 7.89831 36.093 6.12012 33.9013 6.12012H10.09C7.89831 6.12012 6.12012 7.89831 6.12012 10.09V33.9096C6.12012 36.1013 7.89831 37.8795 10.09 37.8795H32.9915C32.8344 37.466 32.7517 37.0194 32.7517 36.5562ZM8.76673 10.09C8.76673 9.36222 9.36222 8.76673 10.09 8.76673H33.9096C34.6374 8.76673 35.2329 9.36222 35.2329 10.09V19.469L30.8742 15.1103C30.3532 14.5976 29.5179 14.5976 29.0051 15.1103L19.684 24.4314L16.3179 21.0652C15.7968 20.5524 14.9615 20.5524 14.4487 21.0652L8.76673 26.7472V10.09Z"
      fill={color}
    />
  </svg>
);

export const AddMarketplaceSvg = ({ color = "#3A4D54" }) => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.7676 22.002C12.1289 21.5684 11.3555 21.3164 10.5234 21.3164C9.97266 21.3164 9.44531 21.4277 8.96484 21.6328C8.24414 21.9375 7.63477 22.4473 7.20117 23.0859C6.76758 23.7246 6.51562 24.498 6.51562 25.3301C6.51562 25.8809 6.62695 26.4082 6.83203 26.8887C7.13672 27.6094 7.64648 28.2188 8.28516 28.6523C8.92383 29.0859 9.69726 29.3379 10.5293 29.3379C11.0801 29.3379 11.6074 29.2266 12.0879 29.0215C12.8086 28.7168 13.418 28.207 13.8516 27.5684C14.2852 26.9297 14.5371 26.1563 14.5371 25.3242C14.5371 24.7734 14.4258 24.2461 14.2207 23.7656C13.916 23.0449 13.4121 22.4297 12.7676 22.002ZM9.50977 24.8906C9.5918 24.6914 9.73242 24.5215 9.91406 24.4043C10.0898 24.2871 10.3008 24.2168 10.5352 24.2168C10.6875 24.2168 10.834 24.2461 10.9687 24.3047C11.168 24.3867 11.3379 24.5273 11.4551 24.709C11.5723 24.8848 11.6426 25.0957 11.6426 25.3301C11.6426 25.4824 11.6133 25.6289 11.5547 25.7578C11.4727 25.957 11.332 26.127 11.1504 26.2441C10.9746 26.3613 10.7637 26.4316 10.5293 26.4316C10.377 26.4316 10.2305 26.4023 10.1016 26.3438C9.90234 26.2617 9.73242 26.1211 9.61523 25.9395C9.49805 25.7637 9.42773 25.5527 9.42773 25.3184C9.42187 25.1719 9.45117 25.0254 9.50977 24.8906Z"
      fill={color}
    />
    <path
      d="M24.2402 22.002C23.6016 21.5684 22.8281 21.3164 21.9961 21.3164C21.4453 21.3164 20.918 21.4277 20.4375 21.6328C19.7168 21.9375 19.1074 22.4473 18.6738 23.0859C18.2402 23.7246 17.9883 24.498 17.9883 25.3301C17.9883 25.8809 18.0996 26.4082 18.3047 26.8887C18.6094 27.6094 19.1191 28.2188 19.7578 28.6523C20.3965 29.0859 21.1699 29.3379 22.002 29.3379C22.5527 29.3379 23.0801 29.2266 23.5605 29.0215C24.2813 28.7168 24.8906 28.207 25.3242 27.5684C25.7578 26.9297 26.0098 26.1563 26.0098 25.3242C26.0098 24.7734 25.8984 24.2461 25.6934 23.7656C25.3887 23.0449 24.8789 22.4297 24.2402 22.002ZM20.9766 24.8906C21.0586 24.6914 21.1992 24.5215 21.3809 24.4043C21.5566 24.2871 21.7676 24.2168 22.002 24.2168C22.1543 24.2168 22.3008 24.2461 22.4355 24.3047C22.6348 24.3867 22.8047 24.5273 22.9219 24.709C23.0391 24.8848 23.1094 25.0957 23.1094 25.3301C23.1094 25.4824 23.0801 25.6289 23.0215 25.7578C22.9395 25.957 22.7988 26.127 22.6172 26.2441C22.4414 26.3613 22.2305 26.4316 21.9961 26.4316C21.8438 26.4316 21.6973 26.4023 21.5684 26.3438C21.3691 26.2617 21.1992 26.1211 21.082 25.9395C20.9648 25.7637 20.8945 25.5527 20.8945 25.3184C20.8887 25.1719 20.918 25.0254 20.9766 24.8906Z"
      fill={color}
    />
    <path
      d="M30 8.73633C30 8.41406 29.8945 8.09766 29.6895 7.8457C29.4141 7.49414 28.9922 7.28906 28.5469 7.28906H24.8965C24.5801 5.47266 23.6484 3.86133 22.3242 2.68945C20.9121 1.43555 19.0488 0.667969 17.0156 0.667969C14.9824 0.667969 13.1191 1.42969 11.707 2.68945C10.3828 3.86719 9.45117 5.47266 9.13477 7.28906H6.75586L5.83594 3.04102C5.76562 2.70703 5.57812 2.41992 5.33203 2.2207C5.08008 2.01562 4.76367 1.89844 4.41797 1.89844H1.45312C1.25391 1.89844 1.06055 1.93945 0.890625 2.00977C0.626953 2.12109 0.410156 2.30273 0.251953 2.53711C0.09375 2.76562 0 3.04687 0 3.3457C0 3.54492 0.0410156 3.73828 0.111328 3.9082C0.222656 4.17187 0.404297 4.38867 0.638672 4.54687C0.867188 4.70508 1.14844 4.79297 1.44727 4.79297H3.24609L6.38086 19.3477C6.45117 19.6816 6.63867 19.9688 6.88477 20.168C7.13672 20.373 7.45313 20.4902 7.79883 20.4902H25.9922C26.3262 20.4902 26.6367 20.3789 26.8887 20.1797C27.1348 19.9863 27.3223 19.7109 27.4043 19.3887L29.9648 9.08203C29.9883 8.9707 30 8.85352 30 8.73633ZM24.8555 17.5957H8.9707L7.37695 10.1895H9.1582C9.50391 11.9766 10.4414 13.5469 11.7598 14.7012C13.166 15.9316 15.0059 16.6758 17.0156 16.6758C19.0254 16.6758 20.8652 15.9316 22.2715 14.7012C23.5898 13.5527 24.5273 11.9766 24.873 10.1895H26.6953L24.8555 17.5957ZM14.1621 4.44141C14.9766 3.89062 15.9551 3.56836 17.0098 3.56836C17.7129 3.56836 18.3809 3.70898 18.9902 3.97266C19.9043 4.35937 20.6836 5.00977 21.2344 5.82422C21.7852 6.63867 22.1074 7.61719 22.1074 8.67188C22.1074 9.375 21.9668 10.043 21.709 10.6523C21.3223 11.5664 20.6719 12.3457 19.8574 12.8965C19.043 13.4473 18.0645 13.7695 17.0098 13.7695C16.3066 13.7695 15.6387 13.6289 15.0234 13.3652C14.1094 12.9785 13.3301 12.3281 12.7793 11.5137C12.2285 10.6992 11.9062 9.7207 11.9062 8.66602C11.9062 7.96289 12.0469 7.29492 12.3047 6.68555C12.7031 5.77148 13.3477 4.99219 14.1621 4.44141Z"
      fill={color}
    />
    <path
      d="M14.0508 9.87304C14.2793 10.0312 14.5605 10.1191 14.8594 10.1191H15.5566V10.8164C15.5566 11.0156 15.5977 11.209 15.668 11.3789C15.7793 11.6426 15.9609 11.8594 16.1953 12.0176C16.4238 12.1758 16.7051 12.2637 17.0039 12.2637C17.2031 12.2637 17.3965 12.2227 17.5664 12.1523C17.8301 12.041 18.0469 11.8594 18.2051 11.625C18.3633 11.3965 18.4512 11.1152 18.4512 10.8164V10.1191H19.1191C19.3184 10.1191 19.5117 10.0781 19.6816 10.0078C19.9453 9.89648 20.1621 9.71484 20.3203 9.48047C20.4785 9.25195 20.5664 8.9707 20.5664 8.67187C20.5664 8.47265 20.5254 8.2793 20.4551 8.10937C20.3437 7.8457 20.1621 7.62891 19.9277 7.4707C19.6992 7.3125 19.418 7.22461 19.1191 7.22461H18.4512V6.52734C18.4512 6.32812 18.4102 6.13477 18.3398 5.96484C18.2285 5.70117 18.0469 5.48438 17.8125 5.32617C17.584 5.16797 17.3027 5.08008 17.0039 5.08008C16.8047 5.08008 16.6113 5.12109 16.4414 5.19141C16.1777 5.30273 15.9609 5.48437 15.8027 5.71875C15.6445 5.94727 15.5566 6.22852 15.5566 6.52734V7.22461H14.8594C14.6602 7.22461 14.4668 7.26562 14.2969 7.33594C14.0332 7.44726 13.8164 7.62891 13.6582 7.86328C13.5 8.0918 13.4121 8.37305 13.4121 8.67187C13.4121 8.87109 13.4531 9.06445 13.5234 9.23437C13.6348 9.49219 13.8223 9.71484 14.0508 9.87304Z"
      fill={color}
    />
  </svg>
);

export const MarketplaceSvg = ({ color = "#3A4D54" }) => (
  <svg
    width="30"
    height="29"
    viewBox="0 0 30 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.7676 21.3867C12.1289 20.9531 11.3555 20.7012 10.5234 20.7012C9.97266 20.7012 9.44531 20.8125 8.96484 21.0176C8.24414 21.3223 7.63477 21.832 7.20117 22.4707C6.76758 23.1094 6.51562 23.8828 6.51562 24.7148C6.51562 25.2656 6.62695 25.793 6.83203 26.2734C7.13672 26.9941 7.64648 27.6035 8.28516 28.0371C8.92383 28.4707 9.69727 28.7227 10.5293 28.7227C11.0801 28.7227 11.6074 28.6113 12.0879 28.4063C12.8086 28.1016 13.418 27.5918 13.8516 26.9531C14.2852 26.3145 14.5371 25.541 14.5371 24.709C14.5371 24.1582 14.4258 23.6309 14.2207 23.1504C13.916 22.4297 13.4121 21.8203 12.7676 21.3867ZM9.50977 24.2813C9.5918 24.082 9.73242 23.9121 9.91406 23.7949C10.0898 23.6777 10.3008 23.6074 10.5352 23.6074C10.6875 23.6074 10.834 23.6367 10.9688 23.6953C11.168 23.7773 11.3379 23.918 11.4551 24.0996C11.5723 24.2754 11.6426 24.4863 11.6426 24.7207C11.6426 24.873 11.6133 25.0195 11.5547 25.1484C11.4727 25.3477 11.332 25.5176 11.1504 25.6348C10.9746 25.752 10.7637 25.8223 10.5293 25.8223C10.377 25.8223 10.2305 25.793 10.1016 25.7344C9.90234 25.6523 9.73242 25.5117 9.61524 25.3301C9.49805 25.1543 9.42773 24.9434 9.42773 24.709C9.42188 24.5566 9.45117 24.4102 9.50977 24.2813Z"
      fill={color}
    />
    <path
      d="M24.2402 21.3867C23.6016 20.9531 22.8281 20.7012 21.9961 20.7012C21.4453 20.7012 20.918 20.8125 20.4375 21.0176C19.7168 21.3223 19.1074 21.832 18.6738 22.4707C18.2402 23.1094 17.9883 23.8828 17.9883 24.7148C17.9883 25.2656 18.0996 25.793 18.3047 26.2734C18.6094 26.9941 19.1191 27.6035 19.7578 28.0371C20.3965 28.4707 21.1699 28.7227 22.002 28.7227C22.5527 28.7227 23.0801 28.6113 23.5605 28.4063C24.2813 28.1016 24.8906 27.5918 25.3242 26.9531C25.7578 26.3145 26.0098 25.541 26.0098 24.709C26.0098 24.1582 25.8984 23.6309 25.6934 23.1504C25.3887 22.4297 24.8789 21.8203 24.2402 21.3867ZM20.9766 24.2813C21.0586 24.082 21.1992 23.9121 21.3809 23.7949C21.5566 23.6777 21.7676 23.6074 22.002 23.6074C22.1543 23.6074 22.3008 23.6367 22.4355 23.6953C22.6348 23.7773 22.8047 23.918 22.9219 24.0996C23.0391 24.2754 23.1094 24.4863 23.1094 24.7207C23.1094 24.873 23.0801 25.0195 23.0215 25.1484C22.9395 25.3477 22.7988 25.5176 22.6172 25.6348C22.4414 25.752 22.2305 25.8223 21.9961 25.8223C21.8438 25.8223 21.6973 25.793 21.5684 25.7344C21.3691 25.6523 21.1992 25.5117 21.082 25.3301C20.9648 25.1543 20.8945 24.9434 20.8945 24.709C20.8887 24.5566 20.918 24.4102 20.9766 24.2813Z"
      fill={color}
    />
    <path
      d="M30 8.12109C30 7.79883 29.8945 7.48242 29.6895 7.23047C29.4141 6.87891 28.9922 6.67383 28.5469 6.67383H25.1367H25.1309H6.75L5.83594 2.42578C5.76562 2.0918 5.57812 1.80469 5.33203 1.60547C5.08008 1.40039 4.76367 1.2832 4.41797 1.2832H1.45312C1.25391 1.2832 1.06055 1.32422 0.890625 1.39453C0.626953 1.50586 0.410156 1.6875 0.251953 1.92188C0.09375 2.15039 0 2.43164 0 2.73047C0 2.92969 0.0410156 3.12305 0.111328 3.29297C0.222656 3.55664 0.404297 3.77344 0.638672 3.93164C0.867188 4.08984 1.14844 4.17773 1.44727 4.17773H3.24609L6.38086 18.7324C6.45117 19.0664 6.63867 19.3535 6.88477 19.5527C7.13672 19.7578 7.45313 19.875 7.79883 19.875H25.9922C26.3262 19.875 26.6367 19.7637 26.8887 19.5645C27.1348 19.3711 27.3223 19.0957 27.4043 18.7734L29.9648 8.4668C29.9883 8.35547 30 8.23828 30 8.12109ZM24.8555 16.9805H8.9707L7.37695 9.57422H23.6191H23.6309H26.6895L24.8555 16.9805Z"
      fill={color}
    />
  </svg>
);

export const ProductInfoSvg = ({ color = "#3A4D54" }) => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.5 12.6484C13.8 12.6484 13.2 13.2628 13.2 13.9795C13.2 14.6962 13.8 15.3106 14.5 15.3106H19.5C20.2 15.3106 20.8 14.6962 20.8 13.9795C20.8 13.2628 20.2 12.6484 19.5 12.6484H14.5Z"
      fill={color}
    />
    <path
      d="M24.8 17.2559C20.8 17.2559 17.6 20.5323 17.6 24.6279C17.6 28.7234 20.8 31.9999 24.8 31.9999C28.8 31.9999 32 28.7234 32 24.6279C32 20.5323 28.8 17.2559 24.8 17.2559ZM24.8 29.4402C23.5 29.4402 22.3 28.9282 21.5 28.0067C20.7 27.1876 20.1 25.9589 20.1 24.6279C20.1 23.2968 20.6 22.0681 21.5 21.249C22.3 20.4299 23.5 19.8156 24.8 19.8156C26.1 19.8156 27.3 20.3275 28.1 21.249C28.9 22.0681 29.5 23.2968 29.5 24.6279C29.5 25.9589 29 27.1876 28.1 28.0067C27.2 28.8258 26.1 29.4402 24.8 29.4402Z"
      fill={color}
    />
    <path
      d="M24.8 23.502C25.5 23.502 26.1 22.8876 26.1 22.1709C26.1 21.4542 25.5 20.8398 24.8 20.8398C24.1 20.8398 23.5 21.4542 23.5 22.1709C23.5 22.8876 24.1 23.502 24.8 23.502Z"
      fill={color}
    />
    <path
      d="M24.8 23.9111C24.1 23.9111 23.5 24.5255 23.5 25.2422V27.5971C23.5 28.3139 24.1 28.9282 24.8 28.9282C25.5 28.9282 26.1 28.3139 26.1 27.5971V25.1398C26.1 24.4231 25.5 23.9111 24.8 23.9111Z"
      fill={color}
    />
    <path
      d="M8 28.2116C7.7 28.2116 7.4 28.2116 7.2 28.1092C7 28.0068 6.9 28.0068 6.7 27.9044C6.5 27.802 6.3 27.5973 6.1 27.3925C5.9 27.0853 5.7 26.6758 5.6 26.3686C5.6 26.2662 5.5 26.0614 5.5 26.0614V11.4198V10.6007H28.1V14.901C29 15.2082 29.9 15.7201 30.6 16.2321V14.7986V10.4983C30.8 10.4983 30.9 10.3959 31 10.2935C31.2 10.0887 31.4 9.98635 31.6 9.78157C31.7 9.67918 31.8 9.4744 31.8 9.37201C31.9 9.16724 32 8.96246 32 8.86007V8.65529C32 8.65529 32 7.6314 32 5.58362C32 5.17406 32 4.86689 31.9 4.45734C31.8 4.15017 31.7 3.94539 31.5 3.63823C31.2 3.22867 30.9 2.9215 30.6 2.71672C30.4 2.51195 30.1 2.40956 29.9 2.30717C29.6 2.20478 29.3 2.10239 29 2C28.8 2 28.7 2 28.6 2H28.5H21.8H12.1H5.4C5.4 2 5.2 2 4.9 2.10239C4.6 2.20478 4.4 2.20478 4.1 2.40956C3.9 2.51195 3.6 2.61433 3.4 2.81911C3.2 2.9215 3 3.02389 2.9 3.22867C2.7 3.53584 2.4 3.843 2.3 4.25256C2.1 4.66212 2 5.07167 2 5.58362V8.65529C2 8.75768 2 8.86007 2 8.96246C2 9.06485 2.1 9.16724 2.1 9.37201C2.1 9.4744 2.2 9.67918 2.3 9.78157L2.5 10.0887C2.7 10.1911 2.9 10.2935 3 10.3959V11.4198V26.2662V26.3686C3 26.3686 3 26.6758 3.2 27.0853C3.3 27.3925 3.4 27.802 3.6 28.1092C3.8 28.4164 3.9 28.7236 4.2 29.0307C4.6 29.5427 5.1 29.9522 5.7 30.2594C6.3 30.5666 7.1 30.7713 8 30.7713H16.7C16.1 29.9522 15.7 29.1331 15.3 28.2116H8ZM4.5 7.93857C4.5 7.42662 4.5 6.7099 4.5 5.58362C4.5 5.48123 4.5 5.37884 4.5 5.27645L4.7 5.07167L4.9 4.86689L5.2 4.76451C5.3 4.66212 5.5 4.66212 5.6 4.66212H5.7H12.3H22H28.6H28.8L29.1 4.76451L29.3 4.86689L29.4 4.96928L29.5 5.17406C29.5 5.27645 29.6 5.37884 29.6 5.48123C29.6 6.60751 29.6 7.42662 29.6 7.83618C27.4 7.93857 6.6 7.93857 4.5 7.93857Z"
      fill={color}
    />
  </svg>
);

export const PageSetupSvg = ({ color = "#3A4D54" }) => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.61264 2.77899C6.37842 2.01321 7.41703 1.58301 8.5 1.58301H19.8333C20.1649 1.58301 20.4828 1.7147 20.7172 1.94912L29.2172 10.4491C29.4516 10.6835 29.5833 11.0015 29.5833 11.333V28.333C29.5833 29.416 29.1531 30.4546 28.3873 31.2204C27.6216 31.9861 26.583 32.4163 25.5 32.4163H8.5C7.41703 32.4163 6.37842 31.9861 5.61264 31.2204C4.84687 30.4546 4.41666 29.416 4.41666 28.333V5.66634C4.41666 4.58337 4.84687 3.54476 5.61264 2.77899ZM8.5 4.08301C8.08007 4.08301 7.67734 4.24982 7.38041 4.54676C7.08348 4.84369 6.91666 5.24641 6.91666 5.66634V28.333C6.91666 28.7529 7.08348 29.1557 7.38041 29.4526C7.67734 29.7495 8.08007 29.9163 8.5 29.9163H25.5C25.9199 29.9163 26.3226 29.7495 26.6196 29.4526C26.9165 29.1557 27.0833 28.7529 27.0833 28.333V11.8508L19.3156 4.08301H8.5Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0833 24.083C10.0833 23.3927 10.643 22.833 11.3333 22.833H22.6667C23.357 22.833 23.9167 23.3927 23.9167 24.083C23.9167 24.7734 23.357 25.333 22.6667 25.333H11.3333C10.643 25.333 10.0833 24.7734 10.0833 24.083Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0833 18.417C10.0833 17.7266 10.643 17.167 11.3333 17.167H22.6667C23.357 17.167 23.9167 17.7266 23.9167 18.417C23.9167 19.1073 23.357 19.667 22.6667 19.667H11.3333C10.643 19.667 10.0833 19.1073 10.0833 18.417Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0833 12.75C10.0833 12.0596 10.643 11.5 11.3333 11.5H14.1667C14.857 11.5 15.4167 12.0596 15.4167 12.75C15.4167 13.4404 14.857 14 14.1667 14H11.3333C10.643 14 10.0833 13.4404 10.0833 12.75Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.8333 1.58301C20.5237 1.58301 21.0833 2.14265 21.0833 2.83301V10.083H28.3333C29.0237 10.083 29.5833 10.6427 29.5833 11.333C29.5833 12.0234 29.0237 12.583 28.3333 12.583H19.8333C19.143 12.583 18.5833 12.0234 18.5833 11.333V2.83301C18.5833 2.14265 19.143 1.58301 19.8333 1.58301Z"
      fill={color}
    />
  </svg>
);

export const EditPageSvg = ({ color = "#3A4D54" }) => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.9031 6.41484L8.42031 15.8777C8.26758 16.0305 8.14805 16.223 8.08828 16.4289L6.50117 21.6551C6.36172 22.1199 6.48125 22.6246 6.81992 22.9699C7.15859 23.3152 7.66328 23.448 8.12813 23.3219L13.4871 21.8344C13.7063 21.7746 13.9121 21.6551 14.0715 21.4957L23.541 12.0395C25.0883 10.5586 25.0883 7.88906 23.541 6.40156C21.9805 4.86094 19.457 4.86094 17.9031 6.41484ZM12.4379 19.3773L9.7418 20.1211L10.532 17.5246L17.6441 10.4258L19.5234 12.3051L12.4379 19.3773ZM21.6617 10.1668L21.4027 10.4258L19.5234 8.54648L19.7824 8.2875C20.3004 7.76953 21.1438 7.76953 21.6617 8.2875C22.173 8.81211 22.173 9.64883 21.6617 10.1668ZM31.3438 20.7188V5.3125C31.3438 3.84492 30.1551 2.65625 28.6875 2.65625H5.3125C3.84492 2.65625 2.65625 3.84492 2.65625 5.3125V28.6875C2.65625 30.1551 3.84492 31.3438 5.3125 31.3438H28.6875C30.1551 31.3438 31.3438 30.1551 31.3438 28.6875H7.76953C6.00977 28.6145 6.00977 26.0977 7.76953 26.0312H32.6719C33.4023 26.0312 34 26.6289 34 27.3594V28.6875C34 31.616 31.616 34 28.6875 34H5.3125C2.38398 34 0 31.616 0 28.6875V5.3125C0 2.38398 2.38398 0 5.3125 0H28.6875C31.616 0 34 2.38398 34 5.3125V20.7188C33.927 22.4785 31.4102 22.4785 31.3438 20.7188Z"
      fill={color}
    />
  </svg>
);

export const PlayIconSvg = ({ color = "#3A4D54" }) => (
  <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.1665 1.5L12.8332 9L1.1665 16.5L1.1665 1.5Z" stroke={color} strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export const UploadIconSvg = ({ color = "#3A4D54" }) => (
  <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3 23C3 11.9543 11.9543 3 23 3C34.0457 3 43 11.9543 43 23C43 34.0457 34.0457 43 23 43C11.9543 43 3 34.0457 3 23Z" fill="#F2F4F7" />
    <g clip-path="url(#clip0_10542_10967)">
      <path d="M26.3335 26.3352L23.0002 23.0019M23.0002 23.0019L19.6669 26.3352M23.0002 23.0019V30.5019M29.9919 28.3269C30.8047 27.8838 31.4467 27.1826 31.8168 26.334C32.1868 25.4855 32.2637 24.5379 32.0354 23.6408C31.807 22.7436 31.2865 21.9481 30.5558 21.3797C29.8251 20.8113 28.9259 20.5025 28.0002 20.5019H26.9502C26.698 19.5262 26.2278 18.6205 25.5752 17.8527C24.9225 17.0849 24.1043 16.4751 23.182 16.069C22.2597 15.663 21.2573 15.4713 20.2503 15.5084C19.2433 15.5455 18.2578 15.8104 17.3679 16.2832C16.478 16.7561 15.7068 17.4244 15.1124 18.2382C14.518 19.0519 14.1158 19.9898 13.936 20.9814C13.7563 21.9729 13.8036 22.9923 14.0746 23.9629C14.3455 24.9335 14.8329 25.8301 15.5002 26.5852" stroke={color} strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <path d="M23 40C13.6112 40 6 32.3888 6 23H0C0 35.7025 10.2975 46 23 46V40ZM40 23C40 32.3888 32.3888 40 23 40V46C35.7025 46 46 35.7025 46 23H40ZM23 6C32.3888 6 40 13.6112 40 23H46C46 10.2975 35.7025 0 23 0V6ZM23 0C10.2975 0 0 10.2975 0 23H6C6 13.6112 13.6112 6 23 6V0Z" fill="#F9FAFB" />
  </svg>
)

export const LinkIconSvg = ({ color = "#3A4D54" }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.3335 10.834C8.69138 11.3124 9.14796 11.7083 9.67229 11.9947C10.1966 12.2812 10.7764 12.4516 11.3724 12.4942C11.9683 12.5369 12.5665 12.4509 13.1263 12.2421C13.6861 12.0333 14.1944 11.7065 14.6168 11.284L17.1168 8.78396C17.8758 7.99811 18.2958 6.9456 18.2863 5.85312C18.2768 4.76063 17.8386 3.71558 17.0661 2.94304C16.2935 2.17051 15.2485 1.73231 14.156 1.72281C13.0635 1.71332 12.011 2.1333 11.2252 2.89229L9.79183 4.31729M11.6668 9.16729C11.309 8.68885 10.8524 8.29297 10.328 8.00651C9.80371 7.72004 9.22391 7.54969 8.62796 7.50701C8.032 7.46433 7.43384 7.55032 6.87405 7.75914C6.31425 7.96796 5.8059 8.29473 5.3835 8.71729L2.8835 11.2173C2.12451 12.0031 1.70453 13.0556 1.71402 14.1481C1.72352 15.2406 2.16172 16.2857 2.93426 17.0582C3.70679 17.8307 4.75184 18.2689 5.84433 18.2784C6.93681 18.2879 7.98932 17.8679 8.77517 17.109L10.2002 15.684" stroke={color} strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export const DustBinIconSvg = ({ color = "#3A4D54" }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.5 5.0013H4.16667M4.16667 5.0013H17.5M4.16667 5.0013V16.668C4.16667 17.11 4.34226 17.5339 4.65482 17.8465C4.96738 18.159 5.39131 18.3346 5.83333 18.3346H14.1667C14.6087 18.3346 15.0326 18.159 15.3452 17.8465C15.6577 17.5339 15.8333 17.11 15.8333 16.668V5.0013H4.16667ZM6.66667 5.0013V3.33464C6.66667 2.89261 6.84226 2.46868 7.15482 2.15612C7.46738 1.84356 7.89131 1.66797 8.33333 1.66797H11.6667C12.1087 1.66797 12.5326 1.84356 12.8452 2.15612C13.1577 2.46868 13.3333 2.89261 13.3333 3.33464V5.0013M8.33333 9.16797V14.168M11.6667 9.16797V14.168" stroke={color} strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export const Star = ({ color = "#632FF9" }) => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill={color} xmlns="http://www.w3.org/2000/svg">
    <rect width="30" height="30" rx="15" fill="#632FF9" />
    <path d="M24.1667 12.8637C24.1088 12.696 24.0035 12.5487 23.8636 12.4397C23.7237 12.3307 23.5551 12.2646 23.3784 12.2495L18.1626 11.4887L15.8251 6.74952C15.75 6.59454 15.6328 6.46384 15.4869 6.37238C15.341 6.28093 15.1723 6.23242 15.0001 6.23242C14.8279 6.23242 14.6591 6.28093 14.5132 6.37238C14.3673 6.46384 14.2501 6.59454 14.1751 6.74952L11.8376 11.4795L6.62173 12.2495C6.45207 12.2736 6.29257 12.3448 6.16133 12.455C6.03009 12.5652 5.93236 12.7099 5.87923 12.8729C5.83059 13.0321 5.82623 13.2015 5.8666 13.363C5.90698 13.5245 5.99056 13.6719 6.10839 13.7895L9.89423 17.4562L8.97756 22.6629C8.94484 22.8347 8.96197 23.0123 9.02694 23.1748C9.09191 23.3372 9.20201 23.4776 9.34423 23.5795C9.48284 23.6786 9.64634 23.7371 9.81635 23.7485C9.98637 23.7598 10.1562 23.7235 10.3067 23.6437L15.0001 21.1962L19.6751 23.6529C19.8037 23.7254 19.949 23.7633 20.0967 23.7629C20.2909 23.7635 20.4803 23.7026 20.6376 23.5887C20.7798 23.4868 20.8899 23.3464 20.9548 23.1839C21.0198 23.0215 21.037 22.8439 21.0042 22.672L20.0876 17.4654L23.8734 13.7987C24.0057 13.6866 24.1036 13.5392 24.1555 13.3737C24.2074 13.2082 24.2113 13.0313 24.1667 12.8637ZM18.5292 16.5304C18.4217 16.6343 18.3413 16.763 18.295 16.9052C18.2486 17.0475 18.2378 17.1988 18.2634 17.3462L18.9234 21.187L15.4767 19.3537C15.3441 19.2831 15.1961 19.2461 15.0459 19.2461C14.8956 19.2461 14.7477 19.2831 14.6151 19.3537L11.1684 21.187L11.8284 17.3462C11.854 17.1988 11.8432 17.0475 11.7968 16.9052C11.7505 16.763 11.6701 16.6343 11.5626 16.5304L8.81256 13.7804L12.6717 13.2212C12.8202 13.2005 12.9614 13.1438 13.0829 13.0559C13.2043 12.968 13.3024 12.8516 13.3684 12.717L15.0001 9.22452L16.7234 12.7262C16.7894 12.8608 16.8875 12.9771 17.0089 13.065C17.1304 13.1529 17.2716 13.2097 17.4201 13.2304L21.2792 13.7895L18.5292 16.5304Z" 
    fill="white" />
    <path d="M21.4167 8.58366H20.5C20.2569 8.58366 20.0238 8.48708 19.8519 8.31517C19.68 8.14327 19.5834 7.91011 19.5834 7.66699C19.5834 7.42388 19.68 7.19072 19.8519 7.01881C20.0238 6.8469 20.2569 6.75033 20.5 6.75033H21.4167V5.83366C21.4167 5.59054 21.5133 5.35739 21.6852 5.18548C21.8571 5.01357 22.0903 4.91699 22.3334 4.91699C22.5765 4.91699 22.8096 5.01357 22.9816 5.18548C23.1535 5.35739 23.25 5.59054 23.25 5.83366V6.75033H24.1667C24.4098 6.75033 24.643 6.8469 24.8149 7.01881C24.9868 7.19072 25.0834 7.42388 25.0834 7.66699C25.0834 7.91011 24.9868 8.14327 24.8149 8.31517C24.643 8.48708 24.4098 8.58366 24.1667 8.58366H23.25V9.50033C23.25 9.74344 23.1535 9.9766 22.9816 10.1485C22.8096 10.3204 22.5765 10.417 22.3334 10.417C22.0903 10.417 21.8571 10.3204 21.6852 10.1485C21.5133 9.9766 21.4167 9.74344 21.4167 9.50033V8.58366Z"
    fill="white" />
  </svg>

)