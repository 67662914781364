import React from "react";
import ButtonBackComponent from "./buttonBack.component";
import { useHistory } from "react-router-dom";

export default function ButtonBackContainer({ to, text = "Back" }) {
  const history = useHistory();
  const handleClick = () => {
    if (to) {
      history.push(to);
      return;
    } else history.goBack();
  };

  return <ButtonBackComponent handleClick={handleClick} text={text} />;
}
