import axios from "axios";

export default function updateCreditAlertInfo(parameters) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/campaignV2/customer/company/updateAlert`, {
        has_alert: parameters.has_alert,
        alert_email: parameters.alert_email,
        alert_threshold: parameters.alert_threshold,
        transaction_threshold: parameters.transaction_threshold,
      })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data));
  });
}
