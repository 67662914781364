import React from "react";

export default function LoyaltyPointIcon(props) {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="9" cy="9" r="8" fill="url(#paint0_linear_15204_37388)" stroke="#FED500" stroke-width="2"/>
            <path d="M8.99967 4.3335L10.0474 7.55808H13.4379L10.6949 9.55099L11.7427 12.7756L8.99967 10.7827L6.25668 12.7756L7.30441 9.55099L4.56141 7.55808H7.95194L8.99967 4.3335Z" fill="#FF7A00"/>
            <defs>
                <linearGradient id="paint0_linear_15204_37388" x1="9" y1="1" x2="9" y2="17" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FFF0BB"/>
                    <stop offset="1" stop-color="#FFD954"/>
                </linearGradient>
            </defs>
        </svg>
    )
}