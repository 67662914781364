import axios from "axios";

export function getReceiveTransferItemProductSNList(parameters) {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `/distributor/admin/api/v1/receive-transfer/${parameters.id}/product/${parameters.product_uuid}/serialnumbers`,
        {
          length: parameters.length,
          start: parameters.start,
          order: parameters.order,
          order_by: parameters.order_by,
          status: parameters.status,
          box_uuid: parameters.box_uuid,
        }
      )
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data));
  });
}
