import React from "react";
import {
  makeStyles,
  Box,
  Typography,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  IconButton,
  Button
} from "@material-ui/core";
import { SetWinnerSelect } from "../../select";
import { ErrorSVG, DustBinSVG } from "../../../utils/static";
import clsx from "clsx";
import { getLang } from "app/feature/constants";

const style = (theme) => ({
  prizeDialog: {
    padding: theme.spacing(0, 3, 3, 3)
  },
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    borderCollapse: "separate",
    borderTop: "1px solid rgba(58, 77, 84, 0.1)",
    borderBottom: "none",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0
    },
    "& th:last-child, td:last-child": {
      borderRight: "none"
    },
    "& th,td": {
      fontSize: ".75rem",
      padding: "10px 16px 10px 16px",
      backgroundColor: theme.palette.common.white,
      borderRight: "solid 1px rgba(0, 0, 0, 0.1)"
    },
  },
  prizeTable: {
    border: "1px solid rgba(58, 77, 84, 0.1)"
  },
  emptyContainer: {
    backgroundColor: theme.palette.background.paper,
    alignItems: "center",
    display: "flex",
    placeContent: "center",
    border: "1px solid rgba(0, 0, 0, 0.1)",
    borderRadius: "4px"
  }
});

const useStyles = makeStyles(style);

export default function SetWinnerTableComponent({
  hasInstantLuckyDrawEditAccessRight,
  dialog,
  quantity,
  winners,
  handleChange,
  balance,
  handleDelete,
  handleAddWinner,
  handleAddWinnerIntoPrize,
  lang
}) {
  const classes = useStyles();
  const winnerDialog = dialog === "winner" ? true : false;
  let count = 1;
  let availableBalance = winners.filter(winner => winner.status === null).length

  const fakeArray = [];
  for (let index = 0; index < quantity; index++) {
    fakeArray.push(index);
  }
  return (
    <Box
      className={clsx({
        [classes.prizeDialog]: !winnerDialog
      })}
    >
      {fakeArray.length > 0 ? (
        <React.Fragment>
          {winnerDialog && (
            <Typography
              variant="body2"
              color="primary"
              style={{ padding: "8px 16px" }}
            >
              {getLang(lang,"label.PRESET_WINNER_LIST")}
            </Typography>
          )}
          <Box>
            <TableContainer>
              <Table
                className={clsx(classes.table, {
                  [classes.prizeTable]: !winnerDialog,
                })}
                size="small"
                aria-label="product table"
                stickyHeader
              >
                <TableHead>
                  <TableRow>
                    <TableCell>{getLang(lang,"label.NUM")}</TableCell>
                    <TableCell>{getLang(lang,"label.SERIAL_NUMBER")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {winnerDialog
                    ?
                    fakeArray.map((row, index) => {
                      return (
                      winners[index].prizeType === "preset" &&
                      (
                        <TableRow
                          key={index}
                          hover
                          role="checkbox"
                          tabIndex={-1}
                        >
                          <TableCell component="th" scope="row">
                            {winners[index].prizeType === "preset" && count++}
                          </TableCell>
                          <TableCell>
                            {
                              hasInstantLuckyDrawEditAccessRight
                                ?
                                <div style={{ display: "flex" }}>
                                  <SetWinnerSelect
                                    currentWinnerList={[]}
                                    value={winners[index]}
                                    handleChange={(value, enc) =>
                                      handleAddWinnerIntoPrize(index, value, enc)
                                    }
                                  />
                                {!winners[index].status &&
                                  <IconButton
                                    style={{ padding: 4, marginLeft: 8 }}
                                    onClick={() => handleDelete(index)}
                                  >
                                    <DustBinSVG />
                                  </IconButton>
                                }
                                </div>
                                : winners[index]
                                  ? winners[index].serialNumber
                                  : ""

                            }
                          </TableCell>
                        </TableRow>
                      )
                      );
                    })
                    : fakeArray.map((row, index) => {
                      return (
                        <TableRow
                          key={index}
                          hover
                          role="checkbox"
                          tabIndex={-1}
                        >
                          <TableCell component="th" scope="row">
                            {index + 1}
                          </TableCell>
                          <TableCell>
                            <SetWinnerSelect
                              currentWinnerList={winners}
                              value={winners[index]}
                              handleChange={(value, enc) =>
                                handleChange(index, value, enc)
                              }
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </React.Fragment>
      ) : !winnerDialog ? (
        <Box>
          <Table
                className={clsx(classes.table, {
                  [classes.prizeTable]: !winnerDialog,
                })}
                size="small"
                aria-label="product table"
                stickyHeader
              >
                <TableHead>
                  <TableRow>
                    <TableCell>{getLang(lang,"label.NUM")}</TableCell>
                    <TableCell>{getLang(lang,"label.SERIAL_NUMBER")}</TableCell>
                  </TableRow>
                </TableHead>
          </Table>
                  <Box py={6} className={classes.emptyContainer}>
                    <ErrorSVG />
                    <Typography variant="body2" style={{ paddingLeft: "4px" }}>
                      {getLang(lang,"paragraph.NO_QUANTITY_ADDED")}
                    </Typography>
                  </Box>
          </Box>
       
      ) : fakeArray.length === 0 ? (
        <Box>
          <Table
                className={clsx(classes.table, {
                  [classes.prizeTable]: !winnerDialog,
                })}
                size="small"
                aria-label="product table"
                stickyHeader
              >
                <TableHead>
                  <TableRow>
                    <TableCell>{getLang(lang,"label.NUM")}</TableCell>
                    <TableCell>{getLang(lang,"label.SERIAL_NUMBER")}</TableCell>
                  </TableRow>
                </TableHead>
          </Table>
                  <Box py={6} className={classes.emptyContainer}>
                    <ErrorSVG />
                    <Typography variant="body2" style={{ paddingLeft: "4px" }}>
                      {getLang(lang,"paragraph.NO_PRESET_WINNER_ADDED")}
                    </Typography>
                  </Box>
          </Box>
      
      ) : null}
      {winnerDialog && hasInstantLuckyDrawEditAccessRight ? (
        <Button
          variant="contained"
          color="primary"
          disabled={availableBalance >= balance}
          onClick={handleAddWinner}
          style={{ margin: "8px 16px" }}
        >
          {getLang(lang,"label.ADD_WINNER")}
        </Button>
      ) : null}
    </Box>
  );
}
