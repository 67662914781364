import React from "react";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Formik } from "formik";
import { getLang } from "app/feature/constants";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";

const useStyle = makeStyles((theme) => ({
  root: {
    margin: 10,
    width: 500,
    maxWidth: "90vw",
    borderRadius: 12,
    boxShadow:
      "0px 20px 24px -4px rgba(16, 24, 40, 0.1), 0px 8px 8px -4px rgba(16, 24, 40, 0.04)",
  },
  field: {
    display: "grid",
    gridTemplateColumns: "2.5fr 7.5fr",
    columnGap: theme.spacing(2),
  },
  btnSecondary: {
    color: theme.palette.common.white,
  },
}));

export default function GiftSettingDialogComponent({
  isOpen,
  handleClose,
  updateSetting,
  giftOrderSetting,
  lang,
  isSubmitting,
}) {
  const classes = useStyle();
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      disableEnforceFocus={true}
      disableAutoFocus={true}
      disableEscapeKeyDown={true}
      disableBackdropClick={true}
      closeAfterTransition
      BackdropComponent={Backdrop}
      maxWidth="sm"
      classes={{
        paper: classes.root,
      }}
    >
      <Formik
        initialValues={{
          order: giftOrderSetting ? giftOrderSetting?.order : "",
          column: giftOrderSetting ? giftOrderSetting?.column : "",
        }}
        enableReinitialize
        onSubmit={updateSetting}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <Box
              py={1}
              px={3}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                variant="body1"
                color="primary"
                style={{ fontWeight: "bold" }}
              >
                {getLang(lang, "label.SETTING")}
              </Typography>
              <IconButton
                onClick={handleClose}
                edge="end"
                size="small"
                aria-label="close dialog"
              >
                <CloseRoundedIcon color="primary" />
              </IconButton>
            </Box>
            <Divider />
            <Box p={2}>
              <Box>
                <Box mb={1} display="flex">
                  <Typography>
                    <b>{getLang(lang, "label.GIFT_DISPLAY_ORDER")}</b>
                  </Typography>
                </Box>
                <Box>
                  <Box className={classes.field}>
                    <Box pt={1}>
                      <Typography variant="body2">
                        {getLang(lang, "label.ORDER_BY")}
                      </Typography>
                    </Box>
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={formik.values.column}
                        onChange={({ target: { value } }) => {
                          formik.setFieldValue("column", value);
                        }}
                      >
                        <FormControlLabel
                          value={"name"}
                          control={<Radio />}
                          label={
                            <Typography
                              variant="body2"
                              style={{
                                marginRight: 4,
                                textTransform: "capitalize",
                              }}
                            >
                              {getLang(lang, "label.GIFT_NAME")}
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          value={"point"}
                          control={<Radio />}
                          label={
                            <Typography
                              variant="body2"
                              style={{
                                marginRight: 4,
                                textTransform: "capitalize",
                              }}
                            >
                              {getLang(lang, "label.POINT")}
                            </Typography>
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                  <Box className={classes.field}>
                    <Box pt={1}>
                      <Typography variant="body2">
                        {getLang(lang, "label.ORDER")}
                      </Typography>
                    </Box>
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={formik.values.order}
                        onChange={({ target: { value } }) => {
                          formik.setFieldValue("order", value);
                        }}
                      >
                        <FormControlLabel
                          value={"asc"}
                          control={<Radio />}
                          label={
                            <Typography
                              variant="body2"
                              style={{
                                marginRight: 4,
                                textTransform: "capitalize",
                              }}
                            >
                              {getLang(lang, "label.ASCENDING")}
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          value={"desc"}
                          control={<Radio />}
                          label={
                            <Typography
                              variant="body2"
                              style={{
                                marginRight: 4,
                                textTransform: "capitalize",
                              }}
                            >
                              {getLang(lang, "label.DESCENDING")}
                            </Typography>
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Divider />
            <Box
              py={1}
              px={3}
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
            >
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                disableElevation
                disabled={isSubmitting}
                classes={{ root: classes.btnSecondary }}
              >
                {isSubmitting && (
                  <CircularProgress
                    size={18}
                    style={{ color: "white", marginRight: 8 }}
                  />
                )}
                {getLang(lang, "label.SAVE")}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Dialog>
  );
}
