import React, { Fragment, useRef } from "react";
import {
  makeStyles,
  Typography,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  Box,
  Divider,
  MenuItem,
  MenuList,
  IconButton,
  InputAdornment,
  Checkbox,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { getLang } from "app/feature/constants";
import InputTextField from "components/input/inputTextField";
import { TIER_ICON_PROPS } from "modules/stockflow/constants";
import {
  Search as SearchIcon,
} from "@material-ui/icons"

const useStyle = makeStyles((theme) => ({
  paper: {
    width: 425
  },
  button: {
    padding: 0,
    minWidth: 0
  },
  buttonAddBranch: {
    display: "flex",
    padding: 0,
    minWidth: 0
  },
  createLink: {
    padding: theme.spacing(2)
  },
   uplineProfileTierCombo: {
    display: "flex",
    flexDirection: "row",
    marginRight: "0.5em"
  },
  tierIconUpline: {
    width: "1em",
    height: "1em",
    borderRadius: "3px",
    display: "flex",
    position: "absolute",
    alignItems: "center",
    justifyContent: "center"
  },
  tierLabelUpline: {
    color: "#FFFFFF",
    fontSize: "0.625rem"
  },
  profilePhotoUpline: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #E4E7EC",
    borderRadius: "50%",
    width: "33px",
    height: "33px",
    marginRight: "0.25em",
    marginLeft: "0.25em",
    marginTop: "0.25em",
    "& img": {
      borderRadius: "50%",
      height: "33px",
      width: "33px"
    },
  },
}));

export default function DealerPanelComponent({
  open,
  anchorRef,
  handleClose,
  isOpenList,
  dealerDropdown,
  handleDealerSearch,
  handleSelectAll,
  isFetching,
  selectAll,
  value,
  handleSelectDealer,
  lang
}) {
  const classes = useStyle();
  const textRef = useRef();

  return (
    <Popper
      open={open}
      style={{ zIndex: 99 }}
      anchorEl={anchorRef.current}
      role={undefined}
      transition
      disablePortal
      placement="bottom-start"
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === "bottom-start" ? "center top" : "center bottom"
          }}
        >
          <Paper className={classes.paper}>
            <ClickAwayListener onClickAway={handleClose}>
              <Box>
                <Fragment>
                  <InputTextField
                    variant="filled"
                    size="small"
                    fullWidth
                    inputRef={textRef}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end' style={{ paddingRight: 6 }}>
                          <IconButton>
                            <SearchIcon onClick={() => handleDealerSearch(textRef.current.value)}/>
                          </IconButton>
                        </InputAdornment>
                      ),
                      style: {
                        backgroundColor: "#fff",
                        paddingRight: 0
                      }
                    }}
                    inputProps={{
                      onChange: (e) => {
                        handleDealerSearch(textRef.current.value);
                      },
                      style: {
                        padding: "8px"
                      }
                    }}
                    placeholder={getLang(lang, "placeholder.SEARCH")}
                  />
              </Fragment>
                 <Divider /> 
                 <MenuList
                      autoFocusItem={isOpenList}
                      id="menu-list-grow"
                      style={ {padding: "0px"} }
                    >
                      <MenuItem onClick={() => { handleSelectAll() }}>
                        <Checkbox checked={selectAll} />
                        <Typography
                          variant="inherit"
                          noWrap
                          style={{ paddingLeft: "8px" }}
                        >
                          {getLang(lang, "label.ALL")}
                        </Typography>
                      </MenuItem>
                    </MenuList>
                    <Divider />
                {isFetching ? (
                  <Box p={1}>
                    <Skeleton variant="text" animation="wave" />
                    <Skeleton variant="text" animation="wave" />
                    <Skeleton variant="text" animation="wave" />
                    <Skeleton variant="text" animation="wave" />
                  </Box>
                ) : (
                  <div>
                    <Box height="25vh" overflow="auto">
                      {dealerDropdown.map((field, index) => {
                        return (
                          <React.Fragment key={index}>
                            <MenuItem
                              onClick={() => handleSelectDealer(field)}
                            >
                              <Checkbox
                                checked={
                                  value.find(
                                    (v) => v.uuid === field.uuid
                                  )
                                    ? true
                                    : false
                                }
                              />
                              <Box className={classes.uplineProfileTierCombo}>
                                <Box
                                  className={classes.tierIconUpline}
                                  style={{
                                    backgroundColor: TIER_ICON_PROPS.find(({ tier }) => tier === field.tier).color
                                  }}
                                >
                                  <Typography className={classes.tierLabelUpline}>
                                    T{field.tier}
                                  </Typography>
                                </Box>
                                <Box className={classes.profilePhotoUpline}>
                                  <img src={field.picture} alt={field.name} />
                                </Box>
                              </Box>
                              <Typography
                                variant="inherit"
                                noWrap
                                style={{ paddingLeft: "8px" }}
                              >
                                {field.name}
                              </Typography>
                            </MenuItem>
                          </React.Fragment>
                        );
                      })}
                    </Box>
                  </div>
                )}
              </Box>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}
