import React from "react";
import InfoRangeListTableComponent from "./infoRangeListTable.component";
import { useSelector } from "react-redux";
import { useState } from "react";
import GeneralDialog from "components/dialog/generalDialog";
import { getLang } from "app/feature/constants";
import DeleteSvgIcon from "assets/svg/delete.svg";

function InfoRangeListTableContainer({
  handleUpdate,
  format,
  formik,
  rows,
  type,
  updateRow,
  invalidRangeError,
  onBlur,
  onCustomRangeChange,
  rowIndex,
  disabled,
  currentLang,
}) {
  const lang = useSelector((state) => state.constant.languages);
  const [selectedDeleteIndex, setSelectedDeleteIndex] = useState(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const deleteRange = (index) => {
    setSelectedDeleteIndex(index);
    setShowDeleteConfirm(true);
  };

  return (
    <>
      <InfoRangeListTableComponent
        onCustomRangeChange={onCustomRangeChange}
        handleUpdate={handleUpdate}
        lang={lang}
        format={format}
        formik={formik}
        rows={rows}
        type={type}
        updateRow={updateRow}
        deleteRange={deleteRange}
        onBlur={onBlur}
        invalidRangeError={invalidRangeError}
        rowIndex={rowIndex}
        disabled={disabled}
        currentLang={currentLang}
      />
      <GeneralDialog
        isOpen={!!showDeleteConfirm}
        handleClose={() => {
          setShowDeleteConfirm(false);
        }}
        handleProceed={() => {
          updateRow("remove", selectedDeleteIndex);
          setShowDeleteConfirm(false);
        }}
        title={getLang(lang, "label.REMOVE_RANGE")}
        description={
          <>
            <span>
              {getLang(lang, "paragraph.ARE_YOU_SURE_WANT_REMOVE_RANGE")}
            </span>
            <br />
            <span>{getLang(lang, "paragraph.REMOVE_RANGE_WARNING")}</span>
          </>
        }
        type="danger"
        proceedLabel={getLang(lang, "label.REMOVE")}
        icon={<img src={DeleteSvgIcon} alt="" />}
      />
    </>
  );
}

export default InfoRangeListTableContainer;
