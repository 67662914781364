import axios from "axios";
const url = "/account/api/v1/nodes/me-bottom-dropdown";

export function getFilterBranchesData() {
  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        if (error?.response?.data) {
          reject(error.response.data);
        } else {
          reject(error);
        }
      });
  });
}

export default getFilterBranchesData;
