import { createAsyncThunk } from "@reduxjs/toolkit";
import { getUploadS3FilePath } from "lib/helper";
import { uploadFile, uploadJSON } from "./s3.utility";
import { dataURLtoFile } from "lib/generalUtility";

/**
 * Upload image to s3 bucket action creator.
 * @param {Object} payload - Redux action payload, plain javascript object.
 * @param {string} payload.fileName - The name of the file.
 * @param {string} payload.base64Image - The image file in base64 format.
 * @param {string} payload.folderId - The folder identifier where image will be put.
 * @param {number} payload.type - The type of upload.
 */

export const uploadFileToS3 = createAsyncThunk(
  "s3/uploadFile",
  async (payload, thunkApi) => {
    const { uploadType, file, id, fileName } = payload;

    const filePath = await getUploadS3FilePath({
      type: uploadType,
      id: id,
      fileName: fileName
    });

    const result = await uploadFile({ filePath, file });

    if (!result.success) {
      return thunkApi.rejectWithValue({ success: false });
    }

    return result.url;
  }
);

export const uploadMultipleFileToS3 = createAsyncThunk(
  "s3/uploadMultipleImage",
  async (payload, thunkApi) => {
    const arrayOfFileObj = payload;
    let arrayOfFileLink = [];

    if (arrayOfFileObj) {
      for (let i = 0; i < arrayOfFileObj.length; i++) {
        const fileObj = arrayOfFileObj[i];

        const filePath = await getUploadS3FilePath({
          type: fileObj.uploadType,
          id: fileObj.id,
          fileName: fileObj.fileName
        });

        const result = await uploadFile({ filePath, file: fileObj.file });

        if (!result.success) {
          return thunkApi.rejectWithValue({ success: false });
        }

        arrayOfFileLink.push({ type: fileObj.uploadType, url: result.url });
      }
    } else {
      return thunkApi.rejectWithValue({ success: false });
    }

    return arrayOfFileLink;
  }
);

export const uploadImageToS3 = createAsyncThunk(
  "s3/uploadImage",
  async (payload, thunkApi) => {
    const { uploadType, base64Image, id, fileName } = payload;

    const filePath = await getUploadS3FilePath({
      type: uploadType,
      id: id,
      fileName: fileName
    });

    const file = await dataURLtoFile(base64Image, filePath);
    const result = await uploadFile({ filePath, file });

    if (!result.success) {
      return thunkApi.rejectWithValue({ success: false });
    }

    return result.url;
  }
);

export const uploadMultipleImageToS3 = createAsyncThunk(
  "s3/uploadMultipleImage",
  async (payload, thunkApi) => {
    const arrayOfImageObj = payload;
    let arrayOfImageLink = [];

    if (arrayOfImageObj) {
      for (let i = 0; i < arrayOfImageObj.length; i++) {
        const imageObj = arrayOfImageObj[i];

        const filePath = await getUploadS3FilePath({
          type: imageObj.uploadType,
          id: imageObj.id,
          fileName: imageObj.fileName
        });

        const file = await dataURLtoFile(imageObj.base64Image, filePath);
        const result = await uploadFile({ filePath, file });

        if (!result.success) {
          return thunkApi.rejectWithValue({ success: false });
        }

        arrayOfImageLink.push({ type: imageObj.uploadType, url: result.url });
      }
    } else {
      return thunkApi.rejectWithValue({ success: false });
    }

    return arrayOfImageLink;
  }
);

export const uploadJSONToS3 = createAsyncThunk(
  "s3/uploadJSON",
  async (payload, thunkApi) => {
    const { uploadType, data, fileName, id } = payload;

    const filePath = await getUploadS3FilePath({
      type: uploadType,
      id,
      fileName: fileName,
    });

    const result = await uploadJSON({ fileName: filePath, data });

    if (!result.success) {
      return thunkApi.rejectWithValue({ success: false });
    }

    return result.url;
  }
);
