import React from "react";
import {
  makeStyles,
  Box,
  Divider,
  Typography,
  Button,
  FormHelperText
} from "@material-ui/core";
import { Formik, ErrorMessage } from 'formik';
import InputTextField from 'components/input/inputTextField';
import DistributeIncentivePointTable from '../../table/distributeIncentivePointTable';
import ProductSelect from '../../select/productSelect';
import AssignedProductTable from '../../table/assignedProductTable';
import { getLang } from "app/feature/constants";
import * as Yup from "yup";
import { Link } from "react-router-dom";

const useStyle = makeStyles(theme => ({
  container: {
    display: "grid",
    gridTemplateColumns: "3fr 5fr 5fr",
    columnGap: theme.spacing(1),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1)
    }
  },
  inputRoot: {
    paddingTop: "8px !important",
    paddingBottom: "7px !important"
  },
  paperRoot: {
    width: "217px",
  }
}))

export default function DealerIncentiveProgramForm({
  type,
  productDropdown,
  maxTier,
  handleSubmit,
  isSubmitting,
  programDetails,
  lang,
  isFetchingProduct,
}) {
  const classes = useStyle();

  const defaultPercentageValue = () => {
    let percentage = []

    for (let i = 0; i < maxTier; i++) {
      percentage.push(0)
    }

    return percentage
  }

  const selectAllProduct = (formik) => {
    formik.setFieldValue("assignProduct", productDropdown);
  }

  const deselectAllProduct = (formik) => {
    formik.setFieldValue("assignProduct", []);
  }

  return (
    <Box style={{ backgroundColor: "#fff" }}>
      <Formik
        initialValues={{
          name: type === "edit" ? programDetails.name : "",
          assignProduct: type === "edit" ? programDetails.products : [],
          tier: type === "edit" ? programDetails.percentages : defaultPercentageValue()
        }}
        validationSchema={
          Yup.object({
            name: Yup.string().trim().required(getLang(lang, 'message.error.FORM_VALIDATE_REQUIRED')),
            assignProduct: Yup.lazy(
              (value) => {
                if (value instanceof Array) {
                  return Yup.mixed().required(getLang(lang, 'message.error.FORM_VALIDATE_REQUIRED'))
                    .test('productSelected', 'At least 1 product must be selected.', (value = []) =>
                      value.length > 0
                    )
                }
                else {
                  return Yup.mixed().required(getLang(lang, 'message.error.FORM_VALIDATE_REQUIRED'))
                }
              }
            ),
            tier: Yup.lazy(
              (value) => {
                if (value instanceof Array) {
                  return Yup.mixed().required(getLang(lang, 'message.error.FORM_VALIDATE_REQUIRED'))
                    .test('exceedPercentage', 'The total percentage has exceeded 100%. Please re-adjust the incentive percentage(s).', (value = []) => 
                      value.reduce((partialSum, a) => partialSum + a, 0) <= 100
                    )
                    .test('totalPercentage', 'The total percentage must be 100%. Please re-adjust the incentive percentage(s).', (value = []) =>
                      value.reduce((partialSum, a) => partialSum + a, 0) === 100
                    )
                }
                else {
                  return Yup.mixed().required(getLang(lang, 'message.error.FORM_VALIDATE_REQUIRED'))
                }
              }
            )
          })
        }
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <Box style={{ padding: "32px" }}>
              <Box>
                <Box style={{ marginBottom: "32px" }}>
                  <Typography variant='h6' color='primary' style={{ fontWeight: "bold" }}>
                    {getLang(lang, 'label.DEALER_INCENTIVE_PROGRAM_DETAILS')}
                  </Typography>
                </Box>
                <Box className={classes.container}>
                  <Box style={{ display: "flex", alignItems: "center" }}>
                    <Typography variant='body1' color='primary'>
                      {getLang(lang, 'label.PROGRAM_NAME')}
                    </Typography>
                  </Box>
                  <InputTextField
                    varaint="filled"
                    size="small"
                    margin="none"
                    placeholder={getLang(lang, 'placeholder.ENTER_PROGRAM_NAME')}
                    InputProps={{
                      disableUnderline: true,
                      margin: "none",
                      style: {
                        borderRadius: "4px",
                        backgroundColor: "#ECEFF0"
                      },
                      classes: { input: classes.inputRoot }
                    }}
                    {...formik.getFieldProps("name")}
                    error={formik.touched.name && formik.errors.name ? true : false}
                    helperText={ErrorMessage({ name: "name" })}
                  />
                </Box>
                <Box className={classes.container}>
                  <Box style={{ display: "flex" }}>
                    <Typography variant='body1' color='primary' style={{ paddingTop: "0.5em" }}>
                      {getLang(lang, 'label.ASSIGN_PRODUCT')}
                    </Typography>
                  </Box>
                  <Box>
                    <ProductSelect
                      searchable={true}
                      value={formik.values.assignProduct}
                      placeholder={getLang(lang, 'placeholder.SELECT_A_PRODUCT')}
                      searchPlaceholder={getLang(lang, 'placeholder.SEARCH_A_PRODUCT_NAME')}
                      isClearable={false}
                      emptyMessage={getLang(lang, 'message.info.NO_PRODUCT_FOUND')}
                      dropdownItem={productDropdown}
                      isFetching={isFetchingProduct}
                      handleChange={(value) => {

                        let temp = [...formik.values.assignProduct]

                        if (temp.length === 0) {
                          temp.push(value)
                        } else {
                          const isProductExist = temp.find((product) => product.uuid === value.uuid)

                          if (isProductExist === undefined) {
                            temp.push(value)
                          } else {
                            let filter = temp.filter((product) => product.uuid !== value.uuid)

                            temp = [...filter]
                          }
                        }

                        formik.setFieldValue("assignProduct", [...temp])
                      }}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left"
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      isEmpty={formik.values.assignProduct.length === 0}
                      disabled={false}
                    />
                    <Box style={{display: "flex", justifyContent: "flex-end"}}>
                      <Link style={{marginRight: "0.5em"}} onClick={() => selectAllProduct(formik)}><Typography variant="caption">{getLang(lang, 'label.SELECT_ALL')}</Typography></Link>
                      <Link onClick={() => deselectAllProduct(formik)}><Typography variant="caption">{getLang(lang, 'label.DESELECT_ALL')}</Typography></Link>
                    </Box>
                    <FormHelperText
                      error={
                        formik.touched.assignProduct && formik.errors.assignProduct
                          ? true
                          : false
                      }
                      style={{ paddingLeft: 8 }}
                    >
                      {ErrorMessage({ name: "assignProduct" })}
                    </FormHelperText>
                    {formik.values.assignProduct.length > 0 && (
                      <Box style={{ marginTop: "20px" }}>
                        <AssignedProductTable
                          rows={formik.values.assignProduct}
                          handleDelete={(value) => {
                            let temp = [...formik.values.assignProduct]

                            let filtered = temp.filter((product) => product.uuid !== value)

                            formik.setFieldValue("assignProduct", [...filtered])
                          }}
                        />
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
              <Divider />
              <Box style={{ marginTop: "32px" }}>
                <Box style={{ marginBottom: "32px" }}>
                  <Typography variant='h6' color='primary' style={{ fontWeight: "bold" }}>
                    {getLang(lang, 'label.DISTRIBUTE_DEALERS_INCENTIVE_POINTS')}
                  </Typography>
                </Box>
                <Box className={classes.container}>
                  <Box>
                    <Box style={{ display: "flex", alignItems: "center" }}>
                      <Typography variant='body1' color='primary'>
                        {getLang(lang, 'label.NUMBER_OF_TIER')}: {maxTier}
                      </Typography>
                    </Box>
                  </Box>
                  <Box>
                    <DistributeIncentivePointTable
                      rows={formik.values.tier}
                      maxTier={maxTier}
                      formik={formik}
                      defaultValue={type === "edit" ? programDetails.percentages : defaultPercentageValue()}
                    />
                    <FormHelperText
                      error={
                        formik.errors.tier
                          ? true
                          : false
                      }
                      style={{ paddingLeft: 8 }}
                    >
                      {ErrorMessage({ name: "tier" })}
                    </FormHelperText>
                  </Box>
                </Box>
              </Box>
              <Divider />
              <Box style={{ marginTop: "20px", display: "flex", justifyContent: "flex-end" }}>
                <Button
                  variant="contained"
                  disableElevation
                  color="primary"
                  type='submit'
                  onClick={formik.handleSubmit}
                  disabled={isSubmitting}
                >
                  {getLang(lang, 'label.SUBMIT')}
                </Button>
              </Box>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  )
}
