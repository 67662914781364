import React, { useRef, Fragment } from 'react'
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  lighten,
  makeStyles,
  Typography
} from '@material-ui/core';
import InputTextField from 'components/input/inputTextField';
import {
  AddCommentOutlined as AddIcon,
  Close as CloseIcon
} from '@material-ui/icons'
import { AddQuestionPopper } from '../../popper';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import clsx from 'clsx';
import { EditSurveyQuestionCard } from '../../card';
import { surveyQuestionType } from 'modules/survey/constants';
import { Skeleton } from '@material-ui/lab';
import { v4 as uuidv4 } from "uuid";
import AssignProductSelect from "components/select/assignProductSelect";
import moment from 'moment';
import { getLang } from 'app/feature/constants';
import DatepickerDialog from "../../../../../components/dialog/datepicker/datepickerDialog.container";

const useStyle = makeStyles(theme => ({
  root: {
    width: 690,
  },
  detailsWrapper: {
    padding: "24px 40px"
  },
  switch: {
    paddingLeft: theme.spacing(2)
  },
  header: {
    paddingLeft: theme.spacing(3),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  componentWrapper: {
    transition: theme.transitions.create("background-color", {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  draggedOver: {
    backgroundColor: lighten(theme.palette.secondary.light, 0.5)
  },
  draggingBody: {
    zIndex: 999999,
    backgroundColor: "blue"
  },
  draggableItem: {
    margin: "10px 0px"
  },
  gridFieldPeriod: {
    width: "100%",
    display: "flex",
    alignItems: "center"
  },
  dateContainer: {
    height: 43,
    backgroundColor: "#ECEFF0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 60,
    borderRadius: "4px 0px 0px 4px"
  },
  dividerContainer: {
    backgroundColor: "#ECEFF0",
    display: "flex"
  },
  divider: {
    height: "70%",
    display: "flex",
    alignSelf: "center"
  },
  checkbox: {
    padding: "0px 9px 0px 0px"
  },
  chips: {
    display: "flex",
    flexWrap: "wrap"
  },
  chip: {
    margin: 2,
    backgroundColor: "#FFF",
    boxShadow: "-1px 1px 2px 0px #dedede",
    width: 80
  },
  dropdown: {
    paddingTop: 12,
    paddingBottom: 12,
    borderRadius: 4
  },
  menuItem: {
    width: 520
  },
  listItemText: {
    display: "block",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  },
  bodyContainer: {
    maxHeight: "calc(100vh - 260px)",
    overflowY: "auto",
  },
}));


export default function EditSurveyFormComponent({
  handleSubmit,
  alertWarningMessage,
  isEditMode,
  isLoading,
  handleClose,
  lang,
  handleDeleteQuestion,
  isAddQuestionOpen,
  handleIsAddQuestionOpen,
  surveyData,
  setSurveyData,
  isError,
  setIsError
}) {
  const classes = useStyle()
  const addQuestionButtonRef = useRef()

  const handleDragEnd = (result) => {
    const { destination, source } = result;

    if (!destination) return;

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    )
      return;

    const questions = [...surveyData.questions];
    const [removed] = questions.splice(source.index, 1);
    questions.splice(destination.index, 0, removed);

    setSurveyData((prev) => ({
      ...prev,
      questions: [...questions]
    }))
  };

  return (
    <Box className={classes.root}>
      <div className={classes.header}>
        <Typography
          variant="subtitle2"
          color="primary"
          style={{ fontWeight: "bold" }}
        >
          {isEditMode ? getLang(lang, 'label.EDIT_SURVEY') : getLang(lang, 'label.NEW_SURVEY')}
        </Typography>
        <IconButton color="primary" onClick={handleClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </div>
      <Divider />
      <Box className={classes.bodyContainer}>
        <Box className={classes.detailsWrapper}>
          <Grid container style={{ justifyContent: "center" }}>
            {[
              {
                title: `${getLang(lang, 'label.SURVEY_NAME')} `,
                name: "name",
                value: surveyData.name,
                placeholder: getLang(lang, 'placeholder.GIVE_SURVEY_NAME'),
                type: "textField",
              },
              {
                title: `${getLang(lang, 'label.DESCIPTION')} `,
                name: "description",
                value: surveyData.description,
                placeholder: getLang(lang, 'placeholder.GIVE_SURVEY_DESCRIPTION'),
                type: "textArea"
              }
            ].map((item, index) => (
              <Fragment key={index}>
                <Grid item xs={3} style={{ paddingTop: "16px" }}>
                  <Typography variant="body2" color="primary">
                    {item.title}  <Typography style={{ color: "#DE350B", display: 'inline-block' }}>*</Typography>
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  {isLoading ? (
                    <Skeleton
                      style={{
                        margin: "10px 0px"
                      }}
                      variant="rect"
                      animation="wave"
                      height={item.type === "textArea"}
                    />
                  ) : (
                    <>
                      <InputTextField
                        hiddenLabel
                        name={item.name}
                        value={item.value}
                        multiline={item.type === "textArea"}
                        rows={item.type === "textArea" ? 6 : undefined}
                        variant="filled"
                        placeholder={item.placeholder}
                        fullWidth
                        margin="dense"
                        onChange={(event) => {
                          const { name, value } = event.target

                          if (value === "") {
                            setIsError(item.name)
                          } else {
                            setIsError("")

                          }

                          setSurveyData((prev) => ({
                            ...prev,
                            [name]: value
                          }))

                        }}

                      />
                      {isError === item.name && (
                        <span style={{ color: "#f44336", fontSize: "0.75rem", margin: "0px" }}>{getLang(lang, 'message.error.FORM_VALIDATE_REQUIRED')}</span>
                      )}
                    </>
                  )}
                </Grid>
              </Fragment>
            ))}
            <Grid item xs={3} style={{ paddingTop: "16px" }}>
              <Typography variant="body2" color="primary">
                {getLang(lang, 'label.ASSIGN_PRODUCT')}
              </Typography>
            </Grid>
            <Grid item xs={9} style={{ marginTop: "8px" }}>
              {isLoading ? (
                <Skeleton
                  style={{ margin: "10px 0px" }}
                  variant="react"
                  animation="wave"
                  height={43}
                />
              ) : (
                <AssignProductSelect
                  value={surveyData.productIds}
                  handleChange={(value) => {
                    setSurveyData((prev) => ({
                      ...prev,
                      productIds: value
                    }))
                  }}
                />
              )}
            </Grid>
            <Grid item xs={3} style={{ paddingTop: "16px" }}>
              <Typography variant='body2' color='primary'>{getLang(lang, 'label.SURVEY_PERIOD')} <Typography style={{ color: "#DE350B", display: 'inline-block' }}>*</Typography></Typography>
            </Grid>
            <Grid item xs={9}>
              {isLoading ? (
                <Skeleton
                  style={{
                    margin: "10px 0px"
                  }}
                  variant="react"
                  animation="wave"
                  height={43}
                />
              ) : (
                <Grid
                  container
                  spacing={1}
                  style={{ margin: "4px -4px" }}
                >
                  {[
                    { label: getLang(lang, 'label.START'), date: surveyData.startDate, disabled: surveyData.disableStartDate },
                    { label: getLang(lang, 'label.END'), date: surveyData.endDate, disabled: false }
                  ].map((field, index) => (
                    <Grid item xs={6} key={index} className={classes.gridFieldPeriod}>
                      <Box display="flex">
                        <Box className={classes.dateContainer}>
                          <Typography
                            variant='subtitle2'
                            style={{
                              fontWeight: "bold",
                              color: "rgba(58, 77, 84, 0.33)"
                            }}
                          >
                            {field.label}
                          </Typography>
                        </Box>
                        <Box className={classes.dividerContainer}>
                          <Divider
                            orientation='vertical'
                            className={classes.divider}
                          />
                        </Box>
                      </Box>
                      <DatepickerDialog
                        handleChange={(v) => {
                          if (field.label === getLang(lang, 'label.START')) {
                            setSurveyData((prev) => ({
                              ...prev,
                              startDate: v.format("YYYY-MM-DD HH:mm"),
                              endDate: moment(v).isSameOrAfter(moment(surveyData.endDate)) ?
                                moment(v).add(1, 'minutes').format("YYYY-MM-DD HH:mm") :
                                surveyData.endDate
                            }))
                          }

                          if (field.label === getLang(lang, 'label.END')) {
                            setSurveyData((prev) => ({
                              ...prev,
                              endDate: moment(v).isSameOrBefore(moment(surveyData.startDate)) ?
                                moment(surveyData.startDate).add(1, 'minutes').format("YYYY-MM-DD HH:mm") :
                                v.format("YYYY-MM-DD HH:mm")
                            }))
                          }
                        }}
                        value={field.date}
                        forceDisplay={moment(field.date).format("YYYY-MM-DD HH:mm")}
                        label={getLang(lang, 'placeholder.SELECT_DATE')}
                        disabled={field.disabled}
                        disablePast={true}
                        displayFormat="DD MMM YYYY HH:mm"
                        enableTime={true}
                        style={{
                          height: 43
                        }}
                        InputProps={{
                          endAdornment: null
                        }}
                        inputProps={{
                          style: {
                            paddingTop: 14,
                          }
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>
              )}
            </Grid>
            <Grid item xs={12} style={{ marginTop: "10px" }}>
              {isLoading ? (
                <Skeleton
                  style={{
                    margin: "10px 0px"
                  }}
                  variant="rect"
                  animation="wave"
                  height={150}
                />
              ) : (
                <DragDropContext
                  onDragEnd={(result) => handleDragEnd(result)}
                >
                  <Droppable
                    droppableId='droppable-question'
                    type='droppable-question'
                  >
                    {(provided, snapshot) => (
                      <Box
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className={clsx(classes.componentWrapper, {
                          [classes.draggedOver]: snapshot.isDraggingOver
                        })}
                      >
                        {surveyData.questions.map((item, index) => (
                          <Draggable
                            style={{ position: "fixed" }}
                            draggableId={`question-${index}`}
                            key={index}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                {...provided.draggableProps}
                                ref={provided.innerRef}
                                className={clsx(classes.draggableItem, {
                                  [classes.draggingBody]: snapshot.isDragging
                                })}
                              >
                                <EditSurveyQuestionCard
                                  dragHandleProps={
                                    provided.dragHandleProps
                                  }
                                  handleDeleteQuestion={handleDeleteQuestion}
                                  index={index}
                                  questions={surveyData.questions}
                                  setSurveyData={setSurveyData}
                                  isError={isError}
                                  setIsError={setIsError}
                                />
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </Box>
                    )}

                  </Droppable>
                </DragDropContext>
              )}
            </Grid>
            <Grid item xs={12}>
              <AddQuestionPopper
                isOpen={isAddQuestionOpen}
                anchorRef={addQuestionButtonRef}
                handleClick={(type) => {
                  const newQuestion = {
                    id: uuidv4(),
                    text: "",
                    placeholder: "",
                    type: type,
                    answers: (type === surveyQuestionType.selection || type === "multipleChoiceMultiSelect" || type === "dropdownlist") ? Array(3).fill("") : null,
                    required: true
                  }

                  let temp = [...surveyData.questions]

                  temp.push(newQuestion)
                  setSurveyData((prev) => ({
                    ...prev,
                    questions: [...temp]
                  }))
                  handleIsAddQuestionOpen(false)
                }}
                handleClose={() => handleIsAddQuestionOpen(false)}
              />
              <Button
                variant="contained"
                color="primary"
                endIcon={<AddIcon />}
                ref={addQuestionButtonRef}
                onClick={() => {
                  if (surveyData.questions.length < 50) {
                    handleIsAddQuestionOpen(true)
                  } else {
                    alertWarningMessage(getLang(lang, 'message.warning.MAXIMUM_20_QUESTIONS'))
                  }
                }}
              >
                {getLang(lang, 'label.ADD_QUESTION')}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Divider />
      <Box p={2} display="flex" justifyContent="space">
        <Box flexGrow={1} display="flex" alignItems="center">
          <Typography variant='caption'>* {getLang(lang, 'label.REQUIRED_FIELD')}</Typography>
        </Box>
        <Box>
          <Button
            onClick={handleClose}
            disableElevation
            style={{ marginRight: "4px" }}
            type="button"
          >
            {getLang(lang, 'label.CANCEL')}
          </Button>
          <Button
            variant='contained'
            color="secondary"
            disableElevation
            type="submit"
            onClick={() => handleSubmit(surveyData, isError)}
          >
            {getLang(lang, 'label.PUBLISH')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
