import React from "react";
import {
  makeStyles,
  ButtonBase,
  Typography,
  Button,
  Box,
  Chip
} from "@material-ui/core";
import ClearIcon from '@material-ui/icons/Clear';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "5px 12px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#eceff0",
    borderRadius: 4,
    width: "100%", 
    overflow: "hidden"
  },
  inputText: {
    color: "#a4a6a8",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  },
  inputContent: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap"
  },
  chip: {
    backgroundColor: "#FFF",
    marginRight: "0.25em",
    marginBottom: "0.25em"
  },
  filled: {
    color: "rgba(0, 0, 0, 0.87)",
  },
  clearButton: {
    minWidth: 0
  }
}))

function AttributeSelectComponent({
  handleClick,
  handleClear,
  handleChipDelete,
  value,
  placeholder,
  disabled,
  style,
  isEmpty,
  id,
  open,
  textStyle,
  isClearable,
}) {
  const classes = useStyles();

  return (
    <ButtonBase
      aria-describedby={id}
      className={classes.root}
      disableRipple
      onClick={handleClick}
      disabled={disabled}
      style={{ paddding: 12, ...style }}
    >
      <Box
        className={classes.inputContent}
      >
        {isEmpty ? (
          <Typography
            variant='body2'
            className={clsx(classes.inputText, {
              [classes.filled]: !isEmpty
            })}
            style={textStyle}
          >
            {placeholder}
          </Typography>
        ) : (
          value.map((item) => (
            <Chip
              variant="outlined"
              label={item.name + ": " + item.options}
              size="small"
              className={classes.chip}
              onDelete={() => handleChipDelete(item)}
            />
          ))
        )}
      </Box>
      <Box
        style={{
          display: "flex",
          alignItems: "center"
        }}
      >
        {isClearable && !isEmpty &&
          <Button
            className={classes.clearButton}
            onClick={(e) => {
              e.stopPropagation()
              handleClear()
            }}
          >
            <ClearIcon style={{ fontSize: 16 }} />
          </Button>
        }
        {!disabled && (open ? (
          <ArrowDropUpIcon fontSize='small' />
        ) : (
          <ArrowDropDownIcon fontSize='small' />
        ))}
      </Box>
    </ButtonBase>
  )
}

AttributeSelectComponent.propTypes = {
  handleClick: PropTypes.func,
  value: PropTypes.array,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  isEmpty: PropTypes.bool
}

export default AttributeSelectComponent