import {
  createWarrantySetting,
  fetchWarrantyListTable,
  fetchWarrantySettingDetail,
  updateWarrantySettingDetail,
  createWarrantyExtension,
  fetchJSONURL,
  fetchWarrantyNameDropdown,
  updateWarrantyExtension,
  fetchWarrantyExtensionList,
  fetchWarrantyExtensionBatch,
  assignWarranty,
  fetchConsumerWarrantyDetail,
  fetchWarranteeListTable,
  verifyWarranty,
  fetchWarrantyLogTable,
  updateWarrantyLog,
  fetchAllWarranteeListTable,
  fetchWarranteeClaims,
  processWarranteeClaims,
  fetchConsumerClaimTable,
  fetchConsumerClaimDetail,
  fetchWarrantyReimbursementTable,
  fetchWarrantyReimbursementDetail,
  createReimbursement,
  processWarrantyReimbursement,
  updateReimbursement,
  updateClaimDetail,
} from "../action";
import { setLoading, addAlert } from "modules/notification";
import { getApiLang } from "app/feature/constants";
import { hideBackdrop, showBackdrop } from "modules/backdrop";
import {
  resetWarrantySetting,
  resetRegisterForm,
  setWarrantyList,
  setWarrantyDetail,
  setWarrantySettingTermConditionDetail,
  setWarrantySettingUpdate,
  setWarrantyID,
  setWarrantyNameDropdown,
  setWarrantyExtensionListTable,
  setWarrantyExtensionBatchTable,
  setConsumerWarrantyDetail,
  setWarranteeList,
  setConsumerWarrantyDetailStatus,
  setWarrantyLogTable,
  setIsWarranteeTableDirty,
  setWarranteeClaimsTable,
  updateWarranteeClaimsTable,
  setConsumerClaimsTable,
  setConsumerClaimDetail,
  clearConsumerClaimDetail,
  setWarrantyReimbursementTable,
  setWarrantyReimbursementLoadingError,
  clearWarrantyReimbursementTable,
  setWarrantyReimbursementDetail,
  clearWarrantyReimbursementDetail,
} from "../slice";
import { getUnprocessClaimNotification } from "modules/notification/redux";

const createWarrantySettingMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);
    const { type, payload } = action;
    const lang = getState().constant.languages;
    const pageSetting = getState().warranty.pageSetting;

    switch (type) {
      case createWarrantySetting.pending.type:
        dispatch(
          setLoading({ id: createWarrantySetting.typePrefix, state: true })
        );
        break;

      case createWarrantySetting.fulfilled.type:
        next(resetWarrantySetting());
        next(resetRegisterForm());
        next(setWarrantyID(action.payload));
        dispatch(fetchWarrantyListTable(pageSetting));
        dispatch(
          setLoading({ id: createWarrantySetting.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "success",
            message: getApiLang(lang, "success.SUCCESS_CREATE_WARRANTY"),
          })
        );
        break;

      case createWarrantySetting.rejected.type:
        dispatch(
          setLoading({ id: createWarrantySetting.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

const fetchWarrantyListTableMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    switch (type) {
      case fetchWarrantyListTable.pending.type:
        dispatch(
          setLoading({ id: fetchWarrantyListTable.typePrefix, state: true })
        );
        break;

      case fetchWarrantyListTable.fulfilled.type:
        next(setWarrantyList(action.payload));
        dispatch(
          setLoading({ id: fetchWarrantyListTable.typePrefix, state: false })
        );
        break;

      case fetchWarrantyListTable.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({ id: fetchWarrantyListTable.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

const fetchWarrantySettingDetailMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    switch (type) {
      case fetchWarrantySettingDetail.pending.type:
        dispatch(
          setLoading({ id: fetchWarrantySettingDetail.typePrefix, state: true })
        );
        break;

      case fetchWarrantySettingDetail.fulfilled.type:
        next(setWarrantyDetail(action.payload));
        dispatch(
          setLoading({
            id: fetchWarrantySettingDetail.typePrefix,
            state: false,
          })
        );
        break;

      case fetchWarrantySettingDetail.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: fetchWarrantySettingDetail.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

const fetchJSONURLMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    switch (type) {
      case fetchJSONURL.pending.type:
        dispatch(setLoading({ id: fetchJSONURL.typePrefix, state: true }));
        break;

      case fetchJSONURL.fulfilled.type:
        next(setWarrantySettingTermConditionDetail(action.payload));
        dispatch(setLoading({ id: fetchJSONURL.typePrefix, state: false }));
        break;

      case fetchJSONURL.rejected.type:
        const lang = getState().constant.languages;
        dispatch(setLoading({ id: fetchJSONURL.typePrefix, state: false }));
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

const updateWarrantySettingDetailMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages;
    const pageSetting = getState().warranty.pageSetting;

    switch (type) {
      case updateWarrantySettingDetail.pending.type:
        dispatch(
          setLoading({
            id: updateWarrantySettingDetail.typePrefix,
            state: true,
          })
        );
        break;

      case updateWarrantySettingDetail.fulfilled.type:
        dispatch(setWarrantySettingUpdate(action.payload));
        dispatch(fetchWarrantyListTable(pageSetting));
        dispatch(fetchAllWarranteeListTable());
        dispatch(
          setLoading({
            id: updateWarrantySettingDetail.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "success",
            message: getApiLang(
              lang,
              "success.SUCCESS_UPDATE_WARRANTY_SETTING_DETAIL"
            ),
          })
        );
        break;

      case updateWarrantySettingDetail.rejected.type:
        dispatch(
          setLoading({
            id: updateWarrantySettingDetail.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

const createWarrantyExtensionMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);
    const { type, payload } = action;
    const lang = getState().constant.languages;

    switch (type) {
      case createWarrantyExtension.pending.type:
        dispatch(
          setLoading({ id: createWarrantyExtension.typePrefix, state: true })
        );
        break;

      case createWarrantyExtension.fulfilled.type:
        dispatch(
          setLoading({ id: createWarrantyExtension.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "success",
            message: getApiLang(
              lang,
              "success.SUCCESS_CREATE_WARRANTY_EXTENSION"
            ),
          })
        );
        break;

      case createWarrantyExtension.rejected.type:
        dispatch(
          setLoading({ id: createWarrantyExtension.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

const fetchWarrantyNameDropdownMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case fetchWarrantyNameDropdown.pending.type:
        dispatch(
          setLoading({ id: fetchWarrantyNameDropdown.typePrefix, state: true })
        );
        break;

      case fetchWarrantyNameDropdown.fulfilled.type:
        next(setWarrantyNameDropdown(action.payload));
        dispatch(
          setLoading({ id: fetchWarrantyNameDropdown.typePrefix, state: false })
        );
        break;

      case fetchWarrantyNameDropdown.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({ id: fetchWarrantyNameDropdown.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

const updateWarrantyExtensionMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages;

    switch (type) {
      case updateWarrantyExtension.pending.type:
        dispatch(
          setLoading({
            id: updateWarrantyExtension.typePrefix,
            state: true,
          })
        );
        break;

      case updateWarrantyExtension.fulfilled.type:
        dispatch(
          setLoading({
            id: updateWarrantyExtension.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "success",
            message: getApiLang(
              lang,
              "success.SUCCESS_UPDATE_WARRANTY_EXTENSION"
            ),
          })
        );
        break;

      case updateWarrantyExtension.rejected.type:
        dispatch(
          setLoading({
            id: updateWarrantyExtension.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

const fetchWarrantyExtensionListMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    switch (type) {
      case fetchWarrantyExtensionList.pending.type:
        dispatch(
          setLoading({ id: fetchWarrantyExtensionList.typePrefix, state: true })
        );
        break;

      case fetchWarrantyExtensionList.fulfilled.type:
        next(setWarrantyExtensionListTable(action.payload));
        dispatch(
          setLoading({
            id: fetchWarrantyExtensionList.typePrefix,
            state: false,
          })
        );
        break;

      case fetchWarrantyExtensionList.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: fetchWarrantyExtensionList.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

const fetchWarrantyExtensionBatchMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    switch (type) {
      case fetchWarrantyExtensionBatch.pending.type:
        dispatch(
          setLoading({
            id: fetchWarrantyExtensionBatch.typePrefix,
            state: true,
          })
        );
        break;

      case fetchWarrantyExtensionBatch.fulfilled.type:
        next(setWarrantyExtensionBatchTable(action.payload));
        dispatch(
          setLoading({
            id: fetchWarrantyExtensionBatch.typePrefix,
            state: false,
          })
        );
        break;

      case fetchWarrantyExtensionBatch.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: fetchWarrantyExtensionBatch.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

const assignWarrantyMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages;
    const pageSetting = getState().warranty.pageSetting;

    switch (type) {
      case assignWarranty.pending.type:
        dispatch(setLoading({ id: assignWarranty.typePrefix, state: true }));
        break;

      case assignWarranty.fulfilled.type:
        dispatch(fetchWarrantyListTable(pageSetting));

        dispatch(setLoading({ id: assignWarranty.typePrefix, state: false }));

        break;

      case assignWarranty.rejected.type:
        dispatch(setLoading({ id: assignWarranty.typePrefix, state: false }));
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

const fetchWarranteeListTableMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    switch (type) {
      case fetchWarranteeListTable.pending.type:
        dispatch(
          setLoading({ id: fetchWarranteeListTable.typePrefix, state: true })
        );
        break;

      case fetchWarranteeListTable.fulfilled.type:
        next(setWarranteeList(action.payload));
        dispatch(
          setLoading({ id: fetchWarranteeListTable.typePrefix, state: false })
        );
        break;

      case fetchWarranteeListTable.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({ id: fetchWarranteeListTable.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

const fetchAllWarranteeListTableMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    switch (type) {
      case fetchAllWarranteeListTable.pending.type:
        dispatch(
          setLoading({ id: fetchAllWarranteeListTable.typePrefix, state: true })
        );
        break;

      case fetchAllWarranteeListTable.fulfilled.type:
        next(setWarranteeList(action.payload));
        dispatch(
          setLoading({
            id: fetchAllWarranteeListTable.typePrefix,
            state: false,
          })
        );
        break;

      case fetchAllWarranteeListTable.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: fetchAllWarranteeListTable.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

const fetchConsumerWarrantyDetailMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    switch (type) {
      case fetchConsumerWarrantyDetail.pending.type:
        dispatch(
          setLoading({
            id: fetchConsumerWarrantyDetail.typePrefix,
            state: true,
          })
        );
        break;

      case fetchConsumerWarrantyDetail.fulfilled.type:
        next(setConsumerWarrantyDetail(action.payload.data));
        dispatch(
          setLoading({
            id: fetchConsumerWarrantyDetail.typePrefix,
            state: false,
          })
        );
        break;

      case fetchConsumerWarrantyDetail.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: fetchConsumerWarrantyDetail.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

const verifyWarrantyMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages;

    switch (type) {
      case verifyWarranty.pending.type:
        dispatch(
          setLoading({
            id: verifyWarranty.typePrefix,
            state: true,
          })
        );
        break;

      case verifyWarranty.fulfilled.type:
        dispatch(setConsumerWarrantyDetailStatus(action.payload));

        dispatch(setIsWarranteeTableDirty(true));

        dispatch(
          setLoading({
            id: verifyWarranty.typePrefix,
            state: false,
          })
        );

        break;

      case verifyWarranty.rejected.type:
        dispatch(
          setLoading({
            id: verifyWarranty.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

const fetchWarrantyLogTableMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    switch (type) {
      case fetchWarrantyLogTable.pending.type:
        dispatch(
          setLoading({ id: fetchWarrantyLogTable.typePrefix, state: true })
        );
        break;

      case fetchWarrantyLogTable.fulfilled.type:
        next(setWarrantyLogTable(action.payload));
        dispatch(
          setLoading({ id: fetchWarrantyLogTable.typePrefix, state: false })
        );
        break;

      case fetchWarrantyLogTable.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({ id: fetchWarrantyLogTable.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

const updateWarrantyLogMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages;

    switch (type) {
      case updateWarrantyLog.pending.type:
        dispatch(
          setLoading({
            id: updateWarrantyLog.typePrefix,
            state: true,
          })
        );
        break;

      case updateWarrantyLog.fulfilled.type:
        const date = new Date(action.payload.extendedTo);
        if (action.payload.action === "reinstate") {
          dispatch(
            setConsumerWarrantyDetailStatus({
              status: action.payload.status,
              reason: action.payload.reason,
              extended: action.payload.extended,
              extendedUntil: `${date.getFullYear()}-${String(
                date.getMonth() + 1
              ).padStart(2, "0")}-${String(date.getDate()).padStart(
                2,
                "0"
              )} ${String(date.getHours()).padStart(2, "0")}:${String(
                date.getMinutes()
              ).padStart(2, "0")}:${String(date.getSeconds()).padStart(
                2,
                "0"
              )}`,
            })
          );
        } else if (action.payload.action === "void") {
          dispatch(
            setConsumerWarrantyDetailStatus({
              status: "voided",
              reason: action.payload.remarks,
            })
          );
        }

        dispatch(setIsWarranteeTableDirty(true));

        dispatch(
          setLoading({
            id: updateWarrantyLog.typePrefix,
            state: false,
          })
        );

        dispatch(
          addAlert({
            severity: "success",
            message: getApiLang(lang, "success.SUCCESS_UPDATE_WARRANT_LOG"),
          })
        );
        break;

      case updateWarrantyLog.rejected.type:
        dispatch(
          setLoading({
            id: updateWarrantyLog.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

const fetchWarranteeClaimsMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    switch (type) {
      case fetchWarranteeClaims.pending.type:
        dispatch(
          setLoading({ id: fetchWarranteeClaims.typePrefix, state: true })
        );
        break;

      case fetchWarranteeClaims.fulfilled.type:
        dispatch(setWarranteeClaimsTable(action.payload));
        dispatch(
          setLoading({ id: fetchWarranteeClaims.typePrefix, state: false })
        );
        break;

      case fetchWarranteeClaims.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({ id: fetchWarranteeClaims.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

const processWarranteeClaimsMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    switch (type) {
      case processWarranteeClaims.pending.type:
        dispatch(
          setLoading({ id: processWarranteeClaims.typePrefix, state: true })
        );
        break;

      case processWarranteeClaims.fulfilled.type:
        if (action.payload.index !== null) {
          dispatch(updateWarranteeClaimsTable(action.payload));
        }
        dispatch(getUnprocessClaimNotification());

        dispatch(
          setLoading({ id: processWarranteeClaims.typePrefix, state: false })
        );
        break;

      case processWarranteeClaims.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({ id: processWarranteeClaims.typePrefix, state: false })
        );
        dispatch(hideBackdrop());
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

const fetchConsumerClaimTableMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    switch (type) {
      case fetchConsumerClaimTable.pending.type:
        dispatch(
          setLoading({ id: fetchConsumerClaimTable.typePrefix, state: true })
        );
        break;

      case fetchConsumerClaimTable.fulfilled.type:
        dispatch(setConsumerClaimsTable(action.payload));
        dispatch(
          setLoading({ id: fetchConsumerClaimTable.typePrefix, state: false })
        );
        break;

      case fetchConsumerClaimTable.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({ id: fetchConsumerClaimTable.typePrefix, state: false })
        );
        dispatch(hideBackdrop());
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

const fetchConsumerClaimDetailMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    switch (type) {
      case fetchConsumerClaimDetail.pending.type:
        dispatch(clearConsumerClaimDetail());
        dispatch(showBackdrop());
        break;

      case fetchConsumerClaimDetail.fulfilled.type:
        dispatch(setWarrantyReimbursementLoadingError(false));
        dispatch(hideBackdrop());
        dispatch(setConsumerClaimDetail(payload));
        break;

      case fetchConsumerClaimDetail.rejected.type:
        const lang = getState().constant.languages;
        dispatch(setWarrantyReimbursementLoadingError(true));
        dispatch(hideBackdrop());
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

const fetchWarrantyReimbursementTableMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    switch (type) {
      case fetchWarrantyReimbursementTable.pending.type:
        dispatch(
          setLoading({
            id: fetchWarrantyReimbursementTable.typePrefix,
            state: true,
          })
        );
        dispatch(clearWarrantyReimbursementTable());
        break;

      case fetchWarrantyReimbursementTable.fulfilled.type:
        dispatch(setWarrantyReimbursementTable(payload));
        dispatch(setWarrantyReimbursementLoadingError(false));
        dispatch(
          setLoading({
            id: fetchWarrantyReimbursementTable.typePrefix,
            state: false,
          })
        );
        break;

      case fetchWarrantyReimbursementTable.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: fetchWarrantyReimbursementTable.typePrefix,
            state: false,
          })
        );
        dispatch(setWarrantyReimbursementLoadingError(true));
        dispatch(hideBackdrop());
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

const fetchWarrantyReimbursementDetailMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    switch (type) {
      case fetchWarrantyReimbursementDetail.pending.type:
        dispatch(clearWarrantyReimbursementDetail());
        dispatch(showBackdrop());
        break;

      case fetchWarrantyReimbursementDetail.fulfilled.type:
        dispatch(setWarrantyReimbursementDetail(payload));
        dispatch(setWarrantyReimbursementLoadingError(false));
        dispatch(hideBackdrop());
        break;

      case fetchWarrantyReimbursementDetail.rejected.type:
        const lang = getState().constant.languages;
        dispatch(setWarrantyReimbursementLoadingError(true));
        dispatch(hideBackdrop());
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

const createWarrantyReimbursementMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    switch (type) {
      case createReimbursement.pending.type:
        dispatch(showBackdrop());
        break;

      case createReimbursement.fulfilled.type:
        dispatch(clearConsumerClaimDetail());
        dispatch(hideBackdrop());
        break;

      case createReimbursement.rejected.type:
        const lang = getState().constant.languages;
        dispatch(hideBackdrop());
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

const processWarrantyReimbursementMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    switch (type) {
      case processWarrantyReimbursement.pending.type:
        dispatch(showBackdrop());
        break;

      case processWarrantyReimbursement.fulfilled.type:
        dispatch(clearConsumerClaimDetail());
        dispatch(hideBackdrop());
        break;

      case processWarrantyReimbursement.rejected.type:
        const lang = getState().constant.languages;
        dispatch(hideBackdrop());
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

const updateReimbursementMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    switch (type) {
      case updateReimbursement.pending.type:
        dispatch(showBackdrop());
        break;

      case updateReimbursement.fulfilled.type:
        dispatch(clearConsumerClaimDetail());
        dispatch(hideBackdrop());
        break;

      case updateReimbursement.rejected.type:
        const lang = getState().constant.languages;
        dispatch(hideBackdrop());
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;

      default:
        break;
    }
  };

const updateClaimDetailMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case updateClaimDetail.pending.type:
        dispatch(
          setLoading({
            id: updateClaimDetail.typePrefix,
            state: true,
          })
        );
        break;
      case updateClaimDetail.fulfilled.type:
        dispatch(
          setLoading({
            id: updateClaimDetail.typePrefix,
            state: false,
          })
        );
        break;
      case updateClaimDetail.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: updateClaimDetail.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

export default [
  createWarrantySettingMiddleware,
  fetchWarrantyListTableMiddleware,
  fetchWarrantySettingDetailMiddleware,
  updateWarrantySettingDetailMiddleware,
  createWarrantyExtensionMiddleware,
  fetchJSONURLMiddleware,
  fetchWarrantyNameDropdownMiddleware,
  updateWarrantyExtensionMiddleware,
  fetchWarrantyExtensionListMiddleware,
  fetchWarrantyExtensionBatchMiddleware,
  assignWarrantyMiddleware,
  fetchWarranteeListTableMiddleware,
  fetchConsumerWarrantyDetailMiddleware,
  verifyWarrantyMiddleware,
  fetchWarrantyLogTableMiddleware,
  updateWarrantyLogMiddleware,
  fetchAllWarranteeListTableMiddleware,
  fetchWarranteeClaimsMiddleware,
  processWarranteeClaimsMiddleware,
  fetchConsumerClaimTableMiddleware,
  fetchConsumerClaimDetailMiddleware,
  fetchWarrantyReimbursementTableMiddleware,
  fetchWarrantyReimbursementDetailMiddleware,
  createWarrantyReimbursementMiddleware,
  processWarrantyReimbursementMiddleware,
  updateReimbursementMiddleware,
  updateClaimDetailMiddleware,
];
