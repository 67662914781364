import { surveyQuestionType, surveyTemplateType } from "."
import { v4 as uuidv4 } from "uuid"
import { getLang } from "app/feature/constants"

export const surveyTemplate = (lang) => {
 return   [
        {
            name: getLang(lang, 'label.PRODUCT_SERVICE_FEEDBACK'),
            type: surveyTemplateType.feedback,
            description: getLang(lang, 'paragraph.PRODUCT_SERVICE_FEEDBACK_DESCRIPTION'),
            questions: [
                {
                    id: uuidv4(),
                    text: getLang(lang, 'paragraph.PRODUCT_SERVICE_FEEDBACK_QUESTION_A'),
                    type: surveyQuestionType.text,
                    answers: null,
                    required: true,
                    placeholder: getLang(lang, 'placeholder.ENTER_ANSWER')
                },
                {
                    id: uuidv4(),
                    text: getLang(lang, 'paragraph.PRODUCT_SERVICE_FEEDBACK_QUESTION_B'),
                    type: surveyQuestionType.selection,
                    answers: [
                        getLang(lang, 'label.GOOD'),
                        getLang(lang, 'label.AVERAGE'),
                        getLang(lang, 'label.BAD')
                    ],
                    required: true,
                    placeholder: ""
                },
                {
                    id: uuidv4(),
                    text: getLang(lang, 'paragraph.PRODUCT_SERVICE_FEEDBACK_QUESTION_C'),
                    type: surveyQuestionType.selection,
                    answers: [
                        getLang(lang, 'label.VERY_WELL'),
                        getLang(lang, 'label.FINE'),
                        getLang(lang, 'label.BADLY')
                    ],
                    required: true,
                    placeholder: ""
                },
                {
                    id: uuidv4(),
                    text: getLang(lang, 'paragraph.PRODUCT_SERVICE_FEEDBACK_QUESTION_D'),
                    type: surveyQuestionType.text,
                    answers: null,
                    required: true,
                    placeholder: getLang(lang, 'placeholder.ENTER_ANSWER')
                },
                {
                    id: uuidv4(),
                    text: getLang(lang, 'paragraph.PRODUCT_SERVICE_FEEDBACK_QUESTION_E'),
                    type: surveyQuestionType.selection,
                    answers: [
                        getLang(lang, 'label.GOOD'),
                        getLang(lang, 'label.AVERAGE'),
                        getLang(lang, 'label.BAD')
                    ],
                    required: true,
                    placeholder: ""
                },
                {
                    id: uuidv4(),
                    text: getLang(lang, 'paragraph.PRODUCT_SERVICE_FEEDBACK_QUESTION_F'),
                    type: surveyQuestionType.selection,
                    answers: [
                        getLang(lang, 'label.GOOD'),
                        getLang(lang, 'label.AVERAGE'),
                        getLang(lang, 'label.BAD')
                    ],
                    required: true,
                    placeholder: ""
                },
                {
                    id: uuidv4(),
                    text: getLang(lang, 'paragraph.PRODUCT_SERVICE_FEEDBACK_QUESTION_G'),
                    type: surveyQuestionType.selection,
                    answers: [
                       getLang(lang, 'label.YES'),
                       getLang(lang, 'label.NO'),
                       getLang(lang, 'label.WOULD_LIKE_TO')
                    ],
                    required: true,
                    placeholder: ""
                },
                {
                    id: uuidv4(),
                    text: getLang(lang, 'paragraph.PRODUCT_SERVICE_FEEDBACK_QUESTION_H'),
                    type: surveyQuestionType.text,
                    answers: null,
                    required: true,
                    placeholder: getLang(lang, 'placeholder.ENTER_ANSWER')
                },
                {
                    id: uuidv4(),
                    text: getLang(lang, 'paragraph.PRODUCT_SERVICE_FEEDBACK_QUESTION_I'),
                    type: surveyQuestionType.text,
                    answers: null,
                    required: true,
                    placeholder: getLang(lang, 'placeholder.ENTER_COMMENT_FEEDBACK')
                }
            ]
        },
        {
            name: getLang(lang, 'label.FOLLOW_UP_SURVEY_TEMPLATE'),
            type: surveyTemplateType.followUp,
            description: getLang(lang, 'paragraph.FOLLOW_UP_SURVEY_DESCRIPTION'),
            questions: [
                {
                    id: uuidv4(),
                    text: getLang(lang, 'paragraph.FOLLOW_UP_SURVEY_QUESTION_A'),
                    type: surveyQuestionType.text,
                    answers: null,
                    required: true,
                    placeholder: getLang(lang, 'placeholder.INSERT_1_TO_10_SCALE')
                },
                {
                    id: uuidv4(),
                    text: getLang(lang, 'paragraph.FOLLOW_UP_SURVEY_QUESTION_B'),
                    type: surveyQuestionType.selection,
                    answers: [
                        getLang(lang, 'label.VERY_LIKELY'),
                        getLang(lang, 'label.MAYBE'),
                        getLang(lang, 'label.UNLIKELY')
                    ],
                    required: true,
                    placeholder: ""
                },
                {
                    id: uuidv4(),
                    text: getLang(lang, 'paragraph.FOLLOW_UP_SURVEY_QUESTION_C'),
                    type: surveyQuestionType.selection,
                    answers: [
                        getLang(lang, 'label.VERY_SATISFIED'),
                        getLang(lang, 'label.SATISFIED'),
                        getLang(lang, 'label.UNSATISFIED')
                    ],
                    required: true,
                    placeholder: ""
                },
                {
                    id: uuidv4(),
                    text: getLang(lang, 'paragraph.FOLLOW_UP_SURVEY_QUESTION_D'),
                    type: surveyQuestionType.selection,
                    answers: [
                        getLang(lang, 'label.YES'),
                        getLang(lang, 'label.NO'),
                        getLang(lang, 'label.COULD_BE_IMPROVED')
                    ],
                    required: true,
                    placeholder: ""
                },
                {
                    id: uuidv4(),
                    text: getLang(lang, 'paragraph.FOLLOW_UP_SURVEY_QUESTION_E'),
                    type: surveyQuestionType.selection,
                    answers: [
                        getLang(lang, 'label.AGREE'),
                        getLang(lang, 'label.DISAGREE')
                    ],
                    required: true,
                    placeholder: ""
                },
                {
                    id: uuidv4(),
                    text: getLang(lang, 'paragraph.FOLLOW_UP_SURVEY_QUESTION_F'),
                    type: surveyQuestionType.selection,
                    answers: [
                        getLang(lang, 'label.VERY_LIKELY'),
                        getLang(lang, 'label.MAYBE'),
                        getLang(lang, 'label.UNLIKELY')
                    ],
                    required: true,
                    placeholder: ""
                },
                {
                    id: uuidv4(),
                    text: getLang(lang, 'paragraph.FOLLOW_UP_SURVEY_QUESTION_G'),
                    type: surveyQuestionType.text,
                    answers: null,
                    required: true,
                    placeholder: getLang(lang, 'placeholder.ENTER_COMMENT_FEEDBACK')
                }
            ]
        },
        {
            name: getLang(lang, 'label.MARKETING_SURVEY_TEMPLATE'),
            type: surveyTemplateType.marketing,
            description: getLang(lang, 'paragraph.MARKETING_SURVEY_DESCRIPTION'),
            questions: [
                {
                    id: uuidv4(),
                    text: getLang(lang, 'paragraph.MARKETING_SURVEY_QUESTION_A'),
                    type: surveyQuestionType.selection,
                    answers: [
                        getLang(lang, 'label.THROUGH_FRIENDS_FAMILY'),
                        getLang(lang, 'label.SOCIAL_MEDIA_PLATFORM'),
                        getLang(lang, 'label.OTHERS')
                    ],
                    required: true,
                    placeholder: ""
                },
                {
                    id: uuidv4(),
                    text: getLang(lang, 'paragraph.MARKETING_SURVEY_QUESTION_B'),
                    type: surveyQuestionType.selection,
                    answers: [
                        getLang(lang, 'label.YES'),
                        getLang(lang, 'label.NO'),
                    ],
                    required: true,
                    placeholder: ""
                },
                {
                    id: uuidv4(),
                    text: getLang(lang, 'paragraph.MARKETING_SURVEY_QUESTION_C'),
                    type: surveyQuestionType.selection,
                    answers: [
                        getLang(lang, 'label.BETTER'),
                        getLang(lang, 'label.THE_SAME'),
                        getLang(lang, 'label.WORSE'),
                        getLang(lang, 'label.NOT_SURE')
                    ],
                    required: true,
                    placeholder: ""
                },
                {
                    id: uuidv4(),
                    text: getLang(lang, 'paragraph.MARKETING_SURVEY_QUESTION_D'),
                    type: surveyQuestionType.selection,
                    answers: [
                        getLang(lang, 'label.VERY_LIKELY'),
                        getLang(lang, 'label.MAYBE'),
                        getLang(lang, 'label.UNLIKELY')
                    ],
                    required: true,
                    placeholder: ""
                },
                {
                    id: uuidv4(),
                    text: getLang(lang, 'paragraph.MARKETING_SURVEY_QUESTION_E'),
                    type: surveyQuestionType.selection,
                    answers: [
                        getLang(lang, 'label.VERY_LIKELY'),
                        getLang(lang, 'label.MAYBE'),
                        getLang(lang, 'label.UNLIKELY')
                    ],
                    required: true,
                    placeholder: ""
                },
                {
                    id: uuidv4(),
                    text: getLang(lang, 'paragraph.MARKETING_SURVEY_QUESTION_F'),
                    type: surveyQuestionType.text,
                    answers: null,
                    required: true,
                    placeholder: getLang(lang, 'placeholder.ENTER_ANSWER')
                },
                {
                    id: uuidv4(),
                    text: getLang(lang, 'paragraph.MARKETING_SURVEY_QUESTION_G'),
                    type: surveyQuestionType.text,
                    answers: null,
                    required: true,
                    placeholder: getLang(lang, 'placeholder.ENTER_ANSWER')
                },
                {
                    id: uuidv4(),
                    text: getLang(lang, 'paragraph.MARKETING_SURVEY_QUESTION_H'),
                    type: surveyQuestionType.text,
                    answers: null,
                    required: true,
                    placeholder: getLang(lang, 'placeholder.ENTER_COMMENT_FEEDBACK')
                }
            ]
        }
    ]
}