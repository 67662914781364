export const ADMIN = "admin";
export const SESSION = "session";
export const APP = "app";

export const LABEL_PROFILE = 'user';
export const LABEL_COMPANY = 'company';
export const LABEL_SUBSCRIPTION_PAYMENT = 'plan';

export const COMPANY_STATUS_PENDING = 'pending';
export const COMPANY_STATUS_SUSPEND = 'suspended';
export const COMPANY_STATUS_REJECTED = 'rejected';
export const COMPANY_STATUS_APPROVED = 'approved';
export const COMPANY_STATUS_DEACTIVATED = 'deactivated';

export const LABEL_VALUE_VERIFIED = 'verified';
export const LABEL_VALUE_REJECTED = 'rejected';
export const LABEL_VALUE_CREATED = 'created';
