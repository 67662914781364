import getAllLoyaltyPoint from "./getAllLoyaltyPoint.api"
import addLoyaltyPoint from "./addLoyaltyPoint.api"
import getLoyaltyPoint from "./getLoyaltyPoint.api"
import updateLoyaltyPoint from "./updateLoyaltyPoint.api";
import updateLoyaltyPointStatus from "./updateLoyaltyPointStatus.api";
import getModuleDropdown from "./getModuleDropdown.api";

import getAllBonanzaPoint from "./getAllBonanzaPoint.api";
import addBonanzaPoint from "./addBonanzaPoint.api";
import getBonanzaPoint from "./getBonanzaPoint.api";
import updateBonanzaPoint from "./updateBonanzaPoint.api";
import updateBonanzaPointStatus from "./updateBonanzaPointStatus.api";

const LoyaltyPointApi = {
    getAllLoyaltyPoint,
    addLoyaltyPoint,
    getLoyaltyPoint,
    updateLoyaltyPoint,
    updateLoyaltyPointStatus,
    getModuleDropdown,

    getAllBonanzaPoint,
    addBonanzaPoint,
    getBonanzaPoint,
    updateBonanzaPoint,
    updateBonanzaPointStatus
};

export default LoyaltyPointApi;