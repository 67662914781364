import axios from "axios";

const getClusterSuspendSnSummary = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/analytic/api/v1/dashboard/get-suspended-scan", {
        start_date: payload.start_date,
        end_date: payload.end_date,
        longitude: payload.longitude,
        latitude: payload.latitude,
      })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data));
  });
};

export default getClusterSuspendSnSummary;
