import { createSlice } from "@reduxjs/toolkit";
import DefaultImg from "assets/img/defaultPhoto.png";
import {
  STOCKFLOWDEALER,
  getAllDealerListing,
  updateDealerStatus,
  getDealerDetails,
  getBranchDropdown,
  getDealerDropdown,
  getAdminBranchDropdown,
  getDealerIncentivePoint,
  getDealerRecallList,
  getDealerStockoutList,
  getDealerReceiveList,
  getDealerOrderList,
  getDealerRequestList,
  getDealerInventoryProductListing,
  getDealerInventoryPackageListing,
  getDealerInventoryBoxListing,
  getDealerRewardList,
  getDealerPointHistoryList,
  getDealerPointExpiringList,
  getDealerInventoryLooseItemListing,
  getDealerInvitedTrending,
  getDealerInvitedSummary,
  getDealerInvitedList,
  getTotalStockoutTrending,
  getTotalRecallTrending,
  getStockoutByProduct,
  getBranchDropdownSpecial
} from "../action/stockflowDealer.action";

const initialState = {
  dealerList: {
    totalRecords: 0,
    totalFiltered: 0,
    totalLoaded: 0,
    data: [],
    isError: false,
    isSelectAll: false,
    selectedUUIDs: [],
  },
  dealerDetails: {
    uuid: "",
    name: "",
    firstName: "",
    lastName: "",
    email: "",
    tier: 0,
    status: "",
    branch: null,
    joinedAt: null,
    lastLoginAt: null,
    upline: null,
    downlineCount: 0,
    picture: null,
    phoneCode: "",
    phoneNumber: "",
    dateOfBirth: null,
    gender: "",
    locale: "",
    addresses: null,
    incentivePoint: 0,
    newDealerFlag: false,
    isError: false,
  },
  incentivePoint: {
    uuid: "",
    tier: 0,
    picture: "",
    name: "",
    pointBalance: 0,
    isError: false,
  },
  dropdowns: {
    branchDropdownData: [],
    dealerListDropdownData: [],
  },
  stockoutList: {
    type: "new",
    list: {
      new: {
        list: [],
        totalFiltered: 0,
        totalRecords: 0,
      },
      accepted: {
        list: [],
        totalFiltered: 0,
        totalRecords: 0,
      },
      rejected: {
        list: [],
        totalFiltered: 0,
        totalRecords: 0,
      },
      cancelled: {
        list: [],
        totalFiltered: 0,
        totalRecords: 0,
      },
      isError: false,
    },
  },
  recallList: {
    list: [],
    totalFiltered: 0,
    totalRecords: 0,
    isError: false,
  },
  receiveList: {
    type: "new",
    list: {
      new: {
        list: [],
        totalFiltered: 0,
        totalRecords: 0,
      },
      accepted: {
        list: [],
        totalFiltered: 0,
        totalRecords: 0,
      },
      rejected: {
        list: [],
        totalFiltered: 0,
        totalRecords: 0,
      },
      cancelled: {
        list: [],
        totalFiltered: 0,
        totalRecords: 0,
      },
      isError: false,
    },
  },
  orderList: {
    type: "new",
    list: {
      new: {
        list: [],
        totalFiltered: 0,
        totalRecords: 0,
      },
      accepted: {
        list: [],
        totalFiltered: 0,
        totalRecords: 0,
      },
      rejected: {
        list: [],
        totalFiltered: 0,
        totalRecords: 0,
      },
      cancelled: {
        list: [],
        totalFiltered: 0,
        totalRecords: 0,
      },
      isError: false,
    },
  },
  requestList: {
    type: "new",
    list: {
      new: {
        list: [],
        totalFiltered: 0,
        totalRecords: 0,
      },
      accepted: {
        list: [],
        totalFiltered: 0,
        totalRecords: 0,
      },
      rejected: {
        list: [],
        totalFiltered: 0,
        totalRecords: 0,
      },
      cancelled: {
        list: [],
        totalFiltered: 0,
        totalRecords: 0,
      },
      isError: false,
    },
  },
  inventoryProductList: {
    list: [],
    totalFiltered: 0,
    totalRecords: 0,
    isError: false,
  },
  inventoryPackageList: {
    list: [],
    totalFiltered: 0,
    totalRecords: 0,
    isError: false,
  },
  inventoryBoxList: {
    list: [],
    totalFiltered: 0,
    totalRecords: 0,
    isError: false,
  },
  inventoryLooseItemList: {
    list: [],
    totalFiltered: 0,
    totalRecords: 0,
    isError: false,
  },
  rewardList: {
    list: [],
    totalFiltered: 0,
    totalRecords: 0,
    isError: false,
  },
  pointHistoryList: {
    list: [],
    totalFiltered: 0,
    totalRecords: 0,
    isError: false,
  },
  pointExpiringList: {
    list: [],
    totalFiltered: 0,
    totalRecords: 0,
    isError: false,
  },
  dashboard: {
    totalStockoutTrend: {
      isSuccess: true,
      noResult: true,
      datasets: [],
      labels: []
    },
    totalRecallTrend: {
      isSuccess: true,
      noResult: true,
      datasets: [],
      labels: []
    },
    totalDealerInvitedTrend: {
      isSuccess: true,
      noResult: true,
      datasets: [],
      labels: []
    },
    dealerInvited: {
      chartData: {
        data: null,
        isError: false,
      },
      list: {
        list: [],
        totalFiltered: 0,
        totalRecords: 0,
        isError: false,
      },
    },
    stockoutByProduct: {
      isSuccess: true,
      noResult: true,
      rows: [],
      total_record: 0
    }
  },
};

const stockflowDealerSlice = createSlice({
  name: STOCKFLOWDEALER,
  initialState,
  reducers: {
    resetDealerList(state) {
      state.dealerList = initialState.dealerList;
    },
    resetDealerListData(state) {
      state.dealerList.data = initialState.dealerList.data;
    },
    setDealerFields(state, action) {
      let { payload } = action;
      state.dealerDetails.name = `${payload.firstName} ${payload.lastName}`;
      state.dealerDetails.firstName = payload.firstName;
      state.dealerDetails.lastName = payload.lastName;
      state.dealerDetails.phoneCode = payload.phoneCode;
      state.dealerDetails.phoneNumber = payload.phoneNumber;
      state.dealerDetails.gender = payload.gender;
      state.dealerDetails.dateOfBirth = payload.dateOfBirth;
    },
    resetDealerDetails(state) {
      state.dealerDetails = initialState.dealerDetails;
    },
    resetBranchDropdownData(state) {
      state.dropdowns.branchDropdownData =
        initialState.dropdowns.branchDropdownData;
    },
    resetDealerListDropdownData(state) {
      state.dropdowns.dealerListDropdownData =
        initialState.dropdowns.dealerListDropdownData;
    },
    resetDealerIncentivePoint(state) {
      state.incentivePoint = initialState.incentivePoint;
    },
    changeBulkSelectedAllDealer(state, action) {
      const { payload } = action;

      state.dealerList.data.forEach((dealer) => {
        dealer.isSelected = payload.value;
      });
      state.dealerList.isSelectAll = payload.value;
      state.dealerList.selectedUUIDs = [];
    },
    changeBulkSelectedAvailableDealer(state, action) {
      const { payload } = action;

      state.dealerList.data.forEach((dealer) => {
        dealer.isSelected = payload.value;
        if (
          (!state.dealerList.isSelectAll && payload.value) ||
          (state.dealerList.isSelectAll && !payload.value)
        ) {
          if (
            state.dealerList.selectedUUIDs.findIndex(
              (uuid) => uuid === dealer.uuid
            ) === -1
          ) {
            state.dealerList.selectedUUIDs = [
              ...state.dealerList.selectedUUIDs,
              dealer.uuid,
            ];
          }
        } else {
          state.dealerList.selectedUUIDs =
            state.dealerList.selectedUUIDs.filter(
              (uuid) => uuid !== dealer.uuid
            );
        }
      });
    },
    changeSelectedDealer(state, action) {
      const { payload } = action;

      state.dealerList.data[payload.index].isSelected = payload.isSelected;
      if (
        (!state.dealerList.isSelectAll && payload.isSelected) ||
        (state.dealerList.isSelectAll && !payload.isSelected)
      ) {
        state.dealerList.selectedUUIDs = [
          ...state.dealerList.selectedUUIDs,
          state.dealerList.data[payload.index].uuid,
        ];
      } else {
        state.dealerList.selectedUUIDs = state.dealerList.selectedUUIDs.filter(
          (uuid) => uuid !== state.dealerList.data[payload.index].uuid
        );
      }
    },
    resetDealerStockoutListing(state) {
      state.stockoutList.list = initialState.stockoutList.list;
    },
    updateStockoutListTab(state, action) {
      state.stockoutList.type = action.payload;
    },
    resetDealerRecallListing(state) {
      state.recallList = initialState.recallList;
    },
    resetDealerReceiveListing(state) {
      state.receiveList.list = initialState.receiveList.list;
    },
    updateReceiveListTab(state, action) {
      state.receiveList.type = action.payload;
    },
    resetDealerOrderListing(state) {
      state.orderList.list = initialState.orderList.list;
    },
    updateOrderListTab(state, action) {
      state.orderList.type = action.payload;
    },
    resetDealerRequestListing(state) {
      state.requestList.list = initialState.requestList.list;
    },
    updateRequestListTab(state, action) {
      state.requestList.type = action.payload;
    },
    resetDealerInventoryListing(state) {
      state.inventoryProductList = initialState.inventoryProductList;
      state.inventoryPackageList = initialState.inventoryPackageList;
      state.inventoryBoxList = initialState.inventoryBoxList;
      state.inventoryLooseItemList = initialState.inventoryLooseItemList;
    },
    resetDealerRewardListing(state) {
      state.rewardList = initialState.rewardList;
    },
    resetDealerPointHistoryListing(state) {
      state.pointHistoryList = initialState.pointHistoryList;
    },
    resetDealerPointExpiringListing(state) {
      state.pointExpiringList = initialState.pointExpiringList;
    },
    resetDealerDownlineListing(state) {
      state.dealerList = initialState.dealerList;
    },
    resetDashboard(state) {
      state.dashboard.totalStockoutTrend = initialState.dashboard.totalStockoutTrend
      state.dashboard.totalRecallTrend = initialState.dashboard.totalRecallTrend
      state.dashboard.totalDealerInvitedTrend = initialState.dashboard.totalDealerInvitedTrend
      state.dashboard.stockoutByProduct = initialState.dashboard.stockoutByProduct
      state.dashboard.dealerInvited.chartData = initialState.dashboard.dealerInvited.chartData
      state.dashboard.dealerInvited.list = initialState.dashboard.dealerInvited.list
    },
  },
  extraReducers: {
    [getAllDealerListing.pending]: (state) => {
      state.dealerList = initialState.dealerList;
    },
    [getAllDealerListing.fulfilled]: (state, action) => {
      const { payload } = action;
      const temp = [];
      payload.data.forEach((item) => {
        let selectedState = !state.dealerList.isSelectAll
          ? state.dealerList.selectedUUIDs.findIndex(
              (uuid) => uuid === item.uuid
            ) !== -1
          : state.dealerList.selectedUUIDs.findIndex(
              (uuid) => uuid === item.uuid
            ) === -1;
        temp.push({
          uuid: item.uuid,
          email: item.email,
          name: item.name,
          picture: item.picture ?? DefaultImg,
          tier: item.tier,
          branchName: item.branch_name,
          upline: item.upline
            ? {
                uuid: item.upline.uuid,
                name: item.upline.name,
                picture: item.upline.picture ?? DefaultImg,
                tier: item.upline.tier,
              }
            : null,
          downlineCount: item.downline_count,
          status: item.status,
          lastActiveDate: item.last_active_date,
          isSelected: selectedState,
          incentivePoint: item.incentive_point,
          emailVerificationLink: item.email_verification_link,
        });
      });
      state.dealerList.data = temp;
      state.dealerList.totalRecords = payload.recordsTotal;
      state.dealerList.totalFiltered = payload.recordsFiltered;
      state.dealerList.totalLoaded = payload.recordsLoaded;
      state.dealerList.isError = false;
    },
    [getAllDealerListing.rejected]: (state) => {
      state.dealerList.data = [];
      state.dealerList.totalRecords = 0;
      state.dealerList.totalFiltered = 0;
      state.dealerList.totalLoaded = 0;
      state.dealerList.isError = true;
    },
    [updateDealerStatus.fulfilled]: (state, action) => {
      const payload = action.payload.data;
      const index = state.dealerList.data.findIndex(
        ({ uuid }) => uuid === payload.uuid
      );
      if (index > -1) {
        state.dealerList.data[index].status = payload.status.toLowerCase();
      }
    },
    [getDealerDetails.pending]: (state) => {
      state.dealerDetails = initialState.dealerDetails;
    },
    [getDealerDetails.rejected]: (state) => {
      state.dealerDetails = {
        ...initialState.dealerDetails,
        isError: true,
      };
    },
    [getDealerDetails.fulfilled]: (state, action) => {
      const payload = action.payload.data.data;
      state.dealerDetails.uuid = payload.uuid;
      state.dealerDetails.name = payload.name;
      state.dealerDetails.firstName = payload.first_name;
      state.dealerDetails.lastName = payload.last_name;
      state.dealerDetails.email = payload.email;
      state.dealerDetails.tier = payload.tier;
      state.dealerDetails.status = payload.status;
      state.dealerDetails.branch = payload.branch
        ? {
            uuid: payload.branch.uuid,
            name: payload.branch.name,
          }
        : null;
      state.dealerDetails.joinedAt = payload.joined_at;
      state.dealerDetails.lastActiveDate = payload.last_active_date;
      state.dealerDetails.upline = payload.upline
        ? {
            uuid: payload.upline.uuid,
            name: payload.upline.name,
            picture: payload.upline.picture ?? DefaultImg,
            tier: payload.upline.tier,
            downlineCount: payload.upline.downline_count,
          }
        : null;
      state.dealerDetails.downlineCount = payload.downline_count;
      state.dealerDetails.picture = payload.picture ?? DefaultImg;
      state.dealerDetails.phoneCode = payload.phone_code;
      state.dealerDetails.phoneNumber = payload.phone_number;
      state.dealerDetails.dateOfBirth = payload.date_of_birth;
      state.dealerDetails.gender = payload.gender;
      state.dealerDetails.locale = payload.locale;
      state.dealerDetails.addresses = payload.addresses
        ? {
            uuid: payload.addresses.uuid,
            address: payload.addresses.address,
            isDefault: payload.addresses.is_default,
          }
        : null;
      state.dealerDetails.incentivePoint = payload.incentive_point;
      state.dealerDetails.newDealerFlag = payload.new_dealer_flag;
      state.isError = false;
    },
    [getBranchDropdown.fulfilled]: (state, action) => {
      const payload = action.payload.data;

      let temp = [];
      payload.map((item, index) => {
        temp.push({
          nodeId: item.node_id,
          name: item.name,
          invitationCode: item.distributor_invitation_code,
          searchName: item.name.toLowerCase(),
          index: index,
        });
        return null;
      });
      state.dropdowns.branchDropdownData = temp;
    },
    [getBranchDropdownSpecial.fulfilled]: (state, action) => {
      const payload = action.payload.data;

      let temp = [];
      payload.map((item, index) => {
        temp.push({
          nodeId: item.node_id,
          name: item.name,
          invitationCode: item.distributor_invitation_code,
          searchName: item.name.toLowerCase(),
          index: index,
        });
        return null;
      });
      state.dropdowns.branchDropdownData = temp;
    },
    [getAdminBranchDropdown.fulfilled]: (state, action) => {
      const payload = action.payload.data;

      let temp = [];
      payload.map((item, index) => {
        temp.push({
          nodeId: item.node_id,
          name: item.name,
          invitationCode: item.distributor_invitation_code,
          searchName: item.name.toLowerCase(),
          index: index,
        });
        return null;
      });
      state.dropdowns.branchDropdownData = temp;
    },
    [getDealerDropdown.fulfilled]: (state, action) => {
      const payload = action.payload.data;

      let temp = [];
      payload.map((item, index) => {
        temp.push({
          uuid: item.uuid,
          name: item.name,
          status: item.status,
          tier: item.tier,
          nodeUuid: item.node_uuid,
          downlineCount: item.downline_count,
          lastActiveDate: item.last_active_date,
          invitationCode: item.invitation_code,
          picture: item.picture ?? DefaultImg,
          searchName: item.name.toLowerCase(),
          index: index,
        });
        return null;
      });
      state.dropdowns.dealerListDropdownData = temp;
    },
    [getDealerIncentivePoint.pending]: (state) => {
      state.incentivePoint = initialState.incentivePoint;
    },
    [getDealerIncentivePoint.fulfilled]: (state, action) => {
      const payload = action.payload.data.data;

      state.incentivePoint.uuid = payload.uuid;
      state.incentivePoint.name = payload.name;
      state.incentivePoint.tier = payload.tier;
      state.incentivePoint.picture = payload.picture ?? DefaultImg;
      state.incentivePoint.pointBalance = payload.point_balance;
      state.incentivePoint.isError = false;
    },
    [getDealerIncentivePoint.isError]: (state) => {
      state.incentivePoint.isError = true;
    },
    [getDealerStockoutList.pending]: (state) => {
      state.stockoutList.list[state.stockoutList.type] =
        initialState.stockoutList.list[state.stockoutList.type];
      state.stockoutList.list.isError = false;
    },
    [getDealerStockoutList.fulfilled]: (state, action) => {
      const { payload } = action;
      state.stockoutList.list[state.stockoutList.type].list = payload.data;
      state.stockoutList.list[state.stockoutList.type].totalRecords =
        payload.recordsTotal;
      state.stockoutList.list[state.stockoutList.type].totalFiltered =
        payload.recordsFiltered;
      state.stockoutList.list.isError = false;
    },
    [getDealerStockoutList.rejected]: (state) => {
      state.stockoutList.list.isError = true;
    },
    [getDealerRecallList.pending]: (state) => {
      state.recallList = initialState.recallList;
    },
    [getDealerRecallList.fulfilled]: (state, action) => {
      const { payload } = action;
      state.recallList.list = payload.data;
      state.recallList.totalRecords = payload.recordsTotal;
      state.recallList.totalFiltered = payload.recordsFiltered;
      state.recallList.isError = false;
    },
    [getDealerRecallList.rejected]: (state) => {
      state.recallList.isError = true;
    },
    [getDealerReceiveList.pending]: (state) => {
      state.receiveList.list[state.receiveList.type] =
        initialState.receiveList.list[state.receiveList.type];
      state.receiveList.list.isError = false;
    },
    [getDealerReceiveList.fulfilled]: (state, action) => {
      const { payload } = action;
      state.receiveList.list[state.receiveList.type].list = payload.data;
      state.receiveList.list[state.receiveList.type].totalRecords =
        payload.recordsTotal;
      state.receiveList.list[state.receiveList.type].totalFiltered =
        payload.recordsFiltered;
      state.receiveList.list.isError = false;
    },
    [getDealerReceiveList.rejected]: (state) => {
      state.receiveList.list.isError = true;
    },
    [getDealerOrderList.pending]: (state) => {
      state.orderList.list[state.orderList.type] =
        initialState.orderList.list[state.orderList.type];
      state.orderList.list.isError = false;
    },
    [getDealerOrderList.fulfilled]: (state, action) => {
      const { payload } = action;
      state.orderList.list[state.orderList.type].list = payload.data;
      state.orderList.list[state.orderList.type].totalRecords =
        payload.recordsTotal;
      state.orderList.list[state.orderList.type].totalFiltered =
        payload.recordsFiltered;
      state.orderList.list.isError = false;
    },
    [getDealerOrderList.rejected]: (state) => {
      state.orderList.list.isError = true;
    },
    [getDealerRequestList.pending]: (state) => {
      state.requestList.list[state.requestList.type] =
        initialState.requestList.list[state.requestList.type];
      state.requestList.list.isError = false;
    },
    [getDealerRequestList.fulfilled]: (state, action) => {
      const { payload } = action;
      state.requestList.list[state.requestList.type].list = payload.data;
      state.requestList.list[state.requestList.type].totalRecords =
        payload.recordsTotal;
      state.requestList.list[state.requestList.type].totalFiltered =
        payload.recordsFiltered;
      state.requestList.list.isError = false;
    },
    [getDealerRequestList.rejected]: (state) => {
      state.requestList.list.isError = true;
    },
    [getDealerInventoryProductListing.pending]: (state) => {
      state.inventoryProductList = initialState.inventoryProductList;
    },
    [getDealerInventoryProductListing.fulfilled]: (state, action) => {
      const { payload } = action;
      const temp = [];
      payload.data.forEach((item) => {
        temp.push({
          id: item.id,
          uuid: item.uuid,
          picture: item.picture ?? DefaultImg,
          name: item.name,
          quantity: item.quantity,
          attributes: item.attributes,
        });
      });
      state.inventoryProductList.list = temp;
      state.inventoryProductList.totalRecords = payload.recordsTotal;
      state.inventoryProductList.totalFiltered = payload.recordsFiltered;
    },
    [getDealerInventoryProductListing.rejected]: (state) => {
      state.inventoryProductList.isError = true;
    },
    [getDealerInventoryPackageListing.pending]: (state) => {
      state.inventoryPackageList = initialState.inventoryPackageList;
    },
    [getDealerInventoryPackageListing.fulfilled]: (state, action) => {
      const { payload } = action;
      const temp = [];
      payload.data.forEach((item) => {
        temp.push({
          id: item.uuid,
          sequence_no: item.sequence_no,
          serial_number: item.serial_number,
          picture: item.product?.picture ?? DefaultImg,
          name: item.product?.name || "",
          quantity: item.total_loose_item_count,
          status: item.status,
          created_at: item.created_at,
          attributes: item.product?.attributes || [],
        });
      });
      state.inventoryPackageList.list = temp;
      state.inventoryPackageList.totalRecords = payload.recordsTotal;
      state.inventoryPackageList.totalFiltered = payload.recordsFiltered;
    },
    [getDealerInventoryPackageListing.rejected]: (state) => {
      state.inventoryPackageList.isError = true;
    },
    [getDealerInventoryBoxListing.pending]: (state) => {
      state.inventoryBoxList = initialState.inventoryBoxList;
    },
    [getDealerInventoryBoxListing.fulfilled]: (state, action) => {
      const { payload } = action;
      const temp = [];
      payload.data.forEach((item) => {
        temp.push({
          ...item,
          id: item.uuid,
        });
      });
      state.inventoryBoxList.list = temp;
      state.inventoryBoxList.totalRecords = payload.recordsTotal;
      state.inventoryBoxList.totalFiltered = payload.recordsFiltered;
    },
    [getDealerInventoryBoxListing.rejected]: (state) => {
      state.inventoryBoxList.isError = true;
    },
    [getDealerInventoryLooseItemListing.pending]: (state) => {
      state.inventoryLooseItemList = initialState.inventoryLooseItemList;
    },
    [getDealerInventoryLooseItemListing.fulfilled]: (state, action) => {
      const { payload } = action;
      state.inventoryLooseItemList.list = payload.data;
      state.inventoryLooseItemList.totalRecords = payload.recordsTotal;
      state.inventoryLooseItemList.totalFiltered = payload.recordsFiltered;
    },
    [getDealerInventoryLooseItemListing.rejected]: (state) => {
      state.inventoryLooseItemList.isError = true;
    },
    [getDealerRewardList.pending]: (state) => {
      state.rewardList = initialState.rewardList;
    },
    [getDealerRewardList.fulfilled]: (state, action) => {
      const { payload } = action;
      state.rewardList.list = payload.data;
      state.rewardList.totalRecords = payload.recordsTotal;
      state.rewardList.totalFiltered = payload.recordsFiltered;
      state.rewardList.isError = false;
    },
    [getDealerRewardList.rejected]: (state) => {
      state.rewardList.list.isError = true;
    },
    [getDealerPointHistoryList.pending]: (state) => {
      state.pointHistoryList = initialState.pointHistoryList;
    },
    [getDealerPointHistoryList.fulfilled]: (state, action) => {
      const { payload } = action;
      state.pointHistoryList.list = payload.data;
      state.pointHistoryList.totalRecords = payload.recordsTotal;
      state.pointHistoryList.totalFiltered = payload.recordsFiltered;
      state.pointHistoryList.isError = false;
    },
    [getDealerPointHistoryList.rejected]: (state) => {
      state.pointHistoryList.isError = true;
    },
    [getDealerPointExpiringList.pending]: (state) => {
      state.pointExpiringList = initialState.pointExpiringList;
    },
    [getDealerPointExpiringList.fulfilled]: (state, action) => {
      const { payload } = action;
      state.pointExpiringList.list = payload.data;
      state.pointExpiringList.totalRecords = payload.recordsTotal;
      state.pointExpiringList.totalFiltered = payload.recordsFiltered;
      state.pointExpiringList.isError = false;
    },
    [getDealerPointExpiringList.rejected]: (state) => {
      state.pointExpiringList.isError = true;
    },
    [getDealerInvitedSummary.pending]: (state) => {
      state.dashboard.dealerInvited.chartData =
        initialState.dashboard.dealerInvited.chartData;
    },
    [getDealerInvitedSummary.fulfilled]: (state, action) => {
      const { payload } = action;
      state.dashboard.dealerInvited.chartData.data = {
        ...payload,
        isSuccess: true
      };
      state.dashboard.dealerInvited.chartData.isError = false;
    },
    [getDealerInvitedSummary.rejected]: (state) => {
      state.dashboard.dealerInvited.chartData.isError = true;
    },
    [getDealerInvitedList.pending]: (state) => {
      state.dashboard.dealerInvited.list = initialState.dashboard.dealerInvited.list;
    },
    [getDealerInvitedList.fulfilled]: (state, action) => {
      const { payload } = action;
      state.dashboard.dealerInvited.list.list = payload.data;
      state.dashboard.dealerInvited.list.totalRecords = payload.recordsTotal;
      state.dashboard.dealerInvited.list.totalFiltered = payload.recordsFiltered;
      state.dashboard.dealerInvited.list.isError = false;
    },
    [getDealerInvitedList.rejected]: (state) => {
      state.dashboard.dealerInvited.list.isError = true;
    },
    [getTotalStockoutTrending.pending]: (state) => {
      state.dashboard.totalStockoutTrend = initialState.dashboard.totalStockoutTrend;
    },
    [getTotalStockoutTrending.fulfilled]: (state, action) => {
      const { payload } = action;
      state.dashboard.totalStockoutTrend = {
        isSuccess: true,
        noResult: false,
        ...payload,
      };
    },
    [getTotalStockoutTrending.rejected]: (state) => {
      state.dashboard.totalStockoutTrend = {
        isSuccess: false,
        noResult: true,
        datasets: [],
        labels: []
      };
    },
    [getTotalRecallTrending.pending]: (state) => {
      state.dashboard.totalRecallTrend = initialState.dashboard.totalRecallTrend;
    },
    [getTotalRecallTrending.fulfilled]: (state, action) => {
      const { payload } = action;
      state.dashboard.totalRecallTrend = {
        isSuccess: true,
        noResult: false,
        ...payload,
      };
    },
    [getTotalRecallTrending.rejected]: (state) => {
      state.dashboard.totalRecallTrend = {
        isSuccess: false,
        noResult: true,
        datasets: [],
        labels: []
      };
    },
    [getDealerInvitedTrending.pending]: (state) => {
      state.dashboard.totalDealerInvitedTrend = initialState.dashboard.totalDealerInvitedTrend;
    },
    [getDealerInvitedTrending.fulfilled]: (state, action) => {
      const { payload } = action;
      state.dashboard.totalDealerInvitedTrend = {
        isSuccess: true,
        noResult: false,
        ...payload,
      };
    },
    [getDealerInvitedTrending.rejected]: (state) => {
      state.dashboard.totalRecallTrend = {
        isSuccess: false,
        noResult: true,
        datasets: [],
        labels: []
      };
    },
    [getDealerInvitedTrending.pending]: (state) => {
      state.dashboard.stockoutByProduct = initialState.dashboard.stockoutByProduct;
    },
    [getStockoutByProduct.fulfilled]: (state, action) => {
      const { payload } = action
      state.dashboard.stockoutByProduct = {
        ...payload,
        isSuccess: true,
        noResult: !payload.rows.length
      };
    },
    [getStockoutByProduct.rejected]: (state, action) => {
      state.dashboard.stockoutByProduct = {
        isSuccess: false,
        noResult: true,
        rows: [],
        total_record: 0,
      };
    },
  },
});

export const {
  resetDealerList,
  resetDealerListData,
  setDealerFields,
  resetDealerDetails,
  resetBranchDropdownData,
  resetDealerListDropdownData,
  resetDealerIncentivePoint,
  changeBulkSelectedAvailableDealer,
  changeBulkSelectedAllDealer,
  changeSelectedDealer,
  resetDealerStockoutListing,
  resetDealerRecallListing,
  updateStockoutListTab,
  resetDealerReceiveListing,
  updateReceiveListTab,
  resetDealerOrderListing,
  updateOrderListTab,
  resetDealerRequestListing,
  updateRequestListTab,
  resetDealerInventoryListing,
  resetDealerRewardListing,
  resetDealerPointHistoryListing,
  resetDealerPointExpiringListing,
  resetDealerDownlineListing,
  resetDashboard,
} = stockflowDealerSlice.actions;

export const stockflowDealerReducer = stockflowDealerSlice.reducer;
