import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { ROOT as Home } from "modules/dashboard";

const PublicRoute = ({ component: Component, restricted = true, isAuthenticated, ...props }) => {

    return (
        // restricted = false meaning public route
        // restricted = true meaning restricted route
        <Route {...props} render={props => (
            isAuthenticated && restricted ?
                <Redirect to={Home} />
                : <Component {...props} />
        )} />
    );
};

const mapStateToProps = state => ({
    isAuthenticated: state.session.accessToken !== '' ? true : false
});

export default connect(mapStateToProps, null)(PublicRoute);