import React from "react";
import {
  makeStyles,
  Box,
  Grid,
  Button,
  Divider,
  Typography,
  IconButton
} from "@material-ui/core";
import {
  AddImage,
  ErrorSVG,
  EditSVG,
  BlankPrizeLabel
} from "../../../utils/static";
import { Formik } from "formik";
import InputTextField from "components/input/inputTextField";
import BlankPrizeBg from "../../../utils/image/blankPrize.png";
import ThankYouImage from "../../../utils/image/thank_you.png";

import { isFile } from "lib/helper";
import { getLang } from "app/feature/constants";

const style = (theme) => ({
  boxContainer: {
    border: "3px solid #6AAF68",
    margin: theme.spacing(3),
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px"
  },
  photoContainer: {
    background: "#EBEDEE",
    border: "1px solid rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
    width: "120px",
    height: "120px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden"
  },
  button: {
    width: "100%",
    height: "100%"
  },
  label: {
    display: "flex",
    position: "absolute",
    right: "25px",
    zIndex: "9"
  },
  bottomContainer: {
    position: "relative",
    background: theme.palette.common.white,
    marginTop: theme.spacing(9)
  },
  displayMessage: {
    background: theme.palette.primary.main,
    borderRadius: "100%",
    height: "100px",
    width: "200px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    padding: theme.spacing(3),
    position: "absolute",
    margin: theme.spacing(2, 0),
    "&::before": {
      content: '""',
      position: "absolute",
      width: "0",
      height: "0",
      top: "30px",
      right: "-30px",
      border: "15px solid",
      borderColor: "transparent transparent transparent",
      borderLeftColor: theme.palette.primary.main,
      transform: "scaleX(2.5) rotateX(295deg) rotateY(0deg)"
    }
  },
  viewPrizeContainer: {
    border: "3px solid #6AAF68",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
    margin: theme.spacing(1, 3, 2, 3),
    display: "grid",
    gridTemplateColumns: "1fr 4.5fr 1.5fr"
  },
  viewPrizePhoto: {
    boxShadow: "1px 0px 0px rgba(0, 0, 0, 0.1)",
    width: 100,
    height: 100,
    display: "flex",
    alignItems: "center",
    padding: "4px",
    placeContent: "center"
  }
});

const useStyles = makeStyles(style);

export default function BlankPrizeCardComponent({
  hasInstantLuckyDrawEditAccessRight,
  handleSubmit,
  type,
  prizeDetail,
  inputImgRef,
  handleImgChange,
  changeToEdit,
  handleDisableEdit,
  AddingPrizeFlag,
  lang
}) {
  const classes = useStyles();

  const viewType = type === "view" ? true : false;
  return (
    <Formik
      initialValues={{
        prizeName: prizeDetail ? prizeDetail.prizeName : "",
        prizeImage: prizeDetail ? prizeDetail.prizeImage : ""
      }}
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <form onSubmit={formik.handleSubmit}>
          {viewType ? (
            <Box className={classes.viewPrizeContainer}>
              <Box className={classes.viewPrizePhoto}>
                <img
                  src={
                    prizeDetail.prizeImage
                      ? prizeDetail.prizeImage
                      : ThankYouImage
                  }
                  alt="example"
                  height="100%"
                  width="100%"
                  style={{ objectFit: "contain" }}
                />
              </Box>

              <Box p={2} width="100%">
                <Box display="flex" pt={1} pb={1}>
                  <Typography
                    variant="body2"
                    style={{ color: " rgba(58, 77, 84, 0.33)" }}
                  >
                    {getLang(lang, "label.PRIZE_NAME")}:&nbsp;
                  </Typography>
                  <Typography
                    variant="body2"
                    color="primary"
                    style={{ fontWeight: "bold" }}
                  >
                    {formik.values.prizeName}
                  </Typography>
                </Box>

                <Box display="flex">
                  <Typography
                    variant="body2"
                    style={{ color: " rgba(58, 77, 84, 0.33)" }}
                  >
                    {getLang(lang, "label.WINNER")}:&nbsp;
                  </Typography>
                  <Typography
                    variant="body2"
                    color="primary"
                    style={{ fontWeight: "bold" }}
                  >
                    {getLang(lang, "label.RANDOM")}
                  </Typography>
                </Box>
              </Box>

              <Box>
                <Typography
                  style={{
                    color: "#6AAF68",
                    fontSize: "12px",
                    fontWeight: "bold",
                    paddingTop: "1rem",
                    textAlign: "center",
                  }}
                >
                  {getLang(lang, "label.BLANK_PRIZE")}
                </Typography>
                <Box display="flex" p={3} px={2} justifyContent="flex-end">
                  {
                    hasInstantLuckyDrawEditAccessRight
                      ?
                      <IconButton style={{ padding: 0 }} onClick={changeToEdit} disabled={AddingPrizeFlag}>
                        { AddingPrizeFlag ? (
                          <EditSVG color="rgba(58, 77, 84, 0.33)"/>
                        ) : (
                          <EditSVG />
                        )}
                        
                        
                      </IconButton>
                      : null
                  }
                </Box>
              </Box>
            </Box>
          ) : (
            <Box className={classes.boxContainer}>
              <div className={classes.label}>
                <BlankPrizeLabel />
              </div>
              <Grid container spacing={1} style={{ padding: "24px" }}>
                <Grid item xs={3} sm={3} md={3}>
                  <Box>
                    <Typography variant="body2" color="primary">
                      {getLang(lang, "label.PRIZE_IMAGE")} <Typography style={{ color: "#DE350B", display: 'inline-block' }}>*</Typography>
                    </Typography>
                    <Box className={classes.photoContainer}>
                      <input
                        accept="image/*"
                        id="contained-button-file"
                        type="file"
                        ref={inputImgRef}
                        style={{ display: "none" }}
                        onChange={(e) => {
                          handleImgChange(formik, e)
                          e.target.value = null;
                        }}
                        
                      />
                      <label
                        htmlFor="contained-button-file"
                        style={{ height: "100%", width: "100%" }}
                      >
                        <Button
                          size="small"
                          className={classes.button}
                          disableElevation
                          component="span"
                          type="button"
                        >
                          {formik.values.prizeImage ? (
                            <img
                              src={isFile(formik.values.prizeImage) ? URL.createObjectURL(formik.values.prizeImage) : formik.values.prizeImage}
                              alt="prize"
                              height="100%"
                              width="100%"
                            />
                          ) : prizeDetail ? (
                            <img
                                src={ThankYouImage}
                                alt="prize"
                                height="100%"
                                width="100%"
                              />
                          ) : (
                            <AddImage />
                          )}
                        </Button>
                      </label>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={9} sm={9} md={9}>
                  <Typography variant="body2" color="primary">
                    {getLang(lang, "label.PRIZE_NAME")} <Typography style={{ color: "#DE350B", display: 'inline-block' }}>*</Typography>
                  </Typography>
                  <InputTextField
                    required
                    variant="filled"
                    size="small"
                    fullWidth
                    placeholder={getLang(lang, 'placeholder.ENTER_NAME')}
                    {...formik.getFieldProps("prizeName")}
                  />

                  <Box className={classes.displayMessage}>
                    <Typography variant="body2" style={{ color: "#fff" }}>
                      {getLang(lang, "paragraph.THIS_IS_THE")} <b>{getLang(lang, "paragraph.BLANK_PRIZE")}</b> {getLang(lang, "paragraph.FOR_PARTICIPANT")}
                    </Typography>
                  </Box>
                  <Box
                    style={{
                      position: "absolute",
                      display: "flex",
                      placeContent: "flex-end",
                      right: "0",
                    }}
                  >
                    <img src={BlankPrizeBg} alt="bg" width="70%" />
                  </Box>
                </Grid>
              </Grid>
              <div className={classes.bottomContainer}>
                <Divider />
                <Box px={3} pt={1} pb={1} display="flex" alignItems="center">
                  <ErrorSVG />
                  <Typography
                    variant="caption"
                    style={{
                      paddingLeft: "4px",
                      color: "rgba(58, 77, 84, 0.33)",
                    }}
                  >
                   {getLang(lang, 'paragraph.RECOMMENDED_IMAGE_SIZE')}
                  </Typography>
                </Box>
                <Divider />

                <Box
                  style={{
                    display: "flex",
                    padding: "10px",
                    justifyContent: "flex-end"
                  }}
                >
                  <Button
                    disableElevation
                    type="button"
                    style={{marginRight: "4px"}}
                    onClick={(e) => {
                      formik.resetForm()
                      handleDisableEdit(e)
                    }}
                  >
                    {getLang(lang, "label.CANCEL")}
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    disableElevation
                    type="submit"
                  >
                    {getLang(lang, "label.UPDATE")}
                  </Button>
                </Box>
              </div>
            </Box>
          )}
        </form>
      )}
    </Formik>
  );
}
