import React, { useEffect, useState } from "react";
import ParticipantPageComponent from "./participant.page";
import { useDispatch, useSelector } from "react-redux";
import { selectLoading } from "modules/notification";
import { hideBackdrop, showBackdrop } from "modules/backdrop";
import {
  fetchInstantLuckyDrawParticipantTable,
  resetParticipantTable
} from "modules/instant-lucky-draw/redux";
import { useHistory } from "react-router";
import getParticipantExcel from "app/api/instantLuckyDraw/getParticipantExcel.api";
import { INSTANT_LUCKY_DRAW_VIEW } from "lib/constants/accessRights";
import LoadingComponent from "components/loading";
import { generatePaginationOptions } from "lib/helper";
import { getLang } from "app/feature/constants";

function ParticipantPageContainer() {
  const hasInstantLuckyDrawViewAccessRight = useSelector(state => state.profile.accessRights.includes(INSTANT_LUCKY_DRAW_VIEW))
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const accessToken = useSelector((state) => state.session.accessToken);
  const totalRecords = useSelector(
    (state) => state.instantLuckyDraw.participantTable.filteredTotalRecords
  );
  const instantParticipantData = useSelector(
    (state) => state.instantLuckyDraw.participantTable.data
  );
  const isFetching = useSelector((state) =>
    selectLoading(state, fetchInstantLuckyDrawParticipantTable.typePrefix)
  );
  const lang = useSelector(state => state.constant.languages)
  const language = useSelector(state => state.profile.language)

  const [activeIndex, setActiveIndex] = useState(0);
  const [paginationOptions, setPaginationOptions] = useState([]);
  const tabLabels = [getLang(lang, 'label.INSTANT_LUCKY_DRAW_NAME')];
  const history = useHistory();
  const instantId = history.location.pathname.substring(
    history.location.pathname.lastIndexOf("/") + 1
  );

  useEffect(() => {
    if(!hasInstantLuckyDrawViewAccessRight){
      history.push("/admin/dashboard")
    }
  }, [history, hasInstantLuckyDrawViewAccessRight])

  useEffect(() => {
    setPaginationOptions(generatePaginationOptions(totalRecords))
  }, [totalRecords])

  useEffect(() => {
    dispatch(
      fetchInstantLuckyDrawParticipantTable({
        instantId,
        length: rowsPerPage,
        start: rowsPerPage * page,
        search: search
      })
    );

    return () => {
      dispatch(resetParticipantTable());
    };
  }, [dispatch, instantId, search, rowsPerPage, page]);

  if (!hasInstantLuckyDrawViewAccessRight) return <LoadingComponent />;

  const handleReload = () => {
    dispatch(
      fetchInstantLuckyDrawParticipantTable({
        instantId,
        length: rowsPerPage,
        start: rowsPerPage * page,
        search: search
      })
    );
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(event.target.value);
  };

  const handleSearch = (value) => {
    setPage(0);
    setSearch(value);
  };

  const handleChange = (event, index) => {
    setActiveIndex(index);
  };

  const handleExport = () => {
    dispatch(showBackdrop());
    getParticipantExcel
      .get({ id: instantId, token: accessToken })
      .finally(() => {
        dispatch(hideBackdrop());
        return;
      });
  };

  return (
    <ParticipantPageComponent
      instantParticipantData={instantParticipantData}
      isFetching={isFetching}
      totalRecords={totalRecords}
      page={page}
      search={search}
      rowsPerPage={rowsPerPage}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      handleSearch={handleSearch}
      handleChange={handleChange}
      language={language}
      activeIndex={activeIndex}
      tabLabels={tabLabels}
      handleExport={handleExport}
      handleReload={handleReload}
      paginationOptions={paginationOptions}
      lang={lang}
    />
  );
}

export default ParticipantPageContainer;
