import ThankyouImage from './image/demoPrizes/thankyou.png'
import CouponImage from './image/demoPrizes/coupon.png'
import GoldenEggImage from './image/demoPrizes/golden-egg.png'
import PresentBoxImage from './image/demoPrizes/present-box.png'
import { getLang } from 'app/feature/constants'

export default function demoPrizes({lang}) {
    return (
        [
           {
               title: getLang(lang,"label.GOLDEN_EGG"),
               image: GoldenEggImage
           },
           {
               title: getLang(lang,"label.THANK_YOU"),
               image: ThankyouImage
           },
           {
               title: getLang(lang,"label.PRESENT_BOX"),
               image: PresentBoxImage
           },
           {
               title: getLang(lang,"label.COUPON"),
               image: CouponImage
           },
           {
               title: getLang(lang,"label.THANK_YOU"),
               image: ThankyouImage
           },
           {
               title: getLang(lang,"label.PRESENT_BOX"),
               image: PresentBoxImage
           },
           {
               title: getLang(lang,"label.COUPON"),
               image: CouponImage
           },
           {
               title: getLang(lang,"label.THANK_YOU"),
               image: ThankyouImage
           }
       ]
    )
}