/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { useSelector } from "react-redux";
import RequestTransferReceiverPanelComponent from "./requestTransferReceiverPanel.component";
import RequestTransferAddressDialog from "../../dialog/requestTransferAddressDialog";

export default function RequestTransferReceiverPanelContainer({
  handleChange,
  receiver,
  branch,
}) {
  const lang = useSelector((state) => state.constant.languages);

  const [openDialog, setOpenDialog] = useState(false);

  return (
    <>
      <RequestTransferReceiverPanelComponent
        lang={lang}
        receiver={receiver}
        changeAddress={() => setOpenDialog(true)}
      />
      <RequestTransferAddressDialog
        isOpen={openDialog}
        handleClose={() => setOpenDialog(false)}
        receiver={receiver}
        updateReceiver={(addr) => handleChange(addr)}
        branch={branch}
      />
    </>
  );
}
