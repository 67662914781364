import React, { memo, useState, useEffect, useCallback, useRef } from "react";
import ProductTraceabilityMapComponent from "./productTraceabilityMap.component";
import { useLoadScript } from "@react-google-maps/api";
import { useSelector } from "react-redux";

const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

function ProductTraceabilityMapContainer({
  traceabilityList,
  recordFocus,
  updateRecordFocus,
}) {
  const productColorList = useSelector(
    (state) => state.consumer.traceabilityList.productColor
  );
  const locale = useSelector((state) => state.profile.language);

  const [map, setMap] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(5);
  const [center, setCenter] = useState({
    lat: 3.139,
    lng: 101.6869,
  });

  const initialZoomRef = useRef(5);
  const hasInitialZoomRef = useRef(false);
  const initialCenterRef = useRef({
    lat: 3.139,
    lng: 101.6869,
  });

  const { isLoaded } = useLoadScript({
    id: "google-map-script",
    googleMapsApiKey: API_KEY,
    language: locale,
  });

  useEffect(() => {
    if (recordFocus?.latitude && recordFocus?.longitude) {
      setCenter({
        lat: parseFloat(recordFocus.latitude),
        lng: parseFloat(recordFocus.longitude),
      });
      setZoomLevel(15);
    } else {
      setCenter(initialCenterRef.current);
      setZoomLevel(initialZoomRef.current);
    }
  }, [recordFocus]);

  const updateZoomLevel = () => {
    if (map) {
      let tempZoom = map.getZoom();
      if (!hasInitialZoomRef.current) {
        tempZoom = tempZoom > 15 ? 15 : tempZoom;
        initialZoomRef.current = tempZoom > 15 ? 15 : tempZoom;

        let tempCenter = map.getCenter();
        initialCenterRef.current = {
          lat: tempCenter.lat(),
          lng: tempCenter.lng(),
        };
        hasInitialZoomRef.current = true;
      }
      setZoomLevel(tempZoom);
    }
  };

  const onLoad = useCallback(function callback(map) {
    map.setOptions({
      styles: [
        {
          featureType: "poi",
          elementType: "labels.icon",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
      ],
    });
    let newBoundary = new window.google.maps.LatLngBounds();
    let hasScanHistory = false;
    traceabilityList.forEach((item) => {
      if (item.user_type === "Consumer" && item.latitude && item.longitude) {
        newBoundary.extend({
          lat: parseFloat(item.latitude),
          lng: parseFloat(item.longitude),
        });
        hasScanHistory = true;
      }
    });
    if (hasScanHistory) {
      map.fitBounds(newBoundary);
    }
    setMap(map);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  return (
    <>
      <ProductTraceabilityMapComponent
        list={traceabilityList}
        isLoaded={isLoaded}
        productColorList={productColorList}
        center={center}
        zoomLevel={zoomLevel}
        recordFocus={recordFocus}
        updateRecordFocus={updateRecordFocus}
        updateZoomLevel={updateZoomLevel}
        onLoad={onLoad}
        onUnmount={onUnmount}
      />
    </>
  );
}

export default memo(ProductTraceabilityMapContainer);
