export const ROOT = "/admin/reporting";

// QR Report Page
export const REPORTING__QR_PAGE = `${ROOT}/QR-report`;
export const REPORTING_DRILLDOWN_QR_TO_COUNTRY_CITY_BRANCH = `${REPORTING__QR_PAGE}/country-city-branch`;
export const REPORTING_DRILLDOWN_TO_COUNTRY_CITY_BRANCH_PRODUCT = `${ROOT}/country-city-branch-product`;
export const REPORTING_DRILLDOWN_QR_TO_CCB_PRODUCT_CAMPAIGN = `${REPORTING__QR_PAGE}/country-city-branch-product-campaign`;


export const REPORTING_DRILLDOWN_QR_TO_COUNTRY_CITY_BRANCH_CAMPAIGN = `${REPORTING__QR_PAGE}/country-city-branch-campaign`;
export const REPORTING__PRODUCT_PAGE = `${ROOT}/product-report`;


// Product Category Report Page
export const REPORTING__PRODUCT_CATEGORY_PAGE = `${ROOT}/product-category-report`;
export const REPORTING_DRILLDOWN_PRODUCT_CATEGORY_TO_CCB = `${REPORTING__PRODUCT_CATEGORY_PAGE}/country-city-branch`;
export const REPORTING_DRILLDOWN_PRODUCT_CATEGORY_TO_CATEGORY = `${REPORTING__PRODUCT_CATEGORY_PAGE}/category`;

// Product Brand Report Page
export const REPORTING__PRODUCT_BRAND_PAGE = `${ROOT}/product-brand-report`;
export const REPORTING_DRILLDOWN_PRODUCT_BRAND_TO_CCB = `${REPORTING__PRODUCT_BRAND_PAGE}/country-city-branch`;
export const REPORTING_DRILLDOWN_PRODUCT_BRAND_TO_BRAND = `${REPORTING__PRODUCT_BRAND_PAGE}/brand`;

// Product Model Report Page
export const REPORTING__PRODUCT_MODEL_PAGE = `${ROOT}/product-model-report`;
export const REPORTING_DRILLDOWN_PRODUCT_MODEL_TO_MODEL = `${REPORTING__PRODUCT_MODEL_PAGE}/model`;
export const REPORTING_DRILLDOWN_PRODUCT_MODEL_TO_CCB = `${REPORTING__PRODUCT_MODEL_PAGE}/country-city-branch`;

// Product List Report Page
export const REPORTING__PRODUCT_LIST_PAGE = `${ROOT}/product-list-report`;
export const REPORTING_DRILLDOWN_PRODUCT_LIST_TO_CCB = `${REPORTING__PRODUCT_LIST_PAGE}/country-city-branch`;

// Product Suspended Report Page
export const REPORTING__PRODUCT_SUSPENDED_PAGE = `${ROOT}/product-suspended-report`;
export const REPORTING_DRILLDOWN_PRODUCT_SUSPENDED_TO_CCB = `${REPORTING__PRODUCT_SUSPENDED_PAGE}/country-city-branch`;

// Consumer Report Page
export const REPORTING__CONSUMER_PAGE = `${ROOT}/consumer-report`;
export const REPORTING_DRILLDOWN_CONSUMER_TO_CCB = `${REPORTING__CONSUMER_PAGE}/country-city-branch`;
export const REPORTING_DRILLDOWN_CONSUMER_TO_CONSUMER_PAGE = "/admin/consumer"

// Serial number page
export const REPORTING__DRILLDOWN_TO_SERIAL_NUMBER_PAGE = "/admin/serial-number";

// Campaign Report Page
export const REPORTING__CAMPAIGN_PAGE = `${ROOT}/campaign-report`;
export const REPORTING_DRILLDOWN_CAMPAIGN_SUMMARY_TO_CAMPAIGN_TYPE = `${REPORTING__CAMPAIGN_PAGE}/all-campaign`;
export const REPORTING_DRILLDOWN_CAMPAIGN_SURVEY = `${REPORTING_DRILLDOWN_CAMPAIGN_SUMMARY_TO_CAMPAIGN_TYPE}/survey`;
export const REPORTING_DRILLDOWN_CAMPAIGN_SUMMARY_TO_CCB = `${REPORTING__CAMPAIGN_PAGE}/country-city-branch`;
export const REPORTING_DRILLDOWN_CAMPAIGN_TO_SPECIFIC = `${REPORTING__CAMPAIGN_PAGE}/campaign`;
export const REPORTING_DRILLDOWN_CAMPAIGN_SPECIFIC_TO_CAMPAIGN_PRIZE = `${REPORTING__CAMPAIGN_PAGE}/campaign-prize`;

// Dealership Report Page
export const REPORTING__DEALERSHIP_PAGE = `${ROOT}/dealership-report`;
export const REPORTING_DRILLDOWN_DEALERSHIP_BY_TYPE = `${REPORTING__DEALERSHIP_PAGE}-drilldown`;
export const REPORTING__DRILLDOWN_TO_DEALERSHIP_DETAIL = '/admin/stockflow/my-dealer';