import React, { useRef, useState } from "react";
import BgImagePanelComponent from "./bgImagePanel.component";
import Modal from "../../modal";
import PropTypes from "prop-types";
import { Paper, Box } from "@material-ui/core";
import  ImageEditorPanel  from "components/panel/landingPageImageEditorPanel";
import { isFileExceedLimit, isImage } from "lib/helper";
import { useDispatch, useSelector } from "react-redux";
import { setBgImageError } from "modules/account/redux/slice"
import { getLang } from "app/feature/constants";

function BgImagePanelContainer({ image, handleChange }) {
  const dispatch = useDispatch();
  const inputImgRef = useRef();
  const [bufferImg, setBufferImg] = useState();
  const [isCroping, setCroping] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const lang = useSelector(state => state.constant.languages)

  const handleImgChange = e => {
    const file = inputImgRef.current.files[0];
    let value = false;
    let message = "";

    if (file) {
      const rawFile = e.target.files[0];

      if (!isImage(rawFile)) {
        value = true;
        message = getLang(lang, "message.error.INVALID_FILE_TYPE")
      }

      if (isFileExceedLimit(rawFile.size, 10, 'MB')) {
        value = true;
        message = getLang(lang, "message.error.FILE_SIZE_EXCEED_LIMIT")
      }

      dispatch(setBgImageError({ value: value, message: message }))

      const reader = new FileReader();
      reader.onload = (event) => {
        setBufferImg(reader.result);
        handleChange(reader.result);
      };
      reader.readAsDataURL(file);
    }
    setBufferImg(image);
    setIsModalOpen(true);
    setCroping(true);
  };

  const handleClose = () => setIsModalOpen(false);

  const handleResult = async image => {
    setIsUploading(true);
    setCroping(false);
    setIsModalOpen(false);
    setIsUploading(false);
    handleChange(image);
  };

  return (
    <>
      <BgImagePanelComponent
        image={image}
        inputImgRef={inputImgRef}
        handleImgChange={handleImgChange}
        lang={lang}
      />
      <Modal isOpen={isModalOpen} handleClose={handleClose}>
        {isCroping && (
          <ImageEditorPanel
            isCircleRadius={false}
            height={375}
            width={375}
            img={bufferImg}
            handleCancel={handleClose}
            handleResult={handleResult}
          />
        )}
        {isUploading && (
          <Paper elevation={0}>
            <Box p={2}>
              <h3>{getLang(lang, "label.UPLOADING")}</h3>
            </Box>
          </Paper>
        )}
      </Modal>
    </>
  );
}

BgImagePanelContainer.propTypes = {
  handleChange: PropTypes.func.isRequired,
  image: PropTypes.string.isRequired
};

export default BgImagePanelContainer;
