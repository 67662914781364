import React from "react";
import SurveyGeneralCardComponent from "./surveyGeneralCard.component";

export default function SurveyGeneralCardContainer({
  title,
  data,
  percentage,
  type
}){
  return (
    <SurveyGeneralCardComponent title={title} data={data} percentage={percentage} type={type}/>
  )
}