import { Box, makeStyles, Typography } from "@material-ui/core";
import { NoDataChart } from "modules/reporting/components/charts";
import React from "react";

const useStyles = makeStyles({
    item: {
        position: "relative",
        borderRadius: "12px",
        border: "1px solid #D0D5DD"
    },
    item__behind: {
        zIndex: 0,
        opacity: 0.2,
    },
    item__front: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    item__title: {
        fontFamily: "Roboto",
        fontWeight: "700",
        fontSize: "16px",
        lineHeight: "24px",
        color: '#101828',
        textAlign: "center"
    },
    item__value: {
        fontFamily: "Roboto",
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "24px",
        color: '#98A2B3',
        textAlign: "center"
    },
})

export default function EmptyChartComponent({
    data, title, value, labels
}){
    const datasets = data.map((item, index) => {
        return {
            name: item,
            data: [0, 0, 0, 0]
        }
    })
    const classes = useStyles();
    return (
        <Box className={classes.item} marginBottom="20px">
            <Box className={classes.item__behind}>
                <NoDataChart
                    labels= {labels}
                    datasets={datasets}
                    type="bar"
                />
            </Box>
            <Box className={classes.item__front}>
                <Box className={classes.row__content}>
                    <Typography className={classes.item__title}>
                        {title}
                    </Typography>
                    <Typography className={classes.item__value}>
                        {value}
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
}