import React, { useEffect, useState } from 'react'
import { fetchOrderListing } from "modules/loyalty-program/redux";
import { useDispatch, useSelector } from 'react-redux'
import GiftRedemptionPanelComponent from "./giftRedemptionPanel.component";
import { selectLoading } from 'modules/notification'
import { GIFT_REDEMPTION_EDIT } from "lib/constants/accessRights"
import { generatePaginationOptions } from "lib/helper";

const GiftListPanelContainer = () => {
  const dispatch = useDispatch()
  const hasGiftRedemptionEditAccessRight = useSelector(state => state.profile.accessRights.includes(GIFT_REDEMPTION_EDIT));
  const data = useSelector((state) => state.redemptionCenter.orderListTable.data);
  const totalRecords = useSelector(state => state.redemptionCenter.orderListTable.totalRecords)
  const filteredTotalRecords = useSelector(state => state.redemptionCenter.orderListTable.filteredTotalRecords)
  const isFetching = useSelector(state => selectLoading(state, fetchOrderListing.typePrefix))

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [search, setSearch] = useState("")
  const [paginationOptions, setPaginationOptions] = useState([]);

  useEffect(() => {
    dispatch(fetchOrderListing({
      length: rowsPerPage,
      start: rowsPerPage * page,
      search: search
    }))
  }, [search, rowsPerPage, page, dispatch])
  
  useEffect(() => {
    setPaginationOptions(generatePaginationOptions(totalRecords))
  }, [totalRecords])

  const handleReload = () => {
    dispatch(
      fetchOrderListing({
        length: rowsPerPage,
        start: rowsPerPage * page,
        search: search,
      })
    );
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setPage(0)
    setRowsPerPage(event.target.value)
  }

  const handleSearch = value => {
    setPage(0)
    setSearch(value)
  }

  return (
    <>
      <GiftRedemptionPanelComponent
        hasGiftRedemptionEditAccessRight={hasGiftRedemptionEditAccessRight}
        data={data}
        isFetching={isFetching}
        page={page}
        search={search}
        rowsPerPage={rowsPerPage}
        totalRecords={search ? filteredTotalRecords : totalRecords}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleSearch={handleSearch}
        handleReload={handleReload}
        paginationOptions={paginationOptions}
      />
    </>
  );
}

export default GiftListPanelContainer