import axios from "axios";

const get = () => {
  return new Promise((resolve, reject) => {
    axios
      .get("/payment/api/v1/payment-methods", { timeout: 10000 })
      .then(response => resolve(response))
      .catch(error => reject(error.response.data));
  });
};

const paymentMethods = {
  get
};

export default paymentMethods;
