import React from "react"
import LineChartComponent from "./lineChart.component";

export default function LineChartContainer({
  data,
  labels
}) {
  return (
    <LineChartComponent data={data} labels={labels} />
  )
}