import axios from "axios";

const checkHasPendingProcess = (parameters) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/serialnumber/api/v1/additional-info/pending-process`)
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

export default checkHasPendingProcess;
