import React, { useEffect, useState } from "react";
import TextMessageComponent from "./textMessage.component";

function TextMessageContainer({ text }) {
  const [messageSetting] = useState({
    codeletters: "QWERTYUIOPASDFGHJKLZXCVBNM",
    message: 0,
    current_length: 0,
    fadeBuffer: false,
    messages: [text],
  });

  const [message, SetMessage] = useState("");

  useEffect(() => {
    var length = messageSetting.current_length;

    setTimeout(function animateIn() {
      if (length < messageSetting.messages[messageSetting.message].length) {
        length = length + 2;
        if (length > messageSetting.messages[messageSetting.message].length) {
          length = messageSetting.messages[messageSetting.message].length;
        }
        var random_text = "";
        while (random_text.length < length) {
          random_text += messageSetting.codeletters.charAt(
            Math.floor(Math.random() * messageSetting.codeletters.length)
          );
        }
        var message = random_text;
        SetMessage(message);

        setTimeout(animateIn, 100);
      } else {
        setTimeout(function animateFadeBuffer() {
          if (messageSetting.fadeBuffer === false) {
            messageSetting.fadeBuffer = [];
            for (
              var i = 0;
              i < messageSetting.messages[messageSetting.message].length;
              i++
            ) {
              messageSetting.fadeBuffer.push({
                c: Math.floor(Math.random() * 12) + 1,
                l: messageSetting.messages[messageSetting.message].charAt(i),
              });
            }
          }

          var do_cycles = false;
          var message = "";

          for (var j = 0; j < messageSetting.fadeBuffer.length; j++) {
            var fader = messageSetting.fadeBuffer[j];
            if (fader.c > 0) {
              do_cycles = true;
              fader.c--;
              message += messageSetting.codeletters.charAt(
                Math.floor(Math.random() * messageSetting.codeletters.length)
              );
            } else {
              message += fader.l;
            }
          }
          SetMessage(message);

          if (do_cycles === true) {
            setTimeout(animateFadeBuffer, 50);
          }
        }, 20);
      }
    }, 100);
  }, [
    messageSetting.codeletters,
    messageSetting.current_length,
    messageSetting.fadeBuffer,
    messageSetting.message,
    messageSetting.messages,
  ]);

  return <TextMessageComponent message={{ message }} />;
}

export default TextMessageContainer;
