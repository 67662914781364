import { addAlert, setLoading } from "modules/notification";
import { getActiveDealerTrending, getCampaignEngagedTrending, getCampaignTrendingData, getDealerStockOutTrending, getInactiveDealerTrending, getItemTotalPackageTrending, getItemTotalRejectTrending, getItemTotalRequestTrending, getNewDealerTrending, getRequestStockoutTrending, getScanAgainstSuspendedData, getSuspendedScanQrData, getTotalCampaignSurveyTrending, getTotalDistributionPerformance, getTotalPackageTrending, getTotalRecallTrending, getTotalRecalledPackageTrending, getTotalReportedTrending, getTotalScanTrending, getTotalSuspendedTrending, getTopSuspendedProductTrending, getTopSuspendedQrTrending } from "../action";

// (1) total scan qr -- start
export const getTotalScanTrendingMiddleware = ({ dispatch, getState}) => next => action => {
    next(action);
    if (action.type === getTotalScanTrending.pending.type) {
        dispatch(setLoading({ id: getTotalScanTrending.typePrefix, state: true }));
    }
    if (action.type === getTotalScanTrending.fulfilled.type) {
        dispatch(setLoading({ id: getTotalScanTrending.typePrefix, state: false }));
    }
    if (action.type === getTotalScanTrending.rejected.type) {
        dispatch(setLoading({ id: getTotalScanTrending.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: action.payload.message}));
    }
}
// (1) total scan qr -- end

// (2) total suspended qr -- start
export const getTotalSuspendedTrendingMiddleware = ({ dispatch, getState}) => next => action => {
    next(action);
    if (action.type === getTotalSuspendedTrending.pending.type) {
        dispatch(setLoading({ id: getTotalSuspendedTrending.typePrefix, state: true }));
    }
    if (action.type === getTotalSuspendedTrending.fulfilled.type) {
        dispatch(setLoading({ id: getTotalSuspendedTrending.typePrefix, state: false }));
    }
    if (action.type === getTotalSuspendedTrending.rejected.type) {
        dispatch(setLoading({ id: getTotalSuspendedTrending.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: action.payload.message}));
    }
}
// (2) total suspended qr -- end

// (3) total distribution performance -- start
export const getTotalDistributionPerformanceMiddleware = ({ dispatch, getState}) => next => action => {
    next(action);
    if (action.type === getTotalDistributionPerformance.pending.type) {
        dispatch(setLoading({ id: getTotalDistributionPerformance.typePrefix, state: true }));
    }
    if (action.type === getTotalDistributionPerformance.fulfilled.type) {
        dispatch(setLoading({ id: getTotalDistributionPerformance.typePrefix, state: false }));
    }
    if (action.type === getTotalDistributionPerformance.rejected.type) {
        dispatch(setLoading({ id: getTotalDistributionPerformance.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: action.payload.message}));
    }
}
// (3) total distribution performance -- end

// (4) total reported qr -- start
export const getTotalReportedTrendingMiddleware = ({ dispatch, getState}) => next => action => {
    next(action);
    if (action.type === getTotalReportedTrending.pending.type) {
        dispatch(setLoading({ id: getTotalReportedTrending.typePrefix, state: true }));
    }
    if (action.type === getTotalReportedTrending.fulfilled.type) {
        dispatch(setLoading({ id: getTotalReportedTrending.typePrefix, state: false }));
    }
    if (action.type === getTotalReportedTrending.rejected.type) {
        dispatch(setLoading({ id: getTotalReportedTrending.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: action.payload.message}));
    }
}
// (4) total reported qr -- end

// (5) campaign engaged -- start
export const getCampaignEngagedTrendingMiddleware = ({ dispatch, getState}) => next => action => {
    next(action);
    if (action.type === getCampaignEngagedTrending.pending.type) {
        dispatch(setLoading({ id: getCampaignEngagedTrending.typePrefix, state: true }));
    }
    if (action.type === getCampaignEngagedTrending.fulfilled.type) {
        dispatch(setLoading({ id: getCampaignEngagedTrending.typePrefix, state: false }));
    }
    if (action.type === getCampaignEngagedTrending.rejected.type) {
        dispatch(setLoading({ id: getCampaignEngagedTrending.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: action.payload.message}));
    }
}
// (5) campaign engaged -- end

// (6) active dealer -- start
export const getActiveDealerTrendingMiddleware = ({ dispatch, getState}) => next => action => {
    next(action);
    if (action.type === getActiveDealerTrending.pending.type) {
        dispatch(setLoading({ id: getActiveDealerTrending.typePrefix, state: true }));
    }
    if (action.type === getActiveDealerTrending.fulfilled.type) {
        dispatch(setLoading({ id: getActiveDealerTrending.typePrefix, state: false }));
    }
    if (action.type === getActiveDealerTrending.rejected.type) {
        dispatch(setLoading({ id: getActiveDealerTrending.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: action.payload.message}));
    }
}
// (6) active dealer -- end

// (7) inactive dealer -- start
export const getInactiveDealerTrendingMiddleware = ({ dispatch, getState}) => next => action => {
    next(action);
    if (action.type === getInactiveDealerTrending.pending.type) {
        dispatch(setLoading({ id: getInactiveDealerTrending.typePrefix, state: true }));
    }
    if (action.type === getInactiveDealerTrending.fulfilled.type) {
        dispatch(setLoading({ id: getInactiveDealerTrending.typePrefix, state: false }));
    }
    if (action.type === getInactiveDealerTrending.rejected.type) {
        dispatch(setLoading({ id: getInactiveDealerTrending.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: action.payload.message}));
    }
}
// (7) inactive dealer -- end

// (8) dealer stock out -- start
export const getDealerStockOutTrendingMiddleware = ({ dispatch, getState}) => next => action => {
    next(action);
    if (action.type === getDealerStockOutTrending.pending.type) {
        dispatch(setLoading({ id: getDealerStockOutTrending.typePrefix, state: true }));
    }
    if (action.type === getDealerStockOutTrending.fulfilled.type) {
        dispatch(setLoading({ id: getDealerStockOutTrending.typePrefix, state: false }));
    }
    if (action.type === getDealerStockOutTrending.rejected.type) {
        dispatch(setLoading({ id: getDealerStockOutTrending.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: action.payload.message}));
    }
}
// (8) dealer stock out -- end

// (9) total recall -- start
export const getTotalRecallTrendingMiddleware = ({ dispatch, getState}) => next => action => {
    next(action);
    if (action.type === getTotalRecallTrending.pending.type) {
        dispatch(setLoading({ id: getTotalRecallTrending.typePrefix, state: true }));
    }
    if (action.type === getTotalRecallTrending.fulfilled.type) {
        dispatch(setLoading({ id: getTotalRecallTrending.typePrefix, state: false }));
    }
    if (action.type === getTotalRecallTrending.rejected.type) {
        dispatch(setLoading({ id: getTotalRecallTrending.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: action.payload.message}));
    }
}
// (9) total recall -- end

// (10) new dealer -- start
export const getNewDealerTrendingMiddleware = ({ dispatch, getState}) => next => action => {
    next(action);
    if (action.type === getNewDealerTrending.pending.type) {
        dispatch(setLoading({ id: getNewDealerTrending.typePrefix, state: true }));
    }
    if (action.type === getNewDealerTrending.fulfilled.type) {
        dispatch(setLoading({ id: getNewDealerTrending.typePrefix, state: false }));
    }
    if (action.type === getNewDealerTrending.rejected.type) {
        dispatch(setLoading({ id: getNewDealerTrending.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: action.payload.message}));
    }
}
// (10) new dealer -- end

// (7) suspended scan qr -- start
export const getSuspendedScanQrDataMiddleware = ({ dispatch, getState}) => next => action => {
    next(action);
    if (action.type === getSuspendedScanQrData.pending.type) {
        dispatch(setLoading({ id: getSuspendedScanQrData.typePrefix, state: true }));
    }
    if (action.type === getSuspendedScanQrData.fulfilled.type) {
        dispatch(setLoading({ id: getSuspendedScanQrData.typePrefix, state: false }));
    }
    if (action.type === getSuspendedScanQrData.rejected.type) {
        dispatch(setLoading({ id: getSuspendedScanQrData.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: action.payload.message}));
    }
}
// (7) suspended scan qr -- end

// (12) total survey -- start
export const getTotalCampaignSurveyTrendingMiddleware = ({ dispatch, getState}) => next => action => {
    next(action);
    if (action.type === getTotalCampaignSurveyTrending.pending.type) {
        dispatch(setLoading({ id: getTotalCampaignSurveyTrending.typePrefix, state: true }));
    }
    if (action.type === getTotalCampaignSurveyTrending.fulfilled.type) {
        dispatch(setLoading({ id: getTotalCampaignSurveyTrending.typePrefix, state: false }));
    }
    if (action.type === getTotalCampaignSurveyTrending.rejected.type) {
        dispatch(setLoading({ id: getTotalCampaignSurveyTrending.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: action.payload.message}));
    }
}
// (12) total survey -- end

// (13) campaign trending data -- start
export const getCampaignTrendingDataMiddleware = ({ dispatch, getState}) => next => action => {
    next(action);
    if (action.type === getCampaignTrendingData.pending.type) {
        dispatch(setLoading({ id: getCampaignTrendingData.typePrefix, state: true }));
    }
    if (action.type === getCampaignTrendingData.fulfilled.type) {
        dispatch(setLoading({ id: getCampaignTrendingData.typePrefix, state: false }));
    }
    if (action.type === getCampaignTrendingData.rejected.type) {
        dispatch(setLoading({ id: getCampaignTrendingData.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: action.payload.message}));
    }
}
// (13) campaign trending data -- end

// (14) scan against suspended data -- start
export const getScanAgainstSuspendedDataMiddleware = ({ dispatch, getState}) => next => action => {
    next(action);
    if (action.type === getScanAgainstSuspendedData.pending.type) {
        dispatch(setLoading({ id: getScanAgainstSuspendedData.typePrefix, state: true }));
    }
    if (action.type === getScanAgainstSuspendedData.fulfilled.type) {
        dispatch(setLoading({ id: getScanAgainstSuspendedData.typePrefix, state: false }));
    }
    if (action.type === getScanAgainstSuspendedData.rejected.type) {
        dispatch(setLoading({ id: getScanAgainstSuspendedData.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: action.payload.message}));
    }
}
// (14) scan against suspended data -- end

// (15) total request stockout -- start
export const getRequestStockoutTrendingMiddleware = ({ dispatch, getState}) => next => action => {
    next(action);
    if (action.type === getRequestStockoutTrending.pending.type) {
        dispatch(setLoading({ id: getRequestStockoutTrending.typePrefix, state: true }));
    }
    if (action.type === getRequestStockoutTrending.fulfilled.type) {
        dispatch(setLoading({ id: getRequestStockoutTrending.typePrefix, state: false }));
    }
    if (action.type === getRequestStockoutTrending.rejected.type) {
        dispatch(setLoading({ id: getRequestStockoutTrending.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: action.payload.message}));
    }
}
// (15) total request stockout -- end

// (16) total package -- start
export const getTotalPackageTrendingMiddleware = ({ dispatch, getState}) => next => action => {
    next(action);
    if (action.type === getTotalPackageTrending.pending.type) {
        dispatch(setLoading({ id: getTotalPackageTrending.typePrefix, state: true }));
    }
    if (action.type === getTotalPackageTrending.fulfilled.type) {
        dispatch(setLoading({ id: getTotalPackageTrending.typePrefix, state: false }));
    }
    if (action.type === getTotalPackageTrending.rejected.type) {
        dispatch(setLoading({ id: getTotalPackageTrending.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: action.payload.message}));
    }
}
// (16) total package -- end

// (17) total recalled package -- start
export const getTotalRecalledPackageTrendingMiddleware = ({ dispatch, getState}) => next => action => {
    next(action);
    if (action.type === getTotalRecalledPackageTrending.pending.type) {
        dispatch(setLoading({ id: getTotalRecalledPackageTrending.typePrefix, state: true }));
    }
    if (action.type === getTotalRecalledPackageTrending.fulfilled.type) {
        dispatch(setLoading({ id: getTotalRecalledPackageTrending.typePrefix, state: false }));
    }
    if (action.type === getTotalRecalledPackageTrending.rejected.type) {
        dispatch(setLoading({ id: getTotalRecalledPackageTrending.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: action.payload.message}));
    }
}

// (17) total recalled package -- end

// (18) total item total package -- start
export const getItemTotalPackageTrendingMiddleware = ({ dispatch, getState}) => next => action => {
    next(action);
    if (action.type === getItemTotalPackageTrending.pending.type) {
        dispatch(setLoading({ id: getItemTotalPackageTrending.typePrefix, state: true }));
    }
    if (action.type === getItemTotalPackageTrending.fulfilled.type) {
        dispatch(setLoading({ id: getItemTotalPackageTrending.typePrefix, state: false }));
    }
    if (action.type === getItemTotalPackageTrending.rejected.type) {
        dispatch(setLoading({ id: getItemTotalPackageTrending.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: action.payload.message}));
    }
}
// (18) total item total package -- end

// (19) total item total request -- start
export const getItemTotalRequesTrendingMiddleware = ({ dispatch, getState}) => next => action => {
    next(action);
    if (action.type === getItemTotalRequestTrending.pending.type) {
        dispatch(setLoading({ id: getItemTotalRequestTrending.typePrefix, state: true }));
    }
    if (action.type === getItemTotalRequestTrending.fulfilled.type) {
        dispatch(setLoading({ id: getItemTotalRequestTrending.typePrefix, state: false }));
    }
    if (action.type === getItemTotalRequestTrending.rejected.type) {
        dispatch(setLoading({ id: getItemTotalRequestTrending.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: action.payload.message}));
    }
}
// (19) total item total request -- end

// (20) total item total reject -- start
export const getItemTotalRejectTrendingMiddleware = ({ dispatch, getState}) => next => action => {
    next(action);
    if (action.type === getItemTotalRejectTrending.pending.type) {
        dispatch(setLoading({ id: getItemTotalRejectTrending.typePrefix, state: true }));
    }
    if (action.type === getItemTotalRejectTrending.fulfilled.type) {
        dispatch(setLoading({ id: getItemTotalRejectTrending.typePrefix, state: false }));
    }
    if (action.type === getItemTotalRejectTrending.rejected.type) {
        dispatch(setLoading({ id: getItemTotalRejectTrending.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: action.payload.message}));
    }
}
// (20) total item total reject -- end

// (21) top suspended product -- start
export const getTopSuspendedProductTrendingMiddleware = ({ dispatch, getState }) => next => action => {
    next(action);
    if (action.type === getTopSuspendedProductTrending.pending.type) {
        dispatch(setLoading({ id: getTopSuspendedProductTrending.typePrefix, state: true }));
    }
    if (action.type === getTopSuspendedProductTrending.fulfilled.type) {
        dispatch(setLoading({ id: getTopSuspendedProductTrending.typePrefix, state: false }));
    }
    if (action.type === getTopSuspendedProductTrending.rejected.type) {
        dispatch(setLoading({ id: getTopSuspendedProductTrending.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: action.payload.message }));
    }
}
// (21) top suspended product -- end

// (22) top suspended qr -- start
export const getTopSuspendedQrTrendingMiddleware = ({ dispatch, getState }) => next => action => {
    next(action);
    if (action.type === getTopSuspendedQrTrending.pending.type) {
        dispatch(setLoading({ id: getTopSuspendedQrTrending.typePrefix, state: true }));
    }
    if (action.type === getTopSuspendedQrTrending.fulfilled.type) {
        dispatch(setLoading({ id: getTopSuspendedQrTrending.typePrefix, state: false }));
    }
    if (action.type === getTopSuspendedQrTrending.rejected.type) {
        dispatch(setLoading({ id: getTopSuspendedQrTrending.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: action.payload.message }));
    }
}
// (22) top suspended qr -- end

export default [
    getTotalScanTrendingMiddleware,
    getTotalSuspendedTrendingMiddleware,
    getTotalDistributionPerformanceMiddleware,
    getTotalReportedTrendingMiddleware,
    getCampaignEngagedTrendingMiddleware,
    getActiveDealerTrendingMiddleware,
    getInactiveDealerTrendingMiddleware,
    getDealerStockOutTrendingMiddleware,
    getTotalRecallTrendingMiddleware,
    getNewDealerTrendingMiddleware,
    getSuspendedScanQrDataMiddleware,
    getTotalCampaignSurveyTrendingMiddleware,
    getCampaignTrendingDataMiddleware,
    getScanAgainstSuspendedDataMiddleware,
    getRequestStockoutTrendingMiddleware,
    getTotalPackageTrendingMiddleware,
    getTotalRecalledPackageTrendingMiddleware,
    getItemTotalPackageTrendingMiddleware,
    getItemTotalRequesTrendingMiddleware,
    getItemTotalRejectTrendingMiddleware,
    getTopSuspendedProductTrendingMiddleware,
    getTopSuspendedQrTrendingMiddleware
]