import React from "react";
import { makeStyles, Box, Typography, Button } from "@material-ui/core";
import { AddDocumentSVG } from "assets/js/resourse";
import { getLang } from "app/feature/constants";

const style = (theme) => ({
  root: {
    border: "1px solid rgba(0, 0, 0, 0.1)",
    borderRadius: "4px"
  },
  textContainer: {
    padding: "10px 16px",
    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
    textAlign: "center"
  },
  buttonContainer: {
    backgroundColor: "#EBEDEE",
    padding: theme.spacing(4, 2),
    textAlign: "center",
    justifyContent: "center"
  }
});

const useStyles = makeStyles(style);

export default function UploadDocumentComponent({
  handleClick,
  inputFile,
  file,
  handleFile,
  reuploadFlag,
  lang
}) {
  const classes = useStyles();

  return (
    <Box pt={2}>
      <Box className={classes.root}>
        <Box className={classes.textContainer}>
          <Typography
            variant="body2"
            style={{ color: "rgba(58, 77, 84, 0.5)" }}
          >
            {getLang(lang, 'paragraph.UPLOAD_TNC_FILE')}
          </Typography>
        </Box>

        <Box className={classes.buttonContainer}>
          <Button
            color="primary"
            variant="contained"
            disableElevation
            type="button"
            onClick={handleClick}
          >
            {file !== null ? (
              <>
                {file}
                <input
                  type="file"
                  id="file"
                  ref={inputFile}
                  accept="application/pdf"
                  style={{ display: "none" }}
                  onChange={handleFile}
                />
              </>
            ) : (
              <>
                <AddDocumentSVG />
                &ensp; {reuploadFlag ? getLang(lang, 'label.REUPLOAD_DOCUMENT') : getLang(lang, 'label.ADD_DOCUMENT')}
                <input
                  type="file"
                  id="file"
                  ref={inputFile}
                  accept="application/pdf"
                  style={{ display: "none" }}
                  onChange={handleFile}
                />
              </>
            )}
          </Button>
          <Box pt={1}>
            <Typography
              variant="caption"
              style={{ color: "rgba(58, 77, 84, 0.33)" }}
            >
              {getLang(lang, 'label.MAX_FILE_SIZE_PDF')}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
