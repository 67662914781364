import React from "react";
import {
  makeStyles,
  Box,
  Typography,
  Tabs,
  Divider,
  IconButton,
  Button,
} from "@material-ui/core";
import CustomTab from "components/tab";
import { useHistory } from "react-router-dom";
import { KeyboardBackspace as BackIcon } from "@material-ui/icons";
import { TabPanel, allyProps } from "components/tabPanel";
import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";
import {
  RespondentListingPanel,
  SurveyResultPanel,
  SurveyReportPanel,
} from "../../components/panel";
import ExportIcon from "assets/svg/export.svg";
import { getLang } from "app/feature/constants";

const useStyles = makeStyles((theme) => ({
  indicator: {
    border: "0.5px solid",
    borderColor: theme.palette.primary.main,
  },
  scroll: {
    width: "min-content",
  },
  mainContainer: {
    backgroundColor: "#f4f6f8",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
  },
  container: {
    backgroundColor: "#fff",
    boxShadow: "0px 1px 0px rgb(0 0 0 / 10%)",
    borderRadius: "4px",
  },
  profileHeaderSubContainer: {
    padding: theme.spacing(2, 4, 2, 4),
  },
  textLabel: {
    marginRight: theme.spacing(1),
  },
  box: {
    padding: theme.spacing(2.4, 4, 0, 4),
    display: "flex",
    justifyContent: "space-between",
  },
  roleDisplay: {
    display: "flex",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
      alignItems: "center",
    },
  },
  roleIcon: {
    borderBottom: "solid",
    color: theme.palette.primary.main,
  },
  textRole: {
    color: "#9CA6A9",
    fontWeight: "300",
  },
  avatar: {
    height: "60px",
    width: "60px",
    borderRadius: "4px",
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    "& img": {
      height: "100%",
      minWidth: "100%",
      minHeight: "100%",
      objectFit: "cover",
      flexShrink: 0,
    },
  },
  downlineContainer: {
    padding: theme.spacing(0.3, 1, 0.25, 0.3),
    backgroundColor: "#e5f1e4",
    borderRadius: "16px",
    marginLeft: theme.spacing(1),
    display: "flex",
    placeContent: "center",
  },
  downlineText: {
    marginLeft: theme.spacing(1),
  },
  exportButton: {
    textTransform: "capitalize",
    color: "#344054",
    fontWeight: "700",
    backgroundColor: "#fff",
    borderRadius: 8,
  },
}));

export default function SurveyDetailsPageComponent({
  handleChange,
  activeIndex,
  tabLabels,
  uuid,
  token,
  respondCount,
  lang
}) {
  const history = useHistory();
  const classes = useStyles();
  const API_HOST = process.env.REACT_APP_API_HOST_URL;
  
  return (
    <Box>
      <Box display="flex" justifyContent="space-between">
        <Box mb={1} display="flex" alignItems="center">
          <IconButton style={{ padding: 5 }} onClick={() => history.goBack()}>
            <BackIcon color="primary" />
          </IconButton>
          <Typography
            variant="h6"
            color="primary"
            style={{ fontWeight: "bold", marginLeft: 5 }}
          >
            {getLang(lang, 'label.SURVEY')}
          </Typography>
          <Box mx={0.5}>
            <ChevronRightRoundedIcon color="disabled" />
          </Box>
          <Box>
            <Typography variant="h6" color="primary">
              {tabLabels[activeIndex]}
            </Typography>
          </Box>
        </Box>
        <Box>
          {/* {respondCount > 0 && ( */}
            <Button
              variant="contained"
              disableElevation
              className={classes.exportButton}
              onClick={() =>
                window.open(
                  `${API_HOST}/consumer/export/v1/survey/${uuid}?token=${token}`,
                  "_blank"
                )
              }
            >
              <img
                src={ExportIcon}
                style={{ marginRight: 8 }}
                alt="exportImg"
              />
              {getLang(lang, 'label.EXPORT_EXCEL')}
            </Button>
          {/* )} */}
          

        </Box>
      </Box>
      <Divider />
      <Box>
        <Box>
          <Tabs
            value={activeIndex}
            onChange={handleChange}
            aria-label="scrollable auto tabs example"
            textColor="primary"
            variant="scrollable"
            indicatorColor="primary"
            classes={{
              indicator: classes.indicator,
              scrollButtons: classes.scroll,
            }}
          >
            {tabLabels.map((item, index) => {
              return <CustomTab key={index} {...allyProps(index, item)} />;
            })}
          </Tabs>
        </Box>
        <Box>
          <TabPanel value={activeIndex} index={0}>
            <SurveyReportPanel uuid={uuid} />
          </TabPanel>
          <TabPanel value={activeIndex} index={1}>
            <SurveyResultPanel />
          </TabPanel>
          <TabPanel value={activeIndex} index={2}>
            <RespondentListingPanel uuid={uuid} />
          </TabPanel>
        </Box>
      </Box>
    </Box>
  );
}
