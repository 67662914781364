import { Box, Chip, Grid, Typography } from "@material-ui/core";
import { borderStyle, chipStyle, fontTextStyle } from "modules/reporting/styles";
import { responseHandler } from "modules/reporting/utils/helper";
import React from "react";
// import { ContentCard } from "../../layout";
import { BoxError } from "../../skeleton/error";
import { BoxLoading } from "../../skeleton/loading";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { CAMPAIGN_TYPE_INSTANTLUCKYDRAW, CAMPAIGN_TYPE_LOYALTYPROGRAM, CAMPAIGN_TYPE_LUCKYDRAW, CAMPAIGN_TYPE_SURVEY, CAMPAIGN_TYPE_WARRANTY } from "modules/reporting/constants";

export default function CampaignSummaryPanelComponent({
    status,
    data,
    currentFilter,
    translate
}) {

    const chipClasses = chipStyle();
    const fontClasses = fontTextStyle();
    const borderClasses = borderStyle();

    const getSurveyChipType = (type) => {
        if(type === CAMPAIGN_TYPE_SURVEY)
            return <Chip label={translate("label.SURVEY")} className={chipClasses.chip__product} />;

        if(type === CAMPAIGN_TYPE_LUCKYDRAW)
            return <Chip label={translate("label.LUCKY_DRAW")} className={chipClasses.chip__product} />;

        if(type === CAMPAIGN_TYPE_INSTANTLUCKYDRAW)
            return <Chip label={translate("label.INSTANT_LUCKY_DRAW")} className={chipClasses.chip__product_big}/>;

        if(type === CAMPAIGN_TYPE_LOYALTYPROGRAM)
            return <Chip label={translate("label.LOYALTY_PROGRAM")} className={chipClasses.chip__product_big}/>;

        if(type === CAMPAIGN_TYPE_WARRANTY)
            return <Chip label={translate("label.WARRANTY")} className={chipClasses.chip__product}/>;

        return <Chip label={translate("label.UNKNOWN")} className={chipClasses.chip__product}/>;
    }

    const renderData = (ref) => {
        if (Object.keys(ref).length === 0 || !ref.isSuccess){
            return null;
        }

        return (
            <Grid container spacing={3}>
                {/* campaign detail : 1st part */}
                <Grid item xs={12} sm={12} md={6}>
                    <Box
                        display="flex"
                        flexDirection="column"
                        style={{
                            gap: "6px"
                        }}
                    >
                        {getSurveyChipType(currentFilter.campaignType)}
                        
                        <Typography
                            className={fontClasses.text__detail_title}
                        >
                            {ref.campaign_detail.name}
                        </Typography>
                        <Chip 
                            icon={<FiberManualRecordIcon className={ref.campaign_detail.status ? fontClasses.text__success : fontClasses.text__danger}/>}
                            label={ref.campaign_detail.status ? translate("label.PUBLISHED") : translate("label.UNPUBLISHED")}
                            className={ref.campaign_detail.status ? chipClasses.chip__publish_success : chipClasses.chip__publish_failed}
                        />
                    </Box>

                </Grid>

                {/* campaign detail : 2nd part */}
                <Grid item xs={12} sm={12} md={6} 
                    className={borderClasses.border_left}
                    alignItems="center"
                >
                    <Grid container spacing={3} alignItems="center" >
                        <Grid item xs={3}>
                            <Typography className={fontClasses.text__detail_subtitle}>
                                {translate("label.DATE_CREATED")}
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography className={fontClasses.text__detail_subtitle}>
                                :
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography className={fontClasses.text__detail_subtitle_value}>
                                {ref.campaign_detail.date_created}
                            </Typography>
                        </Grid>
                    </Grid> 

                    {
                        currentFilter.campaignType !== CAMPAIGN_TYPE_LOYALTYPROGRAM ? (
                            <Grid container spacing={3} alignItems="center" >
                                <Grid item xs={3}>
                                    <Typography className={fontClasses.text__detail_subtitle}>
                                        {translate("label.ACTIVE_PERIOD")}
                                    </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography className={fontClasses.text__detail_subtitle}>
                                        :
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography className={fontClasses.text__detail_subtitle_value}>
                                        {ref.campaign_detail.active_period}
                                    </Typography>
                                </Grid>
                            </Grid> 
                        ) : null
                    }

                </Grid>

            </Grid>
        )
    }


    return (
        <Box style={{
            background: "linear-gradient(270deg, rgba(97, 132, 255, 0.1) -2.01%, rgba(97, 132, 255, 0) 29.54%),linear-gradient(0deg, #FFFFFF, #FFFFFF)",
            padding: "20px 55px"
        }}>
            {
                responseHandler(
                    data.campaignSummaryData,
                    status.isFetchingCampaignSummaryData,
                    (
                        renderData(data.campaignSummaryData)
                    ),
                    (
                        <BoxLoading />
                    ),
                    (
                        <>empty</>
                    ),
                    (
                        <>no result</>
                    ),
                    (
                        <BoxError 
                            title={translate("label.LOADING_FAILED")}
                            value={translate("paragraph.LOADING_FAILED_DESCRIPTION")}
                        />
                    )
                )
            }
        </Box>
    )
}