import React from "react";
import {
  Backdrop,
  Dialog,
  makeStyles,
  Typography,
  Paper,
  Box,
  IconButton,
  Divider,
  Button,
} from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { getLang } from "app/feature/constants";
import BranchSelect from "../../select/branchSelect";

const useStyle = makeStyles((theme) => ({
  dialog: {
    maxWidth: "none",
  },
  root: {
    boxShadow: theme.shadows[5],
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "space-between",
    width: 500,
    maxWidth: "80vw",
    borderRadius: "4px",
    fontSize: 14,
  },
  header: {
    paddingLeft: theme.spacing(3),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  content: {
    padding: "16px",
  },
  buttonContainer: {
    padding: "10px 16px",
    display: "flex",
    justifyContent: "flex-end",
  },
  selectRoot: {
    padding: "8px 14px",
  },
  inputRoot: {
    paddingTop: "8px !important",
    paddingBottom: "7px !important",
    paddingLeft: "12px !important",
    paddingRight: "12px !important",
  },
}));

export default function SelectTargetBranchDialogComponent({
  isOpen,
  handleClose,
  handleSubmit,
  lang,
  selectedBranch,
  updateSelectedBranch,
}) {
  const classes = useStyle();

  return (
    <Dialog
      maxWidth="sm"
      open={isOpen}
      onClose={handleClose}
      disableBackdropClick
      closeAfterTransition
      BackdropComponent={Backdrop}
      classes={{
        paperWidthSm: classes.dialog,
      }}
    >
      <Paper elevation={0} className={classes.root}>
        <Box className={classes.header}>
          <Typography
            variant="subtitle2"
            color="primary"
            style={{ fontWeight: "bold" }}
          >
            {getLang(lang, "label.ADD_BRANCH_GROUP")}
          </Typography>
          <IconButton color="primary" onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
        <Divider />
        <Box>
          <Box className={classes.content}>
            <Typography
              variant="body2"
              color="primary"
              style={{ marginBottom: "10px" }}
            >
              {getLang(lang, "label.BRANCH")}
            </Typography>
            <Box display="flex">
              <BranchSelect
                value={selectedBranch}
                handleChange={(value) => updateSelectedBranch(value)}
                placeholder={getLang(lang, "placeholder.BRANCH")}
                disabled={false}
              />
            </Box>
          </Box>
          <Divider />
          <Box className={classes.buttonContainer}>
            <Button
              type="button"
              variant="contained"
              disableElevation
              color="secondary"
              disabled={selectedBranch.length === 0}
              onClick={handleSubmit}
            >
              {getLang(lang, "label.ADD")}
            </Button>
          </Box>
        </Box>
      </Paper>
    </Dialog>
  );
}
