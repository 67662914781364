import React from "react";
import { makeStyles, Box } from "@material-ui/core";
import { Draggable } from "react-beautiful-dnd";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  root: {},
  draggingBody: {
    left: "auto !important",
    top: "auto !important"
  }
}));

export default function DraggableCardComponent({ id, index, children, title }) {
  const classes = useStyles();

  return (
    <Draggable draggableId={id} index={index}>
      {(provided, snapshot) => (
        <Box
          className={clsx({
            [classes.draggingBody]: snapshot.isDragging
          })}
          {...provided.draggableProps}
          ref={provided.innerRef}
        >
          {children({
            dragHandleProps: provided.dragHandleProps,
            isDragging: snapshot.isDragging
          })}
        </Box>
      )}
    </Draggable>
  );
}
