import React from "react";
import {
  ButtonBase,
  Popper,
  Grow,
  Box,
  Paper,
  makeStyles,
  ClickAwayListener,
  Typography,
  Divider
} from "@material-ui/core";
import {
  MessageSVG,
  SmallUncheckedSVG,
  SmallCheckedSVG
} from "../../../utils/static";
import clsx from "clsx";
import { progressStatus } from "lib/constants/luckyDrawProgress";
import { getLang } from "app/feature/constants";

const style = (theme) => ({
  root: {
    borderRadius: theme.spacing(0.5),
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shortest
    }),
    paddingRight: "8px"
  },
  paper: {
    backgroundColor: "#222D30",
    color: theme.palette.common.white,
    borderRadius: "2px"
  },
  boxSubmenu: {
    "&::before": {
      content: '""',
      position: "absolute",
      width: "0",
      height: "0",
      top: "-13px",
      right: "5px",
      border: "8px solid",
      borderColor: "transparent transparent transparent",
      borderBottomColor: "#222D30",
      zIndex: 999
    }
  },
  contentContainer: {
    width: 160,
    minHeight: 140,
    padding: theme.spacing(2)
  },
  divider: {
    background: "rgba(255, 255, 255, 0.1)",
    margin: theme.spacing(1, 0)
  }
});

const useStyles = makeStyles(style);

export default function LiveDrawRemarkCardComponent({
  isOpen,
  handleToggleOpen,
  anchorRef,
  handleClose,
  progress,
  lang
}) {
  const classes = useStyles();

  const prizeSet =
    progress === progressStatus.setPrize ||
    progress === progressStatus.setWinner ||
    progress === progressStatus.confirmWinner ||
    progress === progressStatus.published
      ? true
      : false;

  const winnerSet =
    progress === progressStatus.setWinner ||
    progress === progressStatus.confirmWinner ||
    progress === progressStatus.published
      ? true
      : false;

  const confirmWinner =
    progress === progressStatus.confirmWinner ||
    progress === progressStatus.published
      ? true
      : false;

  return (
    <Box>
      <Popper
        open={isOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        style={{
          zIndex: 1300,
        }}
        transition
        placement="bottom-end"
        popperOptions={{
          modifiers: {
            offset: {
              offset: "-4,5",
            },
          },
        }}
      >
        {({ TransitionProps, placement }) => {
          return (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "start top" : "start bottom",
              }}
            >
              <Box className={classes.boxSubmenu}>
                <Paper className={classes.paper}>
                  <ClickAwayListener onClickAway={handleClose}>
                    <Box className={classes.contentContainer}>
                      <Typography variant="caption" style={{ color: "#fff" }}>
                        {getLang(lang,"tooltips.LIVE_DRAW_AVAILABLE_WHEN_FULFILLED")}
                      </Typography>

                      <Box pt={2}>
                        {[
                          { label: getLang(lang,"tooltips.SET_PRIZE"), status: prizeSet },
                          { label: getLang(lang,"tooltips.SET_WINNER"), status: winnerSet },
                          { label: getLang(lang,"tooltips.CONFIRM_NUMBER"), status: confirmWinner },
                        ].map((field, index) => {
                          return (
                            <div key={index}>
                              <Box display="grid" gridTemplateColumns="6fr 1fr">
                                <Typography
                                  variant="caption"
                                  style={{ color: "#fff" }}
                                >
                                  {field.label}
                                </Typography>

                                {field.status ? (
                                  <SmallCheckedSVG />
                                ) : (
                                  <SmallUncheckedSVG />
                                )}
                              </Box>

                              {index !== 2 ? (
                                <Divider className={classes.divider} />
                              ) : null}
                            </div>
                          );
                        })}
                      </Box>
                    </Box>
                  </ClickAwayListener>
                </Paper>
              </Box>
            </Grow>
          );
        }}
      </Popper>

      <ButtonBase
        className={clsx(classes.root, {
          [classes.open]: isOpen
        })}
        ref={anchorRef}
        onClick={handleToggleOpen}
      >
        <MessageSVG />
      </ButtonBase>
    </Box>
  );
}
