import React, { useState } from "react";
import InstantPrizeDialogComponent from "./instantPrizeDialog.component";
import ConfirmationDialogComponent from "components/dialog/confirmation";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  setPrizeEdit,
  setPrizeFinishEdit,
  setPrizeList,
  setPrizeListDelete,
  createPrize,
  updatePrize,
  closePrizeDialog
} from "modules/instant-lucky-draw/redux";
import { hideBackdrop } from "modules/backdrop";
import { INSTANT_LUCKY_DRAW_EDIT } from "lib/constants/accessRights";
import { getLang } from "app/feature/constants";

function InstantPrizeDialogContainer() {
  const dispatch = useDispatch();
  const hasInstantLuckyDrawEditAccessRight = useSelector(state => state.profile.accessRights.includes(INSTANT_LUCKY_DRAW_EDIT))
  const isOpen = useSelector(
    (state) => state.instantLuckyDraw.prizeDialogSetting.isOpen
  );
  const type = useSelector(
    (state) => state.instantLuckyDraw.prizeDialogSetting.type
  );
  const defaultPrizeData = useSelector(
    (state) => state.instantLuckyDraw.prizeDialogInfo.defaultPrizeDetail
  );
  const prizeList = useSelector(
    (state) => state.instantLuckyDraw.prizeDialogInfo.prizeList
  );
  const lang = useSelector(state => state.constant.languages)
  const [confirmationDialogModal, setConfirmationDialogModal] = useState(false);
  const [AddingPrizeFlag, setAddingPrizeFlag] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const closeConfirmationDialog = () => {
    setConfirmationDialogModal(false);
  };

  const handleProceed = async () => {
    setConfirmationDialogModal(false);
  };

  const AddPrize = () => {
    if(hasInstantLuckyDrawEditAccessRight){
      setAddingPrizeFlag(true);
    }
  };

  const handleAddPrizeSubmit = async (values) => {
    if(hasInstantLuckyDrawEditAccessRight){
      const temp = [];
      const info = {
        prizeName: values.prizeName,
        description: values.description,
        quantity: values.quantity,
        balance: values.quantity,
        prizeImage: values.prizeImage,
        editing: false,
        order: prizeList.length + 1,
        instantLuckyDrawId: values.instantLuckyDrawId,
        winners: [],
        status: "active",
        fileKey: values.fileKey,
        isGiftCode: values.isGiftCode
      };

      await dispatch(createPrize(info))
        .then(unwrapResult)
        .then(async (response) => {
          info.id = response.id;
          temp.push(info);
          await dispatch(setPrizeList(prizeList.concat(temp)));
        })
        .finally(() => {
          dispatch(hideBackdrop());
        });

      await setAddingPrizeFlag(false);
    }
  };

  const handleEnableEdit = async (index) => {
    await dispatch(setPrizeEdit(index));
    setAddingPrizeFlag(true)
    setIsEditing(true)
  };

  const handleBlankPrizeEdit = () => {
    setIsEditing(true)
  }
  
  const handleIsEditing = () => {
    setIsEditing(false)
  };

  const handleEdit = async (values) => {
    const index = prizeList.findIndex((w) => w.editing);
    const info = {
      ...values,
      index
    };
    setAddingPrizeFlag(false);
    await dispatch(updatePrize(info))
      .then(unwrapResult)
      .then(async (response) => {
        await dispatch(setPrizeFinishEdit(info));
      })
      .catch((error) => {
        dispatch(hideBackdrop());
      });
  };

  const handleNewDelete = () => {
    setAddingPrizeFlag(false);
  };

  const handleDeleteField = async () => {
    setConfirmationDialogModal(true);
    const index = prizeList.findIndex((w) => w.editing);
    await dispatch(setPrizeListDelete(index));
  };

  const handleClose = () => {
    setIsEditing(false)
    setAddingPrizeFlag(false);
    dispatch(closePrizeDialog());
  };

  return (
    <>
      <InstantPrizeDialogComponent
        hasInstantLuckyDrawEditAccessRight={hasInstantLuckyDrawEditAccessRight}
        type={type}
        AddPrize={AddPrize}
        AddingPrizeFlag={AddingPrizeFlag}
        prizeList={prizeList}
        handleAddPrizeSubmit={handleAddPrizeSubmit}
        handleEnableEdit={handleEnableEdit}
        handleEdit={handleEdit}
        handleDeleteField={handleDeleteField}
        handleNewDelete={handleNewDelete}
        handleClose={handleClose}
        isOpen={isOpen}
        defaultPrizeData={defaultPrizeData}
        isEditing={isEditing}
        handleIsEditing={handleIsEditing}
        handleBlankPrizeEdit={handleBlankPrizeEdit}
        lang={lang}
      />
      <ConfirmationDialogComponent
        isOpen={confirmationDialogModal}
        handleClose={closeConfirmationDialog}
        handleProceed={handleProceed}
        type={"danger"}
        description={getLang(lang,"label.DELETE_PRIZE_QUESTION")}
        title={getLang(lang,"label.DELETE_PRIZE")}
      />
    </>
  );
}

export default InstantPrizeDialogContainer;
