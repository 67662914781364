import { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { fetchPaymentMethods } from "../redux";
import { unwrapResult } from "@reduxjs/toolkit";

function usePaymentMethod() {
  const dispatch = useDispatch();
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [isFetching, setFetching] = useState(true);
  const [error, setError] = useState(null);
  const [currentPaymentMethod, setCurrentPaymentMethod] = useState({});

  const getPaymentMethods = useCallback(async () => {
    setFetching(true);

    dispatch(fetchPaymentMethods())
      .then(unwrapResult)
      .then(response => {
        setPaymentMethods(response.data);
        setCurrentPaymentMethod(response.data[0])
        setFetching(false);
        setError(null);
      })
      .catch(error => {
        setFetching(false);
        setError(error);
      });
  }, [dispatch]);

  useEffect(() => {
    getPaymentMethods();
  }, [getPaymentMethods]);

  const reload = () => {
    getPaymentMethods();
  };

  return [paymentMethods, isFetching, error, reload, currentPaymentMethod, setCurrentPaymentMethod];
}

export default usePaymentMethod;
