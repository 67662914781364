import { createSlice } from "@reduxjs/toolkit";
import { REPORTING } from "modules/reporting/constants";
import {
    getConsumerListingData,
    getConsumerScanData,
} from '../action';

const initialState = {
    consumerScanDatalist: {},
    consumerDatalist: {}
}

const reportingConsumerSlice = createSlice({
    name: REPORTING,
    initialState,
    reducers: {
        initReportingConsumerScan : (state) => {
            state.consumerScanDatalist = initialState.consumerScanDatalist;
        },
        initReportingConsumerListing : (state) => {
            state.consumerDatalist = initialState.consumerDatalist;
        }
    },
    extraReducers: {
        // CONSUMER SCAN -- START
        [getConsumerScanData.fulfilled]: (state, action) => {
            let data = action.payload.data;
            data.isSuccess = action.payload.success;
            data.noResult = (data.rows.length === 0);

            state.consumerScanDatalist = data;
        },
        [getConsumerScanData.rejected]: (state, action) => {
            state.consumerScanDatalist = {
                isSuccess: false,
                noResult: true,
                rows: [],
                total: 0,
            };
        },
        // CONSUMER SCAN -- END

        // CONSUMER LISTING DATA -- START
        [getConsumerListingData.fulfilled]: (state, action) => {
            let data = action.payload.data;
            data.isSuccess = action.payload.success;
            data.noResult = (data.rows.length === 0);

            state.consumerDatalist = data;
        },
        [getConsumerListingData.rejected]: (state, action) => {
            state.consumerDatalist = {
                isSuccess: false,
                noResult: true,
                rows: [],
                total: 0,
            };
        },
        // CONSUMER LISTING DATA -- END
    }
});

export const {
    initReportingConsumerScan,
    initReportingConsumerListing
} = reportingConsumerSlice.actions;

export const reportingConsumerReducer = reportingConsumerSlice.reducer;
