import React, { useState } from "react";
import CategoryListComponent from "./categoryList.component";
import { PRODUCT_CATEGORY_ADD } from "lib/constants/accessRights";
import { useSelector } from "react-redux";

function CategoryListContainer() {
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const isCategoryEdit = useSelector(
    (state) => state.products.categoryList.isEdit
  );
  const lang = useSelector((state) => state.constant.languages);
  const hasProductCategoryAddAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(PRODUCT_CATEGORY_ADD)
  );

  const handleCreateCategory = (value) => {
    setIsCreateDialogOpen(value);
  };

  return (
    <>
      <CategoryListComponent
        hasProductCategoryAddAccessRight={hasProductCategoryAddAccessRight}
        handleCreateCategory={handleCreateCategory}
        isCreateDialogOpen={isCreateDialogOpen}
        isCategoryEdit={isCategoryEdit}
        lang={lang}
      />
    </>
  );
}

export default CategoryListContainer;
