import React from "react";
import { Box, Typography, Button } from "@material-ui/core";
import ConsumerListingPanel from "modules/consumer/components/panel/consumerListingPanel";
import { DownloadSVG } from "modules/lucky-draw/utils/static";
import { getLang } from "app/feature/constants";

export default function ConsumerListingComponent({ token, lang }) {

  const API_HOST = process.env.REACT_APP_API_HOST_URL;
  return (
    <Box>
      <Box display="flex" justifyContent={"space-between"}>
        <Typography variant="h6" color="primary" style={{ fontWeight: "bold" }}>
          {getLang(lang, "label.CONSUMER")}
        </Typography>
        <Button
          variant="outlined"
          color="primary"
          size="small"
          style={{ margin: "4px", background: "white" }}
          startIcon={<DownloadSVG />}
          onClick={() => window.open(`${API_HOST}/consumer/export/v1/consumer?token=${token}`, "_blank")}
        >
          {getLang(lang, "label.EXPORT_EXCEL")}
        </Button>
      </Box>
      <ConsumerListingPanel />
    </Box>
  );
}
