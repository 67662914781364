import axios from "axios"

// get all survery listing
export function getAllSurvey({
  length,
  start,
  column,
  order,
  survey_name,
  status,
  created_at,
  product_id
}) {
  return new Promise((resolve, reject) => {
    axios.get("/consumer/admin/api/v1/survey", {
      params: {
        length,
        start,
        column,
        order,
        survey_name,
        status,
        created_at,
        product_id
      }
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data))
  })
}

