import React from "react";
import { makeStyles, Box, Typography } from "@material-ui/core";
import { getLang } from "app/feature/constants";
import collectIcon from "modules/product-mgmt/utils/img/componentMenu/collectIcon.png";

const useStyle = makeStyles((theme) => ({
    container: {
        position: "relative",
        borderRadius: 16,
        minHeight: 143,
        display: "flex",
        justifyContent: "space-between",
    },
    bgIcon: {
        position: "absolute",
        top: 15,
        right: 30,
        height: "75%",
        zIndex: 0,
    },
    text: {
        color: "#632FF9",
        fontSize: 20,
        fontWeight: "bold"
    },
    greyText: {
        color: "#7E7E7E",
    },
    button: {
        textWrap: "nowrap",
        textAlign: "center",
        backgroundColor: "#632FF9",
        borderRadius: 100,
        padding: "6px 16px",
        minWidth: "9em",
        color: "white",
        fontWeight: "bold",
        "&:hover": {
            backgroundColor: "#632FF9",
        },
    },
}));

export default function LoyaltyPointComponent({ lang }) {
    const classes = useStyle();

    return (
        <Box p={2}>
            <Box className={classes.container} justifyContent="space-between"
                style={{
                    background: "linear-gradient(180deg, #FCF7FF, #DFE4FF)"
                }}
            >
                <img
                    src={collectIcon}
                    alt="bg"
                    className={classes.bgIcon}
                />
                 <Box flexDirection="column" display="flex" p={2} zIndex={1}>
                    <Box
                        flex={1}
                        display="flex"
                        justifyContent="center"
                        flexDirection="column"
                        pb={1}
                        pl={3}
                    >
                        <Box display="flex" justifyContent="center"  style={{marginTop: "10px"}} >
                            <Typography
                                variant="h3"
                                className={classes.text}
                                style={{ textAlign: "center", fontSize: 40, height: "40px" }}
                            >
                                200
                            </Typography>
                        </Box>

                        <Box display="flex" justifyContent="center" textAlign="center">
                            <Typography className={classes.greyText} style={{fontSize: "1rem"}}>
                                {getLang(lang, "label.LOYALTY_POINT")}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Box alignSelf="flex-end" pb={1.5} pr={1.5} zIndex={1}>
                    <Box className={classes.button}>
                        {getLang(lang, "label.COLLECT")}
                    </Box>
                </Box>
            </Box>
        </Box>

    );
}
