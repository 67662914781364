import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import FieldFormComponent from "./fieldForm.component";

function FieldFormContainer({
  children,
  type,
  form,
  deliveryOrder,
  formRef,
  FinishEdit,
  Reorder,
  AddFieldChoice,
  height
}) {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const handleClose = () => {
    setOpen(false);
  };
  const lang = useSelector(state => state.constant.languages)

  const handleEdit = async (values) => {
    FinishEdit(values);
  };

  const handleDragEnd = (result) => {
    const { destination, source } = result;

    if (!destination) return;

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    )
      return;

    Reorder(destination, source);
  };

  const Capitalize = (str) =>  {
    let words = str.split('_');

    let capitalizedStr = words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');

    capitalizedStr = capitalizedStr.replace(/_/g, ' ');

    return capitalizedStr;
}


  return (
    <>
      <FieldFormComponent
        children={children}
        open={open}
        setOpen={setOpen}
        anchorRef={anchorRef}
        form={form}
        deliveryOrder={deliveryOrder}
        formRef={formRef}
        Capitalize={Capitalize}
        handleEdit={handleEdit}
        type={type}
        handleClose={handleClose}
        handleDragEnd={handleDragEnd}
        AddFieldChoice={AddFieldChoice}
        height={height}
        lang={lang}
      />
    </>
  );
}

export default FieldFormContainer;
