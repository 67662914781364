import React from "react";
import { Box, Paper, Typography, makeStyles, Button } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import DefaultImg from "assets/img/img-default.png";
import DefaultBoxImg from "assets/img/img-box-default.png";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "components/accordion";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Input from "components/input/inputTextField";
import { Formik } from "formik";
import { getLang } from "app/feature/constants";

const useStyles = makeStyles((theme) => ({
  productContainer: {
    padding: "20px 16px",
    borderRadius: "8px !important",
  },
  productPhoto: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #0000001a",
    width: "50px",
    height: "50px",
    borderRadius: "4px",
    "& img": {
      height: "100%",
      width: "100%",
    },
  },
  list: {
    maxHeight: "45vh",
    overflowY: "auto",
    padding: "8px 16px 16px",
    "&::-webkit-scrollbar": {
      width: "10px",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "16px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#E4E7EC",
      borderRadius: "16px",
    },
  },
  searchContainer: {
    backgroundColor: theme.palette.background.paper,
    display: "grid",
    gridTemplateColumns: "5fr 1fr 1fr",
    columnGap: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1),
    },
  },
  inputRootProduct: {
    paddingTop: "8px !important",
    paddingBottom: "7px !important",
    paddingLeft: "12px !important",
    paddingRight: "12px !important",
  },
  quantityChip: {
    backgroundColor: "#98A2B3",
    color: "#ffffff",
    borderRadius: "15px",
    padding: "0px 10px",
  },
  attributeText: {
    color: "#b2b2b2",
    fontSize: 12,
    whiteSpace: "break-spaces",
  },
}));

export default function StockItemListPanelComponent({
  stockoutItems,
  isFetching,
  handleSearch,
  lang,
}) {
  const classes = useStyles();

  return (
    <Paper elevation={0}>
      {!isFetching ? (
        <Box p={0.5} pb={1}>
          <Box px={2} pt={2}>
            <Typography variant="body1">
              <b>{getLang(lang, "label.ITEMS_STOCKOUT")}</b>
            </Typography>
          </Box>
          <Formik
            initialValues={{
              keyword: "",
            }}
            onSubmit={handleSearch}
            onReset={handleSearch}
          >
            {(formik) => (
              <form onSubmit={formik.handleSubmit}>
                <Box p={2} className={classes.searchContainer}>
                  <Input
                    hiddenLabel
                    variant="filled"
                    size="small"
                    placeholder={getLang(
                      lang,
                      "placeholder.SEARCH_PRODUCT_BOX_PACKAGE"
                    )}
                    margin="none"
                    InputProps={{
                      disableUnderline: true,
                      margin: "none",
                      style: {
                        borderRadius: "4px",
                        backgroundColor: "#eceff0",
                      },
                      classes: { input: classes.inputRootProduct },
                    }}
                    {...formik.getFieldProps("keyword")}
                  />
                  <Button
                    variant="text"
                    disableElevation
                    color="primary"
                    size="small"
                    onClick={formik.handleReset}
                  >
                    {getLang(lang, "label.CLEAR")}
                  </Button>
                  <Button
                    variant="contained"
                    disableElevation
                    color="primary"
                    size="small"
                    type="submit"
                  >
                    {getLang(lang, "label.SEARCH")}
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
          {!!stockoutItems.boxes.length || !!stockoutItems.packages.length ? (
            <>
              {!!stockoutItems.boxes.length && (
                <>
                  <Box
                    style={{ backgroundColor: "#F2F4F7" }}
                    p={1}
                    mx={1}
                    mb={1}
                  >
                    <Typography variant="body1">
                      <b>{getLang(lang, "label.BOX")}</b>
                    </Typography>
                  </Box>
                  <Box className={classes.list}>
                    {stockoutItems.boxes.map((item, index) => (
                      <Accordion
                        key={item.id}
                        defaultExpanded={false}
                        style={{ marginTop: !!index ? "16px" : 0 }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          className={classes.productContainer}
                        >
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            flex={1}
                          >
                            <Box display="flex" alignItems="center">
                              <Typography
                                variant="body1"
                                style={{ marginRight: 10 }}
                              >
                                <b>{index + 1}.</b>
                              </Typography>
                              <Box
                                className={classes.productPhoto}
                                mr={2}
                                ml={1}
                              >
                                <img src={DefaultBoxImg} alt="box" />
                              </Box>
                              <Typography
                                variant="body1"
                                style={{ marginRight: 10 }}
                              >
                                <b>{item.name}</b>
                              </Typography>
                            </Box>
                            <Box className={classes.quantityChip}>
                              <Typography variant="body1">
                                {item.quantity.toLocaleString("en-US")}
                              </Typography>
                            </Box>
                          </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Box px={5} pt={1} pb={2}>
                            {item.products.map((p, i) => (
                              <Box mt={!!i ? 1 : 0} key={i}>
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="space-between"
                                >
                                  <Box display="flex" alignItems="center">
                                    <Box
                                      style={{ width: 36, height: 36 }}
                                      className={classes.productPhoto}
                                      mr={2}
                                      ml={1}
                                    >
                                      {p?.picture === "" ? (
                                        <img
                                          src={DefaultImg}
                                          alt="emptyProduct"
                                        />
                                      ) : (
                                        <img src={p.picture} alt={p.name} />
                                      )}
                                    </Box>
                                    <Box
                                      display="flex"
                                      flexDirection="column"
                                      justifyContent="center"
                                      alignItems="flex-start"
                                      mr={1}
                                    >
                                      <Typography variant="body1">
                                        <b>{p.name}</b>
                                      </Typography>
                                      {!!p.attributes &&
                                        !!p.attributes.length && (
                                          <Typography
                                            className={classes.attributeText}
                                            variant="body2"
                                          >
                                            {p.attributes.join(", ")}
                                          </Typography>
                                        )}
                                    </Box>
                                  </Box>
                                  <Typography variant="body1">
                                    {p.quantity.toLocaleString("en-US")}
                                  </Typography>
                                </Box>
                                <Box ml={7} my={2} m>
                                  {p.packages.map((pkg, seq) => (
                                    <Box mt={!!seq ? 1 : 0} key={seq}>
                                      <Box>
                                        <Typography variant="body1">
                                          {seq + 1}. {pkg.name}
                                        </Typography>
                                      </Box>
                                      <Box
                                        px={3}
                                        pb={1}
                                        mt={pkg.children.length ? 1 : 0}
                                      >
                                        {pkg.children.map((child) => (
                                          <Box key={child.code} mb={0.25}>
                                            <Typography
                                              color="textSecondary"
                                              style={{ marginRight: 3 }}
                                              key={child.code}
                                              display="inline"
                                            >
                                              {child.code}
                                            </Typography>
                                            {child.type === "Loose Item" && (
                                              <Typography
                                                color="textSecondary"
                                                key={child.code}
                                                display="inline"
                                              >
                                                (
                                                {child.quantity?.toLocaleString(
                                                  "en-US"
                                                ) || 0}
                                                )
                                              </Typography>
                                            )}
                                          </Box>
                                        ))}
                                      </Box>
                                    </Box>
                                  ))}
                                </Box>
                              </Box>
                            ))}
                          </Box>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </Box>
                </>
              )}
              {!!stockoutItems.packages.length && (
                <>
                  <Box
                    style={{ backgroundColor: "#F2F4F7" }}
                    p={1}
                    mt={1.5}
                    mx={1}
                    mb={1}
                  >
                    <Typography variant="body1">
                      <b>{getLang(lang, "label.PACKAGE")}</b>
                    </Typography>
                  </Box>
                  <Box className={classes.list}>
                    {stockoutItems.packages.map((item, index) => (
                      <Accordion
                        key={item.id}
                        defaultExpanded={false}
                        style={{ marginTop: !!index ? "16px" : 0 }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          className={classes.productContainer}
                        >
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            flex={1}
                          >
                            <Box display="flex" alignItems="center">
                              <Typography
                                variant="body1"
                                style={{ marginRight: 10 }}
                              >
                                <b>{index + 1}.</b>
                              </Typography>
                              <Box
                                className={classes.productPhoto}
                                mr={2}
                                ml={1}
                              >
                                {item?.picture === "" ? (
                                  <img src={DefaultImg} alt="emptyProduct" />
                                ) : (
                                  <img src={item.picture} alt={item.name} />
                                )}
                              </Box>
                              <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                alignItems="flex-start"
                              >
                                <Typography
                                  variant="body1"
                                  style={{ marginRight: 10 }}
                                >
                                  <b>{item.name}</b>
                                </Typography>
                                {!!item.attributes &&
                                  !!item.attributes.length && (
                                    <Typography
                                      className={classes.attributeText}
                                      variant="body2"
                                    >
                                      {item.attributes.join(", ")}
                                    </Typography>
                                  )}
                              </Box>
                            </Box>
                            <Box className={classes.quantityChip}>
                              <Typography variant="body1">
                                {item.quantity.toLocaleString("en-US")}
                              </Typography>
                            </Box>
                          </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Box px={5} pt={1} pb={2}>
                            {item.packages.map((p, i) => (
                              <Box mt={!!i ? 1 : 0} key={i}>
                                <Box>
                                  <Typography variant="body1">
                                    {i + 1}. {p.name}
                                  </Typography>
                                </Box>
                                <Box
                                  px={3}
                                  pb={1}
                                  mt={p.children.length ? 1 : 0}
                                >
                                  {p.children.map((child) => (
                                    <Box key={child.code} mb={0.25}>
                                      <Typography
                                        color="textSecondary"
                                        style={{ marginRight: 3 }}
                                        key={child.code}
                                        display="inline"
                                      >
                                        {child.code}
                                      </Typography>
                                      {child.type === "Loose Item" && (
                                        <Typography
                                          color="textSecondary"
                                          key={child.code}
                                          display="inline"
                                        >
                                          (
                                          {child.quantity?.toLocaleString(
                                            "en-US"
                                          ) || 0}
                                          )
                                        </Typography>
                                      )}
                                    </Box>
                                  ))}
                                </Box>
                              </Box>
                            ))}
                          </Box>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </Box>
                </>
              )}
            </>
          ) : (
            <Box p={5} display="flex" justifyContent="center">
              <Typography variant="body1">
                <b>{getLang(lang, "paragraph.NO_PRODUCT_EXIST")}</b>
              </Typography>
            </Box>
          )}
        </Box>
      ) : (
        <Box p={2}>
          <Skeleton variant="text" height={40} mt={2} />
          <Skeleton variant="text" height={60} mt={2} />
          <Skeleton variant="text" height={60} mt={2} />
          <Skeleton variant="text" height={60} mt={2} />
        </Box>
      )}
    </Paper>
  );
}
