import React from "react";
import FooterComponent from "./footer.component";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

function FooterContainer({ activeStep, type }) {
  const history = useHistory();
  const handleClickAnotherEmailAddress = () => {
    history.push("/reset-password")
  };
  const lang = useSelector(state => state.constant.languages)
  return (
    <FooterComponent
      activeStep={activeStep}
      type={type}
      handleClickAnotherEmailAddress={handleClickAnotherEmailAddress}
      lang={lang}
    />
  );
}

const mapDispatchToProps = dispatch => ({
});

export default connect(null, mapDispatchToProps)(FooterContainer);
