/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLang } from "app/feature/constants";
import {
  approveHandoff,
  getWarrantyHandoffDetail,
  rejectHandoff,
  resetHandoff,
} from "modules/warranty/redux";
import HandoffDetailDialogComponent from "./handoffDetailDialog.component";
import { selectLoading } from "modules/notification";
import DisplayImageDialog from "modules/warrantee/components/dialog/displayImageDialog";
import GeneralDialog from "components/dialog/generalDialog";

function HandoffDetailDialogContainer({
  handleClose,
  uuid,
  handleTableReload,
}) {
  const dispatch = useDispatch();

  const lang = useSelector((state) => state.constant.languages);
  const language = useSelector((state) => state.profile.language);
  const profileLang = useSelector((state) => state.profile.language);
  const isLoading = useSelector((state) =>
    selectLoading(state, getWarrantyHandoffDetail.typePrefix)
  );
  const handoff = useSelector((state) => state.warrantyHandoff.handoff);
  const isError = useSelector(
    (state) => state.warrantyHandoff.isLoadingHandoffError
  );

  const isApproving = useSelector((state) =>
    selectLoading(state, approveHandoff.typePrefix)
  );
  const isRejecting = useSelector((state) =>
    selectLoading(state, rejectHandoff.typePrefix)
  );

  const [selectedBranch, setSelectedBranch] = useState(null);
  const [displayImage, setDisplayImage] = useState(null);
  const [showApproveConfirm, setShowApproveConfirm] = useState(false);
  const [showRejectConfirm, setShowRejectConfirm] = useState(false);

  useEffect(() => {
    if (uuid) {
      handleDataLoad();
    } else {
      dispatch(resetHandoff());
    }
  }, [dispatch, uuid]);

  const handleDataLoad = () => {
    dispatch(getWarrantyHandoffDetail(uuid));
  };

  const approve = () => {
    dispatch(
      approveHandoff({ uuid: uuid, node_uuid: selectedBranch.node_id })
    ).then(() => {
      handleDataLoad();
      handleTableReload();
      setShowApproveConfirm(false);
    });
  };

  const reject = () => {
    dispatch(rejectHandoff(uuid)).then(() => {
      handleDataLoad();
      handleTableReload();
      setShowRejectConfirm(false);
    });
  };

  return (
    <>
      <HandoffDetailDialogComponent
        isOpen={!!uuid}
        handleMediaClick={(src, type) => setDisplayImage({ src, type })}
        handleClose={handleClose}
        handoff={handoff}
        isError={isError}
        handleReload={handleDataLoad}
        isLoading={isLoading}
        lang={lang}
        language={language}
        handleApprove={(values) => {
          setSelectedBranch(values.branch);
          setShowApproveConfirm(true);
        }}
        handleReject={() => setShowRejectConfirm(true)}
        isApproving={isApproving}
        profileLang={profileLang}
      />
      <GeneralDialog
        isLoading={isApproving}
        isOpen={showApproveConfirm}
        handleClose={() => setShowApproveConfirm(false)}
        handleProceed={approve}
        title={getLang(lang, "label.APPROVE_HANDOFF")}
        description={getLang(lang, "paragraph.APPROVE_HANDOFF_DESC")}
        type="success"
        proceedLabel={getLang(lang, "label.CONFIRM")}
      />
      <GeneralDialog
        isLoading={isRejecting}
        isOpen={showRejectConfirm}
        handleClose={() => setShowRejectConfirm(false)}
        handleProceed={reject}
        title={getLang(lang, "label.REJECT_HANDOFF")}
        description={getLang(lang, "paragraph.REJECT_HANDOFF_DESC")}
        type="danger"
        proceedLabel={getLang(lang, "label.CONFIRM")}
      />
      <DisplayImageDialog
        isOpen={!!displayImage}
        handleClose={() => {
          setDisplayImage(null);
        }}
        src={!!displayImage ? displayImage.src : null}
        type={!!displayImage ? displayImage.type : null}
      />
    </>
  );
}

export default HandoffDetailDialogContainer;
