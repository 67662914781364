import { createSlice } from "@reduxjs/toolkit";
import {
  STOCKFLOWSTOCKTAKE,
  getStockTakeDetail,
  getStockTakeItemBoxList,
  getStockTakeItemBoxProductList,
  getStockTakeItemProductList,
  getStockTakeItemProductSNList,
  getStockTakeList,
} from "../action";

const initialState = {
  stockTakeList: {
    list: [],
    totalFiltered: 0,
    totalRecords: 0,
    isError: false,
  },
  stocktake: null,
  isLoadingStockTakeError: false,
  stockTakeBoxes: {
    list: [],
    totalFiltered: 0,
    totalRecords: 0,
    isError: false,
  },
  stockTakeBoxProductList: {
    list: [],
    isError: false,
  },
  stockTakeProducts: {
    list: [],
    totalFiltered: 0,
    totalRecords: 0,
    isError: false,
  },
  stockTakeSnList: {
    list: [],
    totalFiltered: 1,
    totalRecords: 1,
    isError: false,
  },
};

const stockflowStockTakeSlice = createSlice({
  name: STOCKFLOWSTOCKTAKE,
  initialState,
  reducers: {
    resetStockTakeListing(state) {
      state.stockTakeList = initialState.stockTakeList;
    },
    resetStockTake(state) {
      state.stocktake = initialState.stocktake;
      state.stockTakeBoxes = initialState.stockTakeBoxes;
      state.stockTakeBoxProductList = initialState.stockTakeBoxProductList;
      state.stockTakeSnList = initialState.stockTakeSnList;
      state.stockTakeProducts = initialState.stockTakeProducts;
    },
    resetStockTakeProductListing(state) {
      state.stockTakeProducts = initialState.stockTakeProducts;
    },
    resetStockTakeBoxListing(state) {
      state.stockTakeBoxes = initialState.stockTakeBoxes;
    },
    resetStockTakeSnListing(state) {
      state.stockTakeSnList = initialState.stockTakeSnList;
    },
    resetStockTakeBoxProductListing(state) {
      state.stockTakeBoxProductList = initialState.stockTakeBoxProductList;
    },
    updateStockTakeInfo(state, action) {
      const { payload } = action;
      state.stocktake[payload.name] = payload.value;
    },
  },
  extraReducers: {
    [getStockTakeList.pending]: (state) => {
      state.stockTakeList = initialState.stockTakeList;
    },
    [getStockTakeList.fulfilled]: (state, action) => {
      const { payload } = action;
      state.stockTakeList.list = payload.data;
      state.stockTakeList.totalRecords = payload.recordsTotal;
      state.stockTakeList.totalFiltered = payload.recordsFiltered;
    },
    [getStockTakeList.rejected]: (state) => {
      state.stockTakeList.isError = true;
    },
    [getStockTakeDetail.pending]: (state) => {
      state.stocktake = initialState.stocktake;
      state.isLoadingStockTakeError = false;
    },
    [getStockTakeDetail.fulfilled]: (state, action) => {
      state.stocktake = action.payload;
    },
    [getStockTakeDetail.rejected]: (state) => {
      state.isLoadingStockTakeError = true;
    },
    [getStockTakeItemBoxList.pending]: (state) => {
      state.stockTakeBoxes.isError = false;
    },
    [getStockTakeItemBoxList.fulfilled]: (state, action) => {
      const { payload } = action;
      state.stockTakeBoxes.list = payload.data;
      state.stockTakeBoxes.totalRecords = payload.recordsTotal;
      state.stockTakeBoxes.totalFiltered = payload.recordsFiltered;
    },
    [getStockTakeItemBoxList.rejected]: (state) => {
      state.stockTakeBoxes.isError = true;
    },
    [getStockTakeItemBoxProductList.pending]: (state) => {
      state.stockTakeBoxProductList = initialState.stockTakeBoxProductList;
    },
    [getStockTakeItemBoxProductList.fulfilled]: (state, action) => {
      const { payload } = action;
      state.stockTakeBoxProductList.list = payload;
    },
    [getStockTakeItemBoxProductList.rejected]: (state) => {
      state.stockTakeBoxProductList.isError = true;
    },
    [getStockTakeItemProductList.pending]: (state) => {
      state.stockTakeProducts.isError = false;
    },
    [getStockTakeItemProductList.fulfilled]: (state, action) => {
      const { payload } = action;
      state.stockTakeProducts.list = payload.data;
      state.stockTakeProducts.totalRecords = payload.recordsTotal;
      state.stockTakeProducts.totalFiltered = payload.recordsFiltered;
    },
    [getStockTakeItemProductList.rejected]: (state) => {
      state.stockTakeProducts.isError = true;
    },
    [getStockTakeItemProductSNList.pending]: (state) => {
      state.stockTakeSnList = initialState.stockTakeSnList;
    },
    [getStockTakeItemProductSNList.fulfilled]: (state, action) => {
      const { payload } = action;
      state.stockTakeSnList.list = payload.data;
      state.stockTakeSnList.totalRecords = payload.recordsTotal;
      state.stockTakeSnList.totalFiltered = payload.recordsFiltered;
    },
    [getStockTakeItemProductSNList.rejected]: (state) => {
      state.stockTakeSnList.isError = true;
    },
  },
});

export const {
  resetStockTake,
  resetStockTakeListing,
  resetStockTakeProductListing,
  resetStockTakeBoxListing,
  resetStockTakeSnListing,
  resetStockTakeBoxProductListing,
  updateStockTakeInfo,
} = stockflowStockTakeSlice.actions;

export const stockflowStockTakeReducer = stockflowStockTakeSlice.reducer;
