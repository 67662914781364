import React from "react";
import SingleSnAdditionalInfoAccordionComponent from "./singleSnAdditionalInfoAccordion.component";
import { useSelector } from "react-redux";

function SingleSnAdditionalInfoAccordionContainer({
  info,
  adtInfoIdx,
  isUpdating,
  formik,
  currentLang,
}) {
  const lang = useSelector((state) => state.constant.languages);

  return (
    <SingleSnAdditionalInfoAccordionComponent
      lang={lang}
      isUpdating={isUpdating}
      info={info}
      adtInfoIdx={adtInfoIdx}
      formik={formik}
      currentLang={currentLang}
    />
  );
}

export default SingleSnAdditionalInfoAccordionContainer;
