import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardHeader,
  Divider,
  makeStyles,
  Tabs
} from "@material-ui/core";
import PerfectScrollbar from 'react-perfect-scrollbar';
import CustomTab from 'components/tab';
import { TabPanel, allyProps } from "components/tabPanel";
import { ScanLocationCountryPanel, ScanLocationStatePanel } from "components/panel";
import LoadingComponent from 'components/loading';
import EmptyComponent from 'components/empty';
import { getLang } from "app/feature/constants";

const useStyles = makeStyles(theme => ({
  card: {
    boxShadow: "none",
    borderRadius: 0,
    paddingBottom: theme.spacing(2)
  },
  cardHeader: {
    padding: 0
  },
  cardHeaderContent: {
    padding: 16
  },
  cardTitle: {
    fontSize: 14,
    fontWeight: "bold"
  },
  cardHeaderAction: {
    alignSelf: "flex-end",
    marginTop: 0,
    marginRight: 0
  },
  indicator: {
    border: "0.5px solid",
    borderColor: theme.palette.primary.main,
    borderTopLeftRadius: 999,
    borderTopRightRadius: 999
  },
  scroll: {
    width: "min-content"
  },
  tabButton: {
    textTransform: "none"
  }
}));

export default function ScanLocationCardComponent({
  isLoading,
  scanData,
  tabs,
  lang
}) {
  const classes = useStyles();
  const [activeIndex, setActiveIndex] = useState(1);
  const [targetCountry, setTargetCountry] = useState("");

  useEffect(() => {
    if (scanData.length === 1) {
      setActiveIndex(0)
    }
    else {
      setActiveIndex(1)
    }
  }, [scanData.length])

  useEffect(() => {
    if (targetCountry !== "") {
      setActiveIndex(0)
    }
  }, [targetCountry])

  function handleCountryClick(country) {
    setTargetCountry(country)
  }

  return (
    <Card classes={{ root: classes.card }}>
      <CardHeader
        title={getLang(lang, "label.SCAN_GEO")}
        titleTypographyProps={{
          color: "primary"
        }}
        classes={{
          root: classes.cardHeader,
          title: classes.cardTitle,
          content: classes.cardHeaderContent,
          action: classes.cardHeaderAction
        }}
        action={
          !isLoading &&
          scanData.length > 0 && (
            <Tabs
              value={activeIndex}
              onChange={(e, index) => {
                setTargetCountry("");
                setActiveIndex(index);
              }}
              aria-label="scrollable auto tabs example"
              textColor="primary"
              variant="scrollable"
              indicatorColor="primary"
              classes={{
                indicator: classes.indicator,
                scrollButtons: classes.scroll
              }}
            >
              {tabs.map((item, index) => (
                <CustomTab
                  key={index}
                  {...allyProps(index, item)}
                  disableRipple
                />
              ))}
            </Tabs>
          )
        }
      />
      <Divider />
      <PerfectScrollbar
        style={{
          height: 200,
          padding: "0px 16px 24px 16px"
        }}
        options={{
          suppressScrollX: true
        }}
      >
        {isLoading ? (
          <LoadingComponent />
        ) : scanData.length > 0 ? (
          <Box>
            <TabPanel value={activeIndex} index={0}>
              <ScanLocationStatePanel scanData={scanData} targetCountry={targetCountry} />
            </TabPanel>
            <TabPanel value={activeIndex} index={1}>
              <ScanLocationCountryPanel scanData={scanData} handleCountryClick={handleCountryClick} />
            </TabPanel>
          </Box>
        ) : (
          <EmptyComponent />
        )}
      </PerfectScrollbar>
    </Card>
  );
}