import React from "react";
import {
  makeStyles,
  Typography,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  Box,
  MenuItem,
} from "@material-ui/core";
import PerfectScrollbar from "react-perfect-scrollbar";
import DefaultImg from "assets/img/img-default.png";
import Skeleton from "@material-ui/lab/Skeleton";
import { getLang } from "app/feature/constants";
import RefreshTable from "components/table/refreshTable";

const useStyle = makeStyles((theme) => ({
  paper: {
    width: 425,
  },
  button: {
    padding: 0,
    minWidth: 0,
  },
  courierPhoto: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #E4E7EC",
    width: "30px",
    height: "30px",
    marginRight: "10px",
    "& img": {
      height: "30px",
      width: "30px",
    },
  },
  name: {
    whiteSpace: "normal",
    overflow: "hidden",
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "line-clamp": 2,
    "-webkit-box-orient": "vertical",
  },
}));

export default function CourierCompanySelectPanelComponent({
  value,
  anchorRef,
  open,
  handleClose,
  isFetching,
  couriers,
  handleSelectCourier,
  lang,
  isError,
  handleReload,
}) {
  const classes = useStyle();

  return (
    <Popper
      open={open}
      style={{ zIndex: 99 }}
      anchorEl={anchorRef.current}
      role={undefined}
      transition
      disablePortal
      placement="bottom-start"
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === "bottom-start" ? "center top" : "center bottom",
          }}
        >
          <Paper className={classes.paper}>
            <ClickAwayListener onClickAway={handleClose}>
              <Box>
                <PerfectScrollbar>
                  {isFetching ? (
                    <Box p={1}>
                      <Skeleton variant="text" animation="wave" />
                      <Skeleton variant="text" animation="wave" />
                      <Skeleton variant="text" animation="wave" />
                      <Skeleton variant="text" animation="wave" />
                    </Box>
                  ) : isError ? (
                    <Box style={{ textAlign: "center" }}>
                      <RefreshTable handleReload={handleReload} />
                    </Box>
                  ) : couriers.length > 0 ? (
                    <div>
                      <Box height="25vh" overflow="auto">
                        {couriers.map((courier, index) => {
                          return (
                            <React.Fragment key={index}>
                              <MenuItem
                                style={{ padding: "8px 16px" }}
                                onClick={() => handleSelectCourier(courier)}
                              >
                                <Box display="flex">
                                  <Box display="flex" alignItems="center">
                                    <Box className={classes.courierPhoto}>
                                      {!courier.logo ? (
                                        <img
                                          src={DefaultImg}
                                          alt="emptyProduct"
                                        />
                                      ) : (
                                        <img
                                          src={courier.logo}
                                          alt={courier.name}
                                        />
                                      )}
                                    </Box>
                                    <Box className={classes.name}>
                                      {courier.name}
                                    </Box>
                                  </Box>
                                </Box>
                              </MenuItem>
                            </React.Fragment>
                          );
                        })}
                      </Box>
                    </div>
                  ) : (
                    <Box p={3} style={{ textAlign: "center" }}>
                      <Typography variant="body1">
                        {getLang(lang, "label.NO_DATA_AVAILABLE")}
                      </Typography>
                    </Box>
                  )}
                </PerfectScrollbar>
              </Box>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}
