import axios from "axios";

const connection = process.env.REACT_APP_AUTH_CONNECTION
const connectionId = process.env.REACT_APP_AUTH_CONNECTION_ID

export default function resetPassword({
    email,
    token,
    password,
    confirmPassword
}) {
    return axios.post(`/authentication/api/basic/reset-password`,
        {
            connection,
            connection_id: connectionId,
            email,
            reset_password_token: token,
            password: password,
            confirm_password: confirmPassword
        })
}