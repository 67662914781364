import axios from "axios";

const endpoint_url = "/payment/api/v1/transactions/history-top";

const get = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(endpoint_url)
      .then(response => resolve(response.data))
      .catch(error => {
        return reject(error.response.data);
      });
  });
};

const latestTransaction = {
  get
};

export default latestTransaction;
