import React, { useState } from "react";
import LoginFormComponent from "./loginForm.component";
import { connect, useSelector } from "react-redux";
import { login } from "../../../redux/action";
import { selectLoading } from "modules/notification";
import { unwrapResult } from "@reduxjs/toolkit";

function LoginFormContainer({ login, isLoading }) {
  const lang = useSelector(state => state.constant.languages);
  const [isPasswordShown, setIsPasswordShown] = useState(false)

  const handleClickShowPassword = () => {
    setIsPasswordShown(prev => !prev)
  }

  const handleMouseDownPassword = event => {
    event.preventDefault();
  }

  // const [recaptchaToken, setRecaptchaToken] = useState("");
  // const { executeRecaptcha } = useGoogleReCaptcha();

  const handleLogin = async (values, formikApi) => {
    //await generateRecaptcha();

    login({
      username: values.username,
      password: values.password,
      rememberMe: values.isStaySignedIn
    })
      .then(unwrapResult)
      .catch(() => {
        formikApi.setSubmitting(false)
      });
  };

  // async function generateRecaptcha() {
  //   await executeRecaptcha("login_by_email").then(value => {
  //     setRecaptchaToken(value);
  //   });
  // }

  return (
    <LoginFormComponent
      handleLogin={handleLogin}
      isLoading={isLoading}
      lang={lang}
      handleClickShowPassword={handleClickShowPassword}
      handleMouseDownPassword={handleMouseDownPassword}
      isPasswordShown={isPasswordShown}
    />
  );
}

const mapDispatchToProps = dispatch => ({
  login: payload => dispatch(login(payload))
});

const mapStateToProps = state => ({
  isLoading: selectLoading(state, login.typePrefix)
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginFormContainer);
