import React from 'react'
import {
  Box,
  makeStyles,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import { StyledTableCell, StyledTableRow } from 'components/table';
import ErrorIcon from '@material-ui/icons/Error';
import { convertToBigNum } from 'lib/generalUtility';
import { getLang } from 'app/feature/constants';

const useStyle = makeStyles(theme => ({
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    borderCollapse: "separate",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0
    },
    "& th,td": {
      fontSize: ".75rem",
      borderRight: "1px solid rgba(58, 77, 84, 0.1)",
      borderBottom: "none",
      padding: theme.spacing(1, 2, 1, 2)
    },
    "& th": {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightBold
    },
  },
  tableContainer: {
    maxHeight: 140,
    overflowY: "auto",
    border: "1px solid rgba(0, 0, 0, 0.1);",
    borderRadius: 4,
  },
  emptyContainer: {
    maxHeight: 100,
    backgroundColor: theme.palette.background.paper,
    justifyContent: "center",
    border: "1px solid rgba(0, 0, 0, 0.1);",
    borderRadius: 4,
    display: "flex",
  },
}));

export default function GiftTableComponent({ giftDropdown, tableData, pointCut, lang }) {
  const classes = useStyle()

  const displayPtsAfter = (gift, item, pointCut) => {
    let points = 0;

    if(gift) {
      points = convertToBigNum((gift.point * (1 - pointCut)).toFixed(0))
    } else {
      points = convertToBigNum((item.point * (1 - pointCut)).toFixed(0))
    }

    if(points > 0) {
      return points
    }

    return 1
  }

  return (
    <>
      {tableData.length > 0 ? (
        <TableContainer className={classes.tableContainer}>
          <Table
            className={classes.table}
            size="small"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell>{getLang(lang,"label.NUM")}</StyledTableCell>
                <StyledTableCell>{getLang(lang,"label.GIFT_NAME")}</StyledTableCell>
                <StyledTableCell>{getLang(lang,"label.QUANTITY")}</StyledTableCell>
                <StyledTableCell>{getLang(lang,"label.PTS_BEFORE")}</StyledTableCell>
                <StyledTableCell>{getLang(lang,"label.PTS_AFTER")}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                tableData.map((item, index) => {
                  const gift = giftDropdown.find(p => p.id === item.id)

                  return (
                    <StyledTableRow
                      key={index}
                      hover
                      tabIndex={-1}
                    >
                      <>
                        <StyledTableCell>{index + 1}</StyledTableCell>
                        <StyledTableCell>
                          {
                            gift ? gift.name : item.name
                          }
                        </StyledTableCell>
                        <StyledTableCell>
                          {
                            gift ? convertToBigNum(gift.quantity) : convertToBigNum(item.quantity)
                          }
                        </StyledTableCell>
                        <StyledTableCell>
                          {
                            gift ? convertToBigNum(gift.point) : convertToBigNum(item.point)
                          }
                        </StyledTableCell>
                        <StyledTableCell>
                          {
                            displayPtsAfter(gift, item, pointCut)
                          }
                        </StyledTableCell>
                      </>
                    </StyledTableRow>
                  );
                })
              }
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box>
            <Table
            className={classes.table}
            size="small"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell>{getLang(lang,"label.NUM")}</StyledTableCell>
                <StyledTableCell>{getLang(lang,"label.GIFT_NAME")}</StyledTableCell>
                <StyledTableCell>{getLang(lang,"label.QUANTITY")}</StyledTableCell>
                <StyledTableCell>{getLang(lang,"label.PTS_BEFORE")}</StyledTableCell>
                <StyledTableCell>{getLang(lang,"label.PTS_AFTER")}</StyledTableCell>
              </TableRow>
            </TableHead>
            </Table>
            <Box py={7} className={classes.emptyContainer}>
          <ErrorIcon style={{ paddingRight: 5 }}></ErrorIcon>
          <Typography variant="body1">
            {getLang(lang,"label.NO_GIFT_ADDED")}
          </Typography>
        </Box>
        </Box>
        
      )}
    </>
  )
}