import React from "react";
import BrandTitleCard from './brandTitleCard.component';
import { useDispatch, useSelector } from "react-redux";
import { selectLoading } from "modules/notification";
import { getProductBrandDetailData } from "modules/reporting/redux";
import { useEffect } from "react";
import moment from "moment";

export default function BrandTitleCardContainer({
    currentFilter,
    urlBrandDetail,
    translate
}){
    const dispatch = useDispatch();

    // (1) get product brand detail data -- start
    const isFetchingProductBrandDetailData = useSelector(state => selectLoading(state, getProductBrandDetailData.typePrefix));
    const productBrandDetailData = useSelector(state => state.reporting.product.productBrandDetailData);
    // (1) get product brand detail data -- end

    useEffect(() => {
        dispatch(getProductBrandDetailData({
            branch_uuid: currentFilter.branchUuid,
            brand_uuid: currentFilter.brandUuid,
            period: currentFilter.period,
            start_date: moment(currentFilter.dateRange[0]).format('YYYY-MM-DD'),
            end_date: moment(currentFilter.dateRange[1]).format('YYYY-MM-DD'),
            url: urlBrandDetail
        }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentFilter, dispatch])

    return (
        <BrandTitleCard 
            status={{
                isFetchingProductBrandDetailData
            }}

            data={{
                productBrandDetailData
            }}

            currentFilter={currentFilter}

            translate={translate}
        />
    )
}