import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import LoyaltyProgramPage from "./loyalty-program.page";
import { LOYALTY_PROGRAM_VIEW, BONANZA_VIEW, BONANZA_ADD, LOYALTY_PROGRAM_ADD, GIFT_VIEW, GIFT_ADD, GIFT_REDEMPTION_VIEW, POINT_CUT_ADD, POINT_CUT_VIEW } from "lib/constants/accessRights";
import { useHistory } from "react-router-dom";
import LoadingComponent from "components/loading";
import { getLang } from "app/feature/constants";
import { ROOT } from "modules/loyalty-program";
import { useLocation } from "react-router";
import qs from "query-string";

function LoyaltyProgramPageContainer() {
  const history = useHistory()
  const hasLoyaltyProgramViewAccessRight = useSelector(state => state.profile.accessRights.includes(LOYALTY_PROGRAM_VIEW));
  const hasLoyaltyProgramAddAccessRight = useSelector(state => state.profile.accessRights.includes(LOYALTY_PROGRAM_ADD));
  const hasBonanzaViewAccessRight = useSelector(state => state.profile.accessRights.includes(BONANZA_VIEW));
  const hasBonanzaAddAccessRight = useSelector(state => state.profile.accessRights.includes(BONANZA_ADD));
  const hasGiftViewAccessRight = useSelector(state => state.profile.accessRights.includes(GIFT_VIEW));
  const hasGiftAddAccessRight = useSelector(state => state.profile.accessRights.includes(GIFT_ADD));
  const hasGiftRedemptionViewAccessRight = useSelector(state => state.profile.accessRights.includes(GIFT_REDEMPTION_VIEW));
  const hasPointCutViewAccessRight = useSelector(state => state.profile.accessRights.includes(POINT_CUT_VIEW));
  const hasPointCutAddAccessRight = useSelector(state => state.profile.accessRights.includes(POINT_CUT_ADD));
  const lang = useSelector(state => state.constant.languages);
  const [activeIndex, setActiveIndex] = useState(0);
  const location = useLocation();

  const qsParams = qs.parse(location.search);
  const currentSearch = qsParams.tab || '';

  useEffect(() => {
    if (!hasLoyaltyProgramViewAccessRight) {
      history.push('/admin/dashboard')
    }
  }, [history, hasLoyaltyProgramViewAccessRight])

  useEffect(() => {
    let tempIndex= 0
    switch(currentSearch) {
      case "loyaltyPoint": 
        tempIndex = 0
        break;
      case "bonanza": 
        tempIndex = 1
        break;
      case "giftList": 
        tempIndex = 2
        break;
      case "redemption": 
        tempIndex = 3
        break;
      case "pointCut": 
        tempIndex = 4
        break;
      default:
        tempIndex = 0
    }
    setActiveIndex(tempIndex)
  }, [currentSearch])

  if (!hasLoyaltyProgramViewAccessRight) return <LoadingComponent />;
  
  // if (!(hasGiftViewAccessRight || hasGiftRedemptionViewAccessRight || hasPointCutViewAccessRight)) return <LoadingComponent />;
  
  const tabLabels = [];
  if (hasLoyaltyProgramViewAccessRight) {
    tabLabels.push(getLang(lang,"label.LOYALTY_POINT_TAB"))
  }
  if (hasBonanzaViewAccessRight) {
    tabLabels.push(getLang(lang,"label.BONANZA_TAB"))
  }
  if (hasGiftViewAccessRight) {
    tabLabels.push(getLang(lang,"label.GIFT_LIST_TAB"))
  }
  if (hasGiftRedemptionViewAccessRight) {
    tabLabels.push(getLang(lang,"label.GIFT_REDEMPTION_TAB"))
  }
  if (hasPointCutViewAccessRight) {
    tabLabels.push(getLang(lang,"label.POINTS_CUT_TAB"))
  }

  const handleChange = (event, index) => {
    setActiveIndex(index);
    history.replace({
      pathname: ROOT,
      search: `?tab=${index === 0 ? 'loyaltyPoint' : index === 1 ? 'bonanza' : index === 2 ? 'giftList' : index === 3 ? 'redemption' : 'pointCut'}`
    })
  };

  return (
    <LoyaltyProgramPage
      hasLoyaltyProgramViewAccessRight={hasLoyaltyProgramViewAccessRight}
      hasLoyaltyProgramAddAccessRight={hasLoyaltyProgramAddAccessRight}
      hasBonanzaViewAccessRight={hasBonanzaViewAccessRight}
      hasBonanzaAddAccessRight={hasBonanzaAddAccessRight}
      hasGiftViewAccessRight={hasGiftViewAccessRight}
      hasGiftAddAccessRight={hasGiftAddAccessRight}
      hasGiftRedemptionViewAccessRight={hasGiftRedemptionViewAccessRight}
      hasPointCutViewAccessRight={hasPointCutViewAccessRight}
      hasPointCutAddAccessRight={hasPointCutAddAccessRight}
      handleChange={handleChange}
      activeIndex={activeIndex}
      tabLabels={tabLabels}
      lang={lang}
    />
  );
}

export default LoyaltyProgramPageContainer;
