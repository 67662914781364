import axios from "axios"

const url = "/distributor/admin/api/v1/dealers"

const get = (uuid) => {
  return new Promise((resolve, reject) => {
    axios.get(`${url}/${uuid}`)
      .then(response => resolve(response))
      .catch(error => reject(error.response));
  })
}

const postUpdate = ({
  uuid,
  firstName, 
  lastName,
  phoneCode,
  phoneNumber,
  gender,
  dateOfBirth
}) => {
  const body = {
    first_name: firstName,
    last_name: lastName,
    phone_code: phoneCode,
    phone_number: phoneNumber,
    gender: gender,
    dob: dateOfBirth
  }
  return new Promise((resolve, reject) => {
    axios
      .post(`${url}/${uuid}/update`, body)
      .then((response) => resolve(response))
      .catch((error) => {
        return reject(error.response);
      });
  });
}

const dealerDetails = {
  get,
  postUpdate
}

export default dealerDetails;