import React from "react";
import { makeStyles, Box, Typography } from '@material-ui/core';
import DecorationSvg from "modules/trackco/utils/image/svg/decoration.svg";
import WelcomeImg from "modules/trackco/utils/image/welcomeImg.png";
import DefaultProfile from "assets/img/defaultPhoto.png";
import halfCircle from "assets/img/halfCircle.png";
import clsx from "clsx"
import { Skeleton } from '@material-ui/lab';
import Footer from "../../../components/footer"
import { getLang } from "app/feature/constants";

const useStyle = makeStyles(theme => ({
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  topCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  mainContainer: {
    minHeight: "100vh",
    display: "flex",
  },
  subContainer3: {
    height: "94vh",
    '@media(min-height: 650px)': {
      position: "fixed",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center"
    }
  },
  subContainer2: {
    flexDirection: "column",
    backgroundImage: `url(${DecorationSvg})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "bottom",
    backgroundSize: "contain",
    backgroundPositionY: "101.5%",
    width: "41%",
    [theme.breakpoints.up('xs')]: {
      display: "none"
    },
    '@media(min-width: 1000px)': {
      width: "50%",
      display: "flex",
      paddingTop: "30px"
    },
    [theme.breakpoints.up('lg')]: {
      display: "flex"
    },
  },
  subContainer1: {
    backgroundColor: "#fff",
    width: "59%",
    paddingBottom: "40px",
    backgroundImage: `url(${halfCircle})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top",
    backgroundSize: "contain",
    [theme.breakpoints.up('xs')]: {
      width: "100%",
    },
    '@media(min-width: 1000px)': {
      width: "50%"
    },
    [theme.breakpoints.up('lg')]: {
      width: "59%",
    },
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  welcomeContainer: {
    width: "496px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  navBar: {
    width: "466px",
    backgroundColor: "#ffffff",
    height: "40px",
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    paddingLeft: "10px",
  },
  circle: {
    width: "17px",
    height: "17px",
    borderRadius: "50%",
  },
  welcomeTextContainer: {
    backgroundColor: '#fff',
    width: "448px",
    borderRadius: "13px",
    padding: "24px",
    position: "relative",
    top: '-4px'
  },
  welcomeTitle: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    textAlign: "center",
  },
  welcomeDescription: {
    fontFamily: "Montserrat",
    textAlign: "center",
    fontSize: "18px",
    paddingTop: "20px"
  },
  profileTxt: {
    fontFamily: "Montserrat",
    [theme.breakpoints.up('xs')]: {
      marginTop: "15px",
      textAlign: "center",
      fontSize: "14px",
      marginLeft: "5%",
      marginRight: "5%",
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: "30px",
      fontSize: "16px"
    },
  },
  childrenContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginTop: "10%",
    marginLeft: "10%",
    marginRight: "10%",
    '@media(min-width: 700px)': {
      marginTop: "12%",
    },
    [theme.breakpoints.up('md')]: {
      marginTop: "13%",
    },
    '@media(min-width: 1000px)': {
      marginTop: "10%",
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: "12%",
    },
  },
  profileSkeleton: {
    [theme.breakpoints.up('xs')]: {
      width: "50px",
      height: "50px",
    },
    '@media(min-width: 400px)': {
      width: "65px",
      height: "65px",
    },
    '@media(min-width: 500px)': {
      width: "70px",
      height: "70px",
    },
    [theme.breakpoints.up('sm')]: {
      width: "75px",
      height: "75px",
    },
    '@media(min-width: 700px)': {
      width: "80px",
      height: "80px",
    },
    '@media(min-width: 1000px)': {
      width: "65px",
      height: "65px",
    },
    '@media(min-width: 1100px)': {
      width: "75px",
      height: "75px",
    },
    '@media(min-width: 1200px)': {
      width: "80px",
      height: "80px",
    },
  },
  footer: {
    marginLeft: "10%"
  }
}));

export default function LoginLayoutComponent({
  children,
  header,
  profileStyle,
  profilePhotoStyle,
  isLoading,
  branch,
  distributor,
  lang
}) {
  const classes = useStyle();

  const displayProfilePicture = () => {
    if ((distributor === undefined && branch === undefined) || (distributor === null && branch === null)) {
      return DefaultProfile
    }    
    if (distributor === undefined || distributor === null || distributor.uuid === "") {
      if (branch.picture === "" || branch.picture === undefined || branch.picture === null) {
        return DefaultProfile
      }
      return branch.picture
    }
    if (distributor.picture === "" || distributor.picture === null) {
      return DefaultProfile
    }
    return distributor.picture
  }

  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.subContainer1}>
        <Box>
          <Box style={{ padding: "6% 0% 0% 10%" }}>
            {header}
          </Box>
          <Box className={clsx(classes.center, profileStyle)}>
            {isLoading ? (
              <Skeleton variant='circle' className={classes.profileSkeleton} />
            ) : (
              <img src={displayProfilePicture()} alt="profile" className={profilePhotoStyle} style={{ borderRadius: "50%" }} />
            )}
            {isLoading ? (
              <Skeleton variant='text' className={classes.profileTxt} style={{ width: "50%" }} />
            ) : distributor.uuid === "" ? (
              <Typography variant="body1" className={classes.profileTxt}>
                {getLang(lang, 'paragraph.YOU_HAVE_BEEN_INVITED')} <span style={{ fontWeight: "700", color: "#3A7F3C" }}>{branch.name}</span>
              </Typography>
            ) : (
              <Typography variant="body1" className={classes.profileTxt}>
                <span style={{ fontWeight: "700", color: "#3A7F3C" }}>{distributor.firstName} {distributor.lastName}</span> {getLang(lang, 'paragraph.INVITING_YOU_TO_JOIN')} <span style={{ fontWeight: "700", color: "#3A7F3C" }}>{branch.name}</span>
              </Typography>
            )}
          </Box>
          <Box className={classes.childrenContainer}>
            {children}
          </Box>
        </Box>
        <Box className={classes.footer}>
          <Footer type="logo" />
        </Box>
      </Box>
      <Box className={clsx(classes.subContainer2, classes.topCenter)}>
        <Box className={classes.subContainer3} >
          <Box className={classes.welcomeContainer}>
            <Box className={classes.navBar}>
              <Box className={classes.circle} style={{ marginRight: '12px', backgroundColor: "#FF7575" }}></Box>
              <Box className={classes.circle} style={{ marginRight: '12px', backgroundColor: '#FFEA28' }}></Box>
              <Box className={classes.circle} style={{ backgroundColor: '#15F16D' }}></Box>
            </Box>
            <Box>
              <img src={WelcomeImg} alt="welcomeImg" />
            </Box>
            <Box className={classes.welcomeTextContainer}>
              <Typography variant='h5' className={classes.welcomeTitle}>{getLang(lang, 'paragraph.WELCOME_TO_TRACKCO')}</Typography>
              <Typography varaint="body1" className={classes.welcomeDescription}>
                {getLang(lang, 'paragraph.AVAILABLE_CURATED_MOBILE_APPLICATION')}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}