import React from "react";
import {
  makeStyles,
  Box,
  Typography,
  Paper,
  RadioGroup,
  InputAdornment,
  FormControlLabel,
  Radio,
  Divider,
  Button,
  IconButton,
} from "@material-ui/core";
import InputTextField from "components/input/inputTextField";
import { getLang } from "app/feature/constants";
import { AddIcon as AddIconGreen } from "components/icon";
import { Delete as DeleteIcon, Error as ErrorIcon } from "@material-ui/icons";

const useStyle = makeStyles((theme) => ({
  inputContainer: {
    "&:not(:last-child)": {
      marginBottom: "20px",
    },
    padding: "20px",
  },
  inputLabel: {
    fontSize: "16px",
    color: "#101828",
  },
  inputSubLabel: {
    fontSize: "14px",
    color: "#98A2B3",
  },
  inputSecondarySubLabel: {
    fontSize: "14px",
    color: "#101828",
  },
  inputEndormentLabel: {
    fontSize: "14px",
    color: "#101828",
  },
  deleteIcon: {
    color: "#F04438",
    cursor: "pointer",
  },
  errorIcon: {
    color: "#F04438",
  },
}));

export default function EmailSettingCardComponent({
  attribute,
  isEditable,
  formik,
  attributeValue,
  lang,
  isSubmitting,
  invalidEmails,
  setInvalidEmails,
}) {
  const classes = useStyle();

  return (
    <Paper className={classes.inputContainer} elevation={2}>
      <Typography className={classes.inputLabel}>{attribute.label}</Typography>
      {attribute.subLabel && (
        <Typography className={classes.inputSubLabel}>
          {attribute.subLabel}
        </Typography>
      )}
      <RadioGroup
        name={attribute.fieldName}
        defaultValue={attributeValue}
        value={attributeValue}
        onChange={(e) => {
          formik.setFieldValue(attribute.fieldName, parseInt(e.target.value));
        }}
      >
        {attribute.values.map((value, index) => (
          <Box key={index}>
            <FormControlLabel
              disabled={!isEditable || isSubmitting}
              label={value.label}
              value={value.value}
              control={<Radio />}
            />
          </Box>
        ))}
      </RadioGroup>
      {attributeValue === 3 && (
        <Box
          mt={2}
          borderColor="rgba(0, 0, 0, 0.1)"
          border={1}
          borderRadius={6}
        >
          <Box p={2}>
            {formik.values.emailRecipients.map((email, index) => (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100%"
                mt={index ? 1 : 0}
              >
                <InputTextField
                  variant="filled"
                  size="small"
                  fullWidth
                  disabled={isSubmitting}
                  placeholder={getLang(lang, "placeholder.EMAIL")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        {invalidEmails.includes(index) && (
                          <ErrorIcon size={18} className={classes.errorIcon} />
                        )}
                        <IconButton
                          edge="end"
                          onClick={() => {
                            let temp = [...formik.values.emailRecipients];
                            if (temp.length === 1) {
                              temp = [""];
                            } else {
                              temp.splice(index, 1);
                            }

                            formik.setFieldValue("emailRecipients", temp);

                            let tempInvalid = [...invalidEmails];
                            const invalidIdx = invalidEmails.indexOf(index);
                            if (invalidIdx > -1) {
                              tempInvalid.splice(invalidIdx, 1);
                            }
                            setInvalidEmails(tempInvalid);
                          }}
                        >
                          <DeleteIcon
                            size={18}
                            className={classes.deleteIcon}
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    let temp = [...formik.values.emailRecipients];
                    temp[index] = e.target.value;
                    formik.setFieldValue("emailRecipients", temp);

                    let tempInvalid = [...invalidEmails];
                    const invalidIdx = invalidEmails.indexOf(index);
                    if (invalidIdx > -1) {
                      tempInvalid.splice(invalidIdx, 1);
                    }
                    setInvalidEmails(tempInvalid);
                  }}
                  value={email}
                />
              </Box>
            ))}
            {!!invalidEmails.length && (
              <Box mt={1}>
                <Typography style={{ color: "#f44336" }} variant="caption">
                  <b>{getLang(lang, "paragraph.INVALID_EMAIL_FORMAT")}</b>
                </Typography>
              </Box>
            )}
            {!!formik.values.emailRecipients.length && (
              <Box mt={3} mb={2}>
                <Divider />
              </Box>
            )}
            <Box display="flex">
              <Button
                variant="text"
                color="primary"
                startIcon={
                  <AddIconGreen
                    color="secondary"
                    style={{ fontSize: "13px" }}
                  />
                }
                disableElevation
                onClick={() => {
                  const temp = [...formik.values.emailRecipients];

                  temp.push("");

                  formik.setFieldValue("emailRecipients", temp);
                }}
                disabled={
                  isSubmitting || formik.values.emailRecipients.length >= 20
                }
              >
                {getLang(lang, "label.ADD_EMAIL")}
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </Paper>
  );
}
