import React from "react";
import {
  makeStyles,
  Box,
  Typography,
  IconButton,
  Divider,
  Tabs
} from "@material-ui/core";
import PropTypes from "prop-types";
import CustomTab from "components/tab";
import Dialog from "components/dialog/customDialog";
import CloseIcon from "@material-ui/icons/Close";
import ApprovalWarrantyPanel from "../../panel/approvalWarrantyPanel";
import WarrantyClaimPanel from "../../panel/warrantyClaimPanel";


const style = (theme) => ({
  infoContainer: {
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    }
  },
  header: {
    paddingTop: "4px",
    paddingLeft: theme.spacing(3),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  indicator: {
    border: "0.5px solid",
    borderColor: theme.palette.primary.main
  },
  scroll: {
    width: "min-content"
  },
});

const useStyles = makeStyles(style);

export default function WarranteeDetailDialogComponent({
  isOpen,
  handleClose,
  handleChange,
  activeIndex,
  language,
  tabLabels,
  pageSetting,
  hasClaimListViewAccessRight,
}) {
  const classes = useStyles();
  function a11yProps(index, label = "label") {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tab-${index}`,
      label: <Typography variant="button" children={label} />
    };
  }

  function TabPanel({ children, value, index, ...other }) {
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </Typography>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
  };

  return (
    <Dialog
      isOpen={isOpen}
      handleClose={handleClose}
      disableEnforceFocus={true}
      disableAutoFocus={true}
      disableEscapeKeyDown={true}
      disableBackdropClick={true}
      maxWidth="80%"
    >
      <Box className={classes.header}>
        <Tabs
          value={activeIndex}
          onChange={handleChange}
          aria-label="scrollable auto tabs example"
          textColor="primary"
          variant="scrollable"
          indicatorColor="primary"
          classes={{
            indicator: classes.indicator,
            scrollButtons: classes.scroll
          }}
        >
          {tabLabels.map((item, index) => {
            return <CustomTab key={index} {...a11yProps(index, item)} />;
          })}
        </Tabs>
        <IconButton color="primary" onClick={handleClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>

      <Divider />

      <Box>
        <TabPanel value={activeIndex} index={0}>
          <ApprovalWarrantyPanel pageSetting={pageSetting} language={language} />
        </TabPanel>
        {hasClaimListViewAccessRight && (
          <TabPanel value={activeIndex} index={1}>
            <WarrantyClaimPanel />
          </TabPanel>
        )}
      </Box>
    </Dialog>
  );
}
