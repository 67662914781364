import React from "react";
import BackDropContainer from "./backdrop.container";
import {
  showBackdrop,
  hideBackdrop,
  reducer as backdropReducer
} from "./redux";

export default function BackDrop() {
  return <BackDropContainer />;
}

export { showBackdrop, hideBackdrop, backdropReducer };
