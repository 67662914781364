import { createSlice } from "@reduxjs/toolkit";
import { ACCOUNT } from "../../constants";
import fallbackLogo from "assets/img/img-default.png";
import {
  getAvailableCurrencyConfigListing,
  getUnusedCurrencyConfigData,
  bulkUpdateCurrencyStatus,
  updateAccount,
  updateCurrencyConfigStatus,
  getCompanyDisbursement,
} from "../action";

const initialState = {
  id: 0,
  name: "",
  description: "",
  shortName: "",
  addressLine1: "",
  addressLine2: "",
  city: "",
  country: "",
  postalCode: "",
  state: "",
  registerationNo: null,
  status: true,
  contactCode: "",
  contactNumber: "",
  companyEmail: "",
  industry: 0,
  themes: null,
  logoImg: null,
  created_at: "",
  updated_at: "",
  deleted_at: "",
  timezone: "Asia/Kuala_Lumpur",
  currencyConfig: {
    totalRecords: 0,
    data: [],
    isError: false,
    unusedCurrency: {
      data: [],
      isError: false,
      filteredRecords: 0,
      totalRecords: 0,
    },
  },
  disbursementInfos: [],
  // newsletter: true,
  // code: null,
  // shortName: "",
  // countryCode: "",
  // countryName: "",
  // website: "",
  // referralCode: ""
};

const accountSlice = createSlice({
  name: ACCOUNT,
  initialState,
  reducers: {
    setAccount(state, action) {
      const { payload } = action;

      // city: "Jakarta";
      // contact: "6018273917";
      // country: "Indonesia";
      // created_at: "2021-01-13T10:31:44.000000Z";
      // deleted_at: null;
      // email: "pr@somecompany.com";
      // industry: "8";
      // line1: "Kelapa Gading";
      // line2: "Kelapa gading";
      // name: "SMCP";
      // postal_code: "14250";
      // reg_no: "342";
      // remarks: null;
      // state: "DKI Jakarta";
      // status: "approved";
      // themes: null;
      // updated_at: "2021-01-18T09:40:45.000000Z";
      // uuid: "b5443115726d423aadf3f38376f59f6e";

      /**
       * !missing:
       * - name, not short name
       * - newsletter
       * - countryCode
       * - website
       * - referralCode
       */
      if (payload != null) {
        state.id = payload.uuid || state.id;
        state.name = payload.name || state.name;
        state.description = payload.description || state.description;
        state.shortName = payload.short_name || state.shortName;
        state.addressLine1 = payload.line1 || state.addressLine1;
        state.addressLine2 = payload.line2 || state.addressLine2;
        state.city = payload.city || state.city;
        state.country = payload.country || state.country;
        state.postCode = payload.postal_code || state.postCode;
        state.state = payload.state || state.state;
        state.registerationNo = payload.reg_no || state.registerationNo;
        state.status = payload.status || state.status;
        state.contactCode = payload.contact_code || state.contactCode;
        state.contactNumber = payload.contact || state.contactNumber;
        state.companyEmail = payload.email || state.companyEmail;
        state.industry = payload.industry || state.industry;
        state.themes = payload.themes || state.themes;
        if (payload.image_logo_url)
          state.logoImg = payload.image_logo_url.url || state.logoImg;
        state.created_at = payload.created_at || state.created_at;
        state.updated_at = payload.updated_at || state.updated_at;
        state.deleted_at = payload.deleted_at || state.deleted_at;
        state.timezone = payload.timezone || state.timezone;
        // state.newsletter = true;
        // state.city = payload.city;
        // state.countryCode = "62";
        // state.countryName = payload.country;
        // state.website = payload.website || "";
        // state.referralCode = payload.referralCode || "";
      }
    },
    setAccountProfilePicture(state, action) {
      state.logoImg = action.payload || fallbackLogo;
    },
    changeBulkSelected(state, action) {
      const { payload } = action;

      state.currencyConfig.data.forEach((currency) => {
        currency.isSelected = currency.isDefault ? false : payload.value;
      });
    },
    changeSelectedCurrencyConfig(state, action) {
      const { payload } = action;

      state.currencyConfig.data[payload.index].isSelected = payload.isSelected;
    },
  },
  extraReducers: {
    [updateAccount.fulfilled]: (state, action) => {
      const {
        name,
        description,
        shortName,
        addressLine1,
        addressLine2,
        city,
        country,
        postCode,
        region,
        registerationNo,
        contactCode,
        contactNumber,
        companyEmail,
        industry,
        timezone,
      } = action.meta.arg;
      state.name = name;
      state.description = description;
      state.shortName = shortName;
      state.addressLine1 = addressLine1;
      state.addressLine2 = addressLine2;
      state.city = city;
      state.country = country;
      state.postCode = postCode;
      state.region = region;
      state.registerationNo = registerationNo;
      state.contactCode = contactCode;
      state.contactNumber = contactNumber;
      state.companyEmail = companyEmail;
      state.industry = industry;
      state.timezone = timezone;
    },
    [getAvailableCurrencyConfigListing.fulfilled]: (state, action) => {
      const { payload } = action;

      const temp = [];

      state.currencyConfig.totalRecords = payload.recordsTotal;

      payload.data.forEach((item) => {
        temp.push({
          name: item.name,
          alpha3: item.alpha3,
          status: item.status,
          isDefault: item.isDefault,
          isSelected: false,
        });
      });

      state.currencyConfig.data = temp;
      state.currencyConfig.isError = false;
    },
    [getAvailableCurrencyConfigListing.rejected]: (state) => {
      state.currencyConfig.isError = true;
    },
    [getUnusedCurrencyConfigData.fulfilled]: (state, action) => {
      const { payload } = action;

      const temp = [];

      payload.data.data.forEach((item) => {
        temp.push({
          name: item.name,
          alpha3: item.alpha3,
        });
      });

      state.currencyConfig.unusedCurrency.filteredRecords =
        payload.data.filteredRecords;
      state.currencyConfig.unusedCurrency.totalRecords =
        payload.data.recordsTotal;
      state.currencyConfig.unusedCurrency.data = temp;
      state.currencyConfig.unusedCurrency.isError = false;
    },
    [getUnusedCurrencyConfigData.rejected]: (state) => {
      state.currencyConfig.unusedCurrency.isError = true;
    },
    [bulkUpdateCurrencyStatus.fulfilled]: (state) => {
      // const { payload } = action;
      state.currencyConfig.isError = false;
    },
    [bulkUpdateCurrencyStatus.rejected]: (state) => {
      state.currencyConfig.isError = true;
    },
    [updateCurrencyConfigStatus.fulfilled]: (state, action) => {
      const { payload } = action;
      const index = state.currencyConfig.data.findIndex(
        ({ alpha3 }) => alpha3 === payload.data.alpha3
      );

      if (index !== -1)
        state.currencyConfig.data[index].status = payload.data.status;
    },
    [bulkUpdateCurrencyStatus.fulfilled]: (state, action) => {
      const { payload } = action;

      payload.data.alpha3.forEach((currency) => {
        let index = state.currencyConfig.data.findIndex(
          ({ alpha3 }) => alpha3 === currency
        );
        if (index !== -1)
          state.currencyConfig.data[index].status = payload.data.status;
      });
      state.currencyConfig.data.forEach((currency) => {
        currency.isSelected = false;
      });
    },
    [getCompanyDisbursement.pending]: (state) => {
      state.disbursementInfos = initialState.disbursementInfos;
    },
    [getCompanyDisbursement.fulfilled]: (state, action) => {
      const { payload } = action;
      state.disbursementInfos = payload;
    },
  },
});

export const {
  setAccount,
  setAccountProfilePicture,
  changeBulkSelected,
  changeSelectedCurrencyConfig,
} = accountSlice.actions;
export const accountReducer = accountSlice.reducer;
