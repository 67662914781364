import React from "react";
import SurveyQuestionListingCardComponent from './surveyQuestionListingCard.component';
import { useSelector } from "react-redux";

export default function SurveyQuestionListingCardContainer({
  data,
  selected,
  handleSelectedQuestion,
  isQuestionFetching
}) {
  const lang = useSelector(state => state.constant.languages);

  return (
    <SurveyQuestionListingCardComponent 
      data={data}
      selected={selected}
      handleSelectedQuestion={handleSelectedQuestion}
      isQuestionFetching={isQuestionFetching}
      lang={lang}
    />
  )
}