import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { ROOT, VIEW_PROFILE } from "./config/employee.route";
import { MainPage, ViewProfilePage } from "./pages";

export default function EmployeeModule() {
  return (
    <Switch>
      <Route exact path={ROOT} component={MainPage} />
      <Route exact path={VIEW_PROFILE} component={ViewProfilePage} />
      <Route path="*">
        <Redirect to="/admin/invalid" />
      </Route>
    </Switch>
  );
}

export { ROOT };
