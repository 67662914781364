import React from "react";
import {
  makeStyles,
  Box,
  Typography,
  Paper,
  RadioGroup,
  InputAdornment,
  FormControlLabel,
  Radio,
  Collapse,
  Tooltip,
} from "@material-ui/core";
import InputTextField from "components/input/inputTextField";
import InputHelper from "lib/InputHelper";
import { getLang } from "app/feature/constants";
import HelpOutlinedIcon from "@material-ui/icons/HelpOutlined";

const useStyle = makeStyles((theme) => ({
  inputContainer: {
    "&:not(:last-child)": {
      marginBottom: "20px",
    },
    padding: "20px",
  },
  inputLabel: {
    fontSize: "16px",
    color: "#101828",
  },
  inputSubLabel: {
    fontSize: "14px",
    color: "#98A2B3",
  },
  inputSecondarySubLabel: {
    fontSize: "14px",
    color: "#101828",
  },
  inputEndormentLabel: {
    fontSize: "14px",
    color: "#101828",
  },
}));

export default function AutoCompleteStockTransferSettingCardComponent({
  enableAutoCompleteTransfer,
  actionAfterAutoCompleteTransfer,
  autoReceiveTransferDays,
  lang,
  updateFormikValue,
}) {
  const classes = useStyle();

  const options = [
    {
      label: getLang(lang, "label.ENABLE"),
      value: 1,
      enableCustomField: true,
    },
    {
      label: getLang(lang, "label.DISABLE"),
      value: 0,
      enableCustomField: false,
    },
  ];

  const actionOptions = [
    {
      label: getLang(lang, "paragraph.FORCE_RECEIVE_ALL_REMAINING_ITEMS"),
      value: 1,
    },
    {
      label: getLang(lang, "paragraph.SUSPEND_MARK_UNRECEIVED_MISSING"),
      value: 2,
    },
  ];

  return (
    <Paper className={classes.inputContainer} elevation={2}>
      <Typography className={classes.inputLabel}>
        {getLang(lang, "label.AUTO_COMPLETE_STOCK_TRANSFER")}
      </Typography>
      <Typography className={classes.inputSubLabel}>
        {getLang(lang, "paragraph.SET_AUTOMATICALLY_CONFIRM_RECEIVE_TRANSFER")}
      </Typography>
      <RadioGroup
        name={"autoCompleteTransfer"}
        defaultValue={enableAutoCompleteTransfer}
        value={enableAutoCompleteTransfer}
        onChange={(e) =>
          updateFormikValue(
            "enableAutoCompleteTransfer",
            parseInt(e.target.value)
          )
        }
      >
        {options.map((value, i) => (
          <Box key={i}>
            <FormControlLabel
              label={value.label}
              value={value.value}
              control={<Radio />}
            />
            {value.enableCustomField && (
              <Collapse in={enableAutoCompleteTransfer !== 0}>
                <Box mb={1}>
                  <Box pl={4} mb={3}>
                    <Box>
                      <Typography>
                        {getLang(lang, "label.AUTO_COMPLETE_TRANSFER_AFTER")}
                      </Typography>
                    </Box>
                    <InputTextField
                      min={7}
                      value={autoReceiveTransferDays}
                      type="number"
                      onPaste={(e) =>
                        InputHelper.preventCopyEDecimalAndNegetiveInNumberInput(
                          e
                        )
                      }
                      onKeyDown={(e) =>
                        InputHelper.preventTypeEDecimalAndNegetiveInNumberInput(
                          e
                        )
                      }
                      onBlur={() => {
                        if (parseInt(autoReceiveTransferDays, 10) < 7) {
                          updateFormikValue("autoReceiveTransferDays", 7);
                        }
                      }}
                      onChange={(e) =>
                        updateFormikValue(
                          "autoReceiveTransferDays",
                          e.target.value
                        )
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Box
                              px={1}
                              borderLeft="1px solid rgba(0, 0, 0, 0.1)"
                            >
                              <Typography
                                className={classes.inputEndormentLabel}
                              >
                                {getLang(lang, "label.DAY_S")}
                              </Typography>
                            </Box>
                          </InputAdornment>
                        ),
                        inputProps: {
                          min: 7,
                        },
                      }}
                    />
                  </Box>
                  <Box pl={4}>
                    <Box display="flex" alignItems="center">
                      <Typography style={{ marginRight: 2 }}>
                        {getLang(lang, "label.ACTION_AFTER_AUTO_COMPLETE")}
                      </Typography>
                      <Tooltip
                        title={
                          <Box>
                            <Typography variant="caption">
                              {getLang(
                                lang,
                                "paragraph.IF_SCANNED_MISSING_ITEMS"
                              )}
                            </Typography>
                            <Box>
                              <ul>
                                <li>
                                  <Typography variant="caption">
                                    {getLang(
                                      lang,
                                      "paragraph.ITEMS_CHANGE_TO_ACTIVE_STATUS"
                                    )}
                                  </Typography>
                                </li>
                                <li>
                                  <Typography variant="caption">
                                    {getLang(
                                      lang,
                                      "paragraph.TRANSFER_MARK_AS_RECEIVED_DESC"
                                    )}
                                  </Typography>
                                </li>
                              </ul>
                            </Box>
                          </Box>
                        }
                        arrow
                      >
                        <HelpOutlinedIcon color="primary" fontSize="small" />
                      </Tooltip>
                    </Box>
                    <RadioGroup
                      name={"actionAfterAutoCompleteTransfer"}
                      defaultValue={actionAfterAutoCompleteTransfer}
                      value={actionAfterAutoCompleteTransfer}
                      onChange={(e) =>
                        updateFormikValue(
                          "actionAfterAutoCompleteTransfer",
                          parseInt(e.target.value)
                        )
                      }
                    >
                      {actionOptions.map((value, index) => (
                        <Box
                          style={{ marginBottom: index === 0 ? -8 : 0 }}
                          key={index}
                        >
                          <FormControlLabel
                            label={value.label}
                            value={value.value}
                            control={<Radio />}
                          />
                        </Box>
                      ))}
                    </RadioGroup>
                  </Box>
                </Box>
              </Collapse>
            )}
          </Box>
        ))}
      </RadioGroup>
    </Paper>
  );
}
