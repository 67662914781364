import React from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";
import { getLang } from "app/feature/constants";

const useStyles = makeStyles((theme) => ({
  panel: {
    backgroundColor: "white",
    border: "1px solid #d0d5dd",
    borderRadius: 4,
    display: "flex",
    justifyContent: "space-between",
  },
}));

export default function RequestTransferReceiverPanelComponent({
  lang,
  receiver,
  changeAddress,
}) {
  const classes = useStyles();

  return (
    <Box className={classes.panel} py={1} px={1.5}>
      {receiver ? (
        <>
          <Box>
            <Typography
              variant="body2"
              display="inline"
              style={{ marginRight: 8 }}
            >
              <b>{receiver.branch_name}</b>
            </Typography>
            <Typography
              variant="caption"
              display="inline"
              color="textSecondary"
            >
              {receiver.phone_code} {receiver.phone_number}
            </Typography>
            <Typography variant="body2">{receiver.full_address}</Typography>
          </Box>
          <Box>
            <Typography
              variant="body2"
              style={{ color: "#4AA94A", cursor: "pointer" }}
              onClick={changeAddress}
            >
              <b>{getLang(lang, "label.CHANGE")}</b>
            </Typography>
          </Box>
        </>
      ) : (
        <Box
          style={{ width: "100%", cursor: "pointer" }}
          onClick={changeAddress}
        >
          <Typography variant="body1" style={{ color: "#b8bfc1" }}>
            {getLang(lang, "label.SELECT")}
          </Typography>
        </Box>
      )}
      {/* <Box style={{ marginRight: 8 }}>
        <Typography variant="body2" style={{ marginRight: 8 }}>
          <b>[Default] Test Branch</b>
        </Typography>
        <Typography variant="caption" color="textSecondary">
          +60 196501489
        </Typography>
        <Typography variant="body2">
          12345, iwnoa, 43200, Cheras, Selangor, Malaysia
        </Typography>
      </Box>
      <Box>
        <Typography
          variant="body2"
          style={{ color: "#4AA94A", cursor: "pointer" }}
        >
          <b>{getLang(lang, "label.CHANGE")}</b>
        </Typography>
      </Box> */}
    </Box>
  );
}
