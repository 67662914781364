import axios from 'axios';

const url = "/account/public/api/v1/currency";

export function getAllCurrencyConfig() {
    return new Promise((resolve, reject) => {
      axios.get(url)
        .then(response => resolve(response.data))
        .catch(error => reject(error.response.data))
    })
  }

export default getAllCurrencyConfig;