import React from "react";
import {
  makeStyles,
  Paper,
  Box,
  Typography,
  Button,
  Dialog,
  IconButton,
  Divider,
} from "@material-ui/core";
import AdditionalInfoIcon from "assets/img/additionalinfoIcon.png";
import CloseIcon from "@material-ui/icons/Close";
import { getLang } from "app/feature/constants";
import AdtInfoImportProgress from "../../progress/adtInfoImportProgress";

const useStyle = makeStyles((theme) => ({
  root: {
    position: "relative",
    borderRadius: 8,
    width: 650,
    maxWidth: "95vw",
    boxShadow:
      "0px 20px 24px -4px rgba(16, 24, 40, 0.1), 0px 8px 8px -4px rgba(16, 24, 40, 0.04)",
    [theme.breakpoints.down("xs")]: {
      minWidth: "95vw",
    },
  },
  icon: {
    height: 20,
    width: 20,
  },
  button: {
    backgroundColor: "white",
    border: "1px solid #D0D5DD",
    borderRadius: 4,
    textTransform: "capitalize",
    display: "flex",
    alignItems: "center",
  },
  closeButton: {
    position: "absolute",
    top: 4,
    right: 4,
  },
  header: {
    paddingLeft: theme.spacing(3),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

export default function AdtInfoAddInstructionDialogComponent({
  isOpen,
  handleClose,
  lang,
  handleView,
  pendingProcess,
  setShowImportLog,
}) {
  const classes = useStyle();

  return (
    <Dialog
      open={isOpen}
      disableBackdropClick
      disableEscapeKeyDown
      aria-labelledby='="general-dialog'
      // maxWidth="md"
      classes={{
        paper: classes.root,
      }}
      // fullWidth
    >
      <Paper className={classes.panel} elevation={3}>
        <Box className={classes.header} p={1}>
          <Box display="flex" alignItems="center">
            <Box className={classes.button} p={1} mr={1}>
              <img
                src={AdditionalInfoIcon}
                className={classes.icon}
                alt="info"
              />
            </Box>
            <Typography variant="h6">
              <b>{getLang(lang, "label.ADDITIONAL_INFORMATION_BETA")}</b>
            </Typography>
          </Box>
          <IconButton color="primary" onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
        <Divider />
        <Box p={2} style={{ backgroundColor: "#F2F4F7" }}>
          <Box style={{ backgroundColor: "white" }} p={1.5}>
            <Box>
              <Box mb={2}>
                <Typography variant="body2">
                  {getLang(lang, "paragraph.ADDITIONAL_INFO_OPTIONS_INFO")}
                </Typography>
              </Box>
              <Box pl={2}>
                <Typography variant="body2">
                  <b>{getLang(lang, "label.CSV_IMPORT")}</b>
                </Typography>
                <ul>
                  <li>
                    <Typography variant="body2">
                      {getLang(lang, "paragraph.ADDITIONAL_INFO_IMPORT_DESC_1")}
                    </Typography>
                  </li>
                </ul>
              </Box>
              <Box pl={2}>
                <Typography variant="body2">
                  <b>{getLang(lang, "label.MANUAL_ENTRY")}</b>
                </Typography>
                <ul>
                  <li>
                    <Typography variant="body2">
                      {getLang(
                        lang,
                        "paragraph.ADDITIONAL_INFO_MANUAL_ENTRY_DECS_1"
                      )}
                    </Typography>
                  </li>
                </ul>
              </Box>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mt={4}
            >
              <Box display="flex" alignItems="center" mr={1}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleView("import")}
                  style={{ marginRight: 16 }}
                  disabled={!!pendingProcess}
                >
                  {getLang(lang, "label.CSV_IMPORT")}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleView("manual")}
                  disabled={!!pendingProcess}
                >
                  {getLang(lang, "label.MANUAL_ENTRY")}
                </Button>
              </Box>
              <Button
                variant="text"
                color="secondary"
                onClick={() => {
                  setShowImportLog(true);
                }}
              >
                {getLang(lang, "label.VIEW_UPDATE_LOG")}
              </Button>
            </Box>
          </Box>
          {!!pendingProcess && (
            <Box pt={1.5}>
              <AdtInfoImportProgress />
            </Box>
          )}
        </Box>
      </Paper>
    </Dialog>
  );
}
