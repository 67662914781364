import React, { useState } from "react";
import WarrantyExtensionCardComponent from "./warrantyExtensionCard.component";
import { selectLoading } from "modules/notification";
import { connect, useSelector } from "react-redux";
import {
  createWarrantyExtension,
  fetchWarrantyExtensionBatch,
  updateWarrantyExtension
} from "../../../redux";
import { hideBackdrop, showBackdrop } from "modules/backdrop";
import moment from 'moment';
import { WARRANTY_LIST_EDIT } from "lib/constants/accessRights";

function WarrantyExtensionCardContainer({
  type,
  changeToUpdate,
  index,
  info,
  handleSave,
  handleDelete,
  createWarrantyExtension,
  id,
  isFetching,
  length,
  filteredlength,
  updateWarrantyExtension,
  showBackdrop,
  hideBackdrop
}) {
  const [amount, setAmount] = useState();
  const lang = useSelector(state => state.constant.languages);
  const language = useSelector(state => state.profile.language);
  const hasWarrantyEditAccessRight = useSelector(state => state.profile.accessRights.includes(WARRANTY_LIST_EDIT))

  const handleSubmit = async (values) => {
    if (type === "view") {
      await changeToUpdate("edit");
      return;
    }
    const updateInfo = {
      ...values,
      warrantySettingId: id
    };

    if (type === "add") {
      updateInfo.type = "create";
      const response = await createWarrantyExtension(updateInfo);

      if (response.payload.success) {
        handleSave(updateInfo.type, updateInfo, response);
      }
    } else if (type === "edit") {
      updateInfo.index = index;
      const response = await updateWarrantyExtension(updateInfo);
      if (response.payload.success) {
        handleSave(type, updateInfo, response);
        changeToUpdate("view");
      }
    }
  };

  const handleCancel = () => {
    changeToUpdate("view");
  }

  const handleGenerate = async (values) => {
    showBackdrop();
    const updateInfo = {
      ...info,
      extensionCode: values.amount,
      warrantySettingId: id,
      effectiveDateStart: moment(info.effectiveDateStart).format("YYYY-MM-DD HH:mm"),
      effectiveDateEnd: moment(info.effectiveDateEnd).format("YYYY-MM-DD HH:mm")
    };
    updateInfo.type = "generate";
    updateInfo.index = index;

    const response = await createWarrantyExtension(updateInfo);
    if (response.payload.success) {
      handleSave(updateInfo.type, updateInfo, response);
    }
    hideBackdrop();
  };

  const onAmountFieldChange = (amount) => {
    setAmount(amount);
  }

  return (
    <>
      <WarrantyExtensionCardComponent
        hasWarrantyEditAccessRight={hasWarrantyEditAccessRight}
        info={info}
        index={index}
        handleSubmit={handleSubmit}
        handleDelete={handleDelete}
        handleCancel={handleCancel}
        type={type}
        language={language}
        isFetching={isFetching}
        length={length}
        filteredlength={filteredlength}
        handleGenerate={handleGenerate}
        onAmountFieldChange={onAmountFieldChange}
        amount={amount}
        lang={lang}
      />
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  createWarrantyExtension: (value) => dispatch(createWarrantyExtension(value)),
  updateWarrantyExtension: (value) => dispatch(updateWarrantyExtension(value)),
  hideBackdrop: (value) => dispatch(hideBackdrop(value)),
  showBackdrop: (value) => dispatch(showBackdrop(value))
});

const mapStateToProps = (state) => ({
  id: state.warranty.warrantySetting.id,
  length: state.warranty.warrantyListTable.totalRecords,
  filteredlength: state.warranty.warrantyListTable.filteredTotalRecords,
  isFetching: selectLoading(state, fetchWarrantyExtensionBatch.typePrefix)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WarrantyExtensionCardContainer);
