import React from "react";
import {
  makeStyles,
  IconButton,
  Badge,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  Box,
  Typography,
  ButtonBase
} from "@material-ui/core";
import NotificationsIcon from "@material-ui/icons/Notifications";
import clsx from "clsx";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles(theme => ({
  bold: {
    fontWeight: theme.typography.fontWeightBold
  },
  root: {
    fontWeight: theme.typography.fontWeightBold
  },
  menuListContainer: {
    width: "auto"
  },
  notifListContainer: {
    display: "flex",
    flexFlow: "column wrap",
    alignItems: "stretch",
    paddingBottom: theme.spacing(2)
  },
  notifLabel: {
    padding: theme.spacing(1),
    fontWeight: theme.typography.fontWeightBold,
    borderBottom: "1px solid lightgrey"
  },
  notifCard: {
    padding: theme.spacing(1),
    width: 280,
    
    borderBottom: "1px solid lightgrey",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    "&:hover": {
      backgroundColor: "theme.palette.grey[200]"
    }
  },
  notifCardNotRead: {
    backgroundColor: "#EDF2FA"
  },
  chipStatus: {
    padding: theme.spacing(0, 1),
    backgroundColor: "green",
    borderRadius: 7,
    width: "fit-content",
    color: theme.palette.common.white
  },
  alertsList: {
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "stretch",
    maxHeight: "80vh",
    overflowY: "auto"
  },
  hover: {
    borderRadius:0,
    '&:hover' : {
      backgroundColor: "#49616bb8"
    }
  }
}));

const NotificationCard = ({
  batchNumber,
  type,
  tag,
  isRead,
  qty,
  status,
  modifiedAt
}) => {
  const classes = useStyle();
  return (
    <ButtonBase
      className={clsx(classes.notifCard, {
        [classes.notifCardNotRead]: !isRead
      })}
    >
      <Typography variant="body1">{batchNumber}</Typography>
      <Typography variant="body2" color="textSecondary">
        {type}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {tag}
      </Typography>
      <Typography variant="body2" color="textSecondary" gutterBottom>
        {`Qty ${qty}`}
      </Typography>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <Box className={classes.chipStatus}>
          <Typography variant="caption">{status}</Typography>
        </Box>
        <Typography variant="caption" color="textSecondary">
          {modifiedAt}
        </Typography>
      </Box>
    </ButtonBase>
  );
};

export default function NotificationBadgeComponent({
  alerts,
  hasNotReadCount,
  isFetching,
  handleToggle,
  handleClose,
  isOpen,
  anchorRef,
  lang
}) {
  const classes = useStyle();

  return (
    <>
      <IconButton
        aria-label="notification"
        onClick={handleToggle}
        ref={anchorRef}
        className={classes.hover}
      >
        <Badge badgeContent={hasNotReadCount} color="primary">
          <NotificationsIcon
            fontSize="default"
            style={{ color: "#6AAF68" }}
          />
        </Badge>
      </IconButton>
      <Popper
        open={isOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom"
            }}
          >
            <Paper className={classes.menuListContainer}>
              <ClickAwayListener onClickAway={handleClose}>
                <Box className={classes.notifListContainer}>
                  <Typography className={classes.notifLabel} variant="body2">
                    {getLang(lang,"label.LATEST_STATUS")}
                  </Typography>
                  <Box className={classes.alertsList}>
                    {isFetching ? (
                      getLang(lang,"label.FETCHING")
                    ) : alerts.length === 0 ? (
                      getLang(lang,"label.NO_NEW_NOTIFICATIONS")
                    ) : (
                      <>
                        {alerts.map(alert => {
                          const {
                            BatchNumber,
                            Qty,
                            Format,
                            GenType,
                            SerialType
                          } = JSON.parse(alert.alertValue);
                          const {
                            status,
                            modifiedAt,
                            alertKey,
                            isRead
                          } = alert;
                          return (
                            <NotificationCard
                              key={alertKey}
                              isRead={isRead}
                              batchNumber={BatchNumber}
                              tag={Format}
                              qty={Qty}
                              status={status}
                              modifiedAt={modifiedAt}
                              type={
                                SerialType === 2
                                  ? "Alphanumeric"
                                  : GenType === "S"
                                  ? "Running number"
                                  : "Random number"
                              }
                            />
                          );
                        })}
                      </>
                    )}
                  </Box>
                </Box>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
