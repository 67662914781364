import React from "react";
import { makeStyles, Button, Box } from "@material-ui/core";
import PageWorkspacePanel from "../pageWorkspacePanel";
import ComponentMenuPanel from "../componentMenuPanel";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    flexGrow: 1,
    marginTop: 20
  },
  btnSecondary: {
    color: theme.palette.common.white
  },
  btnEditPage: {
    marginRight: theme.spacing(1)
  }
}));

export default function EditPagePanelComponent({
  children,
  handlePublish,
  lang
}) {
  const classes = useStyle();

  return (
    <Box className={classes.root}>
      <Box style={{
        display: "grid",
        gridTemplateColumns: "380px 1fr",
        columnGap: 25
      }}>
        <ComponentMenuPanel />
        <PageWorkspacePanel />
      </Box>
      {children({
        renderSubmitButton: (
          <Button
            variant="contained"
            color="secondary"
            disableElevation
            classes={{ root: classes.btnSecondary }}
            onClick={handlePublish}
          >
            {getLang(lang,"label.SAVE_PRODUCT")}
          </Button>
        )
      })}
    </Box>
  );
}
