import React from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "components/accordion";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SingleCheckoutProductAccordion from "../singleCheckoutProductAccordion";
import SingleCheckoutBoxAccordion from ".";

const useStyles = makeStyles((theme) => ({
  content: {
    border: "2px solid #ECEEEE",
    borderRadius: 4,
    margin: "10px 0px !important",
    flex: 1,
    overflow: "hidden",
    boxShadow: "none",
  },
  summaryContainer: {
    padding: "8px 16px",
    borderRadius: "8px !important",
  },
  listContainer: {
    maxHeight: 600,
    overflowY: "auto",
    overflowX: "hidden",
  },
}));

export default function SingleCheckoutBoxAccordionComponent({
  box,
  lang,
  handleExpand,
  selectedBox,
  viewItem,
  selectedItem,
  id,
}) {
  const classes = useStyles();

  return (
    <Box>
      <Accordion
        defaultExpanded={false}
        expanded={selectedBox && selectedBox.uuid === box.uuid}
        className={classes.content}
        onChange={(event, expanded) => {
          handleExpand(expanded ? box : null, "box");
          if (!expanded) {
            viewItem(null);
          }
        }}
        elevation={0}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={classes.summaryContainer}
        >
          <Box>
            <Typography className={classes.productName}>
              <b>{box.code}</b>
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box px={1} className={classes.listContainer}>
            <Box>
              {[...box.products].map((product, i) => (
                <SingleCheckoutProductAccordion
                  key={i}
                  product={product}
                  lang={lang}
                  selectViewItem={viewItem}
                  selectedProduct={selectedItem}
                  boxUuid={box.uuid}
                  id={id}
                />
              ))}
            </Box>
          </Box>
          {!!box.child_boxes && !!box.child_boxes.length && (
            <Box px={1}>
              {[...box.child_boxes].map((b, i) => (
                <SingleCheckoutBoxAccordion
                  key={i}
                  box={b}
                  lang={lang}
                  selectViewItem={viewItem}
                  selectedBox={selectedItem}
                  id={id}
                />
              ))}
            </Box>
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
