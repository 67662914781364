import React from "react";
import { ErrorMessage, Formik } from "formik";
import * as Yup from "yup";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Typography,
  FormHelperText
} from "@material-ui/core";
import InputTextField from "components/input/inputTextField";
import { Close as CloseIcon } from "@material-ui/icons";
import ModuleTable from "../../table/moduleTable";
import ModuleSelect from "../../select/moduleSelect";
import clsx from "clsx";
import { convertToBigNum } from "lib/generalUtility";
import { getLang } from "app/feature/constants";


const useStyle = makeStyles((theme) => ({
  root: {
    boxShadow: theme.shadows[5],
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "space-between",
    width: 600,
    borderRadius: "4px",
  },
  detailsWrapper: {
    padding: "25px 20px",
    height: "calc(78vh - 260px)",
    overflowY: "auto",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  discardButton: {
    color: theme.palette.error.main
  },
  gridContainer: {
    minHeight: "50px",
    paddingTop: theme.spacing(1)
  },
  gridContainereView: {
    paddingBottom: theme.spacing(2)
  },
  gridEditField: {
    paddingTop: "16px !important"
  },
  gridViewField: {
    paddingTop: "10px !important"
  },
  title: {
    columnGap: "5px"
  },
  iconButton: {
    padding: "unset"
  },
  headerText: {
    fontWeight: "bold"
  },
  loyaltyPointText: {
    fontWeight: "bold",
    marginTop: "5px"
  }
}));

export default function LoyaltyPointFormComponent({
  hasLoyaltyProgramAddAccessRight,
  hasLoyaltyProgramEditAccessRight,
  loyaltyPoint,
  handleClose,
  handleSubmit,
  loyaltyPointType,
  changeToUpdate,
  headerType,
  lang,
  changeBackView
}) {
  const classes = useStyle();
  const viewType = loyaltyPointType === "view" ? true : false;

  const renderInputField = (item, index, formik) => {
    switch (item.type) {
      case "number":
        return (
          <Grid item xs={5} sm={5} md={5}>
            {viewType ? (
              <Typography
                variant="body2"
                color="primary"
                className={classes.loyaltyPointText}
              >
                {convertToBigNum(formik.getFieldProps(item.name).value)}
              </Typography>
            ) : (
              <InputTextField
                disabled={formik.isSubmitting}
                required
                variant="filled"
                size="small"
                fullWidth
                type={item.type}
                inputProps={{
                  min: 1,
                }}
                placeholder={item.placeholder}
                {...formik.getFieldProps(item.name)}
                error={
                  formik.touched[item.name] && formik.errors[item.name]
                    ? true
                    : false
                }
                helperText={ErrorMessage({
                  name: item.name,
                })}
              />
            )}
          </Grid>
        );
      case "select":
        return (
          <Grid item xs={8} sm={9} md={9}>
            {!viewType &&
              <ModuleSelect
                disabled={formik.isSubmitting || viewType}
                value={formik.values.modules}
                required
                handleChange={(value) => formik.setFieldValue("modules", value)}
              />}
            <FormHelperText
              style={{ margin: "4px 14px 0px" }}
              error={formik.errors[item.name] ? true : false}
            >
              {ErrorMessage({ name: item.name })}
            </FormHelperText>
            <ModuleTable tableData={formik.values.modules} />
          </Grid>
        );
      default:
        return null;
    }
  };
  return (
    <Paper elevation={0} className={classes.root}>
      <Box p={2} className={classes.header}>
        <Typography
          variant="subtitle2"
          color="primary"
          className={classes.headerText}
        >
          {headerType === "create" ? getLang(lang, "label.ADD_LOWERCASE") :
            headerType === "edit" ? getLang(lang, "label.EDIT") :
              getLang(lang, "label.VIEW")} {getLang(lang, "label.POINT_LOWERCASE")}
        </Typography>
        <IconButton color="primary" onClick={handleClose} className={classes.iconButton}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>
      <Divider />
      <Formik
        enableReinitialize
        initialValues={{
          ...loyaltyPoint,
          point: loyaltyPoint.point || "",
          modules: loyaltyPoint.modules,
        }}
        validationSchema={Yup.object({
          point: Yup.string().required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
          modules: Yup.string().required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
        })}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <Box className={classes.detailsWrapper}>
              {[
                {
                  title: getLang(lang, "label.POINT"),
                  requiredText: "*",
                  name: "point",
                  placeholder: getLang(lang, "placeholder.ENTER_POINT_HERE"),
                  type: "number",
                },
                {
                  title: getLang(lang, "label.ASSIGN_PRODUCT"),
                  requiredText: "*",
                  name: "modules",
                  placeholder: getLang(lang, "placeholder.SELECT_PRODUCT"),
                  type: "select",
                },
              ].map((item, index) => (
                <Grid container key={index} spacing={1}
                  className={clsx(classes.gridContainer, {
                    [classes.gridContainerView]: viewType,
                  })}
                >
                  <Grid item xs={4} sm={3} md={3}
                    className={clsx({
                      [classes.gridEditField]: !viewType,
                      [classes.gridViewField]: viewType,
                    })}>
                    <Box display="flex" className={classes.title}>
                      <Typography variant="body2" color="primary">
                        {item.title}
                      </Typography>
                      <Typography variant="body2" color="error">
                        {item.requiredText}
                      </Typography>
                    </Box>
                  </Grid>
                  {renderInputField(item, index, formik)}
                </Grid>
              ))}
            </Box>

            {
              (headerType === "create" && hasLoyaltyProgramAddAccessRight) ||
                (headerType === "view" && hasLoyaltyProgramEditAccessRight) ||
                (headerType === "edit" && hasLoyaltyProgramEditAccessRight)
                ?
                <>
                  <Divider />
                  <Box p={2} flexGrow={1} display="flex" alignItems="center" justifyContent="flex-end">

                    {!viewType ? (
                      <Button
                        disabled={formik.isSubmitting}
                        onClick={(e) => {
                          formik.resetForm()
                          headerType === "edit" ?
                            changeBackView(e) :
                            handleClose(e)
                        }}
                        style={{ marginRight: "4px" }}
                        type="button"
                      >
                        {getLang(lang, "label.CANCEL")}
                      </Button>
                    ) : (
                      ""
                    )}
                    <Button
                      disabled={formik.isSubmitting}
                      variant="contained"
                      color="secondary"
                      disableElevation
                      type={viewType ? "button" : "submit"}
                      onClick={viewType ? changeToUpdate : null}
                    >
                      {loyaltyPointType === "create"
                        ? getLang(lang, "label.ADD")
                        : loyaltyPointType === "edit"
                          ? getLang(lang, "label.SAVE")
                          : getLang(lang, "label.EDIT")}
                    </Button>
                  </Box>
                </>
                : null
            }
          </form>
        )}
      </Formik>
    </Paper>
  );
}
