import React, { useState } from "react";
import LuckyDrawParticipantTableComponent from "./luckyDrawParticipantTable.component";
import { hideBackdrop, showBackdrop } from "modules/backdrop";
import ParticipantDetailDialog from "../../dialog/participantDetailDialog";
import { useDispatch, useSelector } from "react-redux";
import { fetchParticipantDetail } from "modules/lucky-draw/redux";
import { unwrapResult } from "@reduxjs/toolkit";

function LuckyDrawParticipantTableContainer({
  rows,
  rowsPerPage,
  page,
  search,
  handleChangeRowsPerPage,
  handleChangePage,
  totalRecords,
  isFetching,
  language,
  handleReload,
  paginationOptions,
  lang
}) {
  const dispatch = useDispatch();
  const [isModalOpen, setModalOpen] = useState(false);
  const isLoadingLuckyDrawParticipantError = useSelector(state => state.luckyDraw.isLoadingLuckyDrawParticipantError)

  const handleView = async (id) => {
    dispatch(showBackdrop());

    dispatch(fetchParticipantDetail(id))
      .then(unwrapResult)
      .then(() => {
        dispatch(hideBackdrop());
        setModalOpen(true);
      });
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      <LuckyDrawParticipantTableComponent
        rows={rows}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        page={page}
        totalRecords={totalRecords}
        search={search}
        handleView={handleView}
        isFetching={isFetching}
        handleReload={handleReload}
        isLoadingLuckyDrawParticipantError={isLoadingLuckyDrawParticipantError}
        paginationOptions={paginationOptions}
        lang={lang}
        language={language}
      />
      <ParticipantDetailDialog isOpen={isModalOpen} handleClose={closeModal} />
    </>
  );
}
export default LuckyDrawParticipantTableContainer;
