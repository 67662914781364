import BackgroundImage from './image/background.png'
import FrameImage from './image/wheel.png'
import ButtonImage from './image/button.png'

export default {
    blocks: [
        {
            background: '#fff'
        }
    ],
    backgroundImage: BackgroundImage,
    frameImage: FrameImage,
    prizeSegmentColor: [
        "#F8DEF8",
        "#FEF3FC"
    ],
    spinButtons: [
        {
            radius: "40px",
            imgs: [
                {
                    src: ButtonImage,
                    height: "80px",
                    top: "-40px"
                }
            ]
        }
    ],
    prize: {
        imgWidth: '35px',
        imgHeight: '35px',
        imgTop: '50%',
        fontTop: '15%'
    },
    defaultStyle: {
        fontColor: "#000",
        fontSize: "14px"
    },
    width: "308px",
    height: "308px"
}