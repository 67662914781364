import React from "react";
import RoleNameInputComponent from "./accordion.component";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

function RoleNameInputContainer({
    label,
    subgroup,
    accessRight,
    formik,
    setAccess,
    accessRole,
    inheritAccessRights,
    disabledCheckbox,
    disableSummaryAccordion,
    ...props
}) {
    const lang= useSelector(state=>state.constant.languages)
    return (
        <>
            <RoleNameInputComponent
                label={label}
                subgroup={subgroup}
                accessRight={accessRight}
                formik={formik}
                setAccess={setAccess}
                accessRole={accessRole}
                inheritAccessRights={inheritAccessRights}
                disabledCheckbox={disabledCheckbox}
                disableSummaryAccordion={disableSummaryAccordion}
                lang={lang}
                {...props}
            />
        </>
    )
}

RoleNameInputContainer.defaultProps = {
    label: "Label",
};

RoleNameInputContainer.propTypes = {
    label: PropTypes.string,
};

export default RoleNameInputContainer;
