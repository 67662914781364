import axios from "axios";

const updateSnAdtInfo = (parameters) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/serialnumber/api/v1/serialnumber/adt-info/${parameters.enc}`, {
        info: parameters.info,
        deleted_info_ids: parameters.deleted_info_ids,
      })
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

export default updateSnAdtInfo;
