import React from "react";
import { useSelector } from "react-redux";
import CreateBranchGroupDialogComponent from "./createBranchGroupDialog.component";

export default function CreateBranchGroupDialogContainer({
  isOpen,
  handleClose,
  handleSubmit,
  isSubmittingAddGroup,
}) {
  const lang = useSelector((state) => state.constant.languages);
  return (
    <CreateBranchGroupDialogComponent
      isOpen={isOpen}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
      isSubmittingAddGroup={isSubmittingAddGroup}
      lang={lang}
    />
  );
}
