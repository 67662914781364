import React from "react";
import {
  makeStyles,
  Box,
  Dialog,
  Backdrop,
  IconButton,
  Typography,
  Button,
  CircularProgress,
  Divider,
} from "@material-ui/core";
import "react-image-lightbox/style.css";
import {
  CloseRounded as CloseRoundedIcon,
  Delete as DeleteIcon,
} from "@material-ui/icons";
import { getLang } from "app/feature/constants";
import GroupBranchRuleTable from "../../table/groupBranchRuleTable";
import { AddIcon } from "components/icon";
import BranchInGroupSelect from "../../select/branchInGroupSelect";

const useStyle = makeStyles((theme) => ({
  header: {
    padding: "12px 16px",
  },
  container: {
    overflowY: "auto",
    maxHeight: "77vh",
  },
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    borderCollapse: "separate",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
    },
    "& td": {
      backgroundColor: "#FFFFFF",
      borderRight: "none",
      padding: "0px 16px",
    },
    "& td td": {
      borderBottom: "none !important",
    },
  },
  fixedCellContent: {
    width: 158,
    minWidth: 130,
    whiteSpace: "normal",
  },
  tableContainer: {
    border: "1px solid #D0D5DD",
  },
  addBranchContainer: {
    display: "grid",
    gridTemplateColumns: "2fr 7fr 1fr",
    columnGap: theme.spacing(2),
  },
  deleteIcon: {
    color: "#F04438",
    cursor: "pointer",
  },
  emptyContainer: {
    border: "1px solid #D0D5DD",
    minHeight: 120,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    flexDirection: "column",
  },
}));

export default function GroupBranchEditDialogComponent({
  isOpen,
  handleClose,
  rules,
  customRules,
  lang,
  isFetching,
  isError,
  handleReload,
  isSubmitting,
  submitRuleUpdate,
  updateRules,
  updateCustomRule,
  group,
  branchToAdd,
  updateBranchToAdd,
  updateBranchToRemove,
  addBranchForCustomRules,
  openSelectTargetBranchDialog,
  id,
  openRemoveRuleDialog,
  openRemoveRuleTargetDialog,
  hasRuleWithEmptyTarget,
  reset,
}) {
  const classes = useStyle();

  return (
    <Dialog
      maxWidth="lg"
      open={isOpen}
      onClose={() => {}}
      aria-labelledby="modal-group-detail"
      aria-describedby="modal-group-detail"
      BackdropComponent={Backdrop}
      fullWidth
      PaperProps={{
        style: {
          overflowY: "hidden",
        },
      }}
    >
      <Box
        className={classes.header}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="body1">
          <b>{getLang(lang, "label.EDIT_STOCK_TRANSFER_RULES_SETTINGS")}</b>
        </Typography>
        <IconButton
          onClick={handleClose}
          edge="end"
          size="small"
          aria-label="close dialog"
          disabled={isSubmitting}
        >
          <CloseRoundedIcon color="primary" />
        </IconButton>
      </Box>
      <Box pt={2} px={3} pb={3} className={classes.container}>
        <Box mb={2}>
          <Box mt={group?.is_default ? 0 : 5} mb={2}>
            <Typography variant="body1">
              <b>{getLang(lang, "label.GROUP_TRANSFER_RULES")}</b>
            </Typography>
          </Box>
          <GroupBranchRuleTable
            lang={lang}
            isEditable={true}
            rows={rules}
            updateRules={updateRules}
            isFetching={isFetching}
            isError={isError}
            handleReload={handleReload}
            type="group"
          />
        </Box>
        {!group?.is_default && (
          <>
            <Box mt={5} mb={2}>
              <Box mb={1}>
                <Typography variant="body1">
                  <b>{getLang(lang, "label.BRANCH_CUSTOM_TRANSFER_RULES")}</b>
                </Typography>
              </Box>
              <Box className={classes.addBranchContainer}>
                <Box>
                  <Typography>{getLang(lang, "label.BRANCH")}</Typography>
                </Box>
                <BranchInGroupSelect
                  handleChange={(branch) => {
                    updateBranchToAdd(branch);
                  }}
                  disabled={isSubmitting || isFetching}
                  value={branchToAdd}
                  placeholder={getLang(lang, "label.SELECT_BRANCH")}
                  id={id}
                />
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() => {
                    addBranchForCustomRules();
                  }}
                  disabled={!branchToAdd || isFetching}
                >
                  <Typography>{getLang(lang, "label.ADD")}</Typography>
                </Button>
              </Box>
              <Box mt={3.5}>
                {customRules.map((rule, index) => (
                  <Box mb={2} key={index}>
                    <Box
                      mb={1}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Box display="flex" alignItems="center">
                        <Typography variant="body2">
                          <b>{rule.from_node_name}</b>
                        </Typography>
                        <Box ml={1}>
                          <DeleteIcon
                            size={15}
                            className={classes.deleteIcon}
                            onClick={() => {
                              updateBranchToRemove({
                                from_uuid: rule.from_node_uuid,
                              });
                              openRemoveRuleDialog();
                            }}
                          />
                        </Box>
                      </Box>
                      {!!rule.to_node.length && (
                        <Button
                          variant="outlined"
                          color="primary"
                          startIcon={<AddIcon style={{ fontSize: "13px" }} />}
                          disableElevation
                          style={{ borderColor: "#D0D5DD" }}
                          onClick={() =>
                            openSelectTargetBranchDialog(rule.from_node_uuid)
                          }
                        >
                          {getLang(lang, "label.ADD_BRANCH")}
                        </Button>
                      )}
                    </Box>
                    <Box>
                      {rule.to_node.length ? (
                        <GroupBranchRuleTable
                          lang={lang}
                          isEditable={true}
                          rows={rule.to_node}
                          isFetching={isFetching}
                          isError={isError}
                          handleReload={handleReload}
                          type="branch"
                          updateRules={(row, ruleName, isChecked) => {
                            updateCustomRule(row, rule, ruleName, isChecked);
                          }}
                          removeRule={(row) => {
                            updateBranchToRemove({
                              from_uuid: rule.from_node_uuid,
                              to_uuid: row.uuid,
                            });
                            openRemoveRuleTargetDialog();
                          }}
                        />
                      ) : (
                        <Box className={classes.emptyContainer}>
                          <Typography>
                            {getLang(lang, "paragraph.NO_BRANCH_YET")}
                          </Typography>
                          <Button
                            variant="outlined"
                            color="primary"
                            startIcon={<AddIcon style={{ fontSize: "13px" }} />}
                            disableElevation
                            style={{ borderColor: "#D0D5DD", marginTop: 10 }}
                            onClick={() =>
                              openSelectTargetBranchDialog(rule.from_node_uuid)
                            }
                          >
                            {getLang(lang, "label.ADD_BRANCH")}
                          </Button>
                        </Box>
                      )}
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
          </>
        )}
      </Box>
      <Divider />
      <Box display="flex" justifyContent="end" p={2}>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => reset()}
          disabled={isSubmitting || isError}
          style={{ marginRight: 16 }}
        >
          <Typography>{getLang(lang, "label.RESET")}</Typography>
        </Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={() => submitRuleUpdate()}
          disabled={isSubmitting || hasRuleWithEmptyTarget || isError}
        >
          {isSubmitting ? (
            <CircularProgress size={20} style={{ color: "white" }} />
          ) : (
            <Typography>{getLang(lang, "label.SAVE")}</Typography>
          )}
        </Button>
      </Box>
    </Dialog>
  );
}
