import moment from "moment";

const API_HOST = process.env.REACT_APP_API_HOST_URL;
const saveFile = async (blob, type) => {
  const a = document.createElement("a");
  const date = moment().format("YYYYMMDD");
  a.download = `order-list_${date}.${type}`;
  a.href = URL.createObjectURL(blob);
  a.addEventListener("click", (e) => {
    setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
  });
  a.click();
};

export const exportOrder = async ({ ...parameters }) => {
  let response = await fetch(
    `${API_HOST}/distributor/admin/export/v1/stock-order?token=${parameters.token}`,
    {
      method: "Post",
      headers: {
        Authorization: `Bearer ${parameters.token}`
      },
      body: JSON.stringify({
        search: parameters.search,
        nodeIds: parameters.nodeIds,
        fromDate: parameters.fromDate,
        toDate: parameters.toDate,
        requestFrom: parameters.requestFrom,
        requestTo: parameters.requestTo,
        fileType: parameters.fileType,
        orderType: parameters.orderType,
        order: parameters.order,
        orderBy: parameters.orderBy
      })
    }
  );

  let blob = await response.blob();

  saveFile(blob, parameters.fileType);

};