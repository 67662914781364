import React from "react";
import PropTypes from "prop-types";
import {
  makeStyles,
  Box,
  Typography,
  Tabs,
  Divider
} from "@material-ui/core";
import {
  InfoSettingProfilePanel,
  OrderHistoryPanel
} from "../../components/panel";
import CustomTab from "components/tab";
import { BackButton } from "components/button";
import { getLang } from "app/feature/constants";

const style = (theme) => ({
  indicator: {
    border: "0.5px solid",
    borderColor: theme.palette.primary.main,
  },
  scroll: {
    width: "min-content"
  }
});

const useStyles = makeStyles(style);

export default function ViewProfilePageComponent({
  handleChange,
  activeIndex,
  tabLabels,
  lang
}) {
  const classes = useStyles();

  function a11yProps(index, label = "label") {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tab-${index}`,
      label: <Typography variant="button" children={label} />
    };
  }

  function TabPanel({ children, value, index, ...other }) {
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </Typography>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
  };

  return (
    <Box>
      <Box mb={1} display="flex" alignItems="center">
      <BackButton />

        <Typography
          variant="h6"
          color="primary"
          style={{ fontWeight: "bold", marginLeft: 5 }}
        >
          {getLang(lang, "label.VIEW_PROFILE")}
        </Typography>
      </Box>
      <Divider />

      <Box>
        <Box>
          <Tabs
            value={activeIndex}
            onChange={handleChange}
            aria-label="scrollable auto tabs example"
            textColor="primary"
            variant="scrollable"
            indicatorColor="primary"
            classes={{
              indicator: classes.indicator,
              scrollButtons: classes.scroll,
            }}
          >
            {tabLabels.map((item, index) => {
              return <CustomTab key={index} {...a11yProps(index, item)} />;
            })}
          </Tabs>
        </Box>

        <Box>
          <TabPanel value={activeIndex} index={0}>
            <InfoSettingProfilePanel />
          </TabPanel>
          <TabPanel value={activeIndex} index={1}>
            <OrderHistoryPanel />
          </TabPanel>
        </Box>
      </Box>
    </Box>
  );
}
