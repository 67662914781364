
import axios from "axios"

export function getStockHolderDropdown ({ search, type }) {
  return new Promise((resolve, reject) => {
    axios.get(`/distributor/admin/api/v1/inventory/get-stock-holder-dropdown`, {
      params: {
          search,
          type
      }
    })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data))
  });
};