import React from "react"
import {
  Box,
  makeStyles,
  Typography,
} from "@material-ui/core"
import { GoogleMap, OverlayView } from "@react-google-maps/api"
import MarkerStand from "assets/img/markerStand.png"

const useStyles = makeStyles((theme) => ({
  container: {
    background: "white",
    borderRadius: 4,
    marginTop: 2,
    padding: "16px 20px"
  },
  infoSection: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    columnGap: theme.spacing(2),
    rowGap: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
      gridTemplateRows: "1fr auto",
      rowGap: theme.spacing(2)
    }
  },
  icon: {
    color: "#98A2B3",
    marginRight: 12,
    fontSize: 24
  },
  gender: {
    width: 25,
    height: 24,
    objectFit: "contain",
    marginRight: 12
  },
  addressBox: {
    border: "2px solid #D0D5DD",
    padding: "5px 10px"
  },
  pin: {
    backgroundColor: "#35CA91",
    border: "4px solid white",
    borderRadius: "50%",
    height: 37,
    width: 37,
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    "& img": {
      width: 20,
      position: "absolute",
      bottom: -18
    }
  }
}))

export default function ProductTraceabilityMapComponent({ 
  list, 
  isLoaded, 
  productColorList,
  center,
  zoomLevel,
  updateRecordFocus,
  updateZoomLevel,
  onLoad,
  onUnmount,
  recordFocus
}) {
  const classes = useStyles()

  const getMarkerColor = (uuid) => {
    let color = "#35CA91"
    let textColor = "#FFFFFF"

    if (Object.keys(productColorList).length) {
      if (productColorList.hasOwnProperty(uuid)) {
        color = productColorList[uuid]
      }
    }
    const hex = color.replace('#', '');
    const r = parseInt(hex.slice(0, 2), 16);
    const g = parseInt(hex.slice(2, 4), 16);
    const b = parseInt(hex.slice(4, 6), 16);
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;
    if (brightness > 225) {
      textColor = "#000000";
    }

    return {
      backgroundColor: color,
      color: textColor
    } 
  }
  
  return window.google !== undefined && isLoaded && (
    <GoogleMap
      mapContainerStyle={{
        width: "100%",
        height: "630px"
      }}
      onLoad={onLoad}
      onUnmount={onUnmount}
      onZoomChanged={updateZoomLevel}
      center={center}
      zoom={zoomLevel}
      options={{
        mapTypeControl: true,
        streetViewControl: false,
        rotateControl: true,
        // // fullscreenControl: true,
        // // gestureHandling: "none",
        clickableIcons: false,
        keyboardShortcuts: false
      }}
    >
      {list.map((record, index) => (
        <OverlayView
          key={index}
          position={{
            lat: parseFloat(record.latitude),
            lng: parseFloat(record.longitude)
          }}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          getPixelPositionOffset={(offsetWidth, offsetHeight) => ({
            x: -(offsetWidth / 2),
            y: -(offsetHeight / 2) - 22,
          })}
        >
          <Box 
            className={classes.pin} 
            style={getMarkerColor(record.product_uuid)}
            onClick={() => {
              updateRecordFocus(record)
            }}
          >
            <Typography variant="caption">{record.index}</Typography>
            <img src={MarkerStand} alt="marker"/>
          </Box>
        </OverlayView>
      ))}
      {!!recordFocus && (
        <OverlayView
          position={{
            lat: parseFloat(recordFocus.latitude),
            lng: parseFloat(recordFocus.longitude)
          }}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          getPixelPositionOffset={(offsetWidth, offsetHeight) => ({
            x: -(offsetWidth / 2),
            y: -(offsetHeight / 2) - 27,
          })}
        >
          <Box 
            className={classes.pin} 
            style={getMarkerColor(recordFocus.product_uuid)}
          >
            <Typography variant="caption">{recordFocus.index}</Typography>
            <img src={MarkerStand} alt="marker"/>
          </Box>
        </OverlayView>
      )}
    </GoogleMap>
  )
}
