import axios from "axios";

const get = ({ uuid }) => {
  let url = "/account/api/v1/organisation-chart";
  if (uuid) {
    url = url + `?node_id=${uuid}`
  }
  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data)
      });
  });
};

const subscriptionPlan = {
  get
};

export default subscriptionPlan;
