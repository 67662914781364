import React, { useState } from "react";
import { useSelector } from "react-redux";
import MyDealerPage from "./my-dealer.page";
import { STOCKFLOW_DEALER_VIEW, STOCKFLOW_DEALER_ADD } from "lib/constants/accessRights";
import LoadingComponent from "components/loading";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

function MyDealerContainer() {
  const hasStockflowViewAccessRight = useSelector(state => state.profile.accessRights.includes(STOCKFLOW_DEALER_VIEW));
  const hasStockflowAddAccessRight = useSelector(state => state.profile.accessRights.includes(STOCKFLOW_DEALER_ADD));
  const [isInviteDialogOpen, setIsInviteDialogOpen] = useState(false);
  const lang = useSelector(state => state.constant.languages);
  const history = useHistory();

  const handleVisibleInviteDialog = (value) => {
    setIsInviteDialogOpen(value);
  }

  useEffect(() => {
    if (!hasStockflowViewAccessRight) {
      history.push('/admin/dashboard')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!hasStockflowViewAccessRight) return <LoadingComponent />;

  return (
    <>
      <MyDealerPage
        hasStockflowAddAccessRight={hasStockflowAddAccessRight}
        isInviteDialogOpen={isInviteDialogOpen}
        handleVisibleInviteDialog={handleVisibleInviteDialog}
        lang={lang}
      />
    </>
  )
}

export default MyDealerContainer;