const languages = [
  { code: "EN", label: "English" },
  //{ code: "MS-MY", label: "Bahasa Melayu" },
  //{ code: "ZH-CN", label: "简体中文" },
  { code: "VI", label: "tiếng Việt"},
  { code: "TH", label: "ไทย"},
  { code: "ZH", label: "简体中文"},
  { code: "ZH-TW", label: "繁體中文"},
  { code: "MS", label: "Bahasa Melayu"},
  { code: "ID", label: "Bahasa Indonesia"},
  { code: "TL", label: "Tagalog (Filipino)" }
];

export default languages;
