import { createSlice } from "@reduxjs/toolkit";
import {
  STOCKFLOWTRANSFER,
  getTransferDeliveryInfo,
  getTransferDetail,
  getTransferItemBoxList,
  getTransferItemBoxProductList,
  getTransferItemProductList,
  getTransferItemProductSNList,
  getTransferList,
  getTransferReceiverDropdown,
  getTransferSenderDropdown,
} from "../action/stockflowTransfer.action";

const initialState = {
  transferList: {
    list: [],
    totalFiltered: 0,
    totalRecords: 0,
  },
  isLoadingTransferListError: false,
  transfer: null,
  isLoadingTransferError: false,
  transferSenderDropdown: [],
  transferReceiverDropdown: [],
  transferBoxList: {
    list: [],
    totalFiltered: 0,
    totalRecords: 0,
    isError: false,
    hasNextPage: true,
  },
  transferBoxProductList: {
    list: [],
    isError: false,
  },
  transferSnList: {
    list: [],
    totalFiltered: 0,
    totalRecords: 0,
    isError: false,
  },
  transferProductList: {
    list: [],
    totalFiltered: 0,
    totalRecords: 0,
    isError: false,
    hasNextPage: true,
  },
  deliveryInfo: {
    list: [],
    isLoading: false,
    isError: false,
  },
};

const stockflowTransferSlice = createSlice({
  name: STOCKFLOWTRANSFER,
  initialState,
  reducers: {
    resetTransferListing(state) {
      state.transferList = initialState.transferList;
    },
    resetTransfer(state) {
      state.transfer = initialState.transfer;
      state.transferBoxList = initialState.transferBoxList;
      state.transferBoxProductList = initialState.transferBoxProductList;
      state.transferProductList = initialState.transferProductList;
      state.transferSnList = initialState.transferSnList;
      state.deliveryInfo = initialState.deliveryInfo;
    },
    resetSnListing(state) {
      state.transferSnList = initialState.transferSnList;
    },
    resetTransferBoxListing(state) {
      state.transferBoxList = initialState.transferBoxList;
    },
    resetBoxProductListing(state) {
      state.transferBoxProductList = initialState.transferBoxProductList;
    },
  },
  extraReducers: {
    [getTransferList.pending]: (state) => {
      state.transferList = initialState.transferList;
      state.isLoadingTransferListError = false;
    },
    [getTransferList.fulfilled]: (state, action) => {
      const { payload } = action;
      state.transferList.list = payload.data;
      state.transferList.totalRecords = payload.recordsTotal;
      state.transferList.totalFiltered = payload.recordsFiltered;
    },
    [getTransferList.rejected]: (state) => {
      state.isLoadingTransferListError = true;
    },
    [getTransferDetail.pending]: (state) => {
      state.transfer = initialState.transfer;
      state.isLoadingTransferError = false;
    },
    [getTransferDetail.fulfilled]: (state, action) => {
      state.transfer = action.payload;
    },
    [getTransferDetail.rejected]: (state) => {
      state.isLoadingTransferError = true;
    },
    [getTransferSenderDropdown.pending]: (state) => {
      state.transferSenderDropdown = initialState.transferSenderDropdown;
    },
    [getTransferSenderDropdown.fulfilled]: (state, action) => {
      state.transferSenderDropdown = action.payload;
    },
    [getTransferReceiverDropdown.pending]: (state) => {
      state.transferReceiverDropdown = initialState.transferReceiverDropdown;
    },
    [getTransferReceiverDropdown.fulfilled]: (state, action) => {
      state.transferReceiverDropdown = action.payload;
    },
    [getTransferItemBoxList.pending]: (state) => {
      state.transferBoxList.isError = false;
    },
    [getTransferItemBoxList.fulfilled]: (state, action) => {
      const { payload } = action;
      if (state.transferBoxList.hasNextPage) {
        state.transferBoxList.list = [
          ...state.transferBoxList.list,
          ...payload.data,
        ];
      }
      state.transferBoxList.totalRecords = payload.recordsTotal;
      state.transferBoxList.totalFiltered = payload.recordsFiltered;
      state.transferBoxList.hasNextPage =
        state.transferBoxList.list.length < state.transferBoxList.totalFiltered;
    },
    [getTransferItemBoxList.rejected]: (state) => {
      state.transferBoxList.isError = true;
    },
    [getTransferItemBoxProductList.pending]: (state) => {
      state.transferBoxProductList = initialState.transferBoxProductList;
    },
    [getTransferItemBoxProductList.fulfilled]: (state, action) => {
      const { payload } = action;
      state.transferBoxProductList.list = payload;
    },
    [getTransferItemBoxProductList.rejected]: (state) => {
      state.transferBoxProductList.isError = true;
    },
    [getTransferItemProductList.pending]: (state) => {
      state.transferProductList.isError = false;
    },
    [getTransferItemProductList.fulfilled]: (state, action) => {
      const { payload } = action;
      if (state.transferProductList.hasNextPage) {
        state.transferProductList.list = [
          ...state.transferProductList.list,
          ...payload.data,
        ];
      }
      state.transferProductList.totalRecords = payload.recordsTotal;
      state.transferProductList.totalFiltered = payload.recordsFiltered;
      state.transferProductList.hasNextPage =
        state.transferProductList.list.length <
        state.transferProductList.totalFiltered;
    },
    [getTransferItemProductList.rejected]: (state) => {
      state.transferProductList.isError = true;
    },
    [getTransferItemProductSNList.pending]: (state) => {
      state.transferSnList = initialState.transferSnList;
    },
    [getTransferItemProductSNList.fulfilled]: (state, action) => {
      const { payload } = action;
      state.transferSnList.list = payload.data;
      state.transferSnList.totalRecords = payload.recordsTotal;
      state.transferSnList.totalFiltered = payload.recordsFiltered;
    },
    [getTransferItemProductSNList.rejected]: (state) => {
      state.transferSnList.isError = true;
    },
    [getTransferDeliveryInfo.pending]: (state) => {
      state.deliveryInfo.isLoading = true;
      state.deliveryInfo.list = [];
      state.deliveryInfo.isError = false;
    },
    [getTransferDeliveryInfo.fulfilled]: (state, action) => {
      state.deliveryInfo.isLoading = false;
      state.deliveryInfo.list = action.payload;
    },
    [getTransferDeliveryInfo.rejected]: (state) => {
      state.deliveryInfo.isLoading = false;
      state.deliveryInfo.isError = true;
    },
  },
});

export const {
  resetTransferListing,
  resetTransfer,
  resetSnListing,
  resetBoxProductListing,
  resetTransferBoxListing,
} = stockflowTransferSlice.actions;

export const stockflowTransferReducer = stockflowTransferSlice.reducer;
