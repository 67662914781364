import React from "react";
import {
  Backdrop,
  Dialog,
  makeStyles,
  Typography,
  Paper,
  Box,
  IconButton,
  Divider,
  Button,
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio
} from "@material-ui/core";
import { Close as CloseIcon } from '@material-ui/icons'
import InputTextField from "components/input/inputTextField";
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import BranchSingleFlatSelect from "components/select/branchSingleFlatSelect";
import DealerSelectContainer from "../../select/dealerSelect";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles((theme) => ({
  dialog: {
    maxWidth: "none"
  },
  root: {
    boxShadow: theme.shadows[5],
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "space-between",
    borderRadius: "0.25em",
    fontSize: "1rem",
    overflow: "hidden",
    "@media (max-width: 600px)": {
      maxWidth: "40em"
    },
    "@media (min-width: 600px)": {
      width: "34em"
    }
  },
  header: {
    paddingLeft: theme.spacing(3),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  containerBackground: {
    backgroundColor: "#EBEDEE"
  },
  content: {
    margin: "1em",
    backgroundColor: "#FFFFFF",
    maxHeight: "calc(100vh - 260px)", // Adjust the value as needed
    overflowY: "auto",
  },
  buttonContainer: {
    padding: "10px 16px",
    display: "flex",
    justifyContent: "flex-end"
  },
  selectRoot: {
    padding: "8px 14px"
  },
  inputRoot: {
    paddingTop: "8px !important",
    paddingBottom: "7px !important",
    paddingLeft: "12px !important",
    paddingRight: "12px !important"
  },
  fieldContainer: {
    paddingTop: "1em",
    margin: "1em",
    paddingBottom: "1em"
  },
  field: {
    display: "flex",
    alignItems: "flex-start"
  },
  field2: {
    display: "flex",
    alignItems: "center"
  }
}))

export default function InviteDealerDialogComponent({
  isOpen,
  handleClose,
  isBranchFetching,
  branchDropdown,
  selectedBranch,
  handleSelectBranch,
  selectedTierType,
  handleChangeTierType,
  isDealerDropdownFetching,
  dealerDropdown,
  handleSelectDealer,
  selectedDealer,
  inviteUrl,
  copyToClipboard,
  lang
}) {
  const classes = useStyle();

  return (
    <Dialog
      maxWidth="sm"
      open={isOpen}
      onClose={handleClose}
      disableBackdropClick
      closeAfterTransition
      BackdropComponent={Backdrop}
      classes={{
        paperWidthSm: classes.dialog
      }}
    >
      <Paper elevation={0} className={classes.root}>
        <Box className={classes.header}>
          <Typography
            variant="subtitle2"
            color="primary"
            style={{ fontWeight: "bold" }}
          >
            {getLang(lang, 'label.INVITE_DEALER')}
          </Typography>
          <IconButton color="primary" onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
        <Divider />
        <Box className={classes.containerBackground}>
          <Box className={classes.content}>
            <Box className={classes.fieldContainer}>
              <Grid container className={classes.field}>
                <Grid item xs={12} sm={4} style={{ display: "flex", alignItems: "center", marginTop: "1em" }}>
                  <Typography variant="body2" color="primary">
                    {getLang(lang, 'label.BRANCH')}
                  </Typography>&nbsp;<Typography variant="body2" color="error">*</Typography>
                </Grid>
                <Grid item xs={12} sm={8} style={{ display: "flex", alignItems: "center", marginTop: "0.65em" }}>
                  <BranchSingleFlatSelect
                    searchable={true}
                    value={selectedBranch}
                    placeholder={getLang(lang, 'placeholder.SELECT_A_BRANCH')}
                    searchPlaceholder={getLang(lang, 'placeholder.SEARCH_BRANCH_NAME')}
                    isClearable={false}
                    emptyMessage={getLang(lang, 'message.info.NO_BRANCH_FOUND')}
                    dropdownItem={branchDropdown}
                    isFetching={isBranchFetching}
                    handleChange={handleSelectBranch}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    isEmpty={selectedBranch === null}
                  />
                </Grid>
                <Grid item xs={12} sm={4} style={{ display: "flex", alignItems: "flex-start", paddingTop: "0.75em", marginTop: "1em" }}>
                  <Typography variant="body2" color="primary">
                    {getLang(lang, 'label.ASSIGN_DEALER_TO')}
                  </Typography>&nbsp;<Typography variant="body2" color="error">*</Typography>
                </Grid>
                <Grid item xs={12} sm={8} style={{ marginTop: "1em" }}>
                  <Box>
                    <RadioGroup aria-label="dealer-upline" name="dealer-upline" value={selectedTierType} onChange={handleChangeTierType}>
                      <FormControlLabel value="noupline" control={<Radio />} label={getLang(lang, 'label.T1_DEALER')} />
                      <FormControlLabel value="selectupline" control={<Radio />} label={getLang(lang, 'label.SELECT_UPLINE')} />
                    </RadioGroup>
                    <DealerSelectContainer
                      searchable={true}
                      value={selectedDealer}
                      placeholder={getLang(lang, 'placeholder.SELECT_UPLINE_DEALER')}
                      searchPlaceholder={getLang(lang, 'placeholder.SEARCH_DEALER_NAME')}
                      isClearable={false}
                      emptyMessage={getLang(lang, 'message.info.NO_DEALER_FOUND')}
                      dropdownItem={dealerDropdown}
                      isFetching={isDealerDropdownFetching}
                      handleChange={handleSelectDealer}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      isEmpty={selectedDealer === null}
                      disabled={selectedTierType === "noupline"}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>

            <Divider />
            <Box className={classes.fieldContainer}>
              <Typography variant="body">{getLang(lang, 'paragraph.COPY_SEND_LINK_TO_YOUR_DEALER')}</Typography>
              <Grid container className={classes.field2}>
                <Grid item xs={12} sm={9} style={{ paddingRight: "1em" }}>
                  <InputTextField
                    hiddenLabel
                    InputProps={{
                      readOnly: true,
                      style: {
                        backgroundColor: "rgba(0,0,0,0)"
                      }
                    }}
                    disabled={inviteUrl === ""}
                    name="branch"
                    variant="outlined"
                    placeholder={getLang(lang, 'placeholder.LINK_NOT_GENERATED')}
                    value={inviteUrl}
                    fullWidth
                    margin="dense"
                    onFocus={e => {
                      e.target.select();
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Button
                    variant="contained"
                    size="small"
                    startIcon={<FileCopyOutlinedIcon />}
                    style={{
                      border: "1px solid #D0D5DD",
                      backgroundColor: "#FFFFFF"
                    }}
                    onClick={() => copyToClipboard()}
                    disabled={inviteUrl === ""}
                  >
                    {getLang(lang, 'label.COPY_LINK')}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Dialog>

  )
}