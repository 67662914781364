import { addAlert, setLoading } from "modules/notification";
import { getReportValidation } from "../action";

// (1) branch sales data -- start
export const getReportValidationMiddleware = ({ dispatch, getState}) => next => action => {
    next(action);
    if (action.type === getReportValidation.pending.type) {
        dispatch(setLoading({ id: getReportValidation.typePrefix, state: true }));
    }
    if (action.type === getReportValidation.fulfilled.type) {
        dispatch(setLoading({ id: getReportValidation.typePrefix, state: false }));
    }
    if (action.type === getReportValidation.rejected.type) {
        dispatch(setLoading({ id: getReportValidation.typePrefix, state: false }));
        dispatch(addAlert({ severity: "error", message: action.payload.message}));
    }
}
// (1) branch sales data -- end

export default [
    getReportValidationMiddleware,
]