import SerialNumberFirstScanPopUpComponent from "./serialNumberFirstScanPopup.component";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {getSerialNumberFirstScanList} from "../../../redux/action/dashboard.action";
import {useHistory} from "react-router-dom";
import {ROOT as CONSUMER_ROOT} from 'modules/consumer';
import {ROOT as SERIAL_NUMBER_ROOT} from 'modules/serial-number';

export default function SerialNumberFirstScanPopUpContainer({
  isOpen,
  handleClose,
  startDate,
  endDate,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const lang = useSelector((state) => state.constant.languages);
  const firstScanSerialNumberList = useSelector(state => state.dashboard.firstScanSerialNumberList);

  const [page, setPage] = useState(0);

  useEffect(() => {
    dispatch(getSerialNumberFirstScanList({startDate, endDate, page}))
  }, [dispatch, startDate, endDate, page]);

  const handleLoadingMore = () => {
    if (firstScanSerialNumberList.records.length === firstScanSerialNumberList.totalRecords) return;

    setPage(page + 1);
  }

  const handleConsumerNameClick = (id) => {
    history.push(CONSUMER_ROOT + '/' + id);
  }

  const handleSerialNumberClick = (id) => {
    history.push(SERIAL_NUMBER_ROOT + '/details/' + id);
  }

  return <SerialNumberFirstScanPopUpComponent
    isOpen={isOpen}
    handleClose={handleClose}
    lang={lang}
    rows={firstScanSerialNumberList.records}
    haveNext={firstScanSerialNumberList.hasNextPage}
    handleLoadingMore={handleLoadingMore}
    handleConsumerNameClick={handleConsumerNameClick}
    handleSerialNumberClick={handleSerialNumberClick}
  />
}
