import React from "react";
import { makeStyles } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

const style = (theme) => ({
  backdrop: {
    zIndex: "1500",
    color: "#fff"
  }
});

const useStyles = makeStyles(style);
export default function BackDropComponent({ open }) {
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open={open}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
