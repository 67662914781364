import React, { useEffect } from "react";
import SavingDialogComponent from "./savingDialog.component";
import { connect, useSelector } from "react-redux";
import { selectLoading } from "modules/notification";
import { publishNewProduct } from "modules/product-mgmt/redux/action/products.action";
import { useHistory } from "react-router-dom";
import { ROOT } from "../../../config/product-mgmt.route";

function SavingDialogContainer({ isOpen, handleClose, success, isPublishing }) {
  const history = useHistory();
  const lang = useSelector(state => state.constant.languages)

  useEffect(() => {
    if (!isPublishing && !success) handleClose();
  }, [success, isPublishing, handleClose]);

  const handleSeeListing = () => {
    history.push(ROOT);
  };

  return (
    <SavingDialogComponent
      isOpen={isOpen}
      handleClose={handleClose}
      isPublishing={isPublishing}
      handleSeeListing={handleSeeListing}
      lang={lang}
    />
  );
}

const mapStateToProps = state => ({
  isPublishing: selectLoading(state, publishNewProduct.type),
  success: state.products.hasCreatedNewProduct
});

export default connect(mapStateToProps)(SavingDialogContainer);
