import React from 'react';
import { Box } from '@material-ui/core';
import { layoutContentCardStyle } from 'modules/reporting/styles';

export default function ContentCardComponent({ children, id, style },){
    const classes = layoutContentCardStyle();

    return (
        <Box id={id}
            className={classes.card}
            style={style}
        >
            {children}
        </Box>
    )
}
