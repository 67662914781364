import React from "react";
import {
  Backdrop,
  Box,
  Paper,
  Divider,
  makeStyles,
  Typography,
  Grid,
  Button,
  IconButton
} from "@material-ui/core";
import Dialog from "components/dialog/customDialog";
import CloseIcon from "@material-ui/icons/Close";
import { Formik } from "formik";
import { RoleSelect, BranchSelect } from "../../select";
import { getLang } from "app/feature/constants";

const style = (theme) => ({
  infoContainer: {
    width: "650px",
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    }
  },
  wrapper: {
    display: "flex",
    placeContent: "center",
    padding: theme.spacing(1),
    backgroundColor: "#E5E5E5"
  },
  detailsWrapper: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(4),
    height: "30vh",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
    [theme.breakpoints.up("md")]: {
      width: "90%"
    },
    [theme.breakpoints.down("md")]: {
      width: "100%"
    }
  },
  header: {
    paddingLeft: theme.spacing(3),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  buttonAdd: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "flex-end"
  },
  employeeDetail: {
    border: "solid 1px #DCE0E7",
    padding: theme.spacing(3),
    marginTop: "18px",
    marginBottom: theme.spacing(1),
    display: "flex",
    justifyContent: "center",
    textAlign: "center"
  },
  footer: {
    background: theme.palette.common.white,
    boxShadow: "0px -1px 0px rgba(58, 77, 84, 0.15)",
    borderRadius: "0px 0px 4px 4px",
    padding: theme.spacing(2, 4, 2, 3),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  }
});

const useStyles = makeStyles(style);

export default function EditEmployeeDialogComponent({
  isOpen,
  handleClose,
  handleSubmit,
  employeeDetail,
  lang
}) {
  const classes = useStyles();

  return (
    <Dialog
      isOpen={isOpen}
      handleClose={handleClose}
      disableEnforceFocus={true}
      disableAutoFocus={true}
      disableEscapeKeyDown={true}
      disableBackdropClick={true}
      closeAfterTransition
      BackdropComponent={Backdrop}
    >
      <Paper elevation={0} className={classes.root}>
        <Box className={classes.infoContainer}>
          <div className={classes.header}>
            <Typography variant="subtitle2" style={{ fontWeight: "bold" }}>
              {getLang(lang, "label.EDIT_EMPLOYEE")}
            </Typography>
            <IconButton color="primary" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </div>
          <Divider />

          <Formik
            initialValues={{
              role: employeeDetail.role,
              branch: employeeDetail.nodeIds
            }}
            onSubmit={handleSubmit}
          >
            {(formik) => (
              <form onSubmit={formik.handleSubmit}>
                <Box className={classes.wrapper}>
                  <Box className={classes.detailsWrapper}>
                    <Grid
                      container
                      style={{ alignItems: "center", height: 43 }}
                    >
                      <Grid item xs={3} sm={3} md={3}>
                        <Typography variant="body2" color="textSecondary">
                          {getLang(lang, "label.EMAIL")} <Typography style={{ color: "#DE350B", display: 'inline-block' }}>*</Typography>
                        </Typography>
                      </Grid>
                      <Grid item xs={9} sm={9} md={9}>
                        <Typography
                          variant="body2"
                          color="primary"
                          style={{ paddingLeft: "8px" }}
                        >
                          {employeeDetail.email}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      style={{ alignItems: "center", paddingTop: "8px" }}
                    >
                      <Grid item xs={3} sm={3} md={3}>
                        <Typography variant="body2" color="textSecondary">
                          {getLang(lang, "label.ROLE")} <Typography style={{ color: "#DE350B", display: 'inline-block' }}>*</Typography>
                        </Typography>
                      </Grid>
                      <Grid item xs={9} sm={9} md={9}>
                        <RoleSelect
                          value={formik.values.role}
                          handleChange={(value) =>
                            formik.setFieldValue("role", value)
                          }
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      style={{ alignItems: "center", paddingTop: "16px" }}
                    >
                      <Grid item xs={3} sm={3} md={3}>
                        <Typography variant="body2" color="textSecondary">
                          {getLang(lang, "label.BRANCH")} <Typography style={{ color: "#DE350B", display: 'inline-block' }}>*</Typography>
                        </Typography>
                      </Grid>
                      <Grid item xs={9} sm={9} md={9}>
                        <BranchSelect
                          value={formik.values.branch}
                          handleChange={(value) =>
                            formik.setFieldValue("branch", value)
                          }
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>

                <Box className={classes.footer}>
                  <Typography variant="caption" style={{ color: "#9CA6A9" }}>
                    <Typography style={{ color: "#DE350B", display: 'inline-block' }}>*</Typography> {getLang(lang, "label.REQUIRED_FIELD")}
                  </Typography>


                  <Box
                    display="flex"
                    justifyContent="flex-end"
                  >
                    <Button
                      disableElevation
                      type="button"
                      style={{ marginRight: "4px" }}
                      onClick={(e) => {
                        formik.resetForm()
                        handleClose(e)
                      }}
                    >
                      {getLang(lang, "label.CANCEL")}
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                      type="submit"
                      onClick={(e) => {
                        handleClose(e)
                      }}
                    >
                      {getLang(lang, "label.UPDATE")}
                    </Button>
                  </Box>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </Paper>
    </Dialog>
  );
}
