import React from "react";
import DealerIncentiveProgramFormComponent from './dealerIncentiveProgramForm.component';

export default function  DealerIncentiveProgramFormContainer({
  type,
  productDropdown,
  maxTier,
  handleSubmit,
  programDetails,
  lang,
  isSubmitting,
  isFetchingProduct
}) {
  return (
    <DealerIncentiveProgramFormComponent 
      type={type} 
      productDropdown={productDropdown}
      maxTier={maxTier}
      handleSubmit={handleSubmit}
      programDetails={programDetails}
      lang={lang}
      isSubmitting={isSubmitting}
      isFetchingProduct={isFetchingProduct}
    />
  )
}
