import axios from "axios";

const s3Url = process.env.REACT_APP_S3_BUCKET_URL

const getCountry = () => {
    return new Promise((resolve, reject) => {
        axios.get(`${s3Url}/constants/countries.json`)
            .then(response => resolve(response.data))
            .catch(error => reject(error.message))
    });
};

export default getCountry;
