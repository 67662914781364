import React, { useEffect, useState } from "react";
import WarranteeListPageComponent from "./warranteeList.page";
import {connect, useDispatch, useSelector} from "react-redux";
import { selectLoading } from "modules/notification";
import {fetchWarranteeListTable, setIsWarranteeTableDirty} from "modules/warranty/redux";
import { useHistory } from "react-router";
import { WARRANTY_LIST_VIEW, WARRANTY_LIST_EDIT } from "lib/constants/accessRights";
import LoadingComponent from "components/loading";
import { generatePaginationOptions } from "lib/helper";

function WarranteeListPageContainer({
  isFetching,
  warranteeListData,
  length,
  filteredlength,
  fetchWarranteeListTable
}) {
  const dispatch = useDispatch();
  const lang = useSelector(state => state.constant.languages);
  const history = useHistory();
  const hasWarrantyViewAccessRight = useSelector(state => state.profile.accessRights.includes(WARRANTY_LIST_VIEW))
  const hasWarrantyEditAccessRight = useSelector(state => state.profile.accessRights.includes(WARRANTY_LIST_EDIT))
  const isWarrenteeTableDirty = useSelector(state => state.warranty.isWarranteeTableDirty);
  const [page, setPage] = useState(0);
  const [paginationOptions, setPaginationOptions] = useState([]);
  const [pageSetting, setPageSetting] = useState({
    length: 25,
    start: 10 * 0,
    column: "end_at",
    order: "desc",
    search: ""
  });

  useEffect(() => {
    if(!hasWarrantyViewAccessRight){
      history.push('/admin/dashboard')
    }
  }, [history, hasWarrantyViewAccessRight])

  useEffect(() => {
    setPaginationOptions(generatePaginationOptions(length))
  }, [length])

  useEffect(() => {
    if (!isWarrenteeTableDirty) return;
    handleReload();
    dispatch(setIsWarranteeTableDirty(false));
    // eslint-disable-next-line
  }, [isWarrenteeTableDirty])

  function searchWarrantyList(value) {
    setPageSetting({
      length: pageSetting.length,
      start: pageSetting.length * 0,
      column: pageSetting.column,
      order: pageSetting.order,
      search: value.search
    });
    setPage(0);
  }

  function clearSearch() {
    setPageSetting({
      length: pageSetting.length,
      start: pageSetting.length * 0,
      column: pageSetting.column,
      order: pageSetting.order,
      search: "",
    });
    setPage(0);
  }

  useEffect(() => {
    const warranteeInfo = {
      id: history.location.pathname.substring(
        history.location.pathname.lastIndexOf("/") + 1
      ),
      ...pageSetting
    };

    fetchWarranteeListTable(warranteeInfo);
  }, [fetchWarranteeListTable, history.location.pathname, pageSetting]);

  if(!hasWarrantyViewAccessRight) return <LoadingComponent />;

  const handleReload = () => {
    const warranteeInfo = {
      id: history.location.pathname.substring(
        history.location.pathname.lastIndexOf("/") + 1
      ),
      ...pageSetting
    };
    fetchWarranteeListTable(warranteeInfo);
  };

  function handlePageChange(event, page) {
    setPage(page);
    setPageSetting({
      length: pageSetting.length,
      start: pageSetting.length * page,
      column: pageSetting.column,
      order: pageSetting.order,
      search: pageSetting.search
    });
  }

  function handleRowPerPageChange(event) {
    setPage(0);
    setPageSetting((prev) => ({
      ...prev,
      page: 0,
      length: event.target.value
    }))
  }

  const handleSubmit = async (values, formikApi) => {
    await searchWarrantyList(values);
    formikApi.setSubmitting(false);
  };

  const handleClear = (formik) => {
    formik.setFieldValue("search", "");
    clearSearch();
  };

  const changeOrder = (orderBy, order) => {
    let newData = {
      ...pageSetting,
      order: order,
      column: orderBy
    }
    setPageSetting(newData)
  }


  return (
    <WarranteeListPageComponent
      hasWarrantyEditAccessRight={hasWarrantyEditAccessRight}
      warranteeListData={warranteeListData}
      isFetching={isFetching}
      rowsPerPage={pageSetting.length}
      handlePageChange={handlePageChange}
      handleRowPerPageChange={handleRowPerPageChange}
      page={page}
      length={pageSetting.search ? filteredlength : length}
      search={pageSetting.search}
      handleSubmit={handleSubmit}
      handleClear={handleClear}
      pageSetting={pageSetting}
      handleReload={handleReload}
      paginationOptions={paginationOptions}
      lang={lang}
      tableFilter={pageSetting}
      changeOrder={changeOrder}
    />
  );
}

const mapStateToProps = (state) => ({
  warranteeListData: state.warranty.warranteeListTable.data,
  length: state.warranty.warranteeListTable.totalRecords,
  filteredlength: state.warranty.warranteeListTable.filteredTotalRecords,
  isFetching: selectLoading(state, fetchWarranteeListTable.typePrefix)
});

const mapDispatchToProps = (dispatch) => ({
  fetchWarranteeListTable: (value) => dispatch(fetchWarranteeListTable(value))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WarranteeListPageContainer);
