import React, { useState, useRef, useEffect } from "react";
import SurveySelectComponent from "./surveySelect.component";
import SurveySelectPanelComponent from "./surveySelectPanel.component";
import { selectLoading } from "modules/notification";
import { useDispatch, useSelector } from "react-redux";
import { getAvailableSurveys } from "modules/survey/redux/action";
import { resetAvailableSurveys } from "modules/survey/redux/slice";

function SurveySelectContainer({
  placeholder,
  handleChange,
  value,
  disabled,
  styles,
  searchPlaceholder,
  searchable,
  textStyles,
}) {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.constant.languages);
  const surveys = useSelector((state) => state.survey.availableSurveys.list);
  const isFetching = useSelector((state) =>
    selectLoading(state, getAvailableSurveys.typePrefix)
  );
  const currentTotal = useSelector(
    (state) => state.survey.availableSurveys.currentTotal
  );
  const totalFiltered = useSelector(
    (state) => state.survey.availableSurveys.totalFiltered
  );
  const hasNextPage = useSelector(
    (state) => state.survey.availableSurveys.hasNextPage
  );
  const isError = useSelector((state) => state.survey.availableSurveys.isError);
  const productId = useSelector((state) => state.productPage.productId);
  const components = useSelector((state) => state.productPage.components);

  const [open, setOpen] = useState(false);
  const [isOpenList] = useState(false);
  const anchorRef = useRef(null);
  const prevOpen = useRef(open);
  const prevOpenList = useRef(isOpenList);
  const [filter, setFilter] = useState({
    page: 0,
    rowsPerPage: 30,
    search: "",
  });

  const [surveyList, setSurveyList] = useState([]);

  useEffect(() => {
    let temp = surveys;
    let surveyComp = Object.keys(components).filter((c) =>
      c.startsWith("survey")
    );
    if (surveyComp) {
      let usedId = [];
      surveyComp.forEach((key) => {
        let comp = components[key];
        if (comp?.content?.id && comp.content.id !== value) {
          usedId.push(comp.content.id);
        }
      });
      temp = temp.filter((t) => !usedId.includes(t.uuid));
    }

    setSurveyList(temp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveys, components]);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    if (prevOpenList.current === true && isOpenList === false) {
      anchorRef.current.focus();
    }

    prevOpenList.current = isOpenList;
  }, [isOpenList]);

  useEffect(() => {
    if (hasNextPage && !isFetching) {
      getData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, dispatch]);

  const getData = () => {
    let param = {
      length: filter.rowsPerPage,
      start: filter.rowsPerPage * filter.page,
      survey_name: filter.search,
      product_id: productId,
    };

    dispatch(getAvailableSurveys(param));
  };

  const handleSearch = (search) => {
    dispatch(resetAvailableSurveys());
    setFilter({
      ...filter,
      page: 0,
      search: search,
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleFetchMoreItems = () => {
    if (hasNextPage) {
      const newFilter = { ...filter, page: filter.page + 1 };
      setFilter(newFilter);
    }
  };

  const handleReload = () => {
    dispatch(resetAvailableSurveys());
    setFilter({
      ...filter,
      page: 0,
      search: "",
    });
  };

  const handleSelectSurvey = (survey) => {
    handleChange(survey);
    handleClose();
  };

  return (
    <>
      <SurveySelectComponent
        anchorRef={anchorRef}
        handleClick={handleClick}
        value={value}
        styles={styles}
        placeholder={placeholder}
        disabled={disabled}
        textStyles={textStyles}
        lang={lang}
        surveys={surveyList}
      />
      <SurveySelectPanelComponent
        value={value}
        anchorRef={anchorRef}
        open={open}
        handleClose={handleClose}
        isFetching={isFetching}
        surveys={surveyList}
        handleSelectSurvey={handleSelectSurvey}
        searchable={searchable}
        placeholder={searchPlaceholder}
        handleSearch={handleSearch}
        lang={lang}
        totalFiltered={totalFiltered}
        handleFetchMoreItems={handleFetchMoreItems}
        hasNextPage={hasNextPage}
        isError={isError}
        handleReload={handleReload}
        currentTotal={currentTotal}
      />
    </>
  );
}

export default SurveySelectContainer;
