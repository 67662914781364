import moment from "moment";

const API_HOST = process.env.REACT_APP_API_HOST_URL;

const exportAdtInfoForm = async (parameters) => {
  let response = await fetch(
    `${API_HOST}/serialnumber/export/v1/additional-info/sample?token=${parameters.token}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ranges: parameters.ranges,
        type: parameters.type,
        supportedLanguages: parameters.supportedLanguages,
        fields: parameters.fields,
        isSelectAllFields: parameters.isSelectAllFields,
      }),
    }
  );

  let blob = await response.blob();

  saveFile(blob);
};

const saveFile = async (blob) => {
  const a = document.createElement("a");
  const date = moment().format("YYYYMMDD");
  a.download = `additional_info_${date}.csv`;
  a.href = URL.createObjectURL(blob);
  a.addEventListener("click", (e) => {
    setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
  });
  a.click();
};

export default exportAdtInfoForm;
