import axios from "axios";

export function getRequestTransferTargetBranches(parameters) {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `/distributor/admin/api/v1/request-transfer/get-branches?length=${parameters.length}&start=${parameters.start}&search=${parameters.search}&from_node_uuid=${parameters.from_node_uuid}`
      )
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data));
  });
}
