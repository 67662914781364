import React from "react";
import PropTypes from "prop-types";
import RejectConfirmDialogComponent from "./rejectConfirmDialog.component";
import { useSelector } from "react-redux";

function RejectConfirmDialogContainer({
  isLoading,
  isOpen,
  handleClose,
  handleProceed,
  title,
  description,
}) {
  const lang = useSelector((state) => state.constant.languages);

  return (
    <RejectConfirmDialogComponent
      isLoading={isLoading}
      isOpen={isOpen}
      handleClose={handleClose}
      handleProceed={handleProceed}
      lang={lang}
      title={title}
      description={description}
    />
  );
}

RejectConfirmDialogContainer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleProceed: PropTypes.func.isRequired,
};

export default RejectConfirmDialogContainer;
