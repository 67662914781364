import React, { useState, useRef, useEffect } from "react";
import Modal from "modules/profile/components/modal";
import PhotoProductPanelComponent from "./photoProductPanel.component";
import { connect, useSelector } from "react-redux";
import { addAlert, selectLoading } from "modules/notification";
import { setProductPicture } from "../../../redux/slice/productPage.slice";
import PropTypes from "prop-types";
import { updateProductPhoto } from "../../../redux/action/products.action";
import { Paper, CircularProgress, Box } from "@material-ui/core";
import { PRODUCT_PRODUCT_LIST_ADD, PRODUCT_PRODUCT_LIST_EDIT } from "lib/constants/accessRights"
import { ImageEditorPanel } from "components/panel";
import { isImage, isFileExceedLimit } from "lib/helper"
import { getLang } from "app/feature/constants";
import {setImgFormatAndSize} from "../../../../../lib/FileHelper";

function PhotoProductPanelContainer({
  productPicture,
  addAlert,
  setProductPicture,
  isDirectUpload,
  updateProductPhoto,
  isSaving
}) {
  const type = isDirectUpload ? "edit" : "create"
  const hasProductAddAccessRight = useSelector(state => state.profile.accessRights.includes(PRODUCT_PRODUCT_LIST_ADD));
  const hasProductEditAccessRight = useSelector(state => state.profile.accessRights.includes(PRODUCT_PRODUCT_LIST_EDIT));
  const lang = useSelector(state => state.constant.languages);
  const inputImgRef = useRef();

  const [isCropping, setCropping] = useState(false);
  const [bufferImg, setBufferImg] = useState();
  const [isModalOpen, setModalOpen] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const allowedImageExtension = [".jpeg", ".jpg", ".png"];

  const handleImgChange = e => {
    const file = inputImgRef.current.files[0];

    if (file) {
      const rawFile = e.target.files[0];

      if (!isImage(rawFile)) {
        addAlert({ severity: "error", message: getLang(lang,"message.error.INVALID_IMAGE_FORMAT") });

        return;
      }
      if (isFileExceedLimit(rawFile.size, 12, "MB")) {
        addAlert({
          severity: "error",
          message: getLang(lang,"message.error.FILE_TOO_LARGE")
        });

        return;
      }
      const image = URL.createObjectURL(rawFile);
      setBufferImg(image);
      setModalOpen(true);
      setCropping(true);
    }
  };

  
  const handleDrop = dropped => {
    setDragging(false);
    const rawFile = dropped[0];
    if (!ValidImageFormat(rawFile.name)) {
      addAlert({ severity: "error", message: getLang(lang,"message.error.INVALID_IMAGE_FORMAT") });

      return;
    }

    if (!isImageSizeValid(rawFile.size)) {
      addAlert({
        severity: "error",
        message: getLang(lang,"message.error.FILE_TOO_LARGE")
      });

      return;
    }
    const image = URL.createObjectURL(rawFile);

    setBufferImg(image);
    setModalOpen(true);
    setCropping(true);
  };

  const ValidImageFormat = file => {
    for (let index = 0; index < allowedImageExtension.length; index++) {
      if (file.toLowerCase().indexOf(allowedImageExtension[index]) !== -1) {
        return true;
      }
    }
    return false;
  };

  const isImageSizeValid = size => {
    // max file size is 12 mb
    if (size <= 1024 * 1024 * 12) return true;
    else return false;
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    if (hasSubmitted && !isSaving) setModalOpen(false);
  }, [hasSubmitted, isSaving]);

  const handleResult = async base64Image => {
    setCropping(false);
    setHasSubmitted(true);

    base64Image = await setImgFormatAndSize(base64Image, 2160, 2160, "image/webp");

    if (isDirectUpload) {
      // upload directly to s3 for update product image

      updateProductPhoto(base64Image);
    } else {
      // store base64 image to redux store
      setModalOpen(false);
      setProductPicture(base64Image);
    }
  };

  const handleDragEnter = () => {
    setDragging(true);
  };
  const handleDragLeave = () => {
    setDragging(false);
  };

  return (
    <>
      <PhotoProductPanelComponent
        hasProductAddAccessRight={hasProductAddAccessRight}
        hasProductEditAccessRight={hasProductEditAccessRight}
        type={type}
        productPicture={productPicture}
        inputImgRef={inputImgRef}
        handleImgChange={handleImgChange}
        handleDragEnter={handleDragEnter}
        handleDragLeave={handleDragLeave}
        handleDrop={handleDrop}
        dragging={dragging}
        lang={lang}
      />
      <Modal
        isOpen={isModalOpen}
        handleClose={handleClose}
        disableEnforceFocus={true}
        disableAutoFocus={true}
      >
        {isSaving && (
          <Paper elevation={0}>
            <Box p={10}>
              <CircularProgress color="primary" />
            </Box>
          </Paper>
        )}
        {isCropping && (
          <ImageEditorPanel
            img={bufferImg}
            handleCancel={handleClose}
            handleResult={handleResult}
          />
        )}
      </Modal>
    </>
  );
}

PhotoProductPanelContainer.defaultProps = {
  isDirectUpload: false
};

PhotoProductPanelContainer.propTypes = {
  isDirectUpload: PropTypes.bool
};

const mapStateToProps = state => ({
  productPicture: state.productPage.picture,
  isSaving: selectLoading(state, updateProductPhoto.type)
});

const mapDispatchToProps = dispatch => ({
  addAlert: payload => dispatch(addAlert(payload)),
  setProductPicture: payload => dispatch(setProductPicture(payload)),
  updateProductPhoto: payload => dispatch(updateProductPhoto(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PhotoProductPanelContainer);
