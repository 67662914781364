import React, { useEffect } from "react";
import SetWinnerPanelComponent from "./setWinnerPanel.component";
import { selectLoading } from "modules/notification";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchLuckyDrawParticipant,
  resetParticipantTable,
  setParticipantSearch
} from "modules/lucky-draw/redux";

function SetWinnerPanelContainer({
  value,
  anchorRef,
  open,
  isOpenList,
  handleClose,
  handleListKeyDown
}) {
  const dispatch = useDispatch();
  const search = useSelector((state) => state.luckyDraw.searchForParticipants);
  const luckyDrawId = useSelector(
    (state) => state.luckyDraw.LuckyDrawDetail.id
  );

  const luckyDrawParticipant = useSelector(
    (state) => state.luckyDraw.participantList
  );

  const excludeList = useSelector((state) => state.luckyDraw.excludeList);

  const isFetching = useSelector((state) =>
    selectLoading(state, fetchLuckyDrawParticipant.typePrefix)
  );
  const lang = useSelector(state => state.constant.languages);


  useEffect(() => {
    if (open) {
      const fetchParticipantInfo = {
        luckyDrawId,
        length: "10",
        start: 0,
        column: "createdAt",
        order: "desc",
        search: search
      };
      dispatch(fetchLuckyDrawParticipant(fetchParticipantInfo));
    }
    return () => {
      dispatch(resetParticipantTable());
    };
  }, [dispatch, luckyDrawId, search, open]);

  const handleSearch = (value) => {
    dispatch(setParticipantSearch(value));
  };

  const handleFetchMoreParticipant = () => {
    if(open) {
      const fetchParticipantInfo = {
        luckyDrawId,
        length: "10",
        start: luckyDrawParticipant.data.length,
        search: search,
        column: "createdAt",
        order: "desc",
      }
      dispatch(fetchLuckyDrawParticipant(fetchParticipantInfo));
    }
  }

  return (
    <>
      <SetWinnerPanelComponent
        excludeList={excludeList}
        value={value}
        anchorRef={anchorRef}
        open={open}
        isOpenList={isOpenList}
        handleClose={handleClose}
        isFetching={isFetching}
        handleListKeyDown={handleListKeyDown}
        handleSearch={handleSearch}
        luckyDrawParticipantData={luckyDrawParticipant.data}
        fetchMoreData={handleFetchMoreParticipant}
        hasMoretoLoad={luckyDrawParticipant.recordsFiltered > luckyDrawParticipant.data.length }
        lang={lang}
      />
    </>
  );
}

export default SetWinnerPanelContainer;
