import { makeStyles } from "@material-ui/core";

export const layoutContentCardStyle = makeStyles((theme) => ({
    card: {
        margin: "20px",
        padding: "20px",
        backgroundColor: "#FFFFFF",
        borderRadius: "12px",
        border: "1px solid #D0D5DD"
    },
}))