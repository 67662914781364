import React from "react";
import {
  makeStyles,
  Box,
  Typography,
  Button,
  Divider,
  Grid,
  Dialog,
} from "@material-ui/core";
import ProPlan from "../../../utils/image/ProPlan.png";
import BizPlan from "../../../utils/image/BizPlan.png";
import LitePlan from "../../../utils/image/LitePlan.png";
import PlanBackground from "../../../utils/image/PlanBackground.png"
import CloseSvg from "../../../utils/svg/close.svg";
import AlertSvg from "../../../utils/svg/alert.svg";
import AntSwitch from "components/switch/switch";
import LoadingComponent from "components/loading";
import { getLang } from "app/feature/constants";
import { PROFESSIONAL, BUSINESS, LITE } from "lib/constants/subscriptionPlan";

const useStyle = makeStyles(theme => ({
  // Change Plan Dialog
  // Title
  changePlanDialogTitleBarRoot: {
    height: 30,
    display: "flex",
    justifyContent: 'space-between',
    alignItems: "center",
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  changePlanDialogTitleBarTitle: {
    display: "flex",
    alignItems: "center",
    fontSize: 13,
    fontWeight: "bold",
  },
  changePlanDialogTitleBarClose: {
    cursor: "pointer",
    width: 10,
  },

  // Divider
  changePlanDialogDivider: {
  },

  // Change to Plan Title
  changePlanDialogPlanTitleRoot: {
    backgroundImage: `url(${PlanBackground})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: 115,
    backgroundPositionY: "top",
    backgroundPositionX: 30,
    height: 80,
    display: "flex",
    alignItems: "center",
    paddingLeft: 20,
    paddingRight: 20,
  },
  changePlanDialogPlanTitleImage: {
    width: 40
  },
  changePlanDialogPlanTitleTextBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: 80,
    marginLeft: 20,
  },
  changePlanDialogPlanTitleTextBoxTitle: {
    fontSize: 18,
    fontWeight: "bold",
  },
  changePlanDialogPlanTitleTextBoxDescription: {
    fontSize: 12,
  },
  changePlanDialogPlanTitleTextBoxDescriptionBold: {
    fontSize: 12,
    fontWeight: "bold",
  },
  changePlanDialogPlanTitleTextBoxDescriptionGreenBold: {
    fontSize: 12,
    fontWeight: "bold",
    color: "#4BCB0F"
  },

  // Change to Plan Subscription Type
  changePlanDialogSelectPlanSubscriptionTypeRoot: {
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 20,
    paddingRight: 20,
  },
  changePlanDialogSelectPlanSubscriptionTypeLabel: {
    fontSize: 12,
    paddingBottom: 10,
  },
  changePlanDialogSelectPlanSubscriptionTypeSelectionsBox: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  changePlanDialogSelectPlanSubscriptionTypeSelectionBox: {
    display: "flex",
    width: 120,
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 15,
    paddingRight: 15,
    border: "1px solid #3A4D54",
    borderRadius: 4,
    cursor: "pointer",
    position: "relative",
  },
  changePlanDialogSelectPlanSubscriptionTypeSelectionBoxActive: {
    display: "flex",
    width: 120,
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 15,
    paddingRight: 15,
    border: "4px solid #6AAF68",
    borderRadius: 4,
    cursor: "pointer",
    position: "relative",
  },
  changePlanDialogSelectPlanSubscriptionTypeSelectionBoxPrice: {
    fontSize: 14,
    fontWeight: "bold",
    marginLeft: 15,
  },
  changePlanDialogSelectPlanSubscriptionTypeSelectionBoxUnit: {
    fontSize: 14,
  },
  changePlanDialogSelectPlanSubscriptionTypeSelectionBoxBorderLabel: {
    position: "absolute",
    fontSize: 10,
    color: "rgba(58, 77, 84, 0.5)",
    bottom: -10,
    right: 10,
    padding: 2,
    background: "#FFFFFF",
  },
  changePlanDialogSelectPlanSubscriptionTypeOr: {
    fontSize: 10,
    marginTop: 15,
    marginBottom: 15,
    marginLeft: 20,
    marginRight: 20,
  },

  // Change to Plan Accessibilities 
  changePlanDialogPlanAccessibilitiesRoot: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 40,
    paddingRight: 40,
  },
  changePlanDialogPlanAccessibilitiesUl: {
    listStyle: "disc",
    marginTop: 0,
    marginBottom: 5,
  },
  changePlanDialogPlanAccessibilitiesTitle: {
    fontSize: 10,
    fontWeight: "bold",
    color: "#3A4D54",
    marginBottom: 10,
  },

  // Change to Plan Accessibilities Metadata
  changePlanDialogPlanAccessibilitiesMetadataTitle: {
    fontSize: 12,
    fontWeight: "bold",
    color: "rgba(58, 77, 84, 0.33)",
    marginBottom: 5,
  },
  changePlanDialogPlanAccessibilitiesMetadataTitleActive: {
    fontSize: 12,
    fontWeight: "bold",
    color: "#6AAF68",
    marginBottom: 5,
  },
  changePlanDialogPlanAccessibilitiesMetadataText: {
    fontSize: 12,
    color: "rgba(58, 77, 84, 0.33)",
  },
  changePlanDialogPlanAccessibilitiesMetadataTextActive: {
    fontSize: 12,
    color: "#3A4D54",
  },

  // Change to Plan Accessibilities Data
  changePlanDialogPlanAccessibilitiesDataTitle: {
    fontSize: 12,
    fontWeight: "bold",
    color: "rgba(58, 77, 84, 0.33)",
    marginBottom: 5,
  },
  changePlanDialogPlanAccessibilitiesDataTitleActive: {
    fontSize: 12,
    fontWeight: "bold",
    color: "#3A4D54",
    marginBottom: 5,
  },
  changePlanDialogPlanAccessibilitiesDataText: {
    fontSize: 12,
    color: "rgba(58, 77, 84, 0.33)",
  },
  changePlanDialogPlanAccessibilitiesDataTextActive: {
    fontSize: 12,
    color: "#3A4D54",
  },

  // Change to Plan Charges
  changePlanDialogPlanChargesRoot: {
    padding: 20,
    color: "#3A4D54"
  },
  changePlanDialogPlanChargesTitle: {
    fontSize: 10
  },
  changePlanDialogPlanChargesDetails: {
    display: "flex",
    marginTop: 10,
    marginBottom: 10,
    flexWrap: "wrap",
  },
  changePlanDialogPlanChargesDetailsItem: {
    marginRight: 30,
    "&:last-child": {
      marginRight: 0,
      marginLeft: "auto",
    }
  },
  changePlanDialogPlanChargesDetailsItemLabel: {
    fontSize: 10,
  },
  changePlanDialogPlanChargesDetailsItemPrice: {
    fontSize: 16,
  },
  changePlanDialogPlanChargesWarning: {
    display: "flex",
  },
  changePlanDialogPlanChargesWarningIcon: {
    width: 14,
    marginRight: 10,
  },
  changePlanDialogPlanChargesWarningLabel: {
    fontSize: 10,
  },
}));

function IsUpgradeDisableOrSwap({
  userSubscriptionPlan,
  subscriptionPlan,
  lang
}) {
  if (userSubscriptionPlan.productName.toLocaleLowerCase() === subscriptionPlan.name.toLocaleLowerCase()) {
    return getLang(lang, 'label.SWAP')
  } else {
    if (userSubscriptionPlan.productName.toLocaleLowerCase() === PROFESSIONAL.toLocaleLowerCase()) {
      return getLang(lang, 'label.DISABLE')
    } else if (userSubscriptionPlan.productName.toLocaleLowerCase() === BUSINESS.toLocaleLowerCase()) {
      if (subscriptionPlan.name.toLocaleLowerCase() === PROFESSIONAL.toLocaleLowerCase()) {
        return getLang(lang, 'label.UPGRADE')
      }
      if (subscriptionPlan.name.toLocaleLowerCase() === LITE.toLocaleLowerCase()) {
        return getLang(lang, 'label.DISABLE')
      }
    } else if (userSubscriptionPlan.productName.toLocaleLowerCase() === LITE.toLocaleLowerCase()) {
      if (subscriptionPlan.name.toLocaleLowerCase() === PROFESSIONAL.toLocaleLowerCase()) {
        return getLang(lang, 'label.UPGRADE')
      }
    }
    return getLang(lang, 'label.DISABLE')
  }
}

function ChangePlanDialogTitleBar({
  title,
  onChangePlanCloseClick,
}) {
  let classes = useStyle();
  return (
    <Box className={classes.changePlanDialogTitleBarRoot}>
      <Typography className={classes.changePlanDialogTitleBarTitle}>
        {title}
      </Typography>
      <img
        className={classes.changePlanDialogTitleBarClose}
        src={CloseSvg}
        alt={"close svg"}
        onClick={() => onChangePlanCloseClick()}
      />
    </Box>
  )
}

function ChangePlanDialogPlanTitle({
  registrationType,
  userSubscriptionPlan,
  subscriptionPlan,
  lang,
  subscriptionType
}) {
  const classes = useStyle()

  let image = null;
  if (subscriptionPlan.title === "Lite plan") image = LitePlan
  if (subscriptionPlan.title === "Pro plan") image = ProPlan
  if (subscriptionPlan.title === "Biz plan") image = BizPlan

  let basePrice = 0
  let selectedPlanQrPrice = null;
  switch (registrationType) {
    case 1:
      let selectedPlanPrice = subscriptionPlan.prices.find(price => price.interval === subscriptionType)
      selectedPlanQrPrice = subscriptionPlan.qr_credit_prices.find(
        qrcp =>
          qrcp.registration_type === 1 &&
          qrcp.stripe_product === selectedPlanPrice.stripe_product &&
          qrcp.stripe_plan === selectedPlanPrice.stripe_plan
      )
      break;
    case 4:
      selectedPlanQrPrice = subscriptionPlan.qr_credit_prices.find(
        qrcp => qrcp.registration_type === 4
      )
      break;
    case 5:
      selectedPlanQrPrice = subscriptionPlan.qr_credit_prices.find(
        qrcp => qrcp.registration_type === 5
      )
      break;
    default:
      break;
  }
  if (selectedPlanQrPrice) {
    basePrice = Number(selectedPlanQrPrice.base_price).toFixed(3)
  }
  return (
    <Box className={classes.changePlanDialogPlanTitleRoot}>
      <img
        className={classes.changePlanDialogPlanTitleImage}
        src={image}
        alt={subscriptionPlan.title}
      />
      <Box className={classes.changePlanDialogPlanTitleTextBox}>
        <Typography className={classes.changePlanDialogPlanTitleTextBoxTitle}>
          {subscriptionPlan.title}
        </Typography>
        <Typography>
          <Typography component={"span"} className={classes.changePlanDialogPlanTitleTextBoxDescription}>
            ${basePrice}{getLang(lang, 'label.PER_QR_CODE')}
          </Typography>
        </Typography>
      </Box>
    </Box>
  )
}

function ChangePlanDialogPlanSubscriptionType({
  userSubscriptionPlan,
  subscriptionType,
  subscriptionPlan,
  onChangeSubscriptionTypeClick,
  lang,
  disabled,
}) {
  const classes = useStyle();
  return (
    <Box className={classes.changePlanDialogSelectPlanSubscriptionTypeRoot} >
      <Typography className={classes.changePlanDialogSelectPlanSubscriptionTypeLabel} >
        {getLang(lang, 'label.SELECT_SUBSCRIPTION_TYPE')}
      </Typography>
      <Box className={classes.changePlanDialogSelectPlanSubscriptionTypeSelectionsBox} >
        <Box
          className={
            subscriptionType === "month"
              ?
              classes.changePlanDialogSelectPlanSubscriptionTypeSelectionBoxActive
              :
              classes.changePlanDialogSelectPlanSubscriptionTypeSelectionBox
          }
          onClick={() => disabled || (userSubscriptionPlan.interval === 'month' && userSubscriptionPlan.productName === subscriptionPlan.name) ? null : onChangeSubscriptionTypeClick("month")}
        >
          <AntSwitch

            disabled={disabled || (userSubscriptionPlan.interval === 'month' && userSubscriptionPlan.productName === subscriptionPlan.name)}
            checked={subscriptionType === "month"}
          />
          <Typography className={classes.changePlanDialogSelectPlanSubscriptionTypeSelectionBoxPrice}>
            ${subscriptionPlan.prices.find(price => price.interval === 'month').cost}
          </Typography>
          <Typography className={classes.changePlanDialogSelectPlanSubscriptionTypeSelectionBoxUnit}>
            /{getLang(lang, 'label.MONTH')}
          </Typography>
        </Box>
        <Typography className={classes.changePlanDialogSelectPlanSubscriptionTypeOr}>
          {getLang(lang, 'label.OR')}
        </Typography>
        <Box
          className={
            subscriptionType === "year"
              ?
              classes.changePlanDialogSelectPlanSubscriptionTypeSelectionBoxActive
              :
              classes.changePlanDialogSelectPlanSubscriptionTypeSelectionBox
          }
          onClick={() => disabled || (userSubscriptionPlan.interval === 'year' && userSubscriptionPlan.productName === subscriptionPlan.name) ? null : onChangeSubscriptionTypeClick("year")}
        >
          <AntSwitch
            disabled={userSubscriptionPlan.interval === 'year' && userSubscriptionPlan.productName === subscriptionPlan.name}
            checked={subscriptionType === "year"}
          />
          <Typography className={classes.changePlanDialogSelectPlanSubscriptionTypeSelectionBoxPrice}>
            ${subscriptionPlan.prices.find(price => price.interval === 'year').cost}
          </Typography>
          <Typography className={classes.changePlanDialogSelectPlanSubscriptionTypeSelectionBoxUnit}>
            /{getLang(lang, 'label.YEAR')}
          </Typography>
          <Typography className={classes.changePlanDialogSelectPlanSubscriptionTypeSelectionBoxBorderLabel}>
            ${(subscriptionPlan.prices.find(price => price.interval === 'year').cost / 12).toFixed(2)}{getLang(lang, 'label.MONTH')}
          </Typography>
        </Box>
      </Box>
    </Box>

  )
}

function ChangePlanDialogPlanAccessibility({
  subscriptionType,
  subscriptionPlan,
  lang
}) {
  const classes = useStyle()
  const indexToSplit = Math.ceil(subscriptionPlan.metadata.length / 2)
  const gridOneSubscriptionPlanMetadata = subscriptionPlan.metadata.slice(0, indexToSplit);
  const gridTwoSubscriptionPlanMetadata = subscriptionPlan.metadata.slice(indexToSplit);

  return (
    <Box className={classes.changePlanDialogPlanAccessibilitiesRoot}>
      <Typography className={classes.changePlanDialogPlanAccessibilitiesTitle}>
        {getLang(lang, 'label.FEATURES_ACCESSIBILITIES')}
      </Typography>
      <Grid container>
        <Grid item lg={6} md={6} xs={12}>
          {
            gridOneSubscriptionPlanMetadata.map(
              (md, j) => {
                return (
                  <Box key={j}>
                    <Typography
                      className={md.is_valid ? classes.changePlanDialogPlanAccessibilitiesMetadataTitleActive : classes.changePlanDialogPlanAccessibilitiesMetadataTitle} >
                      {getLang(lang, 'db_label.' + md.group)}
                    </Typography>
                    <ul className={classes.changePlanDialogPlanAccessibilitiesUl}>
                      {
                        md.data.map(
                          (d, k) => {
                            return (
                              <li key={k}>
                                <Typography
                                  className={
                                    d.parts.length > 0
                                      ?
                                      d.is_valid ? classes.changePlanDialogPlanAccessibilitiesDataTitleActive : classes.changePlanDialogPlanAccessibilitiesDataTitle
                                      :
                                      d.is_valid ? classes.changePlanDialogPlanAccessibilitiesMetadataTextActive : classes.changePlanDialogPlanAccessibilitiesMetadataText
                                  }
                                >
                                  {getLang(lang, 'db_label.' + d.module)}
                                </Typography>
                                {
                                  d.parts.length > 0
                                    ?
                                    (
                                      <ul className={classes.changePlanDialogPlanAccessibilitiesUl}>
                                        {
                                          d.parts.map(
                                            (p, l) => {
                                              return (
                                                <li key={l}>
                                                  <Typography
                                                    className={p.is_valid ? classes.changePlanDialogPlanAccessibilitiesDataTextActive : classes.changePlanDialogPlanAccessibilitiesDataText}
                                                  >
                                                    {getLang(lang, 'db_label.' + p.module)}
                                                  </Typography>
                                                </li>
                                              )
                                            }
                                          )
                                        }
                                      </ul>
                                    )
                                    :
                                    (
                                      <>
                                      </>
                                    )
                                }
                              </li>
                            )
                          }
                        )
                      }
                    </ul>
                  </Box>
                )

              }
            )
          }
        </Grid>
        <Grid item lg={6} md={6} xs={12}>
          {
            gridTwoSubscriptionPlanMetadata.map(
              (md, j) => {
                return (
                  <Box key={j}>
                    <Typography
                      className={md.is_valid ? classes.changePlanDialogPlanAccessibilitiesMetadataTitleActive : classes.changePlanDialogPlanAccessibilitiesMetadataTitle} >
                      {getLang(lang, 'db_label.' + md.group)}
                    </Typography>
                    <ul className={classes.changePlanDialogPlanAccessibilitiesUl}>
                      {
                        md.data.map(
                          (d, k) => {
                            return (
                              <li key={k}>
                                <Typography
                                  className={
                                    d.parts.length > 0
                                      ?
                                      d.is_valid ? classes.changePlanDialogPlanAccessibilitiesDataTitleActive : classes.changePlanDialogPlanAccessibilitiesDataTitle
                                      :
                                      d.is_valid ? classes.changePlanDialogPlanAccessibilitiesMetadataTextActive : classes.changePlanDialogPlanAccessibilitiesMetadataText
                                  }
                                >
                                  {getLang(lang, 'db_label.' + d.module)}
                                </Typography>
                                {
                                  d.parts.length > 0
                                    ?
                                    (
                                      <ul className={classes.changePlanDialogPlanAccessibilitiesUl}>
                                        {
                                          d.parts.map(
                                            (p, l) => {
                                              return (
                                                <li key={l}>
                                                  <Typography
                                                    className={p.is_valid ? classes.changePlanDialogPlanAccessibilitiesDataTextActive : classes.changePlanDialogPlanAccessibilitiesDataText}
                                                  >
                                                    {getLang(lang, 'db_label.' + p.module)}
                                                  </Typography>
                                                </li>
                                              )
                                            }
                                          )
                                        }
                                      </ul>
                                    )
                                    :
                                    (
                                      <>
                                      </>
                                    )
                                }
                              </li>
                            )
                          }
                        )
                      }
                    </ul>
                  </Box>
                )

              }
            )
          }
        </Grid>
      </Grid>
    </Box>
  )
}

function ChangePlanDialogPlanCharges({
  isLoadingChangeSubscriptionPlanCharges,
  changeSubscriptionPlanCharges,
  onProceedChangePlanClick,
  lang
}) {
  const classes = useStyle()
  if (isLoadingChangeSubscriptionPlanCharges) {
    return (
      <Box className={classes.changePlanDialogPlanChargesRoot}>
        <LoadingComponent />
      </Box>
    )
  }
  return (
    <Box className={classes.changePlanDialogPlanChargesRoot}>
      <Typography className={classes.changePlanDialogPlanChargesTitle}>
        {getLang(lang, 'label.CHARGES')}
      </Typography>
      <Box className={classes.changePlanDialogPlanChargesDetails}>
        <Box className={classes.changePlanDialogPlanChargesDetailsItem}>
          <Typography className={classes.changePlanDialogPlanChargesDetailsItemLabel}>
            {getLang(lang, 'label.NEW_PLAN_CHARGES')}
          </Typography>
          <Typography className={classes.changePlanDialogPlanChargesDetailsItemPrice}>
            {changeSubscriptionPlanCharges.new_plan_rate.toLocaleString('en-US', { style: 'currency', currency: 'USD', })}
          </Typography>
        </Box>
        <Box className={classes.changePlanDialogPlanChargesDetailsItem}>
          <Typography className={classes.changePlanDialogPlanChargesDetailsItemLabel}>
            {getLang(lang, 'label.CURRENT_PLAN_BALANCE')}
          </Typography>
          <Typography className={classes.changePlanDialogPlanChargesDetailsItemPrice}>
            {changeSubscriptionPlanCharges.proration_rate.toLocaleString('en-US', { style: 'currency', currency: 'USD', })}
          </Typography>
        </Box>
        <Box className={classes.changePlanDialogPlanChargesDetailsItem}>
          <Typography className={classes.changePlanDialogPlanChargesDetailsItemLabel}>
            {getLang(lang, 'label.PAYMENT')}
          </Typography>
          <Typography className={classes.changePlanDialogPlanChargesDetailsItemPrice}>
            {changeSubscriptionPlanCharges.total_pay.toLocaleString('en-US', { style: 'currency', currency: 'USD', })}
          </Typography>
        </Box>
        <Button
          variant="contained"
          color="secondary"
          className={classes.changePlanDialogPlanChargesDetailsItem}
          onClick={() => onProceedChangePlanClick()}
        >
          {getLang(lang, 'label.PROCEED')}
        </Button>
      </Box>
      <Box className={classes.changePlanDialogPlanChargesWarning}>
        <img
          className={classes.changePlanDialogPlanChargesWarningIcon}
          src={AlertSvg}
          alt={"alert"}
        />
        <Typography className={classes.changePlanDialogPlanChargesWarningLabel}>
          {getLang(lang, 'paragraph.PAYMENT_CHARGE_ON_NEXT_MONTH')}
        </Typography>
      </Box>
    </Box>
  )
}

export default function ChangePlanDialogComponent({
  isLoadingChangeSubscriptionPlanCharges,
  registrationType,
  subscriptionType,
  userSubscriptionPlan,
  subscriptionPlan,
  changeSubscriptionPlanCharges,
  onChangePlanCloseClick,
  onProceedChangePlanClick,
  onChangeSubscriptionTypeClick,
  lang
}) {
  const classes = useStyle();

  if (!subscriptionPlan) {
    return null;
  }

  let title = IsUpgradeDisableOrSwap({ userSubscriptionPlan: userSubscriptionPlan, subscriptionPlan: subscriptionPlan , lang}).toLocaleLowerCase() + ` ${getLang(lang, 'label.PLAN')}`
  title = title.charAt(0).toUpperCase() + title.slice(1);

  return (
    <Dialog open={subscriptionPlan} maxWidth={"xl"} onClose={() => onChangePlanCloseClick()}>
      <ChangePlanDialogTitleBar
        title={title}
        onChangePlanCloseClick={onChangePlanCloseClick}
      />
      <Divider className={classes.changePlanDialogDivider} />

      <ChangePlanDialogPlanTitle
        userSubscriptionPlan={userSubscriptionPlan}
        subscriptionPlan={subscriptionPlan}
        lang={lang}
        registrationType={registrationType}
        subscriptionType={subscriptionType}
      />
      <Divider className={classes.changePlanDialogDivider} />

      <ChangePlanDialogPlanSubscriptionType
        userSubscriptionPlan={userSubscriptionPlan}
        subscriptionType={subscriptionType}
        subscriptionPlan={subscriptionPlan}
        onChangeSubscriptionTypeClick={onChangeSubscriptionTypeClick}
        lang={lang}
        disabled={isLoadingChangeSubscriptionPlanCharges}
      />

      <Divider className={classes.changePlanDialogDivider} />

      <ChangePlanDialogPlanAccessibility
        subscriptionType={subscriptionType}
        subscriptionPlan={subscriptionPlan}
        lang={lang}
      />
      <Divider className={classes.changePlanDialogDivider} />

      <ChangePlanDialogPlanCharges
        isLoadingChangeSubscriptionPlanCharges={isLoadingChangeSubscriptionPlanCharges}
        changeSubscriptionPlanCharges={changeSubscriptionPlanCharges}
        onProceedChangePlanClick={onProceedChangePlanClick}
        lang={lang}
      />
    </Dialog>
  )
}
