import React from "react";
import DatePickerComponent from "./datePicker.component";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { getLang } from "app/feature/constants";

function DatePickerContainer({
  disabled,
  id,
  value,
  handleChange,
  format,
  label,
  disableFuture,
  error,
  helperText,
  minDate,
  maxDate,
  inputStyle,
  ...props
}) {
  const lang = useSelector(state => state.constant.languages)

  return (
    <DatePickerComponent
      id={id}
      value={value}
      handleChange={handleChange}
      disabled={disabled}
      format={format}
      okLabel={getLang(lang, "label.SELECT")}
      cancelLabel={getLang(lang, 'label.CANCEL')}
      label={label}
      disableFuture={disableFuture}
      error={error}
      helperText={helperText}
      minDate={minDate}
      maxDate={maxDate}
      inputStyle={inputStyle}
      {...props}
    />
  );
}

DatePickerContainer.defaultProps = {
  disabled: false,
  id: "",
  value: null,
  format: "DD-MM-yyyy",
  label: "Date",
  disableFuture: false,
  error: false,
  helperText: undefined
};

DatePickerContainer.propTypes = {
  id: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  handleChange: PropTypes.func,
  format: PropTypes.string,
  okLabel: PropTypes.string,
  label: PropTypes.string,
  helperText: PropTypes.object,
  disableFuture: PropTypes.bool,
  error: PropTypes.bool
};

export default DatePickerContainer;
