import React from "react";
import { makeStyles, Box, Typography, Grid, Button } from "@material-ui/core";
import FieldForm from "components/regField/fieldForm";
import { getLang } from "app/feature/constants";

const style = (theme) => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  textField: {
    color: "rgba(58, 77, 84, 0.33)",
    paddingLeft: 15,
  },
  textColor: {
    color: "rgba(58, 77, 84, 0.33)",
  },
  formContainer: {
    backgroundColor: theme.palette.primary.main,
    width: "250px",
    borderRadius: "4px",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
  iconBorder: {
    textAlign: "start",
    width: "100%",
    minWidth: "0",
    height: "100%",
    borderRadius: "4px",
    "&:hover": {
      backgroundColor: "#49616bb8",
    },
  },
  iconLastBorder: {
    textAlign: "start",
    width: "100%",
    minWidth: "0",
    height: "100%",
    borderRadius: "4px",
    "&:hover": {
      backgroundColor: "#49616bb8",
    },
    "&::before": {
      content: '""',
      position: "absolute",
      width: "0",
      height: "0",
      top: "80px",
      left: "30px",
      border: "8px solid",
      borderColor: "#3A4D54 transparent transparent transparent",
    },
  },
  iconButtonLabel: {
    display: "grid",
    gridTemplateColumns: "10fr 1fr",
    lineHeight: 0.5,
  },
  gridCenter: {
    textAlign: "center",
    display: "flex",
    alignItems: "center",
  },
  button: {
    textAlign: "center",
    padding: 0,
    minWidth: 0,
  },
  nameColumn: {
    paddingLeft: "8px",
    fontWeight: "bold",
  },
  gridEnd: {
    width: "100%",
    textAlign: "end",
  },
});

const useStyles = makeStyles(style);

export default function RegSettingComponent({
  children,
  warrantyRegisterForm,
  deliveryOrder,
  handlePreviewForm,
  type,
  formRef,
  handleDragEnd,
  lang,
}) {
  const classes = useStyles();
  const warrantyRegisterFormFields = [
    {
      labelType: getLang(lang, "label.SHORT_TEXT"),
      // description: "Name, Contact, Email, Seller Name",
      value: "textField",
      enableDelete: true,
      type: "textField",
      fieldType: "custom",
      mandatory: false,
      visible: false,
    },
    {
      labelType: getLang(lang, "label.CONTACT_NUMBER"),
      value: "contactNumber",
      enableDelete: true,
      type: "contactNumber",
      fieldType: "custom",
      mandatory: false,
      visible: false,
    },
    {
      labelType: getLang(lang, "label.PARAGRAPH"),
      // description: "Remark, Address",
      value: "multipleLine",
      enableDelete: true,
      type: "multipleLine",
      fieldType: "custom",
      mandatory: false,
      visible: false,
    },
    {
      labelType: getLang(lang, "label.FILE_UPLOAD"),
      value: "uploadFile",
      enableDelete: true,
      type: "uploadFile",
      fieldType: "custom",
      mandatory: false,
      visible: false,
    },
    {
      labelType: getLang(lang, "label.IMAGE_UPLOAD"),
      // description: "Product Photo, Proof of Purchase",
      value: "uploadImage",
      enableDelete: true,
      type: "uploadImage",
      fieldType: "custom",
      mandatory: false,
      visible: false,
    },
    {
      labelType: getLang(lang, "label.DATE_PICKER"),
      // description: "Date Related Field",
      value: "datePicker",
      enableDelete: true,
      type: "datePicker",
      fieldType: "custom",
      mandatory: false,
      visible: false,
    },
    {
      labelType: getLang(lang, "label.MULTIPLE_CHOICE"),
      value: "multipleChoice",
      enableDelete: true,
      type: "multipleChoice",
      fieldType: "custom",
      mandatory: false,
      visible: false,
    },
    {
      labelType: getLang(lang, "label.MULTIPLE_CHOICE_MULTI_SELECT"),
      value: "multipleChoiceMultiSelect",
      enableDelete: true,
      type: "multipleChoiceMultiSelect",
      fieldType: "custom",
      mandatory: false,
      visible: false,
    },
    {
      labelType: getLang(lang, "label.DROPDOWN_LIST"),
      value: "dropdownlist",
      enableDelete: true,
      type: "dropdownlist",
      fieldType: "custom",
      mandatory: false,
      visible: false,
    },
    {
      fieldName: "address",
      labelType: getLang(lang, "label.ADDRESS"),
      type: "address",
      mandatory: true,
      visible: true,
      enableDelete: true,
      editing: false,
      fieldType: "standard",
      variantName: "Address",
      choice: [],
    },
  ];
  return (
    <Box className={classes.root}>
      <Box pt={2} pl={3} pr={3}>
        <Grid container>
          <Grid
            item
            xs={6}
            sm={6}
            md={6}
            style={{ width: "100%", alignSelf: "center" }}
          >
            <Typography
              variant="body2"
              color="primary"
              style={{ fontWeight: "bold" }}
            >
              {getLang(lang, "label.WARRANTY_REGISTRATION_FORM")}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6} md={6} className={classes.gridEnd}>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              onClick={handlePreviewForm}
            >
              {getLang(lang, "label.PREVIEW")}
            </Button>
          </Grid>
        </Grid>
      </Box>
      <FieldForm
        children={children}
        type={type}
        form={warrantyRegisterForm}
        deliveryOrder={deliveryOrder}
        formRef={formRef}
        AddFieldChoice={warrantyRegisterFormFields}
        Reorder={handleDragEnd}
        height="52vh"
      />
    </Box>
  );
}
