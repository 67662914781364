import React from "react";
import { makeStyles, Box, Typography, Button } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { getLang } from "app/feature/constants";
import moment from "moment";
import { Check as CheckIcon } from "@material-ui/icons";
import RefreshTable from "components/table/refreshTable";
import "moment/min/locales.min";

const useStyle = makeStyles((theme) => ({
  container: {
    boxSizing: "border-box",
    maxHeight: "85vh",
    backgroundColor: "#FFFFFF",
    padding: "4px",
  },
  field: {
    display: "grid",
    gridTemplateColumns: "3fr 7fr",
    columnGap: theme.spacing(2),
  },
  activeText: {
    color: "#32D583",
  },
  activeChipStatus: {
    backgroundColor: "#ECFDF3",
    width: "fit-content",
    textTransform: "capitalize",
    color: "#027A48",
    fontWeight: "bold",
  },
  suspendChipStatus: {
    backgroundColor: "#fdecec",
    width: "fit-content",
    textTransform: "capitalize",
    color: "red",
    fontWeight: "bold",
  },

  item: {
    position: "relative",
    display: "flex",
    listStyle: "none",
  },
  seperator: {
    minWidth: 52,
    display: "flex",
    flexDirection: "column",
    flex: 0,
    alignItems: "center",
    padding: "0px 6px",
  },
  dot: {
    minWidth: 12,
    minHeight: 12,
    boxShadow: "none",
    backgroundColor: "#A749F0",
    color: "#ffffff",
    padding: 2,
    borderRadius: "50%",
    textAlign: "center",
    margin: "5px 0px",
  },
  connector: {
    width: 1,
    backgroundColor: "transparent",
    flexGrow: 1,
    minHeight: "25px",
    borderLeft: "2px dotted #D0D5DD",
  },
  flow: {
    paddingInlineStart: "0px",
  },
}));

export default function DeliveryInfoPanelComponent({
  list,
  isFetching,
  lang,
  isError,
  handleReload,
  showMore,
  setShowMore,
  language
}) {
  const classes = useStyle();

  if(language==="ZH")
  {
    moment.locale('zh-cn')
  }else if(language==="TL")
  {
    moment.locale('tl-ph')
  }else
  {
    moment.locale(language)
  }
  
  return (
    <Box width="100%">
      {isFetching ? (
        <Box>
          <Skeleton variant="text" height={30} />
          <Skeleton variant="text" height={30} />
          <Skeleton variant="text" height={30} />
          <Skeleton variant="text" height={30} />
          <Skeleton variant="text" height={30} />
        </Box>
      ) : isError ? (
        <Box>
          <RefreshTable handleReload={handleReload} />
        </Box>
      ) : (
        <>
          <ul className={classes.flow}>
            {(list.length > 5 && !showMore ? list.slice(0, 5) : list).map(
              (detail, index) => (
                <li className={classes.item} key={index}>
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    flexDirection="column"
                    alignSelf="flex-start"
                    textAlign="right"
                    mb={2}
                  >
                    <Typography
                      variant="body2"
                      style={{
                        color: index ? "#98A2B3" : "#475467",
                      }}
                    >
                      {moment(detail.date).format("ll")}
                    </Typography>
                    <Typography
                      variant="body2"
                      style={{
                        color: index ? "#98A2B3" : "#475467",
                      }}
                    >
                      {moment(detail.date).format("HH:mm")}
                    </Typography>
                  </Box>
                  <Box className={classes.seperator}>
                    <Box
                      className={classes.dot}
                      style={{
                        backgroundColor:
                          detail.status === "delivered" ? "#32D583" : "#D0D5DD",
                      }}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      {detail.status === "delivered" && (
                        <CheckIcon style={{ color: "white", fontSize: 15 }} />
                      )}
                    </Box>
                    {index !== list.length - 1 && (
                      <Box className={classes.connector} />
                    )}
                  </Box>
                  <Box mb={2.5} alignSelf="flex-start" flex={1}>
                    <Box display="flex" alignItems="center" mb={0.5}>
                      <Typography
                        variant="body1"
                        style={{
                          color:
                            detail.status === "delivered"
                              ? "#6AAF68"
                              : "#475467",
                        }}
                      >
                        <b>{getLang(lang, `db_label.${detail.status}`)}</b>
                      </Typography>
                    </Box>
                    {index === 0 && (
                      <Box mb={0.5}>
                        <Typography variant="body2">
                          {detail.description}
                        </Typography>
                        {detail.location && (
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            style={{ marginTop: 4 }}
                          >
                            {detail.location}
                          </Typography>
                        )}
                      </Box>
                    )}
                  </Box>
                </li>
              )
            )}
          </ul>
          {list.length > 5 && (
            <Box display="flex" justifyContent="center">
              <Button
                variant="text"
                color="secondary"
                onClick={() => setShowMore(!showMore)}
              >
                {getLang(
                  lang,
                  showMore ? "label.SHOW_LESS" : "label.SHOW_MORE"
                )}
              </Button>
            </Box>
          )}
        </>
      )}
    </Box>
  );
}
