import axios from "axios";

export function getReceiveTransferItemBoxList(parameters) {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `/distributor/admin/api/v1/receive-transfer/${parameters.id}/get-transfer-box-items`,
        {
          params: {
            length: parameters.length,
            start: parameters.start,
            search: parameters.search,
            type: parameters.type,
          },
        }
      )
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data));
  });
}
