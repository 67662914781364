import React, { useRef } from "react";
import {
  makeStyles,
  Typography,
  Paper,
  ClickAwayListener,
  Box,
  Button,
  Divider,
  MenuItem,
  InputAdornment,
  IconButton,
  Popover,
} from "@material-ui/core";
import { getLang } from "app/feature/constants";
import LoadingComponent from "components/loading";
import InfiniteScroll from "react-infinite-scroll-component";
import InputTextField from "components/input/inputTextField";
import { Search as SearchIcon } from "@material-ui/icons";
import clsx from "clsx";

const useStyle = makeStyles((theme) => ({
  paper: {
    width: 380,
    maxWidth: "80vw",
  },
  button: {
    padding: 0,
    minWidth: 0,
  },
  buttonAddBranch: {
    display: "flex",
    padding: 0,
    minWidth: 0,
  },
  greenLabel: {
    color: theme.palette.secondary.main,
  },
  list: {
    maxHeight: "25vh",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "5px",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "16px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#E4E7EC",
      borderRadius: "16px",
    },
  },
  selected: {
    backgroundColor: "#f1f1f1",
  },
}));

export default function AdditionalFieldTitleWithLangSelectPanelComponent({
  value,
  additionalFields,
  anchorEl,
  open,
  handleClose,
  isFetching,
  dropdown,
  handleSelectField,
  lang,
  isError,
  totalFiltered,
  hasNextPage,
  handleFetchMoreItems,
  handleReload,
  handleSearch,
  userLang,
}) {
  const classes = useStyle();
  const textRef = useRef();

  return (
    <Popover
      id={open ? "simple-popover" : undefined}
      open={open}
      anchorEl={anchorEl}
      onClose={(e) => {
        e.stopPropagation();
        handleClose();
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      elevation={2}
      PaperProps={{
        style: { maxWidth: "63vw", width: 400 },
      }}
    >
      <Paper>
        <ClickAwayListener onClickAway={handleClose}>
          <Box>
            {!isError && (
              <>
                <InputTextField
                  variant="filled"
                  size="small"
                  fullWidth
                  inputRef={textRef}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        style={{ paddingRight: 6 }}
                      >
                        <IconButton
                          onClick={() => handleSearch(textRef.current.value)}
                        >
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                    style: {
                      backgroundColor: "#fff",
                      paddingRight: 0,
                    },
                  }}
                  inputProps={{
                    onKeyDown: (e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        handleSearch(textRef.current.value);
                        return false;
                      }
                    },
                    style: {
                      padding: "8px",
                    },
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  placeholder={getLang(lang, "placeholder.SEARCH")}
                />
                <Divider />
              </>
            )}
            <Box>
              {isError ? (
                <Box
                  display="flex"
                  justifyContent="center"
                  flexDirection="column"
                  alignItems="center"
                  pt={2}
                  pb={3}
                >
                  <Typography
                    variant="body2"
                    color="primary"
                    style={{ fontWeight: "bold", paddingBottom: "8px" }}
                  >
                    {getLang(lang, "label.NO_DATA_RECEIVED")}
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{ color: "#9CA6A9", paddingBottom: "16px" }}
                  >
                    {getLang(lang, "label.SOMETHING_WENT_WRONG")}
                  </Typography>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleReload}
                    disableElevation
                  >
                    {getLang(lang, "label.REFRESH")}
                  </Button>
                </Box>
              ) : (
                <InfiniteScroll
                  height="100%"
                  className={classes.list}
                  dataLength={dropdown.length}
                  next={() => {
                    if (hasNextPage) handleFetchMoreItems();
                  }}
                  hasMore={totalFiltered > additionalFields.length}
                  loader={
                    hasNextPage ? <LoadingComponent height={100} /> : null
                  }
                  endMessage={
                    isFetching ? (
                      <LoadingComponent height={100} />
                    ) : !dropdown.length ? (
                      <Box p={2} style={{ textAlign: "center" }}>
                        <Typography variant="body1">
                          {getLang(lang, "message.info.NO_RECORD_EXIST")}
                        </Typography>
                      </Box>
                    ) : null
                  }
                >
                  {[...dropdown].map((info, index) => {
                    return (
                      <React.Fragment key={index}>
                        <MenuItem
                          style={{ padding: 0 }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleSelectField(info);
                          }}
                          className={clsx({
                            [classes.selected]: value?.id === info.id,
                          })}
                        >
                          <Box display="flex" alignItems="center" p={1}>
                            <Typography variant="inherit">
                              {info.title
                                ? info.title[userLang] ??
                                  info.title["EN"] ??
                                  Object.values(info.title)[0]
                                : ""}
                            </Typography>
                          </Box>
                        </MenuItem>
                      </React.Fragment>
                    );
                  })}
                </InfiniteScroll>
              )}
            </Box>
          </Box>
        </ClickAwayListener>
      </Paper>
    </Popover>
  );
}
