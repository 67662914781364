import React from "react";
import {
  makeStyles,
  Box,
  IconButton,
  Typography,
  Grid,
  Divider,
  ClickAwayListener,
  Checkbox,
  Grow,
  Popover,
  Button,
} from "@material-ui/core";
import InputTextField from "components/input/inputTextField";
import { getLang } from "app/feature/constants";
import {
  DisableDustBinSVG,
  DragabbleSVG,
  CheckedSVG,
  AddSVG,
  UncheckedSVG,
  DustBinSVG,
} from "modules/lucky-draw/utils/static";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Formik, Form, FieldArray } from "formik";
import clsx from "clsx";
import {
  RegistrationFormSchema,
  checkIfFieldAreError,
} from "../../../lib/yuphelper";
import { useDispatch } from "react-redux";
import { addAlert } from "modules/notification";

const style = (theme) => ({
  textField: {
    color: "rgba(58, 77, 84, 0.33)",
    paddingLeft: 15,
  },
  textColor: {
    color: "rgba(58, 77, 84, 0.33)",
  },
  formContainer: {
    backgroundColor: theme.palette.primary.main,
    width: "250px",
    borderRadius: "4px",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
  iconBorder: {
    textAlign: "start",
    width: "100%",
    minWidth: "0",
    height: "100%",
    borderRadius: "4px",
    "&:hover": {
      backgroundColor: "#49616bb8",
    },
  },
  iconLastBorder: {
    textAlign: "start",
    width: "100%",
    minWidth: "0",
    height: "100%",
    borderRadius: "4px",
    "&:hover": {
      backgroundColor: "#49616bb8",
    },
    "&::before": {
      content: '""',
      position: "absolute",
      width: "0",
      height: "0",
      top: "45px",
      left: "30px",
      border: "8px solid",
      borderColor: "#3A4D54 transparent transparent transparent",
    },
  },
  iconButtonLabel: {
    display: "grid",
    gridTemplateColumns: "10fr 1fr",
    lineHeight: 0.5,
  },
  gridCenter: {
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
  },
  button: {
    textAlign: "center",
    padding: 0,
    minWidth: 0,
  },
  nameColumn: {
    paddingLeft: "8px",
    fontWeight: "bold",
  },
  gridEnd: {
    width: "100%",
    textAlign: "end",
  },
  textMessage: {
    color: "rgba(58, 77, 84, 0.33)",
    paddingLeft: "5px",
  },
  componentWrapper: {
    transition: theme.transitions.create("background-color", {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  // draggedOver: {
  //   backgroundColor: lighten(theme.palette.secondary.light, 0.5)
  // },
  draggingBody: {
    zIndex: 999999,
    backgroundColor: "#FFF",
  },
  dragIconButton: {
    display: "flex",
    alignItems: "center",
    padding: "3px",
    paddingRight: "10px",
  },
});

const useStyles = makeStyles(style);

export default function FieldFormComponent({
  form,
  type,
  lang,
  open,
  setOpen,
  handleClose,
  anchorRef,
  deliveryOrder,
  handleSubmit,
  AddFieldChoice,
  formRef,
  children,
  handleDragEnd,
  Capitalize,
  height,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const getTranslateKey = (type) => {
    let typeString = type;
    switch (type) {
      case "textField":
        typeString = getLang(lang, "label.TEXT_FIELD");
        break;
      case "address":
        typeString = getLang(lang, "label.ADDRESS");
        break;
      case "multipleChoice":
        typeString = getLang(lang, "label.MULTIPLE_CHOICE");
        break;
      case "multipleChoiceMultiSelect":
        typeString = getLang(lang, "label.MULTIPLE_CHOICE_MULTI_SELECT");
        break;
      case "uploadFile":
        typeString = getLang(lang, "label.FILE_UPLOAD");
        break;
      case "uploadImage":
        typeString = getLang(lang, "label.IMAGE_UPLOAD");
        break;
      case "contactNumber":
        typeString = getLang(lang, "label.CONTACT_NUMBER");
        break;
      case "dropdownlist":
        typeString = getLang(lang, "label.DROPDOWN_LIST");
        break;
      case "datePicker":
        typeString = getLang(lang, "label.DATE_PICKER");
        break;
      case "multipleLine":
        typeString = getLang(lang, "label.PARAGRAPH");
        break;
      default:
        break;
    }

    return typeString;
  };

  return (
    <Box>
      <Box pt={2} marginRight="30px" marginLeft={"30px"}>
        <Grid container style={{ paddingRight: "30px" }}>
          <Grid
            item
            xs={type === "edit" || type === "create" ? 4 : 5}
            sm={type === "edit" || type === "create" ? 4 : 5}
            md={type === "edit" || type === "create" ? 4 : 5}
            style={{ width: "100%", display: "flex" }}
          >
            <Typography variant="caption" className={classes.textField}>
              {getLang(lang, "label.FIELD_NAME")}
            </Typography>
          </Grid>
          <Grid item xs={3} sm={3} md={3} className={classes.gridCenter}>
            <Typography variant="caption" className={classes.textColor}>
              {getLang(lang, "label.TYPE")}
            </Typography>
          </Grid>
          <Grid item xs={2} sm={2} md={2} className={classes.gridCenter}>
            <Typography variant="caption" className={classes.textColor}>
              {getLang(lang, "label.MANDATORY")}
            </Typography>
          </Grid>
          <Grid item xs={2} sm={2} md={2} className={classes.gridCenter}>
            <Typography variant="caption" className={classes.textColor}>
              {getLang(lang, "label.VISIBLE")}
            </Typography>
          </Grid>
          {type === "edit" || type === "create" ? (
            <Grid item xs={1} sm={1} md={1} className={classes.gridCenter}>
              <Typography variant="caption" className={classes.textColor}>
                {getLang(lang, "label.DELETE")}
              </Typography>
            </Grid>
          ) : null}
        </Grid>
      </Box>
      <Box
        height={height}
        overflow="auto"
        marginRight="30px"
        marginLeft={"30px"}
      >
        <Box pt={2}>
          <Formik
            innerRef={formRef}
            enableReinitialize
            initialValues={{
              form: form,
            }}
            validationSchema={RegistrationFormSchema({ lang })}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            {(formik, errors) => (
              <Form>
                <FieldArray
                  name="form"
                  render={(arrayHelpers) => (
                    <div>
                      <DragDropContext onDragEnd={handleDragEnd}>
                        <Droppable
                          droppableId="droppable-field"
                          type="droppable-field"
                        >
                          {(provided, snapshot) => (
                            <Box
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              className={clsx(classes.componentWrapper, {
                                [classes.draggedOver]: snapshot.isDraggingOver,
                              })}
                            >
                              {formik.values.form.map((field, index) => (
                                <Box key={index} textAlign={"center"}>
                                  {type === "edit" || type === "create" ? (
                                    <Draggable
                                      style={{ position: "fixed" }}
                                      draggableId={`form[${index}]`}
                                      key={index}
                                      index={index}
                                    >
                                      {(provided, snapshot) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          className={clsx({
                                            [classes.draggingBody]:
                                              snapshot.isDragging,
                                          })}
                                        >
                                          <Grid
                                            container
                                            style={{
                                              padding: "10px",
                                              placeItems: "center",
                                              paddingRight: "30px",
                                            }}
                                          >
                                            <Grid
                                              item
                                              xs={
                                                type === "edit" ||
                                                type === "create"
                                                  ? 4
                                                  : 5
                                              }
                                              sm={
                                                type === "edit" ||
                                                type === "create"
                                                  ? 4
                                                  : 5
                                              }
                                              md={
                                                type === "edit" ||
                                                type === "create"
                                                  ? 4
                                                  : 5
                                              }
                                              style={{
                                                width: "100%",
                                                display: "flex",
                                              }}
                                            >
                                              <span
                                                className={
                                                  classes.dragIconButton
                                                }
                                                {...provided.dragHandleProps}
                                              >
                                                <DragabbleSVG />
                                              </span>
                                              {field.fieldName !== "name" &&
                                              field.fieldName !==
                                                "contactNumber" &&
                                              field.fieldName !== "do_number" &&
                                              field.fieldName !== "address" ? (
                                                <>
                                                  <InputTextField
                                                    variant="outlined"
                                                    size="small"
                                                    inputProps={{
                                                      style: {
                                                        padding: "5px",
                                                      },
                                                    }}
                                                    name={`form[${index}].variantName`}
                                                    {...formik.getFieldProps(
                                                      `form[${index}].variantName`
                                                    )}
                                                    error={checkIfFieldAreError(
                                                      formik.touched.form,
                                                      formik.errors.form,
                                                      index
                                                    )}
                                                  ></InputTextField>
                                                </>
                                              ) : (
                                                <>
                                                  <Typography
                                                    variant="body2"
                                                    color="primary"
                                                    style={{ margin: "10px" }}
                                                  >
                                                    {field.fieldName === "name"
                                                      ? getLang(
                                                          lang,
                                                          "label.NAME"
                                                        )
                                                      : field.fieldName ===
                                                        "address"
                                                      ? getLang(
                                                          lang,
                                                          "label.ADDRESS"
                                                        )
                                                      : field.fieldName ===
                                                        "contactNumber"
                                                      ? getLang(
                                                          lang,
                                                          "label.CONTACT_NUMBER"
                                                        )
                                                      : field.fieldName ===
                                                        "do_number"
                                                      ? getLang(
                                                          lang,
                                                          "label.DO_NUMBER"
                                                        )
                                                      : field.variantName}
                                                  </Typography>
                                                </>
                                              )}
                                            </Grid>
                                            <Grid
                                              item
                                              xs={3}
                                              sm={3}
                                              md={3}
                                              className={classes.gridCenter}
                                            >
                                              <Box
                                                className={classes.gridCenter}
                                              >
                                                <Typography
                                                  variant="body2"
                                                  color="primary"
                                                >
                                                  {getTranslateKey(field.type)}
                                                </Typography>
                                              </Box>
                                            </Grid>
                                            <Grid
                                              item
                                              xs={2}
                                              sm={2}
                                              md={2}
                                              className={classes.gridCenter}
                                            >
                                              {field.fieldName !== "name" &&
                                              field.fieldName !==
                                                "contactNumber" ? (
                                                <FormControlLabel
                                                  control={
                                                    <Checkbox
                                                      checked={
                                                        formik.getFieldProps(
                                                          `form[${index}].mandatory`
                                                        ).value
                                                      }
                                                      onChange={(e) => {
                                                        if (
                                                          !formik.getFieldProps(
                                                            `form[${index}].mandatory`
                                                          ).value
                                                        ) {
                                                          formik.setFieldValue(
                                                            `form[${index}].visible`,
                                                            true
                                                          );
                                                        }
                                                        formik.setFieldValue(
                                                          `form[${index}].mandatory`,
                                                          !formik.getFieldProps(
                                                            `form[${index}].mandatory`
                                                          ).value
                                                        );
                                                      }}
                                                      style={{
                                                        marginLeft: "11px",
                                                        marginRight: "-16px",
                                                      }}
                                                    />
                                                  }
                                                  label=""
                                                />
                                              ) : field.fieldName !==
                                                "do_number" ? (
                                                <div>
                                                  {field.mandatory ? (
                                                    <CheckedSVG />
                                                  ) : (
                                                    <UncheckedSVG />
                                                  )}
                                                </div>
                                              ) : (
                                                <FormControlLabel
                                                  control={
                                                    <Checkbox
                                                      checked={
                                                        deliveryOrder
                                                          ? true
                                                          : formik.getFieldProps(
                                                              `form[${index}].mandatory`
                                                            ).value
                                                      }
                                                      onChange={(e) => {
                                                        if (
                                                          !formik.getFieldProps(
                                                            `form[${index}].mandatory`
                                                          ).value
                                                        ) {
                                                          formik.setFieldValue(
                                                            `form[${index}].visible`,
                                                            true
                                                          );
                                                        }
                                                        formik.setFieldValue(
                                                          `form[${index}].mandatory`,
                                                          !formik.getFieldProps(
                                                            `form[${index}].mandatory`
                                                          ).value
                                                        );
                                                      }}
                                                      style={{
                                                        marginLeft: "11px",
                                                        marginRight: "-16px",
                                                      }}
                                                    />
                                                  }
                                                  label=""
                                                />
                                              )}
                                            </Grid>
                                            <Grid
                                              item
                                              xs={2}
                                              sm={2}
                                              md={2}
                                              className={classes.gridCenter}
                                            >
                                              {field.fieldName !== "name" &&
                                              field.fieldName !==
                                                "contactNumber" ? (
                                                <FormControlLabel
                                                  control={
                                                    <Checkbox
                                                      className={
                                                        classes.checkboxGreen
                                                      }
                                                      name={`form[${index}].visible`}
                                                      checked={
                                                        formik.getFieldProps(
                                                          `form[${index}].visible`
                                                        ).value
                                                      }
                                                      onChange={(e) => {
                                                        if (
                                                          formik.getFieldProps(
                                                            `form[${index}].visible`
                                                          ).value
                                                        ) {
                                                          formik.setFieldValue(
                                                            `form[${index}].mandatory`,
                                                            false
                                                          );
                                                        }

                                                        formik.setFieldValue(
                                                          `form[${index}].visible`,
                                                          !formik.getFieldProps(
                                                            `form[${index}].visible`
                                                          ).value
                                                        );
                                                      }}
                                                      style={{
                                                        marginLeft: "11px",
                                                        marginRight: "-16px",
                                                      }}
                                                    />
                                                  }
                                                  label=""
                                                />
                                              ) : field.fieldName !==
                                                "do_number" ? (
                                                <div>
                                                  {field.mandatory ? (
                                                    <CheckedSVG />
                                                  ) : (
                                                    <UncheckedSVG />
                                                  )}
                                                </div>
                                              ) : (
                                                <FormControlLabel
                                                  control={
                                                    <Checkbox
                                                      name={`form[${index}].visible`}
                                                      checked={
                                                        deliveryOrder
                                                          ? true
                                                          : formik.getFieldProps(
                                                              `form[${index}].visible`
                                                            ).value
                                                      }
                                                      onChange={(e) => {
                                                        if (
                                                          formik.getFieldProps(
                                                            `form[${index}].visible`
                                                          ).value
                                                        ) {
                                                          formik.setFieldValue(
                                                            `form[${index}].mandatory`,
                                                            false
                                                          );
                                                        }

                                                        formik.setFieldValue(
                                                          `form[${index}].visible`,
                                                          !formik.getFieldProps(
                                                            `form[${index}].visible`
                                                          ).value
                                                        );
                                                      }}
                                                      style={{
                                                        marginLeft: "11px",
                                                        marginRight: "-16px",
                                                      }}
                                                    />
                                                  }
                                                  label=""
                                                />
                                              )}
                                            </Grid>
                                            <Grid
                                              item
                                              xs={1}
                                              sm={1}
                                              md={1}
                                              className={classes.gridCenter}
                                            >
                                              {field.enableDelete ? (
                                                <IconButton
                                                  onClick={() =>
                                                    arrayHelpers.remove(index)
                                                  }
                                                >
                                                  <DustBinSVG />
                                                </IconButton>
                                              ) : (
                                                <DisableDustBinSVG />
                                              )}
                                            </Grid>
                                            {field.type === "multipleChoice" ||
                                            field.type ===
                                              "multipleChoiceMultiSelect" ||
                                            field.type === "dropdownlist" ? (
                                              <>
                                                <FieldArray
                                                  name={`form[${index}].choice`}
                                                  render={(arrayHelpers1) => (
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                      }}
                                                    >
                                                      <div
                                                        style={{
                                                          marginLeft: "10px",
                                                          maxHeight: "220px",
                                                          overflowX: "hidden",
                                                          overflowY: "scroll",
                                                          width: "450px",
                                                          border:
                                                            "1px solid rgb(221,218,218)",
                                                          borderRadius: "4px",
                                                          marginTop: "1rem",
                                                          padding:
                                                            "8px 0px 8px 0px",
                                                        }}
                                                      >
                                                        {field.choice.map(
                                                          (
                                                            choicefield,
                                                            choiceindex
                                                          ) => (
                                                            <Grid
                                                              item
                                                              xs={12}
                                                              sm={12}
                                                              md={12}
                                                              style={{
                                                                width: "100%",
                                                                display: "flex",
                                                                margin:
                                                                  "8px 8px 8px 8px",
                                                              }}
                                                              key={choiceindex}
                                                            >
                                                              <InputTextField
                                                                variant="outlined"
                                                                inputProps={{
                                                                  style: {
                                                                    padding:
                                                                      "5px",
                                                                    width:
                                                                      "300px",
                                                                    display:
                                                                      "flex",
                                                                    flexDirection:
                                                                      "column",
                                                                    alignItems:
                                                                      "center",
                                                                    justifyContent:
                                                                      "center",
                                                                  },
                                                                }}
                                                                name={`form[${index}].choice[${choiceindex}]`}
                                                                {...formik.getFieldProps(
                                                                  `form[${index}].choice[${choiceindex}]`
                                                                )}
                                                              />
                                                              <IconButton
                                                                onClick={() =>
                                                                  arrayHelpers1.remove(
                                                                    choiceindex
                                                                  )
                                                                }
                                                              >
                                                                <DustBinSVG />
                                                              </IconButton>
                                                            </Grid>
                                                          )
                                                        )}
                                                      </div>
                                                      <div
                                                        style={{
                                                          textAlign: "left",
                                                          margin: "inherit",
                                                          marginTop: "16px",
                                                        }}
                                                      >
                                                        {field.type ===
                                                        "dropdownlist" ? (
                                                          <Button
                                                            type="button"
                                                            onClick={() => {
                                                              arrayHelpers1.push(
                                                                ""
                                                              );
                                                            }}
                                                            color="primary"
                                                            style={{
                                                              fontWeight:
                                                                "bold",
                                                              padding: 0,
                                                            }}
                                                          >
                                                            <Box
                                                              pr={0.5}
                                                              display="flex"
                                                            >
                                                              <AddSVG color="grey" />
                                                            </Box>
                                                            {getLang(
                                                              lang,
                                                              "label.ADD_CHOICE"
                                                            )}
                                                          </Button>
                                                        ) : (
                                                          <Button
                                                            type="button"
                                                            onClick={() => {
                                                              const choices =
                                                                formik.values
                                                                  .form[index]
                                                                  .choice;
                                                              if (
                                                                choices.length <
                                                                10
                                                              ) {
                                                                arrayHelpers1.push(
                                                                  ""
                                                                );
                                                              } else {
                                                                dispatch(
                                                                  addAlert({
                                                                    severity:
                                                                      "error",
                                                                    message:
                                                                      getLang(
                                                                        lang,
                                                                        "message.warning.MAXIMUM_10_CHOICES"
                                                                      ),
                                                                  })
                                                                );
                                                              }
                                                            }}
                                                            color="primary"
                                                            style={{
                                                              fontWeight:
                                                                "bold",
                                                              padding: 0,
                                                            }}
                                                          >
                                                            <Box
                                                              pr={0.5}
                                                              display="flex"
                                                            >
                                                              <AddSVG color="grey" />
                                                            </Box>
                                                            {getLang(
                                                              lang,
                                                              "label.ADD_CHOICE"
                                                            )}
                                                          </Button>
                                                        )}
                                                      </div>
                                                    </div>
                                                  )}
                                                />
                                              </>
                                            ) : null}
                                          </Grid>
                                        </div>
                                      )}
                                    </Draggable>
                                  ) : (
                                    <Draggable
                                      style={{ position: "fixed" }}
                                      isDragDisabled={type === "view"}
                                      draggableId={`field-${index}`}
                                      key={index}
                                      index={index}
                                    >
                                      {(provided, snapshot) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          className={clsx({
                                            [classes.draggingBody]:
                                              snapshot.isDragging,
                                          })}
                                        >
                                          <Grid
                                            container
                                            style={{
                                              padding: "10px",
                                              placeItems: "center",
                                              paddingRight: "30px",
                                            }}
                                          >
                                            <Grid
                                              item
                                              xs={5}
                                              sm={5}
                                              md={5}
                                              style={{
                                                width: "100%",
                                                display: "flex",
                                              }}
                                            >
                                              <Typography
                                                variant="body2"
                                                color="primary"
                                                className={classes.nameColumn}
                                              >
                                                {field.fieldName === "name"
                                                  ? getLang(lang, "label.NAME")
                                                  : field.fieldName ===
                                                    "address"
                                                  ? getLang(
                                                      lang,
                                                      "label.ADDRESS"
                                                    )
                                                  : field.fieldName ===
                                                    "contactNumber"
                                                  ? getLang(
                                                      lang,
                                                      "label.CONTACT_NUMBER"
                                                    )
                                                  : field.fieldName ===
                                                    "do_number"
                                                  ? getLang(
                                                      lang,
                                                      "label.DO_NUMBER"
                                                    )
                                                  : field.variantName}
                                              </Typography>
                                            </Grid>
                                            <Grid
                                              item
                                              xs={3}
                                              sm={3}
                                              md={3}
                                              className={classes.gridCenter}
                                            >
                                              <Box
                                                className={classes.gridCenter}
                                              >
                                                <Typography
                                                  variant="body2"
                                                  color="primary"
                                                >
                                                  {getTranslateKey(field.type)}
                                                </Typography>
                                              </Box>
                                            </Grid>
                                            <Grid
                                              item
                                              xs={2}
                                              sm={2}
                                              md={2}
                                              className={classes.gridCenter}
                                            >
                                              {field.mandatory === true ? (
                                                <Box
                                                  className={classes.gridCenter}
                                                >
                                                  <CheckedSVG />
                                                </Box>
                                              ) : (
                                                <UncheckedSVG />
                                              )}
                                            </Grid>
                                            <Grid
                                              item
                                              xs={2}
                                              sm={2}
                                              md={2}
                                              className={classes.gridCenter}
                                            >
                                              {field.visible === true ? (
                                                <Box
                                                  className={classes.gridCenter}
                                                >
                                                  <CheckedSVG />
                                                </Box>
                                              ) : (
                                                <UncheckedSVG />
                                              )}
                                            </Grid>
                                          </Grid>
                                        </div>
                                      )}
                                    </Draggable>
                                  )}
                                  <Divider />
                                </Box>
                              ))}
                              {type === "edit" || type === "create" ? (
                                <>
                                  <div>
                                    <Button
                                      variant="contained"
                                      ref={anchorRef}
                                      color="primary"
                                      style={{ margin: "10px" }}
                                      onClick={() => {
                                        setOpen(!open);
                                      }}
                                      disableElevation
                                    >
                                      <Box pr={0.5} display="flex">
                                        <AddSVG />
                                      </Box>
                                      {getLang(lang, "label.ADD_FIELD")}
                                    </Button>
                                  </div>
                                  <Popover
                                    open={open}
                                    role={undefined}
                                    anchorEl={anchorRef.current}
                                    disablePortal
                                    anchorOrigin={{
                                      vertical: "top",
                                      horizontal: "center",
                                    }}
                                    PaperProps={{
                                      style: { overflow: "hidden" },
                                    }}
                                    arrow={false}
                                    transformOrigin={{
                                      vertical: "bottom",
                                      horizontal: "center",
                                    }}
                                    style={{ marginLeft: "16px" }}
                                  >
                                    <Grow>
                                      <ClickAwayListener
                                        onClickAway={handleClose}
                                      >
                                        <Box className={classes.formContainer}>
                                          {AddFieldChoice.map(
                                            (field, indexadd) => (
                                              <Box key={indexadd}>
                                                <IconButton
                                                  classes={{
                                                    root:
                                                      indexadd ===
                                                      AddFieldChoice.length - 1
                                                        ? classes.iconLastBorder
                                                        : classes.iconBorder,
                                                    label:
                                                      classes.iconButtonLabel,
                                                  }}
                                                  onClick={() => {
                                                    arrayHelpers.push({
                                                      ...field,
                                                      fieldName: `custom_${indexadd}`,
                                                      choice: [""],
                                                    });
                                                    handleClose();
                                                  }}
                                                >
                                                  <Box pl={1}>
                                                    <Typography
                                                      variant="body2"
                                                      style={{ color: "#fff" }}
                                                    >
                                                      {field.labelType}
                                                    </Typography>
                                                  </Box>
                                                  <AddSVG />
                                                </IconButton>
                                                <Divider />
                                              </Box>
                                            )
                                          )}
                                        </Box>
                                      </ClickAwayListener>
                                    </Grow>
                                  </Popover>
                                </>
                              ) : null}
                              {provided.placeholder}
                            </Box>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </div>
                  )}
                />
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
      {children ? children() : null}
    </Box>
  );
}

FieldFormComponent.defaultProps = {
  height: "60vh",
};
