import React from "react"
import StockoutListTableComponent from "./stockoutListTable.component"

export default function StockoutListTableContainer({
  hasStockflowStockoutViewAccessRight,
  hasStockflowDealerViewAccessRight,
  type,
  rows,
  isFetching,
  selectedIds,
  isSelectAll,
  isError,
  handleReload,
  page,
  rowsPerPage,
  language,
  handleChangePage,
  handleChangeRowsPerPage,
  isSearchResult,
  totalFiltered,
  handleSelectStockout,
  handleSelectAvailable,
  handleSelectAll,
  handleDeselectAll,
  paginationOptions,
  tableFilter,
  changeOrder,
  lang,
  isDealerPage = false,
  displayReceiveOnly = false,
  handleViewDetail
}) {
  return (
    <StockoutListTableComponent
      hasStockflowStockoutViewAccessRight={hasStockflowStockoutViewAccessRight}
      hasStockflowDealerViewAccessRight={hasStockflowDealerViewAccessRight}
      type={type}
      rows={rows}
      isFetching={isFetching}
      isSelectAll={isSelectAll} 
      language={language}
      selectedIds={selectedIds}
      isError={isError}
      handleReload={handleReload}
      page={page}
      rowsPerPage={rowsPerPage}
      handleChangePage={handleChangePage}
      handleChangeRowPerPage={handleChangeRowsPerPage}
      isSearchResult={isSearchResult}
      totalFiltered={totalFiltered}
      handleSelectStockout={handleSelectStockout}
      handleSelectAvailable={handleSelectAvailable}
      handleSelectAll={handleSelectAll}
      handleDeselectAll={handleDeselectAll}
      paginationOptions={paginationOptions}
      tableFilter={tableFilter}
      changeOrder={changeOrder}
      lang={lang}
      isDealerPage={isDealerPage}
      displayReceiveOnly={displayReceiveOnly}
      handleViewDetail={handleViewDetail}
    />
  )
}