import React from "react";
import {
  makeStyles,
  Box,
  Button,
  IconButton,
  InputAdornment,
  Collapse,
  Grid,
  Typography
} from "@material-ui/core";
import { ChromePicker } from "react-color";
import ColorizeRoundedIcon from "@material-ui/icons/ColorizeRounded";
import InputTextField from "components/input/inputTextField";
import { getLang } from "app/feature/constants";

const style = theme => ({
  root: {
    display: "flex"
  },
  colorDot: {
    height: 30,
    width: 30,
    borderRadius: "50%"
  }
});

const useStyle = makeStyles(style);

function ColorDot({ bgColor }) {
  const classes = useStyle();
  return (
    <Box
      className={classes.colorDot}
      style={{ backgroundColor: bgColor }}
    ></Box>
  );
}

export default function SingleColorPickerPanelComponent({
  handleCancel,
  handleColorChange,
  handleSave,
  bufferColor,
  label,
  isOpen,
  handleToggle,
  disabled,
  lang
}) {
  return (
    <Box>
      <Box pl={3} pr={3} style={{ display: "flex" }}>
        <Grid
          container
          style={{
            alignItems: "center",
            marginBottom: "9px"
          }}
        >
          <Grid item xs={4} sm={4} md={4} style={{ width: "100%" }}>
            <Typography variant="body2" color="textSecondary">
              {label}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6} md={6} style={{ width: "100%" }}>
            <InputTextField
              type="text"
              readOnly
              size="small"
              value={bufferColor}
              fullWidth
              margin="dense"
              variant="filled"
              InputProps={{
                disableUnderline: true,
                disabled: disabled,
                style: {
                  padding: "5px",
                  backgroundColor: "#ECEFF0",
                  borderRadius: "4px"
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="picker-primary"
                      edge="end"
                      disabled={disabled}
                      onClick={handleToggle}
                      style={{ paddingLeft: "8px" }}
                    >
                      <ColorizeRoundedIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Grid>

          <Grid item xs={2} sm={2} md={2} style={{ width: "100%" }}>
            <Box display="flex" alignItems="center" justifyContent="center">
              <ColorDot bgColor={bufferColor} />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Collapse in={isOpen}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <ChromePicker color={bufferColor} onChange={handleColorChange} />

          <Box mt={2}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              disableElevation
              onClick={handleSave}
            >
              {getLang(lang, "label.SELECT_COLOR")}
            </Button>
            &ensp;
            <Button
              variant="outlined"
              color="primary"
              size="small"
              disableElevation
              onClick={handleCancel}
            >
              {getLang(lang, "label.RESET")}
            </Button>
          </Box>
        </Box>
      </Collapse>
    </Box>
  );
}
