import React from "react";
import RedirectUrlTextFieldComponent from "./redirectUrlTextField.component";
import { useDispatch, useSelector } from "react-redux";
import { updateBatchRedirectTo } from "modules/serial-number/redux";
import { selectLoading } from "modules/notification";

function RedirectUrlTextFieldContainer({
  batch,
  value,
  handleCancel,
  lang,
}) {
  const dispatch = useDispatch();
  const isSubmitting = useSelector((state) =>
    selectLoading(state, updateBatchRedirectTo.typePrefix)
  );

  const handleSubmit = (value) => {
    dispatch(
      updateBatchRedirectTo({ uuid: batch.uuid, redirect_to: value })
    ).then(() => {
      handleCancel();
    });
  };

  return (
    <RedirectUrlTextFieldComponent
      value={value}
      handleSubmit={handleSubmit}
      handleCancel={handleCancel}
      lang={lang}
      isSubmitting={isSubmitting}
    />
  );
}

export default RedirectUrlTextFieldContainer;
