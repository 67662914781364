import React from "react";
import { Box } from "@material-ui/core";
import { layoutWrapperStyle } from "modules/reporting/styles";

export default function WrapperComponent ({ children }){
    const classes = layoutWrapperStyle();
    return (
        <Box className={classes.card}>
            {children}
        </Box>
    )
}