import React, { useEffect, useState, useRef } from "react"
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import DashboardDealerInvitedPanelComponent from "./dashboardDealerInvitedPanel.component"
import { getDealerInvitedList, getDealerInvitedSummary } from "modules/stockflow/redux"
import { selectLoading } from "modules/notification"
import html2canvas from "html2canvas";
import downloadjs from 'downloadjs';
import { generatePaginationOptions, updateUrlQueryParam } from "lib/helper"
import { STOCKFLOW_DEALER_VIEW, BRANCH_VIEW } from "lib/constants/accessRights"

function DashboardDealerInvitedPanelContainer({ id, filter }) {
  const dispatch = useDispatch()
  const history = useHistory()
  const lang = useSelector(state => state.constant.languages)

  // access rights
  const hasStockflowDealerViewAccessRight = useSelector(state => state.profile.accessRights.includes(STOCKFLOW_DEALER_VIEW));
  const hasBranchViewAccessRight = useSelector(state => state.profile.accessRights.includes(BRANCH_VIEW));

  // summary chart
  const isSummaryLoading = useSelector(state => selectLoading(state, getDealerInvitedSummary.typePrefix))
  const summaryData = useSelector(state => state.stockflowDealer.dashboard.dealerInvited.chartData.data)

  // listing table
  const isListFetching = useSelector(state => selectLoading(state, getDealerInvitedList.typePrefix))
  const totalFiltered = useSelector(state => state.stockflowDealer.dashboard.dealerInvited.list.totalFiltered)
  const list = useSelector(state => state.stockflowDealer.dashboard.dealerInvited.list.list)
  const isError = useSelector(state => state.stockflowDealer.dashboard.dealerInvited.list.isError)
  const [paginationOptions, setPaginationOptions] = useState([]);
  const [isFilterUpdated, setIsFilterUpdated] = useState(false)
  const [tableFilter, setTableFilter] = useState({
    page: 0,
    rowsPerPage: 25,
    order: "desc",
    orderBy: "created_at",
  })

  const query = new URLSearchParams(window.location.search)
  const tableFilterInitialRef = useRef(true)

  const handleDownloadDealerInvitedSummaryCard = () => {
    let element = document.getElementById("dealer-invited");

    html2canvas(element).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      downloadjs(imgData, 'dealer-invited-report.png');
    });
  }

  useEffect(() => {
    const isFilter = query.has("is_filter")
    let filter = sessionStorage.getItem(`dealer_invited_filter_${id}`);

    let temp = {...tableFilter}
    if (isFilter && filter) {
      temp = JSON.parse(filter)
      setIsFilterUpdated(true)
    }

    if (isFilter) {
      updateUrlQueryParam(null, ["is_filter"])
    }
    sessionStorage.removeItem(`dealer_invited_filter_${id}`)

    setTableFilter(temp)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!tableFilterInitialRef.current) {
      getData()
    }
    tableFilterInitialRef.current = false
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableFilter, filter])

  useEffect(() => {
    setPaginationOptions(generatePaginationOptions(totalFiltered))
  }, [totalFiltered])

  const getData = () => {
    dispatch(getDealerInvitedList({
      start_date: filter.dateRange[0],
      end_date: filter.dateRange[1],
      dealer_uuid: id,
      start: tableFilter.rowsPerPage * tableFilter.page,
      length: tableFilter.rowsPerPage,
      order: tableFilter.order,
      order_by: tableFilter.orderBy
    }))
  }

  const handleChangePage = (event, newPage) => {
    let newData = {
      ...tableFilter,
      page: newPage,
    }
    setIsFilterUpdated(true)
    setTableFilter(newData)
  }

  const handleChangeRowsPerPage = event => {
    let newData = {
      ...tableFilter,
      page: 0,
      rowsPerPage: event.target.value,
    }
    setIsFilterUpdated(true)
    setTableFilter(newData)
  }

  const changeOrder = (order) => {
    let newData = {
      ...tableFilter,
      order,
    }
    setIsFilterUpdated(true)
    setTableFilter(newData)
  }

  const handleViewDetail = (url) => {
    if (isFilterUpdated) {
      updateUrlQueryParam({ is_filter: true })
      sessionStorage.setItem(`dealer_invited_filter_${id}`, JSON.stringify(tableFilter));
    }
    history.push(url)
  }

  return (
    <DashboardDealerInvitedPanelComponent
      hasStockflowDealerViewAccessRight={hasStockflowDealerViewAccessRight}
      hasBranchViewAccessRight={hasBranchViewAccessRight}
      summaryData={summaryData}
      isSummaryLoading={isSummaryLoading}
      lang={lang}
      handleDownloadDealerInvitedSummaryCard={handleDownloadDealerInvitedSummaryCard}
      list={list}
      isError={isError}
      isListFetching={isListFetching}
      page={tableFilter.page}
      rowsPerPage={tableFilter.rowsPerPage}
      totalFiltered={totalFiltered}
      changeOrder={changeOrder}
      paginationOptions={paginationOptions}
      tableFilter={tableFilter}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      handleReload={getData}
      handleViewDetail={handleViewDetail}
    />
  )
}

export default DashboardDealerInvitedPanelContainer
