import React, { useEffect } from "react";
import SnAvailabilityListTableComponent from "./snAvailabilityListTable.component";
import { useDispatch, useSelector } from "react-redux";
import {
  checkSerialNumberAvailability,
  resetSNAvailabilityList,
} from "modules/serial-number/redux";
import { selectLoading } from "modules/notification";

function SnAvailabilityListTableContainer({
  handleReload,
  format,
  activeType,
  formik,
}) {
  const dispatch = useDispatch();
  const snAvailabilityList = useSelector(
    (state) => state.serialNumber.snAvailabilityList.data
  );
  const isError = useSelector(
    (state) => state.serialNumber.snAvailabilityList.isError
  );
  const isLoading = useSelector((state) =>
    selectLoading(state, checkSerialNumberAvailability.typePrefix)
  );

  const lang = useSelector((state) => state.constant.languages);

  useEffect(() => {
    return () => {
      dispatch(resetSNAvailabilityList());
    };
  }, [dispatch]);

  useEffect(() => {
    if (snAvailabilityList.length) {
      let totalQty = snAvailabilityList.reduce((total, sn) => {
        if (sn.is_available) {
          total += sn.quantity;
        }
        return total;
      }, 0);
      formik.setFieldValue("quantity", totalQty);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [snAvailabilityList]);

  return (
    <SnAvailabilityListTableComponent
      isLoading={isLoading}
      error={isError}
      list={snAvailabilityList}
      handleReload={handleReload}
      lang={lang}
      format={format}
      activeType={activeType}
    />
  );
}

export default SnAvailabilityListTableContainer;
