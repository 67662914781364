import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useSelector } from "react-redux";

const PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;


export default function StripeWrapperContainer({ children }) {
  const lang = useSelector(state => state.profile.language);

  let stripePromise = loadStripe(PUBLISHABLE_KEY, {
    locale: lang
  });

  return <Elements stripe={stripePromise} key={lang}>{children}</Elements>;
}
