import React from "react";
import {
  Backdrop,
  Box,
  Paper,
  Divider,
  makeStyles,
  Typography,
  IconButton,
  Button
} from "@material-ui/core";
import Dialog from "components/dialog/customDialog";
import CloseIcon from "@material-ui/icons/Close";
import SetWinnerCard from "../../card/setWinnerCard";
import { UndoSVG } from "../../../utils/static";
import { progressStatus } from "lib/constants/luckyDrawProgress";
import { getLang } from "app/feature/constants";

const style = (theme) => ({
  infoContainer: {
    width: 650,
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    }
  },
  root: {
    height: "80%"
  },
  header: {
    padding: "4px 0px",
    paddingLeft: theme.spacing(3),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  publishButton: {
    color: theme.palette.common.white,
    marginLeft: theme.spacing(1)
  },
  discardButton: {
    color: theme.palette.error.main
  }
});

const useStyles = makeStyles(style);

export default function SetWinnerDialogComponent({
  isOpen,
  handleClose,
  prizeList,
  handleRandomPickWinnerForAllPrize,
  handleSaveWinner,
  winnerType,
  changeToUpdate,
  handleConfirmWinner,
  progress,
  lang
}) {
  const classes = useStyles();
  const viewType = winnerType === "view" ? true : false;

  return (
    <Dialog
      isOpen={isOpen}
      handleClose={handleClose}
      disableEnforceFocus={true}
      disableAutoFocus={true}
      disableEscapeKeyDown={true}
      disableBackdropClick={true}
      closeAfterTransition
      BackdropComponent={Backdrop}
    >
      <Paper elevation={0} className={classes.root}>
        <Box className={classes.infoContainer}>
          <Box className={classes.header}>
            <Typography
              variant="subtitle2"
              color="primary"
              style={{ fontWeight: "bold" }}
            >
              {getLang(lang,"label.SET_WINNER")}
            </Typography>
            <IconButton color="primary" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
          <Divider />

          <Box
            pt={2}
            pb={0}
            px={3}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              variant="body2"
              color="primary"
              style={{ fontWeight: "bold" }}
            >
              {getLang(lang,"label.MANAGE_WINNER")}
            </Typography>

            {!viewType ? (
              <Button
                variant="contained"
                color="primary"
                classes={{ root: classes.publishButton }}
                type="button"
                disableElevation
                onClick={handleRandomPickWinnerForAllPrize}
              >
                {getLang(lang,"label.RANDOM_PICK_WINNER")}
                <Box pl={2} display="flex">
                  <UndoSVG />
                </Box>
              </Button>
            ) : null}
          </Box>

          <Box height="55vh" overflow="auto">
            {prizeList.map((value, index) => (
              <SetWinnerCard
                key={index}
                prizeIndex={index}
                prizeDetail={value}
              />
            ))}
          </Box>

          {progress !== progressStatus.confirmWinner &&
          progress !== progressStatus.published ? (
            <Box mt="auto">
              <Divider />
              <Box
                display="flex"
                justifyContent="flex-end"
                px={2}
                pt={1}
                pb={1}
              >
                {viewType ? (
                  <Button
                    variant="contained"
                    color="primary"
                    classes={{ root: classes.publishButton }}
                    disableElevation
                    type="submit"
                    onClick={handleConfirmWinner}
                  >
                    {getLang(lang,"label.CONFIRM_WINNER")}
                  </Button>
                ) : null}
                <Button
                  variant="contained"
                  color="secondary"
                  classes={{ root: classes.publishButton }}
                  disableElevation
                  type="submit"
                  onClick={viewType ? changeToUpdate : handleSaveWinner}
                >
                  {viewType ? getLang(lang,"label.EDIT") : getLang(lang,"label.SAVE")}
                </Button>
              </Box>
            </Box>
          ) : null}
        </Box>
      </Paper>
    </Dialog>
  );
}
