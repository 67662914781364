import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Box } from "@material-ui/core";

const useStyles = makeStyles({
    content__wrapper : {
        paddingLeft: "10px",
        paddingRight: "10px"
    }
})

export default function ContentComponent({ children }) {
    const classes = useStyles();

    return (
        <Box
            className={classes.content__wrapper}
        >
            {children}
        </Box>
    )
}