import React, { useState } from "react";
import LuckyDrawPrizeDialogComponent from "./luckyDrawPrizeDialog.component";
import ConfirmationDialogComponent from "components/dialog/confirmation";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  setPrizeEdit,
  setPrizeFinishEdit,
  setPrizeList,
  setPrizeListDelete,
  createPrize,
  updatePrize
} from "modules/lucky-draw/redux";
import { hideBackdrop } from "modules/backdrop";
import { LUCKY_DRAW_EDIT } from "lib/constants/accessRights";
import { getLang } from "app/feature/constants";

function LuckyDrawPrizeDialogContainer({ type, handleClose, isOpen }) {
  const dispatch = useDispatch();
  const hasLuckyDrawEditAccessRight = useSelector(state => state.profile.accessRights.includes(LUCKY_DRAW_EDIT))
  const prizeList = useSelector((state) => state.luckyDraw.prizeListTable.data);
  const [confirmationDialogModal, setConfirmationDialogModal] = useState(false);
  const [AddingPrizeFlag, setAddingPrizeFlag] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const lang = useSelector(state => state.constant.languages)

  const closeConfirmationDialog = () => {
    setConfirmationDialogModal(false);
  };

  const handleProceed = async () => {
    setConfirmationDialogModal(false);
  };

  const AddPrize = () => {
    setAddingPrizeFlag(true);
  };

  const handleAddPrizeSubmit = async (values) => {
    const temp = [];
    const info = {
      prizeLabel: values.prizeLabel,
      prizeName: values.prizeName,
      description: values.description,
      quantity: values.quantity,
      prizeImage: values.prizeImage,
      drawMethod: values.drawMethod,
      editing: false,
      order: prizeList.length + 1,
      luckyDrawId: values.luckyDrawId,
      id: null,
    };

    await dispatch(createPrize(info))
      .then(unwrapResult)
      .then(async (response) => {
        info.id = response.id
        temp.push(info);
        await dispatch(setPrizeList(prizeList.concat(temp)));
      }).catch((error) => {
        dispatch(hideBackdrop());
      });

    await setAddingPrizeFlag(false);
  };

  const handleEnableEdit = async (index) => {
    await dispatch(setPrizeEdit(index));
    setAddingPrizeFlag(true);
    setIsEditing(true)
  };

  const handleNotEditing = () => {
    setIsEditing(false)
  };

  const handleEdit = async (values) => {
    const index = prizeList.findIndex((w) => w.editing);
    const info = {
      ...values,
      index,
    };
    setAddingPrizeFlag(false);
    await dispatch(updatePrize(info))
      .then(unwrapResult)
      .then(async (response) => {
        await dispatch(setPrizeFinishEdit(info));
      });
  };

  const handleCloseDialog = () => {
    setIsEditing(false)
    setAddingPrizeFlag(false);
    handleClose();
  }

  const handleNewDelete = () => {
    setAddingPrizeFlag(false);
  };

  const handleDeleteField = async () => {
    setConfirmationDialogModal(true);

    const index = prizeList.findIndex((w) => w.editing);
    await dispatch(setPrizeListDelete(index));
  };

  return (
    <>
      <LuckyDrawPrizeDialogComponent
        hasLuckyDrawEditAccessRight={hasLuckyDrawEditAccessRight}
        type={type}
        AddPrize={AddPrize}
        AddingPrizeFlag={AddingPrizeFlag}
        prizeList={prizeList}
        handleAddPrizeSubmit={handleAddPrizeSubmit}
        handleEnableEdit={handleEnableEdit}
        handleEdit={handleEdit}
        handleDeleteField={handleDeleteField}
        handleNewDelete={handleNewDelete}
        handleClose={handleCloseDialog}
        isOpen={isOpen}
        setAddingPrizeFlag={setAddingPrizeFlag}
        isEditing={isEditing}
        handleNotEditing={handleNotEditing}
        lang={lang}
      />
      <ConfirmationDialogComponent
        isOpen={confirmationDialogModal}
        handleClose={closeConfirmationDialog}
        handleProceed={handleProceed}
        type={"danger"}
        description={getLang(lang,"paragraph.DELETE_PRIZE_QUESTION")}
        title={getLang(lang,"label.DELETE_PRIZE")}
      />
    </>
  );
}

export default LuckyDrawPrizeDialogContainer;
