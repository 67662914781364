import { getApiLang } from "app/feature/constants";
import { setLoading, addAlert } from "modules/notification";
import {
  getReceiveTransferDeliveryInfo,
  getReceiveTransferDetail,
  getReceiveTransferItemBoxList,
  getReceiveTransferItemBoxProductList,
  getReceiveTransferItemProductList,
  getReceiveTransferItemProductSNList,
  getReceiveTransferList,
  getReceiveTransferReceiverDropdown,
  getReceiveTransferSenderDropdown,
} from "../action";

const getReceiveTransferListMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getReceiveTransferList.pending.type:
        dispatch(
          setLoading({ id: getReceiveTransferList.typePrefix, state: true })
        );
        break;
      case getReceiveTransferList.fulfilled.type:
        dispatch(
          setLoading({ id: getReceiveTransferList.typePrefix, state: false })
        );
        break;
      case getReceiveTransferList.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({ id: getReceiveTransferList.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_GET_TRANSFER_LIST"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const getReceiveTransferDetailMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getReceiveTransferDetail.pending.type:
        dispatch(
          setLoading({ id: getReceiveTransferDetail.typePrefix, state: true })
        );
        break;
      case getReceiveTransferDetail.fulfilled.type:
        dispatch(
          setLoading({ id: getReceiveTransferDetail.typePrefix, state: false })
        );
        break;
      case getReceiveTransferDetail.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({ id: getReceiveTransferDetail.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_GET_TRANSFER_DETAIL"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const getReceiveTransferSenderDropdownMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getReceiveTransferSenderDropdown.pending.type:
        dispatch(
          setLoading({
            id: getReceiveTransferSenderDropdown.typePrefix,
            state: true,
          })
        );
        break;
      case getReceiveTransferSenderDropdown.fulfilled.type:
        dispatch(
          setLoading({
            id: getReceiveTransferSenderDropdown.typePrefix,
            state: false,
          })
        );
        break;
      case getReceiveTransferSenderDropdown.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: getReceiveTransferSenderDropdown.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

const getReceiveTransferReceiverDropdownMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getReceiveTransferReceiverDropdown.pending.type:
        dispatch(
          setLoading({
            id: getReceiveTransferReceiverDropdown.typePrefix,
            state: true,
          })
        );
        break;
      case getReceiveTransferReceiverDropdown.fulfilled.type:
        dispatch(
          setLoading({
            id: getReceiveTransferReceiverDropdown.typePrefix,
            state: false,
          })
        );
        break;
      case getReceiveTransferReceiverDropdown.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: getReceiveTransferReceiverDropdown.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

const getReceiveTransferItemBoxListMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getReceiveTransferItemBoxList.pending.type:
        dispatch(
          setLoading({
            id: getReceiveTransferItemBoxList.typePrefix,
            state: true,
          })
        );
        break;
      case getReceiveTransferItemBoxList.fulfilled.type:
        dispatch(
          setLoading({
            id: getReceiveTransferItemBoxList.typePrefix,
            state: false,
          })
        );
        break;
      case getReceiveTransferItemBoxList.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: getReceiveTransferItemBoxList.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_GET_TRANSFER_BOX_ITEMS"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const getReceiveTransferItemBoxProductListMiddleWare =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getReceiveTransferItemBoxProductList.pending.type:
        dispatch(
          setLoading({
            id: getReceiveTransferItemBoxProductList.typePrefix,
            state: true,
          })
        );
        break;
      case getReceiveTransferItemBoxProductList.fulfilled.type:
        dispatch(
          setLoading({
            id: getReceiveTransferItemBoxProductList.typePrefix,
            state: false,
          })
        );
        break;
      case getReceiveTransferItemBoxProductList.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: getReceiveTransferItemBoxProductList.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_INVALID_BOX_ID"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const getReceiveTransferItemProductListMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getReceiveTransferItemProductList.pending.type:
        dispatch(
          setLoading({
            id: getReceiveTransferItemProductList.typePrefix,
            state: true,
          })
        );
        break;
      case getReceiveTransferItemProductList.fulfilled.type:
        dispatch(
          setLoading({
            id: getReceiveTransferItemProductList.typePrefix,
            state: false,
          })
        );
        break;
      case getReceiveTransferItemProductList.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: getReceiveTransferItemProductList.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_GET_TRANSFER_PRODUCTS"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const getReceiveTransferItemProductSNListMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);

    const { type } = action;

    switch (type) {
      case getReceiveTransferItemProductSNList.pending.type:
        dispatch(
          setLoading({
            id: getReceiveTransferItemProductSNList.typePrefix,
            state: true,
          })
        );
        break;
      case getReceiveTransferItemProductSNList.fulfilled.type:
        dispatch(
          setLoading({
            id: getReceiveTransferItemProductSNList.typePrefix,
            state: false,
          })
        );
        break;
      case getReceiveTransferItemProductSNList.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: getReceiveTransferItemProductSNList.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              "error.ERROR_GET_TRANSFER_PRODUCT_SN_LIST"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const getReceiveTransferDeliveryInfoMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getReceiveTransferDeliveryInfo.pending.type:
        dispatch(
          setLoading({
            id: getReceiveTransferDeliveryInfo.typePrefix,
            state: true,
          })
        );
        break;
      case getReceiveTransferDeliveryInfo.fulfilled.type:
        dispatch(
          setLoading({
            id: getReceiveTransferDeliveryInfo.typePrefix,
            state: false,
          })
        );
        break;
      case getReceiveTransferDeliveryInfo.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: getReceiveTransferDeliveryInfo.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

export default [
  getReceiveTransferListMiddleware,
  getReceiveTransferDetailMiddleware,
  getReceiveTransferReceiverDropdownMiddleware,
  getReceiveTransferSenderDropdownMiddleware,
  getReceiveTransferItemBoxListMiddleware,
  getReceiveTransferItemProductListMiddleware,
  getReceiveTransferItemProductSNListMiddleware,
  getReceiveTransferItemBoxProductListMiddleWare,
  getReceiveTransferDeliveryInfoMiddleware,
];
