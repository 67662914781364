import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import OverallTableComponent from "./overallTable.component";
import { useHistory } from "react-router-dom";
import { fetchTransactionHistory } from "modules/billing/redux";
import { selectLoading } from "modules/notification";
import { generatePaginationOptions } from "lib/helper";
import { getLang } from "app/feature/constants";

function OverallTablePanelContainer({ selectedYear, module, lang,language }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [paginationOptions, setPaginationOptions] = useState([]);
  const id = history.location.pathname.substring(
    history.location.pathname.lastIndexOf("/") + 1
  );
  const [pageSetting] = useState({
    length: 25,
    start: 0,
    column: "created_at",
    order: "desc",
    search: "",
    year: selectedYear,
    type: module,
    userId: id
  });
  const isFetching = useSelector((state) =>
    selectLoading(state, fetchTransactionHistory.typePrefix)
  );
  const tableUI = [{ label: getLang(lang, "label.YEAR") + selectedYear }];

  const orderHistoryData = useSelector((state) => {
    switch (module) {
      case "sms":
        return state.billing.smsTransactionHistory.data;
      case "serial_number":
        return state.billing.qrTransactionHistory.data;
      default:
        return state.billing.transactionHistory.data;
    }
  });

  const totalRecords = useSelector((state) => {
    switch (module) {
      case "sms":
        return state.billing.smsTransactionHistory.filteredTotalRecords;
      case "serial_number":
        return state.billing.qrTransactionHistory.filteredTotalRecords;
      default:
        return state.billing.transactionHistory.filteredTotalRecords;
    }
  });
  
  useEffect(() => {
    dispatch(fetchTransactionHistory(pageSetting));
  }, [dispatch, pageSetting]);

  useEffect(() => {
    setPaginationOptions(generatePaginationOptions(totalRecords))
  }, [totalRecords])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(event.target.value);
  };

  return (
    <OverallTableComponent
      tableUI={tableUI}
      page={page}
      selectedYear={selectedYear}
      rowsPerPage={rowsPerPage}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      isFetching={isFetching}
      module={module}
      orderHistoryData={orderHistoryData}
      totalRecords={totalRecords}
      paginationOptions={paginationOptions}
      lang={lang}
      language={language}
    />
  );
}

export default OverallTablePanelContainer;
