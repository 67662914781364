import React from "react";
//import { GoogleButton, FacebookButton } from "../../components/button";
import {
  makeStyles,
  Link,
  Box,
  Divider,
  Paper
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { LoginLayout, CreditKoodFooter } from "../../components/layout";
import LoginForm from "../../components/form/loginForm";
import Header from "../../components/header";
// import Footer from "../../components/footer";
import { RESET_PASSWORD } from "../../config/public.route";
import { getLang } from "app/feature/constants";

const style = theme => ({
  root: {
    maxWidth: "900px",
    position: "absolute",

    boxShadow: "0px 10px 15px rgba(0, 0, 0, 0.1)",
    [theme.breakpoints.down("md")]: {
      alignSelf: "center",
      width: "45%"
    },
    [theme.breakpoints.down("sm")]: {
      alignSelf: "center",
      width: "60%",
      position: "sticky"
    },
    [theme.breakpoints.down("xs")]: {
      alignSelf: "center",
      width: "90%"
    }
  },
  form: {
    display: "flex",
    placeContent: "center",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  }
});

const useStyle = makeStyles(style);

export default function Login({ type, lang }) {
  const classes = useStyle();

  return (
    <LoginLayout>
      <Box className={classes.root}>
        <Paper className={classes.paper} elevation={0}>
          <Header />
          <Divider />
          <Paper className={classes.form} elevation={0}>
            <LoginForm />
            <Box textAlign="center" my={3}>
              <Link
                component={RouterLink}
                variant="subtitle2"
                color="secondary"
                to={RESET_PASSWORD}
              >
                {getLang(lang, "paragraph.FORGOT_PASSWORD_QUESTION")}
              </Link>
            </Box>
          </Paper>
          {
            /*
              For Prevent Packtica User Register From Here
              <Divider />
              <Box>
                <Footer type={type} />
              </Box>
            */
          }
        </Paper>
        <CreditKoodFooter />
      </Box>
    </LoginLayout>
  );
}
