import axios from "axios";

// Validate Reset Password Token 
export function validateResetPasswordToken({
  email,
  resetPasswordToken
}) {
  return new Promise((resolve, reject) => {
    axios.post("distributor/public/api/v1/auth/validate-reset-password-token", {
      email: email,
      reset_password_token: resetPasswordToken,
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data))
  })
}