/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useSelector } from "react-redux";
import ConsumerEngagementSettingPanelComponent from "./consumerEngagementSettingPanel.component";
import { selectLoading } from "modules/notification";
import { getCompanyDisbursement } from "modules/account/redux";

export default function ConsumerEngagementSettingPanelContainer() {
  const lang = useSelector((state) => state.constant.languages);
  const isFetching = useSelector((state) =>
    selectLoading(state, getCompanyDisbursement.typePrefix)
  );
  const disbursementInfos = useSelector(
    (state) => state.account.disbursementInfos
  );

  return (
    <ConsumerEngagementSettingPanelComponent
      lang={lang}
      disbursementInfos={disbursementInfos}
      isFetching={isFetching}
    />
  );
}
