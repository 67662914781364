import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
// import { selectLoading } from "modules/notification";
import LoadingComponent from "components/loading";
import { STOCKFLOW_STOCKTAKE_EDIT } from "lib/constants/accessRights";
import StockTakeCreateComponent from "./stockTakeCreate.component";
import { fetchMeAndBranchDropdownSpecial } from "modules/product-mgmt/redux/action/products.action";
import { createStockTake } from "modules/stockflow/redux";
import { selectLoading } from "modules/notification";
import { unwrapResult } from "@reduxjs/toolkit";
import { STOCK_TAKE_LIST_PAGE } from "modules/stockflow/config/stockflow.route";

function StockTakeCreateContainer() {
  const history = useHistory();
  const dispatch = useDispatch();

  const hasStockflowEditAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(STOCKFLOW_STOCKTAKE_EDIT)
  );
  const isSubmitting = useSelector((state) =>
    selectLoading(state, createStockTake.typePrefix)
  );
  const lang = useSelector((state) => state.constant.languages);
  const branches = useSelector((state) => state.products.branchDropdown);

  useEffect(() => {
    if (!hasStockflowEditAccessRight) {
      history.push("/admin/dashboard");
    }
  }, [history, hasStockflowEditAccessRight]);

  useEffect(() => {
    dispatch(fetchMeAndBranchDropdownSpecial({ search: "" }));
  }, [dispatch]);

  if (!hasStockflowEditAccessRight) return <LoadingComponent />;

  const handleSubmit = (values) => {
    dispatch(
      createStockTake({
        node_uuid: values.branch.node_id,
        name: values.name,
      })
    )
      .then(unwrapResult)
      .then((result) => {
        history.replace(`${STOCK_TAKE_LIST_PAGE}/${result.uuid}`);
      });
  };

  return (
    <StockTakeCreateComponent
      handleSubmit={handleSubmit}
      lang={lang}
      branches={branches}
      isSubmitting={isSubmitting}
    />
  );
}

export default StockTakeCreateContainer;
