import getAllConsumer from "./getAllConsumer.api"
import getConsumerScanListing from "./getConsumerScanListing.api"
import getConsumerProfile from "./getConsumerProfile.api"
import fetchAllScanTrack from "./fetchAllScanTrack.api"
import getConsumerGiftRedemptionListing from "./getConsumerGiftRedemptionListing.api"
import getGiftDropdown from "./getGiftDropdown.api"
import getConsumerLoyaltyPointHistory from "./getConsumerLoyaltyPointHistory.api"
import getCampaignProductDropdown from "./getCampaignProductDropdown.api"
import getConsumerCampaignListing from "./getConsumerCampaignListing.api"

const consumerApi = {
  getAllConsumer,
  getConsumerScanListing,
  getConsumerProfile,
  fetchAllScanTrack,
  getConsumerGiftRedemptionListing,
  getGiftDropdown,
  getConsumerLoyaltyPointHistory,
  getCampaignProductDropdown,
  getConsumerCampaignListing
}

export default consumerApi
