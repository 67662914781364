import React, { useEffect } from "react";
import QuantityAdjusterDialogComponent from "./quantityAdjusterDialog.component";
import {
  setPrizeFinishEdit,
  adjustQuantity,
  closeAdjustDialog,
} from "modules/instant-lucky-draw/redux";
import { useDispatch, useSelector } from "react-redux";
import { addAlert } from "modules/notification";
import { unwrapResult } from "@reduxjs/toolkit";
import { hideBackdrop, showBackdrop } from "modules/backdrop";
import giftCodeExcel from "app/api/instantLuckyDraw/legacy/giftCodeExcel.api";
import * as XLSX from "xlsx";
import { getUploadS3FilePath, isExcel } from "lib/helper";
import { UploadType } from "lib/constants/aws_s3";
import { uploadFileToS3 } from "app/feature";
import { fetchSerialNumberList, resetSerialNumberList, setWinnerIntoPrize } from "modules/instant-lucky-draw/redux";
import { getLang } from "app/feature/constants";

function QuantityAdjusterDialogContainer() {
  const dispatch = useDispatch();
  const isOpen = useSelector(
    (state) => state.instantLuckyDraw.adjustDialogSetting.isOpen
  );
  const prizeDetail = useSelector((state) =>
    state.instantLuckyDraw.prizeDialogInfo.prizeList.find((e) => e.editing)
  );
  const prizeIndex = useSelector((state) =>
    state.instantLuckyDraw.prizeDialogInfo.prizeList.findIndex((e) => e.editing)
  );
  const id = useSelector(
    (state) => state.instantLuckyDraw.InstantLuckyDrawDialogInfo.info.id
  );
  const userId = useSelector((state) => state.session.idTokenPayload.sub);
  const accessToken = useSelector((state) => state.session.accessToken);
  const lang = useSelector(state => state.constant.languages)

  const search = useSelector(
    (state) => state.instantLuckyDraw.searchForSerialNumber
  );

  const excludeList = useSelector(
    (state) => state.instantLuckyDraw.excludeWinnerList
  );

  useEffect(() => {
    if (isOpen) {
      const exclude = []
      excludeList.forEach((element, index) => exclude.push(element.enc))

      const fetchInfo = {
        id,
        length: 25,
        start: 0,
        search: search,
        status: "active",
        exclude: exclude
      };
      dispatch(fetchSerialNumberList(fetchInfo));
      return () => {
        dispatch(resetSerialNumberList());
      };
    }
  }, [dispatch, id, search, excludeList, isOpen]);

  const handleAddWinnerAddPrize = async (winnerIndex, serialNumber, enc, isNew) => {
    const info = {
      index: prizeIndex,
      winnerIndex: winnerIndex,
      serialNumber: serialNumber,
      enc: enc,
      isNew: isNew
    };
    await dispatch(setWinnerIntoPrize(info));
  };

  const getWorkbookFromFile = (excelFile) => {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.onload = (e) => {
        var data = e.target.result;
        let readedData = XLSX.read(data, { type: "binary" });
        const wsname = readedData.SheetNames[0];
        const ws = readedData.Sheets[wsname];

        /* Convert array to json*/
        const dataParse = XLSX.utils.sheet_to_json(ws, { header: 2, blankrows: false });
        resolve(dataParse);
      };
      reader.readAsBinaryString(excelFile);
    });
  };

  const handleDownloadTemplate = () => {
    dispatch(showBackdrop());
    giftCodeExcel.get({ id: id, token: accessToken }).finally(() => {
      dispatch(hideBackdrop());
      return;
    });
  };

  const handleSubmit = async (values) => {
    const adjustInfo = {
      id: prizeDetail.id,
      isPreset: !values.RandomWinner,
      quantity: values.AddQuantity
        ? parseFloat(values.Quantity)
        : parseFloat(values.Quantity) * -1,
      serialNumber: [],
      fileKey: undefined
    };


    dispatch(showBackdrop())

    if (!values.AddQuantity && parseFloat(values.Quantity) >= parseFloat(prizeDetail.balance)) {
      dispatch(
        addAlert({
          message: getLang(lang,"message.error.ERROR_QUANTITY_EXCEED_LIMIT"),
          severity: "error"
        })
      );
      dispatch(hideBackdrop());
      return;
    }


    if (prizeDetail.isGiftCode && values.AddQuantity) {
      if (values.adjustExcel === "") {
        dispatch(addAlert({ severity: "error", message: getLang(lang,"message.error.FORM_VALIDATE_REQUIRED_FILE") }))
        dispatch(hideBackdrop());
        return;
      }

      if (!isExcel(values.adjustExcel)) {
        dispatch(addAlert({ severity: "error", message: getLang(lang,"message.error.FORM_VALIDATE_EXCEL") }))
        dispatch(hideBackdrop());
        return;
      }

      let excelQuantity = await getWorkbookFromFile(values.adjustExcel);

      if (excelQuantity.length !== parseFloat(values.Quantity)) {
        dispatch(
          addAlert({
            severity: "error",
            message: getLang(lang,"message.error.ERROR_GIFTCODE_LENGTH_MISMATCH")
          })
        );
        dispatch(hideBackdrop());
        return;
      }

      const excelFileName = `${Date.now()}${values.adjustExcel.name}`;
      const uploadExcelInfo = {
        uploadType: UploadType.giftCodeExcel,
        file: values.adjustExcel,
        id: userId,
        fileName: excelFileName
      };

      await dispatch(uploadFileToS3(uploadExcelInfo))
        .then(unwrapResult)
        .then(() => {
          const filePath = getUploadS3FilePath({
            type: uploadExcelInfo.uploadType,
            id: userId,
            fileName: uploadExcelInfo.fileName
          });
          adjustInfo.fileKey = filePath;
        })
        .catch((error) => {
          dispatch(
            addAlert({
              severity: "error",
              message: error.message || getLang(lang,"message.error.ERROR_UPLOAD_GIFTCODE")
            })
          );
          dispatch(hideBackdrop());
          return;
        });
    }

    if (values.AddQuantity && !values.RandomWinner) {
      let winners = [];
      for (const winner of values.Winners) {
        if (!winner.serialNumber) {
          dispatch(
            addAlert({
              message: getLang(lang,"message.error.ERROR_WINNER_LIST"),
              severity: "error"
            })
          );
          return;
        }
        winners.push(winner.enc);
      }
      dispatch(showBackdrop());
      adjustInfo.serialNumber = winners;
      dispatch(adjustQuantity(adjustInfo))
        .then(unwrapResult)
        .then(() => {
          const updateInfo = {
            index: prizeIndex,
            ...prizeDetail,
            quantity: parseFloat(prizeDetail.quantity) + adjustInfo.quantity,
            balance: parseFloat(prizeDetail.balance) + adjustInfo.quantity
          };
          dispatch(setPrizeFinishEdit(updateInfo));
          dispatch(closeAdjustDialog());
        })
        .catch((error) => {
          dispatch(addAlert({ message: error, severity: "error" }));
        })
        .finally(() => {
          dispatch(hideBackdrop());
        });
    } else {
      dispatch(showBackdrop());
      dispatch(adjustQuantity(adjustInfo))
        .then(unwrapResult)
        .then(() => {
          const updateInfo = {
            index: prizeIndex,
            ...prizeDetail,
            quantity: parseFloat(prizeDetail.quantity) + adjustInfo.quantity,
            balance: parseFloat(prizeDetail.balance) + adjustInfo.quantity,
          };
          dispatch(setPrizeFinishEdit(updateInfo));
          dispatch(closeAdjustDialog());
        })
        .catch((error) => {
          dispatch(addAlert({ message: error, severity: "error" }));
        })
        .finally(() => {
          dispatch(hideBackdrop());
        });
    }
  };

  return (
    <QuantityAdjusterDialogComponent
      handleClose={() => dispatch(closeAdjustDialog())}
      isOpen={isOpen}
      prizeDetail={prizeDetail}
      handleSubmit={handleSubmit}
      handleDownloadTemplate={handleDownloadTemplate}
      handleAddWinnerAddPrize={handleAddWinnerAddPrize}
      lang={lang}
    />
  );
}

export default QuantityAdjusterDialogContainer;
