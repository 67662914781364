import { selectLoading } from 'modules/notification'
import React from 'react'
import { getSurvey } from 'modules/survey/redux/action'
import ViewSurveyDialogComponent from './viewSurveyDialog.component'
import { useSelector } from 'react-redux'

export default function ViewSurveyDialogContainer({
  hasSurveyEditAccessRight,
  isOpen,
  handleClose,
  handleEdit
}) {
  const isLoading = useSelector(state => selectLoading(state, getSurvey.typePrefix))
  const survey = useSelector(state => state.survey.survey)

  return (
    <ViewSurveyDialogComponent
      hasSurveyEditAccessRight={hasSurveyEditAccessRight}
      isOpen={isOpen}
      handleClose={handleClose}
      handleEdit={handleEdit}
      survey={survey}
      isLoading={isLoading}
    />
  )
}
