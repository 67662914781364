import React from "react";
import { ROOT } from "app/config/route";
import { Switch, Redirect } from "react-router-dom";
import { compose } from "@reduxjs/toolkit";
import { withCompanyTheme } from "./utils";
import {
  Login,
  ResetPassword,
  SentResetPassword,
  NotFound,
  VerifyInvitation,
  SignUp,
  SignUpVerification,
  AuthPage,
  EmailVerified,
  Logout,
  VerifyEmail,
  NewPassword,
  ResetPasswordSuccess,
  InvalidLink
} from "./pages";
import {
  LOGIN,
  LOGIN_AUTH,
  RESET_PASSWORD,
  SENT_RESET_PASSWORD,
  VERIFY_INVITATION,
  SIGN_UP,
  PARTIAL_FREE_SIGN_UP,
  FULL_FREE_SIGN_UP,
  RESELLER_PLAN_A_SIGN_UP,
  RESELLER_PLAN_B_SIGN_UP,
  PARTNER_PLAN_A_SIGN_UP,
  PARTNER_PLAN_B_SIGN_UP,

  SIGN_UP_VERIFICATION,
  LOGOUT,
  EMAIL_VERIFIED,
  VERIFY_EMAIL,
  INVALID_LINK,
  NEW_PASSWORD,
  RESET_PASSWORD_SUCCESS
} from "./config/public.route";
import { PrivateRoute, PublicRoute, PublicIgnoreSessionRoute } from './components/route';

function PublicModule() {
  return (
    <Switch>
      <Redirect exact from={ROOT} to={LOGIN} />
      <PublicRoute exact path={VERIFY_EMAIL} component={VerifyEmail} restricted={false} />
      <PublicRoute exact path={EMAIL_VERIFIED} component={EmailVerified} restricted={false} />
      <PublicRoute exact path={INVALID_LINK} component={InvalidLink} restricted={false} />
      <PublicRoute exact path={LOGIN_AUTH} component={AuthPage} />
      <PublicRoute exact path={LOGIN} component={Login} />
      <PublicRoute exact path={LOGOUT} component={Logout} restricted={false} />
      <PublicRoute exact path={RESET_PASSWORD} component={ResetPassword} />
      <PublicRoute exact path={SENT_RESET_PASSWORD} component={SentResetPassword} />
      <PublicIgnoreSessionRoute exact path={NEW_PASSWORD} component={NewPassword} />
      <PublicIgnoreSessionRoute exact path={RESET_PASSWORD_SUCCESS} component={ResetPasswordSuccess} />
      <PublicRoute exact path={VERIFY_INVITATION} component={VerifyInvitation} />
      <PublicRoute exact path={SIGN_UP} component={SignUp} />
      <PublicRoute exact path={PARTIAL_FREE_SIGN_UP} component={SignUp} />
      <PublicRoute exact path={FULL_FREE_SIGN_UP} component={SignUp} />
      <PublicRoute exact path={RESELLER_PLAN_A_SIGN_UP} component={SignUp} />
      <PublicRoute exact path={RESELLER_PLAN_B_SIGN_UP} component={SignUp} />
      <PublicRoute exact path={PARTNER_PLAN_A_SIGN_UP} component={SignUp} />
      <PublicRoute exact path={PARTNER_PLAN_B_SIGN_UP} component={SignUp} />
      
      <PrivateRoute exact path={SIGN_UP_VERIFICATION} component={SignUpVerification} />
      <PublicRoute path="*" component={NotFound} />
    </Switch>
  );
}

export default compose(withCompanyTheme(PublicModule));