import React from "react";
import StockTakeListTableComponent from "./stockTakeListTable.component";

export default function StockTakeListTableContainer({
  rows,
  isFetching,
  isError,
  handleReload,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
  isSearchResult,
  totalFiltered,
  paginationOptions,
  tableFilter,
  changeOrder,
  lang,
  handleViewDetail,
  url,
  handleCancel,
  hasStockflowEditAccessRight,
  language,
}) {
  return (
    <StockTakeListTableComponent
      rows={rows}
      isFetching={isFetching}
      isError={isError}
      handleReload={handleReload}
      page={page}
      language={language}
      rowsPerPage={rowsPerPage}
      handleChangePage={handleChangePage}
      handleChangeRowPerPage={handleChangeRowsPerPage}
      isSearchResult={isSearchResult}
      totalFiltered={totalFiltered}
      paginationOptions={paginationOptions}
      tableFilter={tableFilter}
      changeOrder={changeOrder}
      lang={lang}
      handleViewDetail={handleViewDetail}
      url={url}
      handleCancel={handleCancel}
      hasStockflowEditAccessRight={hasStockflowEditAccessRight}
    />
  );
}
