import React from "react";
import {
  makeStyles,
  Box,
  Typography,
  Grid,
  Divider,
  Button,
  Card,
} from "@material-ui/core";
import { getLang } from "../../../../../app/feature/constants";
import clsx from "clsx";
import moment from "moment";
import { Skeleton } from "@material-ui/lab";
import NoClaimSvg from "assets/svg/noClaim.svg";
import "moment/min/locales.min";
import DOMPurify from "dompurify";

const style = (theme) => ({
  root: {
    width: 1000,
    maxWidth: "100%",
    height: 700,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  maxHeight: {
    height: "100%",
  },
  questionContainer: {
    padding: 12,
    marginBottom: 12,
    cursor: "pointer",
  },
  selected: {
    border: "1px solid #6AAF68",
    borderRadius: 6,
    boxShadow: "0px 0px 0px 4px #E5F1E4",
    cursor: "pointer",
  },
});

const useStyles = makeStyles(style);

export default function WarrantyClaimPanelComponent({
  lang,
  isFetching,
  claims,
  count,
  language,
  currentSelected,
  handleItemClick,
  onApproveClick,
  onRejectClick,
  handleMediaClick,
  hasClaimListEditAccessRight,
  consumerWarrantyStatus,
  claimReasonOptions,
  profileLang,
}) {
  const classes = useStyles();

  if (language === "ZH") {
    moment.locale("zh-cn");
  } else if (language === "TL") {
    moment.locale("tl-ph");
  } else {
    moment.locale(language);
  }

  const generateLink = (remark) => {
    let urlRegex = /(https?:\/\/[^\s]+)/g;
    let textWithLink = remark.replace(urlRegex, (url) => {
      return '<a href="' + url + '" target="_blank">' + url + "</a>";
    });

    const sanitizedHtmlContent = DOMPurify.sanitize(textWithLink);

    return (
      <Box
        dangerouslySetInnerHTML={{
          __html: sanitizedHtmlContent,
        }}
      />
    );
  };

  if (count === 0) {
    return (
      <Box className={classes.root} p={3} display="flex" flexDirection="column">
        <Box
          display="flex"
          flexGrow={1}
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <img src={NoClaimSvg} alt="" />
          <Typography>
            <Box
              mt={1}
              fontSize="h5.fontSize"
              fontWeight="fontWeightBold"
              textAlign="center"
              pb={1}
            >
              {getLang(lang, "label.UNCLAIMED_WARRANTY")}
            </Box>
            <Box fontSize="h5.fontSize" color="primary.main" textAlign="center">
              {getLang(lang, "paragraph.UNCLAIMED_WARRANTY")}
            </Box>
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box className={classes.root}>
      <Grid container className={classes.maxHeight}>
        <Grid
          item
          xs={4}
          width="100%"
          style={{ height: "100%", overflowY: "auto" }}
        >
          <Box
            borderRight="0.5px solid #E5E5E5"
            p={2}
            width="100%"
            className={classes.maxHeight}
            overflowY="scroll"
          >
            <Typography>
              <Typography>
                <Box fontWeight="fontWeightBold">
                  {getLang(lang, "label.WARRANTY_CLAIM_PANEL_TITLE")}
                </Box>
              </Typography>
            </Typography>
            {isFetching ? (
              <>
                <Skeleton variant="text" animation="wave" height={100} />
                <Skeleton variant="text" animation="wave" height={100} />
                <Skeleton variant="text" animation="wave" height={100} />
                <Skeleton variant="text" animation="wave" height={100} />
              </>
            ) : (
              claims.map((item, index) => (
                <Box
                  className={clsx({
                    [classes.questionContainer]: true,
                    [classes.selected]: currentSelected === index,
                  })}
                  onClick={() => handleItemClick(index)}
                  p={1}
                  m={2}
                  mb={30}
                >
                  <Typography component="div">
                    <Box
                      display="flex"
                      flexDirection="row"
                      fontWeight="fontWeightLight"
                      alignItems="center"
                    >
                      <Box flexGrow={1}>
                        {moment(item.createdAt).format("lll")}
                      </Box>
                      {item.status === 1 && (
                        <Box
                          fontWeight="fontWeightBold"
                          bgcolor="warning.main"
                          color="#FFFFFF"
                          p={1}
                          py={0.5}
                          borderRadius={4}
                          height="min-content"
                        >
                          {getLang(lang, "label.PENDING")}
                        </Box>
                      )}
                      {item.status === 2 && (
                        <Box
                          fontWeight="fontWeightBold"
                          bgcolor="secondary.main"
                          color="#FFFFFF"
                          p={1}
                          py={0.5}
                          borderRadius={4}
                          height="min-content"
                        >
                          {getLang(lang, "label.APPROVED")}
                        </Box>
                      )}
                      {item.status === 3 && (
                        <Box
                          fontWeight="fontWeightBold"
                          bgcolor="error.main"
                          color="#FFFFFF"
                          p={1}
                          py={0.5}
                          borderRadius={4}
                          height="min-content"
                        >
                          {getLang(lang, "label.REJECTED")}
                        </Box>
                      )}
                    </Box>
                  </Typography>
                </Box>
              ))
            )}
          </Box>
        </Grid>
        <Grid item xs={8} width={"100%"} style={{ height: "100%" }}>
          {isFetching ? null : (
            <Box display="flex" flexDirection="column" height="100%">
              <Box style={{ overflowY: "auto" }} flexGrow={1} p={3}>
                <Box>
                  <Grid container>
                    <Grid item xs={4}>
                      {getLang(lang, "label.STATUS")}
                    </Grid>
                    <Grid item xs={8}>
                      {claims[currentSelected].status === 1 && (
                        <Box
                          fontWeight="fontWeightBold"
                          bgcolor="warning.main"
                          color="#FFFFFF"
                          p={1}
                          py={0.5}
                          borderRadius={4}
                          height="min-content"
                          width="min-content"
                        >
                          {getLang(lang, "label.PENDING")}
                        </Box>
                      )}
                      {claims[currentSelected].status === 2 && (
                        <Box
                          fontWeight="fontWeightBold"
                          bgcolor="secondary.main"
                          color="#FFFFFF"
                          p={1}
                          py={0.5}
                          borderRadius={4}
                          height="min-content"
                          width="min-content"
                        >
                          {getLang(lang, "label.APPROVED")}
                        </Box>
                      )}
                      {claims[currentSelected].status === 3 && (
                        <Box
                          fontWeight="fontWeightBold"
                          bgcolor="error.main"
                          color="#FFFFFF"
                          p={1}
                          py={0.5}
                          borderRadius={4}
                          height="min-content"
                          width="min-content"
                        >
                          {getLang(lang, "label.REJECTED")}
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </Box>
                {claims[currentSelected].referenceCode.length > 0 ? (
                  <Box mt={2}>
                    <Grid container mt={2}>
                      <Grid item xs={4}>
                        {getLang(lang, "label.REFERENCE_CODE")}
                      </Grid>
                      <Grid item xs={8}>
                        <Box fontWeight="fontWeightBold">
                          {claims[currentSelected].referenceCode}
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                ) : null}
                <Box mt={2}>
                  <Grid container mt={2}>
                    <Grid item xs={4}>
                      {getLang(lang, "label.SUBMISSION_DATE")}
                    </Grid>
                    <Grid item xs={8}>
                      <Box fontWeight="fontWeightBold">
                        {moment(claims[currentSelected].createdAt).format(
                          "lll"
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                {claims[currentSelected].metadata &&
                  claims[currentSelected].metadata.video_file &&
                  claims[currentSelected].metadata.image_file && (
                    <Box mt={2}>
                      <Grid container>
                        <Grid item xs={4}>
                          {getLang(lang, "label.UPLOADED_FILE")}
                        </Grid>
                        <Grid item xs={8}>
                          <Box
                            py={1}
                            mb={1}
                            display="flex"
                            flexWrap="no-wrap"
                            style={{ overflowX: "auto" }}
                          >
                            {claims[currentSelected].metadata.video_file
                              .length > 0 &&
                              claims[currentSelected].metadata.video_file.map(
                                (file) => (
                                  <Box mr={1}>
                                    <Card>
                                      <Box height="70px" width="70px">
                                        <video
                                          width="70"
                                          height="70"
                                          preload="metadata"
                                          muted
                                          onClick={() =>
                                            handleMediaClick(file, "video")
                                          }
                                        >
                                          <source src={file} />
                                        </video>
                                      </Box>
                                    </Card>
                                  </Box>
                                )
                              )}
                            {claims[currentSelected].metadata.image_file
                              .length > 0 &&
                              claims[currentSelected].metadata.image_file.map(
                                (file) => (
                                  <Box mr={1}>
                                    <Card>
                                      <Box height="70px" width="70px">
                                        <img
                                          height="70px"
                                          width="70px"
                                          style={{ objectFit: "contain" }}
                                          src={file}
                                          alt="img"
                                          onClick={() =>
                                            handleMediaClick(file, "image")
                                          }
                                        />
                                      </Box>
                                    </Card>
                                  </Box>
                                )
                              )}
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                {claims[currentSelected].claimReason &&
                  claims[currentSelected].claimReason.length > 0 &&
                  Object.keys(claimReasonOptions).length > 0 && (
                    <Box mt={2}>
                      <Grid container>
                        <Grid item xs={4}>
                          {getLang(lang, "label.CLAIM_REASONS")}
                        </Grid>
                        <Grid item xs={8}>
                          <Box fontWeight="fontWeightBold">
                            {claims[currentSelected].claimReason.map(
                              (item, index) => (
                                <Box>
                                  {index + 1}.{" "}
                                  {claimReasonOptions[item.id][profileLang] ||
                                    claimReasonOptions[item.id]["EN"]}
                                </Box>
                              )
                            )}
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                {claims[currentSelected].remark &&
                  claims[currentSelected].remark.length > 0 && (
                    <Box mt={2}>
                      <Grid container>
                        <Grid item xs={4}>
                          {getLang(lang, "label.REMARK")}
                        </Grid>
                        <Grid item xs={8}>
                          <Box fontWeight="fontWeightBold">
                            {generateLink(claims[currentSelected].remark)}
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                {claims[currentSelected].rejectedReason &&
                  claims[currentSelected].rejectedReason.length > 0 && (
                    <>
                      <Box p={2}>
                        <Divider />
                      </Box>
                      <Box>
                        <Grid container>
                          <Grid item xs={4}>
                            {getLang(lang, "label.CLAIM_REJECTED_REASON")}
                          </Grid>
                          <Grid item xs={8}>
                            <Box fontWeight="fontWeightBold">
                              {claims[currentSelected].rejectedReason}
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </>
                  )}
              </Box>
              {claims[currentSelected].status === 1 &&
                hasClaimListEditAccessRight &&
                consumerWarrantyStatus !== "voided" && (
                  <Box>
                    <Divider />
                    <Box
                      p={3}
                      py={2}
                      display="flex"
                      flexDirection="row"
                      justifyContent="flex-end"
                    >
                      <Button
                        variant="outline"
                        color="primarys"
                        onClick={onRejectClick}
                      >
                        Reject
                      </Button>
                      <Box mx={1} />
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={onApproveClick}
                      >
                        Approve
                      </Button>
                    </Box>
                  </Box>
                )}
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
