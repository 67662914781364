import React from "react";
import GiftDetailsDialogComponent from "./giftDetailsDialog.component";
import { useSelector } from 'react-redux'

export default function GiftDetailsDialogContainer({ isOpen, handleClose }) {
  const headerType = useSelector((state) => state.redemptionCenter.headerType);

  return (
    <GiftDetailsDialogComponent
      isOpen={isOpen}
      handleClose={handleClose}
      headerType={headerType}
    />
  );
}
