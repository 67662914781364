import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CheckoutItemListPanelComponent from "./checkoutItemListPanel.component";
import {
  getCheckoutBoxList,
  getCheckoutProductList,
  resetProductSnList,
} from "modules/stockflow/redux";
import { selectLoading } from "modules/notification";

export default function CheckoutItemListPanelContainer({ id }) {
  const lang = useSelector((state) => state.constant.languages);
  const dispatch = useDispatch();

  // box
  const isBoxFetching = useSelector((state) =>
    selectLoading(state, getCheckoutBoxList.typePrefix)
  );
  const boxTotalFiltered = useSelector(
    (state) => state.stockflowCheckout.boxList.totalFiltered
  );
  const boxList = useSelector((state) => state.stockflowCheckout.boxList.list);
  const isBoxError = useSelector(
    (state) => state.stockflowCheckout.boxList.isError
  );
  const boxHasNextPage = useSelector(
    (state) => state.stockflowCheckout.boxList.hasNextPage
  );

  // product
  const isProductFetching = useSelector((state) =>
    selectLoading(state, getCheckoutProductList.typePrefix)
  );
  const prdTotalFiltered = useSelector(
    (state) => state.stockflowCheckout.productList.totalFiltered
  );
  const prdList = useSelector(
    (state) => state.stockflowCheckout.productList.list
  );
  const isPrdError = useSelector(
    (state) => state.stockflowCheckout.productList.isError
  );
  const prdHasNextPage = useSelector(
    (state) => state.stockflowCheckout.productList.hasNextPage
  );

  const [activeIndex, setActiveIndex] = useState(0);
  const [selectedItem, setSelectedItem] = useState(null);
  const [filter, setFilter] = useState({
    prdPage: 0,
    boxPage: 0,
    rowsPerPage: 25,
  });

  const hasNextPage = activeIndex === 1 ? boxHasNextPage : prdHasNextPage;

  useEffect(() => {
    const isFetching = activeIndex === 1 ? isBoxFetching : isProductFetching;
    if (hasNextPage && !isFetching) {
      fetchData(filter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, activeIndex]);

  const fetchData = (filterInfo) => {
    if (activeIndex === 1) {
      dispatch(
        getCheckoutBoxList({
          id,
          length: filterInfo.rowsPerPage,
          start: filterInfo.rowsPerPage * filterInfo.boxPage,
        })
      );
    } else {
      dispatch(
        getCheckoutProductList({
          id,
          length: filterInfo.rowsPerPage,
          start: filterInfo.rowsPerPage * filterInfo.prdPage,
        })
      );
    }
  };

  const changeTab = (event, val) => {
    if (val !== activeIndex) {
      setActiveIndex(val);
      setSelectedItem(null);
      dispatch(resetProductSnList());
    }
  };

  const handleFetchMoreItems = () => {
    if (hasNextPage) {
      const newFilter = { ...filter };
      if (activeIndex === 1) {
        newFilter.boxPage += 1;
      } else {
        newFilter.prdPage += 1;
      }
      setFilter(newFilter);
    }
  };

  const handleReload = () => {
    const newFilter = { ...filter };
    if (activeIndex === 1) {
      newFilter.boxPage += 1;
    } else {
      newFilter.prdPage += 1;
    }
    setFilter(newFilter);
  };

  const selectItem = (item, type, boxUuid) => {
    setSelectedItem(
      item
        ? {
            ...item,
            type,
            box_uuid: boxUuid ?? null,
          }
        : null
    );
  };

  return (
    <>
      <CheckoutItemListPanelComponent
        lang={lang}
        isFetching={activeIndex === 1 ? isBoxFetching : isProductFetching}
        list={activeIndex === 1 ? boxList : prdList}
        totalFiltered={activeIndex === 1 ? boxTotalFiltered : prdTotalFiltered}
        isError={activeIndex === 1 ? isBoxError : isPrdError}
        changeTab={changeTab}
        activeIndex={activeIndex}
        selectItem={selectItem}
        selectedItem={selectedItem}
        handleFetchMoreItems={handleFetchMoreItems}
        hasNextPage={hasNextPage}
        handleReload={handleReload}
        id={id}
      />
    </>
  );
}
