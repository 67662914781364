import billingDetail from "./billingDetail.api";
import subscriptionPlan from "./subscriptionPlan.api";
import latestTransaction from "./latestTransaction.api";
import transactionHistory from "./transactionHistory.api";

const billingApi = {
  billingDetail,
  subscriptionPlan,
  latestTransaction,
  transactionHistory
};

export default billingApi;
