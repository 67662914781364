import { createSlice } from "@reduxjs/toolkit";
import { RESET_PASSWORD } from "../../constants";
import { checkResetPasswordResendTimeLeft } from "../action";

const initialState = {
  resetPasswordResendTimeLeft: 60,
};

const resetPasswordSlice = createSlice({
  name: RESET_PASSWORD,
  initialState,
  reducers: {
    setResetPasswordResendTimeLeft: (state, action) => {
      state.resetPasswordResendTimeLeft = action.payload
    }
  },
  extraReducers: {
    [checkResetPasswordResendTimeLeft.fulfilled]: (state, action) => {
      state.resetPasswordResendTimeLeft = action.payload.data.time_left
    },
  }
});

export const { 
  setResetPasswordResendTimeLeft,
} = resetPasswordSlice.actions;
export const resetPasswordReducer = resetPasswordSlice.reducer;
