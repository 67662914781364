import axios from "axios"

export default function signupEmployee({ id, token, username, password, confirm_password, timezone }) {
    return new Promise((resolve, reject) => {
        axios.post(`/account/public/api/v1/user/verification/${id}/register`,
            {
                token, username, password, confirm_password, timezone
            })
            .then(response => resolve(response.data))
            .catch(error => reject(error.response.data))
    })
}