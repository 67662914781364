import React, { useState, useEffect } from "react";
import UnassignSerialNumberComponent from "./unassignSerialNumber.component";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import {
  ROOT as SN_ROOT,
  SERIAL_NUMBER_TYPE_NUMBER,
} from "modules/serial-number";
import { useDispatch, useSelector } from "react-redux";
import { getUnassignedSerialNumberByBranch } from "modules/serial-number/redux";
import { selectLoading } from "modules/notification";
import { getLang } from "app/feature/constants";

function UnassignSerialNumberContainer({
  activeType,
  handleChange,
  selectedBranch,
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const serialNumbers = useSelector(
    (state) => state.serialNumber.unassignedSerialNumberList.data
  );
  const isError = useSelector(
    (state) => state.serialNumber.unassignedSerialNumberList.isError
  );
  const isLoading = useSelector((state) =>
    selectLoading(state, getUnassignedSerialNumberByBranch.typePrefix)
  );

  const lang = useSelector((state) => state.constant.languages);
  const [activeFormat, setActiveFormat] = useState("");
  const [filter, setFilter] = useState({
    prefix: "",
    length: 0,
    postfix: "",
  });

  const [prefixList, setPrefixList] = useState("");
  const [postfixList, setPostfixList] = useState("");
  const [lengthList, setLengthList] = useState("");
  const [filteredSN, setFilteredSN] = useState([]);

  useEffect(() => {
    setActiveFormat("");
    setFilter({
      prefix: "",
      length: 0,
      postfix: "",
    });
  }, [activeType]);

  useEffect(() => {
    if (selectedBranch) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBranch]);

  useEffect(() => {
    let list = serialNumbers.filter(
      (sn) => sn.format.split(",")[3] === activeType
    );
    setFilteredSN(list);

    let tempLengthList = [];
    let prefix = [];
    let postfix = [];
    let length = [];

    list.forEach((sn) => {
      const pattern = sn.format.split(",");

      if (!prefixList.includes(pattern[0])) {
        prefix.push(pattern[0]);
      }

      if (!postfixList.includes(pattern[2])) {
        postfix.push(pattern[2]);
      }

      if (!tempLengthList.includes(pattern[1])) {
        length.push({
          name: `[${pattern[1]} ${
            activeType === SERIAL_NUMBER_TYPE_NUMBER
              ? getLang(lang, "label.DIGITS")
              : getLang(lang, "label.CHARACTERS")
          }]`,
          value: pattern[1],
        });
        tempLengthList.push(pattern[1]);
      }
    });

    setPrefixList(prefix);
    setPostfixList(postfix);
    setLengthList(length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serialNumbers, activeType]);

  useEffect(() => {
    let list = serialNumbers.filter(
      (sn) => sn.format.split(",")[3] === activeType
    );

    list = list.filter((sn) => {
      let prefixMatch = true;
      let lengthMatch = true;
      let postfixMatch = true;
      const pattern = sn.format.split(",");
      if (filter.prefix) {
        prefixMatch = pattern[0] === filter.prefix;
      }
      if (filter.length) {
        lengthMatch = pattern[1] === filter.length;
      }
      if (filter.postfix) {
        postfixMatch = pattern[2] === filter.postfix;
      }

      return prefixMatch && lengthMatch && postfixMatch;
    });
    setFilteredSN(list);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const getData = () => {
    dispatch(getUnassignedSerialNumberByBranch(selectedBranch.uuid));
  };
  const handleGenerate = () => {
    history.push(`${SN_ROOT}/generate`);
  };

  return (
    <UnassignSerialNumberComponent
      isLoading={isLoading}
      error={isError}
      serialNumbers={serialNumbers}
      filteredSN={filteredSN}
      activeType={activeType}
      handleChange={handleChange}
      handleGenerate={handleGenerate}
      handleRetry={getData}
      lang={lang}
      activeFormat={activeFormat}
      setActiveFormat={setActiveFormat}
      lengthList={lengthList}
      prefixList={prefixList}
      postfixList={postfixList}
      filter={filter}
      setFilter={setFilter}
    />
  );
}

UnassignSerialNumberContainer.propTypes = {
  activeType: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default UnassignSerialNumberContainer;
