import axios from "axios";

export function getRedemptionList({
  length,
  start,
  order,
  search,
  id
}) {
  const url = `/distributor/admin/api/v1/rewards/${id}/redemptions/list`;
  return new Promise((resolve,reject) => {
    axios.get(url, {
      params: {
        length,
        start,
        search,
        order,
      }
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data))
  })
}