import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import paymentApi from "app/api/payment";

const PLAN = "plan";

const getPlan = createAsyncThunk(
  `${PLAN}/getPlan`,
  async (payload, { rejectWithValue }) => {
    return paymentApi.plans
      .get()
      .then(response => response)
      .catch(error => rejectWithValue(error));
  }
);

const initialState = {
  plans: {
    isLoaded: false,
    isLoading: true,
    error: null,
    data: []
  }
};

const planSlice = createSlice({
  name: PLAN,
  initialState,
  extraReducers: {
    [getPlan.pending]: (state, action) => {
      state.plans.isLoading = true;
    },
    [getPlan.fulfilled]: (state, action) => {
      state.plans.isLoading = false;
      state.plans.isLoaded = true;
      state.plans.data = action.payload;
    },
    [getPlan.rejected]: (state, action) => {
      state.plans.isLoading = false;
      state.plans.isLoaded = false;
      state.plans.data = [];
      state.plans.error = action.payload;
    }
  }
});

export { getPlan };
export default planSlice.reducer;
