import React from "react";
import {
  makeStyles,
  Typography,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  Box,
  Divider,
  MenuItem,
  MenuList,
} from "@material-ui/core";
import PerfectScrollbar from "react-perfect-scrollbar";

const useStyle = makeStyles((theme) => ({
  paper: {
    width: 385,
  },
  button: {
    padding: 0,
    minWidth: 0,
  },
  buttonAddWarranty: {
    display: "flex",
    padding: 0,
    minWidth: 0,
  },
  createLink: {
    padding: theme.spacing(2),
  },
}));

export default function PrizeTypePanelComponent({
  open,
  anchorRef,
  handleClose,
  handleSelect,
  isOpenList,
  prizeTypes,
}) {
  const classes = useStyle();
  return (
    <Popper
      open={open}
      style={{ zIndex: 99 }}
      anchorEl={anchorRef.current}
      role={undefined}
      transition
      disablePortal
      placement="bottom-start"
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === "bottom-start" ? "center top" : "center bottom",
          }}
        >
          <Paper className={classes.paper}>
            <ClickAwayListener onClickAway={handleClose}>
              <Box>
                <PerfectScrollbar>
                  {prizeTypes.map((field, index) => {
                    return (
                      <Box key={index}>
                        <MenuList
                          autoFocusItem={isOpenList}
                          id="menu-list-grow"
                          onClick={() => {
                            handleSelect(field);
                          }}
                        >
                          <MenuItem>
                            <Typography
                              variant="inherit"
                              noWrap
                              style={{ paddingLeft: "8px" }}
                            >
                              {field.provider_name}
                            </Typography>
                          </MenuItem>
                        </MenuList>
                      </Box>
                    );
                  })}
                  <Divider />
                </PerfectScrollbar>
              </Box>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}
