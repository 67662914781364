import React, { Suspense } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import {
  ROOT,
  MY_DEALER_LIST_PAGE,
  DEALER_DETAILS_PAGE,
  REWARD_CENTER_PAGE,
  DEALEAR_PROGRAM_LIST_PAGE,
  NEW_INCENTIVE_PROGRAM_PAGE,
  EDIT_DEALER_INCENTIVE_PROGRAM_PAGE,
  REWARD_DETAILS_PAGE,
  REWARD_CREATE_PAGE,
  STOCKOUT_LIST_PAGE,
  STOCKOUT_DETAILS_PAGE,
  RECALL_LIST_PAGE,
  RECALL_DETAILS_PAGE,
  TRANSFER_LIST_PAGE,
  TRANSFER_DETAILS_PAGE,
  RECEIVE_TRANSFER_LIST_PAGE,
  RECEIVE_TRANSFER_DETAILS_PAGE,
  REQUEST_TRANSFER_LIST_PAGE,
  REQUEST_TRANSFER_CREATE_PAGE,
  INVENTORY_LIST_PAGE,
  INVENTORY_PRODUCT_DETAIL_PAGE,
  INVENTORY_PACKAGE_DETAIL_PAGE,
  INVENTORY_BOX_DETAIL_PAGE,
  ORDER_LIST_PAGE,
  ORDER_DETAILS_PAGE,
  INVENTORY_LOOSE_ITEM_DETAIL_PAGE,
  BRANCH_REQUEST_TRANSFER_DETAILS_PAGE,
  MY_REQUEST_TRANSFER_DETAILS_PAGE,
  CHECKOUT_LIST_PAGE,
  CHECKOUT_DETAILS_PAGE,
  STOCK_TAKE_LIST_PAGE,
  STOCK_TAKE_DETAILS_PAGE,
  STOCK_TAKE_CREATE_PAGE,
} from "./config/stockflow.route";
import { ROOT as Home } from "modules/dashboard";
import LoadingPanel from "./components/panel/loadingPanel";
import {
  MyDealerPage,
  DealerDetailsPage,
  RewardPage,
  DealerIncentiveProgramListing,
  AddDealerIncentiveProgram,
  EditIncentiveProgram,
  RewardDetailsPage,
  AddRewardPage,
  StockoutMainPage,
  StockoutDetailPage,
  RecallMainPage,
  RecallDetailPage,
  OrderMainPage,
  OrderDetailPage,
  TransferMainPage,
  InventoryMainPage,
  InventoryProductDetailsPage,
  InventoryPackageDetailsPage,
  InventoryBoxDetailsPage,
  InventoryLooseItemDetailPage,
  TransferDetailPage,
  ReceiveTransferDetailPage,
  ReceiveTransferMainPage,
  RequestTransferMainPage,
  RequestTransferDetailPage,
  MyRequestTransferDetailPage,
  CheckoutMainPage,
  CheckoutDetailsPage,
  StockTakeMainPage,
  StockTakeDetailsPage,
  StockTakeCreatePage,
} from "./pages";
import CreateRequestTransfer from "./pages/request-transfer/create-request-transfer";

export default function ModuleStockflow() {
  return (
    <Suspense fallback={<LoadingPanel />}>
      <Switch>
        <Route exact path={MY_DEALER_LIST_PAGE} component={MyDealerPage} />
        <Route exact path={DEALER_DETAILS_PAGE} component={DealerDetailsPage} />
        <Route exact path={REWARD_CENTER_PAGE} component={RewardPage} />
        <Route exact path={REWARD_CREATE_PAGE} component={AddRewardPage} />
        <Route exact path={REWARD_DETAILS_PAGE} component={RewardDetailsPage} />
        <Route
          exact
          path={DEALEAR_PROGRAM_LIST_PAGE}
          component={DealerIncentiveProgramListing}
        />
        <Route
          exact
          path={NEW_INCENTIVE_PROGRAM_PAGE}
          component={AddDealerIncentiveProgram}
        />
        <Route
          exact
          path={EDIT_DEALER_INCENTIVE_PROGRAM_PAGE}
          component={EditIncentiveProgram}
        />
        <Route exact path={STOCKOUT_LIST_PAGE} component={StockoutMainPage} />
        <Route
          exact
          path={STOCKOUT_DETAILS_PAGE}
          component={StockoutDetailPage}
        />
        <Route exact path={RECALL_LIST_PAGE} component={RecallMainPage} />
        <Route exact path={RECALL_DETAILS_PAGE} component={RecallDetailPage} />
        <Route exact path={TRANSFER_LIST_PAGE} component={TransferMainPage} />
        <Route
          exact
          path={TRANSFER_DETAILS_PAGE}
          component={TransferDetailPage}
        />
        <Route
          exact
          path={RECEIVE_TRANSFER_LIST_PAGE}
          component={ReceiveTransferMainPage}
        />
        <Route
          exact
          path={RECEIVE_TRANSFER_DETAILS_PAGE}
          component={ReceiveTransferDetailPage}
        />
        <Route
          exact
          path={REQUEST_TRANSFER_LIST_PAGE}
          component={RequestTransferMainPage}
        />
        <Route
          exact
          path={REQUEST_TRANSFER_CREATE_PAGE}
          component={CreateRequestTransfer}
        />
        <Route
          exact
          path={BRANCH_REQUEST_TRANSFER_DETAILS_PAGE}
          component={RequestTransferDetailPage}
        />
        <Route
          exact
          path={MY_REQUEST_TRANSFER_DETAILS_PAGE}
          component={MyRequestTransferDetailPage}
        />
        <Route exact path={ORDER_LIST_PAGE} component={OrderMainPage} />
        <Route exact path={ORDER_DETAILS_PAGE} component={OrderDetailPage} />
        <Route exact path={CHECKOUT_LIST_PAGE} component={CheckoutMainPage} />
        <Route
          exact
          path={CHECKOUT_DETAILS_PAGE}
          component={CheckoutDetailsPage}
        />
        <Route
          exact
          path={STOCK_TAKE_LIST_PAGE}
          component={StockTakeMainPage}
        />
        <Route
          exact
          path={STOCK_TAKE_CREATE_PAGE}
          component={StockTakeCreatePage}
        />
        <Route
          exact
          path={STOCK_TAKE_DETAILS_PAGE}
          component={StockTakeDetailsPage}
        />
        <Route exact path={INVENTORY_LIST_PAGE} component={InventoryMainPage} />
        <Route
          exact
          path={INVENTORY_PRODUCT_DETAIL_PAGE}
          component={InventoryProductDetailsPage}
        />
        <Route
          exact
          path={INVENTORY_PACKAGE_DETAIL_PAGE}
          component={InventoryPackageDetailsPage}
        />
        <Route
          exact
          path={INVENTORY_BOX_DETAIL_PAGE}
          component={InventoryBoxDetailsPage}
        />
        <Route
          exact
          path={INVENTORY_LOOSE_ITEM_DETAIL_PAGE}
          component={InventoryLooseItemDetailPage}
        />
        <Redirect exact from="/admin/stockflow" to={Home} />
        <Route path="*">
          <Redirect to="/admin/invalid" />
        </Route>
      </Switch>
    </Suspense>
  );
}

export { stockflowDealerReducer } from "./redux/slice/stockflowDealer.slice";
export {
  ROOT,
  MY_DEALER_LIST_PAGE,
  REWARD_CENTER_PAGE,
  DEALEAR_PROGRAM_LIST_PAGE,
  INVENTORY_LIST_PAGE,
  STOCKOUT_LIST_PAGE,
  RECALL_LIST_PAGE,
  TRANSFER_LIST_PAGE,
  RECEIVE_TRANSFER_LIST_PAGE,
  REQUEST_TRANSFER_LIST_PAGE,
  ORDER_LIST_PAGE,
  CHECKOUT_LIST_PAGE,
  STOCK_TAKE_LIST_PAGE,
};
