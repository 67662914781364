import React from "react";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import InputAdornment from '@material-ui/core/InputAdornment';
import { LockImg } from "../../../modules/account/utils/static";

// We can inject some CSS into the DOM.
const styles = (theme) => ({
  label: {
    paddingTop: "10px"
  }
});

function InputTextField(props) {
  const { classes, className, ...other } = props;

  return (
    <TextField
      InputProps={{
        disableUnderline: true,
        endAdornment: (
          <InputAdornment position="end" style={{ paddingRight: "6px"}}>
            <LockImg />
          </InputAdornment>
        ),
        style: {
          padding: "5px",
          backgroundColor: "#ECEFF0",
          borderRadius: "4px"
        }
      }}
      inputProps={{
        style: {
          padding: "7px"
        }
      }}
      
      {...other}
    />
  );
}

export default withStyles(styles)(InputTextField);
