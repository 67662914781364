import { isFile, isFileExceedLimit } from "lib/helper";
import React, { useRef, useState } from "react";
import UploadDocumentComponent from "./uploadDocument.component";
import { useSelector } from "react-redux";
import { addAlert } from "modules/notification";
import { useDispatch } from "react-redux";
import { getLang } from "app/feature/constants";

function UploadDocumentContainer({ handleUpload, value, maxUploadSize, reuploadFlag = false }) {
  const dispatch = useDispatch();

  const [file, setFile] = useState(
    isFile(value) ? value.name : typeof value === "string" ? value : null
  );
  const lang = useSelector(state => state.constant.languages)
  const inputFile = useRef(null);
  const handleClick = () => {
    inputFile.current.click();
  };

  const handleFile = (e) => {
    if (isFileExceedLimit(e.target.files[0].size, maxUploadSize.size, maxUploadSize.unit)) {
      dispatch(addAlert({
        severity: "error",
        message: getLang(lang, "message.error.FORM_VALIDATE_FILE_SIZE", { value: maxUploadSize.size })
      }));
      return;
    }
    setFile(e.target.files[0].name);
    handleUpload(e.target.files[0]);
  };

  return (
    <UploadDocumentComponent
      handleClick={handleClick}
      handleFile={handleFile}
      inputFile={inputFile}
      file={file}
      reuploadFlag={reuploadFlag}
      lang={lang}
    />
  );
}

export default UploadDocumentContainer;
