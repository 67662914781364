import React, { memo } from "react";
import SurveyFormComponent from "./surveyForm.component";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

const TYPES = ["new", "edit", "readonly"];

function SurveyFormContainer({
  type,
  handleResult,
  initialValues,
  handleDelete,
  handleClose,
}) {
  const lang = useSelector((state) => state.constant.languages);

  return (
    <SurveyFormComponent
      type={type}
      handleSubmit={handleResult}
      handleDelete={handleDelete}
      initialValues={initialValues}
      lang={lang}
      handleClose={handleClose}
    />
  );
}

SurveyFormContainer.defaultProps = {
  type: TYPES[0],
};

SurveyFormContainer.propTypes = {
  type: PropTypes.oneOf(TYPES).isRequired,
  handleResult: PropTypes.func,
  handleDelete: PropTypes.func,
};

export { TYPES };

export default memo(SurveyFormContainer, (oldProps, newProps) => {
  return (
    oldProps.initialValues === newProps.initialValues ||
    oldProps.initialValues?.id === newProps.initialValues?.id
  );
});
