import React from "react"
import ScanHistoryTableComponent from "./scanHistoryTable.component"

export default function ScanHistoryTableContainer({
  rows,
  isFetching,
  isError,
  page,
  tableFilter,
  rowsPerPage,
  totalFiltered,
  handleChangePage,
  handleChangeRowsPerPage,
  handleReload,
  paginationOptions,
  changeOrder,
  language,
  snScanLimit,
  lang,
  handleViewDetail
}) {

  return (
    <ScanHistoryTableComponent
      rows={rows}
      isFetching={isFetching}
      language={language}
      isError={isError}
      handleReload={handleReload}
      page={page}
      rowsPerPage={rowsPerPage}
      totalFiltered={totalFiltered}
      paginationOptions={paginationOptions}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      tableFilter={tableFilter}
      changeOrder={changeOrder}
      snScanLimit={snScanLimit}
      lang={lang}
      handleViewDetail={handleViewDetail}
    />
  )
}
