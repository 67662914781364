import loginMdl from "./login.mdl";
import resetPasswordMdl from "./resetPassword.mdl";
import signupMdl from "./signup.mdl";
import publicMdl from "./public.mdl";
import constantsMdl from "./constants.mdl";

export default [
  ...signupMdl,
  ...loginMdl,
  ...resetPasswordMdl,
  ...publicMdl,
  ...constantsMdl
];
