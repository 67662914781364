import React, { useRef } from 'react'
import {
  Box,
  Button,
  makeStyles,
  Typography
} from '@material-ui/core'
import { SurveyListingPanel, SurveyTemplatePanel } from 'modules/survey/components/panel'
import { AddIcon } from 'components/icon'
import { getLang } from 'app/feature/constants'

const useStyle = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up("lg")]: {
      display: "grid",
    },
    gridTemplateColumns: "7fr 3fr",
    gridTemplayeRows: "auto auto",
    columnGap: theme.spacing(2),
    gridTemplateAreas: "'topbar .''panel photo'",
    [theme.breakpoints.down("md")]: {
      gridTemplateAreas: "'topbar topbar''panel photo'"
    },
    [theme.breakpoints.down("sm")]: {
      gridTemplateAreas: "'topbar topbar''panel panel''photo photo'"
    }
  }
}))

export default function SurveyListingComponent({
  hasSurveyAddAccessRight,
  lang
}) {
  const classes = useStyle()
  const surveyRef = useRef();

  const openCreateSurveyModal = type => {
    surveyRef.current.openCreateSurveyModal(type)
  }

  return (
    <Box className={classes.root}>
      <Box
        gridArea="topbar"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex" alignItems="flex-end" fontWeight="bold">
          <Typography variant="h6" color="primary" style={{ fontWeight: "bold" }}>
            {getLang(lang, 'label.SURVEY')}
          </Typography>
        </Box>
        {
          hasSurveyAddAccessRight
            ?
            <Button
              variant="text"
              color="primary"
              startIcon={
                <AddIcon color="secondary" style={{ fontSize: "13px" }} />
              }
              onClick={openCreateSurveyModal}
            >
              {getLang(lang, 'label.SURVEY')}
            </Button>
            : null
        }
      </Box>
      <Box gridArea="panel" pt={2} maxWidth="100%" overflow="hidden">
        <SurveyListingPanel
          ref={surveyRef}
        />
      </Box>
      {
        hasSurveyAddAccessRight
          ?
          <Box gridArea="photo" pt={2}>
            <SurveyTemplatePanel handleCreate={openCreateSurveyModal} />
          </Box>
          : null
      }
    </Box>
  )
}