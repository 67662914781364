import React from "react";
import {
  Box,
  Paper,
  makeStyles,
  Typography,
  Divider,
  Grid,
  Tooltip
} from "@material-ui/core";
import InfiniteScroll from "react-infinite-scroll-component";
import Skeleton from "@material-ui/lab/Skeleton";
import LoadingComponent from "components/loading";
import DefaultImage from "assets/img/defaultPhoto.png";
import ActionDetailButton from "components/button/actionDetailButton";
import RefreshTable from "components/table/refreshTable";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles((theme) => ({
  listItemRoot: {
    padding: theme.spacing(0, 2),
    borderTop: "1px solid",
    borderTopColor: theme.palette.divider,
    width: "100%",
    boxSizing: "border-box",
  },
  employeeContainer: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1, 0),
    overflowX: "hidden"
  },
  employeeList: {
    height: 350,
    overflowY: "auto"
  },
  employeeThumbnail: {
    height: 50,
    width: 50,
    minWidth: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& img": {
      height: "100%",
      minHeight: "100%",
      minWidth: "100%",
      objectFit: "cover",
      flexShrink: 0,
      borderRadius: "50%"
    }
  },
  employeeName: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontWeight: theme.typography.fontWeightMedium,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      width: 180,
    },
  },
  dotList: {
    height: 4,
    width: 4,
    borderRadius: "50%",
    backgroundColor: "#899498",
    margin: "4px"
  },
  employeePositionContainer: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      width: 180,
    },
  },
  emptyContainer: {
    backgroundColor: theme.palette.background.paper,
    textAlign: "center"
  }
}));

export default function NewEmployeePanelComponent({
  handleView,
  isFetching,
  fetchMoreData,
  hasMoretoLoad,
  newEmployeeData,
  handleReload,
  isLoadingNewError,
  lang
}) {
  const classes = useStyle();

  function EmployeeList({ thumbnailScr, employeeName, position, place, id }) {
    const classes = useStyle();

    return (
      <Box className={classes.listItemRoot}>
        <Box className={classes.employeeContainer}>
          <Box className={classes.employeeThumbnail}>
            {thumbnailScr === null ? (
              <img src={DefaultImage} alt="default profile" />
            ) : (
              <img src={thumbnailScr.url} alt={employeeName} />
            )}
          </Box>
          <Box ml={2} style={{ width: "100%" }} overflow="hidden">
            <Tooltip title={employeeName}>
              <Typography
                variant="body2"
                color="primary"
                className={classes.employeeName}
              >
                {employeeName}
              </Typography>
            </Tooltip>
            <Typography
              component="p"
              variant="caption"
              color="textSecondary"
              className={classes.employeePositionContainer}
              style={{ 
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {position}&nbsp;&bull;&nbsp;
              {place.map((v, index) => (
                <React.Fragment key={index}>
                  {v.name}{place.length-1!==index?<>&nbsp;&bull;&nbsp;</>:null}
                </React.Fragment>
              ))}
            </Typography>
          </Box>
          <Box>
            <ActionDetailButton
              variant="vertical"
              actionItems={[{
                label: getLang(lang,"label.VIEW_PROFILE"),
                action: () => handleView(id),
              }]}
            />
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Paper elevation={0} className={classes.root}>
      <Box p={2}>
        <Grid container>
          <Grid item xs={9} sm={9} md={9} style={{ width: "100%" }}>
            <Box style={{ display: "flex", alignItems: "center" }}>
              <Typography
                variant="body2"
                style={{ fontWeight: "bold", marginLeft: "8px" }}
              >
                {getLang(lang,"label.NEW_EMPLOYEE")}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      {newEmployeeData.length > 0 || isFetching ? (
        <React.Fragment>
          {isFetching ? (
            <>
              {[1, 2, 3].map((v, index) => (
                <Box
                  p={1}
                  display="grid"
                  gridTemplateColumns="1fr 3fr"
                  key={index}
                >
                  <Skeleton variant="circle" height={50} width={50} />

                  <div style={{ width: "100%" }}>
                    <Skeleton width={"100%"} height={30} />
                    <Skeleton width={"100%"} height={30} />
                  </div>
                </Box>
              ))}
            </>
          ) : !isLoadingNewError && (
            <InfiniteScroll
              dataLength={newEmployeeData.length}
              next={fetchMoreData}
              hasMore={hasMoretoLoad}
              loader={<LoadingComponent height={50} />}
              height={350}
              style={{ position: "relative" }}
            >
              <Box className={classes.employeeList}>
                {newEmployeeData.map((employee, index) => (
                  <EmployeeList
                    key={index}
                    thumbnailScr={employee.imageProfileUrl}
                    employeeName={employee.employeeName}
                    position={employee.userGroup}
                    place={employee.nodeName}
                    id={employee.employeeId}
                  />
                ))}
              </Box>
            </InfiniteScroll>
          )}
        </React.Fragment>
      ) : !isLoadingNewError && (
        <Box p={2} py={9} className={classes.emptyContainer}>
          <Typography variant="body1">
            {getLang(lang,"paragraph.NO_NEW_EMPLOYEE")}
          </Typography>
        </Box>
      )}

      {!isFetching && isLoadingNewError && (
        <RefreshTable handleReload={handleReload} />
      )}
    </Paper>
  );
}
