import s3Api from "../../api/s3";

export async function uploadFile({ filePath, file }) {
  const presigned = await s3Api.createPresignedUrl(filePath);
  const upload = await s3Api.upload(presigned.data, file);

  if (upload.status !== 204) {
    return { success: false };
  }

  const { url } = await s3Api.getUrl(filePath);

  return { success: true, url };
}

export async function uploadJSON({ fileName, data }) {
  const upload = await s3Api.uploadJsonFile(fileName, data);

  if (upload.status !== 200) {
    return { success: false };
  }

  const { data: { url } } = upload.data;

  return { success: true, url };
}
