import React from "react";
import {
  Box,
  makeStyles,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Paper,
  TableSortLabel,
  Link,
} from "@material-ui/core";
import {
  StyledTableCell,
  StyledTableRow,
  StickyRightTableCell,
} from "components/table";
import { SkeletonTableRowStickyLeftRight } from "components/skeleton/skeleton.component";
import { ActionButton } from "components/button";
import { TablePaginationActions } from "components/tablePagination";
import RefreshTable from "components/table/refreshTable";
import moment from "moment";
import { KeyboardArrowDown } from "@material-ui/icons";
import { getLang } from "app/feature/constants";
import "moment/min/locales.min";
import BusinessIcon from "@material-ui/icons/Business";

const useStyle = makeStyles((theme) => ({
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    borderCollapse: "separate",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
    },
    "& th,td": {
      fontSize: ".75rem",
      borderRight: "1px solid rgba(58, 77, 84, 0.1)",
      padding: theme.spacing(1, 2, 1, 2),
    },
    "& th": {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightBold,
    },
    "& th:last-child, td:last-child": {
      width: 37,
      borderRight: "0px",
    },
    "& th th": {
      borderBottom: "none !important",
    },
    "& td td": {
      borderBottom: "none !important",
    },
  },
  emptyContainer: {
    backgroundColor: theme.palette.background.paper,
    textAlign: "center",
  },
  actionContainer: {
    justifyContent: "center",
  },
  paperRoot: {
    width: "300px",
  },
  selectAll: {
    "&.Mui-checked": {
      color: "#FDB022",
    },
  },
  selectOne: {
    "&.Mui-checked": {
      color: "#6AAF68",
    },
  },
  profile: {
    height: 26,
    width: 26,
    minWidth: 26,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& img": {
      height: "100%",
      minHeight: "100%",
      minWidth: "100%",
      objectFit: "cover",
      borderRadius: "50%",
      border: "1px solid #D0D5DD",
    },
  },
  tierIcon: {
    borderRadius: "3px",
    paddingLeft: "0.15em",
    paddingRight: "0.15em",
    display: "flex",
    alignItems: "center",
    position: "absolute",
    top: "-3px",
    left: "-8px",
  },
  tierLabel: {
    color: "#FFFFFF",
    fontSize: "0.6rem",
  },
  ellipsisText: {
    maxWidth: "18em",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: "0.75rem",
  },
}));

export default function RequestListTableComponent({
  rows,
  isFetching,
  isError,
  handleReload,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowPerPage,
  isSearchResult,
  totalFiltered,
  paginationOptions,
  tableFilter,
  changeOrder,
  lang,
  handleViewDetail,
  language,
  url,
  type,
}) {
  const classes = useStyle();

  const statusColor = {
    pending: "#FDB022",
    accepted: "#32D583",
    rejected: "#F97066",
    cancelled: "#98A2B3",
  };

  if(language==="ZH")
  {
    moment.locale('zh-cn')
  }else if(language==="TL")
  {
    moment.locale('tl-ph')
  }else
  {
    moment.locale(language)
  }
  

  return (
    <>
      {!isFetching && isError && <RefreshTable handleReload={handleReload} />}
      {isFetching || rows.length > 0 ? (
        <Paper id="test" style={{ overflowX: "auto", width: "100%" }}>
          <TableContainer>
            <Table
              className={classes.table}
              aria-label="request table"
              size="small"
              stickyHeader
            >
              <TableHead style={{ height: "45px" }}>
                <TableRow>
                  <StyledTableCell>
                    {getLang(lang, "label.REQUEST_ID")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.REQUESTING_BRANCH")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.RECEIVING_BRANCH")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.TRANSFERRING_BRANCH")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.STATUS")}
                  </StyledTableCell>
                  <StyledTableCell>
                    <TableSortLabel
                      active={tableFilter.orderBy === "created_at"}
                      direction={tableFilter.order}
                      onClick={() => {
                        const differentTab =
                          tableFilter.orderBy !== "created_at";
                        const order = differentTab
                          ? tableFilter.order
                          : tableFilter.order === "asc"
                          ? "desc"
                          : "asc";
                        changeOrder("created_at", order);
                      }}
                      IconComponent={KeyboardArrowDown}
                    >
                      {getLang(lang, "label.REQUEST_DATE")}
                    </TableSortLabel>
                  </StyledTableCell>

                  <StickyRightTableCell>
                    <StyledTableCell
                      style={{ width: "67px", textAlign: "center" }}
                    >
                      {getLang(lang, "label.ACTION")}
                    </StyledTableCell>
                  </StickyRightTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isFetching
                  ? [...new Array(5)].map((v, index) => (
                      <SkeletonTableRowStickyLeftRight
                        key={index}
                        columnCount={7}
                      />
                    ))
                  : rows.map((row, index) => {
                      const actionItems = [];

                      actionItems.push({
                        label: getLang(lang, "label.VIEW_DETAILS"),
                        action: () => {
                          handleViewDetail(
                            `${url}/${type === "Receive" ? "branch" : "my"}/${
                              row.uuid
                            }`
                          );
                        },
                      });

                      return (
                        <StyledTableRow
                          key={row.id}
                          hover
                          role="checkbox"
                          tabIndex={-1}
                        >
                          <StyledTableCell>
                            <Link
                              href={`${url}/${type === "Send" ? "my" : "branch"}/${row.uuid}`}
                              color="secondary"
                              onClick={(e) => {
                                handleViewDetail(
                                  `${url}/${
                                    type === "Send" ? "my" : "branch"
                                  }/${row.uuid}`
                                );
                                e.preventDefault();
                                return false;
                              }}
                            >
                              <Typography className={classes.ellipsisText}>
                                {row.reference_code}
                              </Typography>
                            </Link>
                          </StyledTableCell>
                          <StyledTableCell>
                            {row.request_by ? (
                              <Box display="flex" alignItems="center">
                                <Box
                                  className={classes.profile}
                                  mr={1}
                                  position="relative"
                                >
                                  {!row.request_by.picture || row.request_by.picture === "" ? (
                                    <Box
                                      style={{
                                        width: 26,
                                        height: 26,
                                        borderRadius: '100%',
                                        border: '2px solid #6AAF68',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        boxShadow: '0px 0px 4px 0px rgb(0 0 0 / 25%)',
                                      }}
                                    >
                                      <BusinessIcon style={{ fontSize: 18, color: '#6AAF68' }} />
                                    </Box>
                                  ) : (
                                    <img
                                      src={row.request_by.picture}
                                      alt={row.name}
                                    />
                                  )}
                                </Box>
                                <Typography className={classes.ellipsisText}>
                                  {row.request_by.name}
                                </Typography>
                              </Box>
                            ) : (
                              <span>-</span>
                            )}
                          </StyledTableCell>
                          <StyledTableCell>
                            {row.request_from ? (
                              <Box display="flex" alignItems="center">
                                <Box
                                  className={classes.profile}
                                  mr={1}
                                  position="relative"
                                >
                                  {!row.request_from.picture || row.request_from.picture === "" ? (
                                    <Box
                                      style={{
                                        width: 26,
                                        height: 26,
                                        borderRadius: '100%',
                                        border: '2px solid #6AAF68',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        boxShadow: '0px 0px 4px 0px rgb(0 0 0 / 25%)',
                                      }}
                                    >
                                      <BusinessIcon style={{ fontSize: 18, color: '#6AAF68' }} />
                                    </Box>
                                  ) : (
                                    <img
                                      src={row.request_from.picture}
                                      alt={row.name}
                                    />
                                  )}
                                </Box>
                                <Typography className={classes.ellipsisText}>
                                  {row.request_from.name}
                                </Typography>
                              </Box>
                            ) : (
                              <span>-</span>
                            )}
                          </StyledTableCell>
                          <StyledTableCell>
                            {row.request_to ? (
                              <Box display="flex" alignItems="center">
                                <Box
                                  className={classes.profile}
                                  mr={1}
                                  position="relative"
                                >
                                  {!row.request_to.picture || row.request_to.picture === "" ? (
                                    <Box
                                      style={{
                                        width: 26,
                                        height: 26,
                                        borderRadius: '100%',
                                        border: '2px solid #6AAF68',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        boxShadow: '0px 0px 4px 0px rgb(0 0 0 / 25%)',
                                      }}
                                    >
                                      <BusinessIcon style={{ fontSize: 18, color: '#6AAF68' }} />
                                    </Box>
                                  ) : (
                                    <img
                                      src={row.request_to.picture}
                                      alt={row.name}
                                    />
                                  )}
                                </Box>
                                <Typography className={classes.ellipsisText}>
                                  {row.request_to.name}
                                </Typography>
                              </Box>
                            ) : (
                              <span>-</span>
                            )}
                          </StyledTableCell>
                          <StyledTableCell>
                            <span style={{ color: statusColor[row.status] }}>
                              {row.status_label
                                ? getLang(lang, row.status_label)
                                : "-"}
                            </span>
                          </StyledTableCell>
                          <StyledTableCell>
                            <span>
                              {moment
                                .utc(row.created_at)
                                .local()
                                .format("lll")}
                            </span>
                          </StyledTableCell>
                          <StickyRightTableCell>
                            <StyledTableCell style={{ width: "67px" }}>
                              <ActionButton
                                actionItems={actionItems}
                                popperStyle={{ zIndex: 999 }}
                              />
                            </StyledTableCell>
                          </StickyRightTableCell>
                        </StyledTableRow>
                      );
                    })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            style={{
              backgroundColor: "#fff",
              boxShadow: "0px -1px 0px rgba(58, 77, 84, 0.15)",
            }}
            ActionsComponent={TablePaginationActions}
            rowsPerPageOptions={paginationOptions}
            component="div"
            count={totalFiltered}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowPerPage}
            labelRowsPerPage={getLang(lang, "label.ROWS_PER_PAGE")}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} ${getLang(lang, "label.OF")} ${count}`
            }
          />
        </Paper>
      ) : null}
      {!isFetching && !isError && rows.length === 0 && (
        <Box>
          <TableContainer>
            <Table
              className={classes.table}
              aria-label="request table"
              size="small"
              stickyHeader
            >
              <TableHead style={{ height: "45px" }}>
                <TableRow>
                  <StyledTableCell>
                    {getLang(lang, "label.REQUEST_ID")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.REQUESTING_BRANCH")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.RECEIVING_BRANCH")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.TRANSFERRING_BRANCH")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.STATUS")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.REQUEST_DATE")}
                  </StyledTableCell>
                  <StickyRightTableCell>
                    <StyledTableCell>
                      {getLang(lang, "label.ACTION")}
                    </StyledTableCell>
                  </StickyRightTableCell>
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>
          <Box py={9} className={classes.emptyContainer}>
            <Typography
              variant="body1"
              style={{ paddingBottom: "16px", fontWeight: "bold" }}
            >
              {isSearchResult
                ? getLang(lang, "paragraph.NO_REQUEST_EXISTS")
                : getLang(lang, "paragraph.YOU_DONT_HAVE_REQUEST")}
            </Typography>
            {!isSearchResult && (
              <Typography variant="body2" style={{ paddingBottom: "16px" }}>
                {getLang(
                  lang,
                  type === "Receive"
                    ? "paragraph.RECEIVE_REQUEST_INSTRUCTION"
                    : "paragraph.REQUEST_INSTRUCTION"
                )}
              </Typography>
            )}
          </Box>
        </Box>
      )}
    </>
  );
}
