import { Avatar, Box, Checkbox, IconButton, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Typography } from "@material-ui/core";
import html2canvas from "html2canvas";
import { DownloadIcon, UnknownUserIcon } from "modules/reporting/assets/svg";
import { cardChartStyle, fontTextStyle, iconStyle, tableStyle } from "modules/reporting/styles";
import React from "react";
import { ContentCard } from "../../layout";
import downloadjs from 'downloadjs';
import { useState } from "react";
import { drilldownPageSelection, responseHandler } from "modules/reporting/utils/helper";
import { CampaignConversionsNoData } from "../../skeleton/no-data";
import { CampaignConversionsNoResult } from "../../skeleton/no-result";
import { BoxLoading } from "../../skeleton/loading";
import Chart from 'react-apexcharts';
import { setChartColors } from "modules/reporting/utils/chartConfig";
import ApexCharts from 'apexcharts'
import { TablePaginationActions } from "components/tablePagination";
import { useEffect } from "react";
import { generatePaginationOptions } from "lib/helper";
import { Link } from "react-router-dom";
import moment from "moment";
import { CAMPAIGN_TYPE_INSTANTLUCKYDRAW, CAMPAIGN_TYPE_LOYALTYPROGRAM, CAMPAIGN_TYPE_LUCKYDRAW, CAMPAIGN_TYPE_SURVEY, CAMPAIGN_TYPE_WARRANTY } from "modules/reporting/constants";

const checkBoxStyles = makeStyles({
    checkbox__cell: {
        color: props => props.color
    }
})

export default function CampaignProductPanel({
    status,
    data,
    columns,
    sort,
    translate,
    currentFilter,
    pagination,
    pageType
}) {
    // const classes = useStyles();
    const chartClasses = cardChartStyle();
    const tableClasses = tableStyle();
    const fontClasses = fontTextStyle();
    const iconClasses = iconStyle();
    const [selected, setSelected] = useState([]);

    const [paginationOptions, setPaginationOptions] = useState([]);
    useEffect(() => {
        setPaginationOptions(generatePaginationOptions(data ? data.total_record : 0))
    }, [data]);

    // download campaign product
    const handleDownloadCampaignCard = () => {
        let element = document.getElementById("campaign-product");

        html2canvas(element).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            downloadjs(imgData, 'campaign-product.png');
        });
    }

    const hideAll = () => {
        data.campaignConversionData.rows.forEach((n) => {
            if(!n.campaign_type)
                return;
            ApexCharts.exec(chartID, 'hideSeries', n.name);
        })
    }

    const showAll = () => {
        data.campaignConversionData.rows.forEach((n) => {
            if(!n.campaign_type)
                return;
            ApexCharts.exec(chartID, 'showSeries', n.name);
        })
    }

    const toggle = (name) => {
        ApexCharts.exec(chartID, 'toggleSeries', name);
    }

    const CheckboxComponent = ({children, ...props}) => {
        const { checkbox__cell } = checkBoxStyles(props);
        const { checked, onClick, inputProps } = props;

        return (
            <Checkbox
                className={`${checkbox__cell}`}
                checked={checked}
                onClick={onClick}
                inputProps={inputProps}
            />
        )
    }

    const onSelectAllClick = (event) => {
        if (!event.target.checked){
            const newSelecteds = data.campaignConversionData.rows.map((n) => n.name);
            hideAll();
            setSelected(newSelecteds);
        } else {
            showAll();
            setSelected([]);
        }
    }

    // apexchart configuration & function
    const chartID = "campaign-product-chart";

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
        toggle(name)
    }

    const createSortHandler = (property) => (event) => {
        sort.handleRequestSort(event, property);
    };

    const displayData = (ref) => {

        if (Object.keys(ref).length === 0 || !ref.isSuccess){
            return null;
        }
        
        const colorSet = setChartColors(ref.datasets.length, true);

        const options = {
            chart: {
                id: chartID
            },
            tooltip:{
                followCursor: true,
                y: {
                    formatter: function (val) {
                        return parseInt(val)
                    }
                }
            },
            xaxis: {
                categories: ref.labels,
                labels: {
                    rotateAlways: true,
                    rotate: -45,
                    hideOverlappingLabels: true,
                },
                tickPlacement: 'between',
            },
            legend: {
                position: 'bottom',
                showForSingleSeries: true,
                onItemClick: {
                    toggleDataSeries: false
                }
            },
            grid: {
                padding: {
                    left: 12,
                    right: 12,
                }
            },
            stroke: {
                width: 2,
            },
            colors: colorSet
    
        }

        const chartSeries = () => {
            let series = JSON.stringify(ref);
            series = JSON.parse(series);
    
            series.datasets.forEach(e => {
                e.name = translate(e.name);
            });
    
            return series;
        }

        let no_iteration = pagination.rowsPerPage * pagination.page + 1;

        return (
            <Box
                className={chartClasses.card}
            >
                <Chart
                    options={options}
                    series={chartSeries().datasets}
                    type="line"
                    height="430px"
                ></Chart>
                <TableContainer component={ Paper }>
                    <Box style={{
                        overflowX: "auto",
                        width: "100%"
                    }}>
                        <Table>
                            <TableHead>
                                <TableRow
                                    hover
                                >
                                    <TableCell
                                        padding="checkbox"
                                    >
                                        <Checkbox 
                                            onChange={onSelectAllClick}
                                            indeterminate={selected.length > 0 && selected.length < ref.rows.length}
                                            checked={ref.rows.length > 0 && selected.length === 0}
                                        />
                                    </TableCell>
                                    {columns.map((column, index) => (
                                        <TableCell className={[tableClasses.table__cell, fontClasses.text__header_table_cell]} key={column.title} size={column.size}>
                                            {
                                                column.field ? 
                                                (
                                                    <TableSortLabel
                                                        active={sort.valueToOrderBy === column.field ? true : false}
                                                        direction={sort.valueToOrderBy === column.field ? sort.orderDirection : 'desc'}
                                                        onClick={!status.isFetchingCampaignConversionData && column.field ? createSortHandler(column.field) : null}
                                                    >
                                                        {column.render(column)}
                                                    </TableSortLabel>
                                                ) : 
                                                column.render(column)
                                            }
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {ref.rows.map((row, index) => {
                                    const isItemSelected = selected.indexOf(row.name) !== -1;
                                    const labelId = `enhanced-table-checkbox-${row.name}`;
                                    
                                    if(!row.name)
                                        return null;
                                    
                                    return (
                                        <TableRow
                                            hover
                                            aria-checked={isItemSelected}
                                            key={row.name}
                                            selected={isItemSelected}
                                        >
                                            <TableCell className={tableClasses.table__cell} style={{width: "20px"}}>
                                                <Typography className={fontClasses.text__table_cell}>{no_iteration++}</Typography>
                                            </TableCell>
                                            <TableCell 
                                                padding="checkbox"
                                                className={tableClasses.table__cell}
                                            >
                                                <CheckboxComponent 
                                                    checked={!isItemSelected}
                                                    onClick={(event) => handleClick(event, row.name)}
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                    color={colorSet[index] + ' !important'}
                                                />
                                            </TableCell>
                                            <TableCell
                                                className={tableClasses.table__cell}
                                                align="left"
                                            >
                                                <Box className={tableClasses.cell__product}>
                                                    {
                                                        row.image ?
                                                        <Avatar variant="square" alt={row.name} src={row.image} className={iconClasses.svg__icon}/>
                                                        :
                                                        <Avatar variant="square" alt={row.name} src={UnknownUserIcon} className={iconClasses.svg__icon} />
                                                    }
                                                    
                                                    <Link
                                                        target="_blank" rel="noopener noreferrer" 
                                                        className={fontClasses.text__hyperlink}
                                                        to={() => {

                                                            let filterProperties = {};

                                                            if(currentFilter.countryCode)
                                                                filterProperties.countryCode = currentFilter.countryCode;
                                                            
                                                            if(currentFilter.cityUuid)
                                                                filterProperties.city = currentFilter.cityUuid;
                                                            
                                                            if(currentFilter.branchUuid)
                                                                filterProperties.branchUuid = currentFilter.branchUuid;

                                                            if(currentFilter.period)
                                                                filterProperties.period = currentFilter.period;

                                                            if(currentFilter.dateRange){
                                                                filterProperties.start = moment(currentFilter.dateRange[0]).format('YYYY-MM-DD');
                                                                filterProperties.end = moment(currentFilter.dateRange[1]).format('YYYY-MM-DD');
                                                            }

                                                            if(row.uuid)
                                                                filterProperties.productUuid = row.uuid;

                                                            let url = drilldownPageSelection(filterProperties, pageType, true);
                                                            return url;
                                                        }}
                                                    >
                                                        <span>{row.name}</span>
                                                    </Link>
                                                </Box>
                                            </TableCell>
                                            <TableCell
                                                className={tableClasses.table__cell}
                                                size="small"
                                                align="right"
                                                style={{
                                                    maxWidth: '180px',
                                                    width: '180px',
                                                    
                                                }}
                                            >
                                                <Typography className={fontClasses.text__table_cell}>{row.engagement}</Typography>
                                            </TableCell>
                                            <TableCell
                                                className={tableClasses.table__cell}
                                                size="small"
                                                align="right"
                                                style={{
                                                    maxWidth: '180px',
                                                    width: '180px',
                                                    
                                                }}
                                            >
                                                <Typography className={fontClasses.text__table_cell}>{row.received_percentage}</Typography>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </Box>
                    <TablePagination
                        ActionsComponent={TablePaginationActions}
                        component="div"

                        // rowsPerPageOptions={pagination.rowsPerPageOptions}
                        rowsPerPageOptions={paginationOptions}
                        rowsPerPage={pagination.rowsPerPage}
                        page={pagination.page}
                        count={ref.total_record ?? 0}
                        onChangePage={pagination.handleChangePage}
                        onChangeRowsPerPage={pagination.handleChangeRowPerPage}
                    />
                </TableContainer>
            </Box>
        )
    }

    const getCardTitle = (campaignType) => {
        switch(campaignType){
            case CAMPAIGN_TYPE_SURVEY:
                return translate("label.SURVEY_RECEIVED_BY_PRODUCT");
            case CAMPAIGN_TYPE_LUCKYDRAW:
                return translate("label.LUCKY_DRAW_RECEIVED_BY_PRODUCT");
            case CAMPAIGN_TYPE_INSTANTLUCKYDRAW:
                return translate("label.INSTANT_LUCKY_DRAW_RECEIVED_BY_PRODUCT");
            case CAMPAIGN_TYPE_LOYALTYPROGRAM:
                return translate("label.LOYALTY_PROGRAM_RECEIVED_BY_PRODUCT");
            case CAMPAIGN_TYPE_WARRANTY:
                return translate("label.WARRANTY_RECEIVED_BY_PRODUCT");
            default:
                return null;
        }
    }

    return (
        <ContentCard id="campaign-product">
            <Box marginBottom="24px" display="flex" justifyContent="space-between" alignContent="center">
                <Typography className={fontClasses.text__card_title}>
                    {getCardTitle(currentFilter.campaignType)}
                </Typography>

                <Box display="flex" alignContent="center">
                    <IconButton title="Download Charts" aria-label="download" onClick={handleDownloadCampaignCard} disableRipple>
                        <DownloadIcon />
                    </IconButton>
                </Box>
            </Box>
            {
                responseHandler(
                    data.campaignProductData,
                    status.isFetchingCampaignProductData,
                    displayData(data.campaignProductData),
                    <BoxLoading />,
                    <CampaignConversionsNoData 
                        title={translate("label.CAMPAIGN_NOT_FOUND")}
                        value={translate("paragraph.NO_DATA_ON_SELECTED_FILTER")}
                        translate={translate}
                    />,
                    <CampaignConversionsNoResult 
                        title={translate("label.CAMPAIGN_NOT_FOUND")}
                        value={translate("paragraph.NO_DATA_ON_SELECTED_FILTER")}
                        translate={translate}
                    />,
                    <CampaignConversionsNoResult 
                        title={translate("label.LOADING_FAILED")}
                        value={translate("paragraph.LOADING_FAILED_DESCRIPTION")}
                        translate={translate}
                    />
                )
            }
        </ContentCard>
    );
}