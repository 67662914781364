import React from "react";
import { makeStyles, Box } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyle = makeStyles(theme => ({
  root: {
    width: "100%"
  }
}));

export default function LoadingPanelComponent({
  variant = "text",
  width=250,
  height=40
}) {
  const classes = useStyle();

  return (
    <Box className={classes.root}>
      <Skeleton variant={variant} width={width} height={height} />
      { variant !== 'circle' ? 
        (<>
          <Skeleton variant={variant} width={width} height={height} />
          <Skeleton variant={variant} width={width} height={height} />
        </>) 
        : null}

    </Box>
  );
}
