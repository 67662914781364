import {
  FormHelperText,
  makeStyles,
  Box,
  Divider,
  Button,
  FormControlLabel,
  Typography,
  Grid,
  FormGroup,
  Tooltip,
} from "@material-ui/core";
import { ErrorMessage, Formik } from "formik";
import * as Yup from "yup";
import React from "react";
import HelpIcon from "@material-ui/icons/Help";
import TagSelect from "../../select/tagSelect";
import InputTextField from "components/input/inputTextField";
import AntSwitch from "components/switch";
import {
  CustomRangePanel,
  LastGeneratePanel,
} from "../../panel/generateRangePanel";
import {
  SERIAL_NUMBER_TYPE_ALPHANUMERIC,
  SERIAL_NUMBER_TYPE_NUMBER,
  GENERATE_RANGE_TYPE_LAST_NUMBER,
  GENERATE_RANGE_TYPE_CUSTOM,
} from "modules/serial-number/constants";
import ConfirmationDialog from "../../dialog/confirmationDialog";
import { getLang } from "app/feature/constants";
import ProductSearch from "../../select/productSearch";
import BranchSearch from "../../select/branchSearch";

const useStyle = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    width: "100%",
  },
  field: {
    display: "grid",
    gridTemplateColumns: "3fr 2fr 2fr",
    columnGap: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  discardButton: {
    color: theme.palette.error.main,
  },
  submitButton: {
    color: theme.palette.common.white,
  },
  stepTitle: {
    fontWeight: 700,
  },
  stepContainer: {
    margin: "15px 0px",
  },
  optionalLabel: {
    fontWeight: 400,
    marginLeft: 3,
  },
  stepTitleSection: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 11,
  },
  patternSection: {
    marginBottom: 15,
  },
  toggleButton: {
    marginRight: 10,
  },
  toggleControl: {
    margin: "7px 35px 7px 0px",
  },
  toggleFormGroup: {
    flexDirection: "row",
  },
  exampleSubtitle: {
    fontSize: 10,
    color: "rgba(58, 77, 84, 0.33)",
  },
  exampleValue: {
    fontSize: 16,
    fontWeight: 600,
    overflowWrap: "anywhere",
  },
  helpIcon: {
    cursor: "pointer",
    color: theme.palette.primary.main,
    fontSize: 16,
  },
  attributeText: {
    color: "#b2b2b2",
    fontSize: 12,
    whiteSpace: "break-spaces",
  },
  productPhoto: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #E4E7EC",
    width: "30px",
    height: "30px",
    marginRight: "10px",
    borderRadius: "4px",
    "& img": {
      height: "30px",
      width: "30px",
      borderRadius: "4px",
    },
  },
}));

function renderExampleDigit(type, length) {
  let exampleString = "";

  if (type === SERIAL_NUMBER_TYPE_NUMBER) {
    while (length > 0) {
      exampleString += "0";
      length--;
    }
  } else {
    const list = "ABCDEFGHIJKLMNPQRSTUVWXYZ123456789";

    for (var i = 0; i < length; i++) {
      var rnd = Math.floor(Math.random() * list.length);
      exampleString += list.charAt(rnd);
    }
  }

  return exampleString;
}

function validateOnlyAlphabet(value) {
  const reg = new RegExp("^[a-zA-Z]*$");

  return reg.test(value);
}

export default function GenerateSerialNumberFormComponent({
  qrCredit,
  registrationType,
  handleSubmit,
  handleDiscard,
  fetchHistory,
  productsDropdown,
  availableQuantity,
  batchRangeInfo,
  resetGenerateRangeList,
  isLoading,
  isOpen,
  setIsOpen,
  branchDropdown,
  loadProductDropdown,
  isLoadingProductDropdown,
  tags,
  addAlert,
  isSameRange,
  setIsSameRange,
  isExceedLimit,
  setIsExceedLimit,
  isInputDisable,
  setIsInputDisable,
  lang,
  hasBatchAuthenticatorEditRight,
  setShowCreditErrorDialog,
}) {
  const classes = useStyle();

  function resetForm(formik) {
    formik.setFieldValue("generateRangeType", GENERATE_RANGE_TYPE_LAST_NUMBER);
    formik.setFieldValue("prefix", "");
    formik.setFieldValue("digitLength", "");
    formik.setFieldValue("postfix", "");
    formik.setFieldValue("pinType", "");
    formik.setFieldValue("pinRequired", false);
    formik.setFieldValue("savePattern", false);
    formik.setFieldValue("appAuthrorizeRequire", false);

    resetRange(formik);
    resetGenerateRangeList();
  }

  function resetRange(formik) {
    formik.setFieldValue("generateRanges", [{ from: "", to: "" }]);
    formik.setFieldValue("generateAmount", "");
  }

  return (
    <Formik
      initialValues={{
        product: "",
        type: SERIAL_NUMBER_TYPE_NUMBER,
        prefix: "",
        digitLength: "",
        postfix: "",
        generateRangeType: "last",
        pinType: "",
        pinRequired: false,
        generateAmount: "",
        savePattern: false,
        generateRanges: [
          {
            from: "",
            to: "",
          },
        ],
        branch: "",
        appAuthrorizeRequire: false,
      }}
      validateOnChange={false}
      validationSchema={Yup.object({
        type: Yup.string().required(
          getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")
        ),
        pinType: Yup.string().when("pinRequired", {
          is: true,
          then: Yup.string().required(
            getLang(lang, "message.error.FORM_VALIDATE_SN_REQUIRED_PIN")
          ),
        }),
        digitLength: Yup.number()
          .min(
            1,
            getLang(lang, "message.error.FORM_VALIDATE_MIN_NUMBER", {
              value: 1,
            })
          )
          .required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
        generateAmount: Yup.number()
          .min(
            1,
            getLang(lang, "message.error.FORM_VALIDATE_MIN_NUMBER", {
              value: 1,
            })
          )
          .when(["type", "generateRangeType"], {
            is: (type, generateRangeType) =>
              type === SERIAL_NUMBER_TYPE_NUMBER &&
              generateRangeType === GENERATE_RANGE_TYPE_CUSTOM,
            then: Yup.number(),
            otherwise: Yup.number()
              .when("type", {
                is: SERIAL_NUMBER_TYPE_NUMBER,
                then: Yup.number().when("digitLength", {
                  is: (digitLength) => digitLength && digitLength !== "",
                  then: Yup.number().max(
                    batchRangeInfo.totalQuantity - batchRangeInfo.startingDigit,
                    getLang(
                      lang,
                      "message.error.FORM_VALIDATE_SN_GENERATE_AMOUNT",
                      {
                        value:
                          batchRangeInfo.totalQuantity -
                          batchRangeInfo.startingDigit,
                      }
                    )
                  ),
                }),
                otherwise: Yup.number().when("digitLength", {
                  is: (digitLength) => digitLength && digitLength !== "",
                  then: Yup.number().max(
                    availableQuantity,
                    getLang(
                      lang,
                      "message.error.FORM_VALIDATE_SN_GENERATE_AMOUNT",
                      { value: availableQuantity }
                    )
                  ),
                }),
              })
              .required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
          }),
        generateRanges: Yup.array().of(
          Yup.object().shape({
            from: Yup.number().when(["type", "generateRangeType"], {
              is: (type, generateRangeType) =>
                type === SERIAL_NUMBER_TYPE_NUMBER &&
                generateRangeType === GENERATE_RANGE_TYPE_CUSTOM,
              then: Yup.number().required(
                getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")
              ),
            }),
            to: Yup.number()
              .when(["type", "generateRangeType"], {
                is: (type, generateRangeType) =>
                  type === SERIAL_NUMBER_TYPE_NUMBER &&
                  generateRangeType === GENERATE_RANGE_TYPE_CUSTOM,
                then: Yup.number().required(
                  getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")
                ),
              })
              .moreThan(
                Yup.ref("from"),
                getLang(lang, "message.error.FORM_VALIDATE_SN_GERERATE_RANGES")
              ),
          })
        ),
        branch: Yup.string().required(
          getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")
        ),
      })}
      onSubmit={handleSubmit}
    >
      {(formik) => {
        const customRangeTotalQuantity = formik.values.generateRanges.reduce(
          (sum, { from, to }) => {
            const fromValue = parseInt(from);
            const toValue = parseInt(to);

            if (fromValue >= 0 && toValue > 0 && toValue > fromValue) {
              return sum + toValue - fromValue + 1;
            } else {
              return sum;
            }
          },
          0
        );

        const totalQuantity =
          formik.values.generateRangeType === GENERATE_RANGE_TYPE_CUSTOM
            ? customRangeTotalQuantity
            : formik.values.generateAmount;

        const tagExists =
          tags.filter((obj) => {
            const formikValues = formik.values;
            return (
              obj.format ===
              `${formikValues.prefix},${formikValues.digitLength},${formikValues.postfix},${formikValues.type}`
            );
          }).length === 0;

        return (
          <form onSubmit={formik.handleSubmit} className={classes.root}>
            <Box className={classes.content}>
              <Box
                p={{
                  xs: 2,
                  sm: 3,
                }}
                mx="auto"
              >
                <Grid container classes={{ root: classes.stepContainer }}>
                  <Grid item sm={1} xs={2}>
                    <Typography
                      align="center"
                      classes={{ root: classes.stepTitle }}
                      variant="subtitle1"
                      color="primary"
                    >
                      01
                    </Typography>
                  </Grid>
                  <Grid item lg={7} sm={11} xs={10}>
                    <Box
                      classes={{
                        root: classes.stepTitleSection,
                      }}
                    >
                      <Box>
                        <Typography
                          classes={{ root: classes.stepTitle }}
                          variant="subtitle1"
                          color="primary"
                          display="inline"
                        >
                          {getLang(lang, "label.BRANCH")}
                        </Typography>
                      </Box>
                    </Box>
                    <BranchSearch
                      value={formik.values.branch}
                      placeholder={""}
                      searchPlaceholder={getLang(
                        lang,
                        "placeholder.SEARCH_A_BRANCH_NAME"
                      )}
                      isClearable={true}
                      emptyMessage={getLang(
                        lang,
                        "message.info.NO_BRANCH_FOUND"
                      )}
                      dropdownItem={branchDropdown}
                      style={{
                        padding: "0.25em 0.75em",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        borderRadius: 4,
                        width: "100%",
                        overflow: "hidden",
                        height: 38,
                      }}
                      handleChange={(value) => {
                        formik.setFieldValue("branch", value);
                        loadProductDropdown(formik, value);
                      }}
                      handleClear={() => formik.setFieldValue("branch", "")}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      isFetching={false}
                      disabled={formik.isSubmitting}
                    />
                    {formik.touched["branch"] && formik.errors["branch"] ? (
                      <Box
                        color="red"
                        fontWeight="fontWeightLight"
                        fontSize={10}
                      >
                        {formik.errors["branch"]}
                      </Box>
                    ) : null}
                  </Grid>
                </Grid>
                <Divider />
                <Grid container classes={{ root: classes.stepContainer }}>
                  <Grid item sm={1} xs={2}>
                    <Typography
                      align="center"
                      classes={{ root: classes.stepTitle }}
                      variant="subtitle1"
                      color="primary"
                    >
                      02
                    </Typography>
                  </Grid>
                  <Grid item lg={7} sm={11} xs={10}>
                    <Box
                      classes={{
                        root: classes.stepTitleSection,
                      }}
                    >
                      <Box>
                        <Typography
                          classes={{ root: classes.stepTitle }}
                          variant="subtitle1"
                          color="primary"
                          display="inline"
                        >
                          {getLang(lang, "label.ASSIGN_PRODUCT")}
                        </Typography>
                        <Typography
                          classes={{ root: classes.optionalLabel }}
                          variant="subtitle1"
                          color="primary"
                          display="inline"
                        >
                          ({getLang(lang, "label.OPTIONAL")})
                        </Typography>
                      </Box>
                    </Box>
                    <ProductSearch
                      value={formik.values.product}
                      placeholder={""}
                      searchPlaceholder={getLang(
                        lang,
                        "placeholder.SEARCH_A_PRODUCT_NAME"
                      )}
                      isClearable={true}
                      emptyMessage={getLang(
                        lang,
                        "message.info.NO_PRODUCT_FOUND"
                      )}
                      dropdownItem={productsDropdown}
                      style={{
                        padding: "0.25em 0.75em",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        borderRadius: 4,
                        width: "100%",
                        overflow: "hidden",
                        height: 50,
                      }}
                      handleChange={(value) =>
                        formik.setFieldValue("product", value)
                      }
                      handleClear={() => formik.setFieldValue("product", "")}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      isFetching={isLoadingProductDropdown}
                      isBranchEmpty={formik.values.branch === ""}
                    />
                  </Grid>
                </Grid>
                <Divider />
                <Grid container classes={{ root: classes.stepContainer }}>
                  <Grid item sm={1} xs={2}>
                    <Typography
                      align="center"
                      classes={{ root: classes.stepTitle }}
                      variant="subtitle1"
                      color="primary"
                    >
                      03
                    </Typography>
                  </Grid>
                  <Grid item lg={7} sm={11} xs={10}>
                    <Box
                      classes={{
                        root: classes.stepTitleSection,
                      }}
                    >
                      <Typography
                        classes={{ root: classes.stepTitle }}
                        variant="subtitle1"
                        color="primary"
                        display="inline"
                      >
                        {getLang(lang, "label.SELECT_SERIAL_NUMBER_TYPE")}
                      </Typography>
                    </Box>
                    <FormGroup
                      classes={{
                        root: classes.toggleFormGroup,
                      }}
                    >
                      <FormControlLabel
                        classes={{
                          root: classes.toggleControl,
                        }}
                        control={
                          <AntSwitch
                            checked={
                              formik.values.type === SERIAL_NUMBER_TYPE_NUMBER
                            }
                            onChange={(e) => {
                              formik.setFieldValue(
                                "type",
                                SERIAL_NUMBER_TYPE_NUMBER
                              );
                              resetForm(formik);
                            }}
                            disabled={formik.isSubmitting}
                            classes={{
                              root: classes.toggleButton,
                            }}
                          />
                        }
                        label={
                          <Typography variant="body2" color="primary">
                            {getLang(lang, "label.RUNNING_NUMBER")}
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        classes={{
                          root: classes.toggleControl,
                        }}
                        control={
                          <AntSwitch
                            checked={
                              formik.values.type ===
                              SERIAL_NUMBER_TYPE_ALPHANUMERIC
                            }
                            onChange={(e) => {
                              formik.setFieldValue(
                                "type",
                                SERIAL_NUMBER_TYPE_ALPHANUMERIC
                              );
                              resetForm(formik);
                            }}
                            disabled={formik.isSubmitting}
                            classes={{
                              root: classes.toggleButton,
                            }}
                          />
                        }
                        label={
                          <Typography variant="body2" color="primary">
                            {getLang(lang, "label.RANDOM_ALPHANUMERIC")}
                          </Typography>
                        }
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
                <Divider />
                <Grid container classes={{ root: classes.stepContainer }}>
                  <Grid item sm={1} xs={2}>
                    <Typography
                      align="center"
                      classes={{ root: classes.stepTitle }}
                      variant="subtitle1"
                      color="primary"
                    >
                      04
                    </Typography>
                  </Grid>
                  <Grid item lg={7} sm={11} xs={10}>
                    <Box
                      classes={{
                        root: classes.stepTitleSection,
                      }}
                    >
                      <Typography
                        classes={{ root: classes.stepTitle }}
                        variant="subtitle1"
                        color="primary"
                        display="inline"
                      >
                        {getLang(lang, "label.SET_UP_PATTERN")}
                      </Typography>
                      {formik.values.type === SERIAL_NUMBER_TYPE_NUMBER && (
                        <TagSelect
                          tags={tags}
                          type={formik.values.type}
                          handleChange={({ prefix, postfix, digitLength }) => {
                            formik.setFieldValue("postfix", postfix);
                            formik.setFieldValue("digitLength", digitLength);
                            formik.setFieldValue("prefix", prefix);

                            fetchHistory({
                              prefix,
                              length: digitLength,
                              postfix,
                              type: formik.values.type,
                            });
                            setIsInputDisable(false);
                          }}
                          error={
                            formik.touched.tag && formik.errors.tag
                              ? true
                              : false
                          }
                          helperText={ErrorMessage({ name: "tag" })}
                          lang={lang}
                        />
                      )}
                    </Box>
                    <Grid
                      container
                      spacing={1}
                      classes={{
                        root: classes.patternSection,
                      }}
                    >
                      {formik.values.type !==
                        SERIAL_NUMBER_TYPE_ALPHANUMERIC && (
                        <Grid item xs={4}>
                          <Typography variant="subtitle2" color="primary">
                            {getLang(lang, "label.PREFIX")}
                          </Typography>
                          <InputTextField
                            {...formik.getFieldProps("prefix")}
                            hiddenLabel={true}
                            variant="filled"
                            fullWidth
                            margin="dense"
                            size="small"
                            onChange={(e) => {
                              const value = e.target.value.toLocaleUpperCase();
                              if (validateOnlyAlphabet(value)) {
                                formik.setFieldValue("prefix", value);
                              } else {
                                addAlert(
                                  getLang(
                                    lang,
                                    "message.warning.ONLY_ALPHABET_ALLOWED"
                                  )
                                );
                              }
                            }}
                            inputProps={{
                              onBlur: (e) => {
                                const { prefix, digitLength, postfix, type } =
                                  formik.values;

                                if (formik.values.digitLength !== "") {
                                  fetchHistory({
                                    prefix,
                                    length: digitLength,
                                    postfix,
                                    type,
                                  });
                                }
                              },
                            }}
                            error={
                              formik.touched.prefix && formik.errors.prefix
                                ? true
                                : false
                            }
                            helperText={ErrorMessage({ name: "prefix" })}
                            name="prefix"
                          />
                        </Grid>
                      )}
                      <Grid item xs={4}>
                        <Typography variant="subtitle2" color="primary">
                          {getLang(lang, "label.LENGTH")}
                        </Typography>
                        <InputTextField
                          {...formik.getFieldProps("digitLength")}
                          hiddenLabel={true}
                          variant="filled"
                          fullWidth
                          margin="dense"
                          type="number"
                          size="small"
                          onKeyDown={(e) => {
                            const invalidChars = ["-", "+", "e", "."];
                            if (invalidChars.includes(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          onChange={(e) => {
                            const value = e.target.value;

                            if (value > 9) {
                              addAlert(
                                getLang(
                                  lang,
                                  "message.warning.MAXIMUM_NINE_DIGITS"
                                )
                              );
                              formik.setFieldValue("digitLength", 9);
                            } else {
                              formik.setFieldValue("digitLength", value);
                            }
                            if (!!value) {
                              setIsInputDisable(false);
                            } else {
                              setIsInputDisable(true);
                            }
                          }}
                          inputProps={{
                            onBlur: () => {
                              const { prefix, digitLength, postfix, type } =
                                formik.values;

                              if (formik.values.digitLength !== "") {
                                fetchHistory({
                                  prefix,
                                  length: digitLength,
                                  postfix,
                                  type,
                                });
                              }
                            },
                            min: 1,
                            max: 10,
                          }}
                          error={
                            formik.touched.digitLength &&
                            formik.errors.digitLength
                              ? true
                              : false
                          }
                          helperText={ErrorMessage({ name: "digitLength" })}
                        />
                      </Grid>
                      {formik.values.type !==
                        SERIAL_NUMBER_TYPE_ALPHANUMERIC && (
                        <Grid item xs={4}>
                          <Typography variant="subtitle2" color="primary">
                            {getLang(lang, "label.POSTFIX")}
                          </Typography>
                          <InputTextField
                            {...formik.getFieldProps("postfix")}
                            hiddenLabel={true}
                            variant="filled"
                            fullWidth
                            margin="dense"
                            size="small"
                            onChange={(e) => {
                              const value = e.target.value.toLocaleUpperCase();

                              if (validateOnlyAlphabet(value)) {
                                formik.setFieldValue("postfix", value);
                              } else {
                                addAlert(
                                  getLang(
                                    lang,
                                    "message.warning.ONLY_ALPHABET_ALLOWED"
                                  )
                                );
                              }
                            }}
                            inputProps={{
                              onBlur: () => {
                                const { prefix, digitLength, postfix, type } =
                                  formik.values;

                                if (formik.values.digitLength !== "") {
                                  fetchHistory({
                                    prefix,
                                    length: digitLength,
                                    postfix,
                                    type,
                                  });
                                }
                              },
                            }}
                            error={
                              formik.touched.postfix && formik.errors.postfix
                                ? true
                                : false
                            }
                            helperText={ErrorMessage({ name: "postfix" })}
                            name="postfix"
                          />
                        </Grid>
                      )}
                    </Grid>
                    <Typography
                      variant="subtitle2"
                      color="primary"
                      style={{
                        marginBottom: 5,
                      }}
                    >
                      {getLang(lang, "label.PIN")}
                      <Tooltip
                        style={{
                          marginLeft: 5,
                          verticalAlign: "text-top",
                        }}
                        title={getLang(
                          lang,
                          "tooltips.VIEW_PIN_FROM_EXCEL_FILE"
                        )}
                        arrow
                      >
                        <HelpIcon className={classes.helpIcon} />
                      </Tooltip>
                    </Typography>
                    <FormGroup
                      classes={{
                        root: classes.toggleFormGroup,
                      }}
                    >
                      <FormControlLabel
                        classes={{
                          root: classes.toggleControl,
                        }}
                        control={
                          <AntSwitch
                            checked={formik.values.pinRequired}
                            onChange={(e) => {
                              formik.setFieldValue(
                                "pinRequired",
                                e.target.checked
                              );
                            }}
                            disabled={formik.isSubmitting}
                            classes={{
                              root: classes.toggleButton,
                            }}
                          />
                        }
                        label={
                          <Typography variant="body2" color="primary">
                            {getLang(lang, "label.REQUIRED_PIN_FOR_ACCESS")}
                          </Typography>
                        }
                      />
                    </FormGroup>
                    {formik.values.pinRequired && (
                      <FormGroup
                        classes={{
                          root: classes.toggleFormGroup,
                        }}
                      >
                        <FormControlLabel
                          classes={{
                            root: classes.toggleControl,
                          }}
                          control={
                            <AntSwitch
                              checked={formik.values.pinType === "random"}
                              onChange={(e) => {
                                formik.setFieldValue("pinType", "random");
                              }}
                              disabled={formik.isSubmitting}
                              classes={{
                                root: classes.toggleButton,
                              }}
                            />
                          }
                          label={
                            <Typography variant="body2" color="primary">
                              {getLang(lang, "label.SIX_DIGIT_RANDOM_PIN")}
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          classes={{
                            root: classes.toggleControl,
                          }}
                          control={
                            <AntSwitch
                              checked={formik.values.pinType === "equalsn"}
                              onChange={(e) => {
                                formik.setFieldValue("pinType", "equalsn");
                              }}
                              disabled={formik.isSubmitting}
                              classes={{
                                root: classes.toggleButton,
                              }}
                            />
                          }
                          label={
                            <Typography variant="body2" color="primary">
                              {getLang(lang, "label.USE_SN_AS_PIN")}
                            </Typography>
                          }
                        />
                        <FormHelperText
                          error={
                            formik.touched.pinType && formik.errors.pinType
                          }
                        >
                          <ErrorMessage name="pinType" />
                        </FormHelperText>
                      </FormGroup>
                    )}
                  </Grid>
                  {formik.values.digitLength &&
                    formik.values.type === SERIAL_NUMBER_TYPE_NUMBER && (
                      <Grid
                        item
                        lg={4}
                        xs={12}
                        style={{
                          paddingLeft: 20,
                        }}
                      >
                        <Box
                          style={{
                            border: "1px solid #0000001a",
                            backgroundColor: "#F9F9F9",
                            display: "flex",
                            flexDirection: "column",
                            height: "100%",
                            justifyContent: "center",
                          }}
                        >
                          <Box
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              padding: 20,
                            }}
                          >
                            <Typography
                              variant="subtitle2"
                              style={{
                                color: "#3a4d54a8",
                                paddingTop: 20,
                                paddingBottom: 5,
                              }}
                            >
                              {getLang(lang, "label.EXAMPLE_UPPERCASE")}
                            </Typography>
                            <Divider
                              style={{
                                width: 26,
                              }}
                            />
                            <Box
                              style={{
                                display: "flex",
                                width: "100%",
                                margin: "30px 0px",
                                textAlign: "center",
                                justifyContent: "center",
                              }}
                            >
                              {formik.values.prefix && (
                                <Box>
                                  <Typography
                                    classes={{
                                      root: classes.exampleSubtitle,
                                    }}
                                  >
                                    {getLang(lang, "label.PREFIX")}
                                  </Typography>
                                  <Typography
                                    color="primary"
                                    classes={{
                                      root: classes.exampleValue,
                                    }}
                                  >
                                    {formik.values.prefix}
                                  </Typography>
                                </Box>
                              )}
                              <Box
                                style={{
                                  margin: "0px 10px",
                                }}
                              >
                                <Typography
                                  classes={{
                                    root: classes.exampleSubtitle,
                                  }}
                                >
                                  {getLang(lang, "label.LENGTH")}
                                </Typography>
                                <Typography
                                  color="primary"
                                  classes={{
                                    root: classes.exampleValue,
                                  }}
                                >
                                  {renderExampleDigit(
                                    formik.values.type,
                                    formik.values.digitLength
                                  )}
                                </Typography>
                              </Box>
                              {formik.values.postfix && (
                                <Box>
                                  <Typography
                                    classes={{
                                      root: classes.exampleSubtitle,
                                    }}
                                  >
                                    {getLang(lang, "label.POSTFIX")}
                                  </Typography>
                                  <Typography
                                    color="primary"
                                    classes={{
                                      root: classes.exampleValue,
                                    }}
                                  >
                                    {formik.values.postfix}
                                  </Typography>
                                </Box>
                              )}
                            </Box>
                          </Box>
                          {tagExists && (
                            <Box mt="auto" width="100%">
                              <Divider />
                              <FormGroup
                                classes={{
                                  root: classes.toggleFormGroup,
                                }}
                                style={{
                                  justifyContent: "center",
                                }}
                              >
                                <FormControlLabel
                                  style={{
                                    margin: "10px",
                                  }}
                                  control={
                                    <AntSwitch
                                      checked={formik.values.savePattern}
                                      onChange={(e) => {
                                        formik.setFieldValue(
                                          "savePattern",
                                          e.target.checked
                                        );
                                      }}
                                      disabled={formik.isSubmitting}
                                      classes={{
                                        root: classes.toggleButton,
                                      }}
                                    />
                                  }
                                  label={
                                    <Typography
                                      style={{ fontSize: 12 }}
                                      color="primary"
                                    >
                                      {getLang(
                                        lang,
                                        "paragraph.SAVE_PATTERN_QUESTION"
                                      )}
                                    </Typography>
                                  }
                                />
                              </FormGroup>
                            </Box>
                          )}
                        </Box>
                      </Grid>
                    )}
                </Grid>
                <Divider />
                <Grid container classes={{ root: classes.stepContainer }}>
                  <Grid item sm={1} xs={2}>
                    <Typography
                      align="center"
                      classes={{ root: classes.stepTitle }}
                      variant="subtitle1"
                      color="primary"
                    >
                      05
                    </Typography>
                  </Grid>
                  <Grid item lg={7} sm={11} xs={10}>
                    <Box
                      classes={{
                        root: classes.stepTitleSection,
                      }}
                    >
                      <Typography
                        classes={{ root: classes.stepTitle }}
                        variant="subtitle1"
                        color="primary"
                        display="inline"
                      >
                        {formik.values.type === SERIAL_NUMBER_TYPE_NUMBER
                          ? getLang(lang, "label.SELECT_RANGE")
                          : getLang(lang, "label.ENTER_QUANTITY")}
                      </Typography>
                    </Box>
                    {formik.values.type === SERIAL_NUMBER_TYPE_NUMBER && (
                      <FormGroup
                        classes={{
                          root: classes.toggleFormGroup,
                        }}
                      >
                        <FormControlLabel
                          classes={{
                            root: classes.toggleControl,
                          }}
                          control={
                            <AntSwitch
                              checked={
                                formik.values.generateRangeType ===
                                GENERATE_RANGE_TYPE_LAST_NUMBER
                              }
                              onChange={(e) => {
                                formik.setFieldValue(
                                  "generateRangeType",
                                  GENERATE_RANGE_TYPE_LAST_NUMBER
                                );
                                setIsSameRange(false);
                                setIsExceedLimit(false);
                                resetRange(formik);
                              }}
                              disabled={formik.isSubmitting}
                              classes={{
                                root: classes.toggleButton,
                              }}
                            />
                          }
                          label={
                            <Typography variant="body2" color="primary">
                              {getLang(lang, "label.LAST_GENERATED_NO")}
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          classes={{
                            root: classes.toggleControl,
                          }}
                          control={
                            <AntSwitch
                              checked={
                                formik.values.generateRangeType ===
                                GENERATE_RANGE_TYPE_CUSTOM
                              }
                              onChange={(e) => {
                                formik.setFieldValue(
                                  "generateRangeType",
                                  GENERATE_RANGE_TYPE_CUSTOM
                                );
                                resetRange(formik);
                              }}
                              disabled={formik.isSubmitting}
                              classes={{
                                root: classes.toggleButton,
                              }}
                            />
                          }
                          label={
                            <Typography variant="body2" color="primary">
                              {getLang(lang, "label.CUSTOM_RANGE")}
                            </Typography>
                          }
                        />
                      </FormGroup>
                    )}
                    {formik.values.generateRangeType ===
                      GENERATE_RANGE_TYPE_LAST_NUMBER && (
                      <LastGeneratePanel
                        formik={formik}
                        startingDigit={batchRangeInfo.startingDigit}
                        maxQuantity={
                          formik.values.type === SERIAL_NUMBER_TYPE_NUMBER
                            ? batchRangeInfo.totalQuantity -
                              batchRangeInfo.startingDigit
                            : availableQuantity
                        }
                        lang={lang}
                        isInputDisable={isInputDisable}
                      />
                    )}
                    {formik.values.generateRangeType ===
                      GENERATE_RANGE_TYPE_CUSTOM && (
                      <CustomRangePanel
                        formik={formik}
                        batchRangeInfo={batchRangeInfo}
                        totalQuantity={customRangeTotalQuantity}
                        isSameRange={isSameRange}
                        setIsSameRange={setIsSameRange}
                        lang={lang}
                        isExceedLimit={isExceedLimit}
                        setIsExceedLimit={setIsExceedLimit}
                        isInputDisable={isInputDisable}
                      />
                    )}
                  </Grid>
                </Grid>
                {hasBatchAuthenticatorEditRight && (
                  <>
                    <Divider />
                    <Grid container classes={{ root: classes.stepContainer }}>
                      <Grid item sm={1} xs={2}>
                        <Typography
                          align="center"
                          classes={{ root: classes.stepTitle }}
                          variant="subtitle1"
                          color="primary"
                        >
                          06
                        </Typography>
                      </Grid>
                      <Grid item lg={7} sm={11} xs={10}>
                        <Box
                          classes={{
                            root: classes.stepTitleSection,
                          }}
                        >
                          <Typography
                            classes={{ root: classes.stepTitle }}
                            variant="subtitle1"
                            color="primary"
                            display="inline"
                          >
                            {getLang(lang, "label.USE_APP_AUTHENTICATOR")}
                          </Typography>
                        </Box>
                        <FormControlLabel
                          classes={{
                            root: classes.toggleControl,
                          }}
                          control={
                            <AntSwitch
                              checked={formik.values.appAuthrorizeRequire}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  "appAuthrorizeRequire",
                                  e.target.checked
                                );
                              }}
                              disabled={formik.isSubmitting}
                              classes={{
                                root: classes.toggleButton,
                              }}
                            />
                          }
                          label={
                            <Typography variant="body2" color="primary">
                              {getLang(lang, "label.REQUIRED_APP_ACCESS")}
                            </Typography>
                          }
                        />
                      </Grid>
                    </Grid>
                  </>
                )}
              </Box>
              <Divider />
              <Box p={2} display="flex" justifyContent="space-between">
                <Button
                  variant="text"
                  classes={{ root: classes.discardButton }}
                  onClick={handleDiscard}
                >
                  {getLang(lang, "label.DISCARD")}
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  classes={{ root: classes.submitButton }}
                  onClick={() => {
                    if (
                      registrationType !== 3 &&
                      (qrCredit <= 0 || qrCredit - totalQuantity < 0)
                    ) {
                      setShowCreditErrorDialog(true);
                    } else {
                      if (!isSameRange && !isExceedLimit) {
                        formik.validateForm().then((errors) => {
                          if (Object.keys(errors).length === 0) {
                            setIsOpen(true);
                          } else {
                            formik.setTouched({ ...{}, ...errors });
                          }
                        });
                      }
                    }
                  }}
                  disabled={formik.isSubmitting}
                >
                  {getLang(lang, "label.GENERATE")}
                </Button>
              </Box>
            </Box>
            <ConfirmationDialog
              qrCredit={qrCredit}
              registrationType={registrationType}
              title={getLang(lang, "label.CONFIRMATION_NEEDED")}
              quantity={totalQuantity}
              remarks={getLang(lang, "paragraph.PROCESS_IRREVERSIBLE")}
              isLoading={formik.isSubmitting || isLoading}
              isOpen={isOpen}
              handleClose={() => setIsOpen(false)}
              handleProceed={() => formik.submitForm()}
            />
          </form>
        );
      }}
    </Formik>
  );
}
