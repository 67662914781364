/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import BranchInGroupSelectComponent from "./branchInGroupSelect.component";
import { selectLoading } from "modules/notification";
import BranchInGroupSelectPanelComponent from "./branchInGroupSelectPanel.component";
import { useSelector, useDispatch } from "react-redux";
import { getBranchesInGroup } from "modules/group/redux";
import { Box } from "@material-ui/core";

function BranchInGroupSelectContainer({
  handleChange,
  disabled,
  value,
  styles,
  textStyles,
  placeholder,
  id,
}) {
  const branchDropdown = useSelector(
    (state) => state.branchGroup.branchesInGroup
  );
  const isFetching = useSelector((state) =>
    selectLoading(state, getBranchesInGroup.typePrefix)
  );

  const lang = useSelector((state) => state.constant.languages);
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    dispatch(getBranchesInGroup(id));
  }, [search, dispatch]);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setSearch("");
    setAnchorEl(null);
  };

  const handleSelectBranch = (branch) => {
    handleChange(branch);
  };

  const handleSearch = (value) => {
    setSearch(value);
  };

  return (
    <Box position="relative" width="100%">
      <BranchInGroupSelectComponent
        disabled={disabled}
        value={value}
        handleClick={handleOpen}
        styles={styles}
        textStyles={textStyles}
        placeholder={placeholder}
        lang={lang}
      />
      <BranchInGroupSelectPanelComponent
        value={value}
        handleClose={handleClose}
        isFetching={isFetching}
        branchDropdown={branchDropdown}
        handleSelectBranch={handleSelectBranch}
        handleSearch={handleSearch}
        lang={lang}
        anchorEl={anchorEl}
        open={open}
      />
    </Box>
  );
}

export default BranchInGroupSelectContainer;
