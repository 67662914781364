import React, { useEffect, useState } from "react"
import { cloneDeep } from 'lodash'
import { useSelector } from "react-redux"
import { selectLoading } from 'modules/notification'
import StockItemListPanelComponent from "./stockoutItemListPanel.component"
import { getStockoutItemList } from "modules/stockflow/redux"

export default function StockItemListPanelContainer() {
  const lang = useSelector(state => state.constant.languages)
  const stockoutItems = useSelector(state => state.stockflowStockout.stockoutItems)
  const isFetching = useSelector(state => selectLoading(state, getStockoutItemList.typePrefix))

  const [itemList, setItemList] = useState({
    boxes: [],
    packages: []
  })
  
  useEffect(() => {
    setItemList(stockoutItems)
  }, [stockoutItems])

  const handleSearch = (values) => {
    if (values.keyword) {
      let keyword = values.keyword.toLowerCase()
      let boxList = cloneDeep(stockoutItems.boxes)
      let productList = cloneDeep(stockoutItems.packages)
      
      // search by product
      let filteredBoxList = boxList.reduce((filtered, box) => {
        let index = box.products.findIndex(p => p.name.toLowerCase().includes(keyword))
        if (index >= 0) {
          box.products = box.products.filter(p => p.name.toLowerCase().includes(keyword))
          filtered.push(box)
        }
        return filtered
      }, [])

      let filteredProductList = productList.filter(product => 
        !!product.name.toLowerCase().includes(keyword)
      )

      // search by package
      if (!filteredBoxList.length) {
        filteredBoxList = boxList.reduce((filtered, box) => {
          let list = box.products.reduce((list, product) => {
            let index = product.packages.findIndex(p => p.name.toLowerCase().includes(keyword))
            if (index >= 0) {
              product.packages = product.packages.filter(p => p.name.toLowerCase().includes(keyword))
              list.push(product)
            }
            return list
          }, [])

          if (list.length) {
            box.products = list
            filtered.push(box)
          }
          return filtered
        }, [])
      } 

      if (!filteredProductList.length) {
        filteredProductList = productList.reduce((filtered, product) => {
          let index = product.packages.findIndex(p => p.name.toLowerCase().includes(keyword))
          if (index >= 0) {
            product.packages = product.packages.filter(p => p.name.toLowerCase().includes(keyword))
            filtered.push(product)
          }
          return filtered
        }, [])
      }

      // search box
      if (!filteredBoxList.length) {
        filteredBoxList = boxList.filter(box =>
          !!box.name.toLowerCase().includes(keyword)
        )
      }

      setItemList({
        boxes: filteredBoxList,
        packages: filteredProductList
      })
    } else {
      setItemList(stockoutItems)
    }
  }
  
  return (
    <StockItemListPanelComponent
      stockoutItems={itemList}
      isFetching={isFetching}
      handleSearch={handleSearch}
      lang={lang}
    />
  )
}