import React from "react";
import {
  Box,
  Button,
  makeStyles,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Checkbox,
  Link,
} from "@material-ui/core";
import {
  StyledTableCell,
  StyledTableRow,
  StickyRightTableCell,
  StickyLeftTableCell,
} from "components/table";
import { SkeletonTableRowStickyLeftRight } from "components/skeleton/skeleton.component";
import { ActionButton } from "components/button";
import { TablePaginationActions } from "components/tablePagination";
import RefreshTable from "components/table/refreshTable";
import clsx from "clsx";
import { TIER_ICON_PROPS, DEALER_STATUS } from "modules/stockflow/constants";
import SelectAllCheckBox from 'components/selectAllCheckbox';
import { convertToBigNum } from "lib/generalUtility";
import { getLang } from "app/feature/constants";
import moment from "moment";
import "moment/min/locales.min";

const useStyle = makeStyles((theme) => ({
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    borderCollapse: "separate",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
    },
    "& th,td": {
      fontSize: ".75rem",
      borderRight: "1px solid rgba(58, 77, 84, 0.1)",
      padding: theme.spacing(1, 2, 1, 2),
    },
    "& th": {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightBold,
    },
    "& th:last-child, td:last-child": {
      width: 37,
      borderRight: "0px",
    },
    "& th th": {
      borderBottom: "none !important",
    },
    "& td td": {
      borderBottom: "none !important",
    },
  },
  tableContainer: {
    maxHeight: 500,
    overflowY: "auto",
  },
  emptyContainer: {
    backgroundColor: theme.palette.background.paper,
    textAlign: "center",
  },
  button: {
    display: "flex",
    alignItems: "center",
    padding: 0,
    minWidth: 0,
    marginLeft: theme.spacing(1),
  },
  profilePhoto: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #E4E7EC",
    borderRadius: "50%",
    width: "24px",
    height: "24px",
    marginRight: "0.75em",
    "& img": {
      borderRadius: "50%",
      height: "24px",
      width: "24px"
    },
  },
  hidden: {
    display: "none",
  },
  show: {
    display: "flex",
  },
  actionContainer: {
    justifyContent: "center",
  },
  paperRoot: {
    width: "300px",
  },
  userTableRow: {
    display: "flex",
    alignItems: "center"
  },
  ellipsisText: {
    maxWidth: "18em",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: "0.75rem"
  },
  tierIcon: {
    borderRadius: "3px",
    marginRight: "0.75em",
    paddingLeft: "0.15em",
    paddingRight: "0.15em",
    display: "flex",
    alignItems: "center"
  },
  tierLabel: {
    color: "#FFFFFF",
    fontSize: "0.6rem"
  },
  selectAll: {
    '&.Mui-checked': {
      color: "#FDB022",
    },
  },
  selectOne: {
    '&.Mui-checked': {
      color: "#6AAF68",
    },
  }
}));

export default function MyDealerListTableComponent({
  hasStockflowDealerViewAccessRight,
  hasStockflowDealerEditAccessRight,
  hasStockflowDealerAddAccessRight,
  rows,
  rowsPerPage,
  paginationOptions,
  page,
  totalFiltered,
  isFetching,
  isError,
  isSearchResult,
  language,
  handleReload,
  handleViewDetail,
  handleResendEmail,
  handleReplaceDealerDialog,
  handleEditPointDialog,
  handleChangePage,
  handleChangeRowPerPage,
  handleUpdateDealerStatus,
  handleVisibleInviteDialog,
  handleLoadIncentivePointDialog,
  handleSelectAllDealer,
  handleSelectAvailable,
  handleDeselectAll,
  handleSelectDealer,
  isSelectAll,
  selectedUUIDs,
  lang,
  isDealerPage,
  addSuccessAlert,
}) {
  const classes = useStyle();


  if(language==="ZH")
  {
    moment.locale('zh-cn')
  }else if(language==="TL")
  {
    moment.locale('tl-ph')
  }else
  {
    moment.locale(language)
  }

  const selectAllItems = [
    {
      label: `${getLang(lang, 'label.SELECT_AVAILABLE_DEALERS', { available: rows.length > rowsPerPage ? rowsPerPage : rows.length })} `,
      action: () => handleSelectAvailable(),
    },
    {
      label: getLang(lang, 'label.SELECT_ALL_DEALERS'),
      action: () => handleSelectAllDealer(),
    },
    {
      label: getLang(lang, 'label.DESELECT_ALL_DEALERS'),
      action: () => handleDeselectAll(),
      divider: true,
    },
  ];

  function copyLink(link) {
    navigator.clipboard.writeText(link);
    addSuccessAlert(getLang(lang, "message.success.LINK_COPIED_TO_CLIPBOARD"));
  }

  return (
    <>
      {!isFetching && isError && <RefreshTable handleReload={handleReload} />}
      <Box>
        <TableContainer>
          <Table
            className={classes.table}
            aria-label="product table"
            size="small"
            stickyHeader
          >
            <TableHead style={{ height: "45px" }}>
              <TableRow>
                {!isDealerPage && (
                  <StickyLeftTableCell>
                    <StyledTableCell padding="checkbox">
                      <SelectAllCheckBox
                        actionItems={selectAllItems}
                        disabled={isFetching}
                        isSelectAll={isSelectAll}
                        selectedIdLength={selectedUUIDs.length}
                        totalFiltered={totalFiltered}
                      />
                    </StyledTableCell>
                  </StickyLeftTableCell>
                )}
                <StyledTableCell style={{ minWidth: "18rem" }}>
                  {getLang(lang, 'label.NAME')}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, 'label.INCENTIVE_POINT')}
                </StyledTableCell>
                {!isDealerPage && (
                  <>
                    <StyledTableCell style={{ minWidth: "12rem" }}>
                      {getLang(lang, 'label.BRANCH')}
                    </StyledTableCell>
                    <StyledTableCell style={{ minWidth: "18rem" }}>
                      {getLang(lang, 'label.UPLINE')}
                    </StyledTableCell>
                  </>
                )}
                <StyledTableCell style={{ minWidth: "8rem" }}>
                  {getLang(lang, 'label.LAST_ACTIVE_DATE')}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, 'label.DOWNLINES')}
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    width: "14rem",
                    borderRight: "",
                  }}
                >
                  {getLang(lang, 'label.STATUS')}
                </StyledTableCell>
                {!isDealerPage && (
                  <StickyRightTableCell>
                    <StyledTableCell
                      style={{ width: "67px", textAlign: "center" }}
                    >
                      {getLang(lang, 'label.ACTION')}
                    </StyledTableCell>
                  </StickyRightTableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {isFetching
                ? [...new Array(5)].map((v, index) => (
                  <SkeletonTableRowStickyLeftRight key={index} columnCount={isDealerPage ? 5 : 9} />
                ))
                : rows.map((row, index) => {
                  const actionItems = [];

                  actionItems.push({
                    label: hasStockflowDealerEditAccessRight ? getLang(lang, 'label.VIEW_EDIT') : getLang(lang, 'label.VIEW'),
                    action: () => handleViewDetail(row.uuid)
                  });
                  if (row.status.toLowerCase() === "pending") {
                    actionItems.push({
                      label: "Resend Email",
                      action: () => handleResendEmail(row.email)
                    });
                    actionItems.push({
                      label: "Copy Verification Link",
                      action: () => copyLink(row.emailVerificationLink),
                    });
                  }
                  if (hasStockflowDealerEditAccessRight) {
                    // actionItems.push({
                    //   label: "Replace Dealer",
                    //   action: () => handleReplaceDealerDialog(row.uuid)
                    // });
                    if (row.status.toLowerCase() !== "pending") {
                      actionItems.push({
                        label: getLang(lang, 'label.EDIT_INCENTIVE_POINT'),
                        action: () => handleLoadIncentivePointDialog(row.uuid)
                      });
                    }
                    if (row.status.toLowerCase() === "active") {
                      actionItems.push({
                        label: getLang(lang, 'label.DEACTIVATE'),
                        action: () => handleUpdateDealerStatus(row.uuid, false),
                        divider: true,
                        errorText: true
                      });
                    }
                    if (row.status.toLowerCase() === "inactive") {
                      actionItems.push({
                        label: getLang(lang, 'label.ACTIVATE'),
                        action: () => handleUpdateDealerStatus(row.uuid, true),
                        divider: true,
                        errorText: true
                      });
                    }
                  }

                  return (
                    <StyledTableRow
                      key={row.uuid}
                      hover
                      role="checkbox"
                      tabIndex={-1}
                    >
                      {!isDealerPage && (
                        <StickyLeftTableCell>
                          <StyledTableCell padding="checkbox" style={{ paddingLeft: "13px" }}>
                            <Checkbox
                              className={clsx({
                                [classes.selectAll]: isSelectAll,
                                [classes.selectOne]: row.isSelected && isSelectAll === false
                              })}
                              checked={row.isSelected}
                              onChange={(event) => {
                                handleSelectDealer(index, event.target.checked)
                              }}
                            />
                          </StyledTableCell>
                        </StickyLeftTableCell>
                      )}
                      <StyledTableCell>
                        <Box className={classes.userTableRow}>
                          <Box
                            className={classes.tierIcon}
                            style={{
                              backgroundColor: TIER_ICON_PROPS.find(({ tier }) => tier === row.tier).color
                            }}
                          >
                            <Typography className={classes.tierLabel}>
                              T{row.tier}
                            </Typography>
                          </Box>
                          <Box className={classes.profilePhoto}>
                            <img src={row.picture} alt={row.name} />
                          </Box>
                          {hasStockflowDealerViewAccessRight ? (
                            <Link
                              href={ `/admin/stockflow/my-dealer/${row.uuid}`}
                              color="secondary"
                              onClick={(e) => {
                                handleViewDetail(row.uuid)
                                e.preventDefault();
                                return false;
                              }}
                            >
                              <Typography className={classes.ellipsisText}>{row.name}</Typography>
                            </Link>
                          ) : (
                            <Typography className={classes.ellipsisText}>{row.name}</Typography>
                          )}
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell style={{ textAlign: "right" }}>
                        <Typography className={classes.ellipsisText}>{convertToBigNum(row.incentivePoint)}</Typography>
                      </StyledTableCell>
                      {!isDealerPage && (
                        <>
                          <StyledTableCell>
                            <Typography className={classes.ellipsisText}>{row.branchName}</Typography>
                          </StyledTableCell>
                          <StyledTableCell>
                            {
                              row.upline !== null ? (
                                <Box className={classes.userTableRow}>
                                  <Box
                                    className={classes.tierIcon}
                                    style={{
                                      backgroundColor: TIER_ICON_PROPS.find(({ tier }) => tier === row.upline.tier).color
                                    }}
                                  >
                                    <Typography className={classes.tierLabel}>
                                      T{row.upline.tier}
                                    </Typography>
                                  </Box>
                                  <Box className={classes.profilePhoto}>
                                    <img src={row.upline.picture} alt={row.upline.name} />
                                  </Box>
                                  {hasStockflowDealerViewAccessRight ? (
                                    <Link
                                      // href={ `/admin/stockflow/my-dealer/${row.upline.uuid}`}
                                      color="secondary"
                                      onClick={(e) => {
                                        handleViewDetail(row.upline.uuid)
                                        e.preventDefault();
                                        return false;
                                      }}
                                    >
                                      <Typography className={classes.ellipsisText}>{row.upline.name}</Typography>
                                    </Link>
                                  ) : (
                                    <Typography className={classes.ellipsisText}>{row.upline.name}</Typography>
                                  )}
                                </Box>
                              ) : (
                                <span>-</span>
                              )
                            }
                          </StyledTableCell>
                        </>
                      )}
                      <StyledTableCell>
                        {
                          row.lastActiveDate !== null ? (
                            <span>{moment(row.lastActiveDate).format('ll')}</span>
                          ) : (
                            <span>-</span>
                          )
                        }
                      </StyledTableCell>
                      <StyledTableCell style={{ textAlign: "right" }}>
                        <span>{row.downlineCount.toLocaleString("en-US")}</span>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Typography
                          style={{
                            color: DEALER_STATUS.find(({ code }) => code === row.status.toLowerCase()).color,
                            fontSize: "0.75rem"
                          }}
                        >
                          {/* {DEALER_STATUS.find(({ code }) => code === row.status.toLowerCase()).text} */}
                          {row.status === "ACTIVE" ? getLang(lang, 'label.ACTIVATED') : row.status === "INACTIVE" ? getLang(lang, 'label.DEACTIVATED') : getLang(lang, 'label.PENDING')}
                          {/* QUICK FIX FOR LANGUAGES TRANSLATION */}
                        </Typography>
                      </StyledTableCell>
                      {!isDealerPage && (
                        <StickyRightTableCell>
                          <StyledTableCell style={{ width: "67px" }}>
                            <div
                              className={clsx(classes.actionContainer, classes.show)}
                            >
                              <ActionButton
                                actionItems={actionItems}
                                popperStyle={{ zIndex: 999 }}
                              />
                            </div>
                          </StyledTableCell>
                        </StickyRightTableCell>
                      )}
                    </StyledTableRow>
                  )
                })
              }
            </TableBody>
          </Table>
        </TableContainer>
        {!isFetching && !isError && rows.length !== 0 && (
          <TablePagination
            style={{
              backgroundColor: "#fff",
              boxShadow: "0px -1px 0px rgba(58, 77, 84, 0.15)",
            }}
            ActionsComponent={TablePaginationActions}
            rowsPerPageOptions={paginationOptions}
            component="div"
            count={totalFiltered}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowPerPage}
            labelRowsPerPage={getLang(lang, 'label.ROWS_PER_PAGE')}
            labelDisplayedRows={({ from, to, count }) => `${from.toLocaleString("en-US")}-${to.toLocaleString("en-US")} ${getLang(lang, 'label.OF')} ${count.toLocaleString("en-US")}`}
          />
        )}
        {!isFetching && !isError && rows.length === 0 && (
          <Box>
            <Box py={9} className={classes.emptyContainer}>
              <Typography variant="body1" style={{ paddingBottom: "16px" }}>
                {isDealerPage ? getLang(lang, 'paragraph.NO_DOWNLINE')
                  : isSearchResult
                    ? getLang(lang, 'message.info.SEARCH_RESULT_IS_EMPTY')
                    : getLang(lang, 'message.info.YOU_DONT_HAVE_DEALER')}
              </Typography>
              {hasStockflowDealerAddAccessRight && !isSearchResult && (
                <Button
                  onClick={() => handleVisibleInviteDialog(true)}
                  variant="contained"
                  disableElevation
                  color="primary"
                >
                  {getLang(lang, 'label.INVITE_NEW_DEALER')}
                </Button>
              )}
            </Box>
          </Box>
        )}
      </Box>
    </>
  )
}
