import React, { useEffect, useState } from "react";
import InfoSettingProfilePanelComponent from "./infoSettingProfilePanel.component";
import { selectLoading } from "modules/notification";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { hideBackdrop, showBackdrop } from "modules/backdrop";
import EditEmployeeDialog from "../../dialog/editEmployeeDialog";
import {
  fetchEmployeeDetail,
  fetchBranchDropdown
} from "modules/employee/redux";
import { EMPLOYEE_EDIT } from "lib/constants/accessRights";

function InfoSettingProfilePanelContainer() {
  const dispatch = useDispatch();
  const history = useHistory();
  const hasEmployeeEditAccessRight = useSelector(state => state.profile.accessRights.includes(EMPLOYEE_EDIT));
  const currentUserUuid = useSelector(state => state.profile.uuid)
  const [editEmployeeDialogOpen, setEditEmployeeDialogOpen] = useState(false);
  const employeeDetail = useSelector((state) => state.employee.employeeDetail);
  const isFetching = useSelector((state) =>
    selectLoading(state, fetchEmployeeDetail.typePrefix)
  );
  const timezone = useSelector(state => state.constant.timezones);
  const id = history.location.pathname.substring(
    history.location.pathname.lastIndexOf("/") + 1
  );
  const lang = useSelector(state => state.constant.languages);

  useEffect(() => {
    dispatch(fetchBranchDropdown());
  }, [id, dispatch]);

  const handleEdit = async () => {
    if(hasEmployeeEditAccessRight){
      dispatch(showBackdrop());
      dispatch(hideBackdrop());
      setEditEmployeeDialogOpen(true);
    }
  };

  return (
    <>
      <InfoSettingProfilePanelComponent
        hasEmployeeEditAccessRight={hasEmployeeEditAccessRight}
        currentUserUuid={currentUserUuid}
        isFetching={isFetching}
        employeeDetail={employeeDetail}
        handleEdit={handleEdit}
        timezone={timezone}
        lang={lang}
      />
      <EditEmployeeDialog
        isOpen={editEmployeeDialogOpen}
        handleClose={() => setEditEmployeeDialogOpen(false)}
      />
    </>
  );
}

export default InfoSettingProfilePanelContainer;
