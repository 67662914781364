import { selectLoading } from "modules/notification";
import { getProductBrandData, getScanAgainstSuspendedData, getTotalScanTrending, getTotalSuspendedTrending } from "modules/reporting/redux";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProductBrandPanel from './productBrand.component';

export default function ProductBrandContainer({
    currentFilter,
    urlTotalScan,
    urlTotalSuspended,
    urlTotalDistributionPerformance,
    urlBrand,
    pageType,
    translate,
    urlScanAgainstSuspended
}) {
    const dispatch = useDispatch();
    const dispatchTrending = useDispatch();
    const dispatchTable = useDispatch();

    // (1) trending data -- start
    // (a) get trending: total scan qr data
    const isFetchingTotalScanTrending = useSelector(state => selectLoading(state, getTotalScanTrending.typePrefix));
    const totalScanTrendingData = useSelector(state => state.reporting.trending.totalScanData);

    // (b) get trending: total suspended qr data
    const isFetchingTotalSuspendedTrending = useSelector(state => selectLoading(state, getTotalSuspendedTrending.typePrefix));
    const totalSuspendedTrendingData = useSelector(state => state.reporting.trending.totalSuspendedData);

    // (c) get trending: total distribution performance data
    // const isFetchingTotalDistributionPerformance = useSelector(state => selectLoading(state, getTotalDistributionPerformance.typePrefix));
    // const totalDistributionPerformanceData = useSelector(state => state.reporting.trending.totalDistributionPerformanceData);
    // (1) trending data -- end

    // (2) Product Brand data -- start
    const isFetchingProductBrand = useSelector(state => selectLoading(state, getProductBrandData.typePrefix));
    const productBrandData = useSelector(state => state.reporting.product.productBrandDatalist);
    // (2) Product Brand data -- end

    const isFetchingScanAgainstSuspendedData = useSelector(state => selectLoading(state, getScanAgainstSuspendedData.typePrefix));
    const scanAgainstSuspendedData = useSelector(state => state.reporting.trending.scanAgainstSuspendedData);


    const [tableFilter, setTableFilter] = useState({
        page: 0,
        rowsPerPage: 25,
        search: "",
        searchBy: "name",
        orderBy: "total_count",
        orderDirection: "desc",
    })

    useEffect(() => {
        dispatchTrending(getTotalScanTrending({
            branch_uuid: currentFilter.branchUuid,
            brand_uuid: currentFilter.brandUuid,
            countryCode: currentFilter.countryCode,
            city_uuid: currentFilter.cityUuid,
            period: currentFilter.period,
            start_date: moment(currentFilter.dateRange[0]).format('YYYY-MM-DD'),
            end_date: moment(currentFilter.dateRange[1]).format('YYYY-MM-DD'),
            url: urlTotalScan
        }));
        
        dispatchTrending(getTotalSuspendedTrending({
            branch_uuid: currentFilter.branchUuid,
            brand_uuid: currentFilter.brandUuid,
            countryCode: currentFilter.countryCode,
            city_uuid: currentFilter.cityUuid,
            period: currentFilter.period,
            start_date: moment(currentFilter.dateRange[0]).format('YYYY-MM-DD'),
            end_date: moment(currentFilter.dateRange[1]).format('YYYY-MM-DD'),
            url: urlTotalSuspended
        }));
        
        // dispatchTrending(getTotalDistributionPerformance({
        //     branch_uuid: currentFilter.branchUuid,
        //     countryCode: currentFilter.countryCode,
        //     city_uuid: currentFilter.cityUuid,
        //     brand_uuid: currentFilter.brandUuid,
        //     period: currentFilter.period,
        //     start_date: moment(currentFilter.dateRange[0]).format('YYYY-MM-DD'),
        //     end_date: moment(currentFilter.dateRange[1]).format('YYYY-MM-DD'),
        //     url: urlTotalDistributionPerformance
        // }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatchTrending, currentFilter])

    useEffect(() => {
        dispatchTable(getProductBrandData({
            branch_uuid: currentFilter.branchUuid,
            countryCode: currentFilter.countryCode,
            city_uuid: currentFilter.cityUuid,
            brand_uuid: currentFilter.brandUuid,
            period: currentFilter.period,
            start_date: moment(currentFilter.dateRange[0]).format('YYYY-MM-DD'),
            end_date: moment(currentFilter.dateRange[1]).format('YYYY-MM-DD'),
            page: tableFilter.page,
            rowsPerPage: tableFilter.rowsPerPage,
            search: tableFilter.search,
            searchBy: tableFilter.searchBy,
            orderBy: tableFilter.orderBy,
            orderDirection: tableFilter.orderDirection,
            url: urlBrand
        }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatchTable, currentFilter, tableFilter]) 

    useEffect(() => {
        dispatch(getScanAgainstSuspendedData({
            url: urlScanAgainstSuspended,
            countryCode: currentFilter.countryCode,
            city_uuid: currentFilter.cityUuid,
            branch_uuid: currentFilter.branchUuid,
            category_uuid: currentFilter.categoryUuid,
            model_uuid: currentFilter.modelUuid,
            brand_uuid: currentFilter.brandUuid,
            product_uuid: currentFilter.productUuid,
            period: currentFilter.period,
            start_date: currentFilter.dateRange[0],
            end_date: currentFilter.dateRange[1]
        }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, currentFilter])

    const handleChangePage = (e, newPage) => {
        setTableFilter((prev) => ({
          ...prev,
          page: newPage,
        }))
    }

    const handleChangeRowPerPage = (event) => {
        setTableFilter((prev) => ({
          ...prev,
          page: 0,
          rowsPerPage: event.target.value
        }))
    }

    const handleRequestSort = (event, property) => {
        const isAsc = tableFilter.orderBy === property && tableFilter.orderDirection === 'asc';
        setTableFilter((prev) => ({
            ...prev,
            orderDirection: isAsc ? 'desc' : 'asc',
            orderBy: property,
        }))
    }

    const columns = [
        {
            title: translate("label.NUM"),
            field: null,
            render : (row) => {
                return (
                    <>{row.title}</>
                )
            },
            align: null
        },
        {
            title: translate("label.BRANDS"),
            field: null,
            render : (row) => {
                return (
                    <>{row.title}</>
                )
            },
            size: 'medium'

        },
        {
            title: translate("label.TOTAL_COUNT"),
            field: 'total_count',
            render : (row) => {
                return (
                    <>{row.title}</>
                )
            },
            size: 'small'
        },
        {
            title: translate("label.SCAN_WEIGHTAGE"),
            field: null,
            render : (row) => {
                return (
                    <>{row.title}</>
                )
            },
            size: 'small'
        },
        {
            title: translate("label.SUSPENDED"),
            field: 'total_suspended',
            render : (row) => {
                return (
                    <>{row.title}</>
                )
            },
            size: 'small'
        }
    ];

    return (
        <ProductBrandPanel
            status={{
                isFetchingTotalScanTrending,
                isFetchingTotalSuspendedTrending,
                // isFetchingTotalDistributionPerformance,
                isFetchingProductBrand,
                isFetchingScanAgainstSuspendedData
            }}

            data={{
                totalScanTrendingData,
                totalSuspendedTrendingData,
                // totalDistributionPerformanceData,
                productBrandData,
                scanAgainstSuspendedData
            }}

            sort={{
                valueToOrderBy: tableFilter.orderBy,
                orderDirection: tableFilter.orderDirection,
                handleRequestSort: handleRequestSort,
            }}

            pagination={{
                handleChangePage: handleChangePage,
                handleChangeRowPerPage: handleChangeRowPerPage,
                page: tableFilter.page,
                rowsPerPage: tableFilter.rowsPerPage,
                rowsPerPageOptions: [5, 10, 25],
            }}

            columns={columns} 

            pageType={pageType}

            currentFilter={currentFilter}
            translate={translate}
        />
    )
}