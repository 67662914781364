import React from "react"
import { 
  makeStyles, 
  Box, 
  Typography,
  Link,
  Divider, 
} from '@material-ui/core'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary
} from "components/accordion"
import DefaultImg from "assets/img/img-default.png"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { ROOT as SERIAL_NUMBER_ROOT } from "modules/serial-number"
import { useHistory } from "react-router-dom"
import moment from "moment"
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import { getLang } from "app/feature/constants"
import "moment/min/locales.min";

const useStyle = makeStyles((theme) => ({
  item: {
    position: 'relative', 
    display: 'flex', 
    listStyle: 'none'
  },
  seperator: {
    display: 'flex',
    flexDirection: 'column',
    flex: 0,
    alignItems: 'center',
  },
  sequenceIndicator: {
    boxShadow: 'none',
    color: "#ffffff",
    border: "1px solid #D0D5DD",
    alignSelf: 'baseline',
    borderRadius: '50%',
    aspectRatio: "1 / 1",
    height: 32,
    width: 32,
    textAlign: "center",
    margin: "5px 0px"
  },
  connector: {
    width: 1,
    backgroundColor: "transparent",
    flexGrow: 1,
    minHeight: 25,
    borderLeft: '2px dotted #D0D5DD'
  },
  list: {
    paddingInlineStart: "0px",
    marginTop: 0,
    display: "flex",
    flexDirection: "column-reverse"
  },
  content: {
    border: "1px solid #ECEEEE",
    borderRadius: 4,
    margin: "10px 0px 10px 15px !important",
    flex: 1,
    overflow: "hidden"
  },
  dot: {
    color: "#D0D5DD",
    lineHeight: "13px"
  },
  profile: {
    height: 30,
    width: 30,
    minWidth: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& img": {
      height: "100%",
      minHeight: "100%",
      minWidth: "100%",
      objectFit: "cover",
      flexShrink: 0,
      borderRadius: 4
    }
  },
  productContainer: {
    padding: "8px 16px",
    borderRadius: "8px !important"
  },
  field: {
    display: "grid",
    gridTemplateColumns: "1fr 2fr",
    columnGap: theme.spacing(2),
    marginBottom: 5
  },
}))

export default function ProductTraceabilityTimelineComponent({
  traceabilityList,
  productColorList,
  updateRecordFocus,
  recordFocus,
  lang,
  language
}) {
  const classes = useStyle()
  const history = useHistory()
  
  if(language==="ZH")
  {
    moment.locale('zh-cn')
  }else if(language==="TL")
  {
    moment.locale('tl-ph')
  }else
  {
    moment.locale(language)
  }


  const getIndicatorColor = (uuid) => {
    let color = "#35CA91"
    let textColor = "#FFFFFF"

    if (Object.keys(productColorList).length) {
      if (productColorList.hasOwnProperty(uuid)) {
        color = productColorList[uuid]
      }
    }

    const hex = color.replace('#', '');
    const r = parseInt(hex.slice(0, 2), 16);
    const g = parseInt(hex.slice(2, 4), 16);
    const b = parseInt(hex.slice(4, 6), 16);
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;
    if (brightness > 225) {
      textColor = "#000000";
    }

    return {
      backgroundColor: color,
      color: textColor
    } 
  }

  return (
    <Box display="flex" flexDirection="column">
      <Box>
        <Typography>{getLang(lang, "label.TIMELINE_END")}</Typography>
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center" style={{ width: 32 }} mt={2}>
        <Typography className={classes.dot} style={{ marginTop: "-13px" }}><b>●</b></Typography>
        <Box className={classes.connector} style={{ minHeight: 10 }}/>
      </Box>
      <ul className={classes.list}>
        {traceabilityList.map((record, i) => (
          <li key={i} className={classes.item} id={"track_record_" + record.id}>
            <Box className={classes.seperator}>
              <Box className={classes.sequenceIndicator} style={getIndicatorColor(record.product_uuid)} display="flex" justifyContent="center" alignItems="center">
                <Typography variant="caption">{record.index}</Typography>
              </Box>
              <Box className={classes.connector} />
              <Box mt={-1} mb={-2}>
                <ArrowDropUpIcon style={{ color: "#D0D5DD", fontSize: 28 }}/>
              </Box>
              <Box className={classes.connector} />
              {!i && (
                <Typography className={classes.dot} style={{ marginBottom: "-13px" }}><b>●</b></Typography>
              )}
            </Box>
            <Accordion 
              defaultExpanded={false}
              expanded={recordFocus?.id === record.id}
              className={classes.content} 
              onChange={(event, expanded) => {
                if (expanded) {
                  updateRecordFocus(record)
                } else {
                  updateRecordFocus(null)
                }
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={classes.productContainer}
              >
                <Box>
                  <Box display="flex" alignItems="center" mb={1}>
                    <Typography variant="h6">
                      <b>{getLang(lang, "label.SCAN_QR_CODE")}</b>
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center" mb={1}>
                    <Box mr={1} className={classes.profile}>
                      {!record.product_image_url ? (
                        <img src={DefaultImg} alt="default profile" />
                      ) : (
                        <img src={record.product_image_url} alt={record.product_name} />
                      )}
                    </Box>
                    <Link
                      href={`${SERIAL_NUMBER_ROOT}/details/${record.serial_number_enc}`}
                      color="secondary"
                      onClick={(e) => {
                        history.push(`${SERIAL_NUMBER_ROOT}/details/${record.serial_number_enc}`)
                        e.preventDefault();
                        return false;
                      }}
                    >
                      <Typography variant="body1">
                        {record.serial_number || "-"}
                      </Typography>
                    </Link>
                  </Box>
                  <Box mb={1}>
                    <Typography variant="body1" style={{ textTransform: 'capitalize' }}>
                      {record.product_name || "-"}
                    </Typography>
                  </Box>
                  <Typography variant="body1" color="textSecondary">
                    {moment(record.scan_date).format('lll')}
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Box mt={0.25} px={2} pb={2}>
                  <Divider />
                  <Box mt={2}>
                    <Box className={classes.field}>
                      <Typography>{getLang(lang, "label.SCAN_COUNT")}</Typography>
                      <Box display="flex">
                        <Typography color="textSecondary" style={{ marginRight: 5 }}>:</Typography>
                        <Typography color="textSecondary">
                          {record.scan_count}
                        </Typography>
                      </Box>
                    </Box>
                    <Box className={classes.field}>
                      <Typography>{getLang(lang, "label.SCAN_LIMIT")}</Typography>
                      <Box display="flex">
                        <Typography color="textSecondary" style={{ marginRight: 5 }}>:</Typography>
                        <Typography color="textSecondary">
                          {record.scan_limit || getLang(lang, "label.NO_LIMIT")}
                        </Typography>
                      </Box>
                    </Box>
                    <Box className={classes.field}>
                      <Typography>{getLang(lang, "label.STATUS")}</Typography>
                      <Box display="flex">
                        <Typography color="textSecondary" style={{ marginRight: 5 }}>:</Typography>
                        <Typography color="textSecondary">
                          {record.status === "active" ? getLang(lang, "label.VALID") : getLang(lang, "label.SUSPENDED")}
                        </Typography>
                      </Box>
                    </Box>
                    <Box className={classes.field}>
                      <Typography>{getLang(lang, "label.LOCATION")}</Typography>
                      <Box display="flex">
                        <Typography color="textSecondary" style={{ marginRight: 5 }}>:</Typography>
                        <Box>
                          <Typography color="textSecondary">
                            {record.address ?  (
                              <span>{record.address}</span>
                            ) : record.location_country && record.location_country !== 'Unknown' ? (
                              `${record.location_city}, ${record.location_state}, ${record.location_country}`
                            ) : (
                              <span>{getLang(lang, "label.UNKNOWN")}</span>
                            )}
                          </Typography>
                          <Typography variant="body1" align="left" style={{ color: "#6AAF68" }}>
                            ({record.latitude}°{record.latitude < 0 ? "S" : "N"}, {record.longitude}°{record.longitude < 0 ? "W" : "E"})
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          </li>
        ))}
      </ul>
      <Box mt={1}>
        <Typography>{getLang(lang, "label.TIMELINE_START")}</Typography>
      </Box>
    </Box>
  )
}