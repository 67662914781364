import React from "react";
import { makeStyles, Box, Typography, ButtonBase } from "@material-ui/core";
import BatterySvg from "assets/svg/battery.svg";
import CellularConnectionSvg from "assets/svg/cellular-connection.svg";
import PhoneSpeakerSvg from "assets/svg/phone-speaker.svg";
import PhoneWifiSvg from "assets/svg/phone-wifi.svg";
import PhoneHomeBtnSvg from "assets/svg/phone-home-btn.svg";
import FacebookIconSvg from "assets/svg/facebook-icon.svg";
import WhatsappIconSvg from "assets/svg/whatsapp-icon.svg";
import YoutubeIconSvg from "assets/svg/youtube-icon.svg";
import MailIconSvg from "assets/svg/mail-icon.svg";

const useStyle = makeStyles(theme => ({
  root: {
    width: 340,
    height: 600,
    padding: "0px 7px",
    marginBottom: theme.spacing(1),
    backgroundColor: "#262D30",
    borderRadius: 20,
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "stretch",
    position: "relative"
  },
  content: {
    borderRadius: 6,
    width: "100%",
    height: "100%",
    overflow: "hidden",
    position: "relative",
    margin: "auto"
  },
  bottomBar: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    position: "absolute",
    bottom: 0,
    width: "100%",
    zIndex: 1
  },
  contactButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 60,
    width: "100%"
  }
}));

function PhoneMockUpComponent({ children }) {
  const classes = useStyle();
  return (
    <Box className={classes.root}>
      <Box
        display="flex"
        p={1}
        justifyContent="space-between"
        alignItems="center"
        color="white"
      >
        <Typography
          variant="caption"
          style={{ fontWeight: "bold" }}
          color="inherit"
        >
          6:05
        </Typography>
        <img
          style={{ marginLeft: 45 }}
          src={PhoneSpeakerSvg}
          alt="phone-speaker"
        />
        <Box display="flex" alignItems="center">
          <img
            style={{ marginRight: 4 }}
            src={CellularConnectionSvg}
            alt="phone-cellular"
          />
          <img style={{ marginRight: 4 }} src={PhoneWifiSvg} alt="phone-wifi" />
          <img src={BatterySvg} alt="phone-battery" />
        </Box>
      </Box>
      <Box className={classes.content}>
        <Box>
          {children}
        </Box>
        <Box className={classes.bottomBar}>
          {[
            { icon: FacebookIconSvg },
            { icon: WhatsappIconSvg },
            { icon: YoutubeIconSvg },
            { icon: MailIconSvg }
          ].map((contact, index) => (
            <ButtonBase className={classes.contactButton} key={index}>
              <img src={contact.icon} alt="" />
            </ButtonBase>
          ))}
        </Box>
      </Box>
      <Box
        display="flex"
        p={1}
        pb={1.5}
        justifyContent="center"
        alignItems="center"
      >
        <img src={PhoneHomeBtnSvg} alt="phone-home-button" />
      </Box>
    </Box>
  );
}

export default PhoneMockUpComponent;
