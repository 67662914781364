import React, { useEffect, useState } from "react";
import WarranteeDetailDialogComponent from "./warranteeDetailDialog.component";
import { useDispatch } from "react-redux";
import {
  resetWarrantySetting,
  resetRegisterForm,
} from "modules/warranty/redux";
import { useSelector } from "react-redux";
import { getLang } from "app/feature/constants";
import { CLAIM_LIST_VIEW } from "../../../../../lib/constants/accessRights";

function WarranteeDetailDialogContainer({
  isOpen,
  handleClose,
  pageSetting,
  warrantyRegistrationStatus,
}) {
  const dispatch = useDispatch();

  const hasClaimListViewAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(CLAIM_LIST_VIEW)
  );
  const lang = useSelector((state) => state.constant.languages);
  const language = useSelector((state) => state.profile.language);
  const [activeIndex, setActiveIndex] = useState(0);

  const tabLabels = [getLang(lang, "label.APPROVAL")];

  if (
    warrantyRegistrationStatus === "claimed" ||
    warrantyRegistrationStatus === "approved" ||
    warrantyRegistrationStatus === 1
  ) {
    tabLabels.push(getLang(lang, "label.WARRANTY_CLAIM"));
  }

  const handleChange = (event, index) => {
    setActiveIndex(index);
  };

  useEffect(() => {
    return () => {
      dispatch(resetWarrantySetting());
      dispatch(resetRegisterForm());
    };
  }, [dispatch]);

  return (
    <WarranteeDetailDialogComponent
      isOpen={isOpen}
      handleClose={() => {
        handleClose();
        setActiveIndex(0);
      }}
      handleChange={handleChange}
      activeIndex={activeIndex}
      tabLabels={tabLabels}
      language={language}
      pageSetting={pageSetting}
      hasClaimListViewAccessRight={hasClaimListViewAccessRight}
    />
  );
}

export default WarranteeDetailDialogContainer;
