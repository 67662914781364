import React from "react";
import { useSelector } from "react-redux";
import MapsComponent from "./maps.component";
import { useLoadScript } from "@react-google-maps/api";

const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

export default function MapsContainer({ latitude, longitude }) {
  const locale = useSelector((state) => state.profile.language);

  const { isLoaded } = useLoadScript({
    id: "google-map-script",
    googleMapsApiKey: API_KEY,
    language: locale,
  });

  return (
    <MapsComponent
      latitude={latitude}
      longitude={longitude}
      isLoaded={isLoaded}
    />
  );
}
