import axios from "axios";

export function rejectApproveTransfer(parameter) {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `/distributor/admin/api/v1/transfer/${parameter.id}/reject-approve`,
        {
          reasons: parameter.reasons,
        }
      )
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data));
  });
}
