import React from "react";
import {
  Box,
  Button,
  makeStyles,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Checkbox,
} from "@material-ui/core";
import {
  StyledTableCell,
  StyledTableRow,
  StickyLeftTableCell,
  StickyRightTableCell
} from "components/table";
import { SkeletonTableRowStickyLeftRight } from "components/skeleton/skeleton.component";
import { ActionButton } from 'components/button';
import { TablePaginationActions } from "components/tablePagination";
import clsx from "clsx";
import { STATUS } from "modules/stockflow/constants";
import SelectAllCheckBox from 'components/selectAllCheckbox';
import { getLang } from "app/feature/constants";

const useStyle = makeStyles((theme) => ({
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    borderCollapse: "separate",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0
    },
    "& th,td": {
      fontSize: ".75rem",
      borderRight: "1px solid rgba(58, 77, 84, 0.1)",
      padding: theme.spacing(1, 2, 1, 2),
    },
    "& th": {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightBold,
    },
    "& th:last-child, td:last-child": {
      width: 37,
      borderRight: "0px",
    },
    "& th th": {
      borderBottom: "none !important",
    },
    "& td td": {
      borderBottom: "none !important",
    },
  },
  tableContainer: {
    maxHeight: 500,
    overflow: "auto"
  },
  emptyContainer: {
    backgroundColor: theme.palette.background.paper,
    textAlign: "center",
  },
  show: {
    display: "flex",
  },
  actionContainer: {
    justifyContent: "center",
  },
  selectAll: {
    '&.Mui-checked': {
      color: "#FDB022",
    },
  },
  selectOne: {
    '&.Mui-checked': {
      color: "#6AAF68",
    },
  },
  checkbox: {
    paddingLeft: "16px"
  }
}));

export default function IncentiveProgramTableComponent({
  hasStockflowAddAccessRight,
  hasStockflowEditAccessRight,
  isFetching,
  isError,
  isSelectAll,
  selectedUUIDs,
  rows,
  tierLimit,
  paginationOptions,
  totalFiltered,
  rowsPerPage,
  page,
  handleChangePage,
  handleChangeRowPerPage,
  isSearchResult,
  handleEdit,
  handleChangeStatus,
  handleDelete,
  handleAddIncentiveProgram,
  handleSelectProgram,
  handleSelectAll,
  handleDeselectAll,
  handleSelectAvailable,
  lang
}) {
  const classes = useStyle();

  const selectAllItems = [
    {
      label: `${getLang(lang, 'paragraph.SELECT_AVAILABLE_ITEM', { count: rows.length > rowsPerPage ? rowsPerPage : rows.length })}`,
      action: () => handleSelectAvailable()
    },
    {
      label: getLang(lang, 'label.SELECT_ALL_DEALER_INCENTIVE_PROGRAM'),
      action: () => handleSelectAll()
    },
    {
      label: getLang(lang, 'label.DESELECT_ALL_DEALER_INCENTIVE_PROGRAM'),
      action: () => handleDeselectAll(),
      divider: true
    },
  ]

  return (
    <>
      {isFetching || rows.length > 0 ? (
        <Box>
          <TableContainer>
            <Table
              className={classes.table}
              aria-label="product table"
              size="small"
              stickyHeader
            >
              <TableHead style={{ height: "45px" }}>
                <TableRow>
                  <StickyLeftTableCell>
                    <StyledTableCell padding="checkbox" style={{ paddingLeft: '15px' }}>
                      <SelectAllCheckBox
                        actionItems={selectAllItems}
                        disabled={isFetching}
                        isSelectAll={isSelectAll}
                        selectedIdLength={selectedUUIDs.length}
                        totalFiltered={totalFiltered}
                      />
                    </StyledTableCell>
                  </StickyLeftTableCell>
                  <StyledTableCell style={{ minWidth: "32em" }}>{getLang(lang, 'label.PROGRAM_NAME')}</StyledTableCell>
                  <StyledTableCell>{getLang(lang, 'label.PRODUCT_COUNT')}</StyledTableCell>
                  {[...new Array(tierLimit)].map((v, index) => (
                    <StyledTableCell style={{ textAlign: "center" }} key={"column" + index}>{getLang(lang, 'label.TIER')} {index + 1}</StyledTableCell>
                  ))}
                  <StyledTableCell>{getLang(lang, 'label.STATUS')}</StyledTableCell>
                  <StyledTableCell style={{ right: 0 }}>{getLang(lang, 'label.ACTION')}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isFetching ? [...new Array(8)].map((v, index) => (
                  <SkeletonTableRowStickyLeftRight key={'skeleton' + index} columnCount={tierLimit + 5} />
                )) : rows.map((row, index) => {
                  const actionItems = [];

                  actionItems.push({
                    label: getLang(lang, 'label.EDIT'),
                    disabled: !hasStockflowEditAccessRight,
                    action: () => handleEdit({ id: row.uuid })
                  })

                  actionItems.push({
                    label: row.status === "active" ? getLang(lang, 'label.DEACTIVATE') : getLang(lang, 'label.ACTIVATE'),
                    disabled: !hasStockflowEditAccessRight,
                    action: () => handleChangeStatus(row.uuid, row.status)
                  })

                  actionItems.push({
                    label: getLang(lang, 'label.DELETE'),
                    disabled: !hasStockflowEditAccessRight,
                    action: () => handleDelete(row.uuid),
                    divider: true,
                    errorText: true
                  });

                  return (
                    <StyledTableRow
                      key={row.id}
                      hover
                      role="checkbox"
                    >
                      <StickyLeftTableCell>
                        <StyledTableCell padding='checkbox' style={{ paddingLeft: "16px" }}>
                          <Checkbox
                            className={clsx({
                              [classes.selectAll]: isSelectAll,
                              [classes.selectOne]: row.isSelected && isSelectAll === false
                            })}
                            checked={row.isSelected}
                            onChange={(event) => {
                              handleSelectProgram(index, event.target.checked)
                            }}
                          />
                        </StyledTableCell>
                      </StickyLeftTableCell>
                      <StyledTableCell>
                        <span>{row.name}</span>
                      </StyledTableCell>
                      <StyledTableCell>
                        <span>{row.productCount.toLocaleString("en-US")}</span>
                      </StyledTableCell>
                      {row.percentages.map((percentage, index) => (
                        <StyledTableCell style={{ textAlign: "center" }} key={index}>
                          <span>{percentage}%</span>
                        </StyledTableCell>
                      ))}
                      <StyledTableCell>
                        <span
                          style={{
                            textTransform: "capitalize",
                            color: STATUS.find(({ code }) => code === row.status.toLowerCase()).color
                          }}
                        >
                          {/* {STATUS.find(({ code }) => code === row.status.toLowerCase()).text} */}
                          {row.status === "active" ? getLang(lang, 'label.ACTIVATED') : getLang(lang, 'label.DEACTIVATED')}
                          {/* QUICK FIX FOR LANGUAGES TRANSLATION */}
                        </span>
                      </StyledTableCell>
                      <StickyRightTableCell>
                        <StyledTableCell style={{ width: "67px" }}>
                          <div className={clsx(classes.actionContainer, classes.show)}>
                            <ActionButton
                              actionItems={actionItems}
                              popperStyle={{ zIndex: 999 }}
                            />
                          </div>
                        </StyledTableCell>
                      </StickyRightTableCell>
                    </StyledTableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            style={{
              backgroundColor: "#fff",
              boxShadow: "0px -1px 0px rgba(58, 77, 84, 0.15)",
            }}
            ActionsComponent={TablePaginationActions}
            rowsPerPageOptions={paginationOptions}
            component="div"
            count={totalFiltered}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowPerPage}
            labelRowsPerPage={getLang(lang, 'label.ROWS_PER_PAGE')}
            labelDisplayedRows={({ from, to, count }) => `${from.toLocaleString("en-US")}-${to.toLocaleString("en-US")} ${getLang(lang, 'label.OF')} ${count.toLocaleString("en-US")}`}
          />
        </Box>
      ) : null}
      {!isFetching && !isError && rows.length === 0 && (
        <Box>
          <TableContainer>
            <Table
              className={classes.table}
              aria-label="product table"
              size="small"
              stickyHeader
            >
              <TableHead style={{ height: "45px" }}>
                <TableRow>
                  <StyledTableCell padding="checkbox">
                    <SelectAllCheckBox
                      disabled={true}
                    />
                  </StyledTableCell>
                  <StyledTableCell style={{ minWidth: "32em" }}>{getLang(lang, 'label.PROGRAM_NAME')}</StyledTableCell>
                  <StyledTableCell>{getLang(lang, 'label.PRODUCT_COUNT')}</StyledTableCell>
                  {[...new Array(tierLimit)].map((v, index) => (
                    <StyledTableCell style={{ textAlign: "center" }} key={"column" + index}>{getLang(lang, 'label.TIER')} {index + 1}</StyledTableCell>
                  ))}
                  <StyledTableCell>{getLang(lang, 'label.STATUS')}</StyledTableCell>
                  <StyledTableCell style={{ right: 0 }}>{getLang(lang, 'label.ACTION')}</StyledTableCell>
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>
          <Box py={9} className={classes.emptyContainer}>
            <Typography variant='body1' style={{ paddingBottom: "16px" }}>
              {isSearchResult ? getLang(lang, 'message.info.NO_INCENTIVE_PROGRAM_EXISTS') : getLang(lang, 'message.info.YOU_DONT_HAVE_INCENTIVE_PROGRAM')}
            </Typography>
            {hasStockflowAddAccessRight && (
              <Button
                onClick={handleAddIncentiveProgram}
                variant="contained"
                disableElevation
                color="primary"
              >
                {getLang(lang, 'label.ADD_DEALER_INCENTIVE_PROGRAM')}
              </Button>
            )}
          </Box>
        </Box>
      )}
    </>
  )


}
