import axios from "axios";

const endpoint_url = "/serialnumber/api/v1/productui";

const get = productId => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${endpoint_url}/${productId}`)
      .then(response => resolve(response.data))
      .catch(error => {
        return reject(error.response.data);
      });
  });
};

const put = ({ productId, uiPath, lang, liveUiPath, status }) => {
  const body = {
    ui_path: uiPath,
    lang,
    live_ui_path: liveUiPath,
    status
  };

  return new Promise((resolve, reject) => {
    axios
      .put(`${endpoint_url}/${productId}`, body)
      .then(response => resolve(response.data))
      .catch(error => {
        return reject(error.response.data);
      });
  });
};

const productui = {
  get,
  put
};

export default productui;
