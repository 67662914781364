import React from "react";
import {
  Backdrop,
  Dialog,
  makeStyles,
  Typography,
  Paper,
  Box,
  Divider,
  Button,
  IconButton,
  Tooltip
} from "@material-ui/core";
import { Close as CloseIcon, Add as AddIcon } from "@material-ui/icons";
import { Formik } from "formik"
import Input from 'components/input/inputTextField';
import AntSwitch from 'components/switch/switch';
import { DustBinIconSvg } from 'modules/product-mgmt/utils/staticSvg';
import { isEmpty } from 'lib/helper';
import { getLang } from "app/feature/constants";
import BrandImagePanel from "../../panel/brandImagePanel";

const useStyle = makeStyles((theme) => ({
  dialog: {
    maxWidth: "none"
  },
  root: {
    boxShadow: theme.shadows[5],
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "space-between",
    width: 700,
    borderRadius: "4px",
    fontSize: 14
  },
  header: {
    paddingLeft: theme.spacing(3),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  content: {
    padding: "20px 16px"
  },
  buttonContainer: {
    padding: "10px 16px",
    display: "flex",
    justifyContent: "flex-end"
  },
  inputRoot: {
    paddingTop: "8px !important",
    paddingBottom: "7px !important",
    paddingLeft: "12px !important",
    paddingRight: "12px !important"
  },
  modelContainer: {
    display: "grid",
    gridTemplateColumns: "5fr 1fr 1fr",
    columnGap: theme.spacing(1)
  }
}))

export default function EditBrandModelDialogComponent({
  isOpen,
  handleClose,
  handleSubmit,
  data,
  lang,
  isSaving,
}) {
  const classes = useStyle()

  return (
    <Dialog
      maxWidth="sm"
      open={isOpen}
      onClose={handleClose}
      disableBackdropClick
      closeAfterTransition
      BackdropComponent={Backdrop}
      classes={{
        paperWidthSm: classes.dialog
      }}
    >
      <Paper elevation={0} className={classes.root}>
        <Box className={classes.header}>
          <Typography
            variant='subtitle2'
            color="primary"
          >
            {getLang(lang,"label.EDIT_BRAND_MODEL")}
          </Typography>
          <IconButton color="primary" onClick={handleClose}>
            <CloseIcon fontSize='small' />
          </IconButton>
        </Box>
        <Divider />
        <Formik
          initialValues={{
            details: data,
            models: data.models || []
          }}
          enableReinitialize
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              {!isEmpty(formik.values.details) ? (
                <Box style={{ overflow: "auto" }}>
                  <Box display="flex">
                    <Box className={classes.content} flexGrow={1}>
                      <Box style={{ display: "flex", justifyContent: "space-between" }}>
                        <Box style={{ flex: 1 }}>
                          <Box style={{ marginBottom: "16px" }}>
                            <Typography variant='body2' color='primary' style={{ fontFamily: "Roboto" }}>{getLang(lang,"label.BRAND")}</Typography>
                          </Box>
                          <Input
                            hiddenLabel
                            variant="filled"
                            placeholder={getLang(lang,"placeholder.PLEASE_FILL_BRAND_NAME")}
                            margin="none"
                            InputProps={{
                              disableUnderline: true,
                              margin: "none",
                              style: {
                                borderRadius: "4px",
                                backgroundColor: "#eceff0",
                                height: "36.5px"
                              }
                            }}
                            style={{
                              width: "95%",
                            }}
                            value={formik.values.details?.name}
                            disabled={formik.values.details?.id === "-1" || isSaving}
                            onChange={(event) => {
                              let temp = { ...formik.values.details }

                              temp.name = event.target.value

                              formik.setFieldValue("details", temp)
                            }}
                          />
                        </Box>
                        <Box style={{ display: "flex", flexDirection: "column" }}>
                          <Box style={{ marginBottom: "16px" }}>
                            <Typography variant='body2' style={{ fontFamily: "Roboto", color: "#98A2B3" }}>{getLang(lang,"label.STATUS")}</Typography>
                          </Box>
                          <Box style={{ display: "flex", flex: 1, justifyContent: "center", alignItems: "center" }}>
                            <AntSwitch
                              checked={formik.values.details?.status === 1 ? true : false}
                              disabled={formik.values.details?.id === "-1" || isSaving}
                              onChange={(event) => {
                                let temp = { ...formik.values.details }

                                temp.status = event.target.checked ? 1 : 2;

                                formik.setFieldValue("details", temp)
                              }}
                            />
                          </Box>
                        </Box>
                      </Box>
                      {data.id !== "-1" && (
                        <Box pt={1}>
                          <Input
                            hiddenLabel
                            multiline
                            rows={4}
                            variant="filled"
                            size="small"
                            margin="none"
                            fullWidth
                            placeholder={getLang(lang, "placeholder.ENTER_DESCRIPTION")}
                            {...formik.getFieldProps("details.description")}
                            onChange={(event) => {
                              let temp = { ...formik.values.details }

                              temp.description = event.target.value

                              formik.setFieldValue("details", temp)
                            }}
                            disabled={isSaving}
                          />
                        </Box>
                      )}
                      <Box style={{ marginTop: "24px", backgroundColor: "#F9FAFB", padding: "16px" }}>
                        <Box style={{ display: "flex", justifyContent: "space-between", marginBottom: "16px" }}>
                          <Box>
                            <Typography variant='body2' color='primary' style={{ fontFamily: "Roboto" }}>{getLang(lang,"label.MODELS")}</Typography>
                          </Box>
                          {formik.values.models.filter((model) => model.status !== 3).length > 0 ?
                            <Box style={{ display: "flex", flexDirection: "row" }}>
                              <Button
                                variant='text'
                                color='secondary'
                                disableElevation
                                onClick={() => {
                                  let temp = [...formik.values.models]
                                  let modelArray = []

                                  temp.map((model) => {
                                    let modelTemp = { ...model }

                                    modelTemp.status = 2

                                    modelArray.push(modelTemp)

                                    return null;
                                  })

                                  formik.setFieldValue("models", modelArray)

                                }}
                                style={{ fontFamily: "Roboto", paddingRight: "12px", fontSize: "14px", textTransform: "capitalize", borderRight: "1px solid #d1d1d1" }}
                                disabled={isSaving}
                              >
                                {getLang(lang,"label.DISABLE_ALL")}
                              </Button>
                              <Button
                                variant='text'
                                color='secondary'
                                disableElevation
                                onClick={() => {
                                  let temp = [...formik.values.models]
                                  let modelArray = []

                                  temp.map((model) => {
                                    let modelTemp = { ...model }

                                    modelTemp.status = 1

                                    modelArray.push(modelTemp)

                                    return null;
                                  })

                                  formik.setFieldValue("models", modelArray)

                                }}
                                style={{ fontFamily: "Roboto", paddingLeft: "12px", fontSize: "14px", textTransform: "capitalize" }}
                                disabled={isSaving}
                              >
                                {getLang(lang,"label.ENABLE_ALL")}
                              </Button>
                            </Box>
                            : null}
                        </Box>
                        {formik.values.models.filter((model) => model.status !== 3).length > 0 ?
                          <Box className={classes.modelContainer}>
                            <Box></Box>
                            <Typography variant='body2' style={{ fontFamily: "Roboto", color: "#98A2B3", textAlign: "center" }}>{getLang(lang,"label.DELETE")}</Typography>
                            <Typography variant='body2' style={{ fontFamily: "Roboto", color: "#98A2B3", textAlign: "center" }}>{getLang(lang,"label.STATUS")}</Typography>
                          </Box>
                          : null}
                        {formik.values.models.length > 0 && formik.values.models.map((model, index) => (
                          <>
                            {model.status !== 3 && (
                              <Box key={model.id + index} className={classes.modelContainer} style={{ marginTop: "12px" }}>
                                <Input
                                  hiddenLabel
                                  variant="filled"
                                  size="small"
                                  placeholder={getLang(lang,"placeholder.PLEASE_FILL_MODEL")}
                                  margin="none"
                                  InputProps={{
                                    disableUnderline: true,
                                    margin: "none",
                                    style: {
                                      borderRadius: "4px",
                                      backgroundColor: "#ECEFF0"
                                    },
                                    classes: { input: classes.inputRoot }
                                  }}
                                  value={model.name}
                                  onChange={(event) => {
                                    let temp = [...formik.values.models]
                                    let model = { ...temp[index] }

                                    model.name = event.target.value

                                    temp[index] = model

                                    formik.setFieldValue("models", temp)

                                  }}
                                  disabled={isSaving}
                                />
                                <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                  {
                                    formik.values.models[index].product_count > 0
                                      ? <Tooltip title={getLang(lang,"tooltips.MODEL_CANNOT_BE_DELETED")}>
                                      <span>
                                        <IconButton
                                          color="primary"
                                          onClick={() => {
                                            let temp = [...formik.values.models]
                                            let model = { ...temp[index] }
                                            if (isEmpty(model.id)) {
                                              temp.splice(index, 1)
                                            }
                                            else {
                                              model.status = 3
                                              temp[index] = model
                                            }
                                            formik.setFieldValue("models", temp)
                                          }}
                                          disabled={formik.values.models[index].product_count > 0 ? true : false || isSaving}
                                          style={{ padding: "0px" }}
                                        >
                                          <DustBinIconSvg color={formik.values.models[index].product_count > 0 ? '#c9c9c9' : "#F97066"} />
                                        </IconButton>
                                      </span>
                                      </Tooltip>
                                      : <IconButton
                                        color="primary"
                                        onClick={() => {
                                          let temp = [...formik.values.models]
                                          let model = { ...temp[index] }
                                          if (isEmpty(model.id)) {
                                            temp.splice(index, 1)
                                          }
                                          else {
                                            model.status = 3
                                            temp[index] = model
                                          }
                                          formik.setFieldValue("models", temp)
                                        }}
                                        disabled={formik.values.models[index].product_count > 0 ? true : false || isSaving}
                                        style={{ padding: "0px" }}
                                      >
                                        <DustBinIconSvg color={formik.values.models[index].product_count > 0 || isSaving ? '#c9c9c9' : "#F97066"} />
                                      </IconButton>
                                  }

                                </Box>
                                <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                  <AntSwitch
                                    checked={model.status === 1 ? true : false}
                                    onChange={(event) => {
                                      let temp = [...formik.values.models]
                                      let model = { ...temp[index] }

                                      model.status = event.target.checked ? 1 : 2

                                      temp[index] = model

                                      formik.setFieldValue("models", temp)
                                    }}
                                    disabled={isSaving}
                                  />
                                </Box>
                              </Box>
                            )}

                          </>
                        ))}
                        <Box style={{ marginTop: "24px" }}>
                          <Button
                            variant='text'
                            color="primary "
                            startIcon={<AddIcon />}
                            onClick={() => {
                              let temp = [...formik.values.models]

                              temp.push({
                                id: "",
                                name: "",
                                status: 1,
                              })

                              formik.setFieldValue("models", temp)
                            }}
                            disabled={isSaving}
                          >
                            {getLang(lang,"label.ADD_OPTION")}
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                    {data.id !== "-1" && (
                      <Box p={2}>
                        <BrandImagePanel
                          logo={formik.values.details.imageUrl}
                          handleChange={(base64Image) => {
                            formik.setFieldValue('details.imageUrl', base64Image);
                          }}
                          handleRemove={() => {
                            formik.setFieldValue('details.imageUrl', null);
                          }}
                          disabled={isSaving}
                        />
                      </Box>
                    )}
                  </Box>

                  <Divider />
                  <Box style={{ display: "flex", justifyContent: "flex-end", padding: "10px 16px" }}>
                    <Button
                      variant='text'
                      color='secondary'
                      disableElevation
                      onClick={() => {
                        formik.setFieldValue("details", data)
                        formik.setFieldValue("models", data.models)

                      }}
                      style={{ marginRight: "8px" }}
                      disabled={isSaving}
                    >
                      {getLang(lang,"label.RESET")}
                    </Button>
                    <Button
                      variant='contained'
                      color='secondary'
                      type='submit'
                      disabled={isSaving}
                      disableElevation
                    >
                    {isSaving
                      ? getLang(lang, "label.SAVING")
                      : getLang(lang, "label.UPDATE")}
                    </Button>
                  </Box>
                </Box>
              ) : null}
            </form>
          )}

        </Formik>
      </Paper>
    </Dialog >
  )
}
