import React from "react";
import {
  makeStyles,
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from "@material-ui/core";
import {
  StyledTableCell,
  StyledTableRow,
  StickyRightTableCell,
} from "components/table";
import { SkeletonTableRow } from "components/skeleton/skeleton.component";
import { TablePaginationActions } from "components/tablePagination";
import ActionButton from "components/button/actionDetailButton";
import RefreshTable from "components/table/refreshTable";
import clsx from "clsx";
import { getLang } from "app/feature/constants";
import moment from "moment";
import "moment/min/locales.min";
import { Skeleton } from "@material-ui/lab";

const useStyle = makeStyles((theme) => ({
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    borderCollapse: "separate",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
    },
    "& th,td": {
      fontSize: ".75rem",
      borderRight: "1px solid rgba(58, 77, 84, 0.1)",
      padding: theme.spacing(1, 2, 1, 2),
    },
    "& th": {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightBold,
    },
    "& th:last-child, td:last-child": {
      width: 37,
      right: "-2px",
    },
    "& th th": {
      borderBottom: "none !important",
    },
    "& td td": {
      borderBottom: "none !important",
    },
  },
  emptyContainer: {
    textAlign: "center",
  },
  statusChip: {
    padding: theme.spacing(0.2, 0.5),
    borderRadius: theme.spacing(0.5),
    color: theme.palette.common.white,
    textAlign: "center",
  },
  activeText: {
    color: "#32D583",
  },
  suspendText: {
    color: "#F97066",
  },
  missingText: {
    color: "#FDB022",
  },
  disabledText: {
    color: "#98A2B3",
  },
  button: {
    display: "flex",
    alignItems: "center",
    padding: 0,
    minWidth: 0,
    marginLeft: theme.spacing(1),
  },
  greyCell: {
    backgroundColor: "#f1f1f1",
  },
  attributeText: {
    color: "#b2b2b2",
    fontSize: 12,
    whiteSpace: "break-spaces",
  },
  adtTablePagination: {
    "& .MuiTablePagination-actions": {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
    "& .MuiTablePagination-select": {
      marginLeft: theme.spacing(1),
    },
    "& .MuiIconButton-root:last-child": {
      display: "none", // Hide the last page button
    },
    "& .MuiIconButton-root:first-child": {
      display: "none", // Hide the first page button
    },
  },
}));

export default function SerialNumTableComponent({
  hasSerialNumberEditAccessRight,
  hasSerialNumberGenerateAccessRight,
  rows,
  isFetching,
  isLengthFetching,
  disabledPagination,
  page,
  rowsPerPage,
  handleChangePage,
  language,
  handleChangeRowsPerPage,
  length,
  search,
  addSuccessAlert,
  handleAddSerialNumber,
  handleReload,
  handleViewSerialNumberDetails,
  handleStatusChange,
  paginationOptions,
  lang,
  showAdditionalInfo,
  additionalFields,
  isSearchResult,
  userLang,
  isError,
  displayLang,
}) {
  const classes = useStyle();

  if (language === "ZH") {
    moment.locale("zh-cn");
  } else if (language === "TL") {
    moment.locale("tl-ph");
  } else {
    moment.locale(language);
  }

  function copyLink(urlPrefix, encode) {
    navigator.clipboard.writeText(`${urlPrefix}${encode}`);
    addSuccessAlert(getLang(lang, "message.success.LINK_COPIED_TO_CLIPBOARD"));
  }

  return (
    <>
      {rows.length > 0 || isFetching ? (
        <TableContainer>
          <Table className={classes.table} aria-label="product table">
            <TableHead>
              <TableRow>
                <StyledTableCell
                  style={{
                    width: "48px",
                    position: "sticky",
                    left: 0,
                    zIndex: 1,
                    backgroundColor: "white",
                  }}
                >
                  {getLang(lang, "label.NUM")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.SEQUENCE_NUMBER")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.SERIAL_NUMBER")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.PRODUCT")}
                </StyledTableCell>
                {!showAdditionalInfo && (
                  <StyledTableCell style={{ display: "flex" }}>
                    {getLang(lang, "label.BATCH_NUMBER")}
                  </StyledTableCell>
                )}
                {!showAdditionalInfo && (
                  <StyledTableCell>
                    {getLang(lang, "label.TYPE")}
                  </StyledTableCell>
                )}
                <StyledTableCell>
                  {getLang(lang, "label.BRANCH")}
                </StyledTableCell>
                {showAdditionalInfo &&
                  !!additionalFields.length &&
                  additionalFields.map((a, i) => (
                    <StyledTableCell key={i}>
                      {a.title
                        ? a.title[displayLang] ??
                          a.title[userLang] ??
                          a.title["EN"] ??
                          Object.values(a.title)[0]
                        : ""}
                    </StyledTableCell>
                  ))}
                {!showAdditionalInfo && (
                  <StyledTableCell style={{ display: "flex" }}>
                    {getLang(lang, "label.DATE_CREATED")}
                  </StyledTableCell>
                )}
                {!showAdditionalInfo && (
                  <StyledTableCell>
                    {getLang(lang, "label.SCAN_COUNT")} /{" "}
                    {getLang(lang, "label.SCAN_LIMIT")}
                  </StyledTableCell>
                )}
                <StyledTableCell>
                  {getLang(lang, "label.STATUS")}
                </StyledTableCell>
                <StickyRightTableCell>
                  <Box style={{ padding: "8px 16px" }}>
                    {getLang(lang, "label.ACTION")}
                  </Box>
                </StickyRightTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isFetching
                ? [...new Array(5)].map((v, index) => (
                    <SkeletonTableRow
                      key={index}
                      columnCount={
                        showAdditionalInfo ? 7 + additionalFields.length : 11
                      }
                    />
                  ))
                : rows.map((row, index) => {
                    const actionItems = [
                      {
                        label: getLang(lang, "label.VIEW_DETAIL"),
                        action: () =>
                          handleViewSerialNumberDetails({ enc: row.encode }),
                      },
                      {
                        label: getLang(lang, "label.COPY_URL"),
                        action: () => copyLink(row.urlPrefix, row.encode),
                      },
                    ];
                    hasSerialNumberEditAccessRight &&
                      row.status !== "disabled" &&
                      actionItems.push({
                        label:
                          row.status === "suspend"
                            ? getLang(lang, "label.REACTIVATE")
                            : getLang(lang, "label.SUSPEND"),
                        divider: true,
                        errorText: true,
                        action: () =>
                          handleStatusChange({
                            currentStatus: row.status,
                            enc: row.encode,
                          }),
                      });

                    const subStatus = {
                      1: getLang(lang, "label.REPLACED"),
                      2: getLang(lang, "label.FAULTY"),
                      3: getLang(lang, "label.MISSING"),
                    };

                    return (
                      <StyledTableRow
                        key={index}
                        hover
                        // onClick={event => handleSelect(event, row.id)}
                        role="checkbox"
                        // selected={isItemSelected}
                        tabIndex={-1}
                      >
                        <StyledTableCell
                          style={{
                            width: "48px",
                            position: "sticky",
                            left: 0,
                            zIndex: 1,
                            backgroundColor: "white",
                          }}
                        >
                          {index + 1 + rowsPerPage * page}
                        </StyledTableCell>
                        <StyledTableCell>{row.seqNumber}</StyledTableCell>
                        <StyledTableCell>{row.serialNumber}</StyledTableCell>
                        <StyledTableCell>
                          <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="flex-start"
                          >
                            <Typography
                              variant="body2"
                              style={{ fontSize: 12 }}
                            >
                              {row.productName ? row.productName : "-"}
                            </Typography>
                            {!!row.attributes && !!row.attributes.length && (
                              <Typography
                                className={classes.attributeText}
                                variant="body2"
                              >
                                {row.attributes.join(", ")}
                              </Typography>
                            )}
                          </Box>
                        </StyledTableCell>
                        {!showAdditionalInfo && (
                          <StyledTableCell>{row.batchNumber}</StyledTableCell>
                        )}
                        {!showAdditionalInfo && (
                          <StyledTableCell>
                            {row.genType === "running"
                              ? getLang(lang, "label.RUNNING")
                              : row.genType === "random"
                              ? getLang(lang, "label.RANDOM")
                              : row.genType}
                            {/* QUICK FIX FOR LANGUAGES TRANSLATION */}
                          </StyledTableCell>
                        )}
                        <StyledTableCell>{row.branchName}</StyledTableCell>
                        {showAdditionalInfo &&
                          !!additionalFields.length &&
                          additionalFields.map((a, i) => {
                            let info = row.additionalFields.find(
                              (f) => f.id === a.id
                            );
                            return (
                              <StyledTableCell key={i}>
                                {info && info.value
                                  ? info.format === 1
                                    ? info.value[displayLang] ?? "-"
                                    : info.value
                                  : "-"}
                              </StyledTableCell>
                            );
                          })}
                        {!showAdditionalInfo && (
                          <StyledTableCell>
                            {moment(row.datetime).format("ll")}
                          </StyledTableCell>
                        )}
                        {!showAdditionalInfo && (
                          <StyledTableCell>
                            {row.productName
                              ? row.scanCount.toLocaleString("en-US") +
                                "/" +
                                (row.scanLimit === 0
                                  ? getLang(lang, "label.NO_LIMIT")
                                  : row.scanLimit.toLocaleString("en-US"))
                              : "-"}
                          </StyledTableCell>
                        )}
                        <StyledTableCell
                          className={clsx({
                            [classes.activeText]: row.status === "active",
                            [classes.suspendText]: row.status === "suspend",
                            [classes.missingText]:
                              row.status === "missing" ||
                              row.status === "faulty",
                            [classes.disabledText]: row.status === "disabled",
                          })}
                        >
                          {row.status === "suspend"
                            ? `${getLang(lang, "label.SUSPEND")} ${
                                row.subStatus && `(${subStatus[row.subStatus]})`
                              }`
                            : row.status === "active"
                            ? `${getLang(lang, "label.ACTIVE")} ${
                                row.subStatus && `(${subStatus[row.subStatus]})`
                              }`
                            : row.status === "disabled"
                            ? `${getLang(lang, "label.DISABLED")} ${
                                row.subStatus && `(${subStatus[row.subStatus]})`
                              }`
                            : row.status}
                          {/* QUICK FIX FOR LANGUAGES TRANSLATION */}
                        </StyledTableCell>
                        <StickyRightTableCell>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <ActionButton
                              actionItems={actionItems}
                              popperStyle={{ zIndex: 999 }}
                            />
                          </div>
                        </StickyRightTableCell>
                      </StyledTableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : !isFetching && rows.length === 0 ? (
        <Box style={{ overflowX: "auto", backgroundColor: "#fff" }}>
          <TableContainer>
            <Table
              className={classes.table}
              aria-label="product table"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell>
                    {getLang(lang, "label.NUM")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.SEQUENCE_NUMBER")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.SERIAL_NUMBER")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.PRODUCT")}
                  </StyledTableCell>
                  {!showAdditionalInfo && (
                    <StyledTableCell style={{ display: "flex" }}>
                      {getLang(lang, "label.BATCH_NUMBER")}
                    </StyledTableCell>
                  )}
                  {!showAdditionalInfo && (
                    <StyledTableCell>
                      {getLang(lang, "label.TYPE")}
                    </StyledTableCell>
                  )}
                  <StyledTableCell>
                    {getLang(lang, "label.BRANCH")}
                  </StyledTableCell>
                  {showAdditionalInfo &&
                    !!additionalFields.length &&
                    additionalFields.map((a, i) => (
                      <StyledTableCell key={i}>
                        {a.title
                          ? a.title[displayLang] ??
                            a.title[userLang] ??
                            a.title["EN"] ??
                            Object.values(a.title)[0]
                          : ""}
                      </StyledTableCell>
                    ))}
                  {!showAdditionalInfo && (
                    <StyledTableCell style={{ display: "flex" }}>
                      {getLang(lang, "label.DATE_CREATED")}
                    </StyledTableCell>
                  )}
                  {!showAdditionalInfo && (
                    <StyledTableCell>
                      {getLang(lang, "label.SCAN_COUNT")} /{" "}
                      {getLang(lang, "label.SCAN_LIMIT")}
                    </StyledTableCell>
                  )}
                  <StyledTableCell>
                    {getLang(lang, "label.STATUS")}
                  </StyledTableCell>
                  <StickyRightTableCell>
                    <Box style={{ padding: "8px 16px" }}>
                      {getLang(lang, "label.ACTION")}
                    </Box>
                  </StickyRightTableCell>
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>
          <Box py={9} className={classes.emptyContainer}>
            <Typography variant="body1">
              {getLang(
                lang,
                isSearchResult
                  ? "message.info.NO_SERIAL_NUMBER"
                  : "paragraph.NO_SERIAL_NUMBER"
              )}
            </Typography>
            {hasSerialNumberGenerateAccessRight && !isSearchResult ? (
              <Box mt={2}>
                <Button
                  onClick={handleAddSerialNumber}
                  variant="contained"
                  disableElevation
                  color="primary"
                >
                  {getLang(lang, "label.ADD_SERIAL_NUMBER")}
                </Button>
              </Box>
            ) : null}
          </Box>
        </Box>
      ) : null}

      {!isFetching && isError && <RefreshTable handleReload={handleReload} />}

      {disabledPagination ? (
        <Box
          style={{
            backgroundColor: "#fff",
            boxShadow: "0px -1px 0px rgba(58, 77, 84, 0.15)",
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Box px={2} py={1} maxWidth={500} width="100%" overflow="hidden">
            <Skeleton width="100%" height={30} />
          </Box>
        </Box>
      ) : (
        <TablePagination
          style={{
            backgroundColor: "#fff",
            boxShadow: "0px -1px 0px rgba(58, 77, 84, 0.15)",
          }}
          className={showAdditionalInfo ? classes.adtTablePagination : null}
          ActionsComponent={TablePaginationActions}
          rowsPerPageOptions={paginationOptions}
          component="div"
          count={length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          SelectProps={{
            classes: classes.toolbar,
            disabled: disabledPagination,
          }}
          backIconButtonProps={{
            disabled: disabledPagination,
          }}
          nextIconButtonProps={{
            disabled: disabledPagination,
          }}
          labelRowsPerPage={getLang(lang, "label.ROWS_PER_PAGE")}
          labelDisplayedRows={({ from, to, count }) =>
            `${from.toLocaleString("en-US")}-${to.toLocaleString(
              "en-US"
            )} ${getLang(lang, "label.OF")} ${count.toLocaleString("en-US")}`
          }
        />
      )}
    </>
  );
}
