import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { STOCKFLOW_TRANSFER_VIEW } from "lib/constants/accessRights";
import MainPageComponent from "./main.component";
import LoadingComponent from "components/loading";

function MainPageContainer() {
  const hasStockflowViewAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(STOCKFLOW_TRANSFER_VIEW)
  );
  const lang = useSelector((state) => state.constant.languages);
  const history = useHistory();

  useEffect(() => {
    if (!hasStockflowViewAccessRight) {
      history.push("/admin/dashboard");
    }
  }, [history, hasStockflowViewAccessRight]);

  if (!hasStockflowViewAccessRight) return <LoadingComponent />;

  return (
    <>
      <MainPageComponent lang={lang} />
    </>
  );
}

export default MainPageContainer;
