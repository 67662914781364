import React from "react";
import { makeStyles, TextField } from "@material-ui/core";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles(theme => ({
    option: {
        "& > span": {
            flex: 1
        }
    },
    paper: {
        width: "100%"
    },
    codePaper: {
        width: "200%"
    }
}));

const filterOptions = () => createFilterOptions({
    stringify: option => option.name + option.alpha3,
});

export default function CurrencySelectComponent({ textFieldProps, formProps, currencyDropdownData, type = "currency", lang }) {
    const classes = useStyle();
    return (
        <Autocomplete
            {...formProps}
            fullWidth={textFieldProps.fullWidth || false}
            options={currencyDropdownData}
            disableClearable
            noOptionsText={getLang(lang, "label.NO_RESULT_FOUND")}
            classes={{
                option: classes.option,
                paper: type === "currency" ? classes.paper : classes.codePaper,
            }}
            autoHighlight
            filterOptions={filterOptions()}
            getOptionLabel={option => type === "currency" ? `${option.name}` : typeof formProps.value == 'string' ? `${option}` : `${option.alpha3}`}
            renderOption={option => (
                <React.Fragment>
                    <span>{option.name}</span>
                </React.Fragment>
            )}
            renderInput={params => {
                return (
                    <TextField
                        name={type}
                        {...params}
                        {...textFieldProps}
                        InputProps={{
                            ...params.InputProps,
                            disableUnderline: true,
                            style: {
                                padding: "5px",
                                borderRadius: "4px",
                            }
                        }}
                        placeholder={type === "currency" ? getLang(lang, "placeholder.SELECT_A_CURRENCY") : getLang(lang, "placeholder.CURRENCY")}
                        inputProps={{
                            ...params.inputProps,
                            style: {
                                paddingLeft: "8px"
                            },
                            autoComplete: "off" // disable autocomplete and autofill
                        }}
                    />
                );
            }}
        />
    );
}