import axios from "axios";

const get = ({ id }) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/campaign/api/admin/lucky-draws/${id}`)
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

const put = ({
  title,
  description,
  imageUrl,
  tncUrl,
  metadata,
  productId,
  startAt,
  endAt,
  status,
  id
}) => {
  const body = {
    title: title,
    description: description,
    imageUrl: imageUrl,
    tncUrl: tncUrl,
    customField: metadata,
    productId: productId,
    startAt: startAt,
    endAt: endAt,
    status: status
  };

  return new Promise((resolve, reject) => {
    axios
      .put(`/campaign/api/admin/lucky-draws/${id}`, body)
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

const post = ({
  title,
  description,
  imageUrl,
  tncUrl,
  metadata,
  productId,
  startAt,
  endAt,
  status
}) => {
  const body = {
    title: title,
    description: description,
    imageUrl: imageUrl,
    tncUrl: tncUrl,
    customField: metadata,
    productId: productId,
    startAt: startAt,
    endAt: endAt,
    status: status
  };

  return new Promise((resolve, reject) => {
    axios
      .post("/campaign/api/admin/lucky-draws", body)
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

const luckyDraw = {
  put,
  get,
  post
};

export default luckyDraw;
