import axios from "axios";

// validate invitation code
export function validateInvitationCode({
  invitationCode,
}) {
  return new Promise((resolve, reject) => {
    axios.post("distributor/public/api/v1/auth/validate-invitation-code", {
      invitation_code: invitationCode
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data))
  })
}