import React from "react";
import {
  makeStyles,
  Box,
  Typography,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  IconButton
} from "@material-ui/core";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import { StyledTableCell, StyledTableRow } from "components/table";
import { DeleteSVG } from "../../../utils/static";
import { getLang } from "app/feature/constants";

const style = (theme) => ({
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    borderCollapse: "separate",
    border: "1px solid rgba(58, 77, 84, 0.1)",
    borderBottom: "none",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0
    },
    "& th,td": {
      fontSize: ".75rem",
      padding: "10px 16px 10px 16px"
    },
    "& th": {
      color: "rgba(58, 77, 84, 0.33)"
    }
  },
  emptyContainer: {
    backgroundColor: theme.palette.background.paper,
    alignItems: "center",
    display: "flex",
    placeContent: "center",
    border: "1px solid rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
    marginTop: theme.spacing(2)
  }
});

const useStyles = makeStyles(style);

export default function AddEmployeeTableComponent({
  branchDropdown,
  tableData,
  handleDelete,
  lang
}) {
  const classes = useStyles();

  const getDisplay = (value) => {
    let display = getLang(lang, "paragraph.NO_BRANCH_SELECTED");
    if (value.length > 0) {
      display = value[0].name;
      if(value.length > 1){
        display = display + ' + ' + (value.length -1).toString();  
      }
    }
    return display;
  };
 

  return (
    <div>
      {tableData.length > 0 && branchDropdown.length > 0 ? (
        <TableContainer style={{ paddingTop: "16px" }}>
          <Table
            className={classes.table}
            size="small"
            aria-label="product table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <StyledTableCell>{getLang(lang, "label.NUM")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.EMAIL")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.ROLE")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.BRANCH")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.ACTION")}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.map((row, index) => {
                return (
                  <StyledTableRow
                    key={index}
                    hover
                    role="checkbox"
                    tabIndex={-1}
                  >
                    <StyledTableCell component="th" scope="row">
                      {index + 1}
                    </StyledTableCell>
                    <StyledTableCell>{row.email}</StyledTableCell>
                    <StyledTableCell>{row.role.name}</StyledTableCell>
                    <StyledTableCell>{getDisplay(row.branch)}</StyledTableCell>
                    <StyledTableCell>
                      <IconButton
                        onClick={() => handleDelete(index)}
                        style={{ padding: 0 }}
                      >
                        <DeleteSVG />
                      </IconButton>
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box py={6} className={classes.emptyContainer}>
          <ReportProblemOutlinedIcon />
          <Typography variant="body2" style={{ paddingLeft: "4px" }}>
            {getLang(lang, "paragraph.NO_EMPLOYEE_ADDED")}
          </Typography>
        </Box>
      )}
    </div>
  );
}
