import React from "react";
import { Box, Dialog, IconButton, makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const useStyle = makeStyles((theme) => ({
  root: {
    fontWeight: theme.typography.fontWeightBold
  },
  publishButton: {
    color: theme.palette.common.white,
    borderRadius: "100px",
    padding: theme.spacing(1, 3)
  },
  underline: {
    "&:before": {
      borderBottom: "2px solid",
      borderBottomColor: theme.palette.primary.main
    }
  },
  input: {
    fontSize: "24px",
    fontWeight: "bold",
    textAlign: "center"
  },
  header: {
    padding: "4px 0px",
    paddingLeft: theme.spacing(3),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  button: {
    display: "flex",
    placeContent: "flex-end",
    paddingBottom: theme.spacing(2)
  },
  videoContainer: {
    display: "flex",
    alignItems: "stretch",
    justifyContent: "center",
    maxHeight: "83vh",
  },
  video: {
    display: "block",
    objectFit: "contain",
    objectPosition: "center",
    width: "100%"
  }
}));

export default function DisplayImageDialogComponent({
  lang,
  handleClose,
  isOpen,
  src,
  type,
}) {
  const classes = useStyle();

  return (
    <Dialog
      handleClose={handleClose}
      disableEnforceFocus={true}
      disableAutoFocus={true}
      open={isOpen}
      PaperProps={{
        style: {
          margin: "16px",
          borderRadius: "4px",
          width: "100%"
        }
      }}
    >
      <Box>
        <Box className={classes.header}>
          <Box />
          <IconButton color="primary" onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
        <Box>
          {type === 'video' && (
            <div className={classes.videoContainer}>
              <video className={classes.video} muted preload="metadata" controls>
                <source src={src} />
              </video>
            </div>
          )}
          {type === 'image' && (
            <img width="100%" style={{ objectFit: "contain", maxHeight: "83vh" }} src={src} alt="img" />
          )}
        </Box>
      </Box>
    </Dialog>
  );
}
