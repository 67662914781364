import React from "react";
import { makeStyles, Box, Typography, Divider } from "@material-ui/core";
import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";
import RecallListing from "../recall-listing";
import { getLang } from "app/feature/constants";

const style = (theme) => ({
  indicator: {
    border: "0.5px solid",
    borderColor: theme.palette.primary.main,
  },
  scroll: {
    width: "min-content",
  },
});

const useStyles = makeStyles(style);

export default function MainPageComponent({ lang }) {
  const classes = useStyles();

  return (
    <Box>
      <Box gridArea="topbar">
        <Box
          mb={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="flex" alignItems="flex-end" fontWeight="bold">
            <Typography variant="h6" color="primary">
              Trackco
            </Typography>
            <Box mx={0.5}>
              <ChevronRightRoundedIcon color="disabled" />
            </Box>
            <Box>
              <Typography variant="h6" color="primary">
                {getLang(lang, "label.RECALL")}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
      </Box>
      <Box gridArea="panel">
        <Box>
          <Box Box className={classes.panel}>
            <RecallListing />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
