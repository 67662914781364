import { createSlice } from "@reduxjs/toolkit";
import { submitCompanyInfo, verifyEmail } from "modules/public/redux/action";
import { COMPANY_STATUS_PENDING, SESSION } from "../../constants";
import {
  checkSession,
  fetchCompanyInfo,
  fetchAppSetting,
  fetchMasterInfo,
  checkMaintenanceAlert,
} from "../action";
import { fetchLabel } from "../action";
import axios from "axios";
import { createEmployee, removeEmployee } from "modules/employee/redux";

const initialState = {
  isMaintenance: false,
  showMaintenanceAlert: false,
  isSessionChecked: false,
  accessToken: "",
  expiresIn: 0,
  isAuthenticated: false,
  idTokenPayload: {
    sub: "",
    name: "",
    email: "",
    emailVerified: false,
  },
  initialize: {
    loading: true,
    error: false,
  },
  labels: [],
  company: {
    name: "",
    registrationNo: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    country: "",
    postalCode: "",
    state: "",
    contactNumber: "",
    companyEmail: "",
    industry: 8,
    articleAssociation: "",
    directorIdentification: "",
    status: "",
    remarks: "",
    usersCount: 0,
    timezone: "Asia/Kuala_Lumpur",
  },
};

const sessionSlice = createSlice({
  name: SESSION,
  initialState,
  reducers: {
    setMaintenanceStatus(state, action) {
      state.isMaintenance = action.payload;

      if (
        (process.env.REACT_APP_MAINTENANCE_MODE === "true" ||
          !!state.isMaintenance) &&
        window.location.pathname !== "/maintenance"
      ) {
        window.location.replace("/maintenance");
      }
    },
    setMaintenanceAlert(state, action) {
      state.showMaintenanceAlert = action.payload;
    },
    setCheckedSession(state, action) {
      state.isSessionChecked = action.payload;
    },
    setSession(state, action) {
      const { payload } = action;

      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${payload.access_token}`;

      if (state.accessToken === "") {
        state.isSessionChecked = true;
        state.isAuthenticated = true;
        state.idTokenPayload.name = payload.access_token_payload.name;
        state.idTokenPayload.email = payload.access_token_payload.email;
        state.idTokenPayload.emailVerified =
          payload.access_token_payload.verified;
        state.idTokenPayload.sub = payload.access_token_payload.sub;
        state.expiresIn = payload.access_token_payload.exp;
        state.initialize.loading = true;
        state.initialize.error = false;
      }
      state.accessToken = payload.access_token;
    },
    setGrantAccess(state, action) {
      state.isAccessGranted = action.payload;
      state.isHashParsed = true;
    },
    clearSession(state, action) {
      // state.code = initialState.code;
      // state.jwtToken = initialState.jwtToken;
      // state.sessionID = initialState.sessionID;
      // state.status = initialState.status;
      // state.userAccountToken = initialState.userAccountToken;
      state = initialState;

      // TODO: handle reset session state manually
    },
    setLabels(state, action) {
      state.labels = action.payload;
    },
    setAppInitialized(state, action) {
      state.initialize.loading = false;
    },
    setCompanySession(state, action) {
      const { company } = state;

      company.name = action.payload.name;
      company.registrationNo = action.payload.reg_no;
      company.addressLine1 = action.payload.line1;
      company.addressLine2 = action.payload.line2;
      company.city = action.payload.city;
      company.country = action.payload.country;
      company.postalCode = action.payload.postal_code;
      company.state = action.payload.state;
      company.contactNumber = action.payload.contact;
      company.companyEmail = action.payload.email;
      company.industry = action.payload.industry;
      company.status = action.payload.status;
      company.remarks = action.payload.remarks;
      company.usersCount = action.payload.users_count;
      company.timezone = action.payload.timezone;
    },
    setCompanyPendingApproval(state, action) {
      const { company } = state;

      company.status = COMPANY_STATUS_PENDING;
    },
  },
  extraReducers: {
    // [logout.fulfilled]: (state, action) => {
    //   state.session
    // },
    [checkMaintenanceAlert.fulfilled]: (state, action) => {
      state.showMaintenanceAlert = action.payload;
    },
    [fetchAppSetting.pending]: (state, action) => {
      state.initialize.loading = true;
    },
    [fetchAppSetting.fulfilled]: (state, action) => {
      state.initialize.loading = false;
    },
    [fetchMasterInfo.rejected]: (state, action) => {
      state.initialize.loading = false;
      state.initialize.error = true;
    },
    [fetchLabel.fulfilled]: (state, action) => {
      state.labels = action.payload;
    },
    [fetchCompanyInfo.fulfilled]: (state, action) => {
      const { company } = state;
      const { payload } = action;

      if (payload != null) {
        company.name = payload.name;
        company.registrationNo = payload.reg_no;
        company.addressLine1 = payload.line1;
        company.addressLine2 = payload.line2;
        company.city = payload.city;
        company.country = payload.country;
        company.postalCode = payload.postal_code;
        company.state = payload.state;
        company.contactNumber = payload.contact;
        company.companyEmail = payload.email;
        company.industry = payload.industry;
        company.status = payload.status;
        company.remarks = payload.remarks;
        company.usersCount = payload.users_count;
        company.timezone = payload.timezone;
      }
    },
    [submitCompanyInfo.fulfilled]: (state, action) => {
      const { meta } = action;
      const { arg } = meta;
      const { company } = state;

      company.name = arg.name;
      company.registrationNo = arg.registrationNo;
      company.addressLine1 = arg.addressLine1;
      company.addressLine2 = arg.addressLine2;
      company.city = arg.city;
      company.country = arg.country;
      company.postalCode = arg.postalCode;
      company.state = arg.state;
      company.contactNumber = arg.contactNumber;
      company.companyEmail = arg.companyEmail;
      company.industry = arg.industry;
      company.status = COMPANY_STATUS_PENDING;
      company.remarks = "";
      company.timezone = arg.timezone;
    },
    [verifyEmail.fulfilled]: (state, action) => {
      state.idTokenPayload.emailVerified = true;
    },
    [checkSession.rejected]: (state, action) => {
      state.isSessionChecked = true;
      localStorage.clear();
    },
    [createEmployee.fulfilled]: (state, action) => {
      state.company.usersCount =
        state.company.usersCount + action.meta.arg.length;
    },
    [removeEmployee.fulfilled]: (state, action) => {
      state.company.usersCount = state.company.usersCount - 1;
    },
  },
});

export const {
  setCheckedSession,
  setSession,
  clearSession,
  setGrantAccess,
  setLabels,
  setAppInitialized,
  setCompanySession,
  setCompanyPendingApproval,
  setMaintenanceStatus,
  setMaintenanceAlert,
} = sessionSlice.actions;
export const sessionReducer = sessionSlice.reducer;
