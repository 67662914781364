import getAllLuckyDraw from "./getAllLuckyDraw.api"
import luckyDraw from "./luckyDraw.api"
import getAllPrize from "./getAllPrize.api"
import prize from "./prize"
import getAllParticipant from "./getAllParticipant.api"
import getParticipantDetail from "./getParticipantDetail.api"
import changeStatus from "./changeStatus.api"
import getParticipantExcel from "./getParticipantExcel.api"
import getAllRandomParticipant from "./getAllRandomParticipant.api"
import saveWinner from "./saveWinner.api"
import changeProgress from "./changeProgress.api"
import getWinnerExcel from "./getWinnerExcel.api"
import liveDraw from "./liveDraw.api";

const luckyDrawApi = {
  getAllLuckyDraw,
  luckyDraw,
  getAllPrize,
  prize,
  getAllParticipant,
  getParticipantDetail,
  changeStatus,
  getParticipantExcel,
  getAllRandomParticipant,
  saveWinner,
  changeProgress,
  getWinnerExcel,
  liveDraw,
};

export default luckyDrawApi;
