import { Content } from "modules/reporting/components/layout";
import { BranchSalesPanel, DemographicPanel, FilterPanelV3, MiscellaneousPanel, SuspendedProductPanel } from "modules/reporting/components/panel";
import React from "react";

export default function SuspendedProductPage({
    filter,
    tagUrl,
    currentFilter,
    pageMode,
    translate
}) {
    const pageType = "product-suspended";
    return (
        <>
            <FilterPanelV3
                filter={filter}
                currentFilter={currentFilter}
                pageMode={pageMode}
                translate={translate}
            />

            <Content>
                {/* trending card --- START */}
                <SuspendedProductPanel 
                    currentFilter={currentFilter}
                    urlTotalSuspended={tagUrl + "get-suspended-qr"}
                    urlTotalReported={tagUrl + "get-report-received"}
                    urlSuspendedListing={tagUrl + "get-suspended-scan"}

                    pageType={pageType}

                    translate={translate}
                />
                {/* trending card --- END */}

                {/* demographic --- START */}
                <DemographicPanel 
                    currentFilter={currentFilter}
                    urlAge={tagUrl + "get-total-age"} 
                    urlGender={tagUrl + "get-total-gender" } 
                    urlGenderAge={tagUrl + "get-total-gender-age"}
                    urlCountry={tagUrl + "get-country"}
                    urlCity={tagUrl + "get-city"}

                    pageType={pageType}
                    translate={translate}
                />
                {/* demographic --- END */}

                {/* branch sales detail row --- START*/}
                {
                    !currentFilter.branchUuid ? 
                    (
                        <BranchSalesPanel 
                            url={tagUrl + "get-branch"}
                            currentFilter={currentFilter}
        
                            pageType={pageType}
                            translate={translate}
                        /> 
                    ): null
                }
                {/* branch sales detail row --- END*/}  

                {/* miscellaneous card row --- START */}
                <MiscellaneousPanel 
                    currentFilter={currentFilter}
                    urlPlatform={tagUrl + "get-total-platform"}
                    urlDevice={tagUrl + "get-total-device"}
                    urlBrowser={tagUrl + "get-total-browser"}
                    urlLanguage={tagUrl + "get-total-language"}

                    translate={translate}
                />
                {/* miscellaneous card row --- END */}
            </Content>
        </>
    )
}