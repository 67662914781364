import { selectLoading } from "modules/notification";
import { getConsumerListingData, getScanAgainstSuspendedData, getTotalScanTrending, getTotalSuspendedTrending,exportConsumerListingData } from "modules/reporting/redux";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ConsumerPanel from './consumerPanel.component';
import { unwrapResult } from "@reduxjs/toolkit";

export default function ConsumerPanelContainer({
    currentFilter,
    urlTotalScan,
    urlTotalSuspended,
    urlTotalDistributionPerformance,
    urlGetConsumerList,
    urlScanAgainstSuspended,
    pageType,
    translate
}) {
    const dispatchTrending = useDispatch();
    const dispatchConsumerList = useDispatch();
    const dispatch = useDispatch();
    const token = useSelector((state) => state.session.accessToken);
    // (1) trending data -- start
    // (a) get trending: total scan qr data
    const isFetchingTotalScanTrending = useSelector(state => selectLoading(state, getTotalScanTrending.typePrefix));
    const totalScanTrendingData = useSelector(state => state.reporting.trending.totalScanData);

    // (b) get trending: total suspended qr data
    const isFetchingTotalSuspendedTrending = useSelector(state => selectLoading(state, getTotalSuspendedTrending.typePrefix));
    const totalSuspendedTrendingData = useSelector(state => state.reporting.trending.totalSuspendedData);

    // (c) get trending: total distribution performance data
    // const isFetchingTotalDistributionPerformance = useSelector(state => selectLoading(state, getTotalDistributionPerformance.typePrefix));
    // const totalDistributionPerformanceData = useSelector(state => state.reporting.trending.totalDistributionPerformanceData);
    // (1) trending data -- end

    // (2) consumer listing data -- start
    const isFetchingConsumerListingData = useSelector(state => selectLoading(state, getConsumerListingData.typePrefix));
    const consumerListingData = useSelector(state => state.reporting.consumer.consumerDatalist);
    // (2) consumer listing data -- end

    const isFetchingScanAgainstSuspendedData = useSelector(state => selectLoading(state, getScanAgainstSuspendedData.typePrefix));
    const scanAgainstSuspendedData = useSelector(state => state.reporting.trending.scanAgainstSuspendedData);

    const [tableFilter, setTableFilter] = useState({
        page: 0,
        rowsPerPage: 25,
        search: "",
        searchBy: "name",
        orderBy: null,
        orderDirection: "asc",
    })

    useEffect(() => {
        dispatchTrending(getTotalScanTrending({
            countryCode: currentFilter.countryCode,
            city_uuid: currentFilter.cityUuid,
            branch_uuid: currentFilter.branchUuid,
            period: currentFilter.period,
            start_date: moment(currentFilter.dateRange[0]).format('YYYY-MM-DD'),
            end_date: moment(currentFilter.dateRange[1]).format('YYYY-MM-DD'),
            url: urlTotalScan
        }));

        dispatchTrending(getTotalSuspendedTrending({
            countryCode: currentFilter.countryCode,
            city_uuid: currentFilter.cityUuid,
            branch_uuid: currentFilter.branchUuid,
            period: currentFilter.period,
            start_date: moment(currentFilter.dateRange[0]).format('YYYY-MM-DD'),
            end_date: moment(currentFilter.dateRange[1]).format('YYYY-MM-DD'),
            url: urlTotalSuspended
        }));

        // dispatchTrending(getTotalDistributionPerformance({
        //     countryCode: currentFilter.countryCode,
        //     city_uuid: currentFilter.cityUuid,
        //     branch_uuid: currentFilter.branchUuid,
        //     period: currentFilter.period,
        //     start_date: moment(currentFilter.dateRange[0]).format('YYYY-MM-DD'),
        //     end_date: moment(currentFilter.dateRange[1]).format('YYYY-MM-DD'),
        //     url: urlTotalDistributionPerformance
        // }));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatchTrending, currentFilter]);

    useEffect(() => {
        dispatchConsumerList(getConsumerListingData({
            length: tableFilter.rowsPerPage,
            start: tableFilter.page * tableFilter.rowsPerPage,
            orderBy: tableFilter.orderBy,
            orderDirection: tableFilter.orderDirection,
            search: tableFilter.search,
            searchBy: tableFilter.searchBy,
            countryCode: currentFilter.countryCode,
            city_uuid: currentFilter.cityUuid,
            branch_uuid: currentFilter.branchUuid,
            period: currentFilter.period,
            start_date: moment(currentFilter.dateRange[0]).format('YYYY-MM-DD'),
            end_date: moment(currentFilter.dateRange[1]).format('YYYY-MM-DD'),
            url: urlGetConsumerList
        }));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatchConsumerList, tableFilter, currentFilter]);

    useEffect(() => {
        dispatch(getScanAgainstSuspendedData({
            url: urlScanAgainstSuspended,
            countryCode: currentFilter.countryCode,
            city_uuid: currentFilter.cityUuid,
            branch_uuid: currentFilter.branchUuid,
            category_uuid: currentFilter.categoryUuid,
            model_uuid: currentFilter.modelUuid,
            brand_uuid: currentFilter.brandUuid,
            product_uuid: currentFilter.productUuid,
            period: currentFilter.period,
            start_date: currentFilter.dateRange[0],
            end_date: currentFilter.dateRange[1]
        }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, currentFilter])

    const handleChangePage = (e, newPage) => {
        setTableFilter((prev) => ({
            ...prev,
            page: newPage,
        }))
    }

    const handleChangeRowPerPage = (event) => {
        setTableFilter((prev) => ({
            ...prev,
            page: 0,
            rowsPerPage: event.target.value
        }))
    }

    const handleRequestSort = (event, property) => {
        const isAsc = tableFilter.orderBy === property && tableFilter.orderDirection === 'asc';
        setTableFilter((prev) => ({
            ...prev,
            orderDirection: isAsc ? 'desc' : 'asc',
            orderBy: property,
        }))
    }

    const handleExportExcel = () => {
        const info = {
            token: token,
            length: tableFilter.rowsPerPage,
            start: tableFilter.page * tableFilter.rowsPerPage,
            orderBy: tableFilter.orderBy,
            orderDirection: tableFilter.orderDirection,
            search: tableFilter.search,
            searchBy: tableFilter.searchBy,
            countryCode: currentFilter.countryCode,
            city_uuid: currentFilter.cityUuid,
            branch_uuid: currentFilter.branchUuid,
            period: currentFilter.period,
            start_date: moment(currentFilter.dateRange[0]).format('YYYY-MM-DD'),
            end_date: moment(currentFilter.dateRange[1]).format('YYYY-MM-DD'),
            url: urlGetConsumerList
        }

        dispatch(exportConsumerListingData(info))
            .then(unwrapResult)
            .finally(() => {

            });
    }

    const columns = [
        {
            title: translate("label.NUM"),
            field: null,
            render: (row) => {
                return (
                    <>{row.title}</>
                )
            },
            align: null,
        },
        {
            title: translate("label.CONSUMER"),
            field: null,
            render: (row) => {
                return (
                    <>{row.title}</>
                )
            },
            align: null
        },
        {
            title: translate("label.SERIAL_NUMBER"),
            field: null,
            render: (row) => {
                return (
                    <>{row.title}</>
                )
            },
            align: null
        },
        {
            title: translate("label.SCAN_COUNT"),
            field: null,
            render: (row) => {
                return (
                    <>{row.title}</>
                )
            },
            align: null
        },
        {
            title: translate("label.GENDER"),
            field: null,
            render: (row) => {
                return (
                    <>{row.title}</>
                )
            },
            align: null
        },
        {
            title: translate("label.AGE"),
            field: null,
            render: (row) => {
                return (
                    <>{row.title}</>
                )
            },
            align: null
        },
        // {
        //     title: 'Warranty',
        //     field: null,
        //     render : (row) => {
        //         return (
        //             <>{row.title}</>
        //         )
        //     },
        //     align: null
        // },
        // {
        //     title: 'Lucky Draw',
        //     field: null,
        //     render : (row) => {
        //         return (
        //             <>{row.title}</>
        //         )
        //     },
        //     align: null
        // },
        // {
        //     title: 'Instant Lucky Draw',
        //     field: null,
        //     render : (row) => {
        //         return (
        //             <>{row.title}</>
        //         )
        //     },
        //     align: null
        // },
        // {
        //     title: 'Survey',
        //     field: null,
        //     render : (row) => {
        //         return (
        //             <>{row.title}</>
        //         )
        //     },
        //     align: null
        // },
        // {
        //     title: 'Loyalty Points',
        //     field: null,
        //     render : (row) => {
        //         return (
        //             <>{row.title}</>
        //         )
        //     },
        //     align: null
        // },
        {
            title: translate("label.DATE"),
            field: 'created_at',
            render: (row) => {
                return (
                    <>{row.title}</>
                )
            },
            align: null
        },
    ]

    return (
        <ConsumerPanel
            status={{
                isFetchingTotalScanTrending,
                isFetchingTotalSuspendedTrending,
                // isFetchingTotalDistributionPerformance,
                isFetchingConsumerListingData,
                isFetchingScanAgainstSuspendedData
            }}

            data={{
                totalScanTrendingData,
                totalSuspendedTrendingData,
                // totalDistributionPerformanceData,
                consumerListingData,
                scanAgainstSuspendedData
            }}

            handleExportExcel={handleExportExcel}

            sort={{
                valueToOrderBy: tableFilter.orderBy,
                orderDirection: tableFilter.orderDirection,
                handleRequestSort: handleRequestSort,
            }}

            pagination={{
                handleChangePage: handleChangePage,
                handleChangeRowPerPage: handleChangeRowPerPage,
                page: tableFilter.page,
                rowsPerPage: tableFilter.rowsPerPage,
                rowsPerPageOptions: [5, 10, 25],
            }}

            columns={columns}

            currentFilter={currentFilter}

            pageType={pageType}

            translate={translate}
        />
    )
}