import axios from "axios";

export function getProductDropdown(parameters) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/serialnumber/api/v1/products/get-all-dropdown-by-branch`, {
        branch_uuid: parameters.branch_uuid,
        country: parameters.country,
      })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data));
  });
}
