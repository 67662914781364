import React from "react";
import SnSummaryCardComponent from "./snSummaryCard.component";

function SnSummaryCardContainer({ value, title, isLoading }) {
  return (
    <SnSummaryCardComponent value={value} title={title} isLoading={isLoading} />
  );
}

export default SnSummaryCardContainer;
