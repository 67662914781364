import axios from "axios";

export function getRequestTransferRequestBranches(parameters) {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `/distributor/admin/api/v1/request-transfer/get-available-receiver?length=${parameters.length}&start=${parameters.start}&search=${parameters.search}`
      )
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data));
  });
}
