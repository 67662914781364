// All image for genuine background
import GenuineBackgroundEN from "assets/img/genuine-background/genuine-background-EN.png";
import GenuineBackgroundVI from "assets/img/genuine-background/genuine-background-VI.png";
import GenuineBackgroundTH from "assets/img/genuine-background/genuine-background-TH.png";
import GenuineBackgroundZH from "assets/img/genuine-background/genuine-background-ZH.png";
import GenuineBackgroundZHTW from "assets/img/genuine-background/genuine-background-ZHTW.png";
import GenuineBackgroundMS from "assets/img/genuine-background/genuine-background-MS.png";
import GenuineBackgroundTL from "assets/img/genuine-background/genuine-background-TL.png";

const getGenuineBackground = (lang = "EN") => {
    const genuineBackgroundImgs = {
        "EN": GenuineBackgroundEN,
        "VI": GenuineBackgroundVI,
        "TH": GenuineBackgroundTH,
        "ZH": GenuineBackgroundZH,
        "ZHTW": GenuineBackgroundZHTW,
        "MS": GenuineBackgroundMS,
        "ID": GenuineBackgroundMS,
        "TL": GenuineBackgroundTL
    };
    
    return genuineBackgroundImgs[lang];
}

export default {
    getGenuineBackground
  }