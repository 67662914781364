import React from "react";
import { makeStyles } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

const useStyle = makeStyles(theme => ({
  root: {
    margin: theme.spacing(0, 2),

    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    '& .tiktok-embed': {
      minWidth: 'unset !important',
    },
  },
}));

export default function TiktokComponent({
  isFetchingEmbed,
  embedData,
}) {
  const classes = useStyle();

  if (isFetchingEmbed)
    return (
      <div className={classes.root} style={{ height: 400 }}>
        <Skeleton variant="rect" height="100%" width="100%" />
      </div>
    );

  return (
    <div
      className={classes.root}
      dangerouslySetInnerHTML={{
        __html: embedData
      }}
    >
    </div>
  );
}
