import { generatePaginationOptions, updateUrlQueryParam } from "lib/helper"
import { getConsumerCampaignListing } from "modules/consumer/redux/action/consumer.action"
import { selectLoading } from "modules/notification"
import { fetchTagsDropdown } from "modules/serial-number/redux"
import React, { useState, useRef, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useRouteMatch } from "react-router-dom"
import CampaignListPanelComponent from "./campaignListPanel.component"

function CampaignListPanelContainer() {
  const dispatch = useDispatch()
  const history = useHistory()
  const match = useRouteMatch()
  const id = match.params.consumerId

  const list = useSelector(state => state.consumer.campaignList.data)
  const totalRecords = useSelector(state => state.consumer.campaignList.totalRecords)
  const totalFiltered = useSelector(state => state.consumer.campaignList.totalFiltered)
  const isFetching = useSelector(state => selectLoading(state, getConsumerCampaignListing.typePrefix))
  const isError = useSelector(state => state.consumer.isLoadingCampaignListError)
  const tags = useSelector(state => state.serialNumberTag.tagsDropdown)
  const lang = useSelector(state => state.constant.languages)
  const language = useSelector(state => state.profile.language)
  const isSearchResult = list.length < totalRecords
  const [paginationOptions, setPaginationOptions] = useState([])
  const [isFilterUpdated, setIsFilterUpdated] = useState(false)
  const [tableFilter, setTableFilter] = useState({
    page: 0,
    rowsPerPage: 25,
    order: "desc",
    date: "",
    products: [],
    patterns: [],
    type: ""
  })

  const query = new URLSearchParams(window.location.search)
  const tableFilterInitialRef = useRef(true)

  useEffect(() => {
    dispatch(fetchTagsDropdown())

    const isFilter = query.has("is_filter")
    let filter = sessionStorage.getItem(`consumer_campaign_filter_${id}`);

    let temp = {...tableFilter}
    if (isFilter && filter) {
      temp = JSON.parse(filter)
      setIsFilterUpdated(true)
    }

    if (isFilter) {
      updateUrlQueryParam(null, ["is_filter"])
    }
    sessionStorage.removeItem(`consumer_campaign_filter_${id}`)

    setTableFilter(temp)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!tableFilterInitialRef.current) {
      getData()
    }
    tableFilterInitialRef.current = false
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableFilter, id])

  useEffect(() => {
    setPaginationOptions(generatePaginationOptions(totalFiltered))
  }, [totalFiltered])

  const getData = () => {
    dispatch(getConsumerCampaignListing({
      id,
      length: tableFilter.rowsPerPage,
      start: tableFilter.rowsPerPage * tableFilter.page,
      order: tableFilter.order,
      date: tableFilter.date,
      products: tableFilter.products,
      patterns: tableFilter.patterns,
      type: tableFilter.type ? tableFilter.type.toLowerCase() : "",
    }))
  }

  const handleChangePage = (event, newPage) => {
    let newData = {
      ...tableFilter,
      page: newPage,
    }
    setIsFilterUpdated(true)
    setTableFilter(newData)
  }

  const handleChangeRowsPerPage = event => {
    let newData = {
      ...tableFilter,
      page: 0,
      rowsPerPage: event.target.value,
    }
    setIsFilterUpdated(true)
    setTableFilter(newData)
  }

  const handleFilter = (values) => {
    let newData = {
      ...tableFilter,
      page: 0,
      rowsPerPage: tableFilter.rowsPerPage,
      date: values.date,
      products: values.products,
      patterns: values.patterns,
      type: values.type,
    }

    setIsFilterUpdated(true)
    setTableFilter(newData)
  }

  const resetFilter = () => {
    let newData = {
      ...tableFilter,
      page: 0,
      date: "",
      products: [],
      patterns: [],
      type: ""
    }
    setIsFilterUpdated(false)
    setTableFilter(newData)
  }

  const changeOrder = (order) => {
    let newData = {
      ...tableFilter,
      order,
    }
    setIsFilterUpdated(true)
    setTableFilter(newData)
  }

  const handleViewDetail = (url) => {
    if (isFilterUpdated) {
      updateUrlQueryParam({ is_filter: true })
      sessionStorage.setItem(`consumer_campaign_filter_${id}`, JSON.stringify(tableFilter));
    }
    history.push(url)
  }

  return (
    <CampaignListPanelComponent
      list={list}
      isFetching={isFetching}
      isError={isError}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      page={tableFilter.page}
      rowsPerPage={tableFilter.rowsPerPage}
      totalFiltered={totalFiltered}
      handleReload={getData}
      isSearchResult={isSearchResult}
      paginationOptions={paginationOptions}
      tableFilter={tableFilter}
      language={language}
      changeOrder={changeOrder}
      handleFilter={handleFilter}
      id={id}
      tags={tags}
      lang={lang}
      resetFilter={resetFilter}
      handleViewDetail={handleViewDetail}
    />
  )
}

export default CampaignListPanelContainer
