import React from "react";
import { makeStyles, Box, ButtonBase, Badge, lighten } from "@material-ui/core";
import PermIdentityOutlinedIcon from "@material-ui/icons/PermIdentityOutlined";
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Droppable } from "react-beautiful-dnd";
import clsx from "clsx";
import PhoneMockUpComponent from "./phoneMockUp.component";
import DefaultImg from "assets/img/img-default.png";

const useStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "900px",
    display: "grid",
    // gridTemplateColumns: "230px 1fr",
    // columnGap: theme.spacing(2),
    transform: "translateX(0px)",
    '&:hover iframe': {
      pointerEvents: 'none',
    },
  },
  rootWithHiddenComponent: {
    transform: "translateX(-30px)",
  },
  rootPreview: {
    maxHeight: 473,
    display: "block",
    background: "linear-gradient(166.63deg, #632FF9 0%, #C24BAF 85.52%)",
    position: "relative",
    width: "100%",
    maxWidth: 380,
    '&:hover iframe': {
      pointerEvents: 'none',
    },
  },
  componentWrapper: {
    transition: theme.transitions.create("background-color", {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  draggedOver: {
    backgroundColor: lighten(theme.palette.secondary.light, 0.5),
  },
  previewContainer: {
    height: "100%",
    width: "100%",
    maxWidth: 370,
    background: "linear-gradient(166.63deg, #632FF9 0%, #C24BAF 85.52%)",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    justifySelf: "center",
  },
  previewContainerResetBg: {
    background: "none",
  },
  btnProfile: {
    backgroundColor: "rgba(255,255,255,0.15)",
    height: 50,
    width: 50,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#ffffff",
  },
  profileImage: {
    height: 50,
    width: 50,
    borderRadius: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    "& img": {
      height: "100%",
      flexShrink: 0,
      minWidth: "100%",
      minHeight: "100%",
      objectFit: "cover",
    },
  },
}));

export default function InteractiveEditorComponent({
  populateComponents,
  previewMode,
  companyLogo,
  isAnyComponentHidden,
  uiSetting,
}) {
  const classes = useStyle();
  const InteractiveEditorNode = (
    <PerfectScrollbar
      className={clsx({
        [classes.root]: !previewMode,
        [classes.rootPreview]: previewMode,
        // [classes.rootWithHiddenComponent]: isAnyComponentHidden,
      })}
    >
      {/* {!previewMode && (
        <Box position="relative" width="230px" height="100%"></Box>
      )} */}

      <Box
        style={
          uiSetting.bgType === "Solid color"
            ? { background: uiSetting.bgColor }
            : uiSetting.bgType === "Image" && uiSetting.bgImage !== ""
            ? {
                backgroundImage: `url(${uiSetting.bgImage})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }
            : null
        }
        className={clsx(classes.previewContainer, {
          [classes.previewContainerResetBg]: previewMode,
        })}
      >
        {/* App bar start */}
        <Box display="flex" justifyContent="space-between" p={2}>
          <Badge color="error" badgeContent=" " overlap="circle" variant="dot">
            <ButtonBase className={classes.btnProfile}>
              <PermIdentityOutlinedIcon fontSize="large" color="inherit" />
            </ButtonBase>
          </Badge>
          <Box className={classes.profileImage}>
            <img src={companyLogo || DefaultImg} alt="profile-example" />
          </Box>
        </Box>
        {/* App bar end */}
        <Droppable droppableId="interactive-editor" type="interactive-editor">
          {(provided, snapshot) => {
            return (
              <Box
                className={clsx(classes.componentWrapper, {
                  [classes.draggedOver]: snapshot.isDraggingOver,
                })}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {populateComponents()}
                {provided.placeholder}
              </Box>
            );
          }}
        </Droppable>
      </Box>
    </PerfectScrollbar>
  );

  if (previewMode)
    return <PhoneMockUpComponent>{InteractiveEditorNode}</PhoneMockUpComponent>;
  else return InteractiveEditorNode;
}
