import React, { useRef } from "react";
import {
  makeStyles,
  Typography,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  Box,
  Divider,
  InputAdornment,
  Button,
  MenuItem,
  MenuList,
  Tooltip,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import PerfectScrollbar from "react-perfect-scrollbar";
import InputTextField from "components/input/inputTextField";
import Skeleton from "@material-ui/lab/Skeleton";
import AntSwitch from "components/switch/switch";
import { getLang } from "app/feature/constants";
import DefaultImg from "assets/img/img-default.png";

const useStyle = makeStyles((theme) => ({
  paper: {
    width: 385,
  },
  button: {
    padding: 0,
    minWidth: 0,
  },
  buttonAddWarranty: {
    display: "flex",
    padding: 0,
    minWidth: 0,
  },
  createLink: {
    padding: theme.spacing(2),
  },
  perfectScrollbar: {
    height: 200,
  },
  attributeText: {
    color: "#b2b2b2",
    fontSize: 12,
    whiteSpace: "break-spaces",
  },
}));

export default function AssignProductPanelComponent({
  open,
  anchorRef,
  handleClose,
  handleListKeyDown,
  isOpenList,
  productDropdown,
  isFetching,
  value,
  handleSearch,
  lang,
}) {
  const classes = useStyle();
  const textRef = useRef();
  return (
    <Popper
      open={open}
      style={{ zIndex: 1301 }}
      anchorEl={anchorRef.current}
      role={undefined}
      transition
      disablePortal={false}
      placement="bottom-start"
      modifiers={{
        preventOverflow: {
          enabled: false,
          boundariesElement: "scrollParent",
        },
      }}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === "bottom-start" ? "center top" : "center bottom",
          }}
        >
          <Paper className={classes.paper}>
            <ClickAwayListener onClickAway={handleClose}>
              <Box>
                <Box>
                  <InputTextField
                    variant="filled"
                    size="small"
                    fullWidth
                    inputRef={textRef}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          style={{ paddingRight: "6px" }}
                        >
                          <Button
                            onClick={() => handleSearch(textRef.current.value)}
                            className={classes.button}
                          >
                            <SearchIcon fontSize="small" />
                          </Button>
                        </InputAdornment>
                      ),
                      style: {
                        backgroundColor: "#f7f7f7",
                      },
                    }}
                    inputProps={{
                      onKeyDown: (e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                          handleSearch(textRef.current.value);
                          return false;
                        }
                      },
                      style: {
                        padding: "14px",
                      },
                    }}
                    placeholder={getLang(lang, "placeholder.SEARCH_PRODUCT")}
                  />
                </Box>

                <Divider />

                <PerfectScrollbar className={classes.perfectScrollbar}>
                  {isFetching ? (
                    <>
                      <Skeleton variant="text" animation="wave" />
                      <Skeleton variant="text" animation="wave" />
                      <Skeleton variant="text" animation="wave" />
                      <Skeleton variant="text" animation="wave" />
                    </>
                  ) : productDropdown.length > 0 ? (
                    productDropdown.map((field, index) => {
                      return (
                        <Box key={index}>
                          {field.status === "published" ? (
                            <MenuList
                              autoFocusItem={isOpenList}
                              id="menu-list-grow"
                              onClick={() => {
                                handleListKeyDown(field);
                              }}
                            >
                              <MenuItem>
                                <AntSwitch
                                  checked={
                                    value.find(
                                      (v) => v.id === field.id || v === field.id
                                    )
                                      ? true
                                      : false
                                  }
                                />
                                <Box display="flex" alignItems="center" pl={1}>
                                  <img
                                    src={
                                      field.picture ? field.picture : DefaultImg
                                    }
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                      marginRight: "0.5em",
                                    }}
                                    alt={field.id}
                                  />
                                  <Box
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="center"
                                    alignItems="flex-start"
                                  >
                                    <Typography variant="body2">
                                      {field.name}
                                    </Typography>
                                    {!!field.attributes &&
                                      !!field.attributes.length && (
                                        <Typography
                                          className={classes.attributeText}
                                          variant="body2"
                                        >
                                          {field.attributes.join(", ")}
                                        </Typography>
                                      )}
                                  </Box>
                                </Box>
                              </MenuItem>
                            </MenuList>
                          ) : (
                            <Tooltip
                              title="This product is unpublished."
                              placement="right"
                            >
                              <MenuList
                                autoFocusItem={isOpenList}
                                id="menu-list-grow"
                              >
                                <MenuItem>
                                  <Box display="flex" alignItems="center">
                                    <AntSwitch
                                      disabled
                                      checked={
                                        value.find(
                                          (v) =>
                                            v.id === field.id || v === field.id
                                        )
                                          ? true
                                          : false
                                      }
                                    />
                                    <Box
                                      display="flex"
                                      flexDirection="column"
                                      justifyContent="center"
                                      alignItems="flex-start"
                                      pl={1}
                                    >
                                      <Typography variant="body2">
                                        {field.name}
                                      </Typography>
                                      {!!field.attributes &&
                                        !!field.attributes.length && (
                                          <Typography
                                            className={classes.attributeText}
                                            variant="body2"
                                          >
                                            {field.attributes.join(", ")}
                                          </Typography>
                                        )}
                                    </Box>
                                  </Box>
                                </MenuItem>
                              </MenuList>
                            </Tooltip>
                          )}
                        </Box>
                      );
                    })
                  ) : (
                    <Box py={9} style={{ textAlign: "center" }}>
                      <Typography variant="body1">
                        {getLang(lang, "label.NO_PRODUCT")}
                      </Typography>
                    </Box>
                  )}
                  <Divider />
                </PerfectScrollbar>
              </Box>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}
