import React from "react"
import { makeStyles, Box, Button } from "@material-ui/core"
import { Formik } from "formik"
import DateRangePicker from "components/input/dateRangePicker"
import Input from "components/input/inputTextField"
import { getLang } from "app/feature/constants"
import clsx from "clsx"
import DealerRewardListTable from "../../table/dealerRewardListTable"
import moment from "moment"

const useStyles = makeStyles((theme) => ({
  container: {
    background: "white",
    borderRadius: 4,
    padding: "24px 20px"
  },
  searchContainer: {
    backgroundColor: theme.palette.background.paper,
    display: "grid",
    gridTemplateColumns: "6fr 4fr 1fr 2fr",
    columnGap: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1)
    }
  },
  inputRootID: {
    paddingTop: "8px !important",
    paddingBottom: "7px !important",
    paddingLeft: "12px !important",
    paddingRight: "12px !important",
  },
  emptyField: {
    "& .MuiInputBase-root": {
      color: "#A4A6A8"
    }
  }
}))

export default function DealerRewardListPanelComponent({
  hasStockflowRewardCenterViewAccessRight,
  id,
  rewards,
  isFetching,
  isError,
  handleChangePage,
  handleChangeRowsPerPage,
  totalFiltered,
  page,
  rowsPerPage,
  handleSearch,
  clearSearch,
  handleReload,
  paginationOptions,
  tableFilter,
  changeOrder,
  language,
  lang,
  handleViewDetail
}) {
  const classes = useStyles()

  return (
    <>
      <Box className={classes.container}>
        <Formik
          initialValues={{
            search: tableFilter.search,
            dateRange: tableFilter.fromDate && tableFilter.toDate ? {
              from: tableFilter.fromDate,
              to: tableFilter.toDate,
            } : null,
          }}
          enableReinitialize
          onSubmit={(values, actions) => {
            handleSearch(values)
            actions.setSubmitting(false)
          }}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <Box mb={2} className={classes.searchContainer}>
                <Input
                  hiddenLabel
                  variant="filled"
                  size="small"
                  placeholder={getLang(lang, "placeholder.SEARCH_ORDER_ID")}
                  margin="none"
                  InputProps={{
                    disableUnderline: true,
                    margin: "none",
                    style: {
                      borderRadius: "4px",
                      backgroundColor: "#eceff0",
                      minWidth: "50px",
                      height: "100%"
                    },
                    classes: { input: classes.inputRootID }
                  }}
                  {...formik.getFieldProps("search")}
                />
                <DateRangePicker
                  label=""
                  placeholder={`${getLang(lang, "placeholder.REDEMPTION_DATE")}: ${moment().subtract(7, "days").format("DD MMM YY")} - ${moment().format("DD MMM YY")}`}
                  disabled={isFetching}
                  disableFuture={false}
                  className={clsx({
                    [classes.emptyField]: !formik.getFieldProps("dateRange").value
                  })}
                  id={"created-picker-dialog"}
                  value={formik.getFieldProps("dateRange").value}
                  handleChange={(v) => {
                    formik.setFieldValue("dateRange", v)
                  }}
                  InputPropStyle={{
                    color: !formik.getFieldProps("dateRange").value ? "#A4A6A8" : "#000000",
                    fontWeight: "normal"
                  }}
                  inputProps={{
                    fontWeight: "400",
                    fontSize: "16px",
                  }}
                  title={getLang(lang, "placeholder.REDEMPTION_DATE")}
                />
                <Button
                  variant='text'
                  disableElevation
                  color="secondary"
                  size='small'
                  onClick={() => {
                    clearSearch()
                  }}
                >
                  {getLang(lang, "label.CLEAR")}
                </Button>
                <Button
                  disabled={isFetching}
                  variant="contained"
                  disableElevation
                  color='primary'
                  size='small'
                  type='submit'
                >
                  {getLang(lang, "label.SEARCH")}
                </Button>
              </Box>
            </form>
          )}
        </Formik>
        <Box mt={3}>
          <DealerRewardListTable
            hasStockflowRewardCenterViewAccessRight={hasStockflowRewardCenterViewAccessRight}
            rows={rewards}
            isFetching={isFetching}
            isError={isError}
            handleReload={handleReload}
            page={page}
            rowsPerPage={rowsPerPage}
            language={language}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            totalFiltered={totalFiltered}
            paginationOptions={paginationOptions}
            tableFilter={tableFilter}
            changeOrder={changeOrder}
            lang={lang}
            handleViewDetail={handleViewDetail}
          />
        </Box>
      </Box>
    </>
  )
}
