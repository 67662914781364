import React, { useEffect } from "react";
import {
  Box,
  makeStyles,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Typography,
  Button,
  TableSortLabel,
} from "@material-ui/core";
import { StyledTableCell, StyledTableRow, StickyRightTableCell } from "components/table";
import { SkeletonTableRow } from "components/skeleton/skeleton.component";
import Alert from "@material-ui/lab/Alert";
import clsx from "clsx";
import { ShowIcon } from "../../../utils/static";
import { TablePaginationActions } from "components/tablePagination";
import RefreshTable from "components/table/refreshTable";
import { ROOT as ACCOUNT_ROOT } from "modules/account";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { COUNTERFEIT_REPORT_VIEW } from "lib/constants/accessRights";
import { getLang } from "app/feature/constants";
import StickyLeftTableCell from "components/table/stickyLeftTableCell";
import { KeyboardArrowDown } from "@material-ui/icons"
import moment from "moment";
import "moment/min/locales.min";

const useStyle = makeStyles((theme) => ({
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    borderCollapse: "separate",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
    },
    "& th,td": {
      fontSize: ".75rem",
      borderRight: "1px solid rgba(58, 77, 84, 0.1)",
      padding: theme.spacing(1, 2, 1, 2),
    },
    "& th": {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightBold,
    },
    "& th:last-child, td:last-child": {
      width: 37,
      borderRight: "0px",
    },
    "& th th": {
      borderBottom: "none !important",
    },
    "& td td": {
      borderBottom: "none !important",
    },
  },
  emptyContainer: {
    textAlign: "center"
  },
  statusChip: {
    padding: theme.spacing(0.2, 0.5),
    borderRadius: theme.spacing(0.5),
    color: theme.palette.common.white,
    textAlign: "center"
  },
  statusInactive: {
    backgroundColor: "#FF8000"
  },
  statusActive: {
    backgroundColor: "#1f8d35"
  },
  button: {
    minWidth: 0,
    padding: 0
  }
}));

export default function CounterfeitTableComponent({
  rows,
  isFetching,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
  totalFiltered,
  search,
  handleView,
  handleReload,
  isLoadingCounterfeitReportError,
  paginationOptions,
  lang,
  tableFilter,
  language,
  changeOrder
}) {
  const classes = useStyle();
  const history = useHistory();
  
  if(language==="ZH")
  {
    moment.locale('zh-cn')
  }else if(language==="TL")
  {
    moment.locale('tl-ph')
  }else
  {
    moment.locale(language)
  }

  const hasCounterfeitReportViewAccessRight = useSelector(state => state.profile.accessRights.includes(COUNTERFEIT_REPORT_VIEW));
  const enableCounterfeitReport = useSelector(state => state.setting.enableCounterfeitReport);

  useEffect(() => {
    if (!hasCounterfeitReportViewAccessRight) {
      history.push('/admin/dashboard')
    }
  }, [history, hasCounterfeitReportViewAccessRight])

  const handleClick = id => {
    history.push(ACCOUNT_ROOT);
  }
  const displayStatus = status => {
    return (
      <Box
        className={clsx(classes.statusChip, {
          [classes.statusInactive]: status === "pending",
          [classes.statusActive]: status === "processed"
        })}
      >
        {status.toUpperCase()}
      </Box>
    );
  };

  return (
    <>
      {rows.length > 0 || isFetching ? (
        <TableContainer>
          <Table
            className={classes.table}
            size="small"
            aria-label="counterfeir report table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <StickyLeftTableCell>
                  <StyledTableCell>{getLang(lang, "label.NUM")}</StyledTableCell>
                </StickyLeftTableCell>
                <StyledTableCell>{getLang(lang, "label.SERIAL_NUMBER")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.PRODUCT_NAME")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.CONSUMER_NAME")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.CONTACT_NUMBER")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.EMAIL")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.PURCHASED_DATE")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.PURCHASED_FROM")}</StyledTableCell>
                <StyledTableCell>
                  <TableSortLabel
                    active={true}
                    direction={tableFilter.order}
                    onClick={() => {
                      const order = tableFilter.order === 'asc' ? "desc" : "asc"
                      changeOrder(order)
                    }}
                    IconComponent={KeyboardArrowDown}
                  >
                    {getLang(lang, "label.REPORTED_DATE")}
                  </TableSortLabel>
                </StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.STATUS")}</StyledTableCell>
                <StickyRightTableCell>
                  <StyledTableCell>{getLang(lang, "label.ACTION")}</StyledTableCell>
                </StickyRightTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isFetching
                ? [...new Array(5)].map((v, index) => (
                  <SkeletonTableRow key={index} columnCount={11} />
                ))
                : rows.map((row, index) => {
                  return (
                    <StyledTableRow
                      key={index}
                      hover
                      role="checkbox"
                      tabIndex={-1}
                    >
                      <StickyLeftTableCell>
                        <StyledTableCell>
                          {index + 1 + rowsPerPage * page}
                        </StyledTableCell>
                      </StickyLeftTableCell>
                      <StyledTableCell>{row.serialNumber}</StyledTableCell>
                      <StyledTableCell>{row.productName}</StyledTableCell>
                      <StyledTableCell>{row.consumerName}</StyledTableCell>
                      <StyledTableCell>{row.phoneNumber}</StyledTableCell>
                      <StyledTableCell>{row.email}</StyledTableCell>
                      <StyledTableCell>{moment(row.purchasedDate).format('ll')}</StyledTableCell>
                      <StyledTableCell>{row.purchasedFrom}</StyledTableCell>
                      <StyledTableCell>{moment(row.createdAt).format('ll')}</StyledTableCell>
                      <StyledTableCell>{displayStatus(row.status)}</StyledTableCell>
                      <StickyRightTableCell>
                        <Box
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <StyledTableCell style={{
                            display: "contents",
                         }}>

                            <Button
                              className={classes.button}
                              onClick={() => handleView(row.refCode)}
                            >
                              <ShowIcon />
                            </Button>
                          </StyledTableCell>
                        </Box>
                      </StickyRightTableCell>
                    </StyledTableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : !search && !isLoadingCounterfeitReportError && (
        <Box style={{overflowX: 'auto', backgroundColor: '#fff'}}>
          <Table
            className={classes.table}
            size="small"
            aria-label="counterfeir report table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <StickyLeftTableCell>
                  <StyledTableCell>{getLang(lang, "label.NUM")}</StyledTableCell>
                </StickyLeftTableCell>
                <StyledTableCell>{getLang(lang, "label.SERIAL_NUMBER")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.PRODUCT_NAME")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.CONSUMER_NAME")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.CONTACT_NUMBER")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.EMAIL")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.PURCHASED_DATE")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.PURCHASED_FROM")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.REPORTED_DATE")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.STATUS")}</StyledTableCell>
                <StickyRightTableCell>
                  <StyledTableCell>{getLang(lang, "label.ACTION")}</StyledTableCell>
                </StickyRightTableCell>
              </TableRow>
            </TableHead>
          </Table>
          <Box py={9} className={classes.emptyContainer}>
            <Typography variant="body1">
              {getLang(lang, "paragraph.NO_COUNTERFEIT_REPORT")}
            </Typography>
            {
              !enableCounterfeitReport
                ? <Box>
                  <Typography style={{ marginTop: "6px" }} variant="body2">
                    {getLang(lang, "paragraph.ENABLE_COUNTERFEIT_REPORT")}
                  </Typography>
                  <Box mt={2}>
                    <Button
                      variant='contained'
                      disableElevation
                      color="primary"
                      style={{ textTransform: "uppercase" }}
                      onClick={() => handleClick("btn-company")}
                    >
                      {getLang(lang, "label.COMPANY_SETTINGS")}
                    </Button>
                  </Box>
                </Box>
                : null
            }

          </Box>
        </Box>

      )
      }

      {
        !isFetching && rows.length === 0 && search && (
          <Alert severity="info">{getLang(lang, "message.info.NO_COUNTERFEIT_REPORT")}</Alert>
        )
      }

      {
        !isFetching && isLoadingCounterfeitReportError && (
          <RefreshTable handleReload={handleReload} />
        )
      }

      {
        !isFetching && rows.length > 0 && (
          <TablePagination
            style={{
              backgroundColor: "#fff",
              boxShadow: "0px -1px 0px rgba(58, 77, 84, 0.15)",
            }}
            ActionsComponent={TablePaginationActions}
            rowsPerPageOptions={paginationOptions}
            component="div"
            count={totalFiltered}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage={getLang(lang, 'label.ROWS_PER_PAGE')}
            labelDisplayedRows={({ from, to, count }) => `${from.toLocaleString("en-US")}-${to.toLocaleString("en-US")} ${getLang(lang, 'label.OF')} ${count.toLocaleString("en-US")}`}
          />
        )
      }
    </>
  );
}