import axios from "axios";

const publishInstantLuckyDraw = ({ id }) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/campaignV2/customer/instantLuckyDraw/publish", {
        instant_lucky_draw_id: id,
      })
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

export default publishInstantLuckyDraw;
