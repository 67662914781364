import React from "react";
import {
  makeStyles,
  Typography,
  ButtonBase,
  Box,
  CircularProgress,
} from "@material-ui/core";
import { ArrowDropDown as ArrowDropDownIcon } from "@material-ui/icons";
import clsx from "clsx";
import DefaultImg from "assets/img/img-default.png";

const useStyle = makeStyles((theme) => ({
  root: {
    padding: "5px 12px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "white",
    borderRadius: 8,
    width: "100%",
    height: 43,
    border: "1px solid #D0D5DD",
  },
  inputText: {
    fontSize: "1rem",
    color: "#acb4b7",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  filled: {
    color: "rgba(0, 0, 0, 0.87)",
  },
  courierPhoto: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #E4E7EC",
    width: "30px",
    height: "30px",
    marginRight: "10px",
    "& img": {
      height: "30px",
      width: "30px",
    },
  },
}));

export default function CourierCompanySelectComponent({
  handleClick,
  anchorRef,
  disabled,
  value,
  textStyles,
  styles,
  placeholder,
  lang,
  isFetching,
}) {
  const classes = useStyle();

  return (
    <ButtonBase
      className={classes.root}
      disableRipple
      ref={anchorRef}
      style={styles}
      onClick={handleClick}
      disabled={disabled}
    >
      <Box display="flex">
        {isFetching ? (
          <CircularProgress size={20} />
        ) : !value ? (
          <Typography
            variant="body2"
            style={{ ...textStyles, opacity: disabled ? 0.5 : 1 }}
            className={clsx(classes.inputText, !!value && classes.filled)}
          >
            {placeholder}
          </Typography>
        ) : (
          <Box display="flex" alignItems="center">
            <Box className={classes.courierPhoto}>
              {!value.logo ? (
                <img src={DefaultImg} alt="emptyProduct" />
              ) : (
                <img src={value.logo} alt={value.name} />
              )}
            </Box>
            <Box className={classes.name}>{value.name}</Box>
          </Box>
        )}
      </Box>
      <ArrowDropDownIcon fontSize="small" />
    </ButtonBase>
  );
}
