import axios from "axios";

export function getCountryDropdown(parameters) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/account/api/v1/nodes/get-country-dropdown`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data));
  });
}
